<template>
    <div class="modal remodal detailImgModal">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="bg"></div>

        <Hooper class="imageSlideContainer" ref="hooperSlide" @slide="updateViewIndex" :initialSlide="view_index">
            <Slide class="imageSlide" :key="`${file.name}-${index}`" v-for="(file, index) in files">
                <img :id="`slideImage_${index}`" :src="event_image_preview_info.type == 'CHAT' ? `chat_data/${file.url}` : file.url.indexOf('calendar') == -1 && file.url.indexOf('patient_file') == -1 ? file.url : `/app_images/${file.url}`" 
                     @load="imageLoadComplete(index)" draggable="false" style="display : none;"/>
            </Slide>
        </Hooper>

        <div class="grp1">
            <input type="button" class="image-prev" value="이전" @click="clickImagePrev" v-if="view_index > 0"/>
            <input type="button" class="image-next" value="다음" @click="clickImageNext" v-if="view_index < (files.length - 1)"/>
        </div>

        <div class="titleBox">
                
            <div class="forRel">

                <div class="bg"></div>
                <input type="button" class="input_btn reply_image_delete" v-if="event_image_preview_info.reply_delete_function" @click="imageDelete" value="삭제" />
                <div class="grp1">

                    <!-- <h1>첨부된 이미지</h1> -->
                    
                    <div>
                        <img :class="{ on : view_index == index }" class="thumb" 
                        :src="event_image_preview_info.type == 'CHAT' ? `chat_data/${file.url}` : file.url.indexOf('calendar') == -1 && file.url.indexOf('patient_file') == -1 ? file.url : `/app_images/${file.url}`" :key="`${file.name}-${index}`" v-for="(file, index) in files"
                        @click="selectImage(index)" draggable="false"/>

                        <button class="remodal-close" @click="imagePreviewModalClose"><span></span><span></span></button>
                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
import { EventImagePreviewInfo } from '@/store/modules/EventInfo';
import { t_event_file } from '@/model/event';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver, Hooper, Slide
    },
}) export default class EventImagePreview extends Mixins(VueHoduCommon) {
    
    // this.$refs.hooperSlide 사용
    $refs !: { hooperSlide : HTMLFormElement; }

    /**
     * @EventInfo.State
     */
    @EventInfo.State  event_image_preview_info   !: EventImagePreviewInfo;
    
    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventImagePreview ?: any;

    view_index : number = 0;
    files : t_event_file[] = [];

    beforeMount() : void {
        this.view_index = this.event_image_preview_info.selected_index == null ? 0 : this.event_image_preview_info.selected_index;
        this.files = this.event_image_preview_info.files == null ? [] : this.event_image_preview_info.files;
    }

    mounted() {
    
    }

    /**
     * 이미지 선택
     */
    selectImage(new_index : number) : void {
        this.$refs.hooperSlide.slideTo(new_index);
    }

    /**
     * 이미지 미리보기 닫기
     */
    imagePreviewModalClose() : void {
        this.doSetShowEventImagePreview(false);
    }

    /**
     * 이미지 로드 완료
     */
    imageLoadComplete(index : number) : void {
        this.setImageCss(index);
    }

    /**
     * 너비, 높이 css 반환
     */
    setImageCss(index : number) : void {
        
        const img_id : string = `slideImage_${index}`;
        const img : JQuery<HTMLElement> = $(`#${img_id}`);

        let image_original_width  : number | undefined = img.data("image_original_width");
        let image_original_height : number | undefined = img.data("image_original_height");

        if( !image_original_width || image_original_width < 1 || !image_original_height || image_original_height < 1 ) {
            image_original_width  = img.width();
            image_original_height = img.height();

            if( !image_original_width || !image_original_height ) { return; }

            img.data("image_original_width" , image_original_width  ? image_original_width  : 0);
            img.data("image_original_height", image_original_height ? image_original_height : 0);
        }

        const image_max_width : number = 1000;
        const image_max_height : number = window.innerHeight - 100 - 20; // 100px는 하단 영역, 20px는 기본 마진

        let resized_image_width : number = image_original_width; 
        let resized_image_height : number = image_original_height;
        let image_ratio : number = (image_original_width / image_original_height);

        // height가 image_max_height 보다 높은 경우 max_height로 줄이고 width를 비율에 맞게 변경한다
        if( resized_image_height > image_max_height ) {
            resized_image_height = image_max_height;
            resized_image_width  = resized_image_height * image_ratio;
        }
        
        // width가 image_max_width 보다 높은 경우 max_width로 줄이고 height를 비율에 맞게 변경한다
        if( resized_image_width > image_max_width ) {
            resized_image_width  = image_max_width;
            resized_image_height = resized_image_width / image_ratio;
        }

        img.width(resized_image_width);
        img.height(resized_image_height);

        // 높이가 image_max_height 보다 작다면 가운데를 맞춰준다
        if( image_max_height > resized_image_height ) {
            const image_top_margin : number = (((image_max_height) - resized_image_height) / 2) + 10;
            img.css("margin-top", `${image_top_margin}px`);
        }

        else {
            img.css("margin-top", "10px");
        }

        img.show();
    }

    /**
     * 현재 보고있는 인덱스로 세팅
     */
    updateViewIndex(payload : any) : void {
        this.view_index = payload.currentSlide;
    }

    /**
     * 이전
     */
    clickImagePrev() : void {
        this.$refs.hooperSlide.slidePrev();
    }

    /**
     * 이후
     */
    clickImageNext() : void {
        this.$refs.hooperSlide.slideNext();
    }

    /**
     * 댓글 이미지 삭제
     */
    async imageDelete() : Promise<void> {
        if( !this.event_image_preview_info.reply_delete_function ) { return;  }

        if( confirm("사진을 삭제 하시겠습니까?\n묶음 사진은 전부 같이 삭제 됩니다") == true ) {
            await this.event_image_preview_info.reply_delete_function(
                this.event_image_preview_info.js_event,
                this.event_image_preview_info.reply,
                this.event_image_preview_info.dayIndex,
                this.event_image_preview_info.reply_index
            );
            this.imagePreviewModalClose();            
        } 
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        const file_count : number = this.files.length;
        for( let i = 0; i < file_count; i++ ) { this.setImageCss(i); }
    }
}
</script>

<style scoped>
    .remodal.detailImgModal {width: 100%;height: 100%;z-index: 1000000000000;max-height: 100%;position: absolute;}

    .detailImgModal .grp1 { position:relative; }
    .detailImgModal .grp1 input { z-index: 1000; position:fixed; padding:0;  width:80px; height:80px; background-repeat: no-repeat !important; transition:0.1s; right:10%; top:50%; margin-top:-100px; font-size:0;border-radius:50%; background-color: rgba(0,0,0,0.2); background-size:40px !important; }
    .detailImgModal .grp1 input:focus { background-color: rgba(0,0,0,0.2); }
    .detailImgModal .grp1 input:hover { background-color:rgba(0,0,0,0.3) !important; }
    .detailImgModal .image-prev { left: 10%; background-image: url(../../../assets/images/contents/ic_img_prev.png) !important;background-position:18px 20px !important;  }
    .detailImgModal .image-next { background-image: url(../../../assets/images/contents/ic_img_next.png) !important;background-position:23px 21px !important;  }

    .detailImgModal .bg { z-index: -1; position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: #000; opacity: .6 }

    .imageSlideContainer { width: auto; height: 100%; text-align: center; }
    .imagePreviewFooterContainer { z-index: 1000; background: transparent; height: 100px; position: fixed; bottom: 0; top: auto; left: 0; right: auto; width: 100%; box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); -webkit-box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); -moz-box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); -o-box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); -ms-box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); }
    .imagePreviewFooterContainer .forRel { position: relative; width: 100%; height: 100%; text-align: center; }
    .imagePreviewFooterContainer .forRel .bg { z-index: -1; position: absolute; width: 100%; height: 100%; background: #000; opacity: 0.6; }
    .imageSlideContainer .imageSlide img { max-width: 1000px; margin-top: 10px; margin-bottom: 10px; }
    .reply_image_delete:hover { color: black !important; border: white; }
    .reply_image_delete { position: absolute; float: right; left: 80px; top : 30px; color: white !important; background-color : #ff6363 !important; border-color: #ff6363; }
</style>