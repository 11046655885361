<template>
    <div id="event_email_share_modal" class="shareModal modal" :class="{ peepsOn : selected_target != null }">

        <div class="titleBox">
            <h1 class="borderbot">메일 보내기</h1>
            
            <p class="total" v-if="selected_target == null && user_ids.length > 0">멤버 <span>{{ user_ids.length }}</span></p>
            <p class="total grp" v-if="selected_target == null && group_ids.length > 0">그룹 <span>{{ group_ids.length }}</span></p>
            <p class="total team" v-if="selected_target == null && team_ids.length > 0">팀 <span>{{ team_ids.length }}</span></p>

            <!-- 그룹&팀 멤버 클릭해서 들어간 목록에서 사용 -->
            <p class="total user" v-if="selected_target != null">멤버 <span>{{ getSelectedMemberCount() }}</span> / {{ selected_target.group_member_count != null ? selected_target.group_member_count : selected_target.team_member_count != null ? selected_target.team_member_count : 0 }}</p> 

            <div class="schDiv">

                <!-- 검색 버튼, 검색시 on 클래스 추가 -->
                <a class="schBtn" id="grpSchBtn" :class="{ on : (selected_target == null && group_team_search_mode == true) || (selected_target != null && member_search_mode == true) }" @click.prevent="searchModeToggle">검색버튼</a>

                <!-- 검색 영역, 검색시 on 클래스 추가 -->
                <div class="schGrp" :class="{ on : (selected_target == null && group_team_search_mode == true) || (selected_target != null && member_search_mode == true) }">
                    <input type="text" id="searchTotalTxt" class="input_search02" 
                           :placeholder="(selected_target == null && group_team_search_mode == true) ? '그룹 또는 팀 이름으로 검색하세요' : '멤버 이름 또는 이메일로 검색하세요'" 
                           :value="(selected_target == null && group_team_search_mode == true) ? group_team_search_query : member_search_query"
                           @input="(selected_target == null && group_team_search_mode == true) ? group_team_search_query = $event.target.value : member_search_query = $event.target.value" />
                    <a class="schBtn"><span class="blind">찾기버튼</span></a>
                </div>

            </div>

        </div>

        <div class="modal_content">
            
            <!-- 그룹, 팀 헤더 -->
            <ul class="sortHeader grpHeader">
                <li class="grpCheck">선택</li>
                <li class="num">No</li>
                <li class="nm headerSort" @click="groupTeamSort('NAME')" :class="{
                    sortOff  : group_team_sort_type != 'NAME',
                    sortUp   : group_team_sort_type == 'NAME' && group_team_sort_direction == 'ASC', 
                    sortDown : group_team_sort_type == 'NAME' && group_team_sort_direction == 'DESC', 
                }"><span>그룹</span></li>
                <li class="count headerSort" @click="groupTeamSort('COUNT')" :class="{
                    sortOff  : group_team_sort_type != 'COUNT',
                    sortUp   : group_team_sort_type == 'COUNT' && group_team_sort_direction == 'ASC', 
                    sortDown : group_team_sort_type == 'COUNT' && group_team_sort_direction == 'DESC', 
                }"><span>멤버</span></li>
            </ul>

            <!-- 멤버 헤더 -->
            <ul class="sortHeader teamHeader">

                <!-- 전체 선택 -->
                <li class="grpCheck">
                    <p class="grpTeamCheckAll" >
                        <input type="checkbox" id="grpTeamCheckAll" :checked="isMemberAllChecked()" @change="selectAllMember($event)">
                        <label for="grpTeamCheckAll">전체선택</label>
                    </p>
                </li>

                <li class="num">No</li>
                <li class="pNm headerSort" @click="memberSort('NAME')" :class="{
                    sortOff  : member_sort_type != 'NAME',
                    sortUp   : member_sort_type == 'NAME' && member_sort_direction == 'ASC', 
                    sortDown : member_sort_type == 'NAME' && member_sort_direction == 'DESC', 
                }"><span>이름</span></li>
                <li class="pos"><span>이메일</span></li>

            </ul>

            <!-- 그룹, 팀 내용 -->
            <div class="shareUlDiv">
                <div class="noResult">검색 결과가 없습니다</div>

                <ul class="grpUl">

                    <li :key="group.group_id" :class="{ withTeams : group.teams.length > 0, on : group.list_on == true }" v-for="(group, group_index) in computedTargetList">
                        <div class="grp">
                            <input type="checkbox" :id="`grpCheck${group_index + 1}`" v-model="group.checked" @change="groupSelect($event, group)"/>
                            <label :for="`grpCheck${group_index + 1}`"></label> 
                            <p class="num">{{ group_index + 1 }}</p>
                            <p class="img" :style="{ backgroundImage : `url(${hodu_profile_image_url('group', group.group_id)})` }">그룹 사진</p>
                            <img class="img_dummy" :src="hodu_profile_image_url('group', group.group_id)" @error="imageError($event, group.group_info.group_color)" />
                            <h2 class="nm">{{ group.group_info.group_name }}</h2>
                            <p class="cl" :style="{ backgroundColor : `${hodu_hex_color_process(group.group_info.group_color)} !important` }">그룹 색</p>
                            <p class="count">
                                <a @click.prevent="selectTarget(group)">
                                    <span>{{ group.group_member.filter(item => item.checked == true).length }}</span> / {{ group.group_member_count }}
                                </a>
                            </p>
                            <a class="showTeams" v-if="group.teams.length > 0" @click="teamListOnOff(group)">하위그룹 보기</a>
                        </div>

                        <ul class="teamUl">
                            <li :key="team.team_id" v-for="(team, team_index) in group.teams.filter(item => item.isNotincluded == null || item.isNotincluded == false)">
                                <div class="grp">
                                    <input type="checkbox" :id="`grpCheck${group_index + 1}-${team_index + 1}`" v-model="team.checked" @change="teamSelect($event, team)"/>
                                    <label :for="`grpCheck${group_index + 1}-${team_index + 1}`"></label>
                                    <p class="num">{{ `${group_index + 1}-${team_index + 1}` }}</p>
                                    <p class="img" :style="{ backgroundImage : `url(${hodu_profile_image_url('team', team.team_id)})` }">팀 사진</p>
                                    <img class="img_dummy" :src="hodu_profile_image_url('team', team.team_id)" @error="imageError($event, team.team_info.team_color)" />
                                    <h3 class="nm">{{ team.team_info.team_name }}</h3>
                                    <p class="cl" :style="{ backgroundColor : `${hodu_hex_color_process(team.team_info.team_color)} !important` }">팀 색</p>
                                    <p class="count">
                                        <a @click.prevent="selectTarget(team)">
                                            <span>{{ team.team_member.filter(item => item.checked == true).length }}</span> / {{ team.team_member_count }}
                                        </a>
                                    </p>
                                </div>
                            </li>
                        </ul>
                        
                    </li>
                    
                    <!-- <li class="withTeams">

                        <div class="grp">
                            <input type="checkbox" id="grpCheck1"/>
                            <label for="grpCheck1"></label>
                            <p class="num">1</p>
                            <p class="img">그룹 사진</p>
                            <img class="img_dummy" />
                            <h2 class="nm">지누스</h2>
                            <p class="cl dc7">그룹 색</p>
                            <p class="count"><a><span>5</span> / 10</a></p>
                            <a class="showTeams">하위그룹 보기</a>
                        </div>

                        <ul class="teamUl">
                            <li>
                                <div class="grp">
                                    <input type="checkbox" id="grpCheck1-1" />
                                    <label for="grpCheck1-1"></label>
                                    <p class="num">1-1</p>
                                    <p class="img">팀 사진</p>
                                    <img class="img_dummy" />
                                    <h3 class="nm">모바일팀</h3>
                                    <p class="cl dc0">팀 색</p>
                                    <p class="count"><a><span>3</span> / 5</a></p>
                                </div>
                            </li>
                        </ul>

                    </li> -->

                </ul>

            </div>

        </div>

        <!-- 그룹, 팀 멤버 내용 -->
        <div class="peepsDiv" :class="{ on : selected_target != null }">

            <div class="peepsUlDiv">

                <ul class="peeps">

                    <li :key="member.user_id" v-for="(member, member_index) in computedTargetMember">
                        <input type="checkbox" :id="`grpPeepCheck${member_index + 1}`" v-model="member.checked"/>
                        <label :for="`grpPeepCheck${member_index + 1}`"></label>
                        <p class="num">{{ member_index + 1 }}</p>
                        <p class="fPic" :style="{ backgroundImage : `url(${hodu_profile_image_url('user', member.user_id)})` }">멤버 사진</p>
                        <img class="img_dummy" :src="hodu_profile_image_url('user', member.user_id)" @error="userImageError($event)"/>
                        <p class="pNm">{{ member.user_name }}</p>
                        <p class="pos">{{ member.user_email }}</p>
                    </li>

                    <!-- <li>
                        <input type="checkbox" id="grpPeepCheck1" />
                        <label for="grpPeepCheck1"></label>
                        <p class="num">1</p>
                        <p class="fPic">멤버 사진</p>
                        <img class="img_dummy"/>
                        <p class="pNm">이성재</p>
                        <p class="pos">ysdogi@gmail.com</p>
                    </li>
                    <li>
                        <input type="checkbox" id="grpPeepCheck2" />
                        <label for="grpPeepCheck2"></label>
                        <p class="num">2</p>
                        <p class="fPic">멤버 사진</p>
                        <img class="img_dummy"/>
                        <p class="pNm">이성재2</p>
                        <p class="pos">complete242@naver.com</p>
                    </li>
                    <li>
                        <input type="checkbox" id="grpPeepCheck3" />
                        <label for="grpPeepCheck3"></label>
                        <p class="num">3</p>
                        <p class="fPic">멤버 사진</p>
                        <img class="img_dummy"/>
                        <p class="pNm">이성재3</p>
                        <p class="pos">ysarks@gmail.com</p>
                    </li> -->
                </ul>

            </div>

        </div>

        <!-- 버튼 영역 -->
        <div class="btns">
            <input type="button" class="input_btn" value="취소" @click="cancel">
            <input type="button" class="input_btn" :value="selected_target == null ? '보내기' : '확인'" @click="share">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { EventEmailShareModalInfo } from '@/store/modules/ModalInfo';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class EventEmailShareModal extends Mixins(VueHoduCommon) {

    /**
     * 정렬, 검색 결과 등 반영하고 나온 그룹, 팀 타겟 리스트
     */
    get computedTargetList() : any[] {

        let target_list : any[] = [];
        
        target_list = target_list.concat(this.target_list.filter(item => true));

        // 그룹 이름 또는 팀 이름 검색
        if( this.group_team_search_query.length > 0 ) {
            
            const temp : any[] = [];
            for( const group of target_list ) {
                const group_name : string = group.group_info.group_name;

                if( this.hodu_string_includes(group_name, this.group_team_search_query) ) {
                    temp.push(group);
                    continue;
                }
                
                for( const team of group.teams ) {
                    const team_name : string = team.team_info.team_name;
                    team.isNotincluded = !this.hodu_string_includes(team_name, this.group_team_search_query);
                }

                if( group.teams.filter(item => item.isNotincluded == false).length > 0 ) temp.push(group);
            }

            target_list.splice(0, target_list.length);
            target_list = target_list.concat(temp);
        }

        // 정렬 함수 (그룹, 팀 모두 적용해야하기 때문)
        const sort_function = (o1, o2) : number => {

            // 그룹, 팀 이름 오름차순
            if( this.group_team_sort_type == "NAME" && this.group_team_sort_direction == "ASC" ) {
                if( o1.team_info == null || o1.team_info.team_name == null || o1.team_info.team_name.length < 1 || 
                    o2.team_info == null || o2.team_info.team_name == null || o2.team_info.team_name.length < 1 ) {
                    if( o1.group_info.group_name  > o2.group_info.group_name ) { return  1; } 
                    if( o1.group_info.group_name  < o2.group_info.group_name ) { return -1; } 
                    if( o1.group_info.group_name == o2.group_info.group_name ) { return  0; }
                } 

                else {
                    if( o1.team_info.team_name  > o2.team_info.team_name ) { return  1; } 
                    if( o1.team_info.team_name  < o2.team_info.team_name ) { return -1; } 
                    if( o1.team_info.team_name == o2.team_info.team_name ) { return  0; }
                }
            }

            // 그룹, 팀 이름 내림차순
            if( this.group_team_sort_type == "NAME" && this.group_team_sort_direction == "DESC" ) {
                if( o1.team_info == null || o1.team_info.team_name == null || o1.team_info.team_name.length < 1 || 
                    o2.team_info == null || o2.team_info.team_name == null || o2.team_info.team_name.length < 1 ) {
                    if( o1.group_info.group_name  > o2.group_info.group_name ) { return -1; } 
                    if( o1.group_info.group_name  < o2.group_info.group_name ) { return  1; } 
                    if( o1.group_info.group_name == o2.group_info.group_name ) { return  0; }
                }

                else {
                    if( o1.team_info.team_name  > o2.team_info.team_name ) { return -1; } 
                    if( o1.team_info.team_name  < o2.team_info.team_name ) { return  1; } 
                    if( o1.team_info.team_name == o2.team_info.team_name ) { return  0; }
                }
            }

            // 그룹, 팀 멤버 수 오름차순
            if( this.group_team_sort_type == "COUNT" && this.group_team_sort_direction == "ASC" ) {
                
                if( o1.team_info == null || o1.team_info.team_name == null || o1.team_info.team_name.length < 1 || 
                    o2.team_info == null || o2.team_info.team_name == null || o2.team_info.team_name.length < 1 ) {
                    if( o1.group_member_count  > o2.group_member_count ) { return  1; } 
                    if( o1.group_member_count  < o2.group_member_count ) { return -1; } 
                    if( o1.group_member_count == o2.group_member_count ) { return  0; } 
                } 

                else {
                    if( o1.team_member_count  > o2.team_member_count ) { return  1; } 
                    if( o1.team_member_count  < o2.team_member_count ) { return -1; } 
                    if( o1.team_member_count == o2.team_member_count ) { return  0; } 
                }
                
            }

            // 그룹, 팀 멤버 수 내림차순
            if( this.group_team_sort_type == "COUNT" && this.group_team_sort_direction == "DESC" ) {

                if( o1.team_info == null || o1.team_info.team_name == null || o1.team_info.team_name.length < 1 || 
                    o2.team_info == null || o2.team_info.team_name == null || o2.team_info.team_name.length < 1 ) {
                    if( o1.group_member_count  > o2.group_member_count ) { return -1; } 
                    if( o1.group_member_count  < o2.group_member_count ) { return  1; } 
                    if( o1.group_member_count == o2.group_member_count ) { return  0; } 
                } 

                else {
                    if( o1.team_member_count  > o2.team_member_count ) { return -1; } 
                    if( o1.team_member_count  < o2.team_member_count ) { return  1; } 
                    if( o1.team_member_count == o2.team_member_count ) { return  0; } 
                }

            }

            return 0;
        };

        // 정렬
        for( const group of target_list ) group.teams.sort(sort_function);
        target_list.sort(sort_function);

        return target_list;
    }

    /**
     * 선택된 그룹, 팀의 멤버 
     */
    get computedTargetMember() : any[] {

        let target_member_list : any[] = [];

        if ( this.selected_target == null ) return target_member_list;

        if( this.selected_target.group_member != null ) {
            target_member_list = target_member_list.concat(this.selected_target.group_member);
        }

        if( this.selected_target.team_member != null ) {
            target_member_list = target_member_list.concat(this.selected_target.team_member);
        }

        // 검색
        if( this.member_search_query.length > 0 ) {

            const temp : any[] = target_member_list.filter((member) => 
                this.hodu_string_includes(member.user_name, this.member_search_query) ||
                this.hodu_string_includes(member.user_email, this.member_search_query));

            target_member_list.splice(0, target_member_list.length);
            target_member_list = target_member_list.concat(temp);
        }

        // 정렬
        target_member_list.sort((o1, o2) : number => {

            // 그룹, 팀 이름 오름차순
            if( this.member_sort_type == "NAME" && this.member_sort_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 그룹, 팀 이름 내림차순
            if( this.member_sort_type == "NAME" && this.member_sort_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            return 0;
        });

        return target_member_list;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State event_email_share_modal_info !: EventEmailShareModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventEmailShareModalInfo ?: (parms : EventEmailShareModalInfo) => void;

    target_list : any[] = [];

    user_ids : string[] = [];
    group_ids : number[] = [];
    team_ids : string[] = [];

    user_info_map : Map<string, string> = new Map();

    group_team_search_mode : boolean = false;
    group_team_search_query : string = "";
    group_team_sort_type : string = "NAME";
    group_team_sort_direction : string = "ASC";

    member_search_mode : boolean = false;
    member_search_query : string = "";
    member_sort_type : string = "NAME";
    member_sort_direction : string = "ASC";

    selected_target : any = null;

    mounted() : void {
        this.getTargetEventShareByEmail();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        // @ts-ignore
        $('.shareUlDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : 316,
            scrollbarPosition : 'outside',
        });

        // @ts-ignore
        $('.peepsUlDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : 316,
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 이메일 공유 대상 조회
     */
    async getTargetEventShareByEmail() : Promise<void> {

        const url = `api/v1/calendars/${this.event_email_share_modal_info.calendar_id}/events/${this.event_email_share_modal_info.scope}/${this.event_email_share_modal_info.scope_id}/${this.event_email_share_modal_info.event_id}/share/email`;

        try {
            const response = await this.hodu_api_call(url, API_METHOD.GET);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.target ) {
                throw new Error("이메일 공유 대상 조회 실패");
            }

            if( this.auth_info == null || this.auth_info.auth_check == false ) {
                response.data.data.target = response.data.data.target.filter(
                    group => group.group_type != 'BIZH' && group.group_info.project_type != 'kids' 
                );
            }

            this.target_list.splice(0, this.target_list.length);
            this.target_list = this.target_list.concat(response.data.data.target);

        } catch(e) {
            this.hodu_error_process(e, false, false);
        }

    }

    /**
     * 검색 모드 변환
     */
    searchModeToggle() : void {

        // 그룹, 팀
        if( this.selected_target == null ) {
            this.group_team_search_mode = !this.group_team_search_mode;

            if( this.group_team_search_mode == false ) this.group_team_search_query = "";
        }
        
        // 멤버
        if( this.selected_target != null ) {
            this.member_search_mode = !this.member_search_mode;

            if( this.member_search_mode == false ) this.member_search_query = "";
        }
    }

    /**
     * 이미지 에러 발생시
     */
    imageError(event : any, color : string) : void {
        let temp_class : string = "";
        switch( this.hodu_hex_color_process(color).toUpperCase() ) {
            /**
             * dc - 진한색
             */
            case '#FF6363':
                temp_class = 'imgDc0';
                break;

            case '#FFA70E':
                temp_class = 'imgDc1';
                break;

            case '#FFC72F':
                temp_class = 'imgDc2';
                break;

            case '#FF198B':
                temp_class = 'imgDc3';
                break;

            case '#00B2C7':
                temp_class = 'imgDc4';
                break;

            case '#13D08B':
                temp_class = 'imgDc5';
                break;

            case '#4DBAFF':
                temp_class = 'imgDc6';
                break;

            case '#477FFF':
                temp_class = 'imgDc7';
                break;

            case '#6854FF':
                temp_class = 'imgDc8';
                break;

            case '#35405A':
                temp_class = 'imgDc9';
                break;
            
            /**
             * lc - 옅은색 (현재 그룹에 옅은색은 없지만 대비용으로 만들어 놓음)
             */
            case '#B9A494':
                temp_class = 'imgLc0';
                break;

            case '#E67D9B':
                temp_class = 'imgLc1';
                break;

            case '#FF9898':
                temp_class = 'imgLc2';
                break;

            case '#AB8DD6':
                temp_class = 'imgLc3';
                break;

            case '#F3AC77':
                temp_class = 'imgLc4';
                break;

            case '#E3D37A':
                temp_class = 'imgLc5';
                break;

            case '#8DD6A0':
                temp_class = 'imgLc6';
                break;

            case '#7197ED':
                temp_class = 'imgLc7';
                break;

            case '#8DAED6':
                temp_class = 'imgLc8';
                break;

            case '#6F7A93':
                temp_class = 'imgLc9';
                break;

            default:
                temp_class = 'imgDc7';
                break;

        }

        $(event.target).parent().find('.img').addClass(temp_class);
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 그룹의 팀 리스트 ON / OFF
     */
    teamListOnOff(group : any) : void {
        const group_copy = JSON.parse(JSON.stringify(group));
        
        if( group_copy.list_on == null ) group_copy.list_on = true;
        else group_copy.list_on = !group_copy.list_on;

        const index = this.target_list.indexOf(group);
        if( index > -1 ) this.target_list.splice(index, 1, group_copy);
    }

    /**
     * 타겟 선택
     */
    selectTarget(target : any) : void {
        this.selected_target = target;
    }

    /**
     * 그룹 선택
     */
    groupSelect(event : any, group : any) : void {
        const key = group.group_id;

        const checked : boolean = event.target.checked;
        const index = this.group_ids.indexOf(key);

        if( checked ) {

            if( index < 0 ) {
                this.group_ids.push(key);
            }
            
            // 그룹 멤버 전체 선택
            for( const member of group.group_member ) {
                const member_copy = JSON.parse(JSON.stringify(member));
                member_copy.checked = true;
                group.group_member.splice(group.group_member.indexOf(member), 1, member_copy);
            }
        }

        else if( !checked ) {

            if( index > - 1 ) {
                this.group_ids.splice(index, 1);
            }

            // 그룹 멤버 전체 선택 해제
            for( const member of group.group_member ) {
                const member_copy = JSON.parse(JSON.stringify(member));
                member_copy.checked = false;
                group.group_member.splice(group.group_member.indexOf(member), 1, member_copy);
            }
        }

        // user_ids 에서 item.split('-')[0] == group.group_id 인 녀석들을 전부 제거해야함
        const temp = this.user_ids.filter(item => item.split('-')[0] != group.group_id && item.split('-')[1] == '0');
        this.user_ids.splice(0, this.user_ids.length);
        this.user_ids = this.user_ids.concat(temp);
    }

    /**
     * 팀 선택
     */
    teamSelect(event : any, team : any) : void {
        const key = `${team.group_id}-${team.team_id}`;

        const checked : boolean = event.target.checked;
        const index = this.team_ids.indexOf(key);

        if( checked ) {

            if( index < 0 ) {
                this.team_ids.push(key);
            }

            // 팀 멤버 전체 선택
            for( const member of team.team_member ) {
                const member_copy = JSON.parse(JSON.stringify(member));
                member_copy.checked = true;
                team.team_member.splice(team.team_member.indexOf(member), 1, member_copy);
            }
        }

        else if( !checked && index > -1 ) {
            this.team_ids.splice(index, 1);
            
            // 팀 멤버 전체 선택 해제
            for( const member of team.team_member ) {
                const member_copy = JSON.parse(JSON.stringify(member));
                member_copy.checked = false;
                team.team_member.splice(team.team_member.indexOf(member), 1, member_copy);
            }
        }

        // user_ids 에서 item.split('-')[1] == team.team_id 인 녀석들을 전부 제거해야함
        const temp = this.user_ids.filter(item => item.split('-')[1] != team.team_id);
        this.user_ids.splice(0, this.user_ids.length);
        this.user_ids = this.user_ids.concat(temp);
    }

    /**
     * 멤버 선택
     */
    async memberSelect() : Promise<void> {

        const team_id = this.selected_target.team_id != null ? this.selected_target.team_id : 0;

        // 그룹 또는 팀 멤버가 전체 선택됐다면 group_ids, team_ids에 추가 && user_ids 정리
        if( this.isMemberAllChecked() ) {

            if( team_id > 0 ) {
                const team_key = `${this.selected_target.group_id}-${team_id}`;
                const index = this.team_ids.indexOf(team_key);
                if( index < 0 ) this.team_ids.push(team_key);
            }
            
            else {
                const group_key = this.selected_target.group_id;
                const index = this.group_ids.indexOf(group_key);
                if( index < 0 ) this.group_ids.push(group_key);
            }

            for( const member of (this.selected_target.team_member ?? this.selected_target.group_member) ) {
                const user_key = `${this.selected_target.group_id}-${team_id}-${member.user_id}`;
                const index = this.user_ids.indexOf(user_key);

                if( index > -1 ) this.user_ids.splice(index, 1);
            }
        }

        // 전부 선택된게 아니라면 user_ids에 추가, group_ids 또는 team_ids에서 제거
        else {
            for( const member of (this.selected_target.team_member ?? this.selected_target.group_member) ) {
                const user_key = `${this.selected_target.group_id}-${team_id}-${member.user_id}`;
                const index = this.user_ids.indexOf(user_key);
                if( index < 0 && member.checked ) this.user_ids.push(user_key);
                else if( index > -1 && !member.checked ) this.user_ids.splice(index, 1);
            }

            if( team_id > 0 ) {
                const team_key = `${this.selected_target.group_id}-${team_id}`;
                const index = this.team_ids.indexOf(team_key);
                if( index > -1 ) this.team_ids.splice(index, 1);
            }

            else {
                const group_key = this.selected_target.group_id;
                const index = this.group_ids.indexOf(group_key);
                if( index > -1 ) this.group_ids.splice(index, 1);
            }
        }

        if( team_id > 0 ) {
            // team_ids item.split('-')[1] == team_id 또는 user_ids의 item.split('-')[1] == team_id 인게 하나라도 있다면 true 아니라면 false
            const team_count = this.team_ids.filter(team_key => team_key.split('-')[1] == team_id).length;
            const user_count = this.user_ids.filter(user_key => user_key.split('-')[1] == team_id).length;
            this.selected_target.checked = team_count + user_count > 0;
        }

        else {
            // group_ids item == group_id 또는 user_ids의 item.split('-')[0] == group_id 인게 하나라도 있다면 true 아니라면 false
            const group_count = this.group_ids.filter(group_key => group_key == this.selected_target.group_id).length;
            const user_count = this.user_ids.filter(user_key => user_key.split('-')[0] == this.selected_target.group_id && user_key.split('-')[1] == '0').length;
            this.selected_target.checked = group_count + user_count > 0;
        }
    }

    /**
     * 전체 멤버 선택 / 선택 해제
     */
    selectAllMember(event : any) : void {
        const checked = event.target.checked;
        const team_id = this.selected_target.team_id != null ? this.selected_target.team_id : 0;

        for( const member of ( ( team_id > 0 ) 
                               ? this.selected_target.team_member 
                               : this.selected_target.group_member) ) {
                
            const member_copy = JSON.parse(JSON.stringify(member));
            member_copy.checked = checked;
            
            if( team_id > 0 ) this.selected_target.team_member.splice(this.selected_target.team_member.indexOf(member), 1, member_copy);
            else this.selected_target.group_member.splice(this.selected_target.group_member.indexOf(member), 1, member_copy);
        }
    }

    /**
     * 선택된 수
     */
    getSelectedMemberCount() : number {

        let member_check_count : number = 0;

        if( this.selected_target == null ) return 0;

        if( (this.selected_target.team_id ?? 0) > 0 ) {
            member_check_count = this.selected_target.team_member.filter(item => item.checked == true).length;
        }

        else {
            member_check_count = this.selected_target.group_member.filter(item => item.checked == true).length;
        }

        return member_check_count; 
    }
    
    /**
     * 멤버 전체가 선택되어 있는가 반환
     */
    isMemberAllChecked() : boolean {

        let member_all_checked : boolean = false;

        if( this.selected_target == null ) return false;

        if( (this.selected_target.team_id ?? 0) > 0 ) {
            member_all_checked = this.selected_target.team_member.length == this.getSelectedMemberCount();
        }

        else {
            member_all_checked = this.selected_target.group_member.length == this.getSelectedMemberCount();
        }

        return member_all_checked;
    }

    /**
     * 그룹, 팀 정렬 
     */
    groupTeamSort(sort_type : string) : void {
        if( this.group_team_sort_type != sort_type ) {
            this.group_team_sort_type = sort_type;
            this.group_team_sort_direction = 'ASC';
            return;
        }

        this.group_team_sort_direction = this.group_team_sort_direction == 'ASC' ? 'DESC' : 'ASC';
    }

    /**
     * 멤버 정렬
     */
    memberSort(sort_type : string) : void {
        if( this.member_sort_type != sort_type ) {
            this.member_sort_type = sort_type;
            this.member_sort_direction = 'ASC';
            return;
        }

        this.member_sort_direction = this.member_sort_direction == 'ASC' ? 'DESC' : 'ASC';
    }

    /**
     * 취소 클릭
     */
    async cancel() : Promise<void> {

        if( this.selected_target != null ) {

            const group_id = this.selected_target.group_id;
            const team_id = this.selected_target.team_id != null ? this.selected_target.team_id : 0;

            // checked 정리
            if( team_id > 0 ) {

                for( const member of this.selected_target.team_member ) {
                    const member_copy = JSON.parse(JSON.stringify(member));

                    // team 이 선택됨 or user가 선택됨
                    const index_team = this.team_ids.indexOf(`${member.group_id}-${member.team_id}`);
                    const index_user = this.user_ids.indexOf(`${member.group_id}-${member.team_id}-${member.user_id}`);

                    member_copy.checked = index_team > -1 || index_user > - 1;
                    this.selected_target.team_member.splice(this.selected_target.team_member.indexOf(member), 1, member_copy);
                }
            }

            else {
                for( const member of this.selected_target.group_member ) {
                    const member_copy = JSON.parse(JSON.stringify(member));

                    // group 이 선택됨 or user가 선택됨
                    const index_group = this.group_ids.indexOf(member.group_id);
                    const index_user = this.user_ids.indexOf(`${member.group_id}-${member.team_id}-${member.user_id}`);

                    member_copy.checked = index_group > -1 || index_user > - 1;
                    this.selected_target.group_member.splice(this.selected_target.group_member.indexOf(member), 1, member_copy);
                }
            }

            this.selected_target = null;
            this.member_search_query = "";
            this.member_search_mode = false;
            return;
        }

        this.closeModal();
    }

    /**
     * 공유 or 확인
     */
    async share() : Promise<void> {

        if( this.selected_target != null ) {
            await this.memberSelect();
            await this.cancel();
        }

        // 최종 가공
        else {
            for( const user_key of this.user_ids ) {
                const group_id = Number(user_key.split('-')[0]);
                const team_id = Number(user_key.split('-')[1]);
                const user_id = Number(user_key.split('-')[2]);

                const groups = this.target_list.filter(group => group.group_id == group_id);

                if( groups.length < 1 ) continue;

                if( team_id > 0 ) {
                    const teams = groups[0].teams.filter(team => team.team_id == team_id);

                    if( teams.length < 1 ) continue;

                    const members = teams[0].team_member.filter(member => member.user_id == user_id);
                    
                    if( members.length < 1 ) continue;

                    this.user_info_map.set(members[0].user_email, members[0].user_name);
                }

                else {
                    const members = groups[0].group_member.filter(member => member.user_id == user_id);
                    
                    if( members.length < 1 ) continue;

                    this.user_info_map.set(members[0].user_email, members[0].user_name);
                }
            }

            for( const group_id of this.group_ids ) {
                const groups = this.target_list.filter(group => group.group_id == group_id);

                if( groups.length < 1 ) continue;

                for( const member of groups[0].group_member ) {
                    this.user_info_map.set(member.user_email, member.user_name);
                }
            }

            for( const team_key of this.team_ids ) {
                const group_id = Number(team_key.split('-')[0]);
                const team_id = Number(team_key.split('-')[1]);

                const groups = this.target_list.filter(group => group.group_id == group_id);

                if( groups.length < 1 ) continue;

                const teams = groups[0].teams.filter(team => team.team_id == team_id);

                if( teams.length < 1 ) continue;

                for( const member of teams[0].team_member ) {
                    this.user_info_map.set(member.user_email, member.user_name);
                }
            }
            
            // console.log(this.user_info_map);
            // console.log(JSON.stringify(this.user_info_map));
        
            const user_info_array : any[] = [];
            for( const [email, name] of this.user_info_map ) {
                user_info_array.push({ email, name });
            }

            // console.log(user_info_array);
            // console.log(encodeURIComponent(JSON.stringify(user_info_array)));

            let is_all_day = false;
            if( this.event_email_share_modal_info.end.getFullYear() == this.event_email_share_modal_info.start.getFullYear() &&
                this.event_email_share_modal_info.end.getMonth()    == this.event_email_share_modal_info.start.getMonth()    &&
                this.event_email_share_modal_info.end.getDate()     == this.event_email_share_modal_info.start.getDate() ) {
                
                let event_time = this.event_email_share_modal_info.end.getTime() - this.event_email_share_modal_info.start.getTime();

                // 23시간 59분 차이가 난다면 
                let all_day_time = (23 * 60 * 60 * 1000) + (59 * 60 * 1000);

                if( event_time >= all_day_time ) is_all_day = true;
            }

            const start = encodeURIComponent(`${this.hodu_date_to_format_string(this.event_email_share_modal_info.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.event_email_share_modal_info.start, '요일')}${ !is_all_day ? ` ${this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(this.event_email_share_modal_info.start, 'hh:mm a'))}` : '' }`);
            const end = encodeURIComponent(`${this.hodu_date_to_format_string(this.event_email_share_modal_info.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.event_email_share_modal_info.end, '요일')}${ !is_all_day ? ` ${this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(this.event_email_share_modal_info.end, 'hh:mm a'))}` : '' }`);
            const start_timestamp = this.event_email_share_modal_info.start.getTime();
            const end_timestamp = this.event_email_share_modal_info.end.getTime();

            const title = this.event_email_share_modal_info.title;

            let user_info_query = encodeURIComponent(JSON.stringify(user_info_array));
            let query : string = `event_id=${this.event_email_share_modal_info.event_id}&scope=${this.event_email_share_modal_info.scope}&scope_id=${this.event_email_share_modal_info.scope_id}&start=${start}&start_timestamp=${start_timestamp}&end=${end}&end_timestamp=${end_timestamp}&title=${title}&sender_email=${this.event_email_share_modal_info.sender_email}&user_info=${user_info_query}`;
            window.open(`/mail_share.html?&${query}`, "hodu_email_share", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);

            await this.cancel();
            return;
        }
    }

    /**
     * 모달 종료
     */
    closeModal() : void {
        this.doSetEventEmailShareModalInfo?.({ show_modal : false, calendar_id : "", event_id : "", scope : "", scope_id : 0, start : new Date(), end : new Date()});
    }

}
</script>

<style scoped>
    /* 모달 공통 css */
    .modal { position:absolute; z-index:10000000000000000000000000000000; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}
    
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}
    .titleBox .total { float:left; font-size:14px; font-weight:bold; margin-left:15px;margin-top:2px; }
    .titleBox .total span { color:#477fff; padding-left:5px;}
    .titleBox .total.team {  }
    .modal .btns {border-top:1px solid #f1f3f5; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; }
    .modal .schBtn { position:absolute; right:0;top:20px;right:30px; }
    .modal .schGrp {display:none;transition:0.1s;position: absolute;top: 110px;z-index: 100000000;width: 100%;height: 60px;background: white;top: 89px;left: 0;}
    .modal .schGrp.on { display:block; }
    .modal .schGrp input {transition:0.1s;background:transparent;width: 100%;box-sizing: border-box;height: 100%;line-height: 60px;padding: 0 100px 0 30px;font-size: 14px;font-weight: bold;}
    .modal .schGrp:hover { background:#f1f3f5; }
    .modal .schGrp input:focus { background:#fff;border-bottom:1px solid #477fff; height:60px;line-height:61px; }
    .modal .schGrp a.schBtn { top:0px; }

    .modal .noResult { display:none; position: absolute; z-index: 100000;top: 150px;height: 316px;background: #fff;line-height: 316px;font-weight: bold;font-size: 21px;color: #afb6c3;}

    /* .magBtns */
    .magBtns {  overflow: hidden; }
    .magBtns input { line-height: 60px; height: 60px; width: 50%; font-weight: bold; background:#fff; box-sizing: border-box; border-bottom: 2px solid #e7e9ea; }
    .magBtns input.on { border-color:#477fff }

    /* 공유모달 css */
    .shareModal .sortHeader { position:relative; }
    .sortHeader li.grpCheck { position: absolute;left: 0;top: 0;width: 100px;text-align: center;font-weight: bold;font-size: 13px;}
    .shareModal { transition:0.3s; max-width: 800px; bottom:auto !important; margin-top: -268px !important;height: 536px !important;}
    .shareUlDiv {height: auto;display: block;width: 100%;height: 316px;max-height: 316px;overflow:hidden; overflow-y:auto;}
    .shareUlDiv ul {display: block;width:100%;}
    .shareUlDiv li .grp {width: 100%;transition:0.1s;height: 55px;line-height: 55px;border-bottom: 1px solid #f1f3f5; position:relative;}
    .shareUlDiv li .grp:hover { background:#f1f3f5; }
    .shareUlDiv li .grp * {display: inline-block;float:left;}
    .shareUlDiv li .grp:hover .count a { border:2px solid #477fff; border-radius: 5px; padding:0 10px; line-height:30px; height:30px;margin-top:11px; }
    .shareUlDiv li .grp .count.noCount { opacity:0.6 }
    .shareUlDiv li .count a { transition:0.1s; }
    .shareUlDiv li .count a:hover { background:#477fff; color:#fff; }
    .shareUlDiv li .count a:hover span { color:#fff; }
    .shareUlDiv li .img {width: 57px;font-size: 0; background-position:center center !important; background-size: cover !important;height: 42px;border-radius: 5px;border: 1px solid #f1f3f5;position: absolute;left: 180px;top: 7px;}
    .shareUlDiv .teamUl li  .img {width: 50px;height: 36px;top: 9px;left: 186px;}
    .shareUlDiv li h2 {font-size: 14px;}
    .shareUlDiv li .nm { width:64%;box-sizing:border-box; padding-left:120px; padding-right:20px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
    .shareUlDiv li .count { width:10%;font-weight:bold; }
    .shareUlDiv li .count span { color:#477fff; margin-right:4px; }
    .shareUlDiv li.withTeams a.showTeams {position: absolute;right: 20px;top: 0;font-size: 0;width: 35px;height: 60px;border-radius: 50%; background:  url('../../../assets/images/side_area/bt_open.png') no-repeat center center;}
    .shareUlDiv li a.showTeams.on, .shareUlDiv li.on a.showTeams {background:  url('../../../assets/images/side_area/bt_close.png') no-repeat center center;  }
    .shareUlDiv .teamUl { display:none; border-top:1px solid #f1f3f5;border-bottom:1px solid #f1f3f5;}
    .shareUlDiv li.on > .grp {  }
    .shareUlDiv li.on .teamUl { display:block; }
    .shareModal input[type='checkbox']:not(old) + label {position: absolute;left: 38px;top: 17px;z-index:10000000; }
    .shareModal .teamUl input[type='checkbox']:not(old) + label{left: 50px;}
    .shareModal input[type='checkbox']:not(old) + label, .shareModal input[type='checkbox']:not(old) + label:hover {margin-left: 5px;display: inline-block;  padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
    .shareModal input[type='checkbox']:not(old):checked + label { border:2px solid #477fff;background: #477fff url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;}
    .grpUl > li > .grp { height:60px; line-height:60px; }
    .grpUl > li > .grp .img  { top:8px; }
    .grpUl > li > .grp:hover > .count > a { margin-top:14px; }
    .grpUl > li > .grp input[type='checkbox']:not(old) + label { top: 19px }
    .shareUlDiv li .grp .cl {position: absolute;left: 151px;top: 26px;width: 8px;height: 8px;font-size: 0;border-radius: 50%;background: #f1f3f5;}
    .shareUlDiv li .teamUl .grp .cl { left:158px; }
    .shareModal .teamUl .num {padding-left: 90px;}

    .grpHeader .num { width:15%;  }
    .grpHeader .nm {padding-left: 14px;width: 64%;box-sizing: border-box;}
    .grpHeader .count {width: 19%;}

    .teamHeader { display:none; }
    .shareModal.peepsOn ul.grpUl {height: 447px;}
    .shareModal.peepsOn { margin-top: -268px !important; height: 536px !important;}
    .shareModal.peepsOn .teamHeader { display:block; }
    .shareModal.peepsOn .grpHeader { display:none; }
    .shareModal .peepsDiv { display:none;width:100%;position: absolute;background: #fff;z-index: 1000000000;top: 150px; }
    .shareModal .peepsDiv.on {display: block;}
    .shareModal .peepsDiv .peepsUlDiv { width: 100%;height: 316px;max-height: 316px;overflow: hidden;overflow-y: auto;}
    .shareModal ul.peeps li * {display: inline-block;}
    .shareModal ul.peeps li {transition:0.1s;position:relative;line-height: 50px;height: 50px;font-size: 13px;font-weight: bold;border-bottom:1px solid #f1f3f5;}
    .shareModal ul.peeps li .fPic {position:absolute;background-color:#f1f3f5; left:155px; top:10px;font-size: 0;width: 30px;height: 30px;border-radius: 50%;background-position: center center !important;background-size: cover !important;border: 1px solid #e7e9ea;}
    .shareModal ul.peeps li .pNm { padding-left:70px; padding-right:20px; width:40%; box-sizing:border-box; overflow : hidden; white-space: nowrap; text-overflow: ellipsis; }
    .shareModal ul.peeps li .pos { font-size:12px; overflow : hidden; white-space: nowrap; text-overflow: ellipsis; }
    .shareModal ul.peeps li:hover { background:#f1f3f5; }
    .shareModal .peeps input[type='checkbox']:not(old) + label { top:14px; }
    .shareModal .peeps .fPic.none { background-image : url(../../../assets/images/contents/im_photoB.gif) !important; }

    .shareModal .pNm {  width:40%; box-sizing:border-box;    padding-left: 20px;  }
    .shareModal .num {font-weight:bold;padding-left: 80px;width: 17%; box-sizing:border-box; text-align: center;font-size:12px; overflow : hidden;}
    .shareModal .peeps input[type='checkbox']:not(old) + label:after {content: '';display: block;width: 800px;height: 50px;position: absolute;left: -45px;top: -16px;}
    
    
    .teamHeader .pos {width: 43%;}
    .sortHeader input[type='checkbox']:not(old) + label { top:19px;}

    .shareUlDiv.mCS_destroyed { overflow : hidden !important }

    .share_option_btn { position : absolute; float : right; right : 80px; top : 30px; }
    .share_option_btn.on { color : white !important; background-color : #477fff !important; }
    .share_option_btn.option_share { right : 210px; }
    .share_option_btn.option_copy { right : 145px; }
    .share_option_btn.option_move {}

    .img_dummy { display : none !important; } 

    /* 추후삭제 */
    /* .grpUl > li:nth-child(1) .img { background-image: url(../../../assets/images/footer/g4.jpg) }
    .teamUl > li:nth-child(1) .img { background-image: url(../../../assets/images/footer/g1.jpg) }
    .teamUl > li:nth-child(2) .img { background-image: url(../../../assets/images/footer/g2.jpg) }
    .teamUl > li:nth-child(3) .img { background-image: url(../../../assets/images/footer/g6.jpg) }
    .teamUl > li:nth-child(4) .img { background-image: url(../../../assets/images/footer/g10.jpg) }
    .teamUl > li:nth-child(5) .img { background-image: url(../../../assets/images/footer/g11.jpg) }
    .grpUl > li:nth-child(2) .img { background-image: url(../../../assets/images/footer/g7.jpg) }

    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(1) .fPic { background-image:  url(../../../assets/images/footer/f1.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(2) .fPic { background-image:  url(../../../assets/images/footer/f2.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(3) .fPic { background-image:  url(../../../assets/images/footer/f3.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(4) .fPic { background-image:  url(../../../assets/images/footer/f4.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(5) .fPic { background-image:  url(../../../assets/images/footer/f5.jpg)  } */
</style>