<template>
    <!-- 성공 addClass(success), 실패 addClass(cancel), 경고 addClass(alert)  -->
    <!-- 동기화 체크 박스 has_check 클래스 추가 -->
    <div class="msgModal modal" :class="`${message_modal_info.message_type} ${message_modal_info.has_check == true ? 'has_check' : ''}`">
        <div class="content">
            <div class="main">
                <p class="icon">아이콘</p>
                <h1 style="white-space : pre-line;">{{ message_modal_info.message }}</h1>

                <div class="synchBox">
                    <input type="checkbox" id="check_psw" v-model="check_flag">
                    <label for="check_psw"><span>{{ message_modal_info.check_text }}</span></label>
                </div>

            </div>
            <!-- 상황 따라 버튼 변경, 버튼이 2개 일때 클래스 추가 b2 & 3개 일때 b3  -->
            <div class="btns" :class="{ b2 : message_modal_info.button_text.length == 2, b3 : message_modal_info.button_text.length == 3}">
                
                <a class="close firstButton" @click.once="firstButtonClick" @keydown="firstButtonEnterKey($event)">
                    {{ message_modal_info.button_text[0] == null ? "" : message_modal_info.button_text[0] }}
                </a>

                <a @click.once="secondButtonClick" v-if="message_modal_info.button_text[1] != null">
                    {{ message_modal_info.button_text[1] == null ? "" : message_modal_info.button_text[1] }}
                </a>

                <a @click.once="thirdButtonClick" v-if="message_modal_info.button_text[2] != null">
                    {{ message_modal_info.button_text[2] == null ? "" : message_modal_info.button_text[2] }}
                </a>

            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

@Component({

}) export default class MessageModal extends Mixins(VueHoduCommon) {

    is_closeable_by_enter : boolean = false;
    check_flag : boolean = false;

    beforeMount() : void {
        // Modal 띄우기 전 기존 포커스 없애기
        let element : Element | null = document.activeElement
        if( element != null ) { $(element).blur(); }
    }

    mounted() {
        this.$nextTick(() => {
            // 버튼이 1개라면 0.5초 정도 기다린 이후 엔터를 누르면 닫히도록 설정
            if( this.message_modal_info.button_text != null && this.message_modal_info.button_text.length == 1 ) {
                setTimeout(() => {
                    this.is_closeable_by_enter = true; 
                    window.addEventListener("keydown", this.firstButtonEnterKey);
                }, 500);
            }
        });
    }

    beforeDestroy() {
        window.removeEventListener("keydown", this.firstButtonEnterKey);
    }

    /**
     * 왼쪽에서부터 첫번째 버튼 클릭 
     */
    async firstButtonClick() : Promise<void> {
        await this.doSetMessageModalInfo({
            "show_message_modal" : false,
            "message_type" : "",
            "message" : "",
            "button_text" : []
        });

        await window['messageModalFirstButtonClick'](this.check_flag);
    }

    /**
     * 왼쪽에서부터 두번째 버튼 클릭
     */
    async secondButtonClick() : Promise<void> {
        await this.doSetMessageModalInfo({
            "show_message_modal" : false,
            "message_type" : "",
            "message" : "",
            "button_text" : []
        });

        await window['messageModalSecondButtonClick'](this.check_flag);
    }

    /**
     * 왼쪽에서부터 세번째 버튼 클릭
     */
    async thirdButtonClick() : Promise<void> {
        await this.doSetMessageModalInfo({
            "show_message_modal" : false,
            "message_type" : "",
            "message" : "",
            "button_text" : []
        });

        await window['messageModalThirdButtonClick'](this.check_flag);
    }

    /**
     * 버튼이 1개일때 엔터키를 눌렀다면 자동으로 닫히도록
     */
    async firstButtonEnterKey(event : any) {
        if( event.keyCode != 13 || this.is_closeable_by_enter == false ) { return; }
        
        this.doSetMessageModalInfo({
            "show_message_modal" : false,
            "message_type" : "",
            "message" : "",
            "button_text" : []
        });
        
        await window['messageModalFirstButtonClick'](this.check_flag);
    }

}
</script>

<style scoped>
    .modal { text-align:center; width: 500px; height:293px; border-radius:5px;z-index: 99999999999;left: 50%;margin-left: -250px;top: 50%;margin-top: -146px;position: absolute;background: #fff;  }
    .modal .main { border-bottom:3px solid #477fff; position:relative; background:#f1f3f5; height:220px;width:100%; border-radius:5px 5px 0 0 }
    .modal.alert .main { border-bottom:3px solid #ffc72f;;  }
    .modal.cancel .main { border-bottom:3px solid #ff6060;  }
    .modal .main h1 { font-size:17px; padding-top:160px }
    .modal .main .icon { display:block; font-size:0; left:50%; top:50px; margin-left: -40px; width:80px; height:80px; position:absolute; z-index:10; border-radius:50%; background-color:#fff; background-size:50px !important; background-position:center center; background-repeat:no-repeat }
    .modal.success .main .icon { background-image: url(../assets/images/contents/img_success.png); }
    .modal.alert .main .icon { background-image: url(../assets/images/contents/img_alert.png); }
    .modal.cancel .main .icon { background-image: url(../assets/images/contents/img_cancel.png); }
    .modal.tipping_set .main .icon { background-image: url(../assets/images/contents/img_tipping.png); }
    .modal .btns a { transition:0.2s; display:inline-block; float:left; height:70px; line-height:70px; font-weight:bold;font-size:14px; width:100%; }
    .modal .btns a:hover { background:#f1f3f5; color:#477fff; }
    .modal .btns a.close:hover { color:#ff6060; }
    .modal .btns.b2 a { width:50%; }
    .modal .btns.b3 a { width:33.3333%; }

    /* 동기화 체크박스 */
    .modal.has_check { height: 340px; margin-top: -170px; }
    .modal.has_check .main { height: 267px; }
    .modal .synchBox { display: none; }
    .modal.has_check .synchBox { display: flex; }
    .synchBox { display: flex; justify-content: center; align-items: center; height: 50px; margin-top: 18px; padding-right: 95px; position: relative; box-sizing: border-box; }
    .modal.commonInputModal.has_check .detail .titleDiv input[type='checkbox']:not(old) { text-align: right; height: 30px; margin: 0; padding: 0; opacity: 0; width: 0; }
    .has_check input[type='checkbox']:not(old)+label { padding: 0; width: 20px; height: 20px; border-radius: 3px; background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 0px 0px; background-size: 21px; }
    .has_check input[type='checkbox']:checked +label { background-color: #477fff; }
    .has_check input[type='checkbox']:not(old)+label span { display: inline-block; width: 85px; margin-left: 30px; margin-top: 2px; font-weight: bold; }

</style>