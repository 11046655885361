<template>
    <div class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">호두 공지사항</h3>
        </div>

        <div id="noticeScroll" class="section_scroll">
            <div class="viewGroup">         
                <div class="schedule_box">
                    <input type="button" class="verTwoNotice" @click="ckeditor4()" value="작성" v-if="admin_flag"/>
                </div>
                <div class="fixedDiv">
                    <ul class="sortHeader hoduNoticeHeader" id="">
                        <li class="notiCheck">
                            <p class="notiCheck on" id="notiCheckAll">
                                <input type="checkbox" id="">
                                <label for="notiCheckAll">전체선택</label>
                            </p>
                        </li>
                        <li class="num">No</li>
                        <li class="title headerSort" @click="subjectSort"
                            :class="{
                                sortOff  : notice_sort_target != 'SUBJECT',
                                sortUp   : notice_sort_target == 'SUBJECT' && notice_sort_direction == 'ASC', 
                                sortDown : notice_sort_target == 'SUBJECT' && notice_sort_direction == 'DESC', 
                            }"><span>제목</span></li>
                        <li class="writtenBy"><span>작성자</span></li>
                        <li class="time headerSort" @click="timeSort"
                            :class="{
                                sortOff  : notice_sort_target != 'TIME',
                                sortUp   : notice_sort_target == 'TIME' && notice_sort_direction == 'ASC', 
                                sortDown : notice_sort_target == 'TIME' && notice_sort_direction == 'DESC', 
                            }"><span>작성일</span></li>
                        <li class="status"><span>읽은사람</span></li>
                    </ul>
                </div>
                <!-- content 스크롤 -->
                <div class="content" id="group_notice_scroll">
                    <ul class="notiListUl">
                        <li :class="{
                            new: !lo_data.read_flag
                            }" v-bind:key="index" v-for="(lo_data, index) in notice_data">
                            <!-- <p class="notiCheck">
                                <input type="checkbox" id="notiCheck01" value="" class="input_check">
                                <label for="notiCheck01"></label>
                            </p> -->
                            <p class="num"><span>{{index+1}}</span></p>
                            <p class="title">
                                <span>{{lo_data.noti_info.title}}</span>
                                <i class="noticeFile_icon" v-if="lo_data.noti_info.files != null && lo_data.noti_info.files.length > 0">파일첨부여부 icon</i>
                            </p>
                            <p class="writtenBy"><span>호두 관리자</span></p>
                            <p class="time"><span>{{lo_data.noti_info.start_date}}</span></p>
                            <p class="status"><a><span>{{lo_data.read_cnt}}</span> 명</a></p> 
                            <a class="seeNotice" title="상세보기" @click.prevent="detailNotice(lo_data)">상세보기</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>  
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

import moment from 'moment';

import { namespace } from 'vuex-class';
const EtcInfo    = namespace('EtcInfo');
const dateFormat = require('dateformat');

@Component({
    components: {
        ResizeObserver
    },
})
export default class HoduNoticeList extends Mixins(VueHoduCommon) {
    notice_data          : any     = [];
    original_notice_data : any     = [];
    admin_flag           : boolean = false;

    notice_sort_target : string | null = null;
    notice_sort_direction : string = "ASC";

    @EtcInfo.Action doHoduNotiUid ?: any;

    async mounted() : Promise<void> {
        const vue = this;
        
        this.setScroll();

        this.admin_flag = await this.getAdminCheck();

        // 공지사항 조회
        this.getNotice();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();
        const scrollHeight     : number | undefined = window.innerHeight - ( titleHeight ? titleHeight : 0 ) - ( settingBgHeight ? settingBgHeight : 0 ) - ( sortHeaderHeight ? sortHeaderHeight : 0 );

        // @ts-ignore
        $('#group_notice_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : scrollHeight,
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 그룹 공지사항 조회
     */
    async getNotice() : Promise<void> {
        this.notice_data = [];

        await this.hodu_api_call(`api/v1/notification/notice/0/0/HODU?page=0&size=0&current_date=${moment(new Date()).utc().format()}`, API_METHOD.GET, null)
            .then(async(response) => {
                console.log(response);
                this.notice_data = response.data.data.list;

                $.each(this.notice_data, function(key, data) {
                    data.noti_info.start_date = dateFormat(data.noti_info.start_date, 'yyyy.mm.dd');
                });

                this.original_notice_data = JSON.parse(JSON.stringify(this.notice_data));
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 공지사항 상세보기
     */
    async detailNotice(po_data) : Promise<void> {
        const vue = this;

        // 공지사항 읽음여부
        if ( !po_data.read_flag ) {
            await this.hodu_api_call(`api/v1/notification/insertRead/${po_data.noti_uid}`, API_METHOD.PUT, {}, false)
                .then(async(response) => {
                    vue.goNoticeDetail(po_data);
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }
        else {
            vue.goNoticeDetail(po_data);
        }
    }    

    async goNoticeDetail(po_data) : Promise<void> {
        this.doHoduNotiUid(po_data.noti_uid);
        this.hodu_router_push(`/hodu/${new Date().getTime()}/notice_detail`);
    }

    ckeditor4() : void {
        this.doHoduNotiUid("");
        this.hodu_router_push(`/hodu/${new Date().getTime()}/notice_create`);
    }

    /**
     * 제목 기준 정렬
     */
    subjectSort() : void {
        // 이미 SUBJECT Sort 중이라면
        if( this.notice_sort_target == "SUBJECT" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // SUBJECT Sort가 아니였다면
        else {
            this.notice_sort_target = "SUBJECT";
            this.notice_sort_direction = "ASC";
        }

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * 작성일 기준 정렬
     */
    timeSort() : void {
        // 이미 TIME Sort 중이라면
        if( this.notice_sort_target == "TIME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // TIME Sort가 아니였다면
        else {
            this.notice_sort_target = "TIME";
            this.notice_sort_direction = "ASC";
        }

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.notice_data = JSON.parse(JSON.stringify(this.original_notice_data));
    }

    /**
     * 공지사항 정렬
     */
    noticeListSort() : void {
        this.notice_data.sort((o1 : any, o2 : any) : number => {

            // 제목 오름차순
            if( this.notice_sort_target == "SUBJECT" && this.notice_sort_direction == "ASC" ) {
                if( o1.noti_info.title  > o2.noti_info.title ) { return  1; } 
                if( o1.noti_info.title  < o2.noti_info.title ) { return -1; } 
                if( o1.noti_info.title == o2.noti_info.title ) { return  0; }
            }

            // 제목 내림차순
            if( this.notice_sort_target == "SUBJECT" && this.notice_sort_direction == "DESC" ) {
                if( o1.noti_info.title  > o2.noti_info.title ) { return -1; } 
                if( o1.noti_info.title  < o2.noti_info.title ) { return  1; } 
                if( o1.noti_info.title == o2.noti_info.title ) { return  0; }
            }

            // 작성일 오름차순
            if( this.notice_sort_target == "TIME" && this.notice_sort_direction == "ASC" ) {
                if( new Date(o1.noti_info.start_date).getTime()  > new Date(o2.noti_info.start_date).getTime() ) { return  1; } 
                if( new Date(o1.noti_info.start_date).getTime()  < new Date(o2.noti_info.start_date).getTime() ) { return -1; } 
                if( new Date(o1.noti_info.start_date).getTime() == new Date(o2.noti_info.start_date).getTime() ) { return  0; }
            }

            // 작성일 내림차순
            if( this.notice_sort_target == "TIME" && this.notice_sort_direction == "DESC" ) {
                if( new Date(o1.noti_info.start_date).getTime()  > new Date(o2.noti_info.start_date).getTime() ) { return -1; } 
                if( new Date(o1.noti_info.start_date).getTime()  < new Date(o2.noti_info.start_date).getTime() ) { return  1; } 
                if( new Date(o1.noti_info.start_date).getTime() == new Date(o2.noti_info.start_date).getTime() ) { return  0; }
            }

            return 0;
        })
    }
    
    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#group_notice_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }
}
</script>

<style scoped>
  .schLi .schBtn { right:30px; }
  a#addNotice {position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 1110000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(../assets/images/contents/btn_add_fff.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;}
  #noticeScroll .btn_group { margin-right:30px; }
  .section_scroll .delBtn { display:none; }
  .section_scroll.edit .delBtn { display:inline-block }
  #noticeScroll .sortHeader input[type='checkbox']:not(old) + label { margin-top:18px; }
  #noticeScroll input[type='checkbox']:not(old) + label {margin-top: 16px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius:3px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
  #noticeScroll input[type='checkbox']:not(old):checked + label {background: #477fff url('../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;border: 2px solid #477fff;}
  h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
  .settingBg ul.dropdown {width: 170px;left: 280px;top: 60px;}
  .viewGroup { overflow:hidden; }
  #container #section_ce .schedule_box {padding: 0;height:0;}
  #noticeScroll .sortHeader {margin-top: 0}
  .section_scroll .sortHeader .notiCheck { display:none; }
  .section_scroll.edit .sortHeader .notiCheck {height:60px; line-height:60px; font-size:0;display: inline-block; }
  .section_scroll.edit .sortHeader li.notiCheck { position:absolute; left:0;width:130px;top:0; }
  .section_scroll .sortHeader { position:relative;box-sizing:border-box; }
  .section_scroll.edit .sortHeader { padding-left:130px; }
  .section_scroll .notiCheck { width:8%; text-align:center; }
  .section_scroll .sortHeader p.notiCheck { text-align: center;width:100%;}
  .section_scroll .num { width:10%; text-align:center; font-weight:bold; text-index: -20px;}
  .section_scroll.edit .num { text-indent: -60px; }
  .section_scroll .group { width:16%; }
  .section_scroll .title { width: 31%;}
  .section_scroll .time { width:14%; }
  .section_scroll .writtenBy { width:17%; }
  .section_scroll .status { width:12%; }
  .content  { float:left; width:100%; }
  .content .notiListUl > li.new:after {content: 'NEW';display: block;position: absolute;top: 17px;left: 10%;font-size: 10px;font-weight: bold;background: #ff6060;height: 20px;line-height: 20px;border-radius: 5px;padding: 0 5px;color: #fff;}
  .content .notiListUl > li.new .title {padding-left: 45px;}
  .content .notiListUl > li p {display: inline-block;font-weight: bold;float: left;box-sizing: border-box;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
  .content .notiListUl > li p.num, .content .notiListUl > li p.notiCheck { padding-right:0; }
  .content .notiListUl > li { transition:0.2s; }
  .content .notiListUl > li.new:hover { background:#f1f3f5; }
  .content .notiListUl > li p.status a span { color:#477fff; }
  .content .notiListUl > li:hover a { border:2px solid #477fff; padding:0 10px; line-height:29px; height:30px;display:inline-block; color:#477fff; border-radius:5px; }
  .content .notiListUl > li:hover p span { color:#273044 }
  .content .notiListUl > li:hover p.status a:hover { color:#fff; background:#477fff; }
  .content .notiListUl > li:hover p.status a:hover span { color:#fff; }
  .content .notiListUl > li p.status {position: relative;height: 55px;}
  .content .notiListUl > li p.status a {position: absolute;left: 0;opacity: 1;top: 0;z-index: 10000000;display: block;}
  .content .notiListUl > li:hover p.status a {top: 10px;}
  .content .notiListUl > li p span {color: #7c88a2;}
    .content .notiListUl > li.new span {color: #273044;}
    .content .notiListUl { margin-bottom:150px; }
  a.seeNotice {position: absolute;line-height: 55px !important;height: 55px !important;top: 0;right: 0;width: 90%;display: block;border: none !important;z-index: 1000;font-size: 0 !important;background: transparent;}
  a.seeNotice:hover {background: none !important;border: none !important;}
  .content .notiListUl > li { box-sizing:border-box; position:relative; width:100%; border-bottom:1px solid #f1f3f5;height: 55px;line-height: 55px;}
  .section_scroll.edit .content .notiListUl > li {padding-left:130px; }
  .section_scroll.edit .content .notiListUl > li p.notiCheck {text-align: center;position:absolute; left:0;width:130px;top:0;    display: inline-block; }
    .section_scroll .content .notiListUl > li p.notiCheck { display:none; }
    

    .verOneNotice, .verTwoNotice {position: fixed; cursor:pointer;bottom: 40px;right: 100px;opacity: 1;z-index: 1110000000000000000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(../assets/images/contents/btn_add_fff.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;}
    /* 추후 삭제 */
    .verTwoNotice { right:200px; font-size:12px !important;font-weight:bold; color:#fff !important; background-image:none !important; }
    .verOneNotice { font-size:12px !important;font-weight:bold; color:#fff !important; background-image:none !important;  }

    /* 21.07.23 파일첨부 */
    .content .notiListUl > li p .noticeFile_icon { font-size: 0; background: url('../assets/images/contents/ic_file_bk.png') no-repeat center; width: 18px; height: 18px; background-size: 100% auto; display: inline-block; vertical-align: middle; margin-left: 10px; opacity: .6; }
    .content .notiListUl > li:hover .noticeFile_icon { opacity: 1; }
</style>