<template>
    <div id="organization_work_type_detail_modal" class="modal">
        <div class="titleBox">
            <h1 :title="computedWorkType ? computedWorkType.work_type_name : ''">{{ computedWorkType ? computedWorkType.work_type_name : '' }}</h1>
            <p class="beacon" v-if="is_visible_beacon_auto">비콘 사용시 <span class="flag">{{ computedWorkType ? computedWorkType.is_beacon_auto == true ? '자동' : '수동' : '' }}</span> 출근</p>
        </div>
        <div class="content">
            <div class="leftContent">
                <ul class="weekUl" v-if="work_type != null">

                    <li :class="{ offFt : day.is_telecommuting == false && !day.am_from && !day.pm_from }" :key="day.week" v-for="day in work_type.contents">
                        <p class="indi" :class="{
                            home : day.is_telecommuting == true, 
                            morn : day.is_telecommuting == false && day.am_from && !day.pm_from,
                            night : day.is_telecommuting == false && !day.am_from && day.pm_from,
                            allday : day.is_telecommuting == false && day.am_from && day.pm_from,
                            off : day.is_telecommuting == false && !day.am_from && !day.pm_from,
                        }">
                            <span>{{ day.is_telecommuting == true ? '재택' : !day.am_from && !day.pm_from ? '휴무' : '근무' }}</span>
                        </p>
                        <p class="day">{{ getDayName(day.week, '요일') }}</p>
                        <p class="beacon">{{ day.is_telecommuting == false && !day.am_from && !day.pm_from ? '-' : day.is_beacon_use == true ? '비콘 사용' : '비콘 사용 안함' }}</p>
                        <ul class="mornNightUl">
                            <li :class="{ offFt : !day.am_from }">
                                <!-- <p class="morn">오전</p> -->
                                <p class="time">{{ (!day.am_from || !day.am_to) ? '-' : `${processTimeText(day.am_from)} ~ ${processTimeText(day.am_to)}` }}</p>
                            </li>
                            <li :class="{ offFt : !day.pm_from }">
                                <!-- <p class="night">오후</p> -->
                                <p class="time">{{ (!day.pm_from || !day.pm_to) ? '-' : `${processTimeText(day.pm_from)} ~ ${processTimeText(day.pm_to)}` }}</p>
                            </li>
                        </ul>
                    </li>

                    <!-- <li>
                        <p class="indi morn"><span>근무</span></p>
                        <p class="day">일요일</p>
                        <ul class="mornNightUl">
                            <li>
                                <p class="morn">오전</p>
                                <p class="time">09:00 ~ 12:30</p>
                            </li>
                            <li class="offFt">
                                <p class="night">오후</p>
                                <p class="time">-</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p class="indi night"><span>근무</span></p>
                        <p class="day">월요일</p>
                        <ul class="mornNightUl">
                            <li class="offFt">
                                <p class="morn">오전</p>
                                <p class="time">-</p>
                            </li>
                            <li>
                                <p class="night">오후</p>
                                <p class="time">01:30 ~ 06:00</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p class="indi allday"><span>근무</span></p>
                        <p class="day">화요일</p>
                        <ul class="mornNightUl">
                            <li>
                                <p class="morn">오전</p>
                                <p class="time">09:00 ~ 12:30</p>
                            </li>
                            <li>
                                <p class="night">오후</p>
                                <p class="time">01:30 ~ 06:00</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p class="indi allday"><span>근무</span></p>
                        <p class="day">수요일</p>
                        <ul class="mornNightUl">
                            <li>
                                <p class="morn">오전</p>
                                <p class="time">09:00 ~ 12:30</p>
                            </li>
                            <li>
                                <p class="night">오후</p>
                                <p class="time">01:30 ~ 6:00</p>
                            </li>
                        </ul>
                    </li>
                    <li class="offFt">
                        <p class="indi off"><span>휴무</span></p>
                        <p class="day">목요일</p>
                        <ul class="mornNightUl">
                            <li>
                                <p class="morn">오전</p>
                                <p class="time">-</p>
                            </li>
                            <li>
                                <p class="night">오후</p>
                                <p class="time">-</p>
                            </li>
                        </ul>
                    </li>
                    <li class="offFt">
                        <p class="indi off"><span>휴무</span></p>
                        <p class="day">금요일</p>
                        <ul class="mornNightUl">
                            <li>
                                <p class="morn">오전</p>
                                <p class="time">-</p>
                            </li>
                            <li>
                                <p class="night">오후</p>
                                <p class="time">-</p>
                            </li>
                        </ul>
                    </li>
                    <li class="offFt">
                        <p class="indi off"><span>휴무</span></p>
                        <p class="day">토요일</p>
                        <ul class="mornNightUl">
                            <li>
                                <p class="morn">오전</p>
                                <p class="time">-</p>
                            </li>
                            <li>
                                <p class="night">오후</p>
                                <p class="time">-</p>
                            </li>
                        </ul>
                    </li> -->
                </ul>
            </div>
            <div class="rightContent">
                <div class="schDiv">
                    <div class="titleDiv">
                        <p class="right_title">현재 <span>{{ computedWorkType ? computedWorkType.employees.length : 0 }}</span>명이 사용 중</p>
                        <!-- 클릭시 하단 searchDiv on추가, .cont > .memUl 사용하여 리스트 뿌리기 -->
                        <!-- <a class="schBtn">검색하기</a> -->
                    </div>
                    <!-- <div class="searchDiv">
                        <div class="schGrp">
                            <input type="text" name="" value="" placeholder="직원을 검색하세요">
                        </div>
                        <a class="closeBtn">닫기</a>
                    </div> -->
                </div>
                
                <div id="organization_work_type_detail_modal_emp_scroll" class="cont" v-if="work_type != null">
                    <div class="noResult" v-if="computedWorkType.employees.length < 1">
                        <p>해당 근무 타입을 사용하는 직원이 없습니다</p>
                    </div>
                    <!-- 이 방식대로 검색시 사람 뿌리기  -->
                    <ul class="memUl">

                        <li :key="employee.user_id" v-for="employee in computedWorkType.employees">
                            <img class="dummy" :src="getProfileImage(employee)" @error="userImageError($event)" />
                            <p class="img" :style="{ backgroundImage : `url(${getProfileImage(employee)})` }">사진</p>
                            <p class="name">{{ `${employee.user_name} (${getPositionName(employee.pos_id)})` }}</p>
                            <p class="grp">{{ getDepartmentName(employee.dept_id) }}</p>
                        </li>

                        <!--코딩 수정 프로그램 작업 부분-->
                        <!--<li :key="employee.user_id" v-for="employee in computedWorkType.employees" style="overflow: scroll; display: block; padding:0px; padding-left:20px; height: auto; line-height: 0px;">
                            <div style="overflow-x:auto;">
                            <table style="width:100%;">
                                    <tr style="width:15%;">
                                        <th rowspan="2">
                                            <img class="dummy" :src="`app_images/profile/user/${Math.floor(employee.user_id / 10000)}/${employee.user_id}.jpg`" @error="userImageError($event)" />
                                            <p class="img" :style="{ backgroundImage : `url('app_images/profile/user/${Math.floor(employee.user_id / 10000)}/${employee.user_id}.jpg')` }">사진</p>
                                        </th>
                                    </tr>
                                    <tr style="width:85%;">
                                        <td class="name" style="width:100%;">{{ `${employee.user_name} (${getPositionName(employee.pos_id)})` }}</td>
                                        <td class="grp" style="width:288px; height: auto;">
                                            {{ getDepartmentName(employee.dept_id) }}
                                        </td>
                                    </tr>
                            </table>
                            /div-->

                        <!-- <li> -->
                            <!-- <p class="img">사진</p> -->
                            <!-- <p class="name">김성림</p> -->
                            <!-- <p class="grp"><span class="dot dc0"></span>그룹이름</p> -->
                            <!-- on은 삭제, on빼면 추가 -->
                            <!-- <a href="#" class="on">삭제</a> -->
                        <!-- </li> -->
                        <!-- <li> -->
                            <!-- <p class="img">사진</p> -->
                            <!-- <p class="name">김성림</p> -->
                            <!-- <p class="grp"><span class="dot dc0"></span>그룹이름</p> -->
                            <!-- <a href="#" class="on">삭제</a> -->
                        <!-- </li> -->
                        <!-- <li> -->
                            <!-- <p class="img">사진</p> -->
                            <!-- <p class="name">김성림</p> -->
                            <!-- <p class="grp"><span class="dot dc0"></span>그룹이름</p> -->
                            <!-- 추가 예제 -->
                            <!-- <a href="#" class="">추가</a> -->
                        <!-- </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="btns" :class="{ b1 : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') || work_type == null || work_type.work_type_id == 1 }">
            <!-- <input type="button" value="취소" > -->
            <input type="button" value="닫기" @click.prevent="close">
            <input type="button" value="삭제" @click.prevent="del" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') && work_type != null && work_type.work_type_id != 1">
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationWorkTypeDetailModal extends Mixins(VueHoduCommon) {

    get computedWorkType() : any {
        if( this.work_type == null ) return null;

        let work_type = JSON.parse(JSON.stringify(this.work_type));

        if( work_type.employees == null ) work_type.employees = []; 

        work_type.employees.splice(0, work_type.employees.length);
        work_type.employees = work_type.employees.concat(this.employees.filter(employee => employee.work_type_id == work_type.work_type_id));
        work_type.employees.sort((o1, o2) : number => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            // 같은 직급은 이름순
            if( o1_pos_seq == o2_pos_seq ) {
                if( o1_name > o2_name ) return 1;
                else if( o1_name < o2_name ) return -1;
                return 0
            }
            else if( o1_pos_seq > o2_pos_seq ) return  1;
            else if( o1_pos_seq < o2_pos_seq ) return -1;

            return 0;
        });

        return work_type;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_work_type_detail_modal_info !: organization_modal_interface.OrganizationWorkTypeDetailModalInfo;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationWorkTypeDetailModalInfo ?: (params : organization_modal_interface.OrganizationWorkTypeDetailModalInfo) => void;

    work_type : any = null;
    employees : any[] = [];
    departments : any[] = [];
    position : any[] = [];

    is_visible_beacon_auto : boolean = false;
    
    async mounted() : Promise<void> {
        // $("#organization_work_type_detail_modal .rightContent .schDiv .titleDiv .schBtn").click(function(){
        //     $("#organization_work_type_detail_modal .rightContent .searchDiv").addClass("on");

        // });

        // $("#organization_work_type_detail_modal .rightContent .searchDiv .closeBtn").click(function(){
        //     $("#organization_work_type_detail_modal .rightContent .searchDiv").removeClass("on");
        // });
        
        console.log(this.organization_work_type_detail_modal_info);
        await this.getTotalOrganizationData();
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganization();
            await this.getOrganizationPosition();
            await this.getOrganizationWorktype();
            await this.getOrganizationEmp();
        } catch(e) {
            alert("조직도 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 근무 타입 조회
     */
    async getOrganizationWorktype() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype?work_type_id=${this.organization_work_type_detail_modal_info.work_type_id}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_type_info ) {
                throw new Error("조직도 근무 타입 조회 중 오류 발생");
            }

            this.work_type = JSON.parse(JSON.stringify(response.data.data.work_type_info));
            
            this.is_visible_beacon_auto = this.work_type.contents.filter(content => content.is_beacon_use == true).length > 0;

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);
        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 이름 반환
     * level 1 : 최상위 부서 이름 반환
     * level 2 : 최상위 부서 바로 아래의 부서 이름 하나만 반환
     * level n (n > 2) : level 2 / level 3 / level 4 ... 
     */
    getDepartmentName(dept_id : number) : string {
        try {
            
            if( dept_id == 0 ) return "미배정";
            
            let dept_name = "";
            const target = this.departments.filter(item => item.dept_id == dept_id)[0];

            let target_departments : any[] = [];
            for( const department of this.departments ) {
                if( target.dept_id_array.indexOf(department.dept_id) > -1 ) target_departments.push(department);
            }

            target_departments.sort((o1, o2) : number => {
                const o1_length = o1.dept_id_array.length;
                const o2_length = o2.dept_id_array.length;

                if( o1_length > o2_length ) return 1;
                else if( o1_length < o2_length ) return -1;
                return 0;
            });

            for( const department of target_departments ) {
                if( target_departments.length >= 2 && target_departments.indexOf(department) == 0 ) continue;

                if( dept_name.length > 0 ) dept_name += " / ";
                dept_name += department.dept_name;
            }
        
            return dept_name;
        } catch(e) {
            console.log(e);
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_taget.parent().find('p.img').css('background-image', `url(${replace_image})`);
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationWorkTypeDetailModalInfo?.({ show_modal : false, work_type_id : 0 });
    }

    /**
     * 근무타입 삭제
     */
    async del() : Promise<void> {
        if( confirm("정말로 근무타입을 삭제하시겠습니까?") == false ) return;
        
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype/${this.organization_work_type_detail_modal_info.work_type_id}`, API_METHOD.DELETE);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error('근무타입 삭제 중 오류 발생');
            }

            this.organization_work_type_detail_modal_info.callback?.();
            this.close();
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("근무타입 삭제 중 오류 발생");
        }
        
    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-308.5px; margin-left:-512px; width:100%; border-radius:5px; max-width: 1024px;  height:610px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    .modal h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7eae7; padding-right : 170px; text-overflow: ellipsis; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .modal .title {font-size: 15px;font-weight: bold;display: block;margin-bottom: 20px;margin-top: 0;}
    
    .modal .titleBox { position: relative; }
    .modal .titleBox .beacon { position: absolute; right: 30px; top: 0; height: 100%; display: inline-block; line-height: 95px; font-size: 14px; font-weight: bold; }
    .modal .titleBox .beacon .flag { color : #477fff; }

    .setTimeUl .title span { font-size: 13px; margin-left:5px; opacity:0.7 }
    .setTimeUl { padding: 0 40px;  max-width: 100%; box-sizing: border-box; width:100%; margin-bottom: 30px; padding-top: 20px;}
    .setTimeUl li:first-child { position:relative; overflow:hidden;  }
    .setTimeUl .title { font-size: 15px; font-weight: bold; display: block; margin-bottom: 15px;    margin-top: 10px; }
    .setTimeUl > ul > li { overflow: hidden; position:relative;}
    .setTimeUl > ul > li > input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; }
    .setTimeUl > ul > li > input:hover { background-color:#f1f3f5; }
    .setTimeUl > ul > li > input:focus { background-color:#fff; border-color:#477fff; }
    .setTimeUl > ul > li > span { padding-right:20px; }
    .setTimeUl .descript { position: absolute; right:0; top:10px; }
    .setTimeUl .descript li { overflow:hidden; display:inline-block;margin-right:20px; }
    .setTimeUl .descript li:last-child { margin-right:0; }
    .setTimeUl .descript li .clr { float:left; margin-right:5px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; }
    .setTimeUl .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .setTimeUl .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .setTimeUl .descript li .clr.allday {background: #477fff !important;border-color: #477fff;}
    .setTimeUl .descript li .clr.off { background: #a1a5bb !important;border-color: #a1a5bb;}

    .contractUl { border-left:1px solid #e7e9ea; border-top: 1px solid #e7e9ea }
    .contractUl li {transition:0.2s;overflow:hidden; position:relative; font-size: 12px; line-height: 50px; height:50px; border-bottom:1px solid #e7e9ea; }
    .contractUl li:hover { border-color:#477fff; }
    .contractUl li a { display:inline-block; width:100%; height: 100%; }
    .contractUl li a:hover { background:#f7f9fb; font-weight:bold; }
    .contractUl li .day { font-weight:bold; display:inline-block;text-align: center; width:7%; font-size: 12px;box-sizing:border-box; padding-left:25px; }
    .closeP {border-left: 5px solid #f1f3f5; }
    .contractUl li p { transition:0.2s; float:left;    box-sizing: border-box; display:inline-block !important; width: 10%; text-align: center; border-right:1px solid #e7e9ea }
    .contractUl li .sp { width:4.5% }
    .contractUl select {transition:0.2s;font-size: 12px; float:left; cursor:pointer; display: inline-block; appearance: none; border-right:1px solid #e7e9ea; width: 14.23%;text-align:center; box-sizing: border-box; padding:0 20px; line-height: 50px; height:50px; }
    .contractUl select {background: url(../../../assets/images/side_area/bt_open.png) no-repeat 90% center; }
    .contractUl select option { font-size: 14px;}
    .contractUl select:hover { background-color:#f1f3f5; }
    .contractUl select:focus { background-color:#fff; background-image: url(../../../assets/images/side_area/bt_close.png) }

    .contractUl .indi {position:absolute; top: 20px;left: 20px;display: inline-block;width: 7px;height: 7px; border-radius: 50%; font-size:0;border:1px solid; font-weight: bold; text-align: center; color:#fff; } 
    .contractUl .indi.morn {background: #ffc00e !important;border-color: #f3b300;}
    .contractUl .indi.night {background: #846eff !important;border-color: #6c52ff;}
    .contractUl .indi.allday {background: #477fff !important;border-color: #477fff;}
    .contractUl .indi.off { background: #a1a5bb !important;border-color: #a1a5bb;}

    .shiftDiv:hover .openP, .shiftDiv:hover .closeP, .shiftDiv:hover select { font-weight:bold; }

    .inbetweenDiv { position:relative;padding-top:22px; }
    .inbetweenDiv label { position: absolute;top:0;font-size: 12px; }
    .inbetweenDiv select { appearance: none;transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 40px; line-height: 40px; min-width: 150px; font-weight: bold; border-radius: 5px;  margin-right: 30px; width: 100px;text-align: center;background:  url('../../../assets/images/side_area/bt_open.png') no-repeat 91% center; }
    option { font-size: 14px }

    .btns { overflow: hidden; border-top:1px solid #e7eae7;border-radius: 0 0 5px 5px }
    .btns input { line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold; }
    .btns.b1 input { width:100%; }
    .btns.b3 input { width:33.3333333333%; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }

    #organization_work_type_detail_modal { margin-top:-279px; height:558px}
    #organization_work_type_detail_modal .noResult { position: static; padding: 15px 20px; }
    #organization_work_type_detail_modal .noResult p { height: 55px; background:#f1f3f5; line-height: 55px; color:#7c88a2 }

    #organization_work_type_detail_modal .content { overflow: hidden;height: 396px; }
    .leftContent { float: left; width: 60%; height: 100%; box-sizing: border-box;}

    .weekUl { overflow: hidden; }
    .weekUl > li {display: inline-block;width: 100%; line-height: 56px; height: 56px; border-bottom: 1px solid #e7eae7; float: left;}
    .weekUl > li:last-child { border-bottom: 0 none;;  }
    .weekUl > li * { display: inline-block; }
    .weekUl > li * { font-weight: bold; }

    .weekUl .indi { float: left;width: 15%; text-align: center; }
    .weekUl .indi span { display:inline-block; border-radius: 20px; height: 19px; line-height: 19px; background:#f1f3f5; border:1px solid; font-weight: bold; padding:0 13px; }
    .weekUl .indi.home span { background : #13D08B; border-color: #13D08B; color:#fff; }
    .weekUl .indi.morn span { background: #ffc00e;border-color: #f3b300; } 
    .weekUl .indi.night span { background: #846eff; border-color: #6c52ff; color:#fff }
    .weekUl .indi.allday span { background: #477fff; border-color: #477fff; color:#fff }
    .weekUl .indi.off span { background: #fff;border-color: #e5e6ec; color:#a1a5bb}
    .weekUl .day { float: left;width:15%; }
    .weekUl > li .day { border-left: 1px solid #f1f3f5;box-sizing: border-box; text-align: center; }
    .weekUl .beacon { float: left;width:15%; }
    .weekUl > li .beacon { border-left: 1px solid #f1f3f5;box-sizing: border-box; text-align: center; }

    .offFt { color:#a1a5bb !important; background-color:#f6f9fb }

    .mornNightUl { float: left; width: 55% }
    .mornNightUl li { width: 50%; }
    /* .mornNightUl li * { float: left; width: 65%; box-sizing: border-box; text-align: center;} */
    .mornNightUl li * { float: left; width: 100%; box-sizing: border-box; text-align: center; border-left: 1px solid #e7eae7; }
    .mornNightUl li .morn, .mornNightUl li .night { width: 35%; border-left: 1px solid #e7eae7; border-right: 1px solid #e7eae7; text-align: center;  }

    

    .rightContent { float: left; width: 40%; height: 100%; box-sizing: border-box;; border-left: 1px solid #e7e9ea;}

    .rightContent .schDiv { position: relative; height: 56px; line-height: 56px; background: #fff; }
    .rightContent .schDiv .titleDiv { padding: 0 20px; box-sizing: border-box; border-bottom: 1px solid #e7e9ea; }
    .rightContent .schDiv .titleDiv .right_title { font-size: 14px; font-weight: bold;;   }
    .rightContent .schDiv .titleDiv .right_title span { color:#477fff }
    .rightContent .schDiv .titleDiv .schBtn { position: absolute; right: 15px; top: 0; width: 35px; height: 35px; }
    .rightContent .searchDiv { width: 100%; height: 57px; line-height: 57px; background: #fff; }
    .rightContent .searchDiv .schGrp input { float:left; padding-left: 20px; font-size: 13px; height: 57px; line-height: 57px; border-width:1px}
    .rightContent .searchDiv .closeBtn { z-index: 300000; position: absolute; right: 0; top:0; margin-right: 15px; width: 35px; height: 35px;; }
    .rightContent .cont .memUl li { position: relative; height: 65px; line-height: 65px; border-bottom: 1px solid #f1f3f5; padding:0 20px; box-sizing: border-box; }
    .rightContent .cont .memUl li * { display: inline-block; float: left; }
    .rightContent .cont .memUl li .img { display: inline-block; width: 32px; height: 32px; border-radius: 50%; border:1px solid #f1f3f5; font-size: 0; margin-top:14px; margin-right: 13px; background-size: cover; background-position: center center; background-repeat: no-repeat; }
    .rightContent .cont .memUl li .name { display: block; line-height: 46px;height: 32.5px; width: 70%; }
    .rightContent .cont .memUl li .grp { display: block; width: 70%;height: 32.5px; line-height: 20px; white-space: nowrap; }
    .rightContent .cont .memUl li .grp .dot {  display: inline-block; float: left; width: 8px; height: 8px; border-radius: 50%; margin-top:6px; margin-right: 8px; }
    .rightContent .cont .memUl li a {transition: 0.2s; position: absolute; right:0; top:0; width: 50px; height: 100%; display: block;  font-size: 0; background-image: url('../../../assets/images/contents/ic_plus_new.png'); background-position: center center; background-repeat: no-repeat; }
    .rightContent .cont .memUl li a:hover { background-color:#f1f3f5; opacity: 1 !important; }
    .rightContent .cont .memUl li a.on {  opacity: 0; background-image: url('../../../assets/images/contents/ic_x_new.png'); }
    .rightContent .cont .memUl li:hover a.on { opacity: 0.7; }

    img.dummy { display : none !important; width : 1px; height : 1px; }

    #organization_work_type_detail_modal_emp_scroll { width : 409px; height : 340px; overflow: auto; }

    ::-webkit-scrollbar{width: 6px; height : 6px; }
    ::-webkit-scrollbar-track{background-color: transparent;}
    ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
</style>