<template>
    <div id="common_message_modal" class="modal common_message_modal">

        <div class="titleBox">
            <h1 class="borderbot">알림</h1>
        </div>

        <div class="modal_content">
            <img class="expirationImg" src="../../../assets/images/popup/img_hodupremium_expiration.png" alt="이미지">
            <h1 class="messageTitle">호두 <span>프리미엄 기간만료</span> 안내</h1>
            <p class="startDate">(최초 사용일 : <span>{{ hodu_date_to_format_string(common_premium_expire_modal_info.data.premium_audit_created, "YYYY.MM.DD") }}</span>)</p>
            <p class="messageCon">
                안녕하세요. 항상 호두를 사랑해주셔서 감사합니다.<br><br>
                호두를 사랑해주시는 여러분께<br>
                현재 사용 중이신 '프리미엄' 서비스의 사용기간이<br>
                만료되어 안내드립니다.<br><br>
                프리미엄 서비스를 연장하시려면 아래로 문의 부탁드립니다.<br>
                더 좋은 서비스로 보답하는 호두가 되겠습니다.
            </p>
            <ul class="serviceTelMail">
                <li>문의전화 <span>02-411-1161</span></li>
                <li>문의메일 <span>hoduwareco@gmail.com</span></li>
            </ul>
            
        </div>

        <div class="closeTime">
            <p>자동 닫기 남은시간 <span class="timeSeconds"> {{ timer_offset }}</span>초</p>
        </div>
        <!-- <div class="btns">
            <input type="button" value="닫기">
        </div> -->

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo    = namespace('ModalInfo');

import { CommonPremiumExpireModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class CommonPremiumExpireModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State common_premium_expire_modal_info !: CommonPremiumExpireModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetCommonPremiumExpireModalInfo ?: (params : CommonPremiumExpireModalInfo) => void;

    timer_offset : number = 7;
    interval : number | undefined = undefined;

    mounted() : void {

        window.setTimeout(() => {

            this.interval = window.setInterval(() => {
                this.timer_offset -= 1;

                if( this.timer_offset < 0 && this.interval != null ) {
                    window.clearInterval(this.interval);
                }

            }, 1000);
            
        }, 1000);
        
    }

    beforeDestroy() {
        if( this.interval != null ) {
            window.clearInterval(this.interval);
        }
    }

    @Watch("timer_offset")
    watchTimerOffset() : void {
        if( this.timer_offset < 0 ) this.close();
    }

    close() : void {
        this.doSetCommonPremiumExpireModalInfo?.({ show_modal : false, data : {} });
    }

}
</script>

<style scoped>
    #common_message_modal { position: absolute; left: 50%; top: 50%; width: 100%; height: 505px; margin-left: -278px; margin-top: -250px; max-width: 557px; background: #fff; border-radius: 5px; }

    #common_message_modal.modal .titleBox { width : 100%; overflow:hidden; border-bottom: 0; padding: 25px 25px; box-sizing: border-box; box-shadow: none !important; line-height: inherit; border-bottom: 1px solid #e7e9ea; text-align: left;}
    #common_message_modal.modal .titleBox h1 {font-size: 18px;}

    #common_message_modal.modal .modal_content { text-align: center; font-size: 14px; padding: 15px 15px; box-sizing: border-box; }
    #common_message_modal.modal .modal_content .expirationImg { width: 100px; height: auto; overflow: hidden; }
    #common_message_modal.modal .modal_content .messageTitle { font-size: 16px; line-height: 30px; }
    #common_message_modal.modal .modal_content .messageTitle > span { color: #ff6363; }
    #common_message_modal.modal .modal_content .startDate,
    #common_message_modal.modal .modal_content .startDate > span { color: #477fff; }
    #common_message_modal.modal .modal_content .messageCon { margin-top: 15px; line-height: 20px; }
    #common_message_modal.modal .modal_content .serviceTelMail { width: 450px; height: 50px; margin: 0 auto; margin-top: 10px; background: #f1f3f5; }
    #common_message_modal.modal .modal_content .serviceTelMail li { width: 60%; line-height: 50px; display: inline-block; text-align: center; font-weight: bold; }
    #common_message_modal.modal .modal_content .serviceTelMail li:first-child { width: 40%; }
    #common_message_modal.modal .closeTime { width: 100%; overflow: hidden; box-sizing: border-box; text-align: center; line-height: 20px; font-weight: bold; }
    #common_message_modal.modal .closeTime .timeSeconds { font-size: 15px; margin-left: 5px; }

    #common_message_modal.modal .btns { width : 100%; overflow: hidden; border-top:1px solid #e7e9ea; border-radius: 0 0 5px 5px }
    #common_message_modal.modal .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:100%; float: left; font-size:14px; font-weight: bold; }
</style>