<template>
    <div class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <!-- 친구/멤버추가만 할 경우 a태그만 사용 -->
        <!-- <a href="#" class="input_btn" :class="{on: b_openInvite}" id="inviteMate" v-if="b_isInvite && !isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '')" @click.prevent="groupMemberInvite()">추가</a> -->

        <!-- 기존 푸시알림 and 친구/멤버 추가 사용할 경우 -->
        <!-- <div class="bt_scheduleBox" :class="{ on : open_invite_menu == true }" v-if="b_isInvite" >
            <div class="inbox inviteMateMenu">
                <div class="event_btn">
                    <input type="button" title="가져오기" class="btn_invite_import" @click.prevent="groupMemberImportExcel" v-if="isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') == false">
                </div>
                <div class="event_btn">
                    <input type="button" title="카카오톡으로 초대하기" id="kakao_link_btn" class="btn_invite_kakao" @click.prevent="kakaoShare">
                </div>
                <div class="event_btn">
                    <input type="button" title="호두 친구/그룹 초대하기" class="btn_invite_frd" :class="{on: b_openInvite}" @click.prevent="groupMemberInvite">
                </div>
            </div>

            <input type="button" id="inviteMate" class="bt_schedule" :class="{on: b_openInvite}" @click.prevent="() => { open_invite_menu = !open_invite_menu }">
        </div> -->

        <div class="title_box" style="">

            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">
                {{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '입주자' : '멤버' }}
            </h3>

            <a class="schBtn" v-if="!isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '')">검색하기</a>

        </div>

        <!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
        <div class="searchDiv">
            <div class="schGrp">
                <a href="#" class="bt_option" @click.prevent="groupMemberSearchOption()"><span class="txt">검색옵션</span></a>
                <input type="text" id="group_member_search" placeholder="멤버를 검색하세요" @input="member_search_query = $event.target.value">
                <div class="btns">
                    <!-- 검색 디브 removeClass(on) -->
                    <a class="closeBtn">닫기</a>
                    <!--<a href="#" class="bt_search"><span class="blind">찾기버튼</span></a>-->
                </div>
            </div>
        </div>

        <GroupMemberComp
            :event_bus="event_bus"
            :member_search_query="member_search_query"
            :enable_search="false" />

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, OWNER_TYPE } from '@/mixin/VueHoduCommon';
import { GroupModalInfo, GroupListConfirmModalData, GroupTeamOtherOutModalInfo, GroupTeamOtherOutModalData, CommonInputModalInfo, GroupMemberImportModalInfo } from '@/store/modules/ModalInfo';
import GroupListConfirmModal from '@/components/GroupListConfirmModal.vue';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const GroupInfo    = namespace('GroupInfo');
const ModalInfo    = namespace('ModalInfo');

import GroupMemberComp from '@/components/GroupMemberComp.vue';

import { hodu_home_modal_info } from '@/model/hoduhome';

declare let Kakao : any;
import '@/assets/js/kakao';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {
        GroupMemberComp, GroupListConfirmModal, ResizeObserver
    }
})
export default class GroupMember extends Mixins(VueHoduCommon) {

    event_bus : Vue = new Vue();

    /** 
     * CalendarInfo.Action 
     */ 
    @CalendarInfo.Action doSetStartDate ?: any; // start_date 업데이트
    
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;
    @ModalInfo.State group_modal_info  !: GroupModalInfo;
    @ModalInfo.State group_team_other_out_info !: GroupTeamOtherOutModalInfo;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupModalInfo ?: any;
    @ModalInfo.Action doSetGroupTeamOtherOutModalInfo ?: any;
    @ModalInfo.Action doSetHomeResidentCarRequestModalInfo ?: (params : hodu_home_modal_info.HomeResidentCarRequestModalInfo) => void;
    @ModalInfo.Action doSetGroupMemberImportModalInfo ?: (params : GroupMemberImportModalInfo) => void;

    /**
     * computed
     */
    get computedGroupMember() : any[] {
        const member_array : any[] = JSON.parse(JSON.stringify(this.member_data));

        if( this.member_sort_target != null ) {
            member_array.sort((o1 : any, o2 : any) : number => {
                // 이름 오름차순
                if( this.member_sort_target == "NAME" && this.member_sort_direction == "ASC" ) {

                    if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {

                    }

                    else {
                        if( o1.user_info.user_name  > o2.user_info.user_name ) { return  1; } 
                        if( o1.user_info.user_name  < o2.user_info.user_name ) { return -1; } 
                        if( o1.user_info.user_name == o2.user_info.user_name ) { return  0; }
                    }
                    
                }

                // 이름 내림차순
                if( this.member_sort_target == "NAME" && this.member_sort_direction == "DESC" ) {

                    if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {

                    }

                    else {
                        if( o1.user_info.user_name  > o2.user_info.user_name ) { return -1; } 
                        if( o1.user_info.user_name  < o2.user_info.user_name ) { return  1; } 
                        if( o1.user_info.user_name == o2.user_info.user_name ) { return  0; }
                    }
                }

                // 권한 오름차순
                if( this.member_sort_target == "ROLE" && this.member_sort_direction == "ASC" ) {
                    if( o1.role_level  > o2.role_level ) { return -1; }
                    if( o1.role_level  < o2.role_level ) { return  1; }
                    if( o1.role_level == o2.role_level ) { return  0; }
                }

                // 권한 내림차순
                if( this.member_sort_target == "ROLE" && this.member_sort_direction == "DESC" ) {
                    if( o1.role_level  > o2.role_level ) { return  1; }
                    if( o1.role_level  < o2.role_level ) { return -1; }
                    if( o1.role_level == o2.role_level ) { return  0; }
                }

                return 0;
            });
        }

        if( this.member_search_query != null && this.member_search_query.trim().length > 0 ) {
            return member_array.filter(member => this.hodu_string_includes(member.user_info.user_name, this.member_search_query) ||
                                                 this.hodu_string_includes(member.user_info.user_phone_number, this.member_search_query) ||
                                                 this.hodu_string_includes(member.user_info.user_email, this.member_search_query) );
        }

        return member_array;
    }

    /**
     * 변수 선언부
     */
    user_profile_no_image = require("@/assets/images/contents/im_photoB.gif");
    b_adminTeamModify   : boolean  = false;
    b_isMemberModify    : boolean  = false;
    b_isAuthChange      : boolean  = false;    // 권한설정 버튼
    b_isInvite          : boolean  = false;
    b_openInvite        : boolean  = false;
    member_data         : any      = [];
    appr_cnt            : number   = 0;
    scrollHeight        : number   = 0;
    member_search_query : string   = "";       // 멤버 검색 쿼리

    member_sort_target    : string | null = null; // NAME, ROLE
    member_sort_direction : string = "ASC";       // ASC, DESC

    open_invite_menu : boolean = false;
    kakao_share_init : boolean = false;

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() {
        const vue = this;

        // 검색 버튼 클릭시 searchDiv 띄우기
        $("#section_ce .schBtn").click(function(){
            $("#section_ce .searchDiv").addClass("on");
            vue.$nextTick(() => $('#group_member_search').focus());
        });

        // 검색 닫기 버튼 클릭시 searchDiv 없애기
        $("#section_ce .searchDiv .closeBtn").click(function(){
            $("#section_ce .searchDiv").removeClass("on");
            $("#group_member_search").val("");
            vue.member_search_query = "";
            $("#group_member_search").trigger('keyup');
        });

        // 숫자만 입력가능하도록
        $("#modalMemRegPh").on("keyup", function() {
            // @ts-ignore
            $(this).val($(this).val().replace(/[^0-9]/g,""));
        });

        this.setScroll();
        
		// 멤버추가창 인풋에 wrong 빼기
		$(".inviteMateDiv input").click(function(){
			$(this).removeClass("wrong");
        });
        
		// 멤버추가창 다른 멤버 초대
		$(".inviteMateDiv .btns .inviteMore").click(function(){
            $('#modalMemRegNm').val("");
            $('#modalMemRegPh').val("");
            $(".inviteMateDiv").removeClass("sent");
        });

        $(".inviteMateDiv .btns .close").click(function(){
            $('#modalMemRegNm').val("");
            $('#modalMemRegPh').val("");
            $(".inviteMateDiv").removeClass("sent");
			vue.b_openInvite = false;
        });
        
        // 필터검색
        // $("#group_member_search").keyup(function(event) {
        //     let value = $("#group_member_search").val();
            
        //     if ( value == "" ) {  
        //         $("#group_member_ul li").show();
        //     }
        //     else {
        //         $("#group_member_ul li").hide();
        //         let temp = $("#group_member_ul li div .userName:contains('"+value+"')");
        //         $(temp).parent().parent().show();
        //     }
        // });

        await this.get_group_role_service();

        if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
            await this.residentSearch();
        }

        else {
            await this.getGroupMember();
        }

        window['residentSearch'] = this.residentSearch;
        
        window['group_member_reload'] = async() => {
            if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                await vue.residentSearch();
            }

            else {
                await vue.getGroupMember();
            }
        };
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight : number | undefined = $('.settingBg').outerHeight();
        const headerHeight : number | undefined = $('#group_member_header').outerHeight();

        this.scrollHeight = window.innerHeight - ( titleHeight == null ? 0 : titleHeight) - ( settingBgHeight == null ? 0 : settingBgHeight) - ( headerHeight == null ? 0 : headerHeight);

        // @ts-ignore
        $('#group_member_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : this.scrollHeight
        })
    }

    /**
     * 그룹멤버조회
     */
    async getGroupMember() : Promise<void> {
        this.member_data = [];
        this.appr_cnt    = 0;

        await this.hodu_api_call(`api/v1/groupsweb/getGroupTeamMember/${this.group_id}/${this.team_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.b_adminTeamModify = await this.is_group_permmision(this.group_id, "team", "modify");
                this.b_isMemberModify  = await this.is_permmisionCheck(this.group_id, this.team_id, "member", "modify");
                this.b_isAuthChange    = false;
                
                this.b_isInvite        = await this.is_permmisionCheck(this.group_id, this.team_id, "member", "invite");

                // 호두 D는 일단 임시로 멤버 초대 막음
                if( this.isHoduD(this.scope_group_team_option ? this.scope_group_team_option.biz_type : '') == true ) { this.b_isInvite = false; }

                this.member_data       = response.data.data.list;
                this.appr_cnt          = response.data.data.appr_cnt;

                // 내 권한이 관리자 인지 부관리자 인지 알기위함...
                // 권한설정 버튼 보임 유무 때문에...
                for ( let i = (this.member_data.length - 1); i >= 0; i-- ) {

                    const member : any = JSON.parse(JSON.stringify(this.member_data[i]));

                    // 호두D는 그룹 멤버에 일반 멤버(환자)를 포함시키지 않는다
                    if( this.scope_group_team_option.biz_type == 'BIZD' && member.role == this.GroupAuth.GROUP_USER ) {
                        this.member_data.splice(i, 1);
                        continue;
                    }

                    // dropdown on / off 클래스 
                    member.member_role_on = false;

                    if ( member.user_id == this.user_id ) {
                        member.role_level = ( member.role == this.GroupAuth.GROUP_MANAGER     || member.role == this.GroupAuth.TEAM_MANAGER) ? 1 : 
                                              member.role == this.GroupAuth.GROUP_SUB_MANAGER || member.role == this.GroupAuth.TEAM_USER     ? 2 : 3;
                        this.b_isAuthChange = (this.team_id == 0 ? (member.role == this.GroupAuth.GROUP_MANAGER || member.role == this.GroupAuth.ADMIN) : this.b_adminTeamModify ? true : (member.role == this.GroupAuth.TEAM_MANAGER || member.role == this.GroupAuth.ADMIN));

                        // 호두 D는 권한 설정 불가
                        if( this.isHoduD(this.scope_group_team_option ? this.scope_group_team_option.biz_type : '') == true ) { this.b_isAuthChange = false; }
                    }
                    
                    
                    this.member_data.splice(i, 1, member);
                }

                this.$nextTick(() => this.kakaoShareInit());
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 입주자 검색
     */
    residentSearch() : void {

        this.hodu_api_call(`api/v1/home/resident/${this.scope_group_team_option.biz_id}?team_id=${this.team_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                this.b_adminTeamModify = await this.is_group_permmision(this.group_id, "team", "modify");
                this.b_isMemberModify  = await this.is_permmisionCheck(this.group_id, this.team_id, "member", "modify");
                this.b_isAuthChange    = false;
                
                this.b_isInvite = await this.is_permmisionCheck(this.group_id, this.team_id, "member", "invite");

                for( const resident of response.data.data.resident_list ) {
                    if( resident.cars != null && resident.cars.length > 0 ) {
                        resident.cars = resident.cars.filter(car => car.car_info.state == 'APPROVE');
                    }
                }

                this.member_data.splice(0, this.member_data.length);
                this.member_data = this.member_data.concat(response.data.data.resident_list);

                this.$nextTick(() => this.kakaoShareInit());
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })

    }

    /**
     * 호두홈 입주자 승인
     */
    async approve_resident(index) : Promise<void> {

        try {

            const body = {
                "state" : "APPROVE"
            }

            const response = await this.hodu_api_call(`api/v1/home/resident/${this.scope_group_team_option.biz_id}/${this.member_data[index].audit_user_id}?is_basic=false`, API_METHOD.PUT, body);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("입주자 거절 처리 중 오류 발생");
            }

            if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                this.residentSearch();
            }
                
        } catch(e) {
            this.hodu_show_dialog("cancel", "입주자 승인 처리 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

        
    }

    /**
     * 호두홈 입주자 거절
     */
    async reject_resident(index) : Promise<void> {

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : '거절사유입력',
            subtitle : '거절사유',
            content : '',
            placeholder : '거절사유를 입력해주세요',
            save : async(comment) => {

                try {

                    const body = {
                        "state" : "REJECT",
                        "comment" : comment
                    }
                    
                    const response = await this.hodu_api_call(`api/v1/home/resident/${this.scope_group_team_option.biz_id}/${this.member_data[index].audit_user_id}?is_basic=false`, API_METHOD.PUT, body)

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("입주자 거절 처리 중 오류 발생");
                    }

                    if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                        this.residentSearch();
                    }

                } catch(e) {
                    this.hodu_show_dialog("cancel", "입주자 거절 처리 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            
            }
        });
    }

    /**
     * 권한 체크
     */
    async is_permmisionCheck(pi_groupId, pi_teamId, ps_cate, ps_type) : Promise<boolean> {
        let lb_flag : boolean = false;

        // 그룹 멤버조회 권한
        if ( pi_groupId > 0 && pi_teamId == 0 ) {
            lb_flag = this.is_group_permmision(pi_groupId, ps_cate, ps_type);
        }
        else {
            // 그룹에 관리자 및 팀 수정권한이 있는 사람들은 무조건 통과
            if ( this.b_adminTeamModify ) {
                lb_flag = true;
            }
            else {
                lb_flag = this.is_team_permmision(pi_teamId, ps_cate, ps_type);
            }
        }

        return lb_flag;
    }

    /**
     * 그룹 팀 나가기
     */
    group_team_out() : void {
        let param_data : GroupListConfirmModalData = {
            modal_type       : "my_out",
            type             : (this.team_id > 0 ? 'TEAM' : 'GROUP'),
            group_id         : this.group_id,
            team_id          : this.team_id,
            color            : this.group_team_option.group_team_color,
            image            : this.group_team_option.group_team_image,
            description      : this.group_team_option.group_team_descript,
            name             : this.group_team_option.group_team_name,
            user_id          : this.user_id!,
            is_appr_required : false,
            appr_state       : "",
            member_created   : ""
        }

        window["groupListConfirmCancel"] = this.groupListConfirmCancel;
        window["groupListConfirm"]       = this.groupListConfirm;

        this.group_modal_info.show_group_list_confirm = true;
        this.group_modal_info.data = param_data;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 탈퇴 모달창 아니오
     */
    groupListConfirmCancel() : void {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;
        this.doSetGroupModalInfo(this.group_modal_info);
    }    

    /**
     * 탈퇴 모달창 예
     */
    async groupListConfirm(po_data) : Promise<void> {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;
        this.doSetGroupModalInfo(this.group_modal_info);

        if ( po_data.modal_type == "my_out" ) {
            await this.groupTeamMemberDelete("", po_data.group_id, po_data.team_id, po_data.user_id);
        }
    }

    /**
     * 탈퇴 추방 API 호출
     */
    async groupTeamMemberDelete(ps_type, pi_group_id, pi_team_id, pi_user_id) : Promise<void> {
        const vue = this;
        let url = "";

        if ( pi_team_id > 0 ) {
            url = `api/v1/teams/teamMemberDelete/${pi_group_id}/${pi_team_id}/${pi_user_id}`;
        }
        else {
            url = `api/v1/groups/groupMemberDelete/${pi_group_id}/${pi_user_id}`;
        }

        await this.hodu_api_call(url, API_METHOD.DELETE, null)
            .then(async(response) => {
                if ( ps_type == "P" ) {

                    if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                        this.residentSearch();
                    }

                    else {
                        this.getGroupMember();
                    }
                    
                    
                }
                else {
                    if ( this.team_id == 0 ) {
                        this.doGroupId(0);
                        this.doTeamId(0);
                    }
                    else {
                        this.doTeamId(0)
                    }

                    if( this.scope != OWNER_TYPE.PERSONAL ) {
                        // scope 등을 쓰는 computed 데이터가 있을때 먼저 이동 하지 않으면 오류 발생함
                        this.hodu_router_push(`/?${new Date().getTime()}`);
                        
                        this.doSetGroupMenuClose(false);

                        this.doSetStartDate(new Date());
                        this.doSetCalendarId(`personal-${this.user_id}`);
                        this.doSetScope(OWNER_TYPE.PERSONAL);  
                        this.doSetScopeGroupId(0);  
                        this.doSetScopeTeamId(0);  
                        this.doSetScopeGroupTeamOption({
                            group_team_name : '',
                            group_team_color : '#FFFFFF',
                            group_team_image : '',
                            group_team_descript : '',
                            biz_id : '',
                            biz_type : ''
                        });

                        await this.hodu_loading_timer_exit();
                        await this.hodu_loading();

                        setTimeout(() => {
                            // @ts-ignore
                            $('.left_inbox02').mCustomScrollbar('destroy');
                            if( window['left_inbox02_mCustomScrollbar'] != null ) { window['left_inbox02_mCustomScrollbar'](); }
                        }, 100);

                        if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
                    }

                    else {
                        vue.movePrevPage();
                    }
                }

                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });            
    }

    /**
     * 그룹 팀 추방
     */
    group_team_user_out(data) : void {
        let param_data : GroupTeamOtherOutModalData = {
            group_id : this.group_id,
            team_id : this.team_id,
            user_id : data.user_id,
            user_name : data.user_info.user_name,
            image : (data.user_info.user_pic ? data.user_info.user_pic : ""),
            user_phone_number : (data.user_info.user_phone_number ? data.user_info.user_phone_number : ""),
            user_email : (data.user_info.user_email ? data.user_info.user_email : ""),
            role : data.role ? data.role : data.group_role ? data.group_role[0] : data.team_role[0] 
        }

        window["groupTeamOtherOutCancel"]  = this.groupTeamOtherOutCancel;
        window["groupTeamOtherOutConfirm"] = this.groupTeamOtherOutConfirm;        

        this.group_team_other_out_info.show_modal = true;
        this.group_team_other_out_info.data = param_data;
        this.doSetGroupTeamOtherOutModalInfo(this.group_team_other_out_info)
    }

    /**
     * 추방 모달창 아니오
     */
    groupTeamOtherOutCancel() : void {
        this.group_team_other_out_info.show_modal = false;
        this.group_team_other_out_info.data = null;
        this.doSetGroupTeamOtherOutModalInfo(this.group_team_other_out_info);
    }

    /**
     * 추방 모달창 예
     */
    async groupTeamOtherOutConfirm(po_data) : Promise<void> {
        const vue = this;

        this.group_team_other_out_info.show_modal = false;
        this.group_team_other_out_info.data = null;
        this.doSetGroupTeamOtherOutModalInfo(this.group_team_other_out_info);

        await this.groupTeamMemberDelete("P", po_data.group_id, po_data.team_id, po_data.user_id);
    }

    /**
     * 권한변경
     */
    changeAuth(p_user_id, p_old_auth, p_auth) : void {
        if ( p_old_auth == p_auth ) {
            return;
        }

        if( p_auth == this.GroupAuth.ADMIN ) {
            this.hodu_show_dialog("alert", "정말 최종관리자를 위임 하시겠습니까?", ['아니오', '예'], [
                () => {},
                async() => {
                    try {
                        let url = `api/v1/groups/updateGroupMemberPermmision/${this.group_id}/${p_auth}/${p_user_id}`;
                        const response = await this.hodu_api_call(url, API_METHOD.PUT, null);

                        if( !response || !this.isHttpStatusSuccess(response.status) ) {
                            throw new Error("최종관리자 위임 중 오류 발생");
                        }

                        if ( p_user_id == this.user_id ) {
                            await this.get_group_role_service();
                        }
                        
                        if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                            await this.residentSearch();
                        }

                        else {
                            await this.getGroupMember();
                        }

                    } catch(e) {
                        this.hodu_error_process(e, true, false);
                    }
                }
            ]);
            return;
        }
        
        const vue = this;
        let url = "";

        if ( this.group_id > 0 && this.team_id == 0 ) {
            url = `api/v1/groups/updateGroupMemberPermmision/${this.group_id}/${p_auth}/${p_user_id}`;
        }
        else {
            url = `api/v1/teams/updateTeamMemberPermmision/${this.team_id}/${this.group_id}/${p_auth}/${p_user_id}`;
        }

        this.hodu_api_call(url, API_METHOD.PUT, null)
            .then(async(response) => {
                if ( p_user_id == this.user_id ) {
                    await this.get_group_role_service();
                }
                
                if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                    vue.residentSearch();
                }

                else {
                    await vue.getGroupMember();
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 권한설정 페이지 이동
     */
    groupPermmisionGO() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);
        this.doGroupTeamOption(this.group_team_option);

        this.hodu_router_push(`/group/${new Date().getTime()}/permmision`);
    }

    /**
     * 승인
     */
    approve() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);
        this.doGroupTeamOption(this.group_team_option);
        
        this.hodu_router_push(`/group/${new Date().getTime()}/approve`);
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('./../assets/images/contents/im_photoB.gif');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#group_member_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

    /**
     * 검색옵션
     */
    groupMemberSearchOption() : void {

    }

    /**
     * 멤버초대
     */
    groupMemberInvite() : void {
        // this.b_openInvite = !this.b_openInvite;

        // if ( !this.b_openInvite ) {
        //     $('#modalMemRegNm').val("");
        //     $('#modalMemRegPh').val("");
        // }

        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : "멤버 초대",
            calendar_scope_group_id : this.group_id,
            calendar_scope_team_id : this.team_id,
            use_user_select_only : true,
            callback : async(selected_info) => {
                try {

                    const response = await this.hodu_api_call(`api/v1/groups/invite`, API_METHOD.POST, {
                        "user_ids" : selected_info.selected_user_ids,
                        "group_id" : this.group_id,
                        "team_id" : this.team_id
                    });

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("멤버 초대 중 오류 발생");
                    }

                    // await this.getGroupMember();
                    await window['group_member_reload']?.();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "멤버 초대 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 멤버 초대 - 가입 권유 알림 보내기
     */
    async sendGroupInviteMms() : Promise<void> {
        let regex = /^[0-9]*$/;

        let modalMemRegNm : string = String($('#modalMemRegNm').val());
        let modalMemRegPh : string = String($('#modalMemRegPh').val());

        if ( modalMemRegNm.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "이름을 입력해주세요", ['확인']);
            return;
        }

        if ( regex.test("01056757622") == false ) {
            this.hodu_show_dialog("alert", "전화번호는 숫자만 입력해주세요", ['확인']);
            return;
        }

        if ( modalMemRegPh.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "전화번호를 입력해주세요", ['확인']);
            return;
        }

        await this.hodu_api_call(`api/v1/groups/invite/${this.group_id}/${this.team_id}`, API_METHOD.POST, {user_name: modalMemRegNm, user_phone_number: modalMemRegPh, group_team_name: this.group_team_option.group_team_name})
            .then(async(response) => {
                $(".inviteMateDiv").addClass("sent");
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 리스트 권한 드롭다운 ON / OFF
     */
    dropdownOnOff(original_member : any) : void {

        let original_member_role_on : boolean | undefined = original_member.member_role_on;
        let original_user_id : number = original_member.user_id;
        let original_index : number = -1;

        if( original_user_id == this.user_id ) {
            return;
        }

        const member_count : number = this.member_data.length;
        for( let i = 0; i < member_count; i++ ) {
            const member = JSON.parse(JSON.stringify(this.member_data[i]));
            member.member_role_on = false;
            this.member_data.splice(i, 1, member);

            if( original_user_id == member.user_id ) { original_index = i; }
        }

        original_member_role_on = original_member_role_on ? !original_member_role_on : true;
        original_member.member_role_on = original_member_role_on;
        this.member_data.splice(original_index, 1, original_member);
    }
    
    /**
     * 멤버 리스트 이름 기준 정렬
     */
    memberNameSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.member_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.member_sort_direction ) {
                case "ASC":
                    this.member_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.member_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.member_sort_target = "NAME";
            this.member_sort_direction = "ASC";
        }
        
    }

    /**
     * 멤버 리스트 직급 기준 정렬
     */
    memberRoleSort() : void {

        // 이미 ROLE Sort 중이라면
        if( this.member_sort_target == "ROLE" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.member_sort_direction ) {
                case "ASC":
                    this.member_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.member_sort_target = null;
                    break; 
            }
        }
        
        // ROLE Sort가 아니였다면
        else {
            this.member_sort_target = "ROLE";
            this.member_sort_direction = "ASC";
        }
        
    }

    /**
     * 입주자 차량 요청 승인 모달
     */
    showHomeResidentCarRequestModal(resident : any, car : any) : void {
        // if( this.doSetHomeResidentCarRequestModalInfo == null || car.car_info.is_apply == true ) { return; }

        this.doSetHomeResidentCarRequestModalInfo?.({
            show_modal : true,
            resident : resident,
            car : car
        });
    }

    /**
     * 카카오 링크를 이용한 그룹&팀 초대   
     */
    async kakaoShareInit() : Promise<void> {
        if( !Kakao.isInitialized() ) { await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
    }

    async kakaoShare() {

        try {

            // 클릭으로 이벤트를 추가했는지 boolean 값 체크, 했다면 그냥 빠져나감
            if( this.kakao_share_init == true ) {
                return;
            }

            // 제목
            let title : string = "";
            let content : string = ``;
            let params : string  = "";

            let dynamic_link = '';

            for( const group_team of this.all_group_data_temp ) {
                
                if( group_team.group_id != this.group_id || group_team.team_id != this.team_id ) {
                    continue;
                }

                params = `group_id=${this.group_id}&group_name=${group_team.group_name}&team_id=${this.team_id}&noti_type=PUSH&noti_sub_type=GROUP_TEAM_INVITE&biz_type=${this.scope_group_team_option.biz_type}`;
                if( this.team_id > 0 ) {
                    params += `&team_name=${group_team.team_name}`;
                }

                dynamic_link = `${this.DYNAMIC_LINK_PREFIX}${encodeURIComponent(params)}${this.DYNAMIC_LINK_SUFFIX}`;

                // shorten url
                dynamic_link = await this.hodu_shorten_url(dynamic_link);

                if( this.team_id > 0 ) {
                    title = `[${group_team.group_name}-${group_team.team_name}]`;
                    content = `팀에 초대합니다\n\n* 앱 설치 및 초대 바로가기 ↓↓↓\n${dynamic_link}\n\n호두가 설치되어 있는 경우\n아래 '호두 실행'을 클릭하여\n해당 팀에 가입하실 수 있습니다`;
                }
                else {
                    title = `[${group_team.group_name}]`;
                    content = `그룹에 초대합니다\n\n* 앱 설치 및 초대 바로가기 ↓↓↓\n${dynamic_link}\n\n호두가 설치되어 있는 경우\n아래 '호두 실행'을 클릭하여\n해당 그룹에 가입하실 수 있습니다`;
                }

                break;
            }

            let send_text : string = title + "\n" + content;

            Kakao.Link.createDefaultButton({
                container: '#kakao_link_btn',
                objectType: 'text',
                text : send_text,
                link: {
                    androidExecParams: params,
                    iosExecParams: params,
                },
                buttons: [
                    {
                        title: '호두 실행',
                        link: {
                            androidExecParams: params,
                            iosExecParams: params,
                        }
                    },
                ]
            });
            
            $('#kakao_link_btn').trigger('click');

            this.kakao_share_init = true;
        }
        catch(e) {
            console.log(e);
        }
    }

    /**
     * 그룹 멤버 엑셀로 가져오기
     */
    groupMemberImportExcel() {
        this.doSetGroupMemberImportModalInfo?.({
            show_modal : true,
            group_id : this.group_id,
            team_id : this.team_id
        });
    }
    
}
</script>

<style scoped>

#memScroll .mCSB_inside > .mCSB_container { margin-right:0; }
#memScroll p.btn_group { margin-right:30px; } 
#memScroll .sortHeader input[type='checkbox']:not(old) + label {margin-top: 20px;}
#memScroll input[type='checkbox']:not(old) + label {border-radius: 3px;display: inline-block;padding: 0;width: 17px;height: 17px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 0 -0;background-size: 18px !important;font-size: 0;margin-right: 0;margin-top: 17px;}
#memScroll input[type='checkbox']:not(old):disabled + label {background-color: #e6ecef !important;border: 2px solid #e6ecef !important;cursor:default;}
#memScroll input[type='checkbox']:not(old):checked + label {background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat 0 0;border: 2px solid #477fff;}
#memScroll .content .num {width: 9%;display: inline-block;line-height: 55px;float: left;font-weight:bold;text-align: center;}
#memScroll .userName {width: 23%;display: inline-block;padding-left: 70px;line-height: 55px;float: left;font-weight:bold;}
#memScroll .pos {width: 14%;display: inline-block;float: left;line-height: 55px;font-weight:bold;padding-left: 0;}
#memScroll .tel {width: 18%;display: inline-block;float: left;line-height: 55px;font-weight:bold;padding-left: 0;}
#memScroll .email {width: 24%;display: inline-block;float: left;line-height: 55px;padding-left: 0;font-weight:bold;}
#memScroll .status {position:relative; z-index:100000; width:12%;display: inline-block;float: left;line-height: 55px;padding-left: 0;font-weight:bold; }
#memScroll .userSubj img {position: absolute;width: 35px;height: 35px;left: 10%;margin-left: 3px;top: 11px;border-radius: 50%;background: #ecf2f7;border: 1px solid #f1f3f5;box-sizing: border-box;}
#memScroll .checkBox {width: 9%;display: inline-block;text-align: center;float: left;}
#memScroll .sortHeader .userName {padding-left: 30px;box-sizing: border-box;}
#memScroll .content {padding-top: 64px;}
#memScroll .manageUsers_rightArea li.me {background: #fff !important;color: #477fff !important;}
#memScroll .manageUsers_rightArea li.me:after { display:block; content:''; position:absolute; left:0; top:0; height:100%; width:3px; background:#477fff; }
#memScroll .manageUsers_rightArea li.me .status {color: #ff6060 !important;}

#memScroll .hodu_h .userName {width: 15%; padding-left: 30px;}
#memScroll .hodu_h .pos {width: 10%;}
#memScroll .hodu_h .tel {width: 10%;}
#memScroll .hodu_h .email {width: 44%; height : 55px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

#memScroll a.pos { position:relative; z-index:100000; }
#memScroll a.pos:hover { cursor:pointer !important; }
#memScroll a.status:hover { color:#ff6060;  } 

#memScroll h3.selectTitle .cl {display: inline-block;width: 8px;height: 8px;float: left;margin-top: 28px;border-radius: 50%;margin-right: 15px;font-size: 0;}
#memScroll h3.selectTitle a {cursor:default !important; position: absolute;width: 100%;height: 100%;background: none;font-size: 0;display: block;left: 0;top: 0;}
#memScroll h3.selectTitle {position: relative !important;    position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
#memScroll h3.selectTitle .num {padding-left: 20px;color: #477fff;font-size: 14px;}
#memScroll h3.selectTitle .rest {font-size: 14px;}
a#inviteMate {position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 10000000;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(../assets/images/contents/btn_add_fff.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;}
a#inviteMate:hover { background: #477fff url(/img/btn_add_fff.ffacba40.png) no-repeat center center; -webkit-transition: 0s; transition: 0s; -webkit-box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5); box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5); background-size: 60px !important;}
/*a#inviteMate:hover {box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5) !important;}*/

.remodal.mngMemModal .left_titleBox .modal-topBtn {padding:0 10px;float: right;margin-top: 15px;font-size: 13px;font-weight: bold;background:#fff;color:#484848;}
.remodal .btn-purple{background-color:#b770ff ;}
.manageUsers_leftArea a.btn-purple:hover {background-color: #35bcff !important;color: #fff;}
.manageUsers_leftArea a.btn-purple {padding: 0 25px;margin-top: 5px;margin-left: 0;margin-bottom: 30px;height: 40px;line-height: 40px;box-sizing: border-box;border-radius: 5px;font-weight: bold;color: #1192ff;;background: #e4f6ff !important;}
#inviteMate:hover, #inviteMate.on { color:#1192ff }
a#grupMyRemove:hover {color: #ff3737;}

#grupApprov { margin-top:0; width:auto !important; padding:0 15px;height:40px; line-height:40px;font-size: 13px !important;background:#fff; border-radius:5px;border:1px solid #e8e8e8; }
#grupApprov .txt { font-size:14px; color:#484848;}
#grupApprov .red { color:#ff6060; height:40px; line-height:40px;font-size:14px;display:inline-block; float:left;text-align:center; margin-right:7px;border-radius:50%  }
#grupApprov:hover .txt {
    line-height: 40px;
    font-size: 14px;
    margin-right: 0;
}
#grupApprov:hover  { padding:0 15px; box-shadow: none; background:#f1f3f5; }

/* 멤버 초대 */
.inviteMateDiv.on { display:block }
.inviteMateDiv {transition: 0.2s;display:none;position: fixed;z-index: 9999999999;width: 300px;padding: 0;background: #fff;box-shadow: 0 5px 15px rgba(0,0,0,0.15);border-radius: 5px;box-sizing: border-box;right: 80px;text-align: center;transition: 0.2s;bottom: 124px;}
.inviteMateDiv .grp1 {padding: 35px 40px 0 40px;transition:0.3s;height: 205px;}
.inviteMateDiv.sent .grp2 { display:block; }
.inviteMateDiv .btns {border-top: 1px solid #F1f3f5;position: absolute;width: 100%;bottom: 0;border-radius: 0 0 5px 5px;overflow: hidden;}
.inviteMateDiv .btns a {display: inline-block;width: 50%;float: left;height: 70px;line-height: 70px;font-size: 14px;font-weight: bold;}
.inviteMateDiv .btns a:hover { color:#477fff; }
.inviteMateDiv .btns a.close:hover { color:#ff5050 }
.inviteMateDiv .btns a:hover {background: #f1f3f5;}
.inviteMateDiv.sent { transition:0.2s; box-shadow: 0 8px 20px rgba(71, 127, 255, 0.5);}
.inviteMateDiv.sent .grp2 .check {width: 130px;border-radius: 20px;height: 40px;line-height: 40px;background: #477fff;color: #fff;font-weight: bold;display: inline-block;font-size: 14px;margin-top: 16px;}
.inviteMateDiv .grp2 {width: 100%;top: 0;display: none;position: absolute;height: 100%;background: #fff url(../assets/images/contents/sucSent.png) no-repeat center 0px;background-size: 211px;border-radius: 5px;}
.inviteMateDiv .grp2 h3 {font-size: 16px;padding-top: 196px;height: 30px;width: 100%;}
.inviteMateDiv.sent {height: 386px;}
.inviteMateDiv h2 {font-size: 19px;text-align: center;margin-bottom: 10px;}
.inviteMateDiv p {text-align:left;font-weight: bold;padding-top: 10px;font-size: 13px;line-height: 20px;}
.inviteMateDiv span.descript2, .inviteMateDiv span.descript3, .inviteMateDiv span.descript4 { font-size:12px;color: #757f96; }
.inviteMateDiv input {font-weight:bold;line-height: 45px;height: 45px;font-size: 14px;width: 100%;border-radius: 0;padding: 0 15px;box-sizing: border-box;border: 1px solid #f1f3f5;}
.inviteMateDiv input.wrong { border:1px solid #ff5050; background:#ffe7e7; color:#ff5050 }
.inviteMateDiv input.wrong::placeholder  { color:#ff5050 !important; }
.inviteMateDiv input.wrong:hover { border:1px solid #ff5050; background:#fff; transition:0.2s; }
.inviteMateDiv label {font-weight:bold; text-align:left;font-weight: bold;font-size: 13px;margin-top: 20px;margin-bottom: 10px;display: block;color: #757f96;}
.inviteMateDiv input:hover {border: 1px solid #d9dee0;}
.inviteMateDiv input:focus {border: 1px solid #477fff;}
.inviteMateDiv #modalMemInvateBtn {line-height:40px;height:40px;border-radius:25px;width:190px;display: inline-block;font-size: 14px;font-weight: bold;text-align: center;background: #477fff;color: #fff;margin-top: 25px;}
.inviteMateDiv #modalMemInvateBtn:hover, .inviteMateDiv.sent .grp2 .check:hover { background:#3371ff }
.inviteMateDiv form {border-top: 1px solid #f1f3f5;padding-top: 10px;padding: 5px 40px 55px;margin-top: 20px;}
.inviteMateDiv p {}
/* .inviteMateDiv:hover .grp1 {transition:0.3s;height: 64px;box-sizing: border-box;}
.inviteMateDiv:hover p {height: 0;opacity: 0;padding: 0;} */
.inviteMateDiv.sent .grp1 {display:none;}

.manageUsers_leftArea{display:none;width: 260px;position: absolute;height: auto;z-index: 100;box-shadow: 0 13px 20px rgba(0,0,0,0.15);border-right: 1px solid #f3f3f3;background-color: #ffffff;box-sizing: border-box;right: 20px;border-radius: 0 0 15px 15px;}
.manageUsers_leftArea.on { display:block; }
.manageUsers_leftArea .filePlaceList{height:229px;}
.mngMemModal .over_hidden {z-index: -1;position: absolute;padding-top: 62px;top: 0;width: 100%;height: 100%;box-sizing: border-box;}
.manageUsers_leftArea h2 {margin: 25px 0 17px;font-weight: normal;color:#737373;color: #737373;font-size: 14px;border-radius: 5px;line-height: 40px;display: inline-block;height: 40px;cursor: default;background: #f3f3f3;font-weight: bold;margin-top: 35px;margin-bottom: 20px;width: 100%;text-align: center !important;}
.manageUsers_leftArea input {margin-bottom: 15px;width: 120px;height:  40px;line-height:  40px;box-sizing:  border-box;border-radius: 0;width:  100%;padding-left: 12px;}
.manageUsers_leftArea .bt_del{display:inline-block;margin-top:4px; width:19px;height:19px;background:url('../assets/images/contents/bt_del_on.png') no-repeat 0 0;color:#fff;}
.manageUsers_leftArea .bt_add{display:inline-block;margin-top:5px; width:17px;height:17px;background:url('../assets/images/contents/bt_add.png') no-repeat 0 0;color:#fff;}
.manageUsers_leftArea label {width: 100%;display: block;text-align: left;margin-bottom: 0px;color:#484848;line-height: 29px;}

.manageUsers_rightArea.modal-scroll { padding-right: 0;}
.manageUsers_rightArea {overflow: hidden;margin: 0 !important;width: 100% !important;background: #ffffff;} 
.manageUsers_rightArea li.me:hover .position {right: 10px;}
.manageUsers_rightArea #group_member_ul.noDel li:hover .position { right: 10px !important; }
.manageUsers_rightArea li {position: relative;cursor: default;overflow:hidden;transition: 0.2s;color: #737373;height: 58px;line-height:  55px;border-bottom: 1px solid #f1f3f5;box-sizing:  border-box;padding: 0;width:  100%;background:  #fff;padding-bottom: 15px;}
.manageUsers_rightArea li:last-child p.userSubj { border: none }
.manageUsers_rightArea li a.delete {transition: 0.1s;display: block;right: 0;position: absolute;margin-top: 0;top: 0;width: 100%;opacity: 0;border-radius: 0;height: 100%;line-height: 51px;font-size: 0;text-align: center;color: transparent;background-color: transparent}
.manageUsers_rightArea li:hover a.delete span {font-size: 0;display: none;width: 4px;height: 4px;border-radius: 50%;background: #000;float: left;margin: 19px 2px;}
.manageUsers_rightArea li:hover a.delete span:first-child {margin-left: 10px;}
.manageUsers_rightArea li:hover a.delete {transition: 0.1s;display: block;opacity: 1;right: 20px;}
.manageUsers_rightArea li:hover .position {right: 50px;transition: 0.1s;}
.manageUsers_rightArea li:hover .position.top {right: 10px;}
.manageUsers_rightArea li a.delete:hover {}
.manageUsers_rightArea li p {text-align: left;line-height:  55px;color: #737373;width: 100%;}
.manageUsers_rightArea li p span {color: #484848;float: left;}
.manageUsers_rightArea li:hover .position,.manageUsers_rightArea li:hover p#userSubject img {box-shadow: 0 2px 3px rgba(0,0,0,0.2);transition:0.1s}
.manageUsers_rightArea li:hover p span { color:#484848; }
.manageUsers_rightArea li:hover p span.line { background-color:#a9a9a9;  }
.manageUsers_rightArea li.on p span.line { background-color:#a9a9a9 }
.manageUsers_rightArea li.on p span { color:#484848;  }
.manageUsers_rightArea li p.userSubj {/* border-bottom:1px solid #e5e5e5 */}
.manageUsers_rightArea li span.line { margin-top: 11px;background-color: #e3e3e3; width: 1px; height: 10px; vertical-align: middle; display: inline-block; }
.manageUsers_rightArea li span.userName, .manageUsers_rightArea li span.tel, .manageUsers_rightArea li span.email {display: inline-block; ;overflow:hidden; text-overflow: ellipsis;  }
.manageUsers_rightArea li span.userName {text-align: left;width: 20%;padding-right: 10px;box-sizing: border-box;position: relative;font-weight:  bold;text-align: left;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.manageUsers_rightArea li span.tel {width: 20%;padding: 0 5px;text-align: left;box-sizing: border-box;}
.manageUsers_rightArea li span.email {width: 30%;padding: 0 5px;box-sizing:  border-box;text-align: left;padding-right: 5px;}
.manageUsers_rightArea li.on p{color:#484848;}
.manageUsers_rightArea .dotBg { float: left; display: inline-block;  height: 16px; width: 22px; padding-top: 9px; padding-bottom: 1px;}
.manageUsers_rightArea .dot { display: inline-block; width: 4px; height: 4px; border-radius: 2px; background-color: #e3e3e3; }
.manageUsers_rightArea li.on .dot, .manageUsers_rightArea li:hover .dot { background-color: #a9a9a9;  }
.manageUsers_rightArea .userCheck {/* display: none; */float: left;display: inline-block;text-align: center;width: 9%;/* position: absolute; */left: 0;padding: 0;top: 0;}

.hc #memScroll input[type='checkbox']:not(old):checked + label {background: #36bdff url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 22px;border: 2px solid #36bdff;}

.sortHeader p {padding-top: 0;padding-bottom: 0;line-height: 60px !important;}

.userCheck{float:left;padding-top:6px;}
.userSubj{float:left;width: auto;line-height:33px;box-sizing: border-box;white-space:nowrap;padding-left: 0;line-height: 55px;width: 100%;overflow:hidden;text-overflow:ellipsis;}
.manageUsers_rightArea p#userSubject img {border-radius:50%;border: 1px solid #f3f3f3;background-color: #e9eff4;overflow:hidden;width: 35px;height: 35px;margin-top: 9px;float:  left;margin-right: 25px;}
.manageUsers_rightArea p#userSubject .num {width: 7%;padding-right: 10px;box-sizing: border-box;text-align: center;}
.manageUsers_rightArea ul#group_member_ul {padding: 0;width: 100%;float: left;margin-bottom:180px;}
.manageUsers_rightArea span.userName {font-weight:  bold;}
.manageUsers_rightArea li:hover {background: #f6f9fb;transition: 0.2s;}
.manageUsers_rightArea span.position {background: #ececec url(../assets/images/contents/ic_king.png) no-repeat center center;background-size: 17px;font-size: 0;border-radius: 50%;width: 32px;margin: 0 !important;height: 32px;display:  inline-block;position:  absolute;right: 10px;top: 12px;}
.manageUsers_rightArea span.position.top {background: #ffe28d url(../assets/images/contents/ic_king.png) no-repeat center center;background-size: 17px;}
a#grupMyRemove {font-size: 12px;color: #fff !important;font-weight: bold;width: 50px;line-height: 55px;background: #445667;}
.manageUsers_rightArea .userName.double {line-height: 40px;height: 50px;}
.manageUsers_rightArea span.userName span {/* margin-top: 13px; */font-weight: normal;text-align: left;position: absolute;top: 18px;left: 0;}
.manageUsers_leftArea form {padding: 0 40px;}
.manageUsers_leftArea h2 {margin: 25px 0 17px;font-weight: normal;color: #737373;color: #ffffff;font-size: 14px;line-height: 60px;display: inline-block;height: 55px;cursor: default;font-weight: bold;margin-top: 0;border-radius: 0;margin-bottom: 15px;width: 100%;text-align: center !important;background-color: #36bdff !important;background-image: linear-gradient(to right, #36bdff , #007eff 141%)!important;}
.manageUsers_leftArea a.btn-purple {margin-top: 5px;}
.manageUsers_rightArea li:hover span {font-weight: bold;}

#memScroll .manageUsers_rightArea li.me:hover {/* box-shadow: 0 5px 10px rgba(0,0,0,0.1); */background: #eef2f5;}
#memScroll .manageUsers_rightArea li:hover img {box-shadow: 0px 0px 5px rgb(255,255,255);}
#memScroll .manageUsers_rightArea li { overflow:visible; }
#memScroll .manageUsers_rightArea li.noPermission a.pos { cursor:default !important; }
.edit .access_option li.noPermission:hover .pos span.txt { padding:0 !important; background:none !important; box-shadow:none !important; }
.edit .access_option li.noPermission:hover .pos span.txt:hover{ cursor:default !important; }
#memScroll .manageUsers_rightArea li.noPermission:hover a.pos:hover { cursor:default; }
#memScroll .manageUsers_rightArea li.changed .pos .txt { color:#477fff !important; }
#memScroll .king .userSubj img {border: 2px solid #ffc239;width: 37px;margin-left: 3px;height: 37px;}
#memScroll .king.admin .userSubj img {border: 2px solid #ff5757;width: 37px;margin-left: 3px;height: 37px;}
#memScroll .manager .userSubj img {border: 2px solid #b4bfcc;width: 37px;margin-left: 3px;height: 37px;}
#memScroll ul.dropdown {z-index: 100000000000;width: 160px;top: 48px;left: 32%;}
#memScroll ul.dropdown li {padding-bottom: 0 !important;height: 46px;border-bottom: 0;}

/* .edit .access_option li.king:hover .pos span.txt {transition: 0.1s;font-weight: bold;padding: 0;padding-right: 0;background: none;cursor: default;border-radius: 0;margin-top: 0;box-shadow: none;} */

.edit .access_option li:hover .pos span.txt {transition: 0.1s !important;font-weight: bold !important; padding: 7px 8px !important; padding-right: 28px !important; background: #fff url(../assets/images/side_area/bt_open.png) no-repeat center right !important; cursor:pointer !important; border-radius: 5px !important;margin-top: -3px !important; box-shadow: 0 4px 5px rgba(0,0,0,0.1) !important;}
.edit .access_option li:hover .pos:hover { cursor:pointer; }
.access_option li:hover .pos span.txt, #memScroll .access_option li:hover .pos:hover { cursor:default; }
#memScroll .manageUsers_rightArea {overflow: visible;}
#memScroll  .over_hidden {overflow: visible;}
#memScroll .settingBg .btn_group a#inviteMate {display: inline-block;}
#memScroll .settingBg .btn_group a {display: none;}
#memScroll.edit .settingBg .btn_group a {display: inline-block;}

.access_option li.king .pos .txt:before {content: '';display: inline-block;width: 30px;border-radius: 50%;margin-left:-7px;height: 30px;margin-right: 10px;margin-top: 13px;float: left;background: #fff url(../assets/images/contents/ic_king.png) no-repeat 5px 5px;background-size: 19px;}
.access_option li.manager .pos .txt:before {content: '';display: inline-block;width: 30px;height: 30px;margin-left:-7px;border-radius: 50%;height: 30px;margin-right: 10px;margin-top: 13px;float: left;background: #fff url(../assets/images/contents/ic_manager.png) no-repeat  5px 5px;background-size: 19px;}

.residentUl .carNum .car { display : inline-block; width : 65px; height : 17px; line-height: 17px; background: #f1f3f5; padding: 6px 16px; margin-right: 10px; font-weight: bold; text-align: left; border : #e7e9ea solid 1px; border-radius: 35px; font-size: 12px; margin-top:13px; }
.residentUl .carNum .car.contract { background: #477fff; border : #2b6eff solid 1px; color : #fff; }
.residentUl .carNum .car.request { background: #ffc72f; border : #f1b71a solid 1px; cursor: pointer; }

/* 21.05.24 hodu_H 입주자 변경 */
#memScroll .hodu_h .userName { padding-left: 0; }
#memScroll .hodu_h .tel { width: 12%; }
#memScroll .hodu_h .email { width: 36%; }
#memScroll .hodu_h .status { width: 17%; position: relative; }
#memScroll .userSubj .status input { background: #fff; position: relative; z-index: 1000; vertical-align: top; margin-right: 10px; margin-top: 13px; box-sizing: border-box; width: 55px; text-align: center; display: inline-block; height: 28px; line-height: 26px; border-radius: 25px; font-weight: 700; color: #7c88a2; border: 1px solid #e2e5e8; }
#memScroll .userSubj .status input:hover { background: #f1f3f5; }
#memScroll .userSubj .status input.apply.off { display: none; }
#memScroll .userSubj .status input.reject.on { background:#ff6363 !important; color:#fff; border-color: #ff4242; cursor: default; }

/* 22.02.09 멤버초대 변경 */
.bt_scheduleBox .inbox input { background-position: center center; background-repeat: no-repeat; }
.bt_scheduleBox .event_btn input.btn_invite_kakao { background-image: url('../assets/images/contents/ic_kakaoShare.png'); background-size: 50px; border-color: #fae100 !important; background-color: #fae100; }
.bt_scheduleBox .event_btn input.btn_invite_frd { background-image: url('../assets/images/contents/ic_hoduShare.png'); background-size: cover; }
.bt_scheduleBox .event_btn input.btn_invite_import { background-image: url('../assets/images/contents/ic_supply_input.png'); background-size: cover; }
</style>