<template>
    <div id="approval_process_modal" class="modal">
        <table style="width:100%;padding:0;border:0;border-spacing:0px;border-collapse:collapse;">
            <thead>
                <tr style="line-height:60px;border-bottom:1px solid #e7e9ea;font-size:18px;text-align:left;">
                    <th colspan="3" style="padding:15px 0 0 30px;width:70%;color:rgb(083, 084, 111)">
                        어떻게 처리하시겠습니까?
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr style="border-bottom:1px solid #e7e9ea;font-weight:bold;font-size:14px;">
                    
                    <td class="selct" style="box-sizing:border-box;border-right:1px solid #e7e9ea;" colspan="2">
                        <input type="radio" id="one_go" class="bg_btn" name="howto" value="승인" checked/>
                        <label for="one_go" style="margin-top:0;" >승인</label>
                    </td>

                    <td style="box-sizing:border-box;" colspan="2">
                        <input type="radio" id="four_go" class="bg_btn" name="howto" value="반려"/>
                        <label for="four_go" style="margin-top:0;">반려</label>
                    </td>

                </tr> -->
                <tr>
                    <td colspan="3" style="box-sizing:border-box;background:rgb(241, 243, 245);">
                        <textarea id="note" rows="10" placeholder="반려 시 사유를 작성하세요" :value="reject_message" @input="reject_message = $event.target.value" autocomplete="off" spellcheck="false" style="padding:20px; font-size:15px; background:rgb(241, 243, 245);outline: none;width:100%;border: 0;resize: none;height: 159px; box-sizing : border-box;"></textarea>
                    </td>
                </tr>
                <tr style="line-height: 70px;">
                    <td><a href="#" class="btn_modal" @click.prevent="close">취소</a></td>
                    <td><a href="#" class="btn_modal" @click.prevent="process(approval_enum.APPROVAL_APPROVER_STATE.REJECT)">반려</a></td>
                    <td><a href="#" class="btn_modal" @click.prevent="process(approval_enum.APPROVAL_APPROVER_STATE.CONFIRM)">승인</a></td>
                    <!-- <td style="border-left:1px solid #e7e9ea;"><a href="#" class="btn_modal">저장</a></td> -->
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { approval_enum, approval_modal_interface } from '@/model/approval';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ApprovalProcessModal extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State approval_process_modal_info !: approval_modal_interface.ApprovalProcessModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalProcessModalInfo ?: (params : approval_modal_interface.ApprovalProcessModalInfo) => void;

    reject_message : string = ""; 

    mounted() : void {
        
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetApprovalProcessModalInfo?.({ show_modal : false });
    }

    /**
     * 처리
     */
    process(state : approval_enum.APPROVAL_APPROVER_STATE) : void {

        if( state == approval_enum.APPROVAL_APPROVER_STATE.REJECT && this.reject_message.trim().length < 1 ) {
            alert("반려 사유를 작성해주세요");
            $('#approval_process_modal #note').focus();
            return;
        }

        this.approval_process_modal_info.callback?.(state, this.reject_message);
        this.close();
    }   

}
</script>

<style scoped>
    #approval_process_modal.modal { text-align:center; width: 550px; height:307px; border-radius:20px; left: 50%;margin-left: -275px;top: 50%;margin-top: -153.5px;position: absolute;background: #fff; overflow: hidden; }
    #approval_process_modal .btn_modal{display: block;font-weight:bold;font-size: 15px;color:rgb(083, 084, 111)}
    #approval_process_modal input[type='radio']:not(old)+label{padding: 20px;width: 100%;height: 100%;background: none;text-align: center;box-sizing: border-box;}
    #approval_process_modal input[type='radio']:not(old):checked + label{background: #477fff;color:#fff;}
    .btn_modal:hover{background: #f1f3f5;}
</style>