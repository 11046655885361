<template>
    <div id="" class="section_scroll">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />
            
        <!-- 문자 발송 불가로 인해 숨김 -->
        <!-- <input type="button" id="" class="btnAdd" placeholder="" value="환자 추가" title="환자 추가" @click="showPatientAddModal"/> -->

        <div class="content">

            <ul class="sortHeader" id="">
                <li class="checkbox sortOff">
                    <input type="checkbox" id="selectAllPatients" @change="allPatientCheck($event.target.checked)" v-model="is_all_selected">
                    <label for="selectAllPatients"></label>
                </li>
                <li class="no">No</li>
                <li class="name headerSort" @click.prevent="sortTypeChange('NAME')"
                    :class="{
                        sortOff  : sort_type != 'NAME',
                        sortUp   : sort_type == 'NAME' && sort_dir == 'ASC',
                        sortDown : sort_type == 'NAME' && sort_dir == 'DESC'
                    }"><span>환자명</span>
                </li>
                <!-- 고유번호 일단 숨김 -->
                <!-- <li class="pCode headerSort sortOff" ><span>고유번호</span></li> -->
                <li class="tel"><span>연락처</span></li>
                <li class="filebox headerSort" @click.prevent="sortTypeChange('FILE_COUNT')"
                    :class="{
                        sortOff  : sort_type != 'FILE_COUNT',
                        sortUp   : sort_type == 'FILE_COUNT' && sort_dir == 'ASC',
                        sortDown : sort_type == 'FILE_COUNT' && sort_dir == 'DESC'
                    }"><span>파일함</span>
                </li>
            </ul>

            <!-- 현황 없음 -->
            <div class="noResult" :class="{ on : computedPatientSortList.length < 1 }" v-if="computedPatientSortList.length < 1">
                <p>검색 된 환자가 없습니다</p>
            </div>

            <div id="patient_list_scroll">
                <ul class="specialityUl">
                
                    <li :key="index" v-for="(patient, index) in computedPatientSortList">
                        <p class="checkbox">
                            <input type="checkbox" :id="`check_pat_${index}`" v-model="patient.checked" @change="patientCheckChanged">
                            <label :for="`check_pat_${index}`"></label>
                        </p>
                        <p class="no">{{ index + 1 }}</p>
                        <p class="name">{{ patient.user_name }}</p>
                        <!-- 고유번호 일단 숨김 -->
                        <!-- <p class="pCode">{{ 'patient.code' }}</p> -->
                        <p class="tel">
                            <!-- DM 발송을 push 알림으로 대체함 -->
                            <!-- <a title="DM 발송"  @click="showPatientSendMessageModal" v-if="patient.user_phone_number && patient.user_phone_number.length > 0">
                                {{ patient.user_phone_number }}
                            </a> -->
                            {{ patient.user_phone_number == null || patient.user_phone_number.length == 0 ? '-' : patient.user_phone_number }}
                        </p>
                        <p class="filebox"><span>{{ `${patient.file_count}개` }}</span></p>
                        <a class="edit" title="자세히보기" @click="movePatientDetail(patient.user_id)">자세히 보기</a>
                    </li>
                    
                    <!-- <li>
                        <p class="checkbox">
                            <input type="checkbox" id="check_pat1">
                            <label for="check_pat1"></label>
                        </p>
                        <p class="no">1</p>
                        <p class="name">김이정</p>
                        <p class="pCode">00489291093</p>
                        <p class="tel"><a title="DM 발송"  @click="showPatientSendMessageModal">010-2020-2030</a></p>
                        <p class="filebox"><span>10개</span></p>
                        <a class="edit" title="자세히보기" @click="movePatientDetail">자세히 보기</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">2</p>
                        <p class="name">김이연</p>
                        <p class="pCode">00489291093</p>
                        <p class="tel"><a title="DM 발송">010-2020-2030</a></p>
                        <p class="filebox"><span>10개</span></p>
                        <a class="edit" title="자세히보기">자세히 보기</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">3</p>
                        <p class="name">김이석</p>
                        <p class="pCode">00489291093</p>
                        <p class="tel"><a title="DM 발송">010-2020-2030</a></p>
                        <p class="filebox"><span>10개</span></p>
                        <a class="edit" title="자세히보기">자세히 보기</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">4</p>
                        <p class="name">김이순</p>
                        <p class="pCode">00489291093</p>
                        <p class="tel"><a title="DM 발송">010-2020-2030</a></p>
                        <p class="filebox"><span>10개</span></p>
                        <a class="edit" title="자세히보기">자세히 보기</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">5</p>
                        <p class="name">김이혁</p>
                        <p class="pCode">00489291093</p>
                        <p class="tel"><a title="DM 발송">010-2020-2030</a></p>
                        <p class="filebox"><span>10개</span></p>
                        <a class="edit" title="자세히보기">자세히 보기</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">6</p>
                        <p class="name">김이현</p>
                        <p class="pCode">00489291093</p>
                        <p class="tel"><a title="DM 발송">010-2020-2030</a></p>
                        <p class="filebox"><span>10개</span></p>
                        <a class="edit" title="자세히보기"></a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">7</p>
                        <p class="name">김이횬</p>
                        <p class="pCode">00489291093</p>
                        <p class="tel"><a title="DM 발송">010-2020-2030</a></p>
                        <p class="filebox"><span>10개</span></p>
                        <a class="edit" title="자세히보기">자세히 보기</a>
                    </li> -->
                    
                </ul>
            </div>
            
        </div>
    </div>
</template>


<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

// import { namespace } from 'vuex-class';
// const ModalInfo = namespace('ModalInfo');

import { hodu_doc_object } from '@/model/hodudoc';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class PatientListComp extends Mixins(VueHoduCommon) {

    /**
     * 환자 정렬 리스트
     */
    get computedPatientSortList() : hodu_doc_object.patient_info[] {

        let target_list : hodu_doc_object.patient_info[] = JSON.parse(JSON.stringify(this.patient_list));


        target_list = target_list.sort((item1, item2) : number => {

            // 이름 기준 정렬
            if( this.sort_type == 'NAME' ) {

                if( item1.user_name == item2.user_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.user_name < item2.user_name ? -1 : 1;
                }

                return item1.user_name > item2.user_name ? -1 : 1;

            }

            // 파일 개수 기준 정렬
            if( this.sort_type == 'FILE_COUNT' ) {

                if( item1.file_count == item2.file_count ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_count < item2.file_count ? -1 : 1;
                }

                return item1.file_count > item2.file_count ? -1 : 1;

            }

            return 0;
        })
        
        return target_list;

    }

    /**
     * @ModalInfo.Action
     */
    // @ModalInfo.Action doSetShowPatientAddModal ?: (params : boolean) => void;

    @Prop() event_bus !: Vue;

    patient_list : hodu_doc_object.patient_info[] = [];

    is_all_selected : boolean = false;

    // 정렬
    sort_type : 'NAME' | 'FILE_COUNT' = 'NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;
    
    mounted() : void {

        if( this.event_bus != null ) { 
            this.event_bus.$on('searchPatientList', this.getPatientsInfo);
            this.event_bus.$on('patientDelete', this.deletePatients);
            this.event_bus.$on('getSelectedPatient', this.getSelectedPatient); 
        }

        this.setScroll();
        this.getPatientsInfo();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#patient_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    /**
     * 환자 정보 조회
     */
    getPatientsInfo(search_word ?: string) : void {

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient?search_word=${search_word ? search_word : ''}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                this.patient_list.splice(0, this.patient_list.length);
                this.patient_list = this.patient_list.concat(response.data.data);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 모든 환자 체크 / 체크 해제
     */
    allPatientCheck(is_checked : boolean) : void {
        
        for( const patient of this.patient_list ) {
            const temp_patient : hodu_doc_object.patient_info = JSON.parse(JSON.stringify(patient));
            temp_patient.checked = is_checked;
            this.patient_list.splice(this.patient_list.indexOf(patient), 1, temp_patient);
        }

    }

    /**
     * 환자 체크 변경
     */
    patientCheckChanged() : void {

        const all_count : number = this.computedPatientSortList.length;
        const checked_count : number = this.computedPatientSortList.filter(item => item.checked == true).length;

        this.is_all_selected = (all_count == checked_count);
    }

    /**
     * 환자 삭제
     */
    deletePatients() : void {

        const selected_patients : hodu_doc_object.patient_info[] = this.computedPatientSortList.filter(item => item.checked == true); 

        if( selected_patients.length < 1 ) {
            this.hodu_show_dialog('alert', '삭제 할 환자를 선택해주세요', ["확인"], [() => {}]);
            return; 
        } 

        this.hodu_show_dialog('cancel', `환자를 ${ this.is_all_selected ? '전부 ' : '' }삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {
                if( selected_patients.length < 1 ) { return; }
                
                const delete_promise : Promise<void>[] = [];

                for( const patient of selected_patients ) {

                    const patient_id : number = patient.user_id;

                    delete_promise.push(this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient?patient_id=${patient_id}&group_id=${this.scope_group_id}`, API_METHOD.DELETE)
                        .then((response) => {
                            console.log(response);
                        })
                        .catch((e) => {
                            this.hodu_error_process(e, false, false, true);
                        }));

                }

                // 환자 삭제 병렬 처리
                Promise.all(delete_promise)
                    .then(() => {
                        this.is_all_selected = false;
                        this.getPatientsInfo();
                    }).catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });

            }
        ])
    }

    /**
     * 선택된 환자 목록 $emit
     */
    getSelectedPatient() : void {
        this.$emit('showPatientSendMessageModal', this.computedPatientSortList.filter(item => item.checked == true));
    }

    /**
     * 환자 등록
     */
    // showPatientAddModal() : void {
    //     if( this.doSetShowPatientAddModal ) { this.doSetShowPatientAddModal(true); }
    // }

    /**
     * 환자 상세
     */
    movePatientDetail(patient_id : number) : void {
        this.hodu_router_push(`/hospital/${new Date().getTime()}/patient/${patient_id}`);
    }
    
    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'NAME' | 'FILE_COUNT') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#patient_list_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
</script>

<style scoped>
    #container #section_ce .schedule_box { padding:0 !important; max-width:100% !important; }
    .btnBox  { margin-right: 30px; }
    .posRel h4 { display: inline-block; font-size: 18px; margin-left: 30px; line-height: 65px; height: 65px; }
    .posRel .input_btn { margin: 11px 0 0 20px }

    .specialityUl li { transition:0.2s; overflow:hidden; position: relative; border-bottom: 1px solid #f1f3f5;}
    .specialityUl li:hover { background: #f7f9fb; }
    .specialityUl li:hover a.edit { opacity: 1; }
    .specialityUl a.edit { transition:0.2s; opacity: 0; position: absolute; font-size: 0; display: block; width:30px; height:30px;right:30px;top:12px; background-color:#477fff; border-radius: 50%; box-sizing: border-box;background-image: url('../../assets/images/contents/month_bt_next_fff.svg'); background-size: 26px; background-repeat: no-repeat; background-position: center center;}
    .specialityUl a.edit:hover { background-color:#185eff}
    .specialityUl p { float:left; line-height: 55px; height: 55px; font-weight:bold; font-size: 12px; }
    .no { width: 10%; display:inline-block; padding-left: 10px; box-sizing: border-box; }
    .checkbox { width:10%; display:inline-block; text-align:center; }
    
    /* 고유번호 생기면 사용 */
    /* .name { width:25%;  display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .pCode { width: 20%; }
    .tel { width: 20%; display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .filebox { width: 15%; font-size: 12px; } */

    /* 고유번호 없을때 사용 */
    .name { width:30%;  display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .tel { width: 25%; display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .filebox { width: 25%; font-size: 12px; }

    .filebox a, .tel a { transition:0.2s;position:relative; z-index: 1000; display:inline-block; padding:0 10px; background:#f1f3f5; line-height: 25px; height:25px; border:1px solid #e7e9ea; border-radius: 20px; }
    .tel { position:relative; z-index: 1000; }
    .tel a { transition:0s; position:relative; z-index: 1000; }
    .specialityUl li:hover .filebox a,.specialityUl li:hover .tel a  {background:#fff; }
    .filebox a:hover, .tel a:hover { color:#fff; background:#477fff !important; border-color:#2a6bff }
    input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 20px;height: 20px;border-radius:3px; margin-top:15px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;font-size: 0;margin-right: 8px;}
    input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;}
    #uploadId { display: none; }
    .uploadInput {cursor:pointer; float:right; }

    .btnAdd { position:absolute; right: 40px; bottom:40px; }
    .btnAdd { opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .btnAdd:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}

    .grp.settingBg { position:static !important; }

    /* noResult */
    .noResult.on { display:block; }
    .noResult { display:none; position: static; box-sizing: border-box; padding: 20px 50px;width: 100%;  }
    .noResult p { width:100%; border-radius: 5px; background:#f1f3f5; line-height: 80px; height: 80px; font-size: 13px; color: #9ea5b3;}

</style>