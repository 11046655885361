<template>
    <div id="organization_modal" class="modal" :class="{ select : organization_modal_info.is_select_only == true }">
        
        <table style="width:100%;padding:0;border:0;border-spacing:0px;border-collapse:collapse;">
            
            <thead>
                <tr style="line-height:60px;border-bottom:1px solid #e7e9ea;font-size:18px;text-align:left;">
                    <th colspan="2" style="padding:15px 0 0 30px;width:70%;color:rgb(083, 084, 111)">
                        {{ organization_modal_info.is_select_only == true ? '부서 선택' : '조직도 설정' }}
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td :colspan="organization_modal_info.is_select_only == true ? 2 : 1" style="width:50%;height:303px;border-right:1px solid #e7e9ea;border-bottom:1px solid #e7e9ea;">
                        <div class="wrapper" v-if="computedOrganization != null">
			                
                            <!-- 메인  -->
                            <div class="chk check_btn" v-if="selected_department != null" style="margin: 20px 0 0 40px;">
                                <input type="radio" id="modal-top-organization" name="name_select" :checked="selected_department.dept_id == computedOrganization.dept_id" :value="computedOrganization.dept_id" @change="change($event, computedOrganization)"/>
                                <label for="modal-top-organization" style="margin-top:0;">{{ computedOrganization.dept_name }}</label>
                            </div>

                            <OrganizationRecursion 
                                :level="2" 
                                :content_type="'radio'"
                                :departments="computedOrganization.departments"
                                :selected_department="selected_department"
                                :department_closed_map="department_closed_map"
                                :department_height_map="department_height_map" 
                                @select="select" />

			                <!-- 첫번쩨 ul (그룹 리스트) -->
                            <ul class="sub1 check_btn" v-if="false">
                                <li class="fList">
                                    <!-- 상단 삐져나오는 선 가리개 -->
                                    <span class="topBlank"></span>
                                    <!-- 하단 삐져나오는 선 가리개 -->
                                    <span class="btmBlank"></span>
                                    <!-- 길이에 따라 길어지는 세로 선 -->
                                    <span class="vertiLine"></span>
                                    <!-- 화살표 버튼 -->
                                    <input type="button" class="arw" />
                                    <!-- cld : checkbox list div -->
                                    <div class="cld check_btn">
                                        <input type="radio" id="name_select1_1" class="" name="name_select"/>
                                        <label for="name_select1_1" style="margin-top:0;">피닉스</label>
                                    </div>

                                    <!-- 두번째 ul (팀 리스트) -->
                                    <ul class="sub2">
                                        <li>
                                            <!-- horiLine 가로선 -->
                                            <span class="horiLine"></span>
                                            <div class="cld check_btn">
                                                <input type="radio" id="name_select1_1_1" class="" name="name_select"/>
                                                <label for="name_select1_1_1" style="margin-top:0;">개발</label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="horiLine"></span>
                                            <div class="cld">
                                                <input type="radio" id="name_select1_1_2" class="" name="name_select"/>
                                                <label for="name_select1_1_2" style="margin-top:0;">교육</label>
                                            </div>

                                            <!-- 세번째 ul (팀 리스트) -->
                                            <ul style="margin-left: 35px;margin-top: -31px;">
                                                <li class="fList">
                                                    <!-- 상단 삐져나오는 선 가리개 -->
                                                    <span class="topBlank"></span>
                                                    <!-- 하단 삐져나오는 선 가리개 -->
                                                    <span class="btmBlank"></span>
                                                    <!-- 길이에 따라 길어지는 세로 선 -->
                                                    <span class="vertiLine"></span>
                                                    <!-- 화살표 버튼 -->
                                                    <input type="button" class="arw" />
                                                    <!-- cld : checkbox list div -->
                                                    <div class="cld">
                                                        
                                                    </div>
                                                    <ul class="sub2">
                                                        <li>
                                                            <!-- horiLine 가로선 -->
                                                            <span class="horiLine"></span>
                                                            <div class="cld">
                                                                <input type="radio" id="name_select2_1_1_1" class="" name="name_select"/>
                                                                <label for="name_select2_1_1_1" style="margin-top:0;">개발</label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span class="horiLine"></span>
                                                            <div class="cld">
                                                                <input type="radio" id="name_select2_1_2_1" class="" name="name_select"/>
                                                                <label for="name_select2_1_2_1" style="margin-top:0;">심사</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>	
		                </div>  
                    </td>
                    <td class="team_chk" style="border-bottom: 1px solid rgb(231, 233, 234);vertical-align: middle;" v-if="organization_modal_info.is_select_only == null || organization_modal_info.is_select_only == false">
                        
                        <div class="title_Chk" style="position: relative;">
                            <label style="margin-right:10px;font-size: 16px;font-weight: bold;">부서명</label>
                            <input type="text" :value="selected_department != null ? selected_department.dept_name : ''" @input="inputDepartmentTitle($event)" placeholder="부서명을 입력해주세요"/>                      
                        </div>

                        <div style="text-align:right;width:81%;margin:15px auto 0;">
                            <input type="button" value="부서삭제" class="btn_grp" style="margin-right:10px;" @click.prevent="deleteDepartment" v-if="selected_department != null && selected_department.dept_id_array.length > 1">
                            <input type="button" value="부서추가" class="btn_grp" @click.prevent="add">
                        </div>
                    </td>
                </tr>
                <tr style="line-height: 70px;">
                    <!-- <td style="width: 150px;height: 50px;margin: 0 auto;"><a href="#" class="btn_modal">취소</a></td> -->
                    <td colspan="2" style="width:100%;border-left:1px solid #e7e9ea;" v-if="organization_modal_info.is_select_only == null || organization_modal_info.is_select_only == false"><a href="#" class="btn_modal" @click.prevent="close">확인</a></td>
                    <td style="width:50%;border-left:1px solid #e7e9ea;" v-if="organization_modal_info.is_select_only == true"><a href="#" class="btn_modal" @click.prevent="close">취소</a></td>
                    <td style="width:50%;border-left:1px solid #e7e9ea;" v-if="organization_modal_info.is_select_only == true"><a href="#" class="btn_modal" @click.prevent="save">선택</a></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

import { organization_modal_interface } from '@/model/organization';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion
    },
})
export default class OrganizationModal extends Mixins(VueHoduCommon) {

    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_modal_info !: organization_modal_interface.OrganizationModalInfo

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationModalInfo ?: (params : organization_modal_interface.OrganizationModalInfo) => void; 

    departments : any[] = [];
    selected_department : any = null; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    async mounted() : Promise<void> {
        // // 버튼 클릭시 라인과 팀 보이기
        // $(".arw").click(function(){
        //     // 리스트 인덱스 가져오기
        //     var getIndex = $(this).parent().index();
            
        //     // 버튼 클릭시 라인과 팀 보이기
        //     $(".fList").eq(getIndex).toggleClass("showTeam");

        // });
        await this.getOrganization();
    }

    /**
     * 조직도 부서 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

            if( this.selected_department == null ) this.selected_department = this.departments.filter(item => item.dept_id_array.length == 1)[0];

        } catch(e) {
            alert( "조직도 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 타이틀 변경
     */
    @Debounce(500)
    async inputDepartmentTitle(event) : Promise<void> {
        if( event.target.value.trim().length < 1 ) return;
        this.selected_department.dept_name = event.target.value.trim();
        this.update();
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.$nextTick(() => this.updateHeight());
    }

    /**
     * 전체 높이 업데이트
     */
    updateHeight() : void {
        for( const department of this.departments ) {
            const level = department.dept_id_array.length; 

            if( level == 2 ) {
                const target_dept_id = department.dept_id;
                const parent_li_height = $(`#modal-organization-li-${department.dept_id}`).outerHeight();
                const last_child_li_height = $(`#modal-organization-li-${department.dept_id} > ul.sub2 > li:last-child`).outerHeight(); 
                const final_height = (parent_li_height ? parent_li_height : 0) - (last_child_li_height ? last_child_li_height : 0) + 15;
                department.vertical_height = final_height;

                // 맵에 세팅
                this.$nextTick(() => { this.department_height_map.set(target_dept_id, final_height); }); 
            }

            else if( level >= 3 ) {
                // 현재 컴포넌트 높이 업데이트
                const target_dept_id = department.dept_id;

                const parent_li_height = $(`#modal-organization-li-${department.dept_id}`).outerHeight();
                const last_child_li_height = $(`#modal-organization-li-${department.dept_id} > ul.level4 > li > ul.sub2 > li:last-child`).outerHeight();
                const final_height = (parent_li_height ? parent_li_height : 0) - (last_child_li_height ? last_child_li_height : 0) + 12;

                department.vertical_height = final_height;
                if( department.is_closed == true ) department.vertical_height = 0;

                // 맵에 세팅
                this.$nextTick(() => { this.department_height_map.set(target_dept_id, final_height); });
            }       

        }
    }
    
    /**
     * 부서 선택(라디오 버튼 클릭 후 변경 됐을때)
     */
    change(event, department) : void {
        if( event.target.checked == true ) {
            this.select(department);
        }
    }

    /**
     * 아이템 추가
     */
    async add() : Promise<void> {
        try {
            if( this.selected_department == null ) return;
            const new_department = JSON.parse(JSON.stringify(this.selected_department));
            
            await this.getOrganization();
            
            this.selected_department.is_closed = false;
            this.department_closed_map.set(this.selected_department.dept_id, false);

            new_department.dept_name = "새로운 부서";

            // 같은 레벨에 있는 부서 수를 구해서 dept_seq 세팅
            let same_level_departments = JSON.parse(JSON.stringify(this.departments));
            for( const id of this.selected_department.dept_id_array ) {
                same_level_departments = same_level_departments.filter(item => item.dept_id_array.indexOf(id) > -1);
            }
            same_level_departments = same_level_departments.filter(item => item.dept_id_array.length == new_department.dept_id_array.length);
            new_department.dept_seq = same_level_departments.length;

            // insert API
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.POST, { 
                "dept_name"     : new_department.dept_name,
                "dept_id_array" : new_department.dept_id_array,  
                "dept_seq"      : new_department.dept_seq
            });

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 정보 추가 중 오류 발생");
            }

            this.departments.push(response.data.data.dept_info);
            this.select(response.data.data.dept_info);

            this.organization_modal_info.callback?.();

        } catch(e) {
            alert("조직도 부서 정보 추가 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 부서명 업데이트
     */
    async update() : Promise<void> {
        try {
            if( this.selected_department == null ) return;
            
            // update api
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/${this.selected_department.dept_id}`, API_METHOD.PUT, { "dept_name" : this.selected_department.dept_name }, false);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 정보 수정 중 오류 발생");
            }

            this.getOrganization();

            this.organization_modal_info.callback?.();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 부서 삭제
     */
    async deleteDepartment() : Promise<void> {
        try {   
            if( this.selected_department == null ) return;
            if( confirm(`정말로 삭제하시겠습니까?\n'${this.selected_department.dept_name}'`) == false ) return;
            
            // delete api
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/${this.selected_department.dept_id}`, API_METHOD.DELETE);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 부서 정보 삭제 중 오류 발생");
            }

            this.selected_department = null;
            await this.getOrganization();
            this.updateHeight();

            this.organization_modal_info.callback?.();

        } catch(e) {
            alert("조직도 부서 정보 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 모달 닫기
     */
    close() : void {
        if( this.organization_modal_info.is_select_only == false ) this.organization_modal_info.callback?.();
        this.doSetOrganizationModalInfo?.({ show_modal : false });
    }

    /**
     * 선택된 부서 넘겨줌
     */
    save() : void {
        this.organization_modal_info.callback?.(this.selected_department);
        this.doSetOrganizationModalInfo?.({ show_modal : false });
    }

}
</script>

<style>
    #organization_modal.modal { text-align:center; width: 750px; height:450px; border-radius:20px; left: 50%;margin-left: -375px;top: 50%;margin-top: -225px;position: absolute;background: #fff; overflow: hidden;}
    #organization_modal.modal.select { width : 375px; margin-left: -187.5px;}

    /* 필요없음 */
    #organization_modal .wrapper {/* width: 100%; */ width: 375px; height: 100%;box-sizing: border-box;overflow: auto auto;}
    * {margin:0;padding:0;}

    /* 필요없음 */
    #organization_modal .chk { height: 35px; line-height: 35px; text-align: left;position: relative;}
    #organization_modal .chk { height: 35px; line-height: 35px; text-align: left;position: relative;}
    #organization_modal .cld { height: 35px; line-height: 35px; padding-left: 10px; text-align: left;position: relative;margin-left: 20px;}
    /* 최하위 왼쪽 패딩 더 주기 */
    #organization_modal .sub2 .cld { padding-left: 20px; text-align: left;margin-left: 40px;}
    
    /*체크박스 디자인*/
    #organization_modal .chk input,  #organization_modal .cld input{margin: 0;padding: 0;opacity: 0;width: 0;}
    #organization_modal .chk input{border: 1px solid #dadbde;padding: 5px 10px;}
    #organization_modal .title_Chk input{width:225px;border: 1px solid #dadbde;padding: 10px;}
    #organization_modal .chk input::placeholder, #organization_modal .cld input::placeholder, #organization_modal .title_Chk input::placeholder {font-weight:normal;}
    #organization_modal .check_btn input[type='radio']:not(old)+label{margin-top: 6px;display: inline-block;padding: 5px 10px;width: 150px; /* height: auto; */ border-radius: 3px;border: 2px solid #dadbde;margin-right: 8px;background: none; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
    #organization_modal .check_btn input[type='radio']:not(old):checked + label{border-color: #477fff;background: none;}
    /*체크박스 텍스트*/
    #organization_modal .chk label span{position: absolute;left: 30px;top: 9px;}
    #organization_modal .cld label span{position: absolute;left: 40px;top: 9px;}
    #organization_modal .sub2 .cld label span{position: absolute;left: 50px;top: 9px;}

    /* 그룹과 팀 리스트에 적용 */
    #organization_modal ul li { position: relative;  }

    /* 버튼 */
    #organization_modal .arw { background: none;color: inherit;border: none;padding: 0;font: inherit;cursor: pointer;outline: inherit; z-index: 1000; position: absolute; left: 1px;top:10px; width: 11px; height: 11px; width: 0;height: 0;border-bottom: 6px solid transparent;border-top: 6px solid transparent;border-left: 8px solid #000; cursor: pointer; }
    #organization_modal .level4 > li > .arw { top : 6px; }
    #organization_modal .level4 > li.showTeam > .arw { top : 10px; }
    /* 상단 삐져나오는 라인 감추기 */
    #organization_modal .topBlank { z-index: 100; position: absolute; top: 0; width: 15px; height: 14px; background: #fff; display: block; }
    /* 하단 삐져나오는 라인 감추기 */
    #organization_modal .btmBlank { z-index: 100; position: absolute; bottom: 0; width: 15px; height: 19px; background: #fff; display: block; }

    /* 세로 선 */
    #organization_modal .vertiLine { height: 100%; width: 1px; background: #dadbde; display: none; position: absolute; left: 6px; top: 0; }
    /* 가로 선 */
    #organization_modal .horiLine { width: 35px; height: 1px; background: #dadbde; display: none; position: absolute; top: 15px; left: 6px; }
    
    
    /* 최하위 그룹 디폴트로 감추기 */
    #organization_modal .sub2 { display: none; }
    #organization_modal .nono:after {content: ''; display: block; clear: both;}

    /* 팀 보일때 선이랑 다같이 보이기 */
    #organization_modal .showTeam > .sub2 { display: block; }
    #organization_modal .showTeam .horiLine,#organization_modal .showTeam .vertiLine { display: block; }
    #organization_modal .showTeam > input.arw {border-left: 6px solid transparent;border-right: 6px solid transparent;border-top: 8px solid #000;}

    /*스크롤바디자인변경*/
    #organization_modal ::-webkit-scrollbar{width: 6px; height : 6px;}
    #organization_modal ::-webkit-scrollbar-track{background-color: transparent;}
    #organization_modal ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
    #organization_modal .btn_modal{font-size:15px;font-weight:bold;display: block;}
    #organization_modal .btn_grp{padding: 10px 20px;display:inline-block;line-height: 20px;font-weight: bold;color: rgb(083, 084, 111);margin: 0 auto;border: 1px solid #e7e9ea;border-radius: 5px;background:#e7e9ea; }
    #organization_modal .btn_modal:hover{background: #f1f3f5;}
    #organization_modal .cld { white-space:nowrap;  }
</style>