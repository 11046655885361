<template>
    <div class="shareModal modal" :class="{ peepsOn : view_group_team_member == true }">
        
        <!-- 상단 영역 시작 -->
        <div class="titleBox">
            
            <!-- 멤버 클릭시 하단 타이틀 변경 > 멤버 공유 -->
            <h1 class="borderbot">
                {{ !isWork(event.event_sub_type) && !isReport(event.event_sub_type) && !isMeetingLog(event.event_sub_type) ? '전송' : '공유' }}
            </h1>
            
            <!-- 선택 값 -->
            <p class="total"      v-if="view_group_team_member == false && user_ids.length  > 0">사용자 <span>{{ user_ids.length }}</span></p>
            <p class="total grp"  v-if="view_group_team_member == false && group_ids.length > 0">그룹 <span>{{ group_ids.length }}</span></p>
            <p class="total team" v-if="view_group_team_member == false && team_ids.length  > 0">팀 <span>{{ team_ids.length }}</span></p>

            <!-- 그룹&팀 멤버 클릭해서 들어간 목록에서 사용 -->
            <p class="total user" v-if="view_group_team_member == true">사용자 
                <span>
                    {{ member_check_all == true ? group_team_members.length : member_checked_count }}
                </span> / {{ group_team_members.length }}
            </p> 
            
            <div class="schDiv">

                <a class="schBtn" id="grpSchBtn" :class="{ on : ( view_group_team_member == false && view_search_group_team == true ) ||
                                                                ( view_group_team_member == true  && view_search_member     == true )}" 
                                                                  @click="searchButtonClick">검색버튼</a>

                <input type="button" class="input_btn share_option_btn option_share" :class="{ on : share_option == SHARE_OPTION.SHARE }" value="공유" @click="shaerOptionChange(SHARE_OPTION.SHARE)" v-if="!isWork(event.event_sub_type) && !isReport(event.event_sub_type) && !isMeetingLog(event.event_sub_type)" />
                <input type="button" class="input_btn share_option_btn option_copy"  :class="{ on : share_option == SHARE_OPTION.COPY  }" value="복사" @click="shaerOptionChange(SHARE_OPTION.COPY)" v-if="!isWork(event.event_sub_type) && !isReport(event.event_sub_type) && !isMeetingLog(event.event_sub_type)" />
                <input type="button" class="input_btn share_option_btn option_move"  :class="{ on : share_option == SHARE_OPTION.MOVE  }" value="이동" @click="shaerOptionChange(SHARE_OPTION.MOVE)" v-if="!isWork(event.event_sub_type) && !isReport(event.event_sub_type) && !isMeetingLog(event.event_sub_type)" />

                <div class="schGrp" :class="{ on : ( view_group_team_member == false && view_search_group_team == true ) ||
                                                   ( view_group_team_member == true  && view_search_member     == true )}">
                    <input type="text" id="searchTotalTxt" :placeholder="view_group_team_member == true ? '멤버를 검색하세요' : '그룹을 검색하세요'" class="input_search02"
                     :value="view_group_team_member == false ? group_team_search_query : group_team_member_search_query"
                     @input="
                        view_group_team_member == false ? group_team_search_query        = $event.target.value
                                                        : group_team_member_search_query = $event.target.value"
                     >
                    <a class="schBtn"><span class="blind">찾기버튼</span></a>
                </div>
            </div>
        </div>
        <!-- 상단 영역 끝 -->
        <div class="modal_content">
            <!-- 친구 그룹 선택 -->
            <!-- <div class="magBtns">
                <input type="button" value="친구"  /><input type="button" class="on" value="그룹"  />
            </div> -->
            <!-- 그룹 헤더 -->
            <ul class="sortHeader grpHeader">
                <!-- <li class="grpCheck" v-if="view_group_team_member == true">
					<p class="grpCheckAll" id="grpCheckAll">
						<input type="checkbox" v-model="member_check_all">
						<label for="grpCheckAll">전체선택</label>
					</p>
				</li> -->

                <li class="grpCheck" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')">선택</li>

				<li class="num">No</li>

				<li class="nm headerSort" @click="groupTeamNameSort"
                    :class="{
                            sortOff  : group_team_sort_target != 'NAME',
                            sortUp   : group_team_sort_target == 'NAME' && group_team_sort_direction == 'ASC', 
                            sortDown : group_team_sort_target == 'NAME' && group_team_sort_direction == 'DESC', 
                        }">
                    <span>그룹</span>
                </li>

				<li class="count headerSort" @click="groupTeamCountSort"
                    :class="{
                            sortOff  : group_team_sort_target != 'COUNT',
                            sortUp   : group_team_sort_target == 'COUNT' && group_team_sort_direction == 'ASC', 
                            sortDown : group_team_sort_target == 'COUNT' && group_team_sort_direction == 'DESC', 
                        }">
                    <span>멤버</span>
                </li>

			</ul>

            <!-- 멤버 헤더 -->
            <ul class="sortHeader teamHeader">

                <li class="grpCheck" v-if="share_option != 'MOVE'">
                    <p class="grpTeamCheckAll" >
                        <input type="checkbox" id="grpTeamCheckAll" v-model="member_check_all" @change="memberAllSelect">
                        <label for="grpTeamCheckAll">전체선택</label>
                    </p>
                </li>

                <li class="num">No</li>

                <li class="pNm headerSort" @click="memberNameSort"
                    :class="{
                            sortOff  : member_sort_target != 'NAME',
                            sortUp   : member_sort_target == 'NAME' && member_sort_direction == 'ASC', 
                            sortDown : member_sort_target == 'NAME' && member_sort_direction == 'DESC', 
                        }">
                    <span>이름</span>
                </li>

                <li class="pos headerSort" @click="memberRoleSort"
                    :class="{
                            sortOff  : member_sort_target != 'ROLE',
                            sortUp   : member_sort_target == 'ROLE' && member_sort_direction == 'ASC', 
                            sortDown : member_sort_target == 'ROLE' && member_sort_direction == 'DESC', 
                        }">
                    <span>권한</span>
                </li>

            </ul>

            <!-- 공유 내용 영역 시작 -->
            <div class="shareUlDiv">
                <div class="noResult" v-show="group_team_search_list == null || group_team_search_list.length < 1">검색 결과가 없습니다</div>
                <ul class="grpUl" v-show="group_team_search_list != null && group_team_search_list.length > 0">

                    <li :class="{ on : group_info.shareListOn == true, withTeams : group_info.teams != null && group_info.teams.length > 0, checked : group_info.shareChecked == true  }" 
                        :key="group_info.group_id" v-for="(group_info, group_index) in group_team_search_list">
                        
                        <div class="grp">
                            <input type="checkbox" :id="`grpCheck${group_index + 1}`" v-model="group_info.shareChecked" @change="checkGroupOrTeam(group_index, 0, 'GROUP', group_info.group_id)" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')"/>
                            <label :for="`grpCheck${group_index + 1}`" @click="clickGroupOrTeam(group_index, 0, 'GROUP', group_info.group_id)" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')"></label>
                            <p class="num">{{ group_index + 1 }}</p>
                            <p class="img" :style="{ backgroundImage : `url(/app_images/profile/group/${Math.floor(group_info.group_id / 10000)}/${group_info.group_id}.jpg)`}">그룹 사진</p>
                            <img :src="`/app_images/profile/group/${Math.floor(group_info.group_id / 10000)}/${group_info.group_id}.jpg`" @error="imageError($event, group_info)" style="display : none;" />
                            <h2 class="nm">{{ group_info.group_name }}</h2>
                            <p class="cl dc0" :style="{ backgroundColor : `${hodu_hex_color_process(group_info.color)} !important` }">그룹 색</p>
                            <p class="count" v-if="group_info.user_count > 1">
                                <a @click="memberCountClick('GROUP', group_info.group_id, 0, group_index, -1)">
                                    <span> 
                                        {{ group_info.shareChecked == true && group_ids.indexOf(group_info.group_id) > -1 ? ( group_info.user_count - 1 ) : group_user_ids[group_info.group_id] == null ? 0 : group_user_ids[group_info.group_id].length }}  
                                    </span> / {{ group_info.user_count - 1 }} 
                                </a>
                            </p> 
                            <p class="count noCount" v-if="group_info.user_count <= 1">
                                멤버 없음
                            </p>
                            <a class="showTeams" v-if="group_info.teams != null && group_info.teams.length > 0" @click="showTeamList(group_index, group_info)" :class="{ on : group_info.shareListOn == true }">하위그룹 보기</a>
                        </div>

                        <!-- 팀 ul 영역 시작 -->
                        <ul class="teamUl" v-show="group_info.teams != null && group_info.teams.length > 0">
                            <li :key="team.team_id" v-for="(team, team_index) in group_info.teams" :class="{checked : team.shareChecked == true}">
                                <div class="grp">
                                    <input type="checkbox" :id="`grpCheck${group_index + 1}-${team_index + 1}`" v-model="team.shareChecked" @change="checkGroupOrTeam(group_index, team_index, 'TEAM', team.team_id)" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')">
                                    <label :for="`grpCheck${group_index + 1}-${team_index + 1}`" @click="clickGroupOrTeam(group_index, team_index, 'TEAM', team.team_id)" v-if="!(event_crud_type == 'CREATE' && share_option == 'MOVE')"></label>
                                    <p class="num">{{ `${group_index + 1}-${team_index + 1}` }}</p>
                                    <p class="img" :style="{ backgroundImage : `url(/app_images/profile/team/${Math.floor(team.team_id / 10000)}/${team.team_id}.jpg)`}">팀 사진</p>
                                    <img :src="`/app_images/profile/team/${Math.floor(team.team_id / 10000)}/${team.team_id}.jpg`" @error="imageError($event, team)" style="display : none;" />
                                    <h3 class="nm">{{ team.team_name }}</h3>
                                    <p class="cl dc0" :style="{ backgroundColor : `${hodu_hex_color_process(team.color)} !important` }">팀 색</p>
                                    <p class="count" v-if="team.user_count > 1">
                                        <a @click="memberCountClick('TEAM', team.group_id, team.team_id, group_index, team_index)">
                                            <span>
                                                {{ team.shareChecked == true && team_ids.indexOf(team.team_id) > -1 ? ( team.user_count - 1 ) : team_user_ids[team.team_id] == null ? 0 : team_user_ids[team.team_id].length }}  
                                            </span> / {{ team.user_count - 1 }}
                                        </a>
                                    </p>
                                    <p class="count noCount" v-if="team.user_count <= 1">
                                        멤버 없음
                                    </p> 
                                </div>
                            </li>                                
                        </ul>
                        <!-- 팀 ul 영역 끝 -->

                    </li>

                </ul>
            </div>
        </div>
        <!-- 공유 내용 영역 끝 -->

        <!-- 멤버 -->
        <div class="peepsDiv" :class="{ on : view_group_team_member == true }">
            <div class="peepsUlDiv">
                <ul class="peeps">
                    
                    <li :key="member.user_id" v-for="(member, index) in group_team_search_members">
                        <input type="checkbox" :id="`grpPeepCheck${index}`" v-model="member.checked" @change="memberChange(index)">
                        <!-- <label :for="`grpPeepCheck${index}`" @click="memberChange(member.team_id > 0 ? 'TEAM' : 'GROUP', member.team_id > 0 ? member.team_id : member.group_id, member.user_id, !member.checked)"></label> -->
                        <label :for="`grpPeepCheck${index}`" @click="memberClick(index)"></label>
                        <p class="num">{{ index + 1 }}</p>
                        <p class="fPic" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor((member.user_id != null ? member.user_id : 0) / 10000)}/${member.user_id}.jpg)` }">멤버 사진</p>
                        <img class="fPicDummy" :src="`/app_images/profile/user/${Math.floor((member.user_id != null ? member.user_id : 0) / 10000)}/${member.user_id}.jpg`" style="display : none;" @error="userImageError($event)" />
                        <p class="pNm">{{ member.user_info.user_name }}</p>
                        <p class="pos" :class="{ king    : member.role[0] == 'ADMIN' || member.role[0] == 'GROUP_MANAGER' || member.role[0] == 'TEAM_MANAGER', 
                                                 manager : member.role[0] == 'GROUP_SUB_MANAGER', 
                                                 mem     : member.role[0] == 'GROUP_USER' || member.role[0] == 'TEAM_USER' }">
                            {{ member.role[0] == 'GROUP_MANAGER'     ? "최종관리자" :
                               member.role[0] == 'GROUP_MANAGER'     ? "관리자"   : member.role[0] == 'TEAM_MANAGER' ? "팀장" : 
                               member.role[0] == 'GROUP_SUB_MANAGER' ? "부관리자" :
                               member.role[0] == 'GROUP_USER'        ? "멤버"     : member.role[0] == 'TEAM_USER' ? "팀원" : "" }}
                        </p>
                    </li>

                </ul>
            </div>

        </div>
        <!-- 멤버 끝 -->
        <div class="btns">
            <input type="button" class="input_btn" value="취소" @click="cancelButtonClick">
            <input type="button" class="input_btn" :value="view_group_team_member == false ? '공유' : '확인'" @click="shareOrConfirmButtonClick">
        </div>
        
    </div>

</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { OWNER_TYPE, CRUD_TYPE, API_METHOD, SHARE_OPTION } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';

import { GroupTeamInfo } from '@/store/modules/GroupInfo';
import { EventShareInfo } from '@/store/modules/EventInfo';
import { t_event } from '@/model/event';

const GroupInfo = namespace('GroupInfo');
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

@Component({

}) export default class EventShare extends Mixins(VueHoduCommon) {

    SHARE_OPTION : any = SHARE_OPTION;

    /**
     * EventInfo.State
     */
    @EventInfo.State  event               !: t_event;
    @EventInfo.State  event_crud_type     !: string
    @EventInfo.State  event_share_info    !: EventShareInfo;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventShare ?: any;

    share_option : SHARE_OPTION = SHARE_OPTION.SHARE // 일정 공유 타입

    group_team_list        : GroupTeamInfo[] = [];
    group_team_search_list : GroupTeamInfo[] = [];

    user_ids  : number[] = []; // 선택된 user_id  담을 배열(group_user_ids와 team_user_ids를 for문으로 돌려서 중복 제거해야함)
    group_ids : number[] = []; // 선택된 group_id 담을 배열
    team_ids  : number[] = []; // 선택된 team_id  담을 배열

    group_user_ids : number[][] = []; // 그룹별 선택 유저 담을 배열
    team_user_ids  : number[][] = []; // 팀별 선택 유저 담을 배열

    view_search_group_team : boolean = false;
    view_search_member     : boolean = false;

    view_group_team_member : boolean = false;

    member_target    : string = "";
    member_target_id : number = 0;

    group_team_members        : any = [];
    group_team_search_members : any = [];

    member_check_all     : boolean = false;
    member_checked_count : number  = 0;

    group_team_sort_target    : string | null = null;   // NAME, COUNT 
    group_team_sort_direction : string = "ASC";         // ASC, DESC
    member_sort_target        : string | null = null;   // NAME, ROLE
    member_sort_direction     : string = "ASC";         // ASC, DESC

    group_team_search_query        : string = "";
    group_team_member_search_query : string = "";

    reset_id : number = 0;

    async beforeMount() {
        this.share_option = this.event_share_info.share_option ? this.event_share_info.share_option : SHARE_OPTION.SHARE;

        // await this.makeGroupInfoList() - 그룹정보 재조회
        this.group_team_list = JSON.parse(JSON.stringify(this.group_info_list));

        if( this.auth_info == null || this.auth_info.auth_check == false ) {
            this.group_team_list = this.group_team_list.filter(
                group => group.biz_type != 'BIZH' && group.group_info.project_type != 'kids' 
            );
        }
        
        // user_ids, group_ids, team_ids, group_user_ids, team_user_ids 세팅
        this.user_ids       = this.user_ids.concat(this.event_share_info.user_ids);
        this.group_ids      = this.group_ids.concat(this.event_share_info.group_ids);
        this.team_ids       = this.team_ids.concat(this.event_share_info.team_ids);
        this.group_user_ids = this.group_user_ids.concat(this.event_share_info.group_user_ids);
        this.team_user_ids  = this.team_user_ids.concat(this.event_share_info.team_user_ids);

        // 그룹, 팀 공유 되었는지 여부 조사
        const group_count : number = this.group_team_list.length;
        for( let i = 0; i < group_count; i++ ) {

            // 그룹 공유 조사
            if( this.group_ids.indexOf(this.group_team_list[i].group_id) > -1 ) {
                const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[i]));
                group_obj.shareChecked = true;
                this.group_team_list.splice(i, 1, group_obj);
            }

            // 팀 공유 조사
            const team_count : number = this.group_team_list[i].teams.length;
            for( let j = 0; j < team_count; j++ ) {
                if( this.team_ids.indexOf(this.group_team_list[i].teams[j].team_id) > -1 ) {
                    const team_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[i].teams[j]));
                    team_obj.shareChecked = true;
                    this.group_team_list[i].teams.splice(j, 1, team_obj);
                }
            }
        }

        // group_user_ids에 해당 그룹 데이터가 있다면 shareChecked = true;
        const group_user_ids_count : number = this.group_user_ids.length;
        for( let i = 0; i < group_user_ids_count; i++ ) { 
            if( this.group_user_ids[i] == null || this.group_user_ids[i].length < 1 ) {
                continue;
            }

            for( let j = 0; j < group_count; j++ ) {
                if( this.group_team_list[j].group_id == i ) {
                    const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[j]));
                    group_obj.shareChecked = true;
                    this.group_team_list.splice(j, 1, group_obj);
                }
            }
        }

        // team_user_ids에 해당 팀 데이터가 있다면 shareChecked = true;    
        const team_user_ids_count : number = this.team_user_ids.length;
        for( let i = 0; i < team_user_ids_count; i++ ) {
            if( this.team_user_ids[i] == null || this.team_user_ids[i].length < 1 ) {
                continue;
            }

            for( let j = 0; j < group_count; j++ ) {
                
                const team_count : number = this.group_team_list[j].teams.length;
                for( let k = 0; k < team_count; k++ ) {
                    if( this.group_team_list[j].teams[k].team_id == i ) {
                        const team_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[j].teams[k]));
                        team_obj.shareChecked = true;
                        this.group_team_list[j].teams.splice(k, 1, team_obj);
                    }
                }

            }

        }
        
        this.group_team_search_list = JSON.parse(JSON.stringify(this.group_team_list));
    }

    mounted() {
    
        // @ts-ignore
        $('.shareUlDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : 316,
            scrollbarPosition : 'outside',
        });

        // @ts-ignore
        $('.peepsUlDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : 316,
            scrollbarPosition : 'outside',
        });

        if( this.group_info_list == null ) {
            return;
        }
    }

    /**
     * 그룹, 팀 이미지 에러 날시 그룹색상에 맞는 Default 그룹이미지를 보여주도록 클래스를 부여한다 
     */
    imageError(event, group_info : GroupTeamInfo) : void {
        let temp_class : string = "";
        switch( new String(group_info.color).toUpperCase().slice(0, 7) ) {
            /**
             * dc - 진한색
             */
            case '#FF6363':
                temp_class = 'imgDc0';
                break;

            case '#FFA70E':
                temp_class = 'imgDc1';
                break;

            case '#FFC72F':
                temp_class = 'imgDc2';
                break;

            case '#FF198B':
                temp_class = 'imgDc3';
                break;

            case '#00B2C7':
                temp_class = 'imgDc4';
                break;

            case '#13D08B':
                temp_class = 'imgDc5';
                break;

            case '#4DBAFF':
                temp_class = 'imgDc6';
                break;

            case '#477FFF':
                temp_class = 'imgDc7';
                break;

            case '#6854FF':
                temp_class = 'imgDc8';
                break;

            case '#35405A':
                temp_class = 'imgDc9';
                break;
            
            /**
             * lc - 옅은색 (현재 그룹에 옅은색은 없지만 대비용으로 만들어 놓음)
             */
            case '#B9A494':
                temp_class = 'imgLc0';
                break;

            case '#E67D9B':
                temp_class = 'imgLc1';
                break;

            case '#FF9898':
                temp_class = 'imgLc2';
                break;

            case '#AB8DD6':
                temp_class = 'imgLc3';
                break;

            case '#F3AC77':
                temp_class = 'imgLc4';
                break;

            case '#E3D37A':
                temp_class = 'imgLc5';
                break;

            case '#8DD6A0':
                temp_class = 'imgLc6';
                break;

            case '#7197ED':
                temp_class = 'imgLc7';
                break;

            case '#8DAED6':
                temp_class = 'imgLc8';
                break;

            case '#6F7A93':
                temp_class = 'imgLc9';
                break;

            default:
                temp_class = 'imgDc7';
                break;

        }

        $(event.target).parent().find('.img').addClass(temp_class);
    }

    /**
     * 멤버 카운트 클릭 : 멤버 목록 띄우기
     */
    async memberCountClick(target : string, group_id : number, team_id : number, group_index : number, team_index : number) : Promise<void> {
        const vue = this;

        if( this.share_option == SHARE_OPTION.MOVE ) {
            const group = this.group_team_search_list[group_index];

            if( team_index > -1 ) {
                const team = group.teams[team_index];
                
                if( team.shareChecked ) {
                    return;
                }
            }
            
            else if( group.shareChecked ) {
                return;
            }
        }
        
        this.member_target = target;
        this.member_target_id = target == 'TEAM' ? team_id : group_id;

        /**
         * 기존에 있던 멤버리스트 비움
         */
        this.group_team_members.splice(0, this.group_team_members.length);

        // api/v1/groups/getGroupMember/{group_id} 또는 api/v1/teams/getTeamMember/{group_id}/{team_id}
        this.hodu_api_call(`api/v1/${ target == "GROUP" ? 'groups' : 'teams' }/${ target == "GROUP" ? 'getGroupMember' : 'getTeamMember' }/${group_id}${ team_id > 0 ? `/${team_id}` : ''}`, API_METHOD.GET)
            .then(async response => {
                console.log(JSON.stringify(response));

                vue.group_team_members = response.data.data.list;
                vue.member_checked_count = 0;

                let user_index : number = -1;
                vue.member_check_all = true;

                const member_count : number = vue.group_team_members.length;
                for( let i = 0; i < member_count; i++ ) {
                    
                    if( this.user_id == vue.group_team_members[i].user_id ) {
                        user_index = i;
                    }
                    
                    // 타겟이 그룹일때
                    if( target == 'GROUP' ) {
                        
                        // 해당 그룹이 체크 된 경우 전부 true로
                        const group_index : number = vue.group_ids.indexOf(group_id);
                        if( group_index > -1 ) {
                            if( vue.user_id != vue.group_team_members[i].user_id ) {
                                vue.member_checked_count++;
                            }
                            vue.group_team_members[i].checked = true;
                            continue;
                        } 

                        // group_user_ids에 해당 팀의 값이 없을때
                        if( vue.group_user_ids[`${group_id}`] == null ) {
                            vue.group_team_members[i].checked = false;
                            continue;
                        }
                        
                        // 그룹 ids 정보에 해당 유저의 아이디가 들어있는지 안들어있는지 여부 등록
                        const group_user_index : number =vue.group_user_ids[`${group_id}`].indexOf(vue.group_team_members[i].user_id);
                        vue.group_team_members[i].checked = group_user_index == -1 ? false : true;

                        if( vue.group_team_members[i].checked == true ) {
                            vue.member_checked_count++;
                        }
                    }

                    // 타겟이 팀일때
                    else {

                        // 해당 팀이 체크 된 경우 전부 true로
                        const team_index : number = vue.group_ids.indexOf(group_id);
                        if( team_index > -1 ) {
                            if( vue.user_id != vue.group_team_members[i].user_id ) {
                                vue.member_checked_count++;
                            }
                            vue.group_team_members[i].checked = true;
                            continue;
                        } 
                        
                        // team_user_ids에 해당 팀의 값이 없을때
                        if( vue.team_user_ids[`${team_id}`] == null ) {
                            vue.group_team_members[i].checked = false;
                            continue;
                        }

                        // 팀 ids 정보에 해당 유저의 아이디가 들어있는지 안들어있는지 여부 등록
                        const team_user_index : number = vue.team_user_ids[`${team_id}`].indexOf(vue.group_team_members[i].user_id);
                        vue.group_team_members[i].checked = team_user_index == -1 ? false : true;

                        if( vue.group_team_members[i].checked == true ) {
                            vue.member_checked_count++;
                        }
                    }
                }

                // 사용자 본인 제외
                vue.group_team_members.splice(user_index, 1);

                // 하나라도 체크된 상태가 아니였다면 member_check_all = false;
                const new_member_count : number = vue.group_team_members.length;
                for( let i = 0; i < new_member_count; i++ ) {
                    
                    if( vue.group_team_members[i].checked == false ) {
                        vue.member_check_all = false;
                    }
                }

                // 멤버 보기
                vue.view_group_team_member = true;
                
                vue.group_team_search_members.splice(0, vue.group_team_search_members.length);
                vue.group_team_search_members = vue.group_team_search_members.concat(vue.group_team_members);
            })
            .catch(async e => {
                this.hodu_error_process(e, false, false);
            });

    }

    /**
     * 팀 리스트 펼치기 / 접기 
     */
    async showTeamList( index :number, group_team_info : GroupTeamInfo ) : Promise<void> {

        group_team_info.shareListOn = group_team_info.shareListOn == null || group_team_info.shareListOn == false ? true : false;

        if( this.group_team_search_list == null ) {
            return;
        }

        this.group_team_search_list.splice(index, 1, group_team_info);
        await this.setOriginalGroupTeamList();
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 그룹, 팀 체크 클릭
     */
    clickGroupOrTeam(group_index : number, team_index : number, target : string, target_id : number) {

        if( this.share_option == SHARE_OPTION.MOVE ) {
            let original_checked = false;

            const group = this.group_team_search_list[group_index];
            
            if( target == 'GROUP' ) {
                original_checked = group.shareChecked;
            }

            else {
                original_checked = group.teams[team_index].shareChecked;
            }

            this.reset();

            if( original_checked == true ) {
                this.reset_id = target_id; 
            }

        }
    }

    /**
     * 그룹, 팀 선택 or 선택 해제
     */
    async checkGroupOrTeam(group_index : number, team_index : number, target : string, target_id : number) : Promise<void> {

        this.$nextTick(async() => {

            if( this.group_team_search_list == null ) {
                return;
            }

            // 그룹 선택 or 선택 해제
            if( target == "GROUP" ) {
                const group_ids_index : number = this.group_ids.indexOf(target_id);
                const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[group_index]));
                
                // 체크 해제라면
                if( group_obj.shareChecked == false ) {
                    // group_ids에 있다면 제거
                    if( this.group_ids.indexOf(group_obj.group_id) > - 1) {
                        this.group_ids.splice(this.group_ids.indexOf(group_obj.group_id), 1);
                    }
                    
                    // group_user_ids[group_id]에 데이터가 있다면 제거
                    if( this.group_user_ids[group_obj.group_id] != null && this.group_user_ids[group_obj.group_id].length > 0 ) {
                        this.group_user_ids[group_obj.group_id].splice(0, this.group_user_ids[group_obj.group_id].length);
                    }
                }

                // 체크라면
                else if( this.reset_id != target_id ) {
                    this.group_ids.push(target_id);
                }

                else if( this.reset_id == target_id ) {
                    group_obj.shareChecked = false;
                    this.group_team_search_list.splice(group_index, 1, group_obj);
                    this.reset_id = 0;
                }
            }

            // 팀 선택 or 선택 해제
            else {
                const team_ids_index : number = this.team_ids.indexOf(target_id);
                const team_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[group_index].teams[team_index]));

                // 체크 해제라면
                if( team_obj.shareChecked == false ) {
                    // team_ids에 있다면 제거
                    if( this.team_ids.indexOf(team_obj.team_id) > - 1) {
                        this.team_ids.splice(this.team_ids.indexOf(team_obj.team_id), 1);
                    }
                    
                    // team_user_ids[team_id]에 데이터가 있다면 제거
                    if( this.team_user_ids[team_obj.team_id] != null && this.team_user_ids[team_obj.team_id].length > 0 ) {
                        this.team_user_ids[team_obj.team_id].splice(0, this.team_user_ids[team_obj.team_id].length);
                    }
                }

                // 체크라면
                else if( this.reset_id != target_id ) {
                    this.team_ids.push(target_id);
                }

                else if( this.reset_id == target_id ) {
                    team_obj.shareChecked = false;
                    this.group_team_search_list[group_index].teams.splice(team_index, 1, team_obj);
                    this.reset_id = 0;
                }

            }

            // 체크 값 계산
            await this.calculateCheck();
            await this.setOriginalGroupTeamList();
        });
    }

    /**
     * 멤버 전체 선택
     */
    memberAllSelect() : void {
        const member_search_count : number = this.group_team_search_members.length;
        for( let i = 0; i < member_search_count; i++ ) {
            const member_obj : any = JSON.parse(JSON.stringify(this.group_team_search_members[i]));
            member_obj.checked = ( this.member_check_all == true ); // 전체선택값
            this.group_team_search_members.splice(i, 1, member_obj);
        }

        const member_original_count : number = this.group_team_members.length;
        for( let i = 0; i < member_original_count; i++ ) {
            const member_obj : any = JSON.parse(JSON.stringify(this.group_team_members[i]));
            member_obj.checked = ( this.member_check_all == true ); // 전체선택값
            this.group_team_members.splice(i, 1, member_obj);
        }

        if( this.member_check_all == false ) { this.member_checked_count = 0; }
    }

    /**
     * 그룹, 팀 멤버 체크 클릭시 이동이면 기존 선택 초기화
     */
    memberClick(click_index : number) : void {
        if( this.share_option == SHARE_OPTION.MOVE ) {
            const original_checked : boolean = this.group_team_search_members[click_index].checked;
            this.reset();

            if( original_checked == true ) {
                const member_copy = JSON.parse(JSON.stringify(this.group_team_search_members[click_index]));
                member_copy.checked = true;
                this.group_team_search_members.splice(click_index, 1, member_copy);
            }
        }
    }

    /**
     * 그룹, 팀 멤버 클릭시 count 변경 및 전체 선택 체크 변경
     */
    memberChange(click_index : number) : void {
        this.$nextTick(() => {
            this.member_checked_count = this.getMemberCheckedCount(click_index);
            this.member_check_all     = (this.member_checked_count == this.group_team_members.length);
        });
    }

    /**
     * 그룹, 팀 멤버가 체크된게 몇명인지 반환
     */
    getMemberCheckedCount(click_index : number) : number {
        try {
            let checked_memeber_count : number = 0;
                
            const member_count : number = this.group_team_search_members.length;
            for( let i = 0; i < member_count; i++ ) {
                if( this.group_team_search_members[i].checked == true ) {
                    checked_memeber_count++;
                }
            }

            return checked_memeber_count;

        } catch(e) {
            this.hodu_error_process(e, false, false);
            return 0;
        }

    }

    /**
     * 취소버튼 클릭 
     * 1. 그룹, 팀리스트 보는 중에는 Modal이 닫힘
     * 2. 그룹, 팀의 멤버 리스트를 보는 중에는 그룹, 팀 리스트로 변경
     */
    cancelButtonClick() : void {
        
        // 그룹, 팀 멤버 리스트 보는 중
        if( this.view_group_team_member == true ) {
            this.view_group_team_member         = false;
            this.view_search_member             = false;
            this.group_team_member_search_query = "";
        } 
        
        // 그룹, 팀 리스트 보는 중
        else {
            this.shareModalClose();
        }
    }

    /**
     * shareModal 닫기
     */
    shareModalClose() : void {
        this.doSetShowEventShare(false);
    }

    /**
     * 공유 또는 확인 버튼 클릭
     */
    shareOrConfirmButtonClick() : void {
        
        // 그룹, 팀 멤버 리스트 보는 중
        if( this.view_group_team_member == true ) {
            this.memberShareConfirm();
            this.view_group_team_member         = false;
            this.view_search_member             = false;
            this.group_team_member_search_query = "";
        }

        // 그룹, 팀 리스트 보는 중
        else {
            const event_share_info : EventShareInfo = {
                share_option : this.share_option,
                user_ids : this.user_ids,
                group_ids : this.group_ids,
                team_ids : this.team_ids,
                group_user_ids : this.group_user_ids,
                team_user_ids : this.team_user_ids
            };

            
            // 일정 화면으로 데이터 전달
            if( this.event_crud_type == CRUD_TYPE.CREATE ) {
                this.doSetEventShareInfo(event_share_info);
                this.doSetShowEventShare(false);
                window['setShareInfo'](); // Event.vue setShareInfo();
                return;
            }

            // 조회일때는 데이터 전달하지 않고 바로 공유 API 연결
            else {
                if( this.user_ids.length < 1 && this.group_ids.length < 1 && this.team_ids.length < 1 ) {
                    alert(`전송 대상을 선택 해주세요`);
                    return;
                }
                
                const vue = this;

                let scope : string = "";
                let scope_id : number | undefined = 0;

                scope = this.event.event_type ? this.event.event_type : OWNER_TYPE.PERSONAL;

                scope_id = scope == OWNER_TYPE.PERSONAL ? this.user_id 
                                                        : scope == OWNER_TYPE.GROUP ? this.event.group_id : this.event.team_id;

                if( this.share_option == SHARE_OPTION.SHARE ) {
                    this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/share`, API_METHOD.POST, {
                        "subscribe_users"  : this.user_ids,
                        "subscribe_groups" : this.group_ids,
                        "subscribe_teams"  : this.team_ids
                    }).then((response) => {
                        console.log(response);
                        alert("공유 완료");
                        this.doSetShowEventShare(false);
                    }).catch((e) => {
                        this.hodu_error_process(e, false, false);
                    });
                }

                else if ( this.share_option == SHARE_OPTION.COPY ) {
                    this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/share/copy`, API_METHOD.POST, {
                        "subscribe_users"  : this.user_ids,
                        "subscribe_groups" : this.group_ids,
                        "subscribe_teams"  : this.team_ids
                    }).then((response) => {
                        console.log(response);
                        alert("복사 요청 완료");
                        this.doSetShowEventShare(false);
                    }).catch((e) => {
                        this.hodu_error_process(e, false, false);
                    });
                }

                else if ( this.share_option == SHARE_OPTION.MOVE ) {
                    this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${scope}/${scope_id}/${this.event.event_id}/share/move`, API_METHOD.POST, {
                        "subscribe_users"  : this.user_ids,
                        "subscribe_groups" : this.group_ids,
                        "subscribe_teams"  : this.team_ids
                    }).then((response) => {
                        console.log(response);
                        alert("이동 요청 완료");
                        this.doSetShowEventShare(false);

                        if( this.group_ids.length + this.team_ids.length > 0 ) this.movePrevPage();
                        
                    }).catch((e) => {
                        this.hodu_error_process(e, false, false);
                    });
                }

                
            }

        }
    }

    /**
     * 멤버 공유 확인
     */
    async memberShareConfirm() : Promise<void> {
        
        let shareChecked : boolean = false;

        // 그룹
        if( this.member_target == 'GROUP' ) {
            // group_user_ids[target_id]를 NULL 체크 후 group_user_ids[target_id] = []로 초기화
            if( this.group_user_ids[this.member_target_id] == null ) {
                this.group_user_ids[this.member_target_id] = [];
            }
            
            // group_user_ids[target_id]를 완전히 비운다
            this.group_user_ids[this.member_target_id].splice(0, this.group_user_ids[this.member_target_id].length);

            // 전부 체크 되어 있다면 (this.member_check_all == true)
            if( this.member_check_all == true ) {
                // group_user_ids[target_id]를 전부 비운다
                this.group_user_ids[this.member_target_id].splice(0, this.group_user_ids[this.member_target_id].length);

                // group_ids 에 해당 target_id를 집어넣는다
                this.group_ids.push(this.member_target_id);
            }

            // 하나라도 체크 되어 있지 않다면 (this.member_check_all == false)
            else {
                // group_ids 에서 해당 target_id를 제거한다
                if( this.group_ids.indexOf(this.member_target_id) > -1 ) {
                    this.group_ids.splice(this.group_ids.indexOf(this.member_target_id), 1);
                }

                // group_user_ids에 체크된 유저 아이디들을 전부 집어넣는다
                const member_count : number = this.group_team_search_members.length;
                for( let i = 0; i < member_count; i++ ) {
                    if( this.group_team_search_members[i].checked == true ) {
                        this.group_user_ids[this.member_target_id].push(this.group_team_search_members[i].user_id);
                    }
                }
            }

            // group_ids에 target_id도 들어있지 않고 group_user_ids[target_id]도 비어있다면 해당 그룹은 선택되지 않은것 (반대로 둘 중 하나라도 만족하면 선택된 것)
            shareChecked = ( this.group_ids.indexOf(this.member_target_id) > -1 ) || ( this.group_user_ids[this.member_target_id].length > 0 );
        }

        // 팀
        else {
            
            // team_user_ids[member_target_id]를 NULL 체크 후 team_user_ids = []로 초기화
            if( this.team_user_ids[this.member_target_id] == null ) {
                this.team_user_ids[this.member_target_id] = [];
            }

            // team_user_ids[target_id]를 완전히 비운다
            this.team_user_ids[this.member_target_id].splice(0, this.team_user_ids[this.member_target_id].length);

            // 전부 체크 되어 있다면 (this.member_check_all == true)
            if( this.member_check_all == true ) {
                // team_user_ids[target_id]를 전부 비운다
                this.team_user_ids[this.member_target_id].splice(0, this.team_user_ids[this.member_target_id].length);

                // team_ids 에 해당 target_id를 집어넣는다
                this.team_ids.push(this.member_target_id);
            }

            // 하나라도 체크 되어 있지 않다면 (this.member_check_all == false)
            else {
                // team_ids 에서 해당 target_id를 제거한다
                if( this.team_ids.indexOf(this.member_target_id) > -1 ) {
                    this.team_ids.splice(this.team_ids.indexOf(this.member_target_id), 1);
                }

                // group_user_ids에 체크된 유저 아이디들을 전부 집어넣는다
                const member_count : number = this.group_team_search_members.length;
                for( let i = 0; i < member_count; i++ ) {
                    if( this.group_team_search_members[i].checked == true ) {
                        this.team_user_ids[this.member_target_id].push(this.group_team_search_members[i].user_id);
                    }
                }
            }

            // team_ids에 target_id도 들어있지 않고 team_user_ids[target_id]도 비어있다면 해당 그룹은 선택되지 않은것 (반대로 둘 중 하나라도 만족하면 선택된 것)
            shareChecked = ( this.team_ids.indexOf(this.member_target_id) > -1 ) || ( this.team_user_ids[this.member_target_id].length > 0 );
        }

        await this.groupTeamSelect(shareChecked);
        await this.calculateCheck();
    }

    /**
     * target, target_id를 이용해서 group_team_search_list에서 찾아내서 shareChecked를 업데이트 한다
     */
    async groupTeamSelect(shareChecked : boolean) : Promise<void> { 
        const group_length : number = this.group_team_search_list.length;
        for( let i = 0; i < group_length; i++ ) {
            
            // 타겟이 그룹인 경우
            if( this.member_target == 'GROUP' ) {
                if( this.group_team_search_list[i].group_id == this.member_target_id ) {
                    const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[i]));
                    group_obj.shareChecked = shareChecked;
                    this.group_team_search_list.splice(i, 1, group_obj);
                }
            }

            // 타겟이 팀인 경우
            else {
                if( this.group_team_search_list[i].teams == null ) {
                    this.group_team_search_list[i].teams = [];
                }   

                const team_length : number = this.group_team_search_list[i].teams.length;
                for( let j = 0; j < team_length; j++ ) {
                    if( this.group_team_search_list[i].team_id == this.member_target_id ) {
                        const team_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[i].teams[j]));
                        team_obj.shareChecked = shareChecked;
                        this.group_team_search_list[i].teams.splice(j, 1, team_obj);
                    }
                }
            }

        } 

        await this.setOriginalGroupTeamList();
    }

    /**
     * group_user_ids와 team_user_ids 계산해서 user_ids 생산
     */
    async calculateCheck() : Promise<void> {
        this.user_ids.splice(0, this.user_ids.length);
        
        // user_ids가 중복되지 않게 group_user_ids => user_ids로 옮김
        const group_user_ids_length : number = this.group_user_ids.length;
        for( let i = 0; i < group_user_ids_length; i++ ) {
            if( this.group_user_ids[i] == null ) {
                continue;
            }

            const group_user_ids_i_length : number = this.group_user_ids[i].length;
            for( let j = 0; j < group_user_ids_i_length; j++ ) {
                if( this.user_ids.indexOf(this.group_user_ids[i][j]) == -1 ) {
                    this.user_ids.push(this.group_user_ids[i][j]);
                }
            }
        }

        // user_ids가 중복되지 않게 team_user_ids => user_ids로 옮김
        const team_user_ids_length : number = this.team_user_ids.length;
        for( let i = 0; i < team_user_ids_length; i++ ) {
            if( this.team_user_ids[i] == null ) {
                continue;
            }

            const team_user_ids_i_length : number = this.team_user_ids[i].length;
            for( let j = 0; j < team_user_ids_i_length; j++ ) {
                if( this.user_ids.indexOf(this.team_user_ids[i][j]) == -1 ) {
                    this.user_ids.push(this.team_user_ids[i][j]);
                }
            }
        }

        this.user_ids.sort();
    }

    /**
     * 검색 버튼 toggle
     */
    searchButtonClick() : void {
        
        // 그룹, 팀 리스트 보는 중 일때
        if( this.view_group_team_member == false ) {
            this.view_search_group_team = !this.view_search_group_team;
        }

        // 멤버 리스트 보는 중 일때
        else {
            this.view_search_member = !this.view_search_member;
        }

        // 그룹, 팀 검색을 끄는 경우
        if( this.view_search_group_team == false ) {
            this.group_team_search_query = '';
        }

        // 멤버 검색을 끄는 경우
        if( this.view_search_member == false ) {
            this.group_team_member_search_query = '';
        }
    }

    /**
     * 그룹, 팀 리스트 이름 기준 정렬
     */
    groupTeamNameSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.group_team_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.group_team_sort_direction ) {
                case "ASC":
                    this.group_team_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.group_team_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.group_team_sort_target = "NAME";
            this.group_team_sort_direction = "ASC";
        }
        
        if( this.group_team_sort_target == null ) { this.searchGroupAndTeamFunction(); } 
        else { this.groupTeamSort(); }
    }

    /**
     * 그룹, 팀 리스트 멤버수 기준 정렬
     */
    groupTeamCountSort() : void {

        // 이미 COUNT Sort 중이라면
        if( this.group_team_sort_target == "COUNT" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.group_team_sort_direction ) {
                case "ASC":
                    this.group_team_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.group_team_sort_target = null;
                    break; 
            }
        }
        
        // COUNT Sort가 아니였다면
        else {
            this.group_team_sort_target = "COUNT";
            this.group_team_sort_direction = "ASC";
        }

        if( this.group_team_sort_target == null ) { this.searchGroupAndTeamFunction(); } 
        else { this.groupTeamSort(); }
    }

    /**
     * 그룹, 팀 정렬
     */
    groupTeamSort() : void {
        
        const sort_function : (a : GroupTeamInfo, b : GroupTeamInfo) => number = (o1 : GroupTeamInfo, o2 : GroupTeamInfo) : number => {
            // 그룹, 팀 이름 오름차순
            if( this.group_team_sort_target == "NAME" && this.group_team_sort_direction == "ASC" ) {
                if( o1.team_name == null || o1.team_name.length < 1 || o2.team_name == null || o2.team_name.length < 1 ) {
                    if( o1.group_name  > o2.group_name ) { return  1; } 
                    if( o1.group_name  < o2.group_name ) { return -1; } 
                    if( o1.group_name == o2.group_name ) { return  0; }
                } 

                else {
                    if( o1.team_name  > o2.team_name ) { return  1; } 
                    if( o1.team_name  < o2.team_name ) { return -1; } 
                    if( o1.team_name == o2.team_name ) { return  0; }
                }
            }

            // 그룹, 팀 이름 내림차순
            if( this.group_team_sort_target == "NAME" && this.group_team_sort_direction == "DESC" ) {
                if( o1.team_name == null || o1.team_name.length < 1 || o2.team_name == null || o2.team_name.length < 1 ) {
                    if( o1.group_name  > o2.group_name ) { return -1; } 
                    if( o1.group_name  < o2.group_name ) { return  1; } 
                    if( o1.group_name == o2.group_name ) { return  0; }
                }

                else {
                    if( o1.team_name  > o2.team_name ) { return -1; } 
                    if( o1.team_name  < o2.team_name ) { return  1; } 
                    if( o1.team_name == o2.team_name ) { return  0; }
                }
            }

            // 그룹, 팀 멤버 수 오름차순
            if( this.group_team_sort_target == "COUNT" && this.group_team_sort_direction == "ASC" ) {
                if( o1.user_count  > o2.user_count ) { return  1; } 
                if( o1.user_count  < o2.user_count ) { return -1; } 
                if( o1.user_count == o2.user_count ) { return  0; } 
            }

            // 그룹, 팀 멤버 수 내림차순
            if( this.group_team_sort_target == "COUNT" && this.group_team_sort_direction == "DESC" ) {
                if( o1.user_count  > o2.user_count ) { return -1; } 
                if( o1.user_count  < o2.user_count ) { return  1; } 
                if( o1.user_count == o2.user_count ) { return  0; } 
            }

            return 0;
        };

        // 팀 정렬
        const group_count : number = this.group_team_search_list.length;
        for( let i = 0; i < group_count; i++ ) {
            const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[i]));
            if( group_obj.teams == null || group_obj.teams.length < 1 ) { continue; }

            const team_array : GroupTeamInfo[] = JSON.parse(JSON.stringify(group_obj.teams)); 
            team_array.sort(sort_function);

            group_obj.teams.splice(0, group_obj.teams.length);
            group_obj.teams = group_obj.teams.concat(team_array);
            this.group_team_search_list.splice(i, 1, group_obj);
        }

        // 그룹 정렬
        this.group_team_search_list.sort(sort_function);
    }

    /**
     * 멤버 리스트 이름 기준 정렬
     */
    memberNameSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.member_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.member_sort_direction ) {
                case "ASC":
                    this.member_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.member_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.member_sort_target = "NAME";
            this.member_sort_direction = "ASC";
        }
        
        if( this.member_sort_target == null ) { this.searchMemberFunction(); } 
        else { this.memberSort(); }
    }

    /**
     * 멤버 리스트 직급 기준 정렬
     */
    memberRoleSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.member_sort_target == "ROLE" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.member_sort_direction ) {
                case "ASC":
                    this.member_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.member_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.member_sort_target = "ROLE";
            this.member_sort_direction = "ASC";
        }
        
        if( this.member_sort_target == null ) { this.searchMemberFunction(); } 
        else { this.memberSort(); }
    }

    /**
     * 멤버 정렬 함수
     */
    memberSort() : void {
        this.group_team_search_members.sort((o1 : any, o2 : any) : number => {
            // 이름 오름차순
            if( this.member_sort_target == "NAME" && this.member_sort_direction == "ASC" ) {
                if( o1.user_info.user_name  > o2.user_info.user_name ) { return  1; } 
                if( o1.user_info.user_name  < o2.user_info.user_name ) { return -1; } 
                if( o1.user_info.user_name == o2.user_info.user_name ) { return  0; }
            }

            // 이름 내림차순
            if( this.member_sort_target == "NAME" && this.member_sort_direction == "DESC" ) {
                if( o1.user_info.user_name  > o2.user_info.user_name ) { return -1; } 
                if( o1.user_info.user_name  < o2.user_info.user_name ) { return  1; } 
                if( o1.user_info.user_name == o2.user_info.user_name ) { return  0; }
            }

            // 권한 오름차순
            if( this.member_sort_target == "ROLE" && this.member_sort_direction == "ASC" ) {
                if( o1.role_level  > o2.role_level ) { return -1; }
                if( o1.role_level  < o2.role_level ) { return  1; }
                if( o1.role_level == o2.role_level ) { return  0; }
            }

            // 권한 내림차순
            if( this.member_sort_target == "ROLE" && this.member_sort_direction == "DESC" ) {
                if( o1.role_level  > o2.role_level ) { return  1; }
                if( o1.role_level  < o2.role_level ) { return -1; }
                if( o1.role_level == o2.role_level ) { return  0; }
            }
            
            return 0;
        });
    }

    /**
     * 그룹, 팀 검색
     */
    @Watch('group_team_search_query')
    changeGroupTeamSearchQuery() : void {
        this.searchGroupAndTeamFunction();
        this.groupTeamSort();
    }

    /**
     * 그룹 팀 검색 함수
     */
    searchGroupAndTeamFunction() : void {
        const original_list : GroupTeamInfo[] = JSON.parse(JSON.stringify(this.group_team_list));

        this.group_team_search_list.splice(0, this.group_team_search_list.length);
        if( this.group_team_search_query == '' ) {
            this.group_team_search_list = this.group_team_search_list.concat(this.group_team_list);
            return;
        }
        
        const group_count : number = original_list.length;
        for( let i = 0; i < group_count; i++ ) {
            const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(original_list[i]));
            
            // 그룹 이름이 매칭 된다면 push
            if( group_obj.group_name.indexOf(this.group_team_search_query) > -1 ) {
                
                // 팀 중에도 매칭되는게 있다면 펼쳐서 보여준다
                if( group_obj.teams.filter(team => team.team_name.indexOf(this.group_team_search_query) > - 1).length > 0 ) {
                    group_obj.shareListOn = true;
                }

                this.group_team_search_list.push(group_obj);
                continue;
            }

            group_obj.teams = group_obj.teams.filter(team => team.team_name.indexOf(this.group_team_search_query) > - 1);
            
            // 팀 이름이 하나라도 매칭 된다면 push
            if( group_obj.teams.length > 0 ) {

                // 팀 이름만 매칭되는 경우는 리스트를 펼쳐준다
                if( this.group_team_search_query != '') {
                    group_obj.shareListOn = true;
                }

                this.group_team_search_list.push(group_obj);
            }
            
        }
    }

    /**
     * 그룹, 팀 선택 및 펼치기 데이터 조작시 원본에도 똑같이 만들어준다 
     */
    async setOriginalGroupTeamList() : Promise<void> {
        const search_count : number = this.group_team_search_list.length;
        for( let i = 0; i < search_count; i++ ) {
            const search_obj : GroupTeamInfo = this.group_team_search_list[i];

            // group_id가 같다면 원본 데이터 바꾸기
            const original_count : number = this.group_team_list.length;
            for( let j = 0; j < original_count; j++ ) {
                const original_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[j]));

                if( ( search_obj.group_id != original_obj.group_id ) ) {
                    continue;
                }

                // 최신화 해야하는 데이터 변경
                original_obj.shareChecked = search_obj.shareChecked == null ? false : search_obj.shareChecked;
                original_obj.shareListOn  = search_obj.shareListOn  == null ? false : search_obj.shareListOn;
                this.group_team_list.splice(j, 1, original_obj);
                
                // 팀 비교
                const search_team_count : number = search_obj.teams.length;
                for( let k = 0; k < search_team_count; k++ ) {
                    const search_team_obj : GroupTeamInfo = search_obj.teams[k];

                    // team_id가 같다면 원본 데이터 바꾸기
                    const original_team_count : number = original_obj.teams.length;
                    for( let l = 0; l < original_team_count; l++ ) {
                        const original_team_obj : GroupTeamInfo = original_obj.teams[l];

                        if( ( search_team_obj.team_id != original_team_obj.team_id ) ) {
                            continue;
                        }

                        // 최신화 해야하는 데이터 변경
                        original_team_obj.shareChecked = search_team_obj.shareChecked == null ? false : search_team_obj.shareChecked;
                        original_team_obj.shareListOn  = search_team_obj.shareListOn  == null ? false : search_team_obj.shareListOn;
                        this.group_team_list[j].teams.splice(l, 1, original_team_obj);
                    }
                }
            }

        }

    }

    /**
     * 유저 검색
     */
    @Watch('group_team_member_search_query')
    changeGroupTeamMemberSearchQuery() : void {
        this.searchMemberFunction();
        this.memberSort();
    }

    /**
     * 멤버 검색 함수
     */
    searchMemberFunction() : void {
        const original_list : GroupTeamInfo[] = JSON.parse(JSON.stringify(this.group_team_list));

        this.group_team_search_members.splice(0, this.group_team_search_members.length);
        if( this.group_team_member_search_query == '' ) {
            this.group_team_search_members = this.group_team_search_members.concat(this.group_team_members);
            return;
        }

        this.group_team_search_members = this.group_team_members.filter(member => member.user_info.user_name.indexOf(this.group_team_member_search_query) > -1);
    }

    /**
     * 공유 옵션 변경
     */
    shaerOptionChange(share_option : SHARE_OPTION) : void {
        if( this.share_option == share_option ) return;

        this.share_option = share_option;

        // 선택 정보 초기화
        this.reset();
    } 

    /**
     * 선택 정보 초기화
     */
    reset() {
        for( const group of this.group_team_list ) {
            
            const group_copy = JSON.parse(JSON.stringify(group));

            group_copy.shareChecked = false;

            for( const team of group_copy.teams ) {
                team.shareChecked = false;
            }

            this.group_team_list.splice(this.group_team_list.indexOf(group), 1, group_copy);
        } 

        for( const group of this.group_team_search_list ) {
            const group_copy = JSON.parse(JSON.stringify(group));

            group_copy.shareChecked = false;

            for( const team of group_copy.teams ) {
                team.shareChecked = false;
            }

            this.group_team_search_list.splice(this.group_team_search_list.indexOf(group), 1, group_copy);
        } 

        for( const member of this.group_team_members ) {
            const member_copy = JSON.parse(JSON.stringify(member));
            member_copy.checked = false;
            this.group_team_members.splice(this.group_team_members.indexOf(member), 1, member_copy);
        }

        for( const member of this.group_team_search_members ) {
            const member_copy = JSON.parse(JSON.stringify(member));
            member_copy.checked = false;
            this.group_team_search_members.splice(this.group_team_search_members.indexOf(member), 1, member_copy);
        }

        this.user_ids.splice(0, this.user_ids.length);
        this.group_ids.splice(0, this.group_ids.length);
        this.team_ids.splice(0, this.team_ids.length);
        this.group_user_ids.splice(0, this.group_user_ids.length);
        this.team_user_ids.splice(0, this.team_user_ids.length);

        this.member_check_all = false;
    }
}


</script>

<style scoped>
    /* 모달 공통 css */
    .modal { position:absolute; z-index:10000000000000000000000000000000; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}
    
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}
    .titleBox .total { float:left; font-size:14px; font-weight:bold; margin-left:15px;margin-top:2px; }
    .titleBox .total span { color:#477fff; padding-left:5px;}
    .titleBox .total.team {  }
    .modal .btns {border-top:1px solid #f1f3f5; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; }
    .modal .schBtn { position:absolute; right:0;top:20px;right:30px; }
    .modal .schGrp {display:none;transition:0.1s;position: absolute;top: 110px;z-index: 100000000;width: 100%;height: 60px;background: white;top: 89px;left: 0;}
    .modal .schGrp.on { display:block; }
    .modal .schGrp input {transition:0.1s;background:transparent;width: 100%;box-sizing: border-box;height: 100%;line-height: 60px;padding: 0 100px 0 30px;font-size: 14px;font-weight: bold;}
    .modal .schGrp:hover { background:#f1f3f5; }
    .modal .schGrp input:focus { background:#fff;border-bottom:1px solid #477fff; height:60px;line-height:61px; }
    .modal .schGrp a.schBtn { top:0px; }

    .modal .noResult { display:none; position: absolute; z-index: 100000;top: 150px;height: 316px;background: #fff;line-height: 316px;font-weight: bold;font-size: 21px;color: #afb6c3;}

    /* .magBtns */
    .magBtns {  overflow: hidden; }
    .magBtns input { line-height: 60px; height: 60px; width: 50%; font-weight: bold; background:#fff; box-sizing: border-box; border-bottom: 2px solid #e7e9ea; }
    .magBtns input.on { border-color:#477fff }

    /* 공유모달 css */
    .shareModal .sortHeader { position:relative; }
    .sortHeader li.grpCheck { position: absolute;left: 0;top: 0;width: 100px;text-align: center;font-weight: bold;font-size: 13px;}
    .shareModal { transition:0.3s; max-width: 800px; bottom:auto !important; margin-top: -268px !important;height: 536px !important;}
    .shareUlDiv {height: auto;display: block;width: 100%;height: 316px;max-height: 316px;overflow:hidden; overflow-y:auto;}
    .shareUlDiv ul {display: block;width:100%;}
    .shareUlDiv li .grp {width: 100%;transition:0.1s;height: 55px;line-height: 55px;border-bottom: 1px solid #f1f3f5; position:relative;}
    .shareUlDiv li .grp:hover { background:#f1f3f5; }
    .shareUlDiv li .grp * {display: inline-block;float:left;}
    .shareUlDiv li .grp:hover .count a { border:2px solid #477fff; border-radius: 5px; padding:0 10px; line-height:30px; height:30px;margin-top:11px; }
    .shareUlDiv li .grp .count.noCount { opacity:0.6 }
    .shareUlDiv li .count a { transition:0.1s; }
    .shareUlDiv li .count a:hover { background:#477fff; color:#fff; }
    .shareUlDiv li .count a:hover span { color:#fff; }
    .shareUlDiv li .img {width: 57px;font-size: 0; background-position:center center !important; background-size: cover !important;height: 42px;border-radius: 5px;border: 1px solid #f1f3f5;position: absolute;left: 180px;top: 7px;}
    .shareUlDiv .teamUl li  .img {width: 50px;height: 36px;top: 9px;left: 186px;}
    .shareUlDiv li h2 {font-size: 14px;}
    .shareUlDiv li .nm { width:64%;box-sizing:border-box; padding-left:120px; padding-right:20px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
    .shareUlDiv li .count { width:10%;font-weight:bold; }
    .shareUlDiv li .count span { color:#477fff; margin-right:4px; }
    .shareUlDiv li.withTeams a.showTeams {position: absolute;right: 20px;top: 0;font-size: 0;width: 35px;height: 60px;border-radius: 50%; background:  url('../../../assets/images/side_area/bt_open.png') no-repeat center center;}
    .shareUlDiv li a.showTeams.on, .shareUlDiv li.on a.showTeams {background:  url('../../../assets/images/side_area/bt_close.png') no-repeat center center;  }
    .shareUlDiv .teamUl { display:none; border-top:1px solid #f1f3f5;border-bottom:1px solid #f1f3f5;}
    .shareUlDiv li.on > .grp {  }
    .shareUlDiv li.on .teamUl { display:block; }
    .shareModal input[type='checkbox']:not(old) + label {position: absolute;left: 38px;top: 17px;z-index:10000000; }
    .shareModal .teamUl input[type='checkbox']:not(old) + label{left: 50px;}
    .shareModal input[type='checkbox']:not(old) + label, .shareModal input[type='checkbox']:not(old) + label:hover {margin-left: 5px;display: inline-block;  padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
    .shareModal input[type='checkbox']:not(old):checked + label { border:2px solid #477fff;background: #477fff url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;}
    .grpUl > li > .grp { height:60px; line-height:60px; }
    .grpUl > li > .grp .img  { top:8px; }
    .grpUl > li > .grp:hover > .count > a { margin-top:14px; }
    .grpUl > li > .grp input[type='checkbox']:not(old) + label { top: 19px }
    .shareUlDiv li .grp .cl {position: absolute;left: 151px;top: 26px;width: 8px;height: 8px;font-size: 0;border-radius: 50%;background: #f1f3f5;}
    .shareUlDiv li .teamUl .grp .cl { left:158px; }
    .shareModal .teamUl .num {padding-left: 90px;}

    .grpHeader .num { width:15%;  }
    .grpHeader .nm {padding-left: 14px;width: 64%;box-sizing: border-box;}
    .grpHeader .count {width: 19%;}

    .teamHeader { display:none; }
    .shareModal.peepsOn ul.grpUl {height: 447px;}
    .shareModal.peepsOn { margin-top: -268px !important; height: 536px !important;}
    .shareModal.peepsOn .teamHeader { display:block; }
    .shareModal.peepsOn .grpHeader { display:none; }
    .shareModal .peepsDiv { display:none;width:100%;position: absolute;background: #fff;z-index: 1000000000;top: 150px; }
    .shareModal .peepsDiv.on {display: block;}
    .shareModal .peepsDiv .peepsUlDiv { width: 100%;height: 316px;max-height: 316px;overflow: hidden;overflow-y: auto;}
    .shareModal ul.peeps li * {display: inline-block;}
    .shareModal ul.peeps li {transition:0.1s;position:relative;line-height: 50px;height: 50px;font-size: 13px;font-weight: bold;border-bottom:1px solid #f1f3f5;}
    .shareModal ul.peeps li .fPic {position:absolute;background-color:#f1f3f5; left:155px; top:10px;font-size: 0;width: 30px;height: 30px;border-radius: 50%;background-position: center center !important;background-size: cover !important;border: 1px solid #e7e9ea;}
    .shareModal ul.peeps li .pNm { padding-left:70px; padding-right:20px; width:63%; box-sizing:border-box; overflow : hidden; white-space: nowrap; text-overflow: ellipsis; }
    .shareModal ul.peeps li .pos { font-size:12px; overflow : hidden; white-space: nowrap; text-overflow: ellipsis; }
    .shareModal ul.peeps li:hover { background:#f1f3f5; }
    .shareModal .peeps input[type='checkbox']:not(old) + label { top:14px; }
    .shareModal .peeps .fPic.none { background-image : url(../../../assets/images/contents/im_photoB.gif) !important; }

    .shareModal .pNm {  width:63%; box-sizing:border-box;    padding-left: 20px;  }
    .shareModal .num {font-weight:bold;padding-left: 80px;width: 17%; box-sizing:border-box; text-align: center;font-size:12px; overflow : hidden;}
    .shareModal .peeps input[type='checkbox']:not(old) + label:after {content: '';display: block;width: 800px;height: 50px;position: absolute;left: -45px;top: -16px;}
    
    
    .teamHeader .pos {width: 20%;}
    .sortHeader input[type='checkbox']:not(old) + label { top:19px;}

    .shareUlDiv.mCS_destroyed { overflow : hidden !important }

    .share_option_btn { position : absolute; float : right; right : 80px; top : 30px; }
    .share_option_btn.on { color : white !important; background-color : #477fff !important; }
    .share_option_btn.option_share { right : 210px; }
    .share_option_btn.option_copy { right : 145px; }
    .share_option_btn.option_move {}

    /* 추후삭제 */
    /* .grpUl > li:nth-child(1) .img { background-image: url(../../../assets/images/footer/g4.jpg) }
    .teamUl > li:nth-child(1) .img { background-image: url(../../../assets/images/footer/g1.jpg) }
    .teamUl > li:nth-child(2) .img { background-image: url(../../../assets/images/footer/g2.jpg) }
    .teamUl > li:nth-child(3) .img { background-image: url(../../../assets/images/footer/g6.jpg) }
    .teamUl > li:nth-child(4) .img { background-image: url(../../../assets/images/footer/g10.jpg) }
    .teamUl > li:nth-child(5) .img { background-image: url(../../../assets/images/footer/g11.jpg) }
    .grpUl > li:nth-child(2) .img { background-image: url(../../../assets/images/footer/g7.jpg) }

    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(1) .fPic { background-image:  url(../../../assets/images/footer/f1.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(2) .fPic { background-image:  url(../../../assets/images/footer/f2.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(3) .fPic { background-image:  url(../../../assets/images/footer/f3.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(4) .fPic { background-image:  url(../../../assets/images/footer/f4.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(5) .fPic { background-image:  url(../../../assets/images/footer/f5.jpg)  } */
</style>