<template>
    <div id="" class="section_scroll">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <input type="button" id="" class="btnAdd specialityBtn" placeholder="" value="+ 진료과" title="진료과 추가" @click="addDepartment()"/>

        <div class="content">
            <ul class="sortHeader" id="">
                <li class="checkbox sortOff" >
                    <input type="checkbox" id="selectAllSpecial" :checked="selected_indexs.length != 0 && departments.length == selected_indexs.length" @click="selectAll()">
                    <label for="selectAllSpecial"></label>
                </li>
                <li class="no">No</li>
                <li class="name headerSort" @click.prevent="sortTypeChange('NAME')"
                    :class="{
                        sortOff  : sort_type != 'NAME',
                        sortUp   : sort_type == 'NAME' && sort_dir == 'ASC',
                        sortDown : sort_type == 'NAME' && sort_dir == 'DESC'
                    }"><span>진료과명</span>
                </li>
                <li class="code headerSort" @click.prevent="sortTypeChange('CODE')"
                    :class="{
                        sortOff  : sort_type != 'CODE',
                        sortUp   : sort_type == 'CODE' && sort_dir == 'ASC',
                        sortDown : sort_type == 'CODE' && sort_dir == 'DESC'
                }"><span>자체 코드</span></li>
            </ul>
            
            <div id="department_list_scroll">
                <ul class="specialityUl">
                    <li :key="department.department_code" v-for="(department, index) in computedDepartmentSortList">
                        <p class="checkbox" @click="selectDept(index)">
                            <input type="checkbox" id="check_spe1" :checked="selected_indexs.includes(index)">
                            <label for=""></label>
                        </p>
                        <p class="no">{{ index + 1 }}</p>
                        <p class="name">{{department.department_name}}</p>
                        <p class="code">{{department.department_code}}</p>
                        <input type="button" class="del" value="삭제" title="삭제" @click="deleteDepartment(department)"/>
                        <a @click="detailDepartment(department)">수정</a>
                    </li>
                    <!--
                    <li>
                        <p class="checkbox">
                            <input type="checkbox" id="check_spe1">
                            <label for="check_spe1"></label>
                        </p>
                        <p class="no">1</p>
                        <p class="name">내과</p>
                        <p class="code">339421EMSEAA99093</p>
                        <input type="button" class="del" value="삭제" title="삭제" />
                        <a>수정</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">2</p>
                        <p class="name">소아과</p>
                        <p class="code">-</p>
                        <input type="button" class="del" value="삭제" title="삭제" />
                        <a>수정</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">3</p>
                        <p class="name">이비인후과</p>
                        <p class="code">-</p>
                        <input type="button" class="del" value="삭제" title="삭제" />
                        <a>수정</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">4</p>
                        <p class="name">정형외과</p>
                        <p class="code">-</p>
                        <input type="button" class="del" value="삭제" title="삭제" />
                        <a>수정</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">5</p>
                        <p class="name">피부과</p>
                        <p class="code">-</p>
                        <input type="button" class="del" value="삭제" title="삭제" />
                        <a>수정</a>
                    </li>
                    <li>
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">6</p>
                        <p class="name">산부인과</p>
                        <p class="code">-</p>
                        <input type="button" class="del" value="삭제" title="삭제" />
                        <a>수정</a>
                    </li>
                    <li->
                        <p class="checkbox">
                            <input type="checkbox">
                            <label for=""></label>
                        </p>
                        <p class="no">7</p>
                        <p class="name">신경과</p>
                        <p class="code">-</p>
                        <input type="button" class="del" value="삭제" title="삭제" />
                        <a>수정</a>
                    </li->
                    -->
                </ul>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { ResizeObserver } from 'vue-resize';
import {hodu_doc_object, hodu_doc_modal_info} from "@/model/hodudoc";

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class HospitalDepartmentList extends Mixins(VueHoduCommon) {

    /**
     * 진료과 정렬 리스트
     */
    get computedDepartmentSortList() : hodu_doc_object.department_info[] {

        let target_list : hodu_doc_object.department_info[] = JSON.parse(JSON.stringify(this.departments));

        target_list = target_list.sort((item1, item2) : number => {
            
            // 진료과 이름 기준 정렬
            if ( this.sort_type == 'NAME' ) {
                
                if( item1.department_name == item2.department_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.department_name < item2.department_name ? -1 : 1;
                }

                return item1.department_name > item2.department_name ? -1 : 1;

            }

            // 진료과 코드 기준 정렬
            if ( this.sort_type == 'CODE' ) {
                
                if( item1.department_code == item2.department_code ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.department_code < item2.department_code ? -1 : 1;
                }

                return item1.department_code > item2.department_code ? -1 : 1;

            }

            return 0;
        })

        return target_list;
    }
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDepartmentSettingModalInfo ?: (params : hodu_doc_modal_info.DepartmentSettingModalInfo) => void;

    @Prop() event_bus !: Vue;

    biz_id      : string = "";
    departments : hodu_doc_object.department_info[]  = [];
    selected_indexs : number[] = [];

    // 정렬
    sort_type : 'NAME' | 'CODE' = 'NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    created() : void {
        this.biz_id = this.scope_group_team_option.biz_id;
        this.getDepartments();
    }

    mounted() : void {
        if( this.event_bus != null ) { this.event_bus.$on('deleteDepartments', this.deleteDepartments); }
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#department_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    selectDept(index: number){
        if (this.selected_indexs.includes(index) == false) {
            this.selected_indexs.push(index)
        }else{
            this.selected_indexs.splice(this.selected_indexs.indexOf(index), 1)
        }
    }

    selectAll(){
        const numOfDept = this.departments.length

        if (this.selected_indexs.length < numOfDept) {
            this.selected_indexs.splice(0, this.selected_indexs.length)
            for (let i=0; i<numOfDept; i++) {
                this.selected_indexs.push(i)
            }
        }else{
            this.selected_indexs.splice(0, this.selected_indexs.length)
        }
    }

    /**
     * 진료과 조회
     */
    getDepartments(scrollTo : string | null = null) : void {
        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/department`, API_METHOD.GET)
            .then((response) => {
                // let jsonData = JSON.stringify(response.data.data);
                this.departments.splice(0, this.departments.length);
                this.departments = this.departments.concat(response.data.data);
                this.selected_indexs.splice(0, this.selected_indexs.length)
                
                if ( scrollTo == null) { return }
                switch( scrollTo ) {
                    case "TOP" :

                        this.$nextTick(() => {
                            // @ts-ignore
                            $('#department_list_scroll').mCustomScrollbar('scrollTo', 'top', { scrollInertia : 0 });
                        });

                        break;

                    case "BOTTOM" :
                        
                        this.$nextTick(() => {
                            // @ts-ignore
                            $('#department_list_scroll').mCustomScrollbar('scrollTo', 'bottom', { scrollInertia : 0 });
                        });

                        break;

                    default:
                        break;
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 진료과 추가
     */
    addDepartment() : void {
        window['getDepartments'] = this.getDepartments;
        if( this.doSetDepartmentSettingModalInfo ) { 
            this.doSetDepartmentSettingModalInfo({
                show_modal  : true,
                is_create   : true,
                department  : {
                    department_code : "",
                    department_name : ""
                }
            });
        }
    }

    /**
     * 진료과 조회 및 수정
     */
    detailDepartment(department: hodu_doc_object.department_info) : void {
        window['getDepartments'] = this.getDepartments;
        if( this.doSetDepartmentSettingModalInfo ) { 
            this.doSetDepartmentSettingModalInfo({
                show_modal  : true,
                is_create   : false,
                department  : {
                    department_code : department.department_code,
                    department_name : department.department_name
                }
            });
        }
    }

    /**
     * 진료과 단일 삭제
     */
    deleteDepartment(depart: hodu_doc_object.department_info) {
        let body = {
            department_codes : [depart.department_code]
        }
        this.hodu_show_dialog("cancel", `정말로 [${depart.department_name}]를 삭제하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => {
                this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/department`, API_METHOD.DELETE, body)
                .then((response) => {
                    // let jsonData = JSON.stringify(response.data.data);
                    this.getDepartments()
                    
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
            ]);        
    }

    /**
     * 진료과 멀티 삭제
     */
    deleteDepartments(){
        if ( this.selected_indexs.length < 1 ) { return; }

        this.hodu_show_dialog("cancel", `정말로 ${this.selected_indexs.length}개의 진료과를 삭제하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => {
                let arrO_depart_codes : string[] = []
                for (const index of this.selected_indexs) {
                    arrO_depart_codes.push(this.computedDepartmentSortList[index].department_code)
                }
                let body = {
                    department_codes : arrO_depart_codes
                }
                this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/department`, API_METHOD.DELETE, body)
                .then((response) => {
                    // let jsonData = JSON.stringify(response.data.data);
                    this.getDepartments()
                    
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
            ]);   
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'NAME' | 'CODE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#department_list_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
</script>

<style scoped>
    .posRel h4 { display: inline-block; font-size: 18px; margin-left: 30px; line-height: 65px; height: 65px; }
    .posRel .input_btn { margin: 11px 30px 0 0 }
    .sortHeader { margin-top: 0;}
    .specialityUl li { transition:0.2s; overflow:hidden; position: relative; }
    .specialityUl li:hover { background: #f7f9fb; }
    .specialityUl a { position: absolute; font-size: 0; display: block; width:90%; height:100%;right:0;top:0;}
    .specialityUl p { float:left; line-height: 55px; height: 55px; font-weight:bold; font-size: 12px; border-bottom: 1px solid #f1f3f5; box-sizing: border-box; }
    .no { font-weight:bold; width: 10%; display:inline-block; padding-left: 10px; box-sizing: border-box; }
    .checkbox { width:10%; display:inline-block; text-align:center; }
    .name { width:40%;  display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .code { width: 40%; display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    
    input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 20px;height: 20px;border-radius:3px; margin-top:15px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;font-size: 0;margin-right: 8px;}
    input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;}

    .specialityUl li:hover .del { opacity:1; } 
    .specialityUl li .del  { z-index: 1000; opacity:0; transition:0.2s; position:absolute; right: 20px; top:12px; width: 30px; height:30px; background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .specialityUl li .del:hover { background-color:#ff1f1f !important;}

    .btnAdd { position:absolute; right: 40px; bottom:40px; }
    .btnAdd { opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .btnAdd:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}


    
</style>