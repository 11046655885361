<template>
    <div class="section_ce_fix">
        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">가입승인 리스트</h3>
        </div>

        <!-- ********************************************************** 초대현황 리스트 선택시 addClass(invited)  -->
        <div id="grpApprove" class="">
            <div class="viewGroup">					
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <!-- 리스트 두개 필요, 승인 대기자 리스트와 초대현황 리스트  -->
                        <h3 class="selectTitle" id="grpSelectTitle"><span class="txt">승인 대기자 리스트</span> <span class="num">{{list.length}}</span><span class="rest"> 명</span><a class="">그룹 선택하기</a></h3>
                        <div class="dropdown" id="selectTitleDropdown">
                            <ul>
                                <li><a class="on">승인 대기자 리스트</a></li>
                                <!--<li><a>초대현황 리스트</a></li>-->
                            </ul>
                        </div>

                        <p class="btn_group" style="">
                            <!-- 체크박스 선택 후 버튼 클릭시 모달 띄우기 (성공, 거절, 취소) -->
                            <!-- <a href="#" id="" class="cancelBtn input_btn">요청 취소</a> -->
                            <a href="#" id="" class="rejectBtn input_btn" @click.prevent="groupApprovSave('E')">거절</a>
                            <a href="#" class="input_btn acceptBtn" id="" @click.prevent="groupApprovSave('A')">승인</a>
                            <!-- 초대현황 리스트에서 show, 추가시 바로 리스트에 반영 
                            <a href="#" class="input_btn" id="inviteMate">추가</a>-->
                        </p>
                    </div>

                    <div class="content" id="">
                        <!-- 멤버 추가 화면 -->
                        <div class="inviteMateDiv">
                            <div class="grp1">
                                <h2>멤버초대</h2>
                                <p class="descript">초대하고싶은 멤버에 이름과 전화번호를 입력하신 후 문자보내기 버튼을 클릭하세요. <span class="descript2">(초대받은 멤버는 호두 가입 후 가입승인리스트 > 초대현황리스트에 보여집니다.)</span></p>
                            </div>

                            <form action="">
                                <fieldset>
                                    <legend class="over_hidden">멤버 관리</legend>
                                    <label for="">이름</label>

                                    <!-- 인풋을 비어두고 가입권유문자보내기 클릭시 -->
                                    <input type="text" class="input_txt wrong" id="modalMemRegNm" placeholder="예) 홍길동">
                                    <label for="">전화번호</label>
                                    <input type="text" class="input_txt" id="modalMemRegPh" placeholder="010xxxxxxxx">
                                    <a href="#" class="" id="modalMemInvateBtn">가입 권유 문자 보내기</a>
                                </fieldset>
                            </form>

                            <div class="grp2">
                                <h3>성공적으로 전송되었습니다!</h3>
                                <a class="check">현황 확인</a>
                                <div class="btns">
                                    <a class="close">닫기</a>
                                    <a class="inviteMore">다른 멤버 초대</a>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <div class="over_hidden">
                                <div class="sortHeader approveHeader">
                                    <p class="checkBox" id="">
                                        <input type="checkbox" id="selectAll" @change="checkAll($event)" :checked="is_allChack">
                                        <label for="selectAll">전체선택</label>
                                    </p>
                                    <p class="num">No</p>
                                    <p class="userName headerSort" @click="sortName"
                                       :class="{
                                            sortOff  : approve_sort_target != 'NAME',
                                            sortUp   : approve_sort_target == 'NAME' && approve_sort_direction == 'ASC', 
                                            sortDown : approve_sort_target == 'NAME' && approve_sort_direction == 'DESC', 
                                        }">이름</p>
                                    <p class="tel headerSort" @click="sortTel"
                                       :class="{
                                            sortOff  : approve_sort_target != 'TEL',
                                            sortUp   : approve_sort_target == 'TEL' && approve_sort_direction == 'ASC', 
                                            sortDown : approve_sort_target == 'TEL' && approve_sort_direction == 'DESC', 
                                        }">전화번호</p>
                                    <p class="email headerSort" @click="sortEmail"
                                       :class="{
                                            sortOff  : approve_sort_target != 'EMAIL',
                                            sortUp   : approve_sort_target == 'EMAIL' && approve_sort_direction == 'ASC', 
                                            sortDown : approve_sort_target == 'EMAIL' && approve_sort_direction == 'DESC', 
                                        }">이메일</p>
                                    <p class="time headerSort" @click="sortRequestDate"
                                       :class="{
                                            sortOff  : approve_sort_target != 'REQUEST',
                                            sortUp   : approve_sort_target == 'REQUEST' && approve_sort_direction == 'ASC', 
                                            sortDown : approve_sort_target == 'REQUEST' && approve_sort_direction == 'DESC', 
                                        }">요청일</p>
                                </div>

                                <div class="grpApproveList" style=""><!--스크롤 생겨야할 영역-->
                                    <ul class="grpApproveUl" id="">
                                        <li class="" v-bind:key="index" v-for="(lo_data, index) in list">
                                            <div class="grpAppListDiv" id="group_approv_list">
                                                <p class="grpAppCheck">
                                                    <input type="checkbox" :id="`grpAppCheck_${index}`" value="" class="input_check" :checked="lo_data.checked" @change="checkRow($event, index)">
                                                    <label :for="`grpAppCheck_${index}`"></label>
                                                </p> 
                                                <span class="num">{{index+1}}</span>
                                                <span class="userName">{{lo_data.user_name}}</span>
                                                <span class="tel">{{lo_data.user_phone_number ? lo_data.user_phone_number : '-'}}</span>
                                                <span class="email">{{lo_data.user_email ? lo_data.user_email : '-'}}</span>
                                                <span class="time">{{lo_data.audit_created}}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";
import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');

@Component({
   components: {

   }
}) export default class GroupApprove extends Mixins(VueHoduCommon) {
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id          !: number;
    @GroupInfo.State team_id           !: number;

    /**
     * 변수 선언부
     */
    list : any = [];
    original_list : any = [];
    is_allChack : boolean = false;

    approve_sort_target : string | null = null;
    approve_sort_direction : string = "ASC";

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() : Promise<void> {
        /* 화살표 클릭시 팀 보여주기 */
        $(".selectTitle").click(function(){
            $(this).siblings().toggleClass("on");
        });

        // 멤버 추가 버튼클릭시
		$("#inviteMate").click(function(){
			$(this).toggleClass("on");
			$(".inviteMateDiv").toggleClass("on");
        });
        
		// 멤버추가창 전송버튼클릭시
		$("#modalMemInvateBtn").click(function(){
			$(".inviteMateDiv").addClass("sent");	
        });
        
		// 멤버추가창 인풋에 wrong 빼기
		$(".inviteMateDiv input").click(function(){
			$(this).removeClass("wrong");
        });
        
		// 멤버추가창 닫기버튼
		$(".inviteMateDiv .btns .close").click(function(){
			$(".inviteMateDiv").removeClass("sent");
			$(".inviteMateDiv").removeClass("on");
			$("#inviteMate").removeClass("on");
        });
        
		// 멤버추가창 다른 멤버 초대
		$(".inviteMateDiv .btns .inviteMore").click(function(){
			$(".inviteMateDiv").removeClass("sent");
        });
        
        this.getApprov();
    }

    /**
     * 조회
     */
    async getApprov() : Promise<void> {
        this.list = [];
        this.is_allChack = false;

        await this.hodu_api_call(`api/v1/groupsweb/getApprov/${this.group_id}/${this.team_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.list = response.data.data.list;
                this.original_list = JSON.parse(JSON.stringify(this.list));
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }    

    /**
     * 전체선택 
     */
    checkAll(event) : void {
        this.is_allChack = event.target.checked;

        for (let i=0; i<this.list.length; i++) {
            this.list[i].checked = this.is_allChack;
        }
    }

    /**
     * 개별선택
     */
    checkRow(event, index) : void {
        this.list[index].checked = event.target.checked;

        if ( event.target.checked ) {
            this.is_allChack = true;

            for (let i=0; i<this.list.length; i++) {
                if ( !this.list[i].checked ) {
                    this.is_allChack = false;
                    break;
                }
            }
        }
        else {
            this.is_allChack = false;
        }
    }

    /**
     * 승인 거절
     */
    groupApprovSave(state) : void {
        let appr_group_team_name = "";
        let arr_approve : any = [];

        for (let i=0; i<this.list.length; i++) {
            let lo_data = this.list[i];

            if ( !this.list[i].checked ) {
                continue;
            }

            if ( !appr_group_team_name ) {
                appr_group_team_name = (this.team_id == 0 ? lo_data.info.group_name : lo_data.info.team_name);
            }

            let dic = {
                group_id        : lo_data.group_id,
                team_id         : (this.team_id == 0 ? 0 : lo_data.team_id),
                group_team_name : appr_group_team_name,
                user_id         : lo_data.user_id,
                appr_state      : state
            }

            arr_approve.push(dic);
        }

        if ( arr_approve.length < 1 ) {
            this.hodu_show_dialog("alert", "승인 또는 거절 할 사용자를 선택해주세요", ['확인']);
            return;
        }

        this.hodu_show_dialog("alert", `${state == 'A' ? '승인' : '거절'} 하시겠습니까?`, ['아니오', '예'], [
            () => {},
            () => {
                this.groupApprovSaveService(arr_approve);
            },
        ]);
    }

    groupApprovSaveService(arr) : void {
        if ( arr.length < 1 ) {
            return;
        }

        this.hodu_api_call(`api/v1/groups/updateApprov`, API_METHOD.PUT, {arrO_list: arr})
            .then(async(response) => {
                this.getApprov();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });        
    }

    /**
     * 이름 기준 정렬
     */
    sortName() : void {

        // 이미 NAME Sort 중이라면
        if( this.approve_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.approve_sort_direction ) {
                case "ASC":
                    this.approve_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.approve_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.approve_sort_target = "NAME";
            this.approve_sort_direction = "ASC";
        }

        if( this.approve_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * 연락처 기준 정렬
     */
    sortTel() : void {

        // 이미 TEL Sort 중이라면
        if( this.approve_sort_target == "TEL" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.approve_sort_direction ) {
                case "ASC":
                    this.approve_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.approve_sort_target = null;
                    break; 
            }
        }
        
        // TEL Sort가 아니였다면
        else {
            this.approve_sort_target = "TEL";
            this.approve_sort_direction = "ASC";
        }

        if( this.approve_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * 이메일 기준 정렬
     */
    sortEmail() : void {

        // 이미 EMAIL Sort 중이라면
        if( this.approve_sort_target == "EMAIL" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.approve_sort_direction ) {
                case "ASC":
                    this.approve_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.approve_sort_target = null;
                    break; 
            }
        }
        
        // EMAIL Sort가 아니였다면
        else {
            this.approve_sort_target = "EMAIL";
            this.approve_sort_direction = "ASC";
        }

        if( this.approve_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * 요청일 기준 정렬
     */
    sortRequestDate() : void {

        // 이미 REQUEST Sort 중이라면
        if( this.approve_sort_target == "REQUEST" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.approve_sort_direction ) {
                case "ASC":
                    this.approve_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.approve_sort_target = null;
                    break; 
            }
        }
        
        // REQUEST Sort가 아니였다면
        else {
            this.approve_sort_target = "REQUEST";
            this.approve_sort_direction = "ASC";
        }

        if( this.approve_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.list = JSON.parse(JSON.stringify(this.original_list));
    }

    /**
     * approve_list 정렬
     */
    listSort() : void {
        this.list.sort((o1, o2) : number => {
            // 이름 오름차순
            if( this.approve_sort_target == "NAME" && this.approve_sort_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 이름 내림차순
            if( this.approve_sort_target == "NAME" && this.approve_sort_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 전화번호 오름차순
            if( this.approve_sort_target == "TEL" && this.approve_sort_direction == "ASC" ) {
                if( o1.user_phone_number  > o2.user_phone_number ) { return  1; } 
                if( o1.user_phone_number  < o2.user_phone_number ) { return -1; } 
                if( o1.user_phone_number == o2.user_phone_number ) { return  0; }
            }

            // 전화번호 내림차순
            if( this.approve_sort_target == "TEL" && this.approve_sort_direction == "DESC" ) {
                if( o1.user_phone_number  > o2.user_phone_number ) { return -1; } 
                if( o1.user_phone_number  < o2.user_phone_number ) { return  1; } 
                if( o1.user_phone_number == o2.user_phone_number ) { return  0; }
            }

            // 이메일 오름차순
            if( this.approve_sort_target == "EMAIL" && this.approve_sort_direction == "ASC" ) {
                if( o1.user_email  > o2.user_email ) { return  1; } 
                if( o1.user_email  < o2.user_email ) { return -1; } 
                if( o1.user_email == o2.user_email ) { return  0; }
            }

            // 이메일 내림차순
            if( this.approve_sort_target == "EMAIL" && this.approve_sort_direction == "DESC" ) {
                if( o1.user_email  > o2.user_email ) { return -1; } 
                if( o1.user_email  < o2.user_email ) { return  1; } 
                if( o1.user_email == o2.user_email ) { return  0; }
            }

            // 요청일 오름차순
            if( this.approve_sort_target == "REQUEST" && this.approve_sort_direction == "ASC" ) {
                if( new Date(o1.audit_created).getTime()  > new Date(o2.audit_created).getTime() ) { return  1; } 
                if( new Date(o1.audit_created).getTime()  < new Date(o2.audit_created).getTime() ) { return -1; } 
                if( new Date(o1.audit_created).getTime() == new Date(o2.audit_created).getTime() ) { return  0; }
            }

            // 요청일 내림차순
            if( this.approve_sort_target == "REQUEST" && this.approve_sort_direction == "DESC" ) {
                if( new Date(o1.audit_created).getTime()  > new Date(o2.audit_created).getTime() ) { return -1; } 
                if( new Date(o1.audit_created).getTime()  < new Date(o2.audit_created).getTime() ) { return  1; } 
                if( new Date(o1.audit_created).getTime() == new Date(o2.audit_created).getTime() ) { return  0; }
            }

            return 0;
        });
    }

}
</script>

<style scoped>
#grpApprove.invited .cancelBtn {display: inline-block !important;}
#grpApprove.invited .rejectBtn, #grpApprove.invited .acceptBtn {display: none !important;}
#grpApprove .cancelBtn { display:none !important; }
#grpApprove.invited .email { display:none !important; }
#grpApprove.invited .num { width: 13% !important; text-indent: -40px;}
#grpApprove.invited .userName { width: 28% !important}
#grpApprove.invited .tel { width: 25% !important}
#grpApprove.invited .time { width: 25% !important}
#grpApprove a#inviteMate { display:none !important; }
#grpApprove.invited a#inviteMate { display:block !important; }

.title_box { height:61px; }
#container #section_ce .schedule_box { padding:0; width:100%; max-width:100%; }
#grpApprove .mCSB_inside > .mCSB_container { margin-right:0; }
#grpApprove p.btn_group { margin-right:30px; } 
#grpApprove .sortHeader input[type='checkbox']:not(old) + label {margin-top: 20px;}
#grpApprove input[type='checkbox']:not(old) + label {border-radius: 3px;display: inline-block;padding: 0;width: 17px;height: 17px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 0 -0;background-size: 18px !important;font-size: 0;margin-right: 0;margin-top: 17px;}
#grpApprove input[type='checkbox']:not(old):disabled + label {background-color: #e6ecef !important;border: 2px solid #e6ecef !important;cursor:default;}
#grpApprove input[type='checkbox']:not(old):checked + label {background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat 0 0;border: 2px solid #477fff;}

#grpApprove .content .num {width: 9%;display: inline-block;line-height: 55px;float: left;font-weight:bold;text-align: center;text-indent:-25px;}
#grpApprove .userName {width: 20%;display: inline-block;padding-left:0 !important;line-height: 55px;float: left;font-weight:bold;}
#grpApprove .time {width: 18%;display: inline-block;float: left;line-height: 55px;font-weight:bold;padding-left: 0;color: #4e4e4e;}
#grpApprove .tel {width: 20%;display: inline-block;float: left;line-height: 55px;font-weight:bold;padding-left: 0;}
#grpApprove .email {width: 24%;display: inline-block;float: left;line-height: 55px;padding-left: 0;font-weight:bold;}
#grpApprove .status {position:relative; z-index:100000; width:12%;display: inline-block;float: left;line-height: 55px;padding-left: 0;font-weight:bold; }
#grpApprove .grpAppListDiv img {position: absolute;width: 35px;height: 35px;left: 10%;margin-left: 3px;top: 11px;border-radius: 50%;background: #ecf2f7;border: 1px solid #f1f3f5;box-sizing: border-box;}
#grpApprove .checkBox {width: 9%;display: inline-block;text-align: center;float: left;}
#grpApprove .headerSort.userName {box-sizing: border-box;}
/* #grpApprove .content {padding-top: 64px;} */

#grpApprove a.time { position:relative; z-index:100000; }
#grpApprove a.time:hover { cursor:pointer !important; }
#grpApprove a.status:hover { color:#ff6060;  } 

#grpApprove .grp.settingBg h3.selectTitle { cursor:pointer; }
#grpApprove .grp.settingBg h3.selectTitle .txt {    background: url(../assets/images/side_area/bt_open.png) no-repeat center right;padding-right: 35px !important;}
#grpApprove .settingBg ul.dropdown {width: 170px;left: 280px;top: 60px;}
#grpApprove .mCSB_inside > .mCSB_container  { margin-right:0; }
#grpApprove h3.selectTitle a {position: absolute;width: 100%;height: 100%;background: none;font-size: 0;display: block;left: 0;top: 0;}
#grpApprove h3.selectTitle {position: relative !important; position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
#grpApprove h3.selectTitle:hover .txt { color:#477fff; }
#grpApprove h3.selectTitle .num {padding-left: 20px;color: #477fff;font-size: 14px;}
#grpApprove h3.selectTitle .rest {font-size: 14px;}
a#inviteMate {position: fixed;bottom: 40px;right: 100px;z-index: 10000;opacity: 1;z-index: 1000;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(../assets/images/contents/btn_add_fff.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;}
a#inviteMate.on { background: #477fff url(../assets/images/contents/btn_close_fff.png) no-repeat center center !important;background-size: 53px !important;}
a#inviteMate:hover {box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5) !important;}

#inviteMate:hover, #inviteMate.on { color:#1192ff }
a#grupMyRemove:hover {color: #ff3737;}

/* 멤버 초대 */
.inviteMateDiv.on { display:block }
.inviteMateDiv {transition: 0.2s;display:none;position: fixed;z-index: 9999999999;width: 300px;padding: 0;background: #fff;box-shadow: 0 5px 15px rgba(0,0,0,0.15);border-radius: 5px;box-sizing: border-box;right: 80px;text-align: center;transition: 0.2s;bottom: 124px;}
.inviteMateDiv .grp1 {padding: 35px 40px 0 40px;transition:0.3s;height: 125px;}
.inviteMateDiv.sent .grp2 { display:block; }
.inviteMateDiv .btns {border-top: 1px solid #F1f3f5;position: absolute;width: 100%;bottom: 0;border-radius: 0 0 5px 5px;overflow: hidden;}
.inviteMateDiv .btns a {display: inline-block;width: 50%;float: left;height: 70px;line-height: 70px;font-size: 14px;font-weight: bold;}
.inviteMateDiv .btns a:hover { color:#477fff; }
.inviteMateDiv .btns a.close:hover { color:#ff5050 }
.inviteMateDiv .btns a:hover {background: #f1f3f5;}
.inviteMateDiv.sent { transition:0.2s; box-shadow: 0 8px 20px rgba(71, 127, 255, 0.5);}
.inviteMateDiv.sent .grp2 .check {width: 130px;border-radius: 20px;height: 40px;line-height: 40px;background: #477fff;color: #fff;font-weight: bold;display: inline-block;font-size: 14px;margin-top: 16px;}
.inviteMateDiv .grp2 {width: 100%;top: 0;display: none;position: absolute;height: 100%;background: #fff url(../assets/images/contents/sucSent.png) no-repeat center 0px;background-size: 211px;border-radius: 5px;}
.inviteMateDiv .grp2 h3 {font-size: 16px;padding-top: 196px;height: 30px;width: 100%;}
.inviteMateDiv.sent {height: 386px;}
.inviteMateDiv h2 {font-size: 19px;text-align: center;margin-bottom: 10px;}
.inviteMateDiv p {text-align:left;font-weight: bold;padding-top: 10px;font-size: 13px;line-height: 20px;}
.inviteMateDiv span.descript2 { font-size:12px;color: #757f96; padding-left:5px; }
.inviteMateDiv input {font-weight:bold;line-height: 45px;height: 45px;font-size: 14px;width: 100%;border-radius: 0;padding: 0 15px;box-sizing: border-box;border: 1px solid #f1f3f5;}
.inviteMateDiv input.wrong { border:1px solid #ff5050; background:#ffe7e7; color:#ff5050 }
.inviteMateDiv input.wrong::placeholder  { color:#ff5050 !important; }
.inviteMateDiv input.wrong:hover { border:1px solid #ff5050; background:#fff; transition:0.2s; }
.inviteMateDiv label {font-weight:bold; text-align:left;font-weight: bold;font-size: 13px;margin-top: 20px;margin-bottom: 10px;display: block;color: #757f96;}
.inviteMateDiv input:hover {border: 1px solid #d9dee0;}
.inviteMateDiv input:focus {border: 1px solid #477fff;}
.inviteMateDiv #modalMemInvateBtn {line-height:40px;height:40px;border-radius:25px;width:190px;display: inline-block;font-size: 14px;font-weight: bold;text-align: center;background: #477fff;color: #fff;margin-top: 25px;}
.inviteMateDiv #modalMemInvateBtn:hover, .inviteMateDiv.sent .grp2 .check:hover { background:#3371ff }
.inviteMateDiv form {border-top: 1px solid #f1f3f5;padding-top: 10px;padding: 5px 40px 55px;margin-top: 20px;}
.inviteMateDiv:hover .grp1 {transition:0.3s;height: 64px;box-sizing: border-box;}
.inviteMateDiv:hover p {height: 0;opacity: 0;padding: 0;}
.inviteMateDiv.sent .grp1 {display:none;}

.grpApproveList {overflow: hidden;margin: 0 !important;width: 100% !important;background: #ffffff;} 
.grpApproveList li {position: relative;cursor: default;overflow:hidden;transition: 0.2s;color: #737373;height: 58px;line-height:  55px;border-bottom: 1px solid #f1f3f5;box-sizing:  border-box;padding: 0;width:  100%;background:  #fff;padding-bottom: 15px;}
.grpApproveList li:last-child p.grpAppListDiv { border: none }
.grpApproveList li:hover a.delete span {font-size: 0;display: none;width: 4px;height: 4px;border-radius: 50%;background: #000;float: left;margin: 19px 2px;}
.grpApproveList li:hover a.delete span:first-child {margin-left: 10px;}
.grpApproveList li:hover a.delete {transition: 0.1s;display: block;opacity: 1;right: 20px;}
.grpApproveList li p {text-align: left;line-height:  55px;color: #737373;width: 100%;}
.grpApproveList li p span {color: #484848;float: left;}
.grpApproveList li:hover p span { color:#484848; }
.grpApproveList li:hover p span.line { background-color:#a9a9a9;  }
.grpApproveList li.on p span.line { background-color:#a9a9a9 }
.grpApproveList li.on p span { color:#484848;  }
.grpApproveList li span.line { margin-top: 11px;background-color: #e3e3e3; width: 1px; height: 10px; vertical-align: middle; display: inline-block; }
.grpApproveList li span.userName, .grpApproveList li span.tel, .grpApproveList li span.email {display: inline-block; ;overflow:hidden; text-overflow: ellipsis;  }
.grpApproveList li span.userName {text-align: left;width: 20%;padding-right: 10px;box-sizing: border-box;position: relative;font-weight:  bold;text-align: left;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.grpApproveList li span.tel {width: 20%;padding: 0 5px;text-align: left;box-sizing: border-box;}
.grpApproveList li span.email {width: 30%;padding: 0 5px;box-sizing:  border-box;text-align: left;padding-right: 5px;}
.grpApproveList li.on p{color:#484848;}
.grpApproveList .dotBg { float: left; display: inline-block;  height: 16px; width: 22px; padding-top: 9px; padding-bottom: 1px;}
.grpApproveList .dot { display: inline-block; width: 4px; height: 4px; border-radius: 2px; background-color: #e3e3e3; }
.grpApproveList li.on .dot, .grpApproveList li:hover .dot { background-color: #a9a9a9;  }
.grpApproveList .grpAppCheck {/* display: none; */float: left;display: inline-block;text-align: center;width: 9%;/* position: absolute; */left: 0;padding: 0;top: 0;}

.hc #grpApprove input[type='checkbox']:not(old):checked + label {background: #36bdff url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 22px;border: 2px solid #36bdff;}

.sortHeader p {padding-top: 0;padding-bottom: 0;line-height: 60px !important;}

.grpAppCheck{float:left;padding-top:6px;}
.grpAppListDiv{float:left;width: auto;line-height:33px;box-sizing: border-box;white-space:nowrap;padding-left: 0;line-height: 55px;width: 100%;overflow:hidden;text-overflow:ellipsis;}
.grpApproveList p#grpAppListDivect img {border-radius:50%;border: 1px solid #f3f3f3;background-color: #e9eff4;overflow:hidden;width: 35px;height: 35px;margin-top: 9px;float:  left;margin-right: 25px;}
.grpApproveList p#grpAppListDivect .num {width: 7%;padding-right: 10px;box-sizing: border-box;text-align: center;}
.grpApproveList ul#group_member_ul {padding: 0;width: 100%;float: left;margin-bottom:150px;}
.grpApproveList span.userName {font-weight:  bold;}
.grpApproveList li:hover {background: #f6f9fb;transition: 0.2s;}
.grpApproveList span.timeition {background: #ececec url(../assets/images/contents/ic_king.png) no-repeat center center;background-size: 17px;font-size: 0;border-radius: 50%;width: 32px;margin: 0 !important;height: 32px;display:  inline-block;position:  absolute;right: 10px;top: 12px;}
.grpApproveList span.timeition.top {background: #ffe28d url(../assets/images/contents/ic_king.png) no-repeat center center;background-size: 17px;}
a#grupMyRemove {font-size: 12px;color: #fff !important;font-weight: bold;width: 50px;line-height: 55px;background: #445667;}
.grpApproveList .userName.double {line-height: 40px;height: 50px;}
.grpApproveList span.userName span {/* margin-top: 13px; */font-weight: normal;text-align: left;position: absolute;top: 18px;left: 0;}
.grpApproveList li:hover span {font-weight: bold;}

#grpApprove .grpApproveList li { overflow:visible; }
#grpApprove .grpApproveList li.changed .time .txt { color:#477fff !important; }
#grpApprove ul.dropdown {z-index: 100000000000;width: 160px;top: 48px;left: 32%;}
#grpApprove ul.dropdown li {padding-bottom: 0 !important;height: 46px;border-bottom: 0;}

.grpApproveUl li:hover .time span.txt, #grpApprove .grpApproveUl li:hover .time:hover { cursor:default; }
#grpApprove .grpApproveList {overflow: visible;}
#grpApprove  .over_hidden {overflow: visible;}
#grpApprove .settingBg .btn_group a#inviteMate {display: inline-block;}
#grpApprove .settingBg .btn_group a {display: none;}
#grpApprove .settingBg .btn_group a {display: inline-block;}
</style>