<template>
	<div id="weekly-calendar" :class="calendar_view_type">
		<iframe id="weekly-iframe" src="/weekly-calendar.html" v-if="iframe_visible == true && event_processing_complete == true" ref="weekly"/>
	</div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { CALENDAR_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');

import moment from 'moment'
import { RRule, RRuleSet, rrulestr } from 'rrule'

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
	components: {
		
	},
})
export default class WeeklyCalendar extends Mixins(VueHoduCommon) {

	$refs !: { weekly : HTMLFormElement; }

    /**
     * @CalendarInfo.State
     */
	@CalendarInfo.State calendar_view_type !: string; // 현재 달력 모드, 기본값 dayGridMonth (월 달력)

	iframe_visible : boolean = true;
	event_processing_complete : boolean = true;

	mounted() : void {
		window['updateEvents'] = this.updateEvents;
	}

	/**
	 * 일정 조각 업데이트
	 */
	async updateEvents(start_date : Date, events : any) : Promise<void> {
		console.log(events);
		
		const start_time = new Date();

		this.event_processing_complete = false;

		// 가공
		const temp : any[] = [];

		const DAY_UNIT = 24 * 60 * 60 * 1000;
		const TIMEZONE_OFFSET = -(new Date().getTimezoneOffset() * 60 * 1000);

		start_date = new Date(start_date.getTime() - TIMEZONE_OFFSET);

		// 일정 가공
		for( const _event of events ) {

			const event = _event.eventAtomic;
			const event_data = event.event_data;
			const start = moment(event_data.schedule_date.start).toDate();
			const end = moment(event_data.schedule_date.end).toDate();
			// const rrule = event_data.schedule_date.rrule;
			const rrule = _event.rrule;
			const exdate = event_data.schedule_date.exdate ? event_data.schedule_date.exdate : [];
			
			const start_day = Math.floor((start.getTime() + TIMEZONE_OFFSET) / DAY_UNIT);
			const end_day = Math.floor((end.getTime() + TIMEZONE_OFFSET) / DAY_UNIT);

			// 일반 일정 (연속일정 쪼개기)
			if( rrule == null || rrule.length < 1 ) {

				console.log(`${event_data.title}-${start_day}-${end_day}`);
				
				for( let i = start_day; i <= end_day; i++ ) {
					
					const target = new Date((i * DAY_UNIT) - TIMEZONE_OFFSET);

					// 해당 주간에 들어오는 일정만 push
					if( !(start_date.getTime() <= target.getTime() && target.getTime() <= (start_date.getTime() + (8 * DAY_UNIT) - 1) )) {
						continue;
					} 

					const event_temp = {
						"title" : event_data.title,
						"start" : target,
						"color" : this.hodu_hex_color_process(event_data.color)
					};
					temp.push(event_temp);
				}
				continue;
			}

			// 반복 일정 (반복일정 쪼개기)
			const rrule_info : RRule | RRuleSet = rrulestr(rrule);
			
			const day_diff = end_day - start_day;
			
			const rrule_dates : Date[] = rrule_info.all();
			for( const date of rrule_dates ) {
				const rrule_start_day = Math.floor((date.getTime()) / DAY_UNIT);
				const rrule_end_day = rrule_start_day + day_diff;

				for( let i = rrule_start_day; i <= rrule_end_day; i++ ) {

					const target = new Date((i * DAY_UNIT) - TIMEZONE_OFFSET);

					// 해당 주간에 들어오는 일정만 push
					if( !(start_date.getTime() <= target.getTime() && target.getTime() <= (start_date.getTime() + (8 * DAY_UNIT) - 1) )) {
						continue;
					} 

					// exdate에 포함된다면 삭제된것임 
					let is_deleted : boolean = false;
					for( const exdate_date of exdate ) {
						if( moment(exdate_date).format("YYYYMMDD") == moment(date).format("YYYYMMDD") ) {
							is_deleted = true;
							break;
						}
					}

					// 이 일정만 삭제된 경우 제외 
					if( is_deleted == true ) continue;

					const event_temp = {
						"title" : event_data.title,
						"start" : target,
						"color" : this.hodu_hex_color_process(event_data.color)
					};
					temp.push(event_temp);
				}
			}
		}

		this.event_processing_complete = true;
		console.log(temp);

		let month_start : Date = new Date();
		const first_day = moment(start_date).set('date', 1).toDate();
		month_start = moment(first_day).add('day', -first_day.getDay()).toDate();
		
		let month_end : Date = new Date();
		const last_day = moment(start_date).add('month', 1).set('date', 0).toDate();
		month_end = moment(last_day).add('day', 6 - last_day.getDay()).toDate();

		let week_start : Date = new Date(start_date);
		let week_end : Date = new Date(start_date.getTime() + (7 * DAY_UNIT) - 1);

		console.log(`${month_start}\n${month_end}\n${week_start}\n${week_end}`);

		let holidays : Map<string, Object> = new Map(); // 공휴일 Map
		let holi_days : any[] = await this.hodu_get_holidays(month_start, month_end);
		
		// 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

		const end_time = new Date();
		console.log(`${end_time.getTime() - start_time.getTime()} ms`);

		this.$nextTick(() => this.$refs.weekly.contentWindow['initWeeklyCalendar'](month_start, month_end, week_start, week_end, temp, holidays));
	}

	@Watch('calendar_view_type')
	watchCalendarViewType() : void {
		this.iframe_visible = (this.calendar_view_type == CALENDAR_TYPE.TIME_GRID_WEEK);
	}
}
</script>

<style scoped>
	#weekly-calendar { display: none; list-style:none; width: 100%; height: 99%; box-sizing: border-box; padding: 0; margin: 0; font-family: 'Noto Sans KR','sans-serif';  }
	#weekly-iframe { box-sizing: border-box; padding: 0; margin: 0; width : 100%; height:100%; min-width: 1200px; }
</style>