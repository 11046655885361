<template>
    <div id="equipment_management_detail_modal" class="modal">

        <div class="titlebox">
            <h1>장비관리 이력 상세</h1>
        </div>

        <div class="modal_content" v-if="equipment != null && management != null">
            <div class="modal_detail">
                <div class="Moleft_con">
                    <div class="eq_img" v-if="management.management_data.images.length < 1"></div>

                    <div class="eq_img" v-else>
                        <Hooper class="imageSlideContainer" ref="hooperSlide" @slide="updateViewIndex" :initialSlide="view_index">
                            <Slide class="imageSlide" :key="`${image.name}-${index}`" v-for="(image, index) in management.management_data.images">
                                <p :id="`slideImage_${index}`" class="hooperImage" :style="{ backgroundImage : `url(app_images/${image.url})` }"  @click.prevent="showImageBigger(image)"/>
                            </Slide>
                        </Hooper>
                        <div class="dot_container" v-if="management.management_data.images.length > 1">
                            <a href="#" class="dot" :class="{ on : index == view_index }" :key="`${image.name}-${index}`" v-for="(image, index) in management.management_data.images" @click.prevent="slideTo(index)"></a>
                        </div>
                    </div>

                    <!-- 장비명 -->
                    <strong class="eqI_title">{{ equipment.equipment_data.name }}</strong>
                    <p class="img_ex">*클릭시 원본 이미지를 볼 수 있습니다.</p>
                </div>
                <div class="moright_text">
                    <ul>
                        <li>
                            <p class="title">내용</p>
                            <p class="descript">{{ hodu_string_validation(management.management_data.repair_items) }}</p>
                        </li>
                        <li>
                            <p class="title">업체</p>
                            <p class="descript">{{ hodu_string_validation(management.management_data.repairer) }}</p>
                        </li>
                        <li>
                            <p class="title">담당자</p>
                            <p class="descript">{{ hodu_string_validation(management.management_data.manager) }}</p>
                        </li>
                        <li>
                            <p class="title">연락처</p>
                            <p class="descript">{{ hodu_string_validation(management.management_data.repair_tel) }}</p>
                        </li>
                        <li>
                            <p class="title">등록일</p>
                            <p class="descript">
                                {{ hodu_date_to_format_string(yyyymmddToDate(management.management_data.repair_date), 'YYYY.MM.DD') }}
                                ({{ getDayOfWeekByDate(yyyymmddToDate(management.management_data.repair_date)) }})
                            </p>
                        </li>
                        <li>
                            <p class="title">완료일</p>
                            <p class="descript">
                                {{ hodu_date_to_format_string(yyyymmddToDate(management.management_data.repair_complete_date), 'YYYY.MM.DD') }}
                                ({{ getDayOfWeekByDate(yyyymmddToDate(management.management_data.repair_date)) }})
                            </p>
                        </li>
                        <li>
                            <p class="title">비용</p>
                            <p class="descript">{{ hodu_string_validation(String(management.management_data.repair_price)) }}</p>
                        </li>
                    </ul>
                </div>
                <div class="mo_ex">
                    <p class="eqMo_ex">{{ hodu_string_validation(management.management_data.memo, '') }}</p>
                </div>
            </div>
        </div>

        <div class="btns">
            <input type="button" value="닫기" @click.prevent="close">
            <input type="button" value="삭제" @click.prevent="remove">
            <input type="button" value="수정" @click.prevent="modify">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { Hooper, Slide } from 'hooper';

@Component({
    components: {
        Hooper, Slide
    },
})
export default class EquipmentManagementDetailModal extends Mixins(VueHoduCommon) {
    
    // this.$refs.hooperSlide 사용
    $refs !: { hooperSlide : HTMLFormElement; }

    /**
     * ModalInfo.State
     */
    @ModalInfo.State equipment_management_detail_modal_info !: hodu_home_modal_info.EquipmentManagementDetailModalInfo; 

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEquipmentManagementDetailModalInfo ?: (params : hodu_home_modal_info.EquipmentManagementDetailModalInfo) => void;
    
    equipment : any = null;
    management : any = null;

    view_index : number = 0;

    mounted() : void {
        Promise.all([this.getHomeEquipment(), this.getHomeEquipmentManagement()]);
    }

    /**
     * 장비 조회
     */
    async getHomeEquipment() : Promise<void> {

        try {

            const uid = this.equipment_management_detail_modal_info.uid;

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.equipment ) {
                throw new Error("장비 조회 중 오류 발생");
            }

            this.equipment = JSON.parse(JSON.stringify(response.data.data.equipment));

        } catch(e) {
            alert("장비 조회 중 오류 발생");
            this.equipment_management_detail_modal_info.callback?.();
            this.hodu_error_process(e, false, false, true);
            close();
        } 

    }

    /**
     * 장비 관리 이력 상세 조회
     */
    async getHomeEquipmentManagement() : Promise<void> {

        try {

            const uid = this.equipment_management_detail_modal_info.uid;
            const seq = this.equipment_management_detail_modal_info.seq; 

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${uid}/management/${seq}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.management ) {
                throw new Error("장비 관리 이력 조회 중 오류 발생");
            }

            this.management = JSON.parse(JSON.stringify(response.data.data.management));
            
        } catch(e) {
            alert("장비 관리 이력 조회 중 오류 발생");
            this.equipment_management_detail_modal_info.callback?.();
            this.hodu_error_process(e, false, false, true);
            this.close();
        } 

    }

    /**
     * 장비 관리 이력 삭제
     */
    async deleteHomeEquipmentManagement() : Promise<void> {
        try {

            const uid = this.equipment_management_detail_modal_info.uid;
            const seq = this.equipment_management_detail_modal_info.seq; 

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${uid}/management/${seq}`, API_METHOD.DELETE);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("장비 관리 이력 삭제 중 오류 발생");
            }

            this.equipment_management_detail_modal_info.callback?.();
            this.close();
            
        } catch(e) {
            alert("장비 관리 이력 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            Promise.all([this.getHomeEquipment(), this.getHomeEquipmentManagement()]);
        }
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 이미지 크게 보기
     */
    showImageBigger(image) : void {
        if( this.$refs.hooperSlide.isSliding == true ) return;
        window.open(`app_images/${image.url}`, '_blank');
    }

    /**
     * 현재 보고있는 인덱스로 세팅
     */
    updateViewIndex(payload : any) : void {
        this.view_index = payload.currentSlide;
    }

    /**
     * 해당 인덱스로 이동
     */
    slideTo(index : number) : void {
        this.view_index = index;
        this.$refs.hooperSlide.slideTo(index);
    }

    /**
     * 수정
     */
    modify() : void {
        const uid = this.equipment_management_detail_modal_info.uid;
        const seq = this.equipment_management_detail_modal_info.seq; 

        this.hodu_router_push(`/home/equipment/${uid}/management/modify/${seq}`);
        this.close();
    }

    /**
     * 삭제
     */
    remove() : void {
        if( confirm("정말 장비 관리 이력을 삭제 하시겠습니까?") == false ) return;
        this.deleteHomeEquipmentManagement();
    }

    /**
     * 닫기
     */
    close() : void {
        this.doSetEquipmentManagementDetailModalInfo?.({ show_modal : false, uid : "", seq : 0 });
    }

}
</script>

<style scoped>
    #equipment_management_detail_modal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-305px; margin-left:-400px; width:800px; height: 610px; background:#fff; }
    #equipment_management_detail_modal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0 30px;border-bottom: 1px solid #e7e9ea; }
    #equipment_management_detail_modal .titlebox h1 { font-size: 20px; font-weight: bold; height: 90px; line-height: 90px; }
    #equipment_management_detail_modal .modal_content { overflow: hidden; height: 465px; border-bottom: 1px solid #e7e9ea; }
    #equipment_management_detail_modal .modal_content .modal_detail { overflow: hidden; }

    .modal_content .Moleft_con { float: left; width: 40%; background: #f1f3f5; text-align: center; height: 392px; box-sizing: border-box; padding: 0 40px; }
    .modal_content .Moleft_con .eq_img { position : relative; width: 230px; height: 270px; border-radius: 5px; margin: 0 auto; margin-top: 22px; background: url('../../../assets/images/contents/hoduhome_car_camera.png') no-repeat center center; background-size: cover; box-shadow: 0 5px 10px rgb(0 0 0 / 10%); border: 3px solid #e7e9ea; font-size: 0; }
    .modal_content .Moleft_con .eqI_title { margin: 25px 0 20px 0; display: block; }
    .modal_content .Moleft_con .img_ex { color: #989bab; }

    .modal_content .moright_text ul li { padding: 0 30px; overflow: hidden; line-height: 55px; height: 55px; border-bottom: 1px solid #f1f3f5; }
    .modal_content .moright_text ul li p { display: inline-block; font-size: 13px; }
    .modal_content .moright_text ul li p.descript { float: right; text-align: right; font-weight: bold; }

    .modal_content .mo_ex .eqMo_ex { height : 55px; line-height: 55px; padding: 0 25px; width: 87%; font-weight: bold; color: #989bab; background: #f1f3f5; border-radius: 10px; margin: 9px auto 0 auto; }

    #equipment_management_detail_modal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    #equipment_management_detail_modal .btns input { transition:0.2s; width: 33.3%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold;background: #fff; }
    #equipment_management_detail_modal .btns input:hover { background:#f1f3f5; }

    #equipment_management_detail_modal .hooper { width : 100%; height : 100%; }
    #equipment_management_detail_modal .hooper .imageSlide { padding : 0; width : 100%; height: 100%; }
    #equipment_management_detail_modal .hooperImage { width : 100%; height : 100%; background-size: cover; background-position: center; cursor: pointer; }

    #equipment_management_detail_modal .dot_container { position : absolute; width : 100%; height : 25px; bottom : 0; left : 0; cursor : default; background-color : rgba(0, 0, 0, 0.5); }
    #equipment_management_detail_modal .dot_container .dot { display : inline-block; width : 10px; height : 10px; border : 1px solid #000; border-radius: 50%; background-color : #f1f3f5; box-sizing: border-box; margin-top : 7.5px; margin-right: 3px; }
    #equipment_management_detail_modal .dot_container .dot.on { border-color : #fff; background-color: #477fff; }
</style>