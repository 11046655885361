<template>
    <div id="work_pdf_modal" class="modal section_ce_modal">
        <TitleBox class="title_box" :back_disable="true" :title="'업무 문서화'" />
        <div class="content">

            <ul>

                <!-- 업무 종류 -->
                <li>
                    <dl>
                        <dt><label>업무 종류</label></dt>
                        <dd>
                            <div class="work_type_div">
                                <input type="radio" id="work_type_report" name="work_type" value="REPORT" v-model="work_type" @change="changeWorkType" v-if="getReportReadPermission() == true"/> <label for="work_type_report" v-if="getReportReadPermission() == true">업무일지</label> 
                                <input type="radio" id="work_type_meeting_log" name="work_type" value="MEETINGLOG" v-model="work_type" @change="changeWorkType" v-if="getMeetingLogReadPermission() == true"/> <label for="work_type_meeting_log" :class="{ none_report : getReportReadPermission() == false }" v-if="getMeetingLogReadPermission() == true">회의록</label>
                            </div>
                        </dd>
                    </dl>
                </li>

                <!-- 업무 문서 종류 -->
                <li class="document_type_li">
                    <dl>
                        <dt><label>문서 종류</label></dt>
                        <dd>
                            <div class="document_type_div">

                                <div class="document_fragment" :class="`${ document_type == document.type ? 'on' : '' } ${document.type}`"
                                     :key="index" v-for="(document, index) in (work_type == 'REPORT' ? report_documents : meeting_log_documents)">
                                    <img @click="selectDocument($event, document.type)"/>
                                    <span>{{ document.text }}</span>
                                    <input type="button" class="preview" value="미리보기" @click="preview(document.preview)"/>
                                </div>

                                <!-- <div class="document_fragment">
                                    <img src="@/assets/images/popup/voucher_popup.png" />
                                    <span>일일업무일지1</span>
                                </div>
                                <div class="document_fragment">
                                    <img src="@/assets/images/popup/voucher_popup.png" />
                                    <span>일일업무일지2</span>
                                </div>
                                <div class="document_fragment">
                                    <img src="@/assets/images/popup/voucher_popup.png" />
                                    <span>주간업무일지1</span>
                                </div>
                                <div class="document_fragment">
                                    <img src="@/assets/images/popup/voucher_popup.png" />
                                    <span>주간업무일지2</span>
                                </div> -->

                            </div>
                        </dd>
                    </dl>
                </li>

                <!-- 날짜 -->
                <li class="schDateList">
                    <dl>
                        <dt><label for="sch_from">날짜</label></dt>
                        <dd>
                            <input type="button" id="work_search_sch_from" class="input_txt wd45" :value="!start ? '검색 시작일' : start_text" :class="{ on : start != null }"/>
                            <span class="ware_seper">~</span>
                            <input type="button" id="work_search_sch_to" class="input_txt wd45 filterEndDate" :value="!end ? '검색 종료일' : end_text" :class="{ on : end != null }"/>
                        </dd>
                    </dl>
                </li>

                <!-- 제목 -->
                <li>
                    <dl>
                        <dt><label for="sch_title">제목</label></dt>
                        <dd>
                            <input id="sch_title" type="text" class="input_txt" placeholder="제목을 입력하세요" :value="title" @input="title = $event.target.value" />
                        </dd>
                    </dl>
                </li>

                <!-- 내용 -->
                <li>
                    <dl>
                        <dt><label for="sch_title">내용</label></dt>
                        <dd>
                            <input id="sch_title" type="text" class="input_txt" placeholder="내용을 입력하세요" :value="content" @input="content = $event.target.value" />
                        </dd>
                    </dl>
                </li>

                <!-- 색상 -->
                <li class="sch_clr_li">
                    <dl>
                        <dt><label for="sch_place">일정 색상</label></dt>
                        <dd>
                            <div class="sch_clr_div">
                                <ul>

                                    <li :key="color" v-for="(color, index) in dc_color">
                                        <input type="checkbox" :id="`sch_clr_dc0${index}`" class="sch_clr_dc" :checked="isContainColor(color)" @change="changeSearchColor($event, color)"/>
                                        <label :for="`sch_clr_dc0${index}`" :class="hodu_color_dc_lc(color)" class="sch_clr_dc">{{ hodu_color_dc_lc_label_text(color) }}</label>
                                    </li>

                                    <li :key="color" v-for="(color, index) in lc_color">
                                        <input type="checkbox" :id="`sch_clr_lc0${index}`" class="sch_clr_lc" :checked="isContainColor(color)" @change="changeSearchColor($event, color)"/>
                                        <label :for="`sch_clr_lc0${index}`" :class="hodu_color_dc_lc(color)" class="sch_clr_lc">{{ hodu_color_dc_lc_label_text(color) }}</label>
                                    </li>

                                </ul>
                                <div class="selectAllDiv">
                                    <p>
                                        <input type="checkbox" id="selectAllClrs" v-model="is_color_all_check" @change="changeColorAllCheck"/>
                                        <label for="selectAllClrs" class="dc7"><span class="">전체 선택</span></label>
                                    </p>
                                </div>
                            </div>
                        </dd>	
                    </dl>
                </li>

                <!-- 작성자 -->
                <li>
                    <dl>
                        <dt><label for="sch_title">작성자</label></dt>
                        <dd>
                            <input id="sch_title" type="text" class="input_txt" placeholder="작성자를 입력하세요" :value="writer" @input="writer = $event.target.value" />
                        </dd>
                    </dl>
                </li>

            </ul>

            <!-- 버튼 영역 -->
            <div class="save_last_section">
                <input type="button" value="취소" @click="closeModal">
                <input type="button" value="검색 초기화" @click="reset">
                <input type="button" value="검색" @click="search">
            </div>
            
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { CALENDAR_TYPE, EVENT_SUB_TYPE, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import TitleBox from '@/components/ui/TitleBox.vue';

import moment from 'moment';

// import { directive as onClickaway } from 'vue-clickaway';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    // directives: {
    //     onClickaway: onClickaway,
    // },
    components: {
        TitleBox
    },
})
export default class WorkPdfModal extends Mixins(VueHoduCommon) {

    /**
     * ModalInfo.State
     */
    @ModalInfo.State show_work_pdf_modal !: boolean;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowWorkPdfModal ?: (params : boolean) => void;

    work_type : EVENT_SUB_TYPE = EVENT_SUB_TYPE.REPORT;
    document_type : string = "daily_report_1";

    report_documents : any[] = [
        { "type" : "daily_report_1", "text" : "일일업무일지1", "preview" : "ic_preview_report_1.png" }, 
        { "type" : "daily_report_2", "text" : "일일업무일지2", "preview" : "ic_preview_report_2.png" }, 
        { "type" : "weekly_report_1", "text" : "주간업무일지1", "preview" : "ic_preview_report_3.png" }, 
        { "type" : "weekly_report_2", "text" : "주간업무일지2", "preview" : "ic_preview_report_4.png" }
    ];
    meeting_log_documents : any[] = [
        { "type" : "meeting_log_1", "text" : "회의록1", "preview" : "ic_preview_meeting_log_1.png" }, 
        { "type" : "meeting_log_2", "text" : "회의록2", "preview" : "ic_preview_meeting_log_2.png" }
    ];

    start : Date | null = null;
    end : Date | null = null;

    start_text = "";
    end_text = "";

    title : string = "";
    content : string = "";
    writer : string = "";

    selected_color : string[] = [];
    is_color_all_check : boolean = false;

    first_event_complete : boolean = false;

    beforeMount() : void {
        this.start = moment().set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
        this.end = moment(this.start).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
        this.makeDateText();
    }

    mounted() : void {
        this.datepickerInit();

        if( this.getReportReadPermission() == false ) {
            this.work_type = EVENT_SUB_TYPE.MEETINGLOG;
            this.changeWorkType(this.work_type);
        }

        $('#left_area, #right_area_controller, #header').on('click', (event) => {
            event.preventDefault();
            if( !this.first_event_complete ) {
                this.first_event_complete = true;
                return;
            }
            this.closeModal();
        });        
    }

    beforeDestroy() : void {
        $('#left_area, #right_area_controller, #header').off('click');
    }

    /**
     * datepicker 세팅
     */
    datepickerInit() : void {

        let datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange : '1900:2050',
            onSelect : this.dateChange
        };

        // @ts-ignore
        $('#work_search_sch_from').datepicker(datepicker_option);

        // @ts-ignore
        $('#work_search_sch_to').datepicker(datepicker_option);

    }
    
    /**
     * 날짜 변경
     */
    dateChange(dateText, inst) : void {
        const selected_date = moment(dateText).toDate();

        if( this.document_type.includes('weekly') == false ) {
            inst.input.val(`${this.hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(selected_date, '요일')}`);
        }
        
        if( inst.id == 'work_search_sch_from' ) {
            
            // 일반
            if( this.document_type.includes('weekly') == false ) {
                this.start = moment(selected_date).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                if( this.end == null || this.end.getTime() < this.start.getTime() ) {
                    this.end = moment(selected_date).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                }
            } 
            
            // 주간
            if( this.document_type.includes('weekly') == true ) {
                this.start = moment(selected_date).set('day', 1).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                this.end = moment(this.start).set('date', moment(this.start).get('date') + 4).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                inst.input.val(`${this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.start, '요일')}`);
            }
        }

        if( inst.id == 'work_search_sch_to' ) {

            // 일반 
            if( this.document_type.includes('weekly') == false ) {
                this.end = moment(selected_date).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                if( this.start == null || this.end.getTime() < this.start.getTime() ) {
                    this.start = moment(selected_date).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                }
            }

            // 주간
            if( this.document_type.includes('weekly') == true ) {
                this.start = moment(selected_date).set('day', 1).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                this.end = moment(this.start).set('date', moment(this.start).get('date') + 4).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                inst.input.val(`${this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.end, '요일')}`);
            }
        }

        this.makeDateText();
    }
    
    /**
     * 날짜 텍스트 생성
     */
    makeDateText() : void {

        if( this.start != null ) {
            this.start_text = `${this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.start, '요일')}`;
        }

        if( this.end != null ) {
            this.end_text = `${this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.end, '요일')}`;
        }

    }

    /**
     * 업무 종류 선택
     */
    changeWorkType(event : any) : void {
        try {
            if( this.work_type == EVENT_SUB_TYPE.REPORT ) {
                this.document_type = this.report_documents[0].type;
            }

            if( this.work_type == EVENT_SUB_TYPE.MEETINGLOG ) {
                this.document_type = this.meeting_log_documents[0].type;
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 문서 종류 선택
     */
    selectDocument(event : any, document_type : string) : void {
        this.document_type = document_type;

        // 날짜 변경
        if( document_type.includes('weekly') == true && this.start && this.end ) {
            this.start = moment(this.start).set('day', 1).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
            this.end = moment(this.start).set('date', moment(this.start).get('date') + 4).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
            this.makeDateText();
        }
    }

    /**
     * 이미지 미리보기
     */
    preview(image_name : string) : void {
        window.open(`/img/preview/${image_name}`, "_blank");
    } 

    /**
     * 해당 컬러가 검색 할 컬러에 포함되어있는지 여부 반환
     */
    isContainColor(color : string) : boolean {
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();
        return (this.selected_color.indexOf(upper_color) > -1);
    }

    /**
     * 검색 컬러 변경
     */
    changeSearchColor(event : any, color : string) : void {
        const checked : boolean = event.target.checked;
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();

        const color_index : number = this.selected_color.indexOf(upper_color);

        // 체크 하는 경우
        if( checked == true ) {
            if( color_index < 0 ) { this.selected_color.push(upper_color); }
        }

        // 체크를 해제 하는 경우
        else {
            if( color_index > -1 ) { this.selected_color.splice(color_index, 1); }
        }

        this.is_color_all_check = this.selected_color.length == 20;
    }

    /**
     * 색상 전체 선택 / 선택 해제
     */
    changeColorAllCheck(event : any) : void {
        const checked : boolean = event.target.checked;

        this.selected_color.splice(0, this.selected_color.length);
        if( checked ) {
            for( const color of this.dc_color ) this.selected_color.push(color);
            for( const color of this.lc_color ) this.selected_color.push(color);
        }
    }
    
    /**
     * 검색 타입 초기화
     */
    reset() : void {
        this.work_type = EVENT_SUB_TYPE.REPORT;
        this.document_type = this.report_documents[0].type;

        this.start = null;
        this.end = null;
        this.start_text = "";
        this.end_text = "";

        this.title = "";
        this.content = "";
        this.writer = ""; 

        this.selected_color.splice(0, this.selected_color.length);
        this.is_color_all_check = false;
    }

    /**
     * 검색
     */
    search() : void {
        if( !this.check() ) return;

        if( this.start == null || this.end == null) return;

        // popup open
        let query = "";
        query += `calendar_id=${this.calendar_id}`;
        query += `&event_sub_type=${this.work_type}`;
        query += `&document_type=${this.document_type}`;
        query += `&start=${moment(this.start).utc().format()}`;
        query += `&end=${moment(this.end).utc().format()}`;
        
        if( this.title.length > 0 ) query += `&title=${encodeURIComponent(this.title)}`;
        if( this.content.length > 0 ) query += `&content=${encodeURIComponent(this.content)}`;
        if( this.writer.length > 0 ) query += `&writer=${encodeURIComponent(this.writer)}`; 

        query += `&request_user_id=${this.user_id}`;
        query += `&scope=${this.scope}`;
        query += `&scope_id=${this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id}`;
        if( this.selected_color != null && this.selected_color.length < 20 ) {
            const color_size : number = this.selected_color.length;
             for( let i = 0; i < color_size; i++ ) {
                 if( this.selected_color[i] == null || this.selected_color[i].length < 7 ) {
                     continue;
                 }

                 query += `&color=${encodeURIComponent(this.selected_color[i])}`;
                 if( this.selected_color[i].length == 7 ) { query += `&color=${encodeURIComponent(this.selected_color[i].replace(/#/, "#FF"))}`; }
                 if( this.selected_color[i].length == 9 ) { query += `&color=${encodeURIComponent(this.selected_color[i].replace(/#FF/, "#"))}`; }
             }
        }

        const timezone = -(new Date().getTimezoneOffset());
        query += `&timezone=${timezone ? timezone : 540}`;

        try {
            let type = navigator.appName;
            let country_code = 'ko-KR';
            if (type == 'Netscape') {
                country_code = navigator.language;
            } else {
                country_code = navigator['userLanguage'];
            }
            query += `&country_code=${country_code}`;
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            query += `&country_code=ko-KR`;
        }

        window.open(`/work_pdf.html?${query}`, "work_pdf", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);
        this.closeModal();
    }

    /**
     * 옵션 체크
     */
    check() : boolean {

        if( this.start == null ) {
            this.hodu_show_dialog('alert', "날짜를 입력해주세요", ['확인'], [() => document.getElementById('work_search_sch_from')?.focus()]);
            return false;
        }

        if( this.end == null ) {
            this.hodu_show_dialog('alert', "날짜를 입력해주세요", ['확인'], [() => document.getElementById('work_search_sch_to')?.focus()]);
            return false;
        }

        this.title = this.title.trim();
        this.content = this.content.trim();
        this.writer = this.writer.trim();

        return true;
    }

    /**
     * 업무 일지 읽기 권한 체크
     */
    getReportReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "report", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "report", "read");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 회의록 읽기 권한 체크
     */
    getMeetingLogReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "meetinglog", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "meetinglog", "read");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        this.doSetShowWorkPdfModal?.(false);
    }

}
</script>

<style scoped>
    #work_pdf_modal { position : absolute; background : transparent; width : 950px; height : 707px; z-index: 100000001 !important; }
    #work_pdf_modal .title_box { background : #fff; height : 61px; border-bottom: 1px solid #ececec; max-width: 950px; }
    #work_pdf_modal .content { max-width: 950px; height : 645px; background : #fff; box-shadow: 16px 10px 17px rgba(0,0,0,0.15); border-radius: 0 0 15px 15px; }
    #work_pdf_modal .save_last_section { border-top: 1px solid #f1f3f5; overflow: hidden; margin-top: 15px; }
    #work_pdf_modal .save_last_section input { width: 33.3333%; float: left; height: 70px;border-bottom: 0 !important;  background: #fff; line-height: 70px; font-size: 14px; font-weight: bold; transition:0.2s; }
    #work_pdf_modal .save_last_section input:hover { cursor: pointer; background: #f1f3f5; }

    #work_pdf_modal .work_type_div { width: 100% !important; border-bottom: 1px solid #e7e9ea; position: relative; }
    #work_pdf_modal #work_type_meeting_log+label { margin-left : 15px; }
    #work_pdf_modal #work_type_meeting_log+label.none_report { margin-left : 0px; }
    #work_pdf_modal input[type='radio']:not(old)+label { height : 52px; line-height : 52px; background-position : 4px center; }

    #work_pdf_modal .document_type_div { width: 100% !important; height : 100%; border-bottom: 1px solid #e7e9ea; position: relative; }
    #work_pdf_modal .document_type_div .document_fragment { margin-top : 10px; margin-left : 10px; width : 80px; height : 150px; line-height : initial; }
    #work_pdf_modal .document_type_div .document_fragment:first-child { margin-left : 0; } 
    #work_pdf_modal .document_type_div .document_fragment > img { width : 80px; height : 113.12px; opacity: 0.3; cursor : pointer; border : 1px solid #f1f3f5; }
    #work_pdf_modal .document_type_div .document_fragment > img:hover { opacity: 1; }
    #work_pdf_modal .document_type_div .document_fragment.on > img { opacity: 1; border : 1px solid #477fff; }
    #work_pdf_modal .document_type_div .document_fragment.daily_report_1 > img { background-image : url("../../../assets/images/contents/ic_report_1.png"); background-size: cover; }
    #work_pdf_modal .document_type_div .document_fragment.daily_report_2 > img { background-image : url("../../../assets/images/contents/ic_report_2.png"); background-size: cover; }
    #work_pdf_modal .document_type_div .document_fragment.weekly_report_1 > img { background-image : url("../../../assets/images/contents/ic_report_3.png"); background-size: cover; }
    #work_pdf_modal .document_type_div .document_fragment.weekly_report_2 > img { background-image : url("../../../assets/images/contents/ic_report_4.png"); background-size: cover; }
    #work_pdf_modal .document_type_div .document_fragment.meeting_log_1 > img { background-image : url("../../../assets/images/contents/ic_meeting_log_1.png"); background-size: cover; }
    #work_pdf_modal .document_type_div .document_fragment.meeting_log_2 > img { background-image : url("../../../assets/images/contents/ic_meeting_log_2.png"); background-size: cover; }
    #work_pdf_modal .document_type_div .document_fragment > span { width : 80px; text-align: center; display: inline-block; }
    #work_pdf_modal .document_type_div .document_fragment > input.preview { width : 50px; height : 20px; margin : 0 15px; padding : 0 5px; font-size : 10px; text-align: center; border-radius: 3px; margin-top : 5px; background-color : #f1f3f5; }

    #work_pdf_modal .content > ul {  box-sizing:border-box; padding: 15px 30px;  }
    #work_pdf_modal .content > ul > li { position: relative; line-height: 55px; min-height: 55px; }
    #work_pdf_modal .content dl dt { position: absolute;left: 0;top: 0; display: inline-block; width: 100px; font-size: 13px; opacity: 0.8;font-weight: bold; }
    #work_pdf_modal .content > ul > li:hover dt { opacity: 1 }
    #work_pdf_modal .content dl dt label { width: 100%; display: inline-block; }
    #work_pdf_modal .content dl dd { display: inline-block; width: 100%; padding-left: 100px; box-sizing:border-box;}
    #work_pdf_modal .content dl dd div, .sch_options dl dd div p { display: inline-block; width: auto }
    #work_pdf_modal .content dl dd label { display: inline-block; }
    #work_pdf_modal .content dl dd input[type="text"], .sch_options dl dd select { padding-left:0 !important; border-bottom:1px solid #e7e9ea; width: 100% !important;line-height: 55px; height: 55px; box-sizing:border-box; }
    #work_pdf_modal .content dl dd input[type="text"]:hover, .sch_options dl dd select:hover  { border-bottom:1px solid #e3e7ed; background:#fff; }
    #work_pdf_modal .content dl dd input[type="text"]:focus, .sch_options dl dd select:focus  { border-bottom:1px solid #477fff; }
    #work_pdf_modal .content dl dd input[type="text"]#sch_title {font-size:17px }
    #work_pdf_modal .content dl dd input.wd45 { width: 45% !important; height: 54px; line-height: 54px; margin-top: 1px; }
    #work_pdf_modal .content dl dd input.wd45:hover { }
    #work_pdf_modal .content .schDateList dl dd input[type='button'] { text-align : left; color: #acb2bf; font-weight: bold; padding-left:0; border-bottom:1px solid #e3e7ed;}
    #work_pdf_modal .content .schDateList dl dd input[type='button']:focus{ border-color:#477fff; }
    #work_pdf_modal .content .schDateList dl dd input[type='button']:hover { background:#fff; color:#434343 }
    #work_pdf_modal .content .schDateList dl dd input[type='button'].on { color: #434343; }
    #work_pdf_modal .content select {-moz-user-select: none;-khtml-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;-webkit-appearance: none;outline: none;box-shadow: none !important; background: #fff; border-radius:0}
    #work_pdf_modal .content select:hover { cursor: pointer; }
    #work_pdf_modal .content dl dd input, .sch_options dl dd select, .sch_options dl dd { font-size: 13px; font-weight: bold; }
    #work_pdf_modal .content .ware_seper { text-align: center;display: inline-block; width: 10%;float: right; }

    #work_pdf_modal .schDateList { position: relative; }
    #work_pdf_modal .schDateList .ware_seper { position: absolute;left: 40%;margin-left: 100px;opacity: 0.5;top: 0;font-weight: normal;width: 50px;font-size: 14px; text-align: center; }
    #work_pdf_modal .filterEndDate { float: right; margin-top: 2px !important; }

    /* .sch_options #sch_clr { height: 55px;line-height: 55px;background: #fff;color: #000;text-align: left;padding-left: 0;border-bottom: 1px solid #f1f3f5; } */
    #work_pdf_modal .sch_clr_div li {display: inline-block;font-size: 0; height: 55px !important; line-height:55px;}
    #work_pdf_modal .sch_clr_div li label { transition:0.1s; cursor: pointer;float: left;width: 22px;height: 22px;box-sizing: border-box;background-position: -1px -1px !important; display: inline-block; border-radius: 50%;margin-top: 15px;margin-right: 13px;background-image: url(../../../assets/images/contents/checked_fff.png);   }
    #work_pdf_modal .sch_clr_div li label:hover { transform: scale(1.12) }
    #work_pdf_modal .sch_clr_div input[type='checkbox']:not(old) + label {  background-image: none;}
    #work_pdf_modal .sch_clr_div input[type='checkbox']:not(old):checked + label {background-image: url(../../../assets/images/contents/checked_fff.png); }
    #work_pdf_modal .sch_clr_div li:last-child label { margin-right:0; }
    #work_pdf_modal .sch_clr_div {width: 100% !important; border-bottom: 1px solid #e7e9ea; position: relative; }
    #work_pdf_modal .sch_clr_li dd:hover .selectAllDiv { display:block !important; }

    #work_pdf_modal .selectAllDiv {display:block !important;position: absolute;z-index: 100000;width: 40px !important;top:22px;height: 20px;left:0;background: #fff;box-sizing: border-box; border-right:1px solid #e3e7ed;}
    #work_pdf_modal .selectAllDiv p {position: relative;}
    #work_pdf_modal .selectAllDiv label span {display:none;position: absolute;width: 200px;left: 48px;top: 13px;font-size:12px;}
    #work_pdf_modal .selectAllDiv label {  }
    #work_pdf_modal .selectAllDiv input[type='checkbox']:not(old) + label { background-color:#c1cfd8 !important; background-image: url(../../../assets/images/contents/checked_fff.png);background-position: center center !important;padding: 0;width: 20px;height: 20px;border-radius: 3px;background-size: 23px !important;background-position: -1px -1px !important;}
    #work_pdf_modal .selectAllDiv input[type='checkbox']:not(old):checked + label {background-color:#477fff !important;}

    .sch_clr_div li:first-child { margin-left: 55px;}
</style>