<template>
    <div id="group_survey" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">내 설문지</h3>
            <p class="btn_group">
                <input type="button" value="템플릿 보기" class="input_btn" @click.prevent="viewTemplate">
            </p>
        </div>

        <div id="group_survey_scroll" class="section_scroll">
            <div class="settingBg">
                <h3 id="" class="selectTitle">
                    <span class="clr dc7">파랑</span> 호두웨어 
                </h3>
                <div class="schDiv">
                    <input type="text" placeholder="제목을 검색하세요">
                    <input type="button" class="schBtn">
                </div>
            </div>

            <div class="content">
                <div class="selectBg">
                    <h3 id="exampleText" class="selectTitle">
                        <span class="arrowDown"></span>전체
                    </h3>
                    <ul class="dropdown selectUl">
                        <li>
                            <a>전체</a>
                        </li>
                        <li>
                            <a>즐겨찾기</a>
                        </li>
                        <li>
                            <a>작성 중</a>
                        </li>
                        <li>
                            <a>답변 진행 중</a>
                        </li>
                        <li>
                            <a>답변 종료</a>
                        </li>
                    </ul>
                    <p class="totalNum">5 개</p>
                </div>
                <ul class="surveyListUl">
                    <li class="addSurveyList">
                        <a class="newSurvey add_survey" @click.prevent="goSurveyCreate">설문추가</a>
                    </li>
                    <!-- 작성중 write / 답변진행 answer / 종료 surveyClose 진행여부에 맞게 클래스 추가 -->
                    <li class="write">
                        <a href="#" class="surveyMenu">메뉴보기</a>
                        <p class="svyTitle">시연 행사 참석 여부</p>
                        <p class="previewImg">미리보기</p>
                        <p class="ex_time_faved lastUpdated">
                            <span class="progressIcon">진행여부</span>
                            23.01.05 목
                            <span class="faved_new" title="즐겨찾기">즐겨찾기</span>
                        </p>
                        <a title="페이지로 가기" class="goDets" @click.prevent="goSurveyDetail">페이지로가기</a>
                        <ul class="surMenuOp menuOpUl">
                            <li>
                                <a href="#">응답 확인</a>
                            </li>
                            <li>
                                <a href="#">이름 바꾸기</a>
                            </li>
                            <li>
                                <a href="#">설문 복사</a>
                            </li>
                            <li>
                                <a href="#">삭제하기</a>
                            </li>
                        </ul>
                    </li>
                    <!-- 작성중 write / 답변진행 answer / 종료 surveyClose 진행여부에 맞게 클래스 추가 -->
                    <li class="answer">
                        <a href="#" class="surveyMenu">메뉴보기</a>
                        <p class="svyTitle">무료 체험단 설문조사</p>
                        <p class="previewImg">미리보기</p>
                        <p class="ex_time_faved lastUpdated">
                            <span class="progressIcon">진행여부</span>
                            23.01.05 목
                            <span class="faved_new" title="즐겨찾기">즐겨찾기</span>
                        </p>
                        <a title="페이지로 가기" class="goDets" @click.prevent="goSurveyDetail">페이지로가기</a>
                        <ul class="surMenuOp menuOpUl">
                            <li>
                                <a href="#">응답 확인</a>
                            </li>
                            <li>
                                <a href="#">이름 바꾸기</a>
                            </li>
                            <li>
                                <a href="#">설문 복사</a>
                            </li>
                            <li>
                                <a href="#">삭제하기</a>
                            </li>
                        </ul>
                    </li>
                    <!-- 작성중 write / 답변진행 answer / 종료 surveyClose 진행여부에 맞게 클래스 추가 -->
                    <li class="surveyClose">
                        <a href="#" class="surveyMenu">메뉴보기</a>
                        <p class="svyTitle">학년별 학교 평가 설문조사</p>
                        <p class="previewImg">미리보기</p>
                        <p class="ex_time_faved lastUpdated">
                            <span class="progressIcon">진행여부</span>
                            23.01.05 목
                            <span class="faved_new" title="즐겨찾기">즐겨찾기</span>
                        </p>
                        <a title="페이지로 가기" class="goDets" @click.prevent="goSurveyDetail">페이지로가기</a>
                        <ul class="surMenuOp menuOpUl">
                            <li>
                                <a href="#">응답 확인</a>
                            </li>
                            <li>
                                <a href="#">이름 바꾸기</a>
                            </li>
                            <li>
                                <a href="#">설문 복사</a>
                            </li>
                            <li>
                                <a href="#">삭제하기</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupSurveyTemplateModalInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupSurvey extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupSurveyTemplateModalInfo ?: (params : GroupSurveyTemplateModalInfo) => void;

    mounted() : void {
        //toggleClass
        $("#exampleText").click(function(){
            $(".selectUl").toggleClass("on");
        });

        $(".faved_new").click(function(){
            $(this).toggleClass('on')
        })

        //siblings toggle
        $('.surveyMenu').click(function(event){
            event.stopPropagation(); 
            $(this).siblings('ul').toggle();   
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // // @ts-ignore
        // $('#group_member_scroll').mCustomScrollbar('destroy');
        
    	// this.setScroll();
    }

    viewTemplate() {
        this.doSetGroupSurveyTemplateModalInfo?.({ show_modal : true });
    }

    goSurveyCreate() {
        this.hodu_router_push(`/group/${new Date().getTime()}/survey/create`);
    }

    goSurveyDetail() {
        this.hodu_router_push(`/group/${new Date().getTime()}/survey/survey_id`);
    }

}
</script>

<style scoped>
    .settingBg { height: 65px; border-bottom: 1px solid #e7e9ea; display: flex; justify-content: space-between; }
    .settingBg h3 .clr { font-size: 0; width: 10px; height: 10px; display: inline-block; float: left; border-radius: 50%; margin: 28px 10px 0 0; }
    .schDiv { position: relative; padding: 0 15px; box-sizing: border-box; width: 450px; margin-right: 20px; }
    .schDiv input[type="text"] { font-weight: bold; border-radius: 5px; transition: 0.2s; width: 100%; height: 50px; border: 1px solid #e7e9ea; padding: 0 15px; box-sizing: border-box; line-height: 50px; background: #f1f3f5; margin-top: 7px; }
    .schDiv .schBtn { position: absolute; right: 20px; top: 13px; }
    #group_survey .selectBg { width: 100%; display: flex; justify-content: space-between; padding: 10px 20px; line-height: 43px; cursor: auto; }
    #group_survey .selectTitle { cursor: pointer; }
    .selectBg .selectUl { width: 140px; box-sizing: border-box; cursor: pointer; top: 45px; left: 20px; }
    .selectBg .selectUl.on { display: block; }
    #group_survey .selectBg .totalNum { font-size: 13px; font-weight: bold; margin-right: 15px; }
    .surveyListUl { overflow: hidden; -webkit-box-sizing: border-box; box-sizing: border-box; padding-right: 30px; padding-bottom: 40px; }
    .surveyListUl > li { text-align: center; position: relative; float: left; display: inline-block; width: 180px; height: 200px; margin: 30px 0 0 30px; box-sizing: border-box; border: 1px solid #e7e9ea; border-radius: 5px; }
    .surveyListUl > li:hover { background: #f7f9fb; }
    .surveyListUl .addSurveyList { border: none !important; margin-top: 22px; }
    .add_survey { display: inline-block; width: 100%; height: 100%; position: relative; text-align: center; border: 2px dashed #477fff; border-radius: 8px; box-sizing: border-box; font-size: 0; background: url('../assets/images/contents/add_477fff.png') no-repeat center center; background-size: 60px; transition: .2s; }
    .add_survey:hover { background-color: #e1ebff; }

    .surveyListUl .goDets { position: absolute; display: inline-block; height: 100%; left: 0; top: 0; width: 100%; text-align: center; font-size: 0; }
    .surveyListUl .surveyMenu { position: absolute; right: 5px; top: 5px; width: 30px; height: 30px; border-radius: 30px; left: auto; background: url('../assets/images/contents/ic_hover_more.png') no-repeat center center; background-size: 18px auto; transform: rotate(90deg); font-size: 0; z-index: 9999; transition: .2s; }
    .surveyListUl .surveyMenu:hover { box-shadow: 0px 0px 10px rgb(0 0 0 / 10%); background-color: #fff; }
    .surveyListUl .svyTitle { font-size: 14px; font-weight: bold; height: 40px; line-height: 40px; padding: 0 20px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .surveyListUl .previewImg { width: 80%; height: 112px; margin: 3px auto; box-sizing: border-box; font-size: 0; background: url('../assets/images/card/img_invite_4.png') no-repeat; background-size: cover; background-position: top; }
    .surveyListUl .ex_time_faved { height: 40px; line-height: 40px; text-align: left; padding: 0 20px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .ex_time_faved span { font-size: 0; width: 25px; height: 25px; display: inline-block; }
    .surveyListUl li .ex_time_faved .progressIcon { background-image: url('../assets/images/contents/survey_ic_situation.png'); background-repeat: no-repeat; background-size: auto 18px; vertical-align: middle; margin-top: -5px; }
    .surveyListUl li.write .ex_time_faved .progressIcon { background-position: center left; }
    .surveyListUl li.answer .ex_time_faved .progressIcon { background-position: -24px; }
    .surveyListUl li.surveyClose .ex_time_faved .progressIcon { background-position: -55px; background-size: auto 20px; }
    .ex_time_faved .faved_new { position: absolute; bottom: 9px; right: 16px; z-index: 9999; cursor: pointer; background: url('../assets/images/contents/ic_bookmark.svg') no-repeat center center; background-size: cover; }
    .ex_time_faved .faved_new.on { background-image: url('../assets/images/contents/ic_bookmark_fill.svg');}
    .surveyListUl li.write .menuOpUl li:first-child { display: none; }

</style>