<template>
    <div class="section_ce_fix">
        
        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">문구 설정</h3>
        </div>

        <!--스크롤 생겨야할 영역-->
        <div id="msgAddScroll" class="section_scroll">
            <div class="viewGroup">					
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <!-- 하단 h3 수정시 메세지 문구 수정으로 밸류 변경 -->
                        <h3 class="selectTitle"><span class="txt">문구 {{is_created ? '추가' : '변경'}}</span></h3>
                        <p class="btn_group btnBox" style="">
                            <input type="button" id="msgOpInSaveBtn" name="" value="저장" class="input_btn" @click="message_save()"/>
                            <input type="button" id="msgOpInCancelBtn" name="" value="취소" class="input_btn" />
                            <input type="button" id="btnDel"  name="" value="삭제" class="input_btn" />
                        </p>
                    </div>

                    <!-- 체크식일시 content에 addClass(check) -->
                    <div class="content" id="">
                        
                        <div class="first  messageBox_right">
                            <div class="sec1">
                                <h4>문구 타이틀 작성</h4>
                                <!-- 입력없이 저장 누를시 addClass(wrong), 클릭시 removeClass -->
                                <input type="text" id="msgOpInTitle" class="input_txt" placeholder="제목을 입력하세요" maxlength="100" :value="message_data.title" @input="message_data.title = $event.target.value">
                            </div>
                        </div>

                        <div class="second  messageBox_right">
                            <h4>메세지 작성</h4>
                            <div class="textareaBox"><!-- height 115이상 스크롤 right:0;top:0-->
                                <input type="button" id="msgOpInNewBtn" class="input_btn fl" placeholder="" value="재 작성" title="">
                                <!-- 입력없이 저장 누를시 addClass(wrong), 클릭시 removeClass -->
                                <textarea id="msgOpInContents" class="input_textarea" placeholder="원하는 문구를 설정하세요" :value="message_data.message_info.body" @input="message_data.message_info.body = $event.target.value" @keyup="changeKey();"></textarea>
                                <div class="options">
                                    <h4>#{옵션열기}</h4>
                                    <div class="grp">
                                        <h5>#{옵션닫기}</h5>
                                        <ul id="optionContent">
                                            <li @click="addTemp('#{이름}')">#{이름}</li>
                                            <li @click="addTemp('#{이메일}')">#{이메일}</li>
                                            <li @click="addTemp('#{발신일자}')">#{발신일자}</li>
                                            <li @click="addTemp('#{그룹팀명}')">#{그룹팀명}</li>
                                            <!-- <li>#{대표그룹명}</li> -->
                                            <!-- <li>#{부그룹명}</li> -->
                                        </ul>
                                    </div>
                                    <p class="countLetters"><span class="counted" id="sendMmsMasageCount">{{text_count}}</span> / {{text_max}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';

const EtcInfo   = namespace('EtcInfo');
const GroupInfo = namespace('GroupInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({

}) 
export default class MessageTemplateAdd extends Mixins(VueHoduCommon) {
    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;

    /**
     * EtcInfo.Action
     */
    @EtcInfo.Action doMessageUid ?: any;
    
    /**
     * EtcInfo.State
     */
    @EtcInfo.State message_uid !: string;

    is_created : boolean = true;
    text_count : number  = 0;
    text_max   : number  = 2000;

    message_data : any = {
        uid : "",
        scope: "GROUP",
        scope_id: 0,
        title: "",
        message_type: "",
        message_info: {
            title: "",
            body: ""
        },
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: this.user_id
    };

    mounted() : void {
        // 업무 설정 > 업무 형태 선택
		$(".content .sec2 input").click(function(){
			$(this).siblings(".dropdown").toggleClass("on");
        });
        
		// 업무 설정 > 단계 선택
		$(".content .sec3 input").click(function(){
			$(this).siblings(".dropdown").toggleClass("on");
        });
        
		// 업무 색상 선택
		$(".content .second .clr input").click(function(){
			$(this).parent().siblings(".pickColour").toggleClass("on");
        });

        if ( this.message_uid ) {
            this.is_created = false;
            this.getMessageTemplate();
        }
    }

    /**
     * 키 입력
     */
    changeKey() : void {
        let str     = this.message_data.message_info.body;
        let strLen  = str.length;
        let rbyte   = 0;
        let rlen    = 0;
        let oneChar = "";
        let str2    = "";

        for ( let i = 0; i < strLen; i++ ) {
            oneChar = str.charAt(i);

            if ( escape(oneChar).length > 4 ) {
                rbyte += 2;
            }
            else {
                rbyte++;
            }

            if ( rbyte <= this.text_max ) {
                rlen = i+1;
            }
        }

        if ( rbyte > this.text_max ) {
            this.hodu_show_dialog("alert", "한글 " + (this.text_max / 2) + "자 / 영문 " + this.text_max + "자를 초과 입력할 수 없습니다.", ['확인']);
            
            str2 = str.substr(0, rlen);

            this.message_data.message_info.body = str2;
        }
        else {
            this.text_count = rbyte;
        }
    }

    /**
     * 메시지 템플릿 조회
     */
    getMessageTemplate() : void {
        this.hodu_api_call(`api/v1/message_template/GROUP/${this.group_id}/${this.message_uid}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.message_data = response.data.data.data;
                this.changeKey();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }    

    /**
     * 저장
     */
    async message_save() : Promise<void> {
        if ( this.message_data.title.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "제목을 입력해주세요", ['확인']);
            return;
        }

        if ( this.message_data.title.length > 15 ) {
            this.hodu_show_dialog("alert", "제목은 15자 미만입니다", ['확인']);
            return;
        }

        if ( this.message_data.message_info.body.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "메세지를 입력해주세요", ['확인']);
            return;
        }

        if ( this.is_created ) {
            this.message_data.scope_id = this.group_id;

            delete this.message_data.uid;
            delete this.message_data.audit_created;
            delete this.message_data.audit_modified;
            delete this.message_data.audit_deleted;

            await this.hodu_api_call(`api/v1/message_template`, API_METHOD.POST, {message: this.message_data})
                .then(async(response) => {
                    this.movePrevPage();
                    return;
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });        
        }
        else {
            await this.hodu_api_call(`api/v1/message_template`, API_METHOD.PUT, {message: this.message_data})
                .then(async(response) => {
                    this.movePrevPage();
                    return;
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });               
        }
    }

    /**
     * 
     */
    addTemp(value) : void {
        this.message_data.message_info.body += value;
        $('#msgOpInContents').focus();
    }
}
</script>

<style scoped>
    div#msgAddScroll .content {max-width: 600px;float: left;width: 100%;margin-top: 0; padding-left: 30px !important;}
	p.btn_group { margin-right:30px; }
	#section_ce .grp.settingBg {height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
	#section_ce  .grp.settingBg h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px}
	#msgAddScroll .first { padding-top:40px; }
	#msgAddScroll h4 {font-size: 16px;margin-bottom:20px;}
	#msgAddScroll .first input {text-align:left;line-height: 55px;height: 55px;font-size:14px;font-weight:bold;padding:0 15px;border:1px solid #e7e9ea;}
	#msgAddScroll .first > div.sec1 input.wrong {border: 1px solid red;background: #fff7f7;}
	#msgAddScroll .first > div.sec1 input.wrong::placeholder {color: red;}
	#msgAddScroll .first > div.sec1 input {width: 100%;box-sizing: border-box;transition:0.2s;font-size: 16px;}
	#msgAddScroll .first > div.sec1 input:hover { border:1px solid #d2d7e2; background:#f1f3f5;transition:0.2s; }
	#msgAddScroll .first > div.sec1 input:focus { border:1px solid #477fff; background:#fff;transition:0.2s; }
	#msgAddScroll .second {margin-top: 30px;padding-top: 30px;border-top: 1px solid #f1f3f5;position: relative;}
	#msgAddScroll .second p {margin-bottom: 20px;opacity: 0.6;padding-top: 5px;}
	#msgAddScroll p.countLetters {font-weight: bold;opacity: 1;position: absolute;right: 20px;top: 21px;opacity: 1;width: auto;font-size: 12px;z-index: 10000000;}
	#msgAddScroll p.countLetters span#sendMmsMasageCount {color: #477fff;}
	#msgAddScroll textarea {width: 600px;height: 250px;max-width: 100%;min-width: 100%;max-height: 400px;transition: 0.1s;font-size: 14px;font-weight: bold;padding: 20px 60px 20px 20px;box-sizing: border-box;min-height: 200px;border: 1px solid #e7e9ea;}
	#msgAddScroll textarea:hover {background: #f1f3f5;}
	#msgAddScroll textarea:focus {background: #Fff;border-color: #477fff;}
	#msgAddScroll textarea.wrong {border: 1px solid red;background: #fff7f7;}
	#msgAddScroll textarea.wrong::placeholder {color: red;} 
	.messageBox_right .textareaBox{font-size:0;width: 100%;position: relative;height: 100%;/* overflow: hidden; */float: left;}
	.messageBox_right .textareaBox .input_textarea{box-sizing:border-box;max-width:100%;width: 100%;line-height: 22px;min-height: 365px;max-height: 365px;border:1px solid #e9e9e9;background-color: #f1f3f5;;padding: 20px 20px;font-size: 14px;}
	.messageBox_right .options #optionContent {padding-top: 5px;padding-bottom: 8px;padding-right: 0;}
	#msgAddScroll .messageBox_right .options {height: auto;overflow: hidden;}
	#msgAddScroll .options .grp {width: 80%;}
	#msgAddScroll .options {position: relative;}
	.messageBox_right .options {/* position:absolute; */bottom: 0;left: 0;width: 100%;border-left: 1px solid #e9e9e9;border-top: 0 none;box-sizing: border-box;border-right: 1px solid #e9e9e9;background: rgba(255,255,255,0.8) !important;}
	.messageBox_right .textareaBox:hover #msgOpInNewBtn {display: block;}
	input#msgOpInNewBtn {margin-left: 0;top: 13px;right: 0;position: absolute;bottom: 10px;display: none;right: 15px;width: 35px;font-size: 0 !important;height: 35px;background: #f1f3f5 url(../assets/images/contents/ic_close.gif) no-repeat center center !important;border-radius: 50%;}
	.textareaBox .options {width: 100%;border: 1px solid #e7e9ea;box-sizing: border-box;background: #f1f3f5; border-top:0 none;}
	.textareaBox {max-width: 600px;}
    .messageBox_right .btnBox #msgOpInCancelBtn {background: #f55;color: #fff;border: 0;}
    .messageBox_right .btnBox #msgOpInSaveBtn {background: #477fff;color: #fff;border: 0;}
    .messageBox_right .btnBox #msgOpInCancelBtn:hover {background: #ff3737;}
    .messageBox_right .btnBox #msgOpInSaveBtn:hover {background: #477fff;}
    input#msgOpInNewBtn {margin-left: 0;top: 13px;right: 0;position: absolute;bottom: 10px;display: none;right: 15px;width: 35px;font-size: 0 !important;height: 35px;background: #f1f3f5 url(../assets/images/contents/ic_close.gif) no-repeat center center !important;border-radius: 50%;}
    .messageBox_right .textareaBox:hover #msgOpInNewBtn {display: block;}
    .messageBox_right .options {/* position:absolute; */bottom: 0;left: 0;width: 100%;border-left: 1px solid #e9e9e9;border-top: 0 none;box-sizing: border-box;border-right: 1px solid #e9e9e9;background: rgba(255,255,255,0.8) !important;}
    #msgAddScroll .messageBox_right .options {height: auto;overflow: hidden;}
    #msgAddScroll .options .grp {width: 80%;}
    #msgAddScroll .options {position: relative;}
    .textareaBox .options ul {}
    .textareaBox .options ul li {padding: 0 10px !important;display: inline-block;border-radius: 15px;width: auto !important;margin: 10px 10px 0 5px;height:30px  !important;line-height: 30px !important;font-size: 12px;border: 1px solid #e7e9ea;font-weight: bold;transition:0.1s;color: #4e4e4e;}
    .textareaBox .options ul li:hover {color: #477fff !important;}
    .textareaBox .options ul li:hover { cursor:pointer; color:#484848;transition:0.1s;box-shadow:none; }
    .messageBox_right .options h5, .messageBox_right .options h4 {position: static;display: inline-block;padding: 0 20px;display: none !important;background: #fff;font-size: 13px;line-height: 55px;width: 80px;margin-bottom: 0;height: 55PX;}
    .messageBox_right .options h5:hover, .messageBox_right .options h4:hover { cursor: pointer; box-shadow:none;transition:0.1s; background:#f3f3f3}
    .messageBox_right .options h4 {display:none;}
    .msgOptionPage.lClosed  .messageBoxWrap02 { left: 130px !important; }
    .msgOptionPage.lClosed .defaultMsgBoxWrap .forFixed {padding-left: 61px !important;}

</style>