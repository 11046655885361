<template>
    <div id="supply_create" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">{{ is_create ? "비품·수불 등록" : "비품·수불 수정" }}</h3>
            <p class="btn_group">
                <input type="submit" class="input_btn" value="저장" @click.prevent="save">
            </p>
        </div>

        <div id="supply_detail">
            <div class="eqinfor eqinfor03 supplyIn01">
                <div class="eqinfor03_box supplyIn01_box">
                    <div class="eq_explanation supply_explanation">
                        <ul>
                            <li>
                                <p class="supplyTitle">품명</p>
                                <input type="text" id="supplyTitle_text" name="supplyTitle_text" :value="name" @input="name = $event.target.value" autocomplete="off">
                            </li>
                            <li>
                                <p class="supplyClass">분류</p>
                                <input type="text" id="supplyClass_text" name="supplyClass_text" :value="category" @input="category = $event.target.value" autocomplete="off">
                            </li>
                            <li>
                                <p class="supplyTime">등록일</p>
                                <label for="supplyTime_text" class="eqtime_datepicker">
                                    <input type="button" id="supplyTime_text" name="supplyTime_text" :value="`${hodu_date_to_format_string(date, 'YYYY.MM.DD')} (${getDayOfWeekByDate(date, '')})`">
                                </label>
                            </li>
                            <li>
                                <p class="supplyManager">관리자</p>
                                <input type="text" id="supplyManager_text" name="supplyManager_text" :value="manager" @input="manager = $event.target.value" autocomplete="off">
                            </li>
                            <li>
                                <p class="supplyMamaTeam">관리부서</p>
                                <input type="text" id="supplyMamaTeam_text" name="supplyMamaTeam_text" :value="manager_department" @input="manager_department = $event.target.value" autocomplete="off">
                            </li>
                        </ul>
                    </div>
                    <div class="eq_picture supply_picture">
                        <h3 class="ir_so blind">제품사진</h3>
                        <label id="image_label" for="supplyImg" class="eq_img supply_img" title="이미지 선택" :style="{ 
                            'background-image': images.length < 1 ? `url(${default_image})` : `url(${images[0].url.indexOf('home/') == -1 ? images[0].url : `/app_images/${images[0].url}` })`,
                            'background-size' : images.length < 1 ? 'auto' : 'cover'
                        }"></label>
                        <input type="file" id="supplyImg" accept="image/*" @change="addImageNormal($event)"/>
                    </div>
                </div>
            </div>
            <!-- //첫번째 사진 및 설명 -->
            <div class="eqinfor eqinfor02 supplyIn02">
                <ul class="infor_box">
                    <li class="text_one">
                        <p class="eqRemark supplyRemark">비고(용도)</p>
                        <input type="text" id="supplyRemark_text" name="supplyRemark_text" :value="desc" @input="desc = $event.target.value" autocomplete="off">
                    </li>
                </ul>
            </div>
            <!-- //두번째 비고 -->
            <div class="add_btn" :class="{ on : init_first_inbound == true }" v-if="is_create == true">
                <p class="add_icon">추가 이미지</p>
                <input type="button" class="supplyAdd_btn" value="입고" @click.prevent="initFirstInbound">
            </div>
            <!-- //추가 버튼 -->
            <div class="eqinfor buyinputDiv" :class="{ on : init_first_inbound == true }">
                <h2>입고</h2>
                <div class="buyinputDiv_box">
                    <ul>
                        <li>
                            <p class="title">입고량</p>
                            <input type="number" id="buyCount_text" name="buyCount_text" v-model.number="count" autocomplete="off">
                            <div class="btn_count">
                                <!-- <span class="up">▲</span>
                                <span class="down">▼</span> -->
                            </div>
                        </li>
                        <li class="sort_right">
                            <p class="title">입고단가</p>
                            <input type="number" id="buyPrice_text" name="buyPrice_text" v-model.number="unit_price" autocomplete="off">
                        </li>
                        <li>
                            <p class="title">구입총액</p>
                            <input type="number" id="finalPrice_text" name="finalPrice_text" v-model.number="total_price" autocomplete="off">
                        </li>
                        <li class="sort_right">
                            <p class="title">구입처</p>
                            <input type="text" id="buystore_text" name="buystore_text" :value="vendor" @input="vendor = $event.target.value" autocomplete="off">
                        </li>
                        <li>
                            <p class="title">구입일</p>
                            <label for="buyTime_text" class="eqtime_datepicker">
                                <input type="button" id="buyTime_text" name="buyTime_text" :value="`${hodu_date_to_format_string(purchase_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(purchase_date, '요일')}`">
                            </label>
                        </li>
                        <li class="sort_right">
                            <p class="title">구입처 전화</p>
                            <input type="text" id="buystoreTel_text" name="buystoreTel_text" autocomplete="off" :value="vendor_tel" @input="vendor_tel = $event.target.value">
                        </li>
                    </ul>
                </div>
                <div class="eqinfor eqinfor02 buyIn02">
                    <ul class="infor_box">
                        <li class="text_one">
                            <p class="eqRemark supplyUse">비고(사용처 등)</p>
                            <input type="text" id="supplyUse_text" name="supplyUse_text" autocomplete="off" :value="desc2" @input="desc2 = $event.target.value">
                        </li>
                    </ul>
                </div>
            </div>
            <!-- //입고 입력 란 -->
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';
import { t_event_file } from '@/model/event';

@Component({
    components: {
        ResizeObserver
    },
})
export default class SupplyCreate extends Mixins(VueHoduCommon) {

    uid : string = "";
    is_create : boolean = true;

    name : string = "";
	category : string = "";
	manager : string = "";
    date : Date = new Date();
	manager_department : string = "";
	desc : string = "";

    init_first_inbound : boolean = false;

    count : string = "0";
    unit_price : string = "";
    total_price : string = "";
    vendor : string = "";
    vendor_tel : string = "";
    purchase_date : Date = new Date();
    desc2 : string = "";

    images : t_event_file[] = [];
    image_max_cnt : number = 1;
    image_files : any[] = [];

    default_image = require("@/assets/images/contents/hoduhome_car_camera.svg");

    beforeMount() : void {
        this.uid = this.$route.params.uid;
        this.is_create = this.uid == null || this.uid.length < 1;
    }

    mounted() : void {
        //입고입력 on/off
        // $('.add_btn').off("click").on("click", function(){
        // 	if($(".add_btn, .buyinputDiv").hasClass("on")){
        //         $(".add_btn, .buyinputDiv").removeClass("on");
        //     } else {
        //         $(".add_btn, .buyinputDiv").addClass("on");
        //     }
        // });
        
        this.datepickerInit();
        this.setScroll();

        if( this.is_create == false ) {
            this.getHomeSupply();
        }   
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0);

        // @ts-ignore
        $('#supply_detail').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }
    
    /**
     * 비품 조회
     */
    async getHomeSupply() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${this.uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.supply ) {
                throw new Error("비품 조회 중 오류 발생");
            }

            const supply = JSON.parse(JSON.stringify(response.data.data.supply));

            this.name = supply.supply_data.name;
            this.category = supply.supply_data.category;
            this.manager = supply.supply_data.manager;
            this.manager_department = supply.supply_data.manager_department;
            this.desc = supply.supply_data.desc;
            this.date = this.hodu_yyyymmdd_to_date(supply.supply_data.date);

            this.images.splice(0, this.images.length);
            if( supply.supply_data.image != null ) this.images.push(supply.supply_data.image);

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")}`);

                    switch ( inst.id ) {
                        case "supplyTime_text":
                            this.date = new Date(selected_date);
                            break;

                        case "buyTime_text":
                            this.purchase_date = new Date(selected_date);
                            break;
                    }

                },
            }

            // @ts-ignore
            $('#supplyTime_text').datepicker(datepicker_option);

            // @ts-ignore
            $('#buyTime_text').datepicker(datepicker_option);
        });
    }

    /**
     * 최초 입고 이력 추가
     */
    initFirstInbound() : void {
        this.init_first_inbound = true;
        this.datepickerInit();
    }

    /**
     * 일정 이미지 추가
     */
    addImageNormal(event) : void {
        const files : File[] = event.target.files;
        if( files.length == 0 ) { return; }
        this.addImage(files);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(files : File[]) : Promise<void> {
        const vue = this;
        
        let file_count : number = files.length;
        let end_count  : number = 0;

        // 이미지가 이미 있는 경우
        if( this.images.length >= this.image_max_cnt ) {
            this.images.splice(0, this.images.length);
            this.image_files.splice(0, this.image_files.length);
        }

        await this.hodu_show_indicator();

        for( let i = 0; i < file_count; i++ ) {
            await this.fileReaderPromise(files[i])
                .then(async(pe_fr : any) => {

                    if( pe_fr.target == null || pe_fr.target.result == null ){
                        return;
                    }
                    
                    let base64url : string = "";

                    if( pe_fr.target.result instanceof ArrayBuffer ){
                        const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                        const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                        base64url = decodeURIComponent(url);
                    } else {
                        base64url = pe_fr.target.result;
                    }

                    // 이미지 리사이즈
                    const blob : Blob = await vue.hodu_image_resize(base64url);
                    
                    // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                    let resize_file : File = files[i];
                    try{
                        resize_file = await this.hodu_blob_to_file(blob, files[i].name);
                    }catch(e){
                        try {
                            (blob as any).lastModifiedDate = new Date();
                            (blob as any).name = files[i].name;
                            resize_file = (blob as any);
                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                        }
                    }

                    vue.images.push({
                        name: resize_file.name,         // 원본 파일명
                        mimeType: resize_file.type,     // MIME TYPE
                        url: URL.createObjectURL(blob), // 파일 경로
                        size: resize_file.size,         // 파일 크기
                        date: new Date()                // 저장 날짜
                    })

                    // 업로드용 파일 객체 담기
                    vue.image_files.push({
                        index : ( vue.images.length - 1 ), 
                        file : resize_file
                    });

                    // 마지막 파일 로드 후 input 값 비우기 [안 비우면 똑같은 사진 multiple 아니면 안들어감]
                    if( file_count == ++end_count ) { $('#supplyImg').val(""); }

                })
                .catch((e) => {
                    console.log('filereader promise error',e);
                });
        }

        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File | Blob) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지 삭제
     */
    deleteImage(image : t_event_file) : void {

        const image_index : number = this.images.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        this.images.splice(image_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const image_files_count : number = this.image_files.length;
        for( let i = (image_files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.image_files[i].index > image_index ) {
                this.image_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.image_files[i].index == image_index ) {
                this.image_files.splice(i, 1);
                break;
            }
        }

    }

    /**
     * 저장 유효성 검사
     */
    validCheck() : boolean {
        if( this.name == null || this.name.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "품명을 입력해주세요", ['확인'], [() => { $('#supplyTitle_text').focus(); }]);
            return false;
        }

        if( this.category == null || this.category.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "분류를 입력해주세요", ['확인'], [() => { $('#supplyClass_text').focus(); }]);
            return false;
        }

        if( this.init_first_inbound == true ) {

            if( this.count == null || this.count.trim().length < 1 ) {
                this.hodu_show_dialog("alert", "입고량을 입력해주세요", ['확인'], [() => { $('#buyCount_text').focus(); }]);
                return false;
            }
            
            if( isNaN(Number(this.count.replaceAll(",", "").trim())) == true ) {
                this.hodu_show_dialog("alert", "입고량에는 숫자만 입력해주세요", ['확인'], [() => { $('#buyCount_text').focus(); }]);
                return false;
            }

            if( Number(this.count.replaceAll(",", "").trim()) < 0 ) {
                this.hodu_show_dialog("alert", "입고량에는 양수만 입력해주세요", ['확인'], [() => { $('#buyCount_text').focus(); }]);
                return false;
            }

            if( this.count.replaceAll(",", "").trim().includes('.') == true ) {
                this.hodu_show_dialog("alert", "입고량에는 정수만 입력해주세요", ['확인'], [() => { $('#buyCount_text').focus(); }]);
                return false;
            }

            if( this.unit_price == null || this.unit_price.trim().length < 1 ) {
                this.hodu_show_dialog("alert", "입고 단가를 입력해주세요", ['확인'], [() => { $('#buyPrice_text').focus(); }]);
                return false;
            }

            if( isNaN(Number(this.unit_price.replaceAll(",", "").trim())) == true ) {
                this.hodu_show_dialog("alert", "입고 단가에는 숫자만 입력해주세요", ['확인'], [() => { $('#buyPrice_text').focus(); }]);
                return false;
            }

            if( Number(this.unit_price.replaceAll(",", "").trim()) < 0 ) {
                this.hodu_show_dialog("alert", "입고 단가에는 양수만 입력해주세요", ['확인'], [() => { $('#buyPrice_text').focus(); }]);
                return false;
            }

            if( this.unit_price.replaceAll(",", "").trim().includes('.') == true ) {
                this.hodu_show_dialog("alert", "입고 단가에는 정수만 입력해주세요", ['확인'], [() => { $('#buyPrice_text').focus(); }]);
                return false;
            }

            if( this.total_price == null || this.total_price.trim().length < 1 ) {
                this.hodu_show_dialog("alert", "구입 총액을 입력해주세요", ['확인'], [() => { $('#finalPrice_text').focus(); }]);
                return false;
            }

            if( isNaN(Number(this.total_price.replaceAll(",", "").trim())) == true ) {
                this.hodu_show_dialog("alert", "구입 총액에는 숫자만 입력해주세요", ['확인'], [() => { $('#finalPrice_text').focus(); }]);
                return false;
            }

            if( Number(this.total_price.replaceAll(",", "").trim()) < 0 ) {
                this.hodu_show_dialog("alert", "구입 총액에는 양수만 입력해주세요", ['확인'], [() => { $('#finalPrice_text').focus(); }]);
                return false;
            }

            if( this.total_price.replaceAll(",", "").trim().includes('.') == true ) {
                this.hodu_show_dialog("alert", "구입 총액에는 정수만 입력해주세요", ['확인'], [() => { $('#finalPrice_text').focus(); }]);
                return false;
            }
            
        }

        return true;
    }
    
    /**
     * 저장
     */
    async save() : Promise<void> {
        
        this.count = String(this.count);
        this.unit_price = String(this.unit_price);
        this.total_price = String(this.total_price);

        if( this.validCheck() == false ) return;

        try {

            let image : any = null;

            const image_files_count : number = this.image_files.length;
            if( image_files_count > 0 ) {

                const form_data : FormData = new FormData();
                
                // FormData 내용 생성
                for( let i = 0; i < image_files_count; i++ ) {
                    form_data.append('file', this.image_files[i].file);
                }
        
                try {
                    const response = await this.hodu_temp_upload(form_data);
                    console.log(response);
        
                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("이미지 임시 업로드 실패");
                    }
        
                    const temp_files : t_event_file[] = response.data.data.temp_files;
        
                    // 임시파일 데이터로 객체 대체하기
                    const image_files_count : number = this.image_files.length;
                    for( let i = 0; i < image_files_count; i++ ) {
                        this.images.splice(this.image_files[i].index, 1, temp_files[i]);    
                    } 
        
                } catch(e) {
                    throw e;
                }
            }

            if( this.images.length > 0 ) image = this.images[0];

            let body : any = {
                name : this.name.trim(),
                category : this.category.trim(),
                manager : this.manager.trim(),
                manager_department : this.manager_department.trim(),
                desc : this.desc.trim(),
                date : this.hodu_date_to_format_string(this.date, 'YYYYMMDD'),
                image : image,
                management : null
            };

            if( this.init_first_inbound ) {
                body.management = {
                    type : "inbound",
                    count :Number(this.count.replaceAll(",", "").trim()),
                    unit_price : Number(this.unit_price.replaceAll(",", "").trim()),
                    total_price : Number(this.total_price.replaceAll(",", "").trim()),
                    vendor : this.vendor.trim(),
                    vendor_tel : this.vendor_tel.trim(),
                    purchase_date : this.hodu_date_to_format_string(this.purchase_date, 'YYYYMMDD'),
                    desc : this.desc2.trim(),
                    date : this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')
                }
            }

            if( this.is_create ) {    
    
                const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply`, API_METHOD.POST, body);
    
                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("비품 등록 중 오류 발생");
                } 
    
                this.movePrevPage();
    
            }
            else {
                
                const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${this.uid}`, API_METHOD.PUT, body);
    
                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("비품 수정 중 오류 발생");
                } 
    
                this.movePrevPage();

            }

        } catch(e) {
            this.hodu_show_dialog("cancel", "비품 저장 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    @Watch("count")
    @Watch("unit_price")
    compute() : void {

        let count = Number(String(this.count).trim());
        let unit_price = Number(String(this.unit_price).trim());

        if( isNaN(count) || isNaN(unit_price) ) {
            this.total_price = "";
        }

        this.total_price = String(count * unit_price);

    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#supply_detail').mCustomScrollbar("destroy");

        this.setScroll();
    }

}
</script>

<style>
    #supply_create #supply_detail .mCSB_container { padding-bottom: 10px; }
</style>

<style scoped>
    #supply_create .title_box .btn_group input { cursor: pointer; }
    .supply_picture label#image_label { cursor: pointer; }
    .supply_picture input#supplyImg { display: none; }

    #supply_create .add_btn { text-align: center; overflow: hidden; width: 470px; margin: 0 auto; box-shadow: 0px 0px 10px rgb(35 40 72 / 20%); border-radius: 10px; position: relative; transition: .2s; }
    #supply_create .add_btn.on { display: none; }
    #supply_create .add_icon { font-size: 0; width: 14px; height: 14px; background: url('../../assets/images/contents/ic_plus_new.png')no-repeat center; position: absolute; top: 23px; left: 50%; margin-left: -20px; opacity: .7; }
    #supply_create .supplyAdd_btn { cursor: pointer; margin: 0; padding: 0; width: 100%; display: block; padding: 20px 0; background: none; font-weight: bold; color: #9596a1; text-indent: 26px; transition: .2s; }
    #supply_create .supplyAdd_btn:hover, #supply_create .add_btn:hover { transform: scale(1.1); }

    #supply_create .buyinputDiv { display: none; }
    #supply_create .buyinputDiv.on { display: block; }
</style>