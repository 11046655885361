<template>
    <div id="doctor_time_setting_modal" class="modal" :class="{
        with_date : doctor_time_setting_modal_info.is_create_work_time == true
    }">
        <div class="titleBox">
            <h1>{{ doctor_time_setting_modal_info.is_create_work_time == false ? '근무시간 설정' : '근무시간 추가' }}</h1>
        </div>
        <div class="content">
            <div class="detail">
                <div class="descript">
                    <ul>
                        <li>
                            <span class="morn clr">노랑</span>
                            <span class="txt">오전</span>
                        </li>
                        <li>
                            <span class="night clr">보라</span>
                            <span class="txt">오후</span>
                        </li>
                        <li>
                            <span class="allday clr">파랑</span>
                            <span class="txt">종일</span>
                        </li>
                        <li>
                            <span class="off clr">빨강</span>
                            <span class="txt">휴무</span>
                        </li>
                    </ul>
                </div>
                <div class="contractDiv">
                    <p class="title">1. 근무기간 선택</p>
                    <ul>
                        <li>
                            <label for="">시작</label>
                            <!-- datepicker -->
                            <!-- <input type="text" value="2020.04.16 목요일" /> -->
                            <input id="doctor_create_start_date" type="button" :value="getYMDstr(picker_start_date)" />
                                
                        </li>
                        <li class="section">~</li>
                        <li>
                            <label for="">종료</label>
                            <!-- datepicker -->
                            <!-- <input type="text" value="2021.04.16 목요일" /> -->
                            <input id="doctor_create_end_date" type="button" :value="getYMDstr(picker_end_date)" />
                        </li>
                    </ul>

                </div>

                <p class="title day">{{ doctor_time_setting_modal_info.is_create_work_time == true ? "2. 요일별 시간 설정" : "요일별 시간 설정" }}<span class="descriptP">병원설정 오픈&amp;클로징시간과 동일</span></p>
                
                <!-- 병원설정 > 요일별 시간 등록시간과 동일하게 디폴트 세팅 -->
                <ul class="dayUl" v-if="doctor_time_setting_modal_info != null && doctor_time_setting_modal_info.timetable != null">
                    <li :key="timeIndex" v-for="(timeInfo, timeIndex) in doctor_time_setting_modal_info.timetable.list">
                        <p class="day">{{`${getDayNameChild(timeInfo.day_of_week)}`}}</p>
                        <div class="checkDiv">
                            <!-- <a :class="`${isOn(timeInfo.day_of_week, 'AM')}`" @click="onClickTime(timeInfo.day_of_week)"><span>{{`${timeInfo.start_am_time} ~ ${timeInfo.end_am_time}`}}</span></a>
                            <a :class="`${isOn(timeInfo.day_of_week, 'PM')}`"><span>13:30 ~ 18:00</span></a>
                            <a class="off" :class="`${isOn(timeInfo.day_of_week, 'OFF')}`"><span>휴무</span></a> -->

                            <a :class="{
                                on : timeInfo.work_code == 'FULL_TIME' || timeInfo.work_code == 'AM',
                                morn : timeInfo.work_code == 'AM'
                            }" @click="onClickTime(timeInfo.day_of_week, 'AM')">
                                <span>{{ timeInfo.work_code == 'FULL_TIME' || timeInfo.work_code == 'AM' ? timeInfo.am_text.replace(/오전 /ig, '') : '오전' }}</span>
                            </a>

                            <a :class="{
                                on : timeInfo.work_code == 'FULL_TIME' || timeInfo.work_code == 'PM',
                                night : timeInfo.work_code == 'PM'
                            }" @click="onClickTime(timeInfo.day_of_week, 'PM')">
                                <span>{{ timeInfo.work_code == 'FULL_TIME' || timeInfo.work_code == 'PM' ? timeInfo.pm_text.replace(/오후 /ig, '') : '오후' }}</span>
                            </a>

                            <a class="off" :class="{
                                on : timeInfo.work_code == 'OFF'
                            }" @click="onClickTime(timeInfo.day_of_week, 'OFF')">
                                <span>휴무</span>
                            </a>

                        </div>
                    </li>
                    <!-- <li>
                        <p class="day">월</p>
                        <div class="checkDiv">
                            <a class="on"><span>9:00 ~ 12:30</span></a>
                            <a class="on"><span>13:30 ~ 18:00</span></a>
                            <a class="off"><span>휴무</span></a>
                        </div>
                    </li>
                    <li>
                        <p class="day">화</p>
                        <div class="checkDiv">
                            <a class="on"><span>9:00 ~ 12:30</span></a>
                            <a class="on"><span>13:30 ~ 18:00</span></a>
                            <a class="off"><span>휴무</span></a>
                        </div>
                    </li>
                    <li>
                        <p class="day">수</p>
                        <div class="checkDiv">
                            <a class="on"><span>9:00 ~ 12:30</span></a>
                            <a class="on"><span>13:30 ~ 18:00</span></a>
                            <a class="off"><span>휴무</span></a>
                        </div>
                    </li>
                    <li>
                        <p class="day">목</p>
                        <div class="checkDiv">
                            <a class="morn"><span>오전</span></a>
                            <a class="night"><span>오후</span></a>
                            <a class="on off"><span>휴무</span></a>
                        </div>
                    </li>
                    <li>
                        <p class="day">금</p>
                        <div class="checkDiv">
                            <a class="morn"><span>오전</span></a>
                            <a class="on night"><span>13:30 ~ 18:00</span></a>
                            <a class="off"><span>휴무</span></a>
                        </div>
                    </li>
                    <li>
                        <p class="day">토</p>
                        <div class="checkDiv">
                            <a class="on morn"><span>9:00 ~ 12:30</span></a>
                            <a class="night"><span>오후</span></a>
                            <a class="off"><span>휴무</span></a>
                        </div>
                    </li>
                    <li class="offDay">
                        <p class="day">일</p>
                        <div class="checkDiv">
                            <a class="morn"><span>오전</span></a>
                            <a class="night"><span>오후</span></a>
                            <a class="off"><span>휴무</span></a>
                        </div>
                    </li> -->
                </ul>
            </div>
            <div class="btns">
                <input type="button" class="cancel" value="취소" @click="close()"/>
                <!-- <input type="button" class="del" value="삭제" @click="deleteTimetable()"> -->
                <input type="button" class="save" value="저장" @click="saveTimetable()"/>
            </div>
        </div>
        
    </div>  
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import moment from 'moment';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class DoctorTimeSettingModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDoctorTimeSettingModalInfo ?: (params : hodu_doc_modal_info.DoctorTimeSettingModalInfo) => void;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State doctor_time_setting_modal_info !: hodu_doc_modal_info.DoctorTimeSettingModalInfo;

    picker_start_date : Date = moment(new Date()).set("hours", 15).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate();
    picker_end_date   : Date = new Date("2050-12-31 14:59:59Z");

    mounted() : void {
        const vue = this;

        // $(".checkDiv a").click(function(){
        //     var getIndex = $(this).index();
        //     $(this).toggleClass("on");
        //     if (getIndex == 2) {
        //             $(this).siblings().removeClass("on");
        //     } else {
                
                
        //     }
        // });

        // jquery-ui datepicker 옵션
        const datepicker_option = {
            inline: false,
            minDate : new Date(),
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText);
                inst.input.val(mnt.format('YYYY.MM.DD'));
                
                // 시작일 변경
                if( id == 'doctor_create_start_date' ) {
                    vue.picker_start_date = mnt.toDate();
                } 

                // 종료일 변경
                else if( id == 'doctor_create_end_date' ) {
                    vue.picker_end_date = mnt.toDate();
                }

            },
        }
        // @ts-ignore
        $('#doctor_create_start_date').datepicker(datepicker_option);

        // @ts-ignore
        $('#doctor_create_end_date').datepicker(datepicker_option);
    }

    // getPickerStartEndDate(isStart: boolean): string {
    //     if ( this.doctor_time_setting_modal_info == null ) {
    //         return '';
    //     }

    //     if (isStart) {
    //         if ( this.doctor_time_setting_modal_info.is_create_work_time ) {
    //             this.getYMDstr(this.picker_start_date)
    //         }else{
    //             this.getYMDstr(this.doctor_time_setting_modal_info.end_date)
    //         }

    //     }

        
    //      : ''
    // }

    getYMDstr(po_date: Date) : string {
        return moment(po_date).format("YYYY.MM.DD")
    }

    onClickTime(dowIndex: string | number, change_work_code : string) : void {
        if (this.doctor_time_setting_modal_info.timetable == null) { return; }
        const index = Number(dowIndex);
        let origin_work_code : string = this.doctor_time_setting_modal_info.timetable.list[index].work_code;

        if (change_work_code == "OFF") {
            this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.OFF
            return;
        }
        
        if (origin_work_code == hodu_doc_enum.doctor_work_code.AM) {
            switch (change_work_code) {
                case "PM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.FULL_TIME
                    break;
                case "AM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.OFF
                    break;

            }
            return;
        }

        if (origin_work_code == hodu_doc_enum.doctor_work_code.PM) {
            switch (change_work_code) {
                case "PM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.OFF
                    break;
                case "AM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.FULL_TIME
                    break;

            }
            return;
        }

        if (origin_work_code == hodu_doc_enum.doctor_work_code.FULL_TIME) {
            switch (change_work_code) {
                case "PM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.AM
                    break;
                case "AM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.PM
                    break;

            }
            return;
        }

        if (origin_work_code == hodu_doc_enum.doctor_work_code.OFF) {
            switch (change_work_code) {
                case "PM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.PM
                    break;
                case "AM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.AM
                    break;

            }
            return;
        }
    }

    /**
     * 표시될 영역이 해당 요일에 병원 스케줄의 오전/오후/종일/휴무 중 무엇인지 반환
     */
    isOn(dowIndex : number, div: string) : string {
        let   lb_value : string = "off";
        if ( this.doctor_time_setting_modal_info.timetable == null ) { return "" }
        const workCode : hodu_doc_enum.doctor_work_code = this.doctor_time_setting_modal_info.timetable.list[dowIndex].work_code;
        
        switch (div) {
            case "AM" :
                if ( workCode == hodu_doc_enum.doctor_work_code.FULL_TIME ) {
                    lb_value = "morn";
                } else if (workCode == hodu_doc_enum.doctor_work_code.AM ) {
                    lb_value = "on morn";
                } else {
                    lb_value = "off";
                }
                break;
            case "PM" :
                if ( workCode == hodu_doc_enum.doctor_work_code.FULL_TIME ) {
                    lb_value = "night";
                } else if ( workCode == hodu_doc_enum.doctor_work_code.PM ) {
                    lb_value = "on night";
                } else {
                    lb_value = "off";
                }
                break;
            case "OFF" :
                if ( workCode == hodu_doc_enum.doctor_work_code.OFF ) {
                    lb_value = "on off";
                } else {
                    lb_value = "off";
                }
                break;
        }
        
        return lb_value;
    }

    getDayNameChild(value: string | number) : string {
        return this.getDayName(Number(value));
    }

    saveTimetable() : void {
        if (this.doctor_time_setting_modal_info.timetable == null) { return; }
        
        if (this.doctor_time_setting_modal_info.is_create_work_time) {
            let temp_doctor_info : hodu_doc_object.doctor_info = {
                hospital_name       : "",
                department_code     : "",
                department_name     : "",
                doctor_code         : "",
                doctor_name         : "",
                doctor_detail       : {
                    color           : "",
                    position        : "",
                    license         : "",
                    career          : [],
                    profile_image   : ""
                },
                start_date          : moment(this.picker_start_date).set("hours",0).set("minutes",0).set("seconds",0).set("milliseconds",0).toDate(),
                end_date            : moment(this.picker_end_date).set("hours",14).set("minutes",59).set("seconds",59).set("milliseconds",0).toDate(),
                is_current_schedule : false,
                timetable_id        : "",
                monday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
                tuesday             : hodu_doc_enum.doctor_work_code.FULL_TIME,
                wednesday           : hodu_doc_enum.doctor_work_code.FULL_TIME,
                thursday            : hodu_doc_enum.doctor_work_code.FULL_TIME,
                friday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
                saturday            : hodu_doc_enum.doctor_work_code.OFF,
                sunday              : hodu_doc_enum.doctor_work_code.OFF
            }
            window["createTimetable"](this.doctor_time_setting_modal_info.timetable.list, temp_doctor_info)
            
        }else{
            //! 의사 조회한 뒤 근무표 수정할 때
            if ( this.doctor_time_setting_modal_info.timetable.timetable_id.length > 0 ) {
                window["updateTimetable"](this.doctor_time_setting_modal_info.timetable.list, this.doctor_time_setting_modal_info.timetable.timetable_id)
            }
            //! 의사 생성 화면일 때 
            else {
                window["createTimetable"](this.doctor_time_setting_modal_info.timetable.list)
            }
        }
        this.close();
    }

    deleteTimetable() : void {

    }

    close() : void {
        if( this.doSetDoctorTimeSettingModalInfo ) {
            this.doSetDoctorTimeSettingModalInfo({
                "show_modal"            : false,
                "is_create_work_time"   : false,
                "start_date"            : new Date(),
                "end_date"              : new Date(),
                "timetable"             : null
            });
        }
    }
}
</script>

<style scoped>
    .modal.with_date {  height:624px;margin-top:-312px; }
    .modal.with_date .btns input { width: 50%  }
    .modal.with_date .contractDiv { display:block; }
    .modal.with_date .btns .del { display: none; }
    .modal.with_date .detail .title.day { margin-top:30px; }
    .modal { position:absolute; left:50%; top:50%; margin-top:-229.5px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:459px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #f1f3f5 }
    .detail { padding: 30px; position: relative; }
    .detail .descript { position: absolute; right:30px; top:-40px; }
    .detail .descript li { overflow:hidden; display:inline-block;margin-right:20px; }
    .detail .descript li:last-child { margin-right:0; }
    .detail .descript li .clr { float:left; margin-right:5px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; }
    .detail .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .detail .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .detail .descript li .clr.allday {background: #477fff !important;border-color: #477fff;}
    .detail .descript li .clr.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .dayUl  { overflow:hidden; border-right:1px solid #e7e9ea; }
    .dayUl li { display: inline-block; width: 14.2857142857%; float:left; text-align:center; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .dayUl li:hover .day { background:#f1f3f5; }
    .dayUl li p { line-height: 35px; height: 35px; font-weight: bold; border-bottom: 1px solid #e7e9ea;border-top: 1px solid #e7e9ea; }
    .offDay .checkDiv a { cursor: default; }
    .offDay .checkDiv a span { opacity:0.3; cursor: default; background:#fff !important;  }
    .checkDiv a { display: block; height:55px; line-height:55px; box-sizing: border-box; font-weight: bold; border-bottom: 1px solid #e7e9ea; }
    .checkDiv a.on:hover { cursor:default }
    .checkDiv a.on span { background:#477fff !important;border-color:#1b5fff; color:#fff; }
    .checkDiv a:hover span { background: #f1f3f5; }
    .checkDiv a span { transition:0.2s; display: block; height:100%; line-height: 55px; background:#fff; box-sizing: border-box;}
    .checkDiv a.on.morn span {background: #ffc00e !important;border-color: #f3b300;}
    .checkDiv a.on.night span {background: #846eff !important;border-color: #6c52ff;}
    .checkDiv a.on.off span { background: #a1a5bb !important;border-color: red;}

    .contractDiv  { display:none; border-bottom:1px solid #f1f3f5;padding-bottom: 30px; }
    .contractDiv .title { margin-bottom: 10px; }
    .detail .title { font-size: 15px; font-weight: bold; height: 25px; }
    .detail .title.day {  margin-bottom: 10px }
    .contractDiv ul li { display:inline-block; }
    .contractDiv ul li.section { margin: 0 10px; }
    .contractDiv ul label { display:block; height: 20px; }
    .contractDiv input { cursor:pointer; transition: 0.2s; width: 200px; text-align: center; border-radius: 5px; border:1px solid #e7e9ea; background:#fff; height: 45px; line-height: 45px; font-weight: bold; }
    .contractDiv input:hover { background: #f1f3f5; }
    .contractDiv input:focus { background:#fff; border-color:#477fff }
    .contractDiv input:disabled { background:#fff !important; }
    .descriptP { font-size: 12px; height: 30px; opacity:0.7; margin-left: 10px; }

    .btns { overflow: hidden; border-top:1px solid #f1f3f5;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }
</style>