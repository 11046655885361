<template>
    <div class="section_ce_fix">
        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동' : '팀' }}</h3>
        </div>        
        <div id="makeGrpScroll" class="section_scroll"><!--스크롤 생겨야할 영역-->
            <div class="viewGroup">
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <h3 class="selectTitle"><span class="txt">{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동' : '팀' }} {{is_created ? "생성" : "수정"}}</span></h3>
                        <div class="btns fr">
                            <input type="button" class="input_btn" value="삭제" v-if="(team_id > 0 && is_deleteFlag)" @click="teamDelete()">
                            <input type="button" class="input_btn" value="저장" @click="saveValidator()">
                        </div>
                    </div>
                    <!-- 체크식일시 content에 addClass(check) -->
                    <div class="content" id="">
                        <div class="mngGrpModal">
                            <div class="createNewGroup"> <!-- 새그룹 만들시 div display: block -->
                                <form id="groupFileUpload" method="post" action="/hoduweb/upload/groupImageFileUpload.do" enctype="multipart/form-data">
                                    <fieldset>
                                        <legend class="hidden">새팀 만들기</legend>
                                        <div class="grp toTheLeft">
                                            <label for="grupSetting_grup_nm" class="grpName">{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동명' : '팀명' }}</label>
                                            <!-- 작성 안했을 시 addClass(wrong) -->
                                            <input type="text" class="newGroupName input_txt" :placeholder="isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동명을 입력하세요' : '팀명을 입력하세요'" id="grupSetting_grup_nm" 
                                                   :value="team_data.team_info.team_name" @input="team_data.team_info.team_name = $event.target.value">

                                            <div class="half grp">
                                                <!-- #grupSetting_image에 클래스추가 dragging -->
                                                <label for="cdImg" id="grupSetting_image" class="clickOrDropBg forImg" :class="{ dragging : image_drag == true }" @dragover.prevent="imageDragOver($event)" @dragleave.prevent="imageDragLeave" @drop.prevent="imageDrop($event)" >클릭 또는 끌어넣기</label>
                                                <input type="file" id="cdImg" class="cdInput" value="click or drop" accept="image/*" @change="addImageNormal($event)"/>

                                                <p class="grpNm" style="">{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동 이미지' : '팀 이미지' }}</p>
                                                <p class="groupImg img" id="grupSetting_image" :style="{ background: `url(${team_data.team_info.image ? 'app_images/' + team_data.team_info.image : (team_no_image ? team_no_image : '')})` }" v-if="!base64url">{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동 이미지' : '팀 이미지' }}</p>
                                                <p class="groupImg img" id="grupSetting_image" :style="{ backgroundImage: `url(${base64url})` }" v-if="base64url">{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동 이미지' : '팀 이미지' }}</p>
                                                <ul class="descript">
                                                    <li>{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동' : '팀' }}을 대표할 수 있는 사진을 선택하세요.</li>
                                                    <li>해상도가 높은 사진 사용을 권장합니다.</li>
                                                    <li>사진을 선택 안할 시 {{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동' : '팀' }} 색상 이미지로 저장됩니다.</li>
                                                </ul>
                                                <!-- 이미지 있을시 보이기 -->
                                                <input type="button" class="btnDel" value="삭제" v-if="base64url || team_data.team_info.image" @click="imageDelete()"/>
                                            </div>

                                            <!-- 퍼블 추가 2019.05.08 색상 선택 -->
                                            <input type="button" class="clr dc1" value="그룹색상" :style="{ backgroundColor: `${team_data.team_info.team_color} !important` }" @click="colorPick">
                                            <div id="pickColour" :class="{ on : color_pick == true }">
                                                <ul>
                                                    <li>
                                                        <a>기본색</a>
                                                        <ul>
                                                            <li :class="{on: team_data.team_info.team_color == '#FF6363'}"><a href="#" class="dc0" @click.prevent="colorChange('#FF6363')">연어</a></li>
                                                            <li :class="{on: team_data.team_info.team_color == '#FFA70E'}"><a href="#" class="dc1" @click.prevent="colorChange('#FFA70E')">주황</a></li>
                                                            <li :class="{on: team_data.team_info.team_color == '#FFC72F'}"><a href="#" class="dc2" @click.prevent="colorChange('#FFC72F')">노랑</a></li>
                                                            <li :class="{on: team_data.team_info.team_color == '#FF198B'}"><a href="#" class="dc3" @click.prevent="colorChange('#FF198B')">자주</a></li>
                                                            <li :class="{on: team_data.team_info.team_color == '#00B2C7'}"><a href="#" class="dc4" @click.prevent="colorChange('#00B2C7')">청록</a></li>
                                                            <li :class="{on: team_data.team_info.team_color == '#13D08B'}"><a href="#" class="dc5" @click.prevent="colorChange('#13D08B')">초록</a></li>
                                                            <li :class="{on: team_data.team_info.team_color == '#4DBAFF'}"><a href="#" class="dc6" @click.prevent="colorChange('#4DBAFF')">하늘</a></li>
                                                            <li :class="{on: team_data.team_info.team_color == '#477FFF'}"><a href="#" class="dc7" @click.prevent="colorChange('#477FFF')">파랑</a></li>
                                                            <li :class="{on: team_data.team_info.team_color == '#6854FF'}"><a href="#" class="dc8" @click.prevent="colorChange('#6854FF')">보라</a></li>
                                                            <li :class="{on: team_data.team_info.team_color == '#35405A'}"><a href="#" class="dc9" @click.prevent="colorChange('#35405A')">검정</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>                                            
                                            </div> 
                                            <!-- 퍼블 추가 2019.05.08 색상 선택 ends -->

                                            <!--<input type="file" id="chngePicId" accept="image/*" @change="profileImageChange($event)" />-->
                                            <input type="file" name="upl" style="display:none;">
                                            <input type="hidden" id="grupFileSaveName" value="">
                                            <!--<a href="#" class="groupImg" id="grupSetting_image">이미지 선택</a> -->                   

                                            <div class="half grp">
                                                <label for="grupSetting_grup_rmk" class="grpDescript">{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동 소개글' : '팀 소개글' }}</label>
                                                <input class="newGroupIntro" :placeholder="isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동 소개글을 입력하세요' : '팀 소개글을 입력하세요'" id="grupSetting_grup_rmk" 
                                                       :value="team_data.team_info.description" @input="team_data.team_info.description = $event.target.value" />
                                            </div>

                                            <div class="half">
                                                <h5>{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '입주자' : '멤버' }} 가입시 승인 요청</h5>
                                                <label class="switch">
                                                <input type="checkbox" :checked="team_data.team_policy.is_appr_required" @change="setApproveChange($event)">
                                                <span class="slider round"></span>
                                                </label>
                                                <p class="waitDescript">{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동장' : '팀장' }} 가입요청 승인을 거쳐 가입 할 수있습니다. </p>
                                            </div>

                                            <div class="half" v-if="hasSyncButton() == true">
                                                <h5>조직도 부서 {{ team_id == 0 ? '생성' : '수정' }}</h5>
                                                <label class="switch">
                                                <input type="checkbox" v-model="team_sync">
                                                <span class="slider round"></span>
                                                </label>
                                                <p class="waitDescript">달력, 조직도가 동기화 됩니다.</p>
                                            </div>                        

                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>        
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupModalInfo, GroupListConfirmModalData, ImageCropModalInfo } from '@/store/modules/ModalInfo';
import { t_event_file } from '@/model/event';

const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');

@Component({

})
export default class GroupTeamCreate extends Mixins(VueHoduCommon) {
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;

    /**
     * 변수 선언부
     */
    team_data : any = {
        team_id: 0,
        group_id: 0,
        biz_id: "",
        team_type: "",
        create_user_id: 0,
        team_info: {
            team_name: "",
            description: "",
            image: "",
            team_color: "#477FFF"
        },
        team_policy: {
            is_public: false,
            is_appr_required: false
        },
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: 0
    }

    is_created    : boolean = true;
    is_deleteFlag : boolean = false;
    image_drag    : boolean = false;
    base64url     : string  = "";
    image_files   : any     = [];
    biz_id        : string  = "";
    team_no_image = null;

    color_pick : boolean = false;

    team_sync : boolean = false;
    departments_has_team_id : any[] = [];

    async beforeMount() {
        if ( !this.group_id || this.group_id < 1 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() {

        this.biz_id = this.group_team_option.biz_id;

        if ( this.group_id > 0 && this.team_id > 0 ) {
            this.is_created = false;

            // 삭제 퍼미션 체크
            this.is_deleteFlag = this.is_group_permmision(this.group_id, "team", "modify");

            if ( !this.is_deleteFlag ) {
                this.is_deleteFlag = this.is_team_permmision(this.team_id, "team_info", "delete");
            }

            await this.getGroupTeam();
        }
        else {
            this.team_no_image = require("@/assets/images/contents/img"+this.group_color_to_class_name(this.team_data.team_info.team_color)+".png");
        }

        // HODU H는 기본이 승인 받도록
        if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
            this.team_data.team_policy.is_appr_required = true;
        }
    }

    /**
     * 팀 단건 조회
    */
    async getGroupTeam() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getDetail/${this.group_id}/${this.team_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                if ( !response.data.data.data ) {
                    this.hodu_show_dialog("cancel", '팀 조회중 오류가 발생하였습니다.', ['확인']);
                    vue.movePrevPage();
                    return;
                }

                let data = response.data.data.data;
                this.team_data.team_info.team_name          = data.team_info.team_name;
                this.team_data.team_info.team_color         = data.team_info.team_color;
                this.team_data.team_info.image              = data.team_info.image;
                this.team_data.team_info.description        = data.team_info.description;
                this.team_data.team_policy.is_appr_required = data.team_policy.is_appr_required;

                this.departments_has_team_id = response.data.data.departments_has_team_id;

                // if( this.team_id > 0 && this.hasSyncButton() == true ) {
                //     this.team_sync = true;
                // }

                // 호두 H 일때는 주차 가능 수를 받아야 한다
                if( vue.isHoduH(vue.scope_group_team_option.biz_type ? vue.scope_group_team_option.biz_type : '') ) {
                    this.team_data.team_info.max_parking_number = data.team_info.max_parking_number;
                }

                if ( this.team_data.team_info.team_color.length == 9 ) {
                    this.team_data.team_info.team_color = "#" + this.team_data.team_info.team_color.substring(3, this.team_data.team_info.team_color.length);
                }

                if ( this.team_data.team_info.image == "" ) {
                    this.noTeamImage();
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 승인 변경
     */
    setApproveChange(any) : void {
        this.team_data.team_policy.is_appr_required = any.target.checked;
    }

    /**
     * 팀 삭제
     */
    async teamDelete() : Promise<void> {
        this.hodu_show_dialog("cancel", "팀을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            (check_flag) => {
                this.teamDeleteService(check_flag);
            },
        ], this.hasSyncButton() == true, "조직도 부서 삭제");
    }    

    async teamDeleteService(check_flag : boolean) : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/teams/updateTeamDelete/${this.team_id}/${this.group_id}`, API_METHOD.DELETE, { "team_sync" : check_flag })
            .then(async(response) => {
                await this.get_group_role_service();
                this.doTeamId(0);
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });        
    }

    /**
     * 팀 생성
     */
    async saveValidator() : Promise<void> {
        if ( this.team_data.team_info.team_name.length < 2 || this.team_data.team_info.team_name.length > 20 ) {
            this.hodu_show_dialog("cancel", "팀명은 최소 2글자 부터 최대 20글자 입니다.", ['확인']);
            return;
        }

        // 팀 소개글 제한 없음
        // if ( this.team_data.team_info.description.length > 20 ) {
        //     this.hodu_show_dialog("cancel", "팀 소개글은 최대 20글자 입니다.", ['확인']);
        //     return;
        // }

        this.hodu_show_dialog("alert", "팀 " + (this.team_id == 0 ? "생성" : "수정") + " 하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.teamFileSave();
            },
        ]);
    }

    /**
     * 파일 업로드
     */
    async teamFileSave() : Promise<void> {
        const vue = this;

        if ( this.base64url && this.image_files.length > 0 ) {
            const form_data : FormData = new FormData();

            // FormData 내용 생성
            const image_files_count : number = this.image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.image_files[i].file);
            }

            await this.hodu_temp_upload(form_data)
                .then(async(response) => {
                    //const temp_files : t_event_file[] = response.data.data.temp_files;
                    this.team_id == 0 ? await this.teamCreateSave(response.data.data.temp_files) : await this.teamModifySave(response.data.data.temp_files);
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });      
        }
        else {
            this.team_id == 0 ? await this.teamCreateSave([]) : await this.teamModifySave([]);
        }
    }

    /**
     * 팀 생성
     */
    async teamCreateSave(po_files : t_event_file[]) : Promise<void> {
        const vue = this;

        this.team_data.group_id              = this.group_id;
        this.team_data.biz_id                = this.biz_id;
        this.team_data.team_type             = "";
        this.team_data.team_policy.is_public = true;

        // HODU H는 biz_id를 같이 보내준다
        if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
            this.team_data.team_info.hodu_h_biz_id = this.scope_group_team_option.biz_id;
        }

        let param_data = {team: this.team_data}

        console.log(JSON.stringify(this.team_data))

        if ( null != po_files && po_files.length > 0 ) {
            param_data["team_file"] = po_files[0];
        }

        param_data['team_sync'] = this.team_sync;

        await this.hodu_api_call(`api/v1/teams`, API_METHOD.POST, param_data)
            .then(async(response) => {
                await this.get_group_role_service();
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 팀 수정
     */
    async teamModifySave(po_files : t_event_file[]) : Promise<void> {
        const vue = this;

        let param_data = {team: this.team_data}

        if ( null != po_files && po_files.length > 0 ) {
            param_data["team_file"] = po_files[0];
        }

        param_data['team_sync'] = this.team_sync;

        await this.hodu_api_call(`api/v1/teams/${this.team_id}/${this.group_id}`, API_METHOD.PUT, param_data)
            .then(async(response) => {

                if( this.team_id == this.scope_team_id ) {

                    await this.get_group_role_service();
                    const groups = this.all_group_data.filter(team => team.group_id == this.scope_group_id);
                    if( groups.length > 0 ) {

                        const group = groups[0];

                        const teams = group.teams.filter(team => team.team_id == this.scope_team_id);

                        if( teams.length > 0 ) {

                            const team = teams[0];

                            this.doSetScopeGroupTeamOption({
                                group_team_name : team.team_name,
                                group_team_color : team.color,
                                group_team_image : `${this.make_team_profile_image_url(this.scope_team_id)}?t=${new Date().getTime()}`,
                                group_team_descript : this.team_data.team_info.description,
                                biz_id : team.biz_id,
                                biz_type : team.biz_type
                            });
                        }
                    }
                }

                await vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 색상선택
     */
    colorChange(color) : void {
        this.color_pick = false;
        this.team_data.team_info.team_color = color;
        this.noTeamImage();
    }

    /**
     * 색상 선택 boolean
     */
    colorPick() : void {
        this.color_pick = !this.color_pick;
    }

    /**
     * 이미지 삭제
     */
    imageDelete() : void {
        if ( this.base64url ) {
            this.base64url = "";
            this.image_files = [];
        }

        if ( this.team_data.team_info.image ) {
            this.team_data.team_info.image = "";
        }

        this.noTeamImage();
    }

    /**
     * 이미지 변경 (업로드 안되어있을때)
    */
    noTeamImage() : void {
        this.team_no_image = require("@/assets/images/contents/img"+this.group_color_to_class_name(this.team_data.team_info.team_color)+".png");
    }

    /**
     * 이미지 드래그해서 이미지 영역에 올려놨을때
     */
    imageDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.image_drag = true;

    }

    /**
     * 이미지 드래그해서 이미지 영역에서 벗어났을때
     */
    imageDragLeave() : void {
        this.image_drag = false;
    }

    /**
     * 이미지를 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    imageDrop(event) : void {
        this.image_drag = false;
        this.addImage(event.dataTransfer.files);
    }

    /**
     * 팀 이미지 추가
     */
    addImageNormal(event) : void {
        const file : File[] = event.target.files;
        this.addImage(file);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(file : File[]) : Promise<void> {
        const vue = this;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(file[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#cdImg').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                if( this.doSetImageCropModalInfo == null ) { return; }

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (4/3), // 4:3
                    image_url : url,
                    cancel_function : async() => {
                        $('#cdImg').val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        
                        this.base64url = cropped_url;

                        const resize_blob : Blob = await vue.hodu_image_resize(this.base64url);

                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = file[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, file[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = file[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        // 업로드용 파일 객체 담기
                        vue.image_files = [];
                        vue.image_files.push({
                            file : resize_file
                        });

                        $('#cdImg').val("");

                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 팀&부서 동기화 체크
     */
    teamSyncChecked() : boolean {
        const feature = this.get_group_feature(this.group_id);
        if( feature == null || feature.team_sync == null || feature.team_sync.enable == null ) return false;
        return feature.team_sync.enable;
    }

    hasSyncButton() : boolean {

        if( this.teamSyncChecked() == true ) return false; 

        if( this.team_id == 0 ) {
            return true;
        }
        
        const filter_departments = this.departments_has_team_id.filter(department => department.team_id == this.team_id);
        return filter_departments.length > 0;
    }
}
</script>
<style scoped>
    .title_box { height:60px }
    .grp.settingBg { top: 64px }
	#makeGrpScroll .settingBg h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
	#makeGrpScroll .content {padding-top: 0;margin-top:0;}
	#container #section_ce #makeGrpScroll .schedule_box {padding-left: 0;}
	#container #section_ce #makeGrpScroll .schedule_box .settingBg .btns input {margin-top: 10px;margin-left: 15px;}
	#container #section_ce #makeGrpScroll .schedule_box .settingBg .btns {margin-right: 30px;margin-top:0;}
	#makeGrpScroll .createNewGroup { padding-bottom:200px; max-width:650px;margin-left:0 !important; }
	#groupSelectBg h5 { font-size:16px;line-height:40px; height:40px; }
	#groupSelectBg h5 span {float: right;font-size:14px;}
	#groupSelectBg h5 a {width: 100%;display: inline-block;}
	div#groupSelectBg {    float: left;position: relative;width: 100%;margin-bottom: 20px;height: auto;padding-bottom: 20px;border-top: 1px solid #e7e9ea;padding-top: 20px;border-bottom: 1px solid #e7e9ea;}
	.grp.toTheRight {float: left;width: 100%;}
	div#groupSelectBg:hover h5:hover span { color:#477fff;  }
	div#groupSelectBg:hover .selectUl {height: auto;font-size: 12px;}
	div#groupSelectBg .selectUl {height: 0;font-size: 0;}
	#makeGrpScroll .img {position:relative;width: 335px;height: 230px;margin-top: 40px; font-size:0; background: url(../assets/images/contents/imgDc7.png) no-repeat; background-size:cover !important; background-position: center center !important; }
	#makeGrpScroll .half.grp .grpNm {line-height: 40px;height: 40px;}
	.createNewGroup .half h5 {font-size: 14px;height: 40px;line-height: 40px;}
	.createNewGroup .half .switch {width: 52px !important;position: absolute;top: 7px;right: 0;margin-top: 0;}
	.createNewGroup .grpDescript { display:none; }
	.half.grp .grpNm {position: absolute;right: 0;width: 190px !important;line-height: 22px;text-align: left;top: 55px;box-sizing: border-box;padding-left: 10px;margin-top:0; }
	.half.grp ul.descript {position: absolute;right: 4px;width: 190px;color:#273044; line-height: 22px;text-align: left;top: 85px;box-sizing: border-box;padding: 15px;padding-right:0;}
	.half.grp ul.descript li { display: block;font-size: 12px;margin-bottom: 7px;line-height: 20px;margin-left: 15px;}
	.half.grp ul.descript li:before {content: '';display: inline-block;width: 5px;height: 5px;background: #acb2bf;margin-left: -15px;margin-right: 10px;border-radius: 50%;}
	.half.grp ul.descript li:last-child { margin-bottom:0; }
	
	input#grupSetting_grup_nm:focus, input#grupSetting_grup_nm:hover {border-bottom: 2px solid #477fff;}
	input#grupSetting_grup_nm {position: static;top: 0px;font-weight: bold;font-size: 24px;border: 0 none;padding-left: 0;border-bottom: 2px solid #f1f3f5;height: 70px;padding-right: 40px;}
	input#grupSetting_grup_nm.wrong {color: red;border-bottom: 2px solid red}
	input#grupSetting_grup_nm.wrong::placeholder {color: red;}
	textarea#grupSetting_grup_rmk.wrong {color: red;border-top: 1px solid red !important; border-bottom: 1px solid red !important}
	textarea#grupSetting_grup_rmk.wrong::placeholder {color: red;}
	
	#makeGrpScroll .img:after {position: absolute;content: '';opacity:0;width: 100%;height: 100%;border-radius: 5px;background: rgba(0, 0, 0, 0.05);transition:0.2s;}
	#makeGrpScroll .img:hover:after { opacity:1;}
	.waitDescript { line-height:30px; height:30px;color:#273044}
    .mngGrpModal .half.grp {padding-left: 0 !important;width: 100%;float: left;position: relative;box-sizing: border-box;padding-bottom: 15px;}
    input#grupSetting_grup_rmk {font-weight:bold;padding-left: 0;font-size: 18px;border-top: 1px solid #e7e9ea;transition: 0.2s;line-height: 70px;border-bottom: 1px solid #e7e9ea;height: 70px;}
    input#grupSetting_grup_rmk:focus {border-color: #477fff !important;}
    input#grupSetting_grup_rmk:hover {border-color: #d7dcde;}
    input#cdImg {display: none;}
    label#grupSetting_image {z-index:100;position: absolute;border-radius:5px;width: 335px;left: 0;top: 40px;height: 230px;background:rgba(71, 127, 255, 0.15); opacity:0;}
    label#grupSetting_image:hover {opacity:1;}
    label#grupSetting_image.dragging {background: rgba(255, 255, 255, 0.3) url(../assets/images/contents/img_dragDrop.png) no-repeat center center !important;opacity: 1;background-size: 160px !important;border: 2px dashed #477fff;box-sizing: border-box;}
    div#pickColour { top: 90px; }
    div#pickColour>ul>li { width:100%; }
    div#pickColour>ul>li > a { display:none; }
</style>