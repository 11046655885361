@charset "utf-8";

/* font colour */
input, textarea { } 
html { min-height: 700px !important; }
body { background:#f1f3f5; position: fixed; }
body, a, span { color:#232848; }
::placeholder {color:#9c9fa2;} 
.mw { max-width:1200px; display: inline-block; width:100%; padding: 0 30px; box-sizing:border-box; }
.mw.mw900 { max-width:900px; }


/* header */
.ware_header  {z-index:1000; transition:0.2s; position:fixed; top:0; left:0; background:rgba(255,255,255,1);height:70px; border-bottom:1px solid #e3e7ed;width:100%; text-align: center;box-sizing:border-box; }
.ware_header.ware_shadow { border-color:#fff; box-shadow: 0 3px 10px rgba(0,0,0,0.08); }
.ware_header:hover { background: rgba(255,255,255,1) }
.ware_header .mw { position:relative; width:100%; max-width:1200px; display: inline-block; text-align: left; } 
.ware_navDiv  { float:right; margin-right:110px; }
.ware_nav li { display: inline-block; padding:0 20px; }
.ware_nav li a {display: inline-block; height:70px; line-height:70px; font-size:13px; font-weight:bold;box-sizing:border-box;transition:0.2;}
.ware_nav li a:hover { color:#477fff; }
.ware_nav li a.on { color:#477fff; border-bottom:2px solid #477fff; }
h1.ware_h1 {display: inline-block;}
h1.ware_h1 a { float:left; display: block; width: 90px; height:70px;background: url('../images/ware/hodu_logo.png') no-repeat center left; font-size:0;  background-size:60px;}
.liDiv { position: absolute;right:30px;top:17px; }
.logInBtn { display:inline-block; width:90px; height:35px; line-height:35px; background:#477fff;background-image: linear-gradient(to right, #477fff 30% , #4738ff 119%); font-weight: bold; color:#fff; border-radius: 25px; font-size:12px; cursor:pointer; transition:0.2s;}
.logInBtn:hover { box-shadow: 0 4px 8px rgba(71, 127, 255, 0.3);transition:0.2s }


/* content */
.ware_wrapper { background:#fff; display: flex; justify-content: center; }
/* content */
.no_result_div p.no_result {display:none; margin-top:30px;width: 100%;border-radius: 5px;height: 100px;line-height: 100px;font-size: 16px;font-weight: bold;border-radius: 5px;background: #f1f3f5;color: #adafbf;}
.no_result_div.on { display:block; }

/* content sub_nav */
.ware_content .sub_title { width:100%; text-align: center; height:120px; border-bottom:1px solid #e3e7ed;  }
.ware_content .sub_title h2 { display:block; font-size: 18px; font-weight: bold; height:65px;line-height:75px;  }
.ware_content .sub_title .sub_nav li { display: inline-block; margin: 0 5px;  }
.ware_content .sub_title .sub_nav li a { display: inline-block; transition:0.2s; width:80px; background:#f1f3f5; border-radius:18px; height: 30px; line-height: 30px; font-weight: bold; font-size: 12px;   }
.ware_content .sub_title .sub_nav li a:hover { background:#e3e7ed; }
.ware_content .sub_title .sub_nav li a.on { color:#fff; background-color: #477fff; }


/* sidebar */
.ware_content .ware_sidebar {padding-top:70px; position: fixed;left: 50%;margin-left: -600px;width: 200px;top: 0; height:100%; padding-bottom: 155px; min-height: 500px;box-sizing: border-box; }
.ware_content .ware_sidebar .mw { padding-right:0; }
.ware_sidebar .sub_nav { padding-left: 0px; box-sizing:border-box; padding-top:10px; }
.ware_sidebar .sub_nav > li { display: block; width:100%; }
.ware_sidebar .sub_nav > li > a { position: relative; transition:0.2s; width:100%; line-height: 35px; height: 35px;display: block; font-size: 12px; border-right: 2px solid #fff; box-sizing:border-box; }
.ware_sidebar .sub_nav > li > a .ware_dot { opacity:0; position: absolute;right: 14px; top: 15px; display: inline-block;width: 7px; height: 7px; background: #e3e7ed; border-radius: 50%; transition:0.2s;}
.ware_sidebar .sub_nav > li > a.on, .ware_sidebar .sub_nav > li > a.on:hover { font-weight: bold; }
.ware_sidebar .sub_nav > li > a.on .ware_dot, .ware_sidebar .sub_nav > li > a.on:hover .ware_dot{ background: #477fff !important; opacity:1;}
.ware_sidebar .sub_nav > li > a:hover .ware_dot  { opacity:1; }
.ware_sidebar .sub_nav > li > a:hover { font-weight: bold; }
.ware_sidebar .sub_nav > li.open .sub_nav2 { display:block; }
.ware_sidebar .sub_nav > li:hover .sub_nav2 { display: block;  }
.ware_sidebar .sub_nav2  { display: none; margin-bottom: 10px; }
.ware_sidebar .sub_nav2 > li > a { padding-left: 10px;box-sizing:border-box; display: block; width: 100%; font-size: 12px; height: 25px; line-height: 25px; }
.ware_sidebar .sub_nav2 > li > a.on, .sub_nav2 > li > a:hover { font-weight: bold; }

/* 네비게이션 미디어쿼리 */
@media screen and (max-width:1200px) {
	.ware_content .ware_sidebar  { left:0; margin-left: 0; }
	.ware_sidebar .sub_nav { padding-left:0; }
}


/* content ware_search */
.ware_search {position: relative;border-bottom:1px solid #e3e7ed; width:100%;}
.ware_search.on {border-bottom:1px solid #477fff;}
.ware_search_div { width:100%; }
.ware_search.on .ware_searchBtn {left: 0;margin-left:0 !important; }
.ware_searchBtn { cursor:pointer; position: absolute;left: 50%; top:15px; margin-left: -120px !important;width:40px; height:40px; font-size:0; border-radius: 50%; background: url(../images/ware/ware_sch_btn.png) no-repeat center center; background-size: 35px;}
.ware_searchBtn:hover {  background: url(../images/ware/ware_sch_btn_on.png) no-repeat center center; background-size: 35px;}
.ware_txt_input { margin-left:40px;transition:0.2s; display: inline-block; width: 180px; font-size:15px; font-weight:bold; line-height:70px; height:70px;}
.ware_txt_input:focus { margin-left:0;width:100%; width:100%; padding-left:60px; text-align: left; }

.ware_content_ul { margin-bottom:100px; }
.ware_content_ul li { padding-left:40px; border-bottom:1px solid #e3e7ed; text-align:left; }
.ware_content_ul li.on h3 { font-weight: bold; }
.ware_content_ul li.on .answer { display:block; }
.ware_content_ul li.on h3 .number { margin-right:0; border-right:0; }
.ware_content_ul li:hover h3 .number { margin-right:0; border-right:0; }
.ware_content_ul li h3 {  width:100%; height: 60px; line-height:60px; font-weight: normal; font-size: 13px;}
.ware_content_ul li h3 .number { margin-left:-40px;transition:0.2s; display: inline-block; width:40px;height:15px; line-height: 15px;margin-right:20px; border-right: 2px solid #477fff; }
.ware_content_ul li h3 a { display: inline-block; width:100%; height:100%;  }
.ware_content_ul li .answer { display: none; margin-left:0; border-left:2px solid #477fff; padding-left:20px;  max-width:800px; font-size:13px; line-height: 23px; box-sizing:border-box; margin-bottom: 50px;margin-top:10px;}


.ware_section { width:100%; text-align: center; }

.ware_w50.alCntr { text-align: center;  }
.mtop { margin-top:50px;  }

/* footer */
.ware_footer { overflow:hidden; width:100%; border-top:1px solid #f1f3f5; height: 115px; box-sizing:border-box; text-align: center; background:#f1f3f5; padding-bottom: 40px;padding-top: 25px;}
.ware_footer .mw { position: relative; display: inline-block; max-width:1200px; text-align: left; }
.ware_footer_left { float: left;margin-right: 30px;padding-right: 20px;box-sizing: border-box;width: 170px;border-right: 2px solid #e3e7ed; }
.ware_footer h4 { margin: 0 0 10px; font-size: 11px; }
.ware_footer .ware_eng { opacity: 0.3; text-transform: uppercase; font-weight:bold; }
.ware_footer ul  { float:left;	 }
.ware_footer ul li { line-height: 17px; height: 23px;font-size: 11px; }
.ware_footer ul li.ware_address { line-height: 16px; }
.ware_footer .ware_footer_dets { display: inline-block; margin-right: 20px;}
.ware_footer .footer_title { display: inline-block; margin-right: 10px; font-weight: bold;font-size: 10px; }
.ware_footer .copyright { clear:both; margin-top: 60px; opacity: 0.5; display: block; }
.ware_footer .hodu_logo_footer { position:absolute;right:0; top:3px; display: block; width: 90px; height:80px;background: url(../images/ware/hodu_logo_footer.png) no-repeat top center; font-size:0;  background-size:60px; }
.footer_links a {transition:0.2s; display: inline-block;font-size:10px; font-weight: bold; border: 1px solid #232848; border-radius: 2px; padding: 0 4px; line-height: 18px; height: 18px;   }
.footer_links a:first-child { margin-right: 9px; }
.footer_links a:hover { background: #fff;  }




.ware_manual_icon { background: url(../images/ware/ware_manual_icons.png) no-repeat center left;background-position: -165px -5px;background-size: 405px;font-size: 0;width: 35px;height: 35px;display: inline-block;margin-top: -6px;padding: 0;}
.ware_manual_icon.grpBtn { background-position: -329px -5px; }
.ware_manual_icon.grpMenu { background-position: -34px -5px; }
.ware_manual_icon.schBtn { background-position:  -296px -5px}
.ware_manual_icon.saveBtn { background-position: -363px -5px }
.ware_manual_icon.inviMenu { background-position: -101px -5px }
.ware_manual_icon.plusBtn { background-position: -199px -5px }
.ware_manual_icon.copyBtn { background-position: -231px -5px }
.ware_manual_icon.deleteBtn {background-position: -265px -5px}
.ware_manual_icon.alarmMenu {background-position: -134px -5px;}

.ware_ylw { color:#ffd500; }
.ware_red { color:#ff6060; }
.ware_blue { color:#477fff; }
.ware_green { color:#00d5ab; }
.ware_purple { color:#6b3fff; }

.ware_ylw_bg { background-color:#ffd500; }
.ware_red_bg { background-color:#ff6060; color:#fff; }
.ware_blue_bg { background-color:#477fff; color:#fff; }
.ware_green_bg { background-color:#00d5ab; color:#fff; }
.ware_purple_bg { background-color:#6b3fff; color:#fff; }













