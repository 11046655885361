<template>
    <div id="event_history_modal" class="event_history_modal modal">

        <div class="titleBox">
            <h1 class="borderbot">히스토리</h1>
        </div>

        <div class="modal_content">
            <!-- 순서는 최신순이 위로 올라오게 해주세요 -->
            <ul class="evHistoryUl">
                <li class="evHistoryList" :key="history.log_id" v-for="history in histories">
                    <p>{{`${hodu_date_to_format_string(history.audit_created, 'YYYY.MM.DD')}  ${getDayOfWeekByDate(history.audit_created)} ` }}<span class="evHisTime">{{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(history.audit_created, 'hh:mm a')) }}</span></p>
                    <p><span class="evHisUser">{{`[${history.audit_user_name}]`}}</span>{{` 님이 ${getDescription(history.remark)}`}}</p>
                </li>
                <!-- <li class="evHistoryList">
                    <p>2021.08.24 화&nbsp;<span class="evHisTime">오후 04:05</span></p>
                    <p><span class="evHisUser">[김대리]</span>&nbsp;님이 일정을 공유하였습니다</p>
                </li>
                <li class="evHistoryList">
                    <p>2021.08.24 화&nbsp;<span class="evHisTime">오전 11:00</span></p>
                    <p><span class="evHisUser">[김호두]</span>&nbsp;님이 반복 일정을 일정만 수정옵션으로 수정하였습니다</p>
                </li>
                <li class="evHistoryList">
                    <p>2021.08.24 화&nbsp;<span class="evHisTime">오전 10:00</span></p>
                    <p><span class="evHisUser">[김호두]</span>&nbsp;님이 일정을 작성하였습니다</p>
                </li> -->
            </ul>
        </div>

        <div class="btns b1">
            <input type="button" class="input_btn" value="닫기" @click="close">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const EventInfo = namespace('EventInfo');

import { EventHistoryModalInfo } from '@/store/modules/ModalInfo';
import { t_event } from '@/model/event';

@Component({
    components: {
        
    },
})
export default class EventHistoryModal extends Mixins(VueHoduCommon) {

    @EventInfo.State event!: t_event; // 일정 정보

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventHistoryModalInfo ?: (params : EventHistoryModalInfo) => void;
    
    histories : any[] = []

    mounted() : void {
        this.getHistories()
    }

    async getHistories() {

        const owner_type = this.event.team_id && this.event.team_id > 0 ? "TEAM" : this.event.group_id && this.event.group_id > 0 ? "GROUP" : "PERSONAL" ;
        const owner_id = owner_type == "PERSONAL" ? this.user_id : owner_type == "GROUP" ? this.event.group_id ?? 0 : this.event.team_id ?? 0
        
        try {
            const response = await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${owner_type}/${owner_id}/${this.event.event_id}/log`, API_METHOD.GET, null, false);

            console.log(response);

            if( response.data.data.logs == null ) return;

            this.histories = this.histories.concat(JSON.parse(JSON.stringify(response.data.data.logs)));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    getDescription(type: string) : string {
        let desc = ""

        switch ( type ) {
            
            case "EVENT_CREATE" : 
                desc = "일정을 생성했습니다";
                break;

            case "EVENT_MODIFY" : 
                desc = "일정을 수정했습니다";
                break;

            case "EVENT_MOVE" :
                desc = "일정을 이동시켰습니다";
                break;

            case "EVENT_DELETE" :
                desc = "일정을 삭제했습니다";
                break;

            case "REPEAT_INSTANCE_UPDATE" :
                desc = "반복 일정을 이 일정만 수정 옵션으로 수정했습니다";
                break;

            case "REPEAT_AFTER_UPDATE" :
                desc = "반복 일정을 이후 일정 수정 옵션으로 수정했습니다";
                break;

            case "REPEAT_INSTANCE_DELETE" :
                desc = "반복 일정을 이 일정만 수정 옵션으로 삭제했습니다";
                break;

            case "REPEAT_AFTER_DELETE" :
                desc = "반복 일정을 이후 일정 수정 옵션으로 삭제했습니다";
                break;
                
            default:
                desc = "일정을 수정했습니다";
                break;
        }

        return desc
    }

    close() : void {
        this.doSetEventHistoryModalInfo?.({ show_modal : false, event_id : "" });
    }

}
</script>

<style scoped>
    
    .modal { position:absolute; background:#fff; }
    .modal { width: 900px; height:600px; top:50%; left:50%;  margin-top:-300px;  margin-left:-450px; border-radius:5px; overflow:hidden; }

    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}

    .modal .btns {border-top:1px solid #f1f3f5; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; }
    .modal .btns.b1 input { width:100% }

    #event_history_modal .modal_content { min-height: 440px; height: 440px; overflow-y: auto; }
    
    .evHistoryUl { min-width: 440px; }
    .evHistoryUl li { overflow: hidden; box-sizing: border-box; height: 55px; line-height: 55px; border-bottom: 1px solid #e7e9ea; }
    .evHistoryUl li p { width: 75%; display: inline-block; padding: 0 30px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-size: 14px; }
    .evHistoryUl li p:first-child { width: 25%; background: #f1f3f5; text-align: center; }
    .evHistoryUl li p .evHisUser { font-weight: bold; }

    #event_history_modal ::-webkit-scrollbar{width: 6px;}
    #event_history_modal ::-webkit-scrollbar-track{background-color: transparent;}
    #event_history_modal ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

</style>