<template>
    <div id="organization_work_type_setting" class="content">
        <!-- <p class="sub_title">오전타임 또는 오후타임 등 기본시간에 해당하지 않는 직원을위해 다른근무시간을 설정하세요</p> -->

        <div class="bt_scheduleBox" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
            <input type="button" id="btnAddVacationType" class="bt_schedule" @click.prevent="showOrganizationWorkTypeAddModalInfo(true)"/>
        </div>

        <div class="title_box3" :class="{ notAdmin : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') }">
            <p class="desc">근태현황 시작일 : <span class="date" @click.prevent="setAttedanceStart">{{ getStart() == '' ? '근태현황 기능을 사용하려면 시작일을 설정해주세요' : hodu_date_to_format_string(yyyymmddToDate(getStart()), 'YYYY년 MM월 DD일') }}</span></p>
            <!-- <input type="button" class="input_btn" value="설정" @click.prevent="setAttedanceStart"/> -->
        </div>
        
        <ul class="sortHeader">
            <li class="num">No</li>
            <li class="type_title">타입명</li>
            <li class="days">요일 근무 여부</li>
            <li class="peep">사용 직원</li>
        </ul>

        <div id="organization_work_type_scroll" class="scroll">
            <div class="difTypeDiv">
                <ul class="difTypeUl">

                    <li :key="work_type.work_type_id" v-for="(work_type, index) in computedWorkTypes">
                        <p class="num">{{ index + 1 }}</p>
                        <p class="type_title">{{ work_type.work_type_name }}</p>
                        <div class="days">
                            <!-- 근무하는 날 p태그에 on/off 추가 -->
                            <p :class="{ on : day.am_from || day.pm_from || day.is_telecommuting == true, off : day.is_telecommuting == false && !day.am_from && !day.pm_from }" :key="day.week" v-for="day in work_type.contents">
                                <span class="day">{{ getDayName(day.week) }}</span>
                                <span class="dot" :class="{
                                    home : day.is_telecommuting == true, 
                                    morn : day.is_telecommuting == false && day.am_from && !day.pm_from, 
                                    night : day.is_telecommuting == false && !day.am_from && day.pm_from, 
                                    allday : day.is_telecommuting == false && day.am_from && day.pm_from 
                                    }">근무</span>
                            </p>
                        </div>
                        <p class="peep">
                            <!-- 추가한 사람이 없다면 a 태그에 addPeep 추가, 다음 a > span예제 보기 -->
                            <a href="#" @click.prevent="showOrganizationWorkTypeDetailModalInfo(work_type.work_type_id)" v-if="work_type.employees.length > 0">
                                <span class="img first" :style="{ backgroundImage : `url(${getProfileImage(work_type.employees[0])})` }"></span>
                                <span class="img last" :style="{ backgroundImage : `url(${getProfileImage(work_type.employees[1])})` }" v-if="work_type.employees.length > 1">
                                    <span v-if="work_type.employees.length > 2">+{{ work_type.employees.length - 2 }}</span>
                                </span>
                                <img class="dummy" :src="getProfileImage(work_type.employees[0])" @error="userImageError($event)" />
                                <img class="dummy last" :src="getProfileImage(work_type.employees[1])" @error="userImageError($event)" v-if="work_type.employees.length > 1"/>
                            </a>
                            <!--  addPeep 예제, 추가 클릭시 #organization_work_type_detail_modal .searchDiv on 넣고 직원검색에 포커스 넣기, 추 후 그룹 or 팀별로 검새가능한 필터도 좋을거같음 -->
                            <!-- <a href="#" class="addPeep" @click.prevent="showOrganizationWorkTypeDetailModalInfo(work_type.work_type_id)" v-else>
                                <span class="pls">+</span><span class="txt">추가</span>
                            </a> -->
                        </p>
                        <a href="#" class="seeDets" @click.prevent="showOrganizationWorkTypeDetailModalInfo(work_type.work_type_id)">자세히 보기</a>
                        <a href="#" class="editBtn" @click.prevent="showOrganizationWorkTypeAddModalInfo(false, work_type.work_type_id)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">수정</a>
                    </li>

                    <!-- <li>
                        <p class="num">1</p>
                        <p class="type_title">근무 타입 A</p>
                        <div class="days">
                            <p class="on">
                                <span class="day">일</span>
                                <span class="dot morn">근무</span>
                            </p>
                            <p class="on">
                                <span class="day">월</span>
                                <span class="dot night">근무</span>
                            </p>
                            <p class="on">
                                <span class="day">화</span>
                                <span class="dot allday">근무</span>
                            </p>
                            <p class="on">
                                <span class="day">수</span>
                                <span class="dot">근무</span>
                            </p>
                            <p class="off">
                                <span class="day">목</span>
                                <span class="dot">근무</span>
                            </p>
                            <p class="off">
                                <span class="day">금</span>
                                <span class="dot">근무</span>
                            </p>
                            <p class="off">
                                <span class="day">토</span>
                                <span class="dot">근무</span>
                            </p>
                        </div>
                        <p class="peep">
                            <a href="#">
                                <span class="img">김성림</span>
                                <span class="img last"><span>+3</span></span>
                            </a>
                        </p>

                        <a href="#" class="seeDets" @click.prevent="showOrganizationWorkTypeDetailModalInfo(1)">자세히 보기</a>
                    </li>
                    <li>
                        <p class="num">2</p>
                        <p class="type_title">파트 타임</p>
                        <div class="days">
                            <p class="off">
                                <span class="day">일</span>
                                <span class="dot">근무</span>
                            </p>
                            <p class="on">
                                <span class="day">월</span>
                                <span class="dot allday">근무</span>
                            </p>
                            <p class="off">
                                <span class="day">화</span>
                                <span class="dot">근무</span>
                            </p>
                            <p class="on">
                                <span class="day">수</span>
                                <span class="dot allday">근무</span>
                            </p>
                            <p class="off">
                                <span class="day">목</span>
                                <span class="dot">근무</span>
                            </p>
                            <p class="off">
                                <span class="day">금</span>
                                <span class="dot">근무</span>
                            </p>
                            <p class="off">
                                <span class="day">토</span>
                                <span class="dot">근무</span>
                            </p>
                        </div>
                        <p class="peep">
                            <a href="#" class="addPeep">
                                <span class="pls">+</span><span class="txt">추가</span>
                            </a>
                        </p>
                        <a href="#" class="seeDets" @click.prevent="showOrganizationWorkTypeDetailModalInfo(2)">자세히 보기</a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';
import { CommonDatepickerModalInfo } from '@/store/modules/ModalInfo';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationWorkTypeSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 해당 근무 타입을 사용하는 직원들 정보를 포함 시킨 근무타입 정보
     */
    get computedWorkTypes() : any[] {

        let work_types : any[] = JSON.parse(JSON.stringify(this.work_types));
        
        for( const work_type of work_types ) {
            if( work_type.employees == null ) work_type.employees = []; 
            work_type.employees.splice(0, work_type.employees.length);
            work_type.employees = work_type.employees.concat(this.employees.filter(employee => employee.work_type_id == work_type.work_type_id));
        }

        return work_types;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationWorkTypeDetailModalInfo ?: (params : organization_modal_interface.OrganizationWorkTypeDetailModalInfo) => void; 
    @ModalInfo.Action doSetOrganizationWorkTypeAddModalInfo    ?: (params : organization_modal_interface.OrganizationWorkTypeAddModalInfo) => void;
    @ModalInfo.Action doSetCommonDatepickerModalInfo ?: (params : CommonDatepickerModalInfo) => void;

    work_types : any[] = [];
    employees : any[] = [];
    
    async mounted() : Promise<void> {
        this.event_bus?.$on('OrganizationWorkTypeSettingResize', this.handleResize);

        await this.getTotalOrganizationData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {

            const title_box_height = $('#organization_setting .title_box').outerHeight();
            const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            const title_box3_height = $('#organization_setting .title_box3').outerHeight();
            const sort_header_height = $('#organization_setting #organization_work_type_setting .sortHeader').outerHeight();

            // @ts-ignore
            $('#organization_work_type_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                               - (title_box2_height ? title_box2_height : 0)
                                               - (title_box3_height ? title_box3_height : 0)
                                               - (sort_header_height ? sort_header_height : 0)
            });

        });
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationWorktype();
            await this.getOrganizationEmp();
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 근무 타입 조회
     */
    async getOrganizationWorktype() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_type_info ) {
                throw new Error("조직도 근무 타입 조회 중 오류 발생");
            }

            this.work_types.splice(0, this.work_types.length);
            this.work_types = this.work_types.concat(response.data.data.work_type_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);
        } catch(e) {
            throw e;
        }
    }

    /**
     * 근무 타입 상세 모달
     */
    showOrganizationWorkTypeDetailModalInfo(work_type_id : number) : void {
        console.log(work_type_id);
        this.doSetOrganizationWorkTypeDetailModalInfo?.({ show_modal : true, work_type_id : work_type_id, callback : this.getTotalOrganizationData });
    }

    /**
     * 근무 타입 추가 / 수정 모달
     */
    showOrganizationWorkTypeAddModalInfo(is_create : boolean, work_type_id : number = 0) : void {
        this.doSetOrganizationWorkTypeAddModalInfo?.({ show_modal : true, is_create : is_create, work_type_id : work_type_id, callback : this.getTotalOrganizationData });
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');

        console.log(replace_image);

        if( jquery_taget.hasClass('last') ) {
            jquery_taget.parent().find('span.img.last').css('background-image', `url(${replace_image})`);
        }
        else {
            jquery_taget.parent().find('span.img.first').css('background-image', `url(${replace_image})`);
        }
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 근태현황 시작일 설정
     */
    setAttedanceStart() : void {
        
        if( this.get_group_role(this.scope_group_id) != 'GROUP_MANAGER' && this.get_group_role(this.scope_group_id) != 'ADMIN' ) return;

        this.doSetCommonDatepickerModalInfo?.({
            show_modal : true,
            default_date : this.getStart() == '' ? new Date() : this.yyyymmddToDate(this.getStart()),
            callback : async(selected_date : Date) => {

                this.hodu_show_dialog("alert", `근태현황 시작일을 '${this.hodu_date_to_format_string(selected_date, 'YYYY년 MM월 DD일')}'로 설정 하시겠습니까?`, ['아니오', '예'], [
                    () => {},
                    async() => {
                        try {
                            const value = this.hodu_date_to_format_string(selected_date, "YYYYMMDD");

                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/attendance?key=start&feature_string=${value}`, API_METHOD.PUT);

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("근태 현황 시작일 설정 중 오류 발생");
                            }

                        }
                        catch(e) {
                            this.hodu_error_process(e, false, false, true);
                            this.hodu_show_dialog('cancel', '근태 현황 시작일 설정 중 오류 발생', ['확인']);
                        } finally {
                            this.get_group_role_service();
                        }
                    }
                ])

            }
        })
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_work_type_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>

    .title_box3 { position: relative; padding: 0 25px 0 38px !important; height :61px; line-height: 61px; overflow: hidden;border-bottom: 1px solid #e7e9ea; }
    .title_box3 .desc { font-weight: bold; font-size: 14px; display : inline-block; }
    .title_box3 .desc .date { display : inline-block; height : 100%; font-weight: normal; margin-left: 10px; }
    .title_box3 .desc .date:hover { color : #477fff; cursor : pointer; }
    .title_box3 .input_btn { position: absolute; top : 10.5px; right : 38px; }
    .title_box3.notAdmin .desc .date:hover { color : #232848; cursor : default; }

    .content .sub_title { padding: 30px 30px 25px; box-sizing: border-box; font-size: 14px; }

    .sortHeader {  font-weight: bold; }

    ul > li > p.num, #organization_setting .sortHeader > li.num { width: 10%; text-align:center; }
    ul > li > p.type_title, #organization_setting .sortHeader > li.type_title { width: 30%; box-sizing: border-box; padding-left: 30px; overflow: hidden; text-overflow:ellipsis; white-space:nowrap;  }
    ul > li > div.days, #organization_setting .sortHeader > li.days { width: 30%; box-sizing: border-box; padding-left: 30px; overflow: hidden; text-overflow:ellipsis; white-space:nowrap; }
    ul > li > p.peep, #organization_setting .sortHeader > li.peep { width: 30%; box-sizing: border-box;  padding-left: 30px; overflow: hidden; text-overflow:ellipsis; white-space:nowrap; }

    .difTypeUl > li { position: relative; transition: 0.2s; overflow: hidden; height: 75px; line-height: 75px; border-bottom: 1px solid #f1f3f5; }
    .difTypeUl > li .seeDets { z-index: 10; position: absolute; left: 0; top:0; width: 100%; height: 100%;font-size: 0;  }
    .difTypeUl > li:hover {  background:#f6f9fb; } 
    .difTypeUl > li .type_title { font-size: 14px; font-weight: bold; }
    .difTypeUl > li > * { display: inline-block; float: left; }
    .difTypeUl .days > * { display: inline-block; width: 30px; text-align: center; }
    .difTypeUl .days p:first-child { margin-left: -10px; }
    .difTypeUl .days > * span { display: block; margin: 0 auto; line-height: 55px; }
    .difTypeUl .days > p span.dot { width: 10px; height: 10px; border-radius: 50%; font-size: 0; background: #c0cad0; margin-top: -12px; }
    .difTypeUl .days > p.off .day { opacity: 0.5; }
    .difTypeUl .days > p.on .day { opacity: 1; font-weight: bold; }
    .difTypeUl .days > p.on span.dot { background: #477fff; } 

    .difTypeUl .days > p.on span.morn {background: #ffc00e !important;border-color: #f3b300;}
    .difTypeUl .days > p.on span.night {background: #846eff !important;border-color: #6c52ff;}
    .difTypeUl .days > p.on span.allday {background: #477fff !important;border-color: #477fff;}
    .difTypeUl .days > p.on span.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .difTypeUl .days > p.on span.home { background : #13D08B !important; border-color: #13D08B; }

    .difTypeUl .peep a { display: inline-block; position: relative; z-index: 15; }
    .difTypeUl .peep a .img { position: relative; float: left; display: inline-block; width: 40px; height: 40px; border-radius: 50%; font-size: 0; border: 1px solid #f1f3f5; margin-top: 16px; background-color: #f1f3f5; background-size: cover; background-repeat: no-repeat; background-position: center center; }
    .difTypeUl .peep a .img.last { margin-left: -13px; font-size: 12px; }
    .difTypeUl .peep a .img.last span { position: absolute;top: 0; left: 0;  z-index: 15; width: 100%; height: 100%; line-height: 40px; text-align: center; color:#fff; font-size: 14px; font-weight: bold; background:rgba(0,0,0,0.3); display: block; border-radius: 50%;  }
    
    .difTypeUl .peep .addPeep:hover * { color:#232848; font-weight: bold; }
    .difTypeUl .peep .addPeep:hover .pls { background:#477fff; border-color:#477fff; color:#fff; }
    .difTypeUl .peep .addPeep * { color:#9fa8ad; }
    .difTypeUl .peep .addPeep .pls { transition: 0.2s; float: left; display: inline-block; width: 32px; height: 32px; line-height: 30px; box-sizing: border-box; font-size: 21px; border-radius: 50%; border:1px solid #c0cad0; background:#fff;  text-align: center; margin: 20px 7px 0 0  }

    .difTypeUl li:hover .editBtn { opacity: 1; }
    .editBtn { opacity: 0; transition: 0.1s; z-index: 100; position: absolute; right: 30px; top:50%; margin-top:-15px; height: 30px; padding:0 15px; background:#ffc00e; line-height: 30px; border-radius: 20px; font-weight: bold; }
    .editBtn:hover {background:#ffce44}

    /* #addOtherWorkHour { position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 10000000;-webkit-transition: 0.2s;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(/img/btn_add_fff.ffacba40.png) no-repeat center center !important;-webkit-box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;}  */
    /* #addOtherWorkHour:hover {-webkit-box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5) !important;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5) !important;} */

    img.dummy { display : none; }

    /* 개발 시 삭제 */
    /* .difTypeUl .peep a .img { background-image: url('../../assets/images/footer/f1.jpg'); }
    .difTypeUl .peep a .img.last { background-image: url('../../assets/images/footer/f2.jpg'); } */
</style>