<template>
    <div id="attendance_total_list_year" class="section_scroll">
        <div class="yearViewGroup">
            <div class="schedule_box">
                <div class="grp settingBg">

                    <div class="yearSelBox">
                        <!-- +- 3~5년정도로 생산 -->
                        <select name="YEAR" id="yearSel" class="select" v-model="selected_year">
                            <option :key="year" v-for="year in computedYears">{{ year }}</option>
                        </select>
                    </div>

                    <div class="secondBox">
                        <div class="teamLeft">
                            <input type="button" class="sort1" :value="getDeptName(selected_dept_id)">
                            <ul class="dropdown">

                                <li>
                                    <a href="#" :class="{ 'on' : selected_dept_id == -1 }" @click.prevent="() => { selected_dept_id = -1 }">
                                        전체 부서
                                    </a>
                                </li>
                                
                                <li :key="department.dept_id" v-for="department in departments">
                                    <a href="#" :class="{ 'on' : selected_dept_id == department.dept_id }" @click.prevent="() => { selected_dept_id = department.dept_id }">
                                        {{ department.dept_name }}
                                    </a>
                                </li>

                                <!-- <li>
                                    <a href="#">마케팅</a>
                                </li>
                                <li>
                                    <a href="#">기획팀</a>
                                </li>
                                <li>
                                    <a href="#">디자인팀</a>
                                </li>
                                <li>
                                    <a href="#">개발팀</a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="downRight">
                            <input type="button" value="다운로드" class="input_btn download_btn" @click.prevent="excelDownload">
                        </div>
                    </div>
                </div>
            </div>

            <div id="attendance_total_list_year_scroll" class="content">
                <div class="monthDiv yearDiv">
                    <div class="leftDiv">
                        <div class="nameDiv">
                            <div class="sortHeader">
                                <p class="headerSort sortOff">이름</p>
                            </div>
                            <div id="attendance_list_year_left_scroll" class="leftScroll">
                                <!-- 여기에 스크롤 넣고 같이 내려가게 해주세요 기존 리스트 처럼 -->
                                <ul>

                                    <li :key="employee.user_id" v-for="employee in year_emp_attend_info">
                                        <p class="img" :style="{ 'background-image' : `url(${getProfileImage(employee)})` }">사진</p>
                                        <img class="dummy" :src="getProfileImage(employee)" @error="userImageError($event)"/>
                                        <p class="name">{{ employee.user_name }}</p>
                                        <p class="pos">{{ employee.pos_name }}</p>
                                        <p class="tem">{{ employee.dept_name }}</p>
                                    </li>

                                    <!-- <li>
                                        <p class="img img01">사진</p>
                                        <p class="name">김호두</p>
                                        <p class="pos">과장</p>
                                        <p class="tem">마케팅</p>
                                    </li>
                                    <li>
                                        <p class="img">사진</p>
                                        <p class="name">김사원</p>
                                        <p class="pos">사원</p>
                                        <p class="tem">개발</p>
                                    </li>
                                    <li>
                                        <p class="img">사진</p>
                                        <p class="name">이기획</p>
                                        <p class="pos">대리</p>
                                        <p class="tem">마케팅</p>
                                    </li>
                                    <li>
                                        <p class="img">사진</p>
                                        <p class="name">정광고</p>
                                        <p class="pos">사원</p>
                                        <p class="tem">마케팅</p>
                                    </li>
                                    <li>
                                        <p class="img">사진</p>
                                        <p class="name">정사원</p>
                                        <p class="pos">사원</p>
                                        <p class="tem">디자인</p>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="totalDiv">
                            <div class="totalHeader">
                                <p>항목&#47;합계</p>
                            </div>
                            <div id="totalCon_year_left_scroll" class="totalCon">
                                <ul>

                                    <li :key="employee.user_id" v-for="employee in year_emp_attend_info">
                                        <div class="totalListDiv">
                                            <p class="item">연차</p>
                                            <!-- <p class="number">12 <span>&#47; 16</span></p> -->
                                            <p class="number" v-if="employee.use_annual_count == null || employee.use_annual_count == ''">{{ getTotalVacationCount(employee) }}</p>
                                            <p class="number" v-else>{{ employee.use_annual_count.split('/')[0] }}<span>&#47; {{ employee.use_annual_count.split('/')[1] }}</span></p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">지각</p>
                                            <p class="number">{{ getTotalLateCount(employee) }}</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">출장</p>
                                            <p class="number">{{ getTotalBusinessTripCount(employee) }}</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">결근</p>
                                            <p class="number">{{ getTotalAwolCount(employee) }}</p>
                                        </div>
                                    </li>
                                    
                                    <!-- <li>
                                        <div class="totalListDiv">
                                            <p class="item">연차</p>
                                            <p class="number">12 <span>&#47; 16</span></p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">지각</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">출장</p>
                                            <p class="number">7</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">결근</p>
                                            <p class="number">0</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="totalListDiv">
                                            <p class="item">연차</p>
                                            <p class="number">11 <span>&#47; 15</span></p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">지각</p>
                                            <p class="number">3</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">출장</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">결근</p>
                                            <p class="number">0</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="totalListDiv">
                                            <p class="item">연차</p>
                                            <p class="number">11 <span>&#47; 15</span></p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">지각</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">출장</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">결근</p>
                                            <p class="number">0</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="totalListDiv">
                                            <p class="item">연차</p>
                                            <p class="number">12 <span>&#47; 16</span></p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">지각</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">출장</p>
                                            <p class="number">7</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">결근</p>
                                            <p class="number">0</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="totalListDiv">
                                            <p class="item">연차</p>
                                            <p class="number">12 <span>&#47; 16</span></p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">지각</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">출장</p>
                                            <p class="number">7</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">결근</p>
                                            <p class="number">0</p>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="rightDiv">
                        <div class="rightScroll">
                            <ul class="mnthUl head">
                                <li>
                                    <div class="topDiv">
                                        <p class="date">1</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">2</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">3</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">4</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">5</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">6</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">7</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">8</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">9</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">10</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">11</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">12</p>
                                        <p class="month">월</p>
                                    </div>
                                </li>
                            </ul>
                            <!-- slice(-2) -->
                            <div id="attendance_list_year_right_scroll">

                                <div>

                                    <div class="rightCon" :key="employee.user_id" v-for="employee in year_emp_attend_info">
                                        <ul class="mnthUl cont">
                                            <li :key="`0${n}`.slice(-2)" v-for="n in 12">
                                                {{ String(getVacationCount(employee, `0${n}`.slice(-2))) }}
                                            </li>
                                        </ul>
                                        <ul class="mnthUl cont">
                                            <li :key="`0${n}`.slice(-2)" v-for="n in 12">
                                                {{ String(getLateCount(employee, `0${n}`.slice(-2))) }}
                                            </li>
                                        </ul>
                                        <ul class="mnthUl cont">
                                            <li :key="`0${n}`.slice(-2)" v-for="n in 12">
                                                {{ String(getBusinessTripCount(employee, `0${n}`.slice(-2))) }}
                                            </li>
                                        </ul>
                                        <ul class="mnthUl cont">
                                            <li :key="`0${n}`.slice(-2)" v-for="n in 12">
                                                {{ String(getAwolCount(employee, `0${n}`.slice(-2))) }}
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                                <!-- <div class="rightCon">
                                    <ul class="mnthUl cont">
                                        <li>1</li>
                                        <li>1</li>
                                        <li>1</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>3</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>2</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                </div>
                                <div class="rightCon">
                                    <ul class="mnthUl cont">
                                        <li>1</li>
                                        <li>1</li>
                                        <li>1</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>3</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>2</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                </div>
                                <div class="rightCon">
                                    <ul class="mnthUl cont">
                                        <li>3</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>1.5</li>
                                        <li>3</li>
                                        <li>0.5</li>
                                        <li>0.5</li>
                                        <li>1.5</li>
                                        <li>1.5</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>1</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>1</li>
                                        <li>2</li>
                                        <li>2</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                </div>
                                <div class="rightCon">
                                    <ul class="mnthUl cont">
                                        <li>3</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>1.5</li>
                                        <li>3</li>
                                        <li>0.5</li>
                                        <li>0.5</li>
                                        <li>1.5</li>
                                        <li>1.5</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>1</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>1</li>
                                        <li>2</li>
                                        <li>2</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                </div> -->
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import moment from 'moment';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        
    },
})
export default class AttendanceTotalListYear extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    get computedYears() {

        let years : any[] = [];

        const current_year = new Date().getFullYear();

        for( let i = (current_year - 5); i <= (current_year + 5); i++ ) {
            years.push(i);
        }

        return years;
    }

   /**
     * @AttendanceInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @AttendanceInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트

    year_emp_attend_info : any[] = [];
    departments : any[] = [];

    selected_year : string = '';
    selected_dept_id : number = -1;

    header_width : number = 0;
    resize_function_submit : boolean = false;

    async mounted() {

        $(".teamLeft").click(function(){
            $(".dropdown").toggleClass("on");
        });

        this.selected_year = moment(this.selected_date).format('YYYY');

        await this.getTotalData();

        this.setScroll();

        // $(document).ready(function(){
        //     setYeareBox();
        // });
        // // select box 연도
        // function setYeareBox(){
        //     let dt = new Date();
        //     let com_year = dt.getFullYear();
        //     // 현재 년도 자동 선택
        //     $("#yearSel").append("<option value='" + com_year + "' selected>" + com_year + "년</option>");
        //      // 올해 기준으로 -10년부터 +0년을 보여준다.
        //     for(var y = (com_year-10); y <= (com_year+0); y++){
        //         $("#yearSel").append("<option value='" + y + "'>" + y + "년</option>");
        //     }
        // }

        // var $divs = $('#attendance_list_year_left_scroll, #totalCon_year_left_scroll');
        // var sync = function(e){
        //     var $other = $divs.not(this).off('scroll'), other = $other.get(0);
        //     var percentage = this.scrollTop / (this.scrollHeight - this.offsetHeight);
        //     other.scrollTop = percentage * (other.scrollHeight - other.offsetHeight);
        //     setTimeout( function(){ $other.on('scroll', sync ); },10);
        // }
        // $divs.on( 'scroll', sync);
    }

    /**
     * 스크롤 설정
     */
     setScroll() : void {

        this.$nextTick(() => {
            
            const title_height = $('#attendance .title_box').outerHeight();
            const schedule_box_height = $('#attendance_total_list_year .schedule_box').outerHeight();
            const sort_header_height = $('#attendance_total_list_year .sortHeader').outerHeight();

            console.log(`${title_height}px ${schedule_box_height}px ${sort_header_height}px`)

            const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                    - (schedule_box_height ? schedule_box_height : 0)
                                                    - (sort_header_height ? sort_header_height : 0) - 10;

            // @ts-ignore
            $('#attendance_list_year_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_year_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#totalCon_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#attendance_list_year_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            // @ts-ignore
            $('#totalCon_year_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#totalCon_year_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#attendance_list_year_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                        
                    }
                }
            });

            // @ts-ignore
            $('#attendance_list_year_right_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_year_right_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#totalCon_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            
            this.event_bus.$on('totalListYearResize', this.handleResize);
        });

     }

    /**
     * attend data 가져오기
     */
    @Watch('selected_dept_id')
    @Watch('selected_year')
    async getTotalData() : Promise<void> {
        try {
            await this.hodu_show_indicator();

            // @ts-ignore
            // $('#attendance_list_month_right_scroll').mCustomScrollbar('destroy');

            // let month_start = moment(`${this.date_string.substring(0,4)}.01.01`).toDate();
            // let month_end   = moment(month_start).add('year', 1).set('date', 0).toDate();

            // await Promise.all([this.getLunar(month_start, month_end), this.getHolidays(month_start, month_end), this.getOrganization()]);
            
            await this.getOrganization()
            await this.getEmpAttendDataYearInfo();

            // this.$nextTick(() => {
                
            //     this.setScroll();

            //     if( this.date_string == this.hodu_date_to_format_string(new Date(), 'YYYY.MM') ) {
            //         const last_date = new Date();
            //         last_date.setMonth(last_date.getMonth() + 1);
            //         last_date.setDate(0);
            //         const width = document.getElementById('month_right_scroll_ul')?.offsetWidth ?? 0;
            //         $('#month_right_scroll').scrollLeft((width * (new Date().getDate() / last_date.getDate())) - 60);
            //     }
            //     else {
            //         $('#month_right_scroll').scrollLeft(0);
            //     }
            // });            

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 이벤트(월 달력 일별 근태현황) 조회
     */
    async getEmpAttendDataYearInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/data/year?year=${this.selected_year}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.year_emp_attend_info ) {
                throw new Error("연별 통합 리스트 근태현황 조회 실패");
            }

            if( this.selected_dept_id >= 0 ) {
                response.data.data.year_emp_attend_info = response.data.data.year_emp_attend_info.filter(emp => emp.dept_id == this.selected_dept_id);
            }
            
            this.year_emp_attend_info.splice(0, this.year_emp_attend_info.length);
            this.year_emp_attend_info = this.year_emp_attend_info.concat(response.data.data.year_emp_attend_info);

            // 근태현황 시작일 관련 처리
            for( const emp of this.year_emp_attend_info ) {

                if( emp.late != null ) {
                    for( const key in emp.late ) {
                        // 근태현황 시작일이 적용 안됐다면 전부 제외
                        if( this.getAttendanceStart() == '' ) {
                            delete(emp.late[key]);
                            continue;
                        }

                        // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                        if( Number(this.getAttendanceStart()) > Number(key) ) {
                            delete(emp.late[key]);
                            continue;
                        }
                    }
                }

                if( emp.awol != null ) {
                    for( const key in emp.awol ) {
                        // 근태현황 시작일이 적용 안됐다면 전부 제외
                        if( this.getAttendanceStart() == '' ) {
                            delete(emp.awol[key]);
                            continue;
                        }

                        // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                        if( Number(this.getAttendanceStart()) > Number(key) ) {
                            delete(emp.awol[key]);
                            continue;
                        }
                    }
                    
                }

                if( emp.vacation != null ) {
                    for( const key in emp.vacation ) {
                        // 근태현황 시작일이 적용 안됐다면 전부 제외
                        if( this.getAttendanceStart() == '' ) {
                            delete(emp.vacation[key]);
                            continue;
                        }

                        // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                        if( Number(this.getAttendanceStart()) > Number(key) ) {
                            delete(emp.vacation[key]);
                            continue;
                        }
                    }
                }

                if( emp.business_trip != null ) {
                    for( const key in emp.business_trip ) {
                        // 근태현황 시작일이 적용 안됐다면 전부 제외
                        if( this.getAttendanceStart() == '' ) {
                            delete(emp.business_trip[key]);
                            continue;
                        }

                        // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                        if( Number(this.getAttendanceStart()) > Number(key) ) {
                            delete(emp.business_trip[key]);
                            continue;
                        }
                    }
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getAttendanceStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    getDeptName(dept_id : number) : string {

        if( dept_id == null || dept_id < 0 ) {
            return '전체 부서';
        }

        const filter = this.departments.filter(dept => dept.dept_id == dept_id);
        
        if( filter.length < 1 ) {
            return '';
        }

        return filter[0].dept_name;
    }

    async excelDownload() {
        
        if( this.year_emp_attend_info.length < 1 ) {
            return;
        }

        try {

            const months : any[] = [];
            const employees : any[] = [];

            for( let i = 1; i <= 12; i++ ) {
                months.push({
                    "month_string": `${i}월`,
                    "month_string2": `0${i}`.slice(-2),
                    "month": i
                });
            }

            for( const emp of this.year_emp_attend_info ) {

                const employee : any = {
                    "obj" : emp,
                    "user_name" : emp.user_name,
                    "pos_name" : emp.pos_name,
                    "dept_name" : emp.dept_name,
                    "total": {
                        "vacation": (emp.use_annual_count == null || emp.use_annual_count == '') ? this.getTotalVacationCount(emp) : emp.use_annual_count,
                        "late": this.getTotalLateCount(emp),
                        "business_trip": this.getTotalBusinessTripCount(emp),
                        "awol": this.getTotalAwolCount(emp)          
                    },
                    "attend": {}
                };

                for( const month of months ) {
                    
                    employee.attend[String(month.month_string2)] = {
                        "vacation": this.getVacationCount(emp, month.month_string2),
                        "late": this.getLateCount(emp, month.month_string2),
                        "business_trip": this.getBusinessTripCount(emp, month.month_string2),
                        "awol": this.getAwolCount(emp, month.month_string2)  
                    }

                }

                employees.push(employee);
            }

            const body = {
                "name" : encodeURIComponent(`${this.selected_year}_호두_연별_통합_리스트.xlsx`),
                "sheet_name" : this.selected_year,
                "months" : months, 
                "emp" : employees
            }

            console.log(body);

            const excel_response = await this.$http({
                url : "api/v1/excel/attend/total_list_year",
                method : 'POST',
                responseType: 'blob',
                headers : {
                    "Cache-Control" : "no_cache"
                },
                data : body
            });

            console.log(excel_response);

            const blob = new Blob([excel_response.data]);

            // @ts-ignore
            if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, decodeURIComponent(body.name)); // for IE
                return;
            }

            await this.hodu_download_blob(blob, decodeURIComponent(body.name));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }

        
    }

    /**
     * count 지각
     */
    getLateCount(employee, month) {

        if( employee.late == null ) {
            return 0;
        }

        const keys = Object.keys(employee.late);
        const month_keys = keys.filter(key => new RegExp(`${this.selected_year}${month}`).test(key) &&
                                              new RegExp(/[0-9]{8}/ig).test(key));

        let count = 0;

        for( const month_key of month_keys ) {
            const obj_array = employee.late[month_key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 결근
     */
    getAwolCount(employee, month) {

        if( employee.awol == null ) {
            return 0;
        }

        const keys = Object.keys(employee.awol);
        const month_keys = keys.filter(key => new RegExp(`${this.selected_year}${month}`).test(key) &&
                                              new RegExp(/[0-9]{8}/ig).test(key));

        let count = 0;

        for( const month_key of month_keys ) {
            const obj_array = employee.awol[month_key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 연차
     */
    getVacationCount(employee, month) {

        if( employee.vacation == null ) {
            return 0;
        }

        const keys = Object.keys(employee.vacation);
        const month_keys = keys.filter(key => new RegExp(`${this.selected_year}${month}`).test(key) &&
                                              new RegExp(/[0-9]{8}/ig).test(key));
        
        console.log(month_keys);

        let count = 0;

        for( const month_key of month_keys ) {
            const obj_array = employee.vacation[month_key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 출장
     */
    getBusinessTripCount(employee, month) {

        if( employee.business_trip == null ) {
            return 0;
        }

        const keys = Object.keys(employee.business_trip);
        const month_keys = keys.filter(key => new RegExp(`${this.selected_year}${month}`).test(key) &&
                                              new RegExp(/[0-9]{8}/ig).test(key));
        
        console.log(month_keys);

        let count = 0;

        for( const month_key of month_keys ) {
            const obj_array = employee.business_trip[month_key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 지각
     */
    getTotalLateCount(employee) {

        if( employee.late == null ) {
            return 0;
        }

        const keys = Object.keys(employee.late);

        let count = 0;

        for( const key of keys ) {
            const obj_array = employee.late[key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 결근
     */
    getTotalAwolCount(employee) {

        if( employee.awol == null ) {
            return 0;
        }

        const keys = Object.keys(employee.awol);

        let count = 0;

        for( const key of keys ) {
            const obj_array = employee.awol[key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 연차
     */
    getTotalVacationCount(employee) {

        if( employee.vacation == null ) {
            return 0;
        }

        const keys = Object.keys(employee.vacation);

        let count = 0;

        for( const key of keys ) {
            const obj_array = employee.vacation[key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 출장
     */
    getTotalBusinessTripCount(employee) {

        if( employee.business_trip == null ) {
            return 0;
        }

        const keys = Object.keys(employee.business_trip);

        let count = 0;

        for( const key of keys ) {
            const obj_array = employee.business_trip[key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_taget.parent().find('p.img').css('background-image', `url(${replace_image})`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#attendance_list_year_left_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#totalCon_year_left_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#attendance_list_year_right_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

}
</script>

<style scoped>
    #attendance_total_list_year .grp.settingBg { height: auto; }

    .yearSelBox { height: 61px; line-height: 61px; }
    #attendance_total_list_year .grp.settingBg select { position: static; margin-left: 30px; font-size: 19px; color: #202a39; font-weight: bold; }

    .secondBox { width: 100%; display: flex; justify-content: space-between; box-sizing: border-box; height: 60px; line-height: 60px; }
    .teamLeft { padding: 0 33px; position: relative; width: auto; box-sizing: border-box; }
    .teamLeft input[type='button'] { font-size: 12px; font-weight: bold; background: url('../../assets/images/side_area/bt_open.png') no-repeat right center; line-height: 60px; width: auto; float: left; text-align: left; padding-right: 28px; color: #232848; }
    .teamLeft input[type='button']:focus { background-image: url('../../assets/images/side_area/bt_close.png'); }
    .teamLeft .dropdown { max-height: 230px; overflow-y: auto; top: 51px; }
    .downRight .download_btn { background-image: url('../../assets/images/contents/download_btn.png') !important; padding-left: 45px !important; margin-right: 30px; background-repeat: no-repeat !important; background-size: 17px auto !important; background-position: 15px center !important; }

    .monthDiv { position: relative; min-height: 100px; border-top: 1px solid #e7e9ea; }
    .leftDiv { border-right: 1px solid #e7e9ea; width: 330px; height: auto; box-sizing: border-box; position: absolute; left: 0; top: 0; display: flex; justify-content: space-between; }
    .nameDiv { width: 200px; border-right: 1px solid #e7e9ea; box-sizing: border-box; }
    .leftDiv .sortHeader { box-sizing: border-box; padding: 0 15px; }
    .leftDiv .sortHeader .headerSort { background: none; cursor: default; }
    .leftDiv .headerSort.sortOff:hover { cursor: default !important; text-indent: 0px; color: #232848; font-weight: initial; }

    /* 스크롤 임시 */
    #attendance_list_year_left_scroll { max-height: 678px; overflow-y: auto; } 
    #totalCon_year_left_scroll, #attendance_list_year_right_scroll { height: 678px; overflow-y: auto; -ms-overflow-style: none; scrollbar-width: none; }
    #totalCon_year_left_scroll::-webkit-scrollbar, #attendance_list_year_right_scroll::-webkit-scrollbar { display: none; /* Chrome, Safari, Opera*/ }

    /* .leftScroll ul { margin-bottom: 100px; } */
    .leftScroll ul li { position: relative; border-bottom: 2px solid #e7e9ea; height: 165px; padding-left: 80px; padding-right: 15px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; box-sizing: border-box; }
    .leftScroll ul li p { line-height: 20px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .leftScroll ul li p.img { position: absolute; width: 37px; height: 37px; border-radius: 50%; background: #f1f3f5; border: 1px solid #f1f3f5; box-sizing: border-box; font-size: 0; top: 15px; left: 20px; background-position: center center; background-size: cover; background-repeat: no-repeat; }
    .leftScroll ul li p.img01 { background-image: url('../../assets/images/contents/groupImg.png')  }
    .leftScroll ul li p.name { font-size: 13px; font-weight: bold; height: 35px; line-height: 35px; margin: 10px 0 5px 0; }
    .leftScroll ul li p.pos { font-weight: bold; opacity: .5; }
    
    .totalDiv { flex-grow: 1; box-sizing: border-box; }
    .totalDiv .totalHeader { line-height: 60px; height: 60px; border-bottom: 1px solid #e7e9ea; font-size: 13px; padding: 0 15px; box-sizing: border-box; }
    .totalCon ul li { height: 165px; line-height: 40px; border-bottom: 2px solid #e7e9ea; box-sizing: border-box; font-weight: bold; }
    .totalListDiv { display: flex; border-bottom: 1px solid #e7e9ea; padding: 0 15px; box-sizing: border-box; }
    .totalListDiv:last-child { border-bottom: none; }
    .number { margin-left: 6px; color: #477fff; }

    .rightDiv { box-sizing: border-box; width: 100%; padding-left: 330px; height: 100%; }
    .rightScroll { width: 100%; height: 100%; overflow: hidden; /* overflow-x: scroll; */ }
    .rightScroll .mnthUl { overflow: hidden; display: flex; justify-content: space-between; height: 60px; line-height: 60px; border-bottom: 1px solid #e7e9ea; box-sizing: border-box; }
    .mnthUl li { flex-grow: 1; border-right: 1px solid #e7e9ea; width: 8.33333%; text-align: center; box-sizing: border-box; }
    .mnthUl .topDiv { display: flex; justify-content: center; }

    .rightCon { height: 165px; border-bottom: 2px solid #e7e9ea; box-sizing: border-box; }
    .rightScroll .mnthUl.cont { height: 41px; line-height: 41px; }
    .rightScroll .mnthUl.cont:last-child { border-bottom: none; }

    ::-webkit-scrollbar{ width: 6px; height : 10px; }
    ::-webkit-scrollbar-track{background-color:  #e7e9ea;}
    ::-webkit-scrollbar-thumb{background-color: #232848;transition: 0.5s;}

</style>    