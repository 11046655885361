<template>
    <div id="approval_form_modify_modal" class="modal">

        <div class="titleBox">
            <h1 class="borderbot">양식 수정</h1>
        </div>

        <div class="content" v-if="template != null">
            <div class="titleDiv">
                <div class="first_title">
                    <h2>양식 이름</h2>
                    <input type="text" id="form_name" class="input_modal_content" placeholder="양식 이름을 입력해주세요" :value="template.template_name" @input="inputFormName($event)">
                </div>
                <div class="second_title">
                    <h2>보존기간</h2>
                    <select id="form_keep_year" v-model="template.contents.keep_year">
                        <option value="1">1년</option>
                        <option value="2">2년</option>
                        <option value="3">3년</option>
                        <option value="4">4년</option>
                        <option value="5">5년</option>
                    </select>
                </div>
            </div>

            <div class="titleDiv">
                <h2>양식 분류</h2>
                <select v-model="template.template_class_id">
                    <option :key="template_class.template_class_id" v-for="template_class of template_classes" :value="template_class.template_class_id">
                        {{ template_class.template_class_name }}
                    </option>
                </select>
                <!-- <input type="text" id="form_name" placeholder="양식 이름을 입력해주세요" class="input_modal_content"> -->
            </div>

            <div class="titleDiv">
                <h2>양식 파일</h2>
                <!-- <input type="text" id="form_file_name" class="input_modal_content" :value="template.contents.file_name" @input="inputFormFileName($event)"> -->
                <input type="text" id="form_file_name" class="input_modal_content" :class="{ hasFile : new_file != null }" :value="new_file ? new_file.name : template.contents.file_name" disabled>
                <input type="file" id="form_file" :accept="accept_mime_type" @change="fileChangeFunction($event)"/>
                <input type="button" id="form_file_change_cancel" value="변경취소" @click.prevent="cancelFileChange" v-if="new_file != null" />
                <input type="button" id="form_file_button" value="파일변경" @click.prevent="fileChange" />
            </div>
        </div>

        <div class="btns">
            <input type="button" value="취소" @click.prevent="close"/>
            <input type="button" value="저장" @click.prevent="save"/>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { approval_interface, approval_modal_interface } from '@/model/approval';
import { t_event_file } from '@/model/event';

@Component({
    components: {
        
    },
})
export default class ApprovalFormModifyModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State approval_form_modify_modal_info !: approval_modal_interface.ApprovalFormModifyModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalFormModifyModalInfo ?: (params : approval_modal_interface.ApprovalFormModifyModalInfo) => void;

    template : approval_interface.t_approval_template | null = null;
    template_classes : approval_interface.t_approval_template_class[] = [];
    accept_mime_type = ".pdf, .csv, .xls, .xlsx, .doc, .docx, .rtf";

    new_file : File | null = null;

    async mounted() : Promise<void> {
        await this.getApprovalTemplateClasses();
        await this.getApprovalTemplateInfo();
    }   

    async getApprovalTemplateClasses() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.GET, null, false);
            
            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_templates ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }
            
            this.template_classes.splice(0, this.template_classes.length);
            this.template_classes = this.template_classes.concat(response.data.data.approval_templates.filter(template => template.template_state == '0' || template.template_state == '10'));

            // 분류 없음이 가장 먼저 나옴
            this.template_classes.sort((o1, o2) : number => {
                if( o1.template_state > o2.template_state ) return 1;
                else if( o1.template_state < o2.template_state ) return -1;
                else if( o1.template_class_id > o2.template_class_id ) return 1;
                else if( o1.template_class_id < o2.template_class_id ) return -1;
                return 0;
            });
            
        } catch(e) {
            throw e;
        }
    }

    /**
     * 양식 정보 조회
     */
    async getApprovalTemplateInfo() : Promise<void> {

        try {

            const template_class_id = this.approval_form_modify_modal_info.template_class_id;
            const template_id = this.approval_form_modify_modal_info.template_id;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form?template_class_id=${template_class_id}&template_id=${template_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_template_info ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }

            this.template = response.data.data.approval_template_info;

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("전자결재 양식 정보 조회 중 오류 발생");
            this.approval_form_modify_modal_info.callback?.();
            this.close();
        }

    }

    /**
     * 양식 이름 수정
     */
    inputFormName(event) : void {
        if( this.template == null ) return;
        this.template.template_name = event.target.value;
        if( $('#form_name').hasClass("wrong") ) $('#form_name').removeClass("wrong");
    }
    
    /**
     * 양식 파일명 수정
     */
    // inputFormFileName(event) : void {
    //     if( this.template == null ) return;
    //     this.template.contents.file_name = event.target.value;
    //     if( $('#form_file_name').hasClass("wrong") ) $('#form_file_name').removeClass("wrong");
    // }

    /**
     * 파일 수정
     */
    fileChange() : void {
        if( this.template == null ) return;
        $('#form_file').trigger('click');
    }

    /**
     * 파일 수정 기능
     */
    fileChangeFunction(event) : void {
        console.log(event);

        if( this.template == null || event.target.files.length < 1 ) {
            $('#form_file').val("");
            return;
        }

        for ( const file of event.target.files ) {

            if( file.size > this.DEFAULT_FILE_MAX_SIZE ) {
                alert(`${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`);
                $('#form_file').val("");
                this.new_file = null;
                return;
            }

            // 확장자가 없는 파일
            if( file.name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#form_file').val("");
                this.new_file = null;
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(file.name.substring(file.name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ file.name.substring(file.name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#form_file').val("");
                this.new_file = null;
                return;
            }

            // TODO 전용 확장자 추가 확인 (어느 순간부터 확장자 제한을 해도 브라우저 파일 탐색기에서 모든 파일을 볼수있게 변경됐음)

            this.new_file = file;
            break;
        }

    }


    /**
     * 파일 변경 취소
     */
    cancelFileChange() : void {
        this.new_file = null;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetApprovalFormModifyModalInfo?.({
            show_modal : false,
            template_class_id : 0,
            template_id : 0
        });
    }

    /**
     * 유효성 검사
     */
    checkValid() : boolean {

        if( this.template == null ) return false;

        if( this.template.template_name.trim().length < 1 ) {
            alert("양식 이름을 입력 해주세요");
            $('#form_name').addClass("wrong");
            return false;
        }

        // if( this.template.contents.file_name.trim().length < 1 ) {
        //     alert("양식 파일명을 입력 해주세요");
        //     $('#form_file_name').addClass("wrong");
        //     return false;
        // }

        return true;
    }

    /**
     * 양식 수정
     */
    async save() : Promise<void> {

        try {
            
            if( this.template == null ) return;

            if( this.checkValid() == false ) return;

            let temp_file : t_event_file | null = null;
            if( this.new_file != null ) {
                const form_data : FormData = new FormData();
                form_data.append('file', this.new_file);

                try {
                    const response = await this.hodu_temp_upload(form_data);
                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.temp_files || response.data.data.temp_files.length < 1 ) {
                        throw new Error("양식 파일 임시 업로드 실패");
                    }

                    const temp_files : t_event_file[] = response.data.data.temp_files;
                    temp_file = temp_files[0];

                    this.template.contents.file_name = temp_file.name;
                    this.template.contents.file_size = temp_file.size;
                    this.template.contents.mime_type = temp_file.mimeType;

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    alert("양식 파일 업로드 중 오류 발생");
                    return;
                }
            }

            this.template.contents.keep_year = Number(this.template.contents.keep_year);
            this.template.template_class_id = Number(this.template.template_class_id);

            const template_class_id = this.approval_form_modify_modal_info.template_class_id;
            const template_id = this.approval_form_modify_modal_info.template_id;

            // 파일 변경 했다면
            if( temp_file ) this.template['file'] = temp_file;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template_class_id}/${template_id}?is_approver_modify=false`, API_METHOD.PUT, this.template);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("양식 정보 수정 중 오류 발생");
            }

            this.approval_form_modify_modal_info.callback?.();
            this.close();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("양식 정보 수정 중 오류 발생");
        }
        
    }

}
</script>

<style scoped>
    .modal {overflow: hidden; position:absolute; left:50%; top:50%; margin-top:-280px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:567px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    
    .content { height : 345px; padding : 30px 40px !important; }
    .content .titleDiv { position: relative; overflow: hidden; box-sizing: border-box; }
    .content .titleDiv h2 { margin-top : 20px; font-size:14px; margin-bottom:10px; padding-left : 10px; height:30px; line-height:30px; }
    .content .titleDiv:first-child h2 { margin-top : 0; }
    .content .titleDiv input { transition:0.2s; line-height:55px; height:55px; padding: 0 15px; border: 1px solid #e7e9ea; width:100%; box-sizing: border-box; font-size:17px; font-weight:bold; } 
    .content .titleDiv input:hover { border:1px solid #d5dbde; background:#f1f3f5; }
    .content .titleDiv input:focus { border:1px solid #477fff; background:#fff !important; }
    .content .titleDiv input.wrong { border:1px solid #ff6060; background:#fff0f0; color:#ff6060; }
    .content .titleDiv input:disabled { background : #fff; color : #232848; }
    .content .titleDiv select { -webkit-appearance: none; appearance: none; transition:0.2s; line-height:55px; height:55px; padding: 0 15px; border: 1px solid #e7e9ea; width:100%; box-sizing: border-box; font-size:17px; font-weight:bold; background:  url('../../../assets/images/side_area/bt_open.png') no-repeat 690px center; } 

    .content .titleDiv .first_title { width : 70%; float : left; position: relative; box-sizing: border-box; padding-right: 30px; }
    .content .titleDiv .second_title { width : 30%; float : left; position: relative; top : -1px; box-sizing: border-box; }

    .content .titleDiv .second_title #form_keep_year { background-position-x: 186px; }

    .content .titleDiv #form_file_name { padding-right: 90px; }
    .content .titleDiv #form_file_name.hasFile { padding-right: 170px; } 

    .content .titleDiv #form_file { display : none; }
    .content .titleDiv #form_file_button { position: absolute; background: #477fff; color: #fff; font-size: 13px; width: 70px; height: 35px; line-height: 35px; padding: 0 10px; margin: 0; top: 70px; right: 10px; border-radius: 3px; border : none; }
    .content .titleDiv #form_file_button:focus { background: #477fff !important; color: #fff !important; border : none; }
    .content .titleDiv #form_file_button:hover { background: #477fff !important; color: #fff !important; border : none; }

    .content .titleDiv #form_file_change_cancel { position: absolute; background: #FF6363; color: #fff; font-size: 13px; width: 70px; height: 35px; line-height: 35px; padding: 0 10px; margin: 0; top: 70px; right: 90px; border-radius: 3px; border : none; }
    .content .titleDiv #form_file_change_cancel:focus { background: #FF6363 !important; color: #fff !important; border : none; }
    .content .titleDiv #form_file_change_cancel:hover { background: #FF6363 !important; color: #fff !important; border : none; }

    .btns { border-top:1px solid #e7e9ea }
    .btns input { transition: 0.1s; width: 50%; float: left; height: 75px; line-height: 75px; font-size: 14px; text-align: center; font-weight: bold; background-color:#fff; }
</style>