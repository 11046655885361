<template>
    <!-- 파일 검색시 fileSrcOn 클래스 추가해서 리스트형으로 고정 -->
    <div id="file_box" class="section_ce_fix" :class="{
        dp1 : file_search_query.length > 0 || is_card_view == false,
        dp2 : file_search_query.length < 1 && is_card_view == true,
        fileSrcOn : file_search_query.length > 0
    }">
    
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">파일함</h3>
        </div>

        <!-- 검색 -->
        <div class="schedule_box">
            <div class="settingBg">
                <!-- 파일, 폴더 검색 -->
                <div class="schDiv">
                    <input type="text" placeholder="파일명 또는 폴더명을 검색하세요" :value="file_search_query" @input="file_search_query = $event.target.value">
                    <input type="button" class="schBtn">
                    <!-- 파일 검색 쓰면 검색 닫는 버튼 -->
                    <button class="schBtnClear" @click.prevent="file_search_query = ''"></button>
                </div>
                
                <!-- 개인으로 들어왔을때 -->
                <select v-model="file_box_tab" v-if="file_search_query.length < 1">
                    <option value="ALL">전체</option>
                    <option value="PREMIUM">프리미엄</option>
                    <option value="HODUDOC">호두닥</option>
                </select>

                <div class="btns" v-if="file_search_query.length < 1">
                    <input type="button" value="박스형으로보기" title="박스형" class="dpTwo" @click.prevent="cardViewModeOnOff" v-if="is_card_view == false">
                    <input type="button" value="리스트로보기" title="리스트" class="dpOne" @click.prevent="cardViewModeOnOff" v-if="is_card_view == true">
                </div>
            </div>
        </div>

        <!-- 컨텐츠 -->
        <div class="content">
            <div class="contwent_wrap">
                <!-- 달력별 파일함 목록 -->
				<div class="group_file">
					<ul class="sortHeader">
                        <!-- 아이콘 클릭시 fave_on 클래스 추가 후 즐겨찾기 정렬 -->
                        <li class="faveStar" title="즐겨찾기만 보기" :class="{ fave_on : show_fave_only == true }">
                            <i class="ic_faveStar faveSort" @click.prevent="() => { show_fave_only = !show_fave_only }">아이콘</i>
                        </li>
						<li class="grp headerSort" @click.prevent="sortTypeChange('DIR_NAME')"
                            :class="{
                                sortOff  : sort_type != 'DIR_NAME',
                                sortUp   : sort_type == 'DIR_NAME' && sort_dir == 'ASC',
                                sortDown : sort_type == 'DIR_NAME' && sort_dir == 'DESC'
                            }">
                            <span>이름</span>
                        </li>
						<li class="fdType sortOff"><span>종류</span></li>
						<li class="fileHead writtenBy sortOff"><span>파일수</span></li>
						<li class="lastUpdated headerSort" @click.prevent="sortTypeChange('UPDATE')"
                            :class="{
                                sortOff  : sort_type != 'UPDATE',
                                sortUp   : sort_type == 'UPDATE' && sort_dir == 'ASC',
                                sortDown : sort_type == 'UPDATE' && sort_dir == 'DESC'
                            }">
                            <span>업데이트</span>
                        </li>
					</ul>

                    <div id="filebox_scroll">

                        <!-- 프리미엄 -->
                        <div class="premium_group_file" v-show="file_box_tab != 'HODUDOC'">
                            <div class="h4Div">
                                <h4>프리미엄</h4>
                                <p class="totalNum">&#40;{{ computedHoduC.length }}개&#41;</p>
                            </div>
                            <!-- 파일함 존재하지 않을 때 -->
                            <div class="noResult" :class="{ on : computedHoduC.length < 1 }" v-if="is_card_view == true">
                                <p>파일함이 존재하지 않습니다</p>
                            </div>

                            <ul class="grfolderUl">

                                <li :id="`${group.biz_id}_li`" :key="group.biz_id" v-for="group in computedHoduC" :class="{ 
                                    subMore : group.dir_list.length > 0,
                                    on : open_biz_id == group.biz_id
                                }">
                                    <p class="faveStar" title="즐겨찾기" :class="{ fave_on : group.is_favorite == true }">
                                        <i class="ic_faveStar faveSelect" @click.prevent="insertFileDirFavorite(group.group_id, group.biz_id, true)">아이콘</i>
                                    </p>
                                    <p class="grp">
                                        <span class="clr" :class="hodu_color_dc_lc(group.group_color)"></span>
                                        {{ group.group_name }}
                                    </p>
                                    <p class="fdType">프리미엄</p>
                                    <p class="fd folder" :class="`folder_${hodu_color_dc_lc(group.group_color)}`">
                                        <span class="blind">폴더개수</span>
                                        <span class="total">{{ group.dir_list.length }}</span>
                                    </p>
                                    <p class="fileHead">
                                        <span class="title2">파일</span>
                                        <span class="total">{{ ` ${getFileCount(group)}개` }}</span>
                                    </p>
                                    <p class="lastUpdated">{{ getLastUpdate(group) }}</p>

                                    <a title="폴더 보기" class="seeSubFolder" @click="openGroupFilxBox(group.biz_id)">폴더</a>
                                    <a title="페이지로 가기" class="goDets" @click.prevent="moveFileDir(group)">페이지로가기</a>

                                    <div class="subFolderDiv">
                                        <div class="blackBg"></div>
                                        <div class="subFoldertitleDiv">
                                            <h5>폴더</h5>
                                            <input type="button" value="취소" class="closeSub" @click="closeFileBox">
                                        </div>

                                        <div :id="`${group.biz_id}_scroll`" class="subFolderScroll subFolderContent">
                                            <ul class="subFolderUl">
                                                <li :key="dir.dir_uuid" v-for="dir in group.dir_list">
                                                    <!-- 비밀번호 파일 pswFolder 클래스 추가 -->
                                                    <p class="grp" :class="`${ dir.has_password == true ? ' pswFolder' : '' }`">
                                                        <span class="ic-password">비밀번호 여부</span>
                                                        {{ dir.dir_name }}
                                                    </p>
                                                    <p class="fdType">프리미엄</p>
                                                    <p class="fd folder" :class="`folder_${hodu_color_dc_lc(dir.dir_color)}`">
                                                        <span class="blind">폴더개수</span>
                                                        <span class="total">{{ dir.dirs.length }}</span>
                                                    </p>
                                                    <p class="fileHead">
                                                        <span class="title2">파일</span>
                                                        <span class="total">{{ ` ${dir.path_files.length}개` }}</span>
                                                    </p>
                                                    <p class="lastUpdated">{{ `${ hodu_date_to_format_string(new Date(dir.audit_modified), 'YY.MM.DD') } ${ getDayOfWeekByDate(new Date(dir.audit_modified)) }` }}</p>
                                                    <a title="페이지로 가기" class="goDets" :class="{ pswModalBtn : dir.has_password == true }" @click.prevent="moveFileDir(group, dir)">페이지로가기</a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </li>

                                <!-- 서브폴더가 있을 경우 li에 subMore 추가하여 폴더보기 생성 -->
                                <!-- <li>
                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    <p class="grp">
                                        <span class="clr dc5"></span>
                                        송파 스포츠
                                    </p>
                                    <p class="fdType">프리미엄</p>
                                    <p class="fd folder folder_dc5">
                                        <span class="blind">폴더개수</span>
                                        <span class="total">0</span>
                                    </p>
                                    <p class="fileHead">
                                        <span class="title2">파일</span>
                                        <span class="total">5개</span>
                                    </p>
                                    <p class="lastUpdated">24.05.19 월</p>
                                    <a title="폴더 보기" class="seeSubFolder">폴더</a>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                </li> -->

                                <!-- <li class="subMore">
                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    <p class="grp">
                                        <span class="clr dc7"></span>
                                        호두웨어
                                    </p>
                                    <p class="fdType">프리미엄</p>
                                    <p class="fd folder folder_dc7">
                                        <span class="blind">폴더개수</span>
                                        <span class="total">3</span>
                                    </p>
                                    <p class="fileHead">
                                        <span class="title2">파일</span>
                                        <span class="total">19개</span>
                                    </p>
                                    <p class="lastUpdated">24.07.19 월</p>
                                    <a title="폴더 보기" class="seeSubFolder">폴더</a>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                    <div class="subFolderDiv">
                                        <div class="blackBg"></div>
                                        <div class="subFoldertitleDiv">
                                            <h5>폴더</h5>
                                            <input type="button" value="취소" class="closeSub">
                                        </div>
                                        <div class="subFolderContent">
                                            <ul class="subFolderUl">
                                                <li>
                                                    <p class="grp pswFolder"><span class="ic-password">비밀번호 여부</span>경영자료</p>
                                                    <p class="fdType">프리미엄</p>
                                                    <p class="fd folder folder_dc2">
                                                        <span class="blind">폴더개수</span>
                                                        <span class="total">2</span>
                                                    </p>
                                                    <p class="fileHead">
                                                        <span class="title2">파일</span>
                                                        <span class="total">4개</span>
                                                    </p>
                                                    <p class="lastUpdated">24.07.15 목</p>
                                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                                </li>
                                                <li>
                                                    <p class="grp">APP개발</p>
                                                    <p class="fdType">프리미엄</p>
                                                    <p class="fd folder folder_dc3">
                                                        <span class="blind">폴더개수</span>
                                                        <span class="total">2</span>
                                                    </p>
                                                    <p class="fileHead">
                                                        <span class="title2">파일</span>
                                                        <span class="total">4개</span>
                                                    </p>
                                                    <p class="lastUpdated">24.07.15 목</p>
                                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                                </li>
                                                <li>
                                                    <p class="grp">홍보마케팅 자료</p>
                                                    <p class="fdType">프리미엄</p>
                                                    <p class="fd folder folder_dc0">
                                                        <span class="blind">폴더개수</span>
                                                        <span class="total">0</span>
                                                    </p>
                                                    <p class="fileHead">
                                                        <span class="title2">파일</span>
                                                        <span class="total">2개</span>
                                                    </p>
                                                    <p class="lastUpdated">24.07.15 목</p>
                                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li> -->

                                <!-- <li class="subMore">
                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    <p class="grp">
                                        <span class="clr dc1"></span>
                                        호두 아파트
                                    </p>
                                    <p class="fdType">프리미엄</p>
                                    <p class="fd folder folder_dc1">
                                        <span class="blind">폴더개수</span>
                                        <span class="total">2</span>
                                    </p>
                                    <p class="fileHead">
                                        <span class="title2">파일</span>
                                        <span class="total">7개</span>
                                    </p>
                                    <p class="lastUpdated">24.05.19 월</p>
                                    <a title="폴더 보기" class="seeSubFolder">폴더</a>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                    <div class="subFolderDiv">
                                        <div class="blackBg"></div>
                                        <div class="subFoldertitleDiv">
                                            <h5>폴더</h5>
                                            <input type="button" value="취소" class="closeSub">
                                        </div>
                                        <div class="subFolderContent">
                                            <ul class="subFolderUl">
                                                <li>
                                                    <p class="grp pswFolder"><span class="ic-password">비밀번호 여부</span>관리사무소</p>
                                                    <p class="fdType">프리미엄</p>
                                                    <p class="fd folder folder_dc4">
                                                        <span class="blind">폴더개수</span>
                                                        <span class="total">2</span>
                                                    </p>
                                                    <p class="fileHead">
                                                        <span class="title2">파일</span>
                                                        <span class="total">4개</span>
                                                    </p>
                                                    <p class="lastUpdated">24.04.15 목</p>
                                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                                </li>
                                                <li>
                                                    <p class="grp">방제실</p>
                                                    <p class="fdType">프리미엄</p>
                                                    <p class="fd folder folder_dc3">
                                                        <span class="blind">폴더개수</span>
                                                        <span class="total">0</span>
                                                    </p>
                                                    <p class="fileHead">
                                                        <span class="title2">파일</span>
                                                        <span class="total">4개</span>
                                                    </p>
                                                    <p class="lastUpdated">24.05.15 목</p>
                                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li> -->
                            </ul>
                        </div>

                        <!-- 호두닥 -->
                        <div class="hodudoc_file" v-show="file_box_tab != 'PREMIUM'">
                            <div class="h4Div">
                                <h4>호두닥</h4>
                                <p class="totalNum">{{ computedHoduD.length }}개</p>
                            </div>

                            <!-- 호두닥 파일함 존재하지 않을때 -->
                            <div class="noResult" :class="{ on : computedHoduD.length < 1 }" v-if="is_card_view == true">
                                <p>호두닥 파일함이 존재하지 않습니다</p>
                            </div>

                            <ul class="grfolderUl">

                                <li :key="group.biz_id" v-for="group in computedHoduD">
                                    <p class="faveStar" title="즐겨찾기" :class="{ fave_on : group.is_favorite == true }">
                                        <i class="ic_faveStar faveSelect" @click.prevent="insertFileDirFavorite(group.group_id, group.biz_id, true)">아이콘</i>
                                    </p>
                                    <p class="grp">
                                        <span class="clr" :class="hodu_color_dc_lc(group.group_color)"></span>
                                        {{ group.group_name }}
                                    </p>
                                    <p class="fdType">호두닥</p>
                                    <p class="fd folder" :class="`folder_${hodu_color_dc_lc(group.group_color)}`">
                                        <span class="blind"></span>
                                        <span class="total">&nbsp;</span>
                                    </p>
                                    <p class="fileHead">
                                        <span class="title2">파일</span>
                                        <span class="total">{{ ` ${group.file_list.length}개` }}</span>
                                    </p>
                                    <p class="lastUpdated">{{ getLastUpdate(group) }}</p>
                                    <a title="페이지로 가기" class="goDets" @click.prevent="moveHoduDocFileBox(group)">페이지로가기</a>
                                </li>
                                
                                <!-- <li>

                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    <p class="grp">
                                        <span class="clr dc5"></span>
                                        호두병원
                                    </p>
                                    <p class="fdType">호두닥</p>
                                    <p class="fd folder folder_dc5">
                                        <span class="blind"></span>
                                        <span class="total">&nbsp;</span>
                                    </p>
                                    <p class="fileHead">
                                        <span class="title2">파일</span>
                                        <span class="total">5개</span>
                                    </p>
                                    <p class="lastUpdated">24.05.19 월</p>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                </li> -->

                            </ul>
                        </div>
                    </div>

				</div>
				<!-- 달력별 파일함 목록 끝 -->

                <!-- 파일 검색시 보이는 파일 검색 div -->
                <div class="group_file grp_file_search">
                    <ul class="sortHeader">
                        <!-- 아이콘 클릭시 fave_on 클래스 추가 후 즐겨찾기 정렬 -->
                        <li class="faveStar" title="즐겨찾기만 보기" :class="{ fave_on : show_fave_only == true }">
                            <i class="ic_faveStar faveSort" @click.prevent="() => { show_fave_only = !show_fave_only }">아이콘</i>
                        </li>
                        <li class="grp headerSort sortUp"><span>이름</span></li>
					</ul>

                    <div id="search_filebox_scroll">
                        <div class="premium_group_file">

                            <!-- 검색 결과가 없을 때 -->
                            <div class="noResult" :class="{ on : computedSearchFiles.length < 1 }">
                                <p>검색된 결과가 없습니다.</p>
                            </div>

                            <ul class="grfolderUl">

                                <!-- 검색 대상이 폴더 안 파일일 경우 li에 subMore on 추가하여 더보기 아이콘 및 기본 on 상태로 나오기 -->
                                <li :key="dir.dir_uuid" v-for="dir in computedSearchFiles" :class="{
                                    subMore : dir.files.length > 0,
                                    on : dir.files.length > 0 && close_search_dir_uuids.indexOf(dir.group_type == 'BIZC' ? dir.dir_uuid : dir.biz_id) < 0
                                }">
                                    
                                    <p class="faveStar" title="즐겨찾기" :class="{ fave_on : dir.is_favorite == true }">
                                        <i class="ic_faveStar faveSelect" @click.prevent="insertFileDirFavorite(dir.group_id, dir.group_type == 'BIZC' ? dir.dir_uuid : dir.biz_id, (dir.group_type == 'BIZC') ? false : true)">아이콘</i>
                                    </p>

                                    <!-- 디렉토리 비밀번호 걸려있을경우 내부 span에 pswFolder 클래스 추가 -->
                                    <p class="grp" v-html="getGrpHtml(dir)"></p>

                                    <p class="fd folder" :class="`folder_${hodu_color_dc_lc(dir.dir_color)}`">
                                        <span class="blind">폴더개수</span>
                                        <span class="total">{{ dir.dirs.length }}</span>
                                    </p>

                                    <a title="파일 보기" class="seeSubFolder" @click="searchFileBoxOnOff(dir)">폴더</a>
                                    <a title="파일함으로 이동" class="goDets" @click.prevent="dir.group_type == 'BIZC' ? moveFileDir(dir, dir) : moveHoduDocFileBox(dir)">페이지로가기</a>

                                    <div class="subFolderDiv">
                                        <div class="blackBg"></div>
                                        <div class="subFoldertitleDiv">
                                            <h5>폴더</h5>
                                            <input type="button" value="취소" class="closeSub" @click.prevent="searchFileBoxOnOff(dir)">
                                        </div>

                                        <div class="subFolderContent">
                                            <ul class="subFolderUl">

                                                <li class="fileUpLi writerCrt" :key="file.file_uuid" v-for="file in dir.files">
                                                    <p class="grp fileName" v-html="getFileGrpHtml(file)"></p>
                                                    <p class="preview" :class="{ 
                                                        fe : new RegExp('image').test(file.file_type) == false,
                                                        img : new RegExp('image').test(file.file_type) == true 
                                                    }" :style="{ backgroundImage : getBackgroundImage(file) }">
                                                        <span class="blind">파일</span>
                                                        {{ file.file_name.substring(file.file_name.lastIndexOf('.') + 1) }}
                                                    </p>
                                                    <a title="파일함으로 이동" class="goDets" @click.prevent="dir.group_type == 'BIZC' ? moveFileDir(dir, dir) : moveHoduDocFileBox(dir)">페이지로가기</a>
                                                </li>

                                                <!-- 파일 img -->
                                                <!-- <li class="fileUpLi writerCrt">
                                                    <p class="grp fileName"><mark>호두C</mark> 로고</p>
                                                    <p class="preview img">
                                                        <span class="blind">파일</span>
                                                    </p>
                                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                                </li> -->

                                                <!-- 파일 fe (프로그램) -->
                                                <!-- <li class="fileUpLi writerCrt">
                                                    <p class="grp fileName">2024 <mark>호두C</mark>멤버양식</p>
                                                    <p class="preview fe">
                                                        <span class="blind">파일</span>
                                                        xlsx
                                                    </p>
                                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                                </li> -->
                                            </ul>
                                        </div>

                                    </div>

                                </li>

                                <!-- <li class="subMore on">
                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    <p class="grp">
                                        <i class="clr dc6"></i>
                                        <span>호두웨어</span> &#47; <span class="pswFolder"><i class="ic-password">비밀번호 여부</i>경영자</span>
                                    </p>
                                    <p class="fd folder folder_dc6">
                                        <span class="blind">폴더개수</span>
                                        <span class="total">2</span>
                                    </p>
                                    <a title="폴더 보기" class="seeSubFolder">폴더</a>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                    <div class="subFolderDiv">
                                        <div class="blackBg"></div>
                                        <div class="subFoldertitleDiv">
                                            <h5>폴더</h5>
                                            <input type="button" value="취소" class="closeSub">
                                        </div>
                                        <div class="subFolderContent">
                                            <ul class="subFolderUl">
                 
                                                <li class="fileUpLi writerCrt">
                                                    <p class="grp fileName"><mark>호두C</mark> 로고</p>
                                                    <p class="preview img">
                                                        <span class="blind">파일</span>
                                                    </p>
                                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                                </li>

                                                <li class="fileUpLi writerCrt">
                                                    <p class="grp fileName">2024 <mark>호두C</mark>멤버양식</p>
                                                    <p class="preview fe">
                                                        <span class="blind">파일</span>
                                                        xlsx
                                                    </p>
                                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    
                                    <p class="grp">
                                        <i class="clr dc6"></i>
                                        <span>호두웨어</span>
                                        &#47; <span><i class="ic-password">비밀번호 여부</i>APP개발</span>
                                        &#47; <span><i class="ic-password">비밀번호 여부</i>IOS자료</span>
                                        &#47; <span class="pswFolder"><i class="ic-password">비밀번호 여부</i>업데이트 자료</span>
                                        &#47; <span><i class="ic-password">비밀번호 여부</i><mark>호두C</mark> IOS</span>
                                    </p>
                                    <p class="fd folder folder_dc6">
                                        <span class="blind">폴더개수</span>
                                        <span class="total">2</span>
                                    </p>
                                    <a title="폴더 보기" class="seeSubFolder">폴더</a>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 파일 검색 div 끝 -->

            </div>
        </div>

        <!-- <a @click.prevent="moveFileBox"><b>메인달력 파일함</b></a><br/>
        <a @click.prevent="moveGroupFileBox">그룹달력 파일함 페이지</a><br/>
        <a @click.prevent="moveGroupFileList">그룹달력 파일함 디렉토리 내부 페이지</a> -->
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, GROUP_TYPE, OWNER_TYPE, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');

import { hodu_cookie } from '@/lib/HoduCookie';

import { ResizeObserver } from 'vue-resize';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

@Component({
    components: {
        ResizeObserver
    },
})
export default class FileBox extends Mixins(VueHoduCommon) {

    get computedHoduC() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.hodu_c_list));

        if( this.is_card_view == false && this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list = target_list.sort((item, item2) : number => {

            const is_fave = item.is_favorite;
            const is_fave2 = item2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.dir_name == item2.dir_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.dir_name > item2.dir_name ? 1 : -1;
                }

                return item.dir_name < item2.dir_name ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {
                
                // 날짜가 같다면
                if( new Date(item.audit_modified).getTime() == new Date(item2.audit_modified).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item.audit_modified).getTime() > new Date(item2.audit_modified).getTime() ? 1 : -1;
                }

                return new Date(item.audit_modified).getTime() < new Date(item2.audit_modified).getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    get computedHoduD() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.hodu_d_list));

        if( this.is_card_view == false && this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list = target_list.sort((item, item2) : number => {

            const is_fave = item.is_favorite;
            const is_fave2 = item2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.dir_name == item2.dir_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.dir_name > item2.dir_name ? 1 : -1;
                }

                return item.dir_name < item2.dir_name ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {
                
                // 날짜가 같다면
                if( new Date(item.audit_modified).getTime() == new Date(item2.audit_modified).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item.audit_modified).getTime() > new Date(item2.audit_modified).getTime() ? 1 : -1;
                }

                return new Date(item.audit_modified).getTime() < new Date(item2.audit_modified).getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    get computedSearchFiles() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.search_files));

        if( this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list.sort((o1, o2) => {

            const o1_group_type = o1.group_type;
            const o2_group_type = o2.group_type;

            if( o1_group_type != o2_group_type ) {
                if( o1_group_type == 'BIZC' && o2_group_type == 'BIZD' ) return -1;
                else if( o1_group_type == 'BIZD' && o2_group_type == 'BIZC' ) return 1;
            }

            const o1_path_dirs = o1.path_dirs;
            const o2_path_dirs = o2.path_dirs;

            let o1_path_names = ""; 
            let o2_path_names = "";
            
            const o1_path_dirs_length = o1_path_dirs.length;
            for( let i = 0; i < o1_path_dirs_length; i++ ) {
                const o1_path_dir = o1_path_dirs[i];
                if( i > 0 ) o1_path_names += "_";
                o1_path_names += o1_path_dir.dir_name;
            }
            
            const o2_path_dirs_length = o2_path_dirs.length;
            for( let i = 0; i < o2_path_dirs_length; i++ ) {
                const o2_path_dir = o2_path_dirs[i];
                if( i > 0 ) o2_path_names += "_";
                o2_path_names += o2_path_dir.dir_name;
            }

            // 노드, 트리 형식 정렬에 사용하기 좋음
            return o1_path_names.localeCompare(o2_path_names);
        });

        // 비밀번호 중간에 걸려있으면 제외시켜야함 && 패스워드 걸린 디렉토리는 디렉토리 이름이 검색된게 아니면 검색 리스트에서 제외되어야함
        target_list = target_list.filter(item => this.isMiddleDirHasPassword(item) == false && this.isPassHasPasswordDirRule(item) == true);

        // 비밀번호 걸린 디렉토리는 파일이 노출되어서는 안됨
        for( const item of target_list ) {
            if( item.has_password == true ) {
                item.files.splice(0, item.files.length);
            }
        }

        return target_list;
    }

    /**
     * @GroupInfo.Action
     */
    @GroupInfo.Action doSelectedDirUuid ?: (params : string) => void;

    hodu_c_list : any[] = [];
    hodu_d_list : any[] = [];

    is_card_view : boolean = true;
    file_box_tab : 'ALL' | 'PREMIUM' | 'HODUDOC' = 'ALL';
    show_fave_only : boolean = false;
    open_biz_id : string = "";

    // 정렬
    sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE' = 'DIR_NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    file_search_query : string = "";
    search_files : any[] = [];
    close_search_dir_uuids : string[] = [];
    
    mounted() : void {

        const group_file_box_is_card_view = hodu_cookie.get('group_file_box_is_card_view');
        if( group_file_box_is_card_view != null ) {
            this.is_card_view = (group_file_box_is_card_view === 'true');
        }
        
        // //리스트형으로 보기
        // $(".btns .dpTwo").click(function(){
        //     $(".section_ce_fix").addClass("dp2");
        //     $(".section_ce_fix").removeClass("dp1");
        //     $(".btns .dpOne").show();
        //     $(this).hide();

        // });

        // //박스형으로 보기
        // $(".btns .dpOne").click(function(){
        //     $(".section_ce_fix").removeClass("dp2");
        //     $(".section_ce_fix").addClass("dp1");
        //     $(".btns .dpTwo").show();
        //     $(this).hide();
        // });

        // 즐겨찾기 정렬
        // $(".faveStar").click(function(){
        //     $(this).toggleClass("fave_on");
        // });

        // $(".faveStar").click(function(){
        //     $(this).addClass('fave_on').siblings().removeClass();
        // })

        //서브폴더 열기
        // $(".seeSubFolder").click(function(){
        //     $(this).parent().addClass("on");
        // });

        //서브폴더 닫기
        // $(".closeSub").click(function(){
        //     $(this).parent().parent().parent().removeClass("on");
        // });

        this.getFileBoxDirAndFile();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {

            const title_box_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const sort_header_height : number | undefined = this.is_card_view == true ? 0 : $('.sortHeader').outerHeight();
            const search_sort_header_height : number | undefined = $('.grp_file_search .sortHeader').outerHeight();

            // @ts-ignore
            $('#filebox_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (sort_header_height ? sort_header_height : 0),
            });

            // @ts-ignore
            $('#search_filebox_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (search_sort_header_height ? search_sort_header_height : 0),
            });
        });
        
    }

    /**
     * 유저와 관련된 파일함 전체 조회
     */
    async getFileBoxDirAndFile() : Promise<void> {

        await this.hodu_api_call(`api/v1/user/me/file`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.hodu_c_list.splice(0, this.hodu_c_list.length);
                this.hodu_c_list = this.hodu_c_list.concat(response.data.data.hodu_c_list);

                this.hodu_d_list.splice(0, this.hodu_d_list.length);
                this.hodu_d_list = this.hodu_d_list.concat(response.data.data.hodu_d_list);

            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });

    }

    /**
     * 카드 뷰 모드 ON / OFF
     */
    cardViewModeOnOff() : void {
        this.is_card_view = !this.is_card_view;

        if( this.open_biz_id != null && this.open_biz_id.length > 0 ) {
            
            const scroll_container = $(`#${this.open_biz_id}_scroll`);

            if( this.is_card_view == true ) {

                this.$nextTick(() => {
                
                    const li_height : number | undefined = $(`#${this.open_biz_id}_li`).innerHeight();
                    const li_title_height : number | undefined = $(`#${this.open_biz_id}_li .subFoldertitleDiv`).innerHeight();

                    // @ts-ignore
                    scroll_container.mCustomScrollbar({
                        axis : 'y',
                        scrollbarPosition : 'outside',
                        mouseWheelPixels : 100,
                        scrollInertia : 60,
                        autoDraggerLength : false,
                        setHeight : (li_height ? li_height : 0) - (li_title_height ? li_title_height : 0)
                    });

                });

            }

            else {
                // @ts-ignore
                scroll_container.mCustomScrollbar('destroy');
            }
            
        }

        hodu_cookie.set('group_file_box_is_card_view', String(this.is_card_view));
        
        this.$nextTick(() => this.handleResize());
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 호두 C - 그룹 파일 개수 반환
     */
    getFileCount(group : any) : number {
        let file_count : number = 0;
        for( const dir of group.dir_list ) { file_count += dir.path_files.length; }
        return file_count;
    }

    /**
     * 호두 C & D - 마지막 업데이트 된 날짜
     */
    getLastUpdate(group : any) : string {

        let target_date : Date | null = null;
        for( const dir of (group.dir_list ? group.dir_list : group.file_list) ) {
            
            // 첫 데이터
            if( target_date == null ) {
                target_date = new Date(dir.audit_modified);
                continue;
            }

            // 비교
            if( target_date.getTime() < new Date(dir.audit_modified).getTime() ) {
                target_date = new Date(dir.audit_modified);
            }
        }

        // 리스트가 없다면
        if( target_date == null ) { return ''; }

        return `${this.hodu_date_to_format_string(target_date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(target_date)}`;
    }

    /**
     * 그룹 파일함 폴더 열기
     */
    openGroupFilxBox(biz_id : string) : void {
        this.open_biz_id = biz_id;

        if( this.is_card_view == false ) { return; } 

        this.$nextTick(() => {
            
            const li_height : number | undefined = $(`#${biz_id}_li`).innerHeight();
            const li_title_height : number | undefined = $(`#${biz_id}_li .subFoldertitleDiv`).innerHeight();

            // @ts-ignore
            $(`#${biz_id}_scroll`).mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : (li_height ? li_height : 0) - (li_title_height ? li_title_height : 0)
            });

        });
    }

    /**
     * 그룹 파일함 폴더 닫기
     */
    closeFileBox() : void {

        if( this.is_card_view == true ) { 
            // @ts-ignore
            $(`#${this.open_biz_id}_scroll`).mCustomScrollbar('destroy');
        }

        this.open_biz_id = "";
    }

    /**
     * 파일 검색 쿼리 변경 감지
     */
    @Debounce(250)
    @Watch('file_search_query')
    async watchFileSearchQuery() : Promise<void> {

        this.close_search_dir_uuids.splice(0, length);

        if( this.file_search_query.length < 1 ) {
            this.search_files.splice(0, this.search_files.length);
            return;
        }

        try {
            const response = await this.hodu_api_call(`api/v1/file?search_query=${this.file_search_query}`, API_METHOD.GET, null, false);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.groups ) {
                throw new Error("검색 중 오류 발생");
            }

            this.search_files.splice(0, this.search_files.length);
            this.search_files = this.search_files.concat(response.data.data.groups);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        this.$nextTick(() => this.handleResize()); 
    }

    getGrpHtml(dir) {

        // <i class="clr dc6"></i>
        // 비밀번호 파일 pswFolder 클래스 추가
        // <span>{{ dir.group_name + `${ dir.group_type == 'BIZC' ? " / " : "" }` }} </span> 
        // <span :class="{ pswFolder : dir.has_password == true }" v-if="dir.group_type == 'BIZC'">
        //     <i class="ic-password">비밀번호 여부</i>
        //     {{ dir.dir_name }}
        //     <mark>호두C</mark>
        // </span>

        if( dir == null ) return '';

        let html = `<i class="clr ${this.hodu_color_dc_lc(dir.group_color)}"></i>`;
        html += `<span>${ dir.group_name }</span>`;

        if( dir.group_type != 'BIZC' || dir.path_dirs == null || dir.path_dirs.length < 1 ) {
            return html;
        }

        const SHOW_DIR_TREE_MAX_COUNT = 4;

        const dir_count = dir.path_dirs.length;
        for( let i = 0; i < dir_count; i++ ) {
            const path_dir = dir.path_dirs[i];

            if( dir_count > SHOW_DIR_TREE_MAX_COUNT ) {
                if( (SHOW_DIR_TREE_MAX_COUNT / 2) - 1 < i && i < dir_count - (SHOW_DIR_TREE_MAX_COUNT / 2) ) {
                    if( i == dir_count - (SHOW_DIR_TREE_MAX_COUNT / 2) - 1 ) html += ` / ··· `;
                    continue;
                }
            }

            const mark_text = path_dir.dir_name.replaceAll(this.file_search_query, `<mark>${this.file_search_query}</mark>`);

            html += ` / <span class="${ path_dir.has_password == true ? "pswFolder" : "" }"><i class="ic-password">비밀번호 여부</i>${mark_text}</span>`;
        }

        return html;
    }

    getFileGrpHtml(file) {

        if( file == null ) return '';

        return file.file_name.replaceAll(this.file_search_query, `<mark>${this.file_search_query}</mark>`)

    }

    /**
     * 배경화면 반환
     */
    getBackgroundImage(file : any) : string {
        if( new RegExp(escape('image')).test(escape(file.file_type)) == false ) {
            return 'none';
        }

        return file.file_path.indexOf('patient_file') > -1 ? `url(app_images/${file.file_path})` : `url(app_images/group_file/${file.file_path})`;
    }

    /**
     * 그룹 파일함 검색 리스트 폴더 열기
     */
    searchFileBoxOnOff(dir) : void {
        
        if( dir == null ) return;

        let dir_uuid = dir.group_type == 'BIZC' ? dir.dir_uuid : dir.biz_id;

        let index = this.close_search_dir_uuids.indexOf(dir_uuid);
        if( index < 0 ) {
            this.close_search_dir_uuids.push(dir_uuid);
        }
        else {
            this.close_search_dir_uuids.splice(index, 1);
        }

    }

    /**
     * 파일함 즐겨찾기 ON / OFF
     */
    async insertFileDirFavorite(group_id : number, dir_uuid : string, is_root : boolean) {

        try {

            const response = await this.hodu_api_call(`/api/v1/fileDirFavorite/${group_id}/${dir_uuid}?is_root=${is_root}`, API_METHOD.POST);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("파일함 즐겨찾기 ON / OFF 중 오류 발생");
            }

            // 재조회
            this.getFileBoxDirAndFile();

            if( this.file_search_query != null && this.file_search_query.length > 0 ) {
                this.watchFileSearchQuery();
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일함 즐겨찾기 설정 중 오류 발생", ['확인']);
        }
    }

    /**
     * 디렉토리 비밀번호 입력
     */
    password_input(group : any, dir : any) {

        let uuid : string = dir.dir_uuid;

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }

                    this.hodu_router_push(`/?${new Date().getTime()}`, async() => {
                        await this.calendarChange(group);
                        this.doSelectedDirUuid?.(dir != null ? dir.dir_uuid : "");
                        this.hodu_router_push(`/group/filebox`);
                    });

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });
    }

    /**
     * 파일함 디렉토리로 이동
     */
    async moveFileDir(group : any, dir ?: any) : Promise<void> {

        if( dir != null && dir.has_password == true ) {
            this.password_input(group, dir);
            return;
        }
        
        // 개인 달력인 경우 그룹 달력으로 이동 시킨 후 이동한다
        if( group.group_type != GROUP_TYPE.BIZC ) return;

        this.hodu_router_push(`/?${new Date().getTime()}`, async() => {
            await this.calendarChange(group);
            this.doSelectedDirUuid?.(dir != null ? dir.dir_uuid : ""); 
            this.hodu_router_push(`/group/filebox`);
        });
        
    }

    moveHoduDocFileBox(group) {
        this.hodu_router_push(`/hospital/file/${group.biz_id}`);
    }

    // moveFileBox() : void {
    //     this.hodu_router_push(`/filebox?${new Date().getTime()}`);
    // }

    // moveGroupFileBox(group) : void {

    //     // 달력 변경
    //     this.hodu_router_push(`/group/filebox?${new Date().getTime()}`);
    // }

    // moveGroupFileList() : void {
    //     this.hodu_router_push(`/group/filebox/dir_id?${new Date().getTime()}`);
    // }

    async calendarChange(group) {
        if( $('#bt_calContr').hasClass('on') == false ) {
            $('.ui-datepicker-calendar-wrap').hide();
            $('#bt_calContr').addClass('on');
        }

        this.doSetGroupMenuClose(true);
        this.doSetMessageMenuClose(true);
        this.doSetWorkMenuClose(true);
        this.doSetCommunityMenuClose(true);
        this.doSetResidentMenuClose(true);

        this.doGroupId(group.group_id);
        this.doTeamId(0);

        this.doSetCalendarId(`group-${group.group_id}`);
        this.doSetScope(OWNER_TYPE.GROUP);
        this.doSetScopeGroupId(group.group_id);
        this.doSetScopeTeamId(0);
        this.doSetScopeGroupTeamOption({
            group_team_name     : group.group_name,
            group_team_color    : group.group_color,
            group_team_image    : "",
            group_team_descript : "",
            biz_id              : group.biz_id,
            biz_type            : GROUP_TYPE.BIZC,
        });

        await this.hodu_loading_timer_exit();
        await this.hodu_loading();

        setTimeout(() => {
            // @ts-ignore
            $('.left_inbox02').mCustomScrollbar('destroy');
            window['left_inbox02_mCustomScrollbar']();
        }, 100);

        if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
    }

    isMiddleDirHasPassword(dir) {

        if( dir == null || dir.path_dirs == null || dir.path_dirs.length <= 1 ) return false; 

        let is_middle_dir_has_password : boolean = false;

        let count = dir.path_dirs.length;

        for( let i = 0; i < (count - 1); i++ ) {
            const path_dir = dir.path_dirs[i];

            if( path_dir.has_password == true ) {
                is_middle_dir_has_password = true;
                break;
            }
        }

        return is_middle_dir_has_password;
    }

    isPassHasPasswordDirRule(dir) {

        if( dir == null ) return false;
        if( dir.has_password == false ) return true;

        let is_pass_has_password_dir_rule : boolean = new RegExp(this.file_search_query).test(dir.dir_name);

        return is_pass_has_password_dir_rule;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        // @ts-ignore
        $('#filebox_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#search_filebox_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style>
    #file_box .grfolderUl li .grp .clr { display: inline-block; width: 8px; height: 8px; margin-right: 10px; border-radius: 50%; }
    #file_box .group_file.grp_file_search .pswFolder .ic-password { display: inline-block; }
    #file_box.section_ce_fix .grp .ic-password { display: none; width: 15px; height: 15px; font-size: 0; background: url('../assets/images/folder_file/ic_password_232848.png') no-repeat center center; background-size: 9px; vertical-align: middle; margin-top: -2px; }
    #file_box.section_ce_fix mark { font-style: normal; background: none; color: #477fff;}
</style>

<style scoped>
    .settingBg { height: 80px; position: relative; border-bottom:1px solid #e7e9ea; }

    .settingBg .schDiv { position: relative; padding: 15px; box-sizing: border-box; width: 400px; display: inline-block; margin-left: 15px; }
    .settingBg .schDiv input[type="text"] { width: 100%; height: 50px; line-height: 50px; padding: 0 15px; border-radius: 5px; border: 1px solid #e7e9ea; background: #f1f3f5; font-weight: bold; box-sizing: border-box; transition: .2s; }
    .settingBg .schDiv input[type="text"]:hover { border: 1px solid #477fff; }
    .settingBg .schDiv input[type="text"]:focus { background: #fff; border-color: #477fff; }
    .section_ce_fix.fileSrcOn .settingBg .schDiv .schBtn { display: none; }
    .settingBg .schDiv .schBtn { position: absolute ; right:20px;top:20px; }
    .settingBg select { cursor:pointer; transition:0.2s; margin-top: 20px; height: 35px; line-height: 31px; border:1px solid #232848; appearance: none; padding: 0 40px 0 20px; border-radius: 20px; font-weight: bold; font-size: 14px;background: url(../assets/images/side_area/bt_open.png) no-repeat 90% center; float: right; margin-right: 20px; }
    .settingBg select:hover { background-color:#f1f3f5; }
    .settingBg select:focus { border-color:#477fff; background-color:#fff;background-image: url(../assets/images/side_area/bt_close.png); }
    .settingBg .btns { float: right; margin: 18px 20px 0 0; }
    .settingBg .schDiv .schBtnClear { display: none; position: absolute; top: 28px; right: 28px; width: 25px; height: 25px; background: url('../assets/images/contents/ic_attnd_no_dc7.png') center center no-repeat; background-size: 60%; border: 1px solid #477fff; border-radius: 50%; outline: none; cursor: pointer; }
    .section_ce_fix.fileSrcOn .settingBg .schDiv .schBtnClear { display: block; }

    /* content */
    .content .noResult { display:none; position: static; padding: 20px 30px; }
    .content .noResult.on { display:block; }
    .contwent_wrap .noResult p { background: #f1f3f5; height: 80px; line-height: 80px; font-size : 14px; }

    .section_ce_fix.dp2 .group_file .sortHeader { display: none; }
    .section_ce_fix.dp1 .h4Div { display: none; }

    .section_ce_fix.dp1 .faveStar { width: 5%; margin-left: 2% !important; }
    .section_ce_fix.dp1 .grp { width: 36%; margin-left: 8% !important; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .section_ce_fix.dp1 .fdType { display: inline-block; width: 21%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .section_ce_fix.dp1 .fileHead { width: 15%; height: 60px; line-height: 60px; }
    .section_ce_fix.dp1 .lastUpdated { width: 20%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

    .faveStar { cursor: pointer; position: absolute; z-index: 10000000; }
    .faveStar .ic_faveStar { width: 40px; height: 40px; display: inline-block; font-size: 0; vertical-align: middle; margin-top: -2px; }
    .faveStar .faveSort { background: url('../assets/images/contents/ic_fave_off.png'); background-repeat: no-repeat; background-size: 23px; background-position: center center; }
    .faveStar.fave_on .faveSort { background-image: url('../assets/images/contents/ic_fave_on.png'); }
    .faveStar .faveSelect { background: url('../assets/images/contents/faveStar_off.png'); background-repeat: no-repeat; background-size: 23px; background-position: center center; }
    .faveStar.fave_on .faveSelect { background-image: url('../assets/images/contents/faveStar.png');}

    /* 폴더 리스트 */
    .grfolderUl { overflow: hidden; box-sizing: border-box; padding-right: 30px; border-bottom: 5px solid #f1f3f5; padding-bottom: 40px; }
    .section_ce_fix.dp1 .grfolderUl { padding-right: 0; padding-bottom: 0; border-bottom: 0 none; }
    .grfolderUl li { text-align: center; position: relative; float: left; display: inline-block; width: 180px; height: 200px; margin: 30px 0 0 30px; box-sizing: border-box; border: 1px solid #e7e9ea; border-radius: 5px; }
    .fdType { display: none; }
    .fileHead { font-size: 12px; height: 25px; font-weight: bold; }

    .section_ce_fix.dp1 .grfolderUl li { width: 100%; margin: 0; border-radius: 0; border: none; height: 66px; line-height: 66px; border-bottom: 1px solid #f1f3f5; transition: 0.2s; }
    .section_ce_fix.dp1 .grfolderUl li.on { height: auto; border-bottom: 2px solid #f1f3f5; }
    .section_ce_fix.dp1 .grfolderUl li:hover { background: #f7f9fb; }
    .grfolderUl > li:hover { background: #f7f9fb; }
    .grfolderUl li a { position: absolute; display: inline-block; height: 100%; left: 0; top: 0; width: 100%; text-align: center; font-size: 0; }
    .section_ce_fix.dp1 .grfolderUl > li > .goDets { height: 66px !important; }
    .section_ce_fix.dp1 .grfolderUl p { float: left; text-align: left; font-size: 12px; height: 66px; line-height: 66px; padding-left: 0; font-weight: bold; }

    .grfolderUl li .grp { font-size: 14px; font-weight: bold; height: 40px; line-height: 60px; padding: 0 25px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .section_ce_fix.dp1 .grfolderUl .grp { padding-left: 60px; font-size: 13px; }
    .section_ce_fix.dp1 .grfolderUl .fd { position: absolute; left: 8.3%; width: 50px; padding-left: 0; }
    .section_ce_fix.dp1 .fd { background-position: left center; background-size: 41px 30px; color: #232848; }
    .section_ce_fix .grfolderUl .fd span.total { display: inline-block; width: 35px; line-height: 69px; text-align: center; color: #fff; }

    .grfolderUl li .grp .clr { display: inline-block; width: 8px; height: 8px; margin-right: 10px; border-radius: 50%; }
    
    /* 서브 폴더 */
    .grfolderUl > li.on .subFolderDiv { display:block; }
    .grfolderUl > li .seeSubFolder { display:none; }
    .grfolderUl > li.subMore .seeSubFolder { display:block; }
    .subFolderDiv, .grfolderUl > li.on .seeSubFolder { display:none; }
    .grfolderUl li a.seeSubFolder { font-size: 12px; transition: 0.2s; opacity: 0; z-index: 10000000; border-radius: 20px; bottom: 20px; top: auto; left: 50%; width: 85px; border: 1px solid #2265ff; background: #477fff; height: 27px; line-height: 27px; color: #fff; font-weight: bold; margin-left: -42.5px; }
    .grfolderUl li:hover > a.seeSubFolder { opacity: 1; bottom: -18px; }

    .section_ce_fix.dp1 .subFoldertitleDiv h5 { display: none; }
    .section_ce_fix.dp1 .grfolderUl li a.seeSubFolder, .section_ce_fix.dp1 .closeSub { font-size:0; z-index: 10000; opacity: 1; transition:0.2s;left:auto; width: 60px; height: 65px; border-radius: 50%; right: 0; top:0; background-color:transparent; border:none; border-radius: 0; box-sizing: border-box;background-image: url('../assets/images/side_area/bt_open.png'); background-size: 26px; background-repeat: no-repeat; background-position: center center }
    .section_ce_fix.dp1 .closeSub { background-image: url('../assets/images/side_area/bt_close.png'); position: absolute; }
    .section_ce_fix.dp1 .grfolderUl li a.seeSubFolder:hover, .section_ce_fix.dp1 .closeSub:hover { background-color:#fff; }
    
    .section_ce_fix.dp1 .subFolderDiv li { border-top: 1px solid #f1f3f5; border-bottom: 0; background: #fff; line-height: 55px !important; height: 55px !important; }
    .section_ce_fix.dp1 .subFolderDiv li p { line-height: 55px !important; height: 55px !important; }
    .section_ce_fix.dp1 .subFolderDiv p.grp { font-size: 12px; }

    .section_ce_fix .subFolderDiv .fd { box-sizing: border-box; background-position: 8px center; background-size: 33px 23px; }
    .section_ce_fix .subFolderUl .fd span.total { line-height: 58px; width: 37px; text-indent: 9px; font-size: 11px; }

    /* 비밀번호 */
    .section_ce_fix .grp .ic-password { display: none; width: 15px; height: 15px; font-size: 0; background: url('../assets/images/folder_file/ic_password_232848.png') no-repeat center center; background-size: 9px; vertical-align: middle;
    margin-top: -2px; }
    .section_ce_fix .grp.pswFolder .ic-password { display: inline-block; }

    /* 박스형 */
    .contwent_wrap .h4Div { overflow: hidden; padding: 30px 30px 0 30px; font-size: 16px; margin-bottom: -5px; box-sizing: border-box; }
    .contwent_wrap .h4Div h4, .contwent_wrap .h4Div p { display: inline-block; font-weight: bold; margin-right: 5px; }

    .section_ce_fix.dp2 .subFolderDiv { position: absolute; left: 0; top: 0; height: 100%; overflow: hidden; border: 1px solid #f1f3f5; box-sizing: border-box; width: 100%; }
    .section_ce_fix.dp2 .blackBg { display: block; width: 100%; height: 100%; position: absolute; background: #fff; opacity: 0.99; }
    .section_ce_fix.dp2 .subFoldertitleDiv { width: 100%; height: 40px; line-height: 40px; position: relative; z-index: 100; overflow: hidden; text-align: left; background: #fff; border-bottom: 2px solid #f1f3f5; padding: 0 20px; -webkit-box-sizing: border-box; box-sizing: border-box; }
    .section_ce_fix.dp2 .subFoldertitleDiv h5 { display: inline-block; font-size: 14px; text-align: left; padding: 0 15px 0px 15px; }
    .section_ce_fix.dp2 .subFoldertitleDiv input { float: right; margin-top: 6px; background-image: url('../assets/images/contents/ic_close_fff.gif'); background-color: #ff6060; background-repeat: no-repeat; background-position: center center; background-size: 15px auto; font-size: 0; border-radius: 50%; width: 25px; height: 25px; margin-right: -5px; }

    .section_ce_fix.dp2 .subFolderUl { position: relative; }
    .section_ce_fix.dp2 .subFolderUl li { position: relative; border: none; border-bottom: 1px solid #f1f3f5; border-radius: 0; height: auto; margin: 0; }
    .section_ce_fix.dp2 .subFolderUl li .grp { padding-left: 50px; height: 45px; line-height: 45px; text-align: left; font-size: 13px; position: relative; }
    .section_ce_fix.dp2 .subFolderUl .folder { position: absolute; left: 15px; top: 6px; width: 30px; height: 30px; background-size: 23px 17px; background-position: left center; }
    .section_ce_fix.dp2 .subFolderUl .fileHead { text-align: left; padding-left: 11px; }
    .section_ce_fix.dp2 .subFolderUl .fileHead .title2 { margin-right: 9px; }
    .section_ce_fix.dp2 .subFolderUl .lastUpdated { display: none; }

    /* 파일 검색 */
    .section_ce_fix .group_file.grp_file_search { display: none; }
    .section_ce_fix.fileSrcOn .group_file { display: none; }
    .section_ce_fix.fileSrcOn .group_file.grp_file_search { display: block; }
    
    .section_ce_fix mark { font-style: normal; background: none; color: #477fff;}
    .group_file.grp_file_search .pswFolder .ic-password { display: inline-block; }

    .section_ce_fix.dp1 .group_file.grp_file_search .grp { width: 100%; box-sizing: border-box; margin-left: 6% !important; }
    .section_ce_fix.dp1 .group_file.grp_file_search .grp.fileName { margin-left: 7.5% !important; }
    .section_ce_fix.dp1.fileSrcOn .grfolderUl .fd { left: 6.2%; }
    .section_ce_fix.dp1 .fileName { width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-left: 12% !important; box-sizing: border-box; }
    .subFolderContent > ul > li .preview { position: absolute; left: 7%; background: #fff; border-radius: 5px; text-transform: uppercase; text-align: center; width: 50px; color: #477fff; top: 8px; padding-right: 0; border: 1px solid #e7e9ea; box-shadow:none; }
    .subFolderContent > ul > li .preview.img { transition: 0.1s; font-size:0; background-size:cover; background-position:center center; }
    .subFolderContent > ul > li .preview.img { background-image: url('../assets/images/contents/imgDc8.png')}
    .section_ce_fix.dp1 .subFolderContent > ul > li p.preview { height: 33px !important; line-height: 33px !important; text-align: center; background-size: cover; background-position: center; }

    #file_box.section_ce_fix .grp.pswFolder .ic-password { display: inline-block; }
</style>