<template>
    <div class="remodal mainConfirm grpConfirm mem delOneMem" style="min-width:460px;">
        <button data-remodal-action="close" class="remodal-close"><span></span><span></span></button>
        <div class="remodal-content ">
            <div class="modal-scroll" id="">
                <div class="main">
                    <img class="userImg" :src="`${image ? '/app_images/' + image : user_profile_no_image}`" alt="사용자이미지" v-if="!isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '')"/>
                    <h1 class="userName">{{name}}</h1>
                    <p class="pos">{{role}}</p>
                    <p class="tel" v-if="!isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '')">{{phone}}</p>
                    <p class="email" v-if="!isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '')">{{email}}</p>
                </div>
            </div>
            <h2>{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '해당 입주자를 전출시키시겠습니까?' : '이 멤버를 탈퇴시키시겠습니까?' }}</h2>
            <!--
            <div class="btns" id="">
                <a href="#" class="" id="no" data-remodal-action="close">아니오</a>		
                <a href="#" class="" id="yes">예</a>
            </div>
            -->
            <!-- 탈퇴용 버튼 -->
            <div class="btns secondBtns" id="">	
                <a href="#" class="" data-remodal-action="close" @click.prevent="groupTeamOtherOutCancel()">아니오</a>		
                <a href="#" class="" id="yes" @click.prevent="groupTeamOtherOutConfirm()">예</a>
            </div>
            <!-- readOnly용 
            <div class="btns confirmBtns" id="">
                <a href="#" class="" id="no" data-remodal-action="close">닫기</a>		
                <a href="#" class="" id="yes">확인</a>
            </div>-->
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupTeamOtherOutModalInfo, GroupTeamOtherOutModalData } from '@/store/modules/ModalInfo';
const ModalInfo = namespace('ModalInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class GroupTeamOtherOutModal extends Mixins(VueHoduCommon) {
    /**
     * ModalInfo.State
     */
    @ModalInfo.State group_team_other_out_info !: GroupTeamOtherOutModalInfo;

    /**
     * 변수 선언부
     */
    data  !: GroupTeamOtherOutModalData;
    name   : string = "";
    role   : string = "";
    email  : string = "";
    phone  : string = "";
    image  : string = "";

    user_profile_no_image = require("@/assets/images/contents/im_photoB.gif");

    async mounted() {
        if ( null != this.group_team_other_out_info.data ) {
            this.data  = this.group_team_other_out_info.data;
            this.name  = this.data.user_name;
            this.email = this.data.user_email;
            this.phone = this.data.user_phone_number;
            this.image = this.data.image;

            if ( this.data.team_id == 0 ) {
                this.role = this.data.role == this.GroupAuth.ADMIN ? '최종관리자' : (this.data.role == this.GroupAuth.GROUP_MANAGER ? "관리자" 
                                                                                 : (this.data.role == this.GroupAuth.GROUP_SUB_MANAGER ? this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? '보안실' : "부관리자" 
                                                                                                                                       : this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? '입주자' : "멤버"));
            }
            else {
                this.role = this.data.role == this.GroupAuth.ADMIN ? '최종관리자' :  (this.data.role == this.GroupAuth.TEAM_MANAGER ? this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? '동장' : "팀장" 
                                                                                 : this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? '입주자' : "멤버");
            }
        }
    }

    groupTeamOtherOutCancel() : void {
        window["groupTeamOtherOutCancel"]();
    }

    groupTeamOtherOutConfirm() : void {
        window["groupTeamOtherOutConfirm"](this.data);
    }    
}
</script>

<style scoped>
.mainConfirm {width: 500px;z-index: 100000000;left: 50%;margin-left: -150px;top: 50%;margin-top: -174px;position: absolute;background: #fff;}
.mainConfirm .btns {text-align: center;}
.mainConfirm h2 {text-align: center;}
.mainConfirm p {text-align: center;}
.grpConfirm p.descript { text-align:left;}
</style>