/*************** ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â Ãƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬Å“Ãƒâ€šÃ‚Â´ ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦(ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬) ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€ Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ ************************/

/*ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â Ãƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬Å“Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦(ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬) ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€ Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼*/
.remodal.w239{width:239px;}	 
.remodal.w275{width:275px;}
.remodal.w299{width:299px;}
.remodal.w300{width:300px;}
.remodal.w320 {width: 320px}
.remodal.w332{width:332px;}
.remodal.w340{width:340px;}
.remodal.w390{width:390px;}
.remodal.w400{width:400px;}
.remodal.w450{width:450px;}
.remodal.w460{width:460px;}
.remodal.w490{width:490px;}
.remodal.w530{width:530px;}
.remodal.w563{width:563px;}
.remodal.w600{width:600px;}
.remodal.w616{width:616px;}
.remodal.w623{/* width: 713px; */}
.remodal.w650{width:800px;}
.remodal.w750{width:750px;}
.remodal.w800 {width: 800px;margin-bottom: 0;border-radius: 0;right: 0 !important;position: relative;height: 100%;}
.remodal.w840 {width:840px;}
.remodal.w1110{width:1000px;}

.remodal {color:#484848;max-width:1000px;width:auto;border-radius: 5px;box-shadow: 0 27px 42px rgba(101, 101, 101, 0.3);/* -webkit-box-shadow: 0 27px 42px rgba(101, 101, 101, 0.4); */-moz-box-shadow: 0 27px 42px rgba(101, 101, 101, 0.4);-os-box-shadow: 0 27px 42px rgba(101, 101, 101, 0.4);} 
.remodal.modalNoShadow .left_titleBox { box-shadow: none !important; }
.remodal.minWidth{min-width:500px;min-height:300px;}
.remodal button{cursor:pointer;margin:0; padding:0;font-size:13px;color:#5f5f5f}
.remodal .md_titleBox{position:relative;}
.remodal .md_titleBox > div{position:absolute;top:17px;right:20px;}
.remodal .md_titleBox a{display:inline-block; float:left}
.remodal .md_add{margin-right:12px;}
.remodal h1 {
	background:transparent;
	font-size:14px;
	font-weight:bold;
	color:#484848;
	padding:18px 0 17px;
	position:relative;
	text-align:center;	
}
.remodal .left_titleBox{position:relative;padding: 0 20px;line-height: 61px;border-bottom:1px solid #d5d5d5;box-sizing:  border-box;border-radius: 15px 15px 0 0;overflow:hidden;}
.remodal .left_titleBox02{position:relative;padding: 0 20px;height: 62px;line-height: 62px;border-bottom: 2px solid #e7e9ea;position: absolute;z-index: 1000000000000;background: #fff;top: 0;width: 100%;left: 0;box-sizing: border-box;}
.remodal .left_titleBox h1,
.remodal .left_titleBox02 h1{padding: 0;font-size: 18px;float:left;}

.remodal .modal-topBtn{display:inline-block;padding: 0 17px;line-height:35px;margin-top:13px;color:#fff;border:0;outline:0;background-color:#477fff;border-radius:5px;margin-left:10px;cursor:pointer; font-size:14px;}

.remodal h1.borderbot{border-bottom:1px solid #e6e5e5;}
.remodal .remodal-close {
	position: absolute; top: -50px; right: -50px; width: 40px; height: 40px; background-color: #fff;border-radius: 50%;opacity: 0.5; border:none; outline:0;
}
.remodal.basicFilebox .remodal-close {
    right: auto;
    left: -60px;
    top: 10px;
    opacity: 0.9;
}

.remodal-close:hover,
.remodal-close:focus {opacity: 0.9;background-color: #fff;}

.remodal-close span {position: absolute; top: 13px; left: 19px; background-color: #000; display: block; width: 2px; height: 16px; transform: rotate(45deg); -webkit-transform: rotate(45deg);   }
.remodal-close span:first-child { transform: rotate(-45deg); -webkit-transform: rotate(-45deg); }

.remodal .remodal-inClose {
	position: absolute; right: 13px;bottom:15px; width: 29px; height: 29px; background-color: #666;border-radius: 50%; border:none;
}
.remodal-inClose span{position: absolute; top: 9px; left: 14px; background-color: #fff; display: block; width: 1px; height: 10px; transform: rotate(45deg); -webkit-transform: rotate(45deg);   }
.remodal-inClose span:first-child { transform: rotate(-45deg); -webkit-transform: rotate(-45deg); }

@media \0screen {

	.remodal{border:1px solid #7d7d7d;}
	.remodal .remodal-close{background:transparent url('../images/contents/ic_close.png') no-repeat 0 0;opacity: 0.5;}
	.remodal-close:hover,
	.remodal-close:focus {background:transparent url('../images/contents/ic_close_on.png') no-repeat 0 0;opacity: 0.9}
	.remodal-close span{width:0;}
	.remodal-overlay { 		
		opacity: 0.4;  
         filter:alpha(opacity=40); -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)"; /* For IE8 and earlier */   
        background-color:#000;  
	}
}

.remodal  .remodal-content{	position:relative ;padding:10px 20px;}
.remodal  .remodal-content02{padding:0px 12px 0 20px;}
.remodal  .remodal-content03{padding:0 12px 0 20px;	margin-bottom:30px;border-bottom:1px solid #e6e5e5e5;}
.remodal  .remodal-content04{border-bottom:0;}
.remodal  .remodal-content05{padding:25px 0 10px; font-size: 14px; font-weight: bold;}
.remodal  .remodal-content06{padding:46px;}
.remodal  .remodal-content07{padding:25px 35px 25px;}/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â³ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂÃƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ */
.remodal  .remodal-content08{padding:20px 35px;}
.remodal  .remodal-content09{padding:15px 14px 35px 35px;}/* ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ */
.remodal  .remodal-content10{padding:15px 25px 25px 25px;}/* ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒÂ¢Ã¢â€šÂ¬Ã‚Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ */
.remodal  .remodal-content11{padding:20px 10px 15px 35px;}/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â­ */

.modal-scroll{padding-right:18px;}
.remodal  .remodal-content .selectboxWrap label{font-size:14px}
.remodal .remodal-bottom{ position:relative ;font-size:0;}
.remodal-bottom.remodal-bottom02{ padding:10px 0 20px;}
.remodal .remodal-bottom > .modal-btn{background:#ff3737;font-family:'dotum',sans-serif;display:inline-block;width: 35%;height: 40px;font-weight:  bold;font-size:  14px;border:0;color: #fff;outline:0;padding: 0 20px;margin: 0 0 30px;border-radius: 5px;}
.remodal .remodal-bottom > .modal-btn:first-child{background:#477fff;margin-right:20px;}
.modal_message{padding:20px;color:#484848;cursor:default;}

/********** ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“Ãƒâ€¦Ã¢â‚¬Å“, ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â°Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Âµ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ **********/
#regisVoteScroll { padding-right:0;height: 100% !important; }
.voteModal .md_titleBox {z-index: 100000;position: absolute;top: 0;left: 0;width: 100%;height: 61px;background: #fff;}
.regisVoteWrap li.pb0 {  }
.voteModal.remodal .remodal-content03 {padding: 0;margin: 0;height: 100%;box-sizing: border-box;}
.voteModal { max-width:900px; width:100%;height:100%; margin-bottom:0; border-radius:0; float:right; }
.voteModal #regisVoteClose { right: auto;top: 10px;left: -60px; }
.remodal  .basic_offtime{color:#989898}/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚Â*/
.remodal  .basic_topbox{border-bottom:1px solid #e6e5e5;padding-top:2px;padding-bottom:10px;line-height:25px;overflow:hidden;text-align:left;}
.remodal  .basic_voteBoxWrap{border-bottom:1px solid #e6e5e5; overflow:hidden;text-align:left;}
.remodal  .basic_voteBoxWrap .basic_titleBox{padding:11px 0 24px 0;line-height:25px;}
.remodal  .basic_voteBoxWrap .basic_voteBox li{padding-bottom:25px;}
.remodal  .basic_voteBox li { position:relative; }
.remodal  .basic_voteBox li .checkboxGroup02{position:relative}
.remodal  .basic_voteBox li .checkboxGroup02 span{padding:4px 0;color:#484848;display:inline-block}
.remodal  .basic_voteBox .small_name{font-size:12px;line-height:25px;color:#989898;padding-left:28px;}

/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¯Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â°Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂºÃƒâ€šÃ‚Â */
.particiGroup{line-height:25px;padding:12px 25px 11px;text-align:left;}
.partici_no{margin-left:15px;color:#f16727 !important;}

.voteBox_bottBtn{margin-bottom:10px;}
.voteBox_bottBtnMb20{margin-bottom:20px;}

.stats-Bar {position:relative;margin-top:5px;height:5px;background-color:#e8e8e8;margin-left:28px;}
.stats-Bar p {height:5px;background:#ff7f2b;}

/******************************/

/* ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“Ãƒâ€¦Ã¢â‚¬Å“ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â°Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚Â¬ */
.remodal .voteBoxWrap {padding:0 25px;}
.remodal .personalInfo{overflow:hidden; }
.remodal .basic_name{color:#484848;}/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾*/
.remodal .personalImgbox{float:left;position:relative; width:40px;height:40px;margin-right:13px;overflow:hidden;margin-top:3px;}
.remodal .personalImgbox .cut_round{display:inline-block;position:absolute;left:0;top:0;z-index:10;}
.remodal .voteBoxWrap .title_vote{background:url('../images/contents/ic_md_vote.gif') no-repeat 0 0;color:#484848;padding-left:28px;}
.remodal .voteBoxWrap .allcountTxt {padding-left:28px;}/* ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¹Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¡Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¸ */

/* ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â°Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â */
.remodal  .title_attend{background:url('../images/contents/ic_md_cal.gif') no-repeat 0 2px;color:#484848;padding-left:25px;}
.remodal  .attendBoxWrap .basic_titleBox{padding-right:25px;}
.remodal  .attendBoxWrap .allcountTxt {padding-left:25px;}/* ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â°Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¹Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¡Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¸ */
.remodal  .title_attend02{background:url('../images/contents/ic_md_attend.gif') no-repeat 0 0;color:#484848;padding-left:25px;}
.remodal  .basic_voteBox.attendBox{padding:0 25px;}
.remodal  .basic_offtime.pl25{padding-left:25px}

/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â·Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€šÃ‚Â¹ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã¢â‚¬Å“Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ */
.shareGroup li{padding:5px 0;border-bottom:1px solid #e6e5e5;text-align:left;}
.shareGroup li:last-child{border-bottom:0px}

/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â·Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€šÃ‚Â¹ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€¦Ã¢â‚¬â„¢ */
.teamG .teamName{background:url('../images/contents/im_group.png') no-repeat 7px 50%;float:left;line-height:1.3;padding:10px 0 10px 52px;color:#484848;}

/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â©ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â Ãƒâ€šÃ‚Â¥ */
.memoTextarea{min-width:500px;min-height:200px;max-width:900px;max-height:300px;}

/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°Ãƒâ€¦Ã¢â‚¬Å“ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬Å“ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ */
.perG .perPhoto{float:left;position:relative;width:38px;height:38px;overflow:hidden;}
.perG .cut_round{display:inline-block;position:absolute;left:-1;top:-1;z-index:10;}
.perG .perName{float:left;line-height:1.3;padding:10px 0 10px 15px;color:#484848;}
.perG .checkG{margin-top:7px;}

/* ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â³Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â° */
.view_receiver li{padding:5px 0;border-bottom:1px solid #e6e5e5;text-align:left;}
.view_receiver li:last-child{border-bottom:0px}

/* ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â«Ãƒâ€¦Ã‚Â¾Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ */
.setAlarms.remodal .left_titleBox {background-color: #fff; border-radius:15px 15px 0 0}
.setAlarms.remodal .left_titleBox h1 { color:#000; }
.alOption li{line-height:50px;padding:0 10px;border-bottom:1px solid #f1f3f5;;text-align:left;}
.alOption li:last-child{border-bottom:0px}
.setAlarms.remodal {width: 410px;}
.setAlarms.remodal .remodal-content03 {margin: 0;border-bottom:0 none;padding: 0;}
.setAlarms.remodal .modal-scroll { padding-right:0; }
.setAlarms.remodal li { position:relative; }
.setAlarms.remodal li:hover span { font-weight:bold; }
.setAlarms.remodal li span { padding-left:55px; font-size:13px; }
.setAlarms.remodal p.fr { position:absolute; left:30px; top:50%; margin-top:-10px;  }
.setAlarms.remodal .fr input.radio_round03[type='radio']:not(old) + label {background: #e5e8ea url(../images/contents/checked_gray.png) no-repeat 1px 2px;font-weight: bold;color: #4e4e4e;width: 20px;height: 20px;padding: 0;border-radius: 50%;background-size: 18px;}
.setAlarms.remodal .fr input.radio_round03[type='radio']:not(old):checked + label, .setAlarms.remodal li:hover input.radio_round03[type='radio']:not(old) + label {background: #477fff url(../images/contents/checked_fff.png) no-repeat 1px 2px;background-size: 18px;}


/* ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â­Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ chooseTime */
.chooseTime.remodal .modal-topBtn {    background-color: #477fff;
    height: 35px;
    margin-top: 13px; }
.chooseTime.remodal .left_titleBox {background-color:#fff;border-radius:15px 15px 0 0}
.chooseTime.remodal .left_titleBox h1 { color:#000; }
.chooseTime.remodal .remodal-content07 { padding: 25px 35px 35px; }
.chooseTime.remodal {width: 390px !important;}
.chooseTime .selectboxWrap select {height: 100%;line-height: 100%;}
.chooseTime.remodal .selectboxWrap {height: 40px;line-height: 40px;background: #ffffff url(../images/side_area/bt_open.png) no-repeat 69px center;width: 100px;background-color: #f3f3f3;border-radius: 5px;}
.chooseTime.remodal .selectboxWrap:hover {background-color: #eaeaea;}
.chooseTime.remodal label {font-weight: bold;}



/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â°Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢*/
.repeatModal.remodal .modal-topBtn {    background-color: #477fff;height: 35px;margin-top: 13px; }
.repeatModal.remodal .left_titleBox {background-color: #fff; border-radius:15px 15px 0 0}
.repeatModal.remodal .left_titleBox h1 { color:#000; }
.repeatDay{margin-bottom:10px;text-align:left;}
.repeatDay span{margin-top:7px;color:#484848;}
.repeatDay_inbox{margin-bottom:10px;}
.repeatDay_inbox .inn_txt{float:left;}
.repeatDay_inbox .input_txt{margin-left:4px;float:left;}
.repeatModal .repeatDay input[type='checkbox']:not(old) + label {height: 40px;margin: 0;line-height: 40px;box-sizing: border-box;border-radius: 5px;padding-top: 0;padding-left: 30px;padding-right: 13px;margin-top: -4px;background: #c1cfd8 url(../images/contents/checked_fff.png) no-repeat 6px center;background-size: 22px;}
.repeatModal .repeatDay input[type='checkbox']:not(old):checked + label { background: #477fff url(../images/contents/checked_fff.png) no-repeat 6px center;background-size: 22px;color:#fff}
.repeatModal .remodal-content07 { padding: 20px 0 25px; }
input#repeatModalVal {height: 35px;width: 45px !important;margin: 0 0 0 10px;background: #fff;border: 1px solid #e1e4e6;border-radius: 0;}
input#repeatModalVal:hover { background:#f1f3f5; }
input#repeatModalVal:focus,input#repeatModalVal:focus:hover { background:#fff; border: 1px solid #477fff; }
.repeatDay_inbox .inn_txt {margin-top: 0 !important;line-height: 35px;}
span#repeatSpanTxt1 {padding-left: 30px;}
.repeatModal span.fl.inn_txt {padding-left: 30px;}
p.fl.mt04.ml08 {padding-right: 30px;}
.repeatModal .repeatDay input[type='checkbox']:not(old):checked + label:after {content: '2999.01.01';padding-left: 10px;display: block;position: absolute;width: 200px;font-size: 12px;color: #9ca1a5;height: 46px;background: white;bottom: 30px;right: 114px;z-index: 10000;}
.repeatDay_inbox .input_txt {margin-left: 4px;float: left;background: #f3f3f3;border-radius: 5px;text-align: center;font-weight: bold;width: auto !important;padding: 0;box-sizing: border-box;max-width: 50px;height: 40px;margin-right: 0;margin-left: 10px;}
input#repeatEndDate {width: 120px !important;background: #f3f3f3;border-radius: 5px;padding: 10px 10px;text-align:center;font-weight:bold;color:#434343;font-family:'Noto Sans',sans-serif;}
input#repeatEndDate:hover {cursor: pointer;background-color: #eaeaea;}
.datepickerWrap span {position: absolute;right: 13px;top: 12px;}
.repeatDay  p {float: right !important;margin-top: 4px !important;}
.repeatDay span {margin-top: 14px !important;}
.datepickerWrap .mt0 { display:none;}
.repeatDay_inbox {margin-bottom: 15px;border-bottom: 1px solid #ececec;padding-bottom: 15px;}
.weekDay{display:inline-block;width: 40px !important;height: 40px !important;background: #f3f3f3;color:#5f5f5f;padding:0 !important;margin-left:14px;font-size: 13px;padding-top: 0;text-align: center;line-height: 40px;border-radius: 50%;}
.weekDay:hover ,.weekDay.on{background: #477fff !important;color:#fff;font-weight: bold;font-size: 14px;box-shadow: 0 2px 5px rgba(0,0,0,0.1);transition: 0.1s;}
.weekSelect_box{margin-bottom:15px;padding-bottom: 15px;border-bottom: 1px solid #eaeaea;}
.repeateModal.remodal {width: 400px;}
div#repeatModalRadioDiv {border-bottom: 1px solid #ececec;margin-bottom: 15px;padding-bottom: 5px;}
#addFileTr h3 { opacity: 0.6 }
#repeatModalRadioDiv input.radio_round[type='radio']:not(old) + label {margin-left: 30px !important;background:url('../images/contents/ic_radio.png') no-repeat center left;line-height: 23px;height: 23px;padding-left: 31px;font-size: 14px;}
#repeatModalRadioDiv input.radio_round[type='radio']:not(old):checked + label {background:url('../images/contents/ic_radio_on.png') no-repeat  center left;}


/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â°Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢-ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â§Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â£Ãƒâ€šÃ‚Â¼ */
.weekDay{display:inline-block; width:27px;height:20px;color:#5f5f5f;margin-left:14px;padding-top:7px;}
.weekDay:hover ,.weekDay.on{background:url('../images/contents/bt_md_calSelect.png') no-repeat 0 0;color:#fff;}
.weekSelect_box{margin-bottom:15px;margin-top: -5px;}

/* ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“Ãƒâ€¦Ã¢â‚¬Å“ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…â€œÃƒâ€šÃ‚Â±ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€šÃ‚Â */
.voteModal input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 16px;height: 16px;border-radius:3px;border:2px solid #c1cfd8;background: #c1cfd8 url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
.voteModal input[type='checkbox']:not(old):checked + label {background: #477fff url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;border: 2px solid #477fff;}
.regisVoteWrap {position: relative;padding: 0;}
.regisVoteWrap:last-child {margin-bottom: 0; }
.regisVoteWrap:hover > ul > li:first-child input.btn { display: block; }
.regisVoteWrap > ul > li.title  input.btn {display:none;border:0 none; font-size:0;left: 13px;top: -15px;width: 35px;height: 35px;padding: 0;background: #ff4040 url('../images/contents/ic_delImg.png') no-repeat center center;background-size: 15px;}
.regisVoteWrap > ul > li.title  input.btn:hover {box-shadow:0 3px 5px rgba(0,0,0,0.1);}
.regisVoteWrap > ul > li.title .input_txt {transition:0.1s;font-size: 18px;margin-top:0 !important;}
.regisVoteWrap > ul {padding-bottom: 0;overflow: hidden;}
.regisVoteWrap > ul > li.title {display: block;width: 100%;padding: 0;}
.regisVoteWrap > ul > li.pb0 {display: block;}
.regisVoteWrap > ul > li.clearFix {background:#fff;display: block;width: 100%;border-bottom: 2px solid #e7e9ea;background: none;height: 55px;padding: 0;}
.regisVoteWrap > ul > li > div > .input_txt:hover { background:#f1f3f5; border-bottom: 2px solid #e7e9ea !important; }
.regisVoteWrap > ul > li > div > .input_txt:focus:hover, .regisVoteWrap > ul > li > div > .input_txt:focus {background:#fff;border-bottom: 2px solid #477fff !important;}
.regisVoteWrap > ul > li.clearFix > div > .input_txt {float:left;line-height: 55px;height: 55px;border-bottom: 2px solid #e7e9ea;color:#273044; width: 100% !important;padding: 0 10px;text-align: center;}
.regisVoteWrap > ul > li.clearFix > div > .input_txt:focus { color: #477fff; }
.regisVoteWrap > ul > li.clearFix > div > .input_txt:hover {cursor: pointer;color: #477fff;}
.regisVoteWrap > ul > li.clearFix .label {background: #f1f3f5;line-height:53px;height: 53px;font-weight: bold;width: 90px;text-align: center;padding: 0;margin-right:0;}
.datepickerWrap span {display: none;}
.regisVoteWrap > ul > li.clearFix  .selectboxWrap label {font-size: 14px;font-weight: bold;line-height:53px;width: 30px;padding:0 10px;left: 0;text-align: center;}
.regisVoteWrap > ul > li.clearFix .selectboxWrap:hover label {border-bottom: 2px solid #477fff; color:#477fff;}
.regisVoteWrap > ul > li.clearFix .selectboxWrap select {background: none !important;height: 100%;line-height: 100%;min-height: 100%;width:50px;padding: 0 10px 0 0;}
.regisVoteWrap > ul > li.clearFix .selectboxWrap {background: none;height: 50px;line-height: 50px;margin-left: 0 !important;width: 90px;}
.regisVoteWrap > ul > li.clearFix .datepickerWrap {width: 180px;}
.regisVoteWrap > ul > li {position: relative;display: inline-block;width: 33.3%;box-sizing: border-box;float: left;padding: 10px;border-right: 1px solid #fff;}
.options > ul > li {position: relative;display: inline-block;width: 33.3%;box-sizing: border-box;float: left;padding: 10px;border-right: 1px solid #e7e9ea;}
.options > ul > li:last-child { border-right: 0 none; }
.regisVoteWrap > ul > li:hover {background: #e8eaec;}
.regisVoteWrap > ul > li.clearFix:hover {background: none;}
.options > ul > li .regisVoteSpan {color: #acb2bf;width: 180px;font-weight: bold;line-height: 18px;margin-left: 44px;padding: 0;height: 35px;margin-top: 9px;}
.options > ul > li.on .regisVoteSpan{color:#273044}
.regisVoteWrap > ul > li.pb0 {position: static;width:100%;padding: 0;}
.regisVoteWrap input.btn {position: absolute;top: 10px;z-index: 100000;right: 20px;margin-left: -30px;font-weight: bold;background: #fff;border: 1px solid #e7e9ea;}
.regisVoteWrap input.btn:hover {background: #477fff;color: #fff;}
.regisVoteWrap li{padding: 0;text-align:left;}
.regisVoteWrap li .regisVote_inbox li{position: relative;border-bottom:0 none;height:55px;}
.regisVoteWrap li .regisVote_inbox li .input_txt { border-bottom:1px solid #f1f3f5; padding-left:40px; }
.regisVoteWrap li .regisVote_inbox li .input_txt:hover { background:#f1f3f5; transition:0.1s; border-bottom:1px solid  #e7e9ea; }
.regisVoteWrap li .regisVote_inbox li .input_txt:focus, .regisVoteWrap li .regisVote_inbox li .input_txt:focus:hover { background:#fff; border-bottom:1px solid #477fff; }
.regisVoteWrap li .regisVote_inbox li:last-child{border-bottom:0;background:none;}
.regisVoteWrap > ul > li > div > .input_txt {line-height: 70px;height: 70px;border-bottom: 2px solid #e7e9ea;}
.regiVote_delete{display:inline-block;position: absolute;right:10px;width: 40px;height: 50px;text-align: center;opacity: 0.5;}
.regiVote_delete:hover { opacity:1; }
.regisVoteWrap .round_radiobox{display:none;}
.regisVoteSpan{float:left;padding:8px 0 7px;color:#484848;}
.regisVoteWrap .checkGroup{margin-top:-1px;margin-right:3px;position: absolute;left: 13px;top: 16px;}
.regisVoteWrap .label{padding:8px 0 7px;color:#484848;margin-right:12px;}
.regisVoteWrap .input_txt{padding-left:0;padding-right:0;width: 100% !important;line-height: 55px;height: 55px;font-size: 14px;padding: 0 70px 0 20px;box-sizing: border-box;margin: 0;font-weight:bold;}
.regisVoteWrap .datepicker{padding-left:10px;padding-right:10px;}
.regisVote_inbox .input_txt {font-size: 13px;}
.regiVote_delete img {display: inline-block;margin-top: 17px;}


/* ÃƒÂ¬Ã¢â‚¬â€Ã¢â‚¬Â¦ÃƒÂ«Ã‚Â¬Ã‚Â´ÃƒÂ­Ã‹Å“Ã¢â‚¬Å¾ÃƒÂ­Ã¢â€žÂ¢Ã‚Â© */
.remodal.workStatus {width:100%;max-width: 900px;float: right;height: 100%;border-radius: 0;margin-bottom: 0;}
.remodal.workStatus .remodal-close {right: auto;left: -60px;top: 10px;}
.workStatus .left_titleBox {z-index:1000;position: absolute;width: 100%; box-shadow: none !important; border-bottom: 2px solid #e7e9ea  !important }
p.refreshDescript {position: absolute;right: 70px;opacity: 0.5;font-weight: bold;}
.workStatus input.refreshBtn {width: 40px;height: 61px;font-size: 0;background: url(../images/contents/ic_repeat_bk.png) no-repeat center center;}
.workStatus input.refreshBtn:hover { background: url(../images/contents/ic_repeat_bk_on.png) no-repeat center center;}
.workStatus .remodal-content07{padding: 62px 0 0;clear: both;float: left;width: 100%;box-sizing: border-box;}
.chartBg {overflow: hidden;width: 100%;border-bottom: 1px solid #e7e9ea;padding: 28px 165px 30px 61px;box-sizing: border-box;position: relative;height: 92px;}
.chartBg .seeMore {position: absolute;right: 53px;background: #fff;font-weight: bold;top: 23px;line-height:40px;}
.chartBg .seeMore:hover { color:#477fff; }
.chartBg.on .seeMore {font-size: 0;background: #fff url(../images/contents/ic_close.gif) no-repeat center center;width: 40px;height: 40px;border: 1px solid #e7e9ea;border-radius: 50%;right: 53px;top: 27px;}
.chartBg.on .seeMore:hover { background-color:#f1f3f5; }
.BarChart {position:relative;float: left;width: 100%;box-sizing: border-box;}
.BarChart .barUl li {transition:0.2s;float: left;position: relative;width: 20%;height: 18px;}
.BarChart .barUl li .num {position: absolute;bottom: -20px;font-weight: bold;left: 50%;margin-left:-4px}
.BarChart > ul.barUl li:hover .num {bottom: -25px;transition: 0.1s;}
.BarChart .barUl li.zero .num {opacity: 0.5;}
.BarChart > ul.barUl {width: 100%;background:#f1f3f5;border-radius:20px;/* CLEAR: BOTH; */float: left;margin-bottom: 0px;}
.BarChart .barUl li .txt {font-size: 0;width: 100%;height: 100%;display: inline-block;float: left;}
.BarChart > ul.barUl li:first-child span {border-radius: 20px 0 0 20px;}
.BarChart > ul.barUl li:last-child span {border-radius: 0 20px 20px 0;}
ul.details {display: block;overflow: hidden;width: 70%;text-align: left;margin: 0 auto;margin-top: 40px;}
ul.details li {display: block;width: 100%;}
ul.details li .dot { display:inline-block; width:8px; height:8px; border-radius:50%; font-size:0;  }
.details2 {width:100%;display:block;margin-bottom: 50px;}
.details2 h3 {text-align:left;font-size:16px;text-align: center;padding: 0 40px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.details2 p { text-align:left; font-size:12px; }
.remodal.workStatus h3.total {line-height: 40px;font-size: 16px;text-align: left;padding: 0 55px;box-sizing: border-box;width: 100%;margin-top: 30px;margin-bottom: 10px;}
.remodal.workStatus h3.total span {float: right;font-size: 12px;}
.details2 p.status span {font-weight: bold;color: #477fff;}
.details2 p.status {line-height: 25px;margin-top: 21px;text-align: center;}
.titleBg h2 a {transition:0.3s;line-height: 70px;display: block;border-bottom: 1px solid #e7e9ea;font-size: 16px;text-align: left;/* background: #fff url(../images/side_area/bt_open.png) no-repeat right center; */padding-right: 80px !important;box-sizing: border-box;padding: 0 25px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
/* .titleBg h2 a:hover { background:#f1f3f5; transition:0.3s; } */
.titleBg h2 a .dot {display: inline-block;width: 8px;height: 8px;border-radius: 50%;margin-top: 31px;font-size: 0;float: left;margin-right: 15px;}
.titleBg h2 a:after { /* background: url(../images/side_area/bt_open.png) no-repeat center center; */ content: '';display: block;width: 70px;height: 70px;position: absolute;right: 0;top: 0;}
.titleBg h2 a.on:after {background: url(../images/side_area/bt_close.png) no-repeat center center; }
.titleBg h2 {position: relative;}
.workStatus .titleBg {position: relative;}
.workStatus .dropdown {z-index:10000000000;text-align:left;top: 71px;box-shadow: 0 15px 10px rgba(0,0,0,0.1);overflow-y:auto;max-height: 305px;}
.workStatus .dropdown a { line-height:50px;padding: 0 25px; }
.workStatus .dropdown .dot { width:7px; height:7px; display:inline-block; border-radius:50%; font-size: 0; margin-right:15px;margin-top: 21px;float: left; }
.workStatus .dropdown li { position:relative; }
.workStatus .dropdown li.on a { font-weight:bold;  }
.workStatus .dropdown li.on a:hover { background:#fff; cursor:default; }
/* .workStatus .dropdown li.on { background:url(../images/contents/check.png) no-repeat center right; } */
.workStatus .dropdown li.on a:after {background: url(../images/contents/checked_blue.png) no-repeat center center;position: absolute;right: 0;top: 0;width: 60px;height: 50px;content: '';display: block;z-index: 1000;}
.workStatus .bg {display:none;position: absolute;width: 100%;height: 843px;background: #fff;left: 0;opacity: 0.6;top: 133px;z-index: 100000000;}
.workStatus .bg.on { display:block; }

.chartBg .details { height:0; transition:0.2s; }
.chartBg.on .BarChart .barUl li .num {width: 100%;left: 0;margin-left: 0;bottom:-30px;}
.chartBg.on .BarChart .barUl li:hover .num { bottom:-35px }
.chartBg.on .barUl:after {position: absolute;content: '';display: block;background: #e7e9ea;width: 100%;height: 2px;top: 250px;border-radius: 50%;left: 0;}
ul.details li {line-height: 35px;font-weight: bold;}
ul.details li .dot {float: left;margin-top: 14px;margin-right: 15px;}
ul.details li .num {float: right;display: inline-block;}
.chartBg.on {height: auto;padding: 43px 120px 60px;}
.chartBg .details2 { display:none }
.chartBg.on .details2 {display:block;text-align: center;}
.chartBg.on .details { height:auto;transition:0.2s; }
.chartBg.on .barUl {width:100%;padding-bottom: 40px;border-radius: 0;background: #fff;text-align: center;margin-bottom: 30px;position: relative;}
.chartBg.on .barUl li { float:none; transition:0.2s; }
.chartBg.on .barUl li {height: 250px;background: #f1f3f5;width: 45px !important;display: inline-block;margin: 0 20px;}
.chartBg.on .barUl li .txt {transition:0.2s;height: 20%;position: absolute;left: 0;bottom: 0;border-radius: 0;}
.chartBg .barUl li .txt {transition:0.2s;}
.chartBg.on .BarChart > ul.barUl li:first-child span { width:100%; left:0; text-align: center; }
.chartBg.on.four .barUl li {width: 60px !important;}
.chartBg.on.three .barUl li {width: 80px !important;}
.chartBg.on.two .barUl li {width: 80px !important;}

.workStatus .chartBg.on .barUl li { border-radius:0 !important; }
.workStatus .chartBg.on .barUl li .txt { height:3%; border-radius:0 !important;}
/*
.workStatus .chartBg.on .barUl li:hover{box-shadow: 0 5px 10px rgba(20, 44, 68,0.2); background:#fff; transition:0.2s !important}
.workStatus .chartBg.on .barUl li:hover .num { bottom:-30px; transition:0.2s; }
.workStatus .chartBg.on .barUl li {transition:0.2s !important}
*/

/*  */
.chartBg.on .barUl li:first-child .txt.ani {transition-delay: 0.1s;transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);transition-duration: 0.5s;}
.chartBg.on .barUl li:nth-child(2) .txt.ani {transition-delay: 0.15s;transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);transition-duration: 0.5s;}
.chartBg.on .barUl li:nth-child(3) .txt.ani {transition-delay: 0.2s;transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);transition-duration: 0.5s;}
.chartBg.on .barUl li:nth-child(4) .txt.ani {transition-delay: 0.25s;transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);transition-duration: 0.5s;}
.chartBg.on .barUl li:nth-child(5) .txt.ani {transition-delay: 0.3s;transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);transition-duration: 0.5s;}

.chartBg.on .barUl li:first-child .txt.ht {height:30%;}
.chartBg.on .barUl li:nth-child(2) .txt.ht {height:30%;}
.chartBg.on .barUl li:nth-child(3) .txt.ht {height:40%;}
.chartBg.on .barUl li:nth-child(4) .txt.ht {height:3%;}
.chartBg.on .barUl li:nth-child(5) .txt.ht {height:50%;}

.chartBg.on .barUl li .txt.transit { transition:0.2s; }

.chartBg .barUl li:first-child { width:15% }
.chartBg .barUl li:nth-child(2){ width:15% }
.chartBg .barUl li:nth-child(3) { width:30%; }
.chartBg .barUl li:nth-child(4) { width:2% }
.chartBg .barUl li:nth-child(5) { width:38% }


.chartBg.on .barUl li:hover .txt.dc0 { box-shadow: 0 -5px 10px rgba(254, 99, 99, 0.5); transition:0.1s; }
.chartBg.on .barUl li:hover .txt.dc1 { border-top:2px solid #FFA70E; transition:0.1s; }
.chartBg.on .barUl li:hover .txt.dc2 { border-top:2px solid #FFC72F; transition:0.1s; }
.chartBg.on .barUl li:hover .txt.dc3 { border-top:2px solid #FF198B; transition:0.1s; }
.chartBg.on .barUl li:hover .txt.dc4 { border-top:2px solid #00B2C7; transition:0.1s; }
.chartBg.on .barUl li:hover .txt.dc5 { border-top:2px solid #13D08B; transition:0.1s; }
.chartBg.on .barUl li:hover .txt.dc6 { border-top:2px solid #4DBAFF; transition:0.1s; }
.chartBg.on .barUl li:hover .txt.dc7 { border-top:2px solid #477FFF; transition:0.1s; }
.chartBg.on .barUl li:hover .txt.dc8 { border-top:2px solid #6854FF; transition:0.1s; }
.chartBg.on .barUl li:hover .txt.dc9 { box-shadow: 0 -5px 10px rgba(53, 64, 90, 0.5); transition:0.1s; }

.chartBg.on .dc0.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(255, 138, 138));}
.chartBg.on .dc1.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(85, 98, 132)); }
.chartBg.on .dc2.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(255, 223, 135));}
.chartBg.on .dc3.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(85, 98, 132)); }
.chartBg.on .dc4.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(255, 138, 138));}
.chartBg.on .dc5.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(46, 247, 174)); }
.chartBg.on .dc6.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(255, 138, 138));}
.chartBg.on .dc7.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(117, 159, 255)); }
.chartBg.on .dc8.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(255, 138, 138));}
.chartBg.on .dc9.dcG { background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(85, 98, 132)); }


.dc0.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(255, 138, 138));}
.dc1.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(85, 98, 132)); }
.dc2.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(255, 223, 135));}
.dc3.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(85, 98, 132)); }
.dc4.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(255, 138, 138));}
.dc5.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(46, 247, 174)); }
.dc6.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(255, 138, 138));}
.dc7.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(117, 159, 255)); }
.dc8.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(255, 138, 138));}
.dc9.dcG { background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(85, 98, 132)); }

/* .workStatus .people { height:740px; } */
.people .ul1 > li:first-child > a {margin-top: 0;}
.workStatus .people ul img {width: 40px;border-radius: 50%;border: 1px solid #f1f3f5;box-sizing: border-box;background: #f1f3f5;display:inline-block;float: left;position: absolute;left: 0;top: 0;}
.workStatus .people .name {display:inline-block;line-height: 45px;float: left;padding-left: 60px;font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;padding-right: 20px;width: 100%;box-sizing: border-box;}
.workStatus .ul1 > li { position: relative; }
.people p.step {position:absolute;z-index: 100;left: 51px;top: 1px;width: 35px;height: 35px;color: #fff;border: 9px solid #fff;font-size: 16px;font-weight: bold;line-height: 35px;border-radius: 50%;text-align:center;}
.workStatus .people ul > li {position: relative;text-align: left;margin-bottom: 20px}
.workStatus .people .ul1 > li:last-child { margin-bottom: 60px }
.workStatus .people .ul1 ul > li {margin-bottom: 10px; margin-top: 10px; }
.workStatus .people .ul1 ul > li:last-child { margin-bottom: 30px }
.people .ul1 > li > a .txt {width: 70%;display: inline-block;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; float: left;}
.people .ul1 > li:hover .step {transform: scale(1.15);transition: 0.1s;}
.people .ul1 > li > a {overflow: hidden;line-height: 55px;padding: 0 70px 0 120px;box-sizing: border-box;font-size: 14px;font-weight: bold;display: block;position: relative;margin-top: 10px;}
.people .ul1 > li.on > a .btn {background: url(../images/side_area/bt_open.png) no-repeat center center;  }
.people .ul1 > li.on ul { transition:0.2s;height:0; opacity:0; } 
.workStatus .people ul ul {transition:0.2s;overflow:hidden;padding: 0 60px 0 120px;opacity:1;}
.workStatus .people ul ul li { display:inline-block; float:left; width:33.3%; }
.workStatus .people .done .name {opacity: 0.6;}
.workStatus .people .done:after {content: '';display: block;width: 40px;height: 40px;position: absolute;left: 0;top: 0;background: rgba(255,255,255,0.6) url(../images/contents/doneImg.png) no-repeat  -3px -1px;border: 2px solid #477fff;z-index: 100000;box-sizing: border-box;border-radius: 50%;}
.workStatus .people ul ul li.noResult {color: #878c96;width: 100%;line-height: 60px !important;display: block;position: static;text-align: center;font-weight: bold;background: #f1f3f5;height: 60px;border-radius: 5px;}
.workStatus .people .ul1 > li:after {content: '';display: block;position: absolute;left: 76px;top: 25px;z-index: 1;height: 100%;width: 2px;background: #f1f3f5;}
.workStatus .people .ul1 > li:last-child:after {display: none;}
.ul1 > li > a span.num, .ul1 > li > a span.btn, .ul1 > li > a span.seper {float: right;}
.ul1 > li > a span.seper {display:block;width:2px;height: 20px;margin: 18px 40px 0 10px;background: #e7e9ea;}
.ul1 > li > a span.btn {width:30px;height:30px;display:inline-block;background: url(../images/side_area/bt_close.png) no-repeat center center;padding:0;position: absolute;right: 60px;top: 12px;font-size: 0;}
.ul1 > li > a span.num {margin-right: 20px;}
.workStatus .people2 .ul2 li.ing a:after {content: '요청 중';display: inline-block;font-size:12px;line-height: 28px;position: absolute;width: 70px;left: 0;top: 0;}
.workStatus .people2 .ul2 li.ing a {font-size: 0;}

.people .ul1.check li .step {background: url('../images/contents/checked_fff.png') no-repeat center center;font-size: 0;}


.workStatus .people2 .ul2 li.ing img {opacity: 0.6;}
.workStatus .people2 .ul2 li img {left: 10px;background: #fff;}
.workStatus .people2 .ul2 li.ing .name {opacity: 0.6;}
.workStatus .people2 .ul2 li.ing a:hover {cursor: default; box-shadow:none}
.workStatus .people2 {position:relative;background:#f1f3f5;padding: 40px 60px 80px;}
.workStatus .people2 h3 { font-size:16px; text-align: left; margin-bottom:15px;  }
.workStatus .people .people2 img { border: 1px solid #fff; width:40px; height:40px; }
.workStatus .people2 .ul2 { overflow:hidden; }
.workStatus .people2 .ul2 li {width:50%;float:left;margin:10px 0;padding: 0 20px 0 10px;box-sizing: border-box;}
.workStatus .people2 .sub {text-align:left;margin-bottom: 10px;font-size: 12px;border-bottom: 1px solid #e7e9ea;padding-bottom: 25px;}
.people2 > .btn {position: absolute;top: 40px;right: 60px;padding: 0;font-size: 14px;font-weight: bold;}
.ul2 li a {display: inline-block;line-height: 28px;padding: 0;border-radius: 20px;background: #fff;position: absolute;width: 70px;right: 20px;top: 9px;text-align: center;font-weight: bold;color: #000;}
.workStatus .people2 .ul2 li:hover a {background: #ffc730;transition:0.2s;}
.workStatus .people2 .ul2 li a:hover {background: #ffbe10;transition:0.2s;box-shadow: 0 3px 10px rgba(255, 190, 16, 0.5);}
.ul2 li.ing a {background: #ffc730;color: #000; opacity:0.5}
.workStatus .people .ul2 .name {padding-right: 120px;padding-left: 54px;}
.workStatus .people .total { display: inline-block;margin-left: 0px;font-weight: bold;;color:#000; }
.btn a.requestBtn {font-size: 12px;line-height: 28px;margin-bottom:5px;padding: 0 15px;display: inline-block;border-radius: 15px;background: #ffc730;color:#000; }
.btn a.requestBtn:hover {background: #ffbe10;}
.people2 > .btn p {font-size: 12px;color: #000;}
.people2 > .btn p span {color: #d89e00;}

@media only screen and (max-width: 800px) {
    .workStatus .people ul ul li { width:50%; }
}


/* ÃƒÂ«Ã…â€™Ã¢â‚¬Å“ÃƒÂªÃ‚Â¸Ã¢â€šÂ¬ */




/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬*/
.remodal.w1110 h1 { font-size:18px; padding: 25px 30px 20px; text-align:left; }
.remodal.w1110 .remodal-content05 {padding:0;border-radius: 0 0 15px 15px;overflow: hidden;}

/* ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¡Ãƒâ€šÃ‚Â©ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬, ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°Ãƒâ€¦Ã¢â‚¬Å“ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â³Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â·Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â°ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â°Ãƒâ€šÃ‚Â©ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¹Ãƒâ€šÃ‚Â¨ */
.graybox{padding:8px 10px ;background-color:#f5f5f5;height:381px;text-align:left;line-height:20px;font-family:'dotum',sans-serif;}

/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â­ */
.bt_prev_news { background: url('../images/contents/bt_prev_news.png') no-repeat center 9px;  }
.bt_next_news {background: url('../images/contents/bt_next_news.png') no-repeat center 9px;  }
.bt_beginning_news {background: url('../images/contents/bt_beginning_news.png') no-repeat center 9px;  }
.bt_end_news {background: url('../images/contents/bt_end_news.png') no-repeat center 9px;  }

.remodal-content11 .modal-scroll{padding-right:25px;}
.regisNewsfeedWrap .confirmBoxWrap{display:none}
.regisNewsfeedWrap > ol > li > ol > li > a { display: block; padding: 12px 0; width: 100%;overflow:hidden;  }
.regisNewsfeedWrap > ol > li  a .numbering { display: inline-block; float: left; width: 20px; height: 33px; text-align: left;font-weight: bold ; }

.regisNewsfeedWrap > ol > li > ol > li {border-bottom: 1px solid #fff;transition: 0.2s;}
.regisNewsfeedWrap > ol > li > ol > li.on {border-bottom: none;height: 350px;transition: 0.2s;}
.regisNewsfeedWrap li { list-style: none; position:relative; }
.regisNewsfeedWrap li .groupping1 { display: inline-block;text-align:left ;float:left}
.regisNewsfeedWrap li .groupping1 h2 { padding-bottom: 8px; font-weight: bold }
.regisNewsfeedWrap li .groupping2 .yrmmdd { padding-bottom: 8px; }
.regisNewsfeedWrap li .groupping2 { display: inline-block; float: right; text-align: right  }
.pagination { text-align: center; padding: 19px 0;font-size:0 }
.pagination > div{overflow:hidden;display:inline-block;}
.pagination .prevBtns, .nextBtns {float:left; display: inline-block;  }
.pagination a {float:left;display: inline-block;width: 20px;font-weight: bold;border-radius: 5px;height:20px;color: #484848 !important;padding-top:2px;font-size: 12px;line-height:20px;}
.basicFilebox .fileBox_rightArea .pagination a.active, .fileBox4doc .fileBox_rightArea .pagination a.active:hover {color: #ffffff !important;background: #477fff !important;}
.regisNewsfeedWrap li.active { display: block !important;  }
.regisNewsfeedWrap .confirmBoxWrap{display:none;background-color:#fff;}
.regisNewsfeedWrap .confirmBoxWrap .confirmListBox{height: 162px !important;overflow:hidden}
.regisNewsfeedWrap .confirmListBox .photoBox{padding:1px 0 0;}
.regisNewsfeedWrap .notice_detail{position:relative; padding:15px 18px 0;}
.regisNewsfeedWrap .notice_detail .bt_expand{position:absolute;display:inline-block;  right:20px;bottom:20px;width:29px;height:29px;;background:url(../images/contents/im_expand.png) no-repeat 0 0;z-index:100}
.regisNewsfeedWrap .close{display:none;margin-right:-5px;width:25px;height:25px;background:url(../images/contents/ic_listClose.png) no-repeat 9px 9px;}
.regisNewsfeedWrap .contEye:hover{color:#6d6d6d;background:url(../images/contents/im_eye_on.png) no-repeat left 8px;}
.br15 .remodal-content11 { border-radius:0 0 15px 15px; }
.regisNewsfeedWrap .modal-topBtn  { margin-top:0; }

.addNewsfeed .cke_contents { min-height: 305px; }

/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â­ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¸ */
.noticeWrap{background-color:#fff;display:  block !important;border-radius: 5px;}
.notice_detail{padding: 20px 20px 35px 30px;height:292px;overflow:hidden;text-align:left;box-sizing:border-box;}
.notice_detail ul li{list-style-type:disc;}
.notice_detail ol li{list-style-type:decimal;}
.notice_detail a{text-decoration:underline;color:#0066cc}
.notice_detail img {/*max-width:546px;*/max-width: 100%;border: 1px solid #e7e9ea;}

.noticeWrap.withBtns .notice_detail.p_noticeDesc {padding-top: 72px;}
.regisNewsfeedWrap .confirmBoxWrap.dpblock {padding-top: 51px;box-sizing: border-box;}
.noticeWrap.withBtns .notice_detail_inn {height: 220px !important;}

.confrmTitle .modal-topBtn {border-radius: 5px;padding: 0 20px;font-weight: bold;}
.ck_contents.cke_reset { height: 305px !important; }

/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â­ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…â€œÃƒâ€šÃ‚Â±ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€šÃ‚Â */
.titleRegi{border-bottom:1px solid #d5d5d5}
.editArea{margin-top:20px; font-size:0;}
.editArea .notiTextArea{border:1px solid #d1d1d1;min-width:auto;min-height:auto;max-width:900px;max-height:400px;}
.addNewsfeed .grp { display:inline-block; float:left; }
.addNewsfeed .grp label{ display:inline-block; text-align: left; line-height: 30px; margin-right: 10px; float:left;}

.regisNewsfeedWrap > ol > li > ol > li .noticeWrap.dpnone {display:  block !important;}
.regisNewsfeedWrap > ol > li > ol > li {height: 57px;overflow: hidden;transition: 0.2s;}

a#modalNotiListRegBtn {position: absolute;right: 35px;bottom: 35px;border-radius: 4px;height: 40px;box-sizing: border-box;font-size: 14px;margin-top: 0;line-height: 40px;padding: 0 30px;font-weight: bold;}
.remodal .pagination.newPagination {left: 0;right: auto;float: left; margin-left: 0;margin-top: 20px;margin-bottom: 20px;}
.remodal .pagination.newPagination.opa0 { opacity: 0; }
.remodal .pagination.newPagination.opa0 a { cursor:default; }

p#modal_noti_share_area  input[type='checkbox']:not(old) + label {height: 35px;margin: 0;line-height: 35px;box-sizing: border-box;border-radius: 5px;padding-top: 0;border: 1px solid #e4e4e4;padding-left:30px;padding-right: 13px;margin-top: 0px;background: #ffffff url(../images/contents/checked_gray.png) no-repeat 6px center;background-size: 22px;}
p#modal_noti_share_area input[type='checkbox']:not(old) + label:hover { background-color:#e5e8ea; font-weight:bold; }
p#modal_noti_share_area  input[type='checkbox']:not(old):checked + label {background:#477fff url('../images/contents/checked_fff.png') no-repeat 6px center;border: 0 none;color: #fff !important;font-weight: bold;}
p#modal_noti_share_area input:hover { cursor: pointer;font-weight: bold; border: 1px solid #ededed; box-shadow: 0 3px 5px rgba(0,0,0,0.05) }


/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚ÂµÃƒâ€šÃ‚ÂÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â³Ãƒâ€šÃ‚Â¼ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ */
.subjectAdmin{text-align:left;width:100%;background-color: #f3f3f3;border-radius: 0 0 15px 15px}
.subjectAdmin .leftBox{background-color: #f3f3f3;width: 400px;float: right !important;position:  relative;transition: 0.2s;height: 360px;padding: 0 0 0;}
.subjectAdmin .rightBox{width: 300px;padding: 0 45px 35px;background:  #fff;border-right: 1px solid #f3f3f3;height: 360px;transition:  0.2s;box-sizing:  border-box; broder-radius: 0 0 15px 0;}
.subjectAdmin .sub_title{overflow:hidden;padding: 0 30px;box-shadow: 0 3px 4px rgba(0,0,0,0.03);font-size:  14px;line-height: 50px;height: 50px;position: absolute;/* margin: 0 0 10px; */background:  #fff;box-sizing:  border-box;width:  100%;z-index:  100;}
.subjectAdmin .sub_title span{font-size: 14px;color:#484848;font-weight:  bold;/* padding-bottom:8px; */}
.subjectList{/* padding:10px 0 16px 0; */margin-top: 50px;}
.subjectList li{padding:0 0 2px 17px;overflow:hidden;width:  100%;height:  40px;position: relative;box-sizing:  border-box;line-height:  40px;/* margin: 0 20px; */border-radius:  5px;background: #fff;margin-bottom: 15px;}
.subjectList li div{border-bottom:1px solid #f0f0f0;height: 40px;}
.subjectList li .subjectNm{display:inline-block;text-overflow: ellipsis;box-sizing:  border-box;white-space:  nowrap;overflow:  hidden;width:  100%;padding-right: 40px;}
.subjectAdmin .subAd_label{line-height:20px;display:block;padding:10px 0 6px}
.subAdRight{padding: 0;margin-bottom: 25px;margin-top: -8px;}
.subjectList .bt_delete {position: absolute;top: 5px;right: 10px;line-height: 18px;}
.subAdRight {padding: 0;margin-bottom: 25px;margin-top: -8px;}
.subjectAdmin {text-align: left;width: 100%;background-color: #f3f3f3;border-radius: 0 0 15px 15px;}
.subjectAdmin .rightBox h2 { background: #fff; }

/* ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¡Ãƒâ€šÃ‚Â©ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…â€œÃƒâ€šÃ‚Â±ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€šÃ‚Â */
.btnYellow { border-radius: 4px; display: inline-block; width: 162px; height: 29px; color: #989898; border: 1px solid #e6e5e5; background: #fff; line-height: 29px; } 
.btnYellow.on, .btnYellow:hover { border: 1px solid #ffba00; color: #ffba00  }

/* ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¡Ãƒâ€šÃ‚Â©ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ */
.remodal.inviteUser .regiName {display: block;text-align:  left;}
.remodal.inviteUser .input_txt {text-align:  left;display: inline-block;line-height:40px; height:40px; box-sizing:border-box;width:100% !important; border-radius:0;}
.remodal.inviteUser .over_hidden {text-align:  left;width: 45%;}
.remodal.inviteUser .modal-topBtn {position:  absolute;bottom: 40px;right:30px;height:40px;line-height:40px;box-sizing: border-box;border-radius:5px;font-size:14px;font-weight:bold;}
.remodal.inviteUser .access #subAdminSet {width: 90% !important;box-sizing:border-box;height:40px;margin: 0 auto;line-height:40px;border-radius:20px;text-align:center}
.remodal.inviteUser .access #subAdminSet{border-radius: 27px !important;display: block;width: 100%;height: 100%;color: #a3a3a3;border: 1px solid #e2e2e2;background: #ffffff;background-image: url(../images/contents/checked_gray.png);background-repeat: no-repeat;background-position: 30px center;text-align: center;line-height: 40px;}
.remodal.inviteUser .access { overflow:visible;}
.remodal.inviteUser .access #subAdminSet:hover {box-shadow: 0 3px 5px rgb(255, 29, 36, 0.2);background: #ffffff url(../images/contents/checked_red.png) no-repeat 30px center;border: 1px solid #ff5555;color: #ff5555;}
.remodal.inviteUser .access #subAdminSet.on, .remodal.inviteUser .access #subAdminSet.on:hover {background: #ff5555 url(../images/contents/checked_fff.png) no-repeat 30px center;border: 1px solid #ff5555;color: #ffffff;box-shadow: 0 3px 5px rgb(255, 29, 36, 0.4);}
.remodal.inviteUser .access #subAdminSet.on:hover {background-color: #ff778b;border: 1px solid #ff778b;box-shadow: none;transition: 0.4s;}
.remodal.inviteUser .essential01 {position: absolute;right: 30px;width: 45%;top:100px;box-sizing:border-box;padding: 20px;background: #fff;border-radius:5px;cursor:default;/* margin-top: 0 !important; */}
.remodal.inviteUser .remodal-content07 { border-radius: 0 0 15px 15px; padding-bottom:40px; margin-left:0; }

.remodal.mngMemModal {border-radius: 0;width: 90% !important;height: 100%;margin: 0 !important;max-width: 1000px !important;left: 0;float: right;box-sizing: border-box;right: 0;top: 0;}
.remodal.mngMemModal button.remodal-close {left: -60px;z-index: 1000000;top: 14px;}

/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚Â­ - ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½*/
.conLabel{line-height:31px;padding-right:10px;width: 50px;text-align: right;}

/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚Â­ - ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¡Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ */
.conDetail_cont{text-align:left;padding:15px 0; line-height:20px;height:209px;border-bottom:1px solid #d9d9d9}
.conDetail_cont img{max-width:644px;}
.file_view{padding:10px 0;background-color:#fff;}
.file_view a{display:inline-block; padding-bottom:2px;border-bottom:1px solid #868686;color:#989898;}
.p_reportC1 {line-height:17px}

/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¥ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â° */
.possibDate_search li{padding-bottom:15px;border-bottom:1px solid #d9d9d9}
.possibDate_search .sub_titleBox02{padding:15px 0;overflow:hidden}
.timeBox {position:relative;width:  100%;background:  #fff;box-sizing: border-box;}
.timeBox .blueBox{border-top-width:8px;border-bottom-width:8px;border-left-width: 2px;border-right-width: 13px;border-color: #3a77ff;/* box-sizing:  border-box; */border-style:solid;border-radius:4px;/* width:  100%; */height: 19px;background-color: #3a77ff;/* box-sizing:  border-box; */}
.timeBox:hover .blueBox{border-color: #3a77ff;}
.onehourBox{width: 43.5px;}
.minuteFlag{display:none;position:absolute;left:0;top: -23px;width: 28px;height: 20px;background: url(../images/contents/im_conFlag.png) no-repeat;z-index:20;}
.minuteFlag span{color:#fff;line-height: 24px;display: 50px;display:  block;background: #ff5959;width: 54px;height: 24px;border-radius:  5px 5px 5px 0;position:  absolute;top: -8px;left: 0;}
/*.minuteBox:hover .minuteFlag{display:block;}
.onehourBox.on .minuteBox:hover .minuteFlag{display:none;}*/
.onehourBox .minuteBox .minuteArea{display:inline-block;width: auto;height: 19px;background-color: #fff;}
.onehourBox .minuteBox .minuteArea.onRed{background-color:#ff5959;}
.onehourBox .minuteBox .minuteArea:hover{background-color:#ffdadf;}
.onehourBox .minuteBox .minuteArea.onRed:hover {background-color:#ff5959;cursor: default;}
.onehourBox .minuteBox .minuteArea.onBlue{background-color: #3a77ff;}
.timeBox:hover .onehourBox .minuteBox .minuteArea.onBlue,
.timeBox:hover .onehourBox.on .minuteBox .minuteArea.onBlue{background-color: #3a77ff;cursor:default;}

.txt_timeBox{clear: both;font-size:0;/* padding-top:10px; */overflow:hidden;}
.txt_time{float:left;font-size:12px;width: 43.7px;display:inline-block;transition:  0.1s;height: 35px;line-height: 35px;text-align:left;color:#9c9c9c;}

.txt_time02{width:12px;letter-spacing:-1px;}
.txt_time.over{font-weight:bold;/* background: #e5edff; */line-height: 46px;display:;color:#6d6d6d;}

.timeBox:hover .blueBox.blueBox_Regi{border-color: #3a77ff;}
.blueBox.blueBox_Regi .minuteBox:hover .minuteFlag{display:none;}
.blueBox.blueBox_Regi .onehourBox .minuteBox .minuteArea{cursor:default}
.blueBox.blueBox_Regi .onehourBox .minuteBox .minuteArea:hover{background-color:#fff;}
.blueBox.blueBox_Regi .onehourBox .minuteBox .minuteArea.onBlue:hover{background-color: #3a77ff;}
.blueBox.blueBox_Regi .onehourBox .minuteBox .minuteArea.onRed:hover{background-color:#ff5959;}
.blueBox.blueBox_Regi .minuteArea.on,
.blueBox.blueBox_Regi .onehourBox .minuteBox .minuteArea.on,
.blueBox.blueBox_Regi .onehourBox .minuteBox .minuteArea.on:hover{background-color:#ff5959;}
.timeBox:hover .blueBox.blueBox_Regi .onehourBox.on .minuteBox .minuteArea.onBlue{background-color: #3a77ff;cursor:default;}

/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â³Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â¨ */
.remodal.basicFilebox {
    width: 90%;
    max-width: 1600px;
    float: right;
}
.fileBox{padding:10px 20px 14px;font-size:0}
.fileBox li{margin-bottom:6px;}
.fileBox li:last-child{margin-bottom:0;}
.fileBox .fileName a{width:100%;font-size:12px;display:inline-block;padding-left:2px;padding-top:5px;padding-bottom:4px;color:#484848;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
.fileBox .fileName a:hover { font-weight: bold; }
.fileBox .fileName .titleName{color:#484848;width:100px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
.fileBox li:hover p.fileName a span { color: #484848;  }

.remodal .btn-white{background-color:#fff ;border:1px solid #e5e5e5 ;color:#959595 ;line-height:23px}
.remodal .btn-white:hover{background-color:#ff5a5a ;color:#fff ;border:none !important;margin-left:12px;}
.remodal .btn-white.on{background-color:#ff5a5a;color:#fff ;border:none !important;;margin-left:12px;}
.remodal .btn-orange{background-color:#ffbe0d ;}
.remodal .btn-purple{background-color:#b770ff ;}

.fileBox_leftArea{float:left;width:180px;height:311px;border-right:1px solid #e0e0e0;background-color: #f1f3f5;}
.allFile{width:100%;display:inline-block;line-height:40px;background-color:#489aff;border-bottom:1px solid #e5e5e5;color:#fff;}
.fileBox_leftArea .filePlaceList{height:229px;}
.filePlaceList{position:relative;z-index: 100000000;}
.filePlaceList li{border-bottom:1px solid #e7e9ea}
.filePlaceList li .placeNm{padding-left:15px;display:block;line-height:33px;background-color:#e8e8e8;color:#9c9c9c;}
.filePlaceList li.on a.placeNm {color:#6d6d6d;background-color:#fff;}/* ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â­Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ */
.filePlaceList li a{text-align:left}
.placeNmEdit{display:none;background-color:#fff;padding:2px 10px 2px 5px;overflow:hidden}/* ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â­ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã¢â‚¬Å“Ãƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€¦Ã¢â‚¬Å“ */
.placeNmAdd{background-color:#fff;padding:2px 10px 2px 5px;overflow:hidden}

.fileBox_leftArea .bt_del{display:inline-block;margin-top:4px; width:19px;height:19px;background:url('../images/contents/bt_del_on.png') no-repeat 0 0;color:#fff;}/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¹Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â° */
.fileBox_leftArea .bt_add{display:inline-block;margin-top:5px; width:17px;height:17px;background:url('../images/contents/bt_add.png') no-repeat 0 0;color:#fff;}/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â«Ãƒâ€¦Ã‚Â¾ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â° */
.newfolderBox{position:relative; bottom:0;left:0;}
.newfolderBox .newfolder{display:inline-block;width:100%;line-height:40px;background-color:#e8e8e8;color:#9c9c9c;border-top:1px solid #d5d5d5}/* ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂÃƒÂ¢Ã¢â€šÂ¬Ã‚Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ */
.newfolderBox .newfolder.on
.newfolderBox .newfolder:hover{background-color:#fff;}

.fileBox_rightArea{}
.fileBox_rightArea li{/* border-bottom:1px solid #e5e5e5; */overflow:hidden;}
.fileBox_rightArea li p{}
.fileBox_rightArea li.on p{color:#6d6d6d;}
.fileBox_rightArea .pagination{padding:15px 0 0;}
.fileCheck{float:left;padding-top:5px;}
.fileNo{float:left;width:22px;line-height:33px;text-align:left;}
.fileSubj{float:left;text-align:left;width:206px;font-size: 13px;line-height:33px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.fileByte{float:right;line-height:33px;text-align:left;}
.fileDate{float:right;line-height:33px;text-align:right;margin-left:20px;}

/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â¡Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂºÃƒâ€šÃ‚ÂÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…â€œÃƒâ€šÃ‚Â±ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€šÃ‚Â */
.regiName{display:inline-block;width:48px;margin-right:10px;line-height:29px;text-align:right}

/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â¡Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂºÃƒâ€šÃ‚ÂÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â° */
.memberList{margin-top:15px;}
.memberList li span{display:inline-block; line-height:13px;border-left:1px solid #d2d2d2;color:#737373}
.memberList li span:first-child{border-left:none;}
.memberList li .item01{width:60px;text-align:left;}
.memberList li .item02{width:187px;text-align:center;}
.memberList li .item03{width:122px;text-align:center;}

.memberList li div{text-align:left;padding:10px 0;border-top:1px solid #e6e6e6;}
.memberList li:first-child div {border-top:none;}
.memberList li{padding-left:14px;background:url('../images/contents/ic_dot.png') no-repeat 0 14px;}
.memberList li:hover{background:url('../images/contents/ic_dot_on.png') no-repeat 0 14px;}
.memberList li.on, .memberList li:hover span{color:#484848;border-left:1px solid #bcbcbc;}
.memberList li:hover span:first-child{border-left:none;}

.no_searchCont{padding:15px;font-size:12px;text-align:center}

/* ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂºÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â³ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â­ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ */
.color_titleBox .p_moreTitle {font-size:  16px;}
.color_titleBox{overflow:hidden;padding: 40px 25px 16px;border-radius:  15px 15px 0 0;color:#fff;background-color:#f84f77;}
.allClass{padding:13px 20px 12px;background-color:#f3f3f3;overflow:hidden}
.monthInstrucBox li{padding:15px 0;border-bottom:1px solid #e0e0e0;overflow:hidden}
.monthInstrucBox li:hover { cursor:pointer; }
.monthInstrucBox li:first-child{padding:10px 0 15px;}
.monthInstrucBox.hodudoc li:first-child{padding:0px 0 15px;}
.monthInstrucBox li:last-child{padding-bottom:0;border-bottom:0;}
.timeClass{color:#5d6162;font-size: 14px;font-weight:bold;}
.weekInstrucBox .timeClass{padding-bottom:10px;border-bottom:1px solid #eee}
.weekInstrucBox li{padding:15px 0;}
.weekInstrucBox li:first-child{padding-top:5px}
.weekInstrucBox li:last-child{padding-bottom:0;}


/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¹ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¡ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â­ */
.w460 { width: 460px;  }
.remodal .acceptBg.remodal-content08 { padding: 20px 16px 25px 35px; height: 110px; }
.acceptBg .memberList { margin-top: 0 }
.remodal .modal-topBtn.btn-blue { background-color: #34a2ff  }
.acceptBg .memberList li .item01 {width: 12%;text-align: left;overflow:hidden;text-overflow:ellipsis;padding: 0 5px;}
.acceptBg .memberList li .item02 {width: 17%;text-align: center;overflow:hidden;text-overflow:ellipsis;padding: 0 5px;}
.acceptBg .memberList li .item03 {width: 27%;text-align: center;overflow:hidden;text-overflow:ellipsis;padding: 0 5px;}
.acceptBg .memberList li { width: 100%; background: none;padding-left: 0 }
.membercheck { display: inline-block; margin-top: 5px; float: left; }
.membercheck input[type='checkbox']:not(old) + label { padding-left: 12px;  }
.acceptBg .memberList li span { color: #737373 }
.acceptBg .memberList li.on span, .acceptBg .memberList li:hover span { color: #484848;  }
.acceptBg .noRequest { color: #737373; line-height: 107px; font-size: 12px; }



@media only screen and (min-width: 641px) {
    .remodal.w440 { max-width: 440px;} 
}

/* ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ */
.modal_info{display:inline-block;width:23px;height:23px;background:url(../images/contents/im_explan.png) no-repeat 0 0; }
.modal_info:hover{background:url(../images/contents/im_explan_on.png) no-repeat 0 0;}
.tooltipBox{display:none;position:absolute;right:0;top:47px;z-index:100}
.tooltipImg{position:absolute;right:24px;top:-9px}
.tooltip_cont{border:1px solid #e1e1e1;background-color:#fff;padding:15px 29px;line-height:19px;color:#737373}

/* ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ */
.scheduleConfrmBox{background-color:#fff;margin-top:8px}
.confirmBoxWrap{overflow:hidden;}
.confrmTitle{color:#737373;padding:11px 18px 10px;border-bottom:1px solid #ededed;overflow:hidden;z-index:1000;position: absolute;width: 100%;left: 0;box-sizing: border-box;top: 57px;}
.contEye{line-height:30px;padding-left:26px;background:url(../images/contents/im_eye.png) no-repeat left 8px;color:#737373}
.contEye.on{color:#6d6d6d;background:url(../images/contents/im_eye_on.png) no-repeat left 8px;}
.scheduleConfrmBox span.contEye{cursor:default;}/* ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â  ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â­ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂÃƒâ€¹Ã…â€œÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â²Ãƒâ€¦Ã¢â‚¬â„¢ */
.confirmBox{width:50%;}
.redBox{overflow:hidden;background-color:#ff6969;padding:5px 20px; }
.redBox.redBox02{padding-right:10px;}/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¬Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Â¡ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ */
.redBox span{line-height:26px;}
.grayBox{overflow:hidden;background-color:#959595;padding:5px 20px;border-left:1px solid #e3e3e3;}
.grayBox.grayBox02{padding-right:10px;}/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¬Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Â¡ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ */
.grayBox span{line-height:26px;}
.confirmListBox{padding:13px 20px 29px;height:200px;overflow:hidden;border-top:1px solid #e3e3e3;}
.borderLeft{border-left:1px solid #ededed;}
.photoBox {padding:5px 0;float:left;margin-top: 3px;margin-right:15px;}
.photoBox img{width:29px;height:29px;border-radius:50%;border:1px solid #e1e1e1;}
.info_person{float:left;position:relative; padding:11px 20px 11px 0;background:url(../images/contents/ic_listOpen.png) no-repeat right 18px;text-align:left;}
.confirmListBox li.on .info_person{background:url(../images/contents/ic_listClose.png) no-repeat right 18px;}
.info_person{border-top:1px solid #e5e5e5}
.confirmListBox li:first-child .info_person{border-top:none}
.per_no{line-height:20px;float:left;width:27px;text-align:left;color:#484848;padding:11px 0;}
.per_name{line-height: 26px;float:left;width: 208px;color:#484848;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
.per_phone{line-height:20px;float:right;width:98px;color:#737373;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
.per_eMail{line-height:20px;display:none;color:#737373}
.confirmListBox li.on .info_person .per_eMail{display:block;}
.confirmListBox li.on .info_person{float:none;margin-left:73px;}
.confirmListBox li.on .info_person p{ float:none;width:100%}
.confirmListBox li.on .photoBox{height:73px;}

/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â­ ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â§Ãƒâ€šÃ‚Â */
.regisNewsfeedWrap .per_no{padding:7px 0;}

/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¬Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Â¡ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ */
.ConfrmTitle02{padding:9px 18px 8px;border-bottom:none;}
.search_grayBox{display:none;padding:6px 0 6px 20px;background-color:#e0e0e0;border-bottom:1px solid #ededed;overflow:hidden;}
.seachClose{display:inline-block; background:url(../images/contents/bt_close_02.png) no-repeat 5px 12px ;margin-left:6px;width:20px; height:29px;overflow:hidden;}
.bt_extend{display:inline-block; margin-top:3px;width:20px;height:20px;background:url(../images/contents/bt_extend.png) no-repeat 4px;}
.redBox .btn-lineWhite{padding:6px 20px;}
.redBox .btn-lineWhite:hover{color:#ff6969;background-color:#fff;border:1px solid #fff;}
.smsConBoxWrap{height:327px;overflow:hidden;background-color:#fff;}
.smsConBoxWrap .confirmListBox{padding:2px 20px 20px;min-height:233px; }
.comfirmHeader{background-color: #f1f3f5;overflow:hidden;}
.headerSort{float:left;padding:8px 0 7px 0;cursor:pointer;}
.headerSort.sortOff{}
/*.headerSort.sortUp{background:#f1f3f5 url(../images/contents/im_sortUp.png) no-repeat center right; font-weight: bold;} ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¯Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â°ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¤ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ 
.headerSort.sortDown{background:#f1f3f5 url(../images/contents/im_sortDown.png) no-repeat center right; font-weight: bold;}*/
.tableStyle02 .headerSort{float:none;padding:0 5px 0 0;}
.tableStyle03 .headerSort{float:none;padding:0 5px 0 0 ;}
.tableStyle02 .sortOff,
.tableStyle02 .sortUp,
.tableStyle02 .sortDown,
.tableStyle03 .sortOff,
.tableStyle03 .sortUp,
.tableStyle03 .sortDown{background-position:100% 0;}
.headerSort .headerTit{float:left;font-weight:bold;color:#484848;font-size: 12px;line-height:20px;}
.tdCheck{float:left;}
.tdCheck label{background-position: 0 4px !important;}

.headerSort .hbar{margin-right:19px;}
.confirmListBox li:last-child .smsList{border-bottom:none;}
.redBox .smsList{cursor:pointer;}
.smsList{padding:10px 0;border-bottom:1px solid #e5e5e5;box-sizing:border-box;overflow:hidden}
.smsList.on{color:#484848}
.smsList p{line-height:20px;color:#737373;text-align:center;float:left;}
.smsList .per_no{width:20px;padding:0;}
.smsList .per_name{width:125px;text-align:left}
.smsList .per_phone{width:134px;}
.hdper_name{width:165px;padding-left:20px;}
.hdper_phone{width:134px;}
.hdper_date{display:none;width:134px;}
.hdper_time{display:none;width:134px;}
.per_date{display:none;width:134px;}
.per_time{display:none;width:104px;}

.confirmListBox li.on .smsBoxWrap01{display:block}
.confirmListBox li.on .smsList{border-bottom:none;}
.confirmListBox .smsBoxWrap{display:none;padding-left:20px;overflow:hidden}
.confirmListBox .smsBoxWrap02{display:none}
.smsBox{background-color:#ededed;padding:20px 18px 18px 18px}
.cont_sms{background-color:#fafafa;border-radius:5px;padding:15px;}
.cont_sms textarea{width:100%;box-sizing:border-box;line-height:22px;background:transparent;resize:none;overflow-y:hidden}

.compBox .confirmListBox .smsBoxWrap{display:none;padding-left:0;overflow:hidden}
.compBox .confirmListBox li.on .smsBoxWrap{display:block;}
.compBox .per_name{width:145px}
.compBox textarea{min-height:44px;}

/* ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚Â¥ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â­ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â³Ãƒâ€šÃ‚ÂµÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Âµ*/
.confirmBox.extend .bt_extend{background:url(../images/contents/bt_reduce.png) no-repeat 4px;}
.confirmBox.extend{width:100%;}
.confirmBox.extend .hdper_date,
.confirmBox.extend .hdper_time,
.confirmBox.extend .per_date,
.confirmBox.extend .per_time{display:block}
.confirmBox.extend .hdper_name{width:210px;}
.confirmBox.extend .hdper_phone{width:182px;}
.confirmBox.extend .per_phone{width:182px;}
.confirmBox.extend .smsBox{padding:20px 30px;}
.confirmBox.extend .confirmListBox{padding-right:30px;}
.confirmBox.extend .fail_smsBox01{display:none}
.confirmBox.extend .confirmListBox li.on .smsBoxWrap02{display:block;}

/* ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â­ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Â¡ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€šÃ‚Â¨ */
.failBox.extend .confirmListBox li.on .smsBoxWrap01{display:none;}
.failBox.extend .per_name{width:170px;}

/* ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â­ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Â¡ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€¦Ã¢â‚¬â„¢ */
.extend.compBox .hdper_name{width:209px;}
.extend.compBox .per_name{width:189px;}
.compBox.extend .confirmListBox li.on .smsBox{display:block}
.compBox.extend .confirmListBox li.on .smsList{border-bottom:none;}

/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¶Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã¢â‚¬Å“Ãƒâ€šÃ‚Â  ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â©ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ */
.messageBoxWrap {width:600px}
.messageBoxWrap .regiName{width: 30%;padding-right: 10px;box-sizing: border-box;margin-right:0}
.messageBoxWrap .input_txt{width:70%;height:30px;box-sizing:border-box;}

.messageBoxWrap .leftArea, 
.messageBoxWrap .rightArea{width:50%;box-sizing:border-box;}

.messageBoxWrap.leftArea_dpnone {width:300px;}
.messageBoxWrap.leftArea_dpnone .leftArea{display:none;}
.messageBoxWrap.leftArea_dpnone .rightArea{width:100%}

.messageBoxWrap .rightArea .selectboxWrap label{width:80%;text-overflow:ellipsis;overflow:hidden;white-space: nowrap;}

.messageBoxWrap .deco {width:  100%;height: 20px;vertical-align:  middle;margin-top: 10px;}
.messageBoxWrap .deco span {display:  inline-block;width: 80px;height: 5px;background: #e8e8e8;border-radius:  3.5px;vertical-align: middle;margin-top: -5px;}
.messageBoxWrap .deco span.circle {width: 10px;height: 10px;margin-left: 11px;border-radius:  5px;}
.messageBoxWrap .leftArea {width: 40% !important;padding-bottom: 40px;}
.messageBoxWrap .rightArea {width:  60%;border-left:  1px solid #f3f3f3;background:  #f3f3f3;padding: 0;height: 440px !important;text-align:  left;border-radius:  0 0 15px 0; overflow: hidden !important;}
.messagebBoxWrap .selectboxWrap.selectboxWrap02 select {box-sizing:  border-box;height:  40px;line-height:  40px;}
.messageBoxWrap .selectboxWrap.selectboxWrap02 select {}
.messageBoxWrap .selectboxWrap.selectboxWrap02 {height: 45px;line-height: 45px;font-weight:  bold;font-size:  14px;box-sizing:  border-box;}
.messageBoxWrap .bg_darkgray {margin-top: 15px;border-radius:  5px;background: #f3f3f3;padding: 20px !important;font-size: 12px;height: 165px !important;line-height: 22px !important;}
.messageBoxWrap span.allowImg {height: 45px !important;}
.messageBoxWrap .selectboxWrap label {width:  100%;left:  0;text-align:  left;padding: 0 30px 0 15px;box-sizing:  border-box;text-overflow:  ellipsis;overflow:  hidden;font-size: 12px;white-space:  nowrap;}
.messageBoxWrap .tableDefault.tableStyle02 table tr th {border-top: 0;border-left:  0;text-align:  left;}
.messageBoxWrap .tableDefault.tableStyle02 table tr th.p_tableTNm {text-align: left !important;border-left: 1px solid #e4e4e4;border-right: 1px solid #e4e4e4;}
th.text-center.p_tableTPh {text-align: left !important;}
.messageBox .tableDefault.tableStyle02.messageBox table tr td {text-align: left !important;/* height:  40px; */}
tbody#modalMsgTBody > tr {height: 55px;line-height: 55px;background:  #fff;margin: 0 20px;position:  relative;}
tbody#modalMsgTBody > tr:hover,tbody#modalMsgTBody > tr.on {background: #fffafa;}
.grpInputs { display: none; }

.tableDefault > table > thead > tr > th:first-child, .tableDefault > table tbody#modalMsgTBody tr > th:first-child {text-align: center !important;}
.messageBoxWrap .tableDefault.tableStyle02 table tr th:first-child, .messageBoxWrap .tableDefault.tableStyle02 table tbody tr td:first-child {text-align: center !important;position:relative;}
.messageBoxWrap .tableDefault.tableStyle02 table tbody tr td { position:relative; border-bottom: 1px solid #f3f3f3;line-height:  45px;box-sizing:  border-box;padding: 0 15px;text-align:  left;position:  relative;}
.messageBoxWrap .tableDefault.tableStyle02 table tbody tr td.tdName { font-weight: bold; }
.messageBoxWrap .btns a {height: 40px;line-height: 40px;border-radius:  5px;font-weight:  bold;font-size:  14px;width:  50px;margin-top: 20px;}
.messageBoxWrap .tableDefault.tableStyle02 table tr th {padding: 0 15px;}

.messageBoxWrap {width:600px}
.messageBoxWrap .regiName{width: 30%;padding-right: 10px;box-sizing: border-box;margin-right:0}
.messageBoxWrap .input_txt{width:70%;height:30px;box-sizing:border-box;}

.messageBoxWrap .leftArea, 
.messageBoxWrap .rightArea{width: 60%;box-sizing:border-box;}

.messageBoxWrap.leftArea_dpnone {width:300px;}
.messageBoxWrap.leftArea_dpnone .leftArea{display:none;}
.messageBoxWrap.leftArea_dpnone .rightArea{width:100%}

.messageBoxWrap .rightArea .selectboxWrap label{width:80%;text-overflow:ellipsis;overflow:hidden;white-space: nowrap;}
.messageBoxWrap .tableDefault.tableStyle02 table tr th {height:  55px;padding: 0 15px;}

.messageBoxWrap input[type='checkbox']:not(old) {border-left: 1px solid #fff; cursor: pointer; width: 100%; height: 100%; text-align: center; }
.messageBoxWrap input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 16px;height: 16px !important;padding: 0;border-radius: 5px;border: 2px solid #d9d9d9;background: #fff url('../images/contents/checked_gray.png') no-repeat -2px -2px;background-size: 21px;font-size: 0;}
.messageBoxWrap input[type='checkbox']:not(old) + label {position: absolute;top: 0;left: 50%;margin-left: -10px;margin-top: 17px;padding-left:  0 !important;}
.messageBoxWrap input[type='checkbox']:not(old):checked + label {background: #ff2e3e url('../images/contents/checked_fff.png') no-repeat -2px -2px;border: 2px solid #ff2e3e;background-size: 21px;}


.messageBoxWrap .textBg {position: relative;margin-top: 16px;width: 100%;height: 214px;max-width: 370px;min-width: 100%;max-width: 100%;}
.messageBoxWrap .countLetters { cursor: default; position: absolute; right: 20px; bottom: 20px; opacity: 0.5; }
.messageBoxWrap .counted { font-weight: bold; }
.messageBoxWrap textarea { font-size: 14px; font-family: 'dotum',sans-serif; resize: none; width: 100%; background: #f3f3f3; height: 100%; line-height: 20px; padding: 20px 20px; box-sizing: border-box; }
.messageBoxWrap textarea { border: none; overflow: auto; outline: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

.messageBoxWrap.leftArea_dpnone.withInputs { width: 650px !important; }
.messageBoxWrap.leftArea_dpnone.withInputs .grpInputs { display: block;position: absolute;right: 0;top: 68px; background: #f3f3f3; width: 52%; height: 440px; border-radius: 0 0 12px 0; box-sizing: border-box; padding-top: 36px;}
.messageBoxWrap.leftArea_dpnone.withInputs .grpInputs label { width: 237px; margin: 0 auto; text-align: left; padding-right: 0; }
.messageBoxWrap.leftArea_dpnone.withInputs .grpInputs input { height: 40px; line-height: 40px; padding: 0 15px; border-radius: 0; }
.messageBoxWrap.leftArea_dpnone.withInputs .leftArea { display: block; }
.messageBoxWrap.leftArea_dpnone.withInputs .leftArea { width: 48% !important; }
.messageBoxWrap.leftArea_dpnone.withInputs .rightArea { display: none; }
.messageBoxWrap.leftArea_dpnone.withInputs .grpInputs p {cursor: default; width: 237px;margin: auto;text-align: left;box-sizing: border-box;padding: 15px 20px;background: rgba(218, 218, 218, 0.5);border-radius: 5px;line-height: 18px;margin-top: 25px;}
.messageBoxWrap.leftArea_dpnone.withInputs .leftArea .selectboxWrap { display: none; }
.messageBoxWrap.leftArea_dpnone.withInputs .textBg { margin-top: 10px; height: 275px !important; margin-top:0px }
.messageBoxWrap.leftArea_dpnone.withInputs .countLetters { display: none; }
.messageBoxWrap.leftArea_dpnone.withInputs #modalMsgInitBtn  { display: none; }
.messageBoxWrap.leftArea_dpnone.withInputs .leftArea  .btns { text-align: center;}
.messageBoxWrap.leftArea_dpnone.withInputs .leftArea  .btns a { float: none !important; }
.messageBoxWrap.leftArea_dpnone.withInputs textarea:disabled { color: #484848; }

/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â³Ãƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚Â°ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â°ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â©ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ */

.addParentsDiv .remodal-content07 .over_hidden {width:  45%;}
.addParentsDiv .remodal-content07 .over_hidden input {height:  40px;line-height:  40px;border-radius:  0;}
.addParentsDiv #addParentsModalMesgDesc {cursor:  default;width: 38%;position: absolute;right: 49px;box-sizing: border-box;top: 89px;background: #f3f3f3;border: 30px solid #fff;border-left: 10px solid #fff;border-right: 10px solid #fff;border-bottom: 30px solid #FFF;box-shadow: 0 3px 15px rgba(0,0,0,0.05);border-radius: 10px;padding: 15px 15px !important;height: 294px;text-align: left;font-size: 14px;line-height: 24px !important;}
.addParentsDiv .remodal-content07 {height: 306px;padding: 30px 40px 50px;border-radius: 0 0 15px 15px;}
.addParentsDiv a#addParentsModalMesgSendBtn {position:  absolute;height: 35px;line-height: 35px;padding-top: 0;border-radius: 5px;font-size:  14px;font-weight:  bold;bottom: 98px;right: 75px;}
.addParentsDiv .over_hidden p {text-align: left;text-indent: -10px;overflow: visible !important;width: 220px;margin-left:  15px;margin-top: 20px;line-height: 20px;}

/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ */
.fileUploadBox{width:289px;position:relative; }
.file_txt{float:left;width:244px;height:40px;background-color:#fff;border-top:1px solid #ededed;border-left:1px solid #ededed;border-bottom:1px solid #ededed;color:#b3b3b3;box-sizing:border-box;}/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬Å“Ãƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€¦Ã¢â‚¬Å“ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â«Ãƒâ€¦Ã‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â input type="text" */
.fileWrap{display:inline-block; width:43px;height:40px;background:url(../images/contents/input_file.png) 0 0 no-repeat;position:absolute;top:0;right:0 }/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¯Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¥Ãƒâ€šÃ‚Â¼ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â°Ãƒâ€šÃ‚Â°ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã¢â‚¬Å“Ãƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ */
.fileWrap:hover{background:url(../images/contents/input_file_on.png) 0 0 no-repeat;}
.file_add{filter:alpha(opacity:0);opacity:0;width:43px;height:40px;}

/* ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ */
.excelBox{width:700px;}
.excelBox .hbar{margin-top:5px;}
.excelBox .left_titleBox{padding:25px 20px 20px !important;}
.excelBox .left_titleBox .input_btn.on{color:#6d6d6d;font-size:18px;font-weight:bold;}
.excelBox .left_titleBox .input_btn{color:#bababa;font-size:14px;font-weight:bold;}
.excelContent{height:315px;}
.excelContent .leftArea{height:100%;float:left;width:275px;background-color:#f3f3f3;padding:40px;text-align:left;box-sizing:border-box; border-radius: 0 0 0 15px}
.title_howto{font-weight:bold;font-size:14px;}
.cont_howto{margin-left:16px;margin-top:25px;margin-bottom:18px;}
.cont_howto li{line-height:20px;margin-top:8px;}
.excelContent .btn_bigWhite,
.excelContent .btn_bigYellow{width:164px;height:40px;}
.excelContent .rightArea{text-align:center;display:inline-block;padding:40px 0}
.excelContent .inputGroup{width:291px;text-align:left;margin-bottom:35px;overflow:hidden;}
.excelContent .inputGroup > label{font-weight:bold;display:inline-block;margin-top:25px; }
.excelContent .rightArea .selectboxWrap{margin-top:15px;height:38px;width:289px;}
.excelContent .rightArea .selectboxWrap label{top:7px}
.excelContent .rightArea .selectboxWrap .allowImg{height:37px;}
.excelContent .rightArea .groupBox,
.excelContent .rightArea .fileUploadBox{margin-top:15px;}
.groupBox .input_txt{width:109px;height:30px;}
.groupBox .vbar{margin-top:16px;margin-left:8px;margin-right:8px}
.bigbtnBox{border-top:1px solid #e1e1e1;padding-top:18px;text-align:center}

/* 2018_0724 ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â­ */
.excelContent.requested .rightArea label { margin-top: 50px }


/* dmÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â°Ãƒâ€¦Ã¢â‚¬Å“ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Â¡ */
.btn-purple { background-color: #9934ff !important; }
.basicFilebox > .over_hidden {
    padding-top: 62px;
    height: 100%;
    box-sizing: border-box;
}
ul#modalBasicFileBoxList {
    height: 100% !important;
}

.fileBox_leftArea .over_hidden {
    padding-top: 0;
}

.fileBox4doc { width: 800px }
.fileBox4doc .fileSubj { width: 346px; }
.fileBox4doc .fileBox_rightArea .comfirmHeader {height: 61px;line-height: 61px;border-bottom: 1px solid #e7e9ea;position: absolute;width: 100%;box-sizing: border-box;z-index: 100000;left: 0;padding-left: 239px;}
.fileBox4doc .fileBox_leftArea {position: relative;width: 240px;}
.fileBox4doc .fileBox_rightArea {width: 100%;padding: 0;border-radius: 0;overflow: hidden;box-sizing: border-box;padding-left: 240px;height: 100% !important;}
.fileBox4doc .fileBox_rightArea li {padding: 0;line-height: 55px;}
.fileBox4doc .allFile {box-sizing: border-box;height: 62px;line-height: 62px;font-size: 14px;text-align: left;font-weight: bold;padding-left: 50px;background: #477fff url(../images/contents/ic_fileBox_fff.png) no-repeat 18px center;color: #fff;}
.fileBox4doc .allFile:hover, .fileBox4doc .allFile.on {font-weight:bold;background: #477fff url(../images/contents/ic_fileBox_fff.png) no-repeat 18px center;color: #fff;}
.fileBox4doc .filePlaceList li {position: relative;line-height: 55px;overflow: hidden;height: 55px;}
.fileBox4doc .filePlaceList li .placeNm {line-height: inherit;padding-left: 53px;background: #fff url('../images/contents/ic_fileOff.png') no-repeat 23px center;color: #404040;padding-right: 30px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.fileBox4doc .filePlaceList li.on .placeNm,.fileBox4doc .filePlaceList li.on:hover .placeNm {font-weight:bold;background: #f3f4f7 url(../images/contents/ic_fileOff.png) no-repeat 23px center;color: #2c3a53;}
.fileBox4doc .filePlaceList li.on > p.delete { right: -18px;width: 35px; }
.fileBox4doc .filePlaceList li:hover > p.delete {display: block;transition: 0.1s;background: #f55;right: -18px;width: 35px;}
.fileBox4doc .filePlaceList li:hover > p.delete:hover {right: 0;width: 25px;transition: 0.1s;}
.fileBox4doc .filePlaceList li p.delete:hover { transition: 0.1s; background: #f55;}
.fileBox4doc .filePlaceList li > p.delete {/* display: none; */transition: 0.1s;cursor: pointer;position: absolute;top: 0;right: -25px;width: 25px;height: 100%;text-align: center;margin-top: 0;background-color: #ff7b7b;font-size: 0;line-height: 100%;} 
.fileBox4doc .filePlaceList li .done {z-index: 100000;cursor: pointer;display: none;position: absolute;right: 10px;top: 0;border-radius: 50%;color: #abaeaf;text-align: center;width: 30px;height: 100%;font-size: 12px;background: none;}
.fileBox4doc .filePlaceList li .done:hover, .fileBox4doc .filePlaceList li .done.on {cursor: pointer;background: none;}
.fileBox4doc .filePlaceList li .minus {position: absolute;top: 26px;right: 7px;border-radius:;width: 11px;height: 2px;background: #fff;display: inline-block;}
.fileBox4doc .filePlaceList li input {display: none;position: absolute;top: 0;left: 0;width: 100%;height: 100%;box-sizing: border-box;padding-left: 20px;padding-right: 50px;}
.fileBox4doc .fileBox_rightArea li p a { line-height: inherit; height: 45px; }
.fileBox4doc .fileSubj { line-height: 45px; }
.fileBox4doc .fileCheck {padding-top: 0;position: relative;width: 6%;}
.fileBox4doc .fileBox_rightArea .headerSort .headerTit { line-height: inherit; font-weight: normal; }
.fileBox4doc .fileBox_rightArea .headerSort {padding: 0;padding-left: 14px !important;box-sizing: border-box;padding-right: 14px !important;}
.fileBox4doc .headerSort.sortOff { background: url(../images/contents/im_sortOff.png) no-repeat 90% center; }
.fileBox4doc .headerSort.sortDown {background: #e4e7ea  url(../images/contents/im_sortDown.png) no-repeat 90% center;font-weigth: bold;}
.fileBox4doc .headerSort.sortUp {background: #e4e7ea url(../images/contents/im_sortUp.png) no-repeat 90% center;font-weigth: bold;}
.fileBox4doc .headerSort.hdper_fileName.sortUp {background: #e4e7ea url(../images/contents/im_sortUp.png) no-repeat 98% center;}
.fileBox4doc .headerSort.hdper_fileName.sortDown {background: #e4e7ea url(../images/contents/im_sortDown.png) no-repeat 98% center;font-weigth: bold;}
.fileBox4doc .headerSort.hdper_fileName.sortOff {background: url(../images/contents/im_sortOff.png) no-repeat 98% center;font-weigth: bold;}
.fileBox4doc .fileBox_rightArea .btmBg {padding-top: 0;height: 61px;box-sizing: border-box;position: absolute;z-index: 100000000;bottom: 0;left: 0;background: #fff;width: 100%;border-top: 1px solid #eaeaea;padding-left: 240px;}
.fileBox4doc .fileBox_leftArea {height: 100%;position: absolute;left: 0;z-index: 1000000000;box-sizing: border-box;top: 0;padding-top: 61px;}
.fileBox4doc .fileBox_rightArea li p {line-height: 55px;}
.fileBox4doc .fileBox_rightArea li.on p,
.fileBox4doc .fileBox_rightArea li.on p span { color: #313035;  }
.fileBox4doc .fileBox_rightArea li.on p span{display:none}
.fileBox_leftArea .btns { position: absolute; bottom: 0; left: 0; width: 200px; }
.fileBox_leftArea .btn { width: 100px; margin-bottom: 20px; font-weight: bold; border-radius:5px; }
.fileBox4doc .fileBox_rightArea .fileSubj.ic_txt {position:  relative;box-sizing:border-box;padding-right: 30px;width: 54%;}
.fileBox4doc .fileBox_rightArea .fileSubj.ic_txt .arrow {position: absolute; right:0;}

.fileBox4doc input[type='checkbox']:not(old) {   
    background:url('../images/side_area/ra_check_on.png') no-repeat 0 center; 
   
} 
.fileBox4doc input[type='checkbox']:not(old) + label {
    padding-bottom: 0; padding-top: 0; height: 45px; padding-left: 12px; background: url(../images/side_area/ra_check_off.png) no-repeat 0 center;
}
.fileBox4doc input[type='checkbox']:not(old):checked + label {
    background: url('../images/side_area/ra_check_on.png') no-repeat  0 center;
   
}
.fileBox_rightArea input[type='checkbox']:not(old) + label {display: inline-block;padding: 0 !important;border-radius:3px;background: #e5e8ea url('../images/contents/checked_gray.png') no-repeat 1px 1px !important;font-size: 0;margin-right: 8px;}
.fileBox_rightArea input[type='checkbox']:not(old):checked + label {background: #477fff url('../images/contents/checked_fff.png') no-repeat  1px 1px !important;background-size: 18px !important;border: 2px solid #477fff;}
.fileBox_rightArea input[type='checkbox']:not(old) + label {position: absolute;left: 50%;margin-left: -10px;top: 50%;margin-top: -10px;width: 20px !important;height: 20px !important;line-height: 20px;background: #f1f3f5 url(../images/contents/checked_gray.png) no-repeat center center;background-size: 18px !important;border: 0 none;}
.fileBox_rightArea input[type='checkbox']:not(old):checked + label { background-position: 6px 6px; }
.fileBox_rightArea input[type='checkbox']:not(old):checked + label {background: #477fff url(../images/contents/checked_fff.png) no-repeat center center;background-size: 17px;border: 0 none;}
.fileBox4doc .fileBox_rightArea .headerSort input[type='checkbox']:not(old) + label { background-color: #fff !important;box-shadow:0 1px 2px rgba(0,0,0,0.1)}
.fileBox4doc .fileBox_rightArea .headerSort input[type='checkbox']:not(old):checked + label { background-color: #477fff !important;}

.fileBox4doc .hdper_date, .fileBox4doc .hdper_time { display: block; color: #484848 }
/*.fileBox4doc .fileDate .time { color: #484848}*/
.fileBox4doc .hbar { height: 45px; margin-top: 0;  }
.fileBox4doc .fileDate, .fileBox4doc .fileByte {display: inline-block;float: left;text-overflow: ellipsis;padding-right: 15px;box-sizing: border-box;overflow: hidden;white-space: nowrap;}
.fileBox4doc .fileBox_rightArea .headerSort {border-right: 1px solid #e3e3e3;line-height: 60px;width: 6%;text-align: center;}
.fileBox4doc .fileBox_rightArea .headerSort.hdper_num { padding: 0 10px; }
.fileBox4doc .fileBox_rightArea .headerSort.hdper_num span {
    float: none;
}
.fileBox4doc .fileBox_rightArea .headerSort.hdper_fileName {width: 58%;}
.fileBox4doc .fileBox_rightArea .headerSort.hdper_date {width: 13%;}
.fileBox4doc .fileBox_rightArea .headerSort.hdper_time {width: 17%;border-right: 0;}
.fileBox4doc li input[type='checkbox']:not(old) + label { padding-right: 15px  }
.fileBox4doc .fileBox_rightArea .fileNo {width: 6%;text-align: center;}
.fileBox4doc .fileSubj {padding-left: 37px;width: 58%;box-sizing: border-box;}
.fileBox4doc .fileBox_rightArea .fileSubj:hover { cursor: text; }
.fileBox4doc .fileByte {padding-left: 15px;width: 13%;}
.fileBox4doc .fileDate {padding-left: 15px;margin-left: 0;width: 17%;text-align: left;text-overflow: ellipsis;box-sizing: border-box;overflow: hidden;white-space: nowrap;}
.patient { height: 60px; text-align: center; line-height: 60px; border-top: 1px solid #dadada }
.patient a { display: inline-block; height: 100%; padding: 0 15px; font-weight:bold; }
.patient a:hover { color: #313035 !important; }
.on0, .on1 { display: none }
.fileBox4doc .seper { width: 1px; height: 10px; background-color: #c5c5c5; display: inline-block; }
.fileBox4doc .ic_pdf, .fileBox4doc .fileBox_rightArea li.off:hover .ic_pdf{ background: url('../images/contents/ic_pdf.png') no-repeat 10px center; }
.fileBox4doc .ic_img , .fileBox4doc .fileBox_rightArea li.off:hover .ic_img{ background: url('../images/contents/ic_img.png') no-repeat 10px center; }
.fileBox4doc .ic_vid , .fileBox4doc .fileBox_rightArea li.off:hover .ic_vid{ background: url('../images/contents/ic_vid.png') no-repeat 10px center; }
.fileBox4doc .ic_txt , .fileBox4doc .fileBox_rightArea li.off:hover .ic_txt { background: url('../images/contents/ic_txt.png') no-repeat 10px center; }
.fileBox4doc .ic_music , .fileBox4doc .fileBox_rightArea li.off:hover .ic_music { background: url('../images/contents/ic_music.png') no-repeat 10px center; }
.fileBox4doc .ic_error , .fileBox4doc .fileBox_rightArea li.off:hover .ic_error { background: url('../images/contents/ic_error.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li { position: relative; border-bottom:1px solid #fff }
.fileBox4doc .fileBox_rightArea li span,
.fileBox4doc .fileBox_rightArea li p{color: #484848;}
.fileBox4doc .fileBox_rightArea li p.btn {position: absolute;top: -1px;width: 90%;right: 0 !important;height: 100%;padding: 0;line-height: 100%;}
.fileBox4doc .fileBox_rightArea li p.btn a {display: none;width: 100%;height: 100%;padding: 0;background: none !important;font-size: 0;}
.fileBox4doc .fileBox_rightArea li.error { color: #ff2727; background-color: #fff6f6 }
.fileBox4doc .fileBox_rightArea li.on {background-color: #f2f6fd;}
.fileBox4doc .fileBox_rightArea li.on p {
    font-weight: bold;
}
.fileBox4doc .fileBox_rightArea li.ic_error.on p { color: #ff2727; background: #fff !important }
.error .fileDate .time { color: #ff2727;  }
.fileBox4doc .fileBox_rightArea li.error:hover p, .fileBox4doc .fileBox_rightArea li.error:hover .time { color: #ff2727; background-color: #ffecec }
.fileBox4doc .fileBox_rightArea li.error:hover .fileSubj { cursor: pointer }
.fileBox4doc .fileBox_rightArea li.error:hover, .fileBox4doc .fileBox_rightArea li.error.on { background-color: #ffecec }
.fileBox4doc .fileBox_rightArea li.error.on:hover .msgForPatient p, .fileBox4doc .fileBox_rightArea li.error:hover .msgForPatient p { color: #ff2727; background: #fff !important }
.fileBox4doc .fileBox_rightArea li.error .msgForPatient p, .fileBox4doc .fileBox_rightArea li.error p, .fileBox4doc .fileBox_rightArea li.error span, .fileBox4doc .fileBox_rightArea li.error p span { color: #ff2727 }
.fileBox4doc .fileBox_rightArea li:hover {background: #f2f6fd;}
.fileBox4doc .fileBox_rightArea li.off {background-color:#fff}/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂÃƒâ€¹Ã…â€œÃƒÆ’Ã‚Â«Ãƒâ€¦Ã‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¨Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ li */
.fileBox4doc .fileBox_rightArea p.fileSubj p.btn { display: none; }
.fileBox4doc .fileBox_rightArea li:hover p.fileSubj {display: block;margin-right: 0;}
.fileBox4doc .fileBox_rightArea li.txt:hover p.fileSubj { display: block; width: 277px; margin-right: 0; }
.fileBox4doc .fileBox_rightArea li:hover p.btn { display: block; transition: 0.2s; }
.fileBox4doc .fileBox_rightArea li.off p.btn { display: none;}/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â´ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ */
.fileBox4doc .fileBox_rightArea li:hover p.btn a { display: inline-block; transition: 0.2s; }
.fileBox4doc .fileBox_rightArea li:hover p,
.fileBox4doc .fileBox_rightArea li:hover span { color: #313035; cursor: default; }

.fileBox4doc .fileBox_rightArea li:hover .fileSubj.ic_pdf, .fileBox4doc .fileBox_rightArea li.on .fileSubj.ic_pdf { background: url('../images/contents/ic_pdf_on.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li:hover .fileSubj.ic_img, .fileBox4doc .fileBox_rightArea li.on .fileSubj.ic_img { background: url('../images/contents/ic_img_on.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li:hover .fileSubj.ic_vid, .fileBox4doc .fileBox_rightArea li.on .fileSubj.ic_vid { background: url('../images/contents/ic_vid_on.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li:hover .fileSubj.ic_music, .fileBox4doc .fileBox_rightArea li.on .fileSubj.ic_music { background: url('../images/contents/ic_music_on.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li:hover .fileSubj.ic_txt, .fileBox4doc .fileBox_rightArea li.on .fileSubj.ic_txt { background: url('../images/contents/ic_txt_on.png') no-repeat 10px center; }

.fileBox4doc .fileBox_rightArea li.off:hover p.fileSubj { width:237px;margin-right:0}

.fileBox4doc .fileBox_rightArea li.off:hover p.btn{display:none;}
.fileBox4doc .fileBox_rightArea li.off:hover p,
.fileBox4doc .fileBox_rightArea li.off span {color:#989889}
.fileBox4doc .fileBox_rightArea li.off:hover .ic_pdf{ background: url('../images/contents/ic_pdf.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li.off:hover .ic_img{ background: url('../images/contents/ic_img.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li.off:hover .ic_vid{ background: url('../images/contents/ic_vid.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li.off:hover .ic_txt { background: url('../images/contents/ic_txt.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li.off:hover .ic_music { background: url('../images/contents/ic_music.png') no-repeat 10px center; }
.fileBox4doc .fileBox_rightArea li.off:hover .ic_error { background: url('../images/contents/ic_error.png') no-repeat 10px center; }

.fileBox4doc .fileBox_rightArea .progress { padding: 0; height: 45px; width: 100%; }
.fileBox4doc .fileBox_rightArea .progress p.bg { width: 100%; position: absolute; top: 0; left: 0; background: url('../images/contents/progressBar.png') no-repeat; font-size: 14px;  }
.fileBox4doc .fileBox_rightArea .progress p.bg:hover { width: 100%; background: url('../images/contents/progressBar.png') no-repeat; }
.fileBox4doc .fileBox_rightArea .progress span, .fileBox4doc .fileBox_rightArea .progress p.bg:hover span {color: #fff; font-weight: bold}
.fileBox4doc .fileBox_rightArea input[type="text"] { display: none; position: absolute; top: 10px; left: 114px; padding-left: 2px; height: 22px; width: 230px; line-height: 22px; border: 1px solid #eaeaea; font-family: 'dotum', sans-serif; letter-spacing: -0.1em; background: #fff; }
.fileBox4doc .fileBox_rightArea input[type="text"].on { display: block;width: 200px; margin-right: 30px; }
.fileBox4doc .fileBox_rightArea .done { cursor: pointer; display: none; position: absolute; right: 248px; top: 10px; border-radius: 50%; width: 25px; height: 25px; font-size: 0; background: #dadada url('../images/contents/checked_fff.png') no-repeat center center }
.fileBox4doc .fileBox_rightArea .done:hover , .fileBox4doc .fileBox_rightArea .done.on {cursor: pointer; background: #29c78e url('../images/contents/checked_fff.png') no-repeat center center }
/*.fileBox4doc .fileBox_rightArea input[type="text"]:hover { border: 1px solid #bfccec; background: #edf4ff; }*/
.fileBox4doc .fileBox_rightArea .fileSubj.ic_txt:hover { cursor: pointer; width: 277px; margin-right: 0;  }
.msgForPatient { display: none; overflow: hidden; text-align: left; width: 100%; padding-left: 75px; box-sizing: border-box; }
.msgForPatient img { max-width:474px; }
.fileBox4doc .fileBox_rightArea .msgForPatient p { color: #313035; line-height: 20px; border-radius: 5px; padding: 10px; background: #fff; margin-bottom: 6px; }
.fileBox4doc .fileBox_rightArea .pagination {float: right;}
/*.fileBox4doc .fileBox_rightArea .fileSubj:hover span.border { border: 1px solid #bfccec;padding:2px 2px;height: 22px; line-height: 22px; margin-left: -3px;  }*/
.fileBox4doc .fileBox_rightArea li.off .fileSubj:hover span.border { border:0px;padding:2px 0 2px 2px;margin-left:-2px; color:#989889 }
.fileBox4doc .fileBox_rightArea .pagination {padding-top: 12px;padding-left: 100px;margin-right: 10px;}
.fileBox4doc .fileBox_rightArea .pagination a {width: 35px;height: 35px;margin: 0 2px;padding-top: 0;line-height: 35px;}
.fileBox4doc .fileBox_rightArea .pagination a:hover, .fileBox4doc .fileBox_rightArea .btmBtns a:hover {color: #313035 !important;background: #f1f3f5;}
.fileBox4doc .fileBox_rightArea .pagination .prevBtns a.bt_prev_news {margin-right: 10px;width: 35px;background: #ffffff url(../images/contents/month_bt_prev.svg) no-repeat  3px center;}
.fileBox4doc .fileBox_rightArea .pagination .nextBtns a.bt_next_news {margin-left: 10px;width: 40px;background: #ffffff url(../images/contents/month_bt_next.svg) no-repeat  3px center;}
.fileBox4doc .fileBox_rightArea .pagination .prevBtns a.bt_prev_news:hover {background: #f1f3f5 url('../images/contents/month_bt_prev_on.svg') no-repeat  3px center;}
.fileBox4doc .fileBox_rightArea .pagination .nextBtns a.bt_next_news:hover {background: #f1f3f5 url('../images/contents/month_bt_next_on.svg') no-repeat  3px center;}
.fileBox4doc .fileBox_rightArea .pagination .blind {width: 1px;height: 10px;margin-top: 10px;visibility: visible;text-indent: 0;/* background: #d7d6d6; */}
.fileBox4doc .fileBox_rightArea .pagination .prevBtns .blind { float: right; }
.fileBox4doc .fileBox_rightArea .btmBtns {position: absolute;left: 260px;top: 12px;height: 35px;z-index: 10000000000;line-height: 35px;width: 250px;text-align: right;}
.fileBox4doc .fileBox_rightArea .btmBtns a {display: inline-block;height: 100%;width: auto;padding: 0 10px;margin-right: 5px;float: left;border-radius: 5px;font-weight:bold;}
.fileBox4doc .fileBox_rightArea .btmBtns .seper { display: none; width: 1px; height: 10px; background: #eaeaea; margin: 0; }
a#modalPatientFileModify, #modalBasicFileModify,a#modalPatientFileDelete, #modalBasicFileDelete,a#modalPatientFileModComp, #modalBasicFileModComp {background: #f1f3f5;color: #737373 !important;}
a#modalPatientFileModify:hover, #modalBasicFileModify:hover { background: #ffc008;}
a#modalPatientFileDelete:hover, #modalBasicFileDelete:hover {background: #ff5a5a;color: #fff !important;}
a#modalPatientFileModComp:hover, #modalBasicFileModComp:hover { background: #00e29a;}
#modalFileUploadBasic {position: static;padding-left: 50px;z-index:1000000;background: #ffffff url(../images/contents/ic_upload.svg) no-repeat 15px 18px !important;background-size: 26px !important;font-size: 14px;color: #477fff;margin-left: 0;margin-top: 0;line-height: 60px;font-weight:bold;}
.basicFilebox a#modalBasicFileDelete {font-size: 14px;background: none;height: 60px;font-weight: bold;display: inline-block;padding: 0 15px;line-height: 60px;margin: 0 !important;}
.basicFilebox a#modalBasicFileDelete:hover {color: #484848 !important;}
a#modalPatientReSend { background: #7e69ff;color:#fff!important;}
.fileBox4doc .fileBox_rightArea .btmBtns a:hover{color: #fff !important;}

.txt .fileSubj:hover .arrow { display: inline-block; width: 9px; height: 6px; background: url('../images/contents/ic_listOpen.png') no-repeat center center; float: right; margin-right: 10px; margin-top: 18px;  }
.txt .close { z-index: 100; position: absolute; width: 263px;right: 243px; height: 45px; top: 0; font-size: 0; background: url('../images/contents/ic_listClose.png') no-repeat 245px center;  }
.fileBox4doc .fileBox_leftArea .sendMsg { top: 100%; transition:0.2s; z-index: 1000; position: absolute; background: #fff; width: 100%; height: 100%;   }
.fileBox4doc .fileBox_leftArea .sendMsg.soaring { top: 0; transition:0.2s; }
.sendMsg .selectboxWrap label { left: 15px  }
.fileBox4doc .fileBox_leftArea .sendMsg .selectboxWrap { cursor: pointer; float: left; height: 46px; line-height: 46px; text-align: left; box-sizing: border-box; width: 200px; min-width: 200px; border-bottom: 1px solid #eaeaea }
.fileBox4doc .fileBox_leftArea .sendMsg .selectboxWrap label { cursor: pointer;  }
.fileBox4doc .fileBox_leftArea .sendMsg .selectboxWrap.selectboxWrap02 .allowImg { height: 45px;  }
.fileBox4doc .fileBox_leftArea .sendMsg textarea { font-size: 12px; font-family: 'dotum', sans-serif; max-width: 200px; min-width: 200px; min-height: 277px; max-height: 277px; width: 100%; border: 0; padding: 10px; background: #f3f3f3; box-sizing: border-box; height: 277px; }
.fileBox4doc .fileBox_leftArea .sendMsg textarea:focus { border: 0 none; overflow: hidden; outline: none }
.fileBox4doc .changeUser_selectbox.changeOption {  display: none; left: 0; top: 46px; width: 100%; height: 276px; background: #fff; padding: 0; box-sizing: border-box; }
.fileBox4doc .changeUser_selectbox.changeOption ul li {cursor: pointer; display: block; line-height: 45px; height: 45px; width: 100%; border-bottom: 1px solid #eaeaea; text-align: left }
.fileBox4doc .changeUser_selectbox.changeOption ul li a { display: block; height: 100%; width: 100%; padding:0 15px; box-sizing: border-box; text-align: left }
.fileBox4doc .changeUser_selectbox.changeOption ul li:hover a { background: #f3f3f3 }
.fileBox4doc .sendMsg .close { position: absolute; top: -46px; left: 0; width: 100%; height: 45px; font-size: 0; }
.fileBox4doc .fileBox_leftArea .sendMsg .btns { height: 50px; bottom: 0; border-top: 1px solid #eaeaea }
.fileBox4doc .fileBox_leftArea .sendMsg .btns a { float: left;display: inline-block; height: 100%; width: 33.3%; line-height: 50px; }
.fileBox4doc .fileBox_leftArea .sendMsg .btns a:hover { background: #f3f3f3 }
.fileBox4doc .fileBox_leftArea .sendMsg .btns a.refresh { border-right: 1px solid #eaeaea; border-left: 1px solid #eaeaea; box-sizing: border-box; }
.fileBox4doc .fileBox_leftArea {overflow: hidden;border-radius: 0;}
.fileBox4doc .forRel {position: relative;width: 100%;z-index: 1000;height: 100%;box-sizing: border-box;padding-top: 61px;/* background: rgba(71, 127, 255, 0.05); */padding-bottom: 60px;}
.fileBox4doc .forRel.ul.dpblock {}
.fileBox4doc .dropBg {z-index: 10000;display: none;position: absolute;width: 100%;padding: 30px;height: 100%;z-index: 1000000000000000;top: 0;box-sizing: border-box;right: 0;background: rgba(255, 255,255, 0.6);/* padding: 20px; */}
.fileBox4doc .dropBg .bg {width: 100%;height: 100%;background: #2c465c;/* opacity: 0.2; */cursor: grabbing;cursor: -moz-grabbing;cursor: -webkit-grabbing;background-color: rgba(71, 127, 255, 0.2);transition: 0.1s;background: rgba(71, 127, 255, 0.1) url('../images/contents/img_dropit.png') no-repeat center center;box-sizing: border-box;border-radius: 15px;border: 2px dashed #90c1ff;}
.fileBox4doc .dropBg .add { position: absolute;top: 50%; left: 50%; font-size: 55px; color: #fff; font-weight: bold; margin-left: -50px; margin-top: -38px; width: 100px; text-align: center; cursor: grabbing; cursor: -moz-grabbing; cursor: -webkit-grabbing; }
.fileBox4doc .noContent { position: absolute; z-index: 1000; width: 100%; height: 100%; top: 0; left: 0;  background: #fff; visibility: visible; text-indent: 0; font-size: 14px; text-align: center; color: #737373; font-weight: bold; line-height: 326px; }
.fileBox4doc .noContent p, .basicFilebox .noResult p {cursor: default;width: 100%;max-height: 360px;height: 100%;border-radius: 5px;font-size: 16px;background: #f1f3f5;}
.btn-blue { background-color: #5a94ff; }
.btn-blue:hover { background-color: #327aff; transition: 0.2s; }
.btn-red:hover { background-color: #ff3737 !important; transition: 0.2s; }
.btn-purple:hover { background-color: #8e29f5 !important; transition: 0.2s; }
.fileBox4doc .fileBox_leftArea .filePlaceList {height: 276px;height: 100% !important;}
.setName { display: none; position: absolute; top: 0; left: 0; z-index: 1000000; width: 100%; height: 100%; }
.setName .bg { width: 100%;height: 100%;position: absolute; background: #2c465c; opacity: 0.2;z-index: -1; }
.modalBg { width: 320px; padding: 25px 20px; height: 100px; background: #fff; display: inline-block; margin-top: 80px; box-shadow: 0 20px 30px #9db3c5; border-radius: 5px; text-align: center; }
.setName .modalBg label { display: block; text-align: left; padding-left: 10px; }
.setName .modalBg input[type="text"] { width: 260px; position: static !important; display: inline-block !important; text-align: left; margin: 15px 0 15px; padding: 5px 7px }
.setName .modalBg .btn { padding: 8px 30px; }
.setName .modalBg span { cursor: default; }
.btn-green:hover { background: #00b987 !important }

.basicFilebox .btn.fixed { display: block !important; position: fixed; }
.basicFilebox .fileBox_leftArea .btn {position: absolute;bottom: 0;left: 0;width: 100%;height: 60px;background: #fff;z-index: 10000000000;margin-bottom: 0;padding: 0;box-shadow: 0 -5px 10px rgba(0,0,0,0.05);border-radius: 0;}
.basicFilebox .fileBox_leftArea .btn a {display: inline-block;width: 50%;float: left;border-right: 1px solid #e7e9ea;height: 100%;color: #313035 !important;box-sizing: border-box;line-height: 60px;}
.basicFilebox .fileBox_leftArea .btn a:last-child { border-right: 0 none;  }
.basicFilebox .fileBox_leftArea .btn a:hover {background: #477fff;color: #fff !important;}
.basicFilebox .fileBox_leftArea a.fnt_gray.editBtn:hover {background: #ffd42f;color: #313035 !important;}
.basicFilebox .placeNmAdd {display: none;overflow: visible;position: absolute;height: 100%;box-sizing: border-box;padding: 0;width: 100%;bottom: 0;z-index: 100000000000;width: 100%;background: rgba(0,0,0,0.1);line-height: 50px;}
.basicFilebox .input_txt {box-sizing: border-box;line-height: 60px;width: 100% !important;position: absolute;background: #fff url('../images/contents/ic_fileOff.png') no-repeat 13px center;height: 60px;padding: 4px 15px 4px 45px;bottom: 61px;left: 0;z-index: 100;}
.basicFilebox .input_txt:focus {border: 2px solid #477fff;padding-left: 43px;background-position: 11px center;background-color:#fff !important;}
.basicFilebox .input_txt:hover { background-color:#f1f3f5 }
.basicFilebox .placeNmAdd a {z-index: 10000;position: absolute;width: 50%;top: auto;height: 60px;transition: 0.1s;font-weight: bold;border-radius: 0;bottom: 0;border-top: 1px solid #e7e9ea;right: 0;background: #fff !important;}
.basicFilebox .placeNmAdd a:hover { background: #29c78e url('../images/contents/checked_fff.png') no-repeat center center; }
.basicFilebox .placeNmAdd a.close {line-height: 60px;bottom: 0;border-radius: 0 !important;width: 50%;font-size: 14px;border-right: 1px solid #e7e9ea;content: 'ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â«ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â°';left: 0;border-radius: 50%;background: #ff5a5a url('../images/contents/cross.png') no-repeat center center;}
.basicFilebox .placeNmAdd a.close:hover { background: #ff3737 url('../images/contents/cross.png') no-repeat center center }
.basicFilebox .placeNmAdd .blocking {position: absolute;top: 0;width: 100%;height: 100%;z-index: 100;/* background: #000000; */opacity: 0.2;}
.fileBox4doc .fileBox_rightArea li:hover p {font-weight: bold;}
.noCheck.fileBox4doc .fileBox_rightArea li { padding-left:0; }
.noCheck.fileBox4doc .fileBox_rightArea .fileNo {width: 7%;} 
.noCheck.fileBox4doc .fileBox_rightArea .headerSort.hdper_fileName {width: 54%;}
.noCheck.fileBox4doc .fileBox_rightArea li:hover p.fileSubj{
    cursor: default !important;
}
.noCheck.fileBox4doc .fileBox_rightArea li p.btn { right: 265px; }
.noCheck.fileBox4doc .fileBox_rightArea .headerSort.hdper_date {width: 16%;}
.noCheck.fileBox4doc .fileBox_rightArea .headerSort.hdper_time {width: 23%;}
.noCheck.fileBox4doc .fileByte {width: 16%;box-sizing: border-box;}
.noCheck.fileBox4doc .headerSort.hdper_fileName.sortOff {background: url(../images/contents/im_sortOff.png) no-repeat 98% center;}
.noCheck.fileBox4doc .headerSort.hdper_fileName.sortDown {background: #e4e7ea url(../images/contents/im_sortDown.png) no-repeat 98% center;}
.noCheck.fileBox4doc .headerSort.hdper_fileName.sortUp {background: #e4e7ea url(../images/contents/im_sortUp.png) no-repeat 98% center;}
.noCheck.fileBox4doc .fileBox_rightArea li:hover p.fileSubj.ic_txt {width: 54%;}
.basicFilebox .placeNmAdd a span.blind {font-size: 12px;width: auto;height: auto;color: #484848;line-height: 60px;text-indent: 0;overflow: visible;display: block;visibility: visible;}
.basicFilebox .placeNmAdd a.close:hover {background: #ff5a5a !important;color: #fff !important;}
.basicFilebox .fileBox_leftArea .bt_add:hover {background: #477fff !important;color: #fff !important;}
.basicFilebox .fileBox_leftArea .bt_add:hover span {color: #fff !important;}

.fileBox4doc .bt_search:hover {background: url(../images/contents/ic_search_on.png) no-repeat center center;}
.fileBox4doc .bt_search, .fileBox4doc .bt_close {z-index: 100000000;position: absolute;left: 497px;top: 0;display: inline-block;width: 60px;height: 60px;background: url(../images/contents/ic_search_on.png) no-repeat center center;}
.fileBox4doc .showSearchBtn,.fileBox4doc .bt_close { position: absolute; width: 31px; height: 31px; display: block; font-size: 0; right: 17px; top: 20px; border-radius: 50%; z-index: 100000000;  }
.fileBox4doc .bt_close { background: #dadada url('../images/contents/ic_listClose.png') no-repeat center center;  }
.fileBox4doc .bt_close:hover { background: #eaeaea url('../images/contents/ic_listClose.png') no-repeat center center;  }
.fileBox4doc .bt_close { display: none;  }
.fileBox4doc .modal-topBtn {margin-right: 0;}
.fileBox4doc .inputBg  {display:block !important;z-index: 100;position: absolute;top: 0;height: 60px;width: 320px;border-right: 1px solid #e7e9ea;text-align: left;left: 238px;}
.fileBox4doc .inputBg input {width: 100%;box-sizing: border-box;border-left: 1px solid #e7e9ea;line-height: 60px;position: absolute;font-size: 13px;padding: 0 60px 0 15px;}
.fileBox4doc .fileBox_rightArea .comfirmHeader.on { margin-top: 0; }
.fileBox4doc .noContent, .fileBox4doc .noResult {position: static;padding: 30px 30px;width: 100%;box-sizing: border-box;height: 100%;top: 0;right: 0;left: 0;/* background: #fff; */visibility: visible;z-index: 0;text-indent: 0;font-size: 14px;text-align: center;color: #737373;font-weight: bold;line-height: 326px;}
.fileBox4doc .bt_search.on { top: 21px; z-index: 10000000000000;}
.fileBox4doc .forRel.ul.on { height: 326px; }
.fileBox4doc .inputBg input:focus, .fileBox4doc .inputBg input:focus:hover {background: #ffffff;}
.fileBox4doc .filePlaceList li:hover .placeNm {background: #F1f3f5  url(../images/contents/ic_fileOff.png) no-repeat 23px center;font-weight: bold;}
.fileBox4doc .filePlaceList li input:focus, .fileBox4doc .filePlaceList li input:focus:hover {border: 2px solid #477fff;box-sizing: border-box;background: #Fff;padding-left: 18px;}
.fileBox4doc .filePlaceList li input:hover {background: #f1f3f5;}
.fileBox4doc .filePlaceList li .done:hover {color: #477fff;}
.fileBox4doc .inputBg input:hover {background: #f1f3f5;}
.fileBox_rightArea li:last-child {}
.fileBox_rightArea li:first-child {/* border-bottom: 1px solid #e7e9ea; */}
#modalPatientFileBoxList { height: 275px !important; }

.fileBox4doc.noUploadBtn.noUploadBtn .btmBtns { right: 15px;}

/* 2018_0803 */


.makeAppoint { width: 798px; min-width: 798px; }
.makeAppoint .remodal-content07  { border-radius: 0 0 15px 15px }
.makeAppoint .remodal-content07 .underline{ border-bottom: 0 none; padding-top: 10px; padding-bottom: 0 !important; }
#modalConTimeUl .sub_titleBox02 .fl { font-size: 14px; font-weight: bold; }
#modalConDate {cursor: pointer;height: 40px;width: auto !important;padding: 0 0px !important;font-size: 14px !important;color: #484848;font-family: 'dotum', sans-serif;font-weight: bold;}
#modalConTimeUl li {box-shadow: 0 5px 15px rgba(0,0,0,0.05);transition:0.2s;opacity: 0.9;background: #fff;padding: 16px 30px 13px;border-radius: 5px;border: 0 none;height: 118px;box-sizing:  border-box;margin-top: 25px;}
#modalConTimeUl li:hover, #modalConTimeUl li.on { opacity:1; box-shadow: 0 8px 20px rgba(0,0,0,0.1); transition:0.2s; }
#modalConDate:hover {border: 1px solid rgb(218, 218, 218); }
#modalConTimeUl .sub_titleBox02 {padding-top: 5px;padding-bottom: 8px;}
.makeAppoint .remodal-content07 .selectboxWrap.selectboxWrap02 { height: 40px; width: auto !important; min-width:100px !important;   }
.makeAppoint .remodal-content07 .selectboxWrap.selectboxWrap02 select {height: 100%;width: 100%;font-size: 14px;padding: 0 10px;line-height:  40px;min-height:  auto;font-weight:  bold;}
.makeAppoint .remodal-content07 .selectboxWrap.selectboxWrap02 label {font-size: 14px;font-weight: bold;top: 7px;}
.makeAppoint .remodal-content07 .selectboxWrap.selectboxWrap02 .allowImg { height: 40px; }
.remodal .left_titleBox {border-bottom: 1px solid #e8e8e8;box-shadow: 0 0px 10px rgba(0,0,0,0.05);}
.bg_gray { background: #f3f3f3 }
.makeAppoint .onehourBox .minuteBox {width: auto;}
.makeAppoint .noResult { height: 135px; }
.makeAppoint .noResult h3 { line-height: 93px; font-size: 14px; }
.makeAppoint .noResult { position:static; }


.availOrNot { float: right; margin-top: 10px;}
.availOrNot p { display: inline-block; width: 80px; height: 30px; line-height: 30px; margin-right: 10px; text-align: right;  }
.availOrNot p span { display: inline-block; width: 20px; height: 10px; border-radius: 5px; box-sizing: border-box; border: 2px solid #4c83fd; background: #4c83fd; margin-right:5px }
.availOrNot p:first-child  span{ background: #fff; }

.makeAppoint .tableDefault.slideDown { transition:0.3s; height: 0; overflow:hidden; padding: 0; }
.makeAppoint .tableDefault {position: relative; transition:0.3s;margin-top: 20px;}

.makeAppoint .tableDefault tbody { float: left; width: 310px; }
#modalConInRmk { width: 100% !important; line-height: 20px; max-width: 100% !important; min-width: 100% !important; text-align: left; padding: 12px 15px; box-sizing: border-box; min-height: 100% !important; max-width: 100% !important; border-radius: 0; margin: 0;  }
.makeAppoint .tableDefault.tableStyle04 table tr th {padding-top: 0;padding-right: 0;height: auto;text-align: left;width: 310px;float: left;margin-top: 10px;margin-bottom: 4px;}
.makeAppoint .tableDefault tr.memo {position: absolute;top: 13px;right: 35px;width: 310px;overflow: hidden;}
.makeAppoint .tableDefault tr.memo td { height: 120px; border-radius: 0; }
.makeAppoint .tableDefault td { padding: 0; float: left; width: 100%; height: 40px; }
.makeAppoint .tableDefault td input[type="text"] {width: 100% !important;box-sizing: border-box;padding-left: 15px;border-radius: 0;height: 41px;}
.makeAppoint #modalConSelTime { float: left; }
.makeAppoint .tableDefault tbody tr:first-child { width: 310px; }
.makeAppoint .titlebox_inputSearch { height: 40px; line-height: 40px; }
.makeAppoint .tableDefault .selectboxWrap.selectboxWrap02 { width: 100% !important; }
.makeAppoint .titlebox_inputSearch {height: 40px;border-radius: 0;position: relative;padding-left: 15px;padding: 0;margin: 0;position:  relative;}
.makeAppoint .tableDefault .bt_search02 { top: 0; right: 0; position: absolute; width: 40px; height: 40px; margin-top: 0; background: url(../images/contents/ic_search02.png) no-repeat center center; }
.makeAppoint .tableDefault .titlebox_inputSearch .input_txt {padding-left: 15px;padding-right: 40px;position: absolute;top: -1px;left: -1px;right: 0;height: 42px;width: 310px !important;}
.makeAppoint .tableDefault .titlebox_inputSearch .input_txt:hover {border: 1px solid rgb(218, 218, 218) !important;}
.makeAppoint .tableDefault .selectboxWrap.selectboxWrap02 select { font-size: 12px;  }
.makeAppoint .tableDefault .selectboxWrap.selectboxWrap02 label { font-size: 12px }
.makeAppoint .btns { position: absolute; bottom: 60px; right: 70px; display: inline-block; }
.makeAppoint .btns .modal-topBtn { margin-right: 20px; line-height: 40px; border-radius: 5px; font-size: 14px; font-weight: bold; }
.makeAppoint .btns #modalConRegBtn { margin-right: 0; }
.makeAppoint .btn-prev { background: #ffc22f; }
#modalConDate:hover { border: 1px solid rgb(230, 230, 230); }


.makeAppoint .fc-left { display:none; position: absolute; right:25px; overflow: visible; }
.makeAppoint .fc-left input { height: 40px; box-sizing: border-box; transition:0.1s;  border: 1px solid #e6e6e6; }
.makeAppoint input {border: 1px solid #e6e6e6;}
.makeAppoint .fc-left input:hover {background-color:#fff;transition:0.1s; border: 1px solid rgb(218, 218, 218);}
.makeAppoint .today { margin-right: 6px; background:#fff; }
.makeAppoint .bt-prev { width: 40px; background: #fff url('../images/contents/month_bt_prev.png') no-repeat 14px center;}
.makeAppoint .bt-next { float: none; width: 40px; background: #fff url(../images/contents/month_bt_next.png) no-repeat 17px center;}





/****************************************/

/** ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â© **/
.titlebox02_btnBox{display:inline-block; position:absolute; right:25px;bottom:0;}
.titlebox_btnLine{color:#bcbcbc;display:inline-block; margin-right:18px}
.titlebox_btnLine.on{color:#8b8b8b; padding-bottom:15px;border-bottom:1px solid #8b8b8b} 

.stBg{display:inline-block;width:5px;height:55px;background-color:#dfdfdf;}
.conStatus{display:inline-block;padding:23px 19px 17px;color:#969696;}

.con_normalRow .stBg{background-color:#dfdfdf;}
.con_normalRow:hover,
.con_normalRow:hover .conBtn {background-color:#f8f8f8; border-radius:5px;}
/*.con_normalRow:hover .conBtn{background-color:#fff;color:#484848;}*/  /* row hoverÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€¦Ã¢â‚¬Å“ ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ */
.con_normalRow .conStatus{color:#484848}

/* ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©-ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ , ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©-ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¡ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â­ */
.tableStyle03 table .con_reserRow:hover td,
.con_reserRow:hover .conBtn{background-color:#fffcf4;}
.con_reserRow .stBg{background-color:#ffbe0d}
.con_reserRow .conStatus{color:#ffbe0d;}

.tableStyle03 table .yellowRow:hover td,
.yellowRow:hover .conBtn{background-color:#fffcf4;}
.yellowRow .stBg{background-color:#ffbe0d}
.yellowRow .conStatus{color: #ffbe0d;}

/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©- ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ ,ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©-ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ */
.tableStyle03 table .con_confirmRow:hover td,
.con_confirmRow:hover .conBtn{background-color:#f3fcfa;}
.con_confirmRow .stBg{background-color:#1cda87}
.con_confirmRow .conStatus{color:#18ca79;}
/* .tableStyle03 table .con_normalRow:hover { font-weight:bold; } */


.tableStyle03 table .greenRow:hover td,
.greenRow:hover .conBtn{background-color: #f3fcfa;}
.greenRow .stBg{background-color: #1cda87;}
.greenRow .conStatus{color: #18ca79;}

/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©- ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œ, ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©-ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œ */
.tableStyle03 table .con_showUpRow:hover td,
.con_showUpRow:hover .conBtn{background-color:#f9f3fc;;}
.con_showUpRow .stBg{background-color:#bd54ff}
.con_showUpRow .conStatus{color:#bd54ff;}

.tableStyle03 table .purpleRow:hover td,
.purpleRow:hover .conBtn{background-color:#f9f3fc;;}
.purpleRow .stBg{background-color: #bd54ff;}
.purpleRow .conStatus{color: #bd54ff;}

/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©- ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€¦Ã¢â‚¬â„¢, ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©-ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€¦Ã¢â‚¬â„¢ */
.tableStyle03 table .con_completeRow:hover td,
.con_completeRow:hover .conBtn{background-color:#ecf5ff;}
.con_completeRow .stBg{background-color:#2fbdff}
.con_completeRow .conStatus{color:#2fbdff;}

.tableStyle03 table .blueRow:hover td,
.blueRow:hover .conBtn{background-color:#ecf5ff;}
.blueRow .stBg{background-color: #2fbdff;}
.blueRow .conStatus{color: #2fbdff;}

/*ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â»Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©- ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â±Ãƒâ€šÃ‚Â°ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â Ãƒâ€¹Ã¢â‚¬Â , ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â©-ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ */
.tableStyle03 table .con_cancelRow:hover td,
.con_cancelRow:hover .conBtn{background-color:#fff2f2;}
.con_cancelRow .stBg{background-color:#ff2e3e}
.con_cancelRow .conStatus{color:#ff2e3e;}

.tableStyle03 table .redRow:hover td,
.redRow:hover .conBtn{background-color:#fff2f2;}
.redRow .stBg{background-color: #ff2e3e;}
.redRow .conStatus{color: #ff2e3e;}

.conBtn{display:block; line-height:45px;background-color:#fff;color:#989898;}
.conBtn:hover .tdBar{margin-left:1px}
.conBtn:hover .tdBar{width:0}
.tableStyle03 table tr .con_btCancel, .tableStyle03 table tr .con_btRefuse{color:#ff2e3e}
.tableStyle03 table tr .con_btConfirm{color:#1cda87}
.tableStyle03 table tr .con_btComplete{color:#2fbdff;}
.tableStyle03 table tr .con_btShowUp{color:#bd54ff}
.tableStyle03 table tr .con_btReport{color:#bd54ff}
.tableStyle03 table .con_btCancel:hover,
.tableStyle03 table .con_btRefuse:hover{background-color:#ff2e3e;color:#fff;}/* ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â·Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€¦Ã¢â‚¬â„¢, ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â±Ãƒâ€šÃ‚Â°ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â Ãƒâ€¹Ã¢â‚¬Â , ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ */
.tableStyle03 table .con_btConfirm:hover{background-color:#1cda87;color:#fff;}/* ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢, ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ */
.tableStyle03 table .con_btComplete:hover{background-color:#2fbdff;color:#fff;}/* ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€¦Ã¢â‚¬â„¢, ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ */
.tableStyle03 table .con_btShowUp:hover{background-color:#bd54ff;color:#fff;}/* ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ */
.tableStyle03 table .con_btReport:hover{background-color:#bd54ff;color:#fff;}/* reportÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â²ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¼ */

/* ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â­Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€¦Ã¢â‚¬Å“ */
.reserStatus{padding:4px 10px;line-height:16px;border-radius:4px;color:#fff}
.budo {border:1px solid #ff2e3e;background-color:#ff2e3e;}
.jinryo{border:1px solid #2fbdff;background-color:#2fbdff;}
.yeyac{border:1px solid #1cda87;background-color:#1cda87}
.yochung{border:1px solid #fab000;background-color:#ffc413}

/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â­Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€¦Ã¢â‚¬Å“ */
.modal-topBtn.p_btnModalNone { cursor:default; border-radius: 0 0 5px 5px; padding: 17px 15px 3px 15px; margin-top: -12px; box-shadow: 0px 2px 7px rgba(0,0,0,0.1); } 


/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¬Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â°Ãƒâ€¦Ã¢â‚¬Å“ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Â¡ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ */


.detailMsg { position:relative; width: 400px; }
.detailMsg .first .grp1 h2 { font-size: 16px; width: 100%; }
.detailMsg .first .grp1 .from { font-size: 14px; font-weight: bold; border-top: 1px solid #e4e4e4;margin-top: 20px;padding-top: 20px;}
.detailMsg .first .grp1 .linked { font-size: 12px; font-weight: normal; margin-top:10px;  }
.detailMsg .grp2 {max-height: 300px !important;text-align: left;padding-bottom:80px;}
.detailMsg .grp2 .scroll { padding: 30px 30px 10px; }
#modalDetail.detailMsg .grp2 { padding: 0; margin: 0; }
#modalDetail.detailMsg .grp2 .scroll {padding: 30px 35px 10px;}
.detailMsg .grp2 .title { font-size: 14px; padding: 20px 0 15px; }
.detailMsg .grp2 .reason {width: 100%;border-radius: 5px;background: #f3f3f3;text-align: center;line-height: 20px;padding: 20px 20px;color: #737373;font-weight: bold;box-sizing: border-box;margin: 13px 0;}
.detailMsg .content { padding: 0; margin-bottom: 0; line-height: 23px; padding-bottom: 0; word-break: break-all }
.detailMsg .grp2 .ymdt,.detailMsg .grp2 .time { display:inline-block;}
.detailMsg .grp2 .time { margin-left: 5px;}















/* 2018_0814 */


.searchStudentsModal .content {padding: 0 !Important;}
.w700 {width: 700px;transition:  0.2s;}
.searchStudentsModal .remodal-content07 { float:left; width:100%; height:379px; overflow:hidden; box-sizing:border-box; }
.searchStudentsModal .resultBg { height: 100%; overflow:hidden; }
.addNewStudent.searchStudentsModal .memberList .noResult {background:transparent;}
.addNewStudent.remodal .left_titleBox { border-bottom: 1px solid #e5e5e5; height: 68px; box-sizing: border-box; overflow: visible; }
.addNewStudent .titlebox_inputSearch { width: 250px; float: right; height: 35px; line-height: 35px; margin-top: 4px; margin-right: 10px; padding-right: 0; }
.addNewStudent .titlebox_inputSearch .input_txt { width: 80% !important; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; box-sizing: border-box; line-height: 35px; height: 35px; padding-left: 5px; font-family: 'dotum', sans-serif; }
.addNewStudent .bt_search { float: right; width: 35px; height: 35px; border-radius: 50%; margin-top: 0; background: #ffc12d url(../images/contents/bt_search_fff.png) no-repeat center center; background-size: 16px !important; }
.addNewStudent .bt_search:hover {background: #ffbb17 url(../images/contents/bt_search_fff.png) no-repeat center center;}
.addNewStudent form { overflow: hidden; text-align: left; }
.addNewStudent.remodal .remodal-content07 { position: relative; padding: 30px 45px 50px 45px;text-align:left; border-radius: 0 0 15px 15px;}
.addNewStudent .grp { position: relative; width: 47%; display: inline-block; height: auto; }
.addNewStudent .grp.toTheLeft { }
.addNewStudent .grp.toTheRight { float: right; }
.addNewStudent .grp.toTheRight > div { height: 70px; }
.addNewStudent .grp.toTheRight > div:last-child {margin-top: 10px;}
.addNewStudent .regiName { display: block; width: 100%; text-align: left; }
.addNewStudent .grp > div {  box-sizing: border-box; }
.addNewStudent .grp.toTheLeft .input_txt { border-radius: 0; padding-top:0; padding-bottom:0; }
.addNewStudent .grp.toTheLeft #modalMemRegNm { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-right: 40px; }
.addNewStudent .grp.toTheLeft .bt_search02 {display:none;position: absolute; bottom: 0; right: 0px; width: 40px; height: 40px; margin-top: 0; background: url(../images/contents/ic_search02.png) no-repeat center center; }
.addNewStudent .grp.toTheLeft .bt_search02:hover { background: url(../images/contents/ic_search02_on.png) no-repeat center center;}
.addNewStudent .grp.toTheLeft .grpName { position: relative; }
.addNewStudent .input_txt { width: 100% !important; height: 40px; line-height: 40px; box-sizing: border-box; padding-left: 13px; }
.addNewStudent .selectboxWrap.selectboxWrap02 { width: 100% !important; min-width: 170px !important; height: 41px; line-height: 41px; box-sizing: border-box; }
.addNewStudent .selectboxWrap.selectboxWrap02 select { width: 100%; height: 100%; min-height: 40px; height: 40px;  }
.addNewStudent .selectboxWrap.selectboxWrap02 .allowImg { height: 40px; right: 13px; }
.addNewStudent .selectboxWrap.selectboxWrap02 label { left: 13px; }
.addNewStudent .btns { position: absolute; bottom: 50px; right: 45px; text-align: right; }
.addNewStudent .btns a { height: 40px; line-height: 40px; border-radius: 5px; margin-left: 15px !important; position: static; display: inline-block; font-size: 14px; font-weight: bold; }

.addNewStudent .notFound { overflow: hidden; height: 40px; transition: 0.2s; position: absolute; top: 0; left: 0; width: 100%; text-align: center; }
.addNewStudent .notFound p { margin-top: -46px; height: 0; transition: 0.2s; cursor:default; opacity: 0.9; background: #ff4141; width: 100%; display: block; line-height: 30px; font-size: 12px; font-weight: bold; color: #fff; }
.addNewStudent .notFound p.on { height: 30px; transition: 0.2s; margin-top: 0; }

.addNewStudent .bt_back { display: none; }
.addNewStudent .resultBg { transition:0.1s; opacity: 0; overflow: hidden; z-index: 10000; position: absolute; top: 0; left: 0; width: 100%; height: 0; background: #f3f3f3; padding: 0; box-sizing: border-box; }
.addNewStudent .resultBg.on { transition:0.1s; opacity: 1; overflow: visible; height: 100%; }
.addNewStudent .memberList { margin-top: 0;padding: 30px 45px 50px 45px; }
.addNewStudent .memberList .noResult { display: none; width: 100%; height: 100px; line-height: 100px; font-size: 14px; font-weight: bold; border-radius: 5px; background: #fff; }
.addNewStudent .descript { height: 70px; width: 47%; padding-left: 15px;}
.addNewStudent .descript p { line-height: 20px; padding-top: 30px; text-align:left; }
.addNewStudent .descript .star {display: inline-block;margin-left: -15px;margin-right: 7px;}
.addNewStudent .descript span.break { display: block; margin-left: 10px;}
.memberList li span { color: #484848; } 
.addNewStudent .chooseOne { margin-left: 15px;margin-top: 13px;width: 30px; height: 30px; padding: 0 !important; display: inline-block; background: #fff url('../images/contents/checked_gray.png') no-repeat center center; background-size: 23px !important; border-radius: 50%;     border: 2px solid #d9d9d9;; }
.memberList li { transition: 0.2s; clear: both; width: 100%; background: #fff !important; margin-bottom: 0; padding: 0; background: 0; }
.memberList ul.subUl > li:hover { box-shadow: 0 5px 15px rgba(0,0,0,0.07); transition: 0.2s}
.memberList ul.subUl > li:hover .chooseOne { background: #7e69ff url('../images/contents/checked_fff.png') no-repeat center center; border: 2px solid #7e69ff }
.memberList ul.subUl > li:hover p { cursor: pointer }
.memberList ul.subUl > li { opacity: 1; margin-top: 10px;} 
.resultBg.content .subUl li:first-child { margin-top: 0; }
.memberList ul.subUl > li:last-child { margin-bottom:0; }
.addNewStudent .resultBg.content .subUl .name {padding-left: 0;text-align: left;}
.memberList .email { width: 45% }
.resultBg.content { margin-bottom: 0; }
.resultBg.content .num { width: 8%; }
.memberList li ul.linked {opacity: 0.9;float: left;width: 85%;padding-top: 10px;margin-left: 13.5%; margin-bottom: 5px;}
.addNewStudent  .memberList li ul.linked .name {padding-left: 16px !important;width: 24.4%;}
.memberList li ul.linked .kin { float: right; }
.memberList li ul li { background: #fcfcfc; margin-bottom: 10px; height: 40px !important; line-height: 40px !important; }
.memberList li ul li p { cursor: default !important;height: 40px; line-height: 40px; }
.memberList li p { color: #484848 !important; display: inline-block; text-align: left; }
.memberList li:hover { background: 0; }
.memberList li.on, .memberList li:hover span, .memberList li span { border: 0 none; }

.addNewStudent.remodal .left_titleBox { border-bottom: 1px solid #e5e5e5; height: 68px; box-sizing: border-box; overflow: visible; }

.manageLists.remodal .left_titleBox { box-shadow: none;}
.manageLists .titlebox_inputSearch, .addNewStudent .titlebox_inputSearch { width: 250px; float: right; height: 35px; line-height: 35px; margin-top: 4px; margin-right: 10px; padding-right: 0; }
.manageLists .titlebox_inputSearch .input_txt, .addNewStudent .titlebox_inputSearch .input_txt { padding-top:0; padding-bottom:0; width: 80% !important; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; box-sizing: border-box; line-height: 35px; height: 35px; padding-left: 5px; font-family: 'dotum', sans-serif; }
.manageLists .bt_search, .addNewStudent .bt_search { float: right; width: 35px; height: 35px; border-radius: 50%; margin-top: 0; background: #ffc12d url(../images/contents/bt_search_fff.png) no-repeat center center; background-size: 16px !important; }
.manageLists .bt_search:hover .addNewStudent .bt_search:hover {background: #ffbb17 url(../images/contents/bt_search_fff.png) no-repeat center center;}



.subjectList ul {width: 100%;padding: 0 30px;margin-top: 20px;transition: 0.2s;box-sizing: border-box;margin-bottom: 30px;}
.subjectList ul.on {width: 0;height: 0;transition: 0.2s;}
.subjectList li:hover {box-shadow: 0 3px 5px rgba(0,0,0,0.06);transition: 0.1s;cursor: pointer;font-weight:bold;}
.subjectList li.on {font-weight:bold;box-shadow: 0 5px 10px rgba(0,0,0,0.06)}
.subjectList li.on .bt_delete {display:  block;}
.subjectList li:hover .bt_delete {display:  block;}
.subjectList .modal-scroll {padding-right: 0 !important;height: 310px !important;}
.subAdRight input.input_txt {width: 100% !important;line-height:  40px;height:  40px;box-sizing:  border-box;padding-right: 20px;border-radius: 0;text-overflow:  ellipsis;overflow:  hidden;white-space: nowrap;}
.subjectAdmin .rightBox h2 {font-size: 14px;border-radius:  5px;text-align:  center;line-height:  40px;display: inline-block;height:  40px;cursor:  default;background: #fff;margin-top: 30px;width:  100%;text-align:  center !important;}
.subAdRight div {margin: 10px 0;}
.rightBox .btns a {height:  40px;line-height: 40px;font-weight:  bold;font-size:  14px;width: 95px;margin-left:  0;padding: 0;float: right;display:  inline-block !important;border-radius: 5px;text-align:  center;}
.rightBox a#modalSA_New {float:  left;}
.rightBox .btns {margin-top: 23px;}
.subjectAdmin .sub_title span:last-child {font-weight:  normal;font-size: 12px;}


.manageLists.on .leftBox {width: 0;overflow: hidden;transition: 0.2s; padding: 0; margin:0; height:0;}
.manageLists.on .titlebox_inputSearch {width: 0; height: 0;overflow: hidden;transition: 0.2s; padding: 0; margin:0; height:0;}
.manageLists.on {width: 300px !important;}

.subjectList .bt_delete {width: 23px;display:  none;height: 23px;}
.subjectList .bt_delete span.blind {width: 10px; height: 2px; }


.noResult { overflow: hidden; height: 100px; transition: 0.2s; position: absolute; padding: 0 30px; top: -50px; opacity: 1; z-index: 10; line-height: 100px; left: 0; width: 100%; text-align: center; box-sizing: border-box; }
.noResult p  {margin-top: 0;height: 100px;cursor: default;opacity: 1;background: #ffffff;width: 100%;display: block;border-radius: 5px;font-size: 12px;box-sizing: border-box;line-height: auto;/* padding-top: 30px; */font-weight: bold;color: #484848;}
.noResult.on { transition: 0.2s; top: 70px; z-index:2000000; }

.confirmation {display:none; position:  absolute;z-index:  10000;width:  100%;height:  100%;text-align: center;transition:  0.2s;top: 0;}
.confirmation .bg {position:  absolute;top: 0;left:  0;width:  100%;height:  100%;background:  #fff;z-index: -1;opacity: 0.6; border-radius:5px;}
.confirmation h2.clNm {font-size: 16px;font-weight:  bold;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;display:inline-block;width:90%;height: auto;border-bottom: 1px solid #f3f3f3;padding-bottom: 20px;line-height: 20px;}   
.confirmation .btns a {display:  inline-block;width:  100px;height:  40px;line-height: 40px;font-size:  14px;margin: 0 10px;color:  #fff;font-weight:  bold;text-align: center;border-radius:  5px;background: #16cc9b;}
.confirmation .grp {display:  inline-block;width:  300px;padding: 40px 10px 35px;font-size:  14px;background:  #fff;box-shadow: 0 5px 26px rgba(0,0,0,0.15);border-radius:  5px;margin-top: 122px;}
.confirmation p {margin: 20px 0 27px;} 


.subjectAdmin.on .rightBox {width: 0;overflow: hidden;padding: 0;transition: 0.2s;}
.subjectAdmin.on .leftBox {width: 699px;transition: 0.2s;}







/* ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¡Ãƒâ€šÃ‚Â©ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ */
.remodal.mngMemModal .left_titleBox .modal-topBtn {padding:0 10px;float: right;margin-top: 15px;font-size: 13px;font-weight: bold;background:#fff;color:#484848;}
.remodal .btn-purple{background-color:#b770ff ;}
.manageUsers_leftArea a.btn-purple:hover {background-color: #35bcff !important;color: #fff;}
.manageUsers_leftArea a.btn-purple {padding: 0 25px;margin-top: 5px;margin-left: 0;margin-bottom: 30px;height: 40px;line-height: 40px;box-sizing: border-box;border-radius: 5px;font-weight: bold;color: #1192ff;;background: #e4f6ff !important;}
#inviteMate:hover, #inviteMate.on { color:#1192ff }
a#grupMyRemove:hover {
    color: #ff3737;
}
.manageUsers_leftArea{display:none;width: 260px;position: absolute;height: auto;z-index: 100;box-shadow: 0 13px 20px rgba(0,0,0,0.15);border-right: 1px solid #f3f3f3;background-color: #ffffff;box-sizing: border-box;right: 20px;border-radius: 0 0 15px 15px;}
.manageUsers_leftArea.on { display:block; }
.manageUsers_leftArea .filePlaceList{height:229px;}

.mngMemModal .over_hidden {z-index: -1;position: absolute;padding-top: 62px;top: 0;width: 100%;height: 100%;box-sizing: border-box;}

.manageUsers_leftArea h2 {margin: 25px 0 17px;font-weight: normal;color:#737373;color: #737373;font-size: 14px;border-radius: 5px;line-height: 40px;display: inline-block;height: 40px;cursor: default;background: #f3f3f3;font-weight: bold;margin-top: 35px;margin-bottom: 20px;width: 100%;text-align: center !important;}
.manageUsers_leftArea input {margin-bottom: 15px;width: 120px;height:  40px;line-height:  40px;box-sizing:  border-box;border-radius: 0;width:  100%;padding-left: 12px;}

.manageUsers_leftArea .bt_del{display:inline-block;margin-top:4px; width:19px;height:19px;background:url('../images/contents/bt_del_on.png') no-repeat 0 0;color:#fff;}/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¹Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â° */
.manageUsers_leftArea .bt_add{display:inline-block;margin-top:5px; width:17px;height:17px;background:url('../images/contents/bt_add.png') no-repeat 0 0;color:#fff;}/* ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â«Ãƒâ€¦Ã‚Â¾ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â° */

.manageUsers_leftArea label {width: 100%;display: block;text-align: left;margin-bottom: 0px;color:#484848;line-height: 29px;}

.manageUsers_rightArea.modal-scroll { padding-right: 0;}
.manageUsers_rightArea {overflow: hidden;margin: 0 !important;width: 100% !important;background: #ffffff;height: 203px;} 
.manageUsers_rightArea li.me:hover .position {right: 10px;}
.manageUsers_rightArea #grupMemberUL.noDel li:hover .position { right: 10px !important; }
.manageUsers_rightArea li {position: relative;cursor: default;overflow:hidden;transition: 0.2s;color: #737373;height: 58px;line-height:  55px;border-bottom: 1px solid #f1f3f5;box-sizing:  border-box;padding: 0;width:  100%;background:  #fff;padding-bottom: 15px;}
.manageUsers_rightArea li:last-child p.userSubj { border: none }
.manageUsers_rightArea li a.delete {transition: 0.1s;display: block;right: 20px;position: absolute;margin-top: 0;top: 8px;width: 40px;opacity: 0;border-radius: 50%;height: 40px;line-height: 51px;font-size: 0;text-align: center;color:#fff;/* background-color: rgba(0,0,0,0.1); */}
.manageUsers_rightArea li:hover a.delete span {font-size: 0;display: inline-block;width: 4px;height: 4px;border-radius: 50%;background: #000;float: left;margin: 19px 2px;}
.manageUsers_rightArea li:hover a.delete span:first-child {margin-left: 10px;}
.manageUsers_rightArea li:hover a.delete {transition: 0.1s;display: block;opacity: 1;right: 20px;}
.manageUsers_rightArea li:hover .position {right: 50px;transition: 0.1s;}
.manageUsers_rightArea li:hover .position.top {right: 10px;}
.manageUsers_rightArea li a.delete:hover {background-color: rgba(0,0,0,0.1);}
.manageUsers_rightArea li p {text-align: left;line-height:  55px;color: #737373;width: 100%;}
.manageUsers_rightArea li p span {color: #484848;float: left;}
.manageUsers_rightArea li:hover .position,.manageUsers_rightArea li:hover p#userSubject img {box-shadow: 0 2px 3px rgba(0,0,0,0.2);transition:0.1s}
.manageUsers_rightArea li:hover p span { color:#484848; }
.manageUsers_rightArea li:hover p span.line { background-color:#a9a9a9;  }
.manageUsers_rightArea li.on p span.line { background-color:#a9a9a9 }
.manageUsers_rightArea li.on p span { color:#484848;  }
.manageUsers_rightArea li p.userSubj {/* border-bottom:1px solid #e5e5e5 */}
.manageUsers_rightArea li span.line { margin-top: 11px;background-color: #e3e3e3; width: 1px; height: 10px; vertical-align: middle; display: inline-block; }
.manageUsers_rightArea li span.userName, .manageUsers_rightArea li span.tel, .manageUsers_rightArea li span.email {display: inline-block; ;overflow:hidden; text-overflow: ellipsis;  }
.manageUsers_rightArea li span.userName {text-align: left;width: 20%;padding-right: 10px;box-sizing: border-box;position: relative;font-weight:  bold;text-align: left;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.manageUsers_rightArea li span.tel {width: 20%;padding: 0 5px;text-align: left;box-sizing: border-box;}
.manageUsers_rightArea li span.email {width: 30%;padding: 0 5px;box-sizing:  border-box;text-align: left;padding-right: 5px;}
.manageUsers_rightArea li.on p{color:#484848;}
.manageUsers_rightArea .dotBg { float: left; display: inline-block;  height: 16px; width: 22px; padding-top: 9px; padding-bottom: 1px;}
.manageUsers_rightArea .dot { display: inline-block; width: 4px; height: 4px; border-radius: 2px; background-color: #e3e3e3; }
.manageUsers_rightArea li.on .dot, .manageUsers_rightArea li:hover .dot { background-color: #a9a9a9;  }
.manageUsers_rightArea .userCheck {/* display: none; */float: left;display: inline-block;text-align: center;width: 9%;/* position: absolute; */left: 0;padding: 0;top: 0;}
#memScroll input[type='checkbox']:not(old) + label {border-radius: 3px;display: inline-block;padding: 0;width: 20px;height: 20px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;font-size: 0;margin-right: 0;margin-top: 17px;}
#memScroll input[type='checkbox']:not(old):disabled + label {background-color: #e6ecef !important;border: 2px solid #e6ecef !important;cursor:default;}
.hc #memScroll input[type='checkbox']:not(old):checked + label {background: #36bdff url(../images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 22px;border: 2px solid #36bdff;}
#memScroll input[type='checkbox']:not(old):checked + label {background: #477fff url(../images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 22px;border: 2px solid #477fff;}
#memScroll .content .num {width: 7%;display: inline-block;line-height: 55px;float: left;font-weight:bold;text-align: center;text-indent: -10px;}
#memScroll  .userName {width: 26%;display: inline-block;padding-left: 85px;line-height: 55px;float: left;font-weight:bold;}
#memScroll  .pos {width: 14%;display: inline-block;float: left;line-height: 55px;font-weight:bold;padding-left: 0;}
#memScroll  .tel {width: 18%;display: inline-block;float: left;line-height: 55px;font-weight:bold;padding-left: 0;}
#memScroll  .email {width: 26%;display: inline-block;float: left;line-height: 55px;padding-left: 0;font-weight:bold;}
#memScroll .userSubj img {position: absolute;width: 35px;height: 35px;left: 16%;margin-left: 25px;top: 11px;border-radius: 50%;background: #ecf2f7;border: 1px solid #f1f3f5;box-sizing: border-box;}
#memScroll .checkBox {width: 9%;display: inline-block;text-align: center;float: left;}
#memScroll .headerSort.userName {padding-left: 30px;box-sizing: border-box;}
#memScroll .content {padding-top: 64px;}
.sortHeader p {padding-top: 0;padding-bottom: 0;line-height: 60px !important;}

.userCheck{float:left;padding-top:6px;}
.userSubj{float:left;width: auto;line-height:33px;box-sizing: border-box;white-space:nowrap;padding-left: 0;line-height: 55px;width: 100%;overflow:hidden;text-overflow:ellipsis;}
.manageUsers_rightArea p#userSubject img {border-radius:50%;border: 1px solid #f3f3f3;background-color: #e9eff4;overflow:hidden;width: 35px;height: 35px;margin-top: 9px;float:  left;margin-right: 25px;}
.manageUsers_rightArea p#userSubject .num {width: 7%;padding-right: 10px;box-sizing: border-box;text-align: center;}
.manageUsers_rightArea ul#grupMemberUL {padding: 0;width: 100%;float: left;}
.manageUsers_rightArea {height: 100% !important;}
.manageUsers_rightArea span.userName {font-weight:  bold;}
.manageUsers_rightArea li:hover {background: #f6f9fb;transition: 0.2s;}
.manageUsers_rightArea span.position {background: #ececec url(../images/contents/ic_king.png) no-repeat center center;background-size: 17px;font-size: 0;border-radius: 50%;width: 32px;margin: 0 !important;height: 32px;display:  inline-block;position:  absolute;right: 10px;top: 12px;}
.manageUsers_rightArea span.position.top {background: #ffe28d url(../images/contents/ic_king.png) no-repeat center center;background-size: 17px;}
a#grupMyRemove {
    font-size: 12px;
    color: #fff !important;
    font-weight: bold;
    width: 50px;
    line-height: 55px;
    background: #445667;
}
.manageUsers_rightArea .userName.double {line-height: 40px;height: 50px;}
.manageUsers_rightArea span.userName span {/* margin-top: 13px; */font-weight: normal;text-align: left;position: absolute;top: 18px;left: 0;}
.manageUsers_leftArea form {
    padding: 0 40px;
}
.manageUsers_leftArea h2 {margin: 25px 0 17px;font-weight: normal;color: #737373;color: #ffffff;font-size: 14px;line-height: 60px;display: inline-block;height: 55px;cursor: default;font-weight: bold;margin-top: 0;border-radius: 0;margin-bottom: 15px;width: 100%;text-align: center !important;background-color: #36bdff !important;background-image: linear-gradient(to right, #36bdff , #007eff 141%)!important;}
.manageUsers_leftArea a.btn-purple {margin-top: 5px;}
.manageUsers_rightArea li:hover span {
    font-weight: bold;
}

#memScroll .manageUsers_rightArea li.me:hover {/* box-shadow: 0 5px 10px rgba(0,0,0,0.1); */background: #eef2f5;}
#memScroll .manageUsers_rightArea li:hover img {box-shadow: 0px 0px 5px rgb(255,255,255);}
#memScroll .manageUsers_rightArea li { overflow:visible; }
#memScroll .king .userSubj img {border: 2px solid #ffc239;}
#memScroll .manager .userSubj img {border: 2px solid #b4bfcc;}
#memScroll ul.dropdown {z-index: 100000000000;width: 160px;top: 48px;left: 42%;}
#memScroll ul.dropdown li {padding-bottom: 0 !important;height: 46px;border-bottom: 0;}
.edit .access_option li.king:hover .pos span.txt {transition: 0.1s;font-weight: bold;padding: 0;padding-right: 0;background: none;cursor: default;border-radius: 0;margin-top: 0;box-shadow: none;}
.edit .access_option li:hover .pos span.txt {transition: 0.1s;font-weight: bold;padding: 7px 8px;padding-right: 28px;background: #fff url(../images/side_area/bt_open.png) no-repeat center right;cursor:pointer;border-radius: 5px;margin-top: -3px;box-shadow: 0 4px 5px rgba(0,0,0,0.1);}
.edit .access_option li:hover .pos:hover { cursor:pointer; }
.access_option li:hover .pos span.txt, #memScroll .access_option li:hover .pos:hover { cursor:default; }
#memScroll .manageUsers_rightArea {overflow: visible;}
#memScroll  .over_hidden {overflow: visible;}
#memScroll .settingBg .btn_group a#inviteMate {display: inline-block;}
#memScroll .settingBg .btn_group a {display: none;}
#memScroll.edit .settingBg .btn_group a {display: inline-block;}

.access_option li.king .pos .txt:before {content: '';display: inline-block;width: 30px;border-radius: 50%;margin-left:-7px;height: 30px;margin-right: 10px;margin-top: 13px;float: left;background: #fff url(../images/contents/ic_king.png) no-repeat 5px 5px;background-size: 19px;}
.access_option li.manager .pos .txt:before {content: '';display: inline-block;width: 30px;height: 30px;margin-left:-7px;border-radius: 50%;height: 30px;margin-right: 10px;margin-top: 13px;float: left;background: #fff url(../images/contents/ic_manager.png) no-repeat  5px 5px;background-size: 19px;}







/* ÃƒÆ’Ã‚Â­Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â Ãƒâ€¹Ã¢â‚¬Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â° ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢Ãƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â½ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â°ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â° */
.crst.hc .manageUsers_leftArea { display:  none;}
.crst.hc .manageUsers_rightArea { width:  100%;}




/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ */

.access_leftArea {float: right;display: inline-block;width: 540px;height:  360px !important;background:  #f3f3f3;margin: 0;border-radius:  0 0 15px 0;}
h1 { cursor: default; }
.access_leftArea .dot { position: absolute; left: 0; top: 50%; margin-top: -2px; display: inline-block; width: 4px; height: 4px;  background-color: #c5c5c5; border-radius: 2px;  }
.accessMember_ul {margin-right: 0;padding: 30px 30px;}
.accessMember_ul li {position: relative;height: 55px;width: 100%;line-height: 55px;overflow: hidden;text-align: left;border-radius: 5px;background:  #fff;margin-bottom: 15px;}
.accessMember_ul li p { cursor: default; display: inline-block; width: 100%; padding-left: 20px; box-sizing: border-box; }
.accessMember_ul li p span { color: #a3a3a3;  }
.accessMember_ul li a {display: inline-block;float: right;margin-top: 0;height: 32px;line-height: 32px;border-radius: 18px;padding: 0;width: 32px;position:  absolute;top: 11px;box-sizing: border-box;float:  left;right: -50px;background: #439ff6;color: #fff;}
.accessMember_ul .underline { display: inline-block; width: 100%; }
.accessMember_ul p.userCheck {width: 44px;padding-left:  0;text-align: center;display:  inline-block;padding: 0;}
.accessMember_ul .userCheck label {margin-top: 16px;margin-left: 10px;}
.accessMember_ul img {width:  40px;height:  40px;float:  left;margin-top: 7px;margin-right: 10px;border-radius:50%; border: 1px solid #f3f3f3; overflow:hidden;}
.accessMember_ul span.num {width: 40px;display:  inline-block;float:  left;text-align:  center;}
.accessMember_ul span.userName.double {line-height:  39px;font-weight:  bold;}
.accessMember_ul span.userName {height: 55px;width: 70px;line-height:  55px;display:  inline-block;float:  left;box-sizing:  border-box;padding-right: 10px;text-overflow:  ellipsis;overflow:  hidden;white-space:  nowrap;}
.accessMember_ul span.userName.double span {margin-top: -20px;font-weight:  normal;height: auto;display:  block;}
.accessMember_ul span.tel {height: 55px;display:  inline-block;padding-right: 10px;width: 100px;box-sizing:  border-box;text-overflow: ellipsis;overflow:  hidden;white-space:  nowrap;}
.accessMember_ul span.email {height: 55px;display: inline-block;padding-right: 10px;width: 193px;box-sizing: border-box;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.accessMember_ul li:hover {transition:  0.2s;cursor:  default;/* overflow: visible; */box-shadow: 0 5px 15px rgba(0,0,0,0.07);}
.accessMember_ul span.current {width:  100%;color:  #fff;display: block;text-align: center;line-height: 32px;background: #439ff6 url(../images/contents/btn_add_fff.png) no-repeat center center;width:  32px;font-size: 0;box-sizing:  border-box;}
span.position {background: #ececec url(../images/contents/ic_king.png) no-repeat center center;background-size: 17px;font-size: 0;border-radius: 50%;width: 32px;margin: 11px;height: 32px;display: inline-block;position: absolute;right: 0;}
.position.top {background: #ffe28d url(../images/contents/ic_king.png) no-repeat center center;background-size: 17px;}

.accessMember_ul li:hover .dot { background-color: #a2a2a2;  }
.accessMember_ul li:hover p span { color: #6d6d6d  }
.accessMember_ul li:hover a {transition: 0.1s;opacity:  1;color: #fff;right: 11px;}
.accessMember_ul li:hover a.out {border: 0 none;color: #ff5d5d;}
.accessMember_ul li:hover a.out .current { color: #ff5d5d; }
.accessMember_ul li a.out:hover {background: #ff5d5d;color: #fff;padding: 0 20px;}
.accessMember_ul li a:hover {background: #439ff6;color: #fff;width: auto;transition:  0.1s;padding: 0 20px;}
.accessMember_ul li a.out:hover .current {color: #fff; display: none }
.accessMember_ul li a.in:hover .current { color: #fff; display: none }
.accessMember_ul li a.out:hover .new:after {color: #fff; display: inline-block; content: "ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â­ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â Ãƒâ€¦Ã¢â‚¬Å“"; }
.accessMember_ul li a.in:hover .new:after {color: #fff;display: inline-block;width:  auto;transition: 0.1s;content: "ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬";}
.accessMember_ul li:hover a .current {color: #fff;text-align: center;width:  100%;font-size: 0;background: #439ff6 url(../images/contents/btn_add_fff.png) no-repeat center center !important;background-size: 30px !important;border-radius: 18px;padding: 0;float:  left;top: -40px;margin: 0;display: inline-block;}
.accessMember_ul li a.out {background: #ff5d5d;color:  #fff;border: 0 none;}
.accessMember_ul li a.out .current {background: #ff5d5d url(../images/contents/ic_attnd_no_fff.png) no-repeat center center !important;background-size: 13px !important;}

.access_rightArea {float: left;width: 260px;box-sizing:  border-box;height: auto; height: 360px !important;padding: 0 40px;border-left: 1px solid #e6e5e5;border-radius: 0 0px 0px 15px;background-color: #ffffff;}
.access_rightArea h2 {color: #484848;font-size: 14px;border-radius: 5px;line-height: 40px;display: inline-block;height: 40px;cursor: default;/* background: #f3f3f3; */font-weight: bold;margin-top: 35px;margin-bottom: 15px;width: 100%;/* border-bottom: 1px solid #f3f3f3; */text-align: center !important;}
.access_rightArea li {height: 40px;width: 100%;margin-bottom: 20px;}
.access_rightArea p.descript {text-align: left;text-indent: -10px;line-height:  20px;margin-bottom: 40px;margin-top: 27px;width:  160px;margin-left: 10px;}
.access_rightArea a {border-radius: 27px !important;display: inline-block;width: 100%;height: 100%;color: #a3a3a3;border: 1px solid #e2e2e2;background: #ffffff;background-image: url('../images/contents/checked_gray.png');background-repeat: no-repeat;background-position: 10px center;text-align:  left;line-height: 40px;text-indent: 37px;}
.access_rightArea a.on, .access_rightArea a:hover, .access_rightArea a.on:hover {background: #7e69ff url('../images/contents/checked_fff.png') no-repeat 10px center;border: 1px solid #7e69ff;color: #ffffff;box-shadow: 0 3px 5px rgb(126, 105, 255,0.4);}
.access_rightArea a:hover {box-shadow: 0 3px 5px rgb(126, 105, 255,0.2);background: #ffffff url(../images/contents/checked_purple.png) no-repeat 10px center;border: 1px solid #7e69ff;color:  #7e69ff;}
.access_rightArea a.on:hover {background-color: #917fff;border: 1px solid #917fff;box-shadow: none;transition:  0.4s;}

/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“Ãƒâ€šÃ‚Âc */

.hc .access_rightArea a.on, .hc .access_rightArea a:hover, .hc .access_rightArea a.on:hover {background: #36bdff url('../images/contents/checked_fff.png') no-repeat 10px center;border: 1px solid #36bdff;color: #ffffff;box-shadow: 0 3px 5px rgb(60, 170, 255, 0.4);}
.hc .access_rightArea a:hover {box-shadow: 0 3px 5px rgb(60, 170, 255, 0.4);background: #ffffff url(../images/contents/checked_blue.png) no-repeat 10px center;border: 1px solid #36bdff;color:  #36bdff;}
.hc .access_rightArea a.on:hover {background-color: #72d1ff;border: 1px solid #72d1ff;box-shadow: none;transition:  0.4s;}


/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â©ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ */

.remodal.mainMsgModal {width: 600px;}
p#modalMesgDesc {width: 38%;position:  absolute;right: 49px;box-sizing: border-box;top: 89px;background: #f3f3f3;border: 30px solid #fff;border-left:  10px solid #fff;border-right: 10px solid #fff;border-bottom: 30px solid #FFF;box-shadow: 0 3px 15px rgba(0,0,0,0.05);border-radius: 10px;padding: 15px 15px !important;height: 294px;text-align: left;font-size: 14px;line-height: 24px !important;}
.mainMsgModal .over_hidden {width:  45%;text-align:  left;}
.mainMsgModal .over_hidden input {padding-top: 0;padding-bottom: 0;box-sizing:  border-box;height:  40px;line-height:  40px;width:  100% !important;border-radius:  0;}
.mainMsgModal  .selectboxWrap.selectboxWrap02 {width:  100% !important;box-sizing:  border-box;padding: 0 0;height:  40px;line-height:  40px;border-radius:  5px;}
.mainMsgModal .selectboxWrap.selectboxWrap02 .allowImg {height: 40px;}
.mainMsgModal .over_hidden label {text-align:  left;}
.mainMsgModal .remodal-content07.bg_gray {padding: 30px 40px 50px;border-radius:  0 0 15px 15px;}
.mainMsgModal a#modalMesgSendBtn {position:  absolute;right: 75px;bottom: 98px;border-radius:  5px;height: 35px;font-weight:  bold;font-size:  14px;line-height: 35px;}

/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â©ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â§ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â Ãƒâ€šÃ‚Â¡ ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â±Ãƒâ€šÃ‚Â´ */

.failedMsgs .modal-scroll .memberList p { height: 30px; box-sizing:border-box; font-weight: bold; width: 100%; padding: 0 20px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 30px; }
.failedMsgs .modal-scroll .memberList{ margin-top: 15px; } 
.failedMsgs .modal-scroll p { height: 55px; line-height: 55px; font-size: 14px; }
.failedMsgs .btns a { border-radius: 5px; height: 40px; line-height: 40px; font-size: 14px; font-weight: bold; width: 15%;  }
.failedMsgs .acceptBg { border-radius: 0 0 15px 15px; }
/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¾ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¹ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â¡ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â²Ãƒâ€šÃ‚Â­ */



.remodalAccept .left_titleBox {}
.remodalAccept {background: #fff;max-width: 1000px;width: 90%;float: right;height: 100%;border-radius: 0;margin-bottom:0;}
.remodalAccept.remodal .remodal-close {left: -60px;z-index: 1000000;top: 14px; }
.remodalAccept .left_titleBox {background: #ffff;border-radius: 0;}
.remodalAccept .acceptBg.remodal-content08 {overflow: hidden !important;background: transparent;height: 100% !important;width: 100%;padding-top: 62px !important;margin-bottom: 0;padding-left: 0;padding-bottom: 0;padding-right: 0;top: 0;box-sizing: border-box;position: absolute !important;border-radius: 0;}
.remodalAccept p.selectAll {position: fixed;bottom: 0;display: block;left: 0;width: 100%;background: #ffffff;height: 70px;border-top: 1px solid #e8e8e8;padding: 0;text-align: left;}
.remodalAccept .remodal-content08 {}
.remodalAccept .total {display:  inline-block;}
.remodalAccept .memberList li div {padding: 0;border-top: 0 none;}
.remodalAccept .btns {
    position: fixed;
    bottom: 16px;
    right: 30px;
}
.remodalAccept .btns a {height: 40px;line-height: 40px;border-radius:  5px;font-size:  14px;font-weight:  bold;margin-left: 20px;}
.selectAll span.total {display:  inline-block;cursor:default;position: absolute;left: 150px;font-weight: bold;font-size: 14px;top: 22px;line-height: 20px;height: 20px;padding-left: 20px;border-left: 1px solid #e8e8e8;}
.acceptBg .noRequest {color: #737373;line-height: 100px;font-size: 14px;background: #f1f3f5;border-radius: 5px;margin: 0 40px;margin-top: -60px;font-weight: bold;}
.remodalAccept .acceptBg .noRequest  { margin-bottom: 40px; }
#approveListUl span.item01 {font-weight:  bold;}
#approveListUl li:hover, #approveListUl li.on { transition:0.1s;}
#approveListUl li:hover div span {cursor: default;}
#approveListUl img {width:  40px;height:  40px;float:  left;border-radius: 50%;margin: 9px 10px 0 0;border: 1px solid #f3f3f3;overflow:hidden;}
#approveListUl li span {display:  inline-block;height: 60px;line-height: 60px;text-align: left;}
#approveListUl span.num {float: left;width: 7%;text-align:  center;}
#approveListUl li:hover,#approveListUl li:hover div span { cursor:pointer; }
.acceptBg .memberList li { border-bottom:1px solid #f1f3f5; }
.acceptBg .memberList li.on span { font-weight: bold !important; }

.remodalAccept p.selectAll label span { position:absolute; left:70px; width:100px;top: 31px; font-size: 14px !important; display:inline-block; }
.remodalAccept p.selectAll input[type='checkbox']:not(old) + label {margin: 20px 30px;display: inline-block;padding: 0;width: 20px;height: 20px;border-radius: 3px;border: 2px solid #e5e8ea;background: #e5e8ea url('../images/contents/checked_gray.png') no-repeat 0px 0px;background-size: 21px !important;font-size: 0;}
.remodalAccept p.selectAll input[type='checkbox']:not(old):checked + label {background: #36bdff url('../images/contents/checked_fff.png') no-repeat 0px 0px;background-size: 21px !important;border: 2px solid #36bdff;}
.remodalAccept p.selectAll input[type='checkbox']:not(old):checked + label span { font-weight:bold; }
.remodalAccept #approveListUl input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 20px;height: 20px;border-radius: 3px;border: 2px solid #e5e8ea;background: #e5e8ea url('../images/contents/checked_gray.png') no-repeat 0px 0px;background-size: 21px;font-size: 0;margin-right: 8px;}
.remodalAccept #approveListUl input[type='checkbox']:not(old):checked + label {background: #36bdff url('../images/contents/checked_fff.png') no-repeat 0px 0px;background-size: 21px;border: 2px solid #36bdff;}
.remodalAccept .membercheck {margin-top: 18px;}

.remodalAccept #approveListUl {padding: 20px 40px 79px 40px;}
.remodalAccept #approveListUl span.item04 {float:  right;padding-right: 20px;background: #f1f3f5;line-height: 35px;height: 35px;font-weight: bold;color: #ff4f4f;text-align: center;padding-right: 0;border-radius: 5px;padding: 0 15px;margin-top: 13px;}
.remodalAccept #approveListUl .memberList li { transition: 0.1s; clear: both; width: 100%; background: #fff !important; border-radius: 5px; margin-bottom: 15px; padding: 0; background: 0; }
.remodalAccept #approveListUl li.on span.item04 { color:#fff; background:#36bdff; }

.memberList p.forE {height: 35px;line-height: 35px;position:  static;margin-bottom: 10px;box-sizing:  border-box;background:  #f3f3f3;margin-left:  90px;overflow:  hidden;padding: 0 10px;width: 540px;border-radius: 5px;text-overflow: ellipsis;white-space: nowrap;}
.memberList p.forE span {position: static;padding: 0;margin: 0;line-height: 30px!important;float:  none;}
.memberList p.forE span.item05 {margin-right: 10px;}
.memberList p.forE span em {font-style:  normal;font-weight:  bold;}

.remodalAccept.he li:hover { height: 104px; transition:0.2s; overflow: visible; }
.remodalAccept.he li { height: auto; transition:0.2s; overflow:hidden; }





/* ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“Ãƒâ€šÃ‚Âc */
.hc.remodalAccept .memberList p.forE { display:none !important; }
/* ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“Ãƒâ€šÃ‚Âe */

.he.remodalAccept p.selectAll input[type='checkbox']:not(old):checked + label, .he.remodalAccept p.selectAll input[type='checkbox'] + label:hover {border: 2px solid #7e69ff;background: #7e69ff url(../images/contents/checked_fff.png) no-repeat 10px center !important;color: #fff;background-size: 21px;}
.he.remodalAccept #approveListUl input[type='checkbox']:not(old):checked + label, .he.remodalAccept #approveListUl input[type='checkbox'] + label:hover {border: 2px solid #7e69ff;background: #7e69ff url(../images/contents/checked_fff.png) no-repeat center center;background-size: 23px;}







/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â·Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€šÃ‚Â¹ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ */
.mngGrpModal .grp.toTheRight h5 {padding-bottom: 0px;font-size: 16px;/* border-bottom: 1px solid #f1f3f5; */margin-top: 40px;}
.remodal.mngGrpModal .left_titleBox {
	position: fixed;
    width: 100%;
    z-index: 10000;
    background: #fff;border-radius:0;
}
.remodal.mngGrpModal {    height: 100%;
    max-width: 1000px;
    width: 90%;
    float: right;border-radius:0;margin:0;}
.remodal.mngGrpModal button.remodal-close {
    left: -60px;
    z-index: 1000000;
    top: 14px;
}
.widen .manageGroups_leftArea {
    transition: 0.1s;
    width: 0;
}
.manageUsers_rightArea li:hover p span span {
    color: #484848;
}
input#grupSetting_grup_nm:focus, input#grupSetting_grup_nm:hover {
    border-bottom: 2px solid #477fff;
}
input#grupSetting_grup_nm {
    position: static;
    top: 0px;
    font-weight: bold;
    font-size: 21px;
    border: 0 none;
    padding-left: 0;
    border-bottom: 2px solid #f1f3f5;
    height: 60px;
    padding-right: 40px;
}

form#groupFileUpload {
    position: relative;min-height: 100%;
}

form#groupFileUpload > fieldset >label {
    margin-top: 100px;
}
form#groupFileUpload .grpName { display:none; }
form#groupFileUpload .clr {width: 25px !important;height: 25px;position: absolute;padding: 0;font-size: 0;border-radius: 50%;top: 28px;right: 0;}
form#groupFileUpload .clr:hover {transform: scale(1.2);transition:0.2s;}
.mngGrpModal .half {width: 100%;float: left;position: relative;box-sizing: border-box;padding-bottom: 15px;}
.mngGrpModal .half.grp {padding-left: 0 !important;}
.manageGroups_leftArea{width: 100%;border-bottom: 1px solid #eaeaea;background-color: #ffffff;border-radius: 0;z-index: 10000;z-index: 1000000;position: relative;transition: 0.4s;padding: 10px 0;height: auto;min-height: auto !important;box-sizing: border-box;top: 0;overflow: hidden;overflow-x: auto;}
.manageGroups_leftArea .filePlaceList{height:229px;}

.manageGroups_leftArea h2 { margin: 25px 0 20px; font-weight: normal }
.manageGroups_leftArea input { margin-bottom: 15px; width: 120px;  }

.manageGroups_leftArea .bt_del{display:inline-block;margin-top:4px; width:19px;height:19px;background:url('../images/contents/bt_del_on.png') no-repeat 0 0;color:#fff;}/* ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â«ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¹ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¨ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚ÂªÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â°ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€šÃ‚Â¢ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã¢â‚¬Â ÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€šÃ‚Â° */
.manageGroups_leftArea .bt_add{display:inline-block;margin-top: 8px;width: 30px;height: 30px;background: #489aff url(../images/contents/bt_add.png) no-repeat center center;color:#fff;}/* ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â­ÃƒÆ’Ã¢â‚¬Â¦ÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢ÃƒÆ’Ã¢â‚¬Â¦ÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â«ÃƒÆ’Ã¢â‚¬Â¦Ãƒâ€šÃ‚Â¾ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€¹Ã…â€œÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã¢â‚¬Â ÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€šÃ‚Â° */

.manageGroups_leftArea label { width: 100%; display: block; text-align: left; margin-bottom: 7px; }
.manageGroups_leftArea > .scroll {height: 305px !important;}
.manageGroups_leftArea input { margin-bottom: 0; line-height: 40px; height: 40px }
.newGroupBox{position:relative;bottom:0;left: auto;right: 15px;float: right;z-index: 1000000000;}
.newGroupBox .newGroup{display:inline-block;width:100%;line-height: 50px;background-color: #ffffff;color: #484848;font-size: 14px;font-weight: bold;border-top: 0;}/* ÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÂ¢Ã¢â‚¬Å¾Ã‚Â¢ÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â­ÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚ÂÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â´ÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â«ÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚ÂÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â¢ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â ÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¶ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â¢ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚ÂÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚ÂªÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â°ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â¢ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€¦Ã‚Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¬ */
.newGroupBox .newGroup.on, .newGroupBox .newGroup:hover {background-color: #ffffff;color: #484848;font-weight:  bold;}

.manageGroups_subHeader { height: 40px; width: 429px; float: right; padding: 0 20px; background: #ededed; border-bottom: 1px solid #e5e5e5;}
.manageGroups_subHeader a { color: #a3a3a3; float: left; display: inline-block; padding: 6px 15px; margin-top: 7px; border: 1px solid #d2d2d2; border-radius: 20px; }
.manageGroups_subHeader a:hover { color: #fff; background-color: #ff5a5a; border: 1px solid #ff5a5a; }
.manageGroups_subHeader p { float: right; line-height: 40px; }
.manageGroups_rightArea { overflow: hidden; float:right;margin:25px 10px 25px 10px;width:426px; height: 169px;}
.manageGroups_rightArea li { position: relative; cursor: default; overflow:hidden; color: #a3a3a3;}
.manageGroups_rightArea li a { transition: 0.1s; display: block; right: -50px; position: absolute; margin-top: -10px; top: 50%; width: 20px; height: 20px; line-height: 20px; text-align: center; color:#fff; background-color: #e3e3e3; border-radius: 50%;  }
.manageGroups_rightArea li:hover a { transition: 0.1s; display: block; right: 5px;  }
.manageGroups_rightArea li a:hover { background-color: #ff5a5a; }
.manageGroups_rightArea li p {text-align: left; color: #a3a3a3;}
.manageGroups_rightArea li p span { color: #a3a3a3;}
.manageGroups_rightArea li:hover p span { color:#6d6d6d; }
.manageGroups_rightArea li:hover p span.line { background-color:#a9a9a9 }
.manageGroups_rightArea li.on p span.line { background-color:#a9a9a9 }
.manageGroups_rightArea li.on p span { color:#6d6d6d;  }
.manageGroups_rightArea li p.userSubj { border-bottom:1px solid #e5e5e5 }
.manageGroups_rightArea li span.line { background-color: #e3e3e3; width: 1px; height: 10px; vertical-align: middle; display: inline-block; }
.manageGroups_rightArea li span.userName, .manageGroups_rightArea li span.tel, .manageGroups_rightArea li span.email {display: inline-block;  }
.manageGroups_rightArea li span.userName { text-align: left; width: 105px; text-align: left; }
.manageGroups_rightArea li span.tel { width:  115px; text-align: center; }
.manageGroups_rightArea li span.email { width: 175px; text-align: center; }
.manageGroups_rightArea li.on p{color:#6d6d6d;}
.manageGroups_rightArea .dotBg { float: left; display: inline-block;  height: 16px; width: 22px; padding-top: 9px; padding-bottom: 1px;}
.manageGroups_rightArea .dot { display: inline-block; width: 4px; height: 4px; border-radius: 2px; background-color: #e3e3e3; }
.manageGroups_rightArea li.on .dot, .manageGroups_rightArea li:hover .dot { background-color: #a9a9a9;  }
.manageGroups_rightArea .userCheck { display: none  }
.userCheck{float:left;padding-top:6px;}
.manageGroups_rightArea .userSubj{float:left;width:397px;line-height:33px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.manageGroups_leftArea img {height:0;width:0;opacity:0;transition:0.2s;outline:none;border:none;border-radius: 5px;box-sizing: border-box;margin-top: 0;display: block;}
.manageGroups_leftArea:hover img {height: 110px;width: 180px;border: 1px solid #f1f3f5 !important;opacity:0.5;transition:0.2s;margin-top: 10px;}
.manageGroups_leftArea:hover {height: auto;transition: 0.4s;padding: 20px 0px;}
.groupList li{display: inline-block;padding: 0 20px;border: 0 none;margin-right: 10px;/* margin-top: 9px; */}
.groupList li:hover { cursor:pointer; }
.groupList li:hover img { opacity:1; }
.groupList li.on img { opacity:1; }
.groupList li:last-child {/* border-bottom: 0 none; */}
.groupList li .placeNm{width: auto;display:inline-block;box-sizing:  border-box;text-align:  left;padding: 0;font-weight:  bold;font-size: 14px;line-height: 40px;background-color: #ffffff;color: #9da8b3;}
.groupList li span {float: left;}
.manageGroups_leftArea li a.placeNm { overflow: hidden;text-overflow: ellipsis;white-space: nowrap;max-width: 150px;}
.manageGroups_leftArea:hover li a {width: 180px;}
.groupList li .placeNm span {display: inline-block;width: 7px;margin-top: 17px;height: 7px;border-radius: 50%;background: red;vertical-align: middle;margin-right: 10px;float: left;}
.groupList li.on a.placeNm, .groupList li:hover a.placeNm {color: #4e4e4e;background-color: #ffffff;font-weight:  bold;}/* ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€¦Ã‚Â¾ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â­ÃƒÆ’Ã¢â‚¬Â ÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚ÂÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€šÃ‚Â¹ÃƒÆ’Ã¢â‚¬Â¦ÃƒÂ¢Ã¢â€šÂ¬Ã…â€œ */
.manageGroups_leftArea .groupList{height:auto;text-align: left;padding: 0 10px;}
.manageGroups_leftArea .groupList{height:auto;}
.manageGroups_leftArea:hover .groupList li.add a {line-height: 102px;text-align: center;font-size: 19px !important;background: transparent;}
.manageGroups_leftArea:hover .groupList li.add {background: #f1f3f5;margin-top: 11px;float: left;border: 2px dashed #e7e9ea;border-radius: 5px;margin-left: 20px;margin-right: 30px;}
.manageGroups_leftArea:hover .groupList li.add:hover a {background: #fff;transition: 0.2s;color: #477fff;}
.manageGroups_leftArea:hover .groupList li.add:hover {background: #Fff;transition: 0.2s;}

.createNewGroup {z-index: 0;position: static;right: 0;width: 569px;text-align:  left;height: 354px;box-sizing:  border-box;background: transparent;padding: 30px 50px;border-radius: 0 0 15px 0;}
.createNewGroup .groupImg {display: inline-block;font-size: 0;width: 300px;height: 167px;margin: 0 auto;margin-top: 20px;border-radius: 5px;border: 1px solid #e8e8e8;background: url(../images/contents/groupImg.png) no-repeat center center;background-size: 100%;/* margin-bottom: 1px; */cursor: pointer;box-sizing:  border-box;}
.createNewGroup .groupImg:hover {  }

.createNewGroup input {width: 229px;margin: 20px 0 15px;display:  block;/* float:  right; */height:  40px;box-sizing:  border-box;border-radius: 0;clear:  both;}
.createNewGroup input.newGroupIntro {display: block;margin: 0 auto;margin-top:15px;}
.createNewGroup .grpNm {display: block;font-size: 16px;font-weight: bold;}
.createNewGroup textarea {height: 75px;line-height: 24px;max-height: 168px !important;transition: 0.1s;width: 100% !important;max-width: 100%;box-sizing: border-box;border-top: 1px solid #e7e9ea !important;border-bottom: 1px solid #e7e9ea !important;padding: 15px 0;padding-top:27px;margin: 0px;min-width: 240px;max-height: 100px;border: 0;font-size: 18px;font-weight: bold;min-height: 75px;border-radius: 0;resize: none;margin-top:25px;outline: none;}
.createNewGroup textarea.on {background: #fff !important;}
.createNewGroup textarea#grupSetting_grup_rmk:focus {border:0 none;border-top: 1px solid #477fff !important;border-bottom: 1px solid #477fff !important;background: #fff; min-height:150px !important;}
textarea#grupSetting_grup_rmk:focus,textarea#grupSetting_grup_rmk:focus:hover {background: #fff;transition: 0.1s;}
textarea#grupSetting_grup_rmk:hover {border-top: 1px solid #d2d7e2 !important;border-bottom: 1px solid #d2d7e2 !important;}
.createNewGroup a.permission.on, .createNewGroup a.permission:hover {border: 1px solid #ffba00;color: #ffba00;background: #fff url(../images/contents/checked_on_yel.png) no-repeat 11px center;}
.createNewGroup a.permission {border-radius: 7px;width: 221px;height: 100%;color: #a3a3a3;border: 1px solid #e6e5e5;background: #fff;background: #fff url(../images/contents/checked_off_yel.png) no-repeat 11px center;line-height: 40px;text-indent: 42px;text-align:  left;margin-top: 15px;box-sizing:  border-box;margin-top: 18px;}
.createNewGroup a.permission {display: block;width: 100%;height: 100%;color: #9da8b3;background: transparent;background-image: url(../images/contents/checked_fff.png);background-repeat: no-repeat;background-position: 7px center;background-size: 21px;text-align:  left;border: none;line-height: 40px;text-indent: 37px;font-size: 14px;}
.createNewGroup a.permission.on, .createNewGroup a.permission:hover{background: url(../images/contents/checked_fff.png) no-repeat 7px center;color: #484848;background-size: 21px;border: none;}
.createNewGroup a.permission:hover {
    background: url(../images/contents/checked_fff.png) no-repeat 7px center;
    background-size: 21px;
}
.createNewGroup a.permission.on:hover {
    color: #484848;
    background-size: 21px;
    border: none;
    background: url(../images/contents/checked_fff.png) no-repeat 7px center;
    background-size: 21px;
}

.createNewGroup .btns {width:  100%;padding-right:40px;box-sizing:border-box;text-align: right;position: static;bottom: 0;background: #fff;left: 0;float: left;z-index: 1;box-shadow: 0 -21px 20px rgba(255,255,255,1);}.createNewGroup .btns a {/* height: 35px; */border-radius:  5px;line-height: 40px;font-size: 14px;margin-top: 0 !important;font-weight:  bold;display: inline-block;margin-bottom: 35px;padding: 0 15px;background: #fff !important;margin-left: 15px !important;border: 1px solid #e7e9ea;}
.createNewGroup .btns a:hover { background: #f1f3f5 !important; }
.createNewGroup #grupDelete {display:inline-block;box-sizing: border-box;margin-left:10px;width:auto;border-radius:5px;padding: 0 15px;margin-left:15px;font-size: 14px;float:right;font-weight:  bold;margin-top: 20px;color: #484848;}
.createNewGroup #grupDelete:hover { background:#ff3737 !important; color:#fff; }
.createNewGroup .grp.toTheLeft {position: relative;height: auto;margin-top: 0;float: left;width: 300px;margin-bottom: 0;}
.mngGrpModal .createNewGroup .grp.toTheLeft {width: 100%;}
.createNewGroup .grp.toTheLeft input {padding-left: 12px;width: 100% !important;display: block;position: static;float: none;margin:0;margin-top: 20px;margin-bottom: 0;}
#wrap .half input.btnDel {z-index:1000000;position: absolute !important;left: 308px;top: 9px;width: 40px  !important;height: 40px  !important;border-radius: 50%;background: #fff url(../images/contents/ic_close.gif) no-repeat center center !important;border: 1px solid #e7e9ea;font-size: 0;}
#wrap .half input.btnDel:hover { border: 1px solid #ff5454; background: #ff5454 url(../images/contents/ic_close_fff.gif) no-repeat center center !important; } 
.createNewGroup .grp.toTheLeft .grpNm {width: 100%;text-align: left;}
.mngGrpModal h4 {display: inline-block;float: left;font-size: 18px;line-height: 50px;margin: 0 !important;}
.createNewGroup .btns a {margin-bottom: 0;margin: 0;}
.groupList li:hover a.placeNm {background: #ffffff;color:  #484848;}
.groupList li.on:hover a.placeNm {background: #ffffff;color: #484848;}

.mngGrpModal .over_hidden {padding-top: 62px;top: 0;position: absolute;height: 100%;width: 100%;overflow: visible;box-sizing: border-box;}
.createNewGroup {transition: 0.1s}
.widen.remodal .createNewGroup {width: 100% !important;padding: 62px 100px 30px;transition: 0.1s;}
.widen.remodal .createNewGroup .groupImg {width: 320px;height: 187px;}
.widen.remodal .createNewGroup .grp.toTheLeft {width: 320px;right: 50px;}
.widen.remodal .createNewGroup textarea {width: 320px !important;min-width: 320px !important;max-width: 320px !important;}
.widen.remodal .createNewGroup .grp.toTheRight {width: 260px;margin-left: 35px;}


.createNewGroup .selectBg {
	min-width: 240px;
	float: none;
	margin-top: 12px;
	height: 45px;
	padding-left: 12px;
}

.createNewGroup .selectUl { min-width: 240px}

.createNewGroup .selectBg .selectTitle {
	font-size: 14px;
	line-height: 45px;
	padding-left: 0;
}


.createNewGroup .selectUlBg {
	top: 75px;
	left: 0;
	min-width: 298px;
	width: 298px;
	box-shadow: 0 10px 13px 0 rgba(0,0,0,0.15);
}

.createNewGroup .createSelectUlBg.on {
	display: block !important;
}

.createNewGroup .selectUlBg li:hover {
	cursor: pointer;
}

.widen .selectUlBg {
	width: 318px;
	right: 52px;
}

.createNewGroup a.permission.on:after {
    background: #36bdff url('../images/contents/checked_fff.png') no-repeat 1px center;
    background-size: 21px;
}


.mngGrpModal .over_hidden {position:static !important;padding-top: 0 !important;}
#mngGrpScroll .createNewGroup .btns {position:static;padding: 0;}
#groupFileUpload .grp.toTheRight {position: static !important;}


/* common_modal */
/* 1513 */
.manageGroups_leftArea > .scroll {max-height: 375px !important;height: auto !important;}
/* 1554 */
.createNewGroup {height: 100%;box-sizing: border-box;padding-top: 0;padding-left: 39px;top: 0;overflow-y: auto;max-width: 760px;float: left;width: 100%;border-radius: 20px;left: 0;/* margin: 30px; *//* box-shadow: 0 4px 10px rgba(0,0,0,0.1); */}
/* 1573 */
.createNewGroup .grp.toTheRight {width: 200px;}
/* 1565 */
.createNewGroup a.permission {line-height: 35px;position: relative;font-weight: bold;margin-top: 17px;transition: 0.2s;float: none;margin-bottom: 35px !important;}


#groupSelectBg h5 {font-size: 16px;margin: 40px 0 10px;padding-bottom: 10px;/* border-bottom: 1px solid #f1f3f5; */}
#groupSelectBg.grp {position:relative;float: left;width: 50%;box-sizing: border-box;padding-right: 40px;}
/* common */

/* 913 */
.createNewGroup .selectBg {/* background: url(../images/side_area/ic_depthopen_new.png) no-repeat 93% center; */}
#groupSelectBg .selectBg { padding-left:0; } 
#groupSelectBg .selectBg {border: 1px solid #e7e9ea;border-radius: 40px;transition: 0.2s;padding: 0 15px;}
#groupSelectBg .selectBg:hover {background: #f1f3f5;/* border: 1px solid #477fff; */}
.setting2Bg {width: 100%;position: relative;height: 45px;margin-bottom: 10px;}
.selectBg {min-width: 60px;width: auto;float: left;position: relative;height: 100%;box-sizing:border-box;cursor: pointer;border-radius: 5px;}
.selectBg .selectTitle {display: block;height: 100%;font-size: 14px;width: auto;padding: 0 0 0 10px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.selectBg:hover h3.selectTitle .txt {color: #477fff;}
.selectBg .selectTitle .txt {line-height: 45px;}
.selectBg .selectTitle:after {content: '';display: inline-block;width: 20px;margin-left: 10px;height: 40px;border-radius: 50%;float: right;background: url(../images/side_area/bt_open.png) no-repeat 0px 10px;}
.sendMmsPage .settingBg .selectBg:hover h3 {color: #477fff;transition: 0.2s;}

/* ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â·Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â£Ãƒâ€šÃ‚Â¹ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â´ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ ÃƒÆ’Ã‚Â¬Ãƒâ€¹Ã¢â‚¬Â Ãƒâ€¹Ã…â€œÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â­ 2019.02.14 */
.imgTen h2 {font-weight: normal;margin: 10px 0;}

#groupFileUpload .grp.toTheRight {transition:0.1s;position: absolute;left: 340px;top: 100px;z-index: 1000;height: auto;padding-right: 0;box-sizing: border-box;width: 50%;float: left;z-index: 1000;padding-left: 20px;}
#grupSetting_appr_yn {margin-bottom: 0px;}
#grupSetting_img_extendYn {bottom: 25px;left: 0;height: 35px;}
#grupSetting_img_extendYn {/* display:none */}

.mngGrpModal .permission:after {
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 2px;
    background: #b4c3cc url('../images/contents/checked_fff.png') no-repeat 1px center;
    background-size: 21px;
    position: absolute;
    left: 6px;
    top: 7px;
    z-index: 1;
}
.createNewGroup a.permission.on {
    font-weight: bold;
}
.createNewGroup a.permission:last-child {margin-bottom: 10px !important;}

/* ÃƒÆ’Ã‚Â­Ãƒâ€¹Ã…â€œÃƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‹Å“Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚ÂÃƒâ€šÃ‚Â¼ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã…â€œÃƒâ€šÃ‚Â±ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¡Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â°ÃƒÆ’Ã‚Â¬Ãƒâ€ Ã¢â‚¬â„¢Ãƒâ€šÃ‚Â ÃƒÆ’Ã‚Â¬Ãƒâ€šÃ‚Â¶ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â°ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢Ãƒâ€šÃ‚Â­ */
p.color_select { margin-right: 17px; padding:0 !important; width:20px; height:20px; border-radius:50%; }
p.color_select a { position:absolute; border-radius: 50%; margin: 0; padding:0;left:0;top:0; width:100%; height:100%;}
p.color_select img { display: none; padding:0; margin:0;}
p.color_select a:hover, p.color_select.on a {width: 27px !important;height: 27px !important;left:-4px;top:-4px;transition:0.05s;}
p.color_select.on { height: 20px;}
p.color_select.on a {background-image: url(../images/contents/checked_fff.png) !important;background-position: 2px 3px;background-size: 23px;background-repeat: no-repeat;}



/* ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â©ÃƒÂ¢Ã¢â€šÂ¬Ã‚ÂÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â­ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦Ãƒâ€šÃ‚ÂÃƒÆ’Ã‚Â¬Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¤ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã‚Â Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã¢â‚¬ÂÃƒâ€šÃ‚ÂÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â¦Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ */
textarea.memoTextarea {padding: 15px;box-sizing: border-box;background: #f3f3f3;height: 200px !important;}

/* ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚Â Ãƒâ€šÃ‚Â¥ ÃƒÆ’Ã‚Â¬ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾Ãƒâ€šÃ‚Â¸ÃƒÆ’Ã‚Â­Ãƒâ€¦Ã¢â‚¬â„¢ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¦ ÃƒÆ’Ã‚Â«Ãƒâ€šÃ‚ÂªÃƒâ€šÃ‚Â¨ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â¬ */
.calSetting.remodal {width: 550px;text-align: left;}
.calSetting.remodal.editMode .delBtn {display: block; }
.calSetting.remodal.editMode .delBtn:hover { background: #ff6363; color:#fff; }
.calSetting.remodal.editMode p.descript { display:none; }
.calSetting.remodal .delBtn { display:none; margin: 15px auto 15px !important;text-align: center;width: 60px;background: #f1f3f5;font-weight: bold;color: #1c2c4a; }
.calSetting.remodal .modal-topBtn { border-radius: 5px; padding:0 20px; line-height: 33px; margin-top:7px; }
.calSetting.remodal label { padding-left:15%; display:block; margin-bottom: 15px; opacity:0.7}
.calSetting.remodal .grp { position:relative;border-bottom: 1px solid #f1f3f5;padding-bottom: 25px; }
.calSetting.remodal input.newCalName {width: 85%;margin-left:15%;border: 1px solid #e1e4e6;line-height: 40px;padding: 0 12px;box-sizing: border-box;font-size: 13px;font-weight:bold;}
.calSetting.remodal input.newCalName.failed { border: 1px solid #ff3e43;background: #fff5f5;color:#ff3e43 }
.calSetting.remodal input.newCalName.failed:placeholder { color:#ff3e43 }
.calSetting.remodal input.newCalName:focus, .calSetting.remodal input.newCalName:hover {border: 1px solid #477fff;}
.calSetting.remodal .grp h2 { position:absolute; top:0; left:0; font-weight:normal; opacity:0.7 }
.calSetting.remodal .grp p {background: url(../images/contents/checked_fff.png) no-repeat center center;width: 35px;height: 35px;position: absolute;top: 31px;left: 7px;font-size: 0;background-color: #35393c;border-radius: 50%;}
.calSetting.remodal .grp p:hover, .calSetting.remodal .grp p.on { cursor: pointer; box-shadow: 0 2px 5px rgba(0,0,0,0.2); transition:0.1s; }
.calSetting.remodal .grp2 { height:0; overflow:hidden; transition:0.1s; }
.calSetting.remodal .grp2.on { height:132px;  }
.calSetting.remodal h3 { display:block; margin:0 0 20px;padding-top:20px;font-weight:normal; opacity:0.7}
.calSetting.remodal p.color_select { width:30px; height:30px; }
.calSetting.remodal p.color_select a:hover, .calSetting.remodal p.color_select.on a { height: 35px !important; width: 35px !important;}
.calSetting.remodal p.color_select.on a {background-position: 5px 6px; background-size: 25px;}
.calSetting.remodal p.color_select {margin:0 8px; }
.calSetting.remodal .color_group {text-align: center;background: #f1f3f5;padding: 25px 0;margin-left:0;}
.calSetting.remodal p.descript { text-align:center;margin: 30px 0 15px; }

 /* noticeModal */
 .noticeModal {border-radius: 0;width: 90% !important;height: 100%;margin: 0 !important;max-width: 1000px !important;left: 0;float: right;box-sizing: border-box;right: 0;top: 0;}
.noticeModal .remodal-close {left: -60px;z-index: 1000000;top: 14px;/* background-color: #dcdcdc; */}
.noticeModal .remodal-content11.bg_gray.over_hidden {height: 100%;margin-top: -70px;padding: 70px 0 70px 0;/* max-width: 1000px; */box-sizing: border-box;border-radius: 0;background: #f1f3f5;}
.noticeModal.remodal .left_titleBox {background: #fff !important;}
.noticeModal .remodal-content11 .modal-scroll {max-height: 100% !important;padding-right: 35px;padding-left: 35px;height: 100% !important;padding-top: 0;padding-bottom: 0;}
.noticeModal .pagination.newPagination {bottom: 0 !important;margin-bottom: 0 !important;margin: 0;margin-top: 15px;margin-left: 30px;}
.noticeModal .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {background-color: #565656;}
.noticeModal .regisNewsfeedWrap li ol > li {height: 65px;}
.noticeModal .regisNewsfeedWrap li .groupping1 h2 {font-size: 14px;color: #434343;margin-top: 3px;padding-bottom: 11px;}
.noticeModal .regisNewsfeedWrap > ol > li a .numbering {width: 30px;height: 30px;border: 1px solid #eaeaea;background: #fff;text-align: center;line-height: 30px;border-radius: 50%;margin-right: 20px;}
.noticeModal .noticeWrap {background-color: #ffffff;height: auto !important;padding: 0;border: 1px solid #e8e8e8;min-height: 500px;}
.noticeModal .regisNewsfeedWrap > ol > li > ol > li.on {height: auto;}
.noticeModal .notice_detail {height: auto;}
.noticeModal .mCustomScrollbar {/* height: auto !important; */padding: 0 25px 0 25px;height: 100% !important;max-height: auto;border-left: 0;}
.noticeModal .regisNewsfeedWrap .notice_detail {padding: 0;}
.noticeModal .notice_detail {line-height: 20px;font-size: 1.2em;padding: 20px 0 !important;text-align: left;}
.regisNewsfeedWrap > ol > li > ol > li > a:hover .numbering {/* box-shadow: 0 2px 2px rgba(0,0,0,0.2); */background: #e0e0e0;color: #5d5d5d;}
.regisNewsfeedWrap > ol > li > ol > li.on > a .numbering {background: #477fff;border: 1px solid #477fff;font-weight: bold;color: #fff;}
.noticeModal .remodal-close:hover {/* background-color: #cecece; */}
a.bt_expand.p_noticeDetailOpen {}
.noticeModal a.bt_expand {display: none !important;}
.noticeModal .noticeWrap.withBtns .notice_detail.p_noticeDesc {padding-top: 52px !important;}
.noticeModal .noticeWrap.withBtns .notice_detail_inn {height: auto !important;margin-top: 25px;margin-bottom: 25px;}
.noticeModal a#modalNotiListRegBtn {top: 13px;}
.noticeModal .confrmTitle {top: 64px;border-bottom: 1px solid #ececec;/* box-shadow: 0 2px 2px rgba(0,0,0,0.1); */}
.noticeModal .contEye {padding: 0px 14px 0 38px;background: #efefef url(../images/contents/im_eye_on.png) no-repeat 12px 9px;border-radius: 5px;line-height: 32px;font-weight: bold;color: #5d5d5d;border: 1px solid #efefef;/* box-shadow: 0 2px 2px rgba(0,0,0,0.1); */}
.noticeModal .regisNewsfeedWrap > ol > li > ol > li {border-bottom: 0 none;}
.noticeModal .regisNewsfeedWrap .confirmBoxWrap.dpblock, .noticeModal .confirmBox {padding-top: 55px;box-sizing: border-box;height: 100%;overflow: hidden;}
.noticeModal .confirmBox ul li {position: relative;margin-bottom: 0;padding-bottom: 10px;padding-top: 10px;border-bottom: 1px solid #e8e8e8;}
.noticeModal .regisNewsfeedWrap .confirmListBox .photoBox {position: absolute;top: 8px;left: 0;}
.noticeModal .regisNewsfeedWrap .info_person {width: 100%;box-sizing: border-box;padding-left: 50px;background: url(../images/side_area/bt_open.png) no-repeat right 5px;padding: 5px 20px 5px 45px;box-sizing: border-box;border: none;}
.noticeModal .regisNewsfeedWrap .info_person .per_name {font-size: 13px;font-weight: bold;}
.regisNewsfeedWrap  .info_person{padding: 8px 20px 7px 0;background: url(../images/contents/ic_listOpen.png) no-repeat right 16px;}
.regisNewsfeedWrap .confirmListBox li.on .info_person{background: url(../images/side_area/bt_close.png) no-repeat right 4px;}
.noticeModal .regisNewsfeedWrap .close {width: 30px;height: 30px;background: #d6d6d6 url(../images/contents/ic_close.png) no-repeat center center;border-radius: 50%;}
.noticeModal .redBox span {color: #ff6969;}
.noticeModal .grayBox, .noticeModal .redBox {background: #56a0fc;padding: 10px 20px;}
.noticeModal .grayBox span, .noticeModal .redBox span {text-transform: uppercase;font-size: 14px;font-family: 'noto sans', sans-serif;}
.noticeModal .redBox {background: #8a8a8a;}
.noticeModal .confrmTitle .modal-topBtn.p_noticeDel {background: #e8e8e8 !important;color: #434343;}
.noticeModal .confrmTitle .modal-topBtn.p_noticeDel:hover {background: #ff3737 !important;color: #fff;}
.noticeModal .confirmListBox.p_unreadListBox {background: #fbfbfb;}
.noticeModal .regisNewsfeedWrap .confirmBoxWrap.dpblock {padding-top: 0;/* max-height: 500px; */}
.noticeModal .regisNewsfeedWrap .confirmBoxWrap {height: auto !important;}
.regisNewsfeedWrap .confirmBoxWrap .confirmListBox {height: auto !important;}
.noticeModal .regisNewsfeedWrap .confirmBox {min-height: 500px !important;}
.noticeModal .confirmBox ul li:hover {cursor: pointer;}
.noticeModal .regisNewsfeedWrap .confirmListBox li.on .info_person,.noticeModal  .regisNewsfeedWrap .confirmListBox li .info_person:hover {margin-left: 0;}
.noticeModal .regisNewsfeedWrap .contEye:hover,  .noticeModal .contEye.on {color: #434343;background: #e2e2e2 url(../images/contents/im_eye_on.png) no-repeat 12px 9px;border: 1px solid #e2e2e2;}
.noticeModal .pagination > .bg {position: absolute;width: 100%;height: 70px;background: #fff;left: 0 !important;bottom: 0 !important;box-shadow: 0 -2px 9px rgba(0,0,0,0.05);}
.noticeModal .pagination.newPagination > div {position: absolute;left: 35px;bottom: 15px; }
input#modal_noti_title {background: #fff !important;line-height: 50px !important;height: 50px;padding: 0 20px !important;font-weight: bold;box-sizing: border-box;font-size: 14px;border: 1px solid #e8e8e8 !important;}
.noticeModal .editArea.mt20 {margin-top: 0 !important;width: 100%;height: 100% !important;}
textarea#modal_noti_contents {width: 100% !important;max-width: 100%;height: 100% !important;margin: 0 !important;max-height: 100% !important;padding: 10px 20px 20px;border: 1px solid #e8e8e8;box-sizing: border-box;border-top: 0 none;}
.noticeModal .titleRegi {border: 0 none;}
.noticeModal .over_hidden.mt20 {position: absolute;width: 100%;bottom: 0;left: 0;box-sizing: border-box;margin-top: 0 !important;}
.remodal.noticeModal.addNewsfeed {position: relative;}
.addNewsfeed .remodal-content {position: absolute;width: 100%;box-sizing: border-box;height: 100%;top: 0;padding-top: 97px !important;padding-bottom: 130px !important;}
.addNewsfeed .left_titleBox {position: absolute;top: 0;left: 0;padding: 0 20px;width: 100%;line-height: 61px;z-index: 100;}
.addNewsfeed .remodal-content .pt15 {padding: 0 !important;height: 100%;}
.addNewsfeed .selectboxWrap.selectboxWrap02 {position: absolute;right: 35px;line-height: 35px;box-sizing: border-box;height: 35px;}
.addNewsfeed .grp {padding-left: 35px;margin-bottom: 30px;}
.noticeModal .pagination.newPagination a {width: 35px;height: 35px;line-height: 35px;background: #f3f3f3;margin: 0 1px;}
.noticeModal .pagination.newPagination a.active {color: #ffffff !important;background-color: #7e69ff;}
.noticeModal .pagination.newPagination a:hover {background: #e0e0e0;}
.noticeModal .pagination.newPagination a:hover {color: #585858 !important;background: #e2e2e2;}
.noticeModal .pagination.newPagination a.active:hover {color: #ffffff !important;background-color: #7e69ff;cursor: default;}
.noticeModal.addNewsfeed .grp label {line-height: 35px;}
.noticeModal.addNewsfeed .grp label {line-height: 35px;}
.noticeModal #modal_noti_datepicker {line-height: 35px;height: 35px;box-sizing: border-box;color: #434343;padding: 0 20px;width: 120px !important;}
.noticeModal #modal_noti_datepicker:hover {cursor: pointer;border: 1px solid #cacaca;background: #f3f3f3;font-weight: bold;}
.noticeModal select#modal_noti_grup {line-height: 35px !important;height: 35px !important;box-sizing: border-box;}
.noticeModal .selectboxWrap.selectboxWrap02 .allowImg {height: 35px;}
.noticeModal.remodal .remodal-content .selectboxWrap label {font-size: 13px;}
a#modalNotiRegBtn {margin-right: 15px;border-radius: 4px;height: 40px;box-sizing: border-box;font-size: 14px;margin-top: 9px;line-height: 40px;padding: 0 20px;font-weight: bold;}
.noticeModal p#modal_noti_share_area {display: inline-block;float: right !important;position: static;margin-right: 45px !important;margin-top:0 !important;}
.noticeModal.addNewsfeed .grp {width: auto;}
.noticeModal.addNewsfeed .selectboxWrap.selectboxWrap02 {position: relative;line-height: 35px;box-sizing: border-box;height: 35px;min-width: 100px !important;display: inline-block;max-width: 300px;width: auto !important;padding-right: 30px;padding-left: 10px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.noticeModal.remodal .remodal-content .selectboxWrap label {position: static;color: #434343 !important;/* width: 100%; */text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.noticeModal select#modal_noti_grup {line-height: 35px !important;height: 35px !important;box-sizing: border-box;position: absolute;width: auto;top: 0;right: 0;}
.noticeModal p#modal_noti_share_area label {padding-top: 7px;}
.noticeModal div#cke_modal_noti_contents {width: 100% !important;box-sizing: border-box;border: 1px solid #e8e8e8;border-top: 0 none;height: 100% !important;max-height: 100% !important;}
.noticeModal .cke_inner {height: 100%;position: relative;}
.cke_wysiwyg_frame, .cke_wysiwyg_div {height: 100% !important;max-height: 100% !important;}
.noticeModal .cke_bottom {/* bottom: 0; */display: none;}
.noticeModal.addNewsfeed.noticeRegi0 .cke_contents {min-height: 100%;height: 100% !important;box-sizing: border-box;padding-top: 75px;}
.noticeModal.noticeRegi0 .cke_top {width: 100%;box-sizing: border-box;position: absolute;top: 0;left: 0;}
.noticeModal .confrmTitle {background: #fff;border: 1px solid #ececec;}
.noticeModal .confirmBox.left { background: #fbfbfb; }
.msgModal .remodal-close {top: 10px;right: 15px;}
.msgModal.remodal {width: 430px;min-width: auto !important;}
.msgModal.remodal .remodal-content03 {line-height: 28px;font-size: 13px;border: 0;margin-bottom: 0;border-radius: 0 0 15px 15px;background: #f1f3f5;padding: 0;}


/* 
#cke_modal_noti_contents.onlyUser #cke_1_contents { padding-top:0 !important }
#cke_modal_noti_contents.onlyUser #cke_1_top { display: none !important;}
 */
.noticeModal .regisNewsfeedWrap .confirmBoxWrap .confirmBox {max-height: 500px !important;}
.regisNewsfeedWrap .confirmBoxWrap .confirmListBox {height: 398px !important;}
.addNewsfeed .remodal-content {padding-top: 150px !important;padding-bottom: 90px !important;}
.noticeModal .over_hidden.mt20 {top: 90px;z-index: 1000;display: block;height: 36px;}



.shareModal.remodal {max-width: 900px;width:100%;height: 100%;right: 0;left: auto;float: right;border-radius: 0;margin-bottom: 0;}
.shareModal button.remodal-close {right: auto;top: 10px;left: -60px;}
.shareModal.remodal .remodal-content02 {padding: 0;height: 100%;position: absolute;width: 100%;top: 0;padding-top: 119px;overflow: hidden;box-sizing: border-box;}
.second_title {z-index:10000;height: 55px;line-height: 55px;background: #fff;position: absolute;width: 100%;top: 62px;}
.second_title a {float: left;text-align: center;width: 50%;font-size: 14px;font-weight: bold;border-bottom: 2px solid #f1f3f5;}
.second_title a:hover { border-bottom:2px solid #477fff }
.second_title a.on {border-bottom: 2px solid #477fff; color:#477fff}
.second_title a.on span { color:#477fff }
.teamG > .clearFix:hover  {cursor: pointer;}
li.teamG.on ul, li.teamG.on {height:auto;display: block;margin-bottom: 10px;}
li.teamG {height: 50px;line-height: 45px;border-bottom: 1px solid #f3f3f3 !important;padding: 0;border-bottom: 0;}
li.teamG ul {margin: 0 0 0 38px;height:0;display: none;}
ul#modalSharedGroupUl {margin: 0 30px;}
.shareModal .modal-scroll {padding-right: 0;height: 100% !important;min-height: 200px !important;}
.teamG .teamName {font-weight: bold;font-size: 14px;width: 510px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;box-sizing: border-box;opacity: 0.7;padding: 0 0 0 40px;line-height: 50px;background: none;}
.teamG:hover .teamName { opacity:1; }
.shareGroup {padding: 0 0 20px;}
.teamG.on .teamName {opacity: 1;}
.teamG > .clearFix {background: url(../images/side_area/bt_open.png) no-repeat 93% center;height: 50px;}
.teamG.on > .clearFix {background: url(../images/side_area/bt_close.png) no-repeat 93% center;}
.perG .perPhoto {border-radius: 50%;border: 1px solid #f3f3f3;width: 32px;height: 32px;}
.shareGroup li {border-bottom: 0 none;line-height: 30px;}
.teamG p.fr.check.mt07 {margin-top: 14px !important;}
.perG .perName {font-weight: bold;}
ul.perGUl li.perG:first-child {padding-top: 6px;}
.perG .perPhoto img {width: 32px !important;}
.shareModal input[type='checkbox']:not(old) + label {width: 17px !important;height:17px !important;background: #ececec url(../images/contents/checked_gray.png) no-repeat -2px -2px;border: 2px solid #ececec;line-height: 15px;padding: 0;border-radius: 4px;background-size: 23px;}
.shareModal input[type='checkbox']:not(old) + label:hover { background-color: #e0e0e0;border: 2px solid #dedede;}
.shareModal input[type='checkbox']:not(old):checked + label {background: #16cc9b url(../images/contents/checked_fff.png) no-repeat -2px -2px;border: 2px solid #16cc9b;}

.mainConfirm .remodal-close { display:none; }
.mainConfirm .modal-scroll { padding-right:0; }
.grpConfirm .left_titleBox {border: 0 none;text-align: center;width: 100%;height: auto;display: block;padding: 20px 0 10px 0;box-shadow: none;}
.grpConfirm .left_titleBox h1 {width: 100%;}
.mainConfirm {width: 500px;}
.mainConfirm h2 {font-size: 19px;height: 65px;line-height: 80px;border-top: 1px solid #f1f3f5;border-top: 3px solid #ff5454;}
.grpConfirm.signUp h2 { border-top:3px solid #477fff; height: 80px; }
.grpConfirm .placeNm {font-size: 18px;padding: 0;text-align: left;height: 30px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;box-sizing: border-box;padding-right: 30px;}
.grpConfirm p.cl {position:absolute; right:40px; top:43px; width: 15px;background: red;height: 15px;font-size: 0;border-radius: 50%;}
.grpConfirm p.descript {font-size: 12px;font-weight: bold;line-height: 20px;min-height: 25px;margin-bottom: 10px;}
.grpConfirm .img {display:block;background:#f1f3f5 url(../images/footer/g11.jpg) no-repeat center center;background-image: url(../images/footer/g11.jpg);background-size:cover;width: 140px;height: 90px;border-radius:5px;box-shadow: 0 5px 15px rgba(0,0,0,0.1);font-size: 0;position: absolute;left: 45px;}
.remodal.mainConfirm .remodal-content {padding: 0;}
.grpConfirm .main {padding: 40px 40px 20px;min-height: 110px;padding-left: 215px;position: relative;text-align: left;background: #f1f3f5;border-radius: 5px 5px 0 0;/* border-top: 5px solid red; */}
.mainConfirm .btns a {height: 70px;line-height: 70px;font-weight:bold; font-size:14px;width: 50%;display: inline-block;float: left;border: 0;margin-left: 0;border-radius: 0;padding: 0 !important;border-radius: 0 0 5px 0;}
.mainConfirm .btns a:hover {color: #ff5454;background:#f1f3f5;}
.mainConfirm .btns a#yes:hover { color:#477fff; }
.grpConfirm p.warning {margin-bottom: 27px;font-weight: bold;color: #999fad;}
.mainConfirm .btns {border-top: 1px solid #f1f3f5;}
.mainConfirm .btns a:first-child {border-radius: 0 0 0 5px;}
.grpConfirm dl dt {/* float: left; */display: inline-block;font-weight:bold;}
.grpConfirm dl dd {display: inline-block;font-weight: bold;color: #477fff;margin-right: 20px;margin-left: 7px;}

.grpConfirm.step3 h2, .grpConfirm.waiting h2 { border-top: 3px solid #ffa70e; }
.grpConfirm.step3 .warning span {padding-left: 10px;}


.grpConfirm.step2 .img {background-image: url(../images/footer/g6.jpg);background-size:cover;}
.grpConfirm.step3 .img {background-image: url(../images/footer/g10.jpg);background-size:cover;}
.grpConfirm.signUp .img {background-image: url(../images/footer/g8.jpg);background-size:cover;}
.grpConfirm.waiting .img {background-image:url(../images/footer/g3.jpg);}





/* 멤버 모달 */
.mem.mainConfirm {width: 330px;min-width: auto !important;transition: 0.2s;}
.mem.mainConfirm img.userImg {width: 100px;height: 100px;border-radius: 50%;/* box-shadow: 0 5px 10px rgba(0,0,0,0.15); */border: 3px solid #ebecef;}
.mem.mainConfirm img.userImg.king {border: 3px solid #ffbf31 !important;}
.mem.mainConfirm img.userImg.manager {border: 3px solid #b4bfcc;}
.mem.grpConfirm .main {padding-left: 40px;padding-bottom:30px;text-align:center;transition: 0.2s;background: #fff;border-top: 70px solid #ffffff;height: 349px;box-sizing: border-box;}
.mem.grpConfirm .main.edit:hover  {border-top: 70px solid #e3e8ec;}
.mem.grpConfirm .main.edit:hover .pos {font-size: 14px;font-weight: bold;width: 100px;line-height: 30px;cursor: pointer;margin-bottom: 10px;display: inline-block;left: auto;position: absolute;text-align: right;top: -48px;right: 20px;background: url(../images/side_area/bt_open.png) no-repeat center right;padding-right: 29px;}
.mem.grpConfirm .main .pos {position: absolute;top: -40px;left: 50%;margin-left: -50px;width: 100px;text-align: center;font-weight: bold;font-size: 16px;}
.mem.grpConfirm .main .pos.manager { margin-left:-30px }
.mem.grpConfirm .main .pos.king { margin-left:-40px }
.mem.grpConfirm .main.edit .pos:hover { color:#477fff; }
.mem.grpConfirm .main:before {transition:0.2s;content: '';display: block;width: 100%;height: 1px;background: #f1f3f5;position: absolute;left: 0;top: 0px;}
.mem.grpConfirm p.pos.manager:before {background: #fff url(../images/contents/ic_manager.png) no-repeat 7px 7px;content: '';display: block;background-size: 20px;position: absolute;left: 50%;margin-left: -75px;top: -11px;border-radius: 50%;width: 35px;height: 35px;right: auto;}
.mem.grpConfirm .main.edit:hover p.pos.manager:before {left: -157px;margin-left:0;top: -3px;right: auto;}
.mem.grpConfirm p.pos.king:before {background: #fff url(../images/contents/ic_king.png) no-repeat 7px 7px;content: '';display: block;background-size: 20px;position: absolute;left: 50%;margin-left: -65px;top: -11px;border-radius: 50%;width: 35px;height: 35px;right: auto;}
.mem.grpConfirm main.edit:hover p.pos.king:before {left: -157px;margin-left:0;top: -3px;right: auto;}
.mem.grpConfirm .userName {font-size: 18px;padding-bottom: 13px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.mem.grpConfirm .tel, .mem.grpConfirm .email {font-weight: bold;font-size: 13px;color: #999fad;margin-bottom: 7px;text-overflow: ellipsis;white-space: nowrap;line-height: 19px;overflow: hidden;}
.mem.grpConfirm .btns.b3 a  { width:33.33333%; }
.mainConfirm .btns.b3 {border-top: 1px solid #f1f3f5;}
.mem.grpConfirm .btns #delMem:hover { background:#ff5454; color:#fff; }
.mem.grpConfirm .btns #changeKing:hover { background:#477fff; color:#fff; }
.mainConfirm ul.dropdown {width: 110px;text-align: left;right: 10px;top: -10px;left: auto;}
.mainConfirm ul.dropdown li {position: relative;}
.mainConfirm .btns.secondBtns { display:none; }
.mem.mainConfirm .btns.confirmBtns {display: none;}

/* 멤버들이 볼시 확인 닫기 버튼 보이기*/
.mem.mainConfirm.readOnly .btns, .mem.mainConfirm.readOnly .btns.secondBtns {display: none;}
.mem.mainConfirm.readOnly .btns.confirmBtns {display: block;}

/* 멤버 멀티 탈퇴 */
.mem.mainConfirm.multiple {width: 430px;}
.mem.mainConfirm.multiple .btns { display:block !important; }
.mem.mainConfirm.multiple img {width: 37px;height: 37px;border: 1px solid #f1f3f5;margin-top: 8px;margin-right: 15px;}
.mem.mainConfirm.multiple ul li * {display: inline-block;float: left;}
.mem.mainConfirm.multiple ul li:hover { background:#fff; }
.mem.mainConfirm.multiple .pos { float:right; }
.mem.mainConfirm.multiple .num {margin-right:10px;width: 30px;margin-left: -5px;}
.mem.mainConfirm.multiple  p.pos.manager:before, .mem.mainConfirm.multiple  p.pos.king:before { display:none; }
.mem.multiple h1 {font-size: 19px;height: 65px;padding: 0;line-height: 80px;border-top: 3px solid #ff5454;}
.multiple.mem li p {line-height: 55px;height: 55px; font-weight:bold; }
.mem.mainConfirm.multiple ul li {transition:0.2s;position:relative;width: 100%;height: 55px;border-bottom: 1px solid #e6e9ea;padding: 0 20px;box-sizing: border-box;overflow: hidden;}
.mem.mainConfirm h2 { display:none; }
.mem.multiple ul {background: #f1f3f5;border-radius: 5px 5px 0 0;max-height: 250px;overflow-y: auto;}
.multiple.mem li p.userName {font-size:13px;width: 200px;text-align: left;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}

/* 멤버 단일 탈퇴 */
.mainConfirm.mem.delOneMem .pos {position: static;font-size: 14px;width: 100%;text-align: center;margin-left: 0;margin-bottom: 10px;}
.mainConfirm.mem.delOneMem .main {border-top: 0;background: #f1f3f5;height:auto;}
.mainConfirm.mem.delOneMem .main:hover { border-top:0; }
.mainConfirm.mem.delOneMem .main:before {display: none;}
.mainConfirm.mem.delOneMem h2 { display:block; font-size:17px; line-height:65px; }
.mem.mainConfirm.delOneMem  .main:hover .pos {line-height: 20px; background:none; position: static;font-size: 14px;width: 100%;text-align: center;margin-left: 0;margin-bottom: 10px;}
.mem.mainConfirm.delOneMem  .main .pos {line-height: 20px; background:none; position: static;font-size: 14px;width: 100%;text-align: center;margin-left: 0;margin-bottom: 10px;}
.mem.mainConfirm.delOneMem  .main .pos:before { display:none; }
.mem.mainConfirm.delOneMem  .main .pos:hover { cursor:default; color: #000; }
.mainConfirm.delOneMem .btns { display:none; }
.mainConfirm.delOneMem .secondBtns.btns { display:block; }


/* 멤버 관리자 모달 */
.mainConfirm .instruction h2 {display:block;color:#fff;border-top: 0;padding-bottom: 10px;border-bottom:1px solid #868a96;margin-bottom: 25px;margin-top: 5px;}
.mainConfirm .instruction p { color:#fff; font-weight:bold; font-size:12px; line-height:20px; text-align:left; }
.mainConfirm .instruction.on { display:block; }
.mainConfirm .instruction {display:none;cursor:pointer;position: absolute;top: 0;padding: 40px;left: 0;box-sizing: border-box;height: 420px;border-radius: 5px;width: 100%;text-align: center;background: rgba(30, 41, 72, 0.8) url(../images/contents/ic_close_fff.gif) no-repeat center 30px;z-index: 1000000;}
.mainConfirm .instruction:hover {background: rgba(30, 41, 72, 0.85) url(../images/contents/ic_close_fff.gif) no-repeat center 30px;}
.mainConfirm .instruction ol {padding-left: 30px;height: 173px;}
.mainConfirm .instruction ol li {list-style: none;font-size:13px;font-weight:bold;color:#fff;text-align:left;position: relative;line-height: 24px;margin-bottom: 10px;}
.mainConfirm .instruction ol li span {display:inline-block;border-radius:50%;width:20px;height:20px;font-weight:bold;background-color:#fff;color:#000;text-align:center;position: absolute;left: -30px;top: 2px;line-height: 20px;}


/* 관리자 변경 */
.mem.mainConfirm.newKing .modal-scroll { overflow:hidden; }
.mem.mainConfirm.newKing {width: 600px;transition: 0.2s;}
.mem.mainConfirm.newKing .main, .mem.mainConfirm.newKing .kingChosen {width: 50%;float: left;border-radius: 0 5px 0 0;box-sizing: border-box;position: relative;transition: 0.2s;border-top: 70px solid #f1f3f5;}
.mem.mainConfirm.newKing .main { border-left: 1px solid #f1f3f5; }
.mem.mainConfirm.newKing .new { border-top: 70px solid #fff !important; border-left:1px solid #f1f3f5 }
.mem.mainConfirm .kingChosen {display: none;}
.mem.mainConfirm.newKing .kingChosen {display: block;border-radius: 5px 0 0 0;}
.mem.mainConfirm.newKing .btns #delMem {display: none !important;}
.mem.mainConfirm img.userImg.ylwBdr {box-shadow: 0 5px 10px rgba(255, 194, 56, 0.5);border: 3px solid #ffbf31;transition:0.3s}
.mem.grpConfirm.newKing .main.edit .pos {font-size: 14px;font-weight: bold;width: 100%;line-height: 30px;cursor: pointer;margin-bottom: 10px;display: inline-block;left: auto;box-sizing: border-box;position: absolute;text-align: right;top: -48px;right: 20px;background: url(../images/side_area/bt_open.png) no-repeat center right;padding-right: 29px;}
.mem.grpConfirm.newKing .edit p.pos.king:before {background: #fff url(../images/contents/ic_king.png) no-repeat 7px 7px;content: '';display: block;background-size: 20px;position: absolute;left: 50%;margin-left: -105px !important;top: -3px !important;border-radius: 50%;width: 35px;height: 35px;right: auto;}
.mem.grpConfirm.newKing .edit p.pos.manager:before {content: '';display: block;background-size: 20px;position: absolute;left:40px !important;margin-left: 0px !important;top: -3px !important;border-radius: 50%;width: 35px;height: 35px;right: auto;}
.mem.grpConfirm.newKing .wrong.main:before {transition:0.2s;background: #ff4949 !important;transition:0.2s;z-index:100000;line-height:70px;font-size:14px;background: #ff4949 !important;top:0;line-height:70px; color:#fff; font-weight:bold;top:-70px !important; left:0; width:100%; height:70px;content:'직급을 변경해주세요'}
.mem.mainConfirm.newKing .main:after {display: block;content: 'NEW';position: absolute;top: 40px;right: 90px;background: #ffbf31;color: #1b2e5a;font-weight: bold;width: 35px;font-size: 10px;height: 35px;line-height: 35px;border-radius: 50%;}
.mem.mainConfirm.newKing .main.kingChosen:after {display: none;}

















