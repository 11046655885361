<template>
    <div id="resident_car_list" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>
        
        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">입주자 차량</h3>
            <a class="schBtn" @click="searchOnOff">검색하기</a>
        </div>

        <!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
        <div class="searchDiv" :class="{ on : is_search_open == true }">
            <div class="schGrp">
                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                <input type="text" id="resident_car_search_text" :value="search_word" placeholder="차량 번호로 검색하세요" @input="search_word = $event.target.value">
                <div class="btns">
                    <!-- 검색 디브 removeClass(on) -->
                    <a href="#" class="closeBtn" @click.prevent="searchOnOff">닫기</a>
                </div>
            </div>
        </div>

        <div class="section_scroll">
            <div class="viewGroup">

                <!-- 상단 -->
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <div class="posRel">
                            <div class="bg">
                            
                                <div class="bigGrp left">
                                    <div class="selectDiv">
                                        <select @change="changeTargetBuilding($event.target.value)">
                                            <option value="TARGET_ALL">전체</option>
                                            <option :value="building.building" :key="index" v-for="(building, index) in buildings">{{ building.building }}동</option>
                                            <!-- <option :value="building.team_id" :key="index" v-for="(building, index) in apartment_building_list">{{ building.team_info.team_name }}</option> -->
                                            <!-- <option value="4739">101동</option>
                                            <option value="4740">102동</option>
                                            <option value="4741">103동</option>
                                            <option value="4744">104동</option>
                                            <option value="4745">105동</option>
                                            <option value="4746">106동</option>
                                            <option value="4742">관리실</option> -->
                                        </select>
                                        <span class="seper vertiSeper"></span>
                                    </div>
                                    <div class="grp">
                                        <label for="" class="from blind">시작일자</label>
                                        <input type="button" id="resident_car_fr_ymd" :value="`${hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(start_date)}`" class="calFrom">
                                    </div>
                                    <span class="seper">에서</span>
                                    <div class="grp">
                                        <label for="" class="to blind">종료일자</label>
                                        <input type="button" id="resident_car_to_ymd" :value="`${hodu_date_to_format_string(end_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(end_date)}`" class="calTo">
                                    </div>
                                    <input type="button" id="btnToday" value="오늘" class="toolbar-btn today input_btn" @click="clickToday">
                                </div>

                                <!-- <div class="rightBtnDiv fr">
                                    <input type="button" value="모두 승인" class="input_btn">
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 리스트 -->
                <div id="hoduhome_div" class="content">
                    <ul class="sortHeader workHeader">
                        <li class="num">No</li>
                        <li class="time">
                            <span>신청 날짜</span>
                        </li>
                        <li class="peep">
                            <span>동&amp;호</span>
                        </li>
                        <li class="carNum">
                            <span>차량번호</span>
                        </li>
                        <li class="carCard">
                            <span>첨부</span>
                        </li>
                        <li class="status pl29">
                            <a class="sort">현황</a>
                            <div class="dropdown">
                                <ul>
                                    <li>
                                        <p>
                                            <input type="checkbox" id="status_check1" value="" class="input_check">
                                                <span>등록요청</span>
                                            <label for="status_check1">
                                            </label>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <input type="checkbox" id="status_check2" value="" class="input_check">
                                                <span>완료</span>
                                            <label for="status_check2">
                                            </label>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <input type="checkbox" id="status_check3" value="" class="input_check">
                                                <span>추가완료</span>
                                            <label for="status_check3">
                                            </label>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <input type="checkbox" id="status_check4" value="" class="input_check">
                                                <span>등록거절</span>
                                            <label for="status_check4">
                                            </label>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>

                    <!-- 데이터가 없을때 on 클래스 추가 -->
                    <div class="noResult" :class="{ on : computedCarListByTargetBuilding.length < 1 }" v-if="computedCarListByTargetBuilding.length < 1">
                        <p>검색된 입주자 차량이 존재하지 않습니다.</p>
                    </div>

                    <div id="hoduhome_list_scroll">
                        
                        <ul class="hoduhomeUl">

                            <li :key="car.car_id" v-for="(car, index) in computedCarListByTargetBuilding" @click.prevent="showHomeResidentCarRequestModalInfo(car)">
                                <span class="new" v-if="car.car_info.is_new == true">new</span>
                                <p class="num">{{ index + 1}}</p>
                                <p class="time">{{ getTimeText(car) }}</p>
                                <p class="peep">{{ car.resident_info.building }}동 {{ car.resident_info.unit }}호</p>
                                <p class="carNum">{{ car.car_info.car_number }}</p>
                                <p class="carCard">
                                    <span class="carCard_icon" :class="{ non_icon : car.car_info.images == null || car.car_info.images.length < 1 }">등록차량이미지 여부 icon</span>
                                </p>

                                <!-- 상태값에 따라서 on 추가 -->
                                <p class="status">
                                    <span class="Resi_request" :class="{ on : car.car_info.state == 'REQUEST' }">등록요청</span>
                                    <span class="Resi_complete" :class="{ on : car.car_info.state == 'APPROVE' && car.car_info.is_contract == false }">완료</span>
                                    <span class="Resi_Addcomplete" :class="{ on : car.car_info.state == 'APPROVE' && car.car_info.is_contract == true }">완료</span>
                                    <span class="Resi_reject" :class="{ on : car.car_info.state == 'REJECT' }">거절</span>
                                </p>

                            </li>

                        </ul>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import moment from 'moment';

import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class ResidentCarList extends Mixins(VueHoduCommon) {

    /**
     * 선택된 동의 리스트만 반환 
     */
    get computedCarListByTargetBuilding() : any[] {
        let final_list : any[] = JSON.parse(JSON.stringify(this.car_list));

        final_list = final_list.filter(item => new RegExp(escape(this.search_word)).test(escape(item.car_info.car_number)) == true);

        if( this.target_building == 'TARGET_ALL') {
            return final_list;
        }

        final_list = final_list.filter(item => item.resident_info.building == this.target_building);
        
        return final_list;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeResidentCarRequestModalInfo ?: (params : hodu_home_modal_info.HomeResidentCarRequestModalInfo) => void;

    target_building : string = "TARGET_ALL";

    start_date : Date = new Date();
    end_date : Date = new Date();

    apartment_building_list : any[] = [];
    car_list : any[] = [];

    is_search_open : boolean = false;
    search_word    : string = "";

    buildings : any[] = [];

    mounted() : void {
        this.start_date = moment().add('month', -1).toDate(); 

        // $('.seeDets').click(function(){
        //     $('.bg4Modal').fadeIn(200); 
        //     $('.modal').show();
        //     return false;
        // });

        // $('.modal .btns input.close').click(function(){
        //     $('.bg4Modal').hide();
        //     $('.modal').hide()
        // });

        const vue = this;

        const datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
                inst.input.val(mnt.format('YYYY.MM.DD') + ' ' + vue.getDayOfWeekByDate(mnt.toDate()));
                
                // 시작일 변경
                if( id == 'resident_car_fr_ymd' ) {
                    vue.start_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.end_date = moment(vue.start_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
                    }
                } 

                // 종료일 변경
                else if( id == 'resident_car_to_ymd' ) {
                    vue.end_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.start_date = moment(vue.end_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                    }
                }

                vue.end_date = moment(vue.end_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

                Promise.all([vue.getBuildings(), vue.getHoduHomeInfo(), vue.getCarList()]);
            },
        };

        // @ts-ignore
        $('#resident_car_fr_ymd').datepicker(datepicker_option);

        // @ts-ignore
        $('#resident_car_to_ymd').datepicker(datepicker_option);

        Promise.all([this.getBuildings(), this.getHoduHomeInfo(), this.getCarList()]);
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hoduhome_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });
    }

    /**
     * 동 조회
     */
    async getBuildings() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/building`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.buildings ) {
                throw new Error("동 조회 중 오류 발생");
            }

            this.buildings.splice(0, this.buildings.length);
            this.buildings = this.buildings.concat(response.data.data.buildings);

        } catch(e) {
            this.hodu_show_dialog("cancel", "동 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 호두홈 정보 조회
     */
    async getHoduHomeInfo() : Promise<void> {
        
        try {
            
            const response = await this.hodu_api_call(`api/v1/home/info/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null, false)

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.home_info ) {
                throw new Error("입주자 차량 조회 중 오류 발생");
            }

            const home_info : any = response.data.data.home_info;

            this.apartment_building_list.splice(0, this.apartment_building_list.length);
            if( home_info.building_info != null ) this.apartment_building_list = this.apartment_building_list.concat(home_info.building_info);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 입주자 차량 리스트 조회
     */
    async getCarList() : Promise<void> {

        try {

            const start = moment(this.start_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format(); 
            const end   = moment(this.end_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).utc().format();

            const response = await this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}?is_resident=true&start_date=${start}&end_date=${end}&is_web=true&include_deleted_car=true`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.car_list ) {
                throw new Error("입주자 차량 조회 중 오류 발생");
            }

            this.car_list.splice(0, this.car_list.length);
            this.car_list = this.car_list.concat(response.data.data.car_list.filter(car => car.car_info.state != 'TEMP'));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 검색창 열기 / 닫기
     */
    searchOnOff() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_word = "";
        }

        // 검색 창 열릴때 검색 창에 포커스 가도록
        else {
            this.$nextTick(() => { $('#resident_car_search_text').focus(); });
        }
    }

    /**
     * 조회 대상 동을 변경
     */
    changeTargetBuilding(value : string) : void {
        this.target_building = value;
    }

    /**
     * 오늘 클릭
     */
    clickToday() : void {
        this.start_date = moment().set('hour', 0)
                                  .set('minute', 0)
                                  .set('second', 0)
                                  .set('millisecond', 0).toDate();

        this.end_date = moment(this.start_date).set('hour', 23)
                                               .set('minute', 59)
                                               .set('second', 59)
                                               .set('millisecond', 999).toDate();

        this.getBuildings();
        this.getHoduHomeInfo();
        this.getCarList();
    }

    /**
     * 시간 텍스트 반환
     */
    getTimeText(car : any) : string {

        const start_date = new Date(new Date(car.start_date));
        const end_date = new Date(car.end_date);
        
        const start = `${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;

        // 포맷 (같은 년월은 표시하지 않는다)
        // let end_format = 'YY.MM.DD';
        // if( start_date.getFullYear() == end_date.getFullYear() ) {
        //     end_format = 'MM.DD';

        //     if( start_date.getMonth() == end_date.getMonth() ) {
        //         end_format = 'DD';

        //         if( start_date.getDate() == end_date.getDate() ) {
        //             end_format = '';
        //         }
        //     }
        // }

        // const end   = `${this.hodu_date_to_format_string(end_date, end_format)} ${this.getDayOfWeekByDate(end_date)}`;
        // const term = `(${this.getDateDiff(start_date, end_date) + 1}일)`
        // const term = `(방문기간 ${this.getDateDiff(start_date, end_date) + 1}일)`

        // return `${start}${end_format == '' ? ' ' : ` ~ ${end} `}${term}`;
        return `${start}`;
    }

    /**
     * 호두홈 입주자 차량 승인 모달
     */
    showHomeResidentCarRequestModalInfo(car) : void {
        
        const copy_car = JSON.parse(JSON.stringify(car));
        
        // try {
        //     copy_car.resident_info.building = this.apartment_building_list.filter(item => item.team_id == copy_car.resident_info.building)[0].team_info.team_name;
        // } catch(e) {
        //     copy_car.resident_info.building = '-';
        // }

        this.doSetHomeResidentCarRequestModalInfo?.({
            show_modal : true,
            resident : { "resident_info" : copy_car.resident_info },
            car : copy_car,
            callback : () => { Promise.all([this.getBuildings(), this.getHoduHomeInfo(), this.getCarList()]); }
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
    	// @ts-ignore
        $("#hoduhome_list_scroll").mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
</script>

<style scoped>
    .selectDiv { display: inline-block; float: left; }
    .bigGrp select { position: static!important; float: left; font-size: 13px; font-weight: 700; line-height: 40px; height: 40px; background-color: #fff; cursor: pointer; padding-right: 25px; padding-left: 10px; appearance: none; -moz-appearance: none; -webkit-appearance: none; }
    .selectDiv .seper.vertiSeper { width: 1px; height: 15px; margin-top: 12px; margin-right: 20px; }
    .settingBg .left .grp { max-width: 130px!important; min-width: 130px!important; margin-right: 10px!important; }
    .settingBg .left .grp input { width: 130px!important; min-width: 130px!important; padding-right: 0!important; margin-right: 0!important; }
    .settingBg .today { margin: 0!important; }
    .rightBtnDiv input.input_btn { margin-top: 11px; margin-right: 25px; }

    #hoduhome_div .sortHeader li { width: 18%; text-align: left; padding-right: 15px; -webkit-box-sizing: border-box; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .section_ce_fix .apt2, .section_ce_fix .num { width: 10% !important; text-align: center !important; font-weight: bold; }

    .hoduhomeUl li { position: relative; -webkit-transition: .2s; transition: .2s; overflow: hidden; width: 100%; line-height: 55px; height: 55px; -webkit-box-sizing: border-box; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; color: #202a39; }
    .hoduhomeUl li:hover { background: #f7f9fb; font-weight: 700; }
    .hoduhomeUl li:hover .carCard_icon { opacity: 1; }
    .seeDets { position: absolute; left: 0; top:0; font-size: 0; width:100%; height: 100%; display: block; }
    .hoduhomeUl > li p { width: 18%; text-align: left; float: left; display: inline-block; padding-right: 15px; -webkit-box-sizing: border-box; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .hoduhomeUl > li p .carCard_icon { font-size: 0; background: url('../../assets/images/contents/ic_img_bk.png') no-repeat center; width: 20px; height: 20px; display: inline-block; vertical-align: middle; opacity: .6; }
    .hoduhomeUl > li p .non_icon { background: none; }
    .hoduhomeUl li .new { position: absolute; display: block; top: 20px; left: 20px; padding: 0 5px; height: 19px; line-height: 14px; color: #fff; font-size: 11px; font-weight: bold; border-radius: 3px; background: #ff6363; border: 1px solid #ff2121; box-sizing: border-box; }
    .hoduhomeUl li .status > span { width: 115px; height: 30px; background: #ffc72f; border: 1px solid #f1b71a; display: inline-block; text-align: center; display: block; border-radius: 30px; line-height: 30px; font-weight: bold; margin-top: 11px; display: none; }
    .hoduhomeUl li .status > span.Resi_request.on { display: block; background: #ffc72f; border: 1px solid #f1b71a; }
    .hoduhomeUl li .status > span.Resi_complete.on { display: block; background: #f1f3f5; border: 1px solid #e7e9ea; }
    .hoduhomeUl li .status > span.Resi_Addcomplete.on { display: block; background: #477fff; border: 1px solid #3b6bd8; color: #fff; }
    .hoduhomeUl li .status > span.Resi_reject.on { display: block; background: #ff6363; border: 1px solid #dc4c4c; color: #fff; }

    .noResult { position: static; padding: 20px 50px; opacity: 1; display: none; }
    .noResult.on { display: block; transition: .2s; top: 70px; z-index: 20000; }
    .noResult p { width: 100%; border-radius: 5px; background: #f1f3f5; line-height: 80px; height: 80px; font-size: 13px; color: #9ea5b3; }
</style>