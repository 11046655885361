<template>
    <div id="group_survey_create" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <a href="#" class="list_back">
                <span class="blind">설문리스트 가기</span>
            </a>
            <!--
                설문 보내기 전까지는 결과보기 클릭 금지
                설문 보낸 후에는 설문편집 클릭 금지
                클릭 금지된 부분에는 클래스 notClickTab 추가하여 색상 조정 및 cursor 조정
            -->
            <div id="TopTab">
                <h2 class="active"><a href="#">설문 편집</a></h2>
                <h2><a href="#">설문 보내기</a></h2>
                <h2><a href="#" class="notClickTab">결과보기</a></h2>
            </div>
            <p class="btn_group">
                <!--
                    설문 편집 저장/미리보기 버튼만 보이기 (수정이 없을 경우 저장버튼 비활성화)
                    설문 보내기 보내기/미리보기 버튼만 보이기 (보내기 클릭시 비활성화)
                -->
                <input type="button" value="보내기" class="input_btn send_input_btn">
                <input type="button" value="저장" class="input_btn">
                <input type="button" value="미리보기" class="input_btn">
            </p>
        </div>

        <div id="group_survey_scroll" class="section_scroll">
            <!-- 설문 편집 -->
            <div class="tab-content SurveyEdit">
                <div class="schedule_box writeSche">
                    <div class="tableDefault tableBorder th-right">
                        <table cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr id="titleTr" class="forRel">
                                    <th>
                                        <label for="" title="제목">일정제목</label>
                                    </th>
                                    <td>
                                        <input type="text" id="s_mainTitle" placeholder="제목을 입력하세요" class="input_text">
                                        <input type="button" class="clr">
                                        <div id="pickColour">
                                            <ul>
                                                <li><a>기본색</a>
                                                    <ul>
                                                        <li><a href="#" class="dc0">연어</a></li>
                                                        <li><a href="#" class="dc1">주황</a></li>
                                                        <li><a href="#" class="dc2">노랑</a></li>
                                                        <li><a href="#" class="dc3">자주</a></li>
                                                        <li><a href="#" class="dc4">청록</a></li>
                                                        <li><a href="#" class="dc5">초록</a></li>
                                                        <li><a href="#" class="dc6">하늘</a></li>
                                                        <li class="on"><a href="#" class="dc7">파랑</a></li>
                                                        <li><a href="#" class="dc8">보라</a></li>
                                                        <li><a href="#" class="dc9">검정</a></li>
                                                    </ul>
                                                </li>
                                                <li><a>파스텔</a>
                                                    <ul>
                                                        <li><a href="#" class="lc0">연한 갈색</a></li>
                                                        <li><a href="#" class="lc1">연한 자주</a></li>
                                                        <li><a href="#" class="lc2">연한 빨강</a></li>
                                                        <li><a href="#" class="lc3">연한 보라</a></li>
                                                        <li><a href="#" class="lc4">연한 주황</a></li>
                                                        <li><a href="#" class="lc5">연한 노랑</a></li>
                                                        <li><a href="#" class="lc6">연한 초록</a></li>
                                                        <li><a href="#" class="lc7">연한 파랑</a></li>
                                                        <li><a href="#" class="lc8">연한 하늘</a></li>
                                                        <li><a href="#" class="lc9">연한 검정</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr id="titleExTr" class="">
                                    <th>
                                        <label for="" title="제목">제목 설명</label>
                                    </th>
                                    <td>
                                        <!-- 높이 자동조절 가능하면 해주세요 최대 3줄이후에는 스크롤 -->
                                        <textarea rows="1" placeholder="(선택 사항) 설명을 입력하세요" maxLength="1200" id="target"> </textarea>
                                    </td>
                                </tr>
                                <tr id="timeTr" class="enabled">
                                    <th>
                                        <label title="시간">시간</label>
                                    </th>
                                    <td class="timeTd">
                                        <div class="inputgroup">
                                            <input type="button" id="s_eventFrYmd" value="2023.01.30 월" class="input_txt fl input_gray radius04 hasDatepicker">
                                            <input type="button" id="s_FrTm" value="오후 12:00" class="input_txt fl input_gray radius04">
                                            <div class="chooseTime from">
                                                <div class="selectboxWrap">
                                                    <label for="s_frAmpm">오후</label>
                                                    <select id="s_frAmpm" title="오전 오후 선택">
                                                        <option value="AM">오전</option>
                                                        <option value="PM">오후</option>
                                                    </select>
                                                </div>
                                                <div class="selectboxWrap">
                                                    <label for="s_frHour">12</label>
                                                    <select id="s_frHour" title="시간선택">
                                                        <option value="12"> 12 </option>
                                                        <option value="01"> 01 </option>
                                                        <option value="02"> 02 </option>
                                                        <option value="03"> 03 </option>
                                                        <option value="04"> 04 </option>
                                                        <option value="05"> 05 </option>
                                                        <option value="06"> 06 </option>
                                                        <option value="07"> 07 </option>
                                                        <option value="08"> 08 </option>
                                                        <option value="09"> 09 </option>
                                                        <option value="10"> 10 </option>
                                                        <option value="11"> 11 </option>
                                                    </select>
                                                </div>
                                                <div class="selectboxWrap">
                                                    <label for="s_frMin">00</label>
                                                    <select id="s_frMin" title="분 선택">
                                                        <option value="00"> 00 </option>
                                                        <option value="10"> 10 </option>
                                                        <option value="20"> 20 </option>
                                                        <option value="30"> 30 </option>
                                                        <option value="40"> 40 </option>
                                                        <option value="50"> 50 </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <span class="inputgroup_txt01 arw">에서</span>
                                            <input type="button" id="s_eventToYmd" value="2023.01.30 월" class="input_txt fl input_gray radius04 hasDatepicker">
                                            <input type="button" id="s_ToTm" value="오후 01:00" class="input_txt fl input_gray radius04">
                                            <div class="chooseTime to">
                                                <div class="selectboxWrap">
                                                    <label for="s_toAmpm">오후</label>
                                                    <select id="s_toAmpm" title="오전 오후 선택">
                                                        <option value="AM">오전</option>
                                                        <option value="PM">오후</option>
                                                    </select>
                                                </div>
                                                <div class="selectboxWrap">
                                                    <label for="s_toHour">01</label>
                                                    <select id="s_toHour" title="시간 선택">
                                                        <option value="12"> 12 </option>
                                                        <option value="01"> 01 </option>
                                                        <option value="02"> 02 </option>
                                                        <option value="03"> 03 </option>
                                                        <option value="04"> 04 </option>
                                                        <option value="05"> 05 </option>
                                                        <option value="06"> 06 </option>
                                                        <option value="07"> 07 </option>
                                                        <option value="08"> 08 </option>
                                                        <option value="09"> 09 </option>
                                                        <option value="10"> 10 </option>
                                                        <option value="11"> 11 </option>
                                                    </select>
                                                </div>
                                                <div class="selectboxWrap">
                                                    <label for="s_toMin">00</label>
                                                    <select id="s_toMin" title="분 선택">
                                                        <option value="00"> 00 </option>
                                                        <option value="10"> 10 </option>
                                                        <option value="20"> 20 </option>
                                                        <option value="30"> 30 </option>
                                                        <option value="40"> 40 </option>
                                                        <option value="50"> 50 </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- 설문편집 페이지 -->
                <div class="surveyViewer">
                    <div class="surveyViewer_head">
                        <!-- page_nav -->
                        <div class="page_nav">
                            <div class="page_nav_div" title="이전">
                                <input type="button" id="btnPrev" class="toolbar-btn bt-prev">
                            </div>
                            <div class="page_count">
                                <ul class="pg_count_ul">
                                    <!-- 선택된 페이지 span태그에 page_on 클래스 추가 -->
                                    <li>
                                        <div class="page_num page_num_selected">
                                            <span class="page_num_text page_on">1</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="page_num">
                                            <span class="page_num_text">2</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="page_nav_div" title="다음">
                                <input type="button" id="btnNext" class="toolbar-btn bt-next">
                            </div>
                        </div>
                        <!-- //page_nav -->
                        <!-- page_ctrl -->
                        <div class="page_ctrl">
                            <div class="requiredCheck">
                                <p>
                                    이메일 주소 수집
                                    <span class="tooltipSpan">?</span>
                                </p>
                                <label class="switch">
                                    <input type="checkbox">
                                    <span class="slider round"></span>
                                </label>
                                <div class="requiredToolBox">
                                    <p>사용자에게 결과 및 A/S회신을 위한 용도로 이메일 주소를 수집합니다.</p>
                                </div>
                            </div>
                            <div class="itemAdd">
                                <a href="#" class="itm_ic ImAddBtn" title="항목 추가">
                                    <span class="ir_pm">항목추가</span>
                                </a>
                            </div>
                            <div class="divisionDiv"></div>
                            <a class="lengthMenu">메뉴보기</a>
                        </div>
                        <!-- //page_ctrl -->
                    </div>

                    <!-- 이메일 수집 토글시 on 클래스 추가하여 활성화 -->
                    <div class="surveyViewer_body email-collec">
                        <div class="srConTitle">
                            <p class="resultConQst">이메일 주소를 적어주세요<span class="essential on">*</span></p>
                            <p class="qustQstSub on">결과 및 A/S 회신을 위한 용도 입니다</p>
                        </div>
                        <ul class="qst_opt_div choice_qst_opt">
                            <!-- 주관식 옵션 form-qst-list 추가 / 단답형 최대글자 50자 / 장문형 최대글자 300자 -->
                            <li class="choice-qst-list form-qst-list">
                                <div class="choice-qst-list-con form-qst-list-con">
                                    <input type="email" id="surveyEmail" class="qst_input_text" placeholder="유효한 이메일 주소를 적어주세요" readonly>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="surveyViewer_body">
                        <div class="surText_top_bar"></div>
                        <div class="surText_con">
                            <div class="sur_page_title">
                                <div class="pg_title">
                                    <input type="text" id="sur_pg_title" placeholder="(선택 사항) 페이지 제목을 입력하세요" class="input_text">
                                    <input type="text" id="sur_pg__sub_title" placeholder="(선택 사항) 페이지에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                </div>
                            </div>
                            <!--surTypeBox Div에 유형에 따라 클래스 추가 
                                주관식 단답형 type-box-short / 주관식 장문형 type-box-long
                                객관식 단일형 type-box-choice / 객관식 복수형 type-box-mult
                                드롭다운 type-box-dpdw / 선호도형 type-box-prfrn
                                객관식 표 type-box-grpcho / 날짜시간 type-box-dttime
                            -->
                            <!-- 
                                답변기준 페이지 체크시 pgMoveOn 클레스 추가
                            -->

        <!----------------- 객관식 단일 질문 추가시 기본 틀 -->
                            <div class="surTypeBox mt40 type-box-choice">
                                <!-- 유형 선택 -->
                                <div class="selectBg typeSelect">
                                    <h3 id="typeSelBox" class="selectTitle">
                                        <span class="typeIcon choice_ic">유형 아이콘</span>
                                        객관식 단일형
                                    </h3>
                                    <ul class="dropdown selectUl">
                                        <li class="short-form">
                                            <a><span class="typeIcon short_ic">유형 아이콘</span>주관식 단답형</a>
                                        </li>
                                        <li class="long-form">
                                            <a><span class="typeIcon long_ic">유형 아이콘</span>주관식 장문형</a>
                                        </li>
                                        <li class="choice-form">
                                            <a><span class="typeIcon choice_ic">유형 아이콘</span>객관식 단일형</a>
                                        </li>
                                        <li class="multiple-form">
                                            <a><span class="typeIcon multiple_ic">유형 아이콘</span>객관식 복수형</a>
                                        </li>
                                        <li class="drop-down-form">
                                            <a><span class="typeIcon drop_down_ic">유형 아이콘</span>드롭다운</a>
                                        </li>
                                        <li class="preference-form">
                                            <a><span class="typeIcon preference_ic">유형 아이콘</span>선호도형</a>
                                        </li>
                                        <li class="graph-choice-form">
                                            <a><span class="typeIcon graph_choice_ic">유형 아이콘</span>객관식 표</a>
                                        </li>
                                        <li class="date-time-form">
                                            <a><span class="typeIcon date_time_ic">유형 아이콘</span>날짜 / 시간</a>
                                        </li>
                                    </ul>
                                    <div class="typePgMenu">
                                        <div class="itemAdd itemCopy">
                                            <a href="#" class="itm_ic ImCopyBtn" title="복사">
                                                <span class="ir_pm">항목 복사</span>
                                            </a>
                                        </div>
                                        <div class="itemAdd itemDel">
                                            <a href="#" class="itm_ic ImDelBtn" title="삭제">
                                                <span class="ir_pm">항목 삭제</span>
                                            </a>
                                        </div>
                                        <div class="divisionDiv"></div>
                                        <div class="requiredCheck">
                                            <p>
                                                필수입력
                                            </p>
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- 유형에 따라 보이는 옵션이 다름 -->
                                <div class="typeOpBox">
                                    <ul>
                                        <!-- 주관식 단답형-->
                                        <li class="tpOption short-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="50">
                                            </p>
                                        </li>
                                        <!-- //주관식 단답형-->

                                        <!-- 주관식 장문형-->
                                        <li class="tpOption long-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="300">
                                            </p>
                                        </li>
                                        <!-- //주관식 장문형-->

                                        <!-- 객관식 단일형 choice-type-op / 드롭다운 drop-down-type-op -->
                                        <li class="tpOption choice-type-op">
                                            <p>
                                                <input type="checkbox" id="answer-move-op01" name="type_option" value="answer">
                                                <label for="answer-move-op01">답변을 기준으로 페이지 이동</label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op01" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op01">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 단일형 / 드롭다운 -->

                                        <!-- 객관식 복수형 / 질문 답변옵션 개수에 맞추어 select의 option 개수가 늘어나게 가능하면 해주세요 -->
                                        <li class="tpOption multiple-type-op">
                                            <p>
                                                <span class="opTextTitle multText">복수 선택 가능 개수</span>
                                                <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                <select id="least-num" class="least-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                </select>
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <select id="maximum-num" class="maximum-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                    <option value="unlimited">무제한</option>  
                                                </select>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op02" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op02">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 복수형 -->

                                        <!-- 객관식 표-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="graph-multiple-op01" name="type_option" value="graph-multiple">
                                                <label for="graph-multiple-op01">복수 선택</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 표-->

                                        <!-- 날짜 / 시간-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="date-time-op01" name="type_option" value="date-time">
                                                <label for="date-time-op01">시간 포함</label>
                                            </p>
                                        </li>
                                        <!-- //날짜 / 시간-->
                                    </ul>
                                </div>

                                <!-- 유형에 따라 보이는 설문 작성 -->
                                <div class="typeQuestion">
                                    <div class="qst_title">
                                        <div class="pg_title">
                                            <input type="text" id="sur_type_qst_tit" placeholder="질문을 입력하세요" class="input_text">
                                            <span class="qstImgSpan">
                                                <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                    <p class="img_bring_ic">이미지 가져오기</p>
                                                </label>
                                                <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                            </span>
                                            <input type="text" id="sur_type_qst__sub_title" placeholder="(선택 사항) 질문에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                        </div>
                                    </div>

                                    <div class="qst_option_wrap mt20">
                                        <ul class="qst_opt_div choice_qst_opt">
                                            <!-- 선택 옵션 -->
                                            <li class="choice-qst-list">
                                                <div class="choice-qst-list-con">
                                                    <i class="op_chk_show"></i>
                                                    <input type="text" placeholder="옵션을 입력하세요" class="qst_input_text">
                                                </div>
                                                <span class="qstImgSpan">
                                                    <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                        <p class="img_bring_ic">이미지 가져오기</p>
                                                    </label>
                                                    <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                                </span>
                                                <!-- 옵션이 1개일때는 보이지 않고 2개이상일대 보일 것 -->
                                                <a href="#" class="delBtn" title="옵션 삭제">삭제</a>
                                            </li>

                                            <!-- 직접 입력 옵션 클래스 choice-input-qst-list 추가 / 직접 입력 추가하면 on 클래스 추가 -->
                                            <li class="choice-qst-list choice-input-qst-list">
                                                <div class="choice-qst-list-con">
                                                    <i class="op_chk_show"></i>
                                                    <input type="text" placeholder="기타... (직접 입력)" class="qst_input_text" readonly>
                                                </div>
                                                <span class="qstImgSpan">
                                                    <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                        <p class="img_bring_ic">이미지 가져오기</p>
                                                    </label>
                                                    <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                                </span>
                                                <a href="#" class="delBtn" title="옵션 삭제">삭제</a>
                                            </li>
                                        </ul>

                                        <!-- 직접 입력은 한번만 추가 가능 (추가를 했다면 input_none 클래스 추가하여 숨기기) -->
                                        <div class="qst_opt_add mt20">
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_plus">옵션추가 아이콘</span>
                                                옵션 추가
                                            </button>
                                            <span class="text_separator">또는</span>
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_input">기타입력 아이콘</span>
                                                기타 입력란 추가
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
        <!----------------- 객관식 단일 사진 추가 -->
                            <div class="surTypeBox mt40 type-box-choice">
                                <!-- 유형 선택 -->
                                <div class="selectBg typeSelect">
                                    <h3 id="typeSelBox" class="selectTitle">
                                        <span class="typeIcon choice_ic">유형 아이콘</span>
                                        객관식 단일형
                                    </h3>
                                    <ul class="dropdown selectUl">
                                        <li class="short-form">
                                            <a><span class="typeIcon short_ic">유형 아이콘</span>주관식 단답형</a>
                                        </li>
                                        <li class="long-form">
                                            <a><span class="typeIcon long_ic">유형 아이콘</span>주관식 장문형</a>
                                        </li>
                                        <li class="choice-form">
                                            <a><span class="typeIcon choice_ic">유형 아이콘</span>객관식 단일형</a>
                                        </li>
                                        <li class="multiple-form">
                                            <a><span class="typeIcon multiple_ic">유형 아이콘</span>객관식 복수형</a>
                                        </li>
                                        <li class="drop-down-form">
                                            <a><span class="typeIcon drop_down_ic">유형 아이콘</span>드롭다운</a>
                                        </li>
                                        <li class="preference-form">
                                            <a><span class="typeIcon preference_ic">유형 아이콘</span>선호도형</a>
                                        </li>
                                        <li class="graph-choice-form">
                                            <a><span class="typeIcon graph_choice_ic">유형 아이콘</span>객관식 표</a>
                                        </li>
                                        <li class="date-time-form">
                                            <a><span class="typeIcon date_time_ic">유형 아이콘</span>날짜 / 시간</a>
                                        </li>
                                    </ul>
                                    <div class="typePgMenu">
                                        <div class="itemAdd itemCopy">
                                            <a href="#" class="itm_ic ImCopyBtn" title="복사">
                                                <span class="ir_pm">항목 복사</span>
                                            </a>
                                        </div>
                                        <div class="itemAdd itemDel">
                                            <a href="#" class="itm_ic ImDelBtn" title="삭제">
                                                <span class="ir_pm">항목 삭제</span>
                                            </a>
                                        </div>
                                        <div class="divisionDiv"></div>
                                        <div class="requiredCheck">
                                            <p>
                                                필수입력
                                            </p>
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- 유형에 따라 보이는 옵션이 다름 -->
                                <div class="typeOpBox">
                                    <ul>
                                        <!-- 주관식 단답형-->
                                        <li class="tpOption short-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="50">
                                            </p>
                                        </li>
                                        <!-- //주관식 단답형-->

                                        <!-- 주관식 장문형-->
                                        <li class="tpOption long-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="300">
                                            </p>
                                        </li>
                                        <!-- //주관식 장문형-->

                                        <!-- 객관식 단일형 choice-type-op / 드롭다운 drop-down-type-op -->
                                        <li class="tpOption choice-type-op">
                                            <p>
                                                <input type="checkbox" id="answer-move-op01" name="type_option" value="answer">
                                                <label for="answer-move-op01">답변을 기준으로 페이지 이동</label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op01" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op01">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 단일형 / 드롭다운 -->

                                        <!-- 객관식 복수형 / 질문 답변옵션 개수에 맞추어 select의 option 개수가 늘어나게 가능하면 해주세요 -->
                                        <li class="tpOption multiple-type-op">
                                            <p>
                                                <span class="opTextTitle multText">복수 선택 가능 개수</span>
                                                <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                <select id="least-num" class="least-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                </select>
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <select id="maximum-num" class="maximum-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                    <option value="unlimited">무제한</option>  
                                                </select>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op02" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op02">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 복수형 -->

                                        <!-- 객관식 표-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="graph-multiple-op01" name="type_option" value="graph-multiple">
                                                <label for="graph-multiple-op01">복수 선택</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 표-->

                                        <!-- 날짜 / 시간-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="date-time-op01" name="type_option" value="date-time">
                                                <label for="date-time-op01">시간 포함</label>
                                            </p>
                                        </li>
                                        <!-- //날짜 / 시간-->
                                    </ul>
                                </div>

                                <!-- 유형에 따라 보이는 설문 작성 -->
                                <div class="typeQuestion">
                                    <div class="qst_title">
                                        <div class="pg_title">
                                            <input type="text" id="sur_type_qst_tit" placeholder="질문을 입력하세요" class="input_text">
                                            <span class="qstImgSpan">
                                                <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                    <p class="img_bring_ic">이미지 가져오기</p>
                                                </label>
                                                <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                            </span>
                                            <input type="text" id="sur_type_qst__sub_title" placeholder="(선택 사항) 질문에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                        </div>
                                        <!-- 사진 추가시 on 추가 -->
                                        <div class="survey_img_add qst_title_img mt20 on">
                                            <p class="qst_img">질문 이미지</p>
                                            <input type="button" value="삭제" class="del btn_imageDel">
                                        </div>
                                    </div>

                                    <div class="qst_option_wrap mt20">
                                        <ul class="qst_opt_div choice_qst_opt">
                                            <!-- 선택 옵션 -->
                                            <li class="choice-qst-list">
                                                <div class="choice-qst-list-con">
                                                    <i class="op_chk_show"></i>
                                                    <input type="text" placeholder="옵션을 입력하세요" class="qst_input_text">
                                                </div>
                                                <span class="qstImgSpan">
                                                    <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                        <p class="img_bring_ic">이미지 가져오기</p>
                                                    </label>
                                                    <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                                </span>
                                                <!-- 옵션이 1개일때는 보이지 않고 2개이상일대 보일 것 -->
                                                <a href="#" class="delBtn" title="옵션 삭제">삭제</a>
                                            </li>
                                            <!-- 옵션 사진 추가시 li 사진 리스트 추가 / 사진 추가시 on 추가 -->
                                            <li class="survey_img_add on">
                                                <div class="qstOpImg">
                                                    <p class="qst_img">질문 이미지</p>
                                                    <input type="button" value="삭제" class="del btn_imageDel">
                                                </div>
                                            </li>

                                            <li class="choice-qst-list">
                                                <div class="choice-qst-list-con">
                                                    <i class="op_chk_show"></i>
                                                    <input type="text" placeholder="옵션을 입력하세요" class="qst_input_text">
                                                </div>
                                                <span class="qstImgSpan">
                                                    <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                        <p class="img_bring_ic">이미지 가져오기</p>
                                                    </label>
                                                    <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                                </span>
                                                <!-- 옵션이 1개일때는 보이지 않고 2개이상일대 보일 것 -->
                                                <a href="#" class="delBtn" title="옵션 삭제">삭제</a>
                                            </li>

                                            <!-- 직접 입력 옵션 클래스 choice-input-qst-list 추가 / 직접 입력 추가하면 on 클래스 추가 -->
                                            <li class="choice-qst-list choice-input-qst-list">
                                                <div class="choice-qst-list-con">
                                                    <i class="op_chk_show"></i>
                                                    <input type="text" placeholder="기타... (직접 입력)" class="qst_input_text" readonly>
                                                </div>
                                                <span class="qstImgSpan">
                                                    <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                        <p class="img_bring_ic">이미지 가져오기</p>
                                                    </label>
                                                    <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                                </span>
                                                <a href="#" class="delBtn" title="옵션 삭제">삭제</a>
                                            </li>
                                        </ul>

                                        <!-- 직접 입력은 한번만 추가 가능 (추가를 했다면 input_none 클래스 추가하여 숨기기) -->
                                        <div class="qst_opt_add mt20">
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_plus">옵션추가 아이콘</span>
                                                옵션 추가
                                            </button>
                                            <span class="text_separator">또는</span>
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_input">기타입력 아이콘</span>
                                                기타 입력란 추가
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

        <!----------------- 객관식 단일 페이지 기준 이동 -->
                            <div class="surTypeBox mt40 type-box-choice pgMoveOn">
                                <!-- 유형 선택 -->
                                <div class="selectBg typeSelect">
                                    <h3 id="typeSelBox" class="selectTitle">
                                        <span class="typeIcon choice_ic">유형 아이콘</span>
                                        객관식 단일형
                                    </h3>
                                    <ul class="dropdown selectUl">
                                        <li class="short-form">
                                            <a><span class="typeIcon short_ic">유형 아이콘</span>주관식 단답형</a>
                                        </li>
                                        <li class="long-form">
                                            <a><span class="typeIcon long_ic">유형 아이콘</span>주관식 장문형</a>
                                        </li>
                                        <li class="choice-form">
                                            <a><span class="typeIcon choice_ic">유형 아이콘</span>객관식 단일형</a>
                                        </li>
                                        <li class="multiple-form">
                                            <a><span class="typeIcon multiple_ic">유형 아이콘</span>객관식 복수형</a>
                                        </li>
                                        <li class="drop-down-form">
                                            <a><span class="typeIcon drop_down_ic">유형 아이콘</span>드롭다운</a>
                                        </li>
                                        <li class="preference-form">
                                            <a><span class="typeIcon preference_ic">유형 아이콘</span>선호도형</a>
                                        </li>
                                        <li class="graph-choice-form">
                                            <a><span class="typeIcon graph_choice_ic">유형 아이콘</span>객관식 표</a>
                                        </li>
                                        <li class="date-time-form">
                                            <a><span class="typeIcon date_time_ic">유형 아이콘</span>날짜 / 시간</a>
                                        </li>
                                    </ul>
                                    <div class="typePgMenu">
                                        <div class="itemAdd itemCopy">
                                            <a href="#" class="itm_ic ImCopyBtn" title="복사">
                                                <span class="ir_pm">항목 복사</span>
                                            </a>
                                        </div>
                                        <div class="itemAdd itemDel">
                                            <a href="#" class="itm_ic ImDelBtn" title="삭제">
                                                <span class="ir_pm">항목 삭제</span>
                                            </a>
                                        </div>
                                        <div class="divisionDiv"></div>
                                        <div class="requiredCheck">
                                            <p>
                                                필수입력
                                            </p>
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- 유형에 따라 보이는 옵션이 다름 -->
                                <div class="typeOpBox">
                                    <ul>
                                        <!-- 주관식 단답형-->
                                        <li class="tpOption short-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="50">
                                            </p>
                                        </li>
                                        <!-- //주관식 단답형-->

                                        <!-- 주관식 장문형-->
                                        <li class="tpOption long-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="300">
                                            </p>
                                        </li>
                                        <!-- //주관식 장문형-->

                                        <!-- 객관식 단일형 choice-type-op / 드롭다운 drop-down-type-op -->
                                        <li class="tpOption choice-type-op">
                                            <p>
                                                <input type="checkbox" id="answer-move-op01-a" name="type_option" value="answer" checked/>
                                                <label for="answer-move-op01-a">답변을 기준으로 페이지 이동</label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op01-a" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op01-a">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 단일형 / 드롭다운 -->

                                        <!-- 객관식 복수형 / 질문 답변옵션 개수에 맞추어 select의 option 개수가 늘어나게 가능하면 해주세요 -->
                                        <li class="tpOption multiple-type-op">
                                            <p>
                                                <span class="opTextTitle multText">복수 선택 가능 개수</span>
                                                <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                <select id="least-num" class="least-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                </select>
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <select id="maximum-num" class="maximum-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                    <option value="unlimited">무제한</option>  
                                                </select>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op02-a" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op02-a">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 복수형 -->

                                        <!-- 객관식 표-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="graph-multiple-op01-a" name="type_option" value="graph-multiple">
                                                <label for="graph-multiple-op01-a">복수 선택</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 표-->

                                        <!-- 날짜 / 시간-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="date-time-op01-a" name="type_option" value="date-time">
                                                <label for="date-time-op01-a">시간 포함</label>
                                            </p>
                                        </li>
                                        <!-- //날짜 / 시간-->
                                    </ul>
                                </div>

                                <!-- 유형에 따라 보이는 설문 작성 -->
                                <div class="typeQuestion">
                                    <div class="qst_title">
                                        <div class="pg_title">
                                            <input type="text" id="sur_type_qst_tit" placeholder="질문을 입력하세요" class="input_text">
                                            <span class="qstImgSpan">
                                                <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                    <p class="img_bring_ic">이미지 가져오기</p>
                                                </label>
                                                <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                            </span>
                                            <input type="text" id="sur_type_qst__sub_title" placeholder="(선택 사항) 질문에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                        </div>
                                    </div>

                                    <div class="qst_option_wrap mt20">
                                        <ul class="qst_opt_div choice_qst_opt">
                                            <!-- 선택 옵션 -->
                                            <li class="choice-qst-list">
                                                <div class="choice-qst-list-con">
                                                    <i class="op_chk_show"></i>
                                                    <input type="text" placeholder="옵션을 입력하세요" class="qst_input_text">
                                                </div>
                                                <span class="qstImgSpan">
                                                    <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                        <p class="img_bring_ic">이미지 가져오기</p>
                                                    </label>
                                                    <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                                </span>
                                                <!-- 옵션이 1개일때는 보이지 않고 2개이상일대 보일 것 -->
                                                <a href="#" class="delBtn" title="옵션 삭제">삭제</a>
                                                
                                                <!-- 답변을 기준으로 페이지 이동 -->
                                                <div class="selectBg standard_move">
                                                    <h3 id="moveSelBox" class="selectTitle">다음 페이지로 진행</h3>
                                                    <ul class="dropdown moveSelUl">
                                                        <li><a href="#">다음 페이지로 진행</a></li>
                                                        <li><a href="#">2페이지로 진행</a></li>
                                                    </ul>
                                                </div>
                                            </li>

                                            <li class="choice-qst-list choice-input-qst-list on">
                                                <div class="choice-qst-list-con">
                                                    <i class="op_chk_show"></i>
                                                    <input type="text" placeholder="기타... (직접 입력)" class="qst_input_text" readonly>
                                                </div>
                                                <span class="qstImgSpan">
                                                    <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                        <p class="img_bring_ic">이미지 가져오기</p>
                                                    </label>
                                                    <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                                </span>
                                                <a href="#" class="delBtn" title="옵션 삭제">삭제</a>

                                                <div class="selectBg standard_move">
                                                    <h3 id="moveSelBox" class="selectTitle">다음 페이지로 진행</h3>
                                                    <ul class="dropdown moveSelUl">
                                                        <li><a href="#">다음 페이지로 진행</a></li>
                                                        <li><a href="#">2페이지로 진행</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>

                                        <!-- 직접 입력은 한번만 추가 가능 (추가를 했다면 input_none 클래스 추가하여 숨기기) -->
                                        <div class="qst_opt_add mt20 input_none">
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_plus">옵션추가 아이콘</span>
                                                옵션 추가
                                            </button>
                                            <span class="text_separator">또는</span>
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_input">기타입력 아이콘</span>
                                                기타 입력란 추가
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

        <!----------------- 객관식 복수 -->
                            <div class="surTypeBox mt40 type-box-mult">
                                <!-- 유형 선택 -->
                                <div class="selectBg typeSelect">
                                    <h3 id="typeSelBox" class="selectTitle">
                                        <span class="typeIcon multiple_ic">유형 아이콘</span>
                                        객관식 복수형
                                    </h3>
                                    <ul class="dropdown selectUl">
                                        <li class="short-form">
                                            <a><span class="typeIcon short_ic">유형 아이콘</span>주관식 단답형</a>
                                        </li>
                                        <li class="long-form">
                                            <a><span class="typeIcon long_ic">유형 아이콘</span>주관식 장문형</a>
                                        </li>
                                        <li class="choice-form">
                                            <a><span class="typeIcon choice_ic">유형 아이콘</span>객관식 단일형</a>
                                        </li>
                                        <li class="multiple-form">
                                            <a><span class="typeIcon multiple_ic">유형 아이콘</span>객관식 복수형</a>
                                        </li>
                                        <li class="drop-down-form">
                                            <a><span class="typeIcon drop_down_ic">유형 아이콘</span>드롭다운</a>
                                        </li>
                                        <li class="preference-form">
                                            <a><span class="typeIcon preference_ic">유형 아이콘</span>선호도형</a>
                                        </li>
                                        <li class="graph-choice-form">
                                            <a><span class="typeIcon graph_choice_ic">유형 아이콘</span>객관식 표</a>
                                        </li>
                                        <li class="date-time-form">
                                            <a><span class="typeIcon date_time_ic">유형 아이콘</span>날짜 / 시간</a>
                                        </li>
                                    </ul>
                                    <div class="typePgMenu">
                                        <div class="itemAdd itemCopy">
                                            <a href="#" class="itm_ic ImCopyBtn" title="복사">
                                                <span class="ir_pm">항목 복사</span>
                                            </a>
                                        </div>
                                        <div class="itemAdd itemDel">
                                            <a href="#" class="itm_ic ImDelBtn" title="삭제">
                                                <span class="ir_pm">항목 삭제</span>
                                            </a>
                                        </div>
                                        <div class="divisionDiv"></div>
                                        <div class="requiredCheck">
                                            <p>
                                                필수입력
                                            </p>
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- 유형에 따라 보이는 옵션이 다름 -->
                                <div class="typeOpBox">
                                    <ul>
                                        <!-- 주관식 단답형-->
                                        <li class="tpOption short-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="50">
                                            </p>
                                        </li>
                                        <!-- //주관식 단답형-->

                                        <!-- 주관식 장문형-->
                                        <li class="tpOption long-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="300">
                                            </p>
                                        </li>
                                        <!-- //주관식 장문형-->

                                        <!-- 객관식 단일형 choice-type-op / 드롭다운 drop-down-type-op -->
                                        <li class="tpOption choice-type-op">
                                            <p>
                                                <input type="checkbox" id="answer-move-op01-b" name="type_option" value="answer">
                                                <label for="answer-move-op01-b">답변을 기준으로 페이지 이동</label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op01-b" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op01-b">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 단일형 / 드롭다운 -->

                                        <!-- 객관식 복수형 / 질문 답변옵션 개수에 맞추어 select의 option 개수가 늘어나게 가능하면 해주세요 -->
                                        <li class="tpOption multiple-type-op">
                                            <p>
                                                <span class="opTextTitle multText">복수 선택 가능 개수</span>
                                                <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                <select id="least-num" class="least-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                </select>
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <select id="maximum-num" class="maximum-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                    <option value="unlimited">무제한</option>  
                                                </select>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op02-b" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op02-b">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 복수형 -->

                                        <!-- 객관식 표-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="graph-multiple-op01-b" name="type_option" value="graph-multiple">
                                                <label for="graph-multiple-op01-b">복수 선택</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 표-->

                                        <!-- 날짜 / 시간-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="date-time-op01-b" name="type_option" value="date-time">
                                                <label for="date-time-op01-b">시간 포함</label>
                                            </p>
                                        </li>
                                        <!-- //날짜 / 시간-->
                                    </ul>
                                </div>

                                <!-- 유형에 따라 보이는 설문 작성 -->
                                <div class="typeQuestion">
                                    <div class="qst_title">
                                        <div class="pg_title">
                                            <input type="text" id="sur_type_qst_tit" placeholder="질문을 입력하세요" class="input_text">
                                            <span class="qstImgSpan">
                                                <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                    <p class="img_bring_ic">이미지 가져오기</p>
                                                </label>
                                                <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                            </span>
                                            <input type="text" id="sur_type_qst__sub_title" placeholder="(선택 사항) 질문에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                        </div>
                                    </div>

                                    <div class="qst_option_wrap mt20">
                                        <ul class="qst_opt_div choice_qst_opt">
                                            <!-- 선택 옵션 -->
                                            <li class="choice-qst-list">
                                                <div class="choice-qst-list-con">
                                                    <i class="op_chk_show"></i>
                                                    <input type="text" placeholder="옵션을 입력하세요" class="qst_input_text">
                                                </div>
                                                <span class="qstImgSpan">
                                                    <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                        <p class="img_bring_ic">이미지 가져오기</p>
                                                    </label>
                                                    <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                                </span>
                                                <!-- 옵션이 1개일때는 보이지 않고 2개이상일대 보일 것 -->
                                                <a href="#" class="delBtn" title="옵션 삭제">삭제</a>
                                            </li>
                                        </ul>

                                        <div class="qst_opt_add mt20">
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_plus">옵션추가 아이콘</span>
                                                옵션 추가
                                            </button>
                                            <span class="text_separator">또는</span>
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_input">기타입력 아이콘</span>
                                                기타 입력란 추가
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

        <!----------------- 주관식 -->
                            <div class="surTypeBox mt40 type-box-short">
                                <!-- 유형 선택 -->
                                <div class="selectBg typeSelect">
                                    <h3 id="typeSelBox" class="selectTitle">
                                        <span class="typeIcon short_ic">유형 아이콘</span>
                                        주관식 단답형
                                    </h3>
                                    <ul class="dropdown selectUl">
                                        <li class="short-form">
                                            <a><span class="typeIcon short_ic">유형 아이콘</span>주관식 단답형</a>
                                        </li>
                                        <li class="long-form">
                                            <a><span class="typeIcon long_ic">유형 아이콘</span>주관식 장문형</a>
                                        </li>
                                        <li class="choice-form">
                                            <a><span class="typeIcon choice_ic">유형 아이콘</span>객관식 단일형</a>
                                        </li>
                                        <li class="multiple-form">
                                            <a><span class="typeIcon multiple_ic">유형 아이콘</span>객관식 복수형</a>
                                        </li>
                                        <li class="drop-down-form">
                                            <a><span class="typeIcon drop_down_ic">유형 아이콘</span>드롭다운</a>
                                        </li>
                                        <li class="preference-form">
                                            <a><span class="typeIcon preference_ic">유형 아이콘</span>선호도형</a>
                                        </li>
                                        <li class="graph-choice-form">
                                            <a><span class="typeIcon graph_choice_ic">유형 아이콘</span>객관식 표</a>
                                        </li>
                                        <li class="date-time-form">
                                            <a><span class="typeIcon date_time_ic">유형 아이콘</span>날짜 / 시간</a>
                                        </li>
                                    </ul>
                                    <div class="typePgMenu">
                                        <div class="itemAdd itemCopy">
                                            <a href="#" class="itm_ic ImCopyBtn" title="복사">
                                                <span class="ir_pm">항목 복사</span>
                                            </a>
                                        </div>
                                        <div class="itemAdd itemDel">
                                            <a href="#" class="itm_ic ImDelBtn" title="삭제">
                                                <span class="ir_pm">항목 삭제</span>
                                            </a>
                                        </div>
                                        <div class="divisionDiv"></div>
                                        <div class="requiredCheck">
                                            <p>
                                                필수입력
                                            </p>
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- 유형에 따라 보이는 옵션이 다름 -->
                                <div class="typeOpBox">
                                    <ul>
                                        <!-- 주관식 단답형-->
                                        <li class="tpOption short-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="50">
                                            </p>
                                        </li>
                                        <!-- //주관식 단답형-->

                                        <!-- 주관식 장문형-->
                                        <li class="tpOption long-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="300">
                                            </p>
                                        </li>
                                        <!-- //주관식 장문형-->

                                        <!-- 객관식 단일형 choice-type-op / 드롭다운 drop-down-type-op -->
                                        <li class="tpOption choice-type-op">
                                            <p>
                                                <input type="checkbox" id="answer-move-op01-c" name="type_option" value="answer">
                                                <label for="answer-move-op01-c">답변을 기준으로 페이지 이동</label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op01-c" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op01-c">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 단일형 / 드롭다운 -->

                                        <!-- 객관식 복수형 / 질문 답변옵션 개수에 맞추어 select의 option 개수가 늘어나게 가능하면 해주세요 -->
                                        <li class="tpOption multiple-type-op">
                                            <p>
                                                <span class="opTextTitle multText">복수 선택 가능 개수</span>
                                                <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                <select id="least-num" class="least-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                </select>
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <select id="maximum-num" class="maximum-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                    <option value="unlimited">무제한</option>  
                                                </select>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op02-b" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op02-b">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 복수형 -->

                                        <!-- 객관식 표-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="graph-multiple-op01-c" name="type_option" value="graph-multiple">
                                                <label for="graph-multiple-op01-c">복수 선택</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 표-->

                                        <!-- 날짜 / 시간-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="date-time-op01-c" name="type_option" value="date-time">
                                                <label for="date-time-op01-c">시간 포함</label>
                                            </p>
                                        </li>
                                        <!-- //날짜 / 시간-->
                                    </ul>
                                </div>

                                <!-- 유형에 따라 보이는 설문 작성 -->
                                <div class="typeQuestion">
                                    <div class="qst_title">
                                        <div class="pg_title">
                                            <input type="text" id="sur_type_qst_tit" placeholder="질문을 입력하세요" class="input_text">
                                            <span class="qstImgSpan">
                                                <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                    <p class="img_bring_ic">이미지 가져오기</p>
                                                </label>
                                                <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                            </span>
                                            <input type="text" id="sur_type_qst__sub_title" placeholder="(선택 사항) 질문에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                        </div>
                                    </div>

                                    <div class="qst_option_wrap mt20">
                                        <ul class="qst_opt_div choice_qst_opt">
                                            <!-- 주관식 옵션 form-qst-list 추가 / 단답형 최대글자 50자 / 장문형 최대글자 300자 -->
                                            <li class="choice-qst-list form-qst-list">
                                                <div class="choice-qst-list-con form-qst-list-con">
                                                    <textarea cols="300" rows="1" placeholder="답변을 입력하세요." readonly></textarea>
                                                </div>
                                            </li>
                                        </ul>

                                        <div class="qst_opt_add mt20">
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_plus">옵션추가 아이콘</span>
                                                옵션 추가
                                            </button>
                                            <span class="text_separator">또는</span>
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_input">기타입력 아이콘</span>
                                                기타 입력란 추가
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

        <!----------------- 드롭다운 -->
                            <div class="surTypeBox mt40 type-box-dpdw">
                                <!-- 유형 선택 -->
                                <div class="selectBg typeSelect">
                                    <h3 id="typeSelBox" class="selectTitle">
                                        <span class="typeIcon drop_down_ic">유형 아이콘</span>
                                        드롭다운
                                    </h3>
                                    <ul class="dropdown selectUl">
                                        <li class="short-form">
                                            <a><span class="typeIcon short_ic">유형 아이콘</span>주관식 단답형</a>
                                        </li>
                                        <li class="long-form">
                                            <a><span class="typeIcon long_ic">유형 아이콘</span>주관식 장문형</a>
                                        </li>
                                        <li class="choice-form">
                                            <a><span class="typeIcon choice_ic">유형 아이콘</span>객관식 단일형</a>
                                        </li>
                                        <li class="multiple-form">
                                            <a><span class="typeIcon multiple_ic">유형 아이콘</span>객관식 복수형</a>
                                        </li>
                                        <li class="drop-down-form">
                                            <a><span class="typeIcon drop_down_ic">유형 아이콘</span>드롭다운</a>
                                        </li>
                                        <li class="preference-form">
                                            <a><span class="typeIcon preference_ic">유형 아이콘</span>선호도형</a>
                                        </li>
                                        <li class="graph-choice-form">
                                            <a><span class="typeIcon graph_choice_ic">유형 아이콘</span>객관식 표</a>
                                        </li>
                                        <li class="date-time-form">
                                            <a><span class="typeIcon date_time_ic">유형 아이콘</span>날짜 / 시간</a>
                                        </li>
                                    </ul>
                                    <div class="typePgMenu">
                                        <div class="itemAdd itemCopy">
                                            <a href="#" class="itm_ic ImCopyBtn" title="복사">
                                                <span class="ir_pm">항목 복사</span>
                                            </a>
                                        </div>
                                        <div class="itemAdd itemDel">
                                            <a href="#" class="itm_ic ImDelBtn" title="삭제">
                                                <span class="ir_pm">항목 삭제</span>
                                            </a>
                                        </div>
                                        <div class="divisionDiv"></div>
                                        <div class="requiredCheck">
                                            <p>
                                                필수입력
                                            </p>
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- 유형에 따라 보이는 옵션이 다름 -->
                                <div class="typeOpBox">
                                    <ul>
                                        <!-- 주관식 단답형-->
                                        <li class="tpOption short-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="50">
                                            </p>
                                        </li>
                                        <!-- //주관식 단답형-->

                                        <!-- 주관식 장문형-->
                                        <li class="tpOption long-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="300">
                                            </p>
                                        </li>
                                        <!-- //주관식 장문형-->

                                        <!-- 객관식 단일형 choice-type-op / 드롭다운 drop-down-type-op -->
                                        <li class="tpOption choice-type-op">
                                            <p>
                                                <input type="checkbox" id="answer-move-op01-d" name="type_option" value="answer">
                                                <label for="answer-move-op01-d">답변을 기준으로 페이지 이동</label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op01-d" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op01-d">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 단일형 / 드롭다운 -->

                                        <!-- 객관식 복수형 / 질문 답변옵션 개수에 맞추어 select의 option 개수가 늘어나게 가능하면 해주세요 -->
                                        <li class="tpOption multiple-type-op">
                                            <p>
                                                <span class="opTextTitle multText">복수 선택 가능 개수</span>
                                                <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                <select id="least-num" class="least-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                </select>
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <select id="maximum-num" class="maximum-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                    <option value="unlimited">무제한</option>  
                                                </select>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op02-b" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op02-b">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 복수형 -->

                                        <!-- 객관식 표-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="graph-multiple-op01-d" name="type_option" value="graph-multiple">
                                                <label for="graph-multiple-op01-d">복수 선택</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 표-->

                                        <!-- 날짜 / 시간-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="date-time-op01-d" name="type_option" value="date-time">
                                                <label for="date-time-op01-d">시간 포함</label>
                                            </p>
                                        </li>
                                        <!-- //날짜 / 시간-->
                                    </ul>
                                </div>

                                <!-- 유형에 따라 보이는 설문 작성 -->
                                <div class="typeQuestion">
                                    <div class="qst_title">
                                        <div class="pg_title">
                                            <input type="text" id="sur_type_qst_tit" placeholder="질문을 입력하세요" class="input_text">
                                            <span class="qstImgSpan">
                                                <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                    <p class="img_bring_ic">이미지 가져오기</p>
                                                </label>
                                                <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                            </span>
                                            <input type="text" id="sur_type_qst__sub_title" placeholder="(선택 사항) 질문에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                        </div>
                                    </div>

                                    <div class="qst_option_wrap mt20">
                                        <ul class="qst_opt_div choice_qst_opt">
                                            <li class="choice-qst-list dpdw-qst-list">
                                                <span class="qst_order_num dpdw_order_num">1 </span>
                                                <div class="choice-qst-list-con">
                                                    <input type="text" placeholder="옵션을 입력하세요" class="qst_input_text">
                                                </div>
                                                <a href="#" title="옵션 삭제" class="delBtn">삭제</a>
                                            </li>
                                        </ul>

                                        <div class="qst_opt_add mt20 input_none">
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_plus">옵션추가 아이콘</span>
                                                옵션 추가
                                            </button>
                                            <span class="text_separator">또는</span>
                                            <button class="qst_opt_add_btn">
                                                <span class="opt_add_ic opt_add_input">기타입력 아이콘</span>
                                                기타 입력란 추가
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

        <!----------------- 선호도 -->
                            <div class="surTypeBox mt40 type-box-prfrn">
                                <!-- 유형 선택 -->
                                <div class="selectBg typeSelect">
                                    <h3 id="typeSelBox" class="selectTitle">
                                        <span class="typeIcon preference_ic">유형 아이콘</span>
                                        선호도형
                                    </h3>
                                    <ul class="dropdown selectUl">
                                        <li class="short-form">
                                            <a><span class="typeIcon short_ic">유형 아이콘</span>주관식 단답형</a>
                                        </li>
                                        <li class="long-form">
                                            <a><span class="typeIcon long_ic">유형 아이콘</span>주관식 장문형</a>
                                        </li>
                                        <li class="choice-form">
                                            <a><span class="typeIcon choice_ic">유형 아이콘</span>객관식 단일형</a>
                                        </li>
                                        <li class="multiple-form">
                                            <a><span class="typeIcon multiple_ic">유형 아이콘</span>객관식 복수형</a>
                                        </li>
                                        <li class="drop-down-form">
                                            <a><span class="typeIcon drop_down_ic">유형 아이콘</span>드롭다운</a>
                                        </li>
                                        <li class="preference-form">
                                            <a><span class="typeIcon preference_ic">유형 아이콘</span>선호도형</a>
                                        </li>
                                        <li class="graph-choice-form">
                                            <a><span class="typeIcon graph_choice_ic">유형 아이콘</span>객관식 표</a>
                                        </li>
                                        <li class="date-time-form">
                                            <a><span class="typeIcon date_time_ic">유형 아이콘</span>날짜 / 시간</a>
                                        </li>
                                    </ul>
                                    <div class="typePgMenu">
                                        <div class="itemAdd itemCopy">
                                            <a href="#" class="itm_ic ImCopyBtn" title="복사">
                                                <span class="ir_pm">항목 복사</span>
                                            </a>
                                        </div>
                                        <div class="itemAdd itemDel">
                                            <a href="#" class="itm_ic ImDelBtn" title="삭제">
                                                <span class="ir_pm">항목 삭제</span>
                                            </a>
                                        </div>
                                        <div class="divisionDiv"></div>
                                        <div class="requiredCheck">
                                            <p>
                                                필수입력
                                            </p>
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- 유형에 따라 보이는 옵션이 다름 -->
                                <div class="typeOpBox">
                                    <ul>
                                        <!-- 주관식 단답형-->
                                        <li class="tpOption short-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="50">
                                            </p>
                                        </li>
                                        <!-- //주관식 단답형-->

                                        <!-- 주관식 장문형-->
                                        <li class="tpOption long-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="300">
                                            </p>
                                        </li>
                                        <!-- //주관식 장문형-->

                                        <!-- 객관식 단일형 choice-type-op / 드롭다운 drop-down-type-op -->
                                        <li class="tpOption choice-type-op">
                                            <p>
                                                <input type="checkbox" id="answer-move-op01-e" name="type_option" value="answer">
                                                <label for="answer-move-op01-e">답변을 기준으로 페이지 이동</label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op01-e" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op01-e">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 단일형 / 드롭다운 -->

                                        <!-- 객관식 복수형 / 질문 답변옵션 개수에 맞추어 select의 option 개수가 늘어나게 가능하면 해주세요 -->
                                        <li class="tpOption multiple-type-op">
                                            <p>
                                                <span class="opTextTitle multText">복수 선택 가능 개수</span>
                                                <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                <select id="least-num" class="least-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                </select>
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <select id="maximum-num" class="maximum-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                    <option value="unlimited">무제한</option>  
                                                </select>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op02" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op02">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 복수형 -->

                                        <!-- 객관식 표-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="graph-multiple-op01-e" name="type_option" value="graph-multiple">
                                                <label for="graph-multiple-op01-e">복수 선택</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 표-->

                                        <!-- 날짜 / 시간-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="date-time-op01-e" name="type_option" value="date-time">
                                                <label for="date-time-op01-e">시간 포함</label>
                                            </p>
                                        </li>
                                        <!-- //날짜 / 시간-->
                                    </ul>
                                </div>

                                <!-- 유형에 따라 보이는 설문 작성 -->
                                <div class="typeQuestion">
                                    <div class="qst_title">
                                        <div class="pg_title">
                                            <input type="text" id="sur_type_qst_tit" placeholder="질문을 입력하세요" class="input_text">
                                            <span class="qstImgSpan">
                                                <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                    <p class="img_bring_ic">이미지 가져오기</p>
                                                </label>
                                                <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                            </span>
                                            <input type="text" id="sur_type_qst__sub_title" placeholder="(선택 사항) 질문에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                        </div>
                                    </div>

                                    <div class="qst_option_wrap mt20">
                                        <ul class="qst_opt_div choice_qst_opt">
                                            <!-- 선호도형 옵션 -->
                                            <li class="choice-qst-list prfrn-qst-list">
                                                <div class="choice-qst-list-con prfrn-qst-list-con">
                                                    <input type="text" placeholder="옵션을 입력하세요" class="qst_input_text">
                                                </div>
                                                <div class="prfrn_selc_div">
                                                    <span class="opTextTitle multText">선호도 단계</span>
                                                    <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                    <select id="least-num" class="least-num-sel choice-num">
                                                        <option value="1">1</option>  
                                                        <option value="2">2</option>  
                                                        <option value="3">3</option>  
                                                        <option value="4">4</option>  
                                                        <option value="5">5</option>  
                                                    </select>
                                                    <span class="wave_separator">∼</span>
                                                    <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                    <select id="maximum-num" class="maximum-num-sel choice-num">
                                                        <option value="1">1</option>  
                                                        <option value="2">2</option>  
                                                        <option value="3">3</option>  
                                                        <option value="4">4</option>  
                                                        <option value="5" selected>5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                                <div class="choice-qst-list-con prfrn-qst-list-con">
                                                    <input type="text" placeholder="옵션을 입력하세요" class="qst_input_text">
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

        <!----------------- 객관식 표 -->
                            <div class="surTypeBox mt40 type-box-grpcho">
                                <!-- 유형 선택 -->
                                <div class="selectBg typeSelect">
                                    <h3 id="typeSelBox" class="selectTitle">
                                        <span class="typeIcon graph_choice_ic">유형 아이콘</span>
                                        객관식 표
                                    </h3>
                                    <ul class="dropdown selectUl">
                                        <li class="short-form">
                                            <a><span class="typeIcon short_ic">유형 아이콘</span>주관식 단답형</a>
                                        </li>
                                        <li class="long-form">
                                            <a><span class="typeIcon long_ic">유형 아이콘</span>주관식 장문형</a>
                                        </li>
                                        <li class="choice-form">
                                            <a><span class="typeIcon choice_ic">유형 아이콘</span>객관식 단일형</a>
                                        </li>
                                        <li class="multiple-form">
                                            <a><span class="typeIcon multiple_ic">유형 아이콘</span>객관식 복수형</a>
                                        </li>
                                        <li class="drop-down-form">
                                            <a><span class="typeIcon drop_down_ic">유형 아이콘</span>드롭다운</a>
                                        </li>
                                        <li class="preference-form">
                                            <a><span class="typeIcon preference_ic">유형 아이콘</span>선호도형</a>
                                        </li>
                                        <li class="graph-choice-form">
                                            <a><span class="typeIcon graph_choice_ic">유형 아이콘</span>객관식 표</a>
                                        </li>
                                        <li class="date-time-form">
                                            <a><span class="typeIcon date_time_ic">유형 아이콘</span>날짜 / 시간</a>
                                        </li>
                                    </ul>
                                    <div class="typePgMenu">
                                        <div class="itemAdd itemCopy">
                                            <a href="#" class="itm_ic ImCopyBtn" title="복사">
                                                <span class="ir_pm">항목 복사</span>
                                            </a>
                                        </div>
                                        <div class="itemAdd itemDel">
                                            <a href="#" class="itm_ic ImDelBtn" title="삭제">
                                                <span class="ir_pm">항목 삭제</span>
                                            </a>
                                        </div>
                                        <div class="divisionDiv"></div>
                                        <div class="requiredCheck">
                                            <p>
                                                필수입력
                                            </p>
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- 유형에 따라 보이는 옵션이 다름 -->
                                <div class="typeOpBox">
                                    <ul>
                                        <!-- 주관식 단답형-->
                                        <li class="tpOption short-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="50">
                                            </p>
                                        </li>
                                        <!-- //주관식 단답형-->

                                        <!-- 주관식 장문형-->
                                        <li class="tpOption long-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="300">
                                            </p>
                                        </li>
                                        <!-- //주관식 장문형-->

                                        <!-- 객관식 단일형 choice-type-op / 드롭다운 drop-down-type-op -->
                                        <li class="tpOption choice-type-op">
                                            <p>
                                                <input type="checkbox" id="answer-move-op01-f" name="type_option" value="answer">
                                                <label for="answer-move-op01-f">답변을 기준으로 페이지 이동</label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op01-f" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op01-f">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 단일형 / 드롭다운 -->

                                        <!-- 객관식 복수형 / 질문 답변옵션 개수에 맞추어 select의 option 개수가 늘어나게 가능하면 해주세요 -->
                                        <li class="tpOption multiple-type-op">
                                            <p>
                                                <span class="opTextTitle multText">복수 선택 가능 개수</span>
                                                <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                <select id="least-num" class="least-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                </select>
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <select id="maximum-num" class="maximum-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                    <option value="unlimited">무제한</option>  
                                                </select>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op02" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op02">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 복수형 -->

                                        <!-- 객관식 표-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="graph-multiple-op01-f" name="type_option" value="graph-multiple">
                                                <label for="graph-multiple-op01-f">복수 선택</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 표-->

                                        <!-- 날짜 / 시간-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="date-time-op01-f" name="type_option" value="date-time">
                                                <label for="date-time-op01-f">시간 포함</label>
                                            </p>
                                        </li>
                                        <!-- //날짜 / 시간-->
                                    </ul>
                                </div>

                                <!-- 유형에 따라 보이는 설문 작성 -->
                                <div class="typeQuestion">
                                    <div class="qst_title">
                                        <div class="pg_title">
                                            <input type="text" id="sur_type_qst_tit" placeholder="질문을 입력하세요" class="input_text">
                                            <span class="qstImgSpan">
                                                <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                    <p class="img_bring_ic">이미지 가져오기</p>
                                                </label>
                                                <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                            </span>
                                            <input type="text" id="sur_type_qst__sub_title" placeholder="(선택 사항) 질문에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                        </div>
                                    </div>

                                    <div class="qst_option_wrap mt20">
                                        <ul class="qst_opt_div choice_qst_opt">
                                            <!-- 객관식 표 옵션 -->
                                            <li class="choice-qst-list grpcho-qst-list">
                                                <div class="grpcho_preview">
                                                    <button class="qst_opt_add_btn preview_btn" @click.prevent="showChoiceListModal"><span class="opt_add_ic grpcho_preview_ic">미리보기 아이콘</span> 표 미리보기 </button>
                                                </div>
                                                <div class="grpcho_lis_box mt10">
                                                    <div class="grpcho_qst_div grpcho_line_div">
                                                        <button class="qst_opt_add_btn grpcho_op_add line_add_btn">행 추가</button>
                                                        <ul class="grpchoQstUl line-add-ul">
                                                            <li>
                                                                <span class="qst_order_num">1 </span>
                                                                <div class="choice-qst-list-con">
                                                                    <input type="text" placeholder="소 질문 옵션을 입력하세요" class="qst_input_text">
                                                                </div>
                                                                <a href="#" title="옵션 삭제" class="delBtn">삭제</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <hr class="division_hr">
                                                    <div class="grpcho_qst_div grpcho_row_div">
                                                        <button class="qst_opt_add_btn grpcho_op_add row_add_btn">열 추가</button>
                                                        <ul class="grpchoQstUl row-add-ul">
                                                            <li>
                                                                <span class="qst_order_num">1 </span>
                                                                <div class="choice-qst-list-con">
                                                                    <input type="text" placeholder="보기 옵션을 입력하세요" class="qst_input_text">
                                                                </div>
                                                                <a href="#" title="옵션 삭제" class="delBtn">삭제</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

        <!----------------- 시간/날짜 -->
                            <div class="surTypeBox mt40 type-box-dttime">
                                <!-- 유형 선택 -->
                                <div class="selectBg typeSelect">
                                    <h3 id="typeSelBox" class="selectTitle">
                                        <span class="typeIcon date_time_ic">유형 아이콘</span>
                                        날짜 &#47; 시간
                                    </h3>
                                    <ul class="dropdown selectUl">
                                        <li class="short-form">
                                            <a><span class="typeIcon short_ic">유형 아이콘</span>주관식 단답형</a>
                                        </li>
                                        <li class="long-form">
                                            <a><span class="typeIcon long_ic">유형 아이콘</span>주관식 장문형</a>
                                        </li>
                                        <li class="choice-form">
                                            <a><span class="typeIcon choice_ic">유형 아이콘</span>객관식 단일형</a>
                                        </li>
                                        <li class="multiple-form">
                                            <a><span class="typeIcon multiple_ic">유형 아이콘</span>객관식 복수형</a>
                                        </li>
                                        <li class="drop-down-form">
                                            <a><span class="typeIcon drop_down_ic">유형 아이콘</span>드롭다운</a>
                                        </li>
                                        <li class="preference-form">
                                            <a><span class="typeIcon preference_ic">유형 아이콘</span>선호도형</a>
                                        </li>
                                        <li class="graph-choice-form">
                                            <a><span class="typeIcon graph_choice_ic">유형 아이콘</span>객관식 표</a>
                                        </li>
                                        <li class="date-time-form">
                                            <a><span class="typeIcon date_time_ic">유형 아이콘</span>날짜 / 시간</a>
                                        </li>
                                    </ul>
                                    <div class="typePgMenu">
                                        <div class="itemAdd itemCopy">
                                            <a href="#" class="itm_ic ImCopyBtn" title="복사">
                                                <span class="ir_pm">항목 복사</span>
                                            </a>
                                        </div>
                                        <div class="itemAdd itemDel">
                                            <a href="#" class="itm_ic ImDelBtn" title="삭제">
                                                <span class="ir_pm">항목 삭제</span>
                                            </a>
                                        </div>
                                        <div class="divisionDiv"></div>
                                        <div class="requiredCheck">
                                            <p>
                                                필수입력
                                            </p>
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- 유형에 따라 보이는 옵션이 다름 -->
                                <div class="typeOpBox">
                                    <ul>
                                        <!-- 주관식 단답형-->
                                        <li class="tpOption short-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="50">
                                            </p>
                                        </li>
                                        <!-- //주관식 단답형-->

                                        <!-- 주관식 장문형-->
                                        <li class="tpOption long-type-op">
                                            <p>
                                                <span class="opTextTitle multText">글자 수 제한</span>
                                                <label for="least-choice" class="least-num-text choice-text">최소</label>
                                                <input type="text" id="least-num" class="least-num-sel choice-num" placeholder="1">
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <input type="text" id="maximum-num" class="maximum-num-sel choice-num" placeholder="300">
                                            </p>
                                        </li>
                                        <!-- //주관식 장문형-->

                                        <!-- 객관식 단일형 choice-type-op / 드롭다운 drop-down-type-op -->
                                        <li class="tpOption choice-type-op">
                                            <p>
                                                <input type="checkbox" id="answer-move-op01-g" name="type_option" value="answer">
                                                <label for="answer-move-op01-g">답변을 기준으로 페이지 이동</label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op01-g" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op01-g">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 단일형 / 드롭다운 -->

                                        <!-- 객관식 복수형 / 질문 답변옵션 개수에 맞추어 select의 option 개수가 늘어나게 가능하면 해주세요 -->
                                        <li class="tpOption multiple-type-op">
                                            <p>
                                                <span class="opTextTitle multText">복수 선택 가능 개수</span>
                                                <label for="least-choice" class="least-num-tex choice-text">최소</label>
                                                <select id="least-num" class="least-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                </select>
                                                <span class="wave_separator">∼</span>
                                                <label for="maximum-choice" class="maximum-num-text choice-text">최대</label>
                                                <select id="maximum-num" class="maximum-num-sel choice-num">
                                                    <option value="1">1</option>  
                                                    <option value="2">2</option>  
                                                    <option value="3">3</option>  
                                                    <option value="4">4</option>  
                                                    <option value="5">5</option>  
                                                    <option value="unlimited">무제한</option>  
                                                </select>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="random-arrangement-op02" name="type_option" value="arrangement">
                                                <label for="random-arrangement-op02">옵션 보기 무작위 배열</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 복수형 -->

                                        <!-- 객관식 표-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="graph-multiple-op01-g" name="type_option" value="graph-multiple">
                                                <label for="graph-multiple-op01-g">복수 선택</label>
                                            </p>
                                        </li>
                                        <!-- //객관식 표-->

                                        <!-- 날짜 / 시간-->
                                        <li class="tpOption graph-choice-type-op">
                                            <p>
                                                <input type="checkbox" id="date-time-op01-g" name="type_option" value="date-time">
                                                <label for="date-time-op01-g">시간 포함</label>
                                            </p>
                                        </li>
                                        <!-- //날짜 / 시간-->
                                    </ul>
                                </div>

                                <!-- 유형에 따라 보이는 설문 작성 -->
                                <div class="typeQuestion">
                                    <div class="qst_title">
                                        <div class="pg_title">
                                            <input type="text" id="sur_type_qst_tit" placeholder="질문을 입력하세요" class="input_text">
                                            <span class="qstImgSpan">
                                                <label for="cdImg" id="image_label" class="" title="이미지 가져오기">
                                                    <p class="img_bring_ic">이미지 가져오기</p>
                                                </label>
                                                <input type="file" id="cdImg" accept="image/png, image/jpeg" class="cdInput">
                                            </span>
                                            <input type="text" id="sur_type_qst__sub_title" placeholder="(선택 사항) 질문에 대한 설명을 입력하세요" class="input_text input_sub_text">
                                        </div>
                                    </div>

                                    <div class="qst_option_wrap mt20">
                                        <ul class="qst_opt_div choice_qst_opt">
                                            <li class="choice-qst-list dttime-qst-list">
                                                <div class="choice-qst-list-con dttime-qst-list-con dttime_date_list">
                                                    <input type="button" id="s_eventFrYmd" value="년/월/일" class="qst_input_text tel_input fl input_gray hasDatepicker">
                                                    <div class="btn_iocn_div">
                                                        <button class="dttime-btn">
                                                            <i class="dttime-icon date_ic"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                            <!-- 옵션에 시간 포함 체크시 on 클래스 추가 -->
                                            <li class="choice-qst-list dttime-qst-list dttime_time_list on">
                                                <div class="choice-qst-list-con dttime-qst-list-con">
                                                    <input type="button" id="s_FrTm" value="시간선택" class="qst_input_text tel_input fl input_gray on">
                                                    <div class="chooseTime from on">
                                                        <div class="selectboxWrap">
                                                            <label for="s_frAmpm">오후</label>
                                                            <select id="s_frAmpm" title="오전 오후 선택">
                                                                <option value="AM">오전</option>
                                                                <option value="PM">오후</option>
                                                            </select>
                                                        </div>
                                                        <div class="selectboxWrap">
                                                            <label for="s_frHour">04</label>
                                                            <select id="s_frHour" title="시간선택">
                                                                <option value="12"> 12 </option>
                                                                <option value="01"> 01 </option>
                                                                <option value="02"> 02 </option>
                                                                <option value="03"> 03 </option>
                                                                <option value="04"> 04 </option>
                                                                <option value="05"> 05 </option>
                                                                <option value="06"> 06 </option>
                                                                <option value="07"> 07 </option>
                                                                <option value="08"> 08 </option>
                                                                <option value="09"> 09 </option>
                                                                <option value="10"> 10 </option>
                                                                <option value="11"> 11 </option>
                                                            </select>
                                                        </div>
                                                        <div class="selectboxWrap">
                                                            <label for="s_frMin">00</label>
                                                            <select id="s_frMin" title="분 선택">
                                                                <option value="00"> 00 </option>
                                                                <option value="10"> 10 </option>
                                                                <option value="20"> 20 </option>
                                                                <option value="30"> 30 </option>
                                                                <option value="40"> 40 </option>
                                                                <option value="50"> 50 </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="btn_iocn_div">
                                                        <button class="dttime-btn">
                                                            <i class="dttime-icon time_ic"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- //설문 편집 -->

            <!-- 설문 보내기 -->
            <div class="tab-content SurveySend">
                <div class="tableDefault tableBorder th-right sendSectionDiv">
                    <div class="secContainer">
                        <table cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr id="titleTr" class="forRel">
                                    <th>
                                        <label for="" title="제목">일정제목</label>
                                    </th>
                                    <td>
                                        <input type="text" id="s_mainTitle" placeholder="무료 체험단 설문조사" autocomplete="off" spellcheck="false" class="input_text" disabled>
                                    </td>
                                    
                                </tr>
                                <tr id="titleExTr" class="">
                                    <th>
                                        <label for="" title="제목">제목 설명</label>
                                    </th>
                                    <td>
                                        <!-- 높이 자동조절 가능하면 해주세요 최대 3줄이후에는 스크롤 -->
                                        <textarea id="autoTextarea" rows="2" placeholder="(선택 사항) 설명을 입력하세요" maxLength="1200" name="titleExTa" readonly>
                                            이번에 새로 추가된 설문지 기능을 무료로 사용해볼 수 있는 체험단을 모집합니다.
                                            아래 질문에 답해주시면 자동 응모 되며, 선정된 체험단 발표는 기간이 끝난 후 2023년 2월 28일 개별 통지 드립니다.
                                        </textarea>
                                    </td>
                                </tr>
                                <tr id="timeTr" class="enabled">
                                    <th>
                                        <label title="시간">시간</label>
                                    </th>
                                    <td class="timeTd">
                                        <div class="inputgroup">
                                            <input type="button" id="s_eventFrYmd" value="2023.01.30 월" class="input_txt fl input_gray radius04 hasDatepicker">
                                            <input type="button" id="s_FrTm" value="오후 12:00" class="input_txt fl input_gray radius04">
                                            <div class="chooseTime from">
                                                <div class="selectboxWrap">
                                                    <label for="s_frAmpm">오후</label>
                                                    <select id="s_frAmpm" title="오전 오후 선택">
                                                        <option value="AM">오전</option>
                                                        <option value="PM">오후</option>
                                                    </select>
                                                </div>
                                                <div class="selectboxWrap">
                                                    <label for="s_frHour">12</label>
                                                    <select id="s_frHour" title="시간선택">
                                                        <option value="12"> 12 </option>
                                                        <option value="01"> 01 </option>
                                                        <option value="02"> 02 </option>
                                                        <option value="03"> 03 </option>
                                                        <option value="04"> 04 </option>
                                                        <option value="05"> 05 </option>
                                                        <option value="06"> 06 </option>
                                                        <option value="07"> 07 </option>
                                                        <option value="08"> 08 </option>
                                                        <option value="09"> 09 </option>
                                                        <option value="10"> 10 </option>
                                                        <option value="11"> 11 </option>
                                                    </select>
                                                </div>
                                                <div class="selectboxWrap">
                                                    <label for="s_frMin">00</label>
                                                    <select id="s_frMin" title="분 선택">
                                                        <option value="00"> 00 </option>
                                                        <option value="10"> 10 </option>
                                                        <option value="20"> 20 </option>
                                                        <option value="30"> 30 </option>
                                                        <option value="40"> 40 </option>
                                                        <option value="50"> 50 </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <span class="inputgroup_txt01 arw">에서</span>
                                            <input type="button" id="s_eventToYmd" value="2023.02.27 월" class="input_txt fl input_gray radius04 hasDatepicker">
                                            <input type="button" id="s_ToTm" value="오후 01:00" class="input_txt fl input_gray radius04">
                                            <div class="chooseTime to">
                                                <div class="selectboxWrap">
                                                    <label for="s_toAmpm">오후</label>
                                                    <select id="s_toAmpm" title="오전 오후 선택">
                                                        <option value="AM">오전</option>
                                                        <option value="PM">오후</option>
                                                    </select>
                                                </div>
                                                <div class="selectboxWrap">
                                                    <label for="s_toHour">01</label>
                                                    <select id="s_toHour" title="시간 선택">
                                                        <option value="12"> 12 </option>
                                                        <option value="01"> 01 </option>
                                                        <option value="02"> 02 </option>
                                                        <option value="03"> 03 </option>
                                                        <option value="04"> 04 </option>
                                                        <option value="05"> 05 </option>
                                                        <option value="06"> 06 </option>
                                                        <option value="07"> 07 </option>
                                                        <option value="08"> 08 </option>
                                                        <option value="09"> 09 </option>
                                                        <option value="10"> 10 </option>
                                                        <option value="11"> 11 </option>
                                                    </select>
                                                </div>
                                                <div class="selectboxWrap">
                                                    <label for="s_toMin">00</label>
                                                    <select id="s_toMin" title="분 선택">
                                                        <option value="00"> 00 </option>
                                                        <option value="10"> 10 </option>
                                                        <option value="20"> 20 </option>
                                                        <option value="30"> 30 </option>
                                                        <option value="40"> 40 </option>
                                                        <option value="50"> 50 </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- 설문 편집으로 가기 -->
                    <a href="#" title="설문 편집하기" class="goDets">설문 편집하기</a>
                </div>

                <div class="SendOption sendSectionDiv">
                    <!-- 기본 활성화 / 보내기 클릭 후 off 클래스 추가하여 숨기기(오픈) -->
                    <div class="SendOptionScreen off">
                        <p class="sendOpSrText">작성 된 설문을 한번 더 확인 후 보내기를 클릭하시면 배포 방법을 제공해 드립니다.</p>
                        <span>&#42; 보내기가 시작되면 현재 설문은 편집 불가능 합니다.</span>
                    </div>
                    <ul class="sendTabUl">
                        <li class="send-tab-list01 on">
                            <i class="snTabListIc snIc-send01"></i><span class="snTabListTi">URL 링크</span>
                        </li>
                        <li class="send-tab-list02">
                            <i class="snTabListIc snIc-send02"></i><span class="snTabListTi">QR코드</span>
                        </li>
                        <li class="send-tab-list03">
                            <i class="snTabListIc snIc-send03"></i><span class="snTabListTi">메신저 공유</span>
                        </li>
                    </ul>
                    <div class="sendTabConBox">
                        <div class="sn-tab-con sn-tab-con-link">
                            <p>아래 URL 링크를 복사해서 배포하세요</p>
                            <div class="link_box">
                                <input type="text" class="sendSecUrl-input" aria-label="공유 링크" tabindex="0" onfocus="this.select()" value="https://web.hodu.app/f/5EtTSu920S" readonly>
                                <input type="button" value="복사" class="input_btn">
                            </div>
                        </div>
                        <div class="sn-tab-con sn-tab-con-qrcord">
                            <p>아래 이미지를 클릭하면 PNG 파일로 내려받으실 수 있습니다. </p>
                            <div class="qrcord_box">
                                <a href="#" class="qrcordDw" download>
                                    <button class="qrImg">QR코드 다운로드</button>
                                </a>
                            </div>
                        </div>
                        <div class="sn-tab-con sn-tab-con-messenger">
                            <p>다양한 메신저로 설문 보내기가 가능합니다.</p>
                            <div class="messenger_box">
                                <ul class="bt_shareBox">
                                    <li class="share_btn">
                                        <input type="button" id="kakao_link_btn" class="bt_share01">
                                    </li>
                                    <li class="share_btn">
                                        <input type="button" class="bt_share02">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //설문 보내기 -->

            <!-- 설문 결과 -->
            <div class="tab-content SurveyResult">
                <div class="result_header_nav">
                    <div class="header_nav_containar">
                        <div class="rsTitleBox">
                            <h3 class="surTitle">무료 체험단 설문조사</h3>
                            <div class="rsTextBox">
                                <p class="sur-period-time">
                                    2023.01.30 월<span>~</span>2023.02.27 월
                                </p>
                                <div class="divisionDiv"></div>
                                <p class="sur-answers-num">
                                    답변 <span>3</span>개
                                </p>
                            </div>
                        </div>
                        <nav class="rsTabBox">
                            <ul class="sendTabUl resultTabUl">
                                <li class="result-tab-list01 on"><span class="snTabListTi rsTabListTi">요약</span></li>
                                <li class="result-tab-list02"><span class="snTabListTi rsTabListTi">개별 답변</span></li>
                            </ul>
                            <a href="#" class="lengthMenu">메뉴보기</a>
                            <ul class="ResultMenu menuOpUl">
                                <li><a>응답 다운로드</a></li>
                                <li><a>모든 응답 삭제</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="result_content">
                    <!-- 결과 요약 -->
                    <div class="srContWrap summaryWrap">
                        <div class="sr-tab-con sr-tab-con-summary">
                            <div class="surText_top_bar"></div>
                            <div class="sr-con-text">

                                <!-- 주관식 단일/장문 -->
                                <div class="sr-type-box text-result-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">ID 또는 닉네임을 적어주세요 <span class="essential on">*</span></p>
                                        <p class="result-num">답변 <span>3</span>개</p>
                                    </div>
                                    <div class="srConAnswers">
                                        <ul>
                                            <li><p>이참여</p></li>
                                            <li><p>노는게좋아</p></li>
                                            <li><p>아이디뭘로하지</p></li>
                                        </ul>
                                    </div>
                                </div>

                                <!--
                                    원형 그래프 
                                    (객관식 단일 / 드롭다운)
                                -->
                                <div class="sr-type-box pie-result-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">연령대를 알려주세요</p>
                                        <p class="result-num">답변 <span>2</span>개</p>
                                    </div>
                                    <!-- 그래프 -->
                                    <div class="result_graph">
                                        <div id="pie_graph_info" class="graph_info">
                                            <svg class="pie resultPie">
                                                <circle date-percent="10"></circle>
                                                <circle date-percent="20"></circle>
                                                <circle date-percent="30"></circle>
                                                <circle date-percent="40"></circle>
                                                <circle date-percent="50"></circle>
                                            </svg>
                                            <ul class="pie_info">
                                                <li>
                                                    <span class="colorDot"></span>
                                                    <span class="pic_text">10대</span>
                                                </li>
                                                <li>
                                                    <span class="colorDot"></span>
                                                    <span class="pic_text">20대</span>
                                                </li>
                                                <li>
                                                    <span class="colorDot"></span>
                                                    <span class="pic_text">30대</span>
                                                </li>
                                                <li>
                                                    <span class="colorDot"></span>
                                                    <span class="pic_text">40대</span>
                                                </li>
                                                <li>
                                                    <span class="colorDot"></span>
                                                    <span class="pic_text">50대</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <!--
                                    세로형 막대 그래프 
                                    (객관식 복수 / 선호도형)
                                -->
                                <div class="sr-type-box vtcal-bar-result-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">저희 앱을 알게된 경로를 알려주세요 <span class="essential on">*</span></p>
                                        <p class="result-num">답변 <span>7</span>개</p>
                                    </div>
                                    <!-- 그래프 -->
                                    <div class="result_graph">
                                        <div id="vtcal_bar_graph_info" class="graph_info">
                                            <div class="grid_range">
                                                <span class="range">
                                                    <span>4</span>
                                                </span>
                                                <span class="range">
                                                    <span>3</span>
                                                </span>
                                                <span class="range">
                                                    <span>2</span>
                                                </span>
                                                <span class="range">
                                                    <span>1</span>
                                                </span>
                                                <span class="range">
                                                    <span>0</span>
                                                </span>
                                            </div>
                                            <div class="graph_range">
                                                <!-- 선택한 답변이 있다면 areaOn 클래스 추가 -->
                                                <div class="wrap_graph">
                                                    <em class="tit_graph">웹 사이트</em>
                                                    <div class="graph">
                                                        <span class="inner_graph">
                                                            <span class="txt_value" style="bottom: 0%;">0 (0%)</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="wrap_graph areaOn">
                                                    <em class="tit_graph">구글플레이 스토어 또는 앱 스토어</em>
                                                    <div class="graph">
                                                        <span class="inner_graph" style="height: 20%;">
                                                            <span class="txt_value">1 (20%)</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="wrap_graph areaOn">
                                                    <em class="tit_graph">행사 및 광고</em>
                                                    <div class="graph">
                                                        <span class="inner_graph" style="height: 80%;">
                                                            <span class="txt_value">4 (80%)</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="wrap_graph areaOn">
                                                    <em class="tit_graph">지인의 소개</em>
                                                    <div class="graph">
                                                        <span class="inner_graph" style="height: 20%;">
                                                            <span class="txt_value">1 (20%)</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="wrap_graph areaOn">
                                                    <em class="tit_graph">기타 (직접입력)</em>
                                                    <div class="graph">
                                                        <span class="inner_graph" style="height: 20%;">
                                                            <span class="txt_value">1 (20%)</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--
                                    세로형 막대 그래프 
                                    (객관식 표)
                                -->
                                <div class="sr-type-box vtcal-bar-mult-result-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">앱을 사용해보니 어떠한가요? <span class="essential on">*</span></p>
                                        <p class="result-num">답변 <span>3</span>개</p>
                                    </div>
                                    <!-- 그래프 -->
                                    <div class="result_graph">
                                        <div id="vtcal_bar_graph_info" class="graph_info">
                                            <div class="graph_list_range">
                                                <ul>
                                                    <li>
                                                        <span class="barColor clorOp01"></span>
                                                        <span class="bar_list_text">매우 별로다</span>
                                                    </li>
                                                    <li>
                                                        <span class="barColor clorOp02"></span>
                                                        <span class="bar_list_text">별로다</span>
                                                    </li>
                                                    <li>
                                                        <span class="barColor clorOp03"></span>
                                                        <span class="bar_list_text">보통이다</span>
                                                    </li>
                                                    <li>
                                                        <span class="barColor clorOp04"></span>
                                                        <span class="bar_list_text">좋다</span>
                                                    </li>
                                                    <li>
                                                        <span class="barColor clorOp05"></span>
                                                        <span class="bar_list_text">매우 좋다</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="grid_range">
                                                <!-- <span class="range">
                                                    <span>3</span>
                                                </span>
                                                <span class="range">
                                                    <span>2</span>
                                                </span> -->
                                                <span class="range">
                                                    <span>1</span>
                                                </span>
                                                <span class="range">
                                                    <span>0</span>
                                                </span>
                                            </div>
                                            <div class="graph_range">
                                                <!-- 선택한 답변이 있다면 areaOn 클래스 추가 -->
                                                <div class="wrap_graph">
                                                    <em class="tit_graph">체험단 주기는 어떤가요?</em>
                                                    <div class="graph">
                                                        <span class="inner_graph barColor clorOp01"></span>
                                                        <span class="inner_graph clorOp02"></span>
                                                        <span class="inner_graph clorOp03"></span>
                                                        <span class="inner_graph clorOp04"></span>
                                                        <span class="inner_graph clorOp05"></span>
                                                    </div>
                                                </div>
                                                <div class="wrap_graph areaOn">
                                                    <em class="tit_graph">사용하기 편리한가요?</em>
                                                    <div class="graph">
                                                        <span class="inner_graph barColor clorOp01"></span>
                                                        <span class="inner_graph barColor clorOp02"></span>
                                                        <span class="inner_graph barColor clorOp03"></span>
                                                        <span class="inner_graph barColor clorOp04" style="height: 50%;"></span>
                                                        <span class="inner_graph barColor clorOp05"></span>
                                                    </div>
                                                </div>
                                                <div class="wrap_graph areaOn">
                                                    <em class="tit_graph">앱의 속도는 어떠한가요?</em>
                                                    <div class="graph">
                                                        <span class="inner_graph barColor clorOp01"></span>
                                                        <span class="inner_graph barColor clorOp02" style="height: 50%;"></span>
                                                        <span class="inner_graph barColor clorOp03" style="height: 50%;"></span>
                                                        <span class="inner_graph barColor clorOp04"></span>
                                                        <span class="inner_graph barColor clorOp05"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 날짜 / 시간 -->
                                <div class="sr-type-box qust-dttime-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">앱 사용자 교육에 참석하실 수 있는 날짜를 작성해주세요 <span class="essential">*</span></p>
                                        <p class="result-num">답변 <span>2</span>개</p>
                                    </div>
                                    <div class="qustConAnswers mt20">
                                        <div class="dttime_opt_wrap">
                                            <p class="dttime-date">2023.05.01</p>
                                            <!-- 시간포함 체크 후 작성하였을 경우 on -->
                                            <p class="dttime-time on"><span>오전</span>10:00</p>
                                        </div>
                                        <div class="dttime_opt_wrap">
                                            <p class="dttime-date">2023.04.14</p>
                                            <p class="dttime-time"><span>오전</span>10:00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 결과 개별 -->
                    <div class="srContWrap individualWrap">
                        <div class="answer-page-time">
                            <div class="individual-aw-pg-nav">
                                <input type="button" id="btnPrev" class="toolbar-btn bt-prev pg-arrow-left">
                                <div class="pgNumBox">
                                    <div class="inv-aw-pg-num aw-pg-num-first">1</div>
                                    <span class="divisionSlash">&#47;</span>
                                    <div class="inv-aw-pg-num aw-pg-num-last">3</div>
                                </div>
                                <input type="button" id="btnNext" class="toolbar-btn bt-next pg-arrow-right">
                            </div>
                            <div class="individual-aw-time">
                                2023-02-10 11:12:32 <span>제출 됨</span>
                            </div>
                        </div>
                        <div class="sr-tab-con sr-tab-con-individual">
                            <div class="surText_top_bar"></div>
                            <div class="sr-con-text">
                                <!-- 주관식 단일/장문 -->
                                <div class="sr-type-box text-result-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">ID 또는 닉네임을 적어주세요 <span class="essential on">*</span></p>
                                        <p class="qustQstSub on">이벤트 관련 메일을 받기위해서는 정확한 ID를 적어주셔야 합니다</p>
                                    </div>
                                    <div class="srConAnswers">
                                        <ul>
                                            <li><p>이참여</p></li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- 드롭다운 -->
                                <div class="sr-type-box one-result-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">연령대를 알려주세요</p>
                                    </div>
                                    <div class="srConAnswers">
                                        <div class="selectBg">
                                            <div class="selectTitle">
                                                30대
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 객관식 복수 -->
                                <div class="sr-type-box mult-result-form type-box-mult">
                                    <div class="srConTitle">
                                        <p class="resultConQst">저희 앱을 알게된 경로를 알려주세요 <span class="essential on">*</span></p>
                                        <p class="qustQstSub on">최대 3개까지 선택가능 합니다</p>
                                    </div>
                                    <!-- 이미지 -->
                                    <div class="survey_img_add qst_title_img on">
                                        <p class="qst_img">질문 이미지</p>
                                    </div>
                                    <div class="qst_option_wrap choice-qst-list-con">
                                        <!-- 단일형 op_chk_show_one / 복수형 op_chk_show_mult 클래스 추가 -->
                                        <i class="op_chk_show op_chk_show_mult result_chk_show"></i>
                                        <span class="ex-option-text">행사 및 광고</span>
                                    </div>
                                    <div class="qst_option_wrap choice-qst-list-con">
                                        <i class="op_chk_show op_chk_show_mult result_chk_show"></i>
                                        <span class="ex-option-text">구글플레이 스토어 또는 앱스토어</span>
                                    </div>
                                    <div class="opt-etc-input-list">
                                        <div class="etc-qst-list-con">
                                            광고 보고 일정어플 검색하다가 봤어요
                                        </div>
                                    </div>
                                </div>

                                <!-- 객관식 단일 -->
                                <div class="sr-type-box one-result-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">앱에대한 지식은 어느정도 인가요?</p>
                                        <p class="qustQstSub"></p>
                                    </div>
                                    <div class="qst_option_wrap choice-qst-list-con">
                                        <i class="op_chk_show op_chk_show_one result_chk_show"></i>
                                        <span class="ex-option-text">처음본다</span>
                                        <!-- 이미지 -->
                                        <div class="survey_img_add qst_title_img on">
                                            <p class="qst_img">질문 이미지</p>
                                        </div>
                                    </div>
                                </div>

                                <!-- 객관식 표 (단일) -->
                                <div class="sr-type-box qust-grpcho-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">체험의 소감은 어떠한가요? <span class="essential on">*</span></p>
                                        <p class="qustQstSub"></p>
                                    </div>
                                    <div class="qustConAnswers mt20">
                                        <div class="grpcho_opt_wrap">
                                            <div class="grpcho_opt_top">
                                                <p class="gpTableP grpcho_op_first_p none_space">공간</p>
                                                <p class="gpTableP">매우 별로다</p>
                                                <p class="gpTableP">별로다</p>
                                                <p class="gpTableP">보통이다</p>
                                                <p class="gpTableP">좋다</p>
                                                <p class="gpTableP">매우 좋다</p>
                                            </div>
                                            <div class="gpChoDiv">
                                                <span class="gpRowBox">
                                                    <p class="gpTableP grpcho_op_first_p">체험단 주기는 어떤가요?</p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                </span>
                                            </div>
                                            <div class="gpChoDiv">
                                                <span class="gpRowBox">
                                                    <p class="gpTableP grpcho_op_first_p">사용하기 편리한가요?</p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                </span>
                                            </div>
                                            <div class="gpChoDiv">
                                                <span class="gpRowBox">
                                                    <p class="gpTableP grpcho_op_first_p">앱의 속도는 어떠한가요?</p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 객관식 표 (복수) qust-grpcho-mult-form 클래스 추가-->
                                <div class="sr-type-box qust-grpcho-form qust-grpcho-mult-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">앱에 관련하여 각 질문에 해당하는 답변을 모두 선택 해주세요 <span class="essential on">*</span></p>
                                        <p class="qustQstSub"></p>
                                    </div>
                                    <div class="qustConAnswers mt20">
                                        <div class="grpcho_opt_wrap">
                                            <div class="grpcho_opt_top">
                                                <p class="gpTableP grpcho_op_first_p none_space">공간</p>
                                                <p class="gpTableP">일정</p>
                                                <p class="gpTableP">채팅 및 화상채팅</p>
                                                <p class="gpTableP">웍스기능</p>
                                                <p class="gpTableP">소모임</p>
                                            </div>
                                            <div class="gpChoDiv">
                                                <span class="gpRowBox">
                                                    <p class="gpTableP grpcho_op_first_p">사용하면서 주 목적이 되는 부분은?</p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                </span>
                                            </div>
                                            <div class="gpChoDiv">
                                                <span class="gpRowBox">
                                                    <p class="gpTableP grpcho_op_first_p">사용하기 가장 어려웠던 부분은?</p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                </span>
                                            </div>
                                            <div class="gpChoDiv">
                                                <span class="gpRowBox">
                                                    <p class="gpTableP grpcho_op_first_p">아쉬웠던 부분은?</p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                    <p class="gpTableP">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_mult"></i>
                                                        </span>
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 선호도형 -->
                                <div class="sr-type-box qust-prfrn-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">앱의 만족도는 어느정도인가요? <span class="essential on">*</span></p>
                                        <p class="qustQstSub"></p>
                                    </div>
                                    <div class="qustConAnswers mt20">
                                        <div class="prfrn_opt_wrap">
                                            <div class="prfrn_opt_div">
                                                <div class="prfrn-opt-ex prfrn-opt-left">
                                                    <p class="prfrnOptP none_space">공간</p>
                                                    <p class="prfrnOptP prfrn-text-p prfrn-left-text">별로 만족하지 않음</p>
                                                </div>
                                                <div class="prfrn-opt-center">
                                                    <p class="prfrnOptP prfrn-op-num">1</p>
                                                    <p class="prfrnOptP prfrn-center-select">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="prfrn-opt-center">
                                                    <p class="prfrnOptP prfrn-op-num">2</p>
                                                    <p class="prfrnOptP prfrn-center-select">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="prfrn-opt-center">
                                                    <p class="prfrnOptP prfrn-op-num">3</p>
                                                    <p class="prfrnOptP prfrn-center-select">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="prfrn-opt-center">
                                                    <p class="prfrnOptP prfrn-op-num">4</p>
                                                    <p class="prfrnOptP prfrn-center-select">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one result_chk_show"></i>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="prfrn-opt-center">
                                                    <p class="prfrnOptP prfrn-op-num">5</p>
                                                    <p class="prfrnOptP prfrn-center-select">
                                                        <span class="gpInputWrap">
                                                            <i class="op_chk_show op_chk_show_one"></i>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="prfrn-opt-ex prfrn-opt-right">
                                                    <p class="prfrnOptP none_space">공간</p>
                                                    <p class="prfrnOptP prfrn-text-p prfrn-right-text">아주 만족</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 날짜 / 시간 -->
                                <div class="sr-type-box qust-dttime-form">
                                    <div class="srConTitle">
                                        <p class="resultConQst">앱 사용자 교육에 참석하실 수 있는 날짜를 작성해주세요 <span class="essential">*</span></p>
                                        <p class="qustQstSub"></p>
                                    </div>
                                    <div class="qustConAnswers mt20">
                                        <div class="dttime_opt_wrap">
                                            <p class="dttime-date">2023.05.01</p>
                                            <!-- 시간포함 체크 후 작성하였을 경우 on -->
                                            <p class="dttime-time on"><span>오전</span>10:00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 설문 결과 -->
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupSurveyChoiceListModalInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupSurvey extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupSurveyChoiceListModalInfo ?: (params : GroupSurveyChoiceListModalInfo) => void;

    mounted() : void {
        //tab page
        $('#TopTab h2').click (function(){
            var contentIndex = $(this).index();
            $('.tab-content').hide();
            $('.tab-content').eq(contentIndex).show();
            $(this).addClass('active').siblings().removeClass();

            return false;
        });

        $('.sendTabUl > li').click (function(){
            var contentIndex = $(this).index();
            $('.sn-tab-con').hide();
            $('.sn-tab-con').eq(contentIndex).show();
            $(this).addClass('on').siblings().removeClass();

            return false;
        });

        $('.resultTabUl > li').click (function(){
            var contentIndex = $(this).index();
            $('.srContWrap').hide();
            $('.srContWrap').eq(contentIndex).show();
            $(this).addClass('on').siblings().removeClass();

            return false;
        });

        //toggleClass
        $("#typeSelBox").click(function(){
            $(".selectUl").toggleClass("on");
        });

        $("#moveSelBox").click(function () {
			$(this).siblings('ul').toggle();
		});

        //hover
        $('.tooltipSpan').hover(function(){
            $('.requiredToolBox').css("display", "block");
        }, function(){
            $('.requiredToolBox').css("display", "none");
        });

        //원형 그래프
        $(document).ready(function(){
            pieAct();
        })

        function pieAct(){
            var color = ["#477fff","#ff198b","#ffa70e","#4dbaff","#13d08b"]; //그래프 색상
            var angel = -90; //그래프 시작 지점
            var pieWidth = $('.pie').width(); 

            $('.pie circle').each(function(i){
                var percentData = $(this).data('percent'); //그래프 비율
                var perimeter = ((pieWidth??0)/2) * 3.14; //원의 둘레
                var percent =  percentData*(perimeter/100); //그래프 비율만큼 원의 둘레 계산
                
                //그래프 비율, 색상 설정
                $(this).css({
                    'stroke-dasharray':percent+' '+perimeter, 
                    'stroke':color[i],
                    'transform':'rotate('+angel+'deg)'
                });
                $('.pie_info > li').eq(i).find('.colorDot').css({'background':color[i]});
                
                //그래프 시작 지점 갱신
                angel += (percentData * 3.6); 
            })
        }
    }

    showChoiceListModal() {
        this.doSetGroupSurveyChoiceListModalInfo?.({
            show_modal : true,
            data : {}
        });
    }

    /**
     * 리사이즈 감지
     */
     handleResize() : void {
        // // @ts-ignore
        // $('#group_member_scroll').mCustomScrollbar('destroy');
        
    	// this.setScroll();
    }

}
</script>

<style scoped>
    #group_survey_create button { border: none; cursor: pointer; }
    .title_box { display: flex; justify-content: space-between; background: #fff; }
    .list_back { display: inline-block; float: left; margin-right: 2px; width: 50px; height: 40px; transition: 0.1s; background: #ffffff url('../assets/images/contents/survey_ic_list.png') no-repeat center left; background-size: 27px; margin-top: 10px; }
    .list_back:after { content: ''; display: inline-block; width: 2px; height: 30px; background: #dbdfe0; font-size: 0; float: right; margin-top: 5px; }
    #TopTab { display: flex; }
    #TopTab a { padding: 0 20px; display: inline-block; font-size: 14px; font-weight: normal; }
    #TopTab a:hover { font-weight: bold; }
    #TopTab h2.active a { color: #477fff; font-weight: bold; }
    .notClickTab { pointer-events: none; opacity: .7; }
    .tab-content { display: none; }
    .tab-content:first-child { display: block; }
    #container #section_ce .SurveyEdit .schedule_box.writeSche { width: 100% !important; background: #fff; padding-left: 0px; padding-right: 0px; max-width: none; box-sizing: border-box; }
    .schedule_box .tableDefault.tableBorder.th-right { padding-bottom: 0; padding-left: 20px; padding-right: 20px; width: 900px; margin-left: 45px; box-sizing: border-box; }

    /* #container #section_ce .schedule_box.writeSche { padding-left: 60px; } */
    #group_survey_scroll #s_mainTitle { width: 95%; height: 80px; line-height: 80px; font-size: 25px; font-weight: bold; }
    .tab-content tr td:after { content: ''; position: absolute; display: block; width: 0; height: 2px; background: #d2d7e2; bottom: -1px; z-index: 1; left: 0; transition: 0.2s; }
    .tab-content tr:hover td:after, .tab-content tr.on td:after { width: 100%; transition: 0.2s; }
    #group_survey_scroll .inputgroup .arw { height: 60px; display: inline-block; width: 70px; top: 0; font-size: 0; margin-left: 0 !important; background: url('../assets/images/contents/img_time_arw.png') center center no-repeat; }
    #group_survey_scroll .inputgroup .input_txt { text-align: left; font-size: 14px; font-weight: bold; height: 60px; line-height: 60px; color: #434343; background-color: none !important; border: 0 none; margin-left: 0 !important; width: 120px !important; }
    #group_survey_scroll .inputgroup input#s_FrTm, #group_survey_scroll .inputgroup input#s_ToTm { width: 70px !important; margin-left: -3px !important; }
    #titleExTr textarea { width: 100%; resize: none; font-size: 13px; line-height: 18px; outline: none; border: none; color: #232848; opacity: .6; font-weight: 600; margin-bottom: 6px; }

     /* 시간선택 */
    .chooseTime {display:none !important;position: absolute;width: auto;height: 50px;left: 113px;background: #fff;top: 61px;border-radius: 5px;box-shadow: 0 5px 10px rgba(0,0,0,0.15);z-index: 10000000;}
    .chooseTime.on { display:block !important; }
    .chooseTime .selectboxWrap {width: auto;background: #f1f3f5;padding: 0;position: relative;width: 80px !important;height: 50px;text-align: left;float: left;background: url(../assets/images/side_area/bt_open.png) no-repeat 49px center !important;line-height: 50px;border-right: 1px solid #f1f3f5;}
    .chooseTime .selectboxWrap label {position: absolute;left: 0;width: 100% !important;padding-left: 15px;font-size:13px !important;box-sizing: border-box;padding-right: 10px;}
    .chooseTime .selectboxWrap option {text-align: center;padding-left: 10px;}
    .chooseTime .selectboxWrap select { width:80px !important; }
    .chooseTime .selectboxWrap:hover label {color:#477fff;}
    .chooseTime.to {left: 370px;}

    /* 편집 */
    .surveyViewer { display: flex; flex-direction: column; background: #fbfcfd; max-width: 1280px; min-height: 650px; padding: 20px 70px 30px 70px; box-sizing: border-box; }
    .sur_page_title { border-bottom: 3px solid #477fff; }
    /* 편집 페이지 head */
    .surveyViewer_head { height: 50px; line-height: 50px; display: flex; justify-content: space-between; align-items: center; box-sizing: border-box; }
    .page_nav { display: flex; align-items: center; }
    .page_nav_div input { width: 35px; height: 35px; border-radius: 50%; margin-top: 0px !important; vertical-align: middle; }
    .toolbar-btn:hover { background-color: #f1f3f5; }
    .bt-prev { background: url('../assets/images/contents/month_bt_prev.svg') no-repeat 3px; }
    .bt-next { background: url('../assets/images/contents/month_bt_next.svg') no-repeat 3px; }
    .pg_count_ul { display: flex; line-height: 35px; }
    .page_num_text { width: 35px; height: 35px; display: inline-block; text-align: center; border-radius: 50%; color: #232548; font-weight: 600; cursor: pointer; }
    .page_num_text.page_on { background-color: #477fff; color: #fff; }

    .page_ctrl { display: flex; align-items: center; position: relative; }
    .requiredCheck { display: flex; position: relative; }
    .requiredCheck p { font-size: 14px; font-weight: normal; display: inline-block; line-height: 35px; margin-right: 8px; box-sizing: border-box; }
    .requiredCheck p > span { width: 20px; height: 20px; line-height: 20px; display: inline-block; background: #d0d1d3; border-radius: 50%; color: #fff; font-size: 12px; text-align: center; vertical-align: middle; margin-right: 5px; }
    .requiredCheck p > span:hover { background: #485367; }
    .requiredToolBox { display: none; width: 450px; min-width: 170px; z-index: 1111; background: #485367; color: #fff; line-height: 20px; position: absolute; top: -45px; left: -135px; padding: 5px 10px;; box-sizing: border-box; text-align: center; border-radius: 8px; box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 15%); transition: .2s; }
    .requiredCheck .switch { vertical-align: middle; margin-top: 6px; }
    /* 항목추가 */
    .itemAdd { width: 35px; height: 45px; box-sizing: border-box; margin-left: 20px; }
    .itemAdd .itm_ic { display: block; width: 23px; height: 23px; background-repeat: no-repeat; background-size: cover; background-position: center; vertical-align: middle; margin-top: 11px; }
    .itemAdd .itm_ic.ImAddBtn { background-image: url('../assets/images/contents/add_circle.png'); }
    .itemAdd .itm_ic.ImCopyBtn { background-image: url('../assets/images/contents/item_copy.png'); }
    .itemAdd .itm_ic.ImDelBtn { background-image: url('../assets/images/contents/item_del.png'); }
    /* 페이지 메뉴 */
    .divisionDiv { border-left: 2px solid #d2d7e2; height: 32px; margin: 0 16px; width: 0; }

    /* 이메일 수집 */
    .email-collec { display: none; }
    .email-collec.on { display: block; }
    .email-collec .srConTitle { padding: 0 25px; box-sizing: border-box; } 
    /* 편집 페이지 body */
    .surveyViewer_body { padding: 50px 30px; box-sizing: border-box; background: #fff; margin-top: 20px; border-radius: 10px; border: 2px solid #f1f3f5; position: relative; min-width: 900px; }
    .surText_top_bar { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 15px; background: #477fff; border-radius: 5px 5px 0 0; }
    .surveyViewer .input_text { width: 95%; height: 50px; line-height: 50px; font-size: 15px; border-bottom: 1px solid #f3f3f3; text-indent: 15px; }
    .surveyViewer .input_sub_text { height: 40px; line-height: 40px; font-size: 13px; border-bottom: 1px solid #f3f3f3; text-indent: 25px; }
    .surTypeBox { display: flex; flex-direction: column; border-bottom: 1px solid #f2f4f6; padding-bottom: 30px; }
    .surTypeBox:last-child { border-bottom: none; padding-bottom: 0px; }
    .selectBg { display: flex; width: 100%; cursor: auto; justify-content: space-between; align-items: center; line-height: 40px; }
    .selectBg .selectTitle { cursor: pointer; border: 1px solid #f3f3f3; padding: 0 20px; box-sizing: border-box; border-radius: 5px; min-width: 200px; }
    .dropdown { width: 200px; top: 50px; }
    .selectBg .typeIcon { display: inline-block; width: 25px; height: 25px; font-size: 0; margin-right: 5px; background: url('../assets/images/contents/survry_type.png') no-repeat; background-size: 100px; vertical-align: middle; }
    .selectBg .typeIcon.short_ic { background-position: 1px 3px; }
    .selectBg .typeIcon.long_ic { background-position: -25px 0px; }
    .selectBg .typeIcon.choice_ic { background-position: -51px 0px; }
    .selectBg .typeIcon.multiple_ic { background-position: -80px 0px; }
    .selectBg .typeIcon.drop_down_ic { background-position: 1px -29px; }
    .selectBg .typeIcon.preference_ic { background-position: -24px -28px; }
    .selectBg .typeIcon.graph_choice_ic { background-position: -51px -29px; }
    .selectBg .typeIcon.date_time_ic { background-position: -79px -29px; }
    .selectBg .typePgMenu { display: flex; align-items: center; }
    .typeOpBox { margin-top: 10px; padding: 0 7px; margin-bottom: 15px; }

    /* 퍼블을 위한 임시 */
    .surTypeBox .typeOpBox li { display: none; }
    .surTypeBox.type-box-short .typeOpBox li:first-child { display: block; }
    .surTypeBox.type-box-long .typeOpBox li:nth-child(2) { display: block; }
    .surTypeBox.type-box-choice .typeOpBox li:nth-child(3) { display: block; }
    .surTypeBox.type-box-mult .typeOpBox li:nth-child(4) { display: block; }
    .surTypeBox.type-box-dpdw .typeOpBox li:nth-child(3) { display: block; }
    .surTypeBox.type-box-grpcho .typeOpBox li:nth-child(5) { display: block; }
    .surTypeBox.type-box-dttime .typeOpBox li:last-child { display: block; }
    #group_survey_create.section_ce_fix { overflow-y: auto !important;  background: #fbfcfd; }
    /* 퍼블을 위한 임시 */

    .typeOpBox p { display: inline-block; margin-right: 40px; line-height: 30px; height: 30px; }
    .typeOpBox input[type='checkbox']:not(old)+label { background-size: 17px; background-position: 0 6px; padding-left: 28px; height: 18px; padding-top: 6px; font-size: 13px; }
    .typeOpBox p .opTextTitle { margin-right: 15px; font-size: 13px; }
    .typeOpBox p .least-num-tex { opacity: .7; }
    .surTypeBox .choice-num { padding: 0 15px; border: 1px solid #f3f3f3; border-radius: 3px; margin: 0 10px; vertical-align: middle; margin-top: -3px; width: 100px; box-sizing: border-box; }
    .surTypeBox .wave_separator { margin: 0 13px 0 5px; }
    /* 질문 title */
    .surveyViewer .typeQuestion #sur_type_qst_tit { width: 88%; }
    .qstImgSpan { display: inline-block; width: 30px; height: 30px; vertical-align: top; margin-left: 25px; }
    .qstImgSpan #image_label { cursor: pointer; line-height: 30px; }
    .qstImgSpan .img_bring_ic { font-size: 0; background: url('../assets/images/contents/ic_img_bk.png') no-repeat center; opacity: .6; }
    .qstImgSpan .img_bring_ic:hover { opacity: 1; }
    /* 질문 option */
    .choice-qst-list { display: flex; justify-content: flex-start; align-items: center; padding: 0 25px; box-sizing: border-box; line-height: 30px; margin-bottom: 10px; }
    .choice-qst-list-con { padding: 10px 20px; width: 80%; min-width: 500px; border-radius: 5px; background: #f2f4f6; box-sizing: border-box; }
    .op_chk_show { display: inline-block; width: 20px; height: 20px; background: #fff; border-radius: 50%; border: 1px solid #d1d1d1; vertical-align: middle; margin-right: 10px; }
    .type-box-mult .choice-qst-list-con .op_chk_show { border-radius: 5px; }
    .choice-qst-list-con input.qst_input_text { background: none; width: 93%; text-align: left; }
    .typeQuestion .delBtn { display: inline-block; width: 35px; height: 35px; margin-left: 20px; border-radius: 50%; background-image: url('../assets/images/contents/ic_close_new.png'); background-repeat: no-repeat; background-position: center; background-size: 25px auto; opacity: .6; font-size: 0; }
    .typeQuestion .delBtn:hover { background-color: #f2f4f6; opacity: 1; }

    /* 답변 기준 페이지 */
    .surTypeBox .qst_option_wrap .selectBg { flex-direction: column; width: 22%; }
    .surTypeBox .standard_move .moveSelUl { left: 10px; }
    .surTypeBox .qst_option_wrap .selectBg .selectTitle { min-width: 180px; font-size: 13px; margin-left: 20px; font-weight: normal; }
    .pgMoveOn .choice-qst-list-con { width: 65%; }

    .qst_opt_add { padding: 0 25px; box-sizing: border-box; }
    .qst_option_wrap .qst_opt_add_btn { background: none; border: none; padding: 10px 10px; cursor: pointer; color: #477fff; font-weight: bold; border-radius: 5px; }
    .qst_option_wrap .qst_opt_add_btn:hover { background: #fafafa; }
    .qst_opt_add.input_none .qst_opt_add_btn:last-child { display: none; }
    .qst_opt_add .opt_add_ic { font-size: 0; display: inline-block; width: 25px; height: 20px; vertical-align: middle; background: url('../assets/images/contents/add_input_477fff.png') no-repeat top left; background-size: auto 20px; margin-top: -3px; }
    .qst_opt_add .opt_add_input { background-position: top right; }
    .qst_opt_add .text_separator { margin: 0 13px; }
    .qst_opt_add.input_none .text_separator { display: none; }
    .choice-input-qst-list { display: none; }
    .choice-input-qst-list.on { display: flex; }
    .choice-input-qst-list .qstImgSpan { opacity: 0; pointer-events: none; }
    /* 사진 추가 */
    .survey_img_add { position: relative; margin-bottom: 30px; display: none; }
    .survey_img_add.on { display: inline-block; }
    p.qst_img { width: 300px; height: 250px; border-radius: 15px; display: inline-block; box-shadow: 0 5px 10px rgb(0 0 0 / 10%); border: 2px solid #fff; box-sizing: border-box; background: url('../assets/images/card/img_invite_3.png') no-repeat top; background-size: 100% auto; font-size: 0; }
    .survey_img_add .del { z-index: 999; position: absolute; top: 0; right: -5px; font-size: 0; transition: .1s; display: inline-block; width: 25px; height: 25px; color: #eaeaea; background: #ff6060 url('../assets/images/contents/cross.png') no-repeat center; border-radius: 20px; }
    .qst_option_wrap p.qst_img { width: 150px; height: 150px; margin-left: 30px; background-image: url('../assets/images/card/img_invite_2.png');}

    /* 주관식 */
    .type-box-short .qst_opt_add,
    .type-box-long .qst_opt_add { display: none; }
    .type-box-long .form-qst-list .qstImgSpan,
    .type-box-short .form-qst-list .qstImgSpan { display: none; }
    .type-box-long .form-qst-list .delBtn,
    .type-box-short .form-qst-list .delBtn { display: none; }
    .form-qst-list-con textarea { resize: none; background: none; border: none; outline: none; width: 100%; line-height: 20px; vertical-align: middle; }
    /* 드롭 다운 */
    .typeQuestion .qst_order_num { font-size: 14px; margin-right: 10px; }
    .type-box-dpdw .choice-qst-list-con.dpdw-qst-list-con { width: 78%; }
    .type-box-dpdw .dpdw-qst-list .qstImgSpan { display: none; }
    /* 선호도형 */
    .type-box-prfrn .choice-qst-list-con { width: 25%; min-width: 180px; }
    .prfrn_selc_div { margin: 0 15px; }
    .prfrn_selc_div .opTextTitle.multText { display: block; text-align: center; }
    /* 객관식 표 */
    .type-box-grpcho .choice-qst-list { flex-direction: column; }
    .type-box-grpcho .grpcho_lis_box { width: 75%; min-width: 400px; height: 100%; display: flex; justify-content: space-between; flex-flow: row wrap; gap: 8px; box-sizing: border-box; }
    .type-box-grpcho .grpcho_preview_ic { font-size: 0; width: 20px; height: 20px; display: inline-block; box-sizing: border-box; vertical-align: middle; background: url('../assets/images/contents/ic_remark.png') no-repeat center; background-size: 18px; margin-top: -5px; opacity: .7; }
    .type-box-grpcho .grpcho_lis_box .division_hr { margin: 0px; flex-shrink: 0; border-width: 0px thin 0px 0px; border-style: solid; border-color: rgba(0, 0, 0, 0.12); height: auto; align-self: stretch; }
    .grpcho_qst_div { width: 48%; display: flex; gap: 8px; flex-direction: column; }
    .grpcho_qst_div .grpchoQstUl { display: flex; flex-flow: column; margin-top: 15px; }
    .grpcho_qst_div .line_add_btn { align-self: flex-start; }
    .grpcho_qst_div .row_add_btn { align-self: flex-end; }
    .grpchoQstUl li { display: flex; align-items: center; }
    .type-box-grpcho .choice-qst-list-con { flex-basis: 245px; min-width: auto; }
    /* 날짜 시간 */
    .dttime-qst-list-con { display: flex; width: 40%; min-width: 200px; position: relative; }
    .btn_iocn_div { position: relative; width: 30px; height: 30px; vertical-align: middle; border-radius: 50%; }
    .btn_iocn_div:hover { background: rgba(208,209,211,0.4); }
    .btn_iocn_div .dttime-icon { width: 30px; height: 30px; position: absolute; top: 0; left: 0; background: url('../assets/images/menucons/ic_cal_sm_dc9.png') no-repeat center; }
    .btn_iocn_div .dttime-icon.time_ic { background-image: url('../assets/images/contents/ic_time_bk.png'); }
    .dttime_time_list { display: none; }
    .dttime_time_list.on { display: flex; }
    .dttime_time_list .chooseTime.from { top: 51px; left: 78px; min-width: 243px; }

    /* 설문 보내기 */
    .sendSectionDiv { overflow: hidden; position: relative; background: #fff; box-sizing: border-box; border: 2px solid #f1f3f5; border-radius: 10px; width: 90%; min-width: 800px; max-width: 1200px; margin: 20px auto; }
    .sendSectionDiv .secContainer { padding: 40px; padding-top: 10px; box-sizing: border-box; }
    .SurveySend .goDets { position: absolute; display: inline-block; height: 100%; left: 0; top: 0; width: 100%; text-align: center; font-size: 0; }
    .SurveySend .tableDefault.tableBorder table tbody tr td { width: 95%; min-width: 700px; }
    .SurveySend .tableDefault.tableBorder table tbody tr#titleTr td { border-bottom: 0px; }
    .SurveySend.tab-content tr td:after { display: none; }
    #group_survey_scroll input[type='text']:disabled { background: none; }

    .sendTabUl { height: 60px; line-height: 60px; border-bottom: 1px solid #f2f4f6; display: flex; font-size: 14px; font-weight: bold; z-index: 9999; position: relative;  }
    .sendTabUl li { padding: 0 40px; position: relative; cursor: pointer; display: flex; align-items: center; }
    .sendTabUl li:hover .snTabListTi { color: #477fff; }
    .sendTabUl li .snTabListIc { width: 25px; height: 25px; background: url('../assets/images/contents/survey_ic_send.png') no-repeat top left; background-size: auto 25px; margin-right: 10px; } 
    .sendTabUl li .snIc-send02 { background-position: -34px 0; } 
    .sendTabUl li .snIc-send03 { background-position: -69px 0; }
    .sendTabUl li.on .snTabListTi { border-bottom: 2px solid #477fff; }

    .SendOptionScreen { position: absolute; width: 100%; height: 70%; background: rgba(255,255,255,0.8); bottom: 0; left: 0; text-align: center; padding: 35px 0; box-sizing: border-box; font-size: 14px; font-weight: bold; }
    .SendOptionScreen.off { display: none; }
    .SendOptionScreen span { color: #ff6363; display: block; margin-top: 15px; }
    .SendOption .sn-tab-con { display: none; } 
    .SendOption .sn-tab-con:first-child { display: block; }
    .sendTabConBox { padding: 30px 40px; box-sizing: border-box; }
    .sn-tab-con p { font-size: 14px; font-weight: bold; margin-bottom: 25px; }
    .link_box .sendSecUrl-input { line-height: 40px; width: 70%; min-width: 500px; margin-right: 20px; border: 1px solid #f2f4f6; padding: 0 10px; box-sizing: border-box; color: #232848; }
    .qrcord_box .qrImg { font-size: 0; width: 100px; height: 100px; background-size: cover; background-repeat: no-repeat; background-position: center; }
    .messenger_box ul { display: flex; align-items: center; position: static; width: 100%; }
    .messenger_box ul li { position: relative; width: 62px; height: 62px; margin: 0 25px 0 0; }
    .messenger_box ul li > input { display: inline-block; width: 60px; height: 60px; transition: 0.2s; border: 1px solid #e3e7ed; background-position: center center; background-size: 63px; background-repeat: no-repeat; border-radius: 50%; }

    /* 결과보기 */
    .srContWrap { display: none; }
    .srContWrap:first-child { display: block; }
    .result_header_nav { background: #fff; border-bottom: 1px solid #f2f4f6; }
    .result_header_nav .header_nav_containar { position: relative; width: 100%; max-width: 1200px; min-width: 800px; margin: 0 auto; }
    .result_header_nav .rsTitleBox { padding: 45px 50px 20px 50px; box-sizing: border-box; }
    .result_header_nav .surTitle { font-size: 23px; line-height: 40px; word-break: break-word; white-space: pre-wrap; }
    .result_header_nav .rsTextBox { font-size: 13px; color: #232848; line-height: 23px; margin-top: 5px; display: flex; }
    .rsTabBox { display: flex; position: relative; margin:0 50px 0 25px; box-sizing: border-box; align-items: center; justify-content: space-between; }
    .result_header_nav .divisionDiv { height: 15px; margin-top: 4px; }
    .sendTabUl.resultTabUl { border-bottom: 0px; }
    .sr-tab-con { overflow: hidden; position: relative; background: #fff; box-sizing: border-box; border: 2px solid #f1f3f5; border-radius: 10px; width: 90%; min-width: 400px; max-width: 900px; min-height: 500px; margin: 40px auto; }
    .essential { display: none; color: #ff5757; }
    .essential.on { display: inline-block; }

    .sr-con-text { padding: 45px 40px; box-sizing: border-box; }
    .sr-type-box { margin-bottom: 70px; }
    .sr-type-box:last-child { margin-bottom: 0px; }
    .srConTitle { margin-bottom: 15px; }
    .resultConQst { font-size: 14px; font-weight: bold; line-height: 30px; }
    .result-num { text-indent: 3px; }
    .srConAnswers li { padding: 10px 20px; width: 80%; min-width: 300px; border-radius: 5px; background: #f2f4f6; box-sizing: border-box; font-weight: bold; margin-bottom: 10px; color: #333d4b; }

    .individualWrap .sr-tab-con { margin-top: 5px; }
    .answer-page-time { width: 90%; height: 50px; min-width: 400px; max-width: 900px; margin: 20px auto 0 auto; display: flex; justify-content: space-between; box-sizing: border-box; align-items: center; padding: 0 10px; }
    .individual-aw-pg-nav { display: flex; align-items: center; }
    .individual-aw-pg-nav .toolbar-btn { margin-top: 3px !important; }
    .pgNumBox { display: flex; align-items: center; gap: 0 25px; font-size: 14px; font-weight: bold; margin: 0 15px; }
    .pgNumBox .aw-pg-num-first { color: #477fff; }
    .individual-aw-time { font-size: 13px; opacity: .7; }
    .one-result-form .selectBg { float: none; }
    .SurveyResult .selectBg .selectTitle { cursor: auto; }
    .individualWrap .choice-qst-list-con { background: none; }
    .SurveyResult .opt-etc-input-list { padding-left: 20px; }
    .SurveyResult .etc-qst-list-con { padding: 10px 20px; width: 80%; min-width: 200px; border-radius: 5px; box-sizing: border-box; background: #f2f4f6; }
    .op_chk_show_one.result_chk_show { border-color: #477fff; position: relative; }
    .op_chk_show_one.result_chk_show::before { content: ''; width: 12px; height: 12px; border-radius: 50%; background: #477fff; position: absolute; top: 4px; left: 4px; }
    .op_chk_show_mult.result_chk_show { background: #477fff url('../assets/images/contents/checked_fff.png') no-repeat center; background-size: 20px; border-color: #477fff; }
    .op_chk_show_mult { border-radius: 5px; }
    .individualWrap .survey_img_add.on { display: block; }
    .grpcho_opt_wrap { border-collapse: collapse; display: table; width: 90%; }
    .grpcho_opt_top { display: table-row; cursor: default; }
    .gpTableP { display: table-cell; height: 2.5em; min-width: 48px; padding: 0.25em; text-align: center; vertical-align: middle; width: 120px; }
    .gpTableP:last-of-type { border-bottom-right-radius: 5px; border-top-right-radius: 5px; }
    .gpTableP.grpcho_op_first_p { min-width: 48px; max-width: 288px; padding: 8px; text-align: left; background: #fff; }
    .gpChoDiv { display: table-row-group; background-color: #f2f4f6; border-radius: 5px; border-bottom: 2px solid #fff; box-sizing: border-box; }
    .gpRowBox { display: table-row; border-collapse: collapse; }
    .gpTableP .gpInputWrap { display: inline-block; flex-shrink: 0; height: 20px; position: relative; vertical-align: middle; width: 20px; z-index: 0; }
    .none_space { font-size: 0; }
    .prfrn_opt_wrap { display: flex; flex-direction: row; }
    .prfrn_opt_wrap .prfrn_opt_div { display: flex; width: 100%; word-wrap: break-word; }
    .prfrn-opt-ex { -webkit-box-align: stretch; box-align: center; align-items: stretch; display: flex; -webkit-box-flex: 1; flex-grow: 1; flex-direction: column; min-width: 32px; text-align: center; max-width: 35%; }
    .prfrnOptP { -webkit-box-align: center; box-align: center; align-items: center; display: flex; box-pack: center; -webkit-box-pack: center; justify-content: center; min-height: 3em; line-height: 39px; }
    .prfrn-opt-center { -webkit-box-align: stretch; box-align: stretch; -webkit-align-items: stretch; align-items: stretch; display: -webkit-box; display: -webkit-flex; display: flex; -webkit-box-flex: 1; box-flex: 1; -webkit-flex-grow: 1; flex-grow: 1; -webkit-flex-direction: column; flex-direction: column; min-width: 32px; text-align: center; }
    .prfrn-op-num { font-size: 14px; font-weight: 400; letter-spacing: .2px; line-height: 20px; color: #232848; padding: 0 5px; }
    .qust-prfrn-form .op_chk_show { margin-right: 0px; }
    .prfrn-text-p { font-size: 14px; line-height: 20px; padding: 0 5px; color: #232848; min-width: 0%; word-wrap: break-word; white-space: pre-wrap; }
    .dttime_opt_wrap { display: flex; }
    .dttime_opt_wrap p { padding: 5px 20px; font-size: 14px; line-height: 20px; }
    .dttime_opt_wrap .dttime-time { position: relative; background: #f2f4f6; border-radius: 5px; margin-left: 10px; display: none; }
    .dttime_opt_wrap .dttime-time.on { display: block; }
    .dttime_opt_wrap .dttime-time.on::before { content: ''; width: 2px; height: 20px; background: #dbdfe0; font-size: 0; margin-top: 5px; position: absolute; top: 0px; left: -17px; }
    /* 답변 요약 그래프 */
    /* 세로형 막대 그래프 */
    .result_graph { padding: 15px 0 20px 45px; }
    .graph_info { position: relative; box-sizing: border-box; font-size: 0; }
    .grid_range { position: absolute; right: 0; bottom: -1px; left: 0; z-index: 0; }
    .grid_range .range { display: block; position: relative; height: 42px; border-bottom: 1px solid #f2f4f6; box-sizing: border-box; text-align: right; }
    .range span { position: absolute; bottom: -8px; left: -60px; padding-right: 10px; width: 60px; box-sizing: border-box; color: #232848; font-size: 13px; line-height: 18px; text-align: right; }
    .graph_range { text-align: center; display: flex; justify-content: space-around; }
    .graph_range .wrap_graph { position: relative; height: 212px; text-align: center; display: flex; flex-direction: column-reverse; }
    .graph_range .tit_graph { width: 100%; min-width: 130px; color: #232848; font-size: 13px; letter-spacing: -1px; line-height: 30px; text-align: center; font-weight: normal; font-style: normal; vertical-align: top; margin-bottom: -30px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
    .graph_range .graph { position: relative; margin: 0 auto; width: 70px; height: 100%; }
    .graph .inner_graph { position: absolute; bottom: 0; left: 0; width: 100%; height: 0px; background-color: #477fff; padding-top: 3px; box-sizing: border-box; }
    .graph .txt_value { position: absolute; top: auto; left: 50%; margin-left: -35px; padding-bottom: 6px; width: 100%; color: #232848; font-size: 13px; letter-spacing: -1px; line-height: 19px; }
    .areaOn .graph .txt_value { color: #fff; }
    /* 원형 그래프 */
    #pie_graph_info { display: flex; justify-content: space-between; width: 100%; font-size: 13px; }
    .resultPie { width: 300px; height: 300px; }
    /* 원형 그래프 비율 표시 - CSS 고정 */
    .pie circle { r:20%; cx:50%; cy:50%; fill:transparent; stroke-width:50%; stroke:#ddd; transform: rotate(-90deg); transform-origin:center; transition:all 0.5s; }
    /* 원형 그래프 리스트 */
    .pie_info { width: 300px; padding: 5% 0 0 5%; }
    .pie_info > li { font-size: 0; line-height: 25px; }
    .pie_info > li .colorDot { display: inline-block; vertical-align: middle; width: 8px; height: 8px; margin-right: 5px; border-radius:4px; background: #000; }
    .pie_info > li .pic_text { display: inline-block; vertical-align: middle; font-size: 13px; color: #232848; }
    /* 멀티 세로형 막대 그래프 */
    .vtcal-bar-mult-result-form .wrap_graph { height: 84px; }
    .vtcal-bar-mult-result-form .result_graph { padding-top: 40px; }
    .graph_list_range { position: absolute; top: -30px; left: 0; width: 100%; line-height: 30px; box-sizing: border-box; }
    .graph_list_range ul { display: flex; align-items: center; }
    .graph_list_range li { display: flex; align-items: center; padding: 0 10px; box-sizing: border-box; }
    .graph_list_range li span { font-size: 13px; }
    .graph_list_range li > .barColor { width: 8px; height: 8px; vertical-align: middle; margin-right: 5px; }
    .barColor.clorOp01 { background: #ff6363; }
    .barColor.clorOp02 { background: #00b2c7; }
    .barColor.clorOp03 { background: #ffa70e; }
    .barColor.clorOp04 { background: #13d08b; }
    .barColor.clorOp05 { background: #477fff; }
    .vtcal-bar-mult-result-form .graph { display: flex; align-items: flex-end; width: 100% !important; gap: 3px; }
    .vtcal-bar-mult-result-form .graph .inner_graph { padding-top: 0px !important; width: 30%; position: static; }
</style>