<template>
    <div class="doctor_list section_ce_fix" :class="getTopClass()">
        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">{{ getTitle() }}</h3>
            <!-- <a class="schBtn">검색하기</a> -->
        </div>

         <!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
        <!-- <div class="searchDiv">
            <div class="schGrp">
                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                <input type="text" value="" placeholder="의사명으로 검색하세요">
                <div class="btns">
                    <a href="#" class="closeBtn">닫기</a>
                    <a href="#" class="bt_search"><span class="blind">찾기버튼</span></a>
                </div>
            </div>
        </div> -->

        <div class="schedule_box">
            <div class="grp settingBg">
                <div class="posRel">
                    <div class="bg">
                        <div class="btnA">
                            <a href="#" class="docLink" @click.prevent="setPatientTab('DOCTOR')" :class="{ on : doctor_and_department_tab == 'DOCTOR' }">의사 목록</a>
                            <a href="#" class="specialityLink" @click.prevent="setPatientTab('DEPARTMENT')" :class="{ on : doctor_and_department_tab == 'DEPARTMENT' }">진료과</a>
                        </div>
                        <!-- 삭제버튼 -->
                        <div class="btnBox fr">
                            <input type="button" id="" class="input_btn del" placeholder="" value="삭제" title="" @click="deleteDepartments()"/>
                            <!-- <input type="button" id="" class="input_btn delAll" placeholder="" value="전체 삭제" title="" @click="deleteAllDoctors()"/> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 의사 -->
        <HospitalDoctorList v-if="doctor_and_department_tab == 'DOCTOR'"/>

        <!-- 진료과 -->
        <HospitalDepartmentList v-if="doctor_and_department_tab == 'DEPARTMENT'" 
            :event_bus="event_bus"
        />

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');

import HospitalDoctorList     from '@/components/hodu_d/HospitalDoctorList.vue'
import HospitalDepartmentList from '@/components/hodu_d/HospitalDepartmentList.vue'

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HospitalDoctorList, HospitalDepartmentList
    },
}) 
export default class DoctorAndDepartmentList extends Mixins(VueHoduCommon) {

    /**
     * @HoduDocInfo.State
     */
    @HoduDocInfo.State doctor_and_department_tab !: "DOCTOR" | "DEPARTMENT";

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetDoctorAndDepartmentTab ?: (parms : "DOCTOR" | "DEPARTMENT") => void;

    event_bus : Vue = new Vue();

    mounted() : void {
        
    }

    /**
     * 의사 상세 페이지로 이동
     */
    moveDoctorDetail() : void {
        this.hodu_router_push(`/hospital/${new Date().getTime()}/doctor/detail`);
    }

    /**
     * 탭 변경
     */
    setPatientTab(tab : "DOCTOR" | "DEPARTMENT") : void {
        if( this.doSetDoctorAndDepartmentTab ) { 
            this.doSetDoctorAndDepartmentTab(tab);
            this.hodu_router_push(`/hospital/${new Date().getTime()}/${tab.toLowerCase()}`); 
        }
    }

    /**
     * 최상단 클래스 탭에 따라서 소문자로 반환
     */
    getTopClass() : string {
        return this.doctor_and_department_tab.toLowerCase();
    }

    /**
     * 상단 타이틀 반환
     */
    getTitle() : string {
        switch( this.doctor_and_department_tab ) {
            case "DOCTOR": return "의사";
            case "DEPARTMENT" : return "진료과";
            default : return ''; 
        }
    }

    /**
     * 진료과 멀티 삭제 버튼
     */
    deleteDepartments() : void {
        this.event_bus.$emit('deleteDepartments');
    }

}
</script>

<style scoped>
    .section_ce_fix.doctor a.specialityLink {  }
    .section_ce_fix.doctor .specialityBtn { display:none; }

    .section_ce_fix.department a.docLink { text-align: left !important; }
    .section_ce_fix.department a.specialityLink { margin-left:-10px; }
    .section_ce_fix.department .docBtn { display:none; }

    #container #section_ce .section_scroll { margin-top:27px; }

    /* 헤더 */
    .num { width: 10%; text-align: center; font-weight:bold; }
    .specialityHead { width: 15%; }
    .docNameHead {  width: 22%; }
    .contractHead { width: 23%; }
    .workDaysHead { width: 30%; }

    .docListUl > li { cursor:pointer; transition:0.2s; position:relative; overflow: hidden; height:60px; line-height: 60px;border-bottom:1px solid #f1f3f5; font-weight:bold; font-size: 12px;}
    .docListUl > li:hover { background:#f7f9fb; }
    .docListUl > li.retired p { opacity:0.6;  }
    .docListUl > li > p { float:left; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;  }
    .docListUl > li > p.num { padding-right:0; }
    .docListUl li .docImg { display:inline-block;float: left; margin-top:12px; margin-right:10px; font-size:0; width: 33px; height:33px; border-radius:50%; border:2px solid #f1f3f5; background-size:cover; background-repeat: no-repeat; background-position: center center;background-image:  url('../../assets/images/footer/doc_2.png'); }
    .docListUl li .docImg { border-color:#13D08B }
    .docListUl li:nth-child(2) .docImg { background-image: url('../../assets/images/footer/doc_1.png'); border-color:#ff6060; }
    .docListUl li:nth-child(3) .docImg { background-image: url('../../assets/images/footer/doc_3.png'); border-color:#FFC72F  }
    .workDaysUl li { box-sizing:border-box; border:1px solid #2265ff; display:inline-block; background: #477fff; color:#fff; width: 21px; text-align: center; height: 21px;line-height: 19px; border-radius: 3px; margin-right:5px; }
    .workDaysUl li.off {background: #f1f3f5;color:#939cb1; border-color: #eaeaea}

    .workDaysUl li.morn {background: #ffc00e !important;border-color: #f3b300;}
    .workDaysUl li.night {background: #846eff !important;border-color: #6c52ff;}
    .workDaysUl li.off { color: #a1a5bb !important;}

    .btnAdd { position:absolute; right: 40px; bottom:40px; }
    .btnAdd { opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .btnAdd:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}
    
    .btnA { float: left;padding-left: 30px;line-height: 66px; }
    .btnA a { -webkit-transition: 0.2s;transition: 0.2s;font-size: 14px;font-weight: bold;height: 35px;line-height: 35px;width: 95px;border-radius: 20px;display: inline-block;text-align: center;  }
    .btnA a.on { background: #2b3b5f;color: #fff;text-align: center; }
    #section_ce.speciality a.on.docLink { text-align:center !important; }

    .btnBox { margin-top:11px;margin-right: 30px; }
    .btnBox input { margin-left: 20px; }

    #container #section_ce .section_ce_fix.doctor .del { display:none; }
    #container #section_ce .section_ce_fix.department .delAll { display:none; }

    .left_area_close .grp.settingBg { padding-left: 61px !important; }
</style>