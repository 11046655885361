<template>
    <div id="group_member_import_modal" class="modal">

        <div class="titlebox">
            <h1>멤버 가져오기</h1>
        </div>

        <div class="content">
            <div class="mem_import_desc">
                <p>양식을 다운받아 작성 후 가져오기를 해주세요.</p>
                <a href="#" @click.prevent="exampleDownload">양식파일 다운로드</a>
            </div>

            <div class="file_attach_box">
                <p>파일첨부</p>
                <input type="text" readonly="readonly" class="file_text" :value="file == null ? '' : file.name">
                <span class="file_attach_btn">
                    <input type="file" id="uploadfile" name="uploadfile" class="upload_file" @change="addFile($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    <button type="button" class="file_search">파일찾기</button>
                </span>
            </div>
            <p class="attach_caution">※ 양식에 맞지 않는 파일은 가져올 수 없습니다.</p>
        </div>

        <div class="btns">
            <input type="button" value="취소" @click.prevent="close">
            <input type="button" class="makeRm" value="가져오기" @click.prevent="importGroupMember">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupMemberImportModalInfo } from '@/store/modules/ModalInfo';
import { t_event_file } from '@/model/event';

@Component({
    components: {
        
    },
})
export default class GroupMemberImportModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_member_import_modal_info !: GroupMemberImportModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupMemberImportModalInfo ?: (params : GroupMemberImportModalInfo) => void;

    file : File | null = null;
    file_obj : t_event_file | null = null;    

    mounted() : void {
        
    }

    /**
     * 양식 파일 다운로드
     */
    async exampleDownload() : Promise<void> {
        try {
            const response = await this.$http({
                url : 'app_images/hodu_group_member_import_excel_example.xlsx',
                method : 'GET',
                responseType: 'blob'
            });

            console.log(response);

            const blob = new Blob([response.data]);

            // @ts-ignore
            if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, "호두_그룹_멤버_가져오기_양식.xlsx"); // for IE
                return;
            }

            await this.hodu_download_blob(blob, "호두_그룹_멤버_가져오기_양식.xlsx");

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert('그룹 멤버 가져오기 양식 다운로드 중 오류 발생');
        }
    }

    /**
     * 파일 추가 - +버튼 눌러서 추가
     */
    addFile(event) : void {

        try {
            const files : File[] = event.target.files;

            if( files.length < 1 ) {
                this.file = null;
                this.file_obj = null;
                $('#uploadfile').val("");
                return;
            }

            const temp_file = files[0];

            if( temp_file.name.substring(temp_file.name.lastIndexOf('.')) != '.xlsx' ) {
                alert("xlsx 파일만 업로드 가능합니다");
                $('#uploadfile').val("");
                this.file = null;
                this.file_obj = null;
                return;
            }

            this.file = temp_file;

            this.file_obj = JSON.parse(JSON.stringify({
                name: temp_file.name,     // 원본 파일명
                mimeType: temp_file.type, // MIME TYPE
                url: "",                  // 파일 경로
                size: temp_file.size,     // 파일 크기
                date: new Date()          // 저장 날짜
            }));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert('파일 업로드 중 오류 발생');
        }
    }

    /**
     * 그룹 멤버 가져오기
     */
    async importGroupMember() : Promise<void> {
        try {

            if( this.file == null || this.file_obj == null ) {
                alert("파일을 추가해주세요");
                return;
            }

            await this.hodu_show_indicator();

            const form_data : FormData = new FormData();

            form_data.append('file', this.file);

            const temp_response = await this.hodu_temp_upload(form_data);
            console.log(temp_response);

            if( !temp_response || !this.isHttpStatusSuccess(temp_response.status) || !temp_response.data || !temp_response.data.data ) {
                throw new Error("파일 임시 업로드 실패");
            }

            const temp_files : t_event_file[] = temp_response.data.data.temp_files;

            for( const temp_file of temp_files ) {
                this.file_obj = JSON.parse(JSON.stringify(temp_file));
            }

            // 엑셀 멤버 가져오기
            const excel_response = await this.hodu_api_call(`api/v1/excel/member`, API_METHOD.POST, {
                group_id : this.group_member_import_modal_info.group_id,
                team_id : this.group_member_import_modal_info.team_id,
                file : this.file_obj
            });

            console.log(excel_response);

            if( !excel_response || !this.isHttpStatusSuccess(excel_response.status) ) {
                throw new Error("그룹 멤버 가져오기 실패");
            }

            window['group_member_reload']?.();
            this.close();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert('그룹 멤버 가져오기 중 오류 발생');
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetGroupMemberImportModalInfo?.({
            show_modal : false,
            group_id : 0,
            team_id : 0,
        });
    }

}
</script>

<style scoped>
    #group_member_import_modal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-195px; margin-left:-300px; width:600px; height: auto; background:#fff; }
    #group_member_import_modal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0 30px;border-bottom: 1px solid #e7e9ea; }
    #group_member_import_modal .titlebox h1 { font-size: 20px; font-weight: bold; height: 90px; line-height: 90px; }

    #group_member_import_modal .content { box-sizing: border-box; }
    .mem_import_desc { display: flex; width: 100%; line-height: 40px; background: #f1f3f5; padding: 20px 30px; box-sizing: border-box; align-content: center; font-size: 14px; }
    .mem_import_desc a { line-height: 40px; padding: 0px 15px; margin-left: 20px; box-sizing: border-box; background: #477fff; color: #fff; }
    .file_attach_box { padding: 20px 30px; display: flex; }
    .file_attach_box > p { width: 70px; line-height: 30px; box-sizing: border-box; }
    .file_attach_box .file_text { width: 80%; height: 30px; border: 1px solid #e7e9ea; padding: 3px 2px 0 4px; font-size: 12px; box-sizing: border-box; }
    .file_attach_box .file_attach_btn { display: inline-block; overflow: hidden; position: relative; width: 90px; height: 30px; margin-left: 5px; }
    .file_attach_box .file_attach_btn .upload_file { position: absolute; top: 0; right: 0; width: 80px; height: 30px; opacity: 0; cursor: pointer; }
    .file_attach_box .file_attach_btn .file_search { width: 80px; height: 30px; padding: 0; border: 1px solid #e7e9ea; font-size: 12px; line-height: 30px; white-space: nowrap; background: none; vertical-align: middle; letter-spacing: 0; cursor: pointer; display: inline-block; text-decoration: none; background: #fff; }
    #group_member_import_modal .content .attach_caution { padding-left: 95px; margin-bottom: 28px; opacity: .7; }

    #group_member_import_modal .btns { overflow: hidden; border-radius: 0 0 5px 5px; border-top: 1px solid #e7e9ea; }
    #group_member_import_modal .btns input { transition:0.2s; width: 50%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold;background: #fff; }
    #group_member_import_modal .btns input:hover { background:#f1f3f5; }
</style>