<template>
    <div id="organization_vacation_list" class="section_scroll">
        
        <div class="viewGroup">
            <div class="schedule_box">
                <div class="grp settingBg">
                    <div class="posRel">
                        <div class="bg">
                            <div class=" left">
                                <input type="button" id="organization_vacation_list_start" class="datepicker" :value="start_text">
                                <span class="seper">~</span>
                                <input type="button" id="organization_vacation_list_end" class="datepicker" :value="end_text">
                                <input type="button" id="btnPrev" value="" class="toolbar-btn bt-prev mr10" @click="prev"/>
                                <input type="button" id="btnNext" value="" class="toolbar-btn bt-next mr10" @click="next"/>
                                <!-- <input type="button" id="btnToday" value="오늘" class="toolbar-btn today" /> -->
                            </div>

                            <!-- <div class="grp3 fr">
                                <a href="#" class="schBtn" @click.prevent="is_search_mode = true">검색하기</a>
                            </div> -->

                            <!-- <div class="btnDiv">
                                <input type="button" value="처리" @click.prevent="process" :disabled="checked_approval_uids.length < 1">
                            </div> -->
                        </div>
                        <!-- .schBtn 클릭 시 addClass(on) -->
                        <!-- <div class="searchDiv" :class="{ on : is_search_mode == true }">
                            <div class="schGrp">
                                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                                <input type="text" placeholder="직원을 검색하세요" :value="search_query" @input="search_query = $event.target.value"/>
                                <div class="btns">
                                    <a href="#" class="closeBtn" @click.prevent="() => {
                                        is_search_mode = false;
                                        search_query = '';
                                    }">
                                        닫기
                                    </a>
                                </div>
                            </div>
                        </div> -->
                        
                    </div>
                </div>
                
            </div>
            <div id="myHoli_div" class="content">
                <div class="mainDiv">
                    <ul class="sortHeader">
                        <!-- <li class="checkbox">
                            <input type="checkbox" id="organization-vacation-list-check-all" :disabled="isAllDisabled() == true" @change="checkAll($event)" :checked="isAllChecked() == true"/>
                            <label for="organization-vacation-list-check-all"></label>
                        </li> -->

                        <li class="num">No</li>
                        
                        <li class="name headerSort" :class="{
                            sortOff  : sort_type != 'REQUEST',
                            sortUp   : sort_type == 'REQUEST' && sort_direction == 'ASC', 
                            sortDown : sort_type == 'REQUEST' && sort_direction == 'DESC', 
                        }" @click.prevent="sort(organization_enum.ORGANIZATION_VACATION_SORT_TYPE.REQUEST)">
                            <span>요청일</span>
                        </li>
                        
                        <li class="offDays headerSort" :class="{
                            sortOff  : sort_type != 'VACATION',
                            sortUp   : sort_type == 'VACATION' && sort_direction == 'ASC', 
                            sortDown : sort_type == 'VACATION' && sort_direction == 'DESC', 
                        }" @click.prevent="sort(organization_enum.ORGANIZATION_VACATION_SORT_TYPE.VACATION)">
                            <span>휴가 날짜</span>
                        </li>

                        <li class="total headerSort" :class="{
                            sortOff  : sort_type != 'DAYCOUNT',
                            sortUp   : sort_type == 'DAYCOUNT' && sort_direction == 'ASC', 
                            sortDown : sort_type == 'DAYCOUNT' && sort_direction == 'DESC', 
                        }" @click.prevent="sort(organization_enum.ORGANIZATION_VACATION_SORT_TYPE.DAYCOUNT)">
                            <span>휴가 수</span>
                        </li>

                        <li class="manager"><span>요청자</span></li>
                        
                        <li class="status" v-on-clickaway="filterOutsideClick">
                            <!-- <span>승인현황</span> -->
                            <a class="sort" href="#" @click.prevent="stateFilterToggle">승인현황</a>
                            <div class="dropdown" :class="{ on : state_filter_open == true }">
                                <ul>
                                    <!-- <li><p><input type="checkbox" id="op2_check1" class="input_check" v-model="filter.cancel"><label for="op2_check1"><span>취소</span></label></p></li> -->
                                    <li><p><input type="checkbox" id="op2_check2" class="input_check" v-model="filter.ing"><label for="op2_check2"><span>대기</span></label></p></li>
                                    <li><p><input type="checkbox" id="op2_check3" class="input_check" v-model="filter.complete"><label for="op2_check3"><span>승인</span></label></p></li>
                                    <li><p><input type="checkbox" id="op2_check4" class="input_check" v-model="filter.reject"><label for="op2_check4"><span>반려</span></label></p></li>
                                </ul>
                            </div>
                        </li>

                    </ul>
                    <div id="organization_vacation_list_scroll">
                        <ul class="myHoliUl">

                            <li :class="{ ap : approval.approval_state == 'COMPLETE' }" :key="approval.approval_uid" v-for="(approval, index) in computedApproval">
                                <!-- <div class="checkbox" :class="{ disabled : isDisabled(approval) == true }">
                                    <input type="checkbox" :id="`organization-vacation-list-check-${approval.approval_uid}`" @change="check($event, approval)" :checked="checked_approval_uids.indexOf(approval.approval_uid) > -1" v-if="isDisabled(approval) == false"/>
                                    <label :for="`organization-vacation-list-check-${approval.approval_uid}`"></label>
                                </div> -->
                                <p class="num">{{ index + 1 }}</p>
                                <div class="name">{{ `${hodu_date_to_format_string(approval.audit_created, 'YYYY.MM.DD')} ${getDayOfWeekByDate(approval.audit_created)}` }}</div>
                                <div class="offDays">{{ getDateText(approval) }}</div>
                                <div class="total">{{ approval.contents.vacation_type_daycount != 0 ? `${processDayCount(approval.contents.vacation_type_daycount)} 일` : `${approval.contents.vacation_selected.length} 일` }}</div>
                                <p class="manager">{{ approval.approver[0].user_name }} <span class="pos">({{ approval.approver[0].pos_name }})</span></p>
                                <p class="status">
                                    <span class="dot" :class="{ 
                                        ing : approval.approval_state == 'ING', 
                                        approved : approval.approval_state == 'COMPLETE', 
                                        rejected : approval.approval_state == 'REJECT' }"/>
                                    {{ getStatusText(approval) }}
                                </p>
                                <a href="#" @click.prevent="detail(approval)">자세히 보기</a>
                            </li>

                            <!-- <li>
                                <div class="checkbox">
                                    <input type="checkbox" />
                                    <label for=""></label>
                                </div>
                                <p class="num">1</p>
                                <div class="name">2020.05.10 월</div>
                                <div class="offDays">2020.05.20 월</div>
                                <div class="total">1 일</div>
                                <p class="manager">김성림 <span class="pos">(Executive Manager)</span></p>
                                <p class="status"><span class="dot ing"></span>대기</p>
                                <a href="#">자세히 보기</a>
                            </li>
                            <li>
                                <div class="checkbox">
                                    <input type="checkbox" />
                                    <label for=""></label>
                                </div>
                                <p class="num">2</p>
                                <div class="name">2020.05.10 월</div>
                                <div class="offDays">2020.05.20 월</div>
                                <div class="total">1 일</div>
                                <p class="manager">김은정 <span class="pos">(대리)</span></p>
                                <p class="status"><span class="dot ing"></span>대기</p>
                                <a href="#">자세히 보기</a>
                            </li>
                            <li class="ap">
                                <div class="checkbox">
                                    <input type="checkbox" />
                                    <label for=""></label>
                                </div>
                                <p class="num">3</p>
                                <div class="name">2020.05.10 월</div>
                                <div class="offDays">2020.05.20 월 ~ 24 목</div>
                                <div class="total">4 일</div>
                                <p class="manager">박기태 <span class="pos">(차장)</span></p>
                                <p class="status"><span class="dot approved"></span>20.10.22 월</p>
                                <a href="#">자세히 보기</a>
                            </li>
                            <li class="ap">
                                <div class="checkbox">
                                    <input type="checkbox" />
                                    <label for=""></label>
                                </div>
                                <p class="num">4</p>
                                <div class="name">2020.05.10 월</div>
                                <div class="offDays">2020.05.20 월 ~ 22 화</div>
                                <div class="total">1 일 + 오전반차</div>
                                <p class="manager">박한선 <span class="pos">(인턴)</span></p>
                                <p class="status"><span class="dot approved"></span>20.10.22 월</p>
                                <a href="#">자세히 보기</a>
                            </li>
                            <li class="rj">
                                <div class="checkbox">
                                    <input type="checkbox" />
                                    <label for=""></label>
                                </div>
                                <p class="num">5</p>
                                <div class="name">2020.05.10 월</div>
                                <div class="offDays">2020.05.20 월</div>
                                <div class="total">1 일</div>
                                <p class="manager">이성재 <span class="pos">(차장)</span></p>
                                <p class="status"><span class="dot rejected"></span>20.10.22 월</p>
                                <a href="#">자세히 보기</a>
                            </li>
                            <li class="rj">
                                <div class="checkbox">
                                    <input type="checkbox" />
                                    <label for=""></label>
                                </div>
                                <p class="num">6</p>
                                <div class="name">2020.05.10 월</div>
                                <div class="offDays">2020.05.20 월</div>
                                <div class="total">1 일</div>
                                <p class="manager">이유나 <span class="pos">(주임)</span></p>
                                <p class="status"><span class="dot rejected"></span>20.10.22 월</p>
                                <a href="#">자세히 보기</a>
                            </li>
                            <li class="rj">
                                <div class="checkbox">
                                    <input type="checkbox" />
                                    <label for=""></label>
                                </div>
                                <p class="num">7</p>
                                <div class="name">2020.05.10 월</div>
                                <div class="offDays">2020.05.20 월</div>
                                <div class="total">1 일</div>
                                <p class="manager">이호경 <span class="pos">(대리)</span></p>
                                <p class="status"><span class="dot rejected"></span>20.10.22 월</p>
                                <a href="#">자세히 보기</a>
                            </li> -->
                        </ul>

                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_enum, organization_modal_interface } from '@/model/organization';
import { approval_enum, approval_modal_interface } from '@/model/approval';

import moment from 'moment';

import { directive as onClickaway } from 'vue-clickaway';

@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        
    },
})
export default class OrganizationVacationList extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;
    
    @Prop() event_bus !: Vue;
    @Prop() approval_user_id !: number;
    @Prop() annual_start !: string;
    @Prop() annual_end !: string;

    /**
     * 기안서 작성자를 제외한 approver를 불러옴
     */
    get computedApproverExceptDrafter() : any {
        return (req_approver) => {
            return req_approver.slice(1, req_approver.length);
        }
    }

    /**
     * 본인 휴가원 
     */
    get computedApproval() : any[] {

        // 임시, 취소가 아닌 모든 휴가 리스트
        let target = this.approvals.filter(item => item.approval_type == approval_enum.APPROVAL_TYPE.VACATION && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL');

        if( this.filter.cancel == false ) {
            target = target.filter(item => item.approval_state != approval_enum.APPROVAL_STATE.CANCEL);
        }

        if( this.filter.ing == false ) {
            target = target.filter(item => item.approval_state != approval_enum.APPROVAL_STATE.ING);
        }

        if( this.filter.complete == false ) {
            target = target.filter(item => item.approval_state != approval_enum.APPROVAL_STATE.COMPLETE);
        }
        
        if( this.filter.reject == false ) {
            target = target.filter(item => item.approval_state != approval_enum.APPROVAL_STATE.REJECT);
        }

        target.sort((o1, o2) : number => {

            const o1_requested = new Date(moment(o1.audit_created).format('YYYY-MM-DD'));
            const o2_requested = new Date(moment(o2.audit_created).format('YYYY-MM-DD'));

            const o1_start = o1.contents.vacation_start ? o1.contents.vacation_start : o1.contents.vacation_selected[0];
            const o2_start = o2.contents.vacation_start ? o2.contents.vacation_start : o2.contents.vacation_selected[0];

            const o1_daycount = Number(o1.contents.vacation_type_daycount) != 0 ? Number(o1.contents.vacation_type_daycount) : o1.contents.vacation_selected.length;
            const o2_daycount = Number(o2.contents.vacation_type_daycount) != 0 ? Number(o2.contents.vacation_type_daycount) : o2.contents.vacation_selected.length;

            if( this.sort_type == organization_enum.ORGANIZATION_VACATION_SORT_TYPE.REQUEST ) {
                if( o1_requested.getTime() > o2_requested.getTime() ) return this.sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_requested.getTime() < o2_requested.getTime() ) return this.sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else {
                    // 같은 날짜라면 휴가 날짜 최신순
                    if( o1_start > o2_start ) return -1;
                    else if( o1_start < o2_start ) return 1;
                }
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_VACATION_SORT_TYPE.VACATION ) {
                if( o1_start > o2_start ) return this.sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_start < o2_start ) return this.sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else {
                    // 같은 날짜라면 요청일 최신순
                    if( o1_requested.getTime() > o2_requested.getTime() ) return -1;
                    else if( o1_requested.getTime() < o2_requested.getTime() ) return 1;
                }
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_VACATION_SORT_TYPE.DAYCOUNT ) {
                if( o1_daycount > o2_daycount ) return this.sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_daycount < o2_daycount ) return this.sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else {
                    // 같은 날짜라면 요청일 최신순
                    if( o1_requested.getTime() > o2_requested.getTime() ) return -1;
                    else if( o1_requested.getTime() < o2_requested.getTime() ) return 1;
                }
            }

            return 0;
        });

        if( this.search_query.length > 0 ) {
            target = target.filter(item => this.hodu_string_includes(item.approver[0].user_name, this.search_query));
        }

        return target; 
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationDetailModalInfo ?: (params : organization_modal_interface.OrganizationVacationDetailModalInfo) => void;
    @ModalInfo.Action doSetOrganizationMyVacationDetailModalInfo ?: (params : organization_modal_interface.OrganizationMyVacationDetailModalInfo) => void;
    @ModalInfo.Action doSetApprovalProcessModalInfo ?: (params : approval_modal_interface.ApprovalProcessModalInfo) => void;
    
    start : Date = new Date();
    end : Date = new Date();

    start_text : string = "";
    end_text : string = "";

    approvals : any[] = [];

    checked_approval_uids : string[] = [];

    sort_type : organization_enum.ORGANIZATION_VACATION_SORT_TYPE = organization_enum.ORGANIZATION_VACATION_SORT_TYPE.REQUEST;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    state_filter_open : boolean = false;

    filter : any = {
        cancel : true,
        ing : true,
        complete : true,
        reject : true
    }

    is_search_mode : boolean = false;
    search_query : string = "";
    
    async mounted() : Promise<void> {
        this.event_bus?.$on('adminResize', this.handleResize);

        if( this.approval_user_id == null || this.annual_start == null || this.annual_end == null ) {
            return;
        }

        this.end = moment([this.annual_end.substring(0,4), this.annual_end.substring(4,6), this.annual_end.substring(6,8)].join('-')).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        this.start = moment([this.annual_start.substring(0,4), this.annual_start.substring(4,6), this.annual_start.substring(6,8)].join('-')).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
        
        this.makeDateText();
        this.datepickerInit();

        await this.getApproval();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            const title_box_height = $('#organization_vacation .title_box').outerHeight();
            const schedule_box_height = $('#organization_vacation .schedule_box').outerHeight();
            const sort_Header_height = $('#organization_vacation .content .sortHeader').outerHeight();

            const scroll_height = window.innerHeight - (title_box_height ? title_box_height : 0)
                                                    - (schedule_box_height ? schedule_box_height : 0)
                                                    - (sort_Header_height ? sort_Header_height : 0);   

            // @ts-ignore
            $('#organization_vacation_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : scroll_height,
            });
        });
    }

    /**
     * 상신함 조회
     */
    async getApproval() : Promise<void> {

        try {
            // 검색 타입, 시작일, 종료일
            let query = "?search_mode=ALL";
            query += `&start_date=${moment(this.start).utc().format()}`;
            query += `&end_date=${moment(this.end).utc().format()}`;
            
            // 클라이언트 타임존
            const timezone = -(new Date().getTimezoneOffset());
            query += `&timezone=${timezone ? timezone : 540}`;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval${query}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("수신함 문서 조회 중 오류 발생");
            }

            this.approvals.splice(0, this.approvals.length);
            this.approvals = this.approvals.concat(response.data.data.approval_info.filter(approval => approval.user_id == this.approval_user_id));

        } catch(e) {
            this.hodu_show_dialog('cancel', "수신함 문서 조회 중 오류 발생", ['확인']);
            throw e;
        }

    }

    /**
     * 날짜 텍스트 생성
     */
    makeDateText() : void {
        this.start_text = this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD');
        this.end_text = this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD');
    }

    /**
     * datepicker 초기화
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")}`);
                    
                    if( inst.id == 'organization_vacation_list_start' ) {
                        this.start = moment(selected_date).toDate();
                        
                        if( this.start.getTime() > this.end.getTime() ) {
                            this.end = moment(this.start).toDate();
                        }
                    }

                    else if( inst.id == 'organization_vacation_list_end' ) {
                        this.end = moment(selected_date).toDate();

                        if( this.start.getTime() > this.end.getTime() ) {
                            this.start = moment(this.end).toDate();
                        }
                    }

                    this.makeDateText();
                    this.getApproval();

                    this.checked_approval_uids.splice(0, this.checked_approval_uids.length);
                },
            }

            // @ts-ignore
            $('#organization_vacation_list_start').datepicker(datepicker_option);

            // @ts-ignore
            $('#organization_vacation_list_end').datepicker(datepicker_option);
        });
    }

    /**
     * 이전 날짜
     */
    prev() : void {
        const diff = this.getDateDiff(this.start, this.end);

        this.start = moment(this.start).set("date", moment(this.start).get('date') - diff - 1).toDate();
        this.end = moment(this.end).set("date", moment(this.end).get('date') - diff - 1).toDate();

        this.makeDateText();
        this.getApproval();

        this.checked_approval_uids.splice(0, this.checked_approval_uids.length);
    }

    /**
     * 다음 날짜
     */
    next() : void {
        const diff = this.getDateDiff(this.start, this.end);

        this.start = moment(this.start).set("date", moment(this.start).get('date') + diff + 1).toDate();
        this.end = moment(this.end).set("date", moment(this.end).get('date') + diff + 1).toDate();

        this.makeDateText();
        this.getApproval();

        this.checked_approval_uids.splice(0, this.checked_approval_uids.length);
    }

    /**
     * 정렬
     */
    sort(sort_type : organization_enum.ORGANIZATION_VACATION_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 휴가 날짜 텍스트 반환
     */
    getDateText(approval : any) : string {

        if( this.hodu_string_validation(approval.contents.vacation_start, '') != "" && this.hodu_string_validation(approval.contents.vacation_end, '') != '' ) {

            const vacation_start = approval.contents.vacation_start;
            const vacation_end = approval.contents.vacation_end;

            if( vacation_start == vacation_end ) {
                const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');

                let str = `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)}`;

                // if( approval.contents.vacation_type_daycount > 0 && approval.contents.vacation_type_daycount < 1 ) {
                if( approval.contents.vacation_type_daycount == 0.5 ) {
                    let start_hour = approval.contents.vacation_start_time ? Number(approval.contents.vacation_start_time.substring(0,2)) : -1;
                    let start_min = approval.contents.vacation_start_time ? Number(approval.contents.vacation_start_time.substring(2,4)) : -1;
                    let start_am_pm = 'AM';

                    if( start_hour < 0 || start_min < 0 ) return str;

                    if( start_hour >= 12 ) start_am_pm = "PM";
                    if( start_hour >  12 ) start_hour -= 12;
                    if( start_hour == 0  ) start_hour = 12;

                    let end_hour = approval.contents.vacation_end_time ? Number(approval.contents.vacation_end_time.substring(0,2)) : -1;
                    let end_min = approval.contents.vacation_end_time ? Number(approval.contents.vacation_end_time.substring(2,4)) : -1;
                    let end_am_pm = 'AM';

                    if( end_hour < 0 || end_min < 0 ) return str;

                    if( end_hour >= 12 ) end_am_pm = "PM";
                    if( end_hour >  12 ) end_hour -= 12;
                    if( end_hour == 0  ) end_hour = 12;

                    str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                }

                return str;
            }

            const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
            const target_end = [vacation_end.substring(0,4), vacation_end.substring(4,6), vacation_end.substring(6,8)].join('-');

            return `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)} ~ ${this.hodu_date_to_format_string(target_end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_end)}`;
            
        }

        let str = "";

        for( const selected_date of approval.contents.vacation_selected ) {
            if( str.length > 0 ) str += ", ";
            const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
            str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
        }

        if( approval.contents.vacation_selected.length == 1 ) {
            // if( approval.contents.vacation_type_daycount > 0 && approval.contents.vacation_type_daycount < 1 ) {
            if( approval.contents.vacation_type_daycount == 0.5 ) {
                let start_hour = approval.contents.vacation_start_time ? Number(approval.contents.vacation_start_time.substring(0,2)) : -1;
                let start_min = approval.contents.vacation_start_time ? Number(approval.contents.vacation_start_time.substring(2,4)) : -1;
                let start_am_pm = 'AM';

                if( start_hour < 0 || start_min < 0 ) return str;

                if( start_hour >= 12 ) start_am_pm = "PM";
                if( start_hour >  12 ) start_hour -= 12;
                if( start_hour == 0  ) start_hour = 12;

                let end_hour = approval.contents.vacation_end_time ? Number(approval.contents.vacation_end_time.substring(0,2)) : -1;
                let end_min = approval.contents.vacation_end_time ? Number(approval.contents.vacation_end_time.substring(2,4)) : -1;
                let end_am_pm = 'AM';

                if( end_hour < 0 || end_min < 0 ) return str;

                if( end_hour >= 12 ) end_am_pm = "PM";
                if( end_hour >  12 ) end_hour -= 12;
                if( end_hour == 0  ) end_hour = 12;

                str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
            }
        }
        
        return str;
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;

        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 상태에 따른 텍스트 반환
     */
    getStatusText(approval : any) : string {
        switch(approval.approval_state) {
            case approval_enum.APPROVAL_STATE.ING:
                return "대기";
            case approval_enum.APPROVAL_STATE.CANCEL:
                return "취소";
            case approval_enum.APPROVAL_STATE.COMPLETE:
                return "승인";
            case approval_enum.APPROVAL_STATE.REJECT:
                return "반려";
        }

        return "";
    }

    /**
     * 전체 체크
     */
    checkAll(event) : void {
        const checked = event.target.checked;

        this.checked_approval_uids.splice(0, this.checked_approval_uids.length);
        if( checked == true ) {
            for( const approval of this.computedApproval ) {
                if( this.isDisabled(approval) == true ) continue;
                this.checked_approval_uids.push(approval.approval_uid);
            }
        }
    }

    /**
     * 단일 체크
     */
    check(event, approval) : void {
        const checked = event.target.checked;

        if( checked == true && this.checked_approval_uids.indexOf(approval.approval_uid) == -1 ) {
            this.checked_approval_uids.push(approval.approval_uid);
        }
        else if( checked == false && this.checked_approval_uids.indexOf(approval.approval_uid) != -1 ) {
            this.checked_approval_uids.splice(this.checked_approval_uids.indexOf(approval.approval_uid), 1);
        } 
    }

    /**
     * 전부다 체크 됐는지 여부
     */
    isAllChecked() : boolean {
        let count = this.computedApproval.length;
        for( const approval of this.computedApproval ) {
            if( this.isDisabled(approval) == true ) {
                count--;
            }
        }

        return this.isAllDisabled() == false && (count == this.checked_approval_uids.length)
    }

    /**
     * 체크 비활성화 여부
     */
    isDisabled(approval) : boolean {
        const target = approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);
        return target.length < 1;
    }   

    /**
     * 체크 전체 비활성화 여부
     */
    isAllDisabled() : boolean {

        let flag = false;
        for( const approval of this.computedApproval ) {
            flag = this.isDisabled(approval);
            if( flag == false ) break;
        }

        return this.computedApproval.length == 0 || flag;
    }

    /**
     * 휴가 상세
     */
    detail(approval : any) : void {
        // this.doSetOrganizationVacationDetailModalInfo?.({
        //     show_modal : true,
        //     approval_uid : approval.approval_uid,
        //     callback : () => {
        //         this.getApproval();
        //     }
        // });
        this.doSetOrganizationMyVacationDetailModalInfo?.({
            show_modal : true,
            approval_uid : approval.approval_uid,
            callback : () => {
                this.getApproval();
            }
        });
    }

    /**
     * 체크 된 휴가원 처리
     */
    process() : void {
        if( this.checked_approval_uids.length < 1 ) return;
        this.doSetApprovalProcessModalInfo?.({ 
            show_modal : true, 
            callback : async(state : approval_enum.APPROVAL_APPROVER_STATE, reject_message : string) => {
                
                try {
                    await this.getApproval();

                    let promise_array : Promise<any>[] = [];

                    for( const approval_uid of this.checked_approval_uids ) {
                        const target = this.computedApproval.filter(item => item.approval_uid == approval_uid);
                        
                        if( target.length < 1 ) continue;

                        const target_approval = target[0];
                        const my_infos = target_approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);

                        if( my_infos == null || my_infos.length < 1 ) continue;

                        const my_info = my_infos[0];
                        my_info.state = state;
                        my_info.date = new Date();

                        const target_index = target_approval.approver.indexOf(my_info);
                        target_approval.approval_cur = target_approval.approval_cur + 1;

                        // 반려처리라면 문서 자체도 반려 상태로 바꿈
                        if( state == approval_enum.APPROVAL_APPROVER_STATE.REJECT ) {
                            target_approval.approval_state = approval_enum.APPROVAL_STATE.REJECT;
                            my_info.comment = reject_message;
                        }

                        // 마지막 승인이라면 기안서 자체를 완료 처리
                        else if( target_index == (target_approval.approver.length - 1) && state == approval_enum.APPROVAL_APPROVER_STATE.CONFIRM ) {
                            target_approval.approval_state = approval_enum.APPROVAL_STATE.COMPLETE;
                        }

                        else if( target_index != (target_approval.approver.length - 1) && state == approval_enum.APPROVAL_APPROVER_STATE.CONFIRM ) {
                            target_approval.approver[target_index + 1].state = approval_enum.APPROVAL_APPROVER_STATE.ING;
                        }

                        target_approval.approver.splice(target_index, 1, my_info);

                        const promise = this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${target_approval.approval_uid}`, API_METHOD.PUT, target_approval);
                        promise_array.push(promise);
                    }

                    await Promise.all(promise_array);
                    await this.getApproval();
                    
                    this.checked_approval_uids.splice(0, this.checked_approval_uids.length);

                } catch(e) {
                    this.hodu_show_dialog('cancel', '휴가원 처리 중 오류 발생', ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }  
        });
    }

    /**
     * 승인 상태 필터 토글
     */
    stateFilterToggle() : void {
        this.state_filter_open = !this.state_filter_open; 
    }

    /**
     * 필터 바깥쪽 클릭 이벤트
     */
    filterOutsideClick() : void {
        this.state_filter_open = false;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_vacation_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    .btnA a:first-child {padding-left: 0;}
    .btnA { float: left;padding-left: 30px;line-height: 65px; }
    .btnA a { -webkit-transition: 0.2s;transition: 0.2s;font-size: 14px;font-weight: bold;height: 35px;padding:0 20px;line-height: 35px;border-radius: 20px;display: inline-block;text-align: center;  }
    .btnA a.on { background: #2b3b5f;color: #fff;text-align: center; padding:0 20px !important;}

    .title_box .input_btn { cursor: pointer; margin-top:10px; padding-right: 35px !important; appearance: none; -webkit-appearance: none;background: url('../../assets/images/side_area/bt_open.png') no-repeat 90% center !important } 
    .title_box .input_btn:focus { background-image: url('../../assets/images/side_area/bt_close.png') !important; }
    
    .grp3 a.schBtn { margin-top: 13px; margin-left: 0; margin-right: 25px; }
    .searchDiv a.closeBtn { margin: 0; }
    .schGrp input { height: 65px; line-height: 65px; }
    .settingBg > .posRel > .bg { position: static; }
    .settingBg > .posRel { z-index: 100; }

    .settingBg .left  { margin-top: 0;  min-width: auto !important; width: auto !important }
    .settingBg .left input[type="button"].datepicker {float: left;line-height: 61px;font-size: 19px;color: #202A39;margin-right: 15px !important; font-weight: bold; min-width: auto !important; width: 101px !important; background : none; }
    .settingBg .seper { margin-top: 31px; }

    .myHoliUl li { position: relative; transition:0.2s; overflow: hidden; width: 100%; line-height: 60px; height: 60px; box-sizing:border-box; border-bottom: 1px solid #f1f3f5; color:#202A39; }
    .myHoliUl li:hover { background:#f7f9fb; font-weight: bold; }
    .myHoliUl li > * { font-weight: bold; float: left; display: inline-block; padding-right: 15px;box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;}
    .myHoliUl li.ap { background: #fcfdfd; border-color:#edf1f3; }
    .myHoliUl li.ap > * { opacity: 0.6; }
    .myHoliUl li.ap:hover > * { opacity: 1; }
    .myHoliUl li.rj { }
    .myHoliUl li a { transition:0.1s; position: absolute; right: 0;top:0; width:100%; padding: 0; height: 100%; font-size:0; }
    .myHoliUl li .notChecked { background: #ffbc49;border: 1px solid #ffa70e; display:inline-block; width: 20px; height: 20px;  line-height: 20px; border-radius: 50%; text-align: center; font-weight: bold; margin-right: 10px;}
    /* .section_ce_fix .num { width: 5%; text-align: center; text-indent: -20px; } */
    /* .section_ce_fix .checkbox { width: 7%; text-align: center;} */
    /* .section_ce_fix .myHoliUl .num { padding-right: 0; } */
    /* .section_ce_fix .name { width: 17.5%; } */
    /* .section_ce_fix .offDays { width: 18%; } */
    /* .section_ce_fix .total { width: 17.5%; } */
    /* .section_ce_fix .manager { width: 20%; } */
    /* .section_ce_fix .status { width: 15%; } */

    .section_ce_fix .num { width: 10%; text-align: center; }
    .section_ce_fix .myHoliUl .num { padding-right: 0; }
    .section_ce_fix .name { width: 20%; }
    .section_ce_fix .offDays { width: 20%; }
    .section_ce_fix .total { width: 15%; }
    .section_ce_fix .manager { width: 20%; }
    .section_ce_fix .status { width: 15%; }

    .myHoliUl .status .dot { color:#477fff; display: inline-block;width: 20px; height: 20px;border-radius: 50%; float: left; margin-top: 17.5px; margin-right: 10px; background-repeat: no-repeat; background-size: cover; background-position: center center; }
    .myHoliUl .status .dot.ing {background-color: #ffbc49;border: 1px solid #ffa70e;}
    .myHoliUl .status .dot.rejected {background-color: #ff6363;border: 1px solid #ff2e2e; background-image: url(../../assets/images/contents/ic_close_fff.gif); background-size: 12px;}
    .myHoliUl .status .dot.approved {background-color: #477fff;border: 1px solid #0b55ff;background-image: url('../../assets/images/contents/checked_fff.png');}
    .myHoliUl .status .none { opacity: 0.3; color: #202A39;  }
    .section_ce_fix .manager .pos { opacity: 0.6; padding-left: 4px; }
    .section_ce_fix .grpTeam .clr { display: inline-block; width: 7px; height: 7px; border-radius: 50%; font-size: 0; margin-top:19px; margin-right: 7px; float: left; }
    .section_ce_fix .sortHeader .checkbox input[type='checkbox']:not(old)+label { margin-top:19px;  }
    .section_ce_fix .checkbox { z-index: 1000; position: relative; opacity: 1 !important; padding-right: 0 !important; }
    .section_ce_fix .checkbox input{margin: 0;padding: 0;opacity: 0;width: 0;}
    .section_ce_fix .checkbox label { margin-left: 20px;  }
    .section_ce_fix .checkbox label:after { position: absolute; content:''; display: block; width: 100%; height: 100%; left: 0; top:0; }
    .section_ce_fix .checkbox input[type='checkbox']:not(old)+label{margin-top: 17px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid rgb(210, 211, 217);background: rgb(210, 211, 217) url(../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;margin-right: 8px;}
    .section_ce_fix .checkbox input[type='checkbox']:not(old):checked + label{background-color: #477fff !important;border-color: #477fff;}
    .section_ce_fix .checkbox input[type='checkbox']:disabled+label{opacity: 0.3;}
    


    .content { position: relative; height: 900px }
    .mainDiv { box-sizing:border-box; width: 100%;}
    .mainDiv .titleDiv { width:100%; line-height: 60px; height: 60px; box-sizing:border-box; border-bottom: 1px solid #e7e9ea; overflow: hidden; padding: 0 25px; }
    .mainDiv .titleDiv .title { font-weight: bold; font-size: 14px; }
    .mainDiv .titleDiv input { float: right; margin-top: 9px }
    .mainDiv .titleDiv * {display: inline-block; }

    
    .descriptUl li { position: relative; line-height: 55px; height: 55px; padding:0 25px; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; }
    .descriptUl li:hover { background:#f7f9fb; }
    .descriptUl li .total { font-weight: bold; float: right }
    .descriptUl li .total.none { opacity: 0.3 }
    .descriptUl li .total.red { color:#ff6363; }
    .descriptUl li * {  display: inline-block; }
    .descriptUl li a {position: absolute; left: 0; top:0; width: 100%; height: 100%; font-size: 0; }
    .descriptUl li .title {width: 150px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}

    #addOffDay { position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 10000000;-webkit-transition: 0.2s;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(/img/btn_add_fff.ffacba40.png) no-repeat center center !important;-webkit-box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;} 
    #addOffDay:hover {-webkit-box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5) !important;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5) !important;}

    .btnDiv { float: right; padding-right:30px }
    .btnDiv input { padding:0 20px; line-height: 35px; height: 35px; border-radius: 3px; background:#fff; border: 1px solid #e7e9ea; font-weight: bold; margin-left: 15px; margin-top:13px; }
</style>