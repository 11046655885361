<template>
    <div class="modalDiv add">
        <div class="bg"></div>
        <!-- ******************** 모달 분리시 상단 modalDiv와 bg는 필요없음 -->
        <div class="modal aptTelModal">
            <div class="titlebox">
                <h1>아파트 연락처</h1>
            </div>
            <div class="modal_content">
                <ul>
                    <li class="titleLi">
                        <label class="title">이름</label>
                        <p class="descript"><input type="text" placeholder="예) 관리사무소" :value="name" @input="name = $event.target.value"/></p>
                    </li>
                    <li class="telLi">
                        <label class="title">번호</label>
                        <div class="descript">
                            <input type="text" placeholder="예) 02-0000-0000" :value="tel" @input="tel = $event.target.value">
                            <!-- <span class="seper"></span>
                            <input type="text" placeholder="0000" />
                            <span class="seper"></span>
                            <input type="text" placeholder="0000" /> -->
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btns" :class="{ b3 : is_create == false }">
                <input type="button" value="닫기" @click="closeModal">
                <input type="button" value="삭제" v-if="is_create == false" @click="deleteContact">
                <input type="button" :value="is_create ? '추가' : '수정'" @click="createOrUpdateContact">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HomeContactCreateModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_contact_create_modal_info !: hodu_home_modal_info.HomeContactCreateModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeContactCreateModalInfo  ?: (params : hodu_home_modal_info.HomeContactCreateModalInfo) => void;

    is_create : boolean = true;

    key : string = "";
    name : string = "";
    tel : string = "";

    mounted() : void {
        this.is_create = this.home_contact_create_modal_info.contact == null;
        this.key = require("uuid/v4")();

        if( this.is_create == false ) {
            this.key = this.home_contact_create_modal_info.contact.key;
            this.name = this.home_contact_create_modal_info.contact.name;
            this.tel = this.home_contact_create_modal_info.contact.tel;
        }
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetHomeContactCreateModalInfo == null ) { return; }

        this.doSetHomeContactCreateModalInfo({
            show_modal : false
        });
    }

    /**
     * 추가 또는 수정
     */
    createOrUpdateContact() : void {

        if( this.name.trim().length < 1 ) {
            alert("이름을 입력 해주세요");
            $('.titleLi .descript input[type=text]').focus();
            return;
        }

        if( this.tel.trim().length < 1 ) {
            alert("번호를 입력 해주세요");
            $('.telLi .descript input[type=text]').focus();
            return;
        }

        // TODO 전화번호 valid 체크
        
        window[this.is_create ? 'createHomeContact' : 'updateHomeContact']({
            key : this.key,
            name : this.name.trim(),
            tel : this.tel.trim(),
        })

        this.closeModal();
    }

    /**
     * 삭제
     */
    deleteContact() : void {
        if( confirm("연락처를 삭제 하시겠습니까?") == false ) { return; }
        window['deleteHomeContact'](this.key);
        this.closeModal();
    }

    

}
</script>

<style scoped>
    .modal.aptTelModal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-185.5px; margin-left:-260px; width:520px; height: 361px; background:#fff; }   
    .modal.aptTelModal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0 30px;border-bottom: 1px solid #e7e9ea; }
    .modal.aptTelModal .titlebox h1 { font-size: 20px;font-weight: bold;height: 90px;line-height: 110px; }
    
    .modal.aptTelModal .modal_content { overflow: hidden; height: 199px;border-bottom: 1px solid #e7e9ea;  }
    .modal.aptTelModal .modal_content ul li > * { display: block; width: 100% }
    .modal.aptTelModal .modal_content ul li { padding:20px 30px; overflow: hidden; border-bottom:1px solid #f1f3f5;}
    .modal.aptTelModal .modal_content ul li .descript { float: right; text-align: left; font-weight: bold }
    .modal.aptTelModal .modal_content ul li .descript * { float: right; text-align: left; font-weight: bold }
    .modal.aptTelModal .modal_content ul li .descript input { line-height: 40px; height: 40px; border:1px solid #e7e9ea; padding:0 15px; box-sizing:border-box; margin-top:7px; width: 100%; font-size: 13px; }
    .modal.aptTelModal .modal_content ul li.telLi .descript input { width: 100%; float: left; }
    .modal.aptTelModal .modal_content ul li.telLi .descript .seper { float: left; display: inline-block; width: 6px; height: 2px; background:#e7e9ea; margin: 25px 8px 0; }
    .modal.aptTelModal .modal_content ul li .descript input:hover {border-color:#e7e9ea;}
    .modal.aptTelModal .modal_content ul li .descript input:focus {border-color:#477fff;}

    .modal.aptTelModal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    .modal.aptTelModal .btns input { transition:0.2s; width: 50%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold; background: #fff; }
    .modal.aptTelModal .btns.b3 input { width : 33.3% }
    .modal.aptTelModal .btns input:hover { background:#f1f3f5; }
</style>