<template>
    <!-- 내용에 따라 세로값이 변하기에 .modal.detailMsg 하이트 잡아서 margin-top: -반값px 스크립트로 적용  -->
    <div class="modal detailMsg" id="modalDetail" style="transition: 0.3s">
        <div class="grp" id="modalGrp">
            <!--
            <div class="reason" id="grp_pushFailReason">
                <p>플러스 친구 삭제로인해 푸시를 보내지 못했습니다</p>
            </div>
            -->
            <div class="grp2">
                <div class="scroll">
                    <div class="grp" id="grp_Deatil">
                        <!--<h3 class="title" id="sent_title">{{`메세지`}}</h3>-->
                        <p class="content" id="sent_msgContent" v-html="message_body">
                        </p>
                    </div>
                </div>
            </div>
            <div class="grp1">
                <h1 class="blind">전송된 메세지</h1>	
                <h2 class="to"><span class="sub_title">받은이</span>{{message_info.user_name}}</h2>
                <p class="from" id="grpClassNm"><span class="sub_title">그룹 &amp; 팀</span>{{message_info.group_team_name}}</p>
                <p class="ymdt" id="sent_ymd"><span class="sub_title">발신일</span>{{message_info.message_start_date}} <span id="sent_time">{{ message_info.message_start_time}}</span></p>
            </div>
        </div>
        <!-- 버튼 하나일 시 .btns 클래스 추가 b1 -->
        <div class="btns b1" id="bt_box">			
            <a href="#" class=" modal-topBtn" @click.prevent="messageSendStatusPopClose()">닫기</a>		
            <a href="#" class=" modal-topBtn resendBtn" id="resend_msg">재전송</a>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';

const EtcInfo = namespace('EtcInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class MessageSendStatus extends Mixins(VueHoduCommon) {
    /**
     * EtcInfo.State
     */
    @EtcInfo.State message_info !: any;
    message_body : string = "";

    async mounted() {
        this.message_body = await this.message_info.noti_data.body.replace(/#{그룹팀명}/g, this.message_info.group_team_name)
                                                                  .replace(/#{이름}/g, this.message_info.user_name)
                                                                  .replace(/#{이메일}/g, (this.message_info.user_email ? this.message_info.user_email : '-'))
                                                                  .replace(/#{발신일자}/g, this.message_info.message_start_date + ' '  + this.message_info.message_start_time)
                                                                  .replace(/\n/g, '<BR>');

        setTimeout(function() {
            const msgBoxHeight : number | undefined = $('.modal.detailMsg').outerHeight();
            $('#modalDetail').css('margin-top', '-'+(msgBoxHeight!/2)+'px');
        }, 10);
    }

    messageSendStatusPopClose() : void {
        window["messageSendStatusPopClose"]();
    }
}
</script>

<style scoped>
    .detailMsg { box-shadow: 0 5px 20px rgba(0,0,0,0.1); width: 380px; background:#fff; position:absolute; left:50%; top: 50%; margin-left:-190px; box-sizing: border-box; border-radius: 35px; overflow:hidden;border: 5px solid #e3e7ed; }
    #modalDetail.detailMsg .grp2 { background: #fff; float: none; max-height: 330px !important; }
    #modalDetail.detailMsg .btns {background: #fff;position: static;padding: 0;margin: 0;  }
    .detailMsg .btns { background: #fff;height: 70px; border-top: 1px solid #f1f3f5; border-radius: 0 0 5px 5px; overflow:hidden; }
    .detailMsg .btns a { height: 100%; transition:0.2s; line-height: 70px; width: 50%; float: left;  padding: 0; border-radius: 0; font-size: 14px; font-weight: bold; text-align: center; }
    .detailMsg .btns a:hover { background: #f1f3f5; }
    .detailMsg .btns a.resendBtn:hover { color:#477fff; }
    .detailMsg .btns.b1 a { width: 100%; }
    .detailMsg .reason { background: #ff6060; color:#fff;border-radius: 30px 30px 0 0; padding: 15px 30px; font-weight: bold; text-align: center;line-height: 25px; font-size: 14px  }
    #modalDetail.detailMsg .grp2 .scroll { padding: 40px 35px 40px; overflow-y: auto; max-height: 330px; box-sizing: border-box;  }
    .detailMsg .content { font-size: 13px; line-height: 25px; }
    #sent_title { font-size: 20px; padding-top: 0; margin-bottom: 10px }
    #modalDetail.detailMsg .grp1 { background:#fff; padding:  20px 30px; border-top:1px solid #f1f3f5;}
    #modalDetail.detailMsg .sub_title { font-weight: bold; width: 70px; box-sizing:border-box; display: inline-block; opacity: 0.5; }
    #sent_time { padding-left: 10px; opacity: 0.7; }
    .detailMsg .from { height: 30px; line-height: 30px; font-weight: bold; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;box-sizing: border-box; padding-right:30px;}
    .detailMsg .ymdt,  .detailMsg .to { font-weight: bold; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;box-sizing: border-box; padding-right:30px;}

</style>