<template>
    <div class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">권한 설정</h3>
        </div>
        <div id="memSettingScroll" class="section_scroll"><!--스크롤 생겨야할 영역-->
            <div class="viewGroup">					
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <h3 class="selectTitle"><span class="cl" :style="{ backgroundColor : `${hodu_hex_color_process(group_team_option.group_team_color)} !important`}">그룹 색상</span>
                        <span class="txt">{{group_team_option.group_team_name}}</span></h3>
                        <div class="btns fr">
                            <input type="button" class="input_btn" value="초기화" @click="permmisionReset()">
                            <!--<input type="button" class="input_btn" value="취소" >-->
                            <input type="button" class="input_btn" value="저장" @click="setSave()">
                        </div>
                    </div>
                    <ul class="sortHeader" id="permission_header">
                        <li class="title"><span>권한 목록</span></li>
                        <li class="king" id=""><span>{{team_id == 0 ? '관리자' : '팀장'}}</span></li>
                        <li class="manager" id="" v-if="team_id == 0"><span>부관리자</span></li>
                        <li class="member" id=""><span>멤버</span></li>
                    </ul>
                    <!-- content에 스크롤 -->
                    <div class="content" id="permission_scroll">
                        <div class="setBg">
                            <ul>
                                <li v-bind:key="index" v-for="(lo_data, index) in arr_perm" :class="{ on : lo_data.listOff != null && lo_data.listOff == true }">
                                    <h5 @click="clickPermissionTitle(lo_data, index)">{{lo_data.name}}</h5>
                                    <ul>
                                        <li li v-bind:key="cont_index" v-for="(content, cont_index) in lo_data.list" v-show="!((content.cate=='member' && content.type=='read') || (content.cate=='event' && content.type=='read') || (content.cate=='notice' && content.type=='read'))">
                                            <h6>
                                                {{content.name}}
                                                <em class="permission_summary" v-if="content.cate == 'event' && content.type == 'modify_prev'">(작성일 또는 종료일 2일 이후 편집 불가)</em>
                                                <em class="permission_summary" v-if="content.cate == 'event' && content.type == 'delete_prev'">(작성일 또는 종료일 2일 이후 삭제 불가)</em>
                                                <em class="permission_summary" v-if="content.type == 'private_read'">(특정인 지정 여부와 관계없이 모든 게시물 열람가능)</em>
                                                <em class="permission_summary" v-if="content.cate == 'personal_record' && content.type == 'update'">(최종관리자가 지정한 멤버만 등록·수정 가능)</em>
                                                <em class="permission_summary" v-if="content.cate == 'personal_record' && content.type == 'read'">(일반멤버는 열람 불가)</em>
                                            </h6>
                                            <ul v-if="team_id == 0 && content.perm_type != 'select'">
                                                <li class="king"><p>관리자</p><label class="switch"><input type="checkbox" :checked="getPermmisionValue(GroupAuth.GROUP_MANAGER, content.cate, content.type)" @change="setPermmisionChange($event, GroupAuth.GROUP_MANAGER, content.cate, content.type)"><span class="slider round"></span></label></li>
                                                <li class="manager"><p>부관리자</p><label class="switch"><input type="checkbox" :checked="getPermmisionValue(GroupAuth.GROUP_SUB_MANAGER, content.cate, content.type)" @change="setPermmisionChange($event, GroupAuth.GROUP_SUB_MANAGER, content.cate, content.type)"><span class="slider round"></span></label></li>
                                                <li class="member"><p>멤버</p><label class="switch"><input type="checkbox" :checked="getPermmisionValue(GroupAuth.GROUP_USER, content.cate, content.type)" @change="setPermmisionChange($event, GroupAuth.GROUP_USER, content.cate, content.type)" @click="block_switch($event, content, GroupAuth.GROUP_USER)"><span class="slider round"></span></label></li>
                                            </ul>
                                            <ul v-if="team_id == 0 && content.perm_type == 'select'">
                                                <li class="selectionLi">
                                                    <div class="selectionDiv" @click.prevent="member_select(content)">

                                                        <div v-if="content.members == null || content.members.length < 1">
                                                            <span class="selection_member">멤버지정</span>
                                                        </div>

                                                        <div v-else>
                                                            <span class="selection_member added" :key="member.user_id" v-for="member in content.members" @click.prevent="delete_select_member($event, content, member.user_id)">
                                                                {{ member.user_name }}
                                                            </span>
                                                        </div>

                                                        <input type="button" class="selectionBtn">
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul v-if="team_id > 0">
                                                <li class="king"><p>팀장</p><label class="switch"><input type="checkbox" :checked="getPermmisionValue(GroupAuth.TEAM_MANAGER, content.cate, content.type)" @change="setPermmisionChange($event, GroupAuth.TEAM_MANAGER, content.cate, content.type)"><span class="slider round"></span></label></li>
                                                <li class="member"><p>멤버</p><label class="switch"><input type="checkbox" :checked="getPermmisionValue(GroupAuth.TEAM_USER, content.cate, content.type)" @change="setPermmisionChange($event, GroupAuth.TEAM_USER, content.cate, content.type)"><span class="slider round"></span></label></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                
                            </ul>
                        </div>	
                    </div>
                </div>	
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');

import { EventWorkAssignmentInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupPermmision extends Mixins(VueHoduCommon) {

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEventWorkAssignmentInfo ?: (params : EventWorkAssignmentInfo) => void;

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * 변수 선언부
     */
    perm_data = {"group": [/*{name: "파일", list: [{cate: "file", type: "read", name: "조회,읽기"},
                                                {cate: "file", type: "create", name: "파일 업로드"},
                                                {cate: "file", type: "delete", name: "파일 삭제"}]},*/
                           {name: "팀관리", list: [{cate: "team", type: "create", name: "팀 생성"},
                                                 {cate: "team", type: "delete", name: "팀 삭제"},
                                                 {cate: "team", type: "modify", name: "팀 수정(팀 승인, 팀 멤버탈퇴, 팀 초대)"}]},
                           {name: "일정", list: [{cate: "event", type: "read", name: "조회"},
                                                {cate: "event", type: "create", name: "등록"},
                                                {cate: "event", type: "delete", name: "삭제"},
                                                {cate: "event", type: "modify", name: "수정"},
                                                {cate: "event", type: "modify_prev", name: "과거 일정 편집 금지"},
                                                {cate: "event", type: "delete_prev", name: "과거 일정 삭제 금지"},
                                                {cate: "event", type: "private_read", name: "특정인 지정 열람"}]},
                           {name: "멤버", list: [{cate: "member", type: "read", name: "보기"},
                                                {cate: "member", type: "delete", name: "멤버 삭제(강퇴)"},
                                                {cate: "member", type: "invite", name: "초대"},
                                                {cate: "member", type: "modify", name: "수정 (권한변경)"}]},
                           {name: "인사정보", list: [{cate: "personal_record", type: "update", name: "등록·수정", perm_type : "select", members : null },
                                                    {cate: "personal_record", type: "read", name: "열람" }]},
                           {name: "공지사항", list: [{cate: "notice", type: "read", name: "조회"},
                                                   {cate: "notice", type: "create", name: "등록"},
                                                   {cate: "notice", type: "delete", name: "수정"},
                                                   {cate: "notice", type: "modify", name: "삭제"}]},
                           {name: "그룹관리", list: [{cate: "group_info", type: "delete", name: "삭제"},
                                                   {cate: "group_info", type: "modify", name: "수정"},
                                                   {cate: "group_info", type: "approve", name: "승인"}]},
                           {name: "업무일지", list: [/*{cate: "report", type: "read", name: "조회"},*/
                                                    {cate: "report", type: "create", name: "등록"},
                                                    {cate: "report", type: "delete", name: "삭제"},
                                                    {cate: "report", type: "modify", name: "수정"},
                                                    {cate: "report", type: "private_read", name: "특정인 지정 열람"}]},
                           {name: "회의록", list: [/* {cate: "meetinglog", type: "read", name: "조회"}, */
                                                   {cate: "meetinglog", type: "create", name: "등록"},
                                                   {cate: "meetinglog", type: "delete", name: "삭제"},
                                                   {cate: "meetinglog", type: "modify", name: "수정"},
                                                   {cate: "meetinglog", type: "private_read", name: "특정인 지정 열람"}]}, 
                           {name: "프로젝트", list: [{cate: "work", type: "read", name: "조회"},
                                                    {cate: "work", type: "create", name: "등록"},
                                                    {cate: "work", type: "delete", name: "삭제"},
                                                    {cate: "work", type: "modify", name: "수정"},
                                                    {cate: "work", type: "private_read", name: "특정인 지정 열람"}]}],
                 "team" : [{name: "일정", list: [{cate: "event", type: "read", name: "조회"},
                                                {cate: "event", type: "create", name: "등록"},
                                                {cate: "event", type: "delete", name: "삭제"},
                                                {cate: "event", type: "modify", name: "수정"},
                                                {cate: "event", type: "modify_prev", name: "과거 일정 편집 금지"},
                                                {cate: "event", type: "delete_prev", name: "과거 일정 삭제 금지"},
                                                {cate: "event", type: "private_read", name: "특정인 지정 열람"}]},
                           {name: "멤버", list: [{cate: "member", type: "read", name: "보기"},
                                                {cate: "member", type: "delete", name: "멤버 삭제(강퇴)"},
                                                {cate: "member", type: "invite", name: "초대"},
                                                {cate: "member", type: "modify", name: "수정 (권한변경)"}]},
                           {name: "공지사항", list: [{cate: "notice", type: "read", name: "조회"},
                                                   {cate: "notice", type: "create", name: "등록"},
                                                   {cate: "notice", type: "delete", name: "수정"},
                                                   {cate: "notice", type: "modify", name: "삭제"}]},
                           {name: "팀관리", list: [{cate: "team_info", type: "modify", name: "수정"},
                                                 {cate: "team_info", type: "delete", name: "삭제"},
                                                 {cate: "team_info", type: "approve", name: "승인"}]},
                           {name: "업무일지", list: [/*{cate: "report", type: "read", name: "조회"}, */
                                                    {cate: "report", type: "create", name: "등록"},
                                                    {cate: "report", type: "delete", name: "삭제"},
                                                    {cate: "report", type: "modify", name: "수정"},
                                                    {cate: "report", type: "private_read", name: "특정인 지정 열람"}]},
                           {name: "회의록", list: [/*{cate: "meetinglog", type: "read", name: "조회"},*/
                                                   {cate: "meetinglog", type: "create", name: "등록"},
                                                   {cate: "meetinglog", type: "delete", name: "삭제"},
                                                   {cate: "meetinglog", type: "modify", name: "수정"},
                                                   {cate: "meetinglog", type: "private_read", name: "특정인 지정 열람"}]},  
                           {name: "프로젝트", list: [{cate: "work", type: "read", name: "조회"},
                                                {cate: "work", type: "create", name: "등록"},
                                                {cate: "work", type: "delete", name: "삭제"},
                                                {cate: "work", type: "modify", name: "수정"},
                                                {cate: "work", type: "private_read", name: "특정인 지정 열람"}]}]};

    arr_perm   : any = [];
    permmision : any = null;
    personal_record_perm : any = null;

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() {
        const vue = this;
        this.setScroll();

        // 권한 조회
        await this.getRole();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight : number | undefined = $('.settingBg').outerHeight();
        const headerHeight : number | undefined = $('#permission_header').outerHeight();

        // @ts-ignore
        $('#permission_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : window.innerHeight - ( titleHeight == null ? 0 : titleHeight ) - ( settingBgHeight == null ? 0 : settingBgHeight ) - ( headerHeight == null ? 0 : headerHeight ),
        });
    }

    /**
     * 권한 조회
     */
    async getRole() : Promise<void> {
        
        this.arr_perm = [];
        this.permmision = null;
        let url = (this.team_id > 0 ? `api/v1/teams/getPermmision/${this.team_id}/${this.group_id}` : `api/v1/groups/getPermmision/${this.group_id}`);

        try {

            const response = await this.hodu_api_call(url, API_METHOD.GET, null);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list ) {
                throw new Error("권한 조회 오류");
            }

            this.permmision = response.data.data.list;

            this.arr_perm = (this.team_id > 0 ? JSON.parse(JSON.stringify(this.perm_data.team)) : JSON.parse(JSON.stringify(this.perm_data.group)));

            if( this.team_id < 1 ) {
                const permission_response = await this.hodu_api_call(`/api/v1/groups/${this.group_id}/personal_record/permission`, API_METHOD.GET);

                console.log(permission_response);

                if( permission_response == null || !this.isHttpStatusSuccess(permission_response.status) || !permission_response.data || !permission_response.data.data ) {
                    throw new Error("인사정보 권한 조회 오류");
                }

                const personal_record_perm = permission_response.data.data.permission;

                // personal_record.update 찾아서 변수 업데이트

                let change_complete = false;
                const copy_perm_data = JSON.parse(JSON.stringify(this.perm_data))
                for( const perm of copy_perm_data.group ) {

                    if( perm.name == '인사정보' ) {
                        for( const data of perm.list ) {
                            if( data.type == 'update' ) {       
                                data.members = personal_record_perm;
                                change_complete = true;
                            }
                        }
                    }

                    if( change_complete == true ) {
                        break;
                    }
                }

                this.perm_data = JSON.parse(JSON.stringify(copy_perm_data));
                this.arr_perm = (this.team_id > 0 ? JSON.parse(JSON.stringify(this.perm_data.team)) : JSON.parse(JSON.stringify(this.perm_data.group)));
            }

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
    }

    /**
     * 값 변경
     */
    setPermmisionChange(any, ps_auth, ps_cate, ps_type) : void {
        if ( null == this.permmision || this.permmision.length < 1 ) {
            return;
        }

        for ( let i = 0; i < this.permmision.length; i++ ) {
            if ( this.permmision[i].role == ps_auth ) {
                this.permmision[i].permmision[ps_cate][ps_type] = any.target.checked;
                break;
            }
        }
    }

    /**
     * 저장
     */
    async setSave() : Promise<void> {
        await this.hodu_api_call(`api/v1/groupsweb/setPermmision/${this.group_id}/${this.team_id}`, API_METHOD.POST, this.permmision)
            .then(async(response) => {
                this.hodu_show_dialog("success", "저장되었습니다", ['확인']);
                await this.get_group_role_service();
                await this.getRole();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 값 가져오기
     */
    getPermmisionValue(ps_auth, ps_cate, ps_type) : boolean {
        if ( null == this.permmision || this.permmision.length < 1 ) {
            return false;
        }

        for ( let i = 0; i < this.permmision.length; i++ ) {
            let lo_data = this.permmision[i];

            if ( lo_data.role == ps_auth ) {
                return lo_data.permmision[ps_cate][ps_type];
            }
        }

        return false;
    }

    /**
     * 초기화
     */
    permmisionReset() : void {
        this.getRole();
    }

    async member_select(content) : Promise<void> {

        if( content.cate == 'personal_record' && content.type == 'update' ) {

            // 최종관리자만 수정 가능
            if( this.get_group_role(this.group_id) != 'ADMIN' ) {
                return;
            }
            
            // 인사정보 권한 조회
            let select_user_id : number[] = [];
            try {

                const response = await this.hodu_api_call(`/api/v1/groups/${this.group_id}/personal_record/permission`, API_METHOD.GET);

                console.log(response);

                if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("인사정보 권한 조회 오류");
                }

                const permission = response.data.data.permission;
                
                if( permission != null && permission.length > 0 ) {
                    for( const perm of permission ) select_user_id.push(perm.user_id);
                }

                this.doSetEventWorkAssignmentInfo?.({
                    show_event_work_assignment : true,
                    selected_user_ids : select_user_id,
                    is_group_member_select : true,
                    select_max_count : 3,
                    confirm_function : async(user_ids : number[]) => {
   
                        try {

                            this.doSetEventWorkAssignmentInfo?.({
                                show_event_work_assignment : false,
                                selected_user_ids          : [],
                                confirm_function           : () => {}
                            });

                            let body_user_id : number[] = [];

                            if( user_ids.length > 0 ) {
                                body_user_id = body_user_id.concat(user_ids);
                            }

                            const response = await this.hodu_api_call(`/api/v1/groups/${this.group_id}/personal_record/permission`, API_METHOD.POST, {
                                "user_id" : body_user_id
                            });

                            console.log(response);

                            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                                throw new Error("인사정보 권한 업데이트 오류");
                            }

                            this.getRole();

                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                        }
                        
                    }
                })

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
            
        }
    }

    delete_select_member(event, content, user_id) {
        event.stopPropagation();

        // 인사정보 권한은 최종관리자만 수정 가능
        if( content.cate == 'personal_record' && content.type == 'update' && this.get_group_role(this.group_id) != 'ADMIN' ) {
            return;
        }

        this.hodu_show_dialog("cancel", "선택한 멤버의 권한을 제거하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                
                if( content.cate == 'personal_record' && content.type == 'update' ) {
                    try {

                        let user_ids : number[] = [];
                        let is_find_personal_record_update = false;
                        for( const perm of this.perm_data.group ) {

                            if( perm.name == '인사정보' ) {
                                for( const data of perm.list ) {
                                    if( data.type == 'update' ) {       
                                        for( const member of (data.members ?? []) ) {
                                            if( (member as any).user_id != null && user_id != (member as any).user_id ) user_ids.push((member as any).user_id)
                                        }
                                        is_find_personal_record_update = true;
                                    }
                                }
                            }

                            if( is_find_personal_record_update == true ) {
                                break;
                            }
                        }

                        const response = await this.hodu_api_call(`/api/v1/groups/${this.group_id}/personal_record/permission`, API_METHOD.POST, {
                            "user_id" : user_ids
                        });

                        console.log(response);

                        if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                            throw new Error("인사정보 권한 업데이트 오류");
                        }

                        this.getRole();

                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    }
                }

            }
        ])
    }

    block_switch(event, content, auth) {
        if( content.cate == 'personal_record' && content.type == 'read' && auth == this.GroupAuth.GROUP_USER ) {
            event.preventDefault();
        }
    }

    /**
     * 리스트 접기 펼치기
     */
    clickPermissionTitle(lo_data, index) {
        lo_data.listOff = lo_data.listOff == null ? true : !lo_data.listOff;
        this.arr_perm.splice(index, 1, lo_data);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#permission_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }
}
</script>
<style scoped>
	#container #section_ce #memSettingScroll .schedule_box {padding-left: 0;max-width: 100%; padding-right:0;}
	#memSettingScroll .mCSB_inside > .mCSB_container {margin-right: 0;} 
	.setBg {margin-bottom:150px; }
	.switch { width:49px; height:21px; margin-top:15px; }
	.slider:before { width:13px; height:13px; bottom:4px; }
	#memSettingScroll .sortHeader { margin-top:66px; }
	#memSettingScroll .sortHeader > li {padding: 0 30px;box-sizing: border-box;}
	#memSettingScroll .sortHeader > li.title {width: 52%;}
	#memSettingScroll .sortHeader > li.king,#memSettingScroll .sortHeader > li.manager,#memSettingScroll .sortHeader > li.member {width: 16%;text-align: center;}
	#memSettingScroll .sortHeader > li.king.on span,#memSettingScroll .sortHeader > li.manager.on span ,#memSettingScroll .sortHeader > li.member.on span {color:#477fff;}
	#memSettingScroll .sortHeader > li.king.on:after ,#memSettingScroll .sortHeader > li.manager.on:after ,#memSettingScroll .sortHeader > li.member.on:after {position: absolute;bottom: 0px;left: 0;width: 100%;height: 2px;content: '';display: block;background: #477fff;}
	#memSettingScroll .btns { margin-right:30px; margin-top:0; }
	#memSettingScroll .btns input { margin-left:15px; margin-top:10px;  }
	#memSettingScroll h3.selectTitle{position: relative !important;position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
    #memSettingScroll h3.selectTitle .cl {display: inline-block;width: 8px;height: 8px;float: left;margin-top: 28px;border-radius: 50%;margin-right: 15px;font-size: 0;}
	#memSettingScroll .content {margin-top: 0;overflow-y: auto;float: left;width: 100%;}
	#memSettingScroll .content .setBg > ul > li {position: relative;}
	#memSettingScroll .content .setBg > ul > li > h5 {line-height: 55px;font-size: 14px;height: 55px;border-bottom: 1px solid #e7e9ea;border-top: 1px solid #e7e9ea;padding: 0 30px;box-sizing: border-box;}
	#memSettingScroll .content .setBg > ul > li > ul > li {line-height: 50px;height: 50px;transition: 0.2s;border-top: 1px solid #fff;border-bottom: 1px solid #f1f3f5;}
    #memSettingScroll .content .setBg > ul >  li:hover h5 {color: #477fff; cursor:pointer;}
	#memSettingScroll .content .setBg > ul > li > ul > li > * {display: inline-block;color:#6a7182}
	#memSettingScroll .content .setBg > ul > li > ul > li p {display: none;}
	#memSettingScroll .content .setBg > ul > li > ul > li h6 {width: 52%;box-sizing: border-box;float: left;padding-left: 40px;font-size: 12px;}
	#memSettingScroll .content .setBg > ul > li > ul > li h6:before {content: '';display: inline-block;width: 6px;height: 6px;background: #cfd4d6;border-radius: 50%;float: left;margin-top: 22px;margin-right: 15px;}
	#memSettingScroll .content .setBg > ul > li > ul > li:hover h6:before { background:#30384b; }
	#memSettingScroll .content .setBg > ul > li > ul > li:hover h6 { color:#30384b; }
	#memSettingScroll .content .setBg > ul > li > ul > li > ul {overflow:hidden;width: 48%;transition:0.2s}
	#memSettingScroll .content .setBg > ul > li > ul > li > ul li {width: 33.333333%;display: inline-block;float: left;text-align: center;height: 55px;box-sizing: border-box;}
	#memSettingScroll .content .setBg > ul > li > ul > li > ul li .switch {float: none;}
	#memSettingScroll .content .setBg > ul > li > ul > li:hover, #memSettingScroll .content .setBg > ul > li > ul > li:last-child:hover {border-top: 1px solid #f1f3f5;background: #f1f3f5;border-bottom: 1px solid #f1f3f5;}
    #memSettingScroll .content .setBg > ul > li:first-child > h5 {border-top: 0 none;}
	#memSettingScroll .content .setBg > ul > li:last-child > ul { border-bottom:1px solid #e7e9ea }
	
	#memSettingScroll .content .setBg > ul >  li.on > ul { transition:0.2s;opacity:0; height:0;border:0 none; overflow:hidden}
	#memSettingScroll .content .setBg > ul >  li.on > h5 { border-bottom:0 none; }
	
	#memSettingScroll .content .setBg > ul > li > h5:after {position: absolute;right: 20px;top: 7px;width: 40px;height: 40px;background: url(../assets/images/side_area/bt_open.png) no-repeat center right;content:'';display:block;}
	#memSettingScroll .content .setBg > ul > li.on > h5:after { background: url(../assets/images/side_area/bt_close.png) no-repeat center right; }
	#memSettingScroll .content .setBg > ul > li:last-child.on { border-bottom: 1px solid #e7e9ea}
	#memSettingScroll .content .setBg > ul > li > ul > li:last-child {border-bottom: 1px solid #fff;}

    .permission_summary { color : #bcbdc2; font-style: normal; }

    /* 인사정보 */
    #memSettingScroll .content .setBg > ul > li > ul > li > ul li.selectionLi { width: 100%; padding: 0 65px; }
    .selectionDiv { width: 100%; text-align: left; cursor: pointer; position: relative; }
    .selectionDiv .selection_member { line-height: 50px; font-size: 13px; color: #6a7182; font-weight: bold; margin-right: 25px; }
    .selectionDiv .selection_member.added { color: #273044; padding: 3px 15px; background: #f1f3f5 url(../assets/images/contents/ic_x_new_sm.png) no-repeat center right; border-radius: 19px; padding-right: 33px; transition: .2s; }
    .selectionDiv .selection_member.added:hover { background-color: #e5e7ea; }
    .selectionDiv .selectionBtn { position: absolute; right: 3px; top: 9px; border-radius: 50%; width: 35px; height: 35px; display: inline-block; background: url(../assets/images/contents/month_bt_next.svg) center center; background-size: 27px; }
</style>