<template>
    <div class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <!-- 타이틀 영역 -->
        <div class="title_box">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">프로젝트 설정</h3>
            <!-- 클릭시 새창에 PDF를 열어주세요. 업무일지,회의록은 2페이지부터 프로젝트 10페이지부터 -->
            <span class="ussM_box"><a href="app_images/hoduWorks_manual01.pdf#page=10" target="_blank" rel=noopener title="사용 안내서를 확인해주세요" class="use_manual">?</a></span>
        </div>
        <!-- 타이틀 영역 끝 -->

        <div id="workSettingScroll" class="section_scroll"><!--스크롤 생겨야할 영역-->
            <div class="viewGroup">					
                <div class="schedule_box">
                    
                    <!-- 서브 상단 메뉴 영역 -->
                    <div class="grp settingBg">
                        <h3 class="selectTitle"><span class="txt">프로젝트 템플릿 리스트</span> <span class="num">{{ work_template_list ? work_template_list.length : 0 }}</span></h3>
                        <p class="btn_group" style="">
                            <input type="button" id="addWork" value="추가" class="btn btn-md" @click="moveWorkTemplateAdd" v-if="getWorkPermission('create') == true"/>
                        </p>
                    </div>
                    <!-- 서브 상단 메뉴 영역 끝 -->

                    <div class="content" id="work_template_list_scroll">
                        <ol class="work_template_ol">
                            <!-- 업무 템플릿 추가 -->
                            <li class="crtWork" v-if="getWorkPermission('create') == true">
                                <input type="button" class="addNow" id="addWork2" value="+ 추가하기" @click="moveWorkTemplateAdd">
                            </li>
                            <!-- 업무 템플릿 추가 끝 -->

                            <!-- 업무 템플릿 리스트 -->
                            <li :key="template.template_id" v-for="(template, index) in work_template_list" :class="{ check : template.template_type == 'CHECK' }">
                                <p class="status">{{ template.template_info.is_multi_select ? "다중 선택(병행 작업)" : "단일 선택" }}</p>
                                <p class="howMany">{{ template.template_info.content.length }} {{ template.template_type == 'FLOW' ? "단계" : "개" }}</p>
                                <p class="num">{{ index + 1 }}</p>
                                <h4>{{ template.template_info.name }}</h4>

                                <!-- 항목 리스트 -->
                                <ul>
                                    <li :key="content_index" v-for="(content, content_index) in template.template_info.content">
                                        <h5 class="dc9" :style="{ 'background-color' : `${ hodu_hex_color_process(content.color) } !important` }">{{ content_index + 1 }}</h5>
                                        <p class="txt">{{ content.title }}</p>
                                    </li>
                                </ul>
                                <!-- 항목 리스트 끝 -->
                                
                                <input type="button" class="modifyWork" value="수정" @click="modifyWorkTemplate(template.template_id)" v-if="getWorkPermission('modify') == true"/>
                                <input type="button" class="delWork" value="삭제" @click="workTemplateDeleteModal(index, template)" v-if="getWorkPermission('delete') == true"/>

                                <!-- 삭제 영역 -->
                                <div class="delForReal" :class="{ on : template['deleteDivOn'] == true }">
                                    <h5>삭제 하시겠습니까?</h5>
                                    <div class="btns">
                                        <input type="button" class="no" value="아니오" @click="workTemplateDeleteCancel(index, template)"/>
                                        <input type="button" class="yes" value="네" @click="workTemplateDelete(index, template.template_id)"/>
                                    </div>
                                </div>
                                <!-- 삭제 영역 끝 -->

                            </li>
                            <!-- 업무 템플릿 리스트 끝 -->
                        </ol>
                    </div>
                    
                </div>
            </div>
        </div>	
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';
import { t_work_template } from '@/model/osm';

import { namespace } from 'vuex-class';
const WorkInfo = namespace('WorkInfo');

import { ResizeObserver } from 'vue-resize'

@Component({
    components: {
        ResizeObserver
    },
})
export default class WorkSetting extends Mixins(VueHoduCommon) {
    
    /**
     * @WorkInfo.Action
     */
    @WorkInfo.Action doSetWorkTemplateId ?: any;

    work_template_list : t_work_template[] = [];
    
    mounted() : void {
        this.setScroll();
        this.getWorkTepmplate();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height      : number | undefined = $('.title_box').outerHeight();
        const setting_bg_height : number | undefined = $('.settingBg').outerHeight();
        const final_height      : number             = window.innerHeight - ( title_height ? title_height : 0 ) - ( setting_bg_height ? setting_bg_height : 0 );
        
        // @ts-ignore
        $('#work_template_list_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : final_height,
            scrollbarPosition : 'outside'
        })
    }

    /**
     * 업무 템플릿 조회
     */
    getWorkTepmplate() : void {
        if( this.getWorkPermission('read') == false ) {
            return;
        }

        const vue = this;
        
        this.hodu_api_call(`api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                vue.work_template_list.splice(0, vue.work_template_list.length);
                vue.work_template_list = vue.work_template_list.concat(response.data.data.template_list);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 업무 템플릿 삭제창 뜨게 만들기
     */
    workTemplateDeleteModal(index : number, template : any) : void {
        if( this.getWorkPermission('delete') == false ) {
            return;
        }

        template.deleteDivOn = true;
        this.work_template_list.splice(index, 1, template);
    }

    /**
     * 업무 템플릿 삭제 취소
     */
    workTemplateDeleteCancel(index : number, template : any) : void {
        template.deleteDivOn = false;
        this.work_template_list.splice(index, 1, template);
    }

    /**
     * 업무 템플릿 삭제
     */
    workTemplateDelete(index : number, template_id : string) : void {
        if( this.getWorkPermission('delete') == false ) {
            return;
        }

        const vue = this;
        
        this.hodu_api_call(`api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template/${ template_id }`, API_METHOD.DELETE)
            .then((response) => {
                console.log(response);
                vue.work_template_list.splice(index, 1);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 업무 템플릿 추가
     */
    moveWorkTemplateAdd() : void {
        if( this.getWorkPermission('create') == false ) {
            return;
        }

        this.doSetWorkTemplateId("");
        this.hodu_router_push(`/work/${ new Date().getTime() }/setting/template`);
    }

    /**
     * 템플릿 수정
     */
    modifyWorkTemplate(template_id : string) : void {
        if( this.getWorkPermission('modify') == false ) {
            return;
        }

        this.doSetWorkTemplateId(template_id);
        this.hodu_router_push(`/work/${ new Date().getTime() }/setting/template/${ template_id }`);
    }

    /**
     * 업무 권한 구하기
     * 업무 작성 권한(create) - 템플릿 작성 권한
     * 업무 읽기 권한(read)   - 템플릿 리스트 조회 권한
     * 업무 수정 권한(modify) - 템플릿 수정 권한
     * 업무 삭제 권한(delete) - 템플릿 삭제 권한
     */
    getWorkPermission(crud_type : string) : boolean {
        
        // 그룹
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, 'work', crud_type);
        } 
        
        // 팀
        else if ( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, 'work', crud_type);
        } 

        // 그 외의 경우는 불가능
        else {
            return false;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#work_template_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }
}

</script>

<style scoped>
    /* 업무 설정 */
    #container #section_ce .schedule_box { max-width:100%; }
	#workSettingScroll .content {padding-top: 0;/* overflow: hidden; */clear: both;}
	#workSettingScroll .selectTitle span.num { margin-left:10px; color:#477fff; }
	#workSettingScroll .settingBg .btn_group { margin-right: 30px;margin-top: 11px; }
	#section_ce #workSettingScroll ol li { list-style: none; }
	input#addWork {height: 40px;line-height: 40px;border-radius: 5px;background: #fff;border: 1px solid #e7e9ea;color: #35405a;padding: 0 15px;font-size: 14px;font-weight: bold;}
	#workSettingScroll ol > li h4 span {margin-left: 20px;opacity: 0.5;}
	#section_ce #workSettingScroll .grp.settingBg {height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
	#section_ce #workSettingScroll .grp.settingBg h3.selectTitle {position: static;display:inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
	#workSettingScroll .content .num {text-align: center;z-index:10000;width: 30px;height: 30px;font-weight: bold;line-height: 30px;border-radius: 50%;border: 1px solid #e7e9ea;margin-right: 16px;display: inline-block;position: absolute;left: -17px;top: -12px;background: #fff;}
	#workSettingScroll .content ol {margin-top:30px;height: auto;float: left;width: 100%;}
	#workSettingScroll .content ol > li {min-width: 290px;transition:0.2s;position: relative;border-radius: 5px;border: 1px solid #e7e9ea;width: 290px;height: 300px;display: inline-block;margin-right: 30px;margin-bottom: 30px;float: left;padding: 0;box-sizing: border-box;}
	#workSettingScroll .content ol > li:hover { box-shadow:0 8px 15px rgba(0,0,0,0.1); transition:0.2s; }
	#workSettingScroll .content ol > li.crtWork:hover { box-shadow: none; }
	#workSettingScroll .content ol > li h4 {font-size: 16px;display: inline-block;width: 100%;padding: 20px 19px 15px;box-sizing: border-box;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;border-bottom: 1px solid #f1f3f5;margin-bottom: 12px;}
	#section_ce #workSettingScroll ol ul li {display: block;position: relative;}
	#section_ce #workSettingScroll ol li ul li h5 {transition:0.1s;width: 20px;height: 20px;line-height: 20px;font-size:12px;margin-right: 0px;font-weight: bold;display: inline-block;text-align: center;border-radius: 50%;color: #fff;margin-left: 5px;border: 6px solid #fff;position: absolute;top: 0;left: 0;}
	#section_ce #workSettingScroll ol ul li .txt {opacity:0.5;font-weight:bold;display: inline-block;position: static;bottom: 9px;left: -10px;padding: 0;z-index: 100;transition: 0.1s;border-radius: 5px;color: #35405a;line-height: 32px;width: 100%;padding-left: 40px;box-sizing: border-box;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
	#section_ce #workSettingScroll ol ul li:hover h5 {transform: scale(1.1);transition: 0.2s;}
	#workSettingScroll ul {margin-left: 0;padding: 0 10px;box-sizing: border-box;}
	#workSettingScroll li .status,#workSettingScroll li .check {position: absolute;background: #35405a;color: #fff;font-weight: bold;padding: 10px;border-radius: 0 0 5px 5px;right: 15px;top: 0px;}
	#workSettingScroll .howMany {font-weight: bold;opacity: 0.5;padding: 20px 20px 15px;width: 100%;box-sizing: border-box;border-bottom: 1px solid #e7e9ea;}
	#workSettingScroll .content li.crtWork {background:#fff;box-sizing: border-box;border: 2px dashed #e7e9ea;}
	#workSettingScroll .content li.crtWork input {transition:0.2s;color:#a7a7a7;border-radius: 5px;font-size: 21px;font-weight: bold;text-align: center;height:100%; line-height:300px;width:100%;background:#f1f3f5;border:0 none; padding:0; float:left;margin:0;;}
	#workSettingScroll .content li.crtWork:hover input.addNow { color: #477fff; background:#fff; }
	#workSettingScroll input.modifyWork {position: absolute;width: 100%;height: 100%;top: 0;left: 0;padding: 0;margin: 0;font-size: 0 !important;border: 0;background: none;z-index:1000;}
	#workSettingScroll input.modifyWork:hover {background: transparent !important;font-size: 0;}
	#workSettingScroll input.delWork {display:none;position: absolute;top: -12px;left: -17px;width: 32px;background: #ff6363 url(../../assets/images/contents/ic_delImg.png) no-repeat center center;background-size: 14px;font-size: 0;height: 32px;border-radius: 50%;font-weight: bold;color: #fff;z-index: 100000;transition: 0.1s;}
	#workSettingScroll .content ol > li:hover .delWork:hover {width: 70px;border-radius: 30px;font-size: 12px;background: #ff6363;transition: 0.1s;}
	#workSettingScroll .content ol > li:hover .delWork {display:block;}
	#workSettingScroll input.delWork:hover {background-color:#f14343}
	#workSettingScroll .content ol > li:hover .howMany { opacity:1; border-bottom:1px solid #f1f3f5; }
	.content .work_template_ol > li.check ul li h5 {background-image: url('../../assets/images/contents/checked_fff.png') !important;background-size: 20px;background-repeat: no-repeat;font-size: 0 !important;background-position: center center !important;}
	.delForReal.on { display:block; }
	.delForReal {display:none;position: absolute;z-index: 1000;background: rgba(255,255,255,0.8);width: 100%;height: 100%;top: 0;left: 0;text-align: center;border-radius:5px;}
	.delForReal input {background: transparent;font-weight: bold;font-size: 14px;width: 80px;display: inline-block;border-radius: 20px;background: #ffffff;line-height: 35px;border: 1px solid #e7e9ea;margin: 0 10px !important;}
	.delForReal input:hover { background:#f1f3f5;}
	.delForReal input.yes:hover { background:#477fff; color:#fff; border:1px solid #477fff; }
    .delForReal h5 {font-size: 16px;margin-top: 130px;margin-bottom: 60px;}
    .schedule_box { padding-left : 0px !important; padding-right : 0px !important;}
    .work_template_ol { margin-left : 40px !important; margin-right : 40px !important; }
</style>
