<template>
    <div id="doctor_off_setting_modal" class="modal" :class="{ edit : day_off_setting_modal_info.is_create == false }">
        <div class="titleBox">
            <h1>{{ day_off_setting_modal_info.is_create ? '휴가 등록' : '휴가 상세' }}</h1>
        </div>
        <div class="content">
            <div class="detail">
                
                <HoduInlineDatePicker
                    :event_bus="event_bus" 
                    :dp_id="'doctor_off_datepicker'" 
                    :dp_class="'datepickerDiv'"
                    :other_month_selectable="true"
                    :default_date="day_off_setting_modal_info.day_off != null ? new Date(day_off_setting_modal_info.day_off.start_date) : new Date()"
                    @select="datepickerSelectFunction"
                    @changeMonthYear="datepickerChangeFunction"
                />

                <!-- datepickerDiv안에 datepicker넣기 -->
                <!-- <div class="datepickerDiv"> -->
                    <!-- 여기부턴 보여지기용 -->
                    <!-- <a href="" class="ui-datepicker-prev">이전</a><span class="yyyy">2020.04</span><a href="" class="ui-datepicker-next">다음</a>
                    <div class="daysAndDates">
                        <ul class="calUl">
                            <li class="dayList">
                                <ul class="day">
                                    <li>일</li>
                                    <li>월</li>
                                    <li>화</li>
                                    <li>수</li>
                                    <li>목</li>
                                    <li>금</li>
                                    <li>토</li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="off">29</a></li>
                                    <li><a class="off">30</a></li>
                                    <li><a class="off">31</a></li>
                                    <li><a class="off">1</a></li>
                                    <li><a class="off">2</a></li>
                                    <li><a class="off">3</a></li>
                                    <li><a class="off">4</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="off">5</a></li>
                                    <li><a class="off">6</a></li>
                                    <li><a class="off">7</a></li>
                                    <li><a class="off">8</a></li>
                                    <li><a class="off">9</a></li>
                                    <li><a class="off">10</a></li>
                                    <li><a class="off">11</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="off">12</a></li>
                                    <li><a class="off">13</a></li>
                                    <li><a class="on">14</a></li>
                                    <li><a>15</a></li>
                                    <li><a>16</a></li>
                                    <li><a>17</a></li>
                                    <li><a>18</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a>19</a></li>
                                    <li><a>20</a></li>
                                    <li><a>21</a></li>
                                    <li><a>22</a></li>
                                    <li><a>23</a></li>
                                    <li><a>24</a></li>
                                    <li><a>25</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a>26</a></li>
                                    <li><a>27</a></li>
                                    <li><a>28</a></li>
                                    <li><a>29</a></li>
                                    <li><a>30</a></li>
                                    <li><a class="nm">1</a></li>
                                    <li><a class="nm">2</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="nm">3</a></li>
                                    <li><a class="nm">4</a></li>
                                    <li><a class="nm">5</a></li>
                                    <li><a class="nm">6</a></li>
                                    <li><a class="nm">7</a></li>
                                    <li><a class="nm">8</a></li>
                                    <li><a class="nm">9</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div> -->
                <!-- </div> -->
                <div class="context">
                    <div class="timeDiv">
                        <div class="startDiv on">
                            <!-- <input type="button" class="on" value="2020.04.14 화요일  부터" @click="changeDate(new Date())"> -->
                            <input type="button" :class="{on : is_focused_start != null && is_focused_start == true}" :value="getDateString(true)" @click="changeDate(true)">
                            <!-- <select name="" id="selAmPm" @change="changeStartAmPm($event)">
                                <option :key="amPmIndex"  v-for="(val, amPmIndex) in amPmAll" :value="val.value" :selected="val.value == selectedStartAmPm">{{val.key}}</option> -->
                                <!-- <option value="">종일</option>
                                <option value="" selected>오전</option>
                                <option value="">오후</option> -->
                            <!-- </select> -->
                            <!-- <select name="" id="selHour" @change="changeStartHour($event)">
                                <option :key="hourIndex" v-for="(hour, hourIndex) in hours" :value="hour.value" :selected="isStartHour(hour.value)">{{hour.key}}</option> -->
                                <!-- <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                                <option value="">5</option>
                                <option value="">6</option>
                                <option value="">7</option>
                                <option value="">8</option>
                                <option value="" selected>9</option>
                                <option value="">10</option>
                                <option value="">11</option>
                                <option value="">12</option> -->
                            <!-- </select>
                            <select name="" id="selTime" @change="changeStartMin($event)">
                                <option :key="minIndex" v-for="(minute, minIndex) in minutes" :value="minute.value" :selected="isStartMin(minute.value)">{{minute.key}}</option> -->
                                <!-- <option value="" selected>00</option>
                                <option value="">10</option>
                                <option value="">20</option>
                                <option value="">30</option>
                                <option value="">40</option>
                                <option value="">50</option> -->
                            <!-- </select> -->
                        </div>
                        <div class="endDiv">
                            <!-- <input type="button"  value="2020.04.15 수요일  까지" @click="changeDate(new Date())"> -->
                            <input type="button" :class="{on : is_focused_start == false}" :value="getDateString(false)" @click="changeDate(false)">
                            <!-- <select name="" id="selAmPm" @change="changeEndAmPm($event)">
                                <option :key="amPmIndex"  v-for="(val, amPmIndex) in amPmAll" :value="val.value" :selected="val.value == selectedEndAmPm">{{val.key}}</option> -->
                                <!-- <option value="">종일</option>
                                <option value="">오전</option>
                                <option value="" selected>오후</option> -->
                            <!-- </select>
                            <select name="" id="selHour" @change="changeEndHour($event)">
                                <option :key="hourIndex" v-for="(hour, hourIndex) in hours" :value="hour.value" :selected="isEndHour(hour.value)">{{hour.key}}</option> -->
                                <!-- <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                                <option value="">5</option>
                                <option value="" selected>6</option>
                                <option value="">7</option>
                                <option value="">8</option>
                                <option value="">9</option>
                                <option value="">10</option>
                                <option value="">11</option>
                                <option value="">12</option> -->
                            <!-- </select>
                            <select name="" id="selTime" @change="changeEndMin($event)">
                                <option :key="minIndex" v-for="(minute, minIndex) in minutes" :value="minute.value" :selected="isEndMin(minute.value)">{{minute.key}}</option> -->
                                <!-- <option value="" selected>00</option>
                                <option value="">10</option>
                                <option value="">20</option>
                                <option value="">30</option>
                                <option value="">40</option>
                                <option value="">50</option> -->
                            <!-- </select> -->
                        </div>
                    </div>
                    <div class="detailDiv">
                        <label for="">휴가명</label>
                        <input type="text" placeholder="휴가 이름을 작성하세요 (필수)" :value="day_off_setting_modal_info.day_off != null && day_off_setting_modal_info.day_off.off_name != null ? day_off_setting_modal_info.day_off.off_name : ''" @input="inputOffName($event)"/>
                        <label for="">내용</label>
                        <textarea name="" id="" placeholder="휴가 내용을 작성하세요 (선택)" :value="day_off_setting_modal_info.day_off != null && day_off_setting_modal_info.day_off.off_reason != null ? day_off_setting_modal_info.day_off.off_reason : ''" @input="inputOffReason($event)"></textarea>
                    </div>
                </div>
            </div>
            <div class="btns">
                <input type="button" value="취소" @click="cancel()" />
                <input type="button" value="삭제" @click="deleteDayOff()" v-if="day_off_setting_modal_info.is_create == false" />
                <input type="button" class="save" value="저장" @click="day_off_setting_modal_info.is_create ? createDayOff() : updateDayOff()"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo    = namespace('ModalInfo');

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';

import { hodu_doc_modal_info } from '@/model/hodudoc';
import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HoduInlineDatePicker
    },
}) 
export default class DayOffSettingModal extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State day_off_setting_modal_info !: hodu_doc_modal_info.DayOffSettingModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDayOffSettingModalInfo    ?: (day_off_setting_modal_info : hodu_doc_modal_info.DayOffSettingModalInfo) => void;

    selectedStartAmPm : string = "AM"
    selectedEndAmPm : string = "AM"
    
    is_focused_start: boolean = true
    amPmAll : any;
    hours   : any;
    minutes : any;

    event_bus : Vue = new Vue();

    created() : void {
        this.amPmAll = [{key: "오전", value: "AM"}, {key: "오후", value: "PM"}] //{key: "종일", value: "AllDay"}
        this.hours   = [{key: "1", value: 1}, {key: "2", value: 2}, {key: "3", value: 3}, {key: "4", value: 4}, {key: "5", value: 5}, {key: "6", value: 6}, {key: "7", value: 7}, {key: "8", value: 8}, {key: "9", value: 9}, {key: "10", value: 10}, {key: "11", value: 11}, {key: "12", value: 12}]
        this.minutes = [{key: "00", value: 0}, {key: "10", value: 10}, {key: "20", value: 20}, {key: "30", value: 30}, {key: "40", value: 40}, {key: "50", value: 50}]
    }

    mounted() : void {
        // 오른쪽 시간선택시 
        $(".timeDiv div input").click(function(){
            $(this).parent().siblings().removeClass("on");
            $(this).parent().addClass("on");
            $(this).parent().siblings().find("input").removeClass("on");
            $(this).addClass("on");

        });
        if (this.day_off_setting_modal_info.is_create && this.day_off_setting_modal_info.day_off == null) {
            const start_date = moment(new Date()).set("hours",0).set("minutes",0).set("seconds",0).set("milliseconds",0).toDate();
            const end_Date   = moment(start_date).set("hours",23).set("minutes",59).set("seconds",59).set("milliseconds",0).toDate();

            this.day_off_setting_modal_info.day_off = {
                doctor_off_id   : "",
                biz_id          : "",
                department_code : "",
                doctor_code     : "",
                off_name        : "",
                off_reason      : "",
                start_date      : start_date,
                end_date        : end_Date,
                audit_created   : new Date(),
                audit_created_id: 0,
                audit_deleted   : "",
                audit_deleted_id : 0,
                audit_delete_flag : false
            }
            
        }else{
            
        }

        if (this.day_off_setting_modal_info.day_off != null) {
            this.selectedStartAmPm = moment(this.day_off_setting_modal_info.day_off.start_date).get("hours") >= 12 ? "PM" : "AM"
            this.selectedEndAmPm   = moment(this.day_off_setting_modal_info.day_off.end_date).get("hours") >= 12 ? "PM" : "AM"
            // alert(`${this.selectedStartAmPm} / ${this.selectedEndAmPm}`)
        }
    }

    /**
     * 탭 변경으로 왼쪽 데이트피커 날짜 변경
     */
    changeDate(is_start: boolean) : void {
        this.is_focused_start = is_start
        if ( this.day_off_setting_modal_info.day_off == null ) {
            return 
        }
        if ( is_start ) {
            this.event_bus.$emit('changeDate', this.day_off_setting_modal_info.day_off.start_date);
        }else{
            this.event_bus.$emit('changeDate', this.day_off_setting_modal_info.day_off.end_date);
        }
    }

    changeStartAmPm(event) : void {
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return
        }
        this.selectedStartAmPm = event.target.value as string
        const start_date  = this.day_off_setting_modal_info.day_off.start_date
        const start_hours = moment(start_date).get("hours")
        if (event.target.value == "AM" && start_hours > 12) {
            this.day_off_setting_modal_info.day_off.start_date = moment(start_date).set("hours", start_hours-12).toDate()
        }
        if (event.target.value == "PM" && start_hours < 12) {
            this.day_off_setting_modal_info.day_off.start_date = moment(start_date).set("hours", start_hours+12).toDate()
        }
        if (event.target.value == "AM" && start_hours == 12) {
            this.day_off_setting_modal_info.day_off.start_date = moment(start_date).set("hours", 0).toDate()
        }
    }

    changeStartHour(event) : void {
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return 
        }
        let selected_hour = Number(event.target.value)
        //pm 12시는 점심 12시니까 그대로 12시이고 나머지는 (12 + n)시
        if (this.selectedStartAmPm == "PM" && selected_hour < 12) {
            selected_hour = selected_hour + 12
        }
        //am 12시는 밤 12시 (자정) 이니까 00시
        else if ( this.selectedStartAmPm == "AM" && selected_hour == 12 ) {
            selected_hour = 0
        }
        
        let origin_start_date = this.day_off_setting_modal_info.day_off.start_date
        this.day_off_setting_modal_info.day_off.start_date = moment(origin_start_date).set("hours", selected_hour).toDate()
    }

    changeStartMin(event) : void { 
        const selected_min = event.target.value as number
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return 
        }

        let origin_start_date = this.day_off_setting_modal_info.day_off.start_date
        this.day_off_setting_modal_info.day_off.start_date = moment(origin_start_date).set("minutes", selected_min).toDate()
    }

    changeEndAmPm(event) : void {
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return
        }
        this.selectedEndAmPm = event.target.value as string
        const end_date  = this.day_off_setting_modal_info.day_off.end_date
        const end_hours = moment(end_date).get("hours")
        if (event.target.value == "AM" && end_hours > 12) {
            this.day_off_setting_modal_info.day_off.end_date = moment(end_date).set("hours", end_hours-12).toDate()
        }
        if (event.target.value == "PM" && end_hours < 12) {
            this.day_off_setting_modal_info.day_off.end_date = moment(end_date).set("hours", end_hours+12).toDate()
        }
        if (event.target.value == "AM" && end_hours == 12) {
            this.day_off_setting_modal_info.day_off.end_date = moment(end_date).set("hours", 0).toDate()
        }
    }

    changeEndHour(event) : void {
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return 
        }
        let selected_hour = Number(event.target.value)
        //pm 12시는 점심 12시니까 그대로 12시이고 나머지는 (12 + n)시
        if (this.selectedEndAmPm == "PM" && selected_hour < 12) {
            selected_hour = selected_hour + 12
        }
        //am 12시는 밤 12시 (자정) 이니까 00시
        else if ( this.selectedEndAmPm == "AM" && selected_hour == 12 ) {
            selected_hour = 0
        }
        
        let origin_end_date = this.day_off_setting_modal_info.day_off.end_date
        this.day_off_setting_modal_info.day_off.end_date = moment(origin_end_date).set("hours", selected_hour).toDate()
    }

    changeEndMin(event) : void { 
        const selected_min = event.target.value as number
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return 
        }

        let origin_end_date = this.day_off_setting_modal_info.day_off.end_date
        this.day_off_setting_modal_info.day_off.end_date = moment(origin_end_date).set("minutes", selected_min).toDate()
    }

    isStartHour(selected_hour : number) : boolean {
        if( this.day_off_setting_modal_info.day_off == null ) {
            return false
        }
        let start_hour = moment(this.day_off_setting_modal_info.day_off.start_date).get("hours")

        //시작일이 오후일 때엔 선택일도 오후로 교체 후 비교
        if (start_hour > 12) {
            selected_hour += 12
            return selected_hour == start_hour
        }
        // 시작 시간이 am 0시 or pm 0시 라면 무조건 12로 표기
        else if (start_hour == 0 || start_hour == 12) {
            start_hour = 12
            return selected_hour == start_hour
        }
        //나머지는 그대로
        else{
            return selected_hour == start_hour
        }
    }

    isStartMin(min : number) : boolean {
        if( this.day_off_setting_modal_info.day_off == null ) {
            return false
        }
        let start_min = moment(this.day_off_setting_modal_info.day_off.start_date).get("minutes")
        return min == start_min
    }

    isEndMin(min : number) : boolean {
        if( this.day_off_setting_modal_info.day_off == null ) {
            return false
        }
        let end_min = moment(this.day_off_setting_modal_info.day_off.end_date).get("minutes")
        return min == end_min
    }

    isEndHour(selected_hour : number) : boolean {
        if( this.day_off_setting_modal_info.day_off == null ) {
            return false
        }
        let end_hour = moment(this.day_off_setting_modal_info.day_off.end_date).get("hours")
        
        //시작일이 오후일 때엔 선택일도 오후로 교체 후 비교
        if (end_hour > 12) {
            selected_hour += 12
            return selected_hour == end_hour
        }
        // 시작 시간이 am 0시 or pm 0시 라면 무조건 12로 표기
        else if (end_hour == 0 || end_hour == 12) {
            end_hour = 12
            return selected_hour == end_hour
        }
        //나머지는 그대로
        else{
            return selected_hour == end_hour
        }
    }

    getDateString(is_start: boolean) : string {
        if( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return "" 
        }

        if ( is_start ) {
            let date = moment(this.day_off_setting_modal_info.day_off.start_date).format("YYYY.MM.DD ")
            let dowName = this.getDayName(moment(this.day_off_setting_modal_info.day_off.start_date).get("day"), "요일")
            return date + dowName + " 부터"

        }else{
            let date = moment(this.day_off_setting_modal_info.day_off.end_date).format("YYYY.MM.DD ")
            let dowName = this.getDayName(moment(this.day_off_setting_modal_info.day_off.end_date).get("day"), "요일")
            return date + dowName + " 까지"
        }
    }

    /**
     * datepicker 선택 emit 함수
     */
    datepickerSelectFunction(date_string : string , data : any) : void {
        if( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return
        }
        let selected_date = moment(date_string).toDate()
        let start_date    = new Date(this.day_off_setting_modal_info.day_off.start_date)
        let end_date      = new Date(this.day_off_setting_modal_info.day_off.end_date)

        //시작일 선택시
        if (this.is_focused_start) {
            //시작일에 시간 말고 날짜만 세팅해주고
            start_date = moment(start_date).set("years", selected_date.getFullYear()).set("months", selected_date.getMonth()).set("date", selected_date.getDate()).toDate()
            this.day_off_setting_modal_info.day_off.start_date = start_date

            //! 종료일이 시작일보다 전에 있거나 같으면 다시 뒤로 옮겨준다
            if( moment(start_date).isBefore(end_date) == false ) {
                this.day_off_setting_modal_info.day_off.end_date = moment(start_date).set("hours", 23).set("minutes", 59).set("seconds", 59).toDate()
            }
        }
        //종료일 선택시
        else{
            selected_date = moment(end_date).set("years", selected_date.getFullYear()).set("months", selected_date.getMonth()).set("date", selected_date.getDate()).toDate()

            if( moment(start_date).isBefore(selected_date) ) {
                this.day_off_setting_modal_info.day_off.end_date = selected_date
                
            }else{
                this.day_off_setting_modal_info.day_off.start_date = moment(selected_date).set("hours", 0).set("minutes", 0).set("seconds", 0).toDate()
                this.day_off_setting_modal_info.day_off.end_date = moment(selected_date).set("hours", 23).set("minutes", 59).set("seconds", 59).toDate()
                // this.event_bus.$emit('changeDate', this.day_off_setting_modal_info.day_off.end_date);
            }
        }
    }   
    
    /**
     * datepicker 년, 월 변경 emit 함수
     */
    datepickerChangeFunction(year: number, month: number, inst: any) : void {
        // alert(`${`0${year}`.slice(-2)}년 ${`0${month}`.slice(-2)}월`);
    }

    cancel() : void {
        if( this.doSetDayOffSettingModalInfo ) {
            this.doSetDayOffSettingModalInfo({
                "show_modal" : false,
                "is_create"  : true,
                "is_doctor"  : true,
                "day_off"    : null
            }); 
        }
    }

    validationCheck() : boolean {
        if(this.day_off_setting_modal_info.day_off == null) { return false }
        if( moment(this.day_off_setting_modal_info.day_off.start_date).isBefore(this.day_off_setting_modal_info.day_off.end_date) == false ) {
            alert("종료 시간이 시작 시간보다 빠르거나 같습니다\n다시 설정해주세요")
            return false
        }
        if( this.day_off_setting_modal_info.day_off.off_name.length < 2 ) {
            alert("휴가 이름을 2자 이상 작성해주세요")
            return false
        }
        return true
    }

    createDayOff() : void {
        if (this.day_off_setting_modal_info == null) { return }
        if (this.validationCheck()){
            window['createDayOff'](this.day_off_setting_modal_info.day_off)
            this.cancel()
        }
    }

    updateDayOff() : void {
        if (this.day_off_setting_modal_info == null) { return }
        if (this.validationCheck() == false) { return }
        window['updateDayOff'](this.day_off_setting_modal_info.day_off)
        this.cancel()
    }

    deleteDayOff() : void {
        if (this.day_off_setting_modal_info.day_off == null) { return }
        
        if (confirm(`[${this.day_off_setting_modal_info.day_off.off_name}] 휴가를 삭제하시겠습니까?`)) {
            window['deleteDayOff'](this.day_off_setting_modal_info.day_off)
            this.cancel()
        }
    }

    inputOffName(event) {
        const value = event.target.value;
        
        if( this.day_off_setting_modal_info.day_off != null ) {
            this.day_off_setting_modal_info.day_off.off_name = value;
        }
    }

    inputOffReason(event) {
        const value = event.target.value;
        
        if( this.day_off_setting_modal_info.day_off != null ) {
            this.day_off_setting_modal_info.day_off.off_reason = value;
        }
    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-265px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:530px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    .detail { overflow:hidden; }
    .datepickerDiv { float:left; width: 40%; height: 368px; padding: 25px 25px; box-sizing: border-box; text-align: center; }
    .ui-datepicker-prev, .ui-datepicker-next {font-size:0;display: inline-block;width: 25px;height: 25px;text-align: center;cursor: pointer;background-image: url('../../../assets/images/contents/month_bt_prev.svg');background-repeat: no-repeat;line-height:25px;background-size: 22px;overflow: hidden;}
    .ui-datepicker-prev {float:left;background-position: center center;}
    .ui-datepicker-next { float:right; background-position: center center;}
    .ui-datepicker-prev:hover {background-image: url('../../../assets/images/contents/month_bt_prev_on.svg');}
    .ui-datepicker-next:hover {background-image: url('../../../assets/images/contents/month_bt_next_on.svg');}
    a.ui-datepicker-next {margin-left:5px;background-image: url('../../../assets/images/contents/month_bt_next.svg');}
    .datepickerDiv .yyyy { font-size: 16px; font-weight:bold; margin-bottom: 20px; margin-top:6px; display: inline-block; }

    .calUl .day li { height: 20px; line-height: 20px; }
    .calUl .dayList { height:20px; }
    .calUl > li { display: block; height: 40px;line-height: 40px; }
    .calUl ul  { overflow: hidden; }
    .calUl ul li { float:left; text-align: center; display: inline-block; width:14.2857142857%;}
    .calUl ul li a { font-weight: bold; display:inline-block; width: 30px; height:30px;line-height: 30px; transition:0.2s; border-radius:50%;vertical-align: middle; }
    .calUl ul li a:hover { background:#f1f3f5; }
    .calUl ul li a.on { background:#477fff !important; color:#fff; }
    .calUl ul li a.nm { opacity:0.5 }
    .calUl ul li a.off { opacity:0.3; font-weight: normal; }
    .calUl ul li a.off:hover { background:transparent }
    

    .context { float:left; border-left:1px solid #e7e9ea; width: 60%; height:368px; box-sizing: border-box; }
    
    .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }

    .timeDiv { overflow: hidden; }
    .startDiv, .endDiv { overflow:hidden; width:50%; height: 70px; float:left; }
    .startDiv { border-right:1px solid #477fff; box-sizing: border-box; }
    .startDiv.on select, .endDiv.on select { font-weight:bold; } 
    .startDiv select, .endDiv select { cursor:pointer; box-sizing: border-box; transition: 0.2s; float:left; width: 33.3333333333%; appearance: none; padding: 0 15px;line-height:50px;background-image:url('../../../assets/images/side_area/bt_open.png'); background-position: 95%  center; background-repeat: no-repeat; height: 50px; border-right:1px solid #f1f3f5; border-bottom:1px solid #e7e9ea;}
    .startDiv select:last-child, .endDiv select:last-child { border-right:0; }
    
    .timeDiv select:hover { background-color:#f1f3f5; }
    .timeDiv select:focus { border-bottom:1px solid #477fff; background-color:#fff;background-image:url('../../../assets/images/side_area/bt_close.png'); }
    .timeDiv input[type="button"] { border:none; border-bottom:1px solid #e7e9ea; cursor:pointer;transition:0.2s; background:#fff; font-size:13px; font-weight: normal; padding: 0 15px;height: 70px; line-height: 70px; width: 100%; box-sizing: border-box;  }
    .timeDiv input[type="button"]:hover { background:#f1f3f5;border-color:#477fff; }
    .timeDiv input[type="button"]:focus, .timeDiv input[type="button"].on { background-color:#477fff !important; border-color:#477fff; color:#fff; font-weight: bold;}
    
    .detailDiv { padding: 20px 30px 0; }
    .detailDiv label { height: 25px; display:block; }
    input[type="text"] { margin-bottom:20px; transition:0.2s; font-size:14px; font-weight: bold; padding: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box;  line-height: 50px; height:50px; }
    input[type="text"]:hover { background:#f1f3f5; }
    input[type="text"]:focus { border-color:#477fff; background:#fff; }

    textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 127px; font-size: 13px;line-height: 20px; padding: 15px; height:auto; box-sizing: border-box; font-weight:bold; }
    textarea:hover { border-color:#477fff }
    textarea:focus { background:#fff;  }

    .modal.edit .btns input { width : 33.3%; }
</style>