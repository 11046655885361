<template>
    <div class="doc1">
        
        <Hooper class="chooseDay" ref="hooperSlide" v-if="is_render_hooper" @slide="updateCurrentScrollWeekIndex" :initialSlide="current_scroll_week_index">
            <Slide class="imageSlide" :key="week_row" v-for="week_row in getWeekList()" :style="choose_day_div_style">
                
                <a class="date" :key="day.getTime()" v-for="(day, index) in getWeekDayList(week_row - 1)"
                   :id="`choose_day_${index}`" 
                   :class="{ 
                       disabled : new Date().getTime() > day.getTime(),

                       on : selected_date.getFullYear() == day.getFullYear() &&
                            selected_date.getMonth()    == day.getMonth()    && 
                            selected_date.getDate()     == day.getDate(),

                       sun : day.getDay() == 0,
                       sat : day.getDay() == 6,
                       holiday : isHoliday(day) == true,  

                    }" @click="selectDate(day)" :style="choose_day_a_style">

                    {{ `0${day.getDate()}`.slice(-2) }}
                    <span class="day">{{ getDayOfWeekByDate(day, '요일') }}</span>

                </a>

            </Slide>
        </Hooper>

        <!-- <div id="choose_day_scroll"> 
            <div class="chooseDay" :style="choose_day_div_style">
                <a class="date" :key="day.getTime()" v-for="(day, index) in day_list"
                   :id="`choose_day_${index}`" 
                   :class="{ 
                       disabled : new Date().getTime() > day.getTime(),
                       on : selected_date.getFullYear() == day.getFullYear() &&
                            selected_date.getMonth()    == day.getMonth()    && 
                            selected_date.getDate()     == day.getDate() 
                    }" @click="selectDate(day)" :style="choose_day_a_style">

                     {{ `0${day.getDate()}`.slice(-2) }}
                    <span class="day">{{ getDayOfWeekByDate(day, '요일') }}</span>

                </a> -->

                <!-- 선택할 수 없는 이전 요일시 class추가 disabled -->
                <!-- <a class="date disabled">15<span class="day">일요일</span></a> 
                <a class="date disabled">16<span class="day">월요일</span></a> 
                <a class="date disabled">17<span class="day">화요일</span></a> 
                <a class="date disabled">18<span class="day">수요일</span></a>
                <a class="date on">19<span class="day">목요일</span></a> 
                <a class="date">20<span class="day">금요일</span></a> 
                <a class="date">21<span class="day">토요일</span></a> 
                  
            </div>
        </div> -->

        <div id="available_list_left_scroll" class="availDiv">
            <ul class="availabilityUl">

                <li :key="available.original_timetable" v-for="available in available_list"
                    :class="{
                        full : available.coalesce >= available.reservation_term_by_people,
                        after : available.available == true,
                    }">
                    <a @click="showAppointmentCreateModal(available)">
                        <span class="time">{{ hodu_date_to_format_string(available.original_timetable, "hh:mm") }}</span>
                        <span class="amPm">{{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(available.original_timetable, "a")) }}</span>
                        <span class="avail">{{ `${available.coalesce} / ${available.reservation_term_by_people}` }}</span>
                    </a>
                </li>

                <!-- <li><a><span class="time">9:00</span><span class="amPm">am</span><span class="avail">4 / 5</span></a></li> -->

                <!-- 예약완료인 list 클래스추가 full -->
                <!-- <li class="full"><a><span class="time">9:15</span><span class="amPm">am</span><span class="avail">5 / 5</span></a></li>
                <li class="full"><a><span class="time">9:30</span><span class="amPm">am</span><span class="avail">5 / 5</span></a></li>
                <li><a><span class="time">9:45</span><span class="amPm">am</span><span class="avail">3 / 5</span></a></li>
                <li><a><span class="time">5:00</span><span class="amPm">am</span><span class="avail">3 / 5</span></a></li>
                <li><a><span class="time">5:15</span><span class="amPm">am</span><span class="avail">3 / 5</span></a></li>
                <li class="full"><a><span class="time">5:30</span><span class="amPm">am</span><span class="avail">5 / 5</span></a></li>
                <li class="full"><a><span class="time">5:45</span><span class="amPm">am</span><span class="avail">5 / 5</span></a></li>
                <li class="full"><a><span class="time">11:00</span><span class="amPm">am</span><span class="avail">5 / 5</span></a></li>
                <li><a><span class="time">11:15</span><span class="amPm">am</span><span class="avail">4 / 5</span></a></li>
                <li class="full"><a><span class="time">11:30</span><span class="amPm">am</span><span class="avail">5 / 5</span></a></li>
                <li class="full"><a><span class="time">11:45</span><span class="amPm">am</span><span class="avail">5 / 5</span></a></li>
                <li><a><span class="time">12:00</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li><a><span class="time">12:15</span><span class="amPm">pm</span><span class="avail">2 / 5</span></a></li>
                <li><a><span class="time">12:30</span><span class="amPm">pm</span><span class="avail">3 / 5</span></a></li>
                <li><a><span class="time">12:45</span><span class="amPm">pm</span><span class="avail">2 / 5</span></a></li>
                <li><a><span class="time">1:00</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li><a><span class="time">1:15</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li class="full"><a><span class="time">1:30</span><span class="amPm">pm</span><span class="avail">5 / 5</span></a></li>
                <li class="full"><a><span class="time">1:45</span><span class="amPm">pm</span><span class="avail">5 / 5</span></a></li>
                <li><a><span class="time">2:00</span><span class="amPm">pm</span><span class="avail">3 / 5</span></a></li>
                <li><a><span class="time">2:15</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li class="full"><a><span class="time">2:30</span><span class="amPm">pm</span><span class="avail">5 / 5</span></a></li> -->

                <!-- 등록 가능한 시간대 list 클래스추가 after -->
                <!-- <li class="after"><a @click="showAppointmentCreateModal"><span class="time">2:45</span><span class="amPm">pm</span><span class="avail">2 / 5</span></a></li>
                <li class="after"><a><span class="time">3:00</span><span class="amPm">pm</span><span class="avail">0 / 5</span></a></li> -->

                <!-- 예약완료인 list 클래스추가 full -->
                <!-- <li class="after full"><a><span class="time">3:15</span><span class="amPm">pm</span><span class="avail">5 / 5</span></a></li>
                <li class="after"><a><span class="time">3:30</span><span class="amPm">pm</span><span class="avail">0 / 5</span></a></li>
                <li class="after"><a><span class="time">3:45</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li class="after full"><a><span class="time">4:00</span><span class="amPm">pm</span><span class="avail">5 / 5</span></a></li>
                <li class="after"><a><span class="time">4:15</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li class="after full"><a><span class="time">4:30</span><span class="amPm">pm</span><span class="avail">5 / 5</span></a></li>
                <li class="after"><a><span class="time">4:45</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li class="after"><a><span class="time">5:00</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li class="after"><a><span class="time">5:15</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li class="after"><a><span class="time">5:30</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li class="after full"><a><span class="time">5:45</span><span class="amPm">pm</span><span class="avail">5 / 5</span></a></li>
                <li class="after"><a><span class="time">6:00</span><span class="amPm">pm</span><span class="avail">2 / 5</span></a></li>
                <li class="after"><a><span class="time">6:15</span><span class="amPm">pm</span><span class="avail">4 / 5</span></a></li>
                <li class="after"><a><span class="time">6:30</span><span class="amPm">pm</span><span class="avail">0 / 5</span></a></li>
                <li class="after"><a><span class="time">6:45</span><span class="amPm">pm</span><span class="avail">3 / 5</span></a></li>
                <li class="after"><a><span class="time">7:00</span><span class="amPm">pm</span><span class="avail">2 / 5</span></a></li>
                <li class="after"><a><span class="time">7:15</span><span class="amPm">pm</span><span class="avail">2 / 5</span></a></li>
                <li class="after"><a><span class="time">7:30</span><span class="amPm">pm</span><span class="avail">1 / 5</span></a></li>
                <li class="after full"><a><span class="time">7:45</span><span class="amPm">pm</span><span class="avail">5 / 5</span></a></li> -->
            </ul>
            <div class="btmLine"></div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object } from '@/model/hodudoc';

import moment from 'moment';

import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        Hooper, Slide
    },
})
export default class AppointmentAvailableListLeft extends Mixins(VueHoduCommon) {

    // this.$refs.hooperSlide 사용
    $refs !: { hooperSlide : HTMLFormElement; }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentCreateModalInfo ?: (params : hodu_doc_modal_info.AppointmentCreateModalInfo) => void;

    /**
     * Prop
     */
    @Prop() event_bus          !: Vue;
    @Prop() max_date           !: Date;
    @Prop() selected_date      !: Date;
    @Prop() selected_doctor    !: hodu_doc_object.doctor_info;
    @Prop() is_open_right_list !: boolean;

    /**
     * variable
     */
    available_list : hodu_doc_object.appointment_available_time_info[] = [];
    day_list : Date[] = [];
    holi_days : any[] = [];
    
    choose_day_div_style = "";
    choose_day_a_style = "";

    current_scroll_week_index : number = 0;

    is_render_hooper : boolean = true;

    async mounted() : Promise<void> {

        if( this.event_bus != null ) { 
            this.event_bus.$on('leftAvailableListResize', this.handleResize);
            this.event_bus.$on('rerenderHooper', this.rerenderHooper); 
        }

        // 날짜 리스트 제작
        // this.day_list.splice(0, this.day_list.length); 
        // for( let i = 0; i < 7; i++ ) {
        //     const date_diff : number = i - this.selected_date.getDay();

        //     const target_date = new Date(this.selected_date);
        //     target_date.setDate(target_date.getDate() + date_diff);
        //     target_date.setHours(23);
        //     target_date.setMinutes(59);
        //     target_date.setSeconds(59);
        //     target_date.setMilliseconds(999);

        //     this.day_list.push(target_date);
        // }

        // 스크롤 하는 경우 (전부 만드는 경우)
        let current_max_diff : number = this.getDateDiff(this.selected_date, this.max_date)
        this.day_list.splice(0, this.day_list.length); 
        for( let i = 0; i <= current_max_diff; i++ ) {
            const date_diff : number = i - new Date().getDay();

            if( date_diff < 0 ) { current_max_diff++; }

            const target_date = new Date(this.selected_date);
            target_date.setDate(target_date.getDate() + date_diff);
            target_date.setHours(23);
            target_date.setMinutes(59);
            target_date.setSeconds(59);
            target_date.setMilliseconds(999);

            this.day_list.push(target_date);
        }

        // 공휴일 조회
        this.holi_days = await this.hodu_get_holidays(new Date(), this.max_date);

        if( this.selected_doctor != null ) { this.getAvailableAppointmentTime(); }

        this.$nextTick(() => { this.setScroll(); });

        // 재조회용
        window['leftSelectAvailableList'] = this.getAvailableAppointmentTime;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const choose_day_height : number = 67;  // 높이가 순간적으로 변해서 고정값 67px로 사용 

        // @ts-ignore
        $('#available_list_left_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - choose_day_height
        });

        setTimeout(() => {
            this.$nextTick(() => {
                const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
                const right_area_controller_width : number | undefined = $('#right_area_controller').outerWidth();
                const doc2_width : number | undefined = $('.doc2').outerWidth();

                const choose_day_scroll_width : number = window.innerWidth - (left_area_width ? left_area_width : 0)
                                                                            - (right_area_controller_width ? right_area_controller_width : 0)
                                                                            - (doc2_width ? doc2_width : 0)
                
                const date_a_width : number = choose_day_scroll_width / 7;
                this.choose_day_a_style   = `width : ${date_a_width}px !important`;
                this.choose_day_div_style = `width : ${choose_day_scroll_width}px !important`;
            });
        }, 100);
        
        // this.$nextTick(() => {
        //     const choose_day_scroll_width : number = window.innerWidth - (left_area_width ? left_area_width : 0)
        //                                                                 - (right_area_controller_width ? right_area_controller_width : 0)
        //                                                                 - (doc2_width ? doc2_width : 0)
               
        //     const date_a_width : number = choose_day_scroll_width / 7;
        //     this.choose_day_a_style   = `width : ${date_a_width}px !important`;
        //     this.choose_day_div_style = `width : ${choose_day_scroll_width}px !important`;

        //     // @ts-ignore
        //     $('#choose_day_scroll').mCustomScrollbar({
        //         axis : 'x',
        //         scrollbarPosition : 'outside',
        //         mouseWheel:{ 
        //             scrollAmount: choose_day_scroll_width,
        //             preventDefault : true, 
        //         },
        //         scrollInertia : 200,
        //         autoDraggerLength : false,
        //         setWidth : choose_day_scroll_width,
        //         whileScrolling : () => {

        //         },
        //         onScroll : () => {

        //         }
        //     });

        //     // @ts-ignore
        //     // $('#choose_day_scroll').mCustomScrollbar("disable", true);
        // })
    }

    /**
     * 해당 날짜의 예약 가능, 불가능 여부 정보 조회
     */
    getAvailableAppointmentTime() : void {

        // @ts-ignore
        $('#available_list_left_scroll').mCustomScrollbar('scrollTo', 'top', { scrollInertia : 0 });

        const start = moment(this.selected_date).format('YYYY-MM-DD'); // 조회일
        const department_code = this.selected_doctor.department_code;  // 진료과 코드
        const doctor_code = this.selected_doctor.doctor_code;          // 의사 코드

        if( department_code == null || department_code.length < 1 || doctor_code == null || doctor_code.length < 1 ) { return; }

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/availability?start=${start}&department_code=${department_code}&doctor_code=${doctor_code}`, API_METHOD.GET)
            .then((response) => {
                this.available_list.splice(0, this.available_list.length);
                this.available_list = this.available_list.concat(response.data.data);

                // 조회 결과가 없는 경우 throw?
                if( this.available_list.length < 1 ) {
                    return;
                }
                
                // 오늘 날짜인 경우 이미 시간이 지난 예약에 대해서 삭제한다
                const current_day : Date = new Date();
                if( this.selected_date.getFullYear() == current_day.getFullYear() &&
                    this.selected_date.getMonth()    == current_day.getMonth()    && 
                    this.selected_date.getDate()     == current_day.getDate() ) {

                    // 60 - (reservation_by_term) 분 미만이라면 현재 시각의 0분 보다 작은 경우 없앤다
                    // 60 - (reservation_by_term) 분 이상이라면 1시간후의 0분 일때 보다 작은 데이터는 없앤다
                    if( current_day.getMinutes() >= (60 - this.available_list[0].reservation_term) ) { current_day.setHours(current_day.getHours() + 1) }
                    current_day.setMinutes(0);
                    current_day.setSeconds(0);
                    current_day.setMilliseconds(0);
                    
                    this.available_list = this.available_list.filter((item) => moment(item.original_timetable).toDate().getTime() >= current_day.getTime());

                    // this.available_list.length < 1인 경우 어떻게 할 것 인지? (다음 일자 조회?)
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false); 
            });
    }

    /**
     * 예약 등록 모달
     */
    showAppointmentCreateModal(available : hodu_doc_object.appointment_available_time_info) : void {
        if( available.available == false ) { return; }

        if( this.doSetAppointmentCreateModalInfo ) {
            
            this.doSetAppointmentCreateModalInfo({ 
                "show_modal"            : true,
                "is_create_one_patient" : false,
                "department_code"       : available.department_code,
                "doctor_code"           : available.doctor_code,
                "original_timetable"    : new Date(available.original_timetable), 
            }); 
        } 
    }

    /**
     * 날짜 선택
     */
    selectDate(date : Date) : void {
        if( (date.getFullYear() == this.selected_date.getFullYear() &&
             date.getMonth()    == this.selected_date.getMonth()    &&
             date.getDate()     == this.selected_date.getDate() )   || 
             date.getTime()      < new Date().getTime() ) { 
            return; 
        }
        date = date instanceof Date ? date : new Date(date);
        this.$emit('selectDate', date);
    }

    /**
     * 상단 날짜 메뉴 몇 주 까지 있는지 반환
     */
    getWeekList() : number {
        return Math.ceil(this.day_list.length / 7);
    }

    /**
     * 해당 주에 어떤 날짜들이 나올지 반환
     */
    getWeekDayList(week_row : number) : Date[] {
        return this.day_list.slice(week_row * 7, week_row * 7 + 7);
    }

    /**
     * 공휴일 여부 반환
     */
    isHoliday(date : Date) : boolean {
        return this.holi_days.filter( item => item.solar_ymd == moment(date).format("YYYY-MM-DD") ).length > 0;
    } 

    /**
     * Hooper Slide 변경 될 때 마다 index 업데이트
     */
    updateCurrentScrollWeekIndex(payload : any) : void {
        this.current_scroll_week_index = payload.currentSlide;
    }

    /**
     * Hooper 리스트 다시 렌더링
     */
    rerenderHooper() : void {
        this.is_render_hooper = false;
        setTimeout(() => { this.is_render_hooper = true; }, 100);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#available_list_left_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        // $('#choose_day_scroll').mCustomScrollbar('destroy');

        // this.choose_day_div_style = "width : 0 !important";
        // this.choose_day_a_style = "width : 0 !important";
    	this.setScroll();
    }
    
    @Watch('is_open_right_list')
    changedIsOpenRightList() : void {
        setTimeout(() => { this.handleResize(); }, 100);
    }

    /**
     * 다른 의사를 선택한 경우
     */
    @Watch('selected_doctor')
    chagnedSelectedDoctor() : void {
        this.getAvailableAppointmentTime();
    }

    /**
     * 날짜 선택이 변경 된 경우
     */
    @Watch('selected_date')
    chagnedSelectedDate() : void {
        this.getAvailableAppointmentTime();

        try {
            const date : Date = this.day_list.filter((item) => item.getFullYear() == this.selected_date.getFullYear() &&
                                                        item.getMonth() == this.selected_date.getMonth() &&
                                                        item.getDate() == this.selected_date.getDate() )[0];

            
            this.current_scroll_week_index = Math.floor(this.day_list.indexOf(date) / 7);
            
            this.$refs.hooperSlide.slideTo(this.current_scroll_week_index);

            // @ts-ignore
            // $('#choose_day_scroll').mCustomScrollbar('scrollTo', `#choose_day_${this.current_scroll_week_index * 7}`);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        
    }

}
</script>

<style scoped>
    .reservation_create.leftOn .doc1 .availabilityUl li {width: 25%; transition: 0.1s; }
    .reservation_create.leftOn .doc1 { width: 60%; transition: 0.1s;  float: left; overflow:hidden; }

    .chooseDay { height: auto; max-height: 65px; }
    .chooseDay .date { font-size: 16px;font-weight:bold; }
    .chooseDay { overflow: hidden; padding-top:15px;}
    .chooseDay a { transition:0.2s; display:inline-block; width: 14.28%; text-align: center; float: left; height:50px; border-bottom: 2px solid #e7e9ea; }
    .chooseDay span {transition:0.2s; display: inline-block;width:100%; color:#a4a6ab; font-size: 12px;line-height: 15px; margin-top:5px; }
    .chooseDay a:hover, .chooseDay a:hover span  { color:#232f4c; }
    .chooseDay a.on { border-color: #477fff !important; color:#232f4c; cursor:default !important; }
    .chooseDay a.on span { color:#232f4c; }

    .chooseDay a.disabled, .chooseDay a.disabled .date { color:#a4a6ab !important; opacity: 0.5 !important; cursor:default; }
    .chooseDay a.disabled:hover span  { color:#a4a6ab !important; }
    .chooseDay a:hover { border-color: #cbd1e0; }
    .chooseDay a.disabled:hover { border-color: #e7e9ea }

    .availabilityUl { overflow:hidden;  }
    .availDiv { position: relative; }
    .availDiv .btmLine { display:block;content:''; width:100%; height:1px; background: #e7e9ea; position: absolute;bottom: 0;left: 0; z-index: 1}
    .availabilityUl li { float:left; width: 10%; display: inline-block; text-align: center;height:auto;box-sizing:border-box; border-bottom:1px solid #e7e9ea; border-right:1px solid #e7e9ea;}
    .availabilityUl li:hover a { cursor:default; }
    .availabilityUl li a { display:block; width:100%; padding: 15px 0;}
    .availabilityUl li.after:hover { border-bottom:1px solid #477fff; }
    .availabilityUl li span { opacity:0.4; display:block; line-height: 15px; font-weight:bold; }
    .availabilityUl li.after span { opacity: 1; font-weight:bold;} 
    .availabilityUl li a { background:#f6f7f9; }
    .availabilityUl li.after a { background:#fff; } 
    .availabilityUl li.after:hover a { cursor: pointer; }
    .availabilityUl li .avail { transition:0.2s; margin-top:10px; display:inline-block; border-radius: 15px; border:1px solid #e7e9ea; padding: 0 10px; line-height: 20px; }
    .availabilityUl li.after:hover .avail { background:#447fff; color:#fff; border-color:#477fff; }
    .availabilityUl li.full .avail { background:#c5ccda !important; color:#fff; border-color: #c5ccda !important; }
    .availabilityUl li.full span { opacity:0.4; }
    .availabilityUl li.full a { background: #f6f7f9; }
    .availabilityUl li.full:hover a { cursor: default; }
    .availabilityUl li.full:hover {  border-bottom:1px solid #e7e9ea; }
    
    #container #section_ce.left_area_close .schedule_box {padding-left: 61px;box-sizing: border-box;}
    .left_area_close .content {overflow: hidden;padding-left: 61px !important;}
    .section_ce_fix  .content {  margin-top:0 !important;  }

    /* 일요일, 휴일 */
    .chooseDay li a.sun, .chooseDay li a.sun span, .chooseDay li a.holiday, .chooseDay li a.holiday span { color: #ff3c3c !important; }
     
</style>