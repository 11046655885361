<template>
    <div class="rightAreaDiv" v-if="selected_patient != null" :class="{ nothing : file_list.length < 1 }">
        <!-- 파일 없을 시 -->
        <div class="noResult">
            <p>오른쪽 하단 플러스를 클릭해 파일을 추가하세요</p>
        </div>
        <!-- 헤더소트 -->
        <ul class="sortHeader">
            <li class="checkList">
                <p>
                    <input type="checkbox" id="selectAllFiles" @change="allFileCheck($event.target.checked)" v-model="is_all_selected"/>
                    <label for="selectAllFiles"></label>
                </p>
            </li>

            <li class="no">No</li>

            <li class="name headerSort" @click.prevent="sortTypeChange('NAME')"
                :class="{
                    sortOff  : sort_type != 'NAME',
                    sortUp   : sort_type == 'NAME' && sort_dir == 'ASC',
                    sortDown : sort_type == 'NAME' && sort_dir == 'DESC'
                }"><span>파일명</span>
            </li>

            <li class="size headerSort" @click.prevent="sortTypeChange('SIZE')"
                :class="{
                    sortOff  : sort_type != 'SIZE',
                    sortUp   : sort_type == 'SIZE' && sort_dir == 'ASC',
                    sortDown : sort_type == 'SIZE' && sort_dir == 'DESC'
                }"><span>용량</span>
            </li>

            <li class="date headerSort"  @click.prevent="sortTypeChange('DATE')"
                :class="{
                    sortOff  : sort_type != 'DATE',
                    sortUp   : sort_type == 'DATE' && sort_dir == 'ASC',
                    sortDown : sort_type == 'DATE' && sort_dir == 'DESC'
                }">
                <!-- <a class="sort"> -->
                    등록일
                <!-- </a> -->
                <!-- <div class="dropdown">
                    <ul>
                        <li>
                            <input type="checkbox" value="2019" />
                            <label for=""><span>2019</span></label>
                        </li>
                        <li>
                            <input type="checkbox" value="2020" />
                            <label for=""><span>2020</span></label>
                        </li>
                        <li>
                            <input type="checkbox" value="2021" />
                            <label for=""><span>2021</span></label>
                        </li>
                    </ul>
                </div> -->
            </li> 
        </ul>

        <div id="patient_filebox_file_list_scroll" class="scroll"> <!-- 스크롤 -->

            <!-- 파일함 파일 리스트  -->
            <ul class="fileUl">

                <li :key="file.file_uuid" v-for="(file, index) in computedFileSortList">
                    <p class="checkList">
                        <input type="checkbox" :id="`file_${index}`" v-model="file.checked" @change="fileCheckChanged"/>
                        <label :for="`file_${index}`"></label>
                    </p>
                    <p class="no">{{ index + 1 }}</p>
                    <p class="name">
                        <span class="preview" :class="{ format : new RegExp('image').test(file.file_type) == false }" :style="{ backgroundImage : getBackgroundImage(file) }">
                            {{ new RegExp('image').test(file.file_type) == false 
                                ? file.file_name.substring(file.file_name.lastIndexOf('.') + 1)
                                : 'preview' }}
                        </span>
                        <span>{{ file.file_name }}</span>
                    </p>
                    <p class="size"><span>{{ getFileSizeText(file.file_size) }}</span></p>
                    <p class="date">
                        <span>
                            {{ file.audit_created ? `${hodu_date_to_format_string(new Date(file.audit_created), 'YY.MM.DD')} ${getDayOfWeekByDate(new Date(file.audit_created))}` : '' }}
                        </span>
                    </p>

                    <input type="button" value="다운로드" class="download" @click="downloadSingleFile(file)">
                    <!-- 삭제 권한 체크 -->
                    <input type="button" value="삭제" class="del" @click="deleteSingleFile(file)">
                </li>

                <!-- <li>
                    <p class="checkList">
                        <input type="checkbox" id="file1"/>
                        <label for="file1"></label>
                    </p>
                    <p class="no">1</p>
                    <p class="name"><span class="preview">preview</span><span>xray_20200403.jpg</span></p>
                    <p class="size"><span>1 MB</span></p>
                    <p class="date"><span>20.04.06 월</span></p>
                    <input type="button" value="삭제" class="del">
                </li>
                <li>
                    <p class="checkList">
                        <input type="checkbox" id="file2"/>
                        <label for="file2"></label>
                    </p>
                    <p class="no">2</p>
                    <p class="name"><span class="preview">preview</span><span>xray_20200413.jpg</span></p>
                    <p class="size"><span>1.5 MB</span></p>
                    <p class="date"><span>20.04.16 목</span></p>
                    <input type="button" value="삭제" class="del">
                </li>
                <li>
                    <p class="checkList">
                        <input type="checkbox" id="file3"/>
                        <label for="file3"></label>
                    </p>
                    <p class="no">3</p>
                    <p class="name"><span class="preview format">pdf</span><span>김이정_건강검진표.pdf</span></p>
                    <p class="size"><span>1 MB</span></p>
                    <p class="date"><span>20.02.06 목</span></p>
                    <input type="button" value="삭제" class="del">
                </li>
                <li>
                    <p class="checkList">
                        <input type="checkbox" id="file4"/>
                        <label for="file4"></label>
                    </p>
                    <p class="no">4</p>
                    <p class="name"><span class="preview format">pdf</span><span>김이정_건강검진표.pdf</span></p>
                    <p class="size"><span>1.5 MB</span></p>
                    <p class="date"><span>19.11.02 목</span></p>
                    <input type="button" value="삭제" class="del">
                </li>
                <li>
                    <p class="checkList">
                        <input type="checkbox" id="file5"/>
                        <label for="file5"></label>
                    </p>
                    <p class="no">5</p>
                    <p class="name"><span class="preview">mp4</span><span>김이정_대장내시경.mp4</span></p>
                    <p class="size"><span>35 MB</span></p>
                    <p class="date"><span>19.10.16 목</span></p>
                    <input type="button" value="삭제" class="del">
                </li>
                <li>
                    <p class="checkList">
                        <input type="checkbox" id="file6"/>
                        <label for="file6"></label>
                    </p>
                    <p class="no">6</p>
                    <p class="name"><span class="preview format">pdf</span><span>피검사.pdf</span></p>
                    <p class="size"><span>1 MB</span></p>
                    <p class="date"><span>19.07.06 월</span></p>
                    <input type="button" value="삭제" class="del">
                </li>
                <li>
                    <p class="checkList">
                        <input type="checkbox" id="file7"/>
                        <label for="file7"></label>
                    </p>
                    <p class="no">7</p>
                    <p class="name"><span class="preview format">pdf</span><span>피검사.pdf</span></p>
                    <p class="size"><span>1.5 MB</span></p>
                    <p class="date"><span>19.04.16 목</span></p>
                    <input type="button" value="삭제" class="del">
                </li>
                <li>
                    <p class="checkList">
                        <input type="checkbox" id="file8"/>
                        <label for="file8"></label>
                    </p>
                    <p class="no">8</p>
                    <p class="name"><span class="preview">mp4</span><span>김이정_위내시경.mp4</span></p>
                    <p class="size"><span>28 MB</span></p>
                    <p class="date"><span>19.04.06 월</span></p>
                    <input type="button" value="삭제" class="del">
                </li>
                <li>
                    <p class="checkList">
                        <input type="checkbox" id="file9"/>
                        <label for="file9"></label>
                    </p>
                    <p class="no">9</p>
                    <p class="name"><span class="preview format">pdf</span><span>김이정_건강검진표.pdf</span></p>
                    <p class="size"><span>3 MB</span></p>
                    <p class="date"><span>18.02.03 수</span></p>
                    <input type="button" value="삭제" class="del">
                </li>
                <li>
                    <p class="checkList">
                        <input type="checkbox" id="file10"/>
                        <label for="file10"></label>
                    </p>
                    <p class="no">10</p>
                    <p class="name"><span class="preview format">pdf</span><span>김이정_건강검진표.pdf</span></p>
                    <p class="size"><span>3.8 MB</span></p>
                    <p class="date"><span>17.02.16 목</span></p>
                    <input type="button" value="삭제" class="del">
                </li> -->
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { FILE_SIZE, API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';
import { hodu_doc_object, hodu_doc_table } from '@/model/hodudoc';

import JSZip from 'jszip';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {

    },
})
export default class PatientFileBoxFileList extends Mixins(VueHoduCommon) {

    /**
     * 파일 정렬 리스트
     */
    get computedFileSortList() : hodu_doc_table.t_hospital_patient_file[] {
        
        let target_list = JSON.parse(JSON.stringify(this.file_list));

        target_list = target_list.sort((item1, item2) : number => {

            // 파일 이름 기준 정렬
            if( this.sort_type == 'NAME' ) {

                if( item1.file_name == item2.file_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_name < item2.file_name ? -1 : 1;
                }

                return item1.file_name > item2.file_name ? -1 : 1;

            }

            // 파일 용량 기준 정렬
            if( this.sort_type == 'SIZE' ) {

                if( item1.file_size == item2.file_size ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_size < item2.file_size ? -1 : 1;
                }

                return item1.file_size > item2.file_size ? -1 : 1;

            }

            // 파일 올린 날짜 기준 정렬
            if( this.sort_type == 'DATE' ) {

                if( new Date(item1.audit_created).getTime() == new Date(item2.audit_created).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item1.audit_created).getTime() < new Date(item2.audit_created).getTime() ? -1 : 1;
                }

                return new Date(item1.audit_created).getTime() > new Date(item2.audit_created).getTime() ? -1 : 1;

            }

            return 0;
        })

        return target_list;
    }

    @Prop() event_bus !: Vue;

    @Prop() selected_patient !: hodu_doc_object.patient_info | null;
    
    file_list : hodu_doc_table.t_hospital_patient_file[] = [];
    
    is_all_selected : boolean = false;

    // 정렬
    sort_type : 'NAME' | 'SIZE' | 'DATE' = 'DATE';
    sort_dir  : SORT_TYPE = SORT_TYPE.DESC;

    mounted() : void {

        
        if( this.event_bus != null ) { 
            this.event_bus.$on('fileListResize', this.handleResize);
            this.event_bus.$on('patientFileDelete', this.patientFileDelete);
            this.event_bus.$on('patientFileDownload', this.patientFileDownload);
            this.event_bus.$on('reselectFileList', this.getPatientsFileboxInfo); 
        }

        this.setScroll();

        // 리스트로 보이기
        // $(".btnBox .dpTwo").click(function(){
        //     $(".rightAreaDiv").addClass("dp2");
        //     $(".btnBox .dpOne").show();
        //     $(this).hide();

        // });

        // 박스형으로 보이기
        // $(".btnBox .dpOne").click(function(){
        //     $(".rightAreaDiv").removeClass("dp2");
        //     $(".btnBox .dpTwo").show();
        //     $(this).hide();

        // });
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        this.$nextTick(() => {
            const title_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

            // @ts-ignore
            $('#patient_filebox_file_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
            });
        });

    }

    /**
     * 환자 파일함 조회
     */
    getPatientsFileboxInfo() : void {

        if ( this.selected_patient == null ) { return; }

        const patient_id : number = this.selected_patient.user_id;

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${patient_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.file_list.splice(0, this.file_list.length)
                this.file_list = this.file_list.concat(response.data.data);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    
    /**
     * 파일 삭제 API
     */
    deletePatientsFileboxFile(files : hodu_doc_table.t_hospital_patient_file[]) : void {

        if( this.selected_patient == null ) { return; }

        const patient_id : number = this.selected_patient.user_id;

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${patient_id}`, API_METHOD.DELETE, { "files" : files })
            .then((response) => {
                console.log(response);
                this.get_hodu_d_info(this.scope_group_team_option.biz_id);
                this.getPatientsFileboxInfo();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })

    }

    /**
     * 선택 된 모든 파일 삭제 클릭 이벤트
     */
    patientFileDelete() : void {
        
        const selected_files : hodu_doc_table.t_hospital_patient_file[] = this.computedFileSortList.filter(item => item.checked == true);

        if( selected_files.length < 1 ) {
            this.hodu_show_dialog('alert', '삭제 할 파일을 선택해주세요', ["확인"], [() => {}]);
            return;
        }

        this.hodu_show_dialog('cancel', `파일을 ${ this.is_all_selected ? '전부 ' : '' }삭제 하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => { this.deletePatientsFileboxFile(selected_files); }
        ]);
    }

    /**
     * 한개의 파일만 삭제 클릭 이벤트
     */
    deleteSingleFile(file : hodu_doc_table.t_hospital_patient_file) : void {

        this.hodu_show_dialog('cancel', `${file.file_name}\n파일을 삭제 하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => { this.deletePatientsFileboxFile([file]); }
        ]);

    }

    /**
     * 선택 된 모든 파일 다운로드 클릭 이벤트
     */
    async patientFileDownload() : Promise<void> {
        
        const selected_files : hodu_doc_table.t_hospital_patient_file[] = this.computedFileSortList.filter(item => item.checked == true);

        if( selected_files.length < 1 ) {
            this.hodu_show_dialog('alert', '다운로드 할 파일을 선택해주세요', ["확인"], [() => {}]);
            return;
        }

        // 파일 1개라면
        if( selected_files.length == 1 ) {
            this.downloadSingleFile(selected_files[0]);
            return;
        }

        // 파일 여러개는 zip 파일로 받는다

        await this.hodu_show_indicator();

        const zip = new JSZip();

        const file_promise : Promise<void>[] = [];

        for( const file of selected_files ) {

            // 파일 다운로드 Promise
            const promise = this.hodu_download(`/app_images/${file.file_path}`, "", true)
                .then((response) => {
                    console.log(response);

                    // 다운로드한 파일은 zip 파일 구성에 추가
                    zip.file(file.file_name, new Blob([response.data]));
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });

            // 병렬처리용 Promise 배열에 넣기
            file_promise.push(promise);
        }

        Promise.all(file_promise)
            .then(async() => {

                // zip 파일 압축
                await zip.generateAsync({type:"blob"}).then((content) => {
                    
                    // 압축 완료시 나오는 blob으로 다운로드
                    try {
                        this.hodu_download_blob(content, `hodu_download_${new Date().getTime()}.zip`);
                    } catch(e) {
                        this.hodu_error_process(e, true, false);
                    }

                });
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            }).finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 한개의 파일만 다운로드
     */
    downloadSingleFile(file : hodu_doc_table.t_hospital_patient_file) : void {
        this.hodu_download(`/app_images/${file.file_path}`, file.file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 모든 파일 체크 / 체크 해제
     */
    allFileCheck(is_checked : boolean) : void {
        
        for( const file of this.file_list ) {
            const temp_file : hodu_doc_table.t_hospital_patient_file = JSON.parse(JSON.stringify(file));
            temp_file.checked = is_checked;
            this.file_list.splice(this.file_list.indexOf(file), 1, temp_file);
        }

    }

    /**
     * 파일 체크 변경
     */
    fileCheckChanged() : void {

        const all_count : number = this.computedFileSortList.length;
        const checked_count : number = this.computedFileSortList.filter(item => item.checked == true).length;

        this.is_all_selected = (all_count == checked_count);
    }

    /**
     * 파일 용량 텍스트
     */
    getFileSizeText(file_size : number) : string {

        let size : string = FILE_SIZE.B;
        while( file_size > 1024 && size != FILE_SIZE.GB ) {
            file_size /= 1024;

            // 사이즈는 GB까지만 표현한다
            switch( size ) {
                case FILE_SIZE.B:
                    size = FILE_SIZE.KB;
                    break;

                case FILE_SIZE.KB:
                    size = FILE_SIZE.MB;
                    break;

                case FILE_SIZE.MB:
                    size = FILE_SIZE.GB;
                    break;
            } 
        }

        return `${file_size.toFixed(2)} ${size}`;
    }

    /**
     * 배경화면 반환
     */
    getBackgroundImage(file : hodu_doc_table.t_hospital_patient_file) : string {
        if( new RegExp(escape('image')).test(escape(file.file_type)) == false ) {
            return 'none';
        }

        return `url(app_images/${file.file_path})`;
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'NAME' | 'SIZE' | 'DATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#patient_filebox_file_list_scroll').mCustomScrollbar('destroy');
        this.setScroll();
        window['left_inbox02_mCustomScrollbar']();
    }

    /**
     * 환자 선택 변경
     */
    @Watch('selected_patient')
    changedSelectedPatient() : void {
        this.handleResize();
        this.is_all_selected = false;
        this.getPatientsFileboxInfo();
    }
    
}

</script>

<style scoped>

    .noResult { display:none; position: static; padding: 20px; }
    .noResult p { font-size: 14px !important; background:#f1f3f5; color:#a3a9b5; line-height: 70px;; height:70px; }

    .no { width: 20%; font-weight:bold; display:inline-block; text-align: center; box-sizing: border-box; }
    .name { width:45%;font-size: 12px; display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    
    .rightAreaDiv { float:left; width: 70%; height:100%; border-left: 1px solid #e7e9ea; box-sizing: border-box; }
    .rightAreaDiv .checkList { width:13%; text-align: center; }
    .rightAreaDiv .no { width: 10%; text-align: left; }
    .rightAreaDiv .name { width:35%;position: relative; }
    .rightAreaDiv .date { width: 24%;padding-right: 20px;-webkit-box-sizing: border-box;box-sizing: border-box;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; }
    .rightAreaDiv .size { width: 18%;padding-right: 20px;-webkit-box-sizing: border-box;box-sizing: border-box;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; }
    
    .rightAreaDiv .noResult { padding: 30px 60px; }
    .rightAreaDiv.nothing .noResult { display:block; }
    .rightAreaDiv.nothing .sortHeader, .rightAreaDiv.nothing .scroll { display:none; }

    /* 오른쪽 파일리스트 박스형으로 보이기 */
    .rightAreaDiv.dp2 .fileUl li { overflow:visible; line-height: 30px; text-align: center; width: 170px;height: 260px; display:inline-block; float:none;border:1px solid #e7e9ea; margin: 30px 0 0 30px; border-radius: 5px;padding: 20px; box-sizing: border-box; }
    .rightAreaDiv.dp2 .fileUl li:hover { background-color:#fff; box-shadow: 0 5px 15px rgba(0,0,0,0.1) }
    .rightAreaDiv.dp2 .fileUl li p { display: block; width:100%; }
    .rightAreaDiv.dp2 .fileUl li .preview {  width: 126px; height: 80px; line-height:80px; display:block; float:none; margin:0 auto; margin-top:7px; }
    .rightAreaDiv.dp2 .fileUl li .preview.format { font-size: 14px; }
    .rightAreaDiv.dp2 .fileUl li p.name span { display:block; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; }
    .rightAreaDiv.dp2 .fileUl li .date, .rightAreaDiv.dp2 .fileUl li .name, .rightAreaDiv.dp2 .fileUl li .size { padding: 0 !important;  }
    .rightAreaDiv.dp2 .fileUl li .size  { border-top:1px solid #e7e9ea; border-bottom: 1px solid #e7e9ea;margin: 5px 0; }
    .rightAreaDiv.dp2 .fileUl input[type='checkbox']:not(old) + label { margin-top:0;float: left; }
    .rightAreaDiv.dp2 .fileUl .no { position: absolute; left: 60px;top: 20px; width: 120px; height: 24px; line-height: 24px; }
    .rightAreaDiv.dp2 .fileUl li .del {right: -12px;top: -13px;}

    .fileUl li:nth-child(1) .preview { background-image:url(../../assets/images/footer/xray_20200403.jpg) }
    .fileUl li:nth-child(2) .preview { background-image:url(../../assets/images/footer/xray_20200413.jpg) }
    .fileUl li:nth-child(5) .preview { background-image:url(../../assets/images/footer/d_scope.jpg) }
    .fileUl li:nth-child(8) .preview { background-image:url(../../assets/images/footer/w_scope.jpg) }
    .fileUl li { position:relative; transition:0.2s; overflow: hidden; border-bottom:1px solid #f1f3f5; height: 60px; line-height: 60px; }
    .fileUl li:hover { background-color: #f7f9fb; }
    .fileUl li p { display: inline-block; float:left; font-weight: bold; }
    .fileUl  input[type='checkbox']:not(old) + label { margin-top:18px; }
    .preview { float:left; width: 50px; margin-top:11px;border-radius: 5px; margin-right:10px; height: 35px; font-size:0;border:1px solid #e7e9ea; display: inline-block; background-color: #fff; background-position: center center !important; background-size: cover !important; background-repeat: no-repeat !important; }
    .preview.format { font-size: 12px; font-weight:bold; line-height: 35px; text-align: center; color:#477fff; text-transform: uppercase; }
    .grp.settingBg { position:static !important; }
    .fileUl li:hover .del { opacity:1; }  
    .fileUl li .del  { opacity:0; transition:0.2s; position:absolute; right: 20px; top:14px; width: 30px; height:30px; background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .fileUl li .del:hover { background-color:#ff1f1f}
    
    input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 20px;height: 20px;border-radius:3px; margin-top:15px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat; background-size: 22px;font-size: 0;margin-right: 8px;}
    input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;}

    /* 다운로드 버튼 */
    .rightAreaDiv.dp2 .fileUl li .download {right: -12px;top: -13px;}
    .fileUl li:hover .download { opacity:1; }  
    .fileUl li .download  { opacity:0; transition:0.2s; position:absolute; right: 60px; top:14px; width: 30px; height:30px; background: #477fff url(../../assets/images/contents/ic_download.png) no-repeat center center; border-radius: 50%; font-size:0; }
    .fileUl li .download:hover { background-color:#477fff }

</style>