<template>
    <div id="group_filebox_move_modal" class="modal">

        <h1 class="borderbot">파일 이동 위치 선택</h1>

        <div class="content">
            <div class="detail">
                <div class="fd_tree fileBoxTitle">
                    <span class="fd folder" :class="`folder_${hodu_color_dc_lc(scope_group_team_option.group_team_color)}`"></span>
                    <a href="#" v-if="group_filebox_move_modal_info.type == 'DIR'" @click.prevent="selectFileBox('')" :class="{ active : '' == dir_uuid }">{{ scope_group_team_option.group_team_name }}</a>
                    <p v-else>{{ scope_group_team_option.group_team_name }}</p>
                </div>
                <div class="file_move_list">
                    <ul class="moveTreeUl">

                        <li class="fdTreeFirst" :key="dir.dir_uuid" v-for="dir in computedDirTree">
                            <div class="fd_tree" :class="{ tree_has_child : dir.dirs.length > 0 }">
                                <a href="#" @click.prevent="selectFileBox(dir.dir_uuid)" :class="{ active : dir.dir_uuid == dir_uuid }">{{ dir.dir_name }}</a>
                                <button class="subMore" @click.prevent="toggleDir(dir)">더보기</button>
                            </div>
                            <GroupFileBoxRecursion2
                                :level="dir.level" 
                                :dirs="dir.dirs" 
                                :dir_uuid="dir_uuid" 
                                :dir_closed_map="dir_closed_map"
                                :class="{ open : dir.is_closed == false }"
                                @select="selectFileBox"
                                v-if="dir.dirs.length > 0"
                            />
                        </li>

                        <!-- 폴더트리 처음 li만 fdTreeFirst 클래스 붙음 -->
                        <!-- <li class="fdTreeFirst"> -->
                            <!-- 하위폴더가 있을 경우 tree_has_child 클래스 추가 -->
                            <!-- <div class="fd_tree tree_has_child">
                                <a href="#">경영관리</a>
                                <button class="subMore">더보기</button>
                            </div> -->
                            <!-- 더보기 클릭하면 opne 클래스 추가 -->
                            <!-- depth추가 될때마다  padding-left: 10px 씩 증가 하게 -->
                            <!-- <ul class="fdSubUl fdSub_1depth">
                                <li>
                                    <div class="fd_tree">
                                        <a href="#">관리비</a>
                                        <button class="subMore">더보기</button>
                                    </div>
                                </li>
                                <li>
                                    <div class="fd_tree tree_has_child">
                                        <a href="#">경영자료</a>
                                        <button class="subMore">더보기</button>
                                    </div>
                                    <ul class="fdSubUl fdSub_2depth">
                                        <li>
                                            <div class="fd_tree tree_has_child">
                                                <a href="#">세무세무</a>
                                                <button class="subMore">더보기</button>
                                            </div>
                                            <ul class="fdSubUl fdSub_3depth">
                                                <li>
                                                    <div class="fd_tree">
                                                        <a href="#">납부</a>
                                                        <button class="subMore">더보기</button>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="fd_tree">
                                                        <a href="#">계산서</a>
                                                        <button class="subMore">더보기</button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <div class="fd_tree">
                                                <a href="#">회사업무</a>
                                                <button class="subMore">더보기</button>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li> -->

                        
                    </ul>
                </div>
            </div>
        </div>

        <div class="btns">
            <input type="button" value="취소" @click="close">
            <input type="button" class="save" value="저장" @click.prevent="save">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupFileboxMoveModalInfo } from '@/store/modules/ModalInfo';

import GroupFileBoxRecursion2 from '@/components/GroupFileBoxRecursion2.vue';

@Component({
    components: {
        GroupFileBoxRecursion2
    },
})
export default class GroupFileboxMoveModal extends Mixins(VueHoduCommon) {

    /**
     * 파일함 디렉토리 트리
     */
    get computedDirTree() : any {

        let copy_dirs = JSON.parse(JSON.stringify(this.all_dirs));

        copy_dirs.sort((o1, o2) => {

            const is_fave = o1.is_favorite;
            const is_fave2 = o2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            const o1_dir_name = o1.dir_name;
            const o2_dir_name = o2.dir_name;

            if( o1_dir_name < o2_dir_name ) return -1;
            else if( o1_dir_name > o2_dir_name ) return 1;

            return 0;
        });

        let max_level = 0;
        for( const dir of copy_dirs ) {
            if( dir.dir_path.length > max_level ) max_level = dir.dir_path.length;
            dir['dirs'] = [];
            dir['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.dir_closed_map.get(dir.dir_uuid);
            if( is_closed != null ) {
                dir['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_dirs = copy_dirs.filter(item => item.dir_path.length == search_level);
            const next_search_dirs = copy_dirs.filter(item => item.dir_path.length == search_next_level); 

            for( const next_search_dir of next_search_dirs ) {
                const next_search_dir_uuid = next_search_dir.dir_uuid;
                next_search_dir.level = search_next_level;

                for( const current_search_dir of current_search_dirs ) {
                    const current_search_dir_uuid = current_search_dir.dir_uuid;
                    current_search_dir.level = search_level;

                    if( current_search_dir.dir_path.indexOf(next_search_dir_uuid) > -1 ) {
                        next_search_dir.dirs.push(current_search_dir);
                    }

                }
            }
            
            search_level--;
        }

        const top_dirs = copy_dirs.filter(item => item.dir_path.length == 1);
        if( top_dirs == null || top_dirs.length < 1 ) return null;

        return top_dirs;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_filebox_move_modal_info !: GroupFileboxMoveModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupFileboxMoveModalInfo ?: (params : GroupFileboxMoveModalInfo) => void;

    all_dirs : any[] = [];
    dir_closed_map : Map<string, boolean> = new Map();
    dir_uuid : string | null = null;

    mounted() : void {
        // 왼쪽 메뉴 dropdown2
        $(".tree_has_child > .subMore").click(function(){
            $(this).parent().siblings().toggleClass("open");
            $(this).parent().siblings().siblings().toggleClass("open");
            $(this).toggleClass("open");
        });

        this.getGroupFiles();
    }

    /**
     * 그룹 파일함 전체 조회
     */
    async getGroupFiles() : Promise<void> {
        await this.hodu_api_call(`api/v1/groupsweb/getGroupFiles/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                
                this.all_dirs.splice(0, this.all_dirs.length);
                this.all_dirs = this.all_dirs.concat(response.data.data.all_dirs);

            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    selectFileBox(dir_uuid) {
        this.dir_uuid = dir_uuid;
    }

    toggleDir(dir, complete_password_input : boolean = false) {


        // if( dir.is_closed == true && dir.has_password == true && complete_password_input == false ) {
        //     this.password_input(dir);
        //     return;
        // }

        dir.is_closed = !dir.is_closed;
        this.$forceUpdate();
        this.dir_closed_map.set(dir.dir_uuid, dir.is_closed);

    }

    /**
     * 디렉토리 비밀번호 입력
     */
    // password_input(dir : any) {

    //     let uuid : string = dir.dir_uuid;

    //     this.doSetCommonInputModalInfo?.({
    //         show_modal : true,
    //         title : "비밀번호 입력",
    //         subtitle : "비밀번호",
    //         placeholder : "비밀번호를 입력해주세요",
    //         is_password : true,
    //         save_text : "확인",
    //         cancel : () => {

    //         },
    //         save : async(password) => {

    //             try {

    //                 const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
    //                     "password" : password 
    //                 });

    //                 if( !response || !this.isHttpStatusSuccess(response.status) ) {
    //                     throw new Error("파일함 비밀번호 체크 중 오류 발생");
    //                 }
                    
    //                 this.toggleDir(dir, true);

    //             } catch(e) {
    //                 this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
    //             }

    //         },
    //     });
    // }

    async save() {

        try {

            if( this.group_filebox_move_modal_info.type == 'FILE' && (this.dir_uuid == null || this.dir_uuid.length < 1) ) {
                alert("이동할 파일함을 선택해주세요")
                return;
            }

            if( this.group_filebox_move_modal_info.type == 'DIR' && this.dir_uuid == null ) {
                alert("이동할 파일함을 선택해주세요")
                return;
            }

            let response : any = null;

            switch( this.group_filebox_move_modal_info.type ) {
                case 'DIR':
                    response = await this.hodu_api_call(`api/v1/groupsweb/moveGroupFileDir/${this.group_filebox_move_modal_info.dir_uuid}?dest_dir_uuid=${this.dir_uuid}`, API_METHOD.PUT);    
                    break;

                case 'FILE':
                    response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/file/${this.group_filebox_move_modal_info.file_uuid}/${this.dir_uuid}`, API_METHOD.PUT);
                    break;
            }

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("이동 중 오류 발생");
            }

            this.group_filebox_move_modal_info.callback?.();
            this.close();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("이동 중 오류 발생");
        }

    }

    close() {
        this.doSetGroupFileboxMoveModalInfo?.({
            show_modal : false,
            type : "",
        });
    }

}
</script>

<style>
    #group_filebox_move_modal .fd_tree { position: relative; line-height: 45px; box-sizing: border-box; padding: 0 40px 0 30px; }
    #group_filebox_move_modal .fd_tree:hover { background-color:#f5f5f5; border-radius: 7px; }
    #group_filebox_move_modal .fd_tree:hover button.subMore { background-color: initial; }
    #group_filebox_move_modal .moveTreeUl .fd_tree a { display: block; font-size: 14px; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #group_filebox_move_modal button.subMore { display: none; font-size: 0; width: 40px; height: 40px; border-radius: 50%; background-image: url('../assets/images/side_area/bt_open.png'); background-repeat: no-repeat; background-position: center center; position: absolute; top: 3px; margin-left: -35px; transform: rotate(-90deg); }
    #group_filebox_move_modal button.subMore.open { transform: rotate(180deg); }
    #group_filebox_move_modal .moveTreeUl .tree_has_child button.subMore { display: block; }
    #group_filebox_move_modal button.subMore:hover { background-image: url('../assets/images/side_area/bt_open_b.png'); }

    #group_filebox_move_modal .moveTreeUl .fdSubUl { display: none; }
    #group_filebox_move_modal .moveTreeUl .fdSubUl.open { display: block; }

    #group_filebox_move_modal.modal a.active { color: #477fff; }
</style>

<style scoped>
    #group_filebox_move_modal { position: absolute; left: 50%; top: 50%; width: 100%; height: 528px; margin-left: -400px; margin-top: -264px; max-width: 800px; background: #fff; border-radius: 5px; }
    #group_filebox_move_modal.modal h1 { font-size: 19px; padding: 0 30px; height:85px; line-height:100px; width:100%; box-sizing: border-box; border-bottom:1px solid #f1f3f5; }

    #group_filebox_move_modal.modal .content { height : 368px; }
    #group_filebox_move_modal.modal .content .detail { height: 100%; box-sizing: border-box; padding: 20px 40px; overflow: hidden; overflow-y: scroll; }

    #group_filebox_move_modal.modal .btns { width : 100%; overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px }
    #group_filebox_move_modal.modal .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    #group_filebox_move_modal.modal .btns input.save:hover { color:#477fff; }
    #group_filebox_move_modal.modal .btns input:hover { background:#f1f3f5; }

    #group_filebox_move_modal.modal button { border: none; outline: none; background-color: inherit; cursor: pointer; }

    #group_filebox_move_modal.modal .fileBoxTitle { font-size: 14px; font-weight: bold; padding: 0; }
    #group_filebox_move_modal.modal .fileBoxTitle span.fd { width: 30px; height: 30px; background-size: 25px auto; background-position: center; vertical-align: middle; margin: -3px 5px 0 10px; }
    #group_filebox_move_modal.modal .fileBoxTitle > a { display: inline-block; width: 90%; }
    #group_filebox_move_modal.modal .fileBoxTitle > p { display: inline-block; width: 90%; }

    /* #group_filebox_move_modal .fdSub_1depth .fd_tree { padding-left: 50px; }
    #group_filebox_move_modal .fdSub_2depth .fd_tree { padding-left: 60px; }
    #group_filebox_move_modal .fdSub_3depth .fd_tree { padding-left: 70px; } */

    #group_filebox_move_modal ::-webkit-scrollbar{ width : 5px; height : 5px; }
    #group_filebox_move_modal ::-webkit-scrollbar-track{background-color: transparent;}
    #group_filebox_move_modal ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

    
</style>