<template>
    <div class="section_ce_fix" :class="{ dirOn : is_open_dir_list == true }">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">
                호두닥
            </h3>
            
        </div>

        <div id="fileScroll" class="section_scroll">
            <div class="viewGroup">					
                <div class="schedule_box">
                    <div class="grp settingBg">

                        <div class="titleDiv">
                            <a class="folderMenu" @click="dirListOpenOnOff">
                                <div>
                                    <span></span><span></span><span></span>
                                </div>
                            </a>
                            <div class="menuDetail">
                                <p class="title">병원<span>{{ hodu_d_list.length }}</span></p>
                            </div>
                        </div>

                        <!-- 선택된 폴더 정보 -->
                        <div class="thisFolder">
                            
                            <!-- 폴더 색상, 아이콘, 이름 등 수정 버튼 -->
                            <a class="editThisFolder">
                                <p class="folder" :class="`folder_${hodu_color_dc_lc(selected_filebox ? selected_filebox.group_color : '')}`">
                                    <span class="ic_fd"></span>
                                </p>
                                <p class="folderName">{{ selected_filebox ? selected_filebox.group_name : '' }}</p>
                            </a>

                        </div>

                    </div>
                </div>

                <div class="fileMainDiv">
                    <div id="dir_scroll" class="dirDiv">
                        
                        <ul class="folderUl">

                            <li :key="dir.biz_id" v-for="dir in hodu_d_list" :class="{ on : dir.biz_id == biz_id }">
                                <p class="folder" :class="`folder_${hodu_color_dc_lc(dir.group_color)}`"></p>
                                <p class="folderName">{{ dir.group_name }}</p>
                                <p class="files"><span class="file">파일</span>{{ dir.file_list.length }}</p>
                                <a class="goDets" title="보기" @click="fileBoxChange(dir)">보기</a>
                            </li>
                            
                        </ul>

                    </div>

                    <ul class="sortHeader fileHeader" id="">
                        <li class="num">No</li>
                        
                        <li class="fileName headerSort" @click.prevent="sortTypeChange('NAME')"
                            :class="{
                                sortOff  : sort_type != 'NAME',
                                sortUp   : sort_type == 'NAME' && sort_dir == 'ASC',
                                sortDown : sort_type == 'NAME' && sort_dir == 'DESC'
                            }"><span>파일명</span>
                        </li>

                        <li class="size headerSort" @click.prevent="sortTypeChange('SIZE')"
                            :class="{
                                sortOff  : sort_type != 'SIZE',
                                sortUp   : sort_type == 'SIZE' && sort_dir == 'ASC',
                                sortDown : sort_type == 'SIZE' && sort_dir == 'DESC'
                            }"><span>용량</span>
                        </li>

                        <li class="time headerSort" @click.prevent="sortTypeChange('DATE')"
                            :class="{
                                sortOff  : sort_type != 'DATE',
                                sortUp   : sort_type == 'DATE' && sort_dir == 'ASC',
                                sortDown : sort_type == 'DATE' && sort_dir == 'DESC'
                            }"><span>등록일</span>
                        </li>
                    </ul>

                    <!-- content 스크롤 추가-->
                    <div id="hodu_d_file_scroll" class="content">
                            
                        <div>

                            <ul class="fileListUl" v-if="selected_filebox != null">
                                
                                <li class="fileListLi" title="다운로드" :key="file.file_uuid" v-for="(file, index) in computedSortFileList" @click="fileDownLoad(file.file_path, file.file_name)">
                                    <p class="num"><span>{{ index + 1 }}</span></p>
                                    <p class="preview fe" v-if="getExtensionOfFilename(file.file_name) != 'img'">{{ getExtensionOfFilename(file.file_name) }}</p>
                                    <p class="preview img" v-if="getExtensionOfFilename(file.file_name) == 'img'" :style="{ backgroundImage : `url(${'/app_images/' + file.file_path})` }"></p> 
                                    <p class="fileName"><span>{{file.file_name}}</span></p> 
                                    <p class="size"><span>{{ byteToSize(file.file_size) }}</span></p>
                                    <p class="time"><span>{{ hodu_date_to_format_string(new Date(file.audit_created), 'YYYY.MM.DD') }}</span> {{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(new Date(file.audit_created), "hh:mm a")) }}</p> 
                                </li>

                                <!-- <li v-bind:key="index" title="다운로드" v-for="(lo_data, index) in arr_files" :class="{byMe : lo_data.audit_user_id == user_id}" :id="`file_list_li_${index}`">
                                    <p class="num"><span>{{index+1}}</span></p> 
                                    <p class="preview fe" @click="fileDownLoad(lo_data.file_path, lo_data.file_name)" v-if="getExtensionOfFilename(lo_data.file_name) != 'img'">{{getExtensionOfFilename(lo_data.file_name)}}</p>
                                    <p class="preview img" @click="fileDownLoad(lo_data.file_path, lo_data.file_name)" v-if="getExtensionOfFilename(lo_data.file_name) == 'img'" :style="{ backgroundImage : `url(${'/app_images/group_file/' + lo_data.file_path})` }"></p>
                                    <p class="fileName" @click="fileDownLoad(lo_data.file_path, lo_data.file_name)"><span>{{lo_data.file_name}}</span></p> 
                                    <p class="size"><span>{{byteToSize(lo_data.file_size)}}</span></p>  
                                    <p class="time"><span>{{dateToString(lo_data.audit_created, "yyyy-mm-dd")}}</span> {{dateToString(lo_data.audit_created, "HH:MM")}}</p> 
                                </li> -->

                            </ul>

                        </div> 

                    </div>

                </div>
            </div>
        </div>	
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, OWNER_TYPE, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class HospitalFileList extends Mixins(VueHoduCommon) {

    /**
     * 정렬된 파일 리스트 반환
     */
    get computedSortFileList() : any[] {

        if( this.selected_filebox == null ) { return []; }

        let target_list = JSON.parse(JSON.stringify(this.selected_filebox.file_list));

        target_list = target_list.sort((item1, item2) : number => {
            
            // 파일 이름 기준 정렬
            if( this.sort_type == 'NAME' ) {

                if( item1.file_name == item2.file_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_name < item2.file_name ? -1 : 1;
                }

                return item1.file_name > item2.file_name ? -1 : 1;

            }

            // 파일 용량 기준 정렬
            if( this.sort_type == 'SIZE' ) {

                if( item1.file_size == item2.file_size ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_size < item2.file_size ? -1 : 1;
                }

                return item1.file_size > item2.file_size ? -1 : 1;

            }

            // 파일 올린 날짜 기준 정렬
            if( this.sort_type == 'DATE' ) {

                if( new Date(item1.audit_created).getTime() == new Date(item2.audit_created).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item1.audit_created).getTime() < new Date(item2.audit_created).getTime() ? -1 : 1;
                }

                return new Date(item1.audit_created).getTime() > new Date(item2.audit_created).getTime() ? -1 : 1;

            }

            return 0;
        });

        return target_list;
    }

    biz_id : string = "";
    hodu_d_list : any[] = [];
    selected_filebox : any = null;

    is_open_dir_list : boolean = false;

    // 정렬
    sort_type : 'NAME' | 'SIZE' | 'DATE' = 'DATE';
    sort_dir  : SORT_TYPE = SORT_TYPE.DESC;

    async mounted() : Promise<void> {
        
        this.biz_id = this.$route.params.biz_id;
        this.setScroll();
        await this.getFileBoxDirAndFile();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();
        const getHeight        : number = (titleHeight ? titleHeight : 0) + (settingBgHeight ? settingBgHeight : 0) + (sortHeaderHeight ? sortHeaderHeight : 0);
        const finalHeight      : number | undefined = window.innerHeight - getHeight;

        // @ts-ignore
        $('#hodu_d_file_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : finalHeight,
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
        });

        // @ts-ignore
        $('#dir_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0),
        });
    }

    /**
     * 유저와 관련된 파일함 전체 조회
     */
    async getFileBoxDirAndFile() : Promise<void> {

        await this.hodu_api_call(`api/v1/user/me/file`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.hodu_d_list.splice(0, this.hodu_d_list.length);
                this.hodu_d_list = this.hodu_d_list.concat(response.data.data.hodu_d_list);
                
                // 파일함이 하나도 존재하지 않는다면
                if( this.hodu_d_list.length < 1 ) {
                    this.hodu_show_dialog('cancel', "호두닥 파일함이 존재하지 않습니다", ['확인'], [() => { this.movePrevPage(); }]);
                    return;
                }

                const selected_list : any[] = this.hodu_d_list.filter(item => item.biz_id == this.biz_id);
                
                // 해당 병원의 파일함이 존재한다면 선택, 없다면 첫번째꺼 자동선택
                if( selected_list.length > 0 ) {
                    this.selected_filebox = selected_list[0];
                    return;
                }

                this.selected_filebox = this.hodu_d_list[0];

            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });

    }

    /**
     * 확장자 뽑기
     */
    getExtensionOfFilename(filename) : string {
        var _fileLen = filename.length;
        var _lastDot = filename.lastIndexOf('.');
    
        // 확장자 명만 추출한 후 소문자로 변경
        var _fileExt = filename.substring(_lastDot, _fileLen).toLowerCase();
 
        if ( _fileExt == ".png" || _fileExt == ".jpg" || _fileExt == ".jpeg" || _fileExt == ".bmp" || _fileExt == ".gif" ) {
            return "img";
        }
        else {
            return _fileExt.replace(".", "");
        }
    }

    /**
     * BYTE TO SIZE
     */
    byteToSize(byte, decimals = 2) : string {
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];        
    }

    /**
     * 파일 다운로드
     */
    fileDownLoad(ps_path, ps_file_name) : void { 
        this.hodu_download(`/app_images/${ps_path}`, ps_file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 호두닥 파일박스 변경
     */
    fileBoxChange(group : any) : void {
        this.biz_id = group.biz_id;

        this.selected_filebox = JSON.parse(JSON.stringify(this.hodu_d_list.filter(item => item.biz_id == this.biz_id)[0]));
    }

    /**
     * 디렉토리 리스트 ON / OFF
     */
    dirListOpenOnOff() : void {
        this.is_open_dir_list = !this.is_open_dir_list;
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'NAME' | 'SIZE' | 'DATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#hodu_d_file_scroll").mCustomScrollbar('destroy');

        // @ts-ignore
        $('#dir_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }
    
}
</script>

<style scoped>
    .title_box { height:61px; }
    .schLi .schBtn { right:30px; }
	#addFile { display:none; }
	.addFileLabel {cursor:pointer;position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 1110000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 5px;display: block;text-align: center;font-size: 30px !important;width: 60px;border: 2px dashed #abc4ff;height: 60px;line-height: 59px;color: #608fff !important;background: rgba(71, 127, 255, 0.1) !important;}
	.addFileLabel:hover {background: #477fff !important;color: #fff !important;border: 2px solid #477fff;border-radius: 50%;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;}
	#fileScroll .btn_group { margin-right:30px;position: static;float: right;margin-top: 9px; }
	.section_scroll .delBtn { display:none; }
	.section_scroll.edit .delBtn { display:inline-block }
	#fileScroll .sortHeader input[type='checkbox']:not(old) + label { margin-top:18px; }
	#fileScroll input[type='checkbox']:not(old) + label {margin-top: 21px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius:3px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
	#fileScroll input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;border: 2px solid #477fff;}
	#fileScroll h3.selectTitle .cl {display: inline-block;width: 8px;height: 8px;float: left;margin-top: 28px;border-radius: 50%;margin-right: 15px;font-size: 0;}
	
    .title_doc .cl { display:inline-block; width: 10px; height:10px;  border-radius: 50%; margin-right:15px; float:left; margin-top: 27px; }
	h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 63px;cursor:pointer;	}
	.grp.settingBg .grp2, .grp.settingBg .grp1 { position:relative; display:inline-block; }
	.grp.settingBg h3.selectTitle:hover .txt { color:#477fff; }
	.title_doc span.total {padding-left: 10px;font-size: 16px;line-height: 20px;display: inline-block;color: #477fff;}
	.selectTitle.box span.total {display: inline-block;width: 30px;height: 30px;border-radius: 20px;text-align: center;font-size: 0;box-sizing: border-box;border: 1px solid #e7e9ea;background: #fff;float: right;line-height: 29px;margin: 17px 0 0 17px;color: #477fff;}
	.selectTitle.box:hover span.total, .selectTitle.box.on span.total {transition: 0.1s;width: auto;font-size: 12px;padding: 0 20px;}
	.selectTitle.box.on span.total { background:#477fff; color:#fff; border:1px solid #477fff; }
	.selectTitle.box span.total em {font-size: 12px;font-style: normal;}
	.settingBg ul.dropdown {width: 250px;left:30px;;top: 60px;}
	.viewGroup { overflow:hidden; }
	#container #section_ce .schedule_box {padding: 0;width: 100%;max-width: 100%; overflow:hidden;}
	#fileScroll .sortHeader {margin-top: 0;}
    .grp.settingBg { position:static !important; padding: 0 !important }
	.section_scroll .sortHeader .notiCheck { display:none; }
	.section_scroll.edit .sortHeader .notiCheck {height:60px; line-height:60px; font-size:0;display: inline-block; }
	.section_scroll.edit .sortHeader li.notiCheck { position:absolute; left:0;width:110px;top:0; }
	.section_scroll .sortHeader { position:relative;box-sizing:border-box; }
	.section_scroll.edit .sortHeader { padding-left:110px; }
	.section_scroll .notiCheck { width:8%; text-align:center; }
	.section_scroll .sortHeader p.notiCheck { text-align: center;width:100%;}
	.section_scroll .num { width:12%; text-align:center; font-weight:bold; text-indent: -10px;}
	.section_scroll.edit .num { text-indent: -60px; }
	.section_scroll .group { width:15%; }
	.section_scroll .fileName { width: 53%;}
	.section_scroll .time { width:21%; }
	.section_scroll .time span {width: 77px;display: inline-block;}
	.section_scroll .size { width:14%; }
	.content  { float:left; width:100%; }
	.content .fileListUl .fileListLi p {display: inline-block;font-weight: bold;float: left;box-sizing: border-box;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
	.content .fileListUl .fileListLi p.num, .content .fileListUl .fileListLi p.notiCheck { padding-right:0; }
	.content .fileListUl .fileListLi { transition:0.2s; position:relative; }
	.content .fileListUl .fileListLi:hover { background:#f6f9fb; }
	.content .fileListUl .fileListLi a.openFile {z-index:100; position:absolute;background:none !important; font-size:0; width:90%; height:100%; right:0;top:0;  }
	.content .fileListUl .fileListLi.byMe:hover a.moreBtn, .content .fileListUl .fileListLi.remover:hover a.moreBtn { display:block;  }
    .content .fileListUl .fileListLi.byMe .writtenBy span { color:#477fff; }
    .cnfm {display:none;position:absolute; left:0; top:0; width:100%; height:100%;background: rgba(33, 49, 84, 0.6); text-align: right;z-index: 1000;}
    .cnfm p {display: inline-block;color: #fff;font-size: 14px;float: none !important;}
    .cnfm a {line-height: 35px;height: 35px;font-size: 13px;font-weight: bold;background: #fff;border-radius: 20px;display: inline-block;width: 70px;margin-right: 20px;margin-top: 14px;float: right !important;text-align: center;}
    .cnfm a.noNo:hover { background-color:#ff6060 !important; }
    .cnfm a.yesYes:hover { color:#477fff; }
    .content .fileListUl .fileListLi.byMe.open .cnfm, .content .fileListUl .fileListLi.remover.open .cnfm { display:block; } 
    .content .fileListUl .fileListLi.open .moreBtn { display: none !important; }
	.content .fileListUl .fileListLi a.moreBtn { display:none;z-index: 1000;position: absolute;background: none !important;width: 35px;height: 35px;line-height: 41px;text-align: center;border-radius: 50%;background: rgba(0,0,0,0.0) !important;right: 30px;top: 15px;font-size: 0; }
	.content .fileListUl .fileListLi a.moreBtn:hover {background: rgba(0,0,0,0.1) !important;}
	.content .fileListUl .fileListLi a.moreBtn span { width:4px; height:4px; border-radius:50%;margin:0 2px; display:inline-block; background:#000;display:inline-block; }
	.content .fileListUl .fileListLi a span { color:#477fff; }
	#fileScroll.section_scroll .content .fileListUl .fileListLi:hover p.fileName span {color: #477fff !important;}
	.content .fileListUl .fileListLi:hover a:hover { color:#fff; background:#477fff; }
	.content .fileListUl .fileListLi:hover a:hover span { color:#fff; }
	.content .fileListUl .fileListLi { box-sizing:border-box; position:relative; width:100%; border-bottom:1px solid #f1f3f5;height: 65px;line-height: 65px;}
	.content .fileListUl .fileListLi .preview {position: absolute;left: 12%;background: #fff;border-radius: 5px;height: 40px;font-size: 11px !important;line-height: 40px;text-transform: uppercase;text-align: center;width: 60px;color: #477fff;top: 12px;padding-right: 0;-webkit-appearance: none;border: 1px solid #e7e9ea !important;box-shadow:none !important;}
    .content .fileListUl .fileListLi:hover  { cursor: pointer; }
    .content .fileListUl .fileListLi .preview.img {transition: 0.1s; font-size:0 !important; background-size:cover !important; background-position:center center !important; }
	.content .fileListUl .fileListLi p.fileName {padding-left: 80px;}
	.content .fileListUl .fileListLi:hover .preview.img {box-shadow: 0 5px 10px rgba(0,0,0,0.2) !important;border: none !important;}
	.edit .content .fileListUl .fileListLi .preview { margin-left:110px; left:11%; }	
	
	.section_scroll.edit .content .fileListUl .fileListLi {padding-left:110px; }
	.section_scroll.edit .content .fileListUl .fileListLi p.notiCheck {text-align: center;position:absolute; left:0;width:110px;top:0;    display: inline-block; }
	.section_scroll .content .fileListUl .fileListLi p.notiCheck { display:none; }
	
	
	.blckng {display:none;width: 100%;height: 100%;position: absolute;z-index: 100; }
	.blckng.on { display:block }
	#fileScroll h3.selectTitle.box {background: url(../../assets/images/contents/ic_fileBox_blue.png) no-repeat center left;padding-left: 35px !important;margin-left: 30px;}	
	#fileScroll h3.selectTitle.box.on .txt { color:#477fff; }
	#fileScroll ul.dropdown.box li {position:relative;width: 33.33333%;border: none !important;display: inline-block;box-sizing: border-box;height: 100px;float: left;}
	#fileScroll ul.dropdown.box li a.mainLink {height:100px;font-size: 14px;font-weight: bold;line-height: 100px;color: #949fb7;border: 0;border-left: 1px solid #e7e9ea;width: 100%;border-bottom: 1px solid #e7e9ea;display: inline-block;box-sizing: border-box;border-collapse: collapse;}
	#fileScroll ul.dropdown.box li:hover a.mainLink {background-color: #f1f3f5 !important;}
	#fileScroll ul.dropdown.box li:hover a.mainLink.on {background-color: #fff !important;}
	#fileScroll ul.dropdown.box li a.mainLink.on, #fileScroll ul.dropdown.box li a.mainLink:hover {color: #273044;}
	#fileScroll ul.dropdown.box li a.mainLink:before {content: '';display: block;position: absolute;left: 0;top: 0;width: 40px;height: 40px;background: url(../../assets/images/contents/ic_fileBox.png) no-repeat 20px 21px;background-size: 17px;opacity: 0.5;}
	#fileScroll ul.dropdown.box li a.mainLink:hover:before { opacity:1 }
	#fileScroll ul.dropdown.box li a.on.mainLink:before {background: url(../../assets/images/contents/ic_fileBox_on.png) no-repeat 20px 21px;background-size: 20px;opacity: 1;top: -2px;}
	#fileScroll ul.dropdown.box li:last-child a {border-right: 1px solid #e7e9ea}
	.dropdown.box a.mainLink.on:after {display: none;}
	.dropdown.box span.mainBox {position: absolute;top: 21px;right: 20px;font-size: 12px;padding: 0 5px;line-height: 21px;height: 21px;background: #f1f3f5;border-radius: 5px;}
	.dropdown.box li a span.mainBox {background: #808ca7;color: #fff;}
	.dropdown.box span.file {font-size: 13px;position: absolute;box-sizing: border-box;width: 100%;top: 21px;padding-left: 55px;left: 0;line-height: 20px;}
	.dropdown.box em {font-style: normal;color: #477fff;padding-left: 10px;}
	.dropdown.box span.txt {position: absolute;box-sizing: border-box;width: 100%;top: 57px;padding-left: 20px;padding-right: 20px;left: 0;font-size: 16px;line-height: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
	.dropdown.box a.edit {display:none;opacity:1;position: absolute;bottom: 13px;right: 22px;width: 33px;box-sizing: border-box;padding: 0;height: 33px;line-height: 39px;font-size: 0;padding-left: 5px;border: none !important;border-radius: 50%;background: transparent;z-index: 1000000;}
    .dropdown.box a.edit span {background: black;width: 4px;height: 4px;display: block;border-radius: 50%;display: inline-block;margin: 0 2px;}
	.dropdown.box li:hover a.edit { display:block; } 
	.dropdown.box a.edit:hover {background: rgba(0,0,0,0.1);}
	#fileScroll ul.dropdown.box li a.mainLink {color: #949fb7;}
	#fileScroll ul.dropdown.box li a.mainLink.on span, #fileScroll ul.dropdown.box li a.mainLink:hover span {color: #273044;}
	#fileScroll ul.dropdown.box li a.mainLink span em {opacity: 0.6;}
	#fileScroll ul.dropdown.box li a.mainLink span {color: #aeb5c5;}
	#fileScroll ul.dropdown.box li a.mainLink.on span em, #fileScroll ul.dropdown.box li a.mainLink:hover span em {opacity:1;}
	#fileScroll ul.dropdown.box li.mainFileBox a.mainLink span.mainBox {color: #fff;}
	#fileScroll ul.dropdown.box li a.mainLink span.edit {position: absolute;right: 25px;top: -19px;}
	#fileScroll ul.dropdown.box {margin-left: -1px;position:static !important; box-shadow:none !important; border-radius: 0 !important; }
	#fileScroll ul.dropdown.box li a.mainLink span.new {opacity:0.4;display: block;position: absolute;right: 27px;top: 20px;width: auto;padding: 0 8px;height: 22px;background: #ff6060;line-height: 21px;text-align: center;font-size: 12px;color: #fff;border-radius: 5px;}
	#fileScroll ul.dropdown.box li a.mainLink:hover span.new, #fileScroll ul.dropdown.box li a.mainLink.on span.new { opacity:1; }
	#fileScroll ul.dropdown.box li.mainFileBox  a.mainLink span.new {right: 68px;}
	.dropdownScroll { height: auto;max-height: 300px;display: block;overflow-y: auto;width: 100%;position: absolute;z-index: 100000000000000;background: #fff;box-shadow: 0 10px 10px rgba(0,0,0,0.1);top: 129px;}
	
	.bg4file {display:none;position: absolute;width: 100%;vertical-align: middle;height: 100%;top: 0;line-height: 100%;left: 0; background: rgba(255, 255, 255, 0.3);box-sizing: border-box;z-index: 100000000;padding: 60px;}
	.bg4file.on {display: block;}
    .bg4file span {width: 100%;height: 100%;display: inline-block;transition: 0.1s;border-radius: 5px;background: rgba(71, 127, 255, 0.1) url(../../assets/images/contents/img_dragDrop.png) no-repeat center center;background-size: 190px;border: 2px dashed #477fff;color: #477fff;font-weight: bold;font-size: 0;vertical-align: middle;text-align: center;}
	.bg4file span:hover {background: rgba(71, 127, 255, 0.15) url(../../assets/images/contents/img_dragDrop.png) no-repeat center center;background-size: 200px;}
    #newFileModalBtn { height:0;width:0;opacity:0;font-size:0;border:0 none; }
    
    .content .fileListUl .fileListLi:hover .img:after {z-index:10;position:absolute; top:0;left:0;content:'';width:100%;height:100%; display:block;background: rgba(71, 127, 255, 0.2) url(../../assets/images/contents/ic_ddFile.png) no-repeat center 5px;}
	.fileListUl { margin-bottom:150px; }
	@media (max-width:1440px) {
		#fileScroll ul.dropdown.box li { width:50%; }
	}


    /* 상단 왼쪽 선택된 폴더 */
    .dirOn .thisFolder  { margin-left: 15px; }
    .thisFolder  { overflow:hidden; display: inline-block; margin-left: 0; width:auto; line-height: 65px; height: 65px; min-width: 150px; }
    .thisFolder .folder { float:left; width: 70px; height: 64px; background-size: 50px; background-position: 10px center;  }
    .thisFolder .folder span { float: left; background-size: 45px; background-position: 12px center; height: 64px; line-height: 64px; }
    .thisFolder .folderName { float:left; display: inline-block; font-size: 16px; font-weight: bold; }
    .thisFolder .editThisFolder { display: inline-block; width: 100%; height: 100%; }
    .thisFolder .editThisFolder:hover  { color:#477fff; }

    /* 왼쪽 상단 폴더 메뉴 */
    .titleDiv { position:relative; transition:0.1s; overflow: hidden; height: 65px; width:65px; padding-left: 65px; float:left; line-height: 65px; border-bottom:1px solid #e7e9ea; box-sizing: border-box; }
    .titleDiv .folderMenu { opacity:1; z-index: 10; position: absolute; top:0; left:0; display: inline-block; width: 65px; height: 65px; }
    .titleDiv .folderMenu div { transition:0.2s; position: relative; width: 38px; height: 38px; text-align: center; line-height: 36px; background:#f1f3f5; border-radius: 50%; margin-top:12px; margin-left: 17px; }
    .titleDiv .folderMenu div span { transform:  rotate(0) translateY(0) translateX(0); transition: 0.2s; display: inline-block; margin:0 2.5px; width: 4px; height: 4px; border-radius: 50%; background-color:#2e3856 }
    .titleDiv .folderMenu:hover span {margin-left:0; height:2px; transform: translateX(40px); border-radius:3px; opacity:0  }
    .titleDiv .folderMenu:hover span:first-child { opacity:1; width: 11px; transform:rotate(35deg) translateY(-11.5px) translateX(7px);margin-top:10px }
    .titleDiv .folderMenu:hover span:last-child { opacity:1; width: 11px; transform: rotate(-35deg) translateY(-3.5px) translateX(-7px); }

    .titleDiv .folderMenu:hover div { background-color:#e7e9ea; }
    .dirOn .titleDiv .folderMenu span { margin-left:0; height:2px; transform: translateX(40px); border-radius:3px; opacity:0  }
    .dirOn .titleDiv .folderMenu span:first-child { opacity:1; width: 16px; transform:rotate(45deg) translateY(-3.5px) translateX(2px);margin-top:10px }
    .dirOn .titleDiv .folderMenu span:last-child { opacity:1; width: 16px; transform: rotate(-45deg) translateY(-25.5px) translateX(27px); }

    .title { float: left; font-size: 14px; font-weight: bold; display: inline-block; }
    .title span { display: inline-block; padding-left:10px; color:#477fff; }
    .titleDiv input {margin-top:14px;margin-right:-5px; width: 35px; height:35px; float:right; background: transparent url(../../assets/images/contents/ic_add.png) no-repeat center center;margin-right: 10px;background-size: 35px; font-size:0; border-radius: 50%;}
    .titleDiv input:hover {background-color: #477fff;background: #477fff url(../../assets/images/contents/btn_add_fff.png) no-repeat center center;background-size: 35px;}
    .section_ce_fix.dirOn .settingBg .titleDiv { width:300px; opacity:1; border-right:1px solid #e7e9ea; }
    .section_ce_fix.dirOn .menuDetail { width: auto; opacity: 1; }
    .menuDetail { transition:0.1s; width: 0; opacity:0; overflow: hidden; }
    .menuDetail .title { padding-left:5px; }

    /* 왼쪽 디렉토리 섹션 */
    .section_ce_fix .fileMainDiv { transition:0.1s; padding-left:0; }
    .section_ce_fix .dirDiv { width: 0; opacity:0; overflow: hidden; }
    .section_ce_fix.dirOn .fileMainDiv { padding-left: 300px; position: relative; width: 100%; box-sizing: border-box; }
    .section_ce_fix.dirOn .dirDiv  { width:300px; opacity:1;  }
    .dirDiv { transition:0.1s; z-index: 100; position: absolute; left:0; top:0;  width:300px; height:100%; border-right:1px solid #e7e9ea;  }
    .dirDiv .title { float: left; font-size: 14px; font-weight: bold; display: inline-block; }
    .dirDiv .title span { display: inline-block; padding-left:10px; color:#477fff; }
    
    .section_ce_fix.dirOn .dirDiv .folderUl { display:block; }
    .dirDiv .folderUl { display:none; }
    /* 폴더 */
    .dirDiv .folder { position: absolute; left:0; top:0; display:inline-block; width: 50px; height:50px; background-size: 25px; background-position: 15px center; }
    .dirDiv .folderName { font-weight: bold; display: inline-block;  box-sizing: border-box; padding-left: 48px; font-size: 13px; padding-right:55px; width:100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
    .dirOn .dirDiv .folderUl li { width:100%;}
    .dirDiv .folderUl li { transition: 0.1s; position: relative; height: 50px; line-height:50px; width:0; border-bottom:1px solid #f1f3f5;  }
    .dirDiv .folderUl .files { position: absolute; right:15px; top:0; font-weight: bold; }
    .dirDiv .folderUl .files .file { display:none; }
    .dirDiv .folderUl li a.goDets { position: absolute; left:0; top:0; width:100%; height:100%; font-size:0; }
    .dirDiv .folderUl li:hover { background-color: #f6f9fb; }
    .dirDiv .folderUl li.on { overflow: hidden; cursor:default; box-sizing: border-box;  background-color: #fff; border-right: 3px solid #477fff;  }
    .dirDiv .folderUl li.on .folderName { float:left;box-sizing: border-box; padding-right:15px }
    .dirDiv .folderUl li.on .editFolder { display: block; top:auto; bottom: 13px;background:#fff }
    .dirDiv .folderUl li.on:hover a.goDets { cursor:default; }
    .dirDiv .folderUl li .editFolder { transition:0.1s; z-index: 10000; display: none; position:absolute; right: 13px; top:11px; background:#fff; border:1px solid #e7e9ea; font-weight: bold; height: 25px; line-height: 25px; padding: 0 13px; border-radius: 15px; ; }
    .dirDiv .folderUl li .editFolder:hover { background-color:#ffba00; border-color: #ffba00 }
</style>