<template>
    <div class="patient_list section_ce_fix" :class="getTopClass()">
        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">환자</h3>
            <a class="schBtn" v-if="patient_tab == 'LIST'" @click="searchOnOff">검색하기</a>
        </div>

        <!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
        <div class="searchDiv" :class="{ on : is_search_open == true }">
            <div class="schGrp">
                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                <input type="text" id="patient_search_input" placeholder="환자명으로 검색하세요" :value="search_word" @input="search_word = $event.target.value" @keydown="eventKeyDown($event)">
                <div class="btns">
                    <!-- 검색 디브 removeClass(on) -->
                    <a href="#" class="closeBtn" @click.prevent="searchOnOff">닫기</a>
                    <a href="#" class="bt_search" @click.prevent="searchPatientList"><span class="blind">찾기버튼</span></a>
                </div>
            </div>
        </div>

        <div class="schedule_box">
            <div class="grp settingBg">
                <div class="posRel">
                    <div class="bg">
                        <div class="btnA">
                            <a class="pList" @click="setPatientTab('LIST')" :class="{ on : patient_tab == 'LIST'}">
                                리스트
                            </a>
                            <a class="mList" @click="setPatientTab('SEND_MESSAGE')" :class="{ on : patient_tab == 'SEND_MESSAGE'}">
                                발송현황
                            </a>
                            <a class="mSetting" @click="setPatientTab('MESSAGE_TEMPLATE')" :class="{ on : patient_tab == 'MESSAGE_TEMPLATE'}">
                                문구설정
                            </a>
                            <a class="mFilebox" @click="setPatientTab('PATIENT_FILEBOX')" :class="{ on : patient_tab == 'PATIENT_FILEBOX'}">
                                파일함
                            </a>
                        </div>
                        <!-- 삭제버튼 -->
                        <div class="btnBox fr">
                            <input type="button" class="input_btn download" value="다운로드" @click="downloadButtonClick" v-if="patient_tab == 'PATIENT_FILEBOX'"/>
                            <input type="button" id="" class="input_btn del" placeholder="" value="삭제" title="" @click="deleteButtonClick" v-if="patient_tab == 'PATIENT_FILEBOX'"/>
                            <input type="button" id="" class="input_btn dmBtn" placeholder="" value="메세지 발송" title="" @click="requestSelectedPatients" v-if="patient_tab == 'LIST'"/>

                            <!-- 엑셀 파일로 환자 추가해서 업로드  -->
                            <!-- <input type="file" id="uploadId" class="input_btn upBtn" placeholder="" value="업로드" title=""/> -->
                            <!-- <label class="input_btn uploadInput" for="uploadId">업로드</label> -->

                            <input type="button" class="dpTwo" value="박스형으로보기" title="박스형" v-if="is_file_list_card_mode == false" @click="fileListCardModeOnOff"/>
                            <input type="button" class="dpOne" value="리스트로보기"   title="리스트" v-if="is_file_list_card_mode == true"  @click="fileListCardModeOnOff"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 환자 리스트 -->
        <PatientListComp 
            v-if="patient_tab == 'LIST'" 
            :event_bus="event_bus"
            @showPatientSendMessageModal="showPatientSendMessageModal"
        />
        <!-- 환자 리스트 끝 -->

        <!-- 환자 메세지 발송 현황 -->
        <HospitalMessageStatus v-if="patient_tab == 'SEND_MESSAGE'"/>

        <!-- 메세지 템플릿 -->
        <HospitalMessageTemplate v-if="patient_tab == 'MESSAGE_TEMPLATE'"/>

        <!-- 환자 파일함 -->
        <PatientFileBox v-if="patient_tab == 'PATIENT_FILEBOX'" :is_file_list_card_mode="is_file_list_card_mode" :event_bus="event_bus"/>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');
const ModalInfo = namespace('ModalInfo');

import PatientListComp from '@/components/hodu_d/PatientListComp.vue';
import HospitalMessageStatus from '@/components/hodu_d/HospitalMessageStatus.vue';
import HospitalMessageTemplate from '@/components/hodu_d/HospitalMessageTemplate.vue';
import PatientFileBox from '@/components/hodu_d/PatientFileBox.vue';

import { hodu_doc_modal_info, hodu_doc_object } from '@/model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        PatientListComp, HospitalMessageStatus, HospitalMessageTemplate, PatientFileBox
    },
}) 
export default class PatientList extends Mixins(VueHoduCommon) {

    /**
     * @HoduDocInfo.State
     */
    @HoduDocInfo.State patient_tab !: "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX";

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetPatientTab ?: (parms : "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX") => void;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetPatientSendMessageModalInfo ?: (params : hodu_doc_modal_info.PatientSendMessageModalInfo) => void;

    event_bus : Vue = new Vue();

    is_search_open : boolean = false;
    search_word : string = "";

    is_file_list_card_mode : boolean = false;

    mounted() : void {

    }

    /**
     * 탭 변경
     */
    setPatientTab(tab : "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX") : void {
        if( this.doSetPatientTab ) { this.doSetPatientTab(tab); }

        if( tab != "PATIENT_FILEBOX" ) {
            this.is_file_list_card_mode = false;
        }

        this.is_search_open = false;
        this.search_word = "";
    }

    /**
     * 메세지 발송을 위해 선택된 환자 리스트 요청
     */
    requestSelectedPatients() : void {
        this.event_bus.$emit('getSelectedPatient');
    }

    /**
     * 리스트 요청 완료시 메세지 발송 Modal 띄우기
     */
    showPatientSendMessageModal(selected_patients : hodu_doc_object.patient_info[]) : void {
        if( selected_patients == null || selected_patients.length < 1 ) {
            this.hodu_show_dialog('alert', '선택 된 환자가 없습니다', ['확인']);
            return;
        }

        if( this.doSetPatientSendMessageModalInfo == null ) { return; }

        this.doSetPatientSendMessageModalInfo({
            show_modal : true,
            selected_patients : JSON.parse(JSON.stringify(selected_patients)),
        });
    }

    /**
     * 최상단 클래스 탭에 따라서 소문자로 반환
     */
    getTopClass() : string {
        return this.patient_tab.toLowerCase();
    }

    /**
     * 다운로드 버튼 클릭
     */
    downloadButtonClick() : void {

        if ( this.patient_tab != 'PATIENT_FILEBOX' ) { return; }
        this.event_bus.$emit('patientFileDownload');

    }

    /**
     * 삭제 버튼 클릭
     */
    deleteButtonClick() : void {
        
        // 환자 리스트에서 삭제
        if( this.patient_tab == 'LIST' ) {
            this.event_bus.$emit('patientDelete');
        }

        // 환자 파일함에서 삭제
        else if ( this.patient_tab == 'PATIENT_FILEBOX' ) {
            this.event_bus.$emit('patientFileDelete');
        }
    }

    /**
     * 파일리스트 보기 모드 변경
     */
    fileListCardModeOnOff() : void {
        this.is_file_list_card_mode = !this.is_file_list_card_mode;
    }

    /**
     * 검색창 열기 / 닫기
     */
    searchOnOff() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_word = "";
            this.event_bus.$emit('searchPatientList');
        }

        // 검색 창 열릴때 검색 창에 포커스 가도록
        else {
            this.$nextTick(() => { $('#patient_search_input').focus(); });
        }
    }

    /**
     * 검색 
     */
    searchPatientList() : void {
        
        // 환자 리스트에서만 검색 가능
        if( this.patient_tab != 'LIST' ) { return; }

        this.event_bus.$emit('searchPatientList', this.search_word);
    }

    /**
     * 예약 검색 keydown
     */
    eventKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        // 검색
        this.searchPatientList();
    }

}
</script>

<style scoped>
    #container #section_ce .schedule_box { padding:0 !important; max-width:100% !important; }
    .grp.settingBg { padding: 0 0 !important; }
    .btnBox  { margin-right: 30px; }
    .posRel h4 { display: inline-block; font-size: 18px; margin-left: 30px; line-height: 65px; height: 65px; }
    .posRel .input_btn { margin: 11px 0 0 20px }
    .sortHeader { margin-top: 27px;}
    .specialityUl li { transition:0.2s; overflow:hidden; position: relative; }
    .specialityUl li:hover { background: #f7f9fb; }
    .specialityUl li:hover a.edit { opacity: 1; }
    .specialityUl a.edit { transition:0.2s; opacity: 0; position: absolute; font-size: 0; display: block; width:30px; height:30px;right:30px;top:12px; background-color:#477fff; border-radius: 50%; box-sizing: border-box;background-image: url('../../assets/images/contents/month_bt_next_fff.svg'); background-size: 26px; background-repeat: no-repeat; background-position: center center;}
    .specialityUl a.edit:hover { background-color:#185eff}
    .specialityUl p { float:left; line-height: 55px; height: 55px; font-weight:bold; font-size: 12px; border-bottom: 1px solid #f1f3f5; }
    .no { width: 10%; display:inline-block; padding-left: 10px; box-sizing: border-box; }
    .checkbox { width:10%; display:inline-block; text-align:center; }
    .name { width:30%;  display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .tel { width: 30%; display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .filebox { width: 20%; font-size: 12px; }
    .filebox a, .tel a { transition:0.2s;position:relative; z-index: 1000; display:inline-block; padding:0 10px; background:#f1f3f5; line-height: 25px; height:25px; border:1px solid #e7e9ea; border-radius: 20px; }
    .tel { position:relative; z-index: 1000; }
    .tel a { transition:0s; position:relative; z-index: 1000; }
    .specialityUl li:hover .filebox a,.specialityUl li:hover .tel a  {background:#fff; }
    .filebox a:hover, .tel a:hover { color:#fff; background:#477fff !important; border-color:#2a6bff }
    input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 20px;height: 20px;border-radius:3px; margin-top:15px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;font-size: 0;margin-right: 8px;}
    input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;}
    #uploadId { display: none; }
    .uploadInput {cursor:pointer; float:right; }

    .btnAdd { position:absolute; right: 40px; bottom:40px; }
    .btnAdd { opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .btnAdd:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}

    .btnA { float: left;padding-left: 30px;line-height: 66px; }
    .btnA a { -webkit-transition: 0.2s;transition: 0.2s;font-size: 14px;font-weight: bold;height: 35px;line-height: 35px;width: 95px;border-radius: 20px;display: inline-block;text-align: center;  }
    .btnA a.on { background: #2b3b5f;color: #fff;text-align: center; }

    .grp.settingBg { position:static !important; }
    .sortHeader { margin-top:0 !important; }

    .btnBox .dpTwo {display:inline-block;width: 40px;height: 40px;background: url(../../assets/images/contents/ic_dpTwo.png) no-repeat center center;font-size: 0; margin-top:11px;float:right;margin-left:10px;}
	.btnBox .dpOne {display:inline-block;width: 40px;height: 40px;background: url(../../assets/images/contents/ic_dpOne.png) no-repeat center center;font-size: 0; margin-top:11px; margin-left:10px;}

    #container #section_ce .section_ce_fix.list .btnBox .dpTwo, #container #section_ce .section_ce_fix.list .btnBox .dpOne { display: none !important; }
    #container #section_ce .section_ce_fix.message_template .btnBox,  #container #section_ce .section_ce_fix.message_template .btnAdd,   #container #section_ce .section_ce_fix.message_template .btnBox .dpTwo,   #container #section_ce .section_ce_fix.message_template .btnBox .dpOne { display:none !important; }
    #container #section_ce .section_ce_fix.send_message .btnBox .uploadInput,  #container #section_ce .section_ce_fix.send_message .btnAdd,  #container #section_ce .section_ce_fix.send_message .btnBox .dpTwo,   #container #section_ce .section_ce_fix.send_message .btnBox .dpOne { display:none !important; }
    #container #section_ce .section_ce_fix.patient_filebox .btnBox .dmBtn, #container #section_ce .section_ce_fix.patient_filebox .btnBox .uploadInput,  #container #section_ce .section_ce_fix.patient_filebox .btnAdd { display:none !important; }
    
    .left_area_close .grp.settingBg {padding-left: 61px !important;}
    
</style>