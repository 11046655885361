<template>
    <div class="section_ce_fix group_create">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">{{isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? "아파트" : "그룹"}}</h3>
        </div>
        <div id="makeGrpScroll" class="section_scroll"><!--스크롤 생겨야할 영역-->
            <div class="viewGroup">					
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <h3 class="selectTitle"><span class="txt">{{isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? "아파트" : "그룹"}} {{is_created ? "생성" : "수정"}}</span></h3>
                        <div class="btns fr">
                            <input type="button" class="input_btn" value="삭제" v-if="(group_id > 0 && is_deleteFlag)" @click="groupDelete()">
                            <!--<input type="button" class="input_btn" value="취소" >-->
                            <input type="button" class="input_btn" value="저장" @click="saveValidator()">
                        </div>
                    </div>
                    <!-- 체크식일시 content에 addClass(check) -->
                    <div class="content" id="">
                    <div class="mngGrpModal" :style="{ height : `${scroll_height}px` }">
                        <div class="createNewGroup"> <!-- 새그룹 만들시 div display: block -->
                        <form id="groupFileUpload" method="post" action="/hoduweb/upload/groupImageFileUpload.do" enctype="multipart/form-data">
                            <fieldset>
                                <legend class="hidden">새그룹 만들기</legend>
                                <div class="grp toTheLeft">
                                    <label for="grupSetting_grup_nm" class="grpName">그룹명</label>
                                    <!-- 작성 안했을 시 addClass(wrong) -->
                                    <input type="text" class="newGroupName input_txt" placeholder="그룹명을 입력하세요" id="grupSetting_grup_nm" :value="group_data.group_info.group_name" @input="group_data.group_info.group_name = $event.target.value">
                                    
                                    <div class="half grp">
                                    <!-- #grupSetting_image에 클래스추가 dragging -->
                                    <label for="cdImg" id="grupSetting_image" class="clickOrDropBg forImg" :class="{ dragging : image_drag == true }" @dragover.prevent="imageDragOver($event)" @dragleave.prevent="imageDragLeave" @drop.prevent="imageDrop($event)" >클릭 또는 끌어넣기</label>
                                    <input type="file" id="cdImg" class="cdInput" value="click or drop" accept="image/*" @change="addImageNormal($event)"/>

                                    <p class="groupImg_plus" v-if="!(group_data.group_info.image || base64url)">사진추가하기</p>
                                    <p class="groupImg img" id="grupSetting_image" :style="{ background: `url(${group_data.group_info.image ? 'app_images/' + group_data.group_info.image : (group_no_image ? group_no_image : '')})` }" v-if="!base64url">그룹 이미지</p>
                                    <p class="groupImg img" id="grupSetting_image" :style="{ backgroundImage: `url(${base64url})` }" v-if="base64url">그룹 이미지</p>
                                    <div class="grpNmDescript">
                                        <p class="grpNm" style="">{{isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? "아파트 이미지" : "그룹 이미지"}}</p>
                                        <ul class="descript">
                                            <li>{{isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? "아파트를" : "그룹을"}} 대표할 수 있는 사진을 선택하세요.</li>
                                            <li>해상도가 높은 사진 사용을 권장합니다.</li>
                                            <li>사진을 선택 안할 시 그룹 색상 이미지로 저장됩니다.</li>
                                        </ul>
                                    </div>
                                        <!-- 이미지 있을시 보이기 -->
                                        <input type="button" class="btnDel" value="삭제" v-if="base64url || group_data.group_info.image" @click="imageDelete()"/>
                                    </div>
                                    
                                    <!-- 퍼블 추가 2019.05.08 색상 선택 -->
                                    <input type="button" class="clr dc1" value="그룹색상" :style="{ backgroundColor: `${group_data.group_info.group_color} !important` }" @click="colorPick">
                                    <div id="pickColour" :class="{ on : color_pick == true }">
                                        <ul>
                                            <li>
                                                <a>기본색</a>
                                                <ul>
                                                    <li :class="{on: group_data.group_info.group_color == '#FF6363'}"><a href="#" class="dc0" @click.prevent="colorChange('#FF6363')">연어</a></li>
                                                    <li :class="{on: group_data.group_info.group_color == '#FFA70E'}"><a href="#" class="dc1" @click.prevent="colorChange('#FFA70E')">주황</a></li>
                                                    <li :class="{on: group_data.group_info.group_color == '#FFC72F'}"><a href="#" class="dc2" @click.prevent="colorChange('#FFC72F')">노랑</a></li>
                                                    <li :class="{on: group_data.group_info.group_color == '#FF198B'}"><a href="#" class="dc3" @click.prevent="colorChange('#FF198B')">자주</a></li>
                                                    <li :class="{on: group_data.group_info.group_color == '#00B2C7'}"><a href="#" class="dc4" @click.prevent="colorChange('#00B2C7')">청록</a></li>
                                                    <li :class="{on: group_data.group_info.group_color == '#13D08B'}"><a href="#" class="dc5" @click.prevent="colorChange('#13D08B')">초록</a></li>
                                                    <li :class="{on: group_data.group_info.group_color == '#4DBAFF'}"><a href="#" class="dc6" @click.prevent="colorChange('#4DBAFF')">하늘</a></li>
                                                    <li :class="{on: group_data.group_info.group_color == '#477FFF'}"><a href="#" class="dc7" @click.prevent="colorChange('#477FFF')">파랑</a></li>
                                                    <li :class="{on: group_data.group_info.group_color == '#6854FF'}"><a href="#" class="dc8" @click.prevent="colorChange('#6854FF')">보라</a></li>
                                                    <li :class="{on: group_data.group_info.group_color == '#35405A'}"><a href="#" class="dc9" @click.prevent="colorChange('#35405A')">검정</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- 퍼블 추가 2019.05.08 색상 선택 ends -->
                                    
                                    <!--<input type="file" id="chngePicId" accept="image/*" @change="profileImageChange($event)" />-->
                                    <input type="file" name="upl" style="display:none;">
                                    <input type="hidden" id="grupFileSaveName" value="">
                                    <!--<a href="#" class="groupImg" id="grupSetting_image">이미지 선택</a> -->
                                    
                                    <div class="half grp grpInfo">
                                        <label for="grupSetting_grup_rmk" class="grpDescript">그룹 소개글</label> <!--그룹 정보 란 추가로 인해 없어짐-->
                                        <!-- 텍스트애리아에 소개글 작성 되어있을 시 addClass(on) -->
                                        <!-- <textarea class="newGroupIntro input_txt" placeholder="그룹 소개글을 입력하세요 (최소 10자, 최대 40자)" id="grupSetting_grup_rmk" :value="group_data.group_info.description" @input="group_data.group_info.description = $event.target.value"></textarea> -->
                                        <input class="newGroupIntro" placeholder="소개글을 입력하세요" id="grupSetting_grup_rmk" :value="group_data.group_info.description" @input="group_data.group_info.description = $event.target.value" /><!--그룹 정보 란 추가로 인해 없어짐-->

                                        <!-- 회사 정보 -->
                                        <p class="inputGuideTxt">*증명서 출력시 필요한 정보 입니다.</p>
                                        <div class="company_print_detail">
                                            <ul>
                                                <li>
                                                    <p>회사명</p>
                                                    <input type="text" id="input_text" name="input_text" autocomplete="off" class="basicTextInput" placeholder="정확한 회사명을 입력하세요" 
                                                        :value="company_name" @input="company_name = $event.target.value">
                                                </li>
                                                <li>
                                                    <p>회사주소</p>
                                                    <input type="text" id="input_text" name="input_text" autocomplete="off" class="basicTextInput" placeholder="정확한 회사주소를 입력하세요" 
                                                        :value="company_address" @input="company_address = $event.target.value">
                                                </li>
                                                <li>
                                                    <p>대표이사</p>
                                                    <input type="text" id="input_text" name="input_text" autocomplete="off" class="basicTextInput" placeholder="대표자명을 입력하세요" 
                                                        :value="company_ceo" @input="company_ceo = $event.target.value">
                                                </li>
                                            </ul>

                                            <div class="stamp_picture">
                                                <label id="image_label" for="stampImg" title="도장 이미지 선택" class="stamp_img" 
                                                       @dragover.prevent="stampImageDragOver($event)" @dragleave.prevent="stampImageDragLeave" @drop.prevent="stampImageDrop($event)"
                                                       :style="{ backgroundImage : `url(${stamp_blob_url.length > 0 ? stamp_blob_url : `app_images/${group_data.group_info.stamp_image}` })` }"
                                                       v-if="(stamp_blob_url.length > 0 || (group_data.group_info.stamp_image != null && group_data.group_info.stamp_image.length > 0))">
                                                </label>
                                                <label id="image_label" for="stampImg" title="도장 이미지 선택" class="stamp_img" v-else></label>
                                                <input type="file" id="stampImg" accept="image/*" @change="addStampImageNormal($event)">
                                                <p class="stamp_picture_text">증명서 직인</p>

                                                <!-- <p class="groupImg img" id="grupSetting_image" :style="{ background: `url(${group_data.group_info.image ? 'app_images/' + group_data.group_info.image : (group_no_image ? group_no_image : '')})` }" v-if="!base64url">그룹 이미지</p>
                                                <p class="groupImg img" id="grupSetting_image" :style="{ backgroundImage: `url(${base64url})` }" v-if="base64url">그룹 이미지</p> -->
                                            </div>
                                        </div>

                                    </div> 

                                    <div class="half publicSet">
                                        <h5>그룹 공개</h5>
                                        <label class="switch">
                                        <input type="checkbox" v-model="group_data.group_policy.is_public">
                                        <span class="slider round"></span>
                                        </label>
                                        <p class="waitDescript">그룹 비공개 시 검색에 노출되지 않습니다.</p>
                                    </div>

                                    <!-- <input type="text" class="newGroupIntro input_txt radius04" placeholder="그룹소개글을 입력하세요" id="grupSetting_grup_rmk"> -->
                                    <div class="half" v-if="!isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '')">
                                        <h5>{{isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? "입주자" : "멤버"}} 가입시 승인 요청</h5>
                                        <label class="switch">
                                        <input type="checkbox" :checked="group_data.group_policy.is_appr_required" @change="setApproveChange($event)">
                                        <span class="slider round"></span>
                                        </label>
                                        <p class="waitDescript">{{isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? "관리자의" : "관리자 또는 부관리자의"}}  가입요청 승인을 거쳐 가입 할 수있습니다. </p>
                                    </div>
                                </div>
                            
                            </fieldset>
                        </form>
                    </div>
                    </div>	
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

import { ImageCropModalInfo } from '@/store/modules/ModalInfo';
import { t_event_file } from '@/model/event';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupCreate extends Mixins(VueHoduCommon) {

    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;
    
    group_data : any = {
        group_id: 0,
        group_type: "",
        biz_id: "",
        teams: [],
        create_user_id: 0,
        group_info: {
            group_name: "",
            description: "",
            image: "",
            group_color: "#477FFF"
        },
        group_policy: {
            is_public: true,
            is_appr_required: false
        },
        group_features: {},
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: 0
    }

    is_created                 : boolean = true;
    is_deleteFlag              : boolean = false;
    image_drag                 : boolean = false;
    base64url                  : string  = "";
    image_files                : any     = [];
    stamp_image_files          : any     = [];
    group_no_image = null;

    color_pick : boolean = false;

    scroll_height : number = 0;

    company_name : string = "";
    company_address : string = "";
    company_ceo : string = "";
    stamp_blob_url : string  = "";

    async mounted() {

        if ( this.group_id > 0 ) {
            this.is_created = false;

            // 삭제 퍼미션 체크
            this.is_deleteFlag = this.is_group_permmision(this.group_id, "group_info", "delete");

            await this.getGroupDetail();
        }
        else {
            this.group_no_image = require("@/assets/images/contents/img"+this.group_color_to_class_name(this.group_data.group_info.group_color)+".png");
        }

        this.setScroll();
    }

    setScroll() {
        const title_height      : number | undefined = $('.title_box').outerHeight();
        const setting_bg_height  : number | undefined = $('.settingBg').outerHeight();

        this.scroll_height = window.innerHeight - (title_height ?? 0) - (setting_bg_height ?? 0);
    }

    /**
     * 그룹 단건 조회
     */
    async getGroupDetail() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getDetail/${this.group_id}/0`, API_METHOD.GET, null)
            .then(async(response) => {
                if ( !response.data.data.data ) {
                    this.hodu_show_dialog("cancel", "그룹 조회중 오류가 발생하였습니다.", ['확인'], [() => { vue.movePrevPage(); }]);
                    return;
                }

                let data = response.data.data.data;
                this.group_data.group_info.group_name         = data.group_info.group_name;
                this.group_data.group_info.group_color        = data.group_info.group_color;
                this.group_data.group_info.image              = data.group_info.image;
                this.group_data.group_info.description        = data.group_info.description;
                this.group_data.group_info.company_name       = (data.group_info.company_name ?? "");
                this.group_data.group_info.company_address    = (data.group_info.company_address ?? "");
                this.group_data.group_info.company_ceo        = (data.group_info.company_ceo ?? "");
                this.group_data.group_info.stamp_image        = (data.group_info.stamp_image ?? "");
                this.group_data.group_policy.is_appr_required = data.group_policy.is_appr_required;
                this.group_data.group_policy.is_public        = data.group_policy.is_public;

                this.company_name = this.group_data.group_info.company_name;
                this.company_address = this.group_data.group_info.company_address;
                this.company_ceo = this.group_data.group_info.company_ceo;

                if ( this.group_data.group_info.group_color.length == 9 ) {
                    this.group_data.group_info.group_color = "#" + this.group_data.group_info.group_color.substring(3, this.group_data.group_info.group_color.length);
                }

                if ( this.group_data.group_info.image == "" ) {
                    this.noGroupImage();
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 승인 변경
     */
    setApproveChange(any) : void {
        this.group_data.group_policy.is_appr_required = any.target.checked;
    }

    /**
     * 그룹 삭제
     */
    groupDelete() : void {
        let lb_flag : boolean = this.is_group_permmision(this.group_id, "group_info", "delete");

        if ( !lb_flag ) {
            return;
        }

        this.hodu_show_dialog("cancel", "그룹을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.groupDeleteService();
            },
        ]);
    }

    /**
     * 그룹 삭제
     */
    groupDeleteService() : void {
        const vue = this;

        this.hodu_api_call(`api/v1/groups/updateGroupDelete/${this.group_id}`, API_METHOD.DELETE, null)
            .then(async(response) => {
                await vue.reset_group_initialization();
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 그룹 생성
     */
    async saveValidator() : Promise<void> {
        if ( this.group_data.group_info.group_name.length < 2 || this.group_data.group_info.group_name.length > 20 ) {
            this.hodu_show_dialog("alert", "그룹명은 최소 2글자 부터 최대 20글자 입니다.", ['확인']);
            return;
        }

        // 그룹 명에 호두닥이 포함 될 수 없음
        if( new RegExp('호두닥').test(this.group_data.group_info.group_name) == true ) {
            this.hodu_show_dialog("alert", '"호두닥"은 그룹명에 사용 하실 수 없습니다', ['확인']);
            return;
        }

        // 그룹 소개글 제한 없음
        // if ( this.group_data.group_info.description.length > 20 ) {
        //     this.hodu_show_dialog("alert", "그룹 소개글은 최대 20글자 입니다.", ['확인']);
        //     return;
        // }

        if( this.group_id == 0 && !(this.base64url && this.image_files.length > 0) ) {
            this.hodu_show_dialog("alert", "기본 이미지로 그룹을 생성하시겠습니까?", ['아니오', '예'], [
                () => {},
                () => {
                    this.groupFileSave();
                },
            ]);
            return;
        }

        this.hodu_show_dialog("alert", "그룹 " + (this.group_id == 0 ? "생성" : "수정") + " 하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.groupFileSave();
            },
        ]);
    }

    /**
     * 파일 업로드
     */
    async groupFileSave() : Promise<void> {
        const vue = this;

        let temp_image_files : t_event_file[] = [];
        let temp_stamp_image_files : t_event_file[] = [];

        if ( this.image_files.length > 0 ) {
            const form_data : FormData = new FormData();

            // FormData 내용 생성
            const image_files_count : number = this.image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.image_files[i].file);
            }

            try {
                const response = await this.hodu_temp_upload(form_data);
                temp_image_files = response.data.data.temp_files;
            } catch(e) {
                this.hodu_error_process(e, true, false);
                return;
            }
        }

        if( this.stamp_image_files.length > 0 ) {
            const form_data : FormData = new FormData();

            // FormData 내용 생성
            const image_files_count : number = this.stamp_image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.stamp_image_files[i].file);
            }

            try {
                const response = await this.hodu_temp_upload(form_data);
                temp_stamp_image_files = response.data.data.temp_files;
            } catch(e) {
                this.hodu_error_process(e, true, false);
                return;
            }
        }

        this.group_id == 0 ? await this.groupCreateSave(temp_image_files, temp_stamp_image_files) 
                           : await this.groupModifySave(temp_image_files, temp_stamp_image_files);
    }

    /**
     * 그룹 생성
     */
    async groupCreateSave(po_files : t_event_file[], stamp_files : t_event_file[]) : Promise<void> {
        const vue = this;

        this.group_data.group_type             = "GROUP";
        this.group_data.biz_id                 = "";
        // this.group_data.group_policy.is_public = true;

        this.group_data.group_info.company_name = this.company_name;
        this.group_data.group_info.company_address = this.company_address;
        this.group_data.group_info.company_ceo = this.company_ceo;

        let param_data = {group: this.group_data}

        if ( null != po_files && po_files.length > 0 ) {
            param_data["group_file"] = po_files[0];
        }

        if ( null != stamp_files && stamp_files.length > 0 ) {
            param_data["stamp_file"] = stamp_files[0];
        }

        await this.hodu_api_call(`api/v1/groups`, API_METHOD.POST, param_data)
            .then(async(response) => {
                await this.get_group_role_service();
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 그룹 수정
     */
    async groupModifySave(po_files : t_event_file[], stamp_files : t_event_file[]) : Promise<void> {
        const vue = this;

        this.group_data.group_info.company_name = this.company_name;
        this.group_data.group_info.company_address = this.company_address;
        this.group_data.group_info.company_ceo = this.company_ceo;

        let param_data = {group: this.group_data}

        if ( null != po_files && po_files.length > 0 ) {
            param_data["group_file"] = po_files[0];
        }

        if ( null != stamp_files && stamp_files.length > 0 ) {
            param_data["stamp_file"] = stamp_files[0];
        }

        await this.hodu_api_call(`api/v1/groups/updateGroup/${this.group_id}`, API_METHOD.PUT, param_data)
            .then(async(response) => {

                if( this.group_id == this.scope_group_id ) {
                    await this.get_group_role_service();
                    const groups = this.all_group_data.filter(group => group.group_id == this.scope_group_id);
                    if( groups.length > 0 ) {

                        const group = groups[0];

                        this.doSetScopeGroupTeamOption({
                            group_team_name : group.group_name,
                            group_team_color : group.color,
                            group_team_image : `${this.make_group_profile_image_url(this.scope_group_id)}?t=${new Date().getTime()}`,
                            group_team_descript : this.group_data.group_info.description,
                            biz_id : group.biz_id,
                            biz_type : group.biz_type
                        });

                    }
                }

                await vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 색상선택
     */
    colorChange(color) : void {
        this.color_pick = false;
        this.group_data.group_info.group_color = color;
        this.noGroupImage();
    }

    /**
     * 색상 선택 boolean
     */
    colorPick() : void {
        this.color_pick = !this.color_pick;
    }

    /**
     * 이미지 삭제
     */
    imageDelete() : void {
        if ( this.base64url ) {
            this.base64url = "";
            this.image_files = [];
        }

        if ( this.group_data.group_info.image ) {
            this.group_data.group_info.image = "";
        }

        this.noGroupImage();
    }

    /**
     * 이미지 변경 (업로드 안되어있을때)
     */
    noGroupImage() : void {
        this.group_no_image = require("@/assets/images/contents/img"+this.group_color_to_class_name(this.group_data.group_info.group_color)+".png");
    }

    /**
     * 이미지 드래그해서 이미지 영역에 올려놨을때
     */
    imageDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.image_drag = true;

    }

    /**
     * 이미지 드래그해서 이미지 영역에서 벗어났을때
     */
    imageDragLeave() : void {
        this.image_drag = false;
    }

    /**
     * 이미지를 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    imageDrop(event) : void {
        this.image_drag = false;
        this.addImage(event.dataTransfer.files);
    }

    /**
     * 그룹 이미지 추가
     */
    addImageNormal(event) : void {
        const file : File[] = event.target.files;
        this.addImage(file);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(file : File[]) : Promise<void> {
        const vue = this;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(file[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#cdImg').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                if( this.doSetImageCropModalInfo == null ) { return; }

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (4/3), // 4:3
                    image_url : url,
                    cancel_function : async() => {
                        $('#cdImg').val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        
                        this.base64url = cropped_url;

                        const resize_blob : Blob = await vue.hodu_image_resize(this.base64url);

                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = file[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, file[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = file[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        // 업로드용 파일 객체 담기
                        vue.image_files = [];
                        vue.image_files.push({
                            file : resize_file
                        });

                        $('#cdImg').val("");

                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 이미지 드래그해서 이미지 영역에 올려놨을때
     */
    stampImageDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.image_drag = true;
    }

    /**
     * 이미지 드래그해서 이미지 영역에서 벗어났을때
     */
    stampImageDragLeave() : void {
        this.image_drag = false;
    }

    /**
     * 도장 이미지를 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    stampImageDrop(event) : void {
        this.image_drag = false;
        this.addStampImage(event.dataTransfer.files);
    }

    /**
     * 도장 이미지 추가
     */
    addStampImageNormal(event) : void {
        const file : File[] = event.target.files;
        this.addStampImage(file);
    }

    async addStampImage(file : File[]) : Promise<void> {

        const vue = this;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(file[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#stampImg').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const resize_blob : Blob = await vue.hodu_image_resize(base64url);
                const url :string = URL.createObjectURL(resize_blob);

                vue.stamp_blob_url = url;

                // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                let resize_file : File = file[0];
                try {
                    resize_file = await this.hodu_blob_to_file(resize_blob, file[0].name);
                } catch(e) {
                    try {
                        (resize_blob as any).lastModifiedDate = new Date();
                        (resize_blob as any).name = file[0].name;
                        resize_file = (resize_blob as any);
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    }
                }

                vue.stamp_image_files.splice(0, vue.stamp_image_files.length);
                vue.stamp_image_files.push({
                    file : resize_file
                });

                $('#stampImg').val("");

            })
            .catch((e) => {
                console.log('filereader promise error', e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.setScroll();
    }

}
</script>
<style scoped>
    .title_box { height:61px }
    .grp.settingBg { top: 64px }
	#makeGrpScroll .settingBg h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
	#makeGrpScroll .content {padding-top: 0;margin-top:0;}
	#container #section_ce #makeGrpScroll .schedule_box {padding-left: 0;}
	#container #section_ce #makeGrpScroll .schedule_box .settingBg .btns input {margin-top: 10px;margin-left: 15px;}
	#container #section_ce #makeGrpScroll .schedule_box .settingBg .btns {margin-right: 30px;margin-top:0;}
	#makeGrpScroll .createNewGroup { padding-bottom:200px; max-width:800px; margin-left:0 !important; }
	#groupSelectBg h5 { font-size:16px;line-height:40px; height:40px; }
	#groupSelectBg h5 span {float: right;font-size:14px;}
	#groupSelectBg h5 a {width: 100%;display: inline-block;}
	div#groupSelectBg {    float: left;position: relative;width: 100%;margin-bottom: 20px;height: auto;padding-bottom: 20px;border-top: 1px solid #e7e9ea;padding-top: 20px;border-bottom: 1px solid #e7e9ea;}
	.grp.toTheRight {float: left;width: 100%;}
	div#groupSelectBg:hover h5:hover span { color:#477fff;  }
	div#groupSelectBg:hover .selectUl {height: auto;font-size: 12px;}
	div#groupSelectBg .selectUl {height: 0;font-size: 0;}
	#makeGrpScroll .img {position:relative;width: 335px;height: 230px;margin-top: 40px; font-size:0; background: url(../assets/images/contents/imgDc7.png) no-repeat; background-size:cover !important; background-position: center center !important; }
	#makeGrpScroll .half.grp .grpNm {line-height: 40px;height: 40px;}
	.createNewGroup .half h5 {font-size: 14px;height: 40px;line-height: 40px;}
	.createNewGroup .half .switch {width: 52px !important;position: absolute;top: 7px;right: 0;margin-top: 0;}
	.createNewGroup .grpDescript { display:none; }
    .half.grp .grpNmDescript { display: inline-block; margin-left: 25px; box-sizing: border-box; width: 350px; margin-top: 70px; float: right; }
	.half.grp .grpNm {line-height: 22px;text-align: left;top: 55px;box-sizing: border-box;padding-left: 10px;margin-top:0; }
	.half.grp ul.descript {width: 100%;color:#273044; line-height: 22px;text-align: left;box-sizing: border-box;padding: 15px;padding-right:0;}
	.half.grp ul.descript li { display: block;font-size: 12px;margin-bottom: 7px;line-height: 28px;margin-left: 15px;}
	.half.grp ul.descript li:before {content: '';display: inline-block;width: 5px;height: 5px;background: #acb2bf;margin-left: -15px;margin-right: 10px;border-radius: 50%;}
	.half.grp ul.descript li:last-child { margin-bottom:0; }
	
	input#grupSetting_grup_nm:focus, input#grupSetting_grup_nm:hover {border-bottom: 2px solid #477fff;}
	input#grupSetting_grup_nm {position: static;top: 0px;font-weight: bold;font-size: 24px;border: 0 none;padding-left: 0;border-bottom: 2px solid #f1f3f5;height: 70px;padding-right: 40px;}
	input#grupSetting_grup_nm.wrong {color: red;border-bottom: 2px solid red}
	input#grupSetting_grup_nm.wrong::placeholder {color: red;}
	textarea#grupSetting_grup_rmk.wrong {color: red;border-top: 1px solid red !important; border-bottom: 1px solid red !important}
	textarea#grupSetting_grup_rmk.wrong::placeholder {color: red;}
	
	#makeGrpScroll .img:after {position: absolute;content: '';opacity:0;width: 100%;height: 100%;border-radius: 5px;background: rgba(0, 0, 0, 0.05);transition:0.2s;}
	#makeGrpScroll .img:hover:after { opacity:1;}
	.waitDescript { line-height:30px; height:30px;color:#273044}
    .mngGrpModal .half.grp {padding-left: 0 !important;width: 100%; min-width:710px; float: left;position: relative;box-sizing: border-box;padding-bottom: 15px;}
    input#grupSetting_grup_rmk {font-weight:bold;padding-left: 0;font-size: 18px;border-top: 1px solid #e7e9ea;transition: 0.2s;line-height: 70px;border-bottom: 1px solid #e7e9ea;height: 70px;}
    input#grupSetting_grup_rmk:focus {border-color: #477fff !important;}
    input#grupSetting_grup_rmk:hover {border-color: #d7dcde;}
    input#cdImg {display: none;}
    label#grupSetting_image {z-index:100;position: absolute;border-radius:5px;width: 335px;left: 0;top: 40px;height: 230px;background:rgba(71, 127, 255, 0.15); opacity:0;}
    label#grupSetting_image:hover {opacity:1;}
    label#grupSetting_image.dragging {background: rgba(255, 255, 255, 0.3) url(../assets/images/contents/img_dragDrop.png) no-repeat center center !important;opacity: 1;background-size: 160px !important;border: 2px dashed #477fff;box-sizing: border-box;}
    div#pickColour { top: 90px; }
    div#pickColour>ul>li { width:100%; }
    div#pickColour>ul>li > a { display:none; }
    .groupImg_plus{background: url(../assets/images/contents/bg_group_plus.png) no-repeat;width: 335px;height: 230px;position: absolute;top: 40px;left: 0px;z-index:90;font-size: 0px;}

    /* 회사정보 */
    .company_print_detail { display: flex; justify-content: space-between; align-items: center; }
    .mngGrpModal .grpInfo { border-bottom: 2px solid #f1f3f5; padding-top: 15px; }
    .grpInfo .inputGuideTxt { color: #ff6363; line-height: 40px; margin-top: 10px; }
    .grpInfo ul { width: 80%; }
    .grpInfo ul li { display: flex; line-height: 35px; font-size: 13px; }
    .grpInfo ul li p { width: 80px; font-weight: bold; box-sizing: border-box; }
    .createNewGroup .grp.grpInfo input { white-space: nowrap; text-overflow: ellipsis; font-weight: normal; line-height: 35px; margin-top: 0; }

    .group_create .mngGrpModal { overflow-y: auto; }
    .group_create .createNewGroup { height : auto !important; }

    .group_create ::-webkit-scrollbar{width: 6px;}
    .group_create ::-webkit-scrollbar-track{background-color: transparent;}
    .group_create ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

    .stamp_picture { width: 80px; height: auto; }
    .stamp_picture .stamp_picture_text { line-height: 30px; text-align: center; font-weight: bold; opacity: .7; }
    label#image_label { cursor: pointer; }
    .stamp_img { width: 80px; height: 80px; display: inline-block; box-shadow: 0 5px 10px rgb(0 0 0 / 10%); border: 5px solid #fff; box-sizing: border-box; background-image: url('../assets/images/contents/hoduhome_car_camera.svg'); background-size: 50px auto; background-repeat: no-repeat; background-position: center center; }
    .stamp_picture input#stampImg { display: none; }

</style>