<template>
    <div id="work_documentation" class="section_ce_fix">
        <TitleBox class="title_box" :back_disable="true" :title="'업무 문서화'" />

        <div class="content">
            <div class="document_box">
                <ul>
                    <!-- 업무 종류 -->
                    <li>
                        <dl>
                            <dt><label><span class="title_pointer"></span>업무 종류</label></dt>
                            <dd>
                                <div class="work_type_div">
                                    <!-- <input type="radio" id="work_type_report" name="work_type">
                                    <label for="work_type_report">업무일지</label> 
                                    <input type="radio" id="work_type_meeting_log" name="work_type">
                                    <label for="work_type_meeting_log">회의록</label> -->

                                    <input type="radio" id="work_type_report" name="work_type" value="REPORT" v-model="work_type" @change="changeWorkType" v-if="getReportReadPermission() == true"/>
                                    <label for="work_type_report" v-if="getReportReadPermission() == true">업무일지</label> 
                                    <input type="radio" id="work_type_meeting_log" name="work_type" value="MEETINGLOG" v-model="work_type" @change="changeWorkType" v-if="getMeetingLogReadPermission() == true"/>
                                    <label for="work_type_meeting_log" :class="{ none_report : getReportReadPermission() == false }" v-if="getMeetingLogReadPermission() == true">회의록</label>
                                </div>
                            </dd>
                        </dl>
                    </li>

                    <!-- 업무 문서 종류 -->
                    <li class="document_type_li">
                        <dl>
                            <dt><label><span class="title_pointer"></span>문서 종류</label></dt>
                            <dd>
                                <div class="document_type_div">

                                    <div class="document_fragment" :class="`${ document_type == document.type ? 'on' : '' } ${document.type}`"
                                        :key="index" v-for="(document, index) in (work_type == 'REPORT' ? report_documents : meeting_log_documents)">
                                        <img @click="selectDocument($event, document.type)"/>
                                        <span>{{ document.text }}</span>
                                        <input type="button" class="preview" value="미리보기" @click="preview(document.preview)"/>
                                    </div>

                                    <!-- <div class="document_fragment on daily_report_1">
                                        <img>
                                        <span>일일업무일지1</span>
                                        <input type="button" class="preview" value="미리보기">
                                    </div>
                                    <div class="document_fragment daily_report_2">
                                        <img>
                                        <span>일일업무일지2</span>
                                        <input type="button" class="preview" value="미리보기">
                                    </div>
                                    <div class="document_fragment weekly_report_1">
                                        <img>
                                        <span>주간업무일지1</span>
                                        <input type="button" class="preview" value="미리보기">
                                    </div>
                                    <div class="document_fragment weekly_report_2">
                                        <img>
                                        <span>주간업무일지2</span>
                                        <input type="button" class="preview" value="미리보기">
                                    </div> -->

                                </div>
                            </dd>
                        </dl>
                    </li>

                    <!-- 날짜 달력 띄워주세요 -->
                    <li class="schDateList">
                        <dl>
                            <dt><label for="sch_from">날짜</label></dt>
                            <dd>
                                <input type="button" id="work_search_sch_from" class="input_txt wd45" :value="!start ? '검색 시작일' : start_text" :class="{ on : start != null }">
                                <span class="ware_seper">~</span>
                                <input type="button" id="work_search_sch_to" class="input_txt wd45 filterEndDate" :value="!end ? '검색 종료일' : end_text" :class="{ on : end != null }">
                            </dd>
                        </dl>
                    </li>

                    <!-- 제목 -->
                    <li>
                        <dl>
                            <dt><label for="sch_title">제목</label></dt>
                            <dd>
                                <input id="sch_title" type="text" class="input_txt" placeholder="제목을 입력하세요" :value="title" @input="title = $event.target.value">
                            </dd>
                        </dl>
                    </li>

                    <!-- 내용 -->
                    <li>
                        <dl>
                            <dt><label for="sch_title">내용</label></dt>
                            <dd>
                                <input id="sch_title" type="text" class="input_txt" placeholder="내용을 입력하세요" :value="content" @input="content = $event.target.value">
                            </dd>
                        </dl>
                    </li>

                    <!-- 색상 -->
                    <li class="sch_clr_li">
                        <dl>
                            <dt><label for="sch_place">일정 색상</label></dt>
                            <dd>
                                <div class="sch_clr_div">
                                    <ul>

                                        <li :key="color" v-for="(color, index) in dc_color">
                                            <input type="checkbox" :id="`sch_clr_dc0${index}`" class="sch_clr_dc" :checked="isContainColor(color)" @change="changeSearchColor($event, color)"/>
                                            <label :for="`sch_clr_dc0${index}`" :class="hodu_color_dc_lc(color)" class="sch_clr_dc">{{ hodu_color_dc_lc_label_text(color) }}</label>
                                        </li>

                                        <li :key="color" v-for="(color, index) in lc_color">
                                            <input type="checkbox" :id="`sch_clr_lc0${index}`" class="sch_clr_lc" :checked="isContainColor(color)" @change="changeSearchColor($event, color)"/>
                                            <label :for="`sch_clr_lc0${index}`" :class="hodu_color_dc_lc(color)" class="sch_clr_lc">{{ hodu_color_dc_lc_label_text(color) }}</label>
                                        </li>

                                        <!-- <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc00" class="sch_clr_dc">
                                            <label for="sch_clr_dc00" class="sch_clr_dc dc0">빨강</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc01" class="sch_clr_dc">
                                            <label for="sch_clr_dc01" class="sch_clr_dc dc1">주황</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc02" class="sch_clr_dc">
                                            <label for="sch_clr_dc02" class="sch_clr_dc dc2">노랑</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc03" class="sch_clr_dc">
                                            <label for="sch_clr_dc03" class="sch_clr_dc dc3">자주</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc04" class="sch_clr_dc">
                                            <label for="sch_clr_dc04" class="sch_clr_dc dc4">청록</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc05" class="sch_clr_dc">
                                            <label for="sch_clr_dc05" class="sch_clr_dc dc5">초록</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc06" class="sch_clr_dc">
                                            <label for="sch_clr_dc06" class="sch_clr_dc dc6">하늘</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc07" class="sch_clr_dc">
                                            <label for="sch_clr_dc07" class="sch_clr_dc dc7">파랑</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc08" class="sch_clr_dc">
                                            <label for="sch_clr_dc08" class="sch_clr_dc dc8">보라</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_dc08" class="sch_clr_dc">
                                            <label for="sch_clr_dc09" class="sch_clr_dc dc9">검정</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc01" class="sch_clr_lc">
                                            <label for="sch_clr_lc00" class="sch_clr_lc lc0">연한 갈색</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc01" class="sch_clr_lc">
                                            <label for="sch_clr_lc01" class="sch_clr_lc lc1">연한 자주</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc02" class="sch_clr_lc">
                                            <label for="sch_clr_lc02" class="sch_clr_lc lc2">연한 빨강</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc03" class="sch_clr_lc">
                                            <label for="sch_clr_lc03" class="sch_clr_lc lc3">연한 보라</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc04" class="sch_clr_lc">
                                            <label for="sch_clr_lc04" class="sch_clr_lc lc4">연한 주황</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc05" class="sch_clr_lc">
                                            <label for="sch_clr_lc05" class="sch_clr_lc lc5">연한 노랑</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc06" class="sch_clr_lc">
                                            <label for="sch_clr_lc06" class="sch_clr_lc lc6">연한 초록</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc07" class="sch_clr_lc">
                                            <label for="sch_clr_lc07" class="sch_clr_lc lc7">연한 파랑</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc08" class="sch_clr_lc">
                                            <label for="sch_clr_lc08" class="sch_clr_lc lc8">연한 하늘</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" name="chk" id="sch_clr_lc09" class="sch_clr_lc">
                                            <label for="sch_clr_lc09" class="sch_clr_lc lc9">연한 검정</label>
                                        </li> -->

                                    </ul>
                                    <div class="selectAllDiv">
                                        <p>
                                            <input type="checkbox" id="selectAllClrs" v-model="is_color_all_check" @change="changeColorAllCheck">
                                            <label for="selectAllClrs" class="dc7"><span class="">전체 선택</span></label>
                                        </p>
                                    </div>
                                </div>
                            </dd>	
                        </dl>
                    </li>

                    <!-- 작성자 -->
                    <li class="docuBox_last">
                        <dl>
                            <dt><label for="sch_title">작성자</label></dt>
                            <dd>
                                <input id="sch_title" type="text" class="input_txt" placeholder="작성자를 입력하세요" :value="writer" @input="writer = $event.target.value">
                            </dd>
                        </dl>
                    </li>

                </ul>
            </div>

            <!-- 버튼 영역 -->
            <div class="save_last_section">
                <input type="button" value="검색 초기화" @click.prevent="reset">
                <input type="button" value="검색" @click.prevent="search">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { EVENT_SUB_TYPE, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import TitleBox from '@/components/ui/TitleBox.vue';
import moment from 'moment';

@Component({
    components: {
        TitleBox
    },
})
export default class WorkDocumentation extends Mixins(VueHoduCommon) {

    work_type : EVENT_SUB_TYPE = EVENT_SUB_TYPE.REPORT;
    document_type : string = "daily_report_1";

    report_documents : any[] = [
        { "type" : "daily_report_1", "text" : "일일업무일지1", "preview" : "ic_preview_report_1.png" }, 
        { "type" : "daily_report_2", "text" : "일일업무일지2", "preview" : "ic_preview_report_2.png" }, 
        { "type" : "weekly_report_1", "text" : "주간업무일지1", "preview" : "ic_preview_report_3.png" }, 
        { "type" : "weekly_report_2", "text" : "주간업무일지2", "preview" : "ic_preview_report_4.png" }
    ];
    meeting_log_documents : any[] = [
        { "type" : "meeting_log_1", "text" : "회의록1", "preview" : "ic_preview_meeting_log_1.png" }, 
        { "type" : "meeting_log_2", "text" : "회의록2", "preview" : "ic_preview_meeting_log_2.png" }
    ];

    start : Date | null = null;
    end : Date | null = null;

    start_text = "";
    end_text = "";

    title : string = "";
    content : string = "";
    writer : string = "";

    selected_color : string[] = [];
    is_color_all_check : boolean = false;

    first_event_complete : boolean = false;
    
    beforeMount() : void {
        this.start = moment().set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
        this.end = moment(this.start).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
        this.makeDateText();
    }

    mounted() : void {

        this.datepickerInit();

        if( this.getReportReadPermission() == false ) {
            this.work_type = EVENT_SUB_TYPE.MEETINGLOG;
            this.changeWorkType(this.work_type);
        }

        // $("#selectAllClrs").click(function(){
        //     if($("#selectAllClrs").prop("checked")){
        //         $("input[name=chk]").prop("checked",true);
        //     }else{
        //         $("input[name=chk]").prop("checked",false);
        //     }
        // });
    }

    /**
     * datepicker 세팅
     */
    datepickerInit() : void {

        let datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange : '1900:2050',
            onSelect : this.dateChange
        };

        // @ts-ignore
        $('#work_search_sch_from').datepicker(datepicker_option);

        // @ts-ignore
        $('#work_search_sch_to').datepicker(datepicker_option);

    }

    /**
     * 날짜 변경
     */
    dateChange(dateText, inst) : void {
        const selected_date = moment(dateText).toDate();

        if( this.document_type.includes('weekly') == false ) {
            inst.input.val(`${this.hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(selected_date, '요일')}`);
        }
        
        if( inst.id == 'work_search_sch_from' ) {
            
            // 일반
            if( this.document_type.includes('weekly') == false ) {
                this.start = moment(selected_date).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                if( this.end == null || this.end.getTime() < this.start.getTime() ) {
                    this.end = moment(selected_date).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                }
            } 
            
            // 주간
            if( this.document_type.includes('weekly') == true ) {
                this.start = moment(selected_date).set('day', 1).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                this.end = moment(this.start).set('date', moment(this.start).get('date') + 4).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                inst.input.val(`${this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.start, '요일')}`);
            }
        }

        if( inst.id == 'work_search_sch_to' ) {

            // 일반 
            if( this.document_type.includes('weekly') == false ) {
                this.end = moment(selected_date).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                if( this.start == null || this.end.getTime() < this.start.getTime() ) {
                    this.start = moment(selected_date).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                }
            }

            // 주간
            if( this.document_type.includes('weekly') == true ) {
                this.start = moment(selected_date).set('day', 1).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                this.end = moment(this.start).set('date', moment(this.start).get('date') + 4).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                inst.input.val(`${this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.end, '요일')}`);
            }
        }

        this.makeDateText();
    }
    
    /**
     * 날짜 텍스트 생성
     */
    makeDateText() : void {

        if( this.start != null ) {
            this.start_text = `${this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.start, '요일')}`;
        }

        if( this.end != null ) {
            this.end_text = `${this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.end, '요일')}`;
        }

    }

    /**
     * 업무 종류 선택
     */
    changeWorkType(event : any) : void {
        try {
            if( this.work_type == EVENT_SUB_TYPE.REPORT ) {
                this.document_type = this.report_documents[0].type;
            }

            if( this.work_type == EVENT_SUB_TYPE.MEETINGLOG ) {
                this.document_type = this.meeting_log_documents[0].type;
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 문서 종류 선택
     */
    selectDocument(event : any, document_type : string) : void {
        this.document_type = document_type;

        // 날짜 변경
        if( document_type.includes('weekly') == true && this.start && this.end ) {
            this.start = moment(this.start).set('day', 1).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
            this.end = moment(this.start).set('date', moment(this.start).get('date') + 4).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
            this.makeDateText();
        }
    }

    /**
     * 이미지 미리보기
     */
    preview(image_name : string) : void {
        window.open(`/img/preview/${image_name}`, "_blank");
    }

    /**
     * 해당 컬러가 검색 할 컬러에 포함되어있는지 여부 반환
     */
    isContainColor(color : string) : boolean {
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();
        return (this.selected_color.indexOf(upper_color) > -1);
    }

    /**
     * 검색 컬러 변경
     */
    changeSearchColor(event : any, color : string) : void {
        const checked : boolean = event.target.checked;
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();

        const color_index : number = this.selected_color.indexOf(upper_color);

        // 체크 하는 경우
        if( checked == true ) {
            if( color_index < 0 ) { this.selected_color.push(upper_color); }
        }

        // 체크를 해제 하는 경우
        else {
            if( color_index > -1 ) { this.selected_color.splice(color_index, 1); }
        }

        this.is_color_all_check = this.selected_color.length == 20;
    }

    /**
     * 색상 전체 선택 / 선택 해제
     */
    changeColorAllCheck(event : any) : void {
        const checked : boolean = event.target.checked;

        this.selected_color.splice(0, this.selected_color.length);
        if( checked ) {
            for( const color of this.dc_color ) this.selected_color.push(color);
            for( const color of this.lc_color ) this.selected_color.push(color);
        }
    }
    
    /**
     * 검색 타입 초기화
     */
    reset() : void {
        this.work_type = EVENT_SUB_TYPE.REPORT;
        this.document_type = this.report_documents[0].type;

        this.start = null;
        this.end = null;
        this.start_text = "";
        this.end_text = "";

        this.title = "";
        this.content = "";
        this.writer = ""; 

        this.selected_color.splice(0, this.selected_color.length);
        this.is_color_all_check = false;
    }

    /**
     * 검색
     */
    search() : void {
        if( !this.check() ) return;

        if( this.start == null || this.end == null) return;

        // popup open
        let query = "";
        query += `calendar_id=${this.calendar_id}`;
        query += `&event_sub_type=${this.work_type}`;
        query += `&document_type=${this.document_type}`;
        query += `&start=${moment(this.start).utc().format()}`;
        query += `&end=${moment(this.end).utc().format()}`;
        
        if( this.title.length > 0 ) query += `&title=${encodeURIComponent(this.title)}`;
        if( this.content.length > 0 ) query += `&content=${encodeURIComponent(this.content)}`;
        if( this.writer.length > 0 ) query += `&writer=${encodeURIComponent(this.writer)}`; 

        query += `&request_user_id=${this.user_id}`;
        query += `&scope=${this.scope}`;
        query += `&scope_id=${this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id}`;
        if( this.selected_color != null && this.selected_color.length < 20 ) {
            const color_size : number = this.selected_color.length;
             for( let i = 0; i < color_size; i++ ) {
                 if( this.selected_color[i] == null || this.selected_color[i].length < 7 ) {
                     continue;
                 }

                 query += `&color=${encodeURIComponent(this.selected_color[i])}`;
                 if( this.selected_color[i].length == 7 ) { query += `&color=${encodeURIComponent(this.selected_color[i].replace(/#/, "#FF"))}`; }
                 if( this.selected_color[i].length == 9 ) { query += `&color=${encodeURIComponent(this.selected_color[i].replace(/#FF/, "#"))}`; }
             }
        }

        const timezone = -(new Date().getTimezoneOffset());
        query += `&timezone=${timezone ? timezone : 540}`;

        try {
            let type = navigator.appName;
            let country_code = 'ko-KR';
            if (type == 'Netscape') {
                country_code = navigator.language;
            } else {
                country_code = navigator['userLanguage'];
            }
            query += `&country_code=${country_code}`;
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            query += `&country_code=ko-KR`;
        }

        window.open(`/work_pdf.html?${query}`, "work_pdf", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);
    }

    /**
     * 옵션 체크
     */
    check() : boolean {

        if( this.start == null ) {
            this.hodu_show_dialog('alert', "날짜를 입력해주세요", ['확인'], [() => document.getElementById('work_search_sch_from')?.focus()]);
            return false;
        }

        if( this.end == null ) {
            this.hodu_show_dialog('alert', "날짜를 입력해주세요", ['확인'], [() => document.getElementById('work_search_sch_to')?.focus()]);
            return false;
        }

        this.title = this.title.trim();
        this.content = this.content.trim();
        this.writer = this.writer.trim();

        return true;
    }

    /**
     * 업무 일지 읽기 권한 체크
     */
    getReportReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "report", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "report", "read");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 회의록 읽기 권한 체크
     */
    getMeetingLogReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "meetinglog", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "meetinglog", "read");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

}
</script>

<style scoped>
    .left_area_close .content { padding-left: 60px !important; }
    .document_box { padding-left: 20px; padding-right: 40px; width: 900px; max-width: 100%; position: relative; padding-top: 10px; overflow: hidden; box-sizing: border-box; }
    .document_box ul { box-sizing: border-box; }
    .document_box ul li { position: relative; line-height: 55px; min-height: 55px; }
    .document_box ul li.docuBox_last { margin-bottom: 10px; }
    .document_box dl dt { position: absolute; left: 0; top: 0; display: inline-block; width: 100px; font-size: 13px; font-weight: 700; }
    .document_box dl dt label { width: 100%; display: inline-block; padding-left: 15px; }
    .document_box dl dt .title_pointer { display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: #477fff; margin-right: 10px; }
    .document_box dl dd { display: inline-block; width: 100%; -webkit-box-sizing: border-box; box-sizing: border-box; border-bottom: 1px solid #f3f3f3; margin-left: 110px; }
    .document_box dl:hover dd:after { width: 100%; -webkit-transition: .2s; transition: .2s; }
    .document_box dl dd:after { content: ""; position: absolute; display: block; width: 0; height: 2px; background: #d2d7e2; bottom: -1px; z-index: 1; left: 0; margin-left: 110px; -webkit-transition: .2s; transition: .2s; }
    .document_box dl dd div { display: inline-block; }
    .document_box dl dd input { font-size: 13px; font-weight: 700; }
    .document_box dl dd label { display: inline-block; }
    .document_box input[type=radio]:not(old)+label { height: 52px; line-height: 52px; background-position: 4px; margin-right: 30px; }
    .document_box dl dd input[type=text]#sch_title { font-size: 14px; width: 98%; }

    .document_box .document_type_div { width: 100% !important; height : 100%; position: relative; margin-bottom: 15px; }
    .document_box .document_type_div .document_fragment { margin-top: 20px; margin-left: 25px; width: 80px; height: 150px; line-height: initial; }
    .document_box .document_type_div .document_fragment:first-child { margin-left: 0; } 
    .document_box .document_type_div .document_fragment > img { width: 80px; height: 113.12px; opacity: 0.3; cursor: pointer; border: 1px solid #f1f3f5; }
    .document_box .document_type_div .document_fragment > img:hover { opacity: 1; }
    .document_box .document_type_div .document_fragment.on > img { opacity: 1; border: 1px solid #477fff; }
    .document_box .document_type_div .document_fragment.daily_report_1 > img { background-image: url("../../assets/images/contents/ic_report_1.png"); background-size: cover; }
    .document_box .document_type_div .document_fragment.daily_report_2 > img { background-image: url("../../assets/images/contents/ic_report_2.png"); background-size: cover; }
    .document_box .document_type_div .document_fragment.weekly_report_1 > img { background-image: url("../../assets/images/contents/ic_report_3.png"); background-size: cover; }
    .document_box .document_type_div .document_fragment.weekly_report_2 > img { background-image: url("../../assets/images/contents/ic_report_4.png"); background-size: cover; }
    .document_box .document_type_div .document_fragment.meeting_log_1 > img { background-image: url("../../assets/images/contents/ic_meeting_log_1.png"); background-size: cover; }
    .document_box .document_type_div .document_fragment.meeting_log_2 > img { background-image: url("../../assets/images/contents/ic_meeting_log_2.png"); background-size: cover; }
    .document_box .document_type_div .document_fragment > span { width: 80px; text-align: center; display: inline-block; }
    .document_box .document_type_div .document_fragment > input.preview { width: 60px; height: 25px; margin: 0 10px; padding: 0 7px; font-size: 12px; text-align: center; border-radius: 3px; margin-top: 5px; background-color: #f1f3f5; box-sizing: border-box; font-weight: normal; }
    .document_box .document_type_div .document_fragment > input.preview:hover { font-weight: bold; }

    .document_box .schDateList { position: relative; line-height: 55px; min-height: 55px; }
    .document_box .schDateList dl dd .ware_seper { position: absolute; left: 23%; margin-left: 100px; opacity: .5; top: 0; font-weight: 400; width: 50px; font-size: 14px; text-align: center; }
    .document_box .schDateList dl dd input[type=button] { text-align: left; color: #434343; font-weight: 700; padding-left: 0; }
    .document_box .schDateList dl dd input[type='button']:focus{ border-color: #477fff; }
    .document_box .schDateList dl dd input[type='button']:hover { color:#477fff; }
    .document_box .schDateList dl dd input.wd45 { width: 25%!important; height: 55px; line-height: 55px; margin-right: 12%; }
    .document_box .schDateList dl:hover dd:after { width: 100%; -webkit-transition: .2s; transition: .2s; }
    .document_box .schDateList dl dd:after { content: ""; position: absolute; display: block; width: 0; height: 2px; background: #477fff; bottom: -1px; z-index: 1; left: 0; margin-left: 110px; -webkit-transition: .2s; transition: .2s; }

    .document_box .sch_clr_li .sch_clr_div { position: relative; }
    .document_box .sch_clr_li .sch_clr_div li { display: inline-block; font-size: 0; height: 55px!important; line-height: 55px; }
    .document_box .sch_clr_li .sch_clr_div li:first-child { margin-left: 55px; }
    .document_box .sch_clr_li .sch_clr_div li label { -webkit-transition: .1s; transition: .1s; cursor: pointer; float: left; width: 22px; height: 22px; -webkit-box-sizing: border-box; box-sizing: border-box; background-position: -1px -1px!important; display: inline-block; border-radius: 50%; margin-top: 15px; margin-right: 13px; background-image: url("../../assets/images/ware/checked_fff.png"); }
    .document_box .sch_clr_li .sch_clr_div li label:hover { transform: scale(1.12); }
    .document_box .sch_clr_li .sch_clr_div input[type=checkbox]:not(old)+label { background-image: none; }
    .document_box .sch_clr_li .sch_clr_div input[type='checkbox']:not(old):checked + label {background-image: url("../../assets/images/ware/checked_fff.png"); }

    .document_box .sch_clr_li .sch_clr_div .selectAllDiv { display: block!important; position: absolute; z-index: 9999; width: 40px!important; top: 22px; height: 20px; left: 0; background: #fff; -webkit-box-sizing: border-box; box-sizing: border-box; border-right: 1px solid #e3e7ed; }
    .document_box .sch_clr_li .sch_clr_div .selectAllDiv p { position: relative; }
    .document_box .sch_clr_li .sch_clr_div .selectAllDiv input[type='checkbox']:not(old)+label { background-color: #c1cfd8!important; background-image: url("../../assets/images/ware/checked_fff.png"); background-position: 50%!important; padding: 0; width: 20px; height: 20px; border-radius: 3px; background-size: 23px!important; background-position: -1px -1px!important; }
    .document_box .sch_clr_li .sch_clr_div .selectAllDiv input[type='checkbox']:not(old):checked + label {background-color:#477fff !important;}
    .document_box .sch_clr_li .sch_clr_div .selectAllDiv label span { display: none; position: absolute; width: 200px; left: 48px; top: 13px; font-size: 12px; }

    .save_last_section { margin-top: 30px; width: 900px; max-width: 100%; text-align: center; }
    .save_last_section input[type="button"] { padding: 15px 50px; margin-left: 45px; border-radius: 5px; transition: .2s; }
    .save_last_section input[type="button"]:hover { background: #477fff; color: #fff; font-weight: bold; }
</style>