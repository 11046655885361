<template>
    <!-- id 변경 settingDiv?  -->
    <div class="notice setting" id="preferencesDiv">
        <div class="over_hidden noti_titlebox">
            <span class="bar"></span>
            <!-- 팀 설정, 그룹 설정, 환경설정(개인) -->
            <h3 class="tit_noti">환경설정</h3>
        </div>
        <div id="settingWrap" class="notiWrap settingWrap">
            <ul class="preList">
                <!-- 시간표기 옵션은 그룹에 들어가면 숨겨주세요 -->
                <li v-if="scope_group_id < 1">
                    <a href="#" @click.prevent="() => {}" style="cursor : default;">일정 시간표기<span>*시간표기 설정은 Web에서만 적용됩니다.</span></a>
                    <ul class="preUl on calTimeUl">
                        <li>
                            <!-- 체크시 해당 이미지 opacity 1로 변경 -->
                            <p>
                                <input type="radio" id="calTime_check01" name="input_radio_check" class="input_check0" value="NONE" :checked="schedule_time_type == 'NONE'" @change="changeScheduleTimeType($event.target.value)">
                                <label for="calTime_check01"></label>
                                <span>표시안함</span>
                                <i class="calTime_none_img" :class="{ on : schedule_time_type == 'NONE' }">이미지</i>
                            </p>
                            <p>
                                <input type="radio" id="calTime_check02" name="input_radio_check" class="input_check0" value="START" :checked="schedule_time_type == 'START'" @change="changeScheduleTimeType($event.target.value)">
                                <label for="calTime_check02"></label>
                                <span>왼쪽</span>
                                <i class="calTime_left_img" :class="{ on : schedule_time_type == 'START' }">이미지</i>
                            </p>
                            <p>
                                <input type="radio" id="calTime_check03" name="input_radio_check" class="input_check0" value="END" :checked="schedule_time_type == 'END'" @change="changeScheduleTimeType($event.target.value)">
                                <label for="calTime_check03"></label>
                                <span>오른쪽</span>
                                <i class="calTime_right_img" :class="{ on : schedule_time_type == 'END' }">이미지</i>
                            </p>
                        </li>
                    </ul>
                </li>

                <li v-if="scope_group_id > 0">
                    <a class="on">프리미엄 사용 유무(그룹, 팀 동일)</a>
                    <ul class="preUl on">
                        
                        <li v-if="isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') || isHoduH(scope_group_team_option ? scope_group_team_option.biz_type : '')">
                            <p class=""><span class="dot"></span>메세지</p>
                            <label class="switch">
                                <input type="checkbox" @change="premiumFeaturesOnOff($event, 'message')" :checked="messageChecked()">
                                <span class="slider round"></span>
                            </label>
                        </li>

                        <li v-if="isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '')">
                            <p class=""><span class="dot"></span>업무 (업무일지, 회의록, 프로젝트)</p>
                            <label class="switch">
                                <input type="checkbox" @change="premiumFeaturesOnOff($event, 'work')" :checked="workChecked()">
                                <span class="slider round"></span>
                            </label>
                        </li>

                        <li v-if="isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '')">
                            <p class=""><span class="dot"></span>파일함</p>
                            <label class="switch">
                                <input type="checkbox" @change="premiumFeaturesOnOff($event, 'filebox')" :checked="fileboxChecked()">
                                <span class="slider round"></span>
                            </label>
                        </li>

                        <!-- <li v-if="isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '')">
                            <p class=""><span class="dot"></span>전자결재</p>
                            <label class="switch">
                                <input type="checkbox" @change="premiumFeaturesOnOff($event, 'approval')" :checked="approvalChecked()">
                                <span class="slider round"></span>
                            </label>
                        </li>

                        <li v-if="isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '')">
                            <p class=""><span class="dot"></span>근태관리</p>
                            <label class="switch">
                                <input type="checkbox" @change="premiumFeaturesOnOff($event, 'attendance')" :checked="attendanceChecked()">
                                <span class="slider round"></span>
                            </label>
                        </li> -->

                    </ul>
                </li>

                <li v-if="scope_group_id > 0">
                    <a class="on">그룹 설정</a>

                    <ul class="preUl on">

                        <li>
                            <p class=""><span class="dot"></span>그룹 수정</p>
<!-- 그룹수정 화면으로 이동 -->
                            <a href="#" id="addGrp" class="btMore" title="그룹 수정 바로가기" @click.prevent="groupModify">그룹수정 바로가기</a>
                        </li>
                        
                        <li>
                            <p class=""><span class="dot"></span>공지사항 댓글</p>
                            <label class="switch">
                                <input type="checkbox" @change="noticeReplyOnOff($event.target.checked)" :checked="noticeReplyChecked()">
                                <span class="slider round"></span>
                            </label>
                        </li>

                        <li class="twoLinesLi">
                            <p class=""><span class="dot"></span>하위 그룹 생성 / 삭제 시 동기화</p>
                            <span class="expnText">달력, 조직도가 상시 동기화 됩니다</span>
                            <label class="switch">
                                <input type="checkbox" @change="teamSyncOnOff($event.target.checked)" :checked="teamSyncChecked()">
                                <span class="slider round"></span>
                            </label>
                        </li>

                    </ul>
                </li>


                <li v-if="scope_group_id > 0">
                    <a href="#" @click.prevent="eventExport">일정 내보내기 <span class="schedule_export">내보내기</span></a>
                </li>

            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { EventExportModalInfo } from '@/store/modules/ModalInfo';

@Component({

})
export default class RightPreferences extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventExportModalInfo ?: (params : EventExportModalInfo) => void;

    async mounted() : Promise<void> { 

        // 개인 달력 환경설정 정보 갱신
        if( this.scope_group_id < 1 ) {
            const calendar_preference = await this.get_user_preference("calendar", false, true);

            if( calendar_preference == null || calendar_preference.preference == null || calendar_preference.preference.schedule_time_type == null ) {
                this.doSetScheduleTimeType?.("NONE");
            }
            else {
                this.doSetScheduleTimeType?.(calendar_preference.preference.schedule_time_type);
            }
        }

    }

    /**
     * 일정 시간 표기 변경
     */
    async changeScheduleTimeType(value : string) {

        try {
            const calendar_preference = await this.get_user_preference("calendar", false, true);

            let body : any = {};

            if( calendar_preference == null || calendar_preference.preference == null ) {
                body = { "schedule_time_type" : value };
            }
            else {
                body = calendar_preference.preference;
                body.schedule_time_type = value;
            }
            
            await this.set_user_preference("calendar", body, true);

            // 캘린더 환경설정 리셋
            await this.calendar_preference_sync();

        } catch(e) {
            alert("일정 시간 표기 변경 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 프리미엄 기능 ON / OFF
     */
    premiumFeaturesOnOff(event : any, target : string) : void {
        let featureOnOff : boolean = event.target.checked;

        // 그룹 기능 변경하는 API 호출 [(groups.ts : groupFeatureChange) - ( '/groups' : '/:group_id/features/:feature' )]
        this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/${target}?feature_flag=${featureOnOff}`, API_METHOD.PUT)
            .then((response) => {
                this.get_group_role_service();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 메세지 ON / OFF 여부
     */
    messageChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'message');
    }

    /**
     * 업무 ON / OFF 여부
     */
    workChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'work');
    }

    /**
     * 파일함 ON / OFF 여부
     */
    fileboxChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'filebox');
    }

    /**
     * 전자결재 ON / OFF 여부
     */
    approvalChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'approval');
    }

    /**
     * 근태관리 ON / OFF 여부
     */
    attendanceChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'attendance');
    }

    /**
     * 공지사항 댓글 ON / OFF
     */
    async noticeReplyOnOff(value) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/notice?key=enable_group_comment&feature_flag=${value}`, API_METHOD.PUT);

            console.log(response);

            if( !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("그룹 기능 변경 중 오류 발생");
            }
            
            this.get_group_role_service();

        } catch(e) {
            alert("그룹 기능 변경 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 공지사항 댓글 기능
     */
    noticeReplyChecked() : boolean {

        const feature = this.get_group_feature(this.scope_group_id);

        if( feature == null || feature.notice == null || feature.notice.enable_group_comment == null ) return false;

        return feature.notice.enable_group_comment;
    }

    /**
     * 팀&부서 동기화 ON / OFF
     */
     async teamSyncOnOff(value) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/team_sync?key=enable&feature_flag=${value}`, API_METHOD.PUT);

            console.log(response);

            if( !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("그룹 기능 변경 중 오류 발생");
            }
            
            this.get_group_role_service();

        } catch(e) {
            alert("그룹 기능 변경 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 팀&부서 동기화 체크
     */
    teamSyncChecked() : boolean {
        const feature = this.get_group_feature(this.scope_group_id);

        if( feature == null || feature.team_sync == null || feature.team_sync.enable == null ) return false;

        return feature.team_sync.enable;
    }

    /**
     * 일정 내보내기
     */
    eventExport() {
        this.doSetEventExportModalInfo?.({
            show_modal : true
        });
    }

    groupModify() {
        this.doGroupId(this.scope_group_id);
        this.hodu_router_push(`/group/${new Date().getTime()}/create`);
    }

}

</script>

<style scoped>
    .notiWrap .tabs a { position: relative; width:25%; }
    .tab_txt { padding-left: 5px; }
    .tabs a span.new { position:absolute; top:15px;right:15px; display:inline-block; width:7px; height: 7px; font-size:0;background:#ff6060; border-radius:50%;  }
    .notiWrap .noResult { position: static; margin-top:30px; }
    .notiWrap .noResult p { background:#f1f3f5; line-height: 60px; height:60px; font-size:13px;color:#8b8e92 }

    .setting .settingWrap > ul ul.preUl { display:none; }
    .setting .settingWrap > ul ul.preUl.on { display:block; }
    .setting .settingWrap > ul > li {box-sizing:border-box;  border-bottom:1px solid #f1f3f5; } 
    .setting .settingWrap > ul > li a { box-sizing: border-box;line-height:55px; display:block; width:100%; height:55px; font-size: 14px; font-weight:bold; padding-left: 20px;  /* background-image:  url('../../assets/images/side_area/bt_close.png'); */ background-repeat:no-repeat; background-position: 96% center;  }
    .setting .settingWrap > ul > li li {overflow:hidden;box-sizing:border-box; line-height: 50px;height:50px; padding-left: 25px;border-top:1px solid #f1f3f5; position: relative; }
    .setting .settingWrap > ul > li a:hover { background-color:#f1f3f5; }
    .setting .settingWrap > ul > li li p { display: inline-block; font-weight: bold;font-size:13px; }
    .setting .settingWrap > ul > li li .switch { float:right;margin-right:20px; margin-top:12px; }
    .setting .settingWrap > ul > li li:hover .dot { background:#232848 }
    .setting .settingWrap > ul > li li .dot { display:block; float:left; width: 5px; height:5px; border-radius: 50%; background: #aeb7c5;margin: 23px 10px 0 0; }

    .setting_clr_ul.on { display:block; }
    .setting_clr_ul { display:none; position:absolute; top:55px; left:0; box-shadow: 0 5px 10px rgba(0,0,0,0.05)}
    .setting .settingWrap li.settingClrList label { transition:0.1s; cursor: pointer;width: 27px;height: 27px;font-size:0;box-sizing: border-box;background-position: 1px 2px !important; display: inline-block; border-radius: 50%;margin-top: 13px;margin-right: 13px;background-image: url(../../assets/images/contents/checked_fff.png);   }
    .setting .settingWrap li.settingClrList label:hover { transform: scale(1.12) }
    .setting .settingWrap li.settingClrList input[type='checkbox']:not(old) + label {margin-right:0;  background-image: none;}
    .setting .settingWrap li.settingClrList input[type='checkbox']:not(old):checked + label {background-image: url(../../assets/images/contents/checked_fff.png); transform:scale(1.15) }
    .setting .settingWrap li.settingClrList li:last-child label,.setting .settingWrap li.settingClrList li:last-child { margin-right:0; }
    .setting .settingWrap li.settingClrList ul  { border-top:1px solid #f1f3f5; overflow:hidden; width:100%; text-align:center; padding: 10px 20px 20px;box-sizing: border-box; }
    .setting .settingWrap li.settingClrList ul li { display:inline-block;border-top:0 none; padding-left:0;width: 45px; text-align:center; }
    .setting .settingWrap li.settingClrList .setting_txt { line-height: 55px; height:55px; padding: 0 20px;font-size:14px; font-weight:bold;}
    .setting .settingWrap li.settingClrList  { position:relative;  }
    .setting .settingWrap li.settingClrList .setting_clr {position:absolute;top:16px; right:23px; width:23px; height:23px;border-radius:50%; font-size: 0; transition:0.1s;}
    .setting .settingWrap li.settingClrList .setting_clr:hover { transform:scale(1.15) }

    /* 시간표기 설정 */
    .setting .settingWrap > ul > li a span { font-size: 12px; margin-left: 10px; opacity: .7; font-weight: normal; }
    .setting .settingWrap > ul > li .calTimeUl li { height: auto; line-height: normal; }
    .settingWrap .calTimeUl p { width: 161px; vertical-align: top; }
    .settingWrap .calTimeUl p span { line-height: 50px; }
    .settingWrap input[type=radio]:not(old)+label { display: inline-block; padding: 0; width: 14px; height: 14px; border-radius: 3px; border: 2px solid rgb(193, 201, 212); background: rgb(193, 201, 212) url(/img/checked_fff.3f121842.png) no-repeat center; background-size: 17px; margin-right: 10px; margin-top: 16px; }
    .settingWrap input[type=radio]:not(old):checked+label { background-color: #477fff !important; border-color: #477fff !important; }
    .settingWrap input[type=radio]:checked + i { opacity: 1; }
    .settingWrap .calTimeUl p i { display: block; width: 130px; height: 110px; border-radius: 5px; border: 1px solid #c1cfd8; font-size: 0; margin-bottom: 15px; box-sizing: border-box; background-repeat: no-repeat; background-position: center; opacity: .6; transition: .2s; }
    .settingWrap .calTimeUl p i:hover { opacity: 1; }
    .settingWrap .calTimeUl p i.calTime_none_img { background-image: url('../../assets/images/contents/img_calTime_none.png'); }
    .settingWrap .calTimeUl p i.calTime_left_img { background-image: url('../../assets/images/contents/img_calTime_left.png'); }
    .settingWrap .calTimeUl p i.calTime_right_img { background-image: url('../../assets/images/contents/img_calTime_right.png'); }

    .settingWrap .calTimeUl p i.on { opacity: 1; }

    /* 일정 내보내기 */
    .setting .settingWrap > ul > li a .schedule_export { float: right; padding-right: 20px; }
    .setting .settingWrap > ul > li a:hover .schedule_export { color: #477fff; opacity: 1; font-weight: bold; }

    /* 하위 그룹 동기화 */
    .setting .settingWrap > ul > li li.twoLinesLi { height: 75px; }
    .setting .settingWrap > ul > li li.twoLinesLi .expnText { position: absolute; left: 40px; bottom: 0px; }
</style>