<template>
    <div id="organization_emp_personal_record_detail_modal" class="modal">

        <div class="content">
            <div class="hr_detail_modal" v-if="record != null">
                <p class="hr_modal_img" :style="{ 'background-image' : record.profile_image_url != null && record.profile_image_url.length > 0 ? `url(app_images/${record.profile_image_url})` : `url(${default_user_image})`  }"></p>
                <img class="dummy" :src="record.profile_image_url != null && record.profile_image_url.length > 0 ? `app_images/${record.profile_image_url}` : default_user_image" @error="userImageError($event)" />
                <p class="name">{{ record.name }}</p>
                <p class="pos">{{ record.pos_name }}</p>
                <p class="team">{{ record.dept_name }}</p>
                <p class="email">{{ record.email }}</p>
                <p class="tel">{{ record.tel }}</p>
                <!-- <p class="hr_modal_img"></p>
                <p class="name">이호두</p>
                <p class="pos">대리</p>
                <p class="team">디자인팀</p>
                <p class="email">hoduwareco@gmail.com</p>
                <p class="tel">010-1234-5678</p> -->
            </div>
        </div>

        <div class="btns">
            <input type="button" value="닫기" @click.prevent="close">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

@Component({
    components: {
        
    },
})
export default class OrganizationEmpPersonalRecordDetailModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_emp_personal_record_detail_modal_info !: organization_modal_interface.OrganizationEmpPersonalRecordDetailModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordDetailModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordDetailModalInfo) => void;

    record : any = null;
    default_user_image : string = require('@/assets/images/contents/ic_approval_user_on.png');

    mounted() : void {

        if( this.organization_emp_personal_record_detail_modal_info.group_id < 1 || this.organization_emp_personal_record_detail_modal_info.user_id < 1 ) {
            this.close();
        }
        

        this.getPersonalRecord();
    }

    async getPersonalRecord() {

        try {

            const group_id = this.organization_emp_personal_record_detail_modal_info.group_id;
            const user_id = this.organization_emp_personal_record_detail_modal_info.user_id;

            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}?is_not_allow=true`, API_METHOD.GET);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.record ) {
                throw new Error("인사정보 조회중 오류발생");
            }

            this.record = JSON.parse(JSON.stringify(response.data.data.record));

        } catch(e) {
            this.close();
        }

    }

    userImageError(event) : void {
        $(event.target).parent().find('p.hr_modal_img').css('background-image', require('@/assets/images/contents/ic_approval_user_on.png'))
    }

    close() : void {
        this.doSetOrganizationEmpPersonalRecordDetailModalInfo?.({
            show_modal : false,
            group_id : 0,
            user_id : 0
        });
    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-300px; margin-left:-200px; width:100%; border-radius:5px; max-width: 400px;  height:auto; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }

    .hr_detail_modal { overflow: hidden; text-align: center; padding: 50px 40px; box-sizing: border-box; }
    .hr_modal_img { width: 120px; height: 120px; background: url('../../../assets/images/contents/ic_approval_user_on.png') no-repeat; background-size: cover; border-radius: 50%; margin: 0 auto; box-shadow: 0 5px 10px rgba(0,0,0,0.1); border: 1px solid #fff; }
    .hr_detail_modal p { line-height: 20px; font-size: 14px; }
    .hr_detail_modal p.name { width: 100%; font-size: 18px; font-weight: bold; line-height: 60px; margin-top: 10px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .hr_detail_modal p.team { padding-bottom: 30px; border-bottom: 2px solid #e7e9ea; }
    .hr_detail_modal p.email { padding-top: 30px; font-size: 15px; line-height: 30px; }
    .hr_detail_modal .tel { font-size: 15px; }

    .btns { overflow: hidden; border-top:1px solid #e7eae7;border-radius: 0 0 5px 5px }
    .btns input { line-height:75px; height: 75px; background:#fff; width:100%; float: left; font-size:14px; font-weight: bold; }
    .btns input:hover { background:#f1f3f5; }
</style>