<template>
    <div id="resident_move_into_request_setting" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <div class="title_box">
            <a href="#" class="bt_back"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">입주 예약 설정</h3>
        </div>

        <div class="section_scroll">
            <div class="moveinSettingBox">
                <div class="titleDiv">

                    <!-- active -->
                    <h2 class="title" :class="{ active : category == 'TIME' }">
                        <a href="#" class="tit_tab" @click.prevent="category = 'TIME'">예약 시간 설정</a>
                    </h2>

                    <h2 class="title" :class="{ active : category == 'LINE' }">
                        <a href="#" class="tit_tab" @click.prevent="category = 'LINE'">라인 설정</a>
                    </h2>

                </div>
                <div class="settingCon">
                    
                    <ResidentMoveIntoRequestTimeSetting v-if="category == 'TIME'" />
                    <ResidentMoveIntoRequestLineSetting v-if="category == 'LINE'" />

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import ResidentMoveIntoRequestTimeSetting from '@/components/hodu_h/ResidentMoveIntoRequestTimeSetting.vue';
import ResidentMoveIntoRequestLineSetting from '@/components/hodu_h/ResidentMoveIntoRequestLineSetting.vue';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResidentMoveIntoRequestTimeSetting, ResidentMoveIntoRequestLineSetting, ResizeObserver
    },
})
export default class ResidentMoveIntoRequestSetting extends Mixins(VueHoduCommon) {

    category : string = 'TIME';

    mounted() : void {
        //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.settingCon > div').hide();
        // 	$('.settingCon > div').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });
        
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('.title_box').outerHeight();
        const titleDivHeight : number | undefined = $('.titleDiv').outerHeight();

        // @ts-ignore
        $('#request_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (titleDivHeight ? titleDivHeight : 0),
        });
    }

    /**
     * 리사이즈 감지
     */
    @Watch("category")
    handleResize() : void {
        this.$nextTick(() => {
            // @ts-ignore
            $("#request_setting_scroll").mCustomScrollbar('destroy');

            this.setScroll();
        });
    }

}
</script>

<style scoped>
    /* 탭 타이틀 */
    .titleDiv { width: 100%; line-height: 60px; height: 60px; -webkit-box-sizing: border-box; box-sizing: border-box; border-bottom: 1px solid #e7e9ea; overflow: hidden; padding: 0 25px; }
    .titleDiv .title { font-weight: bold; font-size: 14px; vertical-align: middle; }
    .titleDiv * { display: inline-block; }
    .titleDiv .tit_tab { width: 125px; height: 35px; line-height: 35px; display: block; padding: 0 15px; text-align: center; }
    .titleDiv .active .tit_tab { width: 125px; height: 35px; line-height: 35px; display: block; border-radius: 30px; background: #2b3b5f; padding: 0 15px; text-align: center; color: #fff; }

    .moveinSettingBox .settingCon { position: relative; box-sizing: border-box; min-height: 795px; overflow-y: auto; }
    .moveinSettingBox .settingCon h2 { font-size: 15px; padding: 0 15px; }
    .moveinSettingBox .settingCon ul { overflow: hidden; }
    .moveinSettingBox .settingCon ul li { width: 100%; line-height: 60px; border: 1px solid #f1f3f5; border-radius: 10px; padding: 0 15px; margin-top: 20px; box-sizing: border-box; font-weight: bold; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; position: relative; }
    .moveinSettingBox .settingCon ul li p { width: 10%; position: relative; display: inline-block; color: #4f536d; }
    .moveinSettingBox .settingCon ul li p:before { content: ''; width: 2px; height: 30px; background: #a7a9b6; position: absolute; top: 16px; right: 0; }
    .moveinSettingBox .settingCon ul li .selBox { width: auto; display: inline-block; padding: 0 35px; }
    .moveinSettingBox .settingCon ul li .selBox select { font-weight: bold; transition: 0.2s; -webkit-appearance: none; -moz-appearance: none; appearance: none; border-radius: 5px; height: 30px; line-height: 30px; cursor: pointer; background-color: #fff; background-position: 95% center; background-repeat: no-repeat; box-sizing: border-box; border: 1px solid #f1f3f5; padding: 0 50px 0 10px; background-image: url('../../assets/images/side_area/bt_open.png'); margin-bottom: 3px; margin-left: 10px; } 
    .moveinSettingBox .settingCon ul li .selBox select:hover { background-color: #f1f3f5; }
    .moveinSettingBox .settingCon ul li.addTimeSel { position: relative; text-align: center; }
    .moveinSettingBox .settingCon ul li.addTimeSel:hover { background: #f1f3f5; }
    .moveinSettingBox .settingCon ul li.addTimeSel p { width: 14px; height: 14px; font-size: 0; background: url('../../assets/images/contents/ic_plus_new.png')no-repeat center; position: absolute; top: 23px; left: 50%; margin-left: -50px; opacity: .7; }
    .moveinSettingBox .settingCon ul li.addTimeSel p:before { display: none; }
    .moveinSettingBox .settingCon .addTimeBtn { cursor: pointer; margin: 0; padding: 0; width: 100%; display: block; padding: 20px 0; background: none; font-weight: bold; color: #9596a1; text-indent: 26px; transition: .2s; }

    .moveinSettingBox .settingCon .reservation_line .reLineSet { overflow: hidden; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet select { height: 40px; line-height: 40px; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn .copySetBtn { display: none; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn.readyCopySet .copySetBtn { display: inline-block; margin-right: 20px; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn .tippingSetBtn { display: none; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn.tipping .tippingSetBtn { display: inline-block; margin-right: 20px; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox { display: inline-block; width: 40%; box-sizing: border-box; padding: 0 35px; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox.lineTextBox { border-right: 1px solid #f1f3f5; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox input[type=text] { width: 100%; padding: 5px; border-radius: 5px; border: 1px solid #f1f3f5; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox input#builtext { width: 40px; text-align: center; margin: 0 15px; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox p:before { display: none; }

    .moveinSettingBox .settingCon ul li a { padding: 0 15px; background: #ff6363; color: #fff; border-radius: 15px; position: absolute; top: 50%; margin-top: -13px; right: 80px; height: 25px; line-height: 25px; opacity: 0; }
    .moveinSettingBox .settingCon .reservation_line a.amendBtn { background: #477fff; right: 20px; }
    .moveinSettingBox .settingCon ul li:hover a { opacity: 1; }
    .moveinSettingBox .settingCon ul li:hover a:hover { background: #ff3a00; }
    .moveinSettingBox .settingCon ul li:hover a.amendBtn:hover { background: #004bff; }

    /* .modal.tipping_set .main .icon { background-image: url('../../assets/images/contents/img_tipping.png'); } */

    .reservation_setting { margin : 20px 30px; }
    .input_btn.saveSetBtn:disabled { cursor: default; }
</style>