<template>
    <div class="voteModal remodal modal">
        
        <!-- 상단 영역 시작 -->
        <div class="titleBox">
            <h1 class="">투표/설문 등록</h1>
            <input type="button" class="reset" title="재작성" value="재작성" @click="voteDataReset" />
        </div>
        <!-- 상단 영역 끝 -->

        <!-- 투표 작성 내용 영역 시작 -->
        <div class="remodal-content  remodal-content03 remodal-content04">
            <div id="" class=""><!-- height:464px이상 스크롤 -->
            
                <div class="regisVoteWrap" id="voteWrap_1">
                    <ul>
                        <li class="clearFix setting">
                            <label for="" class="fl label">종료일</label>
                            
                            <div class="datepickerWrap fl ">
                                <input type="text" id="voteEndDate_1" class="input_txt" style="width:128px" :value="vote_end_date_text" readonly="readonly">
                                <span>
                                    <img src="@/assets/images/contents/ic_calendar.png" border="0" alt="">
                                </span>
                            </div>
                            
                            <div id="voteEndHourDiv" class="selectboxWrap">

                                <input type="button" id="endTime" :value="vote_end_time_text" class="input_txt" @click="timePickerToggle">

                                <div class="chooseTime from" :class="{ on : time_picker_open == true }">
                                    
                                    <div class="selectboxWrap">
                                        <label for="s_voteEndAmPm" class="">{{ vote_end_am_pm == "AM" ? "오전" : "오후" }}</label> 
                                        <select id="s_voteEndAmPm" title="오전 저녁 선택" @change="selectVoteEndTimeAmPm($event)">
                                            <option :selected="vote_end_am_pm == 'AM'" value="AM">오전</option>
                                            <option :selected="vote_end_am_pm == 'PM'" value="PM">오후</option>
                                        </select>
                                    </div>

                                    <div class="selectboxWrap">
                                        <label for="s_voteEndHour" class="">{{ vote_end_hour }}</label> 
                                        <select id="s_voteEndHour" title="시간선택" @change="selectVoteEndTimeHour($event)">
                                            <option value="12" :selected="vote_end_hour == '12'">12</option>
                                            <option value="01" :selected="vote_end_hour == '01'">01</option>
                                            <option value="02" :selected="vote_end_hour == '02'">02</option>
                                            <option value="03" :selected="vote_end_hour == '03'">03</option>
                                            <option value="04" :selected="vote_end_hour == '04'">04</option>
                                            <option value="05" :selected="vote_end_hour == '05'">05</option>
                                            <option value="06" :selected="vote_end_hour == '06'">06</option>
                                            <option value="07" :selected="vote_end_hour == '07'">07</option>
                                            <option value="08" :selected="vote_end_hour == '08'">08</option>
                                            <option value="09" :selected="vote_end_hour == '09'">09</option>
                                            <option value="10" :selected="vote_end_hour == '10'">10</option>
                                            <option value="11" :selected="vote_end_hour == '11'">11</option>
                                        </select>
                                    </div>

                                    <div class="selectboxWrap">
                                        <label for="s_voteEndMin" class="">{{ vote_end_min }}</label> 
                                        <select id="s_voteEndMin" title="분 선택" @change="selectVoteEndTimeMin($event)"> 
                                            <option value="00" :selected="vote_end_min == '00'">00</option>
                                            <option value="10" :selected="vote_end_min == '10'">10</option>
                                            <option value="20" :selected="vote_end_min == '20'">20</option>
                                            <option value="30" :selected="vote_end_min == '30'">30</option>
                                            <option value="40" :selected="vote_end_min == '40'">40</option>
                                            <option value="50" :selected="vote_end_min == '50'">50</option>
                                        </select>
                                    </div>
                                    
                                </div>

                            </div>
                            
                        </li>

                        <li class="title" :class="{ on : vote.title.length > 0 }">
                            <div class="clearFix">
                                <input type="text" id="voteTitle" class="input_txt mt02" placeholder="투표/설문 제목을 입력해 주세요" 
                                       :value="vote.title" @input="vote.title = $event.target.value">
                                <a href="#" id="voteTitleDel" class="regiVote_delete" @click.prevent="voteTitleRemove">삭제</a>
                            </div>
                        </li>
                        
                        <li class="pb0">
                            <div class="clearFix">
                                <div class="round_radiobox fl pl0">
                                    <!-- <input type="radio" id="voteTypeRadio_1_1" name="voteTypeRadio1" class="radio_round" value="1"/>
                                    <label for="voteTypeRadio_1_1" class="">텍스트</label>
                                    <input type="radio" id="voteTypeRadio_1_2" name="voteTypeRadio1" class="radio_round" value="2">
                                    <label for="voteTypeRadio_1_2" class="">날짜</label> -->
                                </div>
                                <p class="fr">
                                    <input type="button" id="" name="voteBtnItemAdd_1" value="항목추가" class="btn btn-md btn-gray" @click="voteItemAdd">
                                </p>
                            </div>

                            <div id="regisVoteScroll" class="voteUlDiv"> 
                                <ul id="voteItemUl_1" class="regisVote_inbox">
                                    
                                    <!-- 작성 중 또는 작성완료시 on추가, on추가시 삭제 버튼 들어감 -->
                                    <li :class="{ on : vote_item_model[index] != null && vote_item_model[index].length > 0 }" :key="index" v-for="(item, index) in vote.items">
                                        <div>
                                            <input type="text" :id="`voteItemTxt${index}`" class="input_txt fl " placeholder="항목입력" 
                                                   :value="vote_item_model[index]" @input="vote_item_model[index] = $event.target.value; vote_item_model.splice(index, 1, vote_item_model[index]);">
                                            <p class="fr">
                                                <a href="#" :id="`voteItemDel${index}`" class="regiVote_delete" @click.prevent="voteContentDelete(index)">삭제</a>
                                            </p>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </li>
                        
                        
                    </ul>
                </div>
                <div class="options">
                    <ul>
                        <!-- 선택시 on추가 -->
                        <li :class="{on : vote.ing_report == true}">
                            <div class="clearFix">
                                <span class="regisVoteSpan">종료전 현황 보이기</span>
                                <p class="fl checkGroup">
                                    <input type="checkbox" id="voteReportYn_1" value="Y" class="input_check" v-model="vote.ing_report">
                                    <label for="voteReportYn_1"></label>
                                </p>
                            </div>
                        </li>
                        
                        <li :class="{on : vote.multiple == true}">
                            <div class="clearFix">
                                <span class="regisVoteSpan">중복 선택 가능</span>
                                <p class="fl checkGroup">
                                    <input type="checkbox" id="voteMultiYn_1" name="" value="Y" class="input_check" v-model="vote.multiple">
                                    <label for="voteMultiYn_1"></label>
                                </p>
                            </div>
                        </li>
                        
                        <li :class="{on : vote.secret == true}">
                            <div class="clearFix">
                                <span class="regisVoteSpan">익명 선택 가능</span>
                                <p class="fl checkGroup">
                                    <input type="checkbox" id="voteSecretYn_1" name="" value="Y" class="input_check" v-model="vote.secret">
                                    <label for="voteSecretYn_1"></label>
                                </p>
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
            
        </div>
        <!-- 투표 작성 내용 영역 끝 -->
        <!-- 디폴트는 저장과 취소, 저장된 투표를 자세히 볼시 btns에 b3추가  -->
        <div class="btns" :class="{ b3 : vote_modify_index != -1 }">
            <input type="button" value="취소" @click="voteModalCancel"/>
            <input type="button" value="삭제" @click="voteDelete" v-if="vote_modify_index != -1"/>
            <input type="button" value="저장" @click="voteSave"/>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

import { t_event_vote } from '@/model/event';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import moment from 'moment';

@Component({

}) export default class EventVoteCreate extends Mixins(VueHoduCommon) {
    
    @EventInfo.State vote                !: t_event_vote
    @EventInfo.State vote_end_date_limit !: Date
    @EventInfo.State vote_modify_index   !: number
    @EventInfo.State event_crud_type     !: string

    @ModalInfo.Action doSetShowEventVoteCreate ?: any;

    vote_item_model : string[] = [];

    time_picker_open : boolean = false;

    vote_end_date_text : string = "";
    vote_end_time_text : string = "";
    vote_end_hour      : string = "";
    vote_end_min       : string = "";
    vote_end_am_pm     : string = ""; 

    mounted() {
        const vue = this;

        // 아이템이 4개가 안된다면 강제로 4개를 맞춘다
        if( this.vote.items.length < 4 ) {
            const repeat_count : number = 4 - this.vote.items.length;
            for( let i = 0; i < repeat_count; i++ ) {
                this.vote.items.push("" as string & Date);
            }
        }

        const item_length : number = this.vote.items.length;
        for( let i = 0; i < item_length; i++ ) {
            this.vote_item_model.push(this.vote.items[i] as string);
        }

        let scrollerOption = {
            "axis" : 'y',
            "setHeight" : 464,
            "scrollbarPosition" : 'outside'
        };

        // @ts-ignore
        $('#regisVoteScroll').mCustomScrollbar(scrollerOption);
        
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const select_date : Date = new Date(dateText); 

                // 선택된 날짜에 시간 적용
                select_date.setHours(new Date(vue.vote.end_date).getHours());
                select_date.setMinutes(new Date(vue.vote.end_date).getMinutes());

                inst.input.val(`${dateText} ${vue.getDayOfWeek(select_date)}요일`);
                vue.vote.end_date = select_date;
                vue.makeDateString();
            }
        };

        // @ts-ignore
        $('#voteEndDate_1').datepicker(option);

        this.vote_end_date_text = `${moment(vue.vote.end_date).format('YYYY.MM.DD')} ${vue.getDayOfWeek(new Date(vue.vote.end_date))}요일`;
        this.makeDateString();
    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * 날짜 텍스트 만들기
     */
    makeDateString() : void {
        const vote_end : Date = new Date(new Date(this.vote.end_date).getTime());

        this.vote_end_date_text  = `${vote_end.getFullYear()}.${`0${vote_end.getMonth() + 1}`.slice(-2)}.${`0${vote_end.getDate()}`.slice(-2)} ${this.getDayOfWeek(vote_end)}요일`;
        this.vote_end_hour       = `0${vote_end.getHours() <= 12 ? ( vote_end.getHours() == 0 ? 12 : vote_end.getHours() ) : vote_end.getHours() - 12}`.slice(-2);
        this.vote_end_min        = `0${vote_end.getMinutes()}`.slice(-2);
        this.vote_end_am_pm      = vote_end.getHours() < 12 ? "AM" : "PM";
        const string_vote_end_am_pm = vote_end.getHours() < 12 ? "오전" : "오후";
        this.vote_end_time_text  = `${string_vote_end_am_pm} ${this.vote_end_hour}:${this.vote_end_min}`;
    }

    // timepicker ON / OFF
    timePickerToggle() : void {
        this.time_picker_open = !this.time_picker_open;
    }

    /**
     * 투표 종료일 시간 변경
     */
    selectVoteEndTimeHour(event) : void {
        const endHours : number = this.vote_end_am_pm == "AM" && Number(event.target.value) == 12 
                                  ? 0 
                                  : (this.vote_end_am_pm == "AM" && Number(event.target.value) != 12) || (this.vote_end_am_pm == "PM" && Number(event.target.value) == 12)
                                    ? Number(event.target.value)
                                    : Number(event.target.value) + 12;

        const end : Date = new Date(this.vote.end_date); 
        end.setHours(endHours);
        
        // 날짜 세팅
        this.vote.end_date= end;
        this.makeDateString();
    }

    /**
     * 투표 종료일 분 변경
     */
    selectVoteEndTimeMin(event) : void {
        const end : Date = new Date(new Date(this.vote.end_date).getTime()); 
        end.setMinutes(Number(event.target.value));

        // 날짜 세팅
        this.vote.end_date = end;
        this.makeDateString();
    }

    /**
     * 투표 종료일 오전 오후 변경
     */
    selectVoteEndTimeAmPm(event) : void {

        // 이미 같은 값이라면 return
        if( this.vote_end_am_pm == event.target.value ){
            return;
        }

        const end : Date = new Date(new Date(this.vote.end_date).getTime()); 

        switch( event.target.value ){
            case "AM":
                end.setHours(end.getHours() - 12);
                break;
            
            case "PM":
                end.setHours(end.getHours() + 12);
                break;
        }

        // 날짜 세팅
        this.vote.end_date = end;
        this.makeDateString();
    }   

    /**
     * 투표 세팅 값 전체 초기화
     */
    voteDataReset() : void {
        this.vote.end_date = new Date(this.vote_end_date_limit.getTime());
        this.vote.title = "";
        
        this.vote_item_model.splice(0, this.vote.items.length);
        this.vote.items.splice(0, this.vote.items.length);

        for( let i = 0; i < 4; i++ ) {
            this.vote_item_model.push("");
            this.vote.items.push("" as string & Date);
        }

        this.vote.ing_report = false;
        this.vote.multiple   = false;
        this.vote.secret     = true;

        this.makeDateString();
    }
    
    /**
     * 투표 제목 제거  
     */    
    voteTitleRemove() : void {
        this.vote.title = "";
    }

    /**
     * 투표 항목 추가
     */
    voteItemAdd() : void {
        if( this.vote.items.length >= 20 ) {
            alert("투표/설문 항목은 20개까지 사용 가능합니다");
            return;
        }

        this.vote.items.push("" as string & Date);
        this.vote_item_model.push("");

        // @ts-ignore
        setTimeout(() => { $('#regisVoteScroll').mCustomScrollbar("scrollTo", "bottom"); }, 75);
    }

    /**
     * 투표 항목 내용 삭제
     */
    voteContentDelete(index : number) {
        this.vote_item_model.splice(index, 1, "");
    }

    /**
     * Modal 닫기
     */
    voteModalCancel() : void {
        this.doSetShowEventVoteCreate(false);
    }

    /**
     * 투표 삭제
     */
    voteDelete() : void {
        window['deleteVote'](this.vote_modify_index);
    }

    /**
     * 투표 저장
     */
    voteSave() : void {
        // 투표 종료 제한 시간(현재 시간) 보다 작은 경우
        if( new Date(this.vote.end_date).getTime() <= new Date().getTime() ) {
            alert("투표/설문 종료일이 현재 시간 보다 빠를 수 없습니다");
            return;
        }

        // 투표 종료 제한 시간(일정 종료일) 보다 큰 경우
        if( new Date(this.vote.end_date).getTime() > this.vote_end_date_limit.getTime() ) {
            alert("투표/설문 종료일이 일정 종료일 보다 늦을 수 없습니다");
            return;
        }

        // 투표 제목 체크
        if( this.vote.title.trim().length < 1 ) {
            alert("투표/설문 제목을 입력 해 주세요");
            return;
        }

        // 투표 아이템 체크
        const item_count       : number   = this.vote_item_model.length;
        let   valid_item_count : number   = 0;
        let   vote_items       : string[] = [];
        for( let i = 0; i < item_count; i++ ) {
            if( this.vote_item_model[i].trim().length > 0 ) {
                valid_item_count++;
                vote_items.push(this.vote_item_model[i].trim());
            } 
        }
        
        // 올바르게 작성 된 항목이 2개 미만일때
        if( valid_item_count < 2 ) {
            alert("투표/설문 항목을 2개 이상 입력 해 주세요");
            return;
        } 

        const vote_data : t_event_vote = JSON.parse(JSON.stringify(this.vote));
        vote_data.items = vote_items;
        
        if( this.vote_modify_index != -1 ) {
            window['updateVote'](this.vote_modify_index, vote_data);
            return;
        }

        window['createVote'](vote_data);
    }

}
</script>

<style scoped>

    .modal { position:absolute; z-index:10000000000000000000000000000000; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}
    
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;padding:0 !important;}
    .modal .btns {border-top:1px solid #eaebec; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;float: left;width: 100%;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; background:#fff; font-weight:bold; }
    .modal .btns input:hover  { background:#f1f3f5; }
    .modal .btns.b3 input { width:33.3333333%; }

    #ui-datepicker-div { margin-top:0 !important; }
    input.reset {width: 35px;height: 35px;font-size: 0;border-radius: 50%;position: absolute;right: 30px;top: 33px;background: #fff url(../../../assets/images/contents/ic_repeat_bk.png) no-repeat center center;}
    input.reset:hover { background-color:#f1f3f5; }
    .voteModal { background:#fff;max-width:750px;height: 569px;margin: 0 auto;left: 0;right: 0;margin-top: -284.5px;top: 50%;}
    .voteModal .remodal-content {  height: 409px !important; }
    .voteUlDiv {height: 220px;max-height: 220px;overflow: hidden;overflow-y: auto;}

    li.setting.clearFix .label { border-right:1px solid #e7e9ea; }
    li.setting.clearFix:hover .label { background:#fff !important }
    li.title { position:relative; }
    .voteModal li.title .regiVote_delete { display:none; font-size:0; width:35px; height:35px;border-radius:50% ; opacity:1; background:url(../../../assets/images/contents/ic_close.gif) no-repeat center center; top:17px; }
    li.title.on .regiVote_delete  { display:block; }
    li.title.on .regiVote_delete:hover { background-color:#f1f3f5; }
    .regisVoteWrap > ul > li.setting > div > .input_txt:hover {background: none;border-bottom: 2px solid #477fff !important;}
    .regisVoteWrap li .regisVote_inbox li.on .input_txt { background:#fff !important;  }
    .regisVoteWrap li .regisVote_inbox li.on div:after { background:#273044; }
    
    
    .regisVoteWrap li .regisVote_inbox li .regiVote_delete { display:none; font-size:0; }
    .regisVoteWrap li .regisVote_inbox li.on .regiVote_delete { width:35px; height:35px;border-radius:50%; display:block; opacity:1; background:url(../../../assets/images/contents/ic_close.gif) no-repeat center center; top:9px; }
    .regisVoteWrap li .regisVote_inbox li.on .regiVote_delete:hover { background-color:#f1f3f5; }
    .options {height: auto;overflow: hidden;border-top: 1px solid #e7e9ea;}
    .voteModal .options input[type='checkbox']:not(old) + label {position: absolute;left: 20px;top: 18px;}
    .regisVoteWrap li .regisVote_inbox li div:after {content: '';display: block;position: absolute;width: 6px;height: 6px;left: 21px;top: 24px;border-radius: 50%;background: #c1cdda;z-index: 10000000000;}


    /* 시간선택 */
    .chooseTime {width: 243px;display:none !important;position: absolute;height: 50px;left: 0px;background: #fff;top: 56px;border-radius: 5px;box-shadow: 0 5px 10px rgba(0,0,0,0.15);z-index: 10000000;}
    .chooseTime.on { display:block !important; }
    .chooseTime .selectboxWrap {width: auto;background: #f1f3f5;padding: 0;position: relative;width: 80px !important;height: 50px !important;text-align: left;float: left;background: url(../../../assets/images/side_area/bt_open.png) no-repeat 49px center !important;line-height: 50px!important;border-right: 1px solid #f1f3f5;}
    .chooseTime .selectboxWrap label {position: absolute;left: 0;width: 100%;padding-left: 15px;line-height:50px !important;font-size:13px !important;box-sizing: border-box;padding-right: 10px;}
    .chooseTime .selectboxWrap:hover select, .chooseTime .selectboxWrap:hover label:hover, .chooseTime .selectboxWrap:hover label, .chooseTime .selectboxWrap:hover select:hover { }
    .chooseTime .selectboxWrap option {text-align: center;padding-left: 10px;}
    .chooseTime .selectboxWrap select { width:80px !important; }
    .chooseTime .selectboxWrap:hover label {color:#477fff;}
    .chooseTime.to {left: 370px;}
    .voteModal #voteEndHourDiv:hover .chooseTime .selectboxWrap label { color:black; border-bottom: 0 none !important }
    .voteModal #voteEndHourDiv:hover .chooseTime .selectboxWrap:hover label { color:#477fff; }
    .regisVoteWrap > ul > li.clearFix .selectboxWrap label { width: 60px;padding: 0 13px;text-align: left; }
</style>
