<template>
    <div id="hospital_time_setting_modal" class="modal" :class="{ edit : hospital_time_setting_modal_info.is_create == false }">
        <!-- <div class="titleBox">
            <h1>진료시간 추가</h1>
        </div> -->

        <div class="contractSetting">
                <div class="setGrp1 setGrp">
                    <p class="title">근무 기간</p>
                    <div class="contractDiv">
                        <label for="">시작</label>
                        <input id="hospital_time_setting_start_date" type="button" :value="`${hodu_date_to_format_string(selected_start_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(selected_start_date, '요일')}`" :disabled="hospital_time_setting_modal_info.is_create == false" />
                        <span class="seper" v-if="hospital_time_setting_modal_info.is_create == false">~</span>
                        <label for="" v-if="hospital_time_setting_modal_info.is_create == false">종료</label>
                        <input type="button" :value="`${hodu_date_to_format_string(selected_end_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(selected_end_date, '요일')}`" disabled v-if="hospital_time_setting_modal_info.is_create == false" />
                    </div>
                </div>
                <div class="setGrp2 setGrp">
                    <p class="title">예약 텀 <span>(분당 몇명 가능)</span></p>
                    <div class="inbetweenDiv">
                        
                        <label for="">분 당</label>
                        <select disabled>
                            <option value="">15분</option>
                            <!-- <option value="">10분</option>
                            <option value="">15분</option>
                            <option value="">20분</option>
                            <option value="">25분</option>
                            <option value="">30분</option>
                            <option value="">35분</option>
                            <option value="">40분</option>
                            <option value="">45분</option>
                            <option value="">50분</option>
                            <option value="">55분</option>
                            <option value="">60분</option> -->
                        </select>

                        <label for="">환자 몇명 예약 가능</label>
                        <select @change="selectTermByPeople(Number($event.target.value))">
                            <option :key="n" v-for="n in 10" :value="n" :selected="n == selected_term_by_people">
                                {{ `${n}명` }}
                            </option>
                            <!-- <option value="1">1명</option>
                            <option value="2">2명</option>
                            <option value="3">3명</option>
                            <option value="4">4명</option>
                            <option value="5">5명</option>
                            <option value="6">6명</option>
                            <option value="7">7명</option>
                            <option value="8">8명</option>
                            <option value="9">9명</option>
                            <option value="10">10명</option>
                            <option value="11">11명</option>
                            <option value="12">12명</option>
                            <option value="13">13명</option>
                            <option value="14">14명</option>
                            <option value="15">15명</option> -->
                        </select>

                    </div>
                </div>
            </div>
            <ul class="setTimeUl">
                <li>
                    <p class="title fl">진료시간 설정 <span>(예약시 사용됩니다)</span></p>
                    <div class="descript">
                        <ul>
                            <li>
                                <span class="morn clr">노랑</span>
                                <span class="txt">오전</span>
                            </li>
                            <li>
                                <span class="night clr">보라</span>
                                <span class="txt">오후</span>
                            </li>
                            <li>
                                <span class="allday clr">파랑</span>
                                <span class="txt">종일</span>
                            </li>
                            <li>
                                <span class="off clr">빨강</span>
                                <span class="txt">휴무</span>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="contractUl">
                    
                    <ul>
                        <li :key="n" v-for="n in 7">
                            <span class="indi" :class="{
                                allday : selected_time_setting[n - 1] != null && selected_time_setting[n - 1].start_am_time != null && selected_time_setting[n - 1].start_pm_time != null,
                                morn : selected_time_setting[n - 1] != null && selected_time_setting[n - 1].start_am_time != null && selected_time_setting[n - 1].start_pm_time == null,
                                night : selected_time_setting[n - 1] != null && selected_time_setting[n - 1].start_am_time == null && selected_time_setting[n - 1].start_pm_time != null,
                                off : selected_time_setting[n - 1] != null && selected_time_setting[n - 1].start_am_time == null && selected_time_setting[n - 1].start_pm_time == null,
                            }">
                            </span>
                            <p class="day">{{ getDayName(n - 1, '요일') }}</p>
                            
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>

                                <select :id="`amStart_${n - 1}`" @change="changeAmStartTime(n - 1, $event.target.value)" @click="last_select_value = $event.target.value">
                                    <option value="" :selected="selected_time_setting[n - 1] ? selected_time_setting[n - 1].start_am_time == null : false">휴원</option>
                                    <option :key="m" v-for="m in 14" :selected="selected_time_setting[n - 1] ? isSelectedTime(selected_time_setting[n - 1].start_am_time, m, 7, 0) : false" :value="valueOfSelect(m, 7, 0)">
                                        {{ getTimeOptionAmText(m, true) }}
                                    </option>
                                </select>

                                <p class="sp">~</p>

                                <select :id="`amEnd_${n - 1}`" @change="changeAmEndTime(n - 1, $event.target.value, $event.target)" @click="last_select_value = $event.target.value">
                                    <option value="" :selected="selected_time_setting[n - 1] ? selected_time_setting[n - 1].end_am_time == null : false">휴원</option>
                                    <option :key="m" v-for="m in 14" :selected="selected_time_setting[n - 1] ? isSelectedTime(selected_time_setting[n - 1].end_am_time, m, 7, 30) : false" :value="valueOfSelect(m, 7, 30)">
                                        {{ getTimeOptionAmText(m, false) }}
                                    </option>
                                </select>

                            </div>

                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                
                                <select :id="`pmStart_${n - 1}`" @change="changePmStartTime(n - 1, $event.target.value, $event.target)" @click="last_select_value = $event.target.value">
                                    <option value="" :selected="selected_time_setting[n - 1] ? selected_time_setting[n - 1].start_pm_time == null : false">휴원</option>
                                    <option :key="m" v-for="m in 22" :selected="selected_time_setting[n - 1] ? isSelectedTime(selected_time_setting[n - 1].start_pm_time, m, 12, 0) : false" :value="valueOfSelect(m, 12, 0)">
                                        {{ getTimeOptionPmText(m, true) }}
                                    </option>
                                </select>

                                <p class="sp">~</p>

                                <select :id="`pmEnd_${n - 1}`" @change="changePmEndTime(n - 1, $event.target.value)" @click="last_select_value = $event.target.value">
                                    <option value="" :selected="selected_time_setting[n - 1] != null ? selected_time_setting[n - 1].end_pm_time == null : false">휴원</option>
                                    <option :key="m" v-for="m in 22" :selected="selected_time_setting[n - 1] ? isSelectedTime(selected_time_setting[n - 1].end_pm_time, m, 12, 30) : false" :value="valueOfSelect(m, 12, 30)">
                                        {{ getTimeOptionPmText(m, false) }}
                                    </option>
                                </select>

                            </div>

                        </li>

                        <!-- <li>
                            <span class="indi allday">종일</span>
                            <p class="day">월요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="monFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="monToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select> 
                            </div> 
                            <div class="shiftDiv">                               
                            <p class="closeP">오후근무</p>
                                <select name="" id="monFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="monToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>   
                        </li>
                        <li>
                            <span class="indi allday">종일</span>
                            <p class="day">화요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="tueFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="tueToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="tueFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="tueToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi allday">종일</span>
                            <p class="day">수요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="wedFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="wedToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                                <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="wedFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="wedToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi allday">종일</span>
                            <p class="day">목요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="thuFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="thuToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="thuFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="thuToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi allday">종일</span>
                            <p class="day">금요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="friFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="friToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="friFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="friToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi morn">오전</span>
                            <p class="day">토요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="satFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="satToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="satToNight">
                                    <option value="" selected>휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="satToNight">
                                    <option value="" selected>휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi off">휴관</span>
                            <p class="day">일요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="sunFromMorn">
                                    <option value="" selected>휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="">오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="sunToMorn">
                                    <option value="" selected>휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="sunFromNight">
                                    <option value="" selected>휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="sunToNight">
                                    <option value="" selected>휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li> -->
                    </ul>
                </li>
            </ul>

            <div class="btns" :class="{ b3 : is_deleteable == true }">
                <input type="button" class="" value="취소" @click="closeModal">
                <input type="button" class="" value="삭제" v-if="is_deleteable == true" @click="deleteHospitalTimetable">
                <input type="button" class="" :value="hospital_time_setting_modal_info.is_create ? '저장' : '수정'" @click="createOrUpdateHospitalTimetable">
            </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_object, hodu_doc_modal_info } from '@/model/hodudoc';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HospitalTimeSettingModal extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.State hospital_time_setting_modal_info !: hodu_doc_modal_info.HospitalTimeSettingModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHospitalTimeSettingModalInfo ?: (params : hodu_doc_modal_info.HospitalTimeSettingModalInfo) => void;

    last_select_value : string = "";

    selected_time_setting : hodu_doc_object.hospital_setting_info[] = [];
    selected_term : number = 15;
    selected_term_by_people : number = 10;

    selected_start_date : Date = new Date();
    selected_end_date : Date = new Date();
    is_deleteable : boolean = false;
    
    async mounted() : Promise<void> {
        const vue = this;

        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        // 진료시간 수정인데 현재 적용되고 있는 세팅이 없다면
        if( this.hospital_time_setting_modal_info.is_create == false && ( this.hospital_setting_info == null || this.hospital_setting_info.length < 7 ) ) {   
            this.closeModal();
            this.hodu_show_dialog('cancel', '진료시간 정보를 찾을 수 없습니다', ['확인'], [() => { this.$router.go(0); }]);
            return;
        }

        // 진료시간 추가일때
        if( this.hospital_time_setting_modal_info.is_create == true ) {
            
            // @ts-ignore 
            $('#hospital_time_setting_start_date').datepicker({
                inline: false,
                minDate : new Date(),
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: function (dateText, inst) : void {

                    const mnt : moment.Moment = moment(dateText);
                    inst.input.val(mnt.format('YYYY.MM.DD') + ` ${vue.getDayOfWeekByDate(mnt.toDate(), '요일')}`);
                    vue.selected_start_date = mnt.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();

                },
            });

            // 기본 세팅 추가
            for( let i = 0; i < 7; i++ ) {
                switch(i) {
                    case 0:
                    case 6:
                        this.selected_time_setting.push({
                            is_current_schedule : false,
                            from_to : "",
                            day_of_week : i,
                            start_am_time : null,
                            end_am_time : null,
                            start_pm_time : null,
                            end_pm_time : null,
                            start_date : moment().toDate(),
                            end_date : moment().add('year', 1).toDate(),
                            reservation_term : this.selected_term,
                            reservation_term_by_people : this.selected_term_by_people,
                            summary : ""
                        });
                        break;

                    default:
                        this.selected_time_setting.push({
                            is_current_schedule : false,
                            from_to : "",
                            day_of_week : i,
                            start_am_time : "0900",
                            end_am_time : "1300",
                            start_pm_time : "1400",
                            end_pm_time : "1800",
                            start_date : moment().toDate(),
                            end_date : moment().add('year', 1).toDate(),
                            reservation_term : this.selected_term,
                            reservation_term_by_people : this.selected_term_by_people,
                            summary : ""
                        })
                        break;
                }
            }
            this.selected_start_date = moment().toDate();
            return;
        }

        // 진료시간 수정일때
        if( this.hospital_time_setting_modal_info.is_create == false && this.hospital_setting_info != null && this.hospital_setting_info.length > 0 ) {

            this.selected_time_setting = this.selected_time_setting.concat(this.hospital_setting_info.filter(
                item => new Date(item.start_date).getTime() == this.hospital_time_setting_modal_info.start_date.getTime()
            ));

            this.selected_time_setting.sort((item1, item2) : number => {
                
                if( Number(item1.day_of_week) < Number(item2.day_of_week) ) {
                    return -1;
                }
                
                else if ( Number(item1.day_of_week) > Number(item2.day_of_week) ) {
                    return 1;
                }

                return 0;
            });
            
        }

        // 예약텀 세팅 데이터 받아오기
        if( this.selected_time_setting.length > 0 ) {
            const setting : hodu_doc_object.hospital_setting_info = this.selected_time_setting[0];
            this.selected_term = setting.reservation_term;
            this.selected_term_by_people = setting.reservation_term_by_people;
            this.selected_start_date = new Date(setting.start_date);
            this.selected_end_date = new Date(setting.end_date);

            // 삭제 가능 여부 
            this.is_deleteable = new Date().getTime() < this.selected_start_date.getTime();
        }

    }

    /**
     * 근무표 생성
     */
    createHospitalSettingInfo() : void {

        const setting : any[] = [];

        for( const selected_setting of this.selected_time_setting ) {
            setting.push({
                "day_of_week" : selected_setting.day_of_week,
                "start_am_time" : selected_setting.start_am_time,
                "end_am_time" : selected_setting.end_am_time,
                "start_pm_time" : selected_setting.start_pm_time,
                "end_pm_time" : selected_setting.end_pm_time,
            });
        }

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/timetable`, API_METHOD.POST, {
            "setting" : setting,
            "start" : moment(this.selected_start_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format(),
            "reservation_term_by_people" : this.selected_term_by_people 
        }).then((response) => {
            console.log(response);

            // 진료시간 페이지 재조회 시키기
            this.hodu_show_dialog('success', '근무표 생성 완료', ['확인'], [() => { this.$router.go(0); }]);
            
        }).catch((e) => {
            this.hodu_error_process(e, true, false, false, () => { this.$router.go(0); });
        }).finally(() => {
            this.closeModal();
        })

    }

    /**
     * 근무표 수정
     */
    updateHospitalSettingInfo() : void {

        const setting : any[] = [];

        for( const selected_setting of this.selected_time_setting ) {
            setting.push({
                "day_of_week" : selected_setting.day_of_week,
                "start_am_time" : selected_setting.start_am_time,
                "end_am_time" : selected_setting.end_am_time,
                "start_pm_time" : selected_setting.start_pm_time,
                "end_pm_time" : selected_setting.end_pm_time,
            });
        }

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/timetable`, API_METHOD.PUT, {
            "setting" : setting,
            "start" : moment(this.selected_start_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format(),
            "reservation_term_by_people" : this.selected_term_by_people 
        }).then((response) => {
            console.log(response);

            // 진료시간 페이지 재조회 시키기
            this.hodu_show_dialog('success', '근무표 수정 완료', ['확인'], [() => { this.$router.go(0); }]);
            
        }).catch((e) => {
            this.hodu_error_process(e, true, false, false, () => { this.$router.go(0); });
        }).finally(() => {
            this.closeModal();
        })

    }

    /**
     * 근무표 삭제
     */
    deleteHospitalSettingInfo() : void {

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/timetable`, API_METHOD.DELETE, {
            "start" : moment(this.selected_start_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format()
        }).then((response) => {
            console.log(response);

            // 진료시간 페이지 재조회 시키기
            this.hodu_show_dialog('success', '근무표 삭제 완료', ['확인'], [() => { this.$router.go(0); }]);
            
        }).catch((e) => {
            this.hodu_error_process(e, true, false, false, () => { this.$router.go(0); });
        }).finally(() => {
            this.closeModal();
        })

    }

    /**
     * 15분당 몇 명 설정
     */
    selectTermByPeople(n : number) : void {
        this.selected_term_by_people = n;
    }

    /**
     * 세팅 선택 여부
     */
    isSelectedSetting(setting : any) : boolean {
        
        const current_date : Date = new Date();

        return new Date(setting.start_date).getTime() <= current_date.getTime() && new Date(setting.end_date).getTime() >= current_date.getTime();
    }

    /**
     * 옵션 태그 오전 시간 문자열 반환
     */
    getTimeOptionAmText(n : number, is_start :boolean) : string {
        const target_date : Date = moment().set('hour', 7).set('minute', (n - 1) * 30 + ( is_start ? 0 : 30 )).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }

    /**
     * 옵션 태그 오후 시간 문자열 반환
     */
    getTimeOptionPmText(n : number, is_start :boolean) : string {
        const target_date : Date = moment().set('hour', 12).set('minute', (n - 1) * 30 + ( is_start ? 0 : 30 )).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }
    
    /**
     * 선택된 시간 여부 반환
     */
    isSelectedTime(HHmm : string | null, n : number, default_hour : number, default_min : number) : boolean {

        const target_string : string = moment().set('hour', default_hour).set('minute', (n - 1) * 30 + default_min).format('HHmm');

        // console.log(`${target_string}_${HHmm}`);

        return HHmm == target_string;
    }

    /**
     * 해당 Option의 시간 텍스트 HHmm을 value로 설정하기 위해 반환
     */
    valueOfSelect(n : number, default_hour : number, default_min : number) : string {
        return moment().set('hour', default_hour).set('minute', (n - 1) * 30 + default_min).format('HHmm');
    }

    /**
     * 오전 시작 시간 변경
     */
    changeAmStartTime(index : number, value : string) : void {

        if( this.selected_time_setting[index] == null ) { return; }
        const target_setting_info : hodu_doc_object.hospital_setting_info = JSON.parse(JSON.stringify(this.selected_time_setting[index]));
        
        // 휴원 선택시
        if( value == null || value.length < 1 ) {
            target_setting_info.start_am_time = null;
            target_setting_info.end_am_time = null;
            this.selected_time_setting.splice(index, 1, target_setting_info);
            return;
        }

        target_setting_info.start_am_time = value;

        // 오전 종료 시간이 없거나 오전 종료시간보다 미래의 시간으로 바꿨을때 오전 종료 시간 변경
        if( target_setting_info.end_am_time == null || Number(target_setting_info.end_am_time) <= Number(target_setting_info.start_am_time) ) {
            const target_number = Number(target_setting_info.start_am_time) + 30;
            target_setting_info.end_am_time = `00${target_number % 100 == 60 ? (target_number + 40) : target_number}`.slice(-4);
        } 

        // 오후 시작 시간 변경
        if( target_setting_info.start_pm_time != null && Number(target_setting_info.end_am_time) > Number(target_setting_info.start_pm_time) ) {
            const target_number = Number(target_setting_info.end_am_time) + 100;
            target_setting_info.start_pm_time = `00${target_number}`.slice(-4);
        }

        // 오후 종료 시간 변경
        if( target_setting_info.end_pm_time != null && Number(target_setting_info.start_pm_time) >= Number(target_setting_info.end_pm_time) ) {
            const target_number = Number(target_setting_info.start_pm_time) + 30;
            target_setting_info.end_pm_time = `00${target_number % 100 == 60 ? (target_number + 40) : target_number}`.slice(-4);
        }

        this.selected_time_setting.splice(index, 1, target_setting_info);
    }

    /**
     * 오전 종료 시간 변경
     */
    changeAmEndTime(index : number, value : string, target : HTMLElement) : void {
        if( this.selected_time_setting[index] == null ) { return; }
        const target_setting_info : hodu_doc_object.hospital_setting_info = JSON.parse(JSON.stringify(this.selected_time_setting[index]));

        // 휴원 선택시
        if( value == null || value.length < 1 ) {
            target_setting_info.start_am_time = null;
            target_setting_info.end_am_time = null;
            this.selected_time_setting.splice(index, 1, target_setting_info);
            return;
        }

        // 오전 종료 시간 변경
        if( Number(target_setting_info.start_pm_time) < Number(value) ) {
            alert("오후 근무 시작시간보다 늦을 수 없습니다")
            $(target).val(this.last_select_value).prop('selected', true);
            return;
        }

        target_setting_info.end_am_time = value;

        // 오전 시작 시간이 없거나 오전 시작 시간보다 과거의 시간으로 바꿨을때 오전 시작 시간 변경
        if( target_setting_info.start_am_time == null || Number(target_setting_info.end_am_time) <= Number(target_setting_info.start_am_time) ) {
            const target_number = Number(target_setting_info.end_am_time) - 30;
            target_setting_info.start_am_time = `00${target_number % 100 == 70 ? (target_number - 40) : target_number}`.slice(-4);
        }

        // 오후 시작 시간 변경
        // if( target_setting_info.start_pm_time != null && Number(target_setting_info.end_am_time) > Number(target_setting_info.start_pm_time) ) {
        //     const target_number = Number(target_setting_info.end_am_time) + 100;
        //     target_setting_info.start_pm_time = `00${target_number}`.slice(-4);
        // }

        // 오후 종료 시간 변경
        // if( target_setting_info.end_pm_time != null && Number(target_setting_info.start_pm_time) >= Number(target_setting_info.end_pm_time) ) {
        //     const target_number = Number(target_setting_info.start_pm_time) + 30;
        //     target_setting_info.end_pm_time = `00${target_number % 100 == 60 ? (target_number + 40) : target_number}`.slice(-4);
        // }

        this.selected_time_setting.splice(index, 1, target_setting_info);
    }

    /**
     * 오후 시작 시간 변경
     */
    changePmStartTime(index : number, value : string, target : HTMLElement) : void {
        if( this.selected_time_setting[index] == null ) { return; }
        const target_setting_info : hodu_doc_object.hospital_setting_info = JSON.parse(JSON.stringify(this.selected_time_setting[index]));

        // 휴원 선택시
        if( value == null || value.length < 1 ) {
            target_setting_info.start_pm_time = null;
            target_setting_info.end_pm_time = null;
            this.selected_time_setting.splice(index, 1, target_setting_info);
            return;
        }

        // 오전 종료 시간 변경
        if( Number(target_setting_info.end_am_time) > Number(value) ) {
            alert("오전 근무 종료시간보다 빠를 수 없습니다")
            $(target).val(this.last_select_value).prop('selected', true);
            return;
        }

        target_setting_info.start_pm_time = value;

        // 오후 종료 시간 변경
        if( target_setting_info.end_pm_time == null || Number(target_setting_info.start_pm_time) >= Number(target_setting_info.end_pm_time) ) {
            const target_number = Number(target_setting_info.start_pm_time) + 30;
            target_setting_info.end_pm_time = `00${target_number % 100 == 60 ? (target_number + 40) : target_number}`.slice(-4);
        }

        // 오전 종료 시간 변경
        // if( target_setting_info.start_pm_time != null && Number(target_setting_info.end_am_time) > Number(target_setting_info.start_pm_time) ) {
        //     const target_number = Number(target_setting_info.start_pm_time) - 100;
        //     target_setting_info.end_am_time = `00${target_number}`.slice(-4);
        // }
        
        // 오전 시작 시간 변경
        // if( target_setting_info.start_am_time != null && Number(target_setting_info.start_am_time) >= Number(target_setting_info.end_am_time) ) {
        //     const target_number = Number(target_setting_info.end_am_time) - 30;
        //     target_setting_info.start_am_time = `00${target_number % 100 == 70 ? (target_number - 40) : target_number}`.slice(-4);
        // }

        this.selected_time_setting.splice(index, 1, target_setting_info);
    }

    /**
     * 오후 종료 시간 변경
     */
    changePmEndTime(index : number, value : string) : void {
        if( this.selected_time_setting[index] == null ) { return; }
        const target_setting_info : hodu_doc_object.hospital_setting_info = JSON.parse(JSON.stringify(this.selected_time_setting[index]));
        target_setting_info.end_pm_time = value;

        // 휴원 선택시
        if( value == null || value.length < 1 ) {
            target_setting_info.start_pm_time = null;
            target_setting_info.end_pm_time = null;
            this.selected_time_setting.splice(index, 1, target_setting_info);
            return;
        }

        // 오후 시작 시간 변경
        if( target_setting_info.start_pm_time == null || Number(target_setting_info.start_pm_time) >= Number(target_setting_info.end_pm_time) ) {
            const target_number = Number(target_setting_info.end_pm_time) - 30;
            target_setting_info.start_pm_time = `00${target_number % 100 == 70 ? (target_number - 40) : target_number}`.slice(-4);
        }

        // 오전 종료 시간 변경
        if( target_setting_info.start_pm_time != null && Number(target_setting_info.end_am_time) > Number(target_setting_info.start_pm_time) ) {
            const target_number = Number(target_setting_info.start_pm_time) - 100;
            target_setting_info.end_am_time = `00${target_number}`.slice(-4);
        }
        
        // 오전 시작 시간 변경
        if( target_setting_info.start_am_time != null && Number(target_setting_info.start_am_time) >= Number(target_setting_info.end_am_time) ) {
            const target_number = Number(target_setting_info.end_am_time) - 30;
            target_setting_info.start_am_time = `00${target_number % 100 == 70 ? (target_number - 40) : target_number}`.slice(-4);
        }

        this.selected_time_setting.splice(index, 1, target_setting_info);
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetHospitalTimeSettingModalInfo == null ) { return; }

        this.doSetHospitalTimeSettingModalInfo({ "show_modal" : false, "start_date" : new Date(), "is_create" : true, }); 
    }

    /**
     * 근무표 삭제
     */
    deleteHospitalTimetable() : void {
        if( confirm("근무표를 삭제 하시겠습니까?") == true ) {
            this.deleteHospitalSettingInfo();
        } 
    }

    /**
     * 근무표 생성 또는 수정
     */
    createOrUpdateHospitalTimetable() : void {
        
        // 근무표 생성
        if( this.hospital_time_setting_modal_info.is_create ) {
            this.createHospitalSettingInfo();
            return;
        }

        // 근무표 수정
        this.updateHospitalSettingInfo();

    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-346px; margin-left:-450px; width:100%; border-radius:5px; max-width: 900px;  height:692px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #f1f3f5 }


    .selectDiv { display: none; width: 100%; padding-right: 150px; box-sizing: border-box; }
    .selectDiv select { cursor:pointer; height: 55px; line-height: 53px; width:100%; font-weight: bold; appearance: none; border:1px solid #e7e9ea; font-size: 14px; border-radius: 5px; padding: 0 15px; background: url(../../../assets/images/side_area/bt_open.png) no-repeat 98% center; }
    .selectDiv select:hover { background-color:#f1f3f5; }
    .selectDiv select:focus { border-color:#477fff; background-color:#fff; background-image: url(../../../assets/images/side_area/bt_close.png) }
    .selectDiv option { font-size: 14px; padding: 10px 0;zoom: 1.1 }

    .title {font-size: 15px;font-weight: bold;display: block;margin-bottom: 20px;margin-top: 0;}

    .mainSettingDiv { overflow: hidden; border-bottom:2px solid #f1f3f5; margin-bottom: 30px;height: 60px; }
    .mainSettingDiv h4 { display: inline-block; font-size: 18px; line-height: 40px; height: 40px; }
    .btnBoxPos input { margin-left: 20px; }
    .btnBoxPos { float:right; }
    .btnBoxPos input { margin-left: 20px; }
    
    .setTimeDiv { padding: 30px; margin-bottom: 30px; }
    .setTimeUl .title span { font-size: 13px; margin-left:5px; opacity:0.7 }
    .setTimeUl { padding: 0 40px;  max-width: 100%; box-sizing: border-box; width:100%; margin-bottom: 30px;border-top:2px solid #f1f3f5; padding-top: 20px;}
    .setTimeUl li:first-child { position:relative; overflow:hidden;  }
    .setTimeUl .title { font-size: 15px; font-weight: bold; display: block; margin-bottom: 15px;    margin-top: 10px; }
    .setTimeUl > ul > li { overflow: hidden; position:relative;}
    .setTimeUl > ul > li > input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; }
    .setTimeUl > ul > li > input:hover { background-color:#f1f3f5; }
    .setTimeUl > ul > li > input:focus { background-color:#fff; border-color:#477fff; }
    .setTimeUl > ul > li > span { padding-right:20px; }
    .setTimeUl .descript { position: absolute; right:0; top:10px; }
    .setTimeUl .descript li { overflow:hidden; display:inline-block;margin-right:20px; }
    .setTimeUl .descript li:last-child { margin-right:0; }
    .setTimeUl .descript li .clr { float:left; margin-right:5px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; }
    .setTimeUl .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .setTimeUl .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .setTimeUl .descript li .clr.allday {background: #477fff !important;border-color: #477fff;}
    .setTimeUl .descript li .clr.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .contractSelect { transition:0.2s; cursor:pointer; font-size: 14px; font-weight:bold; appearance: none; border-radius: 5px; line-height: 50px; height:55px; border:1px solid #e7e9ea;padding: 0 20px; width:100%; box-sizing: border-box; background-image:url('../../../assets/images/side_area/bt_open.png'); background-position: 98%  center; background-repeat: no-repeat }
    .contractSelect:hover { background-color:#f1f3f5;  }
    .contractSelect:focus { background-color:#fff; border-color:#477fff;background-image:url('../../../assets/images/side_area/bt_close.png'); }
    .contractSelect option { height: 40px; padding:20px 0; }
    .contractUl { border-left:1px solid #e7e9ea; border-top: 1px solid #e7e9ea }
    .contractUl li {transition:0.2s;overflow:hidden; position:relative; font-size: 12px; line-height: 50px; height:50px; border-bottom:2px solid #e7e9ea; }
    .contractUl li:hover { border-color:#477fff; }
    .contractUl li a { display:inline-block; width:100%; height: 100%; }
    .contractUl li a:hover { background:#f7f9fb; font-weight:bold; }
    .contractUl li .day { font-weight:bold; display:inline-block;text-align: center; width:14%; font-size: 12px;box-sizing:border-box; padding-left:25px; }
    .closeP {border-left: 5px solid #f1f3f5; }
    .contractUl li .workHours { display: inline-block; border-left: 1px solid #e7e9ea; line-height: 15px;    box-sizing: border-box; }
    .contractUl li p { transition:0.2s; float:left;    box-sizing: border-box; display:inline-block !important; width: 10%; text-align: center; border-right:1px solid #e7e9ea }
    .contractUl li .sp { width:4.5% }
    .contractUl select {transition:0.2s;font-size: 12px; float:left; cursor:pointer; display: inline-block; appearance: none; border-right:1px solid #e7e9ea; width: 14.23%;text-align:center; box-sizing: border-box; padding:0 20px; line-height: 50px; height:50px; }
    .contractUl select {background: url(../../../assets/images/side_area/bt_open.png) no-repeat 90% center; }
    .contractUl select option { font-size: 14px;}
    .contractUl select:hover { background-color:#f1f3f5; }
    .contractUl select:focus { background-color:#fff; background-image: url(../../../assets/images/side_area/bt_close.png) }
    .workHours span {display:inline-block; padding: 0 25px; }
    .contractUl .indi {position:absolute; top: 20px;left: 20px;display: inline-block;width: 7px;height: 7px; border-radius: 50%; font-size:0;border:1px solid; font-weight: bold; text-align: center; color:#fff; } 
    .contractUl .indi.morn {background: #ffc00e !important;border-color: #f3b300;}
    .contractUl .indi.night {background: #846eff !important;border-color: #6c52ff;}
    .contractUl .indi.allday {background: #477fff !important;border-color: #477fff;}
    .contractUl .indi.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .dayUl  { overflow:hidden; border-right:1px solid #e7e9ea}
    .dayUl li { display: inline-block; width: 14.2857142857%; float:left; text-align:center; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .dayUl li:hover .day { background:#f1f3f5; }
    .dayUl li p { line-height: 35px; height: 35px; font-weight: bold; border-bottom: 1px solid #e7e9ea;border-top: 1px solid #e7e9ea; }
    .offDay .checkDiv a { cursor: default; }
    .offDay .checkDiv a span { opacity:0.3; cursor: default; background:#fff !important;  }
    .checkDiv a { display: block; height:45px; line-height:45px; box-sizing: border-box; font-weight: bold; border-bottom: 1px solid #e7e9ea; }
    .checkDiv a.on:hover { cursor:default }
    .checkDiv a.on span { background:#477fff !important;border-color:#1b5fff; color:#fff; }
    .checkDiv a:hover span { background: #f1f3f5; }
    .checkDiv a span { transition:0.2s; display: block; height:100%; line-height: 45px; background:#fff; box-sizing: border-box;}
    .checkDiv a.on.morn span {background: #ffc00e !important;border-color: #f3b300;}
    .checkDiv a.on.night span {background: #846eff !important;border-color: #6c52ff;}
    .checkDiv a.on.off span { background: #a1a5bb !important;border-color: red;}
    #docClrId {transition:0.2s;width: 100%;height: 50px;line-height: 50px;border-radius: 5px;background: #fff;border: 1px solid #e7e9ea;box-sizing: border-box;padding: 0 15px;text-align: left;font-weight: bold;  }
    #docClrId:hover { background: #f1f3f5; }
    .docDetailDiv .clr { position:absolute; right:35px; top:43px; display: inline-block; width:20px;height: 20px; border-radius: 50%; ; }

    .shiftDiv:hover .openP, .shiftDiv:hover .closeP, .shiftDiv:hover select { font-weight:bold; }
    .contractSetting { overflow: hidden; position: relative; max-width: 100%; }
    .contractSetting .setGrp { width: 50%; float: left; box-sizing: border-box;padding: 30px 0 30px 40px;border-right: 2px solid #f1f3f5; }
    .contractSetting .setGrp.setGrp2 { border-right: 0 none; }
    .contractSetting .contractDiv input { transition:0.2s; cursor:pointer; padding: 0 15px; min-width: 150px;border:1px solid #e7e9ea; height: 40px; line-height: 40px; font-weight: bold; border-radius: 5px;  margin-right: 20px; width: 100px;text-align: center; background-color:#fff; }
    .contractSetting .contractDiv input:hover { background-color:#f1f3f5; }
    .contractSetting .contractDiv input:focus { background-color:#fff; }
    .contractSetting .seper { padding-right: 20px; }
    .contractDiv { position:relative;padding-top:22px;}
    .contractDiv label { position: absolute;top:0;font-size: 12px; }

    .inbetweenDiv { position:relative;padding-top:22px; }
    .inbetweenDiv label { position: absolute;top:0;font-size: 12px; }
    .inbetweenDiv select { appearance: none;transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 40px; line-height: 40px; min-width: 150px; font-weight: bold; border-radius: 5px;  margin-right: 30px; width: 100px;text-align: center;background:  url('../../../assets/images/side_area/bt_open.png') no-repeat 91% center; }
    .contractSetting .inbetweenDiv select:hover { background-color:#f1f3f5; }
    .contractSetting .inbetweenDiv select:focus { background-color:#fff; border-color:#477fff;background-image: url('../../../assets/images/side_area/bt_close.png') }
    option { font-size: 14px }

    .btns { overflow: hidden; border-top:1px solid #f1f3f5;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold; }
    .btns.b3 input { width:33.3333333333%; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }

    .historyDiv { padding: 0 30px 30px 0; }

    .hospital_time_setting_modal.edit {}

</style>