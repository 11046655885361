<template>
    <!-- 동기화 체크 박스 has_check 클래스 추가 -->
    <div id="commonInputModal" class="modal commonInputModal" :class="{ radio : hasRadio() == true, has_check : common_input_modal_info.has_check == true }">
        <h1>{{ common_input_modal_info.title }}</h1>
        <div class="content">
            
            <div class="detail">
                <div class="titleDiv">
                    <h2>{{ common_input_modal_info.subtitle }}</h2>
                    <input :type="common_input_modal_info.is_password == true ? 'password' : 'text'" class="input_modal_content" :class="{ wrong : is_wrong == true }" 
                           :placeholder="common_input_modal_info.placeholder" :value="common_input_modal_info.content" @input="input($event)" />
                    
                    <div class="synchBox">
                        <input type="checkbox" id="check_psw" v-model="check_flag">
                        <label for="check_psw"><span>{{ common_input_modal_info.check_text }}</span></label>
                    </div>

                </div>

                <div class="radio" v-if="hasRadio() == true">
                    <h2>{{ common_input_modal_info.radio.title }}</h2>

                    <div :key="index" v-for="(item, index) in common_input_modal_info.radio.list">
                        <input type="radio" name="input_modal_radio" :value="item.value" :id="`input_modal_radio_${index}`" v-model="selected_radio"/>
                        <label :for="`input_modal_radio_${index}`">{{ item.text }}</label>
                    </div>

                    <!-- <div>
                        <input type="radio" name="input_modal_radio" value="1" id="input_modal_radio_0" />
                        <label for="input_modal_radio_0">1년</label>
                    </div>
                    <div>
                        <input type="radio" name="input_modal_radio" value="1" id="input_modal_radio_1" />
                        <label for="input_modal_radio_1">2년</label>
                    </div>
                    <div>
                        <input type="radio" name="input_modal_radio" value="1" id="input_modal_radio_2" />
                        <label for="input_modal_radio_2">3년</label>
                    </div>
                    <div>
                        <input type="radio" name="input_modal_radio" value="1" id="input_modal_radio_3" />
                        <label for="input_modal_radio_3">4년</label>
                    </div>
                    <div>
                        <input type="radio" name="input_modal_radio" value="1" id="input_modal_radio_4" />
                        <label for="input_modal_radio_4">5년</label>
                    </div> -->
                </div>

            </div>

            <div class="btns">
                <input type="button" class="input_modal_btn" value="취소" @click="cancel"/>
                <input type="button" class="input_modal_btn" 
                       :value="common_input_modal_info.save_text && common_input_modal_info.save_text.trim().length > 0 ? common_input_modal_info.save_text :  '저장'" 
                       @click="save"/>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonInputModalInfo } from '@/store/modules/ModalInfo';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class CommonInputModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State common_input_modal_info !: CommonInputModalInfo;

    selected_radio : string = "";
    is_wrong : boolean = false;

    check_flag : boolean = false;

    mounted() : void {
        if( this.hasRadio() == true ) this.selected_radio = this.common_input_modal_info.radio.list[0].value;
        $('#commonInputModal .input_modal_content').focus();
    }

    hasRadio() : boolean {
        return this.common_input_modal_info.radio != null && 
               this.common_input_modal_info.radio.list !=  null && 
               this.common_input_modal_info.radio.list.length > 0;
    }

    /**
     * 잘못된 입력 (에러메세지는 modal 사용하는곳에서 제어)
     */
    wrong() : void {
        this.is_wrong = true;
    }

    /**
     * 텍스트 입력
     */
    input(event) : void {
        this.is_wrong = false;
        this.common_input_modal_info.content = event.target.value;
    }

    /**
     * 취소
     */
    cancel() : void {
        this.common_input_modal_info.cancel?.();
        this.close();
    }

    /**
     * 값 체크
     */
    check() : boolean {

        if( this.common_input_modal_info.content == null || this.common_input_modal_info.content.trim().length < 1 ) {
            this.is_wrong = true;
            $('.input_modal_content').focus();
            return false;
        }

        return true;
    }

    /**
     * 저장
     */
    save() : void {

        // 값 체크
        if( this.check() == false ) return;

        this.common_input_modal_info.save?.(this.common_input_modal_info.content, this.selected_radio, this.check_flag);
        this.close();
    }

    close() : void {
        this.doSetCommonInputModalInfo?.({ show_modal : false });
    }

}
</script>

<style scoped>
    .modal.commonInputModal { width:650px; position:absolute; background:#fff; border-radius:5px; margin:0 auto; height: auto; top:50%; right: 0; left: 0; margin-bottom: 0; overflow:hidden; margin-top: -155.5px; }
    .modal.commonInputModal.radio { margin-top: -204.5px; }
    .modal.commonInputModal h1 { font-size: 19px; padding: 0 30px; height:85px; line-height:100px; width:100%; box-sizing: border-box; border-bottom:1px solid #f1f3f5; }
    
    .modal.commonInputModal .btns { width:100%;padding:0; border-top:1px solid #f1f3f5; }
    .modal.commonInputModal .btns input { width : 50%; transition:0.2s; background:#fff; font-size:14px; font-weight:bold; height:70px; line-height:70px; box-sizing: border-box; padding:0; }
    .modal.commonInputModal .btns input:hover { background:#f1f3f5; }

    .modal.commonInputModal .detail { padding:30px 40px; }
    .modal.commonInputModal .detail .titleDiv h2 { font-size:14px; margin-bottom:10px; padding-left : 10px; height:30px; line-height:30px; }
    .modal.commonInputModal .detail .titleDiv input { transition:0.2s; line-height:55px; height:55px; padding: 0 15px; border: 1px solid #e7e9ea; width:100%; box-sizing: border-box; font-size:17px; font-weight:bold; } 
    .modal.commonInputModal .detail .titleDiv input:hover { border:1px solid #d5dbde; background:#f1f3f5; }
    .modal.commonInputModal .detail .titleDiv input:focus { border:1px solid #477fff; background:#fff !important; }
    .modal.commonInputModal .detail .titleDiv input.wrong { border:1px solid #ff6060; background:#fff0f0; color:#ff6060; }
    .modal.commonInputModal .detail .titleDiv input.wrong::placeholder { color:#ff6060; }

    .modal.commonInputModal .radio { display : none; width : 100%; height : 80px; }
    .modal.commonInputModal.radio .radio { display : block; }
    .modal.commonInputModal .radio > div { display : inline-block; }
    .modal.commonInputModal .radio > div > label { width : 40px; height : 30px; line-height: 30px; background : #fff; border : solid 1px #f1f3f5; border-radius : 3px; text-align : center; padding : 0; margin : 0; font-weight: bold; font-size: 13px; margin-right: 10px; }
    .modal.commonInputModal .radio > div > input[type='radio']:not(old):checked+label { color : #fff; background: #477fff; border : none; }
    .modal.commonInputModal .radio > div > input[type='radio']:not(old)+label { color : #232848; }

    .modal.commonInputModal .radio > h2 { font-size: 14px; margin-top : 18px; margin-bottom : 10px; padding-left : 10px; height: 30px; line-height: 30px; }

    /* 동기화 체크박스 */
    .modal .synchBox { display: none; }
    .modal.has_check .synchBox { display: flex; }
    .synchBox { display: flex; justify-content: flex-end; align-items: center; height: 50px; margin-top: 10px; position: relative; }
    .modal.commonInputModal.has_check .detail .titleDiv input[type='checkbox']:not(old) { text-align: right; height: 30px; margin: 0; padding: 0; opacity: 0; width: 0; }
    .has_check input[type='checkbox']:not(old)+label { float: right; padding: 0; width: 20px; height: 20px; margin-right: 80px; border-radius: 3px; background: #c1cfd8 url('../../../assets/images/contents/checked_fff.png') no-repeat 0px 0px; background-size: 21px; }
    .has_check input[type='checkbox']:checked +label { background-color: #477fff; }
    .has_check input[type='checkbox']:not(old)+label span { position: absolute; top: 17px; right: 0px; font-weight: bold; }

    .modal.commonInputModal.has_check { margin-top: -185.5px; }
</style>