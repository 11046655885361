<template>
<!-- ************* 기본업무 현황일 시 workStatus 클래스 추가 basicStatus -->
    <div class="modal remodal workStatus" style="width:auto;">
        
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <button class="remodal-close" @click="modalClose"><span></span><span></span></button>
        <div class="left_titleBox">
            <h1 class="">프로젝트 현황</h1>
            <div class="btns">
                <input type="button" class="refreshBtn fr" value="새로고침" @click="getWorkStatus()" />
                <p class="refreshDescript">{{replash_times}}</p>
            </div>
        </div>
        <div class="remodal-content remodal-content07">
            <!-- <div class="bg"></div> -->
            <div class="titleBg">
                <!-- 업무리스트안에 현황 확인 시에만 클릭시 dropdown보이기 -->
                <h2>
                    <a style="cursor:default;">
                        <span class="dot dc0" :style="{ backgroundColor : `${hodu_hex_color_process(work_color)} !important` }">빨강</span>{{work_status_title}}
                    </a>
                </h2>
                <!--
                <div class="dropdown">
                    <ul>
                        <li class="on"><a><span class="dot dc0">빨강</span> 삼성동 (주)유한 회사 기획하기</a></li>
                        <li><a><span class="dot dc1">빨강</span> 송파 (주)유한 회사 기획하기</a></li>
                        <li><a><span class="dot dc2">빨강</span> 서초 (주)유한 회사 기획하기</a></li>
                        <li><a><span class="dot dc2">빨강</span> 광진 (주)유한 회사 기획하기</a></li>
                        <li><a><span class="dot dc3">빨강</span> 서울 (주)유한 회사 기획하기</a></li>
                        <li><a><span class="dot dc4">빨강</span> 광주 (주)유한 회사 기획하기</a></li>
                        <li><a><span class="dot dc5">빨강</span> 부산 (주)유한 회사 기획하기</a></li>
                        <li><a><span class="dot dc7">빨강</span> 몰라 (주)유한 회사 기획하기</a></li>
                        <li><a><span class="dot dc8">빨강</span> 나도 (주)유한 회사 기획하기</a></li>
                    </ul>
                </div>
                -->
            </div>
            <div id="statusScroll">
                <!-- barUl 리스트 개수에 따라 chartBg에 클래스 추가 (two, three, four) 기본이 five이기에 다섯개일땐 클래스 필요없음 -->
                <div class="chartBg" :class="{
                    on : see_chart == true,
                    two : work_template_contents.length == 2,
                    three : work_template_contents.length == 3,
                    four : work_template_contents.length == 4,
                }">
                    <div class="details2">
                        <!-- 업무명 -->
                        <h3>{{ work_status_title }}</h3>
                        <p class="status">
                            이 프로젝트는 총 <span>{{work_template_contents.length}}</span>단계가 있습니다. 현재 <span>{{total_work_user_count}}</span>명에게 배정되었으며 그 중 <span>{{work_start_user_count}}</span>명이 프로젝트를 수행중입니다.
                        </p>
                    </div>
                    <input type="button" class="seeMore" value="차트 보기" @click="chartOnOff"/>
                    <div class="BarChart">
                        <ul class="barUl">
                            <li :class="{zero: lo_data.status_info.length == 0}" v-bind:key="index" v-for="(lo_data, index) in work_template_contents" :style="{ width: `${lo_data.horizontal_percent}%` }">
                                <span class="num">{{lo_data.status_info.length}}</span>

                                <span class="txt" :style="{ backgroundColor: `${hodu_hex_color_process(lo_data.color)} !important` }">{{lo_data.title}}</span>
                            </li>
                        </ul>
                        <ul class="details">
                            <li :class="{zero: lo_data.status_info.length == 0}" v-bind:key="index" v-for="(lo_data, index) in work_template_contents">
                                <span class="dot dc9" :style="{ backgroundColor: `${hodu_hex_color_process(lo_data.color)} !important` }">빨강</span>
                                <span class="txt">{{lo_data.title}}</span><span class="num">{{lo_data.status_info.length}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="people">
                    <h3 class="total">전체 <span>{{ (template_type == "CHECK" ? check_user_ids.length : work_start_user_count) + "명 업무중"}}</span></h3>
<!-- *********** 기본업무 현황도 모두 동일하고, 리스트 속 span.txt만 변경 (진행 중인 업무자, 완료한 업무자), ul#ul1에 ul1을 제외 한 추가되는 클래스 없음  -->
                    <!-- 체크식일시 addClass(check), 단계식일시 클래스없음,  -->
                    <ul id="ul1" class="ul1" :class="{ check : template_type == 'CHECK' }">
                        <li :class="{zero: lo_data.status_info.length == 0, on : lo_data.listOn != null && lo_data.listOn == true }" v-bind:key="index" v-for="(lo_data, index) in work_template_contents">
                            <p class="step dc9" :style="{ backgroundColor: `${hodu_hex_color_process(lo_data.color)} !important` }">{{index+1}}</p>
                            <a @click="listOnOff(index)">
<!-- *********** 기본업무 일 시 span.txt 변경 (진행 중인 업무자, 완료한 업무자)  -->
                                <span class="txt">{{lo_data.title}}</span>
                                <span class="seper"></span>
                                <span class="num">{{lo_data.status_info.length}}</span>
                                <span class="btn">열기</span>

                                <!-- 진행하는 사람이 없을경우 -->
                                <ul v-if="lo_data.status_info.length == 0">
                                    <li class="noResult">이 단계를 진행하는 사람이 없습니다.</li>
                                </ul>
                                <!-- 진행하는 사람이 있을때 -->
                                <ul v-if="lo_data.status_info.length > 0">
                                    <li :class="{done: lo_user.work_status_info.is_end}" v-bind:key="sub_index" v-for="(lo_user, sub_index) in lo_data.status_info">
                                        <img :src="`${lo_user.user_pic ? '/app_images/' + lo_user.user_pic : user_profile_no_image}`" alt="프로필 사진" @error="imageError($event)">
                                        <p class="name">{{lo_user.user_name}}</p>
                                    </li>
                                </ul>
                            </a>
                        </li>
                    </ul>
                    <div class="people2">
                        <h3>시작 전</h3>
                        <p class="sub">현재 <span class="total">{{no_work_start_users.length}}</span>명이 이 프로젝트를 시작하지 않았습니다.</p>
                        <div class="btn">
                            <!-- <a class="requestBtn">전체요청</a><p><span class="count">0</span> / {{no_work_start_users.length}}</p> -->
                        </div>
                        <ul class="ul2">
                            <li v-bind:key="index" v-for="(lo_data, index) in no_work_start_users">
                                <img :src="`${lo_data.user_pic ? '/app_images/' + lo_data.user_pic : user_profile_no_image}`" alt="프로필 사진" @error="imageError($event)">
                                <p class="name">{{lo_data.user_name}}</p>
                                <!-- <a>요청</a> -->
                            </li>
                            <!--
                            <li class="ing">
                                <img src="../../../assets/images/contents/im_photoB.gif" alt="프로필 사진"/>
                                <p class="name">라수석</p>
                                <a>요청</a>
                            </li>
                            -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import moment from 'moment';
import { WorkStatusInfo } from '@/store/modules/WorkInfo';

const dateFormat = require('dateformat');
const WorkInfo = namespace('WorkInfo');
const ModalInfo = namespace('ModalInfo');

import { ResizeObserver } from 'vue-resize'

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {
        ResizeObserver
    }
})
export default class WorkStatus extends Mixins(VueHoduCommon) {
    /**
     * @WorkInfo.State
     */
    @WorkInfo.State   work_status_info !: WorkStatusInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowWorkStatus ?: any;

    work_color             : string    = "";
    work_status_title      : string    = "";
    work_template_contents : any[]     = [];
    no_work_start_users    : any       = [];
    total_work_user_count  : number    = 0;
    work_start_user_count  : number    = 0;
    replash_times          : string    = "";
    template_type          : string    = "";
    check_user_ids         : number[]  = [];    // 체크식 업무중 사용자 카운트
    see_chart              : boolean   = false; // 차트를 보는 중인지 여부
    work_status_list       : any[]     = [];    // 업무자 정보 리스트

    user_profile_no_image = require("@/assets/images/contents/im_photoB.gif");

    mounted() {
        // @ts-ignore
        // $(".workStatus .people").mCustomScrollbar();
		
		// 업무현황 > 시작전
		$(".workStatus .people2 .ul2 > li a").click(function(){
			$(this).parent().addClass("ing");	
		});
		
		// 업무현황 상단 업무명 드롭다운
		// $(".workStatus .titleBg a").click(function(){
		// 	// 버튼 화살표 위치변경
		// 	$(this).toggleClass("on");
		// 	// 드롭다우 보이기
		// 	$(this).parent().siblings(".dropdown").toggleClass("on");	
		// 	//드롭다운 뒤에 하얀 배경
		// 	$(".workStatus .bg").toggleClass("on");
			
        // });
        
		//  업무현황 상단 업무명 드롭다운 하얀 배경 클릭시 모든 on빼기
		// $(".workStatus .bg").click(function(){
		// 	$(this).removeClass("on");
		// 	$(".workStatus .titleBg a").removeClass("on");
		// 	$(".workStatus .titleBg .dropdown").removeClass("on");	
        // });
        
        this.setScroll();
        
        // 업무현황조회
        this.getWorkStatus();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height    : number | undefined = $('.left_titleBox').outerHeight();
        const title_bg_height : number | undefined = $('.titleBg').outerHeight();
        const final_height    : number = window.innerHeight - (title_height ? title_height : 0) - (title_bg_height ? title_bg_height : 0);

        // @ts-ignore
        $("#statusScroll").mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : final_height
        });
    }

    /**
     * 업무 현황 조회
     */
    async getWorkStatus() : Promise<void> {
        this.work_status_title      = "";
        this.work_template_contents = [];
        this.no_work_start_users    = [];
        this.total_work_user_count  = 0;
        this.work_start_user_count  = 0;
        this.check_user_ids         = [];

		await this.hodu_api_call(`api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/status/${this.work_status_info.event_id}`, API_METHOD.GET, null)
			.then(async(response) => {
                let lo_data = response.data.data.list[0];
                this.template_type = lo_data.template_type;

                this.work_status_list = lo_data.status_info;

                // JSON 만들기
                for ( let i = 0; i < lo_data.template_info.content.length; i++ ) {
                    let vo : any = lo_data.template_info.content[i];

                    this.work_template_contents.push({
                         color: await this.hodu_hex_color_process(vo.color)
                       , title: vo.title
                       , horizontal_percent : 0
                       , vertical_percent: 0
                       , status_info: []
                    })
                }

                // 체크식
                if ( this.template_type == "CHECK" ) { 
                    this.checkDisplay(lo_data);
                }
                // 단계식
                else {
                    this.flowDisplay(lo_data)
                }
			})
			.catch(async(e) => {
                this.hodu_error_process(e, false, false);
                this.doSetShowWorkStatus(false);
			});
    }
    
    /**
     * 체크식 데이터만들기
     */
    async checkDisplay(lo_data) : Promise<void> {
        let work_status_length = this.work_template_contents.length;

        // 업무를 시작한사람
        for ( let i = 0; i < lo_data.status_info.length; i++ ) {
            let vo       : any = lo_data.status_info[i];
            let progress : any = vo.work_status_info.progress;

            for ( let j = 0; j < work_status_length; j++ ) {
                if ( progress[j].status == "END" ) {
                    this.work_template_contents[j].status_info.push(vo);

                    if ( this.check_user_ids.indexOf(vo.user_id) < 0 ) { this.check_user_ids.push(vo.user_id) }
                }
            }
        }

        // 업무를 시작안한사람 찾기
        for ( let i = 0; i < lo_data.status_info.length; i++ ) {
            for ( let j = 0; j < work_status_length; j++ ) {
                let vo : any = lo_data.status_info[i];

                if ( this.check_user_ids.indexOf(vo.user_id) > -1 ) {
                    continue;
                }
                else {
                    if ( !this.no_work_start_users.find(u => u.user_id == vo.user_id) ) {
                        this.no_work_start_users.push(vo);
                        break;
                    }
                }
            }
        }

        for ( let i = 0; i < this.work_template_contents.length; i++ ) {
            if ( this.work_template_contents[i].status_info.length > 0 ) {
                this.work_start_user_count += this.work_template_contents[i].status_info.length;
            }
        }

        this.total_work_user_count = this.work_start_user_count;

        this.makePercent(lo_data.event_data.title, lo_data.event_data.color);
    }

    /**
     * 퍼센트만들기
     */
    makePercent(ps_title : string, ps_color : string) : void {
        this.work_status_title = ps_title;
        this.work_color        = ps_color;

        this.replash_times = this.amPmStringToLocaleAmPmString(moment(new Date()).format('YYYY.MM.DD hh:mm:ss A'));

        let total_count : number = 0;
        for(let contents of this.work_template_contents) {
            total_count += contents.status_info.length;
        }

        const contents_count : number = this.work_template_contents.length;

        // 아무도 업무 진행을 하지 않은 경우 
        if( total_count == 0 ) {
            for ( let i = 0; i < contents_count; i++ ) {
                const contents : any = JSON.parse(JSON.stringify(this.work_template_contents[i]));
                contents.horizontal_percent = (100 / (this.work_template_contents.length)).toFixed(10);
                contents.vertical_percent   = 3;
                this.work_template_contents.splice(i, 1, contents);
            }
        }

        // 업무를 진행한 사람이 있는 경우
        else {
            // 해당 번호에 없는지 체크
            let no_result_count : number = 0;
            for(let contents of this.work_template_contents) {
                if( contents.status_info.length == 0 ) {
                    no_result_count++;
                }
            }

            // 백분율 계산
            for ( let i = 0; i < contents_count; i++ ) {
                const contents : any = JSON.parse(JSON.stringify(this.work_template_contents[i]));
                contents.horizontal_percent = contents.status_info.length == 0 ? 3 : (contents.status_info.length / total_count * (100 - ( 3 * no_result_count ))).toFixed(10);
                contents.vertical_percent   = contents.status_info.length == 0 ? 3 : (contents.status_info.length / this.work_status_list.length * 100).toFixed(10);
                this.work_template_contents.splice(i, 1, contents);
            }
        }

        // 백분율 계산
        // let no_persent : number = 0;
        // let total_persent : number = 0;

        // 퍼센트가 0인것들에 대해서 미리만든다..
        // for ( let i = 0; i < this.work_template_contents.length; i++ ) {
        //     if ( this.work_template_contents[i].status_info.length == 0 ) {
        //         this.work_template_contents[i].percent = 3;
        //         no_persent += 3;
        //     }
        // }

        // 단계에 한명이라도 있는경우
        // for ( let i = 0; i < this.work_template_contents.length; i++ ) {
        //     if ( this.work_template_contents[i].status_info.length > 0 ) {

        //         let data_persent = Number(((this.work_template_contents[i].status_info.length / this.total_work_user_count * (100 - no_persent))).toFixed(1));

        //         total_persent += data_persent;

        //         if ( (total_persent + no_persent) > 100 ) {
        //             this.work_template_contents[i].percent =  100 - (total_persent - data_persent + no_persent);
        //         }
        //         else {
        //             this.work_template_contents[i].percent = data_persent;
        //         }
        //     }
        // }
    }

    /**
     * 단계식 데이터만들기
     */
    async flowDisplay(lo_data) : Promise<void> {
        for ( let i = 0; i < lo_data.status_info.length; i++ ) {
            let vo       : any     = lo_data.status_info[i];
            let is_end   : boolean = vo.work_status_info.is_end;
            let progress : any     = vo.work_status_info.progress;

            let work_status_length = this.work_template_contents.length;

                for ( let j = work_status_length - 1; j >= 0; j-- ) {
                    // 업무가 종료 되었을때
                    if ( is_end ) {
                        this.work_start_user_count ++;
                        this.work_template_contents[j].status_info.push(vo);
                        break;
                    }
                    else {
                        // 업무시작
                        if ( progress[j].status == "START" ) {
                            this.work_start_user_count ++;
                            this.work_template_contents[j].status_info.push(vo);
                            break;
                        }
                    }

                    // 업무를 시작 안한 사람들을 배열에 넣어야함.
                    if ( j == 0 ) {
                        this.no_work_start_users.push(vo);
                    }    
                }
        }

        this.total_work_user_count = this.work_start_user_count + this.no_work_start_users.length;

        /*
        this.total_work_user_count = this.work_start_user_count + this.no_work_start_users.length;
        this.work_status_title = lo_data.event_data.title;
        this.replash_times = moment(new Date()).format('YYYY.MM.DD HH:mm:ss A');

        // 백분율 계산
        let no_persent = 0;

        // 퍼센트가 0인것들에 대해서 미리만든다..
        for ( let i = 0; i < this.work_template_contents.length; i++ ) {
            if ( this.work_template_contents[i].status_info.length == 0 ) {
                this.work_template_contents[i].percent = 3;
                no_persent += 3;
            }
        }

        // 단계에 한명이라도 있는경우
        for ( let i = 0; i < this.work_template_contents.length; i++ ) {
            if ( this.work_template_contents[i].status_info.length > 0 ) {
                this.work_template_contents[i].percent = ((this.work_template_contents[i].status_info.length / this.total_work_user_count * (100 - no_persent))).toFixed(2);
            }
        }
        */

        this.makePercent(lo_data.event_data.title, lo_data.event_data.color);
    }

    /**
     * 차트 보기 ON / OFF
     */
    chartOnOff() : void {
        const vue = this;
        this.see_chart = !this.see_chart;

        // 첫번쨰 리스트에, 애니메이션 용
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(0).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[0] != null) {
                $(".chartBg").find(".barUl li").eq(0).find(".txt").css('height', `${vue.work_template_contents[0].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[0] != null ) {
                $(".chartBg").find(".barUl li").eq(0).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(0).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[0].color));
            }
        }, 200);
        // 두번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(1).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[1] != null) {
                $(".chartBg").find(".barUl li").eq(1).find(".txt").css('height', `${vue.work_template_contents[1].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[1] != null ) {
                $(".chartBg").find(".barUl li").eq(1).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(1).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[1].color));
            }
        }, 205);
        // 세번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(2).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[2] != null) {
                $(".chartBg").find(".barUl li").eq(2).find(".txt").css('height', `${vue.work_template_contents[2].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[2] != null ) {
                $(".chartBg").find(".barUl li").eq(2).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(2).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[2].color));
            }
        }, 210);
        // 네번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(3).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[3] != null) {
                $(".chartBg").find(".barUl li").eq(3).find(".txt").css('height', `${vue.work_template_contents[3].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[3] != null ) {
                $(".chartBg").find(".barUl li").eq(3).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(3).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[3].color));
            }
        }, 215);
        // 다섯번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(4).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[4] != null) {
                $(".chartBg").find(".barUl li").eq(4).find(".txt").css('height', `${vue.work_template_contents[4].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[4] != null ) {
                $(".chartBg").find(".barUl li").eq(4).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(4).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[4].color));
            }
        }, 220);

        setTimeout(function(){
            $(".chartBg").find(".barUl li").find(".txt").removeClass("ani");
            $(".chartBg").find(".barUl li").find(".txt").toggleClass("transit2");
        }, 300);
        
    }

    /**
     * 리스트 펼치기 접기
     */
    listOnOff(index : number) : void {
        const contents : any = this.work_template_contents[index];

        if( this.work_template_contents[index].listOn == null ) {
            contents.listOn = true;
        } else {
            contents.listOn = !contents.listOn;
        }

        this.work_template_contents.splice(index, 1, contents);
    }

    /**
     * 모달 닫기
     */
    modalClose() : void {
        this.doSetShowWorkStatus(false);
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#statusScroll").mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    .modal {position:absolute; background:#fff; width:100%; min-width:800px;right:0;top:0;  }
    .workStatus .people .ul1.check > li:after { background : transparent !important; }
    .workStatus { background: #f1f3f5; }
    .workStatus .left_titleBox { background: #ffffff; border-radius: 0px !important; }
    .workStatus .titleBg { background: #ffffff; }
    .workStatus .chartBg { background: #ffffff; }
    .workStatus .people { background: #ffffff; }

    .workStatus .people ul ul {  width:100%;padding-left: 0;}
    .people2 > .btn p { text-align:center; }
</style>