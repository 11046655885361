<template>
    <div id="apt_setting" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <!-- title_box 시작 -->
        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">설정</h3>
        </div>
        <!-- title_box 끝 -->

        <!-- section_scroll -->
        <div class="section_scroll">
            <div class="viewGroup">
                <!-- <div class="schedule_box">
                    <div class="grp settingBg">
                        <div class="posRel">
                            <div class="bg">
                                <div class="bigGrp left">
                                    <input type="button" value="기본 설정" />
                                    <input type="button" value="동/호수 설정" />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div id="aptSet_div" class="content">
                    
                    <div class="leftScroll" :class="{ on : is_right_menu_open == false }">
                        <div class="titleDiv">
                            <h2 class="title" :class="{ active : tab == 'TEL' }"><a href="#" class="tit_tab" @click.prevent="tab = 'TEL'">아파트 연락처</a></h2>
                            <h2 class="title" :class="{ active : tab == 'MAX_PARKING' }"><a href="#" class="tit_tab" @click.prevent="tab = 'MAX_PARKING'">아파트 동별 기본차량</a></h2>
                            <!-- <input type="button" id="apt_setting_add" class="input_btn" value="추가" @click="showHomeBuildingCreateModal"/> -->
                        </div>
                        <div class="left_con">
                            <ul class="apt_tel" v-show="tab == 'TEL'">
                                <li>
                                    <input type="button" id="apt_contacts_add" class="input_btn" value="추가" @click="showHomeContactCreateModal" />   
                                    <ul class="sortHeader workHeader">
                                        <!-- <li class="checkBox">
                                            <div class="checkDiv">
                                                <input type="checkbox" />
                                                <label for=""></label>
                                            </div>
                                        </li> -->
                                        <li class="num"><span>No</span></li>
                                        <li class="apt1 sortOff"><span>상호명</span></li>
                                        <li class="carNum sortOff"><span>전화번호</span></li>
                                    </ul>

                                    <div class="noResult" :class="{ on : apartment_contact_list.length < 1 }" v-if="apartment_contact_list.length < 1">
                                        <p>저장된 연락처가 없습니다</p>
                                    </div>

                                    <div id="aptSet_list_scroll">
                                        <ul class="aptSetUl">
                                            <li :key="index" v-for="(contact, index) in apartment_contact_list">
                                                <!-- <div class="checkBox">
                                                    <div class="checkDiv">
                                                        <input type="checkbox" />
                                                        <label for=""></label>
                                                    </div>
                                                </div> -->
                                                <p class="num">{{ index + 1 }}</p>
                                                <p class="apt1">{{ contact.name }}</p>
                                                <p class="tel">{{ contact.tel }}</p>
                                                <a href="#" @click.prevent="showHomeContactCreateModal(contact)" class="tel_more">자세히 보기</a>
                                                <!-- <a href="#" @click.prevent="deleteBuilding(building)">삭제</a> -->
                                            </li>
                                            <!-- <li>
                                                <p class="num">1</p>
                                                <p class="apt1">100 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="">2 대</option>
                                                        <option value="" selected>3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>

                                                <p class="num">2</p>
                                                <p class="apt1">101 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="" selected>1 대</option>
                                                        <option value="">2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">3</p>
                                                <p class="apt1">102 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="" selected>1 대</option>
                                                        <option value="">2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">4</p>
                                                <p class="apt1">103 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="">2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">5</p>
                                                <p class="apt1">104 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="" selected>2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">6</p>
                                                <p class="apt1">105 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="" selected>2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">7</p>
                                                <p class="apt1">106 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="" selected>2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li> -->
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <ul class="apt_car" v-show="tab == 'MAX_PARKING'">
                                <li>
                                    <ul class="sortHeader workHeader">
                                        <!-- <li class="checkBox">
                                            <div class="checkDiv">
                                                <input type="checkbox" />
                                                <label for=""></label>
                                            </div>
                                        </li> -->
                                        <li class="num"><span>No</span></li>
                                        <li class="apt1"><span>동</span></li>
                                        <li class="carNum"><span>입주자 주차가능 차량</span></li>
                                    </ul>

                                    <div class="noResult" :class="{ on : apartment_building_list.length < 1 }" v-if="apartment_building_list.length < 1">
                                        <p>저장된 동이 없습니다</p>
                                    </div>

                                    <div id="aptSet_building_list_scroll">
                                        <ul class="aptSetUl">
                                            <li :key="building.name" v-for="(building, index) in apartment_building_list">
                                                <!-- <div class="checkBox">
                                                    <div class="checkDiv">
                                                        <input type="checkbox" />
                                                        <label for=""></label>
                                                    </div>
                                                </div> -->
                                                <p class="num">{{ index + 1 }}</p>
                                                <p class="apt1">{{ building.team_info.team_name }}</p>
                                                <div class="cars">
                                                    <select @change="updateBuilding(building, Number($event.target.value))"> 
                                                        <option :value="n" :key="n" v-for="n in 5" :selected="building.team_info.max_parking_number == n">{{ n }} 대</option>
                                                    </select>
                                                </div>
                                                <!-- <a href="#" @click.prevent="deleteBuilding(building)">삭제</a> -->
                                            </li>
                                            <!-- <li>
                                                <p class="num">1</p>
                                                <p class="apt1">100 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="">2 대</option>
                                                        <option value="" selected>3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>

                                                <p class="num">2</p>
                                                <p class="apt1">101 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="" selected>1 대</option>
                                                        <option value="">2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">3</p>
                                                <p class="apt1">102 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="" selected>1 대</option>
                                                        <option value="">2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">4</p>
                                                <p class="apt1">103 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="">2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">5</p>
                                                <p class="apt1">104 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="" selected>2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">6</p>
                                                <p class="apt1">105 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="" selected>2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li>
                                            <li>
                                                <p class="num">7</p>
                                                <p class="apt1">106 동</p>
                                                <div class="cars">
                                                    <select name="" id="">
                                                        <option value="">1 대</option>
                                                        <option value="" selected>2 대</option>
                                                        <option value="">3 대</option>
                                                        <option value="">4 대</option>
                                                    </select>
                                                </div>
                                                <a href="">삭제</a>
                                            </li> -->
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="rightScroll">
                        
                        <!-- <div class="apartment-contact">
                            <div class="titleDiv">
                                <p class="title">아파트 연락처</p>
                                <input type="button" id="apt_contacts_add" class="input_btn" value="추가" @click="showHomeContactCreateModal()" />
                            </div>
                            
                            <div class="telDiv scroll">
                                <div class="noResult" :class="{ on : apartment_contact_list.length < 1 }" v-if="apartment_contact_list.length < 1">
                                    <p>저장된 연락처가 없습니다.</p>
                                </div>
                                <ul class="telUl">
                                    <li :key="index" v-for="(contact, index) in apartment_contact_list">
                                        <p class="title">{{ contact.name }}</p>
                                        <p class="tel">{{ contact.tel }}</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal(contact)">자세히 보기</a>
                                    </li>

                                    <li>
                                        <p class="title">관리사무소</p>
                                        <p class="tel">02-0101-0100</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal">자세히 보기</a>
                                    </li>
                                    <li>
                                        <p class="title">게스트룸</p>
                                        <p class="tel">02-0101-0100</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal">자세히 보기</a>
                                    </li>
                                    <li>
                                        <p class="title">세미나실</p>
                                        <p class="tel">02-0101-0100</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal">자세히 보기</a>
                                    </li>
                                    <li>
                                        <p class="title">도시가스</p>
                                        <p class="tel">02-0101-0100</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal">자세히 보기</a>
                                    </li>
                                    <li>
                                        <p class="title">부녀회장</p>
                                        <p class="tel">02-0101-0100</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal">자세히 보기</a>
                                    </li>
                                    <li>
                                        <p class="title">도서관</p>
                                        <p class="tel">02-0101-0100</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal">자세히 보기</a>
                                    </li>
                                    <li>
                                        <p class="title">헬스장</p>
                                        <p class="tel">02-0101-0100</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal">자세히 보기</a>
                                    </li>
                                    <li>
                                        <p class="title">카페</p>
                                        <p class="tel">02-0101-0100</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal">자세히 보기</a>
                                    </li>
                                    <li>
                                        <p class="title">폐기물</p>
                                        <p class="tel">02-0101-0100</p>
                                        <a href="#" @click.prevent="showHomeContactCreateModal">자세히 보기</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                         -->
                        <div class="apartment-config">
                            <div class="titleDiv">
                                <p class="title">기능 설정</p>
                                <a href="#" class="config_btn" :class="{ on : is_right_menu_open == false }" @click.prevent="is_right_menu_open = !is_right_menu_open"><span class="hidden">내용보기 닫기</span></a>
                            </div>
                            
                            <div id="apartment-config-scroll" class="config_contents" :class="{ on : is_right_menu_open == false }">
                                <ul class="apartment-config-ul">
                                    
                                    <li>
                                        <p class="title">
                                            <span class="dot" />
                                            방문차량 자동 승인
                                        </p>
                                        <label class="switch">
                                            <input type="checkbox" v-model="auto_apply_appointment" @change="changeAutoApplyVistant"/>
                                            <span class="slider round"></span>
                                        </label>
                                    </li>

                                    <li>
                                        <p class="title">
                                            <span class="dot" />
                                            입주차량 승인 여부
                                        </p>
                                        <label class="switch">
                                            <input type="checkbox" v-model="resident_car_approve_enable" @change="changeResidentCarApply"/>
                                            <span class="slider round"></span>
                                        </label>
                                    </li>

                                    <li>
                                        <p class="title">
                                            <span class="dot" />
                                            건의함 사용
                                        </p>
                                        <label class="switch">
                                            <input type="checkbox" v-model="proposal_enable" @change="changeProposalEnable"/>
                                            <span class="slider round"></span>
                                        </label>
                                    </li>

                                    <li>
                                        <p class="title">
                                            <span class="dot" />
                                            메세지 사용
                                        </p>
                                        <label class="switch">
                                            <input type="checkbox" @change="premiumFeaturesOnOff($event, 'message')" :checked="messageChecked()">
                                            <span class="slider round"></span>
                                        </label>
                                    </li>

                                    <li>
                                        <p class="title">
                                            <span class="dot" />
                                            기본 차량 수
                                        </p>

                                        <select class="select" @change="changeMaxParkingNumber($event.target.value)"> 
                                            <option :value="n" :key="n" v-for="n in 5" :selected="n == default_max_parking_number">{{ n }} 대</option>
                                        </select>

                                    </li>

                                    <li>
                                        <p class="title">
                                            <span class="dot" />
                                            안내방송 목소리
                                        </p>

                                        <select class="select" v-model="tts_voice_number" @change="changeTtsVoiceNumber"> 
                                            <option value="1">여자1</option>
                                            <option value="2">여자2</option>
                                            <option value="3">남자1</option>
                                            <option value="4">남자2</option>
                                        </select>

                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        
    </div>
    <!-- 아파트 연락처 -->
    
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
}) export default class ApartmentSetting extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeBuildingCreateModalInfo ?: (params : hodu_home_modal_info.HomeBuildingCreateModalInfo) => void;
    @ModalInfo.Action doSetHomeContactCreateModalInfo  ?: (params : hodu_home_modal_info.HomeContactCreateModalInfo) => void;

    apartment_building_list : any[] = [];
    apartment_contact_list : any[] = [];

    auto_apply_appointment : boolean = false;
    resident_car_approve_enable : boolean = true;

    proposal_enable : boolean = true;
    default_max_parking_number : number = 2;

    tab : string = "TEL"; // TEL, MAX_PARKING
    is_right_menu_open = true;

    tts_voice_number : string = "1";

    mounted() : void {
        this.getHoduHomeInfo();

        // window['createBuilding'] = this.createBuilding;
        window['createHomeContact'] = this.createHomeContact;
        window['updateHomeContact'] = this.updateHomeContact;
        window['deleteHomeContact'] = this.deleteHomeContact;
        this.setScroll();

        //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.left_con > ul').hide();
        // 	$('.left_con > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });

        //메뉴 on/off
        // $('.config_btn').off("click").on("click", function(){
        // 	if($(".config_btn, #apartment-config-scroll, .leftScroll").hasClass("on")){
        //         $(".config_btn, #apartment-config-scroll, .leftScroll").removeClass("on");
        //     } else {
        //         $(".config_btn, #apartment-config-scroll, .leftScroll").addClass("on");
        //     }
        // });
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('.title_box').outerHeight();
        const titleDivHeight : number | undefined = $('.titleDiv').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();
        
        // const apartmentContactHeight : number | undefined = $('.apartment-contact').outerHeight();
        // const apartmentContactTitleDivHeight : number | undefined = $('.apartment-contact .titleDiv').outerHeight();

        const apartmentConfigHeight : number | undefined = $('.apartment-config').outerHeight();
        const apartmentConfigTitleDivHeight : number | undefined = $('.apartment-config .titleDiv').outerHeight();

        // @ts-ignore
        $('#aptSet_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (titleDivHeight ? titleDivHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });

        // @ts-ignore
        $('#aptSet_building_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (titleDivHeight ? titleDivHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });

        // @ts-ignore
        // $('.telDiv.scroll').mCustomScrollbar({
        //     axis : 'y',
        //     scrollbarPosition : 'outside',
        //     mouseWheelPixels : 100,
        //     scrollInertia : 60,
        //     autoDraggerLength : false,
        //     setHeight : (apartmentContactHeight ? apartmentContactHeight : 0) - (apartmentContactTitleDivHeight ? apartmentContactTitleDivHeight : 0) 
        // });

        // @ts-ignore
        $('#apartment-config-scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (titleDivHeight ? titleDivHeight : 0)
        });

    }

    /**
     * 호두홈 정보 조회
     */
    async getHoduHomeInfo() : Promise<void> {
        
        await this.hodu_api_call(`api/v1/home/info/${this.scope_group_team_option.biz_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                const home_info : any = response.data.data.home_info; 

                this.apartment_building_list.splice(0, this.apartment_building_list.length);

                if( home_info.building_info != null ) {
                    this.apartment_building_list = this.apartment_building_list.concat(home_info.building_info);
                }

                this.apartment_contact_list.splice(0, this.apartment_contact_list.length);
                
                if( home_info.company_info.home_contacts != null ) {
                    this.apartment_contact_list = this.apartment_contact_list.concat(home_info.company_info.home_contacts);
                }

                this.auto_apply_appointment      = home_info.company_info.auto_apply_appointment      != null ? home_info.company_info.auto_apply_appointment      : false;
                this.resident_car_approve_enable = home_info.company_info.resident_car_approve_enable != null ? home_info.company_info.resident_car_approve_enable : true;
                this.proposal_enable             = home_info.company_info.proposal_enable             != null ? home_info.company_info.proposal_enable             : true;
                this.default_max_parking_number  = home_info.company_info.max_parking_number          != null ? home_info.company_info.max_parking_number          : 2;   
                this.tts_voice_number            = home_info.company_info.tts_voice_number            != null ? home_info.company_info.tts_voice_number            : '1';  
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 호두홈 그룹 정보 수정
     */
    updateHoduHomeInfo(data : any) : void {

        this.hodu_api_call(`api/v1/home/info/${this.scope_group_team_option.biz_id}`, API_METHOD.PUT, data)
            .then((response) => {
                console.log(response);
                this.getHoduHomeInfo();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 동 추가 모달
     */
    showHomeBuildingCreateModal() : void {
        if( this.doSetHomeBuildingCreateModalInfo == null ) { return; }

        this.doSetHomeBuildingCreateModalInfo({
            show_modal : true
        });
    }

    /**
     * 동 추가
     */
    // async createBuilding(building : any) : Promise<void> {
    //     await this.getHoduHomeInfo();

    //     if( this.apartment_building_list.filter(item => item.key != building.key && item.name == building.name).length > 0 ) {
    //         this.hodu_show_dialog('alert', '이름이 중복되는 동이 있습니다', ['확인']);
    //         return;
    //     }

    //     this.apartment_building_list.push(building);
    //     this.updateHoduHomeInfo({ "max_parking_number" : this.apartment_building_list });
    // }

    /**
     * 동 최대 차량 수 수정
     */
    async updateBuilding(building : any, count : number) : Promise<void> {
        await this.getHoduHomeInfo();

        const target_list = this.apartment_building_list.filter(item => item.team_id == building.team_id);
        if( target_list.length < 1 ) {
            this.hodu_show_dialog('cancel', '수정 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        building = JSON.parse(JSON.stringify(target_list[0]));
        building.team_info.max_parking_number = count;

        let param_data = { team: building }

        await this.hodu_api_call(`api/v1/teams/${building.team_id}/${building.group_id}`, API_METHOD.PUT, param_data)
            .then(async(response) => {
                this.getHoduHomeInfo();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 동 삭제
     */
    async deleteBuilding(building : any) : Promise<void> {
        await this.getHoduHomeInfo();

        const target_list = this.apartment_building_list.filter(item => item.key == building.key);
        if( target_list.length < 1 ) {
            this.hodu_show_dialog('cancel', '삭제 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        const target_index = this.apartment_building_list.indexOf(target_list[0]);
        if( target_index < 0 ) {
            this.hodu_show_dialog('cancel', '삭제 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        this.apartment_building_list.splice(target_index, 1);
        this.updateHoduHomeInfo({ "max_parking_number" : this.apartment_building_list });
    }

    /**
     * 연락처 추가 모달
     */
    showHomeContactCreateModal(contact : any) : void {
        if( this.doSetHomeContactCreateModalInfo == null ) { return; }

        this.doSetHomeContactCreateModalInfo({
            show_modal : true,
            contact : contact ? contact : null
        });
    }

    /**
     * 연락처 추가
     */
    async createHomeContact(contact : any) : Promise<void> {
        await this.getHoduHomeInfo();

        if( this.apartment_contact_list.filter(item => item.key != contact.key && ( item.name == contact.name || item.tel.replace(/-/ig, '') == contact.tel.replace(/-/ig, '') ) ).length > 0 ) {
            this.hodu_show_dialog('alert', '이름이나 번호가 중복되는 연락처가 있습니다', ['확인']);
            return;
        }

        this.apartment_contact_list.push(contact);
        this.updateHoduHomeInfo({ "home_contacts" : this.apartment_contact_list });
    }   

    /**
     * 연락처 수정
     */
    async updateHomeContact(contact : any) : Promise<void> {
        await this.getHoduHomeInfo();

        if( this.apartment_contact_list.filter(item => item.key != contact.key && ( item.name == contact.name || item.tel.replace(/-/ig, '') == contact.tel.replace(/-/ig, '') ) ).length > 0 ) {
            this.hodu_show_dialog('alert', '이름이나 번호가 중복되는 연락처가 있습니다', ['확인']);
            return;
        }

        const target_list = this.apartment_contact_list.filter(item => item.key == contact.key)
        if( target_list.length < 1 ) {
            this.hodu_show_dialog('cancel', '연락처 수정 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        const target_index = this.apartment_contact_list.indexOf(target_list[0]);
        if( target_index < 0 ) {
            this.hodu_show_dialog('cancel', '연락처 수정 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        this.apartment_contact_list.splice(target_index, 1, contact);
        this.updateHoduHomeInfo({ "home_contacts" : this.apartment_contact_list });
    }

    /**
     * 연락처 삭제
     */
    async deleteHomeContact(key : string) : Promise<void> {
        await this.getHoduHomeInfo();

        const target_list = this.apartment_contact_list.filter(item => item.key == key)
        if( target_list.length < 1 ) {
            this.hodu_show_dialog('cancel', '연락처 삭제 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        const target_index = this.apartment_contact_list.indexOf(target_list[0]);
        if( target_index < 0 ) {
            this.hodu_show_dialog('cancel', '연락처 삭제 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        this.apartment_contact_list.splice(target_index, 1);
        this.updateHoduHomeInfo({ "home_contacts" : this.apartment_contact_list });
    }

    /**
     * 방문차량 자동 승인 여부 변경
     */
    changeAutoApplyVistant() : void {
        this.updateHoduHomeInfo({ "auto_apply_appointment" : this.auto_apply_appointment });
    }

    /**
     * 입주차량 승인 여부
     */
    changeResidentCarApply() : void {
        this.updateHoduHomeInfo({ "resident_car_approve_enable" : this.resident_car_approve_enable });
    }

    /**
     * 동 생성시 기본으로 들어갈 주차 가능 차량 수 변경
     */
    changeMaxParkingNumber(count : string) : void {
        this.updateHoduHomeInfo({ "max_parking_number" : Number(count) });
    }

    /**
     * 건의함 사용 여부 변경
     */
    changeProposalEnable() : void {
        this.updateHoduHomeInfo({ "proposal_enable" : this.proposal_enable });
    }

    /**
     * 안내방송 목소리 변경
     */
    changeTtsVoiceNumber() : void {
        this.updateHoduHomeInfo({ "tts_voice_number" : Number(this.tts_voice_number) });
    }   

    /**
     * 메세지 ON / OFF 여부
     */
    messageChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'message');
    }

    /**
     * 프리미엄 기능 ON / OFF
     */
    async premiumFeaturesOnOff(event : any, target : string) : Promise<void> {
        let featureOnOff : boolean = event.target.checked;

        // 그룹 기능 변경하는 API 호출 [(groups.ts : groupFeatureChange) - ( '/groups' : '/:group_id/features/:feature' )]
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/${target}?feature_flag=${featureOnOff}`, API_METHOD.PUT);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("프리미엄 기능 ON / OFF 중 오류 발생");
            }

            await this.get_group_role_service();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }     
            
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#aptSet_list_scroll").mCustomScrollbar('destroy');
        
        // @ts-ignore
        $("aptSet_building_list_scroll").mCustomScrollbar('destroy');
        
        // @ts-ignore
        // $('.telDiv.scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#apartment-config-scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
</script>

<style scoped>
    .noResult { position: static; padding: 20px 30px; height: auto !important; }
    .noResult p { background: #f1f3f5; line-height: 60px; height: 60px; color: #7c88a2; }
    
    .viewGroup .checkDiv { display: none; position: static !important; }
    .viewGroup .checkDiv label { margin-top: 16px; margin-left: 25px;}
    .sortHeader .checkDiv label { margin-top:21px; }

    
    .aptSetUl li { position: relative; transition:0.2s; overflow: hidden; width: 100%; line-height: 55px; height: 55px; box-sizing:border-box; border-bottom: 1px solid #f1f3f5; color:#202A39; }
    .aptSetUl li:hover { background:#f7f9fb;font-weight: bold; }
    .aptSetUl li > * { float: left; display: inline-block; padding-right: 15px;box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;}
    .aptSetUl li:hover a { width: 15px; }
    /* .aptSetUl li:hover a:hover { width: 35px; background-color:#ff3f3f; background: url('../../assets/images/contents/btn_del_fff.png') #ff3f3f center center no-repeat; background-size: 30px; }
    .aptSetUl li a { transition:0.1s; position: absolute; right: 0;top:0; width: 0; padding: 0; height: 100%; font-size:0; background-color: #ff6060; } */
    .section_ce_fix .num { width: 20%; text-align: center; }
    .section_ce_fix .aptSetUl .num { padding-right: 0; }
    .section_ce_fix .apt1 { width: 40%; }
    .section_ce_fix .carNum { width: 40%; }
    .section_ce_fix .aptSetUl li .cars select{ position: static !important; float: left; padding-left: 10px; font-size: 12px; font-weight: bold; line-height: 35px; height: 35px; background-color:#fff; padding-right: 40px; margin-right: 20px; margin-top: 9px; border:1px solid #e7e9ea; appearance: none; -moz-appearance: none; -webkit-appearance: none; cursor: pointer; border-radius: 5px;background: #fff url(../../assets/images/side_area/bt_open.png) no-repeat 90% center;}
    .section_ce_fix .aptSetUl li .cars select:hover, .section_ce_fix .aptSetUl li .cars select:focus { border-color:#477fff; }
    .section_ce_fix .aptSetUl li .cars select:focus { background-image:  url(../../assets/images/side_area/bt_close.png);}
    
    .content { position: relative; height: 900px }
    .leftScroll { box-sizing:border-box; width: 100%; padding-right: 380px; }
    .leftScroll .titleDiv { width:100%; line-height: 60px; height: 60px; box-sizing:border-box; border-bottom: 1px solid #e7e9ea; overflow: hidden; padding: 0 25px; }
    .leftScroll .titleDiv .title { font-weight: bold; font-size: 14px; vertical-align: middle;  }
    .leftScroll .titleDiv input { float: right; margin-top: 9px }
    .leftScroll .titleDiv * {display: inline-block; }

    .rightScroll { width: 380px; box-sizing:border-box; position: absolute;right:0; top:0; }
    .rightScroll .titleDiv { line-height: 60px; height: 60px; position: relative; border-bottom: 1px solid #e7e9ea; border-left: 1px solid #e7e9ea; box-sizing:border-box; overflow: hidden; padding: 0 25px; }
    .rightScroll .titleDiv .title { font-weight: bold; font-size: 14px; }
    .rightScroll .titleDiv input { float: right; margin-top: 9px}
    .rightScroll .titleDiv * {display: inline-block; }
    
    .telUl li { position: relative; line-height: 50px; height: 50px; padding:0 25px; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; }
    .telUl li:hover { background:#f7f9fb; }
    .telUl li .tel { font-weight: bold; float: right }
    .telUl li * {  display: inline-block; }
    .telUl li a {position: absolute; left: 0; top:0; width: 100%; height: 100%; font-size: 0; }
    .telUl li .title {width: 230px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}

    .aptSetDiv.on { display: block; }
    .aptSetDiv { display: none; z-index: 1000; position: absolute;right:20px; top: 70px; background: #fff; height: 100px; width: 300px;  box-shadow: 0 2px 10px rgba(0,0,0,0.15); }

    .apartment-config-ul li { overflow: hidden; -webkit-box-sizing: border-box; box-sizing: border-box; line-height: 50px; height: 50px; padding-left: 25px; border-bottom: 1px solid #f1f3f5; }
    .apartment-config-ul li .title { display: inline-block; font-weight: bold; font-size: 13px; } 
    .apartment-config-ul li .title .dot { display: block; float: left; width: 5px; height: 5px; border-radius: 50%; background: #232848; margin: 23px 10px 0 0; } 
    .apartment-config-ul li .switch { float: right; margin-right: 20px; margin-top: 12px; }
    .apartment-config-ul li .select { position: static !important; float: right; padding-left: 10px; font-size: 12px; font-weight: bold; line-height: 35px; height: 35px; background-color: #fff; padding-right: 40px; margin-right: 20px; margin-top: 9px; border: 1px solid #e7e9ea; appearance: none; -moz-appearance: none; -webkit-appearance: none; cursor: pointer; border-radius: 5px; background: #fff url(/img/bt_open.0927a4a6.png) no-repeat 90% center; }

    .rightScroll .apartment-contact { height : 55%; border-bottom : 1px solid #e7e9ea; overflow: hidden; }
    .rightScroll .apartment-config { height : 45%; }

    /* 21.04.16 추가 */
    .leftScroll .titleDiv .tit_tab { width: 125px; height: 35px; line-height: 35px; display: block; padding: 0 15px; text-align: center; }
    .leftScroll .titleDiv .active .tit_tab { width: 125px; height: 35px; line-height: 35px; display: block; border-radius: 30px; background: #2b3b5f; padding: 0 15px; text-align: center; color: #fff;}
    /* .apt_car { display: none; } */
    .apartment-config .config_contents { /* min-height: 900px; */ background: #fff; border-left: 1px solid #e7e9ea; transition: .2s }

    a.config_btn { position: absolute; margin-right: 20px; display: inline-block; width: 30px; height: 30px; background: url('../../assets/images/contents/ic_x_new.png') no-repeat center center; background-size: 22px 22px; background-color: #f1f3f5; border-radius: 50%; -webkit-box-sizing: border-box; box-sizing: border-box; right: 12px; top: 15px; transition: .2s; }
    a.config_btn:hover { background-color: #e5e7ea; }
    a.config_btn.on { position: absolute; margin-right: 20px; display: inline-block; width: 30px; height: 30px; background: url('../../assets/images/contents/bt_hambuger.png') no-repeat center center; background-size: 22px 22px; -webkit-box-sizing: border-box; box-sizing: border-box; right: 13px; top: 16px; transition: .2s; }
    .apartment-config .config_contents.on { display: none; }
    .leftScroll.on { padding-right: 0; }
    .leftScroll.on input#apt_contacts_add { margin-right: 300px; right: 160px; }

    .left_con { position: relative; }
    input#apt_contacts_add { position: fixed; top: 71px; right: 460px; margin-right: 0; }
    .aptSetUl li a.tel_more { position: absolute; left: 0; top: 0; width: 100%; height: 100%; font-size: 0; z-index: 3; }    
</style>