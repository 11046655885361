<template>
    <div id="hospital_off_setting_modal" class="modal">
        <div class="titleBox">
            <h1>휴원 등록</h1>
        </div>
        <div class="content">
            <div class="detail">

                <HoduInlineDatePicker
                    :event_bus="event_bus" 
                    :dp_id="'hospital_off_datepicker'" 
                    :dp_class="'datepickerDiv'"
                    :min_date="new Date()"
                    :other_month_selectable="true"
                    @select="() => {}"
                    @changeMonthYear="() => {}" 
                />
                
                <!-- datepickerDiv안에 datepicker넣기 -->
                <!-- <div class="datepickerDiv"> -->
                    <!-- 여기부턴 보여지기용 -->
                    <!-- <a href="" class="ui-datepicker-prev">이전</a><span class="yyyy">2020.04</span><a href="" class="ui-datepicker-next">다음</a>
                    <div class="daysAndDates">
                        <ul class="calUl">
                            <li class="dayList">
                                <ul class="day">
                                    <li>일</li>
                                    <li>월</li>
                                    <li>화</li>
                                    <li>수</li>
                                    <li>목</li>
                                    <li>금</li>
                                    <li>토</li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="off">29</a></li>
                                    <li><a class="off">30</a></li>
                                    <li><a class="off">31</a></li>
                                    <li><a class="off">1</a></li>
                                    <li><a class="off">2</a></li>
                                    <li><a class="off">3</a></li>
                                    <li><a class="off">4</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="off">5</a></li>
                                    <li><a class="off">6</a></li>
                                    <li><a class="off">7</a></li>
                                    <li><a class="off">8</a></li>
                                    <li><a class="off">9</a></li>
                                    <li><a class="off">10</a></li>
                                    <li><a class="off">11</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="off">12</a></li>
                                    <li><a class="off">13</a></li>
                                    <li><a class="on">14</a></li>
                                    <li><a>15</a></li>
                                    <li><a>16</a></li>
                                    <li><a>17</a></li>
                                    <li><a>18</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="off">19</a></li>
                                    <li><a>20</a></li>
                                    <li><a>21</a></li>
                                    <li><a>22</a></li>
                                    <li><a>23</a></li>
                                    <li><a>24</a></li>
                                    <li><a>25</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="off">26</a></li>
                                    <li><a>27</a></li>
                                    <li><a>28</a></li>
                                    <li><a>29</a></li>
                                    <li><a>30</a></li>
                                    <li><a class="nm">1</a></li>
                                    <li><a class="nm">2</a></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><a class="nm off">3</a></li>
                                    <li><a class="nm">4</a></li>
                                    <li><a class="nm">5</a></li>
                                    <li><a class="nm">6</a></li>
                                    <li><a class="nm">7</a></li>
                                    <li><a class="nm">8</a></li>
                                    <li><a class="nm">9</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div> -->
                <!-- </div> -->

                <div class="context">
                    <div class="timeDiv">
                        <div class="startDiv on">
                            <input type="button" class="on" value="2020.04.14 화요일  부터" @click="changeDate(new Date())">
                            <select name="" id="selAmPm">
                                <option value="">종일</option>
                                <option value="" selected>오전</option>
                                <option value="">오후</option>
                            </select>
                            <select name="" id="selHour">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                                <option value="">5</option>
                                <option value="">6</option>
                                <option value="">7</option>
                                <option value="">8</option>
                                <option value="" selected>9</option>
                                <option value="">10</option>
                                <option value="">11</option>
                                <option value="">12</option>
                            </select>
                            <select name="" id="selTime">
                                <option value="" selected>00</option>
                                <option value="">10</option>
                                <option value="">20</option>
                                <option value="">30</option>
                                <option value="">40</option>
                                <option value="">50</option>
                            </select>
                        </div>
                        <div class="endDiv">
                            <input type="button"  value="2020.04.15 수요일  까지" @click="changeDate(new Date())">
                            <select name="" id="selAmPm">
                                <option value="">종일</option>
                                <option value="">오전</option>
                                <option value="" selected>오후</option>
                            </select>
                            <select name="" id="selHour">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                                <option value="">5</option>
                                <option value="" selected>6</option>
                                <option value="">7</option>
                                <option value="">8</option>
                                <option value="">9</option>
                                <option value="">10</option>
                                <option value="">11</option>
                                <option value="">12</option>
                            </select>
                            <select name="" id="selTime">
                                <option value="" selected>00</option>
                                <option value="">10</option>
                                <option value="">20</option>
                                <option value="">30</option>
                                <option value="">40</option>
                                <option value="">50</option>
                            </select>
                        </div>
                    </div>
                    <div class="detailDiv">
                        <label for="">휴원명</label>
                        <input type="text" placeholder="달력에서 보여질 제목을 작성하세요" />
                        <label for="">내용</label>
                        <textarea name="" id="" placeholder="휴원 이유를 작성하세요"></textarea>
                    </div>
                </div>
            </div>
            <div class="btns">
                <input type="button" value="취소" />
                <input type="button" class="save" value="저장" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue'

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HoduInlineDatePicker
    },
}) 
export default class HospitalOffSettingModal extends Mixins(VueHoduCommon) {

    event_bus : Vue = new Vue();

    mounted() : void {
        // 오른쪽 시간선택시 
        $(".timeDiv div input").click(function(){
            $(this).parent().siblings().removeClass("on");
            $(this).parent().addClass("on");
            $(this).parent().siblings().find("input").removeClass("on");
            $(this).addClass("on");
        });

    }

    /**
     * 탭 변경으로 왼쪽 데이트피커 날짜 변경
     */
    changeDate(date : Date | string) : void {
        date = date instanceof Date ? date : new Date(date);
        console.log(this.event_bus);
        this.event_bus.$emit('changeDate', date);
    }

}
</script>

<style>
    .datepickerDiv { float:left; width: 40%; height: 368px; padding: 25px 25px; box-sizing: border-box; text-align: center; }
    .datepickerDiv .ui-datepicker-prev, .ui-datepicker-next {font-size:0;display: inline-block;width: 25px;height: 25px;text-align: center;cursor: pointer;background-image: url('../../../assets/images/contents/month_bt_prev.svg');background-repeat: no-repeat;line-height:25px;background-size: 22px;overflow: hidden;}
    .datepickerDiv .ui-datepicker-prev {float:left;background-position: center center;}
    .datepickerDiv .ui-datepicker-next { float:right; background-position: center center;}
    .datepickerDiv .ui-datepicker-prev:hover {background-image: url('../../../assets/images/contents/month_bt_prev_on.svg');}
    .datepickerDiv .ui-datepicker-next:hover {background-image: url('../../../assets/images/contents/month_bt_next_on.svg');}
    .datepickerDiv a.ui-datepicker-next {margin-left:5px;background-image: url('../../../assets/images/contents/month_bt_next.svg');}
    .datepickerDiv .yyyy { font-size: 16px; font-weight:bold; margin-bottom: 20px; margin-top:6px; display: inline-block; }
</style>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-265px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:530px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    .detail { overflow:hidden; }

    .calUl .day li { height: 20px; line-height: 20px; }
    .calUl .dayList { height:20px; }
    .calUl > li { display: block; height: 40px;line-height: 40px; }
    .calUl ul  { overflow: hidden; }
    .calUl ul li { float:left; text-align: center; display: inline-block; width:14.2857142857%;}
    .calUl ul li a { font-weight: bold; display:inline-block; width: 30px; height:30px;line-height: 30px; transition:0.2s; border-radius:50%;vertical-align: middle; }
    .calUl ul li a:hover { background:#f1f3f5; }
    .calUl ul li a.on { background:#477fff !important; color:#fff; }
    .calUl ul li a.nm { opacity:0.5 }
    .calUl ul li a.off { opacity:0.3; font-weight: normal; }
    .calUl ul li a.off:hover { background:transparent }
    

    .context { float:left; border-left:1px solid #e7e9ea; width: 60%; height:368px; box-sizing: border-box; }
    
    .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }

    .timeDiv { overflow: hidden; }
    .startDiv, .endDiv { overflow:hidden; width:50%; height: 100px; float:left; }
    .startDiv { border-right:1px solid #477fff; box-sizing: border-box; }
    .startDiv.on select, .endDiv.on select { font-weight:bold; } 
    .startDiv select, .endDiv select { cursor:pointer; box-sizing: border-box; transition: 0.2s; float:left; width: 33.3333333333%; appearance: none; padding: 0 15px;line-height:50px;background-image:url('../../../assets/images/side_area/bt_open.png'); background-position: 95%  center; background-repeat: no-repeat; height: 50px; border-right:1px solid #f1f3f5; border-bottom:1px solid #e7e9ea;}
    .startDiv select:last-child, .endDiv select:last-child { border-right:0; }
    
    .timeDiv select:hover { background-color:#f1f3f5; }
    .timeDiv select:focus { border-bottom:1px solid #477fff; background-color:#fff;background-image:url('../../../assets/images/side_area/bt_close.png'); }
    .timeDiv input[type="button"] { border:none; border-bottom:1px solid #e7e9ea; cursor:pointer;transition:0.2s; background:#fff; font-weight: normal; padding: 0 15px;height: 50px; line-height: 50px; width: 100%; box-sizing: border-box;  }
    .timeDiv input[type="button"]:hover { background:#f1f3f5;border-color:#477fff; }
    .timeDiv input[type="button"]:focus, .timeDiv input[type="button"].on { background-color:#477fff !important; border-color:#477fff; color:#fff; font-weight: bold;}
    
    .detailDiv { padding: 20px 30px 0; }
    .detailDiv label { height: 25px; display:block; }
    input[type="text"] { margin-bottom:20px; transition:0.2s; font-size:14px; font-weight: bold; padding: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; }
    input[type="text"]:hover { background:#f1f3f5; }
    input[type="text"]:focus { border-color:#477fff; background:#fff; }

    textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 100px; font-size: 13px;line-height: 20px; padding: 15px; height:auto; box-sizing: border-box; font-weight:bold; }
    textarea:hover { border-color:#477fff }
    textarea:focus { background:#fff;  }
</style>