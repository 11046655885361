<template>
    <div id="group_notice_detail" class="section_ce_fix">
        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">공지사항 상세보기</h3>
            <p class="btn_group" style="">
                <!--<input type="button" id="" name="" value="저장" class="input_btn">-->
                <input type="button" id="" name="" value="수정" class="input_btn" v-if="is_modify" @click="actionModify()">
                <!--<input type="button" id="" name="" value="취소" class="input_btn">-->
                <input type="button" id="" name="" value="삭제" class="input_btn" v-if="is_delete" @click="actionDelete()">
            </p>
        </div>
        <div class="content notice">
            <div class="grp">
                <div class="when">
                    <label for="noti_datepicker">공지 등록일</label>
                    <input type="button" id="noti_datepicker" :value="start_date" class="input_txt" style="" readonly/>
                </div>
                <!--<div class="share">
                     그룹에서 공지 등록시 : 모든 팀과 공유하시겠습니까? 
                    <h4>{{team_id > 0 ? '그룹' : '팀'}}에 공유하시겠습니까?</h4>
                    <p>
                        <input type="checkbox" id="shareNoti" class="input_check">
                        <label for="shareNoti"><span><em></em>에 공유</span></label>
                    </p>
                </div>-->
                <!-- 상세에서 보여지는 share div -->
                <div class="share">
                    <!-- 등록때는 공유정보, 상세에선 작성자 -->
                    <h4>작성자</h4>
                    <p class="whoWrote"><span class="fPic" :style="{backgroundImage: `url('${audit_user_pic}')`}">사진</span>{{ audit_user_name && audit_user_name.length > 0 ? audit_user_name : '-' }}</p>
                </div>
            </div>
            <div class="editArea">
                <input type="text" id="noti_title" class="input_txt " placeholder="제목을 입력하세요." :value="title" disabled/>
                <!-- 세로값 리사이즈시 height변경 (-218px + section_ce) notificationTemp4.js -->
                <div class="edtContentDiv">
                    <!-- 세로값 리사이즈시 height변경 (-332px + section_ce)  notificationTemp4.js -->
                    <!-- <textarea id="modal_noti_contents" name="" cols="" rows="5" class="input_textarea" placeholder="공지 내용을 작성하세요" :value="body" disabled></textarea> -->
                    <div id="modal_noti_contents" v-html="body"></div>
                </div>
            </div>
        </div>

        <!-- 첨부파일 팝업 -->
        <div class="filePopup" :class="{ on : file_popup_open == true }">
            <h4 class="blind">첨부파일 팝업</h4>
            <a href="#" class="allDown_btn" @click.prevent="allDownload">전체 다운로드</a>
            <button type="button" class="filePopup_close" @click.prevent="file_popup_open = false">닫기</button>
            <div class="attFileDiv">
                <ul class="attFileUl">
                    <li :key="file.url" v-for="file in files">
                        <p class="attFile_icon">아이콘</p>
                        <p class="attFile_title" :title="file.name">{{ file.name }}</p>
                        <a href="#" class="fileDown_btn" @click.prevent="fileDownload(file)">PC저장</a>
                    </li>
                    <!-- <li>
                        <p class="attFile_icon">아이콘</p>
                        <p class="attFile_title" title="hoduware_introduction.pdf">hoduware_introduction.pdf</p>
                        <a href="#" class="fileDown_btn">PC저장</a>
                    </li>
                    <li>
                        <p class="attFile_icon">아이콘</p>
                        <p class="attFile_title" title="hoduware_introduction.pdf">hoduware_introduction.pdf</p>
                        <a href="#" class="fileDown_btn">PC저장</a>
                    </li> -->
                </ul>
            </div>
        </div>
        <!-- //첨부파일 팝업 -->

        <div class="notice_comment" v-if="noticeReplyChecked() == true || (o_notice != null && o_notice.noti_info.files != null && o_notice.noti_info.files.length > 0)">
            <input type="button" value="댓글" class="cmmntBtn" :class="{ 
                on : o_notice != null && o_notice.event_reply_id != null && o_notice.event_reply_id.length > 0,
                alone : !(o_notice != null && o_notice.noti_info.files != null && o_notice.noti_info.files.length > 0)
            }" @click.prevent="showNoticeReply" v-if="noticeReplyChecked() == true">
            <button class="attachedBtn" @click.prevent="file_popup_open = !file_popup_open" v-if="o_notice != null && o_notice.noti_info.files != null && o_notice.noti_info.files.length > 0">첨부파일 <span>({{ o_notice.noti_info.files == null ? 0 : o_notice.noti_info.files.length }})</span></button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');

import { GroupNoticeReplyModalInfo } from '@/store/modules/ModalInfo';

import { t_event_file } from '@/model/event';

import JSZip from 'jszip';

const dateFormat = require('dateformat');

declare var CKEDITOR: any;

@Component({
    components : {
    }
})
export default class GroupNoticeDetail extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupNoticeReplyModalInfo ?: (params : GroupNoticeReplyModalInfo) => void;

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * 변수 선언부
     */
    noti_uid        : string  = "";
    o_notice        : any     = null;
    start_date      : string  = "";
    title           : string  = "";
    body            : string  = "";
    is_modify       : boolean = false;
    is_delete       : boolean = false;
    body_type       : string  = "";
    audit_user_name : string  = "";
    audit_user_pic  : string  = "";

    file_popup_open : boolean = false;

    interval : number | undefined;

    files : any[] = [];

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() : Promise<void> {
        if ( !this.group_team_option.noti_uid ) {
            alert('uid 가 잘못되었습니다');
            this.movePrevPage();
            return;
        }

        this.noti_uid = this.group_team_option.noti_uid;

        // 공지 상세
        this.getNoticeDetatil();

        this.interval = window.setInterval(() => { $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 390)); }, 1000);

        //파일첨부 팝업
        // $('.attachedBtn').click(function(){
        //     $('.filePopup').fadeIn(200);
        //     return false;
        // })
        // $('.filePopup button.filePopup_close').click(function(){
        //     $('.filePopup').fadeOut()
        // })
    }

    beforeDestroy() {
        if( this.interval ) window.clearInterval(this.interval);
    }

    /**
     * 공지사항 상세화면 조회
     */
    async getNoticeDetatil() : Promise<void> {
        const vue = this;

        this.is_modify = false;
        this.is_delete = false;

        $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 390));

        await this.hodu_api_call(`api/v1/groupsweb/getNoticeDetail/${this.group_id}/${this.team_id}?uid=${this.noti_uid}`, API_METHOD.GET, {}, false)
            .then(async(response) => {
                // 데이터가 없을경우
                if ( !response.data.data.noti_data ) {
                    if ( vue.group_team_option.noti_uid ) {
                        delete vue.group_team_option.noti_uid;
                    }

                    vue.movePrevPage();
                    return;
                }
                else {
                    this.o_notice        = response.data.data.noti_data;
                    this.title           = this.o_notice.noti_info.title;
                    this.body            = this.o_notice.noti_data.body.replace(/\n/g, "<br>");
                    this.start_date      = dateFormat(this.o_notice.noti_info.start_date, 'yyyy.mm.dd');
                    this.body_type       = this.o_notice.noti_data.body_type;
                    this.audit_user_name = this.o_notice.audit_user_name;

                    if( this.o_notice.noti_info.files ) {
                        this.files = this.files.concat(this.o_notice.noti_info.files);
                    }

                    if ( this.o_notice.audit_user_pic ) {
                        this.audit_user_pic = '/app_images/' + this.o_notice.audit_user_pic;
                    }
                    else {
                        this.audit_user_pic = require("@/assets/images/contents/im_photoB.gif");
                    }

                    if ( this.group_id > 0 && this.team_id > 0 ) {
                        if ( this.o_notice.team_id == this.team_id ) {
                            this.is_modify = this.is_team_permmision(this.team_id, "notice", "modify");
                            this.is_delete = this.is_team_permmision(this.team_id, "notice", "delete");
                        }
                    }
                    else if ( this.group_id > 0 && this.team_id == 0 ) {
                        if ( this.o_notice.team_id == 0 && this.o_notice.group_id == this.group_id ) { 
                            this.is_modify = this.is_group_permmision(this.group_id, "notice", "modify");
                            this.is_delete = this.is_group_permmision(this.group_id, "notice", "delete");
                        }
                    }
                }
            })
            .catch(async(e) => {

                if ( vue.group_team_option.noti_uid ) {
                    delete vue.group_team_option.noti_uid;
                }

                this.hodu_error_process(e, true, true);
            });        
    }

    /**
     * 공지사항 수정
     */
    actionModify() : void { 
        if ( this.body_type == "HTML" || this.body_type == "TEXT" ) {
            this.doGroupId(this.group_id);
            this.doTeamId(this.team_id);
            this.group_team_option.noti_uid = this.noti_uid;

            this.doGroupTeamOption(this.group_team_option);
            this.body_type == "HTML" ? this.hodu_router_push(`/group/${new Date().getTime()}/notice_create_editor`) : 
                                       this.hodu_router_push(`/group/${new Date().getTime()}/notice_create`)
        }
    }
    
    /**
     * 공지사항 삭제
     */
    async actionDelete() : Promise<void> { 
        const vue = this;

        this.hodu_show_dialog("alert", "공지사항을 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async () => {
                let notiType = (this.team_id > 0 ? "TEAM" : "GROUP");

                await this.hodu_api_call(`api/v1/notification/notice/${this.noti_uid}/${notiType}/${this.group_id}/${this.team_id}`, API_METHOD.DELETE, {})
                    .then(async(response) => {
                        if ( vue.group_team_option.noti_uid ) {
                            delete vue.group_team_option.noti_uid;
                        }

                        vue.movePrevPage();
                    })
                    .catch(async(e) => {
                        if ( vue.group_team_option.noti_uid ) {
                            delete vue.group_team_option.noti_uid;
                        }

                        this.hodu_error_process(e, true, true);
                    });    
            },
        ]);
    }

    /**
     * 공지사항 댓글
     */
    showNoticeReply() : void {
        this.doSetGroupNoticeReplyModalInfo?.({
            show_modal : true,
            noti_uid : this.noti_uid,
            callback : this.getNoticeDetatil
        });
    }

    /**
     * 공지사항 댓글 기능
     */
    noticeReplyChecked() : boolean {        
        const feature = this.get_group_feature(this.group_id);

        if( feature == null || feature.notice == null || feature.notice.enable_group_comment == null ) return false;

        return feature.notice.enable_group_comment;
    }

    /**
     * 파일 다운로드 (조회)
     */
    fileDownload(file : t_event_file) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 파일 전체 다운로드
     */
    async allDownload() : Promise<void> {
        
        const selected_files : t_event_file[] = this.files;

        if( selected_files.length < 1 ) {
            this.hodu_show_dialog('alert', '다운로드 할 파일을 선택해주세요', ["확인"], [() => {}]);
            return;
        }

        // 파일 1개라면
        if( selected_files.length == 1 ) {
            this.fileDownload(selected_files[0]);
            return;
        }

        // 파일 여러개는 zip 파일로 받는다

        await this.hodu_show_indicator();

        const zip = new JSZip();

        const file_promise : Promise<void>[] = [];

        for( const file of selected_files ) {

            // 파일 다운로드 Promise
            const promise = this.hodu_download(`/app_images/${file.url}`, "", true)
                .then((response) => {
                    console.log(response);

                    // 다운로드한 파일은 zip 파일 구성에 추가
                    zip.file(file.name, new Blob([response.data]));
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });

            // 병렬처리용 Promise 배열에 넣기
            file_promise.push(promise);
        }

        Promise.all(file_promise)
            .then(async() => {

                // zip 파일 압축
                await zip.generateAsync({type:"blob"}).then((content) => {
                    
                    // 압축 완료시 나오는 blob으로 다운로드
                    try {
                        this.hodu_download_blob(content, `hodu_download_${new Date().getTime()}.zip`);
                    } catch(e) {
                        this.hodu_error_process(e, true, false);
                    }

                });
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            }).finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

}
</script>

<style scoped>
    .title_box { line-height:61px; height:61px; }
    .notice.content .grp > div { display:inline-block; float:left; height:80px;padding: 0 25px;box-sizing: border-box; }
    .notice.content .grp > div > * {display: block; }
    .notice.content .grp > div > label, .notice.content .grp > div h4 { width:100%; color:#7c88a2; font-size:13px; font-weight:bold;margin-top:5px; line-height:40px;height:30px; float:left; }
    .notice.content .grp {height: 82px;border-bottom: 2px solid #e7e9ea;padding: 0 px;box-sizing: border-box;width: 100%;border-right: 1px solid #e7e9ea;}
    .notice.content .editArea {margin-top: 0;border-right: 1px solid #e7e9ea;}
    #noti_title { transition:0.2s; font-weight:bold; line-height: 70px; height: 70px;padding:0 20px; box-sizing:border-box; width:100%;font-size:18px;border-bottom:2px solid #e7e9ea;}
    #noti_title:hover { background:#f1f3f5; }
    #noti_title:focus { border-bottom:2px solid #477fff; background:#fff; }
    #noti_title:disabled {background: #fff;color: #273044;}
    #noti_datepicker { font-size:15px; font-weight: bold;}
    #noti_datepicker:disabled { color:#273044 !important; cursor:default; }
    .notice .when { border-right:1px solid #e7e9ea; width:50%;}
    .notice .share { padding:0 30px; position:relative; }
    .notice .share p { height: 37px;line-height: 37px; margin-top: 5px; }
    .notice .when input { height: 33px;line-height: 33px;width: 100%;text-align: left; }
    .notice .when input:hover, .notice .when input:focus { color:#273044; cursor:default }

    .notice .share span.fPic {left: 24px;top: 39px;border: 1px solid #e7e9ea;background-color: #f1f3f5;font-size: 0;min-width: 27px !important;width: 27px !important;background-position: center center !important;background-size: cover !important;height: 27px !important;border-radius: 50%;}
    .notice .share .whoWrote {padding-left: 40px;font-weight: bold;font-size: 14px;}

    .notice input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
    .notice input[type='checkbox']:not(old) + label span { color:#7c88a2; }
    .notice input[type='checkbox']:not(old) + label span em { color:#477fff; font-style:normal; }
    .notice .share span{position: absolute;left: 55px;width: 100% !important;height: 30px !important;max-width: 200px !important;min-width: 100px !important;z-index: 1000000;top: 36px;line-height: 30px;font-size: 14px;font-weight: bold;}
    .notice .share input[type='checkbox']:not(old):checked + label {background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;border: 2px solid #477fff;}
    .notice .share input[type='checkbox']:not(old):checked + label span { color:#273044; }
    .content.notice {width: 100%; max-width: 100%; min-width: 700px;}
    .left_area_close .content.notice {padding-left: 61px !important;}

    .notice_comment { border-top: 1px solid #e7e9ea; padding: 0 25px; }
    .notice_comment input.cmmntBtn { float: left; }
    .notice_comment input.cmmntBtn.alone { float: none; }
  
    #group_notice_detail ::-webkit-scrollbar{width: 6px;}
    #group_notice_detail ::-webkit-scrollbar-track{background-color: transparent;}
    #group_notice_detail ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

</style>

<style>

    .cke_chrome { border:0 none; }
    .cke_top { background:#f1f3f5; border-bottom:0 none; }
    .cke_bottom { border-top: 0 none;background: #f1f3f5; padding:0; line-height:40px; height:40px; }
    .cke_resizer { display:none; }
    #modal_noti_contents { transition:0.1s;line-height: 23px;font-size: 14px;padding: 25px 30px 50px !important;text-align: left; overflow-y:auto; }
    .attachedBtn { font-weight: bold; padding: 10px 30px; border-radius: 50px; border: 1px solid #e3e7ed; background: #fff; display: block; margin-top: 30px; color: #27313f; cursor: pointer; float: right; transition: .2s; }
    .attachedBtn:hover { background: #477fff; border: 1px solid #477fff; color: #fff; box-shadow: 0 5px 10px rgb(71 127 255 / 20%); }
    .attachedBtn:hover span { color: #fff; }
    .attachedBtn:before { content: '';  width: 18px; height: 18px; display: inline-block; background: url('../assets/images/contents/ic_file_bk.png') no-repeat center; background-size: 18px auto; vertical-align: top; margin: 1px 4px 0 0; }
    .attachedBtn:hover:before { content: '';background-image: url('../assets/images/contents/ic_file_wt.png'); }

    .filePopup { width: 270px; height: 220px; position: absolute; bottom: 82px; right: 35px; border: 2px solid #e7e9ea; background: #fff; text-align: right; padding: 15px; box-sizing: border-box; display: none; }
    .filePopup.on { display : block; }
    .filePopup .allDown_btn:after { content: ''; width: 2px; height: 13px; display: inline-block; background: #f1f3f5; vertical-align: middle; margin-left: 20px; }
    .filePopup .allDown_btn:hover { font-weight: bold; }
    .filePopup_close { background: none; border: none; margin-left: 20px; font-size: 12px; cursor: pointer; }
    .attFileDiv { margin-top: 12px }
    .attFileDiv .attFileUl li { box-sizing: border-box; position: relative; width: 100%; height: 30px; line-height: 30px; }
    .attFileDiv .attFileUl p { display: inline-block; float: left; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .attFileDiv .attFileUl p.attFile_icon { font-size: 0; background: url('../assets/images/contents/ic_file_bk.png') no-repeat center; width: 40px; background-size: 17px auto; display: inline-block; vertical-align: middle; }
    .attFileDiv .attFileUl p.attFile_title { width: 150px; font-weight: bold; text-align: left; }
</style>