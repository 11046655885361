<template>
    <div id="home_visitant_amano_info_modal" class="modal" v-if="car != null">

        <div class="titlebox">
            <!-- 예정 visitExpected / 입차 visitEntry / 출차 visitExit -->   
            <h1 :class="{
                visitExpected : car.state == 'EXPECTED',
                visitEntry : car.state == 'IN',
                visitExit : car.state == 'OUT'
            }">{{ getCarStateText(car.state) }}</h1>
        </div>

        <!-- 예정에는 예약일시만, 입차일때는 예약일시/입차일시, 출차일때는 3가지 다 시간 나오게 해주세요 -->
        <div class="modal_content">
            <ul>
                <li>
                    <p class="title">동&amp;호수</p>
                    <p class="descript">{{ (car.dongcode && car.dongcode.length > 0 && car.hocode && car.hocode.length > 0) ? `${car.dongcode}동 ${car.hocode}호` : '-' }}</p>
                </li>
                <li>
                    <p class="title">차량번호</p>
                    <p class="descript">{{ car.carNo }}</p>
                </li>
                <li>
                    <p class="title">예약일시</p>
                    <p class="descript">{{ getVisitDate(car.startDtm) }} <span>{{ getVisitAmPm(car.startDtm) }}</span> {{ getVisitTime(car.startDtm) }}</p>
                </li>
                <li>
                    <p class="title">입차일시</p>
                    <p class="descript" v-if="car.inDtm && car.inDtm.length > 0">
                        {{ getVisitDate(car.inDtm) }} <span>{{ getVisitAmPm(car.inDtm) }}</span> {{ getVisitTime(car.inDtm) }}
                    </p>
                    <p class="descript" v-else>-</p>
                </li>
                <li>
                    <p class="title">출차일시</p>
                    <p class="descript" v-if="car.outDtm && car.outDtm.length > 0">
                        {{ getVisitDate(car.outDtm) }} <span>{{ getVisitAmPm(car.outDtm) }}</span> {{ getVisitTime(car.outDtm) }}
                    </p>
                    <p class="descript" v-else>-</p>
                </li>
                <li>
                    <p class="title">방문기간</p>
                    <p class="descript">{{ getVisitPeriod(car) }}</p>
                </li>
            </ul>
        </div>

        <div class="btns">
            <input type="button" value="확인" @click.prevent="close">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import moment from 'moment';

@Component({
    components: {
        
    },
})
export default class HomeVisitantAmanoInfoModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_visitant_amano_info_modal_info !: hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeVisitantAmanoInfoModalInfo ?: (params : hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo) => void;

    car : any = null;

    mounted() : void {
        this.car = JSON.parse(JSON.stringify(this.home_visitant_amano_info_modal_info.amano_info.car));
    }

    /**
     * 차량 상태값 텍스트 반환
     */
    getCarStateText(state) : string {

        switch(state) {
            case "EXPECTED": return "예정";
            case "IN": return "입차";
            case "OUT": return "출차";
        }

        return '';
    }

    /**
     * 방문 예정 날짜
     */
    getVisitDate(dtm) : string {
        const date = new Date([dtm.substring(0,4), dtm.substring(4,6), dtm.substring(6,8)].join('-'));
        return `${this.hodu_date_to_format_string(date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
    }

    /**
     * 방문 예정 날짜 오전/오후
     */
    getVisitAmPm(dtm) : string {
        const hour = Number(dtm.substring(8,10));

        return hour >= 0 && hour <= 11 ? "오전" : "오후";
    }

    /**
     * 방문 예정 날짜 시간
     */
    getVisitTime(dtm) : string {

        const hour = Number(dtm.substring(8,10));
        const min = Number(dtm.substring(10,12));

        const is_am = hour >= 0 && hour <= 11;

        let hour_min = "";
        if( is_am && hour == 0 ) {
            hour_min = `12:${`0${min}`.slice(-2)}`;
        }
        else if( is_am || (!is_am && hour == 12) ) {
            hour_min = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)}`;
        }
        else {
            hour_min = `${`0${hour - 12}`.slice(-2)}:${`0${min}`.slice(-2)}`;
        }

        return hour_min;
    }

    /**
     * 방문기간
     */
    getVisitPeriod(car) : string {
        const start = car.startDtm;
        const end = car.endDtm;

        const start_yyyy_mm_dd = [start.substring(0,4), start.substring(4,6), start.substring(6,8)].join('-');
        const start_hh_mm_ss = [start.substring(8,10), start.substring(10,12), start.substring(12,14)].join(':');

        const end_yyyy_mm_dd = [end.substring(0,4), end.substring(4,6), end.substring(6,8)].join('-');
        const end_hh_mm_ss = [end.substring(8,10), end.substring(10,12), end.substring(12,14)].join(':');

        const start_date = moment(`${start_yyyy_mm_dd} ${start_hh_mm_ss}`).toDate();
        const end_date = moment(`${end_yyyy_mm_dd} ${end_hh_mm_ss}`).toDate();

        const period_ms = end_date.getTime() - start_date.getTime();

        // 시간 차이 60분(1시간) 미만
        if( period_ms < 60 * 60 * 1000 ) {
            return `${Math.floor(period_ms / 60 / 1000)}분`;
        }

        // 시간 차이 24시간(1일) 미만
        if( period_ms < 24 * 60 * 60 * 1000 ) {
            return `${Math.floor(period_ms / 60 / 60 / 1000)}시간`;
        }

        // 시간 차이 24시간(1일) 이상
        return `${Math.floor(period_ms / 24 / 60 / 60 / 1000)}일`;
    }

    /**
     * 닫기
     */
    close() : void {
        this.doSetHomeVisitantAmanoInfoModalInfo?.({
            show_modal : false,
            amano_info : null
        })
    }

}
</script>

<style scoped>
    #home_visitant_amano_info_modal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-215px; margin-left:-260px; width:520px; height: auto; background:#fff; }
    #home_visitant_amano_info_modal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0; }

    #home_visitant_amano_info_modal .titlebox h1 { font-size: 20px; font-weight: bold; width: 100%; height: 80px; line-height: 90px; text-align: center; }
    #home_visitant_amano_info_modal .titlebox h1.visitExpected { background:#f1f3f5; color:#7c88a2; }
    #home_visitant_amano_info_modal .titlebox h1.visitEntry { background:#477fff; color:#fff; }
    #home_visitant_amano_info_modal .titlebox h1.visitExit { background:#ff6363; color:#fff; }

    #home_visitant_amano_info_modal .modal_content { overflow: hidden; height: auto; border-bottom: 1px solid #e7e9ea;  }
    .modal .modal_content ul li { padding: 0 30px; overflow: hidden; line-height: 55px; height: 55px; border-bottom: 1px solid #f1f3f5; }
    .modal .modal_content ul li:last-child { border-bottom: 0px none; }
    .modal .modal_content ul li p { display: inline-block; font-size: 13px; }
    .modal .modal_content ul li p.descript { float: right; text-align: right; font-weight: bold; }
    
    #home_visitant_amano_info_modal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    #home_visitant_amano_info_modal .btns input { transition:0.2s; width: 100%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold; background: #fff; }
    #home_visitant_amano_info_modal .btns input:hover { background:#f1f3f5; }
    
</style>