<template>
    <div id="customerSettingDiv" class="customerSettingDiv section_ce_fix">
        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">환경설정</h3>
        </div>
        <div id="grpScroll" class="section_scroll"><!--스크롤 생겨야할 영역-->
            <div class="viewGroup">
                <div class="schedule_box">
                    <!-- <div class="grp settingBg">
                        
                        
                    </div> -->
                    <div class="content" id="csStngDiv">
                        <!-- 일반 세팅 -->
                        <div class="basicDiv">
                            <div class="title_div">
                                <p class="title_btn">일반</p>
                                <div class="title_btn_box">
                                    <a href="#" class="input_btn">초기화</a>
                                    <a href="#" class="input_btn">저장</a>
                                </div>
                            </div>
                            <!-- 첫번째 -->
                            <div class="firstSet setBox">
                                <p class="title">기본 세팅</p>
                                <dl>
                                    <dt><label for="chooseLang" class="posAb">언어</label></dt>
                                    <dd>
                                        <select id="chooseLang">
                                            <option value="">한국어</option>
                                            <option value="">日本語</option>
                                            <option value="">English</option>
                                        </select>
                                    </dd>
                                    <dt><label for="chooseCountry" class="posAb">나라</label></dt>
                                    <dd>
                                        <select id="chooseCountry">
                                            <option value="">한국</option>
                                            <option value="">일본</option>
                                            <option value="">미국</option>
                                        </select>
                                    </dd>
                                    <dt><label for="chooseTime" class="posAb">시간</label></dt>
                                    <dd>
                                        <select id="chooseTime">
                                            <option value="">(GMT+09:00) 한국 표준시 - 서울</option>
                                            <option value="">(GMT+09:00) 한국 표준시 - 평양</option>
                                            <option value="">(GMT+09:30) 오스트레일리아 중부 표준시</option>
                                        </select>
                                    </dd>
                                    <dt><label for="chooseTimeType" class="posAb">타입</label></dt>
                                    <dd>
                                        <select id="chooseTimeType">
                                            <option value="">오후 03:00</option>
                                            <option value="">15:00</option>
                                        </select>
                                    </dd>
                                </dl>
                            </div>
                            <!-- 두번째 -->
                            <div class="secondSet setBox">
                                
                                <p class="title">달력 세팅</p>
                                <dl>
                                    <dt><label for="chooseStartday" class="posAb">시작하는 요일</label></dt>
                                    <dd>
                                        <select id="chooseStartday">
                                            <option value="">일요일</option>
                                            <option value="">월요일</option>
                                        </select>
                                    </dd>
                                    <dt><label for="chooseLunar" class="posAb">음력</label></dt>
                                    <dd>
                                        <select id="chooseLunar">
                                            <option value="">표시</option>
                                            <option value="">안함</option>
                                        </select>
                                    </dd>
                                    <dt><label for="chooseHoliday" class="posAb">공휴일</label></dt>
                                    <dd>
                                        <select id="chooseHoliday">
                                            <option value="">대한민국</option>
                                            <option value="">미국</option>
                                            <option value="">일본</option>
                                        </select>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <!-- 가져오기 내보내기  -->
                        <div class="imExportDiv">
                            <div class="title_div">
                                <p class="title_btn">가져오기 / 내보내기</p>
                            </div>
                            <!-- 가져오기 디브 -->
                            <div class="firstDiv">
                                <p class="title">가져오기 (IMPORT)</p>
                                <dl class="importDl">
                                    <dt><label for="chooseGrp" class="posAb">어느곳에 가져오실건가요?</label></dt>
                                    <dd>
                                        <select id="chooseGrp">
                                            <option value="">개인 일정</option>
                                            <option value="">지누스</option>
                                            <option value="">호두웨어</option>
                                            <option value="" disabled></option>
                                            <option value="">새 그룹 만들기</option>
                                        </select>
                                    </dd>
                                    <dt>
                                        
                                    </dt>
                                    <dd>
                                        <input type="file" id="importFile" />
                                        <label for="importFile">클릭 또는 끌어넣기</label>
                                        <p class="sub_title">* 파일은 <em>ical</em> 또는 <em>CSV</em> 타입만 가능합니다.</p>
                                    </dd>
                                    <!--    클릭 또는 끌어넣었을때 나옴, 
                                            a클릭시 삭제,
                                            저장된 파일이 알맞는 확장자라면 하단 dd > input.imexBtn에 클래스추가 on -->
                                    <dd><a href="" class="savedFile"><span class="txt">파일이름.ical</span><span class="delFile">삭제</span></a></dd>
                                    <dd><input type="button" class="imexBtn on" id="importFileBtn" value="가져오기" @click="importEvent" /></dd>
                                </dl>
                            </div>
                            <!-- 내보내기 디브 -->
                            <div class="secondDiv">
                                <p class="title">내보내기 (EXPORT)</p>
                                <dl class="exportGrpDl">
                                    <dt><label for="chooseExGrp" class="posAb">어떤 일정을 내보실건가요?</label></dt>
                                    <dd>
                                        <select id="chooseExGrp">
                                            <option value="">개인 일정</option>
                                            <option value="">지누스</option>
                                            <option value="">호두웨어</option>
                                        </select>
                                    </dd>
                                </dl>
                                <p class="description">어떤 형식으로 내보내시겠습니까?</p>
                                <ul class="exportUl">
                                    <li>
                                        <span class="clr"></span>
                                        <input type="checkbox" id="exCheck01" />
                                        <label for="exCheck01">
                                            <span class="txt">excel</span>
                                        </label>
                                    </li>
                                    <li>
                                        <span class="clr"></span>
                                        <input type="checkbox" id="exCheck02" />
                                        <label for="exCheck02">
                                            <span class="txt">ical</span>
                                        </label>
                                    </li>
                                    <li>
                                        <span class="clr"></span>
                                        <input type="checkbox" id="exCheck03" />
                                        <label for="exCheck03">
                                            <span class="txt">pdf</span>
                                        </label>
                                    </li>
                                </ul>
                                <dl class="exportDl">
                                    <dt>
                                        <label for="exportFile">내보내기</label>
                                    </dt>
                                    <dd>
                                        <input type="button" class="imexBtn" id="exportFile" value="내보내기" />
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { EventImportModalInfo } from '@/store/modules/ModalInfo';

@Component({

})
export default class CustomerSetting extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventImportModalInfo ?: (params : EventImportModalInfo) => void;

    mounted() : void {

    }

    importEvent() : void {
        if( !this.doSetEventImportModalInfo ) { return; }

        this.doSetEventImportModalInfo({ show_modal : true });
    }

}
</script>

<style scoped>
    .basicDiv:hover .title_div, .imExportDiv:hover .title_div { border-bottom:2px solid #477fff; }
    .title_div { width: 800px;border-bottom:2px solid #d1d9dc; margin-bottom: 30px;overflow:hidden; }
    .title_btn { display: inline-block; width: auto; height: 65px; line-height: 65px;font-size: 15px; font-weight: bold; box-sizing:border-box; }
    .title_btn_box { float: right; }
    .title_btn_box a { margin-top: 10px;}

    #customerSettingDiv .content { padding: 0 40px !important; margin-top: 0 !important }
    .content .title { font-size: 18px; margin-bottom:25px; }
    .content dt { margin-top: 15px; }
    .content label { font-size: 12px;padding-bottom: 10px; display:block; }
    .content select { border:none;background-image:none;background-color:transparent;-webkit-box-shadow: none;-moz-box-shadow: none;box-shadow: none;appearance: none; -ms-appearance: none; -moz-appearance: none; -webkit-appearance: none; 
    width: 340px; box-sizing: border-box; border: 2px solid #e7e9ea; height: 56px;padding:0 40px 0 20px; line-height: 61px; background:#fff;border-radius: 5px;font-weight: bold; transition:0.2s; cursor: pointer;background: url(../assets/images/side_area/bt_open.png) no-repeat 304px center; }
    .content select:focus { background-image: url(../assets/images/side_area/bt_close.png); border-color: #477fff; }
    .content select:hover { border-color: #477fff; }
    .content select option { zoom: 1.1}
    .firstSet { padding-left: 20px; box-sizing: border-box }
    .firstSet.setBox { margin-top:0; padding-top:0; border-top:0 none; height: 360px;float: left; }
    .setBox { width: 400px; display: inline-block; }
    .basicDiv { width: 800px; display: block; overflow: hidden;padding-top: 10px; }
    .posAb {position: absolute;margin-left: 11px;padding: 0px 10px;background: #fff;margin-top: 0px; line-height: 14px; margin-top:-1px;}

    .imExportDiv { display: inline-block; overflow:hidden;  }
    .importDl dd label { text-align:center; width: 340px; border-radius: 5px; border: 2px dashed #d1d9dc; line-height: 52px; height:56px; box-sizing:border-box; font-weight: bold;font-size: 13px; background:#f1f3f5; transition:0.2s;cursor: pointer; }
    .importDl dd label:hover { border-color:#477fff; background:hsl(223, 100%, 99%); color:#477fff; }
    .importDl input[type="file"] { display:none; }
    .exportDl dt label { display:none; }
    .imExportDiv dd .imexBtn { margin: 0 auto; margin-top:30px; display: block; border: 2px solid #d1d9dc; background:#fff; transition:0.2s; border-radius: 23px; height: 40px; line-height: 37px; padding: 0 40px; font-weight:bold; }
    .imExportDiv dd .imexBtn:hover,.imExportDiv dd .imexBtn.on { color:#fff; background:#477fff; border-color:#477fff; }
    .savedFile { width: 340px;display: block; font-size: 13px; border-bottom: 2px solid #477fff; font-weight:bold; line-height: 40px; height: 40px; overflow:hidden; }
    .savedFile .delFile { float:right; }
    .savedFile .txt { box-sizing: border-box; width: 300px; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; display:inline-block; }

    .firstDiv { margin-left: 20px; }
    .firstDiv, .secondDiv {height: 380px;float: left;width: 400px;}
    .firstDiv p.sub_title {font-size: 12px;padding-bottom: 10px; padding-top:10px; display:block; opacity:0.7 }
    .firstDiv p.sub_title em { font-style: normal; font-weight: bold; }
    .imExportDiv input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-top: 5px;margin-right: 8px;}
    .imExportDiv input[type='checkbox']:not(old):checked + label {background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;}
    .imExportDiv input[type='checkbox']:not(old):checked + label span { color: #232848 }
    .imExportDiv li { position: relative; display:inline-block; width: 100px; height: 35px; line-height: 35px; }
    .imExportDiv label span { font-weight: bold;color: #9ba4a9; transition:0.2s; display:block;width: 70px;height: 30px;line-height: 9px; position: absolute; left:0; top:0; padding-top:10px; padding-left: 30px; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 13px; box-sizing:border-box } 
    .imExportDiv label span:hover { font-weight: bold; }

    .secondDiv .description { margin-top: 23px; font-size: 13px; }
    .exportUl { padding-top:10px; }


</style>