<template>
    <div id="group_board_detail" class="section_ce_fix" v-if="board != null">

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">게시글 상세보기</h3>
            <p class="btn_group" style="">
                <input type="button" value="수정" class="input_btn" @click.prevent="moveModify" v-if="board.audit_user_id == user_id">
                <input type="button" value="삭제" class="input_btn" @click.prevent="deleteBoard" v-if="board.audit_user_id == user_id">
            </p>
        </div>

        <div class="content bulletinBoard">
            <div class="grp">
                <div class="grp_select_box">
                    <input type="button" :value="board.group_team_name" class="sort1">
                </div>
                <div class="share">

                    <!-- <p class="facePic" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor((board.audit_user_id ? board.audit_user_id : 0) / 10000)}/${board.audit_user_id}.jpg)` }">사진</p> -->
                    <p class="facePic" :style="{ backgroundImage : `url(/app_images/${board.user_pic})` }">사진</p>

                    <p>{{ board.user_name }}</p>
                    <p>
                        {{ hodu_date_to_format_string(board.audit_created, 'YYYY.MM.DD') }}
                        <span>{{ hodu_date_to_format_string(board.audit_created, 'HH:mm') }}</span>
                    </p>
                    <p>
                        읽음
                        <span>{{ board.read_count }}</span>
                    </p>
                </div>
            </div>
            <div class="editArea">
                <input type="text" id="noti_title" disabled class="input_txt " placeholder="제목을 입력하세요." :value="board.board_info.title">
                <!-- 세로값 리사이즈시 height변경 (-218px + section_ce) notificationTemp4.js -->
                <div class="edtContentDiv">
                    <!-- 세로값 리사이즈시 height변경 (-332px + section_ce)  notificationTemp4.js -->
                    <div id="modal_noti_contents" v-html="body"></div>
                </div>
            </div>
        </div>

        <!-- 첨부파일 팝업 -->
        <div class="filePopup" :class="{ on : file_popup_open == true }">
            <h4 class="blind">첨부파일 팝업</h4>
            <a href="#" class="allDown_btn" @click.prevent="allDownload">전체 다운로드</a>
            <button type="button" class="filePopup_close" @click.prevent="file_popup_open = false">닫기</button>
            <div class="attFileDiv">
                <ul class="attFileUl">
                    <li :key="file.url" v-for="file in files">
                        <p class="attFile_icon">아이콘</p>
                        <p class="attFile_title" :title="file.name">{{ file.name }}</p>
                        <a href="#" class="fileDown_btn" @click.prevent="fileDownload(file)">PC저장</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="bulletin_comment">
            <input type="button" value="댓글" class="cmmntBtn" :class="{ 
                on : board.reply_count > 0,
                alone : !(board.board_info.files != null && board.board_info.files.length > 0)
            }" @click.prevent="showBoardReply">
            <button class="attachedBtn" @click.prevent="filePopupOnOff">첨부파일 <span>({{ board.board_info.files.length }})</span></button>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { t_event_file } from '@/model/event';

import JSZip from 'jszip';

import { GroupBoardReplyModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class GroupBoardDetail extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupBoardReplyModalInfo ?: (params : GroupBoardReplyModalInfo) => void;

    board : any = null;
    board_id : string | null = null;
    body : string = "";

    file_popup_open : boolean = false;
    files : any[] = [];

    interval : number | undefined;

    beforeMount() {
        this.board_id = this.$route.params.board_id;
    }

    mounted() : void {
        this.getGroupBoard();

        this.interval = window.setInterval(() => { $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 367)); }, 1000);
    }

    beforeDestroy() {
        if( this.interval ) window.clearInterval(this.interval);
    }

    async getGroupBoard() : Promise<void> {

        try {
            
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/board/${this.board_id}?do_read=true`, API_METHOD.GET);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || ! response.data.data || !response.data.data.post ) {
                throw new Error("그룹 게시글 조회 중 오류 발생");
            }

            this.board = JSON.parse(JSON.stringify(response.data.data.post));
            
            this.body = this.board.board_info.content.replace(/\n/g, "<br>");

            this.files.splice(0, this.files.length);
            this.files = this.files.concat(this.board.board_info.files);

            this.$nextTick(() => {
                $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 367));
            });

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "그룹 게시글 조회 중 오류 발생", ['확인']);
        }
        
    }

    /**
     * 파일 다운로드 (조회)
     */
    fileDownload(file : t_event_file) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 파일 전체 다운로드
     */
     async allDownload() : Promise<void> {
        
        const selected_files : t_event_file[] = this.board.board_info.files;

        if( selected_files.length < 1 ) {
            this.hodu_show_dialog('alert', '다운로드 할 파일을 선택해주세요', ["확인"], [() => {}]);
            return;
        }

        // 파일 1개라면
        if( selected_files.length == 1 ) {
            this.fileDownload(selected_files[0]);
            return;
        }

        // 파일 여러개는 zip 파일로 받는다

        await this.hodu_show_indicator();

        const zip = new JSZip();

        const file_promise : Promise<void>[] = [];

        for( const file of selected_files ) {

            // 파일 다운로드 Promise
            const promise = this.hodu_download(`/app_images/${file.url}`, "", true)
                .then((response) => {
                    console.log(response);

                    // 다운로드한 파일은 zip 파일 구성에 추가
                    zip.file(file.name, new Blob([response.data]));
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });

            // 병렬처리용 Promise 배열에 넣기
            file_promise.push(promise);
        }

        Promise.all(file_promise)
            .then(async() => {

                // zip 파일 압축
                await zip.generateAsync({type:"blob"}).then((content) => {
                    
                    // 압축 완료시 나오는 blob으로 다운로드
                    try {
                        this.hodu_download_blob(content, `hodu_download_${new Date().getTime()}.zip`);
                    } catch(e) {
                        this.hodu_error_process(e, true, false);
                    }

                });
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            }).finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    filePopupOnOff() {
        if( this.files == null || this.files.length < 1 ) return;
        this.file_popup_open = !this.file_popup_open;
    }

    /**
     * 댓글
     */
     showBoardReply() : void {
        this.doSetGroupBoardReplyModalInfo?.({
            show_modal : true,
            group_id : this.board.group_id,
            board_id : this.board.board_id,
            callback : this.getGroupBoard
        });
    }

    moveModify() : void {
        this.hodu_router_push(`/group/${new Date().getTime()}/board/modify/${this.board_id}`);
    }

    deleteBoard() : void {
        this.hodu_show_dialog('cancel', "정말로 게시글을 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/board/${this.board_id}`, API_METHOD.DELETE);

                    console.log(response);

                    if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("그룹 게시글 삭제 중 오류 발생");
                    }

                    this.movePrevPage();
                     
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "그룹 게시글 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);
    }

}
</script>

<style scoped>
    .bulletinBoard .grp { display: flex; justify-content: space-between; height: 60px; border-bottom: 2px solid #e7e9ea; box-sizing: border-box; width: 100%; min-width: 640px; border-right: 1px solid #e7e9ea; }
    .bulletinBoard .grp div { width: 50%; padding: 0 20px; height: 60px; line-height: 60px; box-sizing: border-box; }
    .bulletinBoard .grp .grp_select_box { border-right: 1px solid #e7e9ea; }
    .bulletinBoard .grp .share { display: flex; align-items: center; font-weight: bold; }
    .bulletinBoard .grp input[type='button'] { color: #232848; background: none; line-height: 60px; }
    .share .facePic { animation: none; width: 30px; height: 30px; border-radius: 50%; border: 1px solid #e7e9ea; font-size: 0; margin-right: 10px; background-image: url('../assets/images/footer/f7.jpg'); background-repeat: no-repeat; background-size: cover; }
    .share p:after { content: ''; display: inline-block; width: 2px; height: 15px; background: #e7e9ea; margin: 0 15px; vertical-align: middle; }
    .share p.facePic:after,
    .share p:last-child:after{ display: none; }

    .bulletin_comment { border-top: 1px solid #e7e9ea; padding: 0 25px; display: flex; justify-content: space-between; }
    .bulletin_comment input.cmmntBtn { margin: 0; margin-top: 30px; }
    .attachedBtn { font-weight: bold; padding: 10px 30px; border-radius: 50px; border: 1px solid #e3e7ed; background: #fff; display: block; margin-top: 30px; color: #27313f; cursor: pointer; transition: .2s; }
    .attachedBtn:before { content: ''; width: 18px; height: 18px; display: inline-block; background: url('../assets/images/contents/ic_file_bk.png') no-repeat center; background-size: 18px auto; vertical-align: top; margin: 1px 4px 0 0; }

    .bulletinBoard.content .editArea {
        margin-top: 0;
        border-right: 1px solid #e7e9ea;
    }

    #noti_title {
        -webkit-transition: 0.2s;
        transition: 0.2s;
        font-weight: bold;
        line-height: 70px;
        height: 70px;
        padding: 0 20px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        font-size: 18px;
        border-bottom: 2px solid #e7e9ea;
    }

    #noti_title:disabled {background: #fff;color: #273044;}

    #group_board_detail ::-webkit-scrollbar{width: 6px;}
    #group_board_detail ::-webkit-scrollbar-track{background-color: transparent;}
    #group_board_detail ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

</style>

<style>

    .cke_chrome { border:0 none; }
    .cke_top { background:#f1f3f5; border-bottom:0 none; }
    .cke_bottom { border-top: 0 none;background: #f1f3f5; padding:0; line-height:40px; height:40px; }
    .cke_resizer { display:none; }
    #modal_noti_contents { transition:0.1s;line-height: 23px;font-size: 14px;padding: 25px 30px 50px !important;text-align: left; overflow-y:auto; }
    .attachedBtn { font-weight: bold; padding: 10px 30px; border-radius: 50px; border: 1px solid #e3e7ed; background: #fff; display: block; margin-top: 30px; color: #27313f; cursor: pointer; float: right; transition: .2s; }
    .attachedBtn:hover { background: #477fff; border: 1px solid #477fff; color: #fff; box-shadow: 0 5px 10px rgb(71 127 255 / 20%); }
    .attachedBtn:hover span { color: #fff; }
    .attachedBtn:before { content: '';  width: 18px; height: 18px; display: inline-block; background: url('../assets/images/contents/ic_file_bk.png') no-repeat center; background-size: 18px auto; vertical-align: top; margin: 1px 4px 0 0; }
    .attachedBtn:hover:before { content: '';background-image: url('../assets/images/contents/ic_file_wt.png'); }

    .filePopup { width: 270px; height: 220px; position: absolute; bottom: 82px; right: 35px; border: 2px solid #e7e9ea; background: #fff; text-align: right; padding: 15px; box-sizing: border-box; display: none; }
    .filePopup.on { display : block; }
    .filePopup .allDown_btn:after { content: ''; width: 2px; height: 13px; display: inline-block; background: #f1f3f5; vertical-align: middle; margin-left: 20px; }
    .filePopup .allDown_btn:hover { font-weight: bold; }
    .filePopup_close { background: none; border: none; margin-left: 20px; font-size: 12px; cursor: pointer; }
    .attFileDiv { margin-top: 12px }
    .attFileDiv .attFileUl li { box-sizing: border-box; position: relative; width: 100%; height: 30px; line-height: 30px; }
    .attFileDiv .attFileUl p { display: inline-block; float: left; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .attFileDiv .attFileUl p.attFile_icon { font-size: 0; background: url('../assets/images/contents/ic_file_bk.png') no-repeat center; width: 40px; background-size: 17px auto; display: inline-block; vertical-align: middle; }
    .attFileDiv .attFileUl p.attFile_title { width: 150px; font-weight: bold; text-align: left; }
</style>