<template>
    <div id="app">

        <div class="loadingDiv">
            <div class="loadingBg"></div>
            <div class="loadingRel">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>

        <div id="wrap">
            <div class="login-box">
                <!-- <h1 class="">LogIn</h1> -->
                <div class="other_services kakao_logIn_box">
                    <a href="#" class="kakao_logIn_btn" @click.prevent="kakaoLogin">카카오 로그인</a>
                </div>
                <div class="other_services google_logIn_box">
                    <a href="#" class="google_logIn_btn" @click.prevent="googleLogin">구글 로그인</a>
                </div>
                <div class="other_services apple_logIn_box" @click.prevent="appleLogin">
                    <vue-apple-signin color="black" :border="true" :radius="10" type="sign in" ></vue-apple-signin>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, getBrowserInfo, getOSInfo } from '@/mixin/VueHoduCommon';

declare let Kakao : any;
import '@/assets/js/kakao';
import { AxiosResponse } from 'axios';

@Component({
    components: {
        
    },
})
export default class LoginApp extends Mixins(VueHoduCommon) {

    login_type : string = "";

    async mounted() : Promise<void> {
        if( !Kakao.isInitialized() ){ await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
        await this.appleLoginEventInit();

        window['chrome'].webview.addEventListener('message', event => {

            const login_type = event.data;

            if( login_type == 'APPLE' ) {
                this.$nextTick(() => {
                    this.appleLogin();
                    $('#appleid-signin').trigger('click');
                });
            }
            else if( login_type == 'KAKAO' ) {
                this.$nextTick(() => {
                    this.kakaoLogin();
                });
            }
            else if( login_type == 'GOOGLE' ) {
                this.$nextTick(() => {
                    this.googleLogin();
                });
            }

        });
    }

    async kakaoLogin() : Promise<void> {

        try {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].isKakaoLogin();
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.isKakaoLogin();
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        this.login_type = 'KAKAOTALK';
        

        try {

            const access_token = Kakao.Auth.getAccessToken();

            /**
             * access_token 있다면
             */
            if( access_token ) {
                
                try {

                    const response : AxiosResponse = await this.hodu_api_call(`/api/v1/auth/login/platform/KAKAOTALK`, API_METHOD.POST, {
                        kakaotalk_access_token : access_token
                    });

                    this.processResponse(response);

                } catch(e) {
                    await this.kakaoLoginForm();
                }
                
            }

            /**
            * access_token 없다면
            */
            else {
                await this.kakaoLoginForm();
            }

        } catch(e) {
            alert("카카오톡 계정 로그인 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }
    
    async kakaoLoginForm() {
        await Kakao.Auth.loginForm({
            success: async(authObj) => {

                try {
                    const response : AxiosResponse = await this.hodu_api_call(`/api/v1/auth/login/platform/KAKAOTALK`, API_METHOD.POST, {
                        kakaotalk_access_token : authObj.access_token
                    });

                    this.processResponse(response);
                } catch(e) {
                    alert("카카오톡 계정 로그인 중 오류 발생");
                    this.hodu_error_process(e, false, false, true);
                }

            },
            fail: async(e) => {
                alert("카카오톡 계정 로그인 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        });
    }

    googleLogin() : void {

        try {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].isNotKakaoLogin();
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.isNotKakaoLogin();
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        this.login_type = 'GOOGLE';

        window['gapi'].load('auth2', async() => {

            this.webviewLog(`1:gapi load auth2`);
            
            const auth2 = window['gapi'].auth2.init({
                client_id: '48808503160-k10pbn6thi0ko0easfuid8e4lq4afta9.apps.googleusercontent.com',
            });

            console.log(auth2);

            this.webviewLog(`2: auth2 init`);
            
            auth2.grantOfflineAccess({ scope: 'profile email', prompt: 'select_account' })
                .then(async(result) => {

                    this.webviewLog(`3: auth2.grantOfflineAccess - ${result ? JSON.stringify(result) : ''}`);

                    console.log(result);
                    const access_code = result.code;

                    try {

                        this.webviewLog(`4: access_code - ${access_code}`);

                        const response : AxiosResponse = await this.hodu_api_call(`/api/v1/auth/login/platform/GOOGLE`, API_METHOD.POST, {
                            google_code : access_code
                        });

                        this.processResponse(response);

                    } catch(e) {
                        alert("구글 계정 로그인 중 오류 발생");
                        this.hodu_error_process(e, false, false, true);
                    }

                })
                .catch((e) => {
                    // alert("구글 계정 로그인 중 오류 발생");
                    // this.hodu_error_process(e, false, false, true);

                    if( e.message ) {
                        this.webviewLog(`error: auth2.grantOfflineAccess - ${e.message}`);
                    }
                    else {
                        this.webviewLog(`error: auth2.grantOfflineAccess - ${JSON.stringify(e)}`);
                    }
                });
            });
    }

    appleLogin() {
        try {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].isNotKakaoLogin();
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.isNotKakaoLogin();
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 애플 계정 로그인 관련 이벤트
     */
     async appleLoginEventInit() : Promise<void> {

        this.login_type = 'APPLE';
        
        // meta 태그 redirect-uri 변경
        document.getElementById('appleid-signin-redirect-uri')?.setAttribute('content', `${location.protocol}//${location.hostname}/api/v1/apple`);

        document.addEventListener('AppleIDSignInOnSuccess', async(data) => {
            console.log(data);

            if( !data || !data['detail'] || !data['detail']['authorization'] ) {
                alert("애플 계정 로그인 중 오류 발생");
                return;
            }

            const code = data['detail']['authorization']['code'];
            const id_token = data['detail']['authorization']['id_token'];
            const state = data['detail']['authorization']['state'];

            try {

                this.login_type = 'APPLE';

                const response : AxiosResponse = await this.hodu_api_call(`/api/v1/auth/login/platform/APPLE`, API_METHOD.POST, {
                    sns_id : id_token,
                    apple_access_token : code
                });

                this.processResponse(response);

            } catch(e) {
                alert("애플 계정 로그인 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
            
        });

        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            console.log(error);

            if( error && (error as any).detail && (error as any).detail.error ) {

                if( (error as any).detail.error == "popup_closed_by_user" || (error as any).detail.error == "user_cancelled_authorize" ) {
                    return;
                } 

            }

            alert("애플 계정 로그인 중 오류 발생");
        });
    }

    processResponse(response : AxiosResponse) {
        try {

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.id || response.data.data.id.length < 1  ) {
                throw new Error("로그인 실패");
            }

            const sns_id = response.data.data.id;
            const nickname = response.data.data.nickname;
            const profile_image = response.data.data.profile_image;

            // window?.opener?.postMessage(JSON.stringify({
            //     id : sns_id,
            //     login_type : this.login_type
            // }));

            const message = JSON.stringify({
                id : sns_id,
                nickname : nickname,
                profile_image : profile_image,
                login_type : this.login_type
            });

            // console.log(`[${this.login_type}]` + " window.opener.postMessage : " + sns_id);

            const userAgent = navigator.userAgent.toLowerCase();

            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].showMessage(message);
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.postMessage(message);
            } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
                // window.opener.postMessage(message);
                window['chrome'].webview.postMessage(message);
            }

        } catch(e) {
            throw e;
        }
    } 

    webviewLog(log : string) {

        try {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].log(log);
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.log(log);
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

}
</script>

<style scoped>
    #app { width: 100%; height: 100%; }

    .loadingDiv { z-index: 2000000000; display: none; position:fixed; width:100%; height:100%;top:0; left:0; }
    .loadingBg { display:block; width: 100%; top:0;left:0; height:100%; background:#fff; opacity:0.4;z-index: 1;position: absolute; }
    .loadingBar {  overflow:hidden; display: block; position:absolute; top: 0; left: 0; background: #fff; width: 100%; height: 5px; }
    .loadingAni { position:absolute;background:#477fff; display: block; width: 100px; height: 100%; transition:0.2s; }
    .loadingRel {width: 100%;height: 100%;display: block;text-align: center;position: relative; }

    .lds-ellipsis {display: inline-block;position: absolute;width: 80px;height: 80px;margin-top: -40px;left:50%; margin-left:-40px; top: 50%;z-index: 100000000;}
    .lds-ellipsis div {position: absolute;top: 33px;width: 13px;height: 13px;border-radius: 50%;background: #477fff;animation-timing-function: cubic-bezier(0, 1, 1, 0);}
    .lds-ellipsis div:nth-child(1) {left: 8px;animation: lds-ellipsis1 0.6s infinite;}
    .lds-ellipsis div:nth-child(2) {left: 8px;animation: lds-ellipsis2 0.6s infinite;}
    .lds-ellipsis div:nth-child(3) {left: 32px;animation: lds-ellipsis2 0.6s infinite;}
    .lds-ellipsis div:nth-child(4) {left: 56px;animation: lds-ellipsis3 0.6s infinite;}

    @keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
    }
    @keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
    }
    @keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
    }

    h1 { font-size: 28px; text-align: left; width: 100%; height: 40px; margin-bottom: 20px; }
    .login-box { display: inline-block; width: 100%;position: absolute;top:50%; margin-top:-120px; left:50%; margin-left:-200px; max-width: 400px; height: 240px; box-shadow: 0 3px 20px rgba(71, 127, 255, 0.2); border-radius: 5px; padding: 30px 25px; box-sizing:border-box; min-width: 320px; overflow-x: auto; }

    .kakao_logIn_box { height: 48px; background: #ffe500; border: 1px solid #ffe500; transition: .2s; border-radius: 8px; }
    .kakao_logIn_btn:before { content: ''; width: 24px; height: 24px; display: inline-block; vertical-align: middle; background:  url('./assets/images/contents/kakao_logo.svg') no-repeat center center; background-size: cover; margin-right: 3px; }
    .kakao_logIn_box:hover {box-shadow: 0 5px 5px rgba(255, 224, 0, 0.3)}
    .google_logIn_box { height: 48px; background: #fff; border: 1px solid #e3e7ed; transition: .2s; margin: 15px 0; border-radius: 8px; }
    .google_logIn_btn:before { content: ''; width: 24px; height: 24px; display: inline-block; vertical-align: middle; background:  url('./assets/images/contents/google_logo.svg') no-repeat center center; background-size: cover; margin-right: 3px; }
    .google_logIn_box:hover {box-shadow: 0 5px 5px rgba(224, 224, 224, 0.3)}
    #appleid-signin { height: 50px; }
    #appleid-signin > div { min-width: none; max-width: 111px; min-height: none; max-height: 50px; }
</style>

<style>
    body, html { background: #fff !important; }
    #appleid-signin > div > div > svg { height: 52px; width: 100%; }
    #appleid-signin { height: 52px; cursor : pointer; border-radius: 25px; } 
</style>