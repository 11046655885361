/* DatePicker Container */
.ui-datepicker {
	width: 220px;
	padding:0 0 25px 0;
	height: 235px;
	margin: 0 auto ;
	font: 11px 'noto sans', sans-serif;
	background-color:#fff;
	z-index:200;
}
.ui-datepicker a {
	text-decoration: none;
}
/* DatePicker Table */
.ui-datepicker  .ui-datepicker-calendar-wrap {
	padding:0 16px;
	border-top:1px solid #e6e6e6;
}
.ui-datepicker table {
	width: 100%;
	margin-top: 3px;
	text-align: center;	
}
.ui-datepicker-header {
	color: #232848;
	font-weight: bold;
	height: 23px;
	padding:9px 6px 8px 20px;
	
}


/*ì¶”ê°€*/

.ui-datepicker-title span{font-size:14px;color:#232848;}

.ui-datepicker-prev, .ui-datepicker-next {display: inline-block;width: 25px;height: 25px;text-align: center;cursor: pointer;background-image: url('../images/contents/month_bt_prev.svg');background-repeat: no-repeat;line-height:25px;background-size: 22px;overflow: hidden;}
.ui-datepicker-prev {background-position: center center;}
.ui-datepicker-next {background-position: center center;}
.ui-datepicker-prev:hover {background-image: url('../images/contents/month_bt_prev_on.svg');}
.ui-datepicker-next:hover {background-image: url('../images/contents/month_bt_next_on.svg');}
a.ui-datepicker-next {margin-left:5px;background-image: url('../images/contents/month_bt_next.svg');}

.ui-datepicker thead {
	background-color: #fff;
}
.ui-datepicker th {
	text-transform: uppercase;
	font-size: 6pt;
	padding: 14px 0 8px;
	color: #232848;
/*	text-shadow: 1px 0px 0px #fff;
	filter: dropshadow(color=#fff, offx=1, offy=0);*/
}
.ui-datepicker th span{color:#7c88a2;font-size:11px}
.ui-datepicker tbody td {
	padding: 0;
	width: 32px;
	height: 26px;
	text-align: center;
}
.ui-datepicker td span, .ui-datepicker td a {
	display: inline-block;
	font-weight: normal;
	text-align: center;
	width: 26px;
	height: 26px;
	line-height: 26px;
	box-sizing: border-box;
	color: #232848;
	border: 1px solid #fff;
	border-radius: 50%;
}

.ui-datepicker-calendar .ui-state-default {
	background: #fff;    border: 1px solid #fff;
    box-sizing: border-box;
    line-height: 26px;
	width: 26px;
	height: 26px;
	border-radius: 50%;
}
.ui-datepicker-calendar .ui-state-hover {
	/*color: #f16727;*/color: #232848;
}
.ui-datepicker-current-day .ui-state-active {
	background: url('../images/side_area/bg_cal_select.png') no-repeat center 40%;
	width: 26px;
	height: 26px;
	color: #232848;	
	position: relative;
}
.ui-datepicker-today .ui-state-active {
	background: url('../images/side_area/bg_cal_today.png') no-repeat center 40%;
	width: 29px;
	height: 28px;
	color: #fff;	
	font-weight:bold;
	position: relative;
}
.ui-datepicker-other-month .ui-state-default {
	background: #fff;
	color: #c0cad0;
}
.ui-datepicker-other-month .ui-state-active {
    background: #fff !important;
}
.ui-datepicker-calendar td:first-child .ui-state-active {
	width: 26px;
	margin-left: 0;
}
.ui-datepicker-calendar td:last-child .ui-state-active {
	width: 26px;
	margin-right: 0;
}
.ui-datepicker-calendar tr:last-child .ui-state-active {
	height: 26px;
	margin-bottom: 0;
}

/*ìˆ˜ì •*/

.ui-datepicker a span{width:0;height:0;font-size:0;line-height:0;text-indent:-9999px;overflow:hidden;visibility:hidden}

#container #left_area { padding-top: 0  }
#container #left_area .ui-datepicker .ui-datepicker-calendar-wrap { border-top: 0 none;  }
#container #left_area .ui-datepicker-title { line-height: 51px; width: 64px;}
#container #left_area .ui-datepicker-header { padding: 0; }
#container #left_area .ui-datepicker-prev, #container #left_area .ui-datepicker-next { margin-top: 13px;  }
#container #left_area .bt_calContr {right: 5px;top: 5px;height: 45px !important;border-radius: 50%;width: 45px; background:  url('../images/side_area/bt_close.png') no-repeat center center; }
#container #left_area .bt_calContr.on {background: url(../images/side_area/bt_open.png) no-repeat center center; }

#container #left_area .ui-datepicker { padding: 0 20px 36px 20px;height: 245px !important; border-bottom:1px solid #e7e9ea }
#container #left_area .ui-datepicker th { padding-top: 6px; }
#container #left_area .ui-datepicker .ui-datepicker-calendar-wrap { padding: 0  }
#container #left_area .ui-datepicker td span,
#container #left_area .ui-datepicker td a { margin: 2px 2px  }


.ui-datepicker td a  { color: #232848 ; }
.ui-datepicker-days-cell-over.ui-datepicker-current-day.ui-datepicker-today { color:#fff; }
.ui-datepicker th span { cursor: default; }
.ui-datepicker-other-month .ui-state-default { cursor:pointer; }
.ui-datepicker-other-month.ui-datepicker-unselectable.ui-state-disabled .ui-state-default { cursor: default; }
.ui-datepicker td:hover span, .ui-datepicker td:hover a { font-weight: bold !important; }


.ui-datepicker-today .ui-state-active:hover, .ui-datepicker-today:hover .ui-state-active, .ui-datepicker-today:hover a, .ui-datepicker-today .ui-state-active, .ui-datepicker-today .ui-state-active {padding:0;color:#fff !important;background: #477fff !important;border-radius:50%; width: 24px !important; height: 24px !important;line-height: 24px !important;}
.ui-datepicker td a:hover, .ui-datepicker-current-day .ui-state-active {background: #eaf0ff;border-color: #eaf0ff;color: #477fff;border-radius: 50%;font-weight:bold; }
#container #left_area .ui-datepicker td span:hover,.ui-datepicker td span:hover {background: #f3f3f3;border-radius: 50%;}

.ui-datepicker { height: 245px;  }
#ui-datepicker-div { margin-top: 1px;z-index: 100000000 !important; }
#ui-datepicker-div a.ui-datepicker-prev.ui-corner-all, #ui-datepicker-div a.ui-datepicker-next.ui-corner-all {position: absolute;right: 80px;background-color: #ffffff;border-radius:50%;width: 30px;height: 30px;top: 8px;background-position: 2px 4px;}
#ui-datepicker-div a.ui-datepicker-prev.ui-corner-all:hover, #ui-datepicker-div a.ui-datepicker-next.ui-corner-all:hover {background-color:#e5e8ea; }
#ui-datepicker-div a.ui-datepicker-next.ui-corner-all {/* float: right; */left:auto;position: absolute;right: 10px;background-position: 4px 5px;}
#ui-datepicker-div select.ui-datepicker-year {font-size: 14px;display: inline-block;margin-top: 0;font-weight: bold;color: #232848;font-family: 'noto sans', sans-serif;background: #ffffff url(../images/side_area/bt_open.png) no-repeat 60px center;/* border-right: 1px solid #e6e6e6; */padding: 0 38px 0 20px !important;height: 100%;line-height: 45px;height: 45px;float: left;border-radius:0 !important;-webkit-appearance: none;}
#ui-datepicker-div select.ui-datepicker-year:focus {background: #f5f5f5 url(../images/side_area/bt_close.png) no-repeat 60px center !important;}
#ui-datepicker-div select.ui-datepicker-year:hover {background-color: #f1f3f5;cursor:pointer;}
#ui-datepicker-div .ui-datepicker-title {width: 100%;text-align:left;line-height: 45px;color: #fff;box-sizing: border-box;border-top:1px solid #f3f3f3;border-bottom:1px solid #e6e6e6; }
#ui-datepicker-div span.ui-datepicker-month {position: absolute;right: 53px;top: 0;line-height: 45px;}
#ui-datepicker-div .ui-datepicker-header {position: relative;height: 45px;padding: 0;}
html {-webkit-tap-highlight-color:transparent;-webkit-user-select: none; /* Chrome/Safari */-moz-user-select: none; /* Firefox */-ms-user-select: none;}
select::-ms-expand {display: none;}
#ui-datepicker-div .ui-datepicker-calendar-wrap { border-top: 0; }

td.ui-datepicker-unselectable.ui-state-disabled { opacity: 0.3;}
a.ui-datepicker-prev.ui-corner-all.ui-state-disabled { opacity: 0.3; }
a.ui-datepicker-next.ui-corner-all.ui-state-disabled { opacity: 0.3; }

td.ui-datepicker-today a {border: 1px solid #477fff !important;box-sizing: border-box;border-radius: 50%;font-weight: bold;color: #477fff;line-height: 24px;}

/* 210225 */
.ui-datepicker-title {cursor: default; width: 100%; text-align: left;float:left;line-height:25px;color:#737373;}
