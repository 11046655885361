<template>
    <!-- .wrapper -->
    <div class="ware_wrapper">

        <!-- .content -->
        <div class="ware_content">
            <h1 class="">비밀번호 찾기</h1>
            <div class="ware_accnt_div">
                <label for="ware_username">이름</label>
                <input type="text" placeholder="이름" id="ware_username" class="ware_username ware_input_txt" 
                       :value="ware_user_name" @input="ware_user_name = $event.target.value" />

                <label for="ware_email">이메일 주소</label>
                <input type="text" placeholder="가입시 사용한 이메일 주소" id="ware_email" class="ware_email ware_input_txt" 
                       :value="ware_user_email" @input="ware_user_email = $event.target.value" />
            </div>
            <div class="ware_accnt_options">
                <a href="#" class="logIn_btn" @click.prevent="findPassword">비밀번호 찾기</a>
                <p>작성하신 이메일 주소로 새 비밀번호가 전송됩니다.</p>
            </div>
            <!-- <div class="ware_qna">
                <p>이메일을 모르실 경우 아래 고객센터로 문의바랍니다.</p>
                <a href="cs_ask.html" class="cs_email_btn">문의하기</a>
            </div> -->
        </div>
        <!-- .contnet ends -->

    </div>
    <!-- .wrapper ends -->
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({

})
export default class FindPassword extends Mixins(VueHoduCommon) {
    
    ware_user_name  : string = "";
    ware_user_email : string = "";

    mounted() : void {

    }

    /**
     * 이름, 이메일 형식 확인
     */
    checkValid() : boolean {
        if( this.ware_user_name == null || this.ware_user_name.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "이름을 입력해 주세요", ["확인"], [() => { $('#ware_username').focus(); }]);
            return false;
        }

        if( this.ware_user_email == null || this.ware_user_email.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "이메일을 입력해 주세요", ["확인"], [() => { $('#ware_email').focus(); }]);
            return false;
        }

        // 개발 아이디가 아니라면 이메일 형식 체크
        if( !( this.ware_user_email.trim() == "3" || this.ware_user_email.trim() == "4" || this.ware_user_email.trim() == "5" || 
               this.ware_user_email.trim() == "6" || this.ware_user_email.trim() == "7" ) ) {
            
            // 이메일 형식 체크
            if(! /\S+@\S+\.\S+/.test(this.ware_user_email.trim()) ){
                this.hodu_show_dialog("alert", "이메일 형식을 확인해주세요", ["확인"], [() => { $('#ware_email').focus(); }]);
                return false;
            }
        }

        return true;
    }

    /**
     * 비밀번호 찾기
     */
    findPassword() : void {
        const vue = this;

        if( this.checkValid() == false ) {
            return;
        }

        this.hodu_api_call(`api/v1/auth/find`, API_METHOD.POST, {
            user_name : this.ware_user_name.trim(),
            user_email : this.ware_user_email.trim()
        }).then((response) => {
            console.log(response);
            vue.hodu_show_dialog('success', response.data.msg, ['확인'], [() => { vue.movePrevPage(); }]);
        })
        .catch((e) => {
            this.hodu_error_process(e, true, false);
        });
    }

}
</script>

<style scoped>
    body, html { background: #fff; }
    .ware_wrapper { text-align: center; vertical-align: middle }
    .ware_content { display: inline-block; width: 100%;position: absolute;top:50%; margin-top:-300px; left:50%; margin-left:-220px; max-width: 440px; height: 600px; box-shadow: 0 3px 20px rgba(71, 127, 255, 0.2); border-radius: 5px; padding: 60px 55px; box-sizing:border-box;}
    h1 { font-size: 28px; text-align: left; width: 100%; height: 40px; }
    .ware_accnt_div input.ware_input_txt { display: block; width: 100%; border:1px solid #e3e7ed;height:50px; line-height: 50px; box-sizing:border-box; padding: 0 15px; font-weight: bold; font-size:12px;  transition:0.2s; }
    .ware_accnt_div input.ware_input_txt:hover { background: #f1f3f5; }
    .ware_accnt_div input.ware_input_txt:focus { background: #fff; border:1px solid #477fff; }
    .ware_accnt_div input.ware_input_txt.wrong { border:1px solid #ff6060; background: #fff1f1; color: #ff6060;}
    .ware_accnt_div > label { text-align: left;width: 100%; display: inline-block; height:50px; line-height: 60px; opacity: 0.7 }
    a.logIn_btn, .cs_email_btn { transition:0.2s;margin-top:30px; width: 100%; display: block; line-height: 50px; height: 50px; border-radius: 25px; background: #477fff; color: #fff; font-size: 13px }
    a.logIn_btn:hover {background: #3371ff}

    .ware_qna { border-top: 1px solid #f1f3f5; margin-top: 35px; padding-top: 20px;  }
    .ware_qna p { opacity: 0.5  }
    .cs_email_btn { background: #f1f3f5; color:#232848;margin-top:20px; }
    .cs_email_btn:hover { background: #e3e7ed;  }

    .ware_accnt_btn { padding-top: 15px; padding-bottom: 10px; }
    .kakao_logIn_btn { background: #ffe500;width: 100%; display: block; line-height: 50px; height: 50px; border-radius: 25px; transition:0.2s; }
    .kakao_logIn_btn:hover {box-shadow: 0 5px 5px rgba(255, 224, 0, 0.3)}
    
    .ware_accnt_options { margin-top: 20px; /*padding-top: 20px; border-top:1px solid #f1f3f5;*/ }
    .find_pwd { display: inline-block; opacity: 0.5; float: right; }
    .find_pwd:hover { font-weight: bold; opacity: 1 }

    .ware_accnt_options p { opacity: 0.7; margin-top:40px; }

    @media all and (max-width: 530px) {
        #app .ware_content {width:100%;position:static;margin:0;height:100%;max-width:100%;padding: 60px 35px;box-shadow:none; min-width: 320px; overflow-x: auto;}
        
    }
</style>