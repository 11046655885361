<template>
    <div id="hospital_time_setting_scroll" class="hospital_time_setting_scroll" :class="{ set : is_first_create == false }">
        <div class="setTimeDiv">
            <!-- ********** 최초로 저장 후 .hospital_time_setting_scroll 에 set class 추가  -->
            <div class="contractSetting">
                <div class="mainSettingDiv">
                    <h4>병원 오픈과 클로징 시간을 설정하세요!</h4>
                    <div class="selectDiv" v-if="computedSettingTextList.length > 0">
                        <select @change="selectTimeSetting(Number($event.target.value))">
                            <option :value="new Date(text.start_date).getTime()" :key="new Date(text.start_date).getTime()" v-for="text in computedSettingTextList" :selected="isSelectedSetting(text)">
                                {{ text.text }}
                            </option>
                            <!-- <option value="">2020.04.01 수요일 ~ 2021.04.01 목요일</option> -->
                        </select>
                    </div>
                    <div class="btnBoxPos fr">
                        <input type="button" class="input_btn resetBtn" value="초기화" />
                        <input type="button" class="input_btn saveBtn" value="저장" />
                        <input type="button" class="input_btn delBtn" title="수정" value="수정" @click="showHospitalTimeSettingModal(false)" v-if="is_updateable == true"/>
                        <input type="button" class="addTime" title="추가" value="추가" @click="showHospitalTimeSettingModal(true)"/>
                    </div>
                </div>
                <div class="setGrp1 setGrp">
                    <p class="title">근무 기간</p>
                    <div class="contractDiv">
                        <label for="">시작</label>
                        <input type="button" :value="`${hodu_date_to_format_string(selected_start_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(selected_start_date, '요일')}`" disabled/>
                        <!-- <input type="text" value="2020.04.01 수요일" /> -->
                        <span class="seper">~</span>
                        <label for="">종료</label>
                        <input type="button" :value="`${hodu_date_to_format_string(selected_end_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(selected_end_date, '요일')}`" disabled/>
                        <!-- <input type="text" value="2021.04.01 목요일" /> -->
                    </div>
                </div>
                <div class="setGrp2 setGrp">
                    <p class="title">예약 텀 <span>(분당 몇명 가능)</span></p>
                    <div class="inbetweenDiv">
                        
                        <label for="">분 당</label>
                        <select disabled>
                            <option value="15">15분</option>
                            <!-- <option :key="n" v-for="n in computedValidMinutes" :value="n" :selected="n == selected_term">
                                {{ `${n}분` }}
                            </option> -->
                            <!-- <option value="">10분</option>
                            <option value="">15분</option>
                            <option value="">20분</option>
                            <option value="">25분</option>
                            <option value="">30분</option>
                            <option value="">35분</option>
                            <option value="">40분</option>
                            <option value="">45분</option>
                            <option value="">50분</option>
                            <option value="">55분</option>
                            <option value="">60분</option> -->
                        </select>

                        <label for="">환자 몇명 예약 가능</label>
                        <select disabled>
                            <option :key="n" v-for="n in 10" :value="n" :selected="n == selected_term_by_people">
                                {{ `${n}명` }}
                            </option>
                            <!-- <option value="1">1명</option>
                            <option value="2">2명</option>
                            <option value="3">3명</option>
                            <option value="4">4명</option>
                            <option value="5">5명</option>
                            <option value="6">6명</option>
                            <option value="7">7명</option>
                            <option value="8">8명</option>
                            <option value="9">9명</option>
                            <option value="10">10명</option> -->
                        </select>

                    </div>
                </div>
            </div>
            <ul class="setTimeUl">
                <li>
                    <p class="title fl">진료시간 설정 <span>(예약시 사용됩니다)</span></p>
                    <div class="descript">
                        <ul>
                            <li>
                                <span class="morn clr">노랑</span>
                                <span class="txt">오전</span>
                            </li>
                            <li>
                                <span class="night clr">보라</span>
                                <span class="txt">오후</span>
                            </li>
                            <li>
                                <span class="allday clr">파랑</span>
                                <span class="txt">종일</span>
                            </li>
                            <li>
                                <span class="off clr">빨강</span>
                                <span class="txt">휴무</span>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="contractUl">
                    
                    <ul v-if="selected_time_setting.length == 7">
                        <li :key="n" v-for="n in 7">
                            <span class="indi" :class="{
                                allday : selected_time_setting[n - 1] != null && selected_time_setting[n - 1].start_am_time != null && selected_time_setting[n - 1].start_pm_time != null,
                                morn : selected_time_setting[n - 1] != null && selected_time_setting[n - 1].start_am_time != null && selected_time_setting[n - 1].start_pm_time == null,
                                night : selected_time_setting[n - 1] != null && selected_time_setting[n - 1].start_am_time == null && selected_time_setting[n - 1].start_pm_time != null,
                                off : selected_time_setting[n - 1] != null && selected_time_setting[n - 1].start_am_time == null && selected_time_setting[n - 1].start_pm_time == null,
                            }">

                            </span>
                            <p class="day">{{ getDayName(n - 1, '요일') }}</p>

                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>

                                <select :id="`amStart_${n - 1}`" disabled>
                                    <option value="" :selected="selected_time_setting[n - 1] ? selected_time_setting[n - 1].start_am_time == null : false">휴원</option>
                                    <option :key="m" v-for="m in 14" :selected="selected_time_setting[n - 1] ? isSelectedTime(selected_time_setting[n - 1].start_am_time, m, 7, 0) : false">
                                        {{ getTimeOptionAmText(m, true) }}
                                    </option>
                                </select>

                                <p class="sp">~</p>

                                <select :id="`amEnd_${n - 1}`" disabled>
                                    <option value="" :selected="selected_time_setting[n - 1] ? selected_time_setting[n - 1].end_am_time == null : false">휴원</option>
                                    <option :key="m" v-for="m in 14" :selected="selected_time_setting[n - 1] ? isSelectedTime(selected_time_setting[n - 1].end_am_time, m, 7, 30) : false">
                                        {{ getTimeOptionAmText(m, false) }}
                                    </option>
                                </select>

                            </div>

                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                
                                <select :id="`pmStart_${n - 1}`" disabled>
                                    <option value="" :selected="selected_time_setting[n - 1] ? selected_time_setting[n - 1].start_pm_time == null : false">휴원</option>
                                    <option :key="m" v-for="m in 22" :selected="selected_time_setting[n - 1] ? isSelectedTime(selected_time_setting[n - 1].start_pm_time, m, 12, 0) : false">
                                        {{ getTimeOptionPmText(m, true) }}
                                    </option>
                                </select>

                                <p class="sp">~</p>

                                <select :id="`pmEnd_${n - 1}`" disabled>
                                    <option value="" :selected="selected_time_setting[n - 1] != null ? selected_time_setting[n - 1].end_pm_time == null : false">휴원</option>
                                    <option :key="m" v-for="m in 22" :selected="selected_time_setting[n - 1] ? isSelectedTime(selected_time_setting[n - 1].end_pm_time, m, 12, 30) : false">
                                        {{ getTimeOptionPmText(m, false) }}
                                    </option>
                                </select>

                            </div>

                        </li>

                        <!-- <li>
                            <span class="indi allday">종일</span>
                            <p class="day">월요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="monFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="monToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select> 
                            </div> 
                            <div class="shiftDiv">                               
                                <p class="closeP">오후근무</p>
                                <select name="" id="monFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="monToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>   
                        </li>

                        <li>
                            <span class="indi allday">종일</span>
                            <p class="day">화요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="tueFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="tueToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="tueFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="tueToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi allday">종일</span>
                            <p class="day">수요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="wedFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="wedToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                                <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="wedFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="wedToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi allday">종일</span>
                            <p class="day">목요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="thuFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="thuToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="thuFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="thuToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi allday">종일</span>
                            <p class="day">금요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="friFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="friToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="" selected>오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="friFromNight">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="friToNight">
                                    <option value="">휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="" selected>오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi morn">오전</span>
                            <p class="day">토요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="satFromMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="" selected>오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="satToMorn">
                                    <option value="">휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="" selected>오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="satToNight">
                                    <option value="" selected>휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="satToNight">
                                    <option value="" selected>휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <span class="indi off">휴관</span>
                            <p class="day">일요일</p>
                            <div class="shiftDiv">
                                <p class="openP">오전근무</p>
                                <select name="" id="sunFromMorn">
                                    <option value="" selected>휴관</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                    <option value="">오전 2:00</option>
                                    <option value="">오전 2:30</option>
                                    <option value="">오전 3:00</option>
                                    <option value="">오전 3:30</option>
                                    <option value="">오전 4:00</option>
                                    <option value="">오전 4:30</option>
                                    <option value="">오전 5:00</option>
                                    <option value="">오전 5:30</option>
                                    <option value="">오전 6:00</option>
                                    <option value="">오전 6:30</option>
                                    <option value="">오전 7:00</option>
                                    <option value="">오전 7:30</option>
                                    <option value="">오전 8:00</option>
                                    <option value="">오전 8:30</option>
                                    <option value="">오전 9:00</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="sunToMorn">
                                    <option value="" selected>휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                            <div class="shiftDiv">
                                <p class="closeP">오후근무</p>
                                <select name="" id="sunFromNight">
                                    <option value="" selected>휴관</option>
                                    <option value="">오전 9:30</option>
                                    <option value="">오전 10:00</option>
                                    <option value="">오전 10:30</option>
                                    <option value="">오전 11:00</option>
                                    <option value="">오전 11:30</option>
                                    <option value="">오후 12:00</option>
                                    <option value="">오후 12:30</option>
                                    <option value="">오후 1:00</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                                <p class="sp">~</p>
                                <select name="" id="sunToNight">
                                    <option value="" selected>휴관</option>
                                    <option value="">오후 1:30</option>
                                    <option value="">오후 2:00</option>
                                    <option value="">오후 2:30</option>
                                    <option value="">오후 3:00</option>
                                    <option value="">오후 3:30</option>
                                    <option value="">오후 4:00</option>
                                    <option value="">오후 4:30</option>
                                    <option value="">오후 5:00</option>
                                    <option value="">오후 5:30</option>
                                    <option value="">오후 6:00</option>
                                    <option value="">오후 6:30</option>
                                    <option value="">오후 7:00</option>
                                    <option value="">오후 7:30</option>
                                    <option value="">오후 8:00</option>
                                    <option value="">오후 8:30</option>
                                    <option value="">오후 9:00</option>
                                    <option value="">오후 9:30</option>
                                    <option value="">오후 10:00</option>
                                    <option value="">오후 10:30</option>
                                    <option value="">오후 11:00</option>
                                    <option value="">오후 11:30</option>
                                    <option value="">오전 12:00</option>
                                    <option value="">오전 12:30</option>
                                    <option value="">오전 1:00</option>
                                    <option value="">오전 1:30</option>
                                </select>
                            </div>
                        </li> -->
                    </ul>
                </li> 
            </ul>
            <!-- <div class="historyDiv">
                <h4 class="title">이전 진료 시간</h4>
            </div> -->
        </div>
        <!-- 이전 시간이 있을시 보이기 -->
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object } from '@/model/hodudoc';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalTimeSetting extends Mixins(VueHoduCommon) {

    /**
     * 텍스트 목록
     */
    get computedSettingTextList() : any[] {

        const text_list : any[] = [];

        if( this.hospital_setting_info == null ) { return []; }

        for( const setting of this.hospital_setting_info ) {
            
            const exist = text_list.filter(item => new Date(item.start_date).getTime() == new Date(setting.start_date).getTime());
            
            if( exist.length > 0 ) { continue; }

            text_list.push({
                start_date : setting.start_date,
                end_date : setting.end_date,
                text : `${this.hodu_date_to_format_string(setting.start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(setting.start_date, '요일')} ~ ${this.hodu_date_to_format_string(setting.end_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(setting.end_date, '요일')}`
            })
        }

        // 시간순 정렬
        text_list.sort((item1, item2) : number => {

            const start1 = new Date(item1.start_date);
            const start2 = new Date(item2.start_date);

            if( start1.getTime() > start2.getTime() ) {
                return -1;
            } 
            
            else if ( start1.getTime() < start2.getTime() ) {
                return 1;
            }
            
            return 0;
        });

        return text_list;
    }

    /**
     * 5 이상에서 60에 딱 나눠 떨어지는 5의 배수를 반환
     */
    // get computedValidMinutes() : number[] {
    //     const valid_min : number[] = []; 

    //     for( let i = 5; i <= 60; i+=5 ) {
    //         if( 60 % i == 0 ) { valid_min.push(i); }
    //     }

    //     return valid_min;
    // }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHospitalTimeSettingModalInfo ?: (params : hodu_doc_modal_info.HospitalTimeSettingModalInfo) => void;

    @Prop() event_bus !: Vue;

    selected_time_setting : hodu_doc_object.hospital_setting_info[] = [];
    selected_term : number = 15;
    selected_term_by_people : number = 10;

    selected_start_date : Date = new Date();
    selected_end_date : Date = new Date();

    is_first_create : boolean = false;
    is_updateable : boolean = false;
    
    async mounted() : Promise<void> {
        if( this.event_bus != null ) { this.event_bus.$on('timeSettingResize', this.handleResize); }

        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        this.setScroll();

        // 현재 적용되고 있는 세팅이 없다면 관리자 문의 요청 (반드시 최소 하나의 진료시간 설정이 존재해야한다)
        if( this.hospital_setting_info == null || this.hospital_setting_info.length < 7 ) {    
            this.hodu_show_dialog('cancel', '진료 시간 정보가 없습니다 관리자에게 문의 해주세요', ['확인'], [() => { this.movePrevPage(); }]);
            return;
        }

        const current_date : Date = new Date();
        this.selected_time_setting = this.selected_time_setting.concat(this.hospital_setting_info.filter(
            item => new Date(item.start_date).getTime() <= current_date.getTime() && 
                    new Date(item.end_date).getTime()   >= current_date.getTime()
        ));

        this.selected_time_setting.sort((item1, item2) : number => {
            
            if( Number(item1.day_of_week) < Number(item2.day_of_week) ) {
                return -1;
            }
            
            else if ( Number(item1.day_of_week) > Number(item2.day_of_week) ) {
                return 1;
            }

            return 0;
        });

        // 예약텀 세팅 데이터 받아오기
        if( this.selected_time_setting.length > 0 ) {
            const setting : hodu_doc_object.hospital_setting_info = this.selected_time_setting[0];
            this.selected_term = setting.reservation_term;
            this.selected_term_by_people = setting.reservation_term_by_people;
            this.selected_start_date = new Date(setting.start_date);
            this.selected_end_date = new Date(setting.end_date);

            // 선택된 세팅에 따라서 수정 가능할지 아닐지 정함 (end_date가 현재보다 옛날이라면 수정 불가)
            this.is_updateable = this.selected_end_date.getTime() > new Date().getTime();
        }
        
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();

        // @ts-ignore
        $('#hospital_time_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0)
        });
    }

    /**
     * 조회 할 근무표 변경
     */
    selectTimeSetting(start_time_millis : number) : void {

        if( this.hospital_setting_info == null ) { return;}

        this.selected_time_setting.splice(0, this.selected_time_setting.length);
        this.selected_time_setting = this.selected_time_setting.concat(this.hospital_setting_info.filter(
            item => new Date(item.start_date).getTime() == start_time_millis
        ));

        this.selected_time_setting.sort((item1, item2) : number => {
            
            if( Number(item1.day_of_week) < Number(item2.day_of_week) ) {
                return -1;
            }
            
            else if ( Number(item1.day_of_week) > Number(item2.day_of_week) ) {
                return 1;
            }

            return 0;
        });

        // 예약텀 세팅 데이터 받아오기
        if( this.selected_time_setting.length > 0 ) {
            const setting : hodu_doc_object.hospital_setting_info = this.selected_time_setting[0];
            this.selected_term = setting.reservation_term;
            this.selected_term_by_people = setting.reservation_term_by_people;
            this.selected_start_date = new Date(setting.start_date);
            this.selected_end_date = new Date(setting.end_date);

            // 선택된 세팅에 따라서 수정 가능할지 아닐지 정함 (end_date가 현재보다 옛날이라면 수정 불가)
            this.is_updateable = this.selected_end_date.getTime() > new Date().getTime();
        }

    }
    
    /**
     * 세팅 선택 여부
     */
    isSelectedSetting(setting : any) : boolean {
        
        const current_date : Date = new Date();

        return new Date(setting.start_date).getTime() <= current_date.getTime() && new Date(setting.end_date).getTime() >= current_date.getTime();
    }

    /**
     * 옵션 태그 오전 시간 문자열 반환
     */
    getTimeOptionAmText(n : number, is_start :boolean) : string {
        const target_date : Date = moment().set('hour', 7).set('minute', (n - 1) * 30 + ( is_start ? 0 : 30 )).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }

    /**
     * 옵션 태그 오후 시간 문자열 반환
     */
    getTimeOptionPmText(n : number, is_start :boolean) : string {
        const target_date : Date = moment().set('hour', 12).set('minute', (n - 1) * 30 + ( is_start ? 0 : 30 )).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }

    /**
     * 선택된 시간 여부 반환
     */
    isSelectedTime(HHmm : string | null, n : number, default_hour : number, default_min : number) : boolean {

        const target_string : string = moment().set('hour', default_hour).set('minute', (n - 1) * 30 + default_min).format('HHmm');

        // console.log(`${target_string}_${HHmm}`);

        return HHmm == target_string;
    }

    /**
     * 병원 근무시간 추가 모달 띄우기
     */
    showHospitalTimeSettingModal(is_create : boolean) : void {
        if( this.doSetHospitalTimeSettingModalInfo == null || this.selected_time_setting.length < 1 ) { return; }

        this.doSetHospitalTimeSettingModalInfo({
            "show_modal" : true,
            "start_date" : new Date(this.selected_time_setting[0].start_date),
            "is_create"  : is_create 
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#hospital_time_setting_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
</script>

<style scoped>

    /* .section_ce_fix.set  */
    .hospital_time_setting_scroll.set .mainSettingDiv > h4 { display:none; }
    .hospital_time_setting_scroll.set .mainSettingDiv { border-bottom: 0; margin-bottom:20px; }
    .hospital_time_setting_scroll.set .mainSettingDiv .selectDiv { display:block; }
    .hospital_time_setting_scroll.set .mainSettingDiv .btnBoxPos { position: absolute; right:0; top:7px; }
    .hospital_time_setting_scroll.set .mainSettingDiv .btnBoxPos .resetBtn, .hospital_time_setting_scroll.set .mainSettingDiv .btnBoxPos .saveBtn { display:none; }
    .hospital_time_setting_scroll.set .btnBoxPos .addTime, .hospital_time_setting_scroll.set .btnBoxPos .delBtn { display:inline-block; }
    .btnBoxPos .addTime, .btnBoxPos .delBtn { display:none; }
    .btnBoxPos .addTime { width: 40px; height: 40px; font-size: 0; border-radius: 50%; background: #f1f3f5 url(../../assets/images/contents/ic_add.png) no-repeat center center;background-size: 35px;z-index:30;}
    .btnBoxPos .addTime:hover {background: #477fff url(../../assets/images/contents/btn_add_fff.png) no-repeat center center;background-size:35px}
    .selectDiv { display: none; width: 100%; padding-right: 150px; box-sizing: border-box; }
    .selectDiv select { cursor:pointer; height: 55px; line-height: 53px; width:100%; font-weight: bold; appearance: none; border:1px solid #e7e9ea; font-size: 14px; border-radius: 5px; padding: 0 15px; background: url(../../assets/images/side_area/bt_open.png) no-repeat 98% center; }
    .selectDiv select:hover { background-color:#f1f3f5; }
    .selectDiv select:focus { border-color:#477fff; background-color:#fff; background-image: url(../../assets/images/side_area/bt_close.png) }
    .selectDiv option { font-size: 14px; padding: 10px 0;zoom: 1.1 }

    .title {font-size: 15px;font-weight: bold;display: block;margin-bottom: 20px;margin-top: 0;}

    .mainSettingDiv { overflow: hidden; border-bottom:2px solid #f1f3f5; margin-bottom: 30px;height: 60px; }
    .mainSettingDiv h4 { display: inline-block; font-size: 18px; line-height: 40px; height: 40px; }
    .btnBoxPos input { margin-left: 20px; }
    .btnBoxPos { float:right; }
    .btnBoxPos input { margin-left: 20px; }
    
    .setTimeDiv { padding: 30px; margin-bottom: 30px; }
    .setTimeUl .title span { font-size: 13px; margin-left:5px; opacity:0.7 }
    .setTimeUl { border-top:2px solid #f1f3f5;padding-top:20px; max-width: 940px; margin-bottom: 30px; }
    .setTimeUl li:first-child { position:relative; overflow:hidden;  }
    .setTimeUl .title { font-size: 15px; font-weight: bold; display: block; margin-bottom: 20px;    margin-top: 10px; }
    .setTimeUl > ul > li { overflow: hidden; position:relative;}
    .setTimeUl > ul > li > input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; }
    .setTimeUl > ul > li > input:hover { background-color:#f1f3f5; }
    .setTimeUl > ul > li > input:focus { background-color:#fff; border-color:#477fff; }
    .setTimeUl > ul > li > span { padding-right:20px; }
    .setTimeUl .descript { position: absolute; right:0; top:10px; }
    .setTimeUl .descript li { overflow:hidden; display:inline-block;margin-right:20px; }
    .setTimeUl .descript li:last-child { margin-right:0; }
    .setTimeUl .descript li .clr { float:left; margin-right:5px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; }
    .setTimeUl .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .setTimeUl .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .setTimeUl .descript li .clr.allday {background: #477fff !important;border-color: #477fff;}
    .setTimeUl .descript li .clr.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .contractSelect { transition:0.2s; cursor:pointer; font-size: 14px; font-weight:bold; appearance: none; border-radius: 5px; line-height: 50px; height:55px; border:1px solid #e7e9ea;padding: 0 20px; width:100%; box-sizing: border-box; background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 98%  center; background-repeat: no-repeat }
    .contractSelect:hover { background-color:#f1f3f5;  }
    .contractSelect:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .contractSelect option { height: 40px; padding:20px 0; }
    .contractUl {  margin-top:5px; border-left:1px solid #e7e9ea; border-top: 1px solid #e7e9ea }
    .contractUl li {transition:0.2s;overflow:hidden; position:relative; font-size: 12px; line-height: 60px; height:60px; border-bottom:2px solid #e7e9ea; }
    .contractUl li:hover { border-color:#477fff; }
    .contractUl li a { display:inline-block; width:100%; height: 100%; }
    .contractUl li a:hover { background:#f7f9fb; font-weight:bold; }
    .contractUl li .day { font-weight:bold; display:inline-block;text-align: center; width:14%; font-size: 13px;box-sizing:border-box; padding-left:30px; }
    .closeP {border-left: 5px solid #f1f3f5; }
    .contractUl li .workHours { display: inline-block; border-left: 1px solid #e7e9ea; line-height: 15px; }
    .contractUl li p { transition:0.2s; float:left; display:inline-block !important; width: 10%; text-align: center; border-right:1px solid #e7e9ea }
    .contractUl li .sp { width:4.5% }
    .contractUl select {transition:0.2s;font-size: 12px; float:left; cursor:pointer; display: inline-block; appearance: none; border-right:1px solid #e7e9ea;width: 14%; text-align:center; box-sizing: border-box; padding:0 20px; line-height: 60px; height:60px; }
    .contractUl select {background: url(../../assets/images/side_area/bt_open.png) no-repeat 90% center; }
    .contractUl select option { font-size: 14px;}
    .contractUl select:hover { background-color:#f1f3f5; }
    .contractUl select:focus { background-color:#fff; background-image: url(../../assets/images/side_area/bt_close.png) }
    .contractUl select:disabled, .contractSetting .contractDiv input:disabled, .inbetweenDiv select:disabled { background: #ffff; font-weight: bold; cursor: default; color:#232848; opacity: 1; }
    .inbetweenDiv select:hover:disabled { background:#fff !important; }
    .workHours span {display:inline-block; padding: 0 25px; }
    .contractUl .indi {position:absolute; top:23px; left:24px; display:inline-block; width: 10px; height:10px; border-radius: 50%; font-size:0;border:1px solid; font-weight: bold; text-align: center; color:#fff; } 
    .contractUl .indi.morn {background: #ffc00e !important;border-color: #f3b300;}
    .contractUl .indi.night {background: #846eff !important;border-color: #6c52ff;}
    .contractUl .indi.allday {background: #477fff !important;border-color: #477fff;}
    .contractUl .indi.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .dayUl  { overflow:hidden; border-right:1px solid #e7e9ea}
    .dayUl li { display: inline-block; width: 14.2857142857%; float:left; text-align:center; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .dayUl li:hover .day { background:#f1f3f5; }
    .dayUl li p { line-height: 35px; height: 35px; font-weight: bold; border-bottom: 1px solid #e7e9ea;border-top: 1px solid #e7e9ea; }
    .offDay .checkDiv a { cursor: default; }
    .offDay .checkDiv a span { opacity:0.3; cursor: default; background:#fff !important;  }
    .checkDiv a { display: block; height:45px; line-height:45px; box-sizing: border-box; font-weight: bold; border-bottom: 1px solid #e7e9ea; }
    .checkDiv a.on:hover { cursor:default }
    .checkDiv a.on span { background:#477fff !important;border-color:#1b5fff; color:#fff; }
    .checkDiv a:hover span { background: #f1f3f5; }
    .checkDiv a span { transition:0.2s; display: block; height:100%; line-height: 45px; background:#fff; box-sizing: border-box;}
    .checkDiv a.on.morn span {background: #ffc00e !important;border-color: #f3b300;}
    .checkDiv a.on.night span {background: #846eff !important;border-color: #6c52ff;}
    .checkDiv a.on.off span { background: #a1a5bb !important;border-color: red;}
    #docClrId {transition:0.2s;width: 100%;height: 50px;line-height: 50px;border-radius: 5px;background: #fff;border: 1px solid #e7e9ea;box-sizing: border-box;padding: 0 15px;text-align: left;font-weight: bold;  }
    #docClrId:hover { background: #f1f3f5; }
    .docDetailDiv .clr { position:absolute; right:35px; top:43px; display: inline-block; width:20px;height: 20px; border-radius: 50%; ; }

    .shiftDiv:hover .openP, .shiftDiv:hover .closeP, .shiftDiv:hover select { font-weight:bold; }
    .contractSetting { overflow: hidden; position: relative; max-width: 940px; margin-bottom: 30px; }
    .contractSetting .setGrp { width: 50%; float: left; width: 50%; }
    .contractSetting .contractDiv input { transition:0.2s; cursor:pointer; padding: 0 15px; min-width: 150px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; width: 100px;text-align: center; background-color:#fff; }
    /* .contractSetting .contractDiv input:hover { background-color:#f1f3f5; } */
    .contractSetting .contractDiv input:focus { background-color:#fff; border-color:#477fff; }
    .contractSetting .seper { padding-right: 20px; }
    .contractDiv { position:relative;padding-top:22px;}
    .contractDiv label { position: absolute;top:0;font-size: 12px; }

    .inbetweenDiv { position:relative;padding-top:22px; }
    .inbetweenDiv label { position: absolute;top:0;font-size: 12px; }
    .inbetweenDiv select { appearance: none;transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; min-width: 150px; font-weight: bold; border-radius: 5px;  margin-right: 30px; width: 100px;text-align: center;background:  url('../../assets/images/side_area/bt_open.png') no-repeat 91% center; }
    .contractSetting .inbetweenDiv select:hover { background-color:#f1f3f5; }
    .contractSetting .inbetweenDiv select:focus { background-color:#fff; border-color:#477fff;background-image: url('../../assets/images/side_area/bt_close.png') }
    option { font-size: 14px }

    .historyDiv { padding: 0 30px 30px 0; }
    
</style>