<template>
    <div class="userListModal modal peepsOn">
        
        <!-- 상단 영역 시작 -->
        <div class="titleBox">
            
            <h1 class="borderbot">
                {{ event_work_assignment_info.is_group_member_select == true ? '멤버 선택' : '담당자 선택' }}
            </h1>
            
            <!-- 체크일때만 사용 -->
            <p class="total user" v-if="event_work_assignment_info.select_max_count == null || event_work_assignment_info.select_max_count < 1">
                멤버 
                <span>{{ member_check_all == true ? user_list.length : member_checked_count }}</span> / {{ user_list.length }}
            </p> 
            <!--
            /* 검색이 있을때만 사용 */
            <div class="schDiv">
                <a class="schBtn" @click="searchButtonClick" :class="{ on : user_search_flag == true }">검색버튼</a>
                /* on */
                <div class="schGrp" :class="{ on : user_search_flag == true }">
                    <input type="text" id="searchTotalTxt" placeholder="멤버를 검색하세요" class="input_search02" :value="user_search_query" @input="user_search_query = $event.target.value">
                    <a class="schBtn"><span class="blind">찾기버튼</span></a>
                </div>
            </div>
            -->
        </div>
        <!-- 상단 영역 끝 -->

        <!-- 내용 영역 시작 -->
        <div class="modal_content">
            <!-- 검색창 기본 띄어두기 -->
            <div class="sch_con">
                <input type="search" id="searchInputText" placeholder="멤버를 검색하세요" class="input_search02" :value="user_search_query" @input="user_search_query = $event.target.value">
                <a class="schBtn"><span class="blind">찾기버튼</span></a>
            </div>

            <!-- 멤버 헤더 -->
            <ul class="sortHeader teamHeader">
                <li class="grpCheck">
                    <p class="grpTeamCheckAll" v-if="event_work_assignment_info.select_max_count == null || event_work_assignment_info.select_max_count < 1">
                        <input type="checkbox" id="grpTeamCheckAll" v-model="member_check_all" @change="memberAllSelect">
                        <label for="grpTeamCheckAll">전체선택</label>
                    </p>
                </li>

                <li class="num">No</li>

                <li class="pNm">
                    <span>이름</span>
                </li>

                <li class="pos">
                    <span>권한</span>
                </li>
            </ul>

        </div>
        <!-- 내용 영역 끝 -->

        <!-- 멤버 -->
        <div class="peepsDiv on">
            <div class="peepsUlDiv">
                <ul class="peeps">
                    
                    <li :key="user.user_id" v-for="(user, user_index) in targetUserList">
                        <input type="checkbox" :id="`grpPeepCheck${user_index}`" v-model="user.checked" @change="memberClick($event, user)">
                        <label :for="`grpPeepCheck${user_index}`"></label>
                        <p class="num">{{ user_index + 1 }}</p>
                        
                        <p class="fPic" :style="{ backgroundImage : `url(app_images/${user.user_info.user_pic})` }">멤버 사진</p>
                        <img class="fPicDummy" style="display : none;" :src="`app_images/${user.user_info.user_pic}`" @error="userImageError($event)" />

                        <!-- <p class="fPic" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor((user.user_id ? user.user_id : 0) / 10000)}/${user.user_id}.jpg)` }">멤버 사진</p>
                        <img class="fPicDummy" style="display : none;" :src="`/app_images/profile/user/${Math.floor((user.user_id ? user.user_id : 0) / 10000)}/${user.user_id}.jpg`" @error="userImageError($event)" /> -->

                        <p class="pNm">{{ user.user_info.user_name }}</p>
                        <!-- king, manager, mem -->
                        <p class="pos">
                            {{ user.role[0] == 'ADMIN' ? "최종관리자" :
                               user.role[0] == 'GROUP_MANAGER'     || user.role[0] == 'TEAM_MANAGER'     ? "관리자" : 
                               user.role[0] == 'GROUP_SUB_MANAGER' || user.role[0] == 'TEAM_SUB_MANAGER' ? "부관리자" : "멤버" }}
                        </p>
                    </li>

                </ul>
            </div>
        </div>

        <!-- 멤버 끝 -->
        <div class="btns">
            <input type="button" class="input_btn" value="취소" @click="clickCancelButton">
            <input type="button" class="input_btn" :value=" event_work_assignment_info.is_group_member_select == true ? '완료' : '배정'" @click="clickAssignButton">
        </div>

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const EventInfo = namespace('EventInfo');
const GroupInfo = namespace('GroupInfo');

import { EventWorkAssignmentInfo } from '@/store/modules/ModalInfo';

import { t_event } from '@/model/event';

@Component({

})
export default class EventWorkAssignment extends Mixins(VueHoduCommon) {

    /**
     * ModalInfo.State
     */
    @ModalInfo.State event_work_assignment_info !: EventWorkAssignmentInfo;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEventWorkAssignmentInfo ?: any;

    /**
     * @EventInfo.State
     */
    @EventInfo.State  event !: t_event;

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    get targetUserList() : any[] {
        if( this.user_search_query.length < 1 ) {
            return this.user_list;
        }

        return this.user_list.filter(member => member.user_info.user_name.indexOf(this.user_search_query) > -1);
    } 

    user_list : any[] = [];
    
    user_search_query : string = "";

    member_checked_count : number = 0;
    member_check_all : boolean = false;

    user_search_flag : boolean = false;

    mounted() : void {
        this.getMemebers();
    }

    /**
     * 그룹 or 팀 멤버 리스트 가져오기
     */
    getMemebers() : void {
        const vue = this;
        this.user_list.splice(0, this.user_list.length);

        let target_scope = (this.event.is_temp != true ? this.scope : ((this.event.scope_team_id ?? 0) > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP));

        let request_scope = (target_scope == OWNER_TYPE.GROUP) ? 'groups' : 'teams';
        let request_api = (target_scope == OWNER_TYPE.GROUP) ? 'getGroupMember' : 'getTeamMember';
        let request_group_id = (this.event.is_temp == true ? this.event.scope_group_id : this.scope_group_id);
        let request_team_id = (this.event.is_temp == true ? (this.event.scope_team_id ?? 0) : this.scope_team_id) > 0 ? `/${ (this.event.is_temp == true ? this.event.scope_team_id : this.scope_team_id) }` : '';

        if( this.event_work_assignment_info.is_group_member_select == true ) {
            request_scope = 'groups';
            request_api = 'getGroupMember';
            request_group_id = this.group_id;
            request_team_id = '';
        }

        // api/v1/groups/getGroupMember/{group_id} 또는 api/v1/teams/getTeamMember/{group_id}/{team_id}
        this.hodu_api_call(`api/v1/${request_scope}/${request_api}/${request_group_id}${request_team_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                vue.user_list = vue.user_list.concat(response.data.data.list);

                console.log(vue.event_work_assignment_info.selected_user_ids);
                const user_list_length : number = vue.user_list.length;
                for( let i = 0; i < user_list_length; i++ ) {
                    const user_obj : any = vue.user_list[i];
                    user_obj.checked = vue.event_work_assignment_info.selected_user_ids.indexOf(user_obj.user_id) > -1
                    vue.user_list.splice(i, 1, user_obj);

                    if( user_obj.checked == true ) {
                        vue.member_checked_count++
                    }
                }

                if( vue.member_checked_count == vue.user_list.length ) {
                    this.member_check_all = true;
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 멤버 전체 선택
     */
    memberAllSelect() : void {
        const user_list_count : number = this.user_list.length;
        for( let i = 0; i < user_list_count; i++ ) {
            const member_obj : any = JSON.parse(JSON.stringify(this.user_list[i]));
            member_obj.checked = ( this.member_check_all == true ); // 전체선택값
            this.user_list.splice(i, 1, member_obj);
        }

        if( this.member_check_all == false ) {
            this.member_checked_count = 0;
        }
    }

    /**
     * 그룹, 팀 멤버 클릭시 count 변경 및 전체 선택 체크 변경
     */
    memberClick(event, user) : void {

        // 하나만 선택가능이면 나머지 체크 풀기
        // if( event.target.checked == true && this.event_work_assignment_info.is_select_one == true ) {

        //     for( const user of this.targetUserList ) {
        //         user.checked = false;
        //     }
        //     user.checked = true;
        //     this.$forceUpdate();
        // }

        let checked_count = 0;
        for( const user of this.targetUserList ) {
            if( user.checked == true ) checked_count++;
        }

        if( event.target.checked == true && this.event_work_assignment_info.select_max_count != null && this.event_work_assignment_info.select_max_count < checked_count ) {
            user.checked = false;
            $(event.target).prop("checked", false);
            this.$forceUpdate();
        }

        this.member_checked_count = this.getMemberCheckedCount();
        this.member_check_all     = this.member_checked_count == this.user_list.length;
    }

    /**
     * 그룹, 팀 멤버가 체크된게 몇명인지 반환
     */
    getMemberCheckedCount() : number {
        try {
            let checked_memeber_count : number = 0;
                
            const user_list_count : number = this.user_list.length;
            for( let i = 0; i < user_list_count; i++ ) {
                if( this.user_list[i].checked == true ) {
                    checked_memeber_count++;
                }
            }

            return checked_memeber_count;

        } catch(e) {
            this.hodu_error_process(e, false, false);
            return 0;
        }

    }

    /**
     * 검색 버튼 toggle
     */
    searchButtonClick() : void {
        
        this.user_search_flag = !this.user_search_flag;

        // 검색을 끄는 경우
        if( this.user_search_flag == false ) {
            this.user_search_query = '';
        }

    }

    /**
     * 취소 버튼
     */
    clickCancelButton() : void {
        this.doSetEventWorkAssignmentInfo({
            show_event_work_assignment : false,
            selected_user_ids          : [],
            confirm_function           : () => {}
        })
    }

    /**
     * 배정 버튼
     */
    clickAssignButton() : void {
        if( this.event_work_assignment_info.confirm_function == null ) {
            return;
        }

        const user_ids : number[] = [];

        // check 되어있는 아이디 체크 및 push
        const user_list_length : number = this.user_list.length;
        for( let i = 0; i < user_list_length; i++ ) {
            if( this.user_list[i].checked == true ) {
                user_ids.push(this.user_list[i].user_id);
            }
        }

        if( this.event_work_assignment_info.is_group_member_select != true ) {
            if( user_ids.length < 1 ) { 
                alert("최소 1명 이상 선택 해야 합니다"); 
                return;
            }
        }

        // length가 리스트의 length와 같다면 assignment_type == 'ALL', 1명이라도 배정됐다면 'ASSIGN', 아무도 배정하지 않았다면 'UNDEF'  
        const assignment_type : string = user_ids.length == this.user_list.length ? 'ALL' : 'ASSIGN';

        this.event_work_assignment_info.confirm_function(user_ids, assignment_type, this.user_list.length);
    }
}
</script>

<style scoped>
    /* 모달 공통 css */
    .modal { position:absolute; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}
    
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}
    .titleBox .total { float:left; font-size:14px; font-weight:bold; margin-left:15px;margin-top:2px; }
    .titleBox .total span { color:#477fff; padding-left:5px;}
    .titleBox .total.team {  }
    .modal .btns {border-top:1px solid #f1f3f5; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; }
    .modal .schBtn { position:absolute; right:0;top:20px;right:30px; }
    .modal .schGrp {display:none;transition:0.1s;position: absolute;top: 110px;z-index: 100000000;width: 100%;height: 60px;background: white;top: 89px;left: 0;}
    .modal .schGrp.on { display:block; }
    .modal .schGrp input {transition:0.1s;background:transparent;width: 100%;box-sizing: border-box;height: 100%;line-height: 60px;padding: 0 100px 0 30px;font-size: 14px;font-weight: bold;}
    .modal .schGrp:hover { background:#f1f3f5; }
    .modal .schGrp input:focus { background:#fff;border-bottom:1px solid #477fff; height:60px;line-height:61px; }
    .modal .schGrp a.schBtn { top:0px; }

    .modal .noResult { display:none; position: absolute; z-index: 1000000;top: 150px;height: 316px;background: #fff;line-height: 316px;font-weight: bold;font-size: 21px;color: #afb6c3;}


    /* 공유모달 css */
    .userListModal .sortHeader { position:relative; }
    .sortHeader li.grpCheck { position: absolute;left: 0;top: 0;width: 100px;text-align: center;font-weight: bold;font-size: 13px;}
    .userListModal { transition:0.3s; max-width: 800px; bottom:auto !important; margin-top: -268px !important;height: 536px !important;}
    .shareUlDiv {height: auto;display: block;width: 100%;height: 316px;max-height: 316px;overflow:hidden; overflow-y:auto;}
    .shareUlDiv ul {display: block;width:100%;}
    .shareUlDiv li .grp {width: 100%;transition:0.1s;height: 55px;line-height: 55px;border-bottom: 1px solid #f1f3f5; position:relative;}
    .shareUlDiv li .grp:hover { background:#f1f3f5; }
    .shareUlDiv li .grp * {display: inline-block;float:left;}
    .shareUlDiv li .grp:hover .count a { border:2px solid #477fff; border-radius: 5px; padding:0 10px; line-height:30px; height:30px;margin-top:11px; }
    .shareUlDiv li .grp .count.noCount { opacity:0.6 }
    .shareUlDiv li .count a { transition:0.1s; }
    .shareUlDiv li .count a:hover { background:#477fff; color:#fff; }
    .shareUlDiv li .count a:hover span { color:#fff; }
    .shareUlDiv li .img {width: 57px;font-size: 0; background-position:center center !important; background-size: cover !important;height: 42px;border-radius: 5px;border: 1px solid #f1f3f5;position: absolute;left: 180px;top: 7px;}
    .shareUlDiv .teamUl li  .img {width: 50px;height: 36px;top: 9px;left: 186px;}
    .shareUlDiv li h2 {font-size: 14px;}
    .shareUlDiv li .nm { width:64%;box-sizing:border-box; padding-left:120px; padding-right:20px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
    .shareUlDiv li .count { width:10%;font-weight:bold; }
    .shareUlDiv li .count span { color:#477fff; margin-right:4px; }
    .shareUlDiv li.withTeams a.showTeams {position: absolute;right: 20px;top: 0;font-size: 0;width: 35px;height: 60px;border-radius: 50%; background:  url('../../../assets/images/side_area/bt_open.png') no-repeat center center;}
    .shareUlDiv li a.showTeams.on, .shareUlDiv li.on a.showTeams {background:  url('../../../assets/images/side_area/bt_close.png') no-repeat center center;  }
    .shareUlDiv .teamUl { display:none; border-top:1px solid #f1f3f5;border-bottom:1px solid #f1f3f5;}
    .shareUlDiv li.on > .grp {  }
    .shareUlDiv li.on .teamUl { display:block; }
    .userListModal input[type='checkbox']:not(old) + label {position: absolute;left: 38px;top: 17px;z-index:10000000; }
    .userListModal .teamUl input[type='checkbox']:not(old) + label{left: 50px;}
    .userListModal input[type='checkbox']:not(old) + label, .userListModal input[type='checkbox']:not(old) + label:hover {margin-left: 5px;display: inline-block;  padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
    .userListModal input[type='checkbox']:not(old):checked + label { border:2px solid #477fff;background: #477fff url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;}
    .grpUl > li > .grp { height:60px; line-height:60px; }
    .grpUl > li > .grp .img  { top:8px; }
    .grpUl > li > .grp:hover > .count > a { margin-top:14px; }
    .grpUl > li > .grp input[type='checkbox']:not(old) + label { top: 19px }
    .shareUlDiv li .grp .cl {position: absolute;left: 151px;top: 26px;width: 8px;height: 8px;font-size: 0;border-radius: 50%;background: #f1f3f5;}
    .shareUlDiv li .teamUl .grp .cl { left:158px; }
    .userListModal .teamUl .num {padding-left: 90px;}

    .grpHeader .num { width:15%;  }
    .grpHeader .nm {padding-left: 14px;width: 64%;box-sizing: border-box;}
    .grpHeader .count {width: 19%;}

    .teamHeader { display:none; }
    .userListModal.peepsOn ul.grpUl {height: 447px;}
    .userListModal.peepsOn { margin-top: -268px !important; height: 536px !important;}
    .userListModal.peepsOn .teamHeader { display:block; }
    .userListModal.peepsOn .grpHeader { display:none; }
    .userListModal .peepsDiv { display:none;width:100%;position: absolute;background: #fff;z-index: 1000000000;top: 150px; }
    .userListModal .peepsDiv.on {display: block; position: static;}
    .userListModal .peepsDiv .peepsUlDiv { width: 100%;height: 316px;max-height: 263px;overflow: hidden;overflow-y: auto;}
    .userListModal ul.peeps li * {display: inline-block;}
    .userListModal ul.peeps li {transition:0.1s;position:relative;line-height: 50px;height: 50px;font-size: 13px;font-weight: bold;border-bottom:1px solid #f1f3f5;}
    .userListModal ul.peeps li .fPic {position:absolute;background-color:#f1f3f5; left:155px; top:10px;font-size: 0;width: 30px;height: 30px;border-radius: 50%;background-position: center center !important;background-size: cover !important;border: 1px solid #e7e9ea;}
    .userListModal ul.peeps li .pNm { padding-left:70px; padding-right:20px; width:63%; box-sizing:border-box; }
    .userListModal ul.peeps li .pos { font-size:12px; }
    .userListModal ul.peeps li:hover { background:#f1f3f5; }
    .userListModal .peeps input[type='checkbox']:not(old) + label { top:14px; }
    .userListModal .peeps .fPic.none { background-image : url(../../../assets/images/contents/im_photoB.gif) !important; }

    .userListModal .pNm {  width:63%; box-sizing:border-box;    padding-left: 20px;  }
    .userListModal .num {font-weight:bold;padding-left: 80px;width: 17%; box-sizing:border-box; text-align: center;font-size:12px;}
    .userListModal .peeps input[type='checkbox']:not(old) + label:after {content: '';display: block;width: 800px;height: 50px;position: absolute;left: -45px;top: -16px;}
    
    
    .teamHeader .pos {width: 20%;}
    .sortHeader input[type='checkbox']:not(old) + label { top:19px;}

    .shareUlDiv.mCS_destroyed { overflow : hidden !important }

    /* 모달 검색창 디폴트 */
    .sch_con { display: inline-block; width: 100%; height: 50px; border-bottom: 2px solid #f1f3f5; position: relative; }
    .modal .sch_con input { width: 100%; box-sizing: border-box; line-height: 50px; padding: 0 100px 0 30px; font-size: 13px; font-weight: bold; }
    .modal .sch_con .schBtn { top: 5px; margin: 0; }
    input::-ms-clear,
    input::-ms-reveal{
        display:none;width:0;height:0;
    }
    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button,
    input::-webkit-search-results-button,
    input::-webkit-search-results-decoration{
        display:none;
    }
</style>