<template>
    <div class="modalDiv basic">
        <div class="bg"></div>
        <div class="modal basicAddModal">
            <div class="titlebox">
                <h1>동 추가</h1>
            </div>
            <div class="modal_content">
                <ul>
                    <li class="">
                        <label class="title">동 번호</label>
                        <p class="descript"><input type="text" placeholder="예) 100" :value="name" @input="name = $event.target.value"/>동</p>
                    </li>
                    <li class="">
                        <label class="title">주차가능 차량 수</label>
                        <div class="descript">
                            <select @change="count = Number($event.target.value)">
                                <option :key="n" v-for="n in 3" :value="n">{{ `${n} 대`}}</option>
                                <!-- <option value="1">1 대</option>
                                <option value="2">2 대</option>
                                <option value="3">3 대</option>
                                <option value="4">4 대</option>
                                <option value="5">5 대</option> -->
                            </select>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btns">
                <input type="button" value="닫기" @click="closeModal">
                <input type="button" value="추가" @click="createBuilding">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HomeBuildingCreateModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_building_create_modal_info !: hodu_home_modal_info.HomeBuildingCreateModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeBuildingCreateModalInfo ?: (params : hodu_home_modal_info.HomeBuildingCreateModalInfo) => void;

    name : string = "";
    count : number = 2;

    mounted() : void {
        
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetHomeBuildingCreateModalInfo == null ) { return; } 

        this.doSetHomeBuildingCreateModalInfo({
            show_modal : false
        });
    }

    /**
     * 동 추가 
     */
    createBuilding() : void {
        window['createBuilding']({
            key : require('uuid/v4')(),
            name : this.name,
            count : this.count
        });
        this.closeModal();
    }

}
</script>

<style scoped>
    .modal.basicAddModal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-136.5px; margin-left:-260px; width:520px; height: 273px; background:#fff; }   
    .modal.basicAddModal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0 30px;border-bottom: 1px solid #e7e9ea; }
    .modal.basicAddModal .titlebox h1 { font-size: 20px;font-weight: bold;height: 90px;line-height: 110px; }
    
    .modal.basicAddModal .modal_content { overflow: hidden; height: 111px;border-bottom: 1px solid #e7e9ea;  }
    .modal.basicAddModal .modal_content ul li > * { display: inline-block;  }
    .modal.basicAddModal .modal_content ul li { padding:0 30px; height:55px; line-height: 55px; overflow: hidden; border-bottom:1px solid #f1f3f5;}
    .modal.basicAddModal .modal_content ul li .descript { float: right; text-align: left; font-weight: bold }
    .modal.basicAddModal .modal_content ul li .descript > * { text-align: left; font-weight: bold }
    .modal.basicAddModal .modal_content ul li .descript input { line-height: 35px; height: 35px; border:1px solid #e7e9ea; padding:0 15px; text-align:right; box-sizing:border-box;font-size: 12px; width: 73px; margin-right: 15px; }

    .modal.basicAddModal .modal_content ul li .descript input:hover {border-color:#e7e9ea;}
    .modal.basicAddModal .modal_content ul li .descript input:focus {border-color:#477fff;}
    .modal.basicAddModal .modal_content ul li .descript select { position: static !important; float: left; font-size: 12px; min-width: 100px;font-weight: bold; line-height: 35px; height: 35px; background-color:#fff; padding-right: 35px; padding-left: 15px; appearance: none; -moz-appearance: none; -webkit-appearance: none; border:1px solid #e7e9ea; border-radius: 5px; margin-top:9px; cursor: pointer; width: 99px;background: url(../../../assets/images/side_area/bt_open.png) no-repeat 90% center; }
    .modal.basicAddModal .modal_content ul li .descript select:hover {border-color:#e7e9ea;}
    .modal.basicAddModal .modal_content ul li .descript select:focus {border-color:#477fff; background-image:  url(../../../assets/images/side_area/bt_close.png);}

    .modal.basicAddModal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    .modal.basicAddModal .btns input { transition:0.2s; width: 50%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold;background: #fff }
    .modal.basicAddModal .btns input:hover { background:#f1f3f5; }
</style>