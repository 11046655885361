<template>
    <div id="organization_off_add_modal" class="modal">
        <div class="titleBox">
            <h1>휴무일 등록</h1>
        </div>
        <div class="content"> 
            <div class="detail">
                
                <HoduInlineDatePicker
                    :event_bus="event_bus" 
                    :dp_id="'organization_off_add_datepicker'" 
                    :dp_class="'datepickerDiv'"
                    :other_month_selectable="true"
                    :default_date="new Date()"
                    @select="select"
                    @changeMonthYear="change"
                />

                <div class="context">
                    <div class="timeDiv">
                        <div class="startDiv on">
                            <input type="button" :class="{ on : is_start_select == true }" :value="`${start_text} 부터`" @click.prevent="set(true)">
                        </div>
                        <div class="endDiv">
                            <input type="button" :class="{ on : is_start_select == false }" :value="`${end_text} 까지`" @click.prevent="set(false)">
                        </div>
                    </div>
                    <div class="detailDiv">
                        <label for="">사유</label>
                        <textarea placeholder="휴무 사유를 작성하세요" :value="off_reason" @input="off_reason = $event.target.value"></textarea>
                    </div>
                </div>
            </div>
            <div class="btns" :class="{
                b1 : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN'),
                b3 : (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') && organization_off_add_modal_info.is_create == false
            }">
                <input type="button" value="취소" @click.prevent="close"/>
                <input type="button" value="삭제" @click.prevent="deleteOff" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') && organization_off_add_modal_info.is_create == false"/>
                <input type="button" class="save" value="저장" @click.prevent="save" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';

import { organization_modal_interface } from '@/model/organization';

import moment from 'moment';

@Component({
    components: {
        HoduInlineDatePicker
    },
})
export default class OrganizationOffAddModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_off_add_modal_info !: organization_modal_interface.OrganizationOffAddModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationOffAddModalInfo ?: (params : organization_modal_interface.OrganizationOffAddModalInfo) => void;

    event_bus : Vue = new Vue();

    start : Date = new Date();
    end : Date = new Date();
    is_start_select : boolean = true;

    start_text : string = "";
    end_text : string = "";

    off_reason : string = "";

    off : any = null;

    mounted() : void {
        this.makeDateText();

        if( this.organization_off_add_modal_info.is_create == false ) {
            this.getOrganizationDayOff();
        }
    }

    /**
     * 휴무일 조회
     */
    async getOrganizationDayOff() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off/${this.organization_off_add_modal_info.off_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.day_off ) {
                throw new Error("지정 휴무일 조회 중 오류 발생");
            }

            this.off = JSON.parse(JSON.stringify(response.data.data.day_off));

            this.off_reason = this.off.off_reason;
            this.start = this.yyyymmddToDate(this.off.start_date);
            this.end = this.yyyymmddToDate(this.off.end_date);

            this.changeDate(this.start);
            this.makeDateText();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("지정 휴무일 조회 중 오류 발생");
            this.close();
        }
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 날짜 텍스트 생성
     */
    makeDateText() : void {
        this.start_text = `${this.hodu_date_to_format_string(this.start, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.start, '요일')}`;
        this.end_text = `${this.hodu_date_to_format_string(this.end, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.end, '요일')}`;
    }

    /**
     * 시작일, 종료일 달력 세팅 변경
     */
    set(is_start_select : boolean) : void {
        this.is_start_select = is_start_select;
        this.changeDate(this.is_start_select == true ? this.start : this.end);
    }

    /**
     * datepicker 선택 된 날짜 변경
     */
    changeDate(target_date : Date) : void {
        this.event_bus.$emit('changeDate', target_date);
    }

     /**
     * 달력 선택 됨
     */
    select(date_string : string , data : any) : void {
        
        if( this.is_start_select == true ) {
            this.start = new Date(date_string);
                        
            if( this.start.getTime() > this.end.getTime() ) {
                this.end = moment(this.start).toDate();
            }
        }
        else {
            this.end = new Date(date_string);

            if( this.start.getTime() > this.end.getTime() ) {
                this.start = moment(this.end).toDate();
            }
        }

        this.makeDateText();
        
    }

    /**
     * 달력 년, 월 변경 됨
     */
    change(year: number, month: number, inst: any) : void {
        // DO NOTHING
    }
    
    /**
     * 닫기
     */
    close() : void {
        this.doSetOrganizationOffAddModalInfo?.({
            show_modal : false,
            is_create : true,
            off_id : 0
        });
    }

    /**
     * 삭제
     */
    async deleteOff() : Promise<void> {
        if( confirm("정말로 해당 휴무일을 삭제 하시겠습니까?") == false ) return;

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off/${this.off.off_id}`, API_METHOD.DELETE);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("휴무일 삭제 중 오류 발생");
            }
            
            this.organization_off_add_modal_info.callback?.();
            this.close();

        } catch(e) {
            this.hodu_show_dialog('cancel', '휴무일 삭제 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 저장
     */
    async save() : Promise<void> {
        
        if( this.off_reason.trim().length < 1 ) {
            alert("휴무 사유를 입력해주세요");
            return;
        }
        
        const obj = {
            off_name : '휴무일', // 나중에 적는 칸이 생기면 변수로 사용
            off_reason : this.off_reason.trim(),
            start_date : this.hodu_date_to_format_string(this.start, 'YYYYMMDD'),
            end_date : this.hodu_date_to_format_string(this.end, 'YYYYMMDD'),
        }

        // 생성
        if( this.organization_off_add_modal_info.is_create == true ) {
            try {
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off`, API_METHOD.POST, obj);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴무일 등록 중 오류 발생");
                }

                this.organization_off_add_modal_info.callback?.();
                this.close();

            } catch(e) {
                alert("휴무일 등록 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        }

        // 수정
        else {
            try {

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off/${this.organization_off_add_modal_info.off_id}`, API_METHOD.PUT, obj);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴무일 수정 중 오류 발생");
                }

                this.organization_off_add_modal_info.callback?.();
                this.close();

            } catch(e) {
                alert("휴무일 수정 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        }

    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-265px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:530px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    .modal h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    .modal .detail { overflow:hidden; }
    .datepickerDiv { float:left; width: 40%; height: 368px; padding: 25px 25px; box-sizing: border-box; text-align: center; }
    .ui-datepicker-prev, .ui-datepicker-next {font-size:0;display: inline-block;width: 25px;height: 25px;text-align: center;cursor: pointer;background-image: url('../../../assets/images/contents/month_bt_prev.svg');background-repeat: no-repeat;line-height:25px;background-size: 22px;overflow: hidden;}
    .ui-datepicker-prev {float:left;background-position: center center;}
    .ui-datepicker-next { float:right; background-position: center center;}
    .ui-datepicker-prev:hover {background-image: url('../../../assets/images/contents/month_bt_prev_on.svg');}
    .ui-datepicker-next:hover {background-image: url('../../../assets/images/contents/month_bt_next_on.svg');}
    a.ui-datepicker-next {margin-left:5px;background-image: url('../../../assets/images/contents/month_bt_next.svg');}
    .datepickerDiv .yyyy { font-size: 16px; font-weight:bold; margin-bottom: 20px; margin-top:6px; display: inline-block; }

    .calUl .day li { height: 20px; line-height: 20px; }
    .calUl .dayList { height:20px; }
    .calUl > li { display: block; height: 40px;line-height: 40px; }
    .calUl ul  { overflow: hidden; }
    .calUl ul li { float:left; text-align: center; display: inline-block; width:14.2857142857%;}
    .calUl ul li a { font-weight: bold; display:inline-block; width: 30px; height:30px;line-height: 30px; transition:0.2s; border-radius:50%;vertical-align: middle; }
    .calUl ul li a:hover { background:#f1f3f5; }
    .calUl ul li a.on { background:#477fff !important; color:#fff; }
    .calUl ul li a.nm { opacity:0.5 }
    .calUl ul li a.off { opacity:0.3; font-weight: normal; }
    .calUl ul li a.off:hover { background:transparent }
    

    .modal .context { float:left; border-left:1px solid #e7e9ea; width: 60%; height:368px; box-sizing: border-box; }
    
    .modal .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px; width: 100% }
    .modal .btns input { line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .modal .btns.b1 input { width : 100% }
    .modal .btns.b3 input { width : 33.333333333% }
    .modal .btns input.save:hover { color:#477fff; }
    .modal .btns input:hover { background:#f1f3f5; }

    .timeDiv { overflow: hidden; }
    .startDiv, .endDiv { overflow:hidden; width:50%; height:60px; float:left; }
    .startDiv { border-right:1px solid #477fff; box-sizing: border-box; }
    .startDiv.on select, .endDiv.on select { font-weight:bold; } 
    .startDiv select, .endDiv select { cursor:pointer; box-sizing: border-box; transition: 0.2s; float:left; width: 33.3333333333%; appearance: none; padding: 0 15px;line-height:50px;background-image:url('../../../assets/images/side_area/bt_open.png'); background-position: 95%  center; background-repeat: no-repeat; height: 50px; border-right:1px solid #f1f3f5; border-bottom:1px solid #e7e9ea;}
    .startDiv select:last-child, .endDiv select:last-child { border-right:0; }
    
    .timeDiv select:hover { background-color:#f1f3f5; }
    .timeDiv select:focus { border-bottom:1px solid #477fff; background-color:#fff;background-image:url('../../../assets/images/side_area/bt_close.png'); }
    .timeDiv input[type="button"] { border:none; border-bottom:1px solid #e7e9ea; cursor:pointer;transition:0.2s; background:#fff; font-size:13px; font-weight: normal; padding: 0 15px;height: 60px; line-height: 60px; width: 100%; box-sizing: border-box;  }
    .timeDiv input[type="button"]:hover { background:#f1f3f5;border-color:#477fff; }
    .timeDiv input[type="button"]:focus, .timeDiv input[type="button"].on { background-color:#477fff !important; border-color:#477fff; color:#fff; font-weight: bold;}
    
    .detailDiv { padding: 20px 30px 0; }
    .detailDiv label { height: 25px; display:block; }
    .modal input[type="text"] { margin-bottom:20px; transition:0.2s; font-size:14px; font-weight: bold; padding: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box;  line-height: 50px; height:50px; }
    .modal input[type="text"]:hover { background:#f1f3f5; }
    .modal input[type="text"]:focus { border-color:#477fff; background:#fff; }

    .modal textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 240px; max-height: 240px; font-size: 13px;line-height: 20px; padding: 15px; height:auto; box-sizing: border-box; font-weight:bold; }
    .modal textarea:hover { border-color:#477fff }
    .modal textarea:focus { background:#fff; border-color:#477fff;outline: none;  }

    .modal.edit .btns input { width : 33.3%; }

    
</style>

<style>
    #organization_off_add_modal a.ui-datepicker-prev.ui-corner-all {
        position: absolute;
        left: 4px;
        top: 9px;
        width: 25px;
        height: 25px;
    }

    #organization_off_add_modal a.ui-datepicker-next.ui-corner-all {
        position: absolute;
        right: 4px;
        top: 9px;
        width: 25px;
        height: 25px;
    }
</style>