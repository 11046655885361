<template>
    <!-- .basic > 기본설정 on, setTime > 진료시간 on -->
    <div class="hospital_setting section_ce_fix"
        :class="{
            basic : hospital_setting_tab == 'BASIC',
            setTime : hospital_setting_tab == 'TIME',
            pubHoliday : hospital_setting_tab == 'HOLIDAY',
            offDay : hospital_setting_tab == 'OFF',
        }"
    >
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">병원 설정</h3>
        </div>

        <div class="schedule_box">
            <div class="grp settingBg">
                <div class="posRel">
                    <div class="bg">
                        <div class="mainMenu">
                            
                            <a class="basicLink" @click="setHospitalSettingTab('BASIC')"
                               :class="{
                                   on : hospital_setting_tab == 'BASIC',
                               }">
                                기본 설정
                            </a>

                            <a class="setTimeLink" @click="setHospitalSettingTab('TIME')"
                               :class="{
                                   on : hospital_setting_tab == 'TIME',
                                   linkLeft : hospital_setting_tab == 'HOLIDAY' || hospital_setting_tab == 'OFF',
                               }">
                                진료 시간
                            </a>
                            
                            <a class="pubHolidayLink" @click="setHospitalSettingTab('HOLIDAY')"
                               :class="{
                                   on : hospital_setting_tab == 'HOLIDAY',
                                   linkLeft : hospital_setting_tab == 'OFF',
                               }">
                                법정공휴일
                            </a>

                            <a class="offDayLink" @click="setHospitalSettingTab('OFF')"
                               :class="{
                                   on : hospital_setting_tab == 'OFF',
                               }">
                                병원휴일
                            </a>
                            <div class="btnBox fr" v-if="hospital_setting_tab == 'OFF'">
                                <input type="button" id="" placeholder="" value="삭제" title="" class="input_btn del" @click="deleteDayOffs()">
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div id="" class="section_scroll">

            <div class="content">
                <!-- 병원 기본 설정 -->
                <HospitalBasicSetting v-if="hospital_setting_tab == 'BASIC'" :event_bus="event_bus" />

                <!-- 진료시간 -->
                <HospitalTimeSetting v-if="hospital_setting_tab == 'TIME'" :event_bus="event_bus" />

                <!-- 법정공휴일 -->
                <HospitalHolidaySetting v-if="hospital_setting_tab == 'HOLIDAY'" :event_bus="event_bus" />

                <!-- 휴일관리 -->
                <HospitalOffSetting v-if="hospital_setting_tab == 'OFF'" :event_bus="event_bus" />
                
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');

import HospitalBasicSetting   from '@/components/hodu_d/HospitalBasicSetting.vue';
import HospitalTimeSetting    from '@/components/hodu_d/HospitalTimeSetting.vue';
import HospitalHolidaySetting from '@/components/hodu_d/HospitalHolidaySetting.vue';
import HospitalOffSetting     from '@/components/hodu_d/HospitalOffSetting.vue';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HospitalBasicSetting, HospitalTimeSetting, HospitalHolidaySetting, HospitalOffSetting, ResizeObserver
    },
}) 
export default class HospitalSetting extends Mixins(VueHoduCommon) {

    /**
     * @HoduDocInfo.State
     */
    @HoduDocInfo.State hospital_setting_tab !: "BASIC" | "TIME" | "HOLIDAY" | "OFF";

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetHospitalSettingTab ?: (parms : "BASIC" | "TIME" | "HOLIDAY" | "OFF") => void;

    event_bus : Vue = new Vue();

    mounted() : void {
        // $(".mainMenu a").click(function(){
        //     var getIndex = $(this).index();
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");
        //     if ( getIndex == 0 ) { 
        //         $(".mainMenu a.pubHolidayLink").removeClass("linkLeft");
        //         $(".mainMenu a.setTimeLink").removeClass("linkLeft");

        //         // 탭
        //         $(".hospital_setting").removeClass("offDay");
        //         $(".hospital_setting").removeClass("pubHoliday");
        //         $(".hospital_setting").removeClass("setTime");
        //         $(".hospital_setting").addClass("basic");

        //     } else if ( getIndex == 1 ) {
        //         $(".mainMenu a.pubHolidayLink").removeClass("linkLeft");
        //         $(".mainMenu a.setTimeLink").removeClass("linkLeft");

        //         // 탭
        //         $(".hospital_setting").removeClass("offDay");
        //         $(".hospital_setting").removeClass("pubHoliday");
        //         $(".hospital_setting").removeClass("basic");
        //         $(".hospital_setting").addClass("setTime");

        //     } else if ( getIndex == 2 ) {
        //         $(".mainMenu a.pubHolidayLink").removeClass("linkLeft");
        //         $(".mainMenu a.setTimeLink").addClass("linkLeft");

        //         // 탭
        //         $(".hospital_setting").removeClass("offDay");
        //         $(".hospital_setting").removeClass("setTime");
        //         $(".hospital_setting").removeClass("basic");
        //         $(".hospital_setting").addClass("pubHoliday");

        //     } else {
        //         $(".mainMenu a.setTimeLink").addClass("linkLeft");
        //         $(".mainMenu a.pubHolidayLink").addClass("linkLeft");

        //         // 탭
        //         $(".hospital_setting").removeClass("pubHoliday");
        //         $(".hospital_setting").removeClass("setTime");
        //         $(".hospital_setting").removeClass("basic");
        //         $(".hospital_setting").addClass("offDay");
        //     }
        // });
    }

    setHospitalSettingTab(tab : "BASIC" | "TIME" | "HOLIDAY" | "OFF") : void {
        if( this.doSetHospitalSettingTab ) { this.doSetHospitalSettingTab(tab); }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.event_bus.$emit('basicSettingResize');
        this.event_bus.$emit('timeSettingResize');
        this.event_bus.$emit('holidaySettingResize');
        this.event_bus.$emit('offSettingResize');
    }

    /**
     * 병원 지정 휴일 멀티 삭제
     */
    deleteDayOffs() : void {
        this.event_bus.$emit('deleteDayOffs');
    }

}
</script>

<style scoped>
    /* 기본 설정 선택시 */
    .hospital_setting.basic .setTimeDiv, .hospital_setting.basic .pubHolidayDiv, .hospital_setting.basic .offDayDiv { display:none; }
    /* 진료 시간 선택시 */
    .hospital_setting.setTime .hospitalImgsDiv, .hospital_setting.setTime .pubHolidayDiv, .hospital_setting.setTime .offDayDiv { display:none; }
    /* 법정공휴일 선택시 */
    .hospital_setting.pubHoliday .setTimeDiv, .hospital_setting.pubHoliday .hospitalImgsDiv, .hospital_setting.pubHoliday .offDayDiv { display:none; }
    /* 병원휴일 선택시 */
    .hospital_setting.offDay .setTimeDiv, .hospital_setting.offDay .hospitalImgsDiv, .hospital_setting.offDay .pubHolidayDiv { display:none; }
    
    .mainMenu { float:left; padding-left: 30px;line-height: 66px;  width:100%; box-sizing: border-box; }
    .mainMenu a { transition:0.2s; font-size: 14px; font-weight: bold; height: 35px; line-height: 35px;; width:95px; border-radius: 20px; display: inline-block; text-align: center; }
    .mainMenu a.basicLink { text-align: left; }
    .mainMenu a.setTimeLink.linkLeft { margin-left:-5px;margin-right:15px; text-align: left !important; }
    .mainMenu a.pubHolidayLink.linkLeft { margin-left:-25px;margin-right:10px; text-align:left; }
    .mainMenu a.pubHolidayLink.on { margin-left:-30px; }
    .mainMenu a.on { background:#2b3b5f; color:#fff; text-align: center; margin-left:-20px; }
    .mainMenu a.on.basicLink { margin-left:0; }
    .mainMenu .btnBox input { margin-right: 30px }
    
    .content { margin-top:26px; }
    .title {font-size: 16px;font-weight: bold;display: block;margin-bottom: 20px;margin-top: 0;}

    .left_area_close .grp.settingBg { padding-left: 61px !important; }
    .content { margin-top:0; }
    
    /* .hospitalImgsDiv {position:relative; box-sizing: border-box; max-width: 970px; padding: 30px 30px 0 30px; }
    .hospitalImgsDiv .btnBoxPos { top:20px; }
    .hospitalImgsDiv p { font-size: 14px;opacity: 0.7; }

    .hospitalImgsDiv .num { z-index:20; position:absolute; left:7px; top:7px; width: 30px;text-align: center; height:17px;; line-height:17px;; background:#f1f3f5; border-radius:2px; background:rgba(43, 75, 95, 0.3);color:#fff; font-weight:bold; }
    
    .hospitalImgUl { margin-bottom:30px; }
    .hospitalImgUl li.added label { opacity:0; background:#2b3b5f; font-size:0; }
    .hospitalImgUl li.added:hover .del { display:block; }
    .hospitalImgUl li.added label:hover { opacity:0.2; border: none; }
    .hospitalImgUl li { position: relative; display: inline-block;margin-top: 20px; margin-right: 30px; background-size: cover !important; background-position: center center !important;width: 200px; height:153px; border-radius: 5px; border:1px solid #f1f3f5; }
    .hospitalImgUl input[type="file"] { display:none }
    .hospitalImgUl .del { display:none; z-index: 20; position:absolute; right:-10px; top:-10px; border-radius: 50%; width: 30px; height: 30px; background:#ff6060; font-size: 0;  }
    .hospitalImgUl label { transition:0.2s; cursor:pointer; z-index: 10; position: absolute; cursor: pointer; line-height: 153px; font-weight:bold; color:#477fff; text-align:center; border: 2px dashed #c6d7ff; background-color: #f4f7ff; width: 100%; height:100%; box-sizing: border-box; display:block;border-radius: 5px; }
    .hospitalImgUl label:hover { border-color:#477fff; background:#fff; }

    textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 150px; height:auto; box-sizing: border-box; font-weight:bold; }
    textarea:hover { border-color:#477fff }
    textarea:focus { background:#fff;  } */

    /* .setTimeDiv { padding: 30px; margin-bottom: 30px; }
    .setTimeUl .title span { font-size: 13px; margin-left:5px; opacity:0.7 }
    .setTimeUl { border-top:2px solid #f1f3f5;padding-top:20px; max-width: 940px; margin-bottom: 30px; }
    .setTimeUl li:first-child { position:relative; overflow:hidden;  }
    .setTimeUl .title { font-size: 16px; font-weight: bold; display: block; margin-bottom: 20px;    margin-top: 10px; }
    .setTimeUl > ul > li { overflow: hidden; position:relative;}
    .setTimeUl > ul > li > input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; }
    .setTimeUl > ul > li > input:hover { background-color:#f1f3f5; }
    .setTimeUl > ul > li > input:focus { background-color:#fff; border-color:#477fff; }
    .setTimeUl > ul > li > span { padding-right:20px; }
    .setTimeUl .descript { position: absolute; right:0; top:10px; }
    .setTimeUl .descript li { overflow:hidden; display:inline-block;margin-right:20px; }
    .setTimeUl .descript li:last-child { margin-right:0; }
    .setTimeUl .descript li .clr { float:left; margin-right:5px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; }
    .setTimeUl .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .setTimeUl .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .setTimeUl .descript li .clr.allday {background: #477fff !important;border-color: #477fff;}
    .setTimeUl .descript li .clr.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .contractSelect { transition:0.2s; cursor:pointer; font-size: 14px; font-weight:bold; appearance: none; border-radius: 5px; line-height: 50px; height:55px; border:1px solid #e7e9ea;padding: 0 20px; width:100%; box-sizing: border-box; background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 98%  center; background-repeat: no-repeat }
    .contractSelect:hover { background-color:#f1f3f5;  }
    .contractSelect:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .contractSelect option { height: 40px; padding:20px 0; }
    .contractUl {  margin-top:5px; border-left:1px solid #e7e9ea; border-top: 1px solid #e7e9ea }
    .contractUl li {transition:0.2s;overflow:hidden; position:relative; font-size: 12px; line-height: 60px; height:60px; border-bottom:2px solid #e7e9ea; }
    .contractUl li:hover { border-color:#477fff; }
    .contractUl li a { display:inline-block; width:100%; height: 100%; }
    .contractUl li a:hover { background:#f7f9fb; font-weight:bold; }
    .contractUl li .day { font-weight:bold; display:inline-block;text-align: center; width:14%; font-size: 13px;box-sizing:border-box; padding-left:30px; }
    .closeP {border-left: 5px solid #f1f3f5; }
    .contractUl li .workHours { display: inline-block; border-left: 1px solid #e7e9ea; line-height: 15px; }
    .contractUl li p { transition:0.2s; float:left; display:inline-block !important; width: 10%; text-align: center; border-right:1px solid #e7e9ea }
    .contractUl li .sp { width:4.5% }
    .contractUl select {transition:0.2s;font-size: 12px; float:left; cursor:pointer; display: inline-block; appearance: none; border-right:1px solid #e7e9ea;width: 14%; text-align:center; box-sizing: border-box; padding:0 20px; line-height: 60px; height:60px; }
    .contractUl select {background: url(../../assets/images/side_area/bt_open.png) no-repeat 90% center; }
    .contractUl select option { font-size: 14px;}
    .contractUl select:hover { background-color:#f1f3f5; }
    .contractUl select:focus { background-color:#fff; background-image: url(../../assets/images/side_area/bt_close.png) }
    .workHours span {display:inline-block; padding: 0 25px; }
    .contractUl .indi {position:absolute; top:23px; left:24px; display:inline-block; width: 10px; height:10px; border-radius: 50%; font-size:0;border:1px solid; font-weight: bold; text-align: center; color:#fff; } 
    .contractUl .indi.morn {background: #ffc00e !important;border-color: #f3b300;}
    .contractUl .indi.night {background: #846eff !important;border-color: #6c52ff;}
    .contractUl .indi.allday {background: #477fff !important;border-color: #477fff;}
    .contractUl .indi.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .dayUl  { overflow:hidden; border-right:1px solid #e7e9ea}
    .dayUl li { display: inline-block; width: 14.2857142857%; float:left; text-align:center; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .dayUl li:hover .day { background:#f1f3f5; }
    .dayUl li p { line-height: 35px; height: 35px; font-weight: bold; border-bottom: 1px solid #e7e9ea;border-top: 1px solid #e7e9ea; }
    .offDay .checkDiv a { cursor: default; }
    .offDay .checkDiv a span { opacity:0.3; cursor: default; background:#fff !important;  }
    .checkDiv a { display: block; height:45px; line-height:45px; box-sizing: border-box; font-weight: bold; border-bottom: 1px solid #e7e9ea; }
    .checkDiv a.on:hover { cursor:default }
    .checkDiv a.on span { background:#477fff !important;border-color:#1b5fff; color:#fff; }
    .checkDiv a:hover span { background: #f1f3f5; }
    .checkDiv a span { transition:0.2s; display: block; height:100%; line-height: 45px; background:#fff; box-sizing: border-box;}
    .checkDiv a.on.morn span {background: #ffc00e !important;border-color: #f3b300;}
    .checkDiv a.on.night span {background: #846eff !important;border-color: #6c52ff;}
    .checkDiv a.on.off span { background: #a1a5bb !important;border-color: red;}
    #docClrId {transition:0.2s;width: 100%;height: 50px;line-height: 50px;border-radius: 5px;background: #fff;border: 1px solid #e7e9ea;box-sizing: border-box;padding: 0 15px;text-align: left;font-weight: bold;  }
    #docClrId:hover { background: #f1f3f5; }
    .docDetailDiv .clr { position:absolute; right:35px; top:43px; display: inline-block; width:20px;height: 20px; border-radius: 50%; ; }

    .shiftDiv:hover .openP, .shiftDiv:hover .closeP, .shiftDiv:hover select { font-weight:bold; }
    .contractSetting { position: relative; max-width: 940px; margin-bottom: 30px; }
    .contractSetting .contractDiv input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; width: 100px;text-align: center; }
    .contractSetting .contractDiv input:hover { background-color:#f1f3f5; }
    .contractSetting .contractDiv input:focus { background-color:#fff; border-color:#477fff; }
    .contractSetting .seper { padding-right: 20px; }
    .contractDiv { position:relative;padding-top:22px;}
    .contractDiv label { position: absolute;top:0;font-size: 12px; }

    .btnBoxPos { position: absolute;right:0; top:-10px; }
    .btnBoxPos input { margin-left: 20px; }

    .historyDiv { padding: 0 30px 30px 0; } */

    /* .pubHolidayDiv { }
    .pubHolidayDiv .num { width:13%; text-align: center; }
    .pubHolidayDiv .yyyy { width:17%; }
    .pubHolidayDiv .time { width:25%; }
    .pubHolidayDiv .holidayName { width:25%; }
    .pubHolidayDiv .wether { width:20%; }
    .pubHolidayDiv .wether select {font-weight:bold; transition:0.2s; appearance: none; border-radius:5px; height:30px; line-height: 30px;cursor:pointer; background:transparent; box-sizing: border-box;border:1px solid #fff;}
    .pubHolidayDiv .wether select:hover { font-weight:bold; background:#f1f3f5; }
    .pubHolidayDiv .wether select:focus { border-color:#477fff !important; background:#fff; }
    .pubHolidayDiv .holidayUl .time span {width:60px;display:inline-block; }
    .pubHolidayDiv li:hover .wether select { font-weight:bold; border:1px solid #000;  padding: 0 10px;}
    */

    /* .offDayDiv {  }
    .offDayDiv .checkbox { width:10%; text-align: center; }
    .offDayDiv .num { font-weight:bold; width:10%; text-align: left; padding-left: 20px; box-sizing: border-box; }
    .offDayDiv .yyyy { width:15%; }
    .offDayDiv .time { width:25%; }
    .offDayDiv .holidayName { width:20%; }
    .offDayDiv .off { width:20%; }
    .offDayDiv .holidayUl li { transition:0.2s; }
    .offDayDiv .holidayUl li:hover { background:#f7f9fb }
    .offDayDiv .holidayUl li:hover .del { opacity:1; } 
    .offDayDiv .holidayUl li .del  { opacity:0; transition:0.2s; position:absolute; right: 20px; top:12px; width: 30px; height:30px; background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .offDayDiv .holidayUl li .del:hover { background-color:#ff1f1f}
    .offDayDiv input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 20px;height: 20px;border-radius:3px; margin-top:15px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;font-size: 0;margin-right: 8px;}
    .offDayDiv input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;}
    
    .offDayDiv .holidayUl .off span {width:60px;display:inline-block; }
    .offDayDiv .holidayUl .time span {width:100px;display:inline-block; }

    .holidayUl li { position:relative; font-weight:bold; width:100%; overflow:hidden; line-height:55px; height:55px; border-bottom:1px solid #f1f3f5; }
    .holidayUl li > * { float:left; }
    .holidayUl li.gray {background:#f7f9fb; }
    .holidayUl li.gray > * { opacity:0.4;  }
    .holidayUl li.gray > * span {   }
    .holidayUl li.gray:hover > * { opacity:1 }
    .holidayUl li.replacement { opacity:0; transition:0.2s; overflow:hidden; height: 0; line-height: 0; color:#477fff; }
    .holidayUl li.replacement .wether select {width:80px !important;color:#477fff;}
    .holidayUl li.replacement .clr { position:absolute; top:22px; left: 20px; width:10px; height:10px; border-radius: 50%; display:block; background:#477fff; }
    .holidayUl li.replacement .holidayName span { padding-left:10px; color:#477fff; }
    .holidayUl li.replacement input[type="text"] {color:#477fff; transition:0.2s; font-size:12px; background: transparent; font-weight: bold;; }
    .holidayUl li.replacement:hover input[type="text"], .holidayUl li.replacement:hover select { box-sizing: border-box; padding:0 10px; border:1px solid #000; line-height:30px; height:30px; border-radius: 5px;cursor:pointer; width: 110px; }
    .holidayUl li.replacement:hover input[type="text"]:hover, .holidayUl li.replacement:hover select:hover { background:#f1f3f5; }
    .holidayUl li.replacement:hover input[type="text"]:focus, .holidayUl li.replacement:hover select:focus { background:#fff; border-color:#477fff; }
    .holidayUl li.replacement .yyyy select {color:#477fff; width:70px !important; transition:0.2s;background:transparent;font-weight: bold; appearance: none; line-height:30px; height:30px; border-radius: 5px; font-size:12px; }
    .holidayUl.on .replacement {opacity:1; height: 55px; line-height: 55px;} */

    /* .btnAdd { position:absolute; right: 40px; bottom:40px; }
    .btnAdd { opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .btnAdd:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;} */

</style>