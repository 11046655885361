<template>
    <div class="modal repeatModify" :class="{ b3 : repeat_text != null && repeat_text.length == 3 }" >
        <h1 class="" id="">반복일정 {{ repeat_modify_action }}</h1>
        <div class="content">
            <div class="modifyRepeatDiv">
                <ul class="modifyRepeatUl">
                    <li :key="index" v-for="(obj, index) in repeat_text">
                        <span class="txt">
                            {{ obj.text }}
                        </span>
                        <p class="radioBtn">
                            <input type="radio" :id="`repeatModify${index}`" class="radio_round03" onClick="return false;">
                            <label :for="`repeatModify${index}`" @click="repeatModify(obj.code)"></label>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="btn">
                <input type="button" value="취소" @click="repeatModifyModalClose" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { t_event } from '@/model/event';

@Component({

}) export default class EventRepeatModify extends Mixins(VueHoduCommon) {
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventRepeatModify ?: any;

    /**
     * EventInfo.State
     */
    @EventInfo.State event                 ?: t_event;
    @EventInfo.State is_first_repeat_event ?: boolean;
    @EventInfo.State repeat_modify_action  ?: string;

    repeat_text : any[] = [];
    
    mounted() {
        // 반복 일정의 첫 일정이 아니라면 이후 일정 삭제&수정이 있다
        if( this.is_first_repeat_event == false ) {
            this.repeat_text.push({ 
                text : `이후 일정 모두 ${this.repeat_modify_action}`, 
                code : 'F'
            });
        }

        // 전체 반복 일정 삭제&수정
        this.repeat_text.push({ 
            text : `전체 반복 일정 ${this.repeat_modify_action}`,
            code : 'A' 
        });

        // 이 일정만 삭제&수정
        this.repeat_text.push({
            text : `이 일정만 ${this.repeat_modify_action}`,
            code : 'E'
        });
    }

    /**
     * 반복 수정/삭제 옵션 모달 닫기
     */
    repeatModifyModalClose() : void {
        this.doSetShowEventRepeatModify(false);
    }

    /**
     * repeat modify 선택
     */
    async repeatModify(code : string) : Promise<void> {

        // 삭제인 경우 Event.vue에서 등록한 window 함수 repeatDelete 실행
        if( this.repeat_modify_action == '삭제' ) {
            await window['repeatDelete'](code);
        }

        // 수정인 경우 Event.vue에서 등록한 window 함수 repeatModify 실행
        else {
            await window['repeatModify'](code);
        }
        
        this.doSetShowEventRepeatModify(false);
    }
}
</script>

<style scoped>
    .modal.repeatModify { width:500px; position:absolute; z-index:10000000000000000000000000000000; background:#fff; border-radius:5px;margin:0 auto;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden; margin-top: -117px;}
    .modal.repeatModify.b3 {  margin-top: -143px }
    
    .modal.repeatModify h1 { font-size: 19px; padding: 0 30px;height:85px; line-height:100px; width:100%; box-sizing: border-box; border-bottom:1px solid #f1f3f5; }
    .modal.repeatModify .modifyRepeatUl li {transition:0.2s;overflow:hidden;position:relative; height:60px; line-height:60px;border-top:1px solid #f1f3f5; padding:0 30px; width:100%; box-sizing: border-box; }
    .modal.repeatModify .modifyRepeatUl li .txt { font-size:14px; font-weight:bold; height:100%; opacity:0.6; }
    .modal.repeatModify .modifyRepeatUl li:hover .txt { opacity:1; } 
    .modal.repeatModify .modifyRepeatUl li:hover { background:#f1f3f5; }
    .modal.repeatModify .modifyRepeatUl li:first-child { border-top:0 none; }
    .modal.repeatModify .modifyRepeatUl li * { display:inline-block;  }
    .modal.repeatModify .modifyRepeatUl li .radioBtn { margin-top:14px; float:right !important; }
    .modal.repeatModify .modifyRepeatUl {border-bottom: 1px solid #f1f3f5;} 
    .modal.repeatModify .btn { padding:0 !important; width:100%;}
    .modal.repeatModify .btn input { height:70px;line-height:70px;background:#fff; font-size:14px; font-weight:bold;text-align:center;width:100%; transition:0.2s; }
    .modal.repeatModify .btn input:hover { background:#f1f3f5; border-radius: 0 0 5px 5px; }
    .modal.repeatModify label:after { position:absolute; content:''; display:block; left:0; top:0; width:100%; height:100%; }
    .modal.repeatModify input[type='radio']:not(old)+label {height: 40px;margin: 0;line-height: 40px;box-sizing: border-box;border-radius: 5px;padding-top: 0;padding-left: 30px;padding-right: 13px;background: #c1cfd8 url(../../../assets/images/contents/checked_fff.png) no-repeat 6px center !important;background-size: 22px;}
    .modal.repeatModify input[type='radio']:not(old)+label {width: 30px;height: 30px;padding: 0;border-radius: 50%;top: 14px;right: 6px;background-position: 3px 3px !important;}
    .modal.repeatModify input[type='radio']:not(old):checked+label, .modal.repeatModify input[type='radio']:not(old)+label:hover{background: #477fff url(../../../assets/images/contents/checked_fff.png)no-repeat 6px center !important;background-position: 3px 3px !important;color: #fff !important;}

</style>