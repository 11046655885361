<template>
    <div class="section_ce_fix">
        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">공지사항 상세보기</h3>
            <p class="btn_group" style="">
                <input type="button" id="" name="" value="수정" class="input_btn" v-if="admin_flag" @click="actionModify()">
                <input type="button" id="" name="" value="삭제" class="input_btn" v-if="admin_flag" @click="actionDelete()">
            </p>
        </div>
        <div class="content notice">
            <div class="grp">
                <div class="when">
                    <label for="noti_datepicker">공지 등록일</label>
                    <input type="button" id="noti_datepicker" :value="start_date" class="input_txt" style="" readonly/>
                </div>
                <!-- 상세에서 보여지는 share div -->
                <div class="share">
                    <!-- 등록때는 공유정보, 상세에선 작성자 -->
                    <h4>작성자</h4>
                    <p class="whoWrote"><span class="fPic" :style="{backgroundImage: `url(${admin_image_url})`}">사진</span>호두관리자</p>
                </div>
            </div>
            <div class="editArea">
                <input type="text" id="noti_title" class="input_txt " placeholder="제목을 입력하세요." :value="title" disabled/>
                <div class="edtContentDiv">
                    <div id="modal_noti_contents" v-html="body"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { t_notification } from '@/model/osm';

const EtcInfo    = namespace('EtcInfo');
const dateFormat = require('dateformat');

declare var CKEDITOR: any;

@Component({
    components : {
    }
})
export default class HoduNoticeDetail extends Mixins(VueHoduCommon) {
    @EtcInfo.State hodu_noti_uid !: string;
    @EtcInfo.Action doHoduNotiUid ?: any;

    admin_flag      : boolean = false;
    title           : string  = "";
    body            : string  = "";
    o_notice        : any     = null;
    start_date      : string  = "";
    audit_user_name : string  = "";
    audit_user_pic  : string  = "";
    admin_image_url : string  = require('@/assets/images/contents/ic_hoduShare.png');  

    async mounted() : Promise<void> {
        if ( !this.hodu_noti_uid ) {
            alert('uid 가 잘못되었습니다');
            this.doHoduNotiUid("");
            this.movePrevPage();
            return;
        }

        this.admin_flag = await this.getAdminCheck();

        // 공지 상세
        this.getNoticeDetatil();
    }

    /**
     * 공지사항 상세화면 조회
     */
    async getNoticeDetatil() : Promise<void> {
        const vue = this;

        $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 290));

        await this.hodu_api_call(`api/v1/groupsweb/getNoticeDetail/0/0?uid=${this.hodu_noti_uid}`, API_METHOD.GET, {}, false)
            .then(async(response) => {
                // 데이터가 없을경우
                if ( !response.data.data.noti_data ) {
                    vue.movePrevPage();
                    return;
                }
                else {
                    this.o_notice        = response.data.data.noti_data;
                    this.title           = this.o_notice.noti_info.title;
                    this.body            = this.o_notice.noti_data.body;
                    this.start_date      = dateFormat(this.o_notice.noti_info.start_date, 'yyyy.mm.dd');
                    this.audit_user_name = this.o_notice.audit_user_name;

                    if ( this.o_notice.audit_user_pic ) {
                        this.audit_user_pic = '/app_images/' + this.o_notice.audit_user_pic;
                    }
                    else {
                        this.audit_user_pic = require("@/assets/images/contents/im_photoB.gif");
                    }
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, true);
            });   
    }

    /**
     * 공지사항 수정
     */
    actionModify() : void { 
        this.doHoduNotiUid(this.hodu_noti_uid);
        this.hodu_router_push(`/hodu/${new Date().getTime()}/notice_create`);
    }
    
    /**
     * 공지사항 삭제
     */
    async actionDelete() : Promise<void> { 
        const vue = this;

        this.hodu_show_dialog("alert", "공지사항을 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async () => {
                await this.hodu_api_call(`api/v1/notification/notice/${this.hodu_noti_uid}/HODU/0/0`, API_METHOD.DELETE, {})
                    .then(async(response) => {
                        this.doHoduNotiUid("");
                        vue.movePrevPage();
                    })
                    .catch(async(e) => {
                        this.doHoduNotiUid("");
                        this.hodu_error_process(e, true, true);
                    });    
            },
        ]);
    }
}
</script>


<style scoped>
  .title_box { line-height:61px; height:61px; }
  .notice.content .grp > div { display:inline-block; float:left; height:80px;padding: 0 25px;box-sizing: border-box; }
  .notice.content .grp > div > * {display: block; }
  .notice.content .grp > div > label, .notice.content .grp > div h4 { width:100%; color:#7c88a2; font-size:13px; font-weight:bold;margin-top:5px; line-height:40px;height:30px; float:left; }
  .notice.content .grp {height: 82px;border-bottom: 2px solid #e7e9ea;padding: 0 px;box-sizing: border-box;width: 100%;border-right: 1px solid #e7e9ea;}
  .notice.content .editArea {margin-top: 0;border-right: 1px solid #e7e9ea;}
  #noti_title { transition:0.2s; font-weight:bold; line-height: 70px; height: 70px;padding:0 20px; box-sizing:border-box; width:100%;font-size:18px;border-bottom:2px solid #e7e9ea;}
  #noti_title:hover { background:#f1f3f5; }
  #noti_title:focus { border-bottom:2px solid #477fff; background:#fff; }
  #noti_title:disabled {background: #fff;color: #273044;}
  #noti_datepicker { font-size:15px; font-weight: bold;}
  #noti_datepicker:disabled { color:#273044 !important; cursor:default; }
  .notice .when { border-right:1px solid #e7e9ea; width:50%;}
  .notice .share { padding:0 30px; position:relative; }
  .notice .share p { height: 37px;line-height: 37px; margin-top: 5px; }
  .notice .when input { height: 33px;line-height: 33px;width: 100%;text-align: left; }
  .notice .when input:hover, .notice .when input:focus { color:#273044; cursor:default }
  
  .notice .share span.fPic {left: 24px;top: 39px;border: 1px solid #e7e9ea;background-color: #f1f3f5;font-size: 0;min-width: 27px !important;width: 27px !important;background-position: center center !important;background-size: cover !important;height: 27px !important;border-radius: 50%;}
  .notice .share .whoWrote {padding-left: 40px;font-weight: bold;font-size: 14px;}
  
  .notice input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
  .notice input[type='checkbox']:not(old) + label span { color:#7c88a2; }
  .notice input[type='checkbox']:not(old) + label span em { color:#477fff; font-style:normal; }
  .notice .share span{position: absolute;left: 55px;width: 100% !important;height: 30px !important;max-width: 200px !important;min-width: 100px !important;z-index: 1000000;top: 36px;line-height: 30px;font-size: 14px;font-weight: bold;}
  .notice .share input[type='checkbox']:not(old):checked + label {background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;border: 2px solid #477fff;}
  .notice .share input[type='checkbox']:not(old):checked + label span { color:#273044; }
  .content.notice {width: 100%;max-width: 1000px;min-width: 700px;}
  .cke_chrome { border:0 none; }
  .cke_top { background:#f1f3f5; border-bottom:0 none; }
  .cke_bottom { border-top: 0 none;background: #f1f3f5; padding:0; line-height:40px; height:40px; }
  .cke_resizer { display:none; }
  #modal_noti_contents { transition:0.1s;line-height: 23px;font-size: 14px;padding: 25px 30px 50px !important;text-align: left; overflow-y:auto; }
</style>