	/* 그룹 */	
	.grpDetailTitle { height:60px; line-height: 60px }
	.schLi .schBtn { right:30px; }
	#grpScroll .mCSB_inside > .mCSB_container { margin-right:0; }
	#addTeam {position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 1110000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(../images/contents/btn_add_fff.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;}
	.grp.settingBg { top:63px; }
	.settingBg .btns { margin-top:11px; margin-right:30px;  }
	.settingBg .btns input { margin-left: 15px; }
	.main.up .first {height: 200px;}
	#container #section_ce #grpScroll .schedule_box {max-width: 100%;overflow: hidden;clear: both;float: left;width: 100%;padding: 0;box-sizing: border-box;}
	#grpScroll .content { width:100%;margin-top: 65px !important; }
	/*#grpScroll .grp.settingBg h3.selectTitle { cursor:pointer; } */
	/* #grpScroll .grp.settingBg h3.selectTitle .txt {background: url(../images/side_area/bt_open.png) no-repeat center right;padding-right: 35px !important;} */
	#grpScroll .settingBg ul.dropdown {width: 370px;left: 280px;top: 60px;}
	#grpScroll .settingBg .btns { float:right; }
	.fave_border { opacity:0.8 !important; box-shadow: 0 0 0 rgba(0,0,0,0.3) !important; }
	.fave_border_on { border:1px solid #ffa70e !important; box-shadow: 0 13px 30px rgba(255, 167, 14,0.5) !important; }
	.grpUserList span.placeNm {position: absolute;width: 250px;background: white;display: block;left: 20px;height: auto;overflow: hidden;font-size: 16px;white-space: nowrap;text-overflow: ellipsis;font-weight: bold;padding: 20px 30px 15px 0;/* border-bottom: 1px solid #f1f3f5; */bottom: 33px;border-radius: 0 0 4px 4px;box-sizing: border-box;}
	
	.grpPage .first {transition:0.4s;position:relative;overflow: hidden;width: 100%;box-sizing: border-box;height: 490px;}
	.grpPage .up .first { transition:1s; }
	.grpPage .first .grp1 {z-index:1;transition: 0.2s;float: left;display: inline-block;width: 283px;box-sizing: border-box;padding-left: 30px;height: 490px;padding-top: 30px;border-right: 1px solid #e7e9ea;padding-right: 30px;position: absolute;left: 0;top: 0;}
	.grpPage .first .grp1.is_modify:hover { cursor: pointer; background-color: #f1f3f5; }
	.grpPage .first .grp1:hover a.hoduC_btn.create { background-color: #fff !important; }
	/* .grpPage .first .grp1 .placeNm:hover { cursor: pointer; background-color: #f1f3f5; } */
	.grpPage .first .grp1 a { display:none; }
	.grpPage .grp1 span.faved {opacity: 1 !important; z-index:100;right: 70px;top: 65px;}
	.grpPage .grp1 .img.fave_border_on {border: 1px solid #ffa70e !important;box-shadow: 0 13px 30px rgba(255, 167, 14,0.5) !important; }
	.grpPage .hc .first .grp1 a { display: nonessssss; opacity:0; position: absolute;bottom: 0;left: 50%;margin-left: -85px;width: 170px;height: 45px;line-height: 45px;font-weight: bold;font-size: 14px;color: #fff;background: #477fff;border-radius: 40px; transition:0.2s;text-align: center; }
	.grpPage .hc .first .grp1:hover a {bottom: 40px; opacity:1;} 
	.grpPage .first .img {position: absolute;top: 30px;border: 1px solid #f1f3f5;left: 30px;width: 220px;background: url(../images/contents/groupImg.png) no-repeat center center;display: block;background-size: cover !important;font-size: 0;height: 140px;background-position: center center !important;transition: 0.2s;}
	.grpPage .first .img {background-image: url(../images/footer/g11.jpg);}
	/* .grpPage .first .placeNm {margin-bottom: 8px;font-size: 16px;padding-right: 40px;line-height: 20px;box-sizing: border-box;width: 100%;} */ /* 대시보드 수정으로 인한 주석 */
	.grpPage .first .descript {font-weight:bold; opacity:0.5;font-size: 12px;line-height: 18px;}
	.grpPage .first .cl {position:absolute;transition:0.2s; top: 190px;right: 30px;width: 15px;height: 15px;border-radius:50%;font-size:0;}
	.grpPage .first .cl:hover { transform:scale(1.15); cursor:pointer; }
	.first .grp2 {position:relative;float: left;display: inline-block;width:100%;padding: 0;padding-left: 283px;box-sizing: border-box;height: 490px;}
	#wrap .first .grp2:hover .bigger { display:none; }
	#wrap .first.wider .bigger {display: block;background-image: url(../images/contents/month_bt_next.svg);border-color: transparent;background-color: transparent;margin-left: 0;width: 50px !important;height: 100%;top: 0;margin-left: 0;left: 0;margin-top: 0;border-radius:0;}
	#wrap .first.wider .bigger:hover {background-image: url(../images/contents/month_bt_next_on.svg);border-color: transparent;background-color: #e7e9ea;}
	#wrap .first .bigger {transition:0.2s;display:none;position: absolute;width: 30px !important;left: 0;padding: 0;height: 40px;border-radius: 20px 0 0 20px;background: #fff url(../images/contents/month_bt_prev.svg) no-repeat center center;background-size: 22px !important;font-size: 0;border: 1px solid #e7e9ea;margin-top: -20px;top: 50%;border-right: 0 none;margin-left: 415px;z-index: 10000000;}
	#wrap .first .bigger:hover {background: #fff url(../images/contents/month_bt_prev_on.svg) no-repeat center center;}
	.first .grp2 .push > ul > li {width: 100%;transition: 0.1s;}
	.first .grp2 .push h5.title {display:block;float: left;line-height: 70px !important;height: 70px;margin-right: 30px;font-weight: bold;border-right: 1px solid #e7e9ea;padding-top: 0;width: 110px;font-size: 14px;text-align: center;}
	.first .grp2 .push h5.title a { display:inline-block; width:100%; height:100%; }
	.first .grp2 .push h5.title a:hover { color:#477fff; }
	.first .grp2 .push ul {height: 70px;overflow: hidden;box-sizing: border-box;width: 100%;margin-top: -12px;float: left;}
	.first .grp2 .push h5 { display:inline-block;overflow: hidden;display:none; }
	.first .grp2 .push a.link { margin-top: 0;line-height: 71px;height: 71px;font-size: 14px;font-weight: bold; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;padding-right: 100px;}
	.first .grp2 .push a.link:hover { color:#477fff; }
	.first .grp2 .push a span {width:55px;font-size: 10px;line-height: 18px;display: inline-block;background: #f1f3f5;box-sizing:border-box;text-align:center;color: #000 !important;margin-right: 15px;margin-top: 26px;float: left;border-radius: 5px;}
	.first .grp2 .push a.new span { background: #ff6e6e; color:#fff !important }
	.first .grp2 h5 { font-size: 16px;padding-top: 30px; }
	.first .grp2 .minus70 { margin-top:-70px; }
	.first .grp2 .plus70 { margin-top: 70px; }
	
	.push .btns {position: absolute;top: 18px;right: 30px;}
	#wrap .first .prev {transition:0.2s;display: inline-block;width: 35px;height: 35px;text-align: center;border-radius: 50%;border: 1px solid #e7e9ea;cursor: pointer;background-image: url(../images/contents/month_bt_prev.svg);background-repeat: no-repeat;background-position: 5px 6px;line-height: 25px;background-size: 22px !important;overflow: hidden; }
	#wrap .first .next {transition:0.2s;display: inline-block;cursor: pointer;width: 35px;height: 35px;text-align: center;border-radius: 50%;border: 1px solid #e7e9ea;background-image: url(../images/contents/month_bt_next.svg);background-repeat: no-repeat;line-height: 25px;background-size: 22px !important;overflow: hidden;background-position: 5px 6px;margin-left: 15px;}
	#wrap .first .prev:hover { background-color:#f1f3f5; background-image: url(../images/contents/month_bt_prev_on.svg); }
	#wrap .first .next:hover { background-color:#f1f3f5; background-image: url(../images/contents/month_bt_next_on.svg); }
	
	#wrap .first.wider .grp2 { padding-left:50px; }
	#wrap .first.wider .grp1 { z-index:-1;opacity:0; }
	#wrap .first.wider .grp2:after { content: '';display: block;width: 50px;height: 100%;background: #f1f3f5;position: absolute;left: 0;top: 0;cursor:pointer;} 
	.first .grp2 { transition:0.2s; }
	.first .grp2 > * { padding:0 30px; }
	
	.first .grp2 .current h5 {font-size:14px;padding: 0;display: inline-block;}
	.current h5.king:before {background: url(../images/contents/ic_king.png) no-repeat left 7px;content: '';display: inline-block;width: 25px;height: 31px;margin-top: 16px;margin-right: 10px;float: left;background-size: 20px;}
	.current h5.manager:before {background: url(../images/contents/ic_manager.png) no-repeat left 7px;content: '';display: inline-block;width: 25px;height: 31px;margin-top: 16px;margin-right: 10px;float: left;background-size: 20px;}
	.first .grp2 .current {position:relative;height: 70px;line-height: 70px;border-bottom: 1px solid #e7e9ea;}
	.first .grp2 .current > * {float: none;}
	.first .grp2 .current img {width: 35px;height: 35px;margin-top: 18px;float: left;display: inline-block;}
	.first .grp2 .current h5 span {color:#477fff;}
	.first .grp2 .current a.quickMenu { border-radius:50%;background: url(../images/contents/ic_quickMenu.svg) no-repeat 4px 5px;background-size: 27px;}
	.grpPage .current .btns a:hover, .grpPage .current .btns a.on {background-color: rgba(0,0,0,0.1);}
	.first .grp2 .current a.quickMenu:hover {}
	.first .grp2 .current a.del {  opacity:0;color: #ff6e6e;padding: 0 20px;height: 35px;display:inline-block;margin-left:20px;line-height: 34px;position: relative;font-weight: bold;z-index: 10000;border-radius: 20px;background: #fff;transition: 0.1s;border: 1px solid #e7e9ea;}
    .first .grp2:hover .current a.del { opacity:1; }
    .first .grp2 .current:hover a.del:hover { color:#fff; background-color: #ff6e6e; border:1px solid #ff6e6e; }
    .first .grp2 .current:hover a.del.changeKing:hover { color:#fff;  background-color: #ff6e6e; border:1px solid #ff6e6e;}
	.first .grp2 .current a.del.changeKing { color:#ff6e6e }
	ul#quickMenu:after {content: '';display: block;position: absolute;top: 0;width: 100%;height: 1px;background: #e7e9ea;}
	.first .grp2 .current .dropdown li {border-top: 1px solid #e7e9ea;display: inline-block !important;width: 20%;float: left;height: 100px !important;box-sizing: border-box;border-right: 1px solid #e7e9ea;line-height: 100px !important;border-radius: 0 !important;}
	.first .grp2 .current .dropdown {top: 70px;border-radius: 0 !important;height: 100% !important;background: #fff;box-shadow: none;border-bottom: 1px solid #e7e9ea;height: auto !important;}
	.first .grp2 .current .dropdown li a {box-sizing:border-box;height: 100px !important;line-height: 130px !important;background: #fff;font-size: 13px;text-align: center;font-weight: bold;border-radius: 0 !important;border-bottom: 1px solid #e7e9ea;}
	.first .grp2 .current .dropdown li.mem a {background: url(../images/contents/ic_mem_sm_usr.png) no-repeat center 27px;}
	.first .grp2 .current .dropdown li.notice a {background: url(../images/contents/ic_notice_blue.png) no-repeat center 28px;background-size: 18px;}
	.first .grp2 .current .dropdown li.file a { background: url(../images/contents/ic_fileBox_blue.png) no-repeat center 28px;background-size: 18px;}
	
	.hc .first .grp2 .current .dropdown li.mem a {background: url(../images/contents/ic_mem_sm_on.png) no-repeat center 27px;}
	.hc .first .grp2 .current .dropdown li.notice a {background: url(../images/contents/ic_notice_sm_on.png) no-repeat center 27px;}
	.hc .first .grp2 .current .dropdown li.sch a {background: url(../images/contents/ic_sch_sm_on.png) no-repeat center 27px;}
	.hc .first .grp2 .current .dropdown li.file a {background: url(../images/contents/ic_file_sm_on.png) no-repeat center 27px;}
	.hc .first .grp2 .current .dropdown li.work a {background: url(../images/contents/ic_work_sm_on.png) no-repeat center 27px;}
	.hc .first .grp2 .current .dropdown li.msg a {background: url(../images/contents/ic_msg_sm_on.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc6 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc6.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc6 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc6.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc6 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc6.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc6 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc6.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc6 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc6.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc6 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc6.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc7 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc7.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc7 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc7.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc7 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc7.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc7 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc7.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc7 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc7.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc7 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc7.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc8 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc8.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc8 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc8.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc8 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc8.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc8 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc8.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc8 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc8.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc8 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc8.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc9 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc9.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc9 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc9.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc9 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc9.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc9 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc9.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc9 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc9.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc9 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc9.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc5 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc5.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc5 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc5.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc5 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc5.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc5 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc5.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc5 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc5.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc5 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc5.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc4 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc4.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc4 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc4.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc4 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc4.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc4 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc4.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc4 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc4.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc4 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc4.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc3 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc3.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc3 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc3.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc3 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc3.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc3 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc3.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc3 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc3.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc3 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc3.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc2 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc2.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc2 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc2.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc2 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc2.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc2 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc2.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc2 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc2.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc2 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc2.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc1 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc1.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc1 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc1.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc1 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc1.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc1 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc1.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc1 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc1.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc1 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc1.png) no-repeat center 27px;}

	.section_ce_fix.pr_dc0 .first .grp2 .current .dropdown li.mem a {background: url(../images/menucons/ic_mem_sm_dc0.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc0 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc0.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc0 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc0.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc0 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc0.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc0 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_flow_sm_dc0.png) no-repeat center 27px;}
	.section_ce_fix.pr_dc0 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc0.png) no-repeat center 27px;}
	
	.first .grp2 .current .dropdown li.new:after {position: absolute;content: '';width: 8px;right: 20px;top: 20px;height: 8px;border-radius: 50%;background: #ff6060;}
	
	.first .grp2 .current .dropdown li:hover{ background:#f1f3f5; }
	.first .grp2 .current .bg { display:none; }
	.first .grp2.hover .current .bg {transition:0.2s;cursor:pointer;content: '';display: block;    background: rgba(39, 48, 68, 0.1);width: 100%;height: 420px;position: absolute;z-index: 1;left: 0;top: 70px;}
	.first .grp2.hover .current .bg:hover { background: rgba(39, 48, 68, 0.15);}
	.first .grp2 .current .btns {float: right;}
	.grpPage .current .btns a {width: 35px;height: 35px;display: inline-block;position: relative;margin-top: 17px;background: rebeccapurple;margin-left:10px;}
	.grpPage .current .btns a.edit { border-radius:50%; background: url(../images/contents/ic_pen.svg) no-repeat center center; }
	.grpPage .current .btns a:hover span.ab { display:block; }
	.grpPage .current .btns a span.ab {display:none; position: absolute;top: 49px;width: 70px;height: 30px;line-height: 30px;z-index: 100000;right: 0;font-weight: bold;color: #fff;background: rgba(46, 56, 86,0.9);border-radius: 5px;text-align: center;}
	.grpPage .current .btns a.edit span.ab { width:50px;  }
	/* .grpPage  a.hoduC span.ab { width:140px; } 
	.grpPage  a.hoduC {padding: 0 20px;height: 35px;width: auto !important;display: inline-block;line-height: 34px;opacity: 1;position: static;color: #000;font-weight: bold;bottom: 50px;float: left;margin-top: 17px !important;z-index: 10000;border-radius: 20px;background: #fff;transition: 0.1s;border: 1px solid #e7e9ea;}
	.grpPage  a.hoduC span {position: static !important;font-size: 12px;opacity: 1;} */
	.grpPage  a.hoduC  {transition:0.2s;opacity:1 !important;bottom: 35px !important; display:block;width:140px;border:1px solid #36bdff;   background-color: #36bdff !important;background-image: linear-gradient(to right, #36bdff , #007eff 141%)!important; color:#fff !important; }
	.grpPage  a.hoduC:hover { box-shadow: 0 5px 6px rgba(35, 164, 255, 0.3); }
	.grpPage  a.hoduC span {  color:#fff !important;  }
	.grpPage  a.hoduC span.ab {display: inline-block;color: #000;font-size: 14px !important;background: none;width: auto !important;top: 0;height: auto;opacity: 1 !important;position: static;}
	
	.grpPage a.hoduC_btn  {transition:0.2s;opacity:1 !important;bottom: 35px !important; display:block;width:170px;border:1px solid #f1f3f5;  background-color: #f1f3f5 !important; color:#477fff !important; }
	.grpPage a.hoduC_btn span { color:#477fff; }
	.grpPage a.hoduC_btn:hover span { color:#fff; }
	.grpPage a.hoduC_btn:hover { background:#477fff !important; border-color:#477fff; color:#fff !important; box-shadow: 0 5px 6px rgba(35, 164, 255, 0.3); }
	
	
	.first .grp2 .push {background:#fff;position:relative;height: 50px; }
	.first .usage {background:#fff;position:relative;height:218px;padding:0;box-sizing:border-box;border-top: 1px solid #e7e9ea;}
	.grp2 .usage .btns { position:absolute; right:30px;top:30px; }
	.grp2 .usage .btns input {height: 25px;line-height: 25px;font-size: 14px;}
	.usage .btns .dropdown {width: 100px;right: 0;left: auto;top: 35px;}
	.grp2 .usage h5 { height:40px; padding:25px 30px 0 }
	.grp2 .usage h5 input {line-height: 20px !important;height: 20px;font-size: 16px;}
	.grp2 .usage .scroll {width: 100%;overflow-x: auto;height: 154px;padding: 0 30px;box-sizing: border-box;}
	.grp2 .usage ul.chartUl { width: 98%;margin: 0 1%;padding-left: 30px;box-sizing: border-box;height: 90px;border-bottom: 1px solid #f1f3f5;padding-top: 25px;position: relative;min-width: 810px;}
	.grp2 .usage ul.chartUl > li {position:relative;width: 14.2857143%;height: 100%;display: inline-block;float: left;text-align:center;}
	.grp2 .usage ul.chartUl > li:hover .day { opacity:1;}
	.grp2 .usage ul.chartUl > li ul { width:70px; height:100%; display:inline-block; text-align:center; }
	.grp2 .usage ul.chartUl > li ul li {transition:0.2s;position: relative;width: 9px;height: 100%;background: #f1f3f5;display: inline-block;border-left: 2px solid #fff;border-right: 2px solid #fff; }
	.grp2 .usage ul.chartUl > li ul li p {position: absolute;width: 100%;bottom: 0;}
	.grp2 .usage ul.chartUl > li ul li p.bar { min-height:1px;background:#ff8e8e; font-size:0; transition:0.2s; }
	.grp2 .usage ul.chartUl > li ul li p.bar.work { background: #6e87bf; } 
	.grp2 .usage ul.chartUl > li ul li p.bar.file { background: #92c5c2; }
	.grp2 .usage ul.chartUl > li ul li:hover {background:#e7e9ea} 
	.grp2 .usage ul.chartUl > li ul li:hover p.bar {background: #f96161;}
	.grp2 .usage ul.chartUl > li ul li:hover p.bar.work {background: #506eb1;}
	.grp2 .usage ul.chartUl > li ul li:hover p.bar.file {background: #54a9a4}
	.grp2 .usage ul.chartUl > li ul li p.howMany {display: none !important;top: -28px;width: 30px;height: 20px;left: 50%;margin-left: -15px;border-radius: 5px;background: rgba(46, 56, 86,0.9);color: #fff;font-size: 11px;font-weight: bold;display: block;line-height: 20px;}
	.grp2 .usage ul.chartUl > li ul li:hover p.howMany { display:block !important;opacity:1 !important; }
	.grp2 .usage .chartUl p.day {color:#2e3856; transition:0.2s;position: absolute;bottom: -30px;left: 50%;margin-left: -30px;width: 60px;display: block;font-weight: bold;opacity: 0.5;}
	.grp2 .usage .description p {display: inline-block;margin-right: 30px;line-height:30px; height:30px; cursor:pointer;font-weight:bold;}
	.grp2 .usage .description { position: absolute;top: 20px;right: 10px;text-align: center;}
	.grp2 .usage .description p span {display: inline-block;width: 8px;height: 8px;border-radius: 50%;margin-right: 10px;transition:0.1s;}
	.grp2 .usage .description p.on span, .grp2 .usage .description p:hover span { transform:scale(1.5) }
	.grp2 .usage .description p.on.schedule span {background: #f96161;  }
	.grp2 .usage .description p.on.work span {background: #506eb1;  }
	.grp2 .usage .description p.on.file span { background: #54a9a4 }
	.grp2 .usage .description p.schedule span {background: #ff8e8e;}
	.grp2 .usage .description p.work span {background: #6e87bf;}
	.grp2 .usage .description p.file span {background: #92c5c2;}
	.grp2 .usage ul.chartUl:hover {border-bottom:1px solid #e7e9ea}
	.grp2 .usage ul.chartUl:before {z-index: 1000;content:'';transition: 0.2s;font-size: 10px;color: #d0d0d0;font-weight: bold;width: 100%;height: 1px;display: block;position: absolute;left: 0;top: 40px;margin-top:17px;}
	.grp2 .usage ul.chartUl:after {transition: 0.2s;content:'';font-size: 10px;color: #d0d0d0;font-weight: bold;width: 100%;height: 1px;display: block;position: absolute;left: 0;top: 0;margin-top: 24px;}
	.grp2 .usage .total1 { position: absolute;left: 40px;bottom: 145px;font-weight: bold; }
	.grp2 .usage .total2 { position: absolute;left: 40px;bottom: 178px;font-weight: bold; }
	.grp2 .usage ul.chartUl:hover:after, .grp2 .usage ul.chartUl:hover:before { color:#000; background: rgba(0,0,0,0.07); }
	.grp2 .usage ul.chartUl.schedule li.work, .grp2 .usage ul.chartUl.schedule li.file { opacity:0; border:none;width: 0;transition: 0.2s; }
	.grp2 .usage ul.chartUl.work li.schedule, .grp2 .usage ul.chartUl.work li.file { opacity:0; border:none;width: 0;transition: 0.2s; }
	.grp2 .usage ul.chartUl.file li.work, .grp2 .usage ul.chartUl.file li.schedule { opacity:0; border:none;width: 0;transition: 0.2s; }
	.grp2 .usage ul.chartUl.schedule > li ul li p.howMany {opacity:0.5;display: block !important;background: none;color: #2e3856;}
	.grp2 .usage ul.chartUl.work > li ul li p.howMany {opacity:0.5;display: block !important;background: none;color: #2e3856;}
	.grp2 .usage ul.chartUl.file > li ul li p.howMany {opacity:0.5;display: block !important;background: none;color: #2e3856;}
	.grp2 .usage ul.chartUl.schedule li:hover ul li p.howMany {opacity:1}
	.grp2 .usage ul.chartUl.work > li:hover ul li p.howMany {opacity:1}
	.grp2 .usage ul.chartUl.file > li:hover ul li p.howMany {opacity:1}
	
	
	
	.first .grp2  .myPage {background:#fff; padding:0; border-top:1px solid #e7e9ea; position: relative;height: 70px;}
	.first .myPage a { transition:0.2s; opacity:1; position:absolute; right:30px; top:50%; margin-top: -18px; padding:0 20px !important; border-radius:20px; height:35px !important; line-height:35px !important; text-align: center; background:#477fff; color:#fff; font-weight:bold;}
	.grp2:hover .myPage a { opacity:1; }
	.first .grp2 a.link { display:blocK; }
	.first .grp2 .myPage * { display:inline-block; }
	.first .grp2 .myPage .more {text-align: center;font-size: 12px;margin-left: 10px;line-height: 52px;border-radius: 50%;font-weight: bold;float: none;color: #477fff;}
    .first .grp2 .myPage li:last-child .pos { left: 0 !important; color:#477fff; }
	.first .grp2 .myPage li:last-child .pos span { padding-left:10px; opacity:0.5; }
	.first .grp2 h6 { font-size: 12px;max-width: 100px;overflow: hidden;float: left;text-overflow: ellipsis;white-space: nowrap;line-height: 70px; }
	.first .grp2 h6 span { padding-left:5px; font-size:12px; opacity:0.5; }
	.first .grp2 .myPage img {     width: 35px;height: 35px;box-sizing: border-box;border-radius: 50%;border: 1px solid #f1f3f5;float: left;margin-top: 18px;margin-right: 17px; }
	.first .grp2 .myPage .king img { margin-left:10px; border:2px solid #ffc239;}
	.first .grp2 .pos {     font-weight: bold;background-size: 15px !important;position: absolute;width: 25px;height: 25px;left: auto !important;right: 12px !important;background-color: #fff !important;background-position: 5px 5px!important;border-radius: 50%;/* border: 1px solid #f1f3f5; */   top: 22px;font-size: 0;line-height: 30px;}
	.first .grp2 .pos.king {left: 82px;background: url(../images/contents/ic_king.png) no-repeat left 6px;}
	
	.first .grp2 .manager img { border:2px solid #b4bfcc}
	.first .grp2 .pos.manager {background: url(../images/contents/ic_manager.png) no-repeat left 6px;}
	
	.myPage ul {width: 100%; max-width:900px;box-sizing: border-box;}
	.first .grp2 .myPage ul li {    position: relative;float: left;line-height: 70px;height: 70px;border-right: 1px solid #e7e9ea;width: 33.333%;padding: 0 20px;box-sizing: border-box;padding-top: 0;}
	.first .grp2 .myPage ul li:last-child .pos {background: none !important;position: static;font-size: 12px;width: auto;margin-left:10px;line-height:70px;}
	.first .grp2 .myPage ul li:last-child { border-right:none; }
	.up .second {box-shadow: 0 -10px 10px rgba(0,0,0,0.1);border-top: 0;z-index: 100000;position: relative;}
	.up .second .grpTeamUl { border-top: 1px solid #e7e9ea; padding-bottom: 150px; }
	.up .second .sortHeader { display:block; }
	.second { border-top:1px solid #e7e9ea; margin-bottom:150px; }
	.second ul li:before { display:none; }
	.second li > div > * {display: inline-block;}
	.second li.on, .second li.on:hover { background:#fff; opacity:0.7; cursor:default }
	.second li.on p, .second li.on h6  {opacity:1}
	.second li.on .status a {cursor:default;font-size: 0;display: inline-block;}
	.second li.on .status a:after {content: '입장';display: inline-block;font-size: 12px; opacity:0.7;color:#477fff;}
	.second li.on .faved { display:none !important; }
	.second li {overflow: hidden; transition:0.2s; line-height:60px; height:60px; width:100%; position:relative; border-bottom:1px solid #f1f3f5; }
	.second li .img {background-position: center center !important; background-size:cover !important; transition: 0s;border-radius: 5px;font-size: 0;position: absolute;margin-top: 12px;left: 30px;margin-left: 10%;border: 1px solid #f1f3f5;width: 52px;height: 35px;top: -1px;left: 37px;}
	.second li.group .img { margin-top:9px }
	.second .sortHeader { display:block; border-bottom:1px solid #e7e9ea; height:61px; }
	.second .sortHeader li {transition: 0s;display: inline-block !important;width: auto;}
	.second .num {width: 10% !important;text-align: center;}
	.second .placeNm {width: 36% !important;}
	.second .pos {width: 12% !important;}
	.second .status {width: 15% !important;height: 60px}
	.second .king { width:15% !important; }
	.second .people { width: 12% !important; }
	.second li:hover {background:#f6f9fb; }
	.second li p {float: left;font-weight:bold;    padding-right: 15px;text-overflow: ellipsis;box-sizing: border-box;overflow: hidden;white-space: nowrap;}
	.second li h6 {position: relative;float: left;padding-left: 110px;box-sizing: border-box;}
	.second li h6 .cl {width: 8px;height: 8px;display: inline-block;border-radius: 50%;position: absolute;left: 0;top: 26px; }
	.second li:hover .status a { color:#477fff; }
	.second li:hover .status a:before {content: '';display: block;position: absolute;width: 90px;height: 100%;left: 0;top: 0;}
	.second li.acc .status a {  color:#477fff; }
	.second li.notYet:hover { background:#fff; }
	.second li.waiting {background: #fffaf0;}
	.second li.notYet.waiting:before {    content: '';display: block;width: 3px;height: 65px;position: absolute;background: #ffa70e;left: 0;top: 0;}
    .second li.notYet.waiting:hover {background: #fffaf0; }
	.second li.notYet p, .second li.notYet h6 { opacity:0.5; }
	.second li.notYet:hover p, .second li.notYet:hover h6  { opacity:1; }
	.second li.notYet:hover .status a {display: inline-block;width: 90px;height: 30px !important;border: 2px solid #477fff;line-height: 30px !important;padding: 0;border-radius: 20px;text-align: center;margin-top: 12px;}
	.second li.notYet:hover .status a:hover  { font-size:0; background: #477fff;color: #fff !important; vertical-align: top; }
	.second li.notYet:hover .status a:hover:before { font-size:12px; content:'가입하기'; line-height: 60px; }
	.second li.notYet:hover .status.del a { border: 2px solid #ff6363; }
	.second li.notYet:hover .status.del a:hover  { background: #ff6363;color: #fff !important; }
    .second li.notYet .status { opacity:1; position:relative; }
	.second li.notYet .status.del a {  color:#ff6363; }
    .second li.notYet.waiting .status a { color: #f1a619 !important; }
	.second li.notYet.waiting:hover .status a { display:none;}
	.second li.notYet.waiting:hover .status:hover a { display:block; content:'요청 취소'; background:#ff5555 !important; color:#fff !important; border: 2px solid #ff5555; }
	.second li.notYet.waiting .status .date { display: none;color: #acacbb !important;text-align: left;margin-left: 0;text-align: 0;top: 10px;left: 0;height: 55px;line-height: 19px !important;position: absolute; }
	.second li.notYet.waiting:hover .status .date { display:block; color: #acacbb !important; }
	.second li.notYet.waiting .status .date em { display:block; font-weight: bold;color:#000 !important;font-style: normal;}
	.second li.notYet.waiting:hover .status:hover .date { display:none; }
	
	.second .grpTeamUl li .faved {position: absolute;left: 11px;top: 21px;font-size: 0;background-size: 20px;border: none;width: 20px;background-color: transparent;height: 20px;}
	.second .grpTeamUl li:hover .faved{ opacity:1;  width: 30px;height: 30px;background-color: #fff;margin-top: -5px;transition: 0.2s;}
	
	
	.second .grpTeamUl .placeNm {text-indent: 10px;}
	.second .grpTeamUl .group .placeNm { font-size:14px;}
	.second .grpTeamUl .placeNm .cl {margin-left: 10px;}
	.second .grpTeamUl .group .placeNm .cl {margin-left: 0;}
	.second .grpTeamUl .img {left: 47px;}
	.second .grpTeamUl .group .img {left: 37px;width: 62px;height: 43px;}
	
	
	#grpScroll .content {padding-top:0 !important;clear: both;float: left;margin-top: 5px;}
	ul.grpUserList {float: left;margin-left: 0;}
	ul.grpUserList li.add:hover { box-shadow:none; }
	ul.grpUserList li:hover {transition: 0.2s;opacity: 1;}
	/*ul.grpUserList li.signedUp:hover, ul.grpUserList li.created:hover {box-shadow: 0 10px 25px rgba(0,0,0,0.1);}*/
	.grpUserList span.cl {width: 100%;height: 10px;border-radius: 4px 4px 0 0;font-size: 0;display: block;position: absolute;right: 20px;bottom: 21px;z-index: 2;width: 11px;height: 11px;border-radius: 50%;}
	.grpUserList span.pos {width: 37px;height: 37px;display: inline-block;background-size: 19px !important;font-size: 0;border-radius: 50%;position: absolute;right: 7px;bottom: 42px;display: none;}
	.grpUserList span.pos.king {background: #fff url(../images/contents/ic_king.png) no-repeat center center;display: block;}
	.grpUserList span.pos.manager {background: #fff url(../images/contents/ic_manager.png) no-repeat center center;display: block;}
	.grpUserList a {display: block;width: 100%;height: 100%;}
	.grpUserList li.add a {font-size: 20px;font-weight: bold;transition: 0.2s;color: #a7a7a7;border-radius: 5px;font-size: 21px;font-weight: bold;text-align: center;height: 100%;line-height: 264px;width: 100%;background: #f1f3f5;border: 0 none;padding: 0;float: left;margin: 0;}
	.grpUserList li.add {background: #fff;box-sizing: border-box;border: 2px dashed #e7e9ea;opacity: 1;}
	.grpUserList li.add:hover a {background: #fff;color: #477fff;}
	span.faved {cursor:pointer;background: #fff url(../images/contents/faveStar.png) no-repeat center center;font-size: 0;width: 35px;height: 35px;border-radius: 50%;display: block;position: absolute;right: 15px;top: 15px;border: 1px solid #e7e9ea;background-size: 25px;}
	span.faved.off {background: #fff url(../images/contents/faveStar_off.png) no-repeat center center;background-size: 25px;transition: 0.2s;opacity: 0;}
	/*span.faved:hover {box-shadow:0 3px 5px rgba(0,0,0,0.1)}*/
	ul.grpUserList li.created:hover .faved.off, ul.grpUserList li.signedUp:hover .faved.off {opacity: 1;}
	#wrap .second .fave_border_on {height:62px;box-sizing:border-box;border:0 none !important; border-top: 1px solid #ffa70e !important;border-bottom: 1px solid #ffa70e !important; background:#fff; box-shadow: none !important; }
	ul.grpUserList li.created.on .faved.off, ul.grpUserList li.signedUp.on .faved.off { opacity:1; }
	#grpScroll h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
	#grpScroll .grp.settingBg {height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
	.grpDetailBox { display:none; }
	#grpScroll .content.grpDetailContent { margin-top:0 !important; }
	#grpScroll span.num {margin-left: 10px;color: #477fff;}
	#makeGrpScroll .createNewGroup {margin-left: -39px;}
	.settingBg .selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
	.btns.fr {margin-right: 30px;}
	.btns.fr .input_btn {margin: 11px 0 0 15px;}
	
	
	
	/* 1400부터 공지사항섹션 사라짐,  컨텐츠 위치변경 */
	/* @media (max-width:1400px) {
		.first .grp2 .myPage {position: absolute;top: -239px;width: 100%;box-sizing: border-box;padding-left: 401px;background: none;padding-right: 40px;border-top: 0;}
		.first .grp2 .myPage ul li.manager {display: none;}
		.first .grp2 .myPage ul li {border-right: 0;position: absolute;right: 20px;width: auto;}
		.first .myPage a { right:40px; }
		.first .grp2 .myPage ul li.king {width: 100%;position: static;display: block;padding-left: 0px;border-bottom: 1px solid #e7e9ea;}
		.first .grp2 .pos.king {display: none;}
		.first .grp2 .king img {margin-left: 0;width: 32px;height: 32px;}
		.first .grp2 .king h6 {font-size: 14px;}
		.first .grp2 .push ul {margin-top: 0;}
		.first .grp2 .current {position: absolute;top: -337px;z-index: 10000000;width: 100%;box-sizing: border-box;}
		.first .grp2 .current .dropdown {top: 69px;}
		.first .grp2.hover .current .bg {height: 630px;}
		#wrap .first .grp2:hover .bigger { display:none !important; }
	    .grpPage .first .grp1 {width: 100%;position: relative;height: 266px;margin-top: 70px;border-right:none!important;}
    	.first .grp2 .push {position: absolute;top: -407px;width: 100%;box-sizing: border-box;border-bottom: 1px solid #e7e9ea;display:none;}
	    .grpPage .first .img {    top: 40px;left: 40px;width: 320px;height: 200px;}
	    .grpPage .first .placeNm { padding-top: 90px;padding-left: 350px;padding-right: 180px;}
	    .grpPage .first .descript {padding-left: 350px;}
	    .grpPage .grp1 span.faved {    right: auto;left: 308px;top: 55px;}
	    .grpPage .first {height: 610px;}
	    .first .usage { border-top: none; }
	    .first .grp2 {padding-left: 0;}
	    .grpPage .first .cl {right: 40px;left: auto;top: 121px;}
    	.first .grp2 .usage h5 {padding-left: 40px;padding-right: 40px;height: 40px;padding-top: 20px !important;}
		.first .grp2 .usage .description {right: 20px;top: 15px; }
	    #wrap .first.wider .grp1 { opacity:1;z-index:1; }
	    #wrap .first.wider .bigger {display: none !important;}
	    #wrap .first.wider .grp2 {padding-left: 0;}
		#wrap .first.wider .grp2:after {display: none;}
		.grpDetailContent .grp1 .premiumTag {top: 40px !important;left: 40px !important;  }
		.grpPage .hc .first .grp1 a {left: auto;margin-left: 0;right: 80px;top: 113px;width: 125px;height: 35px;line-height: 35px;}
		.grpPage .hc .first .grp1 a span {font-size: 12px !important;}

		.grpTeam_clr_ul { width: 450px !important;right: 36px;top: 163px !important;height: 80px !important;text-align: right !important;left: auto !important; }

		.grp.settingBg {position: static;padding: 0 0 !important;width: 100% !important;max-width: 100% !important;min-width: 100% !important;}
		#container #section_ce .schedule_box {width: 100%;max-width: 100%;padding-left: 0;padding-bottom: 0;padding-right: 0;}
		#container #section_ce .section_scroll {margin-top: 0 !important;}
		
	} */


	/* 21.02 그룹 페이지 즐겨찾기 수정 */
	.second .grpTeamUl .group .img {left: 0;}
	.second .grpTeamUl .img {left: 10px;}
	.second .favorites {float: left; width: 8% !important;text-align: center; position: relative; height: 60px; line-height: 60px;}
	.second .people {width: 7% !important;}
	.second .placeNm {width: 37% !important;}
	.second .status {width: 12% !important;}
	.second li.on {position: relative;}
	.second .num {width: 9% !important;}

	.second .favorites .Fi_premiumtag {width: 20px; height: 20px; mask-image: url('../images/contents/star_w.svg'); mask-size: 17px; align-self:center; background-repeat: no-repeat; display: block; transition: all 0.5s ease 0s; background: #fff !important; position: absolute; top: 50%; right: 0px; margin-top: -14px; z-index: 1000; font-size: 0; cursor : pointer;} 
	.second .favorites .favorites_fill {width: 23px; height: 23px; mask-image: url('../images/contents/ic_bookmark_fill.svg'); mask-size: 23px; align-self:center; background-repeat: no-repeat; display: block; transition: all 0.5s ease 0s; background: #ff6363 !important; position: absolute; top: 50%; right: 0; margin-top: -15px; z-index: 999; font-size: 0; cursor : pointer;}
	.second .favorites .favorites_line {mask-image: url('../images/contents/ic_bookmark.svg'); mask-size: 23px; background: #ff6363; align-self:center; background-repeat: no-repeat; font-size: 0; display: block; transition: all 0.5s ease 0s; position: absolute; top: 50%; right: 0; margin-top: -15px;  width: 23px; height: 23px; z-index: 999; cursor : pointer;}
	.second .notYet .favorites span { opacity: 0.5; }
	.second .notYet:hover .favorites span { opacity: 1; }
	.second .notYet .favorites * { cursor : default; }
	.second li .img {margin-left: 16.5%;}

	/* 21.02 프리미엄 태그 제거 (즐겨찾기로 프리미엄 /일반 구분되기때문에) */
	.grpDetailContent .grp1.hcList .premiumTag {display: none !important;}
	.grpDetailContent .grp1.hdList .premiumTag {display: none !important;}

	/* 21.02 cursor pionter 안되는 곳 호버 없앰*/ 
	.second li:hover {background: none;}

	/* 21.03.02 수정 */
	.grpPage .first {height: 570px; }
	.grpPage .first .grp1 {height: 340px; }
	.grpPage .first .grp2 {height: 340px; }

	/* 21.03.02 수정 3단 */
	.grp3_under {width: 100%; display: inline-block; padding: 0; }
	.grp3_under .grp3_box {overflow: hidden; height: 230px;  box-sizing: border-box; border-top: 1px solid #e7e9ea;}
	.grp3_under ul li.grp3_list {width: 33.33333%; height: 100%; float: left; box-sizing: border-box; border-right: 1px solid #e7e9ea; }
	.grp3_under ul li.grp3_list:last-child {border-right: none; padding-right: 10px; }
	/* .grp3_under ul li.grp3_list {padding: 10px 30px 0; } */
	.grp3_under ul li.grp3_list .grp3_list_con { padding-left: 30px; }
	.grp3_list > ul > li {line-height: 20px; position: relative; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
	.grp3_list > div ul > li {line-height: 20px; position: relative; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
	.grp3_under .grp3_list .title {font-size: 14px; font-weight: bold; line-height: 25px; text-indent: 35px; margin-top: 10px; overflow: visible; cursor: pointer; position: relative; height: 30px; margin-top: 10px; margin-left: 30px; }
	.grp3_under .grp3_list .title span { width: 25px; height: 25px; border-radius: 50%; background: #477fff; display: block; vertical-align: top; margin-right: 10px; position: absolute; top: 0; left: 0; font-size: 0; }
	.grp3_under .grp3_list .title span:before { content: ''; width: 15px; height: 15px; display: inline-block; background-image: url('../images/menucons/ic_grp3_calendar_white.svg'); background-repeat: no-repeat; background-position: center center; margin-right: 15px; position: absolute; top: 5px; left: 5px; background-size: cover; }
	.grp3_under ul li.grp3_list ul li.grp3_con {font-weight: bold; }
	.grp3_under ul li.grp3_list .seeOpacity {opacity: .5; }
	.grp3_last > ul > li {line-height: 20px; position: relative; padding-left: 0;  box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

	/* 21.03.02 수정 2단 */
	.grp3_under ul li.grp3_list_two {width: 50%; height: 100%; box-sizing: border-box; float: left; border-right: 1px solid #e7e9ea; }
	.grp3_under ul li.grp3_list_two:last-child {border-right: none; }
	.grp3_LT_none {display: none;}

	/* 21.03.02 수정 3단(2줄) */
	.grp3_list .grp3_last_none {display: none; }
	.grp3_list .grp3_last_two {height: 98px;  box-sizing: border-box; border-top: 1px solid #e7e9ea; }
	.grp3_list .grp3_last_two:first-child {border-top: none; }
	.grp3_list .grp3_last_two > ul > li {line-height: 20px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

	/* 21.03.02 수정 오늘의 일정 */
	/* .grp3_under ul li.grp3_list ul li.g3c01:before {content:''; display: inline-block; width: 3px; height: 25px; background: #477fff; position: absolute; top: 8px; left: 3px; border-radius: 5px;} */
	.grp3_under ul li.grp3_list ul > li.g3c01 {padding-left: 18px; overflow: visible; position: relative;}
	.grp3_under ul li.grp3_list ul.lastPadding > li.g3c01:last-child { padding-bottom : 35px; }
	.grp3_under ul li.grp3_list ul > li.g3c01 .g3c01_bar {display: block; width: 3px; height: 25px; background: #477fff; position: absolute; top: 8px; left: 3px; border-radius: 5px; font-size: 0;}
	.grp3_under ul li.grp3_list ul > li .grp3_con_title {width: 93%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
	.grp3_under ul li.grp3_list ul > li .grp3_con_title:hover a,
	.grp3_under ul li.grp3_list ul > li .grp3_con_title:hover span {color: #48649d; }
	.grp3_under ul li.grp3_list ul > li .grp3_con_title span.time {display: block; font-weight: normal; }

	/* 21.03.02 수정 공지사항 */
	.grp3_under .grp3_list .grp_T02 span:before {background-image: url('../images/menucons/ic_grp3_notice_white.svg'); }

	/* 21.03.02 수정 알림 */
	.grp3_under .grp3_list ul li.grp_T03 span:before {background-image: url('../images/menucons/ic_grp3_bell_white.svg'); }
	.grp3_under .grp3_list ul li select { width: 140px; float: right; cursor: pointer; box-sizing: border-box; height: 30px; line-height: 30px; text-align-last: left; -webkit-appearance:none; /* for chrome */ -moz-appearance:none; /*for firefox*/ appearance:none;  padding: 0 15px; border: 1px solid #e7e9ea; border-radius: 5px; font-size: 12px; }
	.grp3_under .grp3_list ul li:first-child select { background: url('../images/side_area/bt_open.png') no-repeat 94% center; background-size: 28px 28px;} 
	.grp3_under .grp3_list ul li:first-child select:focus { background-image: url('../images/side_area/bt_close.png');}

	/* 21.03.02 수정 메세지 */
	.grp3_under .grp3_list ul li.grp_T03_2 span:before {background-image: url('../images/menucons/ic_grp3_message_white.svg'); }
	/* .grp3_under .grp3_list ul li.grp_T03_2 span:before {content: ''; background: #6854ff;} */

	.grp3_under .grp3_list .noone { background: #f1f3f5; border-radius: 5px; height: 63%; line-height: 144px; text-align: center; font-weight: bold; color:#98aec3; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin: 16px 30px; padding: 0 5px; }
	.grp3_under .grp3_list .grp3_last_two .noone { height : 30%; line-height: 29px; }

	 /*  2021.03.03  */
	 .userDets { padding-left: 55px; box-sizing: border-box; width: 100%; }
	 .userDets .facePic {  position: absolute; left: 30px; top: 15px; display: inline-block; width: 40px; height: 40px; border-radius: 50%; box-sizing: border-box; font-size: 0; border: 1px solid #f1f3f5; background-size: cover; background-position: center center; background-repeat: no-repeat; }
	 .userDets .facePic.king.admin { border: 2px solid #ff5757; }
	 .userDets .facePic.king { border: 2px solid #ffc239; }
	 .userDets .facePic.manager {border: 2px solid #b4bfcc;}
	 .userDets ul { overflow: hidden; }
	 .userDets li { padding-top: 15px; margin-top: 0; display: inline-block; float: left; width: 30%; height: 70px; box-sizing: border-box; }
	 .userDets li:first-child { width: 40% }
	 .userDets li .title1 { line-height: 20px;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-right: 20px; box-sizing: border-box;}

	 .userDets li .memT_last { float: right; }
	 .userDets li .first1 { font-weight: bold; display: block; margin-bottom: 1px;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap;   }
	 .userDets li .memT_last label.groupM_radio {margin-right: 25px; }
	 .userDets li .memT_last label.groupM_radio:last-child {margin-right: 0; }
	 

	 /* 호두 C 프리미엄 달력이동 변경 */
	 /* .userDets li .memT_last a { display: inline-block; text-align: center; } */
	 /* .grpPage  a.hoduC_change  {width: 70px; opacity: 0.6;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: inline-block; background: #477fff; color: #fff; font-weight: bold; opacity: 1; border-radius: 22px; height: 22px; line-height: 22px; padding: 0 10px; font-size: 11px !important; } */
	 /* .userDets li .second2 { display: block; opacity: 0.6;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-right: 20px;  }
	 .userDets li .memT_last span.second2 { display: inline-block; background: #477fff; color: #fff; font-weight: bold; opacity: 1; border-radius: 22px; height: 22px; line-height: 22px; padding: 0 10px; font-size: 11px !important; } 
	 .userDets li .memT_last span.normal_second2{ display: inline-block; background: #477fff; color: #fff; font-weight: bold; opacity: 1; border-radius: 22px; height: 22px; line-height: 22px; padding: 0 10px; font-size: 11px !important; vertical-align: top; margin-top: -9px; margin-right: 15px;} 
	 .userDets li .memT_last span.second2:hover, .userDets li .memT_last span.normal_second2:hover { box-shadow: 0 3px 8px rgba(35, 164, 255, 0.3); } */
	 /* a.create_change.hoduC_btn_change  {width:100px; height: 22px; line-height: 22px; transition:0.2s; opacity:1 !important; bottom: 35px !important; display:block; border:1px solid #f1f3f5; border-radius: 40px; background-color: #f1f3f5 !important; color:#477fff !important; }
	 a.create_change.hoduC_btn_change span { color:#477fff; }
	 a.create_change.hoduC_btn_change:hover span { color:#fff; }
	 a.create_change.hoduC_btn_change:hover { background:#477fff !important; border-color:#477fff; color:#fff !important; box-shadow:  0 3px 8px rgba(35, 164, 255, 0.3); } */

	 /* 기존 호두C 프리미엄 달력이동 살리기 */
	 .grpPage .hc .first .grp1 a.hoduC {display: block; margin-left: -58px; width: 115px; height: 25px; line-height: 25px; bottom: 24px !important; z-index: 5 !important;	}
	 .grpPage a.hoduC span.ab {font-size: 12px !important; }
	 .grpPage .first .grp1 a.create.hoduC_btn {display: block; margin-left: -58px; width: 115px; height: 25px; line-height: 25px; bottom: 24px !important; z-index: 5 !important;}
	 .grpPage a.hoduC_btn span.ab {font-size: 12px !important; }

	 .userDets li em { padding-left: 10px; font-style: normal; border-top: 0 none; font-weight: normal;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-right: 20px;  }
 
	 .tabDiv { height: 50px; line-height: 50px; }
	 .tabDiv li { display: inline-block; font-size: 12px; font-weight: bold; margin-right: 25px;  }
	 .tabDiv li a { box-sizing: border-box; line-height: 50px; display: inline-block; transition:0.2s; opacity: 0.5; }
	 .tabDiv li:hover a  { opacity: 0.8; }
	 .tabDiv li a.on { line-height: 46px; border-bottom: 2px solid #477fff; opacity: 1; }
 
	 .tabDivUl { padding:0 !important; height: 219px; }
	 .tabDivUl > li { display: none; border-top: 1px solid #e7e9ea; box-sizing: border-box; height: 219px;}
	 .tabDivUl > li:first-child { display: block; }
	 .tabDivUl li > div { padding: 10px 30px; border-top: 0 none; overflow-x: auto; }
	 .tabDivUl li > div.usage { padding: 0;  border-top: 0 none }
	 .tabDivUl .title { font-size: 14px; font-weight: bold; line-height: 25px; text-indent: 35px; overflow: visible; cursor: pointer; position: relative; height: 30px; margin-top: 10px; }
 
	 /* 멤버 */
	 .tabMemberList {  height: 100%; }
	 .tabMemberList > div { width: 33.3333%; float: left; height: 100%; box-sizing: border-box; border-right: 1px solid #f1f3f5; overflow: hidden; }
	 .tabMemberList > div.is_team { width : 50%; }
	 .tabMemberList > div:last-child { border-right: 0 none; position: relative;}
	 .tabMemberList > div span.mem_gradation { width: 100%; height: 32px; position: absolute; left: 0; background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.3)); bottom: 0; font-size: 0;}
	 .tabMemberList > div .memberTitle {margin: 0; margin-bottom: 5px; text-indent: 0; }
	 .tabMemberList > div span.icon {width: 30px; height: 30px; display: inline-block; font-size: 0; background: url('../../../src/assets/images/contents/ic_king.png') no-repeat center center; background-size: 20px 20px; vertical-align: top; margin-top: -2px;}
	 .tabMemberList > div span.manager_ic {background-image: url('../../../src/assets/images/contents/ic_manager.png'); }
	 .tabMemberList .num { float: right; }
	 .tabMemberList .noone { background: #f1f3f5; border-radius: 5px; height: 77%; line-height: 150px; text-align: center; font-weight: bold; color:#98aec3; }
	 .tabMemberList .tabMemUl li { margin-bottom: 5px; font-weight: bold; position: relative; border-top: 0 none; padding-left: 45px; box-sizing: border-box; width: 100%; height: 40px; line-height: 20px; }
	 .tabMemberList .tabMemUl li .memPic { position: absolute; left: 0; top: 3px; display: inline-block; width: 33px; height: 33px; border-radius: 50%; border: 1px solid #e7e9ea; font-size: 0; box-sizing: border-box; background-size : cover; }
	 .tabMemberList .tabMemUl li .memPic.king { border: 2px solid #ffc239; }
	 .tabMemberList .tabMemUl li .memPic.king.admin { border: 2px solid #ff5757; }
	 .tabMemberList .tabMemUl li .memPic.manager {border: 2px solid #b4bfcc;}
	 .tabMemberList .tabMemUl li .posi { display: block; font-weight: normal; opacity: 0.6;  }
	 .tabMemberList .tabMemUl li.not_pos .name { line-height: 37px; }
	 .tabMemberList .gray { color:#98aec3 !important }
 
	 /* 전자결재 */
	 .tabEappList > div { width: 50%; float: left; box-sizing: border-box; height: 100%; border-right: 1px solid #e7e9ea; position: relative; line-height: 20px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
	 .tabEappList > div:last-child { border-right: none; }
	 .tabEappList > div ul.eapp_box { padding-left: 15px; }
	 .tabEappList > div .appTitle { float: left; }
	 .tabEappList > div .appup_top {overflow: hidden; white-space: nowrap; min-width: 290px; }
	 .tabEappList > div ul.appBar_box {height: 40px; line-height: 40px; padding-top: 5px; box-sizing: border-box; float: right; }
	 .tabEappList > div ul.appBar_box .appBar_con {display: inline-block; }
	 .tabEappList > div ul.appBar_box .appBar_con a {display: inline-block;}
	 .tabEappList > div ul.appBar_box .appBar_con:hover span.appUPbar {transform: scale(1.2); }
	 .tabEappList > div ul.appBar_box .appBar_con p.app_count {display: inline-block; margin: 0 13px 0 3px; }
	 .tabEappList > div ul.appBar_box .appBar_con p.appgrey {opacity: .5; }
	 .tabEappList > div ul.appBar_box .appBar_con span.appUPbar { font-size: 0; width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-top: 15px; float: left; transition: .1s; }
	 .tabEappList > div ul.appBar_box .appBar_con span.grnapp {background-color: #40c8a0; border: 1px solid #40c8a0; }
	 .tabEappList > div ul.appBar_box .appBar_con span.blueapp {background-color: #477fff; border: 1px solid #477fff; }
	 .tabEappList > div ul.appBar_box .appBar_con span.redapp {background-color: #ff5872; border: 1px solid #ff5872; }
	 .tabEappList > div ul.appBar_box .appBar_con span.ylwapp {background-color: #ffbc58; border: 1px solid #ffbc58; }
	 .tabEappList > div ul.appBar_box .appBar_con span.appDOWNbar {display: inline-block; float: left; opacity: .8; transition: .1s; }
	 .tabEappList > div ul.appBar_box .appBar_con:hover span.appDOWNbar {transform: scale(1.2); opacity: 1; }

	 .tabEappList > div .title span { width: 25px; height: 25px; border-radius: 50%; background: #477fff; display: block; vertical-align: top; margin-right: 10px; position: absolute; top: 0; left: 0; font-size: 0; }
	 .tabEappList > div .title span:before { content: ''; width: 15px; height: 15px; display: inline-block; background-image: url('../images/menucons/ic_grp3_elecup_white.svg'); background-repeat: no-repeat; background-position: center center; margin-right: 15px; position: absolute; top: 6px; left: 6px; background-size: cover; }
	 .tabEappList > div .title span.ic_down:before {background-image: url('../images/menucons/ic_grp3_elecdown_white.svg'); }

	 .tabEappList li.g3Eapp_con {padding-left: 18px; overflow: visible; position: relative;}
	 .tabEappList li.g3Eapp_con .g3Eapp_side {display: block; width: 8px; height: 8px; background: #477fff; position: absolute; top: 6px; left: 3px; border-radius: 50%; font-size: 0;}
	 
	 .tabEappList li.g3Eapp_con .g3Eapp_side.grnapp {background-color: #40c8a0; }
	 .tabEappList li.g3Eapp_con .g3Eapp_side.blueapp {background-color: #477fff; }
	 .tabEappList li.g3Eapp_con .g3Eapp_side.redapp {background-color: #ff5872; }
	 .tabEappList li.g3Eapp_con .g3Eapp_side.ylwapp {background-color: #ffbc58; }
	 
	 .tabEappList li .g3Eapp_con_title {width: 93%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: bold; display: inline; }
	 .tabEappList li .g3Eapp_con_title:hover a,
	 .tabEappList li .g3Eapp_con_title:hover span {color: #48649d; }
	 .tabEappList li .g3Eapp_con_title span.time {display: block; font-weight: normal; }
	 .tabEappList li.downApp_con { padding-left: 55px; }
	 .tabEappList li.g3Eapp_con .g3Eapp_smallT {position: absolute; top: 1px; left: 3px;}
	 .tabEappList li.g3Eapp_con .quick { color: #fff; border-radius: 3px; background-color: #ff6363; padding: 1px 5px; margin-right: 6px; font-weight: normal; }
	 .tabEappList li.g3Eapp_con .imprtApp { color: #fff; border-radius: 3px; background-color: #FFC72F; padding: 1px 5px; margin-right: 6px; font-weight: normal; }

	 .tabEappList .noone { background: #f1f3f5; border-radius: 5px; height: 70%; line-height: 140px; text-align: center; font-weight: bold; color:#98aec3; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding: 0 5px; }

	 /* 업무 */
	 .tabTaskList {  height: 100%; }
	 .tabTaskList > div { width: 33.3333%; float: left; height: 100%; line-height: 20px; box-sizing: border-box; border-right: 1px solid #f1f3f5; overflow: hidden; }
	 .tabTaskList > div:last-child { border-right: 0 none; position: relative;}
	 /* .tabTaskList > div ul.task_box { padding-left: 15px; } */
	 .tabTaskList > div .taskTitle { float: left; }
	 .tabTaskList > div .task_top {overflow: hidden; white-space: nowrap; }
	 .tabTaskList > div .title span { width: 25px; height: 25px; border-radius: 50%; background: #477fff; display: block; vertical-align: top; margin-right: 10px; position: absolute; top: 0; left: 0; font-size: 0; }
	 .tabTaskList > div .title span:before { content: ''; width: 15px; height: 15px; display: inline-block; background-image: url('../images/menucons/ic_grp3_conference_white.svg'); background-repeat: no-repeat; background-position: center center; margin-right: 15px; position: absolute; top: 5px; left: 5px; background-size: cover; }
	 .tabTaskList > div .title span.ic_work:before {background-image: url('../images/menucons/ic_grp3_work_white.svg'); }
	 .tabTaskList > div .title span.ic_project:before {background-image: url('../images/menucons/ic_grp3_project_white.svg'); }

	 .tabTaskList li.g3task_con {padding-left: 18px; position: relative; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
	 .tabTaskList li.g3task_con .g3task_side {display: block; width: 4px; height: 4px; background: #477fff; position: absolute; top: 6px; left: 6px; border-radius: 50%; font-size: 0;}
	 .tabTaskList li.g3task_con .g3task_side:before {content: ''; display: block; width: 4px; height: 4px; background: #477fff; position: absolute; top: 6px; border-radius: 50%; font-size: 0;}
	 .tabTaskList li .g3task_con_title {width: 93%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: bold; display: inline; }
	 .tabTaskList li .g3task_con_title:hover a,
	 .tabTaskList li .g3task_con_title:hover span {color: #48649d; }
	 .tabTaskList li .g3task_con_title span.time {display: block; font-weight: normal; }

	 .tabTaskList .noone { background: #f1f3f5; border-radius: 5px; height: 70%; line-height: 140px; text-align: center; font-weight: bold; color:#98aec3; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding: 0 5px; }

	 /* 근태현황 */
	 .tabAtndnceList > div { width: 50%; float: left; height: 100%; box-sizing: border-box; border-right: 1px solid #f1f3f5; overflow: hidden; }
	 .tabAtndnceList > div:last-child { border-right: 0 none; position : relative; }
	 .tabAtndnceList > div .atnTitle { margin: 0; margin-bottom: 5px; text-indent: 0; }
	 
	 .tabAtndnceList .atn_top { min-width: 303px; white-space: nowrap; text-align: center; }
	 .tabAtndnceList .atn_top .weekdate_wrap { display: inline-block; position: relative; overflow: hidden; white-space: nowrap; padding: 0 20px; }
	 .tabAtndnceList .atn_top input[type="button"].weekdate { float: left; line-height: 25px; font-size: 13px; color: #232848; margin: 0 10px !important; font-weight: bold; min-width: auto !important; background: none; box-sizing: border-box; padding-right: 0; }
	 .tabAtndnceList .atn_top input[type="button"].atnLeft-btn { width: 25px; height: 25px; box-sizing: border-box; display: inline-block; background-size: 22px !important; position: absolute; top: 0; padding-right: 0; margin-right: 0; }
	 .tabAtndnceList .atn_top input[type="button"].bt-prev:hover { background-color: #f1f3f5; background-image: url('../images/contents/month_bt_prev_on.svg'); }
	 .tabAtndnceList .atn_top input[type="button"].bt-next:hover { background-color: #f1f3f5; background-image: url('../images/contents/month_bt_next_on.svg'); }
	 .tabAtndnceList .atn_top input[type="button"].bt-prev { background: url('../images/ware/month_bt_prev.svg') no-repeat center center; left: 0; }
	 .tabAtndnceList .atn_top input[type="button"].bt-next { background: url('../images/ware/month_bt_next.svg') no-repeat center center; right: 0; }
	 .tabAtndnceList .atn_top .weeksign { display: block; line-height: 25px; float: left; margin: 0 20px; }
	 .atn_left { width: 100%; min-width: 303px; white-space: nowrap; text-align: left; }
	 .atn_left ul li { display: inline-block; box-sizing: border-box; text-align: center; }
	 .atn_left ul li > a { display: inline-block; width: 100%; }
	 .atn_left .week_check li { width: 11%; margin-right: 3%; }

	/* 근태 관리자 왼쪽 탭 메뉴 */
	.tabAtndnceList .top_nocenter { text-align: left; }
	.tabAtndnceList .atn_top .week_manager { display: inline-block; vertical-align: top; margin-left: 5px; }
	.tabAtndnceList .atn_top .week_manager h2 { display: inline-block;border: 1px solid #f1f3f5; font-weight: normal; opacity: .8; }
	.tabAtndnceList .atn_top .week_manager h2 a { display: block; padding: 5px 11px; }
	.tabAtndnceList .atn_top .week_manager h2.man_tab1 { border-radius: 3px 0 0 3px; }
	.tabAtndnceList .atn_top .week_manager h2.man_tab2 { border-radius: 0 3px 3px 0; }
	.tabAtndnceList .atn_top .week_manager h2.active { border: 1px solid #f1f3f5; background: #f1f3f5; font-weight: bold; opacity: 1; }

	.tabAtndnceList .right .atn_top { position: absolute; left : 20px; text-align: left; }

	 .tabAtndnceList .atn_left .week_check .icon { width: 41px; height: 41px; display: table; margin-left: auto; margin-right: auto; display: inline-block; font-size: 0; box-sizing: border-box; border: 1px solid #232848; background-color: #fff; border-radius: 50%; margin-bottom: 15px; }
	 .tabAtndnceList .atn_left .week_check .ic_todayW { border: 1px solid #477fff; background-color: #fff; }
	 .tabAtndnceList .atn_left .week_check .ic_pastW { border: 1px solid #477fff; background: url('../images/contents/ic_checkbox.png') no-repeat center center; background-size: 20px 20px; }
	 .tabAtndnceList .atn_left .week_check .ic_uncheckW { border: 1px solid #ffa70e; background-color: #fff; background: url('../images/contents/ic_unchecked.png') no-repeat center center; background-size: 20px 20px; }
	 .tabAtndnceList .atn_left .week_check .ic_absenceW { border: 1px solid #ff6363; background-color: #fff; background: url('../images/contents/ic_abnce.png') no-repeat center center; background-size: 20px 20px; }
	 .tabAtndnceList .atn_left .week_check .ic_holiday { border: 1px solid #f1f3f5; background-color: #f1f3f5; }
	 .tabAtndnceList .atn_left .week_check .ic_dayoffW { border: 1px solid #f1f3f5; background: url('../images/contents/ic_dayoff_fill.png') no-repeat center center; background-size: 41px 41px; }
	 .tabAtndnceList .atn_left .week_check a.on .ic_basic { border: 1px solid #232848; background-color: #232848; }
	 .tabAtndnceList .atn_left .week_check a.on .ic_todayW { border: 1px solid #477fff; background-color: #477fff; }
	 .tabAtndnceList .atn_left .week_check a.on .ic_pastW { border: 1px solid #477fff; background-color: #477fff; background-image: url('../images/contents/ic_checkbox_white.png'); }
	 .tabAtndnceList .atn_left .week_check a.on .ic_uncheckW { border: 1px solid #ffa70e; background-color: #ffa70e; background-image: url('../images/contents/ic_unchecked_white.png'); }
	 .tabAtndnceList .atn_left .week_check a.on .ic_absenceW { border: 1px solid #ff6363; background-color: #ff6363; background-image: url('../images/contents/ic_abnce_white.png'); }
	 
	 .atn_left .week_text li { text-align: left; padding-left: 3%; margin-right: 3%; }
	 .atn_left .week_text li:first-child { font-weight: bold; padding-left: 2%; }
	 .atn_left .week_text li > a { position: relative; }
	 .tabAtndnceList .atn_left span.atnBar { font-size: 0; width: 8px; height: 8px; border-radius: 50%; display: inline-block; position: absolute; top: 2px; left: -13px; transition: .1s; }
	 .tabAtndnceList .atn_left span.ylwatn { background-color: #ffa70e; }
	 .tabAtndnceList .atn_left span.blueatn { background-color: #477fff; }
	 .tabAtndnceList .atn_left span.redatn { background-color: #ff6363; }
	 .tabAtndnceList .atn_left span.skyatn { background-color: #4dbaff; }
	 .tabAtndnceList .atn_left span.greenatn { background-color: #13d08b; }

	 .tabAtndnceList .atn_right { width: 100%; min-width: 303px; white-space: nowrap; }
	 .tabAtndnceList .atn_right .graph { width: 120px; float: left; margin-top: 36px; }
	 .tabAtndnceList .atn_right .graph .atngraph { display:inline-block; position: relative; width: 120px; height: 120px; border-radius: 50%; transition: 0.3s; /* border: 1px solid #f1f3f5; */ }
	 .atngraph .worksum { background: #fff; display : flex; align-items: center; position: absolute; top: 50%; left: 50%; width: 90px; height: 90px; border-radius: 50%; text-align: center;transform: translate(-50%, -50%); padding: 20px 0; box-sizing: border-box; font-weight: bold; }
	 .atngraph .worksum .gra_center { width: 100%; color: #477fff; }
	 .atngraph .worksum #gra_dpTime { display: block; margin-top: 20px; color: #232848; }
	/* 근태 개인 오른쪽 */
	 .tabAtndnceList .atn_right .graph_wrap { display: inline-block; }
	 .tabAtndnceList .atn_right .graph_wrap > div { width: 330px; float: left; margin-left: 11%; padding: 0 10px; box-sizing: border-box; }
	 /* .tabAtndnceList .atn_right .graph_wrap > div { display: none; } */
	 /* .tabAtndnceList .atn_right .graph_wrap > div:first-child { display: block; } */
	 .tabAtndnceList .atn_right h3 { text-align: center; }
	 .tabAtndnceList .atn_right .graph_con ul li { line-height: 35px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
	 .tabAtndnceList .atn_right .graph_con .grpcon_one li span { float: right; }
	 .tabAtndnceList .atn_right .graph_con .grpcon_one li:last-child { border-bottom: 1px solid #f1f3f5; padding-bottom: 5px; }
	 .tabAtndnceList .atn_right .graph_con .grpcon_one li:before { content: ''; width: 7px; height: 7px; display: inline-block; border-radius: 50%; background: #477fff; margin-right: 6px;margin-bottom: 1px; }
	 .tabAtndnceList .atn_right .graph_con .grpcon_one li:last-child:before { content: ''; width: 7px; height: 7px; display: inline-block; border-radius: 50%; background: #f1f3f5; margin-right: 6px;margin-bottom: 1px; }
	 .tabAtndnceList .atn_right .graph_con .grpcon_second li > p { display: inline-block; }
	 .tabAtndnceList .atn_right .graph_con .grpcon_second li .basic_atn { padding: 3px 10px; border: 1px solid #477fff; border-radius: 3px; box-sizing: border-box; color: #477fff; margin-right: 17px; }
	 .tabAtndnceList .atn_right .graph_con .grpcon_second li span { padding-right: 17px; }

	/* 근태 관리자 오른쪽 */
	 .tabAtndnceList .atn_right .allGraph_con .todayW_count { margin-top: 25px; font-weight: bold; }
	 .tabAtndnceList .atn_right .allGraph_con ul.allgrpcon_one { width: 100%; white-space: normal; }
	 .tabAtndnceList .atn_right .allGraph_con ul.allgrpcon_one li { width: 50%; display: inline-block; line-height: 40px; padding-right: 40px; box-sizing: border-box; }
	 .tabAtndnceList .atn_right .allGraph_con ul.allgrpcon_one li span { float: right; }
	 .tabAtndnceList .atn_right .allGraph_con ul.allgrpcon_one li:before { content: ''; width: 7px; height: 7px; display: inline-block; border-radius: 50%; background: #477fff; margin-right: 6px;margin-bottom: 1px; }
	 .tabAtndnceList .atn_right .allGraph_con ul.allgrpcon_one li.all_red:before { content: ''; background: #ff2e2e; }
	 .tabAtndnceList .atn_right .allGraph_con ul.allgrpcon_one li.all_pink:before { content: ''; background: #ff6363; }
	 .tabAtndnceList .atn_right .allGraph_con ul.allgrpcon_one li.all_sky:before { content: ''; background: #4dbaff; }
	 .tabAtndnceList .atn_right .allGraph_con ul.allgrpcon_one li.all_green:before { content: ''; background: #13d08b; }
	 .tabAtndnceList .atn_right .allGraph_con ul.allgrpcon_one li.all_yellow:before { content: ''; background: #ffa70e; }
	 
 
	 /* 간편메뉴 */
	 .tabAllMenusList { overflow: hidden; height: 219px; }
	 .tabAllMenusList .allMenusUl { overflow: hidden; }
	 .tabAllMenusList .allMenusUl li { transition: 0.1s; display: inline-block; width: 25%; height: 110px; float: left; border-right: 1px solid #f1f3f5; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; text-align: center; }
	 .tabAllMenusList .allMenusUl li a { display: inline-block; width: 100%; height: 100%; font-weight: bold; padding: 0 15px; padding-top: 70px; box-sizing: border-box; transition: .1s; background-repeat: no-repeat; background-position: center 27px; background-size: 22px 22px; }
	 .tabAllMenusList .allMenusUl li a.allMenus_ic01 {background-image: url('../../../src/assets/images/menucons/ic_sch_30px_dc7.svg'); }
	 /* .tabAllMenusList .allMenusUl li a.allMenus_ic02 {background-image: url('../../../src/assets/images/menucons/ic_cal_30px_dc7.svg');} */
	 .tabAllMenusList .allMenusUl li a.allMenus_ic02 {background-image: url('../../../src/assets/images/menucons/ic_conference_30px_dc7.svg');}
	 .tabAllMenusList .allMenusUl li a.allMenus_ic03 {background-image: url('../../../src/assets/images/menucons/ic_flow_30px_dc7.svg');}
	 .tabAllMenusList .allMenusUl li a.allMenus_ic04 {background-image: url('../../../src/assets/images/menucons/ic_atndnce_30px_dc7.svg');}
	 .tabAllMenusList .allMenusUl li a.allMenus_ic05 {background-image: url('../../../src/assets/images/menucons/ic_eprv_30px_dc7.svg');}
	 .tabAllMenusList .allMenusUl li a.allMenus_ic06 {background-image: url('../../../src/assets/images/menucons/ic_mem_30px_dc7.svg');}
	 .tabAllMenusList .allMenusUl li a.allMenus_ic07 {background-image: url('../../../src/assets/images/menucons/ic_msg_30px_dc7.svg');}
	 .tabAllMenusList .allMenusUl li a.allMenus_ic08 {background-image: url('../../../src/assets/images/menucons/ic_work_30px_dc7.svg');}
	 .tabAllMenusList .allMenusUl li a.allMenus_ic09 {background-image: url('../../../src/assets/images/menucons/ic_bulletinboard_sm_dc7.png');}

	 .tabAllMenusList .allMenusUl li:hover { background-color:#f1f3f5; }
	 .tabAllMenusList .allMenusUl li:hover a { transform: scale(1.1); }

	 /* 21.03.04 그룹명 수정 */
	 /* .grpPage .first .placeNm { margin-top: 157px; overflow: hidden; text-overflow: ellipsis; white-space: normal; line-height: 1.4; max-height: 4.1em; text-align: left; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;} */ /* 대시보드 수정으로 인한 주석 */
	 .grpPage .first .descript {overflow: hidden; text-overflow: ellipsis; white-space: normal; line-height: 1.6; max-height: 5em; text-align: left; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;}
	 

	 /* 21.04.27 키즈 대시보드 미세먼지*/
	 .tabUl > li.air_pollution { float: right; margin-right: 0; max-height: 50px; color: #a2a2a2; box-sizing: border-box; }
	 .air_pollution .air_list { display: inline-block; line-height: 70px; }
	 .air_list > ul > li { position: relative; font-size: 11px; line-height: 58px; padding: 0 5px; margin-right: 0; transition: all 0.3s ease; }
	 .air_list > ul > li:before { content: ''; width: 17px; height: 17px; display: block; border-radius: 50%; position: absolute; top: 2px; left: 50%; transform: translateX(-50%); }
	 .air_list > ul > .ic_good:before { content: ''; background: url('../images/contents/ic_airpollution_good-25px.svg') no-repeat center; background-size: 19px 19px; }
	 .air_list > ul > .ic_generally:before { content: ''; background: url('../images/contents/ic_airpollution_generally-25px.svg') no-repeat center; background-size: 19px 19px; }
	 .air_list > ul > .ic_bad:before { content: ''; background: url('../images/contents/ic_airpollution_bad-25px.svg') no-repeat center; background-size: 19px 19px; }
	 .air_list > ul > .ic_verybad:before { content: ''; background: url('../images/contents/ic_airpollution_verybad-25px.svg') no-repeat center; background-size: 19px 19px; }
	 .air_list > ul > .ic_error:before { content: ''; background: url('../images/contents/ic_airpollution_error-25px.svg') no-repeat center; background-size: 19px 19px; }
 
	 /* Tool Tip */
	 .air_list > ul > li .airTool_box { position: absolute; left: 50%; transform: translateX(-50%); background: #fff; padding: 10px 12px; border-radius: 6px; display: none; opacity: 0; transition: all 0. 3s ease; bottom: -60px; width: 195px; height: 60px; z-index: 2; box-shadow: 0px 0px 9px rgb(0 0 0 / 20%); box-sizing: border-box; line-height: 15px; border: 1px solid #e1e4e6; }
	 .air_list > ul > li .airTool_box:before { content: ''; position: absolute; left: 50%; margin-left: -5px; border-bottom: 5px solid #fff; border-left: 5px solid transparent; border-right: 5px solid  transparent; top: -5px; }
	 .air_list > ul > li:hover .airTool_box { display: block; opacity: 1; bottom: -47px;}
	 .air_list:last-child .airTool_box { transform: translateX(-72%); }
	 .air_list:last-child .airTool_box:before { content: ''; margin-left: 38px; }
 
	 .airTool_box p.dust_text { width: 100%; text-align: right; }
	 .airTool_box p strong { float: left; color: #232848; }
	 .airTool_box .goodText { color: #477fff; }
	 .airTool_box .generallyText { color: #00cc86; }
	 .airTool_box .badText { color: #ffb542; }
	 .airTool_box .verybadText { color: #ff5757; }
	 .airTool_box .errorText { color: #a5a5a5; }
 
	 .airTool_box .dust { width: 98%; height: 6px; background: #f1f3f5; margin-top: 5px; border-radius: 30px; }
	 .airTool_box .dust .dust_good { width: 20%; margin-top: 0px; background: #477fff; }
	 .airTool_box .dust .dust_generally { width: 42%; margin-top: 0px; background: #00cc86; }
	 .airTool_box .dust .dust_bad { width: 60%; margin-top: 0px; background: #ffb542; }
	 .airTool_box .dust .dust_verybad { width: 85%; margin-top: 0px; background: #ff5757; }
	 .airTool_box .dust .dust_error { width: 0%; margin-top: 0px; background: #a5a5a5; }
	 .airTool_box .standard_text { display: inline-block; padding: 2px 0 2px 22px; font-size: 10px; opacity: .5; box-sizing: border-box; font-weight: normal; }


	 /* 21.04.23 키즈 대시보드 출석부_학부모보기 */
	 .user_children li { width: 100%; overflow: hidden; text-overflow: ellipsis; }
	 .user_children .userChil_list { padding: 10px 0 !important; overflow: hidden; white-space: nowrap; }
	 span.userChil_img { float: left; width: 40px; height: 40px; display: inline-block; background: #ececec; border-radius: 50%; border: 1px solid #ececec; box-sizing: border-box; margin-right: 15px; }
	 .userChil_list span { box-sizing: border-box; line-height: 40px; }
	 .userInfor { float: left; padding: 5px 0; }
	 .userInfor p.child_class { color: #4dbaff; font-size: 12px; margin-bottom: 3px; }
	 .userInfor p.userChil_name { font-size: 13px; }
	 .userTime { padding: 0 10%; box-sizing: border-box; display: inline-block; }
	 .userChil_list span.child_onTime { position: relative; padding-left: 28px; }
	 .userChil_list span.child_onTime:before { content: '등'; width: 17px; height: 17px; display: inline-block; background: #477fff; border-radius: 3px; color: #fff; position: absolute; left: 0; top: 0; font-size: 11px; vertical-align: top; line-height: 17px; box-sizing: border-box; text-align: center; }
	 .userChil_list span.child_onTime.on_absent:before { content: '결'; width: 17px; height: 17px; display: inline-block; background: #477fff; border-radius: 3px; color: #fff; position: absolute; left: 0; top: 0; font-size: 11px; vertical-align: top; line-height: 17px; box-sizing: border-box; text-align: center; }
	 .userChil_list span.child_onTime.off_absent:before { content: '결'; width: 17px; height: 17px; display: inline-block; background: #ff6363; border-radius: 3px; color: #fff; position: absolute; left: 0; top: 0; font-size: 11px; vertical-align: top; line-height: 17px; box-sizing: border-box; text-align: center; }
	 .userChil_list span.child_offTime { position: relative; padding-left: 28px; margin-left: 8%; }
	 .userChil_list span.child_offTime.on_absent { display: none; }
	 .userChil_list span.child_offTime.off_absent { display: none; }
	 .userChil_list span.child_offTime:before { content: '하'; width: 17px; height: 17px; display: inline-block; background: #13d08b; border-radius: 3px; color: #fff; position: absolute; left: 0; top: 0; font-size: 11px; vertical-align: top; line-height: 17px; box-sizing: border-box; text-align: center; }
	 .ir_pm { display:block; overflow:hidden; font-size:0; line-height:0; text-indent:-9999px; } /* 의미있는 이미지의 대체 텍스트를 제공하는 경우(Phark Method) */

	 /* 21.04.26 키즈 대시보드 원아 */
	 .tabKidsList .hoduE_safety { height : 219px; box-sizing: border-box; overflow: hidden; }

	 .tabKidsList .hoduE_safety .mCSB_scrollTools.mCS-light.mCSB_scrollTools_vertical { right: 7px; }
	 .user_children .safe_list { position: relative; }
	 .user_children .safe_list .safe_detail { width: 100%; height: 100%; display: inline-block; transition: .2s; }
	 .user_children .safe_list .safe_detail:hover { opacity: .7; }
	 .user_children .safeChil_list { white-space: normal; }
	 .userChil_list .now_safe { line-height: 40px; float: left; font-weight: bold; min-width: 102px; box-sizing: border-box; }
	 .userChil_list span.safe_good { color: #477fff; }
	 .userChil_list span.safe_bad { color: #ffa70e; }
	 .userChil_list span.safe_verybad { color: #ff5757; }
	 .userChil_list span.safe_good:before { content: ''; width: 25px; height: 25px; display: inline-block; background: url('../../../src/assets/images/contents/hodukids_safe_good.svg') no-repeat; border-radius: 50%; margin: 0 10px; vertical-align: middle; }
	 .userChil_list span.safe_bad:before { content: ''; width: 25px; height: 25px; display: inline-block; background: url('../../../src/assets/images/contents/hodukids_safe_bad.svg') no-repeat; border-radius: 50%; margin: 0 10px; vertical-align: middle; }
	 .userChil_list span.safe_verybad:before { content: ''; width: 25px; height: 25px; display: inline-block; background: url('../../../src/assets/images/contents/hodukids_safe_verybad.svg') no-repeat; border-radius: 50%; margin: 0 10px; vertical-align: middle; }
	 .userChil_list .safety_class { display: inline-block; margin-left: 15px; line-height: 40px; }
	 .userChil_list .safety_class p { display: inline-block; padding: 0 10px; font-weight: bold; }
	 p.safe_stress { position: relative; }
	 p.safe_stress:before { content: ''; width: 2px; height: 16px; display: inline-block; background: #d2d7e2; position: absolute; left: 0; top: 13px; }
	 p.safe_stress:after { content: ''; width: 2px; height: 16px; display: inline-block; background: #d2d7e2; position: absolute; right: 0; top: 13px; }
	 
	
	/* 키즈 대시보드 미디어 쿼리 */
	@media (max-width: 1415px) {
		.userChil_list span { line-height: 20px; }
		.userChil_list span.child_onTime { display: block; }
		.userChil_list span.child_offTime { margin-left: 0; }
		span.child_onTime.on_absent, span.child_onTime.off_absent { margin-top: 15px; }
	 }
	 @media (max-width: 1218px) {
		.userTime { padding: 0 4%; }
		.userChil_list span.userChil_img { margin-right: 10px; }
		.userChil_list span.child_onTime, .userChil_list span.child_offTime { padding-left: 23px; }
	 }
	 @media (max-width: 1220px) {
		.userChil_list .safety_class { min-width: 305px; margin-left: 0; }
	 }
	 @media (max-width: 1201px) {
		.userChil_list .safety_class p { padding: 0 15px; }
		.userChil_list .safety_class p:first-child { padding-left: 5px; }
	 }

	 /* 22.03 대시보드 */
	 .grpPage .first .grpTeamUl .img { top: -1px; background-position: 50% !important; width: 62px; height: 43px; }