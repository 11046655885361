<template>
    <div id="resident_data" class="section_ce_fix">

        <resize-observer @notify="handleResize" />

        <TitleBox 
            :title="'입주민 데이터'" 
            :search_enable="false"
            :manual_disable="true" />

        <div class="section_scroll">
            <div class="viewGroup">
                <div>

                    <ul class="sortHeader">
                        <li class="num">No</li>
                        <li class="time"><span>그룹ID</span></li>
                        <li class="group_team"><span>이름</span></li>
                        <li class="title"><span>전화번호</span></li>
                        <li class="writer"><span>동&amp;호</span></li>
                    </ul>

                    <div id="resident_data_scroll">
                        <ul id="resident_data_ul"> 
                            <li :key="resident_data.resident_id" v-for="(resident_data, index) in resident_datas">
                                <p class="num">{{ index + 1 }}</p>
                                <p class="time">{{ resident_data.group_id }}</p>
                                <p class="group_team">{{ resident_data.resident_name }}</p>
                                <p class="title">{{ resident_data.resident_phone_number }}</p>
                                <p class="writer">{{ resident_data.building }}동 {{ resident_data.unit }}호</p>
                            </li>
                            <!-- <li>
                                <p class="num">1</p>
                                <p class="time">4752</p>
                                <p class="group_team">이성재</p>
                                <p class="title">01071046968</p>
                                <p class="writer">101동 1201호</p>
                            </li> -->
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="bt_scheduleBox">
            <input type="button" id="btnAddWork" class="bt_schedule"/>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import TitleBox from '@/components/ui/TitleBox.vue';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        TitleBox, ResizeObserver
    },
})
export default class ResidentData extends Mixins(VueHoduCommon) {

    resident_datas : any[] = [];

    mounted() : void {
        this.getResidentDatas();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height : number | undefined = $('#resident_data .title_box').outerHeight();
        const sort_header_height : number | undefined = $('#resident_data .sortHeader').outerHeight();

        // @ts-ignore
        $('#resident_data_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ) - ( sort_header_height == null ? 0 : sort_header_height )
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#resident_data_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

    /**
     * 입주민 데이터 조회
     */
    async getResidentDatas() : Promise<void> {

        try {
            
            const response = await this.hodu_api_call(`api/v1/home/resident_data`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.resident_datas ) {
                throw new Error("입주민 데이터 조회 중 오류 발생");
            }

            this.resident_datas.splice(0, this.resident_datas.length); 
            this.resident_datas = this.resident_datas.concat(response.data.data.resident_datas);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

}
</script>

<style scoped>
    #resident_data .num { text-align: center; width: 8%; padding-right: 0; font-weight: bold; }
    #resident_data .time { width: 15%; font-weight: bold; }
    #resident_data .group_team { width: 16%; font-weight: bold; }
    #resident_data .title { width: 40%; font-weight: bold; }
    #resident_data .writer { width : 21%; font-weight: bold; }

    ul#resident_data_ul > li { float:left; width:100%; border-bottom: 1px solid #f1f3f5;margin-top: 0;margin-bottom: 0; transition: 0.3s;box-sizing:border-box; cursor: pointer; }
    ul#resident_data_ul > li:hover { background : #f6f9fb; }
    #resident_data_scroll #resident_data_ul li > .num {box-sizing: border-box;text-indent: -1px;transition:0.2s;}
    #resident_data_scroll #resident_data_ul li:hover > .num { border-width:5px; transition:0.2s;text-indent: -3px; }
    ul#resident_data_ul > li > p { display: inline-block; float:left; line-height: 60px; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; box-sizing: border-box; padding-right: 25px; color:#484848; }
</style>