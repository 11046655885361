body {overflow:hidden}

html { height: 100% }
body { position: relative; width:100%; height:100% }

#app.showfff #header h1   { z-index:4; }
#app.showfff .bt_leftContrBox { z-index:3 }
#app.showfff .whiteBg { display:block; }
.whiteBg { display: none;width: 100%;height: 100%;position: absolute;background: #485367;opacity:0.1; z-index: 100000000; }
.input_btn {width: auto;padding: 0 15px !important;border-radius: 5px;height: 40px;line-height: 40px;font-size: 14px !important;display: inline-block;color: #434343 !important;background-image: none !important;background: #fff !important;border: 1px solid #e8e8e8;font-weight: bold;box-shadow: none !important;transition: 0.1s !important;margin-left: 15px;}
.input_btn:hover { background: #f1f3f5 !important;}


.right_area_close .toolbox {padding-right: 60px;}
.right_area_close .searchDiv.on {padding-right: 60px;box-sizing: border-box;}

/* .headerSort.sortOff:hover {background:transparent url(../images/contents/im_sortUp.png) no-repeat right 13px; font-weight: bold;cursor:pointer !important;}
.headerSort.sortUp, .headerSort.sortUp:hover {background:transparent url(../images/contents/im_sortUp.png) no-repeat right 13px; font-weight: bold;cursor:pointer;}
.headerSort.sortDown, .headerSort.sortDown:hover {background:transparent url(../images/contents/im_sortDown.png) no-repeat right 13px; font-weight: bold;cursor:pointer;} */

/* 21.0223 수정 sort*/
.headerSort.sortOff:hover {background:transparent url(../images/contents/im_sortUp.png) no-repeat left 13px; font-weight: bold;cursor:pointer !important; text-indent: 17px; color: #477fff;}
.headerSort.sortUp, .headerSort.sortUp:hover {background:transparent url(../images/contents/im_sortUp.png) no-repeat left 13px; font-weight: bold;cursor:pointer; text-indent: 17px; color: #477fff;}
.headerSort.sortDown span, .headerSort.sortUp span {color: #477fff; }
.headerSort.sortDown, .headerSort.sortDown:hover {background:transparent url(../images/contents/im_sortDown.png) no-repeat left 13px; font-weight: bold;cursor:pointer; text-indent: 17px; color: #477fff;}


/* 검색 */

.schBtn {display: inline-block;height: 38px;width: 38px;background: url(../images/contents/ic_search_on.png) no-repeat center center;float:right;font-size:0;margin: 10px 0; margin-left:20px;}
.schBtn:hover {background: #477fff url(../images/contents/ic_search_fff.png) no-repeat center center;}

.searchDiv.on { display:block; }
.searchDiv {position: absolute;display:none;top: 0;left: 0;width: 100%;height: 63px;background: #fff;}
.searchDiv .bt_option {display:none!important;position: absolute;left: 0;top: 0;width: 125px;height: 61px;line-height:61px;padding: 0 25px;background: #fff url(../images/side_area/ic_depthopen_new.png) no-repeat 90px center; font-size: 14px;font-weight: bold;box-sizing: border-box;border-right:1px solid #e7e9ea;}
.searchDiv .bt_option.on { background: #fff url(../images/side_area/ic_depthclose_new.png) no-repeat 90px center; }
.searchDiv .bt_option:hover { background-color:#f1f3f5; }
.searchDiv a.closeBtn {display: inline-block;font-size:0;margin-top: 10px;border-radius:50%;width:38px;height:38px;font-weight:bold; margin-right:25px;background: #477fff url(../images/contents/ic_close_fff.gif) no-repeat center center}
.searchDiv .schGrp {display: inline-block;width: 100%;z-index: 100000;position: relative;}
.schGrp input {transition:0.2s;width: 100%;height: 63px;line-height: 63px;padding: 0 10px 0 30px;font-size: 19px;font-weight: bold;box-sizing: border-box;border-bottom: 2px solid #e7e9ea;}
.schGrp input:hover { background:#f1f3f5; }
.schGrp input:focus { border-bottom:2px solid #477fff; background:#fff; }
.left_area_close .schGrp input {padding-left: 85px !important;}

.schGrp .btns {position: absolute;top: 0;right: 0;}
.schGrp .btns a { display:inline-block; float:right; }
.schGrp .btns a.bt_option {width: 38px;height: 38px;background: #f1f3f5;text-align: center;line-height: 38px;margin-top: 10px;border-radius: 50%;margin: 10px 20px 0 0;}
.schGrp .btns a.bt_option .dot {background: #2e3856;display: inline-block;width: 4px;margin: 0 2px;height: 4px;border-radius: 50%;}
.schGrp .btns a.bt_option .txt {color: #fff;font-weight: bold;display: none;}
.schGrp .btns a.bt_search {width: 38px;height: 38px;background:  url(../images/contents/ic_search_on.png) no-repeat center center;text-align: center;line-height: 38px;margin-top: 10px;border-radius: 50%;margin: 10px 20px 0 0;}
.schGrp .btns a.bt_search:hover { background:#477fff url(../images/contents/ic_search_fff.png) no-repeat center center; }



/* forView 일정상세 */
div.section_ce_fix.forView a.result {right: 0;}
div.section_ce_fix.forView .addPicNfile p.count{ display:none;}
div.section_ce_fix.forView .clickOrDropBg { display:none }
div.section_ce_fix.forView .chart {display:none}
div.section_ce_fix.forView #attendTr input[type='checkbox']:not(old)+label { display:none; }
div.section_ce_fix.forView div#attendInput { display:block; }
div.section_ce_fix.forView div#attendInput .btns { display:block;margin-top:10px; }
div.section_ce_fix.forView .schedule_box tr td:after { display:none; }
div.section_ce_fix.forView .schedule_box tr#alarmTr td:after { display:block; }
div.section_ce_fix.forView .schedule_box tr#noteTr td:after { display:block; }
div.section_ce_fix.forView .schedule_box tr#memoTr td:after { display:block; }
div.section_ce_fix.forView a#btnRepeat { display:none; }
div.section_ce_fix.forView #mapSearch { display:none; }
div.section_ce_fix.forView #btnVoteInput { display:none; }
div.section_ce_fix.forView #shareTr input { display:none; }
div.section_ce_fix.forView .doc_voteBox .result { display: block; }
div.section_ce_fix.forView .iVoted { display:block; }
div.section_ce_fix.forView .voteTD a.seeDetail { display:none; }
div.section_ce_fix.forView #attendTr a.result {display:block;}

/* 초대장 */
.section_ce_fix .inviteContent { display:none }
.section_ce_fix .inviteImg {display:none;width:100%;height: 600px;background-size:cover !important;background: url(../images/footer/g11.jpg) no-repeat center center;border-radius: 25px 25px 0 0;}
.section_ce_fix.invite .inviteImg, .section_ce_fix.invite .inviteContent { display:block;}
.section_ce_fix.invite #workTr, .section_ce_fix.invite #workerTr, .section_ce_fix.invite #repeatTr, .section_ce_fix.invite #memoTr { display:none;}
.section_ce_fix.invite .inviteImg p { display:none; }
.section_ce_fix.invite .writeSche {max-width: 520px !important;background: #fff;margin: 0 auto;margin-top:40px;margin-bottom:100px;box-shadow: 0 10px 30px rgba(0,0,0,0.2);padding-left: 0 !important;padding-right: 0 !important;padding-top: 0 !important;border-radius: 25px;}
.section_ce_fix.invite  .selectboxWrap.fr.input_gray.w61 {position: absolute;float: none !important;bottom: 14px;left: 0;font-size: 14px !important;height: 35px;line-height: 35px !important;}
.section_ce_fix.invite .inputgroup p.fr {position: absolute;bottom: 0;left: 70px;}
.section_ce_fix.invite .inputgroup {margin-bottom: 0;border-bottom: 0 none;}
.invite .inputgroup .selectboxWrap:hover label, .invite .inputgroup label:hover {background: #477fff !important;color: #fff !important;}
.invite .inputgroup input[type='checkbox']:checked + label {background: #477fff !important;color: #fff !important;}
.invite .inputgroup .selectboxWrap label {background: #f1f3f5;border-radius: 40px;width: 70px !important;font-size: 13px;margin-top:0 !important}
.section_ce_fix.invite .inputgroup p.fr label {width: 70px;height: 35px;line-height: 35px;background: #f1f3f5;margin-top: 12px;font-size: 12px;border-radius: 40px;}
.invite #schDetailScroll .inputgroup .input_txt {width: 100px !important;font-size: 12px;}
.invite #schDetailScroll .inputgroup input#s_FrTm,.invite  #schDetailScroll .inputgroup input#s_ToTm {width: 56px !important;}
.invite #schDetailScroll .inputgroup .arw {width: 45px !important;margin-top: 1px !important;}
.section_ce_fix.invite #time_today {position: absolute;bottom: 13px;border-radius: 40px;width: 70px;left: 140px;font-size: 12px !important;color: #000;font-weight: bold;background: #f1f3f5;height: 35px;}
.section_ce_fix.invite .inputgroup .selectboxWrap select {height:40px;width: 70px;padding-right: 10px;}
#container #section_ce .invite #schDetailScroll {background: #fff;}
.section_ce_fix.invite .tableDefault.tableBorder.th-right {padding: 0 40px 40px 30px !important;border-radius: 5px;}
.invite tr#titleTr {position: absolute;top: 490px;left: 0;width: 100%;}
.invite tr#titleTr th {display: none;}
.invite tr#titleTr input {background: transparent;}
.invite tr#titleTr #s_mainTitle {width: 440px !important;padding: 0 50px 0 20px !important;box-sizing: border-box;margin-left: 37px;background: rgba(0,0,0,0.3);height:70px !important; line-height:70px !important;min-height: 70px;color:#fff !important; font-size: 21px !important; }
.invite tr#titleTr #s_mainTitle:disabled { color:#fff !important; }
.invite tr#titleTr td {width: 100% !important;border-bottom: 0 !important;}
.invite tr#titleTr:hover td:after {display: none;}
.invite tr#titleTr #s_mainTitle::placeholder { color: rgba(255,255,255,0.7); }
.invite tr#titleTr #s_mainTitle:hover::placeholder { color: #fff; }
.invite #pickColour {width: 439.5px;left: 37px;top: 70px;}
.invite .forRel input.clr {right: 20px;top:22px;border: 2px solid #fff;}
#wrap .invite .inviteContent span {display: block;position: absolute;left: -20px;top: -25px;z-index: 100000;width: 50px;height: 50px;background: url(../images/contents/ic_quotation_b.png) no-repeat center center;background-size: 50px;}
#wrap .invite .inviteContent span.end {background: url(../images/contents/ic_quotation_e.png) no-repeat center center;background-size: 50px;right: -20px;bottom: -25px;left: auto;top: auto;}
#wrap .invite .inviteContent {width: 320px;margin: 70px auto 50px;height: auto;/* overflow: hidden; */position:relative;float: left;margin-left: 100px;}
#wrap .invite .inviteContent textarea {max-height: 100% !important;min-height: 130px !important;height: 300px !important;resize: none;padding: 30px !important;background: #f1f3f5 !important;text-align:center; border:1px solid #f1f3f5; font-size:16px !important; font-weight:bold; line-height:20px;}
#wrap .invite .inviteContent textarea::placeholder { font-weight:bold; font-size:16px; line-height:240px; text-align:center; }
#wrap .invite .inviteContent textarea:hover {background: #e7ecf1 !important;}
#wrap .invite .inviteContent textarea:focus {border: 1px solid #477fff;background: #fff !important;}


a.waiting {float: right;font-size: 14px;font-weight: bold;display: inline-block;background: #ff5050;width: 32px;float: right;margin-top: 13px;line-height: 30px;height: 32px;margin-left: 20px;text-align: center;border-radius: 17px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 16px;color: #fff;}
a.waiting .txt {width: 0;height: 0;font-size: 0;}
a.waiting span {color:#fff;}
a.waiting:hover {width: auto;border-radius: 15px;padding: 0 15px;transition: 0.2s;box-shadow: 0 4px 4px rgba(255, 80, 80, 0.3);}
a.waiting:hover .txt {line-height:30px;font-size: 12px;margin-right:10px;}

.firstPage.end1 {transition: 0.4s;}
.firstPage.end2 {opacity:0; }
.firstPage.end3 {display:none; }
.firstPage {display: block;position: absolute;width: 100%;height: 100%;background: #477fff;z-index: 10000000000000;background-image: linear-gradient(to bottom, #477fff 30% , #4738ff 119%);text-align: center;}
.firstPage img {width: 210px;}
.firstPage img.bouncy {animation-name: bouncy;animation-duration: 1s;animation-duration: leaner;animation-iteration-count:infinite;}
.firstPage .grp {position: absolute;top: 50%;margin-top: -60px;margin-left: -170px;width: 370px;display: inline-block;left: 50%;}
.firstPage .grp p {opacity:0.5;color: #fff;font-weight: bold;font-size: 16px;text-transform: uppercase;/* display: none; */margin-top: 30px;}
.firstPage .loadingBar {display: inline-block;width: 230px;border-radius: 7px;height: 12px;display: none;margin-top: 30px;background: #2b489a;}
.firstPage .loadingBar .bar {display: inline-block;float: left;width: 50%;height: 100%;background: #ffc75c;border-radius: 7px;}
span.percentage {position: absolute;bottom: -30px;color: #fff;left: 50%;font-weight: bold;margin-left: -20px;text-align: center;}

.firstPage.end1 img {
    transition: 0.4s;
    width: 59px;
    margin-top: 46px;
    margin-left: -99px;
}

.firstPage.end1 {   transition: 0.4s;width: 260px;
    height: 62px;
    background-image: linear-gradient(to right, #477fff 30% , #4738ff 119%);
   }

@-webkit-keyframes bouncy { 
    0% {
        transform:scale(1);
        opacity:0.5;
    } 
    50% {
        transform:scale(1.1); 
        opacity:1;
    }
    100% {
        transform:scale(1);
        opacity:0.5;
    }   

 }

#wrap{width:100%;height:100%;}
#header{height: 61px;z-index:100;border-bottom: 2px solid #e7e9ea;}
#header h1{position:absolute;left: 66px;top: 17px;z-index: 30000000;}
#header h1:hover img {transition: 0.2s;transform: scale(1.1);}
#header .tagline {display: none;position: absolute;left: 132px;top: 20px;font-size: 11px;text-transform: uppercase;z-index: 1000;line-height: 12px;opacity: 0.9;width: 110px;color: #fff;z-index: 400000;}

#header .gnb_rightarea .itms { display: none; }
#header.crst .gnb_rightarea .itms { display: inline-block !important; }

.headerTitleDiv {width:100%;line-height: 50px;border-bottom: 1px solid #e7e9ea;padding: 0 20px;position: relative;box-sizing: border-box;}
#headerTitle {width:100%;height: 50px;padding-left: 20px;box-sizing:border-box;font-size: 14px;text-overflow: ellipsis;font-weight: bold;overflow: hidden;white-space: nowrap;}
.headerTitleDiv .clr {display: inline-block;width: 9px;height: 9px;background: #477fff;border-radius: 50%;margin-right: 12px;position: absolute;top: 50%;margin-top: -4.5px;vertical-align: middle;}
.headerTitleDiv .personalCalendar { position:absolute; display:block; background:url(../images/contents/ic_go_back.png) no-repeat center 5px;background-size: 18px;font-size: 0;width: 30px;height: 30px;top: 11px;right: 14px; border-radius: 50%; background-color: #f1f3f5; }
.headerTitleDiv .personalCalendar:hover {background-color:#ff6060; background-image: url(../images/contents/ic_go_back_on.png) }

#wrap.leftOn .gnb_area .gnb_schInbox {left: 61px;}
#wrap.leftOn .bt_leftContrBox:before {width:61px;}
.leftOn h1, .leftOn .tagline {display: none;}
#wrap.leftOn #left_area {left: -200px !important;display:block !important;}
.gnb_area {width: 60px;height: 60px;padding: 0;right: 60px !important;float: none !important;margin: 0;position: absolute;display: none;left: auto;z-index: 0;transition: 0.1s;}
.gnb_area.on {display:block;text-align: right;padding-top: 0;transition: 0.1s;height: 62px;padding-right: 60px;box-sizing: border-box;padding-left: 260px;position: absolute;left: 0;width: 100%;}
.gnb_area.on{text-align:right;padding-top:0;transition: 0.1s;height: 62px;padding-right: 60px;box-sizing: border-box;padding-left: 260px;position: absolute;left: 0;width: 100%;}
.gnb_area .gnb_search.over_hidden {overflow:visible;width: 100%;height: 100%;}
.gnb_area .gnb_search .gnb_title{ display: inline-block; font-weight: bold;font-size: 12px;color: #232848;line-height: 53px; cursor:default; background:#fff; padding:0 15px;}
.gnb_area .gnb_search .gnb_title span {display: inline-block;width: 10px;height: 10px;background: #477fff;border-radius: 5px;margin-right: 12px;}
.gnb_area .gnb_rightarea{position:absolute; left:0;top:0;display:inline-block; }
.gnb_area .gnb_schInbox{z-index: 1000;position:relative;box-sizing: border-box;left: 0;display:inline-block;font-size:0;overflow:hidden;}
.gnb_area .gnb_schInbox .input_search02{text-align:left;}
.input_search{border:none;height:28px;float:left;width:215px;margin-right:10px;text-align:right;font-size:14px}
.gnb_area .bt_search{position:absolute; right:0;top:0;display:inline-block;width:28px;height:28px;background: #477fff url(../images/contents/ic_search_fff.png) no-repeat 8px 8px;z-index:10}
.bt_logoutBox{display:none;float:right;}
.bt_logout{display:inline-block;width:35px;height:35px;margin-left:15px;border-radius:50%;background: #ff5d5d; }
.bt_logoutBox.on{}
a.bt_logout img {margin-top: 3px;}

#container{position:absolute;top: 0;left:0;right:0;bottom:0;z-index:20;}


.view_orange{display:inline-block;width:6px;height:6px;float:right;background:url('../images/side_area/ic_org.png') no-repeat right top;margin-top:3px;}
.view_gray{display:inline-block;width:6px;height:6px;float:right;background:url('../images/side_area/ic_gray.png') no-repeat right top;margin-top:3px;}


.bt_calContr{position:absolute; right:6px;top:9px;display:inline-block;width:25px;height:25px;float:right;background:url('../images/side_area/bt_close.png') no-repeat right top;}
.bt_calContr.on{background:url('../images/side_area/bt_open.png') no-repeat right top;}

#wrap #left_area.on {opacity:0;transition:0.1s; width:0; overflow:hidden; }
#container #left_area{opacity:1;transition:0.1s;position:absolute;left:0;top: 62px;bottom:0;background-color:#fff;width:260px;padding: 0 0 50px 0;border-right:1px solid #e1e4e6;z-index: 100;}
#container #left_area .left_inbox{padding:0;height: 282px; /* transition:0.1s; */ }
#container #left_area .left_inbox.on { height: 53px; /* transition:0.1s; */ overflow: hidden;border-bottom: 1px solid #e7e9ea; }
.cal_view{position:absolute;right:20px;top:22px}

.left_inbox02{padding: 0 0; margin-top: 0;overflow:hidden;}
div#grpScroll {overflow-y: auto;}
#container #section_ce{transition:0.1s;position:absolute;left:261px;right:60px;top:0;bottom:0;background-color:#fff;overflow-y:hidden;}
#container #section_ce .section_ce_fix{position:relative;height:100%;right:0;left:0;bottom:0;top:0;overflow: hidden;}
#container #section_ce .section_scroll{min-width:788px;}
#container #section_ce.left_area_close .section_scroll {padding-left: 61px;}
.right_area_close div#workSettingScroll {padding-left: 61px;}
#container #section_ce .section_scroll.msgPage  {min-width:100%;overflow: hidden;overflow-x: hidden;}
#container #section_ce.left_area_close .section_scroll.msgPage {padding-left: 61px;}
#container #section_ce .schedule_box{position:relative;padding-left:40px;padding-right: 40px;padding-bottom: 40px;max-width: 900px;}
#container #section_ce #grpScroll .schedule_box {max-width: 100%;}
#container #section_ce .schedule_box.writeSche { padding-left:20px; padding-right:40px;width:900px !important }
#container #section_ce .schedule_box02{padding: 0px 26px; width: 736px; height: 532px; display: table-cell;vertical-align: middle;}
#section_ce .grp.settingBg.search {
    top: 0;
    padding: 0 !important;
    height:60px;
    width: 50% !important;
    min-width: 250px !important;
    max-width: 300px;
    left: auto !important;
    right: 1px;
    position: absolute;
}
#container #section_ce .msgPage.sendMmsPage .schedule_box {max-width: 100%;padding-left: 0;overflow-x: hidden;margin-top: 45px;}
.view02Group{display: table;margin:0 auto;}


/* leftArea control */
.bt_leftContrBox:before {width: 260px;height: 100%; max-height: 61px; content: '';background-color: #477fff;background-image: linear-gradient(to right, #477fff 30% , #4738ff 119%);display: block;position: absolute;z-index: -1;left: 0;transition: 0.1s;}
.bt_leftContrBox .bg {position: absolute;width: 40px;height: 40px;top: 11px;left: 11px;z-index: -1;background: rgba(0,0,0,0.1);border-radius: 50%;}
.bt_leftContrBox {position:absolute;top:0;left:0;transition: 0.1s;z-index: 20000;}
.bt_leftarea {display:inline-block;width:40px;height: 45px;background: url(../images/contents/bt_leftContr_new.png) no-repeat 6px center;margin-top: 7px;margin-left: 7px;}

.bt_leftarea span{content: '';display: block;margin: 6px 6px;width: 19px;height: 2px;background: #fff;transition:0.1s;}
.bt_leftarea {padding-top: 9px;padding-left:8px;overflow:hidden;}
.bt_leftarea  { background:none; }
.bt_leftContrBox .bg { opacity:0 }
.bt_leftarea:hover {margin-left: 12px;}

.bt_leftContrBox:hover .bg { opacity:1; background: rgba(0,0,0,0.1);}
.bt_leftarea:hover span { margin-left:2px; transform: translateX(-40px); border-radius:3px; opacity:0 }
.bt_leftarea:hover span:first-child {opacity:1;width: 13px;transform:  rotate(-35deg) translateY(4.5px) translateX(-2px);}
.bt_leftarea:hover span:last-child { opacity:1; width: 13px; transform: rotate(35deg) translateY(-4.5px) translateX(-2px); }


.bt_leftContrBox.on .bt_leftarea:hover span { margin-left:11px; transform: translateX(-40px); border-radius:3px; opacity:0 }
.bt_leftContrBox.on .bt_leftarea:hover span:first-child { opacity:1; width: 13px; transform:  rotate(35deg) translateY(7.5px) translateX(-2px); }
.bt_leftContrBox.on .bt_leftarea:hover span:last-child { opacity:1; width: 13px; transform: rotate(-35deg) translateY(-7.5px) translateX(-2px); }

.alldate_box #saveStng, .bt_add {position: absolute;background: #fff url(../images/contents/ic_plus_new.png) no-repeat center center !important;top: 0;right: 15px;width: 30px;transition: 0.2s;font-size: 0;background-size: 21px !important;height: 30px;}
.alldate_box #saveStng:hover { background-color:#f1f3f5 !important; }
.alldate_box:hover #saveStng {right: 15px;}

#left_area .class_depth input[type='checkbox']:not(old) + label {border-radius: 3px;}

 #left_area .class_depth input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 14px;height: 14px;border: 2px solid #d9d9d9;background: #fff url('../images/contents/checked_gray.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
#left_area .class_depth input[type='checkbox']:not(old):checked + label {background: #7d68ff url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;border: 2px solid #7d68ff;}
#left_area .class_depth02 { padding-left: 20px; }
#left_area .class_depth02 input[type='checkbox']:not(old) + label {position: absolute; left: 0; top: 0; width: 100%; height: 30px; line-height: 40px; background: #f3f3f3 url(../images/contents/checked_gray.png) no-repeat 7px 6px; background-size: 19px; border: 0 none;}
#left_area .class_depth02 input[type='checkbox']:not(old):checked + label { background-position: 6px 6px; }
#left_area .class_depth .class_depth02 li > p > span {display: inline-block;width: 147px;left: 32px;z-index: 1;position: absolute;padding: 0;line-height: 31px;height: 30px;color: #4e4e4e;font-weight: 100;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
#left_area .class_depth02 input[type='checkbox']:not(old):checked + label, #left_area .class_depth02 input[type='checkbox']:not(old) + label:hover { background: #d6f1ff url(../images/contents/checked_blue.png) no-repeat 7px 6px; background-size: 19px; border: 0 none; }
#left_area .class_depth .class_depth02 li { margin-bottom: 8px; height: 30px; padding: 0; }
#left_area .class_depth > li.on > p {margin-bottom: 10px;background: url('../images/side_area/ic_depthopen_new.png') no-repeat right 7px;}
#left_area .class_depth > li > p {background: url(../images/side_area/ic_depthclose_new.png) no-repeat 240px 7px;}
#left_area .class_depth .class_depth02 li:last-child { margin-bottom: 0; } 

#left_area .class_depth input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../images/contents/checked_gray.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
#left_area .class_depth input[type='checkbox']:not(old):checked + label {background: #7d68ff url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;border: 2px solid #7d68ff;}#left_area .class_depth02 { padding-left: 20px; }



/* rightArea control */
.bt_rightContrBox .bg {position: absolute;width: 40px;height: 40px;top: 7px;right: 15px;z-index: -1;background: #f1f3f5;border-radius: 50%;}
.bt_rightContrBox{position:absolute;top:0;right:0;z-index: 10000;background: #fff;width: 53px;border-left: 1px solid #e7e9ea;}
.bt_rightarea{display:inline-block;width:40px;height:40px;margin-top: 11px;margin-right:15px;}
.bt_rightContrBox:hover .bg { background:#e5e8ea; }

.bt_rightarea span {display: block;width: 4px;height: 4px;border-radius: 50%;background: #2e3856;margin: 6px 0;margin-left: 28px; transition:0.1s;}
.bt_rightContrBox .bg { opacity:0; display:block;border-radius:0;background:#f1f3f5; width:100%; height:100%;top:0;right:0;}
.bt_rightContrBox:hover .bg {opacity:1;background:#f1f3f5; }
.bt_rightContrBox {width:59px;height: 60px;border-bottom: 2px solid #e7e9ea;}
.bt_rightContrBox.on .bt_rightarea:hover span {margin-left:20px; height:2px; transform: translateX(40px); border-radius:3px; opacity:0  }
.bt_rightContrBox.on .bt_rightarea:hover span:first-child { opacity:1; width: 13px; transform:  rotate(-35deg) translateY(4.5px) translateX(-2px);margin-top:10px }
.bt_rightContrBox.on .bt_rightarea:hover span:last-child { opacity:1; width: 13px; transform: rotate(35deg) translateY(-4.5px) translateX(-2px); }

.bt_rightContrBox .bt_rightarea:hover span {margin-left:28px; height:2px; transform: translateX(40px); border-radius:3px; opacity:0  }
.bt_rightContrBox .bt_rightarea:hover span:first-child { opacity:1; width: 13px; transform:  rotate(35deg) translateY(7.5px) translateX(-2px);margin-top:10px }
.bt_rightContrBox .bt_rightarea:hover span:last-child { opacity:1; width: 13px; transform: rotate(-35deg) translateY(-7.5px) translateX(-2px); }



/* right and left controller hover */
#container.leftHover #left_area { left: -20px !important; }
#container.leftHover #section_ce { left:261px !important; }
#container.rightHover #right_area { right: -20px !important; }
#container.rightHover #section_ce { right: 191px !important }

#container.leftClosed.leftHover #left_area { left: -260px !important; display:block !important; }
#container.leftClosed.leftHover #section_ce { left:20px !important; }
#container.rightClosed.rightHover #right_area { right: -240px !important; display:block !important; }
#container.rightClosed.rightHover #section_ce { right:20px !important; }
a#srchMemBtn {right: 20px;}
.schLi {
    display: inline-block;
    float: right;
    right: 190px;
    top: 10px;
    font-size: 0;
    }
.schLi .schBtn {
    display: inline-block;
    height: 40px;
    width: 40px;
    background: url(../images/contents/ic_search_on.png) no-repeat center center;
    position: absolute;
    right: 110px;
    top: 10px;
}
.schBtn {
    display: inline-block;
    height: 40px;
    width: 40px;
    background: url(../images/contents/ic_search_on.png) no-repeat center center;
    font-size:0;
    border-radius:50%;
}
.schBtn.on { background: #477fff url(../images/contents/ic_close_fff.gif) no-repeat center center !important; }
.schBtn.schFileBtn { background: url('../images/contents/schedule_file_all.png') no-repeat center center; background-size: 22px; margin-left: 10px; }
.schBtn.schFileBtn:hover { background: #477fff url('../images/contents/schedule_file_all_fff.png') no-repeat center center; background-size: 22px; margin-left: 10px; }

.schLi.on #searchTotalDiv {right: 50px;transition:0.1s;opacity:1;top: -5px;}
#searchTotalDiv {z-index: 1000000000000000;left: 0;transition:0.1s;opacity: 1;top: -4px;position: absolute;width: 300px;display: none;height: 50px;overflow:hidden;border-radius: 5px;box-shadow: 0 1px 5px rgba(0,0,0,0.15);}
#searchTotalDiv.on { display:block; }
#searchTotalDiv #searchTotalTxt {width: 100%;height: 100%;box-sizing: border-box;padding: 0 50px 0 20px;}
#searchTotalDiv .schGrp {width: 100%;height: 100%;position: relative;}
#searchTotalDiv .bt_search {background: transparent;width: 50px;height: 50px;background: url(../images/contents/ic_search_on.png) no-repeat center center;position: absolute;right: 0;top: 0;}
#searchTotalDiv #searchTotalTxt:focus, #searchTotalDiv #searchTotalTxt:focus:hover {padding-left:18px;border: 2px solid #477fff;border-radius: 5px;background:#fff;}
#searchTotalDiv #searchTotalTxt:hover { background:#f1f3f5; }
#searchTotalDiv:hover {box-shadow: 0 4px 10px rgba(0,0,0,0.2);}





.bt_scheduleBox{display:inline-block;position:absolute;right:24px;bottom:20px;z-index:20;}/* right:44px; */
.bt_schedule{width:72px;height:72px;background:url('../images/contents/bt_plus.png') no-repeat 0 0;}
.bt_schedule:hover{background:url('../images/contents/bt_plus_on.png') no-repeat 0 0;}


.bt_shareBox{display:inline-block;position:absolute;right:34px;bottom:120px;z-index:20;width:62px; text-align:center;margin-right: 10px;}/* right:44px; */
.bt_share{z-index:10000;width: 62px;height: 62px;border-radius: 50%;background: #fff url('../images/contents/ic_share_bg.png') no-repeat 13px center;box-shadow: 0 4px 15px rgba(0,0,0,0.15);}
.bt_share:hover{opacity:1;background:  url('../images/contents/ic_share_bg_on.png') no-repeat 13px center;box-shadow: 0 4px 15px rgba(0,0,0,0.25); transition:0.1s}
.shareInnbox{display:none;margin-bottom:10px;}
.shareInnbox input{display:inline-block;width: 60px;height: 60px;transition:0.2s;border:1px solid #e3e7ed;background-position:center center; background-size: 63px;background-repeat: no-repeat; border-radius:50%; }
.bt_share01{background:url('../images/contents/bg_shareAll.png') no-repeat 0 0;}
.bt_share02{background:url('../images/contents/bg_shareAll.png') no-repeat 0 50%;}
.bt_share03{background:url('../images/contents/bg_shareAll.png') no-repeat 0 100%;}
.bt_shareBox .share_btn { position: relative; margin-top:10px;margin-bottom:20px;width: 62px;height: 62px; }
.bt_shareBox .share_btn input.bt_share00 { background-image:url(../images/contents/ic_mail_sand.png); background-size: 60px; }
.bt_shareBox .share_btn input.bt_share00:hover { background-color:#fff; }
.bt_shareBox .share_btn input.bt_share01 { background-image:url(../images/contents/ic_kakaoShare.png); background-size: 50px; border-color: #fae100 !important; background-color: #fae100; }
.bt_shareBox .share_btn input.bt_share02 { background-image:url(../images/contents/ic_hoduShare.png);  }
.bt_shareBox .share_btn input.bt_share03:hover { background-color:#fff; }
.bt_shareBox .share_btn input.bt_share03 {background-image:url(../images/contents/ic_sharedPeeps.png); background-color:#f1f3f5; background-size: 45px; }
.bt_shareBox .share_btn input.bt_share03.on {background-image:url(../images/contents/ic_sharedPeepsOn.png); }
.bt_shareBox .share_btn input:hover { box-shadow:0 3px 10px rgba(0,0,0,0.15); border-color:#fff; }
.bt_shareBox p { position: absolute;left: -95px;top: 50%;margin-top: -20px;height: 40px;display: inline-block;line-height: 40px;font-size: 13px;font-weight: bold;width: 80px;text-align: right;z-index: 100;color: #202A39;}
input.cmmntBtn {font-weight: bold;padding: 10px 30px;border-radius: 50px;border: 1px solid #e3e7ed;background: #fff;margin: 0 auto;display: block;margin-top: 30px;color: #27313f;}
input.cmmntBtn:hover, input.cmmntBtn.on {background: #477fff;border: 1px solid #477fff;transition: 0.1s;color: #fff;box-shadow: 0 5px 10px rgba(71, 127, 255, 0.2);}


#container #section_ce.left_area_close{left:0px;}
#container #section_ce.right_area_close{right:0px;}
#container #section_ce.right_area_close .bt_rightarea { background: url(../images/contents/bt_rightContr.png) no-repeat 0 0; }
#container #section_ce.right_area_close .bt_rightarea:hover { background: url(../images/contents/bt_rightContr_on.png) no-repeat 0 0; }

#container #right_area{position:absolute; right:-600px;top:0;bottom:0;background-color:#fff ;width:510px;padding:0 0 34px 0;border-right:1px solid #eaeaea;z-index:30;box-shadow: -20px 0px 21px rgba(0,0,0,0.1);}

#right_footer{position:absolute; left:0;width:100%;bottom:0;font-size:11px;text-align:center;}
#right_footer .bt_right_footer{height:23px;background:url('../images/side_area/quick_bg.png') repeat-x 0 100%;}
.rightFooterInbox{overflow:hidden;background: #fff;font-size:0;border-bottom: 1px solid #eaeaea;}
#right_footer ul{float:left;width:1000px;padding:13px 0px;background-color:#fff;}
#right_footer li{position:relative; float:left;border-left:1px solid #dadada;}
#right_footer li a{font-size:11px;display:inline-block;color:#979797;padding:0 15px;}
#right_footer li:first-child{border:none;}
.bt_rightFootMore{display:inline-block;width:15px;height:38px;background:url(../images/side_area/bt_footOption.gif) no-repeat center 50%;border-left:1px solid #e5e5e5;}
.rightFooterInboxWrap{display:none}

#right_footer .rightFooterInbox02 ul{width:auto;}
.bt_rightFootMoreBox{float:right}
.rightFooterInbox02 .bt_rightFootMoreBox{display:none;}
.rightFootUlbox{width:186px;overflow:hidden;display:inline-block;}

.ft_copyright {transition:0.2s;font-size:0;position:absolute;right:0;width: 100%;height:80px;bottom: 81px;background: url(../images/contents/img_cbhodu.png) no-repeat 3px top;opacity:0.5}
.ft_copyright:hover {background: url(../images/contents/img_cbhodu_ko.png) no-repeat 3px top; opacity:0.8; }

#container #right_area.wdth_wide{width:284px;}

#container #right_area .right_inbox{overflow:hidden;height: 200px;padding:0;position:relative;border-bottom: 1px solid #ececec;transition:0.1s; }
#container #right_area .right_inbox.on { height: 51px; transition:0.1s; overflow: hidden; }


.info_option{background-color:#fff;text-align:center;}
.info_option .nameBox{overflow:hidden;height: 51px; line-height: 51px;}
.info_option .nameBox.on {padding:0}
.bt_infoContr{float:right; display:inline-block;margin-right:0px;width:51px;height:51px;background: url('../images/side_area/bt_close.png') no-repeat center center; }
.info_option .nameBox .bt_infoContr:hover,.info_option .nameBox.on .bt_infoContr:hover, #container #left_area .bt_calContr:hover {transition: 0.2s; background-color: #f1f3f5;}
.info_option .nameBox.on .bt_infoContr{background: url('../images/side_area/bt_open.png') no-repeat center center;}
.info_option .name{float:left;text-align:left;color:#232848;font-size:12px;font-weight: bold; margin-top: 0; padding-left: 20px; line-height: 51px}
.info_option .info_inbox .photo{position:relative;width:72px;height:72px; /*padding:21px 17px 1px;*/border-radius:50%;padding:0; margin:0 auto;overflow:hidden;color:transparent;border: 1px solid #f3f3f3;box-sizing: border-box;}
.info_option .info_inbox .photo p{display:none;position:absolute;width:72px;height:72px;left:50%;margin-left:-36px;top:0;cursor:pointer;}
.info_option .info_inbox .photo .bg_opBlack{display:inline-block;width:72px;height:72px;background-color:#000;opacity:0.3;}
.info_option .info_inbox .photo .txt_changeInfo{position:absolute;width:72px;background:trasparent;height:50px;font-size:0;top:0;left:0;padding-top:22px;color:#fff;line-height:18px;background:url(../images/contents/ic_edit_pen.png) no-repeat center center;}
.info_option .info_inbox .photo:hover p{display:block;}
.info_option .info_inbox .photo img{width:100%;height:100%;}
.info_option .info_inbox .cut_round{position:absolute; left:0;bottom:0;width:106px;height:94px;background:url('../images/side_area/bg_photo.png') no-repeat 0 0;color:transparent}
.info_option .info_inbox .cut_round:hover{height:49px;padding-top:41px;padding-bottom:5px;background: url('../images/side_area/bg_photo_on.png') no-repeat 0 0;cursor:pointer;color:#fff;line-height:18px;}


#right_area .info_inbox02 > .over_hidden {width: 165px;}
#right_area .info_option .info_inbox02 {height:35px;padding: 20px 20px 22px;}
.info_option .info_inbox02{padding:10px 0 14px;font-size:12px;}
.info_option .info_inbox02{display:inline-block}
.info_option .info_inbox02 a{display:inline-block;height: 35px;width: 80px;/* margin-top: 5px; */font-weight: bold;line-height: 35px;border-radius:  5px;}
.info_option .info_inbox02 a span{display:inline-block;width: 100%;color: #fff;padding-top: 0;}
.info_option .info_inbox02 a span:hover{color: #ffffff;}
.r_other{float:left;width:84px;height:43px;background: #f1f3f5;}
.r_other span { color:#232848 !important; }
.r_other:hover span { color:#fff !important; }
.r_other:hover{background: #477fff;}
.hline{float:left;height:43px;display: none;width:1px;}
.hline span{display:inline-block; width:1px;height:27px;background-color:#e5e5e5;font-size:0;}
.r_logout{float: right;width:84px;height:43px;background: #ff8080;}
.r_logout:hover{background: #ff5d5d;color: #fff;}


/* userArea */


/* right_area_conroller */
#container #right_area_controller.on { right:-61px; transition:0.1s }
#container #right_area_controller {z-index: 1000000000000;transition:0.1s;right: 0px;top: 62px;position: absolute;width:60px;height:100%;background:#f1f3f5;box-sizing:border-box;border-left:1px solid #e1e4e6;}
#container #right_area_controller > ul { padding-top:15px;}
ul#changeUserListUl {padding-top: 0 !important;}
#container #right_area_controller li.schLi { position:relative; }
#container #right_area_controller > ul> li  { height: 60px; padding:10px; box-sizing:border-box; margin: 5px 0; }
#container #right_area_controller > ul> li > a {transition:0.1s;display: inline-block;border-radius:50%;width:100%;height:100%;position:relative;}
#container #right_area_controller > ul> li a:hover { box-shadow:0 2px 5px rgba(0,0,0,0.2); transition:0.1s }
#container #right_area_controller > ul> li a:hover span { display:block; }
#container #right_area_controller > ul> li > a > span {display:none;z-index: 1000;position: absolute;top: 6px;right: 50px;background: rgba(46, 56, 86,0.9);min-width:50px;color: #fff;padding: 10px 10px;text-align: center;border-radius: 5px;}
#container #right_area_controller > ul> li.schLi.on > a  span {display:none;}
#container #right_area_controller > ul> li.userLi { padding-top:0; height:50px; }
#container #right_area_controller > ul> li.userLi a { background-color:#fff; background-image: url(../images/contents/im_photoB.gif);border:1px solid #e7e9ea; background-size:cover !important; background-position: center center !important;}
#container #right_area_controller > ul> li.userLi.on a { background-size:unset !important; }

/* 프린트, 필터 */
#container #right_area_controller li.printLi a {background: url(../images/contents/bt_print.png) no-repeat center center; background-color: #ffffff;}
#container #right_area_controller li.printLi a:hover { background:#477fff url(../images/contents/bt_print_on.png) no-repeat center center; }
#container #right_area_controller li.filterLi a {background: url(../images/contents/ic_top_filter_new.png) no-repeat center center; background-color: #ffffff;}
#container #right_area_controller li.filterLi a:hover { background:#477fff url(../images/contents/ic_top_filter_new_on.png) no-repeat center center;  }

#container #right_area_controller li.settingLi a {background: url(../images/contents/ic_setting_on.png) no-repeat center center;background-size: 21px;}
#container #right_area_controller li.noticeLi a {background: url(../images/contents/ic_alarm_on.png) no-repeat 7px 7px;}
#container #right_area_controller li.schLi a { background: url(../images/contents/ic_search_on.png) no-repeat center center; }
#container #right_area_controller li.schLi.on > a { background: #fff url(../images/contents/ic_close.gif) no-repeat center center !important }
#container #right_area_controller li.schLi > a { background-color:#fff; }
#container #right_area_controller li.noticeLi a {background-color: #ffffff;}
#container #right_area_controller li.settingLi a {background-color: #ffffff;}
#container #right_area_controller li.settingLi a:hover {background: #477fff url(../images/contents/ic_setting_fff.png) no-repeat center center;background-size: 21px;}
#container #right_area_controller li.noticeLi a:hover {background: #477fff url(../images/contents/ic_alarm_fff.png) no-repeat  7px 7px;}
#container #right_area_controller li.toDoLi a {background: #ffffff url(../images/contents/ic_todo_on.png) no-repeat center center;}
#container #right_area_controller li.toDoLi a:hover {background: #477fff url(../images/contents/ic_todo_fff.png) no-repeat center center;}
#container #right_area_controller li.etcLi a {background: #ffffff url(../images/contents/ic_right_more_on.png) no-repeat center center;}
#container #right_area_controller li.etcLi a:hover {background: #477fff url(../images/contents/ic_right_more_fff.png) no-repeat center center;}
#container #right_area_controller li.on > a  { background: #477fff url(../images/contents/ic_close_fff.gif) no-repeat center center !important;}


#changeUserListUl {padding: 5px 0 10px;}
.changeUser_selectbox{display:none;position:absolute;top:12px;left:280px;width:186px;height:200px;z-index:100}
.changeUser_selectbox .changeUser{display:none;top:33px;left:0px;width:186px;height:105px;border:1px solid #ececec;z-index:30;box-shadow:0 8px 13px rgba(0, 0, 0, 0.09);-webkit-box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09);-moz-box-shadow:0 8px 13px rgba(0, 0, 0, 0.09);}
.changeUser_selectbox .changeUser:hover{height:204px;}
.changeUser_selectbox .changeUser .groupColor{display:none;}
.changeUser_selectbox .changeUser:hover .groupColor{display:inline-block;position:absolute; left:0;top:0}
.right_inbox .changeUser{ display:block !important; top:400px;left:0px;width:210px;height:187px;transition:0.2s;}
.changeUser.dpblock { top:0; transition:0.2s; }
.changeUser{display:none;position:absolute;background-color:#fff;color:#737373; }
.changeUser > p{padding:16px 4px 15px 10px;border-bottom:1px solid #ececec;overflow:hidden;font-weight:bold;}
.changeUser p .otherTitle{display:inline-block;line-height:48px; font-size: 14px;color: #fff; }
.changeUser p .close{display:inline-block; width:20px;height:20px;background:url(../images/side_area/ic_close.gif) no-repeat 7px 6px;}
.changeUser li {position:relative;text-align: center;}
.userArea #changeUserListUl li:first-child a {border-top: 0 none;}
.changeUser li span{float:left;}
.changeUser li .groupColor{width:3px;height: 100%;position:  absolute;display:inline-block;left:  0;}
.changeUser li .groupName{text-align: left;width:100%;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;box-sizing:border-box;}

.alert_noMoreBox{display:table;width:100%}
.alert_noMore{display:table-cell;text-align:center;line-height:18px;vertical-align:middle;color:#989898}

#changeUserScroll { height: 149px !important; }
#changeUserListUl a {width: 100%;display: inline-block;box-sizing: border-box;border:0 none;border-top: 1px solid #f3f3f3;height: 45px;border-radius: 0;position: relative;line-height: 45px;overflow:hidden;transition:0.1s;margin:0;}
#changeUserListUl a:hover {transition:0.1s;background-color:#f1f3f5;font-weight: bold;}


/* #right_area etcDiv*/
#etcDiv li { position:relative; }
#etcDiv li a {width: 100%;box-sizing: border-box;padding: 0 25px;border-bottom: 1px solid #f1f3f5;display: inline-block;line-height: 55px;transition: 0.2s;height: 55px;font-size: 14px;font-weight: bold;}
#etcDiv li a:hover {background: #f1f3f5;}
#etcDiv li a .num { display:none; }
#etcDiv li a.new .num{ width:25px; height:25px;font-size:12px; background:#FF6363; display:block; position:absolute; right:20px; top:14px; line-height:25px;text-align:center; border-radius:50%; color:#fff;}


/* userArea changerUser */
#wrap .right_inbox.on { display:block; }
#wrap .right_inbox {display: none;}
.userArea .right_inbox {position: absolute;top: 10px;border-radius: 5px;z-index: 10000000;right: 70px;width: 230px;background: #fff;height: 252px;overflow: hidden;box-shadow: 0 9px 25px rgba(44, 51, 66, 0.25);}
.userArea a#changeUserBtn {background: #f1f3f5;font-size: 12px;display: inline-block;float: none;color: #484871;}
.userArea a#changeUserBtn:hover { background:#477fff; color:#fff}
.userArea a#btnLogout {background: #ff6666;font-size: 12px;float: none;display: inline-block;color: #484871;}
.userArea a.bt_infoContr {display: none;}

.userArea .inboxWrap {padding-bottom: 0;}
.userArea .info_option .info_inbox .photo {width: 100px;height: 100px;margin: 32px 67px 40px;}
.userArea .info_option .info_inbox .photo p {width: 100px;height: 100px;margin-left: -50px;}
.userArea .info_option .info_inbox .photo .bg_opBlack {width: 100%;height: 100%;opacity: 0.2;transition: 0.1s;}
.userArea .info_option .info_inbox .photo .txt_changeInfo {width: 100%;padding-top: 48px;font-weight: bold;}

.userArea .changeUser > p {padding: 0 15px; height:48px;background: #477fff;}
.userArea a#changeUserClose {width:25px;height:25px;margin-top: 10px;margin-right:0;border: 1px solid #305fca;background:#305fca url(../images/contents/ic_close_fff.gif) no-repeat center center;}
.userArea #changeUserScroll {height: 203px !important;}
.userArea #changeUserListUl {padding: 5px 10px 50px;overflow: hidden;}
.userArea .right_inbox .changeUser {height: 252px;width: 230px;}
.userArea #changeUserListUl {overflow: hidden;padding: 0;}
.userArea .changeUser li .groupName {font-size: 12px;padding: 0 15px 0 33px;}
.userArea .changeUser li .groupColor {width: 8px;height: 8px;border-radius: 50%;    top: 50%;margin-top: -4px;left: 13px;}
.userArea p.nameBox {position: absolute;top: 135px;width: 100%;box-sizing: border-box;}
.userArea .info_option .name {padding: 0 30px;font-size: 14px;text-align: center;width: 100%;text-overflow: ellipsis;box-sizing: border-box;overflow: hidden;white-space: nowrap;}

#right_area .right_inbox02.mCustomScrollbar {
    height: 100% !important;
}


#footer{position:absolute; bottom:0px;left:0;width:100%;height:29px;background-color:#fff;z-index:100;text-align:center;box-shadow: -5px -2px 7px rgba(0,0,0,0.04);-moz-box-shadow: -5px -2px 7px rgba(0,0,0,0.04);-webkit-box-shadow: -5px -2px 7px rgba(0,0,0,0.04);-ms-box-shadow: -5px -2px 7px rgba(0,0,0,0.04);-o-box-shadow: -5px -2px 7px rgba(0,0,0,0.04);}
#footer .footer_inbox{display:inline-block;padding-top:8px;}
#footer .footer_inbox ul{width: 260px;}
#footer .footer_inbox li{position:relative; display:inline-block;padding:0 15px;border-left:1px solid #dadada; width: 37%; }
#footer .footer_inbox li:first-child{border:none;}
#footer .footer_inbox li .foot_cont{color:#989898;font-size:11px}
#footer .footer_inbox li .foot_cont:hover{color:#7c7c7c}
.optionList{display:none;position:absolute; width:280px;height:42px;left:0;top:-40px;z-index:101;background:url(../images/side_area/bg_option.gif) no-repeat 12% 100%;border-top:1px solid #e3e3e3}
.optionListInbox{background:#fff;font-size:0}
.optionListInbox ul{float:left;width:1000px}
.optionListInbox li{padding:13px 0;float:left;font-size:12px;}
.optionListInbox li:first-child a{border-left:0;}
.optionListInbox li a{border-left:1px solid #ccc;padding:0 14px}
.bt_footOption{display:inline-block;width:15px;height:38px;background:url(../images/side_area/bt_footOption.gif) no-repeat center 50%;border-right:1px solid #e5e5e5;}
.footUlbox{width: 264px; overflow: hidden; float: left;}


.optionListInbox02{display:inline-block;}
.optionListInbox02 .bt_footOptionBox{display:none}
.optionListInbox02  ul{width:auto}

#wrap #right_area{transition:0.2s;}
#wrap #right_area.on {opacity:1;transition:0.2s;right:59px;z-index: 20000000000000000000;}

#right_area .name { width: 150px; box-sizing:border-box; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; }

.title_box{padding: 0 25px 0 20px !important;line-height: 61px;overflow:hidden;/* border-bottom:1px solid #ececec; */}
.bt_back{display: inline-block;float: left;margin-right: 2px;width: 40px;height: 40px;transition:0.1s;background: #ffffff url(../images/contents/month_bt_prev.svg) no-repeat 3px 6px;border-radius: 50%;margin-top: 10px;}
.bt_back:hover {background: #f1f3f5 url(../images/contents/month_bt_prev_on.svg) no-repeat  3px 6px;transition:0.1s;}
.bt_back.h25{margin-top:12px;width:20px;height:25px;background-position:2px 6px;}
.title_box02{position:relative; padding:6px 25px;overflow:hidden;background-color:#ededed;min-width:708px;}
.titlebox02_underline{position:absolute;left:0;bottom:0;width:100%;height:1px;background-color:#ececec}
.title_doc{display:inline-block;float:left;font-size: 20px;color:#232848;font-weight:bold;cursor:default;margin-left:15px;margin-top:-1px;}
.btn_group{display:inline-block;position:absolute;z-index:100000;right:40px;bottom: 20px !important;}
.btn_group input { position: static; margin-left:20px; float:right; }
.btn_group .bt_copy { background: #fff; color: #878787; font-size:14px; font-weight:bold; padding:0 !important; width:60px; height: 60px; box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15) }
.btn_group .bt_copy:hover {box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25); background:#f3f3f3; color: #888888; }
.bt_search_place{display:inline-block;float:left;width:35px;height:35px;margin-top:-7px; border-radius:50%;background:#fff url('../images/contents/ic_search.png') no-repeat 8px 8px;}
.bt_search_place:hover,  tr:hover .bt_search_place, tr.on .bt_search_place{background:#477fff url(../images/contents/ic_search_fff.png) no-repeat 8px 8px}

.round_radiobox{/* padding-left:10px; */}
.round_radiobox.h29{padding-top:6px;padding-bottom:6px;}
.round_radiobox.h29.lastDep{padding-bottom:3px;}

.inputgroup {overflow:hidden;}
.inputgroup_txt{display: inline-block;line-height: 1.3;margin-left:19px;color:#737373;}
.inputgroup_txt01{display: inline-block;float:left;margin-top: 9px;margin-left: 3px;color:#797979;}
.inputgroup_txt02{display: inline-block;line-height: 21px;margin-left:19px;color:#989898;}
.inputgroup_txt03{line-height:1.3;color:#989898;}
.inputgroup_txt04{line-height: 1.3;color:#737373;}
.address{}
.readOnly .address { padding-left: 0;padding:2px 0 2px 0;margin-top:2px;}

.color_group{margin-left:3px;font-size:0;}
.color_select {text-align:center;width:30px;height:17px;padding:6px 0;display:inline-block;}
.color_select.disable{opacity:0.6}
.color_select.disable:hover{opacity:1}
.color_select.on {height:18px;padding:5px 0 6px;}
.color_select a{display:inline-block; width:17px;height:17px;}
.color_select.on a{display:inline-block; width:18px;height:18px;}

.color_select  {position: relative;}

.color_select .gn {background:#82d2a2 url('../images/contents/bg_color_off.png') no-repeat 0 0;}
.color_select.on .gn {background:#82d2a2 url('../images/contents/bg_color_on.png') no-repeat 0 0;}

.color_select .mt {background:#83cbd4 url('../images/contents/bg_color_off.png') no-repeat 0 0;}
.color_select.on .mt {background:#83cbd4 url('../images/contents/bg_color_on.png') no-repeat 0 0;}

.color_select .sb {background:#83bdf4 url('../images/contents/bg_color_off.png') no-repeat 0 0;}
.color_select.on .sb {background:#83bdf4 url('../images/contents/bg_color_on.png') no-repeat 0 0;}

.color_select .bn {background:#a59b95 url('../images/contents/bg_color_off.png') no-repeat 0 0;}
.color_select.on .bn {background:#a59b95 url('../images/contents/bg_color_on.png') no-repeat 0 0;}

.color_select .rd {background:#d96e6a url('../images/contents/bg_color_off.png') no-repeat 0 0;}
.color_select.on .rd {background:#d96e6a url('../images/contents/bg_color_on.png') no-repeat 0 0;}

.color_select .pk {background:#e786a1 url('../images/contents/bg_color_off.png') no-repeat 0 0;}
.color_select.on .pk {background:#e786a1 url('../images/contents/bg_color_on.png') no-repeat 0 0;}

.color_select .bp {background:#ee9d95 url('../images/contents/bg_color_off.png') no-repeat 0 0;}
.color_select.on .bp {background:#ee9d95 url('../images/contents/bg_color_on.png') no-repeat 0 0;}

.color_select .yl {background:#f5c44e url('../images/contents/bg_color_off.png') no-repeat 0 0;}
.color_select.on .yl {background:#f5c44e url('../images/contents/bg_color_on.png') no-repeat 0 0;}

.color_select .vt {background:#ab8dd7 url('../images/contents/bg_color_off.png') no-repeat 0 0;}
.color_select.on .vt {background:#ab8dd7 url('../images/contents/bg_color_on.png') no-repeat 0 0;}



.voteTD{ }


.bt_vote{display:inline-block;    display: inline-block;
    background: #f1f3f5 url(../images/contents/ic_vote.gif) no-repeat 12px center;
    line-height: 40px;
    padding: 0 15px 0 40px;
    border-radius: 5px;
    font-weight: bold;
    color: #737373;}
.bt_vote:hover{background:#e5e8ea url('../images/contents/ic_vote_on.gif') no-repeat 12px center;}


div#attendView {position: absolute;right: 0;top: 60px;}
.bt_attendance{display:inline-block;margin-left: 0;background: 0 none;padding: 0 10px;color: #283240;font-weight: bold;}
.bt_attendance:hover{color:#477fff;}


.bt_noti{display:inline-block;margin-left:16px;background:url('../images/contents/ic_noti.gif') no-repeat 0 0;padding:2px 0 2px 24px;color:#737373}
.bt_noti:hover{background:url('../images/contents/ic_noti_on.gif') no-repeat 0 0;}

.map{display:none;margin-top:5px;width:643px;height:304px;overflow:hidden;}

.tabbox{width:396px;/*height:552px;*/height:438px;overflow:hidden;}

.tabbox.tab2menu .tabStyle01 li{width:50%;} 
.tabbox.tab2menu #tabThanks{display:none;}
.tabbox.tab2menu #tab3{display:none;}

.tabbt01 a{background:url('../images/contents/im_tab01.png') no-repeat 50% 50%;}
.tabbt01 a:hover{background:url('../images/contents/im_tab01_on.png') no-repeat 50% 50%;}
.tabbt02 a{background:url('../images/contents/im_tab02.png') no-repeat 50% 50%;}
.tabbt02 a:hover{background:url('../images/contents/im_tab02_on.png') no-repeat 50% 50%;}
.tabbt03 a{background:url('../images/contents/im_tab03.png') no-repeat 50% 50%;}
.tabbt03 a:hover{background:url('../images/contents/im_tab03_on.png') no-repeat 50% 50%;}

.tab_content{padding-right:20px;}

.pictureBox{float:left;width:328px;height:438px;margin-right:10px;border:1px solid #ececec}
.pictureBox img{width:100%}


.detailInfoRow{border-bottom:1px solid #e6e5e5;padding:12px 9px;line-height:22px;color:#989898;}
.detailInfoRow02 {padding:12px 0;}
.detailInfoRow02 .smallMap{margin-top:10px;width:396px;height:300px;overflow:hidden;}
.detailInfoRow02 .imgArea{margin-top:10px;}
.detailInfoRow02 .imgArea img{width:100%;height:auto;max-width:396px;}


.atten_row{padding:15px 0 14px;padding-left:10px;border-bottom:1px solid #e6e5e5;overflow:hidden;}
.replyBox .replyRow{padding:11px 9px;line-height:25px;border-bottom:1px solid #e6e5e5;}
.replyBox .replyRow .replyName{display:inline-block; color:#5f5f5f;float:left;}
.replyCont{position:relative; overflow:hidden;padding-right:26px;}
.replyContTxt{padding:3px 0 2px;word-break: break-all;word-wrap: break-word;line-height:20px;}
.bt_replyClose{position:absolute;width:9px;height:9px;background:url('../images/contents/ic_close.gif') no-repeat 0 0;right:0;top:8px;}

.replyRead .replyCont{padding-right:0;}
.replyRead .bt_replyClose{display:none;}

.input_reply{overflow:hidden;margin-top:10px;}
.input_reply > textarea{width:280px;float:left}
.input_reply > .sendReply{float:right;padding:20px 17px;border-radius:0;}


.imgbox_thanks{margin-top:10px;overflow:hidden;width:396px;height:492px;}
.imgbox_thanks img{width:100%}



.item .successMode .success {margin-bottom:10px;background: #477fff url(../images/contents/bt_save_fff.png) no-repeat -1px 1px !important;display: inline-block;width: 40px;height: 40px;padding: 0;position: absolute;border-radius: 50%;background-size: 40px !important;margin-top: -35px;top: 50%;left: 50%;margin-left: -25px;}
.item .successMode p {display:block;width: 100%;position: absolute;top: 50%;margin-top: 25px;}



/* groupping1 */
.ic_noti { width: 16px; height: 16px; background: url('../images/contents/ic_noti.png') no-repeat;  }
.ic_reply { width: 16px; height: 16px; background: url('../images/contents/ic_reply.png') no-repeat;  }
.ic_thankYouCard { width: 16px; height: 16px; background: url('../images/contents/ic_thankYouCard.png') no-repeat;  }
.ic_myPlan { width: 16px; height: 16px; background: url('../images/contents/ic_myPlan.png') no-repeat;  }
.ic_repeat { width: 16px; height: 16px; background: url('../images/contents/ic_repeat.png') no-repeat;  }

/* groupping2 */
.ic_placePin { background: url('../images/contents/ic_placePin.png') no-repeat left center; color: #989898; padding-left: 15px;  }
.ic_group { background: url('../images/contents/ic_group.png') no-repeat left center; color: #737373; padding-left: 25px; }

/* groupping3 */
.ic_oldBday { background: url('../images/contents/ic_oldBday.png') top center no-repeat; }
.ic_cal { background: url('../images/contents/ic_cal.png') top center no-repeat; }
.ic_event { background: url('../images/contents/ic_event.png') top center no-repeat; }
.ic_funeral { background: url('../images/contents/ic_funeral.png') top center no-repeat; }
.ic_firstBday { background: url('../images/contents/ic_firstBday.png') top center no-repeat; }
.ic_bday { background: url('../images/contents/ic_bday.png') top center no-repeat; }
.ic_reunion { background: url('../images/contents/ic_reunion.png') top center no-repeat; }
.ic_seminar { background: url('../images/contents/ic_seminar.png') top center no-repeat; }
.ic_wed { background: url('../images/contents/ic_wed.png') top center no-repeat; }
.ic_opening { background: url('../images/contents/ic_opening.png') top center no-repeat; }
.ic_club { background: url('../images/contents/ic_club.png') top center no-repeat; }
.ic_community { background: url('../images/contents/ic_community.png') top center no-repeat; }

.searchResultSection { color: #737373;}
.searchResultSection li { list-style: none  }
.searchResultSection ul  li  h3{ width: 100%; height: 40px; text-align: center; background-color: #f1f3f5; font-size: 14px; line-height: 40px; }
.searchResultSection ul > li > ul > li { overflow: hidden; padding: 20px 10px 10px;  border-bottom: 1px solid #e6e5e5 }
.searchResultSection .date { font-size: 14px; font-weight: bold; margin-right: 10px; }
.searchResultSection .day { font-size: 14px; margin-right: 10px;}
.searchResultSection .when { font-size: 14px; font-weight: bold; color: #f16727;}
.searchResultSection .time { color: #989898; font-size: 13px; }
.searchResultSection .groupping1 { width: 125px; display: inline-block; float: left;}
.searchResultSection .groupping1 .groupping_b p { font-size: 0; margin-right: 5px;}
.searchResultSection .groupping1 p { float: left; margin-bottom: 10px; }
.searchResultSection .groupping2 { display: inline-block; }
.searchResultSection .groupping2 .groupping_a { display: block }
.searchResultSection .groupping2 .groupping_b { display: block; width: 100%  }
.searchResultSection .time { width: 100% }
.searchResultSection h4 { margin-bottom: 10px; font-size: 14px }
.searchResultSection .groupping2 .groupping_a p { display: inline-block; margin-bottom: 10px; margin-right: 20px }
.searchResultSection .groupping2 .groupping_b p { display: inline-block; margin-right: 20px }
.searchResultSection .groupping2 .groupping_b .memo { color: #989898; }
.searchResultSection .vote_ing { margin-left: -10px; color: #989898 }
.groupping3 {  float: right; display: table; height: 62px; }
.groupping3 .tableCell { display: table-cell; vertical-align: middle;  }  
.groupping3 p.invitation { display: inline-block; width: 60px; padding-top: 35px; font-size: 12px; text-align: center;  }

.titleBox_search{overflow:hidden;display:inline-block;}
.labelNm{float:left;line-height:29px;font-size:13px;padding-right:10px;background:url('../images/contents/ic_bar.gif') no-repeat 100% 9px; font-weight:100;}
.titleBox_search .selectboxWrap label{font-weight:100;}


.titlebox_inputSearch{padding:0 10px ;height:27px;overflow:hidden}
.bt_search02{display:inline-block;width:18px;height:18px;margin-top:4px;background:url('../images/contents/ic_search02.png') no-repeat 3px 2px;}
.bt_search02:hover{background:url('../images/contents/ic_search02_on.png') no-repeat  3px 2px}

.ui-datepicker{/*border: 1px solid #eaeaea;*/ box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09); -webkit-box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09);-moz-box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09);}
.disableDaptepicker div#datepicker:after {content: '';display: block;position: absolute;z-index: 100000;left: 0;top: -1px;width: 100%;height: 100%; background:#fff; opacity:0.5 }
.hasDatepicker .ui-datepicker{border:none;box-shadow:none;}

.bt_mms { opacity: 1; z-index: 1000; position: absolute; bottom: 40px; right: 40px; transition: 0.2s; font-size: 0; border-radius: 50%; display: block; width: 60px; height: 60px; background: #00e29a url('../images/contents/bt_mms.png') no-repeat center center; box-shadow: 0 5px 15px rgba(0, 226, 154, 0.4);}
.bt_mms:hover { background-color: #00d08e; transition: 0.1s; box-shadow: 0 10px 25px rgba(0, 226, 154, 0.5);}

.bt_mms.on { bottom: 40px; right: 130px !important; transition: 0.2s; font-size: 0; border-radius: 50%; display: block; width: 60px; height: 60px; background: #00e29a url('../images/contents/bt_mms.png') no-repeat center center; box-shadow: 0 5px 15px rgba(0, 226, 154, 0.4);}
.bt_mms.on:hover { background-color: #02da95; box-shadow: 0 10px 25px rgba(0, 226, 154, 0.5);}


.bt_mms.bt_del { padding: 0; background: #ff5a5a url('../images/contents/btn_del_fff.png') no-repeat center center !important; background-size: 51px !important; box-shadow:0 5px 15px rgba(255, 29, 36,0.2) !important; }
.bt_mms.bt_del:hover { padding: 0; background: #ff3737 url('../images/contents/btn_del_fff.png') no-repeat center center !important; background-size:60px !important;box-shadow:0 10px 25px rgba(255, 29, 36, 0.5) !important;  }


.bt_mms.bt_edit { font-size: 14px; color: #fff !important; font-weight: bold; padding: 0; background: #ffc008 url(../images/contents/bt_save_fff.png) no-repeat -1px -60px !important; box-shadow: 0 5px 15px rgba(255, 198, 5, 0.4); }
.bt_mms.bt_edit:hover { color: #fff; background: #ffb508 url(../images/contents/bt_save_fff.png) no-repeat -1px 1px !important;font-size: 0; box-shadow: 0 10px 25px rgba(255, 198, 5, 0.5);}

.bt_mms.bt_edit.toSave {
    background: #00e29a url(../images/contents/bt_save_fff.png) no-repeat -1px -60px !important;
    transition: 0.1s;
    box-shadow: 0 5px 15px rgba(0, 226, 154, 0.4);
}

.bt_mms.bt_edit.toSave:hover {
    background: #00d08e url(../images/contents/bt_save_fff.png) no-repeat center center !important;
    transition: 0.1s;
    box-shadow: 0 10px 25px rgba(0, 226, 154, 0.5);
}


.mainBtns .bt_mms.bt_excelUpload {background:#00ab5d url('../images/contents/ic_excelUpload.png') no-repeat center center !important; }
.mainBtns .bt_mms.bt_excelUpload:hover {background-color:#00904e !important; }
.mainBtns .bt_mms.bt_excelUpload.up {bottom: 40px; right: 220px !important; }



input#showPhone {position: fixed;bottom: 50px;left: 50%;font-size: 16px !important;color: #fff !important;padding: 0 50px !important;height: 45px;margin-left: -66px;box-shadow: 0 5px 15px rgba(31, 167, 234, 0.3) !important;background: #35bcff !important;line-height: 45px;border: none !important;border-radius: 35px !important;}
input#showPhone.on { display:none; }
.left_area_close .sendMmsPage .toTheLeft { left: 60px; }
.attndPage, #instMemListScroll, #instructorListScroll, .mainConsultingPage .section_scroll, .msgPage {padding-top: 65px !important;}
.title_box { padding-bottom: 16px; }
.right_area_close .title_box {padding-right: 85px !important;}
.left_area_close .title_box {padding-left: 80px !important;}
.consultingPage .tableDefault, .content,.instructorListBox, .allMemberList { padding-left: 0 !important; padding-right: 0 !important; }
.sendMmsPage .setting2Bg {margin-top:0!important;}
#docListScroll, .sendMmsPage.msgPage, #msgPageGubun  { margin-top: 0 !important; box-sizing:border-box; }
.consultingPage .treatDoctList.content {padding-left: 0 !important; padding-right: 0 !important;}


#wrap #section_ce.hideSetting .grp.settingBg.rightOn { right:0 !important; }
.menuBg {display: block;width: 0;height: 0;background: #ff4863;}
#wrap #section_ce.hideSetting .grp.settingBg .menuBg { width: 100%;height: 100%; cursor:pointer; }
#wrap #section_ce.hideSetting .title_box { border-bottom: 1px solid #f3f3f3;padding: 20px 20px 16px; }
#wrap #section_ce.hideSetting .section_scroll, #wrap #section_ce.hideSetting .allMemberList {padding-top:0 !important;margin-top:0 !important;}

#wrap #container #section_ce.hideSettingOn .grp.settingBg { box-shadow:none !important; padding-left: 311px !important;padding-right: 241px !important;height: 80px !important; top: 135px !important;left: 0px !important;width: 100% !important; min-width: 708px !important;border-radius:5px !important;}
#wrap #container #section_ce.hideSettingOn .grp.settingBg.rightOn { padding-right: 31px !important; }
#wrap #container #section_ce.hideSettingOn .grp.settingBg.leftOn { padding-left: 31px !important; }
#container #section_ce.hideSettingOn .menuBg {display: block;width: 0 !important;height: 0 !important;background: none !important;}
#wrap #container #section_ce.hideSettingOn .grp.settingBg .menuBg { width: 100%;height: 100%; cursor:pointer; }
#wrap #container #section_ce.hideSettingOn .title_box { border-bottom: none !important; padding: 30px 48px 16px 20px !important; }
#wrap #container #section_ce.hideSettingOn .section_scroll, #wrap #container #section_ce.hideSettingOn .allMemberList {padding-top: 81px !important; }




.scrollUp { opacity: 1; z-index: 100; position: absolute; bottom: 40px; right: 40px; transition: 0.2s; font-size: 0; border-radius: 50%; display: block; width: 60px; height: 60px; background: #ff4863 url('../images/contents/scrollUp.png') no-repeat center center; box-shadow: 0 5px 15px rgba(255, 72, 99, 0.5); }
.scrollUp.on { bottom: -100px !important; }
.scrollUp:hover {background-color: #e62743;transition: 0;box-shadow: 0 10px 25px rgba(255, 72, 99, 0.5);bottom: 45px;}

.noti_titlebox.mFile { cursor: default; }




.sendMmsPage .textBg .options  {position:absolute;bottom: -38px;left:0;width:100%;}
.sendMmsPage .textBg .options.on {  }
.sendMmsPage .textBg .options.on .grp {display:block;}
.sendMmsPage .textBg .options.on h4 { display:none; } 
.sendMmsPage .textBg .options h4, .sendMmsPage .textBg .options ul li, .sendMmsPage .textBg .options h5 {font-weight: bold;transition:0.1s;margin-left: 15px;display: inline-block;padding: 0 15px;line-height: 30px;background: #fff;border-radius:17.5px;}
.sendMmsPage .textBg .options ul li {margin-bottom: 5px;margin-left: 0;line-height: 30px;padding: 0 10px;color: #4e4e4e;}
ul#optionContent {background: #fff;margin-bottom: 9px;padding: 0 10px;box-sizing: border-box;}
.sendMmsPage .textBg .options .grp {display:none; }
.sendMmsPage .textBg .options h4:hover { background:#fafafa; transition:0.1s; cursor:pointer; box-shadow:none; }
.sendMmsPage .textBg .options h4:hover {color: #1fa7ea;background: none;}
.sendMmsPage .textBg .options h5:hover { color:#ff3737 !important;}
.sendMmsPage .textBg .options ul li:hover {color: #1fa7ea; }

#draggable .textBg .options  {position:absolute;bottom: -38px;left:0;width:100%;}
#draggable .textBg .options.on {  }
#draggable .textBg .options.on .grp {display:block;}
#draggable .textBg .options.on h4 { display:none; } 
#draggable .textBg .options h4, #draggable .textBg .options ul li, #draggable .textBg .options h5 {font-weight: bold;transition:0.1s;margin-left: 15px;display: inline-block;padding: 0 15px;line-height: 30px;background: #fff;border-radius:17.5px;}
#draggable .textBg .options ul li {margin-bottom: 5px;margin-left: 0;line-height: 30px;padding: 0 10px;color: #4e4e4e;}
ul#optionContent {background: #fff;margin-bottom: 9px;padding: 10px 10px;box-sizing: border-box;}
ul#optionContent:hover { cursor: default; }
#draggable .textBg .options h5 { cursor: pointer; }
ul#optionContent li:hover { cursor: pointer; }
#draggable .textBg .options .grp {display:none; }
#draggable .textBg .options h4:hover { background:#fafafa; transition:0.1s; cursor:pointer; box-shadow:none; }
#draggable .textBg .options h4:hover {color: #1fa7ea;background: none;}
#draggable .textBg .options h5:hover { color:#ff3737 !important;}
#draggable .textBg .options ul li:hover {color: #1fa7ea; }

/* chatbot */

#chatInapp{background: transparent; border-radius: 15px;overflow: hidden;height: 600px;}
.achat_Ifr{width:100%; top:0;bottom:0;height:600px;}
.chatbotInner{box-shadow:none;}
.bot_wrap{display:inline-block;cursor:pointer;position:absolute;left: 140px;top: 8px;width:210px;text-align: left;z-index:200;}
.bt_bot  {position:relative;text-align: left;display: inline-block;width: 40px;height: 40px;transition: all .1s ease-out;border-radius: 70px;background: linear-gradient(135deg, #bb99ff 0%, #99aaff 100%);}
.bt_bot:hover {width:100%;box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.3);}
.bt_bot .txt{    display: none; font-size: 13px; font-weight: 600; color: #fff;position: absolute;left: 55px; top: 12%;text-align: left; line-height: 16px;}
.bt_bot:hover .txt{display:inline-block;}
.bt_bot img{width: 40px;height: 40px;border-radius: 70px;/*box-shadow: 0px 5px 6px rgba(0,0,0,0.4);*/}
.bt_bot:hover img , .bt_bot img:hover{ box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.3);}



textarea#msgOpInContents {width: 100%;height: 100%;}
ul#optionContent li:before {display: none;}
#msgAddScroll textarea {width: 600px;height: 250px;max-width: 100%;min-width: 100%;max-height: 400px;transition: 0.1s;font-size: 14px;font-weight: bold;padding: 20px 60px 20px 20px;box-sizing: border-box;min-height: 200px;border: 1px solid #e7e9ea;}
#msgAddScroll textarea:hover {background: #f1f3f5;}
#msgAddScroll textarea:focus {background: #Fff;border-color: #477fff;}
.textareaBox .options {width: 100%;border: 1px solid #e7e9ea;box-sizing: border-box;background: #f1f3f5;}
.textareaBox {max-width: 600px;}
.msgOptionPage .noResult { display:none; background:#fff; position:static; margin-top:20px; }
.msgOptionPage .noResult p { background:#f3f3f3; color:#232848; font-size:14px; line-height: 100px; margin-top:0; padding:0; }
.msgOptionPage .vertiLine {position: fixed;width: 1px;background: #e7e9ea;height: 100%;left: 890px;top: 209px;z-index: 10000;display: none;}
.msgOptionPage.lClosed .vertiLine { left: 689px !important; }
.msgOptionPage .schedule_box { overflow: hidden; }
.msgOptionPage .title_box { border-bottom:1px solid #f3f3f3; }
.bt_del02{border-radius:50%;width:20px;height:20px;background:#eee url(../images/contents/bt_del.png) no-repeat 50% 50%;}
.bt_del02:hover{background:#ff6262 url(../images/contents/bt_del_on_02.png) no-repeat 50% 50%;}
.msgOptionPage .countLetters {opacity: 0.7;position:absolute;z-index:1000;right: 15px;bottom: 20px;font-size: 12px;font-weight: bold;box-shadow: 0 2px 3px rgba(0,0,0,0.1);background: rgba(255,255,255,0.9);opacity: 1;padding: 5px 5px;border-radius: 5px;font-weight: normal;}
.msgOptionPage .countLetters .counted {font-weight: bold;}
.msgOptionPage .countLetters:hover { cursor:default; }

.messageBoxWrap02 .sub_titleBox p { font-size:18px; }
.messageBoxWrap02 .sub_titleBox { padding-bottom:20px; padding-left:0; }
.messageBox_right .underLine .input_txt:hover {background-color: #f1f3f5;}
.messageBox_right .underLine .input_txt:hover {background-color: #f1f3f5;border-bottom: 2px solid #477fff;}
.messageBox_right .underLine .input_txt:focus {background-color: #fff;border-bottom: 2px solid #477fff;}
.defaultMsgBoxWrap{margin-top: 0;padding:0 18px;width: 50%;padding: 0;box-sizing: border-box;height: auto;/* float: right; */width: 100%;padding-left: 0;}
.messageBoxWrap02{display:none;transition:0.1s;width: 450px;min-height: 256px;box-sizing: border-box;overflow: hidden;float: left;position: fixed;left: 340px;margin-top: -315px;top: 50%;}
.messageBox_left{display: none;}
.sbtitle{overflow:hidden;line-height:18px;display: none;}
.sbtitle .txt01{font-weight:bold;color:#232848;float:left;margin: 20px 0 15px 30px;font-size:16px; display:none;}
.sbtitle .txt02{color: #232848; float: right; margin-top: 28px; font-weight: bold;}
span#msgTotalCnt {margin-right: 0;margin-top: 26px;font-weight: bold;font-size: 16px;float: left;margin-left: 30px;}
input#addMsg {line-height: 40px; border: 1px solid #e8e8e8; background: #Fff;padding: 0 15px;height: 40px;border-radius: 5px;font-weight: bold;box-sizing: border-box;font-size: 14px;float: right;margin-right: 30px;margin-top: 9px;}
.messageBox_left .des {margin-left: 30px;font-size: 12px;line-height: 23px;color: #878787;}
.defaultMsgBoxWrap .des { display:none; }

.messageBox_right{float:left;width: 100%;box-sizing:border-box;border-radius:0 4px 4px 0;padding: 0;}
.messageBox_right .underLine{/* border-left: 1px solid #e8e8e8; *//* border-right: 1px solid #e8e8e8; */}
.messageBox_right .underLine .input_txt{margin-bottom: 0;box-sizing:border-box;height: 70px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;font-size: 18px;padding: 20px 20px 20px;width: 100%;font-weight: bold;border: 1px solid #e9e9e9;border-bottom: 2px solid #e7e9ea;background: #ffffff;line-height: 70px;border-radius: 15px 15px 0 0;color: #5f5f5f !important;    background: url(../images/contents/input_pen.png) no-repeat 95% 32px;}
.messageBox_right .textareaBox{font-size:0;width: 100%;position: relative;height: 100%;/* overflow: hidden; */float: left;}
.messageBox_right .textareaBox .input_textarea{box-sizing:border-box;max-width:100%;width: 100%;line-height: 22px;min-height: 365px;max-height: 365px;border:1px solid #e9e9e9;background-color: #f1f3f5;;padding: 20px 20px;font-size: 14px;}
.messageBox_right .btnBox{border-left:1px solid #e9e9e9;border-right:1px solid #e9e9e9;border-bottom:1px solid #e9e9e9;overflow:hidden;padding: 20px 20px;border-radius: 0 0 15px 15px;}
.messageBox_right .btnBox .hbar{margin-top:5px;}
.messageBox_right .btnBox .input_btn{line-height: 35px;height: 35px;font-size: 12px;padding: 0 25px;margin-left: 15px;border-radius: 5px;background: white;color: #585858;border: 1px solid #eaeaea;font-weight: bold;}
.messageBox_right .options #optionContent {padding-top: 5px;padding-bottom: 8px;padding-right: 85px;}

.defaultMsgBoxWrap h2 {font-size: 16px;margin: 20px 0 15px;margin-left: 25px;}
.defaultMsgBox > p { margin-left:25px;font-size: 12px;line-height: 23px;color: #878787; }
.defaultMsgBoxWrap{margin-top: 0;}
.defaultMsgBox ul, .messageBox_left ul{margin-left: 0;text-align:left;margin-top: -10px;}
.msgHListUl li.add input {transition: 0.2s;color: #a7a7a7;border-radius: 5px;font-size: 21px;font-weight: bold;text-align: center;height: 100%;line-height: 300px;width: 100%;background: #f1f3f5;border: 0 none;padding: 0;float: left;margin: 0;}
.messageBox_left li.add {background: #fff;box-sizing: border-box;border: 2px dashed #e7e9ea;border-radius: 5px;}
.messageBox_left li.add:hover {box-shadow: none;}
.messageBox_left li.add:hover input { background:#fff; color:#477fff }
.messageBox_left li.add input {transition: 0.2s;color: #a7a7a7;border-radius: 5px;font-size: 21px;font-weight: bold;text-align: center;height: 100%;line-height: 300px;width: 100%;background: #f1f3f5;border: 0 none;padding: 0;float: left;margin: 0;}
.defaultMsgBox li, .messageBox_left li{margin: 35px 10px 0 40px;width: 270px;display: inline-block;transition:0.2s;position: relative;}
.defaultMsgBox li:hover p, .messageBox_left li:hover p {font-weight: bold;}
.defaultMsgBox li .noBox, .messageBox_left .cont01 {line-height:30px;color:#5a5a5a;text-align: left;font-weight:bold;display: inline-block;width: 30px;height: 30px;font-size: 14px;background: #fff;color: #757575;border: 1px solid #e7e9ea;text-align: center;border-radius: 50%;position: absolute;left: -18px;top: -10px;}
.defaultMsgBox li .yellowBox .top, .messageBox_left li .cont02 {display:inline-block;box-sizing:border-box;width: 100%;line-height: 24px;padding: 15px 20px 10px;font-weight:bold;color:#545454;font-size: 15px;border-top-left-radius: 5px;border-top-right-radius: 5px;background: #ffd823;text-align: left;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;color: #ffffff;}
.messageBox_left li .cont02 {background:#7e69ff url(../images/contents/bg_transWhite.png) no-repeat right 50%;color:#fff; }
.defaultMsgBox li .yellowBox .top:hover, .defaultMsgBox li .yellowBox .bottom:hover { cursor:default; }
.defaultMsgBox li .yellowBox .bottom, .messageBox_left .bottom{transition:0.2s;height: 255px !important;padding: 0;font-size: 13px;line-height:23px;box-sizing:border-box;border-left:1px solid #ededed;border-right:1px solid #ededed;border-bottom:1px solid #ededed;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;}
.defaultMsgBox li:hover .yellowBox .bottom{border-left:1px solid #fff;border-right:1px solid #fff;border-bottom:1px solid #fff;box-shadow: 0 9px 21px rgba(140, 140, 140, 0.19);transition:0.2s;}
input#msgOpInNewBtn:hover {background: #f3f3f3;}
.msgOptionPage .bottom p {padding: 15px 20px 20px;text-align: left;line-height: 25px;color: #828a9a;/* font-weight: bold; */}
.defaultMsgBox, .messageBox_left {padding-top: 65px;width: 100%;/* border-left: 1px solid #e7e9ea; */}
.defaultMsgBoxWrap .forFixed {position: fixed;width:100%;padding-left: 261px;box-sizing: border-box;left: 0;top: 63px;transition: 0.1s;padding-right: 60px !important;z-index: 1000;}
.right_area_close .defaultMsgBoxWrap .forFixed {padding-right: 0 !important;}
.defaultMsgBoxWrap .forFixed .menu {border-bottom: 1px solid #e7e9ea;height: 60px;line-height: 60px;width: 100%;background: #fff;}
.defaultMsgBoxWrap .forFixed .menu input {height:100%;font-weight:bold;font-size: 16px;padding: 0 30px;background: none;color:#232848;float: left;}
.defaultMsgBoxWrap .forFixed .menu .btn_default:hover, .defaultMsgBoxWrap .forFixed .menu .btn_default.on { background: #ffd823; }
.defaultMsgBoxWrap .forFixed .menu .btn_saved:hover, .defaultMsgBoxWrap .forFixed .menu .btn_saved.on {background: #7e69ff;/* color:#fff; */}
.msgOptionPage .menu h3 {display: inline-block;font-size: 16px;margin-left: 30px;}
.msgOptionPage .menu h3 #msgTotalCnt {font-size: 16px;float: none;margin-left: 10px;color: #477fff;}
.defaultMsgBox { display:none; }
.messageBox_left {display:block;max-width: 1000px;}
#msgHListScroll { text-align:center; }
#msgHListUl .bt_del02 {position: absolute;top: -11px;left: -19px;color: #fff;width: 34px;height: 34px;font-weight: bold;background: #ff5555 url(../images/contents/btn_del_fff.png) no-repeat center center;background-size:35px;}
#msgHListUl .bt_del02:hover {background: #ff3737;font-size: 12px !important;width: 70px;border-radius: 30px;border: 1px solid #ff5555;}
.messageBox_left .bt_del02 { opacity:0; transition:0.1s;}
.messageBox_left li:hover, .messageBox_left li.on {cursor:pointer;border-radius: 5px;transition:0.1s;}
.messageBox_left li.on .bt_del02 { opacity:1; }
.messageBox_left li:hover .bt_del02 {opacity:1;transition:0.1s;}
.messageBox_left li.on:hover { cursor:default; }
.messageBox_left li.off .cont02 {background: #a99bff url(../images/contents/bg_transWhite.png) no-repeat right 50%;}
.messageBox_left li.off .bottom p { color:#878787; }
.messageBox_left li.off:hover .bottom p {color:#4e4e4e;}
.messageBox_left li.off:hover .cont02 {background:#7e69ff url(../images/contents/bg_transWhite.png) no-repeat right 50%;color:#fff; }
.messageBox_left li.off:hover { box-shadow:none; }


#msgOpInTitle.editing { background:#7e69ff; color:#fff !important;  }
#msgOpInTitle.editing::placeholder {color: #fff;}
#msgOpInTitle.editing:hover {background: #7e69ff url(../images/contents/input_pen_fff.png) no-repeat 95% 32px;}

/* 2018_0711 */

.addParentsDiv .regiName { width:100%; text-align: left; }
.addParentsDiv input { width: 100% !important; height: 30px; line-height: 30px; box-sizing: border-box; }

.messageBox_right .btnBox #msgOpInCancelBtn {background: #f55;color: #fff;border: 0;}
.messageBox_right .btnBox #msgOpInSaveBtn {background: #477fff;color: #fff;border: 0;}
.messageBox_right .btnBox #msgOpInCancelBtn:hover {background: #ff3737;}
.messageBox_right .btnBox #msgOpInSaveBtn:hover {background: #477fff;}
input#msgOpInNewBtn {margin-left: 0;top: 13px;right: 0;position: absolute;bottom: 10px;display: none;right: 15px;width: 35px;font-size: 0 !important;height: 35px;background: #f1f3f5 url(../images/contents/ic_close.gif) no-repeat center center !important;border-radius: 50%;}
.messageBox_right .textareaBox:hover #msgOpInNewBtn {display: block;}
.messageBox_right .options {/* position:absolute; */bottom: 0;left: 0;width: 100%;border-left: 1px solid #e9e9e9;border-top: 0 none;box-sizing: border-box;border-right: 1px solid #e9e9e9;background: rgba(255,255,255,0.8) !important;}
#msgAddScroll .messageBox_right .options {height: auto;overflow: hidden;}
#msgAddScroll .options .grp {width: 80%;}
#msgAddScroll .options {position: relative;}
.textareaBox .options ul {}
.textareaBox .options ul li {padding: 0 10px;display: inline-block;border-radius: 15px;margin: 10px 10px 0 5px;line-height: 30px;font-size: 12px;border: 1px solid #e7e9ea;font-weight: bold;transition:0.1s;color: #4e4e4e;}
.textareaBox .options ul li:hover {color: #477fff !important;}
.textareaBox .options ul li:hover { cursor:pointer; color:#232848;transition:0.1s;box-shadow:none; }
.messageBox_right .options h5, .messageBox_right .options h4 {position: static;display: inline-block;padding: 0 20px;display: none !important;background: #fff;font-size: 13px;line-height: 55px;width: 80px;margin-bottom: 0;height: 55PX;}
.messageBox_right .options h5:hover, .messageBox_right .options h4:hover { cursor: pointer; box-shadow:none;transition:0.1s; background:#f3f3f3}
.messageBox_right .options h4 {display:none;}

.msgOptionPage.lClosed  .messageBoxWrap02 { left: 130px !important; }
.msgOptionPage.lClosed .defaultMsgBoxWrap .forFixed {padding-left: 61px !important;}

@media (max-width:1880px) {

	.msgOptionPage.lClosed .vertiLine { left: 609px !important; }
	.msgOptionPage .vertiLine {left: 810px;}
}
@media (max-width:1830px) {
	.msgOptionPage .vertiLine {left: 810px;}
}
@media (max-width:1740px) {

	.msgOptionPage .vertiLine {left: 760px;}
}
@media (max-width:1700px) {

	.msgOptionPage .vertiLine {top: 62px;left: 740px;}
	.msgOptionPage.lClosed .vertiLine { left: 479px!important;}
}


@media (max-width:1610px) {

}

@media (max-width:1540px) {
	.messageBox_right .btnBox .input_btn {padding: 0 20px;margin-left: 5px;}
    .messageBox_right .options h5, .messageBox_right .options h4 { padding: 0 15px; font-size:12px; }
    .msgOptionPage.lClosed .vertiLine { left: 449px!important;}
    .msgOptionPage .vertiLine {left: 710px;}
}

@media (max-width:1400px) {
}





.addPicNfile p.count {opacity: 1;float: right;margin-top: 5px;font-weight: bold;padding:0;opacity:0.7;margin-right:10px;}
.addPicNfile p.count span {opacity:0.5; }
.enabled.addPicNfile p.count {opacity:1; }
.enabled.addPicNfile p.count span { color: #477fff; opacity:1;}
tr#addImageTr:hover .count, tr#addFileTr:hover .count {margin-right: 60px;}
.tableDefault.tableBorder table tbody tr.addPicNfile td {padding-top: 17px;padding-bottom: 20px; border-bottom: 0;}
.tableDefault.tableBorder table tbody tr#addImageTr td {border-bottom: 1px solid #e6e5e5;}
.addPicNfile .grp {position: relative;display: inline-block;width: 80px;border-radius: 5px;height: 80px;margin-right: 15px;cursor: pointer;text-align: center;z-index: 1000;}
.addPicNfile .grp .del {top: -10px;right: -10px;}
.addPicNfile .grp input[type="file"] { width: 20px; height: 20px; margin: 20px auto; font-size: 0; display:none;}
.addPicNfile p {padding: 0px 0 16px;opacity: 0.6;}
.addPicNfile .grp .detailImg { position: absolute; top: 0; left: 0; display: inline-block; width: 100%; height: 100%;  }
.addPicNfile .grp label { font-size: 0; background: #f3f3f3 url("../images/contents/ic_add.png") no-repeat center center; z-index: 10; position: absolute; top: 0; left: 0; display: inline-block; width: 100%; height: 100%; }
.addPicNfile .grp:hover label { background-color: #eaeaea; cursor: pointer;  }
.addPicNfile .grp img { display: block; position: absolute; z-index: 100; background-size: cover; background-repeat: no-repeat; background-position: center center; background-size: cover;  width: 100%;height: auto; font-size: 0; }
.addPicNfile .grp input[type="button"] {z-index: 10000;position: absolute;font-size: 0;transition: 0.1s;display: inline-block;width: 25px;height: 25px;color: #eaeaea;background: #ff6060 url('../images/contents/ic_delImg.png') no-repeat center center; background-size:13px; webkit-appearance: none;font-weight: bold;border-radius:20px;}
.addPicNfile .grp input[type="button"].withPic { bottom: 10px; }
.addPicNfile .grp input[type="button"]:hover { opacity: 1; background-color: #ff4040; color: #fff; }
.addPicNfile .grp label.withPic { z-index: 1000; background: transparent; transition: 0; }
.addPicNfile .grp label.withPic:hover { background: #000; opacity: 0.1; }
p.schImg {opacity:1;width: 100%;height: 100%;font-size: 0;padding: 0;background-position: center center !important;border-radius: 5px;background-size: cover !important;}
/* #addImageTr:hover td {background: #f5f8ff !important;} */



.remodal.detailImgModal { width: 100%; height: 100%; background: transparent; margin-bottom: 0; overflow: hidden; border-radius: 0;}
.remodal.detailImgModal .remodal-close { position: relative; float: right;top:0; margin: 6px 0 0 20px; opacity: 0.7; }
.remodal.detailImgModal .remodal-close:hover { opacity: 1; }
.remodal .grp { width: 100%; height: 100%; }
.remodal-wrapper { padding: 0; }
.detailImgModal .titleBox { z-index: 1000; background: transparent; height: 100px; position: fixed; bottom: 0; top: auto; left: 0; right: auto; width: 100%; box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); -webkit-box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); -moz-box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); -o-box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); -ms-box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2); }
.detailImgModal .forRel { position: relative; width: 100%; height: 100%; text-align: center; }
.detailImgModal .titleBox .bg { z-index: -1; position: absolute; width: 100%; height: 100%; background: #000; opacity: 0.6;  }
.detailImgModal .titleBox h1 { margin-top: 25px; padding-bottom: 0; font-size: 18px; color: #fff; text-align: left; float: left; }
.detailImgModal .titleBox .grp1 { width:1170px; display: inline-block; }
.detailImgModal .titleBox .grp1 > div { width: 100%; height: 50px; text-align: center; margin-top: 25px; }
.detailImgModal .titleBox .thumb {background-color: #fff;transition: 0.2s; opacity: 0.6; cursor: pointer; display: inline-block; width: 50px; height: 100%; margin-left: 20px; border: 2px solid #eaeaea; border-radius: 5px; background-size: cover; background-repeat: no-repeat; background-position: center center; background-size: cover; }
.detailImgModal .titleBox .thumb.on, .detailImgModal .titleBox .thumb:hover { opacity: 1; box-shadow: 0 0 15px rgba(0, 0, 0, 0.4); transition: 0.2s }
.detailImgModal .imgs { position: relative; width: 100%; height: 100%; }
.detailImgModal .imgs .bg { z-index: -1; position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: #000; opacity: 0.6; }
.remodal .imgs .grp1 { margin: 0 auto; width: 1000px; height: auto; text-align: center; white-space: nowrap; }
.remodal .imgs .grp1:before { content: ''; display: inline-block;  height: 100%; vertical-align: middle; margin-right: -0.25em; }
.detailImgModal .imgs img { display: none; width: auto; max-width: 1000px; margin: 0 auto; padding: 50px 0 140px 0; vertical-align: middle; }
.detailImgModal .imgs img.on { display: inline-block; }
.detailImgModal .mCSB_inside > .mCSB_container { margin-right: 0 }

@media only screen and (min-width: 641px) { .remodal.detailImgModal { max-width: 100%;} }



.addPicNfile .grp.file label { font-size: 0; background: #f3f3f3 url("../images/contents/file_clip.png") no-repeat center center; z-index: 10; position: absolute; top: 0; left: 0; display: inline-block; width: 100%; height: 100%; }
.addPicNfile .grp.file {transition: 0.1s;display: block;width: 480px;background: #f3f3f3;margin-bottom: 10px;border: 1px solid #ebebeb;display: block;top: 0;height: 50px;}
.addPicNfile .descript:hover { cursor: default; }
.addPicNfile .grp.file label {width: 50px;}
.addPicNfile .grp.file input.del {bottom: 0;width: 40px;height: 100%;border-radius: 0;right: -45px;background-color:#d9d9d9;opacity: 1;background-size: 14px;}
.addPicNfile .grp.file:hover input.del { right: 0;  }
.addPicNfile .grp.file input.del:hover {background-color:#ff4040;}
.addPicNfile .grp.file label { display: none }
.addPicNfile .grp.file input[type="file"] {display: none  }
.addPicNfile .grp.file:hover  .descript  h3 { color:#4089ff; width:290px; transition: 0.1s;  }
.addPicNfile .grp.file .descript {background: #f3f3f3 url(../images/contents/file_clip.png) no-repeat -4px center;height: 100%;text-align: left;line-height: 50px;}
.addPicNfile .grp.file .descript * { float:left;  }
.addPicNfile .grp.file .descript p { margin-left:10px; text-align: right;width: 70px;padding-right: 20px; color:#282828; }
.addPicNfile .grp.file .descript h3 {transition: 0.1s;font-size: 12px;color: #282828;padding-left: 50px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 330px;}


.addPicNfile .grp .download{display:none;width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 1000000000;}
.addPicNfile .download .bg { width:100%; height: 100%; position: absolute; background: #000; opacity: 0.1; z-index: -1; top:0; left: 0; }
.addPicNfile .donwload input {-webkit-appearance: none;border: 0 none;appearance: none;box-shadow: none;font-size: 0;margin: 0;border: 0 none;background-color:transparent;cursor:pointer;padding:0; width:100%; height: 100%;}

.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .description p { display: none; }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .grp.file:hover .descript  h3 {width: 330px; }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .grp .del { display: none; }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .imgAdd { display: none; }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .grp:hover .del { display: none;  }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .grp:hover .download{display:block; }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .download input { border-radius: 5px;  }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .download input.downloadFile { width: 35px; height: 35px; bottom: 10px;right: 10px;opacity: 1; background: #fff; background: #4089ff url(../images/contents/ic_download.png) no-repeat center center;}
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .download input.downloadFile:hover { background-color:#559aff; }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .download input.preview { opacity: 1; width: 35px; height: 35px; bottom: 30px; right: 10px; background: #4f4f4f url(../images/contents/ic_preview.png) no-repeat center center; }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .download input.preview:hover { background-color:#6b6b6b; }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .file .download input.downloadFile { left: auto; right: 10px;  }
.tableDefault.tableBorder table tbody tr.addPicNfile.readOnly .file .download input { bottom: 7px;  }





/* .addPicNfile#addFileTr .description input { display: none !important;  }
.addPicNfile#addFileTr th h3 { font-weight: normal; margin-top: 10px; }
.addPicNfile#addFileTr label {margin-bottom:10px; width: 100px; height: 35px; display:block; line-height: 35px; text-align: center; border-radius: 5px; font-weight: bold; background: #4088ff; color:#fff; }
.addPicNfile#addFileTr label:hover { cursor: pointer; background: #1a71ff; } */





input.btn_memoSet {position: absolute;top: 10px;left: 590px;background: #ffcd3a;padding: 10px 15px;border-radius: 5px; font-weight:normal}
input.btn_memoSet:hover {background: #ffbf01;}
.forRel { position: relative;}


/* 2019.03.20 */
@font-face {
    font-family: 'noto sans';
    src: url('../font/notokr-thin.eot');
    src: url('../font/notokr-thin.eot?#iefix') format('embedded-opentype'),
         url('../font/notokr-thin.woff2') format('woff2'),
         url('../font/notokr-thin.woff') format('woff'),
         url('../font/notokr-thin.ttf') format('truetype'),
         url('../font/notokr-thin.svg#notokr-thin') format('svg');
    font-weight: 100;
    font-style: 100;

}
@font-face {
	font-family: 'noto sans';
	src: url('../font/notokr-regular.eot');
	src: url('../font/notokr-regular.eot?#iefix') format('embedded-opentype'),
	url('../font/notokr-regular.woff2') format('woff2'),
	url('../font/notokr-regular.woff') format('woff'),
	url('../font/notokr-regular.ttf') format('truetype'),
	url('../font/notokr-regular.svg#notokr-regular') format('svg');
	font-weight: normal;
	font-style: normal;

}
@font-face {
	font-family: 'noto sans';
	src: url('../font/notokr-bold.eot');
	src: url('../font/notokr-bold.eot?#iefix') format('embedded-opentype'),
	url('../font/notokr-bold.woff2') format('woff2'),
	url('../font/notokr-bold.woff') format('woff'),
	url('../font/notokr-bold.ttf') format('truetype'),
	url('../font/notokr-bold.svg#notokr-bold') format('svg');
	font-weight: bold;
	font-style: normal;

}


body,.input_txt,.input_textarea, textarea, input {
	font-family:'noto sans', Sans-serif;
}





.schedule_box .tableDefault.tableBorder.th-right {
    padding-bottom: 150px;
}
#section_ce.readOnly #mapTr #mapSearchKey {padding-left:0}
#schDetailScroll .map { margin-left: 0; margin-top: 10px;margin-bottom: 10px;border-radius: 5px;}
#schDetailScroll .map.on {height: 0 !important;transition:0.1s;margin: 0;}
#section_ce #schDetailScroll input.openMap {display: none !important;}
#section_ce.readOnly #schDetailScroll input.openMap {display: block !important; width: 40px;height: 40px;position: absolute;right: 0;top: 13px;z-index: 100;font-size: 0;border-radius: 50%;border: 1px solid #e6e5e5;background: #fff url(../images/side_area/bt_open.png) no-repeat center center;}
#section_ce.readOnly #schDetailScroll input.openMap:hover { background-color: #f3f3f3; }
#schDetailScroll #mapTr td {position: relative;}

.forRel > td { position: relative; }
#schDetailScroll .schedule_box {padding-top: 10px;}
.section_ce_fix .title_box {border-bottom: 2px solid #e7e9ea;}
#schDetailScroll textarea {resize:none;background-color: #fff;width:100% !important;max-width:100%;padding: 0!important;}



#colorDiv,.forRel input.clr {margin-right: 0 !important;}

.tableDefault.tableBorder table tbody tr td { border-bottom: 1px solid #f3f3f3; }
#schDetailScroll .tableDefault.tableBorder table tbody tr td{    padding: 0 !important;}
#schDetailScroll .tableDefault.tableBorder table tbody tr td#repeatTd {padding: 11px 0 !important;padding-right: 40px !important;padding-bottom: 1px !important;}
.inputgroup .selectboxWrap {border:0 none;font-weight: bold;width: 55px !important;text-align: center;height: 60px;line-height: 60px;background:#fff !important;}
.inputgroup .selectboxWrap label { width:50px !important; padding:0;left:0; }
#timeTr .inputgroup .lunarDiv.selectboxWrap label{    text-align: center;
    font-weight: bold;
    color: #273044;
    padding: 0 15px;
    width: auto !important;
    background: #f1f3f5;
    border-radius: 19px;
    line-height: 35px !important;
    height: 35px;
    margin-top: 12px;
    font-size: 12px;}
#timeTr .inputgroup .lunarDiv.selectboxWrap label.disabled { color:#273044 !important }
#timeTr .inputgroup .lunarDiv.selectboxWrap label.disabled:hover { color:#477fff !important }
#timeTr .inputgroup .lunarDiv.selectboxWrap label:focus, #timeTr .inputgroup .lunarDivL.selectboxWrap label:active { background:#477fff !important; color:#fff; }   
#timeTr .inputgroup .lunarDiv.selectboxWrap label:hover { background:#477fff !important; color:#fff !important; }
.inputgroup .selectboxWrap:hover { background:#fff !important; }
.inputgroup .selectboxWrap select { float:right; width:50px; height:60px; padding: 0; }
#schDetailScroll .input_txt {font-size: 13px;font-weight: bold; padding: 0 !important;}
.readOnly .schedule_box tr td:after {display:none; }
.readOnly .tableDefault table tr:hover th label { color: #434343 }
.tableDefault table tr:hover th label {  color:#477fff; }

#schDetailScroll .tableDefault.tableBorder table tbody tr#workerTr td {padding: 19px 0 !important;}
#workerTr .selectboxWrap {line-height: 21px;height: 21px; background: none; width:60px; text-align:center; padding: 0 !important; width:50px; font-weight:bold; display: none; }
#workerTr .selectboxWrap select{ padding:0; }
#workerTr .selectboxWrap label { left: 0;height: 60px;width: 50px;line-height: 60px;top: -20px; }
#workerTr .selectboxWrap label:hover{ color:#477fff; cursor:pointer; }
#workerTr .selectboxWrap select:hover { cursor:pointer; }
#workerTr .inputgroup_txt02 {line-height: 21px;}
a.result {line-height: 34px;font-weight: bold;width: auto;display: inline-block;display: block;position: absolute;top: 12px;/*right: 60px;*/ right: 235px;}
a.result {background: #f1f3f5;border-radius: 20px;padding: 0 15px;line-height: 34px;display: inline-block;}
a.result span { color:#477fff; }
a.result:hover, a.result:hover span {background-color: #477fff; color:#fff;}
.chart {line-height: 42px;border-top: 1px solid #f1f3f5;margin-top: 20px;}
.chart p {float: left;line-height: 1em;padding-top: 22px;padding-bottom: 6px;font-weight: bold;}  
.switch {float:right;margin-top: 15px; }

#schDetailScroll .inputgroup .input_txt {text-align:left;font-size: 14px;font-weight: bold;height: 60px;line-height: 60px;color: #434343;background-color: none !important;border: 0 none;margin-left: 0 !important; width: 120px !important;}
#schDetailScroll .inputgroup .arw {height: 60px;display: inline-block;width:70px;top: 0;font-size: 0;margin-left:0 !important;background:url('../images/contents/img_time_arw.png')  center center no-repeat;}
#schDetailScroll .inputgroup.left .arw{transition:0.2s; background:url('../images/contents/img_time_arw_left.png')  center center no-repeat; }
#schDetailScroll .inputgroup.right .arw {transition:0.2s; background:url('../images/contents/img_time_arw_right.png')  center center no-repeat; }
.forView #schDetailScroll .inputgroup.right .arw, #schDetailScroll .inputgroup.left .arw {height: 60px;display: inline-block;width:70px;top: 0;font-size: 0;margin-left:0 !important;background:url('../images/contents/img_time_arw.png')  center center no-repeat;}

#schDetailScroll .inputgroup  input#s_FrTm, #schDetailScroll .inputgroup input#s_ToTm {width: 70px !important;margin-left: -3px !important;}
#schDetailScroll .inputgroup input#s_ToYmd {}
#voteTr .inputgroup_txt02 {line-height: 34px;}
#schDetailScroll .inputgroup_txt02 { font-weight: bold; color: #434343; margin-left: 0 !important;font-size:13px !important;}
#schDetailScroll #repeatTd .inputgroup_txt02 { line-height: 34px;margin-bottom: 10px; }
#schDetailScroll .inputgroup_txt02.result {padding: 7px 35px 7px 15px;background: #f1f3f5;border-radius: 20px;}
#schDetailScroll span#lunarToSolarTxt {margin-left:10px !important;height: 60px;line-height: 60px;font-size: 14px;opacity: 0.5;}
#schDetailScroll .inputgroup input:hover { color:#477fff; box-shadow: none; border: 0 none; }
.forView #schDetailScroll .inputgroup input:hover { color:#434343; cursor: default; box-shadow: none; border: 0 none; }
#lunarToSolarTxt { margin-top: 0 !important; line-height: 40px;}
#fc-userToolbox .fc-right .selectboxWrap label {font-weight: bold;color: #4c4c4c !important;	}
.inputgroup input[type='checkbox']:not(old) + label {background: none;padding: 0;width: 50px;text-align: center;line-height: 60px;font-weight: bold;}
.inputgroup .selectboxWrap:hover label { color:#477fff !important; }
.inputgroup label:after { display: inline-block; position:absolute; height:5px; width:5px; background:#477fff; }
.inputgroup input[type='checkbox']:checked + label {color:#477fff;}

textarea#myMemo {margin-right: 1%;}
input.radio_round[type='radio']:not(old):checked + label {font-weight: bold;color: #4e4e4e;}
#repeatTd span.inputgroup_txt {display: block;line-height: 1;margin-left: 0;margin-bottom: 9px;margin-top: 17px;color: #434343 !important;font-weight: bold;}
span.inputgroup_txt.disabled {display: none;margin: 0 !important;}
#attendInput input[type='checkbox']:not(old):checked + label {font-weight: bold; color: #434343;}
input.radio_round[type='radio']:not(old) + label {font-size: 12px;color: #989898;}
#schDetailScroll #telNum::placeholder, #schDetailScroll #s_mainTitle::placeholder,#schDetailScroll #mapSearchKey::placeholder { font-weight: normal }


#schDetailScroll #repeatTd .round_radiobox {margin-top: 0;margin-bottom: -4px;}
.addPicNfile .grp {border: 1px solid #ececec;margin-top: 10px;}
.clickOrDropBg { margin-bottom: 0;}

#schDetailScroll .tableDefault.tableBorder table tbody tr.addPicNfile td {border-bottom: 1px solid #f3f3f3;padding: 19px 0 !important;}
#schDetailScroll input[type='checkbox']:not(old):checked + label {font-weight: bold;}
.bt_noti, .bt_noti:hover { background-size: 17px;}

@media (min-width: 1700px) {
	#section_ce > .bg > div {box-shadow: 0 0 15px rgba(0,0,0,0.1) !important;} }


.tableDefault.tableBorder table tbody tr#addImageTr td {border-bottom: 1px solid #f3f3f3;padding: 19px 0 !important;}
#grupTr .selectboxWrap.selectboxWrap02 {padding-right: 20px;}



/* readOnly */
.readOnly input[type='text']:disabled {color: #434343;}
.readOnly input[type='text']:hover {cursor: default;}
.readOnly .inputgroup .selectboxWrap {}
.readOnly .inputgroup .selectboxWrap label.disabled {}
.readOnly #schDetailScroll .inputgroup .input_txt {}
.readOnly span.inputgroup_txt01 {line-height: 30px;margin: 0 10px;}
.readOnly #schDetailScroll input#s_FrTm, .readOnly #schDetailScroll input#s_ToTm {width: 65px !important;}
.readOnly .inputgroup p.fl.mt04.ml10 {display: none !important;}
.selectboxWrap.fl.input_gray.w61.radius04:hover select {cursor: default;}
.readOnly #schDetailScroll tr.writtenBy  { display:contents; }
#schDetailScroll tr.writtenBy { display:none; }
#schDetailScroll tr.writtenBy input {font-size: 12px;}
.readOnly .chart {display: none;}
.readOnly #schDetailScroll .tableDefault.tableBorder table tbody tr.writtenBy td {padding: 19px 0 !important;}


button.cancel.modal-topBtn {background: #ff6060;float:right;}
.repeatModal.remodal.w390 {width: 410px;}
.searchResultSection ul > li > ul > li {overflow: hidden;padding: 20px 20px;border: 2px solid #f1f3f5;margin: 20px 0;border-radius: 15px;background: #fff;opacity:0.9; background: #f1f3f5;}
.searchResultSection ul > li > ul > li:hover {box-shadow: 0 2px 15px rgba(0,0,0,0.12);border: 2px solid #fff;}
.searchResultSection ul li h3 {margin: 0 auto;margin-top: 30px;width: 150px;border-radius: 25px;}
.searchResultSection ul li:hover h3 {background: #477fff;color: #fff;}
.searchResultSection ul > li:hover > ul > li {background: #fbfbfb; transition:0.1s}
.searchResultSection ul > li:hover > ul > li:hover {background: #fff; transition:0.1s;opacity:1;}
.searchResultSection .groupping3 {margin-top: 7px;}
.searchResultSection .groupping1 p.time {margin-top: 3px;font-size: 13px;margin-bottom: 14px;}

.searchResultSection .groupping1 .groupping_b p {margin-bottom: 0;}
.searchResultSection .groupping2 .groupping_b {line-height: 16px;}
.searchResultSection .groupping2 .groupping_a { line-height:19px; }

.searchResultSection .clr { display: block;float: left;width: 7px;height: 73px;margin-right: 15px;background: red;border-radius: 5px; }

.searchResultSection h4 {     width: 100%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; }
.searchResultSection .groupping2 {display: inline-block;width: 70%;}
#schDetailScroll tr.writtenBy input {font-size: 12px;width: 70%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
#section_ce.readOnly #schDetailScroll input.openMap {background: #fff url(../images/contents/ic_mapPin_red.png) no-repeat center center}

.readOnly #repeatTd span.inputgroup_txt, #section_ce.readOnly #mapTr #mapSearchKey::placeholder, #schDetailScroll #telNum::placeholder {font-weight: bold;font-size:13px !important; font-family:'noto sans', sans-serif; }
#schDetailScroll #telNum::placeholder, #schDetailScroll #s_mainTitle::placeholder,#schDetailScroll #mapSearchKey::placeholder, #schDetailScroll #telNum::placeholder { font-weight: bold !important; }
.readOnly #schDetailScroll #telNum::placeholder,.readOnly  #schDetailScroll #s_mainTitle::placeholder,.readOnly #schDetailScroll #mapSearchKey::placeholder, .readOnly  #schDetailScroll #telNum::placeholder { font-weight: bold !important; }
#schDetailScroll textarea { max-width: 100% !important; font-weight:bold; transition:0.1s; overflow:visible !important; font-size: 13px !important;}
textarea.autosize { min-height: 200px; }
#schDetailScroll textarea:hover {}
#schDetailScroll textarea:focus {background-color: #fff;outline: none;min-height:200px; transition:0.1s;}
td#alramTd {position: relative;}
#schDetailScroll .tableDefault.tableBorder table tbody tr td#alramTd, #schDetailScroll .tableDefault.tableBorder table tbody tr#shareTr td  {position: relative;padding: 11px 0 !important;padding-right:40px !important;padding-bottom:1px !important;}
#schDetailScroll td#alramTd .inputgroup_txt02, #schDetailScroll #shareTr td .inputgroup_txt02 { line-height: 34px; margin-bottom:10px;}
#schDetailScroll td#repeatTd .inputgroup_txt02 { color:#acb2bf }
#schDetailScroll td#alramTd .inputgroup_txt02.added { margin-right:10px;color: #273044;margin-bottom: 10px; padding: 0 15px;background: #f1f3f5 url(../images/contents/ic_x_new_sm.png) no-repeat center right;border-radius: 19px;padding-right: 33px;}
#schDetailScroll td#alramTd .inputgroup_txt02.added:hover { cursor:pointer; background-color:#e5e7ea; }
#schDetailScroll td#repeatTd .inputgroup_txt02.added {color:#273044;margin-right:10px;color: #273044;margin-bottom: 10px; padding: 0 15px;background: hsl(210, 17%, 95%) url(../images/contents/ic_x_new_sm.png) no-repeat center right;border-radius: 19px;padding-right: 33px;}
#schDetailScroll td#repeatTd .inputgroup_txt02.added.read { background: #f1f3f5; padding-right:15px }
#schDetailScroll td#repeatTd .inputgroup_txt02.added:hover { cursor:pointer; background-color:#e5e7ea; }
#schDetailScroll #shareTr td .inputgroup_txt02.added em { color:#477fff; font-style:normal; padding:0 0px 0 2px; }
#schDetailScroll #shareTr td .inputgroup_txt02.added { margin-right:10px;color: #273044;margin-bottom: 10px; padding: 0 15px;background: #f1f3f5 url(../images/contents/ic_x_new_sm.png) no-repeat center right;border-radius: 19px;padding-right: 33px;}
#schDetailScroll #shareTr td .inputgroup_txt02.added:hover { cursor:pointer; background-color:#e5e7ea; }

a#btnAlarm, a#btnRepeat, #btnVoteInput, #btnAttendInput {line-height: 35px;background: url(../images/contents/ic_plus_new.png) no-repeat center center;padding: 0;font-weight: bold;color: #434343;width: 35px;font-size: 0;border-radius: 50%;position: absolute;right: 0;top: 10px;}
a#btnAlarm, a#btnRepeat, #btnVoteInput, .shareBtn { opacity:0; transition:0.2s; } 
a#btnAlarm.on, a#btnVoteInput.on {opacity:1;background-image: url(../images/contents/ic_close_new.png); background-color:#f1f3f5;}
a#btnRepeat.on { opacity:0 !important; cursor:default; }
tr:hover a#btnAlarm, tr:hover a#btnRepeat, tr:hover #btnVoteInput, tr:hover .shareBtn { opacity:1; }
.readOnly a#btnAlarm { height: 30px; line-height:30px; }
a#btnAlarm:hover {background-color: #e5e8ea;}
.readOnly #repeatTd span.inputgroup_txt, #section_ce.readOnly #mapTr #mapSearchKey::placeholder, #schDetailScroll #telNum::placeholder {font-weight: bold;font-size:12px !important; font-family:'noto sans', sans-serif; margin: 0;}
#schDetailScroll .tableDefault.tableBorder table tbody tr#attendTr td {padding: 19px 0 !important;}

#btnSave,#btnMod, #btnDel, #btnCopy,.bt_mms.bt_edit.toSave, #hostOptionSaveBtn {width: auto;padding: 0 15px !important;border-radius: 5px;height: 40px;font-size: 14px;color:#434343 !important;background-image:none !important;background: #fff !important;border:1px solid #e8e8e8;font-weight:bold;/* margin-top: -7px; */box-shadow: 0 1px 3px rgba(0, 226, 154, 0.4);box-shadow: none !important;transition:0.1s !important;margin-left:15px;}
#btnSave:hover, #instDelBtn:hover, .bt_mms.bt_edit.toSave:hover, #hostOptionSaveBtn:hover {height: 40px;padding: 0 15px;font-size: 14px;/*box-shadow: 0 2px 5px rgba(0, 226, 154, 0.5) !important;*/background-image: none !important;border-color: #f1f3f5;background: #f1f3f5 !important;}
.bt_shareBox {bottom: 30px}
p.btn_group {position: static;margin-right: 0;float:right;margin-top: 9px;}
p.btn_group.mainBtns { position:absolute; margin-right:0;}
.readOnly .forRel input.clr:hover:hover {cursor: default;transform: scale(1) !important;}
.readOnly span#lunarToSolarTxt {line-height: 30px;color: #959595;}
#left_area .class_depth input[type='checkbox']:not(old) + label {width: 15px;height: 15px;} 



.gnb_area .gnb_rightarea {top:0}
.gnb_area .gnb_schInbox {width: 100%;height: 100%;}
.gnb_area .gnb_schInbox .input_search02 {width: 100%;height: 61px;line-height: 61px;padding: 0 40px 0 20px !important;font-weight: bold;background-color: #fff;box-sizing: border-box;border:0 none;font-size: 15px;border-radius:0;}
.gnb_area .bt_search {width: 60px;height: 60px;background: url(../images/contents/ic_search_on.png) no-repeat center center;}
#searchTotalDiv:hover .bt_search:hover {opacity:1}
.gnb_area .gnb_schInbox .input_search02::placeholder {}
.gnb_area .gnb_schInbox .input_search02:hover, .gnb_area .gnb_schInbox .input_search02:focus {background-color: #f1f3f5;}

div#footer {border-top: 1px solid #ececec;box-shadow: none !important;}
#footer .footer_inbox li .foot_cont {color: #878787;font-weight: bold;}
#footer .footer_inbox li .foot_cont:hover {color: #434343;}
#footer .footer_inbox li {border: 0 none;}
.ft_copyright {color: #9c9c9c;font-weight: bold;font-style: italic;}
#right_footer li a {color: #737373;font-weight: bold;}
#right_footer li a:hover {color: #434343;}



/* 오른쪽 파일박스 */
#right_area .fileBox li:hover {box-shadow: 0 2px 7px rgba(0,0,0,0.1);transition: 0.1s;background:url(../images/contents/ic_fileBox_on.png) no-repeat center 25px;background-color: #fff;}
#right_area .fileBox .fileName a {width: 100%;font-size: 12px;display: inline-block;height: auto;position: absolute;top: 16px;left: 0;color: #232848;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
#right_area .fileBox li {display: inline-block;width: 46%;margin: 5px 2%;height: 90px;position: relative;text-align: center;line-height: 90px;background:url(../images/contents/ic_fileBox.png) no-repeat center 25px;background-color: #f1f3f5;border-radius: 5px;}
#right_area .fileBox {padding: 14px 14px 14px;margin-top: 63px;}


.alldate_box{background-color:#fff;}
.tit_date{position: relative;overflow: hidden;margin-bottom: 0;background: #f1f3f5;transition: 0.1s;border-radius: 0;padding: 15px 0 15px 15px;color: #232848;font-size: 14px;font-weight: bold;}
.tit_date:hover{cursor: pointer;transition: 0.2s;background-color: #f1f3f5;}
.alldate_box.on .date_depth {overflow: hidden;/* display:none !important; */height: 0;padding: 0 !important;border: 0 !important;opacity: 0;transition: 0.1s;}
.date_depth{font-size:12px;padding:0 20px;transition: 0.1s;/* border-bottom: 1px solid #f1f3f5; */}
.date_depth > li{padding:0 0 13px;overflow:hidden;}
.date_depth > li:first-child{border-top:0;}
.date_depth > li ul{display:none;}
.date_depth > li.on ul{display:block}
.date_depth > li#personal > p{background:none;}
.date_depth > li > p{position:relative;background:url('../images/side_area/ic_depthclose_new.png') no-repeat right 7px;}
.date_depth > li > p > span{display:inline-block;padding:4px 0;color:#7c88a2 !important;font-weight: bold;cursor:pointer}
.date_depth > li:hover > p > span {color: #202A39 !important;}
.date_depth > li.on > p{padding-bottom:6px;background:url('../images/side_area/ic_depthopen_new.png') no-repeat right 7px;}
.date_depth > li.on > p > span{display:inline-block;padding:4px 0;color:#4e4e4e;cursor:pointer}
.date_depth .date_depth02 {padding-left:10px;}
.date_depth .date_depth02 li {padding:1px 0}
.date_depth .date_depth02 li > p{position:relative;height:  30px;}
.date_depth .date_depth02 li.off > p{background:url('../images/side_area/ic_depth02_close.gif') no-repeat right 7px;}
.date_depth .date_depth02 li > p > span{display:inline-block;padding:4px 0;color:#737373;cursor:pointer}
.date_depth .date_depth02 li ul{display:none;}
.date_depth .date_depth02 li.on ul{display:block;float:  left;margin-bottom: 10px;}
.date_depth .date_depth02 li.on > p{padding-bottom:5px;background:url('../images/side_area/ic_depth02_open.gif') no-repeat right 7px;/* clear:  both; *//* overflow:  hidden; */height: 25px;}
.date_depth .date_depth02 li.on > p > span{display:inline-block;padding:4px 0;color:#737373;cursor:pointer}
.date_depth .date_depth03 {padding-left:29px;width:  100%;}
.date_depth .date_depth03 li{padding:1px 0;width:  100%;}
.date_depth .date_depth03 li > p{position:relative}
.date_depth .date_depth03 li > p > span{display:inline-block;padding:4px 0;color:#737373;cursor:pointer}

#right_area .right_inbox02 { padding: 0 20px; }
#right_area .right_inbox02 .notice {background-color:#fff;margin-top: 0;margin-bottom: 2px;}
#right_area .right_inbox02 .notice:first-child {}
#right_area .right_inbox02 .noti_titlebox{position: fixed;width: 430px;z-index: 10000;background: #fff;top: 0;height: 61px;overflow:hidden;border-radius:0;border-bottom: 2px solid #e7e9ea;}
#right_area .right_inbox02 .notice.on { margin-bottom: 0 }
#right_area .right_inbox02 .notice.on .noti_titlebox {transition: 0.2s; border: 1px solid #fff !important; background-color: #fff; box-shadow: 0 4px 9px #ededed; -webkit-box-shadow: 0 4px 9px #ededed; }
#right_area div#myFileBoxDiv {margin-bottom: 100px;}
#right_area .right_inbox02 .notice .tit_noti{float:left;/* padding-top:15px; *//* padding-bottom:13px; *//* padding-left:15px; */color: #232848;font-size: 14px;}
#right_area .right_inbox02 .noti_titlebox .tit_noti {/* color: #fff; */}

#right_area .right_inbox02 .noti_titlebox.mNoti {background: #ffffff;margin-top: 0;}
#right_area .right_inbox02 .noti_titlebox.mNoti .tit_noti a {/* color:#fff; */}

.notiWrap{padding:0 15px 10px;}
.txt_imgbox{text-align:center;}
.txt_notibox{position:relative; text-align:left;}
.readbox{margin-top:0px;background: url(../images/side_area/bt_close.png) no-repeat 290px 11px;border-bottom: 1px solid #e7e9ea;}


.btn_fd_up{position:absolute; top:0px;left:0;width:100%;;height:40px;z-index:20;}

.bt_regiBox{position:absolute; top:6px;right:4px}
.bt_regi{position:relative; margin-top:11px;margin-right:10px;display:inline-block; width: 19px; height: 19px;background:url('../images/side_area/ic_add.gif') no-repeat center center;z-index:30;border-radius: 50%;}
.bt_regi:hover  { background: #29c78e url(../images/side_area/ic_add_on.gif) no-repeat center center;  }

.btMoreBox{position:absolute; right:-13px;top:11px;margin-top:1px;font-size: 0;}
a.btMore{display:inline-block;width: 27px;height: 27px;padding: 0 0;background: url('../images/side_area/bt_more.png') no-repeat 12px center;z-index:30;border-radius: 50%;}
a.btMore { width:100%;height:100%;background: none; }
a#addGrp, #memManage .btMore {position: absolute;background: #fff url('../images/contents/ic_add.png') no-repeat center center; top: 7px;right: 15px;width: 30px;transition: 0.2s;font-size: 0;background-size: 26px;height: 30px;}
a#addGrp {background: #fff url('../images/contents/month_bt_next.svg') no-repeat center center; background-size:21px; }
a#addGrp:hover {background: #f1f3f5 url('../images/contents/month_bt_next_on.svg') no-repeat center center; background-size:21px; }
a.btMore:hover {transition: 0.1s;background: #e6e6e6 url(../images/side_area/bt_more.png) no-repeat 12px center;}
a.btMore:hover {background: none; }
a.btMore02{position:relative;display:inline-block;margin-top:10px; margin-right:4px;width:20px;height:8px;padding: 6px 0;background:url('../images/side_area/bt_more.png') no-repeat 3px center;z-index:30;}
a.btMore02:hover {transition: 0.1s; background-position: 7px center;  }
.btMoreBox02{position:absolute;top:0;width:100%;height:100%;font-size:0;right: 0px;transition: 0.1s;}
.btMoreBox02:hover {}
.titleDepthBox a.btMore02 { background: url('../images/side_area/bt_more.png') no-repeat 12px center; width: 40px; height: 40px;  }
.titleDepthBox a.btMore02:hover { background: url('../images/side_area/bt_more.png') no-repeat 17px center; }
.txt_notibox .btMoreBox { z-index:1000; background-color: none; transition:0.1s;left: 0;height: 40px;width: 280px;top: 0; }
.txt_notibox:hover .btMoreBox {border-radius:50%; transition:0.1s; }
.txt_notibox:first-child { border-bottom: 1px solid #e7e9ea;margin-top: 5px;background: url(../images/side_area/bt_close.png) no-repeat 290px 11px; }  

.noti_titlebox .bt_add,.bt_regi{position:relative;float:right;display:inline-block;margin-right: -29px;width: 25px;height: 25px;border-radius: 50%;margin-top: 10px;transition: 0.1s;/* margin-right: 9px; */background: #477fff url(../images/contents/btn_add_fff.png) no-repeat center center;background-size: 25px;z-index:30;}
.noti_titlebox .bt_add:hover,.noti_titlebox .bt_regi:hover {background: #477fff url(../images/contents/btn_add_fff.png) no-repeat center center;background-size: 25px;}
.noti_titlebox .bt_regi:hover {background-color: #477fff !important;}
a#notiBtnReg {background-color:transparent !important;background-size: 35px; margin-right:12px !important;}
.noti_titlebox.close{margin-bottom: 2px;}
.noti_titlebox.consultingLink > p {padding: 0 20px 0 0;display: inline-block;height: 38px;line-height: 38px;}
.noti_titlebox:hover a.bt_add {background: #7e69ff url(../images/contents/btn_add_fff.png) no-repeat center center;background-size: 25px;}
.noti_titlebox:hover .bt_add, .noti_titlebox:hover .bt_regi {margin-right: 18px;}
.noticont #notiUnreadUl .cont_tit { background:url(../images/contents/ic_latest.png) no-repeat 0px center;animation-name: new;animation-duration: 0.6s;animation-iteration-count: infinite; }
@keyframes new {
  0%   {background-size: 15px;background-position:3px center;}
  25%   {background-size: 10px;background-position:5px center;}
  50%  {background-size: 15px;background-position:3px center;}
  100% {background-size: 15px;background-position:3px center;}
}
.alldate_box.on .setDiv {/* display:none !important; */height: 0;padding: 0;opacity: 0;border: 0;transition: 0.1s;}
.alldate_box.saved .setDiv { display:block;}
.alldate_box.saved #saveStng {
    right: -56px;
}
.setDiv .raDiv span {position: absolute;left: 50px;width: 200px;line-height: 20px;    text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.setDiv input.radio_round[type='radio']:not(old) + label { background: #e5e8ea url(../images/contents/checked_gray.png) no-repeat 1px 2px;font-weight: bold;color: #4e4e4e;width: 20px;height: 20px;padding: 0;border-radius: 50%;background-size: 18px; }
.setDiv input.radio_round[type='radio']:not(old) + label span { font-weight:normal; }
.setDiv input.radio_round[type='radio']:not(old):checked + label {background:#477fff url(../images/contents/checked_fff.png) no-repeat 1px 2px;background-size: 18px;}
.setDiv input.radio_round[type='radio']:not(old):checked + label span { font-weight:bold; }
.setDiv { display:none; height:44px; position:relative;border-bottom: 1px solid #f1f3f5;box-sizing: border-box;padding: 12px 20px; }
.alldate_box.saved .showSets:hover { }
.alldate_box .showSets {display:block; position: absolute;opacity: 1;height: 100%;width: 100%;border-radius: 0;top: 0;margin: 0;right: 0;z-index: 100;box-sizing: border-box;transition: 0.1s;}
.alldate_box .showSets:hover {background:rgba(0,0,0,0.05) !important;}
.alldate_box .showSets span, .alldate_box #saveStng span {display: none;position: absolute;right: -30px;top: 40px;width: 70px;text-align: center;color: #fff;border-radius: 5px;padding: 10px 10px;background: rgba(46, 56, 86,0.9);}
.alldate_box #saveStng span { right:-10px; width:80px; }
.alldate_box #saveStng:hover span { display:block; }
.notice .noticont li{position:relative;padding-top:4px;padding-bottom: 4px;}
.notice .noticont #notiUnreadUl  li:hover a, .notice .noticont #notiUnreadUl  li:hover .groupName { padding-left:10px !important; transition:0.1s;}
.notice .noticont #notiUnreadUl  li:hover .cont_tit { background:none;}
.notice .noticont li:hover .cont_tit a:after { opacity:0.05;border-radius:5px}
.notice .noticont li .cont_tit a{ font-weight: bold;width:100%;font-size:12px;box-sizing: border-box;display:inline-block;padding-left:25px;padding-top:5px;padding-right:10px;padding-bottom:4px;color: #232848;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
.notice .noticont li .cont_tit a:after {    content: '';display: block;position: absolute;transition: 0.1s;right: 0;top: 0;width: 100%;margin-top: 0;height: 100%;font-size: 0;opacity:0; background:#292d5f}
.notice .noticont li .cont_tit a:hover { color: #232848; }
.notice .noticont li .groupName{cursor:default;padding-left:25px;padding-top:2px;padding-bottom:5px;box-sizing: border-box;font-weight:normal;font-size:12px;color:#989898;white-space:nowrap;text-overflow:ellipsis;width:93%;overflow:hidden}
.notiReadcont li:first-child {border-top: 0 none;}
.notiReadcont li:hover img {box-shadow: 0 2px 4px rgba(0,0,0,0.1);transition: 0.1s;}
.forAb { position: absolute;top: 50%;margin-top: -20px;border-radius: 5px;overflow: hidden;}
.notiReadcont li:hover .forAb {box-shadow:0 2px 5px rgba(0,0,0,0.1);}
.imgDiv {width: 40px;height: 40px;position: relative;overflow:hidden;}
.notiReadcont li img {    background: #d8d3d3;border-radius: 5px;overflow: hidden;margin-top: -19px;border: 1px solid #f1f3f5;min-width: 50%;min-height: 50%;position: absolute;top: -9999px;right: -9999px;bottom: -9999px;left: -9999px;margin: auto;width: auto;height: 100%;}
.notiReadcont li .groupName{float:left;height: 50px;line-height: 50px;padding-left: 55px;width: 100%;box-sizing: border-box;}
.notiReadcont li .groupName a{font-weight: bold;width:210px;font-size:12px;display:inline-block;color:#232848;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
.notiReadcont li .groupName a:hover{ color: #232848;  }
.notiReadcont li .cont_tit {display: none !important;}
.notiReadcont li .cont_tit a{    width: 100%;box-sizing: border-box;position: relative;font-size: 12px;background: #f1f3f5;border-radius: 5px;padding: 13px 13px 13px 27px;display: inline-block;margin: 2px 0;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.notiReadcont li .cont_tit a:after {content: '';font-size:0;display: inline-block;width: 7px;height: 7px;background: #bbbfc3;left: 12px;margin-top: -3px;top: 50%;position: absolute;border-radius: 50%;}
.notiReadcont li .cont_tit a:hover { font-weight:bold; transition:0.1s; }


.section_ce_fix .ui-datepicker{border: 1px solid #eaeaea; box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09); -webkit-box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09);-moz-box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09);}

#right_area .word { line-height: 45px; font-weight: bold;font-size: 13px; } 




.savedDiv.on {transition:0.1s; left:0; }
.savedDiv {transition:0.1s;left:-400px;position: absolute;top: 0;width: 100%;overflow: hidden;box-sizing: border-box;height: 100%;z-index: 10000;padding-top: 282px;}
ul.saved li {padding-bottom: 13px;}
ul.saved {background: #fff;padding: 15px 20px; margin-bottom: 70px;}
.savedDiv .top {position:relative;box-sizing: border-box;padding-left: 20px;line-height: 44px;border-bottom: 1px solid #f1f3f5;background: #fff;color:#fff;background: #477fff;}
.savedDiv h2 {font-size: 13px;}
.savedDiv .btnGrp { position:absolute; right:0;top:0; }
.savedDiv .scroll {overflow-y: auto;height: 100%;background:#fff;}
#left_area .savedDiv input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border-radius: 5px;border: 2px solid #e5e8ea;background: #e5e8ea url(../images/contents/checked_gray.png) no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
#left_area .savedDiv input[type='checkbox']:not(old):checked + label {background: #477fff url(../images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;border: 2px solid #477fff;}
.savedDiv li p span {line-height: 20px;display: inline-block;width: 190px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.savedDiv li p span:hover { font-weight:bold; cursor:pointer; background: url(../images/contents/ic_edit_pen_small.png) no-repeat 155px center; }
.savedDiv .edit {transition:0.1s;overflow: hidden; box-sizing:border-box; height: 0;overflow: hidden;padding: 0;border: 0;}
.savedDiv.editOn .edit {transition:0.1s;padding: 12px 20px;border-bottom: 1px solid #f1f3f5;height:44px;}
.savedDiv.editOn .canBtn { display:none; }
.savedDiv.editOn .editBtn { margin-right:19px }
.savedDiv .edit p {float: left;}
.savedDiv .edit span {line-height: 19px;}
.savedDiv .edit p:last-child { float:right; }
.savedDiv .edit p:last-child input { color:#477fff; background:#fff; }
.savedDiv .checkLi { display: none; }
.savedDiv.editOn .raLi  { display:none; }
.savedDiv.editOn li:first-child {display:none; }
.savedDiv.editOn .checkLi  { display:inline-block; }
.savedDiv .raLi span {position: absolute;left: 50px;width: 200px;line-height: 20px;}
.savedDiv input.radio_round[type='radio']:not(old) + label {background: #c1cfd8 url(../images/contents/checked_fff.png) no-repeat 1px 2px;font-weight: bold;color: #4e4e4e;width: 20px;height: 20px;padding: 0;border-radius: 50%;background-size: 18px;}
.savedDiv input.radio_round[type='radio']:not(old) + label span { font-weight:normal; }
.savedDiv input.radio_round[type='radio']:not(old):checked + label {background:#477fff url(../images/contents/checked_fff.png) no-repeat 1px 2px;background-size: 18px;}
.savedDiv input.radio_round[type='radio']:not(old):checked + label span { font-weight:bold; }
.savedDiv .editBtn,.savedDiv .canBtn,.savedDiv .saveBtn { color:#fff; width:30px; height:30px; background:#477fff;margin-top: -4px;margin-right: 7px;}
.savedDiv .canBtn { font-size:0; background: url(../images/contents/ic_close_fff.gif) no-repeat center center; }




.titDepthBox.on ul {height: 0;padding: 0;margin: 0;opacity: 0;transition: 0.1s;border: 0;}
.all_box{background-color:#fff;font-size:12px;padding-bottom: 100px;padding-top: 0;}
.titDepthBox{position: relative;overflow: hidden;margin-bottom: 0;transition: 0.1s;padding: 0;line-height: 50px;width: 100%;color: #232848;box-sizing: border-box;font-size: 14px;font-weight: bold;border-top: 1px solid #f1f3f5;}
.titDepthBox#workTab h3.titleDep {background: url(../images/contents/ic_work_sm_on.png) no-repeat 17px center;  }
.titDepthBox#grupMms h3.titleDep {background: url(../images/contents/ic_msg_sm_on.png) no-repeat 17px center}
.titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/contents/ic_notice_sm_on.png) no-repeat 17px center;}
.titDepthBox:hover {cursor: pointer;color: #36bdff;transition: 0.2s;}
.titDepthBox:hover .titleDep {color: #232848;}
.titDepthBox.on {margin-bottom: 0;}
#grupManage h3.titleDep:hover, #memManage h3.titleDep:hover {background-color: #f1f3f5;}
.titDepthBox:hover {/* background-color:#f1f3f5 !important; */}
.titDepthBox:hover, .titDepthBox.first:hover, .titDepthBox.hover {/* cursor: pointer; *//* transition: 0.2s; */}
.titDepthBox.first{/* border-left: 8px solid #ff2e3e; */}
.titDepthBox .titleDep{float:left;position: relative;overflow: hidden;margin-bottom: 0;transition: 0.1s;padding: 0 0 0 50px;line-height: 45px;width: 100%;color: #232848;box-sizing: border-box;font-size: 12px;font-weight: bold;}
.titDepthBox.hover .titleDep {color: #232848;}
#left_area .class_depth > li > p {
    background: url(../images/side_area/ic_depthclose_new.png) no-repeat 210px 7px;
}
#left_area .class_depth .class_depth02 li > p > span {display: inline-block;width: 147px;left: 32px;z-index: 1;position: absolute;padding: 0;line-height: 20px;height: 20px;font-weight: 100;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
#left_area .class_depth .class_depth02 li {margin-bottom: 13px;height: 20px;padding: 0;}
.titDepthBox.close{}
.titDepthBox.consultingLink > p {padding: 0 20px 0 0;display: inline-block;height: 38px;line-height: 38px;position: absolute;right: 0;top: 0;}
.left_inbox02 { padding:0; }
.titDepthBox ul li {line-height: 26px;height: auto;height: 26px;transition: 0.2s;font-size: 12px;padding-left: 25px;font-weight: normal;box-sizing: border-box;position: relative;margin: 0px 0;}
.titDepthBox ul li span.peeps {background: none;width: auto;position: absolute;right: 0;top: 0;top: 0;height: 30px;line-height: 30px;margin: 0;right: 24px;}
.titDepthBox ul li span {background: red;display: inline-block;float: left;width: 8px;height: 8px;margin-top: 10px;margin-right: 10px;border-radius: 50%;}
.titDepthBox ul {display: block;position: static;padding: 10px 0 15px;clear: both;border-top: 1px solid #f1f3f5;float: left;width: 100%;transition: 0.1s;background: #fff;/* border-bottom: 1px solid #f1f3f5; */}
.titDepthBox ul li:hover {font-weight: bold;transition: 0.2s;padding-left: 20px;}
.titDepthBox ul li a {width: 100%;display: inline-block;top: 0;padding-right: 30px;box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-weight: bold;color: #7c88a2;}
.titDepthBox ul li:hover a {color: #202A39;}
.titDepthBox#memManage ul li a {padding-right: 50px;}






.grp.settingBg select {position: absolute;z-index: 10000;top: 0;left: 0;}
.posRel {position: relative;top: 0;left: 0;width: 100%;height: 65px;}
.settingBg > .posRel > .bg {position: absolute;top: 0;left: 0;border-bottom: 1px solid #e7e9ea;width: 100%;background: #fff;height: 65px;/* box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1); */}
.grp.settingBg.leftOn {padding-left:  30px !important;}
.grp.settingBg.rightOn {padding-right: 0 !important;}
.left_area_close .grp.settingBg {padding-left: 61px !important;}
.grp.settingBg {transition: 0.1s;z-index:10;min-width: 708px !important;position: fixed;top: 64px;width:  100% !important;margin-left: 0 !important;background: #fff;padding-left: 261px !important;padding-right: 60px;left:  0!important;height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
.settingBg .left {width: auto !important;width: 50%;height: 40px;position: static;z-index: 100000;float: right;margin-left: 30px;margin-top: 12px;top: 0;float: left;}
.settingBg .left .grp {position: relative;display: inline-block;width: 45%;/* border: 1px solid #e7e9ea; */height: 100%;box-sizing: border-box;max-width: 160px!important;min-width: 160px !important;border-radius: 5px;float: left;}
.settingBg .left .grp:hover .bg {opacity: 1;background: #e8edef;}
.settingBg .left .grp:hover input {color: #477fff;transition: 0.2s;}
.settingBg .left .grp input {cursor: pointer;top: 0;left: 0;box-sizing: border-box;font-size: 14px;font-weight: bold;width: 150px;height: 100%;line-height: 40px;color: #4e4e4e;position: static;padding-right: 20px;text-align: left;margin-right: 13px;background: #fff url(../images/side_area/bt_open.png) no-repeat right center;}
.settingBg .seper {display: inline-block;width: 10px;line-height: 36px;margin: 0 3px;opacity: 1;background: #dbdfe0;float: left;margin-top: 20px;height: 2px;margin-right: 20px;font-size: 0;}
.settingBg .searchGrp {position: relative;margin: 0 0 0 0;max-width: 400px;width: 100%;border-left: 1px solid #e7e9ea;height: 100%;float: right;}
.settingBg .searchGrp .bg {/*z-index: 1;*/position: absolute;opacity: 0.2;width: 100%;height: 100%;border-radius: 20px;}
.settingBg .searchGrp input {/*z-index: 10;*/position: absolute;background: transparent;font-weight: bold;color: #fff;font-size: 14px;}
.settingBg .searchGrp input[type="text"] {width: 100%;height: 100%;color: #232848;padding: 0 0 0 15px;box-sizing: border-box;line-height: 100%;}
.settingBg .searchGrp input[type="button"] {width: 60px;height: 60px;border-radius: 50%;right: -1px;background: url(../images/contents/ic_search_on.png) no-repeat center center;font-size: 0;}
.settingBg .searchGrp input[type="button"]:hover {}
.settingBg .searchGrp input[type="text"]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */color: #232848;opacity: 0.6; /* Firefox */ }
.settingBg .searchGrp input[type="text"]:-ms-input-placeholder { /* Internet Explorer 10-11 */color: #232848; }
.settingBg .searchGrp input[type="text"]::-ms-input-placeholder { /* Microsoft Edge */ color: #232848; }




#wrap #section_ce.hideSetting .grp.settingBg .menuBg  { display:none !important; }
#msgPageGubun .schedule_box {margin-top: 61px;padding-right: 0 !important;padding-left: 0 !important;}
#msgPageGubun.msgPage #listHeader  input[type='checkbox']:not(old) + label {background-color: #c1cfd8 !important;border: 2px solid #c1cfd8 !important;background-size:21px !important;}
#msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color: #36bdff !important;border: 2px solid #36bdff !important;background-size:21px !important;}
.msgPage .content .subUl li.on p, .msgPage .content .subUl li.on span {font-weight: bold;}
#msgPageGubun.msgPage .content #listHeader {padding-right: 60px;}
div#grp_Step {z-index: 10000000;display: inline-block !important;height: 45px;top: 9px;position: static;margin-left: 20px;left: 30px;width: auto !important;margin-top: 10px;margin-bottom: 10px;float: right;margin-right: 30px;}
#mainUlHeader > li { margin-top:0 }
#wrap #msgPageGubun.msgPage .content .msgHeader .scsFail .scs input[type='checkbox']:not(old):checked + label {background: #477fff url(../images/contents/checked_fff.png) no-repeat 0px 0px !important;border: 2px solid #477fff !important;background-size: 21px !important;}
#wrap #msgPageGubun.msgPage .content .msgHeader .scsFail .fail input[type='checkbox']:not(old):checked + label {background: #ff3f3f url('../images/contents/checked_fff.png') no-repeat 0 0px  !important;border: 2px solid #ff3f3f !important;background-size: 21px !important;}

#container #section_ce #mngGrpScroll .schedule_box {padding-top: 0;max-width: 100%;padding: 0;}

#container #section_ce #memScroll .schedule_box {max-width: 100%;padding-left: 0;padding-right: 0;}




/* 그룹 */
.fave_border { opacity:0.8 !important; box-shadow: 0 0 0 rgba(0,0,0,0.3) !important; }
.fave_border_on { border:1px solid #ffa70e !important; box-shadow: 0 13px 30px rgba(255, 167, 14,0.5) !important; }
.section_ce_fix.grpSection.dpOne .dpOne {display: none;}
.section_ce_fix.grpSection.dpTwo .dpTwo {display: none;}
.grpSection .btns {float: right;}
.grpSection .btns input {margin-top: -5px;}
.btns .dpTwo {width: 40px;height: 40px;background: url(../images/contents/ic_dpTwo.png) no-repeat center center;font-size: 0;}
.btns .dpOne {width: 40px;height: 40px;background: url(../images/contents/ic_dpOne.png) no-repeat center center;font-size: 0;}
ul.grpUserList li.waiting .before {display: block;background: rgba(255,255,255, 0.5);height: 100%;}
ul.grpUserList li.waiting .before:hover {background: rgba(255,255,255, 0.8);}
ul.grpUserList li.waiting:hover .before {height: 180px;background: rgba(255,255,255, 0.6);}
ul.grpUserList li.waiting:hover .before span {height:auto;color: #000;}
ul.grpUserList li.waiting:hover .before span.line {height:1px;background: #000;}
ul.grpUserList li.waiting:hover .before input {bottom: 24px; }
ul.grpUserList li.waiting .before span.date {font-size: 0;height: 0;width: 0;padding: 0;position: absolute;bottom: 24px;}
ul.grpUserList li.waiting:hover input {display:none}
ul.grpUserList li.waiting:hover > .before > span.date {color: #18284e;cursor: pointer;width: 80%;display: inline-block;line-height: 40px;text-align: center;font-size: 12px;font-weight: bold;border-radius: 25px;margin: 0 12px;background: #fff;/* border: 1px solid #f1f3f5; */position: absolute;height: 40px;bottom: 24px;left: 50%;margin-left: -116px;}
ul.grpUserList li.waiting:hover > .before > span.date:hover :after {content: '가입요청 취소';display: block;position: absolute;top: 0;color: #fff;background: #ff5555;width: 100%;height: 100%;border-radius: 50px;}
ul.grpUserList li.waiting:hover span.date em {color: #18284e;}
ul.grpUserList li.waiting {opacity: 1;}
ul.grpUserList li ul {display: none;}
ul.grpUserList li.waiting .before span {height:0;}
ul.grpUserList li.waiting .before input {bottom: auto;bottom:50%;box-shadow: none;bottom: auto;top: 0;width: 100%;left: 0;margin-left: 0;border-radius: 5px 5px 0 0;border: 0;height: 25px;line-height: 25px;font-size: 12px;}
ul.grpUserList li {width: 290px;height: 264px;float: left;display: inline-block;margin: 0 15px;border: 1px solid #e7e9ea;border-radius: 5px;transition: 0.2s;margin-bottom: 30px;position: relative;opacity: 0.6;}
.grpUserList span.placeNm {position: absolute;width: 250px;background: white;display: block;left: 20px;height: auto;overflow: hidden;font-size: 16px;white-space: nowrap;text-overflow: ellipsis;font-weight: bold;padding: 20px 30px 15px 0;/* border-bottom: 1px solid #f1f3f5; */bottom: 33px;border-radius: 0 0 4px 4px;box-sizing: border-box;}
ul.grpUserList li .img {width: 100%;background: url(../images/contents/groupImg.png) no-repeat center center;display: block;background-size: cover;font-size: 0;height: 180px;border-radius: 5px 5px 0 0;}
#grpScroll .content {padding-top: 30px;margin-top: 65px;clear: both;float: left;}
ul.grpUserList {float: left;margin-left: -20px;}
ul.grpUserList li.add:hover { box-shadow:none; }
ul.grpUserList li:hover {transition: 0.2s;opacity: 1;}
/* ul.grpUserList li.signedUp:hover, ul.grpUserList li.created:hover {box-shadow: 0 10px 25px rgba(0,0,0,0.1);} */
.grpUserList span.cl {width: 100%;height: 10px;border-radius: 4px 4px 0 0;font-size: 0;display: block;position: absolute;right: 20px;bottom: 21px;z-index: 2;width: 16px;height: 16px;border-radius: 50%;}
.grpUserList span.pos {width: 37px;height: 37px;display: inline-block;background-size: 19px !important;font-size: 0;border-radius: 50%;position: absolute;right: 10px;bottom: 42px;display: none;}
.grpUserList span.pos.king {background: #fff url(../images/contents/ic_king.png) no-repeat center center;display: block;}
.grpUserList span.pos.manager {background: #fff url(../images/contents/ic_manager.png) no-repeat center center;display: block;}
.grpUserList a {display: block;width: 100%;height: 100%;}
.grpUserList li.add a {font-size: 20px;font-weight: bold;transition: 0.2s;color: #a7a7a7;border-radius: 5px;font-size: 21px;font-weight: bold;text-align: center;height: 100%;line-height: 264px;width: 100%;background: #f1f3f5;border: 0 none;padding: 0;float: left;margin: 0;}
.grpUserList li.add {background: #fff;box-sizing: border-box;border: 2px dashed #e7e9ea;opacity: 1;}
.grpUserList li.add:hover a {background: #fff;color: #477fff;}
span.faved {background: #fff url(../images/contents/faveStar.png) no-repeat center center;font-size: 0;width: 35px;height: 35px;border-radius: 50%;display: block;position: absolute;right: 15px;top: 15px;border: 1px solid #e7e9ea;background-size: 25px;}
span.faved.off {background: #fff url(../images/contents/faveStar_off.png) no-repeat center center;background-size: 25px;transition: 0.2s;opacity: 0;}
span.faved:hover {box-shadow:0 3px 5px rgba(0,0,0,0.1)}
/* span.faved:hover {background: #fff url(../images/contents/faveStar_off.png) no-repeat center center;background-size: 25px;transition: 0.2s;}
span.faved.off:hover {background: #fff url(../images/contents/faveStar.png) no-repeat center center;background-size: 25px;transition: 0.2s;opacity: 0;} */
ul.grpUserList li.created:hover .faved.off, ul.grpUserList li.signedUp:hover .faved.off {opacity: 1;}
ul.grpUserList li.created.on .faved.off, ul.grpUserList li.signedUp.on .faved.off { opacity:1; }
#grpScroll h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
#grpScroll .grp.settingBg {height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
#grpScroll span.num {margin-left: 10px;color: #477fff;}
#makeGrpScroll .createNewGroup {margin-left: -39px;}
.settingBg .selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
.btns.fr {margin-right: 30px;}
.btns.fr .input_btn {margin: 11px 0 0 15px;}
#makeGrpScroll .content {margin-top: 65px;}
.grpUserList em {font-weight: bold;color: #477fff;font-style: normal;}
.grpUserList span.people {font-weight: bold;position: absolute;bottom: 23px;left: 20px;}
.grpUserList span.team {font-weight: bold;position: absolute;bottom: 23px;left: 135px;}
.grpUserList li:hover input.del {display:none;}
.grpUserList li.created:hover input.del, .grpUserList li.signedUp:hover input.del {display:block;}
.grpUserList input.del {color:#fff;font-weight:bold;display:none;position: absolute;top: -15px;left: -15px;width: 35px;height: 35px;border-radius: 50%;font-size:0;position: absolute;background: #ff6363 url(../images/contents/ic_delImg.png) no-repeat center center;background-size: 14px;z-index: 2;}
.grpUserList li.signedUp input.del {font-size: 0;font-weight:bold;background: #445667;color:#fff;transition: 0.1s;background: #445667 url(../images/contents/ic_delImg.png) no-repeat center center;background-size: 14px;}
.grpUserList li.signedUp input.del:hover {background: #2f4254;font-size:12px;width: 70px;border-radius: 25px;transition: 0.1s;}
.grpUserList li input.del:hover {background: #ff3636;font-size:12px;width: 70px;border-radius: 25px;transition: 0.1s;}
ul.grpUserList li.created {opacity: 1;}
ul.grpUserList li.signedUp {opacity: 1;}

.grpUserList .before {display: none;position: absolute;top: 0;text-align: center;width: 100%;box-sizing: border-box;padding: 30px 30px 84px;height: 180px;background: rgba(24, 36, 64, 0.5);border-radius: 5px 5px 0 0;z-index: 3;}
.grpUserList li:hover .before {display: block;}
.grpUserList .before .title {font-size: 18px;font-weight: bold;display: block;overflow: hidden;margin-bottom: 17px;text-align: left;text-overflow: ellipsis;white-space: nowrap;}
.grpUserList .before .descript {font-size: 14px;font-weight: bold;overflow: hidden;line-height: 22px;margin-bottom: 20px;text-overflow: ellipsis;display: block;text-align: left;/* height: 50px; */max-height: 69px;}
.grpUserList .before span {font-size: 14px;font-weight: bold;clear: both;float: left;width: 100%;color: #fff;margin-bottom: 30px;}
.grpUserList .before span.line {width: 100%;display: block;height: 1px;background: #fff;opacity: 0.2;margin-bottom: 15px;}
.grpUserList .before input {cursor:pointer;width: 50%; border:1px solid #f1f3f5;/* float: left; */display: inline-block;line-height: 35px;background: none;text-align: center;font-size: 14px;font-weight: bold;border-radius: 25px;margin: 0 12px;background: #f1f3f5;}
.grpUserList .before input.yes {background:#fff;border:1px solid #f1f3f5;color:#477fff;position: absolute;bottom: 24px;left: 50%;margin-left: -70px;transition: 0.1s;}
.grpUserList .before input.yes:hover {background: #477fff;border-color: #477fff;color: #ffffff;transition: 0.1s;box-shadow: 0 5px 10px rgba(107, 153, 255, 0.5);}
.grpUserList span.date {display: none;}
.grpUserList li.waiting .before input {background: #ffa70e;color: #fff;border-color: #ffa70e;cursor: default;}
.grpUserList li.waiting .before input:hover {background: #ffa70e;color: #fff;border-color: #ffa70e;box-shadow: none;}
ul.grpUserList li span.num { display:none; }
li.withTeams .bg { display:none; }

/* dpOne 디스플레이 */
.dpOne ul.sortHeader .people, .dpOne ul.sortHeader .placeNm, .dpOne ul.sortHeader .team { display:none }
.dpOne .sortHeader > li {margin-left: 20px !important;}
.dpOne .sortHeader > li:hover > a.sort {padding-left: 0;}
.dpOne .sortHeader > li:hover a {box-shadow: none !important;}
.dpOne ul.sortHeader {height: 60px;position: fixed;z-index: 1000000;background: none;right: 90px;border-bottom: 0;}
.dpOne ul.sortHeader .dropdown {position: absolute;display: none;}
.dpOne ul.sortHeader .num {display: none;}
.dpOne li.notYet.withTeams.on > .before, .dpOne li.waiting.withTeams.on > .before {display: none;}
.dpOne li.withTeams:hover > a > .team em {display: inline-block;border: 2px solid #477fff;margin-left:0px;line-height: 25px;background: #fff;height: 25px;border-radius: 5px;position: absolute;width: 50px;text-align: center;left: 0;bottom: -9px;transition: 0.1s;}
.dpOne li.withTeams:hover > a > .team em:hover {background: #477fff;color: #fff;}
.dpOne li.withTeams.on .bg {cursor:pointer;display:block;width:100%;height:100%;position:absolute;top:0;left:0;background: rgba(24, 36, 64, 0.5) url(../images/contents/ic_close_fff.gif) no-repeat center 20px;z-index:100;border-radius: 5px;}
.dpOne li.withTeams.on .bg:hover {transition:0.2s;background: rgba(24, 36, 64, 0.7) url(../images/contents/ic_close_fff.gif) no-repeat center 20px;}
.dpOne li.withTeams.on:hover > a > .del {display:none;}
.dpOne li.withTeams.on ul {display: block;position: absolute;bottom: 0;left: 0;width: 100% !important;max-height: 203px !important;min-height: 100px;overflow-y: auto;background:#fff;z-index:1000000000000000;border-radius: 0 0 5px 5px;}
.dpOne li.withTeams.on ul li {margin: 0;height:50px;overflow:hidden;border-radius:0;width: 100%;border: 0;border-top: 1px solid #f1f3f5;}
.dpOne li.withTeams.on ul li span { font-size:12px; position:static; float:left; width:auto; }
.dpOne li.withTeams.on ul li span.cl {width:7px;height:7px;font-size:0;margin: 22px 10px 0 15px;}
.dpOne li.withTeams.on ul li input { position:static; }
.dpOne li.withTeams.on ul li .img { display:none; }
.dpOne li.withTeams.on ul li span.placeNm {width: 170px;padding-right: 10px;background:none;}
.dpOne li.withTeams.on ul li span.people {display:none;}
.dpOne li.withTeams.on ul li span.team {display: none;}
.dpOne li.withTeams.on ul li input {right: 0;width: 70px;height: 50px;display: block;border-radius: 0;font-size: 12px;color: #000;background: none;left: auto;top: 0;position: absolute;}
.dpOne li.withTeams.on ul li:hover input {/* display: block !important; */background: none;color: #000;}
.dpOne li.withTeams.on ul li:hover input.del {color: #ff5f5f !important;width: 70px;}
.dpOne li.withTeams.on ul li input.del.up {color: #477fff !important;width: 70px;}
.dpOne li.withTeams.on ul li.waiting, .dpOne li.withTeams.on ul li.waiting:hover a {background: #fffaf0 !important;}
.dpOne li.withTeams.on ul li.waiting input.del {display: none;}
.dpOne li.withTeams.on ul li.waiting .before {background: none;height: 50px;}
.dpOne li.withTeams.on ul li.waiting .before .descript {display: none;}
.dpOne li.withTeams.on ul li input.yes {margin-right: 0;color: #ffa70e;display: none;}
.dpOne li.withTeams.on ul li.waiting:hover span.date:hover after { content:'ì·¨ì†Œ'; display:block; position:absolute;width:100%; height:100%; top:0; left:0;background:red; }
.dpOne ul.grpUserList li li.waiting:hover .before span.date:after {display: block;content: '취소';position: absolute;width: 70px;right: 0;top: 0;margin-bottom: 0;font-size: 12px;height: 30px;}
.dpOne ul.grpUserList li li.waiting:hover > .before > span.date {position: absolute;top: 6px;font-size: 0;width: 70px;text-align: center;right: 0;color: #ff5b5b;margin: 0;left: auto;background: #fffaf0;}
.dpOne ul.grpUserList li li.waiting:hover > .before > span.date:hover :after {background: none !important;}
.dpOne li.withTeams.on ul li {/* color: #000; */opacity: 1;}
.dpOne li.withTeams.on ul li.notYet {opacity: 0.6;}
.dpOne li.created.withTeams.on ul li:hover a, .dpOne li.signedUp.withTeams.on ul li:hover a {background: #f1f3f5;}
.dpOne li.created.withTeams.on ul li.notYet:hover a, .dpOne li.signedUp.withTeams.on ul li.notYet:hover a {background: #fff !important;cursor: default;}
.dpOne li.created.withTeams.on ul li.notYet:hover, .dpOne li.signedUp.withTeams.on ul li.notYet:hover {opacity: 1;}
.dpOne li.withTeams.on ul li.notYet:hover {opacity: 1;}
.dpOne li.withTeams.on ul li.waiting:before {content: '';display: block;position: absolute;left: 0;top: 0;height: 100%;width: 3px;background: #ffa70e;}
.dpOne li.withTeams.on ul li:first-child {border-top: 0 none;}
.dpOne .grpUserList em { margin-left:5px; }
.dpOne li.withTeams.on ul li.waiting input.yes {display: block;}
.dpOne ul.grpUserList li li .before  {display:none}
.dpOne ul.grpUserList li li.waiting .before { display:block }
.dpOne ul.grpUserList li li > a > span.faved {display:none;}




/* dpTwo 디스플레이 */
.dpTwo ul.grpUserList li.created:hover > a > .faved, .dpTwo ul.grpUserList li.signedUp:hover > a > .faved {width:30px; height:30px; background-color:#fff; margin-top:-5px;transition:0.2s;}
.dpTwo .fave_border { opacity:0.7 !important; }
.dpTwo .fave_border_on { border:0 none !important; border-top:1px solid #f1f3f5 !important;}
.dpTwo .fave_border_on:after { z-index:10000; content:''; display:block; /* background: rgba(255, 167, 14, 0.1); */ border-top:1px solid #FFA70E;box-sizing:border-box;border-bottom:1px solid #FFA70E;width:100%; height:100%; position:absolute; left:0; top:0; }
.dpTwo .sortHeader, .dpTwo .sortHeader li { transition:0s !important; }
.dpTwo .sortHeader > li > a, .dpTwo .sortHeader > li > span  { height:65px;line-height:65px;font-weight:bold; }
.dpTwo .sortHeader > li.num  { text-align:center; }
.dpTwo .sortHeader > li.num > span { display:inline-block; }
.dpTwo .num {width: 10%;}
.dpTwo .placeNm {width: 40%;}
.dpTwo .people {width:12%}
.dpTwo .team {width:12%}
.dpTwo .pos {width:13%}
.dpTwo .status {width: 13%;}

.dpTwo #grpScroll .content {margin-top: 130px;padding-top: 0;border-top: 1px solid #e7e9ea;}
.dpTwo ul.sortHeader {height: 60px;width: 100%;background: #f1f3f5;margin-top: 65px;transition: 0.1s;z-index: 10;min-width: 708px !important;position: fixed;top: 64px;width: 100% !important;margin-left: 0 !important;padding-left: 261px !important;padding-right: 60px;left: 0!important;height: 65px;box-sizing: border-box;}
.dpTwo ul.sortHeader > li {float: left;position: relative;}
.dpTwo ul.sortHeader .dropdown {display:none;position: absolute;}
#container #section_ce .dpTwo .schedule_box {padding-left: 0;padding-right: 0;}
.dpTwo ul.grpUserList li {width:100%;line-height: 65px;margin: 0;height: 65px;transition: 0s;border:0 none;background: #ffffff;opacity: 1;border-top: 1px solid #f1f3f5;position: relative;}
.dpTwo ul.grpUserList > li:last-child { border-bottom:1px solid #f1f3f5; }
.dpTwo ul.grpUserList li.created, .dpTwo ul.grpUserList li.signedUp { border-top:1px solid #f1f3f5 }
.dpTwo ul.grpUserList {margin-left: 0;transition: 0s;}
.dpTwo .content, .dpTwo .grpUserList {transition: 0s;width: 100%;}
.dpTwo ul.grpUserList li .img {transition: 0s;width: 60px;height: 40px;border-radius: 5px;font-size: 0;position: absolute;margin-top: 12px;left: 30px;margin-left: 10.5%;border: 1px solid #f1f3f5;top: 0px;}
.dpTwo ul.grpUserList li > a > span {transition: 0s;position: static;float: left;padding: 0;color: #acacbb;font-size: 12px;}
.dpTwo ul.grpUserList li > input {position: absolute;transition: 0s;float: right;margin: 0;top: 0;left: auto;right: 0;/* display: block; */}
.dpTwo ul.grpUserList li.add {position: fixed;bottom: 50px;right: 100px;z-index: 10000;width: 65px;height:65px;line-height: 65px;border-radius: 50%;border:0 none;transition: 0s;background:none;}
.dpTwo ul.grpUserList li.add a {color:#fff;transition: 0s;opacity: 1;z-index: 2;font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px;background: #477fff url(../images/contents/btn_add_fff.png) no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important;}
.dpTwo ul.grpUserList li > a > span.cl {font-size:0;width: 8px;height: 8px;margin-top: 23px;position: absolute;left: 0px;margin-left: 10.5%;z-index: 1;top: 6px;}
.dpTwo ul.grpUserList li > a > span.pos {background: none;border: 0;width: 13%;font-weight:bold;line-height: 65px;height: 65px;display: block;}
.dpTwo ul.grpUserList li > a > span.faved {position: absolute;left: 11px;top: 22px;font-size: 0;background-size: 20px;border: none;width: 20px;background-color: transparent;height: 20px;}
.dpTwo ul.grpUserList li {box-shadow: none !important;border-radius: 0;overflow: hidden;}
.dpTwo ul.grpUserList li.waiting {background: #fff;border-top: 1px solid #f1f3f5;/* background: #fffaf0; */}
.dpTwo ul.grpUserList > li.waiting:hover > a:hover {background: #fffaf0;}
.dpTwo ul.grpUserList li.waiting:before {content: '';display: block;width: 3px;height: 65px;position: absolute;background: #ffa70e;left: 0;top: 0;}
.dpTwo ul.grpUserList li.waiting .del {color: #f39a00 !important;}
.dpTwo ul.grpUserList li.created, .dpTwo ul.grpUserList li.signedUp {background:#fff;}
.dpTwo ul.grpUserList li.on ul li {background: #Fff;opacity: 1;border-top: 1px solid #f1f3f5;}
.dpTwo li.withTeams.on  > a {border-bottom: 1px solid #f1f3f5;height: 65px;}
.dpTwo ul.grpUserList li.on {height: auto;border-top: 1px solid #aab1b5;border-bottom: 1px solid #aab1b5;transition: 0.2s;}
.dpTwo ul.grpUserList > li.withTeams.on > a > .team em {color: #fff;content: 'ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â«ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â°';background: #477fff;width: 50px;display: inline-block;line-height: 30px;border-radius: 5px;content: 'ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â«ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â°';border: 2px solid #477fff;transition:0.1s;text-align: center;margin-top: 16px;}
.dpTwo ul.grpUserList > li.withTeams.on > a > .team em:hover { background:#477fff; color:#fff; }
.dpTwo ul.grpUserList > li.withTeams.on:hover > a > .team em, .dpTwo ul.grpUserList > li.withTeams.on> a > .team em {background:#477fff;color: #fff !important;}
.dpTwo ul.grpUserList li.withTeams:hover > a > span.team em {width: 50px;display: inline-block;line-height: 30px;border-radius: 5px;content: 'ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â«ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â°';transition:0.1s;margin-top: 16px;border: 2px solid #477fff;text-align: center;color: #477fff !important;}
.dpTwo ul.grpUserList li.withTeams:hover > a > span.team em:hover { color:#fff !important; background:#477fff; }
.dpTwo ul li:hover > a {background: #f1f3f5;transition:0.2s;}
.dpTwo ul li > a {transition:0.2s;}
.dpTwo ul.grpUserList li .before {height: 65px;padding: 0;right: 0;width: 13%;border: 0 !important;left: auto;display: block;line-height: 65px;border-radius: 0 !important;background: none;}
.dpTwo ul.grpUserList li .before input {top: 0;width: 100px;text-align: left;margin-left: 0;border-radius: 0;left: 0;background: none !important;padding: 0;box-shadow: none !important;margin-right: 0;margin-top: 10px;line-height: 45px !important;height: 45px !important;color: #477fff !important;border: none;font-size: 12px;transition: 0s;}
.dpTwo ul.grpUserList li.waiting .before input {left: 0;top: 0;height: 65px;width: 100%;text-align: left;margin-left: 0;border-radius: 0;margin-right: 0;color: #f1a619 !important;font-size: 12px;background: #fff;/* display: none; */}
.dpTwo ul.grpUserList li .before span {font-size: 12px;position: static;float: left;display: inline-block;}
.dpTwo ul.grpUserList li.waiting:hover span.date em {position: absolute;width: 100%;display: inline-block;line-height: 20px;top: 34px;left: 0;text-align: left;color: #000;}
.dpTwo ul.grpUserList li.waiting .before {background: rgba(255,255,255, 0.0);}
.dpTwo ul.grpUserList li.waiting:hover .before {background: none;height: 65px;}
.dpTwo ul.grpUserList li.waiting:hover .before .date {color: #000;text-align: left;margin-left: 0;bottom:0;left: 0;height: 65px;line-height: 49px !important;}
.dpTwo ul.grpUserList ul li.waiting .before input {height: 55px;margin-top: 6px !important;}
.dpTwo ul.grpUserList ul li .before {height: 55px;}
.dpTwo ul.grpUserList ul li.waiting:hover .before {height: 55px;}
.dpTwo ul.grpUserList ul li.waiting:hover .before .date {height: 55px;line-height: 38px !important;}
.dpTwo ul.grpUserList ul li.waiting:hover .before .date em {top: 27px;}
.dpTwo ul.grpUserList li .before span.line {display: none;}
.dpTwo ul.grpUserList li .before span.descript {position: absolute;bottom: -12px;margin-left: -10%;padding-left: 0;left: 0;box-sizing: border-box;display: none;}
.dpTwo ul.grpUserList li .before span.title {position: absolute;padding-left: 30px;font-size: 14px;box-sizing: border-box;left: 0;top: -10px;bottom: auto;margin-bottom: 0;}
.dpTwo ul.grpUserList li:hover span.date {display:none;}
.dpTwo ul.grpUserList li .before .date {display: none;}
.dpTwo ul.grpUserList li.waiting:hover span.date {/* display:block; */position: absolute;width: 100px;right: 0;line-height: 33px !important;top: 0;bottom: 9px;background: transparent;border: 0;color: #fff;}
.dpTwo ul.grpUserList li.waiting:hover span.date em {color: #000;}
.dpTwo ul.grpUserList li > a > span.placeNm {width: 40%;padding-left: 115px;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;background: none;white-space: nowrap;font-size: 14px;color: #acacbb;}
.dpTwo ul.grpUserList li > a > span.people, .dpTwo ul.grpUserList li > a > span.team {width: 12%;font-size: 0;height: 65px;line-height: 65px;}
.dpTwo ul.grpUserList li > a > span.people em, .dpTwo ul.grpUserList li > a > span.team em {font-size: 12px;color: #acacbb;}
.dpTwo ul ul.grpUserList li .img {width: 55px;height: 35px !important;}
.dpTwo ul.grpUserList ul li .img {width: 55px;height: 35px;top: 2px;left: 38px;}
.dpTwo ul.grpUserList ul li > a > span.placeNm {font-size: 12px;}
.dpTwo ul.grpUserList li span.num {display:block;font-size: 12px !important;width: 10%;margin-left: 0 !important;text-align: center;font-weight: bold;color: #acacbb  !important;}
.dpTwo .grpUserList li input.del {top: 0;right: 0;position: static;float: left;width: 13%;width: 90px;border-radius: 0;height: 65px;text-align: left;font-size: 12px;background: none;color: #000;display: none;}
.dpTwo .grpUserList li.signedUp input.del, .dpTwo .grpUserList li.created input.del {display: block;}
.dpTwo .grpUserList li ul input.del {right: 0;left: auto;height: 55px !important;}
.dpTwo ul.grpUserList ul > li { line-height:55px; height:55px }
.dpTwo ul ul.grpUserList li .img {width: 55px;height: 35px !important;}
.dpTwo ul.grpUserList ul li .img {width: 52px;height: 35px;top: -3px;left: 37px;}
.dpTwo ul.grpUserList ul li > a > span.cl {left: 11px;top: 0px;}
.dpTwo ul.grpUserList ul li > a > span.placeNm {font-size: 12px;line-height: 55px;}
.dpTwo ul.grpUserList ul li > a > span.pos {line-height: 55px;height: 55px;}
.dpTwo ul.grpUserList ul li > a > span.people, .dpTwo ul.grpUserList ul li > a > span.team {height: 55px;line-height: 55px;}
.dpTwo ul.grpUserList li ul {display: block;}
.dpTwo ul.grpUserList li ul li:last-child {margin-bottom: 15px;}
.dpTwo ul.grpUserList li.created a span, .dpTwo ul.grpUserList li.signedUp a span {color: #000;}
.dpTwo ul.grpUserList li.created span.num, .dpTwo ul.grpUserList li.signedUp span.num {color: #000 !important;}
.dpTwo ul.grpUserList li.created a  span.people em, .dpTwo ul.grpUserList li.signedUp a span.team em,.dpTwo ul.grpUserList li.signedUp a span.people em {color: #000 !important;}
.dpTwo ul.grpUserList li.created > a > span.team em { color: #000 !important; }
.dpTwo ul.grpUserList li.signedUp > a > span.people em { color: #000 !important; }
.dpTwo .grpUserList li input.del:hover {width: 13%;color: #ff3535;background: none;}
#wrap .dpTwo ul.grpUserList li.notYet a span, #wrap .dpTwo ul.grpUserList li.notYet span em {color: #acacbb !important;}
#wrap .dpTwo ul.grpUserList li.notYet a .del.up, .dpTwo .grpUserList li input.del.up {color: #477fff !important;transition: 0.2s;margin-top: 10px;width: 100px;border-radius: 20px;height: 35px !important;}
.dpTwo .grpUserList li input.del.up {display: block;}
#wrap .dpTwo ul.grpUserList li.notYet.withTeams:hover span.team em {color: #477fff !important;}
#wrap .dpTwo ul.grpUserList li.notYet.withTeams span.team:hover em:hover, #wrap .dpTwo ul.grpUserList li.notYet.withTeams.on span.team em {color: #fff !important;}
#wrap .dpTwo ul.grpUserList li.notYet.withTeams.on   li span.team em {color: #acacbb !important;}
#wrap .dpTwo ul.grpUserList li.notYet .img,#wrap .dpTwo ul.grpUserList li.waiting .img, #wrap .dpTwo ul.grpUserList li.notYet .cl, #wrap .dpTwo ul.grpUserList li.waiting .cl {opacity: 0.5;}
.dpTwo ul.grpUserList li.waiting:hover .before > .date {display: none;}
.dpTwo ul.grpUserList li:hover > a {background: #fff;}
.dpTwo ul.grpUserList li.waiting:hover > .before > .date {display: block;}
.dpTwo ul.grpUserList li.waiting:hover .before > input {/* display: block; */}
.dpTwo ul.grpUserList li > a:hover {background: #f1f3f5;}
.dpTwo ul.grpUserList li li.waiting:hover > a {background: #fffaf0;}
.dpTwo ul.grpUserList li.waiting li.waiting:hover .before input {display: none;}
.dpTwo ul.grpUserList > li.waiting:hover > .before input {display: none;}
.dpTwo ul.grpUserList li.notYet:hover > a:hover {background: #fff;}
#wrap .dpTwo ul.grpUserList li.notYet:hover > a span, #wrap .dpTwo ul.grpUserList li.notYet:hover > a span.team, #wrap .dpTwo ul.grpUserList li.notYet:hover > a span.people em {color: #000 !important;}
#wrap .dpTwo ul.grpUserList li.notYet:hover > a .img, #wrap .dpTwo ul.grpUserList li.notYet:hover > a .cl {opacity: 1;}
.dpTwo ul.grpUserList li.waiting:hover > a > span, .dpTwo ul.grpUserList li.waiting:hover > a > span.num, .dpTwo ul.grpUserList li.waiting:hover > a > span.people em {color: #000 !important;}
.dpTwo ul.grpUserList li.waiting:hover > a > span.img, .dpTwo ul.grpUserList li.waiting:hover > a > span.cl {opacity: 1 !important;}
.dpTwo ul.grpUserList li.notYet:hover a {cursor: default;}
#wrap .dpTwo ul.grpUserList li.withTeams.notYet span em, .dpTwo ul.grpUserList li.waiting span em {cursor: pointer;}
.dpTwo ul.grpUserList li.waiting:hover a {cursor: default;}
.dpTwo .grpUserList li.waiting > a, .dpTwo .grpUserList li.waiting > a:hover, .dpTwo ul.grpUserList li.waiting:hover > a {background: #fffaf0;}
.dpTwo ul.grpUserList li.waiting span, .dpTwo ul.grpUserList li.waiting span.num, #wrap .dpTwo ul.grpUserList li.waiting span.people em {color: #acacbb !important;}
#wrap .dpTwo ul.grpUserList li.waiting:hover > a span.people em {color: #000 !important;}
#wrap .dpTwo ul.grpUserList li.waiting > a > input {display: none;}
.dpTwo ul.grpUserList > li.waiting.on:hover li .before input {display: block;}
.dpTwo ul.grpUserList > li.waiting.on:hover li:hover .before input {display: none;}
#wrap .dpTwo .grpUserList li.notYet:hover > a > .del.up {width: 90px;height: 35px !important;border: 2px solid #477fff;line-height: 35px !important;padding: 0;border-radius:20px;text-align:center;margin-top:10px;line-height: 30px !important;}
#wrap .dpTwo .grpUserList li.notYet:hover .del.up:hover {background:#477fff;color:#fff !important;}
#wrap .dpTwo ul.grpUserList > li.notYet:hover > .before > .del.up {border: 2px solid #477fff;}
.dpTwo .grpUserList > li.notYet > .before > input.del.up {margin-top: 15px;}
.dpTwo .grpUserList > li.notYet:hover > .before > input.del.up {text-align: center;margin-top: 13px;}
.dpTwo li.notYet:hover > .before > .yes {border: 2px solid #477fff;text-align:center;border-radius:30px;height:35px !important;line-height:35px !important;margin-top:14px;width:90px;}
.dpTwo li.notYet:hover > .before > .yes:hover {background: #477fff !important;color:#fff !important;}
#wrap .dpTwo ul.grpUserList li.waiting:hover > .before > .date:hover, #wrap .dpTwo ul.grpUserList li.waiting:hover > .before > .date:hover em {color: transparent !important;}
#wrap .dpTwo ul.grpUserList li.waiting:hover  .before > span.date:hover :after {top:-19px; text-align:center; width:110px; height:35px !important; line-height:35px; }
.dpTwo ul.grpUserList li li > a > span.faved {top: 17px;}
.dpTwo ul.grpUserList li li:hover > a > span.faved {left: 12px;width: 26px;height: 26px;background-color: #fff;transition: 0.1s;top: 14px;}


/* 그룹쪽 이미지, 추후삭제 */
#wrap .grpUserList .g1 .img {background-image: url(../images/footer/g11.jpg)}
#wrap .grpUserList .g2 .img {background-image: url(../images/footer/g6.jpg)}
#wrap .grpUserList .g3 .img {background-image: url(../images/footer/g2.jpg)}
#wrap .grpUserList .g4 .img {background-image: url(../images/footer/g12.jpg)}
#wrap .grpUserList .g5 .img {background-image: url(../images/footer/g10.jpg)}
#wrap .grpUserList .g6 .img {background-image: url(../images/footer/g8.jpg)}
#wrap .grpUserList .g7 .img {background-image: url(../images/footer/g1.jpg)}
#wrap .grpUserList .g8 .img {background-image: url(../images/footer/g3.jpg)}
#wrap .grpUserList .g9 .img {background-image: url(../images/footer/g4.jpg)}
#wrap .grpUserList .g10 .img {background-image: url(../images/footer/g5.jpg)}
#wrap .grpUserList .g11 .img {background-image: url(../images/footer/g7.jpg)}

/* 업무 리스트 */
#workListScroll .content {padding-top: 26px;}
.workList .schLi .schBtn { right: 20px; }
#wrap .fc-right .selectboxWrap:hover { background-color:#f1f3f5 !important; }
#wrap .fc-right .selectboxWrap label {font-weight: bold;color: #4c4c4c !important;}
#wrap .fc-right .selectboxWrap {float:right;border-radius: 5px;width: 70px;height: 40px;line-height: 40px;margin-top: 8px;margin-right: -9px;transition: 0.1s;border: 1px solid #e1e4e6;background: url(../images/side_area/bt_open.png) no-repeat 41px center;}
#workListScroll .settingBg .left {float: left;margin-left: 30px;margin-right: 0;}
#workListScroll .settingBg .left .grp input {position: static;width: 125px;padding-right:20px;text-align: left;margin-right: 13px;background: #fff url(../images/side_area/bt_open.png) no-repeat right center;}
#workListScroll .settingBg .left .grp input:hover { color:#477fff; }
#workListScroll .settingBg .left .grp input:focus {color:#477fff; background: #fff url(../images/side_area/bt_close.png) no-repeat right center;}
#workListScroll .settingBg .left .grp .bg {display: none;}
#workListScroll .settingBg .left .grp {width: auto;min-width: auto !important;max-width: none !important;padding: 0;margin: 0;float: left;}
#workListScroll .settingBg .seper {background:#dbdfe0;float: left;margin-top: 20px;height: 2px;margin-right: 20px;}
#workListScroll .settingBg .line {display:block;width: 2px;height: 15px;margin-top: 25px;margin-right: 0;background: #e7e9ea;float:right;}
#workListScroll .grp1, #workListScroll .grp2, #workListScroll .grp3 {float:right;width: auto;padding: 0 20px;position:relative;}
#workListScroll .grp1:hover input, #workListScroll .grp2:hover input, #workListScroll .grp3:hover input {color: #477fff;transition: 0.2s;}
.grp1 input[type="button"], .grp2 input[type="button"], .grp3 input[type="button"] {font-size: 12px;font-weight: bold;background: #fff url(../images/side_area/bt_open.png) no-repeat right center;line-height: 65px;width: auto;float: left;text-align: left; padding-right:28px;}
.grp1 input[type="button"]:focus, .grp2 input[type="button"]:focus, .grp3 input[type="button"]:focus {background: #fff url(../images/side_area/bt_close.png) no-repeat right center;color:#477fff}
#workListScroll .settingBg .dropdown {top: 66px;}
.sortHeader .dropdown {top: 61px;min-width: 220px;box-shadow: 0 9px 15px rgba(0,0,0,0.2);}
ul#workUl > li { float:left; width:100%; border-bottom: 1px solid #f1f3f5;margin-top: 0;margin-bottom: 0; transition: 0.3s;box-sizing:border-box;}
ul#workUl > li.clicked {background: #fff;border: 2px solid #FFC72F;box-sizing: border-box;transition: 0.3s; margin-bottom: 10px; box-shadow: 0 8px 10px rgba(0,0,0,0.1)}
ul#workUl > li.clicked.paused {border: 2px solid #FF6363;}
ul#workUl > li.clicked.done {border: 2px solid #477fff;}
ul#workUl > li.clicked.finished {border: 2px solid #717684;}
ul#workUl > li.clicked ul { display:block; background: #fff; }
ul#workUl > li > p {display: inline-block;float:left;line-height: 60px;font-weight: bold;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;box-sizing: border-box;padding-right: 25px;color:#232848}
ul#workUl ul {display:none;border-top: 1px solid #f1f3f5;width: 100%;float: left;}
ul#workUl ul li:first-child { border-top:1px solid #f1f3f5; }
ul#workUl ul > li { float:left; width:100%; border-bottom: 1px solid #f1f3f5;margin-top: 0;margin-bottom: 0; }
ul#workUl ul > li > p {display: inline-block;float:left;line-height: 60px;font-weight: bold;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;box-sizing: border-box;padding-right: 20px;}
ul#workUl li.hasUl:hover { cursor:pointer; }
ul#workUl li:hover .worker a.people:hover{ background:#477fff; color:#fff; border: 2px solid #477fff; transition:0.2s; box-shadow:0 3px 8px rgba(0,0,0,0.1); }
ul#workUl li:hover .worker a.people { background:#fff; border: 2px solid #fff; transition:0.2s; }
ul#workUl li:hover .worker a.people:hover span {  color:#fff;  }
ul#workUl li:hover .worker a.people{ display:inline-block; color:#477fff; padding: 0 8px; line-height:30px; border:2px solid #477fff; border-radius:5px; transition:0.2s; margin-left:3px;}
ul#workUl li:hover .worker a.people span {  color:#477fff; }
ul#workUl li .worker a.people span {  color:#477fff; }

ul#workUl li.done {  }
ul#workUl li.done { opacity:0.7;  }
ul#workUl li.done:hover { opacity:1;}
ul#workUl > li.clicked.waiting { border: 2px solid #7d888e }
ul#workUl li:hover .status a:hover{ background:#477fff; color:#fff; border: 2px solid #477fff; transition:0.2s; box-shadow:0 3px 8px rgba(0,0,0,0.1); }
ul#workUl li:hover .status a { background:#fff; border: 2px solid #fff; transition:0.2s; }
ul#workUl li:hover .status a:hover span {  color:#fff;  }
ul#workUl li:hover .status a { display:inline-block; color:#477fff; padding: 0 8px; line-height:30px; border:2px solid #477fff; border-radius:5px; transition:0.2s; margin-left:3px;}
ul#workUl li:hover .status a span {  color:#477fff; }

ul#workUl li.clicked .worker a.people {  display:inline-block; background:#FF6363 !important; color:#fff; border: 2px solid #FF6363 !important;padding: 0 8px; line-height:30px;border-radius:5px; transition:0.2s; margin-left:3px; }
ul#workUl li.clicked .worker a.people span {font-size:0;display:inline-block;width: 17px;height: 20px;background: url(../images/contents/ic_close_fff.gif) no-repeat center center !important;background-size: 13px !important;}
#workUl span.dot {display: inline-block;width: 8px;height: 8px;background: #477fff;float: left;margin-top: 26px;border-radius: 50%;margin-right: 13px;font-size: 0;}
ul#workUl ul > li:hover {background: #f6f9fb;}
ul#workUl > li:hover {background: #f6f9fb;}
ul#workUl > li:hover {cursor: pointer;}
#workUl .status span.bl {color: #477fff;}
ul#workUl > li:hover  .steps { opacity:0.7 }
ul#workUl li .steps { opacity:0.3 }

#workListScroll input[type='checkbox']:not(old) + label {margin-top:15px;display: inline-block;padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid #e5e8ea;background: #e5e8ea url('../images/contents/checked_gray.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
#workListScroll input[type='checkbox']:not(old):checked + label {background: #477fff url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;border: 2px solid #477fff;transition: 0.1s;}
#workListScroll input[type='checkbox']:not(old):checked + label span {background: none;transition: 0.1s;font-weight:bold;}
#workListScroll input[type='checkbox']:not(old) + label span:hover {background: rgba(179, 190, 214, 0.1);transition: 0.1s;}
#workListScroll input[type='checkbox']:not(old):checked + label span:hover {background: none;transition: 0.1s;}
.sortHeader .dropdown li {line-height: 50px;padding: 0 15px;position: relative;box-sizing: border-box;border-top: 1px solid #f1f3f5;}
.sortHeader .dropdown li p {line-height: 50px !important;width:100%;}
.sortHeader .dropdown li p span{display: inline-block;width: 100%;font-size: 12px;line-height: 50px;position: absolute;left: 0;top:0;padding-left: 43px;padding-right: 15px;box-sizing: border-box;float: left;min-width: 200px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.sortHeader .dropdown li input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px; margin-top: 16px;}
.sortHeader .dropdown li input[type='checkbox']:not(old):checked + label {background: #477fff url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;}
.sortHeader .dropdown li label span { position: absolute; left:0;top:0; padding-left: 45px; line-height: 50px; height: 50px; box-sizing:border-box; width:100%; font-size: 12px; height: 100%; padding-right:15px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;color:#7c88a2 }
.sortHeader .dropdown li input[type='checkbox']:not(old):checked + label span { color:#202A39 !important  }
.sortHeader .dropdown li:hover { background:#f7f9fb }
.sortHeader .dropdown li.on { color:#fff !important; }
.sortHeader .dropdown li label span { font-weight: bold; }
.sortHeader .dropdown:hover li label span { color:#7c88a2 }
#workDiv #workUl li > .num {border-left: 3px solid #FFC72F;box-sizing: border-box;text-indent: -1px;transition:0.2s;}
#wrap #workDiv #workUl li:hover > .num { border-width:5px; transition:0.2s;text-indent: -3px; }
#workDiv #workUl li.done > .num, #workDiv #workUl li.done li > .num {border-left: 3px solid #477fff;}
#workDiv #workUl li.waiting > .num, #workDiv #workUl li.waiting li > .num {border-left: 3px solid #717684;}
#workDiv #workUl li.paused > .num, #workDiv #workUl li.paused li > .num {border-left: 3px solid #FF6363;}

#workDiv .num {width: 8%;padding-right: 0;font-weight: bold;}
#workDiv .time {width: 20%;font-weight: bold;}
#workDiv .title {width: 31%;}
#workDiv .addr {width: 20%;display: none;}
#workDiv .worker {width: 16%;}
#workDiv .work {width: 16%;}
#workDiv .status {width: 9%;}
#workDiv .result {width: 8%;display: none;}

.sortHeader > li {position:relative;display: inline-block;float: left;padding: 0;margin: 0 !important;}
.sortHeader > li > a.sort {background:transparent;font-weight:bold;font-size:12px;font-size: 14px !important;margin:0;padding:0;margin-top: -5px;padding-right: 28px;background: url(../images/side_area/bt_open.png) no-repeat center right;}
.sortHeader {line-height: 60px;background: #f2f5f7;height: 60px;border-bottom: 1px solid #e7e9ea;overflow: visible !important;font-size: 13px !important;}
.sortHeader > li:hover > a.sort  { transition:0.1s; font-weight:bold; padding:7px 8px;padding-right: 28px; background: #fff url(../images/side_area/bt_open.png) no-repeat center right; border-radius:5px;margin-top:-3px;}
.sortHeader > li:hover > a.sort:hover { box-shadow:0 5px 5px rgba(0,0,0,0.1); }
.sortHeader > li > a.sort.on { font-weight:bold; padding:7px 8px !important; padding-right: 28px !important; background: #fff url(../images/side_area/bt_close.png) no-repeat center right !important;  border-radius:5px;margin-top:-3px;box-shadow:0 5px 5px rgba(0,0,0,0.1); }
.sortHeader > li > span {font-weight:bold;}
.sortHeader > li.headerSort:hover > span {color:#477fff; }
/* .sortHeader > li.headerSort:hover {color:#477fff; } */

/* dropdown */
.dropdown { z-index:10000; display:none; position:absolute; }
.dropdown.on { display:block; }
.dropdown a {display: block;line-height: 45px;padding: 0 15px;}
.dropdown {background: #fff;border-radius: 5px;box-shadow: 0 5px 10px rgba(0,0,0,0.1);left: 0;width: 100%;top: 93px;}
.dropdown li { position:relative; }
.dropdown a {border-bottom: 1px solid #f1f3f5;}
.dropdown a:hover { background-color:#f1f3f5; font-weight:bold; }
.dropdown a.on:hover { background: #fff; cursor:default; }
.dropdown a.on { font-weight:bold; }
.dropdown a.on:after {display: block;content: '';position: absolute;right: 13px;top: 19px;width: 7px;height: 7px;background: #477fff;border-radius: 50%;}
.grp1 .dropdown li, .grp2 .dropdown li {position: relative;}

div#selectTitleDropdown {width: 410px;left: 280px;top: 60px;}
div#selectTitleDropdown .cl {width: 7px;height: 7px;display: inline-block;border-radius: 50%;margin-right: 10px;}

/* 검색 */
.schLi .gnb_schInbox {display: none;}

/* 업무 설정 */
#workSettingScroll .content {padding-top: 65px;/* overflow: hidden; */clear: both;}
#workSettingScroll .selectTitle span.num { margin-left:10px; color:#477fff; }
#workSettingScroll .settingBg .btn_group { margin-right: 30px;margin-top: 11px; }
#section_ce #workSettingScroll ol li { list-style: none; }
input#addWork {height: 40px;line-height: 40px;border-radius: 5px;background: #fff;border: 1px solid #e7e9ea;color: #35405a;padding: 0 15px;font-size: 14px;font-weight: bold;}
#workSettingScroll ol > li h4 span {margin-left: 20px;opacity: 0.5;}
#section_ce #workSettingScroll .grp.settingBg {height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
#section_ce #workSettingScroll .grp.settingBg h3.selectTitle {position: static;display:inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
#workSettingScroll .content .num {z-index:10000;width: 30px;height: 30px;font-weight: bold;line-height: 30px;border-radius: 50%;border: 1px solid #e7e9ea;margin-right: 16px;display: inline-block;position: absolute;left: -17px;top: -12px;background: #fff;}
#workSettingScroll .content ol {margin-top:30px;height: auto;float: left;width: 100%;}
#workSettingScroll .content ol > li {min-width: 290px;transition:0.2s;position: relative;border-radius: 5px;border: 1px solid #e7e9ea;width: 30.333%;height: 300px;display: inline-block;margin-right: 30px;margin-bottom: 30px;float: left;padding: 0;box-sizing: border-box;}
#workSettingScroll .content ol > li:hover { box-shadow:0 8px 15px rgba(0,0,0,0.1); transition:0.2s; }
#workSettingScroll .content ol > li.crtWork:hover { box-shadow: none; }
#workSettingScroll .content ol > li h4 {font-size: 16px;display: inline-block;width: 100%;padding: 20px 19px 15px;box-sizing: border-box;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;border-bottom: 1px solid #f1f3f5;margin-bottom: 12px;}
#section_ce #workSettingScroll ol ul li {display: block;position: relative;}
#section_ce #workSettingScroll ol li ul li h5 {transition:0.1s;width: 20px;height: 20px;line-height: 20px;font-size:12px;margin-right: 0px;font-weight: bold;display: inline-block;text-align: center;border-radius: 50%;color: #fff;margin-left: 5px;border: 6px solid #fff;position: absolute;top: 0;left: 0;}
#section_ce #workSettingScroll ol ul li .txt {opacity:0.5;font-weight:bold;display: inline-block;position: static;bottom: 9px;left: -10px;padding: 0;z-index: 100;transition: 0.1s;border-radius: 5px;color: #35405a;line-height: 32px;width: 100%;padding-left: 40px;box-sizing: border-box;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
#section_ce #workSettingScroll ol ul li:hover h5 {transform: scale(1.1);transition: 0.2s;}
#workSettingScroll ul {margin-left: 0;padding: 0 10px;box-sizing: border-box;}
#workSettingScroll li .status,#workSettingScroll li .check {position: absolute;background: #35405a;color: #fff;font-weight: bold;padding: 10px;border-radius: 0 0 5px 5px;right: 15px;top: 0px;}
#workSettingScroll .howMany {font-weight: bold;opacity: 0.5;padding: 20px 20px 15px;width: 100%;box-sizing: border-box;border-bottom: 1px solid #e7e9ea;}
#workSettingScroll .content li.crtWork {background:#fff;box-sizing: border-box;border: 2px dashed #e7e9ea;}
#workSettingScroll .content li.crtWork input {transition:0.2s;color:#a7a7a7;border-radius: 5px;font-size: 21px;font-weight: bold;text-align: center;height:100%; line-height:300px;width:100%;background:#f1f3f5;border:0 none; padding:0; float:left;margin:0;;}
#workSettingScroll .content li.crtWork:hover input.addNow { color: #477fff; background:#fff; }
#workSettingScroll input.modifyWork {position: absolute;width: 100%;height: 100%;top: 0;left: 0;padding: 0;margin: 0;font-size: 0 !important;border: 0;background: none;z-index:1000;}
#workSettingScroll input.modifyWork:hover {background: transparent !important;font-size: 0;}
#workSettingScroll input.delWork {display:none;position: absolute;top: -12px;left: -17px;width: 32px;background: #ff6363 url(../images/contents/ic_delImg.png) no-repeat center center;background-size: 14px;font-size: 0;height: 32px;border-radius: 50%;font-weight: bold;color: #fff;z-index: 100000;transition: 0.1s;}
#workSettingScroll .content ol > li:hover .delWork:hover {width: 70px;border-radius: 30px;font-size: 12px;background: #ff6363;transition: 0.1s;}
#workSettingScroll .content ol > li:hover .delWork {display:block;}
#workSettingScroll input.delWork:hover {background-color:#f14343}
#workSettingScroll .content ol > li:hover .howMany { opacity:1; border-bottom:1px solid #f1f3f5; }
.content > ol > li.check ul li h5 {background-image: url('../images/contents/checked_fff.png') !important;background-size: 20px;background-repeat: no-repeat;font-size: 0 !important;background-position: center center !important;}
.delForReal.on { display:block; }
.delForReal {display:none;position: absolute;z-index: 1000;background: rgba(255,255,255,0.8);width: 100%;height: 100%;top: 0;left: 0;text-align: center;border-radius:5px;}
.delForReal input {background: transparent;font-weight: bold;font-size: 14px;width: 80px;display: inline-block;border-radius: 20px;background: #ffffff;line-height: 35px;border: 1px solid #e7e9ea;margin: 0 10px !important;}
.delForReal input:hover { background:#f1f3f5;}
.delForReal input.yes:hover { background:#477fff; color:#fff; border:1px solid #477fff; }
.delForReal h5 {font-size: 16px;margin-top: 130px;margin-bottom: 60px;}

/* 업무추가 */
input#msgOpInTitle {width: 600px;}
.addPageDefault .content {padding-top: 60px;clear: both;float: left;width:100%;}
#mngGrpScroll.addPageDefault .content {padding: 0;}
.addPageDefault  .selectTitle span.num { margin-left:10px; color:#477fff; font-size:14px; }
.addPageDefault  .selectTitle span.rest {font-size:14px; }
.addPageDefault  .settingBg .btn_group {margin-right: 30px;margin-top: 11px;}
#section_ce .addPageDefault  .grp.settingBg {height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
#section_ce .addPageDefault  .grp.settingBg h3.selectTitle {position: static;display:inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
#section_ce .addPageDefault .grp.settingBg h3.selectTitle .txt {    background: url(../images/side_area/bt_open.png) no-repeat center right;padding-right: 35px !important;}


.addPageDefault h4 {font-size: 16px;margin-bottom:20px;}
.addPageDefault .first { padding-top:40px; }
.addPageDefault .first > div {display: inline-block;}
.addPageDefault .first input {text-align:left;line-height: 55px;height: 55px;font-size:14px;font-weight:bold;padding:0 15px;border:1px solid #e7e9ea;} 
.addPageDefault .first > div.sec1 {width: 50%;}
.addPageDefault .first > div.sec1 input {width: 100%;box-sizing: border-box;transition:0.2s;font-size: 16px;}
.addPageDefault .first > div.sec1 input:hover { border:1px solid #d2d7e2; background:#f1f3f5;transition:0.2s; }
.addPageDefault .first > div.sec1 input:focus { border:1px solid #477fff; background:#fff;transition:0.2s; }
.addPageDefault .first > div.sec2 {position: absolute;left: 50%;margin-left: 50px;}
.addPageDefault .first > div.sec3 {position: absolute;left: 50%;margin-left: 196px;}
.addPageDefault .first .sec2 input, .addPageDefault .first .sec3 input {width: 100px;border-radius: 5px;transition:0.2s;background: #fff url('../images/side_area/bt_open.png') no-repeat 65px center;}
.addPageDefault .first .sec2 input:hover, .addPageDefault .first .sec3 input:hover {background: #f1f3f5 url('../images/side_area/bt_open.png') no-repeat  65px center;border:1px solid #d2d7e2;transition:0.2s;}
.addPageDefault .first .sec2 input:focus, .addPageDefault .first .sec3 input:focus {background: #fff url('../images/side_area/bt_close.png') no-repeat  65px center;border:1px solid #477fff;transition:0.2s;}
.addPageDefault .first .dropdown { z-index:10000; display:none; position:absolute; }
.addPageDefault .first .dropdown.on { display:block; }
.addPageDefault .first .dropdown a {display: block;line-height: 45px;padding: 0 15px;}
.addPageDefault .first .dropdown {background: #fff;border-radius: 5px;box-shadow: 0 5px 10px rgba(0,0,0,0.1);left: 0;width: 100%;top: 93px;}
.addPageDefault .first .dropdown a {border-bottom: 1px solid #f1f3f5;}
.addPageDefault .first .dropdown a:hover { background-color:#f1f3f5; font-weight:bold; }
.addPageDefault .first .dropdown a.on:hover { background: #fff; cursor:default; }
.addPageDefault .first .dropdown a.on { font-weight:bold; }
.addPageDefault .first .dropdown a.on:after {display: block;content: '';position: absolute;right: 13px;top: 19px;width: 7px;height: 7px;background: #477fff;border-radius: 50%;}
.sec2 .dropdown li, .sec3 .dropdown li {position: relative;}
.addPageDefault .second {margin-top: 40px;padding-top: 40px;border-top: 1px solid #f1f3f5;position: relative;}
.second ul li:before {content: '';display: block;position: absolute;background: #f1f3f5;width: 3px;height: 100%;top: 33px;left: 16px;}
.second ul li:last-child:before {display: none;}
.addPageDefault .second > ul > li h5 {display: inline-block;width: 35px;height: 35px;border-radius: 50%;text-align: center;line-height: 35px;color: #fff;position: absolute;border: 5px solid #fff;font-size: 14px;left: -5px;top: 14px;}
.addPageDefault .second > ul > li {clear:both;margin-bottom: 20px;padding: 0 330px 0 60px;box-sizing:border-box;position: relative;width:100%;}
.addPageDefault .second > ul > li:hover h5 { transform:scale(1.1); transition:0.2s; }
.addPageDefault .second > ul > li .txt:hover{border-bottom:1px solid #d2d7e2}
.addPageDefault .second > ul > li .txt:focus { border-bottom:1px solid #477fff; }
.addPageDefault .second > ul > li > input {display: inline-block;border-bottom:1px solid #e7e9ea;width: 100%;line-height: 70px;font-weight: bold;font-size:14px;}
.addPageDefault .second > ul > li > .clr {display: inline-block;float: right;position:relative;position: absolute;top: 20px;right: 200px;}
.addPageDefault .second > ul > li > .clr .dot { position:absolute;font-size:0; width:7px; height:7px; border-radius:50%;top:15px; left:14px }
.addPageDefault .second > ul > li > .clr input {display:inline-block;background:#fff;border-radius:20px;border: 1px solid #e7e9ea;padding:0 15px 0 30px;line-height: 35px;font-size:12px;}
.addPageDefault .second > ul > li > .clr input:hover { background:#f1f3f5; border: 1px solid #d2d7e2; }
.addPageDefault .second > ul > li > .clr input:focus { border: 1px solid #477fff; background:#fff; font-weight:bold; }
.addPageDefault .second p {margin-bottom: 20px;opacity: 0.6;padding-top: 5px;}
.addPageDefault .content.check .second ul > li h5 { font-size:0;background-image:url(../images/contents/checked_fff.png); background-repeat: no-repeat; background-position: center 6px; }
#msgAddScroll p.countLetters {font-weight: bold;opacity: 1;position: absolute;right: 20px;top: 21px;opacity: 1;width: auto;font-size: 12px;z-index: 10000000;}
#msgAddScroll p.countLetters span#sendMmsMasageCount {color: #477fff;}



/* 멤버 */
#memScroll h3.selectTitle .cl {display: inline-block;width: 8px;height: 8px;float: left;margin-top: 28px;border-radius: 50%;margin-right: 15px;font-size: 0;}
#memScroll h3.selectTitle a {position: absolute;width: 100%;height: 100%;background: none;font-size: 0;display: block;left: 0;top: 0;}
#memScroll h3.selectTitle {position: relative !important;}
a#inviteMate {position: fixed;bottom: 40px;right: 100px;z-index: 10000;opacity: 1;z-index: 1000;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(../images/contents/btn_add_fff.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;}
a#inviteMate.on { background: #477fff url(../images/contents/btn_close_fff.png) no-repeat center center !important;background-size: 53px !important;}
a#inviteMate:hover {box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5) !important;}

/* 멤버 초대 */
.inviteMateDiv.on { display:block }
.inviteMateDiv {transition: 0.2s;display:none;position: fixed;z-index: 10000;width: 300px;padding: 0;background: #fff;box-shadow: 0 5px 15px rgba(0,0,0,0.15);border-radius: 5px;box-sizing: border-box;right: 80px;text-align: center;transition: 0.2s;bottom: 124px;}
.inviteMateDiv .grp1 {padding: 35px 40px 0 40px;transition:0.3s;height: 125px;}
.inviteMateDiv.sent .grp2 { display:block; }
.inviteMateDiv .btns {border-top: 1px solid #F1f3f5;position: absolute;width: 100%;bottom: 0;border-radius: 0 0 5px 5px;overflow: hidden;}
.inviteMateDiv .btns a {display: inline-block;width: 50%;float: left;height: 70px;line-height: 70px;font-size: 14px;font-weight: bold;}
.inviteMateDiv .btns a:hover { color:#477fff; }
.inviteMateDiv .btns a.close:hover { color:#ff5050 }
.inviteMateDiv .btns a:hover {background: #f1f3f5;}
.inviteMateDiv.sent { transition:0.2s; box-shadow: 0 8px 20px rgba(71, 127, 255, 0.5);}
.inviteMateDiv.sent .grp2 .check {width: 130px;border-radius: 20px;height: 40px;line-height: 40px;background: #477fff;color: #fff;font-weight: bold;display: inline-block;font-size: 14px;margin-top: 16px;}
.inviteMateDiv .grp2 {width: 100%;top: 0;display: none;position: absolute;height: 100%;background: #fff url(../images/contents/sucSent.png) no-repeat center 0px;background-size: 211px;border-radius: 5px;}
.inviteMateDiv .grp2 h3 {font-size: 16px;padding-top: 196px;height: 30px;width: 100%;}
.inviteMateDiv.sent {height: 386px;}
.inviteMateDiv h2 {font-size: 19px;text-align: center;margin-bottom: 10px;}
.inviteMateDiv p {text-align:left;font-weight: bold;padding-top: 10px;font-size: 13px;line-height: 20px;}
.inviteMateDiv span.descript2 { font-size:12px;color: #757f96; padidng-left:5px; }
.inviteMateDiv input {font-weight:bold;line-height: 45px;height: 45px;font-size: 14px;width: 100%;border-radius: 0;padding: 0 15px;box-sizing: border-box;border: 1px solid #f1f3f5;}
.inviteMateDiv input.wrong { border:1px solid #ff5050; background:#ffe7e7; color:#ff5050 }
.inviteMateDiv input.wrong::placeholder  { color:#ff5050 !important; }
.inviteMateDiv input.wrong:hover { border:1px solid #ff5050; background:#fff; transition:0.2s; }
.inviteMateDiv label {font-weight:bold; text-align:left;font-weight: bold;font-size: 13px;margin-top: 20px;margin-bottom: 10px;display: block;color: #757f96;}
.inviteMateDiv input:hover {border: 1px solid #d9dee0;}
.inviteMateDiv input:focus {border: 1px solid #477fff;}
.inviteMateDiv #modalMemInvateBtn {line-height:40px;height:40px;border-radius:25px;width:190px;display: inline-block;font-size: 14px;font-weight: bold;text-align: center;background: #477fff;color: #fff;margin-top: 25px;}
.inviteMateDiv #modalMemInvateBtn:hover, .inviteMateDiv.sent .grp2 .check:hover { background:#3371ff }
.inviteMateDiv form {border-top: 1px solid #f1f3f5;padding-top: 10px;padding: 5px 40px 55px;margin-top: 20px;}
.inviteMateDiv p {}
/* .inviteMateDiv:hover .grp1 {transition:0.3s;height: 64px;box-sizing: border-box;} */
/* .inviteMateDiv:hover p {height: 0;opacity: 0;padding: 0;} */
.inviteMateDiv.sent .grp1 {display:none;}



/* 색상선택 */
.pickColour.on { display:block; max-width:700px; }
.pickColour {display:none;position:absolute;width:100%;z-index: 10000000;top: 71px;background:#fff;box-shadow: 0 10px 20px rgba(0,0,0,0.1); border-radius: 0 0 5px 5px;left: 100px;top: 73px;}
.pickColour > ul > li > a {float: left;width: 100%; line-height:60px;border-bottom:2px solid #f1f3f5;font-size:14px; font-weight:bold;text-align:center;}
.pickColour ul li:before { display:none; }
.pickColour > ul > li > a.on { border-bottom:2px solid #477fff; }
.pickColour > ul > li:hover > a { border-bottom:2px solid #477fff; }
.pickColour > ul > li {overflow: hidden;float: left;width: 50%}
.pickColour > ul ul { clear: both; box-sizing: border-box; padding: 25px 30px; }
.pickColour > ul ul li { width: 30px; height:30px; border-radius:50%; margin: 13px 10px; display: inline-block; }
.pickColour > ul > li { opacity:0.8; }
.pickColour > ul > li:hover, .pickColour > ul > li.on { opacity:1; }
.pickColour > ul > li.on > a {border-bottom:2px solid #477fff;  }
.pickColour > ul ul li a { float:left; display:inline-block; width:100%; height: 100%; background:#477fff; font-size:0;border-radius:50%; transition:0.1 }
.pickColour > ul ul li.on a { opacity:1; transform: scale(1.15); background-image: url(../images/contents/checked_fff.png); background-repeat: no-repeat; background-size: 23px; background-position: center center; }
.pickColour > ul ul li.on a:hover { cursor:default; }
.pickColour > ul ul li:hover a { cursor: pointer; transform: scale(1.15); transition:0.1;}





.mngGrpModal .over_hidden {position:static !important;padding-top: 0 !important;}
#mngGrpScroll .createNewGroup .btns {position:static;padding: 0;}
#groupFileUpload .grp.toTheRight {position: static !important;}

.hc .bt_leftContrBox:before { background-color:#36bdff !important;background-image: linear-gradient(to right, #36bdff , #007eff 141%)!important;}
.hc .firstPage {background-color: #36bdff !important; background-image: linear-gradient(to bottom, #36bdff , #007eff 141%)!important;}
.hc .firstPage.end1 { 
    background-image: linear-gradient(to right, #36bdff , #007eff 141%)!important;
   }



.modal { z-index: 100000000 !important; }

.bt_shareBox:hover .shareInnbox {display: block !important;}

.leftOn .alldate_box, .leftOn .titDepthBox  {transition:0s !important;margin-left: 200px;}
.leftOn #groupListUl {display: none;}
.leftOn #grupListUl {display: none;}
.leftOn .tit_date,.leftOn .titleDep {transition: 0s !important; font-size: 0;}
.leftOn .left_inbox {display: none;}
.leftOn #bizWorkUl {display: none;}
.leftOn ul#bizMemToMsgUl {display: none;}
.leftOn .headerTitleDiv .personalCalendar { right: 17px; }

#wrap.leftOn #left_area:hover #groupListUl { display : block; }
#wrap.leftOn #left_area:hover #grupListUl { display : block; }
#wrap.leftOn #left_area:hover #bizWorkUl { display : block; }
#wrap.leftOn #left_area:hover #bizMemToMsgUl { display : block; }

/* ck editor  */
.cke_dialog { z-index: 200000000 !important; }
.cke_dialog_background_cover {z-index: 100000000 !important; }




/*  휴가등록 데이트픽커  */
.datepickerDiv.hasDatepicker .ui-datepicker {margin: 0;width: 100%; text-align: center;}
.datepickerDiv .ui-datepicker-header {padding: 9px 6px 8px 6px; position: relative; }
.datepickerDiv  .ui-datepicker-title { display: inline-block; float:left; margin-top: -12px; }
#organization_off_add_datepicker .ui-datepicker-title { margin-top: 0; text-align: center; }
.datepickerDiv  .ui-datepicker-title span { font-size: 18px; }
.datepickerDiv .ui-datepicker td { width: 14.2857142857%; height: 36px !important; line-height: 36px !important; }
.datepickerDiv .ui-datepicker td a, .datepickerDiv .ui-datepicker td a.active, .datepickerDiv .ui-datepicker td:hover a { font-size: 13px; width: 36px !important; height: 36px !important; line-height: 36px !important;  }
.datepickerDiv .ui-datepicker-today .ui-state-active:hover,.datepickerDiv  .ui-datepicker-today:hover .ui-state-active,.datepickerDiv  .ui-datepicker-today:hover a,.datepickerDiv  .ui-datepicker-today .ui-state-active,.datepickerDiv  .ui-datepicker-today .ui-state-active {  font-size: 13px !important; width: 36px !important; height: 36px !important; line-height: 36px !important; }




/* 폴더 생성 아이콘 */
.addFolderList { border:none !important; }
.addFolderList .add_folder:hover {background-color:#e1ebff}
.add_folder { background-image: url('../images/folder_file/ic_add_folder.png'); font-size:0; background-size: 70px !important; border-radius: 5px; background-position: center center !important; border:2px dashed #477fff; box-sizing: border-box; font-size:0 !important;}

/* 폴더 타입 아이콘 */
.ic_fd { display: inline-block; width:100%; height:100px; background-position: 55px center; background-size:75px;  background-repeat: no-repeat; font-size:0; }
.ic_fd.fd_pic { background-image: url('../images/folder_file/fd_pic.png');  }
.ic_fd.fd_share { background-image: url('../images/folder_file/fd_share.png');  }
.ic_fd.fd_study { background-image: url('../images/folder_file/fd_study.png');  }
.ic_fd.fd_ppt { background-image: url('../images/folder_file/fd_ppt.png');  }
.ic_fd.fd_important { background-image: url('../images/folder_file/fd_important.png');  }
.ic_fd.fd_rbBin { background-image: url('../images/folder_file/fd_rbBin.png');  }
.ic_fd.fd_star { background-image: url('../images/folder_file/fd_star.png');  }
.ic_fd.fd_file { background-image: url('../images/folder_file/fd_file.png');  }
.ic_fd.fd_lock { background-image: url('../images/folder_file/fd_lock.png');  }
.ic_fd.fd_no { background-image: url('../images/folder_file/fd_no.png');  }
.ic_fd.fd_music { background-image: url('../images/folder_file/fd_music.png');  }
.ic_fd.fd_video { background-image: url('../images/folder_file/fd_video.png');  }

/* 폴더 추가시 사용되는 폴더아이콘 */
.typeUl .ic_fd { display: inline-block; width:100%; height:84px; background-position: 55px center; background-size:30px !important;  background-repeat: no-repeat; font-size:0; }
.ic_fd.ic_fd_bk0 { background-image: none;  }
.ic_fd.ic_fd_bk1 { background-image: url('../images/folder_file/ic_fd_bk1.png');  }
.ic_fd.ic_fd_bk2 { background-image: url('../images/folder_file/ic_fd_bk2.png');  }
.ic_fd.ic_fd_bk3 { background-image: url('../images/folder_file/ic_fd_bk3.png');  }
.ic_fd.ic_fd_bk4 { background-image: url('../images/folder_file/ic_fd_bk4.png');  }
.ic_fd.ic_fd_bk5 { background-image: url('../images/folder_file/ic_fd_bk5.png');  }
.ic_fd.ic_fd_bk6 { background-image: url('../images/folder_file/ic_fd_bk6.png');  }
.ic_fd.ic_fd_bk7 { background-image: url('../images/folder_file/ic_fd_bk7.png');  }
.ic_fd.ic_fd_bk8 { background-image: url('../images/folder_file/ic_fd_bk8.png');  }
.ic_fd.ic_fd_bk9 { background-image: url('../images/folder_file/ic_fd_bk9.png');  }
.ic_fd.ic_fd_bk10 { background-image: url('../images/folder_file/ic_fd_bk10.png');  }
.ic_fd.ic_fd_bk11 { background-image: url('../images/folder_file/ic_fd_bk11.png');  }

/* 폴더 추가시 사용되는 폴더아이콘 on */
.ic_fd.ic_fd_bk0.on { background-image: none;  }
.ic_fd.ic_fd_bk1.on { background-image: url('../images/folder_file/ic_fd_bk1_on.png');  }
.ic_fd.ic_fd_bk2.on { background-image: url('../images/folder_file/ic_fd_bk2_on.png');  }
.ic_fd.ic_fd_bk3.on { background-image: url('../images/folder_file/ic_fd_bk3_on.png');  }
.ic_fd.ic_fd_bk4.on { background-image: url('../images/folder_file/ic_fd_bk4_on.png');  }
.ic_fd.ic_fd_bk5.on { background-image: url('../images/folder_file/ic_fd_bk5_on.png');  }
.ic_fd.ic_fd_bk6.on { background-image: url('../images/folder_file/ic_fd_bk6_on.png');  }
.ic_fd.ic_fd_bk7.on { background-image: url('../images/folder_file/ic_fd_bk7_on.png');  }
.ic_fd.ic_fd_bk8.on { background-image: url('../images/folder_file/ic_fd_bk8_on.png');  }
.ic_fd.ic_fd_bk9.on { background-image: url('../images/folder_file/ic_fd_bk9_on.png');  }
.ic_fd.ic_fd_bk10.on { background-image: url('../images/folder_file/ic_fd_bk10_on.png');  }

/* 폴더 추가시 .folder속 span 아이콘 예제 */
.folder .ic_fd_bk0 { background-image: none;  }
.folder .ic_fd_bk1 { background-image: url('../images/folder_file/ic_fd_bk1_on.png');  }
.folder .ic_fd_bk2 { background-image: url('../images/folder_file/ic_fd_bk2_on.png');  }
.folder .ic_fd_bk3 { background-image: url('../images/folder_file/ic_fd_bk3_on.png');  }
.folder .ic_fd_bk4 { background-image: url('../images/folder_file/ic_fd_bk4_on.png');  }
.folder .ic_fd_bk5 { background-image: url('../images/folder_file/ic_fd_bk5_on.png');  }
.folder .ic_fd_bk6 { background-image: url('../images/folder_file/ic_fd_bk6_on.png');  }
.folder .ic_fd_bk7 { background-image: url('../images/folder_file/ic_fd_bk7_on.png');  }
.folder .ic_fd_bk8 { background-image: url('../images/folder_file/ic_fd_bk8_on.png');  }
.folder .ic_fd_bk9 { background-image: url('../images/folder_file/ic_fd_bk9_on.png');  }
.folder .ic_fd_bk10 { background-image: url('../images/folder_file/ic_fd_bk10_on.png');  }



/* 폴더 아이콘 */
.folder { transition: 0.2s; display: inline-block; width: 100%; height: 100px; line-height: 105px; text-align: center; color: #fff;font-size: 20px; font-weight: bold; background-position: 45px center; background-repeat:no-repeat; background-size:100px 70px; }
/* 폴더 색별 아이콘 */
.folder_dc0 { background-image: url('../images/folder_file/ic_folder_dc0.png'); }
li:hover .folder_dc0 {  background-image: url('../images/folder_file/ic_folder_hover_dc0.png'); }

.folder_dc1 { background-image: url('../images/folder_file/ic_folder_dc1.png'); }
li:hover .folder_dc1 {  background-image: url('../images/folder_file/ic_folder_hover_dc1.png'); }

.folder_dc2 { background-image: url('../images/folder_file/ic_folder_dc2.png'); }
li:hover .folder_dc2 {  background-image: url('../images/folder_file/ic_folder_hover_dc2.png'); }

.folder_dc3 { background-image: url('../images/folder_file/ic_folder_dc3.png'); }
li:hover .folder_dc3 {  background-image: url('../images/folder_file/ic_folder_hover_dc3.png'); }

.folder_dc4 { background-image: url('../images/folder_file/ic_folder_dc4.png'); }
li:hover .folder_dc4 {  background-image: url('../images/folder_file/ic_folder_hover_dc4.png'); }

.folder_dc5 { background-image: url('../images/folder_file/ic_folder_dc5.png'); }
li:hover .folder_dc5 {  background-image: url('../images/folder_file/ic_folder_hover_dc5.png'); }

.folder_dc6 { background-image: url('../images/folder_file/ic_folder_dc6.png'); }
li:hover .folder_dc6 {  background-image: url('../images/folder_file/ic_folder_hover_dc6.png'); }

.folder_dc7 { background-image: url('../images/folder_file/ic_folder_dc7.png'); }
li:hover .folder_dc7 {  background-image: url('../images/folder_file/ic_folder_hover_dc7.png'); }

.folder_dc8 { background-image: url('../images/folder_file/ic_folder_dc8.png'); }
li:hover .folder_dc8 {  background-image: url('../images/folder_file/ic_folder_hover_dc8.png'); }

.folder_dc9 { background-image: url('../images/folder_file/ic_folder_dc9.png'); }
li:hover .folder_dc9 {  background-image: url('../images/folder_file/ic_folder_hover_dc9.png'); }



.grp.settingBg {position: static;padding: 0 0 !important;width: 100% !important;max-width: 100% !important;min-width: 100% !important;}
#container #section_ce .schedule_box {width: 100%;max-width: 100%;padding-left: 0;padding-bottom: 0;padding-right: 0;}
#container #section_ce .section_scroll {margin-top: 0 !important;}
#memSettingScroll .sortHeader {margin-top:0 !important; }
.left_area_close .grp.settingBg { padding-left:0 !important }
#memScroll .content { padding-top:0 !important; }

/* IE에서 x버튼을 클릭할때 @input을 지원하지 않아서 자동으로 나오는 x 버튼을 숨김 */
input[type=text]::-ms-clear { display:none; }

img.dummy { display : none !important; }

/* 21.03.11 left메뉴 hover시 */
#wrap.leftOn .bt_leftContrBox:before {position: absolute; left: 0; right: 0; }
#wrap.leftOn #left_area {position: absolute; left: 0; top: 61px;}
#wrap.leftOn #left_area:hover {
    left: 0 !important;
    box-shadow: 15px 0px 21px rgb(0 0 0 / 14%);
    z-index: 10000;
}
.leftOn .tit_date, .leftOn .titleDep {transition: .1s !important; font-size:0; }
#wrap.leftOn #left_area:hover .titleDep, #wrap.leftOn #left_area:hover .tit_date {
    font-size: 12px !important;
}
#wrap.leftOn #left_area:hover .alldate_box, #wrap.leftOn #left_area:hover .titDepthBox {
    margin-left: 0;
}

/* 달력 필터 수정 */
#right_area .right_inbox02 .calfil_titlebox { position: fixed; width: 510px!important; z-index: 10000; background: #fff; top: 0; height: 60px; overflow: hidden; border-radius: 0; border-bottom: 2px solid #e7e9ea; }
#right_area .right_inbox02 .notice .tit_calfil { float: left; padding-left: 20px; height: 61px; line-height: 61px; color: #484848; font-size: 18px; }
#right_area .right_inbox02 .calFilter_content { padding: 0 0 10px; margin-top: 63px; position: relative; }
#right_area .right_inbox02 .calFilter_content .calFil_box { height: 870px; box-sizing: border-box; overflow: auto; }
#right_area .right_inbox02 .calFil_box ul { text-align: left; font-size: 13px; font-weight: 700; }
#right_area .right_inbox02 .calFil_box .ul.calFil_prmB_one { padding-top: 15px; }
#right_area .right_inbox02 .calFil_box ul li { width: 100%; line-height: 55px;}
#right_area .right_inbox02 .calFil_box ul li > p { cursor : pointer; }
.calFil_basic_second, .calFil_gr_one, .calFil_prm_one, .calFil_prD_one, .calFil_system_oen  { border-top: 1px solid #e7e9ea; }
#right_area .right_inbox02 .calFilter_content .calFil_box input[type=checkbox]:not(old):checked+label { background: #477fff url('../images/ware/checked_fff.png') no-repeat -1px -1px; background-size: 18px; }
#right_area .right_inbox02 .calFilter_content .calFil_box .hc input[type=checkbox]:not(old):checked+label { background-color: #36bdff; }
#right_area .right_inbox02 .calFilter_content .calFil_box .hd input[type=checkbox]:not(old):checked+label { background-color: #ff6363; }
#right_area .right_inbox02 .calFilter_content .calFil_box input[type=checkbox]:not(old)+label { display: inline-block; padding: 0; width: 15px; height: 15px; border-radius: 3px; border: 2px solid transparent; background: #c1cfd8 url('../images/ware/checked_fff.png') no-repeat -1px -1px; background-size: 18px; font-size: 0; margin-right: 15px; margin-top: 18px; }
#right_area .right_inbox02 .calFilter_content .calFil_drop input[type=checkbox]:not(old)+label { margin-top: 10px; }
#right_area .right_inbox02 .calFil_box p { margin-left: 30px; }
#right_area .right_inbox02 .calFil_box ul li.calFil_drop { line-height: 40px; padding: 10px 0; box-sizing: border-box; }
#right_area .right_inbox02 .calFil_box .calFil_basic_second li > p,
#right_area .right_inbox02 .calFil_box .calFil_system_oen li > p { margin-left: 40px; }
#right_area .right_inbox02 .calFil_box ul li.calFil_drop li { line-height: 40px; }
#right_area .right_inbox02 .calFil_box li.calFil_secChild > p { margin-left: 40px; background: url('../images/side_area/ic_depth02_close.gif') no-repeat 391px 17px!important; transition: .2s; }
#right_area .right_inbox02 .calFil_box .calFil_drop > p { background: url('../images/side_area/ic_depthopen_new.png') no-repeat 400px 17px!important; }
#right_area .right_inbox02 .calFil_box li.calFil_secChild .calFil_secChild_2 { margin-left: 20px; }
/* 필터 접기펼치기 */
#right_area .right_inbox02 .calFil_box ul li.calFil_drop > ul { display: none; }
#right_area .right_inbox02 .calFil_box ul li.calFil_drop.on > ul { display: block; }
#right_area .right_inbox02 .calFil_box ul li.calFil_drop.on > p { background-image: url('../images/side_area/ic_depthclose_new.png') !important; }
#right_area .right_inbox02 .calFil_box li.calFil_secChild > ul.calFil_secChild_2 { display: none; }
#right_area .right_inbox02 .calFil_box li.calFil_secChild.on > ul.calFil_secChild_2  { display: block; }
#right_area .right_inbox02 .calFil_box li.calFil_secChild.on > p { background-image: url('../images/side_area/ic_depth02_open.gif') !important; }
#right_area .right_inbox02 .calFil_box li.calFil_secChild.off > p { background-image : none !important; }

#right_area .right_inbox02 .calFil_box .calFil_prmB_one { border-top: 1px solid #e7e9ea; }
#right_area .right_inbox02 .calFil_box .calFil_prmB_one li { overflow: hidden; }
#right_area .right_inbox02 .calFil_box .calFil_prmB_one li p { float: left; width: 27%; box-sizing: border-box; }

/* 각 타이틀 사용메뉴얼 */
.title_box .ussM_box { width: 20px; height: 20px; display: inline-block; background: #ffc72f; border-radius: 50%; vertical-align: middle; margin-left: 10px; transition: .3s; }
.title_box .ussM_box:hover { transform: scale(1.2); }
.title_box .ussM_box .use_manual { display: block; line-height: 20px; text-align: center; color: #fff; font-size: 12px !important; font-weight: bold; }



#left_area .left_inbox02 .titDepthBox { border-top : none !important; border-bottom: 1px solid #f1f3f5; }

/* 호두 홈 안내방송 등록/상세보기 겹치는 스타일 */
.content.announcement { min-width: 788px; }
.announcement .annOption { height: 82px; border-bottom: 2px solid #e7e9ea; padding: 0 px; -webkit-box-sizing: border-box; box-sizing: border-box; width: 100%; border-right: 1px solid #e7e9ea; }
.announcement .annOption > div { display: inline-block; float: left; height: 80px; padding: 0 30px; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative; }
.announcement .annOption > div > p { width: 100%; color: #7c88a2; font-size: 13px; font-weight: bold; margin-top: 5px; line-height: 40px; height: 30px; float: left; }
.announcement .annOption select { cursor: pointer; -webkit-appearance: none; -webkit-box-sizing: border-box; box-sizing: border-box; height: 51px; line-height: 51px; padding-left: 10px; -webkit-appearance: none; width: auto; text-align: left; font-weight: bold; height: 30px; line-height: 30px; padding: 0 7px; border: 1px solid #fff; vertical-align: top; transition: .3s; }

.announcement .annOption .when { width: 33%; }
.announcement .when input { height: 33px; line-height: 33px; text-align: left; margin-right: 15px; transition: .3s; }
.announcement .when input:hover, .announcement .when input:focus  { color: #477fff; }
.announcement .when #ann_datepicker { font-size: 15px; font-weight: bold; }
.when .when_time { display: inline-block; height: 33px; line-height: 33px; vertical-align: middle; }
.when .when_time:hover select { border: 1px solid #e8e8e8; border-radius: 5px; }
.when .when_time:hover select.ampm { margin-right: 7px; }

.announcement .annOption .when_check { width: 40%; padding-left: 0; border-right: 1px solid #e7e9ea;}
.announcement .annOption > div.when_check > p { width: auto !important; margin-right: 20px !important; }
.announcement .annOption > div > label.switch {  margin-right: 20px; margin-top: 12px; float: left; }
.switch { position: relative; display: inline-block; width: 52px; height: 25px; }

.announcement .ann_textBox { margin-top: 0; border-right: 1px solid #e7e9ea; }
.ann_textBox #ann_title { -webkit-transition: .2s; transition: .2s; font-weight: 700; line-height: 70px; height: 70px; padding: 0 30px; -webkit-box-sizing: border-box; box-sizing: border-box; width: 100%; font-size: 18px; border-bottom: 2px solid #e7e9ea; }
textarea#ann_content { width: 100%; max-width: 100%; height: 100%; max-height: 100%; resize: none; margin: 0; border: 1px solid #e8e8e8; -webkit-box-sizing: border-box; box-sizing: border-box; border-top: 0 none; font-size: 14px; transition: .1s; line-height: 23px; padding: 25px 30px 50px; text-align: left; overflow-y: auto; }
textarea#ann_content:focus { outline: none; }

/* 호두홈 장비/자재 */
.leftboder { border-left: 1px solid #f1f3f5;}
.eqinfor h2 { font-size: 15px; font-weight: bold; margin: 0 0 10px 15px; }
.titleTab_div { width: 100%; line-height: 60px; height: 60px; -webkit-box-sizing: border-box; box-sizing: border-box; border-bottom: 1px solid #e7e9ea; overflow: hidden; padding: 0 25px; }
.titleTab_div * { display: inline-block; }
.titleTab_div .title { font-weight: 700; font-size: 14px; vertical-align: middle; display: inline-block; }
.titleTab_div .tit_tab { width: 125px; height: 35px; line-height: 35px; display: block; padding: 0 15px; text-align: center; }
.titleTab_div .active .tit_tab { width: 125px; height: 35px; line-height: 35px; display: block; border-radius: 30px; background: #2b3b5f; padding: 0 15px; text-align: center; color: #fff; }

.left_area_close #equipment_detail { padding-left: 61px !important; }

.eqinfor { overflow: hidden; padding: 30px; position: relative; }
.eqinfor ul { overflow: hidden; background: #fff; border: 1px solid #f1f3f5; border-bottom: 0px none !important; border-radius: 10px; box-sizing: border-box; }
.eqinfor .infor_box { border: 1px solid #f1f3f5; border-radius: 10px; box-sizing: border-box; overflow: hidden; }
.eqinfor ul li { width: 50%; float: left; overflow: hidden; line-height: 50px; border-bottom: 1px solid #f1f3f5; }
.eqinfor ul li p { float: left; padding: 0 20px; font-weight: bold; overflow: hidden; white-space: nowrap; }
.eqinfor ul li p:first-child { width: 25%; box-sizing: border-box; color: #acb2bf; }
.eqinfor02 ul li p:first-child { width: 12%; }
.eqinfor ul li.text_one { width: 100%; }

.eqinfor ul li input[type="text"] { width: 65%; float: left; padding: 0 20px; font-weight: bold; overflow: hidden; white-space: nowrap; font-size: 12px; line-height: 40px; margin-top: 5px; box-sizing: border-box; }
.eqinfor ul li:hover input[type="text"], .eqinfor ul li input[type="text"]:focus { box-sizing: border-box; background: #f1f3f5; border-radius: 5px; }
.eqinfor ul li #eqRemark_text { width: 83%; }

.eqinfor03 { margin-top: 20px; }
.eqinfor03 .eqinfor03_btn { width: 30px; height: 30px; background: url('../../assets/images/side_area/bt_close.png') no-repeat; background-size: 30px; position: absolute; top: 13px; right: 37px; transition: .2s; font-size: 0; }
.eqinfor03 .eqinfor03_btn.off { background: url('../../assets/images/side_area/bt_open.png') no-repeat; background-size: 30px; }
.eqinfor03 .eqinfor03_box { overflow: hidden; display: block; transition: .2s; }
.eqinfor03 .eqinfor03_box.off { display: none; }
.eqinfor03 .eq_picture { float: left; position: absolute; top: 60px; left: 30px; box-sizing: border-box; }
.eqinfor03 .eq_picture .eq_img { width: 450px; height: 345px; border-radius: 30px; display: inline-block; box-shadow: 0 5px 10px rgb(0 0 0 / 10%); border: 5px solid #fff; box-sizing: border-box; background: url('../../assets/images/contents/hoduhome_car_camera.svg') no-repeat center; }
.eqinfor03 .eq_picture em { background: rgba(0,0,0,0.5); color: #fff; width: 440px; line-height: 30px; text-align: center; position: absolute; left: 5px; bottom: 5px; opacity: 1; transition: all .3s ease; padding: 30px 0; box-sizing: border-box; border-radius: 0 0 30px 30px; font-size: 13px; font-style: normal; }
.eqinfor03 .eq_picture em:before { content: ''; width: 30px; height: 30px; display: inline-block; background: url('../../assets/images/contents/hoduhome_camera_white.svg') no-repeat; background-size: 30px; vertical-align: top; margin: -2px 11px 0 0; opacity: .8; }
.eqinfor03 .eq_explanation { width: 100%; float: left; padding-left: 480px; box-sizing: border-box; }
.eqinfor03 .eq_explanation > ul > li { width: 100%; }
.eqinfor .eqtime_datepicker input { background-color: #fff; float: left; padding: 0 20px; font-weight: bold; overflow: hidden; white-space: nowrap; font-size: 12px; line-height: 40px; margin-top: 5px; box-sizing: border-box; }

.eqCardList { position: relative; padding: 30px; margin-top: 20px; }
.eqCardList h2 { font-size: 15px; font-weight: bold; margin: 0 0 10px 15px; }
.eqCardList .btn_group { position: absolute; top: 0; right: 35px; }
#eqinfor04_box .sortHeader { border-top-left-radius: 10px; border-top-right-radius: 10px; height: 50px; line-height: 50px; border-bottom: 0px none; }
#eqinfor04_box .sortHeader li { width: 20%; text-align: left; font-weight: bold; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
#eqinfor04_box.eqinfor04_list .num, #eqinfor04_box.eqinfor04_list .eqPhoto { width: 7%; text-align: center; }
#eqinfor04_box.eqinfor04_list .eqFixstore,
#eqinfor04_box.eqinfor04_list .writtenBy { width: 13%; }
#eqinfor04_box .eqinfor04Ul { border: 1px solid #f1f3f5; }
#eqinfor04_box .eqinfor04Ul li { position: relative; transition: .2s; overflow: hidden; width: 100%; line-height: 50px; height: 50px; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; color: #202a39; cursor: pointer; }
#eqinfor04_box .eqinfor04Ul > li > p { width: 20%; text-align: left; float: left; display: inline-block; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;  transition: .2s; }
#eqinfor04_box .eqinfor04Ul > li:hover p { font-weight: bold; }
#eqinfor04_box .eqinfor04Ul > li > p .eqPhoto_icon { font-size: 0; background: url('../../assets/images/contents/ic_img_bk.png') no-repeat center; width: 20px; height: 20px; display: inline-block; vertical-align: middle; }
#eqinfor04_box .eqinfor04Ul a.eqcardDetail { position: absolute; line-height: 50px; height: 50px; top: 0; width: 100%; display: block; border: none; z-index: 1000; font-size: 0; background: transparent; left: 0; }

.eqCreate_img { overflow: hidden; padding: 30px 30px 0 30px; position: relative; }
.eqCreate_img ul { overflow: visible; background: none; border: none; }
.eqCreate_img ul li { overflow: visible; width: 80px; height: 80px; line-height: 80px; display: inline-block; vertical-align: middle; margin-right: 20px; font-size: 30px; text-align: center; border: 1px solid #e1e4e6; border-radius: 10px; position: relative; }
.eqCreate_img ul li p.img_bring { width: 100%; color: #e1e4e6; }
.eqCreate_img ul li p.eq_img { opacity: 1; width: 100%; height: 100%; font-size: 0; padding: 0; background-position: center center !important; border-radius: 9px; background-size: cover !important; }
.eqCreate_img ul li input { z-index: 999; position: absolute; font-size: 0; transition: .1s; display: inline-block; width: 25px; height: 25px; color: #eaeaea; background: #ff6060 url('../../assets/images/contents/cross.png') no-repeat; border-radius: 20px; }
.eqCreate_img ul li .del { top: -10px; right: -10px; }

/* 비품 수불 관리 제품 등록 버튼 */
.bt_scheduleBox:hover .inbox, .bt_scheduleBox.on .inbox { display: block; }
.bt_scheduleBox { z-index: 999999; }
.bt_scheduleBox .inbox { display: none; margin-bottom: 10px; }
.bt_scheduleBox .event_btn { position: relative; margin-top: 10px; margin-bottom: 20px; width: 60px; height: 60px; }
.bt_scheduleBox .inbox input { display: inline-block; width: 60px; height: 60px; transition: 0.2s; border: 1px solid #e3e7ed; border-radius: 50%; background-color: #f1f3f5; }
.bt_scheduleBox .inbox input:hover { box-shadow: 0 3px 10px rgb(0 0 0 / 15%); border-color: #fff; }
.bt_scheduleBox .inbox input.btn_supply_input { background: url('../../assets/images/contents/ic_supply_input.png') no-repeat center; background-size: cover; }
.bt_scheduleBox .inbox input.btn_buy_input { background: url('../../assets/images/contents/ic_buy_input.png') no-repeat center; background-size: cover; }
.bt_scheduleBox .inbox input.btn_send_input { background: url('../../assets/images/contents/ic_send_input.png') no-repeat center; background-size: cover; }
.bt_scheduleBox p { position: absolute; left: -95px; top: 50%; margin-top: -20px; height: 40px; display: inline-block; line-height: 40px; font-size: 13px; font-weight: bold; width: 80px; text-align: right; z-index: 100; color: #202A39; }

/* 비품 수불 장비 상세보기 등록 */
.eqinfor03 .supply_explanation { padding-left: 290px; }
.eqinfor03 .supply_picture { top : 30px; }
.eqinfor03 .supply_picture .supply_img { width: 255px; height: 255px; }
.supplyIn02 { padding-top: 0; }

.buyinputDiv_box ul { border-radius: 0; border: none; }
.buyinputDiv_box ul li { border: 1px solid #f1f3f5; box-sizing: border-box; width: 49%; border-radius: 10px; float: none; display: inline-block; margin-bottom: 15px; }
.buyinputDiv_box ul li.sort_right { float: right; }
.buyinputDiv_box .btn_count { width: 30px; float: left; text-align: center; margin-top: 7px; }
.buyinputDiv_box .btn_count span { display: block; width: 30px; height: 18px; line-height: 18px; font-size: 13px; color: #9a9c9e; cursor: pointer; transition: .2s; border-radius: 3px 3px 0 0; }
.buyinputDiv_box .btn_count span.up { border-radius: 3px 3px 0 0; }
.buyinputDiv_box .btn_count span.down { border-radius: 0 0 3px 3px; }
.buyinputDiv_box .btn_count span:hover { background: #737d8b; color: #fff; }
.buyinputDiv .buyIn02 { padding: 0; }
.buyinputDiv .buyIn02 .supplyUse { width: 12.25%; }

.eqinfor ul li input[type="number"] { width: 65%; float: left; padding: 0 20px; font-weight: bold; overflow: hidden; white-space: nowrap; font-size: 12px; line-height: 40px; margin-top: 5px; box-sizing: border-box; }
.eqinfor ul li:hover input[type="number"], .eqinfor ul li input[type="number"]:focus { box-sizing: border-box; background: #f1f3f5; border-radius: 5px; }
/* .eqinfor ul li input[type=number]::-webkit-inner-spin-button, .eqinfor ul li input[type=number]::-webkit-outer-spin-button { opacity: 1; background-color : none; } */

/* Chrome, Safari, Edge, Opera */
#finalPrice_text::-webkit-outer-spin-button, #finalPrice_text::-webkit-inner-spin-button, #buyPrice_text::-webkit-outer-spin-button, #buyPrice_text::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
#finalPrice_text, #buyPrice_text { -moz-appearance: textfield; }

.left_area_close #supply_detail, .left_area_close #supplyManagement_detail { padding-left: 60px !important; }

/* 리스트 메뉴 보기 */
a.fileMenu { position: absolute; display: inline-block; text-align: center; right: 5px; top: 16px; width: 30px; height: 30px; border-radius: 30px; left: auto; background: url('../../assets/images/contents/ic_hover_more.png') no-repeat center center; background-size: 18px auto; transform: rotate(90deg); font-size: 0; z-index: 9999; transition: .2s; }
a.fileMenu:hover { background-color: #fff; box-shadow: 0px 0px 10px rgba(0,0,0,0.1); }
ul.fileMenuOp { position: absolute; top: 42px; right: 20px; width: 120px; height: auto; -webkit-box-sizing: border-box; box-sizing: border-box; overflow: hidden; background: #fff; -webkit-box-shadow: 0px 5px 10px rgb(0 0 0 / 10%); box-shadow: 0px 5px 10px rgb(0 0 0 / 10%); border-radius: 5px; -webkit-transition: .2s; transition: .2s; z-index: 10; }
.fileMenuOp > li { height: auto !important; line-height: normal !important; }
.fileOpList { width: 100%; text-align: left; font-size: 12px; cursor: pointer; padding: 9px 15px; background: #fff; border: none; }
.fileMenuOp li:hover .fileOpList { background: #f1f3f5; font-weight: bold; }

/* 메뉴 옵션 */
.menuOpUl { display: none; position: absolute; top: 42px; right: 15px; width: 120px; height: auto; box-sizing: border-box; overflow: hidden; background: #fff; box-shadow: 0px 5px 10px rgb(0 0 0 / 10%); border-radius: 5px; -webkit-transition: .2s; transition: .2s; z-index: 9999; }
.menuOpUl.on { display: block; }
.menuOpUl > li { text-align: left; }
.menuOpUl > li:hover > a { background: #f1f3f5; font-weight: bold; }
.menuOpUl > li > a { line-height: 40px; padding: 0 15px; display: block; }

/* 세로모양 메뉴 */
.lengthMenu { width: 30px; height: 30px; border-radius: 30px; background: url('../../assets/images/contents/ic_hover_more.png') no-repeat center center; background-size: 18px auto; transform: rotate(90deg); font-size: 0; z-index: 9999; transition: .2s; }
.lengthMenu:hover { box-shadow: 0px 0px 10px rgb(0 0 0 / 10%); background-color: #fff; }