<template>
    <div class="section_scroll">
        
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <!-- 파일 추가 -->
        <input type="file" id="addFileLabel" placeholder="" value="파일추가" title="파일추가" @change="addFile($event.target.files)" multiple="multiple" />
        <label for="addFileLabel" class="addFileLabel">+</label>

        <div class="content">

            <!-- 환자리스트 왼쪽 -->
            <PatientFileBoxDirList 
                :event_bus="event_bus" 
                :selected_patient="selected_patient" 
                @selectPatientFileBox="selectPatientFileBox" />

            <!-- 오른쪽 파일함 -->
            <div class="rightAreaDivForFileDrop" @dragover.prevent="fileDragOver($event)" @dragleave.prevent="fileDragLeave" @drop.prevent="fileDrop($event)">
                <PatientFileBoxFileList 
                    :event_bus="event_bus" 
                    :selected_patient="selected_patient" 
                    :class="{ dp2 : is_file_list_card_mode == true }" 
                    />
            </div>
            
        </div>
    </div>
</template>


<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import PatientFileBoxDirList  from '@/components/hodu_d/PatientFileBoxDirList.vue';
import PatientFileBoxFileList from '@/components/hodu_d/PatientFileBoxFileList.vue';

import { ResizeObserver } from 'vue-resize';

import { hodu_doc_object } from '@/model/hodudoc';
import { t_event_file } from '@/model/event';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        PatientFileBoxDirList, PatientFileBoxFileList, ResizeObserver
    },
}) 
export default class PatientFileBox extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    @Prop() is_file_list_card_mode !: boolean;

    selected_patient : hodu_doc_object.patient_info | null = null;

    async mounted() : Promise<void> {
        
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        // 환자 정보가 없으면 선택 된 환자가 없다
        if( this.patient_info == null || this.patient_info.length < 1 ) {
            return;
        }

        // 가장 첫번째 환자 자동 선택
        this.selected_patient = this.patient_info[0];
    }

    /**
     * 파일함 변경
     */
    selectPatientFileBox(patient : hodu_doc_object.patient_info) : void {
        this.selected_patient = patient;
    }

    /**
     * 파일 추가
     */
    async addFile(files : File[]) : Promise<void> {

        const vue = this;

        if( this.selected_patient == null ) { return; }


        await this.hodu_show_indicator();

        const file_count : number = files.length;
        const event_files : t_event_file[] = [];
        
        // 파일 용량 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > this.DEFAULT_FILE_MAX_SIZE ) {
                alert(`${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`);
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 이미지 파일이면 미리보기용 URL을 생성한다
            // let url : string = "";
            // if( new RegExp(escape('image')).test(escape(files[i].type)) == true ) {
                
            //     let blob : Blob | null = null;

            //     // 리사이즈
            //     await this.fileReaderPromise(files[i])
            //         .then(async(pe_fr : any) => {
            //             if( pe_fr.target == null || pe_fr.target.result == null ) {
            //                 return;
            //             }

            //             let base64url : string = "";

            //             if( pe_fr.target.result instanceof ArrayBuffer ){
            //                 const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
            //                 const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
            //                 base64url = decodeURIComponent(url);
            //             } else {
            //                 base64url = pe_fr.target.result;
            //             }

            //             // 이미지 리사이즈
            //             blob = await vue.hodu_image_resize(base64url);

            //         })
                
            //     url = URL.createObjectURL(blob ? blob : files[i]);
            // }

            const url : string = URL.createObjectURL(files[i]);
            
            event_files.push({
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: url,                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            })

        }

        const form_data : FormData = new FormData();
            
        // FormData 내용 생성
        const image_or_files_count : number = files.length;
        for( let i = 0; i < image_or_files_count; i++ ) {
            form_data.append('file', files[i]);
        }

        // 임시 파일 업로드
        await this.hodu_temp_upload(form_data)
            .then(async(response) => {
                console.log(response);
                const temp_files : t_event_file[] = response.data.data.temp_files;

                // 임시파일 데이터로 객체 대체하기
                const file_count : number = event_files.length;
                for( let i = 0; i < image_or_files_count; i++ ) {
                    event_files.splice(i, 1, temp_files[i]);    
                }

            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        // 환자 파일함에 등록
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${this.selected_patient.user_id}&group_id=${this.scope_group_id}`, API_METHOD.POST, { "event_files" : event_files })
            .then(async(response) => {
                console.log(response);
                await this.get_hodu_d_info(this.scope_group_team_option.biz_id);
                this.event_bus.$emit('reselectFileList');
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

        // 파일 input 비우기
        $('#addFileLabel').val("");

        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 promise
     */
    // fileReaderPromise(file : File | Blob) : Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         const fileReader : FileReader = new FileReader();
    //         fileReader.onload = (fr) => resolve(fr);
    //         fileReader.onerror = () => reject();
    //         fileReader.readAsDataURL(file);
    //     });
    // }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        // this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        // DO NOTHING, 나중에 효과 생길때 사용
        // this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        // this.file_drag = false;
        this.addFile(event.dataTransfer.files);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.event_bus.$emit('dirListResize');
        this.event_bus.$emit('fileListResize');
    }

}
</script>

<style scoped>
    #container #section_ce .section_scroll { height:100%;    }
    #container #section_ce .schedule_box { padding:0 !important; max-width:100% !important; }
    .btnBox  { margin-right: 30px; }
    .posRel h4 { display: inline-block; font-size: 18px; margin-left: 30px; line-height: 65px; height: 65px; }
    .posRel .input_btn { margin: 11px 0 0 20px }

    .content { overflow: hidden; height: 100%; }

    #addFileLabel { display:none; }
    .addFileLabel {cursor:pointer;position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 1110000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 5px;display: block;text-align: center;font-size: 30px !important;width: 60px;border: 2px dashed #abc4ff;height: 60px;line-height: 59px;color: #608fff !important;background: rgba(71, 127, 255, 0.1) !important;}
	.addFileLabel:hover {background: #477fff !important;color: #fff !important;border: 2px solid #477fff;border-radius: 50%;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;}
	
</style>