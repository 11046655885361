<template>
    <div class="modal groupAddFileBoxNew" id="groupAddFileBoxModalNew" :class="{ edit : group_add_file_box_new_info.is_create == false, has_password : original_dir != null && original_dir.has_password == true }">
        <div class="titlebox">
            <h1 class="" id="">{{ group_add_file_box_new_info.is_create ? '폴더 추가' : '폴더 수정' }}</h1>
        </div>
        <div class="content" v-if="original_dir != null">
            <div class="detail">
                <div class="alterFolderDiv">

                    <a class="folder" :class="`folder_${hodu_color_dc_lc(dir.dir_color)}`">
                        폴더
                        <span class="ic_fd0"></span>
                        <!-- <span class="ic_fd0" :class="dir.dir_icon == 0 ? '' : `ic_fd_bk${dir.dir_icon}`"></span> -->
                    </a>

                    <div class="menuDiv">
                        <div class="btnBox">
                            <a class="title" :class="{ on : is_color_view_mode == true }" @click="setColorViewMode(true)">색상선택</a>
                            <!-- 비밀번호 설정이 안된 파일은 체크하여 비밀번호 설정가능 -->
                            <div class="pswInputDiv pswAddDiv">
                                <input type="checkbox" id="check_psw" v-model="dir.has_password" :disabled="original_dir.has_password == true">
                                <label for="check_psw"><span>비밀번호 설정</span></label>
                            </div>
                            <!-- 비밀번호 설정이 완료된 파일은 체크 해제 불가능 -->
                            <!-- <div class="pswInputDiv pswFixDiv">
                                <input type="checkbox" id="check_psw" name="chkBox[]" value="Y" onclick="return false;" checked="checked">
                                <label for="check_psw"><span>비밀번호 설정</span></label>
                            </div> -->
                            <!-- <a class="title" :class="{ on : is_color_view_mode == false }" @click="setColorViewMode(false)">타입선택</a> -->
                        </div>
                        
                        <ul class="selectUl clrUl" v-show="is_color_view_mode == true">
                            <li :key="n" v-for="n in 10" @click="selectColor(n - 1)">
                                <a :class="`dc${n - 1}${isSelectedColor(n - 1) ? ' on' : ''}`" />
                            </li>
                            <!-- <li><a class="dc0"></a></li>
                            <li><a class="dc1"></a></li>
                            <li><a class="dc2 on"></a></li>
                            <li><a class="dc3"></a></li>
                            <li><a class="dc4"></a></li>
                            <li><a class="dc5"></a></li>
                            <li><a class="dc6"></a></li>
                            <li><a class="dc7"></a></li>
                            <li><a class="dc8"></a></li>
                            <li><a class="dc9"></a></li> -->
                        </ul>

                        <ul class="selectUl typeUl" v-show="is_color_view_mode == false">
                            <!-- <li><a class="ic_fd_bk0" :class="isSelectedIcon(0) ? 'on' : ''">없음</a></li> -->
                            <li :key="n" v-for="n in 11">
                                <a :class="`${ (n - 1) == 0 ? '' : 'ic_fd ' }ic_fd_bk${n - 1}${isSelectedIcon(n - 1) ? ' on' : ''}`"
                                   @click="selectIcon(n - 1)">
                                    {{ (n - 1) == 0 ? '없음' : '' }}
                                </a>
                            </li>
                            
                            <!-- <li><a class="ic_fd ic_fd_bk1">사진</a></li>
                            <li><a class="ic_fd ic_fd_bk2">서류</a></li>
                            <li><a class="ic_fd ic_fd_bk3">공유</a></li>
                            <li><a class="ic_fd ic_fd_bk4">공부</a></li>
                            <li><a class="ic_fd ic_fd_bk5">별</a></li>
                            <li><a class="ic_fd ic_fd_bk6">잠금</a></li>
                            <li><a class="ic_fd ic_fd_bk7">NO</a></li>
                            <li><a class="ic_fd ic_fd_bk8">파일</a></li>
                            <li><a class="ic_fd ic_fd_bk9">영상</a></li>
                            <li><a class="ic_fd ic_fd_bk10">음악</a></li> -->
                        </ul>

                    </div>
                </div>
                <!-- 패스워드 #check_psw 체크시 pswBoxOn 클래스 생성-->
                <div class="titleDiv" :class="{ pswBoxOn : dir.has_password == true }">
                    <!-- <h2>폴더명</h2> -->
                    <input id="dir_text" type="text" maxlength="20" placeholder="폴더명 *20자내로 작성" 
                        :class="{ wrong : is_wrong_text == true }"
                        :value="dir.dir_name" 
                        @input="inputDirName($event.target.value)" />
                    <input id="psw_text" :class="{ wrong : is_wrong_password == true }" 
                           type="password" :placeholder="group_add_file_box_new_info.is_create == false && original_dir.has_password == true ? '비밀번호 변경시 입력' : '비밀번호 입력'" 
                           :value="password" @input="inputPassword($event.target.value)" v-if="dir.has_password == true">
                </div>
            </div>

            <div class="btns">
                <input type="button" class="closeBtn" value="취소" @click="closeModal"/>
                <input type="button" class="addBtn" value="추가" @click="createGroupFileDir"/>
                <input type="button" class="deleteBtn" value="삭제" @click="deleteGroupFileDir"/>
                <input type="button" class="saveBtn" value="저장" @click="updateGroupFileDir"/>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupAddFileModalNewInfo } from '@/store/modules/ModalInfo';
import { hodu_color } from '@/common/color';

@Component({

}) export default class GroupAddFileBoxNew extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_add_file_box_new_info !: GroupAddFileModalNewInfo;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupAddFileModalNewInfo ?: (params : GroupAddFileModalNewInfo) => void;

    dir : any = null;
    original_dir : any = null;

    is_wrong_text : boolean = false;
    is_wrong_password : boolean = false;
    is_color_view_mode : boolean = true;

    password : string = "";

    async mounted() : Promise<void> {
        //비밀번호 설정 체크시 toggle
        // $('#check_psw').change(function(){ 
        //     $('#psw_text').toggle();         
        // })

        // 폴더 색상과 타입 선택
        // $(".alterFolderDiv .title").click(function(){
        //     var getIndex = $(this).index();
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");
            
        //     // 색상선택시 색상 ul 보이기
        //     if ( getIndex == 0 ) {
        //         $(".typeUl").hide();
        //         $(".clrUl").show();
        //     // 색상선택시 타입 ul 보이기
        //     } else {
        //         $(".clrUl").hide();
        //         $(".typeUl").show();
        //     }

        // });

        // 색상 선택
        // $(".selectUl.clrUl li a").click(function(){
        //     var forColour = $(this).parent().index();
            
        //     $(this).parent().siblings().find("a").removeClass("on");
        //     $(this).addClass("on");

        //     // 색 변경
        //     for( let i = 0; i < 10; i++ ) {
        //         if( $('.alterFolderDiv .folder').hasClass(`folder_dc${i}`) ) {
        //             $('.alterFolderDiv .folder').removeClass(`folder_dc${i}`)
        //             break;
        //         }
        //     }
        //     // 폴더 사라짐 방지
        //     $('.alterFolderDiv .folder').addClass(`folder_dc${forColour}`);

        // });

        // // 타입 선택
        // $(".selectUl.typeUl li a").click(function(){
        //     var fc = $(this).parent().index();
            
        //     $(this).parent().siblings().find("a").removeClass("on");
        //     $(this).addClass("on");
            
            
        //     // 색 변경
        //     for( let i = 0; i < 11; i++ ) {
        //         if( $('.alterFolderDiv .folder span').hasClass(`ic_fd_bk${i}`) ) {
        //             $('.alterFolderDiv .folder span').removeClass(`ic_fd_bk${i}`)
        //             break;
        //         }
        //     }
        //     $('.alterFolderDiv .folder span').addClass(`ic_fd_bk${fc}`);
        // });

        if( this.group_add_file_box_new_info.is_create == false ) {

            try {

                const response = await this.hodu_api_call(`api/v1/groups/${this.group_add_file_box_new_info.group_id}/file/${this.group_add_file_box_new_info.dir_uuid}`, API_METHOD.GET);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dir ) {
                    throw Error("파일함 조회 중 오류 발생");
                }

                this.dir = response.data.data.dir;
                this.original_dir = JSON.parse(JSON.stringify(this.dir));

            } catch(e) {
                console.log(e);
            }

        }
        else {
            this.dir = {
                "group_id" : this.group_add_file_box_new_info.group_id,
                "dir_name" : "",
                "dir_color" : hodu_color.hodu_dc_2,
                "dir_icon" : 0,
                "has_password" : false,
                "up_dir_uuid" : this.group_add_file_box_new_info.up_dir_uuid
            };
            this.original_dir = JSON.parse(JSON.stringify(this.dir));
        }
        
    }

    /**
     * 그룹 파일함 생성
     */
    async createGroupFileDir() : Promise<void> {

        if( this.dir.dir_name.trim().length < 1 || this.dir.dir_name.trim().length > 20 ) {
            this.is_wrong_text = true;
            $('#dir_text').focus();
            return;
        }
        
        if( this.group_add_file_box_new_info.is_create == true && this.dir.has_password == true && this.password.trim().length < 1 ) {
            this.is_wrong_password = true;
            $('#psw_text').focus();
            return;
        }

        await this.hodu_api_call(`api/v1/groupsweb/setGroupFileDir/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.POST, {
            name: this.dir.dir_name.trim(),
            color: this.dir.dir_color,
            icon: this.dir.dir_icon,
            password : this.dir.has_password ? this.password.trim() : undefined,
            up_dir_uuid : this.dir.up_dir_uuid ? this.dir.up_dir_uuid : undefined,
        }).then(async(response) => {
            
            // 디렉토리 재조회
            if( this.group_add_file_box_new_info.create_function ) { this.group_add_file_box_new_info.create_function(); }

        }).catch(async(e) => {
            this.hodu_error_process(e, true, false);
        }).finally(() => {
            this.closeModal();
        });

    }

    /**
     * 파일함 삭제
     */
    async deleteGroupFileDir() : Promise<void> {

        if( confirm("파일함을 삭제하시겠습니까?") == false ) { return; }

        await this.hodu_api_call(`api/v1/groupsweb/setGroupFileDir/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.DELETE, {
            dir_uuid : this.group_add_file_box_new_info.dir_uuid
        }).then(async(response) => {

            // 디렉토리 재조회
            if( this.group_add_file_box_new_info.update_function ) { this.group_add_file_box_new_info.update_function(true, this.group_add_file_box_new_info.dir_uuid); }

        }).catch(async(e) => {
            this.hodu_error_process(e, true, false);
        }).finally(() => {
            this.closeModal();
        });

    }

    /**
     * 파일함 업데이트
     */
    async updateGroupFileDir() : Promise<void> {

        if( this.dir.dir_name.trim().length < 1 || this.dir.dir_name.trim().length > 20 ) {
            this.is_wrong_text = true;
            $('#dir_text').focus();
            return;
        }

        // 기존에 비밀번호 없을때만 체크
        if( this.original_dir.has_password == false && this.dir.has_password == true && this.password.trim().length < 1 ) {
            this.is_wrong_password = true;
            $('#psw_text').focus();
            return;
        }

        await this.hodu_api_call(`api/v1/groupsweb/setGroupFileDir/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.PUT, {
            dir_uuid: this.group_add_file_box_new_info.dir_uuid,
            name: this.dir.dir_name.trim(),
            color: this.dir.dir_color,
            icon: this.dir.dir_icon,
            password : this.dir.has_password && this.password.trim().length > 0 ? this.password.trim() : undefined
        }).then(async(response) => {
            
            // 디렉토리 재조회
            if( this.group_add_file_box_new_info.update_function ) { this.group_add_file_box_new_info.update_function(); }

        }).catch(async(e) => {
            this.hodu_error_process(e, true, false);
        }).finally(() => {
            this.closeModal();
        });

    }

    /**
     * 컬러 뷰 모드 플래그 세팅
     */
    setColorViewMode(flag : boolean) : void {
        this.is_color_view_mode = flag;
    }

    /**
     * 선택된 색상인지 여부 반환
     */
    isSelectedColor(index : number)  : boolean {
        switch(index) {
            case 0: return this.dir.dir_color == hodu_color.hodu_dc_0;
            case 1: return this.dir.dir_color == hodu_color.hodu_dc_1;
            case 2: return this.dir.dir_color == hodu_color.hodu_dc_2;
            case 3: return this.dir.dir_color == hodu_color.hodu_dc_3;
            case 4: return this.dir.dir_color == hodu_color.hodu_dc_4;
            case 5: return this.dir.dir_color == hodu_color.hodu_dc_5;
            case 6: return this.dir.dir_color == hodu_color.hodu_dc_6;
            case 7: return this.dir.dir_color == hodu_color.hodu_dc_7;
            case 8: return this.dir.dir_color == hodu_color.hodu_dc_8;
            case 9: return this.dir.dir_color == hodu_color.hodu_dc_9;
        }

        return false;
    }

    /**
     * 색상 선택
     */
    selectColor(index : number) : void {
        this.dir.dir_color = hodu_color[`hodu_dc_${index}`];
    }

    /**
     * 선택된 아이콘 여부
     */
    isSelectedIcon(index : number)  : boolean {
        return index == this.dir.dir_icon;
    }

    /**
     * 아이콘 선택
     */
    selectIcon(index : number) : void {
        this.dir.dir_icon = index;
    }

    /**
     * 제목 입력
     */
    inputDirName(value : string) : void {
        this.dir.dir_name = value;
        this.is_wrong_text = false;
    }

    /**
     * 비밀번호 입력
     */
    inputPassword(value : string) : void {
        this.password = value;
        this.is_wrong_password = false;
    }

    /**
     * 모달 닫기 
     */
    closeModal() : void {
        if( this.doSetGroupAddFileModalNewInfo == null ) { return; } 

        this.doSetGroupAddFileModalNewInfo({ 
            "show_modal" : false, 
            "is_create" : true, 
            "dir_uuid" : "",
            "group_id" : 0,
        });
    }

}
</script>

<style scoped>
    .modal.groupAddFileBoxNew { width:650px; position:absolute; z-index:1000000; background:#fff; border-radius:5px;margin:0 auto;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden; margin-top: -208px;}
    .modal.groupAddFileBoxNew.has_password { margin-top : -236.5px; }
    .modal.groupAddFileBoxNew h1 { font-size: 19px; padding: 0 30px;height:85px; line-height:100px; width:100%; box-sizing: border-box; border-bottom:1px solid #f1f3f5; }
    
    .modal.groupAddFileBoxNew .btns { width:100%;padding:0; border-top:1px solid #f1f3f5; }
    .modal.groupAddFileBoxNew .btns input { width:50%;transition:0.2s; background:#fff; font-size:14px; font-weight:bold; height:70px; line-height:70px;box-sizing: border-box; padding:0; }
    .modal.groupAddFileBoxNew .btns input.closeBtn:hover { color:#ff6060; }
    .modal.groupAddFileBoxNew .btns .saveBtn, .modal.groupAddFileBoxNew .btns .deleteBtn { display: none; }
    .modal.groupAddFileBoxNew .btns input.deleteBtn:hover { background:#ff6060; color:#fff; }
    .modal.groupAddFileBoxNew .btns input.saveBtn:hover, .modal.groupAddFileBoxNew .btns input.addBtn:hover { color:#477fff; }
    .modal.groupAddFileBoxNew .btns input:hover { background:#f1f3f5; }
    .modal.groupAddFileBoxNew .detail { padding:30px 40px; }
    
    .alterFolderDiv { overflow: hidden; padding-bottom:25px; }
    .alterFolderDiv .title { transition: 0.2s; text-align: left; background:#fff; font-weight: bold; height: 25px; line-height: 25px; width: auto; margin-bottom:5px;  display: inline-block; border-radius: 20px; margin-right: 15px; }
    .alterFolderDiv .title:hover { background:#f1f3f5; text-align: center; padding:0 15px; }
    .alterFolderDiv .title.on { background:#465177 !important; color:#fff; text-align: center; padding:0 15px;}
    .folder { float:left; display: inline-block; width: 120px; height: 120px; font-size:0; border:1px solid #e7e9ea; background-position: 25px center; background-size: 80px; border-radius: 5px; }
    .folder:hover { background-color:#f1f3f5; }
    .folder span { display: inline-block; width: 100%; background-size: 30px; background-repeat: no-repeat; height:100%; background-position: center 49px;  }
    .menuDiv { float:left; padding-left:20px; width: 300px;width: 420px;box-sizing: border-box; }
    .selectUl { overflow:hidden; display:inline-block; width:100%; }
    .selectUl li { float:left; display:inline-block; width: 50px; line-height: 45px; height: 45px; text-align: center; }
    .selectUl li a { transition:0.1s; display: inline-block; border-radius: 50%; width: 28px; height: 28px; margin-top:10px; }
    .selectUl.clrUl li a.on, .selectUl.clrUl li a:hover { transform: scale(1.2); }
    .selectUl.clrUl li a.on { background-image: url('../assets/images/contents/checked_fff.png'); background-repeat: no-repeat; background-position: center center; background-size: 23px; }

    .typeUl.selectUl li a.ic_fd_bk0 { color:#465177 }
    .typeUl.selectUl li a.ic_fd_bk0.on { color:#fff; }
    .typeUl.selectUl li a { float:left; background-color:#fff; width:35px;height:35px; line-height: 35px; background-size: 25px !important; background-position: center center !important;  font-weight:bold; color:#fff; border-radius: 5px;  margin-top:5px !important; }
    .typeUl.selectUl li a:hover { background-color:#f1f3f5; }
    .typeUl.selectUl li a.on { background-color:#465177; }
    .titleDiv { overflow: hidden; width:100%; height: auto; border-bottom: 0px; }

    .modal.groupAddFileBoxNew .detail .titleDiv h2 { font-size:14px; margin-bottom:10px;height:30px;line-height:30px; }
    .modal.groupAddFileBoxNew .detail .titleDiv input { transition:0.2s; line-height:55px; height:55px; padding: 0 15px; border: 1px solid #e7e9ea;width:100%; box-sizing: border-box;font-size:17px;font-weight:bold; } 
    .modal.groupAddFileBoxNew .detail .titleDiv input:hover { border:1px solid #d5dbde; background:#f1f3f5; }
    .modal.groupAddFileBoxNew .detail .titleDiv input:focus { border:1px solid #477fff; background-color:#fff !important; }
    .modal.groupAddFileBoxNew .detail .titleDiv input.wrong { border:1px solid #ff6060; background:#fff0f0; color:#ff6060; }
    .modal.groupAddFileBoxNew .detail .titleDiv input.wrong::placeholder { color:#ff6060; }

    .edit.modal.groupAddFileBoxNew .btns input { width:33.333333%; }
    .edit.modal.groupAddFileBoxNew .btns .addBtn { display:none; }
    .edit.modal.groupAddFileBoxNew .btns .saveBtn, .edit.modal.groupAddFileBoxNew .btns .deleteBtn { display:inline-block; }

    /* 비밀번호 추가 */
    .menuDiv .btnBox { position: relative; }
    .menuDiv .btnBox .pswInputDiv { float: right; }
    .menuDiv .btnBox .pswInputDiv #check_psw:disabled + label { opacity: 0.3; }
    /* .menuDiv .btnBox .pswInputDiv.pswFixDiv { display: none; } */
    /* .edit .menuDiv .btnBox .pswInputDiv.pswFixDiv { display: block; } */
    /* .edit .menuDiv .btnBox .pswInputDiv.pswAddDiv { display: none; } */
    .menuDiv input[type='checkbox']:not(old) { text-align: right; height: 30px; }
    .menuDiv input[type='checkbox']:not(old)+label { float: right; padding: 0; width: 20px; height: 20px; margin-right: 80px; border-radius: 3px; background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 0px 0px; background-size: 21px; }
    .menuDiv input[type='checkbox']:checked +label { background-color: #477fff; }
    .menuDiv input[type='checkbox']:not(old)+label span { position: absolute; top: 3px; right: 0px; font-weight: bold; }
    .titleDiv #psw_text { padding-left: 45px; background-image: url('../assets/images/folder_file/ic_fd_bk6_b4b2bf.png'); background-repeat: no-repeat; background-position: 9px center; background-size: 28px; }
</style>