<template>
    <div id="organization_emp_personal_record_detail" class="section_ce_fix">

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 id="viewTitle" class="title_doc">인사정보 상세보기</h3>
            <p class="btn_group">
                <input type="button" id="btnMod" value="수정" class="input_btn" @click.prevent="updaeteRecord" v-if="create_and_update_permission == true">
                <input type="button" id="btnDel" value="삭제" class="input_btn" @click.prevent="deleteRecord" v-if="create_and_update_permission == true">
                <input type="button" id="btnMod" value="재직증명서" class="input_btn" @click.prevent="print">
            </p>
        </div>

        <div id="hr_detail" v-if="record != null">
            <div class="hr_detail_div">
                <!-- 기본정보 -->
                <div class="hrInfor hrInforBasic">
                    <div class="basicCon hrUserImg">
                        <p class="img_bring" :style="{ 'background-image' : record.personal_record_data.profile_image != null ? `url(app_images/${record.personal_record_data.profile_image.url})` : `url(${default_user_image})`  }">사진</p>
                        <img class="dummy" :src="record.personal_record_data.profile_image != null ? `app_images/${record.personal_record_data.profile_image.url}` : default_user_image" @error="userImageError($event)" />
                    </div>
                    <div class="basicCon bsT01">
                        <ul>
                            <li>
                                <p>이름</p>
                                <p>{{ record.personal_record_data.name }}</p>
                            </li>
                            <li>
                                <p>성별</p>
                                <input type="text" id="input_text" name="input_text" class="basicTextInput" autocomplete="off" :value="record.personal_record_data.gender" onfocus="this.blur()" readonly>
                            </li>
                            <li>
                                <p>생년월일</p>
                                <input type="text" id="input_text" name="input_text" class="basicTextInput" autocomplete="off" :value="record.personal_record_data.birthday" onfocus="this.blur()" readonly>
                            </li>
                        </ul>
                    </div>
                    <div class="basicCon bsT02">
                        <ul>
                            <li>
                                <p>전화번호</p>
                                <p>{{ record.personal_record_data.tel }}</p>
                            </li>
                            <li>
                                <p>이메일</p>
                                <p>{{ record.personal_record_data.email }}</p>
                            </li>
                            <li>
                                <p>주소</p>
                                <input type="text" id="input_text" name="input_text" class="basicTextInput" autocomplete="off" :value="record.personal_record_data.address" onfocus="this.blur()" readonly>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- //기본정보 -->

                <!-- 인사정보 -->
                <div class="addListTitle">
                    <h2>인사정보</h2>
                </div>
                <div class="hrInfor hrInforHr">
                    <div class="hrCon hrT01">
                        <ul>
                            <li>
                                <p>입사일</p>
                                <label for="joinDateText" class="hr_infor_datepicker">
                                    <input type="button" id="joinDateText" name="joinDateText" :value="hodu_date_to_format_string(yyyymmddToDate(record.start_date), 'YYYY.MM.DD')" disabled>
                                </label>
                            </li>
                            <li>
                                <p>부서</p>
                                <select name="" id="" disabled>
                                    <option selected>{{ record.dept_name }}</option>
                                </select>
                            </li>
                        </ul>
                    </div>
                    <div class="hrCon hrT02">
                        <ul>
                            <li>
                                <p>직급</p>
                                <select name="" id="" disabled>
                                    <option value="1" selected>{{ record.pos_name }}</option>
                                </select>
                            </li>
                            <li>
                                <p>직책</p>
                                <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" :value="record.personal_record_data.position" onfocus="this.blur()" readonly>
                            </li>
                        </ul>
                    </div>
                    <div class="hrCon hrT03">
                        <ul>
                            <li class="threeTypeLi">
                                <p>고용형태</p>
                                <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" :value="record.personal_record_data.employment_type" onfocus="this.blur()" readonly>
                                <span class="contourLine">구분선</span>
                                <select name="" id="" disabled>
                                    <option value="0" selected>{{ record.work_type_name }}</option>
                                </select>
                            </li>
                            <li>
                                <p>내선번호</p>
                                <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" :value="record.personal_record_data.extension_tel" onfocus="this.blur()" readonly>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- //인사정보 -->

                <!-- 부가정보 -->
                <div class="addListTitle">
                    <h2>부가정보</h2>
                </div>
                <div class="hrInfor hrInforOther">
                    <!-- 부가 정보 및 첨부파일이 없을 경우 -->
                    <div class="otherCon noOtherCon" :class="{ noInforDiv : record.personal_record_data.additional_information.length < 1 }">
                        
                        <!-- 부가정보가 없을 경우 보여주는 글귀 -->
                        <div class="noResultBox" v-if="record.personal_record_data.additional_information.length < 1">
                            <p>등록된 부가정보가 없습니다.</p>
                        </div>

                        <div class="otherOff" :key="index" v-for="(additional_info, index) in record.personal_record_data.additional_information" :class="{
                            educationCon : additional_info.type == 'education',
                            careerCon : additional_info.type == 'career',
                            awardsCon : additional_info.type == 'license_and_awards',
                            completeCon : additional_info.type == 'training_courses',
                            etcCon : additional_info.type == 'etc',
                            oneThanOp : index == 0,
                            moreThanOp : index > 0,
                        }">

                            <div class="otTitle">
                                <input type="text" id="input_text" name="input_text" class="hrTextInput otherTextInput" :value="getAdditionalInfoTitle(additional_info)" onfocus="this.blur()" readonly>
                                <p class="careerTotal" v-if="additional_info.type == 'career'">
                                    <span>{{ getTotalCareer(additional_info) }}</span>
                                </p>
                            </div>

                            <hr>

                            <div class="etcTextarea" v-if="additional_info.type == 'etc'">
                                <pre id="otherEtcText" class="textarea_create">{{ additional_info.content }}</pre>
                            </div>

                            <ul v-else>
                                <li class="opLiFirst" :key="history_index" v-for="(history, history_index) in additional_info.history">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" :value="history.start || history.date">
                                        </label>
                                    </p>
                                    <span class="seper" v-if="additional_info.type != 'license_and_awards'">~</span>
                                    <p class="otherDeteP" v-if="additional_info.type != 'license_and_awards'">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" :value="history.is_currently_employed ? '재직중' : history.end">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" :value="history.name" onfocus="this.blur()" readonly>
                                    </p>
                                    <p class="otherTextP" v-if="additional_info.type == 'license_and_awards' || additional_info.type == 'training_courses'">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" :value="history.publisher || history.educational_institution" 
                                               onfocus="this.blur()" readonly>
                                    </p>
                                    <p class="otherConditionP" v-if="additional_info.type == 'education'">
                                        <select name="" id="" disabled>
                                            <option value="1" selected>{{ getEducationStatusText(history.status) }}</option>
                                        </select>
                                    </p>
                                </li>
                            </ul>

                        </div>

                        <!-- 학력 -->
                        <!-- <div class="otherOff educationCon oneThanOp">
                            <div class="otTitle">
                                <input type="text" id="input_text" name="input_text" class="hrTextInput otherTextInput" value="학력" onfocus="this.blur()" readonly>
                            </div>
                            <hr>
                            <ul>
                                <li class="opLiFirst">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2006.03">
                                        </label>
                                    </p>
                                    <span class="seper">~</span>
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2008.02">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="호두대학" onfocus="this.blur()" readonly>
                                    </p>
                                    <p class="otherConditionP">
                                        <select name="" id="" disabled>
                                            <option value="0">졸업상태</option>
                                            <option value="1" selected>졸업</option>
                                            <option value="2">졸업예정</option>
                                            <option value="3">휴학중</option>
                                        </select>
                                    </p>
                                </li>
                                <li class="opLiFirst">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2003.02">
                                        </label>
                                    </p>
                                    <span class="seper">~</span>
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2006.02">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="호두고등학교" onfocus="this.blur()" readonly>
                                    </p>
                                    <p class="otherConditionP">
                                        <select name="" id="" disabled>
                                            <option value="0">졸업상태</option>
                                            <option value="1" selected>졸업</option>
                                            <option value="2">졸업예정</option>
                                            <option value="3">휴학중</option>
                                        </select>
                                    </p>
                                </li>
                            </ul>
                        </div> -->
                        <!-- //학력 -->

                        <!-- 경력 -->
                        <!-- <div class="otherOff careerCon moreThanOp">
                            <div class="otTitle">
                                <input type="text" id="input_text" name="input_text" class="hrTextInput otherTextInput" value="경력" onfocus="this.blur()" readonly>
                                <p class="careerTotal">
                                    총<span>11년</span> <span>5개월</span>
                                </p>
                            </div>
                            <hr>
                            <ul>
                                <li class="opLiFirst">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2021.01">
                                        </label>
                                    </p>
                                    <span class="seper">~</span>
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="재직중">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="호두웨어" onfocus="this.blur()" readonly>
                                    </p>
                                </li>
                                <li class="opLiFirst">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2014.12">
                                        </label>
                                    </p>
                                    <span class="seper">~</span>
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2020.01">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="아무회사" onfocus="this.blur()" readonly>
                                    </p>
                                </li>
                                <li class="opLiFirst">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2010.08">
                                        </label>
                                    </p>
                                    <span class="seper">~</span>
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2014.09">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="아무개회사" onfocus="this.blur()" readonly>
                                    </p>
                                </li>
                                <li class="opLiFirst">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2008.08">
                                        </label>
                                    </p>
                                    <span class="seper">~</span>
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2010.07">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="가나다라회사" onfocus="this.blur()" readonly>
                                    </p>
                                </li>
                            </ul>
                        </div> -->
                        <!-- //경력 -->

                        <!-- 자격증 수상 -->
                        <!-- <div class="otherOff awardsCon moreThanOp">
                            <div class="otTitle">
                                <input type="text" id="input_text" name="input_text" class="hrTextInput otherTextInput" value="자격증 / 수상" onfocus="this.blur()" readonly>
                            </div>
                            <hr>
                            <ul>
                                <li class="opLiFirst">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2013.06">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="GTQ 그래픽기술자격" onfocus="this.blur()" readonly>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="한국생산성본부" onfocus="this.blur()" readonly>
                                    </p>
                                </li>
                                <li class="opLiFirst">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2005.05">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="전산회계운용사3급" onfocus="this.blur()" readonly>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off" value="대한상공회의소" onfocus="this.blur()" readonly>
                                    </p>
                                </li>
                            </ul>
                        </div> -->
                        <!-- //자격증 수상 -->

                        <!-- 교육이수 -->
                        <!-- <div class="otherOff completeCon moreThanOp">
                            <div class="otTitle">
                                <input type="text" id="input_text" name="input_text" class="hrTextInput otherTextInput" value="교육이수" onfocus="this.blur()" readonly>
                            </div>
                            <hr>
                            <ul>
                                <li class="opLiFirst">
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2019.06">
                                        </label>
                                    </p>
                                    <span class="seper">~</span>
                                    <p class="otherDeteP">
                                        <label for="edcDateText" class="hr_infor_datepicker edc_datepicker">
                                            <input type="button" id="edcDateText" name="edcDateText" value="2021.01">
                                        </label>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off"  value="웹 디자인 및 퍼블리싱" onfocus="this.blur()" readonly>
                                    </p>
                                    <p class="otherTextP">
                                        <input type="text" id="input_text" name="input_text" class="hrTextInput" autocomplete="off"  value="호두컴퓨터아카데미" onfocus="this.blur()" readonly>
                                    </p>
                                </li>
                            </ul>
                        </div> -->
                        <!-- //교육이수 -->

                        <!-- 기타 (텍스트공간만 제공) -->
                        <!-- <div class="otherOff etcCon moreThanOp">
                            <div class="otTitle">
                                <input type="text" id="input_text" name="input_text" class="hrTextInput otherTextInput" value="입사 후 포부" onfocus="this.blur()" readonly>
                            </div>
                            <hr>
                            <div class="etcTextarea">
                                <pre id="otherEtcText" class="textarea_create">경험하고 종합적인 역량을 쌓겠습니다!</pre>
                            </div>
                        </div> -->
                        <!-- //기타 -->
                    </div>
                </div>
                <!-- //부가정보 -->

                <!-- 첨부파일 -->
                <div class="addListTitle">
                    <h2>첨부파일</h2>
                </div>
                <div class="hrInfor hrInforFile">
                    <!-- 부가 정보 및 첨부파일이 없을 경우 -->
                    <div class="fileCon bsT01" :class="{ noInforDiv : record.personal_record_data.files.length < 1 }">
                        
                        <!-- 첨부파일이 없을 경우 보여주는 글귀 -->
                        <div class="noResultBox" v-if="record.personal_record_data.files.length < 1">
                            <p>등록된 첨부파일이 없습니다.</p>
                        </div>
                        
                        <div class="file_box" :key="index" v-for="(file, index) in record.personal_record_data.files">
                            <span class="ex">{{ file.name.substr(file.name.lastIndexOf('.') + 1) }}</span>
                            <p class="fileNm">{{ file.name }}</p>
                            <p class="size">{{ 
                                file.size >= 1 * 1024 * 1024 ? `${ (file.size / 1024 / 1024).toFixed(2) } MB` :
                                file.size >= 1 * 1024        ? `${ (file.size / 1024       ).toFixed(2) } KB` :
                                                               `${ file.size } Byte`         
                            }}</p>
                            <input type="button" value="다운로드" class="dwnldBtn" @click.prevent="download(file)">
                        </div>

                    </div>
                </div>
                <!-- //첨부파일 -->

            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { t_event_file } from '@/model/event';
import { organization_modal_interface } from '@/model/organization';

@Component({
    components: {
        
    },
})
export default class OrganizationEmpPersonalRecordDetail extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordPrintModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordPrintModalInfo) => void;

    target_user_id : number = 0;
    record : any = null;
    default_user_image : string = require('@/assets/images/contents/user_photo_fff.png');
    create_and_update_permission : boolean = false;

    beforeMount() {
        this.target_user_id = this.$route.params.user_id ? Number(this.$route.params.user_id) : 0;
    }

    mounted() : void {

        if( isNaN(this.target_user_id) || this.target_user_id < 1 ) {
            this.movePrevPage();
            return;
        }

        this.getPersonalRecord();
    }

    async getPersonalRecord() {

        try {

            this.create_and_update_permission = this.get_group_role(this.scope_group_id) == 'ADMIN';

            if( this.create_and_update_permission == false ) {

                const permission_response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/personal_record/permission`, API_METHOD.GET);

                console.log(permission_response);

                if( permission_response == null || !this.isHttpStatusSuccess(permission_response.status) || !permission_response.data || !permission_response.data.data ) {
                    throw new Error("인사정보 등록 및 수정 권한 조회중 오류발생");
                }

                const permission = permission_response.data.data.permission;

                if( permission != null && permission.length > 0 ) {
                    for( const perm of permission ) {
                        if( perm.user_id == this.user_id ) {
                            this.create_and_update_permission = true;
                            break;
                        } 
                    }
                }
            }

            const group_id = this.scope_group_id;
            const user_id = this.target_user_id;

            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}`, API_METHOD.GET);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.record ) {
                throw new Error("인사정보 조회중 오류발생");
            }

            this.record = JSON.parse(JSON.stringify(response.data.data.record));

        } catch(e) {
            this.hodu_error_process(e, false, false ,true);
        }

    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
     yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    getAdditionalInfoTitle(info) {

        switch( info.type ) {
            case "education":
                return "학력";
            case "career":
                return "경력";
            case "license_and_awards":
                return "자격증 / 수상";
            case "training_courses":
                return "교육이수";
            case "etc":
                return info.title;
        }

        return "";
    }

    getEducationStatusText(status) {
        switch( status ) {
            case "graduate":
                return "졸업";
            case "attending":
                return "졸업예정";
            case "take_off_school":
                return "휴학";
            case "dropout":
                return "중퇴";
        }

        return "";
    }

    getTotalCareer(info) {

        let total_career : string = "";

        let month_count : number = 0;
        for( let history of info.history ) {

            let start :string = history.start;
            let end : string = history.end;

            if( history.is_currently_employed == true ) {
                end = this.hodu_date_to_format_string(new Date(), 'YYYY.MM');
            }

            let start_split : string[] = start.split(".");
            let end_split : string[] = end.split(".");

            let start_month_count : number = 0;
            let end_month_count : number = 0;

            for( let i = 0; i < start_split.length; i++ ) {
                let start_str : string = start_split[i];

                let count : number = Number(start_str);
                if( i == 0 ) count *= 12;

                start_month_count += count;
            }

            for( let i = 0; i < end_split.length; i++ ) {
                let end_str : string = end_split[i];

                let count : number = Number(end_str);
                if( i == 0 ) count *= 12;

                end_month_count += count;
            }

            month_count += (end_month_count - start_month_count);
        }
            
        // 년단위로 딱 떨어지는 경우
        if( month_count > 0 && month_count % 12 == 0 ) {
            total_career = `총 ${Math.floor(month_count / 12)} 년`;
        }
        // 년단위 이하
        else if( month_count < 12 ) {
            total_career = `총 ${Math.floor(month_count)} 개월`;
        }
        // n년 m개월
        else {
            total_career = `총 ${Math.floor(month_count / 12)}년 ${Math.floor(month_count % 12)} 개월`;
        }

        return total_career;
    }

    /**
     * 파일 다운로드
     */
     download(file : t_event_file) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    userImageError(event) : void {
        $(event.target).parent().find('p.img_bring').css('background-image', require('@/assets/images/contents/user_photo_fff.png'))
    }

    updaeteRecord() {
        if( this.record == null ) return;
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/organization/personal_record/create/${this.record.user_id}`);
    }

    deleteRecord() { 
        if( this.record == null ) return;
        this.hodu_show_dialog("cancel", `인사정보를 삭제하시겠습니까?`, ["아니오", "예"], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/personal_record/${this.record.user_id}`, API_METHOD.DELETE);

                    if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("인사 정보 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "인사정보 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);
    }

    print() {
        this.doSetOrganizationEmpPersonalRecordPrintModalInfo?.({
            show_modal : true,
            group_id : this.scope_group_id,
            user_id : this.target_user_id,
            is_quitter : false,
        });
    }

}
</script>

<style scoped>
    #hr_detail { position: relative; overflow: visible; height: 100%; color: #232848; overflow-y: auto; padding-bottom: 150px; box-sizing: border-box; }
    #hr_detail input[type="text"] { width: 75%; box-sizing: border-box; white-space: nowrap; text-overflow: ellipsis; color: #888a96; font-weight: bold; line-height: 30px; cursor: auto; }
    #hr_detail select {
        box-sizing: border-box; white-space: nowrap; text-overflow: ellipsis; color: #888a96; font-weight: bold; flex: 1 1 0;
        -webkit-appearance:none; /* 크롬 화살표 없애기 */
        -moz-appearance:none; /* 파이어폭스 화살표 없애기 */
        appearance:none /* 화살표 없애기 */
    }
    #hr_detail input[type='checkbox']:not(old)+label { background-color: #c1cfd8; background-image: url('../../assets/images/contents/checked_fff.png'); background-position: 50%; padding: 0; width: 20px; height: 20px; border-radius: 3px; background-size: 23px; background-position: -1px -1px; font-size: 0; vertical-align: middle; }
    #hr_detail input[type='checkbox']:not(old):checked + label { background-color: #477fff; }
    #hr_detail input[type='button'] { cursor: auto; }
    #hr_detail .addListTitle { min-width: 700px; /* width: 60%; */ max-width: 960px; margin-top: 45px; line-height: 40px; font-size: 16px; text-indent: 10px; position: relative; }
    #hr_detail .hr_infor_datepicker input { background-color: #fff; font-weight: bold; overflow: hidden; white-space: nowrap; box-sizing: border-box; }
    #hr_detail hr { margin: 0.5em 0; }

    #hr_detail .hrT02 select { width: 75%; flex: auto; }
    .hr_detail_div { padding: 25px; box-sizing: border-box; }
    .hrInfor { min-width: 700px; /* width: 60%; */ max-width: 960px; min-height: 150px; overflow: hidden; display: flex; justify-content: space-between; align-items: center; padding: 0 20px; background: #fff; border: 2px solid #f1f3f5; box-sizing: border-box; border-radius: 10px; }
    .hrInfor .basicCon { box-sizing: border-box; }
    .basicCon.hrUserImg { width: 120px; display: flex; align-items: center; }
    .basicCon.bsT01 { width: 250px; }
    .basicCon.bsT02 { flex: 1 1 0; }
    .hrUserImg .img_bring { width: 85px; height: 85px; position: relative; display: inline-block; font-size: 0; border-radius: 5px; background: #e7e9ea no-repeat center center; background-size: 100% auto; }
    .hrInfor li { display: flex; line-height: 30px; font-size: 13px; }
    .hrInfor li p { width: 80px; font-weight: bold; box-sizing: border-box; }
    .hrInfor li p:last-child { width: 75%; opacity: .6; }

    .hrInfor.hrInforHr { min-height: 110px; }
    .hrCon { flex: 1 1 0; }
    #hr_detail .threeTypeLi input[type="text"] { width: 35%; }
    .contourLine { width: 2px; height: 20px; background: #e7e9ea; font-size: 0; vertical-align: top; margin: 7.5px 10px 0 10px; }

    /* 부가정보 및 첨부파일 없을 경우 */
    .otherCon.noInforDiv { padding-bottom: 0px; }
    .noResultBox { display: none; }
    .noInforDiv .noResultBox { display: block; }
    .noResultBox p { width: 100%; text-align: center; font-size: 13px; font-weight: bold; color: #9ea5b3; }
    .noInforDiv .otherOff { display: none; }
    .fileCon.noInforDiv { width: 100%; }
    #hr_detail .noInforDiv .file_box { display: none; }

    .otherOff { margin-top: 15px; }
    .addListTitle .btn_group { position: absolute; top: 0; right: 15px; margin-top: -7px; }
    .hrInfor.hrInforOther { height: auto; min-height: 100px; }
    .otherCon { width: 100%; line-height: 30px; padding-bottom: 20px; box-sizing: border-box; }
    .otherCon li { padding: 0 10px; box-sizing: border-box; position: relative; }
    .otherCon .notiText { text-align: right; margin-top: 10px; }
    #hr_detail .hrInforOther input[type="text"] { width: 100%; color: #232848; font-weight: bold; padding: 0 10px; }
    span.seper { font-size: 13px; font-weight: bold; margin: 0 10px; color: #acb2bf; }
    .hrInfor .otherCon li p:last-child { width: auto; opacity: 1; }

    /* 학력 경력 */
    .hrInfor li p.otherDeteP { width: auto; }
    .hrInfor li p.otherTextP { margin: 0 10px; width: auto; }
    .officeInCk { color: #888a96; margin-right: 10px; line-height: 30px; }
    #hr_detail .hrInforOther .careerCon .otherTextInput { width: 80px !important; display: inline-block; }
    p.careerTotal { display: inline-block; color: #ff6363; line-height: 30px; }
    p.careerTotal span { color: #ff6363; }

    /* 한개 이상의 정보 추가 시 */
    .otherOff.moreThanOp { margin-top: 45px; overflow: hidden; }
    .otherOff.moreThanOp .otTitle { display: inline-block; width: 100%; padding-right: 20px; box-sizing: border-box; }
    .otherOff.moreThanOp .btn_group { position: static; display: inline-block; float: right; }

    .etcTextarea { width: 100%; box-sizing: border-box; overflow: hidden; }
    .etcTextarea .textarea_create { width: 100%; padding: 10px 10px; box-sizing: border-box; word-break: break-all; white-space: pre-line; }

    .hrInfor.hrInforFile { min-height: 80px; }
    #hr_detail .file_create_box { display: none; }
    #hr_detail .file_box {  display: flex; position: relative; overflow: hidden; height: 40px; line-height: 40px; padding: 5px 0; font-weight: bold; }
    #hr_detail .file_box .ex { font-size: 12px; width: 60px; text-align: center; border-radius: 5px; border: 1px solid #e7e9ea; margin-top: 2px; text-transform: uppercase; color: #477fff; margin-right: 10px; line-height: 35px; height: 35px; }
    #hr_detail .file_box .fileNm { width: 420px; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; cursor: pointer; }
    #hr_detail .file_box .size { width: 130px; }
    #hr_detail .file_box .dwnldBtn { color: #477fff !important; width: 100px; text-align: center; margin-right: 10px; background: none; border-radius: 20px; margin-top: 2px; box-sizing: border-box; cursor: pointer; }

    #organization_emp_personal_record_detail ::-webkit-scrollbar{width: 6px; height : 6px;}
    #organization_emp_personal_record_detail ::-webkit-scrollbar-track{background-color: transparent;}
    #organization_emp_personal_record_detail ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

    #hr_detail select:disabled { opacity: 1;}

</style>