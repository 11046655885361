<template>
    <!-- 임시 파일임 DoctorCreate 에서 하나로 만들 것!!!  -->
    <div class="doctor_create section_ce_fix">
        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">의사 관리</h3>
            <p class="btn_group">
                <input type="button" id="" class="input_btn" placeholder="" value="수정" title="수정"/>
                <!-- <input type="button" id="" class="input_btn" placeholder="" value="취소" title="취소"/>
                <input type="button" id="" class="input_btn" placeholder="" value="삭제" title="삭제"/> -->
            </p>
        </div>

        <div id="" class="content">

            <div class="basicDiv">
                <div class="scroll">
                    <p class="title">기본설정</p>
                    <div class="firstDiv">
                        <div class="docImgDiv">
                            <!-- label에 background-image로 사진 추가 -->
                            <label for="addDocPic">사진</label>
                            <input id="addDocPic" type="file" value="의사 사진 추가" />
                            <input type="button" class="del" value="삭제">
                        </div>
                        <div class="docDetailDiv">
                            <ul>
                                <li>
                                    <label for="specialityId">진료과</label>
                                    <select name="" id="specialityId">
                                        <option value="" selected>내과</option>
                                        <option value="">이비인후과</option>
                                        <option value="">피부과</option>
                                        <option value="">신경과</option>
                                    </select>
                                </li>
                                <li>
                                    <label for="docClrId">의사 전용 색상</label>
                                    <input type="button" id="docClrId" value="초록" @click="showCommonColorPickerModal"/>
                                    <span class="clr dc5"></span>
                                </li>
                                <li>
                                    <label for="docNameId">이름</label>
                                    <input type="text" id="docNameId" placeholder="의사 이름" value="김동현" />
                                </li>
                                <li>
                                    <label for="docCodeId">자체코드</label>
                                    <input type="text" id="docCodeId" placeholder="병원 자체코드를 작성하세요" value="3EBD933890001" />
                                </li>
                                <li>
                                    <label for="">직급</label>
                                    <select name="" id="">
                                        <option value="" selected>원장</option>
                                        <option value="">부원장</option>
                                        <option value="">직원</option>
                                    </select>
                                </li>
                                <li>
                                    <label for="">직업</label>
                                    <select name="" id="">
                                        <option value="">의료진</option>
                                        <option value="">전문의</option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="setTimeDiv">
                        <ul>
                            <li>
                                <p class="title fl">근무기간</p>
                                <div class="btns">
                                    <input type="button" class="addPlusBtn" value="근무기간 추가" @click="addWorkTime" />
                                </div>
                            </li>
                            <li class="contractUl">
                                <div class="selectDiv">
                                    <select class="contractSelect">
                                        <!-- <option value="add">근무기간 추가</option> -->
                                        <option value="" selected>2020.04.01 ~ 2021.04.01</option>
                                        <option value="">2019.04.01 ~ 2020.04.01</option>
                                    </select>
                                    <!-- <div class="descript">
                                        <ul>
                                            <li>
                                                <span class="morn clr">노랑</span>
                                                <span class="txt">오전</span>
                                            </li>
                                            <li>
                                                <span class="night clr">보라</span>
                                                <span class="txt">오후</span>
                                            </li>
                                            <li>
                                                <span class="allday clr">파랑</span>
                                                <span class="txt">종일</span>
                                            </li>
                                            <li>
                                                <span class="off clr">빨강</span>
                                                <span class="txt">휴무</span>
                                            </li>
                                        </ul>
                                    </div> -->
                                </div>
                                <ul>
                                    <li @click="showDoctorTimeSettingModalInfo(false)">
                                        <a>
                                            <span class="indi allday">종일</span>
                                            <p class="day">월요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">오후 1:30 ~ 6:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi allday">종일</span>
                                            <p class="day">화요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">오후 1:30 ~ 6:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi allday">종일</span>
                                            <p class="day">수요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">오후 1:30 ~ 6:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi off">휴일</span>
                                            <p class="day">목요일</p>
                                            <p class="workHours"><span>off</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi night">오후</span>
                                            <p class="day">금요일</p>
                                            <p class="workHours"><span class="from">off</span> ~ <span class="to">오후 1:00 ~ 6:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi morn">오전</span>
                                            <p class="day">토요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">off</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi off">휴관</span>
                                            <p class="day">일요일</p>
                                            <p class="workHours"><span>휴관</span></p>
                                        </a>
                                    </li>
                                </ul>
                                <input type="button" class="delContract" value="삭제">
                            </li>
                        </ul>
                    </div>

                    <!-- 학력 / 경력 -->
                    <div class="experienceDiv">
                        <p class="title">학력 / 경력</p>
                        <ul>
                            <!-- 인풋에 글자 작성시 리스트에 on 추가 -->
                            <li class="on"><span class="dot"></span><input type="text" placeholder="순차적으로 학력과 경력을 작성하세요" value="한양대 의대 졸업" disabled><input type="button" class="delThis" value="삭제" /></li>
                            <li><span class="dot"></span><input type="text" placeholder="순차적으로 학력과 경력을 작성하세요" value="한양대 의대 졸업" disabled><input type="button" class="delThis" value="삭제" /></li>
                            <li><span class="dot"></span><input type="text" placeholder="순차적으로 학력과 경력을 작성하세요" value="한양대 의대 졸업" disabled><input type="button" class="delThis" value="삭제" /></li>
                        </ul>
                        <input type="button" class="addList" value="추가" />
                    </div>
                </div>
            </div>
            <div class="offDiv">
                <div class="titleDiv">
                    <h4>휴가 관리</h4>
                    <select name="" class="selectHolidayYear" id="">
                        <option value="">2019</option>
                        <option value="" selected>2020</option>
                        <option value="">2021</option>
                        <option value="">2022</option>
                        <option value="">2023</option>
                    </select>
                    <div class="btns">
                        <input type="button" class="" value="추가" @click="showDoctorOffSettingModal(true)"> 
                    </div>
                </div>
                <div class="addHolidayDiv">
                    <ul>
                        <li>
                            <a @click="showDoctorOffSettingModal(false)">
                                <p class="offTime">종일</p>
                                <p class="offTitle">여행</p>
                                <p class="offDay">04.10 금 <span>종일</span> ~ 14 화 <span>9:00 ~  13:30</span></p>
                                <p class="howManyDays">3.5 일</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="offTime morn">오전</p>
                                <p class="offTitle">개인사유</p>
                                <p class="offDay">03.10 화 <span>9:00 ~ 13:30</span></p>
                                <p class="howManyDays">0.5 일</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="offTime">종일</p>
                                <p class="offTitle">개인사유</p>
                                <p class="offDay">03.02 월 <span>종일</span></p>
                                <p class="howManyDays">1 일</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="offTime night">오후</p>
                                <p class="offTitle">컨퍼런스참여</p>
                                <p class="offDay">01.02 목 <span>13:30 ~ 18:00</span></p>
                                <p class="howManyDays">0.5 일</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="addedDiv">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info } from '@/model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class DoctorDetail extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDoctorTimeSettingModalInfo ?: (params : hodu_doc_modal_info.DoctorTimeSettingModalInfo) => void;
    @ModalInfo.Action doSetDoctorOffSettingModalInfo  ?: (params : hodu_doc_modal_info.DoctorOffSettingModalInfo) => void;
    @ModalInfo.Action doSetShowCommonColorPickerModal ?: (params : boolean) => void;

    mounted() : void {
        $(".checkDiv a").click(function(){
            var getIndex = $(this).index();
            $(this).toggleClass("on");
            if (getIndex == 2) {
                    $(this).siblings().removeClass("on");
            } else {
                
                
            }
        });
    }

    /**
     * 근무시간 추가
     */
    addWorkTime(event : any) : void {
        this.showDoctorTimeSettingModalInfo(true);
    }
    
    /**
     * 의사 근무 시간 설정 모달 보이기
     */
    showDoctorTimeSettingModalInfo(is_create_work_time : boolean) : void {
        if( this.doSetDoctorTimeSettingModalInfo ) { 
            // this.doSetDoctorTimeSettingModalInfo({
            //     "show_modal" : true,
            //     "is_create_work_time" : is_create_work_time
            // }); 
        }
    }

    /**
     * 휴가 관리
     */
    showDoctorOffSettingModal(is_create : boolean) : void {
        if( this.doSetDoctorOffSettingModalInfo ) { 
            this.doSetDoctorOffSettingModalInfo({
                "show_modal" : true,
                "is_create" : is_create,
            }); 
        }
    }

    /**
     * 색상 선택
     */
    showCommonColorPickerModal() : void {
        if( this.doSetShowCommonColorPickerModal ) { this.doSetShowCommonColorPickerModal(true); }
    }

}
</script>

<style scoped>
    .basicDiv .docImgDiv label { background-image: url(../../assets/images/footer/doc_2.png) !IMPORTANT; border-color:#13D08B !important }


    .content { height:100%;overflow: hidden; }
    .titleDiv { overflow: hidden; padding: 30px; }
    .titleDiv h4 { font-size: 17px;line-height: 50px; height: 50px;display:inline-block }
    .titleDiv .btns { display:inline-block; float:right;  }
    .basicDiv { width:60%;float:left;height:100%;  }
    .basicDiv .title { font-size:17px;font-weight: bold; }
    .basicDiv > .scroll > .title {  margin: 30px 30px 0; }
    .basicDiv .docImgDiv label { width: 100px; height:100px;border-radius: 50%; box-sizing: border-box; border: 3px solid #477fff; display: block;  font-size:0; cursor:pointer; background-image: url(../../assets/images/contents/im_photoB.gif); background-size:cover;background-position: center center; background-repeat: no-repeat; }
    .basicDiv .docImgDiv input[type="file"] { display:none; }
    .basicDiv .docImgDiv .del { transition:0.2s; position:absolute; right:0;bottom:0; display: block; width: 30px; height: 30px; background:#ff6060  url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .basicDiv .docImgDiv .del:hover { background-color: #fd1818 }
    .basicDiv .firstDiv { position:relative; padding: 30px 30px 30px 170px; border-bottom:5px solid #e7e9ea; }
    .docImgDiv { position: absolute; left:40px;top:59px;  }
    .docDetailDiv ul { overflow: hidden; }
    .docDetailDiv li { position: relative; float:left; margin-bottom:15px; width:50%;display:inline-block; padding-right: 20px; box-sizing: border-box; height: 78px; }
    .docDetailDiv select { font-weight:bold; transition:0.2s; cursor:pointer; appearance: none;padding-left: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; border-radius: 5px;background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 96%  center; background-repeat: no-repeat }
    .docDetailDiv select:hover { background-color:#f1f3f5; }
    .docDetailDiv select:focus { border-color:#477fff; background-color:#fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .docDetailDiv label { font-size: 13px;display:block; line-height: 23px; height:23px; margin-bottom:5px; } 
    .docDetailDiv input[type="text"] { transition:0.2s; font-weight: bold; padding: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; }
    .docDetailDiv input[type="text"]:hover { background:#f1f3f5; }
    .docDetailDiv input[type="text"]:focus { border-color:#477fff; background:#fff; }

    .experienceDiv { padding: 30px;margin-top:10px;border-top: 5px solid #e7e9ea; }
    .experienceDiv ul { margin-top:20px; border-top:1px solid #f1f3f5;padding-top:10px; }
    .creating .experienceDiv ul { border-top:none;padding-top:0; }
    .experienceDiv ul li { position: relative;transition:0.2s; line-height: 48px; height: 48px; width:100%; padding-left: 30px; box-sizing: border-box; }
    .creating .experienceDiv ul li { line-height: 60px; height: 60px; }
    .experienceDiv ul li input[type="text"]:disabled { cursor:default; color:#232848; background:#fff !important;border:0 none; border-bottom: 1px solid #f1f3f5; width: 100%;line-height: 45px; }
    .creating .experienceDiv ul li input[type="text"] { cursor: text; transition:0.2s; font-weight:bold; width: 100%; border: 1px solid #f1f3f5; background:#fff; height: 45px; line-height: 45px; padding: 15px; box-sizing: border-box; }
    .creating .experienceDiv ul li input[type="text"]:hover { background:#f1f3f5; }
    .creating .experienceDiv ul li input[type="text"]:focus { background:#fff; border-color:#477fff }
    .creating .experienceDiv ul li.on { padding-right: 50px; }
    .experienceDiv ul li input.delThis { display:none; transition:0.2s; display:none; position: absolute; right: 0;top:14px; width: 30px; height: 30px; border-radius: 50%; font-size: 0;background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; }
    .experienceDiv ul li input.delThis:hover { background-color: #fd1818 }
    .experienceDiv ul li.on input.delThis { display:none; } 
    .creating .experienceDiv ul li.on input.delThis { display:block; }

    .creating .experienceDiv ul li span.dot { top: 19px; }
    .experienceDiv ul li span.dot { position: absolute; left:5px; top: 14px; display: inline-block;float: left;width: 8px;height: 8px;margin-right: 10px;margin-top: 7px;border-radius: 50%;background: #b7bbbf; }
    .creating .experienceDiv ul li.on span.dot { background-color:#477fff; }
    .experienceDiv .addList { display:none; width: 100px; height: 35px; line-height: 33px; background:#fff; border: 1px solid #e7e9ea; border-radius: 20px; font-weight: bold; margin:0 auto; margin-top:20px; margin-bottom: 50px;}
    .experienceDiv .addList:hover { background:#477fff; color:#fff;border-color:#477fff; }
    .creating .experienceDiv .addList { display: block; }

    .setTimeDiv { padding: 30px; }
    .setTimeDiv .title { font-size: 16px; font-weight: bold; display: block; margin-bottom: 20px;    margin-top: 10px; }
    .setTimeDiv > ul > li { overflow: hidden; position:relative;}
    .setTimeDiv > ul > li > input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; }
    .setTimeDiv > ul > li > input:hover { background-color:#f1f3f5; }
    .setTimeDiv > ul > li > input:focus { background-color:#fff; border-color:#477fff; }
    .setTimeDiv > ul > li > span { padding-right:20px; }
    .setTimeDiv .descript { position: absolute; right:0; top:16px; }
    .setTimeDiv .descript li { overflow:hidden; display:inline-block;margin-right:20px; border: none;line-height: 20px; height:20px; }
    .setTimeDiv .descript li:last-child { margin-right:0; }
    .setTimeDiv .descript li .clr { float:left; margin-right:5px; margin-top: 4px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; }
    .setTimeDiv .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .setTimeDiv .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .setTimeDiv .descript li .clr.allday {background: #477fff !important;border-color: #477fff;}
    .setTimeDiv .descript li .clr.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .setTimeDiv .delContract { border-radius: 23px; background:#ff6060; border:0 none;  color:#fff; padding: 0 50px; font-size: 14px; line-height: 40px; height:40px; margin:0 auto; display:block; margin-top:20px; }
    .setTimeDiv .delContract:hover, .setTimeDiv .delContract:focus { background: #ff2e2e }
    .setTimeDiv .btns { float:right; }
    .selectDiv {  }
    .contractSelect { transition:0.2s; cursor:pointer; font-size: 14px; font-weight:bold; appearance: none; border-radius: 5px; line-height: 50px; height:55px; border:1px solid #e7e9ea;padding: 0 20px; width:100%; box-sizing: border-box; background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 98%  center; background-repeat: no-repeat }
    .contractSelect:hover { background-color:#f1f3f5;  }
    .contractSelect:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .contractSelect option { height: 40px; padding:20px 0; }
    .contractUl li { position:relative; font-size: 12px; line-height: 47px; height:47px; border-bottom:1px solid #f1f3f5; }
    .contractUl li a { display:inline-block; width:100%; height: 100%; }
    .contractUl li a:hover { background:#f7f9fb; font-weight:bold; }
    .contractUl li .day {  display:inline-block;padding: 0 30px 0 70px; text-align: center; }
    .contractUl li .workHours { display: inline-block; border-left: 1px solid #e7e9ea; line-height: 15px; }
    .workHours span {display:inline-block; padding: 0 25px; width: 150px; box-sizing: border-box; }
    .contractUl li .indi { transition: 0.1s; width: auto !important; font-size: 12px; font-weight:bold; height: 20px; line-height: 20px; margin-top:-7px; color:#fff; padding:0 10px; border-radius: 20px; left:10px; }
    .contractUl .indi { transition: 0s; position: absolute; left:20px; top:20px; width:10px; height:10px; display:block; border-radius: 50%; font-size:0; } 
    .contractUl .indi.morn {background: #ffc00e !important;border-color: #f3b300;}
    .contractUl .indi.night {background: #846eff !important;border-color: #6c52ff;}
    .contractUl .indi.allday {background: #477fff !important;border-color: #477fff;}
    .contractUl .indi.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .dayUl  { overflow:hidden; border-right:1px solid #e7e9ea}
    .dayUl li { display: inline-block; width: 14.2857142857%; float:left; text-align:center; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .dayUl li:hover .day { background:#f1f3f5; }
    .dayUl li p { line-height: 35px; height: 35px; font-weight: bold; border-bottom: 1px solid #e7e9ea;border-top: 1px solid #e7e9ea; }
    .offDay .checkDiv a { cursor: default; }
    .offDay .checkDiv a span { opacity:0.3; cursor: default; background:#fff !important;  }
    .checkDiv a { display: block; height:45px; line-height:45px; box-sizing: border-box; font-weight: bold; border-bottom: 1px solid #e7e9ea; }
    .checkDiv a.on:hover { cursor:default }
    .checkDiv a.on span { background:#477fff !important;border-color:#1b5fff; color:#fff; }
    .checkDiv a:hover span { background: #f1f3f5; }
    .checkDiv a span { transition:0.2s; display: block; height:100%; line-height: 45px; background:#fff; box-sizing: border-box;}
    .checkDiv a.on.morn span {background: #ffc00e !important;border-color: #f3b300;}
    .checkDiv a.on.night span {background: #846eff !important;border-color: #6c52ff;}
    .checkDiv a.on.off span { background: #a1a5bb !important;border-color: red;}
    #docClrId {transition:0.2s;width: 100%;height: 50px;line-height: 50px;border-radius: 5px;background: #fff;border: 1px solid #e7e9ea;box-sizing: border-box;padding: 0 15px;text-align: left;font-weight: bold;  }
    #docClrId:hover { background: #f1f3f5; }
    .docDetailDiv .clr { position:absolute; right:35px; top:43px; display: inline-block; width:20px;height: 20px; border-radius: 50%; ; }


    .offDiv { width: 40%;float:left; height:100%; border-left:1px solid #e7e9ea;box-sizing: border-box;  }
    .offDiv .selectHolidayYear { transition:0.2s; cursor:pointer; margin-top:10px; margin-left:15px; float:left; border: 1px solid #232848;border-radius: 20px; font-size: 13px; line-height: 26px; height:30px; appearance: none; padding: 0 30px 0 15px; font-weight: bold;background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 90%  center; background-repeat: no-repeat !important }
    .offDiv .selectHolidayYear:hover { background-color:#f1f3f5; }
    .offDiv .selectHolidayYear:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }

    .titleDiv { background:#fff; padding-bottom: 10px; padding-top:20px; }
    .titleDiv h4 { float:left;  }
    .offDiv .btns input, .addPlusBtn {transition:0.2s;background: url(../../assets/images/contents/ic_add.png) no-repeat center center; font-size: 0;width: 35px;height:35px; background-size: 40px; border-radius: 50%;margin-top: 5px;}
    .offDiv .btns input:hover, .addPlusBtn:hover {background-image: url(../../assets/images/contents/btn_add_fff.png); background-color:#477fff;}

    .addHolidayDiv li { position:relative; background:#fff; font-weight:bold;padding:0 30px; }
    .addHolidayDiv li a { border-bottom: 1px solid #f1f3f5; line-height: 55px; height: 80px; display: block; padding-left: 50px; padding-right: 100px; }
    .addHolidayDiv li:first-child a {  border-top: 1px solid #e7e9ea; }
    .addHolidayDiv li a p { display:inline-block; }
    .addHolidayDiv li .offTime {border:1px solid; position:absolute; left:30px;top:18px; padding:0 6px; border-radius: 2px; background:#477fff; color:#fff;height:20px; line-height: 20px; font-weight: bold; margin-right: 15px; border-radius: 3px; }
    .offDiv p.morn {background: #ffc00e !important;border-color: #f3b300;}
    .offDiv p.night {background: #846eff !important;border-color: #6c52ff;}
    .offDiv p.off { background: #a1a5bb !important;border-color: red;}
    .addHolidayDiv li .offTitle { width: 100%; font-size:14px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .addHolidayDiv li .offDay {left:80px;top:25px; position: absolute; }
    .offDay span {padding: 0 3px;background:#f1f3f5;margin-left:3px; font-weight: normal; }
    .howManyDays { position:absolute; right: 30px;top:0; }


    .grp.settingBg {position: static;padding: 0 0 !important;width: 100% !important;max-width: 100% !important;min-width: 100% !important;}
    #container #section_ce .schedule_box {width: 100%;max-width: 100%;padding-left: 0;padding-bottom: 0;padding-right: 0;}
    #container #section_ce .section_scroll {margin-top: 0 !important;}
    .left_area_close .content {padding-left: 61px !important;box-sizing: border-box;}

    /* 추후 삭제 */
    .basicDiv { overflow-y: auto; }
    .basicDiv .scroll { height: 1400px;  }
</style>