<template>
    <div id="temps_box" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 id="viewTitle" class="title_doc">임시보관함</h3> 
        </div>

        <div id="tempsList">
            <div class="selectBox">
                <div class="posRel">
                    <div class="bg">
                        <div class="selectDiv">
                            <select id="tempsTypeSel" v-model="filter_type">
                                <option value="ALL">전체보기</option>
                                <option value="BASIC">일정</option>
                                <option value="REPORT">업무일지</option>
                                <option value="MEETINGLOG">회의록</option>
                                <option value="WORK">프로젝트</option>
                            </select>
                        </div>
                        <div class="exTextDiv">
                            <p>임시저장글은 30일간 보관됩니다.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="tempsConDiv" class="content">
                <ul class="sortHeader workHeader">
                    <li class="num">No</li>
                    <li class="type">종류</li>
                    <li class="title">제목</li>
                    <li class="time">작성일</li>
                    <li class="period">기간</li>
                </ul>
                <div id="temps_list_scroll" class="temps_list_con">
                    <ul class="tempsConUl">

                        <li :key="temp.temp_id" v-for="(temp, index) in computedTemps">
                            <p class="num">{{ index + 1 }}</p>
                            <p class="type">{{ getTempType(temp) }}</p>
                            <p class="title">{{ hodu_string_validation(getTempTitle(temp), '(제목 없음)') }}</p>
                            <p class="time">{{ hodu_date_to_format_string(temp.audit_modified, 'YYYY.MM.DD') }}</p>
                            <p class="period">D-<span>{{ getDDay(temp.audit_modified) }}</span></p>
                            <a href="#" class="seeDets" @click.prevent="goDetail(temp)">자세히 보기</a>
                            <a href="#" class="delBtn" @click.prevent="deleteTemp(temp)">삭제</a>
                        </li>

                        <!-- <li>
                            <p class="num">1</p>
                            <p class="type">일정</p>
                            <p class="title">8월 여름휴가 일정</p>
                            <p class="time">2023.08.16</p>
                            <p class="period">D-<span>30</span></p>
                            <a href="#" class="seeDets">자세히 보기</a>
                            <a href="#" class="delBtn">삭제</a>
                        </li>
                        <li>
                            <p class="num">2</p>
                            <p class="type">일정</p>
                            <p class="title">테스트 일정</p>
                            <p class="time">2023.08.11</p>
                            <p class="period">D-<span>25</span></p>
                            <a href="#" class="seeDets">자세히 보기</a>
                            <a href="#" class="delBtn">삭제</a>
                        </li>
                        <li>
                            <p class="num">3</p>
                            <p class="type">업무일지</p>
                            <p class="title">일간 업무일지 작성중</p>
                            <p class="time">2023.08.03</p>
                            <p class="period">D-<span>17</span></p>
                            <a href="#" class="seeDets">자세히 보기</a>
                            <a href="#" class="delBtn">삭제</a>
                        </li>
                        <li>
                            <p class="num">4</p>
                            <p class="type">프로젝트</p>
                            <p class="title">테스트 프로젝트</p>
                            <p class="time">2023.08.01</p>
                            <p class="period">D-<span>15</span></p>
                            <a href="#" class="seeDets">자세히 보기</a>
                            <a href="#" class="delBtn">삭제</a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SHARE_OPTION, CRUD_TYPE } from '@/mixin/VueHoduCommon';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class TempsBox extends Mixins(VueHoduCommon) {

    get computedTemps() {

        let computed_temps : any[] = JSON.parse(JSON.stringify(this.temps));
        
        if( this.filter_type != "ALL" ) {
            computed_temps = computed_temps.filter(temp => temp.temp_type == this.filter_type);
        }

        // 웹은 카드는 제외
        computed_temps = computed_temps.filter(temp => temp.temp_type != 'CARD');

        // 30일 체크
        computed_temps = computed_temps.filter(temp => this.getDDay(temp.audit_modified) >= 0);

        return computed_temps.sort((o1, o2) => {

            if( new Date(o1.audit_modified).getTime() < new Date(o2.audit_modified).getTime() ) return 1;
            else if( new Date(o1.audit_modified).getTime() > new Date(o2.audit_modified).getTime() ) return -1;

            return 0;
        });
    }

    filter_type : string = "ALL";
    temps : any[] = [];

    mounted() : void {
        this.getTemps();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
     async setScroll() : Promise<void> {
        
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();
        const selectBoxOuterHeight : number | undefined = $('.selectBox').outerHeight();
        const sortHeaderOuterHeight : number | undefined = $('.sortHeader').outerHeight();
        
        const scroll_height = window.innerHeight - (titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight) - 
                              (selectBoxOuterHeight == null ? 0 : selectBoxOuterHeight) -
                              (sortHeaderOuterHeight == null ? 0 : sortHeaderOuterHeight);
        
        // @ts-ignore alarmDivScroll 스크롤
        $('#temps_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height
        });
    }

    async getTemps() {

        try {

            const response = await this.hodu_api_call(`api/v1/temp`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.temps ) {
                throw new Error("임시보관함 조회 실패");
            }

            this.temps.splice(0, this.temps.length);
            this.temps = this.temps.concat(response.data.data.temps);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "임시보관함 조회 실패", ['확인']);
        }

    }

    getTempType(temp) : string {

        switch( temp.temp_type ) {
            case "BASIC": return "일정";
            case "CARD": return "카드";
            case "MEETINGLOG": return "회의록";
            case "REPORT": return "업무일지";
            case "WORK": return "프로젝트";
        }

        return "";
    }

    getTempTitle(temp) : string {

        switch( temp.temp_type ) {
            case "BASIC":
            case "CARD": 
            case "MEETINGLOG":
            case "REPORT":
            case "WORK":
                return temp.temp_data.event_data.title;
        }

        return "";
    }

    /**
     * dday 구하기
     */
     getDDay(audit_modified : string | Date) : number {
        audit_modified = audit_modified instanceof Date ? audit_modified : moment(audit_modified).toDate();
        const target_date = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        const diff : number = this.getDateDiff(target_date, audit_modified);
        return 30 - diff;
    }

    goDetail(temp) {

        switch( temp.temp_type ) {
            case "BASIC":
            case "CARD": 
            case "MEETINGLOG":
            case "REPORT":
            case "WORK":
                temp.temp_data.is_temp = true;
                temp.temp_data.temp_id = temp.temp_id;
                this.doSetEvent(temp.temp_data);
                this.doSetEventCrudType(CRUD_TYPE.CREATE);
                this.doSetEventShareInfo({
                    share_option : temp.temp_data.share_option,
                    user_ids : [],
                    group_ids : [],
                    team_ids : [],
                    group_user_ids : [],
                    team_user_ids : []
                });
                this.hodu_router_push(`/event`);
                break;
        }

        return "";

    }

    async deleteTemp(temp) {
        this.hodu_show_dialog('cancel', `정말로 삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    
                    const response = await this.hodu_api_call(`api/v1/temp/${temp.temp_id}`, API_METHOD.DELETE);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("임시보관함 삭제 중 오류 발생");
                    }

                    this.getTemps();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog('cancel', "임시보관함 삭제 중 오류 발생", ['확인']);
                }
            }
        ]); 
    }

    /**
     * 리사이즈 감지 
     */
     handleResize() : void {
        // @ts-ignore
        $('#temps_list_scroll').mCustomScrollbar('destroy');
        this.$nextTick(() => this.setScroll());
    }

}
</script>

<style scoped>
    #tempsList .bg { display: flex; height: 65px; box-sizing: border-box; align-items: center; justify-content: space-between; padding: 0 30px; border-bottom: 1px solid #e7e9ea; }

    #tempsConDiv li { width: 20%; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: bold; }
    #tempsConDiv .num { width: 10%; text-align: center; }
    #tempsConDiv .title { width: 35%; }
    #tempsConDiv .period { width: 15%; color: #666879; }
    #tempsConDiv .period span { color: #666879; }

    #temps_list_scroll li { width: 100%; padding-right: 0px; position: relative; overflow: hidden; line-height: 55px; height: 55px; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; color: #232848; transition: .2s; }
    #temps_list_scroll li:hover { background: #f7f9fb; }
    .tempsConUl > li p { width: 20%; display: inline-block; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .seeDets { position: absolute; left: 0; top: 0; font-size: 0; width: 100%; height: 100%; display: block; }
    .delBtn { position: absolute; top: 50%; right: 50px; padding: 0 15px; margin-top: -13px; background: #ff6363; color: #fff; height: 25px; line-height: 25px; border-radius: 20px; opacity: 0; }
    #temps_list_scroll li:hover .delBtn { background: #ff3a00; opacity: 1; }
</style>