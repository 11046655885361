<template>
    <div id="attendance_my_list" class="section_scroll">
        <div class="viewGroup">
            <div class="schedule_box">
                <div class="grp settingBg">
                    <div class="posRel">
                        <div class="bg">
                            <div class=" left">
                                <!-- <input type="button" id="attendance-my-list-current-date" :value="date_string"> -->
                                <h2 id="hMonthPrt" class="mr10">
                                    <input type="text" id="attendance-my-list-current-date" class="forDatePick" :value="hodu_date_to_format_string(selected_date, 'YYYY-MM-DD')" readonly/>{{ date_string }}
                                </h2>
                                <input type="button" id="btnPrev" value="" class="toolbar-btn bt-prev mr10" @click.prevent="handlePrev">
                                <input type="button" id="btnNext" value="" class="toolbar-btn bt-next mr10" @click.prevent="handleNext">
                                <input type="button" id="btnToday" value="오늘" class="toolbar-btn today " @click.prevent="handleToday">
                            </div>
                            <div class="grp3 fr">
                                <ul>
                                    <li>
                                        <span class="clrBar ylw">노랑</span>
                                        미체크
                                    </li>
                                    <!-- <li>
                                        <span class="clrBar red">빨강</span>
                                        지각
                                    </li> -->
                                    <!-- <li>
                                        <span class="clrBar gray">회색</span>
                                        결근
                                    </li> -->
                                    <li>
                                        <span class="clrBar red">빨강</span>
                                        결근
                                    </li>
                                    <li>
                                        <span class="clrBar blue">파랑</span>
                                        <!-- <div class="clrDiv"> -->
                                            <!-- <span class="clrBar blue">파랑</span> -->
                                            <!-- <span class="clrBar black">검정</span> -->
                                            <!-- <span class="clrBar green">초록</span> -->
                                        <!-- </div> -->
                                        휴가
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="attendance_my_list_scroll" class="content">
                <ul class="yrUl">
                    <li>
                        <div class="monthDiv">

                            <div class="leftDiv">
                                <div class="">
                                    <!-- <p class="title"></p> -->
                                    <div class="descript">
                                        <p><span>총</span><span>{{ getTotalCount() }}</span></p>
                                        <p><span>정상</span><span>{{ getNormalCount() }}</span></p>
                                        <p><span>지각</span><span>{{ getLateCount() }}</span></p>
                                        <p><span>미체크</span><span>{{ getNoneCheckCount() }}</span></p>
                                        <p><span>결근</span><span class="none">{{ getAwolCount() }}</span></p>
                                        <p><span>휴가</span><span>{{ getVacationCount() }}</span></p>
                                    </div>
                                </div>
                            </div>

                            <div class="rightDiv">
                                <ul class="mnthUl">
                                    <li :class="{
                                            today : hodu_date_to_format_string(day.date, 'YYYY.MM') == date_string && hodu_date_to_format_string(new Date(day.date), 'YYYYMMDD') == hodu_date_to_format_string(new Date(), 'YYYYMMDD'), 
                                            ft : hodu_date_to_format_string(new Date(day.date), 'YYYYMMDD') > hodu_date_to_format_string(new Date(), 'YYYYMMDD') && hodu_date_to_format_string(day.date, 'YYYY.MM') == date_string, 
                                            sun : day.date.getDay() == 0, 
                                            none : hodu_date_to_format_string(day.date, 'YYYY.MM') != date_string,
                                            publicHoliday : day.holiday != null && hodu_date_to_format_string(day.date, 'YYYY.MM') == date_string,
                                            round    : getEventCount(day) == 1 || isAwol(day) == true
                                        }" :key="index" v-for="(day, index) in computedCalendar">

                                        <div class="topDiv" v-if="hodu_date_to_format_string(day.date, 'YYYY.MM') == date_string">
                                            <p class="date">{{ day.date.getDate() }}</p>
                                            <p class="day">{{ getDayOfWeekByDate(day.date) }}</p>
                                        </div>

                                        <!-- 들어갈 내용이 2개, 3개, 4개 -->
                                        <div :class="{
                                            btmDiv   : getEventCount(day) == 1 || isAwol(day) == true,
                                            btmDiv_2 : getEventCount(day) == 2 && isAwol(day) == false,
                                            btmDiv_3 : getEventCount(day) == 3 && isAwol(day) == false,
                                            btmDiv_4 : getEventCount(day) == 4 && isAwol(day) == false,
                                        }" v-if="hodu_date_to_format_string(day.date, 'YYYY.MM') == date_string">

                                            <a href="#" @click.prevent="() => {}" v-if="getEventCount(day) == 1 && isAwol(day) == false" :title="getVacationTitle(day.events.filter(item => item.attend_type == '20' || item.attend_type == '30'))">
                                                {{ getOffText(day) }}
                                                <!-- 요청이라는 것을 알려주는 지표 없앰 -->
                                                <!-- {{ day.events.filter(item => item.attend_type == '20' || item.attend_type == '30')[0].approval_complete_yn == true ? '' : ' 요청' }} -->
                                            </a>

                                            <a href="#" class="red" @click.prevent="() => {}" v-else-if="isAwol(day) == true">결근</a>

                                            <div class="btmDiv" :class="{ hide : getEventCount(day) == 1 || isAwol(day) == true }" :key="index" v-for="(vacation, index) in day.events.filter(item => item.attend_type == '20' || item.attend_type == '30')">
                                                <p>{{ `${vacation.vacation_type_name}` }}</p>
                                                <!-- 요청이라는 것을 알려주는 지표 없앰 -->
                                                <!-- ${vacation.approval_complete_yn == true ? '' : ' (요청)' } -->
                                            </div>

                                            <div class="btmDiv" :class="{ notChecked : getStartTimeText(day.events) == '' }" v-if="getEventCount(day) > 1 && isAwol(day) == false">
                                                <p class="fromTime">
                                                    {{ getStartTimeText(day.events) }}
                                                    <span title="미체크" v-if="getStartTimeText(day.events) == ''">?</span>
                                                </p>
                                            </div>
                                            <div class="btmDiv" :class="{ notChecked : getEndTimeText(day.events, day.date) == '', now : getEndTimeText(day.events, day.date) == '근무중' }" v-if="getEventCount(day) > 1 && isAwol(day) == false">
                                                <p class="toTime">
                                                    {{ getEndTimeText(day.events, day.date) }}
                                                    <span title="미체크" v-if="getEndTimeText(day.events, day.date) == ''">?</span>
                                                </p>
                                            </div>

                                        </div>

                                        <!-- <div class="topDiv" v-if="hodu_date_to_format_string(day.date, 'YYYY.MM') == date_string">
                                            <p class="date">{{ day.date.getDate() }}</p>
                                            <p class="day">{{ getDayOfWeekByDate(day.date) }}</p>
                                        </div>

                                        <div class="btmDiv" v-if="hodu_date_to_format_string(day.date, 'YYYY.MM') == date_string">
                                            <div class="btmDiv1" :class="{ notChecked : getStartTimeText(day.events, day.date) == '' }">
                                                <p class="fromTime">
                                                    {{ getStartTimeText(day.events, day.date) }}
                                                    <span title="미체크" v-if="getStartTimeText(day.events, day.date) == ''">?</span>
                                                </p>
                                                <div class="fromTimeDiv">
                                                </div>
                                            </div>
                                            <div class="btmDiv2" :class="{ notChecked : getEndTimeText(day.events, day.date) == '' }">
                                                <p class="toTime">
                                                    {{ getEndTimeText(day.events, day.date) }}
                                                    <span title="미체크" v-if="getEndTimeText(day.events, day.date) == ''">?</span>
                                                </p>
                                                <div class="toTimeDiv offDiv">
                                                </div>
                                            </div>
                                        </div> -->

                                    </li>
                                    <!-- <li>
                                        <div class="topDiv">
                                            <p class="date">1</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv_4">
                                            <div class="btmDiv">
                                                <p class="fromTime">오전 9:00</p>
                                            </div>
                                            <div class="btmDiv">
                                                <p class="toTime">오후 6:00</p>
                                            </div>
                                            <div class="btmDiv">
                                                <p class="fromTime">오전 9:00</p>
                                            </div>
                                            <div class="btmDiv">
                                                <p class="toTime">오후 6:00</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">2</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv_3">
                                            <div class="btmDiv">
                                                <p class="fromTime">오전 9:00</p>
                                            </div>
                                            <div class="btmDiv">
                                                <p class="toTime">오후 6:00</p>
                                            </div>
                                            <div class="btmDiv">
                                                <p class="fromTime">오전 9:00</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">3</p>
                                            <p class="day">수</p>
                                        </div>
                                        <div class="btmDiv_2">
                                            <div class="btmDiv">
                                                <p class="fromTime">오전 9:00</p>
                                            </div>
                                            <div class="btmDiv">
                                                <p class="toTime">오후 6:00</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="round">
                                        <div class="topDiv">
                                            <p class="date">4</p>
                                            <p class="day">목</p>
                                        </div>
                                        <div class="btmDiv">
                                            <a href="">휴가</a>
                                        </div>
                                    </li>
                                    <li class="publicHoliday">
                                        <div class="topDiv">
                                            <p class="date">5</p>
                                            <p class="day">금</p>
                                        </div>
                                        <div class="btmDiv_1">
                                            <div class="btmDiv">
                                                <p class="fromTime">오전 9:00</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="none">
                                        <div class="topDiv">
                                            <p class="date">6</p>
                                            <p class="day">토</p>
                                        </div>
                                        <div class="btmDiv">
                                            
                                        </div>
                                    </li>
                                    <li class="sun none">
                                        <div class="topDiv">
                                            <p class="date">7</p>
                                            <p class="day">일</p>
                                        </div>
                                        <div class="btmDiv">
                                            
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">8</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="half">
                                        <div class="topDiv">
                                            <p class="date">9</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1 notOff">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2 off">
                                                <div class="toTimeDiv offDiv">
                                                    <p>오후 1:40</p>
                                                    <p>오후 반차</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">10</p>
                                            <p class="day">수</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">11</p>
                                            <p class="day">목</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1 late">
                                                <p class="fromTime">오전 9:10</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">12</p>
                                            <p class="day">금</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="none">
                                        <div class="topDiv">
                                            <p class="date">13</p>
                                            <p class="day">토</p>
                                        </div>
                                        <div class="btmDiv">
                                        </div>
                                    </li>
                                    <li class="sun none">
                                        <div class="topDiv">
                                            <p class="date">14</p>
                                            <p class="day">일</p>
                                        </div>
                                        <div class="btmDiv">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">15</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">16</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">17</p>
                                            <p class="day">수</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">18</p>
                                            <p class="day">목</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1 late">
                                                <p class="fromTime">오전 9:30</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">19</p>
                                            <p class="day">금</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="none">
                                        <div class="topDiv">
                                            <p class="date">20</p>
                                            <p class="day">토</p>
                                        </div>
                                        <div class="btmDiv">
                                        </div>
                                    </li>
                                    <li class="sun none">
                                        <div class="topDiv">
                                            <p class="date">21</p>
                                            <p class="day">일</p>
                                        </div>
                                        <div class="btmDiv">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">22</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">23</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2 notChecked ">
                                                <p class="toTime"><span title="미체크">?</span></p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="today">
                                        <div class="topDiv">
                                            <p class="date">24</p>
                                            <p class="day">수</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2 now">
                                                <p class="toTime">근무 중</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="round military ft">
                                        <div class="topDiv">
                                            <p class="date">25</p>
                                            <p class="day">목</p>
                                        </div>
                                        <div class="btmDiv">
                                            <a href="">예비군</a>
                                        </div>
                                    </li>
                                    <li class="ft">
                                        <div class="topDiv">
                                            <p class="date">26</p>
                                            <p class="day">금</p>
                                        </div>
                                        <div class="btmDiv">

                                        </div>
                                    </li>
                                    <li class="ft">
                                        <div class="topDiv">
                                            <p class="date">27</p>
                                            <p class="day">토</p>
                                        </div>
                                        <div class="btmDiv">
                                        </div>
                                    </li>
                                    <li class="ft sun">
                                        <div class="topDiv">
                                            <p class="date">28</p>
                                            <p class="day">일</p>
                                        </div>
                                        <div class="btmDiv">
                                        </div>
                                    </li>
                                    <li class="waiting ft">
                                        <div class="topDiv">
                                            <p class="date">29</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv">
                                            <a href="">휴가 요청</a>
                                        </div>
                                    </li>
                                    <li class="ft">
                                        <div class="topDiv">
                                            <p class="date">30</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv">
                                            
                                        </div>
                                    </li>
                                    <li class="none">
                                        
                                    </li>
                                    <li class="none">
                                        
                                    </li> -->
                                </ul>
                            </div>

                        </div>
                    </li>
                    <!-- <li>
                        <div class="monthDiv">
                            <div class="leftDiv">
                                <div class="titleDiv">
                                    <p class="title">2020.05</p>
                                    <div class="descript">
                                        <p><span>총</span><span>18</span></p>
                                        <p><span>정상</span><span>16</span></p>
                                        <p><span>지각</span><span>2</span></p>
                                        <p><span>미체크</span><span>1</span></p>
                                        <p><span>결근</span><span class="none">0</span></p>
                                        <p><span>휴가</span><span>1.5</span></p>
                                        <p><span>경조사</span><span class="none">0</span></p>
                                        <p><span>예비군</span><span>1</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="rightDiv">
                                <ul class="mnthUl">
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">1</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">2</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">3</p>
                                            <p class="day">수</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">4</p>
                                            <p class="day">목</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">5</p>
                                            <p class="day">금</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">6</p>
                                            <p class="day">토</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">7</p>
                                            <p class="day">일</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">8</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">9</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">10</p>
                                            <p class="day">수</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">11</p>
                                            <p class="day">목</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">12</p>
                                            <p class="day">금</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">13</p>
                                            <p class="day">토</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">14</p>
                                            <p class="day">일</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">15</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">16</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">17</p>
                                            <p class="day">수</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">18</p>
                                            <p class="day">목</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">19</p>
                                            <p class="day">금</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">20</p>
                                            <p class="day">토</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">21</p>
                                            <p class="day">일</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">22</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">23</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">24</p>
                                            <p class="day">수</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">25</p>
                                            <p class="day">목</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">26</p>
                                            <p class="day">금</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">27</p>
                                            <p class="day">토</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">28</p>
                                            <p class="day">일</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">29</p>
                                            <p class="day">월</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">  
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="topDiv">
                                            <p class="date">30</p>
                                            <p class="day">화</p>
                                        </div>
                                        <div class="btmDiv">
                                            <div class="btmDiv1">
                                                <p class="fromTime">오전 9:00</p>
                                                <div class="fromTimeDiv">
                                                    
                                                </div>
                                            </div>
                                            <div class="btmDiv2">
                                                <p class="toTime">오후 6:00</p>
                                                <div class="toTimeDiv">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import moment from 'moment';
const AttendanceInfo = namespace('AttendanceInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class AttendanceMyList extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 달력 틀 computed
     */
    get computedCalendar() : any[] {
        let calendar : any[] = [];

        let month_start = moment(`${this.date_string}.01`).toDate();
        let month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

        const month_start_day = month_start.getDay();
        const month_end_day   = month_end.getDay();

        const default_i = month_start.getDate();
        const end_i     = month_end.getDate();

        for( let i = default_i - month_start_day; i < end_i + (7 - month_end_day); i++ ) {
            const moment_start = moment(month_start);
            const date = moment_start.set('date', moment_start.get('date') + i - 1).toDate();
            const event_key = moment(date).format('YYYYMMDD');
            const map_key = moment(date).format('YYYY-MM-DD');
            
            calendar.push({
                "date" : date,
                "events" : this.event && this.event[event_key] ? this.event[event_key] : [],
                "lunar" : this.lunar_date.get(map_key),
                "holiday" : this.holidays.get(map_key)
            });
        }

        return calendar;
    }

    /**
     * @CalendarInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @CalendarInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트
    
    date_string : string = "";

    event : any = null;
    lunar_date : Map<string, string> = new Map(); // 음력 Map
    holidays   : Map<string, Object> = new Map(); // 공휴일 Map

    mounted() : void {
        this.event_bus.$on('myListResize', this.handleResize);
        
        this.date_string = moment(this.selected_date).format('YYYY.MM');
        this.datepickerInit();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const schedule_box_height = $('.schedule_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                 - (schedule_box_height ? schedule_box_height : 0);

        // @ts-ignore
        $('#attendance_my_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * attend data 가져오기
     */
    @Watch('date_string')
    async getEmpAttendDataMonthInfo() : Promise<void> {
        await this.$nextTick();

        try {
            let month_start = moment(`${this.date_string}.01`).toDate();
            let month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

            await Promise.all([this.getLunar(month_start, month_end), this.getHolidays(month_start, month_end)]);
            await this.getEvents();

            // TODO 회사에서 지정한 휴일도 따로 불러와서 세팅 고려!
            // TODO 회사에서 안쉬기로 정한 법정공휴일은 안 쉬어야하는지 고려!

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
        }
    }

    /**
     * 이벤트(월 달력 일별 근태현황) 조회
     */
    async getEvents() : Promise<void> {
        try {
            const request_date_string = this.date_string.replaceAll(/\./ig, "");

            // 오늘 날짜를 넣어서 오늘 이후의 데이터를 안나오게 하려다가 오늘 이후의 연차 까지 나와야해서 이 달력의 마지막 날까지 조회 하도록 변경
            const today_date_string = moment().format('YYYYMMDD');
            // const today_date_string = moment(`${this.date_string}.01`).add('month', 1).set('date', 0).format('YYYYMMDD');

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/data/month?month=${request_date_string}&search_mode=USER&today_date_string=${today_date_string}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_month_info ) {
                throw new Error("월달력 일별 근태현황 조회 실패");
            }
            
            for( const event of response.data.data.attend_emp_month_info ) {
                for( const key in event.jsonb ) {
                    // 근태현황 시작일이 적용 안됐다면 전부 제외
                    if( this.getAttendanceStart() == '' ) {
                        delete(event.jsonb[key]);
                        continue;
                    }

                    // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                    if( Number(this.getAttendanceStart()) > Number(key) ) {
                        delete(event.jsonb[key]);
                        continue;
                    }
                }
                this.event = JSON.parse(JSON.stringify(event.jsonb));
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 음력 구하기
     */
    async getLunar(start : Date, end : Date) : Promise<void> {
        start = start instanceof Date ? start : new Date(moment(start).format());
        end   = end   instanceof Date ? end   : new Date(moment(end).format());

        const lunar_objs : any[] = await this.hodu_solars_to_lunars(start, end);
        
        for( let lunar_obj of lunar_objs ) {
            this.lunar_date.set(lunar_obj.solar_ymd, lunar_obj.lunar_ymd);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {
        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            this.holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

    }

    /**
     * 날짜 선택
     */
    datepickerInit() : void {

        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const date_moment = moment(dateText);

                vue.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
                vue.doSetSelectedDate(date_moment.toDate());
                vue.date_string = moment(vue.selected_date).format('YYYY.MM');
                
                inst.input.val(vue.date_string);

                // @ts-ignore
                $('#datepicker').datepicker('setDate', vue.selected_date);
            },
        };

        // @ts-ignore
        $('#attendance-my-list-current-date').datepicker(option);
    }

    /**
     * 이전 달로 이동
     */
    async handlePrev() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.add('month', -1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 다음 달로 이동
     */
    async handleNext() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.add('month', 1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 오늘 날짜로 이동
     */
    async handleToday() : Promise<void> {
        
        const date_moment = moment();

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date());
    }

    /**
     * 달력 이동 해야 되는 날짜가 바뀐 것을 감지
     */
    @Watch('go_to_date')
    onDateChange() : void {
        const date_moment = moment(this.go_to_date);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');
    }

    /**
     * 출근 시간
     */
    getStartTimeText(events : any[]) : string {
        let start_time = null;

        for( const attend_info of events ) {
            // 출 퇴근 정보
            if( attend_info.attend_type == 10 ) {
                start_time = attend_info.start_time;
            }

            // 휴가 정보
            else if( attend_info.attend_type == 20 ) {
                
            }
        }

        return start_time != null ? this.processTimeText(start_time) : ''
    }

    /**
     * 퇴근 시간
     */
    getEndTimeText(events : any[], date : Date) : string {
        let end_time = null;
        const today_string = moment().format('YYYYMMDD');

        for( const attend_info of events ) {
            // 출 퇴근 정보
            if( attend_info.attend_type == 10 ) {
                end_time = attend_info.end_time;
            }
            // 휴가 정보
            else if( attend_info.attend_type == 20 ) {
                
            }
        }

        return end_time != null ? this.processTimeText(end_time) : (
            moment(date).format('YYYYMMDD') == today_string ? (this.getStartTimeText(events) == '' ? '' : '근무중') : ''
        );
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    /**
     * 휴가가 존재하는 경우
     */
    isOff(day) : boolean {
        let vacation_type_name = null;

        for( const attend_info of day.events ) {
            if( attend_info.attend_type == 20 ) {
                vacation_type_name = attend_info.vacation_type_name;
            }
        }

        return vacation_type_name != '';
    }

    /**
     * 출·퇴근 데이터가 필요 없는 경우 (휴가로 인해)
     */
    // isRestByVacation(day) : boolean {

    //     let attend_daycount = 0;
    //     let vacation_daycount = 0;

    //     for( const attend_info of day.events ) {
    //         // 출 퇴근 정보
    //         if( attend_info.attend_type == 10 ) {
    //             attend_daycount = attend_info.daycount;
    //         }
    //         // 휴가 정보
    //         else if( attend_info.attend_type == 20 ) {
    //             vacation_daycount += attend_info.daycount;
    //         }
    //     }

    //     return !this.isRestByAttendDaycount(day) || (attend_daycount - vacation_daycount <= 0);
    // }

    /**
     * 출·퇴근 데이터가 필요 없는 경우 (업무일이 아님)
     */
    // isRestByAttendDaycount(day) : boolean {

    //     let attend_daycount = 0;

    //     for( const attend_info of day.events ) {
    //         // 출 퇴근 정보
    //         if( attend_info.attend_type == 10 ) {
    //             attend_daycount = attend_info.daycount;
    //         }
    //     }

    //     return attend_daycount <= 0;
    // }
    
    /**
     * 이벤트 개수
     */
    getEventCount(day) : number {

        if( day.events == null || day.events.length < 1 ) return 0;
        
        const current_yyyymmdd = moment().format('YYYYMMDD');
        const day_yyyymmdd = moment(day.date).format('YYYYMMDD');
        if( day.events.length == 1 && day.events[0].attend_type == null && Number(current_yyyymmdd) < Number(day_yyyymmdd) ) {
            return 0;
        }

        let count = 0;
        let vacation_daycount = 0;
        let businesstrip_daycount = 0;
        let is_exist_state_10 = false;

        for( const event of day.events ) {
            if( event.attend_type == 20 ) {
                count++;
                vacation_daycount += event.daycount;
            }
            else if( event.attend_type == 30 ) {
                count++;
                businesstrip_daycount += event.daycount;
            }
            else if( event.attend_type == null || event.attend_type == 10 ) {
                count += 2;
                is_exist_state_10 = true;
            }
        }

        // 휴가 합이 1일이 안되는데 출근 정보가 존재하지 않는다면(미체크) count + 2 TODO work_type 조회해서 해당 타입에 맞게 바뀌어야함
        if( is_exist_state_10 == false && (vacation_daycount + businesstrip_daycount) < 1 ) {
            return count + 2;
        }
        
        // 휴가가 1일 이상으로 신청됐다면 무조건 휴가 TODO work_type 조회해서 해당 타입에 맞게 바뀌어야함
        if( (vacation_daycount + businesstrip_daycount) >= 1 ) {
            return 1;
        }

        return count;
    }

    /**
     * 결근 여부
     */
    isAwol(day) : boolean {
        if( day.events == null || day.events.length < 1 ) return false;

        for( const event of day.events ) {
            if( event.attend_type == 10 ) {
                return event.attend_state == '40';
            }
        }

        return false;
    }

    /**
     * 휴가 제목 리스트 반환
     */
    getVacationTitle(events) : string {
        let vacation_name_list = "";
        for( const event of events ) {
            if( vacation_name_list.length > 0 ) vacation_name_list += ", "; 
            vacation_name_list += event.vacation_type_name;
        }
        return vacation_name_list;
    }

    /**
     * count 총
     */
    getTotalCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == '10' ) {
                    count += event.daycount;
                }
                if( event.attend_type == '20' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * count 정상
     */
    getNormalCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == 10 && event.attend_state == '10' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * count 지각
     */
    getLateCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == 10 && event.attend_state == '30' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * count 미체크
     */
    getNoneCheckCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == 10 && event.attend_state == '20' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * count 결근
     */
    getAwolCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == 10 && event.attend_state == '40' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    } 

    /**
     * count 휴가
     */
    getVacationCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == '20' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getAttendanceStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * 근무 안하는날 텍스트 반환 
     */
    getOffText(attend) : string {

        if( attend == null && attend.events == null ) return "휴일";

        const vacation = attend.events.filter(item => item.attend_type == '20');
        const businesstrip = attend.events.filter(item => item.attend_type == '30');

        if( vacation.length > 0 && businesstrip.length > 0 ) {
            return '휴가·출장';
        }

        if( vacation.length > 0 ) {
            return '휴가';
        }

        if( businesstrip.length > 0 ) {
            return '출장';
        }

        return '휴일';
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#attendance_my_list_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

}
</script>

<style scoped>
    .title_box .input_btn { cursor: pointer; margin-top:10px; padding-right: 35px !important; appearance: none; -webkit-appearance: none;background: url('../../assets/images/side_area/bt_open.png') no-repeat 90% center !important } 
    .title_box .input_btn:focus { background-image: url('../../assets/images/side_area/bt_close.png') !important; }
    
    .grp3 input[type="button"] { margin-top: 13px; margin-right: 25px; }
    .grp3 ul li { display: inline-block; margin-left: 15px; height: 66px; line-height: 66px }
    .grp3 ul li .clrBar { float: left; display: inline-block; font-size: 0; width: 20px; height: 3px;background:#f1f3f5; margin-top: 30px; margin-right: 10px; }
    .grp3 ul { margin-right: 25px; }
    .grp3 ul li .clrBar.ylw { background:#ffa70e;}
    .grp3 ul li .clrBar.blue { background:#447fff; }
    .grp3 ul li .clrBar.red { background:#ff6363; }
    .grp3 ul li .clrBar.green { background:#17c788; }
    .grp3 ul li .clrBar.black { background:#3f5c82; }
    .grp3 ul li .clrDiv {  float: left; width: 20px; margin-top: 23px; margin-right: 10px; }
    .grp3 ul li .clrDiv .clrBar { display: block; margin-top: 3px; }
    .settingBg > .posRel > .bg { position: static; }


    .content { overflow-y: auto; width: 100%; }
    
    .settingBg .left  { margin-top: 0;  min-width: auto !important; width: auto !important }
    .settingBg .left input[type="text"] {float: left;line-height: 61px;font-size: 19px;color: #202A39;cursor: default;margin-right: 15px !important; font-weight: bold; min-width: auto !important; width: 80px !important }
    /* .settingBg .left #attendance-my-list-current-date {float: left;line-height: 61px;font-size: 19px;color: #202A39;cursor: default;margin-right: 15px !important; font-weight: bold; min-width: auto !important; background : none; cursor : pointer; text-align: left; } */
    
    /* monthDiv */
    .monthDiv { position: relative; min-height: 100px; border-bottom: 35px solid #fff;border-top: 1px solid #e7e9ea; }
    

    /* leftDiv */
    .leftDiv { border-right: 1px solid #e7e9ea; border-bottom: 1px solid #e7e9ea; width: 200px; height: 100%; box-sizing:border-box; position: absolute;left:0; top:0; }
    .leftDiv .title { font-weight: bold; font-size: 16px; line-height: 80px; height: 60px; }
    #attendance_my_list_scroll .monthDiv .leftDiv > div { padding : 30px; }
    .leftDiv .descript { overflow: hidden; }
    .leftDiv .descript p { font-size: 13px; font-weight: bold; line-height: 35px; height: 35px; }
    .leftDiv .descript span:first-child { font-weight: normal }
    .leftDiv .descript span:last-child { float: right }
    .leftDiv .descript .none { opacity: 0.3; }
    .mnthUl { overflow: hidden; }
    .mnthUl li { position: relative; float: left; width: 14.285714285714285714285714285714%; height: 120px; border-right: 1px solid #e7e9ea; border-bottom: 1px solid #e7e9ea; box-sizing:border-box; }
    .mnthUl li .topDiv { overflow: hidden; background:#f8f9fa; line-height: 25px; height: 25px; padding: 0 10px; width:100%; box-sizing:border-box; border-bottom: 1px solid #f1f3f5; text-align: left; }
    .mnthUl li .topDiv * { display: inline-block; font-size: 11px }
    .mnthUl li .topDiv .day { float: right; }
    .mnthUl li .btmDiv1 { height: 47px; line-height: 66px; text-align: center; font-weight: bold; }
    .mnthUl li .btmDiv2 { height: 47px; line-height: 22px; text-align: center; font-weight: bold; }
    .mnthUl li .btmDiv1.late, .mnthUl li .btmDiv2.late { color: #ff6363; }
    .mnthUl li .notChecked { text-align: center }
    .mnthUl li .notChecked span { background: #ffbc49;border: 1px solid #ffa70e; display:inline-block; width: 17px; line-height: 17px; height: 17px; border-radius: 50%; }
    .mnthUl li.sun  .topDiv * { color:#ff6060; }
    .mnthUl li.none { background:#f1f3f5; }
    .mnthUl li.none .topDiv { border-bottom: 1px solid #e7e9ea; }
    .mnthUl li.publicHoliday { color:#ff6363;background:#fff8f8; }
    .mnthUl li.publicHoliday .topDiv {background:#fff8f8; border-color:#ffe3e3;}
    .mnthUl li.today:after { content:''; display: block; position: absolute;left:0; top:0; width: 100%; height: 100%; border: 1px solid #477fff; box-sizing:border-box; }
    .mnthUl li .now { color:#477fff; }
    .mnthUl li.waiting .btmDiv  { text-align: center;  padding: 10px; width: 100%; box-sizing:border-box; }
    .mnthUl li.waiting a { transition:0.2s; display: inline-block; width: 100%; height: 73px;line-height: 73px; text-align: center; box-sizing:border-box; border-radius: 5px; background: #f1f7fd; color:#477fff; font-weight: bold; }
    .mnthUl li.waiting a:hover {background: #e0edfb}
    .mnthUl li.round  { text-align: center }
    .mnthUl li.round a { text-align: center;transition:0.2s; display: inline-block; width: 60px; height: 60px; line-height: 60px; border-radius: 50%; background:#477fff; color:#fff; font-weight: bold; }
    .mnthUl li.round a.red { background: #FF6363; cursor : default; }
    .mnthUl li.round .btmDiv { padding: 15px; }
    .mnthUl li.round.military a {background:#17c788; }
    .mnthUl li.ft .topDiv * { opacity: 0.5; }
    .mnthUl li.half .off { padding: 10px; box-sizing:border-box; }
    .mnthUl li.half .notOff { height: 40px }
    .mnthUl li.half .offDiv { padding:3px 0; line-height: 15px; background: #477fff; color: #fff; border-radius: 3px}


    .mnthUl li .btmDiv.hide { display : none; }
    .mnthUl li .btmDiv_4 { padding-top:5px }
    .mnthUl li .btmDiv_4 .btmDiv { height: 20px; line-height: 20px;text-align: center;  padding: 0; width: 100%; box-sizing:border-box; font-weight: bold;  }
    .mnthUl li .btmDiv_3 { padding-top:15px }
    .mnthUl li .btmDiv_3 .btmDiv { height: 20px; line-height: 20px;text-align: center;  padding: 0; width: 100%; box-sizing:border-box; font-weight: bold;  }
    .mnthUl li .btmDiv_2 { padding-top:25px }
    .mnthUl li .btmDiv_2 .btmDiv { height: 20px; line-height: 20px;text-align: center;  padding: 0; width: 100%; box-sizing:border-box; font-weight: bold;  }
    .mnthUl li .btmDiv_1 { line-height: 90px; height: 90px; }
    .mnthUl li .btmDiv_1 .btmDiv {text-align: center;  padding: 0; width: 100%; box-sizing:border-box; font-weight: bold;  }

    /* rightDiv */
    .rightDiv { box-sizing:border-box; width: 100%; padding-left: 200px; }

    #hMonthPrt { float: left; line-height: 61px; font-size: 19px; color: #202A39; cursor: pointer; margin-right: 15px !important; font-weight: bold; background: none; }
</style>