<template>
    <div id="event_export_modal" class="modal">

        <div class="titlebox">
            <h1>일정 내보내기</h1>
        </div>

        <div class="content">
            <div class="schedule_export_box">
                <p class="schedule_modal_desc">내보낼 그룹 및 기간을 설정해주세요.</p>

                <div class="select_group">
                    <p>그룹&amp;팀</p>
                    <div class="select_group_box">
                        <h2 :class="{on : is_dropdown_open == true }" @click.prevent="selectOnOff">
                            <span class="cl" :style="{ backgroundColor : selectedGroupTeam.color }" v-if="selectedGroupTeam != null"></span>
                            {{ selectedGroupTeam == null ? '그룹&팀 전체' : ( selectedGroupTeam.team_id > 0 ? selectedGroupTeam.team_name : selectedGroupTeam.group_name ) }} 
                            <span class="grupArrow"></span>
                        </h2>
                        <ul id="s_group" class="dropdown" :class="{on : is_dropdown_open == true }" >
                            <li>
                                <a herf="#" @click.prevent="selectGroupTeam(0, 0)">그룹&amp;팀 전체</a>
                            </li>
                            <li :key="`${group_team.group_id}-${group_team.team_id}`" v-for="group_team in getTargetGroupList">
                                <a href="#" @click.prevent="selectGroupTeam(group_team.group_id, group_team.team_id)">{{ group_team.team_id > 0 ? group_team.team_name : group_team.group_name }}<span class="cl" :style="{ backgroundColor : group_team.color }"></span></a>
                            </li>
                            <!-- <li>
                                <a href="#">그룹A<span class="cl"></span></a>
                            </li>
                            <li>
                                <a href="#" class="team">팀1<span class="cl"></span></a>
                            </li>
                            <li>
                                <a href="#" class="team">팀2<span class="cl"></span></a>
                            </li>
                            <li>
                                <a href="#" class="team">팀3<span class="cl"></span></a>
                            </li> -->
                        </ul>
                        <!-- <select name="group_schedule" id="group_name">
                            <option value="group1">그룹A</option>
                            <option value="group2">그룹B</option>
                            <option value="group3">그룹C</option>
                            <option value="group4">그룹D</option>
                            <option value="group5">그룹E</option>
                        </select> -->
                    </div>
                </div>

                <div class="radio_btn">
                    <p>기간설정</p>
                    <ul class="periodUL">
                        <li>
                            <input type="radio" name="export" id="all_schedule" class="period_type" value="all" v-model="export_type">
                            <label for="all_schedule">전체 일정</label>
                        </li>
                        <li>
                            <input type="radio" name="export" id="latest_month" class="period_type" value="month" v-model="export_type">
                            <label for="latest_month">최근 한달</label>
                        </li>
                        <li>
                            <input type="radio" name="export" id="schedule_choice" class="period_type" value="select" v-model="export_type">
                            <label for="schedule_choice">기간 선택</label>

                            <label for="" class="from blind" :disabled="export_type != 'select'">시작일자</label>
                            <input type="button" id="schedule_export_fr_ymd" :value="`${hodu_date_to_format_string(select_start, 'YYYY.MM.DD')} ${getDayOfWeekByDate(select_start)}`" class="schedule_period scheduleFrom" :disabled="export_type != 'select'">
                            <span class="seper">~</span>
                            <label for="" class="to blind" :disabled="export_type != 'select'">종료일자</label>
                            <input type="button" id="schedule_export_to_ymd" :value="`${hodu_date_to_format_string(select_end, 'YYYY.MM.DD')} ${getDayOfWeekByDate(select_end)}`" class="schedule_period scheduleTo" :disabled="export_type != 'select'">
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="btns">
            <input type="button" value="취소" @click.prevent="close">
            <input type="button" value="내보내기" @click.prevent="eventExport">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupTeamInfo } from '@/store/modules/GroupInfo';
import moment from 'moment';
import { EventExportModalInfo } from '@/store/modules/ModalInfo';
import { RRule, RRuleSet, rrulestr } from 'rrule';

@Component({
    components: {
        
    },
})
export default class EventExportModal extends Mixins(VueHoduCommon) {

    /**
     * 선택된 그룹 팀 목록
     */
    get selectedGroupTeam() : GroupTeamInfo | null {

        if( this.selected_group_id < 1 && this.selected_team_id < 1 ) {
            return null;
        }

        const target_list = this.getTargetGroupList.filter(group_team => group_team.group_id == this.selected_group_id && group_team.team_id == this.selected_team_id);

        if( target_list.length < 1 ) {
            return null;
        }

        return target_list[0];
    }

    /**
     * 현재 그룹&팀 목록
     */
    get getTargetGroupList() : GroupTeamInfo[] {

        const group_team : GroupTeamInfo[] = [];
        const hodu_c_group_data_length : number = this.hodu_c_group_data.length;
        for( let i = 0; i < hodu_c_group_data_length; i++ ) {
            const group_info : GroupTeamInfo = this.hodu_c_group_data[i];
            if( group_info.group_id != this.scope_group_id ) {
                continue;
            }

            group_team.push(group_info);
            const team_length : number = group_info.teams.length;
            for( let j = 0; j < team_length; j++ ) {
                group_team.push(group_info.teams[j]);
            }
        }

        return group_team;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventExportModalInfo ?: (params : EventExportModalInfo) => void;

    is_dropdown_open : boolean = false;

    selected_group_id : number = 0;
    selected_team_id : number = 0;

    export_type : string = "all"; // all, month, select

    select_start = new Date();
    select_end = new Date();

    mounted() : void {
        // 그룹 팀 선택
        // $(".select_group_box > h2").click(function(){
        //     $(this).siblings().toggleClass("on");
        //     $(this).toggleClass("on");
        // });

        this.datepickerInit();
    }

    datepickerInit() : void {
        
        const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(selected_date)}`);
                     
                    if( inst.id == 'schedule_export_fr_ymd' ) {
                        this.select_start = moment(selected_date).toDate();

                        if( this.select_start.getTime() > this.select_end.getTime() ) {
                            this.select_end = moment(selected_date).toDate();
                        }
                    }

                    else if( inst.id == 'schedule_export_to_ymd' ) {
                        this.select_end = moment(selected_date).toDate();

                        if( this.select_end.getTime() < this.select_start.getTime() ) {
                            this.select_start = moment(selected_date).toDate();
                        }
                    }
                },
            }

        // @ts-ignore
        $('#schedule_export_fr_ymd, #schedule_export_to_ymd').datepicker(datepicker_option);
    }

    selectOnOff() : void {
        this.is_dropdown_open = !this.is_dropdown_open;
    }

    selectGroupTeam(group_id : number, team_id : number) {
        this.selected_group_id = group_id;
        this.selected_team_id = team_id;
        this.is_dropdown_open = false;
    }

    close() : void {
        this.doSetEventExportModalInfo?.({
            show_modal : false
        });
    }

    async eventExport() : Promise<void> {
        try {

            await this.hodu_show_indicator();

            let start = new Date();
            let end = new Date();

            if( this.export_type == 'all' ) {
                start = moment('1970-01-01').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                end = moment('2050-12-31').set('hour', 26).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
            }
            else if( this.export_type == 'month' ) {
                start = moment().add('month', -1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                end = moment().set('hour', 26).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
            }
            else if( this.export_type == 'select' ) {
                start = moment(this.select_start).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                end = moment(this.select_end).set('hour', 26).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
            }

            const start_query : string = moment(start).utc().format();
            const end_query : string = moment(end).utc().format();

            let owner_type  : string = OWNER_TYPE.GROUP;
            let owner_id    : number = this.scope_group_id; 

            let calendar_id : string = this.calendar_id;

            let query : string = `?start=${start_query}&end=${end_query}&mycal=${ this.scope != OWNER_TYPE.PERSONAL ? 'false' : 'true' }&syscal=${ this.scope != OWNER_TYPE.PERSONAL ? 'false' : 'true' }&shared=${ this.scope != OWNER_TYPE.PERSONAL ? 'false' : 'true' }`;

            if( this.selectedGroupTeam != null ) {
                if( this.selectedGroupTeam.team_id > 0 ) {
                    query += `&teams=${Number(this.selectedGroupTeam.team_id)}`;
                } 
                else {
                    query += `&groups=${Number(this.selectedGroupTeam.group_id)}`;
                }
            }
            else {
                for( const group_team of this.getTargetGroupList ) {
                    if( group_team.team_id > 0 ) {
                        query += `&teams=${Number(group_team.team_id)}`;
                    } 
                    else {
                        query += `&groups=${Number(group_team.group_id)}`;
                    }
                }
            }

            const response = await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}${query}`, API_METHOD.GET, undefined, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.events ) {
                throw new Error("일정 파일 목록 조회 중 오류 발생");
            }

            const temp_events : any[] = [];

            const events = response.data.data.events;

            // 일정 가공 (반복 처리)
            for( const event of events ) {

                // 가공
                const is_lunar = event.event_data.schedule_date.lunar_yn;
                const is_repeat = event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.rrule.length > 0;

                // 음력 반복 일정
                if( is_lunar && is_repeat ) {
                    
                    let lunar_start : string = event.event_data.schedule_date.lunar_start.replace(/-/ig, '').substring(0, 8);

                    // 평달 반복인지 윤달 반복인지 체크
                    const intercalation_obj : any = await this.hodu_is_intercalation(moment(event.event_data.schedule_date.start).format('YYYYMMDD'));
                    
                    // 윤달인 경우, 현재 양력 연도에 해당 반복 일정이 없다면 넘어간다 
                    // if( intercalation_obj.is_intercalation ) {
                    //     let is_search_target_year : boolean = false;
                    //     for( const next_date of intercalation_obj.next_date_obj ) {
                    //         if( next_date.solar_ymd.substr(0, 4) == `${this.date.getFullYear()}` ) {
                    //             is_search_target_year = true;
                    //             break;
                    //         }
                    //     }

                    //     if( is_search_target_year == false ) {
                    //         continue;
                    //     }
                    // }

                    const lunar_list = await this.hodu_lunar_to_solars(lunar_start, intercalation_obj.is_intercalation);

                    console.log(lunar_list);

                    // let target_lunar : any = null;
                    for( const lunar of lunar_list ) {
                        // if( this.hodu_string_includes(lunar.solar_ymd, `${this.date.getFullYear()}-${`0${this.date.getMonth() + 1}`.slice(-2)}`) == true ) {
                        //     target_lunar = lunar;
                        //     break;
                        // }

                        const new_event = JSON.parse(JSON.stringify(event));
                        new_event.original_start = new_event.event_data.schedule_date.start;
                        new_event.original_end = new_event.event_data.schedule_date.end;

                        const target_date = new Date(lunar.solar_ymd);

                        const new_start = moment(new_event.event_data.schedule_date.start).set('year', target_date.getFullYear()).set('month', target_date.getMonth()).set('date', target_date.getDate());
                        const new_end = moment(new_event.event_data.schedule_date.end).set('year', target_date.getFullYear()).set('month', target_date.getMonth()).set('date', target_date.getDate());

                        new_event.event_data.schedule_date.start = new_start.toDate();
                        new_event.event_data.schedule_date.end = new_end.toDate();

                        if( new_start.toDate().getTime() < start.getTime() || end.getTime() < new_end.toDate().getTime() ) {
                            continue;
                        }

                        temp_events.push(new_event);
                    }

                    // if( target_lunar != null ) {
                    //     const new_event = JSON.parse(JSON.stringify(event));
                    //     new_event.original_start = new_event.event_data.schedule_date.start;
                    //     new_event.original_end = new_event.event_data.schedule_date.end;

                    //     const target_date = new Date(target_lunar.solar_ymd);

                    //     const new_start = moment(new_event.event_data.schedule_date.start).set('year', target_date.getFullYear()).set('month', target_date.getMonth()).set('date', target_date.getDate());
                    //     const new_end = moment(new_event.event_data.schedule_date.end).set('year', target_date.getFullYear()).set('month', target_date.getMonth()).set('date', target_date.getDate());

                    //     new_event.event_data.schedule_date.start = new_start;
                    //     new_event.event_data.schedule_date.end = new_end;

                    //     temp_events.push(new_event);
                    // }

                    continue;
                }

                // 반복 일정
                else if( !is_lunar && is_repeat ) {
                    const dtstart = moment(event.event_data.schedule_date.start).utc().format("YYYYMMDDTHHmmss") + 'Z';
                    const until = moment(event.event_data.schedule_date.recurrence_end).utc().format("YYYYMMDDTHHmmss") + 'Z';

                    const rrule : RRule | RRuleSet = rrulestr(`DTSTART:${dtstart}\nRRULE:${event.event_data.schedule_date.rrule};UNTIL=${until}`);

                    if( event.event_data.schedule_date.exdate == null ) event.event_data.schedule_date.exdate = [];
                    const exdate_list : Date[] = event.event_data.schedule_date.exdate;
                    exdate_list.sort();

                    const recurrence_id : Date | null = event.event_data.schedule_date.recurrence_id ? new Date(event.event_data.schedule_date.recurrence_id) : null;

                    const repeat_dates = rrule.all();

                    // 반복이면서 잘려나온 일정은 무시한다 (이 일정만 삭제된것이거나 이후 일정 삭제된것임)
                    if( recurrence_id != null && event.event_data.schedule_date.rrule != null ) {
                        continue;
                    }

                    for( const repeat_date of repeat_dates ) {
                        
                        const repeat_yyyymmdd = moment(repeat_date).format('YYYYMMDD');
                        
                        if( recurrence_id != null ) {
                            const recurrence_id_yyyymmdd = moment(recurrence_id).format('YYYYMMDD');
                            if( repeat_yyyymmdd == recurrence_id_yyyymmdd ) continue;
                        }

                        let find_exdate = false;

                        for( const exdate of exdate_list ) {
                            const exdate_yyyymmdd = moment(exdate).format('YYYYMMDD');

                            if( repeat_yyyymmdd == exdate_yyyymmdd ) {
                                find_exdate = true;
                                break;
                            }
                        }

                        if( find_exdate ) continue;
                        
                        const target_date = moment(repeat_date).toDate();
                        if( !(start.getTime() <= target_date.getTime() && target_date.getTime() <= end.getTime()) ) {
                            continue;
                        }

                        const new_event = JSON.parse(JSON.stringify(event));
                        new_event.original_start = new_event.event_data.schedule_date.start;
                        new_event.original_end = new_event.event_data.schedule_date.end;
                        
                        const new_start = moment(new_event.event_data.schedule_date.start).set('year', repeat_date.getFullYear()).set('month', repeat_date.getMonth()).set('date', repeat_date.getDate());
                        const new_end = moment(new_event.event_data.schedule_date.end).set('year', repeat_date.getFullYear()).set('month', repeat_date.getMonth()).set('date', repeat_date.getDate());

                        new_event.event_data.schedule_date.start = new_start.toDate();
                        new_event.event_data.schedule_date.end = new_end.toDate();

                        if( new_start.toDate().getTime() < start.getTime() || end.getTime() < new_end.toDate().getTime() ) {
                            continue;
                        }
                        
                        temp_events.push(new_event);
                    }
                    continue;
                }

                // 일반 일정
                temp_events.push(event);
            }

            temp_events.sort((o1, o2) : number => {
                
                const o1_start = new Date(o1.event_data.schedule_date.start);
                const o2_start = new Date(o2.event_data.schedule_date.start);

                if( o1_start.getTime() < o2_start.getTime() ) {
                    return -1;
                }
                else if( o1_start.getTime() > o2_start.getTime() ) {
                    return 1;
                }
               
                return 0;
            });

            console.log(temp_events);

            if( temp_events.length < 1 ) {
                alert("해당 조건에 만족하는 일정이 존재하지 않습니다");
                return;
            }

            // 엑셀 데이터로 가공
            let cell : any[] = [];

            cell.push([
                {
                        "value" : "작성자",
                        "border" : {
                            "top" : { "style" : "thin" },
                            "bottom" : { "style" : "thin" },
                            "left" : { "style" : "thin" },
                            "right" : { "style" : "thin" }
                        },
                        "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFF2F2F2" }, "bgColor" : { "argb" : "FFF2F2F2" } },
                        "font" : { "bold" : true },
                        "width" : 10,
                        "alignment" : { "vertical" : "middle" }
                },
                {
                        "value" : "일정타입",
                        "border" : {
                            "top" : { "style" : "thin" },
                            "bottom" : { "style" : "thin" },
                            "left" : { "style" : "thin" },
                            "right" : { "style" : "thin" }
                        },
                        "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFF2F2F2" }, "bgColor" : { "argb" : "FFF2F2F2" } },
                        "font" : { "bold" : true },
                        "width" : 10,
                        "alignment" : { "vertical" : "middle" }
                },
                {
                        "value" : "제목",
                        "border" : {
                            "top" : { "style" : "thin" },
                            "bottom" : { "style" : "thin" },
                            "left" : { "style" : "thin" },
                            "right" : { "style" : "thin" }
                        },
                        "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFF2F2F2" }, "bgColor" : { "argb" : "FFF2F2F2" } },
                        "font" : { "bold" : true },
                        "width" : 20,
                        "alignment" : { "vertical" : "middle" }
                },
                {
                        "value" : "시작일",
                        "border" : {
                            "top" : { "style" : "thin" },
                            "bottom" : { "style" : "thin" },
                            "left" : { "style" : "thin" },
                            "right" : { "style" : "thin" }
                        },
                        "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFF2F2F2" }, "bgColor" : { "argb" : "FFF2F2F2" } },
                        "font" : { "bold" : true },
                        "width" : 10,
                        "alignment" : { "vertical" : "middle" }
                },
                {
                        "value" : "시작시간",
                        "border" : {
                            "top" : { "style" : "thin" },
                            "bottom" : { "style" : "thin" },
                            "left" : { "style" : "thin" },
                            "right" : { "style" : "thin" }
                        },
                        "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFF2F2F2" }, "bgColor" : { "argb" : "FFF2F2F2" } },
                        "font" : { "bold" : true },
                        "width" : 10,
                        "alignment" : { "vertical" : "middle" }
                },
                {
                        "value" : "종료일",
                        "border" : {
                            "top" : { "style" : "thin" },
                            "bottom" : { "style" : "thin" },
                            "left" : { "style" : "thin" },
                            "right" : { "style" : "thin" }
                        },
                        "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFF2F2F2" }, "bgColor" : { "argb" : "FFF2F2F2" } },
                        "font" : { "bold" : true },
                        "width" : 10,
                        "alignment" : { "vertical" : "middle" }
                },
                {
                        "value" : "종료시간",
                        "border" : {
                            "top" : { "style" : "thin" },
                            "bottom" : { "style" : "thin" },
                            "left" : { "style" : "thin" },
                            "right" : { "style" : "thin" }
                        },
                        "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFF2F2F2" }, "bgColor" : { "argb" : "FFF2F2F2" } },
                        "font" : { "bold" : true },
                        "width" : 10,
                        "alignment" : { "vertical" : "middle" }
                },
                {
                        "value" : "위치",
                        "border" : {
                            "top" : { "style" : "thin" },
                            "bottom" : { "style" : "thin" },
                            "left" : { "style" : "thin" },
                            "right" : { "style" : "thin" }
                        },
                        "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFF2F2F2" }, "bgColor" : { "argb" : "FFF2F2F2" } },
                        "font" : { "bold" : true },
                        "width" : 15,
                        "alignment" : { "vertical" : "middle" }
                },
                {
                        "value" : "메모",
                        "border" : {
                            "top" : { "style" : "thin" },
                            "bottom" : { "style" : "thin" },
                            "left" : { "style" : "thin" },
                            "right" : { "style" : "thin" }
                        },
                        "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFF2F2F2" }, "bgColor" : { "argb" : "FFF2F2F2" } },
                        "font" : { "bold" : true },
                        "width" : 23.38,
                        "alignment" : { "vertical" : "middle" }
                },
            ]);

            for( const event of temp_events ) {

                // 배경색 평일 00FFFFFF 토요일 FFDEEBF7, 일요일 FFFCE5D6
                const start = new Date(event.event_data.schedule_date.start);
                const end = new Date(event.event_data.schedule_date.end);

                const day = start.getDay();

                let color = "";
                switch(day) {
                    case 0:
                        color = "FFFCE5D6";
                        break;
                    case 6:
                        color = "FFDEEBF7";
                        break;
                }

                let location_string : string = "";
                if( event.event_data.location != null ) {
                    for( const location of event.event_data.location ) {
                        if( location_string.length > 0 ) location_string += "\n";
                        location_string += location.place;
                    }
                }

                cell.push([
                    {
                        "value" : event.event_data.event_owner_name,
                        "fill" : (color?.length ?? 0) > 0 ? { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : color } } : undefined,
                        "alignment" : { "vertical" : "middle" }
                    },
                    {
                        "value" : event.calendar_id.includes('personal') ? '개인일정' : event.calendar_id.includes('group') ? event.event_data.event_owner_group_name : event.event_data.event_owner_team_name,
                        "fill" : (color?.length ?? 0) > 0 ? { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : color } } : undefined,
                        "alignment" : { "vertical" : "middle" }
                    },
                    {
                        "value" : event.event_data.title,
                        "fill" : (color?.length ?? 0) > 0 ? { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : color } } : undefined,
                        "alignment" : { "vertical" : "middle" }
                    },
                    {
                        "value" : this.hodu_date_to_format_string(start, 'YYYY.MM.DD'),
                        "fill" : (color?.length ?? 0) > 0 ? { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : color } } : undefined,
                        "alignment" : { "vertical" : "middle" }
                    },
                    {
                        "value" : this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(start, 'hh:mm a')),
                        "fill" : (color?.length ?? 0) > 0 ? { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : color } } : undefined,
                        "alignment" : { "vertical" : "middle" }
                    },
                    {
                        "value" : this.hodu_date_to_format_string(end, 'YYYY.MM.DD'),
                        "fill" : (color?.length ?? 0) > 0 ? { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : color } } : undefined,
                        "alignment" : { "vertical" : "middle" }
                    },
                    {
                        "value" : this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(end, 'hh:mm a')),
                        "fill" : (color?.length ?? 0) > 0 ? { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : color } } : undefined,
                        "alignment" : { "vertical" : "middle" }
                    },
                    {
                        "value" : location_string,
                        "fill" : (color?.length ?? 0) > 0 ? { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : color } } : undefined,
                        "alignment" : { "vertical" : "middle" }
                    }
                    ,
                    {
                        "value" : event.event_data.memo,
                        "fill" : (color?.length ?? 0) > 0 ? { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : color } } : undefined,
                        "alignment" : { "vertical" : "middle" }
                    }
                ])
            }

            let data = {
                "data" : cell,
                "name" : "hodu_events.xlsx"
            }

            // 내보내기 (다운로드)
            const excel_response = await this.$http({
                url : "api/v1/excel",
                method : 'POST',
                responseType: 'blob',
                headers : {
                    "Cache-Control" : "no_cache"
                },
                data : data
            });

            console.log(excel_response);

            const blob = new Blob([excel_response.data]);

            // @ts-ignore
            if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, "hodu.xlsx"); // for IE
                return;
            }

            await this.hodu_download_blob(blob, "hodu.xlsx");

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert('일정 내보내기 중 오류 발생');
        } finally {
            await this.hodu_hide_indicator();
        }

        
    }

}
</script>

<style scoped>
    #event_export_modal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-195px; margin-left:-300px; width:600px; height: auto; background:#fff; }
    #event_export_modal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0 30px;border-bottom: 1px solid #e7e9ea; }
    #event_export_modal .titlebox h1 { font-size: 20px; font-weight: bold; height: 90px; line-height: 90px; }
    #event_export_modal .titlebox h1:after { content:''; width: 30px; height: 30px; display: inline-block; background: url('../../../assets/images/contents/xls.png') no-repeat center center; background-size: 25px auto; vertical-align: middle; margin-left: 3px; }

    #event_export_modal .content { padding: 20px 30px !important; box-sizing: border-box; }
    #event_export_modal .content .schedule_export_box p.schedule_modal_desc { font-weight: bold; height: 20px; line-height: 20px; }
    #event_export_modal .content .schedule_export_box .select_group,
    #event_export_modal .content .schedule_export_box .radio_btn { margin-top: 20px; display: flex; }
    #event_export_modal .content .schedule_export_box div > p { width: 70px; line-height: 45px; box-sizing: border-box; }
    #event_export_modal .content .schedule_export_box .select_group .select_group_box { position: relative; vertical-align: top; line-height: 45px; width: 100%; padding: 0 10px; box-sizing: border-box; border: 1px solid #e7e9ea; }
    #event_export_modal .content .schedule_export_box .select_group .select_group_box h2 { cursor: pointer; font-size: 13px; }
    .select_group .select_group_box h2 .cl,
    .select_group_box ul#s_group li a .cl { display: inline-block; width: 8px; height: 8px; border-radius: 50%; float: left; margin-top: 19px; font-size: 0; margin-right: 15px; background: #477fff; }
    #event_export_modal .content .schedule_export_box .select_group .select_group_box h2 .grupArrow { width: 30px; height: 30px; background: url('../../../assets/images/side_area/ic_downopen_232848.png') no-repeat center; background-size: 17px auto; border-radius: 50%; float: right; vertical-align: middle; margin-top: 8px; transition: .2s; }
    .select_group_box ul#s_group { width: 100%; background: #fff; top: 45px !important; border-radius: 0; margin-bottom: 0; border-top: 1px solid #f1f3f5; border-left: 1px solid #f1f3f5; height: auto; box-shadow: 0 10px 10px rgb(0 0 0 / 10%) !important; max-height: 323px; overflow-y: auto;}
    .select_group_box ul#s_group li a { font-size: 13px; }
    .select_group_box ul#s_group li a.team { padding-left: 25px; font-size: 12px; }
    /* 그룸&팀 on일경우 */
    #event_export_modal .content .schedule_export_box .select_group .select_group_box h2.on { color: #477fff; }
    #event_export_modal .content .schedule_export_box .select_group .select_group_box h2.on .grupArrow { background-image: url('../../../assets/images/side_area/ic_upclose.png'); }

    #event_export_modal .content .schedule_export_box .select_group #group_name { width: 100%; }
    #event_export_modal .content .schedule_export_box .radio_btn .periodUL li { height: 30px; line-height: 30px; }
    #event_export_modal .content .schedule_export_box .radio_btn .periodUL li .schedule_period { width: 120px; height: 30px; line-height: 30px; border: 1px solid #e7e9ea; background: none; margin-top: -11px; margin-left: 15px; color: #232848; }
    #event_export_modal .content .schedule_export_box .radio_btn .periodUL li .seper { font-weight: bold; font-size: 15px; margin-left: 15px; }

    #event_export_modal .btns { overflow: hidden; border-radius: 0 0 5px 5px; border-top: 1px solid #e7e9ea; }
    #event_export_modal .btns input { transition:0.2s; width: 50%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold;background: #fff; }
    #event_export_modal .btns input:hover { background:#f1f3f5; }

    ::-webkit-scrollbar{width: 6px; height : 6px; }
    ::-webkit-scrollbar-track{background-color: transparent;}
    ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
</style>