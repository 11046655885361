<template>
    <div class="offDayDiv">
        <input type="button" id="" class="btnAdd" placeholder="" value="휴일 등록" title="휴일 등록" @click="showHospitalOffSettingModal(true)"/>
        <!-- 헤더소트 -->
        <ul class="sortHeader" id="">
            <li class="checkbox">
                <input type="checkbox" id="selectAll" :disabled="computedDayOffsByYear.length == 0" :checked="selected_off_ids.length != 0 && selected_off_ids.length == computedDayOffsByYear.length" @click="selectAllDayOffs()">
                <label for="selectAll"></label>
            </li>
            <li class="num">No</li>
            <li class="yyyy headerSort sortOff"><a class="sort" @click="toggleYearSelectMenu($event)">{{selected_year}}년</a>
                <div class="dropdown" :class="{ on : is_year_list_open == true }">
                    <ul>
                        <li :key="yearIndex" v-for="(year, yearIndex) in computedAvailbleYearList">
                            <a href="" :class="{on : yearIndex == selected_year}" @click.prevent="selectedYear(year)">{{year}}년</a>
                        </li>
                        <!-- <li>
                            <a href="">2018년</a>
                        </li>
                        <li>
                            <a href="">2019년</a>
                        </li>
                        <li>
                            <a href="" class="on">2020년</a>
                        </li>
                        <li>
                            <a href="">2021년</a>
                        </li>
                        <li>
                            <a href="">2022년</a>
                        </li>
                        <li>
                            <a href="">2023년</a>
                        </li>
                        <li>
                            <a href="">2024년</a>
                        </li> -->
                    </ul>
                </div>
            </li>
            <li class="off headerSort sortOff" @click="sortBy('DATE')"
                :class="{
                    sortOff  : day_off_sort_target != 'DATE',
                    sortUp   : day_off_sort_target == 'DATE' && day_off_sort_direction == 'ASC',
                    sortDown : day_off_sort_target == 'DATE' && day_off_sort_direction == 'DESC'
                }"><span>휴일기간</span></li>
            <li class="holidayName headerSort sortOff" @click="sortBy('NAME')"
                :class="{
                    sortOff  : day_off_sort_target != 'NAME',
                    sortUp   : day_off_sort_target == 'NAME' && day_off_sort_direction == 'ASC',
                    sortDown : day_off_sort_target == 'NAME' && day_off_sort_direction == 'DESC'
                }"><span>휴일명</span></li>
            <li class="time headerSort sortOff" @click="sortBy('TERM')"
                :class="{
                    sortOff  : day_off_sort_target != 'TERM',
                    sortUp   : day_off_sort_target == 'TERM' && day_off_sort_direction == 'ASC',
                    sortDown : day_off_sort_target == 'TERM' && day_off_sort_direction == 'DESC'
                }"><span>휴일수</span></li>
        </ul>

        <!-- 병원휴일 -->
        <div id="hospital_off_setting_scroll" >
            <ul class="holidayUl ">
                <li :key="index" v-for="(dayoff, index) in computedDayOffsByYear">
                    <p class="checkbox" @click="selectDayOff(dayoff.hospital_off_id)">
                        <input type="checkbox" id="selectAll" :checked="selected_off_ids.includes(dayoff.hospital_off_id)"/>
                        <label for=""></label>
                    </p>
                    <p class="num">{{index+1}}</p>
                    <p class="yyyy">{{getDayOffYearString(dayoff)}}</p>
                    <p class="off">{{getDayOffDateString(dayoff)}}</p>
                    <p class="holidayName">{{dayoff.off_name}}</p>
                    <p class="time"><span>{{getDayOffDuring(dayoff)}}일</span></p>
                    <input type="button" class="del" value="삭제" title="삭제" @click="deleteDayOff(dayoff, true)">
                    <a @click="showHospitalOffSettingModal(false, dayoff)">수정</a>
                </li>
                <!-- <li>
                    <p class="checkbox">
                        <input type="checkbox" id="selectAll" />
                        <label for=""></label>
                    </p>
                    <p class="num">1</p>
                    <p class="yyyy">2020년</p>
                    <p class="off">4월 16일 목<span>종일</span> ~ 4월 17일 금<span>9:00 ~ 13:30</span></p>
                    <p class="holidayName">모두 쉬세요</p>
                    <p class="time"><span>1.5일</span></p>
                    <input type="button" class="del" value="삭제" title="삭제">
                </li>
                <li>
                    <p class="checkbox">
                        <input type="checkbox" id="selectAll" />
                        <label for=""></label>
                    </p>
                    <p class="num">2</p>
                    <p class="yyyy">2020년</p>
                    <p class="off">7월 6일 월 <span>종일</span> ~ 7월 10일 금 <span>종일</span></p>
                    <p class="holidayName">병원 레노베이션</p>
                    <p class="time"><span>5일</span></p>
                    <input type="button" class="del" value="삭제" title="삭제">
                </li> -->

                
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import moment from 'moment';
import { namespace } from 'vuex-class';
import { hodu_doc_table, hodu_doc_modal_info } from '../../model/hodudoc';
const ModalInfo = namespace('ModalInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalOffSetting extends Mixins(VueHoduCommon) {
    
    /**
     * 선택한 년도에 따른 오름차순 휴가 리스트
     */
    get computedDayOffsByYear() : hodu_doc_table.t_hospital_day_offs[] {
        if ( this.hospital_day_offs == null ) { return [] }
        let filtered_list : hodu_doc_table.t_hospital_day_offs[] = []

        filtered_list = this.hospital_day_offs.filter(item => new Date(item.start_date).getFullYear() == this.selected_year)

        filtered_list.sort((off1 : hodu_doc_table.t_hospital_day_offs, off2 : hodu_doc_table.t_hospital_day_offs) : number => {
                //오름차순
                if( off1.start_date  > off2.start_date ) { return  1; }
                if( off1.start_date  < off2.start_date ) { return -1; }
                if( off1.start_date == off2.start_date ) { return  0; }
                return 0
            })
        return this.sortDayOffs(filtered_list);
    }

    /**
     * 전체 휴가 리스트의 해당 년도 리스트
     */
    get computedAvailbleYearList() : number[] {
        if ( this.hospital_day_offs == null ) { return [] }
        let exist_years : number[] = []
        
        //먼저 전체 휴가 리스트의 작성 연도 리스트를 구한다
        const list = this.hospital_day_offs.reverse();
        for ( const info of list ) {
            const start_year = new Date(info.start_date).getFullYear()
            if ( exist_years.includes(start_year) == false ) {
                exist_years.push(start_year)
            }
        }

        exist_years.sort((year1 : number, year2 : number) : number => {
                //오름차순
                if( year1  > year2 ) { return  1; }
                if( year1  < year2 ) { return -1; }
                if( year1 == year2 ) { return  0; }
                return 0
            })
        
        return exist_years;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDayOffSettingModalInfo ?: (day_off_setting_modal_info : hodu_doc_modal_info.DayOffSettingModalInfo) => void;

    @Prop() event_bus  !: Vue;
    
    selected_year           : number = new Date().getFullYear()
    hospital_day_offs       : hodu_doc_table.t_hospital_day_offs[] = [];
    selected_off_ids        : string[] = [];
    is_year_list_open       : boolean = false;
    day_off_sort_target     : string = "DATE";
    day_off_sort_direction  : string = "ASC";

    mounted() : void {
        if( this.event_bus != null ) { 
            this.event_bus.$on('offSettingResize', this.handleResize);
            this.event_bus.$on('deleteDayOffs', this.deleteDayOffs);
        }
        this.setScroll();
        this.getHospitalDayOffs();
    }

    selectedYear(year : number) {
        this.selected_year = year
        this.is_year_list_open = !this.is_year_list_open;
        this.selected_off_ids.splice(0, this.selected_off_ids.length)
    }

    selectDayOff(off_id: string){
        if (this.selected_off_ids.includes(off_id) == false) {
            this.selected_off_ids.push(off_id)
        }else{
            this.selected_off_ids.splice(this.selected_off_ids.indexOf(off_id), 1)
        }
    }

    selectAllDayOffs(){
        const numOfDayOffs = this.computedDayOffsByYear.length

        if (this.selected_off_ids.length != numOfDayOffs) {
            this.selected_off_ids.splice(0, this.selected_off_ids.length)
            for (const dayoff of this.computedDayOffsByYear) {
                this.selected_off_ids.push(dayoff.hospital_off_id)
            }
        }else{
            this.selected_off_ids.splice(0, this.selected_off_ids.length)
        }
    }

    toggleYearSelectMenu(event) : void {
        if (this.computedAvailbleYearList.length < 1) { return; }
        event.stopPropagation();
        this.is_year_list_open = !this.is_year_list_open;
    }

    sortBy(target: string) {
        // 이미 DATE Sort 중이라면
        if( this.day_off_sort_target == target ) {
            // ASC -> DESC  순으로 변경 되어야 한다
            switch( this.day_off_sort_direction ) {
                case "ASC":
                    this.day_off_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.day_off_sort_direction = "ASC";
                    break; 
            }
        }
        
        // DATE Sort가 아니였다면
        else {
            this.day_off_sort_target = target;
            this.day_off_sort_direction = "ASC";
        }
    }

    sortDayOffs(list : hodu_doc_table.t_hospital_day_offs[]) : hodu_doc_table.t_hospital_day_offs[] {
        return list.sort((dayoff1, dayoff2) : number => {
            // 날짜 오름차순
            if( this.day_off_sort_target == "DATE" && this.day_off_sort_direction == "ASC" ) {
                if( dayoff1.start_date  > dayoff2.start_date ) { return  1; } 
                if( dayoff1.start_date  < dayoff2.start_date ) { return -1; } 
                if( dayoff1.start_date == dayoff2.start_date ) { return  0; }
            }

            // 날짜 내림차순
            if( this.day_off_sort_target == "DATE" && this.day_off_sort_direction == "DESC" ) {
                if( dayoff1.start_date  > dayoff2.start_date ) { return -1; }
                if( dayoff1.start_date  < dayoff2.start_date ) { return  1; }
                if( dayoff1.start_date == dayoff2.start_date ) { return  0; }
            }

            // 휴일명 오름차순
            if( this.day_off_sort_target == "NAME" && this.day_off_sort_direction == "ASC" ) {
                if( dayoff1.off_name  > dayoff2.off_name ) { return  1; }
                if( dayoff1.off_name  < dayoff2.off_name ) { return -1; }
                if( dayoff1.off_name == dayoff2.off_name ) { return  0; }
            }

            // 휴일명 내림차순
            if( this.day_off_sort_target == "NAME" && this.day_off_sort_direction == "DESC" ) {
                if( dayoff1.off_name  > dayoff2.off_name ) { return -1; }
                if( dayoff1.off_name  < dayoff2.off_name ) { return  1; }
                if( dayoff1.off_name == dayoff2.off_name ) { return  0; }
            }

            // 휴일수 오름차순
            if( this.day_off_sort_target == "TERM" && this.day_off_sort_direction == "ASC" ) {
                if( this.getDayOffDuring(dayoff1)  > this.getDayOffDuring(dayoff2) ) { return  1; }
                if( this.getDayOffDuring(dayoff1)  < this.getDayOffDuring(dayoff2) ) { return -1; }
                if( this.getDayOffDuring(dayoff1) == this.getDayOffDuring(dayoff2) ) { return  0; }
            }

            // 휴일수 내림차순
            if( this.day_off_sort_target == "TERM" && this.day_off_sort_direction == "DESC" ) {
                if( this.getDayOffDuring(dayoff1)  > this.getDayOffDuring(dayoff2) ) { return -1; }
                if( this.getDayOffDuring(dayoff1)  < this.getDayOffDuring(dayoff2) ) { return  1; }
                if( this.getDayOffDuring(dayoff1) == this.getDayOffDuring(dayoff2) ) { return  0; }
            }

            return 0;
        });
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hospital_off_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    /**
     * 휴일 생성 및 상세 모달 띄우기
     */
    showHospitalOffSettingModal(is_create : boolean, dayoff: hodu_doc_table.t_hospital_day_offs | null = null) : void {
        if( this.doSetDayOffSettingModalInfo == null ) { return; }
        this.doSetDayOffSettingModalInfo({
            show_modal : true,
            is_create  : is_create,
            is_doctor  : false,
            day_off    : dayoff == null ?  null : JSON.parse(JSON.stringify(dayoff))
        });
        window['deleteDayOff'] = this.deleteDayOff
        window['updateDayOff'] = this.updateDayOff
        window['createDayOff'] = this.createDayOff
    }

    getDayOffYearString(dayoff : hodu_doc_table.t_hospital_day_offs) : string {
        const start_date = new Date(dayoff.start_date).getFullYear()

        return `${start_date}년`
    }

    getDayOffDateString(dayoff : hodu_doc_table.t_hospital_day_offs) : string {
        const start_date = new Date(dayoff.start_date)
        const end_date   = new Date(dayoff.end_date)

        return `${start_date.getMonth()+1}월 ${start_date.getDate()}일 ~ ${end_date.getMonth()+1}월 ${end_date.getDate()}일`
    }

    getDayOffDuring(dayoff : hodu_doc_table.t_hospital_day_offs) : number {
        const start_date = new Date(dayoff.start_date)
        const end_date   = new Date(dayoff.end_date)
        const during_days = moment(end_date).diff(start_date, "days") + 1

        return during_days
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#hospital_off_setting_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

    /**
     * 병원 지정 휴일 생성
     */
    async createDayOff(dayoff: hodu_doc_table.t_hospital_day_offs) : Promise<void> {
        let body = {
            biz_id : this.scope_group_team_option.biz_id,
            off_name : dayoff.off_name,
            off_reason : dayoff.off_reason,
            start_date : new Date(dayoff.start_date),
            end_date : new Date(dayoff.end_date),
            is_public_holiday : false
        }
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.POST, body)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                // console.log(jsonData);
                
                if (jsonData.length > 0) {
                    this.getHospitalDayOffs(new Date(dayoff.start_date).getFullYear());
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 조회
     */
    async getHospitalDayOffs(moveYearTo: number | null = null) : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off?year=0`, API_METHOD.GET)
            .then((response) => {
                let jsonData : hodu_doc_table.t_hospital_day_offs[] = JSON.parse(JSON.stringify(response.data.data))
                this.hospital_day_offs.splice(0, this.hospital_day_offs.length)
                this.hospital_day_offs = this.hospital_day_offs.concat(jsonData)
                this.selected_off_ids.splice(0, this.selected_off_ids.length)
                
                //! 조회 후 이동하고자 하는 년도로 이동
                if ( this.computedDayOffsByYear.length < 1 && this.computedAvailbleYearList.length < 1) {
                    this.selected_year = new Date().getFullYear()
                    return;
                }
                if ( moveYearTo != null ) {
                    this.selected_year = moveYearTo
                }else{
                    if ( this.computedDayOffsByYear.length < 1 && this.computedAvailbleYearList.length > 0 ) {
                        this.selected_year = this.computedAvailbleYearList[this.computedAvailbleYearList.length-1]
                    }
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 단일 삭제
     */
    deleteDayOff(dayoff : hodu_doc_table.t_hospital_day_offs, show_alert : boolean | null = null) {
        
        if (show_alert) {
            this.hodu_show_dialog("cancel", `정말로 [${dayoff.off_name}]휴일을 삭제하시겠습니까?`, ["아니오", "예"], [
                () => {},
                () => {
                    this.deleteHospitalDayOff([dayoff.hospital_off_id])
                }
            ]);
        }else{
            this.deleteHospitalDayOff([dayoff.hospital_off_id])
        }
    }

    /**
     * 병원 지정 휴일 멀티 삭제
     */
    async deleteDayOffs() : Promise<void> {
        if ( this.hospital_day_offs.length < 1 || this.selected_off_ids.length < 1 ) { return; }
        const numOfSelectedDayOffs = this.selected_off_ids.length
        
        this.hodu_show_dialog("cancel", `정말로 ${numOfSelectedDayOffs}개의 휴일을 삭제하시겠습니까?`, ["아니오", "예"], [
            () => {},
            async () => {
                this.deleteHospitalDayOff(this.selected_off_ids)
            }
        ]);
    }

    /**
     * 병원 지정 휴일 삭제 요청
     */
    async deleteHospitalDayOff(off_ids : string[]) : Promise<void> {
        let body = {
            hospital_off_ids : off_ids
        }
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.DELETE, body)
            .then((response) => {
                this.getHospitalDayOffs()
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 수정
     */
    async updateDayOff(dayoff: hodu_doc_table.t_hospital_day_offs) : Promise<void> {
        let body = {
            off_name        : dayoff.off_name,
            off_reason      : dayoff.off_reason,
            start_date      : new Date(dayoff.start_date),
            end_date        : new Date(dayoff.end_date),
            hospital_off_id : dayoff.hospital_off_id
        }
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.PUT, body)
            .then((response) => {
                let jsonData = JSON.parse(JSON.stringify(response.data.data));
                // let day_off = jsonData as hodu_doc_table.t_hospital_doctor_day_offs

                // if ( day_off.off_name != null && day_off.off_name.length > 0 ) {
                    this.getHospitalDayOffs();
                // }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

}
</script>

<style scoped>
    .offDayDiv {  }
    .offDayDiv .checkbox { width:10%; text-align: center; }
    .offDayDiv .num { font-weight:bold; width:10%; text-align: left; padding-left: 20px; box-sizing: border-box; }
    .offDayDiv .yyyy { width:15%; }
    .offDayDiv .time { width:15%; }
    .offDayDiv .holidayName { width:20%; }
    .offDayDiv .off { width:30%; }
    .offDayDiv .holidayUl li { transition:0.2s; }
    .offDayDiv .holidayUl li:hover { background:#f7f9fb }
    .offDayDiv .holidayUl a { position: absolute; font-size: 0; display: block; width:90%; height:100%;right:0;top:0;}
    .offDayDiv .holidayUl li:hover .del { opacity:1; } 
    .offDayDiv .holidayUl li .del  { opacity:0; transition:0.2s; position:absolute; right: 20px; top:12px; width: 30px; height:30px; background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .offDayDiv .holidayUl li .del:hover { background-color:#ff1f1f}
    .offDayDiv input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 20px;height: 20px;border-radius:3px; margin-top:15px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;font-size: 0;margin-right: 8px;}
    .offDayDiv input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 22px;}
    
    .offDayDiv .holidayUl .off span {display:inline-block; background:#f1f3f5; height: 20px; line-height:20px;padding:0 5px; margin:0 5px;border-radius: 5px; font-weight: normal; }
    .offDayDiv .holidayUl .time span {width:100px;display:inline-block; }

    .holidayUl li { position:relative; font-weight:bold; width:100%; overflow:hidden; line-height:55px; height:55px; border-bottom:1px solid #f1f3f5; }
    .holidayUl li > * { float:left; }
    .holidayUl li.gray {background:#f7f9fb; }
    .holidayUl li.gray > * { opacity:0.4;  }
    .holidayUl li.gray > * span {   }
    .holidayUl li.gray:hover > * { opacity:1 }
    .holidayUl li.replacement { opacity:0; transition:0.2s; overflow:hidden; height: 0; line-height: 0; color:#477fff; }
    .holidayUl li.replacement .whether select {width:80px !important;color:#477fff;}
    .holidayUl li.replacement .clr { position:absolute; top:22px; left: 20px; width:10px; height:10px; border-radius: 50%; display:block; background:#477fff; }
    .holidayUl li.replacement .holidayName span { padding-left:10px; color:#477fff; }
    .holidayUl li.replacement input[type="text"] {color:#477fff; transition:0.2s; font-size:12px; background: transparent; font-weight: bold;; }
    .holidayUl li.replacement:hover input[type="text"], .holidayUl li.replacement:hover select { box-sizing: border-box; padding:0 10px; border:1px solid #000; line-height:30px; height:30px; border-radius: 5px;cursor:pointer; width: 110px; }
    .holidayUl li.replacement:hover input[type="text"]:hover, .holidayUl li.replacement:hover select:hover { background:#f1f3f5; }
    .holidayUl li.replacement:hover input[type="text"]:focus, .holidayUl li.replacement:hover select:focus { background:#fff; border-color:#477fff; }
    .holidayUl li.replacement .yyyy select {color:#477fff; width:70px !important; transition:0.2s;background:transparent;font-weight: bold; appearance: none; line-height:30px; height:30px; border-radius: 5px; font-size:12px; }
    .holidayUl.on .replacement {opacity:1; height: 55px; line-height: 55px;}
    .holidayUl li:hover .del { opacity:1; } 
    .holidayUl li .del  { z-index: 1000; opacity:0; transition:0.2s; position:absolute; right: 20px; top:12px; width: 30px; height:30px; background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .holidayUl li .del:hover { background-color:#ff1f1f !important;}

    .btnAdd { position:absolute; right: 40px; bottom:40px; }
    .btnAdd { opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .btnAdd:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}

</style>