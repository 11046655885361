<template>
    <div class="section_ce_fix">
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <!-- toTheLeft 왼쪽 메뉴버튼(.bt_leftarea) 클릭시 toggleClass(on) -->
        <div id="draggable" class="toTheLeft ui-widget-content">
            <div class="speakerCam">
                <div class="grp">	
                    <span class="speaker"></span><span class="cam"></span>
                </div>
            </div>
            <div class="grp">
                <div class="bg"></div>
                <div class="selectBg">
                    <!-- 스크립트 변경 (위치 이동으로 하단 selectUlBg > scroll.dpnone 클래스 리무브 적용안됨) -->
                    <h3 class="selectTitle" id="sendMmsMasageTxt"><span class="arrowDown"></span>메세지문구를 선택하세요</h3>
                    <div class="selectUlBg">
                        <!--<div class="scroll dpnone">-->
                        <div class="scroll dpnone" id="sendMmsMassageScroll">
                            <ul class="selectUl dropdown pn" id="sendMmsMasageList" style="display: block;"> <!-- 리스트 scroll 270px 이상 -->
                                <li @click="messageContentChange('')">직접입력하기</li>
                                <li v-bind:key="index" v-for="(lo_data, index) in message_template" @click="messageContentChange(lo_data.message_info.body)">{{lo_data.title}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grp">	
                <div class="textBg">	
                    <!-- *********** 텍스트에리아 안에 글자가 들어가는 순간 하단 bt_reSendMmsContent addClass("on") -->
                    <input type="button" class="btn" value="재작성" id="bt_reSendMmsContent" @click="resetMessageContent();"/>
                    <textarea id="sendMmsMasageContent" placeholder="상단 메세지 문구 선택 혹은 직접입력하여 메세지를 보내세요." :value="message_content" @input="message_content = $event.target.value" @keyup="changeKey();"></textarea>
                    <p class="countLetters"><span class="counted" id="sendMmsMasageCount">{{text_count}}</span> / {{text_max}}</p>
                    <div class="options" id="optionsDiv">
                        <h4 @click="messageOption()">#{옵션}</h4>
                        <div class="grp">
                            <ul id="optionContent">
                                <li @click="messageContentAddOption('#{이름}')">#{이름}</li>
                                <li @click="messageContentAddOption('#{이메일}')">#{이메일}</li>
                                <li @click="messageContentAddOption('#{발신일자}')">#{발신일자}</li>
                                <li @click="messageContentAddOption('#{그룹팀명}')">#{그룹팀명}</li>
                            </ul>
                            <h5 @click="messageOption()">옵션닫기</h5>
                        </div>
                    </div>
                </div>
                <div class="btns">	
                    <input type="button" class="btn btn-green" value="닫기" id="bt_closeNow" @click="closeMessagePop()"/>
                    <input type="button" class="btn btn-green" value="전송" id="bt_sendMms" @click="sendMessagePop()"/>
                </div>
            </div>
        </div>
        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <!-- 퍼블변경 h3 텍스트 변경  메세지일괄발송 > 메세지 보내기 -->
            <h3 class="title_doc" style="" id="viewTitle">발송하기</h3>
            <a class="schBtn">검색하기</a>
        </div>	
        <!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
        <div class="searchDiv">
            <div class="schGrp">
                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                <input type="text" id="group_member_search" name="" value="" class="" placeholder="멤버를 검색하세요">
                <div class="btns">
                    <!-- 검색 디브 removeClass(on) -->
                    <a class="closeBtn">닫기</a>
                    <a href="#" class="bt_search"><span class="blind">찾기버튼</span></a>
                </div>
            </div>
        </div>
        <div id="sendMmsPageDiv" class="sendMmsPage msgPage section_scroll"><!--스크롤 생겨야할 영역-->
            <div class="schedule_box">
                <div class="grp settingBg">
                    <div class="menuBg"></div>
                        <div class="posRel">
                            <div class="bg">
                                <h3 class="blind">시작일자와 종료일자를 선택하세요.</h3>
                                <div class="bigGrp">
                                    <div class="grp" id="sendMmsPageSelectBox">
                                        <div class="bg"></div>
                                        <div class="selectBg">
                                            <h3 class="selectTitle" id="grpStepTxt"><span class="arrowDown"></span>{{selected_group_team_name}}</h3>
                                            <div class="selectUlBg">
                                                <div class="scroll dpnone" id="groupSelectDiv">	
                                                    <ul class="selectUl" id="grpStep"> <!-- 리스트 scroll 270px 이상 -->
                                                        <li v-bind:key="index" v-for="(lo_data, index) in group_team_list" @click="changeGroupTeam(lo_data.group_id, lo_data.team_id)">{{lo_data.name}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="toTheRight">
                            <div class="grp setting2Bg">
                                <div class="grp1"></div>	
                                <h4>누구에게 보내실건가요?</h4>
                                <p class="toWhom">
                                    <span class="toStudent" id="toStudents"><input type="checkbox" id="onlyStudents"/>
                                        <label for="onlyStudents"><span id="onlyStudents_span">학생에게</span></label></span>
                                    <span class="toKin" id="toOnlyKins"><input type="checkbox" id="onlyKins"/>
                                        <label for="onlyKins"><span>보호자에게</span></label></span>
                                </p>
                            </div>
                            <!-- 결과 없을시 -->
                            <div class="noResultsBg">
                                <p class="noResults">선택하신 일자에 검색된 결과가 없습니다.</p>
                            </div>
                            <div class="msgHeader sendMmsHeader" id="sendMmsPageHeaderSort">
                                <p class="checkBox on" id="selectCheckBoxAll">
                                    <input type="checkbox" id="selectAll" @click="selectAllCheckBox()" :checked="is_checkedAll"/>
                                    <label for="selectAll">전체선택</label>
                                </p>
                                <p class="num">No</p>
                                <p class="name headerSort" id="sendMmsSortName">이름</p>
                                <!-- 그룹 드랍다운 -->
                                <p class="grp headerSort" id="sendMmsSortGrp">
                                    {{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '아파트&amp;동' : '그룹&amp;팀' }}
                                </p>
                                <p class="mob headerSort" id="sendMmsSortMob">전화번호</p>
                                <p class="email headerSort" id="sendMmsSortEmail">이메일</p>
                            </div>
                            <ul class="subUl" id="sendMmsPageUserList">
                                <li v-bind:key="index" v-for="(lo_data, index) in members">
                                    <p class="checkBox">
                                        <input type="checkbox" :id="`check_${index}`" :checked="lo_data.checked" @click="rowSelectCheckBox(index)"/>
                                        <label :for="`check_${index}`">선택</label>
                                    </p>
                                    <p class="num">{{index+1}}</p>
                                    <p class="name">
                                        <img :src="`${lo_data.user_info.user_pic ? '/app_images/' + lo_data.user_info.user_pic : user_profile_no_image}`" alt="" @error="imageError($event)">
                                        <span class="sentTo">{{lo_data.user_info.user_name}}</span>
                                    </p>
                                    <p class="grp">{{selected_group_team_name}}</p>
                                    <p class="mob">{{lo_data.user_info.user_phone_number ? lo_data.user_info.user_phone_number : '-'}}</p>
                                    <p class="email">{{lo_data.user_info.user_email ? lo_data.user_info.user_email : '-'}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        <input type="button" id="showPhone" class="input_btn fr" value="발송" />
        <input type="button" value="맨 위로 가기" class="scrollUp" @click="memberListScrollTop()" />				
    </div>	
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupTeamInfo } from '@/store/modules/GroupInfo';

const GroupInfo = namespace('GroupInfo');

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {
        ResizeObserver
    }
}) 
export default class MessageSend extends Mixins(VueHoduCommon) {
    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;

    is_checkedAll            : boolean = false;
    selected_group_id        : number = 0;
    selected_team_id         : number = 0;
    selected_group_team_name : string = "";
    group_team_list          : any    = [];
    members                  : any    = [];
    message_template         : any    = [];
    message_content          : string = "";
    text_count               : number = 0;
    text_max                 : number = 2000;
    user_profile_no_image             = require("@/assets/images/contents/im_photoB.gif");

    async mounted() : Promise<void> {
        const vue = this;

        // 메세지 문구 클릭시 화살표 방향 변환
        $("#sendMmsMasageTxt").click(function(){
            $(this).toggleClass("on")

        });

        // 검색 버튼 클릭시 searchDiv 띄우기
        $("#section_ce .schBtn").click(function(){
            $("#section_ce .searchDiv").addClass("on");
            vue.$nextTick(() => $('#group_member_search').focus());
        });

        // 검색 닫기 버튼 클릭시 searchDiv 없애기
        $("#section_ce .searchDiv .closeBtn").click(function(){
            $("#section_ce .searchDiv").removeClass("on");
            $("#group_member_search").val("");
            $("#group_member_search").trigger('keyup');
        });

        // 발송 (전체초기화)
        $("#showPhone").click(function(){
			$(this).addClass("on");
            $("#draggable").addClass("on");
            $('#sendMmsMassageScroll').addClass('dpnone');
            $('#optionsDiv').removeClass('on');
            vue.message_content = "";
            vue.text_count = 0;

            vue.$nextTick(() => $('#sendMmsMasageContent').focus());
        });

        // 메세지 문구를 선택하세요
        $("#sendMmsMasageTxt").click(function() {
            if ( vue.message_template.length < 1 ) {
                return;
            }
            
            $('#sendMmsMassageScroll').toggleClass('dpnone');
        });

        // 그룹&팀 선택
        $("#grpStepTxt").click(function() {
            $('#groupSelectDiv').toggleClass('dpnone');
        });

        // 필터검색
        $("#group_member_search").keyup(function(event) {
            let value = $("#group_member_search").val();
            
            if ( value == "" ) {  
                $("#sendMmsPageUserList li").show();
            }
            else {
                $("#sendMmsPageUserList li").hide();
                let temp = $("#sendMmsPageUserList li .name .sentTo:contains('"+value+"')");
                $(temp).parent().parent().show();
            }
        });        

        this.setScroll();
        await this.getGroupTeam();
        await this.getMessageTemplate();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();

        // @ts-ignore
        $('#sendMmsPageDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight ),
            scrollbarPosition : 'outside',
        });

        // @ts-ignore
        $(".content").mCustomScrollbar({
			mouseWheelPixels: 500
        });
    }    

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#sendMmsPageDiv').mCustomScrollbar('destroy');

        // @ts-ignore
        $(".content").mCustomScrollbar('destroy');

    	this.setScroll();
    }

   /**
    * 그룹 팀 조회
    */
   async getGroupTeam() : Promise<void> {
       this.group_team_list = [];
       this.members = [];

       const vue = this;

       await this.hodu_api_call(`api/v1/groupsweb/getGroupTeamList/${this.group_id}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                if ( !response.data.data.group ) {
                    this.hodu_show_dialog("alert", "그룹 조회중 오류가 발생하였습니다[GroupTeamList]", ['확인']);
                    return;
                }

                this.group_team_list.push({group_id: response.data.data.group.group_id, team_id: 0, name: response.data.data.group.group_info.group_name});

                $.each(response.data.data.team_list, function(key, data) {
                    vue.group_team_list.push({group_id: data.group_id, team_id: data.team_id, name: data.team_info.team_name});
                });
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

       await this.changeGroupTeam(this.group_id, this.team_id);
   }

    /**
     * 메시지 템플릿 조회
     */
    async getMessageTemplate() : Promise<void> {
        this.message_template = [];

        await this.hodu_api_call(`api/v1/message_template/GROUP/${this.group_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.message_template = response.data.data.list;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        // @ts-ignore
        $("#sendMmsMasageList").mCustomScrollbar({
            axis : 'y',
            setHeight : 376,
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 메세지 팝업창 닫기
     */
    closeMessagePop() : void {
        $("#showPhone").removeClass("on");
		$("#draggable").removeClass("on");
    }

    /**
     * 메세지 발송
     */
    async sendMessagePop() : Promise<void> {
        if ( this.message_content.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "메세지 문구를 입력해주세요", ['확인']);
            return;
        }

        let send_users : number[] = [];
        let for_count  : number   = this.members.length;

        for ( let i = 0; i < for_count; i++ ) {
            if ( this.members[i].checked ) {
                send_users.push(this.members[i].user_id);
                /*
                send_users.push({
                    user_id: this.members[i].user_id
                    //user_name: this.members[i].user_info.user_name,
                    //user_phone_number: this.members[i].user_info.user_phone_number,
                    //user_email: this.members[i].user_info.user_email
                });
                */
            }
        }

        if ( send_users.length == 0 ) {
            this.hodu_show_dialog("alert", "메세지 발송 할 그룹원을 선택해주세요", ['확인']);
            return;
        }

        let req_data = {
            users: send_users,
            group_id: this.selected_group_id,
            team_id: this.selected_team_id,
            group_team_name: this.selected_group_team_name,
            message_content: this.message_content
        }
        
        const scope = this.scope;
        const scope_id = this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id;

        await this.hodu_api_call(`api/v1/message_template/${scope}/${scope_id}/send`, API_METHOD.POST, req_data)
            .then(async(response) => {
                this.hodu_show_dialog("success", "성공적으로 메세지 발송하였습니다", ['확인']);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 메세지 선택
     */
    messageContentChange(str) : void {
        $('#sendMmsMassageScroll').addClass('dpnone');
        this.message_content = str;
        this.changeKey();
    }

    /**
     * 메세지 초기화
     */
    resetMessageContent() : void {
        this.message_content = "";
        this.changeKey();
    }

    /**
     * 키 입력
     */
    changeKey() : void {
        let str     = this.message_content;
        let strLen  = str.length;
        let rbyte   = 0;
        let rlen    = 0;
        let oneChar = "";
        let str2    = "";

        for ( let i = 0; i < strLen; i++ ) {
            oneChar = str.charAt(i);

            if ( escape(oneChar).length > 4 ) {
                rbyte += 2;
            }
            else {
                rbyte++;
            }

            if ( rbyte <= this.text_max ) {
                rlen = i+1;
            }
        }

        if ( rbyte > this.text_max ) {
            this.hodu_show_dialog("alert", "한글 " + (this.text_max / 2) + "자 / 영문 " + this.text_max + "자를 초과 입력할 수 없습니다.", ['확인']);
            
            str2 = str.substr(0, rlen);

            this.message_content = str2;
        }
        else {
            this.text_count = rbyte;
        }
    }

    /**
     * 옵션 클릭
     */
    messageOption() : void {
        $('#optionsDiv').toggleClass('on');
    }

    /**
     * 메세지 옵션 선택
     */
    messageContentAddOption(str) : void {
        this.message_content += str + " ";
        $('#sendMmsMasageContent').focus();
        this.changeKey();
    }

    /**
     * 그룹&팀 변경
     */
    async changeGroupTeam(pi_group_id, pi_team_id) : Promise<void> {
        this.selected_group_id = 0;
        this.selected_team_id  = 0;
        this.selected_group_team_name = "그룹&팀을 선택하세요";

        for ( let i = 0; i < this.group_team_list.length; i++ ) {
            if ( this.group_team_list[i].group_id == pi_group_id && this.group_team_list[i].team_id == pi_team_id ) {
                this.selected_group_id        = pi_group_id;
                this.selected_team_id         = pi_team_id;
                this.selected_group_team_name = this.group_team_list[i].name;
                break;
            }
        }

        $('#groupSelectDiv').addClass('dpnone');

        // 멤버조회
        await this.hodu_api_call(`api/v1/groupsweb/getGroupTeamMember/${this.selected_group_id}/${this.selected_team_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.members = [];
                
                let user_length = response.data.data.list.length;

                for ( let i = 0; i < user_length; i++ ) {
                    if ( response.data.data.list[i].user_id != this.user_id ) {
                        response.data.data.list[i].checked = false;
                        this.members.push(response.data.data.list[i]);
                    }
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        this.is_checkedAll = false;
    }

    /**
     * 전체선택, 전체해제
     */
    async selectAllCheckBox() : Promise<void> {
        this.is_checkedAll = !this.is_checkedAll;
        
        for ( let i = 0; i < this.members.length; i++ ) {
            this.members[i].checked = this.is_checkedAll;
        }
    }

    /**
     * 개별 체크박스 선택,해제
     */
    rowSelectCheckBox(pi_index) : void {
        this.members[pi_index].checked = !this.members[pi_index].checked;
        this.is_checkedAll = this.members.filter(member => member.checked == true).length == this.members.length;

        /*
        if ( this.members[pi_index].checked ) {
            let members_length = this.members.length;

            for ( let i = 0; i < members_length; i++ ) {
                if ( !this.members[i].checked ) {
                    this.is_checkedAll = false;
                    return;
                }
            }

            this.is_checkedAll = true;
        }
        else {
            this.is_checkedAll = false;
        }
        */
    }

    /**
     * 스크롤 top 이동
     */
    memberListScrollTop() : void {
        // @ts-ignore
        $('#sendMmsPageDiv').mCustomScrollbar("scrollTo", "top", { scrollInertia : 0 });
    }
}

</script>

<style scoped>
    .content {margin-top: 0px;margin-bottom: 0;padding: 0 20px;box-sizing: border-box;width: 100%;}
.content .noResultsBg {display: none;width: 100%;color: #949494;box-sizing: border-box;border-radius: 5px;border: 1px solid #f1f3f5;padding: 60px 20px;font-size: 14px;font-weight: bold;background: #f1f3f5;text-align: center;}
div#noReusltList {margin-top: -30px;}
div#noReusltList {background: none;border: none;padding: 0 30px;}
div#noReusltList .noResults {background: #f1f3f5;line-height: 100px;border-radius: 5px;}
.content > ul { overflow: hidden; }
.content > ul > li { margin: 10px 0 30px; }
.content .ydd { float: none; display: block; cursor:default; width: 100%; margin: 15px 0 15px; font-size: 16px; font-weight: bold; }
.content .attndHeader { width: 100%; height: 55px; background: #fafafa; line-height: 55px; font-size: 12px; border: 1px solid #eaeaea; box-sizing: border-box; }
.content .attndHeader .attndYes, .content .attndHeader .attndNo { box-sizing: border-box;  }
.content .attndHeader p { padding: 0; float: left; line-height: 54px; cursor: default; border-left: 1px solid #e4e4e4; box-sizing: border-box; padding-left: 15px; text-align: left; }
.content .attndHeader p:first-child { border-left: 0 none; padding-left: 0; text-align: center }
.content .subUl p { float: left; }
.content .subUl li {border-bottom: 1px solid #f1f3f5;width: 100%;height: 60px;line-height: 60px;font-size: 12px;}
.content .subUl li:hover { background: #f6fcff; }
.content .subUl li:first-child { /*margin-top: 5px*/ }
.content .attndYes, .content .attndNo { text-align: center; width: 10%; height: 54px; padding: 8px 5px; box-sizing: border-box; }
.content .subUl .attndYes, .content .subUl .attndNo { padding-right: 5px !important; padding-left: 5px !important; height: 55px;}
.content .subUl .attndYes span, .content .subUl .attndNo span { float: left; width: 100%; height: 100%; display: inline-block; background: transparent; font-size: 0; border-radius: 5px; }
/* .content .subUl .attndYes.on span { background: #e8f8fd url('../assets/images/contents/ic_attnd_yes.png') no-repeat center center;  } */
/* .content .subUl .attndNo.on span { background: #ffeded url('../assets/images/contents/ic_attnd_no.png') no-repeat center center; border-radius: 5px;} */
.content .subUl p {cursor: default;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;padding-right: 15px;font-weight: bold;padding-left: 15px;box-sizing: border-box;}
.content .subUl .num { padding-right: 0; padding-left: 0; }
.content .subUl .name { font-weight: bold; }
.content .subUl .name img {float: left;display: inline-block;margin: 13px 16px 0 0;width: 30px;height: 30px;border-radius: 50%;background: #e9eff4 url(../assets/images/contents/im_photoB.gif) no-repeat;background-size: cover;border:  1px solid #f1f3f5;}
.content .num { width: 7%; text-align: center; }
.content .name { width: 19%; }
.content .mobile { width: 20%; }
.content .email { width: 34% }

#allAttndMemberUbody.content .subUl li:hover p {font-weight: bold;}

#container .email.headerSort { background-position: 94% center }
#container .attndYes.headerSort, .attndNo.headerSort { background-position: 85% center }
#allMemberListScroll .tableDefault.tableStyle02 table tr th { height: 55px; line-height: 55px; padding: 0; }
#allMemberListScroll .tableDefault.tableStyle02 table tr th p { padding: 0 10px !important;; line-height: 55px; box-sizing: border-box; }


.attndPage .transit { transition: 0.3s; }
.scrollUp { opacity: 1; z-index: 100; position: absolute; bottom: 40px; right: 40px; transition: 0.2s; font-size: 0; border-radius: 50%; display: block; width: 60px; height: 60px; background: #36bdff url('../assets/images/contents/scrollUp.png') no-repeat center center; box-shadow: 0 5px 15px rgba(92, 66, 255, 0.5); }
.scrollUp.on { bottom: -100px !important; }
.scrollUp:hover { background-color: #f6fcff; transition: 0.1s; box-shadow: 0 10px 25px rgba(92, 66, 255, 0.5); bottom: 45px;}

a#attndMemberListExcelDownLoad span {opacity:0;display: block;margin-top: -35px;color: #fff;}
/* a#attndMemberListExcelDownLoad {text-indent:10000px; transition: 0s;background: #00ab5d url(../assets/images/contents/ic_fileDownload.png) no-repeat center center;background-size: 42px;box-shadow: 0 5px 15px rgb(0, 171, 93,0.4);}  */
#attndMemberListExcelDownLoad:hover {transition: 0s;background: #00904e;background-size: 42px;font-size: 12px;box-shadow: 0 10px 25px rgb(0, 171, 93,0.5);font-weight: bold;color: #fff;text-align: center;line-height: 50px;text-indent:0;}
#attndMemberListExcelDownLoad:hover span {opacity:1;}
.mCSB_container { margin-right: 0 !important; }

#container #section_ce .msgOptionPage .schedule_box {max-width: 100%;padding-left: 0;}

/* 문자발송현황 */
#container #section_ce .msgPage .schedule_box {max-width: 100%;}

.sendMmsPage .toTheLeft:hover {opacity: 1;}
#container .sendMmsPage .content .headerSort.grp {box-sizing: border-box;padding: 0;}
.msgPage {height: 100%;padding-top: 110px; box-sizing: border-box;}
.msgPage .schedule_box {margin-top: 30px;margin-bottom:40px;margin-right: 0;}
.msgPage.sendMmsPage .schedule_box {margin-right: 0;padding-right: 0 !important;}
.msgPage .content .msgHeader {width: 100%;height: 60px;top: 129px;line-height: 60px;padding-left: 261px;padding-right: 60px;z-index: 1;transition: 0.1s;position: fixed;left: 0;font-size: 12px;/* border: 1px solid #eaeaea; */box-sizing: border-box;}
.left_area_close .msgPage .content .msgHeader {padding-left: 61px;}
.right_area_close .content .msgHeader {padding-right: 0 !important;}
.msgPage .content .msgHeader .attndYes, .msgPage .content .msgHeader .attndNo { box-sizing: border-box;  }
.msgPage .content .msgHeader p {height: 100%;padding: 0;float: left;line-height: 60px;font-weight: bold;cursor: default;font-size: 14px !important;box-sizing: border-box;padding-left: 15px;text-align: left;background-color: #f2f5f7;height: 60px;border-bottom: 1px solid #e7e9ea;}
.msgPage .content .msgHeader p.num {  padding-left: 0; text-align: center }
.msgPage .content .msgHeader .checkBox { width: 7%; padding: 0; border-left: 0 none;}
.msgPage .num {width: 7%;}
.msgPage .ymdHour {width: 11%;}
.msgPage .name { width: 15% }
.msgPage .from { width: 15% }
.msgPage .msgCont { width: 23% }
.msgPage .content .msgHeader .scsFail { position: relative; text-align: center; width: 22%; vertical-align: middle; }
.msgPage .content .msgHeader .scsFail .scs {position: relative;margin-right: 10px;width: 60px;height: 100%;cursor: pointer;display: inline-block;}
.msgPage .content .msgHeader .scsFail .fail { position: relative; width: 50px; height: 100%; cursor: pointer; display: inline-block; }
.msgPage .content .msgHeader .scsFail label span {margin-top: 9px;margin-left: 31px;width: 40px;display: inline-block;font-size: 12px;}
.msgPage .content .msgHeader .scsFail input[type='checkbox']:not(old) + label { margin-left: 0; left: 0;  }
#wrap .msgPage .content .msgHeader .scsFail .scs input[type='checkbox']:not(old):checked + label {background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat 0px 0px !important;border: 2px solid #477fff !important;background-size: 21px !important;}
.msgPage .content .msgHeader .scsFail .fail input[type='checkbox']:not(old):checked + label {background: #ff3f3f url('../assets/images/contents/checked_fff.png') no-repeat 0 0px  !important;border: 2px solid #ff3f3f !important;background-size: 21px !important;}

.msgPage .content .msgHeader .scsFail { padding-left: 0; }
.msgPage .content .msgHeader .checkBox { position: relative; cursor: pointer; text-align: center; }
.msgPage .content .msgHeader .checkBox span { margin-top: 17px; display: inline-block; }
#selectCheckBoxAll input[type='checkbox']:not(old) + label {
    background-color: #c1cfd8 !important;
    border: 2px solid #c1cfd8;
}
#selectCheckBoxAll input[type='checkbox']:not(old):checked + label {
    background-color: #36bdff !important;
    border: 2px solid #36bdff;
}
.msgPage .content .checkBox {position: relative; border-left: 1px solid #fff; cursor: pointer; width: 100%; height: 100%; text-align: center; }
.msgPage .content .checkBox span { margin-top: 10px; display: inline-block; width: 16px; height: 16px; border-radius: 5px; border: 2px solid #d9d9d9; background: #fff url('../assets/images/contents/checked_gray.png') no-repeat -2px -2px; background-size: 21px; }
.msgPage .content .checkBox.on span { background: #36bdff url('../assets/images/contents/checked_fff.png') no-repeat -2px -2px; border: 2px solid #36bdff; background-size: 21px;}
.msgPage .content .subUl li.on, .msgPage .content .subUl li:hover {background: #f6f9fb;transition: 0.2s;border-bottom: 1px solid #e7e9ea;}

.msgPage.pagination#sentMsgPaging { margin-left:0; margin-top: 0; }



 /* 841부터 874까지 왼쪽 체크박스 변경시 */

.msgPage input[type='checkbox']:not(old) {border-left: 1px solid #fff; cursor: pointer; width: 100%; height: 100%; text-align: center; }
.msgPage input[type='checkbox']:not(old) + label { display: inline-block; padding: 0; width: 16px; height: 16px; border-radius: 5px; border: 2px solid #d9d9d9; background: #fff url('../assets/images/contents/checked_gray.png') no-repeat -2px -2px; background-size: 21px; font-size: 0; }
.msgPage input[type='checkbox']:not(old) + label {position: absolute;top: 0;left: 50%;margin-left: -10px;margin-top: 17px;}
.msgPage .subUl input[type='checkbox']:not(old) + label { margin-top: 11px; }
.msgPage input[type='checkbox']:not(old):checked + label { background: #36bdff url('../assets/images/contents/checked_fff.png') no-repeat -2px -2px; border: 2px solid #36bdff; background-size: 21px; }

.msgPage  input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 20px;height: 20px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../assets/images/contents/checked_fff.png) no-repeat 0px 0px;background-size: 21px;font-size: 0;margin-right: 8px;}
.msgPage input[type='checkbox']:not(old):checked + label {background: #36bdff url('../assets/images/contents/checked_fff.png') no-repeat 0px 0px;background-size: 21px;border: 2px solid #36bdff;}

.hc.pr_dc0 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #FF6363; border-color: #FF6363; }
.hc.pr_dc1 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #FFA70E; border-color: #FFA70E; }
.hc.pr_dc2 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #FFC72F; border-color: #FFC72F; }
.hc.pr_dc3 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #FF198B; border-color: #FF198B; }
.hc.pr_dc4 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #00B2C7; border-color: #00B2C7; }
.hc.pr_dc5 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #13D08B; border-color: #13D08B; }
.hc.pr_dc6 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #4DBAFF; border-color: #4DBAFF; }
.hc.pr_dc7 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #477FFF; border-color: #477FFF; }
.hc.pr_dc8 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #6854FF; border-color: #6854FF; }
.hc.pr_dc9 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #35405A; border-color: #35405A; }

/* leftarea he */

#container #section_ce .msgPage{ overflow: hidden; } 
.msgPage .subUl .checkBox { height: 100%; padding: 0; width: 7%; }
.msgPage .subUl .ymdHour { line-height: 0; }
.msgPage .subUl .double {display: block;line-height: 26px;height: 45px;width: 100%;text-overflow: ellipsis;white-space: nowrap;overflow:hidden;}
.msgPage .subUl .related, .msgPage .subUl .time {color: #949494;height: 17px;display: block;line-height: 0;margin-top: 4px;}
.msgPage .subUl .scsFail {width: 22%;text-align:center;}
.msgPage .subUl .scsFail .scsChecked, .scsFail .failChecked {display: none;/* background: #e8efff; */border-radius: 23px;height: 100%;width: 100%;text-align: center;box-sizing: border-box;max-width: 130px;}
.msgPage .subUl .scsChecked span.result {display: inline-block;/* background: url('../assets/images/contents/checked_blue.png') no-repeat left 8px; */display: inline-block;/* padding-left: 30px; *//* padding-right: 6px; */box-sizing: border-box;color: #477fff;}
.msgPage .subUl .failChecked { background: #ffebeb }
.msgPage .subUl .failChecked span.result {display: inline-block;color: #ff3f3f;display: inline-block;box-sizing: border-box;}
.msgPage .subUl li:hover .failChecked .result, .msgPage .subUl li:hover .scsChecked .result { display: none }
.msgPage .subUl li .failChecked .btn { background: #ff6060; height: 100%; box-sizing: border-box; width: 100%; font-weight: bold;  }
.msgPage .subUl li .scsChecked .btn { background: #00e29a; height: 100%; box-sizing: border-box; width: 100%; font-weight: bold;  }
.msgPage .subUl li .failChecked .btn, .msgPage .subUl li .scsChecked .btn { vertical-align: top; display: none; padding: 0; }
.msgPage .subUl li:hover .failChecked.on .btn, .msgPage .subUl li:hover .scsChecked.on .btn { display: inline-block; background-color: #ff3f3f; border-radius: 20px; cursor: pointer }
.msgPage .subUl li:hover .scsChecked.on .btn {background: #477fff;}
.msgPage .subUl li:hover .scsChecked.on .btn:hover {background: #477fff;}
.msgPage .subUl li:hover .failChecked, .msgPage .subUl li:hover .scsChecked { background: transparent; }
.msgPage .subUl li:hover .failChecked span { }
.msgPage .subUl li:hover .failChecked span { color: #fff; padding: 0; }
.msgPage .subUl .scsChecked.on, .msgPage .subUl .failChecked.on { display: inline-block; }
.msgPage .subUl li {padding: 7px 0;box-sizing: border-box;}
.msgPage .subUl li p {line-height: 42px; height: 40px;}
.msgPage .subUl li:hover p, .msgPage .subUl li:hover p span {font-weight: bold; cursor:default;}


.setting2Bg {width: 100%;position: relative;height: 45px;margin-bottom: 10px;}
.selectBg {min-width: 60px;width: auto;float: left;position: relative;height: 100%;box-sizing:border-box;cursor: pointer;border-radius: 5px;}
.selectBg .selectTitle {display: block;height: 100%;font-size: 14px;width: auto;padding: 0 0 0 10px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.selectBg:hover h3.selectTitle .txt {color: #477fff;}
.selectBg .selectTitle .txt {line-height: 45px;}
.selectBg .selectTitle:after {content: '';display: inline-block;width: 20px;margin-left: 10px;height: 40px;border-radius: 50%;float: right;background: url(../assets/images/side_area/bt_open.png) no-repeat 0px 10px;}
.sendMmsPage .settingBg .selectBg:hover h3 {color: #477fff;transition: 0.2s;}

.selectBg .selectTitle .arrowDown {}
.selectBg .selectTitle.on .arrowDown {background: url(../assets/images/side_area/bt_close.png) no-repeat 0px 8px;}
.selectBg .selectTitle:hover .arrowDown {background-color: transparent;}
.setting2Bg .btn_resend { margin-top: 8px; float: right; padding: 0 25px; background: #36bdff; box-sizing: border-box; height: 35px; line-height: 35px; font-weight: bold; border-radius: 20px; color: #fff;  } 
.setting2Bg .btn_resend:hover { background: #f6fcff; transition: 0.2s;}
.selectUlBg {z-index: 100;position: absolute;top: 46px;right: 0;width: 100%;height: auto;min-width: 340px;background:#fff;box-shadow: 0 5px 10px rgba(0,0,0,0.1);max-width: 420px;border-radius: 5px;}
.selectUlBg .scroll {height: auto;max-height: 280px;}
.selectUl li {display: block;line-height: 45px;position: relative;padding: 0 15px;}
.selectUl { max-height:230px; overflow-y:auto; background: #fff;border-radius: 5px;/* box-shadow: 0 5px 10px rgba(0,0,0,0.1); */left: 0;width: 100%;top: 93px;border: 1px solid #f1f3f5;box-sizing: border-box;}
.selectUl li {border-bottom: 1px solid #f1f3f5;}
.selectUl li:last-child { border:0 none }
.selectUl li:hover { background-color:#f1f3f5; font-weight:bold; }
.selectUl li.on:hover { background: #fff; cursor:default; }
.selectUl li.on { font-weight:bold; }
.selectUl li.on:after {display: block;content: '';position: absolute;right: 13px;top: 19px;width: 7px;height: 7px;background: #477fff;border-radius: 50%;}
#sendMmsMasageList.selectUl { top:0; box-shadow: none;  }
#sendMmsMasageList.selectUl li:last-child { border-bottom: 1px solid #f1f3f5; }
#sendMmsMasageList.selectUl li:hover { cursor: pointer;}
#sendMmsMasageList.selectBg .selectTitle:after { display: none; }
#sendMmsMasageTxt.on .arrowDown { background: url(../assets/images/side_area/bt_close.png) no-repeat 0px 8px; }
#sendMmsMasageList:hover {cursor: default; }


.bt_mms { opacity: 1; z-index: 1000; position: absolute; bottom: 40px; right: 40px; transition: 0.2s; font-size: 0; border-radius: 50%; display: block; width: 60px; height: 60px; background: #00e29a url('../assets/images/contents/bt_mms.png') no-repeat center center; box-shadow: 0 5px 15px rgba(0, 226, 154, 0.4);}
.bt_mms:hover { background-color: #00d08e; transition: 0.1s; box-shadow: 0 10px 25px rgba(0, 226, 154, 0.5);}

.bt_mms.on { bottom: 40px; right: 130px !important; transition: 0.2s; font-size: 0; border-radius: 50%; display: block; width: 60px; height: 60px; background: #00e29a url('../assets/images/contents/bt_mms.png') no-repeat center center; box-shadow: 0 5px 15px rgba(0, 226, 154, 0.4);}
.bt_mms.on:hover { background-color: #02da95; box-shadow: 0 10px 25px rgba(0, 226, 154, 0.5);}

/* .btn_resend.bt_mms { background: #36bdff url('../assets/images/contents/bt_resend.png') no-repeat center center !important;box-shadow: 0 5px 15px rgba(60, 170, 255,0.4) !important; } */
.btn_resend.bt_mms:hover { background-color: #0b9aff; transition: 0.1s; box-shadow: 0 10px 25px rgba(60, 170, 255, 0.5);}

.scrollUp {background: #36bdff url(../assets/images/contents/scrollUp.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(60, 170, 255, 0.5) !important; }
.scrollUp:hover { background: #0b9aff url(../assets/images/contents/scrollUp.png) no-repeat center center !important; box-shadow: 0 10px 25px rgba(60, 170, 255, 0.5) !important; }

/* .sendMmsPage */
.sendMmsPage #sendMmsPageSelectBox .input_btn {margin-right: 30px;}
.sendMmsPage .content {overflow: hidden;width: 100%;}
.sendMmsPage .content > ul { overflow: initial; }
.sendMmsPage .settingBg { z-index: 1000; }
.sendMmsPage .settingBg .left { max-width: 600px; width: auto; }
.sendMmsPage .settingBg .left .grp { width: 100%; }
.sendMmsPage .settingBg .selectBg {width: auto;}
.sendMmsPage .bigGrp {margin: 11px 0 0 20px;}
.sendMmsPage .settingBg .selectBg .bg { background: #fff; opacity: 0.1; }
.sendMmsPage .settingBg .selectBg h3 {color: #5f5f5f;line-height: 40px;}
.sendMmsPage .settingBg .selectBg h3:after {content: '';display: inline-block;width: 20px;margin-left: 10px;height: 40px;border-radius: 50%;float: right;background: url(../assets/images/side_area/bt_open.png) no-repeat 0px 8px;}
.sendMmsPage .settingBg .selectBg .selectTitle .arrowDown {}
.sendMmsPage .bigGrp.left .selectUlBg { z-index: 1000 }
.sendMmsPage .selectUlBg { z-index: 500;right: auto;left: 5px;top: 54px; }

.sendMmsPage .toTheLeft.on {display: block;}
.sendMmsPage .toTheLeft {z-index: 1;position: absolute;opacity: 1;width: 300px;height: 510px;display: none;right: 40px;z-index: 10000000;top: 20px;background: #fff;box-shadow: 0 5px 40px #ffffff;border-radius: 50px;border: 9px solid #e2eaec;box-sizing: border-box;padding: 0;padding-bottom: 50px;}
.sendMmsPage .toTheLeft:after {content: '';display: block;position: absolute;left: -11px;top: -11px;border-radius: 52px;width: 300px;height: 510px;border: 2px solid #ffffff;z-index: -111111;box-shadow: 0 5px 40px #ffffff;}
.sendMmsPage .toTheLeft .speakerCam {width: 110px;background: #e2eaec;position: absolute;margin-left: -55px;border-radius: 0 0 10px 10px;top: 0px;left: 50%;height: 20px;opacity: 1;text-align: center;}
.sendMmsPage .toTheLeft .speakerCam .grp { display: inline-block; }
.sendMmsPage .toTheLeft .speaker {display: inline-block;width: 50px;height: 4px;margin-top: 3px;margin-right: 10px;border-radius: 2px;background: #7291a0;}
.sendMmsPage .toTheLeft .cam {display: inline-block;float: right;margin-top: 5px;width: 8px;height: 8px;border-radius: 5px;background: #7291a0;}
.sendMmsPage .toTheLeft .btns {height: 40px;width: 100%;padding-top: 20px;position: absolute;bottom: -70px;text-align: center;left: 0;}
.sendMmsPage .toTheLeft .btn {font-size: 14px;/* font-family: 'dotum', sans-serif; */width: 114px;height: 100%;line-height: 40px;border-radius: 30px;transition: 0.2s;opacity: 0;margin-top: -20px;color: #fff;font-weight: bold;display: inline-block;}
.sendMmsPage .toTheLeft:hover .btn {opacity: 1;transition: 0.2s;margin-top: 0;}
#bt_reSendMmsContent.on { display:block; }
#bt_reSendMmsContent {display:none;position:absolute;bottom:10px;right:15px;width:35px;font-size: 0;height:35px;background: #f1f3f5 url(../assets/images/contents/ic_close.gif) no-repeat center center !important;border-radius:50%;}
#bt_reSendMmsContent:hover { background-color:#e8ebef !important; }
.sendMmsPage .toTheLeft .selectBg {box-sizing: border-box;width: 100%;min-width: 100%;max-width: 100%;border-radius: 5px 5px 0 0;height: 46px;margin-top: 25px;}
.sendMmsPage .toTheLeft .btn-green {background: #1fa7ea !important;color: #fff;border: 1px solid #1fa7ea;box-shadow: 0 5px 10px rgba(31, 167, 234, 0.2);}
.sendMmsPage .toTheLeft .btn-green:hover {background: #05a0ec !important;box-shadow: 0 5px 15px rgba(31, 167, 234, 0.3);}
.sendMmsPage .toTheLeft .textBg {position: relative;margin-top: 71px;width: 100%;height: 376px;border-radius: 0;/* overflow: hidden; */max-width: 370px;min-width: 100%;max-width: 100%;/* border-bottom: 1px solid #f1f3f5; */}
.sendMmsPage .toTheLeft .countLetters {cursor: default;position: absolute;right: 24px;bottom: -34px;background: rgba(255,255,255,0.9);opacity: 1;padding: 5px 5px;border-radius: 5px;}
.sendMmsPage .countLetters .counted { font-weight: bold;color:#22a9eb }
.sendMmsPage .toTheLeft textarea {font-weight:bold;font-size: 12px;resize: none;width: 100%;background: #ffffff;height: 100%;line-height: 22px;padding: 15px 20px 40px;box-sizing: border-box;border-bottom: 1px solid #e7e9ea !important;border-top: 1px solid #e7e9ea !important;}
.sendMmsPage .toTheLeft textarea { border: none; overflow: auto; outline: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.sendMmsPage .toTheLeft textarea:focus {background:#fff !important; transition:0.2s;border-top: 1px solid #1fa7ea !important;border-bottom: 1px solid #1fa7ea !important;}
.sendMmsPage .toTheLeft textarea:hover { background:#f1f3f5; }
.sendMmsPage .toTheLeft textarea:disabled {color: #959cab;}
.sendMmsPage .toTheLeft textarea:disabled:hover { background:#fff; }
.sendMmsPage .selectBg:hover {transition: 0.2s;}
.sendMmsPage .toTheLeft .selectUl, .sendMmsPage .toTheLeft .selectUlBg { min-width: 100%; }
.sendMmsPage .toTheLeft .selectTitle {text-overflow: ellipsis;white-space: nowrap;overflow: hidden;position: absolute;top: 0;height: 45px;z-index: 10000;width: 100%;font-size: 14px;line-height: 45px;padding: 0 20px;box-sizing: border-box;}
.sendMmsPage .toTheLeft .selectTitle:hover {color: #1fa7ea;}

#draggable.on {display: block;}
#draggable {z-index: 1;position: absolute;opacity: 1;width: 300px;height: 510px;display: none;cursor:move;cursor: default;right: 40px;z-index: 10000000;top: 100px;background: #fff;border-radius: 50px;border: 9px solid #5f7384;box-sizing: border-box;padding: 0;padding-bottom: 50px;}
#draggable:hover {box-shadow: 0 5px 40px rgba(95, 115, 132, 0.5);}
#draggable:after {content: '';display: block;position: absolute;left: -11px;top: -11px;border-radius: 52px;width: 300px;height: 510px;border: 2px solid #ffffff;z-index: -111111;box-shadow: 0 5px 40px #ffffff;}
#draggable .speakerCam {width: 110px;background: #5f7384;position: absolute;margin-left: -55px;border-radius: 0 0 10px 10px;top: 0px;left: 50%;height: 20px;opacity: 1;text-align: center;}
#draggable .speakerCam .grp { display: inline-block; }
#draggable .speaker {display: inline-block;width: 50px;height: 4px;margin-top: 7px;margin-right: 10px;border-radius: 2px;float: left;background: #234352;}
#draggable .cam {display: inline-block;float: right;margin-top: 2px;width: 7px;height: 7px;border-radius: 8px;background: #ff5959;border: 3px solid #234352;}
#draggable .btns {height: 40px;width: 100%;padding-top: 20px;position: absolute;bottom: -70px;text-align: center;left: 0;}
#draggable .btn {font-size: 14px;width: 104px;height: 100%;line-height: 40px;border-radius: 30px;transition: 0.2s;opacity: 0;margin-top: -20px;color: #fff;font-weight: bold;display: inline-block;}
#draggable:hover .btn {opacity: 1;transition: 0.2s;margin-top: 0;}
#draggable .btns > input {margin: 0 10px;}
#draggable .btns > input#bt_closeNow {background: #ffffff !important;color:#ff6565;border: 1px solid #e7e9ea;}
#draggable .btns > input#bt_closeNow:hover {background: #ff6565 !important;color:#ffffff;border: 1px solid #ff6565;box-shadow: 0 5px 15px rgba(255, 101, 101, 0.3);}
#bt_reSendMmsContent.on { display:block; }
#bt_reSendMmsContent {display:none;position:absolute;bottom: 20px;right: 25px;width: 35px !important;font-size: 0 !important;height: 35px !important;background: #f1f3f5 url(../assets/images/contents/ic_close.gif) no-repeat center center !important;border-radius:50%;}
#bt_reSendMmsContent:hover { background-color:#e8ebef !important; }
#draggable .selectBg {box-sizing: border-box;width: 100%;min-width: 100%;max-width: 100%;border-radius: 5px 5px 0 0;height: 46px;margin-top: 25px;}
#draggable .btn-green {color: #35bcff;background: #fff !important;border: 1px solid #e7e9ea;}
#draggable .btn-green:hover {background: #35bcff !important;color:#fff;box-shadow: 0 5px 15px rgba(31, 167, 234, 0.3);}
#draggable .textBg {position: relative;margin-top: 71px;width: 100%;height: 376px;border-radius: 0;/* overflow: hidden; */max-width: 370px;min-width: 100%;max-width: 100%;/* border-bottom: 1px solid #f1f3f5; */}
#draggable .countLetters {cursor: default;position: absolute;right: 24px;bottom: -34px;background: rgba(255,255,255,0.9);opacity: 1;padding: 5px 5px;border-radius: 5px;}
.countLetters .counted { font-weight: bold;color:#22a9eb }
#draggable textarea {font-weight:bold;font-size: 12px;resize: none;width: 100%;background: #ffffff;height: 100%;line-height: 22px;padding: 15px 20px 40px;box-sizing: border-box;border-bottom: 1px solid #e7e9ea !important;border-top: 1px solid #e7e9ea !important;}
#draggable textarea { border: none; overflow: auto; outline: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
#draggable textarea:focus {background:#fff !important; transition:0.2s;border-top: 1px solid #1fa7ea !important;border-bottom: 1px solid #1fa7ea !important;}
#draggable textarea:hover { background:#f1f3f5; }
#draggable textarea:disabled {color: #959cab;}
#draggable textarea:disabled:hover { background:#fff; }
.selectBg:hover {transition: 0.2s;}
#draggable .selectUl, #draggable .selectUlBg { min-width: 100%; }
#draggable .selectTitle {text-overflow: ellipsis;white-space: nowrap;overflow: hidden;position: absolute;top: 0;height: 45px;z-index: 10000;width: 100%;font-size: 14px;line-height: 45px;padding: 0 20px;box-sizing: border-box;}
#draggable .selectTitle:hover {color: #1fa7ea;}

.sendMmsPage .toTheRight {box-sizing: border-box;padding-left: 0;margin-top: 15px;}
.sendMmsPage .setting2Bg { line-height: 40px; margin-top: 20px; }
.sendMmsPage .setting2Bg h4 { display: inline-block; font-size: 18px; }
.sendMmsPage .setting2Bg .toWhom  { float: right; width: auto; height: 50px; }
.sendMmsPage .setting2Bg .toWhom .toStudent { width: 115px; margin-right: 15px; }
.sendMmsPage .setting2Bg .toWhom > span { background: #fff; position: relative; margin-top: 5px; display: inline-block; border-radius: 18px; width: 130px; height: 35px;  }
.sendMmsPage .setting2Bg .toWhom label { width: 100%; border: 0 none; height: 100%; box-sizing: border-box; left: 0; top: 0; margin-top: 0;border-radius: 18px; text-align: center;  margin-left: 0; background: url(../assets/images/contents/checked_gray.png) no-repeat 10px 3px; border: 2px solid #ececec;}
.sendMmsPage .setting2Bg .toWhom label span { color: #4e4e4e; width: 100%; height: 100%; font-size: 12px; line-height: 35px; width: 100%; margin-left: 14px; } 
.sendMmsPage .setting2Bg .toWhom input[type='checkbox']:not(old):checked + label{ text-align: center; background: #36bdff url(../assets/images/contents/checked_fff.png) no-repeat 10px 3px; border: 2px solid #36bdff; }
.sendMmsPage .setting2Bg .toWhom input[type='checkbox']:not(old):hover + label { text-align: center; background: #36bdff url(../assets/images/contents/checked_fff.png) no-repeat 10px 3px; border: 2px solid #36bdff; }
.sendMmsPage .setting2Bg .toWhom input[type='checkbox']:not(old):checked + label span,.sendMmsPage .setting2Bg .toWhom input[type='checkbox']:not(old):hover + label span {color: #fff; font-weight: bold; }
.sendMmsPage .subUl input[type='checkbox']:not(old) + label {margin-top: 17px;}
.sendMmsPage .subUl li { padding: 0; }
.sendMmsPage .content .subUl li {clear: both;transition: 0.2s;}
.sendMmsPage .subUl li p {height: 100%;line-height: 60px;font-weight: bold;}
#container .sendMmsPage .content .checkBox {width: 9%;}
#container .sendMmsPage .content .num {width: 7%;}
#container .sendMmsPage .content .grp {width: 17%;padding-left:0;}
#container .sendMmsPage .content .name {width: 28%;font-size: 13px;}
#container .sendMmsPage .content .mob {width: 20%;}
#container .sendMmsPage .content .email {width: 19%;}
.sendMmsPage .subUl li.withKins:hover p { cursor: pointer; }

/* .sendMmsPage .subUl li.withKins { background: url('../assets/images/contents/ic_select_on.png') no-repeat 97% center; }
.sendMmsPage .subUl li.withKins.on { background: #f6fcff url('../assets/images/contents/ic_select_on.png') no-repeat 97% center; }
.sendMmsPage .subUl li.withKins:hover { background: #f6fcff url('../assets/images/contents/ic_select_on.png') no-repeat 97% center; }
.sendMmsPage .subUl li.withKins.arrow { cursor: pointer; background: #fff url('../assets/images/contents/ic_select_up_on.png') no-repeat 97% center; }
.sendMmsPage .subUl li.withKins.arrow { border-bottom: 1px solid #fff; }
.sendMmsPage .subUl li.withKins.on.arrow { cursor: pointer; background: #f6fcff url('../assets/images/contents/ic_select_up_on.png') no-repeat 97% center; }
.sendMmsPage .subUl li.withKins.on.arrow .linked { background: #f6fcff }
.sendMmsPage .subUl li.withKins.arrow:hover .linked { background: #f6fcff }
.sendMmsPage .subUl li.withKins.arrow:hover { background: #f6fcff url('../assets/images/contents/ic_select_up_on.png') no-repeat 97% center; } */


.sendMmsPage .subUl li.withKins.on { background: #f6fcff; }
.sendMmsPage .subUl li.withKins:hover { background: #f6fcff; }
.sendMmsPage .subUl li.withKins.arrow { cursor: pointer; background: #fff; }
.sendMmsPage .subUl li.withKins.arrow { border-bottom: 1px solid #fff; }
.sendMmsPage .subUl li.withKins.on.arrow { cursor: pointer; background: #f6fcff; }
.sendMmsPage .subUl li.withKins.on.arrow .linked { background: #f6fcff }
.sendMmsPage .subUl li.withKins.arrow:hover .linked { background: #f6fcff }
.sendMmsPage .subUl li.withKins.arrow:hover { background: #f6fcff; }


.sendMmsPage .linked { overflow: hidden; height: 0; clear: both; width: 100%; box-sizing: border-box; float: left; border-bottom: 1px solid #f3f3f3; }
.sendMmsPage .linked.on { height: auto; padding: 5px 20px 15px 8%; transition: 0.1s;}
.sendMmsPage .linked li:first-child { margin-top: 0; }
.sendMmsPage .linked li { position: relative; border-radius: 5px; margin-top: 10px; background: #f3f3f3; border-bottom: 0; }
#container .sendMmsPage .linked li.on, #container .sendMmsPage .linked li:hover { background: #ececff; }
#container .sendMmsPage .content .linked .checkBox { position: absolute; width: 100%; }
#container .sendMmsPage .content .linked .mob { width: 50%; padding-left: 20px; }
#container .sendMmsPage .content .linked .name { margin-left: 8%; width: 24.5%;  padding-left: 20px; }
#container .sendMmsPage .content .linked .checkBox { border-left: 0 none; }
#container .sendMmsPage .content .linked p { line-height: 45px; height: 45px; }
#container .sendMmsPage .content .linked p.kin { float: right; margin-right: 13px; font-weight: bold; color: #949494; }
#container .sendMmsPage .content .linked li { line-height: 45px; height: 45px; }
.sendMmsPage .linked input[type='checkbox']:not(old) + label { background: url(../assets/images/contents/checked_gray.png) no-repeat 3% 9px; left: 0; margin-left: 0; height: 100%; margin-top: 0; border: 0 none; width: 100%; }
.sendMmsPage .linked input[type='checkbox']:not(old):checked + label, .sendMmsPage .linked input[type='checkbox']:not(old):hover + label { background: url(../assets/images/contents/checked_purple.png) no-repeat 3% 9px; left: 0; margin-left: 0; height: 100%; margin-top: 0; border: 0 none; width: 100%; }
#sendMmsPageUserList {margin-bottom: 25px;}

#sendMmsPageSelectBox > .bg {background:none}

.grp.settingBg {transition: 0.1s;z-index: 10;min-width: 708px !important;position: fixed;top: 64px;width: 100% !important;margin-left: 0 !important;background: #fff;padding-left: 261px !important;padding-right: 60px;left: 0!important;height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
.grp.setting2Bg {display: none;}

.left_area_close .grp.settingBg { padding-left: 61px !important; padding-right: 50px !important; }

.section_ce_fix ::-webkit-scrollbar{width: 6px;}
.section_ce_fix ::-webkit-scrollbar-track{background-color: transparent;}
.section_ce_fix ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

</style>