<template>
    <!-- 오른쪽 닫기 .closeRight 클릭시 section_ce_fix에 toggleClass rightDivClosed -->
    <div class="holiday_setting section_ce_fix" :class="{ 
        rightDivClosed : is_right_div_open == false,
        dp2 : is_card_view == true,
        dp1 : is_card_view == false, 
    }">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">파일함</h3>
            <!-- <div class="btns fr">
                <input type="button" class="input_btn" value="삭제" />
                <input type="button" class="input_btn" value="저장" />
            </div> -->
        </div>
        
        <div class="schedule_box">
            <div class="settingBg">
                <!-- 비즈그룹내에서 들어왔을때 -->
                <h3 id="" class="selectTitle" v-if="!isPersonalScope(scope)">
                    <span class="clr" :class="hodu_color_dc_lc(scope_group_team_option.group_team_color)">파랑</span>
                    {{ scope_group_team_option.group_team_name }}
                </h3>
                <!-- <h3 id="" class="selectTitle"><span class="clr dc7">파랑</span>이석호내과</h3> -->
                <!-- left menu -->
                <div class="btns_left">
                    <input type="button" class="schBtn closeRight" :class="{ on : is_right_div_open == true }" @click="rightDivOnOff"/>
                </div>
                <!-- 파일, 폴더 검색 -->
                <div class="schDiv">
                    <input type="text" placeholder="파일명을 검색하세요" :value="file_search_query" @input="file_search_query = $event.target.value.trim()" />
                    <input type="button" class="schBtn">
                </div>
                
                <!-- 개인으로 들어왔을때 -->
                <select v-if="isPersonalScope(scope)" @change="changeFileBoxTab($event.target.value)">
                    <option value="ALL" :selected="file_box_tab == 'ALL'">전체</option>
                    <!-- 개인 파일함은 나중으로 -->
                    <!-- <option value="my">내폴더</option> -->
                    <option value="PREMIUM" :selected="file_box_tab == 'PREMIUM'">프리미엄</option>
                    <option value="HODUDOC" :selected="file_box_tab == 'HODUDOC'">호두닥</option>
                </select>
                
                <!-- 그룹 파일함에 들어왔을때 추가버튼 활성화 -->
                <div class="folderAddBtn" v-if="scope_group_id > 0">
                    <a id="folderAdd" @click="showGroupAddFileBox" class="input_btn">폴더추가</a>
                </div>

                <div class="btns">
                    <input type="button" value="박스형으로보기" title="박스형" class="dpTwo" @click="cardViewModeOnOff" v-show="is_card_view == false">
                    <input type="button" value="리스트로보기" title="리스트" class="dpOne" @click="cardViewModeOnOff" v-show="is_card_view == true">
                </div>
            </div>
        </div>
        
        <!-- 그룹에 들어왔을때 이동경로표시 -->
        <div id="route_bar" v-if="scope_group_id > 0">
            <nav class="deep_route">
                <ul class="routeUl">
                    <li>
                        <i class="ic_file">아이콘</i>
                    </li>
                    <!-- 해당 파일함 경로시 클래스 fixd_route 추가 -->
                    <li class="routeLi fixd_route">
                        <a href="#" @click.prevent="goGroupFileList">{{ scope_group_team_option.group_team_name }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <!-- //그룹에 들어왔을때 이동경로표시 -->

        <div id="" class="content">
            <div class="mainLeftDiv">
                <!-- 수정전 클래스 mainDisplayNone (숨김) -->
                <div class="mainLeftDivScroll">

                    <!-- 개인 파일함 or 그룹 파일함-->
                    <div class="h4Div" v-if="!isPersonalScope(scope)">
                        <h4>{{  isPersonalScope(scope) == true ? '내 폴더' : '폴더'  }}</h4>
                        <p class="totalNum">{{ `${ computedMyDirList.length } 개` }}</p>
                    </div>
                    
                    <!-- 헤더소트 -->
                    <ul class="sortHeader">
                        <!-- <li class="num">No</li> -->
                        <li class="grp headerSort" @click.prevent="sortTypeChange('DIR_NAME')"
                            :class="{
                                sortOff  : sort_type != 'DIR_NAME',
                                sortUp   : sort_type == 'DIR_NAME' && sort_dir == 'ASC',
                                sortDown : sort_type == 'DIR_NAME' && sort_dir == 'DESC'
                            }">
                            <span>이름</span>
                        </li>

                        <li class="fdType sortOff" @click.prevent="isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') ? sortTypeChange('USER_NAME') : () => {}" v-if="!isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '')"
                            :class="{ 
                                headerSort : isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : ''),
                                sortOff  : sort_type != 'USER_NAME',
                                sortUp   : sort_type == 'USER_NAME' && sort_dir == 'ASC',
                                sortDown : sort_type == 'USER_NAME' && sort_dir == 'DESC' 
                            }">
                            <span>종류</span>
                        </li>

                        <!-- <li class="fdType sortOff" @click.prevent="isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') ? sortTypeChange('USER_NAME') : () => {}" 
                            :class="{ 
                                headerSort : isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : ''),
                                sortOff  : sort_type != 'USER_NAME',
                                sortUp   : sort_type == 'USER_NAME' && sort_dir == 'ASC',
                                sortDown : sort_type == 'USER_NAME' && sort_dir == 'DESC' 
                            }">
                            <span>{{ !isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') ? '종류' : '용량 / 파일수' }}</span>
                        </li> -->

                        <!-- <li class="fd sortOff"><span>폴더</span></li> -->
                        <!-- <li class="fileHead headerSort" @click.prevent="sortTypeChange('FILE_COUNT')"
                            :class="{
                                sortOff  : sort_type != 'FILE_COUNT',
                                sortUp   : sort_type == 'FILE_COUNT' && sort_dir == 'ASC',
                                sortDown : sort_type == 'FILE_COUNT' && sort_dir == 'DESC'
                            }">
                            <span>파일수</span>
                        </li> -->

                        <li class="fileHead writtenBy headerSort" @click.prevent="isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') ? sortTypeChange('USER_NAME') : () => {}" 
                            :class="{ 
                                headerSort : isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : ''),
                                sortOff  : sort_type != 'USER_NAME',
                                sortUp   : sort_type == 'USER_NAME' && sort_dir == 'ASC',
                                sortDown : sort_type == 'USER_NAME' && sort_dir == 'DESC' 
                            }">
                            <span>{{ !isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') ? '파일수' : '등록자' }}</span>
                        </li>

                        <li class="lastUpdated headerSort" @click.prevent="sortTypeChange('UPDATE')"
                            :class="{
                                sortOff  : sort_type != 'UPDATE',
                                sortUp   : sort_type == 'UPDATE' && sort_dir == 'ASC',
                                sortDown : sort_type == 'UPDATE' && sort_dir == 'DESC'
                            }">
                            <span>업데이트</span>
                        </li>
                    </ul>

                    <!-- 파일함 존재하지 않을 때 -->
                    <div class="noResult" :class="{ 
                        on : computedMyDirList.length + computedHoduCList.length + computedHoduDList.length < 1 
                    }" v-if="is_card_view == false">
                        <p>파일함이 존재하지 않습니다</p>
                    </div>

                    <ul class="folderUl" :class="{ group : isPersonalScope(scope) == false }" v-if="!isPersonalScope(scope)">

                        <li class="addFolderList"><a @click="showGroupAddFileBox" class="folder add_folder">파일함</a></li>

                        <li :class="{ pswFolder : dir.has_password == true, on : dir.open_menu == true }" :key="dir.dir_uuid" v-for="(dir, index) in computedMyDirList">
                            
                            <a class="fileMenu" @click.prevent="menu_open(dir)" v-if="dir.audit_user_id == user_id">메뉴보기</a>

                            <p class="num">{{ index + 1 }}</p>
                            <p class="grp">
                                <span class="ic-password">비밀번호 여부</span>
                                {{ dir.dir_name }}
                            </p>
                            <!-- <p class="fdType">
                                <span class="size">21.12 MB</span> / <span class="total">6개</span>
                            </p> -->
                            <p class="fd folder" :class="`folder_${hodu_color_dc_lc(dir.dir_color)}`">
                                <span class="blind">폴더개수</span><span class="total">{{ dir.dirs.length }}</span>
                            </p>
                            <p class="fileHead writtenBy">
                                <!-- <span class="title2">파일</span><span class="total">{{ ` ${dir.files.length}개`}}</span> -->
                                <span>{{ dir.user_name }}</span>
                            </p>
                            <p class="lastUpdated">
                                {{ `${ hodu_date_to_format_string(new Date(dir.audit_modified), 'YY.MM.DD') } ${ getDayOfWeekByDate(new Date(dir.audit_modified)) }` }}
                            </p>
                            <a class="goDets" title="페이지로 가기" @click.prevent="moveFileDir(dir)">페이지로가기</a>

                            <ul class="fileMenuOp" v-if="dir.audit_user_id == user_id">
                                <li><button type="button" class="fileOpList" @click.prevent="modifyDir(dir)">폴더수정</button></li>
                                <li><button type="button" class="fileOpList pswDel" @click.prevent="delete_password(dir)" v-if="dir.has_password == true">비밀번호 삭제</button></li>
                            </ul>

                        </li>
                    </ul>

                    <!-- 프리미엄 -->
                    <div class="premium_group_file" v-show="file_box_tab != 'HODUDOC'">
                        <div class="h4Div" v-if="isPersonalScope(scope) == true">
                            <h4>프리미엄</h4>
                            <p class="totalNum">{{ `${computedHoduCList.length} 개` }}</p>
                            <!-- <p class="totalNum">4 개</p> -->
                        </div>

                        <!-- 프리미엄 파일함 존재하지 않을 때 -->
                        <div class="noResult" :class="{ on : computedHoduCList.length < 1 }" v-if="is_card_view == true && isPersonalScope(scope) == true">
                            <p>프리미엄 파일함이 존재하지 않습니다</p>
                        </div>

                        <ul class="folderUl" v-if="isPersonalScope(scope) == true">
                            
                            <li :id="`${group.biz_id}_li`" :key="group.biz_id" v-for="(group, index) in computedHoduCList" :class="{ on : open_biz_id == group.biz_id }">
                                <p class="num">{{ computedMyDirList.length + index + 1 }}</p>

                                <!-- 폴더 컬러에 따라 folder_dc0 ~ 9로 클래스추가 -->
                                <p class="grp"><span class="clr" :class="hodu_color_dc_lc(group.group_color)"></span>{{ group.group_name }}</p>

                                <p class="fdType">프리미엄</p>
                                <p class="fd folder" :class="`folder_${hodu_color_dc_lc(group.group_color)}`">
                                    <span class="blind">폴더개수</span><span class="total">{{ group.dir_list.length }}</span>
                                </p>
                                <p class="fileHead"><span class="title2">파일</span><span class="total">{{ `${getFileCount(group)}개` }}</span></p>
                                <p class="lastUpdated">{{ getLastUpdate(group) }}</p>

                                <a class="seeSubFolder" title="폴더 보기" @click="openGroupFilxBox(group.biz_id)">폴더</a>
                                <a class="goDets" title="페이지로 가기" @click.prevent="moveFileDir(group.dir_list[0], group)">페이지로가기</a>

                                <!-- 폴더 -->
                                <div class="subFolderDiv">

                                    <div class="blackBg"></div>
                                    <div class="subFoldertitleDiv">
                                        <h5>폴더</h5>
                                        <input type="button" class="closeSub" value="취소" @click="closeFileBox"/>
                                    </div>

                                    <!-- 스크롤 -->
                                    <div :id="`${group.biz_id}_scroll`" class="subFolderScroll">
                                        <ul class="subFolderUl">
                                            <li :key="dir.dir_uuid" v-for="(dir, dir_index) in group.dir_list">
                                                <p class="num">{{ `${computedMyDirList.length + index + 1}-${dir_index + 1}`}}</p>
                                                <!-- 비밀번호가 있는 파일일 경우 p태그에 pswFolder 클래스 추가-->
                                                <p class="grp" :class="`${ dir.has_password == true ? ' pswFolder' : '' }`">
                                                    <span class="ic-password">비밀번호 여부</span>
                                                    {{ dir.dir_name }}
                                                </p>
                                                <p class="fdType">프리미엄</p>
                                                
                                                <p class="fd folder" :class="`folder_${hodu_color_dc_lc(dir.dir_color)}`">
                                                    <span class="blind">폴더개수</span><span class="total">{{ dir.dirs.length }}</span>
                                                </p>
                                                <p class="fileHead"><span class="title2">파일</span><span class="total">{{ `${dir.path_files.length}개` }}</span></p>
                                                <p class="lastUpdated">{{ `${ hodu_date_to_format_string(new Date(dir.audit_modified), 'YY.MM.DD') } ${ getDayOfWeekByDate(new Date(dir.audit_modified)) }` }}</p>
                                                
                                                <!-- 비밀번호가 있는 파일일 경우 a태그에 pswModalBtn 클래스 추가-->
                                                <a class="goDets" :class="{ pswModalBtn : dir.has_password == true }" title="페이지로 가기" @click.prevent="moveFileDir(dir, group)">페이지로가기</a>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </li>

                        </ul>
                    </div>

                    <!-- 호두닥 -->
                    <div class="hodudoc_file" v-show="file_box_tab !== 'PREMIUM'">
                        <div class="h4Div" v-if="isPersonalScope(scope) == true">
                            <h4>호두닥</h4>
                            <p class="totalNum">{{ `${computedHoduDList.length} 개`}}</p>
                            <!-- <p class="totalNum">3 개</p> -->
                        </div>

                        <!-- 호두닥 파일함 존재하지 않을때 -->
                        <div class="noResult" :class="{ on : computedHoduDList.length < 1 }" v-if="is_card_view == true && isPersonalScope(scope) == true">
                            <p>호두닥 파일함이 존재하지 않습니다</p>
                        </div>

                        <ul class="folderUl" v-if="isPersonalScope(scope) == true">

                            <li :key="group.biz_id" v-for="(group, index) in computedHoduDList">
                                <p class="num">{{ computedMyDirList.length + computedHoduCList.length + index + 1 }}</p>

                                <!-- 폴더 컬러에 따라 folder_dc0 ~ 9로 클래스추가 -->
                                <p class="grp"><span class="clr" :class="hodu_color_dc_lc(group.group_color)"></span>{{ group.group_name }}</p>

                                <p class="fdType">호두닥</p>
                                <p class="fd folder" :class="`folder_${hodu_color_dc_lc(group.group_color)}`">
                                    <!-- <span class="blind">폴더개수</span><span class="total">{{ group.dir_list.length }}</span> -->
                                </p>
                                <p class="fileHead"><span class="title2">파일</span><span class="total">{{ `${group.file_list.length}개` }}</span></p>
                                <p class="lastUpdated">{{ getLastUpdate(group) }}</p>

                                <!-- <a class="seeSubFolder" title="폴더 보기" @click="openGroupFilxBox(group.biz_id)">폴더</a> -->
                                <a class="goDets" title="페이지로 가기" @click.prevent="moveFileDir(group, group)">페이지로가기</a>
                            </li>
                        </ul>
                    </div>

                </div>
                <!-- //수정전 클래스 mainDisplayNone (숨김) -->

            </div>

            <!-- 오른쪽 디브 -->
            <div class="mainRightDiv">
                <!-- 모든거 잡는 디브 -->
                <div class="forRel">
                    <!-- 오른쪽 디브 모든 내용 -->
                    <div class="mainRightContent">
                        
                        <!-- 파일, 폴더 검색 -->
                        <div class="schDiv">
                            <input type="text" placeholder="파일명을 검색하세요" :value="file_search_query" @input="file_search_query = $event.target.value.trim()" />
                            <input type="button" class="schBtn">
                        </div>

                        <!-- 결과 없을 시 -->
                        <div class="noResult" :class="{ on : computedSearchFileList.length < 1 }">
                            <p>
                                {{ file_search_query.trim().length == 0 ? "검색할 파일명을 입력해주세요." : "검색된 결과가 없습니다." }}
                            </p>
                        </div>

                        <!-- 스크롤넣기 -->
                        <div id="file_search_scroll" class="scroll">
                            
                            <!-- 검색 결과 -->
                            <ul class="schFileUl">

                                <li :key="dir.dir_uuid" v-for="dir in computedSearchFileList" :class="{ on : dir.is_open != null && !dir.is_open, pswFolder : dir.has_password == true }">

                                    <div class="schFolderDiv">
                                        <p class="folder" :class="`folder_${hodu_color_dc_lc(dir.dir_color)}`">
                                            <span class="blind" v-if="dir.group_type != 'BIZD'">폴더개수</span>
                                            <span class="total" v-if="dir.group_type != 'BIZD'">{{ dir.dirs.length }}</span>
                                        </p>
                                        <p class="folderName">
                                            <span class="ic-password">비밀번호 여부</span>
                                            {{ `${ dir.group_type == 'BIZC' ? `[${dir.group_name}] ` : '' }${dir.dir_name}` }}
                                        </p>
                                        <a class="closeAndOpen" @click="searchDirOpenOnOff(dir)"></a>
                                        <a class="goThisFolder" title="폴더로가기" @click="moveSearchFileDir(dir)">폴더로가기</a>
                                    </div>

                                    <ul class="resultUl">

                                        <li :key="file.file_uuid" v-for="file in dir.files">
                                            <a @click="moveSearchFileDir(dir)">
                                                <p class="preview" :class="{ format : new RegExp('image').test(file.file_type) == false }" :style="{ backgroundImage : getBackgroundImage(file) }">
                                                    {{ file.file_name.substring(file.file_name.lastIndexOf('.') + 1) }}
                                                </p>
                                                <p class="fileName">{{ file.file_name }}</p>
                                            </a>
                                        </li>

                                    </ul>

                                </li>
<!-- 비밀번호 있는 폴더 pswFolder 태그 추가 -->
                                <!-- <li class="on pswFolder">
                                    <div class="schFolderDiv">
                                        <p class="folder folder_dc7">
                                            <span class="ic-password">비밀번호 여부</span>
                                        </p>
                                        <p class="folderName">비밀번호표시</p>
                                        <a class="closeAndOpen"></a>
                                        <a class="goThisFolder" title="폴더로가기">폴더로가기</a>
                                    </div>
                                    <ul class="resultUl">
                                        <li>
                                            <a>
                                                <p class="preview format">
                                                    파일파일
                                                </p>
                                                <p class="fileName">파일파일</p>
                                            </a>
                                        </li>
                                    </ul>
                                 </li> -->

                                <!-- <li>
                                    <div class="schFolderDiv">
                                        <p class="folder folder_dc6"></p>
                                        <p class="folderName">사진폴더</p>
                                        <a class="closeAndOpen"></a>
                                        <a class="goThisFolder" title="폴더로가기">폴더로가기</a>
                                    </div>
                                    <ul class="resultUl">
                                        <li>
                                            <a href="">
                                                <p class="preview">사진</p>
                                                <p class="fileName">23423827372.jpg</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <p class="preview format">PPT</p>
                                                <p class="fileName">234827372.ppt</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""> 
                                                <p class="preview format">ZIP</p>
                                                <p class="fileName">s3k882u2827372.zip</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div class="schFolderDiv">
                                        <p class="folder folder_dc0"></p>
                                        <p class="folderName"><span class="clr dc0"></span>자전거 동호회</p>
                                        <a class="closeAndOpen" title=""></a>
                                        <a class="goThisFolder" title="폴더로가기">폴더로가기</a>
                                    </div>
                                    <ul class="resultUl">
                                        <li>
                                            <a href="">
                                                <p class="preview">사진</p>
                                                <p class="fileName">23423827372.jpg</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <p class="preview format">PPT</p>
                                                <p class="fileName">234827372.ppt</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""> 
                                                <p class="preview format">ZIP</p>
                                                <p class="fileName">s3k882u2827372.zip</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD, GROUP_TYPE, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

import { GroupAddFileModalNewInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';
import { hodu_color } from '@/common/color';
import { hodu_cookie } from '@/lib/HoduCookie';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class GroupFileBox extends Mixins(VueHoduCommon) {

    /**
     * 내 파일함 & 그룹 파일함 반환
     */
    get computedMyDirList() : any[] {

        // if( this.scope == OWNER_TYPE.PERSONAL ) {
        //     // 개인 파일함
        //     return [];
        // }

        let target_list : any[] = JSON.parse(JSON.stringify(this.group_dir_list));

        target_list = target_list.sort((item, item2) : number => {

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.dir_name == item2.dir_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.dir_name > item2.dir_name ? 1 : -1;
                }

                return item.dir_name < item2.dir_name ? 1 : -1;
            }

            // 유저 이름 기준 정렬
            if( this.sort_type == 'USER_NAME' ) {
                
                // 유저 이름이 같다면
                if( item.user_name == item2.user_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.user_name > item2.user_name ? 1 : -1;
                }

                return item.user_name < item2.user_name ? 1 : -1;
            }

            // 파일수 기준 정렬
            if( this.sort_type == 'FILE_COUNT' ) {
                
                // 파일수가 같다면
                if( item.files.length == item2.files.length ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.files.length > item2.files.length ? 1 : -1;
                }

                return item.files.length < item2.files.length ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {
                
                // 날짜가 같다면
                if( new Date(item.audit_modified).getTime() == new Date(item2.audit_modified).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item.audit_modified).getTime() > new Date(item2.audit_modified).getTime() ? 1 : -1;
                }

                return new Date(item.audit_modified).getTime() < new Date(item2.audit_modified).getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;

    }

    /**
     * 내 파일함 & 그룹 파일함 반환
     */
    get computedHoduCList() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.hodu_c_list));

        target_list = target_list.sort((item, item2) : number => {

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.group_name == item2.group_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.group_name > item2.group_name ? 1 : -1;
                }

                return item.group_name < item2.group_name ? 1 : -1;
            }

            // 파일수 기준 정렬
            if( this.sort_type == 'FILE_COUNT' ) {
                
                // 파일수가 같다면
                if( this.getFileCount(item) == this.getFileCount(item2) ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return this.getFileCount(item) > this.getFileCount(item2) ? 1 : -1;
                }

                return this.getFileCount(item) < this.getFileCount(item2) ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {

                let item_1_date : Date | null = null;
                let item_2_date : Date | null = null;

                for( const dir of item.dir_list ) {
                    if( item_1_date == null ) { 
                        item_1_date = new Date(dir.audit_modified); 
                        continue;
                    }
                    if( item_1_date.getTime() < new Date(dir.audit_modified).getTime() ) { item_1_date = new Date(dir.audit_modified); }
                }

                for( const dir of item2.dir_list ) {
                    if( item_2_date == null ) { 
                        item_2_date = new Date(dir.audit_modified); 
                        continue;
                    }
                    if( item_2_date.getTime() < new Date(dir.audit_modified).getTime() ) { item_2_date = new Date(dir.audit_modified); }
                }

                if( item_1_date == null || item_2_date == null ) { return 0; }
                
                // 날짜가 같다면
                if( item_1_date.getTime() == item_2_date.getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item_1_date.getTime() > item_2_date.getTime() ? 1 : -1;
                }

                return item_1_date.getTime() < item_2_date.getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    /**
     * 내 파일함 & 그룹 파일함 반환
     */
    get computedHoduDList() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.hodu_d_list));

        target_list = target_list.sort((item, item2) : number => {

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.group_name == item2.group_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.group_name > item2.group_name ? 1 : -1;
                }

                return item.group_name < item2.group_name ? 1 : -1;
            }

            // 파일수 기준 정렬
            if( this.sort_type == 'FILE_COUNT' ) {
                
                // 파일수가 같다면
                if( item.file_list.length == item2.file_list.length ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.file_list.length > item2.file_list.length ? 1 : -1;
                }

                return item.file_list.length < item2.file_list.length ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {

                let item_1_date : Date | null = null;
                let item_2_date : Date | null = null;

                for( const file of item.file_list ) {
                    if( item_1_date == null ) { 
                        item_1_date = new Date(file.audit_created); 
                        continue;
                    }
                    if( item_1_date.getTime() < new Date(file.audit_created).getTime() ) { item_1_date = new Date(file.audit_created); }
                }

                for( const file of item2.file_list ) {
                    if( item_2_date == null ) { 
                        item_2_date = new Date(file.audit_created); 
                        continue;
                    }
                    if( item_2_date.getTime() < new Date(file.audit_created).getTime() ) { item_2_date = new Date(file.audit_created); }
                }

                if( item_1_date == null || item_2_date == null ) { return 0; }
                
                // 날짜가 같다면
                if( item_1_date.getTime() == item_2_date.getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item_1_date.getTime() > item_2_date.getTime() ? 1 : -1;
                }

                return item_1_date.getTime() < item_2_date.getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    /**
     * 검색 된 파일을 가진 디렉토리(파일리스트를 가짐)들을 반환
     */
    get computedSearchFileList() : any[] {

        if( this.file_search_query == null || this.file_search_query.length < 1 ) {
            return [];
        }

        let list : any[] = JSON.parse(JSON.stringify(this.search_files));

        for( const obj of list ) {
            obj.is_open = this.close_uuids.indexOf(obj.dir_uuid) < 0;
        }

        return list;
    }

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowGroupAddFileBox      ?: any;   
    @ModalInfo.Action doSetGroupAddFileModalInfo    ?: any;
    @ModalInfo.Action doSetGroupAddFileModalNewInfo ?: (params : GroupAddFileModalNewInfo) => void;  

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    group_dir_list : any[] = [];
    hodu_c_list : any[] = [];
    hodu_d_list : any[] = [];

    open_biz_id : string = "";
    
    file_search_query : string = "";

    is_right_div_open : boolean = true;
    is_card_view : boolean = true;

    close_uuids : string[] = [];

    file_box_tab : 'ALL' | 'PREMIUM' | 'HODUDOC' = 'ALL';
    
    // 정렬
    sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE' = 'DIR_NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    search_files : any[] = [];

    mounted() : void {

        const group_file_box_is_card_view = hodu_cookie.get('group_file_box_is_card_view');
        if( group_file_box_is_card_view != null ) {
            this.is_card_view = group_file_box_is_card_view === 'true';
        }

        const group_file_box_is_right_div_open = hodu_cookie.get('group_file_box_is_right_div_open');
        if( group_file_box_is_right_div_open != null ) {
            this.is_right_div_open = group_file_box_is_right_div_open === 'true';
        }

        // //파일 옵션 메뉴 toggle
        // $('.fileMenu').click(function(){ 
        //     $(this).siblings('ul').toggle();         
        // })

        // // 폴더 메뉴 toggle
        // $('.folder_menuFile > .folderDiv > button').click(function(){
        //     $(this).siblings('.folder_menuFile > .folderDiv > ul').toggle();            
        // })

        // // 서브폴더 열기
        // $(".subFolder_more").click(function(){
        //     $(this).parent().toggleClass("on");
        // });

        // // 왼쪽 메뉴 click
        // $(".leftMainMenu > li").click(function(){
        // 	$(this).addClass('active').siblings().removeClass();
        // })

        // // 왼쪽 메뉴 dropdown
        // $(".leftMainMenu > li .mainMore").click(function(){
        // 	$(".leftMainMenu > li.active .leftSubMenu").toggleClass('open');
        // })

        // 오른쪽 찾기버튼 클릭시 검색창 열기
        // $(".closeRight").click(function(){
        //     $(".section_ce_fix").toggleClass("rightDivClosed");
        //     $(this).toggleClass("on");

        // });

        // 리스트로 보이기
        // $(".btns .dpTwo").click(function(){
        //     $(".section_ce_fix").addClass("dp2");
        //     $(".section_ce_fix").removeClass("dp1");
        //     $(".btns .dpOne").show();
        //     $(this).hide();

        // });

        // 박스형으로 보이기
        // $(".btns .dpOne").click(function(){
        //     $(".section_ce_fix").removeClass("dp2");
        //     $(".section_ce_fix").addClass("dp1");
        //     $(".btns .dpTwo").show();
        //     $(this).hide();

        // });

        // 서브폴더 열기
        // $(".seeSubFolder").click(function(){
        //     $(this).parent().addClass("on");
        // });

        // 서브폴더 닫기
        // $(".closeSub").click(function(){
        //     $(this).parent().parent().parent().removeClass("on");

        // });

        // 오른쪽 검색 폴더 닫고 열기
        // $(".closeAndOpen").click(function(){
        //     $(this).parent().parent().toggleClass("on");

        // });

        // 개인 달력
        if( this.scope == OWNER_TYPE.PERSONAL ) { this.getFileBoxDirAndFile(); }

        // 그룹 달력
        else { this.getGroupFiles(); }


        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {
            const title_box_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const search_div_height : number | undefined = $('.mainRightContent .schDiv').outerHeight();
            const route_bar_height : number | undefined = $('#route_bar').outerHeight();

            // @ts-ignore
            $('.mainLeftDivScroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (route_bar_height ? route_bar_height : 0),
            });

            // @ts-ignore
            $('#file_search_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (search_div_height ? search_div_height : 0)
                                            - (route_bar_height ? route_bar_height : 0),
            });
        })

        
    }

    /**
     * 그룹 파일함 디렉토리 조회
     */
    async getGroupFiles() : Promise<void> {
        
        await this.hodu_api_call(`api/v1/groupsweb/getGroupFiles/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.GET)
            .then(async(response) => {
                this.group_dir_list.splice(0, this.group_dir_list.length)
                this.group_dir_list = this.group_dir_list.concat(response.data.data.files);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 유저와 관련된 파일함 전체 조회
     */
    async getFileBoxDirAndFile() : Promise<void> {

        await this.hodu_api_call(`api/v1/user/me/file`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.hodu_c_list.splice(0, this.hodu_c_list.length);
                this.hodu_c_list = this.hodu_c_list.concat(response.data.data.hodu_c_list);

                this.hodu_d_list.splice(0, this.hodu_d_list.length);
                this.hodu_d_list = this.hodu_d_list.concat(response.data.data.hodu_d_list);

            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });

    }

    /**
     * 파일박스 탭 변경
     */
    changeFileBoxTab(value : 'ALL' | 'PREMIUM' | 'HODUDOC') : void {
        this.file_box_tab = value;
        this.is_card_view = !this.is_card_view;
        this.$nextTick(() => { this.is_card_view = !this.is_card_view; });
    }

    /**
     * 카드 뷰 모드 ON / OFF
     */
    cardViewModeOnOff() : void {
        this.is_card_view = !this.is_card_view;

        hodu_cookie.set('group_file_box_is_card_view', String(this.is_card_view));
        
        if( this.scope == OWNER_TYPE.PERSONAL && this.open_biz_id != null && this.open_biz_id.length > 0 ) {
            
            const scroll_container = $(`#${this.open_biz_id}_scroll`);

            if( this.is_card_view == true ) {

                this.$nextTick(() => {
                
                    const li_height : number | undefined = $(`#${this.open_biz_id}_li`).innerHeight();
                    const li_title_height : number | undefined = $(`#${this.open_biz_id}_li .subFoldertitleDiv`).innerHeight();

                    // @ts-ignore
                    scroll_container.mCustomScrollbar({
                        axis : 'y',
                        scrollbarPosition : 'outside',
                        mouseWheelPixels : 100,
                        scrollInertia : 60,
                        autoDraggerLength : false,
                        setHeight : (li_height ? li_height : 0) - (li_title_height ? li_title_height : 0)
                    });

                });

            }

            else {
                // @ts-ignore
                scroll_container.mCustomScrollbar('destroy');
            }
            
        }

        this.$nextTick(() => this.handleResize());
    }

    /**
     * 오른쪽 div 닫기 / 열기
     */
    rightDivOnOff() : void {
        this.is_right_div_open = !this.is_right_div_open; 
        hodu_cookie.set('group_file_box_is_right_div_open', String(this.is_right_div_open));
    }

    /**
     * 검색쪽 디렉토리 닫기 / 열기
     */
    searchDirOpenOnOff(dir_obj : any) : void {

        if( this.close_uuids.indexOf(dir_obj.dir_uuid) < 0 ) {
            this.close_uuids.push(dir_obj.dir_uuid);
        }

        else {
            this.close_uuids.splice(this.close_uuids.indexOf(dir_obj.dir_uuid), 1);
        }

    }

    /**
     * 배경화면 반환
     */
    getBackgroundImage(file : any) : string {
        if( new RegExp(escape('image')).test(escape(file.file_type)) == false ) {
            return 'none';
        }

        return file.file_path.indexOf('patient_file') > -1 ? `url(app_images/${file.file_path})` : `url(app_images/group_file/${file.file_path})`;
    }

    /**
     * 호두 C - 그룹 파일 개수 반환
     */
    getFileCount(group : any) : number {
        let file_count : number = 0;
        for( const dir of group.dir_list ) { file_count += dir.path_files.length; }
        return file_count;
    }

    /**
     * 호두 C & D - 마지막 업데이트 된 날짜
     */
    getLastUpdate(group : any) : string {

        let target_date : Date | null = null;
        for( const dir of (group.dir_list ? group.dir_list : group.file_list) ) {
            
            // 첫 데이터
            if( target_date == null ) {
                target_date = new Date(dir.audit_modified);
                continue;
            }

            // 비교
            if( target_date.getTime() < new Date(dir.audit_modified).getTime() ) {
                target_date = new Date(dir.audit_modified);
            }
        }

        // 리스트가 없다면
        if( target_date == null ) { return ''; }

        return `${this.hodu_date_to_format_string(target_date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(target_date)}`;
    }

    /**
     * 그룹 파일함 폴더 열기
     */
    openGroupFilxBox(biz_id : string) : void {
        this.open_biz_id = biz_id;

        if( this.is_card_view == false ) { return; } 

        this.$nextTick(() => {
            
            const li_height : number | undefined = $(`#${biz_id}_li`).innerHeight();
            const li_title_height : number | undefined = $(`#${biz_id}_li .subFoldertitleDiv`).innerHeight();

            // @ts-ignore
            $(`#${biz_id}_scroll`).mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : (li_height ? li_height : 0) - (li_title_height ? li_title_height : 0)
            });

        });
    }

    /**
     * 그룹 파일함 폴더 닫기
     */
    closeFileBox() : void {

        if( this.is_card_view == true ) { 
            // @ts-ignore
            $(`#${this.open_biz_id}_scroll`).mCustomScrollbar('destroy');
        }

        this.open_biz_id = "";
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }

        // console.log(`[${this.sort_type}, ${this.sort_dir}]`);
    }

    /**
     * 파일함 추가 모달
     */
    showGroupAddFileBox() : void {

        this.doSetGroupAddFileModalNewInfo?.({
            show_modal : true,
            is_create: true,
            dir_uuid: "",
            group_id: this.scope_group_id,
            // dir_name: "",
            // dir_color: hodu_color.hodu_dc_2,
            // dir_icon: 0,
            create_function: this.scope == OWNER_TYPE.PERSONAL ? () => {} : this.getGroupFiles
        });
    }

    /**
     * 디렉토리 수정
     */
    modifyDir(dir) : void {

        this.doSetGroupAddFileModalNewInfo?.({
            show_modal : true,
            is_create: false,
            dir_uuid: dir.dir_uuid,
            group_id: dir.group_id,
            // dir_name: dir.dir_name,
            // dir_color: dir.dir_color,
            // dir_icon: dir.dir_icon,
            update_function: (is_delete : boolean, dir_uuid : string) => {
                this.getGroupFiles();
            }
        });

    }

    /**
     * 파일함 디렉토리로 이동
     */
    async moveFileDir(dir : any, group ?: any) : Promise<void> {
        
        let uuid : string = "";

        if( group != null && this.computedHoduDList.indexOf(group) > -1 ) {
            uuid = group.biz_id;
        }
        else {
            uuid = dir.dir_uuid;
        }

        if( dir.has_password == true ) {
            this.password_input(dir, group);
            return;
        }
        
        // 개인 달력인 경우 그룹 달력으로 이동 시킨 후 이동한다
        if( this.scope == OWNER_TYPE.PERSONAL && group.group_type == GROUP_TYPE.BIZC ) {

            // 달력으로 먼저 이동
            this.hodu_router_push(`/?${new Date().getTime()}`, async() => {

                if( $('#bt_calContr').hasClass('on') == false ) {
                    $('.ui-datepicker-calendar-wrap').hide();
                    $('#bt_calContr').addClass('on');
                }

                this.doSetGroupMenuClose(true);
                this.doSetMessageMenuClose(true);
                this.doSetWorkMenuClose(true);
                this.doSetCommunityMenuClose(true);
                this.doSetResidentMenuClose(true);

                this.doGroupId(group.group_id);
                this.doTeamId(0);

                this.doSetCalendarId(`group-${this.group_id}`);
                this.doSetScope(OWNER_TYPE.GROUP);
                this.doSetScopeGroupId(this.group_id);
                this.doSetScopeTeamId(0);
                this.doSetScopeGroupTeamOption({
                    group_team_name     : group.group_name,
                    group_team_color    : group.group_color,
                    group_team_image    : "",
                    group_team_descript : "",
                    biz_id              : group.biz_id,
                    biz_type            : GROUP_TYPE.BIZC,
                });

                await this.hodu_loading_timer_exit();
                await this.hodu_loading();

                setTimeout(() => {
                    // @ts-ignore
                    $('.left_inbox02').mCustomScrollbar('destroy');
                    window['left_inbox02_mCustomScrollbar']();
                }, 100);

                if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }

                this.hodu_router_push(`/group/${new Date().getTime()}/file/${uuid}`);

            });

            return;
        }

        // 호두 D 
        if( this.scope == OWNER_TYPE.PERSONAL && group.group_type == GROUP_TYPE.BIZD ) {
            this.hodu_router_push(`/hospital/file/${group.biz_id}`);
            return;
        }

        this.hodu_router_push(`/group/${new Date().getTime()}/file/${uuid}`);
    }

    /**
     * 검색된 파일 또는 디렉토리를 누르면 이동
     */
    moveSearchFileDir(dir_obj : any) : void {

        if( dir_obj.files == null || dir_obj.files.length < 1 ) { return; }

        if( dir_obj.has_password == true ) {
            this.password_input(dir_obj);
            return;
        }

        // 개인 달력인 경우 && 호두 C 디렉토리인 경우 그룹 달력으로 이동 시킨 후 이동한다
        if( this.scope == OWNER_TYPE.PERSONAL && dir_obj.group_type == GROUP_TYPE.BIZC ) {

            // 달력으로 먼저 이동
            this.hodu_router_push(`/?${new Date().getTime()}`, async() => {

                if( $('#bt_calContr').hasClass('on') == false ) {
                    $('#container').find('#left_area').find('.left_inbox').addClass('on');
                    $('.ui-datepicker-calendar-wrap').hide();
                    $('#bt_calContr').addClass('on');

                    const left_area_height : number | undefined = $('#left_area').outerHeight();
                    const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

                    const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
                    const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

                    let heightSize : number = left_area_height_final - left_inbox_height_final;
                    $('.left_inbox02').height(heightSize+"px");
                }

                this.doSetGroupMenuClose(true);
                this.doSetMessageMenuClose(true);
                this.doSetWorkMenuClose(true);
                this.doSetCommunityMenuClose(true);
                this.doSetResidentMenuClose(true);

                this.doGroupId(dir_obj.files[0].group_id);
                this.doTeamId(0);

                this.doSetCalendarId(`group-${this.group_id}`);
                this.doSetScope(OWNER_TYPE.GROUP);
                this.doSetScopeGroupId(this.group_id);
                this.doSetScopeTeamId(0);
                this.doSetScopeGroupTeamOption({
                    group_team_name     : dir_obj.group_name,
                    group_team_color    : dir_obj.group_color,
                    group_team_image    : "",
                    group_team_descript : "",
                    biz_id              : dir_obj.files[0].biz_id,
                    biz_type            : GROUP_TYPE.BIZC,
                });

                await this.hodu_loading_timer_exit();
                await this.hodu_loading();

                setTimeout(() => {
                    // @ts-ignore
                    $('.left_inbox02').mCustomScrollbar('destroy');
                    window['left_inbox02_mCustomScrollbar']();
                }, 100);

                if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }

                this.hodu_router_push(`/group/${new Date().getTime()}/file/${dir_obj.dir_uuid}`);

            });

            return;
        }

        // HODU D인 경우
        if( dir_obj.group_type == GROUP_TYPE.BIZD ) {
            this.hodu_router_push(`/hospital/file/${dir_obj.files[0].biz_id}`);
            return;
        }

        this.hodu_router_push(`/group/${new Date().getTime()}/file/${dir_obj.dir_uuid}`);
    }

    /**
     * 디렉토리 비밀번호 입력
     */
    password_input(dir : any, group ?: any) {

        let uuid : string = "";

        if( group != null && this.computedHoduDList.indexOf(group) > -1 ) {
            uuid = group.biz_id;
        }
        else {
            uuid = dir.dir_uuid;
        }

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }
                    
                    // 개인 달력인 경우 그룹 달력으로 이동 시킨 후 이동한다
                    if( this.scope == OWNER_TYPE.PERSONAL && ((group != null && group.group_type == GROUP_TYPE.BIZC) || (group == null && dir.group_type == GROUP_TYPE.BIZC)) ) {

                        // 달력으로 먼저 이동
                        this.hodu_router_push(`/?${new Date().getTime()}`, async() => {

                            if( $('#bt_calContr').hasClass('on') == false ) {
                                $('.ui-datepicker-calendar-wrap').hide();
                                $('#bt_calContr').addClass('on');
                            }

                            this.doSetGroupMenuClose(true);
                            this.doSetMessageMenuClose(true);
                            this.doSetWorkMenuClose(true);
                            this.doSetCommunityMenuClose(true);
                            this.doSetResidentMenuClose(true);

                            this.doGroupId(group != null ? group.group_id : dir.group_id);
                            this.doTeamId(0);

                            this.doSetCalendarId(`group-${this.group_id}`);
                            this.doSetScope(OWNER_TYPE.GROUP);
                            this.doSetScopeGroupId(this.group_id);
                            this.doSetScopeTeamId(0);
                            this.doSetScopeGroupTeamOption({
                                group_team_name     : group != null ? group.group_name : dir.group_name,
                                group_team_color    : group != null ? group.group_color : dir.group_color,
                                group_team_image    : "",
                                group_team_descript : "",
                                biz_id              : group != null ? group.biz_id : dir.biz_id,
                                biz_type            : GROUP_TYPE.BIZC,
                            });

                            await this.hodu_loading_timer_exit();
                            await this.hodu_loading();

                            setTimeout(() => {
                                // @ts-ignore
                                $('.left_inbox02').mCustomScrollbar('destroy');
                                window['left_inbox02_mCustomScrollbar']();
                            }, 100);

                            if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }

                            this.hodu_router_push(`/group/${new Date().getTime()}/file/${uuid}`);

                        });

                        return;
                    }

                    // 호두 D 
                    if( this.scope == OWNER_TYPE.PERSONAL && ((group != null && group.group_type == GROUP_TYPE.BIZD) || (group == null && dir.group_type == GROUP_TYPE.BIZD)) ) {
                        this.hodu_router_push(`/hospital/file/${group != null ? group.biz_id : dir.biz_id}`);
                        return;
                    }

                    this.hodu_router_push(`/group/${new Date().getTime()}/file/${uuid}`);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });
    }

    menu_open(target_dir) {
        for( const dir of this.computedMyDirList ) {
            if( dir.dir_uuid == target_dir.dir_uuid ) continue;
            dir.open_menu = false;
        }

        if( target_dir.open_menu == null ) {
            target_dir.open_menu = true;
        }
        else {
            target_dir.open_menu = !target_dir.open_menu;
        }
        this.$forceUpdate();
    }
    
    delete_password(dir) {

        const uuid = dir.dir_uuid;
        
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }

                    const delete_response = await this.hodu_api_call(`api/v1/groups/${dir.group_id}/file/${uuid}/password`, API_METHOD.DELETE);

                    console.log(delete_response);

                    if( !delete_response || !this.isHttpStatusSuccess(delete_response.status) ) {
                        throw new Error("파일함 비밀번호 삭제 중 오류 발생");
                    }
                    
                    this.getGroupFiles();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });

    }

    /**
     * 파일보관함 리스트로 이동
     */
     goGroupFileList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        
        this.doGroupId(this.scope_group_id);
        this.doTeamId(this.scope_team_id);
        this.doGroupTeamOption(JSON.parse(JSON.stringify(this.scope_group_team_option)));
        this.hodu_router_push(`/group/${new Date().getTime()}/file`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        // @ts-ignore
        $('.mainLeftDivScroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#file_search_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

    /**
     * 파일 검색 쿼리 변경 감지
     */
    @Debounce(250)
    @Watch('file_search_query')
    async watchFileSearchQuery() : Promise<void> {
        this.close_uuids.splice(0, this.close_uuids.length);

        if( this.file_search_query.length < 1 ) {
            this.search_files.splice(0, this.search_files.length);
            return;
        }

        try {

            // 전체 검색
            if( this.isPersonalScope(this.scope) ) {

                const response = await this.hodu_api_call(`api/v1/file?search_query=${this.file_search_query}`, API_METHOD.GET, null, false);

                console.log(response);
                
                if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.groups ) {
                    throw new Error("검색 중 오류 발생");
                }
    
                this.search_files.splice(0, this.search_files.length);
                this.search_files = this.search_files.concat(response.data.data.groups);
            }
            
            // 그룹 검색
            else {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/file?search_query=${this.file_search_query}`, API_METHOD.GET, null, false);

                console.log(response);
                
                if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.files ) {
                    throw new Error("검색 중 오류 발생");
                }
            
                this.search_files.splice(0, this.search_files.length);
                this.search_files = this.search_files.concat(response.data.data.files);
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

}   
</script>

<style scoped>
    .section_ce_fix.rightDivClosed .mainRightDiv { width: 0; }
    .section_ce_fix.rightDivClosed .mainRightContent { display:none; } 
    /* .section_ce_fix.rightDivClosed .mainLeftDiv { padding-right:0; } */

    .settingBg h3 .clr { font-size:0; width:10px; height:10px; display:inline-block; float: left;border-radius: 50%; margin:28px 10px 0 0; margin-top: 34px; }

    .settingBg { position: relative; border-bottom:1px solid #e7e9ea; }
    /* .settingBg .btns { float: right; } */
    .settingBg .btns_left .schBtn.closeRight { position: static; background: url(../assets/images/contents/bt_hambuger_new.png) no-repeat center; }
    .settingBg .btns_left .schBtn.closeRight:hover { background: #477fff url(../assets/images/contents/bt_hambugerWhite_new.png) no-repeat center; }
    .settingBg .btns_left .schBtn.closeRight.on { background-image: url(../assets/images/contents/ic_close_fff.gif) }
    /* .settingBg select { cursor:pointer; transition:0.2s; margin: 14px 0 14px 30px; height: 35px; line-height: 31px; border:1px solid #232848; appearance: none; padding: 0 40px 0 20px; border-radius: 20px; font-weight: bold; font-size: 14px;background: url(../assets/images/side_area/bt_open.png) no-repeat 90% center; } */
    .settingBg select:hover { background-color:#f1f3f5; }
    .settingBg select:focus { border-color:#477fff; background-color:#fff;background-image: url(../assets/images/side_area/bt_close.png); }
    #container #section_ce .schedule_box { position: static !important; padding:0 !important; max-width:100%;  }

    .btnAdd { position:absolute; right: 40px; bottom:40px; }
    .btnAdd { opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .btnAdd:hover {background: #477fff url('../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}

    .content { position: relative; overflow: hidden; height: 100%; }
    .mainLeftDiv .h4Div { overflow: hidden; margin: 30px 30px 0 30px; font-size: 16px; margin-bottom: -5px; }
    .mainLeftDiv .h4Div h4 { display: inline-block; }
    .mainLeftDiv .h4Div p { float:right; font-size: 13px; font-weight: bold;; }
    /* .mainLeftDiv { float: left; width: 100%; box-sizing: border-box; padding-right:400px; } */

    .mainLeftDiv .noResult { display:none; position: static; padding: 20px 30px; }
    .mainLeftDiv .noResult.on { display:block; }
    .mainLeftDiv .noResult p { background: #f1f3f5; height: 80px; line-height: 80px; font-size : 14px; }

    .folderUl { overflow: hidden; box-sizing: border-box; padding-right:30px; border-bottom: 5px solid #f1f3f5;padding-bottom: 40px; }
    .folderUl.group { border-bottom: none; }
    .folderUl li { text-align: center; position:relative; float: left; display:inline-block; width: 180px; height: 200px; margin: 30px 0 0 30px; box-sizing: border-box; border: 1px solid #e7e9ea; border-radius: 5px; }
    .folderUl li a { position: absolute; display: inline-block;height:100%; left:0;top:0; width: 100%; text-align:center; }
    .folderUl > li:hover  { background:#f7f9fb; }
    .goDets { font-size:0; }
    .folderUl li .grp { font-size: 14px; font-weight: bold; height: 40px; line-height: 60px; padding:0 25px;box-sizing: border-box; overflow:hidden;text-overflow: ellipsis; white-space: nowrap; }
    .folderUl li .grp .clr { display: inline-block; width: 8px; height: 8px; margin-right:10px; border-radius: 50%; ; }
    .fileHead { font-size: 12px; height: 25px; font-weight: bold; }
    /* .fileHead .title2 { font-weight: bold; padding:0 8px 0 0; border-right: 1px solid #dee1e4; border-radius: 2px; margin-right: 8px;  } */
    .fd .total {color:#fff;}
    .folderUl li a.seeSubFolder { transition:0.2s; opacity:0; z-index: 10000000;border-radius: 20px; bottom:20px; top:auto; left:50%; width:85px; border:1px solid #2265ff; background:#477fff; height:27px; line-height: 27px; color:#fff; font-weight: bold; margin-left: -42.5px; }
    .folderUl li:hover > a.seeSubFolder { opacity:1;bottom:-18px; }
    .folderUl li:hover > a.seeSubFolder:hover {background-color:#2265ff}

    /* subFolder */
    .folderUl > li.on .subFolderDiv { display:block; }
    .subFolderDiv, .folderUl > li.on .seeSubFolder { display:none; }
    .dp2.section_ce_fix .subFolderDiv { position: absolute; left: 0; top:0; height:100%; overflow:hidden; border:1px solid #f1f3f5; box-sizing: border-box; width: 100%;}
    .dp2.section_ce_fix .subFolderDiv li { margin:0; }
    .dp2.section_ce_fix .subFolderUl { position:relative }
    .dp2.section_ce_fix .subFolderUl li { position:relative; border: none; border-bottom: 1px solid #f1f3f5; border-radius: 0; height: auto;}
    .dp2.section_ce_fix .subFolderUl .folder { position:absolute; left:15px;top:6px; width: 30px;height: 30px;  background-size: 23px 17px;background-position: left center; }
    .dp2.section_ce_fix .subFolderUl .lastUpdated { display:none; }
    .dp2.section_ce_fix .subFolderUl li .grp { padding-left: 50px; height: 45px; line-height: 45px; text-align: left; font-size: 13px; position: relative; }
    .dp2.section_ce_fix .subFolderUl .fileHead { text-align: left; padding-left:11px}
    .dp2.section_ce_fix .subFolderUl .fileHead .title2 { margin-right: 9px }
    .dp2.section_ce_fix .subFoldertitleDiv { box-sizing: border-box; padding:0 15px;width:100%; text-align:left; position: relative; z-index: 100; overflow: hidden; height: 50px; line-height: 50px;border-bottom:1px solid #e7e9ea; }
    .dp2.section_ce_fix .subFoldertitleDiv h5 { display:inline-block; font-size: 14px; text-align:left; }
    .dp2.section_ce_fix .subFoldertitleDiv input { float: right; margin-top:6px; background-image: url(../assets/images/contents/ic_close_fff.gif) ; background-color:#ff6060; background-repeat: no-repeat; background-position: center center; font-size:0; border-radius: 50%; width: 25px; height:25px;  }
    .dp2.section_ce_fix .blackBg { display: block; width:100%; height: 100%; position: absolute; background:#fff; opacity:0.99; }
    .dp2.section_ce_fix .subFolderScroll {overflow-y: auto; height: 164px;  }


    /* .mainRightDiv {transition:0.1s; position:absolute; width: 400px;right:0; top:0; box-sizing: border-box; border-left: 1px solid #e7e9ea; height:100%; } */
    .mainRightDiv .schDiv { position: relative; padding: 15px; box-sizing: border-box; width:100%; border-bottom: 1px solid #e7e9ea }
    .mainRightDiv .schDiv .schBtn { position: absolute ; right:20px;top:20px; }
    .mainRightDiv .schDiv input[type="text"] { font-weight: bold; border-radius: 5px; transition: 0.2s; width: 100%; height: 50px;border: 1px solid #e7e9ea; padding: 0 15px; box-sizing: border-box; line-height: 50px; background:#f1f3f5;; }
    .mainRightDiv .schDiv input[type="text"]:hover { border-color:#477fff }
    .mainRightDiv .schDiv input[type="text"]:focus { background: #fff; border-color: #477fff; }

    .mainRightContent .noResult { display:none; position: static; padding: 15px; }
    .mainRightContent .noResult.on { display:block; }
    .mainRightContent .noResult p { background: #f1f3f5; height: 70px; line-height: 70px; }


    /* dpOne일때  */
    .dp1.section_ce_fix .sortHeader { display:block; width:100%;  }
    .dp1.section_ce_fix h4 { display:none }
    .dp1.section_ce_fix .sortHeader, .dp1.section_ce_fix .num, .dp1.section_ce_fix .fdType { display:inline-block; }
    .dp1.dp1.section_ce_fix .addFolderList { display:none; }
    .dp1.section_ce_fix .num { width: 13% !important; text-align: center !important; font-weight: bold; display: none; }
    .dp1.section_ce_fix .grp { width: 40% !important;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; margin-left: 4% !important; }
    .dp1.section_ce_fix .fdType { width:21% !important;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; }
    .dp1.section_ce_fix .fd { width: 15% !important;color: #232848 }
    .dp1.section_ce_fix .fileHead { width: 15% !important; height: 60px; line-height: 60px; }
    .dp1.section_ce_fix .lastUpdated { width: 20% !important;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; }   
    .dp1.section_ce_fix .folderUl { padding-right:0; padding-bottom:0; border-bottom:0 none; }
    .dp1.section_ce_fix .fileHead span.title2 { display: none; }
    .dp1.section_ce_fix .folderUl p { position: static !important; float: left; text-align: left; font-size: 12px;  height: 66px !important; line-height: 66px !important; padding-left: 0; font-weight:bold;}
    .dp1.section_ce_fix .folderUl p.grp { padding-left: 60px; font-size: 13px; }
    .dp1.section_ce_fix .folderUl p.fd { position:absolute !important; left: 4%; padding-left:0 !important; padding-left: 0 !important; width: 50px !important; }
    .dp1.section_ce_fix .folderUl li { position:relative; width:100%; margin: 0; border-radius: 0; border: none; height: 66px; line-height: 66px;border-bottom:1px solid #f1f3f5; }
    .dp1.section_ce_fix .folderUl li { transition: 0.2s; border-bottom:1px solid #f1f3f5; }
    .dp1.section_ce_fix .folderUl li:hover { background:#f7f9fb }
    .dp1.section_ce_fix .folderUl li:hover > .goDets { opacity:1; }
    .dp1.section_ce_fix .folderUl.group > li:last-child { min-height: 120px; }
    
    .dp1.section_ce_fix .fd { background-position: left center;background-size: 41px 30px;}
    .dp1.section_ce_fix .fd span.total { display: inline-block; width: 35px; line-height: 69px; text-align: center !important; color:#fff; }
    .dp1.section_ce_fix .h4Div { display:none; }
    
    /* 서브폴더 */
    .dp1.section_ce_fix .blackBg, .dp1.section_ce_fix .subFoldertitleDiv h5 { display:none !important; }
    .dp1.section_ce_fix .folderUl > li > .goDets { height: 66px !important }
    .dp1.section_ce_fix .subFolderDiv li { border-top:1px solid #f1f3f5; border-bottom: 0; background:#fff; line-height: 55px !important; height: 55px !important; }
    .dp1.section_ce_fix .subFolderDiv li p {  line-height: 55px !important; height: 55px !important; }
    .dp1.section_ce_fix .closeSub { position: absolute; z-index:10000;right:80px;top:10px; }
    .dp1.section_ce_fix .subFolderDiv  p.grp { font-size: 12px }
    .dp1.section_ce_fix .folderUl li.on { height:auto; border-bottom:2px solid #f1f3f5; }
    .dp1.section_ce_fix .folderUl li a.seeSubFolder, .dp1.section_ce_fix .closeSub { font-size:0; z-index: 10000; opacity: 1; transition:0.2s;left:auto; width: 60px; height: 65px; border-radius: 50%; right: 0; top:0; background-color:transparent; border:none; border-radius: 0; box-sizing: border-box;background-image: url(../assets/images/side_area/bt_open.png); background-size: 26px; background-repeat: no-repeat; background-position: center center }
    .dp1.section_ce_fix .closeSub { background-image: url(../assets/images/side_area/bt_close.png);}
    .dp1.section_ce_fix .folderUl li a.seeSubFolder:hover, .dp1.section_ce_fix .closeSub:hover  { background-color:#fff;}
    .dp1.section_ce_fix .subFolderDiv .fd {  box-sizing: border-box; background-position: 8px center; background-size: 33px 23px; }
    .dp1.section_ce_fix .subFolderDiv li {  }

    .dp1.section_ce_fix span.total.ic_fd {height: 66px;line-height: 60px;background-size: 38px;background-position: 1px 19px !important;}

    /* dpTwo일때 */
    .sortHeader, .num, .fdType { display:none }


    /* 오른쪽 검색 */
    .schFileUl .schFolderDiv { position: relative; height: 50px; line-height: 50px; border-bottom:1px solid #f1f3f5;}
    .schFileUl .folder { position:absolute; left:0; top:5px; display:inline-block; width: 40px; height:40px; background-size: 30px; background-position: 15px center; }
    .schFileUl .folderName { box-sizing: border-box; padding-left: 55px; width:100%; padding-right:65px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: inline-block; font-weight: bold; font-size: 13px;transition:0.1s; background-image:  url(../assets/images/side_area/bt_close.png); background-position: 360px center; background-repeat: no-repeat; }
    /* .schFileUl > li .folderName, .schFileUl > li.on:hover .folderName { background-position: 320px center;} */
    .mainRightContent .schFileUl > li .folderName { background-position: 260px center;}
    .mainRightContent .schFileUl > li.on:hover .folderName  { background-position: 260px center;}
    /* .schFileUl > li.on .folderName {  background-position: 360px center; } */
    .schFileUl > li.on .folderName{ background-image:  url(../assets/images/side_area/bt_open.png);}
    .schFileUl .folderName .clr { display: inline-block; width: 8px; height:8px; border-radius: 50%;margin-right: 10px; }
    .schFileUl .goThisFolder{ z-index:200; transition:0.2s; opacity:0; position:absolute; right: 15px; top: 11px; background-color:#477fff; border-radius: 50%; width: 27px; height:27px; font-size:0; background-image: url('../assets/images/contents/month_bt_next_fff.svg'); background-size: 22px; background-repeat: no-repeat; background-position: center center;}
    .schFileUl > li .goThisFolder { opacity:1; }
    .schFileUl > li.on .goThisFolder { opacity:0; }
    .schFileUl .goThisFolder:hover { background-color:#2b6cff; }
    .schFileUl .schFolderDiv:hover .goThisFolder { opacity:1; }
    .schFileUl .closeAndOpen { position: absolute; left:0; top:0; width:100%; height:100%; z-index: 10; display:block; }
    .schFileUl > li.on .resultUl { display:none }
    .schFileUl .resultUl { padding: 0 0; }
    .schFileUl .resultUl li { transition: 0.1s; border-bottom:1px solid #f1f3f5; position: relative; height: 45px; line-height: 45px; padding:0 20px;}
    .schFileUl .resultUl li:hover { background: #f7f9fb } 
    .schFileUl .resultUl li:hover .fileName { font-weight:bold; }
    .schFileUl .resultUl .preview { background-color:#fff; position: absolute;left:20px; top:7px; width:40px; height: 30px; display:inline-block; border-radius: 5px; border: 1px solid #e7e9ea; font-size:0; background-size: cover; background-position: center center; background-repeat: no-repeat; }
    .schFileUl .resultUl .preview.format { text-transform: uppercase; font-size: 11px; font-weight: bold; text-align: center; line-height: 32px; color:#477fff; }
    .schFileUl .resultUl .fileName { padding-left: 55px; width:100%; box-sizing: border-box; font-weight: normal; height: 40px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;  }

    .left_area_close .content {padding-left: 61px !important;box-sizing: border-box;}
    .left_area_close .settingBg {padding-left: 61px;}

    /* destroy 되면 잔여 높이가 남아있어서 높이를 제거한다  */
    .subFolderDiv .mCS_destroyed { height : auto !important; }

    /* 파일함 수정 21.07.09 */
    /* action시 */
    .section_ce_fix.rightDivClosed .mainLeftDiv { padding-left: 0; }
    .left_area_close .holiday_setting .mainRightDiv { left: 61px; }
    .left_area_close .holiday_setting .btns_left { left: 80px; }

    .settingBg .selectTitle { padding: 0px 20px 0 80px; vertical-align: middle; line-height: 80px; }

    .schedule_box .btns_left { position: absolute; left: 15px; top: 20px; }
    .schedule_box .schDiv { position: relative; padding: 15px; box-sizing: border-box; width:350px; display: inline-block; margin-left: 60px; }
    .schedule_box .schDiv .schBtn { position: absolute ; right:20px;top:20px; }
    .schedule_box .schDiv input[type="text"] { font-weight: bold; border-radius: 5px; transition: 0.2s; width: 100%; height: 50px;border: 1px solid #e7e9ea; padding: 0 15px; box-sizing: border-box; line-height: 50px; background:#f1f3f5;; }
    .schedule_box .schDiv input[type="text"]:hover { border-color:#477fff }
    .schedule_box .schDiv input[type="text"]:focus { background: #fff; border-color: #477fff; }
    .settingBg select { cursor:pointer; transition:0.2s; margin-top: 20px; height: 35px; line-height: 31px; border:1px solid #232848; appearance: none; padding: 0 40px 0 20px; border-radius: 20px; font-weight: bold; font-size: 14px;background: url(../assets/images/side_area/bt_open.png) no-repeat 90% center; float: right; margin-right: 20px; }
    .settingBg .btns { float: right; margin: 18px 20px 0 0; }

    .mainRightDiv {transition:0.1s; position:absolute; width: 340px; left:0; top:0; box-sizing: border-box; border-right: 1px solid #e7e9ea; height:100%; }
    .mainLeftDiv { float: left; width: 100%; box-sizing: border-box; padding-left:340px; }
    .mainDisplayNone { display: none; }

    /* 좌측 메뉴 */
    .mainRightContent .leftMainMenu li { line-height: 60px; border-bottom: 1px solid #e7e9ea; box-sizing: border-box; position: relative; }
    .mainRightContent .leftMainMenu li.active .leftSubMenu.open { display: block; }
    .mainRightContent .leftMainMenu li.active:after { content: ''; width: 2px; height: 60px; display: inline-block; background: #477fff; position: absolute; top: 0; right: 0; }
    .mainRightContent .leftMainMenu li a { display: block; padding: 0 40px; font-size: 14px; font-weight: bold; color: #232848; }
    .mainRightContent .leftMainMenu li a .mainMore { font-size: 0; width: 40px; height: 60px; display: inline-block; float: right; background: url('../assets/images/side_area/ic_downchk.png') no-repeat center; background-size: 15px auto;}
    .mainRightContent .leftSubMenu li { border-bottom: 0px; margin-left: 25px; }
    .mainRightContent .leftSubMenu li a .subMore { font-size: 0; width: 40px; height: 60px; background: url('../assets/images/side_area/bt_more.png') no-repeat center; vertical-align: top; position: absolute; top: 0; left: 0; }
    .mainRightContent .leftSubMenu { display: none; }
    .mainRightContent .leftSubMenu .leftSubM3depth { display: none; }

    /* 우측 폴더 */
    .holiday_setting.section_ce_fix.dp2 .group_file { display: none; }
    .mainLeftDivScroll .group_file > ul { overflow: visible; display: inline-block; width: 100%; height: 60px; line-height: 60px; background: #f2f5f7; border-bottom: 1px solid #e7e9ea; font-size: 0; font-weight: bold; }
    .mainLeftDivScroll .group_file ul li { width: 20%; display: inline-block; float: left; text-overflow: ellipsis; white-space: nowrap; padding-right: 10px; box-sizing: border-box; font-size: 13px; cursor: pointer; }
    .mainLeftDivScroll .grfolderUl li .subfolderDiv { display: none; }
    .mainLeftDivScroll .group_file .grfolderUl li.on { height: auto; border-bottom: 2px solid #f1f3f5; }
    .mainLeftDivScroll .grfolderUl li.on .subfolderDiv { display: block; }
    .mainLeftDivScroll .group_file .grfolderUl li:hover { background: #f7f9fb; }
    .mainLeftDivScroll .group_file .grfolderUl { font-size: 0; margin-top: -5px; background: #fff; height: 100%; min-height: 723px; line-height: 66px; border-bottom: 0 none; }
    .mainLeftDivScroll .group_file .grfolderUl li { width: 100%; position: relative; border: none; height: 66px; line-height: 66px; border-bottom: 1px solid #f1f3f5; transition: .2s; font-size: 0; padding-right: 0; }
    .mainLeftDivScroll .group_file .grfolderUl p { width: 20%; height: 66px; line-height: 66px; position: static; display: inline-block; float: left; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-right: 10px; font-size: 12px; box-sizing: border-box; }
    .mainLeftDivScroll .group_file ul .num { width: 12%; text-align: center; }
    .mainLeftDivScroll .group_file ul .folder { width: 33%; height: auto; line-height: inherit; text-align: left; color: #232848; }
    .mainLeftDivScroll .group_file ul .fileHead { width: 15%; height: auto; }
    .mainLeftDivScroll .grfolderUl p.pdImg { position: absolute; top: 0; left: 13%; width: 50px; height: 65px; transition: .2s; background-size: 50px auto; background-repeat: no-repeat; background-position: center center; }
    .mainLeftDivScroll .grfolderUl p.folder { padding-left: 90px; position: relative; }
    .mainLeftDivScroll .grfolderUl p.folder span{ width: 8px; height: 8px; border-radius: 50%; background: #fff; display: inline-block; position: absolute; top: 29px; left: 70px; }
    /* 폴더 및 그룹 색상별 */
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc0 { background: #ff6363; }
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc1 { background: #ffa70e; }
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc2 { background: #ffc72f; }
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc3 { background: #ff138b; }
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc4 { background: #00b2c7; }
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc5 { background: #13d08b; }
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc6 { background: #4dbaff; }
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc7 { background: #477fff; }
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc8 { background: #6854ff; }
    .mainLeftDivScroll .grfolderUl p.folder span.dot_dc9 { background: #35405a; }
    .mainLeftDivScroll .subfolderDiv li { border-top: 1px solid #f1f3f5; border-bottom: 0; background: #fff; line-height: 55px; height: 55px; display: block; }

    .mainLeftDivScroll .grfolderUl a { position: absolute; }
    .mainLeftDivScroll .grfolderUl a.subFolder_more { font-size: 0; z-index: 99999; width: 40px; height: 40px; border-radius: 50%; background: url('../assets/images/side_area/bt_open.png') no-repeat center; top: 12px; right: 25px; box-sizing: border-box; }
    .mainLeftDivScroll .grfolderUl a.subFolder_more:hover { box-shadow: 0 0 15px 1px rgba(51,58,95,.2); background-color: #fff; }
    .mainLeftDivScroll .grfolderUl li.on .subFolder_more { background: #fff url('../assets/images/side_area/bt_close.png') no-repeat center; }

    /* 리스트 서브 폴더 */
    .subfolderDiv .subFoldertitleDiv h3 { display: none; }
    .mainLeftDivScroll .group_file .grfolderUl li.on .subfolderDiv li:first-child { border-top: 2px solid #f1f3f5; }
    .mainLeftDivScroll .subfolderUl p.pdImg { height: 55px; line-height: 55px; margin-left: 18px; background-size: 35px auto; top: 5px; }

    /* 폴더형 폴더 */
    .holiday_setting.section_ce_fix.dp2 .group_file.group_file_box { display: block; }
    .group_file.group_file_box { display: none; }
    .mainLeftDivScroll .group_file_box .grpBoxTitle { overflow: hidden; margin: 30px 30px 0 30px; line-height: 1.5; }
    .mainLeftDivScroll .group_file_box .grfolderUl { padding: 0 30px; }
    .mainLeftDivScroll .group_file_box .grpBoxTitle h3, .mainLeftDivScroll .group_file_box .grpBoxTitle p { font-size: 16px; font-weight: bold; display: inline-block; }
    .mainLeftDivScroll .group_file_box .grfolderUl li { width: 180px; height: 200px; border: 1px solid #f1f3f5; border-radius: 5px; margin: 30px 0 0 30px; padding: 30px 20px; text-align: center; box-sizing: border-box; }
    .mainLeftDivScroll .group_file_box .grfolderUl li.on { height: 200px; border-bottom: none; }
    .mainLeftDivScroll .group_file_box .grfolderUl p { width: 100%; padding-left: 0; height: 25px; line-height: 25px; font-size: 12px; padding: 0; text-align: center; }
    .mainLeftDivScroll .group_file_box .grfolderUl p.grp { font-size: 14px; }
    .mainLeftDivScroll .group_file_box p.num,
    .mainLeftDivScroll .group_file_box .grfolderUl p.fdType,
    .mainLeftDivScroll .group_file_box p.lastUpdated { display: none; }
    .mainLeftDivScroll .group_file_box .grfolderUl p.folder span { position: static; margin-right: 3px; margin-top: -2px; vertical-align: middle; }
    .mainLeftDivScroll .group_file_box .grfolderUl p.pdImg { position: static; background-size: 90px auto; background-position: 30px; height: 80px; text-align: center; }
    .mainLeftDivScroll .group_file_box .fileHead span.title2 { display: inline-block; position: relative; padding-right: 15px; }
    .mainLeftDivScroll .group_file_box .fileHead span.title2:before { content: ''; width: 2px; height: 15px; background: #dee1e4; position: absolute; top: 6px; left: 32px; }
    .mainLeftDivScroll .group_file_box .grfolderUl a.subFolder_more { background: #477fff; width: 85px; height: 27px; font-size: 12px; line-height: 27px; color: #fff; border-radius: 30px; left: 50%; transform: translateX(-50%); bottom: 25px; top: auto; opacity: 0; }
    .mainLeftDivScroll .group_file_box .grfolderUl li:hover a.subFolder_more { opacity: 1; bottom: -11px; }

    /* 폴더형 서브 폴더 */
    .mainLeftDivScroll .group_file_box .grfolderUl li.on .subfolderDiv { width: 100%; height: 100%; position: absolute; top: 0; left: 0; overflow: hidden; border: 1px solid #f1f3f5; box-sizing: border-box; }
    .dp2.section_ce_fix .subFoldertitleDiv { height: 40px; line-height: 40px; text-align: left; background: #fff; border-bottom: 2px solid #f1f3f5; padding: 0 20px; box-sizing: border-box; }
    .group_file_box .subfolderContent { position: relative; height: 170px; overflow-y: auto; }
    .group_file_box .subFoldertitleDiv span { width: 8px; height: 8px; display: inline-block; background: #477fff; border-radius: 50%; vertical-align: top; margin-top: 16px; margin-right: 10px; }
    .group_file_box .subFoldertitleDiv span.dot_dc0 { background: #ff6363; }
    .group_file_box .subFoldertitleDiv span.dot_dc1 { background: #ffa70e; }
    .group_file_box .subFoldertitleDiv span.dot_dc2 { background: #ffc72f; }
    .group_file_box .subFoldertitleDiv span.dot_dc3 { background: #ff138b; }
    .group_file_box .subFoldertitleDiv span.dot_dc4 { background: #00b2c7; }
    .group_file_box .subFoldertitleDiv span.dot_dc5 { background: #13d08b; }
    .group_file_box .subFoldertitleDiv span.dot_dc6 { background: #4dbaff; }
    .group_file_box .subFoldertitleDiv span.dot_dc7 { background: #477fff; }
    .group_file_box .subFoldertitleDiv span.dot_dc8 { background: #6854ff; }
    .group_file_box .subFoldertitleDiv span.dot_dc9 { background: #35405a; }
    .group_file_box .subFoldertitleDiv h3 { display: inline-block; font-weight: bold; font-size: 15px; }
    .group_file_box .subfolderUl { position: relative; height: 100%; background: #fff; }
    .mainLeftDivScroll .group_file_box .grfolderUl li .subfolderUl li { padding: 15px 15px; margin: 0; width: 100%; height: auto; text-align: left; border: none; border-bottom: 1px solid #f1f3f5; }
    .mainLeftDivScroll .group_file_box .grfolderUl li.on .subfolderDiv li:first-child { border-top: 0px; }
    .mainLeftDivScroll .group_file_box .grfolderUl .subfolderUl p.grp { padding-left: 40px; text-align: left; }
    .mainLeftDivScroll .group_file_box .grfolderUl .subfolderUl p.pdImg { width: 50px; height: 30px; position: absolute; top: 16px; left: 0; background-size: 28px; background-position: left top; }
    .mainLeftDivScroll .group_file_box .grfolderUl .subfolderUl p.fileHead { text-align: left; }
    .mainLeftDivScroll .group_file_box .grfolderUl li.on a.subFolder_more { opacity: 1; top: 6px; right: 10px; left: auto; transform: none; width: 27px; font-size: 0; background: #ff6363 url('../assets/images/contents/ic_close_fff.gif') no-repeat center center; background-size: 15px; }

    /* 기존파일 비밀번호 수정 */
    .settingBg { height: 80px; }
    .schedule_box .schDiv { display: none; }
    /* 그룹들어가기 전 */
    .section_ce_fix .subFolderUl .grp .ic-password { display: none; width: 15px; height: 15px; font-size: 0; background: url('../assets/images/folder_file/ic_password_232848.png') no-repeat center center; background-size: 9px; vertical-align: middle;
    margin-top: -2px; }
    .section_ce_fix .subFolderUl .grp.pswFolder .ic-password { display: inline-block; }
    /* 그룹들어온 후 */
    .section_ce_fix .folderUl.group .folder { position: relative; }
    .section_ce_fix .folderUl.group .pswFolder .ic-password { width: 30px; height: 30px; font-size: 0; background: url('../assets/images/folder_file/ic_password_fff.png') no-repeat center center; position: absolute; top: 90px; left: 100px; background-size: 20px; z-index: 9999; }
    .section_ce_fix .folderUl.group li .ic-password { font-size: 0; }
    .dp1.section_ce_fix .folderUl.group .pswFolder .ic-password { display: inline-block; width: 15px; height: 15px; background-image: url('../assets/images/folder_file/ic_password_232848.png'); background-size: 10px; position: static; vertical-align: middle; margin-top: -3px; }
    .folderUl li a.fileMenu { right: 5px; top: 16px; width: 30px; height: 30px; border-radius: 30px; left: auto; background: url('../assets/images/contents/ic_hover_more.png') no-repeat center center; background-size: 18px auto; transform: rotate(90deg); font-size: 0; z-index: 9999; transition: .2s; }
    /* .folderUl li:hover a.fileMenu { display: block; } */
    .folderUl li a.fileMenu:hover { background-color: #fff; box-shadow: 0px 0px 10px rgba(0,0,0,0.1); }
    .dp1.section_ce_fix .folderUl.group li a.fileMenu { top: 17px; }
    .forRel .schFileUl li .ic-password { display: none; width: 15px; height: 15px; font-size: 0; background: url('../assets/images/folder_file/ic_password_232848.png') no-repeat; background-position: center center; background-size: 9px; vertical-align: middle; margin-top: -3px; }
    .forRel .schFileUl li.pswFolder .ic-password { display: inline-block; }
    /* 파일 메뉴 옵션 */
    .folderUl li .fileMenuOp { display: none; position: absolute; top: 42px; right: 20px; width: 120px; height: auto; box-sizing: border-box; overflow: hidden; background: #fff; box-shadow: 0px 5px 10px rgb(0 0 0 / 10%); border-radius: 5px; transition: .2s; z-index: 10; }
    .folderUl li.on .fileMenuOp { display: block; }
    .folderUl li .fileMenuOp li { text-align: left; width: 100%; height: auto; margin: 0; border: none; }
    .folderUl li .fileMenuOp li .fileOpList { width: 100%; text-align: left; font-size: 12px; cursor: pointer; padding: 9px 15px; background: #fff; border: none; }
    .folderUl li .fileMenuOp li:hover .fileOpList { background: #f1f3f5; font-weight: bold; }
    .folderUl li .fileMenuOp li .pswDel { display: none; }
    .folderUl li.pswFolder .fileMenuOp li .pswDel { display: block; }
    .dp1.section_ce_fix .folderUl li .fileMenuOp li { height: auto; line-height: normal; border-bottom: none; }
    /* 폴더 추가 버튼 */
    .settingBg .folderAddBtn { float: right; margin: 18px 20px 0 0; }

    /* 22.11.08 폴더 넘버 삭제 / 이동경로 표시 추가 / 하위폴더추가 */
    .deep_route { width: 100%; height: 50px; background: #fff; border-bottom: 1px solid #e7e9ea; }
    .deep_route .routeUl { width: 100%; padding: 0 25px; box-sizing: border-box; height: 50px; line-height: 50px; display: flex; }
    .deep_route .routeUl li { max-width: 190px; height: 50px; line-height: 50px; float: left; position: relative; padding-right: 35px; box-sizing: border-box; cursor: pointer; }
    .deep_route .routeUl li:first-child { width: 58px; cursor: default; }
    .deep_route .routeUl li:before { content: ''; width: 7px; height: 7px; border-right: 1px solid #85889a; border-bottom: 1px solid #85889a; position: absolute; right: 18px; transform: rotate(-45deg); top: 21px; }
    
    .deep_route .routeUl li > a { line-height: 20px; vertical-align: middle; }
    .deep_route .routeUl li > a:hover { text-decoration: underline; }
    .deep_route .routeUl li.fixd_route { font-weight: bold; }
    .deep_route .routeUl li.fixd_route:before { display: none; }
    .routeUl .ic_file { width: 20px; height: 50px; display: inline-block; font-size: 0; background: url('../assets/images/menucons/ic_file_sm_dc9.png') no-repeat center center; background-size: 17px auto; vertical-align: top; }
    .dp1.section_ce_fix .subFolderUl .fd span.total { line-height: 58px; width: 37px; text-indent: 9px; font-size: 11px; }    
    .dp2.section_ce_fix .schFolderDiv .folder span.total, .dp1.section_ce_fix .schFolderDiv .folder span.total { display: inline-block; text-align: center; color: #fff; line-height: 40px; width: 39px; text-indent: 17px; font-size: 11px; vertical-align: top; }    
    
    .dp2.section_ce_fix .subFolderUl .fd span.total { display: inline-block; line-height: 30px; width: 37px; text-indent: -15px; font-size: 10px; vertical-align: top; padding-top: 1px; }
</style>