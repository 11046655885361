<template>
    <div id="hodu-popup" class="popup" v-if="show_popup == true">
        <img class="hodu-popup-image" :src="src" href="#" @click.prevent="open">
        <div class="close">
            <button @click="close">닫기</button>
            <button @click="notViewToday">오늘 하루 보지않기</button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const PopupInfo = namespace('PopupInfo');

@Component({
    components: {
        
    },
})
export default class Popup extends Mixins(VueHoduCommon) {
    
    @Prop() id !: string;
    @Prop() src !: string;

    /**
     * @PopupInfo.State
     */
    @PopupInfo.State show_popup !: boolean;

    /**
     * @PopupInfo.Action
     */
    @PopupInfo.Action doSetShowPopup ?: (params : boolean) => void;

    mounted() {

    }

    isModalOpen() : boolean {
        return this.show_popup;
    }

    open() : void {
        this.$emit('open');
    }

    close() : void {
        if( this.doSetShowPopup ) this.doSetShowPopup(false);
    }

    /**
     * 오늘 하루 보지 않기
     */
    notViewToday() : void {
        const today = new Date();
        const today_key = `${today.getFullYear()}${`0${today.getMonth() + 1}`.slice(-2)}${`0${today.getDate()}`.slice(-2)}`;
        localStorage.setItem(`${this.id}_not_view_today`, today_key);
        this.close();
    }

}
</script>

<style scoped>
    #hodu-popup {
        position: fixed;
        background : #fff;
        width : 500px; height : 560px; left:50%; top: 50%;
        margin-top:-280px; margin-left:-250px;
        box-shadow: 0 3px 10px;
        z-index: 100000000;
    }

    #hodu-popup .hodu-popup-image {
        width: 500px;
        height : 519px;
        cursor: pointer;
    }

    #hodu-popup .close {
        width: 100%;
        height: 41px;
        line-height: 41px;
    }

    #hodu-popup .close > button {
        cursor: pointer;
        border: none;
        padding: 0 10px;
        font-size: 13px;
        background: #333;
        color: #fff;
        margin-right : 5px;
        margin-top : 5px;
        float : right;
        height : 27px;
        line-height: 27px;
    }
</style>