<template>
    <div id="common_color_picker_modal" class="modal" :class="{ is_dc_only : common_color_picker_modal_info.is_dc_only == true }">
        <div class="titleBox">
            <h1>색상 선택</h1>
        </div>
        <div class="content">
            <div class="detail">
                <ul class="mainUl">

                    <!-- 진한색 -->
                    <li>
                        <p>진한색</p>

                        <!-- 이미 지정 된 색상 -->
                        <ul class="clrUl" v-if="common_color_picker_modal_info.dc_color == null || common_color_picker_modal_info.dc_color.length < 1">
                            <li :key="colorIndex" v-for="(color, colorIndex) in dc_color" @click="selectColor(color)">
                                <a :class="`${hodu_color_dc_lc(color)} ${hodu_hex_color_process(color).toUpperCase() == hodu_hex_color_process(common_color_picker_modal_info.selected_color).toUpperCase() ? 'on' : ''}`" ></a>
                            </li>
                        </ul>

                        <!-- 커스텀 색상 -->
                        <ul class="clrUl" v-else>
                            <li :key="colorIndex" v-for="(color, colorIndex) in common_color_picker_modal_info.dc_color" @click="selectColor(color)">
                                <a :class="{ on : hodu_hex_color_process(color).toUpperCase() == hodu_hex_color_process(common_color_picker_modal_info.selected_color).toUpperCase() }" :style="{ backgroundColor : color }"></a>
                            </li>
                        </ul>

                    </li>

                    <!-- 파스텔 -->
                    <li>
                        <p>파스텔</p>

                        <!-- 이미 지정된 색상 -->
                        <ul class="clrUl" v-if="common_color_picker_modal_info.lc_color == null || common_color_picker_modal_info.lc_color.length < 1">
                            <li :key="colorIndex" v-for="(color, colorIndex) in lc_color" @click="selectColor(color)">
                                <a :class="`${hodu_color_dc_lc(color)} ${color.toUpperCase() == common_color_picker_modal_info.selected_color.toUpperCase() ? 'on' : ''}`" ></a>
                            </li>
                        </ul>

                        <!-- 커스텀 색상 -->
                        <ul class="clrUl" v-else>
                            <li :key="colorIndex" v-for="(color, colorIndex) in common_color_picker_modal_info.lc_color" @click="selectColor(color)">
                                <a :class="{ on : color.toUpperCase() == common_color_picker_modal_info.selected_color.toUpperCase() }" :style="{ backgroundColor : color }"></a>
                            </li>
                        </ul>

                    </li>
                </ul>

            </div>
            <div class="btns">
                <input type="button" value="취소" @click="cancel()" />
                <input type="button" class="save" value="저장" @click="saveColor()" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonColorPickerModalInfo } from '../../../store/modules/ModalInfo';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class CommonColorPickerModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State common_color_picker_modal_info !: CommonColorPickerModalInfo
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetCommonColorPickerModalInfo ?: (parms : CommonColorPickerModalInfo) => void;

    created() : void {
    }

    mounted() : void {
        // $(".clrUl li a").click(function(){
        //     $(this).parent().parent().parent().siblings().find("a").removeClass("on");
        //     $(this).parent().siblings().find("a").removeClass("on");
        //     $(this).addClass("on");
        // });
    }

    selectColor(ps_color: string) : void {
        this.common_color_picker_modal_info.selected_color = ps_color;
    }

    saveColor() : void {
        window['applyColor']?.(this.common_color_picker_modal_info.selected_color);
        this.cancel();
    }

    cancel() : void {
        delete(window['applyColor']);
        this.doSetCommonColorPickerModalInfo?.({ show_modal : false, selected_color : "", is_dc_only : false });
    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-158px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:316px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    .modal.is_dc_only { width: 400px; margin-left: -200px; }
    .modal.is_dc_only .mainUl > li {width:100%; }
    .modal.is_dc_only .mainUl > li:last-child { display: none;  }
    
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #f1f3f5 }
    .mainUl { overflow: hidden; }
    .mainUl > li { width:50%; float:left; }
    .mainUl > li p { display:none; line-height: 60px; height: 60px; font-weight: bold; font-size: 14px; width:100%;  text-align: center; border-bottom:1px solid #f1f3f5;}

    .clrUl { padding: 10px 30px 30px;border-right:1px solid #f1f3f5; }
    .clrUl li { display: inline-block; width: 20%; padding: 20px 0 0; text-align:center; }
    .clrUl li a { opacity:0.8; transition:0.2s; display: inline-block; width: 35px; height: 35px; border-radius: 50%; }
    .clrUl li a.on { background-image: url(../../../assets/images/contents/checked_fff.png);background-repeat: no-repeat;background-size: 30px;background-position: center center; }
    .clrUl li a:hover, .clrUl li a.on { opacity:1; transform: scale(1.1); }
    
    .btns { overflow: hidden; border-top:1px solid #f1f3f5;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }
</style>