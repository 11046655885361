<template>
    <div class="userListModal modal peepsOn">
        
        <!-- 상단 영역 시작 -->
        <div class="titleBox">
            <h1 class="borderbot">읽음 여부</h1>
        </div>
        <!-- 상단 영역 끝 -->

        <div class="tabs">
            <a href="#" :class="{ on : tab == 'READ'   }" @click.prevent="() => tab = 'READ'">읽은 사람 <span>{{ user_list.length }}</span></a>
            <a href="#" :class="{ on : tab == 'UNREAD' }" @click.prevent="() => tab = 'UNREAD'">안 읽은 사람 <span>{{ unread_user_list.length }}</span></a>
        </div>

        <!-- 내용 영역 시작 -->
        <div class="modal_content">

            <!-- 멤버 헤더 -->
            <ul class="sortHeader teamHeader">
                <li class="num">No</li>

                <li class="pNm">
                    <span>이름</span>
                </li>

                <li class="pos">
                    <span>권한</span>
                </li>
            </ul>

        </div>
        <!-- 내용 영역 끝 -->

        <!-- 멤버 -->
        <div class="peepsDiv on">
            <div class="peepsUlDiv">
                <ul class="peeps">
                    
                    <li :key="user.user_id" v-for="(user, user_index) in (tab == 'READ' ? user_list : unread_user_list)">
                        <p class="num">{{ user_index + 1 }}</p>
                        <p class="fPic" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor((user.user_id ? user.user_id : 0) / 10000)}/${user.user_id}.jpg)` }">멤버 사진</p>
                        <img class="fPicDummy" style="display : none;" :src="`/app_images/profile/user/${Math.floor((user.user_id ? user.user_id : 0) / 10000)}/${user.user_id}.jpg`" @error="userImageError($event)" />
                        <p class="pNm">{{ user.user_name }}</p>
                        <!-- king, manager, mem -->
                        <p class="pos">
                            {{ getRole(user) }}
                        </p>
                    </li>

                </ul>
            </div>
        </div>

        <!-- 멤버 끝 -->
        <div class="btns b1">
            <input type="button" class="input_btn" value="닫기" @click="clickCloseButton">
        </div>

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupBoardReadInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {

    }
}) export default class GroupBoardRead extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_board_read_info !: GroupBoardReadInfo

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupBoardReadInfo ?: (params : GroupBoardReadInfo) => void;

    user_list : any[] = [];
    unread_user_list : any[] = [];

    tab : string = "READ";

    mounted() : void {
        this.getNotificationRead();
    }

    /**
     * 공지사항 읽은 사람 조회
     */
    getNotificationRead() : void {
        const vue = this;

        this.hodu_api_call(`api/v1/groups/${this.group_board_read_info.group_id}/board/read/${this.group_board_read_info.board_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                
                // 읽은 사람
                vue.user_list.splice(0, vue.user_list.length);
                vue.user_list = vue.user_list.concat(response.data.data.board_read_user_list);

                // 안 읽은 사람
                vue.unread_user_list.splice(0, vue.unread_user_list.length);
                vue.unread_user_list = vue.unread_user_list.concat(response.data.data.board_unread_user_list);
            })
            .catch((e) => {
                this.doSetGroupBoardReadInfo?.({
                    show_modal : false,
                    group_id : 0,
                    board_id : ''
                });

                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 역할 받기
     */
    getRole(user : any) : string {
        if( user.team_role && user.team_role.length > 0 ) {
            switch( user.team_role[0] ) {
                case 'ADMIN':
                    return '최종관리자';

                case 'TEAM_MANAGER':
                    return '팀장';

                case 'TEAM_USER':
                    return '팀원';
            }
        }

        if( user.group_role && user.group_role.length > 0 ) {
            switch( user.group_role[0] ) {
                case 'ADMIN':
                    return '최종관리자';

                case 'GROUP_MANAGER':
                    return '그룹 관리자';

                case 'GROUP_SUB_MANAGER':
                    return '그룹 부관리자';
                    
                case 'GROUP_USER':
                    return '그룹원'
            }
        }

        return '';
    }

    /**
     * 확인 버튼 클릭
     */
    clickCloseButton() : void {
        this.doSetGroupBoardReadInfo?.({
            show_modal : false,
            group_id : 0,
            board_id : ""
        });
    }

}

</script>

<style scoped>
    /* 모달 공통 css */
    .modal { position:absolute; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}
    
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}
    .titleBox .total { float:left; font-size:14px; font-weight:bold; margin-left:15px;margin-top:2px; }
    .titleBox .total span { color:#477fff; padding-left:5px;}
    .titleBox .total.team {  }
    .modal .btns {border-top:1px solid #f1f3f5; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; }
    .modal .btns.b1 input { width:100% }

    .modal .schBtn { position:absolute; right:0;top:20px;right:30px; }
    .modal .schGrp {display:none;transition:0.1s;position: absolute;top: 110px;z-index: 100000000;width: 100%;height: 60px;background: white;top: 89px;left: 0;}
    .modal .schGrp.on { display:block; }
    .modal .schGrp input {transition:0.1s;background:transparent;width: 100%;box-sizing: border-box;height: 100%;line-height: 60px;padding: 0 100px 0 30px;font-size: 14px;font-weight: bold;}
    .modal .schGrp:hover { background:#f1f3f5; }
    .modal .schGrp input:focus { background:#fff;border-bottom:1px solid #477fff; height:60px;line-height:61px; }
    .modal .schGrp a.schBtn { top:0px; }

    .modal .noResult { display:none; position: absolute; z-index: 1000000;top: 150px;height: 316px;background: #fff;line-height: 316px;font-weight: bold;font-size: 21px;color: #afb6c3;}

    /* 공유모달 css */
    .userListModal .sortHeader { position:relative; }
    .sortHeader li.grpCheck { position: absolute;left: 0;top: 0;width: 100px;text-align: center;font-weight: bold;font-size: 13px;}
    .userListModal { transition:0.3s; max-width: 800px; bottom:auto !important; margin-top: -293px !important;height: 586px !important;}
    .shareUlDiv {height: auto;display: block;width: 100%;height: 316px;max-height: 316px;overflow:hidden; overflow-y:auto;}
    .shareUlDiv ul {display: block;width:100%;}
    .shareUlDiv li .grp {width: 100%;transition:0.1s;height: 55px;line-height: 55px;border-bottom: 1px solid #f1f3f5; position:relative;}
    .shareUlDiv li .grp:hover { background:#f1f3f5; }
    .shareUlDiv li .grp * {display: inline-block;float:left;}
    .shareUlDiv li .grp:hover .count a { border:2px solid #477fff; border-radius: 5px; padding:0 10px; line-height:30px; height:30px;margin-top:11px; }
    .shareUlDiv li .grp .count.noCount { opacity:0.6 }
    .shareUlDiv li .count a { transition:0.1s; }
    .shareUlDiv li .count a:hover { background:#477fff; color:#fff; }
    .shareUlDiv li .count a:hover span { color:#fff; }
    .shareUlDiv li .img {width: 57px;font-size: 0; background-position:center center !important; background-size: cover !important;height: 42px;border-radius: 5px;border: 1px solid #f1f3f5;position: absolute;left: 180px;top: 7px;}
    .shareUlDiv .teamUl li  .img {width: 50px;height: 36px;top: 9px;left: 186px;}
    .shareUlDiv li h2 {font-size: 14px;}
    .shareUlDiv li .nm { width:64%;box-sizing:border-box; padding-left:120px; padding-right:20px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
    .shareUlDiv li .count { width:10%;font-weight:bold; }
    .shareUlDiv li .count span { color:#477fff; margin-right:4px; }
    .shareUlDiv li.withTeams a.showTeams {position: absolute;right: 20px;top: 0;font-size: 0;width: 35px;height: 60px;border-radius: 50%; background:  url('../assets/images/side_area/bt_open.png') no-repeat center center;}
    .shareUlDiv li a.showTeams.on, .shareUlDiv li.on a.showTeams {background:  url('../assets/images/side_area/bt_close.png') no-repeat center center;  }
    .shareUlDiv .teamUl { display:none; border-top:1px solid #f1f3f5;border-bottom:1px solid #f1f3f5;}
    .shareUlDiv li.on > .grp {  }
    .shareUlDiv li.on .teamUl { display:block; }
    .userListModal input[type='checkbox']:not(old) + label {position: absolute;left: 38px;top: 17px;z-index:10000000; }
    .userListModal .teamUl input[type='checkbox']:not(old) + label{left: 50px;}
    .userListModal input[type='checkbox']:not(old) + label, .userListModal input[type='checkbox']:not(old) + label:hover {margin-left: 5px;display: inline-block;  padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
    .userListModal input[type='checkbox']:not(old):checked + label { border:2px solid #477fff;background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;}
    .grpUl > li > .grp { height:60px; line-height:60px; }
    .grpUl > li > .grp .img  { top:8px; }
    .grpUl > li > .grp:hover > .count > a { margin-top:14px; }
    .grpUl > li > .grp input[type='checkbox']:not(old) + label { top: 19px }
    .shareUlDiv li .grp .cl {position: absolute;left: 151px;top: 26px;width: 8px;height: 8px;font-size: 0;border-radius: 50%;background: #f1f3f5;}
    .shareUlDiv li .teamUl .grp .cl { left:158px; }
    .userListModal .teamUl .num {padding-left: 90px;}

    .grpHeader .num { width:15%;  }
    .grpHeader .nm {padding-left: 14px;width: 64%;box-sizing: border-box;}
    .grpHeader .count {width: 19%;}

    .teamHeader { display:none; }
    .userListModal.peepsOn ul.grpUl {height: 447px;}
    .userListModal.peepsOn { margin-top: -293px !important; height: 586px !important; }
    .userListModal.peepsOn .teamHeader { display:block; }
    .userListModal.peepsOn .grpHeader { display:none; }
    .userListModal .peepsDiv { display:none;width:100%;position: absolute;background: #fff;z-index: 1000000000;top: 150px; }
    .userListModal .peepsDiv.on {display: block; position: static;}
    .userListModal .peepsDiv .peepsUlDiv { width: 100%;height: 316px;max-height: 316px;overflow: hidden;overflow-y: auto;}
    .userListModal ul.peeps li * {display: inline-block;}
    .userListModal ul.peeps li {transition:0.1s;position:relative;line-height: 50px;height: 50px;font-size: 13px;font-weight: bold;border-bottom:1px solid #f1f3f5;}
    .userListModal ul.peeps li .fPic {position:absolute;background-color:#f1f3f5; left:155px; top:10px;font-size: 0;width: 30px;height: 30px;border-radius: 50%;background-position: center center !important;background-size: cover !important;border: 1px solid #e7e9ea;}
    .userListModal ul.peeps li .pNm { padding-left:70px; padding-right:20px; width:63%; box-sizing:border-box; }
    .userListModal ul.peeps li .pos { font-size:12px; }
    .userListModal ul.peeps li:hover { background:#f1f3f5; }
    .userListModal .peeps input[type='checkbox']:not(old) + label { top:14px; }
    .userListModal .peeps .fPic.none { background-image : url(../assets/images/contents/im_photoB.gif) !important; }

    .userListModal .pNm {  width:63%; box-sizing:border-box;    padding-left: 20px;  }
    .userListModal .num {font-weight:bold; /* padding-left: 80px; */ width: 17%; box-sizing:border-box; text-align: center;font-size:12px;}
    .userListModal .peeps input[type='checkbox']:not(old) + label:after {content: '';display: block;width: 800px;height: 50px;position: absolute;left: -45px;top: -16px;}
    
    
    .teamHeader .pos {width: 20%;}
    .sortHeader input[type='checkbox']:not(old) + label { top:19px;}

    .shareUlDiv.mCS_destroyed { overflow : hidden !important }

    .userListModal .tabs { height: 50px; line-height: 50px; position: relative; font-size: 14px; border-bottom: 3px solid #e7e9ea; }
    .userListModal .tabs a { width: 50%; display: inline-block; float: left; text-align: center; position: relative; color: #999; }
    .userListModal .tabs a span { color: #999; }
    .userListModal .tabs a.on, .userListModal .tabs a.on span { font-weight: bold; color: #000; }
    .userListModal .tabs a:hover, .userListModal .tabs a:hover span { color: #000; }
    .userListModal .tabs a:hover:after, .userListModal .tabs a.on:after { z-index: 1; position: absolute; content: ''; width: 100%; height: 3px; display: inline-block; background: #477fff; -webkit-transition: 0.2s; transition: 0.2s; bottom: -3px; left: 0; }

    .userListModal ::-webkit-scrollbar{width: 6px;}
    .userListModal ::-webkit-scrollbar-track{background-color: transparent;}
    .userListModal ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
</style>