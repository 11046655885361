<template>
    <!-- <div id="organization_off_modal" class="modal" :class="{ onlyTerm : organization_off_modal_info.is_only_setting_term == true }">

        <div class="titleBox">
            <h1 class="borderbot">연차 {{ organization_off_modal_info.is_only_setting_term == true ? '기간 ' : '' }}설정</h1>
        </div>

        <div class="modal_content">

            <div class="top_left_content" v-if="employee != null">
                <h2>연차 적용 시작일</h2>
                <input id="modal_annual_start" type="button" :value="start_text" />
            </div>

            <div class="top_right_content" v-if="employee != null">
                <h2>연차 적용 종료일</h2>
                <input id="modal_annual_end" type="button" :value="end_text" />
            </div>

            <div class="bottom_content" v-if="employee != null && !(organization_off_modal_info.is_only_setting_term == true)">
                <h2>연차수</h2>
                <input type="number" placeholder="일수를 입력해주세요 (단위 : 일)" :value="employee.annual_count" @input="employee.annual_count = $event.target.value" />
            </div>

        </div>

        <div class="btns">
            <input type="button" class="input_btn" value="취소" @click.prevent="close">
            <input type="button" class="input_btn" value="저장" @click.prevent="save">
        </div>

    </div> -->

    <div id="organization_off_modal" class="modal">
        <div class="titleBox">
            <h1 class="ing">연차 현황</h1>
        </div>
        <div class="content"> 
            <div class="detail">
                <div class="leftContent" v-if="employee != null">
                    <p class="img" :style="{ backgroundImage : `url(${getProfileImage(employee)})` }">사진</p>
                    <img class="dummy" :src="getProfileImage(employee)" @error="userImageError($event)"/>
                    <p class="name">{{ employee.user_name }}</p>
                    <p class="pos">{{ employee.pos_name }}</p>
                    <p class="team">{{ employee.dept_name }}</p>
                    <p class="email">{{ employee.user_email && employee.user_email.length > 0 ? employee.user_email : '-' }}</p>
                    <p class="tel">{{ employee.user_phone_number && employee.user_phone_number.length > 0 ? employee.user_phone_number : '-' }}</p>
                </div>
                <div class="context">
                    <ul class="detailUl" v-if="employee != null">
                        
                        <li class="">
                            <select :style="{ width : is_list ? '80%' : '100%' }" v-model="select_option_key" @change="selectChanged($event)">
                                <option value="" selected>전체</option>

                                <!-- 추가중일때 나타내는 지표 -->
                                <option :value="`${selected_annual_info.annual_start}-${selected_annual_info.annual_end}-add`" v-if="is_add == true">
                                    {{ hodu_date_to_format_string(yyyymmddToDate(selected_annual_info.annual_start), 'YYYY년 M월 D일') }} ~ {{ hodu_date_to_format_string(yyyymmddToDate(selected_annual_info.annual_end), 'YYYY년 M월 D일') }} (추가중)
                                </option>

                                <!-- 현재 적용중인 최신 지표 -->
                                <!-- <option :value="`${employee.annual_start}-${employee.annual_end}`">
                                    {{ hodu_date_to_format_string(yyyymmddToDate(employee.annual_start), 'YYYY년 M월 D일') }} ~ {{ hodu_date_to_format_string(yyyymmddToDate(employee.annual_end), 'YYYY년 M월 D일') }} ({{ employee.use_annual_count.split(' / ')[0] }}/{{ employee.use_annual_count.split(' / ')[1] }})
                                </option> -->

                                <!-- 과거 연차기간 지표 -->
                                <option :value="`${past_emp.annual_start}-${past_emp.annual_end}`" :key="`${past_emp.annual_start}-${past_emp.annual_end}`" v-for="past_emp in employee.past_emp_info">
                                    {{ hodu_date_to_format_string(yyyymmddToDate(past_emp.annual_start), 'YYYY년 M월 D일') }} ~ {{ hodu_date_to_format_string(yyyymmddToDate(past_emp.annual_end), 'YYYY년 M월 D일') }} ({{ past_emp.use_annual_count.split(' / ')[0] }}/{{ past_emp.use_annual_count.split(' / ')[1] }})
                                </option>

                                <!-- <option value="20210304-20220303">2021년 3월 4일 ~ 2022년 3월 3일 (6/17)</option> -->
                                <!-- <option value="20200304-20210303">2020년 3월 4일 ~ 2021년 3월 3일 (15/16)</option> -->
                                <!-- <option value="20190304-20200303">2019년 3월 4일 ~ 2020년 3월 3일 (9/15)(-3)</option> -->
                            </select>
                            <div class="newYear_add" v-if="is_list == true">
                                <p class="add_icon"><a href="#" @click.prevent="add">아이콘</a></p>
                            </div>
                        </li>

                        <!-- 전체보기 (3년) 클릭시 보여줄 화면 -->
                        <!-- <li class="all_year" v-show="is_list == true">
                            <p class="first_year">
                                <a href="#" @click.prevent="selectAnnualInfo(employee)">
                                    {{ hodu_date_to_format_string(yyyymmddToDate(employee.annual_start), 'YYYY.MM.DD') }} ~ {{ hodu_date_to_format_string(yyyymmddToDate(employee.annual_end), 'YYYY.MM.DD') }}
                                </a>
                            </p>
                            <p class="first_year_count"><a href="#" @click.prevent="selectAnnualInfo(employee)"><span>{{ employee.use_annual_count.split(' / ')[0] }}</span> / {{ employee.use_annual_count.split(' / ')[1] }}</a></p>
                        </li> -->

                        <li class="all_year" :key="`${past_emp.annual_start}-${past_emp.annual_end}`" v-for="past_emp in computedEmpInfo" v-show="is_list == true">
                            <p class="first_year">
                                <a href="#" @click.prevent="selectAnnualInfo(past_emp)">
                                    {{ hodu_date_to_format_string(yyyymmddToDate(past_emp.annual_start), 'YYYY.MM.DD') }} ~ {{ hodu_date_to_format_string(yyyymmddToDate(past_emp.annual_end), 'YYYY.MM.DD') }}
                                </a>
                            </p>
                            <p class="first_year_count"><a href="#" @click.prevent="selectAnnualInfo(past_emp)"><span>{{ past_emp.use_annual_count.split(' / ')[0] }}</span> / {{ past_emp.use_annual_count.split(' / ')[1] }}</a></p>
                            <a href="#" class="remove" @click.prevent="remove(past_emp)">삭제</a>
                        </li>
                        
                        <!-- <li class="all_year">
                            <p class="first_year"><a href="#">2021.03.04 ~ 2022.03.03</a></p>
                            <p class="first_year_count"><a href="#"><span>6</span> / 17</a></p>
                        </li>
                        <li class="all_year">
                            <p class="first_year"><a href="#">2020.03.04 ~ 2021.03.03</a></p>
                            <p class="first_year_count"><a href="#"><span>15</span> / 16</a></p>
                        </li>
                        <li class="all_year">
                            <p class="first_year"><a href="#">2019.03.04 ~ 2020.03.03</a></p>
                            <p class="first_year_count"><a href="#"><span>9</span> / 15 <span class="join_before">(-3)</span></a></p>
                        </li> -->

                        <li class="startLi" v-show="is_list == false" v-if="selected_annual_info != null">
                            <p class="title">시작일</p>
                            <input id="modal_annual_start" type="button" :value="start_text" />
                        </li>

                        <li class="endLi" v-show="is_list == false" v-if="selected_annual_info != null">
                            <p class="title">종료일</p>
                            <input id="modal_annual_end" type="button" :value="end_text" />
                        </li>

                        <li class="yearOffLi" v-show="is_list == false" v-if="selected_annual_info != null">
                            <p class="title">연차 수</p>
                            <div class="descript">
                                <input type="number" maxlength="9" required id="yearOff" class="yearOff" name="yearOff" placeholder="이번 년도 총 연차 수를 적어주세요." v-model="annual_count">
                                <!-- <select class="year_count rtl">
                                    <option :value="n - 1" :key="n - 1" v-for="n in 26">{{ n - 1 }}일</option> 
                                    <option selected>15일</option>
                                    <option>17일</option>
                                    <option>18일</option>
                                    <option>19일</option>
                                    <option>20일</option>
                                    <option>21일</option>
                                    <option>22일</option>
                                    <option>23일</option>
                                    <option>24일</option>
                                    <option>25일</option>
                                    <option>26일</option>
                                    <option>27일</option>
                                    <option>28일</option>
                                    <option>29일</option>
                                    <option>30일</option>
                                </select> -->
                            </div>
                        </li>

                        <li class="yearuseLi" v-show="is_list == false" v-if="selected_annual_info != null && is_add == false">
                            <p class="title">사용 연차 수</p>
                            <div class="descript">
                                <p class="yearuseLi_text"><span>{{ selected_annual_info ? selected_annual_info.use_annual_count.split(' / ')[0] : 0 }}</span></p>
                            </div>                         
                        </li>

                    <!-- 가입후 첫 설정때만 보여주기 -->
                        <li class="firstLi" v-show="is_list == false" v-if="isExistBeforeAnnualCount() == true">
                            <p class="title">호두웨어 가입 전 사용 연차 수</p>
                            <div class="descript">
                                <input type="number" maxlength="9" required id="before_yearOff" class="yearOff" name="before_yearOff" placeholder="호두웨어 가입전 사용한 연차 수를 적어주세요." v-model="before_annual_count">
                                <!-- <select class="year_count rtl">
                                    <option :value="`${n - 1}`" :key="`${n - 1}`" v-for="n in 26">{{ n - 1 }}일</option> 
                                    <option>1일</option>
                                    <option>2일</option>
                                    <option selected>3일</option>
                                    <option>4일</option>
                                    <option>5일</option>
                                    <option>6일</option>
                                    <option>7일</option>
                                    <option>8일</option>
                                    <option>9일</option>
                                    <option>10일</option>
                                    <option>11일</option>
                                    <option>12일</option>
                                    <option>13일</option>
                                    <option>14일</option>
                                    <option>16일</option>
                                    <option>17일</option>
                                    <option>18일</option>
                                    <option>19일</option>
                                </select> -->
                            </div> 
                        </li>

                    </ul>
                    
                </div>
            </div>
            
            <div class="btns" :class="{ b1 : is_list == true }">
                <input type="button" :value="is_list == true ? '닫기' : '이전'" @click.prevent="close"/>
                <input type="button" class="save" value="저장" @click.prevent="save" v-if="is_list == false"/>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';
import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationOffModal extends Mixins(VueHoduCommon) {

    get computedEmpInfo() {

        let emp : any[] = [];

        if( this.employee != null ) {

            const now = moment().format("YYYYMMDD");

            const filter = this.employee.past_emp_info.filter(emp_info => (Number(emp_info.annual_start) <= Number(now) && Number(now) <= Number(emp_info.annual_end)) ) 

            if( filter.length < 1 ) return emp;

            const current_emp_info = filter[0];
            const current_emp_info_index : number = this.employee.past_emp_info.indexOf(current_emp_info);

            emp.push(this.employee.past_emp_info[current_emp_info_index]);
            if( this.employee.past_emp_info.length > (current_emp_info_index + 1) ) {
                emp.push(this.employee.past_emp_info[current_emp_info_index + 1]);
            }
        }

        return emp;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_off_modal_info !: organization_modal_interface.OrganizationOffModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationOffModalInfo ?: (params : organization_modal_interface.OrganizationOffModalInfo) => void;

    employees : any[] = [];
    employee : any = null;
    
    start_text : string = "";
    end_text : string = "";

    select_option_key : string = "";
    annual_count : string = '15';
    before_annual_count : string = '0';

    is_list : boolean = true;
    is_add : boolean = false;

    copy_annual_info : any = null;     // 추가, 수정시 원래 정보
    selected_annual_info : any = null; // 입력된 값 정보

    async mounted() : Promise<void> {
        // // 여러명 세팅
        // if( this.organization_off_modal_info.user_ids != null && this.organization_off_modal_info.user_ids.length > 0 ) {
        //     this.employee = { "annual_start" : null, "annual_end" : null, "annual_count" : null };
        //     const current_year = new Date().getFullYear();

        //     if( this.employee.annual_start == null ) this.employee.annual_start = `${current_year}0101`;
        //     if( this.employee.annual_end == null ) this.employee.annual_end = `${current_year}1231`;

        //     this.processDateText();

        //     await this.getOrganizationEmp();
        // }
        // // 단일 세팅
        // else {
        //     await this.getOrganizationEmp();
        // }
        // this.datepickerInit();

        await this.getOrganizationEmp();
    }   

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?user_id=${this.organization_off_modal_info.user_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 직원 조회 중 오류 발생");
            }

            if( response.data.data.emp_info.past_emp_info != null && response.data.data.emp_info.past_emp_info.length > 0 ) {
                response.data.data.emp_info.past_emp_info.sort((o1, o2) : number => {
                    const o1_annual_start = o1.annual_start;
                    const o2_annual_start = o2.annual_end;

                    if( o1_annual_start > o2_annual_start ) return 1;
                    if( o1_annual_start < o2_annual_start ) return -1;
                    return 0;
                });
            }

            this.employee = JSON.parse(JSON.stringify(response.data.data.emp_info));

            // if( this.start_text == '' || this.end_text == '' ) {
            //     const current_year = new Date().getFullYear();

            //     if( this.employee.annual_start == null ) this.employee.annual_start = `${current_year}0101`;
            //     if( this.employee.annual_end == null ) this.employee.annual_end = `${current_year}1231`;

            //     this.processDateText();
            // }
        
        } catch(e) {
            alert("직원 정보 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }

        // try {
            
        //     // 1명 연차 설정인 경우
        //     if( !(this.organization_off_modal_info.user_ids != null && this.organization_off_modal_info.user_ids.length > 0) ) {
        //         const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?user_id=${this.organization_off_modal_info.user_id}`, API_METHOD.GET);

        //         console.log(response);

        //         if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
        //             throw new Error("조직도 직원 조회 중 오류 발생");
        //         }

        //         this.employee = JSON.parse(JSON.stringify(response.data.data.emp_info));

        //         if( this.start_text == '' || this.end_text == '' ) {
        //             const current_year = new Date().getFullYear();

        //             if( this.employee.annual_start == null ) this.employee.annual_start = `${current_year}0101`;
        //             if( this.employee.annual_end == null ) this.employee.annual_end = `${current_year}1231`;

        //             this.processDateText();
        //         }
        //         return;
        //     }

        //     // 여러명 연차 설정인 경우
        //     const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET);

        //     console.log(response);

        //     if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
        //         throw new Error("조직도 직원 조회 중 오류 발생");
        //     }

        //     this.employees.splice(0, this.employees.length);
        //     this.employees = this.employees.concat(response.data.data.emp_info);
            
        // } catch(e) {
        //     alert("직원 정보 조회 중 오류 발생");
        //     this.hodu_error_process(e, false, false, true);
        // }
    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")}`);
                    
                    if( inst.id == 'modal_annual_start' ) {
                        this.selected_annual_info.annual_start = moment(selected_date).format('YYYYMMDD');

                        // 기간 최소치 1달
                        const target_date = moment(selected_date).add('month', 1).toDate();
                        const annual_end  = moment([this.selected_annual_info.annual_end.substring(0,4), this.selected_annual_info.annual_end.substring(4,6), this.selected_annual_info.annual_end.substring(6,8)].join('.')).toDate();

                        if( target_date.getTime() > annual_end.getTime() ) {
                            this.selected_annual_info.annual_end = moment(target_date).format('YYYYMMDD'); 
                        }
                    }

                    else if( inst.id == 'modal_annual_end' ) {
                        this.selected_annual_info.annual_end = moment(selected_date).format('YYYYMMDD');

                        // 기간 최소치 1달
                        const target_date = moment(selected_date).add('month', -1).toDate();
                        const annual_start  = moment([this.selected_annual_info.annual_start.substring(0,4), this.selected_annual_info.annual_start.substring(4,6), this.selected_annual_info.annual_start.substring(6,8)].join('.')).toDate();

                        if( target_date.getTime() < annual_start.getTime() ) {
                            this.selected_annual_info.annual_start = moment(target_date).format('YYYYMMDD'); 
                        }
                    }

                    this.processDateText();

                    if( this.is_add == true ) {
                        this.select_option_key = `${this.selected_annual_info.annual_start}-${this.selected_annual_info.annual_end}-add`;
                    }
                },
            }

            // @ts-ignore
            $('#modal_annual_start').datepicker(datepicker_option);

            // @ts-ignore
            $('#modal_annual_end').datepicker(datepicker_option);
        });
    }

    /**
     * 날짜 텍스트 가공
     */
    processDateText() : void {
        if( this.selected_annual_info == null ) {
            this.start_text = "";
            this.end_text   = "";
            return;
        }
        this.start_text = [this.selected_annual_info.annual_start.substring(0,4), this.selected_annual_info.annual_start.substring(4,6), this.selected_annual_info.annual_start.substring(6,8)].join('.');
        this.end_text = [this.selected_annual_info.annual_end.substring(0,4), this.selected_annual_info.annual_end.substring(4,6), this.selected_annual_info.annual_end.substring(6,8)].join('.');
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;

        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 연차 기간 선택
     */
    selectAnnualInfo(emp) : void {
        this.selected_annual_info = JSON.parse(JSON.stringify(emp));
        this.copy_annual_info = JSON.parse(JSON.stringify(this.selected_annual_info));
        this.select_option_key = `${this.selected_annual_info.annual_start}-${this.selected_annual_info.annual_end}`;
        this.annual_count = this.processDayCount(this.selected_annual_info.annual_count);
        this.before_annual_count = this.processDayCount(this.selected_annual_info.contents.before_annual_count);
        this.is_list = false;
        this.is_add = false;
        this.processDateText();
        this.datepickerInit();
    }

    /**
     * select - option 선택값 변경 함수
     */
    async selectChanged(event) : Promise<void> {
        const value = event.target.value;

        if( value == '' ) {
            this.close();
        }
        else {

            // 변경점 체크
            if( this.is_list == false && this.changeCheck() == true ) {
                return;
            }

            let emp = null;
            
            if( value == `${this.employee.annual_start}-${this.employee.annual_end}` ) {
                emp = JSON.parse(JSON.stringify(this.employee));
            }

            for( const past_emp of this.employee.past_emp_info ) {
                if( value == `${past_emp.annual_start}-${past_emp.annual_end}` ) {
                    emp = JSON.parse(JSON.stringify(past_emp));
                    break;
                }
            }

            if( emp == null ) {
                alert("연차 기간을 찾지 못했습니다");
                await this.getOrganizationEmp();
                this.select_option_key = '';
            }

            this.selectAnnualInfo(emp);
        }
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 호두웨어 사용 전 사용한 연차 수 항목 보이는지 여부
     */
    isExistBeforeAnnualCount() : boolean {
        if ( this.selected_annual_info == null ) return false;

        // 신규 생성시에는 무조건 안 보임
        if( this.is_add == true ) {
            return false;
        }

        // 수정중일때 아직 과거 연차기간이 없는 경우 무조건 보임
        if( this.employee.past_emp_info == null || this.employee.past_emp_info.length < 1 ) {
            return true;
        } 

        // 과거 연차 기간중 가장 과거의 항목에만 보임
        for( const past_emp of this.employee.past_emp_info ) {
            if( this.copy_annual_info.annual_start == past_emp.annual_start && this.copy_annual_info.annual_end == past_emp.annual_end ) {
                return this.employee.past_emp_info.indexOf(past_emp) == (this.employee.past_emp_info.length - 1);
            }
        }

        // 그 외 무조건 안 보임
        return false;
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_target = $(event.target);
        const defaultImage = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_target.parent().find('p.img').css("background-image", `url(${defaultImage})`);
    }

    /**
     * 새로운 연차기간 추가
     * 1. 가장 미래의 연차기간의 annual_count를 그대로 들고감
     * 2. 가장 미래의 연차기간의 annual_end + 1일을 start로 지정하고 end는 그 1년 후로 지정함
     */
    add() : void {
        if( this.employee == null ) return;

        let target_annual_end_date = this.yyyymmddToDate(this.employee.annual_end);
        let target_annual_count = this.employee.annual_count;
        for( const past_annual of this.employee.past_emp_info ) {
            
            if( target_annual_end_date.getTime() < this.yyyymmddToDate(past_annual.annual_end).getTime() ) {
                target_annual_end_date = this.yyyymmddToDate(past_annual.annual_end);
                target_annual_count = past_annual.annual_count;
            }
        }
        
        const new_annaul_start_date = moment(target_annual_end_date).set('date', moment(target_annual_end_date).get('date') + 1).toDate();
        const new_annaul_end_date = moment(target_annual_end_date).add('year', 1).toDate();

        this.selected_annual_info = JSON.parse(JSON.stringify({
            annual_count : target_annual_count,
            annual_start : this.hodu_date_to_format_string(new_annaul_start_date, 'YYYYMMDD'),
            annual_end : this.hodu_date_to_format_string(new_annaul_end_date, 'YYYYMMDD')
        }));
        this.copy_annual_info = JSON.parse(JSON.stringify(this.selected_annual_info));

        this.select_option_key = `${this.selected_annual_info.annual_start}-${this.selected_annual_info.annual_end}-add`;
        this.annual_count = this.processDayCount(this.selected_annual_info.annual_count);
        this.before_annual_count = '0';

        this.is_list = false;
        this.is_add = true;
        this.processDateText();
        this.datepickerInit();
    }

    /**
     * 수정중일땐 변경점이 있는지 체크후 confirm, 추가중일땐 입력한 사항이 있는지 체크후 confirm
     * 계속 진행 : return false;
     * 멈춤 : return true;
     */
    changeCheck() : boolean {

        if( this.is_add == true ) {

            // 입력사항 체크후 confirm
            if( this.copy_annual_info.annual_start != this.selected_annual_info.annual_start || 
                this.copy_annual_info.annual_end != this.selected_annual_info.annual_end ||
                this.annual_count == '' || isNaN(Number(this.annual_count)) == true || Number(this.annual_count) < 0 ||
                Number(this.copy_annual_info.annual_count) != Number(this.annual_count) ) {
                
                if( confirm("저장하지 않고 진행하면 입력한 내용이 사라집니다\n계속 진행하시겠습니까?") == false ) {
                    this.select_option_key = `${this.selected_annual_info.annual_start}-${this.selected_annual_info.annual_end}-add`;
                    return true;
                }

            }

        } 
        else {

            // 입력사항 체크후 confirm
            if( this.copy_annual_info.annual_start != this.selected_annual_info.annual_start || 
                this.copy_annual_info.annual_end != this.selected_annual_info.annual_end ||
                this.annual_count == '' || isNaN(Number(this.annual_count)) == true || Number(this.annual_count) < 0 ||
                Number(this.copy_annual_info.annual_count) != Number(this.annual_count) ||
                this.before_annual_count == '' || isNaN(Number(this.before_annual_count)) == true || Number(this.before_annual_count) < 0 ||
                Number(this.copy_annual_info.contents.before_annual_count) != Number(this.before_annual_count) ) {

                // 수정사항 체크 confirm
                if( confirm("저장하지 않고 진행하면 입력한 내용이 사라집니다\n계속 진행하시겠습니까?") == false ) {
                    this.select_option_key = `${this.copy_annual_info.annual_start}-${this.copy_annual_info.annual_end}`;
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        if( this.is_list == true ) {
            this.organization_off_modal_info.callback?.();
            this.doSetOrganizationOffModalInfo?.({ show_modal : false, user_id : 0 });
        }
        else {

            if( this.copy_annual_info == null ) {
                this.select_option_key = '';
                this.is_list = true;
                this.is_add = false;
                this.selected_annual_info = null;
            }

            else if( this.changeCheck() == false ) {
                this.select_option_key = '';
                this.is_list = true;
                this.is_add = false;
                this.selected_annual_info = null;
                this.copy_annual_info = null;
            }
            
        }
    }

    /**
     * 저장
     */
    async save() : Promise<void> {

        // 입력값 있는지 체크
        if( this.annual_count == '' || isNaN(Number(this.annual_count)) == true || Number(this.annual_count) < 0 ) {
            alert("연차수에는 양수인 숫자를 입력해주세요 (소수점 가능)");
            $('#yearOff').focus();
            return;
        }

        if( this.isExistBeforeAnnualCount() == true && ( this.before_annual_count == '' || isNaN(Number(this.before_annual_count)) == true ) || Number(this.annual_count) < 0 ) {
            alert("호두웨어 가입 전 사용 연차 수에는 양수인 숫자를 입력해주세요 (소수점 가능)");
            $('#before_yearOff').focus();
            return;
        }

        await this.getOrganizationEmp();

        // 새로운 연차 기간
        if( this.is_add == true ) { 
            // insert
            try {
                const data = JSON.parse(JSON.stringify(this.employee));
                data.annual_count = Number(this.annual_count);
                data.annual_start = this.selected_annual_info.annual_start;
                data.annual_end   = this.selected_annual_info.annual_end;

                delete(data.contents);

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=false`, API_METHOD.POST, data);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("직원 연차 기간 추가 중 오류 발생");
                }

                this.copy_annual_info = null;

                await this.getOrganizationEmp();
                this.close();

            } catch(exception) {

                const e = (exception as any);

                // 연차기간 중복 오류
                if( e.response != null && e.response.data != null ) {
                    if( e.response.data.data.error_type ==  "daterange_overlap" ) {
                        alert("직원 연차 기간 추가 중 오류 발생\n기존에 설정한 연차 기간과 중복됩니다");
                    }
                }
                // 일반적인 오류
                else {
                    alert("직원 연차 기간 추가 중 오류 발생");
                    this.hodu_error_process(e, false, false, true);
                }
            }        
        }

        // 기존 연차 기간 수정
        else {
            // update
            try {
                let data : any = null;
                
                if( this.employee.annual_start == this.copy_annual_info.annual_start && this.employee.annual_end == this.copy_annual_info.annual_end ) {
                    data = JSON.parse(JSON.stringify(this.employee));
                }

                if( this.employee.past_emp_info != null && this.employee.past_emp_info.length > 0 ) {
                    for( const past_emp of this.employee.past_emp_info ) {
                        if( past_emp.annual_start == this.copy_annual_info.annual_start && past_emp.annual_end == this.copy_annual_info.annual_end ) {
                            data = JSON.parse(JSON.stringify(past_emp));
                            break;
                        }
                    }
                }

                if( data == null ) {
                    alert("수정 할 연차 정보를 찾을 수 없습니다");
                    await this.getOrganizationEmp();
                    this.close();
                    return;
                }

                data.group_id     = this.scope_group_id;
                data.user_id      = this.employee.user_id;
                data.annual_count = Number(this.annual_count);
                data.annual_start = this.selected_annual_info.annual_start;
                data.annual_end   = this.selected_annual_info.annual_end;

                if( this.isExistBeforeAnnualCount() == true ) {
                    data.contents.before_annual_count = this.before_annual_count.indexOf('.') > -1 ? Number(this.before_annual_count) : Number(`${this.before_annual_count}.00`).toFixed(2);
                }

                data.before_annual_start = this.copy_annual_info.annual_start;
                data.before_annual_end = this.copy_annual_info.annual_end; 

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=true`, API_METHOD.POST, data);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("직원 연차 수정 중 오류 발생");
                }

                this.copy_annual_info = null;
                
                await this.getOrganizationEmp();
                this.close();

            } catch(exception) {

                const e = (exception as any);
                
                // 연차기간 중복 오류
                if( e.response != null && e.response.data != null ) {
                    if( e.response.data.data.error_type ==  "daterange_overlap" ) {
                        alert("직원 연차 수정 중 오류 발생\n기존에 설정한 연차 기간과 중복됩니다");
                    }
                }
                // 일반적인 오류
                else {
                    alert("직원 연차 수정 중 오류 발생");
                    this.hodu_error_process(e, false, false, true);
                }
            }
        }

        // if( this.organization_off_modal_info.is_only_setting_term == false ) {
        //     if( this.employee.annual_count == null ) {
        //         alert("연차수를 입력해주세요")
        //         return;
        //     }

        //     if( new RegExp(/[0-9]{1,}/).test(this.employee.annual_count) == false || new RegExp(/\.+/).test(this.employee.annual_count) == true || new RegExp(/-+/).test(this.employee.annual_count) == true ) {
        //         alert("연차수에는 정수인 숫자만 입력해주세요")
        //         return;
        //     }
        // }
        
        // // TODO 나중에는 연차 기간을 잘못 설정한경우 수정할 방법이 생겨야함
        // // TODO 기간이 이전 기간과 겹치는지 검사하고 겹치면 안된다고 알려줄것
        // // if(  ) {
        // //     alert(`이전 기간과 겹치면 안됩니다\n이전 기간 : 2021.01.01 ~ 2021.12.31`);
        // //     return;
        // // }

        // try {

        //     const copy_employee = JSON.parse(JSON.stringify(this.employee));
            
        //     // 정보 최신화
        //     await this.getOrganizationEmp();

        //     // API 실행 - 한명 추가 or 수정
        //     if( !(this.organization_off_modal_info.user_ids != null && this.organization_off_modal_info.user_ids.length > 0) ) {
        //         const data = JSON.parse(JSON.stringify(this.employee));
        //         data.annual_start = copy_employee.annual_start
        //         data.annual_end   = copy_employee.annual_end
        //         data.annual_count = Number(copy_employee.annual_count); 

        //         const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=true`, API_METHOD.POST, data);

        //         if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //             throw new Error("조직도 직원 근무 변경 중 오류 발생");
        //         }
        //     }
            
        //     // API 실행 - 여러명 추가 or 수정
        //     else {

        //         const promise_array : Promise<any>[] = [];

        //         // 병렬처리중 하나라도 에러가 존재했음을 알리는 플래그
        //         let error_count : number = 0;
        //         let error_message : string = "";

        //         // user_ids 로 대상 찾기
        //         for( const user_id of this.organization_off_modal_info.user_ids ) {
        //             const target = this.employees.filter(employee => user_id == employee.user_id);

        //             let employee : any = null;

        //             if( target.length < 1 ) {
        //                 employee = {
        //                     "group_id" : this.scope_group_id, 
        //                     "user_id" : user_id, 
        //                     "user_rev" : 1, 
        //                     "dept_id" : null, 
        //                     "pos_id" : null, 
        //                     "start_date" : null, 
        //                     "end_date" : null, 
        //                     "annual_start" : copy_employee.annual_start, 
        //                     "annual_end" : copy_employee.annual_end, 
        //                     "annual_count" : Number(copy_employee.annual_count), 
        //                     "work_type_id" : null,  
        //                     "is_approver" : false
        //                 }
        //             }
        //             else {
        //                 employee = target[0]; 
        //                 employee.annual_start = copy_employee.annual_start;
        //                 employee.annual_end   = copy_employee.annual_end;
        //                 if( this.organization_off_modal_info.is_only_setting_term == false ) {
        //                     employee.annual_count = Number(copy_employee.annual_count);
        //                 }
        //             }

        //             const promise = this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=true`, API_METHOD.POST, employee).catch((e) => {
        //                 if( e.response != null && e.response.data != null ) {
        //                     if( e.response.data.data.error_type ==  "daterange_overlap" ) {
        //                         error_count++;
        //                     }
        //                 }
        //             });

        //             promise_array.push(promise);
        //         }

        //         Promise.all(promise_array).then(() => {

        //             console.log(error_count);

        //             // 에러 메시지 보여주기
        //             if( error_count > 0 ) {
        //                 alert(`연차 기간이 중복된 ${error_count}명의 직원의 연차 설정은 수정 되지 않았습니다`);
        //             }
        //         });
        //     }

        // } catch(e) {
        //     // 연차 단일 설정 중 기간 중복 오류가 발생한 경우
        //     if( e.response != null && e.response.data != null ) {
        //         if( e.response.data.data.error_type ==  "daterange_overlap" ) {
        //             alert("직원 연차 설정 중 오류 발생\n기존에 설정한 연차 기간과 중복됩니다");
        //         }
        //     }
        //     // 일반적인 오류
        //     else {
        //         alert("직원 연차 설정 중 오류 발생");
        //         this.hodu_error_process(e, false, false, true);
        //     }
        // }
    }

    /**
     * 연차 정보 삭제
     */
    async remove(past_emp) : Promise<void> {

        if( confirm(`정말로 직원 연차를 삭제하시겠습니까?`) == false ) {
            return;
        }

        await this.getOrganizationEmp();

        if( this.employee.past_emp_info.length < 2 ) {
            alert("직원 연차 정보는 반드시 하나 이상 존재해야합니다");
            return;
        }

        try {

            let data : any = JSON.parse(JSON.stringify(this.employee));

            data.group_id     = this.scope_group_id;
            data.user_id      = this.employee.user_id;
            data.annual_count = past_emp.annual_count;
            data.annual_start = past_emp.annual_start;
            data.annual_end   = past_emp.annual_end;
            data.is_delete    = true;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp?is_annual=true&is_update=true`, API_METHOD.POST, data);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("직원 연차 수정 중 오류 발생");
            }

        } catch(e) {
            alert("직원 연차 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        } finally {
            await this.getOrganizationEmp();
        }
 
    }

}
</script>

<style scoped>
    /* #organization_off_modal { background:#fff;max-width:500px;height: 440px;margin: 0 auto;left: 0;right: 0;margin-top: -220px; top: 50%;}
    .modal { position:absolute; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}

    #organization_off_modal.modal.onlyTerm { height: 300px; margin-top: -150px; }
    #organization_off_modal.modal.onlyTerm .modal_content { height : 140px !important; }
    #organization_off_modal.modal.onlyTerm .bottom_content { display : none; }

    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}

    .modal .btns {border-top:1px solid #eaebec; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;float: left;width: 100%;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; background:#fff; font-weight:bold; }
    .modal .btns input:hover  { background:#f1f3f5; }

    #organization_off_modal .modal_content { position: relative; height: 280px !important; box-sizing: border-box; }
    #organization_off_modal .modal_content > div { box-sizing: border-box; padding:20px 40px; float : left; height: 140px; }
    #organization_off_modal .modal_content > div > h2 { font-size:14px; margin-bottom:10px; padding-left : 10px; height:30px; line-height:30px; }
    #organization_off_modal .modal_content > div > input { transition:0.2s; line-height:55px; height:55px; padding: 0 15px; border: 1px solid #e7e9ea; width:100%; box-sizing: border-box; font-size:17px; font-weight:bold; } 
    #organization_off_modal .modal_content > div > input:hover { border:1px solid #d5dbde; background:#f1f3f5; }
    #organization_off_modal .modal_content > div > input:focus { border:1px solid #477fff; background:#fff !important; }
    #organization_off_modal .modal_content > div > input.wrong { border:1px solid #ff6060; background:#fff0f0; color:#ff6060; }
    #organization_off_modal .modal_content > div > input.wrong::placeholder { color:#ff6060; }

    #organization_off_modal .modal_content > div > input[type="button"] { background: #fff; }
    #organization_off_modal .modal_content > div > input[type="number"] { text-align: right; }
    
    #organization_off_modal .modal_content div.top_left_content { width : 50%; padding-right: 20px; padding-bottom: 10px; }
    #organization_off_modal .modal_content div.top_right_content { width : 50%; padding-left: 20px; padding-bottom: 10px; }
    #organization_off_modal .modal_content div.bottom_content { width : 100%; padding-top : 10px; } */

    /* Chrome, Safari, Edge, Opera */
    /* input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; } */

    /* Firefox */
    /* input[type=number] { -moz-appearance: textfield; } */

    .modal { position:absolute; left:50%; top:50%; margin-top:-243px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:486px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); box-sizing: border-box;}
    .modal h1 { text-align: left; font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; width: 100%; border-bottom:1px solid #e7e9ea; box-sizing: border-box }
    .modal .detail { overflow:hidden; }

    .modal .context { float:left; border-left:1px solid #e7e9ea; width: 60%; height:324px; box-sizing: border-box; }
    
    .modal .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px; width: 100% }
    .modal .btns input {  line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold; }
    .modal .btns input.save:hover { color:#477fff; }
    .modal .btns input:hover { background:#f1f3f5; }

    .modal textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 113px; max-height: 113px; font-size: 13px;line-height: 20px; padding: 15px; height:auto; box-sizing: border-box; font-weight:bold; }
    .modal textarea:hover { border-color:#477fff }
    .modal textarea:focus { background:#fff; border-color:#477fff;outline: none;  }
    
    #organization_off_modal { width: 800px; margin-left: -400px; }
    #organization_off_modal .titleBox { position: relative; text-align: center; }
    #organization_off_modal h1 { display: inline-block; }
    #organization_off_modal h1 .icon { float: left; margin: 34px 15px 0 0; display: inline-block;  width: 27px; height: 27px; border-radius: 50%;background-repeat: no-repeat; background-position: center center;  }

    #organization_off_modal h1.approved:after { background-color: #477fff; }
    #organization_off_modal h1.rejected:after { background-color: #ff6363 }
    #organization_off_modal h1.ing .icon {background-color: #ffbc49;border: 1px solid #ffa70e;}
    #organization_off_modal h1.rejected .icon {background-color: #ff6363;border: 1px solid #ff2e2e; background-image: url(../../../assets/images/contents/ic_close_fff.gif); background-size: 15px;}
    #organization_off_modal h1.approved .icon {background-color: #477fff;border: 1px solid #0b55ff;background-image: url('../../../assets/images/contents/checked_fff.png');}
    #organization_off_modal .btns.b1 input { width : 100% }

    #organization_off_modal .context { overflow: hidden; float: left; width:60%;    overflow-y: auto; }
    #organization_off_modal .leftContent { float:left; width: 40%; background:#f1f3f5; text-align: center; height: 324px; box-sizing: border-box; padding: 0 40px }
    #organization_off_modal .leftContent p { width: 100%; font-weight: bold; line-height: 25px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
    #organization_off_modal .leftContent p.name { font-size: 18px; margin: 16px 0 10px; }
    #organization_off_modal .leftContent p.pos { margin-top:-8px; font-size: 14px; }
    #organization_off_modal .leftContent p.team { padding-bottom: 10px; border-bottom: 1px solid #fff; margin-bottom: 10px; }
    #organization_off_modal .leftContent p.email, #organization_off_modal .leftContent p.tel {color:#989bab}
    #organization_off_modal .leftContent .img { margin:0 auto; margin-top:29px; width: 90px; height: 90px; border-radius: 50%; background: url(../../../assets/images/contents/ic_approval_user_on.png) no-repeat center center; background-size: cover; box-shadow: 0 5px 10px rgba(0,0,0,0.1); border:7px solid #fff; font-size: 0; }
    #organization_off_modal .detailUl { width: 100%; box-sizing: border-box; }
    #organization_off_modal .detailUl li { transition: 0.2s; overflow: hidden; line-height: 53px; height: 53px; font-size: 14px; font-weight: bold; border-bottom: 1px solid #f1f3f5; box-sizing: border-box; padding: 0 30px; width:100%; }
    #organization_off_modal .detailUl li .descript { float: right; font-weight: bold; font-size: 13px; text-align: right; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #organization_off_modal .detailUl li.reason {height: 156px; padding-right:0;}
    #organization_off_modal .detailUl li.reason .descript {  background:transparent; padding-right: 30px !important; width: 100%; float: left; text-align: left; line-height: 20px; margin-top: -10px;overflow:auto; text-overflow: unset; white-space: normal; }
    #organization_off_modal .detailUl li.reason .descript:disabled { border:0 none; padding: 0; color:#232848 }
    #organization_off_modal .detailUl li.reason.disabled { font-size: 0; background:#f1f3f5;}
    #organization_off_modal .detailUl li.reason.disabled  .descript:disabled { font-size: 0; background:transparent; color:#f1f3f5 !important }
    #organization_off_modal .detailUl li:last-child { border-bottom: 0 none; }
    #organization_off_modal .detailUl li * {  display: inline-block; }
    #organization_off_modal .detailUl li:hover * {  }
    /* #organization_off_modal .detailUl li:hover { background: #f7f9fb} */

    #organization_off_modal .dropdown { display: none !important; width: 157px; height: auto !important; right: 30px;top: 192px;left: auto;}
    #organization_off_modal .dropdown.on { display: block !important; } 
    #organization_off_modal .dropdown ul { float: left; }
    #organization_off_modal .dropdown ul li { transition: 0.1s; float: left; box-sizing: border-box; padding: 0 0 !important; height: 45px !important; line-height: 45px !important; }
    #organization_off_modal .dropdown ul li:hover { background:#f1f3f5; }
    #organization_off_modal .dropdown ul li a { display: inline-block; width: 100%; box-sizing: border-box; }

    .unchecked { cursor: pointer; }
    .unchecked span { background: #ffbc49;border: 1px solid #ffa70e;display: inline-block;width: 20px;height: 20px;line-height: 20px;border-radius: 50%;text-align: center;font-weight: bold;margin-right: 10px; }
    .ampm { margin-right: 10px; }
    select { cursor: pointer;-webkit-appearance: none; box-sizing: border-box;height: 51px; line-height: 51px; padding-left: 10px; -webkit-appearance: none; width: auto; text-align: left;font-weight: bold; height: 30px; line-height: 30px; }
    .timeLi select { padding: 0 2px; }
    select:focus { background: none; }

    #organization_off_modal.notRejected .detailUl li { height: 62px; line-height: 62px; }

    img.dummy { display : none; }

    /* 연차 현황 */
    p.title {font-weight: normal;}
    #organization_off_modal .detailUl li {height: 54px; line-height: 54px; font-size: 12px; }
    /* select */
    select { cursor: pointer; box-sizing: border-box; width: 100%; height: 40px; line-height: 40px; text-align-last: left; font-weight: bold; -webkit-appearance:none; /* for chrome */ -moz-appearance:none; /*for firefox*/ appearance:none;  padding: 0; border: 1px solid #fff; margin-top: -3px; font-size: 12px; }
    #organization_off_modal .detailUl li:hover select { font-weight: bold; border: 1px solid #000; border-radius: 5px; padding: 0 15px 0 15px; } 
    #organization_off_modal .detailUl li:first-child select { width: 80%; border: 0 none; padding: 0 30px; font-size: 14px; background: url('../../../assets/images/side_area/bt_open.png') no-repeat 94% center; background-size: 28px 28px;} 
    #organization_off_modal .detailUl li:first-child select:focus { background-image: url('../../../assets/images/side_area/bt_close.png');} 

    #organization_off_modal .detailUl li:first-child { padding: 0  }

    /* 추가하기 */
    #organization_off_modal .detailUl .newYear_add {width: 20%; box-sizing: border-box; line-height: 54px; border: 1px solid #f1f3f5;}
    #organization_off_modal .detailUl .newYear_add .add_icon {width: 100%; vertical-align: top;}
    #organization_off_modal .detailUl .newYear_add .add_icon a {display: block; font-size: 0; background: url('../../../assets/images/contents/ic_square_plus.svg') no-repeat center center; background-size: 26px 26px; }
    #organization_off_modal .detailUl .newYear_add .add_icon a:hover {background: url('../../../assets/images/contents/ic_square_plus(on).svg') no-repeat center center; background-size: 26px 26px;}
    
    /* 시작일 */
    .modal .detailUl input {  width: 30%; text-align: right; margin-top: 18px; background:#fff; float: right; font-size:14px; font-weight: normal; font-size: 12px; }
    .detailUl li:hover input {font-weight: bold; border: 1px solid; border-radius: 5px; padding: 10px 20px; margin-top: 5px; text-align: center; }

    /* 연차 수 */
    .detailUl li:hover .yearOff {background: #f1f3f5;  border: none; padding: 13px 10px; margin-top: 5px; text-align: right; }
    .modal .detailUl .yearOff {width: 230px; font-weight: normal; font-size: 11px; }
    .modal .detailUl input[type=number]:focus {background: #f1f3f5; padding: 13px 10px; margin-top: 5px; }
    .detailUl li .descript .year_count {font-weight: normal;}

    /* 사용 연차 수 */
    .yearuseLi .yearuseLi_text {font-weight: normal;}
    .yearuseLi_text span {color: #477fff; font-size : 11px; }

    /* 전체보기 (3년) */
    .all_year:hover { background: rgb(248,249,250, 0.3); }
    .all_year p {width: 50%; box-sizing: border-box; font-weight: normal; transition: .1s; }
    .all_year:hover p {font-weight: bold;}
    .all_year p a { width: 100%; display: block;}
    .all_year p.first_year_count {text-align: right; }
    .all_year p.first_year_count span {color: #477fff; }
    .all_year p.first_year_count span.join_before {color: #232848; opacity: .5; }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    #organization_off_modal .content .detail .context .detailUl .all_year:hover p { width: 45%; }
    #organization_off_modal .content .detail .context .detailUl .all_year .remove { display: none; color : #ff6363; width : 10%; text-align: right; }
    #organization_off_modal .content .detail .context .detailUl .all_year:hover .remove { display: inline-block; }

</style>