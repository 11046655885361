<template>
    <div id="appointment_list_modal" class="modal">
        <div class="titleBox">
            <h1>
                {{ appointment_list_modal_info.events.length > 0 
                        ? `${hodu_date_to_format_string(appointment_list_modal_info.events[0].event_data.schedule_date.start, 'YYYY년 MM월 DD일')} ${getDayOfWeekByDate(appointment_list_modal_info.events[0].event_data.schedule_date.start, '요일')}` 
                        : '' }}
            </h1>
            <!-- <h1>2020년 4월 17일 금요일</h1> -->
            <div class="total">
                <p v-if="appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'NOSHOW').length > 0">
                    노쇼
                    <span>{{ appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'NOSHOW').length }}</span>
                </p>

                <p v-if="appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'DIAGNOSIS').length > 0">
                    진료
                    <span>{{ appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'DIAGNOSIS').length }}</span>
                </p>

                <p v-if="appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'CONFIRM').length > 0">
                    확정
                    <span>{{ appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'CONFIRM').length }}</span>
                </p>

                <p v-if="appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'RECEIPT').length > 0">
                    접수
                    <span>{{ appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'RECEIPT').length }}</span>
                </p>

                <p v-if="appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'REQUEST').length > 0">
                    요청
                    <span>{{ appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'REQUEST').length }}</span>
                </p>

                <p v-if="appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'DECLINE').length > 0">
                    거절
                    <span>{{ appointment_list_modal_info.events.filter(item => item.event_data.appointment != null && item.event_data.appointment.appointment_status == 'DECLINE').length }}</span>
                </p>

                <!-- <p>노쇼 <span>1</span></p>
                <p>진료 <span>1</span></p>
                <p>확정 <span>1</span></p>
                <p>접수 <span>1</span></p>
                <p>요청 <span>1</span></p>
                <p>거절 <span>1</span></p> -->
            </div>
            <!-- <select name="" id="">
                <option value="">모든 의사</option>
                <option value="">김동현</option>
                <option value="">이연지</option>
            </select> -->
        </div>
        <div class="content">

            <ul class="sortHeader" id="">
                <li class="num">No</li>
                <li class="stage"><span>전체</span>
                </li>
                <!-- <li class="stage headerSort"><a class="sort">전체</a>
                    <div class="dropdown">
                        <ul>
                            <li>
                                <p class="all"><input type="checkbox" id="stageAll" />
                                <label for="stageAll"><span>요청</span></label></p>
                            </li>
                            <li>
                                <p class="stage1"><input type="checkbox" id="stage1" />
                                <label for="stage1"><span>접수</span></label></p>
                            </li>
                            <li>
                                <p class="stage2"><input type="checkbox" id="stage2" />
                                <label for="stage2"><span>확정</span></label></p>
                            </li>
                            <li>
                                <p class="stage3"><input type="checkbox" id="stage3" />
                                <label for="stage3"><span>진료</span></label></p>
                            </li>
                            <li>
                                <p class="stage4"><input type="checkbox" id="stage4" />
                                <label for="stage4"><span>거절</span></label></p>
                            </li>
                            <li>
                                <p class="stage5"><input type="checkbox" id="stage5" />
                                <label for="stage5"><span>노쇼</span></label></p>
                            </li>
                        </ul>
                    </div>
                </li> -->
                <li class="time" ><span>예약시간</span></li>
                <li class="name"><span>환자명</span></li>
                <li class="tel"><span>연락처</span></li>
                <li class="docName"><span>모든 의사</span>
                <!-- <li class="time headerSort sortOff" ><span>예약시간</span></li>
                <li class="name headerSort sortOff"><span>환자명</span></li>
                <li class="tel"><span>연락처</span></li>
                <li class="docName"><a class="sort">모든 의사</a> -->
                    <div class="dropdown">
                        <ul>
                            <li>
                                <p class="all"><input type="checkbox" id="docAll" />
                                <label for="docAll"><span>모든 의사</span></label></p>
                            </li>
                            <li>
                                <p class="doc1"><input type="checkbox" id="doc1" />
                                <label for="doc1"><span>김동현</span></label></p>
                            </li>
                            <li>
                                <p class="doc2"><input type="checkbox" id="doc2" />
                                <label for="doc2"><span>이연지</span></label></p>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

            <div id="modal_appointment_list_scroll" class="scroll">
                <ul class="calUl">

                    <li :key="event.event_id" v-for="(event, index) in appointment_list_modal_info.events"
                        :class="`${getStatusClassOrText(event.event_data.appointment, true)}List`">
                        
                        <a @click="clickAppointmentList(event)">
                            <span class="num">{{ index + 1 }}</span>
                            <p class="stage">
                                <span :class="getStatusClassOrText(event.event_data.appointment, true)">{{ getStatusClassOrText(event.event_data.appointment) }}</span>
                            </p>
                            <p class="time">
                                {{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(event.event_data.schedule_date.start, 'hh:mm a')) }}
                            </p>
                            <p class="name">{{ event.event_data.appointment != null ? event.event_data.appointment.patient_name : '' }}</p>
                            <p class="tel">
                                {{ event.event_data.appointment != null && event.event_data.appointment.nok_phone_number && event.event_data.appointment.nok_phone_number.length > 0 ? event.event_data.appointment.nok_phone_number : '-' }}
                            </p>
                            <p class="docName">{{ event.event_data.appointment != null ? event.event_data.appointment.doctor_name : '' }}</p>
                        </a>

                    </li>

                    <!-- <li class="noshowList">
                        <span class="num">1</span>                                                                                                                                                                                    
                        <p class="stage"><span class="noshow">노쇼</span></p>
                        <p class="time">오후  3:40</p>
                        <p class="name">박상현</p>
                        <p class="tel">010-1010-1010</p>
                        <p class="docName">김동현</p>
                    </li>
                    <li>
                        <span class="num">2</span>  
                        <p class="stage"><span class="seen">진료</span></p>
                        <p class="time">오후  3:50</p>
                        <p class="name">금연아</p>
                        <p class="tel">010-1010-1010</p>
                        <p class="docName">김동현</p>
                        <a class="goBtn">자세히보기</a>
                    </li>
                    <li>
                        <span class="num">3</span>  
                        <p class="stage"><span class="confirmed">확정</span></p>
                        <p class="time">오후  4:00</p>
                        <p class="name">Josephine Langford</p>
                        <p class="tel">010-1010-1010</p>
                        <p class="docName">김동현</p>
                        <a class="goBtn">자세히보기</a>
                    </li>
                    <li>
                        <span class="num">4</span>  
                        <p class="stage"><span class="made">접수</span></p>
                        <p class="time">오후  4:10</p>
                        <p class="name">김이정</p>
                        <p class="tel">010-2020-2030</p>
                        <p class="docName">김동현</p>
                        <a  @click.prevent="moveAppointmentDetail" class="goBtn">자세히보기</a>
                    </li>
                    <li class="ingList">
                        <span class="num">5</span>  
                        <p class="stage"><span class="ing">요청</span></p>
                        <p class="time">오후  4:20</p>
                        <p class="name">임주형</p>
                        <p class="tel">010-1010-1010</p>
                        <p class="docName">김동현</p>
                        <div class="ingBtns">
                            <input type="button" class="reject" value="거절"><input type="button" class="accept" value="승인">
                        </div>
                    </li>
                    <li class="rejectList">
                        <span class="num">6</span>  
                        <p class="stage"><span class="reject">거절</span></p>
                        <p class="time">오후  4:50</p>
                        <p class="name">박진우</p>
                        <p class="tel">010-1010-1010</p>
                        <p class="docName">김동현</p>
                        <div class="ingBtns">
                            <input type="button" class="reject" value="삭제"><input type="button" class="accept" value="승인">
                        </div>
                    </li> -->
                </ul>
            </div>
            <div class="btns">
                <input type="button" value="취소" @click="closeModal"/>
                <input type="button" class="save" value="자세히 보기" @click="moveAppointmentList"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');
const ModalInfo = namespace('ModalInfo');

import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';
import { t_event_hodu_doc, t_event } from '@/model/event';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class AppointmentListModal extends Mixins(VueHoduCommon) {

    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State appointment_list_modal_info !: hodu_doc_modal_info.AppointmentListModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentListModalInfo ?: (params : hodu_doc_modal_info.AppointmentListModalInfo) => void;

    mounted() : void {
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        // @ts-ignore
        $('#modal_appointment_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
        });
        
    }

    /**
     * 스테이터스에 따른 텍스트 반환
     */
    getStatusClassOrText(appointment : t_event_hodu_doc | undefined, is_get_class : boolean = false) : string {

        if( appointment == null ) return '';

        switch( appointment.appointment_status ) {
            case hodu_doc_enum.appointment_status_code.REQUEST:   return is_get_class ? "ing"       : "요청";
            case hodu_doc_enum.appointment_status_code.DECLINE:   return is_get_class ? "reject"    : "거절";
            case hodu_doc_enum.appointment_status_code.CONFIRM:   return is_get_class ? "confirmed" : "확정";
            case hodu_doc_enum.appointment_status_code.RECEIPT:   return is_get_class ? "made"      : "접수";
            case hodu_doc_enum.appointment_status_code.DIAGNOSIS: return is_get_class ? "seen"      : "진료";
            case hodu_doc_enum.appointment_status_code.NOSHOW:    return is_get_class ? "noshow"    : "노쇼";
            default: return "";
        }
        
    }

    /**
     * 리스트를 클릭한 경우
     */
    clickAppointmentList(event : t_event) : void {
        
        if( event.event_data.appointment == null ) { return; }

        const status : hodu_doc_enum.appointment_status_code = event.event_data.appointment.appointment_status;

        // 요청, 거절, 확정은 AppointmentList.vue
        if( status == hodu_doc_enum.appointment_status_code.REQUEST || status == hodu_doc_enum.appointment_status_code.DECLINE || status == hodu_doc_enum.appointment_status_code.CONFIRM ) {
            this.moveAppointmentList();
            return;
        }

        // 노쇼, 접수, 진료는 Appointment.vue
        if( this.doSetAppointmentDetailInfo ) {
            this.doSetAppointmentDetailInfo({ "is_patient" : false, "event" : event });
            this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event.event_id}`);
            this.closeModal();
        }
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetAppointmentListModalInfo ) { this.doSetAppointmentListModalInfo({ "show_modal" : false, "events" : [] }); }
    }
    
    // moveAppointmentDetail() : void {

    //     if( !this.doSetAppointmentDetailInfo ) { return; }

    //     this.doSetAppointmentDetailInfo({
    //         event : null, // 이벤트
    //         is_patient : false,
    //     });

    //     this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/event_id`);
    //     this.closeModal();
    // }

    /**
     * 예약 리스트로 이동
     */
    moveAppointmentList() : void {
        
        if( this.appointment_list_modal_info.events.length < 1 ) { return; }

        const lo_event : t_event = this.appointment_list_modal_info.events[0];

        this.hodu_router_push(`/hospital/${new Date(lo_event.event_data.schedule_date.start).getTime()}/appointment?criteria=${hodu_doc_enum.appointment_select_criteria.DAILY}`);
        this.closeModal();
    }

    /**
     * 리사이즈 감지 - 브라우저 최소 크기 1024 * 768 제한? 
     */
    handleResize() : void {

        // @ts-ignore
        $('#modal_appointment_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-256px; margin-left:-450px; width:100%; border-radius:5px; max-width: 900px;  height:512px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    .sortHeader { height:55px; line-height: 55px;font-size: 12px !important; }
    .sortHeader a.sort {font-size: 12px !important;  }
    .headerSort.sortOff:hover, .headerSort.sortUp:hover, .headerSort.sortOff, .headerSort.sortUp { background-position: right 10px; }
    
     h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #f1f3f5 }
    .titleBox { position:relative;  }
    .titleBox select { position: absolute; right:30px; top:35px; height: 30px; line-height:28px; max-width: 150px; overflow:hidden; text-overflow:ellipsis; white-space: nowrap; font-weight: bold; padding: 0 35px 0 13px; border-radius: 20px;border:1px solid #232848; color:#232848; appearance: none; cursor:pointer; }
    .titleBox select { background:  url('../../../assets/images/side_area/bt_open.png') no-repeat 90% center; }
    .titleBox select:focus {border-color:#477fff; background: url('../../../assets/images/side_area/bt_close.png') no-repeat 90% center; }
    .total { position:absolute;right: 30px; top:40px; text-align:center; font-size: 13px;font-weight:bold; border-radius: 5px; padding: 0 10px; height:30px; line-height:28px; margin-left:12px; text-align:center; box-sizing: border-box;}
    .total p { display: inline-block; margin-left:10px; line-height: 20px; height:20px; border-right:1px solid #e7e9ea; padding-right:10px; }
    .total p:last-child { padding-right:0;border-right:none; }
    .total p span { padding-left: 4px; }

    .num { width:90px !important;text-align: center;font-weight:bold; }
    .stage {float:left; width: 90px; }
    .time { width: 120px !important; }
    .name, .docName { width: 190px !important; }
    .tel { width: 190px !important; }

    .calUl li span.num { margin-top:0; float:left; border:none; background: none; padding:0; width:80px; text-align: center; line-height: 50px; height: 50px;  }
    .calUl li { overflow:hidden; transition:0.2s; position:relative; width:100%; height: 50px; line-height: 50px; font-weight:bold; border-bottom:1px solid #f1f3f5; box-sizing: border-box;  }
    .calUl li span { margin-top:14px; display: inline-block; padding: 0 10px; border-radius: 20px; padding:0 12px;background:#f1f3f5; height: 20px; line-height: 18px; border:1px solid; box-sizing: border-box; }
    .calUl li p {  float:left; display:inline-block; }
    .calUl li p.time { box-sizing: border-box; }
    .calUl li p.name, .calUl li p.docName { padding-right: 20px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;  }
    .calUl li p.tel { padding-right: 20px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .calUl li a { position:absolute; transition:0.2s;display: inline-block; width:100%; height:100%; box-sizing: border-box; }
    .calUl li:hover { background:#f7f9fb; }
    .btns { overflow: hidden; border-top:1px solid #f1f3f5;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }

    .noshowList, .rejectList { color:#ff6060; }
    .ingList p { opacity:0.5 }
    .rejectList p { opacity:0.5 }

    .ingList:hover p { opacity:1 }
    .rejectList:hover p { opacity:1 }

    li .ingBtns { transition:0.2s; opacity:0; }
    .rejectList:hover,.noshowList:hover {background:#fff7f7 !important}
    .rejectList:hover .ingBtns, .ingList:hover .ingBtns { opacity:1; }

    .ingBtns { position:absolute; right:30px; top:-2px; }
    .ingBtns input { transition:0.2s; height: 28px; line-height:27px; margin: 0 5px; padding:0 15px;border-radius: 20px; font-weight:bold;font-size: 12px; background:#fff; border: 1px solid #dadde0; box-sizing: border-box; }
    .ingBtns input.reject:hover { background:#ff6060; color:#fff; border-color:#ff6060 }
    .ingBtns input.accept:hover { background:#477fff; color:#fff; border-color:#477fff }
    
    .scroll { height: 350px; }

    .calUl li .seper { border:none; background:#e7e9ea; width:1px !important; height: 20px; padding:0; }

    .calUl li a.goBtn { transition:0.2s; opacity: 0; position: absolute; font-size: 0; display: block; width:30px; height:30px;right:30px;top:10px; background-color:#477fff; border-radius: 50%; box-sizing: border-box;background-image: url('../../../assets/images/contents/month_bt_next_fff.svg'); background-size: 26px; background-repeat: no-repeat; background-position: center center;}
    .calUl li:hover a.goBtn { opacity:1; }
    .calUl li a.goBtn:hover {  background-color:#185eff}

    .calUl li span.noshow { background: #ff6363;color:#fff; border-color:#ff4949 }
    .calUl li span.seen { background: #477fff;color:#fff;border-color:#1c61ff }
    .calUl li span.ing { background: #f1f3f5;color:#747e90; border-color: #dadde0; }
    .calUl li span.made { background: #FFC72F;color:#39445d; border-color: #f1b71a;}
    .calUl li span.confirmed { background: #13D08B;color:#fff; border-color:#17bf82}
    .calUl li span.reject { background: #fff0f0;color:#ff6363; border-color:#ffd7d7}
</style>