<template>
    <div id="" class="section_scroll">
        
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <input type="button" id="" class="btnAdd docBtn" placeholder="" value="+ 의사 등록" title="의사 등록" @click="moveDoctorCreate(-1)"/>

        <div id="" class="content">
            <!-- 헤더소트 -->
                <ul class="sortHeader" id="">
                    <li class="num">No</li>
                    <li class="specialityHead"><a class="sort" @click="departmentFilterOnOff">전체</a>
                        <div class="dropdown" :class="{ on : department_filter_open == true }">
                            <ul>
                                <li @click.prevent="departmentFilterAll($event)">
                                    <span class="all"><input type="checkbox" id="docAll" :checked="department_filter.length < 1"/>
                                    <label for="docAll"><span>진료과 전체</span></label></span>
                                </li>

                                <li :key="department.department_code" v-for="(department, index) in computedDepartmentList" @click.prevent="departmentFilterCheckOnOff($event, department.department_code)">
                                    <span><input type="checkbox" :id="`department_${index}`" :checked="department_filter.indexOf(department.department_code) < 0"/>
                                    <label :for="`department_${index}`"><span>{{ department.department_name }}</span></label></span>
                                </li>

                                <!-- 
                                <li>
                                    <span class="doc1"><input type="checkbox" id="doc1" />
                                    <label for="doc1"><span>내과</span></label></span>
                                </li>
                                <li>
                                    <span class="doc2"><input type="checkbox" id="doc2" />
                                    <label for="doc2"><span>피부과</span></label></span>
                                </li> -->
                            </ul>
                        </div>
                    </li>
                    <li class="docNameHead headerSort" @click="sortTypeChange"
                        :class="{
                            sortUp   : sort_dir == 'ASC',
                            sortDown : sort_dir == 'DESC'
                        }"><span>의사명</span>
                    </li>
                    <li class="contractHead"><span>근무기간</span></li>
                    <li class="workDaysHead"><span>근무요일</span></li>
                </ul>

                <div id="doctor_list_scroll">
                    <ul class="docListUl">
                        <li :class="{
                                retired : isRetired(docIndex)
                            }" :key="docIndex" v-for="(doctor, docIndex) in computedDoctorSortList" @click="moveDoctorCreate(docIndex)"> 
                            <p class="num">{{docIndex+1}}</p>
                            <p class="specialityHead">{{doctor.department_name}}</p>
                            <p class="docNameHead">
                                <span class="docImg" :style="{backgroundImage: `url(${doctor != null && doctor.doctor_detail != null && doctor.doctor_detail.profile_image != null && doctor.doctor_detail.profile_image.length > 0 ? `app_images/${doctor.doctor_detail.profile_image}` : default_profile_image })`}">의사이미지</span>
                                {{doctor.doctor_name}}
                            </p>
                            <p class="contractHead">{{getRegiTerm(docIndex)}}</p>
                            <p class="workDaysHead">
                                <ul class="workDaysUl">
                                    <li v-bind:class="{ 
                                        off   : isDoctorOff(docIndex, dofIndex) == 'OFF',
                                        night : isDoctorOff(docIndex, dofIndex) == 'PM',
                                        morn  : isDoctorOff(docIndex, dofIndex) == 'AM'
                                    }" :key="dofIndex" v-for="(dayName, dofIndex) in arrO_dayNames">{{`${dayName}`}}</li>
                                </ul>
                            </p>
                        </li>
                        <!-- <li class="retired" @click="moveDoctorCreate(-2)">
                            <p class="num">3</p>
                            <p class="specialityHead">내과</p>
                            <p class="docNameHead"><span class="docImg">의사이미지</span>Josephine Langford</p>
                            <p class="contractHead">2017.02.02 ~ 20218.12.01</p>
                            <p class="workDaysHead"><span>퇴사</span></p>
                        </li> -->
                    </ul>
                </div>

                <!-- <div id="doctor_list_scroll">
                    <ul class="docListUl">
                        <li @click="moveDoctorDetail"> 
                            <p class="num">1</p>
                            <p class="specialityHead">내과</p>
                            <p class="docNameHead"><span class="docImg">의사이미지</span>김동현</p>
                            <p class="contractHead">2018.02.02 ~ 2020.06.01</p>
                            <p class="workDaysHead">
                                <ul class="workDaysUl">
                                    <li>월</li>
                                    <li>화</li>
                                    <li>수</li>
                                    <li class="off">목</li>
                                    <li class="night">금</li>
                                    <li class="morn">토</li>
                                    <li class="off">일</li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <p class="num">2</p>
                            <p class="specialityHead">내과</p>
                            <p class="docNameHead"><span class="docImg">의사이미지</span>이연지</p>
                            <p class="contractHead">2017.02.02 ~ 20218.12.01</p>
                            <p class="workDaysHead">
                                <ul class="workDaysUl">
                                    <li class="off">월</li>
                                    <li class="morn">화</li>
                                    <li>수</li>
                                    <li>목</li>
                                    <li>금</li>
                                    <li class="off">토</li>
                                    <li class="morn">일</li>
                                </ul>
                            </p>
                        </li>
                        <li class="retired">
                            <p class="num">3</p>
                            <p class="specialityHead">내과</p>
                            <p class="docNameHead"><span class="docImg">의사이미지</span>Josephine Langford</p>
                            <p class="contractHead">2017.02.02 ~ 20218.12.01</p>
                            <p class="workDaysHead"><span>퇴사</span></p>
                        </li>
                    </ul>
                </div> -->
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';
import { hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';
import { ResizeObserver } from 'vue-resize';
import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class HospitalDoctorList extends Mixins(VueHoduCommon) {

    /**
     * 의사 정렬 리스트
     */
    get computedDoctorSortList() : hodu_doc_object.doctor_info[] {
        let target_list : hodu_doc_object.doctor_info[] = JSON.parse(JSON.stringify(this.arrO_doctors));

        // 진료과 필터
        for( const department_code of this.department_filter ) {
            target_list = target_list.filter(item => item.department_code != department_code);
        }

        target_list = target_list.sort((item1, item2) : number => {

            if( this.sort_dir == SORT_TYPE.ASC ) {
                return item1.doctor_name < item2.doctor_name ? -1 : 1;
            }

            return item1.doctor_name > item2.doctor_name ? -1 : 1;
        })

        return target_list;
    }

    /**
     * 의사리스트를 기준으로 의사가 한명이라도 존재하는 진료과 리스트를 반환 
     */
    get computedDepartmentList() : hodu_doc_object.department_info[] {

        let target_list : hodu_doc_object.department_info[] = [];

        for( const doctor of this.arrO_doctors ) {
            
            const target_department : hodu_doc_object.department_info[] = target_list.filter(item => item.department_code == doctor.department_code);
            if( target_department.length > 0 ) { continue; }

            target_list.push({
                "department_code" : doctor.department_code,
                "department_name" : doctor.department_name
            })
        }

        return target_list;
    }

    arrO_doctors : hodu_doc_object.doctor_info[]  = [];
    arrO_dayNames : string[] = ["일", "월", "화", "수", "목", "금", "토"];
    default_profile_image : string = require("@/assets/images/contents/im_photoB.gif");

    sort_dir : SORT_TYPE = SORT_TYPE.ASC;
    department_filter_open : boolean = false;
    department_filter : string[] = [];

    created() : void {
        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/doctor`, API_METHOD.GET)
            .then((response) => {
                // let jsonData = JSON.stringify(response.data.data);
                this.arrO_doctors.splice(0, this.arrO_doctors.length);
                this.arrO_doctors = this.arrO_doctors.concat(response.data.data)
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    mounted() : void {
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#doctor_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    getDoctorStartEnd(doctor : hodu_doc_object.doctor_info) : string {
        return `${moment(doctor.start_date).format("YYYY-MM-DD")} ~ ${moment(doctor.end_date).format("YYYY-MM-DD")}`;
    }

    /**
     * 의사 생성 및 상세 화면으로 이동
     */
    moveDoctorCreate(index: number) : void {
        if (index == -2) {
            this.hodu_router_push(`/hospital/${new Date().getTime()}/doctor/detail`);
            return
        }
        let url = `/hospital/${new Date().getTime()}/doctor/create`;
        if (index >= 0) {
            url = `/hospital/${new Date().getTime()}/doctor/${this.computedDoctorSortList[index].doctor_code}`;
        }

        this.hodu_router_push(url);
    }

    isDoctorOff(docIndex: number, dofIndex: number) : any {
        switch (dofIndex) {
            case 0 :
                return this.computedDoctorSortList[docIndex].sunday
            case 1 :
                return this.computedDoctorSortList[docIndex].monday
            case 2 :
                return this.computedDoctorSortList[docIndex].tuesday
            case 3 :
                return this.computedDoctorSortList[docIndex].wednesday
            case 4 :
                return this.computedDoctorSortList[docIndex].thursday
            case 5 :
                return this.computedDoctorSortList[docIndex].friday
            case 6 :
                return this.computedDoctorSortList[docIndex].saturday
            }
    }

    getRegiTerm(docIndex: number) : string {
        let startStr : string = moment(this.computedDoctorSortList[docIndex].start_date).format("YYYY.MM.DD")
        let endStr   : string = moment(this.computedDoctorSortList[docIndex].end_date).format("YYYY.MM.DD")
        return startStr + " ~ " + endStr
    }

    isRetired(docIndex: number) : boolean {
        let value = false;
        if ( this.computedDoctorSortList[docIndex].end_date <= new Date() ) {
            return true;
        }
        return value;
    }

    /**
     * 진료과 필터 ON / OFF
     */
    departmentFilterOnOff() : void {
        this.department_filter_open = !this.department_filter_open;
    }

    /**
     * 진료과 필터 전체 체크
     */
    departmentFilterAll(event : Event) : void {
        // event.stopPropagation();

        // 전부 필터에 존재하지 않는다면
        if( this.department_filter.length < 1 ) {
            this.department_filter.splice(0 ,this.department_filter.length);
            for( const department of this.computedDepartmentList ) { this.department_filter.push(department.department_code); }
            return;
        }

        // 하나라도 필터에 존재한다면
        this.department_filter.splice(0 ,this.department_filter.length);
    }

    /**
     * 진료과 필터에 추가 / 제거
     */
    departmentFilterCheckOnOff(event : Event, value : string) {
        event.preventDefault();

        if ( this.department_filter.indexOf(value) < 0 ) { 
            this.department_filter.push(value);
            return;
        }

        this.department_filter.splice(this.department_filter.indexOf(value), 1);

    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange() : void {
        this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#doctor_list_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
</script>

<style scoped>
    
    /* 헤더 */
    .num { width: 10%; text-align: center; font-weight:bold; }
    .specialityHead { width: 15%; }
    .docNameHead {  width: 22%; }
    .contractHead { width: 23%;  }
    .workDaysHead { width: 30%; }

    .docListUl > li { cursor:pointer; transition:0.2s; position:relative; overflow: hidden; height:60px; line-height: 60px;border-bottom:1px solid #f1f3f5; font-weight:bold; font-size: 12px;}
    .docListUl > li:hover { background:#f7f9fb; }
    .docListUl > li.retired p { opacity:0.6;  }
    .docListUl > li > p { float:left; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;  }
    .docListUl > li > p.num { padding-right:0; }
    .docListUl li .docImg { display:inline-block;float: left; margin-top:12px; margin-right:10px; font-size:0; width: 33px; height:33px; border-radius:50%; border:2px solid #f1f3f5; background-size:cover; background-repeat: no-repeat; background-position: center center;background-image:  url('../../assets/images/footer/doc_2.png'); }
    .docListUl li .docImg { border-color:#13D08B }
    /* .docListUl li:nth-child(2) .docImg { border-color:#ff6060; }
    .docListUl li:nth-child(3) .docImg { border-color:#FFC72F  } */
    .workDaysUl li { box-sizing:border-box; border:1px solid #2265ff; display:inline-block; background: #477fff; color:#fff; width: 21px; text-align: center; height: 21px;line-height: 19px; border-radius: 3px; margin-right:5px; }
    .workDaysUl li.off {background: #f1f3f5;color:#939cb1; border-color: #eaeaea}

    .workDaysUl li.morn {background: #ffc00e !important;border-color: #f3b300;}
    .workDaysUl li.night {background: #846eff !important;border-color: #6c52ff;}
    .workDaysUl li.off { color: #a1a5bb !important;}

    .btnAdd { position:absolute; right: 40px; bottom:40px; }
    .btnAdd { opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .btnAdd:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}

</style>