<template>
    <div id="appointment_apply_modal" class="modal">
        <div class="titleBox">
            <h1>
                {{ event != null && event.event_data.appointment != null ? event.event_data.appointment.appointment_status == hodu_doc_enum.appointment_status_code.REQUEST
                            ? '진료 요청' 
                            : '진료 거절'
                         : '' }} 
            </h1>
        </div>
        <div class="content">
            <div class="detail">
                <div class="leftPatientDiv">
                    <dl>
                        <dt>예약일</dt>
                        <!-- <dd>2020년 4월 17일 금요일</dd> -->
                        <dd>{{ event != null ? `${hodu_date_to_format_string(event.event_data.schedule_date.start, 'YYYY년 MM월 DD일')} ${getDayOfWeekByDate(event.event_data.schedule_date.start, '요일')}` : '' }}</dd>
                        
                        <dt>예약시간</dt>
                        <!-- <dd>04:20 pm</dd> -->
                        <dd>{{ event != null ? amPmStringToLocaleAmPmString(hodu_date_to_format_string(event.event_data.schedule_date.start, 'hh:mm a')) : '' }}</dd>

                        <dt>의사</dt>
                        <!-- <dd>김동현</dd> -->
                        <dd>{{ doctor_name && doctor_name.length > 0 ? doctor_name : '-' }}</dd>

                        <dt>진료과</dt>
                        <!-- <dd>내과</dd> -->
                        <dd>{{ department_name && department_name.length > 0 ? department_name : '-' }}</dd>

                        <dt>예약자</dt>
                        <!-- <dd>임주형</dd> -->
                        <dd>{{ event != null ? event.event_data.event_owner_name : '' }}</dd>

                        <dt class="noBorder">연락처</dt>
                        <!-- <dd class="noBorder">010-0202-0202</dd> -->
                        <dd class="noBorder">{{ event != null && event.event_data.appointment != null ? event.event_data.appointment.nok_phone_number : '' }}</dd>
                    </dl>
                </div>
                <div class="rightDiv">
                    <div class="reason">
                        <p class="title">예약 사유</p>
                        <!-- 줄바꿈없고 글자 수 제한 있어야함 (두 줄 이상 안보임) -->
                        <p class="reasonP">{{ event != null && event.event_data.appointment != null ? event.event_data.appointment.symptoms : '' }}</p>
                        <label for="memoId">메모</label>
                        <textarea id="memoId" placeholder="거절시 거절사유 또는 환자에게 준비할 사항을 작성하세요. 예) 금식 등등" :value="memo" @input="memo = $event.target.value"></textarea>
                    </div>
                </div>
            </div>
            <div class="btns" :class="{
                b3 : event == null || event.event_data.appointment == null || event.event_data.appointment.appointment_status == 'REQUEST'
            }">
                <input type="button" value="취소" @click="closeModal"/>
                <input type="button" value="거절" v-if="event == null || event.event_data.appointment == null || event.event_data.appointment.appointment_status == 'REQUEST'" @click="declineAppointment"/>
                <input type="button" class="save" value="승인" @click="applyAppointment"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';
import { t_event } from '@/model/event';
import { hodu_color } from '@/common/color';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class AppointmentApplyModal extends Mixins(VueHoduCommon) {

    hodu_doc_enum : any = hodu_doc_enum;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State appointment_apply_modal_info !: hodu_doc_modal_info.AppointmentApplyModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentApplyModalInfo ?: (params : hodu_doc_modal_info.AppointmentApplyModalInfo) => void;

    event : t_event | null = null;
    doctor_name : string = "";
    department_name : string = "";

    memo : string = ""; // 거절시 거절사유, 승인시 준비사항 등

    async mounted() : Promise<void> {
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);
        this.getEvent();
    }

    /**
     * 예약 조회
     */
    getEvent() : void {
        const calendar_id : string = this.calendar_id;
        const scope       : string = OWNER_TYPE.GROUP;
        const scope_id    : number = this.scope_group_id;

        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${this.appointment_apply_modal_info.event_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                this.event = JSON.parse(JSON.stringify(response.data.data.event_data));

                if( this.event == null || this.event.event_data.appointment == null || this.department_info == null || this.doctor_info == null ) { return; }

                const department_code = this.event.event_data.appointment.department_code;
                const doctor_code     = this.event.event_data.appointment.doctor_code;

                const departments : hodu_doc_object.department_info[] = this.department_info.filter(item => item.department_code == department_code);
                const doctors : hodu_doc_object.doctor_info[] = this.doctor_info.filter(item => item.department_code == department_code && item.doctor_code == doctor_code);

                if( departments.length > 0 ) {
                    this.department_name = departments[0].department_name;
                }

                if( doctors.length > 0 ) {
                    this.doctor_name = doctors[0].doctor_name;
                }

                // 거절된 예약이라면 거절사유를 세팅한다
                if( this.event.event_data.appointment.appointment_status == hodu_doc_enum.appointment_status_code.DECLINE ) {
                    this.memo = this.event.event_data.appointment.appointment_decline_reason ? this.event.event_data.appointment.appointment_decline_reason : '';
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })
    }

    /**
     * 예약 수정
     */
    updateEvent() : void {
        
        if( this.event == null ) { return; }

        const calendar_id : string = this.calendar_id;
        const scope       : string = OWNER_TYPE.GROUP;
        const scope_id    : number = this.scope_group_id;

        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${this.appointment_apply_modal_info.event_id}`, API_METHOD.PUT, this.event.event_data)
                .then((response) => {
                    console.log(response);
                    
                    if( this.event == null || this.event.event_data.appointment == null ) { return; }

                    // 거절인 경우 재조회
                    if( this.event.event_data.appointment.appointment_status == hodu_doc_enum.appointment_status_code.DECLINE ) {
                        if( this.appointment_apply_modal_info.apply_callback ) { this.appointment_apply_modal_info.apply_callback(); }
                    }

                    // 승인인 경우 상세 페이지로 이동
                    if( this.event.event_data.appointment.appointment_status == hodu_doc_enum.appointment_status_code.CONFIRM ) {
                        if( this.appointment_apply_modal_info.apply_callback ) { this.appointment_apply_modal_info.apply_callback(); }
                        // this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${this.event.event_id}`);
                    }

                    this.closeModal();
                })
                .catch((e) => {
                    this.hodu_error_process(e, false, false, true);
                });
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetAppointmentApplyModalInfo == null ) { return; }

        this.doSetAppointmentApplyModalInfo({ 
            show_modal : false,
            event_id : '', 
        })
    }

    /**
     * 예약 거부
     */
    declineAppointment() : void {
        if( this.event == null || this.event.event_data.appointment == null ) { return; }

        this.event.event_data.appointment.appointment_status = hodu_doc_enum.appointment_status_code.DECLINE;
        this.event.event_data.appointment.appointment_decline_reason = this.memo;
        this.event.event_data.color = hodu_color.appointment_decline;

        this.updateEvent();
    }

    /**
     * 예약 승인
     */
    applyAppointment() : void {
        if( this.event == null || this.event.event_data.appointment == null ) { return; }

        this.event.event_data.appointment.appointment_status = hodu_doc_enum.appointment_status_code.CONFIRM;
        this.event.event_data.appointment.prescription = this.memo;
        this.event.event_data.color = hodu_color.appointment_confirm;

        this.updateEvent();
    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-245px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:490px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #f1f3f5 }

    .detail { overflow: hidden;height: 328px; }
    .leftPatientDiv { width: 45%; float:left; }
    .detail dl { padding:0 30px; overflow:hidden; margin-top: 10px; }
    .detail dl dt {float:left; width: 40%; display:inline-block; line-height: 50px; height:50px; box-sizing: border-box; font-size: 13px; font-weight: bold; border-bottom:1px solid #f1f3f5; }
    .detail dl dd { float:left; width:60%; display: inline-block; height:50px;line-height: 50px; box-sizing: border-box; font-size: 13px; border-bottom:1px solid #f1f3f5; font-weight:bold; text-align: right; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .detail dl .noBorder { border-bottom: none; }
    .rightDiv { float:left; width: 55%; height: 100%; border-left: 1px solid #f1f3f5; box-sizing: border-box; }
    .reason { margin: 0 30px 30px; }
    .reason .reasonP { background:#f1f3f5; padding: 15px; line-height: 20px; font-weight: bold; max-height: 50px;min-height: 50px; overflow-y: auto;}
    .rightDiv label, .rightDiv .title { height: 35px; display:block; line-height: 35px; margin-top:10px; }
    .rightDiv label { margin-top:10px; }
    textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 130px; height:auto; box-sizing: border-box; font-weight:bold; }
    textarea:hover { border-color:#477fff }
    textarea:focus { background:#fff;  }

    .btns { overflow: hidden; border-top:1px solid #f1f3f5;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold; }
    .btns.b3 input { width:33.3333333%; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }
</style>