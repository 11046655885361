<template>
    <div id="attendance_total_list_month" class="section_scroll">
        <div class="monthViewGroup">
            <div class="schedule_box">
                <div class="grp settingBg">
                    <div class="left">
                        <h2 id="hMonthPrt">
                            <input type="text" id="attendance-list-month-current-date" class="forDatePick" :value="hodu_date_to_format_string(selected_date, 'YYYY-MM-DD')" readonly/>{{ date_string }}
                        </h2>
                        <input type="button" id="btnPrev" value="" class="toolbar-btn bt-prev mr10" @click.prevent="prev">
                        <input type="button" id="btnNext" value="" class="toolbar-btn bt-next mr10" @click.prevent="next">
                        <input type="button" id="btnToday" value="오늘" class="toolbar-btn today " @click.prevent="today">
                    </div>
                    <div class="secondBox">
                        <div class="teamLeft">
                            <input type="button" class="sort1" :value="getDeptName(selected_dept_id)">
                            <ul class="dropdown">
                                <li>
                                    <a href="#" :class="{ 'on' : selected_dept_id == -1 }" @click.prevent="() => { selected_dept_id = -1 }">
                                        전체 부서
                                    </a>
                                </li>
                                <li :key="department.dept_id" v-for="department in departments">
                                    <a href="#" :class="{ 'on' : selected_dept_id == department.dept_id }" @click.prevent="() => { selected_dept_id = department.dept_id }">
                                        {{ department.dept_name }}
                                    </a>
                                </li>
                                <!-- <li>
                                    <a href="#">마케팅</a>
                                </li>
                                <li>
                                    <a href="#">기획팀</a>
                                </li>
                                <li>
                                    <a href="#">디자인팀</a>
                                </li>
                                <li>
                                    <a href="#">개발팀</a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="downRight">
                            <div class="timeSwitchBtn">
                                <p>근태 시간 표시</p>
                                <label class="switch">
                                    <input type="checkbox" v-model="show_attendance_time" @change="changeShowAttendanceTime"><span class="slider round"></span>
                                </label>
                            </div>
                            <input type="button" value="다운로드" class="input_btn download_btn" @click.prevent="excelDownload">
                        </div>
                    </div>
                </div>
            </div>

            <!-- 근태시간표시 on 활성화시 timeListOn 클래스 추가 -->
            <div id="attendance_total_list_year_scroll" class="content" :class="{ timeListOn : show_attendance_time == true }">
                <div class="monthDiv yearDiv">
                    <div class="leftDiv">
                        <div class="nameDiv">
                            <div class="sortHeader">
                                <p class="headerSort sortOff">이름</p>
                            </div>
                            <div id="attendance_list_year_left_scroll" class="leftScroll">
                                <!-- 여기에 스크롤 넣고 같이 내려가게 해주세요 기존 리스트 처럼 -->
                                <ul>

                                    <li :key="employee.user_id" v-for="employee in attend_emp_month_info">
                                        <p class="img" :style="{ 'background-image' : `url(${getProfileImage(employee)})` }">사진</p>
                                        <img class="dummy" :src="getProfileImage(employee)" @error="userImageError($event)"/>
                                        <p class="name">{{ employee.user_name }}</p>
                                        <p class="pos">{{ employee.pos_name }}</p>
                                        <p class="tem">{{ employee.dept_name }}</p>
                                    </li>

                                    <!-- <li>
                                        <p class="img">사진</p>
                                        <p class="name">김사원</p>
                                        <p class="pos">사원</p>
                                        <p class="tem">개발</p>
                                    </li>
                                    <li>
                                        <p class="img">사진</p>
                                        <p class="name">이기획</p>
                                        <p class="pos">대리</p>
                                        <p class="tem">마케팅</p>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="totalDiv">
                            <div class="totalHeader">
                                <p>항목&#47;합계</p>
                            </div>
                            <div id="totalCon_year_left_scroll" class="totalCon">
                                <ul>
                                    <li :key="employee.user_id" v-for="employee in attend_emp_month_info">
                                        <div class="totalListDiv">
                                            <p class="item">연차</p>
                                            <p class="number">{{ getTotalVacationCount(employee) }}</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">지각</p>
                                            <p class="number">{{ getTotalLateCount(employee) }}</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">출장</p>
                                            <p class="number">{{ getTotalBusinessTripCount(employee) }}</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">결근</p>
                                            <p class="number">{{ getTotalAwolCount(employee) }}</p>
                                        </div>
                                        <!-- 근태 시간 표시 on 일 경우 totalTimeDiv 추가-->
                                        <div class="totalListDiv" :class="{ totalTimeDiv : show_attendance_time == true }" v-show="show_attendance_time == true">
                                            <p class="item">근태 시간</p>
                                        </div>
                                    </li>
                                    <!-- <li>
                                        <div class="totalListDiv">
                                            <p class="item">연차</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">지각</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">출장</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">결근</p>
                                            <p class="number">0</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="totalListDiv">
                                            <p class="item">연차</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">지각</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">출장</p>
                                            <p class="number">0</p>
                                        </div>
                                        <div class="totalListDiv">
                                            <p class="item">결근</p>
                                            <p class="number">0</p>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="rightDiv">
                        <div id="month_right_scroll" class="rightScroll" :style="{ width : `${header_width}px` }">
                        <!-- <div id="month_right_scroll" class="rightScroll"> -->
                            <ul id="month_right_scroll_ul" class="mnthUl head" :style="{ width : `${50 * computedMonth.length}px` }">
                            <!-- <ul id="month_right_scroll_ul" class="mnthUl head"> -->

                                <li :class="{
                                    sun : (day.date.getDay() == 0) || (holidays.get(hodu_date_to_format_string(day.date, 'YYYY-MM-DD')) != null),
                                    today : hodu_date_to_format_string(day.date, 'YYYYMMDD') == hodu_date_to_format_string(new Date(), 'YYYYMMDD') 
                                }" :key="day.date.getDate()" v-for="day in computedMonth">
                                    <div class="topDiv">
                                        <p class="date">{{ day.date.getDate() }}</p>
                                        <p class="day">{{ day.day }}</p>
                                    </div>
                                </li>

                                <!-- <li>
                                    <div class="topDiv">
                                        <p class="date">1</p>
                                        <p class="day">수</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">2</p>
                                        <p class="day">목</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">3</p>
                                        <p class="day">금</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">4</p>
                                        <p class="day">토</p>
                                    </div>
                                </li>
                                <li class="sun">
                                    <div class="topDiv">
                                        <p class="date">5</p>
                                        <p class="day">일</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">6</p>
                                        <p class="day">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">7</p>
                                        <p class="day">화</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">8</p>
                                        <p class="day">수</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">9</p>
                                        <p class="day">목</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">10</p>
                                        <p class="day">금</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">11</p>
                                        <p class="day">토</p>
                                    </div>
                                </li>
                                <li class="sun">
                                    <div class="topDiv">
                                        <p class="date">12</p>
                                        <p class="day">일</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">13</p>
                                        <p class="day">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">14</p>
                                        <p class="day">화</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">15</p>
                                        <p class="day">수</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">16</p>
                                        <p class="day">목</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">17</p>
                                        <p class="day">금</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">18</p>
                                        <p class="day">토</p>
                                    </div>
                                </li>
                                <li class="sun">
                                    <div class="topDiv">
                                        <p class="date">19</p>
                                        <p class="day">일</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">20</p>
                                        <p class="day">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">21</p>
                                        <p class="day">화</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">22</p>
                                        <p class="day">수</p>
                                    </div>
                                </li>
                                <li class="today">
                                    <div class="topDiv">
                                        <p class="date">23</p>
                                        <p class="day">목</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">24</p>
                                        <p class="day">금</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">25</p>
                                        <p class="day">토</p>
                                    </div>
                                </li>
                                <li class="sun">
                                    <div class="topDiv">
                                        <p class="date">26</p>
                                        <p class="day">일</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">27</p>
                                        <p class="day">월</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">28</p>
                                        <p class="day">화</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">29</p>
                                        <p class="day">수</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="topDiv">
                                        <p class="date">30</p>
                                        <p class="day">목</p>
                                    </div>
                                </li> -->
                            </ul>
                            <div id="attendance_list_year_right_scroll" :style="{ width : `${50 * computedMonth.length}px` }">
                            <!-- <div id="attendance_list_year_right_scroll" :style="{ width : `${header_width}px` }"> -->
                                <div class="rightCon" :key="employee.user_id" v-for="employee in attend_emp_month_info">

                                    <!-- 연차 -->
                                    <ul class="mnthUl cont">

                                        <!-- 0이 아닌 숫자일 경우 otherInput 클래스를 넣어 색상 주기 -->
                                        <li :class="{ 'otherInput' : Number.isNaN(Number(getVacationCount(employee, day))) == false && Number(getVacationCount(employee, day)) > 0 }" :key="day.date.getDate()" v-for="day in computedMonth">
                                            {{ getVacationCount(employee, day) }}
                                        </li>

                                    </ul>

                                    <!-- 지각 -->
                                    <ul class="mnthUl cont">
                                        
                                        <!-- 0이 아닌 숫자일 경우 otherInput 클래스를 넣어 색상 주기 -->
                                        <li :class="{ 'otherInput' : Number.isNaN(Number(getLateCount(employee, day))) == false && Number(getLateCount(employee, day)) > 0 }" :key="day.date.getDate()" v-for="day in computedMonth">
                                            {{ getLateCount(employee, day) }}
                                        </li>

                                    </ul>

                                    <!-- 출장 -->
                                    <ul class="mnthUl cont">
                                        
                                        <!-- 0이 아닌 숫자일 경우 otherInput 클래스를 넣어 색상 주기 -->
                                        <li :class="{ 'otherInput' : Number.isNaN(Number(getBusinessTripCount(employee, day))) == false && Number(getBusinessTripCount(employee, day)) > 0 }" :key="day.date.getDate()" v-for="day in computedMonth">
                                            {{ getBusinessTripCount(employee, day) }}
                                        </li>

                                    </ul>

                                    <!-- 결근 -->
                                    <ul class="mnthUl cont">
                                        <!-- 0이 아닌 숫자일 경우 otherInput 클래스를 넣어 색상 주기 -->
                                        <li :class="{ 'otherInput' : Number.isNaN(Number(getAwolCount(employee, day))) == false && Number(getAwolCount(employee, day)) > 0 }" :key="day.date.getDate()" v-for="day in computedMonth">
                                            {{ getAwolCount(employee, day) }}
                                        </li>
                                    </ul>

                                    <!-- 근태 시간 on 일경우 활성화 -->
                                    <ul class="mnthUl cont timeUl" v-show="show_attendance_time == true">
                                        
                                        <li :key="day.date.getDate()" v-for="day in computedMonth">
                                            <!-- 기본 출근 시간 넘어가면 workTimeRed 클래스 추가 -->
                                            <p :class="{ workTimeRed : isLate(employee, day) == true }">{{ getStartTime(employee, day) }}</p>
                                            <p>{{ getEndTime(employee, day) }}</p>
                                        </li>

                                    </ul>

                                </div>
                                <!-- <div class="rightCon">
                                    <ul class="mnthUl cont">

                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li class="otherInput">1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li class="otherInput">1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li class="otherInput">1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                </div>
                                <div class="rightCon">
                                    <ul class="mnthUl cont">
       
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li class="otherInput">1</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                    <ul class="mnthUl cont">
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                        <li>0</li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import moment from 'moment';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        
    },
})
export default class AttendanceTotalListMonth extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 월 데이터
     */
    get computedMonth() : any[] {
        let month : any[] = [];

        if( this.date_string == null || this.date_string.length < 1 ) return [];

        const month_start = moment(`${this.date_string}.01`).toDate();
        const month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

        const start = month_start.getDate();
        const end = month_end.getDate()

        for( let i = start; i <= end; i++ ) {
            month.push({
                date : moment(month_start).add('day', i - 1).toDate(),
                day : this.getDayOfWeekByDate(moment(month_start).add('day', i - 1).toDate())
            });
        }

        return month;
    }
    
    /**
     * @AttendanceInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @AttendanceInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트

    date_string : string = "";

    attend_emp_month_info : any[] = [];
    lunar_date : Map<string, string> = new Map(); // 음력 Map
    holidays : Map<string, Object> = new Map(); // 공휴일 Map

    header_width : number = 0;

    resize_function_submit : boolean = false;

    departments : any[] = [];
    selected_dept_id : number = -1;

    show_attendance_time : boolean = false;

    async mounted() : Promise<void> {

        let show_attendance_time = hodu_local_storage.getItem("show_attendance_time");
        if( show_attendance_time != null && show_attendance_time.length > 0 ) {
            this.show_attendance_time = (show_attendance_time === 'true');
        }

        $(".teamLeft").click(function(){
            $(".dropdown").toggleClass("on");
        });

        this.date_string = moment(this.selected_date).format('YYYY.MM');
        this.datepickerInit();

        // 조회
        await this.getTotalData();

        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        this.$nextTick(() => {
            
            const title_height = $('#attendance .title_box').outerHeight();
            const schedule_box_height = $('#attendance_total_list_month .schedule_box').outerHeight();
            const sort_header_height = $('#attendance_total_list_month .sortHeader').outerHeight();

            console.log(`${title_height}px ${schedule_box_height}px ${sort_header_height}px`)

            const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                    - (schedule_box_height ? schedule_box_height : 0)
                                                    - (sort_header_height ? sort_header_height : 0) - 10;

            // @ts-ignore
            $('#attendance_list_year_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_year_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#totalCon_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#attendance_list_year_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            // @ts-ignore
            $('#totalCon_year_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#totalCon_year_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#attendance_list_year_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                        
                    }
                }
            });

            // @ts-ignore
            $('#attendance_list_year_right_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_year_right_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#totalCon_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            const left_div_width : number | undefined = $('#attendance_total_list_month .leftDiv').outerWidth();
            const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
            const right_area_controller_width : number | undefined = !this.right_control_box_flag ? $('#right_area_controller').outerWidth() : 0;

            const scroll_width : number = window.innerWidth - (left_div_width ? left_div_width : 0)
                                                            - (left_area_width ? left_area_width : 0) 
                                                            - (right_area_controller_width ? right_area_controller_width : 0);

            this.header_width = scroll_width;

            if( this.resize_function_submit == false ) { 
                this.event_bus.$on('totalListMonthResize', this.handleResize);
                this.resize_function_submit = true;

                if( this.date_string == this.hodu_date_to_format_string(new Date(), 'YYYY.MM') ) {
                    this.$nextTick(() => {
                        const last_date = new Date();
                        last_date.setMonth(last_date.getMonth() + 1);
                        last_date.setDate(0);
                        const width = document.getElementById('month_right_scroll_ul')?.offsetWidth ?? 0;

                        $('#month_right_scroll').scrollLeft((width * (new Date().getDate() / last_date.getDate())) - 60);
                    });
                }
            }
        });
        
    }

    /**
     * attend data 가져오기
     */
    @Watch('selected_dept_id')
    async getTotalData() : Promise<void> {
        try {
            await this.hodu_show_indicator();

            // @ts-ignore
            $('#attendance_list_month_right_scroll').mCustomScrollbar('destroy');

            let month_start = moment(`${this.date_string}.01`).toDate();
            let month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

            await Promise.all([this.getLunar(month_start, month_end), this.getHolidays(month_start, month_end), this.getOrganization()]);
            await this.getEmpAttendDataMonthInfo();

            this.$nextTick(() => {
                
                this.setScroll();

                if( this.date_string == this.hodu_date_to_format_string(new Date(), 'YYYY.MM') ) {
                    const last_date = new Date();
                    last_date.setMonth(last_date.getMonth() + 1);
                    last_date.setDate(0);
                    const width = document.getElementById('month_right_scroll_ul')?.offsetWidth ?? 0;
                    $('#month_right_scroll').scrollLeft((width * (new Date().getDate() / last_date.getDate())) - 60);
                }
                else {
                    $('#month_right_scroll').scrollLeft(0);
                }
            });            

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 이벤트(월 달력 일별 근태현황) 조회
     */
    async getEmpAttendDataMonthInfo() : Promise<void> {
        try {

            const request_date_string = this.date_string.replaceAll(/\./ig, "");

            // 오늘 날짜를 넣어서 오늘 이후의 데이터를 안나오게 하려다가 오늘 이후의 연차 까지 나와야해서 이 달력의 마지막 날까지 조회 하도록 변경
            const today_date_string = moment().format('YYYYMMDD');
            // const today_date_string = moment(`${this.date_string}.01`).add('month', 1).set('date', 0).format('YYYYMMDD');

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/data/month?month=${request_date_string}&today_date_string=${today_date_string}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_month_info ) {
                throw new Error("월별 통합 리스트 근태현황 조회 실패");
            }

            if( this.selected_dept_id >= 0 ) {
                response.data.data.attend_emp_month_info = response.data.data.attend_emp_month_info.filter(emp => emp.dept_id == this.selected_dept_id);
            }
            
            this.attend_emp_month_info.splice(0, this.attend_emp_month_info.length);
            this.attend_emp_month_info = this.attend_emp_month_info.concat(response.data.data.attend_emp_month_info);

            for( const month_info of this.attend_emp_month_info ) {
                for( const key in month_info.jsonb ) {

                    // 근태현황 시작일이 적용 안됐다면 전부 제외
                    if( this.getAttendanceStart() == '' ) {
                        delete(month_info.jsonb[key]);
                        continue;
                    }

                    // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                    if( Number(this.getAttendanceStart()) > Number(key) ) {
                        delete(month_info.jsonb[key]);
                        continue;
                    }
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 음력 구하기
     */
    async getLunar(start : Date, end : Date) : Promise<void> {

        start = start instanceof Date ? start : new Date(moment(start).format());
        end   = end   instanceof Date ? end   : new Date(moment(end).format());

        const lunar_objs : any[] = await this.hodu_solars_to_lunars(start, end);
        
        for( let lunar_obj of lunar_objs ) {
            this.lunar_date.set(lunar_obj.solar_ymd, lunar_obj.lunar_ymd);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {

        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            this.holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

    }

    /**
     * 날짜 선택
     */
    datepickerInit() : void {

        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const date_moment = moment(dateText);
                
                vue.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
                vue.doSetSelectedDate(date_moment.toDate());
                vue.date_string = moment(vue.selected_date).format('YYYY.MM');

                inst.input.val(vue.date_string);

                // @ts-ignore
                $('#datepicker').datepicker('setDate', vue.selected_date);

                // 조회
                vue.getTotalData();
            },
        };

        // @ts-ignore
        $('#attendance-list-month-current-date').datepicker(option);
    }

    /**
     * 이전 날짜
     */
    prev() : void {
        this.doSetSelectedDate?.(moment(this.selected_date).add('month', -1).toDate());
        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // 조회
        this.getTotalData();
    }

    /**
     * 다음 날짜
     */
    next() : void {
        this.doSetSelectedDate?.(moment(this.selected_date).add('month', 1).toDate());
        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // 조회
        this.getTotalData();
    }

    /**
     * 오늘
     */
    today() : void {
        this.doSetSelectedDate?.(new Date());
        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // 조회
        this.getTotalData();
    }

    getTotalLateCount(employee) {

        let count = 0;

        const keys = Object.keys(employee.jsonb);

        for( const key of keys ) {

            const obj_array = employee.jsonb[key];

            if( obj_array == null ) {
                continue;
            }

            for( const obj of obj_array ) {
                if( obj.attend_type == 10 && obj.attend_state == '30' ) {
                    count += obj.daycount;
                }
            }

        }

        return `${count}`;
    }

    /**
     * count 지각
     */
    getLateCount(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 && obj.attend_state == '30' ) {
                count += obj.daycount;
            }
        }

        return `${count}`;
    }

    getTotalAwolCount(employee) {

        let count = 0;

        const keys = Object.keys(employee.jsonb);

        for( const key of keys ) {

            const obj_array = employee.jsonb[key];

            if( obj_array == null ) {
                continue;
            }

            for( const obj of obj_array ) {
                if( obj.attend_type == 10 && obj.attend_state == '40' ) {
                    count += obj.daycount;
                }
            }

        }

        return `${count}`;
    }

    /**
     * count 결근
     */
    getAwolCount(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 && obj.attend_state == '40' ) {
                count += obj.daycount;
            }
        }

        return `${count}`;
    }

    getStartTime(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];

        if( obj_array == null ) {
            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let start_time = '';

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 ) {
                start_time = obj.start_time;
            }
        }

        if( start_time == null || start_time.length < 1 ) return '-';

        return `${start_time.substring(0,2)}:${start_time.substring(2,4)}`;
    }

    getEndTime(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];

        if( obj_array == null ) {
            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let end_time = '';

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 ) {
                end_time = obj.end_time;
            }
        }

        if( end_time == null || end_time.length < 1 ) return '-';

        return `${end_time.substring(0,2)}:${end_time.substring(2,4)}`;

    }

    isLate(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return false;
            }

            return false;
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 && obj.attend_state == '30' ) {
                count += obj.daycount;
            }
        }

        return count > 0;

    }

    getTotalVacationCount(employee) {

        let count = 0;

        const keys = Object.keys(employee.jsonb);

        for( const key of keys ) {

            const obj_array = employee.jsonb[key];

            if( obj_array == null ) {
                continue;
            }

            for( const obj of obj_array ) {
                if( obj.attend_type == 20 && obj.vacation_dedution_yn == true ) {
                    count += obj.daycount;
                }
            }

        }

        return `${count}`;
    }

    /**
     * count 휴가
     */
    getVacationCount(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 20 && obj.vacation_dedution_yn == true ) {
                count += obj.daycount;
            }
        }

        return `${count}`;
    }

    getTotalBusinessTripCount(employee) {

        let count = 0;

        const keys = Object.keys(employee.jsonb);

        for( const key of keys ) {

            const obj_array = employee.jsonb[key];

            if( obj_array == null ) {
                continue;
            }

            for( const obj of obj_array ) {
                if( obj.attend_type == 30 ) {
                    count += obj.daycount;
                }
            }

        }

        return `${count}`;
    }

    /**
     * count 출장
     */
    getBusinessTripCount(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 30 ) {
                count += obj.daycount;
            }
        }

        return `${count}`;
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getAttendanceStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    getDeptName(dept_id : number) : string {

        if( dept_id == null || dept_id < 0 ) {
            return '전체 부서';
        }

        const filter = this.departments.filter(dept => dept.dept_id == dept_id);
        
        if( filter.length < 1 ) {
            return '';
        }

        return filter[0].dept_name;
    }

    async excelDownload() {
        
        if( this.attend_emp_month_info.length < 1 ) {
            return;
        }

        try {

            const dates : any[] = [];
            const employees : any[] = [];

            for( const day of this.computedMonth ) {
                dates.push({
                    "obj" : day,
                    "date": String(new Date(day.date).getDate()),
                    "day": day.day
                });
            }

            for( const emp of this.attend_emp_month_info ) {

                const employee : any = {
                    "obj" : emp,
                    "user_name" : emp.user_name,
                    "pos_name" : emp.pos_name,
                    "dept_name" : emp.dept_name,
                    "total": {
                        "vacation": this.getTotalVacationCount(emp),
                        "late": this.getTotalLateCount(emp),
                        "business_trip": this.getTotalBusinessTripCount(emp),
                        "awol": this.getTotalAwolCount(emp)          
                    },
                    "attend": {}
                };

                for( const day of this.computedMonth ) {
                    
                    employee.attend[String(day.date.getDate())] = {
                        "vacation": this.getVacationCount(emp, day),
                        "late": this.getLateCount(emp, day),
                        "business_trip": this.getBusinessTripCount(emp, day),
                        "awol": this.getAwolCount(emp, day),
                        "start_time" : this.getStartTime(emp, day),  
                        "end_time" : this.getEndTime(emp, day),  
                    }

                }

                employees.push(employee);
            }

            const body = {
                "name" : encodeURIComponent(`${this.date_string.replaceAll(".", "")}_호두_월별_통합_리스트.xlsx`),
                "sheet_name" : this.date_string,
                "date" : dates, 
                "emp" : employees,
                "show_attendance_time" : this.show_attendance_time
            }

            console.log(body);

            const excel_response = await this.$http({
                url : "api/v1/excel/attend/total_list_month",
                method : 'POST',
                responseType: 'blob',
                headers : {
                    "Cache-Control" : "no_cache"
                },
                data : body
            });

            console.log(excel_response);

            const blob = new Blob([excel_response.data]);

            // @ts-ignore
            if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, decodeURIComponent(body.name)); // for IE
                return;
            }

            await this.hodu_download_blob(blob, decodeURIComponent(body.name));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }

        
    }

    changeShowAttendanceTime(event) {
        hodu_local_storage.setItem("show_attendance_time", String(this.show_attendance_time));
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_taget.parent().find('p.img').css('background-image', `url(${replace_image})`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#attendance_list_year_left_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#totalCon_year_left_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#attendance_list_year_right_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

}
</script>

<style scoped>
    #attendance_total_list_month .grp.settingBg { height: auto; }
    #attendance_total_list_month .grp.settingBg select { position: static; margin-left: 30px; font-size: 19px; color: #202a39; font-weight: bold; }

    .monthViewGroup .left { margin-top: 0px; height: auto; float: none; }
    .monthViewGroup .left #hMonthPrt { float: left; line-height: 61px; font-size: 19px; color: #202A39; cursor: pointer; margin-right: 15px; font-weight: bold; background: none; }

    .secondBox { width: 100%; display: flex; justify-content: space-between; box-sizing: border-box; height: 60px; line-height: 60px; }
    .teamLeft { padding: 0 33px; position: relative; width: auto; box-sizing: border-box; }
    .teamLeft input[type='button'] { font-size: 12px; font-weight: bold; background: url('../../assets/images/side_area/bt_open.png') no-repeat right center; line-height: 60px; width: auto; float: left; text-align: left; padding-right: 28px; color: #232848; }
    .teamLeft input[type='button']:focus { background-image: url('../../assets/images/side_area/bt_close.png'); }
    .teamLeft .dropdown { max-height: 230px; overflow-y: auto; top: 51px; }
    .downRight .download_btn { background-image: url('../../assets/images/contents/download_btn.png') !important; padding-left: 45px !important; margin-right: 30px; background-repeat: no-repeat !important; background-size: 17px auto !important; background-position: 15px center !important; }

    .monthDiv { position: relative; min-height: 100px; border-top: 1px solid #e7e9ea; }
    .leftDiv { border-right: 1px solid #e7e9ea; width: 300px; height: auto; box-sizing: border-box; position: absolute; left: 0; top: 0; display: flex; justify-content: space-between; }
    .nameDiv { width: 200px; border-right: 1px solid #e7e9ea; box-sizing: border-box; }
    .leftDiv .sortHeader { box-sizing: border-box; padding: 0 15px; }
    .leftDiv .sortHeader .headerSort { background: none; cursor: default; }
    .leftDiv .headerSort.sortOff:hover { cursor: default !important; text-indent: 0px; color: #232848; font-weight: initial; }

    /* .leftScroll ul { margin-bottom: 100px; } */
    .leftScroll ul li { position: relative; border-bottom: 2px solid #e7e9ea; height: 165px; padding-left: 80px; padding-right: 15px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; box-sizing: border-box; }
    .leftScroll ul li p { line-height: 20px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .leftScroll ul li p.img { position: absolute; width: 37px; height: 37px; border-radius: 50%; background: #f1f3f5; border: 1px solid #f1f3f5; box-sizing: border-box; font-size: 0; top: 15px; left: 20px; background-position: center center; background-size: cover; background-repeat: no-repeat; }
    .leftScroll ul li p.img01 { background-image: url('../../assets/images/contents/groupImg.png')  }
    .leftScroll ul li p.name { font-size: 13px; font-weight: bold; height: 35px; line-height: 35px; margin: 10px 0 5px 0; }
    .leftScroll ul li p.pos { font-weight: bold; opacity: .5; }
    
    .totalDiv { flex-grow: 1; box-sizing: border-box; }
    .totalDiv .totalHeader { line-height: 60px; height: 60px; border-bottom: 1px solid #e7e9ea; font-size: 13px; padding: 0 15px; box-sizing: border-box; }
    .totalCon ul li { height: 165px; line-height: 40px; border-bottom: 2px solid #e7e9ea; box-sizing: border-box; font-weight: bold; }
    .totalListDiv { display: flex; border-bottom: 1px solid #e7e9ea; padding: 0 15px; box-sizing: border-box; }
    .totalListDiv:last-child { border-bottom: none; }
    .number { margin-left: 6px; color: #477fff; }

    .rightDiv { box-sizing: border-box; width: 100%; padding-left: 300px; height: 100%; }
    .rightScroll { min-width: 748px; height: 100%; overflow: hidden; overflow-x: scroll; }
    .rightScroll .mnthUl { overflow: hidden; display: flex; justify-content: space-between; height: 60px; line-height: 60px; border-bottom: 1px solid #e7e9ea; box-sizing: border-box; }
    .mnthUl li { position: relative; flex-grow: 1; border-right: 1px solid #e7e9ea; width: 8.33333%; text-align: center; box-sizing: border-box; }
    .mnthUl li.otherInput { color: #477fff; font-weight: bold; }
    .mnthUl li:last-child { border-right: none; }
    .mnthUl li.today:after { content: ''; display: block; position: absolute; left: 0; top: 0; width: 100%; height: 100%; border: 1px solid #477fff; -webkit-box-sizing: border-box; box-sizing: border-box; }
    .mnthUl.head li.today .topDiv { border-bottom: 1px solid #477fff; color: #fff; background-color: #477fff; }
    .mnthUl .topDiv { display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 2px; height: 60px; }
    .mnthUl .topDiv > p { line-height: normal; }

    /* #attendance_list_year_right_scroll { width: 1800px; } */
    .rightCon { height: 165px; border-bottom: 2px solid #e7e9ea; box-sizing: border-box; }
    .rightScroll .mnthUl.cont { height: 41px; line-height: 41px; }
    .rightScroll .mnthUl.cont:last-child { border-bottom: none; }
    .sun .topDiv p { color: #ff6060; }

    ::-webkit-scrollbar{ width: 6px; height : 10px; }
    ::-webkit-scrollbar-track{background-color:  #e7e9ea;}
    ::-webkit-scrollbar-thumb{background-color: #232848;transition: 0.5s;}

    /* 근태 시간 표시*/
    .downRight { display: flex; gap: 20px; align-items: center; }
    .timeSwitchBtn p { font-size: 13px; font-weight: bold; display: inline-block; margin-right: 10px; box-sizing: border-box; }
    .timeSwitchBtn .switch { margin-top: 18px; }
    ul.mnthUl.cont.timeUl { display: none; }
    .totalListDiv.totalTimeDiv { display: none; }

    .timeListOn .leftScroll ul li { height: 225px; }
    .timeListOn .totalCon ul li { height: 225px; }
    .timeListOn .rightCon { width: fit-content; height: 225px; }
    .timeListOn .rightScroll .mnthUl { width: fit-content; overflow: visible; }
    .timeListOn .mnthUl li { min-width: 50px; }
    .timeListOn ul.mnthUl.cont.timeUl { display: flex; }
    .timeListOn .totalListDiv.totalTimeDiv { display: block; }

    .totalTimeDiv .item { line-height: 60px; }
    .timeListOn .rightScroll .timeUl.cont { height: 60px; line-height: normal; }
    .timeUl li { display: flex; flex-direction: column; align-items: center; justify-content: center; }
    .workTimeRed { color: #ff6060; }
</style>