<template>
    <div id="announcement" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">안내방송 상세보기</h3>

            <p class="btn_group" style="">
                <input type="button" class="input_btn" value="수정" @click="moveModify" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/>
                <input type="button" value="삭제" class="input_btn" @click="deleteTTS" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/>
            </p>
        </div>

        <div class="content announcement" v-if="tts != null"> 
            <div class="annOption">
                <div class="when">
                    <p class="when_title">안내방송 방송일시</p>
                    <label for="ann_datepicker">
                        <input type="button" id="ann_datepicker" :value="hodu_date_to_format_string(tts.noti_info.tts.start_time, 'YYYY.MM.DD')" class="input_txt"/>
                    </label>
                    <div class="when_time">
                        <p class="annTime">
                            {{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(tts.noti_info.tts.start_time, 'hh:mm a')) }} 
                            <span :class="{ 
                                completeT : tts.noti_info.tts.complete_yn == 'Y',
                                remainingT : tts.noti_info.tts.complete_yn == 'N' 
                            }" class="">({{ tts.noti_info.tts.complete_yn == 'Y' ? '완료' : `${getRemainTime(tts.noti_info.tts.start_time)}${getRemainTime(tts.noti_info.tts.start_time) == '시간만료' ? '' : ' 남음'}` }})</span>
                        </p>
                    </div>
                </div>
                <div class="how_repeat">
                    <p class="repeat_title">반복 방송 설정</p>
                    
                    <select class="ann_repeat" disabled :style="{ color : '#273044', opacity : 1 }">
                        <option value="1" :selected="tts.noti_info.tts.repeat_count == 1">1회</option>
                        <option value="2" :selected="tts.noti_info.tts.repeat_count == 2">2회</option>
                        <option value="3" :selected="tts.noti_info.tts.repeat_count == 3">3회</option>
                    </select>
                </div>
                <div class="share">
                    <p class="share_title">작성자</p>
                    <p class="whoWrote">
                        <span class="fPic" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor(tts.audit_user_id / 10000)}/${tts.audit_user_id}.jpg)` }">사진</span>
                        {{ tts.audit_user_name }}
                    </p>
                </div>
            </div>
            <div class="annOption">
                <div class="when createWhen">
                    <p class="when_title">작성일</p>
                    <label for="create_datepicker">
                        <input type="button" id="create_datepicker" :value="hodu_date_to_format_string(tts.audit_created, 'YYYY.MM.DD')" class="input_txt"/>
                    </label>
                </div>
                <div class="play_btn">
                    <input type="button" value="미리듣기" class="input_btn annExample_btn" title="미리듣기" @click.prevent="setPreTTS" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                    <input type="button" value="방송하기" class="input_btn annPlay_btn" title="방송시작" @click.prevent="play" v-if="tts.noti_info.tts.complete_yn == 'N' && (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                </div>
            </div>
            <div class="ann_textBox">
                <input type="text" id="ann_title" class="input_txt" :value="tts.noti_info.title" disabled="disabled" :style="{ color : '#273044' }">
                <div class="ann_textCon">
                    <!-- <textarea name="" id="ann_content" class="input_textarea" placeholder="안내방송 내용을 작성하세요. *작성 중 미리듣기는 150자이내 2번 실행됩니다." cols="30" rows="10" disabled></textarea> -->

                    <div id="modal_ann_contents" v-html="tts.noti_data.body" :style="{ height : `${scroll_height}px` }">
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class Announcement extends Mixins(VueHoduCommon) {

    noti_uid : string = "";
    tts : any = null;
    audio = new Audio();

    scroll_height : number = 0;

    update_interval : number | undefined;

    target_noti_uid = "";
    goal_repeat_count = 0;
    current_repeat_count = 0;

    mounted() : void {

        this.noti_uid = this.$route.params.uid;

        if( this.noti_uid == null || this.noti_uid.length < 1 ) {
            this.hodu_show_dialog("cancel", "안내방송 조회 중 오류 발생 잠시 후 다시 시도 해주세요", ['확인'], [() => { this.movePrevPage() }]);
            return;
        }

        $(".when_time > select option").prop('disabled',true);

        this.getTTSList();

        // 5분 단위인지 체크후 UI 강제 업데이트
        this.update_interval = window.setInterval(() => {
            
            const target_date = new Date();
            
            if( target_date.getMinutes() % 5 == 0 && target_date.getSeconds() == 0 ) {
                this.$forceUpdate();
            } 
            
        }, 1000);
    }

    beforeDestroy() : void {
        this.audio.pause();
        if( this.update_interval ) { clearInterval(this.update_interval); }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {

            const titleHeight  : number | undefined = $('#announcement .title_box').outerHeight();
            const optionHeight : number | undefined = $('#announcement .annOption').outerHeight();
            const contentTitleHeight : number | undefined = $('#announcement #ann_title').outerHeight();

            console.log(titleHeight);
            console.log(optionHeight);
            console.log(contentTitleHeight);

            this.scroll_height = window.innerHeight - (titleHeight ? titleHeight : 0)
                                                   - (optionHeight ? 2 * optionHeight : 0)
                                                   - (contentTitleHeight ? contentTitleHeight : 0)

            // @ts-ignore
            // $('#modal_ann_contents').mCustomScrollbar({
            //     axis : 'y',
            //     scrollbarPosition : 'outside',
            //     mouseWheelPixels : 100,
            //     scrollInertia : 60,
            //     autoDraggerLength : false,
            //     setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
            //                                 - (optionHeight ? optionHeight : 0)
            //                                 - (optionHeight ? optionHeight : 0)
            //                                 - (contentTitleHeight ? contentTitleHeight : 0)
            // });

        });
    }

    /**
     * 안내방송 리스트 조회
     */
    async getTTSList() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}?page=0&size=0&noti_uid=${this.noti_uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list || response.data.data.list.length < 1 ) {
                throw new Error("안내방송 조회 중 오류 발생");
            }

            const first_init = (this.tts == null);

            this.tts = JSON.parse(JSON.stringify(response.data.data.list[0]));
            this.tts.noti_data.body = this.tts.noti_data.body.split('\n').join("<br>");

            if( first_init ) {
                this.setScroll();
            }

        } catch(e) {
            this.hodu_show_dialog("cancel", "안내방송 조회 중 오류 발생", ["확인"], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 미리 듣기
     */
    async setPreTTS() : Promise<void> {
        this.target_noti_uid = "";
        this.goal_repeat_count = 0;
        this.current_repeat_count = 0;

        this.audio.pause();
        this.audio.src = `app_images/tts/${this.noti_uid}.mp3`;
        this.audio.play();
    }

    /**
     * 안내방송 삭제
     */
    async deleteTTS() : Promise<void> {

        if( this.tts == null ) { 
            return;
        }

        this.hodu_show_dialog('cancel', "정말로 안내방송을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    const response = await this.hodu_api_call(`api/v1/home/tts?noti_uid=${this.noti_uid}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("안내방송 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_show_dialog('cancel', '안내방송 삭제 중 오류 발생', ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ]);

    }

    /**
     * 방송
     */
    async play() : Promise<void> {
        try {

            if( this.tts == null ) {
                return;
            }

            this.audio.pause();

            // 반복 횟수만큼 재생
            this.target_noti_uid = this.tts.noti_uid;
            this.goal_repeat_count = this.tts.noti_info.tts.repeat_count;
            this.current_repeat_count = 0;

            this.current_repeat_count++;
            this.audio.src = `app_images/tts/${this.tts.noti_uid}.mp3`;
            this.audio.onended = () => {
                if( this.target_noti_uid != this.tts.noti_uid || this.goal_repeat_count <= this.current_repeat_count ) {
                    return;
                }

                if( this.goal_repeat_count != this.current_repeat_count ) {
                    this.current_repeat_count++;
                    setTimeout(() => { this.audio.play(); }, 1500); 
                }
            }
            this.audio.play();

            // 방송 완료로 변경
            this.tts.noti_info.tts.complete_yn = 'Y';
            this.tts.noti_data.body = this.tts.noti_data.body.split('<br>').join("\n");
            const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}?noti_uid=${this.tts.noti_uid}&mode=status`, API_METHOD.PUT, {
                "notification" : this.tts
            });

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("안내방송 방송 완료로 수정 중 오류 발생");
            }

            await this.getTTSList();

        } catch(e) {
            this.hodu_show_dialog('cancel', '방송 시작 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 남은 시간 반환
     */
    getRemainTime(time : Date | string) : string {

        if( this.tts == null ) {
            return '';
        }

        time = time instanceof Date ? time : new Date(time);

        const expected_time = time.getTime();
        const current_time = new Date().getTime();

        const offset = expected_time - current_time;

        if( offset < 1 ) {
            return "시간만료";
        }

        const offset_sec = offset / 1000;

        // 일 단위 필요하면 사용
        // const offset_day = offset_sec / 24 / 3600;
        
        const offset_hour = offset_sec / 3600;
        const offset_min = offset_sec / 60;

        // 일 단위 필요하면 사용
        // if( offset_day >= 1 ) {
        //     return `${offset_day.toFixed(0)}일`;
        // }

        if( offset_hour >= 1 ) {
            return `${offset_hour.toFixed(0)}시간`;
        }

        if( offset_min >= 1 ) {
            return `${offset_min.toFixed(0)}분`;
        }

        return `${offset_sec.toFixed(0)}초`;
    }

    moveModify() : void {
        if( this.tts == null ) return;
        this.hodu_router_push(`/home/announcement/modify/${this.tts.noti_uid}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        // $('#modal_ann_contents').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    .announcement .when input:hover, .announcement .when input:focus { color: #273044; cursor: default; }
    .when .when_time:hover select { border: 1px solid #fff; cursor: default; }
    .when .when_time:hover select.ampm { margin-right: 0px; }   
    .when_time p.annTime { color: #273044; font-size: 14px; font-weight: bold; }
    .when_time p.annTime span.remainingT { color: #ff6363; }
    .when_time p.annTime span.completeT { color: #477fff; }
    .annOption .when { width: 40%; border-right: 1px solid #e7e9ea;}

    .left_area_close .announcement { padding-left: 61px !important; }
    #announcement .announcement .annOption > .how_repeat { width: 30%; border-right: 1px solid #e7e9ea; }
    #announcement .announcement .annOption > .how_repeat p.ann_repeat { color: #273044; font-size: 14px; font-weight: bold; line-height: 33px; margin-top: 0; }
    #announcement .announcement .annOption select { cursor: default; }

    .share p.whoWrote { padding-left: 40px; font-weight: bold; font-size: 14px; margin-top: -1px !important; color: #232848 !important; }
    .announcement .share span { position: absolute; left: 55px; width: 100% !important; height: 30px !important; max-width: 200px !important; min-width: 100px !important; z-index: 1000000; top: 36px; line-height: 30px; font-size: 14px; font-weight: bold; }
    .announcement .share span.fPic { left: 24px; top: 39px; border: 1px solid #e7e9ea; background-color: #f1f3f5; font-size: 0; min-width: 27px !important; width: 27px !important; background-position: center center !important; background-size: cover !important; height: 27px !important; border-radius: 50%; }

    #create_datepicker { font-size: 15px; font-weight: bold; }

    .announcement .annOption .play_btn { line-height: 80px; }
    #announcement .annOption .play_btn input { padding-left: 45px !important; transition: .3s !important; border: 1px solid #477fff !important; color: #fff !important; }
    #announcement .annOption .play_btn .annExample_btn { background: url('../../assets/images/contents/hoduhome_Announcement_exampleWhite.svg') no-repeat 11px center #6190fb !important; margin-right: 30px; }
    #announcement .annOption .play_btn .annPlay_btn { background: url('../../assets/images/contents/hoduhome_Announcement_startWhite.svg') no-repeat 11px center #6190fb !important; }
    #announcement .annOption .play_btn .annExample_btn:hover, #announcement .annOption .play_btn .annPlay_btn:hover { background-color: #477fff !important; }

    #modal_ann_contents { transition: 0.1s; line-height: 23px; font-size: 14px; text-align: left; overflow-y: auto; box-sizing: border-box; padding: 25px 30px 50px; }

    #announcement ::-webkit-scrollbar{width: 6px;}
    #announcement ::-webkit-scrollbar-track{background-color: transparent;}
    #announcement ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
</style>