<template>
    <div class="modal visitorModal">
        
        <div class="titlebox">
            <h1>입주자 정보</h1>
        </div>

        <div class="modal_content">
            <ul>
                <li>
                    <p class="title">동&amp;호</p>
                    <p class="descript">{{ `${home_resident_info_modal_info.resident.resident_info.building} 동 ${home_resident_info_modal_info.resident.resident_info.unit} 호` }}</p>
                </li>

                <li>
                    <p class="title">입주자</p>
                    <p class="descript">{{ home_resident_info_modal_info.resident.resident_info.nickname }}</p>
                </li>

                <li>
                    <p class="title">연락처</p>
                    <p class="descript">
                        {{ home_resident_info_modal_info.resident.resident_info.phone && home_resident_info_modal_info.resident.resident_info.phone.length > 0 ? home_resident_info_modal_info.resident.resident_info.phone : '-'  }}
                    </p>
                </li>

                <li>
                    <p class="title">차량</p>
                    <p class="descript">
                        {{ home_resident_info_modal_info.resident.cars.filter(item => item.car_info.is_apply == true).length > 0 ? '' : '-' }}
                        <span :key="index" v-for="(car, index) in home_resident_info_modal_info.resident.cars.filter(item => item.car_info.is_apply == true)" v-show="car.car_info.is_apply == true">
                            <em v-if="home_resident_info_modal_info.resident.cars.filter(item => item.car_info.is_apply == true).length > 0 && car.end_date != null &&
                                        new Date(car.start_date).getTime() <= new Date().getTime() && new Date().getTime() <= new Date(car.end_date).getTime()">계약</em>
                            {{ car.car_info.car_number + `${home_resident_info_modal_info.resident.cars.filter(item => item.car_info.is_apply == true).length == index + 1 ? '' : ',' }` }}
                        </span>
                        <!-- 12가 3456,  78가 9012, <span><em>계약</em>34가 5678</span> -->
                    </p>
                </li>

            </ul>
        </div>

        <div class="btns">
            <input type="button" value="닫기" @click="closeModal">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HomeResidentInfoModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_resident_info_modal_info !: hodu_home_modal_info.HomeResidentInfoModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeResidentInfoModalInfo ?: (params : hodu_home_modal_info.HomeResidentInfoModalInfo) => void;

    mounted() : void {
        
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetHomeResidentInfoModalInfo == null ) { return; }

        this.doSetHomeResidentInfoModalInfo({
            show_modal : false,
            resident : null
        });
    }

}
</script>

<style scoped>
    .modal.visitorModal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-192.5px; margin-left:-260px; width:520px; height: 385px; background:#fff; }
    .modal.visitorModal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0 30px;border-bottom: 1px solid #e7e9ea; }
    .modal.visitorModal .titlebox h1 { font-size: 20px;font-weight: bold;height: 90px;line-height: 110px; }
    
    .modal.visitorModal .modal_content { overflow: hidden; height: 223px;border-bottom: 1px solid #e7e9ea;  }
    .modal.visitorModal .modal_content ul li * { display: inline-block; font-size: 13px; }
    .modal.visitorModal .modal_content ul li { padding:0 30px; overflow: hidden; line-height: 55px; height: 55px; border-bottom:1px solid #f1f3f5;}
    .modal.visitorModal .modal_content ul li .descript { float: right; text-align: right; font-weight: bold }
    .modal.visitorModal .modal_content ul li .descript * { text-align: right; font-weight: bold }
    .modal.visitorModal .modal_content ul li .descript  em {font-size: 11px;font-weight: bold;color: #fff;background: #477fff;font-style: normal;padding: 0 5px;height: 20px;border-radius: 2px;display: inline-block;line-height: 20px;margin-right: 3px;}
    .modal.visitorModal .modal_content ul li .descript input { line-height: 40px; height: 40px; border:1px solid #f1f3f5; padding:0 15px; box-sizing:border-box; margin-top:7px; }
    /*.modal.visitorModal .modal_content ul li .descript span { padding-left: 10px; }*/
    .modal.visitorModal .modal_content ul li .descript input:hover {border-color:#e7e9ea;}
    .modal.visitorModal .modal_content ul li .descript input:focus {border-color:#477fff;}
    .modal.visitorModal .modal_content ul li .descript select { min-width: 100px;position: static !important; float: left; font-size: 12px; font-weight: bold; line-height: 40px; height: 40px; background-color:#fff; padding-right: 35px; padding-left: 15px; appearance: none; -moz-appearance: none; -webkit-appearance: none; border:1px solid #f1f3f5; border-radius: 5px; margin-top:7px; cursor: pointer; }
    .modal.visitorModal .modal_content ul li .descript select:hover {border-color:#e7e9ea;}
    .modal.visitorModal .modal_content ul li .descript select:focus {border-color:#477fff;}

    .modal.visitorModal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    .modal.visitorModal .btns input { transition:0.2s; width: 100%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold;background: #fff; }
    .modal.visitorModal .btns input:hover { background:#f1f3f5; }

    .modalDiv.hoduhome1.on { display: block; }
</style>