<template>
    <!-- 전체 하이트 잡아서 반값 마진탑 주기 -->
	<!-- 체크식(checkModal), 단계식 (stepModal), 현황없는 (noStatusModal)  -->
	<div class="modal taskModal" :class="{ stepModal     : work_data != null && work_data.template_type == 'FLOW', 
                                           checkModal    : work_data != null && work_data.template_type == 'CHECK', 
                                           noStatusModal : work_status_list.length < 1,
                                           cancel        : cancel_bg_open   == true,
                                           complete      : complete_bg_open == true }">
		
        <div class="left_titleBox">
			<!-- 업무 시작 전, 진행 중, 완료로 상황에 따라 변경 -->
			<h1>{{ work_data ? work_data.template_info.name : '' }}</h1>
			<!-- <div class="top_btns">
				<input type="button" class="input_txt canBtn" value="업무 수행 취소" v-if="user_work_status_temp != null" @click="cancelBgOpen"/>
				<input type="button" class="input_txt comBtn" value="업무 완료"      v-if="user_work_status_temp != null" @click="completeBgOpen"/>
			</div> -->
		</div>

		<div class="modal-content">

			<ul v-if="work_data != null">
<!-- ************** 미정 리스트에서 다른사람이 완료한 경우 클래스 추가 selected -->
                <li :key="index" v-for="(content, index) in work_data.template_info.content" 
                    :class="`${hodu_color_dc_lc(content.color)} ${ work_data.template_type == 'CHECK' && getUserStatusByIndex(index) == 'END' ? 'on' : ''} ${isUndefCheckSelectedOtherUser(index) ? 'selected' : ''}`">
                    <!-- 단계식에서만 사용하는 선 -->
					<span class="vertical"></span>

                    <!-- 체크박스 -->
                    <div class="taskAb">

                        <!-- 단계식 현재 위치 stepNow 클래스 (START인 경우, 마지막 index가 END인 경우) -->
                        <p class="stepCheck" v-if="work_data.template_type == 'FLOW'" 
                           :class="{ stepNow : getUserStatusByIndex(index) == 'START' || (index == work_data.template_info.content.length - 1 && getUserStatusByIndex(index) == 'END' ) }">
							<input type="checkbox" :id="`stepCheck${index}`" class="input_check" :checked="getUserStatusByIndex(index) == 'END'" @change="changeFlowWorkStatus($event, index)">
							<label :for="`stepCheck${index}`">{{ index + 1 }}</label>
						</p>

                        <!-- 체크식 -->
                        <p class="checkCheck" v-if="work_data.template_type == 'CHECK'">
							<input type="checkbox" :id="`checkCheck${index}`" class="input_check" :checked="getUserStatusByIndex(index) == 'END'" @change="changeCheckWorkStatus($event, index)">
							<label :for="`checkCheck${index}`"></label>
						</p>

                    </div>

                    <!-- 설명 -->
                    <div class="taskDiv">
<!-- ******************************************** 체크식 업무 아무도 진행 안했을때 글자가 잘리는 문제가 있음! -->
                        <p class="taskTitle">{{ content.title }}</p>
                        <!-- 사용자가 현재 진행 중인 업무 -->
                        <span class="task_ing" v-if="getUserStatusByIndex(index) == 'START'">진행 중</span>
                        <p class="pBar" v-if="work_status_list.length > 0">
							<!-- span 퍼센트 가로값 -->
							<span :style="{ width : `${getProgressByIndex(index)}%`}"></span>
						</p>
                    </div>
                    <div class="selectedBg">
                        <p class="slTxt">이미 다른 담당자가 선택한 업무입니다.</p>
                    </div>
                </li>
				
			</ul>

		</div>

		<!-- 완료, 취소 -->
		<div class="confirmTask">
			
		</div>

		<div class="btns" :class="{ b1 : cancel_bg_open == false }">
			<input type="button" class="input_btn" value="닫기" @click="closeWorkModal" />
		</div>

	</div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const WorkInfo = namespace('WorkInfo');

import { WorkStatusInfo } from '@/store/modules/WorkInfo';

import { t_work_status } from '@/model/osm';

@Component({

}) export default class EventWorkProcess extends Mixins(VueHoduCommon) {
    /**
     * @WorkInfo.State
     */
    @WorkInfo.State work_status_info !: WorkStatusInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventWorkProcess ?: any;

    work_data             : any                  = null;
    work_status_list      : t_work_status[]      = [];
    user_work_status      : t_work_status | null = null;
    user_work_status_temp : t_work_status | null = null;

    cancel_bg_open : boolean = false;
    complete_bg_open : boolean = false;

    async mounted() : Promise<void> {
        $('.taskModal').css('margin-top', window.innerHeight);

        // 업무 현황 조회
        await this.getWorkStatus();
    }

    /**
     * 업무 현황 조회
     */
    async getWorkStatus() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/status/${this.work_status_info.event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);
                vue.work_data = null;
                vue.work_data = response.data.data.list[0];
                vue.work_status_list.splice(0, vue.work_status_list.length);
                vue.work_status_list = vue.work_status_list.concat( vue.work_data.status_info ? vue.work_data.status_info : []);
                
                vue.user_work_status_temp = null;
                for( const work_status of vue.work_status_list ) {
                    if( work_status.user_id == vue.user_id ) {
                        vue.user_work_status = work_status;
                        vue.user_work_status_temp = JSON.parse(JSON.stringify(work_status));
                    }
                }

                // modal 가운데에 맞추기
                setTimeout(() => {
                    const modal_height : number | undefined = $('.taskModal').outerHeight();
                    $('.taskModal').css('margin-top', -( modal_height ? modal_height : 0 ) / 2);
                }, 100);

                // Event.vue 초기화 시키기위해 함수 사용
                await window['setEventWorkData'](vue.work_data.event_data.work, vue.work_status_list);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * index로 사용자의 해당 업무 상태를 반환
     */
    getUserStatusByIndex(index : number) : string {
        try {
            if( index < 0 || this.user_work_status == null || this.user_work_status.work_status_info.progress.length <= index ) {
                return "invalid";
            }

            return this.user_work_status.work_status_info.progress[index].status;
        } catch(e) {
            this.hodu_error_process(e, false, false);
            return "invalid";
        }
    }

    /**
     * index로 해당 업무의 progress % 텍스트 반환
     */
    getProgressByIndex(index : number) : string {
        try { 
            if( index < 0 || !this.work_data || this.work_status_list == null || this.work_status_list.length < 1 ) {
                return `0`;
            }

            let index_progress_count : number = 0;
            for( let work_status of this.work_status_list ) {
                
                // 체크식일땐 END 인것만 progress에 포함
                if( this.work_data.template_type == 'CHECK' && work_status.work_status_info.progress[index] != null && work_status.work_status_info.progress[index].status == 'END' ) {
                    index_progress_count++;
                }

                // 단계식일땐 START 혹은 마지막 업무단계인데 END인 것만 progress에 포함
                else if ( this.work_data.template_type == 'FLOW' && work_status.work_status_info.progress[index] != null && 
                          ( work_status.work_status_info.progress[index].status == 'START' || ( (index == this.work_data.template_info.content.length - 1) && work_status.work_status_info.progress[index].status == 'END') )  ) {
                    index_progress_count++;
                }
            }

            return `${ ( index_progress_count / (this.work_data.event_data.work.assignment_type == 'UNDEF' ? 1 : this.work_status_list.length) * 100 ).toFixed(10) }`;

        } catch(e) {
            this.hodu_error_process(e, false, false);
            return `0`;
        }
    }

    /**
     * 단계식 업무 클릭한 경우
     */
    changeFlowWorkStatus(event : any, index : number) : void {

        // index가 이상한 경우 원래대로 돌리고 return
        if( index < 0 || !this.work_data ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 선착순 업무가 아닌데 배정되지 않은 경우 return
        if( this.work_data.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 중단, 완료된 업무인 경우
        const status_code : string = this.work_data.event_data.work.work_status_code;
        if( status_code == 'CANCEL' || status_code == 'END' ) {
            event.target.checked = !event.target.checked;
            alert(`${ status_code == 'CANCEL' ? '중단' : '완료' }된 업무입니다`);
            return;
        }

        // 이미 누군가 진행중인 미정 업무라면
        if( this.work_data.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null && this.work_status_list.length > 0 ) {
            event.target.checked = !event.target.checked;
            alert('이미 할당 된 업무입니다');
            return;
        }

        // 선착순 단계 업무에서 아무도 배정되지 않은경우
        if( this.work_data.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null && this.work_status_list.length < 1 ) {
            this.user_work_status = {
                event_id : this.work_status_info.event_id,
                user_id : this.user_id,
                scope : this.work_status_info.scope,
                scope_id : this.work_status_info.scope_id,
                last_update_tag : '0',
                audit_modified : new Date(),
                audit_delete_flag : false,
                audit_user_id : this.user_id,
                work_status_info : {
                    is_end : false,
                    progress : []
                }
            };

            // 템플릿 정보 만큼 progress 생성
            const content_count : number = this.work_data.template_info.content.length; 
            for( let i = 0; i < content_count; i++ ) {
                this.user_work_status.work_status_info.progress.push({
                    seq : i,
                    status : 'WAIT'
                });
            }
            
            // 업무자 리스트에 추가
            this.work_status_list.push(this.user_work_status);
        }

        // 유저 업무 상태 정보가 없으면 return
        if( this.user_work_status == null || this.user_work_status.work_status_info.progress[index] == null ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 이미 선택된 곳이라면 return
        if( this.user_work_status.work_status_info.progress[index].status == 'START' || ( (index == this.work_data.template_info.content.length - 1) && this.user_work_status.work_status_info.progress[index].status == 'END') ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 이미 완료한 업무인 경우
        if( this.user_work_status.work_status_info.is_end == true ) {
            event.target.checked = !event.target.checked;
            alert("완료한 업무입니다");
            return;
        }
        
        const progress : any = JSON.parse(JSON.stringify(this.user_work_status.work_status_info.progress));

        const progress_count : number = progress.length;
        for( let i = 0; i < progress_count; i++ ) {
            const progress_fragment : any = JSON.parse(JSON.stringify(progress[i]));
            
            // 클릭한 단계의 이전 단계
            if( i < index ) {
                progress_fragment.status = 'END';
            } 
            
            // 클릭한 단계
            else if ( i == index ) {
                progress_fragment.status = 'START';
            } 
            
            // 클릭한 단계의 이후 단계
            else {
                progress_fragment.status = 'WAIT';
            }

            progress.splice(i, 1, progress_fragment);
        }

        this.user_work_status.work_status_info.progress.splice(0, this.user_work_status.work_status_info.progress.length);
        this.user_work_status.work_status_info.progress = this.user_work_status.work_status_info.progress.concat(progress);

        // 체크값 변경
        $('.modal-content ul > li .taskAb .stepCheck .input_check').each((each_index, item) => {
            if( this.user_work_status == null ) {
                return false;
            }

            if( this.user_work_status.work_status_info.progress[each_index].status == 'END' ) {
                (item as HTMLInputElement).checked = true;
            } else {
                (item as HTMLInputElement).checked = false;
            }
        })

        this.workStatusInsertOrUpdateAndDelete(event, index);
    }

    /**
     * 체크식 업무 클릭한 경우
     */
    changeCheckWorkStatus(event : any, index : number) : void {

        // 배정되지 않은 경우, index가 이상한 경우 원래대로 돌리고 return
        if( index < 0 || !this.work_data ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 선착순 업무가 아닌데 배정되지 않은 경우 return
        if( this.work_data.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 중단, 완료된 업무인 경우
        const status_code : string = this.work_data.event_data.work.work_status_code;
        if( status_code == 'CANCEL' || status_code == 'END' ) {
            event.target.checked = !event.target.checked;
            alert(`${ status_code == 'CANCEL' ? '중단' : '완료' }된 업무입니다`);
            return;
        }

        // 선착순 업무지만 해당 체크가 다른 사람이 이미 체크 했다면 return
        // if( this.work_data.event_data.work.assignment_type == 'UNDEF' ) {
        //     for( let work_status of this.work_status_list ) {
        //         const progress_count : number = work_status.work_status_info.progress.length;

        //         if( work_status.user_id != this.user_id && work_status.work_status_info.progress[index].status == 'END' ) {
        //             event.target.checked = !event.target.checked;
        //             alert('이미 할당 된 업무입니다');
        //             return;
        //         }

        //     }
        // }

        // 선착순 업무에서 해당 체크가 배정되지 않은경우
        // if( this.work_data.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null ) {
        //     this.user_work_status = {
        //         event_id : this.work_status_info.event_id,
        //         user_id : this.user_id,
        //         scope : this.work_status_info.scope,
        //         scope_id : this.work_status_info.scope_id,
        //         last_update_tag : '0',
        //         audit_modified : new Date(),
        //         audit_delete_flag : false,
        //         audit_user_id : this.user_id,
        //         work_status_info : {
        //             is_end : false,
        //             progress : []
        //         }
        //     };

        //     // 템플릿 정보 만큼 progress 생성
        //     const content_count : number = this.work_data.template_info.content.length; 
        //     for( let i = 0; i < content_count; i++ ) {
        //         this.user_work_status.work_status_info.progress.push({
        //             seq : i,
        //             status : 'WAIT'
        //         });
        //     }
            
        //     // 업무자 리스트에 추가
        //     this.work_status_list.push(this.user_work_status);
        // }

        // 유저 업무 상태 정보가 없으면 return
        if( this.user_work_status == null || this.user_work_status.work_status_info.progress[index] == null ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 이미 완료한 업무인 경우
        if( this.user_work_status.work_status_info.is_end == true ) {
            event.target.checked = !event.target.checked;
            alert("완료한 업무입니다");
            return;
        }

        const progress_fragment : any = JSON.parse(JSON.stringify(this.user_work_status.work_status_info.progress[index]));
        progress_fragment.status = event.target.checked ? "END" : "WAIT";
        this.user_work_status.work_status_info.progress.splice(index, 1, progress_fragment);

        // 단일 선택 업무일때 체크를 했다면 다른 업무를 전부 WAIT 상태로 만든다
        if( progress_fragment.status == "END" && this.work_data.template_info.is_multi_select == false ) {
            const progress_count : number = this.user_work_status.work_status_info.progress.length;
            for( let i = 0; i < progress_count; i++ ) {
                if( i == index ) { continue; }
                const progress_frag : any = JSON.parse(JSON.stringify(this.user_work_status.work_status_info.progress[index]));
                progress_frag.status = "WAIT";
                this.user_work_status.work_status_info.progress.splice(i, 1, progress_frag);
            }
        }

        // 미정일때 체크를 전부 없앤다면 완전히 제거 한다
        // if( this.work_data.event_data.work.assignment_type == 'UNDEF' && event.target.checked == false ) {
        //     let flag : boolean = true;
            
        //     // 하나라도 WAIT가 아니라면 false로 만든다
        //     for( let progress of this.user_work_status ? this.user_work_status.work_status_info.progress : [] ) {
        //         flag = flag && progress.status == 'WAIT'
        //     }

        //     // 하나도 체크가 안되어있다면 user_status를 없앤다
        //     if( flag == true ) {
        //         this.user_work_status = null;

        //         for( let i = 0; i < this.work_status_list.length; i++ ) {
        //             if( this.work_status_list[i].user_id == this.user_id ) {
        //                 this.work_status_list.splice(i, 1);
        //                 break;
        //             }
        //         }
        //     }
        // }

        this.workStatusInsertOrUpdateAndDelete(event, index);
    }

    /**
     * 업무 수행 저장
     */
    workStatusInsertOrUpdateAndDelete(event, index : number) : void {
        // 업무 상태 삭제 : deleteWorkStatus (temp != null && status == null)
        if( this.user_work_status_temp != null && this.user_work_status == null ) {
            this.deleteWorkUserStatus();
        }

        // insertOrUpdate 
        else {
            this.insertOrUpdateWorkStatus(event, index);
        }
    }

    /**
     * 업무 상태 삭제
     */
    async deleteWorkUserStatus() : Promise<void> {
        const vue = this;

        let url : string = `api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/${this.work_status_info.event_id}/status/user`;
        await this.hodu_api_call(url, API_METHOD.DELETE)
            .then(async(response) => {
                console.log(response);
                await vue.getWorkStatus();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 업무 상태추가 또는 수정
     */
    async insertOrUpdateWorkStatus(event, index : number) : Promise<void> {
        const vue = this;

        if( this.user_work_status == null ) {
            return;
        }

        this.user_work_status.event_id = this.work_status_info.event_id;
        this.user_work_status.scope    = this.work_status_info.scope;
        this.user_work_status.scope_id = this.work_status_info.scope_id;
        this.user_work_status.user_id  = this.user_id;

        let url : string = `api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/${this.work_status_info.event_id}/status/user?select_seq=${index}`;
        await this.hodu_api_call(url, API_METHOD.PUT, this.user_work_status)
            .then(async(response) => {
                console.log(response);
                await vue.getWorkStatus();
            })
            .catch(async(e) => {
                event.target.checked = !event.target.checked;
                if( e.response && e.response.status == 406 ) {
                    vue.getWorkStatus();
                } 
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 체크 미정 업무에서 이미 할당된 업무인 경우
     */
    isUndefCheckSelectedOtherUser(index : number) : boolean {
        if( this.work_data.event_data.work.assignment_type != 'UNDEF' || this.work_data.event_data.work.template_type != 'CHECK'  ) { 
            return false; 
        }
        
        try {
            for( let status of this.work_status_list ) {
                if( status.user_id == this.user_id ) {
                    continue;
                }

                if( status.work_status_info.progress[index].status != 'WAIT' ) {
                    return true;
                }
            }
        } catch(e) { }

        return false;
    }

    /**
     * 업무 취소 버튼 클릭
     */
    cancelBgOpen() : void {
        this.cancel_bg_open = true;
    }

    /**
     * 업무 완료 버튼 클릭
     */
    completeBgOpen() : void {
        this.complete_bg_open = true;
        // // 완료 API 실행후 this.complete_bg_open = true
        // setTimeout(() => { this.doSetShowEventWorkProcess(false); }, 2000);
    }

    /**
     * 업무 수행 모달 닫기
     */
    closeWorkModal() : void {
        this.doSetShowEventWorkProcess(false);
    }

}
</script>

<style scoped>
    .top_btns { float: right;  padding-right: 40px; }
	.top_btns input {font-size: 14px; font-weight: bold;width: auto;margin-left:25px;display: inline-block; text-align: center;  }
    
	.taskModal {width: 800px; margin:0 auto;background: #fff;position: absolute; left: 50%; top: 50%;  margin-left: -400px; border-radius: 5px; overflow: hidden; transition: 0.3s}
	.taskModal .left_titleBox {overflow: hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit; border-bottom: 1px solid #f1f3f5; }
	.taskModal .left_titleBox h1 {height: auto;font-size: 18px;float: left;}
	
	.taskModal .modal-content.taskModalScroll {max-height: 450px;height: auto;overflow-y: auto;}
	
	.taskModal .modal-content {padding: 0;}
	.taskModal .modal-content > ul { height: auto;overflow: hidden;overflow-y: auto;}
	.taskModal .btns { height:70px; line-height:70px; border-radius:0 0 5px 5px; overflow:hidden }
	.taskModal .btns input { width:50%; height:70px; line-height:60px; border:0 none; float:left;font-size:14px; border-radius:0;}
    .taskModal .btns.b1 input { width:100% }

	.taskModal input[type='checkbox']:not(old) + label {margin-top: 25px;margin-left: 5px;display: inline-block;padding: 0;width: 35px;height: 35px;border-radius:50%;border: 2px solid #c1cfd8;background: url('../../../assets/images/contents/ic_svg_check.svg') no-repeat 8px center;background-size: 18px !important;font-size: 0;margin-right: 8px;}
	.taskModal input[type='checkbox']:not(old):checked + label {fill:#fff;border: 2px solid #477fff;}
    .taskModal input[type='checkbox']:not(old):checked + label { background-color: #477fff; }
    .taskModal.checkModal input[type='checkbox']:not(old):checked + label { background-image: url('../../../assets/images/contents/ic_svg_check_fff.svg') !important; }




    /* 기본 off */
    .checkModal .dc0 input[type='checkbox']:not(old) + label { border-color:#FF6363 !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc0.svg');}
    .checkModal .dc1 input[type='checkbox']:not(old) + label { border-color:#FFA70E !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc1.svg');}
    .checkModal .dc2 input[type='checkbox']:not(old) + label { border-color:#FFC72F !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc2.svg');}
    .checkModal .dc3 input[type='checkbox']:not(old) + label { border-color:#FF198B !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc3.svg');}
    .checkModal .dc4 input[type='checkbox']:not(old) + label { border-color:#00B2C7 !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc4.svg');}
    .checkModal .dc5 input[type='checkbox']:not(old) + label { border-color:#13D08B !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc5.svg');}
    .checkModal .dc6 input[type='checkbox']:not(old) + label { border-color:#4DBAFF !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc6.svg');}
    .checkModal .dc7 input[type='checkbox']:not(old) + label { border-color:#477FFF !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc7.svg');}
    .checkModal .dc8 input[type='checkbox']:not(old) + label { border-color:#6854FF !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc8.svg');}
    .checkModal .dc9 input[type='checkbox']:not(old) + label { border-color:#35405A !important;background-image: url('../../../assets/images/contents/ic_svg_check_dc9.svg');}
    /* 파스텔 off */
    .checkModal .lc0 input[type='checkbox']:not(old) + label { border-color:#B9A494 !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc0.svg');}
    .checkModal .lc1 input[type='checkbox']:not(old) + label { border-color:#E67D9B !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc1.svg');}
    .checkModal .lc2 input[type='checkbox']:not(old) + label { border-color:#FF9898 !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc2.svg');}
    .checkModal .lc3 input[type='checkbox']:not(old) + label { border-color:#AB8DD6 !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc3.svg');}
    .checkModal .lc4 input[type='checkbox']:not(old) + label { border-color:#F3AC77 !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc4.svg');}
    .checkModal .lc5 input[type='checkbox']:not(old) + label { border-color:#E3D37A !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc5.svg');}
    .checkModal .lc6 input[type='checkbox']:not(old) + label { border-color:#8DD6A0 !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc6.svg');}
    .checkModal .lc7 input[type='checkbox']:not(old) + label { border-color:#7197ED !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc7.svg');}
    .checkModal .lc8 input[type='checkbox']:not(old) + label { border-color:#8DAED6 !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc8.svg');}
    .checkModal .lc9 input[type='checkbox']:not(old) + label { border-color:#6F7A93 !important;background-image: url('../../../assets/images/contents/ic_svg_check_lc9.svg');}


    /* 기본 on */
    .checkModal .dc0 input[type='checkbox']:not(old):checked + label {background-color: #FF6363 !important; border-color: #FF6363 !important;}
    .checkModal .dc1 input[type='checkbox']:not(old):checked + label {background-color: #FFA70E !important; border-color:#FFA70E !important;}
    .checkModal .dc2 input[type='checkbox']:not(old):checked + label {background-color: #FFC72F !important; border-color:#FFC72F !important;}
    .checkModal .dc3 input[type='checkbox']:not(old):checked + label {background-color: #FF198B !important; border-color:#FF198B !important;}
    .checkModal .dc4 input[type='checkbox']:not(old):checked + label {background-color: #00B2C7 !important; border-color:#00B2C7 !important;}
    .checkModal .dc5 input[type='checkbox']:not(old):checked + label {background-color: #13D08B !important; border-color:#13D08B !important;}
    .checkModal .dc6 input[type='checkbox']:not(old):checked + label {background-color: #4DBAFF !important; border-color:#4DBAFF !important;}
    .checkModal .dc7 input[type='checkbox']:not(old):checked + label {background-color: #477FFF !important; border-color:#477FFF !important;}
    .checkModal .dc8 input[type='checkbox']:not(old):checked + label {background-color: #6854FF !important; border-color:#6854FF !important;}
    .checkModal .dc9 input[type='checkbox']:not(old):checked + label {background-color: #35405A !important; border-color:#35405A !important;}
    /* 파스텔 off */
    .checkModal .lc0 input[type='checkbox']:not(old):checked + label {background-color: #B9A494 !important; border-color:#B9A494 !important;}
    .checkModal .lc1 input[type='checkbox']:not(old):checked + label {background-color: #E67D9B !important; border-color:#E67D9B !important;}
    .checkModal .lc2 input[type='checkbox']:not(old):checked + label {background-color: #FF9898 !important; border-color:#FF9898 !important;}
    .checkModal .lc3 input[type='checkbox']:not(old):checked + label {background-color: #AB8DD6 !important; border-color:#AB8DD6 !important;}
    .checkModal .lc4 input[type='checkbox']:not(old):checked + label {background-color: #F3AC77 !important; border-color:#F3AC77 !important;}
    .checkModal .lc5 input[type='checkbox']:not(old):checked + label {background-color: #E3D37A !important; border-color:#E3D37A !important;}
    .checkModal .lc6 input[type='checkbox']:not(old):checked + label {background-color: #8DD6A0 !important; border-color:#8DD6A0 !important;}
    .checkModal .lc7 input[type='checkbox']:not(old):checked + label {background-color: #7197ED !important; border-color:#7197ED !important;}
    .checkModal .lc8 input[type='checkbox']:not(old):checked + label {background-color: #8DAED6 !important; border-color:#8DAED6 !important;}
    .checkModal .lc9 input[type='checkbox']:not(old):checked + label {background-color: #6F7A93 !important; border-color:#6F7A93 !important;}


    /* pBar span 색상 */
    /* 기본 off */
    .checkModal .dc0 .pBar span { background-color:#FF6363 !important;}
    .checkModal .dc1 .pBar span { background-color:#FFA70E !important;}
    .checkModal .dc2 .pBar span { background-color:#FFC72F !important;}
    .checkModal .dc3 .pBar span { background-color:#FF198B !important;}
    .checkModal .dc4 .pBar span { background-color:#00B2C7 !important;}
    .checkModal .dc5 .pBar span { background-color:#13D08B !important;}
    .checkModal .dc6 .pBar span { background-color:#4DBAFF !important;}
    .checkModal .dc7 .pBar span { background-color:#477FFF !important;}
    .checkModal .dc8 .pBar span { background-color:#6854FF !important;}
    .checkModal .dc9 .pBar span { background-color:#35405A !important;}
    /* 파스텔 off */
    .checkModal .lc0 .pBar span { background-color:#B9A494 !important;}
    .checkModal .lc1 .pBar span { background-color:#E67D9B !important;}
    .checkModal .lc2 .pBar span { background-color:#FF9898 !important;}
    .checkModal .lc3 .pBar span { background-color:#AB8DD6 !important;}
    .checkModal .lc4 .pBar span { background-color:#F3AC77 !important;}
    .checkModal .lc5 .pBar span { background-color:#E3D37A !important;}
    .checkModal .lc6 .pBar span { background-color:#8DD6A0 !important;}
    .checkModal .lc7 .pBar span { background-color:#7197ED !important;}
    .checkModal .lc8 .pBar span { background-color:#8DAED6 !important;}
    .checkModal .lc9 .pBar span { background-color:#6F7A93 !important;}


    
	.taskModal.stepModal input[type='checkbox']:not(old) + label {width:39px; height: 39px;font-size: 16px; font-weight: bold; background: #c1cfd8;color:#fff; text-align: center; line-height: 39px; border: 0 none}
	.taskModal.stepModal input[type='checkbox']:not(old):checked + label {font-size: 16px; font-weight: bold; background: #c1cfd8; color:#fff; background:#477fff;}
    .taskModal.stepModal .stepNow input[type='checkbox']:not(old) + label { background-image: url('../../../assets/images/contents/ic_svg_check_fff.svg') !important; font-size: 0;background-repeat: no-repeat;background-position: 10px 11.5px;width: 39px;height: 39px;line-height: 39px;background-size: 17px !important;margin-top: 18px;margin-left: 5px; }
    .taskModal.stepModal .stepNow input[type='checkbox']:not(old):checked + label, .taskModal.stepModal li:hover .stepNow  input[type='checkbox']:not(old) + label {  background-image: url('../../../assets/images/contents/ic_svg_check_fff.svg') !important; font-size:0; background-repeat: no-repeat; background-position: 10px 12px; background-size: 17px !important; }
   
    /* 단계식 label 배경 색상 */
    /* 기본 off */
    .stepModal .dc0 input[type='checkbox']:not(old) + label { background-color:#FF6363 !important;}
    .stepModal .dc1 input[type='checkbox']:not(old) + label { background-color:#FFA70E !important;}
    .stepModal .dc2 input[type='checkbox']:not(old) + label { background-color:#FFC72F !important;}
    .stepModal .dc3 input[type='checkbox']:not(old) + label { background-color:#FF198B !important;}
    .stepModal .dc4 input[type='checkbox']:not(old) + label { background-color:#00B2C7 !important;}
    .stepModal .dc5 input[type='checkbox']:not(old) + label { background-color:#13D08B !important;}
    .stepModal .dc6 input[type='checkbox']:not(old) + label { background-color:#4DBAFF !important;}
    .stepModal .dc7 input[type='checkbox']:not(old) + label { background-color:#477FFF !important;}
    .stepModal .dc8 input[type='checkbox']:not(old) + label { background-color:#6854FF !important;}
    .stepModal .dc9 input[type='checkbox']:not(old) + label { background-color:#35405A !important;}
    /* 파스텔 off */
    .stepModal .lc0 input[type='checkbox']:not(old) + label { background-color:#B9A494 !important;}
    .stepModal .lc1 input[type='checkbox']:not(old) + label { background-color:#E67D9B !important;}
    .stepModal .lc2 input[type='checkbox']:not(old) + label { background-color:#FF9898 !important;}
    .stepModal .lc3 input[type='checkbox']:not(old) + label { background-color:#AB8DD6 !important;}
    .stepModal .lc4 input[type='checkbox']:not(old) + label { background-color:#F3AC77 !important;}
    .stepModal .lc5 input[type='checkbox']:not(old) + label { background-color:#E3D37A !important;}
    .stepModal .lc6 input[type='checkbox']:not(old) + label { background-color:#8DD6A0 !important;}
    .stepModal .lc7 input[type='checkbox']:not(old) + label { background-color:#7197ED !important;}
    .stepModal .lc8 input[type='checkbox']:not(old) + label { background-color:#8DAED6 !important;}
    .stepModal .lc9 input[type='checkbox']:not(old) + label { background-color:#6F7A93 !important;}

    /* 단계식  pBar span 색상 */
    /* 기본 off */
    .stepModal .dc0 .pBar span { background-color:#FF6363 !important;}
    .stepModal .dc1 .pBar span { background-color:#FFA70E !important;}
    .stepModal .dc2 .pBar span { background-color:#FFC72F !important;}
    .stepModal .dc3 .pBar span { background-color:#FF198B !important;}
    .stepModal .dc4 .pBar span { background-color:#00B2C7 !important;}
    .stepModal .dc5 .pBar span { background-color:#13D08B !important;}
    .stepModal .dc6 .pBar span { background-color:#4DBAFF !important;}
    .stepModal .dc7 .pBar span { background-color:#477FFF !important;}
    .stepModal .dc8 .pBar span { background-color:#6854FF !important;}
    .stepModal .dc9 .pBar span { background-color:#35405A !important;}
    /* 파스텔 off */
    .stepModal .lc0 .pBar span { background-color:#B9A494 !important;}
    .stepModal .lc1 .pBar span { background-color:#E67D9B !important;}
    .stepModal .lc2 .pBar span { background-color:#FF9898 !important;}
    .stepModal .lc3 .pBar span { background-color:#AB8DD6 !important;}
    .stepModal .lc4 .pBar span { background-color:#F3AC77 !important;}
    .stepModal .lc5 .pBar span { background-color:#E3D37A !important;}
    .stepModal .lc6 .pBar span { background-color:#8DD6A0 !important;}
    .stepModal .lc7 .pBar span { background-color:#7197ED !important;}
    .stepModal .lc8 .pBar span { background-color:#8DAED6 !important;}
    .stepModal .lc9 .pBar span { background-color:#6F7A93 !important;}

    
    .taskModal.stepModal li { border-top:0 none !important; }

	.taskModal label:before  {z-index: 100;content: '';width: 800px;height: 90px;position: absolute;left: -30px;top: -5px; }

	.taskModal .modal-content > ul li { position: relative; height: 90px; padding: 0 60px 0 110px; width: 100%; border-top: 1px solid #f1f3f5; background:none !important; box-sizing:border-box; }
	.taskModal .modal-content > ul li:first-child { border-top:0 none; }
	.taskAb { z-index: 100; position: absolute; left: 30px; top: 0; }
	.taskDiv { position: relative; }
	.taskDiv .pBar { position: absolute;left: 0;top: 55px; width: 100%; border-radius: 5px; background: #f1f3f5; height: 8px; overflow: hidden;}
	.taskDiv .pBar span { transition:0.2s; float: left; display: block; width: 30%; height: 100%; background: #477fff; }
	.taskDiv .taskTitle { font-size: 14px;line-height: 71px; height:50px;font-weight: bold;float: left;width: 580px;box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; }
    .task_ing { float:right; color:#477fff; line-height:71px; font-weight: bold; height:50px; }
	.modal .btns { border-top: 1px solid #f1f3f5; }
	
	.modal.stepModal li:last-child .vertical { display: none !important }
	.vertical {width: 3px;height: 100%;position: absolute;z-index: 1;background: #e3e7ed;border-radius: 0;top: 33px;left: 53px; }
    .taskModal.stepModal .modal-content > ul { padding: 20px 0; }

    .taskModal .modal-content > ul li .selectedBg { display:none; }
    .taskModal .modal-content > ul li.selected .selectedBg { display:block; content:''; position:absolute; z-index:100; background: rgba(255,255,255,0.7); width:100%; height:100%; top:0; left:0; }
    .taskModal .modal-content > ul li.selected .selectedBg .slTxt { font-size: 17px; font-weight: bold; text-align: center; line-height: 90px;  }
	/*   */
	.stepCheck, .vertical { display: none; }
	.modal.stepModal .stepCheck, .modal.stepModal .vertical { display: block; }
	.modal.stepModal .checkCheck { display: none;  }

    .taskModal.stepModal .modal-content > ul li { height: 85px; }
    .taskModal.stepModal label:before { height: 85px; }
    .taskModal.stepModal input[type='checkbox']:not(old) + label { margin-top:22px; width: 33px;height: 33px;line-height:33px;font-size: 14px;margin-left: 8px; transition: 0.1s; }
    .taskModal.stepModal input[type='checkbox']:not(old):checked + label, .taskModal.stepModal input[type='checkbox']:not(old) + label:hover { font-size: 16px;width: 39px;margin-top:18px;height: 39px;line-height:39px; margin-left: 5px; }
    .taskModal.stepModal .taskDiv .taskTitle { line-height: 61px; }
    .taskModal.stepModal .taskDiv .pBar { top: 49px; }

    input:-moz-read-only { color:#35405A }

	.noStatusModal .pBar { display: none; } 
    .noStatusModal .taskTitle { line-height: 90px; height:90px; }
    .canBtn:hover { color:#ff6060 !important }
    .comBtn:hover { color:#477fff !important }
</style>