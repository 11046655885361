@charset "utf-8";
#grupManage h3.titleDep {background: url(../images/contents/ic_grp_sm_usr.png) no-repeat 15px center;}
#memManage h3.titleDep  {background: url(../images/contents/ic_mem_sm_usr.png) no-repeat 15px center; }

.hc .tit_date {background: #fff url(../images/contents/ic_cal_sm_on.png) no-repeat 15px center;}
.hc #grupManage h3.titleDep {background: url(../images/contents/ic_grp_sm_on.png) no-repeat 17px center;}

/* 파일함은 개인달력에서도 나오도록 변경됨 */
#groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc7.png) no-repeat 17px center; /*border-bottom: 1px solid #f1f3f5*/} 

.hc #groupFileTab h3.titleDep {background: url(../images/contents/ic_file_sm_on.png) no-repeat 17px center;}

.hc h3.titleDep:hover, .hc .tit_date:hover { background-color:#f1f3f5 !important; }
.left_inbox02  { margin-bottom: 150px !important; overflow: hidden !important; }

/*일정현황*/
.alldate_box{background-color:#fff;}
.tit_date{position: relative;overflow: hidden;margin-bottom: 0;background: #fff url(../images/contents/ic_cal_sm_usr.png) no-repeat 15px center;transition: 0.1s;border-radius: 0;width: 100%;padding: 0 50px;line-height: 45px;color: #484848;box-sizing: border-box;/* border-bottom: 1px solid #f1f3f5; */font-size: 12px;font-weight: bold;}
.tit_date:hover{}
.alldate_box:hover .tit_date {cursor: pointer;}
#groupListUl { padding-top:15px; }
.date_depth{font-size:12px;padding: 0 22px;}
.date_depth > li{padding:0 0 13px;overflow:hidden;}
.date_depth > li:first-child{border-top:0;}
.date_depth > li ul{display:none;}
.date_depth > li.on ul{display:block}
.date_depth > li#personal > p{background:none;}
.date_depth > li > p{position:relative;background:url('../images/side_area/ic_depthclose_new.png') no-repeat right 7px;}
.date_depth > li > p > span{display:inline-block;padding:4px 0;color:#4e4e4e;cursor:pointer}
.date_depth > li.on > p{padding-bottom:6px;background:url('../images/side_area/ic_depthopen_new.png') no-repeat right 7px;}
.date_depth > li.on > p > span{display:inline-block;padding:4px 0;color:#4e4e4e;cursor:pointer}
.date_depth .date_depth02 {padding-left:10px;}
.date_depth .date_depth02 li {padding:1px 0}
.date_depth .date_depth02 li > p{position:relative;height:  30px;}
.date_depth .date_depth02 li.off > p{background:url('../images/side_area/ic_depth02_close.gif') no-repeat 195px 7px;}
.date_depth .date_depth02 li > p > span{display:inline-block;padding:4px 0;color:#737373;cursor:pointer}
.date_depth .date_depth02 li ul{display:none;}
.date_depth .date_depth02 li.on ul{display:block;float:  left;margin-bottom: 15px;}
.date_depth .date_depth02 li.on > p{padding-bottom:5px;background:url('../images/side_area/ic_depth02_open.gif') no-repeat 195px 7px;/* clear:  both; *//* overflow:  hidden; */height: 25px;}
.date_depth .date_depth02 li.on > p > span{display:inline-block;padding:4px 0;color:#737373;cursor:pointer}
.date_depth .date_depth03 {padding-left:16px;width:  100%;}
.date_depth .date_depth03 li{padding:1px 0;width:  100%;}
.date_depth .date_depth03 li > p{position:relative}
.date_depth .date_depth03 li > p > span{display:inline-block;padding:4px 0;color:#737373;cursor:pointer}

#right_area .right_inbox02 { padding: 0 0; }
#right_area .right_inbox02 .notice {background-color:#fff;margin-top: 0;margin-bottom: 2px;}
#right_area .right_inbox02 .notice:first-child {}
.right_inbox02 .noti_titlebox{position:relative;background:#6754ff;overflow:hidden;border-radius:5px;}
#right_area .right_inbox02 .notice.on { margin-bottom: 0 }
#right_area .right_inbox02 .notice.on .noti_titlebox {transition: 0.2s; border: 1px solid #fff !important; background-color: #fff; box-shadow: 0 4px 9px #ededed; -webkit-box-shadow: 0 4px 9px #ededed; }
#right_area div#myFileBoxDiv {margin-bottom: 100px;}
#right_area .right_inbox02 .notice .tit_noti{float:left;/* padding-top:15px; *//* padding-bottom:13px; */padding-left:20px;height: 61px;line-height: 61px;color: #484848;font-size: 18px;}
#right_area .right_inbox02 .noti_titlebox .tit_noti {/* color: #fff; */ display: inline-block; }


.right_inbox02 .noti_titlebox.mNoti {background: #ffb915;margin-top: 16px;}
#right_area .right_inbox02 .noti_titlebox.mNoti .tit_noti a {/* color:#fff; */}

/* to-do 할일 창 */
#right_area .right_inbox02 .noti_titlebox{position: fixed;width: 510px !important;z-index: 10000;background: #fff;top: 0;height: 60px;overflow:hidden;border-radius:0;border-bottom: 2px solid #e7e9ea;}
.toDoUl li.deadline label span { line-height:60px; }
.toDoUl li.deadline .deadline.start {margin-left: 0;}
.toDoUl li.deadline .deadline {margin-left: 155px;}
.toDoUl p.deadline span {display: inline-block;position: static;width: auto;line-height: 65px;color: #a0a1a2;margin-right: 10px;}
.toDoUl p.deadline span.when { height:auto; }
.toDoUl p.deadline span.dead {opacity:0.8;}
.toDoUl p.deadline.end {left: 90px;}
.toDoUl p.deadline span em {display: inline-block;font-style: normal;padding-left: 5px;}
.toDoUl p.deadline span em.sat {color: #477fff;}
.toDoUl p.deadline span em.sun {color:#ff3c3c;}
.toDoUl p.deadline {display: inline-block;position: absolute; transition:0.2s;right: 8px;left: auto;top: 0;width: auto;color: #fff;height: 20px;}
.toDoUl li:hover p.deadline  {right: 55px;}
.toDoUl li.on p.deadline  {right: 55px;}
.toDoUl p.deadline span.when {font-weight: normal;}
.toDoUl li {overflow:hidden;position: relative;border-bottom:1px solid #f1f3f5;padding: 18px 20px;}
.toDoUl li span {z-index:1000;position: absolute;height:100%;top: -20px;left: -22px;width: 360px;line-height: 60px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.toDoUl input[type='checkbox']:not(old) + label {background: #fff;font-weight: bold;color: #4e4e4e;width: 25px;height: 25px;padding: 0;border-radius: 50%;background-size: 25px; border:2px solid #ced1d6;    position: absolute;
  left: 20px; z-index:111;}
.toDoUl input[type='checkbox']:not(old) + label span { font-weight:bold; font-size: 13px;height:1000px; }
.toDoUl input[type='checkbox']:not(old):checked + label {background:#ffc135 url(../images/contents/checked_fff.png) no-repeat center center;background-size: 25px;border-color:#ffc135}
.toDoUl input[type='checkbox']:not(old):checked + label span { opacity:0.5 }
.toDoUl p.del {display:none;position: absolute;right: 20px;top: 0;width:40px;height:65px;cursor: pointer;background: url(../images/contents/ic_del.svg) no-repeat center center;font-size:0; opacity:0.3}
.toDoUl p.del:hover { opacity:1; }
.toDoUl li span.deco {display: inline-block;width: 0;height: 2px;background: #477fff;height: 1px;
  background: #d0d1d8;
  top: 30px;
  left: 60px;transition: 0.1s;opacity:0;}
.toDoUl li.on span.deco {display:none;width: 290px;opacity:1;transition: 0.1s;}
.toDoUl li:hover .del { display:block }
.toDoUl li.on { background:#fff; transition:0.2s; border-bottom:1px solid #f1f3f5; }
.toDoUl li.on .del { display:block !important; }
#toDoDiv .noResult {width: 100%;height: 150px;z-index: 100000000;background: #fff;top: 63px;box-sizing:border-box; padding: 0 20px;position:static;}
#toDoDiv .noResult span  { background:#f1f3f5; border-radius: 5px; display:inline-block; line-height:60px; width:100%; font-weight:bold; color:#999; margin-top:20px; }

.toDoUl li:hover .more { display:block; }
.toDoUl li.on:hover .more { display:none; }
.toDoUl li:hover .del { display:none; }
.toDoUl .more { display:none; position:absolute; right: 28px;  top:25px; cursor:pointer; opacity:0.3; }
.toDoUl .more:hover { opacity:1; }
.toDoUl .more span { display:inline-block; width:4px; height:4px; background:#000; border-radius:50%; position:static;margin: 0 2px }
.toDoUl p.edit {z-index:10000;display:none;position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: rgba(255, 255, 255, 0.5);}
.toDoUl p.edit span {width: 40px;height: 65px;display: inline-block;position: absolute;right: 65px;left: auto;top: 0;cursor:pointer;background: url(../images/contents/ic_pen.svg) no-repeat center center;font-size:0; }
.toDoUl li.moreOn .del, .toDoUl li.moreOn .edit {display: block !important;opacity:1;z-index: 100000000;}
.toDoUl li.moreOn .more { display:none; }
.toDoUl li .confirm {position: absolute;left: 0;top: 0;box-sizing: border-box;width: 100%;height: 100%;background: rgba(101, 110, 128, 0.8);padding:20px;z-index: 22210000000;}
.toDoUl li .confirm h5, .toDoUl li .confirm p, .toDoUl li .confirm p a {display: inline-block;color: #Fff;}
.toDoUl li .confirm h5 {font-size: 14px;color: #fff;}
.toDoUl li .confirm p a {font-weight: bold;font-size: 12px;width: 65px;text-align: center;margin-left: 10px;border-radius: 20px;background: #fff;color: #477fff;line-height: 30px;}
.toDoUl li .confirm p a:hover { background:#477fff; transition:0.1s; color:#fff; box-shadow: 0px 0px 4px rgba(255,255,255,0.3) }
.toDoUl li .confirm p {float: right;}

.addTodoPage { display:none; }
.addTodoPage p.first, .addTodoPage p.second {padding: 15px 20px 15px;border-bottom: 1px solid #f1f3f5;}
.addTodoPage p.second {width: 100%;height:65px;line-height:65px;box-sizing: border-box;border-right: 1px solid #f1f3f5; margin-bottom:0;}
input#noDeadline {display: none;}
.addTodoPage p.second.end {width: 58%;}
.addTodoPage a.chooseColour {background:#ffc135 url(../images/contents/checked_fff.png) no-repeat center center;font-weight: bold;color: #4e4e4e;margin-right: 23px;margin-left: 6px;width: 30px;height: 30px;padding: 0;float: left;border-radius: 50%;background-size: 25px;display: inline-block;font-size: 0;position: absolute;left: 15px;top: 17px;}
.addTodoPage p.first { overflow:hidden; }
.addTodoPage p.first textarea {overflow: auto;overflow-y: hidden; float:left;border-color:#fff;border-bottom:1px solid #fff;resize:none;width: 100%;min-height: 35px;height:61px;box-sizing:border-box;transition: 0.2s;line-height: 23px;font-size: 16px;font-weight: bold;box-sizing: border-box;padding: 18px 50px 0 64px;border: none;outline: none;-webkit-box-shadow: none;-moz-box-shadow: none;box-shadow: none;}

.addTodoPage p.first textarea:focus { outline:none; border-bottom:1px solid #477fff !important; }
.addTodoPage p.first {padding: 0;position: relative;}
.addTodoPage p.first textarea:hover {background: #f1f3f5;border-color:#f1f3f5;}
.addTodoPage p.first textarea:focus {background: #fff;}
.addTodoPage p.second label {line-height:30px;height: 30px;margin-right: 15px;display:inline-block;font-size:12px;float: left;opacity: 0.7;font-weight: bold;}
.addTodoPage p.second input {line-height:30px;height: 30px;border:0 none;width: 120px !important;font-weight: bold;float: left;box-sizing: border-box;background: transparent;cursor:pointer;padding:0;font-size:12px;}
.addTodoPage p.second.noDeadline input { opacity:0.5; }
.notiWrap .tabs {height: 50px;line-height: 50px;position: relative;font-size: 14px;border-bottom: 3px solid #e7e9ea;}
.notiWrap .tabs a {width: 33.3%;display: inline-block;float: left;text-align: center;position: relative;color: #999;}
.notiWrap .tabs a span { color:#999 }
.notiWrap .tabs a:hover:after, .notiWrap .tabs a.on:after {z-index:1;position: absolute;content: '';width: 100%;height: 3px;display: inline-block;background: #477fff;transition: 0.2s;bottom: -3px;left: 0;}
.notiWrap .tabs a.on, .notiWrap .tabs a.on span {font-weight: bold;color: #000;}
.notiWrap .tabs a:hover, .notiWrap .tabs a:hover span {color: #000;}
.addTodoPage p.second input[type='checkbox']:not(old) + label {float:right;opacity:1;height: 30px;margin: 0;line-height: 30px;box-sizing: border-box;border-radius: 5px;padding:0; text-align: center;width:50px;background: #f1f3f5;background-size: 22px; font-weight:bold;}
.addTodoPage p.second input[type='checkbox']:not(old):checked + label, .addTodoPage p.second:hover input[type='checkbox']:not(old):checked + label { background: #477fff; color:#fff; }
.addTodoPage p.second:hover {background: #f1f3f5;}
.addTodoPage p.second input:hover {color: #477fff;}
.addTodoPage p.second:hover input[type='checkbox']:not(old) + label {background: #fff;}
.addTodoPage .btns { float:right; margin: 10px 20px 0 0; }
.addTodoPage .btns input {margin-left:10px;}
.addTodoPage p.second.end {width: 58%;}
.addTodoPage p.second input:disabled {cursor: default;color: #acb2bf;}

.notiWrap.noEnd .second {width: 100%;}
.notiWrap.noEnd  .second.end {display: none;}
.notiWrap .noEnd input[type='checkbox']:not(old) + label {float:right;opacity:1;height: 30px;margin: 0;line-height: 30px;box-sizing: border-box;border-radius: 5px;padding:0; text-align: center;width:50px;background: #f1f3f5;background-size: 22px; font-weight:bold;}
.notiWrap .noEnd input[type='checkbox']:not(old):checked + label, .addTodoPage p.second:hover input[type='checkbox']:not(old):checked + label { background: #477fff; color:#fff; }
.notiWrap .noEnd:hover {background: #f1f3f5;}
.notiWrap .noEnd input:hover {color: #477fff;}
.notiWrap .noEnd {position: absolute;top: 80px;right: 25px;}

.notiWrap{padding:0 0 10px;margin-top: 63px;position:relative;}
.txt_imgbox{text-align:center;}
.txt_notibox{position:relative; text-align:left;}
.readbox{margin-top:0px;background: url(../images/side_area/bt_close.png) no-repeat 290px 11px;border-bottom: 1px solid #e7e9ea;}

.btn_fd_up{position:absolute; top:0px;left:0;width:100%;;height:40px;z-index:20;}/* side메뉴펼치기 */

.bt_regiBox{position:absolute; top:6px;right:4px}
.bt_regi{position:relative; margin-top:11px;margin-right:10px;display:inline-block; width: 19px; height: 19px;background:url('../images/side_area/ic_add.gif') no-repeat center center;z-index:30;border-radius: 50%;}
.bt_regi:hover  { background: #29c78e url(../images/side_area/ic_add_on.gif) no-repeat center center;  }

.btMoreBox{position:absolute; right:-13px;top:11px;margin-top:1px;font-size: 0;}
a.btMore{display:inline-block;width: 27px;height: 27px;padding: 0 0;background: url('../images/side_area/bt_more.png') no-repeat 12px center;z-index:30;border-radius: 50%;}
a.btMore { width:100%;height:100%;background: none; }
a.btMore:hover {transition: 0.1s;background: #e6e6e6 url(../images/side_area/bt_more.png) no-repeat 12px center;}
a.btMore:hover {background: none; }
a.btMore02{position:relative;display:inline-block;margin-top:10px; margin-right:4px;width:20px;height:8px;padding: 6px 0;background:url('../images/side_area/bt_more.png') no-repeat 3px center;z-index:30;}
a.btMore02:hover {transition: 0.1s; background-position: 7px center;  }
.btMoreBox02{position:absolute;top:0;width:100%;height:100%;font-size:0;right: 0px;transition: 0.1s;}
.btMoreBox02:hover {}
.titleDepthBox a.btMore02 { background: url('../images/side_area/bt_more.png') no-repeat 12px center; width: 40px; height: 40px;  }
.titleDepthBox a.btMore02:hover { background: url('../images/side_area/bt_more.png') no-repeat 17px center; }
.txt_notibox .btMoreBox { z-index:1000; background-color: none; transition:0.1s;left: 0;height: 40px;width: 280px;top: 0; }
.txt_notibox:hover .btMoreBox {border-radius:50%; transition:0.1s; }
.txt_notibox:first-child { border-bottom: 1px solid #e7e9ea;margin-top: 5px;background: url(../images/side_area/bt_close.png) no-repeat 290px 11px; }  

.noti_titlebox .bt_add,.bt_regi, a#toDoBtnReg{position:relative;float:right;display:inline-block;margin-right: 12px;width: 40px;height: 40px;border-radius: 50%;margin-top: 10px;transition: 0.1s;/* margin-right: 9px; */background: transparent url(../images/contents/ic_add.png) no-repeat center center;background-size: 35px;z-index:30;}
.noti_titlebox .bt_add:hover,.noti_titlebox .bt_regi:hover {background: #477fff url(../images/contents/btn_add_fff.png) no-repeat center center;background-size: 25px;}
.noti_titlebox .bt_regi:hover {background-color: #477fff !important;}
a#notiBtnReg:hover, a#toDoBtnReg:hover {
    background-color: #477fff !important;
    background: #477fff url(../images/contents/btn_add_fff.png) no-repeat center center;
    background-size: 35px;
    margin-right: 12px !important;
}
.noti_titlebox.close{margin-bottom: 2px;}
.noti_titlebox.consultingLink > p {padding: 0 20px 0 0;display: inline-block;height: 38px;line-height: 38px;}
.noti_titlebox:hover a.bt_add {background: #7e69ff url(../images/contents/btn_add_fff.png) no-repeat center center;background-size: 25px;}
.noti_titlebox:hover .bt_add, .noti_titlebox:hover .bt_regi {margin-right: 18px;}
.noticont #notiUnreadUl .cont_tit { background:url(../images/contents/ic_latest.png) no-repeat 0px center;animation-name: new;animation-duration: 0.6s;animation-iteration-count: infinite; }
@keyframes new {
  0%   {background-size: 15px;background-position:3px center;}
  25%   {background-size: 10px;background-position:5px center;}
  50%  {background-size: 15px;background-position:3px center;}
  100% {background-size: 15px;background-position:3px center;}
}

.alldate_box.saved .setDiv { display:block;}
.alldate_box.saved #saveStng {}
.setDiv .raDiv span {position: absolute;left: 50px;width: 200px;line-height: 20px;    text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.setDiv input.radio_round[type='radio']:not(old) + label { background: #e5e8ea url(../images/contents/checked_gray.png) no-repeat 1px 2px;font-weight: bold;color: #4e4e4e;width: 20px;height: 20px;padding: 0;border-radius: 50%;background-size: 18px; }
.setDiv input.radio_round[type='radio']:not(old) + label span { font-weight:normal; }
.setDiv input.radio_round[type='radio']:not(old):checked + label {background:#477fff url(../images/contents/checked_fff.png) no-repeat 1px 2px;background-size: 18px;}
.setDiv input.radio_round[type='radio']:not(old):checked + label span { font-weight:bold; }
.setDiv {display:none;height:44px;position:relative;border-top: 1px solid #f1f3f5;box-sizing: border-box;padding: 12px 20px;}
.alldate_box.saved .showSets:hover { }
.alldate_box .showSets {display:block; position: absolute;opacity: 1;height: 100%;width: 100%;border-radius: 0;top: 0;margin: 0;right: 0;z-index: 100;box-sizing: border-box;transition: 0.1s;}
.alldate_box .showSets:hover {background:rgba(0,0,0,0.05) !important;}
.alldate_box .showSets span {display: none;position: absolute;right: -30px;top: 40px;font-size: 12px;width: 70px;height: auto;text-align: center;color: #fff;border-radius: 5px;padding: 10px 10px;background: rgba(46, 56, 86,0.9);}
.alldate_box #saveStng span { font-size:12px; }
.alldate_box #saveStng span {right:-10px;width: 90px;}
.alldate_box #saveStng:hover span { display:block; }
.notice .noticont li{position:relative;padding-top:4px;padding-bottom: 4px;}
.notice .noticont #notiUnreadUl  li:hover a, .notice .noticont #notiUnreadUl  li:hover .groupName { padding-left:10px !important; transition:0.1s;}
.notice .noticont #notiUnreadUl  li:hover .cont_tit { background:none;}
.notice .noticont li:hover .cont_tit a:after { opacity:0.05;border-radius:5px}
.notice .noticont li .cont_tit a{ font-weight: bold;width:100%;font-size:12px;box-sizing: border-box;display:inline-block;padding-left:25px;padding-top:5px;padding-right:10px;padding-bottom:4px;color: #484848;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
.notice .noticont li .cont_tit a:after {    content: '바로가기';display: block;position: absolute;transition: 0.1s;right: 0;top: 0;width: 100%;margin-top: 0;height: 100%;font-size: 0;opacity:0; background:#292d5f}
.notice .noticont li .cont_tit a:hover { color: #484848; }
.notice .noticont li .groupName{cursor:default;padding-left:25px;padding-top:2px;padding-bottom:5px;box-sizing: border-box;font-weight:normal;font-size:12px;color:#989898;white-space:nowrap;text-overflow:ellipsis;width:93%;overflow:hidden}
.notiReadcont li:first-child {border-top: 0 none;}
.notiReadcont li:hover img {box-shadow: 0 2px 4px rgba(0,0,0,0.1);transition: 0.1s;}
.forAb { position: absolute;top: 50%;margin-top: -20px;border-radius: 5px;overflow: hidden;}
.notiReadcont li:hover .forAb {box-shadow:0 2px 5px rgba(0,0,0,0.1);}
.imgDiv {width: 40px;height: 40px;position: relative;overflow:hidden;}
.notiReadcont li img {    background: #d8d3d3;border-radius: 5px;overflow: hidden;margin-top: -19px;border: 1px solid #f1f3f5;min-width: 50%;min-height: 50%;position: absolute;top: -9999px;right: -9999px;bottom: -9999px;left: -9999px;margin: auto;width: auto;height: 100%;}
.notiReadcont li .groupName{float:left;height: 50px;line-height: 50px;padding-left: 55px;width: 100%;box-sizing: border-box;}
.notiReadcont li .groupName a{font-weight: bold;width:210px;font-size:12px;display:inline-block;color:#484848;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
.notiReadcont li .groupName a:hover{ color: #484848;  }
.notiReadcont li .cont_tit {display: none !important;}
.notiReadcont li .cont_tit a{    width: 100%;box-sizing: border-box;position: relative;font-size: 12px;background: #f1f3f5;border-radius: 5px;padding: 13px 13px 13px 27px;display: inline-block;margin: 2px 0;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.notiReadcont li .cont_tit a:after {content: '읽음';font-size:0;display: inline-block;width: 7px;height: 7px;background: #bbbfc3;left: 12px;margin-top: -3px;top: 50%;position: absolute;border-radius: 50%;}
.notiReadcont li .cont_tit a:hover { font-weight:bold; transition:0.1s; }


.section_ce_fix .ui-datepicker{border: 1px solid #eaeaea; box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09); -webkit-box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09);-moz-box-shadow: 0 8px 13px rgba(0, 0, 0, 0.09);}

#right_area .word { line-height: 45px; font-weight: bold;font-size: 13px; } 

/* 파일첨부 */
.fileUploadBox{width:289px;position:relative; }
.file_txt{float:left;width:244px;height:40px;background-color:#fff;border-top:1px solid #ededed;border-left:1px solid #ededed;border-bottom:1px solid #ededed;color:#b3b3b3;box-sizing:border-box;}/* 파일첨부했을시 경로가 나오는 input type="text" */
.fileWrap{display:inline-block; width:43px;height:40px;background:url(../images/contents/input_file.png) 0 0 no-repeat;position:absolute;top:0;right:0 }/* 버튼이미지를 배경으로 삽입 */
.fileWrap:hover{background:url(../images/contents/input_file_on.png) 0 0 no-repeat;}
.file_add{-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter:alpha(opacity=0);opacity:0;width:43px;height:40px;}




#left_area .date_depth .date_depth03 li > p > span {width:173px;}
#left_area .date_depth > li:hover > p {font-weight: bold;cursor: pointer;background: url(../images/side_area/ic_depthclose_new.png) no-repeat 220px 7px;}
#left_area .date_depth input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
#left_area .date_depth input[type='checkbox']:not(old):checked + label {background: #477fff url('../images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;}
#left_area .date_depth02 { padding-left: 10px; margin-bottom:10px; }
#left_area .date_depth02 li .date_depth03 li { height: 25px; }
#left_area .date_depth02 input[type='checkbox']:not(old) + label {position: absolute;left: 0;top: 0;width: 19px;height: 19px;line-height: 19px;background: #c1cfd8 url(../images/contents/checked_fff.png) no-repeat center center;background-size: 17px;border: 0 none;}
#left_area .date_depth02 input[type='checkbox']:not(old):checked + label { background-position: 6px 6px; }
#left_area .date_depth .date_depth02 li > p > span {display: inline-block;width: 187px; padding: 0; box-sizing:border-box;left: 32px;z-index: 1;position: absolute;padding-right: 40px;line-height: 19px;height: 19px;color: #7c88a2;font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; }
#left_area .date_depth02 input[type='checkbox']:not(old):checked + label {background: #477fff url(../images/contents/checked_fff.png) no-repeat center center;background-size: 17px;border: 0 none;width: 19px;height: 19px;}
#left_area .date_depth .date_depth02 li {margin-bottom: 8px;height: 26px;padding: 0;clear:  both;}
#left_area .date_depth > li.on > p,#left_area .date_depth > li.on:hover > p {margin-bottom: 5px;background: url('../images/side_area/ic_depthclose_new.png') no-repeat 205px 7px !important;}
#left_area .date_depth > li > p {background: url(../images/side_area/ic_depthopen_new.png) no-repeat 205px 7px !important;transition:0.1s;}
#left_area .date_depth > li:hover > p {background-position: 205px 7px !important;transition:0.1s;}
#left_area .date_depth .date_depth02 li:last-child { margin-bottom: 0; } 
#left_area .date_depth02 > li > p span {background:none;}
#left_area .date_depth02 > li > p span:hover {font-weight: bold;color: #202A39;}

/* hd */
#left_area .hd > p input[type='checkbox']:not(old):checked + label {background: #ff5555 url(../images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 17px;border: 2px solid #ff5555;}
#left_area .hd ul input[type='checkbox']:not(old):checked + label { background: #ff5555 url(../images/contents/checked_fff.png) no-repeat center center; background-size: 17px;border: 0 none;}
#left_area .hd .date_depth02 .date_depth03 input[type='checkbox']:not(old) + label  {border: 0 none;background:#c1cfd8  url(../images/contents/checked_fff.png) no-repeat center center;background-size: 17px; width:19px; height:19px;}
#left_area .hd .date_depth02 .date_depth03 input[type='checkbox']:not(old):checked + label {width: 19px;height: 19px; box-sizing: border-box;background:#ff5555 url(../images/contents/checked_fff.png) no-repeat center center;background-size: 17px;}

/* #left_area .hd .date_depth02 > li > p span {background: url(../images/side_area/ic_depthclose_new.png) no-repeat 200px 12px !important;transition:0.1s} */
#left_area .hd .date_depth02 > li.on > p span {background: url(../images/side_area/ic_depthopen_new.png) no-repeat 165px 12px !important;transition:0.1s}
#left_area .hd .date_depth02 > li:hover p span {cursor: pointer;background-position: 160px 12px !important;transition:0.1s}
#left_area .hd .date_depth02 > li.on > p span {background: none !important;}

/* hc */
#left_area .hc > p input[type='checkbox']:not(old):checked + label {background: #36bdff url(../images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 17px; border: 2px solid transparent;}
#left_area .hc ul input[type='checkbox']:not(old):checked + label {    background:#36bdff url(../images/contents/checked_fff.png) no-repeat center center; background-size: 17px;border: 0 none;}

#left_area .hc .date_depth02 > li > p span {background: url(../images/side_area/ic_depthclose_new.png) no-repeat 200px 12px !important;transition:0.1s}
/* #left_area .hc .date_depth02 > li.on > p span {background: url(../images/side_area/ic_depthopen_new.png) no-repeat 165px 12px !important;transition:0.1s}
#left_area .hc .date_depth02 > li:hover p span {cursor: pointer;background-position: 160px 12px !important;transition:0.1s} */

/* he */
#left_area .he > p input[type='checkbox']:not(old):checked + label {background: #7e69ff url(../images/contents/checked_fff.png) no-repeat center center;background-size: 17px;border: 2px solid #7e69ff ;}
#left_area .he ul input[type='checkbox']:not(old):checked + label, #left_area .he .class_depth02 p input[type='checkbox']:not(old) + label:hover, #left_area .he .date_depth02 p input[type='checkbox']:not(old) + label:hover {background: #7e69ff url(../images/contents/checked_fff.png) no-repeat center center;background-size: 17px;border: 0 none;}
#left_area .he .date_depth02 .date_depth03 input[type='checkbox']:not(old) + label  {border: 2px solid #d9d9d9;background:#fff url(../images/contents/checked_gray.png) no-repeat center center;background-size: 17px;}
#left_area .he .date_depth02 .date_depth03 input[type='checkbox']:not(old):checked + label, #left_area .he .date_depth02 .date_depth03 input[type='checkbox']:not(old) + label:hover {width: 21px;height: 21px; box-sizing: border-box;border-radius: 5px;border: 2px solid #7e69ff;background:#7e69ff url(../images/contents/checked_fff.png) no-repeat center center;background-size: 17px;}

#left_area .he .date_depth02 > li > p span {background: url(../images/side_area/ic_depthclose_new.png) no-repeat 200px 12px !important;transition:0.1s}
#left_area .he .date_depth02 > li.on > p span {background: url(../images/side_area/ic_depthopen_new.png) no-repeat 165px 12px !important;transition:0.1s}
#left_area .he .date_depth02 > li:hover p span {cursor: pointer;background-position: 160px 12px !important;transition:0.1s}


/* user Scrollbar */
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {-webkit-border-radius: 0 !important;-moz-border-radius: 0 !important; border-radius: 0 !important;}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {background-color: #ececec;}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{/*background-color: #fff; background-color: rgba(255,255,255,0.75);filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; */	background-color: #ececec;}


/* 일정등록 버튼 */
.bt_scheduleBox { right: 40px; bottom:40px; }
.bt_schedule { opacity: 1;z-index: 1000;transition: 0s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
.bt_schedule:hover {background: #477fff url('../images/contents/btn_add_fff.png') no-repeat center center;transition: 0s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}



#groupListUl {padding-bottom: 5px;border-top:1px solid #f1f3f5;}
.titDepthBox.dash_box { border-top: none; }
.titDepthBox.calendar_box { border-top: none; }
.titDepthBox.mSixth { border-top: none; }
.titDepthBox.menu_resident_car { border-top: none; }
/* .titDepthBox.grupall { border-top: none; } */
.tit_date, .titDepthBox {}
.alldate_box .btMoreBox02 {position: absolute;top: 0;}
.alldate_box a {background: none !important;}
.alldate_box .btMoreBox02:hover {right: 0;}
.alldate_box .btMoreBox02:hover {background: #484848 url(../images/side_area/bt_more_fff.png) no-repeat 11px center;}
.alldate_box .btMoreBox02 {transition: 0.1s;background: #616161 url(../images/side_area/bt_more_fff.png) no-repeat 11px center;position: absolute;float: right;display: inline-block;margin-right: -29px !important;width: 25px;height: 25px;border-radius: 50%;margin-top: 10px;transition: 0.1s;padding: 0;z-index: 30;}
.alldate_box {position: relative;}
.alldate_box:hover .btMoreBox02 {margin-right: 18px !important;transition: 0.1s;}

/* 호두웍스 메뉴 */
/* #menu_works { position: relative; } */
/* #menu_works .left_menu { display: none; border-bottom: 1px solid #f1f3f5 !important; border-top: none; } */

#left_area .bt_Contr { display: inline-block; position: absolute; right: 5px; top: 5px; height: 45px !important; border-radius: 50%; width: 45px; background: url('../images/side_area/ic_depthopen_new.png') no-repeat center center; }
#left_area .bt_Contr.on { background-image: url('../images/side_area/ic_depthclose_new.png') ; }

#menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc7.png) no-repeat 15px center; /*border-bottom: 1px solid #f1f3f5; */}
#menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc7.png) no-repeat 17px center; /* border-bottom: 1px solid #f1f3f5; */}
#menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc7.png) no-repeat 17px center; /* border-bottom: 1px solid #f1f3f5; */}
#menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc7.png) no-repeat 17px center; }
#menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc7.png) no-repeat 17px center; /* border-bottom: 1px solid #f1f3f5; */ }
#announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc7.png) no-repeat 17px center; /* border-bottom: 1px solid #f1f3f5; */ }
#equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc7.png) no-repeat 17px center; /* border-bottom: 1px solid #f1f3f5; */ }
#supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc7.png) no-repeat 17px center; /* border-bottom: 1px solid #f1f3f5; */ }
#menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc7.png) no-repeat 17px center; }
#menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc7.png) no-repeat 15px center; }

/* .hc.pr_dc0 .first .grp2 .current .dropdown li.notice a {background: url(../images/menucons/ic_notice_sm_dc0.png) no-repeat center 27px;}
.hc.pr_dc0 .first .grp2 .current .dropdown li.sch a {background: url(../images/menucons/ic_sch_sm_dc0.png) no-repeat center 27px;}
.hc.pr_dc0 .first .grp2 .current .dropdown li.file a {background: url(../images/menucons/ic_file_sm_dc0.png) no-repeat center 27px;}
.hc.pr_dc0 .first .grp2 .current .dropdown li.work a {background: url(../images/menucons/ic_work_sm_dc0.png) no-repeat center 27px;}
.hc.pr_dc0 .first .grp2 .current .dropdown li.msg a {background: url(../images/menucons/ic_msg_sm_dc0.png) no-repeat center 27px;} */

/* 색별로 왼쪽 메뉴 아이콘 색상 변경 */

.hc.pr_dc0 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc0.png) no-repeat 17px center;}
.hc.pr_dc0 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc0.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc0 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc0.png) no-repeat 17px center;}
.hc.pr_dc0 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc0.png) no-repeat 17px center;}
.hc.pr_dc0 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc0.png) no-repeat 17px center;}
.hc.pr_dc0 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc0.png) no-repeat 17px center;}
.hc.pr_dc0 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc0.png) no-repeat 17px center;}
.hc.pr_dc0 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc0.png) no-repeat 17px center;}
.hc.pr_dc0 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc0.png) no-repeat 19px center; background-size: 21px auto;}
.hc.pr_dc0 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc0.png) no-repeat 15px center; }
.hc.pr_dc0 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #menu_attendance_user h3.titleDep, .hc.pr_dc0 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc0.png) no-repeat 16px center; }
.hc.pr_dc0 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc0.png) no-repeat 17px center; }
.hc.pr_dc0 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc0.png) no-repeat 17px center; }

.hc.pr_dc1 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc1.png) no-repeat 17px center;}
.hc.pr_dc1 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc1.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc1 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc1.png) no-repeat 17px center;}
.hc.pr_dc1 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc1.png) no-repeat 17px center;}
.hc.pr_dc1 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc1.png) no-repeat 17px center;}
.hc.pr_dc1 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc1.png) no-repeat 17px center;}
.hc.pr_dc1 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc1.png) no-repeat 17px center;}
.hc.pr_dc1 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc1.png) no-repeat 17px center;}
.hc.pr_dc1 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc1.png) no-repeat 19px center; background-size: 21px auto;}     
.hc.pr_dc1 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc1.png) no-repeat 16px center; }
.hc.pr_dc1 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc1.png) no-repeat 15px center; }
.hc.pr_dc1 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc1.png) no-repeat 16px center; }
.hc.pr_dc1 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc1.png) no-repeat 16px center; }
.hc.pr_dc1 #menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc1.png) no-repeat 16px center; }
.hc.pr_dc1 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc1.png) no-repeat 16px center; }
.hc.pr_dc1 #menu_attendance_user h3.titleDep, .hc.pr_dc1 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc1.png) no-repeat 16px center; }
.hc.pr_dc1 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc1.png) no-repeat 16px center; }
.hc.pr_dc1 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc1.png) no-repeat 16px center; }
.hc.pr_dc1 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc1.png) no-repeat 16px center; }
.hc.pr_dc1 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc1.png) no-repeat 17px center; }
.hc.pr_dc1 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc1.png) no-repeat 17px center; }

.hc.pr_dc2 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc2.png) no-repeat 17px center;}
.hc.pr_dc2 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc2.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc2 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc2.png) no-repeat 17px center;}
.hc.pr_dc2 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc2.png) no-repeat 17px center;}
.hc.pr_dc2 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc2.png) no-repeat 17px center;}
.hc.pr_dc2 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc2.png) no-repeat 17px center;}
.hc.pr_dc2 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc2.png) no-repeat 17px center;}
.hc.pr_dc2 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc2.png) no-repeat 17px center;}
.hc.pr_dc2 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc2.png) no-repeat 19px center; background-size: 21px auto;}
.hc.pr_dc2 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc2.png) no-repeat 16px center; }
.hc.pr_dc2 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc2.png) no-repeat 15px center; }
.hc.pr_dc2 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc2.png) no-repeat 16px center; }
.hc.pr_dc2 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc2.png) no-repeat 16px center; }
.hc.pr_dc2 #menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc2.png) no-repeat 16px center; }
.hc.pr_dc2 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc2.png) no-repeat 16px center; }
.hc.pr_dc2 #menu_attendance_user h3.titleDep, .hc.pr_dc2 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc2.png) no-repeat 16px center; }
.hc.pr_dc2 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc2.png) no-repeat 16px center; }
.hc.pr_dc2 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc2.png) no-repeat 16px center; }
.hc.pr_dc2 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc2.png) no-repeat 16px center; }
.hc.pr_dc2 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc2.png) no-repeat 17px center; }
.hc.pr_dc2 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc2.png) no-repeat 17px center; }

.hc.pr_dc3 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc3.png) no-repeat 17px center;}
.hc.pr_dc3 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc3.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc3 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc3.png) no-repeat 17px center;}
.hc.pr_dc3 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc3.png) no-repeat 17px center;}
.hc.pr_dc3 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc3.png) no-repeat 17px center;}
.hc.pr_dc3 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc3.png) no-repeat 17px center;}
.hc.pr_dc3 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc3.png) no-repeat 17px center;}
.hc.pr_dc3 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc3.png) no-repeat 17px center;}
.hc.pr_dc3 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc3.png) no-repeat 19px center; background-size: 21px auto;}
.hc.pr_dc3 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc3.png) no-repeat 16px center; }
.hc.pr_dc3 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc3.png) no-repeat 15px center; }
.hc.pr_dc3 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc3.png) no-repeat 16px center; }
.hc.pr_dc3 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc3.png) no-repeat 16px center; }
.hc.pr_dc3 #menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc3.png) no-repeat 16px center; }

.hc.pr_dc3 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc3.png) no-repeat 16px center; }
.hc.pr_dc3 #menu_attendance_user h3.titleDep, .hc.pr_dc3 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc3.png) no-repeat 16px center; }
.hc.pr_dc3 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc2.png) no-repeat 16px center; }

.hc.pr_dc3 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc3.png) no-repeat 16px center; }
.hc.pr_dc3 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc3.png) no-repeat 16px center; }
.hc.pr_dc3 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc3.png) no-repeat 17px center; }
.hc.pr_dc3 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc3.png) no-repeat 17px center; }

.hc.pr_dc4 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc4.png) no-repeat 17px center;}
.hc.pr_dc4 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc4.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc4 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc4.png) no-repeat 17px center;}
.hc.pr_dc4 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc4.png) no-repeat 17px center;}
.hc.pr_dc4 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc4.png) no-repeat 17px center;}
.hc.pr_dc4 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc4.png) no-repeat 17px center;}
.hc.pr_dc4 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc4.png) no-repeat 17px center;}
.hc.pr_dc4 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc4.png) no-repeat 16px center;}
.hc.pr_dc4 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc4.png) no-repeat 19px center; background-size: 21px auto;}
.hc.pr_dc4 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc4.png) no-repeat 15px center; }
.hc.pr_dc4 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc4.png) no-repeat 16px center; }
.hc.pr_dc4 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc4.png) no-repeat 16px center; }
.hc.pr_dc4 #menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc4.png) no-repeat 16px center; }
.hc.pr_dc4 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc4.png) no-repeat 16px center; }
.hc.pr_dc4 #menu_attendance_user h3.titleDep, .hc.pr_dc4 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc4.png) no-repeat 16px center; }
.hc.pr_dc4 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc4.png) no-repeat 16px center; }
.hc.pr_dc4 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc4.png) no-repeat 16px center; }
.hc.pr_dc4 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc4.png) no-repeat 16px center; }
.hc.pr_dc4 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc4.png) no-repeat 17px center; }
.hc.pr_dc4 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc4.png) no-repeat 17px center; }

.hc.pr_dc5 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc5.png) no-repeat 17px center;}
.hc.pr_dc5 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc5.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc5 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc5.png) no-repeat 17px center;}
.hc.pr_dc5 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc5.png) no-repeat 17px center;}
.hc.pr_dc5 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc5.png) no-repeat 17px center;}
.hc.pr_dc5 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc5.png) no-repeat 17px center;}
.hc.pr_dc5 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc5.png) no-repeat 17px center;}
.hc.pr_dc5 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc5.png) no-repeat 17px center;}
.hc.pr_dc5 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc5.png) no-repeat 19px center; background-size: 21px auto;}
.hc.pr_dc5 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc5.png) no-repeat 16px center; }
.hc.pr_dc5 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc5.png) no-repeat 15px center; }
.hc.pr_dc5 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc5.png) no-repeat 16px center; }
.hc.pr_dc5 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc5.png) no-repeat 16px center; }
.hc.pr_dc5 #menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc5.png) no-repeat 16px center; }
.hc.pr_dc5 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc5.png) no-repeat 16px center; }
.hc.pr_dc5 #menu_attendance_user h3.titleDep, .hc.pr_dc5 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc5.png) no-repeat 16px center; }
.hc.pr_dc5 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc5.png) no-repeat 16px center; }
.hc.pr_dc5 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc5.png) no-repeat 16px center; }
.hc.pr_dc5 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc5.png) no-repeat 16px center; }
.hc.pr_dc5 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc5.png) no-repeat 17px center; }
.hc.pr_dc5 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc5.png) no-repeat 17px center; }

.hc.pr_dc6 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc6.png) no-repeat 17px center;}
.hc.pr_dc6 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc6.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc6 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc6.png) no-repeat 17px center;}
.hc.pr_dc6 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc6.png) no-repeat 17px center;}
.hc.pr_dc6 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc6.png) no-repeat 17px center;}
.hc.pr_dc6 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc6.png) no-repeat 17px center;}
.hc.pr_dc6 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc6.png) no-repeat 17px center;}
.hc.pr_dc6 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc6.png) no-repeat 17px center;}
.hc.pr_dc6 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc6.png) no-repeat 19px center; background-size: 21px auto;}
.hc.pr_dc6 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc6.png) no-repeat 16px center; }
.hc.pr_dc6 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc6.png) no-repeat 15px center; }
.hc.pr_dc6 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc6.png) no-repeat 16px center; }
.hc.pr_dc6 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc6.png) no-repeat 16px center; }
.hc.pr_dc6 #menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc6.png) no-repeat 16px center; }
.hc.pr_dc6 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc6.png) no-repeat 16px center; }
.hc.pr_dc6 #menu_attendance_user h3.titleDep, .hc.pr_dc6 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc6.png) no-repeat 16px center; }
.hc.pr_dc6 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc6.png) no-repeat 16px center; }
.hc.pr_dc6 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc6.png) no-repeat 16px center; }
.hc.pr_dc6 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc6.png) no-repeat 16px center; }
.hc.pr_dc6 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc6.png) no-repeat 17px center; }
.hc.pr_dc6 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc6.png) no-repeat 17px center; }

.hc.pr_dc7 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc7.png) no-repeat 17px center;}
.hc.pr_dc7 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc7.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc7 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc7.png) no-repeat 17px center;}
.hc.pr_dc7 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc7.png) no-repeat 17px center;}
.hc.pr_dc7 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc7.png) no-repeat 17px center;}
.hc.pr_dc7 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc7.png) no-repeat 17px center;}
.hc.pr_dc7 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc7.png) no-repeat 17px center;}
.hc.pr_dc7 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc7.png) no-repeat 17px center;}
.hc.pr_dc7 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc7.png) no-repeat 19px center; background-size: 21px auto;}
.hc.pr_dc7 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc7.png) no-repeat 16px center; }
.hc.pr_dc7 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc7.png) no-repeat 15px center; }
.hc.pr_dc7 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc7.png) no-repeat 16px center; }
.hc.pr_dc7 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc7.png) no-repeat 16px center; }
.hc.pr_dc7 #menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc7.png) no-repeat 16px center; }
.hc.pr_dc7 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc7.png) no-repeat 16px center; }
.hc.pr_dc7 #menu_attendance_user h3.titleDep, .hc.pr_dc7 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc7.png) no-repeat 16px center; }
.hc.pr_dc7 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc7.png) no-repeat 16px center; }
.hc.pr_dc7 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc7.png) no-repeat 16px center; }
.hc.pr_dc7 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc7.png) no-repeat 16px center; }
.hc.pr_dc7 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc7.png) no-repeat 17px center; }
.hc.pr_dc7 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc7.png) no-repeat 17px center; }

.hc.pr_dc8 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc8.png) no-repeat 17px center;}
.hc.pr_dc8 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc8.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc8 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc8.png) no-repeat 17px center;}
.hc.pr_dc8 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc8.png) no-repeat 17px center;}
.hc.pr_dc8 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc8.png) no-repeat 17px center;}
.hc.pr_dc8 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc8.png) no-repeat 17px center;}
.hc.pr_dc8 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc8.png) no-repeat 17px center;}
.hc.pr_dc8 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc8.png) no-repeat 17px center;}
.hc.pr_dc8 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc8.png) no-repeat 19px center; background-size: 21px auto;}
.hc.pr_dc8 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc8.png) no-repeat 16px center; }
.hc.pr_dc8 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc8.png) no-repeat 15px center; }
.hc.pr_dc8 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc8.png) no-repeat 16px center; }
.hc.pr_dc8 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc8.png) no-repeat 16px center; }
.hc.pr_dc8 #menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc8.png) no-repeat 16px center; }
.hc.pr_dc8 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc8.png) no-repeat 16px center; }
.hc.pr_dc8 #menu_attendance_user h3.titleDep, .hc.pr_dc8 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc8.png) no-repeat 16px center; }
.hc.pr_dc8 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc8.png) no-repeat 16px center; }
.hc.pr_dc8 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc8.png) no-repeat 16px center; }
.hc.pr_dc8 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc8.png) no-repeat 16px center; }
.hc.pr_dc8 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc8.png) no-repeat 17px center; }
.hc.pr_dc8 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc8.png) no-repeat 17px center; }

.hc.pr_dc9 .tit_date {background: #fff url(../images/menucons/ic_cal_sm_dc9.png) no-repeat 17px center;}
.hc.pr_dc9 #grupManage h3.titleDep {background: url(../images/menucons/ic_grp_sm_dc9.png) no-repeat 17px center;}
.hoduHome.hc.pr_dc9 #grupManage h3.titleDep {background: url(../images/menucons/ic_apt_sm_dc9.png) no-repeat 17px center;}
.hc.pr_dc9 #grupMemManage h3.titleDep {background: url(../images/menucons/ic_mem_list_sm_dc9.png) no-repeat 17px center;}
.hc.pr_dc9 #groupFileTab h3.titleDep {background: url(../images/menucons/ic_file_sm_dc9.png) no-repeat 17px center;}
.hc.pr_dc9 .titDepthBox#grupMms h3.titleDep {background: url(../images/menucons/ic_msg_sm_dc9.png) no-repeat 17px center;}
.hc.pr_dc9 .titDepthBox#workTab h3.titleDep {background: url(../images/menucons/ic_work_sm_dc9.png) no-repeat 17px center;}
.hc.pr_dc9 .titDepthBox.grpNoticeTab h3.titleDep{background: url(../images/menucons/ic_notice_sm_dc9.png) no-repeat 17px center;}
.hc.pr_dc9 .titDepthBox.grpCommunityTab h3.titleDep{background: url(../images/menucons/ic_bulletinboard_sm_dc9.png) no-repeat 19px center; background-size: 21px auto;}
.hc.pr_dc9 #menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc9.png) no-repeat 16px center; }
.hc.pr_dc9 #menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_chatting h3.titleDep { background: url(../images/menucons/ic_cr_sm_dc9.png) no-repeat 15px center; }
.hc.pr_dc9 #menu_resident h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc9.png) no-repeat 16px center; }
.hc.pr_dc9 #menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc9.png) no-repeat 16px center; }
.hc.pr_dc9 #menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_apartment_setting h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_attendance_admin_3 h3.titleDep { background: url(../images/menucons/ic_setng_sm_dc9.png) no-repeat 16px center; }
.hc.pr_dc9 #menu_organization_setting h3.titleDep { background: url(../images/menucons/ic_mem_list_sm_dc9.png) no-repeat 16px center; }
.hc.pr_dc9 #menu_attendance_user h3.titleDep, .hc.pr_dc9 #menu_attendance h3.titleDep { background: url(../images/menucons/ic_atndnce_sm_dc9.png) no-repeat 16px center; }
.hc.pr_dc9 #menu_organization_vacation_setting h3.titleDep { background: url(../images/menucons/ic_vacay_sm_dc9.png) no-repeat 16px center; }
.hc.pr_dc9 #approval h3.titleDep { background: url(../images/menucons/ic_eprv_sm_dc9.png) no-repeat 16px center; }
.hc.pr_dc9 #approval_setting h3.titleDep { background: url(../images/menucons/ic_eprvset_sm_dc9.png) no-repeat 16px center; }
.hc.pr_dc9 #menu_dash h3.titleDep { background: url(../images/menucons/ic_dash_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_works h3.titleDep { background: url(../images/menucons/ic_works_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_calendar h3.titleDep { background: url(../images/menucons/ic_cal_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_resident_car h3.titleDep { background: url(../images/menucons/ic_residentCar_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #announcement h3.titleDep { background: url(../images/menucons/ic_announcement_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #equipment h3.titleDep { background: url(../images/menucons/ic_equipment_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #supply h3.titleDep { background: url(../images/menucons/ic_supply_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_friend h3.titleDep { background: url(../images/menucons/ic_friend_sm_dc9.png) no-repeat 17px center; }
.hc.pr_dc9 #menu_temp h3.titleDep { background: url(../images/menucons/ic_temps_sm_dc9.png) no-repeat 17px center; }

#menu_doctor h3.titleDep { background: url(../images/menucons/ic_doc_sm_dc7.png) no-repeat 17px center; }
#menu_patient h3.titleDep { background: url(../images/menucons/ic_patient_sm_dc7.png) no-repeat 17px center; }
#menu_hopital_setting h3.titleDep { background: url(../images/menucons/ic_hospital_sm_dc7.png) no-repeat 17px center; }
#menu_appointment h3.titleDep { background: url(../images/menucons/ic_reservation_sm_dc7.png) no-repeat 17px center; }
#menu_resident h3.titleDep { background: url(../images/menucons/ic_resident_sm_dc7.png) no-repeat 16px center; }
#menu_parking h3.titleDep { background: url(../images/menucons/ic_visitor_sm_dc7.png) no-repeat 16px center; }
#menu_apt_setting h3.titleDep { background: url(../images/menucons/ic_apt_sm_dc7.png) no-repeat 17px center; }

.hc.pr_dc0 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc0.png) no-repeat 17px center;}
.hc.pr_dc1 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc1.png) no-repeat 17px center;}
.hc.pr_dc2 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc2.png) no-repeat 17px center;}
.hc.pr_dc3 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc3.png) no-repeat 17px center;}
.hc.pr_dc4 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc4.png) no-repeat 17px center;}
.hc.pr_dc5 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc5.png) no-repeat 17px center;}
.hc.pr_dc6 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc6.png) no-repeat 17px center;}
.hc.pr_dc7 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc7.png) no-repeat 17px center;}
.hc.pr_dc8 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc8.png) no-repeat 17px center;}
.hc.pr_dc9 #menu_kids_list h3.titleDep {background: url(../images/menucons/ic_mem_sm_dc9.png) no-repeat 17px center;}

.hc.pr_dc0 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc0.png) no-repeat 17px center;}
.hc.pr_dc1 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc1.png) no-repeat 17px center;}
.hc.pr_dc2 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc2.png) no-repeat 17px center;}
.hc.pr_dc3 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc3.png) no-repeat 17px center;}
.hc.pr_dc4 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc4.png) no-repeat 17px center;}
.hc.pr_dc5 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc5.png) no-repeat 17px center;}
.hc.pr_dc6 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc6.png) no-repeat 17px center;}
.hc.pr_dc7 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc7.png) no-repeat 17px center;}
.hc.pr_dc8 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc8.png) no-repeat 17px center;}
.hc.pr_dc9 #menu_teacher h3.titleDep {background: url(../images/menucons/ic_resident_sm_dc9.png) no-repeat 17px center;}

.hc.pr_dc0 .bt_leftContrBox:before { background-color:#FF6363 !important;background-image: linear-gradient(to right, #FF6363 , #ff1417 141%)!important;}
.hc.pr_dc0 .firstPage {background-color: #FF6363 !important; background-image: linear-gradient(to bottom, #FF6363 , #ff1417 141%)!important;}
.hc.pr_dc0 .firstPage.end1 { background-image: linear-gradient(to right, #FF6363 , #ff1417 141%)!important;}
.hc.pr_dc0 th.fc-day-header.fc-widget-header.fc-today { background:#FF6363 !important; }
.hc.pr_dc0 .bt_schedule { background-color:#FF6363 !important;box-shadow:  0 10px 15px rgba(255, 99, 99, 0.3) !important;  }
.hc.pr_dc0 .scrollUp, .hc.pr_dc0 .scrollUp:hover  { background-color:#FF6363 !important; box-shadow:  0 10px 15px rgba(255, 99, 99, 0.3) !important; }
.hc.pr_dc0 .btn_resend.bt_mms { background-color:#FF6363 !important; box-shadow:  0 10px 15px rgba(255, 99, 99, 0.3) !important; }
.hc.pr_dc0 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc0  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#FF6363 !important; border-color:#FF6363 !important }
.hc.pr_dc0 input#showPhone {background-color:#FF6363 !important;box-shadow:  0 10px 15px rgba(255, 99, 99, 0.3) !important; }
.hc.pr_dc0 #addTeam { background-color: #FF6363 !important; box-shadow:  0 10px 15px rgba(255, 99, 99, 0.3) !important;}
.hc.pr_dc0 #inviteMate { background-color: #FF6363 !important; box-shadow:  0 10px 15px rgba(255, 99, 99, 0.3) !important;}
/* .hc.pr_dc0 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc0 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc0 .ui-datepicker-today:hover a,.hc.pr_dc0 .ui-datepicker-today .ui-state-active,.hc.pr_dc0 .ui-datepicker-today .ui-state-active { background-color:#FF6363 !important; } */
.hc.pr_dc0 .first .myPage a { background-color:#FF6363 !important;}
.hc.pr_dc0 .lds-ellipsis div { background-color:#FF6363 !important; }

.hc.pr_dc1 .bt_leftContrBox:before { background-color:#FFA70E !important;background-image: linear-gradient(to right, #FFA70E , #ff8504 141%)!important;}
.hc.pr_dc1 .firstPage {background-color: #FFA70E !important; background-image: linear-gradient(to bottom, #FFA70E , #ff8504 141%)!important;}
.hc.pr_dc1 .firstPage.end1 { background-image: linear-gradient(to right, #FFA70E , #ff8504 141%)!important;}
.hc.pr_dc1 th.fc-day-header.fc-widget-header.fc-today { background:#FFA70E !important; }
.hc.pr_dc1 .bt_schedule { background-color:#FFA70E !important;box-shadow: 0 10px 15px rgba(255, 167, 14, 0.3) !important;  }
.hc.pr_dc1 .scrollUp, .hc.pr_dc1 .scrollUp:hover { background-color:#FFA70E !important; box-shadow: 0 10px 15px rgba(255, 167, 14, 0.3) !important; }
.hc.pr_dc1 .btn_resend.bt_mms { background-color:#FFA70E !important; box-shadow: 0 10px 15px rgba(65, 55, 40, 0.3) !important; }
.hc.pr_dc1 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc1  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#FFA70E !important; border-color:#FFA70E !important }
.hc.pr_dc1 input#showPhone {background-color:#FFA70E !important;box-shadow: 0 10px 15px rgba(255, 167, 14, 0.3) !important; }
.hc.pr_dc1 #addTeam { background-color: #FFA70E !important; box-shadow:  0 10px 15px rgba(255, 167, 14, 0.3) !important;}
.hc.pr_dc1 #inviteMate { background-color: #FFA70E !important; box-shadow:  0 10px 15px rgba(255, 167, 14, 0.3) !important;}
.hc.pr_dc1 .first .myPage a { background-color:#FFA70E !important;}
.hc.pr_dc1 .lds-ellipsis div { background-color:#FFA70E !important; }


.hc.pr_dc2 .bt_leftContrBox:before { background-color:#FFC72F !important;background-image: linear-gradient(to right, #FFC72F , #ffa600 141%)!important;}
.hc.pr_dc2 .firstPage {background-color: #FFC72F !important; background-image: linear-gradient(to bottom, #FFC72F , #ffa600 141%)!important;}
.hc.pr_dc2 .firstPage.end1 { background-image: linear-gradient(to right, #FFC72F , #ffa600 141%)!important;}
.hc.pr_dc2 th.fc-day-header.fc-widget-header.fc-today { background:#FFC72F !important; }
.hc.pr_dc2 .bt_schedule { background-color:#FFC72F !important;box-shadow: 0 10px 15px rgba(255, 167, 14, 0.3) !important;  }
.hc.pr_dc2 .scrollUp,  .hc.pr_dc2 .scrollUp:hover  { background-color:#FFC72F !important; box-shadow: 0 10px 15px rgba(255, 167, 14, 0.3) !important; }
.hc.pr_dc2 .btn_resend.bt_mms { background-color:#FFC72F !important; box-shadow: 0 10px 15px rgba(255, 167, 14, 0.3) !important; }
.hc.pr_dc2 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc2  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#FFC72F !important; border-color:#FFC72F !important }
.hc.pr_dc2 input#showPhone {background-color:#FFC72F !important;box-shadow: 0 10px 15px rgba(255, 167, 14, 0.3) !important; }
.hc.pr_dc2 #addTeam { background-color: #FFC72F !important; box-shadow:  0 10px 15px rgba(255, 167, 14, 0.3) !important;}
.hc.pr_dc2 #inviteMate { background-color: #FFC72F !important; box-shadow:  0 10px 15px rgba(255, 167, 14, 0.3) !important;}
.hc.pr_dc2 .first .myPage a { background-color:#FFC72F !important;}
.hc.pr_dc2 .lds-ellipsis div { background-color:#FFC72F !important; }

.hc.pr_dc3 .bt_leftContrBox:before { background-color:#FF198B !important;background-image: linear-gradient(to right, #FF198B , #ff003a 141%)!important;}
.hc.pr_dc3 .firstPage {background-color: #FF198B !important; background-image: linear-gradient(to bottom, #FF198B , #ff003a 141%)!important;}
.hc.pr_dc3 .firstPage.end1 { background-image: linear-gradient(to right, #FF198B , #ff003a 141%)!important;}
.hc.pr_dc3 th.fc-day-header.fc-widget-header.fc-today { background:#FF198B !important; }
.hc.pr_dc3 .bt_schedule { background-color:#FF198B !important;box-shadow: 0 10px 15px rgba(255, 25, 139, 0.3) !important;  }
.hc.pr_dc3 .scrollUp, .hc.pr_dc3 .scrollUp:hover  { background-color:#FF198B !important; box-shadow: 0 10px 15px rgba(255, 25, 139, 0.3) !important; }
.hc.pr_dc3 .btn_resend.bt_mms { background-color:#FF198B !important; box-shadow: 0 10px 15px rgba(255, 25, 139, 0.3) !important; }
.hc.pr_dc3 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc3  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#FF198B !important; border-color:#FF198B !important }
.hc.pr_dc3 input#showPhone {background-color:#FF198B !important;box-shadow: 0 10px 15px rgba(255, 25, 139, 0.3) !important; }
.hc.pr_dc3 #addTeam { background-color: #FF198B !important; box-shadow:  0 10px 15px rgba(255, 25, 139, 0.3)  !important;}
.hc.pr_dc3 #inviteMate { background-color: #FF198B !important; box-shadow:  0 10px 15px rgba(255, 25, 139, 0.3) !important;}
.hc.pr_dc3 .first .myPage a { background-color:#FF198B !important;}
.hc.pr_dc3 .lds-ellipsis div { background-color:#FF198B !important; }


.hc.pr_dc4 .bt_leftContrBox:before { background-color:#00B2C7 !important;background-image: linear-gradient(to right, #00B2C7 , #028bc1 141%)!important;}
.hc.pr_dc4 .firstPage {background-color: #00B2C7 !important; background-image: linear-gradient(to bottom, #00B2C7 , #028bc1 141%)!important;}
.hc.pr_dc4 .firstPage.end1 { background-image: linear-gradient(to right, #00B2C7 , #028bc1 141%)!important;}
.hc.pr_dc4 th.fc-day-header.fc-widget-header.fc-today { background:#00B2C7 !important; }
.hc.pr_dc4 .bt_schedule { background-color:#00B2C7 !important;box-shadow: 0 10px 15px rgba(0, 178, 199, 0.3) !important;  }
.hc.pr_dc4 .scrollUp,  .hc.pr_dc4 .scrollUp:hover  { background-color:#00B2C7 !important; box-shadow: 0 10px 15px rgba(0, 178, 199, 0.3) !important; }
.hc.pr_dc4 .btn_resend.bt_mms { background-color:#00B2C7 !important; box-shadow: 0 10px 15px rgba(0, 178, 199, 0.3) !important; }
.hc.pr_dc4 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc4  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#00B2C7 !important; border-color:#00B2C7 !important }
.hc.pr_dc4 input#showPhone {background-color:#00B2C7 !important;box-shadow: 0 10px 15px rgba(0, 178, 199, 0.3); }
.hc.pr_dc4 #addTeam { background-color: #00B2C7 !important; box-shadow: 0 10px 15px rgba(0, 178, 199, 0.3)  !important;}
.hc.pr_dc4 #inviteMate { background-color: #00B2C7 !important; box-shadow:  0 10px 15px rgba(0, 178, 199, 0.3) !important;}
.hc.pr_dc4 .first .myPage a { background-color:#00B2C7 !important;}
.hc.pr_dc4 .lds-ellipsis div { background-color:#00B2C7 !important; }


.hc.pr_dc5 .bt_leftContrBox:before { background-color:#13D08B !important;background-image: linear-gradient(to right, #13D08B , #00925b 141%)!important;}
.hc.pr_dc5 .firstPage {background-color: #13D08B !important; background-image: linear-gradient(to bottom, #13D08B , #00925b 141%)!important;}
.hc.pr_dc5 .firstPage.end1 { background-image: linear-gradient(to right, #13D08B , #00925b 141%)!important;}
.hc.pr_dc5 th.fc-day-header.fc-widget-header.fc-today { background:#13D08B !important; }
.hc.pr_dc5 .bt_schedule { background-color:#13D08B !important;box-shadow: 0 10px 15px rgba(19, 208, 139, 0.3) !important;  }
.hc.pr_dc5 .scrollUp,  .hc.pr_dc5 .scrollUp:hover   { background-color:#13D08B !important; box-shadow: 0 10px 15px rgba(19, 208, 139, 0.3) !important; }
.hc.pr_dc5 .btn_resend.bt_mms { background-color:#13D08B !important; box-shadow: 0 10px 15px rgba(19, 208, 139, 0.3) !important; }
.hc.pr_dc5 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc5  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#13D08B !important; border-color:#13D08B !important }
.hc.pr_dc5 input#showPhone {background-color:#13D08B !important;box-shadow: 0 10px 15px rgba(19, 208, 139, 0.3) !important; }
.hc.pr_dc5 #addTeam { background-color: #13D08B !important; box-shadow:  0 10px 15px rgba(19, 208, 139, 0.3) !important;}
.hc.pr_dc5 #inviteMate { background-color: #13D08B !important; box-shadow:  0 10px 15px rgba(19, 208, 139, 0.3) !important;}
.hc.pr_dc5 .first .myPage a { background-color:#13D08B !important;}
.hc.pr_dc5 .lds-ellipsis div { background-color:#13D08B !important; }



.hc.pr_dc6 .bt_leftContrBox:before { background-color:#4DBAFF !important;background-image: linear-gradient(to right, #4DBAFF , #0096f3 141%)!important;}
.hc.pr_dc6 .firstPage {background-color: #4DBAFF !important; background-image: linear-gradient(to bottom, #4DBAFF , #0096f3 141%)!important;}
.hc.pr_dc6 .firstPage.end1 { background-image: linear-gradient(to right, #4DBAFF , #0096f3 141%)!important;}
.hc.pr_dc6 th.fc-day-header.fc-widget-header.fc-today { background:#4DBAFF !important; }
.hc.pr_dc6 .bt_schedule { background-color:#4DBAFF !important;box-shadow: 0 10px 15px rgba(77, 186, 255, 0.3) !important;  }
.hc.pr_dc6 .scrollUp, .hc.pr_dc6 .scrollUp:hover  { background-color:#4DBAFF !important; box-shadow: 0 10px 15px rgba(77, 186, 255, 0.3) !important; }
.hc.pr_dc6 .btn_resend.bt_mms { background-color:#4DBAFF !important; box-shadow: 0 10px 15px rgba(77, 186, 255, 0.3) !important; }
.hc.pr_dc6 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc6  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#4DBAFF !important; border-color:#4DBAFF !important }
.hc.pr_dc6 input#showPhone {background-color:#4DBAFF !important;box-shadow: 0 10px 15px rgba(77, 186, 255, 0.3) !important; }
.hc.pr_dc6 #addTeam { background-color: #4DBAFF !important; box-shadow:  0 10px 15px rgba(77, 186, 255, 0.3) !important;}
.hc.pr_dc6 #inviteMate { background-color: #4DBAFF !important; box-shadow:  0 10px 15px rgba(77, 186, 255, 0.3) !important;}
.hc.pr_dc6 .first .myPage a { background-color:#4DBAFF !important;}
.hc.pr_dc6 .lds-ellipsis div { background-color:#4DBAFF !important; }



.hc.pr_dc7 .bt_leftContrBox:before { background-color:#477FFF !important;background-image: linear-gradient(to right, #477FFF , #004bff 141%)!important;}
.hc.pr_dc7 .firstPage {background-color: #477FFF !important; background-image: linear-gradient(to bottom, #477FFF , #004bff 141%)!important;}
.hc.pr_dc7 .firstPage.end1 { background-image: linear-gradient(to right, #477FFF , #004bff 141%)!important;}
.hc.pr_dc7 th.fc-day-header.fc-widget-header.fc-today { background:#477FFF !important; }
.hc.pr_dc7 .bt_schedule { background-color:#477FFF !important;box-shadow: 0 10px 15px rgba(71, 127, 255, 0.3) !important;  }
.hc.pr_dc7 .scrollUp, .hc.pr_dc7 .scrollUp:hover  { background-color:#477FFF !important; box-shadow: 0 10px 15px rgba(71, 127, 255, 0.3) !important; }
.hc.pr_dc7 .btn_resend.bt_mms { background-color:#477FFF !important; box-shadow: 0 10px 15px rgba(71, 127, 255, 0.3) !important; }
.hc.pr_dc7 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc7  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#477FFF !important; border-color:#477FFF !important }
.hc.pr_dc7 input#showPhone {background-color:#477FFF !important;box-shadow: 0 10px 15px rgba(71, 127, 255, 0.3) !important; }
.hc.pr_dc7 #addTeam { background-color: #477FFF !important; box-shadow:  0 10px 15px rgba(71, 127, 255, 0.3) !important;}
.hc.pr_dc7 #inviteMate { background-color: #477FFF !important; box-shadow:  0 10px 15px rgba(71, 127, 255, 0.3) !important;}
.hc.pr_dc7 .first .myPage a { background-color:#477FFF !important;}
.hc.pr_dc7 .lds-ellipsis div { background-color:#477FFF !important; }



.hc.pr_dc8 .bt_leftContrBox:before { background-color:#6854FF !important;background-image: linear-gradient(to right, #6854FF , #1e00ff 141%)!important;}
.hc.pr_dc8 .firstPage {background-color: #6854FF !important; background-image: linear-gradient(to bottom, #6854FF , #1e00ff 141%)!important;}
.hc.pr_dc8 .firstPage.end1 { background-image: linear-gradient(to right, #6854FF , #1e00ff 141%)!important;}
.hc.pr_dc8 th.fc-day-header.fc-widget-header.fc-today { background:#6854FF !important; }
.hc.pr_dc8 .bt_schedule { background-color:#6854FF !important;box-shadow: 0 10px 15px rgba(104, 84, 255, 0.3) !important;  }
.hc.pr_dc8 .scrollUp, .hc.pr_dc8 .scrollUp:hover  { background-color:#6854FF !important; box-shadow: 0 10px 15px rgba(104, 84, 255, 0.3) !important; }
.hc.pr_dc8 .btn_resend.bt_mms { background-color:#6854FF !important; box-shadow: 0 10px 15px rgba(104, 84, 255, 0.3) !important; }
.hc.pr_dc8 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc8  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#6854FF !important; border-color:#6854FF !important }
.hc.pr_dc8 input#showPhone {background-color:#6854FF !important;box-shadow: 0 10px 15px rgba(104, 84, 255, 0.3) !important; }
.hc.pr_dc8 #addTeam { background-color: #6854FF !important; box-shadow:  0 10px 15px rgba(104, 84, 255, 0.3) !important;}
.hc.pr_dc8 #inviteMate { background-color: #6854FF !important; box-shadow:  0 10px 15px rgba(104, 84, 255, 0.3) !important;}
.hc.pr_dc8 .first .myPage a { background-color:#6854FF !important;}
.hc.pr_dc8 .lds-ellipsis div { background-color:#6854FF !important; }



.hc.pr_dc9 .bt_leftContrBox:before { background-color:#35405A !important;background-image: linear-gradient(to right, #35405A , #1c243e 141%)!important;}
.hc.pr_dc9 .firstPage {background-color: #35405A !important; background-image: linear-gradient(to bottom, #35405A , #1c243e 141%)!important;}
.hc.pr_dc9 .firstPage.end1 { background-image: linear-gradient(to right, #35405A , #1c243e 141%)!important;}
.hc.pr_dc9 th.fc-day-header.fc-widget-header.fc-today { background:#35405A !important; }
.hc.pr_dc9 .bt_schedule { background-color:#35405A !important;box-shadow: 0 10px 15px rgba(53, 64, 90, 0.3) !important;  }
.hc.pr_dc9 .scrollUp, .hc.pr_dc9 .scrollUp:hover  { background-color:#35405A !important; box-shadow: 0 10px 15px rgba(53, 64, 90, 0.3) !important; }
.hc.pr_dc9 .btn_resend.bt_mms { background-color:#35405A !important; box-shadow: 0 10px 15px rgba(53, 64, 90, 0.3) !important; }
.hc.pr_dc9 #selectCheckBoxAll input[type='checkbox']:not(old):checked + label,.hc.pr_dc9  #msgPageGubun.msgPage #listHeader .checkBox input[type='checkbox']:not(old):checked + label {background-color:#35405A !important; border-color:#35405A !important }
.hc.pr_dc9 input#showPhone {background-color:#35405A !important;box-shadow: 0 10px 15px rgba(53, 64, 90, 0.3) !important; }
.hc.pr_dc9 #addTeam { background-color: #35405A !important; box-shadow:  0 10px 15px rgba(53, 64, 90, 0.3) !important;}
.hc.pr_dc9 #inviteMate { background-color: #35405A !important; box-shadow:  0 10px 15px rgba(53, 64, 90, 0.3) !important;}
.hc.pr_dc9 .first .myPage a { background-color:#35405A !important;}
.hc.pr_dc9 .lds-ellipsis div { background-color:#35405A !important; }


/* fullCal css */
.hc.pr_dc0 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc0 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc0 .ui-datepicker-today:hover a,.hc.pr_dc0 .ui-datepicker-today .ui-state-active,.hc.pr_dc0  .ui-datepicker-today .ui-state-active, .hc.pr_dc0 .on_orange { background-color:#ff6363 !important; border-color:#ff6363 !important; color:#fff !important; }
.hc.pr_dc1 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc1 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc1 .ui-datepicker-today:hover a,.hc.pr_dc1 .ui-datepicker-today .ui-state-active,.hc.pr_dc1  .ui-datepicker-today .ui-state-active, .hc.pr_dc1 .on_orange { background-color:#FFA70E !important; border-color:#FFA70E !important; color:#fff !important; }
.hc.pr_dc2 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc2 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc2 .ui-datepicker-today:hover a,.hc.pr_dc2 .ui-datepicker-today .ui-state-active,.hc.pr_dc2  .ui-datepicker-today .ui-state-active, .hc.pr_dc2 .on_orange { background-color:#FFC72F !important; border-color:#FFC72F !important; color:#fff !important; }
.hc.pr_dc3 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc3 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc3 .ui-datepicker-today:hover a,.hc.pr_dc3 .ui-datepicker-today .ui-state-active,.hc.pr_dc3  .ui-datepicker-today .ui-state-active, .hc.pr_dc3 .on_orange { background-color:#FF198B !important; border-color:#FF198B !important; color:#fff !important; }
.hc.pr_dc4 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc4 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc4 .ui-datepicker-today:hover a,.hc.pr_dc4 .ui-datepicker-today .ui-state-active,.hc.pr_dc4  .ui-datepicker-today .ui-state-active, .hc.pr_dc4 .on_orange { background-color:#00B2C7 !important; border-color:#00B2C7 !important; color:#fff !important; }
.hc.pr_dc5 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc5 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc5 .ui-datepicker-today:hover a,.hc.pr_dc5 .ui-datepicker-today .ui-state-active,.hc.pr_dc5  .ui-datepicker-today .ui-state-active, .hc.pr_dc5 .on_orange { background-color:#13D08B !important; border-color:#13D08B !important; color:#fff !important; }
.hc.pr_dc6 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc6 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc6 .ui-datepicker-today:hover a,.hc.pr_dc6 .ui-datepicker-today .ui-state-active,.hc.pr_dc6  .ui-datepicker-today .ui-state-active, .hc.pr_dc6 .on_orange { background-color:#4DBAFF !important; border-color:#4DBAFF !important; color:#fff !important; }
.hc.pr_dc7 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc7 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc7 .ui-datepicker-today:hover a,.hc.pr_dc7 .ui-datepicker-today .ui-state-active,.hc.pr_dc7  .ui-datepicker-today .ui-state-active, .hc.pr_dc7 .on_orange { background-color:#477FFF !important; border-color:#477FFF !important; color:#fff !important; }
.hc.pr_dc8 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc8 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc8 .ui-datepicker-today:hover a,.hc.pr_dc8 .ui-datepicker-today .ui-state-active,.hc.pr_dc8  .ui-datepicker-today .ui-state-active, .hc.pr_dc8 .on_orange { background-color:#6854FF !important; border-color:#6854FF !important; color:#fff !important; }
.hc.pr_dc9 .ui-datepicker-today .ui-state-active:hover,.hc.pr_dc9 .ui-datepicker-today:hover .ui-state-active,.hc.pr_dc9 .ui-datepicker-today:hover a,.hc.pr_dc9 .ui-datepicker-today .ui-state-active,.hc.pr_dc9  .ui-datepicker-today .ui-state-active, .hc.pr_dc9 .on_orange { background-color:#35405A !important; border-color:#35405A !important; color:#fff !important; }

.pr_dc0 td.ui-datepicker-today a { color:#ff6363 !important; border-color:#ff6363 !important }
.pr_dc0 .ui-datepicker td a:hover,.pr_dc0 .ui-datepicker-current-day .ui-state-active { color:#ff6363 !important; background: #ffecec; border-color: #ffecec; }

.pr_dc1 td.ui-datepicker-today a { color:#FFA70E !important; border-color:#FFA70E !important }
.pr_dc1 .ui-datepicker td a:hover,.pr_dc1 .ui-datepicker-current-day .ui-state-active { color:#FFA70E !important; background: #fff0d6; border-color: #fff0d6; }

.pr_dc2 td.ui-datepicker-today a { color:#efae00 !important; border-color:#FFC72F !important }
.pr_dc2 .ui-datepicker td a:hover,.pr_dc2 .ui-datepicker-current-day .ui-state-active { color: #efae00 !important;background: #fff3d4;border-color: #fff3d4; }

.pr_dc3 td.ui-datepicker-today a { color:#FF198B !important; border-color:#FF198B !important }
.pr_dc3 .ui-datepicker td a:hover,.pr_dc3 .ui-datepicker-current-day .ui-state-active { color:#FF198B !important; background: #ffecec; border-color: #ffecec; }

.pr_dc4 td.ui-datepicker-today a { color:#00B2C7 !important; border-color:#00B2C7 !important }
.pr_dc4 .ui-datepicker td a:hover,.pr_dc4 .ui-datepicker-current-day .ui-state-active { color:#00B2C7 !important;background: #e6f3f5;border-color: #e6f3f5; }

.pr_dc5 td.ui-datepicker-today a { color:#08ce85 !important; border-color:#13D08B !important }
.pr_dc5 .ui-datepicker td a:hover,.pr_dc5 .ui-datepicker-current-day .ui-state-active { color: #08ce85 !important; background: #dbf7ed; border-color: #dbf7ed; }

.pr_dc6 td.ui-datepicker-today a { color:#4DBAFF !important; border-color:#4DBAFF !important }
.pr_dc6 .ui-datepicker td a:hover,.pr_dc6 .ui-datepicker-current-day .ui-state-active { color:#4DBAFF !important; background: #e4f4ff; border-color: #e4f4ff; }

.pr_dc7 td.ui-datepicker-today a { color:#477FFF !important; border-color:#477FFF !important }
.pr_dc7 .ui-datepicker td a:hover,.pr_dc7 .ui-datepicker-current-day .ui-state-active { color:#477FFF !important; background: #eaf0ff; border-color: #eaf0ff; }

.pr_dc8 td.ui-datepicker-today a { color:#6854FF !important; border-color:#6854FF !important }
.pr_dc8 .ui-datepicker td a:hover,.pr_dc8 .ui-datepicker-current-day .ui-state-active { color:#6854FF !important; background: #f1f0ff; border-color: #f1f0ff; }

.pr_dc9 td.ui-datepicker-today a { color:#35405A !important; border-color:#35405A !important }
.pr_dc9 .ui-datepicker td a:hover,.pr_dc9 .ui-datepicker-current-day .ui-state-active { color:#35405A !important; background: #eaeef9; border-color: #eaeef9; }