<template>
    <div id="visitant_list_amano" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">방문차량</h3>
        </div>

        <!-- <div class="section_scroll">
            <input type="button" class="input_btn" value="모달" @click.prevent="showVisitantAmanoInfoModalInfo"/>
        </div> -->
        
        <div class="section_scroll">
            <div class="amanoGroup">
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <div class="posRel">
                            <div class="bg">
                                <div class="bigGrp left">
                                    <div class="selectDiv">
                                        
                                    <select>
                                        <option value="TARGET_ALL">전체</option>
                                        <option value="">101동</option>
                                        <option value="">102동</option>
                                        <option value="">103동</option>
                                        <option value="">104동</option>
                                        <option value="">105동</option>
                                        <option value="">201동</option>
                                        <option value="">202동</option>
                                        <option value="">203동</option>
                                        <option value="">204동</option>
                                        <option value="">205동</option>
                                    </select>

                                    <span class="seper vertiSeper"></span>

                                    </div>
                                    <div class="grp">
                                        <label for="" class="from blind">시작일자</label>
                                        <input type="button" id="visitant_fr_ymd" :value="`${hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(start_date)}`" class="calFrom">
                                    </div>
                                    <span class="seper">에서</span>
                                    <div class="grp">
                                        <label for="" class="to blind">종료일자</label>
                                        <input type="button" id="visitant_to_ymd" :value="`${hodu_date_to_format_string(end_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(end_date)}`" class="calTo">
                                    </div>
                                    <input type="button" id="btnToday" value="오늘" class="toolbar-btn today input_btn">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hoduhomAmano" class="Amano_content">
                    <ul class="sortHeader workHeader">
                        <li class="num">No</li>
                        <li class="time"><span>방문 날짜</span></li>
                        <li class="peep"><span>동&amp;호</span></li>
                        <li class="carNum"><span>차량번호</span></li>
                        <li class="status"><span>현황</span></li>
                    </ul>
                    <div class="noResult">
                        <p>방문차량이 존재하지 않습니다</p>
                    </div>

                    <div id="hoduhome_list_scroll">
                        <ul class="hoduhomeUl hoduAmanoUl">

                            <li :key="index" v-for="(car, index) in car_list">
                                <p class="num">{{ index + 1 }}</p>
                                <p class="time">{{ getVisitDate(car) }} {{ getVisitAmPm(car) }} {{ getVisitTime(car) }} <span>(방문기간 {{ getVisitPeriod(car) }})</span></p>
                                <p class="peep">{{ (car.dongcode && car.dongcode.length > 0 && car.hocode && car.hocode.length > 0) ? `${car.dongcode}동 ${car.hocode}호` : '-' }}</p>
                                <p class="carNum">{{ car.carNo }}</p>

                                <!-- 예정 visitExpected / 입차 visitEntry / 출차 visitExit -->
                                <p class="status" :class="{ 
                                    visitExpected : car.state == 'EXPECTED',
                                    visitEntry : car.state == 'IN',
                                    visitExit : car.state == 'OUT',
                                }">
                                    <span>{{ getCarStateText(car.state) }}</span>
                                </p>

                                <a href="#" class="seeDets" @click.prevent="showVisitantAmanoInfoModalInfo(car)">자세히 보기</a>

                            </li>

                            <!-- <li>
                                <p class="num">1</p>
                                <p class="time">21.07.14 수 <span>(방문기간 8시간)</span></p>
                                <p class="peep">101동 1004호</p>
                                <p class="carNum">86호 7545</p>
                                <p class="status visitExpected">
                                    <span>예정</span>
                                </p>
                                <a href="#" class="seeDets" @click.prevent="showVisitantAmanoInfoModalInfo">자세히 보기</a>
                            </li>
                            <li>
                                <p class="num">1</p>
                                <p class="time">21.07.13 화 <span>(방문기간 1일)</span></p>
                                <p class="peep">102동 805호</p>
                                <p class="carNum">77호 7777</p>
                                <p class="status visitEntry">
                                    <span>입차</span>
                                </p>
                                <a href="#" class="seeDets" @click.prevent="showVisitantAmanoInfoModalInfo">자세히 보기</a>
                            </li>
                            <li>
                                <p class="num">1</p>
                                <p class="time">21.07.12 월 <span>(방문기간 3시간)</span></p>
                                <p class="peep">101동 1105호</p>
                                <p class="carNum">88호 1111</p>
                                <p class="status visitExit">
                                    <span>출차</span>
                                </p>
                                <a href="#" class="seeDets" @click.prevent="showVisitantAmanoInfoModalInfo">자세히 보기</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class VisitantListAmano extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeVisitantAmanoInfoModalInfo ?: (params : hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo) => void;

    start_date : Date = new Date();
    end_date : Date = new Date();

    car_list : any[] = [];

    beforeMount() : void {
        this.end_date = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        this.start_date = moment(this.end_date).set('date', this.end_date.getDate() - 7).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
    }

    mounted() : void {
        this.datepickerInit();
        this.getPreDiscountListInfo();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hoduhome_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });
    }

    /**
     * 차량 조회
     */
    async getParkingTcktCarList(carNo4Digit : string, startDtm : string, endDtm : string) : Promise<any> {

        try {

            const body = {
                "url" : "interop/getParkingTcktCarList.do",
                "method" : API_METHOD.POST,
                "request_body" : {
                    "lotAreaNo": 20,
                    "carNo4Digit" : carNo4Digit,
                    "customerSearchType" : 0,
                    "fromInDtm": startDtm,
                    "toInDtm": endDtm,
                    // "passType" : "visitor"
                }
            }

            return this.hodu_api_call(`api/v1/amano`, API_METHOD.POST, body, false);

            // console.log(response);

            // if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.response ) {
            //     throw new Error("방문차량 조회 중 오류 발생");
            // }

            // const amano_response = response.data.data.response;

            // console.log(amano_response);

        } catch(e) {
            this.hodu_show_dialog('error', "방문 차량 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 방문 차량 조회
     */
    async getPreDiscountListInfo() : Promise<void> {
        try {

            const body = {
                "url" : "interop/getPreDiscountListInfo.do",
                "method" : API_METHOD.POST,
                "request_body" : {
                    "lotAreaNo": 20,
                    "fromRegiDtm": this.hodu_date_to_format_string(moment(this.start_date).set('month', this.start_date.getMonth() - 1).toDate(), 'YYYYMMDDHHmmss'),
                    "toRegiDtm": this.hodu_date_to_format_string(moment(this.end_date).set('month', this.start_date.getMonth() + 1).toDate(), 'YYYYMMDDHHmmss')
                }
            }

            const response = await this.hodu_api_call(`api/v1/amano`, API_METHOD.POST, body);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.response ) {
                throw new Error("방문차량 조회 중 오류 발생");
            }

            const amano_response = response.data.data.response;

            console.log(amano_response);

            if( !amano_response.data || !amano_response.data.list ) {
                throw new Error("방문 차량 조회 중 오류 발생");
            }

            this.car_list.splice(0, this.car_list.length);
            this.car_list = this.car_list.concat(amano_response.data.list.sort((o1, o2) : number => {

                const o1_startDtm = o1.startDtm;
                const o2_startDtm = o2.startDtm;

                if( o1_startDtm > o2_startDtm ) return -1;
                if( o1_startDtm < o2_startDtm ) return 1;

                return 0;
            }).filter(item => item.startDtm >= this.hodu_date_to_format_string(this.start_date, 'YYYYMMDDHHmmss') && item.startDtm <= this.hodu_date_to_format_string(this.end_date, 'YYYYMMDDHHmmss')));

            const promise_array : Promise<any>[] = [];

            for( const car of this.car_list ) {
                const car_number = car.carNo;
                const car_start_dtm = car.startDtm;
                const car_end_dtm = car.endDtm;

                if( !car_number && car_number.length < 1 ) {
                    car.state = 'EXPECTED';
                    this.$forceUpdate();
                    continue
                }

                const promise : Promise<any> = this.getParkingTcktCarList(car_number.substring(car_number.length - 4), car_start_dtm, car_end_dtm).then((response) => {
                    try {
                        console.log(response);

                        if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.response ) {
                            throw new Error("주차 차량 조회 중 오류 발생");
                        }

                        const amano_response = response.data.data.response;

                        console.log(amano_response);

                        if( !amano_response.data || !amano_response.data.list ) {
                            throw new Error("주차 차량 조회 중 오류 발생");
                        }

                        const parking_car_list = amano_response.data.list.filter(item => item.carNo == car_number);

                        if( parking_car_list.length < 1 ) {
                            car.state = "EXPECTED";
                            this.$forceUpdate();
                            return;
                        }

                        const parking_car = parking_car_list[0];

                        if( parking_car.outDtm && parking_car.outDtm.length > 0 ) {
                            car.state = 'OUT';
                            car.inDtm = parking_car.inDtm;
                            car.outDtm = parking_car.outDtm;
                        } 

                        else if( parking_car.inDtm && parking_car.inDtm.length > 0 ) {
                            car.state = 'IN';
                            car.inDtm = parking_car.inDtm;
                        }

                        this.$forceUpdate();

                    } catch(e) {

                    }
                });

                promise_array.push(promise);
            }

            Promise.all(promise_array);

        } catch(e) {
            this.hodu_show_dialog('error', "방문 차량 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * datepikcer 설정
     */
    datepickerInit() : void {
        const vue = this;

        const datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
                inst.input.val(mnt.format('YYYY.MM.DD') + ' ' + vue.getDayOfWeekByDate(mnt.toDate()));
                
                // 시작일 변경
                if( id == 'visitant_fr_ymd' ) {
                    vue.start_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.end_date = moment(vue.start_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
                    }
                } 

                // 종료일 변경
                else if( id == 'visitant_to_ymd' ) {
                    vue.end_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.start_date = moment(vue.end_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                    }
                }

                vue.end_date = moment(vue.end_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

                vue.getPreDiscountListInfo();
            },
        }

        // @ts-ignore
        $('#visitant_fr_ymd').datepicker(datepicker_option);

        // @ts-ignore
        $('#visitant_to_ymd').datepicker(datepicker_option);
    }

    /**
     * 방문 날짜
     */
    getVisitDate(car) : string {
        const start = car.startDtm;
        const date = new Date([start.substring(0,4), start.substring(4,6), start.substring(6,8)].join('-'));
        return `${this.hodu_date_to_format_string(date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
    }

    /**
     * 방문 예정 날짜 오전/오후
     */
    getVisitAmPm(car) : string {
        const start = car.startDtm;

        const hour = Number(start.substring(8,10));

        return hour >= 0 && hour <= 11 ? "오전" : "오후";
    }

    /**
     * 방문 예정 날짜 시간
     */
    getVisitTime(car) : string {
        const start = car.startDtm;

        const hour = Number(start.substring(8,10));
        const min = Number(start.substring(10,12));

        const is_am = hour >= 0 && hour <= 11;

        let hour_min = "";
        if( is_am && hour == 0 ) {
            hour_min = `12:${`0${min}`.slice(-2)}`;
        }
        else if( is_am || (!is_am && hour == 12) ) {
            hour_min = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)}`;
        }
        else {
            hour_min = `${`0${hour - 12}`.slice(-2)}:${`0${min}`.slice(-2)}`;
        }

        return hour_min;
    }

    /**
     * 방문기간
     */
    getVisitPeriod(car) : string {
        const start = car.startDtm;
        const end = car.endDtm;

        const start_yyyy_mm_dd = [start.substring(0,4), start.substring(4,6), start.substring(6,8)].join('-');
        const start_hh_mm_ss = [start.substring(8,10), start.substring(10,12), start.substring(12,14)].join(':');

        const end_yyyy_mm_dd = [end.substring(0,4), end.substring(4,6), end.substring(6,8)].join('-');
        const end_hh_mm_ss = [end.substring(8,10), end.substring(10,12), end.substring(12,14)].join(':');

        const start_date = moment(`${start_yyyy_mm_dd} ${start_hh_mm_ss}`).toDate();
        const end_date = moment(`${end_yyyy_mm_dd} ${end_hh_mm_ss}`).toDate();

        const period_ms = end_date.getTime() - start_date.getTime();

        // 시간 차이 60분(1시간) 미만
        if( period_ms < 60 * 60 * 1000 ) {
            return `${Math.floor(period_ms / 60 / 1000)}분`;
        }

        // 시간 차이 24시간(1일) 미만
        if( period_ms < 24 * 60 * 60 * 1000 ) {
            return `${Math.floor(period_ms / 60 / 60 / 1000)}시간`;
        }

        // 시간 차이 24시간(1일) 이상
        return `${Math.floor(period_ms / 24 / 60 / 60 / 1000)}일`;
    }

    /**
     * 차량 상태값 텍스트 반환
     */
    getCarStateText(state) : string {

        switch(state) {
            case "EXPECTED": return "예정";
            case "IN": return "입차";
            case "OUT": return "출차";
        }

        return '';
    }

    /**
     * 방문차량 정보 모달
     */
    showVisitantAmanoInfoModalInfo(car) : void {

        if( !car.state ) return;

        this.doSetHomeVisitantAmanoInfoModalInfo?.({
            show_modal : true,
            amano_info : {
                "car" : car
            }
        })
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#hoduhome_list_scroll").mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
</script>

<style scoped>
    .selectDiv { display: inline-block; float: left; }
    .bigGrp .selectDiv select { position: static !important; float: left; font-size: 13px; font-weight: bold; height: 40px; line-height: 40px; background-color: #fff; cursor: pointer; padding-right: 25px; padding-left: 10px; appearance: none; -moz-appearance: none; -webkit-appearance: none; }
    .bigGrp .selectDiv select option { float: left; font-size: 14px; }
    .selectDiv .seper.vertiSeper { width: 1px; height: 15px; margin-top: 12px; margin-right: 20px; }
    .settingBg .left .grp { max-width: 130px !important; min-width: 130px !important; margin-right: 10px !important; }
    .settingBg .left .grp input { width: 130px; min-width: 130px; padding-right: 0; margin-right: 0; }
    .settingBg .today { margin: 0 !important; }

    #hoduhomAmano .sortHeader > li { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; box-sizing: border-box; padding-right: 15px; }
    #hoduhomAmano .sortHeader > li > a.sort { background: none; padding-right: 0; }
    .section_ce_fix .num { width: 13%; text-align: center; font-weight: bold;  }
    .section_ce_fix .time { width: 23%; }
    .section_ce_fix .peep { width: 27%; }
    .section_ce_fix .carNum { width: 18%; }
    .section_ce_fix .status { width: 19%; }

    .noResult { position: static; box-sizing: border-box; padding: 20px 50px; width: 100%; display: none; }
    .noResult.on { display: block; }
    .noResult p { width: 100%; border-radius: 5px; background: #f1f3f5; line-height: 80px; height: 80px; font-size: 13px; color: #9ea5b3; }

    .hoduhomeUl li { position: relative; transition: 0.2s; overflow: hidden; width: 100%; line-height: 55px; height: 55px; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; color: #202A39; }
    .hoduhomeUl li:hover { background: #f7f9fb; font-weight: bold; }
    .hoduhomeUl li p { padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: inline-block; }
    .hoduhomeUl li p.status span { padding: 5px 23px; box-sizing: border-box; border-radius: 20px; font-weight: bold; }
    .hoduhomeUl li p.status.visitExpected span { border: 1px solid #e2e5e8; color: #8c8fa0; }
    .hoduhomeUl li p.status.visitEntry span { border: 1px solid #477fff; background: #477fff; color: #fff; }
    .hoduhomeUl li p.status.visitExit span { border: 1px solid #ff6363; background: #ff6363; color: #fff; }

    /* 상세보기 */
    .seeDets { position: absolute; left: 0; top: 0; font-size: 0; width: 100%; height: 100%; display: block; }
</style>