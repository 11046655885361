<template>
    <div id="announcement_list" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">안내방송</h3>

            <!-- <input type="button" class="input_btn" value="생성" @click="moveCreate"/> -->
            <div id="bt_scheduleBoxNoti" class="bt_scheduleBox">
                <input type="button" id="btnAddNotification" class="bt_schedule" @click="moveCreate" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/>
            </div>

            <div class="fc-right">
                <a href="#" class="schBtn" @click.prevent="search">검색하기</a>
            </div>
            
            <!-- <input type="button" class="input_btn" value="상세" @click="moveDetail"/> -->
        </div>

        <div class="searchDiv" :class="{ on : is_search_div_open == true }">
            <div class="schGrp">
                <input type="text" id="announcement_search" placeholder="제목, 내용으로 검색" :value="search_query" @input="search_query = $event.target.value">
                <div class="btns">
                    <a class="closeBtn" @click.prevent="() => {
                        search_query = '';    
                        is_search_div_open = false;
                    }">닫기</a>
                </div>
            </div>
        </div>

        <div class="section_scroll">
            <div class="viewGroup">
                <div id="aptAnn_div" class="content">

                    <!-- 타이틀 -->
                    <div class="titleDiv titleTab_div" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                        <h2 class="title" :class="{ active : tab == 'EXPECTED' }">
                            <a href="#" class="tit_tab" @click.prevent="tab = 'EXPECTED'">방송 예정</a>
                        </h2>
                        <h2 class="title" :class="{ active : tab == 'COMPLETE' }">
                            <a href="#" class="tit_tab" @click.prevent="tab = 'COMPLETE'">완료된 방송</a>
                        </h2>
                    </div>

                    <!-- 내용 -->
                    <div class="aptAnn_con">
                        <AnnouncementExpectedList v-if="tab == 'EXPECTED'" :event_bus="event_bus" :search_query="search_query" />
                        <AnnouncementCompleteList v-if="tab == 'COMPLETE'" :event_bus="event_bus" :search_query="search_query" />
                    </div>

                </div>
            </div>          
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import AnnouncementExpectedList from '@/components/hodu_h/AnnouncementExpectedList.vue';
import AnnouncementCompleteList from '@/components/hodu_h/AnnouncementCompleteList.vue';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        AnnouncementExpectedList, AnnouncementCompleteList, ResizeObserver
    },
})
export default class AnnouncementList extends Mixins(VueHoduCommon) {

    event_bus : Vue = new Vue();

    tab : string = "EXPECTED";

    is_search_div_open : boolean = false;
    search_query : string = "";

    beforeMount() : void {
        this.tab = (this.get_group_role(this.scope_group_id) == 'GROUP_MANAGER' || this.get_group_role(this.scope_group_id) == 'ADMIN') ? "EXPECTED" : "COMPLETE";
    }

    mounted() : void {

        //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.aptAnn_con > ul').hide();
        // 	$('.aptAnn_con > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });
    }

    moveCreate() : void {
        this.hodu_router_push(`/home/announcement/create`);
    }

    /**
     * 검색
     */
    search() : void {
        this.is_search_div_open = true;
        this.$nextTick(() => $('#announcement_search').focus());
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        if( this.tab == 'EXPECTED' ) this.event_bus.$emit('expected_scroll');
        if( this.tab == 'COMPLETE' ) this.event_bus.$emit('complete_scroll');
    }

}
</script>

<style>
    #announcement_list #aptAnn_div { position: relative; height: 900px; }
    #announcement_list #aptAnn_div .titleDiv { width: 100%; line-height: 60px; height: 60px; -webkit-box-sizing: border-box; box-sizing: border-box; border-bottom: 1px solid #e7e9ea; overflow: hidden; padding: 0 25px; }
    #announcement_list #aptAnn_div .titleDiv * { display: inline-block; }
    #announcement_list #aptAnn_div .titleDiv .title { font-weight: 700; font-size: 14px; vertical-align: middle; display: inline-block; }
    #announcement_list #aptAnn_div .aptAnn_con { position: relative; }

    #announcement_list #aptAnn_div .titleDiv .tit_tab { width: 125px; height: 35px; line-height: 35px; display: block; padding: 0 15px; text-align: center; }
    #announcement_list #aptAnn_div .titleDiv .active .tit_tab { width: 125px; height: 35px; line-height: 35px; display: block; border-radius: 30px; background: #2b3b5f; padding: 0 15px; text-align: center; color: #fff; }

    #announcement_list #aptAnn_div .sortHeader li { width: 18%; text-align: left; padding-right: 15px; -webkit-box-sizing: border-box; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #announcement_list.section_ce_fix .num { width: 10%!important; text-align: center!important; }
    #announcement_list.section_ce_fix .apt1 { width: 30%!important; }
    #announcement_list.section_ce_fix .annWriter { width: 10%!important; }
    #announcement_list.section_ce_fix .annTime { width: 30%!important; }
    #announcement_list.section_ce_fix .annFile { width: 20%!important; }

    #announcement_list .aptAnnUl li { position: relative; -webkit-transition: .2s; transition: .2s; overflow: hidden; width: 100%; line-height: 55px; height: 55px; -webkit-box-sizing: border-box; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; color: #202a39; }
    #announcement_list .aptAnnUl>li p { width: 18%; text-align: left; font-weight: 700; color: #7c88a2; float: left; display: inline-block; padding-right: 15px; -webkit-box-sizing: border-box; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; transition: .3s; }
    #announcement_list .aptAnnUl>li:hover p { color: #232848; }
    #announcement_list .aptAnnUl>li p.annFile_icon { width: 10% !important; cursor: pointer; }
    #announcement_list .aptAnnUl>li p.annFile_icon span { font-size: 0; width: 25px; height: 25px; display: inline-block; vertical-align: middle; opacity: .6; transition: .3s; }
    #announcement_list .aptAnnUl>li p.annFile_icon:hover span { opacity: 1; }
    #announcement_list .aptAnnUl>li p .annExample_icon { background: url('../../assets/images/contents/hoduhome_Announcement_example.svg') no-repeat center; }
    #announcement_list .aptAnnUl>li p .annPlay_icon { background: url('../../assets/images/contents/hoduhome_Announcement_start.svg') no-repeat center; }
    #announcement_list .aptAnnUl>li p.annTime span.remainingT { color: #ff6363; }
    #announcement_list .aptAnnUl>li p.annTime span.completeT { color: #477fff; }

    #announcement_list .aptAnnUl a.annDets { position: absolute; line-height: 55px !important; height: 55px !important; top: 0; right: 22%; width: 70%; display: block; border: none !important; z-index: 1000; font-size: 0 !important; background: transparent; }
</style>