<template>
    <!-- patient > patientDetail로 변경 -->
    <div class="patientDetail section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">환자 관리</h3>
            <p class="btn_group">
                <!-- <input type="button" id="" class="input_btn" placeholder="" value="저장" title="저장"/>
                <input type="button" id="" class="input_btn" placeholder="" value="취소" title="취소"/> -->
                <input type="button" id="" class="input_btn" placeholder="" value="삭제" title="삭제" @click="deletePatient" v-if="false"/>
            </p>
        </div>

        <div class="content">
            <div class="basicDiv">
                <p class="title">기본설정</p>
                <div class="firstDiv">
                    <div class="patientDetailDiv">
                        <ul>
                            <li>
                                <label for="patientNameId">이름</label>
                                <input type="text" id="patientNameId" placeholder="환자명" :value="selected_patient ? selected_patient.user_name : ''" disabled/>
                                <!-- <input type="text" id="patientNameId" placeholder="환자명" value="김이정" /> -->
                            </li>
                            <li class="patientTelList">
                                <label for="patientTelId4">연락처</label>
                                <input type="text" id="patientTelId4" placeholder="환자 연락처" :value="selected_patient ? selected_patient.user_phone_number : ''" disabled/>
                                <!-- <label for="patientTelId">연락처</label> 
                                    <select name="" id="patientTelId">
                                    <option value="" selected>010</option>
                                    <option value="">011</option>
                                    <option value="">016</option>
                                    <option value="">02</option>
                                </select>
                                <input type="text" id="patientTelId2" placeholder="가운데 번호" value="2020" />
                                <input type="text" id="patientTelId3" placeholder="마지막 번호" value="2030" /> -->
                            </li>
                            <!-- <li>
                                <label for="patientCode">고유번호</label>
                                <input type="text" id="patientCode" placeholder="환자 고유번호를 작성하세요" value="00489291093" />
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="appointmentDiv">
                    <ul>
                        <li class="appointment_header_li">
                            <p class="title fl">방문일</p>
                            <select class="yearSelect" @change="selectAppointmentYear($event.target.value)" v-if="events.length > 0">
                                
                                <option value="" :selected="selected_appointment_year == null">
                                    {{ `전체 (${events.length})`}}
                                </option>

                                <option :value="year" :key="year" v-for="year in computedAppointmentYearList" :selected="selected_appointment_year == year">
                                    {{ `${year} (${getAppointmentCountByYear(year)})` }}
                                </option>

                                <!-- <option value="">2019 (10)</option>
                                <option value="" selected>2020 (5)</option>
                                <option value="">2021</option>
                                <option value="">2022</option>
                                <option value="">2023</option> -->
                            </select>
                            <div class="btns">
                                <input type="button" id="makeAppointBtn" class="addFilePlus" value="예약" @click="showAppointmentCreateModal"/>
                            </div>
                            <!-- <div class="descript">
                                <ul>
                                    <li>
                                        <span class="morn clr">노랑</span>
                                        <span class="txt">요청</span>
                                    </li>
                                    <li>
                                        <span class="night clr">보라</span>
                                        <span class="txt">접수</span>
                                    </li>
                                    <li>
                                        <span class="allday clr">파랑</span>
                                        <span class="txt">확정</span>
                                    </li>
                                    <li>
                                        <span class="off clr">빨강</span>
                                        <span class="txt">진료</span>
                                    </li>
                                    <li>
                                        <span class="off clr">빨강</span>
                                        <span class="txt">노쇼</span>
                                    </li>
                                     <li>
                                        <span class="off clr">빨강</span>
                                        <span class="txt">거절</span>
                                    </li>
                                </ul>
                            </div> -->
                        </li>
                        <li class="appointmentList">
                            <div class="noResult" v-if="computedAppointmentListBySelectedYear.length < 1">
                                <p>방문 정보 없음</p>
                            </div>
                            <!-- <select name="" class="contractSelect" id="">
                                <option value="" selected>2020.04.01</option>
                                <option value="">2019.04.01</option>
                            </select> -->
                            <div id="appointment_list_scroll_by_patient">
                                <ul class="apUl">
                                    <li :key="appointment.event_id" v-for="appointment in computedAppointmentListBySelectedYear">
                                        <a @click.prevent="moveAppointmentDetail(appointment.event_id)">
                                            <span class="status" :class="getAppointmentStatusClassOrText(appointment.event_data.appointment, true)">
                                                {{ getAppointmentStatusClassOrText(appointment.event_data.appointment) }}
                                            </span>
                                            <p class="workHours">
                                                <span>
                                                    {{ `${hodu_date_to_format_string(new Date(appointment.event_data.schedule_date.start), 'YYYY.MM.DD')} ${getDayOfWeekByDate(new Date(appointment.event_data.schedule_date.start))}` }}
                                                </span>
                                            </p>
                                            <p class="day">
                                                {{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(new Date(appointment.event_data.schedule_date.start), 'hh:mm a')) }}
                                            </p>
                                        </a>
                                    </li>

                                    <!-- <li>
                                        <a @click.prevent="moveAppointmentDetail('event_id')">
                                            <span class="status made">접수</span>
                                            <p class="day">오후 04:15</p>
                                            <p class="workHours"><span>2020.03.17 화</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="status seen">진료</span>
                                            <p class="day">오후 03:15</p>
                                            <p class="workHours"><span>2020.03.02 목</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="status noshow">노쇼</span>
                                            <p class="day">오후 04:15</p>
                                            <p class="workHours"><span>2020.02.13 목</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="status seen">진료</span>
                                            <p class="day">오후 06:15</p>
                                            <p class="workHours"><span>2020.02.03 월</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="status seen">진료</span>
                                            <p class="day">오후 02:00</p>
                                            <p class="workHours"><span>2020.01.22 수</span></p>
                                        </a>
                                    </li> -->
                                </ul>
                            </div>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <!-- 파일함 -->
            <div class="thisPatientFileBox" :class="{ dp2 : is_file_list_card_mode == true }" @dragover.prevent="fileDragOver($event)" @dragleave.prevent="fileDragLeave" @drop.prevent="fileDrop($event)">
                <div class="titleDiv">
                    <h4>파일함</h4>
                    <select class="yearSelect" @change="selectFileYear($event.target.value)" v-if="file_list.length > 0">
                        
                        <!-- 전체 -->
                        <option value="" :selected="selected_file_year == null">
                            {{ `전체 (${file_list.length})` }}
                        </option>

                        <!-- 파일이 존재하는 연도 -->
                        <option :value="year" :key="year" v-for="year in computedFileYearList" :selected="year == selected_file_year">
                            {{ `${year} (${getFileCountByYear(year)})` }}
                        </option>

                        <!-- <option value="" selected>전체 (10)</option>
                        <option value="">2017 (1)</option>
                        <option value="">2018 (1)</option>
                        <option value="">2019 (5)</option>
                        <option value="">2020 (3)</option>
                        <option value="">2021</option>
                        <option value="">2022</option>
                        <option value="">2023</option> -->
                    </select>
                    <div class="btns">
                        <input type="button" class="dpOne" v-if="is_file_list_card_mode == true" @click="fileListCardModeOnOff"/>
                        <input type="button" class="dpTwo" v-if="is_file_list_card_mode == false" @click="fileListCardModeOnOff"/>
                        <input type="file" class="" id="addFile" value="추가" @change="addFile($event.target.files)" multiple="multiple" /> 
                        <label for="addFile" class="addFilePlus">추가</label>
                    </div>
                </div>
                <div class="noResult" v-if="computedFileListBySelectedYear.length < 1">
                    <p>파일 없음</p>
                </div>
                <div id="patient_file_box_list_scroll" class="thisPatientFileUl">
                    
                    <ul class="pfbUl">  
                        <li :key="file.file_uuid" v-for="(file, index) in computedFileListBySelectedYear">
                            <a @click="fileClick(file)">
                                <p class="preview" :class="{ format : new RegExp('image').test(file.file_type) == false }" :style="{ backgroundImage : getBackgroundImage(file) }">
                                    {{ new RegExp('image').test(file.file_type) == false 
                                        ? file.file_name.substring(file.file_name.lastIndexOf('.') + 1)
                                        : '미리보기' }}
                                </p>
                                <p class="fileName"><span class="num">{{ index + 1 }}</span>{{ file.file_name }}</p>
                                <p class="fileSize">{{ getFileSizeText(file.file_size) }}</p>
                                <p class="uploaded">
                                    {{ file.audit_created ? `${hodu_date_to_format_string(new Date(file.audit_created), 'YY.MM.DD')} ${getDayOfWeekByDate(new Date(file.audit_created))}` : '' }}
                                </p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" @click="deletePatientsFileboxFile([file])"/>
                        </li>

                        <!-- <li>
                            <a>
                                <p class="preview">미리보기</p>
                                <p class="fileName"><span class="num">1</span>xray_20200403.jpg</p>
                                <p class="fileSize">1 mb</p>
                                <p class="uploaded">20.04.06 월</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li>
                        <li>
                            <a>
                                <p class="preview">미리보기</p>
                                <p class="fileName"><span class="num">2</span>xray_20200413.jpg</p>
                                <p class="fileSize">1.5 mb</p>
                                <p class="uploaded">20.04.16 목</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li>
                        <li>
                            <a>
                                <p class="preview format">pdf</p>
                                <p class="fileName"><span class="num">3</span>김이정_건강검진표.pdf</p>
                                <p class="fileSize">1 mb</p>
                                <p class="uploaded">20.02.06 목</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li>
                        <li>
                            <a>
                                <p class="preview format">pdf</p>
                                <p class="fileName"><span class="num">4</span>김이정_건강검진표.pdf</p>
                                <p class="fileSize">1.5 mb</p>
                                <p class="uploaded">19.11.02 목</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li>
                        <li>
                            <a>
                                <p class="preview">미리보기</p>
                                <p class="fileName"><span class="num">5</span>김이정_대장내시경.mp4</p>
                                <p class="fileSize">35 mb</p>
                                <p class="uploaded">19.10.16 목</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li>
                        <li>
                            <a>
                                <p class="preview format">pdf</p>
                                <p class="fileName"><span class="num">6</span>피검사.pdf</p>
                                <p class="fileSize">1 mb</p>
                                <p class="uploaded">19.07.06 월</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li>
                        <li>
                            <a>
                                <p class="preview format">pdf</p>
                                <p class="fileName"><span class="num">7</span>피검사.pdf</p>
                                <p class="fileSize">2.3 mb</p>
                                <p class="uploaded">19.04.16 월</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li>
                        <li>
                            <a>
                                <p class="preview">미리보기</p>
                                <p class="fileName"><span class="num">8</span>김이정_위내시경.mp4</p>
                                <p class="fileSize">28 mb</p>
                                <p class="uploaded">19.04.06 월</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li>
                        <li>
                            <a>
                                <p class="preview format">pdf</p>
                                <p class="fileName"><span class="num">9</span>김이정_건강검진표.pdf</p>
                                <p class="fileSize">3 mb</p>
                                <p class="uploaded">18.02.03 수</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li>
                        <li>
                            <a>
                                <p class="preview format">pdf</p>
                                <p class="fileName"><span class="num">10</span>김이정_건강검진표.pdf</p>
                                <p class="fileSize">3.8 mb</p>
                                <p class="uploaded">17.02.16 목</p>
                            </a>
                            <input type="button" class="delFileBtn" value="삭제" />
                        </li> -->
                    </ul>
                </div>
                <div class="addedDiv">
                    
                </div>

            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { FILE_SIZE, API_METHOD } from '@/mixin/VueHoduCommon';

import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_table, hodu_doc_enum } from '@/model/hodudoc';
import { t_event, t_event_hodu_doc, t_event_file } from '@/model/event';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { ResizeObserver } from 'vue-resize';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class Patient extends Mixins(VueHoduCommon) {

    /**
     * 예약 년도 리스트 
     */
    get computedAppointmentYearList() : number[] {

        const years : number[] = [];

        for( const event of this.events ) {

            if( event.event_data == null ) { continue; }

            const target_date : Date = new Date(event.event_data.schedule_date.start);

            if( years.indexOf(target_date.getFullYear()) > -1 ) { continue; }

            years.push(target_date.getFullYear());

        }

        return years;
    }

    /**
     * 선택된 년도의 방문일
     */
    get computedAppointmentListBySelectedYear() : t_event[] {

        if( this.selected_appointment_year == null ) { return this.events; }

        const target_event_list : t_event[] = this.events.filter(item => item.event_data != null &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appointment_year
        );

        return target_event_list;
    }

    /**
     * 파일 년도 리스트 
     */
    get computedFileYearList() : number[] {

        const years : number[] = [];

        for( const file of this.file_list ) {

            if( file.audit_created == null ) { continue; }

            const target_date : Date = new Date(file.audit_created);

            if( years.indexOf(target_date.getFullYear()) > -1 ) { continue; }

            years.push(target_date.getFullYear());

        }

        return years;
    }

    /**
     * 선택된 년도의 파일 리스트
     */
    get computedFileListBySelectedYear() : hodu_doc_table.t_hospital_patient_file[] {
        
        // 선택된 년도가 없는 경우
        if( this.selected_file_year == null ) { return this.file_list; }

        const target_file_list : hodu_doc_table.t_hospital_patient_file[] = this.file_list.filter(item => item.audit_created != null && 
                                                                                                          new Date().getFullYear() == this.selected_file_year);

        return target_file_list;
    }

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentCreateModalInfo ?: (params : hodu_doc_modal_info.AppointmentCreateModalInfo) => void;
    @ModalInfo.Action doSetShowEventImagePreview ?: any;

    selected_patient : hodu_doc_object.patient_info | null = null;

    events : t_event[] = [];
    selected_appointment_year : number | null = null;

    file_list : hodu_doc_table.t_hospital_patient_file[] = [];
    selected_file_year : number | null = null;
    is_file_list_card_mode : boolean = false;
    
    async mounted() : Promise<void> {

        const patient_id = this.$route.params.patient_id;

        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        // 환자 정보 체크
        if( this.patient_info == null || this.patient_info.length < 1 || this.patient_info.filter(item => item.user_id == Number(patient_id)).length < 1 ) {
            
            this.hodu_show_dialog("alert", "환자 정보가 없습니다", ["확인"], [
                () => { this.movePrevPage(); }
            ])
            return;
        }

        this.selected_patient = this.patient_info.filter(item => item.user_id == Number(patient_id))[0];

        // 리스트로 보이기
        // $(".btns .dpTwo").click(function(){
        //     $(".thisPatientFileBox").addClass("dp2");
        //     $(".btns .dpOne").show();
        //     $(this).hide();

        // });

        // 박스형으로 보이기
        // $(".btns .dpOne").click(function(){
        //     $(".thisPatientFileBox").removeClass("dp2");
        //     $(".btns .dpTwo").show();
        //     $(this).hide();

        // });

        // API 호출 및 스크롤 설정 
        this.getEvents();
        this.getPatientsFileboxInfo();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {
            const title_box_height : number | undefined = $('.title_box').outerHeight();
            const basic_div_title_height : number | undefined = $('.basicDiv .title').outerHeight();
            const basic_div_first_div_height : number | undefined = $('.basicDiv .firstDiv').outerHeight();
            const appointment_header_li_height : number | undefined = $('.appointment_header_li').outerHeight();
            const file_box_title_div_height : number | undefined = $('.thisPatientFileBox .titleDiv').outerHeight();

            // @ts-ignore
            $('#appointment_list_scroll_by_patient').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (basic_div_title_height ? basic_div_title_height : 0)
                                            - (basic_div_first_div_height ? basic_div_first_div_height : 0)
                                            - (appointment_header_li_height ? appointment_header_li_height : 0)
                                            - 30 
            })

            // @ts-ignore
            $('#patient_file_box_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                                - (file_box_title_div_height ? file_box_title_div_height : 0)
            });
        })
    }

    /**
     * 환자 파일함 조회
     */
    getPatientsFileboxInfo() : void {

        if ( this.selected_patient == null ) { return; }

        const patient_id : number = this.selected_patient.user_id;

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${patient_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.file_list.splice(0, this.file_list.length)
                this.file_list = this.file_list.concat(response.data.data);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 방문기록 조회
     */
    getEvents() : void {
        
        const start : string = moment('1970-01-01 00:00:00').utc().format();
        const end   : string = moment('2050-12-31 23:59:59').utc().format();

        const calendar_id : string = this.calendar_id;
        const owner_type  : string = this.scope;
        const owner_id    : number = this.scope_group_id;

        let query : string = `?start=${start}&end=${end}&mycal=false&syscal=false&shared=false&biz_type=${this.scope_group_team_option.biz_type}&biz_id=${this.scope_group_team_option.biz_id}`;
    
        this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}${query}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                this.events.splice(0, this.events.length);
                let temp_events : t_event[] = this.events.concat(response.data.data.events.filter(item => item.event_data 
                    ? item.event_data.event_owner_id == ( this.selected_patient 
                        ? this.selected_patient.user_id
                        : 0)
                    : false
                ));

                // 요청, 거절 상태인 예약은 제외한다
                this.events = this.events.concat(temp_events.filter(item => item.event_data.appointment 
                        ? item.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.REQUEST &&
                          item.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.DECLINE &&
                          item.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.CONFIRM 
                        : false));

                // 시간 역순
                this.events.sort((item1, item2) : number => {
                    
                    if( new Date(item1.event_data.schedule_date.start).getTime() > new Date(item2.event_data.schedule_date.start).getTime() ) {
                        return -1;
                    } else if( new Date(item1.event_data.schedule_date.start).getTime() < new Date(item2.event_data.schedule_date.start).getTime() ) {
                        return 1;
                    }

                    return 0;
                });

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })
    }

    /**
     * 파일 삭제 API
     */
    deletePatientsFileboxFile(files : hodu_doc_table.t_hospital_patient_file[]) : void {
        
        if( this.selected_patient == null || files == null || files.length < 1 ) { return; }

        this.hodu_show_dialog('cancel', `${files[0].file_name}\n파일을 삭제 하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => { 

                if( this.selected_patient == null ) { return; }

                const patient_id : number = this.selected_patient.user_id;

                this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${patient_id}`, API_METHOD.DELETE, { "files" : files })
                    .then((response) => {
                        console.log(response);
                        this.get_hodu_d_info(this.scope_group_team_option.biz_id);
                        this.getPatientsFileboxInfo();
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });
            }
        ]);
    }

    /**
     * 환자 삭제
     */
    deletePatient() : void {

        if( this.selected_patient == null ) { return; } 

        this.hodu_show_dialog('cancel', `${this.selected_patient.user_name}\n환자를 삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            () => {
                if( this.selected_patient == null ) { return; }
                
                const patient_id : number = this.selected_patient.user_id;

                this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient?patient_id=${patient_id}&group_id=${this.scope_group_id}`, API_METHOD.DELETE)
                    .then((response) => {
                        console.log(response);
                        this.hodu_router_push(`/hospital/${new Date()}/patient`);
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    })

            }
        ])
    }

    /**
     * 방문일 년도 선택
     */
    selectAppointmentYear(value : string) : void {
        if ( value == null || value.length < 1 ) { 
            this.selected_appointment_year = null;
            this.getEvents();
            return;
        }

        this.selected_appointment_year = Number(value);
        this.getEvents();
    }

    /**
     * 예약 년도별 개수 반환
     */
    getAppointmentCountByYear(year : number) : number {
        return this.events.filter(item => item.event_data != null &&
                                          new Date(item.event_data.schedule_date.start).getFullYear() == year).length;
    }

    /**
     * 예약 상태에 따른 클래스 또는 텍스트를 반환
     */
    getAppointmentStatusClassOrText(appointment : t_event_hodu_doc | undefined, is_class : boolean = false) : string {

        if( appointment == null ) return '';

        switch(appointment.appointment_status) {
            case hodu_doc_enum.appointment_status_code.REQUEST  : return is_class ? "ing"       : "요청";
            case hodu_doc_enum.appointment_status_code.DECLINE  : return is_class ? "reject"    : "거절";
            case hodu_doc_enum.appointment_status_code.CONFIRM  : return is_class ? "confirmed" : "확정";
            case hodu_doc_enum.appointment_status_code.RECEIPT  : return is_class ? "made"      : "접수";
            case hodu_doc_enum.appointment_status_code.DIAGNOSIS: return is_class ? "seen"      : "진료";
            case hodu_doc_enum.appointment_status_code.NOSHOW   : return is_class ? "noshow"    : "노쇼";
            default: break;
        }

        return "";
    }

    /**
     * 파일리스트 보기 모드 변경
     */
    fileListCardModeOnOff() : void {
        this.is_file_list_card_mode = !this.is_file_list_card_mode;
    }

    /**
     * 파일함 년도 선택
     */
    selectFileYear(value : string) : void {
        if ( value == null || value.length < 1 ) { 
            this.selected_file_year = null;
            this.getPatientsFileboxInfo();
            return;
        }

        this.selected_file_year = Number(value);
        this.getPatientsFileboxInfo();
    }

    /**
     * 파일 년도별 개수 반환
     */
    getFileCountByYear(year : number) : number {
        return this.file_list.filter(item => item.audit_created != null &&
                                             new Date(item.audit_created).getFullYear() == year).length;
    }

    /**
     * 파일 용량 텍스트
     */
    getFileSizeText(file_size : number) : string {

        let size : string = FILE_SIZE.B;
        while( file_size > 1024 && size != FILE_SIZE.GB ) {
            file_size /= 1024;

            // 사이즈는 GB까지만 표현한다
            switch( size ) {
                case FILE_SIZE.B:
                    size = FILE_SIZE.KB;
                    break;

                case FILE_SIZE.KB:
                    size = FILE_SIZE.MB;
                    break;

                case FILE_SIZE.MB:
                    size = FILE_SIZE.GB;
                    break;
            } 
        }

        return `${file_size.toFixed(2)} ${size}`;
    }
    
    /**
     * 배경화면 반환
     */
    getBackgroundImage(file : hodu_doc_table.t_hospital_patient_file) : string {
        if( new RegExp(escape('image')).test(escape(file.file_type)) == false ) {
            return 'none';
        }

        return `url(app_images/${file.file_path})`;
    }

    /**
     * 파일 추가
     */
    async addFile(files : File[]) : Promise<void> {

        const vue = this;

        if( this.selected_patient == null ) { return; }


        await this.hodu_show_indicator();

        const file_count : number = files.length;
        const event_files : t_event_file[] = [];
        
        // 파일 용량 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > this.DEFAULT_FILE_MAX_SIZE ) {
                alert(`${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`);
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 이미지 파일이면 미리보기용 URL을 생성한다
            // let url : string = "";
            // if( new RegExp(escape('image')).test(escape(files[i].type)) == true ) {
                
            //     let blob : Blob | null = null;

            //     // 리사이즈
            //     await this.fileReaderPromise(files[i])
            //         .then(async(pe_fr : any) => {
            //             if( pe_fr.target == null || pe_fr.target.result == null ) {
            //                 return;
            //             }

            //             let base64url : string = "";

            //             if( pe_fr.target.result instanceof ArrayBuffer ){
            //                 const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
            //                 const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
            //                 base64url = decodeURIComponent(url);
            //             } else {
            //                 base64url = pe_fr.target.result;
            //             }

            //             // 이미지 리사이즈
            //             blob = await vue.hodu_image_resize(base64url);

            //         })
                
            //     url = URL.createObjectURL(blob ? blob : files[i]);
            // }

            const url : string = URL.createObjectURL(files[i]);
            
            event_files.push({
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: url,                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            })

        }

        const form_data : FormData = new FormData();
            
        // FormData 내용 생성
        const image_or_files_count : number = files.length;
        for( let i = 0; i < image_or_files_count; i++ ) {
            form_data.append('file', files[i]);
        }

        // 임시 파일 업로드
        await this.hodu_temp_upload(form_data)
            .then(async(response) => {
                console.log(response);
                const temp_files : t_event_file[] = response.data.data.temp_files;

                // 임시파일 데이터로 객체 대체하기
                const file_count : number = event_files.length;
                for( let i = 0; i < image_or_files_count; i++ ) {
                    event_files.splice(i, 1, temp_files[i]);    
                }

            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        // 환자 파일함에 등록
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${this.selected_patient.user_id}&group_id=${this.scope_group_id}`, API_METHOD.POST, { "event_files" : event_files })
            .then(async(response) => {
                console.log(response);
                this.getPatientsFileboxInfo();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

        // 파일 input 비우기
        $('#addFile').val("");

        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 promise
     */
    // fileReaderPromise(file : File | Blob) : Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         const fileReader : FileReader = new FileReader();
    //         fileReader.onload = (fr) => resolve(fr);
    //         fileReader.onerror = () => reject();
    //         fileReader.readAsDataURL(file);
    //     });
    // }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        // this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        // DO NOTHING, 나중에 효과 생길때 사용
        // this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        // this.file_drag = false;
        this.addFile(event.dataTransfer.files);
    }

    /**
     * 파일 클릭
     */
    fileClick(file : hodu_doc_table.t_hospital_patient_file) : void {

        // 이미지는 미리보기
        if( new RegExp(escape('image')).test(escape(file.file_type)) == true ) {
            this.imagePreviewOpen(file);
            return;
        }   

        this.fileDownload(file);
    }

    /**
     * 이미지 미리보기
     */
    imagePreviewOpen(image : hodu_doc_table.t_hospital_patient_file) : void {
        
        const image_files : t_event_file[] = []

        const images : hodu_doc_table.t_hospital_patient_file[] = this.file_list.filter(item => new RegExp(escape('image')).test(item.file_type) == true);
        const image_index : number = images.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        for( const image of images ) {
            image_files.push({
                name : image.file_name,
                url : image.file_path,
                mimeType : image.file_type,
                size : image.file_size,
                date : image.audit_created ? image.audit_created : new Date()
            })
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : image_files
        });
        this.doSetShowEventImagePreview(true);
    }

    // 파일 다운로드 (조회)
    fileDownload(file : hodu_doc_table.t_hospital_patient_file) : void {
        this.hodu_download(`/app_images/${file.file_path}`, file.file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 예약 생성 모달
     */
    showAppointmentCreateModal() : void {
        if( this.doSetAppointmentCreateModalInfo == null || this.hospital_setting_info == null || this.selected_patient == null ) { return; }
        
        const current : Date = new Date();

        const settings : hodu_doc_object.hospital_setting_info[] = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= current.getTime() &&
                                                                                                             new Date(item.end_date).getTime()   >= current.getTime() );
        
        const term : number = settings[0].reservation_term;
        const max_count : number = Math.floor(60 / term);

        const current_count = Math.floor(current.getMinutes() / term);

        // 현재 시각이 마지막 텀 이후라면 1시간 후 0분으로, 아니라면 다음 텀 시간으로
        let target_date = moment(current).set("minute", term * (current_count + 1))
                                         .set("second", 0)
                                         .set("millisecond", 0).toDate();
                                         
        if( max_count <= current_count ) {
            target_date = moment(current).add("hour", 1)
                                         .set("minute", 0).toDate();
        }

        this.doSetAppointmentCreateModalInfo({ 
            "show_modal"            : true,
            "is_create_one_patient" : true, 
            "department_code"       : "",
            "doctor_code"           : "",
            "original_timetable"    : target_date,
            "patient_info"          : this.selected_patient
        });
    
    }

    /**
     * 예약 상세로 이동
     */
    moveAppointmentDetail(event_id : string | undefined) : void {
        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event_id}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#patient_file_box_list_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#appointment_list_scroll_by_patient').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>

    .basicDiv .docImgDiv label { background-image: url(../../assets/images/footer/doc_2.png) !IMPORTANT; border-color:#13D08B !important }

    
    .content { height:100%;overflow: hidden; }
    .titleDiv { overflow: hidden; padding: 30px; }
    .titleDiv h4 { font-size: 17px;line-height: 50px; height: 50px;display:inline-block }
    .titleDiv .btns { display:inline-block; float:right;  }
    .thisPatientFileBox .btns { overflow: hidden; }
    .thisPatientFileBox .btns label { position: static;float: right; margin-right: 0 !important; }
    .thisPatientFileBox .btns input[type="button"] { float: right; position: static;margin-left: -5px !important; }
    .basicDiv { width:60%;float:left;height:100%;  }
    .basicDiv > .title { font-size:17px;font-weight: bold; margin: 30px 30px 0; }
    .basicDiv .docImgDiv label { width: 100px; height:100px;border-radius: 50%; box-sizing: border-box; border: 3px solid #477fff; display: block;  font-size:0; cursor:pointer; background-image: url(../../assets/images/contents/im_photoB.gif); background-size:cover;background-position: center center; background-repeat: no-repeat; }
    .basicDiv .docImgDiv input[type="file"] { display:none; }
    .basicDiv .docImgDiv .del { transition:0.2s; position:absolute; right:0;bottom:0; display: block; width: 35px; height: 35px; background:#ff6060  url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .basicDiv .docImgDiv .del:hover { background-color: #fd1818 }
    .basicDiv .firstDiv { position:relative; padding: 30px 30px 30px 30px; border-bottom:5px solid #e7e9ea; }
    .docImgDiv { position: absolute; left:40px;top:59px;  }
    .patientDetailDiv ul { overflow: hidden; }
    .patientDetailDiv li { position: relative; float:left; margin-bottom:15px; width:50%;display:inline-block; padding-right: 20px; box-sizing: border-box; height: 78px; }
    .patientDetailDiv select { font-weight:bold; transition:0.2s; cursor:pointer; appearance: none;padding-left: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; border-radius: 5px;background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 96%  center; background-repeat: no-repeat }
    .patientDetailDiv select:hover { background-color:#f1f3f5; }
    .patientDetailDiv select:focus { border-color:#477fff; background-color:#fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .patientDetailDiv label { font-size: 13px;display:block; line-height: 23px; height:23px; margin-bottom:5px; } 
    .patientDetailDiv input[type="text"] { transition:0.2s; font-weight: bold; padding: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; }
    .patientDetailDiv input[type="text"]:hover { background:#f1f3f5; }
    .patientDetailDiv input[type="text"]:focus { border-color:#477fff; background:#fff; }
    .patientDetailDiv input[type="text"]:disabled { color:#7c88a2; background:#f8f9fa;  }
    .patientDetailDiv .patientTelList input[type="text"], .patientDetailDiv .patientTelList select { width: 30%; margin-right: 3.333333%; } 

    .appointmentDiv .title { font-size: 16px; font-weight: bold; display: block; margin-bottom: 20px;    margin-top: 10px; }
    .appointmentDiv > ul > li { overflow: hidden; position:relative;}
    .appointmentDiv > ul > li > input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; }
    .appointmentDiv > ul > li > input:hover { background-color:#f1f3f5; }
    .appointmentDiv > ul > li > input:focus { background-color:#fff; border-color:#477fff; }
    .appointmentDiv > ul > li > span { padding-right:20px; }
    /* .appointmentDiv .descript { position: absolute; right:0; top:10px; }
    .appointmentDiv .descript li { overflow:hidden; display:inline-block;margin-right:20px; }
    .appointmentDiv .descript li:last-child { margin-right:0; }
    .appointmentDiv .descript li .clr { float:left; margin-right:5px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; }
    .appointmentDiv .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .appointmentDiv .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .appointmentDiv .descript li .clr.allday {background: #477fff !important;border-color: #477fff;}
    .appointmentDiv .descript li .clr.off { background: #a1a5bb !important;border-color: #a1a5bb;} */
    .appointmentDiv .delContract { border-radius: 23px; background:#ff6060; border:0 none;  color:#fff; padding: 0 50px; font-size: 14px; line-height: 40px; height:40px; margin:0 auto; display:block; margin-top:20px; }
    .appointmentDiv .delContract:hover, .appointmentDiv .delContract:focus { background: #ff2e2e }
    .appointmentDiv .btns { float:right; margin-top:0px;} 
    .contractSelect { transition:0.2s; cursor:pointer; font-size: 14px; font-weight:bold; appearance: none; border-radius: 5px; line-height: 50px; height:55px; border:1px solid #e7e9ea;padding: 0 20px; width:100%; box-sizing: border-box; background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 98%  center; background-repeat: no-repeat }
    .contractSelect:hover { background-color:#f1f3f5;  }
    .contractSelect:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .contractSelect option { height: 40px; padding:20px 0; }

    .appointmentList li { transition:0.2s; position:relative; font-size: 12px; line-height: 47px; height:47px; border-bottom:1px solid #f1f3f5; }
    .appointmentList li a { transition:0.2s;display:inline-block; width:100%; height: 100%; }
    .appointmentList li a:hover {transition:0.2s; font-weight:bold; }
    .appointmentList li .workHours { display:inline-block;padding: 0 30px 0 90px; text-align: center; }
    .appointmentList li .day { display: inline-block; border-left: 1px solid #e7e9ea; line-height: 15px;padding: 0 25px;  }
    /* .workHours span {display:inline-block; padding: 0 25px; } */
    .dayUl  { overflow:hidden; border-right:1px solid #e7e9ea}
    .dayUl li { display: inline-block; width: 14.2857142857%; float:left; text-align:center; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .dayUl li:hover .day { background:#f1f3f5; }
    .dayUl li p { line-height: 35px; height: 35px; font-weight: bold; border-bottom: 1px solid #e7e9ea;border-top: 1px solid #e7e9ea; }
    #docClrId {transition:0.2s;width: 100%;height: 50px;line-height: 50px;border-radius: 5px;background: #fff;border: 1px solid #e7e9ea;box-sizing: border-box;padding: 0 15px;text-align: left;font-weight: bold;  }
    #docClrId:hover { background: #f1f3f5; }
    .docDetailDiv .clr { position:absolute; right:35px; top:43px; display: inline-block; width:20px;height: 20px; border-radius: 50%; ; }


    .thisPatientFileBox { width: 40%;float:left; height:100%; border-left:1px solid #e7e9ea;box-sizing: border-box;  }
    .yearSelect { transition:0.2s; cursor:pointer; margin-top:10px; margin-left:15px; float:left; border: 1px solid #232848;border-radius: 20px; font-size: 13px; line-height: 26px; height:30px; appearance: none; padding: 0 30px 0 15px; font-weight: bold;background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 90%  center; background-repeat: no-repeat !important }
    .yearSelect:hover { background-color:#f1f3f5; }
    .yearSelect:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }

    .appointmentDiv .yearSelect { margin-top:2px; }

    .titleDiv { background:#fff; padding-bottom: 10px; padding-top:20px; }
    .titleDiv h4 { float:left;  }
    .thisPatientFileBox .btns input#addFile { display:none; }
    .thisPatientFileBox .btns .addFilePlus { margin-right:35px; margin-top:7px; }
    .btns .addFilePlus { cursor:pointer; display:block; transition:0.2s;background: url(../../assets/images/contents/ic_add.png) no-repeat center center; font-size: 0;width: 35px;height:35px; background-size: 40px; border-radius: 50%;margin-top: 5px;}
    .btns .addFilePlus:hover {background-image: url(../../assets/images/contents/btn_add_fff.png); background-color:#477fff;}

    .thisPatientFileUl li { position:relative; background:#fff; font-weight:bold;padding:0 30px; }
    .thisPatientFileUl li a { border-bottom: 1px solid #f1f3f5; line-height: 55px; height: 80px; display: block; padding-left: 80px; padding-right: 100px; }
    .thisPatientFileUl li a p { display:inline-block; }
    .thisPatientFileUl li .preview {border:1px solid; position:absolute; left:30px;top:18px; padding:0 6px; border-radius: 2px; background:#fff; border:1px solid #e7e9ea; color:#fff;height:40px; line-height: 40px; font-weight: bold; margin-right: 15px; border-radius: 3px; }
    .thisPatientFileUl li .fileName { width: 100%; font-size:13px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .thisPatientFileUl li .fileName span { padding-right: 10px; }
    .thisPatientFileUl li .uploaded {left:110px;top:25px; position: absolute; opacity:0.7 }
    .thisPatientFileUl li:hover .delFileBtn { opacity:1; }
    .thisPatientFileUl li .delFileBtn { transition: 0.2s; opacity:0; position: absolute; right:30px; bottom:15px; height: 20px; line-height: 20px; padding:0 5px; font-weight: bold; color:#fff; background-color: #ff6060; border-radius: 3px;}
    .thisPatientFileUl li .delFileBtn :hover { background-color:#ff1f1f}
    .fileSize { position:absolute; right: 30px;top:0; }



    .clr { padding:0 13px; border-radius: 20px; color:#fff; float:right; display:inline-block !important; height: 20px;line-height: 20px;margin-top:14px; }
    .status { position:absolute; top:12px; left: 10px; height: 23px; text-align: center; width:50px; border-radius: 20px; line-height:21px; display:inline-block; font-weight:bold; font-size: 12px; background:#477fff; box-sizing: border-box; margin-right: 10px; color:#fff; border:none; border: 1px solid;}
    .noshow { background: #ff6363;border-color:#ff4949;  color:#fff !important; }
    .seen { background: #477fff;border-color:#1c61ff;  color:#fff !important; }
    .ing { background: #f1f3f5;color:#747e90 !important; border-color: #dadde0; }
    .made { background: #FFC72F;color:#39445d !important; border-color: #f1b71a; }
    .confirmed { background: #13D08B; border-color:#17bf82; color:#fff !important;}
    .reject { background: #fff0f0;color:#ff6363 !important; border-color:#ffd7d7}


    .preview { float:left; width: 50px;;border-radius: 5px; margin-right:10px; height: 35px; font-size:0;border:1px solid #e7e9ea; display: inline-block; background-color: #fff; background-position: center center !important; background-size: cover !important; background-repeat: no-repeat !important; }
    .preview.format { font-size: 12px !important; font-weight:bold; line-height: 35px; text-align: center; color:#477fff !important; text-transform: uppercase; }
    
    .btns  { overflow: hidden; }
    .btns .dpTwo {display:inline-block; width: 40px;height: 40px;background: url(../../assets/images/contents/ic_dpTwo.png) no-repeat center right;font-size: 0; margin-top:5px;float:right;margin-left:10px;}
	.btns .dpOne {display:inline-block; width: 40px;height: 40px;background: url(../../assets/images/contents/ic_dpOne.png) no-repeat center right;font-size: 0; margin-top:5px;float:right; margin-left:10px;}

    /* 오른쪽 파일리스트 박스형으로 보이기 */
    .thisPatientFileBox.dp2 .thisPatientFileUl li a { padding: 0; display: block; width:100%; height: 100%; border: none; }
    .thisPatientFileBox.dp2 .thisPatientFileUl li { overflow:visible; line-height: 30px; text-align: center; width: 170px;height: 217px; display:inline-block; float:none;border:1px solid #e7e9ea; margin: 30px 0 0 30px; border-radius: 5px;padding: 20px; box-sizing: border-box; }
    .thisPatientFileBox.dp2 .thisPatientFileUl li:hover { background-color:#fff; box-shadow: 0 5px 15px rgba(0,0,0,0.1) }
    .thisPatientFileBox.dp2 .thisPatientFileUl li p { display: block; width:100%; }
    .thisPatientFileBox.dp2 .thisPatientFileUl li .preview { position: static; width: 114px; height: 80px; line-height:80px; display:block; float:none; margin:0 auto; }
    .thisPatientFileBox.dp2 .thisPatientFileUl li .preview.format { font-size: 14px; }
    .thisPatientFileBox.dp2 .thisPatientFileUl li .fileName { text-align: left; display:block; overflow:hidden; line-height: 30px; height:30px; text-overflow: ellipsis; white-space: nowrap; }
    .thisPatientFileBox.dp2 .thisPatientFileUl li .uploaded, .thisPatientFileBox.dp2 .thisPatientFileUl li .fileName, .thisPatientFileBox.dp2 .thisPatientFileUl li .fileSize {position:static;display:block; padding: 0 !important;  }
    .thisPatientFileBox.dp2 .thisPatientFileUl li .fileSize  { display:block; position: static; width:100%; line-height: 30px; height:30px; border-top:1px solid #e7e9ea; border-bottom: 1px solid #e7e9ea;margin: 5px 0; }
    .thisPatientFileBox.dp2 .thisPatientFileUl li .uploaded {line-height: 30px; height:30px; }
    .thisPatientFileBox.dp2 .thisPatientFileUl input[type='checkbox']:not(old) + label { margin-top:0;float: left; }
    .thisPatientFileBox.dp2 .thisPatientFileUl .no { position: absolute; left: 60px;top: 20px; width: 120px; height: 24px; line-height: 24px; }
    .thisPatientFileBox.dp2 .thisPatientFileUl li .delFileBtn {right: -12px;top: -13px;}
    .thisPatientFileBox.dp2 .thisPatientFileUl li .delFileBtn  { opacity:0; transition:0.2s; position:absolute; width: 30px; height:30px; background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .thisPatientFileBox.dp2 .thisPatientFileUl li:hover .delFileBtn { opacity:1; }
    .thisPatientFileBox.dp2 .thisPatientFileUl ul { padding-bottom: 30px; }

    .left_area_close .content {padding-left: 61px !important;box-sizing: border-box;}

    /* 예제, 나중에 지우기 */
    .thisPatientFileUl ul li:nth-child(1) .preview { background-image:url(../../assets/images/footer/xray_20200403.jpg) }
    .thisPatientFileUl ul li:nth-child(2) .preview { background-image:url(../../assets/images/footer/xray_20200413.jpg) }
    .thisPatientFileUl ul li:nth-child(5) .preview { background-image:url(../../assets/images/footer/d_scope.jpg) }
    .thisPatientFileUl ul li:nth-child(8) .preview { background-image:url(../../assets/images/footer/w_scope.jpg) }

    /* 연락처 3분할이 아닌 1개의 text로 사용 */
    #patientTelId4 { width : 100%; }

    /* 방문일 div에 padding을 넣는게 아니라 li와 스크롤 div에 padding 넣도록 수정 */
    /* .appointmentDiv { padding: 30px; } */
    /* .appointmentList li:first-child { border-top:1px solid #e7e9ea; } */
    .appointment_header_li { padding: 30px 30px 0px 30px; box-sizing: border-box;border-bottom:1px solid #e7e9ea;  }
    #appointment_list_scroll_by_patient { padding: 0 30px 0 30px; box-sizing: border-box; }

    /* 파일함 파일리스트 first-child가 아닌 title div의 border-bottom 으로 변경*/
    /* .thisPatientFileUl li:first-child a {  border-top: 1px solid #e7e9ea; } */
    .thisPatientFileBox .titleDiv { border-bottom: 1px solid #e7e9ea; }

    .apUl, .pfbUl { padding-bottom: 100px; }
    .noResult { display:block; width: 100%; padding: 20px 30px; position: static !important; }
    .noResult p { background:#f1f3f5;border-radius: 5px; height: 60px; line-height: 60px; font-size: 13px;; }
</style>