<template>
    <div id="home_resident_join_modal" class="modal">

        <div class="titlebox">
            <h1>가입신청</h1>
        </div>

        <div class="modal_content">
            <!-- 가입신청 화면 -->
            <div class="residentJoinDiv" v-if="is_join == true">
                <p>정확한 정보를 입력해주세요.</p>
                <div class="joinModalCon">
                    <div class="joinUserImg">
                        <p class="userImg">가입자사진</p>
                    </div>
                    <div class="joinUserInfor">
                        <ul class="inforUl">
                            <li>
                                <p>성명</p>
                                <div class="listText">
                                    <input type="text" placeholder="성명 입력" :value="name" @input="name = $event.target.value">
                                </div>
                            </li>
                            <li>
                                <p>전화번호</p>
                                <div class="listText">
                                    <!-- <input type="text" placeholder="전화번호 입력 (예 : 01012345678)" :value="phone" @input="phone = $event.target.value"> -->
                                    <input type="text" placeholder="전화번호 입력 (예 : 01012345678)" :value="user_phone_number" disabled>
                                </div>
                            </li>
                            <li class="listBlock">
                                <p>아파트 인증</p>
                                <div class="listText appNum">
                                    <input type="number" placeholder="아파트 동 (예 : 101)" :value="building" @input="building = $event.target.value">
                                    <input type="number" placeholder="아파트 호 (예 : 1203)" :value="unit" @input="unit = $event.target.value">
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- //가입신청 화면 -->

            <!-- 가입신청완료 화면 -->
            <div class="joinOtherDiv joinCompleteDiv" v-if="is_join == false && appr_state == 'R'">
                <div class="complTitle">
                    <img src="@/assets/images/contents/img_complete.png" alt="완료">
                    <h3>가입신청 완료</h3>
                </div>
                <div class="complcon">
                    <p>관리사무소에 가입신청을 요청하였습니다.</p>
                    <p>승인 완료 후 이용 가능하십니다.</p>
                    <p>&#40;아파트 동,호수를 한번 더 확인해주세요.&#41;</p>
                    <p class="appNumRe">동/호수 : <span>{{ building }}동 {{ unit }}호</span></p>
                </div>
            </div>
            <!-- //가입신청완료 화면 -->

            <!-- 가입승인거절 화면 -->
            <div class="joinOtherDiv joinRejectDiv" v-if="is_join == false && appr_state == 'E'">
                <div class="complTitle">
                    <img src="@/assets/images/contents/img_reject.png" alt="거절">
                    <h3>가입승인 거절</h3>
                </div>
                <div class="complcon">
                    <p>요청하신 입주민의 정보 오류발생으로</p>
                    <p>승인이 거절되었습니다.</p>
                    <p>재가입요청 또는 관리사무소에 문의 바랍니다.</p>
                    <p class="appNumRe">동/호수 : <span>{{ building }}동 {{ unit }}호</span></p>
                </div>
                <div class="inquiryBox">
                    <p>
                        관리사무소 문의
                        <span>{{ manager_tel }}</span>
                    </p>
                </div>
            </div>
            <!-- //가입승인거절 화면 -->
        </div>

        <div class="btns">
            <input type="button" :value="is_join ? '취소' : '확인'" @click="close">
            <input type="button" :value="is_join ? (home_resident_join_modal_info.is_invite ? '가입' : '가입신청') : '가입취소'" @click="join">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

@Component({
    components: {
        
    },
})
export default class HomeResidentJoinModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_resident_join_modal_info !: hodu_home_modal_info.HomeResidentJoinModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeResidentJoinModalInfo ?: (params : hodu_home_modal_info.HomeResidentJoinModalInfo) => void;

    is_join : boolean = true;
    appr_state = "";
    name : string = "";
    building : string = "";
    unit : string = "";
    manager_tel : string = "";
    
    mounted() : void {
        this.getResidentInfo();
    }

    /**
     * 가입 신청 내역 조회
     */
    async getResidentInfo() : Promise<void> {
        try {

            const group_id = this.home_resident_join_modal_info.group_id;
            const user_id = this.user_id;

            const response = await this.hodu_api_call(`api/v1/home/${group_id}/resident/${user_id}`, API_METHOD.GET);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("호두홈 가입 정보 조회 실패");
            }

            this.is_join = (response.data.data.resident == null);

            if( this.is_join ) {
                await this.updateResidentNewInfo();
                return;
            }

            if( !this.is_join ) {
                const resident = response.data.data.resident;
                const resident_info = resident.resident_info;
                const basic_info = resident.basic_info;

                this.name = basic_info.nickname;
                this.building = resident_info.building;
                this.unit = resident_info.unit;

                this.appr_state = resident.appr_state;

                this.manager_tel = resident.manager_tel;

                if( this.appr_state == 'A' ) {
                    alert("가입 완료");
                    this.close();
                    return;
                }
            }

        }
        catch(e) {
            alert("호두홈 가입 정보 조회 실패");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    /**
     * 자동가입 절차
     */
    async updateResidentNewInfo() : Promise<void> {
        try {

            const group_id = this.home_resident_join_modal_info.group_id;
            const user_id = this.user_id;
            
            const response = await this.hodu_api_call(`api/v1/home/resident/new/${group_id}/${user_id}`, API_METHOD.PUT);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("호두홈 자동가입 시도 실패");
            }

            // 매칭되는 정보 없기 때문에 수동가입
            if( response.data.data.resident == null ) {
                return;
            }

            const group_response = await this.hodu_api_call(`api/v1/groups/groupMemberInsert/${group_id}`, API_METHOD.POST, {
                "is_appr_required" : false,
            });

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("호두홈 자동가입 시도 실패");
            }

            alert("가입 완료");
            this.close();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    close() : void {
        this.doSetHomeResidentJoinModalInfo?.({ "show_modal" : false, "group_id" : 0, "is_invite" : false });
        
        // 그룹 재조회
        window["getGroupList"]?.();
        this.get_group_role_service();
    }
    
    async join() : Promise<void> {

        const group_id = this.home_resident_join_modal_info.group_id;

        // 가입 신청
        if( this.is_join == true ) {

            try {

                if( this.building.length < 1 || this.unit.length < 1 || this.name.length < 1 ) {
                    alert("성명, 전화번호, 동/호 정보를 전부 입력 해주세요");
                    return;
                }

                if( isNaN(Number(this.building)) || isNaN(Number(this.unit)) ) {
                    alert("동/호 정보는 숫자로만 입력 해주세요");
                    return;
                }

                const response = await this.hodu_api_call(`api/v1/groups/groupMemberInsert/${group_id}`, API_METHOD.POST, {
                    "is_appr_required" : this.home_resident_join_modal_info.is_invite == false ? true : false,
                    "resident_info" : {
                        "building": Number(this.building),
                        "unit": this.unit,
                        "phone": this.user_phone_number,
                        "name": this.name
                    },
                });

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("호두홈 가입 중 오류 발생");
                }

                if( this.home_resident_join_modal_info.is_invite == true ) {
                    this.doGroupId(group_id);
                    this.doTeamId(0);
                    this.doGroupStatisticsStartDate("");
                    this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
                }

                this.close();
                return;

            } catch(e) {
                alert("호두홈 가입 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
                this.close();
                return;
            }
        }

        // 가입 신청 취소
        if( confirm("정말로 가입 취소 하시겠습니까?") == true ) {
            try {
                const response = await this.hodu_api_call(`api/v1/groups/groupMemberDelete/${group_id}/${this.user_id}`, API_METHOD.DELETE, null);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("호두홈 가입 취소 중 오류 발생");
                }
                
                this.close();
                return;

            } catch(e) {
                alert("호두홈 가입 취소 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
                this.close();
                return;
            }
        }
    }

}
</script>

<style scoped>
    #home_resident_join_modal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-215px; margin-left:-285px; width:570px; height: auto; background:#fff; }

    #home_resident_join_modal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; }
    #home_resident_join_modal .titlebox h1 { font-size: 20px; font-weight: bold; width: 100%; height: 80px; line-height: 90px; text-align: left; border-bottom: 1px solid #e7e9ea; padding: 0 30px; box-sizing: border-box; }

    #home_resident_join_modal .modal_content { overflow: hidden; height: auto; border-bottom: 1px solid #e7e9ea; padding: 20px 30px; box-sizing: border-box; }

    #home_resident_join_modal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    #home_resident_join_modal .btns input { transition:0.2s; width: 50%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold; background: #fff; }
    #home_resident_join_modal .btns input:hover { background:#f1f3f5; }

    .modal_content p { height: 30px; line-height: 30px; font-size: 14px; margin-bottom: 20px; }
    .modal_content .residentJoinDiv { display: block; } /* 가입신청 */
    .modal_content .joinCompleteDiv { display: block; text-align: center; } /* 가입신청완료 */
    .modal_content .joinRejectDiv { display: block; text-align: center; } /* 가입승인거절 */
    .modal_content .residentJoinDiv .joinModalCon { display: flex; align-items: center; }

    /* 이미지 */
    .residentJoinDiv .joinModalCon .joinUserImg { width: 120px; height: 120px; }
    .residentJoinDiv .joinModalCon .joinUserInfor { width: 360px; margin-left: 30px; }
    .residentJoinDiv p.userImg { font-size: 0; width: 100%; height: 100%; border-radius: 50%; background: url('../../../assets/images/contents/ic_approval_user.png') no-repeat center center; background-size: cover; border: 1px solid #e7e9ea; }

    /* 텍스트 */
    .residentJoinDiv .joinUserInfor .inforUl li { display: flex; }
    .residentJoinDiv .joinUserInfor .inforUl p { width: 25%; }
    .residentJoinDiv .joinUserInfor .inforUl .listText { width: 75%; }
    .residentJoinDiv .joinUserInfor .inforUl .listText input[type="text"] { width: 100%; height: 30px; line-height: 30px; padding: 0 10px; box-sizing: border-box; border: 1px solid #f1f3f5;}
    .residentJoinDiv .joinUserInfor .inforUl .listText input[type="number"] { width: 100%; height: 30px; line-height: 30px; padding: 0 10px; box-sizing: border-box; border: 1px solid #f1f3f5;}
    .residentJoinDiv .joinUserInfor .inforUl li.listBlock { display: block; }
    .residentJoinDiv .joinUserInfor .inforUl li.listBlock p { margin-bottom: 8px; }
    .residentJoinDiv .joinUserInfor .inforUl li.listBlock .appNum { width: 100%; }
    .residentJoinDiv .joinUserInfor .inforUl li.listBlock input[type="text"] { width: 48% !important; text-align: right; }
    .residentJoinDiv .joinUserInfor .inforUl li.listBlock input[type="text"]:first-child { margin-right: 14px; }
    .residentJoinDiv .joinUserInfor .inforUl li.listBlock input[type="number"] { width: 48% !important; text-align: right; }
    .residentJoinDiv .joinUserInfor .inforUl li.listBlock input[type="number"]:first-child { margin-right: 14px; }

    /* 가입신청완료 가입승인거절 */
    .joinOtherDiv .complTitle img { width: 60px; height: 60px; margin-bottom: 10px; }
    .joinOtherDiv .complTitle h3 { font-size: 20px; }
    .joinOtherDiv .complcon { margin-top: 20px; }
    .joinOtherDiv .complcon p { height: 23px; line-height: 23px; margin-bottom: 0px; opacity: .6; font-weight: bold; }
    .joinOtherDiv .complcon p.appNumRe { margin-top: 10px; opacity: 1; }
    .joinOtherDiv .inquiryBox { width: 60%; background: #f1f3f5; padding: 5px 10px; margin: 0 auto; box-sizing: border-box; margin-top: 10px; }
    .joinOtherDiv .inquiryBox p { margin-bottom: 0px; }
    .joinOtherDiv .inquiryBox p:before { content: ''; width: 23px; height: 23px; border-radius: 50%; display: inline-block; background: url('../../../assets/images/contents/ic_tel_background.png') no-repeat center; background-size: 100%; vertical-align: middle; margin: -3px 5px 0 0; }
    .joinOtherDiv .inquiryBox p span { font-weight: bold; font-size: 16px; margin-left: 20px; }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
</style>