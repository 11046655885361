<template>
    <div id="equipment" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">장비사양 상세보기</h3>
            <p class="btn_group">
                <input type="button" class="input_btn" value="수정" @click.prevent="moveEquipmentModify"/>
                <input type="button" class="input_btn" value="삭제" @click.prevent="deleteHomeEquipment">
                <input type="button" class="input_btn" value="QR코드 라벨" @click.prevent="qrcode">
            </p>
        </div>

        <!-- <input type="button" class="input_btn" value="장비 수정" @click.prevent="moveEquipmentCreate"/> -->
        <!-- <input type="button" class="input_btn" value="장비 관리 이력 추가" @click.prevent="moveEquipmentManagementCreate"/> -->
        <!-- <input type="button" class="input_btn" value="장비 관리 이력 상세" @click.prevent="showEquipmentManagementDetail"/> -->

        <div id="equipment_detail" v-if="equipment != null">
            <div class="eqinfor eqinfor01">
                <ul class="infor_box">
                    <li>
                        <p class="eqTitle">장비명</p>
                        <p>{{ equipment.equipment_data.name }}</p>
                    </li>
                    <li>
                        <p class="eqNum leftboder">관리번호</p>
                        <p>{{ equipment.equipment_data.code }}</p>
                    </li>
                    <li class="eqTime_li">
                        <p class="makeTime">제조일</p>
                        <p>{{ hodu_date_to_format_string(yyyymmddToDate(equipment.equipment_data.manufacture_date), 'YYYY.MM.DD') }}</p>
                    </li>
                    <li class="eqTime_li">
                        <p class="buyTime leftboder">구입일</p>
                        <p>{{ hodu_date_to_format_string(yyyymmddToDate(equipment.equipment_data.purchase_date), 'YYYY.MM.DD') }}</p>
                    </li>
                    <li>
                        <p class="eqPlace">사용처</p>
                        <p>{{ equipment.equipment_data.location }}</p>
                    </li>
                    <li>
                        <p class="eqTeam leftboder">담당자</p>
                        <p>{{ equipment.equipment_data.manager }}</p>
                    </li>
                </ul>
            </div>
            <!-- //eqinfor01 -->
            <div class="eqinfor eqinfor02">
                <ul class="infor_box">
                    <li class="text_one">
                        <p class="eqRemark">비고</p>
                        <p>{{ equipment.equipment_data.desc }}</p>
                    </li>
                </ul>
            </div>
            <!-- //eqinfor02 -->
            <div class="eqinfor eqinfor03">
                <h2>제원</h2>
                <a href="#" class="eqinfor03_btn" :class="{ off : is_off_spec == true }" @click.prevent="is_off_spec = !is_off_spec">접기펼치기</a>
                <div class="eqinfor03_box" :class="{ off : is_off_spec == true }">
                    <div class="eq_picture">
                        <h3 class="ir_so blind">장비사진</h3>
                        <p class="eq_img" :style="{ 
                            'background-image': equipment.equipment_data.images.length > 0 ? `url(${equipment.equipment_data.images[image_view_seq].url.indexOf('home/') == -1 ? equipment.equipment_data.images[image_view_seq].url : `/app_images/${equipment.equipment_data.images[image_view_seq].url}` })` : `url(${default_image})`,
                            'background-size' : equipment.equipment_data.images.length > 0 ? 'cover' : 'auto'
                        }"></p>
                        <input type="button" class="btn_img_prev" value="이전" :disabled="image_view_seq == 0" @click.prevent="imageSeqMove(-1)" v-if="equipment.equipment_data.images.length > 1"/>
                        <input type="button" class="btn_img_next" value="다음" :disabled="image_view_seq == (equipment.equipment_data.images.length - 1)" @click.prevent="imageSeqMove(1)" v-if="equipment.equipment_data.images.length > 1"/>
                    </div>
                    <div class="eq_explanation">
                        <ul>
                            <li>
                                <p class="eqClass eqLarge">대분류</p>
                                <p class="eqClass_text">{{ equipment.equipment_data.major_category }}</p>
                            </li>
                            <li>
                                <p class="eqClass eqMedium">중분류</p>
                                <p class="eqClass_text">{{ equipment.equipment_data.middle_category }}</p>
                            </li>
                            <li>
                                <p class="eqClass eqSmall">소분류</p>
                                <p class="eqClass_text">{{ equipment.equipment_data.minor_category }}</p>
                            </li>
                            <li>
                                <p class="eqmake">제조사</p>
                                <p class="eqmake_text">{{ equipment.equipment_data.manufacturer }}</p>
                            </li>
                            <li>
                                <p class="eqmake_tel">제조사 번호</p>
                                <p class="eqmake_text">{{ equipment.equipment_data.manufacturer_tel }}</p>
                            </li>
                            <li>
                                <p class="eqsale">판매처</p>
                                <p class="eqsale_text">{{ equipment.equipment_data.vendor }}</p>
                            </li>
                            <li>
                                <p class="eqsale_tel">판매처 번호</p>
                                <p class="eqsale_text">{{ equipment.equipment_data.vendor_tel }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- //eqinfor03 -->
            <div class="eqCardList">
                <h2>장비관리 이력</h2>
                <p class="btn_group">
                    <input type="button" class="input_btn" value="이력추가" @click.prevent="moveEquipmentManagementCreate"/>
                </p>
                <div id="eqinfor04_box" class="eqinfor04_list">
                    <div class="fixedDiv">
                        <ul id="" class="sortHeader">
                            <li class="num">No</li>
                            <li class="eqSubmit">등록일</li>
                            <li class="eqFix">내용</li>
                            <li class="eqFixstore">업체</li>
                            <li class="writtenBy">담당자</li>
                            <li class="eqRemark">비고</li>
                            <li class="eqPhoto">첨부</li>
                        </ul>
                    </div>
                    <div class="eqinfor04_con">
                        <ul class="eqinfor04Ul">
                            <li :key="management.seq" v-for="(management, index) in equipment.managements">
                                <p class="num">{{ index + 1 }}</p>
                                <p class="eqSubmit">{{ hodu_date_to_format_string(yyyymmddToDate(management.management_data.repair_date), 'YYYY.MM.DD') }} ({{ getDayOfWeekByDate(yyyymmddToDate(management.management_data.repair_date)) }})</p>
                                <p class="eqFix">{{ hodu_string_validation(management.management_data.repair_items) }}</p>
                                <p class="eqFixstore">{{ hodu_string_validation(management.management_data.repairer) }}</p>
                                <p class="writtenBy">{{ hodu_string_validation(management.management_data.manager) }}</p>
                                <!-- 장비수리이력에 나오는 비고가 나오게 바꿔주세요-->
                                <p class="time eqRemark">{{ hodu_string_validation(management.management_data.memo) }}</p>
                                <!-- 사진이 없을경우 eqPhoto_none -->
                                <p class="eqPhoto"><span class="eqPhoto_icon" v-if="management.management_data.images.length > 0">장비사진 여부 icon</span></p>
                                <a href="#" class="eqcardDetail" @click.prevent="showEquipmentManagementDetail(management.seq)">이력 상세보기</a>
                            </li>
                            <!-- <li>
                                <p class="num">1</p>
                                <p class="eqSubmit">21.05.11 (화)</p>
                                <p class="eqFix">엔진 불량교체</p>
                                <p class="eqFixstore">호두정비테크</p>
                                <p class="writtenBy">호담당 기사</p>
                                <p class="time">21.05.21 (금)</p>
                                <p class="eqPhoto"><span class="eqPhoto_icon">장비사진 여부 icon</span></p>
                                <a href="#" class="eqcardDetail" @click.prevent="showEquipmentManagementDetail">이력 상세보기</a>
                            </li>
                            <li>
                                <p class="num">2</p>
                                <p class="eqSubmit">21.05.03 (월)</p>
                                <p class="eqFix">오일 필터</p>
                                <p class="eqFixstore">호두정비테크</p>
                                <p class="writtenBy">호담당 기사</p>
                                <p class="time">21.05.06 (목)</p>
                                <p class="eqPhoto"><span class="eqPhoto_none">-</span></p>
                                <a href="#" class="eqcardDetail" @click.prevent="showEquipmentManagementDetail">이력 상세보기</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <!-- //eqCardList -->
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class Equipment extends Mixins(VueHoduCommon) {

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEquipmentManagementDetailModalInfo ?: (params : hodu_home_modal_info.EquipmentManagementDetailModalInfo) => void;

    uid : string = "";

    equipment : any = null; 

    is_off_spec : boolean = false;
    
    image_view_seq : number = 0;

    default_image = require("@/assets/images/contents/hoduhome_car_camera.svg");

    beforeMount() : void {
        this.uid = this.$route.params.uid;

        if( this.uid == null || this.uid.trim().length < 1 ) {
            this.hodu_show_dialog('cancel', '장비 조회에 필요한 데이터가 부족합니다', ['확인'], [() => { this.movePrevPage(); }])
        }
    }

    mounted() : void {
        //제원 on/off
        // $('.eqinfor03_btn').off("click").on("click", function(){
        // 	if($(".eqinfor03_btn, .eqinfor03_box").hasClass("off")){
        //         $(".eqinfor03_btn, .eqinfor03_box").removeClass("off");
        //     } else {
        //         $(".eqinfor03_btn, .eqinfor03_box").addClass("off");
        //     }
        // });

        this.getHomeEquipment();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0);

        // @ts-ignore
        $('#equipment_detail').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * 장비 조회
     */
    async getHomeEquipment() : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${this.uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.equipment ) {
                throw new Error("장비 조회 중 오류 발생");
            }

            this.equipment = JSON.parse(JSON.stringify(response.data.data.equipment));
            this.$nextTick(() => this.setScroll());

        } catch(e) {
            this.hodu_show_dialog('cancel', "장비 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 장비 삭제
     */
    async deleteHomeEquipment() : Promise<void> {

        this.hodu_show_dialog('cancel', "장비를 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {

                try {

                    const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${this.uid}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("장비 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_show_dialog('cancel', "장비 삭제 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
                
            }
        ]);

    }

    /**
     * 이미지 슬라이드
     */
    @Throttle(100)
    imageSeqMove(num : number) {
        if( this.image_view_seq + num < 0 || this.image_view_seq + num >= this.equipment.equipment_data.images.length ) {
            return;
        }
        this.image_view_seq = this.image_view_seq + num;
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 장비 수정
     */
    moveEquipmentModify() : void {
        this.hodu_router_push(`/home/equipment/modify/${this.uid}`);
    }

    /**
     * 장비 관리 이력 생성
     */
    moveEquipmentManagementCreate() : void {
        this.hodu_router_push(`/home/equipment/${this.uid}/management/create`);
    }

    /**
     * 장비 관리 이력 모달
     */
    showEquipmentManagementDetail(seq) : void {
        this.doSetEquipmentManagementDetailModalInfo?.({ 
            show_modal : true, 
            uid : this.uid, 
            seq : seq,
            callback : this.getHomeEquipment 
        });
    }

    /**
     * QR라벨
     */
    qrcode() : void {

        if( this.equipment == null ) return;
        
        let query = ``;
        query += `group_id=${this.scope_group_id}`;
        query += `&uid=${this.uid}`;
        query += `&name=${encodeURIComponent(this.equipment.equipment_data.name)}`;

        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;

        const w = 491;
        const h = 420;
        const l = (width - w) / 2 / systemZoom + dualScreenLeft;
        const t = (height - h) / 2 / systemZoom + dualScreenTop;

        window.open(`/equipment_qr_label.html?${query}`, "equipment_qr_code", `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#equipment_detail').mCustomScrollbar("destroy");

        this.setScroll();
    }

}
</script>

<style scoped>
    #equipment { background: #fbfcfd }
    #equipment .title_box { background: #fff }

    #equipment .btn_img_prev { position: absolute; width : 40px; height : 40px; top : 50%; left : 20px; margin-top : -20px; background-color: #e1e4e6; font-size: 0; background-image : url('../../assets/images/contents/ic_img_prev.png'); border-radius: 50%; background-size : 25px; background-position : 6px center; background-repeat: no-repeat; }
    #equipment .btn_img_next { position: absolute; width : 40px; height : 40px; top : 50%; right : 20px; margin-top : -20px; background-color: #e1e4e6; font-size: 0; background-image : url('../../assets/images/contents/ic_img_next.png'); border-radius: 50%; background-size : 25px; background-position : 9px center; background-repeat: no-repeat; }
    #equipment .btn_img_prev:disabled, #equipment .btn_img_next:disabled { opacity: 0.7; cursor: default; }
</style>