<template>
    <div id="hospital_basic_setting_scroll">

        <div class="hospitalImgsDiv">

            <h4 class="title">병원 사진</h4>
            <div class="btnBoxPos fr">
                <input type="button" class="input_btn" value="초기화" @click="resetBasicSetting"/>
                <input type="button" class="input_btn" value="저장" @click="updateHospitalInfo"/>
            </div>
            <p>호두에서 검색시 사용하는 이미지를 저장합니다. * 4장 이하</p>
            <ul class="hospitalImgUl">
                
                <li :class="{ added : images[n - 1] != null }" :key="n" v-for="n in 4" :style="getBackgroundImageStyle(n - 1)">
                    <span class="num ">{{ n }}</span>
                    <input type="file" :id="`addHospImg${n}`" @change="addImage($event, (n - 1))" accept="image/*"/>
                    <label :for="`addHospImg${n}`">jpg, png, gif</label>
                    <input type="button" class="del" value="삭제" @click="removeImageFile(n - 1)"/>
                </li>
                
                <!-- 리스트에 background-image로 추가된 이미지가 있을 시 클래스 추가 added, label 색상 변경과 del 보임 -->
                <!-- <li class="added">
                    <span class="num ">1</span>
                    <input type="file" id="addHospImg1" />
                    <label for="addHospImg1">jpg, png, gif  5mb 이하</label>
                    <input type="button" class="del" value="삭제" />
                </li>
                <li class="added">
                    <span class="num ">2</span>
                    <input type="file" id="addHospImg1" />
                    <label for="addHospImg1">jpg, png, gif  5mb 이하</label>
                    <input type="button" class="del" value="삭제" />
                </li>
                <li>
                    <span class="num ">3</span>
                    <input type="file" id="addHospImg1" />
                    <label for="addHospImg1">jpg, png, gif  5mb 이하</label>
                    <input type="button" class="del" value="삭제" />
                </li>
                <li>
                    <span class="num ">4</span>
                    <input type="file" id="addHospImg1" />
                    <label for="addHospImg1">jpg, png, gif  5mb 이하</label>
                    <input type="button" class="del" value="삭제" />
                </li> -->
            </ul>

            <h4 class="title">병원 소개</h4>
            <textarea placeholder="병원 소개글을 작성해주세요" :value="descript" @input="descript = $event.target.value" />
            <!-- <textarea name="" id="" placeholder="병원 소개글을 작성해주세요">제일 잘 나가는 병원</textarea> -->

            <div class="otherDetailsDiv">
                <h4 class="title">기타</h4>
                <ul>
                    <li class="wd50 telList">
                        <label for="">전화번호</label>
                        <input type="text" placeholder="예) 02-443-7661" :value="phone" @input="phone = $event.target.value"/>
                        <!-- <input type="text" placeholder="예) 02-443-7661" value=""/> -->
                        <!-- <select name="" id="">
                            <option value="">02</option>
                            <option value="">010</option>
                        </select>
                        <span>-</span>
                        <input type="text" placeholder="" value="443"/>
                        <span>-</span>
                        <input type="text" placeholder="" value="7661"/> -->
                    </li>
                    <li class="wd50">
                        <label for="">웹사이트</label>
                        <input type="text" placeholder="예) www.*******.com" :value="web_url" @input="web_url = $event.target.value" />
                        <!-- <input type="text" placeholder="예) www.*******.com" value="www.gowelldoc.com"/> -->
                    </li>
                    <li>
                        <label for="">주소</label>
                        <!-- <select name="" id=""  title="시선택">
                            <option value="" title="서울특별시">서울특별시</option>
                            <option value="" title="부산광역시">부산광역시</option>
                            <option value="" title="대구광역시">대구광역시</option>
                            <option value="" title="인천광역시">인천광역시</option>
                            <option value="" title="광주광역시">광주광역시</option>
                            <option value="" title="대전광역시">대전광역시</option>
                            <option value="" title="울산광역시">울산광역시</option>
                            <option value="" title="세종특별자치시">세종특별자치시</option>
                            <option value="" title="경기도">경기도</option>
                            <option value="" title="강원도">강원도</option>
                            <option value="" title="충청북도">충청북도</option>
                            <option value="" title="충청남도">충청남도</option>
                            <option value="" title="전라북도">전라북도</option>
                            <option value="" title="전라남도">전라남도</option>
                            <option value="" title="경상북도">경상북도</option>
                            <option value="" title="경상남도">경상남도</option>
                            <option value="" title="제주특별자치도">제주특별자치도</option>
                        </select>

                        <select name="" id="" class="" title="시군구 선택">
                            <option value="" title="강남구">강남구</option>
                            <option value="" title="강동구">강동구</option>
                            <option value="" title="강북구">강북구</option>
                            <option value="" title="강서구">강서구</option>
                            <option value="" title="관악구">관악구</option>
                            <option value="" title="광진구">광진구</option>
                            <option value="" title="구로구">구로구</option>
                            <option value="" title="금천구">금천구</option>
                            <option value="" title="노원구">노원구</option>
                            <option value="" title="도봉구">도봉구</option>
                            <option value="" title="동대문구">동대문구</option>
                            <option value="" title="동작구">동작구</option>
                            <option value="" title="마포구">마포구</option>
                            <option value="" title="서대문구">서대문구</option>
                            <option value="" title="서초구">서초구</option>
                            <option value="" title="성동구">성동구</option>
                            <option value="" title="성북구">성북구</option>
                            <option value="" title="송파구">송파구</option>
                            <option value="" title="양천구">양천구</option>
                            <option value="" title="영등포구">영등포구</option>
                            <option value="" title="용산구">용산구</option>
                            <option value="" title="은평구">은평구</option>
                            <option value="" title="종로구">종로구</option>
                            <option value="" title="중구">중구</option>
                            <option value="" title="중랑구">중랑구</option>
                        </select> -->
                        <!-- 클릭 시 주소 찾기 나오기 -->
                        <input :disabled="true" type="text" class="addr1" :value="getLocationAddress()" @input="setLocationAddress($event)" />
                        <!-- <input v-if="false" :disabled="true" type="text" class="addr2" placeholder="예) 빌딩명 또는 호수" :value="getLocationRemainAddress()" @input="setLocationRemainAddress($event)"/> -->
                        <!-- <input type="text" class="addr1" placeholder="클릭해서 주소를 찾으세요" value="서울특별시 송파구 중대로 40길 20-3"/>
                        <input type="text" class="addr2" placeholder="예) 빌딩명 또는 호수" value="은하빌라 302호"/> -->
                        <!-- <input v-if="false" type="button" class="schBtn" @click="searchAddress"/> -->
                    </li>

                    <li class="setList">
                        <h4 class="title">설정</h4>
                        <div class="setAutoDiv">
                            <p class="title"><span class="dot"></span>예약 자동 승인</p>
                            <p class="desc">환자가 예약 요청을 했을때 자동으로 확정된 예약으로 등록이 되는 기능입니다</p>
                            <label class="switch"><input type="checkbox" v-model="auto_apply_appointment"><span class="slider round"></span></label>
                        </div>
                    </li>

                </ul>

            </div>
        </div>
        
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { ImageCropModalInfo } from '@/store/modules/ModalInfo';

import { t_event_file, t_event_location } from '@/model/event';
import { t_company_info } from '@/model/osm';

declare var daum: any;

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalBasicSetting extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;
    
    @Prop() event_bus !: Vue;

    images : t_event_file[] = [];
    image_files : any[] = [];
    descript : string = "";
    phone : string = "";
    web_url : string = "";
    auto_apply_appointment : boolean = false;

    location : t_event_location | null = null;

    async mounted() : Promise<void> {
        if( this.event_bus != null ) { this.event_bus.$on('basicSettingResize', this.handleResize); }
        
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        this.setDataByHospitalInfo();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();

        // @ts-ignore
        $('#hospital_basic_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0)
        });
    }

    /**
     * 병원 기본 정보 저장
     */
    async updateHospitalInfo() : Promise<void> {
        const vue = this;
        
        if( this.hospital_info == null ) { return; }

        /**
         * 이미지 파일이 있을때 => 이미지 임시파일 생성
         */
        console.log(this.image_files);
        if( this.image_files.length > 0 ) {
            
            const form_data : FormData = new FormData();
            
            // FormData 내용 생성
            const image_count : number = this.image_files.length;
            for( let i = 0; i < image_count; i++ ) {
                form_data.append('file', this.image_files[i].file);
            }

            await this.hodu_temp_upload(form_data)
                .then(async(response) => {
                    console.log(response);
                    const temp_files : t_event_file[] = response.data.data.temp_files;

                    // 임시파일 데이터로 객체 대체하기
                    const image_count : number = this.image_files.length;
                    for( let i = 0; i < image_count; i++ ) {
                        vue.images.splice(this.image_files[i].index, 1, temp_files[i]);    
                    }

                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        const body : t_company_info = JSON.parse(JSON.stringify(this.hospital_info.company_info));

        body.images = this.images;
        body.descript = this.descript;
        body.contacts = {
            name : "본원",
            tel : this.phone
        }
        body.web_url = this.web_url;
        body.location = this.location ? this.location : body.location;
        body.auto_apply_appointment = this.auto_apply_appointment;

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}`, API_METHOD.PUT, body)
            .then((response) => {
                this.hodu_show_dialog('success', '병원 기본 설정 저장 완료', ['확인'], [async() => {
                    await this.get_hodu_d_info(this.scope_group_team_option.biz_id);
                    this.setDataByHospitalInfo();
                }]);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })

    }

    /**
     * hospital_info 에서 데이터를 꺼내서 사용
     */
    setDataByHospitalInfo() : void {
        if( this.hospital_info == null ) { return; }
        
        this.images.splice(0, this.image_files.length);

        // 이미지 정보가 존재한다면
        if( this.hospital_info.company_info.images != null ) {
            this.images.splice(0, this.images.length);
            for( const image of this.hospital_info.company_info.images ) {
                this.images.push(JSON.parse(JSON.stringify(image)));
            }
        }

        // 병원 기본 정보들이 존재한다면 적용
        this.descript = this.hospital_info.company_info.descript ? this.hospital_info.company_info.descript : '';
        this.phone    = this.hospital_info.company_info.contacts 
                            ? this.hospital_info.company_info.contacts.tel 
                                ? this.hospital_info.company_info.contacts.tel
                                :  ''
                            : '';
        this.web_url  = this.hospital_info.company_info.web_url ? this.hospital_info.company_info.web_url : ''; 
        this.location = this.hospital_info.company_info.location ? this.hospital_info.company_info.location : {
            place : "",
            tel : "",
            post_code : "",
            address : "",
            address_old : "",
            geo : {
                longitude : "",
                latitude : "",
            },
        };
        this.auto_apply_appointment = this.hospital_info.company_info.auto_apply_appointment ? this.hospital_info.company_info.auto_apply_appointment : false;
        
    }

    /**
     * 기본 설정 초기화
     */
    resetBasicSetting() : void {
        this.images.splice(0, this.image_files.length);
        this.images.splice(0, this.images.length);
        this.descript = "";
        this.phone = "";
        this.web_url = "";
        this.location = {
            place : "",
            tel : "",
            post_code : "",
            address : "",
            address_old : "",
            geo : {
                longitude : "",
                latitude : "",
            },
        }
        this.auto_apply_appointment = false;
    }

    /**
     * 이미지 파일 추가
     */
    async addImage(event : any, index : number) : Promise<void> {
        const vue = this;
        const files : File[] = event.target.files;

        // 이미지 파일 추가 (파일 1개만 들어옴)
        await this.hodu_show_indicator();
        await this.fileReaderPromise(files[0])
            .then(async(pe_fr : any) => {

                // onloadend로 들어온 경우
                if( pe_fr == null || this.doSetImageCropModalInfo == null ) {
                    $(event.target).val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (4/3), // 4:3
                    image_url : url,
                    cancel_function : async() => {
                        $(event.target).val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        
                        await this.hodu_show_indicator();

                        const resize_blob : Blob = await vue.hodu_image_resize(cropped_url);
                        
                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = files[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, files[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = files[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }
                        
                        await this.hodu_hide_indicator();

                        // 이미 존재하는 이미지 교체
                        if( vue.images[index] != null ) {
                            
                            vue.images.splice(index, 1, {
                                name: resize_file.name,                // 원본 파일명
                                mimeType: resize_file.type,            // MIME TYPE
                                url: URL.createObjectURL(resize_blob), // 파일 경로
                                size: resize_file.size,                // 파일 크기
                                date: new Date()                       // 저장 날짜
                            });

                            // 이번에 새로추가하고 있었던 파일이라면
                            const exist_file = vue.image_files.filter(item => item.index == index);
                            if( exist_file.length > 0 ) {
                                vue.image_files.splice(vue.image_files.indexOf(exist_file[0]), 1, {
                                    index : index, 
                                    file : resize_file
                                });
                            }

                            // 해당 번호에 새로운 파일이 없었다면 업로드용 파일 객체 담기
                            else {
                                
                                vue.image_files.push({
                                    index : index, 
                                    file : resize_file
                                });
                            }
                            
                        }

                        // 새로운 이미지 추가
                        else {
                            vue.images.push({
                                name: resize_file.name,                // 원본 파일명
                                mimeType: resize_file.type,            // MIME TYPE
                                url: URL.createObjectURL(resize_blob), // 파일 경로
                                size: resize_file.size,                // 파일 크기
                                date: new Date()                       // 저장 날짜
                            });

                            // 업로드용 파일 객체 담기
                            vue.image_files.push({
                                index : ( vue.images.length - 1 ), 
                                file : resize_file
                            });
                        }

                        $(event.target).val("");

                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 파일 
     */
    removeImageFile(index : number) : void {
        if( this.images[index] == null ) {
            return;
        }

        this.images.splice(index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const image_count : number = this.image_files.length;
        for( let i = (image_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.image_files[i].index > index ) {
                this.image_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.image_files[i].index == index ) {
                this.image_files.splice(i, 1);
                break;
            }
        }
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지가 존재하는 경우 background-image : url(app_images/${this.images[index].url}) 를 반환한다
     */
    getBackgroundImageStyle(index : number) : string {
        if( this.images[index] == null ) { return 'background-image : none;' }
        return `background-image : url(${ this.images[index].url.indexOf('profile') > -1 ? 'app_images/' : '' }${this.images[index].url})`;
    }

    /**
     * 장소 주소 가져오기
     */
    getLocationAddress() : string {
        if( this.location == null ) {
            return "";
        }

        let address = ""; 

        // 도로명 주소가 유효한 경우
        if( this.location.address != null && this.location.address.length > 0 ) {
            address = this.location.address;
        }

        // 지번 주소가 유효한 경우
        else if( this.location.address_old != null && this.location.address_old.length > 0 ) {
            address = this.location.address_old;
        }

        return address;
    }

    /**
     * 장소 주소 입력
     */
    setLocationAddress(event : any) : void {
        if( this.location == null ) { return; }
        
        // 도로명 주소가 유효한 경우
        if( this.location.address != null && this.location.address.length > 0 ) {
             this.location.address = event.target.value.trim();
        }

        // 지번 주소가 유효한 경우
        else if( this.location.address_old != null && this.location.address_old.length > 0 ) {
            this.location.address_old = event.target.value.trim();
        }

        // 둘다 유효하지 않은 경우
        else {
            this.location.address = event.target.value.trim();
            this.location.address_old = event.target.value.trim();
        }

    }

    /**
     * 장소 나머지 주소 가져오기
     */
    // getLocationRemainAddress() : string {
    //     if( this.location == null ) {
    //         return "";
    //     }

    //     return this.location.remain_address ? this.location.remain_address : '';
    // }

    /**
     * 장소 나머지 주소 입력
     */
    // setLocationRemainAddress(event : any) : void {
    //     if( this.location == null ) { return; }
        
    //     this.location.remain_address = event.target.value;
    // }

    /**
     * 주소 검색
     */
    searchAddress() : void {
        
        const width : number = 500;
        const height : number = 600;

        new daum.Postcode({
            width : width,
            height : height,
            oncomplete: async(data) => {
                // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
                // 예제를 참고하여 다양한 활용법을 확인해 보세요.
                // https://spi.maps.daum.net/postcode/guidessl
                console.log(data);

                // location이 null인 경우
                if( this.location == null ) {
                    this.location = {
                        place : "",
                        tel : "",
                        post_code : "",
                        address : "",
                        address_old : "",
                        geo : {
                            longitude : "",
                            latitude : "",
                        },
                    };
                }

                // 지번주소, 도로명주소
                // const building_name      = data.buildingName;
                const user_selected_type = data.userSelectedType;
                const address            = data.jibunAddress;
                const road_address       = data.roadAddress;

                // 건물명
                // if( building_name != null && building_name.length > 0 ) {
                //     this.location.place = building_name;
                // }

                // 지번 주소를 클릭한 경우
                if( user_selected_type == 'J' && address != null && address.length > 0 ) {
                    this.location.address_old = address;
                    this.location.address = address;  
                }

                // 도로명 주소를 클릭한 경우
                if( user_selected_type == 'R' && road_address != null && road_address.length > 0 ) {
                    this.location.address_old = road_address;
                    this.location.address = road_address;  
                }
                
                // 나온 정보로 검색
                await this.hodu_api_call(`api/v1/location?query=${this.location.address + ' ' + this.scope_group_team_option.group_team_name}&page=${1}&size=15&category_group_code=HP8`, API_METHOD.GET)
                    .then(async(response) => {
                        
                        console.log(response);

                        // 검색결과가 없는 경우
                        if( response.data.data.documents.length < 1 ) {
                            this.hodu_show_dialog('cancel', '장소 정보를 찾는데 문제가 발생 했습니다\n관리자에게 문의 해주세요', ['확인']);
                            return;
                        }

                        // 정보 세팅
                        if( this.location != null && this.location.geo != null ) {
                            this.location.geo.latitude  = response.data.data.documents[0].y;
                            this.location.geo.longitude = response.data.data.documents[0].x;
                        }

                        $('.addr2').focus();

                    })
                    .catch(async(e) => {
                        this.hodu_error_process(e, false, false, true);
                    });

            }
        }).open({ 
            q : this.getLocationAddress(),
            left: (window.screen.width / 2) - (width / 2),
            top: (window.screen.height / 2) - (height / 2)
        });

    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#hospital_basic_setting_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
</script>

<style scoped>

    .title {font-size: 16px;font-weight: bold;display: block;margin-bottom: 20px;margin-top: 0;}

    .btnBoxPos { position: absolute;right:0; top:-10px; }
    .btnBoxPos input { margin-left: 20px; }

    .hospitalImgsDiv {position:relative; box-sizing: border-box; max-width: 970px; padding: 30px 30px 0 30px; margin-bottom:100px }
    .hospitalImgsDiv .btnBoxPos { top:20px; }
    .hospitalImgsDiv p { font-size: 14px;opacity: 0.7; }

    .hospitalImgsDiv .num { z-index:20; position:absolute; left:7px; top:7px; width: 30px;text-align: center; height:17px;; line-height:17px;; background:#f1f3f5; border-radius:2px; background:rgba(43, 75, 95, 0.3);color:#fff; font-weight:bold; }
    
    .hospitalImgUl { margin-bottom:30px; }
    .hospitalImgUl li.added label { opacity:0; background:#2b3b5f; font-size:0; }
    .hospitalImgUl li.added:hover .del { display:block; }
    .hospitalImgUl li.added label:hover { opacity:0.2; border: none; }
    .hospitalImgUl li { position: relative; display: inline-block;margin-top: 20px; margin-right: 25px; background-size: cover !important; background-position: center center !important;width: 200px; height:153px; border-radius: 5px; border:1px solid #f1f3f5; }
    .hospitalImgUl input[type="file"] { display:none }
    .hospitalImgUl .del { display:none; z-index: 20; position:absolute; right:-10px; top:-10px; border-radius: 50%; width: 30px; height: 30px; background:#ff6060; background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; font-size: 0;  }
    .hospitalImgUl .del:hover { background-color: #fd1818 }
    .hospitalImgUl label { transition:0.2s; cursor:pointer; z-index: 10; position: absolute; cursor: pointer; line-height: 153px; font-weight:bold; color:#477fff; text-align:center; border: 2px dashed #c6d7ff; background-color: #f4f7ff; width: 100%; height:100%; box-sizing: border-box; display:block;border-radius: 5px; }
    .hospitalImgUl label:hover { border-color:#477fff; background:#fff; }

    textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 150px; height:auto; box-sizing: border-box; font-weight:bold; }
    textarea:hover { border-color:#477fff }
    textarea:focus { background:#fff;  }


    .otherDetailsDiv { margin-top:40px; margin-bottom: 35px; }
    .otherDetailsDiv label { font-size: 13px; padding-bottom:10px; display: block; }
    .otherDetailsDiv li {width:100%;float: left; position: relative; margin-bottom: 25px; }
    .otherDetailsDiv ul { overflow: hidden; }
    .otherDetailsDiv li:first-child.wd50 { padding-right: 30px;box-sizing: border-box; }
    .otherDetailsDiv li.wd50 { width:50%;  }
    .otherDetailsDiv li.wd50.telList select { float:left; cursor: pointer; border: 1px solid #e7e9ea; line-height: 45px; height: 45px; border-radius: 5px;box-sizing: border-box; padding: 0 30px 0 15px; appearance: none; background:  url('../../assets/images/side_area/bt_open.png') no-repeat 91% center; }
    .otherDetailsDiv li.wd50.telList select:hover { background-color:#f1f3f5; }
    .otherDetailsDiv li.wd50.telList select:focus { background-color:#fff; border-color:#477fff;background-image: url('../../assets/images/side_area/bt_close.png') }
    .otherDetailsDiv li.wd50.telList span { display: inline-block; float: left; height: 45px; line-height: 45px; width: 4.33333333333%; text-align: center; }
    
    /* 전화번호 한번에 받도록 변경 */
    /* .otherDetailsDiv li.wd50.telList input[type="text"], .otherDetailsDiv li.wd50.telList select { width: 30%; float: left; } */
    .otherDetailsDiv li.wd50.telList input[type="text"], .otherDetailsDiv li.wd50.telList select { width: 100%; float: left; }

    .otherDetailsDiv input[type="text"] { font-weight: bold;; transition:0.2s; width:100%; height: 45px; line-height: 45px; box-sizing: border-box; padding: 0 15px; border:1px solid #e7e9ea; background: #fff; }
    .otherDetailsDiv input[type="text"]:hover { background:#f1f3f5 }
    .otherDetailsDiv input[type="text"]:focus { border-color:#477fff; background:#fff; }
    .otherDetailsDiv input[type="text"]:disabled { color : #232848; }
    .otherDetailsDiv .schBtn { position: absolute; right:10px;top:30px; } 
    .otherDetailsDiv .schBtn:hover {background: url(../../assets/images/contents/ic_search_on.png) no-repeat center center;}

    .addr2 { margin-top:10px; }

    .setList { margin-top:30px; }
    .setAutoDiv { position: relative; }
    .setAutoDiv .title { opacity: 1; margin-bottom: 15px;}
    .setAutoDiv .title .dot { display: inline-block; width: 5px; height: 5px; border-radius: 50%;float: left; background:#000; margin-top:5px; margin-right: 10px; }
    .setAutoDiv .desc { padding-left: 15px; box-sizing: border-box; }
    .setAutoDiv .switch { position: absolute; right:0; top:-5px; margin-top:0; padding-bottom:0; }

    /* 예제 추후삭제 */
    /* .hospitalImgUl li:nth-child(1) { background-image: url('../../assets/images/footer/hopi2.jpg'); }
    .hospitalImgUl li:nth-child(2) { background-image: url('../../assets/images/footer/hopi1.jpg'); } */

</style>