<template>
    <div class="approval_content up1">
        <div class="filter_tap" id="dep4">

            <div class="content_top filter1">
                <p class="appLocation appDraftLc">
                    상신함
                </p>
                <a href="#" class="bt_hambuger" :class="{ change : is_open_filter == true }" @click.prevent="toggleFilter"></a>
                <h3>검색 옵션</h3>
                <input type="text" id="filter_search" placeholder="검색할 내용을 입력해주세요" :value="filter.search_query" @input="inputSearchQuery($event.target.value)"/>
            </div>

            <div class="nav_item" :class="{ open : is_open_filter == true }" style="position: relative;">

                <div style="width : 100%; height:109px; top: 0; left: 267px; right:0;">
                    <ul>
                        <li class="Search_li01">검색일</li>
                        <li class="Search_li02">
                            <!-- <span style="margin-right:10px;">2020.09.01 목</span> ~ <span style="margin-left:10px;">2020.09.07 월</span> -->
                            <input type="button" id="approval_draft_filter_start" class="hodu-datepicker-button" :value="start_text" style="margin-right:10px;" /> ~ <input type="button" id="approval_draft_filter_end" class="hodu-datepicker-button" style="margin-left:10px;" :value="end_text" />
                        </li>
                        <a href="#" class="restart_button" @click.prevent="reset">초기화</a>
                    </ul>
                    <ul>
                        <li class="Search_li01">중요도</li>
                        <li class="Search_li02">

                            <!-- 당장은 전체를 삭제 나중에 필요시 추가 -->
                            <!-- <p style="display: inline-block;margin-right:34px;">
                                <input type="checkbox" id="search_check01" value="" class="input_check0">
                                <label for="search_check01" style="margin: 8px 12px 10px 0px;"></label>
                                <span>전체</span>
                            </p> -->

                            <p style="display: inline-block;margin-right:30px;">
                                <input type="checkbox" id="search_check02" value="" class="input_check0" v-model="filter.is_emergency" @change="changeImportanceFilter">
                                <label for="search_check02" style="margin: 8px 12px 10px 0px;"></label>
                                <span>긴급</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;">
                                <input type="checkbox" id="search_check03" value="" class="input_check0" v-model="filter.is_importance" @change="changeImportanceFilter">
                                <label for="search_check03" style="margin: 8px 12px 10px 0px;"></label>
                                <span>중요</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;">
                                <input type="checkbox" id="search_check04" value="" class="input_check0" v-model="filter.is_normal" @change="changeImportanceFilter">
                                <label for="search_check04" style="margin: 8px 12px 10px 0px;"></label>
                                <span>일반</span>
                            </p>
                        </li>
                    </ul>
                    <ul>
                        <li class="Search_li01">문서검색</li>
                        <li class="Search_li02">
                            <p style="display: inline-block;margin-right:30px;">
                                <input type="checkbox" id="search_check05" value="" class="input_check0" v-model="filter.is_vacation" @change="changeTypeFilter">
                                <label for="search_check05" style="margin: 8px 12px 10px 0px;"></label>
                                <span>휴가</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check06" value="" class="input_check0" v-model="filter.is_businesstrip" @change="changeTypeFilter">
                                <label for="search_check06" style="margin: 8px 12px 10px 0px;"></label>
                                <span>출장</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check10" value="" class="input_check0" v-model="filter.is_holiday_work" @change="changeTypeFilter">
                                <label for="search_check10" style="margin: 8px 12px 10px 0px;"></label>
                                <span>휴일근무</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check07" value="" class="input_check0" v-model="filter.is_cash_disbursement_voucher" @change="changeTypeFilter">
                                <label for="search_check07" style="margin: 8px 12px 10px 0px;"></label>
                                <span>지출결의서</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check08" value="" class="input_check0" v-model="filter.is_free" @change="changeTypeFilter">
                                <label for="search_check08" style="margin: 8px 12px 10px 0px;"></label>
                                <span>일반 기안서</span>
                            </p>
                            <p style="display: inline-block;margin-right:30px;" v-if="isAttendanceOnly() == false">
                                <input type="checkbox" id="search_check09" value="" class="input_check0" v-model="filter.is_approval" @change="changeTypeFilter">
                                <label for="search_check09" style="margin: 8px 12px 10px 0px;"></label>
                                <span>사용자 지정 문서</span>
                            </p>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>

        <div class="content" style="width: 100%;">
            <div id="noticeScroll" class="section_scroll">
                <div class="viewGroup">
                    <div class="schedule_box"></div>
                    
                    <div class="fixedDiv">
                        <ul class="sortHeader groupNoticeHeader">
                            <!-- <li class="notiCheck" style="padding-left: 20px;box-sizing: border-box;">
                                <p id="selectCheckBoxAll" class="checkBox on">
                                    <input type="checkbox" id="selectAll">
                                    <label for="selectAll">전체선택</label>
                                </p>
                            </li> -->
                            <li class="num">No</li>

                            <li class="title headerSort" :class="{
                                sortOff  : sort_type != 'TITLE',
                                sortUp   : sort_type == 'TITLE' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'TITLE' && sort_direction == 'DESC', 
                            }" @click.prevent="sort(approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.TITLE)">
                                <span style="margin-left:0px;">제목</span>
                            </li>

                            <li class="group headerSort" :class="{
                                sortOff  : sort_type != 'TIME',
                                sortUp   : sort_type == 'TIME' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'TIME' && sort_direction == 'DESC', 
                            }" @click.prevent="sort(approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.TIME)">
                                <span style="margin-left:0;">기안일</span>
                            </li>

                            <li class="writtenBy"><span style="margin-left:26px;">기한</span><!-- <span class="chk">클릭</span> --></li>

                            <li class="status headerSort" :class="{
                                sortOff  : sort_type != 'STATUS',
                                sortUp   : sort_type == 'STATUS' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'STATUS' && sort_direction == 'DESC', 
                            }" @click.prevent="sort(approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.STATUS)">
                                <span style="margin-left:13px;">현황</span>
                            </li>

                        </ul>
                    </div>

                    <div id="approval_list" class="content">
                        <ul class="notiListUl">

                            <li class="Send_up" :key="`${approval.approval_uid}-${approval.approval_rev}`" v-for="(approval, index) in computedApprovalDraftList" @click="moveApprovalDetail(approval.approval_uid)">
                                <p class="num"><span >{{ index + 1 }}</span></p>
                                <p class="title">
                                    <span :class="{ quick : approval.imp_seq == 10, important : approval.imp_seq == 20 }" v-if="approval.imp_seq < 30">{{ getImportanceText(approval.imp_seq) }}</span>
                                    <span>{{ approval.contents.title }}</span>
                                </p>
                                <p class="group">
                                    <span>
                                        {{ hodu_date_to_format_string(approval.audit_created, 'YY.MM.DD') + " (" + getDayOfWeekByDate(approval.audit_created) + ") " + amPmStringToLocaleAmPmString(hodu_date_to_format_string(approval.audit_created, 'hh:mm a')) }}
                                    </span>
                                </p>
                                <p class="writtenBy"><span>D-{{ getDDay(approval.audit_created) }}</span></p>
                                <p class="status">
                                    <ul class="status_info">
                                        <li class="list_Progress">
                                            <span class="Progress_bar">진행현황</span>
                                            <span class="Progress_ing" :style="{ 
                                                width : `${getProgress(approval)}%`,
                                                backgroundColor : getProgressColor(approval.approval_state) 
                                            }">진행현황</span>
                                        </li>
                                        <li class="list_Protext">
                                            <span style="margin-left:5px;">{{ getStateText(approval.approval_state) }}</span>
                                            <span class="ing_num">{{ approval.approval_cur }} / {{ approval.approval_total }}</span>
                                        </li>
                                    </ul>
                                </p>
                            </li>

                            <!-- <li class="Send_up">

                                <p class="notiCheck" style="padding-left: 20px;">
                                    <input type="checkbox" id="notiCheck01" value="" class="input_check">
                                    <label for="notiCheck01"></label>
                                </p>

                                <p class="num"><span >1</span></p>

                                <p class="title">
                                    <span>진행 예제</span>
                                </p>
                                <p class="group">
                                    <span>20.09.05 11:00am</span>
                                </p>
                                <p class="writtenBy"><span>D-2</span></p>
                                <p class="status">
                                    <ul class="status_info">
                                        <li class="list_Progress">
                                            <span class="Progress_bar">진행현황</span>
                                            <span class="Progress_ing" style="background: #40c8a0;width:30%;">진행현황</span>
                                        </li>
                                        <li class="list_Protext">
                                            <span style="margin-left:5px;">진행</span>
                                            <span class="ing_num">1 / 3</span>
                                        </li>
                                    </ul>
                                </p>
                            </li>

                            <li class="Send_up">
                                
                                <p class="notiCheck" style="padding-left: 20px;">
                                    <input type="checkbox" id="notiCheck01" value="" class="input_check">
                                    <label for="notiCheck01"></label>
                                </p>

                                <p class="num"><span>2</span></p>
                                <p class="title">
                                    <span class="quick">긴급</span><span>반려 예제</span>
                                </p>
                                <p class="group">
                                    <span>20.09.05 11:00am</span>
                                </p>
                                <p class="writtenBy"><span>D-2</span></p>
                                <p class="status">
                                    <ul class="status_info">
                                        <li class="list_Progress">
                                            <span class="Progress_bar">진행현황</span>
                                            <span class="Progress_ing" style="background: #ff5872;width:60%;">진행현황</span>
                                        </li>
                                        <li class="list_Protext">
                                            <span style="margin-left:5px;">반려</span>
                                            <span class="ing_num">2 / 3</span>
                                        </li>
                                    </ul>
                                </p>
                            </li>

                            <li class="Send_up">
                                
                                <p class="notiCheck" style="padding-left: 20px;">
                                    <input type="checkbox" id="notiCheck01" value="" class="input_check">
                                    <label for="notiCheck01"></label>
                                </p>

                                <p class="num"><span>3</span></p>
                                <p class="title">
                                    <span>완료 예제</span>
                                </p>
                                <p class="group">
                                    <span>20.09.05 11:00am</span>
                                </p>
                                <p class="writtenBy"><span>D-2</span></p>
                                <p class="status">
                                    <ul class="status_info">
                                        <li class="list_Progress">
                                            <span class="Progress_bar">진행현황</span>
                                            <span class="Progress_ing" style="background: #477fff;width:100%;">진행현황</span>
                                        </li>
                                        <li class="list_Protext">
                                            <span >완료</span>
                                            <span class="ing_num">2 / 2</span>
                                        </li>
                                    </ul>
                                </p>
                            </li> -->

                        </ul>
                    </div> 
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ApprovalInfo = namespace('ApprovalInfo');

import { approval_enum, approval_interface } from '@/model/approval';

import moment from 'moment';
import { hodu_color } from '@/common/color';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ApprovalDraftBox extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    @Prop() event_bus !: Vue;
    @Prop() tab       !: approval_enum.APPROVAL_DRAFT_BOX_TAB;
    @Prop() filter    !: approval_interface.ApprovalFilter;
    @Prop() approvals !: any[];

    /**
     * 상신함 리스트
     */
    get computedApprovalDraftList() : any[] {

        let apporval_list : any[] = this.approvals;
        
        // 탭 선택시 필터링
        if( this.tab != approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL ) {
            apporval_list = apporval_list.filter(item => item.approval_state == this.tab);
        }
        
        // 정렬
        apporval_list.sort((o1, o2) : number => {

            if( this.sort_type == approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.TITLE ) {
                const o1_title = o1.contents.title;
                const o2_title = o2.contents.title;

                if( o1_title == o2_title ) return 0;

                if( this.sort_direction == SORT_TYPE.ASC ) {
                    return o1_title > o2_title ? 1 : -1;
                }
                return o1_title > o2_title ? -1 : 1;
            }

            else if( this.sort_type == approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.TIME ) {
                const o1_time = o1.audit_created;
                const o2_time = o2.audit_created;

                if( o1_time == o2_time ) return 0;

                if( this.sort_direction == SORT_TYPE.ASC ) {
                    return o1_time > o2_time ? 1 : -1;
                }
                return o1_time > o2_time ? -1 : 1;
            }

            else if( this.sort_type == approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.STATUS ) {

                const o1_progress = o1.approval_cur;
                const o2_progress = o2.approval_cur;

                if( o1_progress == o2_progress ) return 0;

                if( this.sort_direction == SORT_TYPE.ASC ) {
                    return o1_progress > o2_progress ? 1 : -1;
                }
                return o1_progress > o2_progress ? -1 : 1;
            }

            return 0;
        });

        if( this.filter.search_query != null && this.filter.search_query.trim().length > 0 ) {
            apporval_list = apporval_list.filter(approval => this.hodu_string_includes(approval.contents.title, this.filter.search_query) ||
                                                             this.hodu_string_includes(approval.contents.comment, this.filter.search_query) );
        }

        return apporval_list; 
    }

    /**
     * @ApprovalInfo.State
     */
    @ApprovalInfo.State draft_filter !: approval_interface.ApprovalFilter;
    @ApprovalInfo.State receive_filter !: approval_interface.ApprovalFilter;

    /**
     * @ApprovalInfo.Action
     */
    @ApprovalInfo.Action doSetDraftFilter ?: (params : approval_interface.ApprovalFilter) => void;

    is_open_filter : boolean = true;

    start_text = "";
    end_text = "";

    // 정렬
    sort_type : approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE = approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE.TIME;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    mounted() : void {
        this.event_bus?.$on("draftResize", this.handleResize);

        this.makeDateText();
        this.datepickerInit();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            const title_height = $('.title_box').outerHeight();
            const filter_height = $('.approval_content .filter_tap').outerHeight();
            const sort_header_height = $('.content .sortHeader').outerHeight();
            const list_height = window.innerHeight - (title_height ? title_height : 0)
                                                   - (filter_height ? filter_height : 0)
                                                   - (sort_header_height ? sort_header_height : 0);

            // @ts-ignore
            $('#approval_list').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : list_height,
            });
        });
    }

    /**
     * 전자결재 상신함 문서 조회
     */
    async getApporval() : Promise<void> {
        console.log("ApprovalDraftBox : getApporval");
        this.$emit("getApproval");
    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: async(dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(selected_date)}`);
                    
                    const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));
                    filter.start = new Date(filter.start);
                    filter.end = new Date(filter.end);

                    // 시작일 조정
                    if( inst.id == 'approval_draft_filter_start' ) {
                        filter.start = moment(selected_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();

                        if( filter.start.getTime() > filter.end.getTime() ) {
                            filter.end = moment(filter.start).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
                        }
                    }

                    // 종료일 조정
                    else if( inst.id == 'approval_draft_filter_end' ) {
                        filter.end = moment(selected_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

                        if( filter.start.getTime() > filter.end.getTime() ) {
                            filter.start = moment(filter.end).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                        }
                    }

                    await this.setFilter(filter);
                    await this.makeDateText();

                    // 재조회
                    await this.getApporval();
                },
            }

            // @ts-ignore
            $('#approval_draft_filter_start').datepicker(datepicker_option);

            // @ts-ignore
            $('#approval_draft_filter_end').datepicker(datepicker_option);
        });
    }

    /**
     * date 텍스트 만들기
     */
    makeDateText() : void {
        this.start_text = `${this.hodu_date_to_format_string(this.filter.start, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.filter.start)}`;
        this.end_text   = `${this.hodu_date_to_format_string(this.filter.end, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.filter.end)}`; 
    }

    /**
     * 검색
     */
    async inputSearchQuery(value) : Promise<void> {
        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));
        filter.search_query = value;
        await this.setFilter(filter);
    }

    /**
     * 리셋
     */
    async reset() : Promise<void> {

        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));

        filter.end   = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        filter.start = moment(filter.end).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).add('month', -1).toDate();

        filter.is_emergency = true;
        filter.is_importance = true;
        filter.is_normal = true;
        filter.importance_filter.splice(0, filter.importance_filter.length);
        if( filter.is_emergency == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.EMERGENCY);
        if( filter.is_importance == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.IMPORTANCE);
        if( filter.is_normal == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL);

        filter.is_approval = true;
        filter.is_vacation = true;
        filter.is_businesstrip = true;
        filter.is_cash_disbursement_voucher = true;
        filter.is_free = true;
        filter.is_holiday_work = true;

        await this.setFilter(filter);

        await this.makeDateText();

        // 재조회
        await this.getApporval();
    }

    /**
     * 중요도 설정
     */
    async changeImportanceFilter(event : any) : Promise<void> {

        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));

        // 전부다 취소 됐을때 필터 true로 다시 고정
        if( filter.is_emergency == false && filter.is_importance == false && filter.is_normal == false ) {
            this.$nextTick(async() => {
                // alert(event.target.id);
                switch( event.target.id ) {
                    case 'search_check02':
                        console.log("is_emergency");
                        filter.is_emergency = true;
                        break;

                    case 'search_check03':
                        console.log("is_importance");
                        filter.is_importance = true;
                        break;

                    case 'search_check04':
                        console.log("is_normal");
                        filter.is_normal = true;
                        break;
                }
                $(event.target).prop("checked", true);
                await this.setFilter(filter);
            });
            return;
        }
        
        // 필터 데이터 가공
        filter.importance_filter.splice(0, filter.importance_filter.length);
        if( filter.is_emergency == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.EMERGENCY);
        if( filter.is_importance == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.IMPORTANCE);
        if( filter.is_normal == true ) filter.importance_filter.push(approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL);

        await this.setFilter(filter);

        // 재조회
        await this.getApporval();
    }

    /**
     * 문서 타입 설정
     */
    async changeTypeFilter(event : any) : Promise<void> {
        const filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.filter));
        await this.setFilter(filter);
        await this.getApporval();
    }

    /**
     * 필터 토글
     */
    toggleFilter() : void {
        this.is_open_filter = !this.is_open_filter;
        this.handleResize();
    }

    /**
     * 중요도 관련 텍스트 반환
     */
    getImportanceText(imp_seq : number) : string {
        switch(imp_seq) {
            case 10: return "긴급";
            case 20: return "중요";
        }

        return "";
    }

    /**
     * dday 구하기
     */
    getDDay(audit_created : string | Date) : number {
        audit_created = audit_created instanceof Date ? audit_created : moment(audit_created).toDate();
        const today = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        const diff : number = this.getDateDiff(today, audit_created);
        return diff;
    } 

    /**
     * progress 진행율 반환
     */
    getProgress(approval) : string {
        const progress = (100 * (approval.approval_cur) / (approval.approval_total)).toFixed(10);
        return Number(progress) == 0 ? '5' : progress;
    }

    // /**
    //  * 완료된 (COMPLETE 또는 REJECT) 수 반환
    //  */
    // getCompleteProgress(approvers : any) : number {
    //     let progress = 0;
    //     for( const approver of approvers ) {
    //         if( approver.state == approval_enum.APPROVAL_APPROVER_STATE.CONFIRM || approver.state == approval_enum.APPROVAL_APPROVER_STATE.REJECT ) progress++;
    //     }
    //     return progress;
    // }

    /**
     * 현재 문서의 상태에 따른 색상 반환
     */
    getProgressColor(approval_state : string) : string {
        switch(approval_state) {
            case approval_enum.APPROVAL_STATE.CANCEL   : return hodu_color.approval_cancel_color;
            case approval_enum.APPROVAL_STATE.ING      : return hodu_color.approval_ing_color;
            case approval_enum.APPROVAL_STATE.REJECT   : return hodu_color.approval_reject_color;
            case approval_enum.APPROVAL_STATE.COMPLETE : return hodu_color.approval_confirm_color;
            case approval_enum.APPROVAL_STATE.TEMP     : return hodu_color.approval_temp_color;
        }

        return "transparent";
    }

    /**
     * 현재 문서의 상태에 따른 텍스트 반환
     */
    getStateText(approval_state : string) : string {
        switch(approval_state) {
            case approval_enum.APPROVAL_STATE.CANCEL   : return "취소";
            case approval_enum.APPROVAL_STATE.ING      : return "진행";
            case approval_enum.APPROVAL_STATE.REJECT   : return "반려";
            case approval_enum.APPROVAL_STATE.COMPLETE : return "완료";
            case approval_enum.APPROVAL_STATE.TEMP     : return "임시";
        }
        return "";
    }

    /**
     * 정렬
     */
    sort(sort_type : approval_enum.APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 상세화면으로 이동
     */
    moveApprovalDetail(approval_uid : string) : void {
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/${approval_uid}`);
    }

    /**
     * 근태관리 기능만 ON 인 상태
     */
    isAttendanceOnly() : boolean {
        return (this.isEnableGroupFeature(this.scope_group_id, 'approval') == false && this.isEnableGroupFeature(this.scope_group_id, 'attendance') == true);
    }

    async setFilter(filter) {

        await this.doSetDraftFilter?.(filter);

        const approval_filter_string = hodu_local_storage.getItem("approval_filter");

        if( approval_filter_string != null && approval_filter_string.length < 1 ) {
            const approval_filter = JSON.parse(approval_filter_string);
            const approval_group_filter = approval_filter[`${this.user_id}_${this.scope_group_id}`];

            if( approval_group_filter != null ) {
                approval_group_filter.receive_filter = filter;
            }
            else {
                approval_filter[`${this.user_id}_${this.scope_group_id}`] = { "draft_filter" : this.draft_filter, "receive_filter" : this.receive_filter };
            }

            hodu_local_storage.setItem("approval_filter", JSON.stringify(approval_filter));
        }
        else {
            const approval_filter = {};
            approval_filter[`${this.user_id}_${this.scope_group_id}`] = { "draft_filter" : this.draft_filter, "receive_filter" : this.receive_filter };
            hodu_local_storage.setItem("approval_filter", JSON.stringify(approval_filter));
        }
        
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#approval_list').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
</script>

<style scoped>
    .hodu-datepicker-button { font-weight: bold; font-size: 14px; background : none; color: #232848; }

    /* .up1 .filter1 #filter_search{position: absolute;top: 12px;left: 135px !important;width: 355px;-webkit-transition: 0.2s;transition: 0.2s;height: 40px;line-height: 40px;padding: 0px 15px;font-size: 13px;font-weight: normal;-webkit-box-sizing: border-box;box-sizing: border-box;border: 1px solid #e7e9ea;display: inline-block;} */
    #approval_list ul.notiListUl li.Send_up p.title span{font-size: 13px;line-height: 60px;margin-left: 0;}
    #approval_list ul.notiListUl li.Send_up p.group span{font-size: 12px;line-height: 60px;margin-left: 0;}
    .up1 #approval_list ul.notiListUl li.Send_up p.group, .up1 .content #noticeScroll .viewGroup .fixedDiv .sortHeader li.group{width:21%;}
    .up1 #approval_list ul.notiListUl li.Send_up p.writtenBy, .up1 .content #noticeScroll .viewGroup .fixedDiv .sortHeader li.writtenBy{width:13%;}
    #approval_list ul.notiListUl li.Send_up p.writtenBy span{color: #aaa;font-size: 12px;line-height: 60px;}
    #approval_list ul.notiListUl li.Send_up p.title .quick {color: #fff;border-radius: 3px;background-color: #ff6363;padding: 1px 5px;margin-right: 6px;font-size: 12px;font-weight: normal;}
    #approval_list ul.notiListUl li.Send_up p.title .important {color: #fff;border-radius: 3px;background-color: #FFC72F;padding: 1px 5px;margin-right: 6px;font-size: 12px;font-weight: normal;}

    .restart_button{position: absolute;top: 8px; right: 55px; border: 1px solid; padding: 10px 15px; line-height: 20px; box-sizing: border-box; border-radius: 5px; border-color: #B0B0B0; font-size: 14px;}
    @media screen and (max-width: 1400px ) {
        .restart_button{position: absolute;top: 8px; right: 10px; border: 1px solid; padding: 10px 10px; line-height: 20px; box-sizing: border-box; border-radius: 5px; border-color: #B0B0B0; font-size: 14px;}
    }
    
</style>