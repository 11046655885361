<template>
    <div id="imageCropModal" class="modal imageCropModal">
        <!-- 상단 영역 시작 -->
        <div id="imageCropTitle" class="imageCropTitle titleBox">
            <h1 class="borderbot">이미지 자르기</h1>

            <!-- 상단 버튼 영역 시작 -->
            <div id="imageCropButtonContainer" class="imageCropButtonContainer btns">
                <input type="button" class="input_btn" value="취소" @click="clickCancel"/>
                <input type="button" class="input_btn" value="확인" @click="clickConfirm"/>
            </div>
            <!-- 상단 버튼 영역 끝 -->

        </div>
        <!-- 상단 영역 끝 -->
        
        <!-- 크롭 영역 시작 -->
        <div id="imageCropContainer" class="imageCropContainer modal_content">
            <VueCropper
                id="imageCropper"
                class="imageCropper"
                ref="cropper"
                :viewMode="1"
                :dragMode="'move'"
                :src="image_url"
                :aspectRatio="image_crop_ratio"
                :center="false"
                :highlight="false"
                :guides="false"
            />
        </div>
        <!-- 크롭 영역 끝 -->
        <!-- 버튼 -->
        <div class="toolBtns">
            <input type="button" value="확대" @click="zoomIn" />
            <input type="button" value="축소" @click="zoomOut" />
            <input type="button" value="왼쪽회전" @click="rotateLeft" />
            <input type="button" value="오른쪽회전" @click="rotateRight" />
            <input type="button" value="좌우반전" @click="flipHorizontal" />
            <input type="button" value="상하반전" @click="flipVertical" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { ImageCropModalInfo } from '@/store/modules/ModalInfo';

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

@Component({
    components: {
        VueCropper
    }
}) export default class ImageCropModal extends Mixins(VueHoduCommon) {

    // this.$refs.cropper 사용
    $refs !: { cropper : HTMLFormElement; }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State image_crop_modal_info !: ImageCropModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;

    image_url : string = "";
    image_crop_ratio : number = 1;

    async beforeMount() : Promise<void> {
        if( this.image_crop_modal_info.crop_image_ratio ) { this.image_crop_ratio = this.image_crop_modal_info.crop_image_ratio; }
        if( this.image_crop_modal_info.image_url ) { this.image_url = this.image_crop_modal_info.image_url; }
    }

    mounted() : void {

    }
    
    /**
     * 취소 버튼 클릭
     */
    clickCancel() : void {
        if( this.image_crop_modal_info.cancel_function == null ) { return; }

        Promise.all([this.image_crop_modal_info.cancel_function()]).then(() => {
            if( this.doSetImageCropModalInfo == null ) { return; }
            this.doSetImageCropModalInfo({ "show_modal" : false });
        });
    }

    /**
     * 확인 버튼 클릭
     */
    clickConfirm() : void {
        if( this.image_crop_modal_info.confirm_function == null ) { return; }
        
        // console.log(this.$refs.cropper.initCrop().getCroppedCanvas());

        // crop
        const cropped_url : string = this.$refs.cropper.initCrop().getCroppedCanvas().toDataURL('image/jpeg')

        // crop 해서 나온 Blob 파라미터로 넘기기
        Promise.all([this.image_crop_modal_info.confirm_function(cropped_url)]).then(() => {
            if( this.doSetImageCropModalInfo == null ) { return; }
            this.doSetImageCropModalInfo({ "show_modal" : false });
        });
    }

    /**
     * 확대
     */
    zoomIn() : void {
        this.$refs.cropper.relativeZoom(0.1);
    }

    /**
     * 축소
     */
    zoomOut() : void {
        this.$refs.cropper.relativeZoom(-0.1);
    }

    /**
     * 왼쪽으로 회전
     */
    rotateLeft() : void {
        this.$refs.cropper.rotate(-90);
    }

    /**
     * 오른쪽으로 회전
     */
    rotateRight() : void {
        this.$refs.cropper.rotate(90);
    }

    /**
     * 좌우반전
     */
    flipHorizontal() : void {
        this.$refs.cropper.scale(-(this.$refs.cropper.getData().scaleX), this.$refs.cropper.getData().scaleY);
    }

    /**
     * 상하반전
     */
    flipVertical() : void {
        this.$refs.cropper.scale(this.$refs.cropper.getData().scaleX, -(this.$refs.cropper.getData().scaleY));
    }

}

</script>

<style scoped>
    .modal { position:absolute; background:#fff; }
    .modal {margin:0 auto;width:100%;height: 100%;top:0;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}

    .titleBox {overflow:hidden;border-bottom: 0;padding: 20px 40px 25px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left; padding-top : 10px; }

    .imageCropModal { max-width: 100%; max-height: 100%; }
    .imageCropModal .imageCropTitle { height: 30px; } /* 패딩 40px, 30px 90px에서 뺀 만큼을 높이로  */
    .imageCropModal .imageCropTitle .imageCropButtonContainer { text-align: right; }
    .imageCropModal .imageCropTitle .imageCropButtonContainer .input_btn { margin-left : 15px; } 
    .imageCropModal .imageCropContainer { height: 100%; }

    .toolBtns { height:100%; width:60px; background:#fff;border-left:1px solid #e7e9ea; position: absolute; right:0; top: 76px; }
    .toolBtns input { display:block; width:35px; height:35px; border-radius:50%; font-weight:bold; color:#fff; background:#477fff; margin: 10px auto;}
    #imageCropper {height: 100%;}
    
</style>

<style>

    /* crop modal 자체 */
    .cropper-bg { background:#f1f3f5 !important; }
    .cropper-modal { background-color: #fff !important; opacity: 0.3 }
    .cropper-canvas { border: 1px solid #c0c8ce;box-sizing: border-box; }
    .cropper-modal { opacity:0.4 }
    .cropper-container.cropper-bg {height: 100% !important;}

</style>