<template>
    <div id="kids_detail" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <!-- <resize-observer @notify="handleResize" /> -->

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 id="viewTitle" class="title_doc">원아 정보</h3>
        </div>

        <div class="section_scroll">
            <div class="infor infor01">
                <div class="child_picture">
                    <h2 class="ir_so blind">원아사진</h2>
                    <img src="../../assets/images/footer/kid01.png" alt="프로필사진">
                </div>
                <div class="child_intro">
                    <ul>
                        <li>
                            <p class="name">원아명</p>
                            <!-- <p class="child_name">이영아</p> -->
                            <!-- 다 자녀일 경우 select 로 -->
                            <p class="child_name">
                                <select name="kid" id="kid_name">
                                    <option value="">이영아</option>
                                    <option value="">이준영</option>
                                    <option value="">이태인</option>
                                </select>
                            </p>
                        </li>
                        <li>
                            <p class="year_birth">생년월일</p>
                            <p class="child_yearB">2017.05.07</p>
                        </li>
                        <li>
                            <p class="gender">성별</p>
                            <p class="child_gender">여아</p>
                        </li>
                    </ul>
                </div>
                <div class="child_intro2" style="display: none;">
                    <ul>
                        <li>
                            <p class="name">원아명</p>
                            <!-- <p class="child_name">이영아</p> -->
                            <!-- 다 자녀일 경우 select 로 -->
                            <p class="child_name">
                                <select name="kid" id="kid_name">
                                    <option value="">이영아</option>
                                    <option value="" selected="selected">이준영</option>
                                    <option value="">이태인</option>
                                </select>
                            </p>
                        </li>
                        <li>
                            <p class="year_birth">생년월일</p>
                            <p class="child_yearB">2017.05.07</p>
                        </li>
                        <li>
                            <p class="gender">성별</p>
                            <p class="child_gender">여아</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="infor infor02">
                <h2>소속</h2>
                <ul class="class_box">
                    <li>
                        <p class="class">반이름</p>
                        <p class="child_class">무지개반</p>
                    </li>
                    <li>
                        <p class="enter">입학일</p>
                        <p class="child_enter">2021.03.04</p>
                    </li>
                    <li class="teacher_li">
                        <p class="teacher">담당 선생님</p>
                        <p class="child_teacher">장재영 교사</p>
                    </li>
                    <li>
                        <p class="tel">전화번호</p>
                        <p class="teacher_tel">010-5555-8989</p>
                    </li>
                </ul>
            </div>
            
            <div class="infor infor03">
                <h2>상태</h2>
                <ul>
                    <!-- 아이상태 등하원 사용할때를 위해 지우지마세요
                    <li>
                        /* child_onTime 등원 / child_offTime 하원 / on_absent 인정결석 / off_absent 결석 */
                        /* <p class="child_onTime">등원</p> */
                        <p class="child_onTime on_absent">인정결석</p>
                        <p class="userTime_on">오전 10:00</p>
                    </li>
                    <li>
                        <p class="child_offTime">하원</p>
                        <p class="userTime_off">-- : --</p>
                    </li>
                     -->
                    <li class="safe_box">
                        <!-- safe_good 좋아요 / safe_bad 나빠요 / safe_verybad 매우나빠요 / safe_error 오류  클래스 -->
                        <span class="now_safe safe_good">좋아요</span>
                        <div class="safety_class">
                            <p class="safe_time"><i class="safeTime_icon">측정시간아이콘</i>측정시간 : <span class="time_value">오전 11:00</span></p>
                            <p class="safe_heart">심박수 : <span class="heart_value">82</span></p>
                            <p class="safe_stress">스트레스 : <span class="stress_value">보통</span></p>
                            <p class="safe_steps">걸음수(활동량) : <span class="steps_value">2,155</span></p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="infor infor04">
                <h2>보호자</h2>
                <ul>
                    <li>
                        <p class="name">보호자명</p>
                        <p class="guardian_name">이영아맘</p>
                    </li>
                    <li>
                        <p class="tel">전화번호</p>
                        <p class="guardian_tel">010-5656-7878</p>
                    </li>
                    <li class="relation_li">
                        <p class="relation">관계</p>
                        <p class="guardian_relation">엄마</p>
                    </li>
                    <li>
                        <p class="remark">비고</p>
                        <p class="guardian_remark">-</p>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class KidsDetail extends Mixins(VueHoduCommon) {

    mounted() : void {
        $("#kid_name").off("click").on("click", function() {
            if ($("#kid_name").hasClass("on")) { 
                $("#kid_name").removeClass("on");

            } else { 
                $("#kid_name").addClass("on");

            }
        });
    }

}
</script>

<style scoped>
    #kids_detail { background: #fbfcfd; overflow: auto; }
    #kids_detail .section_scroll { max-width: 1240px; }
    .infor { overflow: hidden; padding: 30px; position: relative; }
    .infor h2 { font-size: 15px; font-weight: bold; margin: 0 0 10px 15px; }
    .infor ul { overflow: hidden; background: #fff; border: 1px solid #f1f3f5; border-radius: 10px; box-sizing: border-box; }
    .infor ul li { width: 50%; float: left; overflow: hidden; line-height: 50px; border-bottom: 1px solid #f1f3f5; }
    .infor ul li:last-child { border-bottom: 0; }
    .infor ul li p:first-child { width: 25%; border-right: 1px solid #f1f3f5; color: #acb2bf; box-sizing: border-box; }
    .infor ul li p { float: left; padding: 0 20px; font-weight: bold; overflow: hidden; white-space: nowrap; }

    .infor01 .child_picture { float: left; position: absolute; top: 30px; left: 30px; box-sizing: border-box; }
    .infor01 .child_picture img { width: 154px; height: 154px; border-radius: 50%; display: inline-block; box-shadow: 0 5px 10px rgb(0 0 0 / 10%); border: 5px solid #fff; box-sizing: border-box; }
    .infor01 .child_intro { width: 100%; height: 154px; float: left; padding-left: 185px; box-sizing: border-box; }
    .infor01 .child_intro > ul > li { width: 100%; }
    .infor01 .child_intro .child_name { width: 70%; box-sizing: border-box; }
    .infor01 .child_intro .child_name #kid_name { width: 100%; cursor: pointer; line-height: 50px; transition: .3s; }
    .infor01 .child_intro .child_name #kid_name:hover { font-weight: bold; }
    select#kid_name { -webkit-appearance: none; /* 네이티브 외형 감추기 */
             -moz-appearance: none; appearance: none;
             background: url('../../assets/images/side_area/bt_open.png') no-repeat 95% 50%; /* 화살표 모양의 이미지 */ 
    }
     /* IE 10, 11의 네이티브 화살표 숨기기 */
    select#kid_name::-ms-expand { display: none; }
    select#kid_name.on { background-image: url('../../assets/images/side_area/bt_close.png'); }

    .infor02 .class_box { border: 1px solid #f1f3f5; border-radius: 10px; box-sizing: border-box; overflow: hidden; }
    .infor02 li.teacher_li { border-bottom: 0; }
    .infor02 .enter, .infor02 .tel { border-left: 1px solid #f1f3f5; }

    .infor03 ul { background: none; border: 0; }
    .infor03 ul li { border-bottom: 0; }
    .infor03 ul li p:first-child { border-right: 0; color: #fff; text-align: center; width: 85px; padding: 0; }
    .infor03 ul li p { position: relative; }
    .infor03 ul li p.safe_heart { padding-right: 20px;  }
    .infor03 ul li .child_onTime { width: 85px; height: 40px; line-height: 40px; display: inline-block; background: #477fff; border-radius: 5px;  margin-top: 5px; }
    .infor03 ul li .child_offTime { width: 85px; height: 40px; line-height: 40px; display: inline-block; background: #13d08b; border-radius: 5px;  margin-top: 5px; }
    .infor03 ul li .on_absent { display: inline-block; background: #477fff;}
    .infor03 ul li .off_absent { background: #ff5757;  }
    /* 상태 */
    .infor03 li.safe_box { width: 100%; position: relative; }
    .infor03 li.safe_box .now_safe { position: absolute; top: 0; left: 0; line-height: 50px; float: left; font-weight: bold; min-width: 100px; box-sizing: border-box; }
    .infor03 li.safe_box .now_safe:before { content: ''; width: 25px; height: 25px; display: inline-block; border-radius: 50%; vertical-align: top; margin: 0 10px; margin-top: 12px; }
	.infor03 li.safe_box .safe_good { color: #477fff; }
	.infor03 li.safe_box .safe_bad { color: #ffa70e; }
	.infor03 li.safe_box .safe_verybad { color: #ff5757; }
	.infor03 li.safe_box .safe_error { color: #A5A5A5; }
    .infor03 li.safe_box .safe_good:before { background: url('../../assets/images/contents/hodukids_safe_good.svg') no-repeat; }
    .infor03 li.safe_box .safe_bad:before { background: url('../../assets/images/contents/hodukids_safe_bad.svg') no-repeat; }
    .infor03 li.safe_box .safe_verybad:before { background: url('../../assets/images/contents/hodukids_safe_verybad.svg') no-repeat; }
    .infor03 li.safe_box .safe_error:before { background: url('../../assets/images/contents/hodukids_safe_error.svg') no-repeat; }
    .infor03 li.safe_box .safety_class {  width: 100%; float: left; overflow: hidden; padding-left: 100px; box-sizing: border-box; }
    .infor03 li.safe_box .safety_class p { width: auto; color: #232848 !important; text-indent: 0; }
    .infor03 li.safe_box .safety_class p.safe_stress:before {top: 18px; }
    .infor03 li.safe_box .safety_class p.safe_stress:after { top: 18px; }
    .infor03 li.safe_box .safety_class .safe_time { opacity: 0.7; }
    .infor03 li.safe_box .safety_class .safe_time .safeTime_icon { width: 25px; height: 25px; display: inline-block; background: url('../../assets/images/contents/ic_time_bk.png') no-repeat center; vertical-align: top; border-radius: 50%; font-size: 0; text-indent: -9999px; margin-top: 12px; margin-right: 5px; background-size: 20px 20px; }

    .infor04 li.relation_li { border-bottom: 0; }
    .infor04 .tel, .infor04 .remark { border-left: 1px solid #f1f3f5; }
</style>