<template>
    <div id="organization_work_type_add_modal" class="modal">
        <div class="titleBox">
            <h1>
                <input type="text" class="typeNameInput" placeholder="타입명을 작성하세요" :value="work_type_name" @input="work_type_name = $event.target.value" :disabled="organization_work_type_add_modal_info.is_create == false && organization_work_type_add_modal_info.work_type_id == 1"/>
            </h1>
            <input type="checkbox" id="beacon_auto" v-model="is_beacon_auto"/>
            <label for="beacon_auto" style="font-size: 14px; font-weight: bold" v-if="is_visible_beacon_auto">비콘 사용시 <span class="flag" style="color : #477fff">자동</span> 출근</label>
        </div>
        <ul class="setTimeUl">
            
            <li>
                <p class="title fl">요일별 시간 설정 <span>(출퇴근 시간으로 사용됩니다)</span></p>
                <div class="descript">
                    <ul>
                        <li>
                            <span class="morn clr">노랑</span>
                            <span class="txt">오전</span>
                        </li>
                        <li>
                            <span class="night clr">보라</span>
                            <span class="txt">오후</span>
                        </li>
                        <li>
                            <span class="allday clr">파랑</span>
                            <span class="txt">종일</span>
                        </li>
                        <li>
                            <span class="off clr">회색</span>
                            <span class="txt">휴무</span>
                        </li>
                        <li>
                            <span class="home clr">밝은 회색</span>
                            <span class="txt">재택</span>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="contractUl">
                <ul>
                    <li :key="day.week" v-for="day in contents">
                        <span class="indi" :class="{
                            allday :  day.am_from &&  day.pm_from && day.am_from != 'home',
                            morn   :  day.am_from && !day.pm_from && day.am_from != 'home',
                            night  : !day.am_from &&  day.pm_from && day.am_from != 'home',
                            off    : !day.am_from && !day.pm_from && day.am_from != 'home',
                            home   :  day.am_from == 'home',
                        }" />
                        <p class="day">{{ getDayName(day.week, '요일') }}</p>

                        <p class="beacon">
                            <select v-model="day.is_beacon_use" @change="changeBeaconUse($event)">
                                <option value="true">비콘 사용</option>
                                <option value="false">비콘 사용 안함</option>
                                <option value="all_true">전부 비콘 사용</option>
                                <option value="all_false">전부 비콘 사용 안함</option>
                            </select>
                        </p>

                        <div class="shiftDiv">
                            <p class="openP">오전근무</p>
                            <!-- 오전 시작 -->
                            <select id="monFromMorn" v-model="day.am_from" @change="changeAmFrom($event, day.week)">
                                <option value="">휴무</option>
                                <option value="home">재택</option>
                                <option :key="n" v-for="n in 15" :value="`0${ 700 + (30 * Math.floor(( n - 1 ) / 2) + 30 * ((n - 1) % 2) + (70 * Math.floor(( (n - 1) / 2 )) ) ) }`.slice(-4)">
                                    {{ processTimeText(`0${ 700 + (30 * Math.floor(( n - 1 ) / 2) + 30 * ((n - 1) % 2) + (70 * Math.floor(( (n - 1) / 2 )) ) ) }`.slice(-4)) }}
                                </option>
                            </select>
                            <p class="sp">~</p>
                            <!-- 오전 종료 -->
                            <select id="monToMorn" v-model="day.am_to" @change="changeAmTo($event, day.week)">
                                <option value="">휴무</option>
                                <option value="home">재택</option>
                                <option :key="n" v-for="n in 15" :value="`0${ 730 + (70 * Math.floor(( n - 1 ) / 2) + 70 * ((n - 1) % 2) + (30 * Math.floor(( (n - 1) / 2 )) ) ) }`.slice(-4)">
                                    {{ processTimeText(`0${ 730 + (70 * Math.floor(( n - 1 ) / 2) + 70 * ((n - 1) % 2) + (30 * Math.floor(( (n - 1) / 2 )) ) ) }`.slice(-4)) }}
                                </option>
                            </select>
                        </div>

                        <div class="shiftDiv">                               
                            <p class="closeP">오후근무</p>
                            <!-- 오후 시작 -->
                            <select id="monFromNight" v-model="day.pm_from" @change="changePmFrom($event, day.week)">
                                <option value="">휴무</option>
                                <option value="home">재택</option>
                                <option :key="n" v-for="n in 22" :value="`0${ 1200 + (30 * Math.floor(( n - 1 ) / 2) + 30 * ((n - 1) % 2) + (70 * Math.floor(( (n - 1) / 2 )) ) ) }`.slice(-4)">
                                    {{ processTimeText(`0${ 1200 + (30 * Math.floor(( n - 1 ) / 2) + 30 * ((n - 1) % 2) + (70 * Math.floor(( (n - 1) / 2 )) ) ) }`.slice(-4)) }}
                                </option>
                            </select>
                            <p class="sp">~</p>
                            <!-- 오후 종료 -->
                            <select id="monToNight" v-model="day.pm_to" @change="changePmTo($event, day.week)">
                                <option value="">휴무</option>
                                <option value="home">재택</option>
                                <option :key="n" v-for="n in 22" :value="`0${ 1230 + (70 * Math.floor(( n - 1 ) / 2) + 70 * ((n - 1) % 2) + (30 * Math.floor(( (n - 1) / 2 )) ) ) }`.slice(-4)">
                                    {{ processTimeText(`0${ 1230 + (70 * Math.floor(( n - 1 ) / 2) + 70 * ((n - 1) % 2) + (30 * Math.floor(( (n - 1) / 2 )) ) ) }`.slice(-4)) }}
                                </option>
                            </select>
                        </div>

                    </li>

                    <!-- <li>
                        <span class="indi allday">종일</span>
                        <p class="day">월요일</p>
                        <div class="shiftDiv">
                            <p class="openP">오전근무</p>
                            <select id="monFromMorn">
                                <option value="">휴무</option>
                                <option value="">오전 07:00</option>
                                <option value="">오전 07:30</option>
                                <option value="">오전 08:00</option>
                                <option value="">오전 08:30</option>
                                <option value="">오전 09:00</option>
                                <option value="">오전 09:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 01:00</option>
                                <option value="">오후 01:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="monToMorn">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select> 
                        </div> 
                        <div class="shiftDiv">                               
                        <p class="closeP">오후근무</p>
                            <select id="monFromNight">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="monToNight">
                                <option value="">휴무</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>   
                    </li>
                    <li>
                        <span class="indi allday">종일</span>
                        <p class="day">화요일</p>
                        <div class="shiftDiv">
                            <p class="openP">오전근무</p>
                            <select id="tueFromMorn">
                                <option value="">휴무</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                                <option value="">오전 2:00</option>
                                <option value="">오전 2:30</option>
                                <option value="">오전 3:00</option>
                                <option value="">오전 3:30</option>
                                <option value="">오전 4:00</option>
                                <option value="">오전 4:30</option>
                                <option value="">오전 5:00</option>
                                <option value="">오전 5:30</option>
                                <option value="">오전 6:00</option>
                                <option value="">오전 6:30</option>
                                <option value="">오전 7:00</option>
                                <option value="">오전 7:30</option>
                                <option value="">오전 8:00</option>
                                <option value="">오전 8:30</option>
                                <option value="">오전 9:00</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="tueToMorn">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                        <div class="shiftDiv">
                            <p class="closeP">오후근무</p>
                            <select id="tueFromNight">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="tueToNight">
                                <option value="">휴무</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <span class="indi allday">종일</span>
                        <p class="day">수요일</p>
                        <div class="shiftDiv">
                            <p class="openP">오전근무</p>
                            <select id="wedFromMorn">
                                <option value="">휴무</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                                <option value="">오전 2:00</option>
                                <option value="">오전 2:30</option>
                                <option value="">오전 3:00</option>
                                <option value="">오전 3:30</option>
                                <option value="">오전 4:00</option>
                                <option value="">오전 4:30</option>
                                <option value="">오전 5:00</option>
                                <option value="">오전 5:30</option>
                                <option value="">오전 6:00</option>
                                <option value="">오전 6:30</option>
                                <option value="">오전 7:00</option>
                                <option value="">오전 7:30</option>
                                <option value="">오전 8:00</option>
                                <option value="">오전 8:30</option>
                                <option value="">오전 9:00</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="wedToMorn">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                            <div class="shiftDiv">
                            <p class="closeP">오후근무</p>
                            <select id="wedFromNight">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="wedToNight">
                                <option value="">휴무</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <span class="indi allday">종일</span>
                        <p class="day">목요일</p>
                        <div class="shiftDiv">
                            <p class="openP">오전근무</p>
                            <select id="thuFromMorn">
                                <option value="">휴무</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                                <option value="">오전 2:00</option>
                                <option value="">오전 2:30</option>
                                <option value="">오전 3:00</option>
                                <option value="">오전 3:30</option>
                                <option value="">오전 4:00</option>
                                <option value="">오전 4:30</option>
                                <option value="">오전 5:00</option>
                                <option value="">오전 5:30</option>
                                <option value="">오전 6:00</option>
                                <option value="">오전 6:30</option>
                                <option value="">오전 7:00</option>
                                <option value="">오전 7:30</option>
                                <option value="">오전 8:00</option>
                                <option value="">오전 8:30</option>
                                <option value="">오전 9:00</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="thuToMorn">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                        <div class="shiftDiv">
                            <p class="closeP">오후근무</p>
                            <select id="thuFromNight">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="thuToNight">
                                <option value="">휴무</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <span class="indi allday">종일</span>
                        <p class="day">금요일</p>
                        <div class="shiftDiv">
                            <p class="openP">오전근무</p>
                            <select id="friFromMorn">
                                <option value="">휴무</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                                <option value="">오전 2:00</option>
                                <option value="">오전 2:30</option>
                                <option value="">오전 3:00</option>
                                <option value="">오전 3:30</option>
                                <option value="">오전 4:00</option>
                                <option value="">오전 4:30</option>
                                <option value="">오전 5:00</option>
                                <option value="">오전 5:30</option>
                                <option value="">오전 6:00</option>
                                <option value="">오전 6:30</option>
                                <option value="">오전 7:00</option>
                                <option value="">오전 7:30</option>
                                <option value="">오전 8:00</option>
                                <option value="">오전 8:30</option>
                                <option value="">오전 9:00</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="friToMorn">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                        <div class="shiftDiv">
                            <p class="closeP">오후근무</p>
                            <select id="friFromNight">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="friToNight">
                                <option value="">휴무</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <span class="indi morn">오전</span>
                        <p class="day">토요일</p>
                        <div class="shiftDiv">
                            <p class="openP">오전근무</p>
                            <select id="satFromMorn">
                                <option value="">휴무</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                                <option value="">오전 2:00</option>
                                <option value="">오전 2:30</option>
                                <option value="">오전 3:00</option>
                                <option value="">오전 3:30</option>
                                <option value="">오전 4:00</option>
                                <option value="">오전 4:30</option>
                                <option value="">오전 5:00</option>
                                <option value="">오전 5:30</option>
                                <option value="">오전 6:00</option>
                                <option value="">오전 6:30</option>
                                <option value="">오전 7:00</option>
                                <option value="">오전 7:30</option>
                                <option value="">오전 8:00</option>
                                <option value="">오전 8:30</option>
                                <option value="">오전 9:00</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="satToMorn">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                        <div class="shiftDiv">
                            <p class="closeP">오후근무</p>
                            <select id="satToNight">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="satToNight">
                                <option value="">휴무</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <span class="indi off">휴무</span>
                        <p class="day">일요일</p>
                        <div class="shiftDiv">
                            <p class="openP">오전근무</p>
                            <select id="sunFromMorn">
                                <option value="">휴무</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                                <option value="">오전 2:00</option>
                                <option value="">오전 2:30</option>
                                <option value="">오전 3:00</option>
                                <option value="">오전 3:30</option>
                                <option value="">오전 4:00</option>
                                <option value="">오전 4:30</option>
                                <option value="">오전 5:00</option>
                                <option value="">오전 5:30</option>
                                <option value="">오전 6:00</option>
                                <option value="">오전 6:30</option>
                                <option value="">오전 7:00</option>
                                <option value="">오전 7:30</option>
                                <option value="">오전 8:00</option>
                                <option value="">오전 8:30</option>
                                <option value="">오전 9:00</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="sunToMorn">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                        <div class="shiftDiv">
                            <p class="closeP">오후근무</p>
                            <select id="sunFromNight">
                                <option value="">휴무</option>
                                <option value="">오전 9:30</option>
                                <option value="">오전 10:00</option>
                                <option value="">오전 10:30</option>
                                <option value="">오전 11:00</option>
                                <option value="">오전 11:30</option>
                                <option value="">오후 12:00</option>
                                <option value="">오후 12:30</option>
                                <option value="">오후 1:00</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                            <p class="sp">~</p>
                            <select id="sunToNight">
                                <option value="">휴무</option>
                                <option value="">오후 1:30</option>
                                <option value="">오후 2:00</option>
                                <option value="">오후 2:30</option>
                                <option value="">오후 3:00</option>
                                <option value="">오후 3:30</option>
                                <option value="">오후 4:00</option>
                                <option value="">오후 4:30</option>
                                <option value="">오후 5:00</option>
                                <option value="">오후 5:30</option>
                                <option value="">오후 6:00</option>
                                <option value="">오후 6:30</option>
                                <option value="">오후 7:00</option>
                                <option value="">오후 7:30</option>
                                <option value="">오후 8:00</option>
                                <option value="">오후 8:30</option>
                                <option value="">오후 9:00</option>
                                <option value="">오후 9:30</option>
                                <option value="">오후 10:00</option>
                                <option value="">오후 10:30</option>
                                <option value="">오후 11:00</option>
                                <option value="">오후 11:30</option>
                                <option value="">오전 12:00</option>
                                <option value="">오전 12:30</option>
                                <option value="">오전 1:00</option>
                                <option value="">오전 1:30</option>
                            </select>
                        </div>
                    </li> -->
                </ul>
            </li>
        </ul>

        <div class="btns">
            <input type="button" value="취소" @click.prevent="close">
            <input type="button" value="저장" @click.prevent="save">
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationWorkTypeAddModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_work_type_add_modal_info !: organization_modal_interface.OrganizationWorkTypeAddModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationWorkTypeAddModalInfo ?: (params : organization_modal_interface.OrganizationWorkTypeAddModalInfo) => void;

    work_type_name : string = "";

    contents : any[] = [
        { week:0, am_from: ''    , am_to: ''    , pm_from: ''    , pm_to: ''    , is_beacon_use : false },
        { week:1, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:2, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:3, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:4, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:5, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:6, am_from: ''    , am_to: ''    , pm_from: ''    , pm_to: ''    , is_beacon_use : false },
    ];

    is_beacon_auto : boolean = false;
    is_visible_beacon_auto : boolean = false;

    mounted() : void {
        if( this.organization_work_type_add_modal_info.is_create == false ) {
            this.getOrganizationWorktype();
        }
    }

    /**
     * 근무 타입 조회
     */
    async getOrganizationWorktype() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype?work_type_id=${this.organization_work_type_add_modal_info.work_type_id}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_type_info ) {
                throw new Error("조직도 근무 타입 조회 중 오류 발생");
            }

            const work_type_info = response.data.data.work_type_info;
            
            this.work_type_name = work_type_info.work_type_name;
            this.is_beacon_auto = work_type_info.is_beacon_auto;
            
            let count = 0;
            for( const content of work_type_info.contents ) {

                if( content.is_beacon_use == true ) {
                    count++;
                }

                if( content.is_telecommuting == true ) {
                    content.am_from = 'home';
                    content.am_to   = 'home';
                    content.pm_from = 'home';
                    content.pm_to   = 'home';
                    continue;
                }

                if( content.am_from == null ) content.am_from = ''; 
                if( content.am_to   == null ) content.am_to   = ''; 
                if( content.pm_from == null ) content.pm_from = ''; 
                if( content.pm_to   == null ) content.pm_to   = ''; 
            }

            this.is_visible_beacon_auto = count > 0;

            this.contents = JSON.parse(JSON.stringify(work_type_info.contents));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("근무 타입 조회 중 오류 발생");
            this.close();
        }
    }

    /**
     * am_from 선택
     */
    changeAmFrom(event, week) : void {
        const value = event.target.value;
        const day   = this.contents[week];

        if( value == '' ) {
            day.am_to = '';

            if( day.pm_from == 'home' ) {
                day.pm_from = '';
                day.pm_to   = '';
            }

            return;
        }

        else if( value == 'home' ) {
            day.am_to = 'home';
            day.pm_from = 'home';
            day.pm_to   = 'home';
            day.is_beacon_use = false;
            return;
        }

        if( day.pm_from == 'home' ) {
            day.pm_from = '';
            day.pm_to   = '';
        }

        if( day.am_to == '' || day.am_to == 'home' ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.am_to = `0${Number(value) + 70}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.am_to = `0${Number(value) + 30}`.slice(-4);
            }
            return;
        }

        const number_am_from = Number(day.am_from);
        const number_am_to   = Number(day.am_to);

        if( number_am_from >= number_am_to ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.am_to = `0${Number(value) + 70}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.am_to = `0${Number(value) + 30}`.slice(-4);
            }
        }

        
    }
    
    /**
     * am_to 선택
     */
    changeAmTo(event, week) : void {
        const value = event.target.value;
        const day   = this.contents[week];

        if( value == '' ) {
            day.am_from = '';

            if( day.pm_from == 'home' ) {
                day.pm_from = '';
                day.pm_to   = '';
            }

            return;
        }

        else if( value == 'home' ) {
            day.am_from = 'home';
            day.pm_from = 'home';
            day.pm_to   = 'home';
            day.is_beacon_use = false;
            return;
        }

        if( day.pm_from == 'home' ) {
            day.pm_from = '';
            day.pm_to   = '';
        }

        if( day.am_from == '' || day.am_from == 'home' ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.am_from = `0${Number(value) - 30}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.am_from = `0${Number(value) - 70}`.slice(-4);
            }
            return;
        }

        const number_am_from = Number(day.am_from);
        const number_am_to   = Number(day.am_to);

        if( number_am_from >= number_am_to ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.am_from = `0${Number(value) - 30}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.am_from = `0${Number(value) - 70}`.slice(-4);
            }
        }

    }

    /**
     * pm_from 선택
     */
    changePmFrom(event, week) : void {
        const value = event.target.value;
        const day   = this.contents[week];

        if( value == '' ) {
            day.pm_to = '';

            if( day.am_from == 'home' ) {
                day.am_from = '';
                day.am_to   = '';
            }

            return;
        }

        else if( value == 'home' ) {
            day.am_from = 'home';
            day.am_to   = 'home';
            day.pm_to   = 'home';
            day.is_beacon_use = false;
            return;
        }

        if( day.am_from == 'home' ) {
            day.am_from = '';
            day.am_to   = '';
        }

        if( day.pm_to == '' || day.pm_to == 'home' ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.pm_to = `0${Number(value) + 70}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.pm_to = `0${Number(value) + 30}`.slice(-4);
            }
            return;
        }

        const number_pm_from = Number(day.pm_from);
        const number_pm_to   = Number(day.pm_to);

        if( number_pm_from >= number_pm_to ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.pm_to = `0${Number(value) + 70}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.pm_to = `0${Number(value) + 30}`.slice(-4);
            }
        }
    }

    /**
     * pm_to 선택
     */
    changePmTo(event, week) : void {
        const value = event.target.value;
        const day   = this.contents[week];

        if( value == '' ) {
            day.pm_from = '';
            
            if( day.am_from == 'home' ) {
                day.am_from = '';
                day.am_to   = '';
            }
            
            return;
        }

        else if( value == 'home' ) {
            day.am_from = 'home';
            day.am_to   = 'home';
            day.pm_from = 'home';
            day.is_beacon_use = false;
            return;
        }

        if( day.am_from == 'home' ) {
            day.am_from = '';
            day.am_to   = '';
        }

        if( day.pm_from == '' || day.pm_to == '' ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.pm_from = `0${Number(value) - 30}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.pm_from = `0${Number(value) - 70}`.slice(-4);
            }
            return;
        }

        const number_pm_from = Number(day.pm_from);
        const number_pm_to   = Number(day.pm_to);

        if( number_pm_from >= number_pm_to ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.pm_from = `0${Number(value) - 30}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.pm_from = `0${Number(value) - 70}`.slice(-4);
            }
        }
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    /**
     * 비콘 사용여부 체크 (전부 사용, 전부 사용 안함 일때만 기능)
     */
    changeBeaconUse(event) : void {

        const value = event.target.value;

        if( value == "all_true" ) {
            for( const day of this.contents ) {
                day.is_beacon_use = "true";
            }
        }

        else if( value == "all_false" ) {
            for( const day of this.contents ) {
                day.is_beacon_use = "false";
            }
        }

        let count = 0;
        for( const day of this.contents ) {
            if( day.is_beacon_use == true || day.is_beacon_use == "true" ) count++;
        }

        this.is_visible_beacon_auto = count > 0;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationWorkTypeAddModalInfo?.({ show_modal : false, is_create : true, work_type_id : 0 });
    }

    /**
     * 모달 저장
     */
    async save() : Promise<void> {

        if( this.work_type_name.trim().length < 1 ) {
            alert("근무 타입 이름을 작성해주세요");
            $('#organization_work_type_add_modal .titleBox > h1 > input[type="text"]').focus();
            return;
        }

        for( const content of this.contents ) {
            const index = this.contents.indexOf(content);

            if( content.am_from && content.pm_from ) {
                const number_am_to   = Number(content.am_to);
                const number_pm_from = Number(content.pm_from);

                if( number_am_to > number_pm_from ) {
                    alert(`${this.getDayName(content.week, '요일')} 오전 근무시간과 오후 근무시간이 겹칩니다`);
                    return;
                }
            }
        }

        try {
            const contents = JSON.parse(JSON.stringify(this.contents));

            for( const content of contents ) {

                content.is_beacon_use = content.is_beacon_use == true || content.is_beacon_use == 'true'; 
                
                if( content.am_from == 'home' || content.am_to == 'home' || content.pm_from == 'home' || content.pm_to == 'home' ) {
                    content.am_from = null;
                    content.am_to   = null;
                    content.pm_from = null;
                    content.pm_to   = null;
                    content.is_telecommuting = true;
                    continue;
                }
                
                content.is_telecommuting = false;
                if( content.am_from == '' ) content.am_from = null;
                if( content.am_to   == '' ) content.am_to   = null;
                if( content.pm_from == '' ) content.pm_from = null;
                if( content.pm_to   == '' ) content.pm_to   = null;
            }

            const data = {
                group_id : this.scope_group_id,
                work_type_name : this.work_type_name.trim(),
                contents :  contents,
                is_beacon_auto : this.is_beacon_auto
            }

            if( this.organization_work_type_add_modal_info.is_create == true ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype`, API_METHOD.POST, data);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("조직도 근무 타입 생성 중 오류 발생");
                }

                this.organization_work_type_add_modal_info.callback?.();
                this.close();
            }
            
            else {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype/${this.organization_work_type_add_modal_info.work_type_id}`, API_METHOD.PUT, data);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("조직도 근무 타입 수정 중 오류 발생");
                }

                this.organization_work_type_add_modal_info.callback?.();
                this.close();
            }

        } catch(e) {
            if( this.organization_work_type_add_modal_info.is_create == true ) {
                alert("조직도 근무 타입 생성 중 오류 발생");
            }
            else {
                alert("조직도 근무 타입 수정 중 오류 발생");
            }
            this.hodu_error_process(e, false, false, true);
        }
        
    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-308.5px; margin-left:-512px; width:100%; border-radius:5px; max-width: 1024px;  height:610px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    .modal h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7eae7 }
    .modal .title {font-size: 15px;font-weight: bold;display: block;margin-bottom: 20px;margin-top: 0;}

    .setTimeUl .title span { font-size: 13px; margin-left:5px; opacity:0.7; }
    .setTimeUl { padding: 0 40px; max-width: 100%; box-sizing: border-box; width:100%; margin-bottom: 30px; padding-top: 20px;}
    .setTimeUl li:first-child { position:relative; overflow:hidden;  }
    .setTimeUl .title { font-size: 15px; font-weight: bold; display: block; margin-bottom: 15px; margin-top: 10px; }
    .setTimeUl > ul > li { overflow: hidden; position:relative;}
    .setTimeUl > ul > li > input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; }
    .setTimeUl > ul > li > input:hover { background-color:#f1f3f5; }
    .setTimeUl > ul > li > input:focus { background-color:#fff; border-color:#477fff; }
    .setTimeUl > ul > li > span { padding-right:20px; }
    .setTimeUl .descript { position: absolute; right:0; top:10px; }
    .setTimeUl .descript li { overflow:hidden; display:inline-block;margin-right:20px; }
    .setTimeUl .descript li:last-child { margin-right:0; }
    .setTimeUl .descript li .clr { float:left; margin-right:5px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; }
    .setTimeUl .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .setTimeUl .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .setTimeUl .descript li .clr.allday {background: #477fff !important;border-color: #477fff;}
    .setTimeUl .descript li .clr.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .setTimeUl .descript li .clr.home { background: #13D08B !important;border-color: #13D08B;}

    .contractUl { border-left:1px solid #e7e9ea; border-top: 1px solid #e7e9ea }
    .contractUl li {transition:0.2s;overflow:hidden; position:relative; font-size: 12px; line-height: 50px; height:50px; border-bottom:1px solid #e7e9ea; }
    .contractUl li:hover { border-color:#477fff; }
    .contractUl li a { display:inline-block; width:100%; height: 100%; }
    .contractUl li a:hover { background:#f7f9fb; font-weight:bold; }
    .contractUl li .day { font-weight:bold; display:inline-block;text-align: center; width:11%; font-size: 12px;box-sizing:border-box; padding-left:25px; }
    .contractUl li .beacon { font-weight:bold; display:inline-block;text-align: center; width:15.08%; font-size: 12px; box-sizing:border-box; }
    .contractUl li .beacon select { width : 100%; border : none; }
    .closeP {border-left: 5px solid #f1f3f5; }
    .contractUl li p { transition:0.2s; float:left;    box-sizing: border-box; display:inline-block !important; width: 8%; text-align: center; border-right:1px solid #e7e9ea }
    .contractUl li .sp { width:4.5% }
    .contractUl select {transition:0.2s;font-size: 12px; float:left; cursor:pointer; display: inline-block; appearance: none; border-right:1px solid #e7e9ea; width: 12.23%;text-align:center; box-sizing: border-box; padding:0 20px; line-height: 50px; height:50px; }
    .contractUl select {background: url(../../../assets/images/side_area/bt_open.png) no-repeat 90% center; }
    .contractUl select option { font-size: 14px;}
    .contractUl select:hover { background-color:#f1f3f5; }
    .contractUl select:focus { background-color:#fff; background-image: url(../../../assets/images/side_area/bt_close.png) }

    .contractUl .indi {position:absolute; top: 20px;left: 20px;display: inline-block;width: 7px;height: 7px; border-radius: 50%; font-size:0;border:1px solid; font-weight: bold; text-align: center; color:#fff; } 
    .contractUl .indi.morn {background: #ffc00e !important;border-color: #f3b300;}
    .contractUl .indi.night {background: #846eff !important;border-color: #6c52ff;}
    .contractUl .indi.allday {background: #477fff !important;border-color: #477fff;}
    .contractUl .indi.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .contractUl .indi.home { background: #13D08B !important;border-color: #13D08B;}

    .shiftDiv:hover .openP, .shiftDiv:hover .closeP, .shiftDiv:hover select { font-weight:bold; }

    .inbetweenDiv { position:relative;padding-top:22px; }
    .inbetweenDiv label { position: absolute;top:0;font-size: 12px; }
    .inbetweenDiv select { appearance: none;transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 40px; line-height: 40px; min-width: 150px; font-weight: bold; border-radius: 5px;  margin-right: 30px; width: 100px;text-align: center;background:  url('../../../assets/images/side_area/bt_open.png') no-repeat 91% center; }
    option { font-size: 14px }

    .btns { overflow: hidden; border-top:1px solid #e7eae7;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold; }
    .btns.b3 input { width:33.3333333333%; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }
    
    #organization_work_type_add_modal h1 { padding-left: 40px; }
    .typeNameInput { transition: 0.2s; font-size: 16px; width: 400px; border:1px solid #f1f3f5; line-height: 45px; height: 45px; padding: 0 15px; box-sizing: border-box; font-weight: bold;; }
    .typeNameInput:hover { border-color:#e7e9ea; background: #f1f3f5 }
    .typeNameInput:focus { border-color: #477fff; background:#fff}

    .modal .titleBox { position: relative; }
    #beacon_auto { position: absolute; top : 0; right : 0; }
    #beacon_auto:not(old)+label { position: absolute; top : 30px; right : 40px; background-position-y: 12px; display: inline-block; opacity: 0.7; }
    #beacon_auto:not(old):checked+label { opacity: 1; }
</style>