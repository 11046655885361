<template>
    <div class="pubHolidayDiv">
        <!-- 헤더소트 -->
        <ul class="sortHeader" id="">
            <li class="num">No</li>
            <li class="yyyy"><a class="sort" @click="toggleYearSelectMenu($event)">{{selected_year}}년</a>
                <div id="year_dropdown" class="dropdown" :class="{ on : is_year_list_open == true }">
                    <ul>
                        <li :key="yearIndex" v-for="(year, yearIndex) in year_list" @click.prevent="changeYear(year)">
                            <a href="" :class="{on : year == selected_year}" >{{year}}년</a>
                        </li>
                        <!-- <li>
                            <a href="">2018년</a>
                        </li>
                        <li>
                            <a href="">2019년</a>
                        </li>
                        <li>
                        <a href="" class="on">2020년</a>
                        </li>
                        <li>
                            <a href="">2021년</a>
                        </li>
                        <li>
                            <a href="">2022년</a>
                        </li>
                        <li>
                            <a href="">2023년</a>
                        </li>
                        <li>
                            <a href="">2024년</a>
                        </li> -->
                    </ul>
                </div>
            </li>
            <li class="time headerSort " @click="sortBy('DATE')"
                :class="{
                    sortOff  : holiday_sort_target != 'DATE',
                    sortUp   : holiday_sort_target == 'DATE' && holiday_sort_direction == 'ASC',
                    sortDown : holiday_sort_target == 'DATE' && holiday_sort_direction == 'DESC'
                }" ><span>기간</span></li>
            <li class="holidayName headerSort " @click="sortBy('NAME')"
                :class="{
                    sortOff  : holiday_sort_target != 'NAME',
                    sortUp   : holiday_sort_target == 'NAME' && holiday_sort_direction == 'ASC',
                    sortDown : holiday_sort_target == 'NAME' && holiday_sort_direction == 'DESC'
                }"><span>휴일명</span></li>
            <li class="wether headerSort "  @click="sortBy('OFFYN')"
                :class="{
                    sortOff  : holiday_sort_target != 'OFFYN',
                    sortUp   : holiday_sort_target == 'OFFYN' && holiday_sort_direction == 'ASC',
                    sortDown : holiday_sort_target == 'OFFYN' && holiday_sort_direction == 'DESC'
                }"><span>쉬나요?</span></li>
        </ul>

        <!-- 휴일 리스트 -->
        <div id="hospital_holiday_setting_scroll" > 
            <ul class="holidayUl ">
                <!-- <li class="replacement">
                    <span class="clr"></span>
                    <p class="num">1-1</p>
                    <div class="yyyy">
                        <select name="" id="">
                            <option value="" selected>2020년</option>
                            <option value="">2021년</option>
                            <option value="">2022년</option>
                            <option value="">2023년</option>
                            <option value="">2024년</option>
                            <option value="">2025년</option>
                            <option value="">2026년</option>

                        </select>
                    </div>
                    <p class="time"><input type="text" value="1월 2일    토요일"></p>
                    <p class="holidayName">신정<span>대체 휴일</span></p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>대체 휴무</option>
                            <option value="">대체 없음</option>
                        </select>
                    </div>
                </li> -->

                <li :key="`${holiday.holi_name}_${holidayIndex}`" v-for="(holiday, holidayIndex) in computedHolidays">
                    <p class="num">{{holidayIndex+1}}</p>
                    <p class="yyyy">{{getYearString(holiday.solar_ymd)}}년</p>
                    <p class="time"><span>{{getDateString(holiday.solar_ymd)}}</span></p>
                    <p class="holidayName">{{holiday.holi_name}}</p>
                    <div class="wether">
                        <select @change="changeHoliday($event, holiday)">
                            <!-- <option value="yes" selected>네</option>
                            <option value="no">아니오</option> -->
                            <option value="yes" :selected="holiday.is_work_day == false">네</option>
                            <option value="no" :selected="holiday.is_work_day == true">아니오</option>
                        </select>
                    </div>
                </li>

                <!-- <li>
                    <p class="num">2</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>1월 24일</span>금요일</p>
                    <p class="holidayName">설날(연휴)</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>

                <li>
                    <p class="num">3</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>1월 25일</span>토요일</p>
                    <p class="holidayName">신정</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">4</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>1월 26일</span>일요일</p>
                    <p class="holidayName">설날(연휴)</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">5</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>1월 27일</span>월요일</p>
                    <p class="holidayName">설날(대체공휴일)</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>

                <li>
                    <p class="num">6</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>3월 1일</span>일요일</p>
                    <p class="holidayName">삼일절</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">7</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>4월 15일</span>수요일</p>
                    <p class="holidayName">제21대 국회의원선거</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">8</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>4월 30일</span>목요일</p>
                    <p class="holidayName">부처님오신날</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">9</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>5월 5일</span>화요일</p>
                    <p class="holidayName">어린이날</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">10</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>6월 6일</span>토요일</p>
                    <p class="holidayName">현충일</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">11</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>8월 15일</span>토요일</p>
                    <p class="holidayName">광복절</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">12</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>9월 30일</span>수요일</p>
                    <p class="holidayName">추석 (연휴)</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">13</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>10월 1일</span>목요일</p>
                    <p class="holidayName">추석 (연휴)</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">14</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>10월 2일</span>금요일</p>
                    <p class="holidayName">추석 (연휴)</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">15</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>10월 3일</span>토요일</p>
                    <p class="holidayName">개천절</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">16</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>10월 9일</span>금요일</p>
                    <p class="holidayName">한글날</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li>
                <li>
                    <p class="num">17</p>
                    <p class="yyyy">2020년</p>
                    <p class="time"><span>12월 25일</span>금요일</p>
                    <p class="holidayName">성탄절</p>
                    <div class="wether">
                        <select name="" id="">
                            <option value="" selected>네</option>
                            <option value="">아니오</option>
                        </select>
                    </div>
                </li> -->
                
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { hodu_doc_table, hodu_doc_object } from '../../model/hodudoc';
import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalHolidaySetting extends Mixins(VueHoduCommon) {
    
    /**
     * 선택한 년도에 따른 오름차순 휴가 리스트
     */
    get computedHolidays() : any[] {
        if ( this.legal_holidays.length < 1 ) { return [] }
        let filtered_list : hodu_doc_object.public_holiday_info[] = JSON.parse(JSON.stringify(this.legal_holidays))
        
        for (let i=0; i<filtered_list.length; i++) {
            let holiday = filtered_list[i]
            if( this.computedWorkingHolidays.includes(holiday.solar_ymd) ) {
                filtered_list[i].is_work_day = false
                continue;
            }
            filtered_list[i].is_work_day = true
        }

        return this.sortHolidays(filtered_list);
    }

    /**
     * 일하는 공휴일의 날짜 string 리스트  ex. ["20200101", "20211231"]
     */
    get computedWorkingHolidays() : string[] {
        let workingHolidayDates : string[] = []
        for ( const workingholiday of this.hospital_working_holidays) {
            let start_date = moment(new Date(workingholiday.start_date)).format("YYYYMMDD")
            if ( start_date != null ) {
                workingHolidayDates.push(start_date)
            }
        }
        return workingHolidayDates
    }

    @Prop() event_bus !: Vue;
    
    year_list                   : number[] = []
    selected_year               : number = new Date().getFullYear()
    is_year_list_open           : boolean = false;
    legal_holidays              : hodu_doc_object.public_holiday_info[] = [];
    hospital_working_holidays   : any[] = [];
    holiday_sort_target         : string = "DATE";
    holiday_sort_direction      : string = "ASC";

    async mounted() : Promise<void> {
        if( this.event_bus != null ) { this.event_bus.$on('holidaySettingResize', this.handleResize); }
        this.setScroll();
        this.getLegalHolidays(new Date().getFullYear())

        const curr_year = new Date().getFullYear()
        for(let i=curr_year; i<=2050; i++) {
            this.year_list.push(i)
        }
    }

    async getLegalHolidays(year: number) : Promise<void> {
        let start_date = moment(new Date()).set("years", year).set("months", 0).set("dates",1).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate();
        let end_date   = moment(start_date).set("months", 11).set("dates",31).toDate();
        
        await this.getHolidays(this.selected_year)
        await this.getHospitalDayOffs()
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hospital_holiday_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });

        // @ts-ignore
        $('#year_dropdown').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 50,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    sortHolidays(list : hodu_doc_object.public_holiday_info[]) : hodu_doc_object.public_holiday_info[] {
        return list.sort((holiday1, holiday2) : number => {
            // 날짜 오름차순
            if( this.holiday_sort_target == "DATE" && this.holiday_sort_direction == "ASC" ) {
                if( holiday1.solar_ymd  > holiday2.solar_ymd ) { return  1; } 
                if( holiday1.solar_ymd  < holiday2.solar_ymd ) { return -1; } 
                if( holiday1.solar_ymd == holiday2.solar_ymd ) { return  0; }
            }

            // 날짜 내림차순
            if( this.holiday_sort_target == "DATE" && this.holiday_sort_direction == "DESC" ) {
                if( holiday1.solar_ymd  > holiday2.solar_ymd ) { return -1; }
                if( holiday1.solar_ymd  < holiday2.solar_ymd ) { return  1; }
                if( holiday1.solar_ymd == holiday2.solar_ymd ) { return  0; }
            }

            // 휴일명 오름차순
            if( this.holiday_sort_target == "NAME" && this.holiday_sort_direction == "ASC" ) {
                if( holiday1.holi_name  > holiday2.holi_name ) { return  1; }
                if( holiday1.holi_name  < holiday2.holi_name ) { return -1; }
                if( holiday1.holi_name == holiday2.holi_name ) { return  0; }
            }

            // 휴일명 내림차순
            if( this.holiday_sort_target == "NAME" && this.holiday_sort_direction == "DESC" ) {
                if( holiday1.holi_name  > holiday2.holi_name ) { return -1; }
                if( holiday1.holi_name  < holiday2.holi_name ) { return  1; }
                if( holiday1.holi_name == holiday2.holi_name ) { return  0; }
            }

            // 휴무 여부 오름차순
            if( this.holiday_sort_target == "OFFYN" && this.holiday_sort_direction == "ASC" ) {
                if( holiday1.is_work_day == true && holiday2.is_work_day == false ) { return  1; }
                if( holiday1.is_work_day == false && holiday2.is_work_day == true ) { return  -1; }
                if( holiday1.is_work_day == holiday2.is_work_day ) { return  0; }
            }

            // 휴무 여부 내림차순
            if( this.holiday_sort_target == "OFFYN" && this.holiday_sort_direction == "DESC" ) {
                if( holiday1.is_work_day == true && holiday2.is_work_day == false ) { return  -1; }
                if( holiday1.is_work_day == false && holiday2.is_work_day == true ) { return  1; }
                if( holiday1.is_work_day == holiday2.is_work_day ) { return  0; }
            }

            return 0;
        });
    }

    sortBy(target: string) {
        // 이미 DATE Sort 중이라면
        if( this.holiday_sort_target == target ) {
            // ASC -> DESC  순으로 변경 되어야 한다
            switch( this.holiday_sort_direction ) {
                case "ASC":
                    this.holiday_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.holiday_sort_direction = "ASC";
                    break; 
            }
        }
        
        // DATE Sort가 아니였다면
        else {
            this.holiday_sort_target = target;
            this.holiday_sort_direction = "ASC";
        }
    }

    getYearString(date_str : string) : string {
        return moment(date_str).format("YYYY")
    }

    toggleYearSelectMenu(event) : void {
        event.stopPropagation();
        this.is_year_list_open = !this.is_year_list_open;
    }

    changeHoliday(event, holiday : hodu_doc_object.public_holiday_info) : void {
        // if( event.target.value == 'no' ) {
        //     $(event.target).parent().parent().addClass("gray");
        //     $(".holidayUl").addClass("on");
        // } else {
        //     $(event.target).parent().parent().removeClass("gray");
        //     $(".holidayUl").removeClass("on");
        // }
        // $(event.target).blur();

        //! 병원 지정 휴일에 들어가있던 공휴일 휴무를 지운다
        if ( event.target.value == 'no' ) {
            for (const day_off of this.hospital_working_holidays) {
                let start_date = moment(day_off.start_date).format("YYYYMMDD")
                
                if ( start_date == holiday.solar_ymd && day_off.off_name == holiday.holi_name ) {
                    const delete_day_off = JSON.parse(JSON.stringify(day_off))
                    delete_day_off.is_public_holiday = event.target.value == 'no' ? false : true
                    this.deleteHolidayOff([delete_day_off.hospital_off_id])
                }
            }
        //! 해당 공휴일에 쉬기 위해 병원 지정 휴일에 공휴일 데이터를 넣어준다
        }else{
            let day_off : hodu_doc_table.t_hospital_day_offs = {
                biz_id : this.scope_group_team_option.biz_id,
                start_date          : moment(holiday.solar_ymd).set("hours", 0).set("minutes", 0).set("seconds", 0).toDate(),
                end_date            : moment(holiday.solar_ymd).set("hours", 23).set("minutes", 59).set("seconds", 59).toDate(),
                off_name            : holiday.holi_name,
                off_reason          : holiday.holi_name,
                is_public_holiday   : true,
                audit_created       : new Date(),
                audit_created_id    : this.user_id,
                hospital_off_id     : "",
            }
            this.createHolidayOff(day_off)
        }
    }

    changeYear(year : number) : void {
        this.selected_year = year;
        this.getLegalHolidays(this.selected_year);
        this.is_year_list_open = false;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#hospital_holiday_setting_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

    getDateString(date_str: string) : string {
        const month = moment(date_str).get("months") + 1
        const dates = moment(date_str).get("dates")
        return `${month}월 ${dates}일`
    }
    
    /**
     * 공휴일 조회
     */
    async getHolidays(year: number) : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/holiday?year=${year}`, API_METHOD.GET )
            .then((response) => {
                let jsonData : hodu_doc_object.public_holiday_info[] = JSON.parse(JSON.stringify(response.data.data))
                this.legal_holidays.splice(0, this.legal_holidays.length)
                this.legal_holidays = this.legal_holidays.concat(jsonData)
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 조회
     */
    async getHospitalDayOffs() : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/workingholiday?year=0`, API_METHOD.GET )
            .then((response) => {
                let jsonData : any[] = JSON.parse(JSON.stringify(response.data.data))
                this.hospital_working_holidays.splice(0, this.hospital_working_holidays.length)
                this.hospital_working_holidays = this.hospital_working_holidays.concat(jsonData)
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 해당 공휴일에 휴무 여부 설정 (병원 지정 휴일 수정 api 입니다.)
     */
    async createHolidayOff(dayoff: hodu_doc_table.t_hospital_day_offs) : Promise<void> {
        let body = {
            biz_id              : this.scope_group_team_option.biz_id,
            off_name            : dayoff.off_name,
            off_reason          : dayoff.off_reason,
            start_date          : new Date(dayoff.start_date),
            end_date            : new Date(dayoff.end_date),
            is_public_holiday   : dayoff.is_public_holiday
        }
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.POST, body)
            .then((response) => {
                let jsonData = JSON.parse(JSON.stringify(response.data.data));
                // let day_off = jsonData as hodu_doc_table.t_hospital_doctor_day_offs

                // if ( day_off.off_name != null && day_off.off_name.length > 0 ) {
                    // this.getHospitalDayOffs();
                    this.getLegalHolidays(this.selected_year);
                // }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 삭제 요청
     */
    async deleteHolidayOff(off_ids : string[]) : Promise<void> {
        let body = {
            hospital_off_ids : off_ids,
            delete_db        : true
        }
        console.log(body.hospital_off_ids);
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.DELETE, body)
            .then((response) => {
                this.getLegalHolidays(this.selected_year);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

}
</script>

<style scoped>
    .pubHolidayDiv { }
    .pubHolidayDiv .num { width:13%; text-align: center; }
    .pubHolidayDiv .yyyy { width:17%; }
    .pubHolidayDiv .time { width:25%; }
    .pubHolidayDiv .holidayName { width:25%; }
    .pubHolidayDiv .wether { width:20%; }
    .pubHolidayDiv .wether select {font-weight:bold; transition:0.2s; appearance: none; border-radius:5px; height:30px; line-height: 30px;cursor:pointer; background:transparent; box-sizing: border-box;border:1px solid #fff;}
    .pubHolidayDiv .wether select:hover { font-weight:bold; background:#f1f3f5; }
    .pubHolidayDiv .wether select:focus { border-color:#477fff !important; background:#fff; }
    .pubHolidayDiv .holidayUl .time span {width:60px;display:inline-block; }
    .pubHolidayDiv .dropdown {max-height: 282px;}
    .pubHolidayDiv li:hover .wether select { font-weight:bold; border:1px solid #000;  padding: 0 10px;}

    .holidayUl li { position:relative; font-weight:bold; width:100%; overflow:hidden; line-height:55px; height:55px; border-bottom:1px solid #f1f3f5; }
    .holidayUl li > * { float:left; }
    .holidayUl li.gray {background:#f7f9fb; }
    .holidayUl li.gray > * { opacity:0.4;  }
    .holidayUl li.gray > * span {   }
    .holidayUl li.gray:hover > * { opacity:1 }
    .holidayUl li.replacement { opacity:0; transition:0.2s; overflow:hidden; height: 0; line-height: 0; color:#477fff; }
    .holidayUl li.replacement .wether select {width:80px !important;color:#477fff;}
    .holidayUl li.replacement .clr { position:absolute; top:22px; left: 20px; width:10px; height:10px; border-radius: 50%; display:block; background:#477fff; }
    .holidayUl li.replacement .holidayName span { padding-left:10px; color:#477fff; }
    .holidayUl li.replacement input[type="text"] {color:#477fff; transition:0.2s; font-size:12px; background: transparent; font-weight: bold;; }
    .holidayUl li.replacement:hover input[type="text"], .holidayUl li.replacement:hover select { box-sizing: border-box; padding:0 10px; border:1px solid #000; line-height:30px; height:30px; border-radius: 5px;cursor:pointer; width: 110px; }
    .holidayUl li.replacement:hover input[type="text"]:hover, .holidayUl li.replacement:hover select:hover { background:#f1f3f5; }
    .holidayUl li.replacement:hover input[type="text"]:focus, .holidayUl li.replacement:hover select:focus { background:#fff; border-color:#477fff; }
    .holidayUl li.replacement .yyyy select {color:#477fff; width:70px !important; transition:0.2s;background:transparent;font-weight: bold; appearance: none; line-height:30px; height:30px; border-radius: 5px; font-size:12px; }
    .holidayUl.on .replacement {opacity:1; height: 55px; line-height: 55px; }


    .sortHeader .dropdown li { padding:0; border-top: 0 none; }
    .sortHeader .dropdown li a { padding: 0 20px; font-weight: bold; color: #7c88a2; }
    .sortHeader .dropdown li a.on, .sortHeader .dropdown li a:hover  { color:#232848 }
    .dropdown a.on:after { right: 20px; top:16px; width:10px; height: 10px; }
</style>