<template>

    <div class="offDiv">
        <div class="titleDiv">
            <h4>휴가 관리</h4>
            <select v-if="computedAvailbleYearList.length > 0" name="" class="selectHolidayYear" id="" v-on:change="selected_year = $event.target.value">
                <option :key="index" v-for="(year, index) in computedAvailbleYearList" :value="year" :selected="selected_year == year" >{{year}}</option>
                <!-- <option value="2019" :selected="year == 2019">2019</option>
                <option value="2020" :selected="year == 2020">2020</option>
                <option value="2021" :selected="year == 2021">2021</option>
                <option value="2022" :selected="year == 2022">2022</option>
                <option value="2023" :selected="year == 2023">2023</option> -->
            </select>
            <div class="btns">
                <input type="button" class="" value="추가" @click="showOffSettingModal(null)"> 
            </div>
        </div>
        <div id="offListScroll" class="addHolidayDiv">
            <div class="noResult" v-if="computedAvailbleYearList.length < 1">
                <p>현재 등록된 휴가 없음</p>
            </div>
            <ul class="ahUl">
                <!-- <li v-bind:key="index" v-for="(dayOff, index) in dayoff_info" >
                    <a>
                        <p class="offTime">종일</p>
                        <p class="offTitle">{{dayOff.off_name}}</p>
                        <p class="offDay">{{getDateString(dayOff.start_date, "DD E")}}<span>종일</span> ~ {{getDateString(dayOff.end_date, "DD E")}} <span>9:00 ~  13:30</span></p>
                        <p class="howManyDays">3.5 일</p>
                    </a>
                </li> -->
                <li v-bind:key="index" v-for="(dayOff, index) in computedOffListByYear" @click="showOffSettingModal(JSON.parse(JSON.stringify(dayOff)))">
                    <a>
                        <p class="offTime">종일</p>
                        <p class="offTitle">{{dayOff.off_name}}</p>
                        <!-- <p class="offDay">{{getDateString(dayOff.start_date, null, "start")}}<span>{{getTimeString(dayOff.start_date)}}</span> ~ {{getDateString(dayOff.end_date, dayOff.start_date, "end")}} <span>{{getTimeString(dayOff.end_date)}}</span></p> -->
                        <p class="offDay">{{getDateString(dayOff.start_date, null, "start")}} {{getDateString(dayOff.end_date, dayOff.start_date, "end")}}</p>
                        <p class="howManyDays">{{ getDuringDaysString(dayOff) }}</p>
                    </a>
                </li>
            </ul>
        </div>
        <div class="addedDiv">
            
        </div>
    </div>

    <!-- <div>

        <table>
            <tr v-bind:key="index" v-for="(time,index) in arrO_timetable">
                <td>time</td>
            </tr>
        </table>
    </div> -->
</template>

<script lang="ts">

import { Component, Vue, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import moment from 'moment';
import { namespace } from 'vuex-class';
import { hodu_doc_modal_info, hodu_doc_table } from '@/model/hodudoc';
const ModalInfo = namespace('ModalInfo');

@Component
export default  class DoctorOffDays extends Mixins(VueHoduCommon) {

    /**
     * 선택한 년도에 따른 오름차순 휴가 리스트
     */
    get computedOffListByYear() : hodu_doc_table.t_hospital_doctor_day_offs[] {
        if ( this.dayoff_info == null ) { return [] }
        let filtered_list : hodu_doc_table.t_hospital_doctor_day_offs[] = []

        filtered_list = this.dayoff_info.filter(item => new Date(item.start_date).getFullYear() == this.selected_year)

        filtered_list.sort((off1 : hodu_doc_table.t_hospital_doctor_day_offs, off2 : hodu_doc_table.t_hospital_doctor_day_offs) : number => {
                //오름차순
                if( off1.start_date  > off2.start_date ) { return  1; }
                if( off1.start_date  < off2.start_date ) { return -1; }
                if( off1.start_date == off2.start_date ) { return  0; }
                return 0
            })
        return filtered_list;
    }

    /**
     * 전체 휴가 리스트의 해당 년도 리스트
     */
    get computedAvailbleYearList() : number[] {
        if ( this.dayoff_info == null ) { return [] }
        let exist_years : number[] = []
        
        //먼저 전체 휴가 리스트의 작성 연도 리스트를 구한다
        const list = this.dayoff_info.reverse();
        for ( const info of list ) {
            const start_year = new Date(info.start_date).getFullYear()
            if ( exist_years.includes(start_year) == false ) {
                exist_years.push(start_year)
            }
        }

        exist_years.sort((year1 : number, year2 : number) : number => {
                //오름차순
                if( year1  > year2 ) { return  1; }
                if( year1  < year2 ) { return -1; }
                if( year1 == year2 ) { return  0; }
                return 0
            })
        return exist_years;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDayOffSettingModalInfo ?: (day_off_setting_modal_info : hodu_doc_modal_info.DayOffSettingModalInfo) => void;

    @Prop() dayoff_info    !: hodu_doc_table.t_hospital_doctor_day_offs[];
    @Prop() biz_id         !: string;
    @Prop() department_code!: string;
    @Prop() doctor_code    !: string;

    selected_year : number = new Date().getFullYear()

    async mounted() {
        this.setScroll()
    }

    /**
     * 휴가 생성 및 상세 모달 띄우기
     */
    showOffSettingModal(dayoff: hodu_doc_table.t_hospital_doctor_day_offs | null) : void {
        if( this.doSetDayOffSettingModalInfo ) {
            if ( dayoff != null ) {
                window['deleteDayOff'] = this.deleteDayOff
                window['updateDayOff'] = this.updateDayOff
                this.doSetDayOffSettingModalInfo({
                    "show_modal" : true,
                    "is_create"  : false,
                    "is_doctor"  : true,
                    "day_off"    : dayoff
                }); 
            }else{
                window['createDayOff'] = this.createDayOff
                this.doSetDayOffSettingModalInfo({
                    "show_modal" : true,
                    "is_create"  : true,
                    "is_doctor"  : true,
                    "day_off"    : null
                }); 
            }
        }
    }

    /**
     * 휴가 날짜 표기
     */
    getDateString(date: Date | string, compare_date: Date | string | null, key: string) : string {
        if (compare_date != null && key == "end"){
            const start_date = moment(new Date(date)).format("YYYY-MM-DD")
            const end_date   = moment(new Date(compare_date)).format("YYYY-MM-DD")

            if ( start_date == end_date ) {
                return ""
            }
        }
        let dowNum = Number(moment(date).format("E"));
        let dowName = this.getDayName(dowNum)

        return (key == "end" ? "~ " : "" ) + moment(date).format("MM.DD ") + dowName
    }

    /**
     * 휴가 시간 표기
     */
    getTimeString(date: Date) : string {
        return ""
        // return moment(date).format("HH:mm")
    }

    /**
     * 휴가 기간 표기
     */
    getDuringDaysString(dayoff : hodu_doc_table.t_hospital_doctor_day_offs) : string {
        const term = moment(dayoff.end_date).diff(dayoff.start_date, "days")
        
        // let fixed_term = Number(term.toFixed(1)) //! 8.8
        // let a = Math.floor(fixed_term)           //! 8
        // let b = Math.round((fixed_term % 1) * 10) / 10 //! 0.799999 -> 0.8
        
        // if(b == 0) { 
        //     b = 0
        // }else if(b <= 0.5) {
        //     b = 0.5
        // }else{
        //     b = 1
        // }
        
        // if( (a+b) == 0 ) {
        //     return ""
        // }
        // return `${a + b} 일`
        if( term < 1 ) { return "1일" }
        return `${term+1}일`;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const windowOuterHeight   : number | undefined = $(window).innerHeight();
        const titleBoxHeight      : number | undefined = $('.title_box').innerHeight();
        const offTitleBoxHeight   : number | undefined = $('.titleDiv').innerHeight();

        // @ts-ignore
        $('#offListScroll').mCustomScrollbar({
            axis : 'y',
            mouseWheelPixels : 80,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : (windowOuterHeight == null ? 0 : windowOuterHeight) - (titleBoxHeight == null ? 0 : titleBoxHeight) - (offTitleBoxHeight == null ? 0 : offTitleBoxHeight),
            scrollbarPosition : 'outside'
        });
    }
    
    /**
     * 의사 휴무 삭제
     */
    async deleteDayOff(dayoff: hodu_doc_table.t_hospital_doctor_day_offs) : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/doctor/${this.department_code}/${this.doctor_code}/day_off/${dayoff.doctor_off_id}`, API_METHOD.DELETE)
            .then((response) => {
                let response_msg = response.data.msg as string
                if (response_msg == "success"){
                    this.getDoctorDayOffs()
                }
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 의사 휴무 수정
     */
    async updateDayOff(dayoff: hodu_doc_table.t_hospital_doctor_day_offs) : Promise<void> {
        let req_data = {
            off_name   : dayoff.off_name,
            off_reason : dayoff.off_reason,
            start_date : new Date(dayoff.start_date),
            end_date   : new Date(dayoff.end_date)
        }
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/doctor/${this.department_code}/${this.doctor_code}/day_off/${dayoff.doctor_off_id}`, API_METHOD.PUT, req_data)
            .then((response) => {
                let jsonData = JSON.parse(JSON.stringify(response.data.data));
                let day_off = jsonData as hodu_doc_table.t_hospital_doctor_day_offs

                if ( day_off.off_name != null && day_off.off_name.length > 0 ) {
                    this.getDoctorDayOffs();
                }
                
            })
            .catch((e) => {
                let error = JSON.stringify(e);
                console.log(error);
            });
    }

    /**
     * 의사 휴무 생성
     */
    async createDayOff(dayoff: hodu_doc_table.t_hospital_doctor_day_offs) : Promise<void> {
        let req_data = {
            biz_id : this.biz_id,
            department_code : this.department_code,
            doctor_code : this.doctor_code,
            off_name : dayoff.off_name,
            off_reason : dayoff.off_reason,
            start_date : new Date(dayoff.start_date),
            end_date : new Date(dayoff.end_date)
        }
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/doctor/${this.department_code}/${this.doctor_code}/day_off`, API_METHOD.POST, req_data)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                if (jsonData.length > 0) {
                    this.getDoctorDayOffs();
                }
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 의사 휴무 정보 조회
     */
    async getDoctorDayOffs() : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/doctor/${this.department_code}/${this.doctor_code}/day_off`, API_METHOD.GET)
            .then((response) => {
                let jsonData : any[] = JSON.parse(JSON.stringify(response.data.data))
                this.$emit('reloadDayOffs', jsonData)
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

}


</script>

<style scoped>
.basicDiv .docImgDiv label { background-image: url(../../assets/images/footer/doc_2.png) !IMPORTANT; border-color:#13D08B !important }


    .content { height:100%;overflow: hidden; }
    .titleDiv { overflow: hidden; padding: 30px; }
    .titleDiv h4 { font-size: 17px;line-height: 50px; height: 50px;display:inline-block }
    .titleDiv .btns { display:inline-block; float:right;  }
    .basicDiv { width:60%;float:left;height:100%;  }
    .basicDiv > .title { font-size:17px;font-weight: bold; margin: 30px 30px 0; }
    .basicDiv .docImgDiv label { width: 100px; height:100px;border-radius: 50%; box-sizing: border-box; border: 3px solid #477fff; display: block;  font-size:0; cursor:pointer; background-image: url(../../assets/images/contents/im_photoB.gif); background-size:cover;background-position: center center; background-repeat: no-repeat; }
    .basicDiv .docImgDiv input[type="file"] { display:none; }
    .basicDiv .docImgDiv .del { transition:0.2s; position:absolute; right:0;bottom:0; display: block; width: 30px; height: 30px; background:#ff6060  url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .basicDiv .docImgDiv .del:hover { background-color: #fd1818 }
    .basicDiv .firstDiv { position:relative; padding: 30px 30px 30px 170px; border-bottom:5px solid #e7e9ea; }
    .docImgDiv { position: absolute; left:40px;top:59px;  }
    .docDetailDiv ul { overflow: hidden; }
    .docDetailDiv li { position: relative; float:left; margin-bottom:15px; width:50%;display:inline-block; padding-right: 20px; box-sizing: border-box; height: 78px; }
    .docDetailDiv select { font-weight:bold; transition:0.2s; cursor:pointer; appearance: none;padding-left: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; border-radius: 5px;background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 96%  center; background-repeat: no-repeat }
    .docDetailDiv select:hover { background-color:#f1f3f5; }
    .docDetailDiv select:focus { border-color:#477fff; background-color:#fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .docDetailDiv label { font-size: 13px;display:block; line-height: 23px; height:23px; margin-bottom:5px; } 
    .docDetailDiv input[type="text"] { transition:0.2s; font-weight: bold; padding: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; }
    .docDetailDiv input[type="text"]:hover { background:#f1f3f5; }
    .docDetailDiv input[type="text"]:focus { border-color:#477fff; background:#fff; }

    .setTimeDiv { padding: 30px; }
    .setTimeDiv .title { font-size: 16px; font-weight: bold; display: block; margin-bottom: 20px;    margin-top: 10px; }
    .setTimeDiv > ul > li { overflow: hidden; position:relative;}
    .setTimeDiv > ul > li > input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; }
    .setTimeDiv > ul > li > input:hover { background-color:#f1f3f5; }
    .setTimeDiv > ul > li > input:focus { background-color:#fff; border-color:#477fff; }
    .setTimeDiv > ul > li > span { padding-right:20px; }
    .setTimeDiv .descript { position: absolute; right:0; top:16px; }
    .setTimeDiv .descript li { overflow:hidden; display:inline-block;margin-right:20px; border: none;line-height: 20px; height:20px; }
    .setTimeDiv .descript li:last-child { margin-right:0; }
    .setTimeDiv .descript li .clr { float:left; margin-right:5px; margin-top: 4px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; }
    .setTimeDiv .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .setTimeDiv .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .setTimeDiv .descript li .clr.allday {background: #477fff !important;border-color: #477fff;}
    .setTimeDiv .descript li .clr.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .setTimeDiv .delContract { border-radius: 23px; background:#ff6060; border:0 none;  color:#fff; padding: 0 50px; font-size: 14px; line-height: 40px; height:40px; margin:0 auto; display:block; margin-top:20px; }
    .setTimeDiv .delContract:hover, .setTimeDiv .delContract:focus { background: #ff2e2e }
    .setTimeDiv .btns { float:right; }
    .selectDiv {  }
    .contractSelect { transition:0.2s; cursor:pointer; font-size: 14px; font-weight:bold; appearance: none; border-radius: 5px; line-height: 50px; height:55px; border:1px solid #e7e9ea;padding: 0 20px; width:100%; box-sizing: border-box; background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 98%  center; background-repeat: no-repeat }
    .contractSelect:hover { background-color:#f1f3f5;  }
    .contractSelect:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .contractSelect option { height: 40px; padding:20px 0; }
    .contractUl li { position:relative; font-size: 12px; line-height: 47px; height:47px; border-bottom:1px solid #f1f3f5; }
    .contractUl li a { display:inline-block; width:100%; height: 100%; }
    .contractUl li a:hover { background:#f7f9fb; font-weight:bold; }
    .contractUl li .day {  display:inline-block;padding: 0 30px 0 70px; text-align: center; }
    .contractUl li .workHours { display: inline-block; border-left: 1px solid #e7e9ea; line-height: 15px; }
    .workHours span {display:inline-block; padding: 0 25px; width: 150px; box-sizing: border-box; }
    .contractUl li .indi { transition: 0.1s; width: auto !important; font-size: 12px; font-weight:bold; height: 20px; line-height: 20px; margin-top:-7px; color:#fff; padding:0 10px; border-radius: 20px; left:10px; }
    .contractUl .indi { transition: 0s; position: absolute; left:20px; top:20px; width:10px; height:10px; display:block; border-radius: 50%; font-size:0; } 
    .contractUl .indi.morn {background: #ffc00e !important;border-color: #f3b300;}
    .contractUl .indi.night {background: #846eff !important;border-color: #6c52ff;}
    .contractUl .indi.allday {background: #477fff !important;border-color: #477fff;}
    .contractUl .indi.off { background: #a1a5bb !important;border-color: #a1a5bb;}
    .dayUl  { overflow:hidden; border-right:1px solid #e7e9ea}
    .dayUl li { display: inline-block; width: 14.2857142857%; float:left; text-align:center; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .dayUl li:hover .day { background:#f1f3f5; }
    .dayUl li p { line-height: 35px; height: 35px; font-weight: bold; border-bottom: 1px solid #e7e9ea;border-top: 1px solid #e7e9ea; }
    .offDay .checkDiv a { cursor: default; }
    .offDay .checkDiv a span { opacity:0.3; cursor: default; background:#fff !important;  }
    .checkDiv a { display: block; height:45px; line-height:45px; box-sizing: border-box; font-weight: bold; border-bottom: 1px solid #e7e9ea; }
    .checkDiv a.on:hover { cursor:default }
    .checkDiv a.on span { background:#477fff !important;border-color:#1b5fff; color:#fff; }
    .checkDiv a:hover span { background: #f1f3f5; }
    .checkDiv a span { transition:0.2s; display: block; height:100%; line-height: 45px; background:#fff; box-sizing: border-box;}
    .checkDiv a.on.morn span {background: #ffc00e !important;border-color: #f3b300;}
    .checkDiv a.on.night span {background: #846eff !important;border-color: #6c52ff;}
    .checkDiv a.on.off span { background: #a1a5bb !important;border-color: red;}
    #docClrId {transition:0.2s;width: 100%;height: 50px;line-height: 50px;border-radius: 5px;background: #fff;border: 1px solid #e7e9ea;box-sizing: border-box;padding: 0 15px;text-align: left;font-weight: bold;  }
    #docClrId:hover { background: #f1f3f5; }
    .docDetailDiv .clr { position:absolute; right:35px; top:43px; display: inline-block; width:20px;height: 20px; border-radius: 50%; ; }


    .offDiv { width: 40%;float:left; height:100%; border-left:1px solid #e7e9ea;box-sizing: border-box;  }
    .offDiv .selectHolidayYear { transition:0.2s; cursor:pointer; margin-top:10px; margin-left:15px; float:left; border: 1px solid #232848;border-radius: 20px; font-size: 13px; line-height: 26px; height:30px; appearance: none; padding: 0 30px 0 15px; font-weight: bold;background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 90%  center; background-repeat: no-repeat !important }
    .offDiv .selectHolidayYear:hover { background-color:#f1f3f5; }
    .offDiv .selectHolidayYear:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }

    .titleDiv { background:#fff; padding-bottom: 10px; padding-top:20px;border-bottom: 1px solid #e7e9ea }
    .titleDiv h4 { float:left;  }
    .offDiv .btns input, .addPlusBtn {transition:0.2s;background: url(../../assets/images/contents/ic_add.png) no-repeat center center; font-size: 0;width: 35px;height:35px; background-size: 40px; border-radius: 50%;margin-top: 5px;}
    .offDiv .btns input:hover, .addPlusBtn:hover {background-image: url(../../assets/images/contents/btn_add_fff.png); background-color:#477fff;}

    .addHolidayDiv li { position:relative; background:#fff; font-weight:bold;padding:0 30px; }
    .addHolidayDiv li a { border-bottom: 1px solid #f1f3f5; line-height: 55px; height: 80px; display: block; padding-left: 25px; padding-right: 50px; }
    .addHolidayDiv li:first-child a {  border-top: 1px solid #e7e9ea; }
    .addHolidayDiv li a p { display:inline-block; }
    /* .addHolidayDiv li .offTime {border:1px solid; position:absolute; left:30px;top:18px; padding:0 6px; border-radius: 2px; background:#477fff; color:#fff;height:20px; line-height: 20px; font-weight: bold; margin-right: 15px; border-radius: 3px; } */
    .addHolidayDiv li .offTime {border:0 none; font-size:0;position:absolute; left:30px;top:23px; border-radius: 2px; background:#477fff;height:10px; width: 10px; font-weight: bold; margin-right: 15px; border-radius: 50%;  }
    .offDiv p.morn {background: #ffc00e !important;border-color: #f3b300;}
    .offDiv p.night {background: #846eff !important;border-color: #6c52ff;}
    .offDiv p.off { background: #a1a5bb !important;border-color: red;}
    .addHolidayDiv li .offTitle { width: 100%; font-size:14px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .addHolidayDiv li .offDay {left:55px;top:25px; position: absolute; }
    .offDay span {padding: 0 3px;background:#f1f3f5;margin-left:3px; font-weight: normal; }
    .howManyDays { position:absolute; right: 30px;top:0; }

    .ahUl { padding-bottom: 100px; }

    .noResult { position: static; padding: 20px 30px }
    .noResult p { background:#f1f3f5; line-height: 60px; height: 60px; font-size: 13px;color:#7c88a2 }
</style>