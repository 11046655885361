<template>
    <div class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="bt_scheduleBox" id="bt_scheduleBoxNoti">
            <input type="button" id="btnAddNotification" class="bt_schedule" @click="ckeditor4" v-if="b_isNoticeCreateFlag">
        </div>

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">공지사항</h3>
            <a href="#" class="schBtn" @click.prevent="searchOnOff(true)">검색하기</a>
        </div>

        <SearchBar 
            :is_search_open="is_search_open" 
            :placeholder="'공지사항을 검색하세요'"
            :search_query="search_query" 
            @searchOff="searchOnOff"
            @query="(value) => search_query_temp = value"
            @search="search"
            @searchKeyDown="searchKeyDown" 
            />

        <!-- addClass(edit) 수정 가능한자일시 ****************** 여기 스크롤 아님 하단 .content 스크롤 -->
        <div id="noticeScroll" class="section_scroll">
            <div class="viewGroup">					
                <div class="schedule_box">
                    <!--
                    <div class="grp settingBg">
                        <h3 class="selectTitle"><span class="">{{search_type == '' ? '호두 공지사항' : '그룹 공지사항'}}</span></h3>
                         <ul class="dropdown ">
                            <li><a :class="{on: search_type == ''}" @click="tabChange('')">호두 공지사항<span></span></a></li>
                            <li><a :class="{on: search_type == 'GROUP'}" @click="tabChange('GROUP')">그룹 공지사항<span></span></a></li>
                        </ul>
                        <p class="btn_group" style="">
                            <a href="#" class="input_btn delBtn" id="">삭제</a>
                            <a href="#" class="input_btn" id="addNotice" v-if="b_isNoticeCreateFlag" @click.prevent="addNotice()">추가</a>
                        </p>
                        
                    </div> -->
                    <!-- <input type="button" class="verOneNotice" @click="addNotice()" value=" 일반 공지사항" v-if="b_isNoticeCreateFlag"/>&nbsp;&nbsp;&nbsp; -->
                    <!-- <input type="button" class="verOneNotice" @click="ckeditor4" value="" v-if="b_isNoticeCreateFlag"/> -->
                </div>
                <div class="fixedDiv">
                    <ul class="sortHeader groupNoticeHeader" id="">
                        <li class="notiCheck">
                            <p class="notiCheck on" id="notiCheckAll">
                                <input type="checkbox" id="">
                                <label for="notiCheckAll">전체선택</label>
                            </p>
                        </li>
                        <li class="num">No</li>
                        <li class="group headerSort" @click="groupTeamSort"
                            :class="{
                                sortOff  : notice_sort_target != 'GROUP_TEAM',
                                sortUp   : notice_sort_target == 'GROUP_TEAM' && notice_sort_direction == 'ASC', 
                                sortDown : notice_sort_target == 'GROUP_TEAM' && notice_sort_direction == 'DESC', 
                            }"><span>
                                {{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '아파트 &amp; 동' : '그룹 &amp; 팀' }}
                                </span>
                        </li>
                        <li class="title headerSort" @click="subjectSort"
                            :class="{
                                sortOff  : notice_sort_target != 'SUBJECT',
                                sortUp   : notice_sort_target == 'SUBJECT' && notice_sort_direction == 'ASC', 
                                sortDown : notice_sort_target == 'SUBJECT' && notice_sort_direction == 'DESC', 
                            }"><span>제목</span></li>
                        <li class="writtenBy headerSort" @click="userNameSort"
                            :class="{
                                sortOff  : notice_sort_target != 'USER_NAME',
                                sortUp   : notice_sort_target == 'USER_NAME' && notice_sort_direction == 'ASC', 
                                sortDown : notice_sort_target == 'USER_NAME' && notice_sort_direction == 'DESC', 
                            }"><span>작성자</span></li>
                        <li class="time headerSort" @click="timeSort"
                            :class="{
                                sortOff  : notice_sort_target != 'TIME',
                                sortUp   : notice_sort_target == 'TIME' && notice_sort_direction == 'ASC', 
                                sortDown : notice_sort_target == 'TIME' && notice_sort_direction == 'DESC', 
                            }"><span>작성일</span></li>
                        <li class="status"><span>읽은사람</span></li>
                    </ul>
                </div>
                <!-- content 스크롤 -->
                <div class="content" id="group_notice_scroll">
                    <ul class="notiListUl">
                        <!-- <li>
                            <p class="notiCheck">
                                <input type="checkbox" id="notiCheck01" value="" class="input_check">
                                <label for="notiCheck01"></label>
                            </p>                
                            <p class="num"><span>0</span></p>     
                            <p class="group"><span>테스트0</span></p>
                            <p class="title"><span>파일첨부되면 아이콘 보이기</span><i class="noticeFile_icon">파일첨부여부 icon</i></p>
                            <p class="writtenBy"><span>호호두</span></p>
                            <p class="time"><span>2021.07.23</span></p>
                            <p class="status"><a><span>0</span> 명</a></p> 
                            <a class="seeNotice" title="상세보기">상세보기</a>
                        </li> -->
                        <li :class="{
                            new: !lo_data.read_flag
                            }" v-bind:key="index" v-for="(lo_data, index) in notice_data">
                            <p class="notiCheck">
                                <input type="checkbox" id="notiCheck01" value="" class="input_check">
                                <label for="notiCheck01"></label>
                            </p>                
                            <p class="num"><span>{{index+1}}</span></p>     
                            <p class="group"><span>{{lo_data.group_nm}}</span></p>         
                            <p class="title">
                                <span>{{lo_data.noti_info.title}}</span>
                                <i class="noticeFile_icon" v-if="lo_data.noti_info.files != null && lo_data.noti_info.files.length > 0">파일첨부여부 icon</i>
                            </p>
                            <p class="writtenBy"><span>{{ lo_data.audit_user_name && lo_data.audit_user_name.length > 0 ? lo_data.audit_user_name : '-' }}</span></p>
                            <p class="time"><span>{{lo_data.noti_info.start_date}}</span></p>
                            <p class="status"><a @click="showGroupNoticeStatus(lo_data.noti_uid)"><span>{{lo_data.read_cnt}}</span> 명</a></p> 
                            <a class="seeNotice" title="상세보기" @click.prevent="detailNotice(lo_data)">상세보기</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>	
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import SearchBar from '@/components/ui/SearchBar.vue';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');
const dateFormat = require('dateformat');

import { GroupNoticeSearchInfo } from '@/store/modules/GroupInfo';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        SearchBar, ResizeObserver
    },
})
export default class GroupNoticeList extends Mixins(VueHoduCommon) {
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupNoticeReadInfo ?: any;

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;
    @GroupInfo.State group_notice_search_info !: GroupNoticeSearchInfo;

    /**
     * GroupInfo.Action
     */
    @GroupInfo.Action doSetGroupNoticeSearchInfo ?: (params : GroupNoticeSearchInfo) => void;

    /**
     * 변수 선언부
     */
    search_type          : string  = "GROUP";  // 공백 : 호두공지 사항, GROUP : 그룹&팀 공지사항
    notice_type          : string  = "";
    notice_data          : any     = [];
    original_notice_data : any     = [];
    b_isNoticeCreateFlag : boolean = false;

    notice_sort_target : string | null = null;
    notice_sort_direction : string = "ASC";

    is_search_open : boolean = false;
    search_query : string = "";
    search_query_temp : string = "";

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }

        this.search_query = this.group_notice_search_info.search_query ? this.group_notice_search_info.search_query : "";
        if ( this.search_query.length > 0 ) {
            this.is_search_open = true;
            this.search_query_temp = this.search_query;
        }

        this.notice_sort_target = this.group_notice_search_info.notice_sort_target;
        this.notice_sort_direction = this.group_notice_search_info.notice_sort_direction;
    }

    mounted() : void {
        const vue = this;
        
        this.setScroll();
        
        $(".selectTitle").click(function(){
			$(this).toggleClass("on");
			$(this).siblings().toggleClass("on");
		});
		
		$('.seeNotice').on('click', function(e){
            e.preventDefault();
            vue.hodu_router_push(`/group/${new Date().getTime()}/notice_create`);
			// $.notificationTemp3.initViewPage('#section_ce');
		});
        
        /*
		$('#addNotice').on('click', function(e){
            e.preventDefault();
            vue.hodu_router_push('group_notice_create');
			// $.notificationTemp4.initViewPage('#section_ce');
        });
        */

       // 공지사항 uid 삭제
       if ( this.group_team_option.noti_uid ) {
            delete vue.group_team_option.noti_uid;
        }
        
        // 공지사항 조회
        this.getNotice();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        const scrollHeight      : number | undefined = window.innerHeight - ( titleHeight ? titleHeight : 0 ) - ( settingBgHeight ? settingBgHeight : 0 ) - ( sortHeaderHeight ? sortHeaderHeight : 0 );

        // @ts-ignore
        $('#group_notice_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : scrollHeight,
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 공지사항 작성
     */
    addNotice() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);

        if ( this.group_team_option.noti_uid ) {
            delete this.group_team_option.noti_uid;
        }

        this.doGroupTeamOption(this.group_team_option);
        this.hodu_router_push(`/group/${new Date().getTime()}/notice_create`);
    }

    ckeditor4() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);

        if ( this.group_team_option.noti_uid ) {
            delete this.group_team_option.noti_uid;
        }

        this.doGroupTeamOption(this.group_team_option);
        this.hodu_router_push(`/group/${new Date().getTime()}/notice_create_editor`);
    }

    /**
     * 공지사항 상세보기
     */
    async detailNotice(po_data) : Promise<void> {
        const vue = this;

        // 공지사항 읽음여부
        if ( !po_data.read_flag ) {
            await this.hodu_api_call(`api/v1/notification/insertRead/${po_data.noti_uid}`, API_METHOD.PUT, {}, false)
                .then(async(response) => {
                    vue.goNoticeDetail(po_data);
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }
        else {
            vue.goNoticeDetail(po_data);
        }
    }

    goNoticeDetail(po_data) : void { 
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);
        this.group_team_option.noti_uid = po_data.noti_uid;

        this.doGroupTeamOption(this.group_team_option);
        this.hodu_router_push(`/group/${new Date().getTime()}/notice_detail`);
    }

    /**
     * 그룹 읽음 현황 모달 보이기
     */
    showGroupNoticeStatus(noti_uid : string) : void {
        this.doSetGroupNoticeReadInfo({
            show_modal : true,
            noti_uid : noti_uid
        });
    }

    /**
     * 그룹 공지사항, 호두 공지사항 변경
     */
    // tabChange(type) : void {
    //     alert('준비중입니다');
    //     this.search_type = type;
    // }

    /**
     * 그룹 공지사항 조회
     */
    async getNotice() : Promise<void> {
        
        this.notice_type = (this.team_id == 0 ? "GROUP" : "TEAM");

        await this.hodu_api_call(`api/v1/notification/notice/${this.group_id}/${this.team_id}/${this.notice_type}?page=0&size=0&current_date=${moment(new Date()).utc().format()}`, API_METHOD.GET, null)
            .then(async(response) => {

                this.notice_data.splice(0, this.notice_data.length)
                this.notice_data = this.notice_data.concat(response.data.data.list);

                if( this.search_query != null && this.search_query.length > 0 ) {
                    this.notice_data = this.notice_data.filter(notice => this.hodu_string_includes(notice.noti_info.title, this.search_query) == true || 
                                                                         this.hodu_string_includes(notice.noti_data.body, this.search_query) == true);
                }

                // 공지사항 작성
                if ( this.notice_type == "TEAM" ) {
                    this.b_isNoticeCreateFlag = this.is_team_permmision(this.team_id, "notice", "create");
                }
                else {
                    this.b_isNoticeCreateFlag = this.is_group_permmision(this.group_id, "notice", "create");
                }

                $.each(this.notice_data, function(key, data) {
                    data.noti_info.start_date = dateFormat(data.noti_info.start_date, 'yyyy.mm.dd');
                });

                this.original_notice_data = JSON.parse(JSON.stringify(this.notice_data));

                if( this.notice_sort_target != null ) {
                    this.noticeListSort();
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 그룹, 팀 이름 기준 정렬
     */
    groupTeamSort() : void {
        // 이미 GROUP_TEAM Sort 중이라면
        if( this.notice_sort_target == "GROUP_TEAM" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // GROUP_TEAM Sort가 아니였다면
        else {
            this.notice_sort_target = "GROUP_TEAM";
            this.notice_sort_direction = "ASC";
        }

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.notice_sort_target = this.notice_sort_target;
        search_info.notice_sort_direction = this.notice_sort_direction;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * 제목 기준 정렬
     */
    subjectSort() : void {
        // 이미 SUBJECT Sort 중이라면
        if( this.notice_sort_target == "SUBJECT" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // SUBJECT Sort가 아니였다면
        else {
            this.notice_sort_target = "SUBJECT";
            this.notice_sort_direction = "ASC";
        }

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.notice_sort_target = this.notice_sort_target;
        search_info.notice_sort_direction = this.notice_sort_direction;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * 작성자 이름 기준 정렬
     */
    userNameSort() : void {
        // 이미 USER_NAME Sort 중이라면
        if( this.notice_sort_target == "USER_NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // USER_NAME Sort가 아니였다면
        else {
            this.notice_sort_target = "USER_NAME";
            this.notice_sort_direction = "ASC";
        }

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.notice_sort_target = this.notice_sort_target;
        search_info.notice_sort_direction = this.notice_sort_direction;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * 작성일 기준 정렬
     */
    timeSort() : void {
        // 이미 TIME Sort 중이라면
        if( this.notice_sort_target == "TIME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // TIME Sort가 아니였다면
        else {
            this.notice_sort_target = "TIME";
            this.notice_sort_direction = "ASC";
        }

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.notice_sort_target = this.notice_sort_target;
        search_info.notice_sort_direction = this.notice_sort_direction;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.notice_data = JSON.parse(JSON.stringify(this.original_notice_data));
    }

    /**
     * 공지사항 정렬
     */
    noticeListSort() : void {
        this.notice_data.sort((o1 : any, o2 : any) : number => {
            // 그룹&팀 이름 오름차순
            if( this.notice_sort_target == "GROUP_TEAM" && this.notice_sort_direction == "ASC" ) {
                if( o1.group_nm  > o2.group_nm ) { return  1; } 
                if( o1.group_nm  < o2.group_nm ) { return -1; } 
                if( o1.group_nm == o2.group_nm ) { return  0; }
            }

            // 그룹&팀 이름 내림차순
            if( this.notice_sort_target == "GROUP_TEAM" && this.notice_sort_direction == "DESC" ) {
                if( o1.group_nm  > o2.group_nm ) { return -1; } 
                if( o1.group_nm  < o2.group_nm ) { return  1; } 
                if( o1.group_nm == o2.group_nm ) { return  0; }
            }

            // 제목 오름차순
            if( this.notice_sort_target == "SUBJECT" && this.notice_sort_direction == "ASC" ) {
                if( o1.noti_info.title  > o2.noti_info.title ) { return  1; } 
                if( o1.noti_info.title  < o2.noti_info.title ) { return -1; } 
                if( o1.noti_info.title == o2.noti_info.title ) { return  0; }
            }

            // 제목 내림차순
            if( this.notice_sort_target == "SUBJECT" && this.notice_sort_direction == "DESC" ) {
                if( o1.noti_info.title  > o2.noti_info.title ) { return -1; } 
                if( o1.noti_info.title  < o2.noti_info.title ) { return  1; } 
                if( o1.noti_info.title == o2.noti_info.title ) { return  0; }
            }

            // 작성자 이름 오름차순
            if( this.notice_sort_target == "USER_NAME" && this.notice_sort_direction == "ASC" ) {
                if( o1.audit_user_name  > o2.audit_user_name ) { return  1; } 
                if( o1.audit_user_name  < o2.audit_user_name ) { return -1; } 
                if( o1.audit_user_name == o2.audit_user_name ) { return  0; }
            }

            // 작성자 이름 내림차순
            if( this.notice_sort_target == "USER_NAME" && this.notice_sort_direction == "DESC" ) {
                if( o1.audit_user_name  > o2.audit_user_name ) { return -1; } 
                if( o1.audit_user_name  < o2.audit_user_name ) { return  1; } 
                if( o1.audit_user_name == o2.audit_user_name ) { return  0; }
            }

            // 작성일 오름차순
            if( this.notice_sort_target == "TIME" && this.notice_sort_direction == "ASC" ) {
                if( new Date(o1.noti_info.start_date).getTime()  > new Date(o2.noti_info.start_date).getTime() ) { return  1; } 
                if( new Date(o1.noti_info.start_date).getTime()  < new Date(o2.noti_info.start_date).getTime() ) { return -1; } 
                if( new Date(o1.noti_info.start_date).getTime() == new Date(o2.noti_info.start_date).getTime() ) { return  0; }
            }

            // 작성일 내림차순
            if( this.notice_sort_target == "TIME" && this.notice_sort_direction == "DESC" ) {
                if( new Date(o1.noti_info.start_date).getTime()  > new Date(o2.noti_info.start_date).getTime() ) { return -1; } 
                if( new Date(o1.noti_info.start_date).getTime()  < new Date(o2.noti_info.start_date).getTime() ) { return  1; } 
                if( new Date(o1.noti_info.start_date).getTime() == new Date(o2.noti_info.start_date).getTime() ) { return  0; }
            }

            return 0;
        })
    }

    /**
     * 검색 ON / OFF
     */
    searchOnOff(flag : boolean) : void {

        this.is_search_open = flag;

        if( flag == false ) {
            this.search_query = "";
            this.search_query_temp = "";

            const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
            search_info.search_query = "";
            this.doSetGroupNoticeSearchInfo?.(search_info);

            this.getNotice();
        }
    }

    /**
     * 검색 keydown
     */
    searchKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }
        
        this.search();
    }

    /**
     * 검색
     */
    search() : void {
        this.search_query = this.search_query_temp.trim();

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.search_query = this.search_query;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        this.getNotice();
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#group_notice_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }    
}
</script>

<style scoped>
	.schLi .schBtn { right:30px; }
	a#addNotice {position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 1110000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(../assets/images/contents/btn_add_fff.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;}
	#noticeScroll .btn_group { margin-right:30px; }
	.section_scroll .delBtn { display:none; }
	.section_scroll.edit .delBtn { display:inline-block }
	#noticeScroll .sortHeader input[type='checkbox']:not(old) + label { margin-top:18px; }
	#noticeScroll input[type='checkbox']:not(old) + label {margin-top: 16px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius:3px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
	#noticeScroll input[type='checkbox']:not(old):checked + label {background: #477fff url('../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;border: 2px solid #477fff;}
	h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
	.settingBg ul.dropdown {width: 170px;left: 280px;top: 60px;}
	.viewGroup { overflow:hidden; }
	#container #section_ce .schedule_box {padding: 0;height:0;}
	#noticeScroll .sortHeader {margin-top: 0}
	.section_scroll .sortHeader .notiCheck { display:none; }
	.section_scroll.edit .sortHeader .notiCheck {height:60px; line-height:60px; font-size:0;display: inline-block; }
	.section_scroll.edit .sortHeader li.notiCheck { position:absolute; left:0;width:130px;top:0; }
	.section_scroll .sortHeader { position:relative;box-sizing:border-box; }
	.section_scroll.edit .sortHeader { padding-left:130px; }
	.section_scroll .notiCheck { width:8%; text-align:center; }
	.section_scroll .sortHeader p.notiCheck { text-align: center;width:100%;}
	.section_scroll .num { width:10%; text-align:center; font-weight:bold; text-index: -20px;}
	.section_scroll.edit .num { text-indent: -60px; }
	.section_scroll .group { width:16%; }
	.section_scroll .title { width: 31%;}
	.section_scroll .time { width:14%; }
	.section_scroll .writtenBy { width:17%; }
	.section_scroll .status { width:12%; }
	.content  { float:left; width:100%; }
	.content .notiListUl > li.new:after {content: 'NEW';display: block;position: absolute;top: 17px;left: 26%;font-size: 10px;font-weight: bold;background: #ff6060;height: 20px;line-height: 20px;border-radius: 5px;padding: 0 5px;color: #fff;}
	.content .notiListUl > li.new .title {padding-left: 45px;}
	.content .notiListUl > li p {display: inline-block;font-weight: bold;float: left;box-sizing: border-box;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
	.content .notiListUl > li p.num, .content .notiListUl > li p.notiCheck { padding-right:0; }
	.content .notiListUl > li { transition:0.2s; }
	.content .notiListUl > li.new:hover { background:#f1f3f5; }
	.content .notiListUl > li p.status a span { color:#477fff; }
	.content .notiListUl > li:hover a { border:2px solid #477fff; padding:0 10px; line-height:29px; height:30px;display:inline-block; color:#477fff; border-radius:5px; }
	.content .notiListUl > li:hover p span { color:#273044 }
	.content .notiListUl > li:hover p.status a:hover { color:#fff; background:#477fff; }
	.content .notiListUl > li:hover p.status a:hover span { color:#fff; }
	.content .notiListUl > li p.status {position: relative;height: 55px;}
	.content .notiListUl > li p.status a {position: absolute;left: 0;opacity: 1;top: 0;z-index: 10000000;display: block;}
	.content .notiListUl > li:hover p.status a {top: 10px;}
	.content .notiListUl > li p span {color: #7c88a2;}
    .content .notiListUl > li.new span {color: #273044;}
    .content .notiListUl { margin-bottom:150px; }
	a.seeNotice { position: absolute; line-height: 55px !important; height: 55px !important; top: 0; right: 0; width: 90%;display: block;border: none !important; z-index: 1000; font-size: 0 !important;background: transparent;}
	a.seeNotice:hover {background: none !important; border: none !important;}
	.content .notiListUl > li { box-sizing:border-box; position:relative; z-index: 0; width:100%; border-bottom:1px solid #f1f3f5;height: 55px;line-height: 55px;}
	.section_scroll.edit .content .notiListUl > li {padding-left:130px; }
	.section_scroll.edit .content .notiListUl > li p.notiCheck {text-align: center;position:absolute; left:0;width:130px;top:0;    display: inline-block; }
    .section_scroll .content .notiListUl > li p.notiCheck { display:none; }
    
    #bt_scheduleBoxNoti { z-index: 1000000 !important; position: fixed; bottom: 40px; right: 100px;}
    .verOneNotice, .verTwoNotice {position: fixed; cursor:pointer;bottom: 40px;right: 100px;opacity: 1;z-index: 10000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 50%;display: block;font-size: 0 !important;width: 60px;height: 60px;background: #477fff url(../assets/images/contents/btn_add_fff.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;background-size: 53px !important;}
    /* 추후 삭제 */
    /* .verTwoNotice { right:200px; font-size:12px !important;font-weight:bold; color:#fff !important; background-image:none !important; }
    .verOneNotice { font-size:12px !important;font-weight:bold; color:#fff !important; background-image:none !important;  } */

    /* 21.07.23 파일첨부 */
    .content .notiListUl > li p .noticeFile_icon { font-size: 0; background: url('../assets/images/contents/ic_file_bk.png') no-repeat center; width: 18px; height: 18px; background-size: 100% auto; display: inline-block; vertical-align: middle; margin-left: 10px; opacity: .6; }
    .content .notiListUl > li:hover .noticeFile_icon { opacity: 1; }
</style>