<template>
    <div class="doc2">
        <div class="docDets">
            
            <!-- 진료과 선택, 진료과가 1개라면 숨김 -->
            <div class="chooseWhich" v-if="computedDoctorExistDepartment != null && computedDoctorExistDepartment.length > 1">
                <select @change="changeDepartment($event.target.value)">

                    <option :value="department.department_code" :key="department.department_code" v-for="department in computedDoctorExistDepartment"
                            :selected="selected_department != null && department.department_code == selected_department.department_code">
                        {{ department.department_name }}
                    </option>

                    <!-- <option value="내과">내과</option>
                    <option value="피부과">피부과</option>
                    <option value="이비인후과">이비인후과</option>
                    <option value="치과">치과</option> -->
                </select>
            </div>

            <!-- 의사 리스트 -->
            <div id="doctor_list_scroll">

                <div id="doctor_list_div" :style="doctor_list_div_style">

                    <!-- 홀수 == 의사, 짝수 == 구분선 -->
                    <div :key="index" v-for="index in computedDepartmentDoctor.length == 0 ? 0 : (2 * computedDepartmentDoctor.length) - 1"
                         :class="{
                             docList    : index % 2 == 1,
                             seper_docs : index % 2 == 0,
                             on         : index % 2 == 1 && computedDepartmentDoctor.length > 0 && selected_doctor != null && 
                                          selected_doctor.doctor_code == computedDepartmentDoctor[Math.floor(index / 2)].doctor_code
                         }" @click="selectDoctor(computedDepartmentDoctor[Math.floor(index / 2)])">
                        
                        <!-- 홀수일때만 보이도록한다 -->
                        <span class="checked" v-if="index % 2 == 1"></span>
                        <p class="docImg" v-if="index % 2 == 1" :style="{ backgroundImage : getDoctorBackgroundImage(computedDepartmentDoctor[Math.floor(index / 2)]) }">의사 사진</p>
                        <h4 v-if="index % 2 == 1">{{ computedDepartmentDoctor[Math.floor(index / 2)].doctor_name }}</h4>
                        <p class="speciality" v-if="index % 2 == 1">{{ computedDepartmentDoctor[Math.floor(index / 2)].doctor_detail.license == 'MEDIC' ? '의료진' : '전문의' }}</p>

                        <!-- TODO 의사별 설정이 아님, 기간 별로 다를 수 있음! -->
                        <div class="howManyPerMins" v-if="index % 2 == 1"> 
                            <p>
                                {{ getHospitalSettingString() }}
                            </p> 
                        </div>

                    </div>

                    <!-- <div class="docList doc_1 on">
                        <span class="checked"></span>
                        <p class="docImg">의사 사진</p>
                        <h4 class="">김동현</h4>
                        <p class="speciality">전문의</p>
                        <div class="howManyPerMins">
                            <p>15분 / 5명 가능</p>
                        </div>
                    </div>

                    <div class="seper_docs"></div>

                    <div class="docList doc_2">
                        <span class="checked"></span>
                        <p class="docImg">의사 사진</p>
                        <h4 class="">이나영</h4>
                        <p class="speciality">전문의</p>
                        <div class="howManyPerMins">
                            <p>15분 / 5명 가능</p>
                        </div>
                    </div> -->

                </div>

            </div>

        </div>

        <div class="simpleUlDescript" :class="{ on : is_scroll == true }">
            
            <!-- 오늘 버튼 -->
            <input type="button" id="" value="오늘" class="toolbar-btn today " @click="selectDate(new Date())"/>
            
            <!-- 이전 날짜, datepicker, 다음 날짜 버튼 -->
            <div class="grp">
                <input type="button" class="toolbar-btn bt-prev mr10" :class="{ disabled : isLeftEnd() == true }" @click="selectDateByOffset(-1)"/>
                <input type="button" class="calFrom" id="reservation_fr_ymd" :value="hodu_date_to_format_string(selected_date, 'YYYY.MM')"/>
                <input type="button" class="toolbar-btn bt-next mr10" :class="{ disabled : isRightEnd() == true }" @click="selectDateByOffset(1)"/>
            </div>

            <!-- 안내 -->
            <div class="grp2">
                <p><span class="dot blue">파란</span> 예약가능</p>
                <p><span class="dot gray">회색</span> 예약불가</p>
            </div>

        </div>

        <div id="available_list_right_scroll">
            <ul class="reserSimpleUl">

                <li :key="new Date(available.date).getTime()" v-for="available in available_list">
                    <a>{{ `${hodu_date_to_format_string(new Date(available.date), 'MM.DD')} ${getDayOfWeekByDate(new Date(available.date))}` }}</a>
                    <p class="date" :class="{ on : new Date(available.date).getFullYear() == new Date().getFullYear() &&
                                                   new Date(available.date).getMonth()    == new Date().getMonth()    &&
                                                   new Date(available.date).getDate()     == new Date().getDate() }">
                        
                        {{ new Date(available.date).getFullYear() == new Date().getFullYear() &&
                           new Date(available.date).getMonth()    == new Date().getMonth()    &&
                           new Date(available.date).getDate()     == new Date().getDate()      ? amPmStringToLocaleAmPmString(`현재 ${hodu_date_to_format_string(new Date(), 'hh:mm a')}`) 
                                                                                               : `D-${getDateDiff(new Date(), new Date(available.date))}` }}

                    </p> <!-- 오늘 날짜라면 현재 시각, 미래 날짜라면 D-1 세팅 -->
                    
                    <div class="visualizedUl">

                        <!-- 셀 -->
                        <a :key="new Date(time.original_timetable).getTime()" v-for="time in available.available_time_info" @click="showAppointmentCreateModal(time)"
                           :class="{ full : time.available == false }" :style="{ width : `${100 / available.available_time_info.length}%` }">
                            <span class="timeSpan">{{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(new Date(time.original_timetable), 'hh:mm a')) }}</span>
                        </a>

                        <!-- 시간대 뿌리기 -->
                        <div class="timeDets">
                            <p :key="number" v-for="number of available.hour_list" 
                               :style="{ 
                                   width : available.available_time_info[0] == null ? 0 
                                                                                    : `${(60 / available.available_time_info[0].reservation_term) * 100 / available.available_time_info.length}%` 
                                }">
                                {{ number > 12 ? number - 12 : number }}
                            </p>
                            <!-- <p>9</p>
                            <p>10</p>
                            <p>11</p>
                            <p>12</p>
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>7</p> -->
                        </div>

                    </div>

                </li>

                <!-- <li class="">
                    <a href="">03.19 목</a>
                    <p class="date on">현재 2:45 PM</p>
                    <div class="visualizedUl">
                        <a  class="full"><span class="timeSpan">9:00 AM</span></a>
                        <a  class="full"><span class="timeSpan">9:15 AM</span></a>
                        <a  class="full"><span class="timeSpan">9:30 AM</span></a>
                        <a  class="full"><span class="timeSpan">9:45 AM</span></a>
                        
                        <a  class="full"><span class="timeSpan">10:00 AM</span></a>
                        <a  class="full"><span class="timeSpan">10:15 AM</span></a>
                        <a  class="full"><span class="timeSpan">10:30 AM</span></a>
                        <a  class="full"><span class="timeSpan">10:45 AM</span></a>

                        <a  class="full"><span class="timeSpan">11:00 AM</span></a>
                        <a  class="full"><span class="timeSpan">11:15 AM</span></a>
                        <a  class="full"><span class="timeSpan">11:30 AM</span></a>
                        <a  class="full"><span class="timeSpan">11:45 AM</span></a>

                        <a  class="full"><span class="timeSpan">12:00 PM</span></a>
                        <a  class="full"><span class="timeSpan">12:15 PM</span></a>
                        <a  class="full"><span class="timeSpan">12:30 PM</span></a>
                        <a  class="full"><span class="timeSpan">12:45 PM</span></a>
                        
                        <a  class="full"><span class="timeSpan">1:00 PM</span></a>
                        <a  class="full"><span class="timeSpan">1:15 PM</span></a>
                        <a  class="full"><span class="timeSpan">1:30 PM</span></a>
                        <a  class="full"><span class="timeSpan">1:45 PM</span></a>

                        <a  class="full"><span class="timeSpan">2:00 PM</span></a>
                        <a  class="full"><span class="timeSpan">2:15 PM</span></a>
                        <a  class="full"><span class="timeSpan">2:30 PM</span></a>
                        <a ><span class="timeSpan">2:45 PM</span></a>

                        <a ><span class="timeSpan">3:00 PM</span></a>
                        <a  class="full"><span class="timeSpan">3:15 PM</span></a>
                        <a ><span class="timeSpan">3:30 PM</span></a>
                        <a ><span class="timeSpan">3:45 PM</span></a>
                        
                        <a  class="full"><span class="timeSpan">4:00 PM</span></a>
                        <a ><span class="timeSpan">4:15 PM</span></a>
                        <a  class="full"><span class="timeSpan">4:30 PM</span></a>
                        <a ><span class="timeSpan">4:45 PM</span></a>

                        <a ><span class="timeSpan">5:00 PM</span></a>
                        <a ><span class="timeSpan">5:15 PM</span></a>
                        <a ><span class="timeSpan">5:30 PM</span></a>
                        <a  class="full"><span class="timeSpan">5:45 PM</span></a>

                        <a ><span class="timeSpan">6:00 PM</span></a>
                        <a ><span class="timeSpan">6:15 PM</span></a>
                        <a ><span class="timeSpan">6:30 PM</span></a>
                        <a ><span class="timeSpan">6:45 PM</span></a>

                        <a ><span class="timeSpan">7:00 PM</span></a>
                        <a ><span class="timeSpan">7:15 PM</span></a>
                        <a ><span class="timeSpan">7:30 PM</span></a>
                        <a  class="full"><span class="timeSpan">7:45 PM</span></a>
                        <div class="timeDets">
                            <p>9</p>
                            <p>10</p>
                            <p>11</p>
                            <p>12</p>
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>7</p>
                        </div>
                    </div>
                    
                </li>

                <li>
                    <a >03.20 금</a>
                    <p class="date">D-1</p>
                    <div class="visualizedUl">
                        <a ><span>9:00 AM</span></a>
                        <a ><span>9:15 AM</span></a>
                        <a ><span>9:30 AM</span></a>
                        <a ><span>9:45 AM</span></a>
                        
                        <a ><span>10:00 AM</span></a>
                        <a ><span>10:15 AM</span></a>
                        <a ><span>10:30 AM</span></a>
                        <a ><span>10:45 AM</span></a>

                        <a ><span>11:00 AM</span></a>
                        <a ><span>11:15 AM</span></a>
                        <a ><span>11:30 AM</span></a>
                        <a ><span>11:45 AM</span></a>

                        <a  class="full"><span>12:00 PM</span></a>
                        <a ><span>12:15 PM</span></a>
                        <a ><span>12:30 PM</span></a>
                        <a ><span>12:45 PM</span></a>
                        
                        <a ><span>1:00 PM</span></a>
                        <a  class="full"><span>1:15 PM</span></a>
                        <a ><span>1:30 PM</span></a>
                        <a ><span>1:45 PM</span></a>

                        <a ><span>2:00 PM</span></a>
                        <a ><span>2:15 PM</span></a>
                        <a  class="full"><span>2:30 PM</span></a>
                        <a  class="full"><span>2:45 PM</span></a>

                        <a ><span>3:00 PM</span></a>
                        <a ><span>3:15 PM</span></a>
                        <a ><span>3:30 PM</span></a>
                        <a ><span>3:45 PM</span></a>
                        
                        <a ><span>4:00 PM</span></a>
                        <a  class="full"><span>4:15 PM</span></a>
                        <a ><span>4:30 PM</span></a>
                        <a ><span>4:45 PM</span></a>

                        <a ><span>5:00 PM</span></a>
                        <a ><span>5:15 PM</span></a>
                        <a ><span>5:30 PM</span></a>
                        <a ><span>5:45 PM</span></a>

                        <a ><span>6:00 PM</span></a>
                        <a ><span>6:15 PM</span></a>
                        <a ><span>6:30 PM</span></a>
                        <a ><span>6:45 PM</span></a>

                        <a ><span>7:00 PM</span></a>
                        <a  class="full"><span>7:15 PM</span></a>
                        <a  class="full"><span>7:30 PM</span></a>
                        <a  class="full"><span>7:45 PM</span></a>
                        <div class="timeDets">
                            <p>9</p>
                            <p>10</p>
                            <p>11</p>
                            <p>12</p>
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>7</p>
                        </div>
                    </div>
                </li>

                <li>
                    <a >03.21 토</a>
                    <p class="date">D-2</p>
                    <div class="visualizedUl">
                        <a ><span>9:00 AM</span></a>
                        <a ><span>9:15 AM</span></a>
                        <a ><span>9:30 AM</span></a>
                        <a ><span>9:45 AM</span></a>
                        
                        <a ><span>10:00 AM</span></a>
                        <a ><span>10:15 AM</span></a>
                        <a ><span>10:30 AM</span></a>
                        <a ><span>10:45 AM</span></a>

                        <a ><span>11:00 AM</span></a>
                        <a ><span>11:15 AM</span></a>
                        <a ><span>11:30 AM</span></a>
                        <a ><span>11:45 AM</span></a>

                        <a ><span>12:00 PM</span></a>
                        <a ><span>12:15 PM</span></a>
                        <a ><span>12:30 PM</span></a>
                        <a ><span>12:45 PM</span></a>
                        
                        <a ><span>1:00 PM</span></a>
                        <a  class="full"><span>1:15 PM</span></a>
                        <a  class="full"><span>1:30 PM</span></a>
                        <a ><span>1:45 PM</span></a>

                        <a  class="full closed"><span>2:00 PM</span></a>
                        <a  class="full closed"><span>2:15 PM</span></a>
                        <a  class="full closed"><span>2:30 PM</span></a>
                        <a  class="full closed"><span>2:45 PM</span></a>

                        <a  class="full closed"><span>3:00 PM</span></a>
                        <a  class="full closed"><span>3:15 PM</span></a>
                        <a  class="full closed"><span>3:30 PM</span></a>
                        <a  class="full closed"><span>3:45 PM</span></a>
                        
                        <a  class="full closed"><span>4:00 PM</span></a>
                        <a  class="full closed"><span>4:15 PM</span></a>
                        <a  class="full closed"><span>4:30 PM</span></a>
                        <a  class="full closed"><span>4:45 PM</span></a>

                        <a  class="full closed"><span>5:00 PM</span></a>
                        <a  class="full closed"><span>5:15 PM</span></a>
                        <a  class="full closed"><span>5:30 PM</span></a>
                        <a  class="full closed"><span>5:45 PM</span></a>

                        <a  class="full closed"><span>6:00 PM</span></a>
                        <a  class="full closed"><span>6:15 PM</span></a>
                        <a  class="full closed"><span>6:30 PM</span></a>
                        <a  class="full closed"><span>6:45 PM</span></a>

                        <a  class="full closed"><span>7:00 PM</span></a>
                        <a  class="full closed"><span>7:15 PM</span></a>
                        <a  class="full closed"><span>7:30 PM</span></a>
                        <a  class="full closed"><span>7:45 PM</span></a>
                        <div class="timeDets">
                            <p>9</p>
                            <p>10</p>
                            <p>11</p>
                            <p>12</p>
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>7</p>
                        </div>
                    </div>
                </li>

                <li>
                    <a >03.23 월</a>
                    <p class="date">D-3</p>
                    <div class="visualizedUl">
                        <a ><span>9:00 AM</span></a>
                        <a ><span>9:15 AM</span></a>
                        <a ><span>9:30 AM</span></a>
                        <a ><span>9:45 AM</span></a>
                        
                        <a ><span>10:00 AM</span></a>
                        <a ><span>10:15 AM</span></a>
                        <a ><span>10:30 AM</span></a>
                        <a ><span>10:45 AM</span></a>

                        <a ><span>11:00 AM</span></a>
                        <a ><span>11:15 AM</span></a>
                        <a ><span>11:30 AM</span></a>
                        <a ><span>11:45 AM</span></a>

                        <a ><span>12:00 PM</span></a>
                        <a ><span>12:15 PM</span></a>
                        <a ><span>12:30 PM</span></a>
                        <a ><span>12:45 PM</span></a>
                        
                        <a ><span>1:00 PM</span></a>
                        <a ><span>1:15 PM</span></a>
                        <a ><span>1:30 PM</span></a>
                        <a ><span>1:45 PM</span></a>

                        <a  class="full"><span>2:00 PM</span></a>
                        <a ><span>2:15 PM</span></a>
                        <a ><span>2:30 PM</span></a>
                        <a ><span>2:45 PM</span></a>

                        <a ><span>3:00 PM</span></a>
                        <a ><span>3:15 PM</span></a>
                        <a ><span>3:30 PM</span></a>
                        <a ><span>3:45 PM</span></a>
                        
                        <a ><span>4:00 PM</span></a>
                        <a ><span>4:15 PM</span></a>
                        <a ><span>4:30 PM</span></a>
                        <a ><span>4:45 PM</span></a>

                        <a ><span>5:00 PM</span></a>
                        <a ><span>5:15 PM</span></a>
                        <a ><span>5:30 PM</span></a>
                        <a ><span>5:45 PM</span></a>

                        <a ><span>6:00 PM</span></a>
                        <a ><span>6:15 PM</span></a>
                        <a ><span>6:30 PM</span></a>
                        <a ><span>6:45 PM</span></a>

                        <a ><span>7:00 PM</span></a>
                        <a ><span>7:15 PM</span></a>
                        <a ><span>7:30 PM</span></a>
                        <a ><span>7:45 PM</span></a>
                        <div class="timeDets">
                            <p>9</p>
                            <p>10</p>
                            <p>11</p>
                            <p>12</p>
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>7</p>
                        </div>
                    </div>
                </li>

                <li>
                    <a >03.24 화</a>
                    <p class="date">D-4</p>
                    <div class="visualizedUl">
                        <a ><span>9:00 AM</span></a>
                        <a ><span>9:15 AM</span></a>
                        <a ><span>9:30 AM</span></a>
                        <a ><span>9:45 AM</span></a>
                        
                        <a ><span>10:00 AM</span></a>
                        <a ><span>10:15 AM</span></a>
                        <a ><span>10:30 AM</span></a>
                        <a ><span>10:45 AM</span></a>

                        <a ><span>11:00 AM</span></a>
                        <a ><span>11:15 AM</span></a>
                        <a ><span>11:30 AM</span></a>
                        <a ><span>11:45 AM</span></a>

                        <a ><span>12:00 PM</span></a>
                        <a ><span>12:15 PM</span></a>
                        <a ><span>12:30 PM</span></a>
                        <a ><span>12:45 PM</span></a>
                        
                        <a ><span>1:00 PM</span></a>
                        <a ><span>1:15 PM</span></a>
                        <a ><span>1:30 PM</span></a>
                        <a ><span>1:45 PM</span></a>

                        <a ><span>2:00 PM</span></a>
                        <a ><span>2:15 PM</span></a>
                        <a ><span>2:30 PM</span></a>
                        <a ><span>2:45 PM</span></a>

                        <a ><span>3:00 PM</span></a>
                        <a ><span>3:15 PM</span></a>
                        <a ><span>3:30 PM</span></a>
                        <a ><span>3:45 PM</span></a>
                        
                        <a ><span>4:00 PM</span></a>
                        <a ><span>4:15 PM</span></a>
                        <a ><span>4:30 PM</span></a>
                        <a ><span>4:45 PM</span></a>

                        <a ><span>5:00 PM</span></a>
                        <a ><span>5:15 PM</span></a>
                        <a ><span>5:30 PM</span></a>
                        <a ><span>5:45 PM</span></a>

                        <a ><span>6:00 PM</span></a>
                        <a ><span>6:15 PM</span></a>
                        <a ><span>6:30 PM</span></a>
                        <a ><span>6:45 PM</span></a>

                        <a ><span>7:00 PM</span></a>
                        <a ><span>7:15 PM</span></a>
                        <a ><span>7:30 PM</span></a>
                        <a ><span>7:45 PM</span></a>
                        <div class="timeDets">
                            <p>9</p>
                            <p>10</p>
                            <p>11</p>
                            <p>12</p>
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>7</p>
                        </div>
                    </div>
                </li>

                <li>
                    <a >03.25 수</a>
                    <p class="date">D-4</p>
                    <div class="visualizedUl">
                        <a ><span>9:00 AM</span></a>
                        <a ><span>9:15 AM</span></a>
                        <a ><span>9:30 AM</span></a>
                        <a ><span>9:45 AM</span></a>
                        
                        <a ><span>10:00 AM</span></a>
                        <a ><span>10:15 AM</span></a>
                        <a ><span>10:30 AM</span></a>
                        <a ><span>10:45 AM</span></a>

                        <a ><span>11:00 AM</span></a>
                        <a ><span>11:15 AM</span></a>
                        <a ><span>11:30 AM</span></a>
                        <a ><span>11:45 AM</span></a>

                        <a ><span>12:00 PM</span></a>
                        <a ><span>12:15 PM</span></a>
                        <a ><span>12:30 PM</span></a>
                        <a ><span>12:45 PM</span></a>
                        
                        <a ><span>1:00 PM</span></a>
                        <a ><span>1:15 PM</span></a>
                        <a ><span>1:30 PM</span></a>
                        <a ><span>1:45 PM</span></a>

                        <a ><span>2:00 PM</span></a>
                        <a ><span>2:15 PM</span></a>
                        <a ><span>2:30 PM</span></a>
                        <a ><span>2:45 PM</span></a>

                        <a ><span>3:00 PM</span></a>
                        <a ><span>3:15 PM</span></a>
                        <a ><span>3:30 PM</span></a>
                        <a ><span>3:45 PM</span></a>
                        
                        <a ><span>4:00 PM</span></a>
                        <a ><span>4:15 PM</span></a>
                        <a ><span>4:30 PM</span></a>
                        <a ><span>4:45 PM</span></a>

                        <a ><span>5:00 PM</span></a>
                        <a ><span>5:15 PM</span></a>
                        <a ><span>5:30 PM</span></a>
                        <a ><span>5:45 PM</span></a>

                        <a ><span>6:00 PM</span></a>
                        <a ><span>6:15 PM</span></a>
                        <a ><span>6:30 PM</span></a>
                        <a ><span>6:45 PM</span></a>

                        <a ><span>7:00 PM</span></a>
                        <a ><span>7:15 PM</span></a>
                        <a ><span>7:30 PM</span></a>
                        <a ><span>7:45 PM</span></a>
                        <div class="timeDets">
                            <p>9</p>
                            <p>10</p>
                            <p>11</p>
                            <p>12</p>
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>7</p>
                        </div>
                    </div>
                </li> -->

            </ul>

            <a class="loadMoreBtn" @click="selectAvailableList(++current_page, false)" v-show="is_page_end == false">더 보기</a>

        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_object, hodu_doc_modal_info } from '@/model/hodudoc';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class AppointmentAvailableListRight extends Mixins(VueHoduCommon) {

    /**
     * 의사가 한 명이라도 존재하는 진료과만 반환
     */
    get computedDoctorExistDepartment() : hodu_doc_object.department_info[] {

        let doctor_exist_department : hodu_doc_object.department_info[] = [];

        if( this.department_info != null && this.doctor_info != null ) {

            for( const department of this.department_info ) {

                if( this.doctor_info.filter(item => item.department_code == department.department_code).length > 0 ) {
                    doctor_exist_department.push(department);
                }

            }

        }

        return doctor_exist_department;
    }

    /**
     * 선택된 진료과의 의사만 반환
     */
    get computedDepartmentDoctor() : hodu_doc_object.doctor_info[] {

        let department_doctor : hodu_doc_object.doctor_info[] = [];

        if( this.selected_department != null && this.doctor_info != null ) {
            const department_doctor_temp = this.doctor_info.filter(item => this.selected_department ? item.department_code == this.selected_department.department_code : false);
            department_doctor = department_doctor.concat(department_doctor_temp);
        }

        return department_doctor;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentCreateModalInfo ?: (params : hodu_doc_modal_info.AppointmentCreateModalInfo) => void;

    @Prop() event_bus !: Vue;
    @Prop() max_date !: Date;

    doctor_list_div_style : string = "";
    selected_department : hodu_doc_object.department_info | null = null;
    selected_doctor : hodu_doc_object.doctor_info | null = null;
    selected_date : Date = new Date();
    current_page : number = 0;
    is_page_end : boolean = false;
    is_scroll : boolean = false;

    appointment_avaiable_promise_list : Promise<void>[] = [];
    available_list_temp : hodu_doc_object.appointment_available_time_info_list[] = [];
    available_list      : hodu_doc_object.appointment_available_time_info_list[] = [];
    
    mounted() : void {
        if( this.event_bus != null ) { 
            this.event_bus.$on('rightAvailableListResize', this.handleResize);
            this.event_bus.$on('defaultDataInit', this.defaultDataInit); 
        }

        // 재조회용
        window['rightSelectAvailableList'] = this.rightSelectAvailableList;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        if( this.doctor_info == null ) { return; }
        const filtered_doctor_list : hodu_doc_object.doctor_info[] = this.doctor_info.filter(item => item.department_code == (this.selected_department ? this.selected_department.department_code : ''));
        const doctor_div_with : number = (filtered_doctor_list.length * 270) + (filtered_doctor_list.length - 1); // 의사 + 구분선 (271d - 1)로 식 변경 가능하지만 이해하기 쉽게 내버려둠
        this.doctor_list_div_style = `width : ${doctor_div_with}px !important;`;

        // @ts-ignore
        $('#doctor_list_scroll').mCustomScrollbar({
            axis : 'x',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
        });

        this.$nextTick(() => {
            const title_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
            const doc_dets_height : number | undefined = $('.docDets').outerHeight();
            const simple_ul_descript_height : number | undefined = $('.simpleUlDescript').outerHeight();

            // @ts-ignore
            $('#available_list_right_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_height ? title_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (doc_dets_height ? doc_dets_height : 0)
                                            - (simple_ul_descript_height ? simple_ul_descript_height : 0),
                callbacks : {
                    whileScrolling: () => {
                        const top_px : number = Math.abs(Number($('#available_list_right_scroll').find('.mCSB_container').css('top').replace(/px/ig, '')));
                        this.is_scroll = top_px > 0; 
                    }
                }
            });
        });
    }

    /**
     * 해당 날짜의 예약 가능, 불가능 여부 정보 조회
     */
    async getAvailableAppointmentTime(date : Date) : Promise<void> {

        if( this.selected_department == null || this.selected_doctor == null ) { return; } 

        date = date instanceof Date ? date : new Date(date);

        const start = moment(date).format('YYYY-MM-DD');              // 조회일
        const department_code = this.selected_doctor.department_code; // 진료과 코드
        const doctor_code = this.selected_doctor.doctor_code;         // 의사 코드

        if( doctor_code == null || doctor_code.length < 1 ) { return; }

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/availability?start=${start}&department_code=${department_code}&doctor_code=${doctor_code}`, API_METHOD.GET)
            .then((response) => {
                const available_list : hodu_doc_object.appointment_available_time_info[] = response.data.data;
                const hour_list : number[] = [];

                for( const available of available_list ) {
                    if( hour_list.indexOf(new Date(available.original_timetable).getHours()) > -1 ) { continue; }
                    hour_list.push(new Date(available.original_timetable).getHours());
                }

                this.available_list_temp.push({
                    "date" : date,
                    "available_time_info" : available_list,
                    "hour_list" : hour_list 
                });
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false); 
            });
    }

    /**
     * 기본 정보 init
     */
    async defaultDataInit() : Promise<void> {
        if( this.computedDoctorExistDepartment.length > 0 ) { this.selected_department = this.computedDoctorExistDepartment[0]; }

        // 예약 가능 시간 API 조회 병렬 처리
        this.selectAvailableList();

        this.$nextTick(() => { this.setScroll(); });
    }

    /**
     * 해당 의사의 available_list 최초 조회
     */
    selectAvailableList(page : number = 0, is_first_doctor : boolean = true) : void {
        if( is_first_doctor == true && this.computedDepartmentDoctor.length > 0 ) { this.selected_doctor = this.computedDepartmentDoctor[0]; }
    
        this.appointment_avaiable_promise_list.splice(0, this.appointment_avaiable_promise_list.length);
        this.available_list_temp.splice(0, this.available_list_temp.length);
        
        // 첫 페이지를 조회하는 경우
        if( page == 0 ) {
            this.current_page = 0; 
            this.is_page_end = false;
            this.available_list.splice(0, this.available_list.length); 
        }

        const selected_date_month_max_date : number = moment(this.selected_date).add('month', 1).set('date', 0).toDate().getDate();

        for( let i = (7 * page); i < (7 * page) + 7; i++ ) {
            const target_date = moment(this.selected_date).set('date', this.selected_date.getDate() + i).toDate();

            // max_date 와 같거나 이후의 날짜인 경우, 해당 월 보다 큰 경우 제외하고, 해당 달의 마지막 날이라면  break;
            if( ( this.max_date.getFullYear()   == target_date.getFullYear() && 
                  this.max_date.getMonth()      == target_date.getMonth()    &&
                  this.max_date.getDate()        < target_date.getDate()  )  ||
                  this.selected_date.getMonth() != target_date.getMonth() ) {
                
                this.is_page_end = true;
                break;
            }

            // 해당 달의 마지막 날짜까지 갔다면 page_end
            if( selected_date_month_max_date == target_date.getDate() ) { this.is_page_end = true; }

            this.appointment_avaiable_promise_list.push(this.getAvailableAppointmentTime(target_date));
        }

        Promise.all(this.appointment_avaiable_promise_list)
            .then(async() => {

                // 날짜 순서대로 sort
                await this.available_list_temp.sort((item1, item2) : number => {
                    
                    if( item1.date.getTime() < item2.date.getTime() ) {
                        return -1;
                    }

                    else if( item1.date.getTime() > item2.date.getTime() ) {
                        return 1;
                    }

                    return 0;
                });
                
                // 리스트에 합치고 temp, promise 리스트는 전부 제거
                this.available_list = this.available_list.concat(JSON.parse(JSON.stringify(this.available_list_temp)));
                this.appointment_avaiable_promise_list.splice(0, this.appointment_avaiable_promise_list.length);
                this.available_list_temp.splice(0, this.available_list_temp.length);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 몇분당 몇명까지 진료가능 한지 텍스트 반환 (오늘 기준으로 밖에 못가져온다)
     */
    getHospitalSettingString() : string {

        if( this.hospital_setting_info == null ) { return ''; }

        const day_of_week_string : string = String(new Date().getDay());

        // day_of_week의 타입은 number지만 string으로 데이터가 넘어와서 이렇게 사용
        const settings : hodu_doc_object.hospital_setting_info[] = this.hospital_setting_info.filter(item => String(item.day_of_week)    == day_of_week_string   &&
                                                                                                    new Date(item.start_date).getTime() <= new Date().getTime() &&
                                                                                                    new Date(item.end_date).getTime()   >= new Date().getTime() )

        if( settings.length < 1 ) { return ''; }

        const setting : hodu_doc_object.hospital_setting_info = settings[0];

        return `${setting.reservation_term}분 / ${setting.reservation_term_by_people}명 가능 (오늘 기준)`;
    }

    /**
     * 의사 사진 URL 반환
     */
    getDoctorBackgroundImage(doc : hodu_doc_object.doctor_info) : string {

        if( doc.doctor_detail.profile_image == null || doc.doctor_detail.profile_image.length < 1 ) {
            return `url(${require("@/assets/images/contents/im_photoB.gif")})`;
        }

        return `url('app_images/${doc.doctor_detail.profile_image}')`;

    }

    /**
     * 진료과 변경
     */
    changeDepartment(value : string) : void {

        for ( const department of this.computedDoctorExistDepartment ) {
            if( department.department_code == value ) { 
                this.selected_department = department;
                this.current_page = 0;
                this.selectAvailableList();
                break;
            } 
        }
    }

    /**
     * 의사 변경
     */
    selectDoctor(doctor : hodu_doc_object.doctor_info) : void {

        // alert(JSON.stringify(doctor));

        this.selected_doctor = JSON.parse(JSON.stringify(doctor));
        this.current_page = 0;
        this.selectAvailableList(this.current_page, false);
    }

    /**
     * 예약 등록 모달
     */
    showAppointmentCreateModal(available : hodu_doc_object.appointment_available_time_info) : void {
        if( available.available == false ) { return; }

        if( this.doSetAppointmentCreateModalInfo ) { 

            this.doSetAppointmentCreateModalInfo({ 
                "show_modal" : true,
                "is_create_one_patient" : false,
                "department_code"       : available.department_code,
                "doctor_code"           : available.doctor_code,
                "original_timetable"    : new Date(available.original_timetable), 
            }); 
        } 
    }

    /**
     * 재 조회
     */
    rightSelectAvailableList() : void {
        for( let i = 0; i <= this.current_page; i++ ) {
            this.selectAvailableList(i, false);
        }
    }

    /**
     * 왼쪽 끝 주간 여부
     */
    isLeftEnd() : boolean {
        return moment(this.selected_date).month() == moment().month();
    }

    /**
     * 오른쪽 끝 주간 여부
     */
    isRightEnd() : boolean {
        return moment(this.selected_date).month() == moment(this.max_date).month();
    }

    /**
     * 날짜 선택
     */
    selectDate(date : Date) : void {
        this.selected_date = date;
        this.current_page = 0;
        this.selectAvailableList(this.current_page, false);
    }

    /**
     * 일 단위 offset 값 만큼 날짜 선택
     */
    selectDateByOffset(date_offset : number) : void {
        
        // 이전 달로 이동할 때 이미 왼쪽 끝이라면 무시 
        if( date_offset < 0 && this.isLeftEnd() ) { return; }

        // 다음 달로 이동할 때 이미 오른쪽 끝이라면 무시
        if( date_offset > 0 && this.isRightEnd() ) { return; }

        // 날짜 이동
        let move_date : Date = moment(this.selected_date).add("month", date_offset)
                                                         .set("date", 1)
                                                         .set("hour", 23)
                                                         .set("minute", 59)
                                                         .set("second", 59)
                                                         .set("millisecond", 999)
                                                         .toDate();

        if( move_date.getTime() < new Date().getTime() ) { move_date = new Date(); }
        
        this.selected_date = move_date;
        this.current_page = 0;
        this.selectAvailableList(this.current_page, false);
    }
    
    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#doctor_list_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#available_list_right_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
</script>

<style scoped>
    .btnBox { padding: 11px 30px 0 0; }
    .bt-prev, .bt-next { margin-top:0 !important; }
    .bt-prev { margin-right:0 !important; }
    .toolbar-btn.today { line-height: 33px; margin-top: 2px !important; transition:0.2s; float: left;}
    .toolbar-btn.today:hover { background-color:#e7eaec !important; }

    .docDets .chooseWhich { padding:0 30px; }
    .docDets .chooseWhich select { border:1px solid #e7e9ea; border-radius: 25px; cursor:pointer; width:100%; height: 45px; font-weight:bold; padding: 0 20px; box-sizing: border-box; font-size: 14px; appearance: none; margin-bottom: 15px; background-image: url(../../assets/images/side_area/bt_open.png); background-position:  97% center; background-repeat:no-repeat}
    .docDets .chooseWhich select:hover { background-color:#f1f3f5; }
    .docDets .chooseWhich select:focus { border-color:#477fff; background-color:#fff; background-image: url(../../assets/images/side_area/bt_close.png);}
    .seper_docs { width:1px;height: 37px;margin-bottom: 13px; background:#e7e9ea; display: inline-block; }
    .docList { position:relative; display:inline-block; opacity:0.5; width: 270px; transition:0.2s;}
    .docList:hover { cursor:pointer; opacity:0.9 } 
    .docList .checked { opacity:0; position:absolute; left:23px;top:0px;width: 24px;height:24px; display:block; border-radius: 50%;background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px 0px; border:2px solid #fff; }
    .docList.on { opacity:1; cursor: default; }
    .docList.on .checked { opacity:1 }
    .docList.doc_2 .docImg { background-image: url(../../assets/images/footer/f2.jpg) }
    .doc2 { overflow:hidden;  transition: 0.1s; width: 0; height: 100%; float: right; min-height: 600px; border-left:1px solid #e7e9ea; box-sizing:border-box; }
    .docDets {padding: 20px 0;border-bottom:1px solid #e7e9ea; overflow: hidden; display:block;width: 100%;  }
    .docImg { display:inline-block; margin-left: 30px; font-size: 0; width: 60px; height: 60px; margin-top:5px; border-radius: 50%; border: 1px solid #f1f3f5; box-sizing:border-box; background-size: cover !important; background-position:center center !important; float:left;margin-right:15px; background-repeat: no-repeat; background-image: url(../../assets/images/footer/f9.jpg)}
    .docDets h4 { display: block; font-size: 14px;height: 28px;padding-top: 5px; padding-right:20px; box-sizing:border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
    .docDets .speciality { display: inline-block;border: 1px solid #294661;padding: 3px 10px;font-weight: bold;border-radius: 10px; margin-bottom:8px; }
    .howManyPerMins { display: block;padding-right:20px; box-sizing:border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .offDays .off { display:inline-block; padding: 5px 10px; font-weight:bold; color: #ff6060; background-color:#fff0f0; border-radius: 15px; margin-right: 10px;; }
    
    .reservation_create.leftOn .doc2 { width: 40%; display:block; }
    /* .reservation_create.leftOn .settingBg .left { margin-left:0; left: 40%; position:absolute; } */

    .simpleUlDescript { padding: 25px 30px 15px; overflow: hidden;text-align: center; border-bottom:1px solid #e7e9ea; }
    .simpleUlDescript.on { border-color: #fff; box-shadow: 0 5px 7px rgba(0,0,0,0.1); }
    .simpleUlDescript .grp { width: auto;display: inline-block; }
    .simpleUlDescript .toolbar-btn.today { margin-left:0; }
    .simpleUlDescript .calFrom { font-size: 16px; font-weight:bold; background:#fff; float:left;margin-top:8px; margin-right:10px; margin-left:10px; cursor: default; }
    .simpleUlDescript .dot { float:left; border-radius: 5px; width: 8px; height:8px; background:#477fff;border:1px solid #1f5dea; font-size: 0; display: inline-block; margin-right:10px; margin-top:1px; }
    .simpleUlDescript .dot.gray { background: #ebedf1; border-color:#d0d7dc }
    .simpleUlDescript .grp2 { float:right; }
    .simpleUlDescript .grp2 p {margin: 5px 0;}

    .reserSimpleUl { padding: 30px 40px 25px; }
    .reserSimpleUl > li {padding: 0 0 20px; margin-bottom: 40px;}
    .reserSimpleUl li > a {  font-weight:bold; font-size: 12px;padding-bottom:7px; display:inline-block }
    .reserSimpleUl li > .date { display:inline-block; float: right; font-size: 11px; font-weight:bold; margin-top: 1px; opacity:0.3}
    .reserSimpleUl li > .date.on { color:#477fff; opacity:1; }
    .visualizedUl a.full { background:#477fff; cursor: default;  }
    .visualizedUl a.full:hover span { background:#fff; border-color:#d0d4dc; color:#b5b8bf }
    .visualizedUl a.full { background: #ebedf1;}
    .visualizedUl a.full:hover {background: #d6dae2;}
    .visualizedUl a.full.closed:hover { background: #ebedf1}
    .visualizedUl a.full.closed:hover span { display:none; }
    .visualizedUl a span { display:none; width: 55px; text-align:center; }
    .visualizedUl a:hover span { display:block; color:#477fff; background:#fff; border-color:#477fff; }
    .visualizedUl a:hover {background: #1f5dea;}
    .visualizedUl a { background:#477fff; height: 15px; }
    .visualizedUl a { float:left; position:relative; width: 2.2727272727%; }
    .visualizedUl a span { position:absolute; top:-27px;border:1px solid #cbd1e0; font-size:11px; border-radius: 2px; padding: 3px; background: #fff; font-weight:bold; }
    .visualizedUl { position:relative; float:left;width:100%; box-sizing:border-box; padding: 5px 0; background:#fff; border-top:1px solid #dfe3e6;border-bottom:1px solid #dfe3e6; }
    .reserSimpleUl .on .visualizedUl { background:#477fff; }
    .visualizedUl:hover .timeDets { opacity:0.5; }
    .visualizedUl .timeDets { opacity: 0; transition:0.2s; position:absolute;bottom:-16px; overflow:hidden; width:100%; box-sizing:border-box; left:0; }
    .visualizedUl .timeDets p {display: inline-block;width: 9.09%;float: left; font-size:11px; font-weight:bold; }
    
    .loadMoreBtn { display:block; text-align: center; border-radius: 30px; width: 100px; transition:0.2s; line-height:40px; height:40px;margin:0 auto;background:#f1f3f5; border:1px solid #dfe3e6; font-size: 13px; font-weight:bold; margin-bottom: 50px; }
    .loadMoreBtn:hover { background:#fff; color:#477fff; }

    input.toolbar-btn.bt-prev.mr10.disabled.disabled { opacity: 0.3; }  
    input.toolbar-btn.bt-next.mr10.disabled { opacity: 0.3; }  
    input.toolbar-btn.bt-prev.mr10.disabled.disabled:hover { cursor: default; background-color: #fff; }  
    input.toolbar-btn.bt-next.mr10.disabled:hover { cursor: default; background-color: #fff; }

</style>