<template>
    <div id="chatting" class="section_ce_fix" :class="{ 
        rm : is_chatting_room_manager == true, 
        leftDivClosed : connected_channel != null,
        rightDivClosed : connected_channel == null || is_right_div_closed == true
    }"> 

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />
                        
        <div class="title_box main-title">
            <a href="#" class="bt_back" @click.prevent="movePrevPageAndChattingRoomExit"><span class="blind">이전화면 가기</span></a>
            
            <h3 class="title_doc" id="viewTitle">채팅</h3>
            
            <p class="btn_group" v-if="connected_channel != null">
                <input type="button" class="mag_btn" :class="{ on : is_right_div_closed == false }" @click.prevent="is_right_div_closed = !is_right_div_closed" />
            </p>
        </div>

        <div class="content">
            <div class="forRel">
                <ChattingLeft 
                    :event_bus="event_bus"
                    :channels="channels"
                    :connected_channel="connected_channel"
                    :chat_list="chat_list"
                    @join="join"
                    @send="send"
                    @setIsSendMyMessage="(flag) => { is_send_my_message = flag }"
                    @movePrevPageAndChattingRoomExit="movePrevPageAndChattingRoomExit"
                    @openWebSocket="openWebSocket"
                    @closeWebSocket="closeWebSocket" />

                <ChattingRight 
                    :event_bus="event_bus"
                    :connected_channel="connected_channel"
                    :users_online="users_online"
                    :is_chatting_room_manager="is_chatting_room_manager"
                    @invite="invite"
                    v-if="connected_channel != null"/>
            </div>

            <input type="button" class="addBtn" title="채팅방 생성"  @click="showChattingRoomCreateModal"/>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, CRUD_TYPE, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_color } from '@/common/color';

import ChattingLeft  from '@/components/chatting/ChattingLeft.vue'
import ChattingRight from '@/components/chatting/ChattingRight.vue'

import { chat_interface, chat_enum, chat_modal_interface } from '@/model/chat';

import { hodu_web_socket } from '@/lib/HoduWebSocket';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ChattingLeft, ChattingRight,
        ResizeObserver
    },
})
export default class ChattingList extends Mixins(VueHoduCommon) {
    
    FORCE_CLOSE : number = 1000;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetChattingRoomCreateModalInfo ?: (params : chat_modal_interface.ChattingRoomCreateModalInfo) => void;
    @ModalInfo.Action doSetChattingRoomInfoModalInfo   ?: (params : chat_modal_interface.ChattingRoomInfoModalInfo) => void;

    event_bus : Vue = new Vue();

    channels : chat_interface.I_CHANNEL_INFO[] = [];
    connected_channel : chat_interface.I_CHANNEL_INFO | null = null;
    chat_list : chat_interface.I_CHAT_MESSAGE[] = [];
    users_online : Object = {};

    is_right_div_closed : boolean = false;
    is_chatting_room_manager : boolean = false;
    is_send_my_message : boolean = false;

    channel_interval ?: number

    first_msg_list : boolean = false; 
    is_closed_not_forcely : boolean = false;
    
    async mounted() : Promise<void> {

        const vue = this;
        
        // // 채팅 내용으로 검색
        // $(".schBtn").click(function(){
        //     $(this).parent().parent().find(".searchDiv").addClass("on");
        // });

        // // 채팅 내용으로 검색 닫기
        // $(".leftDiv .closeBtn").click(function(){
        //     $(".leftDiv .searchDiv").removeClass("on");
        // });

        // // 수정버튼 클릭 시
        // $(".leftDiv .btns .input_btn.edit").click(function(){
        //     $(".section_ce_fix").addClass("edit");
        // });

        // // 수정버튼 클릭 후 수정모드 닫기
        // $(".leftDiv .btns .input_btn.cancel").click(function(){
        //     $(".section_ce_fix").removeClass("edit");
        // });


        // // 친구와 그룹 열고 닫기
        // $(".mag_btn").click(function(){
        //     $(this).toggleClass("on");
        //     $(".section_ce_fix").toggleClass("rightDivClosed");

        // });

        // // .rightDiv .whenClosed 클릭시 친구 그룹 다시 열기
        // $(".rightDiv .whenClosed").click(function(){
        //     $(".mag_btn").removeClass("on");
        //     $(".section_ce_fix").removeClass("rightDivClosed");

        // });

        // // .chatroomBtns 친구, 사진, 파일 버튼
        // $(".chatroomBtns input").click(function(){

        //     var getIndex3 = $(this).index();

        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");

        //     $(".rightDiv").removeClass("mate");
        //     $(".rightDiv").removeClass("img");
        //     $(".rightDiv").removeClass("file");

        //     if ( getIndex3 == 0 ) {
        //         $(".rightDiv").addClass("mate");
        //     } else if ( getIndex3 == 1 ) {
        //         $(".rightDiv").addClass("img");
        //     } else {
        //         $(".rightDiv").addClass("file");
        //     }
        // });



        // // .goChatroom 채팅룸 가기 클릭 시
        // $(".goChatroom").click(function(){

        //     // 인덱스 찾기
        //     var getIndex = $(this).parent().index();

        //     $(".section_ce_fix").removeClass("rm");
        //     $(".section_ce_fix").removeClass("oneCt");

        //     // 방장 일 시 .section_ce_fix에 rm 클래스 추가
        //     if( getIndex == 3 ) {
        //         $(".section_ce_fix").addClass("rm");
        //     } else if ( getIndex == 5 ) {
        //         $(".section_ce_fix").addClass("oneCt");
        //     } else {
        //         $(".section_ce_fix").removeClass("rm");

        //     }

        //     $(".section_ce_fix").addClass("leftDivClosed");

        //     $(this).parent().siblings().removeClass("on");

        //     // 선택된 .chatroomUl li에 on넣기
        //     $(this).parent().addClass("on");
            
        // });

        // .chatroomMenu 클릭 시 채팅룸 닫고 채팅ul 다시 열기
        // $(".chatroomMenu").click(function(){
        //     $(".section_ce_fix").removeClass("leftDivClosed");
        //     // .chatroomUl li에 on 빼기
        //     $(".chatroomUl li").removeClass("on");
        // });


        // .mainPanel .addExtra 클릭 시 chatroomPanel 넓히기
        // $(".mainPanel .addExtra").click(function(){
        //     $(".chatroomPanel").toggleClass("on");
        //     $(this).toggleClass("on");
        // });

        // changeChatroomBgBtn 클릭시 chatroomBgDiv 열기 (배경색 바꾸기)
        // $(".changeChatroomBgBtn").click(function(){
        //     $(".chatroomBgDiv").addClass("on");

        // });

        // .addEmojis 클릭 시
        // $(".addEmojis").click(function(){
        //     $(this).toggleClass("on");

        // });

        // chatroomBgDiv a 클릭시
        // $(".chatroomBgDiv a").click(function(){
        //     var getIndexClr = $(this).index();
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");

        //     // 색 변경
        //     for( let i = 0; i < 11; i++ ) {
        //         if( $('.chatroom').hasClass(`bgc${i}`) ) {
        //             $('.chatroom').removeClass(`bgc${i}`)
        //             break;
        //         }
        //     }
        //     // 폴더 사라짐 방지
        //     $('.chatroom').addClass(`bgc${getIndexClr}`);
        // });

        // // .chatroomBgDiv .closeBtn 닫기 버튼
        // $(".chatroomBgDiv .closeBtn").click(function(){
        //     $(".chatroomBgDiv").removeClass("on");

        // });

        // // .rightDiv 속 .magBtn 클릭 시 친구 또는 그룹 ul보이기
        // $(".rightDiv .btns input").click(function(){

        //     // 인덱스 찾기
        //     var getIndex = $(this).index();

        //     // 버튼에 온 넣고 빼기
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");

        //     // 첫번째일때 친구 리스트 보이기
        //     if ( getIndex == 0 ) {

        //         $(".magUl").removeClass("grp");
        //         $(".magUl").addClass("mate");

        //     } else {

        //         // 그룹일때 그룹 보이기
        //         $(".magUl").removeClass("mate");
        //         $(".magUl").addClass("grp");
                
        //     }

        // });

        // // 그룹 > 멤버보기 클릭 시 (채팅룸 만들기 없애기) 
        // $(".grpUl > li .seeMembers").click(function(){
        //     var getIndex1 = $(this).parent().parent().parent().index();

        //     $(this).toggleClass("on");

        //     $(".grpUl > li").eq(getIndex1).find(">div").toggleClass("on");
        //     $(".grpUl > li").eq(getIndex1).toggleClass("on");
        // });

        // // 팀 보기
        // $(".grpList .oac").click(function(){
        //     $(".teamList").addClass("teamOn");
        // });

        // // 팀 멤버보기
        // $(".teamList .grpUl .oac").click(function(){
        //     $(this).toggleClass("on");
        //     $(this).parent().siblings().toggleClass("on");
        //     $(this).parent().parent().toggleClass("on");
        // });

        // // 팀 빠져나가기
        // $(".teamList .prev").click(function(){
        //     $(".teamList").removeClass("teamOn");
        // });

        // // rightDiv 수정 클릭 시
        // $(".rightDiv .editBtn").click(function(){
        //     $(this).parent().parent().addClass("editMode");
        //     $(".rightDiv").addClass("editMode");

        // });
        // $(".rightDiv .cancelBtn").click(function(){
        //     $(this).parent().parent().removeClass("editMode");
        //     $(".rightDiv").removeClass("editMode");

        // });

        // // .ctMenu
        // $(".ctMenu").click(function(){
        //     $(this).toggleClass("on");
        //     $(".rightSettingDiv").toggleClass("on");
        // });

        // // .addBtn
        // $(".addBtn").click(function(){
        //     $("#chatting_room_invite_confirm_modal").removeClass("ps");
        // });


        // $(".section_ce_fix .btns2 .edit").click(function(){
        //     $(this).parent().addClass("editMode");
        //     $(".chatroom").addClass("editMode");

        // });


        // $(".section_ce_fix .btns2 .cancel").click(function(){
        //     $(this).parent().removeClass("editMode");
        //     $(".chatroom").removeClass("editMode");

        // });

        await this.myChannelList()
        this.channel_interval = window.setInterval(() => {
            if( this.connected_channel != null ) return;
            window['myNewMessage']?.();
            this.myChannelList();
        }, 30000);
    }

    /**
     * 웹소켓 닫기
     */
    beforeDestroy() : void {
        this.closeWebSocket();
        clearInterval(this.channel_interval);
    }

    /**
     * 채널 리스트 가져오기
     */
    async myChannelList() : Promise<void> {

        try {

            // 친구 정보 조회
            await this.getFriends(false);

            const blocked_friends = this.friends.filter(friend => friend.is_block == true);
            const blocked_friends_user_ids = Array.from(blocked_friends, x => x.friend_user_id);

            const channel_response = await this.hodu_api_call('api/v1/chat/channel_info/my', API_METHOD.GET, null, false);

            console.log(channel_response);
            this.channels.splice(0, this.channels.length);
            this.channels = this.channels.concat(channel_response.data.data.channels);

            // 차단된 친구와 1:1 채팅방인경우 그냥 숨긴다
            if( blocked_friends.length > 0 ) {
                console.log(JSON.stringify(blocked_friends));
                this.channels = this.channels.filter(channel => 
                    (channel.users == null || (channel.users != null && ( channel.users.length != 2 || ( channel.users.length == 2 &&
                        channel.users.filter(user => user.user_id != String(this.user_id) && blocked_friends_user_ids.indexOf(Number(user.user_id)) == -1).length > 0
                    ))))
                );
            }

            this.channels.sort((o1, o2) : number => {

                if( o1.last_msg == null && o2.last_msg == null ) {
                    return 0;
                }

                if( o1.last_msg == null && o2.last_msg != null ) {
                    return 1;
                }

                if( o1.last_msg != null && o2.last_msg == null ) {
                    return -1;
                }

                if( o1.last_msg != null && o2.last_msg != null ) {
                    const o1_date : Date = new Date(o1.last_msg['send_date']);
                    const o2_date : Date = new Date(o2.last_msg['send_date']);

                    if( o1_date.getTime() == o2_date.getTime() ) return 0;
                    if( o1_date.getTime() > o2_date.getTime() ) return -1;
                    if( o1_date.getTime() < o2_date.getTime() ) return 1;
                }

                return 0;
            });

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
    }

    /**
     * 채널 참여
     */
    async join(channel : chat_interface.I_CHANNEL_INFO) : Promise<void> {

        try {
            hodu_web_socket.close();

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${channel.channel_uid}/join`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.channel ) {
                throw new Error("채팅방 참여 실패");
            }

            this.connected_channel = response.data.data.channel;
            
            if( this.connected_channel != null && this.connected_channel.users != null ) {

                for( const user of this.connected_channel.users ) {
                    if( Number(user.user_id) != this.user_id ) continue;
                    this.is_chatting_room_manager = Number(user.user_id) == this.user_id && user.user_role == 'OWNER';
                    break;
                }

            }

            if( this.connected_channel == null || this.connected_channel.channel_url == null || this.connected_channel.channel_url.length < 1 ) {
                throw new Error("채팅방 참여 실패");
            }

            this.openWebSocket(response.data.data.channel.channel_url);

        } catch(e) {
            this.hodu_show_dialog('cancel', "채팅방 참여 실패", ['확인']);
        }
        
    }

    /**
     * 웹 소켓 열기
     */
    openWebSocket(url : string, is_retry : boolean = false) : void {
        console.log(`TRY CONNECT WEB SOCKET : ${url}`);

        const device_uid = sessionStorage.getItem("device_uid");
        const session_token = sessionStorage.getItem("session_token");

        if( device_uid == null || session_token == null ) { return; }

        // 재시도라면 원래 URL 그대로 사용
        if( is_retry == false ) {
 
            url = url.replace(/https:/ig, 'wss:').replace(/http:/ig, 'ws:');

            // https 환경에서는 wss 사용해야함
            if( new RegExp("https:").test(location.protocol) == true ) {
                const url_class : URL = new URL(url);    
                let port_number : number = Number(url_class.port);
                
                port_number += 10;

                url_class.port = String(port_number);

                url = url_class.toString().replace(/ws:/ig, "wss:");
                url = url.replace("app.hoduware.com", "chat.hodu.app");
            }

        }

        try {
            hodu_web_socket.connect(`${url}?device-uid=${device_uid}&authorization=${session_token}`);
        } catch(e) {
            this.hodu_show_dialog('cancel', "채팅방 접속 실패", ['확인'], [() => { 
                this.connected_channel = null;
                this.is_chatting_room_manager = false; 
            }]);
            return;
        }

        // 웹소켓 콜백 등록
        hodu_web_socket.onopen(this.webSocketOnOpen);

        hodu_web_socket.onerror((event : Event) => {
            console.log("WEB SOCKET ERROR");
            hodu_web_socket.close();
        });

        hodu_web_socket.onclose((event : CloseEvent) => {
            console.log(`CLOSE WEB SOCKET : ${hodu_web_socket.getWebSocket()?.url}`);

            this.users_online = JSON.parse(JSON.stringify({}));

            // 채널 접속 중에 끊겼다면 재시도
            if( this.connected_channel != null && event.code != this.FORCE_CLOSE ) {
                this.is_closed_not_forcely = true;
                this.openWebSocket(url, true);
            } else {
                this.chat_list.splice(0, this.chat_list.length);
            }
        });

    }

    /**
     * 웹소켓 onopen 콜백
     */
    webSocketOnOpen(event : Event) : void {
        console.log(`OPEN WEB SOCKET : ${hodu_web_socket.getWebSocket()?.url}`);
        hodu_web_socket.onmessage(this.webSocketOnMessage);
    }

    /**
     * 웹소켓 onmessage 콜백
     */
    webSocketOnMessage({data}) : void {

        // 데이터 수신
        data = JSON.parse(data);
        console.log(data);

        switch( data.sub_type ) {
            /**
             * 메세지 리스트
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_LIST:
                
                // 끊겼다가 들어왔다면
                if( this.is_closed_not_forcely == true ) {
                    this.is_closed_not_forcely = false;
                    
                    // 데이터가 완전히 비었다면 다 삭제 
                    if( data.data.length < 1 ) {
                        this.chat_list.splice(0, this.chat_list.length);
                        return;
                    }

                    // 기존 채팅 리스트가 비어있었다면 전부 추가
                    if( this.chat_list.length < 1 ) {
                        this.chat_list = this.chat_list.concat(data.data);
                        this.read(data.data[0].idx); 
                        return;
                    }

                    for( const chat of data.data ) {
                        if( chat.idx > (this.chat_list[0].idx ?? 0) ) {
                            this.chat_list.unshift(chat);
                        }
                    }
                    this.read(data.data[0].idx); 
                    return;
                }

                this.chat_list = this.chat_list.concat(data.data);
                
                if( data.data.length < 50 ) {
                    this.event_bus.$emit('setPageEnd', true);
                }

                this.readMaxIdx();
                console.log("Chatting:webSocketOnMessage:MSG_LIST");
                
                if( this.first_msg_list == false ) {
                    this.first_msg_list = true;
                    this.$nextTick(() => { 
                        console.log("Chatting:webSocketOnMessage:MSG_LIST:scrollInit");
                        this.event_bus.$emit('scrollInit'); 
                    });
                }
                break;

            /**
             * 메세지
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG:
                this.chat_list.push(data);
                this.chat_list.sort((o1, o2) : number => {
                    
                    if( o1.send_date! > o2.send_date! ) {
                        return -1;
                    }
                    
                    if( o1.send_date! < o2.send_date! ) {
                        return 1;
                    }

                    return 0;
                })
                this.read(data.idx);

                /* eslint no-case-declarations: "off"*/
                let is_bottom : boolean = false;
                const scroll_container = document.getElementById('chatroom_scroll')
                if( scroll_container != null ) {
                    const height = $(scroll_container).innerHeight() 
                    const scrollY = $(scroll_container).scrollTop();
                    const scrollHeight = scroll_container.scrollHeight;

                    if( height != null && scrollY != null ) {
                        is_bottom = (height + scrollY) == scrollHeight;
                    }
                    
                }
                

                if( (data.user_id == this.user_id && this.is_send_my_message == true) || is_bottom ) {
                    this.event_bus.$emit('scrollBottom');
                    this.is_send_my_message = false;
                }

                // 이미지 또는 파일인 경우 이미지 리스트, 파일 리스트에 추가시킨다
                if( data.data != null && data.data.files != null && data.data.files.length > 0 ) {
                    this.event_bus.$emit('imageOrFileAdd', data.data.files);
                }
                break;

            /**
             * 메세지 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_DEL:
                if( this.chat_list.filter(chat => chat.idx == data.data).length < 1 ) { return; }

                this.chat_list.splice(this.chat_list.indexOf(this.chat_list.filter(chat => chat.idx == data.data)[0]), 1);
                break;

            /**
             * 메세지 전체 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_DEL_ALL:
                this.chat_list.splice(0, this.chat_list.length);
                break;

            /**
             * 이미지 전체 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_DEL_ALL_IMAGE:
                this.event_bus.$emit('imageOrFileDelete', 'IMAGE');
                break;

            /**
             * 파일 전체 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_DEL_ALL_FILE:
                this.event_bus.$emit('imageOrFileDelete', 'FILE');
                break;

            /**
             * 메시지 읽음여부
             */
            case chat_enum.MSG_CHANNEL_TYPE.READ:
                if( this.connected_channel == null ) { return; }

                if( this.connected_channel.users_read == null ) {
                    this.connected_channel.users_read = {};
                }

                this.connected_channel.users_read[`${data.user_id}`] = data.data;
                this.connected_channel.users_read = JSON.parse(JSON.stringify(this.connected_channel.users_read));
                window['myNewMessage']?.();
                break;

            /**
             * 멤버 온라인
             */
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_ONLINE:
                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }
                
                this.users_online[`${data.user_id}`] = String(data.send_date);
                this.users_online = JSON.parse(JSON.stringify(this.users_online));
                break;

            /**
             * 멤버 오프라인
             */
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_OFFLINE:
                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }

                delete(this.users_online[`${data.user_id}`]);
                this.users_online = JSON.parse(JSON.stringify(this.users_online));
                break;

            /**
             * 멤버 가입 && 멤버 역할 변경
             */
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_JOIN:
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_MODIFY:
                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }

                if ( this.connected_channel.users.filter(user => user.user_id == data.data.user_id)[0] == null ) {
                    this.connected_channel?.users?.push(data.data);
                    return;
                }

                this.connected_channel.users.splice(
                    this.connected_channel.users.indexOf(this.connected_channel.users.filter(user => user.user_id == data.data.user_id)[0]),
                    1,
                    data.data
                );

                if( this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id)[0] != null ) {
                    this.is_chatting_room_manager = ("OWNER" == this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id)[0].user_role);
                }

                break;

            /**
             * 멤버 강퇴 && 멤버 나감
             */
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_KICK:
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_LEAVE:
                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }
                
                if( data.data == this.user_id ) {
                    if( data.sub_type == chat_enum.MSG_CHANNEL_TYPE.MEMBER_KICK ) {
                        this.hodu_show_dialog("cancel", "채팅방에서 강제 퇴장 처리 되었습니다", ["확인"]);
                    }
                    this.movePrevPageAndChattingRoomExit();
                }

                for( let i = 0; i < this.connected_channel.users.length; i++ ) {
                    if( this.connected_channel.users[i].user_id == data.data ) {
                        this.connected_channel.users.splice(i, 1);
                        delete(this.connected_channel.users_read![`${data.data}`]);
                        break;
                    }
                }
                break;

            /**
             * 채팅방 수정
             */
            case chat_enum.MSG_CHANNEL_TYPE.MODIFY:
                this.connected_channel = JSON.parse(JSON.stringify(data.data));
                break;

            /**
             * 채팅방 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.DESTROY:
                this.hodu_show_dialog("cancel", "채팅방이 삭제 되었습니다", ["확인"]);
                this.movePrevPageAndChattingRoomExit();
                break;
        }

    }

    /**
     * 웹 소켓 닫기
     */
    closeWebSocket() : void {
        hodu_web_socket.close(this.FORCE_CLOSE);
    }

    /**
     * 웹 소켓에 데이터 송신
     */
    send(data : string) : void {
        console.log(data);
        hodu_web_socket.send(data);
    }

    /**
     * 읽기 처리
     */
    read(idx: number) {

        this.send(JSON.stringify({
            type : 'CHANNEL',
            sub_type : chat_enum.MSG_CHANNEL_TYPE.READ,
            data : idx
        }));

    }

    /**
     * 마지막 index 읽기처리
     */
    readMaxIdx() {
        this.read(this.connected_channel?.msg_idx ?? 0);
    }

    /**
     * 채팅방 초대
     */
    invite() { 
        
        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : "채팅방 초대",
            use_user_select_only : true,
            callback : async(selected_info) => {
                console.log(selected_info);

                try {
                    const user_infos : any[] = [];
                    for( const user_id of selected_info.selected_user_ids ) {
                        user_infos.push({
                            user_id: String(user_id), 
                            user_name: ""
                        });
                    }

                    if( this.connected_channel != null && this.connected_channel.users != null && this.connected_channel.users.length == 1 ) {

                        // 차단한 상대와의 1:1채팅은 불가능
                        await this.getFriends(false);

                        const blocked_friends = this.friends.filter(friend => friend.is_block == true);
                        const blocked_friends_user_ids = Array.from(blocked_friends, x => x.friend_user_id);

                        if( selected_info.selected_user_ids.length == 1 && blocked_friends_user_ids.indexOf(selected_info.selected_user_ids[0]) > -1 ) {
                            this.hodu_show_dialog("cancel", "차단한 상대와 1:1 채팅은 불가능합니다", ['확인']);
                            return;
                        }

                    }
                    
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/invite`, API_METHOD.POST, user_infos);

                    console.log(response);
                
                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 초대 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 초대 중 오류 발생", ['확인']);
                }
            }
        });
        
    }

    /**
     * 채팅방으로 이동
     */
    moveChattingRoom(room_id : string) : void {
        this.hodu_router_push(`/chatting/${new Date().getTime()}/${room_id}`)
    }

    /**
     * 임시 페이지로 이동
     */
    moveChattingTempPage() : void {
        this.hodu_router_push(`/chatting/${new Date().getTime()}/temp-page`);
    }

    /**
     * 모달 열기
     */
    async showChattingRoomCreateModal() : Promise<void> {
        
        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : "채팅방 생성",
            use_user_select_only : true,
            callback : async(selected_info) => {
                console.log(selected_info);

                try {

                    // 차단한 상대와의 1:1채팅은 불가능
                    await this.getFriends(false);

                    const blocked_friends = this.friends.filter(friend => friend.is_block == true);
                    const blocked_friends_user_ids = Array.from(blocked_friends, x => x.friend_user_id);

                    if( selected_info.selected_user_ids.length == 1 && blocked_friends_user_ids.indexOf(selected_info.selected_user_ids[0]) > -1 ) {
                        this.hodu_show_dialog("cancel", "차단한 상대와 1:1 채팅은 불가능합니다", ['확인']);
                        return;
                    }

                    const user_infos : any[] = [];
                    for( const user_id of selected_info.selected_user_ids ) {
                        user_infos.push({
                            user_id: String(user_id), 
                            user_name: ""
                        });
                    }

                    const post_data : chat_interface.I_CHANNEL_CREATE_INFO = {
                        channel_info : {
                            type : 'CHAT',                 
                            sub_type : OWNER_TYPE.PERSONAL,
                            ref_id : '',
                            is_private : true,
                            info: { "title": '' }
                        },
                        invite_user_infos : user_infos ? user_infos : []
                    }
                    
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/`, API_METHOD.POST, post_data);

                    console.log(response);
                
                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.channel ) {
                        throw new Error("채팅방 등록 실패");
                    }

                    const channel = response.data.data.channel;

                    this.myChannelList();
                    this.connected_channel = channel;

                    console.log(channel);

                    // 해당 방의 관리자인지 구해야함
                    this.is_chatting_room_manager = false;
                    for( const user of channel.users ) {
                        if( user.user_id == this.user_id ) {
                            this.is_chatting_room_manager = (user.user_role == 'OWNER');
                            break;
                        }
                    }

                    this.openWebSocket(channel.channel_url);

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 등록 중 오류 발생", ['확인']);
                }
            }
        });
    }

    /**
     * 채팅방 설정 모달
     */
    showChattingRoomInfoModal() : void {
        if( this.doSetChattingRoomInfoModalInfo == null ) { return; }

        this.doSetChattingRoomInfoModalInfo({
            show_modal : true
        });
    }

    /**
     * 뒤로 가기
     */
    movePrevPageAndChattingRoomExit() : void {

        if( this.connected_channel == null ) {
            this.movePrevPage();
            return;
        }

        this.connected_channel = null;
        this.is_chatting_room_manager = false;
        this.is_right_div_closed = false;
        this.first_msg_list = false;
        this.closeWebSocket();
        this.myChannelList();
    }

    /**
     * 리사이즈 감지 
     */
    handleResize() : void {
        this.event_bus.$emit('handleLeftResize');
        this.event_bus.$emit('handleRightResize');
    }

}
</script>

<style scoped>
    .modalDiv {z-index: 100000000; display: none; position: absolute;width: 100%; height: 100%; background: transparent; left:0;top:0; }
    .modalDiv .bg { display: block;width: 100%; height: 100%; background: black; opacity: 0.1; }
    .modalDiv .modal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-280px; margin-left:-400px; width:800px; height: 560px; background:#fff; }
    .modalDiv.on { display: block }
    
    /* 채팅룸 만들기  */
    .crChtrm .titleBox { padding: 0 30px; border-bottom: 1px solid #e7e9ea; }
    .crChtrm .titleBox h1 { font-size: 20px; font-weight: bold; height: 90px; line-height: 110px; }
    .crChtrm .modal_content { overflow: hidden; height: 400px;border-bottom: 1px solid #e7e9ea;  }
    .crChtrm .btns { overflow: hidden;  }
    .crChtrm .btns input { width: 50%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold; }
    .ctSettingDiv  { display: block; width: 100%;height: 100%; box-sizing:border-box; }
    .ctSettingDiv > div {   position: relative;  height: 50%; width: 100%; box-sizing:border-box; border-bottom:1px solid #f1f3f5; font-size: 14px; font-weight: bold; text-align: center; line-height: 180px; }
    .ctSettingDiv > div:hover { background:#f1f3f5; }
    .ctSettingDiv > div.on {background:#f7f9fb;}
    .ctSettingDiv .descript { width: 100%; position: absolute;bottom: 0; line-height: 230px; height: 200px; font-size: 12px; opacity: 0.5}
    .ctSettingDiv .checkDiv { z-index: 10000; top: 80px; left: 30px; }
    .ctSettingDiv .checkDiv input[type='checkbox']:not(old) + label { width:25px; height: 25px; border-radius: 50%; background-size: 25px; background-position: center center;}
    .ctSettingDiv .checkDiv label:after { width: 480px; height: 200px; left: -30px; content: ''; display: block; position: absolute; top:-80px; }
    .ctSettingDiv .checkDiv input[type='checkbox']:not(old):checked + label { }
    .psDiv { display: none; width:40%;box-sizing:border-box; border-left:1px solid #f1f3f5;}
    .psDiv .titleDiv { border-bottom:1px solid #f1f3f5; padding:0 20px; line-height: 55px; line-height: 55px; box-sizing:border-box; width:100%; }
    .psDiv .titleDiv * { display: inline-block; font-size: 13px; font-weight: bold; }
    .psDiv .titledIV .total {float: right;font-size: 12px; }
    .crChtrm.ps .psDiv { display: block; float: left;}
    .crChtrm.ps .ctSettingDiv { width: 60%; float: left;}
    .psDiv .mateUl li { padding-left:70px !important; }
    .psDiv .mateUl li .mPic{ left:20px !important; }
    .psDiv .mateUl li .dot { left: 15px !important; }
    .psDiv .magUl li:hover .creator {  display: block; }



    .chatroomPanel.on .typingDiv { top:-100px; }

    .typingDiv { display: none; overflow: hidden; padding:0 25px; box-sizing:border-box; position: absolute;width: 100%; height: 40px; top:-40px;left:0; }
    .typingDiv .bg { display: inline-block; border-radius: 20px; padding:0 13px; height: 23px; text-align: center; line-height:  20px; background:#fff; box-sizing:border-box; border:1px solid #e7e9ea;}
    .typingDiv .bg.meTyping { background:#477fff; float: right; border-color:#477fff;}
    .typingDiv .bg.otherTyping {  }
    .typingDiv .bg span { width:6px; height: 6px; margin:0 2px;display: inline-block; border-radius: 50%; background:#35405A; }
    .typingDiv .bg.meTyping span { background:#fff; }




    input[type='checkbox']:not(old) + label { padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;}
    input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;}

    /* 왼쪽 햄버거 버튼 클릭 시 */
    #section_ce.left_area_close .content { padding-left: 61px !important; box-sizing: border-box; }

    /* 수정 모드 */
    .section_ce_fix.edit .titleBox .btns .del,.section_ce_fix.edit .titleBox .btns .cancel { display:inline-block;  }
    .section_ce_fix.edit .titleBox .btns .edit, .section_ce_fix.edit .titleBox .btns .schBtn { display:none; }
    .section_ce_fix.edit .chatroomUl .checkDiv { display:block; }
    .section_ce_fix.edit .titleBox h4 { display:none; }
    .section_ce_fix.edit .editModeDiv { display: inline-block; }
    .section_ce_fix.edit .chatroomUl .leaveDiv { opacity:1; }
    .section_ce_fix.edit .addBtn { display: none; }
    .section_ce_fix.edit .chatroomUl li { padding: 10px 130px 10px 150px; }
    .section_ce_fix.edit .chatroomUl li .goChatroom { display:none;}
    .section_ce_fix.edit .chatroomUl li .peepsDiv { left: 50px; }
    .section_ce_fix.edit .chatroomUl li .unread {right: 65px}
    .section_ce_fix.edit .chatroomUl li .sent { right: 67px; }


    /* 오른쪽 닫혔을때 (친구, 그룹 버튼 있는 부분) */
    .section_ce_fix.rightDivClosed .rightDiv { transition:0.1s; width:0px; overflow: hidden; }
    .section_ce_fix.rightDivClosed .rightDiv .rightDivDescript {display:block; }
    .section_ce_fix.rightDivClosed .rightDiv .btns, .section_ce_fix.rightDivClosed .rightDiv .schPeepsDiv, .section_ce_fix.rightDivClosed .rightDiv .checkDiv { display:none; }
    .section_ce_fix.rightDivClosed .mateUl { overflow:hidden; }
    .section_ce_fix.rightDivClosed .mateUl li .dot, .section_ce_fix.rightDivClosed .mateUl li .dot {left: 14px !important;}
    .section_ce_fix.rightDivClosed .mateUl li { border-bottom: 0 none;height: 52px !important; }
    .section_ce_fix.rightDivClosed .magUl .mPic {left: 18px !important;}
    .section_ce_fix.rightDivClosed .leftDiv { padding-right:0px; }
    .section_ce_fix.rightDivClosed .title_box .mag_btn { margin-right:-5px; }
    .section_ce_fix.rightDivClosed .rightDiv .whenClosed { display:block; }
    .section_ce_fix.rightDivClosed .rightDiv .rightSettingDiv { display: none; }
    .rightSettingDiv .chatSetUl li p span { opacity: 0.7; width: auto; height: auto; background: none; }
    .rightSettingDiv .switch { width: 42px; margin-right: 17px;height: 20px;; }
    .rightSettingDiv .slider:before { height: 12px;width: 12px;left: 5px;bottom: 4px; }
    .rightSettingDiv input:checked+.slider:before { transform: translateX(20px);}

    /* chatroomUl 닫혔을때, chatroom 오픈 */
    .section_ce_fix.leftDivClosed .leftDiv { /* padding-left: 75px; */ padding-top: 60px; }
    .section_ce_fix.leftDivClosed .leftDiv .titleBox { padding-left: 100px; }
    .section_ce_fix.leftDivClosed .chatroomDiv  { width: 75px;position: absolute;left: 0;top: 0;height: 100%;box-sizing: border-box;padding-top: 60px; }
    .section_ce_fix.leftDivClosed .chatroomUl { width: 75px; overflow: hidden; border-right: 1px solid #e7e9ea; }
    .section_ce_fix.leftDivClosed .chatroomUl li .peepsDiv { left: 0; width: 75px; height: 75px; box-sizing: border-box; padding: 15px }
    .section_ce_fix.leftDivClosed .chatroomUl li .peepsDiv.two p { width: 35px;height: 35px; }
    .section_ce_fix.leftDivClosed .chatroomUl li { height: 75px; }
    .section_ce_fix.leftDivClosed .chatroomUl li .peepsDiv.two p:last-child span { line-height: 30px; font-size: 13px; }
    .section_ce_fix.leftDivClosed .leftDiv .titleBox .chatroomMenu { display:block; }
    .section_ce_fix.leftDivClosed .chatroom { display:block; }
    .section_ce_fix.leftDivClosed .leftDiv .searchDiv {box-sizing: border-box;padding-left: 75px;z-index: 1; }
    .section_ce_fix.leftDivClosed .useWhenClosed { z-index: 1; position: absolute; top:0;left:0;width:100%; height: 60px;box-sizing: border-box;padding-right: 400px; }
    .btns2 { display:none;float:right; }
    .btns2 input { margin-left:15px; margin-top:11px; height: 35px; line-height: 33px; }
    .btns2 .schBtn { margin-top:12px; margin-right:-8px; margin-left: 10px; width: 35px;height: 35px; }
    .btns2.editMode .input_btn { display: inline-block !important;  }
    .btns2.editMode .edit, .btns2.editMode .schBtn { display: none !important;  }
    .section_ce_fix.leftDivClosed .useWhenClosed .titleBox > .btns { display: none; }
    .section_ce_fix.leftDivClosed .useWhenClosed .btns2 { display:block; }
    .section_ce_fix.leftDivClosed .useWhenClosed .btns2 input { display: none; float: right; }
    .section_ce_fix.leftDivClosed .useWhenClosed .btns2 .edit, .section_ce_fix.leftDivClosed .useWhenClosed .btns2 .schBtn { display: inline-block; }
    .section_ce_fix.leftDivClosed .addBtn { display:none !important; }
    .section_ce_fix.leftDivClosed .chatroomUl { position: relative; }
    .section_ce_fix.leftDivClosed .chatroomUl li { opacity:0.6; width: 75px; padding-right:0;padding-left:0; }
    .section_ce_fix.leftDivClosed .chatroomUl li:hover { opacity:1; }
    .section_ce_fix.leftDivClosed .chatroomUl li.on { opacity:1; }
    .section_ce_fix.leftDivClosed .chatroomUl li.on .goChatroom { display:none; }
    .section_ce_fix.leftDivClosed .chatroomUl li .chType { display:none; }
    .section_ce_fix.leftDivClosed .chName {display: none;}
    .section_ce_fix.leftDivClosed .sent {display: none;}
    .section_ce_fix.leftDivClosed .lastMsg {display: none;}
    .section_ce_fix.leftDivClosed .chatroomUl li .unread { z-index: 1; right:auto; top:10px; right: 10px; font-size: 11px; padding: 0 5px; height: 16px; line-height: 14px; width: auto !important; min-width: 20px !important; }
    .section_ce_fix.leftDivClosed .titleBox .beforeClosed { display:none; }
    .section_ce_fix.leftDivClosed .titleBox .afterClosed { display:block; }
    .section_ce_fix.leftDivClosed .chatroom { display:block; }
    .section_ce_fix.leftDivClosed .leaveDiv { display: none; }
    .section_ce_fix.leftDivClosed .leftDiv .titleBox .btns input.edit { display:none !important; }
    .section_ce_fix.leftDivClosed .leftDiv .titleBox .btns input.del { display:none !important; }

    /* 처음 만들었을 때 */
    .section_ce_fix.leftDivClosed.oneCt .chatsUlDiv { display: none }
    .section_ce_fix.leftDivClosed.oneCt .noChatYet { display: block; }
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting li:nth-child(1), 
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting li:nth-child(2), 
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting li:nth-child(3), 
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting li:nth-child(4) { height: 0; border-bottom: 0; }
    .section_ce_fix.leftDivClosed.oneCt .afterClosed { font-size:0; }
    .section_ce_fix.leftDivClosed.oneCt .afterClosed:after { content:'김연지 (나)'; font-size: 13px !important; display: inline-block;  }
    .section_ce_fix.leftDivClosed.oneCt .schPeepsDiv { display: none; }
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting .editDiv { display:none; }
    
    /* 채팅자 보이기 */
    #section_ce .section_ce_fix.leftDivClosed .mateList { display:none; }
    #section_ce .section_ce_fix.leftDivClosed .rightDiv.mate .mateList.chatting { display:block }
    #section_ce .section_ce_fix .rightDiv.mate .mateList.chatting { display: none }
    #section_ce .section_ce_fix.leftDivClosed .rightDiv.img .imgList { display:block }
    #section_ce .section_ce_fix.leftDivClosed .rightDiv.file .fileList { display:block }
    .section_ce_fix.leftDivClosed .chatroomBtns { display:block }
    .section_ce_fix.leftDivClosed .editDiv { display:block }
    .section_ce_fix.leftDivClosed .rightDiv .btns { display:none !important; }
    .section_ce_fix.leftDivClosed .rightDiv .chatroomTitle { display:block }
    
    .section_ce_fix.leftDivClosed .rightDiv.img .imgFileSchDiv { display:block }
    .section_ce_fix.leftDivClosed .rightDiv.file .imgFileSchDiv { display:block }

    .section_ce_fix.leftDivClosed .rightDiv.img .editDiv, .section_ce_fix.leftDivClosed .rightDiv.file .editDiv { display:block }

    .section_ce_fix.leftDivClosed .rightDiv.img .schPeepsDiv { display:none }
    .section_ce_fix.leftDivClosed .rightDiv.file .schPeepsDiv { display:none }

    .section_ce_fix.leftDivClosed .rightDiv.mate .inviteMate { display:block }
    

    /* 오른쪽 닫힐때 */
    .section_ce_fix.rightDivClosed .rightDiv .inviteMate { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .delSelectedBtn { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .imgFileSchDiv { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .chatroomBtns { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .editDiv { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .chatroomTitle { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .rightSettingDiv { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv p.creator { display: none; }
    .section_ce_fix.rightDivClosed .rightDiv .memberDiv { display: none; }
    .section_ce_fix.rightDivClosed .rightDiv .grpUl .grpLi > div .grpPic { left: 12px; }
    .section_ce_fix.rightDivClosed .rightDiv .grpUl > li > div { height: 66px }
    .section_ce_fix.rightDivClosed .rightDiv .mateUl li .dot, .section_ce_fix.rightDivClosed .rightDiv .mateUl li .dot, .memberUl li .dot, .section_ce_fix.rightDivClosed .rightDiv  .memberUl li .dot {left:14px !important; }
    #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .checkDiv { display: none !important; }
    .section_ce_fix.rightDivClosed .rightDiv .editMode .grpUl .grpLi > div .grpPic { left: 12px; }
    .section_ce_fix.rightDivClosed .rightDiv .teamList .mainGrp .prev { display: none }
    .section_ce_fix.rightDivClosed .rightDiv .teamList .mainGrp .grpPic { left: 7px; }
    .section_ce_fix.rightDivClosed.leftDivClosed .rightDiv .mateList.chatting { display:block !important; }
    .section_ce_fix.rightDivClosed .rightDiv .fileList {display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .imgList {display:none !important }

    #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .mateUl li .mPic, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .mateUl li .mPic, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .memberUl li .mPic, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .memberUl li .mPic {left: 20px !important;}
    #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .mateUl li .dot, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .mateUl li .dot, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .memberUl li .dot, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .memberUl li .dot {left: 14px !important;}


    /* 방장일때 */
    #section_ce .section_ce_fix.leftDivClosed.rm .editDiv .editBtn { display:block; }

    #section_ce .section_ce_fix.leftDivClosed.rm { }
    
    #section_ce .section_ce_fix.leftDivClosed .chatroomTitle .title.rm { display:none; }
    #section_ce .section_ce_fix.leftDivClosed.rm .chatroomTitle .title { display:none; } 
    #section_ce .section_ce_fix.leftDivClosed.rm .chatroomTitle .title.rm { display:inline-block; }
    
    /* chatroom 오픈, rightDiv 오픈된 채팅룸에 디테일 보임 */
    
    
    .chatroomBtns, .inviteMate, .imgFileSchDiv, .fileList, .imgList, .delSelectedBtn { display:none }
    .editDiv { display:none }
    .chatroomTitle {display:none;}

    #section_ce .section_ce_fix.leftDivClosed .magUl > li { display:none; }



    /* leftDivClosed, rightDivClosed 동시에 = 채팅룸이 열렸을때 */
    .section_ce_fix.leftDivClosed.rightDivClosed .useWhenClosed { padding-right:70px }


    .content, .forRel { width:100%; height: 100%; }

    /* 오른쪽 닫혔을때 사용되는 blocking div */
    .whenClosed { display:none; position: absolute; z-index: 100; top:0; left:0; width:100%; height: 100%; opacity:0; background:#35405A; transition:0.2s;}
    .whenClosed:hover { opacity:0.05; cursor:pointer }

    /* 왼쪽 닫혔을때 사용되는 blocking div */
    .leftDiv .whenClosed { top:60px;}

    /* .title_box */
    .title_box .mag_btn { transition:0.1s; width: 36px; height: 36px; margin-top:3px; font-size:0; border:1px solid #e7e9ea; font-size:0; box-sizing: border-box; border-radius: 50%; background:  url(../../assets/images/contents/bt_hambuger.png) no-repeat 6px center; background-size: 23px; }
    .title_box .mag_btn:hover {background-color:#f1f3f5}
    .title_box .mag_btn.on { background: #477fff url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-color:#477fff }

    /* .leftDiv */
    .leftDiv > div > .forRel { height: auto; }
    .leftDiv { position: relative; width:100%; height: 100%; box-sizing: border-box; padding-right: 400px; transition: 0.1s;}
    .leftDiv .titleBox { transition:0.1s;position:relative; padding: 0 30px;overflow: hidden; line-height: 60px; height: 60px; box-sizing: border-box; border-bottom:1px solid #e7e9ea; }
    .leftDiv .titleBox h4 { transition:0.1s; float: left; font-size: 14px; }
    .leftDiv .titleBox h4 span { color:#477fff; font-size:12px }
    .leftDiv .titleBox .afterClosed { display:none; }
    .leftDiv .titleBox .afterClosed span { padding-left: 10px; }
    .leftDiv .titleBox .chatroomMenu { display:none; }
    .leftDiv .titleBox .btns { float: right; line-height: 58px;}
    .leftDiv .titleBox .btns input { float: right; height: 35px; line-height: 33px; margin-left: 10px; font-size: 13px !important; margin-top:11px; height: 35px; line-height: 33px; }
    .leftDiv .titleBox .btns .schBtn { width: 35px; font-size: 0 !important; margin-top:12px;margin-right: -8px; }
    .titleBox .btns .del,.titleBox .btns .cancel { display:none; }
    .leftDiv .searchDiv { z-index: 1000; }
    .leftDiv .searchDiv input[type="text"] { padding-left: 60px; padding-right: 70px; font-size: 15px; height: 60px; line-height: 62px; margin-top:-1px; background: url(../../assets/images/contents/ic_search_on.png) 25px center no-repeat; }
    .leftDiv .searchDiv input[type="text"]:hover { background-color:#f1f3f5; }
    .leftDiv .searchDiv input[type="text"]:focus { background-color:#fff; }
    .leftDiv .searchDiv .closeBtn { width: 35px; height: 35px; margin-top:12px;margin-right: 22px; }
    .leftDiv .editModeDiv { display:none; }
    .leftDiv .editModeDiv .checkDiv  { top: 18px;left: 25px; }
    .leftDiv .editModeDiv .checkDiv input[type='checkbox']:not(old) + label { width: 18px; height:18px; background-size: 21px !important; }
    .leftDiv .editModeDiv .checkDiv input[type='checkbox']:not(old) + label span { color:#7c88a2; }
    .leftDiv .editModeDiv .checkDiv input[type='checkbox']:not(old):checked + label span { color:#232848; }
    .leftDiv .editModeDiv .checkDiv span { position: absolute; top:-19px; left: 40px; height: 60px; line-height: 60px; width: 70px; display: inline-block; font-size: 14px; font-weight: bold; }
    .leftDiv .editModeDiv .counting { padding-left: 110px; font-weight: bold;  font-size:  14px; }
    .leftDiv .editModeDiv .counting span { color:#477fff; }
    
    
    
    /* .leftDiv .chatroomMenu  */
    .chatroomMenu {z-index: 100000; transition:0.1s; position: absolute; left: 0; top: 0; height: 60px; line-height: 60px; width: 76px; font-size: 0; border-right:1px solid #e7e9ea; box-sizing: border-box; background:  url(../../assets/images/contents/ic_loc.png) no-repeat center center; }  
    .chatroomMenu:hover{ background-color:#f1f3f5; }

    /* .chatroomUl */
    .chatroomUl li { transition:0.2s; position: relative; height: 104px; border-bottom:1px solid #f1f3f5; padding: 12px 100px 12px 120px; box-sizing: border-box; }
    .chatroomUl { padding-bottom: 100px; }
    .chatroomUl li:hover { background: #f7f9fb; padding-right: 150px; }
    .chatroomUl li .peepsDiv { transition:0.1s; overflow: hidden; position: absolute; left:7px; top:0; height: 104px; width: 104px; box-sizing: border-box; padding: 20px; }
    .chatroomUl li .peepsDiv p { float: left; font-size: 0; border: 1px solid #e7e9ea; box-sizing: border-box; border-radius: 50%; background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .chatroomUl li .peepsDiv.one p { width: 100%; height: 100%; border-radius: 50%; }
    .chatroomUl li .peepsDiv.two p { width: 49px; height: 49px; }
    .chatroomUl li .peepsDiv.two p:last-child { position: absolute; bottom:13px;right:13px; border:3px solid #fff;width: 52px; height: 52px; }
    .chatroomUl li .peepsDiv.two p:last-child span { text-indent:-2px; position: absolute; display:block; background:rgba(0,0,0,0.3); width:100%; height: 100%; border-radius: 50%; font-size: 14px; font-weight: bold; color:#fff; line-height: 46px; text-align:center; text-shadow: 0 2px 2px rgb(0,0,0); }
    .chatroomUl li.specList .peepsDiv.one p { border-radius: 5px;  }
    /* .chatroomUl li.specList .peepsDiv.one { padding: 20px 20px } */
    .chatroomUl li .chName { font-size: 14px; font-weight: bold; height: 32px; line-height: 34px;overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .chatroomUl li .chType { display: inline-block; width:40px; font-size: 11px; text-align: center; box-sizing:border-box;border-radius: 2px; height: 17px; line-height: 17px; font-weight: bold; background:#f1f3f5; margin-right: 10px;}
    .chatroomUl li:hover .chType { background:#fff; }
    .chatroomUl li .chType.grp, .chatroomUl li .chType.team { color:#fff; }
    .chatroomUl li .unread { transition:0.1s; position: absolute; right: 30px; top:20px; box-sizing: border-box; min-width: 40px; text-align: center; padding: 0 10px; border-radius: 15px; height: 17px; font-weight: bold; color:#fff; line-height: 15px; background: #ff6363;border: 1px solid #ff2929; }
    .chatroomUl li .lastMsg { line-height: 18px; display: block; margin-top:-1px; height: 38px; overflow: hidden; text-overflow: ellipsis;}
    .chatroomUl li .sent { position:absolute; right: 32px; bottom:18px; line-height: 17px;text-align: right; font-weight: bold; opacity: 0.5;}
    .chatroomUl li .goChatroom { z-index: 10; position:absolute; left:0 ;top:0; width:100%; height:100%; font-size: 0;  }
    .chatroomUl li:after { content:''; display:block; width: 3px; height: 100%; position: absolute; left:0; top:0; box-sizing: border-box; }
    .chatroomUl li:hover:after { background: #e7e9ea; }
    .chatroomUl li.on:after { background: #477fff;  }
    .chatroomUl li.on { background:#fff !important; }
    .chatroomUl li.on:hover .goChatroom { cursor: default !important; }
    .chatroomUl .checkDiv { display:none; top: 36px; z-index: 100; left: 25px; }
    .chatroomUl .checkDiv input[type='checkbox']:not(old) + label { width: 19px; height: 19px;background-size: 22px !important; }
    .chatroomUl li:hover .unread {right: 65px; }
    .chatroomUl li:hover .sent { right: 67px; }

    .chatroomUl .leaveDiv { z-index: 100; opacity:0; transition:0.1s; position: absolute; right:0; top:0; height: 100%; line-height: 100px; width: 60px; text-align: right; background: url(../../assets/images/contents/img_shdw_leaveDiv.png) repeat-y top right; background-size: 60px; }
    .chatroomUl li:hover .leaveDiv { opacity: 1; }
    .chatroomUl .leaveDiv input { transition:0.1s; width: 60px; height: 100%; margin-right: 0; background: url(../../assets/images/contents/ic_lcr.png) no-repeat 20px center; font-size:0; }
    .chatroomUl .leaveDiv input:hover { background-image: url(../../assets/images/contents/ic_lcr_on.png); background-position: 25px center; }

    /* 채팅룸 없을 시 */
    .noChatRoomDiv.on { display: block; }
    .noChatRoomDiv { display:none; width: 100%; box-sizing: border-box; padding: 30px 40px; }
    .noChatRoom { background:#f1f3f5; text-align: center; border-radius: 5px; padding-top:10px; }
    .noChatRoom span { font-size:0; display:inline-block; width: 100px; height: 90px; background: url('../../assets/images/contents/img_noChatRoom.png') no-repeat center center; background-size: 80px; }
    .noChatRoom p { font-size: 15px; font-weight: bold; color: #93a0b1; display: block; height: 65px; line-height: 25px; }

    .chatroomDiv .noResult.on { display:block; }
    .chatroomDiv .noResult { display:none; position: static; padding: 15px 30px }
    .chatroomDiv .noResult p { background:#f1f3f5; color: #93a0b1; line-height: 65px; height: 65px; }

    /* .chatroom */
    /* .chatroom은 mCustomScrollbar 넣으면서 height값은 변경되어야함 */
    .chatroom { background:#f7f9fb; border-left:1px solid #e7e9ea; position:relative; display:none; width:100%; height:855px; padding-bottom: 110px;box-sizing: border-box; }
    .chatroom .noChatYet.on { display:block; }
    .chatroom .noChatYet { display:none; width:100%; text-align: center; padding-top:25px; }
    .chatroom .noChatYet p { width: auto; display: inline-block; padding:0 50px; border-radius: 30px; height: 50px; line-height: 50px; font-size: 14px; background: #fff; border:1px solid #e7e9ea; text-align: center; font-weight: bold;color: #93a0b1;  }
    .chatroomGrp { padding: 30px 25px 35px; width: 100%; box-sizing: border-box; }

    /* chatsUlDiv */
    .chatsUlDiv .till.when span.txt { color:#7c88a2 }
    .chatsUlDiv .till { position: relative; width:100%;text-align:center; padding: 30px 40px 20px; box-sizing: border-box;  }
    .chatsUlDiv .till span.txt { position: relative; z-index: 10; height: 30px; line-height: 30px; font-weight: bold; background:#fff; border-radius: 20px; padding: 0 20px; border:1px solid #e7e9ea; display: inline-block; }
    .chatsUlDiv .till span.line { display:block; width: 95%; height: 1px; background:#000; opacity:0.07; position:absolute;left: 2.5%; border-radius: 50%; top:46px; z-index: 1; }
    .chatsUlDiv .msgDiv { position: relative; }
    .rm .chatroom.editMode .chatsUlDiv .from .msgDiv p { opacity:0.7; }
    .chatroom.editMode .chatsUlDiv .me .msgDiv p { opacity:0.7; }
    .chatsUlDiv .checkDiv { display: none; }
    #section_ce .chatroom.editMode  { background:#fff !important }
    .chatroom.editMode .checkDiv { display: block; }
    .rm .chatroom.editMode .from .msgDiv {box-sizing: border-box;padding-left: 40px;} 
    .chatroom.editMode > .scroll { border:2px solid #477fff; height: 744px; box-sizing: border-box; }
    .rm .chatroom.editMode .from .checkDiv { left: 0; top: 5px; }
    .chatroom li .checkDiv input[type='checkbox']:not(old) + label { width: 20px;height: 20px;border-radius: 50%;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../assets/images/contents/checked_fff.png) no-repeat center center;background-size: 22px !important;font-size: 0;}
    .chatroom li .checkDiv input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;border-color:#477fff;}
    .chatroom.editMode .me .msgDiv {box-sizing: border-box;padding-right: 40px;} 
    .chatroom.editMode .me .checkDiv { right: 0px; top: 5px; left: auto; }
     .chatroom.editMode li p.msg span { position: relative; overflow: hidden }
    .chatroom.editMode li p.msg span:after  { z-index: 100000; position: absolute; content: ''; left: 0;top:0; width:100%; height: 100%; opacity: 0.15 }
    .chatroom.editMode .chatsUlDiv li .msgDiv.on p { opacity:1; }
    /*.chatroom li .checkDiv label:after { z-index: 1000; position: absolute; content: ''; display: block; width: 500px; height: 41px; }*/
    .rm .chatroom li.from .checkDiv label:after  { left:0; top:0; }
    .chatroom li.me .checkDiv label:after  { right:0; top:0; }
    .chatroom.editMode .checkDiv { width: auto; height: auto; }
    
    /* .chatsUl */
    .chatsUl li { box-sizing: border-box; position: relative; width:100%; margin-bottom: 25px; }
    .chatsUl li .chatGrp { height: 20px; line-height: 15px; }
    .chatsUl li .time, .chatsUl li .mName { display:inline-block; font-weight: bold; opacity: 0.7; }
    .chatsUl li.from .mName { margin-right: 15px; }
    .chatsUl li .mPic { position: absolute; left:-3px; top:-2px; width: 38px; height: 38px; display: inline-block; font-size:0; border-radius: 50%; border: 1px solid #e7e9ea; background-color:#fff; background-size:cover; background-position: center center; background-repeat: no-repeat; }
    .chatsUl li .msg { margin-bottom:6px; }
    .chatsUl li .msg span { background: #fff;  display:inline-block; max-width: 600px; width: auto; box-sizing:border-box; padding: 10px 13px; font-size: 13px;font-weight: bold; line-height: 19px; }
    .chatsUl li .time span { padding-right: 10px; }
    .chatsUl li .msg span a { padding:0 3px;text-decoration: underline; color:#477fff; }
    .chatsUl li.from { padding-left: 50px;  }
    .chatsUl li.from .msg.first span {  border-radius: 0 15px 15px 15px }
    .chatsUl li.from .msg.first.img span  { padding:0; border-radius: 0 15px 15px 15px }
    .chatsUl li.from .msg.first.img span a {  }
    .chatsUl li.from .msg span { border-radius: 15px 15px 15px 15px;border:1px solid #e7e9ea; }
    
    #section_ce .chatsUl li .msg.deleted span { background-color:#e7e9ea !important; border-color: #e7e9ea !important;; color: #7c88a2 !important; }
    #section_ce .chatsUl li .msg.deleted span em { display:inline-block; width: 17px; height: 17px; line-height: 17px; border:1px solid #fff; font-style: normal; text-align: center; border-radius: 50%; background:#f1f3f5; color:#7c88a2; font-size: 12px; margin-right:8px; float:left; } 

    .chatsUl li.me .msg span a { color:#fff;}
    .chatsUl li.me { text-align: right;}
    .chatsUl li.me .msg span { background:#477fff; color:#fff; }
    .chatsUl li.me .msg.first span { border-radius: 15px; border-radius: 15px 0 15px 15px; }
    .chatsUl li.me .msg span { border-radius: 15px; }
    .chatsUl li.me .msg.img.first span { padding:0;border-radius: 15px 0 15px 15px;  } 
    .chatsUl li.me .msg.img.first a { } 
    
    .chatsUl li .msg.img { height: auto; }
    .chatsUl li .msg.img span { padding:0; border-radius: 15px 15px 15px 15px; }
    .chatsUl li .msg.img span a { float:left; font-size:0; display: inline-block; width: 100px;  height: 100px; position: relative; border:1px solid #e7e9ea; background-size: cover; background-position: center center; background-repeat: no-repeat; background-image: url(../../assets/images/footer/g13.jpg); }
    .chatsUl li .msg.img span { position: relative; overflow: hidden;  }
    
    .chatsUl li .msg.img span a:after { transition:0.2s; opacity:0.15; content:''; display:block; position: absolute; left:0; top:0; width:100%; height: 100%; background:#35405A}
    .chatsUl li .msg.img span a:hover:after { opacity:0; }

    .chatsUl li .msg.file span  { text-align: center;  position: relative; overflow: hidden; width:150px; }
    .chatsUl li .msg.file.vid span  {  position: relative; overflow: hidden; width:150px; }
    .chatsUl li .msg.file a { width: 123px;height: 80px; line-height: 80px;text-align: center; font-size: 0; box-sizing: border-box;border: 1px solid #e7e9ea;padding: 0;display: block; border-radius: 10px; font-size: 0; margin-bottom:7px; }
    .chatsUl li .msg.file.format a { transition:0.1s; font-size: 13px !important; color:#477fff; background:#fff; text-transform: uppercase; text-decoration: none; height: 60px; line-height: 60px; font-size:12px; }
    .bgc8 .chatsUl li.from .msg.file.format a { background-color:#f1f3f5; }
    .bgc8 .chatsUl li.from .msg.file.format a:hover {  background-color:#fff; }
    .chatsUl li .msg.file a:after { transition:0.2s; opacity:0.15; content:''; display:block; position: absolute; left:0; top:0; width:100%; height: 100%; }
    .chatsUl li .msg.file a:hover:after { opacity:0; }
    .chatsUl li .msg.file.vid a { height: 70px; border-radius: 10px; background-size: cover; background-position: center center; background-repeat: no-repeat; }
    .chatsUl li .msg.file.vid a { overflow: hidden; position:relative; top:-10px; left:-14px;margin-bottom:-3px; width: 150px; height: 90px; border-radius: 15px 15px 0 0;}
    .chatsUl li .msg.file.vid a:after { opacity:0.25; background:#000; }
    .chatsUl li .msg.file.vid a span { z-index: 10000; left:50%; margin-left: -22.5px; top:50%; margin-top:-22.5px; width: 45px; height: 45px; border-radius: 50%; position: absolute; border:3px solid #fff; display: block; background: url(../../assets/images/contents/ic_playBtn.png) no-repeat center center; background-size:30px; font-size:0; box-sizing: border-box; box-shadow: 0 3px 3px rgba(0,0,0,0.3); }
    .chatsUl li .msg.file.vid a:hover:after { opacity:0; }
    .chatsUl li .msg.file em { display:block; font-style: normal; width:100%; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }


    .chatroomPanel {z-index: 1000; transition:0.1s; background:#fff; position: absolute;bottom: 0; left:0; width:100%; height: 110px; border-top:1px solid #e7e9ea }

    .mainPanel { position: relative; padding: 15px 75px 30px 75px; width:100%; box-sizing: border-box; height: 100%; }
    .mainPanel textarea { font-weight: bold; transition:0.2s; width:100%;border: 2px solid #e7e9ea; line-height: 20px; appearance: none; resize: none; background: #f1f3f5; height:100%;border-radius: 5px; padding: 10px;box-sizing: border-box; font-size: 13px; }
    .mainPanel textarea:hover { background:#fff; }
    .mainPanel textarea:focus { border-color:#477fff; background:#fff; outline: #477fff; }
    .mainPanel input { transition:0.1s; position: absolute; }
    .mainPanel .addExtra { font-size: 0; width: 40px; height: 40px; border-radius: 50%; background-color:#fff; border: 1px solid #e7e9ea;left: 17px; top: 20px; background: url("../../assets/images/contents/ic_plus_new.png") no-repeat center center;  }
    .mainPanel .addExtra:hover { background-color:#f1f3f5; }
    .mainPanel .addExtra.on { background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-color:#ff6363 }
    .mainPanel .sendBtn { font-size: 0; width: 40px; height: 40px; border-radius: 50%; background-color:#fff; border: 1px solid #e7e9ea;right: 17px; top: 20px; background: #477fff url('../../assets/images/contents/ic_send_on.png') no-repeat center center;  }
    .mainPanel .sendBtn:hover { background-color: #3471fd }

    .chatroomPanel.on { padding-top: 60px; }
    .chatroomPanel.on .extraPanel {display: block; transition:0.5s; opacity: 1; }
    .extraPanel {  transition:0.5s; opacity: 0; display:none; position: absolute; top:-60px; padding: 0 20px; box-sizing: border-box; width:100%; border-bottom:1px solid #f1f3f5;height: 60px; }
    .extraPanel input[type="file"] { display:none; }
    .extraPanel input[type="button"], .extraPanel label {  transition:0.1s; cursor: pointer; display: inline-block; font-size:0; width: 35px; height: 35px; background-color:#fff;border-radius: 50%;  border: 1px solid #e7e9ea; float:left; margin-top:11px; margin-right:10px; }
    .extraPanel input[type="button"].changeChatroomBgBtn { transition:0.2s; opacity:0.9; float:right; border: 1px solid #b7c1cc; margin-right:0; font-size:0; margin-top:11px; background-image:url('../../assets/images/contents/checked_fff.png'); background-position: center center; }
    .extraPanel input[type="button"].changeChatroomBgBtn:hover { opacity:1;border: 3px solid #b7c1cc; }

    .extraPanel input[type="button"].addEmojis { background:  url(../../assets/images/contents/ic_emoji.png) no-repeat center center; font-size:0;background-size: 23px !important; }
    .extraPanel input[type="button"].addEmojis:hover  { background-color:#f1f3f5; }
    .extraPanel input[type="button"].addEmojis.on { background-image:url(../../assets/images/contents/ic_emoji_fff.png);background-color:#477fff; border-color:#477fff; }
    .extraPanel label.addFile {background:  url(../../assets/images/contents/ic_file_bk.png) no-repeat center center;background-size: 23px !important;  }
    .extraPanel label.addFile:hover { background-color:#f1f3f5; }

    .extraPanel label.addImg {background:  url(../../assets/images/contents/ic_img_bk.png) no-repeat center center;background-size: 23px !important;  }
    .extraPanel label.addImg:hover { background-color:#f1f3f5; }

    .chatroomBgDiv.on { display:block; }
    .chatroomBgDiv { display:none; overflow:hidden; position:absolute; left:0; top:0; width:100%; height: 100%; background: #fff; padding-left: 4px; }
    .chatroomBgDiv a { transition:0.1s; float: left; margin-top: 16px; margin-right: 10px; display: inline-block; width: 28px;border: 1px solid #fff; height: 28px; font-size: 0; background:red; border-radius: 50%; box-sizing: border-box;  }
    .chatroomBgDiv a.bgc0 {border: 2px solid #d6dce2;}
    .chatroomBgDiv a.on { background-image:url('../../assets/images/contents/checked_fff.png'); background-position: center center; background-size: 22px; transform: scale(1.1) }
    .chatroomBgDiv a.bgc0.on { background-image:url('../../assets/images/contents/checked_gray.png'); background-position: center center; background-size: 22px; transform: scale(1.1) }
    .chatroomBgDiv a:hover  { transform: scale(1.1) }
    .chatroomBgDiv input[type="button"].closeBtn { float:right; font-size: 0; margin-top:12px; margin-right: 4px;background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-color:#ff6363 }
    

    /* 색상 선택 */
    .bgc0 .extraPanel input[type="button"].changeChatroomBgBtn { background-color:#fff !important; background-image:url('../../assets/images/contents/checked_gray.png');}
    .bgc1 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #FF6363 !important;}
    .bgc2 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #FFA70E !important;}
    .bgc3 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #FFC72F !important;}
    .bgc4 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #FF198B !important;}
    .bgc5 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #00B2C7 !important;}
    .bgc6 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #13D08B !important;}
    .bgc7 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #4DBAFF !important;}
    .bgc8 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #477FFF !important;}
    .bgc9 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #6854FF !important;}
    .bgc10 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #35405A !important;}


    /* 채팅룸 a 색상 */
    .bgc0 { background-color:#fff !important; }
    .bgc1 {background-color: #FF6363 !important;}
    .bgc2 {background-color: #FFA70E !important;}
    .bgc3 {background-color: #FFC72F !important;}
    .bgc4 {background-color: #FF198B !important;}
    .bgc5 {background-color: #00B2C7 !important;}
    .bgc6 {background-color: #13D08B !important;}
    .bgc7 {background-color: #4DBAFF !important;}
    .bgc8 {background-color: #477FFF !important;}
    .bgc9 {background-color: #6854FF !important;}
    .bgc10 {background-color: #35405A !important;}
    

    /* 채팅룸 배경색상 변경 */
    .chatroom.bgc0, .chatroom.bgc0 .chatsUlDiv .till.when span.txt { background-color:#fff !important; }
    .chatroom.bgc1, .chatroom.bgc1 .chatsUlDiv .till.when span.txt {background-color: #fffafa !important;}
    .chatroom.bgc2, .chatroom.bgc2 .chatsUlDiv .till.when span.txt {background-color: #fffdfb !important;}
    .chatroom.bgc3, .chatroom.bgc3 .chatsUlDiv .till.when span.txt {background-color: #fffdf7 !important;}
    .chatroom.bgc4, .chatroom.bgc4 .chatsUlDiv .till.when span.txt {background-color: #fffbfd !important;}
    .chatroom.bgc5, .chatroom.bgc5 .chatsUlDiv .till.when span.txt {background-color: #f8feff !important;}
    .chatroom.bgc6, .chatroom.bgc6 .chatsUlDiv .till.when span.txt {background-color: #f9fdfb !important;}
    .chatroom.bgc7, .chatroom.bgc7 .chatsUlDiv .till.when span.txt {background-color: #f7fcff !important;}
    .chatroom.bgc8, .chatroom.bgc8 .chatsUlDiv .till.when span.txt {background-color: #f7f9fb !important;}
    .chatroom.bgc9, .chatroom.bgc9 .chatsUlDiv .till.when span.txt {background-color: #fcfcff !important;}
    .chatroom.bgc10, .chatroom.bgc10 .chatsUlDiv .till.when span.txt {background-color: #f8f9fb !important;}


    /* 채팅룸 내가 보낸 msg span 색상 변경 */
    .chatroom.bgc0 .from .msg span { background-color:#f1f3f5 !important; border-color: #f1f3f5 }
    .chatroom.bgc1 .from .msg span {background-color: #f7e8e8 !important; border-color: #f7e8e8 }
    .chatroom.bgc2 .from .msg span {background-color: #f9f0e1 !important; border-color: #f9f0e1 }
    .chatroom.bgc3 .from .msg span {background-color: #fbf3dd !important; border-color: #fbf3dd }
    .chatroom.bgc4 .from .msg span {background-color: #ffe8f4 !important; border-color: #ffe8f4 }
    .chatroom.bgc5 .from .msg span {background-color: #dceff3 !important; border-color: #dceff3 }
    .chatroom.bgc6 .from .msg span {background-color: #e5f1ed !important;  border-color: #e5f1ed;}
    .chatroom.bgc7 .from .msg span {background-color: #e9f1f7 !important; border-color: #e9f1f7 }
    .chatroom.bgc8 .from .msg span {}
    .chatroom.bgc9 .from .msg span {background-color: #f0eeff !important; border-color: #f0eeff }
    .chatroom.bgc10 .from .msg span {background-color: #ebecef!important; border-color: #ebecef }

    /* 채팅룸 내가 보낸 msg span 색상 변경 */
    .chatroom.bgc0 .me .msg span { background-color:#477FFF !important; }
    .chatroom.bgc1 .me .msg span {background-color: #FF6363 !important;}
    .chatroom.bgc2 .me .msg span {background-color: #FFA70E !important; color:#232848}
    .chatroom.bgc3 .me .msg span {background-color: #FFC72F !important; color:#232848}
    .chatroom.bgc4 .me .msg span {background-color: #FF198B !important;}
    .chatroom.bgc5 .me .msg span {background-color: #00B2C7 !important;}
    .chatroom.bgc6 .me .msg span {background-color: #13D08B !important; color:#232848}
    .chatroom.bgc7 .me .msg span {background-color: #4DBAFF !important;}
    .chatroom.bgc8 .me .msg span {background-color: #477FFF !important;}
    .chatroom.bgc9 .me .msg span {background-color: #6854FF !important;}
    .chatroom.bgc10 .me .msg span {background-color: #35405A !important;}

    
    /* 채팅룸 내가 보낸 msg span 색상 변경 */
    .chatroom.bgc0 .mainPanel .sendBtn { background-color:#477FFF !important; border-color:#477FFF}
    .chatroom.bgc1 .mainPanel .sendBtn {background-color: #FF6363 !important; border-color:#FF6363}
    .chatroom.bgc2 .mainPanel .sendBtn {background-color: #FFA70E !important; border-color:#FFA70E}
    .chatroom.bgc3 .mainPanel .sendBtn {background-color: #FFC72F !important; border-color:#FFC72F}
    .chatroom.bgc4 .mainPanel .sendBtn {background-color: #FF198B !important; border-color:#FF198B}
    .chatroom.bgc5 .mainPanel .sendBtn {background-color: #00B2C7 !important; border-color:#00B2C7}
    .chatroom.bgc6 .mainPanel .sendBtn {background-color: #13D08B !important; border-color:#13D08B}
    .chatroom.bgc7 .mainPanel .sendBtn {background-color: #4DBAFF !important; border-color:#4DBAFF}
    .chatroom.bgc8 .mainPanel .sendBtn {background-color: #477FFF !important; border-color:#477FFF}
    .chatroom.bgc9 .mainPanel .sendBtn {background-color: #6854FF !important; border-color:#6854FF}
    .chatroom.bgc10 .mainPanel .sendBtn {background-color: #35405A !important; border-color:#35405A}

    

    /* rightDiv 친구리스트 */
    .rightDiv {z-index:1000;transition:0.1s; position: absolute;right: 0; top:0; width: 400px; height: 100%; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .rightDiv .btns { width: 100%; overflow: hidden; }
    .rightDiv .btns input { font-weight: bold; float: left; height: 60px; line-height: 60px; border-bottom: 2px solid #e7e9ea; width: 50%; background:#fff; }
    .rightDiv .btns input:hover { border-color: #b9c0c3 }
    .rightDiv .btns input.on { border-color: #477fff; background:#fff !important; }


    /* rightDiv .rightDivDescript */
    .rightDivDescript { display:none; font-weight: bold; }
    .rightDivDescript p { height: 35px; line-height: 35px; border-bottom:1px solid #e7e9ea; text-align: center; font-size: 11px; }
    .rightDivDescript span { color:#06c580 }


    /* 친구,그룹 검색 */
    .schPeepsDiv { position: relative;  }
    .schPeepsDiv input[type="text"] { transition:0.2s; font-weight: bold; height: 55px; line-height: 55px; padding:0 15px 0 45px; border-bottom: 1px solid #e7e9ea; box-sizing: border-box; width:100%; }
    .schPeepsDiv input[type="text"]:hover { background:#f1f3f5; }
    .schPeepsDiv input[type="text"]:focus { background:#fff; border-color:#477fff; }
    .schPeepsDiv .schBtn { position: absolute; right:auto; left: 5px; top:8px; }
    .schPeepsDiv .schBtn:hover  {background: url(../../assets/images/contents/ic_search_on.png) no-repeat center center;}
    

    /* 친구 ul mateUl */
    .magUl.mate li.mateList { display:block; }
    .magUl.grp li.grpList { display:block; }
    .magUl.grp li.mateList { display:none; }
    .magUl.mate li.grpList { display:none; }
    .magUl.mate li.teamList { display:none !important; }
    .mateUl li, .memberUl li {transition:0.1s;  overflow: hidden; position: relative; padding:0 40px 0 110px; line-height: 55px; height: 55px; width:100%; border-bottom:1px solid #f1f3f5;box-sizing: border-box; overflow:hidden; text-overflow: ellipsis; white-space: nowrap;}
    .mateUl li:hover, .memberUl li:hover { background:#f7f9fb; }
    .mateUl li.on, .memberUl li.on { background:#fff !important;  }
    
    .mateUl li:hover > *, .mateUl li.on > p { opacity:1 !important; }
    .mateUl li .dot, .memberUl li .dot {transition:0.1s;  z-index: 10; position: absolute; left: 15px; font-size:0; top:9px; display:block; width:10px; height: 10px; border-radius: 50%; border: 2px solid #fff;  }    
    .mateUl li.online .dot, .memberUl li.online .dot { background:#13D08B }
    .mateUl li.offline .dot, .memberUl li.offline .dot { background:#b2c0d2 }
    .mateUl li > *, .memberUl li > * { float: left; }
    .mateUl li .mPic, .mateUl li .mPic, .memberUl li .mPic, .memberUl li .mPic { left: 60px !important; }
    .mateUl li .dot, .mateUl li .dot, .memberUl li .dot, .memberUl li .dot { left: 55px !important }
    .mateUl li .mPic, .memberUl li .mPic { transition:0.1s; z-index: 1; position: absolute; background-color:#fff; left: 20px; top:11px; font-size:0; width: 33px; height: 33px; border-radius: 50%; border:1px solid #e7e9ea; background-position: center center; background-size: cover; background-repeat: no-repeat; box-sizing: border-box; }
    .mateUl li .mName, .memberUl li .mName { transition:0.1s; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width:100%; }
    .mateUl li.offline .mPic, .mateUl li.offline .mName, .memberUl li.offline .mPic, .memberUl li.offline .mName { transition:0.1s; opacity:0.6 }
    .magUl li a.makeRoom { opacity:0; position: absolute; right: 0; top: 0; display: block; width: 55px; height: 55px; font-size: 0; background-position: center center; background-size: 30px; background-repeat: no-repeat; background-image:url(../../assets/images/contents/ic_addChatroom.png);  }
    .magUl li:hover a.makeRoom { opacity:1 }
    .mateUl li a.makeRoom { opacity:0 !important; }
    .mateUl li:hover a.makeRoom { opacity:1 !important; }
    .magUl li a.makeRoom:hover { background-image:url(../../assets/images/contents/ic_addChatroom_on.png);  } 
    .magUl li.on .checkDiv, .magUl li:hover .checkDiv {opacity:1; display:inline-block; }
    .checkDiv { position: absolute;left:20px; top:17px; }
    .magUl li input[type='checkbox']:not(old) + label { padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;}
    .magUl li input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;}
    .magUl  { padding-bottom: 100px; }
    .magUl > li > ul { padding-bottom: 100px; }


    

    /* leftDivClosed 채팅창 체크박스 없음 */
    .magUl li.mateList.chatting .checkDiv { display:none; }
    .magUl li.mateList.chatting  .mateUl li:hover input[type="button"] { opacity:1; }
    .magUl li.mateList.chatting  .mateUl li input[type="button"] { opacity:0; position:absolute; right:20px; top:14px;font-size:0; display:block; width: 25px; height:25px; border-radius: 50%; }
    .magUl li.mateList.chatting  .mateUl li input.leave { background: url(../../assets/images/contents/ic_lcr.png) no-repeat center center; }
    .magUl li.mateList.chatting  .mateUl li input.leave:hover { background-image: url(../../assets/images/contents/ic_lcr_on.png); }
    .magUl li.mateList.chatting  .mateUl li input.del {background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center}
    .section_ce_fix.leftDivClosed .magUl li.mateList.chatting  .mateUl li input.del { display:none }
    .section_ce_fix.leftDivClosed.rm .magUl li.mateList.chatting  .mateUl li input.del { display:block }
    .section_ce_fix.leftDivClosed.rm .magUl li.mateList.chatting.editMode  .mateUl li input.del , .section_ce_fix.leftDivClosed.rm .magUl li.mateList.chatting.editMode  .mateUl li input.leave { display:none }
    .mateList.chatting .mateUl li,.mateList.chatting  .memberUl li { padding: 0 40px 0 70px; }
    .mateList.chatting .mateUl li .mPic, .mateList.chatting .mateUl li .mPic, .mateList.chatting .memberUl li .mPic, .mateList.chatting .memberUl li .mPic {left: 22px !important;}
    .mateList.chatting .mateUl li .dot,.mateList.chatting .mateUl li .dot,.mateList.chatting .memberUl li .dot,.mateList.chatting .memberUl li .dot {left: 17px !important;}
    .magUl li .creator { position: absolute; right:20px; top:11px; display:block; width: 30px; height: 30px;border-radius: 50%; background: #fff url(../../assets/images/contents/faveStar.png) no-repeat center center; background-size: 22px; box-sizing:border-box; border:2px solid #ffc23a; font-size:0;}
    .magUl ul li:hover .creator { display: none; }
    .magUl ul li.cr { background:#fff9ea; }


    /* editMode */
    #section_ce .editMode .mateUl li, #section_ce .editMode .memberUl li {transition:0.1s;  overflow: hidden; position: relative; padding:0 40px 0 110px; line-height: 55px; height: 55px; width:100%; border-bottom:1px solid #f1f3f5;box-sizing: border-box; overflow:hidden; text-overflow: ellipsis; white-space: nowrap;}
    #section_ce .editMode .mateUl li .mPic, #section_ce .editMode .mateUl li .mPic, #section_ce .editMode .memberUl li .mPic, #section_ce .editMode .memberUl li .mPic { left: 60px !important; }
    #section_ce .editMode .mateUl li .dot, #section_ce .editMode .mateUl li .dot, #section_ce .editMode .memberUl li .dot, #section_ce .editMode .memberUl li .dot { left: 55px !important }
    /* #section_ce .magUl li.editMode  .mateUl li:hover .checkDiv input { opacity:0 !important } */

    .rightDiv .inviteMate, .rightDiv .delSelectedBtn, .rightDiv .leaveRoomBtn { transition:0.2s; display: none;position: fixed;border-top:1px solid #e7e9ea; background:#fff; height: 40px;line-height: 35px;right: 150px;bottom: 40px; border-radius: 25px;width: 200px;z-index: 10000; font-size: 13px; font-weight: bold; }
    .rightDiv .inviteMate { background:#477fff; color:#fff; }
    .rightDiv .inviteMate:hover { background:#296aff }
    .rightDiv .delSelectedBtn, .rightDiv .leaveRoomBtn { background:#ff6363; color:#fff; }
    .rightDiv .delSelectedBtn:hover, .rightDiv .leaveRoomBtn:hover {background:#ff4040}
    #section_ce .rightDiv.editMode .inviteMate { display:none !important  }
    #section_ce .rightDiv.editMode .delSelectedBtn { display:block }

    #section_ce .rightSettingDiv.on .leaveRoomBtn { display:block }

    


    /* .chatroomTitle */
    .rightDiv .chatroomTitle { position:relative; padding:0 20px; line-height: 60px; height: 60px; border-bottom:1px solid #e7e9ea; box-sizing:border-box; }
    .rightDiv .chatroomTitle > * { display:inline-block; }
    .rightDiv .chatroomTitle .title { font-weight:bold; font-size: 13px; width: 300px; }
    .rightDiv .chatroomTitle .title .dot { display: inline-block; width: 8px; height: 8px; border-radius: 50%; margin-right:10px;}
    .rightDiv .chatroomTitle input { display:none; position:absolute; right:0; top:0;font-size:0; width: 60px; height:100%; background: url(../../assets/images/contents/ic_lcr.png) no-repeat center center;}
    .rightDiv .chatroomTitle input:hover {  background-image: url(../../assets/images/contents/ic_lcr_on.png) }

    /* .chatroomBtns */
    .chatroomBtns { overflow: hidden; height: 50px; line-height: 50px; box-sizing:border-box; }
    .chatroomBtns * { float: left;width: 33.333333%;height: 50px; line-height: 50px; border-bottom:2px solid #e7e9ea;text-align: center; background:#fff; font-weight: bold;font-size: 12px; display: inline-block; }
    .chatroomBtns input.on { border-color:#477fff; }

    /* .editDiv */
    .rightDiv .editDiv { position: relative; border-bottom: 1px solid #e7e9ea; padding: 0 20px; }
    .rightDiv .editDiv .checkDiv { display:none; }
    .rightDiv .editDiv > * { display: inline-block; line-height: 50px; height: 50px;}
    .rightDiv .editDiv .editBtn, .rightDiv .editDiv .cancelBtn { display:none; width: 60px; font-weight: bold; height: 100%; font-size: 12px; position: absolute; right:20px; top:0;background: #fff; }
    .rightDiv .me .editDiv .editBtn { display:block !important; } 
    .rightDiv .editDiv .editBtn { height: 25px; line-height: 25px;  background: #FFC72F; padding:0 15px; border-radius: 15px;  margin-top:12px; }
    .rightDiv .editDiv .editBtn:hover { background:#fdc01b }
    .rightDiv .editDiv .cancelBtn { height: 25px; line-height: 25px;  background: #f1f3f5;  color:#232848; padding:0 15px; border-radius: 15px;  margin-top:12px; }
    .rightDiv .editDiv .cancelBtn:hover { background:#e7e9ea }
    .rightDiv .editDiv .counting { display:none; font-size: 13px; font-weight: bold; padding-left: 100px; }
    .rightDiv .editDiv .counting span { color:#477fff }
    .rightDiv .editDiv label span { position: absolute; left: 30px; top: -17px;height: 50px;line-height: 50px;width: 60px; display: inline-block; font-size: 12px; font-weight: bold; color:#7c88a2 }
    .rightDiv .editDiv .total { font-size: 13px; font-weight: bold; }
    .rightDiv .editDiv .total span { color:#08bf7d; }
    .rightDiv .editDiv .cancelBtn { display:none; }
    #section_ce .editMode .checkDiv { display:block !important; opacity:1 !important; }
    #section_ce .editMode .cancelBtn, #section_ce .editMode .counting { display:inline-block !important; }
    #section_ce .editMode .total { display:none }
    .rightDiv .editDiv input[type='checkbox']:not(old):checked + label span { color:#000 !important; }
    
    /* .ctMenu */
    .ctMenu { transition:0.2s;margin-top: 13px; float:right; font-size:0; display: block; width: 35px; height: 35px; box-sizing:border-box; border-radius: 50%; padding: 8px 9px; }
    .ctMenu:hover  {  background-color:#f1f3f5; }
    .ctMenu span { float:left; display: block; width: 17px; height: 2px; margin:2px auto; background:#000; }
    .ctMenu.on span { display: none }
    .ctMenu.on { background:#f1f3f5 url(../../assets/images/contents/ic_close.gif) no-repeat center center; }
    .ctMenu.on:hover { background-color:#e7e9ea; }

    /* leftDivClosed 채팅창 체크박스 없음 */
    .magUl li.imgList { display:none; }

    .imgFileSchDiv {width:100%; overflow:hidden; height: 55px; line-height: 55px; box-sizing: border-box; font-weight: bold; border-bottom:1px solid #e7e9ea; }
    .imgFileSchDiv > * { display: inline-block; width: 50%;  float:left; }
    .imgFileSchDiv > div:first-child { width: 50%; }
    .imgFileSchDiv > div:last-child select { width: 100%; border-right: 0 none; }
    .imgFileSchDiv select { font-size: 12px; cursor:pointer; float:left; appearance:none; -webkit-appearance:none;background: #fff url(../../assets/images/side_area/bt_open.png) no-repeat 95% center; width: 100%; padding: 0 20px 0 15px; line-height: 55px; height: 55px;  box-sizing: border-box; border-right:1px solid #e7e9ea; font-weight: bold; border-radius: 0; }
    .imgFileSchDiv select option { font-size: 14px; }
    .imgFileSchDiv select:focus { border-color: #477fff; background-image: url(../../assets/images/side_area/bt_close.png) }

    /* .imgUl */
    .imgUl { overflow: hidden; }
    .imgUl > li { position:relative; float:left; display:inline-block; width: 99.65px; height: 100px; border-bottom: 1px solid #f1f3f5; border-right:1px solid #f1f3f5; box-sizing:border-box; }
    .imgUl > li a { z-index: 10; position: absolute;left:0; top:0; display: block; width: 100%; height: 100%; font-size: 0;background-size: cover; background-position:center center;background-repeat: no-repeat; }
    .imgUl > li a:after { transition:0.2s; content:''; display: block; position: absolute; width: 100%; height: 100%; left: 0; top: 0; background:#000; opacity:0; }
    .imgUl > li:hover a:after { opacity:0.2;}
    .imgUl > li.on a:after { opacity:0.2;}
    .imgUl .delImg {display:none; opacity:0; z-index: 100; right: 10px; bottom:10px; width:25px; height: 25px; line-height: 25px; background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; position: absolute; font-size: 0; border-radius: 50%; }
    .imgUl > li:hover .delImg { opacity:1; }
    .imgUl > li.me .delImg, .rm .imgUl .delImg { display:block; }
    .imgUl > li .imgName { font-size:0; }

    .imgUl .ymList { display: none; overflow: hidden; width:100% !important; height: 40px; line-height: 38px; font-size: 14px; font-weight: bold; padding: 0 15px; box-sizing: border-box; background:#f7f9fb;border-top:1px solid #f1f3f5; box-sizing: border-box; }
    .imgUl .ymList span { color:#477fff; float: right; font-size: 13px; }
    .imgList .checkDiv { display:none !important; }

    #section_ce .imgList.editMode .ymList { display: none; }
    .imgList.editMode .checkDiv { display: block !important; opacity:1 !important; z-index: 10000; }
    .imgList.editMode .imgUl > li a:after { opacity:0.3;}
    .imgList.editMode .delImg { display:none !important; }
    .imgList li .checkDiv { left: 10px; top:10px; }
    .imgList li input[type='checkbox']:not(old) + label { padding: 0;width: 20px;height: 20px;border-radius:50%;border: 2px solid #fff;background: transparent url('../../assets/images/contents/checked_fff.png') no-repeat center center;background-size: 22px !important;font-size: 0;}
    .imgList li input[type='checkbox']:not(old):checked + label {background-color: #477fff; border-color:#fff;}
    .imgList li input[type='checkbox']:not(old) + label:after { z-index: 1000000; content:''; display:block; width:99.65px; height: 100px; position: absolute; left:-10px; top:-10px; }


    
    /* .fileList */
    .fileUl > li { position:relative; width:100%; display:inline-block; border-bottom: 1px solid #f1f3f5; box-sizing:border-box; padding: 10px 20px 10px 100px; }
    
    .fileList .checkDiv { display: none !important; }
    .fileList li { position: relative; height: 70px; }
    .fileList li:hover { background: #f7f9fb; }
    .fileList .file { background:#fff; position: absolute; left: 20px; top:13px; display: inline-block; width: 60px; height: 40px; border-radius: 5px; border:1px solid #e7e9ea; text-align: center; line-height: 40px;  }
    .fileList .file.format { font-weight: bold; color:#477fff; font-size: 12px; text-transform: uppercase; }
    .fileList .fileName { font-weight: bold; font-size: 12px; line-height: 30px; height: 25px; text-overflow:ellipsis; overflow: hidden; white-space: nowrap; }
    .fileList .fileSize { font-weight: bold; font-size: 12px; line-height: 20px; height: 20px; opacity:0.5; text-overflow:ellipsis; overflow: hidden; white-space: nowrap; }
    .delfile { display:none; position: absolute; right: 20px; top: 18px; width: 30px; height:30px;border-radius: 50%; font-size:0; background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center}
    .editMode.fileList .delfile { display: none !important; }
    .editMode.fileList .checkDiv { display:block !important; }
    .editMode.fileList li { padding-left: 135px; }
    .editMode.fileList .file { left: 55px; }
    .editMode.fileList li .checkDiv { top: 24px;}
    .fileList li:hover .delfile { display:block; }
    .fileList li:hover .delfile { display: none; }
    .fileList li.me:hover .delfile { display: block; }
    .rm .fileList li:hover .delfile { display: block; }
    


    /* 결과 없을 시  */
    .magDiv { position: relative; }
    .magDiv .noResult.on { display:block; }
    .magDiv .noResult { display:none; position: static; box-sizing: border-box; width: 100%; padding: 15px 20px; }
    .magDiv .noResult p { background:#f1f3f5; line-height: 60px; height: 60px; color: #93a0b1; font-size: 13px; } 


    /* 그룹 리스트 */
    .grpUl > li > div { overflow: hidden; position: relative; height: 115px; line-height: 65px; border-bottom: 1px solid #f1f3f5; box-sizing: border-box; padding: 0 110px 0 115px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;  }
    .grpUl > li > div .checkDiv { top: 25px; }
    .grpUl > li > div .makeRoom { height: 65px !important; line-height: 65px !important; }
    .grpUl > li > div .memberDiv { padding-top:10px; border-top:1px dashed #e7e9ea; overflow:hidden; position: absolute; bottom: 10px; width:325px; height: 31px; line-height: 31px; left: 53px; }
    .grpUl > li > div .memberDiv > * { float:left; }
    .grpUl > li > div .memberDiv p.first{ margin-left:0px; }
    .grpUl > li > div .memberDiv p { position:relative; width: 28px; height: 28px; border:2px solid #fff;box-sizing: border-box; margin-left:-7px;background-color:#fff; border-radius: 50%; font-size:0; background-size:cover; background-position: center center; background-repeat: no-repeat; }
    .grpUl > li > div .memberDiv p.last span {  position: absolute; background:rgba(0,0,0,0.6); width:100%; height: 100%; text-align: center; font-weight: bold; color:#fff;  border-radius: 50%; font-size: 11px !important; line-height: 24px ; text-shadow: 0 2px 2px rgb(0,0,0);}
    .grpUl > li > div .memberDiv .seeMembers { float:right; margin-top:1px; height: 23px; line-height: 23px; background:#f1f3f5; border:1px solid #e7e9ea; padding:0 15px; border-radius: 15px; font-weight: bold; }
    .grpUl > li > div:hover .memberDiv .seeMembers { background:#477fff; color:#fff; border-color:#477fff }
    .grpUl > li > div .memberDiv .seeMembers:after {  top:0; left:0;position: absolute; width: 100%; height: 100%; content:'';display:block; background: transparent; }
    .grpUl > li > div .memberDiv .seeMembers.on { background:#ff6363; font-size:0; color:#fff; }
    .grpUl > li > div .memberDiv .seeMembers.on:after { position:absolute; right:0; top:11px; padding:0 15px;height: 25px; line-height: 25px; width:100%; box-sizing:border-box; border-radius: 20px; display:block; content:'닫기'; text-align: center;  font-size: 12px !important;background:#ff6363; color:#fff;}
    .grpUl > li > div .memberDiv .seeMembers.on:hover:after {background:#ff3939}
    .grpUl > li > div:hover  { background:#f7f9fb; }
    .grpUl > li > div > * { float: left; }
    .grpUl > li > div.on { padding-right: 50px; }
    .grpUl > li > div.on .makeRoom { display:block !important; opacity: 1 !important; }
    .grpUl > li > div.on .oac  {display:none; }
    .grpUl > li .oac { transition:0.1s; position: absolute; right: 20px; width:auto; padding: 0 15px;height: 25px; box-sizing: border-box; border:1px solid #e7e9ea; line-height: 23px;top:21px; border-radius: 15px; font-size:12px; font-weight:bold; text-align:center;background:#fff; } 
    .grpUl > li > div:hover > .oac { right: 50px; }
    .grpUl > li .oac:hover { background:#fff; color:#477fff; border-color:#477fff }
    .grpUl > li .oac.on { background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; background-size: 15px; border-color:#ff6363; font-size:0; }
    .grpUl > li .grpName { font-size: 13px; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width:100%;}
    .grpUl > li .grpName span.clr { font-size:0; width: 8px; height:8px; margin-top:29px; margin-right:8px; border-radius: 5px; float:left; display: inline-block;  }
    .grpUl > li .grpPic { background-color:#fff; width: 45px; height: 35px;font-size: 0; border-radius: 5px; position:absolute; left: 55px; top:14px; border: 1px solid #f1f3f5; background-size: cover; background-position: center center;background-repeat: no-repeat;  }
    .grpUl > li .makeRoom { height: 60px !important; line-height: 60px !important; }
    .grpUl > li .checkDiv { top: 17px; }
    .grpUl > li div a.makeRoom { opacity:0 !important; }
    .grpUl > li div:hover a.makeRoom { opacity:1 !important; }
    .grpUl .grpLi.on div .checkDiv { display: block; }
    .grpUl .grpLi div .checkDiv { display: none; }
    .grpUl .grpLi > div { transition:0.1s; padding-left: 80px; } 
    .grpUl .grpLi > div .grpPic { transition:0.1s; left: 20px; }
    .grpUl .grpLi > div .memberDiv {transition:0.1s; left: 20px; width: 358px;  }
    .grpUl .grpLi.on > div { padding-left: 110px; }
    .grpUl .grpLi.on > div .grpPic { left: 55px; }
    .grpUl .grpLi.on > div .memberDiv { left: 53px; width: 325px; }

    /* 멤버 리스트 */
    .grpUl > li.on .memberUl { display: block;}
    .memberUl { display:none; }
    .memberUl {border-bottom: 3px solid #e7e9ea;}
    .memberUl li:hover { background:#f7f9fb !important; }
    .memberUl li .mName span { display:inline-block; width: 20px; }
    .memberUl li .makeRoom { opacity:0 !important }
    .memberUl li:hover .makeRoom { opacity:1 !important }


    /* 팀리스트 */
    .teamList { display:none !important; }
    .teamList.teamOn { display:block !important; }
    .teamList { position: absolute; top:0; height:100%; left:0; background:#fff; width:100%; }
    .teamList .grpLiDiv { height: 65px; }
    .teamList .memberUl.on { display:block; }
    .teamList .mainGrp  { font-weight: bold; overflow: hidden; position: relative; padding: 0 25px 0 115px; line-height: 45px; height: 70px; border-bottom:2px solid #e7e9ea; }
    .teamList .mainGrp:hover { background: #f7f9fb }
    .teamList .mainGrp > * { float: left; }
    .teamList .mainGrp .prev { position: absolute;left:0;top:0; display: inline-block;float: left;width: 45px;height: 100%;background:  url(../../assets/images/contents/month_bt_prev.svg) no-repeat 8px center; background-size: 24px; font-size: 0; }
    .teamList .mainGrp .prev:hover { background-image: url(../../assets/images/contents/month_bt_prev_on.svg) }
    .teamList .mainGrp .grpPic { position: absolute; left: 45px; top: 13px; display: block; border-radius: 5px; border: 1px solid #f1f3f5; background-size: cover; background-position: center center; background-repeat: no-repeat; width: 55px; height: 45px; box-sizing: border-box; font-size: 0; ; }
    .teamList .mainGrp .grpName { font-size: 14px; width:100%; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; }
    .teamList .mainGrp .grpName span.clr { font-size:0; width: 8px; height:8px; margin-top:19px; margin-right:8px; border-radius: 5px; float:left; display: inline-block; }
    .teamList .mainGrp .oac { position: absolute; left:115px; bottom:14px; top: auto; line-height: 19px; height:19px;  width:100%; overflow:hidden; text-overflow: ellipsis; white-space: nowrap;}
    .teamList .mainGrp .oac span { float: left; display:inline-block; height: 100%; padding: 0 8px; margin-right:10px; border-radius: 3px; background:#f1f3f5; }
    .teamList .mainGrp:hover .oac span { background:#fff; }
    .teamList .mainGrp .makeRoom { height: 70px; line-height: 70px; opacity:1 !important  }

    .teamList .grpUl .oac:hover { background:#477fff; border-color:#477fff; color:#fff; }
    .teamList .grpUl .oac.on { font-size:0; color:#fff; background:#fff; }
    .teamList .grpUl .oac.on:after { transition:0.2s; position:absolute;background:#ff6363; right:-1px; top:-2px; padding:0 16px;height: 27px; line-height: 27px; box-sizing:border-box; border-radius: 20px; display:block; content:'닫기'; text-align: center;  font-size: 12px !important;background:#ff6363; color:#fff;}
    .teamList .grpUl .oac.on:hover:after {background:#ff3939}

    .teamList .grpLi.on div .checkDiv { display: block; }
    .teamList .grpLi div .checkDiv { display: none; }
    .teamList .grpLi > div { transition:0.1s; padding-left: 80px; } 
    .teamList .grpLi > div .grpPic { transition:0.1s; left: 20px; }
    .teamList .grpLi > div .memberDiv {transition:0.1s; left: 20px; width: 358px;  }
    .teamList .grpLi.on > div { padding-left: 110px; }
    .teamList .grpLi.on > div .grpPic { left: 55px; }
    .teamList .grpLi.on > div .memberDiv { left: 53px; width: 325px; }


    
    /* .rightSettingDiv */
    .rightSettingDiv { display:none; }
    .rightSettingDiv.on { z-index: 100000; display: none; position: absolute; left: 0;top:60px; width:100%; height: 824px; background: #fff; }
    .rightSettingDiv.on { display: block }
    .rightSettingDiv li { position: relative; padding:0 60px 0 20px; height: 50px; line-height: 49px; border-bottom: 1px solid #f1f3f5; box-sizing:border-box; } 
    .rightSettingDiv li p { font-size: 12px; font-weight: normal; width:100%; text-overflow:ellipsis; overflow: hidden; white-space: nowrap; }
    .rightSettingDiv li:hover p { font-weight: bold; }
    .rightSettingDiv li p span { display: inline-block; width: 5px; height: 5px; margin-right:8px; opacity:0.2; border-radius: 50%; background:#000; }
    .rightSettingDiv li .btn { position: absolute; right:0; top:0; padding:0;}
    .rightSettingDiv li .btn input { height: 50px; width: 60px; padding-right:25px; box-sizing:border-box; text-align: right; background:transparent; font-size: 12px; float:right }
    .rightSettingDiv li .btn input:hover { color:#ff6363;font-weight: bold; }
    .rightSettingDiv .rightSetting { padding-bottom: 100px; }
    .rightSettingDiv li .btn input.chngeClr { transition:0.2s; width: 25px; height: 25px; border-radius: 50%; border: 1px solid #e7e9ea; font-size:0;margin-right: 20px;margin-top: 12px;float: right; box-sizing:border-box; padding:0; }
    .rightSettingDiv li .btn input.chngeClr:hover { border:3px solid #e7e9ea; }
    .rightSettingDiv .title { border-bottom:1px solid #f1f3f5; background:#f1f3f5; height: 40px; line-height: 40px; padding:0 20px; font-size: 13px; font-weight: bold; }
    .rightSettingDiv .delThisRoom { color:#ff6363; }
    .rightSettingDiv li.delThisRoom p span { background-color:#ff6363; }
    .rightSettingDiv .mainTitle p.dets span { opacity: 0.5; display:block; height: 25px; line-height: 25px; font-weight: bold; }
    .rightSettingDiv .mainTitle > div { padding-left: 100px; box-sizing: border-box; }
    .rightSettingDiv .mainTitle { overflow: hidden; position:relative; padding: 10px 20px 10px; box-sizing:border-box; width:100%; }
    .rightSettingDiv .mainTitle p { padding: 10px 0 10px !important;}
    .rightSettingDiv .mainTitle p.title { padding:0; background: none; border-bottom:0 none; height: auto;line-height: unset;padding-right: 0 !important; font-size: 14px; margin-bottom:5px; margin-bottom: 10px; padding-top: 0 !important; padding-bottom:0 !important }
    .rightSettingDiv .mainTitle p.descript { height: auto; line-height: 20px; }
    .rightSettingDiv .mainTitle input { position: absolute;right: 20px;top: 20px; height: 35px; line-height: 35px; width: 35px; border-radius: 50%; font-weight: bold; background:#ffbe0f url(../../assets/images/folder_file/fd_study.png) no-repeat -4px -1px; background-size: 48px; font-size: 0; }
    .rightSettingDiv .mainTitle input:hover { background-color:#fbb700 }
    .rightSettingDiv .mainTitle .crImg { position: absolute; left:20px; top:10px; float: left; display: block;width: 80px;margin: 0 auto; height: 80px; border:1px solid #f1f3f5; border-radius: 5px; font-size: 0;margin-bottom:5px; margin-top:10px; padding:0 !important; background-size: cover; background-position: center center; background-repeat: no-repeat }

    /* 채팅룸 추가 버튼 */
    .addBtn  { position:fixed;right:490px; bottom:40px; opacity: 1;z-index: 1000;transition: 0.1s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/ic_addChatroom_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 40px !important; }
    .addBtn:hover {background: #477fff url('../../assets/images/contents/ic_addChatroom_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 45px !important;}
    .addBtn.quick { right: 180px; }
    .addBtn.dntknow { right: 300px; }
    .section_ce_fix.rightDivClosed .addBtn { right: 100px; }

    #chatting ::-webkit-scrollbar{width: 6px;}
    #chatting ::-webkit-scrollbar-track{background-color: #f1f3f5;}
    #chatting ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
    
    /* 추후 삭제 */

    /* 채팅 대표 이미지 */
    /* .rightSettingDiv .mainTitle .crImg { background-image: url(../../assets/images/footer/g7.jpg) }

    .imgUl > li:nth-child(1) a { background-image: url(../../assets/images/footer/g1.jpg); }
    .imgUl > li:nth-child(2) a { background-image: url(../../assets/images/footer/g2.jpg); }
    .imgUl > li:nth-child(3) a { background-image: url(../../assets/images/footer/g3.jpg); }
    .imgUl > li:nth-child(4) a { background-image: url(../../assets/images/footer/g4.jpg); }
    .imgUl > li:nth-child(5) a { background-image: url(../../assets/images/footer/g5.jpg); }
    .imgUl > li:nth-child(6) a { background-image: url(../../assets/images/footer/g6.jpg); }
    .imgUl > li:nth-child(7) a { background-image: url(../../assets/images/footer/g7.jpg); }
    .imgUl > li:nth-child(8) a { background-image: url(../../assets/images/footer/g8.jpg); }
    .imgUl > li:nth-child(9) a { background-image: url(../../assets/images/footer/g9.jpg); }

    .imgUl > li:nth-child(10) a { background-image: url(../../assets/images/footer/g10.jpg); }
    .imgUl > li:nth-child(11) a { background-image: url(../../assets/images/footer/g11.jpg); }
    .imgUl > li:nth-child(12) a { background-image: url(../../assets/images/footer/g12.jpg); }
    .imgUl > li:nth-child(13) a { background-image: url(../../assets/images/footer/g13.jpg); }
    .imgUl > li:nth-child(14) a { background-image: url(../../assets/images/footer/g15.jpg); }
    .imgUl > li:nth-child(15) a { background-image: url(../../assets/images/footer/g14.jpg); }
    .imgUl > li:nth-child(16) a { background-image: url(../../assets/images/footer/g16.jpg); }
    .imgUl > li:nth-child(17) a { background-image: url(../../assets/images/footer/f38.jpg); }
    .imgUl > li:nth-child(18) a { background-image: url(../../assets/images/footer/f33.jpg); }

    .imgUl > li:nth-child(19) a { background-image: url(../../assets/images/footer/f28.jpg); }
    .imgUl > li:nth-child(20) a { background-image: url(../../assets/images/footer/f25.jpg); }
    .imgUl > li:nth-child(21) a { background-image: url(../../assets/images/footer/f11.jpg); } */

    /* 채팅룸 스크롤 예제 */
    /* .chatroom > .scroll { height: 745px;overflow-y: auto; } */

    /* chatsUl li 사진 */
    /* .chatsUl li:nth-child(1) .mPic { background-image: url(../../assets/images/footer/f3.jpg); }
    .chatsUl li:nth-child(2) .mPic { background-image: url(../../assets/images/footer/f2.jpg); }
    .chatsUl li:nth-child(3) .mPic { background-image: url(../../assets/images/footer/f1.jpg); }

    .chatsUl li:nth-child(4) .mPic { background-image: url(../../assets/images/footer/f4.jpg); }
    .chatsUl li:nth-child(5) .mPic { background-image: url(../../assets/images/footer/f5.jpg); }
    .chatsUl li:nth-child(6) .mPic { background-image: url(../../assets/images/footer/f6.jpg); }

    .chatsUl li:nth-child(7) .mPic { background-image: url(../../assets/images/footer/f3.jpg); }
    .chatsUl li:nth-child(8) .mPic { background-image: url(../../assets/images/footer/f2.jpg); }
    .chatsUl li:nth-child(9) .mPic { background-image: url(../../assets/images/footer/f1.jpg); }


    .chatsUl li .msg.file.vid  a { background-image: url(../../assets/images/footer/g1.jpg); } */

    /* 친구 사진 */
    /* .mateUl li:nth-child(1) .mPic { background-image: url(../../assets/images/footer/f1.jpg); }
    .mateUl li:nth-child(2) .mPic { background-image: url(../../assets/images/footer/f2.jpg); }
    .mateUl li:nth-child(3) .mPic { background-image: url(../../assets/images/footer/f3.jpg); }
    .mateUl li:nth-child(4) .mPic { background-image: url(../../assets/images/footer/f4.jpg); }
    .mateUl li:nth-child(5) .mPic { background-image: url(../../assets/images/footer/f5.jpg); }
    .mateUl li:nth-child(6) .mPic { background-image: url(../../assets/images/footer/f6.jpg); }
    .mateUl li:nth-child(7) .mPic { background-image: url(../../assets/images/footer/f7.jpg); }
    .mateUl li:nth-child(8) .mPic { background-image: url(../../assets/images/footer/f8.jpg); }
    .mateUl li:nth-child(9) .mPic { background-image: url(../../assets/images/footer/f9.jpg); }
    .mateUl li:nth-child(10) .mPic { background-image: url(../../assets/images/footer/f10.jpg); }
    .mateUl li:nth-child(11) .mPic { background-image: url(../../assets/images/footer/f11.jpg); }
    .mateUl li:nth-child(12) .mPic { background-image: url(../../assets/images/footer/f12.jpg); }
    .mateUl li:nth-child(13) .mPic { background-image: url(../../assets/images/footer/f13.jpg); }
    .mateUl li:nth-child(14) .mPic { background-image: url(../../assets/images/footer/f14.jpg); }

    .mateUl li.me .mPic { background-image: url(../../assets/images/footer/f23.jpg); } */


    /* 그룹 사진 */
    /* .grpUl > li:nth-child(1) .grpPic { background-image: url(../../assets/images/footer/g4.jpg); }
    .grpUl > li:nth-child(2) .grpPic { background-image: url(../../assets/images/footer/g10.jpg); }
    .grpUl > li:nth-child(3) .grpPic { background-image: url(../../assets/images/footer/g7.jpg); }
    .grpUl > li:nth-child(4) .grpPic { background-image: url(../../assets/images/footer/g1.jpg); } */

    /* 그룹 사진 */
    /* .teamList .mainGrp .grpPic { background-image: url(../../assets/images/footer/g4.jpg); }
    .teamList .grpUl > li:nth-child(1) .grpPic { background-image: url(../../assets/images/footer/g2.jpg); }
    .teamList .grpUl > li:nth-child(2) .grpPic { background-image: url(../../assets/images/footer/g9.jpg); }
    .teamList .grpUl > li:nth-child(3) .grpPic { background-image: url(../../assets/images/footer/g11.jpg); } */

    /* 그룹 멤버 사진 */
    /* .grpUl li:nth-child(1) > div .memberDiv p:nth-child(1) { background-image: url(../../assets/images/footer/f15.jpg); }
    .grpUl li:nth-child(1) > div .memberDiv p:nth-child(2) { background-image: url(../../assets/images/footer/f16.jpg); }
    .grpUl li:nth-child(1) > div .memberDiv p:nth-child(3) { background-image: url(../../assets/images/footer/f17.jpg); }
    .grpUl li:nth-child(1) > div .memberDiv p:nth-child(4) { background-image: url(../../assets/images/footer/f18.jpg); }

    .grpUl li:nth-child(2) > div .memberDiv p:nth-child(1) { background-image: url(../../assets/images/footer/f26.jpg); }
    .grpUl li:nth-child(2) > div .memberDiv p:nth-child(2) { background-image: url(../../assets/images/footer/f27.jpg); }
    .grpUl li:nth-child(2) > div .memberDiv p:nth-child(3) { background-image: url(../../assets/images/footer/f28.jpg); }
    .grpUl li:nth-child(2) > div .memberDiv p:nth-child(4) { background-image: url(../../assets/images/footer/f29.jpg); }
    
    .grpUl li:nth-child(3) > div .memberDiv p:nth-child(1) { background-image: url(../../assets/images/footer/f10.jpg); }
    .grpUl li:nth-child(3) > div .memberDiv p:nth-child(2) { background-image: url(../../assets/images/footer/f11.jpg); }
    .grpUl li:nth-child(3) > div .memberDiv p:nth-child(3) { background-image: url(../../assets/images/footer/f12.jpg); }
    .grpUl li:nth-child(3) > div .memberDiv p:nth-child(4) { background-image: url(../../assets/images/footer/f13.jpg); } */

    /* 그룹 멤버 사진 */
    /* .grpUl li:nth-child(1) li:nth-child(1) .mPic { background-image: url(../../assets/images/footer/f15.jpg); }
    .grpUl li:nth-child(1) li:nth-child(2) .mPic { background-image: url(../../assets/images/footer/f16.jpg); }
    .grpUl li:nth-child(1) li:nth-child(3) .mPic { background-image: url(../../assets/images/footer/f17.jpg); }
    .grpUl li:nth-child(1) li:nth-child(4) .mPic { background-image: url(../../assets/images/footer/f18.jpg); }
    .grpUl li:nth-child(1) li:nth-child(5) .mPic { background-image: url(../../assets/images/footer/f20.jpg); }
    .grpUl li:nth-child(1) li:nth-child(6) .mPic { background-image: url(../../assets/images/footer/f21.jpg); }
    .grpUl li:nth-child(1) li:nth-child(7) .mPic { background-image: url(../../assets/images/footer/f22.jpg); }
    .grpUl li:nth-child(1) li:nth-child(8) .mPic { background-image: url(../../assets/images/footer/f23.jpg); }
    .grpUl li:nth-child(1) li:nth-child(9) .mPic { background-image: url(../../assets/images/footer/f11.jpg); }

    .grpUl li:nth-child(2) li:nth-child(1) .mPic { background-image: url(../../assets/images/footer/f26.jpg); }
    .grpUl li:nth-child(2) li:nth-child(2) .mPic { background-image: url(../../assets/images/footer/f27.jpg); }
    .grpUl li:nth-child(2) li:nth-child(3) .mPic { background-image: url(../../assets/images/footer/f28.jpg); }
    .grpUl li:nth-child(2) li:nth-child(4) .mPic { background-image: url(../../assets/images/footer/f29.jpg); }
    .grpUl li:nth-child(2) li:nth-child(5) .mPic { background-image: url(../../assets/images/footer/f30.jpg); }
    .grpUl li:nth-child(2) li:nth-child(6) .mPic { background-image: url(../../assets/images/footer/f31.jpg); }
    .grpUl li:nth-child(2) li:nth-child(7) .mPic { background-image: url(../../assets/images/footer/f32.jpg); }
    .grpUl li:nth-child(2) li:nth-child(8) .mPic { background-image: url(../../assets/images/footer/f33.jpg); }
    .grpUl li:nth-child(2) li:nth-child(9) .mPic { background-image: url(../../assets/images/footer/f34.jpg); }
    .grpUl li:nth-child(2) li:nth-child(10) .mPic { background-image: url(../../assets/images/footer/f35.jpg); }
    .grpUl li:nth-child(2) li:nth-child(11) .mPic { background-image: url(../../assets/images/footer/f36.jpg); }

    .grpUl li:nth-child(3) li:nth-child(1) .mPic { background-image: url(../../assets/images/footer/f10.jpg); }
    .grpUl li:nth-child(3) li:nth-child(2) .mPic { background-image: url(../../assets/images/footer/f11.jpg); }
    .grpUl li:nth-child(3) li:nth-child(3) .mPic { background-image: url(../../assets/images/footer/f12.jpg); }
    .grpUl li:nth-child(3) li:nth-child(4) .mPic { background-image: url(../../assets/images/footer/f13.jpg); }
    .grpUl li:nth-child(3) li:nth-child(5) .mPic { background-image: url(../../assets/images/footer/f6.jpg); }
    .grpUl li:nth-child(3) li:nth-child(6) .mPic { background-image: url(../../assets/images/footer/f7.jpg); }
    .grpUl li:nth-child(3) li:nth-child(7) .mPic { background-image: url(../../assets/images/footer/f8.jpg); }
    .grpUl li:nth-child(3) li:nth-child(8) .mPic { background-image: url(../../assets/images/footer/f9.jpg); }
    .grpUl li:nth-child(3) li:nth-child(9) .mPic { background-image: url(../../assets/images/footer/f10.jpg); } */

    /* 채팅룸 리스트 사진 */
    /* .chatroomUl li:nth-child(3) .peepsDiv .img { background-image: url(../../assets/images/footer/f9.jpg); }
    .chatroomUl li:nth-child(2) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f1.jpg); }
    .chatroomUl li:nth-child(2) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f2.jpg); }
    .chatroomUl li:nth-child(1) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f3.jpg); }
    .chatroomUl li:nth-child(1) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f7.jpg); }
    .chatroomUl li:nth-child(4) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f8.jpg); }
    .chatroomUl li:nth-child(4) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f12.jpg); }
    .chatroomUl li:nth-child(5) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f6.jpg); }
    .chatroomUl li:nth-child(5) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f14.jpg); }
    .chatroomUl li:nth-child(6) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f23.jpg); }
    .chatroomUl li:nth-child(7) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f11.jpg); }
    .chatroomUl li:nth-child(7) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f14.jpg); }
    .chatroomUl li:nth-child(8) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f4.jpg); }
    .chatroomUl li:nth-child(8) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f12.jpg); }
    .chatroomUl li:nth-child(9) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f14.jpg); }
    .chatroomUl li:nth-child(9) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f11.jpg); } */


    /* .chatroomUl li.specList .peepsDiv.one p { background-image: url(../../assets/images/footer/g7.jpg) !important; } */
    
</style>