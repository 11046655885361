<template>
    <div id="resident_move_into_request" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <div class="title_box">
            <a href="#" class="bt_back"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">입주 예약 현황</h3>
        </div>

        <div id="move_into_detail">
            <div class="moveinBox">
                <ul class="moveinBoxUl settingBg">
                    <li class="inputLi left">
                        <div class="grp">
                            <label for="" class="from blind">시작일자</label>
                            <input type="button" id="reservation_from_ymd" :value="`${hodu_date_to_format_string(start, 'YYYY.MM.DD')} ${getDayOfWeekByDate(start)}`" class="calFrom">
                        </div>
                        <span class="seper"></span>
                        <div class="grp">
                            <label for="" class="from blind">종료일자</label>
                            <input type="button" id="reservation_to_ymd" :value="`${hodu_date_to_format_string(end, 'YYYY.MM.DD')} ${getDayOfWeekByDate(end)}`" class="calTo">
                        </div>
                        <input type="button" value="" class="toolbar-btn bt-prev mr10" @click.prevent="prev">
                        <input type="button" value="" class="toolbar-btn bt-next mr10" @click.prevent="next">
                    </li>
                    <!-- 기본 첫동 / 첫 라인을 selected -->
                    <li>
                        <select name="aptBuilding" id="aptBuilD" v-model="selected_building">
                            <option :key="building.building" :value="building.building" v-for="building in computedBuildings">{{ building.building }}동</option>
                            <!-- <option value="101동" selected="selected">101동</option>
                            <option value="102동">102동</option>
                            <option value="103동">103동</option>
                            <option value="104동">104동</option>
                            <option value="105동">105동</option>
                            <option value="106동">106동</option>
                            <option value="107동">107동</option>
                            <option value="108동">108동</option>
                            <option value="201동">201동</option>
                            <option value="202동">202동</option>
                            <option value="203동">203동</option>
                            <option value="204동">204동</option>
                            <option value="205동">205동</option>
                            <option value="206동">206동</option>
                            <option value="301동">301동</option>
                            <option value="302동">302동</option> -->
                        </select>
                    </li>
                    <li>
                        <select name="apartmentLine" id="aptLine" v-model="selected_line">
                            <option :key="line.line_id" :value="line.line_id" v-for="line in computedLines">{{ line.line_data.name }}</option>
                            <!-- <option value="1~2 라인" selected="selected">1~2 라인</option>
                            <option value="3~5 라인">3~5 라인</option>
                            <option value="6 라인">6 라인</option> -->
                        </select>
                    </li>
                </ul>
            </div>

            <div id="move_in_box_table" class="moveinBox moveinBoxTable">
                <div class="tableBoxWrap">
                    <div class="reservationColr">
                        <p><span class="reWaiting">민트</span>예약대기</p>
                        <p><span class="reComplet">회색</span>예약완료</p>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>요일</th>
                                <th :colspan="times.length">회차 및 시간</th>
                            </tr>
                        </thead>
                        <!-- 예약 완료시 span에 클래스 reservationComplet 추가--> 
                        <tbody>
                            <tr :key="request.key" v-for="request in requests">
                                <th>{{ getDayOfWeekByDate(yyyymmddToDate(request.key)) }}</th>
                                <td :key="time.time_id" v-for="time in request.value">
                                    <span :class="{ reservationComplet : time.request_id != null }">
                                        {{ time.time_seq }}회차 ({{ `${time.time_start.substring(0,2)}:${time.time_start.substring(2)}` }} ~ {{ `${time.time_end.substring(0,2)}:${time.time_end.substring(2)}` }})
                                    </span>
                                </td>
                            </tr>
                            <!-- <tr>
                                <th>일</th>
                                <td><span class="reservationComplet">1회차 (09:00 ~ 11:30)</span></td>
                                <td><span>2회차 (11:30 ~ 14:00)</span></td>
                                <td><span>3회차 (14:00 ~ 16:30)</span></td>
                            </tr>
                            <tr>
                                <th>월</th>
                                <td><span>1회차 (09:00 ~ 11:30)</span></td>
                                <td><span>2회차 (11:30 ~ 14:00)</span></td>
                                <td><span class="reservationComplet">3회차 (14:00 ~ 16:30)</span></td>
                            </tr>
                            <tr>
                                <th>화</th>
                                <td><span class="reservationComplet">1회차 (09:00 ~ 11:30)</span></td>
                                <td><span class="reservationComplet">2회차 (11:30 ~ 14:00)</span></td>
                                <td><span class="reservationComplet">3회차 (14:00 ~ 16:30)</span></td>
                            </tr>
                            <tr>
                                <th>수</th>
                                <td><span>1회차 (09:00 ~ 11:30)</span></td>
                                <td><span class="reservationComplet">2회차 (11:30 ~ 14:00)</span></td>
                                <td><span class="reservationComplet">3회차 (14:00 ~ 16:30)</span></td>
                            </tr>
                            <tr>
                                <th>목</th>
                                <td><span>1회차 (09:00 ~ 11:30)</span></td>
                                <td><span>2회차 (11:30 ~ 14:00)</span></td>
                                <td><span>3회차 (14:00 ~ 16:30)</span></td>
                            </tr>
                            <tr>
                                <th>금</th>
                                <td><span class="reservationComplet">1회차 (09:00 ~ 11:30)</span></td>
                                <td><span class="reservationComplet">2회차 (11:30 ~ 14:00)</span></td>
                                <td><span class="reservationComplet">3회차 (14:00 ~ 16:30)</span></td>
                            </tr>
                            <tr class="last_tr">
                                <th>토</th>
                                <td><span>1회차 (09:00 ~ 11:30)</span></td>
                                <td><span>2회차 (11:30 ~ 14:00)</span></td>
                                <td><span class="reservationComplet">3회차 (14:00 ~ 16:30)</span></td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { hodu_web_socket } from '@/lib/HoduWebSocket';
import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class ResidentMoveIntoRequest extends Mixins(VueHoduCommon) {

    /**
     * 동
     */
    get computedBuildings() : any[] {

        let buildings = JSON.parse(JSON.stringify(this.buildings));

        buildings = buildings.filter(building => building.lines.length > 0);

        return buildings;
    }

    /**
     * 라인
     */
    get computedLines() : any[] {

        let lines : any[] = [];

        if( this.selected_building != null && this.selected_building.length > 0 ) {
            const filter = this.buildings.filter(building => building.building == this.selected_building);
            if( filter.length > 0 ) {
                lines.splice(0, lines.length);
                lines = lines.concat(filter[0].lines);
            }
        }

        return lines;
    }

    start = new Date();
    end = new Date();

    selected_building : string = "";
    selected_line : string = "";

    buildings : any[] = [];
    times : any[] = [];
    requests : any[] = [];

    mounted() : void {
        const moment_start_date = moment();
        const start_date = moment_start_date.toDate();

        this.start = moment_start_date.set('date', moment_start_date.get('date') - start_date.getDay()).toDate();
        this.end   = moment_start_date.set('date', moment_start_date.get('date') + 6).toDate();

        this.getBuildings();
        this.setScroll();
    }

    /**
     * 웹소켓 닫기
     */
    beforeDestroy() : void {
        this.closeWebSocket();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('.title_box').outerHeight();
        const moveinBoxHeight : number | undefined = $('.moveinBox').outerHeight();

        // @ts-ignore
        $('#move_in_box_table').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (moveinBoxHeight ? moveinBoxHeight : 0),
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#move_in_box_table").mCustomScrollbar('destroy');

    	this.setScroll();
    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(selected_date)}`);

                    // 일주일 간격
                    if( inst.id == 'reservation_from_ymd' ) {
                        this.start = moment(selected_date).add('day', -selected_date.getDay()).toDate();
                        this.end   = moment(this.start).add('day', 6).toDate();
                        inst.input.val(`${this.hodu_date_to_format_string(this.start, "YYYY.MM.DD")}`);
                    }

                    else if( inst.id == 'reservation_to_ymd' ) {
                        this.end   = moment(selected_date).add('day', 6 - selected_date.getDay()).toDate();
                        this.start = moment(this.end).add('day', -6).toDate();
                        inst.input.val(`${this.hodu_date_to_format_string(this.end, "YYYY.MM.DD")}`);
                    }

                    this.getMoveIntoRequest();
                },
            }

            // @ts-ignore
            $('#reservation_from_ymd').datepicker(datepicker_option);

            // @ts-ignore
            $('#reservation_to_ymd').datepicker(datepicker_option);
        });
    }

    /**
     * 동 조회
     */
    async getBuildings() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/building`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.buildings ) {
                throw new Error("동 조회 중 오류 발생");
            }

            this.buildings.splice(0, this.buildings.length);
            this.buildings = this.buildings.concat(response.data.data.buildings);

            const buildings = this.buildings.filter(building => building.lines.length > 0)

            if( this.selected_building.length < 1 && buildings.length > 0 ) {
                this.selected_building = buildings[0].building;

                if( buildings[0].lines.length > 0 ) {
                    this.selected_line = buildings[0].lines[0].line_id;
                }
            }

            const host = location.host;

            let url = "";
            if( new RegExp(/192\.168\.192\.{1,3}/).test(host) == true || new RegExp(/127\.0\.0\.1/).test(host) == true || new RegExp('localhost').test(host) == true ) {
                url = "http://localhost:3020";
            }
            else if( new RegExp(/\.dev\.hodu/).test(host) == true ) {
                url = "https://socket.dev.hoduware.com";
            }
            else {
                url = "https://socket.hodu.app";
            }

            // 웹소켓 연결
            this.openWebSocket(`${url}/api/v1/socket/home/${this.scope_group_id}`);

            this.datepickerInit();

        } catch(e) {
            this.hodu_show_dialog("cancel", "동 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 예약 조회 요청
     */
    getMoveIntoRequest() : void {
        this.send(JSON.stringify({
            "type" : "home_move_into_request_calendar",
            "start" : this.hodu_date_to_format_string(this.start, 'YYYYMMDD'),
            "end" : this.hodu_date_to_format_string(this.end, 'YYYYMMDD'),
            "group_id" : this.scope_group_id,
            "building" : this.selected_building,
            "line_id" : this.selected_line 
        }));
    }

    /**
     * 웹 소켓 열기
     */
    openWebSocket(url : string, is_retry : boolean = false) : void {
        console.log(`TRY CONNECT WEB SOCKET : ${url}`);

        const device_uid = sessionStorage.getItem("device_uid");
        const session_token = sessionStorage.getItem("session_token");

        if( device_uid == null || session_token == null ) { return; }

        // 재시도라면 원래 URL 그대로 사용
        if( is_retry == false ) {
            url = url.replace(/https:/ig, 'wss:').replace(/http:/ig, 'ws:');
        }

        try {
            hodu_web_socket.connect(`${url}?device-uid=${device_uid}&authorization=${session_token}`);
        } catch(e) {
            return;
        }

        // 웹소켓 콜백 등록
        hodu_web_socket.onopen(this.webSocketOnOpen);

        hodu_web_socket.onerror((event : Event) => {
            console.log("WEB SOCKET ERROR");
            hodu_web_socket.close();
        });

        hodu_web_socket.onclose((event : CloseEvent) => {
            console.log(`CLOSE WEB SOCKET : ${hodu_web_socket.getWebSocket()?.url}`);
        });

    }

    /**
     * 웹소켓 onopen 콜백
     */
    webSocketOnOpen(event : Event) : void {
        console.log(`OPEN WEB SOCKET : ${hodu_web_socket.getWebSocket()?.url}`);
        hodu_web_socket.onmessage(this.webSocketOnMessage);
    }

    /**
     * 웹소켓 onmessage 콜백
     */
    webSocketOnMessage({data}) : void {

        // 데이터 수신
        data = JSON.parse(data);
        console.log(data);

        switch( data.type ) {
            case "home_move_into_option":
                this.times.splice(0, this.times.length);
                this.times = this.times.concat(data.data.times);
                this.getMoveIntoRequest();
                break;

            case "home_move_into_request_calendar":
                this.requests.splice(0, this.requests.length);
                for( const key in data.data ) {
                    this.requests.push({
                        "key" : key,
                        "value" : data.data[key]
                    });
                }
                break;

            case "home_move_into_time":
            case "home_move_into_line":
            case "home_move_into_request":
                this.closeWebSocket();
                this.getBuildings();
                break;
        }
        
    }

    /**
     * 웹 소켓에 데이터 송신
     */
    send(data : string) : void {
        console.log(data);
        hodu_web_socket.send(data);
    }

    /**
     * 웹 소켓 닫기
     */
    closeWebSocket() : void {
        hodu_web_socket.close();
    }

    /**
     * 이전 날짜
     */
    prev() : void {
        this.start.setDate(this.start.getDate() - 7);
        this.end.setDate(this.end.getDate() - 7);
        this.getMoveIntoRequest();
    }

    /**
     * 다음 날짜
     */
    next() : void {
        this.start.setDate(this.start.getDate() + 7);
        this.end.setDate(this.end.getDate() + 7);
        this.getMoveIntoRequest();
    }

    /**
     * 동 변경
     */
    @Watch('selected_building')
    changeSelectedBuilding() {
        if( this.selected_building != null && this.selected_building.length > 0 ) {
            const filter = this.buildings.filter(building => building.building == this.selected_building);
            if( filter.length > 0 ) {
                const lines = filter[0].lines;
                if( lines.length > 0 ) {
                    this.selected_line = lines[0].line_id;
                }
            }
        }
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 라인 변경
     */
    @Watch('selected_line')
    changeSelectedLine() {
        // 웹소켓 재조회
    }

}
</script>

<style scoped>
    #resident_move_into_request { background: #fbfcfd; }
    #resident_move_into_request .title_box { background: #fff; }
    #resident_move_into_request .moveinBox { overflow: hidden; padding: 30px; position: relative; box-sizing: border-box; }
    
    #resident_move_into_request .moveinBox .moveinBoxUl { border: 1px solid #f1f3f5; border-radius: 10px; box-sizing: border-box; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; background: #fff; text-align: right; }
    #resident_move_into_request .moveinBox .moveinBoxUl li { float: left; overflow: hidden; line-height: 50px; width: 15%; padding-right: 20px; box-sizing: border-box; }
    #resident_move_into_request .moveinBox .moveinBoxUl li:first-child { width: 70% !important; min-width: 360px; padding-left: 20px; }
    #resident_move_into_request .moveinBox .moveinBoxUl li .toolbar-btn { width: 30px; height: 30px; background-position: -1px 1px; margin-left: 5px; z-index: 99999; }
    .settingBg .left.inputLi { height: 50px; margin-top: 0px; margin-left: 0px; }
    .settingBg .left.inputLi .grp { width: auto; min-width: auto !important; max-width: none !important; }
    .settingBg .left.inputLi .seper { margin-top: 25px; margin-left: -5px; }
    .settingBg .left.inputLi .grp input { width: 120px; }
    #resident_move_into_request .moveinBox .moveinBoxUl li select { font-size: 13px; font-weight: bold; }

    #resident_move_into_request .moveinBox.moveinBoxTable { padding-top: 0px; }
    .moveinBox .tableBoxWrap { position: relative; border: 1px solid #f1f3f5; border-bottom: 0px; border-radius: 10px; box-sizing: border-box; overflow: hidden; background: #fff; }
    .moveinBox .tableBoxWrap .reservationColr { position: absolute; top: 0; right: 25px; line-height: 50px; }
    .moveinBox .tableBoxWrap .reservationColr p { display: inline-block; }
    .moveinBox .tableBoxWrap .reservationColr p span { width: 15px; height: 15px; display: inline-block; background: #b8f1dc; font-size: 0; vertical-align: middle; margin: 0 6px; }
    .moveinBox .tableBoxWrap .reservationColr p span.reComplet { background: #f1f3f5; margin-left: 20px; }
    .moveinBox .tableBoxWrap table { width: 100%; text-align: center; line-height: 50px; }
    .moveinBox .tableBoxWrap table th,td { border-right: 1px solid #f1f3f5; border-bottom: 1px solid #f1f3f5; }
    .moveinBox .tableBoxWrap table tbody tr { line-height: 60px; }
    .moveinBox .tableBoxWrap table span { padding: 5px 10px; background: #b8f1dc; border-radius: 3px; box-sizing: border-box; }
    .moveinBox .tableBoxWrap table span.reservationComplet { background: #f1f3f5; }
</style>