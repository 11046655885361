<template>
    <div id="approval_template_except_file_modal" class="modal approval_template_except_file_modal">

        <div class="titlebox">
            <h1>양식 선택</h1>
        </div>

        <div class="content">
        </div>

        <div class="btns">
            <input type="button" value="취소" />
            <input type="button" value="저장" />
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class ApprovalTemplateExceptFileModal extends Mixins(VueHoduCommon) {

    mounted() : void {
        
    }

}
</script>

<style scoped>
    #approval_template_except_file_modal {overflow: hidden; position:absolute; left:50%; top:50%; margin-top:-280px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:567px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    #approval_template_except_file_modal h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    #approval_template_except_file_modal .btns { border-top:1px solid #e7e9ea }
    #approval_template_except_file_modal .btns input { transition: 0.1s; width: 50%; float: left; height: 75px; line-height: 75px; font-size: 14px; text-align: center; font-weight: bold; background-color:#fff; }
</style>