<template>
    <div id="kids_teacher" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 id="viewTitle" class="title_doc">선생님</h3>
            <a class="schBtn" @click.prevent="toggleSearch">검색하기</a>
        </div>
        
        <div class="searchDiv" :class="{ on : is_search_open == true }">
            <div class="schGrp">
                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                <input type="text" id="kids_teacher_search" placeholder="이름, 담당반, 전화번호, 이메일로 검색하세요" :value="search_query" @input="search_query = $event.target.value">
                <div class="btns">
                    <a class="closeBtn" @click.prevent="toggleSearch">닫기</a>
                </div>
            </div>
        </div>

        <div id="teacher_list" class="section_scroll">
            <div class="viewGroup">

                <div class="fixedDiv">
                    <ul class="sortHeader teacher_list_header">
                        <li class="num"><span>No</span></li>
                        <li class="name headerSort" :class="{
                                sortOff  : sort_type != 'NAME',
                                sortUp   : sort_type == 'NAME' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'NAME' && sort_direction == 'DESC', 
                            }" @click.prevent="sort('NAME')">
                            <span>이름</span>
                        </li>
                        <li class="role"><span>직급</span></li>
                        <li class="responsible_class headerSort" :class="{
                                sortOff  : sort_type != 'CLASS',
                                sortUp   : sort_type == 'CLASS' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'CLASS' && sort_direction == 'DESC', 
                            }" @click.prevent="sort('CLASS')">
                            <span>담당반</span>
                        </li>
                        <li class="tel"><span>전화번호</span></li>
                        <li class="email"><span>이메일</span></li>
                    </ul>
                </div>

                <div id="teacher_list_scroll" class="scroll">

                    <ul class="teacher_list_ul">
                        <li :key="teacher.user_id" v-for="(teacher, index) in computedTeachers">
                            <p class="num">{{ index + 1 }}</p>
                            <p class="name" :class="{ me : teacher.user_id == user_id }">{{ teacher.user_name }}{{ teacher.user_id == user_id ? ' (나)' : '' }}</p>
                            <p class="role">
                                {{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '' : (teacher.group_role.indexOf('GROUP_MANAGER') > -1 ? '최종관리자' : (teacher.group_role.indexOf('GROUP_MANAGER') > -1 ? '관리자' : '부관리자')) }}
                                <select @change="changeGroupRole($event, teacher)" :disabled="teacher.user_id == user_id" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                    <option value="GROUP_MANAGER" :selected="teacher.group_role.indexOf('GROUP_MANAGER') > -1">관리자</option>
                                    <option value="GROUP_SUB_MANAGER" :selected="teacher.group_role.indexOf('GROUP_SUB_MANAGER') > -1">부관리자</option>
                                </select>
                            </p>
                            <p class="responsible_class">
                                {{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '' : (teacher.class_info ? teacher.class_info.team_info.team_name : '없음') }}
                                <select @change="changeTeacherClass($event, teacher)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                    <option value="0" :selected="teacher.class_info == null || teams.filter(team => team.team_id == teacher.class_info.team_id).length < 1">없음</option>
                                    <option :value="team.team_id" :key="team.team_id" v-for="team in teams" 
                                            :selected="teacher.class_info != null && team.team_id == teacher.class_info.team_id">
                                        {{ team.team_info.team_name }}
                                    </option>
                                    <!-- <option value="team_id">개나리반</option> -->
                                    <!-- <option value="team_id2">무지개반</option> -->
                                </select>
                            </p>
                            <p class="tel">{{ teacher.user_phone_number && teacher.user_phone_number.length > 0 ? teacher.user_phone_number : '-' }}</p>
                            <p class="email">{{ teacher.user_email && teacher.user_email.length > 0 ? teacher.user_email : '-' }}</p>
                        </li>
                        <!-- <li>
                            <p class="num">1</p>
                            <p class="name">이성재</p>
                            <p class="responsible_class">무지개반</p>
                            <p class="tel">01071046968</p>
                            <p class="email">ysdogi@gmail.com</p>
                        </li> -->
                    </ul>

                </div>

            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class KidsTeacher extends Mixins(VueHoduCommon) {

    get computedTeachers() : any[] {

        let computed_teachers : any[] = JSON.parse(JSON.stringify(this.teachers));

        if( this.search_query.trim().length > 0 ) {
           computed_teachers = computed_teachers.filter(teacher => this.hodu_string_includes(teacher.user_name, this.search_query) || 
                                                        teacher.class_info != null && this.hodu_string_includes(teacher.class_info.team_info.team_name, this.search_query) ||
                                                        teacher.user_phone_number != null && this.hodu_string_includes(teacher.user_phone_number, this.search_query) || 
                                                        teacher.user_email != null && this.hodu_string_includes(teacher.user_email, this.search_query))
        }

        return computed_teachers.sort((o1, o2) : number => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_class_name = o1.class_info ? o1.class_info.team_info.team_name : null;
            const o2_class_name = o2.class_info ? o2.class_info.team_info.team_name : null;
            
            if( this.sort_type == 'NAME' ) {
                if( o1_name > o2_name ) return this.sort_direction == "ASC" ? 1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == "ASC" ? -1 : 1;
                return 0;
            }

            else if( this.sort_type == 'CLASS' ) {
                
                if( o1_class_name == o2_class_name ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0;
                }
                else if( o1_class_name == null && o2_class_name != null ) return 1;
                else if( o1_class_name != null && o2_class_name == null ) return -1;
                else if( o1_class_name > o2_class_name ) return this.sort_direction == "ASC" ? 1 : -1;
                else if( o1_class_name < o2_class_name ) return this.sort_direction == "ASC" ? -1 : 1;
                
            }

            return 0;
        });
    }

    // 정렬
    sort_type : string = "NAME";
    sort_direction : SORT_TYPE = SORT_TYPE.ASC;

    is_search_open : boolean = false;
    search_query : string = "";

    teams : any[] = [];
    teachers : any[] = [];

    mounted() : void {
        this.getTotalData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        let title_height = $('.title_box').outerHeight();
        let sort_header_height = $('.sortHeader').outerHeight();

        let scroll_height = window.innerHeight - (title_height ? title_height : 0) 
                                               - (sort_header_height ? sort_header_height : 0);
        
        // @ts-ignore
        $('#teacher_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });

    }

    /**
     * 전체 데이터 조회
     */
    async getTotalData() : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getTeamInfo();
            await this.getTeachersInfo();
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "선생님 정보 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 팀 정보 조회
     */
    async getTeamInfo() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/teams/${this.scope_group_id}/0`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.teams ) {
                throw new Error("팀 정보 조회 중 오류 발생");
            }

            this.teams.splice(0, this.teams.length);
            this.teams = this.teams.concat(response.data.data.teams);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 선생님 정보 조회
     */
    async getTeachersInfo() : Promise<void> {

        const partner_key : string = this.getGroupPartnerKey(this.scope_group_id);

        try {
            const response = await this.hodu_api_call(`api/v1/kids/teacher?partner_key=${partner_key}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.teachers ) {
                throw new Error("선생님 정보 조회 중 오류 발생");
            }

            this.teachers.splice(0, this.teachers.length);
            this.teachers = this.teachers.concat(response.data.data.teachers);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 선생님 직급 변경
     */
    async changeGroupRole(event, teacher) : Promise<void> {

        let before_api_call = true;

        try {

            const role = event.target.value;
            const user_id = teacher.user_id;

            const response = await this.hodu_api_call(`api/v1/groups/updateGroupMemberPermmision/${this.scope_group_id}/${role}/${user_id}`, API_METHOD.PUT);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("선생님 직급 변경 중 오류 발생");
            }

            before_api_call = false;
            this.getTotalData();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "선생님 직급 변경 중 오류 발생", ['확인']);

            // API 실행이 완료되기전에 오류 발생시 원본값으로 변경하기 위해 재조회
            if( before_api_call ) {
                this.getTotalData();
            }
        }
    }

    /**
     * 선생님 반 변경
     */
    async changeTeacherClass(event, teacher) : Promise<void> {

        let before_api_call = true;

        try {
            const partner_key = this.getGroupPartnerKey(this.scope_group_id);
            const string_team_id = event.target.value;
            const team_id = Number(string_team_id);

            const response = await this.hodu_api_call(`api/v1/kids/class`, API_METHOD.PUT, {
                "type" : "teacher",
                "group_id" : this.scope_group_id,
                "team_id" : team_id,
                "partner_key" : partner_key,
                "user_id" : teacher.user_id
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("선생님 반 변경 중 오류 발생");
            }

            before_api_call = false;
            this.getTotalData();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "선생님 반 변경 중 오류 발생", ['확인']);

            // API 실행이 완료되기전에 오류 발생시 원본값으로 변경하기 위해 재조회
            if( before_api_call ) {
                this.getTotalData();
            }
        }

    }

    /**
     * 검색창 토글
     */
    toggleSearch() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_query = "";
        }
        else {
            this.$nextTick(() => $('#kids_teacher_search').focus());
        }
    }

    /**
     * 정렬
     */
    sort(sort_type : string) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        // @ts-ignore
        $('#teacher_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    #kids_teacher .num { width : 10%; text-align: center; }
    #kids_teacher .name { width : 20%; }
    #kids_teacher .role { width : 15%; }
    #kids_teacher .responsible_class { width : 15%; }
    #kids_teacher .tel { width : 20%; }
    #kids_teacher .email { width : 20%; }

    #kids_teacher .teacher_list_ul > li { box-sizing:border-box; position:relative; z-index: 0; width:100%; border-bottom:1px solid #f1f3f5;height: 55px;line-height: 55px; }
    #kids_teacher .teacher_list_ul > li p { display: inline-block;font-weight: bold;float: left;box-sizing: border-box;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; }
    #kids_teacher .teacher_list_ul > li p.num { padding-right: 0px }
    #kids_teacher .teacher_list_ul > li p.name.me { color : #477fff; }
    #kids_teacher .teacher_list_ul > li p select {font-weight: bold; transition: 0.2s;-webkit-appearance: none;-moz-appearance: none;appearance: none;border-radius: 5px;height: 30px;line-height: 30px;cursor: pointer;background-color: #fff;background-position: 95% center;background-repeat: no-repeat;box-sizing: border-box;border:1px solid #f1f3f5; padding: 0 30px 0 10px; background-image: url(../../assets/images/side_area/bt_open.png); margin : 0; margin-bottom : 3px; }
    #kids_teacher .teacher_list_ul > li p select:hover { border:1px solid #e7e9ea; }
    #kids_teacher .teacher_list_ul > li p select:disabled { opacity: 0.5; color: #232848; cursor : default; }
</style>