<template>
    <div class="section_ce_fix">
        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">공지사항 {{is_created ? "등록" : "수정"}}</h3>
            <p class="btn_group" style="">
                <input type="button" id="" name="" value="저장" class="input_btn" @click="actionSave()">
                <!--
                <input type="button" id="" name="" value="수정" class="input_btn">
                <input type="button" id="" name="" value="취소" class="input_btn">
                <input type="button" id="" name="" value="삭제" class="input_btn" @click="actionDelete()" v-if="is_created == false">
                -->
            </p>
        </div>
        <!-- 공지사항 등록시 팀이 없다면 클래스 추가 noTeamsNotice (오른쪽 팀/그룹 공유하시겠습니까? 칸 없애기) -->
        <div class="content notice">
            <div class="grp">
                <div class="when">
                    <label for="noti_datepicker">공지 등록일</label>
                    <input type="button" id="noti_datepicker" value="2019.11.01" class="input_txt" style="" readonly/>
                </div>
                <div class="share">
                    <!-- 그룹에서 공지 등록시 : 모든 팀과 공유하시겠습니까? -->
                    <h4 v-if="is_shared">{{team_id > 0 ? '그룹' : '팀'}}에 공유하시겠습니까?</h4>
                    <p v-if="is_shared">
                        <input type="checkbox" id="shareNoti" class="input_check" @change="sharedChange($event)" :checked="o_notice.noti_info.shared != ''">
                        <!-- 팀과 공유 -->
                        <label for="shareNoti"><span><em>{{team_id > 0 ? '그룹' : '팀'}}</em>에 공유</span></label>
                    </p>
                </div>
            </div>
            <div class="editArea">
                <input type="text" id="noti_title" class="input_txt " placeholder="제목을 입력하세요." :value="o_notice.noti_info.title" @input="o_notice.noti_info.title = $event.target.value"/>
                <!-- 세로값 리사이즈시 height변경 (-218px + section_ce) notificationTemp4.js -->
                <div class="edtContentDiv">
                    <!-- 세로값 리사이즈시 height변경 (-332px + section_ce)  notificationTemp4.js -->
                    <textarea id="modal_noti_contents" name="" cols="" rows="5" class="input_textarea" placeholder="공지 내용을 작성하세요" :value="o_notice.noti_data.body" @input="o_notice.noti_data.body = $event.target.value"></textarea>
                    <!-- <ckeditor id="modal_noti_contents" :editor="editor" v-model="editorData" :config="editorConfig" class="input_textarea"></ckeditor> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { t_notification } from '@/model/osm';

const GroupInfo = namespace('GroupInfo');
const dateFormat = require('dateformat');

// import CKEDITOR from '';
declare var CKEDITOR: any;

// import CKEditor from '@ckeditor/ckeditor5-vue';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import '@ckeditor/ckeditor5-build-classic/build/translations/ko';

@Component({
    components : {
        // ckeditor: CKEditor.component
    }
})
export default class GroupNoticeCreate extends Mixins(VueHoduCommon) {
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * 변수 선언부
     */
    noti_uid   : string  = "";
    is_created : boolean = true;
    is_shared  : boolean = false;

    o_notice : t_notification = {
        noti_uid : "",
        noti_type : "",
        noti_sub_type: "",
        group_id: 0,
        team_id: 0,
        team_ids: [],
        group_ids: [],
        user_ids: [],
        noti_info: {
            title: "",
            start_date: null,
            end_date: null,
            calendar_id: "",
            event_id: "",
            event_sub_type: "",
            shared: "",
            scope: "",
            scope_id: 0
        },
        noti_data: {
            body: "",
            body_type: null
        },
        meta: "",
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: 0
    };

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }    

    async mounted() : Promise<void> {
        const vue = this;

        // 리사이즈시 322를 제외한 section_ce하이트로 하단에 하이트 변경
        let height_ori = $("#section_ce").outerHeight();
        let height = (height_ori == null ? 0 : height_ori) - 332;
        
        // 공지사항 수정일때 사용함..
        if ( this.group_team_option.noti_uid ) {
            this.noti_uid   = this.group_team_option.noti_uid;
            this.is_created = false;
        }
        else {
            this.noti_uid   = "";
            this.is_created = true;
            this.o_notice.noti_info.start_date = new Date();
            this.setNoticeStartYmdDisplay();
        }

        // date_picker
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                vue.o_notice.noti_info.start_date = new Date(dateText)
                vue.setNoticeStartYmdDisplay();
            },
        };

        // @ts-ignore
        $('#noti_datepicker').datepicker(option);        

        // 공지 상세
        this.getNoticeDetatil();
        
        /*
		setTimeout(() => {
			$("#cke_modal_noti_contents .cke_contents").attr('style', 'height : ' + height + 'px !important');
		}, 100);
		
		CKEDITOR.on( 'instanceReady', function( e ) {
			let editor = e.editor,body = CKEDITOR.document.getBody();
			editor.on( 'focus', function() {
				// texArea에 포커스가 있을때 datePicker none 처리
				$('#ui-datepicker-div').css("display", "none");
			});
		});

		let ckeditorOption : any = {
			uploadUrl: "/hoduweb/upload/ckeditorImgdrag.do",  // 이게 드래그 드롭을 위한 URL
			filebrowserUploadUrl: "/hoduweb/upload/ckeditorFileUpload.do",  // 파일업로드를 위한 URL
			filebrowserImageUploadUrl : "/hoduweb/upload/ckeditorImg.do",
			filebrowserUploadMethod : 'xhr',
		};
		CKEDITOR.replace( 'modal_noti_contents', ckeditorOption);
		
		ckeditorOption.resize_maxWidth 		= 652;
		CKEDITOR.replace( 'modal_condetail_contents', ckeditorOption);

        CKEDITOR.replace( 'modal_concomplte_contents', ckeditorOption);
        */
    }

    /**
     * 공지 시작일 표시
     */
    setNoticeStartYmdDisplay() : void {
        $('#noti_datepicker').val(dateFormat(this.o_notice.noti_info.start_date, "yyyy.mm.dd"));
    }

    /**
     * 공지사항 상세화면 조회
     */
    async getNoticeDetatil() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getNoticeDetail/${this.group_id}/${this.team_id}?uid=${this.noti_uid}`, API_METHOD.GET, {}, false)
            .then(async(response) => {
                if ( this.team_id > 0 ) {
                    this.is_shared = true;
                }
                else {
                    this.is_shared = (response.data.data.team_count.team_count > 0 ? true : false);
                }

                if ( this.is_created == false ) {
                    // 데이터가 없을경우
                    if ( !response.data.data.noti_data ) {
                        if ( vue.group_team_option.noti_uid ) {
                            delete vue.group_team_option.noti_uid;
                        }

                        vue.movePrevPage();
                        return;
                    }
                    else {
                        this.o_notice = response.data.data.noti_data;
                        this.setNoticeStartYmdDisplay();
                    }
                }

                return;
            })
            .catch(async(e) => {
                if ( vue.group_team_option.noti_uid ) {
                    delete vue.group_team_option.noti_uid;
                }

                this.hodu_error_process(e, true, true);
            });        
    }
    
    /**
     * 저장
     */
    async actionSave() : Promise<void> {
        const vue = this;

        if ( this.o_notice.noti_info.title.trim().length < 1 ) {
            alert('제목을 입력하세요');
            return;
        }

        if ( this.o_notice.noti_data.body.trim().length < 1 ) {
            alert('내용을 입력하세요');
            return;
        }

        if ( this.is_created ) {
            this.o_notice.noti_type = "GROUP_NOTICE";
            this.o_notice.noti_data.body_type = "TEXT";
            this.o_notice.noti_info.end_date = new Date("2050.12.31");

            if ( this.team_id == 0 ) {
                this.o_notice.group_id = this.group_id;
            }
            else {
                this.o_notice.group_id = this.group_id;
                this.o_notice.team_id  = this.team_id;
                this.o_notice.team_ids = [this.team_id];
            }
        }

        if ( this.team_id > 0 ) {
            if ( this.o_notice.noti_info.shared == "G" ) {
                this.o_notice.group_ids = [this.group_id];
            }
            else {
                this.o_notice.group_ids = [];
            }
        }
        else {
            this.o_notice.group_ids = [this.group_id]
        }

        await this.hodu_api_call(`api/v1/notification/notice/${this.team_id == 0 ? 'GROUP' : 'TEAM'}`, this.is_created ? API_METHOD.POST : API_METHOD.PUT, {notification: this.o_notice})
            .then(async(response) => {
                if ( this.is_created ) {
                    if ( vue.group_team_option.noti_uid ) {
                        delete vue.group_team_option.noti_uid;
                    }
                }
                
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });        
    }

    /**
     * 공유 변경
     */
    sharedChange(any) : void {
        let ls_shared = this.o_notice.noti_info.shared;

        if ( ls_shared == "" ) {
            this.o_notice.noti_info.shared = (this.team_id == 0 ? "T" : "G");
        }
        else {
            this.o_notice.noti_info.shared = "";
        }
    }    
}
</script>

<style scoped>
    .title_box { line-height:62px; height:62px; }
    .notice.content.noTeamsNotice .share {display:none  }
    .notice.content.noTeamsNotice .when { width:100% !important; border-right:0; }
    .notice.content .grp > div { display:inline-block; float:left; height:80px;padding: 0 25px;box-sizing: border-box; }
	.notice.content .grp > div > * {display: block; }
	.notice.content .grp > div > label, .notice.content .grp > div h4 { width:100%; color:#7c88a2; font-size:13px; font-weight:bold;margin-top:5px; line-height:40px;height:30px; float:left; }
	.notice.content .grp {height: 82px;border-bottom: 2px solid #e7e9ea;padding: 0 px;box-sizing: border-box;width: 100%;border-right: 1px solid #e7e9ea;}
	.notice.content .editArea {margin-top: 0;border-right: 1px solid #e7e9ea;}
	#noti_title { transition:0.2s; font-weight:bold; line-height: 70px; height: 70px;padding:0 20px; box-sizing:border-box; width:100%;font-size:18px;border-bottom:2px solid #e7e9ea;}
	#noti_title:hover { background:#f1f3f5; }
	#noti_title:focus { border-bottom:2px solid #477fff; background:#fff; }
	#noti_title:disabled {background: #fff;color: #273044;}
	#noti_datepicker { font-size:15px; font-weight: bold;}
	#noti_datepicker:disabled { color:#273044 !important; cursor:default; }
	.notice .when { border-right:1px solid #e7e9ea; width:50%;}
	.notice .share { padding:0 30px; position:relative; }
	.notice .share p { height: 37px;line-height: 37px; margin-top: 5px; }
	.notice .when input { height: 33px;line-height: 33px;width: 100%;text-align: left; }
	.notice .when input:hover, .notice .when input:focus { color:#477fff; }
	.cke_chrome { border:0 none; }
	.cke_top { background:#f1f3f5; border-bottom:0 none; }
	.cke_bottom { border-top: 0 none;background: #f1f3f5; padding:0; line-height:40px; height:40px; }
	.cke_resizer { display:none; }
	.notice .share span.fPic {left: 24px;top: 39px;border: 1px solid #e7e9ea;background-color: #f1f3f5;font-size: 0;min-width: 27px !important;width: 27px !important;background-position: center center !important;background-size: cover !important;height: 27px !important;border-radius: 50%;}
	.notice .share .whoWrote {padding-left: 40px;font-weight: bold;font-size: 14px;}
	
	.notice input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
	.notice input[type='checkbox']:not(old) + label span { color:#7c88a2; }
	.notice input[type='checkbox']:not(old) + label span em { color:#477fff; font-style:normal; }
	.notice .share span{position: absolute;left: 55px;width: 100% !important;height: 30px !important;max-width: 200px !important;min-width: 100px !important;z-index: 1000000;top: 36px;line-height: 30px;font-size: 14px;font-weight: bold;}
    .notice .share input[type='checkbox']:not(old):checked + label {background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;border: 2px solid #477fff;}
	.notice .share input[type='checkbox']:not(old):checked + label span { color:#273044; }
	.content.notice {width: 100%;max-width: 1000px;min-width: 700px;}
    #cke_modal_noti_contents .cke_contents { transition:0.1s; }
    .left_area_close .content.notice {padding-left: 61px !important;}
</style>

<style>
    textarea#modal_noti_contents { padding:20px; font-size:14px; }
    .cke_chrome { border:0 none; }
  .cke_top { background:#f1f3f5; border-bottom:0 none; }
  .cke_bottom { border-top: 0 none;background: #f1f3f5; padding:0; line-height:40px; height:40px; }
  .cke_resizer { display:none; }
  #cke_modal_noti_contents .cke_contents { transition:0.1s; }
</style>