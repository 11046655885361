<template>
    <div class="section_ce_fix msgOptionPage">
        
        <div class="title_box text-center">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">문구 설정</h3>
        </div>

        <!--스크롤 생겨야할 영역-->
        <div class="section_scroll">
            <div class="vertiLine"></div>
            <div class="schedule_box">
                <div class="messageBoxWrap02" >
                    <div class="sub_titleBox">
                        <p>내게 맞는 문구 작성하기</p>
                    </div>
                    
                    <div class="messageBox_right" >
                        
                        <p class="underLine">
                            <input type="text" id="msgOpInTitle" class="input_txt" placeholder="제목을 입력하세요" maxlength="100"/>
                        </p>

                        <div class="textareaBox" ><!-- height 115이상 스크롤 right:0;top:0-->
                            <textarea id="msgOpInContents" class="input_textarea" placeholder=""></textarea>
                            
                            <div class="options">
                                <h4>#{옵션열기}</h4>
                                
                                <div class="grp">
                                    <ul id="optionContent">
                                        <li>#{이름}</li>
                                        <li>#{이메일}</li>
                                        <li>#{발신일자}</li>
                                        <!-- <li>#{대표그룹명}</li> -->
                                        <!-- <li>#{부그룹명}</li> -->
                                    </ul>
                                    <h5>#{옵션닫기}</h5>
                                </div>

                            </div>

                            <p class="countLetters"><span class="counted" id="sendMmsMasageCount">0</span> / 2000</p>
                        </div>

                        <div class="btnBox">
                            <input type="button" id="msgOpInNewBtn" class="input_btn fl" placeholder="" value="+ 새등록" title=""/>
                            <p class="fr">
                                <input type="button" id="msgOpInCancelBtn" class="input_btn " placeholder="" value="취소" title=""/>
                                <input type="button" id="msgOpInSaveBtn" class="input_btn" placeholder="" value="저장" title=""/>
                            </p>
                        </div>

                    </div>
                </div>

                <div class="defaultMsgBoxWrap">
                    
                    <div class="forFixed">
                        
                        <div class="menu">
                            <!--  <input type="button" value="저장된 문구" class="btn_saved on" /> -->
                            <h3><span class="txt01">저장된 문구</span><span class="txt02" id="msgTotalCnt">{{ list ? list.length : 0 }}</span><span class=""> 개</span></h3>
                            <!-- <input type="button" value="기본 제공 문구" class="btn_default" /> -->
                            <input type="button" id="addMsg" value="추가" @click="goMessageTemplateAdd()"/>
                        </div>

                    </div>

                    <div class="messageBox_left" >
                        <div class="noResult">
                            <p>저장된 문구가 없습니다.</p>
                        </div>

                        <!--
                        <p class="sbtitle">
                            <p class="des">저장된 문구 수정을 원하실시경우 문구박스 클릭 후 문구작성 화면에서 가능합니다.</p>
                        </p>
                        -->

                        <div id="msgHListScroll"><!-- height:205px이상 스크롤 right:0;top:0 -->
                            <ul id="msgHListUl">
                                <li class="add"><input type="button" value="+ 추가하기" @click="goMessageTemplateAdd()" /></li>
                                <li v-bind:key="index" v-for="(lo_data, index) in list">
                                    <span class="cont01" >{{index + 1}}</span>
                                    <span class="cont02">{{lo_data.title}}</span>
                                    <div class="bottom" @click="messageUpdate(lo_data.uid, lo_data.audit_user_id);">
                                        <p v-html="lo_data.message_info.body.replace(/(?:\r\n|\r|\n)/g, '<br />')"></p>
                                    </div>
                                    <input type="button" class="bt_del02 p_msgDel" placeholder="삭제" title="삭제" @click="deleteMessage(lo_data.uid);" v-if="lo_data.audit_user_id == user_id"/>
                                </li>
                            </ul>
                        </div>
                    </div>                    
                </div>                          
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';

const EtcInfo   = namespace('EtcInfo');
const GroupInfo = namespace('GroupInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({

}) 
export default class MessageTemplate extends Mixins(VueHoduCommon) {
    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;

    /**
     * EtcInfo.Action
     */
    @EtcInfo.Action doMessageUid ?: any;

    list : any = [];

    async mounted() : Promise<void> {
        if ( !this.group_id || this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
        
        this.doMessageUid("");
        this.getMessageTemplate();
    }
    
    goMessageTemplateAdd() : void {
        this.doMessageUid("");
        this.hodu_router_push(`/message/${new Date().getTime()}/template_add`);
    }

    /**
     * 메시지 템플릿 조회
     */
    getMessageTemplate() : void {
        this.list = [];

        this.hodu_api_call(`api/v1/message_template/GROUP/${this.group_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.list = response.data.data.list;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 메시지 템플릿 삭제
     */
    deleteMessage(ps_uid) : void {
        this.hodu_show_dialog("alert", "메세지를 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.hodu_api_call(`api/v1/message_template/${ps_uid}`, API_METHOD.DELETE, null)
                    .then(async(response) => {
                        this.getMessageTemplate();
                    })
                    .catch(async(e) => {
                        this.hodu_error_process(e, true, false);
                    });
            },
        ]);
    }

    /**
     * 메세지 수정
     */
    messageUpdate(ps_uid, pi_user_id) : void {
        if ( this.user_id != pi_user_id )  {
            return;
        }

        this.doMessageUid(ps_uid);
        this.hodu_router_push(`/message/${new Date().getTime()}/template_add`);
    }
}

</script>

<style scoped>
    /* */
    .title_box { height: 60px; }
    #container #section_ce .schedule_box { max-width:100%; }
    textarea#msgOpInContents {width: 100%;height: 100%;}
    ul#optionContent li:before {display: none;}
    #msgAddScroll textarea {width: 600px;height: 250px;max-width: 100%;min-width: 100%;max-height: 400px;transition: 0.1s;font-size: 14px;font-weight: bold;padding: 20px 60px 20px 20px;box-sizing: border-box;min-height: 200px;border: 1px solid #e7e9ea;}
    #msgAddScroll textarea:hover {background: #f1f3f5;}
    #msgAddScroll textarea:focus {background: #Fff;border-color: #477fff;}
    .textareaBox .options {width: 100%;border: 1px solid #e7e9ea;box-sizing: border-box;background: #f1f3f5;}
    .textareaBox {max-width: 600px;}
    .msgOptionPage .noResult { display:none; background:#fff; position:static; margin-top:20px; }
    .msgOptionPage .noResult p { background:#f3f3f3; color:#484848; font-size:14px; line-height: 100px; margin-top:0; padding:0; }
    .msgOptionPage .vertiLine {position: fixed;width: 1px;background: #e7e9ea;height: 100%;left: 890px;top: 209px;z-index: 10000;display: none;}
    .msgOptionPage.lClosed .vertiLine { left: 689px !important; }
    .msgOptionPage .schedule_box { overflow: hidden; }
    .bt_del02{border-radius:50%;width:20px;height:20px;background:#eee url(../assets/images/contents/bt_del.png) no-repeat 50% 50%;}
    .bt_del02:hover{background:#ff6262 url(../assets/images/contents/bt_del_on_02.png) no-repeat 50% 50%;}
    .msgOptionPage .countLetters {opacity: 0.7;position:absolute;z-index:1000;right: 15px;bottom: 20px;font-size: 12px;font-weight: bold;box-shadow: 0 2px 3px rgba(0,0,0,0.1);background: rgba(255,255,255,0.9);opacity: 1;padding: 5px 5px;border-radius: 5px;font-weight: normal;}
    .msgOptionPage .countLetters .counted {font-weight: bold;}
    .msgOptionPage .countLetters:hover { cursor:default; }

    .messageBoxWrap02 .sub_titleBox p { font-size:18px; }
    .messageBoxWrap02 .sub_titleBox { padding-bottom:20px; padding-left:0; }
    .messageBox_right .underLine .input_txt:hover {background-color: #f1f3f5;}
    .messageBox_right .underLine .input_txt:hover {background-color: #f1f3f5;border-bottom: 2px solid #477fff;}
    .messageBox_right .underLine .input_txt:focus {background-color: #fff;border-bottom: 2px solid #477fff;}
    .defaultMsgBoxWrap{margin-top: 0;padding:0 18px;width: 50%;padding: 0;box-sizing: border-box;height: auto;/* float: right; */width: 100%;padding-left: 0;}
    .messageBoxWrap02{display:none;transition:0.1s;width: 450px;min-height: 256px;box-sizing: border-box;overflow: hidden;float: left;position: fixed;left: 340px;margin-top: -315px;top: 50%;}
    .messageBox_left{display: none;}
    .sbtitle{overflow:hidden;line-height:18px;display: none;}
    .sbtitle .txt01{font-weight:bold;color:#484848;float:left;margin: 20px 0 15px 30px;font-size:16px; display:none;}
    .sbtitle .txt02{color: #484848; float: right; margin-top: 28px; font-weight: bold;}
    span#msgTotalCnt {margin-right: 0;margin-top: 26px;font-weight: bold;font-size: 16px;float: left;margin-left: 30px;}
    input#addMsg {line-height: 40px; border: 1px solid #e8e8e8; background: #Fff;padding: 0 15px;height: 40px;border-radius: 5px;font-weight: bold;box-sizing: border-box;font-size: 14px;float: right;margin-right: 30px;margin-top: 9px;}
    .messageBox_left .des {margin-left: 30px;font-size: 12px;line-height: 23px;color: #878787;}
    .defaultMsgBoxWrap .des { display:none; }

    .messageBox_right{float:left;width: 100%;box-sizing:border-box;border-radius:0 4px 4px 0;padding: 0;}
    .messageBox_right .underLine{/* border-left: 1px solid #e8e8e8; *//* border-right: 1px solid #e8e8e8; */}
    .messageBox_right .underLine .input_txt{margin-bottom: 0;box-sizing:border-box;height: 70px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;font-size: 18px;padding: 20px 20px 20px;width: 100%;font-weight: bold;border: 1px solid #e9e9e9;border-bottom: 2px solid #e7e9ea;background: #ffffff;line-height: 70px;border-radius: 15px 15px 0 0;color: #5f5f5f !important;    background: url(../assets/images/contents/input_pen.png) no-repeat 95% 32px;}
    .messageBox_right .textareaBox{font-size:0;width: 100%;position: relative;height: 100%;/* overflow: hidden; */float: left;}
    .messageBox_right .textareaBox .input_textarea{box-sizing:border-box;max-width:100%;width: 100%;line-height: 22px;min-height: 365px;max-height: 365px;border:1px solid #e9e9e9;background-color: #f1f3f5;;padding: 20px 20px;font-size: 14px;}
    .messageBox_right .btnBox{border-left:1px solid #e9e9e9;border-right:1px solid #e9e9e9;border-bottom:1px solid #e9e9e9;overflow:hidden;padding: 20px 20px;border-radius: 0 0 15px 15px;}
    .messageBox_right .btnBox .hbar{margin-top:5px;}
    .messageBox_right .btnBox .input_btn{line-height: 35px;height: 35px;font-size: 12px;padding: 0 25px;margin-left: 15px;border-radius: 5px;background: white;color: #585858;border: 1px solid #eaeaea;font-weight: bold;}
    .messageBox_right .options #optionContent {padding-top: 5px;padding-bottom: 8px;padding-right: 85px;}

    .defaultMsgBoxWrap h2 {font-size: 16px;margin: 20px 0 15px;margin-left: 25px;}
    .defaultMsgBox > p { margin-left:25px;font-size: 12px;line-height: 23px;color: #878787; }
    .defaultMsgBoxWrap{margin-top: 0;}
    .defaultMsgBox ul, .messageBox_left ul{margin-left: 0;text-align:left;margin-top: 0}
    .msgHListUl li.add input {transition: 0.2s;color: #a7a7a7;border-radius: 5px;font-size: 21px;font-weight: bold;text-align: center;height: 100%;line-height: 300px;width: 100%;background: #f1f3f5;border: 0 none;padding: 0;float: left;margin: 0;}
    .messageBox_left li.add {background: #fff;box-sizing: border-box;border: 2px dashed #e7e9ea;border-radius: 5px;margin-left: 0;}
    .messageBox_left li.add:hover {box-shadow: none;}
    .messageBox_left li.add:hover input { background:#fff; color:#477fff }
    .messageBox_left li.add input {transition: 0.2s;color: #a7a7a7;border-radius: 5px;font-size: 21px;font-weight: bold;text-align: center;height: 100%;line-height: 300px;width: 100%;background: #f1f3f5;border: 0 none;padding: 0;float: left;margin: 0;}
    .messageBox_left li { float:left; }
    .defaultMsgBox li, .messageBox_left li{margin: 35px 30px 0 0;width: 270px;display: inline-block;transition:0.2s;position: relative;}
    .defaultMsgBox li:hover p, .messageBox_left li:hover p {font-weight: bold;}
    .defaultMsgBox li .noBox, .messageBox_left .cont01 {line-height:30px;color:#5a5a5a;text-align: left;font-weight:bold;display: inline-block;width: 30px;height: 30px;font-size: 14px;background: #fff;color: #757575;border: 1px solid #e7e9ea;text-align: center;border-radius: 50%;position: absolute;left: -18px;top: -10px;}
    .defaultMsgBox li .yellowBox .top, .messageBox_left li .cont02 {display:inline-block;box-sizing:border-box;width: 100%;line-height: 24px;padding: 15px 20px 10px;font-weight:bold;color:#545454;font-size: 15px;border-top-left-radius: 5px;border-top-right-radius: 5px;background: #ffd823;text-align: left;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;color: #ffffff;}
    .messageBox_left li .cont02 {background:#7e69ff url(../assets/images/contents/bg_transWhite.png) no-repeat right 50%;color:#fff; }
    .defaultMsgBox li .yellowBox .top:hover, .defaultMsgBox li .yellowBox .bottom:hover { cursor:default; }
    .defaultMsgBox li .yellowBox .bottom, .messageBox_left .bottom{transition:0.2s;height: 255px !important;overflow: hidden;overflow-y: auto;padding: 0;font-size: 13px;line-height:23px;box-sizing:border-box;border-left:1px solid #ededed;border-right:1px solid #ededed;border-bottom:1px solid #ededed;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;}
    .defaultMsgBox li:hover .yellowBox .bottom{border-left:1px solid #fff;border-right:1px solid #fff;border-bottom:1px solid #fff;box-shadow: 0 9px 21px rgba(140, 140, 140, 0.19);transition:0.2s;}
    input#msgOpInNewBtn:hover {background: #f3f3f3;}
    .msgOptionPage .bottom p {padding: 15px 20px 20px;text-align: left;line-height: 25px;color: #828a9a;/* font-weight: bold; */}
    .defaultMsgBox, .messageBox_left {padding-top: 0;width: 100%;/* border-left: 1px solid #e7e9ea; */}
    .defaultMsgBoxWrap .forFixed {position: fixed;width:100%;padding-left: 261px;box-sizing: border-box;left: 0;top: 63px;transition: 0.1s;padding-right: 60px !important;z-index: 1;}
    .right_area_close .defaultMsgBoxWrap .forFixed {padding-right: 0 !important;}
    .defaultMsgBoxWrap .forFixed .menu {border-bottom: 1px solid #e7e9ea;height: 60px;line-height: 60px;width: 100%;background: #fff;}
    .defaultMsgBoxWrap .forFixed .menu input {height:100%;font-weight:bold;font-size: 16px;padding: 0 30px;background: none;color:#484848;float: left;}
    .defaultMsgBoxWrap .forFixed .menu .btn_default:hover, .defaultMsgBoxWrap .forFixed .menu .btn_default.on { background: #ffd823; }
    .defaultMsgBoxWrap .forFixed .menu .btn_saved:hover, .defaultMsgBoxWrap .forFixed .menu .btn_saved.on {background: #7e69ff;/* color:#fff; */}
    .msgOptionPage .menu h3 {display: inline-block;font-size: 16px;margin-left: 30px;}
    .msgOptionPage .menu h3 #msgTotalCnt {font-size: 16px;float: none;margin-left: 10px;color: #477fff;}
    .defaultMsgBox { display:none; }
    .messageBox_left {display:block;max-width: 100%;overflow: hidden; box-sizing: border-box; padding: 0 30px;}
    #msgHListScroll { text-align:center; }
    #msgHListUl .bt_del02 {position: absolute;top: -11px;left: -19px;color: #fff;width: 34px;height: 34px;font-weight: bold;background: #ff5555 url(../assets/images/contents/btn_del_fff.png) no-repeat center center;background-size:35px;}
    #msgHListUl .bt_del02:hover {background-color: #ff3737;font-size: 12px !important;width: 70px;border-radius: 30px;border: 1px solid #ff5555;}
    .messageBox_left .bt_del02 { opacity:0; transition:0.1s;}
    .messageBox_left li:hover, .messageBox_left li.on {cursor:pointer;box-shadow: 0 7px 18px rgba(77, 93, 109, 0.2);border-radius: 5px;transition:0.1s;}
    .messageBox_left li.on .bt_del02 { opacity:1; }
    .messageBox_left li:hover .bt_del02 {opacity:1;transition:0.1s;}
    .messageBox_left li.on:hover { cursor:default; }
    .messageBox_left li.off .cont02 {background: #a99bff url(../assets/images/contents/bg_transWhite.png) no-repeat right 50%;}
    .messageBox_left li.off .bottom p { color:#878787; }
    .messageBox_left li.off:hover .bottom p {color:#4e4e4e;}
    .messageBox_left li.off:hover .cont02 {background:#7e69ff url(../assets/images/contents/bg_transWhite.png) no-repeat right 50%;color:#fff; }
    .messageBox_left li.off:hover { box-shadow:none; }


    #msgOpInTitle.editing { background:#7e69ff; color:#fff !important;  }
    #msgOpInTitle.editing::placeholder {color: #fff;}
    #msgOpInTitle.editing:hover {background: #7e69ff url(../assets/images/contents/input_pen_fff.png) no-repeat 95% 32px;}

    
/* 메세지기본설정 */

.messageBoxWrap input[type='checkbox']:not(old):checked + label {background: #ff2e3e url('../assets/images/contents/checked_fff.png') no-repeat -2px -2px;border: 2px solid #ff2e3e;background-size: 21px;}

.defaultMsgBoxWrap .forFixed .menu .btn_saved:hover, .defaultMsgBoxWrap .forFixed .menu .btn_saved.on {background: #ffffff !important;}
.messageBox_left li .cont02 {background: #e6eef3 !important;color: #4d5d6d !important;}
.messageBox_left li:hover .cont02 {background-color: #4d5d6d !important;color: #fff !important;}
#msgOpInTitle.editing {background: #36bdff !important;}
#msgOpInTitle.editing:hover {background: #36bdff url(../assets/images/contents/input_pen_fff.png) no-repeat 95% 32px !important;}
.messageBox_left li.off .cont02 {}
.messageBox_left li.off:hover .cont02 {}

.section_ce_fix .defaultMsgBoxWrap .forFixed {
    position: static !important;
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
}

</style>