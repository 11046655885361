<template>
    <div id="organization_vacation_type_modal" class="modal" :class="{ with : day == '0.5' }">

        <div class="titleBox">
            <h1 class="">휴가종류 설정</h1>
        </div>

        <div class="content remodal-content remodal-content03 remodal-content04">
            <div class="detail">
                
                <div class="titleDiv">
                    <h2>이름</h2>
                    <input type="text" id="vacation_type_name" class="input_modal_content" placeholder="이름을 입력해주세요" :value="name" @input="name = $event.target.value" />
                </div>
                
                <div class="bottomDiv">

                    <div class="dayDiv">
                        <!-- \n \r 등등 다 안먹혀서 정리 안하고 엔터 하나만 누르고 공백 없앰 -->
                        <!-- <h2>일수<span class="desc" title="1일 미만의 휴가는 소수점으로 사용해주세요 (반차 : 0.5, 반반차 : 0.25)
1일 이상의 휴가에는 소수점 단위를 사용하실수 없습니다">?</span></h2> -->
                        <h2>일수</h2>
                        <!-- <input type="number" id="vacation_type_daycount" class="input_modal_content" placeholder="일수를 입력해주세요 (단위 : 일)" :value="day" @input="day = $event.target.value"/> -->
                        <select class="input_modal_content" v-model="day">
                            <!-- <option value="0.25">0.25</option> -->
                            <option value="0">직접입력</option>
                            <!-- <option value="0.25">0.25</option> -->
                            <option value="0.5">반차</option>
                            <option value="0.25">반반차</option>
                            <!-- <option :key="n" :value="n" v-for="n in 30">{{ n }}</option> -->
                        </select>
                    </div>

                    <div class="dayAndNightDiv" v-if="day == '0.5'">
                        <h2>오전 / 오후</h2>
                        <input type="radio" name="dayAndNight" id="dayAndNightAM" value="AM" v-model="amPm"/>
                        <label for="dayAndNightAM">오전</label>
                        <input type="radio" name="dayAndNight" id="dayAndNightPM" value="PM" v-model="amPm"/>
                        <label for="dayAndNightPM">오후</label>
                    </div>
                    
                    <div class="payDiv">
                        <h2>유급 / 무급 여부</h2>
                        <input type="radio" name="pay" id="payY" value="Y" v-model="pay"/>
                        <label for="payY">유급</label>
                        <input type="radio" name="pay" id="payN" value="N" v-model="pay"/>
                        <label for="payN">무급</label>
                    </div>

                </div>

            </div>
        </div>

        <div class="btns">
            <input type="button" value="취소" @click.prevent="close"/>
            <input type="button" value="저장" @click.prevent="save"/>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationVacationTypeModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_vacation_type_modal_info   !: organization_modal_interface.OrganizationVacationTypeModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationTypeModalInfo  ?: (params : organization_modal_interface.OrganizationVacationTypeModalInfo) => void;

    name : string = "";
    day : string = "0";
    amPm : string = "AM";
    pay : string = "Y";

    mounted() : void {
        if( this.organization_vacation_type_modal_info.is_create == false ) {
            this.name = this.organization_vacation_type_modal_info.title ? this.organization_vacation_type_modal_info.title : '';
            this.day = this.organization_vacation_type_modal_info.day ? String(this.organization_vacation_type_modal_info.day) : '1';
            this.pay = this.organization_vacation_type_modal_info.pay == false ? 'N' : 'Y';
        }
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationVacationTypeModalInfo?.({
            show_modal : false, 
            is_create : false
        })
    }

    /**
     * 체크
     */
    validationCheck() : boolean {
        
        if( this.name == null || this.name.trim().length < 1 ) {
            alert("휴가 종류 이름을 입력해주세요");
            $('#vacation_type_name').focus();
            return false;
        }
        
        if( this.day == null || String(this.day).trim().length < 1 ) {
            alert("휴가 일수를 입력해주세요");
            $('#vacation_type_daycount').focus();
            return false;
        }

        try {
            const n = Number(this.day);

            if( 0 > n ) {
                alert("휴가 일수에는 양수만 입력해주세요");
                $('#vacation_type_daycount').focus();
                return false;
            }

            if( 99.99 < n ) {
                alert("휴가 일수는 최대 99.99일 까지입니다");
                $('#vacation_type_daycount').focus();
                return false;
            }
            
            if( n > 1 && (n - Math.floor(n)) > 0 ) {
                alert("1일 이상의 휴가에는 소수점을 쓸수 없습니다");
                $('#vacation_type_daycount').focus();
                return false;
            }

        } catch(e) {
            alert("휴가 일수에는 숫자만 입력해주세요");
            $('#vacation_type_daycount').focus();
            return false;
        }
        
        return true;
    }

    /**
     * 휴가 종류 저장
     */
    async save() : Promise<void> {

        if( this.validationCheck() == false ) return;

        try {

            this.name = this.name.trim();
            this.day = String(this.day).trim();

            const contents = (this.day == '0.5') ? { "amPm" : this.amPm } : {};

            if( this.organization_vacation_type_modal_info.is_create == true ) {
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype`, API_METHOD.POST, {
                    "vacation_type_name" : this.name, 
                    "vacation_type_daycount" : Number(this.day), 
                    "vacation_dedution_yn" : this.pay == 'Y', 
                    "contents" : contents, 
                    "audit_create_id" : this.user_id
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴가타입 생성 중 오류 발생");
                }

            }

            else {

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype/${this.organization_vacation_type_modal_info.vacation_type_id}`, API_METHOD.PUT, {
                    "vacation_type_name" : this.name, 
                    "vacation_type_daycount" : Number(this.day), 
                    "vacation_dedution_yn" : this.pay == 'Y', 
                    "contents" : contents, 
                    "audit_create_id" : this.user_id
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴가타입 생성 중 오류 발생");
                }

            }
            
            this.organization_vacation_type_modal_info.callback?.();
            this.close();
        } catch(e) {
            alert('휴가종류 설정 중 오류 발생');
        }

    }

}
</script>

<style scoped>
    #organization_vacation_type_modal { background:#fff;max-width:750px;height: 440px;margin: 0 auto;left: 0;right: 0;margin-top: -220px;top: 50%;}
    .modal { position:absolute; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}
    
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;padding:0 !important;}
    .modal .btns {border-top:1px solid #eaebec; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;float: left;width: 100%;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; background:#fff; font-weight:bold; }
    .modal .btns input:hover  { background:#f1f3f5; }
    
    #organization_vacation_type_modal .content { height: 280px !important; }
    #organization_vacation_type_modal .content .detail { height: 100%; box-sizing: border-box; }
    #organization_vacation_type_modal .content .detail { padding:30px 40px; }
    #organization_vacation_type_modal .content .detail .titleDiv h2 { font-size:14px; margin-bottom:10px; padding-left : 10px; height:30px; line-height:30px; }
    #organization_vacation_type_modal .content .detail .titleDiv input { transition:0.2s; line-height:55px; height:55px; padding: 0 15px; border: 1px solid #e7e9ea; width:100%; box-sizing: border-box; font-size:17px; font-weight:bold; } 
    #organization_vacation_type_modal .content .detail .titleDiv input:hover { border:1px solid #d5dbde; background:#f1f3f5; }
    #organization_vacation_type_modal .content .detail .titleDiv input:focus { border:1px solid #477fff; background:#fff !important; }
    #organization_vacation_type_modal .content .detail .titleDiv input.wrong { border:1px solid #ff6060; background:#fff0f0; color:#ff6060; }
    #organization_vacation_type_modal .content .detail .titleDiv input.wrong::placeholder { color:#ff6060; }

    #organization_vacation_type_modal .content .detail .bottomDiv { margin-top: 20px; }

    #organization_vacation_type_modal .content .detail .dayDiv { width : 50%; float : left; position: relative; }
    #organization_vacation_type_modal .content .detail .dayDiv h2 { font-size:14px; margin-bottom:10px; padding-left : 10px; height:30px; line-height:30px; }
    #organization_vacation_type_modal .content .detail .dayDiv h2 .desc { position : absolute; width: 20px; height : 20px; line-height: 20px; right : 0; top : 5px; color : #477fff; border : #477fff 1px solid; border-radius: 50%; text-align: center; }
    #organization_vacation_type_modal .content .detail .dayDiv input { transition:0.2s; line-height:55px; height:55px; padding: 0 15px; border: 1px solid #e7e9ea; width:100%; box-sizing: border-box; font-size:17px; font-weight:bold; } 
    #organization_vacation_type_modal .content .detail .dayDiv input:hover { border:1px solid #d5dbde; background:#f1f3f5; }
    #organization_vacation_type_modal .content .detail .dayDiv input:focus { border:1px solid #477fff; background:#fff !important; }
    #organization_vacation_type_modal .content .detail .dayDiv input.wrong { border:1px solid #ff6060; background:#fff0f0; color:#ff6060; }
    #organization_vacation_type_modal .content .detail .dayDiv input.wrong::placeholder { color:#ff6060; }

    #organization_vacation_type_modal .content .detail .payDiv { width : 40%; float : left; padding-left : 10%; }
    #organization_vacation_type_modal .content .detail .payDiv h2 { font-size:14px; margin-bottom:10px; padding-left : 10px; height:30px; line-height:30px; }
    #organization_vacation_type_modal .content .detail .payDiv input[type='radio'] { width : auto; line-height:55px; height:55px; margin-left: 10px; opacity: 1; } 
    #organization_vacation_type_modal .content .detail .payDiv input[type='radio']:not(old)+label { background : none; padding : 0 10px; line-height:55px; height:55px; font-size:17px; font-weight:bold; } 

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

    /* Firefox */
    input[type=number] { -moz-appearance: textfield; }

    #organization_vacation_type_modal .content .detail .dayDiv select { -webkit-appearance: none; appearance: none; transition:0.2s; line-height:55px; height:55px; padding: 0 15px; border: 1px solid #e7e9ea; width:100%; box-sizing: border-box; font-size:17px; font-weight:bold; background:  url('../../../assets/images/side_area/bt_open.png') no-repeat 295px center; } 
    #organization_vacation_type_modal.with .content .detail .dayDiv select { background-position : 185px center; } 
    #organization_vacation_type_modal .content .detail .dayDiv select:focus {background-image: url(../../../assets/images/side_area/bt_close.png); }

    #organization_vacation_type_modal.with .content .detail .dayDiv { width : 33%; }
    #organization_vacation_type_modal.with .content .detail .payDiv { width : 30%; padding-left : 0; }

    #organization_vacation_type_modal .content .detail .dayAndNightDiv { width : 30%; float : left; padding-left : 7%; display: none;}
    #organization_vacation_type_modal.with .content .detail .dayAndNightDiv { display:block; }
    #organization_vacation_type_modal .content .detail .dayAndNightDiv h2 { font-size:14px; margin-bottom:10px; padding-left : 10px; height:30px; line-height:30px; }
    #organization_vacation_type_modal .content .detail .dayAndNightDiv input[type='radio'] { width : auto; line-height:55px; height:55px; margin-left: 10px; opacity: 1; } 
    #organization_vacation_type_modal .content .detail .dayAndNightDiv input[type='radio']:not(old)+label { background : none; padding : 0 10px; line-height:55px; height:55px; font-size:17px; font-weight:bold; } 

</style>