<template>
    <ul class="example-departments">
        <li :key="departments.id" v-if="level == 1">
            <input type="checkbox" :id="`filter-departments-${departments.id}`" />
            <label :for="`filter-departments-${departments.id}`">{{ departments.name }}</label>
            <ExampleDepartments :level="level + 1" :departments="departments.departments" v-if="departments.departments != null && departments.departments.length > 0" />
        </li>
        <li :key="department.id" v-for="department of departments" v-else>
            <input type="checkbox" :id="`filter-departments-${department.id}`" />
            <label :for="`filter-departments-${department.id}`">{{ department.name }}</label>
            <ExampleDepartments :level="level + 1" :departments="department.departments" v-if="department.departments != null && department.departments.length > 0" />
        </li>
    </ul>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ExampleDepartments extends Mixins(VueHoduCommon) {

    @Prop() level       !: number;
    @Prop() departments !: any; 

    mounted() : void {
        
    }

}
</script>

<style scoped>
    .example-departments { float : left; }
    .example-departments > li { display: inline-block; width: 100%; margin-left : 5px; }
    .example-departments > li > input[type='checkbox'] { display: block; width: 13px; float: left; }
    .example-departments > li > label { display: block; float: left; }
</style>