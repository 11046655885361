<template>
    <div id="kids_attendance" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <!-- <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a> -->
            <h3 id="viewTitle" class="title_doc">출석부</h3>

            <select class="fr input_btn" @change="changeCalendar($event.target.value)" v-if="get_group_role(scope_group_id) != 'GROUP_USER'">
                <option value="DAYGRID_MONTH" :selected="calendar_view_type == 'DAYGRID_MONTH'">월별 달력</option>
                <option value="LIST_MONTH" :selected="calendar_view_type == 'LIST_MONTH'">월별 리스트</option>
                <option value="LIST_WEEK" :selected="calendar_view_type == 'LIST_WEEK'">주별 리스트</option>
                <option value="LIST_DAY" :selected="calendar_view_type == 'LIST_DAY'">일별 리스트</option>
            </select>
        </div>

        <KidsAttendanceDayGridMonth :event_bus="event_bus" @changeCalendar="changeCalendar" v-if="isDayGridMonth() == true" />
        <KidsAttendanceListMonth :event_bus="event_bus" v-if="isListMonth() == true" />
        <KidsAttendanceListWeek :event_bus="event_bus" v-if="isListWeek() == true" />
        <KidsAttendanceListDay :event_bus="event_bus" v-if="isListDay() == true" />
        <KidsAttendanceMyList :event_bus="event_bus" v-if="isMyList() == true" />

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import KidsAttendanceDayGridMonth from '@/components/kids/KidsAttendanceDayGridMonth.vue';
import KidsAttendanceListMonth from '@/components/kids/KidsAttendanceListMonth.vue';
import KidsAttendanceListWeek from '@/components/kids/KidsAttendanceListWeek.vue';
import KidsAttendanceListDay from '@/components/kids/KidsAttendanceListDay.vue';
import KidsAttendanceMyList from '@/components/kids/KidsAttendanceMyList.vue';

import { attendance_enum } from '@/model/attendance';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        KidsAttendanceDayGridMonth, KidsAttendanceListMonth, KidsAttendanceListWeek, KidsAttendanceListDay, KidsAttendanceMyList,
        ResizeObserver
    },
})
export default class KidsAttendance extends Mixins(VueHoduCommon) {

    /**
     * @AttendanceInfo.State
     */
    @AttendanceInfo.State calendar_view_type !: attendance_enum.ATTENDANCE_CALENDAR_TYPE;

    /**
     * @AttendanceInfo.Action
     */
    @AttendanceInfo.Action doSetCalendarViewType ?: (params : attendance_enum.ATTENDANCE_CALENDAR_TYPE) => void;

    event_bus : Vue = new Vue();

    mounted() : void {
        
    }

    /**
     * 월 달력
     */
    isDayGridMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.DAYGRID_MONTH;
    }

    /**
     * 월별 리스트
     */
    isListMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_MONTH;
    }

    /**
     * 주별 리스트
     */
    isListWeek() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_WEEK;
    }

    /**
     * 일별 리스트
     */
    isListDay() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_DAY;
    }

    /**
     * 내 현황 달력
     */
    isMyCalendar() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.CALENDAR;
    }

    /**
     * 내 현황 리스트
     */
    isMyList() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST;
    }

    /**
     * 달력 변경
     */
    changeCalendar(calendar_type : attendance_enum.ATTENDANCE_CALENDAR_TYPE) : void {
        this.doSetCalendarViewType?.(calendar_type);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        if( this.isDayGridMonth() == true ) this.event_bus.$emit('daygridMonthResize');
        else if( this.isListDay() == true ) this.event_bus.$emit('listDayResize');
        else if( this.isListWeek() == true ) this.event_bus.$emit('listWeekResize');
        else if( this.isListMonth() == true ) this.event_bus.$emit('listMonthResize');
        else if( this.isMyCalendar() == true ) this.event_bus.$emit('myCalendarResize');
        else if( this.isMyList() == true ) this.event_bus.$emit('myListResize');
    }

}
</script>

<style>
    #kids_attendance .schedule_box .refresh { position : absolute; right : 18px; top : 12.5px; width : 40px; height : 40px; background : url('../../assets/images/contents/ic_repeat_bk.png') no-repeat center; }
    #container #section_ce.left_area_close .section_ce_fix  div#calendar { padding-left : 61px; }
    #container #section_ce.left_area_close #kids_attendance div#calendar { padding-left :0; }
</style>

<style scoped>
    .title_doc { margin-left: 8px; }
    #container #section_ce.left_area_close #kids_attendance #attendance_day_grid_month { padding-left : 61px; }
    .title_box .input_btn { cursor: pointer; margin-top:10px; padding-right: 35px !important; appearance: none; -webkit-appearance: none;background: url('../../assets/images/side_area/bt_open.png') no-repeat 90% center !important } 
    .title_box .input_btn:focus { background-image: url('../../assets/images/side_area/bt_close.png') !important; }
</style>