<template>
    <div class="previewContent">

        <div class="yearData">
            <!-- <input type="button" :value="`${hodu_date_to_format_string(selected_date, 'YYYY')}년`" /> -->
            <select class="firstSelect" @change="changeSelectedDateYear(Number($event.target.value))">
                <option :key="n" :value="(n - 1) + 2020" v-for="n in 31">{{ `${(n - 1) + 2020} 년` }}</option>
                <!-- <option>2020년</option>
                <option>2021년</option>
                <option>2022년</option> -->
            </select>
            <select name="3월" class="secondSelect" @change="changeSelectedDateMonth(Number($event.target.value))">
                <option :key="n" :value="(n - 1)" v-for="n in 12" :selected="selected_date.getMonth() == (n - 1)">
                    {{ `${n} 월` }}
                </option>
                <!-- <option value="">1 월</option>
                <option value="">2 월</option>
                <option value="3" selected>3 월</option>
                <option value="">4 월</option>
                <option value="">5 월</option>
                <option value="">6 월</option>
                <option value="">7 월</option>
                <option value="">8 월</option>
                <option value="">9 월</option>
                <option value="">10 월</option>
                <option value="">11 월</option>
                <option value="">12 월</option> -->
            </select>
            <!-- <a>1 월</a>
            <a>2 월</a>
            <a class="on">3 월</a>
            <a>4 월</a>
            <a>5 월</a>
            <a>6 월</a>
            <a>7 월</a>
            <a>8 월</a>
            <a>9 월</a>
            <a>10 월</a>
            <a>11 월</a>
            <a>12 월</a> -->
        </div>
        <ol class="reservationsData" :style="ol_width_style">
            <li :key="new Date(daily.orig_date).getTime()" v-for="(daily, index) in appointment_daily_list" :id="`daily_${index}`"
                :class="{
                    offDay : daily.is_work_day == false,
                    today : getDateDiff(new Date(daily.orig_date), new Date()) < 1,
                    after : daily.is_work_day == true && new Date().getTime() <= new Date(daily.orig_date).getTime() && getDateDiff(new Date(daily.orig_date), new Date()) > 0,
                }">
                <a @click="getDailyAppointmentList(daily)">
                    <span class="dateData">{{ `${hodu_date_to_format_string(new Date(daily.orig_date), 'D')} 일` }}</span>
                    <span class="totalData">{{ daily.is_work_day == true ? `${daily.cnt}건` : '휴무' }}</span>
                </a>
            </li>

            <!-- <li class="offDay">
                <a>
                    <span class="dateData">1 일</span>
                    <span class="totalData">휴무</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">2 월</span>
                    <span class="totalData">282건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">3 화</span>
                    <span class="totalData">127건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">4 수</span>
                    <span class="totalData">182건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">5 목</span>
                    <span class="totalData">210건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">6 금</span>
                    <span class="totalData">232건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">7 토</span>
                    <span class="totalData">87건</span>
                </a>
            </li>

            <li class="offDay">
                <a>
                    <span class="dateData">8 일</span>
                    <span class="totalData">휴무</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">9 월</span>
                    <span class="totalData">282건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">10 화</span>
                    <span class="totalData">127건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">11 수</span>
                    <span class="totalData">182건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">12 목</span>
                    <span class="totalData">210건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">13 금</span>
                    <span class="totalData">232건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">14 토</span>
                    <span class="totalData">87건</span>
                </a>
            </li>

            <li class="offDay">
                <a>
                    <span class="dateData">15 일</span>
                    <span class="totalData">휴무</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">16 월</span>
                    <span class="totalData">282건</span>
                </a>
            </li>
            <li class="publicHoliday offDay">
                <a>
                    <span class="dateData">17 화</span>
                    <span class="totalData">공휴일</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">18 수</span>
                    <span class="totalData">182건</span>
                </a>
            </li>
            <li>
                <a>
                    <span class="dateData">19 목</span>
                    <span class="totalData">210건</span>
                </a>
            </li>
            <li class="today">
                <a>
                    <span class="dateData">오늘</span>
                    <span class="totalData">232건</span>
                </a>
            </li>
            <li class="after">
                <a>
                    <span class="dateData">21 토</span>
                    <span class="totalData">87건</span>
                </a>
            </li>

            <li class="offDay">
                <a>
                    <span class="dateData">22 일</span>
                    <span class="totalData">휴무</span>
                </a>
            </li>
            <li class="after">
                <a>
                    <span class="dateData">23 월</span>
                    <span class="totalData">282건</span>
                </a>
            </li>
            <li class="after">
                <a>
                    <span class="dateData">24 화</span>
                    <span class="totalData">0 건</span>
                </a>
            </li>
            <li class="after">
                <a>
                    <span class="dateData">25 수</span>
                    <span class="totalData">0 건</span>
                </a>
            </li>
            <li class="after">
                <a>
                    <span class="dateData">26 목</span>
                    <span class="totalData">2 건</span>
                </a>
            </li>
            <li class="after">
                <a>
                    <span class="dateData">27 금</span>
                    <span class="totalData">0 건</span>
                </a>
            </li>
            <li class="after">
                <a>
                    <span class="dateData">28 토</span>
                    <span class="totalData">0 건</span>
                </a>
            </li>
            <li class="offDay">
                <a>
                    <span class="dateData">29 일</span>
                    <span class="totalData">휴무</span>
                </a>
            </li>
            <li class="after">
                <a>
                    <span class="dateData">30 월</span>
                    <span class="totalData">0 건</span>
                </a>
            </li>
            <li class="after">
                <a>
                    <span class="dateData">31 화</span>
                    <span class="totalData">0 건</span>
                </a>
            </li> -->
        </ol>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { hodu_doc_object } from '@/model/hodudoc';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class AppointmentPreviewList extends Mixins(VueHoduCommon) {
    
    @Prop() event_bus  !: Vue;
    @Prop() start_date !: Date;

    appointment_daily_list : hodu_doc_object.appointment_daily_info[] = [];
    selected_date : Date = new Date();
    ol_width_style = "";

    async mounted() : Promise<void> {
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        if( this.event_bus != null ) { this.event_bus.$on('previewResize', this.handleResize); }
        

        // start_date의 월의 첫 날, 마지막 날을 기준으로 예약 기간별 리스트를 조회한다
        const start : Date = new Date(this.start_date);
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const end : Date = new Date(this.start_date);
        end.setMonth(end.getMonth() + 1);
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        this.selected_date = new Date(this.start_date);

        await this.getAppointmentCountByRange(start, end);
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const li_width : number | undefined = $('.reservationsData li').outerWidth();
        this.ol_width_style = `width : ${(this.appointment_daily_list.length * ( (li_width ? li_width : 72) + 10 ) + 60)}px !important`;

        const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
        const right_area_controller_width : number | undefined = $('#right_area_controller').outerWidth();

        const scroll_width : number = window.innerWidth - (left_area_width ? left_area_width : 0) 
                                                        - (right_area_controller_width ? right_area_controller_width : 0);
        // @ts-ignore
        $('.previewContent').mCustomScrollbar({
            axis : 'x',
            // scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setWidth : scroll_width,
        });

        this.$nextTick(() => {
            $('.previewContent .mCSB_container').width(this.appointment_daily_list.length * ( (li_width ? li_width : 72) + 10 ) + 60);

            // 오늘과 같은 년월 이라면 오늘 날짜를 가운데로 두도록 스크롤
            if( new Date().getFullYear() == this.selected_date.getFullYear() && new Date().getMonth() == this.selected_date.getMonth() ) {
                const max_item : number = Math.ceil(scroll_width / ( (li_width ? li_width : 72) + 10 ));
                const center : number = Math.ceil(max_item / 2);
                const item_index = new Date().getDate() - 1;

                // @ts-ignore
                $('.previewContent').mCustomScrollbar('scrollTo', `#daily_${item_index - center}`, { scrollInertia : 0 });
            }
        })
    }

    /**
     * 예약 기간별 리스트 조회
     */
    async getAppointmentCountByRange(start_date : Date, end_date : Date) : Promise<void> {
        start_date = start_date instanceof Date ? start_date : new Date(start_date);
        end_date   = end_date   instanceof Date ? end_date   : new Date(end_date);

        const start : string = moment(start_date).utc().format();
        const end   : string = moment(end_date).utc().format();

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/appointmentCountByRange?start=${start}&end=${end}`, API_METHOD.GET)
            .then((response) => {
                
                // 병원 규칙이 없는 경우 throw
                if( this.hospital_setting_info == null ) {
                    throw new Error("병원 정보를 찾을 수 없습니다\n잠시 후 다시 시도해주세요");
                }

                console.log(response);
                this.appointment_daily_list.splice(0, this.appointment_daily_list.length);
                this.appointment_daily_list = this.appointment_daily_list.concat(response.data.data);

                for( const appointment of this.appointment_daily_list ) {
                    const appointment_daily_obj : hodu_doc_object.appointment_daily_info = JSON.parse(JSON.stringify(appointment));
                    
                    const target_date : Date = new Date(appointment_daily_obj.orig_date);
                    const target_day_of_week : number = target_date.getDay();

                    // 해당 날짜에 맞는 병원 규칙을 가져온다
                    const hospital_daily_setting : hodu_doc_object.hospital_setting_info = this.hospital_setting_info.filter(
                        item => item.day_of_week == target_day_of_week &&
                        new Date(item.start_date).getTime() <= target_date.getTime() &&
                        new Date(item.end_date).getTime()   >= target_date.getTime() 
                    )[0];

                    // 병원 규칙을 찾지 못한 경우 휴무 처리
                    if( hospital_daily_setting == null ) {
                        appointment_daily_obj.is_work_day = false;
                        this.appointment_daily_list.splice(this.appointment_daily_list.indexOf(appointment), 1, appointment_daily_obj);
                        continue;
                    }

                    // 병원 오전, 오후 둘다 휴무라면 휴무 처리
                    appointment_daily_obj.is_work_day = true;
                    if( ( hospital_daily_setting.start_am_time == null || hospital_daily_setting.start_am_time.length < 1 ) &&
                        ( hospital_daily_setting.end_am_time   == null || hospital_daily_setting.end_am_time.length   < 1 ) ) {
                        appointment_daily_obj.is_work_day = false;
                    }

                    // 기존 배열 아이템 대체
                    this.appointment_daily_list.splice(this.appointment_daily_list.indexOf(appointment), 1, appointment_daily_obj);
                    this.handleResize();
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 해당 일자 예약 리스트 조회
     */
    getDailyAppointmentList(daily : hodu_doc_object.appointment_daily_info) : void {
        if( daily.is_work_day == false ) { return; }
        this.$emit('dailyAppointments', new Date(daily.orig_date))
    }

    /**
     * 년도 선택
     */
    changeSelectedDateYear(year : number) : void {
        this.selected_date.setDate(1);
        this.selected_date.setFullYear(year);

        // start_date의 월의 첫 날, 마지막 날을 기준으로 예약 기간별 리스트를 조회한다
        const start : Date = new Date(this.selected_date);
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const end : Date = new Date(this.selected_date);
        end.setMonth(end.getMonth() + 1);
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        this.getAppointmentCountByRange(start, end);
    }

    /**
     * 월 선택
     */
    changeSelectedDateMonth(month : number) : void {
        this.selected_date.setDate(1);
        this.selected_date.setMonth(month);

        // start_date의 월의 첫 날, 마지막 날을 기준으로 예약 기간별 리스트를 조회한다
        const start : Date = new Date(this.selected_date);
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const end : Date = new Date(this.selected_date);
        end.setMonth(end.getMonth() + 1);
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        this.getAppointmentCountByRange(start, end);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('.previewContent').mCustomScrollbar('destroy');
        this.ol_width_style = "";
    	this.setScroll();
    }

    /**
     * left_control_box_flag 조정시 애니메이션 완료 후 리사이즈
     */
    @Watch('left_control_box_flag')
    watchLeftControlBoxFlag() : void {
        setTimeout(() => { this.handleResize(); }, 100);
    }

}
</script>

<style scoped>
    /* 간략정보 */
    .yearData  { transition:0.1s; position: fixed; top:158px;left:290px; padding-bottom: 20px; }
    .yearData input { float:left; font-size: 18px;height: 31px; line-height: 31px; font-weight:bold; background: none; float:left; margin-right: 20px; }
    .yearData input:hover { color:#477fff; }
    .yearData select { cursor:pointer; float:left; padding: 0 20px; border-radius: 20px; border:1px solid #404a67; background: url('../../assets/images/side_area/bt_open.png') no-repeat 89% center;  padding-left: 17px; box-sizing:border-box;height: 35px; line-height: 33px; font-size: 14px;font-weight: bold; -webkit-appearance: none;-moz-appearance: none;text-indent: 1px;text-overflow: ''; }
    .yearData select:focus { border-color:#477fff; background-image: url('../../assets/images/side_area/bt_close.png') }
    .left_area_close .yearData {left: 92px;}

    .yearData .firstSelect { border-radius: 20px 0 0 20px; box-sizing: border-box; width: 90px; background-image: none !important; padding-right:20px;  }
    .yearData .secondSelect { border-radius: 0 20px 20px 0; border-left: 0; padding-right: 40px }
    .yearData  option { font-size: 14px; }
    /* .yearData a { display:inline-block; width: 50px;text-align:center; margin-right: 10px; border-radius: 20px; height: 30px; line-height: 30px; font-weight:bold; font-size: 13px; }
    .yearData a:hover { color:#477fff; }
    .yearData a.on { background: #404a67;color:#fff; }
    .yearData a.on:hover { cursor: default; } */
    .previewContent { position:relative; padding: 60px 0 0; width:100%; margin-top: 27px; height: 160px; box-sizing:border-box; transition:0.2s;border-bottom:1px solid #e7e9ea }
    .previewContent.on { margin-top: 0; height: 0;margin-bottom:0; padding-top:0; }
    .previewContent.on * { display:none; }
    .previewContent h4 { font-size: 18px; padding: 20px 0 20px; }
    .reservationsData { padding: 0 30px; box-sizing: border-box; margin-right:0 !important;}
    .reservationsData li { transition:0.2s; display:inline-block; width: 70px; border: 1px solid #f1f3f5;height: 60px; border-radius: 3px;margin-right: 10px;}
    .reservationsData li a { display:block; width:100%; height:100%; }
    .reservationsData li:hover {border-color:#d5d9dc}
    .reservationsData li.after:hover { border-color: #477fff;}
    .reservationsData li:hover span.dateData {  }
    .reservationsData li.offDay { background: #f1f3f5;  }
    .reservationsData li.offDay:hover { border-color: #f1f3f5; }
    .reservationsData li.offDay:hover span { opacity: 0.6; }
    .reservationsData li.offDay a:hover { cursor:default; }
    .reservationsData li.offDay span, .reservationsData li span { opacity:0.6 }
    .reservationsData li.after span, .reservationsData li.today span, .reservationsData li:hover span { opacity:1 }
    /* .reservationsData li.publicHoliday { border-color:#ff4949; color:#ff4949}
    .reservationsData li.publicHoliday:hover { background:#fff9f9 }
    .reservationsData li.publicHoliday span {color:#ff4949}
    .reservationsData li.publicHoliday:hover span {color:#ff4949} */
    .reservationsData li span { display:block; text-align: center; }
    .reservationsData li span.dateData { font-weight:bold; font-size: 13px; line-height: 30px; padding-top:4px; }
    .reservationsData li span.totalData { font-weight: bold; }
    .reservationsData li.today { background:  #477fff; border-color:#477fff }
    .reservationsData li.today span, .reservationsData li.today:hover span { color:#fff; }

    
</style>

<style >
    .previewContent .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { margin-top:10px !important; margin-bottom: 0 !important; height: 6px; }
    .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background:#477fff; }
</style>