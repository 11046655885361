<template>
    <div class="section_ce_fix">
        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle"><span class="cl dc4"></span>프리미엄 그룹 생성</h3>
        </div>

        <div class="default_form">
            <fieldset>
                <legend>기본 신청양식</legend>
                <dl class="in_form">
                    <!--
                    <dt><label for="biz_id">회사코드</label></dt>
                    <dd>
                        <input type="text" id="biz_id" name="biz_id" class="input_txt" maxlength="10" placeholder="사업장코드" style="width:150px" @input="admin_create_biz_id = $event.target.value"/>
                        &nbsp;<input type="button" id="biz_id_check" value="중복검사" class="bizcheck" @click="biz_check()"/>
                    </dd>   	
                    -->
                    <dt><label for="biz_nm">회사명</label></dt>
                    <dd><input type="text" id="biz_nm" name="biz_nm" class="input_txt size_m" maxlength="19" placeholder="회사명을 입력하세요"/></dd>

                    <dt><label for="is_cert">인증여부</label></dt>
                    <dd>
                        <select id="is_cert" name="is_cert" class="input_txt size_s">
                            <option value=true>사용</option>
                            <option value=false>미사용</option>
                        </select>
                    </dd>
                    <dt><label for="biz_id">USER_ID</label></dt>
                    <dd>
                        <input type="text" id="user_id" name="user_id" class="input_txt" maxlength="6" placeholder="관리자 아이디" style="width:150px" @input="admin_user_id = $event.target.value"/>
                    </dd>
                </dl>
                <br/><br/>
                <ul class="btns">
                    <li><input type="button" id="btn_ok" value="저장" class="btn_ok" @click="biz_create_save()"/></li>
                </ul>
            </fieldset>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { GROUP_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';

@Component({

})
export default class BizCreate extends Mixins(VueHoduCommon) {
    admin_user_id       : string  = "";
    admin_create_biz_id : string  = "";
    check_biz_id        : string  = "";
    b_biz_id_flag       : boolean = false;

    mounted() : void {

    }

    /*
    biz_check() : void {
        if ( this.admin_create_biz_id.trim().length < 2 ) {
            this.hodu_show_dialog("cancel", "사업장코드 를 입력해주세요 최소 2자이상", ['확인']);
            return;
        }

        this.hodu_api_call(`api/v1/admin/biz/biz_admin/getBizIdCheck?biz_id=${this.admin_create_biz_id}`, 'get', null)
            .then(async(response) => {
                if ( response.data.data.count > 0 ) {
                    this.check_biz_id  = "";
                    this.b_biz_id_flag = false;
                    this.hodu_show_dialog("cancel", "사용하실 수 없는 사업장코드 입니다 (중복)", ['확인']);
                    return;
                }
                else {
                    this.check_biz_id  = this.admin_create_biz_id;
                    this.b_biz_id_flag = true;
                    this.hodu_show_dialog("success", "사용가능 합니다", ['확인']);
                    return;
                }
            })
            .catch(async(e) => {
                if ( (e.response.status) && e.response.status == 403 ) {
                    this.movePrevPage();
                    return;
                }
            });        
    }
    */

    biz_create_save() : void {
        let is_cert = $('#is_cert').val();
        let ls_biz_nm = $("#biz_nm").val();

        /*
        if ( !this.b_biz_id_flag ) {
            this.hodu_show_dialog("cancel", "사업장코드 중복체크 검사를 해주세요", ['확인']);
            return;
        }

        if ( this.admin_create_biz_id.trim().length < 2 ) {
            this.hodu_show_dialog("cancel", "사업장코드 를 입력해주세요 최소 2자이상", ['확인']);
            return;
        }

        if ( this.check_biz_id != this.admin_create_biz_id ) {
            this.hodu_show_dialog("cancel", "사업장코드 중복체크 검사를 해주세요", ['확인']);
            return;
        }
        */

        if ( ls_biz_nm == "" ) {
            this.hodu_show_dialog("cancel", "회사명을 입력해주세요", ['확인']);
            return;
        }

        if ( !this.admin_user_id ) {
            this.hodu_show_dialog("cancel", "관리자 아이디를 입력해주세요", ['확인']);
            return;
        }

        var regexp = /^[0-9]*$/

        if ( !regexp.test(this.admin_user_id) ) {
            this.hodu_show_dialog("cancel", "관리자아이디는 숫자만 입력하실수 있습니다", ['확인']);
            return;
        }

        let insert_data = {
            //biz_id: this.admin_create_biz_id,
            biz_nm: ls_biz_nm,
            group_id: 0,
            group_type: GROUP_TYPE.BIZC,
            is_cert: is_cert,
            biz_master_id: this.admin_user_id
        }

        this.hodu_api_call(`api/v1/admin/biz/biz_admin`, API_METHOD.POST, insert_data)
            .then(async(response) => {
                this.admin_create_biz_id = "";
                this.check_biz_id        = "";
                this.b_biz_id_flag       = false;

                //$('#biz_id').val("");
                $('#biz_nm').val("");

                this.hodu_show_dialog("success", "등록되었습니다", ['확인']);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            }); 
    }
}
</script>

<style scoped>
ol,ul{list-style: none}
table{width:100%;border-collapse:collapse;border-spacing:0}
table th,table td{vertical-align:middle}
hr,legend,caption{font-size:0;height:0;line-height:0}
em{font-style:normal}
a{color:inherit;text-decoration:none}
a:hover{text-decoration:none}
body{font-family:"Dotum", sans-serif}

::-webkit-input-placeholder {font-family:"Dotum", sans-serif;font-size:11px;color:#999}
:-moz-placeholder {font-family:"Dotum", sans-serif;font-size:11px;color:#999}
::-moz-placeholder {font-family:"Dotum", sans-serif;font-size:11px;color:#999}
:-ms-input-placeholder {font-family:"Dotum", sans-serif;font-size:11px;color:#999}

/** cs_form **/
.cs_form{display:inline-block;position:relative;font-size:0}
.cs_form input{position:absolute;left:-9999px;top:0;width:5px;height: 5px;outline:none;-webkit-appearance:none}
/*.cs_form label{display:inline-block;position:relative;padding:0 0 0 25px;font-size:12px;color:#333;height:20px;line-height:20px;cursor:pointer;background:url('images/form/cs_check.jpg') no-repeat 0 0}*/
/*.cs_form.cs_radio label{background:url('images/form/cs_radio.jpg') no-repeat 0 0}*/
.cs_form input:checked + label{background-position:0 -20px}
.cs_form label.on{background-position:0 -20px}

/** default_form **/
.default_form {margin:30px;padding:10px 10px 30px 10px;width:570px;font-size:13px;border:solid 3px #ddd;background:#fff}
.left_area_close .default_form { margin: 30px 0 30px 95px; }
.default_form button{margin:0;padding:0 0 3px 0;font-size:12px;color:#fff;border:none;cursor:pointer}
.default_form input, .default_form select, .default_form textarea{margin:0;padding:0;box-sizing:border-box}
.default_form select,.default_form .input_txt{padding:0 5px;width:100%;height:26px;line-height:26px;border:solid 1px #ddd}
.default_form .size_s{width:100px}
.default_form .size_sx{width:150px}
.default_form .size_m{width:250px}
.default_form .in_form{position:relative;padding:0 0 0 80px}
.default_form .in_form > dt{position:absolute;left:0;font-weight:bold;line-height:36px}
.default_form .in_form > dd{padding:5px 0;min-height:26px}
.default_form .in_form > dd [class^="formlist_"]{overflow:hidden}
.default_form .in_form > dd [class^="formlist_"] li{float:left;margin:0 0 0 10px;box-sizing:border-box}
.default_form .in_form > dd [class^="formlist_"] li:first-child{margin:0}
.default_form .in_form > dd .formlist_tel{width:250px}
.default_form .in_form > dd .formlist_tel li{position:relative;margin:0;padding:0 0 0 20px;width:35%}
.default_form .in_form > dd .formlist_tel li:before{content:'';position:absolute;left:7px;top:50%;margin:-1px 0 0 0;width:6px;height:2px;background:#555}
.default_form .in_form > dd .formlist_tel li select{padding:0}
.default_form .in_form > dd .formlist_tel li:first-child{width:30%;padding:0}
.default_form .in_form > dd .formlist_tel li:first-child:before{display:none}
.default_form .in_form > dd .formlist_post{margin:0 0 5px 0}
.default_form .in_form > dd .formlist_post li{width:76px}
.default_form .in_form > dd .formlist_post li .btn_post{width:77px;height:26px;line-height:24px;background:#333}
.default_form .in_form > dd .btn_overlap{width:200px;height:26px;line-height:24px;background:#333}
.default_form .in_form > dd .formlist_address li{margin:0;padding:0 0 0 10px;width:61.5%}
.default_form .in_form > dd .formlist_address li:first-child{padding:0;width:38.5%}
.default_form .agree{margin:10px 0 30px 0;text-align:center}
.default_form .btns{font-size:0;text-align:center}
.default_form .btns li{display:inline-block;margin:0 4px;font-size:12px}
.default_form .btns li .btn_ok{width:100px;height:30px;font-size:13px;font-weight:bold;color:#fff;line-height:27px;border:solid 1px #bbb;background:orange;cursor:pointer;}
.default_form .btns li .btn_cancel{width:100px;height:30px;font-size:13px;font-weight:bold;color:#333;line-height:27px;border:solid 1px #bbb;background:#fff;cursor:pointer;}
.bizcheck{width:100px;height:30px;font-size:13px;font-weight:bold;color:#333;line-height:27px;border:solid 1px #bbb;background:#fff;cursor:pointer;}
</style>