<template>
    <div id="approval_form_download">

        <div class="filter_tap">
            
            <div class="content_top">
                <!-- <a href="#" class="bt_hambuger2" :class="{ change : is_open_tab == true }"></a> -->
                <h3 style="margin-left: 25px;">분류</h3>
                <!-- <input type="text" id="filter_search" placeholder="파일명으로 검색하세요"/> -->
                <!-- <a href="#" class="cont_del2">삭제</a> -->
                <!-- <a href="#" class="down">다운로드</a> -->
            </div>

            <div class="nav_item2" :class="{ open : is_open_tab == true }" style="position: relative;">
                <ul class="approval_division_title_name">
                    <li :key="template.template_class_id" v-for="(template,index) in templates">
                        <input type="checkbox" :id="`TemplateCheck${index}`" v-model="template.is_selected" class="input_check" @change="selectClass($event,index)"/>
                        <label :for="`TemplateCheck${index}`" ></label>
                        <span class="temp_text">{{template.template_class_name}}</span>
                        <span class="amount">{{template.files.length}}</span>
                    </li>
                    <!--
                    <li>
                        <input type="checkbox" id="TemplateCheck02" value="" class="input_check">
                        <label for="TemplateCheck02" >
                            <span class="temp_text">총무/서무서식</span>
                            <span class="amount">31</span>
                        </label>
                    </li>
                    <li>
                        <input type="checkbox" id="TemplateCheck03" value="" class="input_check">
                        <label for="TemplateCheck03" >
                            <span class="temp_text">무역/수출입서식</span>
                            <span class="amount">41</span>
                        </label>
                    </li>
                    <li>
                        <input type="checkbox" id="TemplateCheck04" value="" class="input_check">
                        <label for="TemplateCheck04" >
                            <span class="temp_text">경영/관리서식</span>
                            <span class="amount">41</span>
                        </label>
                    </li>
                    <li>
                        <input type="checkbox" id="TemplateCheck05" value="" class="input_check">
                        <label for="TemplateCheck05" >
                            <span class="temp_text">구매/외주서식</span>
                            <span class="amount">41</span>
                        </label>
                    </li>
                    <li>
                        <input type="checkbox" id="TemplateCheck06" value="" class="input_check">
                        <label for="TemplateCheck06" >
                            <span class="temp_text">세무/회계서식</span>
                            <span class="amount">41</span>
                        </label>
                    </li>
                     -->
                </ul>
            </div>
        </div>


<!-- 스크롤 위치 변경 -->
        <div class="content" id="form_list_scroll" style="width: 100%;">
            <div id="noticeScroll" class="section_scroll">
                <div class="viewGroup">
                    <div class="schedule_box"></div>
                    <div class="fixedDiv">
                        <ul id="" class="sortHeader groupNoticeHeader" style="line-height:57px;height:57px;">
                            <!-- <li class="notiCheck" style="padding-left: 20px;box-sizing: border-box;">
                                <p id="selectCheckBoxAll" class="checkBox on">
                                    <input type="checkbox" id="selectAll">
                                    <label for="selectAll">전체선택</label>
                                </p>
                            </li> -->
                            <li class="num">No</li>
                            <li class="title2 headerSort"><span style="margin-left:15px;">양식명</span></li>
                            <li class="group2 headerSort"><span>분류 </span></li>
                            <li class="writtenBy2 headerSort"><span style="margin-left:0;">현황</span></li>
                        </ul>
                    </div>
<!-- 스크롤이 들어가야할 곳 -->
                    <div id="approval_list" class="content" style="position : relative; margin-right : 0;">
                        <ul class="notiListUl">
                            <li :key="`${template.template_class_id}-${template.template_id}`" v-for="(template,index) in computedTemplates" >
                                <!-- <p class="notiCheck"  style="padding-left: 20px;">
                                    <input type="checkbox" id="notiCheck01" value="" class="input_check">
                                    <label for="notiCheck01" style="margin-top: 18px;"></label></p> -->
                                <p class="num"><span >{{index+1}}</span></p>
                                <p class="title2">
                                    <ul class="title_info2">
                                        <li class="list_title">{{template.template_name}}</li>
                                    </ul>
                                </p>
                                <p class="group2">
                                    <ul class="group_info2">
                                        <li class="list_group">{{template.template_class_name}}</li>
                                    </ul>
                                </p>
                                <p class="writtenBy2" @click.prevent="hodu_download(`/app_images/${template.contents.file_url}`, template.contents.file_name)" >
                                    <span >다운로드</span>
                                    <a href="#" class="download"  ></a>
                                </p>
                            </li>
                            <!--
                            <li class="">
                                <p class="notiCheck" style="padding-left: 20px;">
                                    <input type="checkbox" id="notiCheck01" value="" class="input_check">
                                    <label for="notiCheck01" style="margin-top: 18px;"></label></p>
                                <p class="num"><span >2</span></p>
                                <p class="title2">
                                    <ul class="title_info2">
                                        <li class="list_title">구매 품의서</li>
                                    </ul>
                                </p>
                                <p  class="group2">
                                    <ul class="group_info2">
                                        <li class="list_group">총무/서무서식</li>
                                    </ul>
                                </p>
                                <p class="writtenBy2">
                                    <span >미리보기</span>
                                    <a href="#" class="download" @click.prevent="downloadForm()"></a>
                                </p>
                            </li>
                            -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, {API_METHOD} from '@/mixin/VueHoduCommon';
import { approval_modal_interface, approval_interface } from '@/model/approval';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ApprovalFormDownload extends Mixins(VueHoduCommon) {

/**
     * 부서 정보
     */
    get computedTemplates() : any[] {
        let templates = JSON.parse(JSON.stringify(this.templates));

        var template_files : any[] = []

        for ( const template of templates ) {
            if ( template.is_selected ) {
                for ( const file of template.files ) {
                    file.template_class_name = template.template_class_name
                }
                template_files = template_files.concat(template.files)
            }
        }

        return template_files;
    }

    is_open_tab : boolean = true;
    
    templates : approval_interface.ApprovalTemplateBundle[] = []

    async mounted() : Promise<void> {
        await this.getApprovalTemplateInfo();
        this.setScroll();
    }

    async getApprovalTemplateInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class?include_templates=${true}`, API_METHOD.GET, null, false);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }

            this.templates = response.data.data.approval_templates.filter(template => template.template_state == '0' || template.template_state == '10');
            for (const template of this.templates) {
                template.is_selected = true
                template.is_fold = true
            }

            this.templates.sort((o1, o2) : number => {
                if( o1.template_state > o2.template_state ) return 1;
                else if( o1.template_state < o2.template_state ) return -1;
                else if( o1.template_class_id > o2.template_class_id ) return 1;
                else if( o1.template_class_id < o2.template_class_id ) return -1;
                return 0;
            });

        } catch(e) {
            throw e;
        }
    }

    downloadForm() : void {

    }

    selectClass(event, index:number) {
        var preClass = JSON.parse(JSON.stringify(this.templates[index]))
        preClass.is_selected = event.target.checked as boolean
        this.templates.splice(index, 1, preClass)
    }
    
    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight  : number | undefined = $('.title_box').outerHeight();
        const filterHeight : number | undefined = $('.filter_tap').outerHeight();

        // @ts-ignore
        $('#form_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (filterHeight ? filterHeight : 0)
        });
    }

}
</script>

<style scoped>
    .notiListUl { padding-bottom:100px; }
    #approval div.nav_item2 ul li span.temp_text { position: relative; font-weight: bold;  /*word-wrap: break-word; word-break: break-all;*/ overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 85%; display: block; line-height: 15px; float: left; margin-top: 15px; left: 0px; top: 0px; }
    #approval div.nav_item2 ul li span.amount { position: relative; font-weight: bold; display: block; width: 5%; float: left; margin-top: -3px; margin-left: 10px; top: 0; right: 0; }
    #approval .title_info2 li.list_title { margin-left: 15px; font-size: 12px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 90%;}
    .approval_division_title_name{height: 153px; overflow-y: auto;}
    #approval .approval_content input[type='checkbox']:not(old)+label {margin-top: 6px;display: inline-block;padding: 0;width: 14px;height: 14px;border-radius: 1px;border: 2px solid rgb(193, 201, 212);background: rgb(193, 201, 212) url(/img/checked_fff.3f121842.png) no-repeat center;background-size: 17px;margin-right: 8px;float: left;}
    @media screen and (max-width: 1600px) {
        #approval div.nav_item2 ul li span.temp_text { position: relative; font-weight: bold;  /*word-wrap: break-word; word-break: break-all;*/ overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 79%; display: block; line-height: 15px; float: left; margin-top: 15px; left: 0px; top: 0px; }
    }
</style>