<template>
    <div id="group_survey_choice_list_modal" class="modal group_survey_choice_list_modal">
        
        <div class="titlebox">
            <h1>객관식 표 미리보기</h1>
        </div>

        <div class="content">
            <div class="conPadDiv">
                <div class="sr-type-box qust-grpcho-form">
                    <div class="qustConAnswers">
                        <div class="grpcho_opt_wrap">
                            <div class="grpcho_opt_top">
                                <p class="gpTableP grpcho_op_first_p none_space">공간</p>
                                <p class="gpTableP">매우 별로다</p>
                                <p class="gpTableP">별로다</p>
                                <p class="gpTableP">보통이다</p>
                                <p class="gpTableP">좋다</p>
                                <p class="gpTableP">매우 좋다</p>
                            </div>
                            <div class="gpChoDiv">
                                <span class="gpRowBox">
                                    <p class="gpTableP grpcho_op_first_p">체험단 주기는 어떤가요?</p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one result_chk_show"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                </span>
                            </div>
                            <div class="gpChoDiv">
                                <span class="gpRowBox">
                                    <p class="gpTableP grpcho_op_first_p">사용하기 편리한가요?</p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one result_chk_show"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                </span>
                            </div>
                            <div class="gpChoDiv">
                                <span class="gpRowBox">
                                    <p class="gpTableP grpcho_op_first_p">앱의 속도는 어떠한가요?</p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one result_chk_show"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                    <p class="gpTableP">
                                        <span class="gpInputWrap">
                                            <i class="op_chk_show op_chk_show_one"></i>
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="btns">
            <input type="button" value="닫기" @click.prevent="close">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupSurveyChoiceListModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class GroupSurveyChoiceListModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_survey_choice_list_modal_info !: GroupSurveyChoiceListModalInfo;

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupSurveyChoiceListModalInfo ?: (params : GroupSurveyChoiceListModalInfo) => void;

    mounted() : void {

    }

    close() : void {
        this.doSetGroupSurveyChoiceListModalInfo?.({ show_modal : false, data : null });
    }

}
</script>

<style scoped>
    #group_survey_choice_list_modal.modal { overflow: hidden; position:absolute; left:50%; top:50%; margin-top:-280px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height: 392px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    #group_survey_choice_list_modal h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    #group_survey_choice_list_modal .content { overflow: hidden; height: 230px;border-bottom: 1px solid #e7e9ea; display: flex; align-items: center; }
    #group_survey_choice_list_modal .btns input { transition: 0.1s; width: 100%; float: left; height: 75px; line-height: 75px; font-size: 14px; text-align: center; font-weight: bold; background-color:#fff; color: #232848; }
    .conPadDiv { padding: 10px 25px; box-sizing: border-box; }
    .grpcho_opt_wrap { border-collapse: collapse; display: table; width: 100%; }
    .grpcho_opt_top { display: table-row; cursor: default; }
    .gpTableP { display: table-cell; height: 2.5em; min-width: 48px; padding: 0.25em; text-align: center; vertical-align: middle; width: 120px; }
    .gpTableP:last-of-type { border-bottom-right-radius: 5px; border-top-right-radius: 5px; }
    .gpTableP.grpcho_op_first_p { min-width: 48px; max-width: 288px; padding: 8px; text-align: left; background: #fff; }
    .gpChoDiv { display: table-row-group; background-color: #f2f4f6; border-radius: 5px; border-bottom: 2px solid #fff; box-sizing: border-box; }
    .gpRowBox { display: table-row; border-collapse: collapse; }
    .gpTableP .gpInputWrap { display: inline-block; flex-shrink: 0; height: 20px; position: relative; vertical-align: middle; width: 20px; z-index: 0; }
    .none_space { font-size: 0; }

    .op_chk_show { display: inline-block; width: 20px; height: 20px; background: #fff; border-radius: 50%; border: 1px solid #d1d1d1; vertical-align: middle; margin-right: 10px; }
    .op_chk_show_one.result_chk_show { border-color: #477fff; position: relative; }
    .op_chk_show_one.result_chk_show:before { content: ''; width: 12px; height: 12px; border-radius: 50%; background: #477fff; position: absolute; top: 4px; left: 4px;  }
</style>