<template>
    <div id="approval_message_modal" class="modal">
<!--반려처리시/기안자---->
        <table style="display:none;">
            <thead>
                <tr style="line-height:60px;border-bottom:1px solid #e7e9ea;font-size:18px;text-align:left;">
                    <th colspan="3" style="padding:15px 0 0;width:100%;text-align:center;color:rgb(083, 084, 111);">
                        죄송합니다! 반려처리 되었습니다.
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="2" width="40%" style="padding:15px 0;text-align:center;background:rgb(241, 243, 245);font-size:14px;">
                        <p class="profile" style="margin-top:15px;"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 60px;" alt="" ></p>
                        <p style="font-weight:bold;margin-top:20px;">Kitae Park</p>
                        <p style="margin-top:15px;">차장</p>
                        <p style="margin:15px 0;">국내 영업 / 영업</p>
                    </td>
                    <td colspan="2" width="60%" height="10%" style="padding:15px 0;border-bottom:1px solid #e7e9ea;position: relative;text-align: left;">
                        <p style="padding:0 15px; color:rgb(162, 162, 162);">반려일</p>
                        <p style="position: absolute;right: 20px;top: 15px; font-weight:bold;">2020.09.06<span style="margin-left: 10px;">03:13pm</span></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="padding:15px 0;text-align: left;">
                        <p style="padding:0 15px 15px; color:rgb(162, 162, 162);">반려사유</p>
                        <p style="padding:0 15px 15px; font-weight:bold;line-height:20px;">모든 파일에는 기안자 이름이 포함되어야 합니다.</p>
                    </td>
                </tr>
                <tr style="line-height: 70px;font-size:bold;border-top:1px solid #e7e9ea;">
                    <td colspan="2" width="50%"><a href="#" class="btn_modal">확인</a></td>
                    <td style="border-left:1px solid #e7e9ea;"  width="60%"><a href="#" class="btn_modal">수정</a></td>
                </tr>
            </tbody>
        </table>
<!--수정후시/결재자------>
        <table style="">
            <thead>
                <tr style="line-height:60px;border-bottom:1px solid #e7e9ea;font-size:18px;text-align:left;">
                    <th colspan="3" style="padding:15px 0 0;width:100%;text-align:center;color:rgb(083, 084, 111);">
                        수정되었습니다.
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="2" width="40%" style="padding:15px 0;text-align:center;background:rgb(241, 243, 245);font-size:14px;">
                        <p class="profile" style="margin-top:15px;"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 60px;" alt="" ></p>
                        <p style="font-weight:bold;margin-top:20px;">Ji-in Lee</p>
                        <p style="margin-top:15px;">사원</p>
                        <p style="margin:15px 0;">개발 / 피닉스</p>
                    </td>
                    <td colspan="2" width="60%" height="10%" style="padding:15px 0;border-bottom:1px solid #e7e9ea;position: relative;text-align: left;">
                        <p style="padding:0 15px; color:rgb(162, 162, 162);">수정일</p>
                        <p style="position: absolute;right: 20px;top: 15px; font-weight:bold;">2020.09.06<span style="margin-left: 10px;">03:13pm</span></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="padding:15px 0;text-align: left;">
                        <p style="padding:0 15px 15px; color:rgb(162, 162, 162);">수정사항</p>
                        <p style="padding:0 15px 15px;line-height:20px; font-weight:bold;">모든 파일에 이름 작성하여 수정하였습니다.<br>확인 후 처리 부탁드립니다.</p>
                    </td>
                </tr>
                <tr style="line-height: 70px;font-size:bold;border-top:1px solid #e7e9ea;">
                    <td colspan="3" width="100%"><a href="#" class="btn_modal">확인</a></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ApprovalMessageModal extends Mixins(VueHoduCommon) {

    mounted() : void {
        
    }

}
</script>

<style scoped>
    .modal { text-align:center; width: 550px; height:353px; border-radius:20px; left: 50%;margin-left: -275px;top: 50%;margin-top: -176.5px;position: absolute;background: #fff;overflow: hidden; }
    .modal table{width:100%;padding:0;border:0;border-spacing:0px;border-collapse:collapse;}
    p.profile{overflow: hidden;height: 60px;width: 60px;border-radius: 18px;display: inline-block;vertical-align: top;}
    .btn_modal{display: block;font-weight:bold;font-size: 15px;color:rgb(083, 084, 111)}
    .btn_modal:hover{background: #f1f3f5;}
</style>