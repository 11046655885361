<template>
    <div class="modal vistorDetModal" :class="{ phone_empty : home_visitant_info_modal_info.car.car_info.phone == null || home_visitant_info_modal_info.car.car_info.phone.length < 1  }">
        <div class="titlebox"
             :class="{
                 ylw :  home_visitant_info_modal_info.car.car_info.is_new == true,
                 blue : home_visitant_info_modal_info.car.car_info.is_new == false && home_visitant_info_modal_info.car.car_info.is_apply == true,
                 red :  home_visitant_info_modal_info.car.car_info.is_new == false && home_visitant_info_modal_info.car.car_info.is_apply == false,
             }">   
            <h1>
                {{ home_visitant_info_modal_info.car.car_info.is_new == true ? '요청' :
                   home_visitant_info_modal_info.car.car_info.is_apply == true ? '승인' : '거절' }}
            </h1>
        </div>
        <div class="modal_content">
            <ul>
                <li>
                    <p class="title">동&amp;호수</p>
                    <p class="descript">{{ `${home_visitant_info_modal_info.car.resident_info.building}동 ${home_visitant_info_modal_info.car.resident_info.unit}호` }}</p>
                </li>
                <!-- <li>
                    <p class="title">요청자</p>
                    <p class="descript">{{ home_visitant_info_modal_info.car.resident_info.nickname }}</p>
                </li> -->
                <li v-if="home_visitant_info_modal_info.car.car_info.phone && home_visitant_info_modal_info.car.car_info.phone.length > 0">
                    <p class="title">연락처</p>
                    <p class="descript">
                        {{ 
                            home_visitant_info_modal_info.car.car_info.phone && home_visitant_info_modal_info.car.car_info.phone.length > 0 ? home_visitant_info_modal_info.car.car_info.phone : '-' 
                        }}
                    </p>
                </li>
                <li>
                    <p class="title">차량 번호</p>
                    <p class="descript">{{ home_visitant_info_modal_info.car.car_info.car_number }}</p>
                </li>
                <li>
                    <p class="title">방문 날짜</p>
                    <p class="descript">{{ getTimeText(home_visitant_info_modal_info.car) }}</p>
                </li>
                <li>
                    <p class="title">방문 기간</p>
                    <p class="descript">{{ `${getDateDiff(home_visitant_info_modal_info.car.start_date, home_visitant_info_modal_info.car.end_date) + 1} 일` }}</p>
                </li>
            </ul>
        </div>
        <div class="btns">
            <input type="button" value="확인" @click="closeModal">
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HomeVisitantInfoModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_visitant_info_modal_info !: hodu_home_modal_info.HomeVisitantInfoModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeVisitantInfoModalInfo ?: (params : hodu_home_modal_info.HomeVisitantInfoModalInfo) => void;

    mounted() : void {
        
    }

    /**
     * 시간 텍스트 반환
     */
    getTimeText(car : any) : string {

        const start_date = new Date(new Date(car.start_date));
        const end_date = new Date(car.end_date);
        
        const start = `${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;

        // 포맷 (같은 년월은 표시하지 않는다)
        let end_format = 'YYYY.MM.DD';
        if( start_date.getFullYear() == end_date.getFullYear() ) {
            end_format = 'MM.DD';

            if( start_date.getMonth() == end_date.getMonth() ) {
                end_format = 'DD';

                if( start_date.getDate() == end_date.getDate() ) {
                    end_format = '';
                }
            }
        }

        const end   = `${this.hodu_date_to_format_string(end_date, end_format)} ${this.getDayOfWeekByDate(end_date)}`;

        // return `${start}${end_format == '' ? '' : ` ~ ${end}`}`;
        return start;
    }
    
    closeModal() : void {
        if( this.doSetHomeVisitantInfoModalInfo == null ) { return; }
        
        this.doSetHomeVisitantInfoModalInfo({
            show_modal : false
        })
    }

}
</script>

<style scoped>
    .modal.vistorDetModal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-215px; margin-left:-260px; width:520px; height: 430px; background:#fff; }
    .modal.vistorDetModal.phone_empty { margin-top:-187px; height : 374px; }
    .modal.vistorDetModal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0; }
    .modal.vistorDetModal .titlebox h1 { font-size: 20px; font-weight: bold; height: 80px; line-height: 90px; }
    
    .modal.vistorDetModal .modal_content { overflow: hidden; height: 279px;border-bottom: 1px solid #e7e9ea;  }
    .modal.vistorDetModal.phone_empty .modal_content { height : 223px; }
    .modal.vistorDetModal .modal_content ul li * { display: inline-block; font-size: 13px; }
    .modal.vistorDetModal .modal_content ul li { padding:0 30px; overflow: hidden; line-height: 55px; height: 55px; border-bottom:1px solid #f1f3f5;}
    .modal.vistorDetModal .modal_content ul li .descript { float: right; text-align: right; font-weight: bold }
    .modal.vistorDetModal .titlebox h1 { font-weight: bold;text-align: center; width: 100%; background:#f1f3f5;color:#7c88a2}
    .modal.vistorDetModal .titlebox.blue  h1{ background:#477fff;color:#fff}
    .modal.vistorDetModal .titlebox.ylw h1 { background: #ffc72f;color: #202A39;}
    .modal.vistorDetModal .titlebox.red h1  { background: #ff6363; color: #fff; }

    .modal.vistorDetModal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    .modal.vistorDetModal .btns input { transition:0.2s; width: 100%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold; background: #fff; }
    .modal.vistorDetModal .btns input:hover { background:#f1f3f5; }
</style>