<template>
    <div class="modal addVistorModal">
        
        <div class="titlebox">
            <h1>방문차량 추가</h1>
        </div>

        <div class="modal_content">
            <ul>
                <li>
                    <p class="title">동</p>
                    <div class="descript">
                        <select @change="changeSelectedBuilding($event.target.value)">
                            <option :value="building" :key="building" v-for="building in computedBuildingList">{{ building }}</option>
                            <!-- <option value="">100 호</option>
                            <option value="">200 호</option>
                            <option value="">300 호</option>
                            <option value="">400 호</option> -->
                        </select>
                    </div>
                </li>
                <li>
                    <p class="title">호수</p>
                    <div class="descript">
                        <select @change="changeSelectedUnit($event.target.value)">
                            <option :value="unit" :key="unit" v-for="unit in computedBuildingUnitList">{{ `${unit} 호` }}</option>
                            <!-- <option value="">1 호</option>
                            <option value="">2 호</option>
                            <option value="">3 호</option>
                            <option value="">4 호</option> -->
                        </select>
                    </div>
                </li>
                <li>
                    <p class="title">차량 번호</p>
                    <p class="descript"><input type="text" id="visitant-create-car-number" placeholder="예) 12가 3456" :value="car_number" @input="car_number = $event.target.value"/></p>
                </li>
                <li>
                    <p class="title">방문 날짜</p>
                    <p class="descript">
                        <input type="button" id="visitant-datepicker" class="input_btn" :value="`${hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(start_date)}`" />
                    </p>
                </li>
                <li>
                    <p class="title">방문 기간</p>
                    <div class="descript">
                        <select @change="changeEndDate(Number($event.target.value))">
                            <option :value="n" :key="n" v-for="n in 5" :selected="date_term + 1 == n">{{ `${n}일` }}</option>
                            <!-- <option value="1">1일</option>
                            <option value="2">2일</option>
                            <option value="3">3일</option>
                            <option value="4">4일</option>
                            <option value="5">5일</option>
                            <option value="6">6일</option>
                            <option value="7">7일</option> -->
                        </select>
                    </div>
                </li>
                <!-- <li class="peepList">
                    <p class="title">요청자</p>
                    <p class="descript"><span>010-0000-0000</span><span>요청자</span></p>
                </li> -->
            </ul>
        </div>

        <div class="btns">
            <input type="button" value="닫기" @click="closeModal">
            <input type="button" value="저장" @click="registerCarInfo">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HomeVisitantCreateModal extends Mixins(VueHoduCommon) {

    /**
     * 동 리스트
     */
    get computedBuildingList() : string[] {
        const buildings : string[] = [];

        for( const resident of this.resident_list ) {
            if( resident.resident_info.building == '-' || resident.resident_info.unit == null || 
                resident.resident_info.unit.length < 1 || buildings.indexOf(resident.resident_info.building) > -1 ) { continue; }
            buildings.push(resident.resident_info.building);
        }

        buildings.sort((o1, o2) : number => {
            
            const is_o1_number = !isNaN(Number(o1));
            const is_o2_number = !isNaN(Number(o2));
            
            if( is_o1_number == is_o2_number ) {

                if( is_o1_number == true ) {
                    if( Number(o1) > Number(o2) ) {
                        return 1;
                    }

                    return -1;
                }

                if( o1 > o2 ) {
                    return 1;
                }

                if( o1 < o2 ) {
                    return -1;
                }

            }

            else {
                if( is_o1_number == true ) {
                    return 1;
                }

                return -1;
            }
            
            return 0;
        });

        return buildings;
    }

    /**
     * 호 리스트
     */
    get computedBuildingUnitList() : string[] {
        const units : string[] = [];

        for( const resident of this.resident_list ) {
            if( this.selected_building != resident.resident_info.building || units.indexOf(resident.resident_info.unit) > -1 ) { continue; }
            units.push(resident.resident_info.unit);
        }

        units.sort((o1, o2) : number => {
            
            const is_o1_number = !isNaN(Number(o1));
            const is_o2_number = !isNaN(Number(o2));
            
            if( is_o1_number == is_o2_number ) {

                if( is_o1_number == true ) {
                    if( Number(o1) > Number(o2) ) {
                        return 1;
                    }

                    return -1;
                }

                if( o1 > o2 ) {
                    return 1;
                }

                if( o1 < o2 ) {
                    return -1;
                }

            }

            else {
                if( is_o1_number == true ) {
                    return 1;
                }

                return -1;
            }
            
            return 0;
        });

        return units;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeVisitantCreateModalInfo ?: (params : hodu_home_modal_info.HomeVisitantCreateModalInfo) => void;

    resident_list : any[] = [];

    selected_building : string = "";
    selected_unit : string = "";
    selected_resident : any = null;
    car_number : string = "";

    start_date = new Date();
    end_date = new Date();
    date_term = 0;

    async mounted() : Promise<void> {
        const vue = this;

        this.start_date = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
        this.end_date   = moment(this.start_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

        await this.residentSearch();

        const datepicker_option = {
            inline: false,
            minDate: new Date(),
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
                inst.input.val(mnt.format('YYYY.MM.DD') + ' ' + vue.getDayOfWeekByDate(mnt.toDate()));

                vue.start_date = mnt.toDate();
                vue.end_date   = mnt.set('date', vue.start_date.getDate() + vue.date_term)
                                    .set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
            },
        };

        // @ts-ignore datepikcer
        $('#visitant-datepicker').datepicker(datepicker_option);

        if( this.computedBuildingList.length < 1 ) {
            alert("입주자가 없습니다\n먼저 입주자를 호두홈에 초대해주세요")
            this.closeModal();
        }
    }

    /**
     * 입주자 검색
     */
    async residentSearch() : Promise<void> {

        await this.hodu_api_call(`api/v1/home/resident/${this.scope_group_team_option.biz_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                this.resident_list.splice(0, this.resident_list.length);
                this.resident_list = this.resident_list.concat(response.data.data.resident_list);

                if( this.computedBuildingList.length > 0 ) {
                    this.selected_building = this.computedBuildingList[0];

                    if( this.computedBuildingUnitList.length > 0 ) {
                        this.selected_unit = this.computedBuildingUnitList[0];
                    }

                    const selected_list = this.resident_list.filter(item => item.resident_info.building == this.selected_building && 
                                                                            item.resident_info.unit == this.selected_unit);

                    if( selected_list.length > 0 ) {
                        this.selected_resident = selected_list[0];
                    }
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 방문차량 생성
     */
    registerCarInfo() : void {

        if( this.selected_resident == null ) { return; }

        if( this.car_number.trim().length < 1 ) {
            alert("차량 번호를 입력 해주세요");
            $('#visitant-create-car-number').focus();
            return;
        }

        const start = moment(this.start_date).utc().format();
        const end = moment(this.end_date).utc().format();

        this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}?start_date=${start}&end_date=${end}`, API_METHOD.POST, {
            "user_id" : this.selected_resident.user_id,
            "is_new" : false,  
            "is_apply" : true,   
            "car_number" : this.car_number,
            "is_resident" : false, 
        }).then((response) => {
            console.log(response);
            window['getBuildings']();
            window['getHoduHomeInfo']();
            window['getCarList']();
            this.closeModal();
        }).catch((e) => {
            this.hodu_error_process(e, false, false);
        });

    }   

    /**
     * 동 선택
     */
    changeSelectedBuilding(value : string) : void {
        this.selected_building = value;

        if( this.computedBuildingUnitList.length > 0 ) {
            this.selected_unit = this.computedBuildingUnitList[0];

            const selected_list = this.resident_list.filter(item => item.resident_info.building == this.selected_building && 
                                                                    item.resident_info.unit == this.selected_unit);

            if( selected_list.length > 0 ) {
                this.selected_resident = selected_list[0];
            }
        }
    }

    /**
     * 호 선택
     */
    changeSelectedUnit(value : string) : void {
        this.selected_unit = value;

        const selected_list = this.resident_list.filter(item => item.resident_info.building == this.selected_building && 
                                                                item.resident_info.unit == this.selected_unit);

        if( selected_list.length > 0 ) {
            this.selected_resident = selected_list[0];
        }
    }

    /**
     * 간격 설정
     */
    changeEndDate(value : number) : void {
        this.date_term = (value - 1);

        this.end_date = moment(this.start_date).set('date', this.start_date.getDate() + this.date_term)
                                               .set('hour', 23)
                                               .set('minute', 59)
                                               .set('second', 59)
                                               .set('millisecond', 999).toDate(); 
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetHomeVisitantCreateModalInfo == null ) { return; }

        this.doSetHomeVisitantCreateModalInfo({
            show_modal : false
        });
    }

}
</script>

<style scoped>
    .modal.addVistorModal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-220.5px; margin-left:-260px; width:520px; height: 441px; background:#fff; }

    .modal.addVistorModal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0 30px;border-bottom: 1px solid #e7e9ea; }
    .modal.addVistorModal .titlebox h1 { font-size: 20px;font-weight: bold;height: 90px;line-height: 110px; }
    
    .modal.addVistorModal .modal_content { overflow: hidden; height: 279px;border-bottom: 1px solid #e7e9ea;  }
    .modal.addVistorModal .modal_content ul li * { display: inline-block; font-size: 13px; }
    .modal.addVistorModal .modal_content ul li { padding:0 30px; overflow: hidden; line-height: 55px; height: 55px; border-bottom:1px solid #f1f3f5;}
    .modal.addVistorModal .modal_content ul li .descript { float: right; text-align: right; font-weight: bold }
    .modal.addVistorModal .modal_content ul li .descript * { float: right; text-align: right; font-weight: bold }
    .modal.addVistorModal .modal_content ul li .descript input { line-height: 40px; height: 40px; border:1px solid #f1f3f5; padding:0 15px; box-sizing:border-box; margin-top:7px; }
    .modal.addVistorModal .modal_content ul li .descript input:hover {border-color:#e7e9ea;}
    .modal.addVistorModal .modal_content ul li .descript input:focus {border-color:#477fff;}
    .modal.addVistorModal .modal_content ul li .descript select { min-width: 100px; position: static !important; float: left; font-size: 12px; font-weight: bold; line-height: 40px; height: 40px; background-color:#fff; padding-right: 35px; padding-left: 15px; appearance: none; -moz-appearance: none; -webkit-appearance: none; border:1px solid #f1f3f5; border-radius: 5px; margin-top:7px; cursor: pointer;background: url(../../../assets/images/side_area/bt_open.png) no-repeat 90% center; }
    .modal.addVistorModal .modal_content ul li .descript select:hover {border-color:#e7e9ea;}
    .modal.addVistorModal .modal_content ul li .descript select:focus {border-color:#477fff;background-image:  url(../../../assets/images/side_area/bt_close.png);}
    .peepList span { padding-left: 20px; }
    .modal.addVistorModal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    .modal.addVistorModal .btns input { transition:0.2s; width: 50%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold;background: #fff; }
    .modal.addVistorModal .btns input:hover { background:#f1f3f5; }

    #visitant-datepicker { width: 178px; }
</style>