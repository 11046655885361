<template>
    <div id="approval_form_setting">
<!-- 기본 결재자 설정 추가 -->
        <div class="setDefaultDiv" :class="{ on : is_default_approver_open == true }">
            <div class="content_top">
                <!-- 설정 완료시 setDefaultTitle에 on 추가 -->
                <!-- <h3 class="setDefaultTitle" :class="{ on : default_template != null }">기본 결재자 설정 <span class="">설정{{ default_template == null ? ' 안됨' : '됨' }}</span></h3> -->
                <h3 class="setDefaultTitle">
                    기본 결재자 / 수신참조 설정 
                    <span :class="{ on : computedTemplate.default_template != null && computedTemplate.default_template.files != null && computedTemplate.default_template.files.length > 0 && computedTemplate.default_template.files[0].approver != null && computedTemplate.default_template.files[0].approver.length > 0 }">결재자</span>
                    <span :class="{ on : computedTemplate.receive_template != null && computedTemplate.receive_template.files != null && computedTemplate.receive_template.files.length > 0 && computedTemplate.receive_template.files[0].approver != null && computedTemplate.receive_template.files[0].approver.length > 0 }">수신참조</span>
                </h3>
                <input type="button" class="more_temp" :class="{ change2 : is_default_approver_open == true }" @click.prevent="is_default_approver_open = !is_default_approver_open"/>
            </div>
            <div class="content_btm">
                <!-- 21.03.03 결재자/수신참조 tab 추가 -->
                <div class="content_btm_tab">
                    <h2 :class="{ active : default_tab == 'APPROVER' }"><a href="#" @click.prevent="() => { default_tab = approval_enum.APPROVAL_DEFAULT_TAB.APPROVER; }">결재자 설정</a></h2>
                    <h2 :class="{ active : default_tab == 'RECEIVER' }"><a href="#" @click.prevent="() => { default_tab = approval_enum.APPROVAL_DEFAULT_TAB.RECEIVER; }">수신참조 설정</a></h2>
                    <div class="orderCheck">
                        <p><span class="tooltipSpan">?</span>순차열람</p>
                        <label class="switch">
                            <input type="checkbox" :checked="isInOrderApproval() == true" @change="changeIsInOrderApproval($event)"><span class="slider round"></span>
                        </label>
                        <div class="orderToolBox">
                            <p>결재해야 할 차례가 되면 문서 열람이 가능합니다.</p>
                        </div>
                    </div>
                </div>
                <h4>가장 많이 요청되는 {{ default_tab == 'APPROVER' ? '결재자' : '수신참조' }}를 세팅하세요.</h4>
                <!--<div class="autoSelect">
                    <p class="descript">자동 상위 직급자 볼러오기</p>
                    <label class="switch">
                        <input type="checkbox">
                        <span class="slider round"></span>
                    </label>
                </div>-->
                <div class="setDefaultUl_wrap">
                    <ul class="setDefaultUl" v-if="default_tab == 'APPROVER'">
                        <!-- <li class="posLi">
                            <a href="#" @click.prevent="showApproverSelectModal">
                                <p class="turnNum">1</p>
                                <p class="img dc5">5</p>
                                <p class="name">팀장</p>
                                <p class="include">상위부서 미포함</p>
                            </a>
                        </li> -->
                        <!-- <li class="memLi">
                            <a href="#" @click.prevent="showApproverSelectModal">
                                <p class="img"></p>
                                <p class="name">김성림</p>
                                <p class="pos">부장</p>
                                <p class="team">호두웨어</p>
                            </a>
                        </li> -->
                        <li :class="{ posLi : approver.approver_type == 'POSITION', memLi : approver.approver_type == 'EMPLOYEE' }" :key="`${approver.seq}-${approver.user_id}`" 
                            v-for="(approver, index) in ((computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length < 1 || computedTemplate.default_template.files[0].approver == null) ? null : computedTemplate.default_template.files[0].approver)">
                            <a href="#" @click.prevent="showApproverSelectModal('20', computedTemplate.default_template.files[0])">
                                <!-- 결재자 순번 표시 추가 -->
                                <p class="turnNum">{{ index + 1 }}</p>
                                <p class="img" :style="{ backgroundImage : `url(${getProfileImage(approver)})` }" v-if="approver.approver_type == 'EMPLOYEE'"></p>
                                <p class="img" :class="`dc${(getPositionSeq(approver.pos_id) - 1) % 10}`" v-else>{{ getPositionSeq(approver.pos_id) }}</p>
                                <img class="dummy" :src="getProfileImage(approver)" v-if="approver.approver_type == 'EMPLOYEE'" @error="userImageError($event)"/>
                                <p class="name">{{ approver.approver_type == 'EMPLOYEE' ? approver.user_name : approver.pos_name }}</p>
                                <p class="include" v-if="approver.approver_type == 'POSITION'">상위부서 {{ approver.include_parent == true ? '' : '미' }}포함</p>
                                <p class="pos" v-if="approver.approver_type == 'EMPLOYEE'">{{ approver.pos_name }}</p>
                                <!-- <p class="team" v-if="approver.approver_type == 'EMPLOYEE'">{{ approver.dept_id == 0 ? '미배정' : approver.dept_name }}</p> -->
                                <p class="team" v-if="approver.approver_type == 'EMPLOYEE'">{{ approver.dept_name }}</p>
                            </a>
                        </li>
                        <li class="addList">
                            <a href="#" @click.prevent="showApproverSelectModal('20', ((computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length < 1 || computedTemplate.default_template.files[0].approver == null) ? null : computedTemplate.default_template.files[0]))">
                                <p class="img">+</p>
                                <p class="name">결재자 선택</p>
                            </a>
                        </li>
                    </ul>

                    <!-- 수신참조 설정 -->
                    <ul class="setDefaultUl" v-if="default_tab == 'RECEIVER'">
                        <!-- <li class="posLi">
                            <a href="#" @click.prevent="showApproverSelectModal">
                                <p class="img dc5">5</p>
                                <p class="name">팀장</p>
                                <p class="include">상위부서 미포함</p>
                            </a>
                        </li>
                        <li class="memLi">
                            <a href="#" @click.prevent="showApproverSelectModal">
                                <p class="img"></p>
                                <p class="name">김성림</p>
                                <p class="pos">부장</p>
                                <p class="team">호두웨어</p>
                            </a>
                        </li> -->
                        <li :class="{ posLi : approver.approver_type == 'POSITION', memLi : approver.approver_type == 'EMPLOYEE' }" :key="`${approver.seq}-${approver.user_id}`" v-for="approver in ((computedTemplate.receive_template == null || computedTemplate.receive_template.files == null || computedTemplate.receive_template.files.length < 1 || computedTemplate.receive_template.files[0].approver == null) ? null : computedTemplate.receive_template.files[0].approver)">
                            <a href="#" @click.prevent="showApproverSelectModal('50', computedTemplate.receive_template.files[0])">
                                <p class="img" :style="{ backgroundImage : `url(${getProfileImage(approver)})` }" v-if="approver.approver_type == 'EMPLOYEE'"></p>
                                <p class="img" :class="`dc${(getPositionSeq(approver.pos_id) - 1) % 10}`" v-else>{{ getPositionSeq(approver.pos_id) }}</p>
                                <img class="dummy" :src="getProfileImage(approver)" v-if="approver.approver_type == 'EMPLOYEE'" @error="userImageError($event)"/>
                                <p class="name">{{ approver.approver_type == 'EMPLOYEE' ? approver.user_name : approver.pos_name }}</p>
                                <p class="include" v-if="approver.approver_type == 'POSITION'">상위부서 {{ approver.include_parent == true ? '' : '미' }}포함</p>
                                <p class="pos" v-if="approver.approver_type == 'EMPLOYEE'">{{ approver.pos_name }}</p>
                                <!-- <p class="team" v-if="approver.approver_type == 'EMPLOYEE'">{{ approver.dept_id == 0 ? '미배정' : approver.dept_name }}</p> -->
                                <p class="team" v-if="approver.approver_type == 'EMPLOYEE'">{{ approver.dept_name }}</p>
                            </a>
                        </li>
                        <li class="addList">
                            <a href="#" @click.prevent="showApproverSelectModal('50', ((computedTemplate.receive_template == null || computedTemplate.receive_template.files == null || computedTemplate.receive_template.files.length < 1 || computedTemplate.receive_template.files[0].approver == null) ? null : computedTemplate.receive_template.files[0]))">
                                <p class="img">+</p>
                                <p class="name">수신참조 선택</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="filter_tap">
            <div class="content_top">
                <h3 style="margin-left: 25px;">분류 목록</h3>
                <!-- <input type="text" id="filter_search" placeholder="파일명으로 검색하세요" style="left:125px"/> -->
                <!-- <a href="#" class="add_template" @click.prevent="showApprovalFileModal(0)">+ 분류 없이 양식 추가</a> -->
                <a href="#" class="cont_del2" :class="{ approval_disabled : isAttendanceOnly() == true }" @click.prevent="deleteClass()">분류 삭제</a>
                <a class="down" :class="{ approval_disabled : isAttendanceOnly() == true }" @click.prevent="modalAddForm()">＋ 분류 추가</a>
            </div>
        </div>

        <div id="form_list_scroll" :style="{ height : `${scroll_height}px` }">
<!-- 시스템 양식 -->
            <div class="Template_setting systemForm">
                <div class="div_title">
                    <h3 class="approval_division_b_title_name">시스템 양식</h3>
                    <span class="more_temp" :class="{ change2 : is_vacation_open == true }" @click.prevent="is_vacation_open = !is_vacation_open"></span>
<!-- 추가 버튼 위치 변경 -->
                   
                </div>
                <ul class="text_more" :class="{ open : is_vacation_open == true }">
                    <li>
                        <div class="div_first">
                            <span class="in_file" style="">＋</span><p class="list_name">휴가원</p>
                            <span style="position: absolute;bottom: -2px;line-height:25px; left: 60px;color: #bbb;font-weight: normal;"></span>
                        </div>
<!-- 시스템 양식 옵션 -->
                        <div class="stting-option-box" v-on-clickaway="vacationColorOutsideClick">
                            <!-- 색상 커스텀 --> 
                            <div class="div_second clrsecondDiv">
                                <a href="#" title="색상선택" class="clr_select_btn" @click.prevent="showSelectVacationColor">
                                    색상 선택
                                    <!--
                                        각 클릭 색상에 맞추어 클래스 추가
                                        skyblue / pink / lightGrn / lightOran / lightPurple
                                    -->
                                    <span class="clrPick" :style="{ backgroundColor : isColorNone(computedTemplate.vacation_template) == true ? '#E4F5FD' : computedTemplate.vacation_template.files[0].contents.color }">색상</span>
                                </a>
                            </div>
                            <!-- 색상선택시 checkOpen 클래스 추가-->
                            <div class="clrselBox" :class="{ checkOpen : vacation_color_open == true }">
                                <!-- 체크시 checked 클래스 추가-->
                                <li>
                                    <a href="#" class="clr-select skyblue" :class="{ checked : isColorNone(computedTemplate.vacation_template) == true || isColorChecked(computedTemplate.vacation_template, '#E4F5FD') }" @click.prevent="setVacationTemplateColor('#E4F5FD', '#4DBAFF')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select pink" :class="{ checked : isColorChecked(computedTemplate.vacation_template, '#FCE5EF') }" @click.prevent="setVacationTemplateColor('#FCE5EF', '#FC91C7')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select lightGrn" :class="{ checked : isColorChecked(computedTemplate.vacation_template, '#D5FFEF') }" @click.prevent="setVacationTemplateColor('#D5FFEF', '#43E2A6')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select lightOran" :class="{ checked : isColorChecked(computedTemplate.vacation_template, '#FDEBCD') }" @click.prevent="setVacationTemplateColor('#FDEBCD', '#F3B448')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select lightPurple" :class="{ checked : isColorChecked(computedTemplate.vacation_template, '#E2EAFF') }" @click.prevent="setVacationTemplateColor('#E2EAFF', '#7FA2F6')"></a>
                                </li>
                            </div>

                            <!-- 자가 결재자 -->
                            <div class="div_second selfsecondDiv">
                                <span class="verti"></span>

                                <a href="#" title="자가 결재자 추가" class="form_list_btn" :class="{ selfApp : (computedTemplate.vacation_template != null && computedTemplate.vacation_template.files != null && computedTemplate.vacation_template.files[0].self_approver != null && computedTemplate.vacation_template.files[0].self_approver.length > 0) }"
                                @click.prevent="showSelfApproverSelectModal('30', ((computedTemplate.vacation_template == null || computedTemplate.vacation_template.files == null || computedTemplate.vacation_template.files.length < 1 || computedTemplate.vacation_template.files[0].self_approver == null) ? null : computedTemplate.vacation_template.files[0]))">
                                    자가 결재자 {{ (computedTemplate.vacation_template != null && computedTemplate.vacation_template.files != null && computedTemplate.vacation_template.files[0].self_approver != null && computedTemplate.vacation_template.files[0].self_approver.length > 0) ? '' : '없음' }}
                                    <span class="num">
                                        {{ (computedTemplate.vacation_template != null && computedTemplate.vacation_template.files != null && computedTemplate.vacation_template.files[0].self_approver != null && computedTemplate.vacation_template.files[0].self_approver.length > 0) ? computedTemplate.vacation_template.files[0].self_approver.length : '0' }}
                                    </span>
                                </a>
                            </div>

                            <!-- 기본 결재자 -->
                            <div class="div_second">
                                <span class="verti"></span>

                                <a class="form_list_btn" :class="{ exist : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.vacation_template != null && computedTemplate.vacation_template.files != null && computedTemplate.vacation_template.files[0].approver != null && computedTemplate.vacation_template.files[0].approver.length > 0) }" 
                                href="#" title="기본 결재자 추가" @click.prevent="showApproverSelectModal('30', ((computedTemplate.vacation_template == null || computedTemplate.vacation_template.files == null || computedTemplate.vacation_template.files.length < 1 || computedTemplate.vacation_template.files[0].approver == null) ? null : computedTemplate.vacation_template.files[0]))">
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) && !(computedTemplate.vacation_template != null && computedTemplate.vacation_template.files != null && computedTemplate.vacation_template.files[0].approver != null && computedTemplate.vacation_template.files[0].approver.length > 0) ? '기본' : '' }}
                                    결재자
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.vacation_template != null && computedTemplate.vacation_template.files != null && computedTemplate.vacation_template.files[0].approver != null && computedTemplate.vacation_template.files[0].approver.length > 0) ? '' : '없음 '}}
                                    <span class="num">
                                        {{ `${computedTemplate.vacation_template != null && computedTemplate.vacation_template.files != null && computedTemplate.vacation_template.files.length != 0 && computedTemplate.vacation_template.files[0].approver != null && computedTemplate.vacation_template.files[0].approver.length != 0
                                        ? computedTemplate.vacation_template.files[0].approver.length 
                                        : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) ? computedTemplate.default_template.files[0].approver.length : 0 }` }}
                                    </span>
                                </a>

                            </div>
                        </div>
                    </li>

                    <!-- 출장 신청서 -->
                    <li :class="{ approval_disabled : isAttendanceOnly() == true }">
                        <div class="div_first">
                            <span class="in_file" style="">＋</span><p class="list_name">출장신청서</p>
                            <span style="position: absolute;bottom: -2px;line-height:25px; left: 60px;color: #bbb;font-weight: normal;"></span>
                        </div>
<!-- 시스템 양식 옵션 -->
                        <div class="stting-option-box" v-on-clickaway="businessTripColorOutsideClick">

                            <!-- 색상 커스텀 --> 
                            <div class="div_second clrsecondDiv">
                                <a href="#" title="색상선택" class="clr_select_btn" @click.prevent="showSelectBusinessTripColor">
                                    색상 선택
                                    <span class="clrPick" :style="{ backgroundColor : isColorNone(computedTemplate.business_trip_template) == true ? '#E4F5FD' : computedTemplate.business_trip_template.files[0].contents.color }">색상</span>
                                </a>
                            </div>   

                            <div class="clrselBox" :class="{ checkOpen : business_trip_color_open == true }">
                                <!-- 체크시 checked 클래스 추가-->
                                <li>
                                    <a href="#" class="clr-select skyblue" :class="{ checked : isColorNone(computedTemplate.business_trip_template) == true || isColorChecked(computedTemplate.business_trip_template, '#E4F5FD') }" @click.prevent="setBusinessTripTemplateColor('#E4F5FD', '#4DBAFF')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select pink" :class="{ checked : isColorChecked(computedTemplate.business_trip_template, '#FCE5EF') }" @click.prevent="setBusinessTripTemplateColor('#FCE5EF', '#FC91C7')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select lightGrn" :class="{ checked : isColorChecked(computedTemplate.business_trip_template, '#D5FFEF') }" @click.prevent="setBusinessTripTemplateColor('#D5FFEF', '#43E2A6')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select lightOran" :class="{ checked : isColorChecked(computedTemplate.business_trip_template, '#FDEBCD') }" @click.prevent="setBusinessTripTemplateColor('#FDEBCD', '#F3B448')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select lightPurple" :class="{ checked : isColorChecked(computedTemplate.business_trip_template, '#E2EAFF') }" @click.prevent="setBusinessTripTemplateColor('#D2DFFF', '#7FA2F6')"></a>
                                </li>
                            </div>                     

                            <!-- 자가 결재자가 -->
                            <div class="div_second selfsecondDiv">
                                <span class="verti"></span>

                                <a href="#" title="자가 결재자 추가" class="form_list_btn" :class="{ selfApp : (computedTemplate.business_trip_template != null && computedTemplate.business_trip_template.files != null && computedTemplate.business_trip_template.files[0].self_approver != null && computedTemplate.business_trip_template.files[0].self_approver.length > 0) }" 
                                @click.prevent="showSelfApproverSelectModal('40', ((computedTemplate.business_trip_template == null || computedTemplate.business_trip_template.files == null || computedTemplate.business_trip_template.files.length < 1 || computedTemplate.business_trip_template.files[0].self_approver == null) ? null : computedTemplate.business_trip_template.files[0]))">
                                    자가 결재자 {{ (computedTemplate.business_trip_template != null && computedTemplate.business_trip_template.files != null && computedTemplate.business_trip_template.files[0].self_approver != null && computedTemplate.business_trip_template.files[0].self_approver.length > 0) ? '' : '없음' }}
                                    <span class="num">{{ (computedTemplate.business_trip_template != null && computedTemplate.business_trip_template.files != null && computedTemplate.business_trip_template.files[0].self_approver != null && computedTemplate.business_trip_template.files[0].self_approver.length > 0) ? computedTemplate.business_trip_template.files[0].self_approver.length : '0' }}</span>
                                </a>
                            </div>
                            
                            <!-- 결재자 -->
                            <div class="div_second">
                                <span class="verti"></span>
                                
                                <a class="form_list_btn" :class="{ exist : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.business_trip_template != null && computedTemplate.business_trip_template.files != null && computedTemplate.business_trip_template.files[0].approver != null && computedTemplate.business_trip_template.files[0].approver.length > 0) }"
                                href="#" title="기본 결재자 추가" @click.prevent="showApproverSelectModal('40', ((computedTemplate.business_trip_template == null || computedTemplate.business_trip_template.files == null || computedTemplate.business_trip_template.files.length < 1 || computedTemplate.business_trip_template.files[0].approver == null) ? null : computedTemplate.business_trip_template.files[0]))">
                                    
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) && !(computedTemplate.business_trip_template != null && computedTemplate.business_trip_template.files != null && computedTemplate.business_trip_template.files[0].approver != null && computedTemplate.business_trip_template.files[0].approver.length > 0) ? '기본' : '' }}
                                    결재자
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.business_trip_template != null && computedTemplate.business_trip_template.files != null && computedTemplate.business_trip_template.files[0].approver != null && computedTemplate.business_trip_template.files[0].approver.length > 0) ? '' : '없음 '}}
                                    <span class="num">
                                        {{ `${computedTemplate.business_trip_template != null && computedTemplate.business_trip_template.files != null && computedTemplate.business_trip_template.files.length != 0 && computedTemplate.business_trip_template.files[0].approver != null && computedTemplate.business_trip_template.files[0].approver.length != 0
                                        ? computedTemplate.business_trip_template.files[0].approver.length 
                                        : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) ? computedTemplate.default_template.files[0].approver.length : 0 }` }}
                                    </span>

                                </a>
                                
                            </div>
                        </div>
                    </li>

                    <!-- 휴일근무 -->
                    <li :class="{ approval_disabled : isAttendanceOnly() == true }">
                        <div class="div_first">
                            <span class="in_file" style="">＋</span><p class="list_name">휴일근무 신청서</p>
                            <span style="position: absolute;bottom: -2px;line-height:25px; left: 60px;color: #bbb;font-weight: normal;"></span>
                        </div>
<!-- 시스템 양식 옵션 -->
                        <div class="stting-option-box">

                            <!-- 사용여부 -->
                            <div class="div_second clrsecondDiv">
                                <p class="useWhetherText">사용여부</p>
                                <label class="switch" v-if="computedTemplate.holiday_work_template != null && computedTemplate.holiday_work_template.files != null && computedTemplate.holiday_work_template.files[0] != null && computedTemplate.holiday_work_template.files[0].contents != null">
                                    <input type="checkbox" v-model="computedTemplate.holiday_work_template.files[0].contents.visible" @change="changeHolidayWorkVisible"><span class="slider round"></span>
                                </label>
                                <label class="switch" v-else>
                                    <input type="checkbox" @change="changeHolidayWorkVisible"><span class="slider round"></span>
                                </label>
                            </div> 

                            <!-- 자가 결재자가 -->
                            <div class="div_second selfsecondDiv">
                                <span class="verti"></span>

                                <a href="#" title="자가 결재자 추가" class="form_list_btn" :class="{ selfApp : (computedTemplate.holiday_work_template != null && computedTemplate.holiday_work_template.files != null && computedTemplate.holiday_work_template.files[0].self_approver != null && computedTemplate.holiday_work_template.files[0].self_approver.length > 0) }" 
                                @click.prevent="showSelfApproverSelectModal('80', ((computedTemplate.holiday_work_template == null || computedTemplate.holiday_work_template.files == null || computedTemplate.holiday_work_template.files.length < 1 || computedTemplate.holiday_work_template.files[0].self_approver == null) ? null : computedTemplate.holiday_work_template.files[0]))">
                                    자가 결재자 {{ (computedTemplate.holiday_work_template != null && computedTemplate.holiday_work_template.files != null && computedTemplate.holiday_work_template.files[0].self_approver != null && computedTemplate.holiday_work_template.files[0].self_approver.length > 0) ? '' : '없음' }}
                                    <span class="num">{{ (computedTemplate.holiday_work_template != null && computedTemplate.holiday_work_template.files != null && computedTemplate.holiday_work_template.files[0].self_approver != null && computedTemplate.holiday_work_template.files[0].self_approver.length > 0) ? computedTemplate.holiday_work_template.files[0].self_approver.length : '0' }}</span>
                                </a>
                            </div>
                            
                            <!-- 결재자 -->
                            <div class="div_second">
                                <span class="verti"></span>
                                
                                <a class="form_list_btn" :class="{ exist : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.holiday_work_template != null && computedTemplate.holiday_work_template.files != null && computedTemplate.holiday_work_template.files[0].approver != null && computedTemplate.holiday_work_template.files[0].approver.length > 0) }"
                                href="#" title="기본 결재자 추가" @click.prevent="showApproverSelectModal('80', ((computedTemplate.holiday_work_template == null || computedTemplate.holiday_work_template.files == null || computedTemplate.holiday_work_template.files.length < 1 || computedTemplate.holiday_work_template.files[0].approver == null) ? null : computedTemplate.holiday_work_template.files[0]))">
                                    
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) && !(computedTemplate.holiday_work_template != null && computedTemplate.holiday_work_template.files != null && computedTemplate.holiday_work_template.files[0].approver != null && computedTemplate.holiday_work_template.files[0].approver.length > 0) ? '기본' : '' }}
                                    결재자
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.holiday_work_template != null && computedTemplate.holiday_work_template.files != null && computedTemplate.holiday_work_template.files[0].approver != null && computedTemplate.holiday_work_template.files[0].approver.length > 0) ? '' : '없음 '}}
                                    <span class="num">
                                        {{ `${computedTemplate.holiday_work_template != null && computedTemplate.holiday_work_template.files != null && computedTemplate.holiday_work_template.files.length != 0 && computedTemplate.holiday_work_template.files[0].approver != null && computedTemplate.holiday_work_template.files[0].approver.length != 0
                                        ? computedTemplate.holiday_work_template.files[0].approver.length 
                                        : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) ? computedTemplate.default_template.files[0].approver.length : 0 }` }}
                                    </span>

                                </a>
                                
                            </div>
                        </div>
                    </li>

                    <!-- 지출결의서 -->
                    <li :class="{ approval_disabled : isAttendanceOnly() == true }">
                        <div class="div_first">
                            <span class="in_file" style="">＋</span><p class="list_name">지출결의서</p>
                            <span style="position: absolute;bottom: -2px;line-height:25px; left: 60px;color: #bbb;font-weight: normal;"></span>
                        </div>
<!-- 시스템 양식 옵션 -->
                        <div class="stting-option-box">

                            <!-- 색상 커스텀 --> 
                            <!-- <div class="div_second clrsecondDiv">
                                <a href="#" title="색상선택" class="clr_select_btn" @click.prevent="showSelectBusinessTripColor">
                                    색상 선택
                                    <span class="clrPick" :style="{ backgroundColor : isColorNone(business_trip_template) == true ? '#E4F5FD' : business_trip_template.files[0].contents.color }">색상</span>
                                </a>
                            </div> -->

                            <!-- <div class="clrselBox" :class="{ checkOpen : business_trip_color_open == true }">
                                <li>
                                    <a href="#" class="clr-select skyblue" :class="{ checked : isColorNone(business_trip_template) == true || isColorChecked(business_trip_template, '#E4F5FD') }" @click.prevent="setBusinessTripTemplateColor('#E4F5FD', '#4DBAFF')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select pink" :class="{ checked : isColorChecked(business_trip_template, '#FCE5EF') }" @click.prevent="setBusinessTripTemplateColor('#FCE5EF', '#FC91C7')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select lightGrn" :class="{ checked : isColorChecked(business_trip_template, '#D5FFEF') }" @click.prevent="setBusinessTripTemplateColor('#D5FFEF', '#43E2A6')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select lightOran" :class="{ checked : isColorChecked(business_trip_template, '#FDEBCD') }" @click.prevent="setBusinessTripTemplateColor('#FDEBCD', '#F3B448')"></a>
                                </li>
                                <li>
                                    <a href="#" class="clr-select lightPurple" :class="{ checked : isColorChecked(business_trip_template, '#E2EAFF') }" @click.prevent="setBusinessTripTemplateColor('#E2EAFF', '#7FA2F6')"></a>
                                </li>
                            </div> -->

                            <!-- 자가 결재자가 -->
                            <div class="div_second selfsecondDiv">
                                <!-- <span class="verti"></span> -->

                                <a href="#" title="자가 결재자 추가" class="form_list_btn" :class="{ selfApp : (computedTemplate.cash_disbursement_voucher_template != null && computedTemplate.cash_disbursement_voucher_template.files != null && computedTemplate.cash_disbursement_voucher_template.files[0].self_approver != null && computedTemplate.cash_disbursement_voucher_template.files[0].self_approver.length > 0) }" 
                                @click.prevent="showSelfApproverSelectModal('60', ((computedTemplate.cash_disbursement_voucher_template == null || computedTemplate.cash_disbursement_voucher_template.files == null || computedTemplate.cash_disbursement_voucher_template.files.length < 1 || computedTemplate.cash_disbursement_voucher_template.files[0].self_approver == null) ? null : computedTemplate.cash_disbursement_voucher_template.files[0]))">
                                    자가 결재자 {{ (computedTemplate.cash_disbursement_voucher_template != null && computedTemplate.cash_disbursement_voucher_template.files != null && computedTemplate.cash_disbursement_voucher_template.files[0].self_approver != null && computedTemplate.cash_disbursement_voucher_template.files[0].self_approver.length > 0) ? '' : '없음' }}
                                    <span class="num">{{ (computedTemplate.cash_disbursement_voucher_template != null && computedTemplate.cash_disbursement_voucher_template.files != null && computedTemplate.cash_disbursement_voucher_template.files[0].self_approver != null && computedTemplate.cash_disbursement_voucher_template.files[0].self_approver.length > 0) ? computedTemplate.cash_disbursement_voucher_template.files[0].self_approver.length : '0' }}</span>
                                </a>
                            </div>
                            
                            <!-- 결재자 -->
                            <div class="div_second">
                                <span class="verti"></span>
                                
                                <a class="form_list_btn" :class="{ exist : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.cash_disbursement_voucher_template != null && computedTemplate.cash_disbursement_voucher_template.files != null && computedTemplate.cash_disbursement_voucher_template.files[0].approver != null && computedTemplate.cash_disbursement_voucher_template.files[0].approver.length > 0) }"
                                href="#" title="기본 결재자 추가" @click.prevent="showApproverSelectModal('60', ((computedTemplate.cash_disbursement_voucher_template == null || computedTemplate.cash_disbursement_voucher_template.files == null || computedTemplate.cash_disbursement_voucher_template.files.length < 1 || computedTemplate.cash_disbursement_voucher_template.files[0].approver == null) ? null : computedTemplate.cash_disbursement_voucher_template.files[0]))">
                                    
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) && !(computedTemplate.cash_disbursement_voucher_template != null && computedTemplate.cash_disbursement_voucher_template.files != null && computedTemplate.cash_disbursement_voucher_template.files[0].approver != null && computedTemplate.cash_disbursement_voucher_template.files[0].approver.length > 0) ? '기본' : '' }}
                                    결재자
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.cash_disbursement_voucher_template != null && computedTemplate.cash_disbursement_voucher_template.files != null && computedTemplate.cash_disbursement_voucher_template.files[0].approver != null && computedTemplate.cash_disbursement_voucher_template.files[0].approver.length > 0) ? '' : '없음 '}}
                                    <span class="num">
                                        {{ `${computedTemplate.cash_disbursement_voucher_template != null && computedTemplate.cash_disbursement_voucher_template.files != null && computedTemplate.cash_disbursement_voucher_template.files.length != 0 && computedTemplate.cash_disbursement_voucher_template.files[0].approver != null && computedTemplate.cash_disbursement_voucher_template.files[0].approver.length != 0
                                        ? computedTemplate.cash_disbursement_voucher_template.files[0].approver.length 
                                        : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) ? computedTemplate.default_template.files[0].approver.length : 0 }` }}
                                    </span>

                                </a>
                                
                            </div>
                        </div>
                    </li>

                    <!-- 파일양식 없음 -->
                    <li :class="{ approval_disabled : isAttendanceOnly() == true }">
                        <div class="div_first">
                            <span class="in_file" style="">＋</span><p class="list_name">기안서</p>
                            <span style="position: absolute;bottom: -2px;line-height:25px; left: 60px;color: #bbb;font-weight: normal;"></span>
                        </div>
<!-- 시스템 양식 옵션 -->
                        <div class="stting-option-box">

                            <!-- 자가 결재자가 -->
                            <div class="div_second selfsecondDiv">
                                <!-- <span class="verti"></span> -->

                                <a href="#" title="자가 결재자 추가" class="form_list_btn" :class="{ selfApp : (computedTemplate.free_template != null && computedTemplate.free_template.files != null && computedTemplate.free_template.files[0].self_approver != null && computedTemplate.free_template.files[0].self_approver.length > 0) }" 
                                @click.prevent="showSelfApproverSelectModal('70', ((computedTemplate.free_template == null || computedTemplate.free_template.files == null || computedTemplate.free_template.files.length < 1 || computedTemplate.free_template.files[0].self_approver == null) ? null : computedTemplate.free_template.files[0]))">
                                    자가 결재자 {{ (computedTemplate.free_template != null && computedTemplate.free_template.files != null && computedTemplate.free_template.files[0].self_approver != null && computedTemplate.free_template.files[0].self_approver.length > 0) ? '' : '없음' }}
                                    <span class="num">{{ (computedTemplate.free_template != null && computedTemplate.free_template.files != null && computedTemplate.free_template.files[0].self_approver != null && computedTemplate.free_template.files[0].self_approver.length > 0) ? computedTemplate.free_template.files[0].self_approver.length : '0' }}</span>
                                </a>
                            </div>
                            
                            <!-- 결재자 -->
                            <div class="div_second">
                                <span class="verti"></span>
                                
                                <a class="form_list_btn" :class="{ exist : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.free_template != null && computedTemplate.free_template.files != null && computedTemplate.free_template.files[0].approver != null && computedTemplate.free_template.files[0].approver.length > 0) }"
                                href="#" title="기본 결재자 추가" @click.prevent="showApproverSelectModal('70', ((computedTemplate.free_template == null || computedTemplate.free_template.files == null || computedTemplate.free_template.files.length < 1 || computedTemplate.free_template.files[0].approver == null) ? null : computedTemplate.free_template.files[0]))">
                                    
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) && !(computedTemplate.free_template != null && computedTemplate.free_template.files != null && computedTemplate.free_template.files[0].approver != null && computedTemplate.free_template.files[0].approver.length > 0) ? '기본' : '' }}
                                    결재자
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || (computedTemplate.free_template != null && computedTemplate.free_template.files != null && computedTemplate.free_template.files[0].approver != null && computedTemplate.free_template.files[0].approver.length > 0) ? '' : '없음 '}}
                                    <span class="num">
                                        {{ `${computedTemplate.free_template != null && computedTemplate.free_template.files != null && computedTemplate.free_template.files.length != 0 && computedTemplate.free_template.files[0].approver != null && computedTemplate.free_template.files[0].approver.length != 0
                                        ? computedTemplate.free_template.files[0].approver.length 
                                        : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) ? computedTemplate.default_template.files[0].approver.length : 0 }` }}
                                    </span>

                                </a>
                                
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div :key="template.template_class_id" v-for="(template,index) in computedTemplate.templates" class="Template_setting" :class="{ approval_disabled : isAttendanceOnly() == true }">
<!-- css용 div추가 -->
                <div class="div_title">
                    <input type="checkbox" :id="`TempsetCheck${index}`" v-model="template.is_selected" class="input_check" :disabled="isAttendanceOnly() == true">
<!-- 분류 양식 파일 개수 span 위치 변경 -->
                    <label :for="`TempsetCheck${index}`" style="margin-top:6px;"></label>
                    <h3 class="approval_division_s_title_name" @click="toggleClass(template, index)">{{template.template_class_name}} (<span class="num_of_forms">{{template.files.length}}</span>)</h3>
                    <!-- <p style="position: absolute; right:50px;top:15px;" @click="deleteAllFilesFromClass(template)"></p> -->
                    <span class="more_temp" :class="{ change2 : template.is_fold == true }" @click="toggleClass(template, index)"></span>
<!-- 추가 버튼 위치 변경 -->
                    <input type="button" class="plus_file" @click.prevent="showApprovalFileModal(template.template_class_id)" value="양식 추가" />
                </div>
                   
                <ul class="text_more" :class="{ open : template.is_fold == true }">
                    <!-- <li>
                        <span class="plus_file" style="" @click.prevent="showApprovalFileModal(template.template_class_id)">＋</span><a class="list_name plus_btn">추가</a>
                    </li> -->
                    <li :key="file.template_id" v-for="file in template.files">
<!-- css용 div 추가  -->
                        <div class="div_first">
                            <a href="#" @click.prevent="modify(file)">
                                <span class="in_file" style="">＋</span><p class="list_name" @click.prevent="showApprovalFormDetailModal">{{file.template_name}}</p>
                                <span class="kb_name">{{ 
                                    file.contents.file_size >= 1 * 1024 * 1024 ? `${ (file.contents.file_size / 1024 / 1024).toFixed(2) } MB` :
                                    file.contents.file_size >= 1 * 1024        ? `${ (file.contents.file_size / 1024       ).toFixed(2) } KB` : `${ file.contents.file_size } Byte`         
                                }}</span>
                            </a>
                        </div>

                        <!-- 자가 결재자가 -->
                        <div class="div_second selfsecondDiv">
                            <a href="#" title="자가 결재자 추가" class="form_list_btn" :class="{ selfApp : ( file.self_approver != null && file.self_approver.length > 0 ) }"
                               @click.prevent="showSelfApproverSelectModal('10', file)">
                                자가 결재자 {{ ( file.self_approver != null && file.self_approver.length > 0 ) ? '' : '없음' }}
                                <span class="num">{{ ( file.self_approver != null && file.self_approver.length > 0 ) ? file.self_approver.length : '0' }}</span>
                            </a>
                         </div>

                        <div class="div_second">
                            <span class="verti"></span>
                            
                            <!-- exist : 기본 결재자가 있음 || 파일에 결재자가 있음  -->
                            <a class="form_list_btn" :class="{ exist : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || ( file.approver != null && file.approver.length > 0 ) }" href="#" title="기본 결재자 추가" @click.prevent="showApproverSelectModal('10', file)">
                                {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) && ( file.approver == null || file.approver.length == 0 ) ? '기본' : '' }}
                                결재자
                                {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || ( file.approver != null && file.approver.length > 0 ) ? '' : '없음 '}}
                                <span class="num">
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) && ( file.approver == null || file.approver.length == 0 ) ? computedTemplate.default_template.files[0].approver.length : (file.approver ? file.approver.length : 0) }}
                                </span>
                            </a>

                        </div>
                        <div class="div_third">
                            <span class="verti"></span>
                            <input type="button" class="close_end" @click="deleteFile(file)" value="삭제" />    
                        </div>
                    </li>
                </ul>
            </div>

            <!-- 분류 없음 -->
            <div class="Template_setting" :class="{ approval_disabled : isAttendanceOnly() == true }">
                
                <div class="div_title">
                    <h3 class="approval_division_b_title_name">분류 없음</h3>
                    <span class="more_temp" :class="{ change2 : is_none_class_open == true }" @click.prevent="is_none_class_open = !is_none_class_open"></span>
                    <input type="button" class="plus_file" @click.prevent="showApprovalFileModal(0)" value="양식 추가" />
                </div>
                
                <ul class="text_more" :class="{ open : is_none_class_open == true }" v-if="computedTemplate.none_class_template != null && computedTemplate.none_class_template.files != null && computedTemplate.none_class_template.files.length > 0">
                    <li :key="file.template_id" v-for="file in computedTemplate.none_class_template.files">
                        <div class="div_first">
                            <a href="#" @click.prevent="modify(file)">
                                <span class="in_file" style="">＋</span><p class="list_name" @click.prevent="showApprovalFormDetailModal">{{file.template_name}}</p>
                                <span class="kb_name">{{ 
                                    file.contents.file_size >= 1 * 1024 * 1024 ? `${ (file.contents.file_size / 1024 / 1024).toFixed(2) } MB` :
                                    file.contents.file_size >= 1 * 1024        ? `${ (file.contents.file_size / 1024       ).toFixed(2) } KB` : `${ file.contents.file_size } Byte`         
                                }}</span>
                            </a>
                        </div>

                        <!-- 자가 결재자가 -->
                        <div class="div_second selfsecondDiv">

                            <a href="#" title="자가 결재자 추가" class="form_list_btn" :class="{ selfApp : ( file.self_approver != null && file.self_approver.length > 0 ) }"
                               @click.prevent="showSelfApproverSelectModal('10', file)">
                                자가 결재자 {{ ( file.self_approver != null && file.self_approver.length > 0 ) ? '' : '없음' }}
                                <span class="num">{{ ( file.self_approver != null && file.self_approver.length > 0 ) ? file.self_approver.length : '0' }}</span>
                            </a>
                        </div>

                        <div class="div_second">
                            <span class="verti"></span>
                            
                            <!-- exist : 기본 결재자가 있음 || 파일에 결재자가 있음  -->
                            <a class="form_list_btn" :class="{ exist : !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || ( file.approver != null && file.approver.length > 0 ) }" href="#" title="기본 결재자 추가" @click.prevent="showApproverSelectModal('10', file)">
                                {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) && ( file.approver == null || file.approver.length == 0 ) ? '기본' : '' }}
                                결재자
                                {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) || ( file.approver != null && file.approver.length > 0 ) ? '' : '없음 '}}
                                <span class="num">
                                    {{ !( computedTemplate.default_template == null || computedTemplate.default_template.files == null || computedTemplate.default_template.files.length == 0 || computedTemplate.default_template.files[0].approver == null || computedTemplate.default_template.files[0].approver.length == 0 ) && ( file.approver == null || file.approver.length == 0 ) ? computedTemplate.default_template.files[0].approver.length : file.approver.length }}
                                </span>
                            </a>

                        </div>
                        <div class="div_third">
                            <span class="verti"></span>
                            <input type="button" class="close_end" @click="deleteFile(file)" value="삭제" />    
                        </div>
                    </li>
                </ul>
            </div>

        </div>

<!--
        <div class="Template_setting" style="">
            <input type="checkbox" id="TempsetCheck01" value="" class="input_check">
            <label for="TempsetCheck01" style="margin-top:2px;"><h3 style="position: absolute;left: 60px;">무역/수출입서식</h3></label>
            <p style="position: absolute; right:50px;top:15px;">모든 파일 삭제<span style="margin-left:30px;">3</span></p>
            <span class="more_temp"></span>
            <ul class="text_more">
                <li>
                    <span class="plus_file" style="" @click="showApprovalFileModal">＋</span><p>click or drag</p>
                </li>
                <li>
                    <span class="in_file" style="">＋</span><p>transfer invoice (세로형) .docx</p><span class="close_end"></span>
                    <span style="position: absolute;bottom: 15px;left: 95px;color: #bbb;font-weight: normal;">237.kb</span>
                </li>
                <li>
                    <span class="in_file" style="">＋</span><p>표준 송장 (ivoice) .docx</p>
                    <span style="position: absolute;bottom: 15px;left: 95px;color: #bbb;font-weight: normal;">237.kb</span>
                </li>
                <li>
                    <span class="in_file" style="">＋</span><p>Customer invoice .docx</p>
                    <span style="position: absolute;bottom: 15px;left: 95px;color: #bbb;font-weight: normal;">237.kb</span>
                </li>
            </ul>
        </div>

        <div class="Template_setting" style="">
            <input type="checkbox" id="TempsetCheck02" value="" class="input_check">
            <label for="TempsetCheck02" style="margin-top:2px;"><h3 style="position: absolute;left: 60px;">총무/서무서식</h3></label>
            <p style="position: absolute; right:50px;top:15px;">모든 파일 삭제<span style="margin-left:30px;">3</span></p>
            <a href="#" class="more_temp"></a>
            <ul class="text_more">
                <li>
                    <span class="plus_file" style="">＋</span><p>click or drag</p>
                </li>
                <li>
                    <span class="in_file" style="">＋</span><p>transfer invoice (세로형) .docx</p>
                    <span style="position: absolute;bottom: 15px;left: 95px;color: #bbb;font-weight: normal;">237.kb</span>
                </li>
                <li>
                    <span class="in_file" style="">＋</span><p>표준 송장 (ivoice) .docx</p>
                    <span style="position: absolute;bottom: 15px;left: 95px;color: #bbb;font-weight: normal;">237.kb</span>
                </li>
                <li>
                    <span class="in_file" style="">＋</span><p>Customer invoice .docx</p>
                    <span style="position: absolute;bottom: 15px;left: 95px;color: #bbb;font-weight: normal;">237.kb</span>
                </li>
            </ul>
        </div>
-->
        
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, {API_METHOD} from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonInputModalInfo } from '@/store/modules/ModalInfo';

import { approval_modal_interface, approval_interface, approval_enum } from '@/model/approval';

import { directive as onClickaway } from 'vue-clickaway';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        
    },
})
export default class ApprovalFormSetting extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    @Prop() event_bus !: Vue;
    @Prop() all_templates !: approval_interface.ApprovalTemplateBundle[];
    @Prop() templates !: approval_interface.ApprovalTemplateBundle[];
    @Prop() none_class_template !: any;
    @Prop() default_template !: any;
    @Prop() vacation_template !: any;
    @Prop() business_trip_template !: any;
    @Prop() holiday_work_template !: any;
    @Prop() cash_disbursement_voucher_template !: any;
    @Prop() free_template !: any;
    @Prop() receive_template !: any;

    get computedTemplate() {

        let obj = {
            "all_templates" : JSON.parse(JSON.stringify(this.all_templates)),
            "templates" : JSON.parse(JSON.stringify(this.templates)),
            "none_class_template" : JSON.parse(JSON.stringify(this.none_class_template)),
            "default_template" : JSON.parse(JSON.stringify(this.default_template)),
            "vacation_template" : JSON.parse(JSON.stringify(this.vacation_template)),
            "business_trip_template" : JSON.parse(JSON.stringify(this.business_trip_template)),
            "holiday_work_template" : JSON.parse(JSON.stringify(this.holiday_work_template)),
            "cash_disbursement_voucher_template" : JSON.parse(JSON.stringify(this.cash_disbursement_voucher_template)),
            "free_template" : JSON.parse(JSON.stringify(this.free_template)),
            "receive_template" : JSON.parse(JSON.stringify(this.receive_template)),
        }

        if( this.employees != null && this.employees.length > 0 ) {
            
            for( const all_template of obj.all_templates ) {
                for( const temp of all_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }

            for( const template of obj.templates ) {
                for( const temp of template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }

            if( obj.none_class_template != null ) {
                for( const temp of obj.none_class_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.default_template != null ) {
                for( const temp of obj.default_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }

            if( obj.vacation_template != null ) {
                for( const temp of obj.vacation_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.business_trip_template != null ) {
                for( const temp of obj.business_trip_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.holiday_work_template != null ) {
                for( const temp of obj.holiday_work_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.cash_disbursement_voucher_template != null ) {
                for( const temp of obj.cash_disbursement_voucher_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.free_template != null ) {
                for( const temp of obj.free_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.receive_template != null ) {
                for( const temp of obj.receive_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }

        }

        return obj;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalFileModalInfo       ?: (params : approval_modal_interface.ApprovalFileModalInfo) => void;
    @ModalInfo.Action doSetApproverSelectModalInfo     ?: (params : approval_modal_interface.ApproverSelectModalInfo) => void;
    @ModalInfo.Action doSetApprovalFormModifyModalInfo ?: (params : approval_modal_interface.ApprovalFormModifyModalInfo) => void;
    // @ModalInfo.Action doSetApprovalFormDetailModalInfo ?: (params : approval_modal_interface.ApprovalFormDetailModalInfo) => void;

    position : any[] = [];
    employees : any[] = [];

    default_tab : approval_enum.APPROVAL_DEFAULT_TAB = approval_enum.APPROVAL_DEFAULT_TAB.APPROVER;
 
    is_default_approver_open : boolean = true;
    is_vacation_open : boolean = true;
    is_none_class_open : boolean = true;

    scroll_height : number = 0;
    resize_function_submit : boolean = false;

    vacation_color_open : boolean = false;
    business_trip_color_open : boolean = false;

    async mounted() : Promise<void> {
        // $(".more_temp").click(function(){
        //     $(this).toggleClass("change2");
		// 	$(".more_temp").siblings("ul.text_more").toggleClass("open");
        // });


        //21.03.03 결재자/수신참조 tab
        // $('.content_btm_tab h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.setDefaultUl_wrap > ul').hide();
        // 	$('.setDefaultUl_wrap > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // })

        await this.getOrganizationEmp();
        await this.getOrganizationPosition();
        await this.getApprovalTemplateInfo();
        this.setScroll();

// 추가 jquery
        // $(document).ready(function(){
        //     $(".setDefaultDiv .more_temp").click(function(){
        //         $(".setDefaultDiv").toggleClass("on");
        //         return false;
        //     });
        // });

    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            const titleHeight  : number | undefined = $('.title_box').outerHeight();
            const default_div_height = $('.setDefaultDiv').outerHeight();
            const filterHeight : number | undefined = $('.filter_tap').outerHeight();

            this.scroll_height = window.innerHeight - (titleHeight ? titleHeight : 0)
                                                - (default_div_height ? default_div_height : 0)
                                                - (filterHeight ? filterHeight : 0);

            if( this.resize_function_submit == false ) {
                this.event_bus?.$on('formSettingResize', this.handleResize);
                this.resize_function_submit = true;
            }
        });
    }

    /**
     * 양식 설정 조회
     */
    async getApprovalTemplateInfo() : Promise<void> {
        this.$emit('getApprovalTemplateInfo');
    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
     async getOrganizationEmp() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 전자결재 파일 모달
     */
    async showApprovalFileModal(templateClassId : number) : Promise<void> {

        if( this.isAttendanceOnly() == true ) return;
        
        // 분류 없음 class가 없다면 추가
        if( this.computedTemplate.none_class_template == null ) {
            try {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { approval_class_name : '분류 없음', template_state : '0' });
            
                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("양식 추가에 필요한 데이터를 불러오는 중 오류 발생");
                }

                await this.getOrganizationPosition();
                await this.getApprovalTemplateInfo();

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", '양식 추가에 필요한 데이터를 불러오는 중 오류 발생', ['확인']);
            }
        }

        // 양식 추가 모달 (template_class_id가 0으로 들어온 경우 분류 없음)
        this.doSetApprovalFileModalInfo?.({ 
            show_modal : true, 
            is_create: true, 
            template_class_id: templateClassId == 0 ? this.computedTemplate.none_class_template.template_class_id : templateClassId, 
            callback: () =>{ 
                this.getApprovalTemplateInfo()
            } 
        });
    }

    toggleClass(template : any, index: number) {

        if( this.isAttendanceOnly() == true ) return;

        template.is_fold = !template.is_fold;
        this.computedTemplate.templates.splice(index, 1, template);
        this.$forceUpdate();
    }

    modalAddForm() : void {

        if( this.isAttendanceOnly() == true ) return;

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "분류 추가",
            subtitle : "분류명",
            placeholder : "분류명을 입력해주세요",
            save : async(name) => {
                try {
                    this.insertApprovalTemplateClass(name);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "분류항목 추가 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 선택한 양식파일을 삭제
     */
    async deleteFile(file: any) : Promise<void> {

        if( this.isAttendanceOnly() == true ) return;
        
        const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${file.template_class_id}/${file.template_id}`, API_METHOD.DELETE, null);
        console.log(response)

        if( !response || !this.isHttpStatusSuccess(response.status) ) {
            throw new Error("양식 분류항목 추가 중 오류 발생");
        }

        this.getApprovalTemplateInfo()
    }
    
    /**
     * 선택한 분류 항목들을 모두 삭제
     */
    deleteClass() : void {

        if( this.isAttendanceOnly() == true ) return;

        let selectedTemplateClassIds : number[] = []
        
        for ( const template of this.computedTemplate.templates ) {
            if ( template.is_selected ) {
                selectedTemplateClassIds.push(template.template_class_id as number)
            }
        }
        
        if ( selectedTemplateClassIds.length < 1 ) {
            return
        }

        this.hodu_show_dialog("cancel", selectedTemplateClassIds.length > 1 ? "선택하신 분류들을 삭제하시겠습니까?" : "선택하신 분류를 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.deleteApprovalTemplateClasses(selectedTemplateClassIds);
            }
        ]);
    }
    
    /**
     * 선택된 분류항목 삭제 서비스 호출
     */
    async deleteApprovalTemplateClasses(class_ids: number[]) : Promise<void> {
        
        let body = {
            class_ids : class_ids
        }

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.DELETE, body);
            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("분류항목 삭제 중 오류 발생");
            }
    
            await this.getApprovalTemplateInfo();

        }catch(e){
            this.hodu_error_process(e, false, false, true);
            alert("분류항목 삭제 중 오류 발생");
        }
    }

    /**
     * 분류항목 추가 서비스 호출
     */
    async insertApprovalTemplateClass(class_name: String) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, {approval_class_name : class_name});
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("양식 분류항목 추가 중 오류 발생");
            }

            await this.getApprovalTemplateInfo();

        } catch(e) {
            this.hodu_show_dialog("cancel", "양식 분류항목 추가 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 상세 모달
     */
    showApprovalFormDetailModal() : void {
        // this.doSetApprovalFormDetailModalInfo?.({
        //     show_modal : true,
        //     template_class_id : 0,
        //     template_id : 0
        // });
    }

    /**
     * 결재자 선택 모달
     */
    async showApproverSelectModal(template_state : string, template) : Promise<void> {

        if( template_state != '20' && template_state != '30' && this.isAttendanceOnly() == true ) {
            return;
        }

        await this.hodu_show_indicator();
        await this.getOrganizationPosition();
        await this.getApprovalTemplateInfo();
        await this.hodu_hide_indicator();
        
        let target_approver;

        if( template == null ) {
            target_approver = [];
        }
        else {
            const target_template_class_array = this.computedTemplate.all_templates.filter(temp_class => temp_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id ).length > 0);
            
            if( target_template_class_array.length < 1 ) {
                target_approver = [];
            }
            else {
                const target_template_class = target_template_class_array[0];
                const target_template = target_template_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id )[0];
                target_approver = (target_template != null && target_template.approver != null && target_template.approver.length > 0) ? JSON.parse(JSON.stringify(target_template.approver)) : []
            }
            
        }

        // 해당 양식에 기본 결재자가 없다면 && 전체 기본 결재자가 존재한다면
        if( template_state != '50' && target_approver.length < 1 && (this.computedTemplate.default_template != null && this.computedTemplate.default_template.files != null && this.computedTemplate.default_template.files.length > 0 && this.computedTemplate.default_template.files[0].approver != null && this.computedTemplate.default_template.files[0].approver.length > 0) ) {
            target_approver = JSON.parse(JSON.stringify(this.computedTemplate.default_template.files[0].approver));
        }

        for( const approver of target_approver ) {
            if( approver.approver_type != 'POSITION' ) continue;
            approver.position_seq = this.getPositionSeq(approver.pos_id) - 1;
        }

        this.doSetApproverSelectModalInfo?.({
            show_modal : true,
            is_approver : template_state != '50',
            is_default_setting : true,
            is_self_approve : false,
            selected : target_approver,
            callback : async(data : any) => {
                try {

                    console.log(data);
                    
                    // 일반 양식 결재자
                    if( template_state == '10' ) {
                        template.approver = data;
                        const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                        
                        console.log(response);

                        if( !response || !this.isHttpStatusSuccess(response.status) ) {
                            throw new Error("결재자 등록 중 오류 발생");
                        }
                    }

                    // 기본 결재자
                    else if( template_state == '20' ) {
                        if( this.computedTemplate.default_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '기본결재자',
                                template_state : '20',
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                            
                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 휴가원
                    else if( template_state == '30' ) {
                        if( this.computedTemplate.vacation_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '휴가원',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 출장신청서
                    else if( template_state == '40' ) {
                        if( this.computedTemplate.business_trip_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '출장신청서',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 지출결의서
                    else if( template_state == '60' ) {
                        if( this.computedTemplate.cash_disbursement_voucher_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '지출결의서',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 양식없음
                    else if( template_state == '70' ) {
                        if( this.computedTemplate.free_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '양식없음',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 휴일근무
                    else if( template_state == '80' ) {
                        if( this.computedTemplate.holiday_work_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '휴일근무',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 기본 수신참조
                    else if( template_state == '50' ) {
                        if( this.computedTemplate.receive_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '기본수신참조',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("수신참조 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("수신참조 등록 중 오류 발생");
                            }
                        }
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);

                    if( template_state != '50' ) {
                        this.hodu_show_dialog("cancel", "결재자 등록 중 오류 발생", ['확인']);
                    } 
                    else {
                        this.hodu_show_dialog("cancel", "수신참조 등록 중 오류 발생", ['확인']);
                    }
                    
                } finally {
                    await this.hodu_show_indicator();
                    await this.getOrganizationPosition();
                    await this.getApprovalTemplateInfo();
                    await this.hodu_hide_indicator();
                }
            }
        });
    }

    /**
     * 자가 결재 설정
     */
    async showSelfApproverSelectModal(template_state : string, template) : Promise<void> {

        if( template_state != '20' && template_state != '30' && this.isAttendanceOnly() == true ) {
            return;
        }

        await this.hodu_show_indicator();
        await this.getOrganizationPosition();
        await this.getApprovalTemplateInfo();
        await this.hodu_hide_indicator();
        
        let target_self_approver;

        if( template == null ) {
            target_self_approver = [];
        }
        else {
            const target_template_class_array = this.computedTemplate.all_templates.filter(temp_class => temp_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id ).length > 0);
            
            if( target_template_class_array.length < 1 ) {
                target_self_approver = [];
            }
            else {
                const target_template_class = target_template_class_array[0];
                const target_template = target_template_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id )[0];
                target_self_approver = (target_template != null && target_template.self_approver != null && target_template.self_approver.length > 0) ? JSON.parse(JSON.stringify(target_template.self_approver)) : []
            }
        }

        this.doSetApproverSelectModalInfo?.({
            show_modal : true,
            is_approver : true,
            is_default_setting : true,
            is_self_approve : true,
            selected : target_self_approver,
            callback : async(data : any) => {

                console.log(data);

                try {

                    await this.hodu_show_indicator();
                    await this.getOrganizationPosition();
                    await this.getApprovalTemplateInfo();
                    await this.hodu_hide_indicator();

                    let target_approver;

                    if( template == null ) {
                        target_approver = [];
                    }
                    else {
                        const target_template_class_array = this.computedTemplate.all_templates.filter(temp_class => temp_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id ).length > 0);
                        
                        if( target_template_class_array.length < 1 ) {
                            target_approver = [];
                        }
                        else {
                            const target_template_class = target_template_class_array[0];
                            const target_template = target_template_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id )[0];
                            target_approver = (target_template != null && target_template.approver != null && target_template.approver.length > 0) ? JSON.parse(JSON.stringify(target_template.approver)) : []
                        }
                    }

                    // 일반 양식 결재자
                    if( template_state == '10' ) {
                        template.approver = target_approver;
                        template.self_approver = data;  
                        const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                        
                        console.log(response);

                        if( !response || !this.isHttpStatusSuccess(response.status) ) {
                            throw new Error("자가 결재자 등록 중 오류 발생");
                        }
                    }

                    // 시스템 양식 - 휴가원
                    else if( template_state == '30' ) {
                        if( this.computedTemplate.vacation_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '휴가원',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 출장신청서
                    else if( template_state == '40' ) {
                        if( this.computedTemplate.business_trip_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '출장신청서',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 지출결의서
                    else if( template_state == '60' ) {
                        if( this.computedTemplate.cash_disbursement_voucher_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '지출결의서',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 양식없음
                    else if( template_state == '70' ) {
                        if( this.computedTemplate.free_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '양식없음',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 휴일근무
                    else if( template_state == '80' ) {
                        if( this.computedTemplate.holiday_work_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '휴일근무',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "자가 결재자 등록 중 오류 발생", ['확인']);
                } finally {
                    await this.hodu_show_indicator();
                    await this.getOrganizationPosition();
                    await this.getApprovalTemplateInfo();
                    await this.hodu_hide_indicator();
                }

            }
        });
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_target = $(event.target);
        const defaultImage = require('@/assets/images/contents/ic_approval_user.png');
        jquery_target.parent().find('p.img').css("background-image", `url(${defaultImage})`);
    }

    /**
     * 양식 수정모달
     */
    modify(file) : void {

        if( this.isAttendanceOnly() == true ) return;

        this.doSetApprovalFormModifyModalInfo?.({
            show_modal : true,
            template_class_id : file.template_class_id,
            template_id : file.template_id,
            callback : async() => {
                await this.getOrganizationPosition();
                await this.getApprovalTemplateInfo();
            }
        });
    }

    /**
     * 근태관리 기능만 ON 인 상태
     */
    isAttendanceOnly() : boolean {
        return (this.isEnableGroupFeature(this.scope_group_id, 'approval') == false && this.isEnableGroupFeature(this.scope_group_id, 'attendance') == true);
    }

    /**
     * 휴가원 색상
     */
    showSelectVacationColor() : void {
        this.vacation_color_open = !this.vacation_color_open;
    }

    /**
     * 출장신청서 색상
     */
    showSelectBusinessTripColor() : void {
        this.business_trip_color_open = !this.business_trip_color_open;
    }

    /**
     * 선택된 색상 없음
     */
    isColorNone(template) {
        return (template == null || template.files == null || template.files.length < 1 || template.files[0].contents == null ||
            template.files[0].contents.color == null || template.files[0].contents.color.length < 1);
    }

    /**
     * 선택된 색상인가?
     */
    isColorChecked(template, color) {

        if( this.isColorNone(template) == true ) {
            return false;    
        }

        return this.hodu_hex_color_process(template.files[0].contents.color).toUpperCase() == this.hodu_hex_color_process(color).toUpperCase();
    }

    /**
     * 휴가원 색상 선택
     */
    async setVacationTemplateColor(color, bar_color) {

        try {
            if( this.computedTemplate.vacation_template == null ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                    approval_class_name : '휴가원',
                    template_state : '30',
                    approver : [],
                    contents : { color : color, bar_color : bar_color } 
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("색상 등록 중 오류 발생");
                }

            }
            else {

                const template = this.computedTemplate.vacation_template.files[0];
                template.contents.color = color;
                template.contents.bar_color = bar_color;
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("색상 등록 중 오류 발생");
                }
            }

            const login_response = await this.hodu_api_call('api/v1/user/me', API_METHOD.GET);

            console.log(login_response);

            if( login_response == null || !this.isHttpStatusSuccess(login_response.status) || login_response.data == null || login_response.data.data == null ) {
                throw new Error("유저 정보 갱신 실패");
            }

            await this.doLogin({
                isLogin           : true,
                user_id           : login_response.data.data.user.user_id,
                user_type         : login_response.data.data.last_login_type,
                user_email        : login_response.data.data.user.user_email,
                user_name         : login_response.data.data.user.user_name,
                user_phone_number : login_response.data.data.user.user_phone_number,
                is_temp_password  : login_response.data.data.is_temp_password,
                user_preference   : login_response.data.data.user_preference,
                user_group_role   : [],
                user_team_role    : [],
                template_map      : login_response.data.data.template_map,
                auth_info         : login_response.data.data.user.auth_info
            });

            await this.get_group_role_service();

            // 달력 환경설정 정보 갱신
            await this.calendar_preference_sync(false);

            // 친구 정보 갱신
            await this.getFriends();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "색상 등록 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_show_indicator();
            await this.getOrganizationPosition();
            await this.getApprovalTemplateInfo();
            await this.hodu_hide_indicator();
        }

    }

    /**
     * 출장신청서 색상 선택
     */
    async setBusinessTripTemplateColor(color, bar_color) {

        try {

            if( this.computedTemplate.business_trip_template == null ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                    approval_class_name : '출장신청서',
                    template_state : '40',
                    approver : [],
                    contents : { color : color, bar_color : bar_color } 
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("색상 등록 중 오류 발생");
                }

            }
            else {

                const template = this.computedTemplate.business_trip_template.files[0];
                template.contents.color = color;
                template.contents.bar_color = bar_color;
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("색상 등록 중 오류 발생");
                }
            }

            const login_response = await this.hodu_api_call('api/v1/user/me', API_METHOD.GET);

            console.log(login_response);

            if( login_response == null || !this.isHttpStatusSuccess(login_response.status) || login_response.data == null || login_response.data.data == null ) {
                throw new Error("유저 정보 갱신 실패");
            }

            await this.doLogin({
                isLogin           : true,
                user_id           : login_response.data.data.user.user_id,
                user_type         : login_response.data.data.last_login_type,
                user_email        : login_response.data.data.user.user_email,
                user_name         : login_response.data.data.user.user_name,
                user_phone_number : login_response.data.data.user.user_phone_number,
                is_temp_password  : login_response.data.data.is_temp_password,
                user_preference   : login_response.data.data.user_preference,
                user_group_role   : [],
                user_team_role    : [],
                template_map      : login_response.data.data.template_map,
                auth_info         : login_response.data.data.user.auth_info
            });

            await this.get_group_role_service();

            // 달력 환경설정 정보 갱신
            await this.calendar_preference_sync(false);

            // 친구 정보 갱신
            await this.getFriends();
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "색상 등록 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_show_indicator();
            await this.getOrganizationPosition();
            await this.getApprovalTemplateInfo();
            await this.hodu_hide_indicator();
        }

    }

    vacationColorOutsideClick() : void {
        this.vacation_color_open = false;
    }

    businessTripColorOutsideClick() : void {
        this.business_trip_color_open = false;
    }

    /**
     * 순차열람 여부
     */
    isInOrderApproval() : boolean {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null && feature['approval'] != null ? (feature['approval']['is_in_order_approval'] ? feature['approval']['is_in_order_approval'] : false) : false;
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return false;
    }

    /**
     * 순차열람 옵션 변경
     */
    async changeIsInOrderApproval(event) {
        try {
            const feature = this.get_group_feature(this.scope_group_id);

            if( feature == null || feature['approval'] == null ) {
                throw new Error("순차열람 옵션 변경 중 오류 발생");
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/approval?key=is_in_order_approval&feature_flag=${event.target.checked}`, API_METHOD.PUT);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("순차열람 옵션 변경 중 오류 발생");
            }

        } catch(e) {
            this.hodu_show_dialog("cancel", "순차열람 옵션 변경 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        } finally {
            this.get_group_role_service();
        }
    }

    async changeHolidayWorkVisible(event) : Promise<void> {

        try {
            const checked = event.target.checked;
            
            if( this.computedTemplate.holiday_work_template != null && this.computedTemplate.holiday_work_template.files != null && this.computedTemplate.holiday_work_template.files[0] != null ) {
                
                const template = this.computedTemplate.holiday_work_template.files[0];
                
                if( template.contents == null ) {
                    template.contents = { "visible" : checked };
                }
                else {
                    template.contents.visible = checked;
                }
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴일근무 사용여부 변경 중 오류 발생");
                }

                
            }
            else {
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                    approval_class_name : '휴일근무',
                    template_state : '80',
                    approver : [],
                    contents : { "visible" : checked } 
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴일근무 사용여부 변경 중 오류 발생");
                }
            }

            await this.getApprovalTemplateInfo();
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "휴일근무 사용여부 변경 중 오류 발생", ['확인']);
        }
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 리사이즈 감지
     */
    @Watch('is_default_approver_open')
    handleResize() : void {
        // @ts-ignore
        // $('#form_list_scroll').mCustomScrollbar('destroy');
        this.setScroll();
    }

    //21.03.03 결재자/수신참조 tab
    // ('.content_btm_tab h2').click (function(){
	// 	var borderIndex = $(this).index();
	// 	$('.setDefaultUl_wrap > ul').hide();
	// 	$('.setDefaultUl_wrap > ul').eq(borderIndex).show();
	// 	$(this).addClass('active').siblings().removeClass();

	// 	return false;
    // })
    
    


}

    
</script>

<style scoped>

    /* 기본 결재자 선택 */
    .setDefaultDiv { transition: 0.2s; height: 67px; border-bottom: 1px solid #e7e9ea;padding-left: 28px;}
    .setDefaultDiv .content_top { border: 0 none !important; }
    #approval .setDefaultDiv .content_top h3.setDefaultTitle { padding-left: 0; }
    #approval .setDefaultDiv .content_top h3.setDefaultTitle span { background:#f1f3f5; color:#999cab; border:1px solid #dce1e6; font-size: 13px; padding:0 6px; margin-left: 10px; border-radius: 2px; }
    #approval .setDefaultDiv .content_top h3.setDefaultTitle span.on { background:#477fff; border-color: #477fff; color:#fff; }
    .setDefaultDiv.on { transition: 0.2s; height: auto;}
    .setDefaultDiv .more_temp { margin-right: 42px;margin-top:13px !important; }

    /* 기본 결재자 리스트 */
    .setDefaultDiv.on .content_btm { display: block; }
    .content_btm { display:none; padding-bottom:30px; }
    .content_btm h4 { margin-bottom: 20px; font-size: 13px; margin-top: 10px; }
    .content_btm .autoSelect { width: 100%; overflow: hidden; line-height: 20px; }
    .content_btm .autoSelect p {float: left; }
    .content_btm .switch { margin-top: 0; float: none; }

    .kb_name{position: relative; display: block; top: -10px; line-height:25px; left: 60px; color: #bbb; font-weight: normal;}

    .content_btm .setDefaultUl { overflow: hidden; }
    .content_btm .setDefaultUl li { float: left; width: 100px; height: 160px; text-align: center;  }
    .content_btm .setDefaultUl li .img { display:inline-block; width: 70px; height: 70px; border-radius: 50%; margin-bottom: 10px; border:3px solid #f1f3f5; }
    .content_btm .setDefaultUl li p { font-size: 13px; font-weight: bold; }
    .content_btm .setDefaultUl li p.name { font-size: 14px; }
    .content_btm .setDefaultUl li p.name, .content_btm .setDefaultUl li p.team { line-height: 25px; height: 25px; }
    .content_btm .setDefaultUl li.memLi p.pos, .content_btm .setDefaultUl li.memLi p.team { opacity:0.5; line-height: 20px; font-weight: normal; }
    .content_btm .setDefaultUl li.addList p.name { color:#477fff }
    .content_btm .setDefaultUl li.addList .img { border: 3px dashed #477fff; font-size: 33px; font-weight: bold; line-height: 68px; color:#477fff }
    .content_btm .setDefaultUl li.memLi .img { font-size: 0; background-size: cover; background-position: center center; }

    .approval_division_b_title_name{ position: absolute; left: 20px;}
    #form_list_scroll h3.approval_division_s_title_name{ position: relative; padding-left: 10px; padding-right: 0px; font-size: 13px; line-height: 20px; left: 0px; top: 4px; display: block; float: left; word-wrap: break-word; word-break: break-all; width: 80%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: pointer; } 

/* 추후 삭제 */
/* .content_btm .setDefaultUl li.memLi .img{background-image:url(../../assets/images/footer/f27.jpg);} */

    .content_btm .setDefaultUl li.posLi .img { font-size: 30px; line-height: 67px; }
    .content_btm .setDefaultUl li.posLi p.include { opacity:0.5; line-height: 20px; font-weight: normal; }
    #approval .more_temp:before { position: absolute; content:''; width:100%; right:0; top:0; }
    #approval .div_title:hover .more_temp  { background-color:#e7e9ea !important;  }
    #approval .approval_content input[type='checkbox']:not(old)+label {float: left;margin-top: 6px;padding: 0;width: 14px;height: 14px;border-radius: 1px;border: 2px solid rgb(193, 201, 212);background: rgb(193, 201, 212) url(/img/checked_fff.3f121842.png) no-repeat center;background-size: 17px;margin-right: 8px;}
    #approval .more_temp {float: right;background: #fff url(/img/bt_open.0927a4a6.png) no-repeat center center;width: 35px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 0;height: 35px;border-radius: 50%;cursor: pointer;margin-top: -5px;}
    #approval div.Template_setting {-webkit-transition: 0.2s;transition: 0.2s;font-size: 13px;margin: 0 40px 0 30px;padding: 16px 0 16px 20px;position: relative;overflow: hidden;height: auto;border-bottom: 1px solid #e7e9ea;line-height: normal;}
    #approval div.Template_setting:last-child { margin-bottom: 100px; }
    /* .num_of_forms { float: left; padding-right: 20px; border-right: 1px solid #dcdfe0; display: inline-block; width:20px; margin-right: 30px; } */
    #approval .text_more li div { position: relative; float: left; }
    #approval .text_more li div.div_first { position: relative; box-sizing: border-box; width:100%; padding-right: 200px; }
    #approval .text_more li div.div_second {z-index: 1000; position: absolute; top:0px; right: 50px; line-height: 44px; text-align:center; width: 130px }
    #approval .text_more li div.div_third {z-index: 1000; position: absolute; top:10px; right: 0; width: 45px }
    #approval .Template_setting .plus_file { transition: 0.1s; /* opacity:0; */ position: absolute; right: 50px; top: 13px; padding: 0 15px; height: 30px; line-height: 30px; border-radius: 15px; text-align: center; font-weight: bold; background:#477fff; color:#fff;  }
    /* #approval .Template_setting:hover .plus_file { opacity:1; } */
    #form_list_scroll h3 { font-size: 14px; top: 16px; padding-right:30px}
    .form_list_btn { position: relative; transition:0.1s; color:#b5b8ca; height: 44px; padding:0 10px;background:#fff; line-height: 44px; }
    .form_list_btn:hover { color:#477fff !important }
    .form_list_btn.exist { color: #232848; }
    
    #approval .text_more li .verti { display:block; width: 1px; height: 20px; background: #dcdfe0; position: absolute; left: -1px; top: 50%; margin-top: -10px; }
    #approval .text_more li .form_list_btn.exist .num {color: #477fff; }
    #approval .text_more li .num { margin-left: 5px; display: inline-block; padding: 0 6px;color:#9095b1; box-sizing: border-box; border: 1px solid #d7dbe4; min-width: 20px; box-sizing: border-box; line-height: 18px; text-align: center; height: 20px; border-radius: 16px; }

    #approval .Template_setting.systemForm { padding: 28px 0; }
    #approval .Template_setting.systemForm .list_name { line-height: 40px; }
    #approval .Template_setting.systemForm .text_more li div.div_second { right: -20px; }
    #approval .Template_setting.systemForm .more_temp { margin-top:-19px }

    #approval .Template_setting.systemForm .div_first .list_name { line-height: 45px; height: 45px;; }

    img.dummy { display : none; }

    #approval_form_setting .setDefaultDiv .dc0 { background-color:#ff6363 !important; border-color:#ff6363 !important; color:#fff !important; }
    #approval_form_setting .setDefaultDiv .dc1 { background-color:#FFA70E !important; border-color:#FFA70E !important; color:#fff !important; }
    #approval_form_setting .setDefaultDiv .dc2 { background-color:#FFC72F !important; border-color:#FFC72F !important; color:#fff !important; }
    #approval_form_setting .setDefaultDiv .dc3 { background-color:#FF198B !important; border-color:#FF198B !important; color:#fff !important; }
    #approval_form_setting .setDefaultDiv .dc4 { background-color:#00B2C7 !important; border-color:#00B2C7 !important; color:#fff !important; }
    #approval_form_setting .setDefaultDiv .dc5 { background-color:#13D08B !important; border-color:#13D08B !important; color:#fff !important; }
    #approval_form_setting .setDefaultDiv .dc6 { background-color:#4DBAFF !important; border-color:#4DBAFF !important; color:#fff !important; }
    #approval_form_setting .setDefaultDiv .dc7 { background-color:#477FFF !important; border-color:#477FFF !important; color:#fff !important; }
    #approval_form_setting .setDefaultDiv .dc8 { background-color:#6854FF !important; border-color:#6854FF !important; color:#fff !important; }
    #approval_form_setting .setDefaultDiv .dc9 { background-color:#35405A !important; border-color:#35405A !important; color:#fff !important; }

    #form_list_scroll { overflow : hidden; overflow-y : auto; }

    /* 21.03.03 수정 */
    .content_btm_tab {width: 92%; height: 35px; line-height: 35px; font-size: 14px; border-bottom: 1px solid #e7e9ea; }
    .content_btm_tab h2 { display: inline-block;}
    .content_btm_tab h2 a {height: 35px; line-height: 35px; display: block; padding: 0 10px; color: #232848; opacity: .5;}
    .content_btm_tab h2.active {border-bottom: 2px solid #477fff;}
    .content_btm_tab h2.active a {opacity: 1;}
    .content_btm h4 {margin-top: 30px; opacity: .5; }

    .content_btm .setDefaultUl {display: none;}
    .content_btm .setDefaultUl:first-child {display: block;}

    /* 21.8.19 수정 */
    #approval .text_more li div.div_second.selfsecondDiv { right: 180px; width: 150px; }
    #approval .Template_setting.systemForm .text_more li div.div_second.selfsecondDiv { right: 110px; }
    .form_list_btn.selfApp { color: #232848; }
    #approval .text_more li .form_list_btn.selfApp .num { color: #477fff; }

    /* 21.09.08 결재자순서 수정 */
    #approval_form_setting .setDefaultDiv .turnNum { position: absolute; top: 0; left: 5px; width: 22px; height: 22px; line-height: 22px; border-radius: 50%; background: #477fff; border: 2px solid #fff; color: #fff; }

    /* 21.11.29 색상선택 */
    #approval .Template_setting.systemForm .text_more li div.div_second.clrsecondDiv { right: 260px; }
    #approval .text_more li .clr_select_btn:hover { color: #477fff !important; }
    #approval .text_more li .clrPick { width: 20px; height: 20px; background: #E4F5FD; box-sizing: border-box; font-size: 0; display: inline-block; border-radius: 30px; vertical-align: middle; margin-top: -3px; margin-left: 4px; }
    #approval .text_more li .skyblue { width: 20px; height: 20px; background: #E4F5FD; }
    #approval .text_more li .pink { width: 20px; height: 20px; background: #FCE5EF; }
    #approval .text_more li .lightGrn { width: 20px; height: 20px; background: #D5FFEF; }
    #approval .text_more li .lightOran { width: 20px; height: 20px; background: #FDEBCD; }
    #approval .text_more li .lightPurple { width: 20px; height: 20px; background: #E2EAFF; }
    #approval .text_more .clrselBox { display: none; width: 270px; height: 55px; position: absolute; top: 0px; right: 8px; background: #fff; z-index: 999999; padding: 5px 10px; border-radius: 5px; box-shadow: 2px 2px 10px rgb(0 0 0 / 20%); box-sizing: border-box; }
    #approval .text_more .clrselBox.checkOpen { display: block; }
    #approval .text_more .clrselBox li { width: 30px !important; min-width: 30px; height: 30px; padding: 0; margin-top: 0px; margin-bottom: 0px; margin: 7px 10px; }
    #approval .text_more .clrselBox li a.clr-select { width: 30px; height: 30px; display: inline-block; background: #ddd; border-radius: 30px; }
    #approval .text_more .clrselBox li a.clr-select:hover { transform: scale(1.1); }
    #approval .text_more .clrselBox li a.clr-select.checked { background-image: url('../../assets/images/contents/checked_fff.png'); background-repeat: no-repeat; background-position: center center; background-size: 23px; }
    #approval .text_more .clrselBox li a.skyblue { background: #E4F5FD; }
    #approval .text_more .clrselBox li a.pink { background: #FCE5EF; }
    #approval .text_more .clrselBox li a.lightGrn { background: #D5FFEF; }
    #approval .text_more .clrselBox li a.lightOran { background: #FDEBCD; }
    #approval .text_more .clrselBox li a.lightPurple { background: #E2EAFF; }

    /* 결재자 순차 열람 */
    #approval #approval_form_setting label { padding-left: 0px; }
    .orderCheck { position: relative; float: right; text-align: right; }
    .orderCheck p { font-size: 14px; font-weight: normal; display: inline-block; line-height: 35px; margin-right: 8px; box-sizing: border-box; }
    .orderCheck span.tooltipSpan { width: 20px; height: 20px; line-height: 20px; display: inline-block; background: #666879; border-radius: 50%; color: #fff; font-size: 12px; text-align: center; vertical-align: middle; margin-right: 5px; }
    .orderCheck .switch { margin-top: 0px; vertical-align: middle; margin-top: -5px; }

    .orderToolBox { display: none; width: 300px; min-width: 170px; z-index: 1111; background: #fff; line-height: 20px; position: absolute; bottom: -35px; left: -135px; padding: 5px 10px; box-sizing: border-box; text-align: center; border-radius: 8px; box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 15%); transition: .2s; }
    .orderToolBox:after { content: ''; position: absolute; top: -10px; left: 135px; border-top: 0px solid transparent; border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid #fff; }
    .orderCheck .orderToolBox p { font-size: 13px; line-height: 20px; margin-top: 0; margin-right: 0; }
    .orderCheck p:hover ~ .orderToolBox { display: block; }

    /* 휴일근무 */
    .stting-option-box .clrsecondDiv .useWhetherText { display: inline-block; vertical-align: top; margin-right: 5px; }
    .stting-option-box .clrsecondDiv label.switch { float: none; margin-top: 9px; }

    /* mediaquery */    
    /* 화면 너비 0 ~ 1210px */
    @media (max-width: 1210px) {
        #approval .text_more li div.div_first { padding-right: 0px; }
        #approval .text_more li div.stting-option-box { display: block; width: 100%; margin-top: -5px; }
        #approval .text_more li .stting-option-box div.div_second { width: 25% !important; max-width: 135px; min-width: 115px; position: relative; left: 0; top: 0; right: 0px !important; white-space: nowrap; }
        #approval .Template_setting.systemForm .text_more li div.div_second.clrsecondDiv { width: 20% !important; min-width: 95px; text-align: left; }
        #approval .text_more .clrselBox { left: 90px; }
    }
</style> 