<template>
    <div id="event_read_modal" class="modal event_read_modal">

        <div class="titlebox">
            <h1 class="borderbot">읽음여부</h1>
        </div>

        <div class="tabs">
            <a href="#" :class="{ on : tab == 'READ' }" @click.prevent="tabChange('READ')">읽은 사람 <span>{{ getRead.count }}</span></a>
            <a href="#" :class="{ on : tab == 'NOT_READ' }" @click.prevent="tabChange('NOT_READ')">안 읽은 사람 <span>{{ getNotRead.count }}</span></a>
        </div>

        <div class="modal_content">
            <!-- 읽은 사람 -->
            <div class="userDiv readUser" v-show="tab == 'READ'">
                <ul class="userUlList readUl">

                    <li :class="{ on : read.open == true }" :key="index" v-for="(read, index) in getRead.list" @click.prevent="shareOnOff(read)">
                        <p>{{ read.share_tag }}</p>
                        <a title="리스트보기" class="readListMore" @click.prevent="() => {}">리스트</a>
                        <p class="userTotalP">총<span>{{ read.read_users.length }}</span></p>

                        <div class="shareUserList">
                            <ul>
                                <li :key="user_index" v-for="(user, user_index) in read.read_users">
                                    <p class="userImg">
                                        <!-- <img :src="make_user_profile_image_url(user.user_id)" alt="인물" @error="userImageError($event)"> -->
                                        <img :src="`app_images/${user.user_pic}`" alt="인물" @error="userImageError($event)">
                                    </p>
                                    <p class="userName">{{ user.user_name }} <span class="userRank">{{ user.organization_position }}</span></p>
                                    <p class="whether">{{ `${hodu_date_to_format_string(user.audit_created, 'YYYY.MM.DD')} (${getDayOfWeekByDate(user.audit_created)})` }} <span>{{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(user.audit_created, 'hh:mm a')) }}</span></p>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <!-- <li>
                        <p>공유자</p>
                        <a title="리스트보기" class="readListMore">리스트</a>
                        <p class="userTotalP">총<span>2</span></p>


                        <div class="shareUserList">
                            <ul>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">이시영 <span class="userRank">차장/팀장</span></p>
                                    <p class="whether">2022.07.27 &#40;수&#41; <span>오후 03:23</span></p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">정동진 <span class="userRank">과장</span></p>
                                    <p class="whether">2022.07.27 &#40;수&#41; <span>오후 03:23</span></p>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <p>호두모바일</p>
                        <a title="리스트보기" class="readListMore">리스트</a>
                        <p class="userTotalP">총<span>4</span></p>


                        <div class="shareUserList">
                            <ul>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">이시영 <span class="userRank">차장/팀장</span></p>
                                    <p class="whether">2022.07.27 &#40;수&#41; <span>오후 03:23</span></p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">정동진 <span class="userRank">과장</span></p>
                                    <p class="whether">2022.07.27 &#40;수&#41; <span>오후 03:23</span></p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">Michelangelo <span class="userRank">사원</span></p>
                                    <p class="whether">2022.07.27 &#40;수&#41; <span>오후 03:23</span></p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">김하영 <span class="userRank">사원</span></p>
                                    <p class="whether">2022.07.27 &#40;수&#41; <span>오후 03:23</span></p>
                                </li>
                            </ul>
                        </div>
                    </li> -->
                </ul>
            </div>
            <!-- //읽은 사람 -->

            <!-- 읽지않은 사람 -->
            <div class="userDiv notreadUser" v-show="tab == 'NOT_READ'">
                <ul class="userUlList notreadUl">

                    <li :class="{ on : read.open == true }" :key="index" v-for="(read, index) in getNotRead.list" @click.prevent="shareOnOff(read)">
                        <p>{{ read.share_tag }}</p>
                        <a title="리스트보기" class="readListMore" @click.prevent="() => {}">리스트</a>
                        <p class="userTotalP">총<span>{{ read.read_users.length }}</span></p>

                        <div class="shareUserList">
                            <ul>
                                <li :key="user_index" v-for="(user, user_index) in read.read_users">
                                    <p class="userImg">
                                        <!-- <img :src="make_user_profile_image_url(user.user_id)" alt="인물" @error="userImageError($event)"> -->
                                        <img :src="`app_images/${user.user_pic}`" alt="인물" @error="userImageError($event)">
                                    </p>
                                    <p class="userName">{{ user.user_name }} <span class="userRank">{{ user.organization_position }}</span></p>
                                    <p class="whether">읽지않음</p>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <!-- <li>
                        <p>공유자</p>
                        <a title="리스트보기" class="readListMore">리스트</a>
                        <p class="userTotalP">총<span>4</span></p>

                        <div class="shareUserList">
                            <ul>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">이시영 <span class="userRank">차장/팀장</span></p>
                                    <p class="whether">읽지않음</p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">정동진 <span class="userRank">과장</span></p>
                                    <p class="whether">읽지않음</p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">정동진 <span class="userRank">과장</span></p>
                                    <p class="whether">읽지않음</p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">정동진 <span class="userRank">과장</span></p>
                                    <p class="whether">읽지않음</p>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <p>호두모바일</p>
                        <a title="리스트보기" class="readListMore">리스트</a>
                        <p class="userTotalP">총<span>4</span></p>

                        <div class="shareUserList">
                            <ul>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">이시영 <span class="userRank">차장/팀장</span></p>
                                    <p class="whether">읽지않음</p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">정동진 <span class="userRank">과장</span></p>
                                    <p class="whether">읽지않음</p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">Michelangelo <span class="userRank">사원</span></p>
                                    <p class="whether">읽지않음</p>
                                </li>
                                <li>
                                    <p class="userImg">
                                        <img src="../../../assets/images/contents/ic_approval_user.png" alt="인물">
                                    </p>
                                    <p class="userName">김하영 <span class="userRank">사원</span></p>
                                    <p class="whether">읽지않음</p>
                                </li>
                            </ul>
                        </div>
                    </li> -->
                </ul>
            </div>
            <!-- //읽지않은 사람 -->
        </div>

        <div class="btns">
            <input type="button" value="확인" @click="close">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo    = namespace('ModalInfo');

import { EventReadModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class EventReadModal extends Mixins(VueHoduCommon) {

    /**
     * 읽은 사람
     */
    get getRead() : any {

        let read : any[] = JSON.parse(JSON.stringify(this.read));
        let count : number = 0;

        const read_count = read.length;
        for( let i = (read_count - 1);  i >= 0; i-- ) {
            const obj = read[i];
            const user_count = obj.read_users.length;
            for( let j = (user_count - 1);  j >= 0; j-- ) {
                const user = obj.read_users[j];
                if( user.read_flag == true ) continue;
                obj.read_users.splice(j, 1);
            }

            if( obj.read_users.length < 1 ) {
                read.splice(i, 1);
            }
        }

        for( const obj of read ) {
            count += obj.read_users.length;
        }

        return {
            "list" : read,
            "count" : count
        };
    }

    /**
     * 안 읽은 사람
     */
    get getNotRead() : any {

        let read : any[] = JSON.parse(JSON.stringify(this.read));
        let count : number = 0;

        const read_count = read.length;
        for( let i = (read_count - 1);  i >= 0; i-- ) {
            const obj = read[i];
            const user_count = obj.read_users.length;
            for( let j = (user_count - 1);  j >= 0; j-- ) {
                const user = obj.read_users[j];
                if( user.read_flag == false ) continue;
                obj.read_users.splice(j, 1);
            }

            if( obj.read_users.length < 1 ) {
                read.splice(i, 1);
            }
        }

        for( const obj of read ) {
            count += obj.read_users.length;
        }

        return {
            "list" : read,
            "count" : count
        };
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State event_read_modal_info !: EventReadModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventReadModalInfo ?: (params : EventReadModalInfo) => void;

    read : any[] = [];
    tab : string = "READ"; // READ, NOT_READ

    mounted() : void {
        // // 탭 메뉴
        // $('.tabs a').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.modal_content > div').hide();
        // 	$('.modal_content > div').eq(borderIndex).show();
        // 	$(this).addClass('on').siblings().removeClass();

        // 	return false;
        // })

        // // 리스트 열기
        // $(".userUlList > li").click(function(){
        //     $(this).toggleClass("on");
        // });

        this.getEventRead();
    }

    async getEventRead() : Promise<void> {
        try {

            const calendar_id = this.event_read_modal_info.calendar_id;
            const owner_type = this.event_read_modal_info.owner_type;
            const owner_id = this.event_read_modal_info.owner_id;
            const event_id = this.event_read_modal_info.event_id;
            const repeat_seq = this.event_read_modal_info.repeat_seq;

            const response = await this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}/read/${event_id}/${repeat_seq}`, API_METHOD.GET, null, false);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.read ) {
                throw new Error("읽기 정보 조회 중 오류 발생")
            }

            this.read.splice(0, this.read.length);
            this.read = this.read.concat(response.data.data.read);
            
        } catch(e) {
            alert("읽기 정보 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    tabChange(tab : string) {
        this.tab = tab;
        this.read.splice(0, this.read.length);
        this.getEventRead();
    } 

    shareOnOff(read) {
        let flag = true;
        let index = -1;
        let target_obj : any = null;

        for( const obj of this.read ) {
            if( obj.share_tag == read.share_tag ) {
                index = this.read.indexOf(obj);
                target_obj = obj;
                flag = (obj.open == true) ? false : true;
                break;
            }
        }

        if( index > -1 && target_obj != null ) {
            target_obj = JSON.parse(JSON.stringify(target_obj));
            target_obj.open = flag;
            this.read.splice(index, 1, target_obj);
        }

    }

    userImageError(event) : void {
        const default_url = require('@/assets/images/contents/ic_approval_user.png');
        $(event.target).attr('src', default_url);
    }

    close() : void {
        this.doSetEventReadModalInfo?.({
            show_modal : false,
            event_id : "",
            repeat_seq : 0,
            calendar_id : "",
            owner_type : "",
            owner_id : 0
        })
    }

}
</script>

<style scoped>
    #event_read_modal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-262px; margin-left:-325px; width:650px; height: auto; background:#fff; }

    #event_read_modal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; }
    #event_read_modal .titlebox h1 { font-size: 20px; font-weight: bold; width: 100%; height: 80px; line-height: 90px; text-align: left; border-bottom: 1px solid #e7e9ea; padding: 0 30px; box-sizing: border-box; }

    #event_read_modal .modal_content { overflow: hidden; height: auto; border-bottom: 1px solid #e7e9ea; box-sizing: border-box; }

    #event_read_modal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    #event_read_modal .btns input { transition:0.2s; width: 100%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold; background: #fff; }
    #event_read_modal .btns input:hover { background:#f1f3f5; }

    .event_read_modal .tabs { height: 50px; line-height: 50px; position: relative; font-size: 14px; border-bottom: 3px solid #e7e9ea; }
    .event_read_modal .tabs a { width: 50%; display: inline-block; float: left; text-align: center; position: relative; color: #999; }
    .event_read_modal .tabs a span { color: #999; }

    .event_read_modal .tabs a.on, .event_read_modal .tabs a.on span { font-weight: bold; color: #477fff; }
    .event_read_modal .tabs a:hover:after, .event_read_modal .tabs a.on:after { z-index: 1; position: absolute; content: ''; width: 100%; height: 3px; display: inline-block; background: #477fff; -webkit-transition: 0.2s; transition: 0.2s; bottom: -3px; left: 0; }

    .userDiv .userUlList { overflow: hidden; box-sizing: border-box; min-height: 320px; max-height: 320px; }
    .userDiv .notreadUser { display: none; }
    .userDiv .userUlList { overflow: hidden; box-sizing: border-box; min-height: 320px; max-height: 320px; overflow-y : auto; }

    .userDiv .userUlList li { display: inline-block; float: left; position: relative; width: 100%; height: auto; line-height: 55px; border-bottom: 1px solid #f1f3f5; box-sizing: border-box; cursor: pointer; }
    .userDiv .userUlList li a.readListMore { display: inline-block; vertical-align: top; font-size: 0; z-index: 10000; opacity: 1; -webkit-transition: 0.2s; transition: 0.2s; width: 50px; height: 55px; background-color: transparent; -webkit-box-sizing: border-box; box-sizing: border-box; background-image: url('../../../assets/images/side_area/bt_open.png'); background-size: 26px; background-repeat: no-repeat; background-position: center center; }
    .userDiv .userUlList li.on a.readListMore { background-image: url('../../../assets/images/side_area/bt_close.png'); }
    .userDiv .userUlList li p { font-size: 13px; display: inline-block; padding-left: 30px; box-sizing: border-box; }
    .userDiv .userUlList li .userTotalP { position: absolute; top: 0; right: 30px; font-size: 12px; }
    .userDiv .userUlList li p img { width: 37px; height: 37px; border-radius: 50%; overflow: hidden; box-sizing: border-box; margin-top: 9px; vertical-align: top; border: 2px solid #e7e9ea; /* background: url('../../../assets/images/contents/ic_approval_user.png') no-repeat center center; */ }

    .userDiv .userUlList li .shareUserList { display: none; }
    .userDiv .userUlList li.on .shareUserList { display: block; }
    .userDiv .userUlList li .shareUserList p { font-weight: bold; }
    .userDiv .userUlList li .shareUserList p.userName { padding-left: 15px; }
    .userDiv .userUlList li .shareUserList p.userName span { opacity: 0.5; margin-left: 5px; }
    .userDiv .userUlList li .shareUserList p.whether { float: right; margin-right: 30px; font-size: 12px; font-weight: normal; }
    .userDiv .notreadUl li .shareUserList p.whether { color: #ff6565; }

    #event_read_modal ::-webkit-scrollbar{width: 6px;}
    #event_read_modal ::-webkit-scrollbar-track{background-color: transparent;}
    #event_read_modal ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
</style> 