<template>
    <div class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />
        
        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 id="viewTitle" class="title_doc">결재 상세</h3>
            <p class="btn_group">
                <input type="button" id="Modify" value="수정" class="input_btn" v-if="approval != null && (user_id == approval.user_id || user_id == approval.contents.deputy_user_id) && approval.approval_state == 'TEMP'" @click.prevent="approvalModify">
                <input type="button" id="Delete" value="삭제" class="input_btn" v-if="approval != null && (user_id == approval.user_id || user_id == approval.contents.deputy_user_id) && approval.approval_state == 'TEMP'" @click.prevent="approvalDelete">
                <input type="button" id="Print" value="인쇄" class="input_btn" v-if="approval != null && approval.approval_state == 'COMPLETE'" @click.prevent="printApproval">
                <input type="button" id="Copy" value="복사" class="input_btn" v-if="approval != null && (user_id == approval.user_id || user_id == approval.contents.deputy_user_id) && approval.approval_state != 'TEMP' && isCopyable() == true" @click.prevent="approvalCopy">
            </p> 
            <!-- <a href="#" class="Delete" v-if="approval != null && user_id == approval.user_id && approval.approval_state == 'TEMP'" @click.prevent="approvalDelete">삭제</a>
            <a href="#" class="Modify" v-if="approval != null && user_id == approval.user_id && approval.approval_state == 'TEMP'" @click.prevent="approvalModify">수정</a>
            <a href="#" class="Print" v-if="approval != null && approval.approval_state == 'COMPLETE'" @click.prevent="printApproval">인쇄</a> -->
            <!---반려시 등록자에서 표시--->
        </div>

        <div class="content" style="height:100%;position: relative;" v-if="approval != null">
            
            <div id="approval_detail_left_scroll" class="left_main" >

                <div class="left_main_content">

                    <table class="Approval_table">
                        <thead>
                            <tr style="border-bottom:1px solid #e7e9ea;">
                                <th colspan="4">
                                    <span :class="{ quick : approval.imp_seq == approval_enum.APPROVAL_IMPORTANCE_FILTER.EMERGENCY, important : approval.imp_seq == approval_enum.APPROVAL_IMPORTANCE_FILTER.IMPORTANCE }" v-if="approval.imp_seq < approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL">{{ approval.imp_seq == approval_enum.APPROVAL_IMPORTANCE_FILTER.EMERGENCY ? '긴급' : '중요' }}</span>{{ approval.contents.title }}
                                </th>
                                <!-- <th style="text-align:right;padding: 15px 0;"> -->
                                    <!-- <input id="fever" type="checkbox" name="tabs"><label class="fever"  style="font-size:0;" for="fever">즐겨찾기</label> -->
                                <!-- </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="border-bottom:1px solid #e7e9ea;">
                                <td class="cen_text right_bor">기안자</td>
                                <td class="right_bor right_text" style="width:35%;">{{ approval.approver[0].user_name }}({{ approval.approver[0].pos_name }})</td>
                                <td class="cen_text right_bor">문서번호</td>
                                <td class="right_text" style="width:35%;">
                                    {{ approval.approval_document_num != null && approval.approval_document_num.length > 0 ? approval.approval_document_num : '결재 완료시 문서번호가 생성됩니다' }}
                                </td>
                            </tr>
                            <tr style="border-bottom:1px solid #e7e9ea;">
                                <td class="cen_text right_bor">부서</td>
                                <td class="right_bor right_text">{{ approval.approver[0].dept_name }}</td>
                                <td class="cen_text right_bor">요청일</td>
                                <td class="right_text">
                                    {{ hodu_date_to_format_string(approval.audit_created, 'YYYY.MM.DD') + " " + getDayOfWeekByDate(approval.audit_created) + " " + amPmStringToLocaleAmPmString(hodu_date_to_format_string(approval.audit_created, 'hh:mm a')) }}
                                </td>
                            </tr>

                            <tr style="border-bottom:1px solid #e7e9ea;" v-if="approval.approval_type == 'VACATION'">
                                <td class="cen_text right_bor">휴가종류</td>
                                <td class="right_text" :class="{ right_bor : isDenoteAnnualCount() == true }" :colspan="isDenoteAnnualCount() == true ? 1 : 3">{{ approval.contents.vacation_type_name }} ({{ approval.contents.vacation_type_daycount == 0 ? (approval.contents.vacation_selected ? approval.contents.vacation_selected.length : 0) : processDayCount(approval.contents.vacation_type_daycount) }} 일)</td>
                                <td class="cen_text right_bor" v-if="isDenoteAnnualCount() == true">사용 된 휴가 수</td>
                                <td class="right_text" v-if="isDenoteAnnualCount() == true"><span class="left" :class="{ gray : approval['annual_total'].split(' / ')[0] == 0 }">{{ approval['annual_total'].split(' / ')[0] }}</span> / {{ approval['annual_total'].split(' / ')[1] }}</td>
                            </tr>

                            <tr style="border-bottom:1px solid #e7e9ea;" v-if="approval.approval_type == 'VACATION'">
                                <td class="cen_text right_bor">휴가일자</td>
                                <td class="right_text" colspan="3">
                                    {{ getVacationText() }}
                                </td>
                            </tr>

                            <tr style="border-bottom:1px solid #e7e9ea;" v-if="approval.approval_type == 'BUSINESSTRIP'">
                                <td class="cen_text right_bor">출장종류</td>
                                <td class="right_text right_bor">{{ approval.contents.vacation_type_name }}</td>
                                <td class="cen_text right_bor">출장일수</td>
                                <td class="right_text">{{ approval.contents.vacation_type_daycount == 0 ? (approval.contents.vacation_selected ? approval.contents.vacation_selected.length : 0) : processDayCount(approval.contents.vacation_type_daycount) }} 일</td>
                            </tr>

                            <tr style="border-bottom:1px solid #e7e9ea;" v-if="approval.approval_type == 'BUSINESSTRIP'">
                                <td class="cen_text right_bor">출장일자</td>
                                <td class="right_text" colspan="3">
                                    {{ getBusinessTripText() }}
                                </td>
                            </tr>

                            <tr style="border-bottom:1px solid #e7e9ea;" v-if="approval.approval_type == 'HOLIDAY_WORK'">
                                <td class="cen_text right_bor">휴일근무종류</td>
                                <td class="right_text right_bor">{{ approval.contents.vacation_type_name }}</td>
                                <td class="cen_text right_bor">근무일수</td>
                                <td class="right_text">{{ approval.contents.vacation_type_daycount == 0 ? (approval.contents.vacation_selected ? approval.contents.vacation_selected.length : 0) : processDayCount(approval.contents.vacation_type_daycount) }} 일</td>
                            </tr>

                            <tr style="border-bottom:1px solid #e7e9ea;" v-if="approval.approval_type == 'HOLIDAY_WORK'">
                                <td class="cen_text right_bor">근무일자</td>
                                <td class="right_text" colspan="3">
                                    {{ getHolidayWorkText() }}
                                </td>
                            </tr>

                            <tr style="border-bottom:1px solid #e7e9ea;">
                                <td class="cen_text right_bor">수신(참조)</td>
                                <td class="right_text" colspan="3">{{ getReceiveReference() }}</td>
                            </tr>

                            <tr style="border-bottom:1px solid #e7e9ea;">
                                <td class="cen_text right_bor" style="height:100px;">내용</td>
                                <td class="right_text" colspan="3" style="white-space: pre-line; word-break: break-all;" v-html="hodu_make_href(approval.contents.comment).replace(/\n/ig, '<br/>')"></td>
                            </tr>

                            <!--기안서반려시 수정내용------>
                            <!-- <tr style="border-bottom:1px solid #e7e9ea;">
                                <td class="cen_text right_bor" style="height:100px;">수정내용</td>
                                <td class="right_text" colspan="3">기안자 포함하여 수정완료 했습니다.</td>
                            </tr> -->

                        </tbody>
                    </table>

                    <!-- 지출결의서 상세 노출 -->
                    <table class="Approval_table dealList mt30" v-if="approval.approval_type == 'CASH_DISBURSEMENT_VOUCHER'">
                        <thead>
                            <tr style="border-bottom:1px solid #e7e9ea;">
                                <th colspan="4" style="color: #232848;">
                                    <h3>지출내역</h3>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div class="dealListDiv" v-if="approval.approval_type == 'CASH_DISBURSEMENT_VOUCHER'">
                        <ul class="dealMenuUl">
                            <li class="dealSubject">항목</li>
                            <li class="dealDate">지출일자</li>
                            <li class="dealAmount">금액</li>
                            <li class="dealStore">거래처</li>
                            <li class="dealNote">비고</li>
                        </ul>
                        <ul class="dealContentUl">

                            <li :key="index" v-for="(transaction, index) in approval.contents.transactions">
                                <p class="dealSubject">{{ transaction.subject }}</p>
                                <p class="dealDate">{{ `${hodu_date_to_format_string(transaction.date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(transaction.date)}` }}</p>
                                <p class="dealAmount">{{ number_price_format(transaction.price) }}</p>
                                <p class="dealStore">{{ hodu_string_validation(transaction.account) }}</p>
                                <p class="dealNote right_bor_non">{{ hodu_string_validation(transaction.remark) }}</p>
                            </li>

                            <!-- <li>
                                <p class="dealSubject">사업자 자동차 정비</p>
                                <p class="dealDate">2022.06.27 월</p>
                                <p class="dealAmount">150000</p>
                                <p class="dealStore">호두정비소</p>
                                <p class="dealNote right_bor_non">정기점검 및 정비</p>
                            </li>
                            <li>
                                <p class="dealSubject">개발팀 회식</p>
                                <p class="dealDate">2022.06.27 월</p>
                                <p class="dealAmount">186000</p>
                                <p class="dealStore">송파 한우</p>
                                <p class="dealNote right_bor_non"></p>
                            </li>
                            <li>
                                <p class="dealSubject">6월 셋째주 석식</p>
                                <p class="dealDate">2022.06.24 금</p>
                                <p class="dealAmount">62000</p>
                                <p class="dealStore">호두반점</p>
                                <p class="dealNote right_bor_non"></p>
                            </li> -->

                            <li>
                                <p class="dealTotal">합계</p>
                                <p class="dealSum right_bor">{{ number_price_format(computedTotalPrice) }}</p>
                            </li> 

                        </ul>
                    </div>
                    <div class="file_div" v-if="approval.approval_type == 'CASH_DISBURSEMENT_VOUCHER' && computedTransactionFileLength > 0">
                        <button class="receiptAddBtn" @click.prevent="fadeInTransactionFile">영수증파일 <span>&#40;{{ computedTransactionFileLength }}&#41;</span></button>
                        <p>	&#42; 프린터 출력 시 영수증 파일은 출력되지 않습니다. 별도로 다운로드하여 출력 바랍니다.</p>
                        
                        <!-- 영수증파일 팝업 -->
                        <div class="filePopup" v-if="approval.approval_type == 'CASH_DISBURSEMENT_VOUCHER'">
                            <h4 class="blind">영수증 첨부파일</h4>
                            <a href="#" class="allDown_btn" @click.prevent="downloadAll">전체 다운로드</a>
                            <button type="button" class="filePopup_close" @click.prevent="fadeOutTransactionFile">닫기</button>
                            <div class="attFileDiv">
                                <ul class="attFileUl">
                                    
                                    <li :key="index" v-for="(transaction_file, index) in approval.contents.transaction_files">
                                        <p class="attFile_icon">아이콘</p>
                                        <p class="attFile_title" title="hoduware_introduction.pdf">{{ transaction_file.name }}</p>
                                        <a href="#" class="fileDown_btn" @click.prevent="hodu_download(`app_images/${transaction_file.url}`, transaction_file.name)">PC저장</a>
                                    </li>

                                    <!-- <li>
                                        <p class="attFile_icon">아이콘</p>
                                        <p class="attFile_title" title="hoduware_introduction.pdf">호두정비소_영수증.jpg</p>
                                        <a href="#" class="fileDown_btn">PC저장</a>
                                    </li>
                                    <li>
                                        <p class="attFile_icon">아이콘</p>
                                        <p class="attFile_title" title="hoduware_introduction.pdf">회식_영수증.jpg</p>
                                        <a href="#" class="fileDown_btn">PC저장</a>
                                    </li>
                                    <li>
                                        <p class="attFile_icon">아이콘</p>
                                        <p class="attFile_title" title="hoduware_introduction.pdf">6월_3_석식.jpg</p>
                                        <a href="#" class="fileDown_btn">PC저장</a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <!-- //영수증파일 팝업 -->
                    </div>
            <!-- //지출결의서 상세 노출 -->

                    <div class="file_div" v-if="approval.approval_type == 'FREE' && computedFreeFileLength > 0">
                        <button class="receiptAddBtn" @click.prevent="fadeInTransactionFile">파일 <span>&#40;{{ computedFreeFileLength }}&#41;</span></button>
                        <p>	&#42; 프린터 출력 시 파일은 출력되지 않습니다. 별도로 다운로드하여 출력 바랍니다.</p>
                        
                        <!-- 영수증파일 팝업 -->
                        <div class="filePopup" v-if="approval.approval_type == 'FREE'">
                            <h4 class="blind">첨부파일</h4>
                            <a href="#" class="allDown_btn" @click.prevent="downloadAll">전체 다운로드</a>
                            <button type="button" class="filePopup_close" @click.prevent="fadeOutTransactionFile">닫기</button>
                            <div class="attFileDiv">
                                <ul class="attFileUl">
                                    
                                    <li :key="index" v-for="(file, index) in approval.contents.files">
                                        <p class="attFile_icon">아이콘</p>
                                        <p class="attFile_title" title="hoduware_introduction.pdf">{{ file.name }}</p>
                                        <a href="#" class="fileDown_btn" @click.prevent="hodu_download(`app_images/${file.url}`, file.name)">PC저장</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>

                    <div style="min-height : 100px; position: relative;font-size: 14px;" v-if="approval.approval_type == 'BASIC'">
                        <div class="downBtnBox">
                            <span class="fileAllCount">첨부파일({{ approval.contents.files.length }})</span>
                            <a href="#" style="position: absolute;top: 15px;right: 0px;font-weight: bold;color: #477fff;" @click.prevent="downloadAll">전체 다운로드</a>
                        </div>

                        <div class="Approval_flle normalAppFile" v-if="none_pdf_files.length > 0">일반파일</div>

                        <div class="hodu-none-pdf-container" v-if="none_pdf_files.length > 0"> 
                            <p class="none_pdf_files" :key="index" v-for="(file, index) in none_pdf_files" @click.prevent="hodu_download(`app_images/${file.url}`, file.name)"><span class="attach_img">이미지</span>{{ file.name }}</p>
                        </div>

                        <div class="Approval_flle normalAppFile" v-if="pdf_infos.length > 0">결재문서</div>

                        <div class="hodu-pdf-container" :key="index" v-for="(pdf_info, index) in pdf_infos">
                            <!-- <p class="hodu-pdf-title">{{ `${pdf_info.file.name} (${pdf_info.pages} 페이지)` }}<a href="#" class="file_donwload" @click.prevent="hodu_download(`app_images/${pdf_info.file.url}`, pdf_info.file.name)">다운로드</a></p> -->
                            <pdf 
                                :src="`app_images/${pdf_info.file.url}`"
                                :page="i"
                                :key="i"
                                v-for="i in pdf_info.pages"
                            />
                            <!-- <pdf 
                                :src="`app_images/${pdf_info.file.url}`"
                                :page="pdf_info.page"
                            /> -->
                        </div>

                        <!-- <ul class="flie_box">
                            <li :key="index" v-for="(file, index) in approval.contents.files">
                                <p class="filepdf_img">아이콘</p>
                                <p style="line-height: 25px;vertical-align: bottom;font-weight: bold;">
                                    {{ file.name }}
                                    <span style="color: #aaa;display:block;">{{
                                        file.size >= 1 * 1024 * 1024 ? `${ (file.size / 1024 / 1024).toFixed(2) } MB` :
                                        file.size >= 1 * 1024        ? `${ (file.size / 1024       ).toFixed(2) } KB` : `${ file.size } Byte`    
                                    }} / {{ `${file.template_name} (${file.template_class_name})` }}</span>
                                </p>
                                <a href="#" class="file_donwload" @click.prevent="hodu_download(`app_images/${file.url}`, file.name)">다운로드</a>
                            </li>

                            <li>
                                <p class="filepdf_img">아이콘</p>
                                <p style="line-height: 25px;vertical-align: bottom;font-weight: bold;">모바일팀_데스크탑_기안서1.pdf<span style="color: #aaa;display:block;">237 KB</span></p>
                            </li>
                            <li class="es_bar"></li>
                            <li>
                                <p class="filepdf_img">아이콘</p>
                                <p style="line-height: 25px;vertical-align: bottom;font-weight: bold;">모바일팀_데스크탑_기안서2.pdf<span style="color: #aaa;display:block;">302 KB</span></p>
                            </li>
                            <li>
                                <p class="filepdf_img">아이콘</p>
                                <p style="line-height: 25px;vertical-align: bottom;font-weight: bold;">모바일팀_데스크탑_기안서3.pdf<span style="color: #aaa;display:block;">302 KB</span></p>
                            </li>
                        </ul> -->
                    </div>

                </div>
            
            </div>

            <div class="CommentBox" v-if="approval.approval_state != 'TEMP' && approval.approval_state != 'CANCEL'">
                <input type="button" value="댓글" class="cmmntBtn" :class="{ on : approval != null && approval.event_reply_id != null && approval.event_reply_id.length > 0 }" @click.prevent="showApprovalReply">
            </div>

            <div class="approver right_app">

                <input id="tab1" class="tab" type="radio" name="tabs" checked>
                <label class="approval_info" style="width:100%;border-right:1px solid rgb(231, 233, 234);" for="tab1">
                    결재현황
                    <p style="display:inline-block;margin-left:30px;"><span style="color:#477fff;">{{ approval.approval_cur }}</span> / {{ approval.approval_total }} <span class="complete_circle" v-if="approval.approval_state == 'COMPLETE'">complete</span></p>
                    
                </label>
                    
                <div id="approver_status_scroll" class="approval_list">
                    <ul class="box_line" style="padding:30px;font-size:15px;">
                        <li :key="`${approver.user_id}-${approver.rev}`" v-for="approver in computedApproverHistory">
                            <span class="tree_line" v-if="computedApproverHistory.indexOf(approver) > 0"></span>
                            
                            
                            <!-- 본인 결재 차례라면 yes_line, 반려 처리되면 cancel_line -->
                            <div class="stand_line" :class="{ 
                                yes_line : approval.approval_rev == approver.rev && approver.state == 'ING', 
                                cancel_line : approval.approval_rev == approver.rev && approver.state == 'REJECT', 
                                past_cancel_line : approval.approval_rev != approver.rev && approver.state == 'REJECT', 
                                hover : approval.approval_rev == approver.rev 
                            }">
                                <span class="text_stand">{{ getApproverStateText(approver.state) }}</span>
                                <p class="on_day">
                                    {{ approver.date != null && approver.date != '' ? `${hodu_date_to_format_string(approver.date, 'YYYY.MM.DD') + " " + getDayOfWeekByDate(approver.date) + " " + amPmStringToLocaleAmPmString(hodu_date_to_format_string(approver.date, 'hh:mm a'))}` : '' }}
                                </p>
                                <!-- <p class="hover_menu"></p> -->
                                
                                <span class="prof" :style="{ 'background-image' : `url(${getProfileImage(approver)})`}">프로필</span>
                                <img class="dummy" :src="getProfileImage(approver)" @error="userImageError($event)"/>

                                <p style="display:inline-block;margin-left: 45px;margin-top: 20px;font-weight:bold;">{{ approver.user_name }} ({{ approver.pos_name }})</p>
                                <span style="display:block;margin-left: 45px;margin-top: 5px;">{{ approver.dept_name }}</span>
                                <p class="comment" v-if="approver.comment != null && approver.comment.length > 0" style="white-space : pre-line;">{{ approver.comment }}</p>
                            </div>
                            <!-- <p class="look">
                                <span class="boxup"></span>
                                <ul class="boxdown">
                                    <li style="border-bottom:1px solid rgb(196, 196, 206);">채팅 하기</li>
                                    <li>확인 요청 푸시 보내기</li>
                                </ul>
                            </p> -->
                        </li>
                    </ul>
                </div>

                <div class="approver_button_area" v-if="approval.approval_state != 'TEMP' && approval.approver.length > 1">
                    <a href="#" class="Appno_btn" v-if="(user_id == approval.user_id || user_id == approval.contents.deputy_user_id) && approval.approval_state == 'REJECT'" @click.prevent="approvalModify">기안서 수정</a>
                    <a href="#" class="Appno_btn2" :class="{ b2 : isCancelable() == true && isMyTurn() == true }" v-if="isCancelable() == true" @click.prevent="cancel">기안서 취소</a>
                    <a href="#" class="App_btn" :class="{ b2 : isCancelable() == true && isMyTurn() == true }" @click.prevent="showApprovalProcessModal" v-if="isMyTurn() == true">결재처리하기</a>
                </div>

            </div>
            
            <!--결재자용 클래스 approver------->
            <div class="approver right_app" v-if="false">
                <input id="tab1" class="tab" type="radio" name="tabs" checked>
                <label class="approval_info" style="width:100%;border-right:1px solid rgb(231, 233, 234);" for="tab1">
                    결재현황
                    <p style="display:inline-block;margin-left:30px;"><span style="color:#477fff;">0</span> / 2</p>
                </label>
                <!--<input id="tab2" class="tab" type="radio" name="tabs"><label class="approval_info" for="tab2">히스토리<span style="display:inline-block;margin-left:30px;" class="new_count">10</span></label> 읽음시 클래스 없앰---->
                <!---결재현황 내용---->
                <div class="approval_list">
                    <ul class="box_line" style="padding:30px;font-size:15px;">
                        <li>
                            <div class="stand_line">
                                <p style="line-height:20px;">2020.09.05 11:00am</p>
                                <p style="line-height:20px;font-weight:bold;">이지인 사원 요청</p>
                            </div>
                        </li>
                        <li>
                            <span class="tree_line"></span>
                            <div class="stand_line">
                                <span class="text_stand">승인</span>
                                <p class="on_day">2020.09.06 03:13pm</p>
                                <span class="prof">프로필</span><p style="display:inline-block;margin-left: 45px;margin-top: 20px;font-weight:bold;">강희혜 (대리)</p><span style="display:block;margin-left: 45px;margin-top: 5px;">경영지원</span>
                            </div>
                        </li>
                        <li class="text_black"><!--현재상태 반려---------->
                            <span class="tree_line"></span>
                            <div class="stand_line cancel_line">
                                <span class="text_app text_stand">반려</span>
                                <p class="on_day">2020.09.06 03:13pm</p>
                                <span class="prof prof_on">프로필</span>
                                <p style="display:inline-block;margin-left: 45px;margin-top: 20px;font-weight:bold;">박기태 (차장)</p>
                                <span style="display:block;margin-left: 45px;margin-top: 5px;">국내 영업</span>
                                <p class="comment">모든 파일에는 기안자 이름이 포함되어야합니다.</p>
                            </div>
                        </li>
                        <li class="text_black"><!--현재상태 요청---------->
                            <span class="tree_line"></span>
                            <div class="stand_line yes_line">
                                <span class="text_app text_stand">요청</span>
                                <p class="on_day">2020.09.06 03:13pm</p>
                                <span class="prof prof_on">프로필</span>
                                <p style="display:inline-block;margin-left: 45px;margin-top: 20px;font-weight:bold;">박기태 (차장)</p>
                                <span style="display:block;margin-left: 45px;margin-top: 5px;">국내 영업</span>
                            </div>
                        </li>
                        <li><!--hover시 우측 점메뉴--->
                            <span class="tree_line"></span>
                            <div class="stand_line hover">
                                <span class="text_stand">승인</span>
                                <p class="on_day"></p>
                                <p class="hover_menu"></p>
                                <span class="prof">프로필</span>
                                <p style="display:inline-block;margin-left: 45px;margin-top: 20px;font-weight:bold;">김성림 (팀장)</p>
                                <span style="display:block;margin-left: 45px;margin-top: 5px;">캐발 / 모바일</span>
                            </div>
                            <p class="look">
                                <span class="boxup"></span>
                                <ul class="boxdown">
                                    <li style="border-bottom:1px solid rgb(196, 196, 206);">채팅 하기</li>
                                    <li>확인 요청 푸시 보내기</li>
                                </ul>
                            </p>
                        </li>
                    </ul>
                    <a href="#" class="App_btn">결재처리하기</a><!---반려처리 수정하기시 class:Appno_btn--->
                </div>
                <!----히스토리---->
            <!--<div class="history">
                    <ul class="box_line" style="font-size:15px;border-left:2px solid rgb(224, 227, 236);">
                        <li style="height: 95px;padding: 0 45px;position: relative;">
                            <span class="red_on">13</span>
                            <p style="line-height:20px;font-weight:bold;">2020.09.06 03:13pm</p>
                            <p style="line-height:20px;">박기태(차장)님이 반려처리하셨습니다.</p>
                        </li>
                    </ul>
                </div>--->
            </div>

            <!--등록자용 클래스 registrant------->
            <div class="registrant right_app" v-if="false">
                <!---결재현황/히스토리 탭--->
                <input id="tab1_1" class="tab" type="radio" name="tabs_0" checked><label class="approval_info"  style="width:100%;border-right:1px solid rgb(231, 233, 234);" for="tab1_1">결재현황<p style="display:inline-block;margin-left:30px;"><span style="color:#477fff;">1</span> / 3</p></label>
                <!---<input id="tab2_1" class="tab" type="radio" name="tabs_0"><label class="approval_info" for="tab2_1">히스토리<span class="new_count" style="display:inline-block;margin-left:30px;">10</span></label>--->
                <!---결재현황 내용---->
                <div class="approval_list">
                    <ul class="box_line" style="padding:30px;font-size:15px;">
                        <li>
                            <div class="stand_line">
                                <span class="prof" style="top:16px;">프로필</span>
                                <p style="line-height:20px;margin-left:45px;font-weight:bold;">2020.09.05 11:00am</p>
                                <p style="line-height:20px;font-weight:bold;margin-left:45px;">이지인(사원) 요청</p>
                            </div>
                        </li>
                        <li>
                            <span class="tree_line"></span>
                            <div class="stand_line">
                                <span class="text_stand">승인</span>
                                <p class="on_day">2020.09.06 03:13pm</p>
                                <span class="prof">프로필</span><p style="display:inline-block;margin-left: 45px;margin-top: 20px;font-weight:bold;">강희혜 (대리)</p><span style="display:block;margin-left: 45px;margin-top: 5px;">경영지원</span>
                            </div>
                        </li>
                        <li class="text_black"><!--현재상태 반려---------->
                            <span class="tree_line"></span>
                            <div class="stand_line cancel_line">
                                <span class="text_app text_stand">반려</span>
                                <p class="on_day">2020.09.06 03:13pm</p>
                                <span class="prof prof_on">프로필</span>
                                <p style="display:inline-block;margin-left: 45px;margin-top: 20px;font-weight:bold;">박기태 (차장)</p>
                                <span style="display:block;margin-left: 45px;margin-top: 5px;">국내 영업</span>
                                <p class="comment">모든 파일에는 기안자 이름이 포함되어야합니다.</p>
                            </div>
                        </li>
                        <li class="text_black"><!--현재상태 요청---------->
                            <span class="tree_line"></span>
                            <div class="stand_line yes_line">
                                <span class="text_app text_stand">요청</span>
                                <p class="on_day">2020.09.06 03:13pm</p>
                                <span class="prof prof_on">프로필</span>
                                <p style="display:inline-block;margin-left: 45px;margin-top: 20px;font-weight:bold;">박기태 (차장)</p>
                                <span style="display:block;margin-left: 45px;margin-top: 5px;">국내 영업</span>
                            </div>
                        </li>
                        <li><!--hover시 우측 점메뉴--->
                            <span class="tree_line"></span>
                            <div class="stand_line hover">
                                <span class="text_stand">대기</span>
                                <p class="on_day"></p>
                                <p class="hover_menu"></p>
                                <span class="prof">프로필</span>
                                <p style="display:inline-block;margin-left: 45px;margin-top: 20px;font-weight:bold;">김성림 (팀장)</p>
                                <span style="display:block;margin-left: 45px;margin-top: 5px;">개발 / 모바일</span>
                            </div>
                            <p class="look">
                                <span class="boxup"></span>
                                <ul class="boxdown">
                                    <li style="border-bottom:1px solid rgb(196, 196, 206);">채팅 하기</li>
                                    <li>확인 요청 푸시 보내기</li>
                                </ul>
                            </p>
                        </li>
                    </ul>
                    <!-------기본 1단 버튼-------->
                    <a href="#" class="Appno_btn" style="display:none;">결재취소하기</a>
                    <!-------반려시 수정이 필요할 경우 2단 버튼-------->
                    <div>
                        <a href="#" class="Appno_btn2" @click.prevent="showApprovalProcessModal">결재취소</a>
                        <!---수정완료시 클래스 App_btn2로 변경(버튼색변경)--->
                        <a href="#" class="stand_btn2">반려자에게<span style="display:block;margin-top:-27px;color:#fff;">수정확인 푸시보내기</span></a>
                    </div>
                </div>
                <!----히스토리---->
            <!--<div class="history">
                    <ul class="box_line" style="font-size:15px;border-left:2px solid rgb(224, 227, 236);">
                        <li style="height: 95px;padding: 0 45px;position: relative;">
                            <span class="red_on">13</span>
                            <p style="line-height:20px;font-weight:bold;">2020.09.06 03:13pm</p>
                            <p style="line-height:20px;">박기태(차장)님이 반려처리하셨습니다.</p>
                        </li>
                    </ul>
                </div>--->
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const ApprovalInfo = namespace('ApprovalInfo');

import { approval_enum, approval_interface, approval_modal_interface } from '@/model/approval';

import JSZip from 'jszip';
import pdf from 'vue-pdf';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        pdf, ResizeObserver
    },
})
export default class ApprovalDetail extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    /**
     * 히스토리 + 현재 결재 상태 합치기
     */
    get computedApproverHistory() : any[] {
        let history : any[] = [];

        if( this.approval != null ) {
            
            if( this.approval['histapprover'] != null ) {
                history = history.concat(this.approval['histapprover']);
            }

            history = history.concat(this.approval.approver);
        }

        return history;
    }

    /**
     * 지출결의서 합계 금액
     */
    get computedTotalPrice() : number { 
        let price : number = 0;

        if( this.approval == null || this.approval.contents == null || this.approval.contents.transactions == null ) return 0;

        for( const transaction of this.approval.contents.transactions ) {
            if( transaction.price != null && String(transaction.price).length > 0 ) {
                const transaction_price = Number(transaction.price);
                if( isNaN(transaction_price) ) {
                    continue;
                }
                price += transaction_price;
            }
        }

        return price;
    }

    /**
     * 지출결의서 영수증 파일 개수
     */
    get computedTransactionFileLength() : number {
        return this.approval != null && this.approval.contents != null && this.approval.contents.transaction_files != null ? this.approval.contents.transaction_files.length : 0;
    }

    /**
     * 기안서 파일 개수
     */
     get computedFreeFileLength() : number {
        return this.approval != null && this.approval.contents != null && this.approval.contents.files != null ? this.approval.contents.files.length : 0;
    }
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalMessageModalInfo ?: (params : approval_modal_interface.ApprovalMessageModalInfo) => void;
    @ModalInfo.Action doSetApprovalProcessModalInfo ?: (params : approval_modal_interface.ApprovalProcessModalInfo) => void;
    @ModalInfo.Action doSetApprovalReplyModalInfo ?: (params : approval_modal_interface.ApprovalReplyModalInfo) => void;

    /**
     * @ApprovalInfo.Action
     */
    @ApprovalInfo.Action doSetApproval ?: (params : approval_interface.t_approval) => void;

    approval_uid : string = "";
    approval : approval_interface.t_approval | null = null;

    // current_page : number[] = [];
    // page_count : number[] = [];

    pdf_infos : any[] = [];
    none_pdf_files : any[] = [];

    group_id : number = 0;

    templates : approval_interface.ApprovalTemplateBundle[] = [];

    beforeMount() {
        this.group_id = Number(this.$route.params.scope_id);
        this.approval_uid = this.$route.params.approval_id;
    }

    async mounted() : Promise<void> {
        $(".hover_menu").click(function(){
			$(this).parent().siblings('.look').toggleClass("open");
        });

        // this.showApprovalMessageModal();
        await this.getSelectedApprovalInfo();

        //영수증파일 팝업
        // $('.receiptAddBtn').click(function(){
        //     $('.filePopup').fadeIn(200);
        //     return false;
        // })
        // $('.filePopup button.filePopup_close').click(function(){
        //     $('.filePopup').fadeOut()
        // })
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const reply_height = $('.CommentBox').outerHeight();
        const approver_title_height = $('.approver label.approval_info').outerHeight();
        const approver_button_height = $('.approver .approver_button_area').outerHeight();
        

        // @ts-ignore
        $('#approval_detail_left_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - ((reply_height && this.approval != null && this.approval.approval_state != 'TEMP') ? reply_height : 0),
        });

        // @ts-ignore
        $('#approver_status_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0)
                                           - (approver_title_height ? approver_title_height : 0)
                                           - (approver_button_height ? approver_button_height : 0),
        });
    }

    /**
     * 기안서 조회
     */
    async getSelectedApprovalInfo() : Promise<void> {

        this.pdf_infos.splice(0, this.pdf_infos.length);
        this.none_pdf_files.splice(0, this.none_pdf_files.length);
        
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval/${this.approval_uid}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("기안서 조회 중 오류 발생");
            }

            const approval_info = response.data.data.approval_info;

            if( approval_info.contents != null && approval_info.contents.files != null ) {
                
                for( const file of approval_info.contents.files ) {
                    try {

                        if ( file.name.indexOf(".pdf") > -1 && (file.name.length - ".pdf".length) == file.name.indexOf(".pdf") ) {

                            // @ts-ignore
                            const loadingTask = pdf.createLoadingTask(`app_images/${file.url}`);
                            await loadingTask.promise.then(pdf_info => { 
                                this.pdf_infos.push({
                                    file : file,
                                    page : 1,
                                    pages : pdf_info._pdfInfo.numPages,
                                });
                            });

                        }
                        else {
                            this.none_pdf_files.push(file);
                        }
                        
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    }
                }
            }

            this.approval = JSON.parse(JSON.stringify(approval_info));
            
            if( this.approval != null && !((this.approval.user_id == this.user_id) || (this.approval.contents.deputy_user_id == this.user_id)) && (this.approval.approval_state == approval_enum.APPROVAL_STATE.CANCEL || this.approval.approval_state == approval_enum.APPROVAL_STATE.TEMP) ) {
                this.movePrevPage();
                return;
            }

            // 양식 기안서면 양식이 현재 존재하는지 체크 (복사 가능여부 체크를 위해서임)
            if( this.approval != null && this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ) {
                try {
                    const template_response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class?include_templates=${true}`, API_METHOD.GET, null, false);
                    
                    console.log(template_response);

                    if( !template_response || !this.isHttpStatusSuccess(template_response.status) || !template_response.data || !template_response.data.data ) {
                        throw new Error("전자결재 양식 정보 조회 중 오류 발생");
                    }

                    const approval_templates = template_response.data.data.approval_templates;

                    this.templates.splice(0, this.templates.length);
                    this.templates = this.templates.concat(approval_templates.filter(template_class => template_class.template_state == '0' || template_class.template_state == '10'));

                } catch(e) {
                    throw e;
                }
            }

            await this.$nextTick();
            
            this.setScroll();

        } catch(e) {
            this.hodu_show_dialog('cancel', '기안서 조회 중 오류 발생', ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }

        this.$nextTick(() => this.handleResize());
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : number | string | undefined) : string {

        if( day_count == null ) return '';
        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 휴가일자 텍스트 반환
     */
    getVacationText() : string {
        
        if( this.approval == null ) return "";

        let str = "";

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION ) {
            
            if( this.approval.contents.vacation_start != null && (this.approval.contents.vacation_start as string).length > 0 &&
                this.approval.contents.vacation_end != null && (this.approval.contents.vacation_end as string).length > 0 ) {
                const start = String(this.approval.contents.vacation_start);
                const end   = String(this.approval.contents.vacation_end);

                if( start == null || end == null ) return "";

                const start_date = new Date([start.substring(0, 4), start.substring(4, 6), start.substring(6, 8)].join('-'));
                const end_date   = new Date([end.substring(0, 4), end.substring(4, 6), end.substring(6, 8)].join('-'));

                if( start == end ) {
                    
                    str = `${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;
                    
                    if( this.approval.contents.vacation_type_daycount != null && this.approval.contents.vacation_start_time != null && this.approval.contents.vacation_end_time != null && 
                        // this.approval.contents.vacation_type_daycount > 0 && this.approval.contents.vacation_type_daycount < 1 ) {
                        this.approval.contents.vacation_type_daycount == 0.5 ) {
                            
                        let start_hour = Number(this.approval.contents.vacation_start_time.substring(0,2));
                        let start_min = Number(this.approval.contents.vacation_start_time.substring(2,4));
                        let start_am_pm = 'AM';

                        if( start_hour >= 12 ) start_am_pm = "PM";
                        if( start_hour >  12 ) start_hour -= 12;
                        if( start_hour == 0  ) start_hour = 12;

                        let end_hour = Number(this.approval.contents.vacation_end_time.substring(0,2));
                        let end_min = Number(this.approval.contents.vacation_end_time.substring(2,4));
                        let end_am_pm = 'AM';

                        if( end_hour >= 12 ) end_am_pm = "PM";
                        if( end_hour >  12 ) end_hour -= 12;
                        if( end_hour == 0  ) end_hour = 12;

                        str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                    }

                }
                else {
                    str = `${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;
                    str += " ~ ";
                    str += `${this.hodu_date_to_format_string(end_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(end_date)}`;
                }
            }   

            // 5.4.2 부터는 vacation_selected로 만들어야함
            else if( this.approval.contents.vacation_selected != null ) {
                for( const selected_date of this.approval.contents.vacation_selected ) {
                    if( str.length > 0 ) str += ", ";
                    const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
                    str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
                }

                if( this.approval.contents.vacation_selected.length == 1 && this.approval.contents.vacation_type_daycount != null &&
                    this.approval.contents.vacation_start_time != null && this.approval.contents.vacation_end_time != null ) {
                    // if( this.approval.contents.vacation_type_daycount > 0 && this.approval.contents.vacation_type_daycount < 1 ) {
                    if( this.approval.contents.vacation_type_daycount == 0.5 ) {
                        let start_hour = Number(this.approval.contents.vacation_start_time.substring(0,2));
                        let start_min = Number(this.approval.contents.vacation_start_time.substring(2,4));
                        let start_am_pm = 'AM';

                        if( start_hour >= 12 ) start_am_pm = "PM";
                        if( start_hour >  12 ) start_hour -= 12;
                        if( start_hour == 0  ) start_hour = 12;

                        let end_hour = Number(this.approval.contents.vacation_end_time.substring(0,2));
                        let end_min = Number(this.approval.contents.vacation_end_time.substring(2,4));
                        let end_am_pm = 'AM';

                        if( end_hour >= 12 ) end_am_pm = "PM";
                        if( end_hour >  12 ) end_hour -= 12;
                        if( end_hour == 0  ) end_hour = 12;

                        str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                    }
                }
            }

        }

        return str;
    }

    /**
     * 출장일자 텍스트 반환
     */
    getBusinessTripText() : string {

        if( this.approval == null ) return "";

        let str = "";

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {
            
            // 5.4.2 부터는 vacation_selected로 만들어야함
            if( this.approval.contents.vacation_selected != null ) {
                for( const selected_date of this.approval.contents.vacation_selected ) {
                    if( str.length > 0 ) str += ", ";
                    const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
                    str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
                }

                if( this.approval.contents.vacation_selected.length == 1 && this.approval.contents.vacation_type_daycount != null &&
                    this.approval.contents.vacation_start_time != null && this.approval.contents.vacation_end_time != null ) {
                    // if( this.approval.contents.vacation_type_daycount > 0 && this.approval.contents.vacation_type_daycount < 1 ) {
                    if( this.approval.contents.vacation_type_daycount == 0.5 ) {
                        let start_hour = Number(this.approval.contents.vacation_start_time.substring(0,2));
                        let start_min = Number(this.approval.contents.vacation_start_time.substring(2,4));
                        let start_am_pm = 'AM';

                        if( start_hour >= 12 ) start_am_pm = "PM";
                        if( start_hour >  12 ) start_hour -= 12;
                        if( start_hour == 0  ) start_hour = 12;

                        let end_hour = Number(this.approval.contents.vacation_end_time.substring(0,2));
                        let end_min = Number(this.approval.contents.vacation_end_time.substring(2,4));
                        let end_am_pm = 'AM';

                        if( end_hour >= 12 ) end_am_pm = "PM";
                        if( end_hour >  12 ) end_hour -= 12;
                        if( end_hour == 0  ) end_hour = 12;

                        str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                    }
                }
            }

        }

        return str;

    }

    /**
     * 근무일자 텍스트 반환
     */
    getHolidayWorkText() : string {

        if( this.approval == null ) return "";

        let str = "";

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ) {
            
            // 5.4.2 부터는 vacation_selected로 만들어야함
            if( this.approval.contents.vacation_selected != null ) {
                for( const selected_date of this.approval.contents.vacation_selected ) {
                    if( str.length > 0 ) str += ", ";
                    const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
                    str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
                }

                if( this.approval.contents.vacation_selected.length == 1 && this.approval.contents.vacation_type_daycount != null &&
                    this.approval.contents.vacation_start_time != null && this.approval.contents.vacation_end_time != null ) {
                    // if( this.approval.contents.vacation_type_daycount > 0 && this.approval.contents.vacation_type_daycount < 1 ) {
                    if( this.approval.contents.vacation_type_daycount == 0.5 ) {
                        let start_hour = Number(this.approval.contents.vacation_start_time.substring(0,2));
                        let start_min = Number(this.approval.contents.vacation_start_time.substring(2,4));
                        let start_am_pm = 'AM';

                        if( start_hour >= 12 ) start_am_pm = "PM";
                        if( start_hour >  12 ) start_hour -= 12;
                        if( start_hour == 0  ) start_hour = 12;

                        let end_hour = Number(this.approval.contents.vacation_end_time.substring(0,2));
                        let end_min = Number(this.approval.contents.vacation_end_time.substring(2,4));
                        let end_am_pm = 'AM';

                        if( end_hour >= 12 ) end_am_pm = "PM";
                        if( end_hour >  12 ) end_hour -= 12;
                        if( end_hour == 0  ) end_hour = 12;

                        str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                    }
                }
            }

        }

        return str;

    }

    /**
     * 수신 참조 이름 반환
     */
    getReceiveReference() : string {

        if( this.approval == null ) return '';

        try {
            if( this.approval.receive_reference == null || this.approval.receive_reference.length < 1 ) {
                return "없음";
            } 

            let str = "";

            for( const ref of this.approval.receive_reference ) {
                if( str.length > 0 ) str += ", ";
                str += ref.user_name;
            }

            return str;

        } catch(e) {
            return "없음";
        }
    }
    
    /**
     * 파일 전체 다운로드
     */
    async downloadAll() : Promise<void> {

        if( this.approval == null ) return;

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC || this.approval.approval_type == approval_enum.APPROVAL_TYPE.FREE ) {

            // 파일 1개라면
            if( this.approval.contents.files.length == 1 ) {
                const file = this.approval.contents.files[0];
                this.hodu_download(`app_images/${file.url}`, file.name);
                return;
            }

            // 파일 여러개는 zip 파일로 받는다

            await this.hodu_show_indicator();

            const zip = new JSZip();

            const file_promise : Promise<void>[] = [];

            for( const file of this.approval.contents.files ) {

                // 파일 다운로드 Promise
                const promise = this.hodu_download(`/app_images/${file.url}`, "", true)
                    .then((response) => {
                        console.log(response);

                        // 다운로드한 파일은 zip 파일 구성에 추가
                        zip.file(file.name, new Blob([response.data]));
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });

                // 병렬처리용 Promise 배열에 넣기
                file_promise.push(promise);
            }

            Promise.all(file_promise)
                .then(async() => {

                    // zip 파일 압축
                    await zip.generateAsync({type:"blob"}).then((content) => {
                        
                        if( this.approval == null ) return;
                        
                        // 압축 완료시 나오는 blob으로 다운로드
                        try {
                            this.hodu_download_blob(content, `기안서_${this.approval.contents.title}_${new Date().getTime()}.zip`);
                        } catch(e) {
                            this.hodu_error_process(e, true, false);
                        }

                    });
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                }).finally(async() => {
                    await this.hodu_hide_indicator();
                });

        }

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER && this.approval.contents.transaction_files != null ) {

            // 파일 1개라면
            if( this.approval.contents.transaction_files.length == 1 ) {
                const file = this.approval.contents.transaction_files[0];
                this.hodu_download(`app_images/${file.url}`, file.name);
                return;
            }

            // 파일 여러개는 zip 파일로 받는다

            await this.hodu_show_indicator();

            const zip = new JSZip();

            const file_promise : Promise<void>[] = [];

            for( const file of this.approval.contents.transaction_files ) {

                // 파일 다운로드 Promise
                const promise = this.hodu_download(`/app_images/${file.url}`, "", true)
                    .then((response) => {
                        console.log(response);

                        // 다운로드한 파일은 zip 파일 구성에 추가
                        zip.file(file.name, new Blob([response.data]));
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });

                // 병렬처리용 Promise 배열에 넣기
                file_promise.push(promise);
            }

            Promise.all(file_promise)
                .then(async() => {

                    // zip 파일 압축
                    await zip.generateAsync({type:"blob"}).then((content) => {
                        
                        if( this.approval == null ) return;
                        
                        // 압축 완료시 나오는 blob으로 다운로드
                        try {
                            this.hodu_download_blob(content, `${this.approval.contents.title}_${new Date().getTime()}.zip`);
                        } catch(e) {
                            this.hodu_error_process(e, true, false);
                        }

                    });
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                }).finally(async() => {
                    await this.hodu_hide_indicator();
                });
        }
    }

    /**
     * 결재자 상태값 텍스트
     */
    getApproverStateText(state : approval_enum.APPROVAL_APPROVER_STATE) : string {

        switch(state) {
            case approval_enum.APPROVAL_APPROVER_STATE.REQUEST:
                return "요청";

            case approval_enum.APPROVAL_APPROVER_STATE.CANCEL:
                return "취소";

            case approval_enum.APPROVAL_APPROVER_STATE.WAIT:
                return "대기";

            case approval_enum.APPROVAL_APPROVER_STATE.ING:
                return "진행";

            case approval_enum.APPROVAL_APPROVER_STATE.CONFIRM:
                return "승인";

            case approval_enum.APPROVAL_APPROVER_STATE.REJECT:
                return "반려";
        }

        return "";
    }

    /**
     * 전자결재 메시지 모달 띄우기
     */
    // showApprovalMessageModal() : void {
    //     this.doSetApprovalMessageModalInfo?.({ show_modal : true });
    // }

    /**
     *전자결재 처리 모달 띄우기
    */
    async showApprovalProcessModal() : Promise<void> {

        if( this.approval == null ) return;

        await this.getSelectedApprovalInfo();

        const my_infos = this.approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);

        if( my_infos == null || my_infos.length < 1 ) {
            this.hodu_show_dialog("cancel", "결재처리를 완료 할 수 없습니다\n이미 처리됐거나 대상이 아닙니다", ["확인"]);
            return;
        }

        this.doSetApprovalProcessModalInfo?.({ 
            show_modal : true,
            callback : async(state : approval_enum.APPROVAL_APPROVER_STATE, reject_message : string) => {
                try {

                    if( this.approval == null ) return;

                    await this.getSelectedApprovalInfo();

                    const my_infos = this.approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);
                    
                    if( my_infos == null || my_infos.length < 1 ) {
                        this.hodu_show_dialog("cancel", "결재처리를 완료 할 수 없습니다\n이미 처리됐거나 대상이 아닙니다", ["확인"]);
                        return;
                    }

                    const my_info = my_infos[0];
                    my_info.state = state;
                    my_info.date = new Date();

                    const target_index = this.approval.approver.indexOf(my_info);
                    
                    // 두번 눌리는듯한 버그가 있어서 버그 방지
                    if( (this.approval.approval_cur + 1) > this.approval.approval_total ) {
                        return;
                    }

                    this.approval.approval_cur = this.approval.approval_cur + 1;
                    
                    // 반려처리라면 문서 자체도 반려 상태로 바꿈
                    if( state == approval_enum.APPROVAL_APPROVER_STATE.REJECT ) {
                        this.approval.approval_state = approval_enum.APPROVAL_STATE.REJECT;
                        my_info.comment = reject_message;
                    }

                    // 마지막 승인이라면 기안서 자체를 완료 처리
                    else if( target_index == (this.approval.approver.length - 1) && state == approval_enum.APPROVAL_APPROVER_STATE.CONFIRM ) {
                        this.approval.approval_state = approval_enum.APPROVAL_STATE.COMPLETE;
                    }

                    else if( target_index != (this.approval.approver.length - 1) && state == approval_enum.APPROVAL_APPROVER_STATE.CONFIRM ) {
                        this.approval.approver[target_index + 1].state = approval_enum.APPROVAL_APPROVER_STATE.ING;
                    }

                    this.approval.approver.splice(target_index, 1, my_info);

                    // 수정 API
                    const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval/${this.approval_uid}`, API_METHOD.PUT, this.approval);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("기안서 수정 중 오류 발생");
                    }

                } catch(e) {
                    this.hodu_show_dialog('cancel', '기안서 결재 처리 중 오류 발생', ['확인']);
                    this.hodu_error_process(e, false, false, true);
                } finally {
                    await this.getSelectedApprovalInfo();
                } 

            } 
        });
    }

    isCancelable() : boolean {
        if( this.approval == null ) return false;
        return (this.user_id == this.approval.user_id || this.user_id == this.approval.contents.deputy_user_id) && (this.approval.approval_state == 'ING' || this.approval.approval_state == 'TEMP') && this.approval.approver[1].state == 'ING';
    }

    /**
     * 본인의 결재 차례라면
     */
    isMyTurn() : boolean {
        if( this.approval == null ) return false;
        const my_infos = this.approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);
        if( my_infos == null || my_infos.length < 1 ) return false;
        return true;
    }

    /**
     * 임시 저장한 기안서 삭제
     */
    approvalDelete() : void {
        this.hodu_show_dialog('cancel', "정말로 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval/${this.approval_uid}`, API_METHOD.DELETE);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("기안서 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "기안서 삭제 중 오류 발생", ['확인'], [() => this.getSelectedApprovalInfo()]);
                }
            }
        ])
    }

    /**
     * 임시 저장한 기안서 / 반려된 기안서 수정
     */
    approvalModify() : void {
        if( this.approval == null ) return;
        this.hodu_router_push(`/${this.scope}/${this.group_id}/approval/create/${this.approval.approval_uid}`);
    }

    /**
     * 복사
     */
    async approvalCopy() {
        if( this.approval == null ) return;

        const copy_approval = JSON.parse(JSON.stringify(this.approval));

        console.log(copy_approval);

        copy_approval.is_copy = true;
        copy_approval.approver = [];
        copy_approval.receive_reference = [];

        this.doSetApproval?.(copy_approval);
        this.hodu_router_push(`/${this.scope}/${this.group_id}/approval/create`);
    }

    /**
     * 기안서 취소하기
     */
    async cancel() : Promise<void> {

        this.hodu_show_dialog("alert", "기안서를 취소 처리 하시겠습니까?", ["아니오", "예"], [() => {}, async() => {
            try {

                if( this.approval == null ) return;

                await this.getSelectedApprovalInfo();

                if( (this.approval.approval_state != approval_enum.APPROVAL_STATE.ING && this.approval.approval_state != approval_enum.APPROVAL_STATE.TEMP) || this.approval.approver[1].state != approval_enum.APPROVAL_APPROVER_STATE.ING ) {
                    this.hodu_show_dialog("cancel", "기안서 취소를 할 수 없습니다\n기안서가 이미 처리 됐습니다", ["확인"]);
                    return;
                }

                this.approval.approval_state = approval_enum.APPROVAL_STATE.CANCEL;
                this.approval.approver[0].state = approval_enum.APPROVAL_APPROVER_STATE.CANCEL;
                this.approval.approver[1].state = approval_enum.APPROVAL_APPROVER_STATE.WAIT;

                // 수정 API
                const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval/${this.approval_uid}`, API_METHOD.PUT, this.approval);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("기안서 취소 중 오류 발생");
                }

            } catch(e) {
                this.hodu_show_dialog('cancel', '기안서 취소 처리 중 오류 발생', ['확인']);
                this.hodu_error_process(e, false, false, true);
            } finally {
                await this.getSelectedApprovalInfo();
            }
        }]);
        
    }

    /**
     * 작성시 연차수 표시여부
     */
    isDenoteAnnualCount() : boolean {
        const feature = this.get_group_feature(this.group_id);
        return feature != null ? feature['approval']['is_denote_annual_count'] : false;
    }

    /**
     * 휴가원, 출장 신청서 프린트
     */
    printApproval() : void {

        let query = ``;
        query += `group_id=${this.group_id}`;
        query += `&approval_uid=${this.approval_uid}`;

        const timezone = -(new Date().getTimezoneOffset());
        query += `&timezone=${timezone ? timezone : 540}`;

        if( this.approval != null && this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ) {
            for( const file of this.approval.contents.files ) {
                query += `&pdf_path=${encodeURIComponent("app_images/")}${encodeURIComponent(file.url)}`;
            }
        }

        if( this.approval != null && this.approval.contents != null && this.approval.contents.transactions != null && this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {
            
            let bottom_page = 0;

            // line_count 계산
            if( this.approval.line_count != null && this.approval.top_page_count != null ) {

                // 총 페이지가 1페이지인 경우
                if( this.approval.top_page_count == 1 ) {
                    bottom_page = Math.ceil((this.approval.contents.transactions.length - 5) / 12);
                }
                else {

                    // 마지막 페이지가 지출내역만 따로 있는 경우
                    if( (this.approval.line_count - 30) % 50 == 0 || (this.approval.line_count - 30) % 50 > 30 ) {
                        bottom_page = Math.ceil((this.approval.contents.transactions.length - 12) / 12);
                        query += `&is_long_transactions=true`;
                    }
                    // 마지막 페이지가 내용 + 지출내역인 경우
                    else {
                        bottom_page = Math.ceil((this.approval.contents.transactions.length - 5) / 12);
                    }
                    
                }

            }
            else {
                bottom_page = Math.ceil((this.approval.contents.transactions.length - 5) / 12);
            }
            
            query += `&bottom_page=${bottom_page}`;
        }

        if( this.approval != null && this.approval.is_long_comment != null ) {
            query += `&is_long_comment=${this.approval.is_long_comment}`;
        }

        if( this.approval != null && this.approval.top_page_count != null ) {
            query += `&top_page_count=${this.approval.top_page_count}`;
        }
        
        window.open(`/approval_pdf.html?${query}`, "work_pdf", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);

    }

    fadeInTransactionFile() {
        $('.filePopup').fadeIn(200);
    }

    fadeOutTransactionFile() {
        $('.filePopup').fadeOut();
    }

    isCopyable() {

        if( this.approval == null ) {
            return false;
        }

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ) {

            if( this.templates == null || this.templates.length < 1 || this.approval.contents.files == null || this.approval.contents.files.length < 1 ) {
                return false;
            }

            for( const file of this.approval.contents.files ) {
                let is_find_template : boolean = false;

                for( const template of this.templates ) {
                    
                    if( template.files == null || template.files.length < 1 ) {
                        continue;
                    }

                    for( const template_file of template.files ) {

                        // 저장이 반대로 된지 오래되서 반대로 비교해야함..
                        if( template_file.template_class_id == file.template_id && template_file.template_id == file.template_class_id ) {
                            is_find_template = true;
                        }

                        if( is_find_template == true ) {
                            break;
                        }
                    }

                    if( is_find_template == true ) {
                        break;
                    }

                }

                // 한번이라도 양식을 찾지 못한게 있다면 그대로 복사 불가 
                if( is_find_template == false ) {
                    return false;
                }
            }

            return true;
        }
        
        return true;
    }

    /**
     * 전자결재 댓글
     */
    showApprovalReply() {
        this.doSetApprovalReplyModalInfo?.({
            show_modal : true,
            group_id : this.group_id,
            approval_uid : this.approval_uid,
            callback : this.getSelectedApprovalInfo
        })
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('span.prof').css('background-image', require('@/assets/images/contents/ic_approval_user.png'));
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#approval_detail_left_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#approver_status_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>
<style scoped>
.section_ce_fix .title_box {position: relative;}
ul.flie_box{box-sizing: border-box;width: 98%;margin: 10px auto;}
ul.flie_box li{line-height: 50px; display: inline-block;width: 40%;margin:1% 4%; box-sizing: border-box;}
ul.flie_box li.es_bar{border-left: 1px solid rgb(231, 233, 234);width: 2%;height: 30px;margin:0;vertical-align: middle;}
ul.flie_box li p{display: inline-block;}
ul.flie_box li p.flie_icon{height:50px;width:50px;background: #f1f3f5;border-radius: 8px;}
ul.flie_box li p.filedoc_img{height:50px;width:50px;font-size: 0;border-radius: 8px;background:#f1f3f5 url(../../assets/images/contents/ic_docs_doc.png) no-repeat;background-size: 25px;background-position: center;margin-right: 15px;}
ul.flie_box li p.filepdf_img{height:50px;width:50px;font-size: 0;border-radius: 8px;background:#f1f3f5 url(../../assets/images/contents/ic_docs_pdf.png) no-repeat;background-size: 25px;background-position: center;margin-right: 15px;}
ul.flie_box li p.filehwp_img{height:50px;width:50px;font-size: 0;border-radius: 8px;background:#f1f3f5 url(../../assets/images/contents/ic_docs_hwp.png) no-repeat;background-size: 25px;background-position: center;margin-right: 15px;}
table.Approval_table{ width:95%;padding:0;border:0;border-spacing:0px;border-collapse:collapse;}
table.Approval_table th{text-align: left;font-size: 18px;}
table.Approval_table th span.quick{color:#fff;vertical-align: bottom;border-radius:3px;background-color:#ff6363;padding:2px 8px;margin-right:15px;font-size: 14px;font-weight: normal;}
table.Approval_table th span.important {color:#fff;vertical-align: bottom;border-radius:3px;background-color:#ffc72f;padding:2px 8px;margin-right:15px;font-size: 14px;font-weight: normal;}
table.Approval_table th,table.Approval_table td{padding:15px;line-height: 30px;}
table.Approval_table tbody{font-size:14px;}
.Approval_flle{margin: 0 auto; width:90%;padding:0;border:0;position: relative;padding: 15px;line-height: 15px;border-bottom:1px solid #e7e9ea; margin: 30px auto 0;box-sizing: border-box;font-weight:bold;}
.cen_text{width:15%; text-align: center;}
.right_text{font-weight: bold;}
.right_bor{border-right:1px solid #e7e9ea;}
.approval_info{margin: 0;padding: 0;background: none;color: rgb(162, 162, 162); border-bottom:2px solid rgb(231, 233, 234);width:50%;height:60px;text-align:center;line-height:60px;display:inline-block;box-sizing: border-box;font-weight: bold; cursor : default; }
.set{border-bottom:2px solid #477fff;color:rgb(007, 007, 007);}
.prof{display: block;position: absolute;left: 15px;top: 50px; font-size: 0;width: 35px;height: 35px;background:url(../../assets/images/contents/ic_approval_user.png) no-repeat;background-size: 35px; border-radius: 50%;}
.prof_on{background:url(../../assets/images/contents/ic_approval_user_on.png) no-repeat;background-size: 35px;}
.left_main{width: calc(100% - 360px);height:100%;border-right: 1px solid #e7e9ea;box-sizing:border-box;}
.left_area_close .left_main { padding-left: 60px; }
.left_main_content{ padding:20px; }
.right_app{width: 360px;height:100%;border-right: 1px solid #e7e9ea;position: absolute;top: 0;right: 0;}
.approval_list .box_line >li{min-width:230px;max-width: 310px;margin: 0 auto;position: relative;}
.approval_list .box_line >li div span, .approval_list .box_line li div p{color: rgb(167, 169, 183);}
.approval_list .box_line >li div.cancel_line span, .approval_list .box_line li div.cancel_line p, .approval_list .box_line >li div.yes_line span, .approval_list .box_line li div.yes_line p{ color: #232848; }
.approval_list .box_line >li div.cancel_line span.text_stand { color : #FF6363; }
.approval_list .box_line >li div.yes_line span.text_stand { color : #477fff; }
.approver .approver_button_area { left: 0;font-size:18px;position: absolute;width: 100%; }
/*승인시*/a.App_btn{display:block; height:60px;line-height:60px;font-size:18px;background:#477fff;color:#fff;width: 100%;text-align: center;}
/*반려.취소시*/a.Appno_btn{display:block;height:60px;line-height:60px;font-size:18px;background:rgb(255, 094, 094);color:#fff;width: 100%;text-align: center;}
/*승인시 반쪽버튼*/a.App_btn2{display:block;height:60px;line-height:60px;font-size:14px;background:#477fff;color:#fff;width: 50%;text-align: center;}
/*반려.취소시 반쪽버튼*/a.Appno_btn2{display:block;height:60px;line-height:60px;font-size:18px;background:rgb(255, 094, 094);color:#fff;width: 100%;text-align: center;}
a.stand_btn2{display:block;right: 0;height:60px;line-height:45px;font-size:14px;background:rgb(204, 206, 214);color:#fff;position: absolute;width: 50%;bottom: 60px;text-align: center;}
.history{background: rgb(241, 243, 245);display:none;width:100%;height: calc(100% - 60px);background:c;position: relative;padding: 50px 0 50px 50px;box-sizing: border-box;}
.history .box_line li span.red_on{border:1px solid rgb(216, 044, 090);background:rgb(255, 063, 090);color:  #fff;}
.history .box_line li span{position: absolute;top: 0;left: -18px;border:2px solid rgb(224, 227, 236);background: rgb(241, 243, 245);height: 30px;width: 30px;border-radius: 18px;font-size: 15px;line-height: 30px;color:  rgb(160, 162, 177);text-align: center;}
.approval_list{display:none;width:100%;height: calc(100% - 60px);background:rgb(241, 243, 245);position: relative;}
input.tab{display: none;}
input:checked + label.approval_info {background: none;border-bottom:2px solid #477fff;color:rgb(007, 007, 007);}
label {display: inline-block;margin: 0 0 -1px;padding: 15px 25px;font-weight: 600;text-align: center;color: #bbb;border: 1px solid transparent;background: none;}
#tab1:checked ~ .approval_list, #tab2:checked ~ .history, #tab1_1:checked ~ .approval_list, #tab2_1:checked ~ .history{display: block;}
.tree_line{display: block;margin-left:35px;height:25px;width:2px;background:rgb(208, 212, 227);}
.stand_line{border:2px solid rgb(208, 212, 227);padding:15px;border-radius:7px;position: relative;}
.yes_line{border:2px solid #477fff;background:#fff;}/*결재요청시 */
.cancel_line{border:2px solid rgb(255, 005, 028);background:#fff;}/*반려시*/
.past_cancel_line{border:2px solid rgb(255, 005, 028);}
.comment{ margin-left: 45px;margin-top: 10px;padding: 10px;background: #e4e6e8; color: rgb(167, 169, 183); border-radius: 0 8px 8px 8px;font-weight: bold;line-height: 20px;font-size:14px; }
.yes_line .comment, .cancel_line .comment{ background: #f1f3f5; }
.on_day{display:inline-block;margin-left: 12px;font-weight: bold;width: 200px;}
.text_black span, .text_black p{color:rgb(038, 038, 038) !important;}
.fever{width: 28px;height: 28px;background:url(../../assets/images/contents/ic_kstar_off.png) no-repeat;background-size: 30px;display:inline-block;padding:0;margin: 0;}
input#fever:checked + label.fever{background:url(../../assets/images/contents/ic_kstar_on.png)no-repeat;background-size: 28px;}
.hover:hover > .hover_menu{display: block;}
.hover{background: #fff;}
.hover_menu{cursor: pointer; display:none;position: absolute; top: 10px; right: 17px; width: 25px;height: 25px;background:url(../../assets/images/contents/ic_hover_more.png) no-repeat;background-size: 25px;}
.look{display: none;text-align: right;position: absolute; top: 64px; right: 30px;}
.open{display: block;}
span.boxup{width: 0px;height: 0px;border-bottom: 15px solid rgb(085, 086, 116, 0.9);border-left: 15px solid transparent;display: inline-block;text-align: right;}
ul.boxdown{width: 180px;height: 80px;background:rgb(085, 086, 116, 0.9);border-radius: 10px 0 10px 10px; display: block;margin-top: -2px;text-align: center}
ul.boxdown li{width:180px;line-height: 40px;color: #fff;font-size: 14px;font-weight: bold;cursor: pointer;}
.new_count{line-height: normal;background:rgb(255, 063, 090);width: 35px;height: 20px;border-radius: 15px;color: #fff;}
.cancel_line .text_app{color: rgb(255, 005, 028)!important;}
.yes_line .text_app{color: #477fff !important;}
.text_stand{width: 35px;display: inline-block;text-align: center;font-weight: bold;}
.Delete{position: absolute;top: 10px;right: 90px;color:rgb(083, 084, 111);border: 1px solid #e7e9ea;height: 35px;line-height: 35px;width: 55px;text-align: center;font-size: 14px;font-weight: bold;border-radius: 5px;}
.Modify, .Print{position: absolute;top: 10px;right: 20px;color:rgb(083, 084, 111);border: 1px solid #e7e9ea;height: 35px;line-height: 35px;width: 55px;text-align: center;font-size: 14px;font-weight: bold;border-radius: 5px;}

/* .section_ce_fix ul.flie_box li .file_donwload { display : none; font-size: 0; width:35px;height: 35px; background:url(../../assets/images/contents/ic_fileDownload.png) no-repeat center #477fff; background-size: 25px; vertical-align: bottom; margin-bottom : 8px; border-radius: 50%; margin-left: 5px;}
.section_ce_fix ul.flie_box li:hover .file_donwload { display : inline-block; } */

.hodu-pdf-container { position: relative; width: 90%; padding: 15px; margin: 0 auto 0; }
.hodu-pdf-container .hodu-pdf-title { display : inline-block; width : 100%; font-weight: bold; font-size: 20px; text-align: center; margin : 20px 0; }
.hodu-pdf-container .hodu-pdf-title > .file_donwload { position: absolute; display : inline-block; font-size: 0; width:35px;height: 35px; background:url(../../assets/images/contents/ic_fileDownload.png) no-repeat center #477fff; background-size: 25px; vertical-align: bottom; margin-bottom : 8px; border-radius: 50%; margin-left: 5px; top: 28px; right: 15px;}
.hodu-pdf-container > span { border : 1px solid #f1f3f5; margin-top : 20px; }

.approver .approval_info .complete_circle { position: absolute; right: 36.5px; top: 21.5px; height: 17px; width: 17px; font-size: 0; border-radius: 50%; background: #477fff; }

/* 일반파일. 결재파일 구분 */
.Approval_flle .fileAllCount { float: right; margin-right: 75px; font-size: 13px; opacity: .8; }
.Approval_flle .fileAllCount:after { content: ''; width: 2px; height: 15px; display: inline-block; background: #e7e9ea; vertical-align: middle; margin-left: 9px; }
.hodu-none-pdf-container { width: 90%; margin: 15px auto 0 auto; }
.hodu-none-pdf-container p { line-height: 20px; }
.hodu-none-pdf-container p .attach_img { display: inline-block; width: 19px; height: 19px; background: url('../../assets/images/contents/ic_file_bk.png') no-repeat center; background-size: 18px; font-size: 0; vertical-align: top; margin-right: 5px; }

.none_pdf_files { cursor : pointer; width: max-content; white-space: pre-line; word-break: break-all; max-width: 100%; }

.downBtnBox { position: absolute; top: 13px; right: 31px; z-index: 999999; }
.downBtnBox .fileAllCount { margin-right: 10px; }
.downBtnBox > a { position: static !important; }

/* 지출결의서 */
.dealListDiv { width: 95%; }
.dealListDiv .dealMenuUl li { display: inline-block; line-height: 30px; font-size: 14px; padding: 15px; box-sizing: border-box; border-right: 1px solid #e7e9ea; border-bottom: 1px solid #e7e9ea; text-align: center; overflow: hidden; white-space: nowrap;}
.dealListDiv .dealMenuUl li:last-child { border-right: 0px; }
.dealListDiv .dealSubject { width: 27%; order: 1; }
.dealListDiv .dealDate { width: 16%; order: 2; }
.dealListDiv .dealAmount { width: 13%; order: 3; }
.dealListDiv .dealStore { width: 22%; order: 4; }
.dealListDiv .dealNote { width: 22%; order: 5; }

.dealListDiv .dealContentUl li { position: relative; display: flex; margin-top: -2px; }
.dealListDiv .dealContentUl li p {  display: flex; justify-content: center; align-items: center; text-align: center; padding: 12px; line-height: 20px; box-sizing: border-box; border-right: 1px solid #e7e9ea; border-bottom: 1px solid #e7e9ea; font-size: 14px; overflow: hidden; text-overflow: ellipsis; }
.dealListDiv .dealContentUl li p:last-child { border-right: 0px; }
.dealListDiv .dealContentUl li p.dealDate { font-size: 13px; }
.dealListDiv .dealContentUl li .dealTotal { width: 43%; }
.dealListDiv .dealContentUl li .dealSum { width: 13%; border-right: 1px solid #e7e9ea !important; font-weight: bold; }
.dealListDiv .dealContentUl li p.dealSubject { text-align: left; }
.dealListDiv .dealContentUl li p.dealAmount { text-align: right; }

.file_div { width: 95%; height: 70px; margin-top: 40px; padding-bottom: 40px; position: relative; }
.file_div .receiptAddBtn { font-weight: bold; padding: 10px 30px; border-radius: 50px; border: 1px solid #e3e7ed; background: #fff; display: block; margin-top: 30px; color: #27313f; cursor: pointer; float: right; font-size: 14px; transition: .2s; }
.file_div .receiptAddBtn:before { content: ''; width: 18px; height: 18px; display: inline-block; background: url('../../assets/images/contents/ic_file_bk.png') no-repeat center; background-size: 18px auto; vertical-align: top; margin: 1px 4px 0 0; }
.file_div .receiptAddBtn:hover { background: #477fff; border: 1px solid #477fff; color: #fff; box-shadow: 0 5px 10px rgb(71 127 255 / 20%); }
.file_div .receiptAddBtn:hover:before { content: ''; background: url('../../assets/images/contents/ic_file_wt.png') no-repeat center; }
.file_div .receiptAddBtn:hover span { color: #fff; }
.file_div > p { position: absolute; bottom: 15px; right: 0; }

.filePopup { width: 270px; height: 200px; position: absolute; bottom: 85px; right: 0px; border: 2px solid #e7e9ea; background: #fff; text-align: right; padding: 15px; box-sizing: border-box; display: none; }
.filePopup.on { display : block; }
.filePopup .allDown_btn:after { content: ''; width: 2px; height: 13px; display: inline-block; background: #f1f3f5; vertical-align: middle; margin-left: 20px; }
.filePopup .allDown_btn:hover { font-weight: bold; }
.filePopup_close { background: none; border: none; margin-left: 20px; font-size: 12px; cursor: pointer; }
.attFileDiv { margin-top: 12px }
.attFileDiv .attFileUl li { box-sizing: border-box; position: relative; width: 100%; height: 30px; line-height: 30px; }
.attFileDiv .attFileUl p { display: inline-block; float: left; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.attFileDiv .attFileUl p.attFile_icon { font-size: 0; background: url('../../assets/images/contents/ic_file_bk.png') no-repeat left center; width: 28px; background-size: 17px auto; display: inline-block; vertical-align: middle; }
.attFileDiv .attFileUl p.attFile_title { width: 150px; font-weight: bold; text-align: left; }

.left_main_content * { 
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}

.approver_button_area a.b2 { width : 50%; display : inline-block; }

</style>

<style>
    .section_ce_fix a.hodu-link { color: #477fff !important; }
    
    /* 댓글 */
    .CommentBox { width: calc(100% - 360px); height: 77px; background: #fff; border-top: 1px solid #e7e9ea; text-align: center; box-sizing: border-box; }
    .CommentBox input.cmmntBtn { margin: 20px auto; }
</style>