<template>
    <div id="doc">

        <!-- title_box 시작 -->
        <div class="title_box">
            <a href="#" class="bt_back"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">호두닥</h3>
            <p class="btn_group">
            </p>
        </div>
        <!-- title_box 끝 -->

        <div class="select_div">

            <select @change="departmentChnage($event)">
                <option value="">선택 없음</option>
                <option :value="department.department_code" :key="department.department_code" v-for="department in department_info">{{ department.department_name }}</option>
            </select>
            
            <select @change="doctorChange($event)">
                <option value="" :selected="selected_doctor_code == ''">선택 없음</option>
                <option :value="`${doctor.department_code}___${doctor.doctor_code}`" :key="`${doctor.department_code}___${doctor.doctor_code}`" v-for="doctor in doctor_list">{{ `[${doctor.department_name}]${doctor.doctor_name}` }}</option>
            </select>

            <input type="button" id="appointment_time" class="input_txt" />
        </div>
        
        <div class="week_calendar">
            <a class="date" :key="index" v-for="(day, index) in day_array"
                :class="{
                    disabled : today.getTime() > day.date.getTime(),
                    on : isSelectedDay(day.date) == true
                }"
                @click.prevent="changeDate(day.date)">
                {{ `0${day.date.getDate()}`.slice(-2) }}
                <span class="day">{{ getDayOfWeekByDate(day.date, "요일") }}</span>
            </a>
        </div>

        <div class="available_time_list">
            <div class="not_exist" v-if="available_time_list == null || available_time_list.length < 1">
                <p>데이터 없음</p>
            </div>
            <div class="time" :key="index" v-for="(time, index) in available_time_list" :class="{ 'available' : time.available == true }" @click="appointment(time)">
                {{ getTimeText(time.original_timetable) }}
                <br>{{ time.doctor_name }}
            </div>
        </div>

        <div class="info" id="hospital_info" @click="copyHospitalInfo">
            <h3>병원 정보</h3>
            <span>{{ JSON.stringify(hospital_info) }}</span>
        </div>

        <div class="info" id="departments_info" @click="copyDepartmentsInfo">
            <h3>진료과 정보</h3>
            <span>{{ JSON.stringify(department_info) }}</span>
        </div>

        <div class="info" id="appointments" @click="copyAppointments">
            <h3>예약 현황</h3>
            <span>{{ appointments }}</span>
        </div>

        <div class="info" id="available_time" @click="copyAvailableTime">
            <h3>진료 가능 시간</h3>
            <span>{{ available_time }}</span>
        </div>

        <div class="info" id="doctor_info" @click="copyDoctorInfo">
            <h3>의사 정보</h3>
            <span>{{ JSON.stringify(doctor_info) }}</span>
        </div>

        <div class="info" id="patients_info" @click="copyPatientsInfo">
            <h3>환자 정보</h3>
            <span>{{ JSON.stringify(patient_info) }}</span>
        </div>

        <div class="info" id="hospital_setting_info" @click="copyHospitalSettingInfo">
            <h3>병원 세팅 정보</h3>
            <span>{{ JSON.stringify(hospital_setting_info) }}</span>
        </div>

        <div class="info" id="hospital_setting_info" @click="copyHolidayInfo">
            <h3>휴일 정보</h3>
            <span>{{ holiday_info }}</span>
        </div>

        <div class="info"  id="hospital_day_offs" @click="copyHospitalDayOffs">
            <h3>병원 지정 휴일 정보</h3>
            <span>{{ hospital_day_offs }}</span>
        </div>

        <div class="info" id="hospital_message_info" @click="hospital_message_info">
            <h3>병원 메세지 정보</h3>
            <span>{{ hospital_message_info }}</span>
        </div>
        
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import moment from 'moment';

@Component({
    components: {
        
    },
}) export default class Doc extends Mixins(VueHoduCommon) {

    biz_id : string = '131412c1-6e7d-49af-ab12-cf511c1eacbe'; // 이석호내과
    group_id : number = 4729;

    // hospital_info : string = "";
    departments_info : string = "";
    appointments : string = "";
    available_time : string = "";
    // doctor_info : string = "";
    // patients_info : string = "";
    // hospital_setting_info : string = "";
    holiday_info : string = "";
    hospital_day_offs : string = "";
    hospital_message_info : string = "";

    // 진료과 리스트
    selected_department_code = "";

    // 의사 리스트
    doctor_list : any[] = [];
    selected_doctor_code = "";

    // 진료 가능 시간
    day_array : any[] = [];
    today : Date = new Date(); 
    selected_date : Date = new Date(); 
    available_time_list : any[] = [];
    
    async beforeMount() : Promise<void> {
        await this.get_hodu_d_info(this.biz_id);
    }
    
    mounted() : void {
        // @ts-ignore
        $('#doc').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : window.innerHeight
        });

        this.getDoctorsInfo();
        this.getAppointmentsByDate();
        this.getAvailableAppointmentTime();
        this.getHolidayInfo();
        this.getHospitalDayOffs();
        this.getHospitalMessageInfo();

        this.today = new Date();
        this.today.setHours(0);
        this.today.setMinutes(0);
        this.today.setSeconds(0);
        this.today.setMilliseconds(0);

        const max_date = new Date(this.today);
        max_date.setFullYear(max_date.getFullYear() + 1);

        let datepicker_option = {
            minDate : this.today,
            maxDate : max_date,
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : `${this.today.getFullYear()}:${this.today.getFullYear() + 1}`,
            onSelect: (dateText, inst) => {
                
                this.selected_date = new Date(dateText);
                this.selected_date.setHours(0);
                this.selected_date.setMinutes(0);
                this.selected_date.setSeconds(0);
                this.selected_date.setMilliseconds(0);

                inst.input.val(moment(this.selected_date).format('YYYY.MM.DD'));

                this.getDayList(this.selected_date);
            },
        };

        // @ts-ignore
        $('#appointment_time').datepicker(datepicker_option);
        $('#appointment_time').val(moment(this.selected_date).format('YYYY.MM.DD'));

        this.getDayList(this.today);
    }

    /**
     * 병원 정보 조회
     */
    // getHospitalInfo() : void {
    //     this.get_hospital_info(this.biz_id);
    // }

    /**
     * 병원 정보 복사
     */
    copyHospitalInfo() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = JSON.stringify(this.hospital_info);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 병원 정보가 복사 되었습니다.");
    }

    /**
     * 진료과 정보 조회
     */
    // getDepartmentsInfo() : void {
    //     const search_word = ""; // 과 이름
    //     this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/department?search_word=${search_word}`, 'get')
    //         .then((response) => {
    //             this.departments_info = JSON.stringify(response.data.data);
    //             this.department_list = response.data.data; 
    //         })
    //         .catch((e) => {
    //             this.departments_info = JSON.stringify(e); 
    //         });
    // }

    /**
     * 진료과 정보 복사
     */
    copyDepartmentsInfo() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = JSON.stringify(this.department_info);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 진료과 정보가 복사 되었습니다.");
    }

    /**
     * 예약 현황 (페이징 있음)
     */
    getAppointmentsByDate() : void {
        const start = moment(new Date('2020-02-01 00:00:00')).utc().format(); // 조회 시작일
        const end   = moment(new Date('2020-04-31 23:59:59')).utc().format(); // 조회 종료일
        const search_word = ""; // 검색 쿼리
        const pagnation = 0; // 페이지

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/appointment?start=${start}&end=${end}&search_word=${search_word}&pagnation=${pagnation}`, API_METHOD.GET)
            .then((response) => {
                this.appointments = JSON.stringify(response.data.data); 
            })
            .catch((e) => {
                this.appointments = JSON.stringify(e); 
            });
    }

    /**
     * 예약 현황 복사
     */
    copyAppointments() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = this.appointments;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 예약 현황이 복사 되었습니다.");
    }

    /**
     * 예약 가능 시간 조회
     */
    @Watch('selected_date')
    getAvailableAppointmentTime() : void {

        const start = moment(this.selected_date).utc().format(); // 조회일
        const department_code = this.selected_department_code; // 진료과 코드 (없으면 모든 진료과의 현황이 나옴)
        const doctor_code = this.selected_doctor_code; // 의사 코드 (없으면 모든 의사의 현황이 나옴)

        if( doctor_code == null || doctor_code.length < 1 ) { return; }

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/availability?start=${start}&department_code=${department_code}&doctor_code=${doctor_code}`, API_METHOD.GET)
            .then((response) => {
                this.available_time = JSON.stringify(response.data.data);
                this.available_time_list = response.data.data;
                
                // 현재 시간에서 분 이하를 전부 날린다
                const delete_date = new Date();
                delete_date.setMinutes(0);
                delete_date.setSeconds(0);
                delete_date.setMilliseconds(0);

                this.available_time_list = this.available_time_list.filter((item) => new Date(item.original_timetable).getTime() >= delete_date.getTime());
                
            })
            .catch((e) => {
                this.available_time = JSON.stringify(e); 
            });
    }

    /**
     * 예약 가능 시간 복사
     */
    copyAvailableTime() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = this.available_time;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 진료 가능 시간이 복사 되었습니다.");
    }

    /**
     * 의사 정보 조회
     */
    getDoctorsInfo() : void {
        const department_code = this.selected_department_code; // 진료과 코드
        const search_word = ""; // 의사이름 검색어

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/doctor?department_code=${department_code}&search_word=${search_word}`, API_METHOD.GET)
            .then((response) => {
                // this.doctor_info = JSON.stringify(response.data.data);
                this.doctor_list = response.data.data; 
            })
            .catch((e) => {
                // this.doctor_info = JSON.stringify(e); 
            });
    }

    /**
     * 의사 정보 복사
     */
    copyDoctorInfo() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = JSON.stringify(this.doctor_info);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 의사 정보가 복사 되었습니다.");
    }

    /**
     * 환자 정보 조회
     */
    // getPatientsInfo() : void {
    //     this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/patient`, 'get')
    //         .then((response) => {
    //             // this.patients_info = JSON.stringify(response.data.data); 
    //         })
    //         .catch((e) => {
    //             // this.patients_info = JSON.stringify(e); 
    //         });
    // }

    /**
     * 환자 정보 복사
     */
    copyPatientsInfo() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = JSON.stringify(this.patient_info);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 환자 정보가 복사 되었습니다.");
    }

    /**
     * 병원 기본 설정 복사
     */
    copyHospitalSettingInfo() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = JSON.stringify(this.hospital_setting_info);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 환자 정보가 복사 되었습니다.");
    }

    /**
     * 휴일 정보 조회
     */
    getHolidayInfo() : void {
        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/holiday?year=2020`, API_METHOD.GET)
            .then((response) => {
                this.holiday_info = JSON.stringify(response.data.data); 
            })
            .catch((e) => {
                this.holiday_info = JSON.stringify(e); 
            });
    }

    /**
     * 휴일 정보 복사
     */
    copyHolidayInfo() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = this.holiday_info;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 휴일 정보가 복사 되었습니다.");
    }

    /**
     * 병원 지정 휴일 조회
     */
    getHospitalDayOffs() : void {
        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/day_off?year=2020`, API_METHOD.GET)
            .then((response) => {
                this.hospital_day_offs = JSON.stringify(response.data.data); 
            })
            .catch((e) => {
                this.hospital_day_offs = JSON.stringify(e); 
            });
    }

    /**
     * 병원 지정 휴일 복사
     */
    copyHospitalDayOffs() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = this.hospital_day_offs;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 병원 지정 휴일이 복사 되었습니다.");
    }

    /**
     * 병원 메세지 정보 조회
     */
    getHospitalMessageInfo() : void {
        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/message_template`, API_METHOD.GET)
            .then((response) => {
                this.hospital_message_info = JSON.stringify(response.data.data); 
            })
            .catch((e) => {
                this.hospital_message_info = JSON.stringify(e); 
            });
    }

    /**
     * 병원 메세지 정보 복사
     */
    copyHospitalMessageInfo() : void {
        const textArea : HTMLTextAreaElement = document.createElement("textarea");
        document.body.appendChild(textArea);
        textArea.value = this.hospital_message_info;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("클립보드에 병원 메세지 정보가 복사 되었습니다.");
    }

    /**
     * 타임 텍스트 반환
     */
    getTimeText(date : Date | string) : string {
        date = date instanceof Date ? date : new Date(date);
        
        const hour = `0${date.getHours() > 12 ? date.getHours() - 12 : date.getHours()}`.slice(-2);
        const min  = `0${date.getMinutes()}`.slice(-2);

        const amPm = date.getHours() < 12 ? "오전" : "오후";

        return `${amPm} ${hour}:${min}`;
    }

    /**
     * 예약가능시간에서 진료과 변경
     */
    departmentChnage(event : any) : void {
        const value : string = event.target.value;

        if( value == null || value.length < 1 ) {
            if( this.selected_doctor_code == "" ) { this.selected_department_code = ""; }
            this.getDoctorsInfo();
            return; 
        }
        
        this.selected_department_code = value;
        this.selected_doctor_code = "";
        this.getDoctorsInfo();
        this.available_time_list = [];
    }

    /**
     * 예약가능시간에서 의사 변경
     */
    doctorChange(event : any) : void {
        const value : string = event.target.value;

        if( value == null || value.length < 1 ) {
            this.selected_doctor_code = "";
            this.available_time_list.splice(0, this.available_time_list.length);
            return; 
        }

        const values : string[] = value.split('___');

        if( values == null || values.length < 2 ) {
            this.selected_doctor_code = "";
            this.available_time_list.splice(0, this.available_time_list.length);
            return; 
        }

        this.selected_department_code = values[0];
        this.selected_doctor_code = values[1];
        this.getAvailableAppointmentTime();
    }

    /**
     * 상단 날짜 리스트 구하기
     */
    getDayList(date : string | Date) : void {
        date = date instanceof Date ? date : new Date(date);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        this.day_array.splice(0, this.day_array.length);

        const day = date.getDay();

        // 날짜 생성
        for( let i = 0; i < 7; i++ ) {
            const day_date = new Date(date.getTime());
            day_date.setDate(day_date.getDate() -day + i);
            this.day_array.push({ "date" : day_date });
        }
    }
    
    /**
     * 선택된 날짜인지 여부 반환
     */
    isSelectedDay(date : string | Date) : boolean {
        date = date instanceof Date ? date : new Date(date);
        return date.getFullYear() == this.selected_date.getFullYear() && date.getMonth() == this.selected_date.getMonth() && date.getDate() == this.selected_date.getDate();
    }

    /**
     * 날짜 변경
     */
    changeDate(date : string | Date) : void {
        date = date instanceof Date ? date : new Date(date);

        if( date.getTime() < this.today.getTime() ) { return; }

        this.selected_date = date;
        $('#appointment_time').val(moment(this.selected_date).format('YYYY.MM.DD'));
    }

    /**
     * 예약
     */
    appointment(time : any) : void {
        if( time.available == false ) { return; }
        console.log(time);

        this.hodu_show_dialog("alert", `[${time.department_name}]${time.doctor_name} ${this.getTimeText(time.original_timetable)}\n예약 하시겠습니까?`, 
            ["아니오", "예"],
            [
                () => {}, 
                () => {
                    
                    const start = new Date(time.original_timetable);
                    const end = new Date(start.getTime());
                    end.setMinutes(end.getMinutes() + 15);

                    // 예약
                    this.hodu_api_call(`/api/v1/calendars/group-${this.group_id}/events/GROUP/${this.group_id}`, API_METHOD.POST, {
                        "alarm": [],
                        "attachment": {
                            "files": [],
                            "imgs": []
                        },
                        "attend": false,
                        "color": "#FF477FFF",
                        "contacts": [],
                        "event_owner_group_id": this.group_id,
                        "event_owner_group_name": "이석호내과",
                        "event_owner_id": this.user_id,
                        "event_owner_name": this.user_name,
                        "event_owner_team_id": 0,
                        "event_owner_team_name": "",
                        "event_push_yn": true,
                        "event_sub_type": "APPOINTMENT",
                        "location": [],
                        "memo": "",
                        "note": "",
                        "percent_complete": 0,
                        "priority": 0,
                        "schedule_date": {
                            "end": moment(end).utc().format(),
                            "exdate": [],
                            "isAllDay": false,
                            "isContinuos": false,
                            "isIgnore": false,
                            "lunar_yn": false,
                            "recurrence_end": moment(end).utc().format(),
                            "start": moment(start).utc().format()
                        },
                        "appointment": {
                            "biz_id": this.biz_id,
                            "department_code": this.selected_department_code,
                            "doctor_code": this.selected_doctor_code,
                            "patient_name": this.user_name,
                            "nok_name": "본인",
                            "symptoms": "두통, 복통, 인후통",
                            "prescription": null,
                            "appointment_status": "REQUEST",
                            "appointment_decline_reason": null,
                        },
                        "status": "",
                        "title": `${moment(start).format('HH:mm')} 예약`,
                        "uid": "",
                        "version": "1",
                        "vote": []
                    }).then((response) => {
                        console.log(response);
                        this.get_group_role_service();
                        this.getAvailableAppointmentTime();
                    }).catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });
                }
            ]
        );
    }

}
</script>

<style scoped>
    #doc { width: 100%; text-align: center; }
    #doc .title_box { line-height:61px; height:61px; border-bottom : 1px solid #f1f3f5; }
    #doc div.info { border: 1px solid #f1f3f5; padding : 10px; border-radius: 10px; margin-bottom: 20px;}

    #doc .week_calendar { display : inline-block; width : 60%; overflow : hidden; margin-top : 20px; }
    #doc .week_calendar a { transition: 0.2s; display: inline-block; width: 14.28%; text-align: center; float: left; height: 50px; border-bottom: 2px solid #e7e9ea; }
    #doc .week_calendar a.on { border-color: #477fff !important; color: #232f4c; cursor: default !important; }
    #doc .week_calendar a.disabled { cursor: default; }
    #doc .week_calendar .date { font-size: 16px; font-weight: bold; }
    #doc .week_calendar span { -webkit-transition: 0.2s; transition: 0.2s; display: inline-block; width: 100%; color: #a4a6ab; font-size: 12px; line-height: 15px; margin-top: 5px; }

    #doc .available_time_list { display : inline-block; width: 60%; overflow: hidden; margin : 20px 0; }
    #doc .available_time_list .time { float : left; width: 25%; border: 1px black solid; padding : 20px 0; box-sizing: border-box; text-align: center; font-weight: bold; font-size: 15px; color : black !important; opacity: 0.3; }
    #doc .available_time_list .time.available { opacity: 1; cursor: pointer; }
    #doc .not_exist { padding : 20px 0; border-radius: 10px; background-color: #f1f3f5; font-weight: bold; }
</style>