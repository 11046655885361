<template>

    <div class="doctor_create section_ce_fix creating" :class="{editing : is_create == false}" >
        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <!-- <h3 class="title_doc" style="" id="viewTitle">의사 등록</h3> -->
            <h3 class="title_doc" style="" id="viewTitle">{{is_create ? '의사 등록' : '의사 수정'}}</h3>
            <p class="btn_group">
                <input type="button" id="" class="input_btn" placeholder="" value="저장" title="저장" @click="validationCheckBeforeSave()"/>
                <input v-if="is_create == false" type="button" id="" class="input_btn" placeholder="" value="삭제" title="삭제" @click="showDeleteDoctorModal()"/>
            </p>
        </div>

        <div id="" class="content">

            <div id="scrollDiv" class="basicDiv">
                <!-- 스크롤 추가 -->
                <div class="scroll">
                    <p class="title firstTitle">{{is_create ? '1. 기본설정' : '기본설정'}}</p>
                    <div class="firstDiv">
                        <div class="docImgDiv">
                            <!-- label에 background-image로 사진 추가 -->
                            <label for="addDocPic" v-if="base64url == ''" :style="{ backgroundImage: `url(${o_doctor_info != null && o_doctor_info.doctor_detail.profile_image != null && o_doctor_info.doctor_detail.profile_image.length > 0 ? `app_images/${o_doctor_info.doctor_detail.profile_image}` : default_profile_image })` }"></label>
                            <label for="addDocPic" v-if="base64url != ''" :style="{ backgroundImage: `url(${base64url})` }"></label>
                            <input id="addDocPic" type="file" accept="image/*" @change="onClickProfileImage($event)"  />
                            <input type="button" class="del" v-if="base64url != '' || (o_doctor_info != null && o_doctor_info.doctor_detail.profile_image != null && o_doctor_info.doctor_detail.profile_image.length > 0)" value="삭제" @click="deleteProfileImage()">
                        </div>
                        <div class="docDetailDiv">
                            <ul>
                                <li>
                                    <label for="specialityId">진료과</label>
                                    <select name="" id="specialityId" @change="selectDepartment($event)" :disabled="is_create ? false : true">
                                        <!-- <option value="" >정형외과 </option>
                                        <option value="" >내과    </option> -->
                                        <option :value="deptIndex" :key="deptIndex" v-for="(deptInfo, deptIndex) in department_info" :selected="deptInfo != null && o_doctor_info != null && deptInfo.department_code == o_doctor_info.department_code">{{deptInfo.department_name}}</option>
                                    </select>
                                </li>
                                <li>
                                    <label for="docClrId">전용 색상</label>
                                    <input type="button" id="docClrId" :value="`${o_doctor_info != null && o_doctor_info.doctor_detail != null && o_doctor_info.doctor_detail.color != null ? getColorName(o_doctor_info.doctor_detail.color) : ''}`" @click="showCommonColorPickerModal" />
                                    <span :class="`${'clr'} ${o_doctor_info != null && o_doctor_info.doctor_detail != null && o_doctor_info.doctor_detail.color != null ? hodu_color_dc_lc(o_doctor_info.doctor_detail.color) : ''}`"></span>
                                </li>
                                <li>
                                    <label for="docNameId">이름</label>
                                    <!-- <input type="text" id="docNameId" placeholder="의사 이름"> -->
                                    <input type="text" id="docNameId" placeholder="이름" :value="o_doctor_info != null ? o_doctor_info.doctor_name : ''" @input="inputDoctorName($event)"/>
                                </li>
                                <li>
                                    <label for="docCodeId">코드</label>
                                    <!-- <input type="text" id="docCodeId" placeholder="병원 자체코드를 작성하세요"> -->
                                    <input :disabled="is_create == false" type="text" id="docCodeId" placeholder="코드" :value="o_doctor_info != null ? o_doctor_info.doctor_code : ''" @input="inputDoctorCode($event)">
                                </li>
                                <li>
                                    <label for="">직급</label>
                                    <select name="" id="selectPosition" @change="changeDoctorPosition($event)">
                                        <option :value="'DIR'" :selected="o_doctor_info != null && o_doctor_info.doctor_detail != null && o_doctor_info.doctor_detail.position == 'DIR'">원장</option>
                                        <option :value="'DPT'" :selected="o_doctor_info != null && o_doctor_info.doctor_detail != null && o_doctor_info.doctor_detail.position == 'DPT'">부원장</option>
                                        <option :value="'EMP'" :selected="o_doctor_info != null && o_doctor_info.doctor_detail != null && o_doctor_info.doctor_detail.position == 'EMP'">직원</option>
                                    </select>
                                </li>
                                <li>
                                    <label for="">자격</label>
                                    <select name="" id="" @change="changeDoctorLicense($event)">
                                        <option :value="'MEDIC'" :selected="o_doctor_info != null && o_doctor_info.doctor_detail != null && o_doctor_info.doctor_detail.license == 'MEDIC'">의료진</option>
                                        <option :value="'SPCLT'" :selected="o_doctor_info != null && o_doctor_info.doctor_detail != null && o_doctor_info.doctor_detail.license == 'SPCLT'">전문의</option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- 시간 선택 -->
                    <div class="setTimeDiv">
                        <ul>
                            <!-- <li>
                            <input id="doctor_create_start_date" type="text" :value="start_date_string" /><span>~</span>
                            <input id="doctor_create_end_date" type="text" :value="end_date_string" /> -->

                            <li v-if="is_create == true">
                                <label for="" class="title">2. 근무기간</label>
                                <input id="doctor_create_start_date" type="button" :value="o_doctor_info != null ? getYMDstr(o_doctor_info.start_date) : ''" /><span>~</span>
                                <input id="doctor_create_end_date" type="button" :value="o_doctor_info != null ? getYMDstr(o_doctor_info.end_date) : ''" />
                            </li>
                            <li v-else>
                                <p class="title fl">근무기간</p>
                                <div class="btns">
                                    <input type="button" class="addPlusBtn" value="근무기간 추가" @click="showDoctorTimeSettingModalInfo(true)" />
                                </div>
                            </li>
                            <li class="contractUl">
                                <!-- <p class="title">3. 요일별 근무시간</p>
                                <div class="descript"> -->
                                <p class="title" v-if="is_create == true">3. 요일별 근무시간</p>
                                <div class="descript" v-if="is_create == true">
                                    <ul>
                                        <li>
                                            <span class="morn clr">노랑</span>
                                            <span class="txt">오전</span>
                                        </li>
                                        <li>
                                            <span class="night clr">보라</span>
                                            <span class="txt">오후</span>
                                        </li>
                                        <li>
                                            <span class="allday clr">파랑</span>
                                            <span class="txt">종일</span>
                                        </li>
                                        <li>
                                            <span class="off clr">빨강</span>
                                            <span class="txt">휴무</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="selectDiv">
                                    <select class="contractSelect" v-if="is_create == false" @change="selectContract($event)">
                                        <!-- <option value="add">근무기간 추가</option> -->
                                        <option :value="termIndex" :key="termIndex" v-for="(term, termIndex) in timetable_history" :selected="termIndex == selectedTimetableIndex">{{term.date_text}}</option>
                                        <!-- <option value="" selected>2020.04.01 ~ 2021.04.01</option>
                                        <option value="">2019.04.01 ~ 2020.04.01</option> -->
                                    </select>
                                </div>
                                <!-- 병원설정 > 요일별 시간 등록시간과 동일하게 디폴트 세팅 -->
                                <ul v-if="timetable_history.length > 0 && timetable_history[selectedTimetableIndex].list.length">
                                    <li :key="timeIndex" v-for="(timeInfo, timeIndex) in timetable_history[selectedTimetableIndex].list" @click="showDoctorTimeSettingModalInfo(false)">
                                        <a>
                                            <span class="indi" :class="{
                                                    off    : timeInfo.work_code == 'OFF',
                                                    allday : timeInfo.work_code == 'FULL_TIME',
                                                    morn   : timeInfo.work_code == 'AM',
                                                    night  : timeInfo.work_code == 'PM'
                                                }">{{getAmPmStr(timeInfo.work_code)}}</span>
                                            <p class="day">{{getDayName(timeInfo.day_of_week, "요일")}}</p>
                                            <p class="workHours"><span class="from">{{timeInfo.am_text}}</span> ~ <span class="to">{{timeInfo.pm_text}}</span></p>
                                        </a>
                                    </li>
                                </ul>

                                <!-- <ul>
                                    <li @click="showDoctorTimeSettingModalInfo">
                                        <a>
                                            <span class="indi allday">종일</span>
                                            <p class="day">월요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">오후 1:00 ~ 6:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi allday">종일</span>
                                            <p class="day">화요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">오후 1:00 ~ 6:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi allday">종일</span>
                                            <p class="day">수요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">오후 1:00 ~ 6:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi allday">종일</span>
                                            <p class="day">목요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">오후 1:00 ~ 6:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi allday">종일</span>
                                            <p class="day">금요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">오후 1:00 ~ 6:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi allday">종일</span>
                                            <p class="day">토요일</p>
                                            <p class="workHours"><span class="from">오전 9:00 ~ 12:30</span> ~ <span class="to">오후 1:00 ~ 3:00</span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span class="indi off">휴관</span>
                                            <p class="day">일요일</p>
                                            <p class="workHours"><span>휴관</span></p>
                                        </a>
                                    </li>
                                </ul> -->

                            </li>
                        </ul>
                    </div>

                    <!-- 학력 / 경력 -->
                    <div class="experienceDiv">
                        <p class="title">학력 / 경력</p>
                        <ul v-if="o_doctor_info != null && o_doctor_info.doctor_detail != null && o_doctor_info.doctor_detail.career != null">
                            <!-- 인풋에 글자 작성시 리스트에 on 추가 -->
                            <li class="on" :key="careerIndex" v-for="(career, careerIndex) in o_doctor_info.doctor_detail.career" ><span class="dot"></span>
                                <input type="text" placeholder="순차적으로 학력과 경력을 작성하세요" :value="career" @input="inputDoctorCareer($event, careerIndex)">
                                <input :v-if="career.length > 1"  type="button" class="delThis" value="삭제" @click="deleteCareer(careerIndex)">
                            </li>
                            <!-- <li class="on"><span class="dot"></span><input type="text" placeholder="순차적으로 학력과 경력을 작성하세요" value="한양대 의대 졸업"><input type="button" class="delThis" value="삭제" /></li>
                            <li><span class="dot"></span><input type="text" placeholder="순차적으로 학력과 경력을 작성하세요"><input type="button" class="delThis" value="삭제" /></li>
                            <li><span class="dot"></span><input type="text" placeholder="순차적으로 학력과 경력을 작성하세요"><input type="button" class="delThis" value="삭제" /></li> -->
                        </ul>
                        <input type="button" class="addList" value="추가" @click="addCareer"/>
                    </div>
                </div>
            </div>

            <DoctorOffDays v-if="is_create == false && o_doctor_info != null" 
                :dayoff_info="dayoff_info"
                :biz_id="scope_group_team_option.biz_id"
                :department_code="o_doctor_info.department_code"
                :doctor_code="o_doctor_info.doctor_code"
                @reloadDayOffs="reloadDayOffs"
            />
            
            <div class="offDiv" v-else />
            <!-- <div class="offDiv">
                <div class="titleDiv">
                    <h4>휴가 관리</h4>
                    <select name="" class="selectHolidayYear" id="">
                        <option value="">2019</option>
                        <option value="" selected>2020</option>
                        <option value="">2021</option>
                        <option value="">2022</option>
                        <option value="">2023</option>
                    </select>
                    <div class="btns">
                        <input type="button" class="" value="추가">
                    </div>
                </div>
                <div class="addHolidayDiv">
                    <ul>
                        <li>
                            <a>
                                <p class="offTime">종일</p>
                                <p class="offTitle">여행</p>
                                <p class="offDay">04.10 금</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="offTime morn">오전</p>
                                <p class="offTitle">개인사유</p>
                                <p class="offDay">03.10 화</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="offTime">종일</p>
                                <p class="offTitle">개인사유</p>
                                <p class="offDay">03.02 월</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="offTime night">오후</p>
                                <p class="offTitle">컨퍼런스참여</p>
                                <p class="offDay">01.02 목</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="addedDiv">

                </div>
            </div> -->

            
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import { namespace } from 'vuex-class';
import { hodu_doc_modal_info, hodu_doc_table, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';

import moment from 'moment';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import DoctorOffDays from '@/components/hodu_d/DoctorOffDays.vue';
import { CommonColorPickerModalInfo, ImageCropModalInfo } from '../../store/modules/ModalInfo';
import { t_event, t_event_reply, t_event_file } from '@/model/event';

const ModalInfo = namespace('ModalInfo');
const HoduDocInfo = namespace('HoduDocInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        DoctorOffDays
    },
}) 
export default class Doctor extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDoctorTimeSettingModalInfo ?: (params : hodu_doc_modal_info.DoctorTimeSettingModalInfo) => void;
    @ModalInfo.Action doSetDoctorOffSettingModalInfo  ?: (params : hodu_doc_modal_info.DoctorOffSettingModalInfo) => void;
    @ModalInfo.Action doSetCommonColorPickerModalInfo ?: (params : CommonColorPickerModalInfo) => void;
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;

    base64url               : string = "";
    image_files             : any    = [];
    default_profile_image   : string = require("@/assets/images/contents/im_photoB.gif");
    
    o_doctor_info           : hodu_doc_object.doctor_info | null = null;
    is_create               : boolean = true;
    timetable_history       : hodu_doc_object.doctor_timetable_history[] = [];
    dayoff_info             : hodu_doc_table.t_hospital_doctor_day_offs[] = [];
    selectedTimetableIndex  : number = 0;
    selectedDepartIndex     : number = 0;

    async mounted() : Promise<void> {
        this.setScroll();
        const vue = this;
        
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        // 진료과가 없는 경우
        if( this.department_info == null || this.department_info.length < 1 ) {
            this.hodu_show_dialog("cancel", "진료과를 먼저 등록해주세요", ["확인"], [() => {
                this.movePrevPage();
            }]);
            return;
        }

        // 조회
        if( this.$route.params.doctor_code && this.doctor_info ) {
            for( const doctor of this.doctor_info ) {
                if( doctor.doctor_code == this.$route.params.doctor_code ) {
                    this.o_doctor_info = JSON.parse(JSON.stringify(doctor));
                    break;
                }
            }
            //! 필수 데이터 체크
            if ( this.o_doctor_info == null || 
                this.o_doctor_info.doctor_name == null || this.o_doctor_info.doctor_name.length < 1 ||
                this.o_doctor_info.doctor_code == null || this.o_doctor_info.doctor_code.length < 1 ||
                this.o_doctor_info.doctor_detail == null || 
                this.o_doctor_info.doctor_detail.color == null || this.o_doctor_info.doctor_detail.color.length < 1 || 
                this.o_doctor_info.doctor_detail.position == null || this.o_doctor_info.doctor_detail.position.length < 1 ||
                this.o_doctor_info.doctor_detail.license == null || this.o_doctor_info.doctor_detail.license.length < 1       ) {
                this.hodu_show_dialog("cancel", "필수 데이터가 존재하지 않습니다 잠시 후 다시 시도해주세요", ["확인"], [() => {
                    this.movePrevPage();
                }]);
                return;
            }
        }

        // this.getHospitalDepartment()
        this.is_create = this.o_doctor_info == null;

        if (this.is_create) {
            this.setDefaultTimeTableData();
        }else{
            this.getDoctorInfo();
        }

        $(".checkDiv a").click(function(){
            var getIndex = $(this).index();
            $(this).toggleClass("on");
            if (getIndex == 2) {
                $(this).siblings().removeClass("on");
            } else {
                
            }
        });

        // jquery-ui datepicker 옵션
        const datepicker_option = {
            inline: false,
            minDate : new Date(),
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText);
                inst.input.val(mnt.format('YYYY.MM.DD'));
                
                // 시작일 변경
                if( id == 'doctor_create_start_date' ) {
                    if (vue.o_doctor_info != null ) {
                        vue.o_doctor_info.start_date = mnt.toDate();
                    }
                } 

                // 종료일 변경
                else if( id == 'doctor_create_end_date' ) {
                    if (vue.o_doctor_info != null ) {
                        vue.o_doctor_info.end_date = mnt.toDate();
                    }
                }

            },
        }
        
        // @ts-ignore
        $('#doctor_create_start_date').datepicker(datepicker_option);

        // @ts-ignore
        $('#doctor_create_end_date').datepicker(datepicker_option);

    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const windowOuterHeight   : number | undefined = $(window).innerHeight();
        const titleBoxHeight      : number | undefined = $('.title_box').innerHeight();

        // @ts-ignore
        $('#scrollDiv').mCustomScrollbar({
            axis : 'y',
            mouseWheelPixels : 80,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : (windowOuterHeight == null ? 0 : windowOuterHeight) - (titleBoxHeight == null ? 0 : titleBoxHeight),
            scrollbarPosition : 'outside'
        });
    }

    /**
     * 그룹 이미지 추가
     */
    onClickProfileImage(event) : void {
        const file : File[] = event.target.files;
        this.addImage(file);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(file : File[]) : Promise<void> {
        const vue = this;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(file[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#cdImg').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                if( this.doSetImageCropModalInfo == null ) { return; }

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (1/1), // 1:1
                    image_url : url,
                    cancel_function : async() => {
                        $('#cdImg').val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        
                        this.base64url = cropped_url;

                        const resize_blob : Blob = await vue.hodu_image_resize(this.base64url);

                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = file[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, file[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = file[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        // 업로드용 파일 객체 담기
                        vue.image_files = [];
                        vue.image_files.push({
                            file : resize_file
                        });

                        $('#cdImg').val("");

                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지 삭제
     */
    deleteProfileImage() : void {
        if ( this.base64url ) {
            this.base64url = "";
            this.image_files = [];
        }
        if ( this.o_doctor_info != null ) { 
            this.o_doctor_info.doctor_detail.profile_image = ""
        }
    }

    getYMDstr(po_date: Date | string) : string {
        return moment(po_date).format("YYYY.MM.DD")
    }

    /**
     * doctor_work_code -> 종일/오전/오후/휴무 
     */
    getAmPmStr(value: hodu_doc_enum.doctor_work_code) : string {
        switch (value) {
            case hodu_doc_enum.doctor_work_code.FULL_TIME :
                return "종일"
            case hodu_doc_enum.doctor_work_code.AM :
                return "오전"
            case hodu_doc_enum.doctor_work_code.PM :
                return "오후"
            case hodu_doc_enum.doctor_work_code.OFF : 
                return "휴무"
            default :
                return ""
        }
    }

    /**
     * 의사 정보 초기값 세팅
     */
    setDefaultTimeTableData(): void {
        if ( this.hospital_info == null || this.department_info == null || this.department_info.length < 1) { return; }
        this.o_doctor_info = {
            hospital_name       : this.hospital_info.company_info.name,
            department_code     : this.department_info[this.selectedDepartIndex].department_code,
            department_name     : this.department_info[this.selectedDepartIndex].department_name,
            doctor_code         : "",
            doctor_name         : "",
            doctor_detail       : {
                color         : "#477FFF",
                position      : "EMP",
                license       : "SPCLT",
                career        : [""],
                profile_image : ""
            },
            start_date          : moment(new Date()).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate(),
            end_date            : new Date("2050-12-31 14:59:59Z"),
            is_current_schedule : false,
            timetable_id        : "",
            monday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
            tuesday             : hodu_doc_enum.doctor_work_code.FULL_TIME,
            wednesday           : hodu_doc_enum.doctor_work_code.FULL_TIME,
            thursday            : hodu_doc_enum.doctor_work_code.FULL_TIME,
            friday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
            saturday            : hodu_doc_enum.doctor_work_code.OFF,
            sunday              : hodu_doc_enum.doctor_work_code.OFF
        }

        let newInfo : hodu_doc_object.doctor_timetable_history = {
            timetable_id : "",
            date_text    : this.getListDateStr(this.o_doctor_info),
            list         : this.getTimeTableList(this.o_doctor_info)
        };
        
        this.timetable_history.push(newInfo);
    }
    
    /**
     * 색상 선택
     */
    async showCommonColorPickerModal() : Promise<void> {
        if ( this.o_doctor_info == null ) { return; }

        window['applyColor'] = this.applyColor;
        if( this.doSetCommonColorPickerModalInfo ) { 
            this.doSetCommonColorPickerModalInfo({
                show_modal      : true,
                selected_color  : this.o_doctor_info.doctor_detail.color,
                is_dc_only      : false,
            }); 
        }
    }

    /**
     * 컬러 픽커에서 선택한 값 적용
     */
    applyColor(hexColorStr: string){
        if ( this.o_doctor_info == null ) { return; }
        this.o_doctor_info.doctor_detail.color = hexColorStr;
    }

    /**
     * 색상 이름 반환
     */
    getColorName(color_string: string) : string {
        return this.hodu_color_dc_lc_label_text(color_string)
    }

    /**
     * 근무 기간 선택
     */
    selectContract(event: any) : void {
        this.selectedTimetableIndex = event.target.value as number;
    }

    addCareer(event) {
        if (this.o_doctor_info == null || this.o_doctor_info.doctor_detail.career[this.o_doctor_info.doctor_detail.career.length-1].length < 1 ) {
            return;
        }
        this.o_doctor_info.doctor_detail.career.push("")
    }

    deleteCareer(index: number) {
        if (this.o_doctor_info != null) {
            this.o_doctor_info.doctor_detail.career.splice(index, 1)
        }
    }

    /**
     * 의사 근무 시간 설정 모달 보이기
     */
    showDoctorTimeSettingModalInfo(is_create_work_time : boolean) : void {
        if ( this.o_doctor_info == null ) { return; }
        if( this.doSetDoctorTimeSettingModalInfo ) { 
            
            if ( is_create_work_time ) {
                if(this.hospital_info == null) { return; }
                let temp_doctor_info : hodu_doc_object.doctor_info = {
                    hospital_name       : this.hospital_info.company_info.name,
                    department_code     : this.o_doctor_info.department_code,
                    department_name     : this.o_doctor_info.department_name,
                    doctor_code         : "",
                    doctor_name         : "",
                    doctor_detail       : {
                        color         : "",
                        position      : "",
                        license       : "",
                        career        : [""],
                        profile_image : ""
                    },
                    start_date          : moment(new Date()).set("hours", 15).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate(),
                    end_date            : new Date("2050-12-31 14:59:59Z"),
                    is_current_schedule : false,
                    timetable_id        : "",
                    monday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    tuesday             : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    wednesday           : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    thursday            : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    friday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    saturday            : hodu_doc_enum.doctor_work_code.OFF,
                    sunday              : hodu_doc_enum.doctor_work_code.OFF
                }

                this.doSetDoctorTimeSettingModalInfo({
                    "show_modal"            : true,
                    "is_create_work_time"   : true,
                    "start_date"            : temp_doctor_info.start_date as Date,
                    "end_date"              : temp_doctor_info.end_date as Date,
                    "timetable"             : {
                        timetable_id : "",        // uuid
                        date_text    : "",        // "2020.02.15~2020.05.05"
                        list         : JSON.parse(JSON.stringify(this.getTimeTableList(temp_doctor_info)))
                    }
                }); 

            }else{
                this.doSetDoctorTimeSettingModalInfo({
                    "show_modal"            : true,
                    "is_create_work_time"   : false,
                    "start_date"            : new Date(),
                    "end_date"              : new Date(),
                    "timetable"             : JSON.parse(JSON.stringify(this.timetable_history[this.selectedTimetableIndex]))
                }); 
            }
            
        }
        window["createTimetable"]   = this.createTimetable;     // 근무표 새로 생성할 때
        window["updateTimetable"]   = this.updateTimetable;     // 근무표를 수정할 때
        window["addTimetable"]      = this.addTimetable;        // 근무표를 수정할 때
    }

    createTimetable(timetable_info : hodu_doc_object.doctor_timetable[], temp_doctor_info : hodu_doc_object.doctor_info | null = null) : void {
        if ( this.o_doctor_info == null ) { return; }

        let docInfo : hodu_doc_object.doctor_info;

        //! temp_doctor_info 가 존재하면 의사 조회하고 근무표 추가
        if ( temp_doctor_info != null ) {
            docInfo = temp_doctor_info;
        }
        //! temp_doctor_info 가 없다면 의사 생성에서 근무표 작성
        else{
            docInfo = this.o_doctor_info;
        }

        const list   = this.timetable_history[this.selectedTimetableIndex].list;
        for ( const info of timetable_info ) {
            switch (info.day_of_week) {
                case 0 :
                    docInfo.sunday = info.work_code;
                    break;
                case 1 :
                    docInfo.monday = info.work_code;
                    break;
                case 2 :
                    docInfo.tuesday = info.work_code;
                    break;
                case 3 :
                    docInfo.wednesday = info.work_code;
                    break;
                case 4 :
                    docInfo.thursday = info.work_code;
                    break;
                case 5 :
                    docInfo.friday = info.work_code;
                    break;
                case 6 :
                    docInfo.saturday = info.work_code;
                    break;
            }
        }

        //! temp_doctor_info 가 존재하면 의사 조회하고 근무표 추가
        if ( temp_doctor_info != null ) {
            this.addTimetable(timetable_info, temp_doctor_info.start_date as Date);
        }
        //! temp_doctor_info 가 없다면 의사 생성에서 근무표 작성
        else{
            this.timetable_history[this.selectedTimetableIndex].list = this.getTimeTableList(docInfo);
        }
        
    }

    /**
     * 의사 조회중 근무표 즉시 수정
     */
    async updateTimetable(timetable_info : hodu_doc_object.doctor_timetable[], timetable_id : string) : Promise<void> {
        
        if( this.o_doctor_info == null ) { return; }
        const biz_id            = this.scope_group_team_option.biz_id;
        const department_code   = this.o_doctor_info.department_code;
        const doctor_code       = this.o_doctor_info.doctor_code;
        
        let req_data = {
            work_day_info   : this.makeWorkInfoArray(timetable_info)
        }
        // console.log("HIS ==>> req_data");
        // console.log(req_data);
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/doctor/${department_code}/${doctor_code}/timetable/${timetable_id}`, API_METHOD.PUT, req_data)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                if (jsonData.length > 0) {
                    // console.log(jsonData);
                    this.getDoctorInfo();
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 의사 조회중 근무표 추가
     */
    async addTimetable(timetable_info : hodu_doc_object.doctor_timetable[], new_start_date: Date) : Promise<void> {
        
        if( this.o_doctor_info == null ) { return; }
        const biz_id            = this.scope_group_team_option.biz_id;
        const department_code   = this.o_doctor_info.department_code;
        const doctor_code       = this.o_doctor_info.doctor_code;
        
        let req_data = {
            biz_id          : this.scope_group_team_option.biz_id,
            department_code : this.o_doctor_info.department_code,
            doctor_code     : this.o_doctor_info.doctor_code,
            doctor_name     : this.o_doctor_info.doctor_name,
            start_date      : new_start_date,
            work_day_info   : this.makeWorkInfoArray(timetable_info)
        }
        // console.log("HIS ==>> req_data");
        // console.log(req_data);
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/doctor/${department_code}/${doctor_code}/timetable`, API_METHOD.POST, req_data)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                if (jsonData.length > 0) {
                    this.getDoctorInfo();
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 요일별 의사 근무표 데이터 가공
     */
    getTimeTableList(docInfo: hodu_doc_object.doctor_info) : hodu_doc_object.doctor_timetable[] {
        if ( this.hospital_setting_info == null ) { return []; }
        let larrO_timetable : hodu_doc_object.doctor_timetable[] = []; 

        try {
            const setting_info_cnt  : number = this.hospital_setting_info.length
        
            // 요일 정보 세팅
            larrO_timetable.push({ "day_of_week" : 0, "work_code" : docInfo.sunday   , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 1, "work_code" : docInfo.monday   , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 2, "work_code" : docInfo.tuesday  , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 3, "work_code" : docInfo.wednesday, "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 4, "work_code" : docInfo.thursday , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 5, "work_code" : docInfo.friday   , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 6, "work_code" : docInfo.saturday , "am_text" : "", pm_text : "" });
            
            // 해당 날짜에 적용 되는 병원 타임 테이블을 찾는다
            let hospital_timetable !: hodu_doc_object.hospital_setting_info[];
            hospital_timetable = this.getHospitalTimetableFromDoctorDate(docInfo);

            // 찾지 못한 경우 의사 정보로만 텍스트 세팅
            if( hospital_timetable == null ) {
                // // 세팅
                // for ( const docTimeTable of larrO_timetable ) {
                    
                // }
                
                return larrO_timetable;
            }

            for ( const docTimeTable of larrO_timetable ) {

                const doc_table : hodu_doc_object.doctor_timetable = JSON.parse(JSON.stringify(docTimeTable));

                for( const hosptialTimeTable of hospital_timetable ) {
                    if ( docTimeTable.day_of_week != Number(hosptialTimeTable.day_of_week) ) { continue; }

                    doc_table.am_text = "오전 휴무";
                    doc_table.pm_text = "오후 휴무";

                    if (doc_table.work_code == hodu_doc_enum.doctor_work_code.FULL_TIME) {
  
                        if( hosptialTimeTable.start_am_time && hosptialTimeTable.start_am_time.length > 0 &&
                            hosptialTimeTable.end_am_time   && hosptialTimeTable.end_am_time.length   > 0 ) {
                            doc_table.am_text = `오전 ${hosptialTimeTable.start_am_time.substring(0, 2)}:${hosptialTimeTable.start_am_time.substring(2, 4)} ~ ${hosptialTimeTable.end_am_time.substring(0, 2)}:${hosptialTimeTable.end_am_time.substring(2, 4)}`
                        }

                        if( hosptialTimeTable.start_pm_time && hosptialTimeTable.start_pm_time.length > 0 &&
                            hosptialTimeTable.end_pm_time   && hosptialTimeTable.end_pm_time.length   > 0 ) {
                            doc_table.pm_text = `오후 ${hosptialTimeTable.start_pm_time.substring(0, 2)}:${hosptialTimeTable.start_pm_time.substring(2, 4)} ~ ${hosptialTimeTable.end_pm_time.substring(0, 2)}:${hosptialTimeTable.end_pm_time.substring(2, 4)}`
                        }
                        
                    } 
                    
                    else if (doc_table.work_code == hodu_doc_enum.doctor_work_code.AM) {
                        
                        if( hosptialTimeTable.start_am_time && hosptialTimeTable.start_am_time.length > 0 &&
                            hosptialTimeTable.end_am_time   && hosptialTimeTable.end_am_time.length   > 0 ) {
                            doc_table.am_text = `오전 ${hosptialTimeTable.start_am_time.substring(0, 2)}:${hosptialTimeTable.start_am_time.substring(2, 4)} ~ ${hosptialTimeTable.end_am_time.substring(0, 2)}:${hosptialTimeTable.end_am_time.substring(2, 4)}`
                        }

                    }

                    else if (doc_table.work_code == hodu_doc_enum.doctor_work_code.PM) {
                        
                        if( hosptialTimeTable.start_pm_time && hosptialTimeTable.start_pm_time.length > 0 &&
                            hosptialTimeTable.end_pm_time   && hosptialTimeTable.end_pm_time.length   > 0 ) {
                            doc_table.pm_text = `오후 ${hosptialTimeTable.start_pm_time.substring(0, 2)}:${hosptialTimeTable.start_pm_time.substring(2, 4)} ~ ${hosptialTimeTable.end_pm_time.substring(0, 2)}:${hosptialTimeTable.end_pm_time.substring(2, 4)}`
                        }

                    }

                    larrO_timetable.splice(larrO_timetable.indexOf(docTimeTable), 1, doc_table);
                    break;
                }
            }
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
        
        return larrO_timetable;
    }

    /**
     * 의사 근무 시작~종료 기간에 따라 병원 근무표 반환
     */
    getHospitalTimetableFromDoctorDate(docInfo : hodu_doc_object.doctor_info) : hodu_doc_object.hospital_setting_info[] {
        let hospital_timetable !: hodu_doc_object.hospital_setting_info[];
        if ( this.hospital_setting_info == null ) { return []; } 

        // past
        if ( new Date(docInfo.end_date).getTime() < new Date().getTime() ) {
            hospital_timetable = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= new Date(docInfo.end_date).getTime() &&
                                                                        new Date(item.end_date).getTime()   >= new Date(docInfo.end_date).getTime() );
        }
        // future
        else if( new Date(docInfo.start_date).getTime() > new Date().getTime() ) {
            hospital_timetable = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= new Date(docInfo.start_date).getTime() &&
                                                                        new Date(item.end_date).getTime()   >= new Date(docInfo.start_date).getTime() );
        }
        // now
        else {
            hospital_timetable = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= new Date().getTime() &&
                                                                        new Date(item.end_date).getTime()   >= new Date().getTime() );
        }
        
        return hospital_timetable;
    }

    /**
     * 근무기간 선택시 보여질 문구 생성 ( 2020.01.01 ~ 2020.12.31 )
     */
    getListDateStr(timetable: hodu_doc_object.doctor_info) : string {
        let startDate = moment(timetable.start_date).format("YYYY.MM.DD");
        let endDate   = moment(timetable.end_date).format("YYYY.MM.DD");

        return startDate + "~" + endDate;
    }

    /**
     * 의사 근무표 및 휴일 조회
     */
    async getDoctorInfo() : Promise<void> {
        if (this.o_doctor_info == null) { return; }
        const biz_id            = this.scope_group_team_option.biz_id;
        const department_code   = this.o_doctor_info.department_code;
        const doctor_code       = this.o_doctor_info.doctor_code;

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/doctor/${department_code}/${doctor_code}/timetable`, API_METHOD.GET)
            .then((response) => {
                // let jsonData = JSON.stringify(response.data.data);
                let timetables : hodu_doc_object.doctor_info[] = response.data.data.timetable_history;
                let numOfTimetables = timetables.length;
                
                this.timetable_history = [];
                for (let i=0; i<numOfTimetables; i++) {
                    let timetable_info : hodu_doc_object.doctor_info = timetables[i]
                    let newInfo : hodu_doc_object.doctor_timetable_history = {
                        timetable_id : timetable_info.timetable_id,
                        date_text    : this.getListDateStr(timetable_info),
                        list         : this.getTimeTableList(timetable_info)
                    };
                    
                    this.timetable_history.push(newInfo);
                }

                this.dayoff_info = []
                this.dayoff_info = this.dayoff_info.concat(response.data.data.dayoff_info)
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    showDeleteDoctorModal() : void {
        if( this.o_doctor_info == null ) { return; }
        this.hodu_show_dialog("cancel", `정말 [${this.o_doctor_info.department_name} - ${this.o_doctor_info.doctor_name}] 선생님을 삭제하겠습니까?`, 
        ['아니오', '예'], 
        [
            () => {

            },
            async () => {
                if( this.o_doctor_info == null ) { return; }
                let body = {
                    doctor_codes : [this.o_doctor_info.doctor_code]
                }
                await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/doctor`, API_METHOD.DELETE, body)
                .then((response) => {
                    this.movePrevPage();
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
        ]);
    }

    // showDeleteWorkTimeModal() : void {
    //     this.hodu_show_dialog("cancel", `2020.04.01 ~ 2021.04.01 스케줄을 삭제하겠습니까?`, 
    //     ['아니오', '예'], 
    //     [
    //         () => {

    //         },
    //         () => {
    //             this.movePrevPage();
    //         }
    //     ]);
    // }

    /**
     * 진료과 선택
     */
    selectDepartment(event) : void {
        if( this.department_info == null || this.o_doctor_info == null ) { return; }
        this.selectedDepartIndex = event.target.value as number
        this.o_doctor_info.department_code = this.department_info[this.selectedDepartIndex].department_code
        this.o_doctor_info.department_name = this.department_info[this.selectedDepartIndex].department_name
    }

    /**
     * 의사 근무표 정보 조립
     */
    makeWorkInfoArray( timetable_list : hodu_doc_object.doctor_timetable[] ) : hodu_doc_table.t_hospital_doctors_timetable_work_day_info[] {
        let work_infos : hodu_doc_table.t_hospital_doctors_timetable_work_day_info[] = [];

        for ( const timetable of timetable_list) {
            work_infos.push({
                work_code   : timetable.work_code,
                day_of_week : timetable.day_of_week
            })
        }

        return work_infos;
    }

    /**
     * 파일 업로드
     */
    async uploadProfileImage() : Promise<void> {
        const vue = this;

        if ( this.base64url && this.image_files.length > 0 ) {
            const form_data : FormData = new FormData();

            // FormData 내용 생성
            const image_files_count : number = this.image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.image_files[i].file);
            }

            await this.hodu_temp_upload(form_data)
                .then(async(response) => {
                    const temp_files : t_event_file[] = response.data.data.temp_files;
                    await this.saveDoctor(temp_files[0]);
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });      
        }
        else {
            await this.saveDoctor();
        }
    }

    /**
     * 저장 전 유효성 검사
     */
    validationCheckBeforeSave() {
        let error_message    = "";
        let handling_message = "";
        
        if ( this.o_doctor_info == null ) {
            error_message = "데이터가 손실되었습니다 잠시 후 다시 시도해주세요"
            handling_message = "back"
        }
        else if ( this.o_doctor_info.doctor_name.trim().length < 1 ) {
            error_message = "이름을 입력해주세요"
            handling_message = "docNameId"
        }
        else if ( this.o_doctor_info.doctor_code.trim().length < 1 ) {
            error_message = "코드를 입력해주세요"
            handling_message = "docCodeId"
        }
        else if ( this.o_doctor_info.doctor_detail == null ) {
            error_message = "부가정보가 손실되었습니다 잠시 후 다시 시도해주세요"
            handling_message = "back"
        }
        else if ( this.o_doctor_info.doctor_detail.color.length < 1 ) {
            error_message = "색상을 다시 선택해주세요"
        }
        else if ( this.o_doctor_info.doctor_detail.position.length < 1 ) {
            error_message = "직급을 다시 선택해주세요"
        }
        else if ( this.o_doctor_info.doctor_detail.license.length < 1 ) {
            error_message = "자격을 다시 선택해주세요"
        }
        else{
            this.uploadProfileImage();
            return;
        }
        
        this.hodu_show_dialog('alert', error_message, ['확인'], [
            () => {
                if ( handling_message == "back") {
                    this.movePrevPage();
                }else if (handling_message.length > 1){
                    $("#" + handling_message ).focus();
                }
            }
        ]);
    }

    /**
     * 의사 정보 생성 및 수정
     */
    async saveDoctor(profile_image_file : t_event_file | null = null) : Promise<void> {
        if ( this.o_doctor_info == null ) { return }

        let req_data = {
            biz_id          : this.scope_group_team_option.biz_id,
            department_code : this.o_doctor_info.department_code,
            doctor_code     : this.o_doctor_info.doctor_code,
            doctor_name     : this.o_doctor_info.doctor_name,
            start_date      : this.o_doctor_info.start_date,
            doctor_info     : this.o_doctor_info.doctor_detail,
            work_day_info   : this.makeWorkInfoArray(this.timetable_history[this.selectedTimetableIndex].list),
            profile_image   : profile_image_file
        }
        // console.log(req_data);
        let url    = ""
        let method;
        if ( this.is_create ) {
            url = `api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/doctor`;
            method = API_METHOD.POST;
        }else{
            url = `api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/doctor/${this.o_doctor_info.department_code}/${this.o_doctor_info.doctor_code}`;
            method = API_METHOD.PUT;
        }
        
        await this.hodu_api_call(url, method, req_data)
            .then(async(response) => {
                this.movePrevPage();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    reloadDayOffs(jsonData: any[]) : void {
        this.dayoff_info.splice(0, this.dayoff_info.length)
        this.dayoff_info = this.dayoff_info.concat(jsonData)
    }

    inputDoctorName(event) {

        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_name = value;
        }
    }

    inputDoctorCode(event) {

        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_code = value;
        }
    }

    changeDoctorPosition(event) {
        
        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_detail.position = value;
        }
    }

    changeDoctorLicense(event) {
        
        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_detail.license = value;
        }
    }

    inputDoctorCareer(event, careerIndex) {

        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_detail.career[careerIndex] = value;
        }
    }
}
</script>

<style scoped>
    .content { height:100%;overflow: hidden; }
    .titleDiv { overflow: hidden; padding: 30px; }
    .titleDiv h4 { font-size: 17px;line-height: 50px; height: 50px;display:inline-block }
    .titleDiv .btns { display:inline-block; float:right;  }
    .basicDiv { width:60%;float:left;height:100%;  }
    .basicDiv .title { font-size:17px;font-weight: bold; }
    .basicDiv .scroll .firstTitle {  margin: 30px 30px 0; }
    .basicDiv .docImgDiv label { width: 100px; height:100px;border-radius: 50%; box-sizing: border-box; border: 3px solid #477fff; display: block;  font-size:0; cursor:pointer; background-image: url(../../assets/images/contents/im_photoB.gif); background-size:cover;background-position: center center; background-repeat: no-repeat; }
    .basicDiv .docImgDiv input[type="file"] { display:none; }
    .basicDiv .docImgDiv .del { transition:0.2s; position:absolute; right:0;bottom:0; display: block; width: 35px; height: 35px; background:#ff6060  url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-radius: 50%; font-size:0; }
    .basicDiv .docImgDiv .del:hover { background-color: #fd1818 }
    .basicDiv .firstDiv { position:relative; padding: 30px 30px 30px 170px; border-bottom:5px solid #e7e9ea; }
    .docImgDiv { position: absolute; left:40px;top:59px;  }
    .docDetailDiv ul { overflow: hidden; }
    .docDetailDiv li { position: relative; float:left; margin-bottom:15px; width:50%;display:inline-block; padding-right: 20px; box-sizing: border-box; height: 78px; }
    .docDetailDiv select { font-weight:bold; transition:0.2s; cursor:pointer; appearance: none;padding-left: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; border-radius: 5px;background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 96%  center; background-repeat: no-repeat }
    .docDetailDiv select:hover { background-color:#f1f3f5; }
    .docDetailDiv select:focus { border-color:#477fff; background-color:#fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .docDetailDiv label { font-size: 13px;display:block; line-height: 23px; height:23px; margin-bottom:5px; } 
    .docDetailDiv input[type="text"] { transition:0.2s; font-weight: bold; padding: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; }
    .docDetailDiv input[type="text"]:hover { background:#f1f3f5; }
    .docDetailDiv input[type="text"]:focus { border-color:#477fff; background:#fff; }
    
     .experienceDiv { padding: 30px;margin-top:10px;border-top: 5px solid #e7e9ea; }
    .experienceDiv ul { margin-top:20px; border-top:1px solid #f1f3f5;padding-top:10px; }
    .creating .experienceDiv ul { border-top:none;padding-top:0; }
    .experienceDiv ul li { position: relative;transition:0.2s; line-height: 48px; height: 48px; width:100%; padding-left: 30px; box-sizing: border-box; }
    .creating .experienceDiv ul li { line-height: 60px; height: 60px; }
    .experienceDiv ul li input[type="text"]:disabled { cursor:default; color:#232848; background:#fff !important;border:0 none; border-bottom: 1px solid #f1f3f5; width: 100%;line-height: 45px; }
    .creating .experienceDiv ul li input[type="text"] { cursor: text; transition:0.2s; font-weight:bold; width: 100%; border: 1px solid #f1f3f5; background:#fff; height: 45px; line-height: 45px; padding: 15px; box-sizing: border-box; }
    .creating .experienceDiv ul li input[type="text"]:hover { background:#f1f3f5; }
    .creating .experienceDiv ul li input[type="text"]:focus { background:#fff; border-color:#477fff }
    .creating .experienceDiv ul li.on { padding-right: 50px; }
    .experienceDiv ul li input.delThis { display:none; transition:0.2s; display:none; position: absolute; right: 0;top:14px; width: 30px; height: 30px; border-radius: 50%; font-size: 0;background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; }
    .experienceDiv ul li input.delThis:hover { background-color: #fd1818 }
    .experienceDiv ul li.on input.delThis { display:none; } 
    .creating .experienceDiv ul li.on input.delThis { display:block; }

    .creating .experienceDiv ul li span.dot { top: 19px; }
    .experienceDiv ul li span.dot { position: absolute; left:5px; top: 14px; display: inline-block;float: left;width: 8px;height: 8px;margin-right: 10px;margin-top: 7px;border-radius: 50%;background: #b7bbbf; }
    .creating .experienceDiv ul li.on span.dot { background-color:#477fff; }
    .experienceDiv .addList { display:none; width: 100px; height: 35px; line-height: 33px; background:#fff; border: 1px solid #e7e9ea; border-radius: 20px; font-weight: bold; margin:0 auto; margin-top:20px; margin-bottom: 50px;}
    .experienceDiv .addList:hover { background:#477fff; color:#fff;border-color:#477fff; }
    .creating .experienceDiv .addList { display: block; }

    .setTimeDiv { padding: 30px; }
    .setTimeDiv .title { font-size: 16px; font-weight: bold; display: block; margin-bottom: 20px;    margin-top: 10px; }
    .setTimeDiv > ul > li { overflow: hidden; position:relative; margin-bottom:30px;}
    .setTimeDiv > ul > li > input { transition:0.2s; cursor:pointer; padding: 0 15px;border:1px solid #e7e9ea; background:#fff; height: 45px; line-height: 45px; font-weight: bold; border-radius: 5px;  margin-right: 20px; width: 150px;text-align: center; }
    .setTimeDiv > ul > li > input {  }
    .setTimeDiv > ul > li > input:hover { background-color:#f1f3f5; }
    .setTimeDiv > ul > li > input:focus { background-color:#fff; border-color:#477fff; }
    .setTimeDiv > ul > li > span { padding-right:20px; }
    .setTimeDiv .descript { position: absolute; right:0; top:10px; }
    .setTimeDiv .descript li { overflow:hidden; display:inline-block;margin-right:20px; border: none !important; line-height:20px; height:20px; }
    .setTimeDiv .descript li  span { float:left; }
    .setTimeDiv .descript li:last-child { margin-right:0; }
    .setTimeDiv .descript li .clr { float:left; margin-right:7px; display:inline-block; width: 10px; height:10px; font-size: 0; border-radius: 50%; border:1px solid; margin-top:3px}
    .setTimeDiv .descript li .clr.morn {background: #ffc00e !important;border-color: #f3b300;}
    .setTimeDiv .descript li .clr.night {background: #846eff !important;border-color: #6c52ff;}
    .setTimeDiv .descript li .clr.allday {background: #477fff !important;border-color: #296aff;}
    .setTimeDiv .descript li .clr.off { background: #a1a5bb !important;border-color: #9195a9;}
    .contractSelect { transition:0.2s; cursor:pointer; font-size: 14px; font-weight:bold; appearance: none; border-radius: 5px; line-height: 50px; height:55px; border:1px solid #e7e9ea;padding: 0 20px; width:100%; box-sizing: border-box; background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 98%  center; background-repeat: no-repeat }
    .contractSelect:hover { background-color:#f1f3f5;  }
    .contractSelect:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }
    .contractSelect option { height: 40px; padding:20px 0; }
    .contractUl li:first-child { border-top:1px solid #f1f3f5; }
    .contractUl li { position:relative; font-size: 12px; line-height: 47px; height:47px; border-bottom:1px solid #f1f3f5; }
    .contractUl li a { display:inline-block; width:100%; height: 100%; }
    .contractUl li a:hover { background:#f7f9fb; font-weight:bold; }
    .contractUl li .day {  display:inline-block;padding: 0 30px 0 70px; text-align: center; }
    .contractUl li .workHours { display: inline-block; border-left: 1px solid #e7e9ea; line-height: 15px; }
    .workHours span {display:inline-block; padding: 0 25px; box-sizing: border-box; width:160px }
    .contractUl li .indi { transition: 0.1s; width: auto !important; font-size: 12px; font-weight:bold; height: 20px; line-height: 20px; margin-top:-7px; color:#fff; padding:0 10px; border-radius: 20px; left:10px; }
    .contractUl .indi { position: absolute; left:20px; top:20px; width:10px; height:10px; display:block; border-radius: 50%;border: 1px solid; } 
    .contractUl .indi.morn {background: #ffc00e !important;border-color: #f3b300;}
    .contractUl .indi.night {background: #846eff !important;border-color: #6c52ff;}
    .contractUl .indi.allday {background: #477fff !important;border-color: #296aff;}
    .contractUl .indi.off { background: #a1a5bb !important;border-color: #9195a9;}
    .dayUl  { overflow:hidden; border-right:1px solid #e7e9ea}
    .dayUl li { display: inline-block; width: 14.2857142857%; float:left; text-align:center; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .dayUl li:hover .day { background:#f1f3f5; }
    .dayUl li p { line-height: 35px; height: 35px; font-weight: bold; border-bottom: 1px solid #e7e9ea;border-top: 1px solid #e7e9ea; }
    .offDay .checkDiv a { cursor: default; }
    .offDay .checkDiv a span { opacity:0.3; cursor: default; background:#fff !important;  }
    .checkDiv a { display: block; height:45px; line-height:45px; box-sizing: border-box; font-weight: bold; border-bottom: 1px solid #e7e9ea; }
    .checkDiv a.on:hover { cursor:default }
    .checkDiv a.on span { background:#477fff !important;border-color:#1b5fff; color:#fff; }
    .checkDiv a:hover span { background: #f1f3f5; }
    .checkDiv a span { transition:0.2s; display: block; height:100%; line-height: 45px; background:#fff; box-sizing: border-box;}
    .checkDiv a.on.morn span {background: #ffc00e !important;border-color: #f3b300;}
    .checkDiv a.on.night span {background: #846eff !important;border-color: #6c52ff;}
    .checkDiv a.on.off span { background: #a1a5bb !important;border-color: red;}
    #docClrId {transition:0.2s;width: 100%;height: 50px;line-height: 50px;border-radius: 5px;background: #fff;border: 1px solid #e7e9ea;box-sizing: border-box;padding: 0 15px;text-align: left;font-weight: bold;  }
    #docClrId:hover { background: #f1f3f5; }
    .docDetailDiv .clr { position:absolute; right:35px; top:43px; display: inline-block; width:20px;height: 20px; border-radius: 50%; }


    .offDiv { width: 40%;float:left; height:100%; border-left:1px solid #e7e9ea;box-sizing: border-box;  }
    .offDiv .selectHolidayYear { transition:0.2s; cursor:pointer; margin-top:10px; margin-left:15px; float:left; border: 1px solid #232848;border-radius: 20px; font-size: 13px; line-height: 26px; height:30px; appearance: none; padding: 0 30px 0 15px; font-weight: bold;background-image:url('../../assets/images/side_area/bt_open.png'); background-position: 90%  center; background-repeat: no-repeat !important }
    .offDiv .selectHolidayYear:hover { background-color:#f1f3f5; }
    .offDiv .selectHolidayYear:focus { background-color:#fff; border-color:#477fff;background-image:url('../../assets/images/side_area/bt_close.png'); }

    .titleDiv { background:#fff; padding-bottom: 10px; padding-top:20px; }
    .titleDiv h4 { float:left;  }
    .offDiv .btns input {transition:0.2s;background: url(../../assets/images/contents/ic_add.png) no-repeat center center; font-size: 0;width: 40px;height:40px; background-size: 40px; border-radius: 50%;margin-top: 5px;}
    .offDiv .btns input:hover {background-image: url(../../assets/images/contents/btn_add_fff.png); background-color:#477fff;}
    .offDiv .btns input, .addPlusBtn {transition:0.2s;background: url(../../assets/images/contents/ic_add.png) no-repeat center center; font-size: 0;width: 35px;height:35px; background-size: 40px; border-radius: 50%;margin-top: 5px;}
    .offDiv .btns input:hover, .addPlusBtn:hover {background-image: url(../../assets/images/contents/btn_add_fff.png); background-color:#477fff;}

    .addHolidayDiv li { position:relative; background:#fff; font-weight:bold;padding:0 30px; }
    .addHolidayDiv li a { border-top: 1px solid #f1f3f5; line-height: 55px; height: 55px; display: block; padding-left: 45px; padding-right: 100px; }
    .addHolidayDiv li:last-child a {  border-bottom: 1px solid #f1f3f5; }
    .addHolidayDiv li a p { display:inline-block; }
    .addHolidayDiv li .offTime {border:1px solid; position:absolute; left:30px;top:18px; padding:0 6px; border-radius: 2px; background:#477fff; color:#fff;height:20px; line-height: 20px; font-weight: bold; margin-right: 15px; border-radius: 3px; }
    .offDiv p.morn {background: #ffc00e !important;border-color: #f3b300;}
    .offDiv p.night {background: #846eff !important;border-color: #6c52ff;}
    .offDiv p.off { background: #a1a5bb !important;border-color: red;}
    .addHolidayDiv li .offTitle { width: 100%; font-size:13px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .addHolidayDiv li .offDay {right:30px; position: absolute; }
    
    .grp.settingBg {position: static;padding: 0 0 !important;width: 100% !important;max-width: 100% !important;min-width: 100% !important;}
    #container #section_ce .schedule_box {width: 100%;max-width: 100%;padding-left: 0;padding-bottom: 0;padding-right: 0;}
    #container #section_ce .section_scroll {margin-top: 0 !important;}
    .left_area_close .content {padding-left: 61px !important;box-sizing: border-box;}

    /* 수정 또는 작성 중 */
    #container #section_ce .section_ce_fix.creating .contractUl { margin-top:20px; }
    #container #section_ce .section_ce_fix.creating .addPlusBtn {float: right;}
    #container #section_ce .section_ce_fix.creating .setTimeDiv > ul > li { margin-bottom:0 !important;} 
    #container #section_ce .section_ce_fix.creating.editing .setTimeDiv > ul > li { margin-top:0 !important;} 
    #container #section_ce .section_ce_fix.creating.editing .contractUl li:first-child { border-top: 0 none;  }


</style>