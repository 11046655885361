<template>
    <div id="resident_join" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">입주자 가입신청</h3>
        </div>

        <div class="section_scroll">
            <div class="viewGroup">
                <div class="schedule_box">
                    <!--settingBg-->
                    <div class="grp settingBg">
                        <div class="bigGrp left">
                            <div class="selectDiv">
                                <select @change="changeTargetBuilding($event.target.value)">
                                    <option value="TARGET_ALL">전체</option>
                                    <option :value="building.building" :key="index" v-for="(building, index) in buildings">{{ building.building }}동</option>
                                    <!-- <option value="">101 동</option>
                                    <option value="">102 동</option>
                                    <option value="">1003 동</option> -->
                                </select>
                            </div>
                        </div>
                        <!-- 체크가 하나라도 될 경우 selectBtn 클래스 추가하여 선택해제 버튼 활성화-->
                        <div class="rightBtnDiv fr selectBtn">
                            <input type="button" class="input_btn select_btn" value="선택 해제" @click.prevent="allCheckOff" v-if="check_user_id_list.length > 0 ">
                            <input type="button" class="input_btn" value="모두 승인" @click.prevent="applyCheckedRequests">
                        </div>
                    </div>
                    <!--//settingBg-->

                    <div id="joinLlist" class="content">
                        <!--joinListHeader-->
                        <ul class="sortHeader joinListHeader">
                            <li class="checkBox headerSort">
								<p class="checkBox">
									<input type="checkbox" id="selectAll" name="selectall" :checked="isAllApply == false && isAllCheck() == true" @change="checkAll($event)" :disabled="isAllApply == true"/>
									<label for="selectAll" :disabled="isAllApply == true">전체선택</label>
								</p>
							</li>
							<li class="num">No</li>
                            <li class="peep">
                                <span>이름 (동&amp;호)</span>
                            </li>
                            <li class="phoneNum">
                                <span>전화번호</span>
                            </li>
                            <li class="time">
                                <span>가입신청일</span>
                            </li>
                            <li class="status">
                                <span>가입승인</span>
                            </li>
                        </ul>
                        <!--//joinListHeader-->

                        <div id="join_list_scroll" class="join_list_scroll">
                            <ul class="joinListCon">

                                <li :class="{ on : request.listOnOff == true }" :key="`${request.building}-${request.unit}`" v-for="(request, index) in computedRequestListByTargetBuilding">
                                    <p class="checkBox">
										<input type="checkbox" :id="`chck_${index}`" :checked="check_user_id_list.indexOf(request.main_resident.user_id) > -1" @change="checkUser($event, request.main_resident.user_id)" v-if="request.main_resident.appr_state != 'A'"/>
										<label :for="`chck_${index}`" v-if="request.main_resident.appr_state != 'A'">전체선택</label>
									</p>
                                    <p class="num">{{ index + 1 }}</p>

                                    <!--중복되는 동,호수가 있을 경우 overlapPeep 클래스 추가-->
                                    <p class="peep" :class="{ 'overlapPeep' : request.sub_residents.length > 0 }">
                                        {{ request.main_resident.nickname }} ({{ request.building }}동 {{ request.unit }}호)
                                        <a title="중복 보기" class="overlapP" @click.prevent="listOnOff(request)" v-if="request.sub_residents.length > 0">동&amp;호수</a>
                                    </p>

                                    <p class="phoneNum">{{ request.main_resident.phone }}</p>
                                    <p class="time">{{ hodu_date_to_format_string(request.main_resident.audit_created, 'YYYY.MM.DD') }}</p>

                                    <p class="status">
                                        <input type="button" value="승인" class="apply" :class="{ 'on' : request.main_resident.appr_state == 'A' }" @click.prevent="showApproveMessageDialog(request.main_resident.user_id, 'A')" :disabled="request.main_resident.appr_state == 'A'">
                                        <input type="button" value="거절" class="reject" :class="{ 'on' : request.main_resident.appr_state == 'E' }" @click.prevent="showApproveMessageDialog(request.main_resident.user_id, 'E')" :disabled="request.main_resident.appr_state == 'E'" v-if="request.main_resident.appr_state != 'A'">
                                    </p>

                                    <div class="overlapDiv" v-if="request.sub_residents.length > 0">
                                        <div class="overlapDivTitle">
                                            <h5>동&amp;호수</h5>
                                        </div>
                                        <div class="overlapList">
                                            <ul class="overlapListUl">
                                                <li :key="sub_index" v-for="(sub_resident, sub_index) in request.sub_residents">
                                                    <p class="checkBox">
                                                        <input type="checkbox" :id="`chck_${index}_${sub_index}`" :checked="check_user_id_list.indexOf(sub_resident.user_id) > -1" @change="checkUser($event, sub_resident.user_id)"  v-if="sub_resident.appr_state != 'A'"/>
										                <label :for="`chck_${index}_${sub_index}`" v-if="sub_resident.appr_state != 'A'">전체선택</label>
                                                    </p>
                                                    <p class="num">{{ index + 1 }}-{{ sub_index + 1 }}</p>
                                                    <p class="peep">{{ sub_resident.nickname }} ({{ request.building }}동 {{ request.unit }}호)</p>
                                                    <p class="phoneNum">{{ sub_resident.phone }}</p>
                                                    <p class="time">{{ hodu_date_to_format_string(sub_resident.audit_created, 'YYYY.MM.DD') }}</p>
                                                    <p class="status">
                                                        <input type="button" value="승인" class="apply" :class="{ 'on' : sub_resident.appr_state == 'A' }" @click.prevent="showApproveMessageDialog(sub_resident.user_id, 'A')" :disabled="sub_resident.appr_state == 'A'">
                                                        <input type="button" value="거절" class="reject" :class="{ 'on' : sub_resident.appr_state == 'E' }" @click.prevent="showApproveMessageDialog(sub_resident.user_id, 'E')" :disabled="sub_resident.appr_state == 'E'" v-if="sub_resident.appr_state != 'A'">
                                                    </p> 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class ResidentJoin extends Mixins(VueHoduCommon) {

    /**
     * 전부 체크 된 경우
     */
    get isAllApply() : boolean {
        const residents : any[] = [];
        for( const request of this.computedRequestListByTargetBuilding ) {
            residents.push(request.main_resident);
            for( const sub_resident of request.sub_residents ) {
                residents.push(sub_resident);
            }
        }

        return residents.filter(resident => resident.appr_state != 'A').length < 1;
    }

    /**
     * 선택된 동의 리스트만 반환 
     */
    get computedRequestListByTargetBuilding() : any[] {
        let requests : any[] = JSON.parse(JSON.stringify(this.requests));

        // 검색 생기면 검색 조건 바꿔서 사용
        // requests = requests.filter(item => new RegExp(escape(this.search_word)).test(escape(item.car_info.car_number)) == true);

        if( this.target_building == 'TARGET_ALL') {
            return requests;
        }

        requests = requests.filter(item => item.building == this.target_building);
        
        return requests;
    }

    buildings : any[] = [];
    requests : any[] = [];

    target_building : string = "TARGET_ALL";
    check_user_id_list : number[] = [];

    mounted() : void {
        // 중복동호수 열기
        // $(".overlapP").click(function(){
        //     $(this).parent().parent().toggleClass("on");
        // });

        this.getInfo();
        this.setScroll();        
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height : number | undefined = $('#resident_join .title_box').outerHeight();
        const setting_bg_height : number | undefined = $('#resident_join .settingBg').outerHeight();
        const sort_header_height : number | undefined = $('#resident_join .sortHeader').outerHeight();

        // @ts-ignore
        $('#join_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height == null ? 0 : title_height) 
                                           - (setting_bg_height == null ? 0 : setting_bg_height)
                                           - (sort_header_height == null ? 0 : sort_header_height)
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        this.$nextTick(() => {
            
            // @ts-ignore
            $('#join_list_scroll').mCustomScrollbar('destroy');

            this.setScroll();
        });
        
    }

    /**
     * 전체 조회
     */
    async getInfo() {
        await this.getBuildings();
        await this.getResidentJoinRequests();
    }

    /**
     * 동 조회
     */
    async getBuildings() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/building`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.buildings ) {
                throw new Error("동 조회 중 오류 발생");
            }

            this.buildings.splice(0, this.buildings.length);
            this.buildings = this.buildings.concat(response.data.data.buildings);

        } catch(e) {
            this.hodu_show_dialog("cancel", "동 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 가입 요청 조회
     */
    async getResidentJoinRequests() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/resident/join`, API_METHOD.GET, null, false);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.requests ) {
                throw new Error("입주민 가입 신청 리스트 조회 실패");
            }

            this.requests.splice(0, this.requests.length);
            this.requests = this.requests.concat(response.data.data.requests);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 조회 대상 동을 변경
     */
    changeTargetBuilding(value : string) : void {
        this.allCheckOff();
        this.target_building = value;
    }

    /**
     * 중복 동&호 열기 / 닫기
     */
    listOnOff(obj : any) {
        const index = this.computedRequestListByTargetBuilding.indexOf(obj);
        const copy_obj = JSON.parse(JSON.stringify(obj));
        copy_obj.listOnOff = (copy_obj.listOnOff ? false : true);

        this.computedRequestListByTargetBuilding.splice(index, 1, copy_obj);
        this.$forceUpdate();
    } 

    /**
     * 체크 ON / OFF
     */
    checkUser(event, user_id : number) {
        const index = this.check_user_id_list.indexOf(user_id);

        const checked = event.target.checked;

        if( checked == true && index < 0 ) {
            this.check_user_id_list.push(user_id);
        }
        else if( checked == false && index > -1 ) {
            this.check_user_id_list.splice(index, 1);
        }
    }

    /**
     * 전체 체크 여부
     */
    isAllCheck() : boolean {
        const residents : any[] = [];
        for( const request of this.computedRequestListByTargetBuilding ) {
            if( request.main_resident.appr_state != 'A' ) residents.push(request.main_resident);
            for( const sub_resident of request.sub_residents ) {
                if( sub_resident.appr_state != 'A' ) residents.push(sub_resident);
            }
        }

        return residents.length == this.check_user_id_list.length;
    }

    /**
     * 체크 전체 해제
     */
    allCheckOff() : void {
        this.check_user_id_list.splice(0, this.check_user_id_list.length);
    }

    checkAll(event) : void {

        const checked = event.target.checked;

        if( checked == true ) {
            const residents : any[] = [];
            for( const request of this.computedRequestListByTargetBuilding ) {
                if( request.main_resident.appr_state != 'A' ) residents.push(request.main_resident);
                for( const sub_resident of request.sub_residents ) {
                    if( sub_resident.appr_state != 'A' ) residents.push(sub_resident);
                }
            }
            this.allCheckOff();
            for( const resident of residents ) {
                this.check_user_id_list.push(resident.user_id);
            }
        }
        else {
            this.allCheckOff();
        }
    }
 
    /**
     * 체크된 요청 전부 승인
     */
    async applyCheckedRequests() : Promise<void> {

        if ( this.check_user_id_list.length < 1 ) {
            this.hodu_show_dialog("alert", "승인할 입주자 가입 신청을 선택해주세요", ['확인']);
            return;
        }

        this.hodu_show_dialog("alert", `승인 하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {
                const promise_array : Promise<any>[] = [];
                for( const user_id of this.check_user_id_list ) {
                    promise_array.push(this.updateApprove(user_id, 'A'));
                }
                Promise.all(promise_array); 
            },
        ]);
    }

    /**
     * 승인 / 거절 처리 전 물어보기
     */
    async showApproveMessageDialog(user_id : number, appr_state : string) {
        this.hodu_show_dialog("alert", `${appr_state == 'A' ? '승인' : '거절'} 하시겠습니까?`, ['아니오', '예'], [
            () => {},
            () => { this.updateApprove(user_id, appr_state); },
        ]);
    }
    
    /**
     * 승인 업데이트
     */
    async updateApprove(user_id : number, appr_state : string) {
        let approve_obj = {
            group_id        : this.scope_group_id,
            team_id         : 0,
            group_team_name : this.scope_group_team_option.group_team_name,
            user_id         : user_id,
            appr_state      : appr_state
        }

        try {
            const response = await this.hodu_api_call(`api/v1/groups/updateApprov`, API_METHOD.PUT, {arrO_list: [approve_obj]});

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("입주자 가입 신청 처리 중 오류 발생");
            }

            this.allCheckOff();
            this.getInfo();

        } catch(e) {
            this.hodu_show_dialog("cancel", "입주자 가입 신청 처리 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    } 
}
</script>

<style scoped>
    .bigGrp select { position: static !important; float: left; font-size: 13px; font-weight: bold; line-height: 40px; height: 40px; background-color: #fff; cursor: pointer; padding-right: 20px; padding-left: 10px; appearance: none; -webkit-appearance: none; -moz-appearance: none; background: url('../../assets/images/side_area/bt_open.png')no-repeat center right; }
    .rightBtnDiv .input_btn { margin-top: 11px; margin-right: 25px; }
    .rightBtnDiv .select_btn { display: none; }
    .rightBtnDiv.selectBtn .select_btn { display: inline-block; }

    #joinLlist input[type='checkbox']:not(old) { border-left: 1px solid #fff; cursor: pointer; width: 100%; height: 100%; text-align: center; }
    #joinLlist input[type='checkbox']:not(old) + label { display: inline-block; padding: 0; width: 20px; height: 20px; border-radius: 3px; border: 2px solid #c1cfd8; background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat 0px 0px; background-size: 21px; font-size: 0;position: absolute; top: 0; left: 50%; margin-right: 8px; margin-left: -10px; margin-top: 17px; }
    #joinLlist input[type='checkbox']:not(old):checked + label { background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat 0px 0px; background-size: 21px; border: 2px solid #477fff; }

    .section_ce_fix .joinListHeader li { box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .section_ce_fix li.checkBox { width: 7%; overflow: visible; }
    .section_ce_fix .num { width: 7%; padding-right: 5px; font-weight: bold; }
    .section_ce_fix .peep { width: 25%; padding-right: 5px; }
    .section_ce_fix .phoneNum { width: 20%; padding-right: 5px; }
    .section_ce_fix .time { width: 20%; padding-right: 5px; }
    .section_ce_fix .status { width: 21%; }

    .content .joinListCon li { border-bottom: 1px solid #f1f3f5; width: 100%; height: 60px; line-height: 60px; display: inline-block; font-size: 12px; padding: 7px 0; box-sizing: border-box; }
    .content .joinListCon li.on { height: auto; border-bottom: 2px solid #f1f3f5; }
    .content .joinListCon li p { float: left; line-height: 60px; height: 60px; margin-top: -7px; box-sizing: border-box; }
    .content .joinListCon .checkBox { /* height: 100%; */ padding: 0; width: 7%; position: relative; }

    .content .joinListCon li p.overlapPeep { position: relative; }
    .content .joinListCon li p.overlapPeep:hover { font-weight: bold; }
    .content .joinListCon li a.overlapP { font-size: 0; width: 60px; height: 60px; display: inline-block; vertical-align: top; box-sizing: border-box; background: url('../../assets/images/side_area/bt_open.png')no-repeat center center; background-size: 26px; z-index: 10000; }
    .content .joinListCon li.on a.overlapP { background-image: url('../../assets/images/side_area/bt_close.png'); }
    .content .joinListCon li.on p.overlapPeep:hover a.overlapP { background-image: url('../../assets/images/side_area/bt_close_b.png'); }
    .content .joinListCon li p.overlapPeep:hover a.overlapP { background-image: url('../../assets/images/side_area/bt_open_b.png'); }
    .joinListCon li .status input { position: relative; z-index: 1000; float: left; margin-right: 10px; -webkit-box-sizing: border-box; box-sizing: border-box; width: 65px; text-align: center; display: inline-block; height: 28px; line-height: 26px; border-radius: 25px; background: #fff; font-weight: bold; margin-top: 17px; color: #7c88a2; border: 1px solid #e2e5e8; }
    .joinListCon li .status input:hover { background:#f1f3f5; }
    .joinListCon li .status input.reject.on { background: #ff6363; color: #fff; border-color:#ff4242; cursor: default; }
    .joinListCon li .status input.apply.on { background:#447fff !important; color:#fff; border-color: #2b6eff; cursor: default; }

    /*중복 동호수*/
    .content .joinListCon li .overlapDiv { display: none; }
    .content .joinListCon li.on .overlapDiv { display: block; }
    .overlapDiv h5 { display: none; }
    .overlapListUl li { border-top: 1px solid #f1f3f5; border-bottom: 0 !important; background: #fff; line-height: 55px !important; height: 55px !important; float: left; }
    .overlapListUl li p { line-height: 55px !important; height: 55px !important; }
    .overlapListUl li p input { margin-top: 14px !important; }
</style>