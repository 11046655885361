<template>
<!-- 수정시 edit추가 -->
    <div class="notice" id="toDoDiv" :class="{ edit : is_multi_delete_mode == true }">
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <!-- 상단 영역 시작 -->
        <div class="over_hidden noti_titlebox mToDo">
            <span class="bar"></span>
<!-- edit 모드일때 할일 "할 일 삭제" -->
            <h3 class="tit_noti">할 일 (TO-DO)</h3>
            <a href="#" @click.prevent="createTodo" id="toDoBtnReg" class="bt_regi fr" title="추가"><span class="blind">할 일 추가</span></a>

            <input type="button" class="input_btn toDoBtnEdit" value="삭제" @click="multiDeleteMode" v-if="todo_list.length > 0"/>
<!-- 삭제 클릭시 보이기 -->
            <input type="button" class="input_btn toDoBtn toDoBtnEditCancel" value="취소" @click="listMode"/>
            
        </div>
        <!-- 상단 영역 끝 -->

        <!-- 내용 영역 시작 -->
        <div class="notiWrap">

            <!-- 저장 된 할 일 없을 때 시작 -->
            <p v-show="todo_list.length < 1" class="noResult"><span>현재 저장된 할 일이 없습니다</span></p>
            <!-- 저장 된 할 일 없을 때 끝 -->

            <!-- 할 일 존재시 영역 시작 -->
            <!-- 탭 영역 시작 -->
            <div v-show="todo_list.length > 0 && is_multi_delete_mode == false" class="tabs">
                <a href="#" @click.prevent="allView" :class="{ on : view_all == true }">전체 <span>{{ all_todo_count }}</span></a>
                <a href="#" @click.prevent="ingView" :class="{ on : view_ing == true }">진행 중 <span>{{ ing_todo_count }}</span></a>
                <a href="#" @click.prevent="endView" :class="{ on : view_end == true }">완료 <span>{{ end_todo_count }}</span></a>
            </div>
            <!-- 탭 영역 끝 -->

            <div id="todoListScroll">
                <!-- 할 일 리스트 시작 -->
                <ul v-show="todo_list.length > 0" class="toDoUl">
                    <!-- 종료일 지난 리스트 expired -->
<!-- delCheckbox 클릭시 그 리스트에 delOn 추가 -->
                    <li :class="{ 
                        deadline : todo.todo_info.end != null, 
                        on : todo.todo_info.is_end == true, 
                        moreOn : todo.todo_info.more_on,
                        expired : isExpired(todo) == true,
                        delOn : multi_select_list.indexOf(index) > -1 
                    }" 
                        @mouseleave="todoMouseLeave(todo)" :key="todo.todo_id" v-for="(todo, index) in target_list">

                        <!-- 다중 삭제 체크 박스 -->
                        <div class="delCheckDiv">
                            <input type="checkbox" class="delCheckbox" :id="`delCheck-${todo.todo_id}`" 
                                   @change="changeMultiSelect($event, index)" :checked="multi_select_list.indexOf(index) > -1">
                            <label :for="`delCheck-${todo.todo_id}`"><span class="title"></span></label>
                        </div> 

                        <input type="checkbox" :id="`checkbox-${todo.todo_id}`" v-model="todo.todo_info.is_end" />
                        
                        <!-- <label @click="todoClick(todo)" :for="`checkbox-${todo.todo_id}`"><span class="title">{{ todo.todo_info.summary }}</span></label> -->
                         <label @click="todoClick(todo)" :for="`checkbox-${todo.todo_id}`"><span class="title"></span></label>

                        <div class="toDoDiv" v-html="getSummary(todo.todo_info.summary)">
                        </div>

                        <!-- <textarea name="text" :value="todo.todo_info.summary" disabled>
                            
                        </textarea> -->

                        <!-- 시작일 영역 시작 -->
                        <p class="deadline start">
                            <!-- <span class="dead">시작일</span> -->
                            <span class="when">{{ getDateByFormat(new Date(todo.todo_info.start)) }} 
                                <em :class="{ sat : new Date(todo.todo_info.start).getDay() == 6, sun : new Date(todo.todo_info.start).getDay() == 0 }">
                                    <!-- {{ getDayOfWeek(new Date(todo.todo_info.start)) }} -->
                                </em>
                            </span>
                        </p>
                        <!-- 시작일 영역 종료 -->

                        <!-- 종료일 영역 시작 -->
                        <!-- <p v-if="todo.todo_info.end != null" class="deadline end">
                            <span class="dead">종료일</span>
                            <span class="when">{{ getDateByFormat(new Date(todo.todo_info.end)) }} 
                                <em :class="{ sat : new Date(todo.todo_info.end).getDay() == 6, sun : new Date(todo.todo_info.end).getDay() == 0 }">
                                    {{ getDayOfWeek(new Date(todo.todo_info.end)) }}
                                </em>
                            </span>
                        </p> -->
                        <!-- 종료일 영역 종료 -->

                        <span class="deco"></span>

                        <!-- 리스트 버튼 영역 시작 -->
                        <p @click="moreButtonClick(todo)" class="more"><span></span><span></span><span></span></p>
                        <p class="edit"><span @click="modifyTodo(todo)" title="수정">수정</span></p>
                        <p @click="todoDelete(index)" class="del" title="삭제">삭제</p>
                        <!-- 리스트 버튼 영역 끝 -->

                        <!-- 삭제 영역 시작 -->
                        <!-- <div v-show="todo.todo_info.deleteButtonClicked == true" class="confirm">
                            <h5>삭제 하시겠습니까?</h5>
                            <p>
                                <a href="#" @click.prevent="todoDelete(index)">네</a>
                                <a href="#" @click.prevent="todoDeleteRefuse(todo)">아니오</a>
                            </p>
                        </div> -->
                        <!-- 삭제 영역 끝 -->

                    </li>
                </ul>
                <!-- 할 일 리스트 끝 -->
                <input type="button" class="toDoListDelBtn" value="삭제하기" @click.prevent="deleteAll"/>
            </div>
            <!-- 할 일 존재시 영역 끝 -->

        </div>
        <!-- 내용 영역 끝 -->

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const MenuInfo = namespace('MenuInfo');
const TodoInfo = namespace('TodoInfo');

import { t_todo } from '@/model/osm'; 

const dateFormat = require('dateformat');

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
}) export default class RightTodoList extends Mixins(VueHoduCommon) {

    /**
     * @MenuInfo.Action
     */
    @MenuInfo.Action doSetTodoMenuOpen    ?: any;
    @MenuInfo.Action doTodoCreateOrUpdate ?: any;

    /**
     * @TodoInfo.State
     */
    @TodoInfo.State  todoSelectFlag       !: boolean;
    
    /**
     * @TodoInfo.Action
     */
    @TodoInfo.Action doSetTodo            ?: any;
    @TodoInfo.Action doSetTodoSelectFlag  ?: any;
    
    view_all : boolean = true;
    view_ing : boolean = false;
    view_end : boolean = false;

    all_todo_count : number = 0;
    ing_todo_count : number = 0;
    end_todo_count : number = 0;

    todo_list : t_todo[] = [];
    target_list : t_todo[] = [];

    is_multi_delete_mode : boolean = false;
    multi_select_list : number[] = [];

    async mounted() : Promise<void> {

        // 상단 삭제 버튼 클릭시 .notice 디브에 edit 추가
        // $(".toDoBtnEdit").click(function(){
        //     $(".notice").addClass("edit");

        // });

        // .notice 디브에 edit 추가 시 취소버튼
        $(".toDoBtnEditCancel").click(function(){
            $(".notice").removeClass("edit");

        });

        // .notice.edit에서 삭제 체크박스 클릭시 리스트에 delOn 추가
        $(".notice .title").click(function(){
            $(this).parent().parent().parent().toggleClass("delOn");
        });

        

        this.setScroll();
        await this.getTodoList();

        if( this.ing_todo_count > 0 ) {
            this.view_all = false;
            this.view_ing = true;
            this.target_list = this.todo_list.filter((todo) => todo.todo_info.is_end == false );
        }

        window['getTodoList'] = this.getTodoList;
    }

    beforeDestroy() {
        window['getTodoList'] = null;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.noti_titlebox.mToDo').outerHeight();
        const tab_height : number | undefined = $('.notiWrap .tabs').outerHeight();

        // @ts-ignore
        $('#todoListScroll').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ) - ( tab_height == null || this.is_multi_delete_mode == true ? 0 : tab_height),
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 개인 할 일 조회
     */
    async getTodoList() : Promise<void> {
        await this.hodu_api_call(`api/v1/todo`, API_METHOD.GET)
            .then(async(response) => {
                // console.log(JSON.stringify(response));
                this.todo_list = response.data.data.todo_list;

                if( this.view_all == true ) {
                    this.target_list = this.todo_list;
                }

                if( this.view_ing == true ) {
                    this.target_list = this.todo_list.filter((todo) => todo.todo_info.is_end == false );
                }

                if( this.view_end == true ) {
                    this.target_list = this.todo_list.filter((todo) => todo.todo_info.is_end == true );
                }
                
                // 정렬
                await this.todoListSort();

                // 카운트 구하기 (전체, 진행 중, 종료)
                this.all_todo_count = this.todo_list.length;
                this.ing_todo_count = 0;
                this.end_todo_count = 0;

                for( let i = 0; i < this.all_todo_count; i++ ){
                    if( this.todo_list[i].todo_info.is_end == true) {
                        this.end_todo_count++;
                    } else {
                        this.ing_todo_count++;
                    }
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
        
    }

    /**
     * 할일 check on/off
     */
    todoClick(todo : t_todo) : void {

        // is_end를 반대로 바꿔줘야 이제 되야 할 상태를 나타냄
        const clone_todo : t_todo = JSON.parse(JSON.stringify(todo));
        clone_todo.todo_info.is_end = !clone_todo.todo_info.is_end;

        this.hodu_api_call(`api/v1/todo/${clone_todo.todo_id}`, API_METHOD.PUT, clone_todo, false)
            .then((response) => {

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 할 일 todo_list 정렬
     */
    async todoListSort() : Promise<void> {
        await this.todo_list.sort((a : t_todo, b : t_todo) => {
                
            // 한쪽은 종료되고 한쪽은 진행 중일때
            if( a.todo_info.is_end != b.todo_info.is_end ){
                
                // 왼쪽이 종료 된 경우
                if( a.todo_info.is_end == true ){
                    return 1;

                // 오른쪽이 종료 된 경우
                } else {
                    return -1;
                }

            // 둘다 종료 되었거나 둘다 진행중일때
            } else {
                // 시작일 오름차순 기준, 다른 정렬 기준 추가 되면 더 추가 해야함
                const aStartTimeMillis : number = new Date(a.todo_info.start).getTime();
                const bStartTimeMillis : number = new Date(b.todo_info.start).getTime();

                return aStartTimeMillis < bStartTimeMillis ? -1 : (aStartTimeMillis == bStartTimeMillis ? 0 : 1);
                
            }
        });
    }

    /**
     * 시작일 종료일에 맞는 포맷으로 string 반환
     */
    getDateByFormat(date : Date) : string {
        return dateFormat(date, 'yyyy.mm.dd');
    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    allView() : void {
        if( this.is_multi_delete_mode == true ) { return; }

        this.view_all = true;
        this.view_ing = false;
        this.view_end = false;

        this.getTodoList();
    }

    ingView() : void {
        if( this.ing_todo_count < 1 || this.is_multi_delete_mode == true ) { return; }

        this.view_all = false;
        this.view_ing = true;
        this.view_end = false;

        this.getTodoList();
    }

    endView() : void {
        if( this.end_todo_count < 1 || this.is_multi_delete_mode == true ) { return; }

        this.view_all = false;
        this.view_ing = false;
        this.view_end = true;

        this.getTodoList();
    }

    /**
     * more 버튼 클릭
     */
    moreButtonClick(todo : t_todo) : void {
        const todo_index : number = this.target_list.indexOf(todo); 
        todo.todo_info.more_on = true; 

        if( todo_index > -1 ){
            this.target_list.splice(todo_index, 1, todo);
        }
    }

    /**
     * li에서 마우스가 떠났을때의 이벤트
     */
    todoMouseLeave(todo : t_todo) : void {
        if( todo.todo_info.more_on == false ){
            return;
        }

        const todo_index : number = this.target_list.indexOf(todo); 
        todo.todo_info.more_on = false; 

        if( todo_index > -1 ){
            this.target_list.splice(todo_index, 1, todo);
        }
    }

    /**
     * 개인 할 일 작성버튼 클릭
     */
    createTodo() : void {
        this.doSetTodo({
            todo_id : "",          
            user_id : 0,        
            last_update_tag : "",  
            audit_modified : new Date(),  
            audit_delete_flag : false,
            todo_info : {
                summary : "",
                start : new Date(),
                end : null,
                is_end : false
            }        
        });
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(true);
    }

    /**
     * 수정 버튼 클릭
     */
    modifyTodo(todo : t_todo) : void {
        todo.todo_info.more_on = false;
        this.doSetTodo(JSON.parse(JSON.stringify(todo)));
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(true);
    }

    /**
     * 삭제 버튼 클릭
     */
    deleteButtonClick(todo : t_todo) : void {
        const todo_index : number = this.target_list.indexOf(todo); 
        todo.todo_info.deleteButtonClicked = true; 

        if( todo_index > -1 ){
            this.target_list.splice(todo_index, 1, todo);
        }
    }

    /**
     * 개인 할 일 삭제
     */
    todoDelete(index : number) : void {

        this.hodu_api_call(`api/v1/todo/${this.target_list[index].todo_id}`, API_METHOD.DELETE)
            .then((response) => {
                this.target_list.splice(index, 1);
                this.getTodoList();
            })
            .catch((e) =>{
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 삭제 확인 창에서 아니오 버튼 클릭
     */
    todoDeleteRefuse(todo : t_todo) : void {
        const todo_index : number = this.target_list.indexOf(todo); 
        todo.todo_info.deleteButtonClicked = false; 

        if( todo_index > -1 ){
            this.target_list.splice(todo_index, 1, todo);
        }
    }

    @Watch('todo_list', { immediate : false, deep : true })
    changeCount() : void {
        // 카운트 구하기 (전체, 진행 중, 종료)
        this.all_todo_count = this.todo_list.length;
        this.ing_todo_count = 0;
        this.end_todo_count = 0;

        for( let i = 0; i < this.all_todo_count; i++ ){
            if( this.todo_list[i].todo_info.is_end == true) {
                this.end_todo_count++;
            } else {
                this.ing_todo_count++;
            }
        }
    }

    @Watch('todoSelectFlag')
    reviewTodoList() : void {

        if( this.todoSelectFlag == false ){
            return;
        }

        this.getTodoList();
        this.doSetTodoSelectFlag(false);
    }

    /**
     * expired된 todo인지 구한다
     */
    isExpired(todo : t_todo) : boolean {
        return todo.todo_info.is_end == false && todo.todo_info.end != null && (new Date().getTime() >= new Date(moment(todo.todo_info.end).format()).getTime());
    }

    /**
     * Summary 가공 후 반환
     */
    getSummary(summary : string) : string {
        let return_summary : string = '<p style="min-height : 13px;">';
        return_summary += summary.replace(/\n/g, '</p><p style="min-height : 13px;">');
        return_summary += '</p>';

        return return_summary;
    }

    /**
     * 다중 삭제 모드
     */
    multiDeleteMode() : void {
        this.is_multi_delete_mode = true;
        this.$nextTick(() => { this.handleResize(); });
    }

    /**
     * 리스트 모드
     */
    listMode() : void {
        this.is_multi_delete_mode = false;
        this.multi_select_list.splice(0, this.multi_select_list.length);
        this.$nextTick(() => { this.handleResize(); });
    }

    /**
     * 할 일 다중 삭제 리스트 선택 ON / OFF
     */
    changeMultiSelect(event : any, index : number) : void {

        const multi_select_list_index_of : number = this.multi_select_list.indexOf(index);

        // 선택한 할 일이 이미 리스트에 들어있지 않다면 추가 
        if ( event.target.checked == true && multi_select_list_index_of == -1 ) {
            this.multi_select_list.push(index);
        } 

        // 선택 취소한 할 일이 리스트에 들어있는 경우 제거
        if( event.target.checked == false && multi_select_list_index_of != -1 ) {
            this.multi_select_list.splice(multi_select_list_index_of, 1);
        }

    }

    /**
     * 할 일 다중 삭제
     */
    deleteAll() : void {
        if( this.multi_select_list.length < 1 ) { return; }
        
        this.hodu_show_indicator();

        const todo_ids : string[] = [];

        for( const index of this.multi_select_list ) { todo_ids.push(this.target_list[index].todo_id); }

        this.hodu_api_call("api/v1/todo/todos", API_METHOD.DELETE, { "todo_ids" : todo_ids })
            .then(async() => {
                this.target_list.splice(0, this.target_list.length);
                await this.getTodoList();
                this.listMode();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#todoListScroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }
}

</script>

<style scoped>
    /* .toDoUl .expired input[type='checkbox']:not(old) + label { background-color:#ffe2e2;background-image: url('../../assets/images/contents/ic_svg_check_dc0.svg');background-size: 14px;  }
    .toDoUl .expired p.deadline  span { color:#ff6060; opacity:1; }
    .toDoUl .expired .title { color:#ff6060; } */
    li.on .toDoDiv { color:#a0a1a2 !important}
    li .toDoDiv { font-weight:bold; width: 300px;padding-left: 41px;margin-top: -5px; }
    textarea { width: 310px;resize: none;border: 0;box-shadow: none;max-height:auto; height:100%;overflow:hidden;background: #fff !important; padding: 18px 0 18px 20px;line-height:23px; color:#232848 !important; font-weight:bold; }
    .noti_titlebox.mToDo input {  float:right; margin: 10px 15px 0 0; }

    .notice .toDoBtn { display:none; }
    .notice.edit .toDoBtn { display:block; }
    .notice.edit a#toDoBtnReg,.notice.edit .toDoBtnEdit { display:none; }

    .notice.edit .toDoUl .delCheckDiv { display:block; }
    .toDoUl .delCheckDiv { display:none; float:left; position:relative;z-index: 100000000; }
    .toDoUl .delCheckDiv input[type='checkbox']:not(old) + label {top: 3px; border-radius: 3px !important; left:0 !important;width: 24px !important;height:24px !important;border: none; background:#c1cfd8  url(../../assets/images/contents/checked_fff.png) no-repeat 0 0;}
    .toDoUl .delCheckDiv span.title { width: 510px !important }
    .toDoUl .delCheckDiv input[type='checkbox']:not(old):checked + label { background-color: #ff6060 !important; }
    .notice.edit .toDoUl input[type='checkbox']:not(old) + label { left: 60px; }
    .notice.edit .toDoUl p.edit,.notice.edit .toDoUl .more, .notice.edit .toDoUl p.del { display: none !important }
    .notice.edit .toDoUl li:hover p.deadline, .notice.edit .toDoUl li.on p.deadline { right: 8px;  }
    .notice.edit .toDoUl li span.title { width: 370px; }
    .notice.edit li .toDoDiv { padding-left: 41px; transition:0.2s;  }
    .notice.edit .toDoUl input[type='checkbox']:not(old) + label { width:0; border: none; }
    .notice.edit .toDoListDelBtn { display:block; }
    .notice.edit li { background:#f1f3f5;border-color:#dbdfe2 }
    .notice.edit li.delOn { background:#fff; }
    .toDoListDelBtn { transition:0.2s; display:none; margin: 20px auto;position:fixed; bottom: 30px; z-index:100000000;right: 230px; width: 150px; height: 40px; line-height: 40px;border-radius: 20px; font-weight:bold; color: #fff; background:#ff6060; }
    .toDoListDelBtn:hover {background:#ff3f3f;}
    .edit .notiWrap .tabs { display: none; }
</style>

<style>

    li .toDoDiv p { margin-bottom:10px; font-size:13px }
</style>