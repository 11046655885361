<template>

    <div class="reservation_list section_ce_fix">
        
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">예약 현황</h3>
            <a class="schBtn" @click="searchOnOff">검색하기</a>
        </div>

        <!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
        <div class="searchDiv" :class="{ on : is_search_open == true }">
            <div class="schGrp">
                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                <input type="text" id="appointment_search_text" :value="search_word" placeholder="의사, 환자명으로 검색하세요" @input="search_word = $event.target.value" @keydown="eventKeyDown($event)">
                <div class="btns">
                    <!-- 검색 디브 removeClass(on) -->
                    <a href="#" class="closeBtn" @click.prevent="searchOnOff">닫기</a>
                    <a href="#" class="bt_search" @click.prevent="getAppointments"><span class="blind">찾기버튼</span></a>
                </div>
            </div>
        </div>

        <div id="" class="section_scroll">
            <!-- 시간선택 -->
            <div class="schedule_box">
                <div class="grp settingBg">
                    <div class="menuBg"></div>
                    <div class="posRel">
                        <div class="bg">
                            <div class="selectOptionDiv">
                                <select @change="changeSelectCriteria($event.target.value)">
                                    <!-- <option value="">월별</option> -->
                                    <option value="DIRECT" :selected="appointment_select_criteria == 'DIRECT'">기간별</option>
                                    <option value="WEEKLY" :selected="appointment_select_criteria == 'WEEKLY'">주별</option>
                                    <option value="DAILY" :selected="appointment_select_criteria == 'DAILY'">일별</option>
                                </select>
                            </div>
                            <div class="seperVertical"></div>
                            <!-- <h3 class="blind">시작일자와 종료일자를 선택하세요.</h3> -->
                            
                            <div class="bigGrp left" id="reservation_list_ymd_div">

                                <input type="button" id="btnPrev" class="toolbar-btn bt-prev mr10" @click="getAppointmentsByOffset(false)"/>
                                
                                <div class="grp">
                                    <label for="reservation_list_fr_ymd" class="from blind">시작일자</label>
                                    <input type="button" class="calFrom" id="reservation_list_fr_ymd" :value="`${hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(start_date)}`"/>
                                </div>
                                
                                <div class="seper">에서</div>
                                
                                <div class="grp">
                                    <label for="reservation_list_to_ymd" class="to blind">종료일자</label>
                                    <input type="button" class="calTo" id="reservation_list_to_ymd" :value="`${hodu_date_to_format_string(end_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(end_date)}`"/>
                                </div>

                                <input type="button" id="btnNext" class="toolbar-btn bt-next mr10" @click="getAppointmentsByOffset(true)"/>

                            </div>

                            <!-- 이전,다음,오늘 버튼 -->
                            <input type="button" id="btnToday" value="오늘" class="toolbar-btn today" @click="getTodayAppointments" />

                            <!-- 프린트 버튼 -->
                            <div class="btnBox fr">
                                <!-- <input type="button" id="" class="input_btn" placeholder="" value="프린트" title="프린트"/> -->
                                <!-- <input type="button" id="" class="input_btn showMore" placeholder="" value="간략보기" title="간략보기"/> -->
                                <a class="showMore" :class="{ on : preview_close == false }" @click="previewOnOff"><span></span><span></span><span></span></a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <input type="button" id="" class="btnAdd" placeholder="" value="+ 새등록" title="등록" @click="moveCreate"/>

            <!-- 간략정보 -->
            <AppointmentPreviewList 
                :event_bus="event_bus" 
                :start_date="start_date" 
                :class="{ on : preview_close == true }"
                @dailyAppointments="getDailyAppointments"
            />

            <!-- 컨텐츠 -->
            <div class="content" >
                <!-- 헤더소트 -->
                <ul class="sortHeader" id="listHeader">

                    <li class="statusHead">
                        <a class="sort" @click.prevent="statusFilterMenuOnOff($event)">현황</a>
                        <div class="dropdown" :class="{ on : is_status_filter_open == true }">
                            <ul>
                                <li @click.prevent="statusFilterAll($event)">
                                    <span><input type="checkbox" id="ReserAll" :checked="status_filter.length < 1"/>
                                    <label for="ReserAll"><span>전체</span></label></span>
                                </li>
                                <li @click.prevent="statusFilterOnOff($event, hodu_doc_enum.appointment_status_code.REQUEST)">
                                    <span class="ing"><input type="checkbox" id="reserIng" :checked="status_filter.indexOf(hodu_doc_enum.appointment_status_code.REQUEST) < 0"/>
                                    <label for="reserIng"><span>요청</span></label></span>
                                </li>
                                <li @click.prevent="statusFilterOnOff($event, hodu_doc_enum.appointment_status_code.CONFIRM)">
                                    <span class="confirmed"><input type="checkbox" id="reserConfirmed" :checked="status_filter.indexOf(hodu_doc_enum.appointment_status_code.CONFIRM) < 0"/>
                                    <label for="reserConfirmed"><span>확정</span></label></span>
                                </li>
                                <li @click.prevent="statusFilterOnOff($event, hodu_doc_enum.appointment_status_code.RECEIPT)">
                                    <span class="made"><input type="checkbox" id="reserMade" :checked="status_filter.indexOf(hodu_doc_enum.appointment_status_code.RECEIPT) < 0"/>
                                    <label for="reserMade"><span>접수</span></label></span>
                                </li>
                                <li @click.prevent="statusFilterOnOff($event, hodu_doc_enum.appointment_status_code.DIAGNOSIS)">
                                    <span class="seen"><input type="checkbox" id="reserSeen" :checked="status_filter.indexOf(hodu_doc_enum.appointment_status_code.DIAGNOSIS) < 0"/>
                                    <label for="reserSeen"><span>진료</span></label></span>
                                </li>
                                <li @click.prevent="statusFilterOnOff($event, hodu_doc_enum.appointment_status_code.NOSHOW)">
                                    <span class="noshow"><input type="checkbox" id="reserNoshow" :checked="status_filter.indexOf(hodu_doc_enum.appointment_status_code.NOSHOW) < 0"/>
                                    <label for="reserNoshow"><span>노쇼</span></label></span>
                                </li>
                                <li @click.prevent="statusFilterOnOff($event, hodu_doc_enum.appointment_status_code.DECLINE)">
                                    <span class="decline"><input type="checkbox" id="reserDecline" :checked="status_filter.indexOf(hodu_doc_enum.appointment_status_code.DECLINE) < 0"/>
                                    <label for="reserDecline"><span>거절</span></label></span>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <!-- <li class="checkBox headerSort">
                        <p class="checkBox on">
                            <input type="checkbox" id="selectAll" />
                            <label for="selectAll">전체선택</label>
                        </p>
                    </li> -->

                    <li class="num">No</li>

                    <li class="ymdHour headerSort" @click.prevent="sortTypeChange(hodu_doc_enum.appointment_sort_type.TIME)"
                        :class="{
                            sortOff  : sort_type != 'TIME',
                            sortUp   : sort_type == 'TIME' && sort_dir == 'ASC',
                            sortDown : sort_type == 'TIME' && sort_dir == 'DESC'
                        }">
                        <span>예약시간</span>
                    </li>

                    <li class="medicalHead headerSort sortOff" @click.prevent="sortTypeChange(hodu_doc_enum.appointment_sort_type.DEPARTMENT)"
                        :class="{
                            sortOff  : sort_type != 'DEPARTMENT',
                            sortUp   : sort_type == 'DEPARTMENT' && sort_dir == 'ASC',
                            sortDown : sort_type == 'DEPARTMENT' && sort_dir == 'DESC'
                        }">
                        <a class="sort" @click.prevent="departmentFilterMenuOnOff($event)">진료과</a>
                        <div class="dropdown" :class="{ on : is_department_filter_open == true }">
                            <ul>
                                <li @click="departmentFilterAll($event)">
                                    <span class="all"><input type="checkbox" id="departmentAll" :checked="department_filter.length < 1"/>
                                    <label for="departmentAll"><span>전체</span></label></span>
                                </li>

                                <!-- 의사가 존재하는 진료과만 -->
                                <li :key="department.department_code" v-for="(department, index) in computedDoctorExistDepartment" @click="departmentFilterOnOff($event, department.department_code)">
                                    <span><input type="checkbox" :id="`department_${index}`" :checked="department_filter.indexOf(department.department_code) < 0"/>
                                    <label :for="`department_${index}`"><span>{{ department.department_name }}</span></label></span>
                                </li>
                                
                                <!-- <li>
                                    <span class="doc1"><input type="checkbox" id="doc1" />
                                    <label for="doc1"><span>내과</span></label></span>
                                </li>

                                <li>
                                    <span class="doc2"><input type="checkbox" id="doc2" />
                                    <label for="doc2"><span>피부과</span></label></span>
                                </li> -->

                            </ul>
                        </div>
                    </li>

                    <li class="name headerSort sortOff" @click.prevent="sortTypeChange(hodu_doc_enum.appointment_sort_type.DOCTOR)"
                        :class="{
                            sortOff  : sort_type != 'DOCTOR',
                            sortUp   : sort_type == 'DOCTOR' && sort_dir == 'ASC',
                            sortDown : sort_type == 'DOCTOR' && sort_dir == 'DESC'
                        }">
                        <a class="sort" @click.prevent="doctorFilterMenuOnOff($event)">의사</a>
                        <div class="dropdown" :class="{ on : is_doctor_filter_open == true }">
                            <ul>
                                <li @click.prevent="doctorFilterAll($event)">
                                    <span class="all"><input type="checkbox" id="docAll" :checked="doctor_filter.length < 1"/>
                                    <label for="docAll"><span>전체</span></label></span>
                                </li>

                                <!-- 필터에 존재하지 않는 과의 의사만 -->
                                <li :key="`${doctor.department_code}___${doctor.doctor_code}`" v-for="(doctor, index) in computedNotFilteredDoctor" @click.prevent="doctorFilterOnOff($event, `${doctor.department_code}___${doctor.doctor_code}`)">
                                    <span><input type="checkbox" :id="`doc_${index}`" :checked="doctor_filter.indexOf(`${doctor.department_code}___${doctor.doctor_code}`) < 0"/>
                                    <label :for="`doc_${index}`"><span>{{ doctor.doctor_name }}</span></label></span>
                                </li>
                                <!-- <li>
                                    <span class="doc1"><input type="checkbox" id="doc1" />
                                    <label for="doc1"><span>김동현</span></label></span>
                                </li>
                                
                                <li>
                                    <span class="doc2"><input type="checkbox" id="doc2" />
                                    <label for="doc2"><span>김은영</span></label></span>
                                </li> -->

                            </ul>
                        </div>
                    </li>

                    <li class="patientHead headerSort sortOff" @click.prevent="sortTypeChange(hodu_doc_enum.appointment_sort_type.PATIENT)"
                        :class="{
                            sortOff  : sort_type != 'PATIENT',
                            sortUp   : sort_type == 'PATIENT' && sort_dir == 'ASC',
                            sortDown : sort_type == 'PATIENT' && sort_dir == 'DESC'
                        }">
                        <span>환자</span>
                    </li>

                    <li class="telHead"><span>연락처</span></li>
                    
                </ul>
                
                <!-- 현황 없음 -->
                <div class="noResult" :class="{ on : computedAppointmentSortList.length < 1 }" v-if="computedAppointmentSortList.length < 1">
                    <p>선택한 시간에 예약된 정보가 없습니다.</p>
                </div>

                <!-- 현황 리스트 -->
                <div id="appointment_list_scroll"> 
                    <ul class="reservationPeopleList">

                        <li :key="index" v-for="(appointment, index) in computedAppointmentSortList" :class="{ 
                            rejectList : appointment.status == 'DECLINE',
                            noShowList : appointment.status == 'NOSHOW', 
                        }">
                            <a class="seeDetBtn" @click="clickAppointment(appointment)">
                                <p class="statusHead"><span class="clr" :class="getStatusClassOrText(appointment, true)">{{ getStatusClassOrText(appointment) }}</span></p>
                                <p class="num">{{ index + 1}}</p>
                                <p class="ymdHour">
                                    {{ `${hodu_date_to_format_string(appointment.start_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(appointment.start_date)}` }}
                                    <span class="emTime">{{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(appointment.start_date, 'hh:mm a')) }}</span>
                                </p>
                                <p class="medicalHead">{{ appointment.department_name }}</p>
                                <p class="name">{{ appointment.doctor_name}}</p>
                                <p class="patientHead">{{ appointment.patient_name }}</p>
                                <p class="telHead">{{ appointment.user_phone_number && appointment.user_phone_number.length > 0 ? appointment.user_phone_number : '-' }}</p>
                                <!-- <a class="moreBtn"><span></span><span></span><span></span><span class="blind">더 보기</span></a> -->
                            </a>
                            
                            <input type="button" class="del" title="삭제" value="삭제" v-if="appointment.status == 'DECLINE' && getAppointmentDeletePermission() == true" @click="deleteEvent(appointment.event_id)"/>
                            
                        </li>

                        <!-- <li class="rejectList">
                            <a class="seeDetBtn">
                                <p class="statusHead"><span class="reject clr">거절</span></p> -->
                                <!-- <p class="checkBox headerSort">
                                    <span class="patient1"><input type="checkbox" id="patient1" />
                                    <label for="patient1"><span>전체</span></label></span>
                                </p> -->
                                <!-- <p class="num">1</p>
                                <p class="ymdHour">2020.04.17 금 <span class="emTime">04:20pm</span></p>
                                <p class="medicalHead">내과</p>
                                <p class="name">김동현</p>
                                <p class="patientHead">박진우</p>
                                <p class="telHead">010-0202-0202</p>
                            </a>
                            <input type="button" class="del" title="삭제" value="삭제" /> -->
                            <!-- <a class="moreBtn"><span></span><span></span><span></span><span class="blind">더 보기</span></a> -->
                        <!-- </li> -->
                        <!-- <li>
                            <a class="seeDetBtn" @click="showApplyModal">
                                <p class="statusHead"><span class="ing clr">요청</span></p> -->
                                <!-- <p class="checkBox headerSort">
                                    <span class="patient1"><input type="checkbox" id="patient1" />
                                    <label for="patient1"><span>전체</span></label></span>
                                </p> -->
                                <!-- <p class="num">1</p>
                                <p class="ymdHour">2020.04.17 금 <span class="emTime">04:20pm</span></p>
                                <p class="medicalHead">내과</p>
                                <p class="name">김동현</p>
                                <p class="patientHead">임주형</p>
                                <p class="telHead">010-0202-0202</p>
                            </a> -->
                            <!-- <a class="moreBtn"><span></span><span></span><span></span><span class="blind">더 보기</span></a> -->
                        <!-- </li> -->
                        <!-- <li>
                            <a  class="seeDetBtn" @click="moveDetail('event_id')">
                                <p class="statusHead"><span class="made clr">접수</span></p> -->
                                <!-- <p class="checkBox headerSort">
                                    <span class="patient2"><input type="checkbox" id="patient2" />
                                    <label for="patient2"><span>전체</span></label></span>
                                </p> -->
                                <!-- <p class="num">2</p>
                                <p class="ymdHour">2020.04.17 금 <span class="emTime">04:10pm</span></p>
                                <p class="medicalHead">내과</p>
                                <p class="name">김동현</p>
                                <p class="patientHead">김이정</p>
                                <p class="telHead">010-2020-0230</p>
                            </a> -->
                            <!-- <a class="moreBtn"><span></span><span></span><span></span><span class="blind">더 보기</span></a> -->
                        <!-- </li> -->
                        <!-- <li>
                            <a class="seeDetBtn" @click="moveDetail">
                                <p class="statusHead"><span class="confirmed clr">확정</span></p> -->
                                <!-- <p class="checkBox headerSort">
                                    <span class="patient3"><input type="checkbox" id="patient3" />
                                    <label for="patient3"><span>전체</span></label></span>
                                </p> -->
                                <!-- <p class="num">3</p>
                                <p class="ymdHour">2020.04.17 금 <span class="emTime">04:00pm</span></p>
                                <p class="medicalHead">내과</p>
                                <p class="name">김동현</p>
                                <p class="patientHead">Josephine Langford</p>
                                <p class="telHead">010-0202-0202</p>
                            </a> -->
                            <!-- <a class="moreBtn"><span></span><span></span><span></span><span class="blind">더 보기</span></a> -->
                        <!-- </li> -->
                        <!-- <li>
                            <a class="seeDetBtn" @click="moveDetail">
                                <p class="statusHead"><span class="seen clr">진료</span></p> -->
                                <!-- <p class="checkBox headerSort">
                                    <span class="patient4"><input type="checkbox" id="patient4" />
                                    <label for="patient4"><span>전체</span></label></span>
                                </p> -->
                                <!-- <p class="num">4</p>
                                <p class="ymdHour">2020.04.17 금 <span class="emTime">03:50pm</span></p>
                                <p class="medicalHead">내과</p>
                                <p class="name">김동현</p>
                                <p class="patientHead">금연아</p>
                                <p class="telHead">010-0202-0202</p>
                            </a> -->
                            <!-- <a class="moreBtn"><span></span><span></span><span></span><span class="blind">더 보기</span></a> -->
                        <!-- </li> -->

                        <!-- <li class="noShowList">
                            <a class="seeDetBtn">
                                <p class="statusHead"><span class="noshow clr">노쇼</span></p> -->
                                <!-- <p class="checkBox headerSort">
                                    <span class="patient5"><input type="checkbox" id="patient5" />
                                    <label for="patient5"><span>전체</span></label></span>
                                </p> -->
                                <!-- <p class="num">5</p>
                                <p class="ymdHour">2020.04.17 금 <span class="emTime">03:40pm</span></p>
                                <p class="medicalHead">내과</p>
                                <p class="name">김동현</p>
                                <p class="patientHead">박상현</p>
                                <p class="telHead">010-0202-0202</p>
                            </a> -->
                            <!-- <a class="moreBtn"><span></span><span></span><span></span><span class="blind">더 보기</span></a> -->
                        <!-- </li> -->

                    </ul>
                </div>
					
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { SORT_TYPE, OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');
const ModalInfo   = namespace('ModalInfo');

import { ResizeObserver } from 'vue-resize';

import AppointmentPreviewList from '@/components/hodu_d/AppointmentPreviewList.vue';

import moment from 'moment';

import { hodu_doc_object, hodu_doc_enum, hodu_doc_modal_info } from '@/model/hodudoc';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { t_event } from '@/model/event';
import { hodu_color } from '@/common/color';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        AppointmentPreviewList, ResizeObserver
    },
}) 
export default class AppointmentList extends Mixins(VueHoduCommon) {

    hodu_doc_enum : any = hodu_doc_enum;

    /**
     * 정렬된 예약 리스트
     */
    get computedAppointmentSortList() : hodu_doc_object.appointment_info[] {

        let sort_appointment_list : hodu_doc_object.appointment_info[] = JSON.parse(JSON.stringify(this.appointment_list));

        // 상태값 필터
        for( const status of this.status_filter ) {
            sort_appointment_list = sort_appointment_list.filter(item => item.status != status);
        }

        // 진료과 필터
        for( const department_code of this.department_filter ) {
            sort_appointment_list = sort_appointment_list.filter(item => item.department_code != department_code);
        }

        // 의사 필터
        for( const code of this.doctor_filter ) {
            sort_appointment_list = sort_appointment_list.filter(item => `${item.department_code}___${item.doctor_code}` != code);
        }

        // 정렬
        sort_appointment_list = sort_appointment_list.sort((item1, item2) : number => {
            
            const target_date_item1 : Date = new Date(item1.start_date); 
            const target_date_item2 : Date = new Date(item2.start_date); 

            // 아래로 내려가는 타입 여부 체크 (거절, 노쇼, 진료)
            // const is_down_type_item1 : boolean = item1.status == hodu_doc_enum.appointment_status_code.DECLINE ||
            //                                      item1.status == hodu_doc_enum.appointment_status_code.NOSHOW  ||
            //                                      item1.status == hodu_doc_enum.appointment_status_code.DIAGNOSIS;

            // const is_down_type_item2 : boolean = item2.status == hodu_doc_enum.appointment_status_code.DECLINE ||
            //                                      item2.status == hodu_doc_enum.appointment_status_code.NOSHOW  ||
            //                                      item2.status == hodu_doc_enum.appointment_status_code.DIAGNOSIS;

            // sort level 지정 (높을수록 밑에 나옴)
            let item1_sort_level : number = 0;
            switch( item1.status ) {
                case hodu_doc_enum.appointment_status_code.REQUEST  : item1_sort_level = 0; break;
                case hodu_doc_enum.appointment_status_code.CONFIRM  : item1_sort_level = 1; break;
                case hodu_doc_enum.appointment_status_code.RECEIPT  : item1_sort_level = 2; break;
                case hodu_doc_enum.appointment_status_code.DIAGNOSIS: item1_sort_level = 3; break;
                case hodu_doc_enum.appointment_status_code.NOSHOW   : item1_sort_level = 4; break;
                case hodu_doc_enum.appointment_status_code.DECLINE  : item1_sort_level = 5; break;
            }

            let item2_sort_level : number = 0;
            switch( item2.status ) {
                case hodu_doc_enum.appointment_status_code.REQUEST  : item2_sort_level = 0; break;
                case hodu_doc_enum.appointment_status_code.CONFIRM  : item2_sort_level = 1; break;
                case hodu_doc_enum.appointment_status_code.RECEIPT  : item2_sort_level = 2; break;
                case hodu_doc_enum.appointment_status_code.DIAGNOSIS: item2_sort_level = 3; break;
                case hodu_doc_enum.appointment_status_code.NOSHOW   : item2_sort_level = 4; break;
                case hodu_doc_enum.appointment_status_code.DECLINE  : item2_sort_level = 5; break;
            }
            
            // 다른 sort_level 인 경우
            if( item1_sort_level != item2_sort_level ) {
                return item1_sort_level < item2_sort_level ? -1 : 1;
            }

            // 같은 타입인 경우 sort_type 에 따라서 정렬
            else {

                // 시간 기준 정렬
                if( this.sort_type == hodu_doc_enum.appointment_sort_type.TIME ) {
                    
                    // 같은 시간
                    if( target_date_item1.getTime() == target_date_item2.getTime() ) { return 0; }

                    // ASC
                    if( this.sort_dir == SORT_TYPE.ASC ) {
                        return target_date_item1.getTime() > target_date_item2.getTime() ? 1 : -1;
                    }

                    // DESC
                    return target_date_item1.getTime() < target_date_item2.getTime() ? 1 : -1;

                }

                // 진료과 기준 정렬
                else if ( this.sort_type == hodu_doc_enum.appointment_sort_type.DEPARTMENT ) {
                    
                    // 같은 진료과
                    if( item1.department_name == item2.department_name ) { return 0; }

                    // ASC
                    if( this.sort_dir == SORT_TYPE.ASC ) {
                        return item1.department_name > item2.department_name ? 1 : -1;
                    }

                    // DESC
                    return item1.department_name < item2.department_name ? 1 : -1;

                }

                // 의사 기준 정렬
                else if ( this.sort_type == hodu_doc_enum.appointment_sort_type.DOCTOR ) {

                    // 같은 의사
                    if( item1.doctor_name == item2.doctor_name ) { return 0; }

                    // ASC
                    if( this.sort_dir == SORT_TYPE.ASC ) {
                        return item1.doctor_name > item2.doctor_name ? 1 : -1;
                    }

                    // DESC
                    return item1.doctor_name < item2.doctor_name ? 1 : -1;

                }
                
                // 환자 기준 정렬
                else if ( this.sort_type == hodu_doc_enum.appointment_sort_type.PATIENT ) {

                    // 같은 환자
                    if( item1.patient_name == item2.patient_name ) { return 0; }

                    // ASC
                    if( this.sort_dir == SORT_TYPE.ASC ) {
                        return item1.patient_name > item2.patient_name ? 1 : -1;
                    }

                    // DESC
                    return item1.patient_name < item2.patient_name ? 1 : -1;

                }

            }

            return 0;
        })

        return sort_appointment_list;
    }

    /**
     * 의사가 한 명이라도 존재하는 진료과만 반환
     */
    get computedDoctorExistDepartment() : hodu_doc_object.department_info[] {

        let doctor_exist_department : hodu_doc_object.department_info[] = [];

        if( this.department_info != null && this.doctor_info != null ) {

            for( const department of this.department_info ) {

                if( this.doctor_info.filter(item => item.department_code == department.department_code).length > 0 ) {
                    doctor_exist_department.push(department);
                }

            }

        }

        return doctor_exist_department;
    }

    /**
     * 필터에 들어 있는 진료과를 제외한 의사 리스트 반환
     */
    get computedNotFilteredDoctor() : hodu_doc_object.doctor_info[] {

        let doctor_not_filtered_list : hodu_doc_object.doctor_info[] = [];

        if( this.doctor_info != null ) {

            doctor_not_filtered_list = doctor_not_filtered_list.concat(this.doctor_info);

            for( const department_code of this.department_filter ) {        
                const temp : hodu_doc_object.doctor_info[] = JSON.parse(JSON.stringify(doctor_not_filtered_list.filter(item => item.department_code != department_code)));                
                doctor_not_filtered_list.splice(0, doctor_not_filtered_list.length);
                doctor_not_filtered_list = doctor_not_filtered_list.concat(temp);

                // 의사 필터에도 제거
                this.doctor_filter = this.doctor_filter.filter(item => new RegExp(`${department_code}___`).test(item) == false);
            }
        }

        return doctor_not_filtered_list;
    }

    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentApplyModalInfo ?: (params : hodu_doc_modal_info.AppointmentApplyModalInfo) => void;

    event_bus : Vue = new Vue();

    start_date : Date = new Date();
    end_date : Date = new Date();

    is_search_open : boolean = false;
    preview_close : boolean = true;

    search_word : string = "";

    appointment_list : hodu_doc_object.appointment_info[] = [];

    appointment_select_criteria : hodu_doc_enum.appointment_select_criteria = hodu_doc_enum.appointment_select_criteria.DIRECT;
    date_term : number = 0;

    // 정렬
    sort_type : hodu_doc_enum.appointment_sort_type = hodu_doc_enum.appointment_sort_type.TIME;
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    // 필터
    is_status_filter_open     : boolean = false;
    is_department_filter_open : boolean = false;
    is_doctor_filter_open     : boolean = false;

    status_filter : hodu_doc_enum.appointment_status_code[] = [];
    department_filter : string[] = []; // ${department_code}
    doctor_filter : string[] = [];     // ${department_code}___${doctor_code}

    get_appointments_interval : number | undefined;

    beforeMount() : void {
    
        // 넘어온 날짜
        const tag = Number(this.$route.params.tag);

        this.start_date = moment(tag).set("hour", 0)
                                  .set("minute", 0)
                                  .set("second", 0)
                                  .set("millisecond", 0).toDate();

        this.end_date = moment(this.start_date).set("hour", 23)
                                               .set("minute", 59)
                                               .set("second", 59)
                                               .set("millisecond", 999).toDate();
    }

    mounted() : void {
        const vue = this;

        this.getAppointments();

        // 2초마다 예약 재조회
        this.get_appointments_interval = window.setInterval(async() => {
            await this.getAppointments(false);
        }, 2000);

        this.setScroll(); 

        // $(".sortHeader a.sort").click(function(event){
        //     event.stopPropagation();

        //     // 선택외 다른 dropdown닫기
        //     $(this).parent().siblings().find(".dropdown").removeClass("on");
        //     // 선택된 dropdown만 열기
        //     $(this).parent().find(".dropdown").toggleClass("on");
            
        // });

        // $(".showMore").click(function(){
        //     // 
        //     $(this).toggleClass("on");
        //     $(".previewContent").toggleClass("on");
            
        // });

        // jquery-ui datepicker 옵션
        const datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText);
                inst.input.val(mnt.format('YYYY.MM.DD') + ` ${vue.getDayOfWeekByDate(mnt.toDate())}`);

                // 직접 간격 선택시 
                if( vue.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.DIRECT ) {
                    
                    if( id == 'reservation_list_fr_ymd' ) {
                        vue.start_date = mnt.set("hour", 0)
                                            .set("minute", 0)
                                            .set("second", 0)
                                            .set("millisecond", 0).toDate();

                        // 종료일이 시작일보다 작아진 경우
                        if( vue.end_date.getTime() < vue.start_date.getTime() ) {
                            vue.end_date = mnt.set("hour", 23)
                                              .set("minute", 59)
                                              .set("second", 59)
                                              .set("millisecond", 999).toDate();
                        }
                    }

                    if( id == 'reservation_list_to_ymd' ) {
                        vue.end_date = mnt.set("hour", 23)
                                          .set("minute", 59)
                                          .set("second", 59)
                                          .set("millisecond", 999).toDate();

                        // 종료일이 시작일보다 작아진 경우
                        if( vue.end_date.getTime() < vue.start_date.getTime() ) {
                            vue.start_date = mnt.set("hour", 0)
                                                .set("minute", 0)
                                                .set("second", 0)
                                                .set("millisecond", 0).toDate();
                        }
                    }

                    vue.date_term = vue.getDateDiff(vue.start_date, vue.end_date);

                    // 재조회
                    vue.getAppointments();

                    return;
                }

                vue.start_date = mnt.set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .set("millisecond", 0).toDate();

                vue.end_date = mnt.set("hour", 23)
                                  .set("minute", 59)
                                  .set("second", 59)
                                  .set("millisecond", 999).toDate();

                // 시작일 변경 (주별)
                if( id == 'reservation_list_fr_ymd' && vue.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
                    vue.end_date = moment(vue.end_date).set("date", vue.end_date.getDate() + 7).toDate();
                } 

                // 종료일 변경 (주별)
                else if( id == 'reservation_list_to_ymd' && vue.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
                    vue.start_date = moment(vue.start_date).set("date", vue.start_date.getDate() - 7).toDate();
                }

                vue.date_term = vue.getDateDiff(vue.start_date, vue.end_date);

                // 재조회
                vue.getAppointments();

            },
        };

        // @ts-ignore
        $('#reservation_list_fr_ymd').datepicker(datepicker_option);

        // @ts-ignore
        $('#reservation_list_to_ymd').datepicker(datepicker_option);
    }

    /**
     * 종료 전 interval 제거
     */
    beforeDestroy() : void {
        if( this.get_appointments_interval ) { clearInterval(this.get_appointments_interval); }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_box_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const preview_content_height : number | undefined = $('.previewContent').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#appointment_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (schedule_box_height ? schedule_box_height : 0)
                                           - (preview_content_height ? preview_content_height : 0)
                                           - (sort_header_height ? sort_header_height : 0),
        });

    }

    /**
     * 예약 리스트 가져오기
     */
    getAppointments(is_show_error_report : boolean = true) : void {

        if( this.search_word != null && this.search_word.length > 0 && this.search_word.length < 2 ) {
            this.hodu_show_dialog('alert', '검색어를 2자 이상 입력해주세요', ["확인"], [() => {}]);
            return;
        }

        const start : string = moment(this.start_date).utc().format();
        const end   : string = moment(this.end_date).utc().format();

        const search_word : string = encodeURIComponent(this.search_word);

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/appointment?start=${start}&end=${end}&search_word=${search_word}`, API_METHOD.GET, null, false)
            .then((response) => {
                console.log(response);

                this.appointment_list.splice(0, this.appointment_list.length);
                this.appointment_list = this.appointment_list.concat(response.data.data);

            })  
            .catch((e) => {
                this.hodu_error_process(e, true, false, !is_show_error_report);
            })
    }

    /**
     * 예약 수정
     */
    async updateEvent(event_id : string, status_code : hodu_doc_enum.appointment_status_code) : Promise<void> {
        
        // 이벤트
        let lo_event !: t_event;

        // event_id로 먼저 조회
        
        let calendar_id : string = this.calendar_id;
        let scope       : string = OWNER_TYPE.GROUP;
        let scope_id    : number = this.scope_group_id;

        await this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                lo_event = JSON.parse(JSON.stringify(response.data.data.event_data));
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })

        // 조회 결과 없으면 존재하지 않는 예약 처리
        if( lo_event == null || lo_event.event_data.appointment == null ) {
            this.hodu_show_dialog('cancel', '존재하지 않는 예약 입니다', ['확인'], [
                () => { this.getAppointments(); }
            ])
            return;
        }

        // 코드 세팅
        lo_event.event_data.appointment.appointment_status = status_code;

        // 컬러 세팅
        switch(status_code) {
            case hodu_doc_enum.appointment_status_code.RECEIPT:
                lo_event.event_data.color = hodu_color.appointment_receipt;
                break;

            case hodu_doc_enum.appointment_status_code.NOSHOW:
                lo_event.event_data.color = hodu_color.appointment_noshow;
                break;
        }

        // 수정 API
        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.PUT, lo_event.event_data)
            .then((response) => {
                console.log(response);
                this.getAppointments();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 스테이터스에 따른 텍스트 반환
     */
    getStatusClassOrText(appointment : hodu_doc_object.appointment_info, is_get_class : boolean = false) : string {

        switch( appointment.status ) {
            case hodu_doc_enum.appointment_status_code.REQUEST:   return is_get_class ? "ing"       : "요청";
            case hodu_doc_enum.appointment_status_code.DECLINE:   return is_get_class ? "reject"    : "거절";
            case hodu_doc_enum.appointment_status_code.CONFIRM:   return is_get_class ? "confirmed" : "확정";
            case hodu_doc_enum.appointment_status_code.RECEIPT:   return is_get_class ? "made"      : "접수";
            case hodu_doc_enum.appointment_status_code.DIAGNOSIS: return is_get_class ? "seen"      : "진료";
            case hodu_doc_enum.appointment_status_code.NOSHOW:    return is_get_class ? "noshow"    : "노쇼";
            default: return "";
        }
        
    }

    /**
     * 조회 기준 변경 (일별, 주별)
     */
    changeSelectCriteria(value : hodu_doc_enum.appointment_select_criteria) : void {
        if ( this.appointment_select_criteria == value ) { return; }

        this.appointment_select_criteria = value;
        
        if( this.appointment_select_criteria != hodu_doc_enum.appointment_select_criteria.DIRECT ) {
            this.end_date = moment(this.start_date).set("hour", 23)
                                                   .set("minute", 59)
                                                   .set("second", 59)
                                                   .set("millisecond", 999).toDate();
        }
        
        // 주별일때는 7일 더한 값으로 변경
        if( this.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
            this.end_date = moment(this.end_date).set("date", this.start_date.getDate() + 7).toDate();
        }

        this.date_term = this.getDateDiff(this.start_date, this.end_date);

        // 조회
        this.getAppointments();
    }

    /**
     * 시작일이 오늘인 예약 리스트 조회
     */
    getTodayAppointments() : void {
        this.start_date = moment().set("hour", 0)
                                  .set("minute", 0)
                                  .set("second", 0)
                                  .set("millisecond", 0).toDate();

        this.end_date = moment(this.start_date).set("hour", 23)
                                               .set("minute", 59)
                                               .set("second", 59)
                                               .set("millisecond", 999).toDate();

        // 주별이라면 종료일을 7일 후로 설정
        if( this.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
            this.end_date = moment(this.end_date).set("date", this.start_date.getDate() + 7).toDate();
        }

        this.date_term = this.getDateDiff(this.start_date, this.end_date);

        this.getAppointments();
    }

    /**
     * 이전, 다음 구간의 예약 리스트 조회
     */
    getAppointmentsByOffset(is_next : boolean) : void {

        this.start_date = moment(this.start_date).set("date", this.start_date.getDate() + (is_next ? (this.date_term == 0 ?  1 :  this.date_term) 
                                                                                                   : (this.date_term == 0 ? -1 : -this.date_term) ))
                                                 .set("hour", 0)
                                                 .set("minute", 0)
                                                 .set("second", 0)
                                                 .set("millisecond", 0).toDate();

        // 직접 선택이라면 정해진 간격대로 설정한다
        if( this.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.DIRECT ) {
            this.end_date = moment(this.start_date).set("date", this.start_date.getDate() + this.date_term)
                                                   .set("hour", 23)
                                                   .set("minute", 59)
                                                   .set("second", 59)
                                                   .set("millisecond", 999).toDate();
        }

        // 직접 선택이 아니라면 오늘 시점으로 간격을 맞춘다
        if( this.appointment_select_criteria != hodu_doc_enum.appointment_select_criteria.DIRECT )  {
            this.end_date = moment(this.start_date).set("hour", 23)
                                                   .set("minute", 59)
                                                   .set("second", 59)
                                                   .set("millisecond", 999).toDate();


            // 주별이라면 종료일을 7일 후로 설정
            if( this.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
                this.end_date = moment(this.end_date).set("date", this.start_date.getDate() + 7).toDate();
            }
        }

        this.getAppointments();
    }

    /**
     * 해당 일자의 예약 리스트를 조회한다
     */
    getDailyAppointments(date : Date) : void {
        this.start_date = moment(date).set("hour", 0)
                                      .set("minute", 0)
                                      .set("second", 0)
                                      .set("millisecond", 0).toDate();

        this.end_date = moment(this.start_date).set("hour", 23)
                                               .set("minute", 59)
                                               .set("second", 59)
                                               .set("millisecond", 999).toDate();

        this.appointment_select_criteria = hodu_doc_enum.appointment_select_criteria.DAILY;
        this.getAppointments();
    }

    /**
     * 검색창 열기 / 닫기
     */
    searchOnOff() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_word = "";
            this.getAppointments();
        }

        // 검색 창 열릴때 검색 창에 포커스 가도록
        else {
            this.$nextTick(() => { $('#appointment_search_text').focus(); });
        }
    }

    /**
     * 예약 검색 keydown
     */
    eventKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.getAppointments();
    }

    /**
     * 예약 등록으로 이동
     */
    moveCreate() : void {
        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/create`);
    }

    /**
     * 예약 상세 화면으로 이동
     */
    moveDetail(event_id : string) : void {
        if( !this.doSetAppointmentDetailInfo ) { return; }

        this.doSetAppointmentDetailInfo({
            event : null,
            is_patient : false,
        });
        
        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event_id}`);
    }

    /**
     * 예약 승인 모달 띄우기
     */
    showApplyModal(event_id : string) : void {
        if( this.doSetAppointmentApplyModalInfo ) { 
            this.doSetAppointmentApplyModalInfo({ 
                show_modal : true,
                event_id  : event_id,
                apply_callback : this.getAppointments 
            }); 
        }
    }

    /**
     * 월별 예약 건수 리스트 ON / OFF
     */
    previewOnOff() : void {
        this.preview_close = !this.preview_close;
        setTimeout(() => { this.handleResize(); }, 200);
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : hodu_doc_enum.appointment_sort_type) : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }

        // console.log(`[${this.sort_type}, ${this.sort_dir}]`);
    }

    /**
     * 상태값 필터창 ON / OFF
     */
    statusFilterMenuOnOff(event : Event) : void {
        event.stopPropagation();
        this.is_status_filter_open     = !this.is_status_filter_open;
        this.is_department_filter_open = false;
        this.is_doctor_filter_open     = false;
    }

    /**
     * 상태값 필터 전체 체크
     */
    statusFilterAll(event : Event) : void {
        // event.stopPropagation();

        // 전부 필터에 존재하지 않는다면
        if( this.status_filter.length < 1 ) {
            this.status_filter.splice(0 ,this.status_filter.length);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.REQUEST);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.DECLINE);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.CONFIRM);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.RECEIPT);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.DIAGNOSIS);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.NOSHOW);
            return;
        }

        // 하나라도 필터에 존재한다면
        this.status_filter.splice(0 ,this.status_filter.length);
    }

    /**
     * 상태값 필터에 추가 / 제거
     */
    statusFilterOnOff(event : Event, value : hodu_doc_enum.appointment_status_code) {
        // event.stopPropagation();
        
        if ( this.status_filter.indexOf(value) < 0 ) { 
            this.status_filter.push(value);
            return;
        }

        this.status_filter.splice(this.status_filter.indexOf(value), 1);
    }

    /**
     * 진료과 필터창 ON / OFF
     */
    departmentFilterMenuOnOff(event : Event) : void {
        event.stopPropagation();
        this.is_status_filter_open     = false;
        this.is_department_filter_open = !this.is_department_filter_open;
        this.is_doctor_filter_open     = false;
    }

    /**
     * 진료과 필터 전체 체크
     */
    departmentFilterAll(event : Event) : void {
        // event.stopPropagation();

        // 전부 필터에 존재하지 않는다면
        if( this.department_filter.length < 1 ) {
            this.department_filter.splice(0, this.department_filter.length);
            for( const department of this.computedDoctorExistDepartment ) {
                this.department_filter.push(department.department_code);
            }
            return;
        }

        // 하나라도 필터에 존재한다면
        this.department_filter.splice(0 ,this.department_filter.length);
    }

    /**
     * 진료과 필터에 추가 / 제거
     */
    departmentFilterOnOff(event : Event, value : string) {
        // event.stopPropagation();
        
        if ( this.department_filter.indexOf(value) < 0 ) { 
            this.department_filter.push(value);
            return;
        }

        this.department_filter.splice(this.department_filter.indexOf(value), 1);
    }

    /**
     * 의사 필터창 ON / OFF
     */
    doctorFilterMenuOnOff(event : Event) : void {
        event.stopPropagation();
        this.is_status_filter_open     = false;
        this.is_department_filter_open = false;
        this.is_doctor_filter_open     = !this.is_doctor_filter_open;
    }

    /**
     * 의사 필터 전체 체크
     */
    doctorFilterAll(event : Event) : void {
        // event.stopPropagation();

        // 전부 필터에 존재하지 않는다면
        if( this.doctor_filter.length < 1 ) {
            this.doctor_filter.splice(0, this.doctor_filter.length);
            for( const doctor of this.computedNotFilteredDoctor ) {
                this.doctor_filter.push(`${doctor.department_code}___${doctor.doctor_code}`);
            }
            return;
        }

        // 하나라도 필터에 존재한다면
        this.doctor_filter.splice(0 ,this.doctor_filter.length);
    }

    /**
     * 의사 필터에 추가 / 제거
     */
    doctorFilterOnOff(event : Event, value : string) {
        // event.stopPropagation();
        
        if ( this.doctor_filter.indexOf(value) < 0 ) { 
            this.doctor_filter.push(value);
            return;
        }

        this.doctor_filter.splice(this.doctor_filter.indexOf(value), 1);
    }

    /**
     * 예약 리스트 아이템 클릭 했을때 status에 따라서 다른 일을 함
     */
    clickAppointment(appointment : hodu_doc_object.appointment_info) : void {

        switch(appointment.status) {

            /**
             * 요청, 거절
             */
            case hodu_doc_enum.appointment_status_code.REQUEST:
            case hodu_doc_enum.appointment_status_code.DECLINE:
                this.showApplyModal(appointment.event_id);
                break;

            /**
             * 확정
             */
            case hodu_doc_enum.appointment_status_code.CONFIRM:
                // 노쇼, 접수로 변경 할 수 있는 모달
                this.hodu_show_dialog('alert', '예약 상태를 변경 하시겠습니까?', ['취소', '노쇼', '접수'], [
                    () => {},
                    () => { this.updateEvent(appointment.event_id ,hodu_doc_enum.appointment_status_code.NOSHOW); },
                    () => { this.updateEvent(appointment.event_id ,hodu_doc_enum.appointment_status_code.RECEIPT); }
                ]);
                break;
            /**
             * 접수, 진료, 노쇼
             */
            case hodu_doc_enum.appointment_status_code.RECEIPT:
            case hodu_doc_enum.appointment_status_code.DIAGNOSIS:
            case hodu_doc_enum.appointment_status_code.NOSHOW:
                this.moveDetail(appointment.event_id);
                break;

        }

    }

    /**
     * 필터 영역 외에 다른 곳을 누른다면 닫는다 
     */
    // filterOff(event : Event) : void {
    //     this.is_status_filter_open     = false;
    //     this.is_department_filter_open = false;
    //     this.is_doctor_filter_open     = false;
    // }

    /**
     * 예약(일정) 삭제 권한 반환
     */
    getAppointmentDeletePermission() : boolean {
        return this.is_group_permmision(this.scope_group_id, "event", "delete");
    }

    /**
     * 예약 삭제
     */
    deleteEvent(event_id : string) : void {

        this.hodu_show_dialog("cancel", "예약을 삭제하시겠습니까?", ["아니오", "예"], [
            () => {},
            () => {
                const calendar_id : string = this.calendar_id;
                const scope       : string = OWNER_TYPE.GROUP;
                const scope_id    : number = this.scope_group_id;
                
                const url : string = `api/v1/calendars/${ calendar_id }/events/${ scope }/${ scope_id }/${ event_id }?push_yn=false`;

                this.hodu_api_call(url, API_METHOD.DELETE)
                    .then((response) => { 
                        this.getAppointments();
                    })
                    .catch((e) => { 
                        this.hodu_error_process(e, true, false); 
                    });
            },
        ]);
        
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // 이벤트 버스 리사이즈
        this.event_bus.$emit('previewResize');
        
        // @ts-ignore
        $('#appointment_list_scroll').mCustomScrollbar('destroy');
        this.setScroll();
    }

}
</script>

<style scoped>
    
    /* 기간별, 월별,일별,주별 */
    .selectOptionDiv {float:left; padding-left: 20px;box-sizing: border-box; height: 60px;line-height:60px;display:inline-block; font-size: 14px; font-weight:bold;}
    .selectOptionDiv select { position: static !important; cursor:pointer; padding: 0 10px; padding-right:20px; line-height:40px; font-size: 14px;  font-weight:bold;-webkit-appearance: none;-moz-appearance: none;text-indent: 1px;text-overflow: '';}
    .seperVertical { display:block;width:2px;height: 20px; margin-top:22px; background:#f1f3f5; float:left; }

    .showMore { position:relative; transition:0.1s; margin-top: 2px;display: inline-block;font-size: 0;width: 35px;height: 35px;border-radius: 50%;background: #f1f3f5;-webkit-box-sizing: border-box;box-sizing: border-box;text-align: center;float: right;margin-left: 20px;}
    .showMore span { transition:0.1s; display: inline-block;width: 4px;height: 4px;border-radius: 5px;background: #232848;margin: 0 2px;margin-top: 16px;float: left; }
    .showMore span:first-child { margin-left:7px }
    .showMore:hover { background:#477fff }
    .showMore:hover span {background-color: #fff; }
    .showMore.on { background-color:#477fff }
    .showMore.on span {background-color:#fff; margin:0; position: absolute;top: 10px;left: 16px;width: 2px;height: 16px; transform: rotate(45deg); -webkit-transform: rotate(45deg);   }
    .showMore.on span:first-child {left: 16px; transform: rotate(-45deg); -webkit-transform: rotate(-45deg); }

    .btnBox { padding: 11px 30px 0 0; }
    .bt-prev, .bt-next { margin-top:0 !important; }
    .bt-prev { margin-right:0 !important; }
    .settingBg .seper { margin-left: 10px; margin-right:10px; }
    .settingBg .left { margin-left: 20px; }
    .toolbar-btn.today { line-height: 33px; margin-top: 14px !important; transition:0.2s; }
    .toolbar-btn.today:hover { background-color:#e7eaec !important; }

    /* sortHeader */
    .sortHeader .checkBox.headerSort label { margin-top:17px; }
    .statusHead {transition:0.2s; width: 10%; box-sizing:border-box; padding-left: 40px; }
    .num { transition:0.2s;width: 6%; font-weight:bold; }
    .ymdHour { transition:0.2s;width:19%; }
    .medicalHead {transition:0.2s; width:15%; }
    .name { transition:0.2s;width: 16%; }
    .patientHead {transition:0.2s; width:17%; }
    .telHead { transition:0.2s;width:17%; }
    .sortHeader .headerSort { transition: 0s !important; }

    .reservationPeopleList .patientHead.fe { background:  url('../../assets/images/contents/ic_female.png') no-repeat center left; padding-left: 30px; }
    .reservationPeopleList .patientHead.ma { background:  url('../../assets/images/contents/ic_male.png') no-repeat center left; padding-left: 30px; }

    /* #listHeader { margin-top:27px; } */

    .btnAdd { position:absolute; right: 40px; bottom:40px; }
    .btnAdd { opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .btnAdd:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}

    .settingBg .left .grp { max-width: 115px!important; min-width: 115px !important; }
    .settingBg .left .grp input.calFrom, .settingBg .left .grp input.calTo {width: 115px;padding-right: 0; background:none; text-align:center; margin-right:0 !important}
    .checkBox input[type='checkbox']:not(old) + label {background-color: #c1cfd8 !important;border: 2px solid #c1cfd8 !important;background-size:21px !important;display: inline-block;padding: 0;width: 20px;height: 20px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../assets/images/contents/checked_fff.png) no-repeat 0px 0px;background-size: 21px;font-size: 0;margin-right: 8px;}
    .checkBox input[type='checkbox']:not(old):checked + label {background-color: #477fff !important;border: 2px solid #477fff !important;background-size:21px !important;}

    .reservationPeopleList > li {overflow:hidden; transition:0.2s;position:relative; }
    .reservationPeopleList > li > a.seeDetBtn {overflow:hidden;display: block;width: 100%;height: 100%;}
    .reservationPeopleList > li:hover {transition:0.2s; background:#f7f9fb; }
    .reservationPeopleList > li:hover .moreBtn { opacity:1; }
    .moreBtn { opacity:0; transition:0.2s; position:absolute;border-radius: 50%; width: 35px; height: 35px; background:rgba(0,0,0,0.1); line-height: 35px; text-align: center;box-sizing:border-box; padding-left:1.5px; right: 20px;top:12px; }
    .moreBtn:hover { background:rgba(0,0,0,0.2); }
    .moreBtn span { width: 4px; height:4px; display:inline-block; border-radius: 50%; background: #000; float: left; margin-top:16px; margin-left: 5px;}
    .reservationPeopleList > li > a > p { float:left; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; padding-right: 20px; box-sizing: border-box; }
    .reservationPeopleList > li > a > * { font-weight:bold; display: inline-block; line-height: 60px; border-bottom:1px solid #f1f3f5; }
    .reservationPeopleList > li.noShowList > *, .reservationPeopleList > li.noShowList .emTime, .reservationPeopleList > li.rejectList > *, .reservationPeopleList > li.rejectList .emTime { color:#ff4949; }
    .reservationPeopleList > li.noShowList:hover, .reservationPeopleList > li.rejectList:hover { background:#fff9f9; }
    .reservationPeopleList .checkBox.headerSort { padding:0; float:none; }
    .reservationPeopleList .checkBox.headerSort label { margin-top:17px; }
    .reservationPeopleList .emTime { font-weight:bold;padding-left: 15px; }

    .reservationPeopleList > li.rejectList input.del {z-index:1000; opacity:0; transition:0.2s; position: absolute; right:20px; top:12px; width: 35px; height: 35px;border-radius: 50%; background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center;  font-size: 0; }
    .reservationPeopleList > li.rejectList:hover input.del { opacity:1; }
    .reservationPeopleList > li.rejectList:hover input.del:hover {background-color:#ff4949 }

    /* noResult */
    .noResult.on { display:block; }
    .noResult { display:none; position: static; box-sizing: border-box; padding: 20px 50px;width: 100%;  }
    .noResult p { width:100%; border-radius: 5px; background:#f1f3f5; line-height: 80px; height: 80px; font-size: 13px; color: #9ea5b3;}

    /* 현황 색상 */
    .reservationPeopleList .clr { padding: 0 10px; height: 20px; line-height:20px; display:inline-block; border-radius:20px; background:#477fff; margin-right: 10px;color:#fff; border: 1px solid; }
    .clr.noshow { background: #ff6363;border-color:#ff4949 }
    .clr.seen { background: #477fff;border-color:#1c61ff }
    .clr.ing { background: #f1f3f5;color:#747e90; border-color: #dadde0; }
    .clr.made { background: #FFC72F;color:#39445d; border-color: #f1b71a;}
    .clr.confirmed { background: #13D08B; border-color:#17bf82}
    .clr.reject { background: #fff0f0;color:#ff6363; border-color:#ffd7d7}


    @media (max-width: 1500px ) {
        .statusHead { width: 12%;padding-left: 30px; }
        .num { width: 7%; }
        .ymdHour { width:23%; }
        .medicalHead { width:13%; }
        .name { width: 15%; }
        .patientHead { width:15%; }
        .telHead { width:15%; }
    }

</style>