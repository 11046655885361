<template>
    <div id="group_file_list_new" class="section_ce_fix dp1">

        <div class="bg4file">
            <span>끌어당기고 떨어뜨리세요</span>
        </div>

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">그룹명</h3>
        </div>

        <div class="schedule_box">
            <div class="settingBg">
                <!-- 폴더트리 메뉴 default 열어두기 -->
                <div class="btns_left">
                    <input type="button" class="schBtn closeLeft on">
                </div>

                <!-- 파일, 폴더 검색 -->
                <div class="schDiv">
                    <input type="text" placeholder="파일명 또는 폴더명을 검색하세요">
                    <input type="button" class="schBtn">
                </div>

                <div class="folderAddBtn">
                    <a id="folderAdd" class="input_btn">폴더추가</a>
                </div>

                <div class="btns">
                    <input type="button" value="박스형으로보기" title="박스형" class="dpTwo">
                    <input type="button" value="리스트로보기" title="리스트" class="dpOne">
                </div>
            </div>
        </div>

        <!-- 그룹에 들어왔을때 이동경로표시 -->
        <div id="route_bar">
            <nav class="deep_route">
                <ul class="routeUl">
                    <li>
                        <i class="ic_file">아이콘</i>
                    </li>
                    <!-- 해당 파일함 경로시 클래스 fixd_route 추가 -->
                    <li class="routeLi">
                        <a href="#">호두웨어</a>
                    </li>
                    <li class="routeLi fixd_route">
                        <a href="#">경영자</a>
                    </li>
                </ul>
            </nav>
        </div>
        <!-- //그룹에 들어왔을때 이동경로표시 -->

        <div id="fileScroll" class="section_scroll">
            <div class="viewGroup">

                <!-- 파일 추가 -->
                <div class="addFileDiv">
                    <!-- label 클릭시 파일선택 후 확인 클릭시 .newFileModal 불러오기 -->
                    <label for="addFile" class="addFileLabel">+</label>
                    <input type="file" multiple="multiple" id="addFile" value="파일추가">
                </div>
                <!-- //파일 추가 -->

                <div class="fileMainDiv">
                    <!-- left 폴더 트리 -->
                    <div class="groupLeftDiv">
                        <div class="forRel">
                            <div class="leftContent">
                                <ul class="sortHeader">
                                    <li class="fdType sortOff">
                                        <span>폴더</span>
                                    </li>
                                </ul>
                                <div class="fdTreeBox">
                                    <ul class="fdTreeUl">
                                        <!-- 폴더트리 처음 li만 fdTreeFirst 클래스 붙음 -->
                                        <li class="fdTreeFirst">
                                            <div class="fd_tree fdTotal">
                                                <a href="#">전체보기</a>
                                            </div>
                                        </li>
                                        <li class="fdTreeFirst active">
                                            <!-- 하위폴더가 있을 경우 tree_has_child 클래스 추가 -->
                                            <div class="fd_tree tree_has_child">
                                                <a href="#">경영자</a>
                                                <button class="mainMore">더보기</button>
                                            </div>
                                            <!-- 더보기 클릭하면 opne 클래스 추가 -->
                                            <!-- depth추가 될때마다  padding-left: 15px 씩 증가 하게 -->
                                            <ul class="fdSubUl fdSub_1depth">
                                                <li>
                                                    <div class="fd_tree">
                                                        <a href="#">관리비</a>
                                                        <button class="mainMore">더보기</button>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="fd_tree tree_has_child">
                                                        <a href="#">경영자료</a>
                                                        <button class="subMore">더보기</button>
                                                    </div>
                                                    <ul class="fdSubUl fdSub_2depth">
                                                        <li>
                                                            <div class="fd_tree tree_has_child">
                                                                <a href="#">세무세무</a>
                                                                <button class="subMore">더보기</button>
                                                            </div>
                                                            <ul class="fdSubUl fdSub_3depth">
                                                                <li>
                                                                    <div class="fd_tree">
                                                                        <a href="#">납부</a>
                                                                        <button class="subMore">더보기</button>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="fd_tree">
                                                                        <a href="#">계산서</a>
                                                                        <button class="subMore">더보기</button>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <div class="fd_tree">
                                                                <a href="#">회사업무</a>
                                                                <button class="subMore">더보기</button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="fdTreeFirst">
                                            <div class="fd_tree tree_has_child">
                                                <a href="#">APP개발</a>
                                                <button class="mainMore">더보기</button>
                                            </div>
                                            <ul class="fdSubUl fdSub_1depth">
                                                <li>
                                                    <div class="fd_tree">
                                                        <a href="#">ios</a>
                                                        <button class="subMore">더보기</button>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="fd_tree">
                                                        <a href="#">Android</a>
                                                        <button class="subMore">더보기</button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="fdTreeFirst">
                                            <div class="fd_tree">
                                                <a href="#">홍보마케팅 자료</a>
                                                <button class="mainMore">더보기</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //left 폴더 트리 -->

                    <!-- right 폴더 리스트 -->
                    <div class="groupRightDiv">
                        <!-- 그룹 하위 폴더 -->
                        <div class="group_file">
                            <ul class="sortHeader">
                                <!-- 아이콘 클릭시 fave_on 클래스 추가 후 즐겨찾기 정렬 -->
                                <li class="faveStar" title="즐겨찾기 정렬"><i class="ic_faveStar faveSort">아이콘</i></li>
                                <li class="fileName headerSort sortOff"><span>이름</span></li>
                                <li class="size headerSort sortOff"><span>용량 / 파일수</span></li>
                                <li class="fileHead writtenBy headerSort sortOff"><span>등록자</span></li>
                                <li class="time headerSort sortUp"><span>업데이트</span></li>
                            </ul>

                            
                            <div class="h4Div">
                                <h4>폴더</h4>
                                <p class="totalNum">&#40;19개&#41;</p>
                            </div>
                            <!-- 파일함 존재하지 않을 때 -->
                            <div class="noResult">
                                <p>파일함이 존재하지 않습니다</p>
                            </div>

                            <ul class="grfolderUl group grpFileList">
                                <li class="addFolderList"><a class="folder add_folder">파일함</a></li>

                                <!-- 내가 생성한 폴더 또는 파일 클래스 writerCrt 추가 메뉴보기 생성 -->
                                <!-- 비밀번호 파일 pswFolder 클래스 추가 -->
                                <!-- 파일 li에는 fileUpLi 클래스 기본 추가 -->
                                <li class="writerCrt">
                                    <a class="fileMenu">메뉴보기</a>
                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    <p class="grp fileName"><span class="ic-password">비밀번호 여부</span>관리비</p>
                                    <p class="size">
                                        <span class="size">23.61 KB</span>
                                        <span class="total"> &#47; 8개</span>
                                    </p>
                                    <p class="fd folder folder_dc2">
                                        <span class="blind">폴더개수</span>
                                        <span class="total">0</span>
                                    </p>
                                    <p class="fileHead writtenBy">
                                        <span>장경영</span>
                                    </p>
                                    <p class="lastUpdated">24.03.11 월</p>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                    <!-- 내가 생성한 폴더 메뉴 -->
                                    <ul class="fileMenuOp">
                                        <li><button type="button" class="fileOpList fdMove">폴더이동</button></li>
                                        <li><button type="button" class="fileOpList">폴더수정</button></li>
                                        <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                    </ul>

                                    <!-- 내가 업로드한 파일 메뉴 -->
                                    <ul class="fileMenuOp fileUplodOp">
                                        <li><button type="button" class="fileOpList fdMove">파일이동</button></li>
                                        <li><button type="button" class="fileOpList">파일삭제</button></li>
                                    </ul>
                                </li>

                                <li class="writerCrt pswFolder">
                                    <a class="fileMenu">메뉴보기</a>
                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    <p class="grp fileName"><span class="ic-password">비밀번호 여부</span>경영자료</p>
                                    <p class="size">
                                        <span class="size">705.51 KB</span>
                                        <span class="total"> &#47; 6개</span>
                                    </p>
                                    <p class="fd folder folder_dc2">
                                        <span class="blind">폴더개수</span>
                                        <span class="total">2</span>
                                    </p>
                                    <p class="fileHead writtenBy">
                                        <span>이과장</span>
                                    </p>
                                    <p class="lastUpdated">24.05.19 월</p>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                    <!-- 내가 생성한 폴더 메뉴 -->
                                    <ul class="fileMenuOp">
                                        <li><button type="button" class="fileOpList fdMove">폴더이동</button></li>
                                        <li><button type="button" class="fileOpList">폴더수정</button></li>
                                        <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                    </ul>

                                    <!-- 내가 업로드한 파일 메뉴 -->
                                    <ul class="fileMenuOp fileUplodOp">
                                        <li><button type="button" class="fileOpList fdMove">파일이동</button></li>
                                        <li><button type="button" class="fileOpList">파일삭제</button></li>
                                    </ul>
                                </li>

                                <!-- 파일 img -->
                                <li class="fileUpLi writerCrt" title="다운로드">
                                    <a class="fileMenu">메뉴보기</a>
                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    <p class="grp fileName"><span class="ic-password">비밀번호 여부</span>호두 프리미엄</p>
                                    <p class="size">
                                        <span class="size">705.51 KB</span>
                                        <span class="total"> &#47; 6개</span>
                                    </p>
                                    <p class="preview img">
                                        <span class="blind">파일</span>
                                    </p>
                                    <p class="fileHead writtenBy">
                                        <span>이과장</span>
                                    </p>
                                    <p class="lastUpdated">24.05.19 월</p>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                    <!-- 내가 생성한 폴더 메뉴 -->
                                    <ul class="fileMenuOp">
                                        <li><button type="button" class="fileOpList fdMove">폴더이동</button></li>
                                        <li><button type="button" class="fileOpList">폴더수정</button></li>
                                        <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                    </ul>

                                    <!-- 내가 업로드한 파일 메뉴 -->
                                    <ul class="fileMenuOp fileUplodOp">
                                        <li><button type="button" class="fileOpList fdMove">파일이동</button></li>
                                        <li><button type="button" class="fileOpList">파일삭제</button></li>
                                    </ul>
                                </li>

                                <!-- 파일 fe (프로그램) -->
                                <li class="fileUpLi writerCrt" title="다운로드">
                                    <a class="fileMenu">메뉴보기</a>
                                    <p class="faveStar" title="즐겨찾기">
                                        <i class="ic_faveStar faveSelect">아이콘</i>
                                    </p>
                                    <p class="grp fileName"><span class="ic-password">비밀번호 여부</span>호두_직원_기본인사정보_가져오기_양식.xlsx</p>
                                    <p class="size">
                                        <span class="size">705.51 KB</span>
                                        <span class="total"> &#47; 6개</span>
                                    </p>
                                    <p class="preview fe">
                                        <span class="blind">파일</span>
                                        xlsx
                                    </p>
                                    <p class="fileHead writtenBy">
                                        <span>이과장</span>
                                    </p>
                                    <p class="lastUpdated">24.05.19 월</p>
                                    <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                    <!-- 내가 생성한 폴더 메뉴 -->
                                    <ul class="fileMenuOp">
                                        <li><button type="button" class="fileOpList fdMove">폴더이동</button></li>
                                        <li><button type="button" class="fileOpList">폴더수정</button></li>
                                        <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                    </ul>

                                    <!-- 내가 업로드한 파일 메뉴 -->
                                    <ul class="fileMenuOp fileUplodOp">
                                        <li><button type="button" class="fileOpList fdMove">파일이동</button></li>
                                        <li><button type="button" class="fileOpList">파일삭제</button></li>
                                    </ul>
                                </li>
                            </ul>
                            

                        </div>
                        <!-- // 그룹 하위 폴더 -->

                    </div>
                    <!-- //right 폴더 리스트 -->
                </div>

            </div>
        </div>

        <a @click.prevent="moveFileBox">메인달력 파일함</a><br/>
        <a @click.prevent="moveGroupFileBox">그룹달력 파일함 페이지</a><br/>
        <a @click.prevent="moveGroupFileList"><b>그룹달력 파일함 디렉토리 내부 페이지</b></a>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class GroupFileListNew extends Mixins(VueHoduCommon) {

    mounted() : void {
        //리스트형으로 보기
        $(".btns .dpTwo").click(function(){
            $(".section_ce_fix").addClass("dp2");
            $(".section_ce_fix").removeClass("dp1");
            $(".btns .dpOne").show();
            $(this).hide();

        });

        //박스형으로 보기
        $(".btns .dpOne").click(function(){
            $(".section_ce_fix").removeClass("dp2");
            $(".section_ce_fix").addClass("dp1");
            $(".btns .dpTwo").show();
            $(this).hide();

        });

        // 즐겨찾기 정렬
        $(".faveStar").click(function(){
            $(this).toggleClass("fave_on");
        });

        //메뉴 열기
        $(".fileMenu").click(function(){
            $(this).parent().toggleClass("on");
        });

        // 왼쪽 menu 열기
        $(".closeLeft").click(function(){
            $(".section_ce_fix").toggleClass("leftDivClosed");
            $(this).toggleClass("on");

        });

        // 왼쪽 메뉴 체크
        $(".fdTreeBox .fd_tree a").click(function(){
            $(this).parent().parent().addClass('active').siblings().removeClass();
        })

        // 왼쪽 메뉴 dropdown
        $(".tree_has_child > .mainMore").click(function(){
            $(this).parent().siblings().toggleClass("open");
            $(this).toggleClass("open");
        })

        // 왼쪽 메뉴 dropdown2
        $(".tree_has_child > .subMore").click(function(){
            $(this).parent().siblings().toggleClass("open");
            $(this).toggleClass("open");
        })

        var file_drag = false;
        // 파일을 드래그하고 해당 영역위에 올렸을때 발생하는 이벤트
        $('body').on('dragover', function(e){
            e.stopPropagation();
            e.preventDefault();
            $(".bg4file").css('display','block');
            
            file_drag = true;
        });

        // 파일을 드래그하고 해당 영역을 떠났을때 발생하는 이벤트
        $('.bg4file').on('dragleave', function(e){
            if(file_drag){
                $(".bg4file").css('display','none');
            }

            file_drag = false;
        });
    }

    moveFileBox() : void {
        this.hodu_router_push(`/filebox?${new Date().getTime()}`);
    }

    moveGroupFileBox() : void {
        this.hodu_router_push(`/group/filebox?${new Date().getTime()}`);
    }

    moveGroupFileList() : void {
        this.hodu_router_push(`/group/filebox/dir_id?${new Date().getTime()}`);
    }

}
</script>

<style scoped>
    div#fileScroll { height: 100%; }

    .bg4file {display:none;position: absolute;width: 100%;vertical-align: middle;height: 100%;top: 0;line-height: 100%;left: 0; background: rgba(255, 255, 255, 0.3);box-sizing: border-box;z-index: 100000000;padding: 60px;}
	.bg4file.on {display: block;}
    .bg4file span {width: 100%;height: 100%;display: inline-block;transition: 0.1s;border-radius: 5px;background: rgba(71, 127, 255, 0.1) url(../assets/images/contents/img_dragDrop.png) no-repeat center center;background-size: 190px;border: 2px dashed #477fff;color: #477fff;font-weight: bold;font-size: 0;vertical-align: middle;text-align: center;}
	.bg4file span:hover {background: rgba(71, 127, 255, 0.15) url(../assets/images/contents/img_dragDrop.png) no-repeat center center;background-size: 200px;}

    button { border: none; outline: none; background-color: inherit ; cursor: pointer; }
    .viewGroup { overflow: hidden; height: 100%; }

    #addFile { display:none; }
	.addFileLabel {cursor:pointer;position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 1110000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 5px;display: block;text-align: center;font-size: 30px !important;width: 60px;border: 2px dashed #abc4ff;height: 60px;line-height: 59px;color: #608fff !important;background: rgba(71, 127, 255, 0.1) !important;}
	.addFileLabel:hover {background: #477fff !important;color: #fff !important;border: 2px solid #477fff;border-radius: 50%;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;}
	#fileScroll .sortHeader input[type='checkbox']:not(old) + label { margin-top:18px; }
	#fileScroll input[type='checkbox']:not(old) + label {margin-top: 21px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius:3px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
	#fileScroll input[type='checkbox']:not(old):checked + label {background: #477fff url('../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;border: 2px solid #477fff;}
	#fileScroll h3.selectTitle .cl {display: inline-block;width: 8px;height: 8px;float: left;margin-top: 28px;border-radius: 50%;margin-right: 15px;font-size: 0;}

    .settingBg { height: 80px; position: relative; border-bottom: 1px solid #e7e9ea; }
    .settingBg > div { display: inline-block; vertical-align: middle; }
    .settingBg .btns_left { margin: 0 10px 0 20px; }
    .settingBg .schDiv { position: relative; padding: 15px; box-sizing: border-box; width: 400px; display: inline-block; margin-left: 15px; }
    .settingBg .schDiv input[type="text"] { width: 100%; height: 50px; line-height: 50px; padding: 0 15px; border-radius: 5px; border: 1px solid #e7e9ea; background: #f1f3f5; font-weight: bold; box-sizing: border-box; transition: .2s; }
    .settingBg .schDiv input[type="text"]:hover { border: 1px solid #477fff; }
    .settingBg .schDiv input[type="text"]:focus { background: #fff; border-color: #477fff; }
    .settingBg .schDiv .schBtn { position: absolute ; right:20px;top:20px; }
    .settingBg select { cursor:pointer; transition:0.2s; margin-top: 20px; height: 35px; line-height: 31px; border:1px solid #232848; appearance: none; padding: 0 40px 0 20px; border-radius: 20px; font-weight: bold; font-size: 14px;background: url('../assets/images/side_area/bt_open.png') no-repeat 90% center; float: right; margin-right: 20px; }
    .settingBg select:hover { background-color:#f1f3f5; }
    .settingBg select:focus { border-color:#477fff; background-color:#fff;background-image: url('../assets/images/side_area/bt_close.png'); }
    .settingBg .btns { float: right; margin: 18px 20px 0 0; }
    .settingBg .btns .dpOne { display: none; }
    .settingBg .folderAddBtn { float: right; margin: 18px 20px 0 0; }
    .settingBg .btns_left .schBtn.closeLeft { position: static; background: url('../assets/images/contents/bt_hambuger_new.png') no-repeat center; }

    /* 이동경로 표시 */
    .deep_route { width: 100%; height: 50px; background: #fff; border-bottom: 1px solid #e7e9ea; }
    .deep_route .routeUl { width: 100%; padding: 0 25px; box-sizing: border-box; height: 50px; line-height: 50px; display: flex; }
    .deep_route .routeUl li { max-width: 190px; height: 50px; line-height: 50px; float: left; position: relative; padding-right: 35px; box-sizing: border-box; cursor: pointer; }
    .deep_route .routeUl li:first-child { width: 58px; cursor: default; }
    .deep_route .routeUl li:before { content: ''; width: 7px; height: 7px; border-right: 1px solid #85889a; border-bottom: 1px solid #85889a; position: absolute; right: 18px; transform: rotate(-45deg); top: 21px; }
    
    .deep_route .routeUl li > a { line-height: 20px; vertical-align: middle; }
    .deep_route .routeUl li > a:hover { text-decoration: underline; }
    .deep_route .routeUl li.fixd_route { font-weight: bold; line-height: 46px; }
    .deep_route .routeUl li.fixd_route:before { display: none; }
    .routeUl .ic_file { width: 20px; height: 50px; display: inline-block; font-size: 0; background: url('../assets/images/menucons/ic_file_sm_dc9.png') no-repeat center center; background-size: 17px auto; vertical-align: top; }

    /* content */
    .fileMainDiv { position: relative; overflow: hidden; height: 100%; }
    .fileMainDiv .noResult { display:none; position: static; padding: 20px 30px; }
    .fileMainDiv .noResult.on { display:block; }
    .groupRightDiv .noResult p { background: #f1f3f5; height: 80px; line-height: 80px; font-size : 14px; }

    .faveStar { cursor: pointer; position: absolute; z-index: 10000000; }
    .faveStar .ic_faveStar { width: 40px; height: 40px; display: inline-block; font-size: 0; vertical-align: middle; margin-top: -2px; }
    .faveStar .faveSort { background: url('../assets/images/contents/ic_fave_off.png'); background-repeat: no-repeat; background-size: 23px; background-position: center center; }
    .faveStar.fave_on .faveSort { background-image: url('../assets/images/contents/ic_fave_on.png'); }
    .faveStar .faveSelect { background: url('../assets/images/contents/faveStar_off.png'); background-repeat: no-repeat; background-size: 23px; background-position: center center; }
    .faveStar.fave_on .faveSelect { background-image: url('../assets/images/contents/faveStar.png');}

    /* 비밀번호 */
    .section_ce_fix .grfolderUl.group  .grp .ic-password { display: none; width: 30px; height: 30px; font-size: 0; background: url('../assets/images/folder_file/ic_password_fff.png') no-repeat center center; position: absolute; top: 90px; left: 100px; background-size: 20px; z-index: 9999; }
    .section_ce_fix.dp1 .grfolderUl.group  .grp .ic-password { display: none; width: 15px; height: 15px; font-size: 0; background: url('../assets/images/folder_file/ic_password_232848.png') no-repeat center center; background-size: 9px; position: static; vertical-align: middle; margin-top: -2px; }
    .section_ce_fix .grfolderUl.group li.pswFolder .grp .ic-password { display: inline-block; }
    .grfolderUl li .fileMenuOp li .pswDel { display: none; }
    .grfolderUl li.pswFolder .fileMenuOp li .pswDel { display: block; }


    /* left 폴더 트리 */
    .section_ce_fix.leftDivClosed .groupLeftDiv { width: 0; }
    .left_area_close .groupLeftDiv { left: 61px; }
    .section_ce_fix.leftDivClosed .groupLeftDiv { width: 0; }
    .section_ce_fix.leftDivClosed .leftContent { display: none; }
    .section_ce_fix.leftDivClosed .groupRightDiv { padding-left: 0; }

    .groupLeftDiv { transition: 0.1s; position: absolute; width: 340px; left: 0; top: 0; box-sizing: border-box; border-right: 1px solid #e7e9ea; height: 100%; }
    .groupLeftDiv .sortHeader > li { width: 100%; padding-left: 30px; box-sizing: border-box; }

    .fdTreeBox .fdTreeUl li { position: relative; line-height: 60px; box-sizing: border-box; cursor: pointer; }
    .fdTreeBox .fdTreeUl li.active:after { content: ''; width: 2px; height: 60px; display: inline-block; background: #477fff; position: absolute; top: 0; right: 0; }
    .fdTreeBox .fdTreeUl li a { display: block; font-size: 14px; font-weight: bold; }
    .fdTreeBox .fdTreeUl li button { display: none; font-size: 0; width: 40px; height: 40px; border-radius: 50%; background-image: url('../assets/images/side_area/bt_open.png'); background-repeat: no-repeat; background-position: center center; position: absolute; }
    .fdTreeBox .fdTreeUl li .tree_has_child button { display: block; }
    .fdTreeBox .fdTreeUl li button.mainMore { float: right; top: 10px; right: 10px; }
    .fdTreeBox .fdTreeUl li button.mainMore:hover { background-color: #f1f3f5; }
    .fdTreeBox .fdTreeUl li button.mainMore.open { background-image: url('../assets/images/side_area/bt_close.png'); }
    .fdTreeBox .fdTreeUl li button.subMore { top: 15px; margin-left: -30px; width: 30px; height: 30px; transform: rotate( -90deg ); }
    .fdTreeBox .fdTreeUl li button.subMore.open { background-image: url('../assets/images/side_area/bt_close.png'); transform: rotate( 0deg ); }
    .fdTreeBox .fdTreeUl li button.subMore:hover { background-image: url('../assets/images/side_area/bt_open_b.png'); }

    .fdTreeBox .fdTreeUl .fdSubUl { display: none; }
    .fdTreeBox .fdTreeUl .fdSubUl.open { display: block; }
    .fdTreeBox .fdTreeUl .fd_tree { border-bottom: 1px solid #e7e9ea; padding: 0 60px 0 30px; position: relative; transition: 0.1s; }
    .fdTreeBox .fdTreeUl .fd_tree:hover { background: #f7f9fb; }
    .fdTreeBox .fdTreeUl li .fdSubUl .fd_tree { padding-left: 50px; }
    .fdTreeBox .fdTreeUl li .fdSubUl.fdSub_2depth .fd_tree { padding-left: 65px; }
    .fdTreeBox .fdTreeUl li .fdSubUl.fdSub_3depth .fd_tree { padding-left: 80px; }

    /* right 폴더 리스트 */
    .groupRightDiv { float: left; width: 100%; box-sizing: border-box; padding-left: 340px; }
    .section_ce_fix.dp1 .h4Div { display: none; }
    .section_ce_fix.dp1 .addFolderList { display: none; }

    .grpFileList li { text-align: center; position: relative; float: left; display: inline-block; width: 180px; height: 200px; margin: 30px 0 0 30px; -webkit-box-sizing: border-box; box-sizing: border-box; border: 1px solid #e7e9ea; border-radius: 5px; }
    .grpFileList li:hover { background: #f7f9fb; }
    .grpFileList li a { position: absolute; display: inline-block; height: 100%; left: 0; top: 0; width: 100%; text-align: center; font-size: 0; }
    .grpFileList li a.fileMenu { display: none; right: 5px; top: 16px; width: 30px; height: 30px; left: auto; }
    .grpFileList li.writerCrt a.fileMenu { display: inline-block; }
    .grpFileList li .fileName { font-size: 14px; height: 35px; line-height: 45px; padding: 0 25px; box-sizing: border-box; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .grpFileList li .size { height: 20px; line-height: 25px; }
    .fd .total { color: #fff; }
    .grpFileList li .fileMenuOp { display: none; position: absolute; top: 42px; right: 20px; width: 120px; height: auto; box-sizing: border-box; overflow: hidden; background: #fff; box-shadow: 0px 5px 10px rgb(0 0 0 / 10%); border-radius: 5px; transition: .2s; z-index: 9999; }
    .grpFileList li.on .fileMenuOp { display: block; }
    .grpFileList li.on .fileUplodOp { display: none; }
    .grpFileList li.fileUpLi.on .fileMenuOp { display: none; }
    .grpFileList li.fileUpLi.on .fileUplodOp { display: block; }
    .grpFileList li.fileUpLi .total { display: none; }
    .grpFileList li.fileUpLi .goDets { display: none; }
    /* .grpFileList li.fileUpLi .faveStar { display: none; } */

    .groupRightDiv .h4Div { overflow: hidden; margin: 30px 30px 0 30px; font-size: 16px; margin-bottom: -5px; }
    .groupRightDiv .h4Div h4, .groupRightDiv .h4Div p { display: inline-block; margin-right: 5px; font-weight: bold; }
    .grfolderUl.grpFileList { overflow: hidden; box-sizing: border-box; padding-right: 30px; padding-bottom: 40px; }
    .fileHead { font-size: 12px; height: 20px; font-weight: bold; }
    .grpFileList li .fileMenuOp li { text-align: left; width: 100%; height: auto; margin: 0; border: none; }

    .group_file > ul > li .preview { position: absolute; left: 8%; background: #fff; border-radius: 5px; height: 40px; font-size: 11px; line-height: 40px; text-transform: uppercase; text-align: center; width: 60px; color: #477fff; top: 12px; padding-right: 0; border: 1px solid #e7e9ea; box-shadow:none; }
    .group_file > ul > li .preview.img { transition: 0.1s; font-size:0; background-size:cover; background-position:center center; }
	.group_file > ul > li:hover .preview { box-shadow: 0 5px 10px rgba(0,0,0,0.2); border: none; }
	.group_file > ul > li:hover { cursor: pointer; }
	.group_file > ul > li:hover .preview::after { content:''; z-index:10; position:absolute; top:0; left:0; width:100%; height:100%; display:block; background: rgba(71, 127, 255, 0.2) url('../assets/images/contents/ic_ddFile.png') no-repeat center 5px; }
    .group_file > ul > li .preview.img { background-image: url('../assets/images/contents/imgDc8.png')}

    .section_ce_fix.dp1 .grpFileList { padding-right: 0; padding-bottom: 0; border-bottom: 0 none; padding-bottom: 120px; }
    .section_ce_fix.dp1 .faveStar { width: 5%; margin-left: 12px !important; }
    .section_ce_fix.dp1.leftDivClosed .faveStar { margin-left: 22px !important; }
    .section_ce_fix.dp1 .fileName { width: 40%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-left: 8% !important; }
    .section_ce_fix.dp1 .size { width: 14%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .section_ce_fix.dp1 .fileHead { width: 15%; height: 60px; line-height: 60px; }
    .section_ce_fix.dp1 .time { width: 20%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .section_ce_fix.dp1 .grpFileList li { width: 100%; margin: 0; border-radius: 0; border: none; height: 66px; line-height: 66px; border-bottom: 1px solid #f1f3f5; transition: 0.2s; }
    .section_ce_fix.dp1 .grpFileList li:hover { background: #f7f9fb; }
    .section_ce_fix.dp1 .grpFileList p { float: left; text-align: left; font-size: 12px; height: 66px; line-height: 66px; padding-left: 0; font-weight: bold; }
    .section_ce_fix.dp1 .grpFileList .fileName { padding-left: 80px; font-size: 13px; }
    .section_ce_fix.dp1 .grpFileList p.fd { position: absolute; left: 8.3%; padding-left: 0; padding-left: 0; width: 50px; background-position: left center; background-size: 41px 30px; }
    .section_ce_fix.dp1 .grpFileList p.fileName { padding-left: 80px; }
    .section_ce_fix.dp1 .fd span.total { display: inline-block; width: 35px; line-height: 69px; text-align: center !important; }
    .section_ce_fix.dp1 .group_file > ul > li .preview { height: 40px; line-height: 40px; text-align: center; }

    .section_ce_fix.dp2 .sortHeader { display: none; }
    .section_ce_fix.dp2 .grpFileList li a.fileMenu { top: 10px; }
    .section_ce_fix.dp2 .group_file > ul > li .preview { position: relative; display: inline-block; width: 50%; height: 55px; line-height: 55px; margin: 20px 0; left: 0; top: 0; font-weight: bold; }
    .section_ce_fix.dp2 .group_file > ul > li:hover .preview::after { background-position: center 13px; }

</style>