<template>
    <div id="hospital_list" class="section_ce_fix grpPage hoduDoc">
        <div class="title_box grpDetailTitle" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">내 병원</h3>
            <div class="schLi">
                <!--<a class="schBtn"><span>그룹검색</span></a>-->
                <div class="gnb_schInbox" id="searchTotalDiv">
                    <div class="schGrp">
                        <input type="text" id="searchTotalTxt" name="" value="" class="input_search02" placeholder="그룹을 검색하세요.">
                        <a href="#" class="bt_search"><span class="blind">찾기버튼</span></a>
                    </div>
                </div>
            </div>
        </div>

        <div id="grpScroll" class="section_scroll">
            <div class="viewGroup">					
                <div class="schedule_box">

                </div>
                <div class="content grpDetailContent" id="">
                    <div class="main hc">
                        <div class="first">
                            <div class="grp1 hdList">
                                 <!-- <p class="premiumTag">HODU DOC</p> -->
                                <h4 class="placeNm">{{hodu_d_list.length > 0 && hodu_d_list[selected_hospital_index].hospital_info != null ? hodu_d_list[selected_hospital_index].hospital_info.name : "-"}}</h4>
                                <!-- <p class="cl dc7">그룹 색상</p>
                                <ul id="grpTeamUlDiv" class="grpTeam_clr_ul">
                                    <li>
                                        <a href="#" class="dc0">연어</a>
                                    </li>
                                    <li>
                                        <a href="#" class="dc1" >주황</a>
                                    </li>
                                    <li>
                                        <a href="#" class="dc2">노랑</a>
                                    </li>
                                    <li>
                                        <a href="#" class="dc3">자주</a>
                                    </li>
                                    <li>
                                        <a href="#" class="dc4">청록</a>
                                    </li>
                                    <li>
                                        <a href="#">초록</a>
                                    </li>
                                    <li>
                                        <a href="#" class="dc6">하늘</a>
                                    </li>
                                    <li>
                                        <a href="#" class="dc7">파랑</a>
                                    </li>
                                    <li>
                                        <a href="#" class="dc8">보라</a>
                                    </li>
                                    <li>
                                        <a href="#" class="dc9">검정</a>
                                    </li>
                                </ul> -->
                                <p class="img" :style="{backgroundImage: `url(${hodu_d_list.length > 0 && hodu_d_list[selected_hospital_index].hospital_info != null && hodu_d_list[selected_hospital_index].hospital_info.images != null && hodu_d_list[selected_hospital_index].hospital_info.images.length > 0 ? `app_images/${hodu_d_list[selected_hospital_index].hospital_info.images[0].url}` : default_profile_image })`}">그룹 이미지</p>
                                <p class="descript">{{hodu_d_list.length > 0 && hodu_d_list[selected_hospital_index].hospital_info != null ? hodu_d_list[selected_hospital_index].hospital_info.descript : ""}}</p>
                            </div>
                            <div class="grp2">
                                <input type="button" class="bigger" value="크게보기" />
                                <div class="current">
                                    <div class="bg"></div>
                                    <!-- 텍스트는 상황에따라 달라짐, 현재 근무 종료 중입니다. 오늘은 쉬는날입니다. 등등 -->
                                    <h5>오늘은 <span>오전 9시 ~ 오후 7시 까지</span> 진료합니다.</h5>
                                    <!-- 관리자 일때  -->
                                    
                                    <!-- <div class="btns"> -->
                                        <!-- 환자 혼자 일때 -->
                                        <!-- <h5>김연지</h5> -->
                                        <!-- 환자 여려명 일때 (예제, 김연지가 호두 가입자이고 배은지, 동연은 자녀일때), 우선 주석처리 -->
                                        <!-- <select name="" id="">
                                            <option value="">김연지</option>
                                            <option value="">배은지</option>
                                            <option value="">배동연</option>
                                        </select> -->
                                        <!-- <a class="del">삭제</a> -->
                                    <!-- </div> -->
                                    
                                </div>
                                <div class="push">
                                    <ul>
                                        <li class="notice"><a class="link"><span>공지사항</span>코로나로인한 진료시간 변경 안내</a></li>
                                    </ul>
                                    <div class="btns" style="display: none;">
                                    </div>
                                </div>
                                <!-- 현황 -->
                                <div class="usage">
                                    <!-- 왼쪽 메뉴 -->
                                    <div class="btnBox">
                                        <!-- 스크롤 필요 -->
                                        <div class="btn_scroll">
                                            <a class="on">내 진료</a>
                                            <a>파일함</a>
                                            <a>병원소개</a>
                                            <a>의료진</a>
                                            <a>찾아 오시는길</a>
                                        </div>
                                    </div>
                                    <div class="contentOnRight">
                                        <ul class="menuUl">
                                            <li class="appointmentList on">
                                                <!-- 스크롤넣기 -->
                                                <div class="selectTimeDiv">
                                                    <select name="" class="firstSelect" id="" @change="changeAppointmentsYear($event)">
                                                        <option :key="yearIndex"
                                                                v-for="(year, yearIndex) in computedAppointsYears"
                                                                :value="year"
                                                                :selected="year == selected_appoints_year"
                                                        >{{year}}년</option>
                                                        <!-- <option :value="2017">2017년 (8)</option>
                                                        <option value="">2018년 (5)</option>
                                                        <option value="">2019년 (19)</option>
                                                        <option value="" selected>2020년 (23)</option> -->
                                                    </select>
                                                    <select name=""  class="secondSelect" id="" @change="changeAppointmentsMonth($event)">
                                                        <option :value="month" :key="monthIndex" v-for="(month, monthIndex) in month_list" :selected="month == selected_appoints_month">{{month == 100 ? `전체` : `${month}월`}}</option>
                                                        <!-- <option :value="100" :selected="selected_appoints_month == 100">전체 (23)</option>
                                                        <option :value="1"   :selected="selected_appoints_month == 1">1월 (3)</option>
                                                        <option :value="2"   :selected="selected_appoints_month == 2">2월 (2)</option>
                                                        <option :value="3"   :selected="selected_appoints_month == 3">3월 (2)</option>
                                                        <option :value="4"   :selected="selected_appoints_month == 4">4월 (3)</option>
                                                        <option :value="5"   :selected="selected_appoints_month == 5">5월 (0)</option>
                                                        <option :value="6"   :selected="selected_appoints_month == 6">6월 (3)</option>
                                                        <option :value="7"   :selected="selected_appoints_month == 7">7월 (0)</option>
                                                        <option :value="8"   :selected="selected_appoints_month == 8">8월 (4)</option>
                                                        <option :value="9"   :selected="selected_appoints_month == 9">9월 (2)</option>
                                                        <option :value="10"  :selected="selected_appoints_month == 10">10월 (4)</option>
                                                        <option :value="11"  :selected="selected_appoints_month == 11">11월 (0)</option>
                                                        <option :value="12"  :selected="selected_appoints_month == 12">12월 (0)</option> -->
                                                    </select>
                                                </div>
                                                <div class="ul_scroll">
                                                    <ul class="appointmentUl">
                                                        <li>
                                                            <p class="status"><span class="clr made">접수</span></p>
                                                            <p class="num">{{receiptAppointments.length}}</p>
                                                            <p class="when">{{receiptAppointments.length > 0 ? getDateString(receiptAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <!-- <p class="time">오후 3:00</p> -->
                                                            <p class="time">{{receiptAppointments.length > 0 ? getTimeString(receiptAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <p class="doc">{{receiptAppointments.length > 0 && receiptAppointments[0].event_data.appointment != null ? receiptAppointments[0].event_data.appointment.doctor_name : '-'}}</p>
                                                            <!-- 예약 상세 페이지로 이동 -->
                                                            <!-- <a class="goThisPage" title="자세히 보기" @click="moveAppointmentDetail('event_id')">자세히 보기</a> -->
                                                        </li>
                                                        <li>
                                                            <p class="status"><span class="clr seen">진료</span></p>
                                                            <p class="num">{{diagnosisAppointments.length}}</p>
                                                            <!-- <p class="when">2020년 3월 12 목</p> -->
                                                            <p class="when">{{diagnosisAppointments.length > 0 ? getDateString(diagnosisAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <!-- <p class="time">오후 3:00</p> -->
                                                            <p class="time">{{diagnosisAppointments.length > 0 ? getTimeString(diagnosisAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <p class="doc">{{diagnosisAppointments.length > 0 && diagnosisAppointments[0].event_data.appointment != null ? diagnosisAppointments[0].event_data.appointment.doctor_name : '-'}}</p>
                                                            <!-- <a class="goThisPage" title="자세히 보기">자세히 보기</a> -->
                                                        </li>
                                                        <li>
                                                            <p class="status"><span class="clr confirmed">확정</span></p>
                                                            <p class="num">{{confirmAppointments.length}}</p>
                                                            <!-- <p class="when">2020년 3월 12 목</p> -->
                                                            <p class="when">{{confirmAppointments.length > 0 ? getDateString(confirmAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <!-- <p class="time">오후 3:00</p> -->
                                                            <p class="time">{{confirmAppointments.length > 0 ? getTimeString(confirmAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <p class="doc">{{confirmAppointments.length > 0 && confirmAppointments[0].event_data.appointment != null ? confirmAppointments[0].event_data.appointment.doctor_name : '-'}}</p>
                                                            <!-- <a class="goThisPage" title="자세히 보기">자세히 보기</a> -->
                                                        </li>
                                                        <li>
                                                            <p class="status"><span class="clr reject">거절</span></p>
                                                            <p class="num">{{declineAppointments.length}}</p>
                                                            <!-- <p class="when">2020년 3월 12 목</p> -->
                                                            <p class="when">{{declineAppointments.length > 0 ? getDateString(declineAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <!-- <p class="time">오후 3:00</p> -->
                                                            <p class="time">{{declineAppointments.length > 0 ? getTimeString(declineAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <p class="doc">{{declineAppointments.length > 0 && declineAppointments[0].event_data.appointment != null ? declineAppointments[0].event_data.appointment.doctor_name : '-'}}</p>
                                                            <!-- <a class="goThisPage" title="자세히 보기">자세히 보기</a> -->
                                                        </li>
                                                        <li>
                                                            <p class="status"><span class="clr ing">요청</span></p>
                                                            <p class="num">{{requestAppointments.length}}</p>
                                                            <!-- <p class="when">2020년 3월 12 목</p> -->
                                                            <p class="when">{{requestAppointments.length > 0 ? getDateString(requestAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <!-- <p class="time">오후 3:00</p> -->
                                                            <p class="time">{{requestAppointments.length > 0 ? getTimeString(requestAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <p class="doc">{{requestAppointments.length > 0 && requestAppointments[0].event_data.appointment != null ? requestAppointments[0].event_data.appointment.doctor_name : '-'}}</p>
                                                            <!-- <a class="goThisPage" title="자세히 보기">자세히 보기</a> -->
                                                        </li>
                                                        <li>
                                                            <p class="status"><span class="clr noshow">노쇼</span></p>
                                                            <p class="num">{{noshowAppointments.length}}</p>
                                                            <!-- <p class="when">2020년 3월 12 목</p> -->
                                                            <p class="when">{{noshowAppointments.length > 0 ? getDateString(noshowAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <!-- <p class="time">오후 3:00</p> -->
                                                            <p class="time">{{noshowAppointments.length > 0 ? getTimeString(noshowAppointments[0].event_data.schedule_date.start) : '-'}}</p>
                                                            <p class="doc">{{noshowAppointments.length > 0 && noshowAppointments[0].event_data.appointment != null ? noshowAppointments[0].event_data.appointment.doctor_name : '-'}}</p>
                                                            <!-- <a class="goThisPage" title="자세히 보기">자세히 보기</a> -->
                                                        </li>
                                                    </ul>
                                                </div>    
                                            </li>
                                            <li class="fileBoxList">
                                                <!-- 스크롤넣기 -->
                                                <div class="selectTimeDiv">
                                                    <select name="" class="firstSelect" id="" @change="changeFileYear($event)">
                                                        <option :key="yearIndex" v-for="(year, yearIndex) in computedFilesYears" :value="year" :selected="year == selected_file_year">{{year}}년</option>
                                                        <!-- <option value="">2017년 (8)</option>
                                                        <option value="">2018년 (5)</option>
                                                        <option value="">2019년 (19)</option>
                                                        <option value="" selected>2020년 (12)</option> -->
                                                    </select>
                                                    <select name="" class="secondSelect" id="" @change="changeFileMonth($event)">
                                                        <option :key="monthIndex" v-for="(month, monthIndex) in files_month_list" :value="month" :selected="month == selected_file_month">{{month == 100 ? `전체` : `${month}월`}}</option>
                                                        <!-- <option value="">전체 (12)</option>
                                                        <option value="">1월 (1)</option>
                                                        <option value="">2월 (0)</option>
                                                        <option value="">3월 (0)</option>
                                                        <option value="">4월 (1)</option>
                                                        <option value="">5월 (0)</option>
                                                        <option value="">6월 (1)</option>
                                                        <option value="">7월 (0)</option>
                                                        <option value="">8월 (2)</option>
                                                        <option value="">9월 (2)</option>
                                                        <option value="">10월 (2)</option>
                                                        <option value="">11월 (0)</option>
                                                        <option value="">12월 (3)</option> -->
                                                    </select>
                                                    <input type="button" value="파일함가기" />
                                                </div>
                                                <div class="ul_scroll">
                                                    <ul class="filboxUl">
                                                        <li :key="fileIndex" v-for="(file, fileIndex) in computedFiles">
                                                            <p class="num">{{fileIndex+1}}</p>
                                                            <p class="fileName"><span class="preview">{{file.file_type}}</span><span class="txt">{{file.original_file_name}}</span></p>
                                                            <p class="fileSize">{{file.file_size != null ? getMB(file.file_size) : '-'}} MB</p>
                                                            <p class="uploaded">{{file.audit_modified != null ? getDateString(file.audit_modified) : "-"}}</p>
                                                            <!-- 미리보기 페이지로 이동 -->
                                                            <a title="자세히 보기">자세히 보기</a>
                                                        </li>
                                                        <!-- <li>
                                                            <p class="num">1</p>
                                                            <p class="fileName"><span class="preview">영상</span><span class="txt">대장내시경.mp4</span></p>
                                                            <p class="fileSize">1.5 MB</p>
                                                            <p class="uploaded">2020년 3월 12 목</p>
                                                            미리보기 페이지로 이동 
                                                            <a title="자세히 보기">자세히 보기</a>
                                                        </li>
                                                        <li>
                                                            <p class="num">2</p>
                                                            <p class="fileName"><span class="preview format">PDF</span><span class="txt">피검사.pdf</span></p>
                                                            <p class="fileSize">4 MB</p>
                                                            <p class="uploaded">2020년 3월 12 목</p>
                                                            <a title="자세히 보기">자세히 보기</a>
                                                        </li>
                                                        <li>
                                                            <p class="num">3</p>
                                                            <p class="fileName"><span class="preview format">PPT</span><span class="txt">건강검진표.ppt</span></p>
                                                            <p class="fileSize">2.5 MB</p>
                                                            <p class="uploaded">2020년 3월 12 목</p>
                                                            <a title="자세히 보기">자세히 보기</a>
                                                        </li>
                                                        <li>
                                                            <p class="num">4</p>
                                                            <p class="fileName"><span class="preview">영상</span><span class="txt">위내시경.mp4</span></p>
                                                            <p class="fileSize">1.5 MB</p>
                                                            <p class="uploaded">2020년 3월 12 목</p>
                                                            <a title="자세히 보기">자세히 보기</a>
                                                        </li>
                                                        <li>
                                                            <p class="num">5</p>
                                                            <p class="fileName"><span class="preview">사진</span><span class="txt">xray_20200312.jpg</span></p>
                                                            <p class="fileSize">1.5 MB</p>
                                                            <p class="uploaded">2020년 3월 12 목</p>
                                                            <a title="자세히 보기">자세히 보기</a>
                                                        </li>
                                                        <li>
                                                            <p class="num">6</p>
                                                            <p class="fileName"><span class="preview">사진</span><span class="txt">xray_20200312.jpg</span></p>
                                                            <p class="fileSize">1.5 MB</p>
                                                            <p class="uploaded">2020년 3월 12 목</p>
                                                            <a title="자세히 보기">자세히 보기</a>
                                                        </li> -->
                                                    </ul>
                                                </div>    
                                            </li>
                                            <li class="hopiInfoList"> 
                                                <!-- 스크롤넣기 -->
                                                <div class="ul_scroll">
                                                    <!-- 메인 이미지 외 병원 사진 / 스크롤 상하로 추가  *** 메인 제외한 추가 이미지 없을시 통으로 없앰 -->
                                                    <div class="hopi_scroll">
                                                        <!-- 스크롤 좌우로 추가 -->
                                                        <div class="hopiImgsDiv">
                                                            <ul class="hopiImgUl" v-if="hodu_d_list.length > 0 && hodu_d_list[selected_hospital_index].hospital_info != null">
                                                                <li :key="photoIndex" v-for="(photo, photoIndex) in hodu_d_list[selected_hospital_index].hospital_info.images">
                                                                    <a :style="{backgroundImage: `url(${photo.url.length > 0 ? `app_images/${photo.url}` : default_profile_image })`}">병원 사진</a>
                                                                </li>
                                                                <!-- <li>
                                                                    <a>병원 사진</a>
                                                                </li> -->
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <ul class="hopiDetUl">
                                                        <!-- 문자가 길시 모든 element에 타이틀 달아주기 -->
                                                        <li :title="hodu_d_list.length > 0 && hodu_d_list[selected_hospital_index].hospital_info != null ? hodu_d_list[selected_hospital_index].hospital_info.contacts.tel : '-'"><span>전화</span>{{hodu_d_list.length > 0 && hodu_d_list[selected_hospital_index].hospital_info != null ? hodu_d_list[selected_hospital_index].hospital_info.contacts.tel : '-'}}</li>
                                                        <li class="addrList" ><span>주소</span>{{hodu_d_list.length > 0 && hodu_d_list[selected_hospital_index].hospital_info != null ? hodu_d_list[selected_hospital_index].hospital_info.location.address : '-'}} </li>
                                                        <!-- <li title="평일 08:30 - 19:00 | 토요일 08:30 - 16:00 | 일요일 휴무공휴일 휴무"><span>진료시간</span>평일 08:30 - 19:00 | 토요일 08:30 - 16:00 | 일요일 휴무공휴일 휴무</li> -->
                                                        <li :title="getWorkSchedule(selected_hospital_index)"><span>진료시간</span>{{getWorkSchedule(selected_hospital_index)}}</li>
                                                        <li :title="getWebSite()"><span>웹사이트</span><a>{{getWebSite()}}</a></li>
                                                    </ul>
                                                    <div class="txtDiv">
                                                        <p class="title">병원 소개</p>
                                                        <!-- <textarea class="txt" disabled>
                                                            정성을 모아 진료에 임하고 고객님의 만족을 위하여 인터넷을 통한 양질의 의료정보와 서비스를 제공하고 있으며,
                                                            든든한 의료진과 풍부한 치료경험, 그리고 편안함과 친절로 고객님을 대하고 있습니다.
                                                            궁금하신 사항이 있을시 언제든지 본 의원을 방문하시면 친절하게 상담해드리겠습니다.
                                                            여러분을 위해서 노력하는 의원으로 발돋음할 것을 약속 드리며 항상 고객의 입장에 서는 든든한 의원이 되겠습니다.
                                                        </textarea> -->
                                                        <textarea class="txt" :disabled="true" :value="getDescript()"></textarea>
                                                    </div>
                                                </div>    
                                            </li>
                                            <li class="docInfoList">
                                                <!-- 스크롤넣기 -->
                                                <div class="ul_scroll">
                                                    <ul class="subUl" v-if="hodu_d_list.length > 0 && hodu_d_list[selected_hospital_index].doctors != null && hodu_d_list[selected_hospital_index].doctors.length > 0">
                                                        <!-- 더보기 더버튼 클릭시 list 클래스추가 on -->
                                                        <li :key="doctorIndex" v-for="(doctor, doctorIndex) in hodu_d_list[selected_hospital_index].doctors">
                                                            <p :class="{king : doctor['doctor_info'].position == 'DIR'}"></p>
                                                            <p class="docImg" :style="{backgroundImage: `url(${doctor['profile_image'] != null && doctor['profile_image'].length > 0 ? `app_images/${doctor.doctor_detail.profile_image}` : default_profile_image })`}">의료진 사진</p>
                                                            <div class="firstSub">
                                                                <p class="speciality">{{doctor.department_code}}</p>
                                                                <p class="docName">{{doctor.doctor_name}}</p>
                                                                <p class="position">{{getPositionStr(doctor['doctor_info'].position)}}</p>
                                                            </div>
                                                            <div class="secondSub">
                                                                <dl>
                                                                    <dt class="title">학력 / 경력</dt>
                                                                    <dd :key="index" v-for="(career, index) in doctor['doctor_info'].career"><span v-if="career.length > 0"></span>{{career}}</dd>
                                                                </dl>
                                                                <!-- 학력과 경력이 3줄 이상일때 더보기 있음, 이 버튼 클릭 시 리스트에 on추가 -->
                                                                <div class="inputBg" v-if="doctor['doctor_info'].career != null && doctor['doctor_info'].career.length > 2"><input type="button" class="seeMoreDetail" value="더보기" /></div>
                                                            </div>
                                                        </li>

                                                        <!-- <li>
                                                            <p class="king">원장</p>
                                                            <p class="docImg">의료진 사진</p>
                                                            <div class="firstSub">
                                                                <p class="speciality">내과</p>
                                                                <p class="docName">이석호</p>
                                                                <p class="position">의료진</p>
                                                            </div>
                                                            <div class="secondSub">
                                                                <dl>
                                                                    <dt class="title">학력 / 경력</dt>
                                                                    <dd><span></span>한양대 의대 졸업</dd>
                                                                    <dd><span></span>한양대 의대 의학박사 및 내과 전문의</dd>
                                                                    <dd><span></span>국군 수도통합병원 내분비 내과과장 역임</dd>
                                                                    <dd><span></span>삼성의료원 소화기 내과 전임의사 역임</dd>
                                                                    <dd><span></span>포천 중문의대 차병원 내과과장 및 조교수 역임</dd>
                                                                    <dd><span></span>송파 성모병원 내과과장 역임</dd>
                                                                    <dd><span></span>한양대 의대 외래교수</dd>
                                                                    <dd><span></span>성균관 의대 삼성의료원 내과 외래교수</dd>
                                                                    <dd><span></span>대한소화기 학회 평생회원</dd>
                                                                </dl>
                                                                학력과 경력이 3줄 이상일때 더보기 있음, 이 버튼 클릭 시 리스트에 on추가 
                                                                <div class="inputBg"><input type="button" class="seeMoreDetail" value="더보기" /></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p class="docImg">의료진 사진</p>
                                                            <div class="firstSub">
                                                                <p class="speciality">내과</p>
                                                                <p class="docName">이연지</p>
                                                                <p class="position">의료진</p>
                                                            </div>
                                                            <div class="secondSub">
                                                                <dl>
                                                                    <dt class="title">학력 / 경력</dt>
                                                                    <dd><span></span>서울대 의대 졸업</dd>
                                                                    <dd><span></span>서울대학 병원 내과 수련</dd>
                                                                    <dd><span></span>서울대 의대 의학 박사</dd>
                                                                </dl>
                                                            </div>
                                                        </li> -->
                                                    </ul>
                                                </div>    
                                            </li>
                                            <li class="directionList">
                                                <div class="forRel">
                                                    <p id="mapView" class="map"></p>
                                                    <!-- 스크롤 올릴때 필요함 -->
                                                    <div class="bg"></div>
                                                    <!-- 스크롤넣기 -->
                                                    <div class="dirDiv">
                                                        <div class="no_ul_scroll">
                                                            <a class="titleBtn">찾아 오시는 길</a>
                                                            <p class="descript">주변 대중교통 (지하철, 버스)</p>
                                                            <!-- 스크롤 추가 -->
                                                            <div class="subUl_scroll">
                                                                <ul class="subUl">
                                                                    <li>
                                                                        <div class="busDiv">
                                                                            <p class="title">주변 버스</p>
                                                                            <ul class="busList transportationUl">
                                                                                <li>
                                                                                    <span class="stationNum">8</span>
                                                                                    <p class="stationName">몽촌토성역</p>
                                                                                </li>
                                                                                <li>
                                                                                    <span class="stationNum">7</span>
                                                                                    <p class="stationName">한성백제역</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="subDiv">
                                                                            <p class="title">주변 지하철</p>
                                                                            <ul class="subList transportationUl">
                                                                                <li>
                                                                                    <span class="busColour">간선</span>
                                                                                    <p class="stationName"><span>333</span><span>340</span></p>
                                                                                </li>
                                                                                <li>
                                                                                    <span class="busColour grn">지선</span>
                                                                                    <p class="stationName"><span>3216</span><span>3313</span><span>3315</span><span>3414</span></p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <div class="dirDetDiv">
                                                                    <p class="address"><span class="title">주소</span>서울특별시 송파구 방이2동 107-6 가응빌딩 2층</p>
                                                                    <p class="tel"><span class="">전화번호</span>02-420-7582</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div> 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div class="second">
                        <ul id="groupTeamHeader" class="sortHeader groupTeamHeader">
                            <li class="num"><span>No</span></li>
                            <li class="placeNm headerSort sortDown" :class="{sortDown : sort_hospital_name_asc, sortOff : false}"><span>병원명</span></li>
                            <li class="people"><span>진료과</span></li>
                            <li class="king"><span>의료진</span></li>
                            <li class="pos headerSort sortOff"><span class="sort">마지막 진료</span></li>
                            <!-- <li class="placeNm headerSort sortDown"><span>병원명</span></li>
                            <li class="people headerSort sortOff"><span>진료과</span></li>
                            <li class="king headerSort sortOff"><span>의료진</span></li>
                            <li class="pos headerSort sortOff"><span class="sort">마지막 진료</span></li> -->
                            <li class="status"><span>현황<span></span></span></li>
                        </ul>
                        <div id="group_team_list">
                            <ul class="grpTeamUl" v-if="hodu_d_list.length > 0">
                                <!-- 병원리스트는 리스트에 group 클래스 추가 (팀이 없음) -->
                                <li class="group" :class="{on : hospitalIndex == selected_hospital_index}" :key="hospitalIndex" v-for="(hospital, hospitalIndex) in sortedHospitalList" @click="changeHospital(hospitalIndex)">
                                    <div>
                                        <p class="num">{{hospitalIndex+1}}</p>
                                        <h6 class="placeNm"><span class="cl dc7"></span>{{hospital.hospital_info.name}}</h6>
                                        <p class="img" :style="{backgroundImage: `url(${hospital.hospital_info != null && hospital.hospital_info.images != null && hospital.hospital_info.images.length > 0 ? `app_images/${hospital.hospital_info.images[0].url}` : default_profile_image })`}" >팀 이미지</p>
                                        <p class="people">{{hospital.departments.length > 1 ? `${hospital.departments[0].department_name} (+${hospital.departments.length-1})` : (hospital.departments.length > 0 ? hospital.departments[0].department_name : "-") }}</p>
                                        <p class="king">{{hospital.doctors.length > 1 ? `${hospital.doctors[0].doctor_name} (+${hospital.doctors.length-1})` : (hospital.doctors.length > 0 ? hospital.doctors[0].doctor_name : "-")}}</p>
                                        <p class="pos me">20.03.12 목</p>
                                        <p class="status"><a>{{ hospitalIndex == selected_hospital_index ? "보는 중" : "보기"}}</a></p>
                                    </div>
                                </li>
                                <!-- <li class="on group">
                                    <div>
                                        <p class="num">1</p>
                                        <h6 class="placeNm"><span class="cl dc7"></span>이석호 내과</h6>
                                        <p class="img imgDc4">팀 이미지</p>
                                        <p class="people">내과</p>
                                        <p class="king">이석호 (+1)</p>
                                        <p class="pos me">20.03.12 목</p>
                                        <p class="status"><a>보기</a></p>
                                    </div>
                                </li>
                                <li class="group">
                                    <div>
                                        <p class="num">2</p>
                                        <h6 class="placeNm"><span class="cl dc4"></span>서울연세치과</h6>
                                        <p class="img imgDc8">팀 이미지</p>
                                        <p class="people">치과</p>
                                        <p class="king">이연유</p>
                                        <p class="pos me">20.01.14 수</p>
                                        <p class="status"><a>보기</a></p>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { t_event } from '../../model/event';
import { namespace } from 'vuex-class';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { hodu_doc_table, hodu_doc_object } from '../../model/hodudoc';
import moment from 'moment';

const HoduDocInfo = namespace('HoduDocInfo');
declare var daum: any;

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalList extends Mixins(VueHoduCommon) {

    get sortedHospitalList() : hodu_doc_object.my_hospital_info[] {
        let new_list = this.hodu_d_list
        
        return new_list
    }


    get computedAppointsYears() : number[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }
        
        let year_list : number[] = []

        for (let appoint of this.hodu_d_list[this.selected_hospital_index].appointments ) {
            let year = new Date(appoint.event_data.schedule_date.start).getFullYear();
            if ( year_list.includes(year) == false ) {
                year_list.push(year)
            }
        }

        year_list.sort((year1: number, year2: number) : number => {
            if( year1  > year2 ) { return  1; }
            if( year1  < year2 ) { return -1; }
            if( year1 == year2 ) { return  0; }
            return 0;
        })

        if ( year_list.length < 1 ) {
            year_list.push(new Date().getFullYear())
        }
        return year_list;
    }

    /**
     * 접수
     */
    get receiptAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let receiptAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "RECEIPT" &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        receiptAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return receiptAppoints;
    }
    
    get requestAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let requestAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "REQUEST"  &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        requestAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return requestAppoints;
    }

    get declineAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let declineAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "DECLINE" &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        declineAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return declineAppoints;
    }

    get confirmAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let confirmAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "CONFIRM" && 
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        confirmAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return confirmAppoints;
    }

    get diagnosisAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let diagnosisAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "DIAGNOSIS" && 
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        diagnosisAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return diagnosisAppoints;
    }

    get noshowAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let noshowAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "NOSHOW" &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        noshowAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return noshowAppoints;
    }

    get computedFilesYears() : number[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].files == null ) { return [2020]; }
        
        let year_list : number[] = []

        for (let file of this.hodu_d_list[this.selected_hospital_index].files ) {
            if ( file.audit_modified == null ) {
                continue;
            }
            let year = new Date(file.audit_modified).getFullYear();
            if ( year_list.includes(year) == false ) {
                year_list.push(year)
            }
        }

        year_list.sort((year1: number, year2: number) : number => {
            if( year1  > year2 ) { return  1; }
            if( year1  < year2 ) { return -1; }
            if( year1 == year2 ) { return  0; }
            return 0;
        })

        if ( year_list.length < 1 ) {
            year_list.push(new Date().getFullYear())
        }
        return year_list;
    }

    /**
     * 년 월에 해당하는 파일
     */
    get computedFiles() : hodu_doc_table.t_hospital_patient_file[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].files == null ) { return []; }

        // let new_files = this.hodu_d_list[this.selected_hospital_index].files.filter(item =>
        //     item.audit_modified != null ?
        //         new Date(item.audit_modified).getFullYear() == this.selected_file_year &&
        //         (this.selected_file_year != 100 ? (new Date(item.audit_modified).getMonth()+1) == this.selected_appoints_month : true ) 
        //     : false
        // );
        // new_files.sort((file1 : hodu_doc_table.t_hospital_patient_file, file2 : hodu_doc_table.t_hospital_patient_file) : number => {
        //     if( file1.audit_modified == null || file2.audit_modified == null ) { return 0; }
        //     if( file1.audit_modified  > file2.audit_modified ) { return -1; } 
        //     if( file1.audit_modified  < file2.audit_modified ) { return  1; } 
        //     if( file1.audit_modified == file2.audit_modified ) { return  0; }
        //     return 0;
        // });
        // return new_files;
        return this.hodu_d_list[this.selected_hospital_index].files;
    }

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;

    hodu_d_list    : hodu_doc_object.my_hospital_info[]  = [];
    selected_hospital_index : number = 0;

    month_list : number[] = [];
    files_month_list : number[] = [];

    // selected_appoints_year  : number = new Date().getFullYear();
    selected_appoints_year  : number = 2020;
    selected_appoints_month : number = 100;

    selected_file_year  : number = 2020;
    selected_file_month : number = 100;

    sort_hospital_name_asc   : boolean = true;
    sort_hospital_last_treat_asc : boolean = true;

    map : any;
    default_profile_image : string = require("@/assets/images/contents/im_photoB.gif");
    // require("@/assets/images/contents/img"+this.group_color_to_class_name+".png");
    
    created() {
        this.month_list.push(100)
        this.files_month_list.push(100)
        for (let i=1; i<=12; i++) {
            this.month_list.push(i)
            this.files_month_list.push(i)
        }
    }

    async mounted() : Promise<void> {
        $(".grp2 .btnBox a").click(function(){
            var getIndex = $(this).index();

            $(this).siblings().removeClass("on");
            $(this).addClass("on");

            $(".contentOnRight ul.menuUl > li").eq(getIndex).siblings().removeClass("on");
            $(".contentOnRight ul.menuUl > li").eq(getIndex).addClass("on");
        });

        // 의료진 학력 경력 더 보기 버튼 클릭 시
        $(".seeMoreDetail").click(function(){
            $(this).parent().parent().parent().addClass("on");

        });

        // .titleBtn 클릭 시
        $(".directionList .dirDiv").click(function(){
            $(".directionList").addClass("mapOff");

        });

        // .titleBtn 클릭 후 암전된 지도 클릭 시 다시 보이기
        $(".contentOnRight .directionList .bg").click(function(){
            $(".directionList").removeClass("mapOff");

        });

        await this.get_group_role_service();
        await this.getHoduDList()

        let container = document.getElementById('mapView');
        let mapOption = {
            center: new daum.maps.LatLng(
                37.566826,
                126.9786567
            ), // 지도의 중심좌표
            level: 3 // 지도의 확대 레벨
        }; 

        this.map = new daum.maps.Map(container, mapOption); //지도 생성 및 객체 리턴

        this.$nextTick(() => {
            this.setScroll();
        })
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const main_height  : number | undefined = $('#grpScroll .viewGroup .content .main').outerHeight();
        const sort_header_height : number | undefined = $('#groupTeamHeader').outerHeight();

        // @ts-ignore
        $('#group_team_list').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (main_height ? main_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    async getHoduDList() {
        await this.hodu_api_call(`api/v1/user/me/hodudoc`, API_METHOD.GET)
            .then((response) => {
                let jsonData = JSON.parse(JSON.stringify(response.data.data));
                this.hodu_d_list.splice(0, this.hodu_d_list.length)
                this.hodu_d_list = this.hodu_d_list.concat(jsonData.hodu_d_list);
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    openCareer(event){

    }

    getPositionStr(position : string) : string {
        switch (position) {
            case "DIR" :
                return "원장";

            case "DPT" :
                return "부원장";

            case "EMP" :
                return "직원";

            default :
                return "";
        }
    }

    /**
     * 예약 상세로 이동
     */
    moveAppointmentDetail(event_id : string) : void {

        if( !this.doSetAppointmentDetailInfo ) { return; }

        this.doSetAppointmentDetailInfo({
            event : null,
            is_patient : true,
        });

        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event_id}`);
    }

    /**
     * 병원 리스트 클릭 이벤트 (병원 체인지)
     */
    changeHospital(index: number) {
        this.selected_hospital_index = index
        this.setNearestYearOfAppoints()
        this.selected_appoints_month = 100
        this.setNearestYearOfFiles()
        this.selected_file_month = 100
    }

    /**
     * 내 진료에서 현재 년도랑 가장 가까운 년도 선택
     */
    setNearestYearOfAppoints(){
        var target = new Date().getFullYear(); // 현재 년도 값
        var near = new Date().getFullYear();
        var abs = 0;
        var min = 10; // +- 몇년까지?

        for(var i = 0; i < this.computedAppointsYears.length; i++) {
            abs = ( (this.computedAppointsYears[i] - target) < 0) ? -(this.computedAppointsYears[i] - target) : (this.computedAppointsYears[i] - target)
            if(abs < min) {
                min = abs
                near = this.computedAppointsYears[i]
            }
        }
        this.selected_appoints_year = near
    }

    /**
     * 파일함에서 현재 년도랑 가장 가까운 년도 선택
     */
    setNearestYearOfFiles(){
        var target = new Date().getFullYear(); // 현재 년도 값
        var near = new Date().getFullYear();
        var abs = 0;
        var min = 10; // +- 몇년까지?

        for(var i = 0; i < this.computedFilesYears.length; i++) {
            abs = ( (this.computedFilesYears[i] - target) < 0) ? -(this.computedFilesYears[i] - target) : (this.computedFilesYears[i] - target)
            if(abs < min) {
                min = abs
                near = this.computedFilesYears[i]
            }
        }
        this.selected_file_year = near
    }

    /**
     * 내 진료 년도 변경 이벤트
     */
    changeAppointmentsYear(event) : void {
        this.selected_appoints_year = Number(event.target.value)
    }

    /**
     * 내 진료 월 변경 이베트
     */
    changeAppointmentsMonth(event) {
        this.selected_appoints_month = Number(event.target.value)
    }

    /**
     * 내 진료 리스트에 년월일 (요일)
     */
    getDateString(date : string | Date) : string {
        return `${moment(date).format('YYYY년 MM월 DD일')} (${this.getDayName(new Date(date).getDay())})`;
    }

    /**
     * 파일함 년도 변경
     */
    changeFileYear(event) {
        this.selected_file_year = Number(event.target.value)
    }
    
    /**
     * 파일함 월 변경
     */
    changeFileMonth(event) {
        this.selected_file_month = Number(event.target.value)
    }

    getMB(file_size : string | number) : number {
        let fileSize = Number(file_size)
        fileSize = Math.round(fileSize/1024)
        return fileSize
    }

    /**
     * 내 진료 리스트에 시간
     */
    getTimeString(date : string | Date) : string {
        let amPm = moment(date).format('A') == "AM" ? "오전" : "오후"
        return `${amPm} ${moment(date).format('hh시 mm분')}`;
    }
    
    /**
     * 병원소개에서 진료시간 문자열
     */
    getWorkSchedule(index) : string {
        if ( this.hodu_d_list.length < 1 || this.hodu_d_list[index] == null || this.hodu_d_list[index].time_tables == null ) {return '';}
        let timetables = this.hodu_d_list[index].time_tables

        let current_timetable : any;
        for ( let timetable of timetables ) {
            let start_date = new Date(timetable.start_date)
            let end_date   = new Date(timetable.end_date)
            
            //! 현재 년도에 해당하는 근무표가 존재한다면
            if ( start_date < new Date() && new Date() < end_date ) {
                current_timetable = timetable
            }
        }
        if ( current_timetable == null ) { 
            return '-' 
        }
        else{
            let final_string = ""
            for (let i=1; i<=6; i++) {
                let dayWork = current_timetable.work_info[i];
                final_string += `(${this.getDayName(i)})`
                
                let start_hh = dayWork.start_am_time != null ? String(dayWork.start_am_time).slice(0,2) : '오전 휴원'
                let start_mm = dayWork.start_am_time != null ? ":" + String(dayWork.start_am_time).slice(2,4) : ''
                let end_hh   = dayWork.end_pm_time   != null ? String(dayWork.end_pm_time).slice(0,2) : (dayWork.end_am_time != null ? String(dayWork.end_am_time).slice(0,2) : '오후 휴원')
                let end_mm   = dayWork.end_pm_time   != null ? ":" + String(dayWork.end_pm_time).slice(2,4) : (dayWork.end_am_time != null ? String(dayWork.end_am_time).slice(2,4) : '')

                final_string += ` ${start_hh}${start_mm} - ${end_hh}${end_mm} | `;

                if ( i == 6 ) {
                    dayWork = current_timetable.work_info[0];
                    final_string += `(${this.getDayName(0)})`
                
                    let start_hh = dayWork.start_am_time != null ? String(dayWork.start_am_time).slice(0,2) : '오전 휴원'
                    let start_mm = dayWork.start_am_time != null ? ":" + String(dayWork.start_am_time).slice(2,4) : ''
                    let end_hh   = dayWork.end_pm_time   != null ? String(dayWork.end_pm_time).slice(0,2) : (dayWork.end_am_time != null ? String(dayWork.end_am_time).slice(0,2) : '오후 휴원')
                    let end_mm   = dayWork.end_pm_time   != null ? ":" + String(dayWork.end_pm_time).slice(2,4) : (dayWork.end_am_time != null ? String(dayWork.end_am_time).slice(2,4) : '')

                    final_string += ` ${start_hh}${start_mm} - ${end_hh}${end_mm}`;
                }
            }
            return final_string;
        }
    }

    /**
     * 병원소개 웹사이트
     */
    getWebSite() : string {
        if ( this.hodu_d_list.length < 1 || this.hodu_d_list[this.selected_hospital_index].hospital_info == null || this.hodu_d_list[this.selected_hospital_index].hospital_info.web_url == null ) { return "-"; }
        return this.hodu_d_list[this.selected_hospital_index].hospital_info.web_url
    }

    /**
     * 병원소개 소개글
     */
    getDescript() : string {
        if ( this.hodu_d_list.length < 1 || this.hodu_d_list[this.selected_hospital_index].hospital_info == null || this.hodu_d_list[this.selected_hospital_index].hospital_info.descript == null ) { return "-"; }
        return this.hodu_d_list[this.selected_hospital_index].hospital_info.descript
    }

}
</script>

<style scoped>
    /* .grpPage .first .img { background-image: url(../../assets/images/footer/hopi0.jpg) }
    .second li:nth-child(1) .img { background-image: url(../../assets/images/footer/hopi0.jpg) !important }
    .second li:nth-child(2) .img { background-image: url(../../assets/images/footer/hopi1.jpg) !important } */

    .grpDetailContent .grp1 .premiumTag  { display:none; font-size:0; position: absolute; top:45px;left:55px; z-index:1000; width:108px; height:108px; background: url('../../assets/images/contents/ic_c_tag.png') no-repeat left top; }
	.grpDetailContent .grp1.hcList .premiumTag { display:block;  background-image: url('../../assets/images/contents/ic_c_tag.png'); }
    .grpDetailContent .grp1.hdList .premiumTag { display:block; background-image: url('../../assets/images/contents/ic_d_tag.png'); }
    
    #grpTeamUlDiv.grpTeam_clr_ul.on { display:block; }
    #grpTeamUlDiv.grpTeam_clr_ul { background:#fff; z-index:1000; display:none; position: absolute;top: 355px;height: 135px;left: 0;box-shadow: 0 5px 10px rgba(0,0,0,0.05)}
    #grpTeamUlDiv.grpTeam_clr_ul li  { margin:0 5px; display: inline-block !important; opacity:1 !important;   width:30px; height:30px;position: static !important; }
    #grpTeamUlDiv.grpTeam_clr_ul:hover li a {position: static !important; opacity:1 !important; display:block !important; } 
    #grpTeamUlDiv.grpTeam_clr_ul li:hover a {position: static !important;  opacity:1 !important; display:block !important; }
    .grpPage .hc .first .grp1  #grpTeamUlDiv.grpTeam_clr_ul a { opacity:1 !important;margin-left:0; }
    #grpTeamUlDiv.grpTeam_clr_ul li a { transition:0.1s; cursor: pointer;width: 25px;height: 25px;font-size:0;box-sizing: border-box; display: inline-block !important;position:static !important; border-radius: 50%;margin-top: 13px;margin-right: 13px;  }
    #grpTeamUlDiv.grpTeam_clr_ul li a:hover { transform: scale(1.12) }
    #grpTeamUlDiv.grpTeam_clr_ul li a.on {background-image: url(../../assets/images/contents/checked_fff.png); transform:scale(1.15);background-image: url(../../assets/images/contents/checked_fff.png); background-position:center center;  }
    #grpTeamUlDiv.grpTeam_clr_ul  { border-top:1px solid #f1f3f5; overflow:hidden; width:100%; text-align:center; padding: 10px 10px 20px;box-sizing: border-box; }
    #grpTeamUlDiv.grpTeam_clr_ul .setting_txt { line-height: 55px; height:55px; padding: 0 20px;font-size:14px; font-weight:bold;}
    #grpTeamUlDiv.grpTeam_clr_ul .setting_clr {position:absolute;top:16px; right:23px; width:23px; height:23px;border-radius:50%; font-size: 0; transition:0.1s;}
    #grpTeamUlDiv.grpTeam_clr_ul .setting_clr:hover { transform:scale(1.15) }






/* 호두닥 용 css */
    .section_ce_fix.grpPage.hoduDoc .current h5 span {   }
    .section_ce_fix.grpPage.hoduDoc .current select { transition: 0.2s; line-height: 33px; cursor:pointer; height: 35px; font-weight: bold;  padding: 0 35px 0 15px; appearance: none; border: 1px solid #232848; border-radius: 20px; background: url('../../assets/images/side_area/bt_open.png') no-repeat 90% center; }
    .section_ce_fix.grpPage.hoduDoc .current select:hover { background-color:#f1f3f5; }
    .section_ce_fix.grpPage.hoduDoc .current select:focus { background-color: #fff; border-color:#477fff; background-image: url('../../assets/images/side_area/bt_close.png')  }
    .section_ce_fix.grpPage.hoduDoc option { font-size: 14px; }
    .section_ce_fix.grpPage.hoduDoc .first .usage { height: 349px;}
    .section_ce_fix.grpPage.hoduDoc .btnBox { z-index: 100; position: absolute; left:0; top:0; height: 100%; width: 200px;border-right:1px solid #e7e9ea; box-sizing: border-box; }
    .section_ce_fix.grpPage.hoduDoc .btnBox a { transition:0.2s; display: block; height: 55px;border-left:3px solid #fff; line-height: 55px; font-size: 13px; box-sizing: border-box; font-weight: bold; padding: 0 25px; width:100%; border-bottom: 1px solid #e7e9ea; ; }
    .section_ce_fix.grpPage.hoduDoc .btnBox a:hover { background:#f6f9fb; border-left:3px solid #f1f3f5; }
    .section_ce_fix.grpPage.hoduDoc .btnBox a.on { background:#fff !important; cursor: default; border-left:3px solid #477fff }
    .section_ce_fix.grpPage.hoduDoc .first .grp2 .push ul { margin-top:0; }
    .section_ce_fix.grpPage.hoduDoc .first .grp2 .push { overflow: hidden; }
    .section_ce_fix.grpPage.hoduDoc .first .grp2 .current a.del { position: absolute; right:30px; top:0; text-align: center;}
    .section_ce_fix.grpPage.hoduDoc .current .btns h5 { transition: 0.2s;  }
    .section_ce_fix.grpPage.hoduDoc .grp2:hover .btns h5, .section_ce_fix.grpPage.hoduDoc .grp2:hover .btns select { margin-right: 100px !important; }
    
    .contentOnRight { position: relative; width:100%; height:100%; box-sizing: border-box; padding-left: 200px;   }
    .contentOnRight ul.menuUl { width:100%; height: 100%; position: relative; }
    .contentOnRight ul.menuUl > li { display:none; position: absolute; width: 100%; height: 100%; }
    .contentOnRight ul.menuUl > li.on { display:block; }

    .contentOnRight .subUl li { border-bottom: 1px solid #f1f3f5;}


    /* 오른쪽 .selectTimeDiv */
    .contentOnRight .selectTimeDiv  {width:100%; overflow: hidden; line-height: 60px; height: 60px; padding: 0 20px; border-bottom: 1px solid #e7e9ea; box-sizing: border-box; }
    .contentOnRight .selectTimeDiv select { font-size: 12px; float:left; margin-top:12px; transition:0.2s; cursor:pointer; height: 35px; line-height: 33px; padding: 0 35px 0 15px; appearance: none; border-radius: 20px; border: 1px solid #232848; font-weight: bold; background:  url('../../assets/images/side_area/bt_open.png') no-repeat 95% center; }
    .contentOnRight .selectTimeDiv select.firstSelect { border-radius: 20px 0 0 20px;  padding-right:15px; background-image: none; }
    .contentOnRight .selectTimeDiv select.firstSelect:focus { background-image: none; }
    .contentOnRight .selectTimeDiv select.secondSelect {  border-radius: 0 20px 20px 0; border-left:0 none;}
    .contentOnRight .selectTimeDiv select:hover { background-color:#f1f3f5; }
    .contentOnRight .selectTimeDiv select:focus { background-color:#fff; border-color:#477fff; background-image: url('../../assets/images/side_area/bt_close.png') }


    /* 오른쪽 내 진료 */
    .contentOnRight .appointmentUl .clr { padding: 0 10px; height: 20px; display: inline-block; line-height:20px; display:inline-block; border-radius:20px; background:#477fff; margin-right: 10px;color:#fff; border: 1px solid; }
    .contentOnRight .appointmentUl .clr.noshow { background: #ff6363;border-color:#ff4949 }
    .contentOnRight .appointmentUl .clr.seen { background: #477fff;border-color:#1c61ff }
    .contentOnRight .appointmentUl .clr.ing { background: #f1f3f5;color:#747e90; border-color: #dadde0; }
    .contentOnRight .appointmentUl .clr.made { background: #FFC72F;color:#39445d; border-color: #f1b71a;}
    .contentOnRight .appointmentUl .clr.confirmed { background: #13D08B; border-color:#17bf82}
    .contentOnRight .appointmentUl .clr.reject { background: #fff0f0;color:#ff6363; border-color:#ffd7d7}

    .contentOnRight .appointmentList .ul_scroll { height: 288px; width:100%; overflow-y: auto; box-sizing: border-box; }
    .contentOnRight .appointmentUl { padding-bottom: 100px; }
    .contentOnRight .appointmentUl li { background:#fff; transition:0.2s; position: relative; overflow: hidden; padding-left: 100px; width: 100%; height: 50px; line-height: 50px; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; ; }
    .contentOnRight .appointmentUl li:hover { background: #f6f9fb; }
    .contentOnRight .appointmentUl li > * { float: left; display: inline-block; font-weight: bold; box-sizing: border-box; padding-right:15px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
    .contentOnRight .appointmentUl li .status { position: absolute; width: 100px; left: 0; text-align: center; padding-right:0; }
    .contentOnRight .appointmentUl li .when { width: 35%; }
    .contentOnRight .appointmentUl li .time { width: 25%; }
    .contentOnRight .appointmentUl li .doc { width: 30%; }
    .contentOnRight .appointmentUl li .num { width: 10%; }
    .contentOnRight .appointmentUl li .goThisPage { position:absolute; left:0; top:0; width:100%; height: 100%; font-size: 0; }

    /* 파일함 */
    .contentOnRight .fileBoxList .ul_scroll { height: 288px; width:100%; overflow-y: auto; box-sizing: border-box; }
    .contentOnRight .filboxUl  { padding-bottom: 100px; }
    .contentOnRight .filboxUl li { position: relative; transition: 0.2s; background: #fff; overflow: hidden; width:100%; height: 50px; line-height: 50px; font-weight: bold; border-bottom:1px solid #f1f3f5; }
    .contentOnRight .filboxUl li:hover { background:#f6f9fb }
    .contentOnRight .filboxUl li > * { float: left; box-sizing: border-box; padding-right: 15px; }
    .contentOnRight .filboxUl li .num { width: 15%; text-align: center; padding-right:0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .contentOnRight .filboxUl li .uploaded { width: 23%; }
    .contentOnRight .filboxUl li .fileName { position: relative; width: 45%; padding-left: 60px; }
    .contentOnRight .filboxUl li .fileName .preview { position: absolute;left: 0; top:6px; width: 45px; height: 35px; font-size: 0; text-align: center; line-height: 35px; border: 1px solid #e7e9ea; border-radius: 3px; background-color:#fff; background-position:center center; background-size: cover; }
    .contentOnRight .filboxUl li .fileName .preview.format  { color:#477fff; font-size: 12px; }
    .contentOnRight .filboxUl li .fileSize { width: 17% }
    .contentOnRight .filboxUl li a { position:absolute; left:0; top:0; width:100%; height: 100%; font-size: 0; }
    .contentOnRight .fileBoxList .selectTimeDiv input { float: right; padding:0 15px !important; background: #477fff; color:#fff; line-height: 30px;height: 30px; border-radius: 20px; margin-top:14px;  }
    .contentOnRight .fileBoxList .selectTimeDiv input:hover  { background:#2467ff  }


    /* 오른쪽 병원소개 */
    .contentOnRight .hopiInfoList .hopi_scroll { width:100%; border-bottom: 1px solid #f1f3f5;  }
    .contentOnRight .hopiInfoList .hopi_scroll .hopiImgsDiv ul.hopiImgUl { margin-bottom:30px; }
    .contentOnRight .hopiInfoList .hopi_scroll .hopiImgsDiv ul.hopiImgUl li  { overflow: hidden; position: static; display: inline-block; margin-left:30px; margin-top:30px; width: 160px; height: 120px; border-radius: 5px; border:1px solid #e7e9ea; font-size:0; } 
    .contentOnRight .hopiInfoList .hopi_scroll .hopiImgsDiv ul.hopiImgUl li a { float: left; width: 100%; height: 100%; display: block; background-size: cover; background-position: center center; background-repeat: no-repeat; }
    .contentOnRight .hopiInfoList .hopi_scroll .hopiImgsDiv ul.hopiImgUl li:nth-child(1) a { background-image: url(../../assets/images/footer/hopi1.jpg); }
    .contentOnRight .hopiInfoList .hopi_scroll .hopiImgsDiv ul.hopiImgUl li:nth-child(2) a { background-image: url(../../assets/images/footer/hopi2.jpg); }
    .contentOnRight .hopiInfoList .hopiDetUl { margin: 20px 30px 20px; }
    .contentOnRight .hopiInfoList .hopiDetUl li { line-height: 35px; height: 35px; font-size: 13px; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; }
    .contentOnRight .hopiInfoList .txtDiv { padding: 0 30px 50px; line-height: none; height: auto;  }
    .contentOnRight .hopiInfoList .txtDiv .title { font-size: 13px; font-weight: bold; margin-bottom: 20px; }
    .contentOnRight .hopiInfoList .txtDiv textarea { width:100%; max-width: 600px; min-height: 180px; padding: 20px;  box-sizing: border-box; resize: none; appearance: none; background:#f6f9fb; border: none; line-height: 24px;color:#232848;  }
    .contentOnRight .hopiInfoList .hopiDetUl li.addrList a { transition:0.2s; line-height: 25px; height: 25px; padding: 0 15px; border-radius: 20px; background:#477fff; color:#fff; display: inline-block; margin-left: 10px; }
    .contentOnRight .hopiInfoList .hopiDetUl li.addrList a:hover { background: #2467ff; color:#fff; }
    .contentOnRight .hopiInfoList .hopiDetUl li span { display:inline-block; width: 70px; font-weight: bold; margin-right:20px; height: 20px; line-height: 20px; padding-right: 10px; border-right: 1px solid #e7e9ea; }
    .contentOnRight .hopiInfoList .hopiDetUl li a {font-weight: bold; color:#477fff; }
    .contentOnRight .hopiInfoList .hopiDetUl li a:hover { color: #2467ff }


    /* 오른쪽 의료진섹션 */
    .contentOnRight .docInfoList .subUl { margin-bottom: 100px; }
    .contentOnRight .docInfoList .subUl li { background: #fff; overflow: hidden; position: relative; padding: 20px 20px 20px 125px; width:100%; box-sizing: border-box; min-height: 120px; max-height: 120px; }
    .contentOnRight .docInfoList .subUl li .docImg { position: absolute; left:30px; top:22px; display:inline-block; font-size:0; width: 70px; height: 70px; border:1px solid #e7e9ea; box-sizing: border-box; border-radius: 50%; background-size: cover; background-position: center center; background-repeat: no-repeat; }
    .contentOnRight .docInfoList .subUl li .docName { font-size: 14px; font-weight: bold; line-height: 32px; height: 32px;  overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
    .contentOnRight .docInfoList .subUl li .position { font-size: 12px; padding: 0 10px; border-radius: 12px; line-height: 20px; height: 20px; border: 1px solid #232848; display: inline-block; font-weight: bold; }
    .contentOnRight .docInfoList .subUl li .king { position:absolute; left: 12px; top:12px; z-index: 100;border-radius: 50%; display: inline-block; width: 32px; text-align: center; font-size: 11px;font-weight: bold; line-height: 32px; border:3px solid #fff; height: 32px; background:#FFC72F ;  }
    .contentOnRight .docInfoList .subUl li .speciality { font-weight: bold;}
    .contentOnRight .docInfoList .subUl li div { display: inline-block;float:left; height: 100%; }
    .contentOnRight .docInfoList .subUl li div .title { font-weight: bold; }
    .contentOnRight .docInfoList .subUl li .firstSub { width: 40%; box-sizing: border-box; padding-right: 15px; }
    .contentOnRight .docInfoList .subUl li .secondSub { width:60%; position: relative; }
    .contentOnRight .docInfoList .subUl li .secondSub dt { margin-bottom:10px }
    .contentOnRight .docInfoList .subUl li .secondSub dd { line-height: 20px; height: 20px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .contentOnRight .docInfoList .subUl li .secondSub dd span { display: inline-block; float: left; width:5px; height: 5px; margin-right:10px; margin-top:7px; border-radius: 50%; background:#b7bbbf }
    .contentOnRight .docInfoList .subUl li .secondSub .inputBg { z-index: 1000;  position: absolute; top:49px;left:0; width:100%; height: 50px; line-height: 60px; background: url(../../assets/images/contents/doc_btm_seeMore_bg.png) repeat-x bottom left }
    .contentOnRight .docInfoList .subUl li .secondSub .inputBg input { height: 30px;line-height: 30px;color:#477fff;background: #fff;border-top: 1px solid #f1f3f5;padding-right: 0 !important;float: none;width: 100%;text-align: left;font-size: 12px !important; }
    .contentOnRight .docInfoList .subUl li .secondSub .inputBg input:hover  { color:#477fff; }
    .contentOnRight .docInfoList .subUl li.on { max-height: none !important; height: auto !important; }
    .contentOnRight .docInfoList .subUl li.on .inputBg { display:none }

    /* 오른쪽 오는길 섹션 */
    .contentOnRight .directionList .map { display: block; margin-top:0; width: 100%; height: 250px; border-bottom:1px solid #e7e9ea; background-size: cover; background-position: center center; background-repeat: no-repeat;  }
    .contentOnRight .directionList .map:hover { cursor:pointer }
    .contentOnRight .directionList .subUl { transition:0.2s; z-index: 10; position:relative; }
    .contentOnRight .directionList .no_ul_scroll { width:100%; height: 100%;  background: #fff; }
    .contentOnRight .directionList.mapOff .no_ul_scroll { overflow-y: auto; }
    .contentOnRight .directionList .no_ul_scroll .titleBtn { transition:0.2s; z-index: 1000; position: relative; transition:0.2s; margin:0 auto; top:-15px; font-size: 14px; display:block; width: 130px; text-align: center; border-radius: 20px; line-height: 35px; height: 35px; font-weight: bold; background:#477fff; color:#fff;  } 
    .contentOnRight .directionList .no_ul_scroll .titleBtn:hover { background:#2467ff }
    .contentOnRight .directionList .no_ul_scroll .descript { text-align: center; margin: 0 0 20px; border-bottom: 1px solid #e7e9ea; padding-bottom: 15px }
    .contentOnRight .directionList.mapOff .no_ul_scroll .descript {margin: 35px 0 20px}
    .contentOnRight .directionList.mapOff  .no_ul_scroll .titleBtn  { top:20px; cursor:default }
    .contentOnRight .directionList.mapOff .no_ul_scroll .titleBtn:hover { background:#477fff }
    .contentOnRight .directionList.mapOff .dirDiv { top:0; position: absolute; width:100%; height:100%; overflow: hidden;padding-top: 50px;box-sizing: border-box; }
    .contentOnRight .directionList.mapOff .bg { transition:0.2s; z-index: 100; position: absolute; top:0;left:0; display:block; width:100%; height: 50px; background:#232848; opacity:0.1; }
    .contentOnRight .directionList.mapOff .bg:hover { cursor:pointer; opacity:0.2;  }
    .contentOnRight .directionList .forRel { width:100%; height: 100%; }
    .contentOnRight .directionList .subUl { overflow: hidden; transition:0.2s; background: #fff; padding: 0 30px;  }
    .contentOnRight .directionList .subUl li { width: 50%; float:left; border: none; }
    .contentOnRight .directionList .subUl li .title { font-size: 14px; font-weight: bold; margin-bottom: 15px; }
    .contentOnRight .directionList .subUl .stationNum { height: 20px; width: 20px; line-height: 20px; text-align: center; display: inline-block; float:left; border-radius: 50%; background:#d50673; margin:4px 10px 0 0; font-weight: bold; color:#fff; }
    .contentOnRight .directionList .subUl li:nth-child(2) .stationNum{background: #caa755}
    .contentOnRight .directionList .subUl .transportationUl  { width: 100%; }
    .contentOnRight .directionList .subUl .transportationUl li { line-height: 30px; height: 30px; width: 100%; }
    .contentOnRight .directionList .subUl .transportationUl li .stationName span { padding-right: 10px;; }
    .contentOnRight .directionList .subUl .transportationUl li .busColour { height: 20px; padding:0 7px; border-radius: 3px; line-height: 20px; text-align: center; display: inline-block; float:left; background:#34a2f7; margin:4px 10px 0 0; font-weight: bold; color:#fff; }
    .contentOnRight .directionList .subUl .transportationUl li .busColour.grn {background:#4dc74d}
    .contentOnRight .directionList .dirDiv { transition:0.2s; }
    .contentOnRight .directionList .dirDiv:hover { margin-top:-20px; }
    .contentOnRight .directionList.mapOff .dirDiv { margin-top:0; }
    .dirDetDiv { padding:20px 30px 0; margin-top:20px; border-top:1px solid #f1f3f5; font-size: 13px; }
    .dirDetDiv p { line-height: 30px; height: 30px; }
    .dirDetDiv span { font-weight: bold; width: 70px; display: inline-block; }
    .contentOnRight .directionList .subUl_scroll { padding-bottom: 50px; }

    .contentOnRight .ul_scroll { overflow-y: auto;height: 348px; }

    /* 호두닥 1400부터 공지사항섹션 사라짐,  컨텐츠 위치변경 */
    @media (max-width: 1400px) {
        .section_ce_fix.grpPage.hoduDoc .first .placeNm {padding-top: 10px;padding-left: 350px;padding-right: 180px;}
        .section_ce_fix.grpPage.hoduDoc .first .cl {right: 40px;left: auto;top: 45px;}
        .section_ce_fix.grpPage.hoduDoc .first .usage  { border-top:1px solid #f1f3f5; }
        .section_ce_fix.grpPage.hoduDoc .btnBox a { height: 50px; line-height: 50px; }
        .section_ce_fix.grpPage.hoduDoc .first .usage { height: 274px;; }
        .section_ce_fix.grpPage.hoduDoc .contentOnRight .directionList .map { height: 200px; }
        .section_ce_fix.grpPage.hoduDoc .hopiInfoList .ul_scroll, .section_ce_fix.grpPage.hoduDoc .docInfoList .ul_scroll  { height: 274px; }
        .section_ce_fix.grpPage.hoduDoc .appointmentList .ul_scroll, .section_ce_fix.grpPage.hoduDoc  .fileBoxList .ul_scroll {height: 213px;}
        .section_ce_fix.grpPage.hoduDoc .first .directionList .descript { padding-left: 0; padding-right:0; }
    }


    /* 나중에 지우기 */
    .contentOnRight .subUl li:nth-child(1) .docImg { background-image: url(../../assets/images/footer/doc_2.png) }
    .contentOnRight .subUl li:nth-child(2) .docImg { background-image: url(../../assets/images/footer/doc_1.png) }

    .filboxUl li:nth-child(1) .fileName .preview {  background-image: url(../../assets/images/footer/w_scope.jpg) }
    .filboxUl li:nth-child(4) .fileName .preview {  background-image: url(../../assets/images/footer/d_scope.jpg) }
    .filboxUl li:nth-child(5) .fileName .preview {  background-image: url(../../assets/images/footer/xray_20200403.jpg) }
    .filboxUl li:nth-child(6) .fileName .preview {  background-image: url(../../assets/images/footer/xray_20200413.jpg) }

</style>