<template>
    <div class="leftDiv" @dragover.prevent="fileDragOver($event)" @dragleave.prevent="fileDragLeave" @drop.prevent="fileDrop($event)">
        <!-- useWhenClosed -->
        <div class="useWhenClosed">
            <div class="forRel">
                <!-- titleBox -->
                <div class="titleBox sub-title">
                    <!-- .chatroomMenu  -->
                    <!-- <input type="button" class="chatroomMenu" value="채팅방 열기" @click="moveChattingRoomList"/> -->

                    <!-- 보여주기 용으로 만든 h4, 나중에는 하나 가지고만 사용가능 함 -->
                    <h4 class="beforeClosed">현재 <span>{{ channels.length }}</span>개의 채팅방이 존재합니다.</h4>
                    <h4 class="afterClosed">{{ connected_channel == null ? '-' : getChattingRoomTitle(connected_channel) }}</h4>
                    
                    <!-- 수정버튼 클릭 시 나옴, section_ce_fix 클래스추가 edit -->
                    <!-- <div class="editModeDiv">
                        <div class="checkDiv">
                            <input type="checkbox" id="cuCheckAll" >
                            <label for="cuCheckAll"><span>전체 선택</span></label>
                        </div>
                        <p class="counting"><span>0</span> / {{ channels.length }}</p>
                    </div> -->
                    
                    <div class="btns" v-if="connected_channel == null">
                        <input type="button" class="schBtn" :class="{ on : is_search_div_open == true }" value="찾기" 
                               @click.prevent="search" />
                        <!-- <input type="button" class="input_btn edit" value="수정" /> -->
                        <!-- <input type="button" class="input_btn del" value="나가기" /> -->
                        <!-- <input type="button" class="input_btn cancel" value="취소" /> -->
                    </div>

                    <!-- <div class="btns2">
                        <input type="button" class="schBtn" value="찾기" />
                        <input type="button" class="input_btn edit" value="편집" />
                        <input type="button" class="input_btn del" value="삭제" />
                        <input type="button" class="input_btn cancel" value="취소" />
                    </div> -->
                    
                    <!-- .shcBtn 클릭시 searchDiv 클래스추가 on -->
                    <div class="searchDiv" :class="{ on : is_search_div_open == true }" v-if="connected_channel == null">
                        <div class="schGrp">
                            <input type="text" id="chatting_room_search" placeholder="채팅방 제목, 채팅방 참여자 검색" :value="channel_search_query" @input="channel_search_query = $event.target.value">
                            <div class="btns">
                                <a class="closeBtn" @click.prevent="() => { 
                                    is_search_div_open = false;
                                    channel_search_query = '';
                                }">닫기</a>
                            </div>
                        </div>
                    </div>
                    <!-- searchDiv ends -->

                </div>
                <!-- titleBox ends -->
            </div>
        </div>
        <!-- useWhenClosed ends -->

        <!-- scroll  chatroomDiv -->
        <div class="chatroomDiv scroll" v-show="connected_channel == null">
            <!--  noChatRoomDiv 채팅방이 없을 시 addClass on -->
            <div class="noChatRoomDiv" :class="{ on : channels.length < 1 }">
                <div class="noChatRoom">
                    <span class="img">엑스가 들어간 채팅방 이미지</span>
                    <p>현재 채팅방이 없습니다 :(</p>
                </div>
            </div>
            <!-- noChatRoomDiv ends -->

            <!-- 결과 없을 시 addClass on -->
            <!-- <div class="noResult">
                <p>검색된 결과가 없습니다.</p>
            </div> -->
            <!-- noResult ends -->

            <!-- chatroomUl 그룹 채팅방 ul  -->
            <ul class="chatroomUl">

                <!-- 선택된 리스트에 on 넣어주기, 테마 채팅은 specList 클래스 추가 -->
                <li :key="channel.channel_uid" v-for="channel in computedChannelList" :class="{ 
                    on : connected_channel != null && connected_channel.channel_uid == channel.channel_uid,
                    specList : channel.info != null && channel.info.banner != null
                }">

                    <!-- 수정 시 보여짐 -->
                    <!-- <div class="checkDiv">
                        <input type="checkbox" id="cuCheck0" />
                        <label for="cuCheck0"></label>
                    </div> -->

                    <!-- 타이틀 있는 채팅방 -->
                    <p class="chName" :title="getUsersText(channel)">
                        {{ getChattingRoomTitle(channel) }}
                        <em class="member_count" v-if="getMemberCountExceptLeavedMember(channel) > 2">{{ getMemberCountExceptLeavedMember(channel) }}</em>
                    </p>

                    <!-- 마지막으로 보내진 내용 -->
                    <p class="lastMsg">
                        {{ (channel.last_msg != null && channel.last_msg['data'] != null) ? 
                                            (((channel.last_msg['data'].msg == null || channel.last_msg['data'].msg.length == 0) && channel.last_msg['data'].files != null && channel.last_msg['data'].files.length != 0) 
                                            ? getFileNameList(channel.last_msg['data'].files) : channel.last_msg['data'].msg) : '메세지 없음' }}
                    </p>

                    <!-- 마지막으로 언제 보냈는지 시간 -->
                    <p class="sent">
                        {{ channel.last_msg != null ? `${hodu_date_to_format_string(new Date(channel.last_msg['send_date']), 'YYYY.MM.DD')} ${getDayOfWeekByDate(new Date(channel.last_msg['send_date']))}` : '' }}
                        <br>
                        {{ channel.last_msg != null ? amPmStringToLocaleAmPmString(hodu_date_to_format_string(new Date(channel.last_msg['send_date']), 'hh:mm a')) : '' }}
                    </p>

                    <!-- 읽지 않은거 -->
                    <p class="unread" v-if="channel != null && channel.msg_idx != null && channel.users_read != null && channel.msg_idx - Number(channel.users_read[user_id]) > 0">{{ channel.msg_idx - Number(channel.users_read[user_id]) }}</p>

                    <!-- 이미지 있을때 -->
                    <div class="peepsDiv one" v-if="channel.info != null && channel.info.banner != null">
                        <img class="error_detect_img" :src="`chat_data/${channel.info.banner.url}`" @error="firstImageError($event)"/>
                        <p class="img first-img" :style="{ backgroundImage : `url(chat_data/${channel.info.banner.url})` }">사진</p>
                    </div>

                    <!-- 이미지 없을때 : 두명 이상일때 클래스추가 two -->
                    <div class="peepsDiv" v-else
                         :class="{
                             one : getTargetUsers(channel).length == 1,
                             two : getTargetUsers(channel).length >= 2
                         }">
                        <img class="error_detect_img" :src="`/app_images/profile/user/${Math.floor((getTargetUsers(channel)[0].user_id ? Number(getTargetUsers(channel)[0].user_id) : 0) / 10000)}/${getTargetUsers(channel)[0].user_id}.jpg`" @error="firstImageError($event)"/>
                        <img class="error_detect_img" :src="`/app_images/profile/user/${Math.floor((getTargetUsers(channel)[1].user_id ? Number(getTargetUsers(channel)[1].user_id) : 0) / 10000)}/${getTargetUsers(channel)[1].user_id}.jpg`" @error="secondImageError($event)" v-if="getTargetUsers(channel).length >= 2"/>
                        <p class="img first-img" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor((getTargetUsers(channel)[0].user_id ? Number(getTargetUsers(channel)[0].user_id) : 0) / 10000)}/${getTargetUsers(channel)[0].user_id}.jpg)` }">사진</p>
                        <p class="img second-img" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor((getTargetUsers(channel)[1].user_id ? Number(getTargetUsers(channel)[1].user_id) : 0) / 10000)}/${getTargetUsers(channel)[1].user_id}.jpg)` }" v-if="getTargetUsers(channel).length >= 2">
                            <span v-if="getTargetUsers(channel).length > 2">+{{ getTargetUsers(channel).length - 1 }}</span>
                        </p>
                    </div>

                    <!-- title에 친구들 적어주기, 클릭시 이 리스트에 클래스추가 on -->
                    <a class="goChatroom" :title="`${getUsersText(channel)} 채팅방으로 가기`" @click.prevent="openChattingRoom(channel)">채팅방으로 이동</a>

                    <!-- leaveDiv 나가기 -->
                    <!-- <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div> -->

                </li>

                <!-- 선택된 리스트에 on 넣어주기, 테마 채팅은 specList 클래스 추가 -->
                <li v-if="false">
                    <!-- 수정 시 보여짐 -->
                    <div class="checkDiv">
                        <input type="checkbox" id="cuCheck0" />
                        <label for="cuCheck0"></label>
                    </div>
                    <!-- 채팅 타입 -->
                    <!-- <p class="chType">일반</p> -->
                    <!-- 타이틀 있는 채팅방 -->
                    <p class="chName" title="김금수, 김고연, 김나은, 김라미">패러글라이딩 동호회</p>
                    <!-- 마지막으로 보내진 내용 -->
                    <p class="lastMsg">신문 내용엔 장 교수 아들 장모씨의 서울대 법대 인턴증명서를 조 장관이 발급해줬다는 정황이 드러난다. 그런데 장 교수의 아들은 이 인턴십</p>
                    <!-- 언제 보냈는지 시간 -->
                    <p class="sent">방금</p>
                    <!-- 읽지 않은거 -->
                    <p class="unread">5</p>
                    <!-- 두명 이상일때 클래스추가 two -->
                    <div class="peepsDiv two">
                        <p class="img">김금수 사진</p>
                        <!-- 마지막 사진 위에 span > 총 인원 적기 (1명 제외한)-->
                        <p class="img"><span>+3</span></p>
                    </div>
                    <!-- title에 친구들 적어주기, 클릭시 이 리스트에 클래스추가 on -->
                    <a class="goChatroom" title="김금수, 김고연, 김나은, 김라미 채팅방으로 가기">채팅창으로 가기</a>
                    <!-- leaveDiv 나가기 -->
                    <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div>
                </li>
                <!-- 테마 채팅은 specList 클래스 추가 -->
                <li v-if="false">
                    <div class="checkDiv">
                        <input type="checkbox" id="cuCheck1" />
                        <label for="cuCheck1"></label>
                    </div>
                    <!-- 타이틀없이 사람만 고르고 채팅방 만듬 -->
                    <!-- <p class="chType">일반</p> -->
                    <p class="chName" title="김금수, 김고연">김금수, 김고연</p>
                    <p class="lastMsg">미국의 북한전문매체 NK뉴스는 28일(현지시간) 김 위원장이 체류</p>
                    <p class="sent">4분 전</p>
                    <p class="unread">32</p>
                    <div class="peepsDiv two">
                        <p class="img">김금수 사진</p>
                        <p class="img">김고연 사진</p>
                    </div>
                    <a class="goChatroom" title="김금수, 김고연 채팅방으로 가기">채팅창으로 가기</a>
                    <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div>
                </li>
                <li v-if="false">
                    <div class="checkDiv">
                        <input type="checkbox" id="cuCheck2" />
                        <label for="cuCheck2"></label>
                    </div>
                    <!-- <p class="chType">일반</p> -->
                    <p class="chName">Jerry Miller</p>
                    <p class="lastMsg">We were told the baby was due in early summer, so it has come early. But we are told both Carrie Symonds and the baby boy are doing well. Not much more is known.</p>
                    <p class="sent">어제</p>
                    <p class="unread">2</p>
                    <!-- 한명일때 클래스추가 one -->
                    <div class="peepsDiv one">
                        <p class="img">Jerry Miller 사진</p>
                    </div>
                    <a class="goChatroom" title="Jerry Miller 채팅방으로 가기">채팅창으로 가기</a>
                    <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div>
                </li>
                <li v-if="false">
                    <div class="checkDiv">
                        <input type="checkbox" id="cuCheck3" />
                        <label for="cuCheck3"></label>
                    </div> 
                    
                    <p class="chName" title="김배진, 김나은, 김라미"><span class="chType dc7 grp">그룹</span>김배진 <span>+2</span></p>
                    <p class="lastMsg">4월 마지막주는 세계보건기구(WHO)에서 정한 ‘세계예방접종주간’이다. 하지만 코로나19의 불안감으로 예방접종에 구멍이 생겼다.</p>
                    <p class="sent">오전 09:34<br>20.04.29 수</p>
                    <p class="unread">42</p>
                    <div class="peepsDiv two">
                        <p class="img">김배진 사진</p>
                        <p class="img"><span>+2</span></p>
                    </div>
                    <a class="goChatroom" title="김배진, 김나은, 김라미 채팅방으로 가기">채팅창으로 가기</a>
                    <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div>
                </li>
                <li v-if="false">
                    <div class="checkDiv">
                        <input type="checkbox" id="cuCheck4" />
                        <label for="cuCheck4"></label>
                    </div>
                    
                    <p class="chName" title="김라미, 김고연, 김나은, 김라미, 김세영"><span class="chType dc2 team">팀</span>김라미 <span>+4</span></p>
                    <p class="lastMsg">신문 내용엔 장 교수 아들 장모씨의 서울대 법대 인턴증명서를 조 장관이 발급해줬다는 정황이 드러난다. 그런데 장 교수의 아들은 이 인턴십</p>
                    <p class="sent">오전 10:11<br>20.04.23 목</p>
                    <p class="unread">35</p>
                    <div class="peepsDiv two">
                        <p class="img">김금수 사진</p>
                        <p class="img"><span>+4</span></p>
                    </div>
                    <a class="goChatroom" title="김라미, 김고연, 김나은, 김라미, 김세영 채팅방으로 가기">채팅창으로 가기</a>
                    <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div>
                </li>
                <li v-if="false">
                    <div class="checkDiv">
                        <input type="checkbox" id="cuCheck5" />
                        <label for="cuCheck5"></label>
                    </div>
                    <!-- <p class="chType">일반</p> -->
                    <p class="chName" title="김연지">김연지 (나)</p>
                    <p class="lastMsg">오늘 준비해야할 것들</p>
                    <p class="sent">오후 11:24<br>20.04.23 목</p>
                    <div class="peepsDiv one">
                        <p class="img">김연지 사진</p>
                    </div>
                    <a class="goChatroom" title="김연지 채팅방으로 가기">채팅창으로 가기</a>
                    <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div>
                </li>
                <!-- 테마 채팅은 specList 클래스 추가 -->
                <li class="specList" v-if="false">
                    <div class="checkDiv">
                        <input type="checkbox" id="cuCheck6" />
                        <label for="cuCheck6"></label>
                    </div>
                    <!-- <p class="chType">일반</p> -->
                    <p class="chName" title="이종현, 김고연, 김나은, 김라미, 김세영, Jennifer Dawson, 우인진">패러글라이딩 동호회</p>
                    <p class="lastMsg">신문 내용엔 장 교수 아들 장모씨의 서울대 법대 인턴증명서를 조 장관이 발급해줬다는 정황이 드러난다. 그런데 장 교수의 아들은 이 인턴십</p>
                    <p class="sent">오후 07:04<br>20.04.22 수</p>
                    <p class="unread">329</p>
                    <div class="peepsDiv one">
                        <p class="img">테마 사진</p>
                    </div>
                    <a class="goChatroom" title="이종현, 김고연, 김나은, 김라미, 김세영, Jennifer Dawson, 우인진 채팅방으로 가기">채팅창으로 가기</a>
                    <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div>
                </li>
                <li v-if="false">
                    <div class="checkDiv">
                        <input type="checkbox" id="cuCheck7" />
                        <label for="cuCheck7"></label>
                    </div>
                    <!-- <p class="chType">일반</p> -->
                    <p class="chName" title="김종현, 김철중, 라미오, 배민영">김종현 <span>+3</span></p>
                    <p class="lastMsg">신문 내용엔 장 교수 아들 장모씨의 서울대 법대 인턴증명서를 조 장관이 발급해줬다는 정황이 드러난다. 그런데 장 교수의 아들은 이 인턴십</p>
                    <p class="sent">오후 03:55<br>20.04.21 화</p>
                    <div class="peepsDiv two">
                        <p class="img">김종현 사진</p>
                        <p class="img"><span>+3</span></p>
                    </div>
                    <a class="goChatroom" title="김종현, 김철중, 라미오, 배민영 채팅방으로 가기">채팅창으로 가기</a>
                    <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div>
                </li>
                <li v-if="false">
                    <div class="checkDiv">
                        <input type="checkbox" id="cuCheck8" />
                        <label for="cuCheck8"></label>
                    </div>
                    <!-- <p class="chType">일반</p> -->
                    <p class="chName" title="배민영, 김나혁">배민영, 김나혁</p>
                    <p class="lastMsg">신문 내용엔 장 교수 아들 장모씨의 서울대 법대 인턴증명서를 조 장관이 발급해줬다는 정황이 드러난다. 그런데 장 교수의 아들은 이 인턴십</p>
                    <p class="sent">오후 09:13<br>20.04.21 화</p>
                    <div class="peepsDiv two">
                        <p class="img">김금수 사진</p>
                        <p class="img">배민영 사진</p>
                    </div>
                    <a class="goChatroom" title="배민영, 김나혁 채팅방으로 가기">채팅창으로 가기</a>
                    <div class="leaveDiv">
                        <input type="button" title="나가기" class="leaveChatroom" value="나가기" />
                    </div>
                </li>
                
            </ul>
            <!-- chatroomUl ends  -->
        </div>
        <!-- scroll chatroomDiv ends -->


        <!-- chatroom, 색상변경은 bgc0~9, editMode -->
        <div class="chatroom" v-show="connected_channel != null" :class="getChattingColorClass()">
            <!-- scroll -->
            <div id="chatroom_scroll" class="scroll">
                <div class="chatroomGrp">
                    <!-- addClass on 아무런 대화가 없을 시 -->
                    <div class="noChatYet" :class="{ on : computedDayChattingList.length < 1 }">
                        <p>대화를 시작하세요 :)</p>
                    </div>

                    <!-- chatsUlDiv -->
                    <div class="chatsUlDiv">
                        
                        <div :key="day.date" v-for="day in computedDayChattingList">
                            
                            <p class="till when">
                                <span class="txt">{{ `${hodu_date_to_format_string(new Date(day.date), 'YYYY.MM.DD')} ${getDayOfWeekByDate(new Date(day.date))}` }}</span> 
                                <span class="line"></span>
                            </p>

                            <ul class="chatsUl">
                                
                                <li :key="chat.idx" v-for="chat in day.chat" :class="{
                                    me : Number(chat.user_id) == user_id,
                                    from : Number(chat.user_id) != user_id, 
                                }">
                                    <div class="chatting-wrap">

                                        <p class="mPic" v-if="Number(chat.user_id) != user_id" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor(chat.user_id ? Number(chat.user_id) / 10000 : 0)}/${chat.user_id}.jpg)` }">사진</p>
                                        <img class="error_detect_img" :src="`/app_images/profile/user/${Math.floor(chat.user_id ? Number(chat.user_id) / 10000 : 0)}/${chat.user_id}.jpg`" @error="userImageError($event)" v-if="Number(chat.user_id) != user_id"/>

                                        <div class="chatGrp">
                                            <p class="mName" v-if="Number(chat.user_id) != user_id">{{ getUserName(chat.user_id ? chat.user_id : '') }}</p>
                                            <p class="time"><span>{{ chat.send_date ? getTimeTextByDate(new Date(chat.send_date)) : '' }}</span></p>
                                        </div>

                                        <div class="msgDiv" v-if="chat.data != null">

                                            <!-- 일반 메세지 -->
                                            <p class="msg" v-if="chat.data.msg != null && chat.data.msg.length > 0">
                                                <span class="msg-span" v-html="hodu_make_href((chat.data != null && chat.data.msg != null ? chat.data.msg : '').replace(/\n/ig, '<br/>'))"></span>
                                                
                                                <!--  읽음여부 -->
                                                <em class="read_count">{{ getReadCount(chat) }}</em>

                                                <!-- 삭제버튼 -->
                                                <input type="button" class="delBtn" @click="deleteChatting(chat)"/>
                                            </p>
                                            
                                            <!-- 이미지 -->
                                            <p class="msg img" v-else-if="chat.data.files != null && chat.data.files.filter(item => new RegExp('image').test(item.mimeType) == true).length > 0">
                                                <span class="msg-span" :key="index" v-for="(file, index) in (Number(chat.user_id) == user_id ? chat.data.files.slice().reverse() : chat.data.files)">
                                                    <a :style="{ backgroundImage : `url(chat_data/${file.url})` }" @click.prevent="imageOpen(chat.data.files, file)" :title="file.name">사진</a>
                                                    <img class="error_detect_img" :src="`chat_data/${file.url}`" @error="imageError($event)"/>
                                                </span>

                                                <!--  읽음여부 -->
                                                <em class="read_count">{{ getReadCount(chat) }}</em>
                                                
                                                <!-- 삭제버튼 -->
                                                <input type="button" class="delBtn" @click="deleteChatting(chat)"/>
                                            </p>

                                            <!-- 파일 -->
                                            <p class="msg file format" v-else-if="chat.data.files != null && chat.data.files.filter(item => new RegExp('image').test(item.mimeType) == false).length > 0">
                                                <span class="msg-span" :key="index" v-for="(file, index) in (Number(chat.user_id) == user_id ? chat.data.files.slice().reverse() : chat.data.files)">
                                                    <a @click.prevent="download(file)" :title="file.name">{{ file.name.substr(file.name.lastIndexOf('.') + 1) }}</a>
                                                    <em>{{ file.name }}</em>
                                                    <em>{{ 
                                                        file.size >= 1 * 1024 * 1024 ? `${ (file.size / 1024 / 1024).toFixed(2) } MB` :
                                                        file.size >= 1 * 1024        ? `${ (file.size / 1024       ).toFixed(2) } KB` :
                                                                                       `${ file.size } Byte`         
                                                    }}</em>
                                                </span>

                                                <!--  읽음여부 -->
                                                <em class="read_count">{{ getReadCount(chat) }}</em>
                                                
                                                <!-- 삭제버튼 -->
                                                <input type="button" class="delBtn" @click="deleteChatting(chat)"/>
                                            </p>
                                            
                                        </div>

                                    </div>
                                </li>

                            </ul>

                        </div>

                        <div v-if="false">
                            <!-- 연도 -->
                            <p class="till when"><span class="txt">2020.04.29 수</span> <span class="line"></span></p>
                            <!-- chatsUl -->
                            <ul class="chatsUl">

                                <!-- 상대방일 시 클래스추가 from -->
                                <li class="from">
                                    <div>
                                        <p class="mPic">사진</p>
                                        <div class="chatGrp">
                                            <p class="mName">김고연</p>
                                            <p class="time"><span>오후 01:12</span> 2020.04.29 수</p>
                                        </div>
                                        <!--  체크박스  checked 시 msgDiv 클래스 추가 on -->
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck0" />
                                                <label for="msgCheck0"></label>
                                            </div>
                                            <p class="msg first deleted"><span><em>X</em>삭제된 메세지 입니다.</span></p>
                                        </div>
                                        
                                    </div>
                                </li>
                                <li class="from">
                                    <div>
                                        <!-- 상대방 사진 -->
                                        <p class="mPic">사진</p>
                                        <!-- chatGrp -->
                                        <div class="chatGrp">
                                            <!-- 상대방 이름 -->
                                            <p class="mName">김나은</p>
                                            <!-- 상대방이 보낸 시간 -->
                                            <p class="time"><span>오후 01:15</span> 2020.04.29 수</p>
                                        </div>
                                        <!-- 첫번째 msg에는 클래스 추가 first -->
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck1" />
                                                <label for="msgCheck1"></label>
                                            </div>
                                            <p class="msg first"><span>이번에 서울대 인턴관련 기사 보신 분?</span></p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck2" />
                                                <label for="msgCheck2"></label>
                                            </div>
                                            <p class="msg img one"><span><a>사진</a></span></p>
                                        </div>
                                    </div>
                                </li>
                                <li class="me">
                                    <div>                                                
                                        <div class="chatGrp">
                                            <p class="time"><span>오후 01:19</span> 2020.04.29 수</p>
                                        </div>

                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck3" />
                                                <label for="msgCheck3"></label>
                                            </div>
                                            <p class="msg first deleted"><span><em>X</em>삭제된 메세지 입니다.</span></p>
                                        </div>

                                        <!-- 링크 들어갔을 시 -->
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck4" />
                                                <label for="msgCheck4"></label>
                                            </div>
                                            <p class="msg"><span>여기 한번 체크해보세요 <a>http://www.hoduware.com</a></span></p>
                                        </div>
                                    </div>
                                </li>
                                <li class="me">
                                    <div>
                                        <div class="chatGrp">
                                            <p class="time"><span>오후 03:02</span> 2020.04.29 수</p>
                                        </div>
                                        <!-- 파일 -->
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck5" />
                                                <label for="msgCheck5"></label>
                                            </div>
                                            <p class="msg file format"><span><a>pdf</a><em>mj2223dwe.pdf</em><em>2.3 mb</em></span></p>
                                        </div>
                                    </div>
                                </li>
                                <li class="from">
                                    <div>
                                        <p class="mPic">사진</p>
                                        <div class="chatGrp">
                                            <p class="mName">김고연</p>
                                            <p class="time"><span>오후 03:03</span> 2020.04.29 수</p>
                                        </div>
                                        <!-- 첫번째 msg에는 클래스 추가 first -->
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck6" />
                                                <label for="msgCheck6"></label>
                                            </div>
                                            <p class="msg img first"><span><a>사진</a></span></p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck7" />
                                                <label for="msgCheck7"></label>
                                            </div>
                                            <p class="msg file vid"><span><a><span>프리뷰</span></a><em>20200403_Edreedddemm.mp4</em><em>14 mb</em></span></p>
                                        </div>
                                    </div>
                                </li>
                                <li class="from">
                                    <div>
                                        <p class="mPic">사진</p>
                                        <div class="chatGrp">
                                            <p class="mName">김금수</p>
                                            <p class="time"><span>오후 03:04</span> 2020.04.29 수</p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck8" />
                                                <label for="msgCheck8"></label>
                                            </div>
                                            <p class="msg first"><span>혹시 못 보신분들을 위해 기사 내용 올립니다.</span></p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck9" />
                                                <label for="msgCheck9"></label>
                                            </div>
                                            <p class="msg"><span>신문 내용엔 장 교수 아들 장모씨의 <a>http://www.hoduware.com</a> 서울대 법대 인턴증명서를 조 장관이</span></p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck10" />
                                                <label for="msgCheck10"></label>
                                            </div>
                                            <p class="msg"><span>그런데 장 교수의 아들은 이 인턴십...</span></p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck11" />
                                                <label for="msgCheck11"></label>
                                            </div>
                                            <p class="msg file format"><span><a>zip</a><em>호두웹_이미지.zip</em><em>14mb</em></span></p>
                                        </div>
                                    </div>
                                </li>
                                <li class="me">
                                    <div>
                                        <div class="chatGrp">
                                            <p class="time"><span>오후 03:15</span> 2020.04.29 수</p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck12" />
                                                <label for="msgCheck12"></label>
                                            </div>
                                            <p class="msg img first"><span><a>사진</a></span></p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <!-- chatsUl ends -->
                        </div>

                        <div v-if="false">
                            <!-- 여기까지 읽음 -->
                            <p class="till"><span class="txt">여기까지 읽음</span> <span class="line"></span></p>
                            <!-- chatsUl -->
                            <ul class="chatsUl">
                                <!-- 상대방일 시 클래스추가 from -->
                                <li class="from">
                                    <div>
                                        <!-- 상대방 사진 -->
                                        <p class="mPic">사진</p>
                                        <!-- chatGrp -->
                                        <div class="chatGrp">
                                            <!-- 상대방 이름 -->
                                            <p class="mName">김나은</p>
                                            <!-- 상대방이 보낸 시간 -->
                                            <p class="time">14분 전</p>
                                        </div>
                                        <!-- 첫번째 msg에는 클래스 추가 first -->
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck13" />
                                                <label for="msgCheck13"></label>
                                            </div>
                                            <p class="msg first"><span>이번에 서울대 인턴관련 기사 보신 분?</span></p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck14" />
                                                <label for="msgCheck14"></label>
                                            </div>
                                            <p class="msg"><span>세상에....</span></p>
                                        </div>
                                    </div>
                                </li>
                                <li class="from">
                                    <div>
                                        <p class="mPic">사진</p>
                                        <div class="chatGrp">
                                            <p class="mName">김고연</p>
                                            <p class="time">5분 전</p>
                                        </div>
                                        <!-- 첫번째 msg에는 클래스 추가 first -->
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck15" />
                                                <label for="msgCheck15"></label>
                                            </div>
                                            <p class="msg first"><span>그러게나 말이에요.</span></p>
                                        </div>
                                    </div>
                                </li>
                                <li class="from">
                                    <div>
                                        <p class="mPic">사진</p>
                                        <div class="chatGrp">
                                            <p class="mName">김금수</p>
                                            <p class="time">2분 전</p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck16" />
                                                <label for="msgCheck16"></label>
                                            </div>
                                            <p class="msg first"><span>혹시 못 보신분들을 위해 기사 내용 올립니다.</span></p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck17" />
                                                <label for="msgCheck17"></label>
                                            </div>
                                            <p class="msg"><span>신문 내용엔 장 교수 아들 장모씨의 서울대 법대 인턴증명서를 조 장관이 발급해줬다는 정황이 드러난다. 그런데 장 교수의 아들은 이 인턴십...</span></p>
                                        </div>
                                    </div>
                                </li>

                                <!-- 내가 보낸 건 클래스 추가 me -->
                                <li class="me">
                                    <div>
                                        <div class="chatGrp">
                                            <p class="time">방금</p>
                                        </div>
                                        <div class="msgDiv">
                                            <div class="checkDiv">
                                                <input type="checkbox" id="msgCheck18" />
                                                <label for="msgCheck18"></label>
                                            </div>
                                            <p class="msg first"><span>링크 좀 보내주세요</span></p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <!-- chatsUl ends -->
                            </div>
                        </div>

                    <!-- chatsUlDiv ends -->
                </div>
            </div>
            <!-- scroll ends -->

            <!-- chatroomPanel -->
            <div class="chatroomPanel" :class="{ on : is_extra_panel_open == true }">
                <div class="forRel">
                    <!-- .typingDiv -->
                    <div class="typingDiv">
                        <div class="otherTyping bg">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div class="meTyping bg">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                    <!-- mainPanel -->
                    <div class="mainPanel">
                        <!-- .addExtra 클릭 시 .extraPanel에 클래스 추가 on  -->
                        <input type="button" class="addExtra" :class="{ on : is_extra_panel_open == true }" value="사진, 이모티콘, 파일 업로드" title="사진, 이모티콘, 파일 업로드" @click="extraPanelOnOff"/>
                        <textarea id="chatting_textarea" placeholder="채팅을 입력하세요 (ctrl + enter 또는 shift + enter 개행)" :value="chatting_message" 
                                  @input="chatting_message = $event.target.value" @keyup="keyUpTextArea" @keydown="keyDownTextArea"></textarea>
                        <input type="button" class="sendBtn" value="보내기" @click="sendMessage"/>
                    </div>
                    <!-- mainPanel ends -->

                    <!-- extraPanel, .addExtra 클릭 시 클래스 추가 on -->
                    <div class="extraPanel" :class="{ on : is_extra_panel_open == true }">
                        <div class="forRel">
                            <!-- TODO 나중에 이모티콘 생기면 추가 -->
                            <!-- <input type="button" class="addEmojis" id="addEmojis" value="이모티콘" title="이모티콘" /> -->

                            <!-- <input type="file" class="addImg" id="addImg" value="사진 업로드" title="사진 업로드" /> -->
                            <!-- <label for="addImg" class="addImg" title="사진 업로드">사진</label> -->

                            <input type="file" class="addFile" id="addFile" value="파일 업로드" title="파일 업로드" @change="addFile($event)" multiple/>
                            <label for="addFile" class="addFile" title="파일 업로드">파일</label>

                            <input type="button" class="changeChatroomBgBtn" :class="getChattingColorClass()" value="채팅방 배경 변경" title="채팅방 배경 변경" @click="is_chatting_room_background_change_open = !is_chatting_room_background_change_open"/>
                            <div class="chatroomBgDiv" :class="{ on : is_chatting_room_background_change_open == true }">

                                <a class="bgc0" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#FFE0E0' }" @click="selectBackgroundColor('#FFE0E0')">연어</a>
                                <a class="bgc1" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#FFEDCF' }" @click="selectBackgroundColor('#FFEDCF')">주황</a>
                                <a class="bgc2" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#FFF4D5' }" @click="selectBackgroundColor('#FFF4D5')">노랑</a>
                                <a class="bgc3" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#FFD1E8' }" @click="selectBackgroundColor('#FFD1E8')">자주</a>
                                <a class="bgc4" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#CCF0F4' }" @click="selectBackgroundColor('#CCF0F4')">청록</a>
                                <a class="bgc5" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#D0F6E8' }" @click="selectBackgroundColor('#D0F6E8')">초록</a>
                                <a class="bgc6" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#DBF1FF' }" @click="selectBackgroundColor('#DBF1FF')">하늘</a>
                                <a class="bgc7" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#DAE5FF' }" @click="selectBackgroundColor('#DAE5FF')">파랑</a>
                                <a class="bgc8" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#E1DDFF' }" @click="selectBackgroundColor('#E1DDFF')">보라</a>
                                <a class="bgc9" :class="{ on : hodu_hex_color_process(isSelectedColor()).toUpperCase() == '#D7D9DE' }" @click="selectBackgroundColor('#D7D9DE')">검정</a>

                                <input type="button" class="closeBtn" value="닫기" @click="is_chatting_room_background_change_open = !is_chatting_room_background_change_open"/>
                            </div>
                        </div>
                    </div>
                    <!-- extraPanel ends -->
                </div>
            </div>
            <!-- chatroomPanel ends -->
        </div>
        <!-- chatroom ends -->
        
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { hodu_color } from '@/common/color';

import { chat_interface, chat_enum, chat_modal_interface } from '@/model/chat';
import { t_event_file } from '@/model/event';

import moment from 'moment';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ChattingLeft extends Mixins(VueHoduCommon) {

    @Prop() event_bus         !: Vue;
    @Prop() channels          !: chat_interface.I_CHANNEL_INFO[];
    @Prop() connected_channel !: chat_interface.I_CHANNEL_INFO | null;
    @Prop() chat_list         !: chat_interface.I_CHAT_MESSAGE[];
    
    /**
     * 검색된 채널 리스트
     */
    get computedChannelList() : chat_interface.I_CHANNEL_INFO[] {
        return this.channels.filter(channel => (channel.info != null && channel.info.title != null && this.hodu_string_includes(channel.info.title, this.channel_search_query)) || 
                                               (channel.users != null && channel.users.filter(user => this.hodu_string_includes(user.user_name, this.channel_search_query)).length > 0));
    }

    /**
     * 일별 채팅 리스트
     */
    get computedDayChattingList() : chat_interface.I_CHAT_DAY_MESSAGE[] {

        let list : chat_interface.I_CHAT_DAY_MESSAGE[] = [];

        for( const chat of this.chat_list ) {
            const target_date : number = moment(chat.send_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate().getTime();
            const target_list = list.filter(item => item.date == target_date);
            
            if( chat.data != null && chat.data.msg != null ) {
                chat.data.msg = chat.data.msg.replace(/(<([^>]+)>)/ig, "");
            } 

            if( target_list.length < 1 ) {
                list.push({
                    date : target_date,
                    chat : [chat]
                });
                continue;
            }

            target_list[0].chat.push(chat);
            target_list[0].chat.sort((o1, o2) : number => {
                
                if( !o1.send_date || !o2.send_date ) { return 0; }

                if( o1.send_date < o2.send_date ) { return -1; }

                if( o1.send_date > o2.send_date ) { return 1; }

                return 0;
            })
        }

        list.sort((o1, o2) : number => {

            if( o1.date < o2.date ) { return -1; }

            if( o1.date > o2.date ) { return 1; }

            return 0;
        })

        console.log("ChattingLeft:computedDayChattingList");
        
        return list;
    }

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventImagePreview              ?: any;
    @ModalInfo.Action doSetChattingRoomInfoModalInfo          ?: (params : chat_modal_interface.ChattingRoomInfoModalInfo) => void;
    
    SCROLL_COUNT : number = 50;

    chatting_message : string = "";
    channel_search_query : string = "";
    
    is_search_div_open : boolean = false;
    is_first_scroll_event_end : boolean = false;
    is_page_end : boolean = false;
    is_extra_panel_open : boolean = false;
    is_chatting_room_background_change_open : boolean = false;
    isShift : boolean = false; // 쉬프트가 눌렸는지 여부
    isCtrl : boolean = false; // 컨트롤키 눌렸는지 여부

    clipboard_selection_start : number = 0;
    clipboard_selection_end : number = 0;

    mounted() : void {
        this.event_bus?.$on('handleLeftResize', this.handleResize);
        this.event_bus?.$on('scrollBottom', () => { 
            this.$nextTick(() => {
                const scroll_amount : number | undefined = $('.chatroom .scroll .chatroomGrp').outerHeight();
                const scroll_amount_number : number = scroll_amount ? scroll_amount : 0;
                if( scroll_amount_number < 1 ) return;
                $('.chatroom .scroll').scrollTop(scroll_amount_number);
            })
        });
        this.event_bus?.$on('setPageEnd', (is_end) => this.is_page_end = is_end);
        this.event_bus?.$on('scrollInit', this.setChattingRoomScroll);

        this.setScroll();

        // 스크롤 이벤트
        $('#chatroom_scroll').on("scroll", this.onChatRoomScroll);

        this.clipboardEventAdd();
    }

    /**
     * 클립보드 처리 
     */
    async clipboardEventAdd() : Promise<void> {
        const target = document.querySelector('textarea#chatting_textarea');

        target?.addEventListener('paste', (event) => {
            console.log(event);

            this.clipboard_selection_start = (target as HTMLTextAreaElement).selectionStart;
            this.clipboard_selection_end = (target as HTMLTextAreaElement).selectionEnd;

            if( !(event instanceof ClipboardEvent) ) {
                return;
            }

            const clipboardData = event.clipboardData;

            if( clipboardData == null ) {
                return;
            }

            const items = clipboardData.items;
            const files = clipboardData.files;

            const text = clipboardData.getData('Text');

            if( text != null && text.length > 0 ) {
                return;
            }

            // 클립보드 아이템
            if( items != null && items.length > 0 ) {

                event.preventDefault();

                const dialog_button_text : string[] = ['취소'];
                const dialog_button_callback : Function[] = [() => { 
                    (target as HTMLElement).focus(); 
                    (target as HTMLTextAreaElement).selectionStart = this.clipboard_selection_start;
                    (target as HTMLTextAreaElement).selectionEnd = this.clipboard_selection_end;
                }];

                for(const item of items) {

                    let target_item : string | File = "";
                    if( item.type.includes('text/plain') ) {
                        item.getAsString((data) => {
                            target_item = data;
                        });
                    }

                    if( item.type.includes('image') ) {
                        const file = item.getAsFile();
                        if( file ) target_item = file;
                    }

                    if( item.type.includes('text/plain') || item.type.includes('image') ) {
                        dialog_button_text.push(item.type.includes('text/plain') ? '텍스트' : '이미지');
                        dialog_button_callback.push(() => {
                            console.log(target_item);
                            this.clipboardProcess(target, target_item);
                        });
                    }

                }

                if( dialog_button_text.length == 1 ) return;
                if( dialog_button_text.length == 2 ) {

                    const item = items[0];

                    if( item.type.includes('text/plain') ) {
                        item.getAsString((data) => {
                            this.clipboardProcess(target, data);
                        });
                    }

                    if( item.type.includes('image') ) {
                        const file = item.getAsFile();
                        if( file ) this.clipboardProcess(target, file);
                    }

                    return;
                }
                    
                this.hodu_show_dialog("alert", "복사 유형을 선택해주세요", dialog_button_text, dialog_button_callback);
                return;
            }

            // 파일인 경우
            else if( files != null && files.length > 0 ) {

                const file : File | null = files.item(0);

                console.log(file);

                if( file == null ) return;

                this.addFileEvent([file]);
            }

        });
    }

    /**
     * 복사 유형 파악후 붙여넣기
     */
    clipboardProcess(target : Element, target_item : string | File) : void {
        console.log(target_item);

        (target as HTMLElement).focus();

        if( target_item instanceof File ) {
            this.addFileEvent([target_item]);
        }

        if( 'string' == typeof target_item ) {

            const original_value = (target as HTMLTextAreaElement).value;
            let value = "";
            
            const start_value = original_value.substring(0, this.clipboard_selection_start);
            const end_value = original_value.substring(this.clipboard_selection_end);

            console.log(start_value);
            console.log(end_value);

            value = start_value + (target_item as string) + end_value;
            (target as HTMLTextAreaElement).value = value;
            (target as HTMLTextAreaElement).selectionStart = this.clipboard_selection_start + (target_item as string).length;
            (target as HTMLTextAreaElement).selectionEnd = this.clipboard_selection_start + (target_item as string).length;
            this.chatting_message = value;
            this.isCtrl = false;
            this.isShift = false;
        }
    }

    /**
     * 스크롤 설정
     */
    @Watch('connected_channel')
    setScroll() : void {
        
        // if( this.connected_channel != null ) { return; }

        try {
            const main_title_height : number | undefined = $('.section_ce_fix .main-title').outerHeight();
            const sub_title_height : number | undefined = $('.section_ce_fix .useWhenClosed .sub-title').outerHeight();

            // @ts-ignore
            $('.chatroomDiv.scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - ( main_title_height == null ? 0 : main_title_height ) - ( sub_title_height == null ? 0 : sub_title_height )
            });

            console.log("ChattingLeft:setScroll");

            this.$nextTick(() => $('#chatting_textarea').focus());

        } catch(e) {
            alert(e);
        }
    }

    /**
     * 채팅방 스크롤 설정
     */
    @Watch('connected_channel')
    setChattingRoomScroll(watch_obj, is_resize : boolean = false) : void {

        if( this.connected_channel == null ) {
            this.is_first_scroll_event_end = false;
            this.is_page_end = false;
            return;
        }

        this.$nextTick(() => {

            const main_title_height : number | undefined = $('.section_ce_fix .main-title').outerHeight();
            const sub_title_height : number | undefined = $('.section_ce_fix .useWhenClosed .sub-title').outerHeight();
            const main_panel_height : number | undefined = $('.section_ce_fix .chatroom .chatroomPanel .mainPanel').outerHeight();

            $('.chatroom .scroll').height(window.innerHeight - ( main_title_height == null ? 0 : main_title_height ) 
                                                             - ( sub_title_height == null ? 0 : sub_title_height )
                                                             - ( main_panel_height == null ? 0 : main_panel_height ));

            
            // 리사이즈가 아니라면
            if( !is_resize ) {
                const scroll_amount : number | undefined = $('.chatroom .scroll .chatroomGrp').outerHeight();
                const scroll_amount_number : number = scroll_amount ? scroll_amount : 0;
                
                if( scroll_amount_number < 1 ) {
                    return;
                }
                
                $('.chatroom .scroll').scrollTop(scroll_amount_number);
                this.is_first_scroll_event_end = true;
                console.log("ChattingLeft:setChattingRoomScroll");
            }

        });
    }

    /**
     * 스크롤
     */
    @Throttle(100)
    onChatRoomScroll(event) : void {
        if( this.is_first_scroll_event_end == false || this.is_page_end == true || this.chat_list.length < 1 ) { return; }

        // console.log(`===============================================`);
        // console.log('onChatRoomScroll');
        // console.log($(event.target).scrollTop());
        // console.log(`===============================================`);

        const scroll_top : number | undefined = $(event.target).scrollTop();

        if( scroll_top == null || scroll_top > window.innerHeight ) { return; }

        // console.log("SCROLL ACTIVATED");
        
        const first_idx = this.chat_list[this.chat_list.length - 1].idx
        if( first_idx == null || first_idx < 2 ) {
            this.is_page_end = true;
        }

        this.send(JSON.stringify({
            type : 'CHANNEL',
            sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG_LIST,
            data : {
                offset : this.chat_list[this.chat_list.length - 1].idx,
                size : this.SCROLL_COUNT
            }
        }));
    }

    /**
     * 채널 나가면서 리셋해야할 내용 리셋
     */
    @Watch('connected_channel')
    reset() : void {
        if( this.connected_channel != null ) { return; }
        this.chatting_message = "";
        this.is_extra_panel_open = false;
        this.is_chatting_room_background_change_open = false; 
        // this.is_search_div_open = false;
        // this.channel_search_query = "";
    }

    /**
     * send
     */
    send(data : string) : void {
        this.$emit('send', data);
    }

    /**
     * 컬러에 따른 클래스 반환
     */
    getChattingColorClass() : string {

        if( this.connected_channel == null ) { return "bgc7"; }

        let target_color = "#DAE5FF";

        if( this.connected_channel?.info?.color ) {
            target_color = this.connected_channel.info.color;
        }

        if( this.connected_channel.users != null ) {
            const my_user_infos = this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id);

            if( my_user_infos.length > 0 && my_user_infos[0].user_info != null && my_user_infos[0].user_info['color'] != null ) {
                target_color = my_user_infos[0].user_info['color'].toUpperCase();
            }
        }

        switch( this.hodu_hex_color_process(target_color).toUpperCase() ) {
            case hodu_color.chat_color_0.toUpperCase(): return "bgc0";
            case hodu_color.chat_color_1.toUpperCase(): return "bgc1";
            case hodu_color.chat_color_2.toUpperCase(): return "bgc2";
            case hodu_color.chat_color_3.toUpperCase(): return "bgc3";
            case hodu_color.chat_color_4.toUpperCase(): return "bgc4";
            case hodu_color.chat_color_5.toUpperCase(): return "bgc5";
            case hodu_color.chat_color_6.toUpperCase(): return "bgc6";
            case hodu_color.chat_color_7.toUpperCase(): return "bgc7";
            case hodu_color.chat_color_8.toUpperCase(): return "bgc8";
            case hodu_color.chat_color_9.toUpperCase(): return "bgc9";
        }

        return `bgc7`;
    }

    /**
     * 선택된 색상 반환
     */
    isSelectedColor() : string {
        if( this.connected_channel == null || this.connected_channel.users == null ) { return "#DAE5FF"; }

        const my_user_infos = this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id);

        // 본인이 지정한 색상이 없는 경우 채팅방 색상 그대로
        if( my_user_infos.length < 1 || my_user_infos[0].user_info == null || my_user_infos[0].user_info['color'] == null ) {
            return (this.connected_channel.info == null || this.connected_channel.info.color == null ? "#DAE5FF" : this.connected_channel.info.color);
        }

        // 본인이 지정한 색상이 있는 경우
        return my_user_infos[0].user_info['color'];
    }

    /**
     * 색상 선택
     */
    async selectBackgroundColor(color : string) : Promise<void> {
        
        if( this.connected_channel == null || this.connected_channel.users == null ) { return; }

        try {

            const my_user_infos = this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id);
            
            if( my_user_infos.length < 1 ) { return; }

            // 본인 유저 정보
            const post_data = JSON.parse(JSON.stringify(my_user_infos[0]));

            if( post_data.user_info == null ) {
                post_data.user_info = {};
            }

            post_data.user_info['color'] = color;

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel.channel_uid}/users/${this.user_id}`, API_METHOD.PUT, post_data);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.member ) {
                throw new Error("채팅방 배경색 변경 오류");
            }

            // 새로운 정보로 교체
            this.connected_channel.users.splice(this.connected_channel.users.indexOf(my_user_infos[0]), 1, response.data.data.member);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "채팅방 배경색 변경 중 오류 발생", ['확인']);
        }
    }

    /**
     * 채팅방 제목 반환 (유저가 정한 제목우선 없으면 처음 만들때 정해진 제목, 제목 자체가 없으면 유저목록)
     */
    getChattingRoomTitle(channel : chat_interface.I_CHANNEL_INFO) : string {

        if( channel.users != null && channel.users.length > 0 ) {
            const target_users = channel.users.filter(user => String(user.user_id) == String(this.user_id));
            if( target_users.length > 0 ) {
                const target_user = target_users[0];

                if( target_user.user_info != null && target_user.user_info['title'] != null ) {
                    return target_user.user_info['title'].trim();
                }
            }
        }

        else if( channel.info != null && channel.info.title != null ) {
            if( channel.info.title.trim().length > 0 ) return channel.info.title.trim();
        }
        
        return this.getUsersText(channel);
    }

    /**
     * 이미지, 텍스트에 나타낼 대상 유저 뽑아내기
     */
    getTargetUsers(channel : chat_interface.I_CHANNEL_INFO) : chat_interface.t_chat_channel_user[] {

        if( channel.users == null ) { return []; }

        if( channel.users.filter(user => user.leaved_flag == false).length < 2 ) { return channel.users.filter(user => user.leaved_flag == false); }

        return channel.users.filter(user => Number(user.user_id) != this.user_id && user.leaved_flag == false);
    }

    /**
     * 나가지 않은 멤버 수 반환
     */
    getMemberCountExceptLeavedMember(channel : chat_interface.I_CHANNEL_INFO) : number {
        if( channel == null || channel.users == null ) return 0;
        return channel.users.filter(user => user.leaved_flag == false).length;
    }

    /**
     * title에 나타낼 유저들 
     */
    getUsersText(channel : chat_interface.I_CHANNEL_INFO) : string {

        if( channel.users == null ) { return ""; }

        let title = "";

        for( const user of this.getTargetUsers(channel) ) {

            let user_name = user.user_name;

            if( isNaN(Number(user.user_id)) == false ) {
                const friend = this.getFriend(Number(user.user_id));

                if( friend != null ) {
                    user_name = this.getFriendName(friend);       
                }
            }
            
            title += `${user_name}${Number(user.user_id) == this.user_id ? ' (나)' : ''}, `;
        }

        return title.substr(0, title.length - 2);
    }

    /**
     * 파일 이름 리스트 반환
     */
    getFileNameList(files : t_event_file[]) : string {
        
        let last_msg : string = "메세지 없음";

        if( files == null || files.length < 1 ) return "메세지 없음";

        const file = files[0];
        
        const is_image = new RegExp("image").test(file.mimeType);

        // 사진 1장
        if( is_image == true && files.length == 1 ) {
            last_msg = `사진을 보냈습니다`;
        }

        // 사진 2장 이상
        if( is_image == true && files.length > 1 ) {
            last_msg = `사진 ${files.length}장을 보냈습니다`
        }

        // 파일 1개
        if( is_image == false && files.length == 1 ) {
            last_msg = `파일을 보냈습니다`;
        }

        // 파일 2개 이상
        if( is_image == false && files.length > 1 ) {
            last_msg = `파일 ${files.length}개를 보냈습니다`;
        }

        return last_msg.trim();
    }

    /**
     * 채팅방 첫번째 이미지 에러
     */
    firstImageError(event) : void {
        $(event.target).parent().find('p.first-img').addClass('no-img');
    }

    /**
     * 채팅방 두번째 이미지에러
     */
    secondImageError(event) : void {
        $(event.target).parent().find('p.second-img').addClass('no-img');
    }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        if( this.connected_channel == null ) return;
        
        event.dataTransfer.dropEffect = 'copy';
        // this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        // DO NOTHING, 나중에 효과 생길때 사용
        // this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        if( this.connected_channel == null ) return;

        // this.file_drag = false;
        this.addFileEvent(event.dataTransfer.files);
    }

    addFile(event) : void {
        if( this.connected_channel == null ) return;

        const files : File[] = event.target.files;
        this.addFileEvent(files);
    }

    /**
     * 파일 추가
     */
    async addFileEvent(files : File[]) : Promise<void> {
        const send_files : File[] = [];

        if( files.length < 1 ) { return; }

        if( files.length > 4 ) {
            this.hodu_show_dialog("alert", "파일은 한번에 최대 4개까지만 업로드 가능합니다", ['확인']);
            $('#addFile').val(""); 
            return;
        }

        await this.hodu_show_indicator();
        for( let file of files ) {

            // 파일 용량 체크
            if( file.size > this.DEFAULT_FILE_MAX_SIZE ) {
                await this.hodu_hide_indicator();
                this.hodu_show_dialog("alert", `${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`, ['확인']);
                $('#addFile').val("");
                return;
            }

            // 확장자가 없는 파일
            if( file.name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#addFile').val("");
                await this.hodu_hide_indicator();
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(file.name.substring(file.name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ file.name.substring(file.name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#addFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 이미지인지 파일인지 구분해서 이미지라면 리사이즈 한다
            if( new RegExp('image').test(file.type) == true ) {

                // 이미지 리사이즈
                try {
                    await this.fileReaderPromise(file)
                        .then(async(pe_fr : any) => {

                            if( pe_fr.target == null || pe_fr.target.result == null ){
                                return;
                            }
                            
                            let base64url : string = "";

                            if( pe_fr.target.result instanceof ArrayBuffer ){
                                const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                                const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                                base64url = decodeURIComponent(url);
                            } else {
                                base64url = pe_fr.target.result;
                            }

                            // 이미지 리사이즈
                            const blob : Blob = await this.hodu_image_resize(base64url);
                            
                            // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                            let resize_file : File = file;
                            try{
                                resize_file = await this.hodu_blob_to_file(blob, file.name);
                            }catch(e){
                                try {
                                    (blob as any).lastModifiedDate = new Date();
                                    (blob as any).name = file.name;
                                    resize_file = (blob as any);
                                } catch(e) {
                                    this.hodu_error_process(e, false, false, true);
                                }
                            }

                            send_files.push(resize_file);
                        });
                        
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    await this.hodu_hide_indicator();
                    this.hodu_show_dialog("cancel", "파일 업로드 중 오류 발생", ['확인']);
                    return;
                }
            }

            else {
                send_files.push(file);
            }
        }
        await this.hodu_hide_indicator();

        // 채팅방 파일 업로드
        let event_files : t_event_file[] = [];

        try {
            const form_data = new FormData();
            
            for( const file of send_files ) { form_data.append("req_files", file); }

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/upload`, API_METHOD.POST, form_data);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || 
                !response.data.data || !response.data.data.files || response.data.data.files.length < 1 ) {
                throw new Error("파일 업로드 실패");
            }

            event_files = event_files.concat(response.data.data.files);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일 업로드 중 오류 발생", ['확인']);
            $('#addFile').val("");
            return;
        }

        // input에서 파일 값을 지운다
        $('#addFile').val("");

        // 일반 파일과 이미지가 겹치지 않도록 한다
        const img : t_event_file[] = event_files.filter(item => new RegExp('image').test(item.mimeType) == true);
        const file : t_event_file[] = event_files.filter(item => new RegExp('image').test(item.mimeType) == false);

        // 이미지 채팅 추가
        if( img.length > 0 ) {
            this.$emit("setIsSendMyMessage", true);
            this.send(JSON.stringify({
                type : "CHANNEL",
                sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG,
                data : {
                    msg : "",
                    files : img
                }
            }));
        }

        // 파일 채팅 추가
        if( file.length > 0 ) {
            this.$emit("setIsSendMyMessage", true);
            this.send(JSON.stringify({
                type : "CHANNEL",
                sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG,
                data : {
                    msg : "",
                    files : file
                }
            }));
        }
        
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File | Blob) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지 다이얼로그
     */
    imageOpen(files : t_event_file[], file : t_event_file) : void {
        const image_index : number = files.indexOf(file);

        if( image_index == -1 ){
            return;
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : files,
            "type" : "CHAT"
        });
        this.doSetShowEventImagePreview(true);
    }

    /**
     * 파일 다운로드
     */
    download(file : t_event_file) : void {
        this.hodu_download(`/chat_data/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 추가 패널 ON / OFF
     */
    extraPanelOnOff() : void {
        this.is_extra_panel_open = !this.is_extra_panel_open;
        this.is_chatting_room_background_change_open = false; 
    }

    /**
     * 채팅방으로 이동
     */
    openChattingRoom(channel : chat_interface.I_CHANNEL_INFO) : void {
        // 채널 JOIN & WebSocket 
        this.$emit('join', channel);
    }

    /**
     * 채팅방 리스트로 이동
     */
    moveChattingRoomList() : void {
        this.$emit('movePrevPageAndChattingRoomExit');
    }

    /**
     * shift + event 개행
     * enter         댓글 등록
     */
    keyUpTextArea(event) : void {
        if( event.keyCode == 16 ) { this.isShift = false; }
        if( event.keyCode == 17 ) { this.isCtrl = false; } 
    }

    /**
     * shift + event 개행
     * enter         댓글 등록
     */
    keyDownTextArea(event) : boolean {
        if( event.keyCode == 16 ) { this.isShift = true; }
        if( event.keyCode == 17 ) { this.isCtrl = true; } 

        if( event.keyCode == 13 ) {
            
            if( this.isCtrl == true ) {
                const val = this.chatting_message;
                const start = event.target.selectionStart;
                this.chatting_message = val.slice(0, start) + "\n" + val.slice(event.target.selectionEnd);
                
                this.$nextTick(() => {
                    event.target.selectionEnd = start + 1;
                    event.target.selectionStart = start + 1;
                });

                return true;
            }

            if( this.isShift == true ) {
                return true;
            }

            event.preventDefault();
            this.sendMessage();
            return false; 

        }

        return true;
    }

    /**
     * 메시지 보내기
     */
    sendMessage() : void {
        if( new RegExp(/(<([^>]+)>)/).test(this.chatting_message) == true ) {
            this.hodu_show_dialog('alert', '태그는 입력 할 수 없습니다\n예) <tag></tag>', ['확인']);
            return;
        }

        if( this.chatting_message.replace(/(<([^>]+)>)/ig, "").trim().length < 1 ) {
            return;
        }

        this.$emit("setIsSendMyMessage", true);

        this.send(JSON.stringify({
            type : 'CHANNEL',
            sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG,
            data : { msg : this.chatting_message.replace(/(<([^>]+)>)/ig, "").trim() }
        }));

        this.$nextTick(() => this.chatting_message = "");
    }

    /**
     * 채팅 삭제
     */
    deleteChatting(chat : chat_interface.I_CHAT_MESSAGE) : void {
        if( this.user_id != Number(chat.user_id) ) { return; }

        this.hodu_show_dialog('cancel', "채팅을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.send(JSON.stringify({
                    type : 'CHANNEL',
                    sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG_DEL,
                    data : chat.idx
                }));
            }
        ]);
    }
    
    /**
     * 유저명 반환
     */
    getUserName(user_id : string) : string {
        
        if( isNaN(Number(user_id)) == false ) {
            const friend = this.getFriend(Number(user_id));

            if( friend != null ) {
                return this.getFriendName(friend);       
            }
        }

        if( this.connected_channel != null && this.connected_channel.users != null ) {
            
            for( const user of this.connected_channel.users ) {
                if( user_id == user.user_id ) { 
                    return user.user_name; 
                }
            }

        }

        return "알수없음";
    }

    /**
     * Date로 시간 text 만들기
     */
    getTimeTextByDate(reply_date : Date) : string {
        const hour : string = `0${reply_date.getHours() == 0 ? '12' : reply_date.getHours() > 12 ? reply_date.getHours() - 12 : reply_date.getHours() }`.slice(-2);
        const min  : string = `0${reply_date.getMinutes()}`.slice(-2);
        const amPm : string = `${reply_date.getHours() < 12 ? '오전' : '오후' }`;
        return `${amPm} ${hour}:${min}`;
    }

    /**
     * 채팅 안 읽은 수 반환
     */
    getReadCount(chat : chat_interface.I_CHAT_MESSAGE) : string {
        const idx = chat.idx;

        if( this.connected_channel == null || this.connected_channel.users == null ) { return ''; }

        const user_count = this.connected_channel.users.filter(user => user.leaved_flag == false).length;

        let unread_count = user_count;

        for( const key in this.connected_channel.users_read ) {
            if( Number(idx) <= this.connected_channel.users_read[`${key}`] ) { unread_count--; }
        }

        return unread_count > 0 ? `${unread_count}` : '';
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('p.mPic').addClass('no-img');
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        $(event.target).parent().find('a').addClass('no-img');
    }

    /**
     * 채팅방 설정 모달
     */
    showChattingRoomInfoModal() : void {
        if( this.doSetChattingRoomInfoModalInfo == null ) { return; }

        this.doSetChattingRoomInfoModalInfo({
            show_modal : true
        });
    }

    /**
     * 검색창 열기
     */
    search() : void {
        this.is_search_div_open = true;
        this.$nextTick(() => $('#chatting_room_search').focus());
    }

    /**
     * 리사이즈 감지 
     */
    handleResize() : void {
        // @ts-ignore
        $('.chatroomDiv.scroll').mCustomScrollbar('destroy');
        this.$nextTick(() => this.setScroll());
        this.setChattingRoomScroll(null, true);
    }

}
</script>

<style>
    .chatroom .chatsUl li a.hodu-link { word-break: break-all; }
    .chatroom .chatsUl li.me a.hodu-link { color : #16da89 !important; }
    .chatroom .chatsUl li.from a.hodu-link { color : #477fff !important; }
</style>

<style scoped>
    .chatroomPanel.on .typingDiv { top:-100px; }

    .typingDiv { display: none; overflow: hidden; padding:0 25px; box-sizing:border-box; position: absolute;width: 100%; height: 40px; top:-40px;left:0; }
    .typingDiv .bg { display: inline-block; border-radius: 20px; padding:0 13px; height: 23px; text-align: center; line-height:  20px; background:#fff; box-sizing:border-box; border:1px solid #e7e9ea;}
    .typingDiv .bg.meTyping { background:#477fff; float: right; border-color:#477fff;}
    .typingDiv .bg.otherTyping {  }
    .typingDiv .bg span { width:6px; height: 6px; margin:0 2px;display: inline-block; border-radius: 50%; background:#35405A; }
    .typingDiv .bg.meTyping span { background:#fff; }



    .checkDiv { position: absolute;left:20px; top:17px; }
    input[type='checkbox']:not(old) + label { padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;}
    input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;}

    /* 왼쪽 햄버거 버튼 클릭 시 */
    #section_ce.left_area_close .content { padding-left: 61px !important; box-sizing: border-box; }

    /* 수정 모드 */
    .section_ce_fix.edit .titleBox .btns .del,.section_ce_fix.edit .titleBox .btns .cancel { display:inline-block;  }
    .section_ce_fix.edit .titleBox .btns .edit, .section_ce_fix.edit .titleBox .btns .schBtn { display:none; }
    .section_ce_fix.edit .chatroomUl .checkDiv { display:block; }
    .section_ce_fix.edit .titleBox h4 { display:none; }
    .section_ce_fix.edit .editModeDiv { display: inline-block; }
    .section_ce_fix.edit .chatroomUl .leaveDiv { opacity:1; }
    .section_ce_fix.edit .addBtn { display: none; }
    .section_ce_fix.edit .chatroomUl li { padding: 10px 130px 10px 150px; }
    .section_ce_fix.edit .chatroomUl li .goChatroom { display:none;}
    .section_ce_fix.edit .chatroomUl li .peepsDiv { left: 50px; }
    .section_ce_fix.edit .chatroomUl li .unread {right: 65px}
    .section_ce_fix.edit .chatroomUl li .sent { right: 67px; }


    /* 오른쪽 닫혔을때 (친구, 그룹 버튼 있는 부분) */
    .section_ce_fix.rightDivClosed .rightDiv { transition:0.1s; width:70px; overflow: hidden; }
    .section_ce_fix.rightDivClosed .rightDiv .rightDivDescript {display:block; }
    .section_ce_fix.rightDivClosed .rightDiv .btns, .section_ce_fix.rightDivClosed .rightDiv .schPeepsDiv, .section_ce_fix.rightDivClosed .rightDiv .checkDiv { display:none; }
    .section_ce_fix.rightDivClosed .mateUl { overflow:hidden; }
    .section_ce_fix.rightDivClosed .mateUl li .dot, .section_ce_fix.rightDivClosed .mateUl li .dot {left: 14px !important;}
    .section_ce_fix.rightDivClosed .mateUl li { border-bottom: 0 none;height: 52px !important; }
    .section_ce_fix.rightDivClosed .magUl .mPic {left: 18px !important;}
    .section_ce_fix.rightDivClosed .leftDiv { padding-right:70px; }
    .section_ce_fix.rightDivClosed .title_box .mag_btn { margin-right:-5px; }
    .section_ce_fix.rightDivClosed .rightDiv .whenClosed { display:block; }
    .section_ce_fix.rightDivClosed .rightDiv .rightSettingDiv { display: none; }
    .rightSettingDiv .chatSetUl li p span { opacity: 0.7; width: auto; height: auto; background: none; }
    .rightSettingDiv .switch { width: 42px; margin-right: 17px;height: 20px;; }
    .rightSettingDiv .slider:before { height: 12px;width: 12px;left: 5px;bottom: 4px; }
    .rightSettingDiv input:checked+.slider:before { transform: translateX(20px);}

    /* chatroomUl 닫혔을때, chatroom 오픈 */
    .section_ce_fix.leftDivClosed .leftDiv { /* padding-left: 75px; */ padding-top: 60px; }
    /* .section_ce_fix.leftDivClosed .leftDiv .titleBox { padding-left: 100px; } */
    .section_ce_fix.leftDivClosed .chatroomDiv  { width: 75px;position: absolute;left: 0;top: 0;height: 100%;box-sizing: border-box;padding-top: 60px; }
    .section_ce_fix.leftDivClosed .chatroomUl { width: 75px; overflow: hidden; border-right: 1px solid #e7e9ea; }
    .section_ce_fix.leftDivClosed .chatroomUl li .peepsDiv { left: 0; width: 75px; height: 75px; box-sizing: border-box; padding: 15px }
    .section_ce_fix.leftDivClosed .chatroomUl li .peepsDiv.two p { width: 35px;height: 35px; }
    .section_ce_fix.leftDivClosed .chatroomUl li { height: 75px; }
    .section_ce_fix.leftDivClosed .chatroomUl li .peepsDiv.two p:last-child span { line-height: 30px; font-size: 13px; }
    .section_ce_fix.leftDivClosed .leftDiv .titleBox .chatroomMenu { display:block; }
    .section_ce_fix.leftDivClosed .chatroom { display:block; }
    .section_ce_fix.leftDivClosed .leftDiv .searchDiv {box-sizing: border-box;padding-left: 75px;z-index: 1; }
    .section_ce_fix.leftDivClosed .useWhenClosed { z-index: 1; position: absolute; top:0;left:0;width:100%; height: 60px;box-sizing: border-box;padding-right: 400px; }
    .btns2 { display:none;float:right; }
    .btns2 input { margin-left:15px; margin-top:11px; height: 35px; line-height: 33px; }
    .btns2 .schBtn { margin-top:12px; margin-right:-8px; margin-left: 10px; width: 35px;height: 35px; }
    .btns2.editMode .input_btn { display: inline-block !important;  }
    .btns2.editMode .edit, .btns2.editMode .schBtn { display: none !important;  }
    .section_ce_fix.leftDivClosed .useWhenClosed .titleBox > .btns { display: none; }
    .section_ce_fix.leftDivClosed .useWhenClosed .btns2 { display:block; }
    .section_ce_fix.leftDivClosed .useWhenClosed .btns2 input { display: none; float: right; }
    .section_ce_fix.leftDivClosed .useWhenClosed .btns2 .edit, .section_ce_fix.leftDivClosed .useWhenClosed .btns2 .schBtn { display: inline-block; }
    .section_ce_fix.leftDivClosed .addBtn { display:none !important; }
    .section_ce_fix.leftDivClosed .chatroomUl { position: relative; }
    .section_ce_fix.leftDivClosed .chatroomUl li { opacity:0.6; width: 75px; padding-right:0;padding-left:0; }
    .section_ce_fix.leftDivClosed .chatroomUl li:hover { opacity:1; }
    .section_ce_fix.leftDivClosed .chatroomUl li.on { opacity:1; }
    .section_ce_fix.leftDivClosed .chatroomUl li.on .goChatroom { display:none; }
    .section_ce_fix.leftDivClosed .chatroomUl li .chType { display:none; }
    .section_ce_fix.leftDivClosed .chName {display: none;}
    .section_ce_fix.leftDivClosed .sent {display: none;}
    .section_ce_fix.leftDivClosed .lastMsg {display: none;}
    .section_ce_fix.leftDivClosed .chatroomUl li .unread { z-index: 1; right:auto; top:10px; right: 10px; font-size: 11px; padding: 0 5px; height: 16px; line-height: 14px; width: auto !important; min-width: 20px !important; }
    .section_ce_fix.leftDivClosed .titleBox .beforeClosed { display:none; }
    .section_ce_fix.leftDivClosed .titleBox .afterClosed { display:block; width: 100%; overflow: hidden; text-overflow: ellipsis; }
    .section_ce_fix.leftDivClosed .chatroom { display:block; }
    .section_ce_fix.leftDivClosed .leaveDiv { display: none; }
    .section_ce_fix.leftDivClosed .leftDiv .titleBox .btns input.edit { display:none !important; }
    .section_ce_fix.leftDivClosed .leftDiv .titleBox .btns input.del { display:none !important; }

    /* 처음 만들었을 때 */
    .section_ce_fix.leftDivClosed.oneCt .chatsUlDiv { display: none }
    .section_ce_fix.leftDivClosed.oneCt .noChatYet { display: block; }
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting li:nth-child(1), 
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting li:nth-child(2), 
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting li:nth-child(3), 
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting li:nth-child(4) { height: 0; border-bottom: 0; }
    .section_ce_fix.leftDivClosed.oneCt .afterClosed { font-size:0; }
    .section_ce_fix.leftDivClosed.oneCt .afterClosed:after { content:'김연지 (나)'; font-size: 13px !important; display: inline-block;  }
    .section_ce_fix.leftDivClosed.oneCt .schPeepsDiv { display: none; }
    .section_ce_fix.leftDivClosed.oneCt .mateList.chatting .editDiv { display:none; }
    
    /* 채팅자 보이기 */
    #section_ce .section_ce_fix.leftDivClosed .mateList { display:none; }
    #section_ce .section_ce_fix.leftDivClosed .rightDiv.mate .mateList.chatting { display:block }
    #section_ce .section_ce_fix .rightDiv.mate .mateList.chatting { display: none }
    #section_ce .section_ce_fix.leftDivClosed .rightDiv.img .imgList { display:block }
    #section_ce .section_ce_fix.leftDivClosed .rightDiv.file .fileList { display:block }
    .section_ce_fix.leftDivClosed .chatroomBtns { display:block }
    .section_ce_fix.leftDivClosed .editDiv { display:block }
    .section_ce_fix.leftDivClosed .rightDiv .btns { display:none !important; }
    .section_ce_fix.leftDivClosed .rightDiv .chatroomTitle { display:block }
    
    .section_ce_fix.leftDivClosed .rightDiv.img .imgFileSchDiv { display:block }
    .section_ce_fix.leftDivClosed .rightDiv.file .imgFileSchDiv { display:block }

    .section_ce_fix.leftDivClosed .rightDiv.img .editDiv, .section_ce_fix.leftDivClosed .rightDiv.file .editDiv { display:block }

    .section_ce_fix.leftDivClosed .rightDiv.img .schPeepsDiv { display:none }
    .section_ce_fix.leftDivClosed .rightDiv.file .schPeepsDiv { display:none }

    .section_ce_fix.leftDivClosed .rightDiv.mate .inviteMate { display:block }
    

    /* 오른쪽 닫힐때 */


    /* 방장일때 */
    #section_ce .section_ce_fix.leftDivClosed.rm .editDiv .editBtn { display:block; }

    #section_ce .section_ce_fix.leftDivClosed.rm { }
    
    #section_ce .section_ce_fix.leftDivClosed .chatroomTitle .title.rm { display:none; }
    #section_ce .section_ce_fix.leftDivClosed.rm .chatroomTitle .title { display:none; } 
    #section_ce .section_ce_fix.leftDivClosed.rm .chatroomTitle .title.rm { display:inline-block; }
    
    /* chatroom 오픈, rightDiv 오픈된 채팅방에 디테일 보임 */
    
    
    .chatroomBtns, .inviteMate, .imgFileSchDiv, .fileList, .imgList, .delSelectedBtn { display:none }
    .editDiv { display:none }
    .chatroomTitle {display:none;}

    #section_ce .section_ce_fix.leftDivClosed .magUl > li { display:none; }



    /* leftDivClosed, rightDivClosed 동시에 = 채팅방이 열렸을때 */
    .section_ce_fix.leftDivClosed.rightDivClosed .useWhenClosed { padding-right:70px }


    .content, .forRel { width:100%; height: 100%; }

    /* 오른쪽 닫혔을때 사용되는 blocking div */
    .whenClosed { display:none; position: absolute; z-index: 100; top:0; left:0; width:100%; height: 100%; opacity:0; background:#35405A; transition:0.2s;}
    .whenClosed:hover { opacity:0.05; cursor:pointer }

    /* 왼쪽 닫혔을때 사용되는 blocking div */
    .leftDiv .whenClosed { top:60px;}

    /* .title_box */
    .title_box .mag_btn { transition:0.1s; width: 36px; height: 36px; margin-top:3px; font-size:0; border:1px solid #e7e9ea; font-size:0; box-sizing: border-box; border-radius: 50%; background:  url(../../assets/images/contents/ic_mag.png) no-repeat 6px center; background-size: 23px; }
    .title_box .mag_btn:hover {background-color:#f1f3f5}
    .title_box .mag_btn.on { background: #477fff url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-color:#477fff }

    /* .leftDiv */
    .leftDiv > div > .forRel { height: auto; }
    .leftDiv { position: relative; width:100%; height: 100%; box-sizing: border-box; padding-right: 400px; transition: 0.1s;}
    .leftDiv .titleBox { transition:0.1s;position:relative; padding: 0 30px;overflow: hidden; line-height: 60px; height: 60px; box-sizing: border-box; border-bottom:1px solid #e7e9ea; }
    .leftDiv .titleBox h4 { transition:0.1s; float: left; font-size: 14px; }
    .leftDiv .titleBox h4 span { color:#477fff; font-size:12px }
    .leftDiv .titleBox .afterClosed { display:none; }
    .leftDiv .titleBox .afterClosed span { padding-left: 10px; }
    .leftDiv .titleBox .chatroomMenu { display:none; }
    .leftDiv .titleBox .btns { float: right; line-height: 58px;}
    .leftDiv .titleBox .btns input { float: right; height: 35px; line-height: 33px; margin-left: 10px; font-size: 13px !important; margin-top:11px; height: 35px; line-height: 33px; }
    .leftDiv .titleBox .btns .schBtn { width: 35px; font-size: 0 !important; margin-top:12px;margin-right: -8px; }
    .titleBox .btns .del,.titleBox .btns .cancel { display:none; }
    .leftDiv .searchDiv { z-index: 1000; }
    .leftDiv .searchDiv input[type="text"] { padding-left: 60px; padding-right: 70px; font-size: 15px; height: 60px; line-height: 62px; margin-top:-1px; background: url(../../assets/images/contents/ic_search_on.png) 25px center no-repeat; }
    .leftDiv .searchDiv input[type="text"]:hover { background-color:#f1f3f5; }
    .leftDiv .searchDiv input[type="text"]:focus { background-color:#fff; }
    .leftDiv .searchDiv .closeBtn { width: 35px; height: 35px; margin-top:12px;margin-right: 22px; }
    .leftDiv .editModeDiv { display:none; }
    .leftDiv .editModeDiv .checkDiv  { top: 18px;left: 25px; }
    .leftDiv .editModeDiv .checkDiv input[type='checkbox']:not(old) + label { width: 18px; height:18px; background-size: 21px !important; }
    .leftDiv .editModeDiv .checkDiv input[type='checkbox']:not(old) + label span { color:#7c88a2; }
    .leftDiv .editModeDiv .checkDiv input[type='checkbox']:not(old):checked + label span { color:#232848; }
    .leftDiv .editModeDiv .checkDiv span { position: absolute; top:-19px; left: 40px; height: 60px; line-height: 60px; width: 70px; display: inline-block; font-size: 14px; font-weight: bold; }
    .leftDiv .editModeDiv .counting { padding-left: 110px; font-weight: bold;  font-size:  14px; }
    .leftDiv .editModeDiv .counting span { color:#477fff; }
    
    
    
    /* .leftDiv .chatroomMenu  */
    .chatroomMenu {z-index: 100000; transition:0.1s; position: absolute; left: 0; top: 0; height: 60px; line-height: 60px; width: 76px; font-size: 0; border-right:1px solid #e7e9ea; box-sizing: border-box; background:  url(../../assets/images/contents/ic_loc.png) no-repeat center center; }  
    .chatroomMenu:hover{ background-color:#f1f3f5; }

    /* .chatroomUl */
    .chatroomUl li { transition:0.2s; position: relative; height: 104px; border-bottom:1px solid #f1f3f5; padding: 12px 100px 12px 120px; box-sizing: border-box; }
    .chatroomUl { padding-bottom: 100px; }
    .chatroomUl li:hover { background: #f7f9fb; }
    .chatroomUl li .peepsDiv { transition:0.1s; overflow: hidden; position: absolute; left:7px; top:0; height: 104px; width: 104px; box-sizing: border-box; padding: 20px; }
    .chatroomUl li .peepsDiv p { float: left; font-size: 0; border: 1px solid #e7e9ea; box-sizing: border-box; border-radius: 50%; background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .chatroomUl li .peepsDiv.one p { width: 100%; height: 100%; border-radius: 50%; }
    .chatroomUl li .peepsDiv.two p { width: 49px; height: 49px; }
    .chatroomUl li .peepsDiv.two p:last-child { position: absolute; bottom:13px;right:13px; border:3px solid #fff;width: 52px; height: 52px; }
    .chatroomUl li .peepsDiv.two p:last-child span { text-indent:-2px; position: absolute; display:block; background:rgba(0,0,0,0.3); width:100%; height: 100%; border-radius: 50%; font-size: 14px; font-weight: bold; color:#fff; line-height: 46px; text-align:center; text-shadow: 0 2px 2px rgb(0,0,0); }
    .chatroomUl li.specList .peepsDiv.one p { border-radius: 10px;  }
    /* .chatroomUl li.specList .peepsDiv.one { padding: 20px 20px } */
    .chatroomUl li .chName { font-size: 14px; font-weight: bold; height: 32px; line-height: 34px;overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .chatroomUl li .chType { display: inline-block; width:40px; font-size: 11px; text-align: center; box-sizing:border-box;border-radius: 2px; height: 17px; line-height: 17px; font-weight: bold; background:#f1f3f5; margin-right: 10px;}
    .chatroomUl li:hover .chType { background:#fff; }
    .chatroomUl li .chType.grp, .chatroomUl li .chType.team { color:#fff; }
    .chatroomUl li .unread { transition:0.1s; position: absolute; right: 30px; top:20px; box-sizing: border-box; min-width: 40px; text-align: center; padding: 0 10px; border-radius: 15px; height: 17px; font-weight: bold; color:#fff; line-height: 15px; background: #ff6363;border: 1px solid #ff2929; }
    .chatroomUl li .lastMsg { line-height: 18px; display: block; margin-top:-1px; height: 38px; overflow: hidden; text-overflow: ellipsis;}
    .chatroomUl li .sent { position:absolute; right: 32px; bottom:18px; line-height: 17px;text-align: right; font-weight: bold; opacity: 0.5;}
    .chatroomUl li .goChatroom { z-index: 10; position:absolute; left:0 ;top:0; width:100%; height:100%; font-size: 0;  }
    .chatroomUl li:after { content:''; display:block; width: 3px; height: 100%; position: absolute; left:0; top:0; box-sizing: border-box; }
    .chatroomUl li:hover:after { background: #e7e9ea; }
    .chatroomUl li.on:after { background: #477fff;  }
    .edit .chatroomUl li.on:hover { background: #fff; cursor: default }
    .edit .chatroomUl li.on:after { display: none;  }
    .chatroomUl li.on { background:#fff !important; }
    .chatroomUl li.on:hover .goChatroom { cursor: default !important; }
    .chatroomUl .checkDiv { display:none; top: 36px; z-index: 100; left: 25px; }
    .chatroomUl .checkDiv input[type='checkbox']:not(old) + label { width: 19px; height: 19px;background-size: 22px !important; }
    .chatroomUl li:hover .unread {right: 65px; }
    /* .chatroomUl li:hover .sent { right: 67px; } */

    .chatroomUl .leaveDiv { z-index: 100; opacity:0; transition:0.1s; position: absolute; right:0; top:0; height: 100%; line-height: 100px; width: 60px; text-align: right; background: url(../../assets/images/contents/img_shdw_leaveDiv.png) repeat-y top right; background-size: 60px; }
    .chatroomUl li:hover .leaveDiv { opacity: 1; }
    .chatroomUl .leaveDiv input { transition:0.1s; width: 60px; height: 100%; margin-right: 0; background: url(../../assets/images/contents/ic_lcr.png) no-repeat 20px center; font-size:0; }
    .chatroomUl .leaveDiv input:hover { background-image: url(../../assets/images/contents/ic_lcr_on.png); background-position: 25px center; }

    /* 채팅방 없을 시 */
    .noChatRoomDiv.on { display: block; }
    .noChatRoomDiv { display:none; width: 100%; box-sizing: border-box; padding: 30px 40px; }
    .noChatRoom { background:#f1f3f5; text-align: center; border-radius: 5px; padding-top:10px; }
    .noChatRoom span { font-size:0; display:inline-block; width: 100px; height: 90px; background: url('../../assets/images/contents/img_noChatRoom.png') no-repeat center center; background-size: 80px; }
    .noChatRoom p { font-size: 15px; font-weight: bold; color: #93a0b1; display: block; height: 65px; line-height: 25px; }

    .chatroomDiv .noResult.on { display:block; }
    .chatroomDiv .noResult { display:none; position: static; padding: 15px 30px }
    .chatroomDiv .noResult p { background:#f1f3f5; color: #93a0b1; line-height: 65px; height: 65px; }

    /* .chatroom */
    /* .chatroom은 mCustomScrollbar 넣으면서 height값은 변경되어야함 */
    .chatroom { background:#f7f9fb; border-left:1px solid #e7e9ea; position:relative; display:none; width:100%; height:100%; padding-bottom: 110px;box-sizing: border-box; }
    .chatroom .noChatYet.on { display:block; }
    .chatroom .noChatYet { display:none; width:100%; text-align: center; padding-top:25px; }
    .chatroom .noChatYet p { width: auto; display: inline-block; padding:0 50px; border-radius: 30px; height: 50px; line-height: 50px; font-size: 14px; background: #fff; border:1px solid #e7e9ea; text-align: center; font-weight: bold;color: #93a0b1;  }
    .chatroomGrp { padding: 30px 25px 35px; width: 100%; box-sizing: border-box; }

    /* chatsUlDiv */
    .chatsUlDiv .till.when span.txt { color:#7c88a2 }
    .chatsUlDiv .till { position: relative; width:100%;text-align:center; padding: 30px 40px 20px; box-sizing: border-box;  }
    .chatsUlDiv .till span.txt { position: relative; z-index: 10; height: 30px; line-height: 30px; font-weight: bold; background:#fff; border-radius: 20px; padding: 0 20px; border:1px solid #e7e9ea; display: inline-block; }
    .chatsUlDiv .till span.line { display:block; width: 95%; height: 1px; background:#000; opacity:0.07; position:absolute;left: 2.5%; border-radius: 50%; top:46px; z-index: 1; }
    .chatsUlDiv .msgDiv { position: relative; }
    .rm .chatroom.editMode .chatsUlDiv .from .msgDiv p { opacity:0.7; }
    .chatroom.editMode .chatsUlDiv .me .msgDiv p { opacity:0.7; }
    .chatsUlDiv .checkDiv { display: none; }
    #section_ce .chatroom.editMode  { background:#fff !important }
    .chatroom.editMode .checkDiv { display: block; }
    .rm .chatroom.editMode .from .msgDiv {box-sizing: border-box;padding-left: 40px;} 
    .chatroom.editMode > .scroll { border:2px solid #477fff; height: 744px; box-sizing: border-box; }
    .rm .chatroom.editMode .from .checkDiv { left: 0; top: 5px; }
    .chatroom li .checkDiv input[type='checkbox']:not(old) + label { width: 20px;height: 20px;border-radius: 50%;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../assets/images/contents/checked_fff.png) no-repeat center center;background-size: 22px !important;font-size: 0;}
    .chatroom li .checkDiv input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;border-color:#477fff;}
    .chatroom.editMode .me .msgDiv {box-sizing: border-box;padding-right: 40px;} 
    .chatroom.editMode .me .checkDiv { right: 0px; top: 5px; left: auto; }
     .chatroom.editMode li p.msg span { position: relative; overflow: hidden }
    .chatroom.editMode li p.msg span:after  { z-index: 100000; position: absolute; content: ''; left: 0;top:0; width:100%; height: 100%; opacity: 0.15 }
    .chatroom.editMode .chatsUlDiv li .msgDiv.on p { opacity:1; }
    /*.chatroom li .checkDiv label:after { z-index: 1000; position: absolute; content: ''; display: block; width: 500px; height: 41px; }*/
    .rm .chatroom li.from .checkDiv label:after  { left:0; top:0; }
    .chatroom li.me .checkDiv label:after  { right:0; top:0; }
    .chatroom.editMode .checkDiv { width: auto; height: auto; }
    
    /* .chatsUl */
    .chatsUl li { box-sizing: border-box; position: relative; width:100%; margin-bottom: 25px; }
  
    .chatsUl li .chatGrp { height: 20px; line-height: 15px; }
    .chatsUl li .time, .chatsUl li .mName { display:inline-block; font-weight: bold; opacity: 0.7; }
    .chatsUl li.from .mName { margin-right: 15px; }
    .chatsUl li .mPic { position: absolute; left:-3px; top:-2px; width: 38px; height: 38px; display: inline-block; font-size:0; border-radius: 50%; border: 1px solid #e7e9ea; background-color:#fff; background-size:cover; background-position: center center; background-repeat: no-repeat; }
    .chatsUl li .msg { margin-bottom:6px; }
    .chatsUl li .msg span { background: #fff; text-align: left; display:inline-block; max-width: 600px; width: auto; box-sizing:border-box; padding: 9px 13px; font-size: 13px;font-weight: bold; line-height: 19px; }
    .chatsUl li .time span { padding-right: 10px; }
    .chatsUl li .msg span a { padding:0 3px;text-decoration: underline; color:#477fff; }
    .chatsUl li.from { padding-left: 50px;  }
    .chatsUl li.from .msg.first span {  border-radius: 0 15px 15px 15px }
    .chatsUl li.from .msg.first.img span  { padding:0; border-radius: 0 15px 15px 15px }
    .chatsUl li.from .msg.first.img span a {  }
    .chatsUl li.from .msg span { border-radius: 15px 15px 15px 15px;border:1px solid #e7e9ea; }
    
    #section_ce .chatsUl li .msg.deleted span { background-color:#e7e9ea !important; border-color: #e7e9ea !important;; color: #7c88a2 !important; }
    #section_ce .chatsUl li .msg.deleted span em { display:inline-block; width: 17px; height: 17px; line-height: 17px; border:1px solid #fff; font-style: normal; text-align: center; border-radius: 50%; background:#f1f3f5; color:#7c88a2; font-size: 12px; margin-right:8px; float:left; } 

    .chatsUl li.me .msg span a { color:#fff;}
    .chatsUl li.me { text-align: right;}
    .chatsUl li.me .msg span { background:#477fff; color:#fff; }
    .chatsUl li.me .msg.first span { border-radius: 15px; border-radius: 15px 0 15px 15px; }
    .chatsUl li.me .msg span { border-radius: 15px; }
    .chatsUl li.me .msg.img.first span { padding:0;border-radius: 15px 0 15px 15px;  } 
    .chatsUl li.me .msg.img.first a { } 
    
    .chatsUl li .msg.img { height: auto; }
    .chatsUl li .msg.img span { padding:0; border-radius: 15px 15px 15px 15px; }
    .chatsUl li .msg.img span a { float:left; font-size:0; display: inline-block; width: 100px;  height: 100px; position: relative; border:1px solid #e7e9ea; background-size: cover; background-position: center center; background-repeat: no-repeat; background-image: url(../../assets/images/footer/g13.jpg); }
    .chatsUl li .msg.img span { position: relative; overflow: hidden; margin : 0 2px; }
    
    .chatsUl li .msg.img span a:after { transition:0.2s; opacity:0.15; content:''; display:block; position: absolute; left:0; top:0; width:100%; height: 100%; background:#35405A}
    .chatsUl li .msg.img span a:hover:after { opacity:0; }

    .chatsUl li .msg.file span  { text-align: center;  position: relative; overflow: hidden; width:150px; margin : 0 3px; }
    .chatsUl li .msg.file.vid span  {  position: relative; overflow: hidden; width:150px; }
    .chatsUl li .msg.file a { width: 123px;height: 80px; line-height: 80px;text-align: center; font-size: 0; box-sizing: border-box;border: 1px solid #e7e9ea;padding: 0;display: block; border-radius: 10px; font-size: 0; margin-bottom:7px; }
    .chatsUl li .msg.file.format a { transition:0.1s; font-size: 13px !important; color:#477fff; background:#fff; text-transform: uppercase; text-decoration: none; height: 60px; line-height: 60px; font-size:12px; }
    .bgc7 .chatsUl li.from .msg.file.format a { background-color:#f1f3f5; }
    .bgc7 .chatsUl li.from .msg.file.format a:hover {  background-color:#fff; }
    .chatsUl li .msg.file a:after { transition:0.2s; opacity:0.15; content:''; display:block; position: absolute; left:0; top:0; width:100%; height: 100%; }
    .chatsUl li .msg.file a:hover:after { opacity:0; }
    .chatsUl li .msg.file.vid a { height: 70px; border-radius: 10px; background-size: cover; background-position: center center; background-repeat: no-repeat; }
    .chatsUl li .msg.file.vid a { overflow: hidden; position:relative; top:-10px; left:-14px;margin-bottom:-3px; width: 150px; height: 90px; border-radius: 15px 15px 0 0;}
    .chatsUl li .msg.file.vid a:after { opacity:0.25; background:#000; }
    .chatsUl li .msg.file.vid a span { z-index: 10000; left:50%; margin-left: -22.5px; top:50%; margin-top:-22.5px; width: 45px; height: 45px; border-radius: 50%; position: absolute; border:3px solid #fff; display: block; background: url(../../assets/images/contents/ic_playBtn.png) no-repeat center center; background-size:30px; font-size:0; box-sizing: border-box; box-shadow: 0 3px 3px rgba(0,0,0,0.3); }
    .chatsUl li .msg.file.vid a:hover:after { opacity:0; }
    .chatsUl li .msg.file em { display:block; font-style: normal; width:100%; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }


    .chatroomPanel {z-index: 1000; transition:0.1s; background:#fff; left:0; width:100%; height: 110px; border-top:1px solid #e7e9ea; position: absolute; }

    .mainPanel { position: relative; padding: 15px 75px 30px 75px; width:100%; box-sizing: border-box; height: 100%; }
    .mainPanel textarea { font-weight: bold; transition:0.2s; width:100%;border: 2px solid #e7e9ea; line-height: 20px; appearance: none; resize: none; background: #f1f3f5; height:100%;border-radius: 5px; padding: 10px;box-sizing: border-box; font-size: 13px; }
    .mainPanel textarea:hover { background:#fff; }
    .mainPanel textarea:focus { border-color:#477fff; background:#fff; outline: #477fff; }
    .mainPanel input { transition:0.1s; position: absolute; }
    .mainPanel .addExtra { font-size: 0; width: 40px; height: 40px; border-radius: 50%; background-color:#fff; border: 1px solid #e7e9ea;left: 17px; top: 20px; background: url("../../assets/images/contents/ic_plus_new.png") no-repeat center center;  }
    .mainPanel .addExtra:hover { background-color:#f1f3f5; }
    .mainPanel .addExtra.on { background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-color:#ff6363 }
    .mainPanel .sendBtn { font-size: 0; width: 40px; height: 40px; border-radius: 50%; background-color:#fff; border: 1px solid #e7e9ea;right: 17px; top: 20px; background: #477fff url('../../assets/images/contents/ic_send_on.png') no-repeat center center;  }
    .mainPanel .sendBtn:hover { background-color: #3471fd }

    /* .chatroomPanel.on { padding-top: 60px; } */
    .chatroomPanel.on .extraPanel {display: block; transition:0.5s; opacity: 1; }
    .extraPanel { background-color: #fff; transition:0.5s; opacity: 0; display:none; position: absolute; top:-60px; padding: 0 20px; box-sizing: border-box; width:100%; border-bottom:1px solid #f1f3f5; border-top:1px solid #f1f3f5; height: 60px; }
    .extraPanel input[type="file"] { display:none; }
    .extraPanel input[type="button"], .extraPanel label {  transition:0.1s; cursor: pointer; display: inline-block; font-size:0; width: 35px; height: 35px; background-color:#fff;border-radius: 50%;  border: 1px solid #e7e9ea; float:left; margin-top:11px; margin-right:10px; }
    .extraPanel input[type="button"].changeChatroomBgBtn { transition:0.2s; float:right; margin-right:0; font-size:0; margin-top:11px; background-image:url('../../assets/images/contents/checked_fff.png'); background-position: center center; }
    /* .extraPanel input[type="button"].changeChatroomBgBtn:hover { opacity:1;border: 3px solid #b7c1cc; } */

    .extraPanel input[type="button"].addEmojis { background:  url(../../assets/images/contents/ic_emoji.png) no-repeat center center; font-size:0;background-size: 23px !important; }
    .extraPanel input[type="button"].addEmojis:hover  { background-color:#f1f3f5; }
    .extraPanel input[type="button"].addEmojis.on { background-image:url(../../assets/images/contents/ic_emoji_fff.png);background-color:#477fff; border-color:#477fff; }
    .extraPanel label.addFile {background:  url(../../assets/images/contents/ic_file_bk.png) no-repeat center center;background-size: 23px !important;  }
    .extraPanel label.addFile:hover { background-color:#f1f3f5; }

    .extraPanel label.addImg {background:  url(../../assets/images/contents/ic_img_bk.png) no-repeat center center;background-size: 23px !important;  }
    .extraPanel label.addImg:hover { background-color:#f1f3f5; }

    .chatroomBgDiv.on { display:block; }
    .chatroomBgDiv { display:none; overflow:hidden; position:absolute; left:0; top:0; width:100%; height: 100%; background: #fff; padding-left: 4px; }
    .chatroomBgDiv a { transition:0.1s; float: left; margin-top: 16px; margin-right: 10px; display: inline-block; width: 28px;border: 1px solid #fff; height: 28px; font-size: 0; background:red; border-radius: 50%; box-sizing: border-box;  }
    /* .chatroomBgDiv a.bgc0 {border: 2px solid #d6dce2;} */
    .chatroomBgDiv a.on { background-image:url('../../assets/images/contents/checked_fff.png'); background-position: center center; background-size: 22px; transform: scale(1.1) }
    /* .chatroomBgDiv a.bgc0.on { background-image:url('../../assets/images/contents/checked_gray.png'); background-position: center center; background-size: 22px; transform: scale(1.1) } */
    .chatroomBgDiv a:hover  { transform: scale(1.1) }
    .chatroomBgDiv input[type="button"].closeBtn { float:right; font-size: 0; margin-top:12px; margin-right: 4px;background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-color:#ff6363 }
    

    /* 색상 선택 */
    /* .bgc0 .extraPanel input[type="button"].changeChatroomBgBtn { background-color:#fff !important; background-image:url('../../assets/images/contents/checked_gray.png');} */
    .bgc0 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #FFE0E0 !important;}
    .bgc1 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #FFEDCF !important;}
    .bgc2 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #FFF4D5 !important;}
    .bgc3 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #FFD1E8 !important;}
    .bgc4 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #CCF0F4 !important;}
    .bgc5 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #D0F6E8 !important;}
    .bgc6 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #DBF1FF !important;}
    .bgc7 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #DAE5FF !important;}
    .bgc8 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #E1DDFF !important;}
    .bgc9 .extraPanel input[type="button"].changeChatroomBgBtn {background-color: #D7D9DE !important;}

    /* 채팅방 a 색상 */
    /* .bgc0 { background-color:#fff !important; } */
    .bgc0 {background-color: #FFE0E0 !important;}
    .bgc1 {background-color: #FFEDCF !important;}
    .bgc2 {background-color: #FFF4D5 !important;}
    .bgc3 {background-color: #FFD1E8 !important;}
    .bgc4 {background-color: #CCF0F4 !important;}
    .bgc5 {background-color: #D0F6E8 !important;}
    .bgc6 {background-color: #DBF1FF !important;}
    .bgc7 {background-color: #DAE5FF !important;}
    .bgc8 {background-color: #E1DDFF !important;}
    .bgc9 {background-color: #D7D9DE !important;}

    /* 채팅방 배경색상 변경 */
    .chatroom.bgc0 {background-color: #FFE0E0 !important;}
    .chatroom.bgc1 {background-color: #FFEDCF !important;}
    .chatroom.bgc2 {background-color: #FFF4D5 !important;}
    .chatroom.bgc3 {background-color: #FFD1E8 !important;}
    .chatroom.bgc4 {background-color: #CCF0F4 !important;}
    .chatroom.bgc5 {background-color: #D0F6E8 !important;}
    .chatroom.bgc6 {background-color: #DBF1FF !important;}
    .chatroom.bgc7 {background-color: #DAE5FF !important;}
    .chatroom.bgc8 {background-color: #E1DDFF !important;}
    .chatroom.bgc9 {background-color: #D7D9DE !important;}

    /* leftDivClosed 채팅창 체크박스 없음 */
    .section_ce_fix.leftDivClosed .magUl li.mateList.chatting  .mateUl li input.del { display:none }
    .section_ce_fix.leftDivClosed.rm .magUl li.mateList.chatting  .mateUl li input.del { display:block }
    .section_ce_fix.leftDivClosed.rm .magUl li.mateList.chatting.editMode  .mateUl li input.del , .section_ce_fix.leftDivClosed.rm .magUl li.mateList.chatting.editMode  .mateUl li input.leave { display:none }

    /* editMode */
    #section_ce .editMode .mateUl li, #section_ce .editMode .memberUl li {transition:0.1s;  overflow: hidden; position: relative; padding:0 40px 0 110px; line-height: 55px; height: 55px; width:100%; border-bottom:1px solid #f1f3f5;box-sizing: border-box; overflow:hidden; text-overflow: ellipsis; white-space: nowrap;}
    #section_ce .editMode .mateUl li .mPic, #section_ce .editMode .mateUl li .mPic, #section_ce .editMode .memberUl li .mPic, #section_ce .editMode .memberUl li .mPic { left: 60px !important; }
    #section_ce .editMode .mateUl li .dot, #section_ce .editMode .mateUl li .dot, #section_ce .editMode .memberUl li .dot, #section_ce .editMode .memberUl li .dot { left: 55px !important }
    /* #section_ce .magUl li.editMode  .mateUl li:hover .checkDiv input { opacity:0 !important } */

    /* .editDiv */
    #section_ce .editMode .checkDiv { display:block !important; opacity:1 !important; }
    #section_ce .editMode .cancelBtn, #section_ce .editMode .counting { display:inline-block !important; }
    #section_ce .editMode .total { display:none }


    /* 채팅방 추가 버튼 */
    .addBtn  { position:fixed;right:490px; bottom:40px; opacity: 1;z-index: 1000;transition: 0.1s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/ic_addChatroom_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 40px !important; }
    .addBtn:hover {background: #477fff url('../../assets/images/contents/ic_addChatroom_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 45px !important;}
    .addBtn.quick { right: 180px; }
    .addBtn.dntknow { right: 300px; }
    .section_ce_fix.rightDivClosed .addBtn { right: 160px; }
    
    /* 임의로 추가한 css 문제가 있으면 변경 */
    .error_detect_img { display : none; }

    .peepsDiv .img.no-img, .mPic.no-img { background-color : #f1f3f5 !important; background-image: url('../../assets/images/contents/im_photoB.gif') !important; }
    .specList .peepsDiv .img.no-img { background-image: url('../../assets/images/contents/groupImg.png') !important; }
    .chatroom > .scroll { overflow-y: auto; }
    
    .me:hover .msg .delBtn { display : block; }
    .msg .delBtn { display : none; width: 33px; height: 33px; border:1px solid #e6e5e5; background: #fff url(../../assets/images/contents/ic_x_new.png) no-repeat center center; font-size: 0; border-radius: 50%; top: 0; margin-right: 10px; padding: 0; background-size:20px 20px; float : right; }
    .msg .delBtn:hover { background-color: #f1f3f5; }

    .msgDiv .msg { overflow-x:hidden; overflow-y:auto; }
    .me .msg .msg-span { float: right; } 
    .msg .msg-span { float : left; word-break: break-all; }

    .me .msg .read_count { float: right; margin-right: 3px; margin-left: 0px; }
    .msg .read_count { width: auto !important; float : left; font-style: normal; font-weight: 700; color : #000; margin-left: 3px; }

    .chatsUl li .msg.img span a.no-img { background-image : url('../../assets/images/contents/groupImg.png') !important; background-color : #fff; }
    em.member_count { opacity: 0.5; font-style: normal; margin-left: 5px; }

    .section_ce_fix .chatroom, .msgDiv .msg .msg-span {
        -webkit-tap-highlight-color: transparent;
        -webkit-user-select: text !important; 
        /* Chrome/Safari */
        -moz-user-select: text !important; 
        /* Firefox */
        -ms-user-select: text !important;
    }

    li.from .msg-span::selection { background:#477fff; color : white; }
    li.me .msg-span::selection { background:#fff; color : black; }

    /* 추후 삭제 */

    /* 채팅 대표 이미지 */
    /* .rightSettingDiv .mainTitle .crImg { background-image: url(../../assets/images/footer/g7.jpg) }

    .imgUl > li:nth-child(1) a { background-image: url(../../assets/images/footer/g1.jpg); }
    .imgUl > li:nth-child(2) a { background-image: url(../../assets/images/footer/g2.jpg); }
    .imgUl > li:nth-child(3) a { background-image: url(../../assets/images/footer/g3.jpg); }
    .imgUl > li:nth-child(4) a { background-image: url(../../assets/images/footer/g4.jpg); }
    .imgUl > li:nth-child(5) a { background-image: url(../../assets/images/footer/g5.jpg); }
    .imgUl > li:nth-child(6) a { background-image: url(../../assets/images/footer/g6.jpg); }
    .imgUl > li:nth-child(7) a { background-image: url(../../assets/images/footer/g7.jpg); }
    .imgUl > li:nth-child(8) a { background-image: url(../../assets/images/footer/g8.jpg); }
    .imgUl > li:nth-child(9) a { background-image: url(../../assets/images/footer/g9.jpg); }

    .imgUl > li:nth-child(10) a { background-image: url(../../assets/images/footer/g10.jpg); }
    .imgUl > li:nth-child(11) a { background-image: url(../../assets/images/footer/g11.jpg); }
    .imgUl > li:nth-child(12) a { background-image: url(../../assets/images/footer/g12.jpg); }
    .imgUl > li:nth-child(13) a { background-image: url(../../assets/images/footer/g13.jpg); }
    .imgUl > li:nth-child(14) a { background-image: url(../../assets/images/footer/g15.jpg); }
    .imgUl > li:nth-child(15) a { background-image: url(../../assets/images/footer/g14.jpg); }
    .imgUl > li:nth-child(16) a { background-image: url(../../assets/images/footer/g16.jpg); }
    .imgUl > li:nth-child(17) a { background-image: url(../../assets/images/footer/f38.jpg); }
    .imgUl > li:nth-child(18) a { background-image: url(../../assets/images/footer/f33.jpg); }

    .imgUl > li:nth-child(19) a { background-image: url(../../assets/images/footer/f28.jpg); }
    .imgUl > li:nth-child(20) a { background-image: url(../../assets/images/footer/f25.jpg); }
    .imgUl > li:nth-child(21) a { background-image: url(../../assets/images/footer/f11.jpg); } */

    /* chatsUl li 사진 */
    /* .chatsUl li:nth-child(1) .mPic { background-image: url(../../assets/images/footer/f3.jpg); }
    .chatsUl li:nth-child(2) .mPic { background-image: url(../../assets/images/footer/f2.jpg); }
    .chatsUl li:nth-child(3) .mPic { background-image: url(../../assets/images/footer/f1.jpg); }

    .chatsUl li:nth-child(4) .mPic { background-image: url(../../assets/images/footer/f4.jpg); }
    .chatsUl li:nth-child(5) .mPic { background-image: url(../../assets/images/footer/f5.jpg); }
    .chatsUl li:nth-child(6) .mPic { background-image: url(../../assets/images/footer/f6.jpg); }

    .chatsUl li:nth-child(7) .mPic { background-image: url(../../assets/images/footer/f3.jpg); }
    .chatsUl li:nth-child(8) .mPic { background-image: url(../../assets/images/footer/f2.jpg); }
    .chatsUl li:nth-child(9) .mPic { background-image: url(../../assets/images/footer/f1.jpg); }


    .chatsUl li .msg.file.vid  a { background-image: url(../../assets/images/footer/g1.jpg); } */

    /* 채팅방 리스트 사진 */
    /* .chatroomUl li:nth-child(3) .peepsDiv .img { background-image: url(../../assets/images/footer/f9.jpg); }
    .chatroomUl li:nth-child(2) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f1.jpg); }
    .chatroomUl li:nth-child(2) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f2.jpg); }
    .chatroomUl li:nth-child(1) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f3.jpg); }
    .chatroomUl li:nth-child(1) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f7.jpg); }
    .chatroomUl li:nth-child(4) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f8.jpg); }
    .chatroomUl li:nth-child(4) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f12.jpg); }
    .chatroomUl li:nth-child(5) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f6.jpg); }
    .chatroomUl li:nth-child(5) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f14.jpg); }
    .chatroomUl li:nth-child(6) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f23.jpg); }
    .chatroomUl li:nth-child(7) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f11.jpg); }
    .chatroomUl li:nth-child(7) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f14.jpg); }
    .chatroomUl li:nth-child(8) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f4.jpg); }
    .chatroomUl li:nth-child(8) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f12.jpg); }
    .chatroomUl li:nth-child(9) .peepsDiv .img:nth-child(1) { background-image: url(../../assets/images/footer/f14.jpg); }
    .chatroomUl li:nth-child(9) .peepsDiv .img:nth-child(2) { background-image: url(../../assets/images/footer/f11.jpg); }
    .chatroomUl li.specList .peepsDiv.one p { background-image: url(../../assets/images/footer/g7.jpg); } */
</style>