/*!
 * FullCalendar v3.9.0
 * Docs & License: https://fullcalendar.io/
 * (c) 2018 Adam Shaw
 */
 
 /* 월별에서 일 호버시 배경색 변경  */
 /*
.fc-unthemed .fc-dayGridMonth-view tr > td.fc-sun:hover,
.fc-unthemed .fc-dayGridMonth-view tr > td.fc-mon:hover,
.fc-unthemed .fc-dayGridMonth-view tr > td.fc-tue:hover,
.fc-unthemed .fc-dayGridMonth-view tr > td.fc-wed:hover,
.fc-unthemed .fc-dayGridMonth-view tr > td.fc-thu:hover,
.fc-unthemed .fc-dayGridMonth-view tr > td.fc-fri:hover, 
.fc-unthemed .fc-dayGridMonth-view tr > td.fc-sat:hover {
    background: #f1f3f5;
}
*/

.fc-icons { z-index:5; position: absolute; height: 25px; overflow:hidden; }
.fc-icons li  { position: relative; margin-left:0px; display:inline-block; }
.fc-icons li:hover .descript { display:block; }
.fc-icons li .icon { display:inline-block; border-radius:50%;  width:23px !important; height:22px; background-size: 17px;background-position: center center;background-repeat: no-repeat;  }
.fc-icons li .descript {display:none;position:absolute; bottom: -40px;background: rgba(46, 56, 86,0.9); border-radius:5px; height:30px; line-height: 30px; text-align: center; width:70px; left:50%;margin-left:-35px; font-weight:bold; font-size:12px;color:#fff; }
.fc-icons .iconList .repeat .icon { background-image:url(../images/contents/ic_repeat_bk.png);  }
.fc-icons .iconList .alarm .icon { background-image:url(../images/contents/ic_alarm_bk.png);  }
.fc-icons .iconList .place .icon { background-image:url(../images/contents/ic_place_bk.png);  }
.fc-icons .iconList .tel .icon { background-image:url(../images/contents/ic_tel_bk.png);  }
.fc-icons .iconList .note .icon { background-image:url(../images/contents/ic_note_bk.png);  }
.fc-icons .iconList .memo .icon { background-image:url(../images/contents/ic_memo_bk.png);  }
.fc-icons .iconList .vote .icon { background-image:url(../images/contents/ic_vote_bk.png);  }
.fc-icons .iconList .attnd .icon { background-image:url(../images/contents/ic_attend_bk.png);  }
.fc-icons .iconList .file .icon { background-image:url(../images/contents/ic_file_bk.png);  }
.fc-icons .iconList .pic .icon { background-image:url(../images/contents/ic_img_bk.png);  }
.fc-icons .iconList .share .icon { background-image:url(../images/contents/ic_share_bk.png);  }
.fc-icons .iconList .invite .icon { background-image:url(../images/contents/ic_invite_bk.png);  }
.fc-icons .iconList .cmmnt .icon { background-image:url(../images/contents/ic_cmmnt_bk.png);  }

.fc-event span.schCl {width: 3px !important;height: 13px;position:relative;margin-top: 1px;display: inline-block;float: left;background: #477fff;border-radius: 3px !important;}
.fc-day-grid-event.fc-draggable.hd_list .schCl {width: 37px !important;text-align: center;font-size: 11px;border-radius: 2px !important;}
.fc-day-grid-event.fc-draggable.hd_list .schCl.reject:after, .fc-day-grid-event.fc-draggable.hd_list .schCl.noshow:after{display:block;content:'';opacity:0.6;position: absolute;width: 36px;height: 1px;background: #ff6363;top: 6px;left: 0;}
.fc-day-grid-event.fc-draggable.hd_list .schCl.noshow:after { background:#fff; opacity:0.5; }

.fc-event span.schCl.made { color : #39445d; }
.fc-event span.schCl.ing { color : #747e90; }
.fc-event span.schCl.reject { color : #ff6363; }

.fc-more-popover .fc-event-container {overflow: hidden; overflow-y: auto; max-height: 350px;}

.fc-unthemed .fc-dayGridMonth-view td {border-right: 1px solid #fff;}
.fc-unthemed .fc-dayGridMonth-view th {border-right: 1px solid #f1f3f5 !important;}
.fc-unthemed .fc-dayGridMonth-view th {padding-left: 6px !important;padding-top: 8px;padding-bottom: 8px;background: #f1f3f5;border-right: 1px solid #dee2e6 !important;}
.fc-unthemed .fc-dayGridMonth-view td a {padding-left:0}

 .left_area_close div#calendar {padding-left: 61px; box-sizing: border-box;}
.fc {
  direction: ltr;
  text-align: left; }

.fc-rtl {
  text-align: right; }

body .fc {
  /* extra precedence to overcome jqui */
  font-size: 1em; }

/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-highlight {
  /* when user is selecting cells */
  background: #bce8f1;
  opacity: .3; }

.fc-bgevent {
  /* default look for background events */
  background: #8fdf82;
  opacity: .3; }

.fc-nonbusiness {
  /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7; }

/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
.fc button {
  /* force height to include the border and padding */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* dimensions */
  margin: 0;
  height: 2.1em;
  padding: 0 .6em;
  /* text & cursor */
  font-size: 1em;
  /* normalize */
  white-space: nowrap;
  cursor: pointer; }

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0; }

.fc-state-default {
  /* non-theme */
  border: 0px solid; 
  color:#202A39;
  font-size:13px;
  }

.fc-state-default.fc-corner-left {
  /* non-theme */
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; }

.fc-state-default.fc-corner-right {
  /* non-theme */
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

/* icons in buttons */
.fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -0.05em;
  /* seems to be a good adjustment across browsers */
  margin: 0 .2em;
  vertical-align: middle; }

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
.fc-state-default {
  background-color: #fff;
  color:#202A39;
  }

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #fff; 
  }

.fc-state-hover {
  color: #333333;
  text-decoration: none;
  }

.fc-state-down,
.fc-state-active {
  background-color: #cccccc;
  background-image: none;
  }

.fc-state-disabled {
  cursor: default;
  background-image: none;
  color:#eff1f3;
  box-shadow: none; }

/* Buttons Groups
--------------------------------------------------------------------------------------------------*/
.fc-button-group {
  display: inline-block; }

/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
.fc .fc-button-group > * {
  /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 0 0 -1px; }

.fc .fc-button-group > :first-child {
  /* same */
  margin-left: 0; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); }

.fc-popover .fc-header {
  /* TODO: be more consistent with fc-head/fc-body */
  padding: 2px 4px; }

.fc-popover .fc-header .fc-title {
  margin: 0 2px; }

.fc-popover .fc-header .fc-close {
  cursor: pointer; }

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left; }

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right; }

/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
.fc-divider {
  border-style: solid;
  border-width: 1px; }

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  /* height is unreliable across browsers, so use padding */
  border-width: 1px 0; }

.fc-clear {
  clear: both; }

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.fc-bg {
  bottom: 0;
  /* strech bg to bottom edge */ }

.fc-bg table {
  height: 100%;
  /* strech bg to bottom edge */ }



/* Tables
--------------------------------------------------------------------------------------------------*/
.fc table {
  width: 100%;
  box-sizing: border-box;
  /* fix scrollbar issue in firefox */
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  /* normalize cross-browser */ }

.fc th {
  text-align: center; }

.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top; }

.fc td.fc-today {
  border-style: double;
  /* overcome neighboring borders */ }

/* Internal Nav Links
--------------------------------------------------------------------------------------------------*/
a[data-goto] {
  cursor: pointer; }

a[data-goto]:hover {
  text-decoration: underline; }

/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
.fc .fc-row {
  /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0; }

.fc-row table {
  /* don't put left/right border on anything within a fake row.
     the outer tbody will worry about this */
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  /* no bottom borders on rows */
  border-bottom: 0 hidden transparent; }

.fc-row:first-child table {
  border-top: 0 hidden transparent;
  /* no top border on first row */ }

/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
.fc-row {
  position: relative; }

.fc-row .fc-bg {
  z-index: 1; }

/* highlighting cells & background event skeleton */
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
  /* stretch skeleton to bottom of row */ }

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
  /* stretch skeleton to bottom of row */ }

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
  border-color: transparent; }

.fc-row .fc-bgevent-skeleton {
  z-index: 2; }

.fc-row .fc-highlight-skeleton {
  z-index: 3; }

/*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
  /* matches the space above the events */ }

.fc-row .fc-helper-skeleton {
  z-index: 5; }

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  /* see-through to the background below */
  /* extra precedence to prevent theme-provided backgrounds */
  background: none;
  /* in case <td>s are globally styled */
  border-color: transparent; }

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  /* don't put a border between events and/or the day number */
  border-bottom: 0; }

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  /* don't put a border between event cells */
  border-top: 0; }

/* Scrolling Container
--------------------------------------------------------------------------------------------------*/
.fc-scroller {
  -webkit-overflow-scrolling: touch; }

/* TODO: move to agenda/basic */
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative;
  /* re-scope all positions */
  width: 100%;
  /* hack to force re-sizing this inner element when scrollbars appear/disappear */ }

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
  position: relative;
  /* for resize handle and other inner positioning */
  display: block;
  /* make the <a> tag block */
  font-size:12px;
  line-height: 1.3; 
  border: 1px solid #3a87ad;  
  /* default BORDER color */  
  }

.fc-event,
.fc-event-dot {
  background-color: #3a87ad;
  /* default BACKGROUND color */ }

.fc-event,
.fc-event:hover {
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */ }

.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
  /* give events with links and draggable events a hand mouse pointer */ }

.fc-not-allowed,
.fc-not-allowed .fc-event {
  /* to override an event's custom cursor */
  cursor: not-allowed; }

.fc-event .fc-bg {
  /* the generic .fc-bg already does position */
  z-index: 1;
  /*background: #fff;*/
  opacity: .25; }

.fc-event .fc-content {
  position: relative;
  z-index: 2; }

/* resizer (cursor AND touch devices) */
.fc-event .fc-resizer {
  position: absolute;
  z-index: 4; }

/* resizer (touch devices) */
.fc-event .fc-resizer {
  display: none; }

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  /* only show when hovering or selected (with touch) */
  display: block; }

/* hit area */
.fc-event.fc-selected .fc-resizer:before {
  /* 40x40 touch area */
  content: "";
  position: absolute;
  z-index: 9999;
  /* user of this util can scope within a lower z-index */
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px; }

/* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/
.fc-event.fc-selected {
  z-index: 9999 !important;
  /* overcomes inline z-index */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }

.fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3); }

/* Horizontal Events
--------------------------------------------------------------------------------------------------*/
/* bigger touch area when selected */
.fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  /* below resizers */
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0; }

/* events that are continuing to/from another week. kill rounded corners and butt up against edge */
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* resizer (cursor AND touch devices) */
/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px;
  /* overcome border */ }

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px;
  /* overcome border */ 
  height: 10px !important;
}

/* resizer (mouse devices) */
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  /* overcome top border */
  bottom: -1px;
  /* overcome bottom border */ }

/* resizer (touch devices) */
.fc-h-event.fc-selected .fc-resizer {
  /* 8x8 little dot */
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* vertically center */
  top: 50%;
  margin-top: -4px; }

/* left resizer  */
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px;
  /* centers the 8x8 dot on the left edge */ }

/* right resizer */
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px;
  /* centers the 8x8 dot on the right edge */ }

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-day-grid-event {
  margin: 1px 2px 0;
  /* spacing between events and edges */
  padding: 0 1px; }

tr:first-child > td > .fc-day-grid-event {
  margin-top: 2px;
  /* a little bit more space before the first event */ }

.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  /* same z-index as fc-bg, behind text */
  /* overcome the borders */
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  /* darkening effect */
  background: #000;
  opacity: .25; }

.fc-day-grid-event .fc-content {
  /* force events to be one-line tall */
  white-space: nowrap;
  overflow: hidden; }

.fc-day-grid-event .fc-time {
  font-weight: bold; }

/* resizer (cursor devices) */
/* left resizer  */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px;
  /* to the day cell's edge */ }

/* right resizer */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px;
  /* to the day cell's edge */ }

/* Event Limiting
--------------------------------------------------------------------------------------------------*/
/* "more" link that represents hidden events */
a.fc-more {
  margin: 1px 3px;
  font-size: .85em;
  cursor: pointer;
  text-decoration: none; }

a.fc-more:hover {
  text-decoration: underline; }

.fc-limited {
  /* rows and cells that are hidden because of a "more" link */
  display: none; }

/* popover that appears when "more" link is clicked */
.fc-day-grid .fc-row {
  z-index: 1;
  /* make the "more" popover one higher than this */ }

.fc-more-popover {
  z-index: 2;
  width: 220px; }

.fc-more-popover .fc-event-container {
  padding: 10px; }

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-now-indicator {
  position: absolute;
  border: 0 solid red; }

/* Utilities
--------------------------------------------------------------------------------------------------*/
.fc-unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent; }

/*
TODO: more distinction between this file and common.css
*/
/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #f3f6f9; }

.fc-unthemed .fc-popover {
  background-color: #fff; }

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background: #eee; }

.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666; }

.fc-unthemed td.fc-today {
  background: #fcf8e3; }

.fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: .3; }

/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
.fc-icon {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  /* don't allow browser text-selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/*
Acceptable font-family overrides for individual icons:
  "Arial", sans-serif
  "Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
.fc-icon:after {
  position: relative; }

.fc-icon-left-single-arrow:after {
  content: "\2039";
  font-weight: bold;
  font-size: 200%;
  top: -7%; }

.fc-icon-right-single-arrow:after {
  content: "\203A";
  font-weight: bold;
  font-size: 200%;
  top: -7%; }

.fc-icon-left-double-arrow:after {
  content: "\AB";
  font-size: 160%;
  top: -7%; }

.fc-icon-right-double-arrow:after {
  content: "\BB";
  font-size: 160%;
  top: -7%; }

.fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%; }

.fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%; }

.fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%; }

.fc-icon-x:after {
  content: "\D7";
  font-size: 200%;
  top: 6%; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid; }

.fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: .9em;
  margin-top: 2px; }

/* List View
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-list-item:hover td {
  background-color: #f5f5f5; }

/* Colors
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-disabled-day {
  background-image: none; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;
  /* where they meet, let the header have the border */ }

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-event {
  /* overpower jqui's styles on <a> tags. TODO: more DRY */
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
  /* undo ui-widget-header bold */
  font-weight: normal; }

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.ui-widget td.fc-axis {
  font-weight: normal;
  /* overcome bold */ }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats .ui-widget-content {
  background: none;
  /* see through to fc-bg */ }

.fc.fc-bootstrap3 a {
  text-decoration: none; }

.fc.fc-bootstrap3 a[data-goto]:hover {
  text-decoration: underline; }

.fc-bootstrap3 hr.fc-divider {
  border-color: inherit; }

.fc-bootstrap3 .fc-today.alert {
  border-radius: 0; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-popover .panel-body {
  padding: 0; }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none; }

.fc.fc-bootstrap4 a {
  text-decoration: none; }

.fc.fc-bootstrap4 a[data-goto]:hover {
  text-decoration: underline; }

.fc-bootstrap4 hr.fc-divider {
  border-color: inherit; }

.fc-bootstrap4 .fc-today.alert {
  border-radius: 0; }

.fc-bootstrap4 a.fc-event:not([href]):not([tabindex]) {
  color: #fff; }

.fc-bootstrap4 .fc-popover.card {
  position: absolute; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap4 .fc-popover .card-body {
  padding: 0; }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap4 .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none; }

/* Toolbar
--------------------------------------------------------------------------------------------------*/
.fc-toolbar {
  text-align: center; }

.fc-toolbar.fc-header-toolbar {
  padding: 16px 0;}

.fc-toolbar.fc-footer-toolbar {
  margin-top: 1em; }

.fc-toolbar .fc-left {
  float: left; }

.fc-toolbar .fc-right {
  float: right;margin-right: 10px; }

.fc-toolbar .fc-center {
  display: inline-block; }

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * {
  /* extra precedence to override button border margins */
  float: left;
  margin-left: .75em; }

/* the first thing within each left/center/right section */
.fc .fc-toolbar > * > :first-child {
  /* extra precedence to override button border margins */
  margin-left: 0; }

/* title text */
.fc-toolbar h2 {float:left;line-height: 61px;font-size: 19px;color:#202A39;cursor: default;margin-right:15px !important;}

/* button layering (for border precedence) */
.fc-toolbar button {
  position: relative; }

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2; }

.fc-toolbar .fc-state-down {
  z-index: 3; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4; }

.fc-toolbar button:focus {
  z-index: 5; }

/* View Structure
--------------------------------------------------------------------------------------------------*/
/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.fc-view,
.fc-view > table {
  /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1; }

/* BasicView
--------------------------------------------------------------------------------------------------*/
/* day row structure */
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  /* there may be week numbers in these views, so no padding-top */
  padding-bottom: 1em;
  /* ensure a space at bottom of cell for user selecting/clicking */ }

.fc-dayGrid-view .fc-body .fc-row {
  min-height: 4em;
  /* ensure that all rows are at least this tall */ }

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
.fc-row.fc-rigid {
  overflow: hidden; }

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

/* week and day number styling */
.fc-day-top.fc-other-month {
  opacity: 0.3; }

.fc-dayGrid-view .fc-week-number,
.fc-dayGrid-view .fc-day-number {
  padding: 2px; }

.fc-dayGrid-view th.fc-week-number,
.fc-dayGrid-view th.fc-day-number {
  padding: 0 2px;
  /* column headers can't have as much v space */ }

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: right; }

.fc-rtl .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: left; }

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-week-number {
  float: left;
  border-radius: 0 0 3px 0; }

.fc-rtl .fc-dayGrid-view .fc-day-top .fc-week-number {
  float: right;
  border-radius: 0 0 0 3px; }

.fc-dayGrid-view .fc-day-top .fc-week-number {
  min-width: 1.5em;
  text-align: center;
  background-color: #f2f2f2;
  color: #808080; }

/* when week/day number have own column */
.fc-dayGrid-view td.fc-week-number {
  text-align: center; }

.fc-dayGrid-view td.fc-week-number > * {
  /* work around the way we do column resizing and ensure a minimum width */
  display: inline-block;
  min-width: 1.25em; }

/* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
.fc-timeGrid-view .fc-day-grid {
  position: relative;
  z-index: 2;
  /* so the "more.." popover will be over the time grid */ }

.fc-timeGrid-view .fc-day-grid .fc-row {
  min-height: 3em;
  /* all-day section will never get shorter than this */ }

.fc-timeGrid-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 1em;
  /* give space underneath events for clicking/selecting days */ }

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.fc .fc-axis {
  /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap; }

.fc-ltr .fc-axis {
  text-align: right; }

.fc-rtl .fc-axis {
  text-align: left; }

/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
.fc-time-grid-container,
.fc-time-grid {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1; }

.fc-time-grid {
  min-height: 100%;
  /* so if height setting is 'auto', .fc-bg stretches to fill height */ }

.fc-time-grid table {
  /* don't put outer borders on slats/bg/content/etc */
  border: 0 hidden transparent; }

.fc-time-grid > .fc-bg {
  z-index: 1; }

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  /* the <hr> AgendaView injects when grid is shorter than scroller */
  position: relative;
  z-index: 2; }

.fc-time-grid .fc-content-col {
  position: relative;
  /* because now-indicator lives directly inside */ }

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0; }

/* divs within a cell within the fc-content-skeleton */
.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1; }

.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2; }

.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3; }

.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4; }

.fc-time-grid .fc-now-indicator-line {
  z-index: 5; }

.fc-time-grid .fc-mirror-container {
  /* also is fc-event-container */
  position: relative;
  z-index: 6; }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0;
  /* each cell is responsible for its top border */ }

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted; }

/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-highlight-container {
  /* a div within a cell within the fc-highlight-skeleton */
  position: relative;
  /* scopes the left/right of the fc-highlight to be in the column */ }

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
  /* top and bottom will be in by JS */ }

/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
.fc-ltr .fc-time-grid .fc-event-container {
  /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 2px; }

.fc-rtl .fc-time-grid .fc-event-container {
  /* space on the sides of events for RTL */
  margin: 0 2px 0 2.5%; }

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  /* scope inner z-index's */ }

.fc-time-grid .fc-bgevent {
  /* background events always span full width */
  left: 0;
  right: 0; }

/* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
.fc-v-event.fc-not-start {
  /* events that are continuing from another day */
  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;
  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;
  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-time-grid-event {
  overflow: hidden;
  /* don't let the bg flow over rounded corners */ }

.fc-time-grid-event.fc-selected {
  /* need to allow touch resizers to extend outside event's bounding box */
  /* common fc-selected styles hide the fc-bg, so don't need this anyway */
  overflow: visible; }

.fc-time-grid-event.fc-selected .fc-bg {
  display: none;
  /* hide semi-white background, to appear darker */ }

.fc-time-grid-event .fc-content {
  overflow: hidden;
  /* for when .fc-selected */ }

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px;font-size:12px;}

.fc-time-grid-event .fc-time {
  font-size:12px;;
  white-space: nowrap; }

/* short mode, where time and title are on the same line */
.fc-time-grid-event.fc-short .fc-content {
  /* don't wrap to second line (now that contents will be inline) */
  white-space: nowrap; }

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  /* put the time and title on the same line */
  display: inline-block;
  vertical-align: top; }

.fc-time-grid-event.fc-short .fc-time span {
  display: none;
  /* don't display the full time text... */ }

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
  /* ...instead, display only the start time */ }

.fc-time-grid-event.fc-short .fc-time:after {
  content: "\A0-\A0";
  /* seperate with a dash, wrapped in nbsp's */ }

.fc-time-grid-event.fc-short .fc-title {
  font-size: 12px;
  /* make the title text the same size as the time */
  padding: 0;
  /* undo padding from above */ }

/* resizer (cursor device) */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 12px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize; }

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "="; }

/* resizer (touch device) */
.fc-time-grid-event.fc-selected .fc-resizer {
  /* 10x10 dot */
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* horizontally center */
  left: 50%;
  margin-left: -5px;
  /* center on the bottom edge */
  bottom: -5px; }

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0; }

/* arrow on axis */
.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px;
  /* vertically center on top coordinate */ }

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  /* triangle pointing right... */
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent; }

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  /* triangle pointing left... */
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent; }

/* List View
--------------------------------------------------------------------------------------------------*/
/* possibly reusable */
.fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px; }

/* view wrapper */
.fc-rtl .fc-list-view {
  direction: rtl;
  /* unlike core views, leverage browser RTL */ }

.fc-list-view {
  border-width: 1px;
  border-style: solid; }

/* table resets */
.fc .fc-list-table {
  table-layout: auto;
  /* for shrinkwrapping cell content */ }

.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px; }

.fc-list-table tr:first-child td {
  border-top-width: 0; }

/* day headings with the list */
.fc-list-heading {
  border-bottom-width: 1px; }

.fc-list-heading td {
  font-weight: bold; }

.fc-ltr .fc-list-heading-main {
  float: left; }

.fc-ltr .fc-list-heading-alt {
  float: right; }

.fc-rtl .fc-list-heading-main {
  float: right; }

.fc-rtl .fc-list-heading-alt {
  float: left; }

/* event list items */
.fc-list-item.fc-has-url {
  cursor: pointer;
  /* whole row will be clickable */ }

.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px; }

/* make the dot closer to the event title */
.fc-ltr .fc-list-item-marker {
  padding-right: 0; }

.fc-rtl .fc-list-item-marker {
  padding-left: 0; }

.fc-list-item-title a {
  /* every event title cell has an <a> tag */
  text-decoration: none;
  color: inherit; }

.fc-list-item-title a[href]:hover {
  /* hover effect only on titles with hrefs */
  text-decoration: underline; }

/* message when no events */
.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table; }

.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.fc-unthemed .fc-list-empty {
  /* theme will provide own background */
  background-color: #eee; }


/***********201803 추가************/

.fc-dayGridMonth-view th,
.fc-dayGridMonth-view td {
  border-style: solid;
  border-width: 0px;
  padding: 0;
  vertical-align: top; }

.fc th {
  text-align: left; 
  border-top:1px solid #e6e5e5;
  padding: 12px 0 12px 10px;   }

.fc-dayGridMonth-view th{background-color: #ffffff;padding: 7px 0 7px 10px;}
.fc th span{font-size:13px;color:#8a8a8a;font-weight:normal;cursor:default;}
.fc th a{font-size:13px;color:#202A39;font-weight:normal}
.fc td {border-top: 1px solid #e7e9ea;border-right: 1px solid #e7e9ea !important;}
.fc .fc-time-grid td{border-top:1px solid #efefef;}

.fc-time-grid .fc-slats td:first-child{border:none;}
.fc .fc-axis td{border: none; }
td.fc-axis.fc-time.fc-widget-content {
    background: #f1f3f5;width: 50px !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
}
/*월간 - 기념일 날짜*/
.fc-special a{color:#ff4242;}

.fc-special-name{color:#b8b8b8;font-size: 11px;font-weight: bold;margin-left:1px;margin-top: 5px;margin-right: 5px;float: right;line-height:1.3;margin-top:5px;}/*padding:2px 잡혀있음*/
@media all and (max-width:1250px) {
  .fc-left .fc-special-name {    max-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
}
.fc-left .fc-special-name{color:#b8b8b8;font-size:12px;margin-right:8px;float:none;line-height:1.3;margin-top:0}
.day-num-other{color:#a2a2a2;font-size:12px;float:right;margin-right:0px;margin-top:10px}/*음력*/

.fc-widget-content .fc td{border-top:0px !important}

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number{float:left;text-indent: 6px;}/*날짜*/
a.fc-day-number.on_orange {text-indent: 0 !important; text-align: center;}
.fc-day-number{color:#202A39;font-size:12px;}/*기본날짜*/
.fc-day-top .fc-day-number{
    margin-top:5px;
    margin-left:7px; 
    padding: 0;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: left;
}

.fc-toolbar label{padding-left:22px !important;}

/*today*/
.fc td.fc-today{border-style:solid none none none;background:#f3f6f9;}
/*.fc td.fc-today a.fc-day-number{color:#fff;background:url('../images/contents/ic_cal_today.png') no-repeat 0 0;}
a.fc-day-number:visited,
a.fc-day-number:active{color:#585858;background:url('../images/contents/ic_cal_select.png') no-repeat 0 0;}
*/

/*a.fc-day-number.one-txt:visited,
a.fc-day-number.one-txt:active{margin-left:4px;padding-left:6px;color:#585858;background:url('../images/contents/ic_cal_select.png') no-repeat 0 0;}/* 달력 한글자 */
.on_orange{color: #fff !important;background: #477fff; border-radius:50%; text-align:center;}
.on_orange.one-txt{margin-left:4px;padding-left:0;color:#fff;background: #477fff;}/* 달력 한글자 */
.on_select{
    color:#202A39;
    background: #e7ecf1;
    border-radius: 50%;
    text-indent: 0px !important;
    text-align: center !important;
}

/*더보기*/
a.fc-more {
  border-radius:25px;
  background-color:#f3f6f9;
  padding:4px 6px;
  float:right;
  margin: 5px 3px 1px;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  }

/*a.fc-more { 
  background-color:#f3f6f9;
  padding:5px 0px;
  float:right;
  position:absolute;
  width:99%;
  bottom:0;
  border-top:1px solid #ededed;
  text-align:center;
  color:#949494;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none; }*/

a.fc-more:hover{color:#202A39;background-color:#fff}

.toolbar-button-today{border:none;background:none;font-size:13px;color:#202A39;}

.toolbar-btn{display:inline-block;background:none;border:none;color:#202A39;padding:0;margin-right: 0 !important;margin-top: 10px !important;}
.toolbar-btn.today{font-size: 14px;line-height: 40px;padding: 0 13px;background-color: #f1f3f5 !important;border-radius: 5px;margin-left: 10px;-moz-border-radius : 5px;
-webkit-border-radius : 5px;
-ms-border-radius: 5px;border: 1px solid #ffffff;background-color:#ededed;}
.toolbar-btn.today:hover { background-color: #f1f3f5;color:#202A39 }
.fc-center{}
.fc-center h2{float:left;font-size:14px;font-weight:bold;color:#202A39;margin:0px 8px 0 16px;line-height:1;padding-top:3px}
.bt-prev{width: 40px;height: 40px;border-radius:50px;/* border:1px solid #e0e0e0; */background: #f1f3f5 url('../images/contents/month_bt_prev.svg') no-repeat 3px center;float:left;}
.bt-next{width: 40px;height: 40px;border-radius:50px;background: #f1f3f5 url('../images/contents/month_bt_next.svg') no-repeat center center;float:left;}
.bt-prev:hover {background: #f1f3f5 url('../images/contents/month_bt_prev_on.svg') no-repeat  3px center;}
.bt-next:hover {background: #f1f3f5 url(../images/contents/month_bt_next_on.svg) no-repeat center center;}

.toolbox{padding: 0 15px 0 20px;overflow:hidden;border-bottom: 1px solid #e7e9ea;height: 60px;}
.left_area_close .toolbox {
    padding: 0 20px 0 80px;
}
.toolbar-radio{margin-right:9px;}
.toolbox label{padding-left:16px !important;}
.toolbox .selectboxWrap label{padding-left:0 !important;}
 #calendar {
    width: 100%;
    margin: 0 auto;
  }

a[data-goto]:hover {text-decoration: none; }

  /* Popover
--------------------------------------------------------------------------------------------------*/

.fc-popover {position: absolute;box-shadow:0 0 6px rgba(0, 0, 0, 0.4); }

.fc-popover .fc-header {
  /* TODO: be more consistent with fc-head/fc-body */
  padding: 10px 14px 5px ; }

.fc-popover .fc-header .fc-title { margin: 0 2px;color:#202A39; }
.fc-popover .fc-header .fc-close { cursor: pointer; }
.fc-unthemed .fc-popover {  border-width: 0px;  background-color: #fff;}
.fc-unthemed .fc-popover .fc-header .fc-close { font-size: 0;width:19px;height:19px;background:url('../images/contents/ic_close.gif') no-repeat center center; margin-top: -3px; color: #666;  }
.fc-unthemed .fc-popover .fc-header{background: transparent; }
.fc-more-popover { z-index: 2; width: 200px; }
.fc-more-popover .fc-event-container { padding:0 15px 15px 15px; }

  /* 
--------------------------------------------------------------------------------------------------*/

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: solid;border-top:0px }

.fc-ltr .fc-axis {text-align:center;}

/*주간*/
.fc-timeGridWeek-view .fc-day-header .fc-day-number{float:right;color:#202A39;font-size:14px;margin-right: 5px;width: 20px;height: 20px;line-height: 20px;text-align: center;}

.fc-timeGridWeek-view .fc-day-header.fc-today{background-color:#f5f5f5;}

.fc-timeGridWeek-view .on_orange{color: #fff !important;background: transparent;width: 20px;height: 20px;line-height: 20px;text-align: center; border-radius:50%;}
.fc-day-header.fc-special a.on_orange.fc-day-number { color: #fff !important; }

.fc-timeGridWeek-view .fc-special-name{color: #ff0b0b;font-size: 11px;margin-right: 8px;float: right;line-height: 20px;margin-top: 0px;margin-left: 0;}/*기념일*/
.fc-day-header.fc-special a,  .fc-special span.fc-special-name, .fc-timeGridWeek-view .fc-day-header.fc-special .fc-day-number {color: #ff3c3c !important; }
.fc-timeGridWeek-view th.fc-day-header{padding:12px 0 13px 5px !important;}/*주간 시간 th*/
.fc-timeGridWeek-view .fc-axis{font-size:14px;font-weight:normal}
.fc-timeGridWeek-view .fc-time{font-size:12px;}
.fc-timeGridWeek-view .fc-time span{color:#202A39;}
.fc-timeGrid-view .fc-day-grid .fc-row .fc-content-skeleton{padding-bottom:1.7em;}/*주간 종일*/
.fc-timeGridWeek-view td:first-child{border-left:0px;}
.fc-timeGridWeek-view td:last-child{border-right:0px;}

.fc-timeGridWeek-view th.fc-day-header a{line-height:20px;}
.fc-timeGrid-view th.fc-day-header.fc-sun a {color: #ff3c3c;}
.fc-timeGrid-view th.fc-day-header.fc-sat a{color:#0c82ff}

hr.fc-divider { padding: 0 ;border-width: 1px 0 0; }/*종일 밑 라인*/

.fc-time-grid .fc-slats td {height:1.8em;}

/*일간*/
.fc-timeGrid-view th.fc-day-header{padding:16px 0 15px 10px}/*일간 시간 th*/
.fc-timeGrid-view .fc-day-grid .fc-row { min-height: 3.6em; }/*일간 종일*/
.fc-timeGridDay-view .fc-axis{font-size:14px;font-weight:normal}
.fc-timeGridDay-view .fc-time{font-size:12px;color:#202A39;}
.fc-timeGridDay-view .fc-time span{color:#202A39;}
.fc-timeGridDay-view td:first-child{border-left:0px;}
.fc-timeGridDay-view td:last-child{border-right:0px;}

.fc-timeGridDay-view th .fc-special-name{color:#b8b8b8;font-size:12px;margin-left:0px;float:left;margin-top:0}/*기념일-padding:2px 잡혀있음*/
.fc-timeGridDay-view th .day-num-other{color:#b8b8b8;font-size:12px;float:left;margin-left:10px;margin-top:0}/*음력*/
.fc-timeGridDay-view th span{float:left;}

/*일정 색상영역 */
.fc-day-grid-event {margin: 2px 3px 3px 6px;padding: 4px 1px 4px;border-radius:2px; /**/}


/**일정칸띄우기**/
/*.fc-event span:first-child{margin-left:9px}*/
.fc-event span{color:#fff;font-size: 12px;line-height:1.2;padding: 0;width: 100% !important;box-sizing: border-box;}
.fc-timeGridWeek-view .fc-event span{color:#fff;}
.fc-timeGridDay-view .fc-event span{color:#fff;}
.fc-event .fc-content{padding-left:4px}

/* 월간 스케쥴 */
/*.fc-bullet {float: left;margin-top: 3px;width: 6px;height: 6px;display: inline-block;border-radius: 3px;background: #b18cdc;margin-right:6px;}

.fc-bullet{float:left;width:3px;height:100%;background: #b18cdc;margin-right:8px;}*/

/* 주간 보더색상 */
.fc-timeGridWeek-view .fc-time-grid-event,
.fc-day-grid .fc-time-grid-event{margin-left:-3px;margin-right:10px ;margin-bottom:-1px;padding-top:4px;}

/*.fc-more-cell{position:absolute;top:0; }
.fc-more-cell div{position: relative;height:100%;width:100%;}*/

.fc-timeGridWeek-view .fc-event .fc-bg,
.fc-day-grid .fc-event .fc-bg {border:1px solid #e6e5e5;opacity:1}
.fc-day-grid .fc-time-grid-event{margin-left:-3px;margin-right:10px ;margin-bottom:-1px;padding-top:4px;}

/* 메인캘린더 호버시 */
.fc td.fc-today { background: #fafafa; }
.fc-content:hover span.fc-title {
    font-weight: bold;
   /* color: #484848 !important; */
}
.fc-timeGridWeek-view th.fc-day-header {
    padding: 8px 0 8px 6px !important;
}
.fc-row.fc-widget-header { background: #fafafa;}

.fc-timeGridWeek-view .fc-axis, .fc-timeGridDay-view .fc-axis { font-size: 12px;width: 50px !important; } 
.fc-day-top .fc-day-number { color: #202A39; font-weight:bold; }
.fc-sun.fc-widget-header span {color: #ff3c3c;}
.day-num-other {color: #a1a6b3 !important;font-weight: bold;font-size: 11px;margin-right: -3px;}
.fc-sat.fc-widget-header span {color: #477fff;}
.fc-sat .fc-day-number {color: #477fff;}
.fc-sun .fc-day-number {color: #ff3c3c;}
.fc-dayGridMonth-view th { padding-left:6px; }
.fc-day-grid-event { padding: 0; line-height: 15px;border-top: 0 !important;border-bottom: 0 !important;}
.fc-day-top .fc-day-number {margin-left: 0; margin-top:0; }
.day-num-other { margin-top: 5px; }
.fc-timeGrid-view th.fc-day-header { padding: 10px 0 10px 10px; }

a.fc-more, a.fc-more:hover {padding: 4px 5px;float: right;width: auto;box-sizing: border-box;margin: 2px 4px 2px;text-align: center;border: 1px solid #e7e9ea;font-size: 12px;cursor: pointer;border-radius: 5px;padding-left:4px !important;color: #477fff;background: #fff;text-decoration: none;font-weight: bold;}
a.fc-more:hover { color: #202A39; background:#f3f6f9; }

.fc-more-popover .fc-event-container {padding: 15px 15px;}
.fc-unthemed .fc-popover { border-radius: 5px; box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2); } 
.fc-unthemed .fc-popover .fc-header {font-size: 13px;color:#202A39;font-weight: bold;padding: 20px 15px 28px;border-bottom: 1px solid #f3f3f3;}
.fc-more-popover .fc-event-container .fc-day-grid-event { margin:0 0 4px; }

.toolbar-btn.today { font-weight: bold; }
.toolbar-btn.today, .bt-next, .bt-prev {background-color: #fff;}
td.fc-day-top.fc-special a, td.fc-day-top.fc-special span {color:#ff3c3c;}

.fc-time-grid .fc-event, .fc-time-grid .fc-bgevent {margin-top: -1px;background: #f9fafb !important;border-radius: 2px;right: 0 !important;opacity:0.8;}
.fc-timeGridWeek-view .fc-time-grid .fc-event,.fc-timeGridWeek-view .fc-time-grid .fc-bgevent {opacity:1}
.fc-timeGrid-view .fc-time-grid .fc-event, .fc-timeGrid-view .fc-time-grid .fc-bgevent {border-width: 0 0 0 4px !important;border-top: 1px solid #e1e5e8 !important;border-bottom: 1px solid #e1e5e8 !important;border-radius: 3px; margin-top:0;border-right: 1px solid #e1e5e8 !important}
.fc-time-grid .fc-slats td {line-height: 3.5em !important;height:3.5em !important;}
.fc-timeGrid-view .fc-axis.fc-time {position: relative;}
.fc-timeGrid-view .fc-time-grid-container .fc-axis.fc-time span {line-height: 0;position: absolute;top: 6px;right: 10px;display: inline-block;}
.fc th span { font-weight: bold; font-size: 12px; }
tr:first-child > td > .fc-day-grid-event { margin-top: 1.5px }
.fc-day-grid-event:hover { /* box-shadow: 0 1px 3px rgba(0,0,0,0.2); */ }
#fc-userToolbox .fc-right .selectboxWrap:hover {}
.fc-timeGridWeek-view .fc-week .fc-axis, .fc-timeGridDay-view .fc-week .fc-axis { font-size: 12px; font-weight: bold; }
.fc th a {font-size: 12px;color: #202A39;font-weight: bold;}
.fc-timeGrid-view .fc-row.fc-week.fc-widget-content {box-shadow:0 3px 2px rgba(27, 35, 45, 0.08);border-bottom: 0 !important;}
hr.fc-divider.fc-widget-header {border-color: #ffff;}
.fc-timeGrid-view .fc-head-container .fc-axis {font-weight: bold;}
.fc-timeGridDay-view .fc-row.fc-widget-header, .fc-timeGridDay-view .fc-week.fc-widget-content {margin-right: 0 !important;}
.fc-left .fc-special-name {color:#9a9a9a;margin-left: 11px;}
.fc-timeGridDay-view th .fc-special-name { margin-left: 10px; color:#ff4b4b;margin-top: -1px;}
.fc th span {color: #202A39;}
.fc .fc-time-grid td {border-top: 1px solid #e1e5e8;}
.fc-time-grid-event:hover { box-shadow: 0 1px 2px rgba(0,0,0,0.15) }
.fc-time-grid-event .fc-time, .fc-time-grid-event .fc-title { font-weight: bold; color: #202A39 !important;}
.fc-timeGrid-view .fc-time span {color: #202A39 !important; }
.fc-unthemed td { border-color: #e7e9ea}
.fc-row.fc-widget-header {background: #f1f3f5;}
.fc-unthemed th {border-color: #ccd2d8;background: #f1f3f5;border-right: 1px solid #dee2e6;}
.fc-event span {font-weight:bold;}
.fc-day-grid-event {margin-right: 10px;}
.fc td.fc-today {background: #ffffff;}
.fc-time-grid .fc-event:hover, .fc-time-grid .fc-bgevent:hover {opacity: 1;z-index: 10000 !important;transition: 0.1s;margin-left: -10px;margin-top: -7px;opacity:0.92}
.fc-timeGridWeek-view .fc-time-grid .fc-event:hover,.fc-timeGridWeek-view  .fc-time-grid .fc-bgevent:hover {left:0 !important;}
.fc-time-grid .fc-event:hover div,.fc-time-grid .fc-event:hover span, .fc-time-grid .fc-bgevent:hover {font-size: 14px !important;}
.fc-timeGridWeek-view .fc-day-header .fc-day-number { font-size: 12px !important; }
.fc-dayGridMonth-view .fc-special-name,.fc-timeGridWeek-view .fc-special-name  {background: #ffe7e7;line-height: 1.5;padding: 0px 2px;margin-top: 4px;border-radius: 2px !important;}
.fc-timeGridWeek-view .fc-special-name {margin-top: 2px;margin-right: 2px;background: transparent; border-radius:5px;}
.fc-event-container .writtenBy span {color: #7b7b7b !important;}
.fc-timeGrid-view .fc-row.fc-week.fc-widget-content .fc-title {padding-left: 2px;}
.fc-timeGrid-view .fc-row.fc-week.fc-widget-content .fc-event {text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.fc-timeGrid-view .fc-row.fc-week.fc-widget-content .writtenBy {padding-left: 6px;font-weight: normal;}

th.fc-day-header.fc-widget-header.fc-today {
    background: #477fff;
    color:#fff !important;
    /* border-right: 0 none; */
}
.fc-timeGridWeek-view th.fc-day-header.fc-today a { color:#fff !important;}
th.fc-day-header.fc-widget-header.fc-today span, th.fc-day-header.fc-widget-header.fc-today .day-num-other, th.fc-day-header.fc-widget-header.fc-today .fc-special-name {
    color: #fff !important;
}

.fc-timeGrid-view .fc-row.fc-week.fc-widget-content td.fc-today { background:#fff}

.fc-day-grid-event.fc-draggable {line-height: 15px !important;margin: 1px 9px 3px 0;transition:0.2s; }
a.fc-day-grid-event.fc-resizable { line-height:17px !important; margin-top:0 !important; }
.fc-day-grid-event.fc-draggable:hover { margin-left:3px; transition:0.1s; }

.fc-special.fc-sun > .fc-special-name {float: left;}
.fc-special.fc-sun > .day-num-other { margin-right:7px }

#app .fc-sub-title { color: #35405a;background: #fff;border-radius: 2px;line-height: 13px !important;font-size: 11px;height: 13px;padding: 0 2px;width: auto !important;margin-left: -3px;display: inline-block; }

.fc-timeGridDay-view .fc-content {
  overflow: visible;
}

#hMonthPrt { position: relative; transition:0.2s;  }
#hMonthPrt:hover { color:#477fff; }
#hMonthPrt .forDatePick { position: absolute; width:100%; height: 100%; cursor:pointer; color : transparent; background : transparent; font-size: 0; }

.fc-widget-content .contDiv {padding: 0 7px 10px; cursor: pointer;}
.fc-widget-content .grpDiv {padding: 6px 10px;background: #f1f3f5;border: 1px solid #f1f3f5; border-radius: 3px; overflow: hidden; box-sizing: border-box; min-height: 56px; }
.fc-widget-content .grpDiv * { font-weight: bold; } 
.fc-widget-content .grpDiv div { overflow: hidden; width: 100%;height: 16px; line-height: 16px; }
.fc-widget-content .row-6 .grpDiv div { height : 13px; line-height: 13px; }
.fc-widget-content .grpDiv div.late span { color:#ff6363 !important; }
.fc-widget-content .grpDiv .fst { float: left;}
.fc-widget-content .grpDiv .lst  { float: right; }
.fc-widget-content .contDiv.today .grpDiv { background: #fff; border: 1px solid #e7e9ea; box-sizing: border-box; }
.fc-widget-content .contDiv .grpDiv.dc7 span, .fc-widget-content .contDiv .grpDiv.dc0 span { color:#fff; }
.fc-widget-content .grpDiv .now { color:#477fff }
.contDiv.holiday .grpDiv {line-height: 41px !important; height: 41px;}

span.fc-title.request_move { font-style: italic; }

/* 달력 시간표기 */
span.cal_time { float: left; width: auto !important; min-width: 51px; color: #202a39; opacity: .7; font-weight: normal; margin-right: 5px; line-height: 1.3; }
span.right_calTime { float: right; font-size: 11px; line-height: 1.3; text-align: right; margin-right: 0; margin-left: 5px; letter-spacing: -0.5px; }
.fc-event span.titleTime {width: 72% !important; overflow: hidden; display: inline-block; }
@media all and (max-width:1700px) {
  .fc-event span.titleTime { width: 68% !important; padding-right: 10px; white-space: nowrap; text-overflow: ellipsis; }
}

.section_ce_fix.cal .fc-toolbar .toolbar-btn.today { color : #202A39; }
.section_ce_fix.cal .fc-toolbar .toolbar-btn.today.current_month { color : #acb2bf; }