<template>
    <div class="section_ce_fix grpSection dpOne">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">그룹</h3>
            <div class="btns">
                <!-- <input type="button" class="dpTwo" value="세로"/> -->
                <!-- <input type="button" class="dpOne" value="가로"/> -->
				<a class="schBtn">검색하기</a>
            </div>
        </div>
		<!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
        <div class="searchDiv">
            <div class="schGrp">
                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                <input type="text" id="group_search" name="" value="" class="" placeholder="그룹을 검색하세요">
                <div class="btns">
                    <!-- 검색 디브 removeClass(on) -->
                    <a class="closeBtn">닫기</a>
                    <a href="#" class="bt_search" @click.prevent="getGroupList()"><span class="blind">찾기버튼</span></a>
                </div>
            </div>
        </div>
        <div id="grpScroll" class="section_scroll"><!--스크롤 생겨야할 영역-->
            <div class="viewGroup">					
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <h3 class="selectTitle"><span class="txt">{{list_type == 9 ? '전체' : (list_type == 1 ? '일반그룹' : (list_type == 2 ? '프리미엄 그룹' : '즐겨찾기'))}}</span> <span class="num">{{ computedGroupList.length }}</span></h3>
                        <ul class="dropdown">
                            <li @click="listTypeChange(9)"><a :class="{on: list_type == 9}">전체<span></span></a></li>
                            <li @click="listTypeChange(1)"><a :class="{on: list_type == 1}">일반그룹<span></span></a></li>
                            <li @click="listTypeChange(2)"><a :class="{on: list_type == 2}">프리미엄 그룹<span></span></a></li>
                            <li @click="listTypeChange(3)"><a :class="{on: list_type == 3}">즐겨찾기<span></span></a></li>
                        </ul>
                    </div>
                    <ul class="sortHeader groupHeader" id="">
                        <li class="faved_new"><span>즐겨찾기</span></li>
                        <li class="placeNm headerSort midea" style="width: 776px;" @click="groupNameSort"
                            :class="{
                                sortOff  : group_sort_target != 'NAME',
                                sortUp   : group_sort_target == 'NAME' && group_sort_direction == 'ASC', 
                                sortDown : group_sort_target == 'NAME' && group_sort_direction == 'DESC', 
                            }"><span style="margin-left:19px;">그룹명</span></li>
                        <!--<li class="people headerSort sortOff" id=""><span>멤버</span></li>
                        <li class="team headerSort sortOff" id=""><span>팀</span></li>-->
                        <!--<li class="pos headerSort sortOff" id=""><a class="sort">직급</a>-->
                            <ul class="dropdown">
                                <li><a class="on">전체<span></span></a></li>
                                <li><a>관리자<span></span></a></li>
                                <li><a>부관리자<span></span></a></li>
                                <li><a>멤버<span></span></a></li>
                            </ul>
                        <li class=status id=""><span>현황</span></li>
                    </ul>
                    <div class="content" id="">
                        <ul class="grpUserList" id="">
                            
							<!--height:229px이상시 스크롤 -->
                            <li class="add" v-if="list_type == 1 || list_type == 9">
                                <a href="#" id="makeGroup"  class="newGroup placeNm" style="" @click.prevent="makeGroup()">+</a> <!-- 클릭시 class="on" 추가-->
								<span class="place_plus">그룹만들기</span>
                            </li>

							<!-- <li class="notYet">
								<div class="bg"></div>
								<a>
									<p class="premiumTag" style="transition: all 0.5s ease 0s; background-color: rgb(255, 255, 255) !important;">HODU H</p>
									<span class="img">그룹 이미지</span>
									<img src="@/assets/images/footer/g1.jpg" style="display : none;" @load="onLoadDummyImage">
									<span class="placeNm ">호두아파트</span>
								</a>
								
								<div class="before">
                                    <span class="descript">호두아파트입니다</span>
                                    <input type="button" class="yes" value="가입요청" @click="homeJoin"/>
                                </div>
							</li> -->

							<li class="created hodudoc-group" v-if="hodu_d_group_data.length > 0 && (list_type == 2 || list_type == 9)">
								<p class="premiumTag">HODU D</p>
								<div class="bg"></div>
								<a>
									<!---<span class="num">1</span>---->
									<!-- <span title="즐겨찾기" class="faved_new off">즐겨찾기</span> -->
									<!-- <span class="bookmark" style="position: absolute;">색상</span> -->
									<span class="img imgDc0">그룹 이미지</span><!---->
									<span class="placeNm ">호두닥</span>
									<!-- <span class="pos king">관리자</span> -->
								</a>
								<a class="seeDets" @click="moveHospitalList">가입한 병원</a><!----><!---->
							</li>

                            <!-- 리스트 부분 -->
                            <!-- 
                                created   : 삭제 권한도 있는거
                                signedUp  : 삭제권한이 없는 거
                                waiting   : 가입 요청 된 그룹
                                notYet    : 가입 이 안된 그룹
                            -->
<!-- ********** 호두C 리스트 클래스추가(hcList), **** 호두D 리스트 클래스추가(hdList), 호두D는 그룹 한개로 묶어 갈 수 있으니 아직은 보류 && group.biz_id.substr(0, 1) == 'C' -->
                            <li :class="{
                                created: (group.user_id > 0 && group.appr_state == 'A' && (group.type == 'GROUP' ? is_group_permmision(group.group_id, 'group_info', 'delete') : is_team_permmision(group.team_id, 'team_info', 'delete')) ),
                                signedUp : (group.user_id > 0 && group.appr_state == 'A' && (group.type == 'GROUP' ? !is_group_permmision(group.group_id, 'group_info', 'delete') : !is_team_permmision(group.team_id, 'team_info', 'delete')) ), 
                                waiting : (group.user_id > 0 && (group.appr_state == 'R' || group.appr_state == 'E')),
                                notYet : (group.user_id == 0),
                                hcList : isHoduC(group.group_type),
								hdList : isHoduD(group.group_type)
                                }" v-bind:key="`${group.group_id}-${group.team_id}`" v-for="group in computedGroupList"> <!-- 비즈 그룹을 우선순위로 보여주는 로직 때문에 computed로 변경 -->
<!--*********** 프리미엄(비즈)일시에만 SHOW, 리스트에 따른 밸류값 변경 (HODU C, HODU DOC)  -->
                                
                                <div class="bg"></div>
                                <a>
                                    <p class="premiumTag" style="transition:0.5s;" :class="is_list ? hodu_color_dc_lc(group.user_group_color) : ''" v-if="(group.biz_id != null && group.biz_id.length > 0)" :style="group.favorite > 0 ? 'background-color : #fff !important;' : ''">HODU C</p>
									<!-- <span class="num" style="display:;none">{{ index + 1 }}</span> -->
									<!--즐겨찾기등록--->
									<span class="faved_new" :class="{on: group.favorite > 0, off: group.favorite == 0}" title="즐겨찾기" v-if="group.is_group_apply" style="transition:0.5s;" :style="{ backgroundColor: `${group.user_group_color} !important` }" >즐겨찾기</span>
                                    
									<!---<span class="cl dc4" :style="{ backgroundColor: `${group.user_group_color} !important` }">색상</span>---이전-->
									<!---즐겨찾기 미등록--->
									<span class="bookmark" style="transition:0.5s;" @click="group_favorite($event, group)" v-if="group.is_group_apply" :style="{ backgroundColor: `${group.user_group_color} !important` }">색상</span>

                                    <span class="img" :class="`${group.group_info.image ? '' : `img${group_color_to_class_name(group.user_group_color)}`}`" :style="getGroupImageUrlStyle(group)">그룹 이미지</span>
                                    <img :src="'app_images/' + group.group_info.image" style="display : none;" v-if="group.group_info.image" @error="groupImageError($event, group)">
									<span class="placeNm " style="">{{ group.type == 'GROUP' ? group.group_info.group_name : group.group_info.team_name }}</span>
									<span class="manager king" :class="{ off : isAlarmOff(group) == true }">
										{{ group.type == 'GROUP' ? get_group_role(group.group_id) == GroupAuth.ADMIN ? '최종관리자' : get_group_role(group.group_id) == GroupAuth.GROUP_MANAGER ? '관리자' 
																									                             : (get_group_role(group.group_id) == GroupAuth.GROUP_SUB_MANAGER ? '부관리자' : '멤버')
																   : get_team_role(group.team_id) == GroupAuth.ADMIN ? '최종관리자' : get_team_role(group.team_id) == GroupAuth.TEAM_MANAGER ? '팀장' : '팀원' }}
									</span>
									<span class="manager_text king">
										{{ group.type == 'GROUP' ? get_group_role(group.group_id) == GroupAuth.ADMIN ? '최종관리자' : get_group_role(group.group_id) == GroupAuth.GROUP_MANAGER ? '관리자' 
																							                                     : (get_group_role(group.group_id) == GroupAuth.GROUP_SUB_MANAGER ? '부관리자' : '멤버')
																   : get_team_role(group.team_id) == GroupAuth.ADMIN ? '최종관리자' : get_team_role(group.team_id) == GroupAuth.TEAM_MANAGER ? '팀장' : '팀원'	}}
									</span>
                                </a>

                                <a class="seeDets" v-if="group.user_id > 0 && group.appr_state == 'A'" @click="groupDetailGo(group)">{{ group.type == 'GROUP' ? '그룹' : '팀' }}가기</a>

                                <div class="before" :class="{ refused : group.appr_state == 'E' }" v-if="group.user_id > 0 && (group.appr_state == 'R' || group.appr_state == 'E')">
                                    <span class="descript">{{ group.group_info.description }}</span>
                                    <input class="yes" :value="group.appr_state == 'E' ? '요청 거절 됨' : '요청 중'" />
                                    <span class="date" @click="openModal(group)">가입 요청일 <em>{{ group.group_member_created }}</em></span>
                                </div>

                                <div class="before" v-if="group.user_id == 0">
                                    <span class="descript">{{ group.group_info.description }}</span>
                                    <input type="button" class="yes" value="가입요청"  @click="openModal(group)"/>
                                </div>
                            </li>
                            <!-- 리스트 부분 종료 -->
                        </ul>
                    </div>	
                </div>
            </div>
        </div>
    </div> 
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { GROUP_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupModalInfo, GroupListConfirmModalData } from '@/store/modules/ModalInfo';
const GroupInfo  = namespace('GroupInfo');
const ModalInfo  = namespace('ModalInfo');
const dateFormat = require('dateformat');

import GroupListConfirmModal from '@/components/GroupListConfirmModal.vue';
import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component ({
    components : {
        GroupListConfirmModal, ResizeObserver
    }
})
export default class GroupList extends Mixins(VueHoduCommon) {
	/**
	 * GroupInfo.State
	 */
	@GroupInfo.State list_type !: number;

	/**
	 * ModalInfo.State
	 */
	@ModalInfo.State group_modal_info !: GroupModalInfo;
	@ModalInfo.Action doSetHomeResidentJoinModalInfo ?: (params : hodu_home_modal_info.HomeResidentJoinModalInfo) => void;
	
	/**
	 * ModalInfo.Action
	 */
	@ModalInfo.Action doSetGroupModalInfo ?: any;
	
	/**
	 * GroupInfo.Action
	 */
	@GroupInfo.Action doGroupListType ?: any;

    /**
     * computed
     */
    get computedGroupList() : any[] {

        let group_array : any[] = JSON.parse(JSON.stringify(this.group_list_data));
		
		// 키즈가 아닌것 || 키즈이면서 가입 된 것
		group_array = group_array.filter(item => item.group_info.project_type != 'kids' || (item.group_info.project_type == 'kids' && item.appr_state == 'A'));

        // 미니 리스트일때는 정렬 조건에 따라서 보여준다
        if( this.group_sort_target != null ) {
            group_array.sort((obj1 : any, obj2 : any) : number => {
                // 이름 오름차순
                if( this.group_sort_target == "NAME" && this.group_sort_direction == "ASC" ) {
                    if( obj1.group_info.group_name  > obj2.group_info.group_name ) { return  1; } 
                    if( obj1.group_info.group_name  < obj2.group_info.group_name ) { return -1; } 
                    if( obj1.group_info.group_name == obj2.group_info.group_name ) { return  0; }
                }

                // 이름 내림차순
                if( this.group_sort_target == "NAME" && this.group_sort_direction == "DESC" ) {
                    if( obj1.group_info.group_name  > obj2.group_info.group_name ) { return -1; } 
                    if( obj1.group_info.group_name  < obj2.group_info.group_name ) { return  1; } 
                    if( obj1.group_info.group_name == obj2.group_info.group_name ) { return  0; }
                }

                return 0;
            })
        } 

        // 전체 리스트 조회일땐 프리미엄을 먼저 보여준다!
        if( this.list_type == 9 ) {
            return group_array.sort((obj1, obj2) : number => {
                if( obj1.biz_id != null && obj1.biz_id.length > 0 && (obj2.biz_id == null || obj2.biz_id.length < 1) ) {
                    return -1;
                } 
                
                else if( (obj1.biz_id == null || obj1.biz_id.length < 1) && obj2.biz_id != null && obj2.biz_id.length > 0 ) {
                    return 1;
                }

                else {
                    return 0;
                }

            });
        }

        return group_array;
    }

	/**
	 * 변수 선언부
	 */
    group_list_data : any[] = [];
    group_sort_target : string | null = null;
	group_sort_direction : string = "ASC";
	
	is_list : boolean = false;

	dummy_image = "@/assets/images/footer/g1.jpg";

	async beforeMount() {
		await this.reset_biz_initialization();
		await this.reset_group_initialization();
	}

    async mounted() {
		const vue = this;

		if ( !this.list_type ) {
			this.doGroupListType(1);
		}

		// 검색 엔터키
		$('#group_search').keydown(function(key) {
			if ( key.keyCode == 13 ) {
				vue.getGroupList();
			}
		});

		// 검색 버튼 클릭시 searchDiv 띄우기
        $("#section_ce .schBtn").click(function(){
            $("#section_ce .searchDiv").addClass("on");
			vue.$nextTick(() => $('#group_search').focus());
        });

        // 검색 닫기 버튼 클릭시 searchDiv 없애기
        $("#section_ce .searchDiv .closeBtn").click(function(){
			$("#section_ce .searchDiv").removeClass("on");
			$('#group_search').val("");
			vue.getGroupList();
		});
		
        this.setScroll();
        
        /* 화살표 클릭시 팀 보여주기 */
        $(".selectTitle").click(function(){
            $(this).siblings().toggleClass("on");
        });

        // 소팅헤더 클릭시ㅣ 드랍다운 보이기
		$(".sortHeader > li > a").click(function(){
			$(this).siblings('.dropdown').toggleClass("on");
        });
        
        // 오른쪽 디스플레이 버튼
		$(".btns .dpTwo").click(function(){
			$(".section_ce_fix").removeClass("dpOne");
			$(".section_ce_fix").addClass("dpTwo");
			vue.is_list = true;
		});
		$(".btns .dpOne").click(function(){
			$(".section_ce_fix").removeClass("dpTwo");
            $(".section_ce_fix").addClass("dpOne");
			vue.group_sort_target = null;
			vue.is_list = false;
		});

        this.getGroupList();

        window["getGroupList"]           = this.getGroupList;
        window["groupListConfirmCancel"] = this.groupListConfirmCancel;
        window["groupListConfirm"]       = this.groupListConfirm;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();

        // @ts-ignore
        $('#grpScroll').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight ),
            scrollbarPosition : 'outside',
        });

        // @ts-ignore
        $(".content").mCustomScrollbar({
			mouseWheelPixels: 500
        });
    }

    /**
     * 모달창 아니오
     */
    groupListConfirmCancel() : void {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 모달창 예
     */
    groupListConfirm(po_data) : void {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;

        // 가입
        if ( po_data.user_id == 0 || po_data.appr_state == "" ) {
			this.hodu_api_call(`api/v1/groups/groupMemberInsert/${po_data.group_id}`, API_METHOD.POST, {is_appr_required: po_data.is_appr_required})
                .then(async(response) => {
                    this.getGroupList();
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        // 승인 취소
        if ( po_data.user_id > 0 && (po_data.appr_state == "R" || po_data.appr_state == "E" || po_data.appr_state == "T") ) {
			this.hodu_api_call(`api/v1/groups/groupMemberDelete/${po_data.group_id}/${this.user_id}`, API_METHOD.DELETE, null)
                .then(async(response) => {
                    this.getGroupList();
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 모달 OPEN
     */
    openModal(group) : void {

		if( group.group_type == 'BIZH' ) {
			this.homeJoin(group);
			return;
		}

        let param_data : GroupListConfirmModalData = {
            modal_type       : "join_cancel",
            type             : "GROUP",
            group_id         : group.group_id,
            team_id          : 0,
            color            : group.user_group_color,
            image            : group.group_info.image,
            description      : group.group_info.description,
            name             : group.group_info.group_name,
            user_id          : group.user_id,
            is_appr_required : group.group_policy.is_appr_required,
            appr_state       : group.appr_state,
            member_created   : group.group_member_created
        }

        this.group_modal_info.show_group_list_confirm = true;
        this.group_modal_info.data = param_data;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 그룹 조회
     */
    async getGroupList() : Promise<void> {
        const vue = this;

        this.group_list_data.splice(0, this.group_list_data.length);

        // 파라미터 변수
        let   search            = String($('#group_search').val());
        const sort              = "CA";   // CA : 등록일 오름차순, CD : 등록일 내림차순, NA : 그룹명 오름차순, ND : 그룹명 내림차순
		const favorite_group_id = [];
		
		if ( search.trim().length < 1 ) {
			search = "";
		}

		await this.hodu_api_call(`api/v1/groups?list_type=${this.list_type}&search=${search}&sort=${sort}&favorite_group_id=${favorite_group_id}`, API_METHOD.GET, null, false)
			.then(async(response) => {

				await this.get_group_role_service();
				this.group_list_data.splice(0, this.group_list_data.length);
				this.group_list_data = this.group_list_data.concat(response.data.data.list);

				const group_count : number = this.group_list_data.length
				for( let i = group_count - 1; i >= 0; i-- ) {
					const data : any = JSON.parse(JSON.stringify(this.group_list_data[i]));

					if ( data.user_group_color == null || data.user_group_color.length < 1 ) {
						data.user_group_color = data.type == 'GROUP' ? data.group_info.group_color : data.group_info.team_color;
					}

					// BIZD 그룹 일 경우 그룹 리스트에서 보이지 않는다
					if( data.group_type == GROUP_TYPE.BIZD ) {
						this.group_list_data.splice(i, 1);
						continue; 

						// 해당 부분은 의료진은 볼수있도록 한 부분인데 HODU D는 전용 로그인 페이지로만 들어갈수있도록 바뀌어야하기에 숨기도록 변경
						// const role_array : any[] = this.user_group_role.filter(item => item.group_id == data.group_id);
						
						// if( role_array == null || role_array.length < 1 ) {
						// 	this.group_list_data.splice(i, 1); 
						// 	continue; 
						// }

						// if( role_array[0].group_role == this.GroupAuth.GROUP_USER ) {
						// 	this.group_list_data.splice(i, 1);
						// 	continue;
						// }

					}

					data.user_group_color = await vue.hodu_hex_color_process(data.user_group_color);
					data.group_member_created = dateFormat(data.group_member_created, 'yyyy.mm.dd');
					vue.group_list_data.splice(i, 1, data);
				}

			})
			.catch(async(e) => {
				this.hodu_error_process(e, true, false);
			});
    }

    /**
     * 그룹이동
     */
    groupDetailGo(data) : void {

		console.log(data);

		// 인증 안된 상태인데 호두홈, 호두키즈로 이동한다면 인증절차
        if( (this.auth_info == null || this.auth_info.auth_check == false) && (data.group_type == 'BIZH' || data.group_info.project_type == 'kids') ) {
            try {
                let popup : Window | null = null;

                // window['skip_button_select'] = false;

                window['auth_callback'] = (user_info) => {
                    try {
						this.doSetAuthInfo(user_info.auth_info);
						this.doSetUserPhoneNumber(user_info.user_phone_number);
						this.doSetCountryCode(user_info.country_code);
						this.groupDetailGo(data);

                        // console.log(authResult);
                        // this.firebase_auth(authResult, auth_check_later, (user_info) => {
                        //     try {
                        //         console.log(user_info);
                        //         this.doSetAuthInfo(user_info.auth_info);
                        //         this.doSetUserPhoneNumber(user_info.user_phone_number);
                        //         this.doSetCountryCode(user_info.country_code);

                        //         if( auth_check_later == true ) {
                        //             return;
                        //         }

                        //         this.groupDetailGo(data);
                        //     } catch(e) {
                        //         this.hodu_error_process(e, false, false, true);
                        //     }
                        // });
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    } finally {
                        popup?.close();
                    }
                }

                const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                const systemZoom = width / window.screen.availWidth;

                const w = 500;
                const h = 626;
                const l = (width - w) / 2 / systemZoom + dualScreenLeft;
                const t = (height - h) / 2 / systemZoom + dualScreenTop;

                popup = window.open(`/naver-auth.html?device_uid=${this.get_device_uid()}&session_token=${this.get_session_token()}`, `naver-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
            return;
        }

        this.doGroupStatisticsStartDate("");
		this.doGroupId(data.group_id);
        this.doTeamId(data.team_id);
        this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
    }

    /**
     * 그룹생성
     */
    makeGroup() : void {
        this.reset_group_initialization();
        this.hodu_router_push(`/group/${new Date().getTime()}/create`);
	}
	
	/**
	 * 탭 변경
	 */
	listTypeChange(val) : void {
		this.doGroupListType(val);
		this.getGroupList();
		$(".selectTitle").siblings().toggleClass("on");
    }
    
    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#grpScroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $(".content").mCustomScrollbar('destroy');

    	this.setScroll();
	}
	
	/**
	 * 즐겨찾기 변경
	 */
	async group_favorite(po_event, po_data) : Promise<void> {

		if( po_data.type == 'GROUP' ) {
			this.hodu_api_call(`api/v1/groupFavorite/${po_data.group_id}`, API_METHOD.POST, {}, false)
				.then(async(response) => {
					// // 즐겨찾기 에서는 재조회
					// if ( this.list_type == 3 ) {
					// 	this.getGroupList();
					// }
					// else {
					// 	po_data.favorite = (po_data.favorite > 0 ? 0 : 1);
					// }
					this.getGroupList();
				})
				.catch(async(e) => {
					this.hodu_error_process(e, true, false);
				});
		}
		else {
			try {
				const response = await this.hodu_api_call(`api/v1/teams/favorite/${po_data.group_id}/${po_data.team_id}`, API_METHOD.POST, {}, false);

				console.log(response);

				if( !response || !this.isHttpStatusSuccess(response.status) ) {
					throw new Error("팀 즐겨찾기 변경 중 오류 발생");
				}

			} catch(e) {
				this.hodu_error_process(e, false, false, true);
				this.hodu_show_dialog('cancel', "즐겨찾기 변경 중 오류 발생", ['확인']);
			} finally {
				this.getGroupList();
			}
		}

		
    }

    /**
     * 미니 리스트에서 그룹 이름으로 sort
     */
    groupNameSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.group_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.group_sort_direction ) {
                case "ASC":
                    this.group_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.group_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.group_sort_target = "NAME";
            this.group_sort_direction = "ASC";
        }
        
	}

	/**
	 * 그룹 li span.img 의 이미지 URL
	 */
	getGroupImageUrlStyle(data : any) : string {
		if( !data.group_info.image ) { return ''; }

		return `backgroundImage : url('app_images/${data.group_info.image}')`;
	}
	
	/**
	 * 그룹 이미지 에러
	 */
	groupImageError(event : any, data : any) : void {
		$(event.target).parent().find('span.img').removeAttr("style");
		$(event.target).parent().find('span.img').addClass(`img${this.group_color_to_class_name(data.user_group_color)}`);
	}

	/**
	 * 알람 ON, OFF 여부 반환 
	 */
	isAlarmOff(data) : boolean {
		const group_id = data.group_id;
		const team_id = data.team_id;

		if( this.user_preference == null ) return false;
		
		for( const preference of this.user_preference ) {
			if( data.type == 'GROUP' && preference.cate != 'group_alarm' ) continue;
			if( data.type == 'TEAM' && preference.cate != 'team_alarm' ) continue;
			if( preference.preference == null || preference.preference[`${data.type == 'GROUP' ? group_id : team_id }`] == null ) return false;
			return preference.preference[`${data.type == 'GROUP' ? group_id : team_id }`] == 'false';
		}

		return false;
	}

	/**
	 * 병원 리스트로 이동
	 */
	moveHospitalList() : void {
		this.hodu_router_push("/hospital");
	}

	/**
	 * 이미지 로드
	 */
	// onLoadDummyImage(event) : void {
	// 	console.log("이미지 로드");
	// 	console.log(event);
	// 	$(event.target).parent().find('span.img').css('background-image', `url('${$(event.target)[0].currentSrc}')`)
	// }

	homeJoin(group) : void {

		// 인증 안된 상태인데 호두홈, 호두키즈로 이동한다면 인증절차
        if( (this.auth_info == null || this.auth_info.auth_check == false) && (group.group_type == 'BIZH' || group.group_info.project_type == 'kids') ) {
            try {
                let popup : Window | null = null;

                window['skip_button_select'] = false;

                window['auth_callback'] = (user_info) => {
                    try {
						this.doSetAuthInfo(user_info.auth_info);
						this.doSetUserPhoneNumber(user_info.user_phone_number);
						this.doSetCountryCode(user_info.country_code);

						this.doSetHomeResidentJoinModalInfo?.({
							show_modal : true,
							group_id : group.group_id,
							is_invite : false,
						});

                        // console.log(authResult);
                        // this.firebase_auth(authResult, auth_check_later, (user_info) => {
                        //     try {
                        //         console.log(user_info);
                        //         this.doSetAuthInfo(user_info.auth_info);
                        //         this.doSetUserPhoneNumber(user_info.user_phone_number);
                        //         this.doSetCountryCode(user_info.country_code);

                        //         if( auth_check_later == true ) {
                        //             return;
                        //         }

                        //         this.doSetHomeResidentJoinModalInfo?.({
						// 			show_modal : true,
						// 			group_id : group.group_id,
						// 			is_invite : false,
						// 		    });

                        //     } catch(e) {
                        //         this.hodu_error_process(e, false, false, true);
                        //     }
                        // });
						
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    } finally {
                        popup?.close();
                    }
                }

                const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                const systemZoom = width / window.screen.availWidth;

                const w = 500;
                const h = 626;
                const l = (width - w) / 2 / systemZoom + dualScreenLeft;
                const t = (height - h) / 2 / systemZoom + dualScreenTop;

                popup = window.open(`/naver-auth.html?device_uid=${this.get_device_uid()}&session_token=${this.get_session_token()}`, `naver-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
            return;
        }

		this.doSetHomeResidentJoinModalInfo?.({
			show_modal : true,
			group_id : group.group_id,
			is_invite : false
		});
	}

}
</script>

<style scoped>
	/* 그룹 */
	#grpScroll .grp.settingBg h3.selectTitle { cursor:pointer; }
	#grpScroll .grp.settingBg h3.selectTitle .txt {    background: url(../assets/images/side_area/bt_open.png) no-repeat center right;padding-right: 35px !important;}
	#grpScroll .settingBg ul.dropdown {width: 170px;left: 29px;top: 60px;}
	#grpScroll .mCSB_inside > .mCSB_container  { margin-right:0; }
	#container #section_ce #grpScroll .schedule_box { padding-left:0;padding-right:0; }
	a#makeGroup { cursor:pointer !important; }
	.grpUserList a.seeDets {transition:0.2s;position: absolute;top: 70px;display: none;left: 50%;width: 140px;margin-left: -70px;height:38px;line-height: 38px; border:2px solid #e7e9ea; border-radius: 25px;text-align: center;background: #fff;font-size: 14px;font-weight: bold;color: #477fff;}
	.dpTwo li:hover > .seeDets {display: block; right:20px;top:16px;bottom:0;border:2px solid #477fff;margin-left:0 !important;left:auto !important;position: absolute; background:#477fff url(../assets/images/side_area/bt_more_fff.png) no-repeat 13px 11px !important;font-size:0;width: 30px;transition: 0.2s;font-size: 0;background-size: 8px !important;height: 30px !important;}
	.grpUserList li:hover a.seeDets {display: block;}
	.grpUserList li:hover a.seeDets:hover { background:#477fff;color:#fff;border:2px solid #477fff; }
	.fave_border { opacity:0.8 !important; box-shadow: 0 0 0 rgba(0,0,0,0.3) !important; }
	.fave_border_on { border:1px solid #ffa70e !important; box-shadow: 0 13px 30px rgba(255, 167, 14,0.5) !important; }
	.section_ce_fix.grpSection.dpOne .dpOne {display: none;}
	.section_ce_fix.grpSection.dpTwo .dpTwo {display: none;}
	.grpSection .btns {float: right;}
	.grpSection .btns input {margin-top: -5px;}
	.btns .dpTwo {width: 40px;height: 40px;background: url(../assets/images/contents/ic_dpTwo.png) no-repeat center center;font-size: 0;}
	.btns .dpOne {width: 40px;height: 40px;background: url(../assets/images/contents/ic_dpOne.png) no-repeat center center;font-size: 0;}
	.grpUserList > li > a {cursor: default;}
	.grpUserList > li > a.seeDets {cursor: pointer;}
	ul.grpUserList li.waiting .before {display: block;background: rgba(255,255,255, 0.5);height: 100%;}
	ul.grpUserList li.waiting .before:hover {background: rgba(255,255,255, 0.8);}
	ul.grpUserList li.waiting:hover .before {height: 180px;background: #ffffff99;}
	ul.grpUserList li.waiting:hover .before span {height:auto;color: #000;}
	ul.grpUserList li.waiting:hover .before span.line {height:1px;background: #000;}
	ul.grpUserList li.waiting:hover .before input {bottom: 24px; }
	ul.grpUserList li.waiting .before span.date {font-size: 0;height: 0;width: 0;padding: 0;position: absolute;bottom: 24px;}
	ul.grpUserList li.waiting:hover input {display:none}
	ul.grpUserList li.waiting:hover > .before > span.date {color: #18284e;cursor: pointer;width: 80%;display: inline-block;line-height: 40px;text-align: center;font-size: 12px;font-weight: bold;border-radius: 25px;margin: 0 12px;background: #fff;/* border: 1px solid #f1f3f5; */position: absolute;height: 40px;bottom: 24px;left: 50%;margin-left: -107px;}
	ul.grpUserList li.waiting:hover > .before > span.date:hover :after {content: '가입요청 취소';display: block;position: absolute;top: 0;color: #fff;background: #ff5555;width: 100%;height: 100%;border-radius: 50px;}
	ul.grpUserList li.waiting:hover span.date em {color: #18284e;}
	ul.grpUserList li.waiting {opacity: 1;}
	ul.grpUserList li ul {display: none;}
	ul.grpUserList li.waiting .before span {height:0;}
	ul.grpUserList li.waiting .before input {bottom: auto;bottom:50%;box-shadow: none;bottom: auto;top: 0;width: 100%;left: 0;margin-left: 0;border-radius: 5px 5px 0 0;border: 0;height: 25px;line-height: 25px;font-size: 12px;}
	ul.grpUserList li {width: 268px;height: 254px;float: left;display: inline-block;margin: 0 20px;border: 0;border-radius: 5px;transition: 0.2s;margin-bottom: 30px;margin-top: 10px;position: relative;opacity: 0.6;}
	.grpUserList span.placeNm {position: absolute;width: 248px;background: transparent;display: block;left: 6px;height: auto;overflow: hidden;font-size: 18px;white-space: nowrap;text-overflow: ellipsis;font-weight: bold;padding: 20px 40px 15px 0;/* border-bottom: 1px solid #f1f3f5; */bottom: 33px;border-radius: 0 0 4px 4px;box-sizing: border-box;}
	ul.grpUserList li .img {width: 100%;background: url(../assets/images/contents/groupImg.png) no-repeat center center;display: block;background-size: cover;font-size: 0;height: 167.5px;border-radius: 5px;}
	#grpScroll .content {padding-top: 0;margin-top: 0 !important; margin-bottom:150px;clear: both;float: left;}
	ul.grpUserList {float: left;padding: 30px 20px; padding-bottom: 150px !important;}
	ul.grpUserList li.add:hover { box-shadow:none; }
	ul.grpUserList li:hover {transition: 0.2s;opacity: 1;}
	/*ul.grpUserList li.signedUp:hover, ul.grpUserList li.created:hover {box-shadow: 0 10px 25px rgba(0,0,0,0.1);}*/
	.grpUserList span.cl {width: 100%;height: 10px;border-radius: 4px 4px 0 0;font-size: 0;display: block;position: absolute;right: 20px;bottom: 21px;z-index: 2;width: 11px;height: 11px;border-radius: 50%;}
	.grpUserList span.pos {width: 37px;height: 37px;display: inline-block;background-size: 19px !important;font-size: 0;border-radius: 50%;position: absolute;right: 7px;bottom: 42px;display: none;}
	.grpUserList span.pos.king {background: #fff url(../assets/images/contents/ic_king.png) no-repeat center center;display: block;}
	.grpUserList span.pos.manager {background: #fff url(../assets/images/contents/ic_manager.png) no-repeat center center;display: block;}
	.grpUserList a {display: block;width: 100%;height: 100%;}
	.grpUserList li.add a {box-sizing: border-box; /*border: 2px dashed #e7e9ea;*/font-size: 20px;font-weight: bold;transition: 0.2s;color: #a7a7a7;border-radius: 5px;font-size: 21px;font-weight: bold;text-align: center;height: 170px;line-height: 170px;width: 100%;background: #f1f3f5;padding: 0;float: left;margin: 0;}
	.grpUserList li.add {background: #fff;border:0;box-sizing: border-box;opacity: 1;}
	.grpUserList li.add:hover a {background: #fff;color: #477fff;}
	span.faved_new {cursor:pointer;mask-image:url('../assets/images/contents/ic_bookmark_fill.svg');background: black;width: 40px;height: 40px; align-self:center;mask-size: 40px;font-size: 0;border-radius: 0;display: block;position: absolute;right: 11px;bottom: 96px;border:0;z-index: 3;}
	span.faved_new.off {font-size: 0;background: url(../assets/images/contents/faveStar_off.png) no-repeat center center;background-size: 22px;transition: 0.2s;opacity: 0;}
	/*span.faved:hover {box-shadow:0 3px 5px rgba(0,0,0,0.1)}*/
	/* span.faved:hover {background: #fff url(../assets/images/contents/faveStar_off.png) no-repeat center center;background-size: 25px;transition: 0.2s;}
	span.faved.off:hover {background: #fff url(../assets/images/contents/faveStar.png) no-repeat center center;background-size: 25px;transition: 0.2s;opacity: 0;} */
	ul.grpUserList li.created:hover .faved.off, ul.grpUserList li.signedUp:hover .faved.off {opacity: 1;}
	ul.grpUserList li.created.on .faved.off, ul.grpUserList li.signedUp.on .faved.off { opacity:1; }
	#grpScroll h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
	#grpScroll .grp.settingBg {height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
	#grpScroll span.num {margin-left: 10px;color: #477fff;}
	#makeGrpScroll .createNewGroup {margin-left: -39px;}
	.settingBg .selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
	.btns.fr {margin-right: 30px;}
	.btns.fr .input_btn {margin: 11px 0 0 15px;}
	#makeGrpScroll .content {margin-top: 65px;}
	.grpUserList em {font-weight: bold;color: #477fff;font-style: normal;}
	.grpUserList span.people {font-weight: bold;position: absolute;bottom: 23px;left: 20px;}
	.grpUserList span.team {font-weight: bold;position: absolute;bottom: 23px;left: 135px;}
	.grpUserList li:hover input.del {display:none;}
	.grpUserList li.created:hover input.del, .grpUserList li.signedUp:hover input.del {display:block;}
	.grpUserList input.del {color:#fff;font-weight:bold;display:none;position: absolute;top: -15px;left: -15px;width: 35px;height: 35px;border-radius: 50%;font-size:0;position: absolute;background: #ff6363 url(../assets/images/contents/ic_delImg.png) no-repeat center center;background-size: 14px;z-index: 2;}
	.grpUserList li.signedUp input.del {font-size: 0;font-weight:bold;background: #445667;color:#fff;transition: 0.1s;background: #445667 url(../assets/images/contents/ic_delImg.png) no-repeat center center;background-size: 14px;}
	.grpUserList li.signedUp input.del:hover {background: #2f4254;font-size:12px;width: 70px;border-radius: 25px;transition: 0.1s;}
	.grpUserList li input.del:hover {background: #ff3636;font-size:12px;width: 70px;border-radius: 25px;transition: 0.1s;}
	ul.grpUserList li.created {opacity: 1;}
	ul.grpUserList li.signedUp {opacity: 1;}
	
	.grpUserList .before {display: none;position: absolute;top: 0;text-align: center;width: 100%;box-sizing: border-box;padding: 20px 30px 84px;height: 170px;background: rgba(24, 36, 64, 0.5);border-radius: 5px 5px 0 0;z-index: 3;}
	.grpUserList li:hover .before {display: block;}
	.grpUserList .before .title {font-size: 18px;font-weight: bold;display: block;overflow: hidden;margin-bottom: 17px;text-align: left;text-overflow: ellipsis;white-space: nowrap;}
	.grpUserList .before .descript {font-size: 13px;font-weight: bold;overflow: hidden;line-height: 22px;margin-bottom: 20px;text-overflow: ellipsis;display: block;text-align: left;/* height: 50px; */max-height: 69px;}
	.grpUserList .before span {font-size: 14px;font-weight: bold;clear: both;float: left;width: 100%;color: #fff;margin-bottom: 30px;}
	.grpUserList .before span.line {width: 100%;display: block;height: 1px;background: #fff;opacity: 0.2;margin-bottom: 15px;}
	.grpUserList .before input {cursor:pointer;width: 50%; border:1px solid #f1f3f5;/* float: left; */display: inline-block;line-height: 35px;background: none;text-align: center;font-size: 14px;font-weight: bold;border-radius: 25px;margin: 0 12px;background: #f1f3f5;}
	.grpUserList .before input.yes {background:#fff;border:1px solid #f1f3f5;color:#477fff;position: absolute;bottom: 24px;left: 50%;margin-left: -63px;transition: 0.1s;}
	.grpUserList .before input.yes:hover {background: #477fff;border-color: #477fff;color: #ffffff;transition: 0.1s;box-shadow: 0 5px 10px rgba(107, 153, 255, 0.5);}
	.grpUserList span.date {display: none;}
	.grpUserList li.waiting .before input {background: #ffa70e;color: #fff;border-color: #ffa70e;cursor: default;}
	.grpUserList li.waiting .before input:hover {background: #ffa70e;color: #fff;border-color: #ffa70e;box-shadow: none;}
	.grpUserList li.waiting .before.refused input {background: #ff6363; border-color: #ff6363;cursor: default;}
	.grpUserList li.waiting .before.refused input:hover {background: #ff6363; border-color: #ff6363;box-shadow: none;}
	ul.grpUserList li span.num { display:none; }
	li.withTeams .bg { display:none; }
	
	/* dpOne 디스플레이 */
	.dpOne .sortHeader > li.headerSort {background: none;}
	.dpOne ul.sortHeader .people, .dpOne ul.sortHeader .placeNm, .dpOne ul.sortHeader .team, .dpOne ul.sortHeader .status  { display:none }
	.dpOne .sortHeader > li {margin-left: 30px !important;}
	.dpOne .sortHeader > li:hover > a.sort {padding-left: 0;}
	.dpOne .sortHeader > li:hover a {box-shadow: none !important;cursor:default}
	.dpOne ul.grpUserList li.add a{border: 2px dashed #e7e9ea;}
	.dpOne ul.sortHeader {height: 60px;position: fixed;z-index: 1000000;background: none;right: 90px;border-bottom: 0;}
	.dpOne ul.sortHeader .dropdown {position: absolute;display: none;}
	.dpOne ul.sortHeader .num {display: none;}
	.dpOne li.notYet.withTeams.on > .before, .dpOne li.waiting.withTeams.on > .before {display: none;}
	.dpOne li.withTeams:hover > a > .team em, .dpOne li.created > a > .people:hover em, .dpOne li.signedUp > a > .people:hover em {display: inline-block;border: 2px solid #477fff;margin-left:0px;line-height: 25px;background: #fff;height: 25px;border-radius: 5px;position: absolute;width: 50px;text-align: center;left: 0;bottom: -9px;transition: 0.1s;}
	.dpOne li.withTeams:hover > a > .team em:hover, .dpOne li.created:hover > a > .people em:hover, .dpOne li.signedUp:hover > a > .people em:hover {cursor:pointer;background: #477fff;color: #fff;}
	.dpOne li.withTeams.on .bg {cursor:pointer;display:block;width:100%;height:100%;position:absolute;top:0;left:0;background: rgba(24, 36, 64, 0.5) url(../assets/images/contents/ic_close_fff.gif) no-repeat center 20px;z-index:100;border-radius: 5px;}
	.dpOne li.withTeams.on .bg:hover {transition:0.2s;background: rgba(24, 36, 64, 0.7) url(../assets/images/contents/ic_close_fff.gif) no-repeat center 20px;}
	.dpOne li.withTeams.on:hover > a > .del {display:none;}
	.dpOne li.withTeams.on ul {display: block;position: absolute;bottom: 0;left: 0;width: 100% !important;max-height: 203px !important;min-height: 100px;overflow-y: auto;background:#fff;z-index:1000000000000000;border-radius: 0 0 5px 5px;}
	.dpOne li.withTeams.on ul li {margin: 0;height:50px;overflow:hidden;border-radius:0;width: 100%;border: 0;border-top: 1px solid #f1f3f5;}
	.dpOne li.withTeams.on ul li span { font-size:12px; position:static; float:left; width:auto; }
	.dpOne li.withTeams.on ul li span.cl {width:7px;height:7px;font-size:0;margin: 22px 10px 0 15px;}
	.dpOne li.withTeams.on ul li input { position:static; }
	.dpOne li.withTeams.on ul li .img { display:none; }
	.dpOne li.withTeams.on ul li span.placeNm {width: 170px;padding-right: 10px;background:none;}
	.dpOne li.withTeams.on ul li span.people {display:none;}
	.dpOne li.withTeams.on ul li span.team {display: none;}
	.dpOne li.withTeams.on ul li input {right: 0;width: 70px;height: 50px;display: block;border-radius: 0;font-size: 12px;color: #000;background: none;left: auto;top: 0;position: absolute;}
	.dpOne li.withTeams.on ul li:hover input {/* display: block !important; */background: none;color: #000;}
	.dpOne li.withTeams.on ul li:hover input.del {color: #ff5f5f !important;width: 70px;}
	.dpOne li.withTeams.on ul li input.del.up {color: #477fff !important;width: 70px;}
	.dpOne li.withTeams.on ul li.waiting, .dpOne li.withTeams.on ul li.waiting:hover a {background: #fffaf0 !important;}
	.dpOne li.withTeams.on ul li.waiting input.del {display: none;}
	.dpOne li.withTeams.on ul li.waiting .before {background: none;height: 50px;}
	.dpOne li.withTeams.on ul li.waiting .before .descript {display: none;}
	.dpOne li.withTeams.on ul li input.yes {margin-right: 0;color: #ffa70e;display: none;}
	.dpOne li.withTeams.on ul li.waiting:hover span.date:hover after { content:'ì·¨ì†Œ'; display:block; position:absolute;width:100%; height:100%; top:0; left:0;background:red; }
	.dpOne ul.grpUserList li li.waiting:hover .before span.date:after {display: block;content: '취소';position: absolute;width: 70px;right: 0;top: 0;margin-bottom: 0;font-size: 12px;height: 30px;}
	.dpOne ul.grpUserList li li.waiting:hover > .before > span.date {position: absolute;top: 6px;font-size: 0;width: 70px;text-align: center;right: 0;color: #ff5b5b;margin: 0;left: auto;background: #fffaf0;}
	.dpOne ul.grpUserList li li.waiting:hover > .before > span.date:hover :after {background: none !important;}
	.dpOne li.withTeams.on ul li {/* color: #000; */opacity: 1;}
	.dpOne li.withTeams.on ul li.notYet {opacity: 0.6;}
	.dpOne li.created.withTeams.on ul li:hover a, .dpOne li.signedUp.withTeams.on ul li:hover a {background: #f1f3f5;}
	.dpOne li.created.withTeams.on ul li.notYet:hover a, .dpOne li.signedUp.withTeams.on ul li.notYet:hover a {background: #fff !important;cursor: default;}
	.dpOne li.created.withTeams.on ul li.notYet:hover, .dpOne li.signedUp.withTeams.on ul li.notYet:hover {opacity: 1;}
	.dpOne li.withTeams.on ul li.notYet:hover {opacity: 1;}
	.dpOne li.withTeams.on ul li.waiting:before {content: '';display: block;position: absolute;left: 0;top: 0;height: 100%;width: 3px;background: #ffa70e;}
	.dpOne li.withTeams.on ul li:first-child {border-top: 0 none;}
	.dpOne .grpUserList em { margin-left:5px; }
	.dpOne li.withTeams.on ul li.waiting input.yes {display: block;}
	.dpOne ul.grpUserList li li .before  {display:none}
	.dpOne ul.grpUserList li li.waiting .before { display:block }
	.dpOne ul.grpUserList li li > a > span.faved {display:none;}
	.dpOne .faved_new {font-size: 0;}
	.dpOne ul.sortHeader .dropdown {width: 140px !important;min-width: 140px !important;padding: 0 !important;left: auto;right: 0px;}
	.dpOne ul.sortHeader .dropdown li {padding-left: 0;padding-right: 0;border: 0;}
	
	
	/* dpTwo 디스플레이 */
	.dpTwo ul.grpUserList li.created:hover > a > .faved, .dpTwo ul.grpUserList li.signedUp:hover > a > .faved {width:30px; height:30px; background-color:#fff; margin-top:-5px;transition:0.2s;}
	.dpTwo .fave_border { opacity:0.7 !important; }
	.dpTwo .fave_border_on { border:0 none !important; border-top:1px solid #f1f3f5 !important;}
	.dpTwo .fave_border_on:after { z-index:10000; content:''; display:block; /* background: rgba(255, 167, 14, 0.1); */ border-top:1px solid #FFA70E;box-sizing:border-box;border-bottom:1px solid #FFA70E;width:100%; height:100%; position:absolute; left:0; top:0; }
	.dpTwo .sortHeader, .dpTwo .sortHeader li { transition:0s !important; }
	.dpTwo .sortHeader > li > a, .dpTwo .sortHeader > li > span  { height:65px;line-height:65px;font-weight:bold; }
	.dpTwo .sortHeader > li.num  { text-align:center; }
	.dpTwo .sortHeader > li.num > span { display:inline-block; }
	.dpTwo .num {width: 10%;}
	.dpTwo .placeNm {width: 40%;}
	.dpTwo .people {width:12%}
	.dpTwo .team {width:12%}
	.dpTwo .pos {width:13%}
	.dpTwo .status {width: 13%;}

	.dpTwo .sortHeader .faved_new { text-align : center; /*width : 7%;*/ width:120px; }
	
	.dpTwo #grpScroll .content {margin-top: 0 !important;padding-top: 0;border-top:0 none;}
	.dpTwo ul.sortHeader {height: 60px;width: 100%;background: #f1f3f5;margin-top: 64px;transition: 0.1s;z-index: 1;min-width: 708px !important;position: fixed;top: 64px;width: 100% !important;margin-left: 0 !important;padding-left: 261px !important;padding-right: 60px;left: 0!important;height: 65px;box-sizing: border-box;}
	.section_ce_fix.dpTwo ul.sortHeader {position: static !important;margin-top: 0 !important;padding: 0 !important;}
	.dpTwo ul.sortHeader > li {float: left;position: relative;}
	.dpTwo ul.sortHeader .dropdown {display:none;position: absolute;left: 0;margin-left: -80px !important;}
	.dpTwo ul.sortHeader .dropdown {width: 140px !important;min-width: 140px !important;padding: 0 !important;}
	.dpTwo ul.sortHeader .dropdown li {padding-left: 0;padding-right: 0;border: 0;}
	#container #section_ce .dpTwo .schedule_box {padding-left: 0;padding-right: 0;}
	.dpTwo ul.grpUserList li {width:100%;line-height: 65px;margin: 0;height: 65px;transition: 0s;border:0 none;background: #ffffff;opacity: 1;border-top: 1px solid #f1f3f5;position: relative;}
	.dpTwo ul.grpUserList > li:last-child { border-bottom:1px solid #f1f3f5; }
	.dpTwo ul.grpUserList li.created, .dpTwo ul.grpUserList li.signedUp { border-top:1px solid #f1f3f5 }
	.dpTwo ul.grpUserList {margin-left: 0;transition: 0s;padding:0;}
	.dpTwo .content, .dpTwo .grpUserList {transition: 0s;width: 100%;}
	.dpTwo ul.grpUserList li .img {transition: 0s;width: 60px;height: 40px;border-radius: 5px;font-size: 0;position: absolute;margin-top: 12px;left: 45px;/*margin-left: 5.5%;*/margin-left: 88px;border: 1px solid #f1f3f5;top: 0px;}
	.dpTwo ul.grpUserList li > a > span {transition: 0s;position: static;float: left;padding: 0;color: #acacbb;font-size: 12px;}
	.dpTwo ul.grpUserList li > input {position: absolute;transition: 0s;float: right;margin: 0;top: 0;left: auto;right: 0;/* display: block; */}
	.dpTwo ul.grpUserList li.add {position: fixed;bottom: 50px;right: 100px;z-index: 10000;    width: 60px;height: 60px;line-height: 60px;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;border-radius: 50%;border:0 none;transition: 0s;background:none;}
    .dpTwo ul.grpUserList li.add a, .dpTwo ul.grpUserList li.add a:hover {color:#fff;transition: 0s;opacity: 1;z-index: 2;font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px;background: #477fff url(../assets/images/contents/btn_add_fff.png) no-repeat center center;background-size: 53px !important;}
	.dpTwo ul.grpUserList li > a > span.cl {font-size:0;width: 8px;height: 8px;margin-top: 23px;position: absolute;left: 0px;margin-left: 10.5%;z-index: 0;top: 6px;}
	.dpTwo ul.grpUserList li > a > span.pos {background: none;border: 0;width: 13%;font-weight:bold;line-height: 65px;height: 65px;display: block;}
	.dpTwo ul.grpUserList li > a > span.faved {position: absolute;left: 20px;top: 22px;font-size: 0;background-size: 20px;border: none;width: 20px;background-color: transparent;height: 20px;}
	.dpTwo ul.grpUserList li > a > span.faved_new {transition: none;position: absolute;left: 50px;top: 20px;font-size: 0;mask-repeat: no-repeat;height: 25px;mask-size: 21px;width:20px;;}
	.dpTwo ul.grpUserList li > a > span.bookmark {transition: none;position: absolute;left: 50px;top: 20px;font-size: 0;mask-repeat: no-repeat;height: 25px;mask-size: 21px; width : 20px; }
	.dpTwo ul.grpUserList li {box-shadow: none !important;border-radius: 0;overflow: hidden;}
	.dpTwo ul.grpUserList li.waiting {background: #fff;border-top: 1px solid #f1f3f5;/* background: #fffaf0; */}
	.dpTwo ul.grpUserList > li.waiting:hover > a:hover {background: #fffaf0;}
	.dpTwo ul.grpUserList li.waiting:before {content: '';display: block;width: 3px;height: 65px;position: absolute;background: #ffa70e;left: 0;top: 0;}
	.dpTwo ul.grpUserList li.waiting .del {color: #f39a00 !important;}
	.dpTwo ul.grpUserList li.created, .dpTwo ul.grpUserList li.signedUp {background:#fff;}
	.dpTwo ul.grpUserList li.on ul li {background: #Fff;opacity: 1;border-top: 1px solid #f1f3f5;}
	.dpTwo li.withTeams.on  > a {border-bottom: 1px solid #f1f3f5;height: 65px;}
	.dpTwo ul.grpUserList li.on {height: auto;border-top: 1px solid #aab1b5;border-bottom: 1px solid #aab1b5;transition: 0.2s;}
	.dpTwo ul.grpUserList > li.withTeams.on > a > .team em {color: #fff;content: 'ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â«ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â°';background: #477fff;width: 50px;display: inline-block;line-height: 30px;border-radius: 5px;content: 'ÃƒÆ’Ã‚Â«ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¹Ãƒâ€šÃ‚Â«ÃƒÆ’Ã‚ÂªÃƒâ€šÃ‚Â¸Ãƒâ€šÃ‚Â°';border: 2px solid #477fff;transition:0.1s;text-align: center;margin-top: 16px;}
	.dpTwo ul.grpUserList > li.withTeams.on > a > .team em:hover { background:#477fff; color:#fff; }
	.dpTwo ul.grpUserList > li.withTeams.on:hover > a > .team em, .dpTwo ul.grpUserList > li.withTeams.on> a > .team em {background:#477fff;color: #fff !important;}
	
	.dpTwo ul.grpUserList li.withTeams:hover > a > span.team em, 
	.dpTwo ul.grpUserList li.created:hover > a > span.people:hover em, 
	.dpTwo ul.grpUserList li.signedUp:hover > a > span.people:hover em,
	.dpTwo ul.grpUserList li.created li > a > span.people:hover em, 
	.dpTwo ul.grpUserList li.signedUp li > a > span.people:hover em {width: 50px;display: inline-block;line-height: 30px;border-radius: 5px;content: '';transition:0.1s;margin-top: 16px;border: 2px solid #477fff;text-align: center;color: #477fff !important;}
	
	.dpTwo ul.grpUserList li.created li > a > span.people:hover em, 
	.dpTwo ul.grpUserList li.signedUp li > a > span.people:hover em { margin-top:11px }
	
	.dpTwo ul.grpUserList li.withTeams:hover > a > span.team em:hover, 
	.dpTwo ul.grpUserList li.created:hover > a > span.people em:hover, 
	.dpTwo ul.grpUserList li.signedUp:hover > a > span.people em:hover, 
	.dpTwo ul.grpUserList li.signedUp li > a > span.people em:hover,
	.dpTwo ul.grpUserList li.created li > a > span.people em:hover { color:#fff !important;cursor:pointer; background:#477fff; }
	
	.dpTwo ul.grpUserList li.created li.notYet > a > span.people em:hover, .dpTwo ul.grpUserList li.signedUp li.notYet > a > span.people:hover em, .dpTwo ul.grpUserList li.signedUp li.notYet > a > span.people em:hover, .dpTwo ul.grpUserList li.created li.notYet > a > span.people:hover em {width: auto;border: none;margin-top: 0;line-height: 55px; background:none;}
	
	.dpTwo ul li:hover > a {background: #f1f3f5;transition:0.2s;}
	.dpTwo ul li > a {transition:0.2s;}
	.dpTwo ul.grpUserList li .before {height: 65px;padding: 0;right: 0;width: 13%;border: 0 !important;left: auto;display: block;line-height: 65px;border-radius: 0 !important;background: none;}
	.dpTwo ul.grpUserList li .before input {top: 0;width: 100px;text-align: left;margin-left: 0;border-radius: 0;left: 0;background: none !important;padding: 0;box-shadow: none !important;margin-right: 0;margin-top: 10px;line-height: 45px !important;height: 45px !important;color: #477fff !important;border: none;font-size: 12px;transition: 0s;}
	.dpTwo ul.grpUserList li.waiting .before input {left: 0;top: 0;height: 65px;width: 100%;text-align: left;margin-left: 0;border-radius: 0;margin-right: 0;color: #f1a619 !important;font-size: 12px;background: #fff;/* display: none; */}
	.dpTwo ul.grpUserList li .before span {font-size: 12px;position: static;float: left;display: inline-block;}
	.dpTwo ul.grpUserList li.waiting:hover span.date em {position: absolute;width: 100%;display: inline-block;line-height: 20px;top: 34px;left: 0;text-align: left;color: #000;}
	.dpTwo ul.grpUserList li.waiting .before {background: rgba(255,255,255, 0.0);}
	.dpTwo ul.grpUserList li.waiting:hover .before {background: none;height: 65px;}
	.dpTwo ul.grpUserList li.waiting:hover .before .date {color: #000;text-align: left;margin-left: 0;bottom:0;left: 0;height: 65px;line-height: 49px !important;}
	.dpTwo ul.grpUserList ul li.waiting .before input {height: 55px;margin-top: 6px !important;}
	.dpTwo ul.grpUserList ul li .before {height: 55px;}
	.dpTwo ul.grpUserList ul li.waiting:hover .before {height: 55px;}
	.dpTwo ul.grpUserList ul li.waiting:hover .before .date {height: 55px;line-height: 38px !important;}
	.dpTwo ul.grpUserList ul li.waiting:hover .before .date em {top: 27px;}
	.dpTwo ul.grpUserList li .before span.line {display: none;}
	.dpTwo ul.grpUserList li .before span.descript {position: absolute;bottom: -12px;margin-left: -10%;padding-left: 0;left: 0;box-sizing: border-box;display: none;}
	.dpTwo ul.grpUserList li .before span.title {position: absolute;padding-left: 30px;font-size: 14px;box-sizing: border-box;left: 0;top: -10px;bottom: auto;margin-bottom: 0;}
	.dpTwo ul.grpUserList li:hover span.date {display:none;}
	.dpTwo ul.grpUserList li .before .date {display: none;}
	.dpTwo ul.grpUserList li.waiting:hover span.date {/* display:block; */position: absolute;width: 100px;right: 0;line-height: 33px !important;top: 0;bottom: 9px;background: transparent;border: 0;color: #fff;}
	.dpTwo ul.grpUserList li.waiting:hover span.date em {color: #000;}
	.dpTwo ul.grpUserList li > a > span.placeNm {width: 40%;padding-left: 210px;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;background: none;white-space: nowrap;font-size: 14px;color: #acacbb;}
	.dpTwo ul.grpUserList li > a > span.people, .dpTwo ul.grpUserList li > a > span.team {width: 12%;font-size: 0;height: 65px;line-height: 65px;}
	.dpTwo ul.grpUserList li > a > span.people em, .dpTwo ul.grpUserList li > a > span.team em {font-size: 12px;color: #acacbb;}
	.dpTwo ul ul.grpUserList li .img {width: 55px;height: 35px !important;}
	.dpTwo ul.grpUserList ul li .img {width: 55px;height: 35px;top: 2px;left: 38px;}
	.dpTwo ul.grpUserList ul li > a > span.placeNm {font-size: 12px;}
	.dpTwo ul.grpUserList li span.num {display:block;font-size: 12px !important;width: 10%;margin-left: 0 !important;text-align: center;font-weight: bold;color: #acacbb  !important;}
	.dpTwo .grpUserList li input.del {top: 0;right: 0;position: static;float: left;width: 13%;width: 90px;border-radius: 0;height: 65px;text-align: left;font-size: 12px;background: none;color: #000;display: none;}
	.dpTwo .grpUserList li.signedUp input.del, .dpTwo .grpUserList li.created input.del {display: block;}
	.dpTwo .grpUserList li ul input.del {right: 0;left: auto;height: 55px !important;}
	.dpTwo ul.grpUserList ul > li { line-height:55px; height:55px }
	.dpTwo ul ul.grpUserList li .img {width: 55px;height: 35px !important;}
	.dpTwo ul.grpUserList ul li .img {width: 52px;height: 35px;top: -3px;left: 37px;}
	.dpTwo ul.grpUserList ul li > a > span.cl {left: 11px;top: 0px;}
	.dpTwo ul.grpUserList ul li > a > span.placeNm {font-size: 12px;line-height: 55px;}
	.dpTwo ul.grpUserList ul li > a > span.pos {line-height: 55px;height: 55px;}
	.dpTwo ul.grpUserList ul li > a > span.people, .dpTwo ul.grpUserList ul li > a > span.team {height: 55px;line-height: 55px;}
	.dpTwo ul.grpUserList li ul {display: block;}
	.dpTwo ul.grpUserList li ul li:last-child {margin-bottom: 15px;}
	.dpTwo ul.grpUserList li.created a span, .dpTwo ul.grpUserList li.signedUp a span {color: #000;font-weight: bold;}
	.dpTwo ul.grpUserList li.created span.num, .dpTwo ul.grpUserList li.signedUp span.num {color: #000 !important;}
	.dpTwo ul.grpUserList li.created a  span.people em, .dpTwo ul.grpUserList li.signedUp a span.team em,.dpTwo ul.grpUserList li.signedUp a span.people em {color: #000 !important;}
	.dpTwo ul.grpUserList li.created > a > span.team em { color: #000 !important; }
	.dpTwo ul.grpUserList li.signedUp > a > span.people em { color: #000 !important; }
	.dpTwo .grpUserList li input.del:hover {width: 13%;color: #ff3535;background: none;}
	#wrap .dpTwo ul.grpUserList li.notYet a span, #wrap .dpTwo ul.grpUserList li.notYet span em {color: #acacbb !important;}
	#wrap .dpTwo ul.grpUserList li.notYet a .del.up, .dpTwo .grpUserList li input.del.up {color: #477fff !important;transition: 0.2s;margin-top: 10px;width: 100px;border-radius: 20px;height: 35px !important;}
	.dpTwo .grpUserList li input.del.up {display: block;}
	#wrap .dpTwo ul.grpUserList li.notYet.withTeams:hover span.team em {color: #477fff !important;}
	#wrap .dpTwo ul.grpUserList li.notYet.withTeams span.team:hover em:hover, #wrap .dpTwo ul.grpUserList li.notYet.withTeams.on span.team em {color: #fff !important;}
	#wrap .dpTwo ul.grpUserList li.notYet.withTeams.on   li span.team em {color: #acacbb !important;}
	#wrap .dpTwo ul.grpUserList li.notYet .img,#wrap .dpTwo ul.grpUserList li.waiting .img, #wrap .dpTwo ul.grpUserList li.notYet .cl, #wrap .dpTwo ul.grpUserList li.waiting .cl {opacity: 0.5;}
	.dpTwo ul.grpUserList li.waiting:hover .before > .date {display: none;}
	.dpTwo ul.grpUserList li:hover > a {background: #fff;}
	.dpTwo ul.grpUserList li.waiting:hover > .before > .date {display: block;}
	.dpTwo ul.grpUserList li.waiting:hover .before > input {/* display: block; */}
	.dpTwo ul.grpUserList li > a:hover {background: #f1f3f5;}
	.dpTwo ul.grpUserList li li.waiting:hover > a {background: #fffaf0;}
	.dpTwo ul.grpUserList li.waiting li.waiting:hover .before input {display: none;}
	.dpTwo ul.grpUserList > li.waiting:hover > .before input {display: none;}
	.dpTwo ul.grpUserList li.notYet:hover > a:hover {background: #fff;}
	#wrap .dpTwo ul.grpUserList li.notYet:hover > a span, #wrap .dpTwo ul.grpUserList li.notYet:hover > a span.team, #wrap .dpTwo ul.grpUserList li.notYet:hover > a span.people em {color: #000 !important;}
	#wrap .dpTwo ul.grpUserList li.notYet:hover > a .img, #wrap .dpTwo ul.grpUserList li.notYet:hover > a .cl {opacity: 1;}
	.dpTwo ul.grpUserList li.waiting:hover > a > span, .dpTwo ul.grpUserList li.waiting:hover > a > span.num, .dpTwo ul.grpUserList li.waiting:hover > a > span.people em {color: #000 !important;}
	.dpTwo ul.grpUserList li.waiting:hover > a > span.img, .dpTwo ul.grpUserList li.waiting:hover > a > span.cl {opacity: 1 !important;}
	.dpTwo ul.grpUserList li.notYet:hover a {cursor: default;}
	#wrap .dpTwo ul.grpUserList li.withTeams.notYet span em, .dpTwo ul.grpUserList li.waiting span em {cursor: pointer;}
	.dpTwo ul.grpUserList li.waiting:hover a {cursor: default;}
	.dpTwo .grpUserList li.waiting > a, .dpTwo .grpUserList li.waiting > a:hover, .dpTwo ul.grpUserList li.waiting:hover > a {background: #fffaf0;}
	.dpTwo ul.grpUserList li.waiting span, .dpTwo ul.grpUserList li.waiting span.num, #wrap .dpTwo ul.grpUserList li.waiting span.people em {color: #acacbb !important;}
	#wrap .dpTwo ul.grpUserList li.waiting:hover > a span.people em {color: #000 !important;}
	#wrap .dpTwo ul.grpUserList li.waiting > a > input {display: none;}
	.dpTwo ul.grpUserList > li.waiting.on:hover li .before input {display: block;}
	.dpTwo ul.grpUserList > li.waiting.on:hover li:hover .before input {display: none;}
	#wrap .dpTwo .grpUserList li.notYet:hover > a > .del.up {width: 90px;height: 35px !important;border: 2px solid #477fff;line-height: 35px !important;padding: 0;border-radius:20px;text-align:center;margin-top:10px;line-height: 30px !important;}
	#wrap .dpTwo .grpUserList li.notYet:hover .del.up:hover {background:#477fff;color:#fff !important;}
	#wrap .dpTwo ul.grpUserList > li.notYet:hover > .before > .del.up {border: 2px solid #477fff;}
	.dpTwo .grpUserList > li.notYet > .before > input.del.up {margin-top: 15px;}
	.dpTwo .grpUserList > li.notYet:hover > .before > input.del.up {text-align: center;margin-top: 13px;}
	.dpTwo li.notYet:hover > .before > .yes {border: 2px solid #477fff;text-align:center;border-radius:30px;height:35px !important;line-height:33px !important;margin-top:14px;width:90px;}
	.dpTwo li.notYet:hover > .before > .yes:hover {background: #477fff !important;color:#fff !important;}
	#wrap .dpTwo ul.grpUserList li.waiting:hover > .before > .date:hover, #wrap .dpTwo ul.grpUserList li.waiting:hover > .before > .date:hover em {color: transparent !important;}
	#wrap .dpTwo ul.grpUserList li.waiting:hover  .before > span.date:hover :after {top:-19px; text-align:center; width:110px; height:35px !important; line-height:35px; }
	.dpTwo ul.grpUserList li li > a > span.faved {top: 17px;}
	.dpTwo ul.grpUserList li li:hover > a > span.faved {left: 12px;width: 26px;height: 26px;background-color: #fff;transition: 0.1s;top: 14px;}
	.dpTwo .manager{display: none !important;}

	ul.grpUserList li .premiumTag  { display:none;  font-size:0;bottom:109px;right: 21px; position: absolute; width:22px; height:22px;z-index: 6; background: url('../assets/images/contents/ic_c_tag.png') no-repeat left top; }
	ul.grpUserList li.hcList .premiumTag { display:block; background:#ffffff; align-self:center; mask-repeat: no-repeat;mask-size: 24px;mask-image: url('../assets/images/contents/star_w.svg');
	 }
	/*ul.grpUserList li.hcList:hover { box-shadow: 0 10px 25px rgba(0, 151, 255, 0.3);}*/
	ul.grpUserList li.hdList .premiumTag { display:block; background-image: url('../assets/images/contents/ic_d_tag.png'); }
	ul.grpUserList li.hdList:hover{box-shadow:0 10px 25px rgba(255, 96, 96, 0.3);}
	.dpTwo ul.grpUserList li .premiumTag {mask-size: 13px !important;top: 22px;left: 54px;mask-repeat: no-repeat;}
	.grpUserList li.hcList:hover a.seeDets:hover { background:#0097ff;color:#fff;border:2px solid #0097ff; } 
	.grpUserList li.hdList:hover a.seeDets:hover { background:#ff6060;color:#fff;border:2px solid #ff6060; } 

	/* 1차 개발 변경사항_ 멤버 팀 제거 */
	.dpTwo .placeNm,.dpTwo ul.grpUserList li > a > span.placeNm {/*width: 63%;*/width: 897px;}
	.dpTwo .pos, .dpTwo ul.grpUserList li > a > span.pos {width: 19%;}
	.dpTwo .status, .dpTwo .grpUserList li input.del, .dpTwo ul.grpUserList li .before {width: 30%;}
	.grpUserList span.placeNm { bottom:33px; }
	.grpUserList span.people {display: none;}
	.grpUserList span.team {display: none;}
	.hodudoc-group .img { background-image : url('../assets/images/contents/hodu_doc.png') !important }

	/*20.10.26 추가*/
	.grpUserList span.place_plus {position: absolute;width: 248px;display: block;left: 3px;height: auto;overflow: hidden;font-size: 18px;white-space: nowrap;text-overflow: ellipsis;font-weight: bold;padding: 20px 40px 15px 0;/* border-bottom: 1px solid #f1f3f5; */bottom: 33px;border-radius: 0 0 4px 4px;-webkit-box-sizing: border-box;box-sizing: border-box;}
	.bookmark{mask-image:url('../assets/images/contents/ic_bookmark.svg');mask-size: 40px;background: black; align-self:center; background-repeat: no-repeat;bottom:96px !important;width: 100%;height: 10px;font-size: 0;display: block;position: absolute;right: 11px;z-index: 2;width: 40px;height: 40px;border-radius: 0; z-index: 1000; cursor: pointer;}
	.manager{background-image : url('../assets/images/contents/ic_alarm_bk.png');width: 18px;background-repeat: no-repeat;height: 20px;display: inline-block;background-size:19px !important;font-size: 0;position: absolute;left: 3px;bottom:23px;display: none;}
	.grpUserList span.manager.king,.grpUserList span.manager_text.king{display: block;}
	.manager_text{height: 19px;display: inline-block;font-size: 15px;position: absolute;left: 24px;bottom:22px;display: none;color:#787c81;}
	
	@media (max-width:1600px) {
		.dpTwo li.midea {width: 479px !important;}
		.dpTwo .placeNm,.dpTwo ul.grpUserList li > a > span.placeNm {/*width: 63%;*/width: 599px;}
	}

	.manager.king.off { background-image : url('../assets/images/contents/ic_alarm_off.svg'); }
</style>