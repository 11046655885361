<template>
    <div class="remodal grpConfirm mainConfirm waiting" data-remodal-id="modal-grpWaiting" style="min-width:460px;">
        <button data-remodal-action="close" class="remodal-close"><span></span><span></span></button>
        <div class="remodal-content ">
            <div class="modal-scroll" id="">
                <div class="main">
                    <p class="img" :class="`img${image ? '' : group_color_to_class_name(color)}`" :style="{ backgroundImage : `url(${image ? 'app_images/' + image : ''})` }">그룹 이미지</p>
                    <h1 class="placeNm">{{(name ? name : "")}}</h1>
                    <p class="cl dc5" :style="{ backgroundColor: `${color ? color : ''} !important` }">그룹 색상</p>
                    <p class="descript">{{description ? description : (type == 'TEAM' ? isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '동' : '팀' 
                                                                                      : isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '아파트' : '그룹') + ' 소개글이 없습니다.'}}</p>
                    <!--
                    <dl>
                        <dt>멤버</dt>
                        <dd>12</dd>
                        <dt>팀</dt>
                        <dd>3</dd>
                    </dl>
                    -->
                </div>
            </div>
            <h2 class="" id="border_color">{{content}}</h2>
            <p class="warning">{{sub_content}}</p>
            <div class="btns" id="">			
                <a href="#" class="" id="no" data-remodal-action="close" @click.prevent="groupListConfirmCancel()">아니오</a>		
                <a href="#" class="" id="yes" @click.prevent="groupListConfirm()">예</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupModalInfo, GroupListConfirmModalData } from '@/store/modules/ModalInfo';
const ModalInfo = namespace('ModalInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class GroupListConfirmModal extends Mixins(VueHoduCommon) {
    @ModalInfo.State group_modal_info !: GroupModalInfo;

    data            !: GroupListConfirmModalData;
    type             : string  = "";
    name             : string  = "";
    image            : string  = "";
    description      : string  = "";
    color            : string  = "";
    content          : string  = "";
    sub_content      : string  = "";

    async mounted() {
        if ( null != this.group_modal_info.data ) {
            this.data        = this.group_modal_info.data;
            this.type        = this.data.type;
            this.name        = this.data.name;
            this.image       = this.data.image;
            this.description = this.data.description;
            this.color       = this.data.color;

            //! ===============================================================
            //! 가입요청 , 가입취소
            //! ===============================================================
            if ( this.data.modal_type == "join_cancel" ) {
                if ( this.data.user_id == 0 ) {
                    $('#border_color').css('border-color', '#477fff');
                    this.content = (this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? this.name+"에" : (this.type == "GROUP" ? "그룹에" : "팀에")) + " 가입하시겠습니까?";
                }
                else {
                    // if ( this.data.appr_state == "R" ) {
                    $('#border_color').css('border-color', '#ffa70e');
                    this.content = "가입요청을 취소하시겠습니까?";
                    this.sub_content = "가입요청일 : " + this.data.member_created;
                    // }
                    // else if ( this.data.appr_state == "E" || this.data.appr_state == "T" ) {
                    //     $('#border_color').css('border-color', '#ffa70e');
                    //     this.content = "가입요청이 거절되었습니다. 가입요청을 취소하시겠습니까?";
                    //     this.sub_content = "가입요청을 취소하시겠습니까?";
                    // }
                }
            }
            //! ===============================================================
            //! 그룹 팀 탈퇴 (내자신)
            //! ===============================================================
            else if ( this.data.modal_type == "my_out" ) {

                $('#border_color').css('border-color', '#ff6060');

                if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                    this.content = (this.type == "GROUP" ? "아파트" : "동") + "에서 탈퇴하시겠습니까?";
                    this.sub_content = (this.type == "GROUP" ? "아파트에 관련된 모든 데이터가 삭제되며 가입된 동에서도 자동 탈퇴됩니다." : "동에 관련된 모든 데이터가 삭제됩니다.");
                }

                else {
                    this.content = (this.type == "GROUP" ? "그룹" : "팀") + "에서 탈퇴하시겠습니까?";
                    this.sub_content = (this.type == "GROUP" ? "그룹에 관련된 모든 데이터가 삭제되며 가입된 팀에서도 자동 탈퇴됩니다." : "팀에 관련된 모든 데이터가 삭제됩니다.");
                }
            }
        }
    }

    groupListConfirmCancel() : void {
        window["groupListConfirmCancel"]();
    }

    groupListConfirm() : void {
        window["groupListConfirm"](this.data);
    }
}
</script>

<style scoped>
.mainConfirm {width: 500px;z-index: 100000000;left: 50%;margin-left: -250px;top: 50%;margin-top: -174px;position: absolute;background: #fff;}
.mainConfirm .btns {text-align: center;}
.mainConfirm h2 {text-align: center;}
.mainConfirm p {text-align: center;}
.grpConfirm p.descript { text-align:left;}
</style>