<template>
    <div id="group_survey_template_modal" class="modal group_survey_template_modal">

        <div class="titlebox">
            <h1>템플릿</h1>
        </div>

        <div class="content">
            <div class="conPadDiv">
                <div class="selectBg">
                    <h3 id="exampleText" class="selectTitle">
                        <span class="arrowDown"></span>
                        전체
                    </h3>
                    <ul class="dropdown selectUl">
                        <li><a href="#">일반</a></li>
                        <li><a href="#">업무</a></li>
                        <li><a href="#">교육</a></li>
                        <li><a href="#">행사</a></li>
                        <li><a href="#">기타</a></li>
                    </ul>
                </div>
                <div class="surveyModalCon">
                    <ul class="surMdListUl">
                        <li>
                            <p class="svyTitle">시연 행사 참석 여부</p>
                            <p class="previewImg">미리보기</p>
                            <div class="preview_copy_use">
                                <a><span class="previewIcon" title="서식 미리보기">미리보기</span></a>
                                <span class="copy_use_btn">복사하여 사용하기</span>
                            </div>
                        </li>
                        <li>
                            <p class="svyTitle">시연 행사 참석 여부</p>
                            <p class="previewImg">미리보기</p>
                            <div class="preview_copy_use">
                                <a><span class="previewIcon" title="서식 미리보기">미리보기</span></a>
                                <span class="copy_use_btn">복사하여 사용하기</span>
                            </div>
                        </li>
                        <li>
                            <p class="svyTitle">시연 행사 참석 여부</p>
                            <p class="previewImg">미리보기</p>
                            <div class="preview_copy_use">
                                <a><span class="previewIcon" title="서식 미리보기">미리보기</span></a>
                                <span class="copy_use_btn">복사하여 사용하기</span>
                            </div>
                        </li>
                        <li>
                            <p class="svyTitle">시연 행사 참석 여부</p>
                            <p class="previewImg">미리보기</p>
                            <div class="preview_copy_use">
                                <a><span class="previewIcon" title="서식 미리보기">미리보기</span></a>
                                <span class="copy_use_btn">복사하여 사용하기</span>
                            </div>
                        </li>
                        <li>
                            <p class="svyTitle">시연 행사 참석 여부</p>
                            <p class="previewImg">미리보기</p>
                            <div class="preview_copy_use">
                                <a><span class="previewIcon" title="서식 미리보기">미리보기</span></a>
                                <span class="copy_use_btn">복사하여 사용하기</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="btns">
            <input type="button" value="닫기" @click.prevent="close">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupSurveyTemplateModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class GroupSurveyTemplateModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_survey_template_modal_info !: GroupSurveyTemplateModalInfo;

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupSurveyTemplateModalInfo ?: (params : GroupSurveyTemplateModalInfo) => void;

    mounted() : void {
        $("#exampleText").click(function(){
            $("#group_survey_template_modal .selectUl").toggleClass("on");
        });
    }

    close() : void {
        this.doSetGroupSurveyTemplateModalInfo?.({ show_modal : false });
    }

}
</script>

<style scoped>
    #group_survey_template_modal.modal { overflow: hidden; position:absolute; left:50%; top:50%; margin-top:-280px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height: 562px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    #group_survey_template_modal h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    #group_survey_template_modal .content { overflow: hidden; height: 400px;border-bottom: 1px solid #e7e9ea;  }
    #group_survey_template_modal .btns input { transition: 0.1s; width: 100%; float: left; height: 75px; line-height: 75px; font-size: 14px; text-align: center; font-weight: bold; background-color:#fff; color: #232848; }

    #group_survey_template_modal .conPadDiv { padding: 10px 25px; box-sizing: border-box; }
    #group_survey_template_modal .selectBg { float: right; height: auto; line-height: 40px; }
    #group_survey_template_modal .dropdown { top: 40px; }
    .surveyModalCon { width: 100%; height: 350px; overflow: hidden; overflow-y: auto; margin-top: 10px; }
    .surMdListUl { overflow: hidden; box-sizing: border-box; padding-bottom: 40px; }
    .surMdListUl li { text-align: center; position: relative; float: left; display: flex; flex-direction: column; width: 160px; height: 180px; margin: 15px 0 0 20px; box-sizing: border-box; border: 1px solid #e7e9ea; border-radius: 5px; }
    .surMdListUl li .svyTitle { font-size: 13px; font-weight: bold; height: 40px; line-height: 40px; padding: 0 20px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .surMdListUl li .previewImg { width: 80%; height: 100px; margin: 3px auto; box-sizing: border-box; font-size: 0; background: url('../assets/images/card/img_invite_4.png') no-repeat; background-size: cover; background-position: top; }
    .surMdListUl li .preview_copy_use { flex-grow: 1; line-height: 30px; display: flex; justify-content: space-between; padding: 0 15px; box-sizing: border-box; }
    .preview_copy_use a:after { content: ''; display: inline-block; width: 1px; height: 15px; background: #dbdfe0; vertical-align: middle; margin-left: -5px; }
    .previewIcon { width: 30px; height: 30px; display: inline-block; font-size: 0; background: url('../assets/images/contents/im_eye_on.png') no-repeat; background-position: 0px 4px; }
    .copy_use_btn { cursor: pointer; }
</style>