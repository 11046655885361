<template>
    <div class="notice userArea">
        <resize-observer @notify="handleResize" />
        
        <!-- 모든 인풋에 잘못된 밸류가 들어갔을시 wrong 클래스 추가 -->
        <div class="noti_titlebox">
            <h1 class="tit_noti">{{ password_change == false ? '프로필' : '비밀번호 변경' }}</h1>
            <div class="btns fr" v-if="password_change == false">
                <a href="#" class="input_btn" @click.prevent="profileCancel">취소</a>
                <a href="#" class="input_btn" @click.prevent="profileSave">저장</a>
            </div>
        </div>

        <!-- notiWrap에 스크롤추가 -->
        <div class="notiWrap">
            <div class="grp1">
                <input type="button" class="logOut" value="로그아웃" @click="logout"/>
                <!-- <h2>환영합니다!</h2> -->
                <div class="fcDiv">
                    <div class="chngePicDiv">
                        <!-- 사용자 이미지 영역, label 태그 background-image로 추가 -->
                        <label  class="picChange img" for="chngePicId" :style="{ backgroundImage : `url(${ profile_image_error == true ? userDefaultImageUrl : `${userImageUrl}?t=${image_time}`})`}">사용자 이미지</label>
                        <img :src="`${ profile_image_error == true ? userImageUrl : `${userImageUrl}?t=${image_time}`}`" alt="onError 감지용" @error="profileImageError" style="display : none;">
                        <input type="file" id="chngePicId" accept="image/*" @change="profileImageChange($event)" />
                    </div>
                    <!-- TODO 프로필이미지 삭제 기능 추가해야함 -->
                    <!-- <div class="delImg"><input type="button" value="이미지 삭제" title="이미지 삭제"/></div> -->
                    <!-- .delImg 버튼 클릭시 저장된 이미지 삭제 -->
                </div>
                <div class="accountDiv">
                    <ul>
                        <!-- 각 로그인에 맞추어 클래스 변경 hodu_login / kakao_login / google_login-/ apple_login -->
                        <li class="account_login" :class="{
                            hodu_login : user_type == 'EMAIL',
                            kakao_login : user_type == 'KAKAOTALK',
                            google_login : user_type == 'GOOGLE',
                            apple_login : user_type == 'APPLE',
                        }">
                            <p><span>로그인마크</span>로그인정보</p>
                        </li>
                        <!-- 인증완료 certificationComplete 클래스 / 인증 미완료 certificationUn 클래스-->
                        <li class="account_certifi" :class="{
                            certificationUn : auth_info == null || auth_info.auth_check == false,
                            certificationComplete : auth_info != null && auth_info.auth_check == true
                        }">
                            <p title="인증하러 가기" @click.prevent="auth"><span class="un_certification">인증icon</span>본인 미인증<span class="certification_arrow">바로가기</span></p>
                            <p><span class="complete_certification">인증icon</span>본인 인증완료</p>
                        </li>
                    </ul>
                </div>
                <!-- <p>* 가장 멋진 사진으로 선택하세요.<br>* 가로 세로 최소 200px이상 사진 권장<br></p> -->
            </div>
            <div class="grp2">
                <h2>개인정보</h2>
                <!-- ************ .input_txt 인풋에 에러가 났을 시 그 상황에 맞는 밸류 또는 플레이스홀더를 변경 후 addClass(failed), 빨간 테두리와 배경색이 변경 됨. 클릭시 removeClass(failed) -->
                <p>
                    <label>성명</label>
                    <input class="input_txt" type="text" :placeholder="name_placeholder" maxlength="30" 
                           :value="user_profile_name" @input="user_profile_name = $event.target.value"
                           :class="{ wrong : name_wrong }" @focus="nameFocus">
                </p>

                <!-- 퍼블추가 2019.04.24 가입시 사용된 이메일 추가 -->
                <p v-if="user_type == 'EMAIL'">
                    <label>이메일</label>
                    <input class="input_txt noBox_input" type="text" title="수정 불가" placeholder="이메일 없음" 
                           :value="user_email" disabled>
                </p>

                <p>
                    <label>휴대폰</label>
                    <input class="input_txt noBox_input" type="text" :placeholder="phone_placeholder" maxlength="14" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 45' 
                           :value="user_profile_phone_number" @input="user_profile_phone_number = $event.target.value"
                           :class="{ wrong : phone_wrong }" @focus="phoneNumberFocus" disabled />
                    <input class="input_btn change" type="button" value="전화번호 변경" @click.prevent="auth"/>
                </p>

            </div>
            
            <!-- 비밀번호 변경 -->
            <!-- chngePwdDiv 스크롤추가 -->
            <div class="grp3 chngePwdDiv" v-if="user_type == 'EMAIL'" :class="{ on : password_change == true }">
                <h2>비밀번호 변경</h2>
                
                <p>
                    <label>기존 비밀번호 입력</label>
                    <input class="input_txt" type="password" placeholder="" maxlength="20" 
                           :value="current_password" @input="current_password = $event.target.value"
                           :class="{ wrong : current_password_wrong == true }" >
                </p>

                <p class="newPassword">
                    <label>신규 비밀번호 입력</label>
                    <input class="input_txt" type="password" placeholder="8-20자, 영문, 숫자, 특수문자 사용가능" maxlength="20" 
                           :value="new_password" @input="new_password = $event.target.value"
                           :class="{ wrong : new_password_wrong == true }" >
                </p>

                <p>
                    <label>신규 비밀번호 확인</label>
                    <input class="input_txt" type="password" placeholder="" maxlength="20" 
                           :value="new_password_confirm" @input="new_password_confirm = $event.target.value"
                           :class="{ wrong : new_password_confirm_wrong == true }" >
                </p>

                <div class="btns">
                    <input type="button" value="취소" @click="cancelPasswordChange"/>
                    <input type="button" value="저장" @click="updatePassword"/>
                </div>
                <!-- 오류시 하단 클래스 추가 on -->
                <div class="errorDiv" :class="{ on : password_error_message != null && password_error_message.length > 0 }">
                    <p>{{ password_error_message }}</p>
                </div>
            </div>

            <!-- 비밀번호 변경 버튼, 클릭시 .chngePwdDiv 클래스추가 on -->
            <div class="grp4 btn" v-if="user_type == 'EMAIL'">
                <input type="button" class="chngePwd" value="비밀번호 변경" @click="passwordChange" />
            </div>	
        </div>

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const LoginInfo    = namespace('LoginInfo');
const CalendarInfo = namespace('CalendarInfo');
const MenuInfo     = namespace('MenuInfo');
const ModalInfo    = namespace('ModalInfo');

import { ImageCropModalInfo } from '@/store/modules/ModalInfo';
import { ResizeObserver } from 'vue-resize';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';
import { hodu_cookie } from '@/lib/HoduCookie';

declare let Kakao : any;
import '@/assets/js/kakao'

@Component({
    components : {
        ResizeObserver
    }
})
export default class RightUserArea extends Mixins(VueHoduCommon) {
    
    /**
     * @LoginInfo.Action
     */
    @LoginInfo.Action doSetUserName ?: any;

    /** 
     * @CalendarInfo.Action 
     */ 
    @CalendarInfo.Action doSetStartDate ?: any; // start_date 업데이트

    /**
     * @MenuInfo.State
     */
    @MenuInfo.State user_profile_open !: boolean;

    /**
     * @MenuInfo.Action
     */
    @MenuInfo.Action doSetUserProfileOpen ?: any;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;


    profile_image_error : boolean = false;
    userImageUrl        : string = require("@/assets/images/contents/im_photoB.gif");
    userDefaultImageUrl : string = require("@/assets/images/contents/im_photoB.gif");

    user_profile_name         : string = "";
    user_profile_phone_number : string = "";

    user_profile_name_temp         : string = "";
    user_profile_phone_number_temp : string = "";

    name_wrong       : boolean = false;
    name_placeholder : string = "이름을 작성하세요";

    phone_wrong       : boolean = false;
    phone_placeholder : string = "입력 된 휴대폰 번호가 없습니다";

    password_change : boolean = false;

    current_password     : string = "";
    new_password         : string = "";
    new_password_confirm : string = "";

    current_password_wrong     : boolean = false;
    new_password_wrong         : boolean = false;
    new_password_confirm_wrong : boolean = false;

    password_error_message : string = "";
    
    beforeMount() : void {
        this.userImageUrl = `/app_images/profile/user/${Math.floor((this.user_id != null ? this.user_id : 0) / 10000)}/${this.user_id}.jpg`;
    }

    mounted() : void {
        this.user_profile_name         = this.user_name         == null ? "" : this.user_name;
        this.user_profile_phone_number = this.user_phone_number == null ? "" : this.user_phone_number;
        this.scrollBarSetting();

        window['user_area_refresh'] = () => {
            this.user_profile_name         = this.user_name         == null ? "" : this.user_name;
            this.user_profile_phone_number = this.user_phone_number == null ? "" : this.user_phone_number;
        };
    }

    beforeDestroy() {
        delete(window['user_area_refresh']);
    }

    profileImageError() : void {
        this.profile_image_error = true;
    }

    userImageClick() : void {
        this.doSetUserProfileOpen(!this.user_profile_open);
    }

    async logout() : Promise<void> {
    
        try {
            if( this.user_type == "KAKAOTALK" ) { 
                if(!Kakao.isInitialized()){ await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
                Kakao.Auth.logout((data) => {}); 
            }
            
            // 자동로그인 관련 설정
            hodu_local_storage.setItem('auto_login_flag', String(false));
            hodu_cookie.delete("auto_login_flag");
            
            const response = await this.hodu_api_call(`/api/v1/auth/logout`, API_METHOD.GET);

        } catch(e){
            this.hodu_error_process(e, false, false, true);
        } finally {
            this.moveLoginPage();
        }
    }

    moveLoginPage() : void {
        this.hodu_router_push('/login', () => {
                        
            // 세션 없애기
            if(sessionStorage != null && localStorage != null){
                sessionStorage.setItem("session_token", "");
                localStorage.setItem("session_token"  , "");
                hodu_cookie.delete("session_token");
            }
            
            // 로그인 정보 없애기
            this.doLogin({
                isLogin           : false,
                user_id           : 0,
                user_type         : "",
                user_email        : "",
                user_name         : "",
                user_phone_number : "",
                country_code      : "",
                is_temp_password  : false,
                user_preference   : null,
                user_group_role   : null,
                user_team_role    : null,
                template_map      : null,
                auth_info         : {
                    auth_check: false, 
                    auth_check_date: null, 
                    auth_delay_date: null, 
                    auth_check_later: false
                },
                schedule_search_config : {
                    past_schedule     : true,
                    personal_schedule : true,
                    my_schedule       : true,
                    shared_schedule   : true,
                    group_schedule    : true,
                    hodu_c_schedule   : true,
                    group_filter      : [],
                    team_filter       : [],    
                }
            });

            // 프로필 창 닫기
            this.doSetUserProfileOpen(false);

            // 달력 정보 없애기
            this.doSetStartDate(new Date());
            this.doSetCalendarId('');
            this.doSetScope('');  
            this.doSetScopeGroupId(0);  
            this.doSetScopeTeamId(0);  
            this.doSetScopeGroupTeamOption({
                group_team_name : '',
                group_team_color : '#FFFFFF',
                group_team_image : '',
                group_team_descript : '',
                biz_id : '',
                biz_type : ''
            }); 
            
            // datepicker 남아 있는거 제거
            $('#ui-datepicker-div').remove();
        });
    }

    /**
     * 프로필 끄기
     */
    profileCancel() : void {
        this.doSetUserProfileOpen(false);
    }
    
    /**
     * 유저 프로필 저장
     */
    profileSave() : void {
        const vue = this;

        if( this.checkValid() == false ) {
            return;
        }

        // 유저 프로필 수정
        this.hodu_api_call(`api/v1/user/me`, API_METHOD.PUT, {
            "user_name" : this.user_profile_name,
            "user_phone_number" : this.user_profile_phone_number
        }).then((response) => {
            console.log(JSON.stringify(response));

            // 이름, 휴대폰 번호 적용
            vue.doSetUserName(response.data.data.user.user_name);
            vue.doSetUserPhoneNumber(response.data.data.user.user_phone_number);
        })
        .catch((e) => {
            this.hodu_error_process(e, false, false);
        });
        
    }

    /**
     * 값이 올바른지 체크
     */
    checkValid() : boolean {
        this.name_wrong        = false;
        this.name_placeholder  = "이름을 작성하세요";
        this.phone_wrong       = false;
        this.phone_placeholder = '예) 010-0000-0000';

        // 이름 체크
        if( this.user_profile_name.trim().length < 1 ) {
            this.user_profile_name_temp = this.user_profile_name;
            this.name_wrong             = true;
            this.name_placeholder       = "이름을 입력해주세요";
            return false;
        }
        
        // 전화/휴대폰 번호 정규식 체크
        if( this.user_profile_phone_number.trim().length > 0 ) {
            
            // 010-1234-5678 형식인 경우
            if( this.user_profile_phone_number.trim().indexOf('-') > -1 ) {
                if( /^\d{3}-\d{3,4}-\d{4}$/.test(this.user_profile_phone_number.trim()) == false ) {
                    this.user_profile_phone_number_temp = this.user_profile_phone_number;
                    this.user_profile_phone_number      = '';
                    this.phone_wrong                    = true;
                    this.phone_placeholder              = '휴대폰 번호 형식을 확인해주세요 예) 010-0000-0000';
                    return false;
                }
            }

            // 01012345678 형식인경우
            else {
                if( /^\d{3}\d{3,4}\d{4}$/.test(this.user_profile_phone_number.trim()) == false ) {
                    this.user_profile_phone_number_temp = this.user_profile_phone_number;
                    this.user_profile_phone_number      = '';
                    this.phone_wrong                    = true;
                    this.phone_placeholder              = '휴대폰 번호 형식을 확인해주세요 예) 010-0000-0000';
                    return false;
                }     
            }
        }
          
        return true;
    }

    /**
     * wrong인 상태의 name에 포커스가 가면 wrong을 풀고 사용자가 입력한 사용자 이름을 다시 세팅
     */
    nameFocus() : void {
        if( this.name_wrong == false ) {
            return;
        }

        this.user_profile_name = this.user_profile_name_temp;
        this.name_wrong = false;
        this.name_placeholder  = "이름을 작성하세요";
    }

    /**
     * wrong인 상태의 phone number에 포커스가 가면 wrong을 풀고 사용자가 입력한 휴대폰 번호를 다시 세팅
     */
    phoneNumberFocus() : void {

        if( this.phone_wrong == false ) {
            return;
        }

        this.user_profile_phone_number = this.user_profile_phone_number_temp;
        this.phone_wrong = false;
        this.phone_placeholder = '예) 010-0000-0000';
    }

    /**
     * 프로필 이미지 변경
     */
    async profileImageChange(event) : Promise<void> {
        const vue = this;
        const files : File[] = event.target.files;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(files[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#chngePicId').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                if( this.doSetImageCropModalInfo == null ) { return; }

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (1/1), // 1:1
                    image_url : url,
                    cancel_function : async() => {
                        $('#chngePicId').val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        await this.hodu_show_indicator();

                        const resize_blob : Blob = await vue.hodu_image_resize(cropped_url);
                        
                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = files[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, files[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = files[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        // 프로필 이미지 변경
                        const form_data : FormData = new FormData();
                        form_data.append('user_pic', resize_file);
                        
                        await this.hodu_api_call(`api/v1/user/me/pic`, API_METHOD.PUT, form_data)
                            .then(async(response) => {
                                console.log(JSON.stringify(response));
                                vue.doSetImageTime(new Date().getTime());
                                vue.profile_image_error = false;
                            })  
                            .catch(async(e) => {
                                this.hodu_error_process(e, false, false);
                            })
                            .finally(async() => {
                                $('#chngePicId').val("");
                                await this.hodu_hide_indicator();
                            });
                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

        
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * password 변경
     */
    passwordChange() : void {
        this.current_password       = "";
        this.new_password           = "";
        this.new_password_confirm   = "";
        this.password_error_message = "";

        this.password_change = true;
    }

    /**
     * password 변경 모드에서 나가기
     */
    cancelPasswordChange() : void {
        this.password_change = false;
    }

    /**
     * 비밀번호 변경
     */
    async updatePassword() : Promise<void> {
        const vue = this;

        if( await this.checkValidPassword() == false ) {
            return;
        }

        // 비밀번호 변경
        await this.hodu_api_call(`api/v1/user/me/accounts/EMAIL`, API_METHOD.PUT, {
            "old_password" : this.current_password,
            "new_password" : this.new_password
        }).then((response) => {
            console.log(JSON.stringify(response));
            vue.password_change = false;
        })
        .catch((e) => {
            this.hodu_error_process(e, false, false);
        });
    }

    /**
     * 비밀번호 유효성 체크
     */
    async checkValidPassword() : Promise<boolean> {
        this.password_error_message     = "";
        this.current_password_wrong     = false;
        this.new_password_wrong         = false;
        this.new_password_confirm_wrong = false;

        if( this.current_password.trim().length < 1 ) {
            this.password_error_message = "현재 비밀번호를 입력 해주세요";
            this.current_password_wrong = true;
            return false;
        }

        let password_check_flag : boolean = false;

        // 현재 비밀번호가 맞는지 체크
        await this.hodu_api_call(`api/v1/user/me/accounts/passwordCheck/EMAIL`, API_METHOD.POST, {
            "old_password" : this.current_password
        }).then((response) => {
            console.log(JSON.stringify(response));
            password_check_flag = response.data.data.password_check_flag
        })
        .catch((e) => {
            this.hodu_error_process(e, false, false);
        });

        if( password_check_flag == false ) {
            this.password_error_message = "비밀번호 오류! 현재 비밀번호를 확인 해주세요";
            this.current_password_wrong = true;
            return false;
        }

        if( this.new_password.trim().length < 1 ) {
            this.password_error_message = "신규 비밀번호를 입력 해주세요";
            this.new_password_wrong     = true;
            return false;
        }

        if( this.new_password.trim().length < 4 ) {
            this.password_error_message = "신규 비밀번호를 4자리 이상 입력 해주세요";
            this.new_password_wrong     = true;
            return false;
        }

        if( this.new_password_confirm.trim().length < 1 ) {
            this.password_error_message     = "신규 비밀번호 확인을 입력 해주세요";
            this.new_password_confirm_wrong = true;
            return false;
        }

        if( this.new_password.trim() != this.new_password_confirm.trim() ) {
            this.password_error_message     = "신규 비밀번호와 신규 비밀번호 확인이 서로 다릅니다";
            this.new_password_confirm       = "";
            this.new_password_confirm_wrong = true;
            return false;
        }

        if( this.current_password.trim() == this.new_password.trim() ) {
            this.password_error_message = "신규 비밀번호와 현재 비밀번호가 같습니다";
            this.new_password_wrong     = true;
            this.new_password_confirm   = "";
            return false;
        }

        return true;
    }

    @Watch('current_password')
    changeCurrentPassword() : void {
        if( this.current_password_wrong == false ) {
            return;
        } 

        this.current_password_wrong = false;
        this.password_error_message = "";
    }

    @Watch('new_password')
    changeNewPassword() : void {
        if( this.new_password_wrong == false ) {
            return;
        } 

        this.new_password_wrong = false;
        this.password_error_message = "";
    }

    @Watch('new_password_confirm')
    changeNewPasswordConfirm() : void {
        if( this.new_password_confirm_wrong == false ) {
            return;
        } 

        this.new_password_confirm_wrong = false;
        this.password_error_message = "";
    }

    /**
     * 인증
     */
    auth() : void {
        try {
            let popup : Window | null = null;

            // window['skip_button_select'] = false;

            window['auth_callback'] = (user_info) => {
                try {

                    this.doSetAuthInfo(user_info.auth_info);
                    this.doSetUserPhoneNumber(user_info.user_phone_number);
                    this.doSetCountryCode(user_info.country_code);

                    this.user_profile_phone_number = user_info.user_phone_number;

                    // console.log(authResult);
                    // this.firebase_auth(authResult, auth_check_later, (user_info) => {
                    //     try {
                    //         console.log(user_info);
                    //         this.doSetAuthInfo(user_info.auth_info);
                    //         this.doSetUserPhoneNumber(user_info.user_phone_number);
                    //         this.doSetCountryCode(user_info.country_code);

                    //         this.user_profile_phone_number = user_info.user_phone_number;
                            
                    //     } catch(e) {
                    //         this.hodu_error_process(e, false, false, true);
                    //     }
                    // });
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                } finally {
                    popup?.close();
                }
            }

            const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            const systemZoom = width / window.screen.availWidth;

            const w = 500;
            const h = 626;
            const l = (width - w) / 2 / systemZoom + dualScreenLeft;
            const t = (height - h) / 2 / systemZoom + dualScreenTop;

            popup = window.open(`/naver-auth.html?device_uid=${this.get_device_uid()}&session_token=${this.get_session_token()}`, `naver-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }
    
    /**
     * 스크롤바 세팅
     */
    scrollBarSetting() : void {
        const windowHeight : number = window.innerHeight;
        const titleHeight : number | undefined = $('.noti_titlebox').outerHeight();

        // @ts-ignore
        $('.notiWrap').mCustomScrollbar({
            axis : 'y',
            setHeight : windowHeight - ( titleHeight == null ? 0 : titleHeight ),
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 리사이즈 핸들러
     */
    handleResize() : void {
        // @ts-ignore
        $('.notiWrap').mCustomScrollbar('destroy');
        this.scrollBarSetting();
    }
    
}

</script>


<style scoped>
    /* 개인정보 */
    .userArea .noti_titlebox .btns a { margin-top:9px; }
    .userArea .grp1 {  width:100%; position:relative; border-bottom:1px solid #f1f3f5; }
    .userArea h2 { width:100%; height:60px; line-height:60px;font-size:16px;margin-top:10px;margin-bottom:10px;box-sizing:border-box;border-bottom:1px solid #f1f3f5;}
    .fcDiv { height:150px; }
    .userArea .img { border:1px solid #e7e9ea; font-size:0; margin-left:20px; margin-top:10px; display:inline-block; width:105px !important; height: 105px !important; border-radius:50%;background:#f1f3f5; background-image: url(../../assets/images/contents/im_photoB.gif); background-size:cover !important; background-position:center center !important; }
    .delImg {position: absolute;width: 40px;height: 40px;left: 130px;border-radius: 50%;padding-right:0 !important;bottom: 34px;overflow: hidden; box-shadow:0 1px 5px rgba(0,0,0,0.1);font-size:0;}
    .delImg:hover {  box-shadow:0 1px 9px rgba(0,0,0,0.2);}
    .delImg input {padding-right:0 !important;font-size: 0 !important;background: #ff6060 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center !important;background-size:18px;width: 40px !important;height: 40px;}
    .delImg input:hover {background-color: #ff5656 !important;}
    .userArea .grp1 h2 { border-bottom:0 none;line-height:80px; } 

    .userArea input.wrong { border:1px solid #ff6060 !important; background:#fff6f6 !important; color:#ff6060; }
    .userArea input.wrong::placeholder { color: #ff6060; }
    .userArea .grp3.on { display:block; position:absolute; top:0; left:0; height:100%; z-index:100000; background:#fff; } 
    .userArea .grp1, .userArea .grp2, .userArea .grp3, .userArea .grp4  { width:100%; box-sizing:border-box; padding:0 35px; box-sizing:border-box; }
    .userArea .grp2 label, .userArea .grp3 label { margin-top:7px; font-size: 12px;display: block;height: 35px;line-height: 35px;font-weight: bold;}
    .userArea .grp2 input, .userArea .grp3 input { transition:0.1s; border:1px solid #e7e9ea; font-size:14px; width:100%;line-height:50px; height:50px; border-radius:0;padding:0 15px; box-sizing:border-box; font-weight:bold; }
    .userArea .grp2 input:hover, .userArea .grp3 input:hover { background:#f1f3f5;  }
    .userArea .grp2 input:focus, .userArea .grp3 input:focus { border-color:#477fff;background:#fff; }
    .userArea input.logOut, .userArea input.logOut:focus { background:#ff6060; color:#fff; float:right;padding-right:0; text-align:center;border-radius:20px; line-height:35px; height:35px; width:90px; font-weight:bold;margin-top:23px;  }
    .userArea input.logOut:hover {background-color:#ff5656; }
    .userArea .grp3 .btns { margin-top:20px; overflow:hidden; }
    .userArea .grp3 .btns input { width:50%; float:left;text-align:center;background:#fff;border: 0 none;box-sizing:border-box; }
    .userArea .chngePwdDiv { display:none; }
    .userArea input:disabled { background:#f1f3f5; }
    /* .userArea .grp1 p { position: absolute;left: 210px;font-weight:bold;height:55px;width:220px;top: 87px;background: #f1f3f5;padding: 20px;border-radius: 5px; line-height:22px;} */
    .errorDiv { display:none;background:#fff6f6; color:#ff6060;height: 56px;line-height: 50px;font-size: 14px;font-weight: bold;  border-radius: 0 0 5px 5px;text-align: center;box-sizing: border-box;margin-top: 30px;border-top: 3px solid #ff6060;}
    .errorDiv.on { display:block;}
    .chngePicDiv { position:relative; }
    .chngePicDiv:hover label { cursor:pointer; opacity:1; }
    .chngePicDiv label { cursor:pointer;opacity:1; width:112px; height:112px; font-size:0; border-radius:50%; }
    .chngePicDiv input#chngePicId { display:none; }
    .userArea .grp4  { margin-top:30px; }
    .chngePwd { background:#477fff; color:#fff; font-size:14px; width:100%; box-sizing:border-box; font-weight:bold; line-height:50px; height:50px;border-radius:5px; }


    .btns.fr { margin-right:20px; }
    
    #right_area.usr .right_inbox02 .noti_titlebox { width:400px !important; }

    .userArea .grp2 p { position: relative; margin-bottom: 30px; }
    /* .userArea input.change { position: absolute; top: 42.5px; right: 9px; width: 50px; height: 35px; border-radius: 5px; line-height: 35px; background-color: #477fff !important; color: #fff !important; padding: 0 10px !important; margin: 0; text-align: center; } */

    /* 21.06.30 수정 */
    .userArea input.logOut, .userArea input.logOut:focus { margin-top: 0; position: absolute; top: 17px; right: 25px; z-index: 2; }
    .fcDiv { height: auto; padding: 20px 0; box-sizing: border-box; text-align: center; }
    .userArea .img { width: 140px !important; height: 140px !important; margin-left: 0; margin-top: 0; }
    .accountDiv { margin-bottom: 15px; }
    .accountDiv ul { overflow: hidden; padding: 10px 0; box-sizing: border-box; }
    .accountDiv ul li { display: inline-block; width: 50%; font-size: 13px; font-weight: bold; line-height: 20px; padding: 0 10px; box-sizing: border-box; }
    .accountDiv ul li:first-child::before { content: ''; width: 2px; height: 15px; display: inline-block; background: #cacad0; position: absolute; top: 4px; right: 0; }
    .accountDiv ul li.account_login { text-align: right; position: relative; }
    .accountDiv ul li.account_login span { font-size: 0; width: 20px; height: 20px; display: inline-block; border-radius: 50%; vertical-align: middle; margin-right: 10px; }
    .accountDiv ul li.kakao_login span { background: #f9e000 url('../../assets/images/contents/kakao_logo.png')no-repeat center center; border: 2px solid #f9cd00; }
    .accountDiv ul li.google_login span { background: #fff url('../../assets/images/contents/google_logo.png')no-repeat center center; border: 2px solid #f1f3f5; }
    .accountDiv ul li.hodu_login span { background: #477fff url('../../assets/images/contents/logo.svg')no-repeat center center; border: 2px solid #477fff; background-size: 16px; }
    .accountDiv ul li.apple_login span { background: #fff url('../../assets/images/contents/apple_black_logo.png')no-repeat center center; border: 2px solid #f1f3f5; }
    .accountDiv ul li.certificationUn { opacity: .6; cursor: pointer; }
    .accountDiv ul li.certificationComplete { opacity: 1; }
    .accountDiv ul li.account_certifi span { font-size: 0; width: 20px; height: 20px; display: inline-block; vertical-align: middle; margin-right: 5px; }
    .accountDiv ul li.certificationUn span {background: url('../../assets/images/contents/user_certification.png')no-repeat center center; }
    .accountDiv ul li.certificationUn span.certification_arrow { background: url('../../assets/images/side_area/bt_more.png')no-repeat center center; margin-left: 5px; background-size: 10px; }
    .accountDiv ul li.account_certifi span.complete_certification { background: url('../../assets/images/contents/user_certification_complete.png')no-repeat center center; }
    .accountDiv ul li.certificationUn p:last-child { display: none; }
    .accountDiv ul li.certificationComplete p:first-child { display: none; }

    .userArea input.change { position: absolute; top: 35px; right: 9px; width: 100px; height: 35px; line-height: 35px; background-color: none !important; color: #477fff !important; padding: 0 10px !important; margin: 0; text-align: center; background: none !important; border: none 0px; font-size: 13px !important; }
    .userArea .grp2 .noBox_input { border: none 0px; padding: 0 5px; height: 35px; line-height: 35px; }
    .userArea .grp2 .noBox_input:disabled { background: none !important; color: #232848; }
</style>

<style>
     #right_area.usr { width:400px !important; }
</style>