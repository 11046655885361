<template>
    <div id="equipment_list" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">장비/자재관리</h3>

            <div class="fc-right">
                <a href="#" class="schBtn" @click.prevent="openSearch">검색하기</a>
            </div>
        </div>

        <div class="searchDiv" :class="{ on : is_search_div_open == true }">
            <div class="schGrp">
                <input type="text" id="equipment_search" placeholder="검색" :value="search_query" @input="search_query = $event.target.value">
                <div class="btns">
                    <a class="closeBtn" @click="closeSearch">닫기</a>
                </div>
            </div>
        </div>

        <div id="bt_scheduleBoxNoti" class="bt_scheduleBox" v-if="tab == 'EQUIPMENT'">
            <input type="button" class="bt_schedule" value="장비 등록" @click.prevent="moveEquipmentCreate"/>
        </div>

        <div class="section_scroll">
            <div class="viewGroup">
                <div id="aptEquip_div" class="aptEquip_sort">
                    <!-- 타이틀 -->
                    <div class="titleDiv titleTab_div">
                        <h2 class="title" :class="{ active : tab == 'EQUIPMENT' }">
                            <a href="#" class="tit_tab" @click.prevent="tabChange('EQUIPMENT')">장비/자재</a>
                        </h2>
                        <h2 class="title" :class="{ active : tab == 'MANAGEMENT' }">
                            <a href="#" class="tit_tab" @click.prevent="tabChange('MANAGEMENT')">수리/보수 이력</a>
                        </h2>
                        <input type="button" class="input_btn" value="가져오기" @click.prevent="importEquipment" v-if="tab == 'EQUIPMENT'"/>
                    </div>
                    <!-- //타이틀 -->
                    <div class="fixedDiv_con">
                        <ul id="aptEquip_list" class="aptEquip_listCon" v-if="tab == 'EQUIPMENT'">
                            <li>
                                <ul id="" class="sortHeader">
                                    <li class="num">No</li>
                                    <li class="eqClass">
                                        <span>대분류</span>
                                    </li>
                                    <li class="eqClass">
                                        <span>중분류</span>
                                    </li>
                                    <li class="eqClass">
                                        <span>소분류</span>
                                    </li>
                                    <li class="title">
                                        <span>장비명</span>
                                    </li>
                                    <li class="writtenBy">
                                        <span>담당자</span>
                                    </li>
                                    <li class="time">
                                        <span>관리이력</span>
                                    </li>
                                    <li class="eqPhoto">
                                        <span>첨부</span>
                                    </li>
                                </ul>
                                <div id="equipment_scroll" class="aptEquip_con">
                                    <ul class="aptEquipUl">

                                        <li :key="equipment.uid" v-for="(equipment, index) in computedEquipments">
                                            <p class="num">{{ index + 1 }}</p>
                                            <p class="eqClass eqLarge">{{ hodu_string_validation(equipment.equipment_data.major_category) }}</p>
                                            <p class="eqClass eqMedium">{{ hodu_string_validation(equipment.equipment_data.middle_category) }}</p>
                                            <p class="eqClass eqSmall">{{ hodu_string_validation(equipment.equipment_data.minor_category) }}</p>
                                            <p class="title">{{ hodu_string_validation(equipment.equipment_data.name) }}</p>
                                            <p class="writtenBy">{{ hodu_string_validation(equipment.equipment_data.manager) }}</p>
                                            <p class="time">
                                                {{ equipment.current_management_date != null ? `${hodu_date_to_format_string(equipment.current_management_date, 'YYYY.MM.DD')} (${getDayOfWeekByDate(equipment.current_management_date)})` : '' }}
                                                총 <span>{{ equipment.management_count }}건</span>
                                            </p>
                                            <p class="eqPhoto" v-if="equipment.equipment_data.images.length > 0"><span class="eqPhoto_icon">장비사진 여부 icon</span></p>
                                            <a href="#" class="eqDetail" @click.prevent="moveEquipmentDetail(equipment.uid)">장비 상세보기</a>
                                        </li>
                                        
                                        <!-- <li>
                                            <p class="num">1</p>
                                            <p class="eqClass eqLarge">장비</p>
                                            <p class="eqClass eqMedium">기계장치</p>
                                            <p class="eqClass eqSmall">설치·장비</p>
                                            <p class="title">전기 발전기</p>
                                            <p class="writtenBy">장관리</p>
                                            <p class="time">21.05.21 (금) 총<span> 3건</span></p>
                                            <p class="eqPhoto"><span class="eqPhoto_icon">장비사진 여부 icon</span></p>
                                            <a href="#" class="eqDetail" @click.prevent="moveEquipmentDetail">장비 상세보기</a>
                                        </li> -->
                                    </ul>
                                </div>
                             </li>
                        </ul>
                        <ul id="aptEquip_Managementlist" class="aptEquip_ManagementlistCon" v-if="tab == 'MANAGEMENT'">
                            <li>
                                <ul id="" class="sortHeader">
                                    <li class="num">No</li>
                                    <li class="eqSubmit">
                                        <span>등록일</span>
                                    </li>
                                    <li class="title">
                                        <span>장비명</span>
                                    </li>
                                    <li class="eqFix">
                                        <span>내용</span>
                                    </li>
                                    <li class="eqFixstore">
                                        <span>업체</span>
                                    </li>
                                    <li class="writtenBy">
                                        <span>담당자</span>
                                    </li>
                                    <li class="eqRemark">
                                        <span>비고</span>
                                    </li>
                                    <li class="eqPhoto">
                                        <span>첨부</span>
                                    </li>
                                </ul>
                                <div id="equipment_management_scroll" class="aptEquip_con">
                                    <ul class="aptEquipUl">
                                        <li :key="`${management.uid}_${management.seq}`" v-for="(management, index) in computedManagements">
                                            <p class="num">{{ index + 1 }}</p>
                                            <p class="eqSubmit">
                                                {{ hodu_date_to_format_string(management.audit_created, 'YYYY.MM.DD') }}
                                                ({{ getDayOfWeekByDate(management.audit_created) }})
                                            </p>
                                            <p class="title">{{ hodu_string_validation(management.equipment.equipment_data.name) }}</p>
                                            <p class="eqFix">{{ hodu_string_validation(management.management_data.repair_items) }}</p>
                                            <p class="eqFixstore">{{ hodu_string_validation(management.management_data.repairer) }}</p>
                                            <p class="writtenBy">{{ hodu_string_validation(management.management_data.manager) }}</p>
                                      <!-- 장비수리이력에 나오는 비고가 나오게 바꿔주세요-->
                                            <p class="time eqRemark">
                                                {{ hodu_date_to_format_string(yyyymmddToDate(management.management_data.repair_complete_date), 'YYYY.MM.DD') }}
                                                ({{ getDayOfWeekByDate(yyyymmddToDate(management.management_data.repair_complete_date)) }})
                                            </p>
                                            <p class="eqPhoto"><span class="eqPhoto_icon" v-if="management.management_data.images.length > 0">장비사진 여부 icon</span></p>
                                            <a href="#" class="eqcardDetail" @click.prevent="showEquipmentManagementDetail(management.uid, management.seq)">이력 상세보기</a>
                                        </li>
                                        <!-- <li>
                                            <p class="num">1</p>
                                            <p class="eqSubmit">21.05.11 (화)</p>
                                            <p class="title">전기 발전기</p>
                                            <p class="eqFix">엔진 불량교체</p>
                                            <p class="eqFixstore">호두정비테크</p>
                                            <p class="writtenBy">호담당 기사</p>
                                            <p class="time">21.05.21 (금)</p>
                                            <p class="eqPhoto"><span class="eqPhoto_icon">장비사진 여부 icon</span></p>
                                            <a href="#" class="eqcardDetail" @click.prevent="showEquipmentManagementDetail">이력 상세보기</a>
                                        </li>
                                        <li>
                                            <p class="num">2</p>
                                            <p class="eqSubmit">21.05.03 (월)</p>
                                            <p class="title">전기 발전기</p>
                                            <p class="eqFix">오일 필터</p>
                                            <p class="eqFixstore">호두정비테크</p>
                                            <p class="writtenBy">호담당 기사</p>
                                            <p class="time">21.05.06 (목)</p>
                                            <p class="eqPhoto"><span class="eqPhoto_none">-</span></p>
                                            <a href="#" class="eqcardDetail" @click.prevent="showEquipmentManagementDetail">이력 상세보기</a>
                                        </li> -->
                                    </ul>
                                </div>
                             </li>
                        </ul>
                    </div>
                </div>
            </div>          
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';
import { EquipmentImportModalInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class EquipmentList extends Mixins(VueHoduCommon) {

    /**
     * computed 장비 리스트
     */
    get computedEquipments() : any[] {
        let list = JSON.parse(JSON.stringify(this.equipments));

        const search_query = this.search_query.toUpperCase().trim();

        if( search_query.length > 0 ) {
            list = list.filter(equipment => this.hodu_string_includes(equipment.equipment_data.name, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.code, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.location, search_query) || 
                                            this.hodu_string_includes(equipment.equipment_data.manager, search_query) || 
                                            this.hodu_string_includes(equipment.equipment_data.desc, search_query) || 
                                            this.hodu_string_includes(equipment.equipment_data.major_category, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.middle_category, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.minor_category, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.manufacturer, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.manufacturer_tel, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.vendor, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.vendor_tel, search_query));
        }

        return list;
    }

    /**
     * computed 장비 관리 이력 리스트
     */
    get computedManagements() : any[] {
        let list = JSON.parse(JSON.stringify(this.managements));

        const search_query = this.search_query.toUpperCase().trim();

        if( search_query.length > 0 ) {
            list = list.filter(management => this.hodu_string_includes(management.equipment.equipment_data.name, search_query) ||
                                             this.hodu_string_includes(management.management_data.repair_items, search_query) || 
                                             this.hodu_string_includes(management.management_data.repairer, search_query) || 
                                             this.hodu_string_includes(management.management_data.repair_tel, search_query) ||
                                             this.hodu_string_includes(management.management_data.memo, search_query));
        }

        return list;
    }

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEquipmentManagementDetailModalInfo ?: (params : hodu_home_modal_info.EquipmentManagementDetailModalInfo) => void;
    @ModalInfo.Action doSetEquipmentImportModalInfo ?: (params : EquipmentImportModalInfo) => void;

    tab : string = "EQUIPMENT";
    equipments : any[] = [];
    managements : any[] = [];

    is_search_div_open : boolean = false;
    search_query : string = "";
    
    mounted() : void {
        
        if( this.tab == 'EQUIPMENT' ) {
            this.getHomeEquipments();
        }
        else {
            this.getHomeEquipmentManagements();
        }

        this.setScroll();
        //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.fixedDiv_con > ul').hide();
        // 	$('.fixedDiv_con > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });
        
        window['equipment_reload'] = this.getHomeEquipments;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const title_div_height = $('.titleDiv').outerHeight();
        const sort_header_height = $('.sortHeader').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                 - (title_div_height ? title_div_height : 0)
                                                 - (sort_header_height ? sort_header_height : 0);

        if( this.tab == 'EQUIPMENT' ) {

            // @ts-ignore
            $('#equipment_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : scroll_height,
            });

        }
        else {

            // @ts-ignore
            $('#equipment_management_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : scroll_height,
            });

        }
        
    }

    /**
     * 장비 목록 조회
     */
    async getHomeEquipments() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment`, API_METHOD.GET);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.equipments ) {
                throw new Error("장비 리스트 조회 중 오류 발생");
            }

            this.equipments.splice(0, this.equipments.length);
            this.equipments = this.equipments.concat(response.data.data.equipments);

        } catch(e) {
            this.hodu_show_dialog('cancel', "장비 리스트 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 장비 이력 리스트 조회
     */
    async getHomeEquipmentManagements() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/management`, API_METHOD.GET);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.managements ) {
                throw new Error("장비 리스트 조회 중 오류 발생");
            }

            this.managements.splice(0, this.managements.length);
            this.managements = this.managements.concat(response.data.data.managements);

        } catch(e) {
            this.hodu_show_dialog('cancel', "장비 리스트 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }
    

    /**
     * 검색창 열기
     */
    openSearch() : void {
        this.is_search_div_open = true;
        this.$nextTick(() => $('#equipment_search').focus());
    }   

    /**
     * 검색창 닫기
     */
    closeSearch() : void {
        this.is_search_div_open = false;
        this.search_query = "";
    }

    /**
     * 탭 변경
     */
    tabChange(tab) : void {

        this.is_search_div_open = false;
        this.search_query = "";
        
        this.tab = tab;
        
        if( this.tab == 'EQUIPMENT' ) {
            // @ts-ignore
            $('#equipment_management_scroll').mCustomScrollbar("destroy");
            this.getHomeEquipments();
        }
        else {
            // @ts-ignore
            $('#equipment_scroll').mCustomScrollbar("destroy");
            this.getHomeEquipmentManagements();
        }

        this.$nextTick(() => this.setScroll());
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 장비 생성으로 이동
     */
    moveEquipmentCreate() : void {
        this.hodu_router_push(`/home/equipment/create`);
    }

    /**
     * 상세조회로 이동
     */
    moveEquipmentDetail(uid) : void {
        this.hodu_router_push(`/home/equipment/${uid}`);
    }

    /**
     * 장비 관리 이력 모달
     */
    showEquipmentManagementDetail(uid, seq) : void {
        this.doSetEquipmentManagementDetailModalInfo?.({ 
            show_modal : true, 
            uid : uid, 
            seq : seq,
            callback : this.getHomeEquipmentManagements 
        });
    }

    importEquipment() : void {
        this.doSetEquipmentImportModalInfo?.({
            show_modal : true,
            group_id : this.scope_group_id
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        if( this.tab == 'EQUIPMENT' ) {
            // @ts-ignore
            $('#equipment_scroll').mCustomScrollbar("destroy");
        }
        else {
            // @ts-ignore
            $('#equipment_management_scroll').mCustomScrollbar("destroy");
        }

        this.$nextTick(() => this.setScroll());
    }

}
</script>

<style scoped>
    #equipment_list #aptEquip_div .sortHeader li { width: 21%; text-align: left; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #equipment_list #aptEquip_div.aptEquip_sort .num, #equipment_list #aptEquip_div.aptEquip_sort .eqPhoto { width: 7%; text-align: center; font-weight: bold; }
    #equipment_list #aptEquip_div.aptEquip_sort .eqClass { width: 11%; }
    #equipment_list #aptEquip_div.aptEquip_sort .writtenBy { width: 10%; }
    #equipment_list #aptEquip_Managementlist .sortHeader li { width: 18%; text-align: left; font-weight: bold; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #equipment_list #aptEquip_Managementlist.aptEquip_ManagementlistCon .writtenBy { width: 10%; }
    #equipment_list #aptEquip_Managementlist.aptEquip_ManagementlistCon .eqFixstore,
    #equipment_list #aptEquip_Managementlist.aptEquip_ManagementlistCon .eqSubmit { width: 13%; }
    #equipment_list #aptEquip_Managementlist.aptEquip_ManagementlistCon .eqRemark { width: 14%; }
    #equipment_list #aptEquip_Managementlist .aptEquipUl li > p { width: 18%; text-align: left; float: left; display: inline-block; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; transition: .2s; }
    #aptEquip_Managementlist .aptEquipUl a.eqcardDetail { position: absolute; line-height: 55px; height: 55px; top: 0; width: 100%; display: block; border: none; z-index: 1000; font-size: 0; background: transparent; left: 0;
}

    #equipment_list .aptEquipUl li { position: relative; transition: .2s; overflow: hidden; width: 100%; line-height: 55px; height: 55px; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; color: #202a39; cursor: pointer; }
    #equipment_list .aptEquipUl > li p { width: 21%; text-align: left; font-weight: 700; float: left; display: inline-block; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; opacity: .7; }
    #equipment_list .aptEquipUl > li:hover p { opacity: 1; font-weight: bold; }
    #equipment_list .aptEquipUl > li p .eqPhoto_icon { font-size: 0; background: url('../../assets/images/contents/ic_img_bk.png') no-repeat center; width: 20px; height: 20px; display: inline-block; vertical-align: middle; }
    #equipment_list .aptEquipUl a.eqDetail { position: absolute; line-height: 55px; height: 55px; top: 0; width: 100%; display: block; border: none; z-index: 1000; font-size: 0; background: transparent; left: 0; }

    #aptEquip_div .titleTab_div input.input_btn { float: right; margin-top: 9px; }
</style>