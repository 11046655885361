<template>
    <div id="supply_management_detail_modal" class="modal">

        <div class="titlebox">
            <h1>입·출고 내역</h1>
        </div>

        <div class="modal_content" v-if="management != null">
            <div class="modal_detail">
                <div class="Moleft_con">
                    <div class="supplyMo_img" :style="{ 
                        backgroundImage : management.supply.supply_data.image ? `url(app_images/${management.supply.supply_data.image.url})` : `url(${default_image})`,
                        cursor : management.supply.supply_data.image ? 'pointer' : 'default' 
                    }" @click.prevent="imageClick"></div>
                    <strong class="supplyMoImg_title">{{ hodu_string_validation(management.supply.supply_data.name) }}</strong>
                    <p class="img_ex">*클릭시 원본 이미지를 볼 수 있습니다.</p>
                </div>
                <div class="moright_text">
                    <ul>
                        <li>
                            <p class="title">등록일</p>
                            <p class="descript">
                                {{ hodu_date_to_format_string(management.audit_created, 'YYYY.MM.DD') }}
                                ({{ getDayOfWeekByDate(management.audit_created) }})
                            </p>
                        </li>
                        <li>
                            <p class="title">입고량</p>
                            <p class="descript">{{ management.management_data.type == 'inbound' ? hodu_string_validation(String(management.management_data.count)) : '-' }}</p>
                        </li>
                        <li>
                            <p class="title">입고단가</p>
                            <p class="descript">{{ management.management_data.type == 'inbound' ? hodu_string_validation(String(management.management_data.unit_price)) : '-' }}</p>
                        </li>
                        <li>
                            <p class="title">출고량</p>
                            <p class="descript">{{ management.management_data.type == 'outbound' ? hodu_string_validation(String(management.management_data.count)) : '-' }}</p>
                        </li>
                        <li>
                            <p class="title">재고</p>
                            <p class="descript stock_chabge">
                                {{ getPrevStock() }}
                                <span class="change_arrow">이미지</span>
                                <span>{{ getStock() }}</span>
                            </p>
                        </li>
                        <li>
                            <p class="title">관리자</p>
                            <p class="descript">{{ hodu_string_validation(management.supply.supply_data.manager) }}</p>
                        </li>
                    </ul>
                </div>
                <div class="morunder_text">
                    <ul>
                        <li>
                            <p class="title">구입처</p>
                            <p class="descript">{{ hodu_string_validation(management.management_data.vendor) }}</p>
                        </li>
                        <li>
                            <p class="title">구입처 연락처</p>
                            <p class="descript">{{ hodu_string_validation(management.management_data.vendor_tel) }}</p>
                        </li>
                    </ul>
                </div>
                <div class="mo_ex">
                    <p class="eqMo_ex">{{ hodu_string_validation(management.management_data.desc, '') }}</p>
                </div>
            </div>
        </div>

        <div class="btns">
            <input type="button" value="닫기" @click.prevent="close">
            <input type="button" value="삭제" @click.prevent="remove">
            <input type="button" value="수정" @click.prevent="modify">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

@Component({
    components: {
        
    },
})
export default class SupplyManagementDetailModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State supply_management_detail_modal_info !: hodu_home_modal_info.SupplyManagementDetailModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetSupplyManagementDetailModalInfo ?: (params : hodu_home_modal_info.SupplyManagementDetailModalInfo) => void;

    management : any = null;

    default_image = require("@/assets/images/contents/hoduhome_car_camera.png");

    mounted() : void {
        this.getHomeSupplyManagement();
    }

    /**
     * 비품 관리 이력 조회
     */
    async getHomeSupplyManagement() : Promise<void> {
        try {
            const uid = this.supply_management_detail_modal_info.uid;
            const seq = this.supply_management_detail_modal_info.seq;

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${uid}/management/${seq}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.management ) {
                throw new Error("비품 관리 이력 조회 중 오류 발생");
            }

            this.management = JSON.parse(JSON.stringify(response.data.data.management));

        } catch(e) {
            alert("비품 관리 이력 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    /**
     * 장비 관리 이력 삭제
     */
    async deleteHomeSupplyManagement() : Promise<void> {
        try {

            const uid = this.supply_management_detail_modal_info.uid;
            const seq = this.supply_management_detail_modal_info.seq; 

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${uid}/management/${seq}`, API_METHOD.DELETE);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("비품 관리 이력 삭제 중 오류 발생");
            }

            this.close();
            
        } catch(e) {
            alert("비품 관리 이력 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.getHomeSupplyManagement();
        }
    }

    /**
     * 이미지 클릭
     */
    imageClick() : void {
        if( this.management && this.management.supply && this.management.supply.supply_data && this.management.supply.supply_data.image ) {
            window.open(`app_images/${this.management.supply.supply_data.image.url}`, '_blank');
        }
    }

    /**
     * 재고수 반환
     */
    getPrevStock() : number {

        let filter = this.management.managements;

        let index = -1;
        for( const management of filter ) {
            if( management.uid == this.management.uid && management.seq == this.management.seq ) index = filter.indexOf(management) + 1;
        }

        if( index < 0 || index >= filter.length ) {
            return 0; 
        }

        let stock : number = 0;
        let array_count : number = filter.length;

        for( let i = (array_count - 1); i >= index; i-- ) {
            const management = filter[i]
            if ( management.management_data.type == 'inbound' ) stock += management.management_data.count;
            if ( management.management_data.type == 'outbound' ) stock -= management.management_data.count;
        }

        return stock;
    }

    /**
     * 재고수 반환
     */
    getStock() : number {

        let filter = this.management.managements;

        let index = -1;
        for( const management of filter ) {
            if( management.uid == this.management.uid && management.seq == this.management.seq ) index = filter.indexOf(management);
        }

        if( index < 0 ) {
            return 0; 
        }

        let stock : number = 0;
        let array_count : number = filter.length;

        for( let i = (array_count - 1); i >= index; i-- ) {
            const management = filter[i]
            if ( management.management_data.type == 'inbound' ) stock += management.management_data.count;
            if ( management.management_data.type == 'outbound' ) stock -= management.management_data.count;
        }

        return stock;
    }

    /**
     * 수정
     */
    modify() : void {
        const uid = this.supply_management_detail_modal_info.uid;
        const seq = this.supply_management_detail_modal_info.seq; 
        this.hodu_router_push(`/home/supply/${uid}/management/modify/${seq}`, () => this.close());
    }

    /**
     * 삭제
     */
    remove() : void {
        if( confirm("정말 비품 관리 이력을 삭제 하시겠습니까?") == false ) return;
        this.deleteHomeSupplyManagement();
    }

    /**
     * 닫기
     */
    close() : void {
        this.supply_management_detail_modal_info.callback?.();
        this.doSetSupplyManagementDetailModalInfo?.({
            show_modal : false,
            uid : "",
            seq : 0,
        })
    }

}
</script>

<style scoped>
    #supply_management_detail_modal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-305px; margin-left:-400px; width:800px; height: 627px; background:#fff; }
    #supply_management_detail_modal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0 30px;border-bottom: 1px solid #e7e9ea; }
    #supply_management_detail_modal .titlebox h1 { font-size: 20px; font-weight: bold; height: 90px; line-height: 90px; }
    #supply_management_detail_modal .modal_content { overflow: hidden; height: 465px; border-bottom: 1px solid #e7e9ea; }
    #supply_management_detail_modal .modal_content .modal_detail { overflow: hidden; }

    .modal_content .Moleft_con { float: left; width: 40%; background: #f1f3f5; text-align: center; height: 336px; box-sizing: border-box; padding: 0 40px; }
    .modal_content .Moleft_con .supplyMo_img { position : relative; width: 230px; height: 215px; border-radius: 5px; margin: 0 auto; margin-top: 22px; background: url('../../../assets/images/contents/hoduhome_car_camera.png') no-repeat center center; background-size: cover; box-shadow: 0 5px 10px rgb(0 0 0 / 10%); border: 3px solid #e7e9ea; font-size: 0; }
    .modal_content .Moleft_con .supplyMoImg_title { margin: 25px 0 20px 0; display: block; }
    .modal_content .Moleft_con .img_ex { color: #989bab; }

    .modal_content ul li { padding: 0 30px; overflow: hidden; line-height: 55px; height: 55px; border-bottom: 1px solid #f1f3f5; }
    .modal_content ul li p { display: inline-block; font-size: 13px; }
    .modal_content ul li p.descript { float: right; text-align: right; font-weight: bold; }
    .modal_content ul li p.descript .change_arrow { font-size: 0; width: 15px; height: 10px; display: inline-block; background: url('../../../assets/images/contents/right_arrow.svg')no-repeat center center; vertical-align: middle; background-size: cover; margin: 0 20px; }
    .modal_content ul li p.stock_chabge span { color: #477fff; }
    .modal_content .morunder_text li { width: 50%; display: inline-block; box-sizing: border-box; }
    .modal_content .morunder_text li:last-child { border-left: 1px solid #f1f3f5; }

    .modal_content .mo_ex .eqMo_ex { height : 55px; line-height: 55px; padding: 0 25px; width: 87%; font-weight: bold; color: #989bab; background: #f1f3f5; border-radius: 10px; margin: 9px auto 0 auto; }

    #supply_management_detail_modal .btns { overflow: hidden; border-radius: 0 0 5px 5px }
    #supply_management_detail_modal .btns input { transition:0.2s; width: 33.3%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold;background: #fff; }
    #supply_management_detail_modal .btns input:hover { background:#f1f3f5; }
</style>