<template>
    <div id="organization_default_emp_off_modal" class="modal">

        <div class="titlebox">
            <h1>연차 기준 설정</h1>
        </div>

        <div class="content">
            <div class="container">
                <div class="setting_ex">
                    <p><span class="setting_ex_ic">아이콘</span>연차발생 기준 설정</p>
                    <p>
                        현재 날짜 기준으로 갱신되며, 기존 작성된 연차수 변경이 있을 수 있습니다<br>
                        계산된 연차 개수를 변경하려면 연차 설정 해당 직원 개별 설정에서 변경할 수 있습니다<br>
                        단, 재설정 시 현재 날짜 기준으로 다시 변경됩니다.
                    </p>
                    <span>* 입사일 기준은 호두웍스 기본	&#183; 인사 정보에 입사일 기록이 작성되어야 반영됩니다.</span>
                </div>

                <div class="setting_radio">
                    <span>연차발생 기준 선택</span>
                    <input type="radio" id="EmpOffYear" name="EmpOffDefault" value="FiscalYear" v-model="annual_type">
                    <label for="EmpOffYear" class="empoff_radio">회계연도 (1월 1일)</label>
                    <input type="radio" id="EmpOffJoin" name="EmpOffDefault" value="JoinDate" v-model="annual_type">
                    <label for="EmpOffJoin" class="empoff_radio">입사일</label>
                </div>
            </div>
        </div>

        <div class="btns">
            <input type="button" value="취소" @click.prevent="close">
            <input type="button" value="확인" @click="confirm">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

@Component({
    components: {
        
    },
})
export default class OrganizationDefaultEmpOffModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_default_emp_off_modal_info !: organization_modal_interface.OrganizationDefaultEmpOffModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationDefaultEmpOffModalInfo ?: (params : organization_modal_interface.OrganizationDefaultEmpOffModalInfo) => void;

    annual_type : string = "FiscalYear"; // FiscalYear, JoinDate

    mounted() : void {
        this.getGroup();
    }

    async getGroup() {

        try {
            const group_id = this.organization_default_emp_off_modal_info.group_id;

            const response = await this.hodu_api_call(`api/v1/groupsweb/getDetail/${group_id}/0`, API_METHOD.GET, null);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.data ) {
                throw new Error("그룹 정보 조회중 오류발생");
            }

            const data = response.data.data.data;

            this.annual_type = data.group_info.annual_type ?? 'FiscalYear';

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("그룹 정보 조회 중 오류 발생");
            this.close();
        }
    }

    async confirm() {

        try {
            
            const response = await this.hodu_api_call(`api/v1/groups/${this.organization_default_emp_off_modal_info.group_id}/organization/default_off?annual_type=${this.annual_type}`, API_METHOD.POST);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("연차 수 표시 여부 변경 중 오류 발생");
            }

            this.organization_default_emp_off_modal_info.callback?.();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("연차 기준 설정 중 오류 발생");
        } finally {
            this.close();
        }
        
    }

    close() {
        this.doSetOrganizationDefaultEmpOffModalInfo?.({
            show_modal : false,
            group_id : 0
        });
    }

}
</script>

<style scoped>
    #organization_default_emp_off_modal { display: block; border-radius: 5px; position: absolute; left:50%; top:50%; margin-top:-250px; margin-left:-315px; width:630px; height: auto; background:#fff; }
    #organization_default_emp_off_modal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0 30px;border-bottom: 1px solid #e7e9ea; }
    #organization_default_emp_off_modal .titlebox h1 { font-size: 20px; font-weight: bold; height: 90px; line-height: 90px; }

    #organization_default_emp_off_modal .btns { overflow: hidden; border-radius: 0 0 5px 5px; border-top: 1px solid #e7e9ea; }
    #organization_default_emp_off_modal .btns input { transition:0.2s; width: 50%; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: bold;background: #fff; }
    #organization_default_emp_off_modal .btns input:hover { background:#f1f3f5; }

    .container { padding: 20px 30px; box-sizing: border-box; }
    .container .setting_ex { background: #f1f3f5; border-radius: 5px; padding: 15px; box-sizing: border-box; line-height: 23px; font-size: 14px; font-weight: bold; }
    .container .setting_ex p:first-child { padding-left: 0; margin-bottom: 10px; opacity: 1; }
    .container .setting_ex p { padding-left: 30px; box-sizing: border-box; opacity: .7; } 
    .container .setting_ex span { display: block; font-size: 13px; color: #ff5757; padding-left: 30px; box-sizing: border-box; margin-top: 10px; } 
    .container .setting_ex .setting_ex_ic { width: 20px; height: 20px; display: inline-block; font-size: 0; background: url('../../../assets/images/contents/information25.svg') no-repeat center center; vertical-align: top; margin: 2px 7px 0 0; padding: 0; }
    .container .setting_radio { margin: 20px 0 5px 10px; }
    .container .setting_radio span { font-size: 14px; font-weight: bold; display: block; margin-bottom: 15px; }
    .container .setting_radio .empoff_radio { margin-right: 55px; font-size: 14px; padding-top: 1px; }

</style>