<template>
    <div id="organization_setting" class="section_ce_fix" :class="selected_member_tab">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 id="viewTitle" class="title_doc">멤버 관리</h3> 
            <!-- 클릭시 새창에 PDF를 열어주세요. 직원관리는 2페이지부터 열리게 해주세요 -->
            <span class="ussM_box"><a href="app_images/hoduWorks_manual02.pdf#page=2" target="_blank" rel=noopener title="사용 안내서를 확인해주세요" class="use_manual">?</a></span>
        </div>
        
        <div class="title_box2">
            <div class="btn_area">
                <input id="tab1"  class="tab" type="radio" name="tabs" value="MEMBER" :checked="selected_member_tab == 'MEMBER'">
                <label class="member_set" for="tab1" @click.prevent="selectTab(organization_enum.ORGANIZATION_SETTING_TAB.MEMBER)">권한</label>

                <input id="tab2"  class="tab" type="radio" name="tabs" value="RANK" :checked="selected_member_tab == 'RANK'">
                <label class="member_set" for="tab1" @click.prevent="selectTab(organization_enum.ORGANIZATION_SETTING_TAB.RANK)">기본 &middot; 인사</label>
                
                <input id="tab3" class="tab" type="radio" name="tabs" value="APPROVAL" :checked="selected_member_tab == 'APPROVAL'">
                <label class="member_set" for="tab11" @click.prevent="selectTab(organization_enum.ORGANIZATION_SETTING_TAB.APPROVAL)">결재</label>

                <input id="tab4"  class="tab" type="radio" name="tabs" value="EMP_OFF" :checked="selected_member_tab == 'EMP_OFF'">
                <label class="member_set" for="tab3" @click.prevent="selectTab(organization_enum.ORGANIZATION_SETTING_TAB.EMP_OFF)">연차</label> 

                <input id="tab5" class="tab" type="radio" name="tabs" value="QUITTER" :checked="selected_member_tab == 'QUITTER'" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                <label class="member_set" for="tab11" @click.prevent="selectTab(organization_enum.ORGANIZATION_SETTING_TAB.QUITTER)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">퇴사자</label>
            </div>
        </div>

        <!-- 멤버 -->
        <GroupMemberComp
            :event_bus="event_bus"
            :enable_search="true"
            v-if="isMember() == true" />
        
        <!-- 직원 기본 설정(부서, 직급) -->
        <OrganizationRankSetting 
            :event_bus="event_bus"
            :class="{ pos_close : is_close_position == true }" 
            v-if="isRank() == true" />

        <!-- 직원 결재 설정 -->
        <OrganizationApprovalSetting 
            :event_bus="event_bus" 
            v-if="isApproval() == true" />

        <!-- 직원 연차 설정 -->
        <OrganizationEmpOffSetting 
            :event_bus="event_bus" 
            v-if="isEmpOff() == true" />

        <!-- 퇴사자 -->
        <OrganizationQuitterSetting 
            :event_bus="event_bus" 
            v-if="isQuitter() == true" />

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const OrganizationInfo = namespace('OrganizationInfo');

import GroupMemberComp from '@/components/GroupMemberComp.vue';
import OrganizationRankSetting from '@/components/organization/OrganizationRankSetting.vue';
import OrganizationApprovalSetting from '@/components/organization/OrganizationApprovalSetting.vue';
import OrganizationEmpOffSetting from '@/components/organization/OrganizationEmpOffSetting.vue';
import OrganizationQuitterSetting from '@/components/organization/OrganizationQuitterSetting.vue';

import { organization_enum } from '@/model/organization';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        GroupMemberComp, OrganizationRankSetting, OrganizationApprovalSetting, 
        OrganizationEmpOffSetting, OrganizationQuitterSetting,
        ResizeObserver
    },
})
export default class OrganizationMemberSetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;

    event_bus : Vue = new Vue();

    /**
     * @OrganizationInfo.State
     */
    @OrganizationInfo.State selected_member_tab !: organization_enum.ORGANIZATION_SETTING_TAB;

    /**
     * @OrganizationInfo.Action
     */
    @OrganizationInfo.Action doSetOrganizationMemberSelectedTab ?: (params : organization_enum.ORGANIZATION_SETTING_TAB) => void;

    is_close_position : boolean = false;

    mounted() : void {

    }

    /**
     * 탭 선택
     */
    selectTab(tab : organization_enum.ORGANIZATION_SETTING_TAB) : void {
        this.doSetOrganizationMemberSelectedTab?.(tab);
    }

    /**
     * 직원 기본 설정 탭
     */
    isMember() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.MEMBER;
    }

    /**
     * 직원 기본 설정 탭
     */
    isRank() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.RANK;
    }

    /**
     * 직원 결재 설정 탭
     */
    isApproval() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.APPROVAL;
    }

    /**
     * 직원 연차 설정 탭
     */
    isEmpOff() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.EMP_OFF;
    }

    /**
     * 직원 퇴사자 탭
     */
    isQuitter() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.QUITTER;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        if( this.isMember() == true ) {
            this.event_bus.$emit('OrganizationMemberResize');
            return;
        }

        else if( this.isRank() == true ) {
            this.event_bus.$emit('OrganizationRankSettingResize');
            return;
        }

        else if( this.isApproval() == true ) {
            this.event_bus.$emit('OrganizationApprovalSettingResize');
        }

        else if( this.isEmpOff() == true ) {
            this.event_bus.$emit('OrganizationEmpOffSettingResize');
            return;
        }

        else if( this.isQuitter() == true ) {
            this.event_bus.$emit('OrganizationQuitterSettingResize');
            return;
        }
    }

}
</script>

<style>
    #organization_setting .title_box2{padding: 0 25px 0 20px !important;line-height: 61px;overflow: hidden;border-bottom: 1px solid #e7e9ea;}
    #organization_setting .approval_menu{opacity: 1;-webkit-transition: .1s;transition: .1s;position: absolute;right: 0;top:124px;bottom: 0;background-color: #fff;width: 21%;padding: 0 0 50px 0;border-left: 1px solid #e1e4e6;z-index: 100;}
    #organization_setting .approval_menu .approval_wrtie{width: 100%;line-height: 65px;border-bottom: 1px solid #e7e9ea;padding: 0 20px;position: relative;-webkit-box-sizing: border-box;box-sizing: border-box;}
    #organization_setting .approval_menu .app_wrt_btn{background: #fff; border:1px solid #477fff !important;font-size: 15px !important;color:#477fff;padding: 0 50px !important;width:100%;height: 45px;font-weight:bold;border-radius: 5px !important;}
    #organization_setting .approval_menu .miniDep_box{position: relative;overflow: hidden;margin-bottom: 0;-webkit-transition: 0.1s;transition: 0.1s;padding: 0;line-height: 50px;width: 100%;color: #8091a8;-webkit-box-sizing: border-box;box-sizing: border-box;font-size: 14px;font-weight: bold;}
    #organization_setting .approval_menu .miniDep_box .position_list{}
    #organization_setting .approval_menu .miniDep_box .position_list.position_title{background-color:#f1f3f5;}
    #organization_setting .approval_menu .miniDep_box .position_list p span.modify {display: inline-block;width: 20%;position: absolute;top: 0;right: 0;text-align: center;background: red;color: #fff;font-weight: bold;font-size: 15px;}
    #organization_setting .approval_menu .miniDep_box .position_list p.till{position: relative; color: rgb(000, 034, 080);display:inline-block;width: 30%;text-align: center;border-right: 1px solid #e7e9ea;border-bottom: 1px solid #e7e9ea;box-sizing: border-box; height : 60px; line-height : 60px; }
    #organization_setting .approval_menu .miniDep_box .position_list p.till:hover> span.updown{display: block;}
    #organization_setting .approval_menu .miniDep_box .position_list p.pos_name{position: relative;color:rgb(000, 034, 080);display:inline-block; width: 70%;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;padding-left: 20px; cursor: pointer; height : 60px; line-height : 60px; }
    #organization_setting .approval_menu .miniDep_box .position_list p.pos_name input.dal{display: none;height: 100%;width: 20%;position: absolute;top: 0;right: 0;text-align: center;background: red;color: #fff;font-weight: bold;font-size: 18px;}
    #organization_setting .approval_menu .miniDep_box .position_list p.pos_name:hover>.dal{display: block;}
    #organization_setting .approval_menu .miniDep_box li.position_list p.till span.updown{position: absolute;height: 100%;top: 8px;right: 5px;line-height: 35px;display: none;}
    #organization_setting .approval_menu .miniDep_box li.position_list p.till span.updown input.upbtn[type="button"]{width: 20px;height: 20px;display: block;background: url(../../assets/images/side_area/bt_close.png) no-repeat center center;}
    #organization_setting .approval_menu .miniDep_box li.position_list p.till span.updown input.downbtn[type="button"]{width: 20px;height: 20px;display: block;background: url(../../assets/images/side_area/bt_open.png) no-repeat center center;margin-top: 4px;}
    #organization_setting .approval_menu .miniDep_box li.position_list p.till span.updown input.upbtn:hover{background:#f1f3f5 url(../../assets/images/side_area/bt_close_b.png) no-repeat center center !important;border-radius: 20px;}
    #organization_setting .approval_menu .miniDep_box li.position_list p.till span.updown input.downbtn:hover{background:#f1f3f5 url(../../assets/images/side_area/bt_open_b.png) no-repeat center center !important;border-radius: 20px;}
    #organization_setting input[type='radio']:not(old):checked+label.member_set{ background:#2b3b5f; color: #fff;}
    #organization_setting label.member_set{margin: 13px 0 ;padding: 0;background: none;border-radius: 20px;color: #232848;width:120px;font-size: 14px;height:35px;text-align:center;line-height:35px;display:inline-block;box-sizing: border-box;font-weight: bold;}

    #organization_setting .filter_tap{height: 100%;}
    #organization_setting .approval_content{opacity: 1;-webkit-transition: .1s;transition: .1s;position: relative;/*top:124px;left: 0;right: 281px;*/height:100%;padding: 0 0 50px 0;/*border-right: 1px solid #e1e4e6;*/z-index: 100;}
    #organization_setting .approval_content .content_top{position: relative; height: 65px;border-bottom: 1px solid #e7e9ea;}
    #organization_setting .approval_content .content_top h3{line-height: 40px;height: 100%;font-size: 16px;width: auto;padding: 13px 0 0 3px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;box-sizing: border-box;}
    #organization_setting .approval_content .content_top .bt_hambuger{position: relative;margin-left: 20px;padding: 11px 0 0 30px;display: inline-block;width: 40px;height: 100%;background: url(../../assets/images/contents/bt_hambuger.png) no-repeat center center;box-sizing: border-box;}
    #organization_setting .approval_content .content_top .bt_hambuger2{position: relative;margin-left: 20px;padding: 11px 0 0 30px;display: inline-block;width: 40px;height: 100%;background: url(../../assets/images/contents/bt_hambuger.png) no-repeat center center;box-sizing: border-box;}
    #organization_setting .approval_content .content_top #filter_search{position: absolute;top: 12px;left:144px;width: 355px;-webkit-transition: 0.2s;transition: 0.2s;height: 40px;line-height: 40px;padding: 0px 10px;font-size: 15px;font-weight: normal;-webkit-box-sizing: border-box;box-sizing: border-box;border: 1px solid #e7e9ea;display: inline-block;}
    #organization_setting .approval_content .content_top .cont_del{font-size: 14px;font-weight:bold;position: absolute;top: 12px;right:20px;padding: 11px 0 0 10px;line-height: 40px;display: inline-block;padding: 0px 30px;/*border: 1px solid #dddddd;*/}
    #organization_setting .approval_content .content_top .cont_del2{font-size: 14px;font-weight:bold;position: absolute;top: 12px;right:110px;padding: 11px 0 0 10px;line-height: 40px;display: inline-block;padding: 0px 30px;/*border: 1px solid #dddddd;*/}
    #organization_setting .approval_content .content_top .down{font-size: 14px;font-weight:bold;position: absolute;top: 12px;right:20px;padding: 11px 0 0 10px;line-height: 40px;display: inline-block;padding: 0px 30px;/*border: 1px solid #dddddd;*/}
    #organization_setting .wrapper {padding-top:20px; width: 15%;height: auto;box-sizing: border-box;border-right: 1px solid#e7e9ea;font-weight: bold;color: rgb(086, 088, 115);}
    #organization_setting label { padding-left: 10px }
    #organization_setting .chk { height: 40px; line-height: 40px; text-align: left;position: relative; }
    #organization_setting .cld { height: 35px; line-height: 35px;  text-align: left;position: relative; cursor: pointer;}
    /* 최하위 왼쪽 패딩 더 주기 */
    #organization_setting .sub2 .cld { padding-left: 0px; text-align: left;margin-left: 0;box-sizing: border-box;}
    /*체크박스 디자인*/
    #organization_setting .chk input{margin: 0;padding: 0;opacity: 0;width: 0;}
    #organization_setting .approval_content input[type='checkbox']:not(old)+label{margin-top: 6px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid rgb(210, 211, 217);background: rgb(210, 211, 217) url(../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;margin-right: 8px;}
    #organization_setting .approval_content input[type='checkbox']:not(old):checked + label{background-color: #477fff !important;border-color: #477fff;}
    #organization_setting .approval_content input[type='checkbox']:not(old):checked:disabled + label{ opacity: 0.5; cursor: normal; }
    /*체크박스 텍스트*/
    #organization_setting .chk p{position: absolute;left: 30px;top: 0px;}
    #organization_setting .cld p{position: absolute;left: 60px;top: 0px;}
    #organization_setting .sub2 .cld p{position: absolute;left: 50px;top: 0px;}
    /* 그룹과 팀 리스트에 적용 */
    #organization_setting ul li { position: relative;  }
    /* 버튼 */
    #organization_setting .arw, #organization_setting .arw2, #organization_setting .arw3 { background: none;color: inherit;border: none;padding: 0;font: inherit;cursor: pointer;outline: inherit; z-index: 1000; position: absolute; left: 35px;top:13px; width: 11px; height: 11px; width: 0;height: 0; cursor: pointer;border-bottom: 6px solid transparent;border-top: 6px solid transparent;border-left: 8px solid #000;}
    #organization_setting .level4 > li > .arw { top : 6px; }
    #organization_setting .level4 > li.showTeam > .arw { top : 10px; }

    /* 상단 삐져나오는 라인 감추기 */
    #organization_setting .topBlank { z-index: 100; position: absolute; top: 0; left:35px;width: 15px; height: 14px; background: #fff; display: block; }
    /* 하단 삐져나오는 라인 감추기 */
    #organization_setting .btmBlank { z-index: 100; position: absolute; bottom: 1px;left:35px; width: 15px; height: 18px; background: #fff; display: block; }
    /* 세로 선 */
    #organization_setting .vertiLine { height: 100%; width: 1px; background: #dadbde; display: none; position: absolute; left: 40px; top: 0; }
    /* 가로 선 */
    #organization_setting .horiLine { width: 35px; height: 1px; background: #dadbde; display: none; position: absolute; top: 15px; left: 0px; }
    /* 최하위 그룹 디폴트로 감추기 */
    #organization_setting .sub2 { display: none;}
    /* 팀 보일때 선이랑 다같이 보이기 */
    #organization_setting .showTeam > .sub2 { display: block; padding-left: 40px;box-sizing: border-box;}
    #organization_setting .showTeam .horiLine, #organization_setting .showTeam .vertiLine { display: block; }
    #organization_setting .showTeam > input.arw, #organization_setting .showTeam > input.arw2, #organization_setting .showTeam > input.arw3 {border-left: 6px solid transparent;border-right: 6px solid transparent;border-top: 8px solid #000;}
    #organization_setting .circle{display: inline-block;width:10px;height:10px;margin-top: 10px;margin-left: 35px;border-radius: 50%;font-size: 0;border: 1px solid #40c8a0; background: #40c8a0;}

    #organization_setting .sortHeader {line-height: 60px;background: #f2f5f7;height: 60px;border-bottom: 1px solid #e7e9ea;overflow: visible !important;font-size: 13px !important;}
    #organization_setting .sortHeader > li {position: relative;display: inline-block;padding: 0;margin: 0 !important;}
    #organization_setting .section_scroll .group3{width: 39%;}
    #organization_setting .section_scroll .group3 > ul > li{margin-left: 38px;}
    #organization_setting .section_scroll .group > ul > li{margin-left: 38px;}
    #organization_setting .headerSort {float: left;padding: 8px 0 7px 0;cursor: pointer;}

    #organization_setting .section_scroll .title > span{margin-left: 15px;}
    #organization_setting .section_scroll .title3{width: 45%;font-size: 14px;}
    #organization_setting .section_scroll .title3 > span{margin-left: 15px;font-size: 14px;}
    #organization_setting .section_scroll .time{width: 17%;}
    #organization_setting .content .notiListUl > li{-webkit-box-sizing: border-box;box-sizing: border-box;position: relative;/* width: 100%; */border-bottom: 1px solid #f1f3f5;height: 70px;line-height: 70px;}
    #organization_setting .content .notiListUl > li:hover{background: rgb(248,249,250, 0.3);}
    #organization_setting .content .notiListUl > li:hover a.modi_look{display: block;}
    #organization_setting a.modi_look{display: none;}
    #organization_setting img.modi{position: absolute;right: 30px;top: 23px; font-size: 0;width: 25px;height:25px; opacity: .7;}
    #organization_setting .content .notiListUl > li p.num, #organization_setting .content .notiListUl > li p.notiCheck{padding-right: 0;}
    #organization_setting .content .notiListUl > li p{display: inline-block;font-weight: bold;-webkit-box-sizing: border-box;box-sizing: border-box;padding-left: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-size: 14px;}
    #organization_setting .content .notiListUl > li p a { transition: 0.1s; display: inline-block; line-height: 30px; height: 30px; border-radius: 15px; }
    #organization_setting .content .notiListUl > li:hover p a { display: inline-block; background:#477fff !important; color:#fff !important; padding:0 15px;  }
    #organization_setting .content .notiListUl > li:hover p a:hover {background: #2c67ef !important}
    #organization_setting .section_scroll .notiCheck{width: 10.5%;text-align: center;}
    #organization_setting #noticeScroll3{position: absolute; top: 66px; left: 0px;right: 0;}
    #organization_setting #noticeScroll .sortHeader input[type='checkbox']:not(old) + label{margin-top: 18px;}
    #organization_setting #noticeScroll input[type='checkbox']:not(old) + label{margin-top: 16px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}

    #organization_setting .content .notiListUl > li div.num, #organization_setting .content .notiListUl > li p.notiCheck{padding-right: 0;}
    #organization_setting .content .notiListUl > li div{display: inline-block;font-weight: bold;-webkit-box-sizing: border-box;box-sizing: border-box;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
    #organization_setting .title_info li.list_title{display:inline-block;margin-left: 15px;font-size:14px;}
    #organization_setting .title_info li.list_day{display:inline-block;margin-left: 15px;font-size:14px;}
    #organization_setting .status_info {width:80%;margin-top: 33px;}
    #organization_setting .status_info li.list_Progress{height:15px;font-size:14px;position: relative;}
    #organization_setting .status_info li.list_Protext{line-height:30px;height:30px;color:#aaa;font-weight:normal;}
    #organization_setting .status_info li.list_Protext > span{color:#000;font-weight: bold;}
    #organization_setting .group_info li.list_group{line-height:75px;height:50px;font-size:14px;}
    #organization_setting .group_info li.list_rank{line-height:30px;height:30px;color:#aaa;font-weight:normal;}
    #organization_setting .notiListUl li p.status span.Progress_bar{background: #eceeef;font-size: 0px;display: block;height: 11px;margin: 30px 0;border-radius: 20px;width:100%;font-size:0;}
    #organization_setting .notiListUl li p.status span.Progress_ing{position: absolute;top: 0;left: 0;right: 0;font-size: 0px;display: block;height: 11px;border-radius: 20px;font-size:0;}
    #organization_setting .quick{color:#fff;border-radius:3px;background-color:#ff1955;padding:1px 5px;margin-right:6px;font-size: 12px;font-weight: normal;}

    #organization_setting .section_scroll { transition: 0.1s; }
    #organization_setting .section_scroll .title2{width: 39%;}
    #organization_setting .section_scroll .group2{width: 22%;}
    #organization_setting .section_scroll .writtenBy2{width: 21%;}
    #organization_setting .section_scroll .writtenBy2 > span{margin-left: 26px;}
    #organization_setting .section_scroll .group2 > ul > li{margin-left: 38px;}
    #organization_setting .section_scroll .title4{width: 28%;}
    #organization_setting .section_scroll .title4 > span{margin-left: 15px;}
    #organization_setting .section_scroll .group4{width: 14%;}
    #organization_setting .section_scroll .writtenBy4{width: 20%;}
    #organization_setting .section_scroll .writtenBy4 > span{margin-left: 26px;}
    #organization_setting .section_scroll .group4 > ul > li{margin-left: 38px;}
    #organization_setting .section_scroll .lastday4{width: 23%;}
    #organization_setting .section_scroll .lastday4 > span{margin-left: 8px;}
    #organization_setting .title_info2 li.list_title{margin-left: 15px;font-size:14px;}
    #organization_setting .group_info2 li.list_group{font-size:14px;}

    #organization_setting .title img, #organization_setting .title3 img, #organization_setting .title4 img{width: 40px;vertical-align: middle;border:3px solid transparent;border-radius: 50%;}
    #organization_setting .title img.king, #organization_setting .title3 img.king, #organization_setting .title4 img.king{border:3px solid #477fff;border-radius: 50%;}
    #organization_setting .approval_menu .miniDep_box2{position: relative;overflow: hidden;margin-bottom: 0;-webkit-transition: 0.1s;transition: 0.1s;padding: 0;line-height: 50px;width: 100%;color: #8091a8;-webkit-box-sizing: border-box;box-sizing: border-box;font-size: 14px;}
    #organization_setting .approval_menu .miniDep_box2 .time_list{border-bottom: 1px solid #e7e9ea;padding: 15px 0;}
    #organization_setting .approval_menu .miniDep_box2 .time_list p{position: relative;color:rgb(000, 034, 080);width: 100%;box-sizing: border-box;padding-left: 20px;line-height: 25px; height : 25px; }
    #organization_setting .approval_menu .miniDep_box2 .time_list p.line2{ height : 50px; }
    #organization_setting .approval_menu .miniDep_box2 .time_list p.time_title{font-weight: bold;font-size: 13px;}
    #organization_setting .approval_menu .miniDep_box2 .time_list p.gray_p{color: rgb(155, 161, 174);position: relative;font-size: 13px;}
    #organization_setting .approval_menu .miniDep_box2 .time_list p.gray_p span{color: rgb(155, 161, 174);position: absolute;top: 0; right: 20px;}
    #organization_setting .cld .p_num{position: absolute;right: 30px;}
    #organization_setting .dis{height: 100%;}

    #container #section_ce.left_area_close #organization_setting .title_box2 { padding-left : 81px !important; }
    #container #section_ce.left_area_close #organization_setting .approval_content { left : 61px; }
    #container #section_ce.left_area_close #organization_setting .approval_content .section_scroll { padding-left: 0; }

    /* #organization_setting.RANK .title_box2 { padding-right : 80px !important; position: relative; } */
    /* #organization_setting .pos_close_btn { display: none; width: 0; height: 0; background: none; } */
    /* #organization_setting.RANK .pos_close_btn { position: absolute; display: inline-block;width: 40px;height: 40px; background: url(../../assets/images/contents/bt_hambuger.png) no-repeat center center;box-sizing: border-box; right: 10px; top: 10.5px;} */
    /* #organization_setting.RANK .pos_close_btn.on { background: url(../../assets/images/contents/ic_filtercan_btn.png) no-repeat center center !important;background-size: 35px !important; } */
    
    /* 추가 */
    .notiListUl, #organization_setting #work_type_list_scroll ul { padding-bottom:150px; }
    
</style>

