<template>
    <!-- 환자리스트에서 예약시 #appointment_create_modal class추가 one -->
    <div id="appointment_create_modal" class="modal appointment_create_modal v1" :class="{
        one : appointment_create_modal_info.is_create_one_patient == true
    }">
        <div class="titleBox"><h1 class="borderbot">예약</h1></div>
        <div class="modal_content">
            <div class="left_content">
                
                <!-- 의료진을 선택하고 들어왔을 시 하단 select disabled, 환자관리에서 의료진이 여러명일때 select 가능  -->
                <div class="docSelected selectDocs">
                    <select :disabled="computedDepartmentDoctor.length < 2" @change="selectDoctor($event.target.value)">
                        <option :key="doctor.doctor_code" v-for="doctor in computedDepartmentDoctor" :value="doctor.doctor_code" :selected="selected_doctor != null && doctor.doctor_code == selected_doctor.doctor_code">
                            {{ doctor.doctor_name }}
                        </option>
                        <!-- <option value="">김동현 (내과)</option>
                        <option value="">이여진 (내과)</option> -->
                    </select>
                </div>

                <!-- 의료진이 여러명일때 하단 selectDocs 숨기기  -->
                <!-- <div class="docSelected selectDocs" v-if="computedDepartmentDoctor.length == 1">
                    <p class="docDet0">
                        <span class="docDet1">{{ computedDepartmentDoctor[0].doctor_name }}</span> -->
                        <!-- <span class="docDet1">김동현</span>
                        <span class="docDet2">내과</span> -->
                    <!-- </p>
                </div> -->

                <div class="selectDiv">
                    
                    <!-- 오늘 부터 이번 달 말 까지 나타냄 -->
                    <select @change="selectDate(new Date(Number($event.target.value)).getDate())">
                        <option :value="day.getTime()" :key="day.getTime()" v-for="day in computedDayList"
                                :selected="selected_date.getFullYear() == day.getFullYear() && 
                                           selected_date.getMonth()    == day.getMonth()    &&
                                           selected_date.getDate()     == day.getDate()">
                            {{ `${hodu_date_to_format_string(day, "YY.MM.DD")} ${getDayOfWeekByDate(day)}` }}
                        </option>
                        <!-- <option value="" selected>20.03.19 목</option>
                        <option value="">20.03.20 금</option>
                        <option value="">20.03.21 토</option>
                        <option value="">20.03.23 월</option>
                        <option value="">20.03.24 화</option>
                        <option value="">20.03.25 수</option>
                        <option value="">20.03.26 목</option>
                        <option value="">20.03.27 금</option>
                        <option value="">20.03.28 토</option>
                        <option value="">20.03.30 월</option>
                        <option value="">20.03.31 화</option> -->
                    </select>

                    <select @change="selectTime(new Date(Number($event.target.value)).getHours(), new Date(Number($event.target.value)).getMinutes())">
                        
                        <option :value="selected_date.getTime()" v-if="computedAvailableList.length < 1">예약가능 시간 없음</option>

                        <option :value="new Date(available.original_timetable).getTime()" :key="new Date(available.original_timetable).getTime()" v-for="available in computedAvailableList"
                                :selected="selected_date.getFullYear() == new Date(available.original_timetable).getFullYear() &&
                                           selected_date.getMonth()    == new Date(available.original_timetable).getMonth()    &&
                                           selected_date.getDate()     == new Date(available.original_timetable).getDate()     &&
                                           selected_date.getHours()    == new Date(available.original_timetable).getHours()    &&
                                           selected_date.getMinutes()  == new Date(available.original_timetable).getMinutes()">
                            {{ `${amPmStringToLocaleAmPmString(hodu_date_to_format_string(new Date(available.original_timetable), 'hh:mm a'))} (${available.coalesce}/${available.reservation_term_by_people})` }}
                        </option>
                        <!-- <option value="" selected>2:45 pm (2/5)</option>
                        <option value="">3:00 pm (0/5)</option>
                        <option value="">3:15 pm (5/5)</option>
                        <option value="">3:30 pm (0/5)</option>
                        <option value="">3:45 pm (4/5)</option>
                        <option value="">4:00 pm (5/5)</option>
                        <option value="">4:15 pm (4/5)</option>
                        <option value="">4:30 pm (5/5)</option>
                        <option value="">4:45 pm (4/5)</option>
                        <option value="">5:00 pm (4/5)</option>
                        <option value="">5:15 pm (4/5)</option>
                        <option value="">5:30 pm (4/5)</option>
                        <option value="">5:45 pm (5/5)</option>
                        <option value="">6:00 pm (2/5)</option>
                        <option value="">6:15 pm (4/5)</option>
                        <option value="">6:30 pm (0/5)</option>
                        <option value="">6:45 pm (3/5)</option>
                        <option value="">7:00 pm (2/5)</option>
                        <option value="">7:15 pm (2/5)</option>
                        <option value="">7:30 pm (1/5)</option>
                        <option value="">7:45 pm (5/5)</option> -->
                    </select>
                </div>
                
                <div id="modal_appointment_list_scroll">

                    <!-- 결과가 없을 때 -->
                    <div class="noResult" :class="{ on : computedSelectedTimeAppointment.length < 1 }">
                        <p>해당 시간에는 예약이 없습니다</p>
                    </div>

                    <ul class="otherReser">

                        <li :key="index" v-for="(appointment, index) in computedSelectedTimeAppointment">
                            <p class="pName">{{ appointment.user_name }}</p>
                            <!-- 개인정보 이유로 우선 성별과 생년월일은 숨김 -->
                            <!-- <p class="pSex f">여자</p>
                            <p class="pAge">1984.12.09</p> -->
                            <p class="pTel">연락처 : {{ appointment.nok_phone_number && appointment.nok_phone_number.length > 0 ? appointment.nok_phone_number : '-' }}</p>
                            <p class="pMemo">증상 : {{ appointment.symptoms && appointment.symptoms.length > 0 ? appointment.symptoms : '-' }}</p>
                            <input type="button" value="삭제" v-if="getAppointmentDeletePermission()" @click="deleteEvent(appointment.event_id)"/>
                        </li>
                        
                        <!-- <li> -->
                            <!-- <p class="pName">박윤아</p> -->
                            <!-- 개인정보 이유로 우선 성별과 생년월일은 숨김 -->
                            <!-- <p class="pSex f">여자</p>
                            <p class="pAge">1984.12.09</p> -->
                            <!-- <p class="pTel">010-0202-2020</p> -->
                            <!-- <p class="pMemo">감기기운</p> -->
                            <!-- <input type="button" class="" value="삭제"/> -->
                        <!-- </li> -->

                        <!-- <li>
                            <p class="pName">김 연</p> -->
                            <!-- <p class="pSex m">남자</p>
                            <p class="pAge">1990.01.01</p> -->
                            <!-- <p class="pTel">010-0202-2020</p>
                            <p class="pMemo">목아픔, 코막힘, 가래</p>
                            <input type="button" class="cancel" value="삭제"/>
                        </li> -->
                    </ul>
                </div>
            </div>
            
            <div class="main_content">
                <!-- <div class="blocking"></div>
                <div class="blocking1"></div>
                <div class="blocking2"></div>
                <div class="blocking3"></div>
                <div class="blocking4"></div> -->

                <div class="findDiv">
                    <input type="text" class="findPInput" placeholder="환자명으로 검색하세요" :value="patient_query" @input="patient_query = $event.target.value">
                    <input type="button" class="patient_schBtn" value="환자 찾기" @click="searchOnOff"/>
                </div>

                <ul class="patientDetUl">
                    
                    <li>
                        <label for="patientName">환자 이름</label>
                        <!-- <input type="text" id="patientName" placeholder="예) 홍길동" /> -->
                        <input type="text" id="patientName" :value="selected_patient.user_name" disabled/>
                    </li>

                    <!-- 개인정보로인해 잠시 주석처리 -->
                    <!-- <li>
                        <div class="fl">
                            <label for="patientAge">환자 생년월일</label>
                            <input type="text" id="patientAge" placeholder="예) 1999.01.01" />
                        </div>
                        <div class="fl pSexDiv">
                            <label for="patientSex">성별</label>
                            <select name="" id="patientSex">
                                <option value="">남자</option>
                                <option value="">여자</option>
                            </select>
                        </div>
                    </li> -->

                    <li>

                        <label for="patientTel2">환자 연락처</label>
                        <input type="text" id="patientTel3" class="patientTel3" 
                               :value="selected_patient.user_phone_number && selected_patient.user_phone_number.length > 0 ? selected_patient.user_phone_number : '-'" disabled/>

                        <!-- <label for="patientTel2">환자 연락처</label>
                        <select name="" id="patientTel1" class="patientTel1">
                            <option value="">010</option>
                            <option value="">02</option>
                        </select>
                        <span class="hy">-</span>
                        <input type="text" id="patientTel2" class="patientTel2" placeholder="끝 7~8자리" /> -->
                    </li>

                    <!-- 고유 번호 숨김 -->
                    <!-- <li>
                        <label for="patientCode">환자 고유번호</label>
                        <input type="text" id="patientCode" placeholder="-" />
                    </li> -->
                    
                    <li>
                        <label for="patientMemo">진료목적</label>
                        <textarea id="patientMemo" placeholder="방문 사유" :value="memo" @input="memo = $event.target.value"></textarea>
                    </li>
                </ul>
                
                <!-- mCustomScroller 필요, .patient_schBtn클릭시 toggleClass추가 on -->
                <div id="patient_list_scroll" class="findResult" :class="{ on : is_search_patient == true }">
                    
                    <!-- 결과가 없을 때 -->
                    <div class="noResult" :class="{ on : computedPatientList.length < 1 }">
                        <p>검색된 결과가 없습니다. 환자명을 다시 검색하세요</p>
                    </div>

                    <ul>

                        <li :key="index" v-for="(patient, index) in computedPatientList">
                            <a @click="setPatientInfo(patient)">
                                <p class="pName">{{ patient.user_name }}</p>
                                <!-- <p class="pSex">남자</p>
                                <p class="pAge">1990.01.01</p> -->
                                <p class="pTel"><span>tel</span>{{ patient.user_phone_number && patient.user_phone_number.length > 0 ? patient.user_phone_number : '-' }}</p>
                                <!-- <p class="pCode"><span>code</span>029428291029</p> -->
                            </a>
                        </li>
                        
                        <!-- <li>
                            <a>
                                <p class="pName">김구라</p> -->
                                <!-- <p class="pSex">남자</p>
                                <p class="pAge">1990.01.01</p> -->
                                <!-- <p class="pTel"><span>tel</span>010-0202-2020</p>
                                <p class="pCode"><span>code</span>029428291029</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="pName">김노아</p> -->
                                <!-- <p class="pSex">남자</p>
                                <p class="pAge">1994.03.03</p> -->
                                <!-- <p class="pTel"><span>tel</span>010-0202-2020</p>
                                <p class="pCode"><span>code</span>029428291029</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="pName">김다연</p> -->
                                <!-- <p class="pSex">여자</p>
                                <p class="pAge">1981.11.05</p> -->
                                <!-- <p class="pTel"><span>tel</span>010-0202-2020</p>
                                <p class="pCode"><span>code</span>029428291029</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="pName">김라연</p> -->
                                <!-- <p class="pSex">여자</p>
                                <p class="pAge">1982.08.12</p> -->
                                <!-- <p class="pTel"><span>tel</span>010-0202-2020</p>
                                <p class="pCode"><span>code</span>029428291029</p>
                            </a>
                        </li>
                        <li>
                            <a>
                                <p class="pName">김마은</p> -->
                                <!-- <p class="pSex">여자</p>
                                <p class="pAge">1965.02.10</p> -->
                                <!-- <p class="pTel"><span>tel</span>010-0202-2020</p>
                                <p class="pCode"><span>code</span>029428291029</p>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="btns b2">
            <input type="button" class="red" value="취소" @click="closeModal"/>
            <input type="button" class="blue" value="예약" @click="saveAppointment" :disabled="!isSaveable()"/>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, EVENT_SUB_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import moment from 'moment';

import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';
import { t_event_json } from '@/model/event';
import { hodu_color } from '@/common/color';

@Component({
    components: {
        
    },
}) export default class AppointmentCreateModal extends Mixins(VueHoduCommon) {

    /**
     * 선택되서 들어온 진료과의 의사 리스트 반환
     */
    get computedDepartmentDoctor() : hodu_doc_object.doctor_info[] {

        let department_doctor : hodu_doc_object.doctor_info[] = []; 
        
        if( this.doctor_info != null ) {

            // 코드가 없다면 전체리스트를 가져온다
            if( this.appointment_create_modal_info.department_code == null || this.appointment_create_modal_info.department_code.length < 1 ) {
                return this.doctor_info;
            }

            department_doctor = department_doctor.concat(this.doctor_info.filter(item => item.department_code == this.appointment_create_modal_info.department_code));
        }

        return department_doctor;
    }

    /**
     * 선택된 날의 첫번째 날 부터 이번달 말까지 리스트 반환
     */
    get computedDayList() : Date[] {

        let current_month_day_list : Date[] = [];

        if( this.appointment_create_modal_info != null && this.appointment_create_modal_info.original_timetable != null && 
            this.hospital_setting_info         != null && this.selected_doctor != null ) {

            const target_date : Date = moment(this.appointment_create_modal_info.original_timetable).set('hour', 0)
                                                                                                    .set('minute', 0)
                                                                                                    .set('second', 0)
                                                                                                    .set('millisecond', 0).toDate();
            const first_day : Date = new Date(target_date);
            first_day.setDate(1);

            const today : Date = moment().set('hour', 0)
                                        .set('minute', 0)
                                        .set('second', 0)
                                        .set('millisecond', 0).toDate();

            // 해당 월의 첫날이 오늘보다 옛날이라면 오늘을 기준으로 만든다
            if( first_day.getTime() < today.getTime() ) {
                target_date.setDate(today.getDate());
            }

            // 오늘 이후의 날짜였다면 1일부터 만든다
            else {
                target_date.setDate(1);
            }

            const final : Date = new Date();
            final.setMonth(final.getMonth() + 1);
            final.setDate(0);

            for( let i = target_date.getDate(); i <= final.getDate(); i++) {
                const target : Date = new Date(target_date);
                target.setDate(target.getDate() + i - target.getDate()); 

                // 해당 날짜에 병원이 OFF인 경우, 의사가 OFF인 경우는 제외시켜야함
                // 병원 정보
                const settings = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= target.getTime() &&
                                                                           new Date(item.end_date).getTime()   >= target.getTime() &&
                                                                           Number(item.day_of_week)            == target.getDay() );
                                                                           
                if( settings.length < 1 ) { continue; }

                // 오전, 오후 둘 다 휴무인 날은 리스트에 추가시키지 않고 넘어간다
                if( settings[0].start_am_time == null && settings[0].start_pm_time == null ) { continue; }

                // 의사 정보
                let work_code : hodu_doc_enum.doctor_work_code = hodu_doc_enum.doctor_work_code.OFF;
                switch(target.getDay()) {
                    case 0:
                        work_code = this.selected_doctor.sunday;
                        break;

                    case 1:
                        work_code = this.selected_doctor.monday;
                        break;

                    case 2:
                        work_code = this.selected_doctor.tuesday;
                        break;

                    case 3:
                        work_code = this.selected_doctor.wednesday;
                        break;

                    case 4:
                        work_code = this.selected_doctor.thursday;
                        break;

                    case 5:
                        work_code = this.selected_doctor.friday;
                        break;

                    case 6:
                        work_code = this.selected_doctor.saturday;
                        break;

                }

                // 의사가 해당 요일에 OFF 인 경우 날짜 리스트에 추가시키지 않는다
                if( work_code == hodu_doc_enum.doctor_work_code.OFF ) { continue; }

                // 병원, 의사 정보를 조합해서 병원은 AM인데 의사는 PM인 경우와 같이 서로 반대라면 제외시킨다
                if( work_code == hodu_doc_enum.doctor_work_code.AM && settings[0].start_am_time == null || 
                    work_code == hodu_doc_enum.doctor_work_code.PM && settings[0].start_pm_time == null ) { continue; } 
                
                current_month_day_list.push(target);
            }
        }
        
        return current_month_day_list; 
    }

    /**
     * 검색 된 환자 리스트 반환
     */
    get computedPatientList() : hodu_doc_object.patient_info[] {

        let patient_list : hodu_doc_object.patient_info[] = [];

        if( this.patient_info != null ) {

            patient_list = this.patient_info.filter(item => this.hodu_string_includes(item.user_name, this.patient_query) == true);

        }

        return patient_list;
    }

    /**
     * 불가능한 시간(사람이 전부 예약한 경우는 제외)은 제외한 available_list를 보여준다
     */
    get computedAvailableList() : hodu_doc_object.appointment_available_time_info[] {
        return this.available_list.filter(item => item.available == true || ( item.available == false && item.coalesce == item.reservation_term_by_people ) );
    }

    /**
     * 선택된 시간의 예약 정보를 반환
     */
    get computedSelectedTimeAppointment() : hodu_doc_object.appointment_by_time[] { 
        
        const key = this.hodu_date_to_format_string(this.selected_date, 'HH:mm');

        const time_list : hodu_doc_object.appointment_by_time_list[] = this.appointment_by_time_list.filter(item => item.date_key == key);
        
        if( time_list.length < 1 ) { return []; }

        return time_list[0].appointments;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State appointment_create_modal_info !: hodu_doc_modal_info.AppointmentCreateModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentCreateModalInfo ?: (params : hodu_doc_modal_info.AppointmentCreateModalInfo) => void;
    
    available_list : hodu_doc_object.appointment_available_time_info[] = [];
    appointment_by_time_list : hodu_doc_object.appointment_by_time_list[] = [];
    selected_doctor : hodu_doc_object.doctor_info | null = null;
    selected_date : Date = new Date();
    selecetd_date_temp : Date | null = null;
    selected_patient : hodu_doc_object.patient_info = {
        user_id : 0,
        user_name : "",
        user_phone_number : "",
        user_email : "",
        nok_count : 0,
        file_count : 0,
        checked : false,
    }
    memo : string = "";
    is_search_patient : boolean = true;
    patient_query : string = "";

    async mounted() : Promise<void> {
        // $(".patient_schBtn").click(function(){
        //     $(".findResult").toggleClass("on");
        // });

        this.is_search_patient = this.appointment_create_modal_info.is_create_one_patient == false;
    
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        // 의사 정보 및 선택한 시간 정보가 없을때 오류 처리
        if( this.doctor_info == null || this.doctor_info.length < 1 || this.appointment_create_modal_info.original_timetable == null ) {
            this.closeModal();
            this.hodu_show_dialog("cancel", "오류 발생\n잠시 후 다시 시도해주세요", ["확인"], [() => {}]);
            return;
        }

        // 선택해서 들어온 의사 및 시간으로 세팅
        this.selected_doctor = this.appointment_create_modal_info.is_create_one_patient == false 
            ? this.computedDepartmentDoctor.filter(item => item.doctor_code == this.appointment_create_modal_info.doctor_code)[0]
            : this.computedDepartmentDoctor[0];

        this.selected_date   = new Date(this.appointment_create_modal_info.original_timetable);

        if( this.appointment_create_modal_info.patient_info != null ) {
            this.selected_patient = JSON.parse(JSON.stringify(this.appointment_create_modal_info.patient_info));
        }

        // 예약 시간 정보 조회
        Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()])
            .then(() => { this.setScroll(); })
    }

    setScroll() : void {
        
        const modal_content_height : number | undefined = $('#appointment_create_modal .modal_content').outerHeight();
        
        const left_select_docs_height : number | undefined = $('#appointment_create_modal .modal_content .left_content .selectDocs').outerHeight();
        const left_select_div_height : number | undefined = $('#appointment_create_modal .modal_content .left_content .selectDiv').outerHeight();
        
        const main_content_find_div_hegiht : number | undefined = $('#appointment_create_modal .modal_content .main_content .findDiv').outerHeight();

        // @ts-ignore
        $('#modal_appointment_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : (modal_content_height ? modal_content_height : 0) - (left_select_docs_height ? left_select_docs_height : 0)
                                                                          - (left_select_div_height  ? left_select_div_height  : 0),
        });

        // @ts-ignore
        $('#patient_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : (modal_content_height ? modal_content_height : 0),
        });
    }

    /**
     * 해당 날짜의 예약 가능, 불가능 여부 정보 조회
     */
    async getAvailableAppointmentTime() : Promise<void> {

        if( this.selected_doctor == null ) { return; }
        
        const start = moment(this.selected_date).format('YYYY-MM-DD'); // 조회일
        const department_code = this.selected_doctor.department_code;  // 진료과 코드
        const doctor_code = this.selected_doctor.doctor_code;          // 의사 코드

        if( department_code == null || department_code.length < 1 || doctor_code == null || doctor_code.length < 1 ) { return; }

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/availability?start=${start}&department_code=${department_code}&doctor_code=${doctor_code}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                
                this.available_list.splice(0, this.available_list.length);
                this.available_list = this.available_list.concat(response.data.data);

                // 조회 결과가 없는 경우 throw?
                if( this.available_list.length < 1 ) {
                    return;
                }
                
                // 오늘 날짜인 경우 이미 시간이 지난 예약에 대해서 삭제한다
                const current_day : Date = new Date();
                if( this.selected_date.getFullYear() == current_day.getFullYear() &&
                    this.selected_date.getMonth()    == current_day.getMonth()    && 
                    this.selected_date.getDate()     == current_day.getDate() ) {

                    this.available_list = this.available_list.filter((item) => moment(item.original_timetable).toDate().getTime() >= new Date().getTime());
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true); 
            });
    }

    /**
     * 시간별 예약 리스트 조회
     */
    async getAppointmentByTime() : Promise<void> {
        
        if( this.selected_doctor == null ) { return; }

        const start = moment(this.selected_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format();      // 조회일
        const end   = moment(this.selected_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).utc().format(); // 조회일
        const department_code = this.selected_doctor.department_code; // 진료과 코드
        const doctor_code = this.selected_doctor.doctor_code;         // 의사 코드

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/appointmentByTime?start=${start}&end=${end}&department_code=${department_code}&doctor_code=${doctor_code}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                const appointment_by_time : hodu_doc_object.appointment_by_time[] = response.data.data;

                // 비우기
                this.appointment_by_time_list.splice(0, this.appointment_by_time_list.length);

                // 데이터 가공
                for( const appointment of appointment_by_time ) {
                    const key : string = this.hodu_date_to_format_string(appointment.start, 'HH:mm');

                    if( this.appointment_by_time_list.filter(item => item.date_key == key).length > 0 ) {
                        this.appointment_by_time_list.filter(item => item.date_key == key)[0].appointments.push(appointment);
                        continue;
                    }

                    this.appointment_by_time_list.push({
                        date_key : key,
                        appointments : [appointment]
                    })
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            });

    }

    /**
     * 예약 삭제
     */
    deleteEvent(event_id : string) : void {

        const calendar_id : string = this.calendar_id;
        const scope       : string = OWNER_TYPE.GROUP;
        const scope_id    : number = this.scope_group_id;
        
        const url : string = `api/v1/calendars/${ calendar_id }/events/${ scope }/${ scope_id }/${ event_id }?push_yn=false`;

        this.hodu_api_call(url, API_METHOD.DELETE)
            .then((response) => { 
                Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()])
                
                // 재조회 시키기
                if( this.appointment_create_modal_info.is_create_one_patient == false ) {
                    window['leftSelectAvailableList']();
                    window['rightSelectAvailableList']();
                }
            })
            .catch((e) => { 
                this.hodu_error_process(e, false, false, true); 
            });
    }

    /**
     * 의사 선택
     */
    selectDoctor(value : string) : void {

        const doc_list : hodu_doc_object.doctor_info[] = this.computedDepartmentDoctor.filter(item => item.doctor_code == value);

        if( doc_list.length < 1 ) { return; }

        this.selected_doctor = JSON.parse(JSON.stringify(doc_list[0]));
        Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()])
    }

    /**
     * 일자 선택
     */
    selectDate(date : number) : void {
        this.selecetd_date_temp = new Date(this.selected_date);
        this.selected_date = moment(this.selected_date).set('date', date).toDate();
        Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()])
            .then(() => {
                
                if( this.computedAvailableList.length < 1 && this.selecetd_date_temp != null ) {
                    alert("예약 할 수 있는 시간이 없습니다");
                    this.selected_date = new Date(this.selecetd_date_temp);
                    this.selecetd_date_temp = null;
                    Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()]);
                }

            })
    }

    /**
     * 시간 선택
     */
    selectTime(hour : number, min : number) : void {
        this.selected_date = moment(this.selected_date).set('hour', hour).set('minute', min).toDate();
    }

    /**
     * 예약(일정) 삭제 권한 반환
     */
    getAppointmentDeletePermission() : boolean {
        return this.is_group_permmision(this.scope_group_id, "event", "delete");
    }

    /**
     * 검색 ON / OFF
     */
    searchOnOff() : void {
        this.is_search_patient = !this.is_search_patient;
    }

    /**
     * 환자 선택
     */
    setPatientInfo(patient : hodu_doc_object.patient_info) : void {
        this.is_search_patient = false;
        this.patient_query = "";

        this.selected_patient = JSON.parse(JSON.stringify(patient));
        this.$nextTick(() => { $('#patientMemo').focus(); });
    }
    
    /**
     * 저장 가능 여부 반환
     */
    isSaveable() : boolean {
        const list : hodu_doc_object.appointment_available_time_info[] = this.computedAvailableList.filter(item => new Date(item.original_timetable).getTime() == new Date(this.selected_date).getTime());

        if( list.length < 1 ) { return false; }

        return list[0].available;
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {

        if( this.doSetAppointmentCreateModalInfo == null ) { return; }
        
        this.doSetAppointmentCreateModalInfo({
            "show_modal" : false,
            "is_create_one_patient" : false,
        })
    }

    /**
     * 예약 등록
     */
    saveAppointment() : void {
        
        // 이름은 필수 조건이다
        if( this.selected_patient.user_name.length < 1 || this.selected_doctor == null || this.hospital_info == null ) { return; }

        const available_list = this.computedAvailableList.filter(item => new Date(item.original_timetable).getTime() == this.selected_date.getTime());

        if( available_list.length < 1 ) { return; }

        const calendar_id : string = this.calendar_id;
        const scope       : string = OWNER_TYPE.GROUP;
        const scope_id    : number = this.scope_group_id;

        const event_data : t_event_json = {
            version : "1",
            status : "",
            priority : 0,
            event_sub_type : EVENT_SUB_TYPE.APPOINTMENT,
            title : `${this.hospital_info.company_info.name} 예약 ${this.hodu_date_to_format_string(this.selected_date, 'HH:mm')}`,
            color : hodu_color.hodu_dc_7,
            schedule_date: {
                start : this.selected_date,
                end : moment(this.selected_date).add(available_list[0].reservation_term, 'minute').toDate(),
                isAllDay : false,
                lunar_yn : false,
                recurrence_end : moment(this.selected_date).add(available_list[0].reservation_term, 'minute').toDate(),
                isContinuos : false,
                isIgnore : false,
            },
            contacts : [],
            location : [],
            attachment : {
                imgs : [],
                files : [],
            },
            vote: [],
            attend : false,
            appointment : {
                biz_id : this.scope_group_team_option.biz_id,
                department_code : this.selected_doctor.department_code,
                doctor_code : this.selected_doctor.doctor_code,
                doctor_name : this.selected_doctor.doctor_name,
                patient_name : this.selected_patient.user_name,
                nok_name : this.user_name,
                nok_phone_number : this.selected_patient.user_phone_number,
                symptoms : this.memo,
                prescription : null,
                appointment_status : hodu_doc_enum.appointment_status_code.CONFIRM,
                appointment_decline_reason : null,
            },
            event_owner_name : this.selected_patient.user_name,
            event_owner_id : this.selected_patient.user_id,
            event_owner_group_id : this.scope_group_id,
            event_owner_group_name : this.scope_group_team_option.group_team_name,
            is_private : false,
        }

        // 예약 등록 API
        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}?event_type=${scope}`, API_METHOD.POST, event_data)
            .then((response) => {
                // 재조회 시키기
                if( this.appointment_create_modal_info.is_create_one_patient == false ) {
                    window['leftSelectAvailableList']();
                    window['rightSelectAvailableList']();
                }

                this.closeModal();
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            })
    }

}
</script>

<style scoped>
    #appointment_create_modal.one { height: 472px; margin-top:-236px; }
    #appointment_create_modal.one .findDiv { display:none;  }
    /* #appointment_create_modal.one .main_content .blocking { position: absolute; left:0; top:0; height:270px; width:100%; z-index: 1000; }
    #appointment_create_modal.one .main_content .blocking1 { position: absolute; left:0; top:0; height:270px; width:100%; z-index: 1000; }
    #appointment_create_modal.one .main_content .blocking2 { position: absolute; left:0; top:0; height:270px; width:100%; z-index: 1000; }
    #appointment_create_modal.one .main_content .blocking3 { position: absolute; left:0; top:0; height:270px; width:100%; z-index: 1000; }
    #appointment_create_modal.one .main_content .blocking4 { position: absolute; left:0; top:0; height:270px; width:100%; z-index: 1000; }
    #appointment_create_modal.one .main_content .blocking5 { position: absolute; left:0; top:0; height:270px; width:100%; z-index: 1000; } */

    .modal { position:absolute; width: 800px; height: 600px; background:#fff; z-index: 10000; left: 50%; margin-left:-400px; top:50%; margin-top:-300px; box-shadow: 0 10px 10px rgba(0,0,0,0.1); border-radius: 5px;  }
    .modal.v1 { height : 527px; }
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}

    .modal_content { background:#f1f3f5; overflow:hidden; }
    .selectDiv { overflow:hidden; }
    .left_content .docSelected.selectDocs { overflow: hidden; }
    .left_content .docSelected.selectDocs select { width:100% !important; background-position: 96% center; }
    /* .left_content .docSelected p { padding: 0 20px; font-size: 14px; font-weight:bold; background:#fff; border-bottom:1px solid #e7e9ea; line-height:55px; height:54px; }
    .left_content .docSelected .docDet1 {display: inline-block;text-overflow: ellipsis;max-width: 200px;overflow: hidden;float: left;margin-right: 15px;white-space: nowrap;}
    .left_content .docSelected .docDet2 { font-size:12px; display:inline-block; padding: 0 10px; border-radius: 20px;border:1px solid #656c82; line-height: 20px; } */
    .left_content { width: 44%; float:left; }
    .left_content select:first-child { border-right:1px solid #f1f3f5; }
    .left_content select { width: 50%; float:left; transition:0.2s;height: 55px; line-height: 55px; appearance: none; border-bottom: 1px solid #eaeaea; padding: 0 20px; box-sizing: border-box; font-weight: bold; font-size: 13px; cursor:pointer;background-image: url(../../../assets/images/side_area/bt_open.png); background-position:  90% center; background-repeat:no-repeat}
    .left_content select:hover {background-color: #f1f3f5; }
    .left_content select:focus { border-bottom: 1px solid #477fff; background-color:#fff; background-image: url(../../../assets/images/side_area/bt_close.png); }
    .left_content select option { font-size: 16px; }
    .otherReser { width: 100%; }
    .otherReser li {background: #fff; position: relative; border-bottom:1px solid #f1f3f5; padding: 10px 20px; }
    .otherReser li p { display: inline-block; }
    .otherReser li .pName { display:inline-block; font-size: 14px; line-height: 35px; height:35px; font-weight:bold; width: 100%;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-right: 50px; box-sizing:border-box;  }
    .otherReser li .pTel { font-weight: bold;display:inline-block;  }
    .otherReser li .pAge {font-weight: bold; width: 30%;}
    .otherReser li .pSex {font-weight: bold; border:1px solid; font-size:11px; padding: 2px 4px; border-radius: 3px; margin-right: 15px;}
    .otherReser li .pSex.f { color:#ff6060 }
    .otherReser li .pSex.m {color:#477fff }
    .otherReser li .pMemo { display:block; border-top:1px solid #f1f3f5;padding-top:10px; margin-top:10px; padding-bottom:10px; }
    .otherReser li input { position:absolute; right: 20px; top:18px; background: #ff6060; font-weight: bold; height:23px;line-height:23px; font-size: 11px; color:#fff; padding: 0 7px;border-radius: 3px; }
    .otherReser li input.cancel:hover {background:#fb2424}

    .findDiv { position: relative;z-index:1000; }
    .findDiv input.findPInput { height: 55px; line-height: 55px; border:none; border-bottom: 1px solid #e7e9ea; padding-left: 20px;font-size: 14px; margin-bottom:0; }
    .main_content input.patient_schBtn { position:absolute; right: 0; bottom:0; margin-bottom:0; width: 55px; height:55px; font-size:0; background-color: transparent;border:none; background: url(../../../assets/images/contents/ic_search_on.png) no-repeat center center;}
    .main_content input.patient_schBtn:hover {background: #477fff url(../../../assets/images/contents/ic_search_fff.png) no-repeat center center;}
    .findResult { display:none; background:#fff; position:absolute;top:0;left:0; padding-top:55px; box-sizing: border-box; height:100%; width:100%;}
    .findResult.on { display:block; }
    .findResult .noResult { display:none; position: static; position: static; width: 100%;padding: 20px 20px !important; box-sizing: border-box; height: auto;font-weight:bold; color:#a6adbb; }
    .findResult .noResult.on { display:block; }
    .findResult .noResult.on > p { background : #f1f3f5; line-height: 60px;height: 60px; color:#9fa4b3}
    .findResult ul {width:100%;background:#fff; height:100%;padding:0 !important; }
    .findResult ul li a { transition:0.2s; overflow:hidden; box-sizing: border-box; display:block;width:100%; line-height: 45px; height:45px; border-bottom:1px solid #f1f3f5; padding:0 0 0 20px; }
    .findResult ul li a:hover { background:#f1f3f5; }
    .findResult ul li a:hover span { background:#fff; }
    .findResult ul li a:hover p { font-weight:bold; }
    .findResult ul li p { float:left; display: inline-block;padding-right: 10px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    .findResult ul li p.pName { width: 25%;font-weight:bold;  }
    .findResult ul li p.pSex { width: 12%; }
    .findResult ul li p.pAge { width: 20%; }
    .findResult ul li p.pTel { width: 32%; }
    .findResult ul li p.pCode {  width: 30% }
    .findResult ul li p span { display:inline-block; background: #f1f3f5; line-height: 20px; height:20px; padding: 0 3px;border-radius: 3px; margin-right:5px;  }

    .main_content ul {  padding: 25px 30px; }
    .main_content  { background:#fff;position:relative; width: 56%;float: left; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .main_content .pSexDiv { padding-left:20px; box-sizing: border-box; }
    .main_content .pSexDiv select { appearance: none;  width: 100%; border-radius: 5px;font-weight:bold; border: 1px solid #e7e9ea; padding: 0 15px; height: 40px; line-height: 40px; box-sizing:border-box;background-image: url(../../../assets/images/side_area/bt_open.png); background-position:  97% center; background-repeat:no-repeat  }
    .main_content .pSexDiv select:hover { border-color:#477fff; cursor:pointer;  }
    .main_content .pSexDiv select:focus {  background-image: url(../../../assets/images/side_area/bt_close.png); border-color:#477fff; }
    
    .main_content .patientTel1 { width: 75px; float:left; font-weight:bold; padding-left: 15px; height: 40px; line-height: 40px; box-sizing:border-box;appearance: none; border:1px solid #e7e9ea; border-radius: 5px; cursor:pointer;background-image: url(../../../assets/images/side_area/bt_open.png); background-position:  92% center; background-repeat:no-repeat   }
    .main_content .patientTel1:hover { border-color:#477fff; cursor:pointer }
    .main_content .patientTel1:focus {  background-image: url(../../../assets/images/side_area/bt_close.png); border-color:#477fff; }
    .main_content .patientTel2 { width: 269px; float:right; }

    .main_content .hy { text-align: center; display:inline-block; width: 40px; line-height: 40px;font-size: 27px; }
    .main_content li { position:relative; overflow:hidden; }
    .main_content .fl { overflow:hidden; width:50%; }
    .main_content label { display:block; margin-bottom: 8px; }
    .main_content input { transition:0.2s; width: 100%; border: 1px solid #e7e9ea; font-weight:bold; padding: 0 13px; height: 40px; line-height: 40px; box-sizing:border-box; margin-bottom: 20px; }
    .main_content input:disabled { background: #f1f3f5; color: #4e5667; }
    .main_content input:hover { background:#f1f3f5; }
    
    /* 검색 버튼이 해당 css 영향 받음  */
    /* .main_content input:focus { background:#fff; border-color:#477fff; } */

    .main_content textarea { transition:0.2s; resize:none; border-color:#f1f3f5; background:#f1f3f5;padding: 13px; box-sizing: border-box; width:100%; height: 80px; }
    .main_content textarea:hover { border-color: #477fff; }
    .main_content textarea:focus { outline:none; border-color: #477fff; background:#fff !important; }

    .btns { width:100%; border-top:1px solid #e7e9ea; border-radius: 0 0 5px 5px; overflow: hidden;}
    .btns input { transition:0.2s; width:50%; background:#fff; font-size: 14px;font-weight:bold; height:70px; line-height:70px; }
    .btns input.blue:hover { color:#477fff; }
    .btns input.red:hover { color:#ff6060; }
    .btns input:hover { background:#f1f3f5; }
    
    #modal_appointment_list_scroll .noResult { display: none; position: static; width: 100%;padding: 20px 20px; box-sizing: border-box; height: auto; border-radius: 5px; font-weight:bold; color:#a6adbb; }
    #modal_appointment_list_scroll .noResult.on { display: block; }
    #modal_appointment_list_scroll .noResult.on > p { background : #fff; line-height: 50px; height:50px; color:#9fa4b3}
    
    .noResult { padding:20px 20px; height: auto; box-sizing:border-box}   
    .noResult p { width: 100%;  }

    /* 임시 */
    .patientTel3 { width : 100%; }

</style>