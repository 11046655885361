<template>
     <div class="section_ce_fix">
        
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">일정 검색</h3>
        </div>

        <div class="scheduleScrollDiv">
            <!-- 검색 부분, 일정검색 들어올시 input text에 자동 포커스 -->
            <div class="searchDiv">
                <div class="schGrp">
                    <a href="#" class="bt_option" @click.prevent="searchOptionOnOff" :class="{ on : searchOptionVisible == true }"><span class="txt">검색옵션</span></a>
                    <input type="text" id="event_search" placeholder="일정을 검색하세요" :value="event_search_query" @input="event_search_query_temp = $event.target.value" @keydown.stop="eventKeyDown">
                    <div class="btns">
                        <a href="#" class="bt_search" @click.prevent="newSearchEvent(false)"><span class="blind">찾기버튼</span></a>
                    </div>

                    <div class="schOption" :class="{ on : searchOptionVisible == true, noWork : event_search_option.search_event_type != 'WORK' }">
                        <div class="sch_options">
                            <ul>
                                <li>
                                    <dl>
                                        <dt><label for="sch_grp">일정 종류</label></dt>
                                        <dd>
                                            <select name="모든 일정" id="sch_grp" :value="event_search_option.search_event_type" @change="searchTypeChange($event)">
                                                <option value="ALL">모든 일정</option>
                                                <option value="PERSONAL" v-if="isPersonalScope(scope)">개인 일정</option>
                                                <option value="GROUP" v-if="isPersonalScope(scope)">그룹 일정</option>
                                                <option value="TEAM" v-if="isPersonalScope(scope)">팀 일정</option>
                                                <option value="BASIC">일정</option>
                                                <option value="CARD">카드</option>
                                                <option value="WORK">업무</option>

                                                <!-- 아래부터는 그룹, 팀 달력에선 보이지 않음 -->
                                                <!-- 구분선 -->
                                                <option value="LINE" disabled v-if="isPersonalScope(scope)"></option>
                                                <!-- 가입된 그룹 팀, 그룹 > 소속팀 순서 -->
                                                <option :value="`${groupTeam.group_id}-${groupTeam.team_id}`" :key="`${groupTeam.group_id}-${groupTeam.team_id}`" v-for="groupTeam in computedGroupAndTeam" v-show="isPersonalScope(scope)">
                                                    {{ groupTeam.team_id > 0 ? `└ ${groupTeam.team_name}` : groupTeam.group_name }}
                                                </option>
                                            </select>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="schDateList">
                                    <dl>
                                        <dt><label for="sch_from">날짜</label></dt>
                                        <dd>
                                            <!-- datePicker -->
                                            <input type="button" id="search_sch_from" class="input_txt wd45" 
                                                :class="{ on : start_date_text != null }" :value="start_date_text != null ? start_date_text : `검색 시작일`" />
                                            <span class="ware_seper">~</span>
                                            <!-- datePicker -->
                                            <input type="button" id="search_sch_to" class="input_txt wd45 filterEndDate" 
                                                :class="{ on : end_date_text != null }" :value="end_date_text != null ? end_date_text : `검색 종료일`" />
                                        </dd>
                                    </dl>
                                </li>
                                <li>
                                    <dl>
                                        <dt><label for="sch_title">제목</label></dt>
                                        <dd>
                                            <input id="sch_title" type="text" class="input_txt" placeholder="제목을 입력하세요" @input="event_search_option.event_title = $event.target.value"
                                                :value="event_search_option.event_title ? event_search_option.event_title : ''"/>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="sch_clr_li">
                                    <dl>
                                        <dt><label for="sch_place">일정 색상</label></dt>
                                        <dd>
                                            <div class="sch_clr_div">
                                                <ul>

                                                    <li :key="color" v-for="(color, index) in dc_color">
                                                        <input type="checkbox" :id="`sch_clr_dc0${index}`" class="sch_clr_dc" :checked="isContainColor(color)" @change="changeSearchColor($event, color)"/>
                                                        <label :for="`sch_clr_dc0${index}`" :class="hodu_color_dc_lc(color)" class="sch_clr_dc">{{ hodu_color_dc_lc_label_text(color) }}</label>
                                                    </li>

                                                    <li :key="color" v-for="(color, index) in lc_color">
                                                        <input type="checkbox" :id="`sch_clr_lc0${index}`" class="sch_clr_lc" :checked="isContainColor(color)" @change="changeSearchColor($event, color)"/>
                                                        <label :for="`sch_clr_lc0${index}`" :class="hodu_color_dc_lc(color)" class="sch_clr_lc">{{ hodu_color_dc_lc_label_text(color) }}</label>
                                                    </li>

                                                </ul>
                                                <div class="selectAllDiv">
                                                    <p>
                                                        <input type="checkbox" id="selectAllClrs" :checked="isCheckedAllColor()" @change="changeCheckAllColor($event)"/>
                                                        <label for="selectAllClrs" class="dc7"><span class="">전체 선택</span></label>
                                                    </p>
                                                </div>
                                            </div>
                                        </dd>	
                                    </dl>
                                </li>
                                <li class="sch_wrt_li">
                                    <dl>
                                        <dt><label for="sch_writer">작성자</label></dt>
                                        <dd>
                                            <input id="sch_writer" type="text" class="input_txt" placeholder="작성자 이름을 입력하세요" @input="event_search_option.event_owner_name = $event.target.value"
                                                :value="event_search_option.event_owner_name ? event_search_option.event_owner_name : ''"/>
                                        </dd>
                                    </dl>
                                </li>
                                <li class="sch_plc_li">
                                    <dl>
                                        <dt><label for="sch_place">장소</label></dt>
                                        <dd>
                                            <input type="text" id="sch_place" placeholder="장소를 입력하세요" @input="event_search_option.location_name = $event.target.value"
                                                :value="event_search_option.location_name ? event_search_option.location_name : ''"/>
                                        </dd>	
                                    </dl>
                                </li>
                                <li class="ware_etc_list">
                                    <dl>
                                        <dt>그 외</dt>
                                        <dd>
                                            <div class="checkOptions" :class="{ last : event_search_option.search_event_type != 'WORK' }">
                                                <p>
                                                    <input type="checkbox" id="sch_pic" v-model="event_search_option.has_photo"/>
                                                    <label for="sch_pic"><span class="option_txt">사진 있음</span></label>
                                                </p>
                                                <!-- 그룹, 팀이 선택된 경우는 일정에 파일 업로드가 가능한 경우에만 나타난다 -->
                                                <p v-if="checkFileEnabled(event_search_option.search_event_type)">
                                                    <input type="checkbox" id="sch_file" v-model="event_search_option.has_file"/>
                                                    <label for="sch_file"><span class="option_txt">파일 있음</span></label>
                                                </p>
                                                <p>
                                                    <input type="checkbox" id="sch_cmmnt" v-model="event_search_option.has_chat"/>
                                                    <label for="sch_cmmnt"><span class="option_txt">채팅 있음</span></label>
                                                </p>
                                                <p v-if="event_search_option.search_event_type != 'WORK'">
                                                    <input type="checkbox" id="sch_attend" v-model="event_search_option.has_attend"/>
                                                    <label for="sch_attend"><span class="option_txt">참석 있음</span></label>
                                                </p>
                                                <p v-if="event_search_option.search_event_type != 'WORK'">
                                                    <input type="checkbox" id="sch_vote" v-model="event_search_option.has_vote"/>
                                                    <label for="sch_vote"><span class="option_txt">투표/설문 있음</span></label>
                                                </p>
                                            </div>
                                        </dd>
                                    </dl>
                                </li>
                                <!-- 일정 종류에서 hoduC 리스트 선택 시 보이기 -->
                                <li class="ware_sch_work" v-if="event_search_option.search_event_type == 'WORK'">
                                    <dl>
                                        <dt>업무</dt>
                                        <dd>
                                            <div class="checkOptions">
                                                <!-- <p>
                                                    <input type="checkbox" id="sch_work_sch" />
                                                    <label for="sch_work_sch"><span class="option_txt">일정 제외</span></label>
                                                </p> -->
                                                <p>
                                                    <input type="checkbox" id="sch_work_step1" v-model="event_search_option.is_work_wait"/>
                                                    <label for="sch_work_step1"><span class="option_txt">대기</span></label>
                                                </p>
                                                <p>
                                                    <input type="checkbox" id="sch_work_step2" v-model="event_search_option.is_work_start"/>
                                                    <label for="sch_work_step2"><span class="option_txt">진행 중</span></label>
                                                </p>
                                                <p>
                                                    <input type="checkbox" id="sch_work_step3" v-model="event_search_option.is_work_end"/>
                                                    <label for="sch_work_step3"><span class="option_txt">완료</span></label>
                                                </p>
                                            </div>
                                        </dd>
                                    </dl>
                                </li>
                            </ul>

                            <div class="loadingBg">
                                <div class="loadingRel">
                                    <span class="loadingBar"></span>
                                </div>
                            </div>
                            <div class="save_last_section">
                                <input type="button" value="취소" @click="searchOptionOnOff"/>
                                <input type="button" value="검색 초기화" @click="searchOptionReset"/>
                                <input type="button" value="검색" @click="newSearchEventByFilter"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- 검색 부분 ends -->
            <!-- 일정 contents -->
            <div id="eventSearchDiv" class="contents">
                <div class="bg4sch" :class="{ on : searchOptionVisible == true }" @click="searchOptionOnOff"></div>
                <div class="schListDiv">
                    <!-- 월별 스크롤 넘어갈때마다 밸류 변경  -->
                    <!-- <div class="schListHeader">
                        <h3>2019.05</h3>
                        <p class="total">54</p>
                    </div> -->
                    <!-- .mainSchListDiv 스크롤 -->
                    <div class="mainSchListDiv">
                        <!-- 검색 결과가 없을시 -->
                        <p class="noResult" v-if="events == null || events.length < 1">검색결과가 없습니다 :(</p>
                        <ul class="mainSchList" v-else>
                            
                            <!-- 월별 리스트 시작 -->
                            <li class="yyyy" :key="render_event.year_and_month" v-for="render_event in render_events">
                                
                                <div class="yyyytitle">
                                    <p class="yyyyH4">{{ render_event.year_and_month }}</p>
                                    <p class="total">{{ render_event.events.length }}</p>
                                </div>

                                <!-- 일정 리스트 -->
                                <ul class="schListUl">
                                    
                                    <!-- 일정 리스트 시작 -->
                                    <li :id="`${event.event_id}-${new Date(event.event_data.schedule_date.start).getTime()}`" 
                                        :class="{ 
                                            work : isWork(event.event_sub_type),
                                            sameDay : isSameDay(render_event.events, index) == false
                                        }"
                                        :key="`${event.event_id}-${new Date(event.event_data.schedule_date.start).getTime()}`" v-for="(event, index) in render_event.events">
                                        
                                        <!-- 최상위 일자 -->
                                        <div class="topContainer" v-if="isFirstDayEvent(render_event.events, index)">
                                            <p class="top_date">{{ render_event.year_and_month}}.{{ getDate(new Date(event.event_data.schedule_date.start)) }}</p>
                                            <p class="top_day">{{ getDayOfWeekByDate(new Date(event.event_data.schedule_date.start), "요일") }}</p>
                                            <p class="top_dDay" :class="{ today : isToday(new Date(event.event_data.schedule_date.start)) == true }">{{ isToday(new Date(event.event_data.schedule_date.start)) == true ? "오늘" : `D${ isPastDate(new Date(event.event_data.schedule_date.start)) ? "+" : "-" }${Math.abs(getDateDiff(new Date(event.event_data.schedule_date.end), new Date()))}` }}</p>
                                        </div>
                                        
                                        <div class="btmContainer">
                                            <!-- 시간 영역 -->
                                            <div class="colorDiv">
                                                <p class="cl" :style="{ backgroundColor : `${ event.event_data.color } !important` }" v-if="isSubTypeWork(event) == false">일정 색상</p>
                                                <p class="cl-work-top" :style="{ backgroundColor : `${ event.event_data.color } !important` }" v-if="isSubTypeWork(event) == true"></p>
                                                <p class="cl-work-bottom" :style="{ backgroundColor : `${ event.event_data.color } !important` }" v-if="isSubTypeWork(event) == true"></p>

                                                <!-- <p class="date">{{ getDate(new Date(event.event_data.schedule_date.start)) }}</p>
                                                <p class="day">{{ getDayOfWeek(new Date(event.event_data.schedule_date.start)) }}</p>
                                                <p class="counting" :class="{ today : isToday(new Date(event.event_data.schedule_date.start)) == true }">
                                                    {{ 
                                                        isToday(new Date(event.event_data.schedule_date.start)) == true 
                                                            ? "오늘" 
                                                            : `D ${ isPastDate(new Date(event.event_data.schedule_date.start)) ? "+" : "-" } ${Math.abs(getDateDiff(new Date(event.event_data.schedule_date.start), new Date()))}` 
                                                    }}
                                                </p> -->
                                            </div>
                                            <!-- 시간 영역 끝 -->

                                            <!-- 일정 내용 영역 -->
                                            <div class="contDiv">
                                                <!-- 업무 자체의 상태 -->
                                                <p class="work_status" v-if="isWork(event.event_sub_type)" :class="`work_${event.event_data.work.work_status_code.toLowerCase()}`">{{ getWorkStatusText(event) }}</p>
                                                
                                                <!-- 제목 -->
                                                <p class="title">{{ event.event_data.title }}</p>

                                                <!-- 업무 종료일 d-day -->
                                                <p class="work_d_day" v-if="isWork(event.event_sub_type)">
                                                    {{ 
                                                        isToday(new Date(event.event_data.schedule_date.end)) == true 
                                                            ? "오늘" 
                                                            : `D    ${ isPastDate(new Date(event.event_data.schedule_date.end)) ? "+" : "-" }${Math.abs(getDateDiff(new Date(event.event_data.schedule_date.end), new Date()))}` 
                                                    }}
                                                </p>
                                                
                                                <!-- 시간 -->
                                                <p class="fromTo" v-html="`${ event.event_data.schedule_date.lunar_yn == true ? '' : `${getDate(new Date(event.event_data.schedule_date.start))}일 ${getDayOfWeek(new Date(event.event_data.schedule_date.start))} ` }${event.lunar_date_text}`" />
                                                
                                                <!-- 작성자 -->
                                                <p class="postBy" v-if="isVisibleWriter(event)">
                                                    <span class="titleSpan">작성자</span>
                                                    <span class="cont">{{ getOwnerName(event.event_data.event_owner_id, event.event_data.event_owner_name) }}</span>
                                                </p>

                                                <!-- 그룹, 팀 명 -->
                                                <p class="grpTeam" v-if="event.group_id > 0 || event.team_id > 0">
                                                    <span class="titleSpan">{{ event.group_id > 0 ? "그룹" : "팀" }}</span>
                                                    <span class="cont">{{ event.group_id > 0 ? event.event_data.event_owner_group_name : event.event_data.event_owner_team_name }}</span>
                                                </p>
                                                
                                                <!-- 메모 -->
                                                <p class="memo" v-if="hasMemo(event.event_data.memo)">
                                                    <span class="titleSpan">메모</span>
                                                    <span class="cont">{{ event.event_data.memo }}</span>
                                                </p>
                                                
                                                <!-- 참석&투표 현황 -->
                                                <p class="attendAndVote" v-if="event.event_data.attend == true || hasVote(event.event_data.vote)">
                                                    <ul class="attendAndVoteUl">
                                                        <li class="attend_li" v-if="event.event_data.attend == true" :class="{ 
                                                            x_blue : event.user_attend == 'Y', 
                                                            x_red : event.user_attend == 'N', 
                                                            x_gray : !event.user_attend || event.user_attend == '-'
                                                        }">
                                                            {{ getAttendText(event) }}
                                                        </li>
                                                        <li class="vote_li" v-if="hasVote(event.event_data.vote)" :class="getVoteText(event, true)">
                                                            {{ getVoteText(event) }}
                                                        </li>
                                                    </ul>
                                                </p>

                                                <!-- 이미지 -->
                                                <p class="eventImage" v-if="event.event_data.attachment != null && event.event_data.attachment.imgs != null && event.event_data.attachment.imgs.length > 0">
                                                    <ul class="eventImageUl">
                                                        <li :key="index" v-for="(img, index) in event.event_data.attachment.imgs">
                                                            <img :src="`app_images/${img.url}`" />
                                                        </li>
                                                    </ul>
                                                </p>

                                                <!-- 사용자의 업무 현황 -->
                                                <p class="work_user_status" v-if="event.work_status_info != null && event.work_template_info != null">
                                                    내 현황
                                                    <ul class="work_user_status_check_ul">
                                                        <li class="cl" :class="{ is_wait : progress.status == 'WAIT' }" :style="{ backgroundColor : hodu_hex_color_process(event.work_template_info.content[index].color) }"
                                                            :key="index" v-for="(progress, index) in event.work_status_info.progress" />
                                                    </ul>
                                                </p>

                                            </div>
                                            <!-- 일정 내용 영역 끝 -->

                                            <!-- 아이콘 영역 -->
                                            <div class="icons">
                                                <ul class="iconList">
                                                    
                                                    <li class="repeat" v-if="isRepeat(event.event_data.schedule_date.rrule) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">반복</span>
                                                    </li>

                                                    <li class="alarm" v-if="hasAlarm(event.event_data.alarm) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">알림</span>
                                                    </li>

                                                    <li class="place" v-if="hasLocation(event.event_data.location) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">장소</span>
                                                    </li>

                                                    <li class="tel" v-if="hasContact(event.event_data.contacts) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">전화</span>
                                                    </li>

                                                    <li class="note" v-if="hasNote(event.event_data.note) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">노트</span>
                                                    </li>

                                                    <li class="memo" v-if="hasMemo(event.event_data.memo) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">메모</span>
                                                    </li>

                                                    <li class="pic" v-if="hasImage(event.event_data.attachment) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">사진</span>
                                                    </li>

                                                    <li class="file" v-if="hasFile(event.event_data.attachment) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">파일</span>
                                                    </li>

                                                    <li class="attnd" v-if="event.event_data.attend == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">참석여부</span>
                                                    </li>

                                                    <li class="vote" v-if="hasVote(event.event_data.vote) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">투표/설문</span>
                                                    </li>   

                                                    <li class="cmmnt" v-if="hasReply(event.exist_reply) == true">
                                                        <span class="icon"></span>
                                                        <span class="descript">댓글</span>
                                                    </li>

                                                    <li class="invite" v-if="isCard(event.event_sub_type)">
                                                        <span class="icon"></span>
                                                        <span class="descript">카드</span>
                                                    </li>

                                                    <li class="share" v-if="isShared(event.subscribe_users, event.subscribe_groups, event.subscribe_teams)">
                                                        <span class="icon"></span>
                                                        <span class="descript">공유일정</span>
                                                    </li>

                                                </ul>
                                            </div>
                                            <!-- 아이콘 영역 끝 -->

                                            <!-- 투표 참석 현황, 상황에 따라 변경 참석, 미정, 불참, 투표 진행 중, 투표 종료 -->
                                            <!-- <p class="stsBtn" v-if="event.event_data.attend == true || hasVote(event.event_data.vote) == true" :class="getAttendVoteClass(event.event_data.attend, event.user_attend, event.event_data.vote)">
                                                {{ getAttendVoteText(event.event_data.attend, event.user_attend, event.event_data.vote) }}
                                            </p> -->
                                        </div>

                                        <a href="#" class="seeDetail" @click.prevent="viewEvent(event)">일정상세 가기</a>

                                    </li>
                                    <!-- 일정 리스트 끝 -->
                                </ul>

                            </li>
                            <!-- 월별 리스트 끝 -->
                        </ul>
                        <!-- 스크롤 페이징 처리 하기 때문에 숨김, 더 보기 방식으로 전환 한다면 사용  -->
                        <!-- <div class="seeMoreDiv" v-else>
                            <a href="#" class="seeMore">더 보기</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- 일정 contents ends -->
        </div>
     </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, EVENT_SUB_TYPE, CRUD_TYPE, GROUP_TYPE, API_METHOD, SHARE_OPTION } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const EventInfo    = namespace('EventInfo');
const HoduDocInfo  = namespace('HoduDocInfo');

import { ScheduleSearchConfig, GroupAndTeamFilter, EventSearchOption } from '@/store/modules/CalendarInfo';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { t_event, t_event_alarm, t_event_location, t_event_contact, t_event_vote } from '@/model/event';
 
const dateFormat = require('dateformat');
import moment from 'moment';
import 'moment-lunar';

import { RRule, RRuleSet, rrulestr } from 'rrule'

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class EventSearch extends Mixins(VueHoduCommon) {
    
    /**
     * CalendarInfo.State
     */
    @CalendarInfo.State is_event_filter_search !: boolean;              // 일정 필터 검색 여부
    @CalendarInfo.State event_search_query     !: string;               // 일정 검색 쿼리  
    @CalendarInfo.State event_search_option    !: EventSearchOption;    // 일정 검색 옵션
 
    /** 
     * CalendarInfo.Action 
     */
    @CalendarInfo.Action doSetIsEventFilterSearch ?: (a : boolean) => void; // is_event_search_filter 업데이트 
    @CalendarInfo.Action doSetEventSearchQuery    ?: any;                   // event_search_query 업데이트
    @CalendarInfo.Action doSelectScheduleList     ?: any;                   // schedule_search_config 업데이트
    @CalendarInfo.Action doSetEventSearchOption   ?: any;                   // event_search_option 업데이트 

    /**
     * EventInfo.Action
     */
    @EventInfo.Action doSetIsFirstRepeatEvent ?: any; // 조회하는 반복일정이 해당 반복일정의 첫 일정이였다면 true 아니라면 false
    @EventInfo.Action doSetEventOriginalDate  ?: any; // 반복일정 조회시 해당 일정의 원본 date들을 설정

    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;

    event_search_query_temp    : string = "";
    render_events              : any[]     = [];
    events                     : t_event[] = [];
    last_event_id              : string    = "";
    is_paging_end              : boolean   = false;
    is_api_call                : boolean   = false;
    is_first_api_call_complete : boolean   = false;
    
    start_date_text : string | null = null;
    end_date_text   : string | null = null;

    get computedGroupAndTeam() : any[] {
        let group_and_team : any[] = [];

        const group_length : number = this.all_group_data.length;
        for( let i = 0; i < group_length; i++ ) {
            const group_data : any = this.all_group_data[i];
            group_and_team.push(group_data);

            if( group_data.teams == null || group_data.teams.length < 1 ) { continue; }

            const team_length : number = group_data.teams.length;
            for( let j = 0; j < team_length; j++ ) { group_and_team.push(group_data.teams[j]); }
        }

        return group_and_team;
    }

    searchOptionVisible : boolean = false; // 일정검색 옵션창 보일지 여부
    
    async mounted() : Promise<void> {
        await this.setScroll();
        if( this.is_event_filter_search == false ) { await this.searchEventForWeb(); }
        else { await this.searchEventForWebByFilter(); }

        $('#event_search').focus();
        this.event_search_query_temp = this.event_search_query;
    }

    /**
     * 스크롤 설정
     */
    async setScroll() : Promise<void> {
        const title_height  : number | undefined = $('.title_box').outerHeight();
        const search_height : number | undefined = $('.searchDiv').outerHeight();

        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ) - ( search_height == null ? 0 : search_height ),
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            callbacks : {
                whileScrolling: this.whileScrolling
            }
        });

    }

    /**
     * 일정 검색 keydown
     */
    eventKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.newSearchEvent();
    }

    /**
     * 새롭게 일정 검색 
     * select_by_config_change == true라면 설정 변경으로 인해 다시 조회 하는것
     */
    async newSearchEvent(select_by_config_change : boolean = false) : Promise<void> {
        this.$nextTick(async() => {
            if( this.is_api_call == true ) { return; }

            if( this.doSetIsEventFilterSearch != null ) { this.doSetIsEventFilterSearch(false); }
        
            if( select_by_config_change == false && this.event_search_query_temp.length < 2 ) {
                this.hodu_show_dialog('alert', '검색어를 2자 이상 입력해주세요', ["확인"]);
                return;
            }

            if( select_by_config_change == false ) {
                this.doSetEventSearchQuery(this.event_search_query_temp);
            }

            this.is_paging_end = false;
            this.is_first_api_call_complete = false;
            this.last_event_id = "";
            this.events.splice(0, this.events.length);
            this.render_events.splice(0, this.render_events.length);

            this.searchOptionReset();

            // @ts-ignore
            $('#eventSearchDiv').mCustomScrollbar("disable", true);
            // @ts-ignore
            $('#eventSearchDiv').mCustomScrollbar("update");
            await this.searchEventForWeb();
        });
    }

    /**
     * 새롭게 필터 검색
     */
    async newSearchEventByFilter() : Promise<void> {
        this.$nextTick(async() => {
            if( this.is_api_call == true ) { return; }
            if( this.doSetIsEventFilterSearch != null ) { this.doSetIsEventFilterSearch(true); }

            this.is_paging_end = false;
            this.is_first_api_call_complete = false;
            this.last_event_id = "";
            this.events.splice(0, this.events.length);
            this.render_events.splice(0, this.render_events.length);
            this.doSetEventSearchQuery("");
            this.event_search_query_temp = "";

            // @ts-ignore
            $('#eventSearchDiv').mCustomScrollbar("disable", true);
            // @ts-ignore
            $('#eventSearchDiv').mCustomScrollbar("update");
            await this.searchEventForWebByFilter();
        });
    }

    /**
     * 이벤트 조회
     */
    async searchEventForWeb() : Promise<void> {

        if( this.is_api_call == true || this.is_paging_end == true ) {
            return;
        }

        await this.get_group_role_service();

        const vue = this;
        this.is_api_call = true; 

        let owner_type  : string = this.scope;
        let owner_id    : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id :
                                   this.scope == OWNER_TYPE.GROUP    ? this.scope_group_id : this.scope_team_id;

        let calendar_id : string = this.calendar_id;
        let query : string = `?last_event_id=${this.last_event_id}&include_past_event=${ this.schedule_search_config.past_schedule }&mycal=${ this.scope == OWNER_TYPE.PERSONAL ? this.schedule_search_config.my_schedule : false }&syscal=${ this.scope == OWNER_TYPE.PERSONAL ? this.schedule_search_config.system_calendar : false }&shared=${ this.scope == OWNER_TYPE.PERSONAL ? this.schedule_search_config.shared_schedule : false }&search=${this.event_search_query}`;
        
        // groups 쿼리 생성
        if( this.user_group_role != null && this.user_group_role.length > 0 ){
            const role_size : number = this.user_group_role.length;
            for( let i = 0; i < role_size; i++ ){
                if ( this.user_group_role[i].group_id == null || this.user_group_role[i].group_id < 1 ) {
                    continue;
                }
                    
                // 개인 달력일때 그룹 필터에 해당 그룹 아이디가 존재한다면 다음순서로
                if( this.scope == OWNER_TYPE.PERSONAL && this.schedule_search_config.group_filter.indexOf(Number(this.user_group_role[i].group_id)) != -1 ){
                    continue;
                }

                // PERSONAL이 아니라면 해당 그룹의 정보만 추가한다
                if( this.scope != OWNER_TYPE.PERSONAL ) {
                    if( this.scope_group_id > 0 && this.scope_group_id != Number(this.user_group_role[i].group_id) ) {
                        continue;
                    }

                    let biz_id : string | null = null;
                    let hodu_c_group_length : number = this.hodu_c_group_data.length;
                    for( let j = 0; j < hodu_c_group_length; j++ ) {
                        if( this.hodu_c_group_data[j].group_id == Number(this.user_group_role[i].group_id) ) {
                            biz_id = this.hodu_c_group_data[j].biz_id;
                        }
                    }

                    // 판독한 그룹이 biz그룹이 아니라면 || 현재 보고 있는 달력의 비즈 그룹이 아니라면 continue;
                    if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                        continue;
                    }

                    // BIZ_ID로 필터를 뽑아낸다
                    let filter : GroupAndTeamFilter | null = null;
                    const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
                    for( let j = 0; j < filter_length; j++ ) {
                        if( this.schedule_search_config.group_and_team_filter[j].biz_id == biz_id &&
                            this.schedule_search_config.group_and_team_filter[j].scope == this.scope &&
                            this.schedule_search_config.group_and_team_filter[j].scope_id == (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) ) {
                            filter = this.schedule_search_config.group_and_team_filter[j];
                        }
                    }

                    // 필터에 들어있다면 건너뛴다
                    if( filter != null && filter.group_id == Number(this.user_group_role[i].group_id) ) {
                        continue;
                    }
                }

                query += `&groups=${Number(this.user_group_role[i].group_id)}`;
            }
        }

        // teams 쿼리 생성
        if( this.user_team_role != null && this.user_team_role.length > 0 ){
            const role_size : number = this.user_team_role.length;
            for( let i = 0; i < role_size; i++ ){
                if( this.user_team_role[i].team_id == null || this.user_team_role[i].team_id < 1 ){
                    continue;
                }
                    
                // 팀 필터에 해당 팀 아이디가 존재한다면 다음순서로
                if( this.scope == OWNER_TYPE.PERSONAL && this.schedule_search_config.team_filter.indexOf(Number(this.user_team_role[i].team_id)) != -1 ){
                    continue;
                }

                // PERSONAL이 아니라면 해당 팀의 정보만 추가한다
                if( this.scope != OWNER_TYPE.PERSONAL ) {
                    if( this.scope_team_id > 0 && this.scope_team_id != Number(this.user_team_role[i].team_id) ) {
                        continue;
                    }

                    let biz_id : string | null = null;
                    let hodu_c_group_length : number = this.hodu_c_group_data.length;
                    for( let j = 0; j < hodu_c_group_length; j++ ) {
                        const team_length : number = this.hodu_c_group_data[j].teams.length;
                        for( let k = 0; k < team_length; k++ ) {
                            if( this.hodu_c_group_data[j].teams[k].team_id == Number(this.user_team_role[i].team_id) ) {
                                biz_id = this.hodu_c_group_data[j].biz_id;
                                break;
                            }
                        }

                        // 비즈 아이디를 찾았다면 break;
                        if( biz_id != null ) {
                            break;
                        }
                    }

                    // 판독한 팀이 biz팀이 아니라면 || 현재 보고 있는 달력의 비즈 팀이 아니라면 continue;
                    if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                        continue;
                    }

                    // BIZ_ID로 필터를 뽑아낸다
                    let filter : GroupAndTeamFilter | null = null;
                    const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
                    for( let j = 0; j < filter_length; j++ ) {
                        if( this.schedule_search_config.group_and_team_filter[j].biz_id == biz_id &&
                            this.schedule_search_config.group_and_team_filter[j].scope == this.scope &&
                            this.schedule_search_config.group_and_team_filter[j].scope_id == (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) ) {
                            filter = this.schedule_search_config.group_and_team_filter[j];
                        }
                    }

                    // 필터에 들어있다면 건너뛴다
                    if( filter != null && filter.team_ids.indexOf(Number(this.user_team_role[i].team_id)) > -1 ) {
                        continue;
                    }
                }

                query += `&teams=${Number(this.user_team_role[i].team_id)}`;
            }
        }

        // 병원 예약 - 일반 달력인 경우 
        if( this.hodu_d_group_data.length > 0 && this.scope == OWNER_TYPE.PERSONAL ) {
            const hopital_count : number = this.hodu_d_group_data.length;
            for( let i = 0; i < hopital_count; i++ ) {
                const doctor_count : number = this.hodu_d_group_data[i].teams.length;
                for( let j = 0; j < doctor_count; j++ ) {
                    const doctor_key : string = `${this.hodu_d_group_data[i].teams[j].biz_id}___${this.hodu_d_group_data[i].teams[j].department_code}___${this.hodu_d_group_data[i].teams[j].doctor_code}`; 

                    // 필터에 들어 있는 경우 건너 뜀
                    if( this.schedule_search_config.hodu_d_filter.indexOf(doctor_key) > -1 ) {
                        continue;
                    }

                    query += `&doctors=${doctor_key}`;
                }
            }
        }

        // 병원 예약 - 호두 D 달력인 경우
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD && this.scope_group_team_option.biz_id != null && this.scope_group_team_option.biz_id.length > 0 ) {
            query += `&biz_type=${this.scope_group_team_option.biz_type}&biz_id=${this.scope_group_team_option.biz_id}`;

            const group_appointment_filter_count : number = this.schedule_search_config.group_appointment_filter.length;
            for( const doctor_key of this.schedule_search_config.group_appointment_filter ) {
                query += `&doctors=${doctor_key}`;
            }
        }
        
        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar("disable", false);
        await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}/web${query}`, API_METHOD.GET, undefined, true)
            .then(async(response) => {
                console.log(response);
                
                await vue.hodu_show_indicator();

                // null 체크
                if( response.data.data.events == null ) {
                    return;
                }

                await vue.makeEventsToRenderEvents(response);

                // 과거일정을 안보는 경우
                if( this.schedule_search_config.past_schedule == false ) {
                    const current_date : Date = new Date();
                    current_date.setHours(0);
                    current_date.setMinutes(0);
                    current_date.setSeconds(0);
                    current_date.setMilliseconds(0);

                    const return_events_length : number = this.render_events.length;
                    for( let i = (return_events_length - 1); i >= 0; i-- ) {
                        const year_and_month_last_date : Date = new Date(this.render_events[i].year_and_month.replace(/\./g, "-"));
                        
                        // 해당 월의 마지막 일자 23시 59분 59초 999로 만들고 비교
                        year_and_month_last_date.setMonth(year_and_month_last_date.getMonth() + 1);
                        year_and_month_last_date.setDate(0);
                        year_and_month_last_date.setHours(23);
                        year_and_month_last_date.setMinutes(59);
                        year_and_month_last_date.setSeconds(59);
                        year_and_month_last_date.setMilliseconds(999);

                        // 해당 월이 과거라면 제외시킨다
                        if( year_and_month_last_date.getTime() < current_date.getTime() ) {
                            this.render_events.splice(i, 1);
                            continue;
                        }

                        const render_event :any = JSON.parse(JSON.stringify(this.render_events[i]));
                        let events : t_event[] = render_event.events == null ? null : JSON.parse(JSON.stringify(render_event.events));
                        if( events == null ) {
                            render_event.events = [];
                            events = [];
                        }

                        const events_length : number = events.length;
                        render_event.events.splice(0, events_length);
                        for( let j = 0; j < events_length; j++ ) {
                            const event : t_event = events[j];

                            const start_date : Date = new Date(event.event_data.schedule_date.start);

                            // 오늘 0시 0분 0초보다 미래라면 push
                            if( start_date.getTime() >= current_date.getTime() ) {
                                render_event.events.push(event);
                            }
                        }

                        // 일정이 전부 잘려나갔다면 월을 없앤다
                        if( render_event.events.length < 1 ) {
                            this.render_events.splice(i, 1);
                            continue;
                        }
                        
                        // 일정이 남아있다면 대체시킨다
                        // render_event.events = render_event.events.concat(events);
                        this.render_events.splice(i, 1, render_event);
                    }
                }

                this.$nextTick(() => { setTimeout(() => { if( this.is_first_api_call_complete == false ) { this.compareToday(); } }, 500); });
            })
            .catch(async(e) => { this.hodu_error_process(e, true, false); })
            .finally(async() => {
                if( this.render_events == null || this.render_events.length < 1 ) { this.events.splice(0, this.events.length); }
                vue.is_api_call = false;
                await this.hodu_hide_indicator();

                // @ts-ignore
                $('#eventSearchDiv').mCustomScrollbar("update");
            });

    }

    /**
     * 이벤트 데이터를 렌더링 가능한 데이터로 변경 
     */
    async makeEventsToRenderEvents(response : any) : Promise<void> {
        const vue = this;
        
        await this.hodu_show_indicator();

        let groups : number[] = [];
        let teams : number[] = [];

        if( this.user_group_role != null && this.user_group_role.length > 0 ) {
            for( const group_role of this.user_group_role ) {
                if( group_role.group_id == null || group_role.group_id < 1 ) {
                    continue
                }
                groups.push(group_role.group_id);
            }
        }

        if( this.user_team_role != null && this.user_team_role.length > 0 ) {
            for( const team_role of this.user_team_role ) {
                if( team_role.team_id == null || team_role.team_id < 1 ) {
                    continue
                }
                teams.push(team_role.team_id);
            }
        }

        const temp_events : t_event[] = [];
        const response_events : t_event[] = response.data.data.events;

        for( const event of response_events ) {

            if ( event == null || event.event_data == null ) {
                temp_events.push(event);
                continue;
            }

            const event_data = event.event_data;

            // 숨김 처리 아니라면 추가
            if( (event_data.is_private ?? false) == false ) {
                temp_events.push(event);
                continue;
            }

            // 본인 작성이면 숨김상태여도 그대로 추가
            if( event_data.event_owner_id == this.user_id ) {
                temp_events.push(event);
                continue;
            }

            // 권한 구하기
            let is_permitted : boolean = false;
            if( event.team_id > 0 ) {
                if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                    is_permitted = this.is_team_permmision(event.team_id, "event", "private_read");
                }
                else if( this.isMeetingLog(event.event_sub_type) ) {
                    is_permitted = this.is_team_permmision(event.team_id, "meetinglog", "private_read");
                }
                else if( this.isReport(event.event_sub_type) ) {
                    is_permitted = this.is_team_permmision(event.team_id, "report", "private_read");
                }
                else if( this.isWork(event.event_sub_type) ) {
                    is_permitted = this.is_team_permmision(event.team_id, "work", "private_read");
                }
            }
            else if( event.group_id > 0 ) {
                if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                    is_permitted = this.is_group_permmision(event.group_id, "event", "private_read");
                }
                else if( this.isMeetingLog(event.event_sub_type) ) {
                    is_permitted = this.is_group_permmision(event.group_id, "meetinglog", "private_read");
                }
                else if( this.isReport(event.event_sub_type) ) {
                    is_permitted = this.is_group_permmision(event.group_id, "report", "private_read");
                }
                else if( this.isWork(event.event_sub_type) ) {
                    is_permitted = this.is_group_permmision(event.group_id, "work", "private_read");
                }
            }

            if( is_permitted == true ) {
                temp_events.push(event);
                continue;
            }

            // 프로젝트의 경우 담당자면 볼 수 있음
            if( event.event_sub_type, "WORK" && event_data.work != null ) {

                if( event_data.work?.assignment_type == "ALL" ) {
                    temp_events.push(event);
                    continue;
                }

                const assign_user_ids = event_data.work!.assign_user_ids;
                if( assign_user_ids != null && assign_user_ids.indexOf(this.user_id) > -1 ) {
                    temp_events.push(event);
                    continue;
                }

            }

            // 숨김 처리인데 개인 공유 받은 경우 추가
            if( event.subscribe_users != null && event.subscribe_users.indexOf(this.user_id) > -1 ) {
                temp_events.push(event);
                continue;
            }

            // 숨김 처리인데 그룹 공유 받은 경우 추가
            for( const group_id of groups ) {
                if ( event.subscribe_groups != null && event.subscribe_groups.indexOf(group_id) > -1 ) {
                    temp_events.push(event);
                    break;
                }
            }

            if( temp_events.indexOf(event) > -1 ) continue;

            // 숨김 처리인데 팀 공유 받은 경우 추가
            for( const team_id of teams ) {
                if ( event.subscribe_teams != null && event.subscribe_teams.indexOf(team_id) > -1) {
                    temp_events.push(event);
                    break;
                }
            }

            if( temp_events.indexOf(event) > -1 ) continue;
        }

        vue.events = vue.events.concat(temp_events);

        // console.log(JSON.stringify(response.data.data.events));

        // 이벤트 조회된 개수가 50개 미만이면 페이지 끝 
        if( response.data.data.events.length < 50 ) {
            vue.is_paging_end = true;
        }

        // 이벤트 조회된게 없으면 나가기
        if( response.data.data.events.length < 1 ) {
            vue.is_api_call = false;
            return;
        }

        // 마지막 이벤트 id 담기
        let last_event : t_event | null = null;
        if( vue.events.length > 0 ) {
            last_event = vue.events[vue.events.length - 1];
            if( last_event.event_id != null ) {
                vue.last_event_id = last_event.event_id;
            }
        }

        // 연속일정 반복일정 첫 날짜 이외의 쪼개진 날짜의 이벤트들 다 제거 (구분법은 original_start가 null이 아니면 됨)
        const render_month_length : number = vue.render_events.length;
        for( let i = 0; i < render_month_length; i++ ) {
            const render_month_event_length : number = vue.render_events[i].events.length;
            for( let j = render_month_event_length - 1; j >= 0; j-- ) {
                const event : t_event = vue.render_events[i].events[j];
                if( event.original_start != null ) {
                    vue.render_events[i].events.splice(j, 1);
                }
            }
        }

        // 과거일정 체크용
        const current_date : Date = new Date();
        current_date.setHours(0);
        current_date.setMinutes(0);
        current_date.setSeconds(0);
        current_date.setMilliseconds(0);
        
        // 조회된 이벤트 가공
        const select_event_length : number = temp_events.length;
        for( let i = 0; i < select_event_length; i++ ) {
            const select_event : t_event = temp_events[i];

            // 권한 체크는 서버에서만 체크하도록 변경, 공유된 일정에 대해서 하나라도 권한이 있는지 체크해야하므로 느려질 가능성이 있기 때문
            // 본인이 작성한게 아니라면 권한체크
            // if( select_event.event_data.event_owner_id != this.user_id ) {
            //     // 일정 읽기 권한 없으면 건너뛴다
            //     if( (select_event.event_sub_type == EVENT_SUB_TYPE.SCHEDULE || select_event.event_sub_type == EVENT_SUB_TYPE.CARD) && !(select_event.group_id && select_event.group_id > 0 && this.is_group_permmision(select_event.group_id, 'event', 'read') || 
            //         (select_event.team_id && select_event.team_id > 0 && this.is_team_permmision(select_event.team_id, 'event', 'read'))) ) {
            //         continue;
            //     }

            //     // 업무 일지 읽기 권한 없으면 건너뛴다
            //     if( select_event.event_sub_type == EVENT_SUB_TYPE.REPORT && !(select_event.group_id && select_event.group_id > 0 && this.is_group_permmision(select_event.group_id, 'report', 'read') || 
            //         (select_event.team_id && select_event.team_id > 0 && this.is_team_permmision(select_event.team_id, 'report', 'read'))) ) {
            //         continue;
            //     }

            //     // 회의록 읽기 권한 없으면 건너뛴다
            //     if( select_event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG && !(select_event.group_id && select_event.group_id > 0 && this.is_group_permmision(select_event.group_id, 'meetinglog', 'read') || 
            //         (select_event.team_id && select_event.team_id > 0 && this.is_team_permmision(select_event.team_id, 'meetinglog', 'read'))) ) {
            //         continue;
            //     }

            //     // 프로젝트 읽기 권한 없으면 건너뛴다
            //     if( select_event.event_sub_type == EVENT_SUB_TYPE.WORK && !(select_event.group_id && select_event.group_id > 0 && this.is_group_permmision(select_event.group_id, 'work', 'read') || 
            //         (select_event.team_id && select_event.team_id > 0 && this.is_team_permmision(select_event.team_id, 'work', 'read'))) ) {
            //         continue;
            //     }
            // }

            select_event.lunar_date_text = await vue.getAmPmTime(new Date(select_event.event_data.schedule_date.start), new Date(select_event.event_data.schedule_date.end), select_event.event_data.schedule_date.isAllDay, select_event.event_data.schedule_date.lunar_yn);

            // RGB값만 있다면 그대로 RGB를 사용, ARGB라면 ARGB를 RGB로 변환
            select_event.event_data.color = this.hodu_hex_color_process(select_event.event_data.color);

            const year_and_month : string = vue.getYearAndMonth(new Date(select_event.event_data.schedule_date.start));

            // 만들어진 year_and_month가 이미 있는지 체크
            let year_and_month_index : number = -1;
            const render_event_length : number = vue.render_events.length;
            for( let j = 0; j < render_event_length; j++ ) {
                
                // 이미 만들어졌다면 index를 담고 나가기
                if( vue.render_events[j].year_and_month == year_and_month ) {
                    year_and_month_index = j;
                    break;
                }
            }

            // 안만들어져 있다면 새로 추가하고 index 담기
            if( year_and_month_index == -1 ) {
                const year_and_month_last_date : Date = new Date(year_and_month.replace(/\./g, "-"));

                year_and_month_index = render_event_length;
                vue.render_events.push({
                    "year_and_month" : year_and_month,
                    "events"         : []
                })
            }

            // vue.render_events[year_and_month_index].events에 이벤트 데이터 넣기!
            vue.render_events[year_and_month_index].events.push(select_event);
            
            // 연속&반복 일정인 경우 첫일정의 연속일정 뒤쪽을 render_events[n].events에 미리 추가시켜준다
            const date_diff : number             = vue.getDateDiff(select_event.event_data.schedule_date.start, select_event.event_data.schedule_date.end);
            const rrule     : string | undefined = select_event.event_data.schedule_date.rrule;
            if( date_diff > 0 && vue.isRepeat(rrule == null ? "" : rrule) == true ) {
                const event_fragments : t_event[] = [];

                for( let j = 1; j <= date_diff; j++ ) {
                    const event_fragment : t_event = JSON.parse(JSON.stringify(select_event));
                    const start_date : Date = new Date(select_event.event_data.schedule_date.start); 
                    const new_start  : Date = new Date(start_date);
                    const end_date : Date = new Date(select_event.event_data.schedule_date.end); 

                    new_start.setDate(start_date.getDate() + j);

                    // 잘린 날짜가 마지막 일정의 날짜보다 미래의 날짜라면
                    if( vue.is_paging_end == false && vue.getDateDiff(new_start, last_event!.event_data.schedule_date.start) > 0 && 
                        new_start.getTime() > new Date(last_event!.event_data.schedule_date.start).getTime() ) {
                        continue;
                    }

                    event_fragment.event_data.schedule_date.start = new_start;
                    event_fragment.original_start                 = start_date;
                    event_fragment.original_end                   = end_date;
                    event_fragments.push(event_fragment);
                }

                // 잘라낸 일정을 original_end와 original_start를 넣고 reder_event에 넣기
                const event_fragment_length : number = event_fragments.length;
                for( let j = 0; j < event_fragment_length; j++ ) {
                    const event_fragment : t_event = event_fragments[j];
                    const year_and_month : string = vue.getYearAndMonth(new Date(event_fragment.event_data.schedule_date.start));

                    // 만들어진 year_and_month가 이미 있는지 체크
                    let year_and_month_index : number = -1;
                    const render_event_length : number = vue.render_events.length;
                    for( let k = 0; k < render_event_length; k++ ) {
                        
                        // 이미 만들어졌다면 index를 담고 나가기
                        if( vue.render_events[k].year_and_month == year_and_month ) {
                            year_and_month_index = k;
                            break;
                        }
                    }

                    // 안만들어져 있다면 새로 추가하고 index 담기
                    if( year_and_month_index == -1 ) {
                        year_and_month_index = render_event_length;
                        vue.render_events.push({
                            "year_and_month" : year_and_month,
                            "events"         : []
                        })
                    }

                    vue.render_events[year_and_month_index].events.push(event_fragment);
                }

            }
        }

        // events에서 연속일정, 반복일정을 찾는다
        const event_length : number = vue.events.length;
        for( let i = 0; i < event_length; i++ ) {

            const event : t_event = vue.events[i];
            const event_fragments : t_event[] = [];

            // 연속 일정이거나 반복 일정인 경우 처리
            const date_diff : number             = vue.getDateDiff(event.event_data.schedule_date.start, event.event_data.schedule_date.end);
            const rrule     : string | undefined = event.event_data.schedule_date.rrule;
            
            /**
             * 반복일정인 경우 - rrule.js 이용해서 쪼갬 (recurrence_end가 마지막 일정의 날짜보다 크다면 마지막 일정의 날짜까지만 UNTIL 설정)
             */
            if( vue.isRepeat(rrule == null ? "" : rrule) == true ) {

                let last_current_date_diff : number = vue.getDateDiff(event.event_data.schedule_date.recurrence_end, last_event!.event_data.schedule_date.start);
                
                // 현재 일정의 recurrence_end가 더 미래의 날짜라면 
                if( new Date(event.event_data.schedule_date.recurrence_end).getTime() > new Date(last_event!.event_data.schedule_date.start).getTime() ) {
                    last_current_date_diff = -last_current_date_diff;
                }

                const rrule_dtstart : string = `${vue.formatDateForRruleDTSTART(new Date(event.event_data.schedule_date.start))}`;
                const rrule_until   : string = `${vue.formatDateForRruleUNTIL( last_current_date_diff < 0 && vue.is_paging_end == false ? new Date(last_event!.event_data.schedule_date.start)
                                                                                                                                        : new Date(event.event_data.schedule_date.recurrence_end) )}`;

                const full_rrule : string = `DTSTART:${rrule_dtstart}\nRRULE:${rrule};UNTIL=${rrule_until}`;
                
                const rrule_obj : RRule | RRuleSet = rrulestr(full_rrule);
                // console.log(`${event.event_data.title} : ${rrule_obj.all()}`);
                
                let lunar_arr : any[] = [];
                if( event.event_data.schedule_date.lunar_yn == true && event.event_data.schedule_date.lunar_start != null && 
                        event.event_data.schedule_date.lunar_end != null ) {
                    const intercalation_obj : any = await vue.hodu_is_intercalation(event.event_data.schedule_date.start);
                    lunar_arr = await vue.hodu_lunar_to_solars(event.event_data.schedule_date.lunar_start.replace(/-/ig, '').substr(0, 8), intercalation_obj.is_intercalation);
                }

                // 반복 일정 쪼개서 넣기
                const rrule_all : Date[] = rrule_obj.all();
                const rrule_all_length : number = rrule_all.length;
                const start_date : Date = new Date(event.event_data.schedule_date.start); 
                const end_date   : Date = new Date(event.event_data.schedule_date.end); 
                for( let i = 1; i < rrule_all_length; i++ ) {
                    const event_fragment : t_event = JSON.parse(JSON.stringify(event));
                    let new_start : Date = new Date(start_date);
                    let new_end : Date = new Date(end_date);
                    const target_date : Date = rrule_all[i];

                    // exdate에 포함되어있는지 검사
                    let include_exdate : boolean = false;
                    const exdates : Date[] | undefined = event_fragment.event_data.schedule_date.exdate;
                    if( exdates != null ) {
                        const exdate_length : number = exdates != null ? exdates.length : 0;
                        for( let j = 0; j < exdate_length; j++ ) {
                            const exdate : Date = new Date(exdates[j]);
                            if( vue.getDateDiff(target_date, exdate) == 0 ) {
                                include_exdate = true;
                                break;
                            }
                        }
                    }

                    // exdate에 포함되는 날짜라면 건너뛴다
                    if( include_exdate == true ) {
                        continue;
                    }

                    // 날짜 조작
                    new_start.setFullYear(target_date.getFullYear());
                    new_start.setMonth(target_date.getMonth());
                    new_start.setDate(target_date.getDate());

                    new_end.setFullYear(target_date.getFullYear());
                    new_end.setMonth(target_date.getMonth());
                    new_end.setDate(target_date.getDate());

                    // 음력 처리(음력 반복은 1일짜리 일정밖에 없음)
                    if( event_fragment.event_data.schedule_date.lunar_yn == true && event_fragment.event_data.schedule_date.lunar_start != null && 
                        event_fragment.event_data.schedule_date.lunar_end != null ) {

                        if( lunar_arr[i] == null ) { break; }

                        // const new_lunar_start : Date = new Date(lunar_arr[i].lunar_ymd);
                        // new_lunar_start.setHours(0);
                        // new_lunar_start.setMinutes(0);
                        // new_lunar_start.setSeconds(0);
                        // new_lunar_start.setMilliseconds(0);

                        // const new_lunar_end : Date = new Date(lunar_arr[i].lunar_ymd);
                        // new_lunar_end.setHours(23);
                        // new_lunar_end.setMinutes(59);
                        // new_lunar_end.setSeconds(59);
                        // new_lunar_end.setMilliseconds(999);

                        event_fragment.event_data.schedule_date.lunar_start = lunar_arr[i].lunar_ymd;
                        event_fragment.event_data.schedule_date.lunar_end   = lunar_arr[i].lunar_ymd;

                        new_start = new Date(lunar_arr[i].solar_ymd);
                        new_start.setHours(0);
                        new_start.setMinutes(0);
                        new_start.setSeconds(0);
                        new_start.setMilliseconds(0);

                        new_end = new Date(lunar_arr[i].solar_ymd);
                        new_end.setHours(23);
                        new_end.setMinutes(59);
                        new_end.setSeconds(59);
                        new_end.setMilliseconds(999);
                    }
                    
                    event_fragment.original_start = start_date;
                    event_fragment.original_end   = end_date;
                    event_fragment.event_data.schedule_date.start = new_start;
                    event_fragment.event_data.schedule_date.end   = new_end;
                    event_fragments.push(event_fragment);
                }

            }

            // 연속일정, 반복일정, 연속 반복일정을 전부 쪼개서 보여주던 시절에 사용한 로직 필요하다면 사용할것
            // /**
            //  * 연속일정 Only인 경우 - 시작일 종료일 차이나는 일수 기준으로 자른다
            //  */
            // if( date_diff > 0 && vue.isRepeat(rrule == null ? "" : rrule) == false ) {

            //     for( let i = 1; i <= date_diff; i++ ) {
            //         const event_fragment : t_event = JSON.parse(JSON.stringify(event));
            //         const start_date : Date = new Date(event.event_data.schedule_date.start); 
            //         const new_start  : Date = new Date(start_date);
            //         // 종료일도 건드릴 필요가 있다면 할것

            //         new_start.setDate(start_date.getDate() + i);

            //         // 잘린 날짜가 마지막 일정의 날짜보다 미래의 날짜라면
            //         if( vue.is_paging_end == false && vue.getDateDiff(new_start, last_event.event_data.schedule_date.start) > 0 && 
            //             new_start.getTime() > new Date(last_event.event_data.schedule_date.start).getTime() ) {
            //             continue;
            //         }

            //         event_fragment.event_data.schedule_date.start = new_start;
            //         event_fragment.original_start                 = start_date;
            //         event_fragments.push(event_fragment);
            //     }

            // }

            // /**
            //  * 반복일정 Only인 경우 - rrule.js 이용해서 쪼갬 (recurrence_end가 마지막 일정의 날짜보다 크다면 마지막 일정의 날짜까지만 UNTIL 설정)
            //  */
            // if( date_diff == 0 && vue.isRepeat(rrule == null ? "" : rrule) == true ) {

            //     let last_current_date_diff : number = vue.getDateDiff(event.event_data.schedule_date.recurrence_end, last_event.event_data.schedule_date.start);
                
            //     // 현재 일정의 recurrence_end가 더 미래의 날짜라면 
            //     if( new Date(event.event_data.schedule_date.recurrence_end).getTime() > new Date(last_event.event_data.schedule_date.start).getTime() ) {
            //         last_current_date_diff = -last_current_date_diff;
            //     }

            //     const rrule_dtstart : string = `${vue.formatDateForRruleDTSTART(new Date(event.event_data.schedule_date.start))}`;
            //     const rrule_until   : string = `${vue.formatDateForRruleUNTIL( last_current_date_diff < 0 && vue.is_paging_end == false ? new Date(last_event.event_data.schedule_date.start)
            //                                                                                                                             : new Date(event.event_data.schedule_date.recurrence_end) )}`;

            //     const full_rrule : string = `DTSTART:${rrule_dtstart}\nRRULE:${rrule};UNTIL=${rrule_until}`;
                
            //     const rrule_obj : RRule | RRuleSet = rrulestr(full_rrule);
            //     // console.log(`${event.event_data.title} : ${rrule_obj.all()}`);

            //     let lunar_arr : any[] = [];
            //     if( event.event_data.schedule_date.lunar_yn == true && event.event_data.schedule_date.lunar_start != null && 
            //             event.event_data.schedule_date.lunar_end != null ) {
            //         lunar_arr = await vue.hodu_lunar_to_solars(event.event_data.schedule_date.lunar_start);
            //     }

            //     // 반복 일정 쪼개서 넣기
            //     const rrule_all : Date[] = rrule_obj.all();
            //     const rrule_all_length : number = rrule_all.length;
            //     const start_date : Date = new Date(event.event_data.schedule_date.start); 
            //     const end_date   : Date = new Date(event.event_data.schedule_date.end); 
            //     for( let i = 1; i < rrule_all_length; i++ ) {
            //         const event_fragment : t_event = JSON.parse(JSON.stringify(event));
            //         let new_start : Date = new Date(start_date);
            //         let new_end : Date = new Date(end_date);
            //         const target_date : Date = rrule_all[i];

            //         // exdate에 포함되어있는지 검사
            //         let include_exdate : boolean = false;
            //         const exdates : Date[] | undefined = event_fragment.event_data.schedule_date.exdate;
            //         if( exdates != null ) {
            //             const exdate_length : number = exdates != null ? exdates.length : 0;
            //             for( let j = 0; j < exdate_length; j++ ) {
            //                 const exdate : Date = new Date(exdates[j]);
            //                 if( vue.getDateDiff(target_date, exdate) == 0 ) {
            //                     include_exdate = true;
            //                     break;
            //                 }
            //             }
            //         }

            //         // exdate에 포함되는 날짜라면 건너뛴다
            //         if( include_exdate == true ) {
            //             continue;
            //         }

            //         // 날짜 조작
            //         new_start.setFullYear(target_date.getFullYear());
            //         new_start.setMonth(target_date.getMonth());
            //         new_start.setDate(target_date.getDate());

            //         new_end.setFullYear(target_date.getFullYear());
            //         new_end.setMonth(target_date.getMonth());
            //         new_end.setDate(target_date.getDate());

            //         // 음력 처리(음력 반복은 1일짜리 일정밖에 없음)
            //         if( event_fragment.event_data.schedule_date.lunar_yn == true && event_fragment.event_data.schedule_date.lunar_start != null && 
            //             event_fragment.event_data.schedule_date.lunar_end != null ) {

            //             const new_lunar_start : Date = new Date(lunar_arr[i].lunar_ymd);
            //             new_lunar_start.setHours(0);
            //             new_lunar_start.setMinutes(0);
            //             new_lunar_start.setSeconds(0);
            //             new_lunar_start.setMilliseconds(0);

            //             const new_lunar_end : Date = new Date(lunar_arr[i].lunar_ymd);
            //             new_lunar_end.setHours(23);
            //             new_lunar_end.setMinutes(59);
            //             new_lunar_end.setSeconds(59);
            //             new_lunar_end.setMilliseconds(999);

            //             event_fragment.event_data.schedule_date.lunar_start = new_lunar_start;
            //             event_fragment.event_data.schedule_date.lunar_end   = new_lunar_end;

            //             new_start = new Date(lunar_arr[i].solar_ymd);
            //             new_start.setHours(0);
            //             new_start.setMinutes(0);
            //             new_start.setSeconds(0);
            //             new_start.setMilliseconds(0);

            //             new_end = new Date(lunar_arr[i].solar_ymd);
            //             new_end.setHours(23);
            //             new_end.setMinutes(59);
            //             new_end.setSeconds(59);
            //             new_end.setMilliseconds(999);
            //         }
                    
            //         event_fragment.original_start = start_date;
            //         event_fragment.original_end   = end_date;
            //         event_fragment.event_data.schedule_date.start = new_start;
            //         event_fragment.event_data.schedule_date.end   = new_end;
            //         event_fragments.push(event_fragment);
            //     }

            // }

            // /**
            //  * 연속일정 AND 반복일정인 경우 - rrule.js이용해서 쪼갠후(recurrence_end가 마지막 일정의 날짜보다 크다면 마지막 일정의 날짜까지만 UNTIL 설정) 
            //  * 쪼개진 일정을 시작일 종료일 차이나는 일수 기준으로 다시 쪼갠다         
            //  */                                                                   
            // if( date_diff > 0 && vue.isRepeat(rrule == null ? "" : rrule) == true ) {
            //     let last_current_date_diff : number = vue.getDateDiff(event.event_data.schedule_date.recurrence_end, last_event.event_data.schedule_date.start);
                
            //     // 현재 일정의 recurrence_end가 더 미래의 날짜라면 
            //     if( new Date(event.event_data.schedule_date.recurrence_end).getTime() > new Date(last_event.event_data.schedule_date.start).getTime() ) {
            //         last_current_date_diff = -last_current_date_diff;
            //     }

            //     const rrule_dtstart : string = `${vue.formatDateForRruleDTSTART(new Date(event.event_data.schedule_date.start))}`;
            //     const rrule_until   : string = `${vue.formatDateForRruleUNTIL( last_current_date_diff < 0 && vue.is_paging_end == false ? new Date(last_event.event_data.schedule_date.start)
            //                                                                                                                             : new Date(event.event_data.schedule_date.recurrence_end) )}`;

            //     const full_rrule : string = `DTSTART:${rrule_dtstart}\nRRULE:${rrule};UNTIL=${rrule_until}`;
                
            //     const rrule_obj : RRule | RRuleSet = rrulestr(full_rrule);
            //     // console.log(`${event.event_data.title} : ${rrule_obj.all()}`);

            //     // 반복 일정 쪼개서 넣기
            //     const rrule_all : Date[] = rrule_obj.all();
            //     const rrule_all_length : number = rrule_all.length;
            //     const start_date : Date = new Date(event.event_data.schedule_date.start); 
            //     const end_date   : Date = new Date(event.event_data.schedule_date.end); 
            //     for( let i = 1; i < rrule_all_length; i++ ) {
            //         const event_fragment : t_event = JSON.parse(JSON.stringify(event));
            //         const new_start : Date = new Date(start_date);
            //         const new_end : Date = new Date(end_date);
            //         const target_date : Date = rrule_all[i];

            //         // exdate에 포함되어있는지 검사
            //         let include_exdate : boolean = false;
            //         const exdates : Date[] | undefined = event_fragment.event_data.schedule_date.exdate;
            //         if( exdates != null ) {
            //             const exdate_length : number = exdates != null ? exdates.length : 0;
            //             for( let j = 0; j < exdate_length; j++ ) {
            //                 const exdate : Date = new Date(exdates[j]);
            //                 if( vue.getDateDiff(target_date, exdate) == 0 ) {
            //                     include_exdate = true;
            //                     break;
            //                 }
            //             }
            //         }

            //         // exdate에 포함되는 날짜라면 건너뛴다
            //         if( include_exdate == true ) {
            //             continue;
            //         }

            //         // 날짜 조작
            //         new_start.setFullYear(target_date.getFullYear());
            //         new_start.setMonth(target_date.getMonth());
            //         new_start.setDate(target_date.getDate());

            //         new_end.setFullYear(target_date.getFullYear());
            //         new_end.setMonth(target_date.getMonth());
            //         new_end.setDate(target_date.getDate() + date_diff);
                    
            //         event_fragment.original_start = start_date;
            //         event_fragment.original_end   = end_date;
            //         event_fragment.event_data.schedule_date.start = new_start;
            //         event_fragment.event_data.schedule_date.end   = new_end;

            //         for( let j = 0; j <= date_diff; j++ ) {
            //             const event_fragment2 : t_event = JSON.parse(JSON.stringify(event_fragment));
            //             const start_date : Date = new Date(event_fragment.event_data.schedule_date.start); 
            //             const new_start  : Date = new Date(start_date);
            //             // 종료일도 건드릴 필요가 있다면 할것

            //             new_start.setDate(start_date.getDate() + j);

            //             // 잘린 날짜가 마지막 일정의 날짜보다 미래의 날짜라면
            //             if( vue.is_paging_end == false && vue.getDateDiff(new_start, last_event.event_data.schedule_date.start) > 0 && 
            //                 new_start.getTime() > new Date(last_event.event_data.schedule_date.start).getTime() ) {
            //                 continue;
            //             }

            //             event_fragment2.event_data.schedule_date.start = new_start;
            //             event_fragments.push(event_fragment2);
            //         }

            //         // event_fragments.push(event_fragment);
            //     }
            // }

            // 잘라낸 일정을 original_end와 original_start를 넣고 reder_event에 넣기
            const event_fragment_length : number = event_fragments.length;
            for( let j = 0; j < event_fragment_length; j++ ) {
                const event_fragment : t_event = event_fragments[j];
                const year_and_month : string = vue.getYearAndMonth(new Date(event_fragment.event_data.schedule_date.start));
                
                let year_and_month_index : number = -1;
                const render_event_length : number = vue.render_events.length;
                
                for( let k = 0; k < render_event_length; k++ ) {
                    
                    // 이미 만들어졌다면 index를 담고 나가기
                    if( vue.render_events[k].year_and_month == year_and_month ) {
                        year_and_month_index = k;
                        break;
                    }
                }
                

                // 안만들어져 있다면 새로 추가하고 index 담기
                if( year_and_month_index == -1 ) {
                    year_and_month_index = render_event_length;
                    vue.render_events.push({
                        "year_and_month" : year_and_month,
                        "events"         : []
                    })
                }

                vue.render_events[year_and_month_index].events.push(event_fragment);
            }
        }

        /**
         * 년, 월 정렬
         */
        this.render_events.sort((a : any, b : any) : number => {
            const a_start : Date = new Date(a.year_and_month.replace(/\./g, "-"));
            const b_start : Date = new Date(b.year_and_month.replace(/\./g, "-"));

            if( a_start.getTime() > b_start.getTime() ) {
                return 1;
            }
            
            else if( a_start.getTime() < b_start.getTime() ) {
                return -1;
            } 
            
            else {
                return 0;
            }

        });

        /**
         * events 일자별로 재정렬
         */
        const render_events_length = vue.render_events.length;
        for( let i = 0; i < render_events_length; i++ ) {
            this.render_events[i].events.sort((a : t_event, b : t_event) : number => {
                const a_start : Date = new Date(a.event_data.schedule_date.start); 
                const b_start : Date = new Date(b.event_data.schedule_date.start); 

                if( a_start.getTime() > b_start.getTime() ) {
                    return 1;
                }
                
                else if( a_start.getTime() < b_start.getTime() ) {
                    return -1;
                } 
                
                else {
                    return 0;
                }

            });
        }

        await this.hodu_hide_indicator();
    }

    /**
     * 이벤트 필터 검색
     */
    async searchEventForWebByFilter() : Promise<void> {

        if( this.is_api_call == true || this.is_paging_end == true ) {
            return;
        }

        this.is_api_call = true; 

        let owner_type  : string = this.scope;
        let owner_id    : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id :
                                   this.scope == OWNER_TYPE.GROUP    ? this.scope_group_id : this.scope_team_id;

        let calendar_id : string = this.calendar_id;
        let query : string = `?last_event_id=${this.last_event_id}`;
        query += `&search_event_type=${this.event_search_option.search_event_type}`;
        if( this.event_search_option.start_date       != null ) { query += `&start_date=${moment(this.event_search_option.start_date).utc().format()}`; }
        if( this.event_search_option.end_date         != null ) { query += `&end_date=${moment(this.event_search_option.end_date).utc().format()}`; }
        if( this.event_search_option.event_title      != null ) { query += `&event_title=${this.event_search_option.event_title}`; }
        if( this.event_search_option.event_owner_name != null ) { query += `&event_owner_name=${this.event_search_option.event_owner_name}`; }
        if( this.event_search_option.location_name    != null ) { query += `&location_name=${this.event_search_option.location_name}`; }
        query += `&has_photo=${this.event_search_option.has_photo}`;
        query += `&has_file=${this.event_search_option.has_file}`;
        query += `&has_chat=${this.event_search_option.has_chat}`;
        query += `&has_attend=${this.event_search_option.has_attend}`;
        query += `&has_vote=${this.event_search_option.has_vote}`;
        query += `&is_work_wait=${this.event_search_option.is_work_wait}`;
        query += `&is_work_start=${this.event_search_option.is_work_start}`;
        query += `&is_work_end=${this.event_search_option.is_work_end}`;

        // event_color 쿼리 생성
        if( this.event_search_option.event_color != null && this.event_search_option.event_color.length < 20 ) {
            const color_size : number = this.event_search_option.event_color.length;
             for( let i = 0; i < color_size; i++ ) {
                 if( this.event_search_option.event_color[i] == null || this.event_search_option.event_color[i].length < 7 ) {
                     continue;
                 }

                 query += `&event_color=${encodeURIComponent(this.event_search_option.event_color[i])}`;
                 if( this.event_search_option.event_color[i].length == 7 ) { query += `&event_color=${encodeURIComponent(this.event_search_option.event_color[i].replace(/#/, "#FF"))}`; }
                 if( this.event_search_option.event_color[i].length == 9 ) { query += `&event_color=${encodeURIComponent(this.event_search_option.event_color[i].replace(/#FF/, "#"))}`; }
             }
        }

        // groups 쿼리 생성
        if( this.user_group_role != null && this.user_group_role.length > 0 ) {
            const role_size : number = this.user_group_role.length;
            for( let i = 0; i < role_size; i++ ) {
                if ( this.user_group_role[i].group_id == null || this.user_group_role[i].group_id < 1 ) {
                    continue;
                }        
                
                // 그룹 달력이라면 해당 그룹만, 팀이라면 전부 제외
                if( this.scope == OWNER_TYPE.GROUP && this.scope_group_id != this.user_group_role[i].group_id ) { continue; }
                if( this.scope == OWNER_TYPE.TEAM ) { break; }

                query += `&groups=${Number(this.user_group_role[i].group_id)}`;
            }
        }

        // teams 쿼리 생성
        if( this.user_team_role != null && this.user_team_role.length > 0 ) {
            const role_size : number = this.user_team_role.length;
            for( let i = 0; i < role_size; i++ ) {
                if( this.user_team_role[i].team_id == null || this.user_team_role[i].team_id < 1 ){
                    continue;
                }

                // 그룹 달력이라면 해당 그룹의 팀, 팀이라면 해당 팀만
                if( this.scope == OWNER_TYPE.GROUP && this.scope_group_id != this.user_team_role[i].group_id ) { continue; }
                if( this.scope == OWNER_TYPE.TEAM  && this.scope_team_id  != this.user_team_role[i].team_id  ) { continue; }
                
                query += `&teams=${Number(this.user_team_role[i].team_id)}`;
            }
        }

        // 병원 예약 - 일반 달력인 경우
        if( this.hodu_d_group_data.length > 0 && this.scope == OWNER_TYPE.PERSONAL ) {
            const hopital_count : number = this.hodu_d_group_data.length;
            for( let i = 0; i < hopital_count; i++ ) {
                const doctor_count : number = this.hodu_d_group_data[i].teams.length;
                for( let j = 0; j < doctor_count; j++ ) {
                    const doctor_key : string = `${this.hodu_d_group_data[i].teams[j].biz_id}___${this.hodu_d_group_data[i].teams[j].department_code}___${this.hodu_d_group_data[i].teams[j].doctor_code}`; 

                    // 필터에 들어 있는 경우 건너 뜀
                    if( this.schedule_search_config.hodu_d_filter.indexOf(doctor_key) > -1 ) {
                        continue;
                    }

                    query += `&doctors=${doctor_key}`;
                }
            }
        }

        // 병원 예약 - 호두 D 달력인 경우
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD && this.scope_group_team_option.biz_id != null && this.scope_group_team_option.biz_id.length > 0 ) {
            query += `&biz_type=${this.scope_group_team_option.biz_type}&biz_id=${this.scope_group_team_option.biz_id}`;

            const group_appointment_filter_count : number = this.schedule_search_config.group_appointment_filter.length;
            for( const doctor_key of this.schedule_search_config.group_appointment_filter ) {
                query += `&doctors=${doctor_key}`;
            }
        }

        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar("disable", false);
        await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}/web/filter${query}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                await this.hodu_show_indicator(); 

                // null 체크
                if( response.data.data.events == null ) {
                    return;
                }

                await this.makeEventsToRenderEvents(response);

                // 반복일정으로 인해서 나오는 기간 바깥의 일정을 없앤다 (시작일, 종료일 중 하나라도 세팅 되어 있다면)
                if( this.event_search_option.start_date != null || this.event_search_option.end_date != null ) {
                    const render_count : number = this.render_events.length;
                    for( let i = (render_count - 1); i >= 0; i-- ) {
                        const render_date_string : string = `${this.render_events[i].year_and_month}.01`.replace(/\./g, "-");
                        const render_first_date : Date = new Date(moment(render_date_string).format());
                        const render_last_date : Date = new Date(render_first_date);
                        render_last_date.setMonth(render_first_date.getMonth() + 1);
                        render_last_date.setDate(0);

                        const start_date : Date = this.event_search_option.end_date != null ? new Date(moment(this.event_search_option.start_date).format())
                                                                                            : new Date(moment('1900-01-01 00:00:00').format());
                        const end_date : Date = this.event_search_option.end_date != null ? new Date(moment(this.event_search_option.end_date).format())
                                                                                          : new Date(moment('2050-12-31 23:59:59').format());

                        // 해당 달의 마지막 날이 시작일 보다 작을때는 자름
                        if( this.event_search_option.start_date != null && render_last_date.getTime() < new Date(moment(this.event_search_option.start_date).format()).getTime() ) {
                            this.render_events.splice(i, 1);
                            continue;
                        }

                        // 해당 달의 첫 날(render_date)이 종료일 보다 클때는 자름 
                        if( this.event_search_option.end_date != null && render_first_date.getTime() > end_date.getTime() ) {
                            this.render_events.splice(i, 1);
                            continue;
                        }

                        // 해당 월이 문제가 없다면 내부 일정의 시작일 ~ 종료일을 따져서 자른다 (연속일정은 그대로 내버려두고 반복일정만!)
                        const event_count : number = this.render_events[i].events.length;
                        for( let j = (event_count - 1); j >= 0; j-- ) {
                            const event_fragment : t_event = this.render_events[i].events[j];

                            // 반복일정이 아니라면 문제 없음
                            if( event_fragment.event_data.schedule_date.rrule == null || event_fragment.event_data.schedule_date.rrule.length < 1  ) {
                                continue;
                            }

                            // 반복일정의 시작일 ~ 종료일 어느쪽이든 걸치기만 하면 OK, 어느쪽에도 걸치지 못하면 자름
                            const fragment_start : Date = new Date(moment(event_fragment.event_data.schedule_date.start).format());
                            fragment_start.setHours(0);
                            fragment_start.setMinutes(0);
                            fragment_start.setSeconds(0);
                            fragment_start.setMilliseconds(0);

                            const fragment_end : Date = new Date(moment(event_fragment.event_data.schedule_date.end).format());
                            fragment_end.setHours(0);
                            fragment_end.setMinutes(0);
                            fragment_end.setSeconds(0);
                            fragment_end.setMilliseconds(0);

                            // 검색 시작일이 일정 종료 날짜 보다 큰 경우, 검색 종료일이 일정 시작 날짜보다 작은 경우 자른다
                            if( fragment_end.getTime() < start_date.getTime() || fragment_start.getTime() > end_date.getTime() ) {
                                this.render_events[i].events.splice(j, 1);
                            }

                        }

                        // 전부 처리 된 이후에 남은 일정이 0개라면 지운다
                        if( this.render_events[i].events.length < 1 ) { this.render_events.splice(i, 1); }

                    }
                }

                this.searchOptionVisible = false;
                this.$nextTick(() => { setTimeout(() => { if( this.is_first_api_call_complete == false ) { this.compareToday(); } }, 500); });
            })
            .catch((e) => { this.hodu_error_process(e, true, false); })
            .finally(async() => {
                if( this.render_events == null || this.render_events.length < 1 ) { this.events.splice(0, this.events.length); }
                this.is_api_call = false;
                await this.hodu_hide_indicator();

                // @ts-ignore
                $('#eventSearchDiv').mCustomScrollbar("update");
            });
    }

    /**
     * 오늘 날짜에서 가장 가까운 일정을 구한 뒤 스크롤
     */
    async compareToday() : Promise<void> {
        
        try {
            this.hodu_show_indicator();

            const today : Date = new Date();
            let search_array : any[] = JSON.parse(JSON.stringify(this.render_events));

            // 첫 중앙 값 기준으로 검색
            while( search_array.length > 2 ) {
                
                // 중앙 값 구하기
                const center_index : number = parseInt(String(search_array.length / 2));
                const center_render_event : any = search_array[center_index];
                const center_year_and_month_date : Date = new Date(center_render_event.year_and_month.replace(/\./g, "-"));


                // 중앙 값의 년월이 같은 경우
                if( center_year_and_month_date.getFullYear() == today.getFullYear() && 
                    center_year_and_month_date.getMonth() == today.getMonth() ) {
                    
                    // 해당 월의 일정만을 비교용으로 사용
                    search_array = [center_render_event];
                } 
                
                // 중앙 값이 년월이 다른 경우
                else {
                    // 오늘보다 중앙 값이 더 작다면 - 중앙 값과 중앙 값 이후의 데이터 사용
                    if( today.getTime() > center_year_and_month_date.getTime() ) {
                        const search_array_temp : any[] = JSON.parse(JSON.stringify(search_array));
                        search_array.splice(0, search_array.length);

                        const search_array_temp_length : number = search_array_temp.length;
                        for( let i = center_index; i < search_array_temp_length; i++) {
                            search_array.push(JSON.parse(JSON.stringify(search_array_temp[i])));
                        }

                        // search_array = search_array.slice(center_index, search_array.length);
                    }

                    // 오늘보다 중앙 값이 더 크다면 - 중앙 값과 중앙 값 이전의 데이터 사용
                    else {
                        const search_array_temp : any[] = JSON.parse(JSON.stringify(search_array));
                        search_array.splice(0, search_array.length);
                        
                        for( let i = 0; i <= center_index; i++) {
                            search_array.push(JSON.parse(JSON.stringify(search_array_temp[i])));
                        }

                        // search_array = search_array.slice(0, center_index + 1);
                    }
                }

                // 2개만 남았을때 둘 중 하나가 같은 년월이라면 해당 월의 일정데이터만 사용 
                if( search_array.length == 2 ) {
                    for( let search_obj of search_array ) {
                        
                        const search_obj_year_and_month_date : Date = new Date(search_obj.year_and_month.replace(/\./g, "-"));

                        // 년월이 같은 경우
                        if( search_obj_year_and_month_date.getFullYear() == today.getFullYear() && 
                            search_obj_year_and_month_date.getMonth() == today.getMonth() ) {
                            
                            // 해당 월의 일정만을 비교용으로 사용
                            search_array = [search_obj];
                            break;
                        }
                    }
                }
                    
            }
            
            // render_event의 일정 중 에서 가장 가까운 일정 하나를 구함
            if( search_array.length == 1 ) {
                
                const render_event : any = search_array[0];
                await this.scrollToClosedEventByRenderEvent(render_event, today);

                if( this.is_paging_end == true ) { this.is_first_api_call_complete = true; }
            }

            else if ( search_array.length == 2 ) {
                const first_year_and_month_date : Date = new Date(search_array[0].year_and_month.replace(/\./g, "-"));
                const second_year_and_month_date : Date = new Date(search_array[1].year_and_month.replace(/\./g, "-"));

                // 오늘 이전의 날짜 밖에 없는 경우 -> 가장 마지막 일정까지 스크롤됨
                if( second_year_and_month_date.getTime() < today.getTime() ) {
                    const render_event : any = search_array[1];
                    await this.scrollToClosedEventByRenderEvent(render_event, today);

                    if( this.is_paging_end == true ) { this.is_first_api_call_complete = true; }
                }

                // 오늘 이후의 날짜 밖에 없는 경우 -> 가장 처음 일정까지 스크롤
                else if( first_year_and_month_date.getTime() > today.getTime() ) {
                    const render_event : any = search_array[0];
                    await this.scrollToClosedEventByRenderEvent(render_event, today);

                    this.is_first_api_call_complete = true;
                }

                // 두 아이템의 사이에 오늘이 있는 경우 -> 두 아이템의 일정을 전부 비교 후 가장 가까운 일정까지 스크롤
                else {
                    await this.scrollToClosedEventByRenderEvents(search_array, today);
                    this.is_first_api_call_complete = true;
                } 
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        
        } finally {
            this.hodu_hide_indicator();
        }
    }

    /**
     * 해당 월의 가장 가까운 일정으로 스크롤
     */
    async scrollToClosedEventByRenderEvent(render_event : any, today : Date) : Promise<void> {
        let closed_date_diff = Number.MAX_SAFE_INTEGER; // 최대치
        let closed_event : t_event | null = null; 
        for( let event of render_event.events ) {
            const date_diff : number = this.getDateDiff((event as t_event).event_data.schedule_date.start, today);
            if( closed_date_diff > date_diff ) { 
                closed_date_diff = date_diff;
                closed_event = JSON.parse(JSON.stringify(event)); 
            }
        }

        // 오늘 날짜와 가장 비슷한 날의 제일 위쪽 일정으로 스크롤 하면 됨
        if( closed_event == null ) { return; }

        const target_html : JQuery<HTMLElement> = $(`#${closed_event.event_id}-${new Date(closed_event.event_data.schedule_date.start).getTime()}`);

        await this.$nextTick();

        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar("scrollTo", target_html.position().top, {
            timeout:0,
            scrollInertia:0,
        });
    }

    /**
     * 여러 월 중에 가장 가까운 일정으로 스크롤
     */
    async scrollToClosedEventByRenderEvents(render_events : any[], today : Date) : Promise<void> {
        let closed_date_diff = Number.MAX_SAFE_INTEGER; // 최대치
        let closed_event : t_event | null = null; 
        for( let render_event of render_events ) {
            for( let event of render_event.events ) {
                const date_diff : number = this.getDateDiff((event as t_event).event_data.schedule_date.start, today);
                if( closed_date_diff > date_diff ) { 
                    closed_date_diff = date_diff;
                    closed_event = JSON.parse(JSON.stringify(event)); 
                }
            }
        }

        // 오늘 날짜와 가장 비슷한 날의 제일 위쪽 일정으로 스크롤 하면 됨
        if( closed_event == null ) { return; }

        const target_html : JQuery<HTMLElement> = $(`#${closed_event.event_id}-${new Date(closed_event.event_data.schedule_date.start).getTime()}`);

        await this.$nextTick();

        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar("scrollTo", target_html.position().top, {
            timeout:0,
            scrollInertia:0,
        });
    }

    /**
     * 년, 월 string 받아오기
     */
    getYearAndMonth(start_date : Date) : string {
        const year : string = `${start_date.getFullYear()}`;
        const month : string = `0${start_date.getMonth() + 1}`.slice(-2); 
        return `${year}.${month}`;
    }

    /**
     * DTSTART 제작 : 일정 시작일
     */
    formatDateForRruleDTSTART(date : Date) : string {
        return `${dateFormat(date, "UTC:yyyymmdd")}T${dateFormat(date, "UTC:HHMMss")}Z`;
    }

    /**
     * UNTIL 제작 : 해당 날짜 -1일까지 뿌려줌
     */
    formatDateForRruleUNTIL(date : Date) : string {
        date.setDate(date.getDate() + 1); // 월의 마지막 일 이후로 넘어가면 자동으로 다음달로 계산해줌
        return `${dateFormat(date, "UTC:yyyymmdd")}`;
    }

    /**
     * 일자 받기
     */
    getDate(start_date : Date) : string {
        return `0${start_date.getDate()}`.slice(-2);
    }

    /**
     * 요일 반환
     */
    getDayOfWeek(start_date : Date) : string {
        switch( start_date.getDay() ) {
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";
        
            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "";
        } 
    }

    /**
     * 오늘인지 여부 반환
     */
    isToday(start_date : Date) : boolean {
        return (this.getDateDiff(new Date(), start_date) == 0);
    }

    /**
     * 다음 이벤트랑 같은 날의 일정인지 반환
     */
    isSameDay(events : t_event[], index : number) : boolean {
        try {
            if( events.length - 1 == index ) { return false; }

            const current_event_start : Date = new Date(events[index].event_data.schedule_date.start);
            const next_event_start : Date = new Date(events[index + 1].event_data.schedule_date.start);

            return current_event_start.getDate() == next_event_start.getDate();

        } catch(e) {
            return false;
        }
    }

    /**
     * 해당 일의 첫번째 날짜인지 반환
     */
    isFirstDayEvent(events : t_event[], index : number) : boolean {
        try {
            if( index == 0 ) { return true; }

            const current_event_start : Date = new Date(events[index].event_data.schedule_date.start);
            const prev_event_start : Date = new Date(events[index - 1].event_data.schedule_date.start);

            return current_event_start.getDate() != prev_event_start.getDate();

        } catch(e) {
            return false;
        }
    }

    /**
     * 과거 데이터인지 여부 반환
     */
    isPastDate(date : Date) : boolean {
        return date.getTime() < new Date().getTime()
    }

    /**
     * AM PM 시간 자리에 들어갈 텍스트 구하기
     */
    async getAmPmTime(start_date : Date, end_date : Date, is_all_day : boolean, lunar_yn : boolean) : Promise<string> {

        // 일반 일정
        if( is_all_day == false && lunar_yn == false ) {
            const hour : number = start_date.getHours() > 12 ? start_date.getHours() - 12 
                                                    : start_date.getHours() == 0 ? 12 : start_date.getHours();

            const hour_string : string = `0${hour}`.slice(-2);
            const min_string  : string = `0${start_date.getMinutes()}`.slice(-2);
            const am_pm_string : string = start_date.getHours() < 12 ? '오전' : '오후';
            
            let end_date_string : string = "";
            if( this.getDateDiff(start_date, end_date) > 0 ) {
                const end_year : string = `${end_date.getFullYear()}`;
                const end_month : string = `0${end_date.getMonth() + 1}`.slice(-2);
                const end_day : string = `0${end_date.getDate()}`.slice(-2);

                const end_hour : number = end_date.getHours() > 12 ? end_date.getHours() - 12 
                                                    : end_date.getHours() == 0 ? 12 : end_date.getHours();

                const end_hour_string : string = `0${end_hour}`.slice(-2);
                const end_min_string  : string = `0${end_date.getMinutes()}`.slice(-2);
                const end_am_pm_string : string = end_date.getHours() < 12 ? '오전' : '오후';
                const end_day_of_week : string = this.getDayOfWeekByDate(end_date);

                end_date_string = `~ ${end_year.substring(end_year.length - 2)}.${end_month}.${end_day} ${end_day_of_week} <span class="to_am_pm ${end_am_pm_string == '오전' ? 'am' : 'pm' }" style="margin-left : 2px;">${end_am_pm_string}</span> ${end_hour_string}:${end_min_string} 까지`;
            }

            return `<span class="from_am_pm ${am_pm_string == '오전' ? "am" : "pm" }" style="margin-left : 2px;">${am_pm_string}</span> ${hour_string}:${min_string} ${end_date_string}`;
        }

        // 종일 일정
        if( is_all_day == true && lunar_yn == false ) {
            return `종일`;
        }

        // 음력 일정
        if( lunar_yn == true ) {
            const lunar_date : string = await this.hodu_solar_to_lunar(start_date);
            
            // 음력 문자열 포맷 결정 되어야함
            const lunar_arr : string[] = lunar_date.split('-');

            return `음력 ${ lunar_arr[1] }월 ${ lunar_arr[2] }일 `;
        }

        // 에러
        return '';
    }

    /**
     * 작성자 이름 반환
     */
    getOwnerName(owner_id : number, owner_name : string) : string {
        if( owner_id == this.user_id ) {
            return `나(${this.user_name})`;
        }

        return owner_name;
    }

    /**
     * 반복 여부 반환
     */
    isRepeat(rrule : string) : boolean {
        return rrule != null && rrule.length > 0;
    }

    /**
     * 알람 존재 여부 반환
     */
    hasAlarm(alarm : t_event_alarm[]) : boolean {
        return alarm != null && alarm.length > 0;
    }

    /**
     * 장소 존재 여부 반환
     */
    hasLocation(location : t_event_location[]) : boolean {
        return location != null && location.length > 0;
    }

    /**
     * 전화번호 존재 여부 반환 (현재 0번째 인덱스만 사용 중, 나중에 배열로 바뀐다면 바꿔야함)
     */
    hasContact(contact : t_event_contact[]) : boolean {
        return contact != null && contact.length > 0 && contact[0].tel != null && contact[0].tel.length > 0;
    }

    /**
     * 노트 존재 여부 반환
     */
    hasNote(note : string) : boolean {
        return note != null && note.length > 0;
    }

    /**
     * 메모 존재 여부 반환
     */
    hasMemo(memo : string) : boolean {
        return memo != null && memo.length > 0;
    }

    /**
     * 사진 존재 여부 반환
     */
    hasImage(attachment : any) : boolean {
        return attachment != null && attachment.imgs != null && attachment.imgs.length > 0;
    }

    /**
     * 파일 존재 여부 반환
     */
    hasFile(attachment : any) : boolean {
        return attachment != null && attachment.files != null && attachment.files.length > 0;
    }

    /**
     * 투표 존재여부 반환
     */
    hasVote(vote : t_event_vote[]) : boolean {
        return vote != null && vote.length > 0;        
    }

    /**
     * 댓글 존재여부 반환
     */
    hasReply(exist_reply : boolean) {
        return exist_reply != null && exist_reply == true;
    }

    /**
     * 공유 일정인지 여부 반환
     */
    isShared(subscribe_users : number[], subscribe_groups : number[], subscribe_teams : number[]) : boolean {
        return ( subscribe_users  != null && subscribe_users.length > 0 ) ||
               ( subscribe_groups != null && subscribe_groups.length > 0 ) || 
               ( subscribe_teams  != null && subscribe_teams.length > 0 );
    }
    
    /**
     * 사용자의 참석여부 상태 텍스트 반환
     */
    getAttendText(event : t_event) : string {
        let attend_text : string = "참석 미정";

        switch( event.user_attend ) {
            case "Y": return "참석";
            case "N": return "참석 불가";
            case "-": return "참석 미정";
        }

        return attend_text;
    }

    /**
     * 투표의 진행 상태 텍스트 반환
     */
    getVoteText(event : t_event, return_class : boolean = false) : string {
        let vote_text : string = "";

        if( event.event_data.vote != null && event.event_data.vote.length > 0 ) {

            // 투표 종료일이 지난 경우
            if( new Date(event.event_data.vote[0].end_date).getTime() <= new Date().getTime() ) {
                vote_text = "투표/설문 종료";
                if( return_class ) { vote_text = "x_red"; }
            }

            // 투표 종료일이 자나지 않은 경우는 투표가 전부 조기 종료 됐는지 체크
            else {
                let is_vote_end_all = true;
                for( let vote of event.event_data.vote ) {
                    is_vote_end_all = vote.is_end && is_vote_end_all
                }

                vote_text = is_vote_end_all ? "투표/설문 종료" : "투표/설문 진행중"
                if( return_class ) { vote_text = is_vote_end_all ? "x_red" : "x_blue" }
            }

        }

        return vote_text;
    }

    /**
     * 참석, 투표 진행 상황 텍스트 반환
     */
    // getAttendVoteText(attend : boolean, user_attend : string, vote : t_event_vote[]) : string {

    //     // 투표가 있다면
    //     if( vote != null && vote.length > 0 ) {
            
    //         const target_date : Date = new Date();
    //         let end_of_vote : boolean = true;

    //         const event_vote_length : number = vote.length;
    //         for( let i = 0; i < event_vote_length; i++ ) {
    //             end_of_vote = end_of_vote && ( vote[i].is_end == true || new Date(vote[i].end_date).getTime() <= target_date.getTime() );
    //         }

    //         return `투표 ${ end_of_vote ? "종료" : "진행 중"}`;
    //     }

    //     if( attend == true ) {
    //         switch( user_attend ) {
    //             case 'Y':
    //                 return '참석';

    //             case 'N':
    //                 return '불참';

    //             default:
    //                 return "미정"; 
    //         }
    //     }

    //     return '';
    // }

    /**
     * 참석, 투표 진행 상황 텍스트 반환
     */
    getAttendVoteClass(attend : boolean, user_attend : string, vote : t_event_vote[]) : string {

        // 투표가 있다면
        if( vote != null && vote.length > 0 ) {
            
            const target_date : Date = new Date();
            let end_of_vote : boolean = true;

            const event_vote_length : number = vote.length;
            for( let i = 0; i < event_vote_length; i++ ) {
                end_of_vote = end_of_vote && ( vote[i].is_end == true || new Date(vote[i].end_date).getTime() <= target_date.getTime() );
            }

            return end_of_vote == true ? 'endVote' : 'ingVote';
        }

        if( attend == true ) {
            switch( user_attend ) {
                case 'Y':
                    return 'attendY';

                case 'N':
                    return 'attendN';

                default:
                    return "undef"; 
            }
        }

        return '';
    }

    /**
     * 스크롤 리스너 - 페이징 처리
     */
    @Throttle(500)
    async whileScrolling() : Promise<void> {
        // console.log(`${$('.schListDiv').height()}, ${$('#eventSearchDiv').find('.mCSB_container').css('top')}, ${window.innerHeight}`);

        try {
            const schedule_list_height : number | undefined = $('.schListDiv').height();
            let scroll_container_top : number = 0;
            let scroll_container_top_string : string = "";
            
            try {
                scroll_container_top_string = $('#eventSearchDiv').find('.mCSB_container').css('top');

                if(scroll_container_top_string) {
                    scroll_container_top_string = scroll_container_top_string.replace('px', '');
                    scroll_container_top = Math.abs(Number(scroll_container_top_string));
                } else {
                    scroll_container_top = 0;
                }
            } catch(e) {
                scroll_container_top = 0;
            }
            
            // console.log(`schedule_list_height : ${schedule_list_height}`);
            // console.log(`scroll_container_top : ${scroll_container_top}`);
            // console.log(`window.innerHeight : ${window.innerHeight}`);
            // console.log(`result : ${( ( schedule_list_height == null ? 0 : schedule_list_height ) - scroll_container_top - window.innerHeight )}`);

            // if( this.is_first_api_call_complete == false ) return;

            if( ( ( schedule_list_height == null ? 0 : schedule_list_height ) - scroll_container_top - window.innerHeight ) < 250 ) {
                if( this.is_event_filter_search == false ) { await this.searchEventForWeb(); }
                else { await this.searchEventForWebByFilter(); }
            }
        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
        
    }

    /**
     * 일정 보기
     */
    async viewEvent(eventAtomic : t_event) : Promise<void> {
        
        if( eventAtomic.event_data.approval && eventAtomic.event_data.approval.approval_uid && eventAtomic.event_data.approval.approval_uid.length > 0 ) {
            
            const approver : number[] = Array.from(eventAtomic.event_data.approval.approver ? eventAtomic.event_data.approval.approver : [], x => x.user_id);
            const receiver : number[] = Array.from(eventAtomic.event_data.approval.receive_reference ? eventAtomic.event_data.approval.receive_reference : [], x => x.user_id);
            
            // 작성자, 결재자, 수신참조만 기안서 화면으로 이동
            if( eventAtomic.event_data.event_owner_id == this.user_id || approver.indexOf(this.user_id) > -1 || receiver.indexOf(this.user_id) > -1 ) {
                this.hodu_router_push(`GROUP/${eventAtomic.group_id}/approval/${eventAtomic.event_data.approval.approval_uid}`);
            }

            return;
        }

        if( eventAtomic.event_sub_type == "APPOINTMENT" ) {
            if( !this.doSetAppointmentDetailInfo ) { return; }
            this.doSetAppointmentDetailInfo({
                event : eventAtomic,
                is_patient : true,
            })
            this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${eventAtomic.event_id}`);
            return;
        }

        // ONLY 연속 일정인 경우
        if( eventAtomic.original_start != null && this.getDateDiff(eventAtomic.original_start, eventAtomic.event_data.schedule_date.end) > 0 &&
            (eventAtomic.event_data.schedule_date.rrule == null || eventAtomic.event_data.schedule_date.rrule.length < 1) ) {
            eventAtomic.event_data.schedule_date.start = eventAtomic.original_start;
        }

        // 양력 반복 일정인 경우
        if( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.lunar_yn == false && 
            eventAtomic.original_start != null && eventAtomic.original_end != null ) {

            // 해당 반복일정의 첫 일정인지 구하기
            const dtStart : Date = new Date(eventAtomic.event_data.schedule_date.start);
            
            // DTSTART랑 UNTIL이 이미 들어있다면 제거후 재등록
            let rrule_string : string = eventAtomic.event_data.schedule_date.rrule;
            if( rrule_string.indexOf(';UNTIL') > -1 ) {
                rrule_string = rrule_string.substring(0, rrule_string.indexOf(';UNTIL'));
            }
            if( rrule_string.indexOf('FREQ') > - 1 ) {
                rrule_string = rrule_string.substring(rrule_string.indexOf('FREQ'));
            }
            eventAtomic.event_data.schedule_date.rrule = rrule_string;

            const rrule : RRule | RRuleSet = rrulestr(`DTSTART:${this.formatDateForRruleDTSTART(dtStart)}\nRRULE:${eventAtomic.event_data.schedule_date.rrule};UNTIL=${this.formatDateForRruleUNTIL(new Date(eventAtomic.event_data.schedule_date.recurrence_end))}`);

            this.doSetIsFirstRepeatEvent( new Date(eventAtomic.original_start).getTime() == new Date(eventAtomic.event_data.schedule_date.start).getTime() && 
                                          ( rrule.all()[0].getTime() == new Date(eventAtomic.event_data.schedule_date.start).getTime() ) );

            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : new Date(eventAtomic.original_start),
                original_end : new Date(eventAtomic.original_end)
            })
            
            // 연속&반복 일정이라면
            const diff : number = this.getDateDiff(eventAtomic.original_start, eventAtomic.original_end);
            if( diff > 0 ) {
                const end : Date =  new Date(eventAtomic.event_data.schedule_date.end);
                const start : Date =  new Date(eventAtomic.event_data.schedule_date.start);

                start.setDate(end.getDate() - diff);
                eventAtomic.event_data.schedule_date.start = start;
            }

        }
        
        // 음력 반복 일정
        else if ( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.lunar_yn == true &&
                  eventAtomic.original_start != null && eventAtomic.original_end != null ) {

            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : new Date(eventAtomic.original_start),
                original_end : new Date(eventAtomic.original_end)
            });
        }

        // 웹에서만 사용하는 칼럼 undefined로 변경
        eventAtomic.lunar_date_text    = undefined;
        eventAtomic.exist_reply        = undefined;
        eventAtomic.user_attend        = undefined;
        eventAtomic.work_status_info   = undefined;
        eventAtomic.work_template_info = undefined;
        eventAtomic.original_start     = undefined;
        eventAtomic.original_end       = undefined;

        // EventInfo에 이벤트 등록
        this.doSetEvent(eventAtomic);
        this.doSetEventCrudType(CRUD_TYPE.READ);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 일정 조회 페이지로 이동 
        this.hodu_router_push(`/event/${eventAtomic.event_id}`);
    }

    /**
     * 일정 검색 옵션이 바뀐 것을 감지
     */
    @Watch('schedule_search_config', { immediate : false, deep : true })
    @Debounce(100)
    onScheduleSearchConfigChanged(): void {
        this.doSelectScheduleList({"schedule_search_config" : this.schedule_search_config});
        this.newSearchEvent(true);
    }

    /**
     * 일정 검색 옵션 ON / OFF
     */
    searchOptionOnOff() : void {
        const vue = this;

        this.searchOptionVisible = !this.searchOptionVisible;

        if( this.searchOptionVisible ) { 

            if( this.event_search_option.start_date != null ) {
                const date_format_text : string = moment(this.event_search_option.start_date).format("YYYY.MM.DD");
                const week_of_day : string = this.getDayOfWeekByDate(this.event_search_option.start_date, "요일");
                const final_date_text : string = `${date_format_text} ${week_of_day}`;
                this.start_date_text = final_date_text;
            }

            if( this.event_search_option.end_date != null ) {
                const date_format_text : string = moment(this.event_search_option.end_date).format("YYYY.MM.DD");
                const week_of_day : string = this.getDayOfWeekByDate(this.event_search_option.end_date, "요일");
                const final_date_text : string = `${date_format_text} ${week_of_day}`;
                this.end_date_text = final_date_text;
            }

            setTimeout(() => { 
                const option : any = {
                    inline: false,
                    showOtherMonths: true,
                    selectOtherMonths: true,
                    dateFormat: 'yy-mm-dd',
                    monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                    yearSuffix : '.',
                    blankSpace : '',
                    changeYear : true,
                    yearRange  : '1970:2050',
                    onSelect: function (dateText, inst) {
                        const id : string | undefined = $(this).attr('id');

                        const selected_date : Date = new Date(dateText);
                        selected_date.setHours(0);
                        selected_date.setMinutes(0);
                        selected_date.setSeconds(0);
                        selected_date.setMilliseconds(0);

                        const date_format_text : string = moment(selected_date).format("YYYY.MM.DD");
                        const week_of_day : string = vue.getDayOfWeekByDate(selected_date, "요일");
                        
                        const final_date_text : string = `${date_format_text} ${week_of_day}`;

                        if( id == "search_sch_from" ) {
                            vue.start_date_text                = final_date_text;
                            vue.event_search_option.start_date = selected_date;

                            // end가 비어있지 않고 start보다 작다면 end를 start로 덮어씌운다
                            if( vue.event_search_option.end_date != null && new Date(vue.event_search_option.end_date).getTime() < selected_date.getTime() ) {
                                vue.end_date_text                = final_date_text;
                                vue.event_search_option.end_date = selected_date;
                            }

                        }

                        if( id == "search_sch_to" ) {
                            vue.end_date_text                = final_date_text;
                            vue.event_search_option.end_date = selected_date;

                            // start가 비어있지 않고 end보다 크다면 start를 end로 덮어씌운다
                            if( vue.event_search_option.start_date != null && new Date(vue.event_search_option.start_date).getTime() > selected_date.getTime() ) {
                                vue.start_date_text                = final_date_text;
                                vue.event_search_option.start_date = selected_date;
                            }
                        }
                    }
                };

                // @ts-ignore
                $('#search_sch_from').datepicker(option);

                // @ts-ignore
                $('#search_sch_to').datepicker(option);
            }, 10); 
        }
    }

    /**
     * 일정 검색 타입 변경
     */
    searchTypeChange(event : any) : void {
        this.event_search_option.search_event_type = event.target.value;

        const value : string = event.target.value;

        if( value != "WORK" ) {
            this.event_search_option.is_work_wait = false;
            this.event_search_option.is_work_start = false;
            this.event_search_option.is_work_end = false;
        }

        if( value == "WORK" ) {
            this.event_search_option.has_attend = false;
            this.event_search_option.has_vote   = false;
        }

    }

    /**
     * 파일 검색 허용 할 것인지 여부
     */
    checkFileEnabled(value : string) : boolean {
        
        // 특정 그룹, 팀이 선택 된 경우
        const reg : RegExp = new RegExp(/\d-\d/);
        if( reg.test(value) == true ) {
            let group_id : number = 0;
            let team_id : number = 0;

            try {
                const scope_ids : string[] = value.split("-");
                if( scope_ids[0] != null ) { group_id = Number(scope_ids[0]); }
                if( scope_ids[1] != null ) { team_id  = Number(scope_ids[1]); }

                const group_team_count : number = this.computedGroupAndTeam.length;
                for( let i = 0; i < group_team_count; i++ ) {
                    const group_team : any = this.computedGroupAndTeam[i];
                    if( group_team.group_id == group_id && group_team.team_id == team_id ) {
                        return group_team.limit_event_attachment > 0;
                    }
                }

            } catch(e) {
                this.hodu_error_process(e, false, false, false);
            }

            return false;
        }

        // 특정 그룹, 팀이 선택된게 아니라면 무조건 검색 허용
        return true;
    }

    /**
     * 해당 컬러가 검색 할 컬러에 포함되어있는지 여부 반환
     */
    isContainColor(color : string) : boolean {
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();
        return (this.event_search_option.event_color.indexOf(upper_color) > -1);
    }
    
    /**
     * 검색 컬러 변경
     */
    changeSearchColor(event : any, color : string) : void {
        const checked : boolean = event.target.checked;
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();

        const color_index : number = this.event_search_option.event_color.indexOf(upper_color);

        // 체크 하는 경우
        if( checked == true ) {
            if( color_index < 0 ) { this.event_search_option.event_color.push(upper_color); }
        }

        // 체크를 해제 하는 경우
        else {
            if( color_index > -1 ) { this.event_search_option.event_color.splice(color_index, 1); }
        }
    }

    /**
     * 모든 컬러가 선택 되었는지 여부
     */
    isCheckedAllColor() : boolean {
        return this.event_search_option.event_color.length >= 20;
    }

    /**
     * 작성자 영역을 보여줄것인지 (개인일정에서 본인이 작성한 일정은 보이지 않음)
     */
    isVisibleWriter(event : t_event) : boolean {
        if( event.event_data.event_owner_id == this.user_id ) {
            return ( event.group_id != null && event.group_id > 0 ) || (event.team_id != null && event.team_id > 0);
        }

        return true;
    }

    /**
     * 모든 색상 선택 체크 변경
     */
    changeCheckAllColor(event : any) : void {
        const checked : boolean = event.target.checked;
        this.event_search_option.event_color.splice(0, this.event_search_option.event_color.length);
        if( checked == true ) {
            for( let color of this.dc_color ) { this.event_search_option.event_color.push(color); }
            for( let color of this.lc_color ) { this.event_search_option.event_color.push(color); }
        }
    }

    /**
     * 검색 옵션 리셋
     */
    searchOptionReset() : void {
        this.start_date_text = null;
        this.end_date_text = null;

        const option : EventSearchOption = {
            search_event_type : "ALL",
            event_color : [],
            has_photo : false,
            has_file : false,
            has_chat : false,
            has_attend : false,
            has_vote : false,
            is_work_wait : false,
            is_work_start : false,
            is_work_end : false
        };

        if( this.doSetEventSearchOption != null ) { this.doSetEventSearchOption(option); }
    }

    /**
     * 업무 상태 텍스트 반환
     */
    getWorkStatusText(event : t_event) : string {
        if( !event.event_data.work ) { return ""; }

        switch( event.event_data.work.work_status_code ) {
            case "WAIT": return "대기";
            case "START": return "진행";
            case "END": return "완료";
            case "CANCEL": return "중단";
        }

        return "";
    }

    /**
     * 업무 타입인지 체크
     */
    isSubTypeWork(event : t_event) : boolean {

        if( event == null || event.event_sub_type == null || event.event_sub_type.length < 1 ) return false;

        const event_sub_type = event.event_sub_type;

        return this.isWork((event_sub_type as EVENT_SUB_TYPE)) == true || 
               this.isReport((event_sub_type as EVENT_SUB_TYPE)) == true ||
               this.isMeetingLog((event_sub_type as EVENT_SUB_TYPE)) == true;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar('destroy')
    	this.setScroll();
    }
    
}
</script>

<style scoped>
    .mainSchListDiv .noResult { position:static;background:#f1f3f5; border-radius:5px; margin-top:30px;font-size:14px;color: #999; font-weight:bold;max-width:900px; margin-left: 40px;}
    .title_box { height:61px; }
    .searchDiv { display:block !important; position: relative; }
    .schListDiv {  width:100%; font-size: 13px;}
    .schListHeader { background:url(../assets/images/contents/bg_whiteGradi.png) repeat-x;z-index:100; width:100%; max-width:900px; height:60px; line-height: 60px; }
    .schListHeader * { display: inline-block; }
    .schListHeader .total {float: right;font-size: 14px;}
    .mainSchList { padding-top: 30px;max-width: 100%; width:100%; }
    .yyyytitle { height:50px; line-height:50px; width:100%; opacity:0.5;border-bottom: 1px solid #f1f3f5;padding-bottom: 5px;margin-bottom: 20px; padding:0 30px;box-sizing: border-box;}
    .yyyytitle * { display: inline-block; font-weight:bold; font-size: 20px; }
    .yyyytitle .total { font-size:20px; font-weight:normal;width: 120px; text-align:right; }
    .schListUl > li {position:relative; transition:0.2s; width:100%;margin: 0px 0 0;padding:0 40px 15px;box-sizing:border-box;height:auto;}
    .sameDay { margin-bottom:40px !important; padding-bottom: 30px !important;  border-bottom: 6px solid #f1f3f5;}
    .mainSchList > li:hover .yyyytitle { opacity:1}
    .mainSchListDiv { padding:0 0 40px 0;width:100%; box-sizing:border-box; }
    li.yyyy:hover .yyyytitle { border-color:#477fff; }
    p.yyyyH4 { width: 800px; }


    .btmContainer { position: relative; }
    .schListUl > li > div { display:inline-block; width:900px; }
    .schListUl > li .contDiv {float: right;width: 95.5%;}
    .schListUl .colorDiv { text-align: center; width:4%; float: left; line-height: 20px; height: 20px; }
    /* .schListUl .timeDiv .counting {font-size:12px;font-weight:bold; position: absolute;top: 83px;background:#f1f3f5;left: 50%;width: 60px;line-height: 25px;height: 25px;border-radius: 5px;margin-left: -30px;color:#969fb3; }
    .schListUl .timeDiv .counting.today { color:#477fff; }
    .schListUl .timeDiv p.day {height: 25px;line-height: 25px;} */
    .schListUl .icons { z-index:5; position: absolute;right:0; top:29px; }
    .schListUl .icons li  { position: relative; margin-left:5px; }
    .schListUl .icons li:hover .descript { display:block; }
    .schListUl .icons li .icon { display:inline-block; border-radius:50%;  width:25px; height:25px;background-size: 20px;background-position: center;background-repeat: no-repeat; }
    .schListUl .icons li .descript {display:none;position:absolute; bottom: -40px;background: rgba(46, 56, 86,0.9); border-radius:5px; height:30px; line-height: 30px; text-align: center; width:70px; left:50%;margin-left:-35px; font-weight:bold; font-size:12px;color:#fff; }
    .schListUl .date { font-weight:bold; font-size:18px; height: 30px;line-height: 30px;}
    /* .schListUl .cl { position:absolute; top:31px; left:12%; font-size:0;background:#ff6060;display:inline-block; width:8px; height:8px; border-radius:50%; } */
    .schListUl .cl { top: 31px; left: 12%; font-size: 0; background: #ff6060; display: inline-block; width: 5px; height: 22px; border-radius: 10px; }
    .schListUl .title { height:30px;line-height:30px;display:inline-block; box-sizing:border-box; font-size:15px; font-weight: bold;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; }
    .schListUl .icons li { display:inline-block; }
    .titleSpan {opacity:0.7; display:inline-block; width:68px;}
    .contDiv  .no .cont { opacity:0.5; }
    .schListUl .fromTo { font-weight:bold; height:25px; line-height: 25px;font-size:13px; opacity:0.5; margin-bottom: 4px; }
    .schListUl .contDiv .memo { height:30px; line-height: 30px;padding-right:20px; width:100%; box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap; }
    .postBy, .grpTeam { display: inline-block;width:40%; float:left;padding-right:20px; height:25px;line-height:25px; box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;}
    .stsBtn { position:absolute;top:-10px; right:40px; display:inline-block; height:35px; line-height: 35px; border-radius: 20px;width:90px; background:#477fff; font-weight:bold; color:#fff;text-align: center; font-size: 13px; }
    .stsBtn.endVote { color :  #eaedef; background:#92969e; }
    .stsBtn.ingVote, .stsBtn.attendY { color :  #ffffff; background:#477fff; }
    .stsBtn.attendN	{ color :  #ffffff; background:#ff6363; }
    .stsBtn.undef { color :  #ffffff; background:#ffc72f; }
    .schListUl .seeDetail { z-index:1;max-width: 900px; position: absolute;top: 0;opacity:0;left: 40px; font-size: 0; width:100%; height:100%; display: block; transition:0.1s; }
    .schListDiv .seeMoreDiv { text-align: center; width:100%;margin:30px 0 50px; }
    .schListDiv .seeMore {text-decoration:none;text-align: center; height:40px; line-height:40px; width:100px; border-radius:20px; font-weight:bold; background:#fff; transition:0.2s; border:1px solid #f1f3f5; color:#477fff; margin:0 auto; display:inline-block; font-size:14px;}
    .schListDiv .seeMore:hover { background:#477fff; color:#fff; border:1px solid #477fff; box-shadow: 0 5px 5px rgb(71, 127, 255, 0.2);  }

    .icons .iconList .repeat .icon { background-image:url(../assets/images/contents/ic_repeat_bk.png);  }
    .icons .iconList .alarm .icon { background-image:url(../assets/images/contents/ic_alarm_bk.png);  }
    .icons .iconList .place .icon { background-image:url(../assets/images/contents/ic_place_bk.png);  }
    .icons .iconList .tel .icon { background-image:url(../assets/images/contents/ic_tel_bk.png);  }
    .icons .iconList .note .icon { background-image:url(../assets/images/contents/ic_note_bk.png);  }
    .icons .iconList .memo .icon { background-image:url(../assets/images/contents/ic_memo_bk.png);  }
    .icons .iconList .vote .icon { background-image:url(../assets/images/contents/ic_vote_bk.png);  }
    .icons .iconList .attnd .icon { background-image:url(../assets/images/contents/ic_attend_bk.png);  }
    .icons .iconList .file .icon { background-image:url(../assets/images/contents/ic_file_bk.png);  }
    .icons .iconList .pic .icon { background-image:url(../assets/images/contents/ic_img_bk.png);  }
    .icons .iconList .share .icon { background-image:url(../assets/images/contents/ic_share_bk.png);  }
    .icons .iconList .invite .icon { background-image:url(../assets/images/contents/ic_invite_bk.png);  }
    .icons .iconList .cmmnt .icon { background-image:url(../assets/images/contents/ic_cmmnt_bk.png);  }
    


    /* 검색 옵션  */

    .schOption.on { display: block; max-width: 950px; }
    .schOption {display: none;width: 100%;background:#f9fafb;max-width: 100%; height: 507px;position: absolute;top: 63px;left: 0;z-index: 1000;box-shadow: 16px 10px 17px rgba(0,0,0,0.15);    max-width: 950px;border-radius: 0 0 15px 15px;overflow: hidden;}
    .schOption.noWork { height:446px; }
    .schOption .schGrp input:hover { background: #fff; }
    .sch_options {max-width: 950px; border-right:1px solid #e7e9ea;background:#fff; }
    .sch_options > ul {  box-sizing:border-box; padding: 15px 30px;  }
    .sch_options > ul > li { position: relative; line-height: 55px; min-height: 55px; }
    .sch_options dl dt { position: absolute;left: 0;top: 0; display: inline-block; width: 100px; font-size: 13px; opacity: 0.8;font-weight: bold; }
    .sch_options > ul > li:hover dt { opacity: 1 }
    .sch_options dl dt label { width: 100%; display: inline-block; }
    .sch_options dl dd { display: inline-block; width: 100%; padding-left: 100px; box-sizing:border-box;}
    .sch_options dl dd div, .sch_options dl dd div p { display: inline-block; width: auto }
    .sch_options dl dd label { display: inline-block; }
    .sch_options dl dd input[type="text"], .sch_options dl dd select { padding-left:0 !important; border-bottom:1px solid #e7e9ea; width: 100% !important;line-height: 55px; height: 55px; box-sizing:border-box; }
    .sch_options dl dd input[type="text"]:hover, .sch_options dl dd select:hover  { border-bottom:1px solid #e3e7ed; background:#fff; }
    .sch_options dl dd input[type="text"]:focus, .sch_options dl dd select:focus  { border-bottom:1px solid #477fff; }
    .sch_options dl dd input[type="text"]#sch_title {font-size:17px }
    .sch_options dl dd input.wd45 { width: 45% !important; height: 54px; line-height: 54px; margin-top: 1px; }
    .sch_options dl dd input.wd45:hover { }
    .sch_options .schDateList dl dd input[type='button'] { text-align : left; color: #acb2bf; font-weight: bold; padding-left:0; border-bottom:1px solid #e3e7ed;}
    .sch_options .schDateList dl dd input[type='button']:focus{ border-color:#477fff; }
    .sch_options .schDateList dl dd input[type='button']:hover { background:#fff; color:#434343 }
    .sch_options .schDateList dl dd input[type='button'].on { color: #434343; }
    .sch_options select {-moz-user-select: none;-khtml-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;-webkit-appearance: none;outline: none;box-shadow: none !important; background: #fff; border-radius:0}
    .sch_options select:hover { cursor: pointer; }
    .sch_options dl dd input, .sch_options dl dd select, .sch_options dl dd { font-size: 13px; font-weight: bold; }
    .sch_options .ware_seper { text-align: center;display: inline-block; width: 10%;float: right; }
    .filterEndDate { float: right; margin-top: 2px !important; }
    :-webkit-autofill { color: #fff !important; }
    .ware_etc_list .checkOptions { border-bottom: 1px solid #f1f3f5; width: 100% }
    .sch_options #sch_clr { height: 55px;line-height: 55px;background: #fff;color: #000;text-align: left;padding-left: 0;border-bottom: 1px solid #f1f3f5; }
    .sch_clr_div li {display: inline-block;font-size: 0; height: 55px !important; line-height:55px;}
    .sch_clr_div li label { transition:0.1s; cursor: pointer;float: left;width: 22px;height: 22px;box-sizing: border-box;background-position: -1px -1px !important; display: inline-block; border-radius: 50%;margin-top: 15px;margin-right: 13px;background-image: url(../assets/images/contents/checked_fff.png);   }
    .sch_clr_div li label:hover { transform: scale(1.12) }
    .sch_clr_div input[type='checkbox']:not(old) + label {  background-image: none;}
    .sch_clr_div input[type='checkbox']:not(old):checked + label {background-image: url(../assets/images/contents/checked_fff.png); }
    .sch_clr_div li:last-child label { margin-right:0; }
    .sch_clr_div {width: 100% !important; border-bottom: 1px solid #e7e9ea; position: relative; }
    .sch_clr_li dd:hover .selectAllDiv { display:block !important; }
    .selectAllDiv {display:block !important;position: absolute;z-index: 100000;width: 40px !important;top:17px;height: 20px;left:0;background: #fff;box-sizing: border-box; border-right:1px solid #e3e7ed;}
    .selectAllDiv p {position: relative;}
    .selectAllDiv label span {display:none;position: absolute;width: 200px;left: 48px;top: 13px;font-size:12px;}
    .selectAllDiv label {  }
    .selectAllDiv input[type='checkbox']:not(old) + label { background-color:#c1cfd8 !important; background-image: url(../assets/images/contents/checked_fff.png);background-position: center center !important;padding: 0;width: 20px;height: 20px;border-radius: 3px;background-size: 23px !important;background-position: -1px -1px !important;}
    .selectAllDiv input[type='checkbox']:not(old):checked + label {background-color:#477fff !important;}
    
    .checkOptions p { margin-right:15px; }
    .schDateList { position: relative; }
    .schDateList .ware_seper { position: absolute;left: 40%;margin-left: 100px;opacity: 0.5;top: 0;font-weight: normal;width: 50px;font-size: 14px; text-align: center; }
    .save_last_section { border-top: 1px solid #f1f3f5; overflow: hidden; }
    .save_last_section input { width: 33.3333%; float: left; height: 70px;border-bottom: 0 !important;  background: #fff; line-height: 70px; font-size: 14px; font-weight: bold; transition:0.2s; }
    .save_last_section input:hover { cursor: pointer; background: #f1f3f5; }
    

    .checkOptions label {    margin-top: 18px; cursor: pointer;float: left;background-size: 23px !important;width: 20px;height: 20px;box-sizing: border-box;background-position: -1px -1px !important; display: inline-block; border-radius: 3px; }
    .checkOptions input { display:none; }
    .checkOptions > p { position: relative; width: 100px !important}
    .checkOptions p span {cursor: pointer;position: absolute;width: 80px !important; left: 30px;top: 19px;display: inline-block;font-size:12px;margin-left: 5px; opacity: 0.6 }
    .checkOptions label { background-image: url(../assets/images/contents/checked_fff.png);  }
    .checkOptions input[type='checkbox']:not(old) + label {  background-image: url(../assets/images/contents/checked_fff.png);background-color: #c1cfd8;}
    .checkOptions input[type='checkbox']:not(old):checked + label { background-color: #477fff;  }
    .checkOptions input[type='checkbox']:not(old):checked + label span { font-weight: bold; opacity: 1; }
    .checkOptions.last { border-bottom: 0px; }

    .bg4sch {display:none;width: 100%;height: 100%;background: #fff; opacity:0.4;position: absolute;z-index: 1000;}
    .bg4sch.on { display: block; }

    /* 검색 옵션 */
    .searchDiv .bt_option { display:block !important; }
    .schGrp input#event_search { padding-left: 150px;  }
    .sch_wrt_li, .sch_plc_li { display:inline-block; width:50%; }
    .sch_plc_li dl dt label { text-align:center;     text-indent: -10px;}
    .sch_clr_div li:first-child { margin-left: 55px;}

    /* 참석 & 투표 */
    .attendAndVote { display: inline-block;width:100%; float:left;padding-right:20px; height:30px;line-height:30px; box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap; margin-top:5px; }
    .attendAndVote .attendAndVoteUl > li { float : left; margin-right: 10px; border-radius: 20px; height: 30px; line-height:30px;font-weight:bold; background:#f1f3f5; padding: 0 13px; font-size: 12px; } 

    /* 이미지 */
    .eventImage { padding-top:13px; border-top:1px dashed #e7e9ea; display: inline-block;width:100%; float:left;padding-right:20px;  box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap; margin-top:15px;}
    .eventImageUl > li { width : 50px; height: 50px; margin-right: 10px; border : 1px solid #f1f3f5; float: left;border-radius: 5px; overflow:hidden; }
    .eventImageUl > li > img { width : 100%; height: 100%; }

    /* d-day */
    p.work_d_day {display: inline-block;float: right; line-height:23px; font-weight: bold; text-align:right; font-size: 12px; opacity:0.5}

    /* 업무 자체의 현황 */
    .work_status {margin-top:5px;margin-right:10px;float:left;font-size: 12px; display:inline-block; width : 30px; font-weight: bold; text-align: center; padding : 4px 4px 4px 4px ; border-radius: 10%; color :#fff; }
    .work_status.work_wait { background : #e7e9ea; color :#838c92 }
    .work_status.work_start {  background : #ffcd00; }
    .work_status.work_end { background : transparent; color : #477fff; border : #477fff 1px solid; }
    .work_status.work_cancel { background : #ff6369; }

    /* 업무 유저의 현황 */
    .work_user_status { margin-top:10px; margin-top: 13px;border-top:1px solid #eceff1; padding-top:5px; display: inline-block;width:100%; float:left; height : 40px; line-height: 40px; font-size: 14px; }
    .work_user_status .work_user_status_check_ul { width:93%; height : 40px; line-height : 40px; float : right; text-align: right; }
    .work_user_status .work_user_status_check_ul li { float : left; width: 23px;    margin-top: 7px;
    height: 23px; border-radius: 50%; margin-left : 10px; background: url('../assets/images/contents/ic_svg_check_fff.svg') center no-repeat; background-size:13px; background-color: #477fff; }
    .work_user_status .work_user_status_check_ul li.is_wait { background-color : #c1cfd8 !important; }

    .x_red { color:#ff6060 }
    .x_blue { color:#477fff }
    .x_gray { color:#92969e  }
    .top_date { font-size: 13px;font-weight:bold; margin-right:10px !important; }
    .top_dDay { font-weight:bold; opacity:0.4; font-size: 12px; }
    .top_dDay.today { color:#477fff; opacity:1; }

    .topContainer { display:block;width:100%; margin-bottom: 15px; }
    .topContainer * { display:inline-block;  margin-right:15px;}

    .cl-work-top { display : inline-block; position: absolute; width : 5px; height : 5px; background-color : #477fff; border-radius : 50%; top : 9px; left : 16px; }
    .cl-work-bottom { display : inline-block; position: absolute; width : 5px; height : 5px; background-color : #477fff; border-radius : 50%; top : 17px; left : 16px; }
</style>

<style>
    .from_am_pm_em { font-style:normal; }
    .to_am_pm_em { font-style:normal; }

</style>