<template>
    <div id="organization_rank_setting" class="dis member_set_1" :class="{ dept_close : is_close_organization == true }">

        <div class="approval_content">
            <div class="filter_tap" id="dep4" :class="{ close : is_close_organization == true }">

                <div class="content_top">
                    <input type="button" class="mag_btn" :class="{ on : is_close_organization == true }" @click="is_close_organization = !is_close_organization">
                    <h3 style="margin-left: 60px;">직원 기본 및 인사정보</h3>
                    <input type="text" id="filter_search" placeholder="이름, 부서, 직급으로 검색하세요" style="left:250px" :value="search_query" @input="search_query = $event.target.value"/>
                    <!-- <input type="button" class="refresh" title="그룹환경 불러오기" value="그룹환경 불러오기" @click.prevent="reset" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/> -->
                    <p class="btn_group" style="">
                        <a href="#" id="excel_import" class="input_btn" @click.prevent="showOrganizationEmpPersonalRecordImportModalInfo" v-if="create_and_update_permission == true">엑셀 불러오기</a>
                    </p>
                </div>

                <div class="wrapper" style="padding-top : 0;">
                    
                    <div class="chk all" style="padding-top : 20px; border-bottom:1px solid #e7e9ea; background: #f2f5f7;">
                        <p style="top:10px; font-size:13px; font-weight:bold;">부서</p>
                    </div>

                    <!-- 조직도 모달 -->
                    <!-- <a href="#" class="organization_setting" @click.prevent="showOrganizationModal"></a> -->

                    <div id="organization_setting_scroll" :style="{ height : `${department_scroll_height}px` }"> 

                        <div class="chk all" style="padding-top : 0;cursor:pointer;" :class="{ click_on : selected_department.dept_id == -1 }" @click="select({ dept_id : -1 })">
                            <p style="top:0px;">전체</p>
                        </div>

                        <!-- 메인  -->
                        <!-- <div class="chk" style="cursor:pointer;" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                            <p>{{ computedOrganization != null ? computedOrganization.dept_name : '' }}</p>
                        </div> -->

                        <OrganizationRecursion 
                            :level="2"
                            :content_type="'p'" 
                            :departments="computedOrganization.departments"
                            :selected_department="selected_department"
                            :department_closed_map="department_closed_map"
                            :department_height_map="department_height_map" 
                            @select="select"
                            v-if="computedOrganization != null" />

                        <!-- 미배정 -->
                        <div class="chk" style="cursor:pointer;" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                            <p style="font-size: 13px; font-weight: bold; left: 60px; color: #477fff; opacity: .8;">미배정</p>
                        </div>

                        <!-- 첫번쩨 ul (그룹 리스트) -->
                        <ul class="sub1" v-if="false">
                            <li class="fList">
                                <!-- 상단 삐져나오는 선 가리개 -->
                                <span class="topBlank"></span>
                                <!-- 하단 삐져나오는 선 가리개 -->
                                <span class="btmBlank"></span>
                                <!-- 길이에 따라 길어지는 세로 선 -->
                                <span class="vertiLine"></span>
                                <!-- 화살표 버튼 -->
                                <input type="button" class="arw" /> 
                                <!-- cld : checkbox list div -->
                                <div class="cld">
                                    <p>피닉스</p>
                                </div>

                                <!-- 두번째 ul (팀 리스트) -->
                                <ul class="sub2">
                                    <li>
                                        <!-- horiLine 가로선 -->
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <p>개발</p>
                                        </div>

                                        <ul class="level4" style="margin-left: 35px;margin-top: -31px;">
                                            <li class="fList">
                                                <span class="topBlank"></span>
                                                <span class="btmBlank"></span>
                                                <span class="vertiLine"></span>
                                                <input type="button" class="arw"/>
                                                <div class="cld"></div>

                                                <ul class="sub2">
                                                    <li>
                                                        <span class="horiLine"></span>
                                                        <div class="cld">
                                                            <p>1팀</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span class="horiLine"></span>
                                                        <div class="cld">
                                                            <p>2팀</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>

                                    </li>
                                    <li>
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <p>교육</p>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="fList">
                                <!-- 상단 삐져나오는 선 가리개 -->
                                <span class="topBlank"></span>
                                <!-- 하단 삐져나오는 선 가리개 -->
                                <span class="btmBlank"></span>
                                <!-- 길이에 따라 길어지는 세로 선 -->
                                <span class="vertiLine"></span>
                                <!-- 화살표 버튼 -->
                                <input type="button" class="arw" />
                                <!-- cld : checkbox list div -->
                                <div class="cld">
                                    <p>IRS</p>
                                </div>
                                <ul class="sub2">
                                    <li>
                                        <!-- horiLine 가로선 -->
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <p>개발</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <p>심사</p>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="fList">
                                <!-- 상단 삐져나오는 선 가리개 -->
                                <span class="topBlank"></span>
                                <!-- 하단 삐져나오는 선 가리개 -->
                                <span class="btmBlank"></span>
                                <!-- 길이에 따라 길어지는 세로 선 -->
                                <span class="vertiLine"></span>
                                <!-- 화살표 버튼 -->
                                <input type="button" class="arw" />
                                <!-- cld : checkbox list div -->
                                <div class="cld">
                                    <p>모바일</p>
                                </div>
                                <!-- 두번째 ul (팀 리스트) -->
                                <ul class="sub2">
                                    <li>
                                        <!-- horiLine 가로선 -->
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <p>개발</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span class="horiLine"></span>
                                        <div class="cld">
                                            <p>디자인</p>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="fList">
                                <div class="cld">
                                    <p>영업</p>
                                </div>
                            </li>
                            <li class="fList">
                                <div class="cld">
                                    <p>경영지원</p>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
                <div id="noticeScroll" class="section_scroll">
                    <div class="viewGroup">
                        <div class="schedule_box"></div>

                        <div class="fixedDiv">
                            <ul id="" class="sortHeader groupNoticeHeader">
                                <li class="num">No</li>
                                <li class="title headerSort" :class="{
                                    sortOff  : sort_type != 'NAME',
                                    sortUp   : sort_type == 'NAME' && sort_direction == 'ASC', 
                                    sortDown : sort_type == 'NAME' && sort_direction == 'DESC', 
                                }" @click.prevent="sort(organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME)">
                                    <span style="margin-left:23px;">이름</span>
                                </li>

                                <!-- <li class="phonenum"><span>전화번호</span></li> -->

                                <li class="group headerSort" :class="{
                                    sortOff  : sort_type != 'DEPARTMENT',
                                    sortUp   : sort_type == 'DEPARTMENT' && sort_direction == 'ASC', 
                                    sortDown : sort_type == 'DEPARTMENT' && sort_direction == 'DESC', 
                                }" @click.prevent="sort(organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.DEPARTMENT)">
                                    <span style="margin-left:20px;">부서</span>
                                </li>

                                <li class="pos headerSort" :class="{
                                    sortOff  : sort_type != 'POSITION',
                                    sortUp   : sort_type == 'POSITION' && sort_direction == 'ASC', 
                                    sortDown : sort_type == 'POSITION' && sort_direction == 'DESC', 
                                }" @click.prevent="sort(organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION)">
                                    <span style="margin-left:20px;">직급</span>
                                </li>
                                
                                <!-- <li class="approver headerSort" :class="{
                                    sortOff  : sort_type != 'APPROVER',
                                    sortUp   : sort_type == 'APPROVER' && sort_direction == 'ASC', 
                                    sortDown : sort_type == 'APPROVER' && sort_direction == 'DESC', 
                                }" @click.prevent="sort('APPROVER')">
                                    <span>결재자</span>
                                </li> -->

                                <!-- 이메일 -->
                                <!-- <li class="email headerSort" :class="{
                                    sortOff  : sort_type != 'EMAIL',
                                    sortUp   : sort_type == 'EMAIL' && sort_direction == 'ASC', 
                                    sortDown : sort_type == 'EMAIL' && sort_direction == 'DESC', 
                                }" @click.prevent="sort(organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.EMAIL)">
                                    <span>이메일</span>
                                </li> -->
                                <!-- 근무타입 -->
                                <li class="workType">
                                    <span>근무타입</span>
                                    <button class="workTypeBt" type="button" @click="is_work_type_open = !is_work_type_open">
                                        <span class="blind ">정보 보기</span>
                                    </button>
                                    <!-- 스크롤 -->
                                    <div class="workTypeList" :class="{ on : is_work_type_open == true }">

                                        <ul>
                                            <li :key="work_type.work_type_id" v-for="work_type in work_types" style="line-height: 30px; padding: 15px 0; border-bottom: 1px solid #e7e9ea;">
                                                <table style="margin:0px; width: 100%;">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2" style="color: rgb(000, 034, 080); font-weight: bold; font-size: 13px; padding-left: 20px; line-height: 25px;">{{ work_type.work_type_name }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr :key="index" v-for="(day, index) in computedWorkType(work_type)">
                                                            <td style="font-size: 13px; color: rgb(155, 161, 174); padding-left: 20px;">{{ day.text }}</td>
                                                            <td style="font-size: 13px; color: rgb(155, 161, 174); padding-right: 20px; text-align: right;" v-if="day.is_telecommuting == false && (!day.am_from || !day.am_to) && (!day.pm_from || !day.pm_to)">근무 없음</td>
                                                            <td style="font-size: 13px; color: rgb(155, 161, 174); padding-right: 20px; text-align: right;" v-else-if="day.is_telecommuting == true">재택 근무</td>
                                                            <td style="font-size: 13px; color: rgb(155, 161, 174); padding-right: 20px; text-align: right;" v-else>{{ (!day.am_from || !day.am_to) ? '' : `${processTimeText(day.am_from)} ~ ${processTimeText(day.am_to)}` }}<br v-if="day.am_from && day.am_to && day.pm_from && day.pm_to" />{{ (!day.pm_from || !day.pm_to) ? '' : `${processTimeText(day.pm_from)} ~ ${processTimeText(day.pm_to)}` }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li> 
                                        </ul>

                                        <!-- <ul>
                                            <li>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>기본근무</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>주말</td>
                                                            <td>근무 없음</td>
                                                        </tr>
                                                        <tr>
                                                            <td>평일</td>
                                                            <td>오전 09:00 ~ 오후 12:00<br>오후 01:00 ~ 오후 06:00</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>
                                            <li>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>금요일재택</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>주말</td>
                                                            <td>근무 없음</td>
                                                        </tr>
                                                        <tr>
                                                            <td>월, 화, 수, 목</td>
                                                            <td>오전 09:00 ~ 오후 12:00<br>오후 01:00 ~ 오후 06:00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>금</td>
                                                            <td>재택 근무</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>
                                            <li>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>재택근무2일</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>주말</td>
                                                            <td>근무 없음</td>
                                                        </tr>
                                                        <tr>
                                                            <td>월, 수, 목</td>
                                                            <td>오전 09:00 ~ 오후 12:00<br>오후 01:00 ~ 오후 06:00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>화, 금</td>
                                                            <td>재택 근무</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>
                                        </ul> -->
                                    </div>
                                </li>

                                <!-- <li class="writtenBy"><span style="margin-left:20px;">팀 대표</span></li> -->
                            </ul>
                        </div>

                        <div id="organization_emp_scroll" class="content">

                            <ul class="notiListUl">

                                <!-- <li class="">
                                    <p class="num"  style="padding-left: 20px;box-sizing: border-box;"><span >1</span></p>
                                    <p class="title">
                                        <img src="../../assets/images/contents/ic_approval_user_on.png">
                                        <span>김성림</span>
                                    </p>
                                    <p class="email">abcdefg@hij.com</p>
                                    <p class="phonenum">01012345678</p>
                                    <p class="group">
                                        <span class="setting">지누스</span>
                                    </p>
                                    <p class="pos">
                                        <select name="" id="">
                                            <option value="" selected="selected">부장</option>
                                            <option value="" selected="selected">과장</option>
                                        </select>
                                    </p>
                                </li> -->

                                <li :key="employee.user_id" v-for="(employee, index) in computedEmployee">
                                    <p class="num">{{ index + 1 }}</p>
                                    <p class="title" :title="employee.user_name">
                                        <img :class="{ king : employee.is_approver == true }" :src="getProfileImage(employee)" @error="userImageError($event)">
                                        <span>{{ employee.user_name }}</span>
                                        <!-- title쪽 클릭시 상세보기(권한자용)페이지 / 인사기본정보(일반멤버용)모달 -->
                                        <a href="#" class="seeDets" @click.prevent="movePersonalRecord(employee)">자세히 보기</a>
                                    </p>
                                    
                                    <!-- <p class="phonenum" :title="employee.user_phone_number && employee.user_phone_number.length > 0 ? employee.user_phone_number : ''">
                                        {{ employee.user_phone_number && employee.user_phone_number.length > 0 ? employee.user_phone_number : '-' }}
                                    </p> -->
                                    
                                    <p class="group" :title="getDepartmentName(employee.dept_id) == '-' ? '' : getDepartmentName(employee.dept_id)">
                                        {{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '' : (getDepartmentName(employee.dept_id) == '-' ? '' : getDepartmentName(employee.dept_id))  }}
                                        <span class="setting" @click="showOrganizationModal2(employee)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">{{ getDepartmentName(employee.dept_id) }}</span>
                                    </p>

                                    <p class="pos" :title="getPositionName(employee.pos_id) == '-' ? '' : getPositionName(employee.pos_id)">
                                        {{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '' : (getPositionName(employee.pos_id) == '-' ? '' : getPositionName(employee.pos_id)) }}
                                        <select @change="changeEmployeePosition($event, employee)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                            <option value="-" :selected="getPositionName(employee.pos_id) == '-'" v-if="getPositionName(employee.pos_id) == '-'">-</option>
                                            <option :key="pos.pos_id" :value="pos.pos_id" v-for="pos of position" :selected="employee.pos_id == pos.pos_id">{{ pos.pos_name }}</option>
                                        </select>
                                    </p>

                                    <p class="workType">
                                        {{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '' : (employee.work_type_id ? work_types.filter(work_type => work_type.work_type_id == employee.work_type_id).length > 0 ? work_types.filter(work_type => work_type.work_type_id == employee.work_type_id)[0].work_type_name : '-' : '-') }}
                                        <select @change="changeEmployeeWorkType($event, employee)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                            <option value="-" :selected="employee.work_type_id || work_types.filter(work_type => work_type.work_type_id == employee.work_type_id).length < 1" v-if="employee.work_type_id == null || work_types.filter(work_type => work_type.work_type_id == employee.work_type_id).length < 1">-</option>
                                            <option :value="work_type.work_type_id" :key="work_type.work_type_id" v-for="work_type in work_types" :selected="work_type.work_type_id == employee.work_type_id">{{ work_type.work_type_name }}</option>
                                        </select>
                                    </p>

                                    <!-- 근무타입 리스트 -->
                                    <!-- <p class="workType">
                                        <select name="" id="">
                                            <option value="0">기본근무</option>
                                            <option value="0">금요일재택</option>
                                            <option value="0">주2회재택</option>
                                        </select>
                                    </p> -->
                                    
                                    <!-- <p class="approver">
                                        {{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '' : (employee.is_approver == true ? '예' : '아니오') }}
                                        <select @change="changeEmployeeApprover($event, employee)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                            <option value="N" :selected="employee.is_approver == false">아니오</option>
                                            <option value="Y" :selected="employee.is_approver == true">예</option>
                                        </select>
                                    </p> -->

                                    <!-- 이메일 -->
                                    <!-- <p class="email" :title="employee.user_email && employee.user_email.length > 0 ? employee.user_email : ''">
                                        {{ employee.user_email && employee.user_email.length > 0 ? employee.user_email : '-' }}
                                    </p> -->

                                    

                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <!-- <div class="approval_menu" v-if="false">
            <div class="approval_wrtie">
                <input type="button" value="＋직급 추가" class="app_wrt_btn" @click="addPosition">
            </div>
            <div class="miniDep_box">
                <ul class="header">
                    <li class="position_list position_title"><p class="till">직급 순</p><p class="pos_name">직급명</p></li>
                </ul>
                                
            <div id="organization_rank_scroll">
                <ul>
                    <li class="position_list" :key="pos.pos_seq" v-for="(pos, index) in position">
                        <p class="till">
                        <span>{{ pos.pos_seq }}</span>
                        <span class="updown" style="position: absolute;">
                        <input class="upbtn" type="button" :disabled="pos.pos_seq == 1" @click.prevent="updatePositionSeq(index, -1)"/>
                        <input class="downbtn" type="button" :disabled="pos.pos_seq == (position.length)" @click.prevent="updatePositionSeq(index, 1)"/>
                        </span>
                        </p>
                        <p class="pos_name" @click.prevent="modifyPosition(pos)">
                        <span>{{ pos.pos_name }}</span>
                        <input class="dal" type="button"  value="-" @click.prevent="deletePosition($event, pos)"/>
                        </p>
                    </li> -->
                                        
                    <!-- <li class="position_list">
                        <p class="till">
                            <span>1</span>
                            <span class="updown" style="position: absolute;">
                                <input class="upbtn" type="button" />
                                <input class="downbtn" type="button" />
                            </span>
                        </p>
                        <p class="pos_name">
                            <span>대표 (CEO)</span>
                            <input class="dal" type="button"  value="-" />
                        </p>
                    </li> -->
                                        
                <!-- </ul>
            </div>
            </div>
        </div> -->
         
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_enum, organization_modal_interface } from '@/model/organization';

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion
    },
})
export default class OrganizationRankSetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;
    
    @Prop() event_bus !: Vue;

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME ) {

                if( o1_name == o2_name ) {
                    // 같은 이름은 직급순
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_name > o2_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION ) {

                // 같은 직급은 이름순
                if( o1_pos_seq == o2_pos_seq ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0
                }
                else if( o1_pos_seq > o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
                else if( o1_pos_seq < o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.DEPARTMENT ) {
                const o1_dept_id   = o1.dept_id;
                const o2_dept_id   = o2.dept_id;

                const o1_dept_name = this.getDepartmentName(o1_dept_id);
                const o2_dept_name = this.getDepartmentName(o2_dept_id);

                if( o1_dept_name == o2_dept_name ) {

                    if( o1_dept_id == o2_dept_id ) {
                        // 같은 이름은 dept_id 순서 + 직급순
                        if( o1_pos_seq > o2_pos_seq ) return 1;
                        else if( o1_pos_seq < o2_pos_seq ) return -1;
                        return 0;
                    }
                    else if( o1_dept_id > o2_dept_id ) return 1;
                    else if( o1_dept_id < o2_dept_id ) return -1;
                    
                    return 0;
                }
                else if( o1_dept_name > o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_dept_name < o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if ( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.APPROVER ) {
                const o1_is_approver = o1.is_approver;
                const o2_is_approver = o2.is_approver;

                if( o1_is_approver == o2_is_approver ) {
                    // 같다면 직급 순
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_is_approver == false && o2_is_approver == true ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_is_approver == true && o2_is_approver == false ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if ( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.EMAIL ) {
                const o1_email = o1.user_email;
                const o2_email = o2.user_email;

                if( o1_email == o2_email ) {
                    // 이메일이 같다면 직급 순 (일반적으로 데이터가 들어있다면 같을 순 없지만 비어있다면 똑같을수 있음)
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_email > o2_email ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_email < o2_email ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            return 0;
        });

        // 검색
        if( this.search_query != null && this.search_query.length > 0 ) {
            employees = employees.filter(item => 
                                                 // 이름   
                                                 this.hodu_string_includes(item.user_name, this.search_query) ||
                                                 
                                                 // 이메일
                                                 this.hodu_string_includes(item.user_email, this.search_query) ||

                                                 // 전화번호
                                                 this.hodu_string_includes(item.user_phone_number, this.search_query) ||

                                                 // 부서
                                                 this.hodu_string_includes(this.getDepartmentName(item.dept_id), this.search_query) ||

                                                 // 직급
                                                 this.hodu_string_includes(this.getPositionName(item.pos_id), this.search_query) );
        }

        return employees;
    }

    /**
     * work_type.contents 같은 시간끼리 뭉치기
     */
     get computedWorkType() {
        return (work_type : any) => {
            
            const list : any[] = [];

            for( const content of work_type.contents ) {

                const am_from          = content.am_from;
                const am_to            = content.am_to;
                const pm_from          = content.pm_from;
                const pm_to            = content.pm_to;
                const is_telecommuting = content.is_telecommuting;

                const targets = list.filter(item => item.am_from          == am_from &&
                                                    item.am_to            == am_to   &&
                                                    item.pm_from          == pm_from &&
                                                    item.pm_to            == pm_to   && 
                                                    item.is_telecommuting == is_telecommuting);

                if( targets.length < 1 ) {
                    list.push({
                        text_array       : [this.getDayName(content.week)],
                        text             : "",
                        am_from          : am_from,
                        am_to            : am_to,
                        pm_from          : pm_from,
                        pm_to            : pm_to,
                        is_telecommuting : is_telecommuting,  
                    });
                    continue;
                }

                targets[0].text_array.push(this.getDayName(content.week));
            }

            for( const item of list ) {
                // 평일, 주말 여부
                let is_week_day = false;
                let is_week_end = false;

                if( item.text_array.indexOf('월') > -1 && item.text_array.indexOf('화') > -1 && item.text_array.indexOf('수') > -1 && item.text_array.indexOf('목') > -1 && item.text_array.indexOf('금') > -1 ) {
                    is_week_day = true;
                }

                if( item.text_array.indexOf('토') > -1 && item.text_array.indexOf('일') > -1 ) {
                    is_week_end = true;
                }

                if( is_week_day == true && is_week_end == true ) {
                    item.text = "평일, 주말";
                    continue;
                }

                else if( is_week_day == true && is_week_end == false ) {
                    item.text = "평일";
                }

                else if( is_week_day == false && is_week_end == true ) {
                    item.text = "주말";
                }

                for( const text of item.text_array ) {
                    if( is_week_day == true && (text == "월" || text == "화" || text == "수" || text == "목" || text == "금") ) continue;
                    if( is_week_end == true && (text == "토" || text == "일" ) ) continue;

                    if( item.text.length > 0 ) item.text += ", ";
                    item.text += text;
                }
            }

            return list;
        };
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationModalInfo ?: (params : organization_modal_interface.OrganizationModalInfo) => void;
    @ModalInfo.Action doSetOrganizationModal2Info ?: (params : organization_modal_interface.OrganizationModal2Info) => void;
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordDetailModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordDetailModalInfo) => void;
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordImportModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordImportModalInfo) => void;

    departments : any[] = [];
    selected_department : any = { dept_id : -1 }; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];
    work_types : any[] = [];
    search_query : string = "";

    sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE = organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    department_scroll_height : number = 0;

    is_close_organization : boolean = false;
    is_work_type_open : boolean = false;

    create_and_update_permission : boolean = false;
    
    async mounted() : Promise<void> {
        this.event_bus?.$on("OrganizationRankSettingResize", this.handleResize);

        await this.getTotalOrganizationData();
        this.setScroll();

        // $(".cld, .chk").click(function(){
        //     $(this).toggleClass("click_on");
        // });

        //근무타입 정보보기
        // $(".workTypeBt").click(function(){
        //     $(this).siblings().toggleClass("on");
        // });


    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_box_height = $('#organization_setting .title_box').outerHeight();
        const title_box2_height = $('#organization_setting .title_box2').outerHeight();
        const approval_wrtie_height = $('#organization_setting .approval_menu .approval_wrtie').outerHeight();
        const header_height = $('#organization_setting .approval_menu .miniDep_box .header').outerHeight();

        // @ts-ignore
        // $('#organization_rank_scroll').mCustomScrollbar({
        //     axis : 'y',
        //     scrollbarPosition : 'outside',
        //     mouseWheelPixels : 100,
        //     scrollInertia : 60,
        //     autoDraggerLength : false,
        //     setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
        //                                    - (title_box2_height ? title_box2_height : 0)
        //                                    - (approval_wrtie_height ? approval_wrtie_height : 0)
        //                                    - (header_height ? header_height : 0)
        // });

            
        // 조직도 스크롤
        const all_height = $('#organization_setting .approval_content .wrapper .chk.all').outerHeight();
        const content_top_height = $('#organization_setting .approval_content .content_top').outerHeight();

        this.department_scroll_height = window.innerHeight - (title_box_height ? title_box_height : 0)
                                                           - (title_box2_height ? title_box2_height : 0)
                                                           - (content_top_height ? content_top_height : 0)
                                                           - (all_height ? all_height : 0);

        // 부서원 리스트 스크롤
        const fixed_div_height = $('#organization_setting .approval_content .section_scroll .fixedDiv').outerHeight();

        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (title_box2_height ? title_box2_height : 0)
                                           - (content_top_height ? content_top_height : 0)
                                           - (fixed_div_height ? fixed_div_height : 0)
        });
    }
    
    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();
            await this.getOrganizationWorktype();

            this.create_and_update_permission = this.get_group_role(this.scope_group_id) == 'ADMIN';
                    
            if( this.create_and_update_permission == false ) {

                const permission_response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/personal_record/permission`, API_METHOD.GET);

                console.log(permission_response);

                if( permission_response == null || !this.isHttpStatusSuccess(permission_response.status) || !permission_response.data || !permission_response.data.data ) {
                    throw new Error("인사정보 등록 및 수정 권한 조회중 오류발생");
                }

                const permission = permission_response.data.data.permission;

                if( permission != null && permission.length > 0 ) {
                    for( const perm of permission ) {
                        if( perm.user_id == this.user_id ) {
                            this.create_and_update_permission = true;
                            break;
                        } 
                    }
                }
            }

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }
    
    /**
     * 근무 타입 조회
     */
    async getOrganizationWorktype() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_type_info ) {
                throw new Error("조직도 근무 타입 조회 중 오류 발생");
            }

            this.work_types.splice(0, this.work_types.length);
            this.work_types = this.work_types.concat(response.data.data.work_type_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 직원 직급 변경
     */
     async changeEmployeeWorkType(event, employee : any) : Promise<void> {
        try {
            const work_type_id = event.target.value;

            // API 실행
            const data = JSON.parse(JSON.stringify(employee));
            data.work_type_id = work_type_id;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.POST, data);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 직원 근무 변경 중 오류 발생");
            }

            this.getTotalOrganizationData();

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직원 근무 변경 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 조직도 부서 관리 모달
     */
    showOrganizationModal() : void {
        this.doSetOrganizationModalInfo?.({ 
            show_modal : true, 
            is_select_only : false,
            callback : () => {
                this.getTotalOrganizationData();
            }
        });
    }

    /**
     * 조직도 부서 선택 모달
     */
    showOrganizationModal2(employee) : void {
        this.doSetOrganizationModal2Info?.({ 
            show_modal : true,
            dept_id : employee.dept_id ? employee.dept_id : 0, 
            callback : async(department) => {  
                try {

                    // API 실행
                    const data = JSON.parse(JSON.stringify(employee));
                    data.dept_id = department.dept_id;
                    data.is_approver = false;
                    
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.POST, data);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 직원 부서 변경 중 오류 발생");
                    }

                    this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직원 부서 변경 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 직급 추가 모달
     */
    addPosition() : void {
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "직급 추가",
            subtitle : "직급명",
            placeholder : "직급명을 입력해주세요",
            save : async(name) => {
                try {

                    // 정보 가공
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.POST, { "pos_name" : name });
                    
                    console.log(response);
                    
                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 직급 추가 중 오류 발생");
                    }

                    this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직급 추가 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 직급 수정 모달
     */
    modifyPosition(position : any) : void {
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "직급 수정",
            subtitle : "직급명",
            placeholder : "직급명을 입력해주세요",
            content : position.pos_name,
            save : async(name) => {
                try {
                    await this.modifyPositionAPI(position.pos_id, { "pos_name" : name });
                    this.getTotalOrganizationData();
                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직급 수정 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 직급 수정 API
     */
    async modifyPositionAPI(pos_id : number, data : any) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position/${pos_id}`, API_METHOD.PUT, data, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 직급 수정 중 오류 발생");
            }
        } catch(e) {
            throw e;
        }
    }

    /**
     * 직급 순서 변경
     */
    async updatePositionSeq(index : number, dir : number) : Promise<void> {
        try {
            // 제일 직급이 높은데 위로 가는걸 누른경우, 제일 직급이 낮은데 아래로 가는걸 누른 경우
            if( (dir == -1 && index == 0) || (dir == 1 && index == (this.position.length - 1)) ) return;

            const target_position = this.position[index];
            const affected_position = this.position[index + dir];

            if( target_position == null || affected_position == null ) {
                throw new Error("조직도 직급 순서 수정 중 오류 발생");
            }

            const modify_promise : Promise<any>[] = [];
            
            modify_promise.push(this.modifyPositionAPI(target_position.pos_id, { pos_seq : target_position.pos_seq + dir }));
            modify_promise.push(this.modifyPositionAPI(affected_position.pos_id, { pos_seq : affected_position.pos_seq - dir }));

            this.hodu_show_indicator();
            await Promise.all(modify_promise);
            await this.getTotalOrganizationData();
            this.hodu_hide_indicator();
            
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직급 순서 수정 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 직급 삭제
     */
    deletePosition(event, position : any) : void {
        event.stopPropagation();

        this.hodu_show_dialog('cancel', `정말로 직급을 삭제하시겠습니까?\n'${position.pos_name}'`, ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    // 정보 가공
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position/${position.pos_id}`, API_METHOD.DELETE);
                    
                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 직급 삭제 중 오류 발생");
                    }

                    this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직급 삭제 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ]);
    }

    /**
     * 직원 부서 변경 모달
     */
    selectOrganizationDepartmentModal(employee : any) : void {
        this.doSetOrganizationModalInfo?.({ 
            show_modal : true, 
            is_select_only : true,
            callback : async(department) => {
                try {

                    // API 실행
                    const data = JSON.parse(JSON.stringify(employee));
                    data.dept_id = department.dept_id;
                    
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.POST, data);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 직원 부서 변경 중 오류 발생");
                    }

                    this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직원 부서 변경 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 직원 직급 변경
     */
    async changeEmployeePosition(event, employee : any) : Promise<void> {
        try {
            const pos_id = event.target.value;

            // API 실행
            const data = JSON.parse(JSON.stringify(employee));
            data.pos_id = pos_id;
            data.is_approver = false;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.POST, data);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 직원 직급 변경 중 오류 발생");
            }

            this.getTotalOrganizationData();

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직원 직급 변경 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 부서 이름 반환
     */
    getDepartmentName(dept_id : number) : string {
        try {
            const target = this.departments.filter(item => item.dept_id == dept_id);
            if( target.length < 1 ) return "-";
            if( target[0].dept_id == 0 ) return "미배정";
            return target[0].dept_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 정렬
     */
    sort(sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 부서 초기화 진행 (그룹환경 불러오기)
     */
    reset() : void {
        this.hodu_show_dialog("alert", "그룹환경 불러오기를 진행하면 기존에 입력한\n부서 및 직원의 부서, 직원의 기본결재자 정보가 전부 초기화 됩니다\n정말로 그룹환경 불러오기를 진행하시겠습니까?", ['아니오', '예'], [
            () => this.getTotalOrganizationData(),
            () => {
                this.hodu_show_dialog("alert", "최종 확인입니다\n정말로 그룹환경 불러오기를 진행하시겠습니까?", ['아니오', '예'], [
                    () => this.getTotalOrganizationData(),
                    async() => {
                        try {

                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/set_team_default`, API_METHOD.POST);

                            console.log(response);
                            
                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("그룹환경 불러오기 진행 중 오류 발생");
                            }

                            await this.getTotalOrganizationData();

                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                            this.hodu_show_dialog('cancel', "그룹환경 불러오기 진행 중 오류 발생", ['확인']);
                        }
                    },
                ]);
                
            },
        ]);
    }

    async movePersonalRecord(employee) {

        console.log(employee);

        // 인사정보 열람 권한
        const personal_record_read_permission = this.is_group_permmision(this.scope_group_id, 'personal_record', 'read'); 
        
        // 인사정보 등록 및 수정 권한 조회 (ADMIN은 그냥 가능)
        this.create_and_update_permission = (this.get_group_role(this.scope_group_id) == 'ADMIN');

        if( this.create_and_update_permission == false ) {

            const permission_response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/personal_record/permission`, API_METHOD.GET);

            console.log(permission_response);

            if( permission_response == null || !this.isHttpStatusSuccess(permission_response.status) || !permission_response.data || !permission_response.data.data ) {
                throw new Error("인사정보 등록 및 수정 권한 조회중 오류발생");
            }

            const permission = permission_response.data.data.permission;

            if( permission != null && permission.length > 0 ) {
                for( const perm of permission ) {
                    if( perm.user_id == this.user_id ) {
                        this.create_and_update_permission = true;
                        break;
                    } 
                }
            }

        }
    
        // 인사정보 열람 권한 있음 || 인사정보 수정 권한이 있음
        if( personal_record_read_permission == true || this.create_and_update_permission == true ) {
            
            try {

                // 인사정보 조회
                const group_id = employee.group_id;
                const user_id = employee.user_id;

                const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}`, API_METHOD.GET);

                if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("인사정보 조회중 오류발생");
                }

                const record = response.data.data.record;

                // 인사정보 조회 결과 없음
                if( record == null ) {
                    
                    // 등록 권한 있음 (인사정보 등록 화면으로 이동)
                    if( this.create_and_update_permission == true ) {
                        this.hodu_router_push(`/GROUP/${this.scope_group_id}/organization/personal_record/create/${user_id}`);
                    }
                    // 등록 권한 없음 (인사정보 다이얼로그 띄움)
                    else {
                        this.doSetOrganizationEmpPersonalRecordDetailModalInfo?.({
                            show_modal : true,
                            group_id : employee.group_id,
                            user_id : employee.user_id
                        });
                    }
                    
                }
                // 인사정보 조회 결과 있음 (인사정보 조회 화면으로 이동)
                else {
                    this.hodu_router_push(`/GROUP/${this.scope_group_id}/organization/personal_record/${employee.user_id}`); 
                }

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
        }
        // 인사정보 열람 권한 없음
        else {
            this.doSetOrganizationEmpPersonalRecordDetailModalInfo?.({
                show_modal : true,
                group_id : employee.group_id,
                user_id : employee.user_id
            });
        }


        // movePersonalRecordDetail() {
        //     this.hodu_router_push(`/GROUP/${this.scope_group_id}/organization/personal_record/user_id`);
        // }

        // movePersonalRecordCreate() {
        //     this.hodu_router_push(`/GROUP/${this.scope_group_id}/organization/personal_record/create/user_id`);
        // }

        // openPersonalRecordDetailModal() {
        //     this.doSetOrganizationEmpPersonalRecordDetailModalInfo?.({
        //         show_modal : true,
        //         user_id : 0
        //     });
        // }
        // <input type="button" class="input_btn" value="상세(페이지)" @click.prevent="movePersonalRecordDetail"/>
        // <input type="button" class="input_btn" value="상세(모달)" @click.prevent="openPersonalRecordDetailModal"/>
        // <input type="button" class="input_btn" value="수정" @click.prevent="movePersonalRecordCreate"/> -->
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
     processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    showOrganizationEmpPersonalRecordImportModalInfo() {
        this.doSetOrganizationEmpPersonalRecordImportModalInfo?.({
            show_modal : true,
            callback : () => {
                this.getTotalOrganizationData();
            }
        });
    }

    /**
     * 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user_on.png');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        // $('#organization_rank_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}

</script>

<style>
    /*#organization_rank_setting .viewGroup .num{width: 10%;text-align: center;font-weight: bold;max-width: 130px;}*/
    #organization_rank_setting .approval_content .section_scroll .viewGroup .fixedDiv li.num{padding-left: 20px;box-sizing: border-box;}
    #organization_rank_setting .approval_content .section_scroll .viewGroup .fixedDiv li.approver{padding-left: 20px; box-sizing: border-box;}
    #organization_rank_setting .viewGroup li.email span{padding-left: 20px;box-sizing: border-box;}
    #organization_rank_setting .viewGroup li.phonenum span{padding-left: 20px;box-sizing: border-box;}
    #organization_rank_setting .viewGroup .num {width: 9%; text-align: center; font-weight:bold; }
    #organization_rank_setting .viewGroup .title{width: 27%; position: relative; }
    #organization_rank_setting .viewGroup .email{width:30%;}
    #organization_rank_setting .viewGroup .phonenum{width: 14%;}
    #organization_rank_setting .viewGroup .pos{width:16%;}
    #organization_rank_setting .viewGroup .group{width: 18%;}
    #organization_rank_setting .viewGroup .approver{width: 10%;}
    /* #organization_rank_setting .viewGroup .writtenBy{width:13%;} */
    #organization_rank_setting .filter_tap .wrapper { position: relative;}
    #organization_rank_setting .filter_tap .wrapper .organization_setting { width: 35px; height: 35px; display: inline-block; position: absolute; right: 10px; top: 12.5px; background: url('../../assets/images/contents/ic_setting_bk.png') no-repeat; background-size: 30px; background-position: center; cursor: pointer; z-index: 201; }
    #organization_rank_setting div#organization_emp_scroll ul p.pos select, 
    #organization_rank_setting div#organization_emp_scroll ul p.workType select {font-weight: bold;-webkit-transition: 0.2s;transition: 0.2s;-webkit-appearance: none;-moz-appearance: none;appearance: none;border-radius: 5px;height: 30px;line-height: 30px;cursor: pointer;background-color: #fff;background-position: 95% center;background-repeat: no-repeat;-webkit-box-sizing: border-box;box-sizing: border-box;border: 1px solid #fff;}
    #organization_rank_setting div#organization_emp_scroll ul p.pos select:hover,
    #organization_rank_setting div#organization_emp_scroll ul p.workType select:hover {font-weight: bold;background-color: #fff;}
    #organization_rank_setting div#organization_emp_scroll ul p.pos select:focus,
    #organization_rank_setting div#organization_emp_scroll ul p.workType select:focus { border-color:#477fff !important; background-color:#fff; padding: 0 30px 0 10px; background-image: url(../../assets/images/side_area/bt_close.png) !important; }
    #organization_rank_setting div#organization_emp_scroll ul p.pos:hover select,
    #organization_rank_setting div#organization_emp_scroll ul p.workType:hover select { font-weight:bold; border:1px solid #000;  padding: 0 30px 0 10px; background-image: url(../../assets/images/side_area/bt_open.png); }
    #organization_rank_setting .click_on{z-index: 200;background: rgb(237, 242, 255, 0.8);}
    #organization_rank_setting .setting{ height: 30px;line-height: 30px;display: inline-block;}
    #organization_rank_setting .group:hover>.setting{font-weight: bold;border: 1px solid #000;padding: 0 10px;border-radius: 5px; cursor: pointer;}

    #organization_rank_setting div#organization_emp_scroll ul p.approver select:hover { font-weight: bold; background-color: #fff; }
    #organization_rank_setting div#organization_emp_scroll ul p.approver select:focus { border-color: #477fff !important; background-color: #fff; padding: 0 30px 0 10px; background-image: url(/img/bt_close.a3d0cfc5.png) !important; }
    #organization_rank_setting div#organization_emp_scroll ul p.approver:hover select { font-weight:bold; border:1px solid #000;  padding: 0 30px 0 10px; background-image: url(../../assets/images/side_area/bt_open.png); }
    #organization_rank_setting div#organization_emp_scroll ul p.approver select { width: auto; font-weight: bold; -webkit-transition: 0.2s; transition: 0.2s; -webkit-appearance: none; -moz-appearance: none; appearance: none; border-radius: 5px; height: 30px; line-height: 30px; cursor: pointer; background-color: #fff; background-position: 95% center; background-repeat: no-repeat; -webkit-box-sizing: border-box; box-sizing: border-box; border: 1px solid #fff; }

    #organization_setting_scroll { width: 100%; white-space : nowrap; overflow : auto; }
    #organization_setting_scroll::-webkit-scrollbar{width: 15px; height : 15px;}
    #organization_setting_scroll::-webkit-scrollbar-track{background-color: transparent;}
    #organization_setting_scroll::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

    #organization_rank_setting .updown input:disabled { opacity: 0.3; cursor: default; }

    #organization_rank_setting .mag_btn { position: absolute; margin-left: 20px;padding: 11px 0 0 30px;display: inline-block;width: 40px;height: 100%;background: url(../../assets/images/contents/bt_hambuger.png) no-repeat center center;box-sizing: border-box; }
    #organization_rank_setting .mag_btn.on { background: url(../../assets/images/contents/ic_filtercan_btn.png) no-repeat center center !important;background-size: 35px !important; }
    #organization_rank_setting .filter_tap.close .wrapper { display : none; }
    #organization_rank_setting .filter_tap.close #noticeScroll { left : 0px; }

    #organization_rank_setting.pos_close .approval_menu { display : none; }
    #organization_rank_setting.pos_close .approval_content { right : 0; border-right: none; }
    #organization_rank_setting #noticeScroll,#noticeScroll2{position: absolute; width: 55%; top: 66px; left: 15%; right: 0; border-right: 1px solid #f1f3f5;}

    /* #container #section_ce #organization_rank_setting.pos_close.dept_close .section_scroll {min-width: 100%;} */
    /* #container #section_ce #organization_rank_setting.pos_close .section_scroll {min-width: 85%;} */
    #container #section_ce #organization_rank_setting.dept_close .section_scroll {min-width: 100%;}
    #container #section_ce #organization_rank_setting .section_scroll {min-width: 85%;}

    #container #section_ce.right_area_close #organization_setting .approval_content .section_scroll{ min-width: calc(85% - 1px); max-width: 0;}
    #container #section_ce.right_area_close #organization_setting #organization_rank_setting.dept_close .approval_content .section_scroll{ min-width: calc(100% - 1px); max-width: 0;}
    #container #section_ce.left_area_close #organization_setting .approval_content .section_scroll{ min-width: calc(85% -  62px); max-width: 0;}
    #container #section_ce.left_area_close #organization_setting #organization_rank_setting.dept_close .approval_content .section_scroll { min-width: calc(100% -  62px); max-width: 0;}
    #container #section_ce.left_area_close.right_area_close #organization_setting .approval_content .section_scroll{ min-width: calc(85% -  63px); max-width: 0;}

    /* 23.07 기본 인사 추가 */
    .notiListUl > li .seeDets { z-index: 10; position: absolute; left: 0; top: 0; width: 100%; height: 100%; font-size: 0; }
    #organization_setting .content .notiListUl > li:hover p a.seeDets { height: 100%; padding: 0px; background: none !important; }
    #organization_setting .content .notiListUl > li:hover p a.seeDets:hover { background: none !important; }
    #organization_rank_setting .viewGroup .workType { width: 30%; }
    #organization_rank_setting .viewGroup .workType span { padding-left: 20px; -webkit-box-sizing: border-box; box-sizing: border-box; }
    .workType button.workTypeBt { width: 20px; height: 20px; position: relative; background-color: rgba(0,0,0,0); cursor: pointer; vertical-align: middle; margin-left: 3px;  border: 0; }
    .workType button.workTypeBt:before {  content: ''; display: block; width: 20px; height: 20px; position: relative; top: -1px; left: 1px; background-image: url(../../assets/images/contents/information25.svg); background-repeat: no-repeat; background-size: 100% auto; background-position: center; }
    .workType button.workTypeBt > span { padding-left: 20px; box-sizing: border-box; }
    
    .workType .workTypeList { display: none; position: absolute; width: 300px; height: auto; max-height: 600px; left: 10px; top: 55px; padding: 15px 15px; background: #fff; border-radius: 6px; border: 1px solid #e1e4e6; box-shadow: 0px 0px 9px rgb(0 0 0 / 20%); box-sizing: border-box; z-index: 99999; overflow-y: auto; }
    .workType .workTypeList.on { display: block; }

    .workType .workTypeList::-webkit-scrollbar{width: 6px;}
    .workType .workTypeList::-webkit-scrollbar-track{background-color: transparent;}
    .workType .workTypeList::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

    .workType .workTypeList::before { content: ''; position: absolute; left: 62px; border-bottom: 8px solid #fff; border-left: 8px solid transparent; border-right: 8px solid transparent; top: -7px; }
    .workType .workTypeList li { border-bottom: 1px solid #e7e9ea; padding: 10px 0 10px 0; }
    .workType .workTypeList li:first-child { padding-top: 0px; }
    .workType .workTypeList li:last-child { border-bottom: none; }
    .workTypeList table { width: 100%; margin: 0px; color: #232848; font-size: 13px; }
    .workTypeList table th { font-weight: bold; line-height: 25px; }
    .workTypeList table td { color: rgb(155, 161, 174); line-height: 30px; }
    .workTypeList table td:last-child { text-align: right; }

    @media screen and (max-width: 1400px) {
        #container #section_ce.left_area_close #organization_setting .approval_content .section_scroll{ min-width: 80%; max-width: 0;}
        #container #section_ce.left_area_close.right_area_close #organization_setting .approval_content .section_scroll{ min-width: 80.3%; max-width: 0;}

        #organization_setting_scroll { width: 100%; white-space : nowrap; overflow : auto; }
        #organization_setting #noticeScroll, #noticeScroll2{ position: absolute; width: 100%; top: 66px; left: 15%; right: 0;}
        #container #section_ce #organization_rank_setting.pos_close.dept_close .section_scroll {min-width: 100%;}
        #organization_setting .wrapper{ padding-top: 20px; width: 17%; height: auto; -webkit-box-sizing: border-box; box-sizing: border-box; border-right: 1px solid#e7e9ea; font-weight: bold; color: rgb(086, 088, 115);}

        #container #section_ce #organization_rank_setting .section_scroll {min-width: 0%; max-width: 85%;}
        /*#organization_rank_setting .viewGroup .num{ width: 6%;text-align: center;font-weight: bold;max-width: 130px;}*/
        #organization_rank_setting .viewGroup .title { width: 27%; position: relative; }
        #organization_rank_setting .viewGroup .email { width: 30%; }
        #organization_rank_setting .viewGroup .phonenum { width: 16%; }
        #organization_rank_setting .viewGroup .group { width: 17%; }
        #organization_rank_setting .viewGroup .pos { width: 15%; }
        #organization_rank_setting .viewGroup .approver{ width: 15%; }

        #container #section_ce #organization_rank_setting.pos_close.dept_close .section_scroll {min-width: 100%;}
        #organization_rank_setting.pos_close.dept_close .viewGroup .title { width: 21%; }
        #organization_rank_setting.pos_close.dept_close .viewGroup .email { width: 21%; }
        #organization_rank_setting.pos_close.dept_close .viewGroup .phonenum { width: 16%; }
        #organization_rank_setting.pos_close.dept_close .viewGroup .group { width: 13%; }
        #organization_rank_setting.pos_close.dept_close .viewGroup .pos { width: 13%; }
        #organization_rank_setting.pos_close.dept_close .viewGroup .approver{ width: 15%;}

        #container #section_ce #organization_rank_setting.dept_close .section_scroll { min-width: 0%; max-width: 100%;}
        #organization_rank_setting.dept_close .viewGroup .title { width: 21%; }
        #organization_rank_setting.dept_close .viewGroup .email { width: 21%; }
        #organization_rank_setting.dept_close .viewGroup .phonenum { width: 16%; }
        #organization_rank_setting.dept_close .viewGroup .group { width: 13%; }
        #organization_rank_setting.dept_close .viewGroup .pos { width: 13%; }
        #organization_rank_setting.dept_close .viewGroup .approver{ width: 15%;}

        #container #section_ce #organization_rank_setting.pos_close .section_scroll {min-width: 0%; max-width: 85%;}
        #organization_rank_setting.pos_close .viewGroup .title { width: 21%; }
        #organization_rank_setting.pos_close .viewGroup .email { width: 21%; }
        #organization_rank_setting.pos_close .viewGroup .phonenum { width: 16%; }
        #organization_rank_setting.pos_close .viewGroup .group { width: 13%; }
        #organization_rank_setting.pos_close .viewGroup .pos { width: 13%; }
        #organization_rank_setting.pos_close .viewGroup .approver{ width: 15%;}

        #organization_rank_setting .approval_content .section_scroll .viewGroup .fixedDiv li.approver{padding-left: 20px; box-sizing: border-box;}
        #organization_setting .content .notiListUl > li p.approver{ padding-left: 20px; }
        #organization_rank_setting div#organization_emp_scroll ul p.pos select {
            width: 95%;
            font-weight: bold;
            -webkit-transition: 0.2s;
            transition: 0.2s;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 5px;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            background-color: #fff;
            background-position: 95% center;
            background-repeat: no-repeat;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #fff;
            }
        #organization_rank_setting div#organization_emp_scroll ul p.approver select {
            width: 95%;
            font-weight: bold;
            -webkit-transition: 0.2s;
            transition: 0.2s;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 5px;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            background-color: #fff;
            background-position: 95% center;
            background-repeat: no-repeat;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #fff;
            }

    }

    #organization_rank_setting .content_top .refresh { border-radius: 50%; position : absolute; right : 18px; top : 12.5px; font-size:0; width : 40px; height : 40px; background : url('../../assets/images/contents/ic_repeat_bk.png') no-repeat center center; }
    .left_area_close #organization_rank_setting .content_top .refresh { right: 80px; }
    #organization_rank_setting .content_top .refresh:hover  { background-color:#f1f3f5; }

    #organization_rank_setting p.btn_group { margin-right: 50px; }
</style>