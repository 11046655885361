<template>
    <div id="request_setting_scroll" class="reservation_line">
        <div class="reservation_setting">
            <div class="reLineSet">
                <select name="aptBuilding" id="aptBuilD" v-model="selected_building">
                    <option :key="building.building" :value="building.building" v-for="building in buildings">{{ building.building }}동</option>
                    <!-- <option value="101동" selected="selected">101동</option>
                    <option value="102동">102동</option>
                    <option value="103동">103동</option>
                    <option value="104동">104동</option>
                    <option value="105동">105동</option>
                    <option value="106동">106동</option>
                    <option value="107동">107동</option>
                    <option value="108동">108동</option>
                    <option value="201동">201동</option>
                    <option value="202동">202동</option>
                    <option value="203동">203동</option>
                    <option value="204동">204동</option>
                    <option value="205동">205동</option>
                    <option value="206동">206동</option>
                    <option value="301동">301동</option>
                    <option value="302동">302동</option> -->
                </select>
                <!-- 저장 할 경우 readyCopySet 클래스 추가하여 설정복사 버튼을 활성화 한다 -->
                <!-- 설정 복사를 눌렀을 경우 새로고침 하기 전까지 tipping 클래스로 변경하여 붙여넣기 버튼을 활성화 한다 (redayCopySet을 원래는 없애기로 했으나 복사를 다시 하고 싶을수 있기 때문에 내버려둠) -->
                <div class="lineSetBtn fr readyCopySet" :class="{
                    tipping : copy_lines.length > 0
                }">
                    <input type="button" value="붙여넣기" class="input_btn tippingSetBtn" @click.prevent="pasteLines"> 
                    <input type="button" value="설정 복사" class="input_btn copySetBtn" @click.prevent="copyLines">
                    <input type="button" value="저장" class="input_btn saveSetBtn" @click.prevent="save" :disabled="isSaveable() == false">
                </div>
            </div>

            <ul>
                <li :key="index" v-for="(line, index) in computedLines">
                    <p><span>{{ index + 1 }}</span> 라인명칭</p>
                    <div class="inputTextBox lineTextBox">
                        <input type="text" id="linetext" name="linetext" placeholder="구분할 명칭을 적어주세요. ex) 1~3호 라인" 
                               :value="line.line_data.name" @input="line.line_data.name = $event.target.value" />
                    </div>
                    <div class="inputTextBox builTextBox">
                        <p>호수</p>
                        <input type="text" id="builtext" name="builtext" placeholder="00"
                               :value="line.line_data.start_unit" @input="line.line_data.start_unit = $event.target.value">
                        <span> ~ </span>
                        <input type="text" id="builtext" name="builtext" placeholder="00"
                               :value="line.line_data.end_unit" @input="line.line_data.end_unit = $event.target.value">
                    </div>
                    <a href="#" class="delBtn" @click.prevent="removeLine(computedLines, line)">삭제</a>
                    <!-- <a href="#" class="amendBtn">수정</a> -->
                </li>
                <!-- <li>
                    <p><span>1</span> 라인명칭</p>
                    <div class="inputTextBox lineTextBox">
                        <input type="text" id="linetext" name="linetext" placeholder="구분할 명칭을 적어주세요. ex) 1~3호 라인">
                    </div>
                    <div class="inputTextBox builTextBox">
                        <p>호수</p>
                        <input type="text" id="builtext" name="builtext" placeholder="00">
                        <span> ~ </span>
                        <input type="text" id="builtext" name="builtext" placeholder="00">
                    </div>
                    <a href="#" class="delBtn">삭제</a>
                    <a href="#" class="amendBtn">수정</a>
                </li>
                <li>
                    <p><span>2</span> 라인명칭</p>
                    <div class="inputTextBox lineTextBox">
                        <input type="text" id="linetext" name="linetext" placeholder="구분할 명칭을 적어주세요. ex) 1~3호 라인">
                    </div>
                    <div class="inputTextBox builTextBox">
                        <p>호수</p>
                        <input type="text" id="builtext" name="builtext" placeholder="00">
                        <span> ~ </span>
                        <input type="text" id="builtext" name="builtext" placeholder="00">
                    </div>
                    <a href="#" class="delBtn">삭제</a>
                    <a href="#" class="amendBtn">수정</a>
                </li>
                <li>
                    <p><span>3</span> 라인명칭</p>
                    <div class="inputTextBox lineTextBox">
                        <input type="text" id="linetext" name="linetext" placeholder="구분할 명칭을 적어주세요. ex) 1~3호 라인">
                    </div>
                    <div class="inputTextBox builTextBox">
                        <p>호수</p>
                        <input type="text" id="builtext" name="builtext" placeholder="00">
                        <span> ~ </span>
                        <input type="text" id="builtext" name="builtext" placeholder="00">
                    </div>
                    <a href="#" class="delBtn">삭제</a>
                    <a href="#" class="amendBtn">수정</a>
                </li> -->
                <!-- 라인 추가 -->
                <li class="addTimeSel addLine">
                    <p class="add_icon">추가 이미지</p>
                    <input type="button" value="라인 추가" class="addTimeBtn addLineBtn" @click.prevent="add">
                </li>
            </ul>

        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class ResidentMoveIntoRequestLineSetting extends Mixins(VueHoduCommon) {

    /**
     * 라인
     */
    get computedLines() : any[] {

        let lines : any[] = [];

        if( this.selected_building != null && this.selected_building.length > 0 ) {
            const filter = this.buildings.filter(building => building.building == this.selected_building);
            if( filter.length > 0 ) {
                lines.splice(0, lines.length);
                lines = lines.concat(filter[0].lines);
            }
        }

        return lines;
    }

    buildings : any[] = [];
    selected_building : string = "";

    original_lines : any[] = [];
    copy_lines : any[] = [];

    mounted() : void {
        this.getBuildings();
    }

    /**
     * 동 조회
     */
    async getBuildings() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/building`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.buildings ) {
                throw new Error("동 조회 중 오류 발생");
            }

            this.buildings.splice(0, this.buildings.length);
            this.buildings = this.buildings.concat(response.data.data.buildings);

            if( this.selected_building.length < 1 && this.buildings.length > 0 ) {
                this.selected_building = this.buildings[0].building;
            }

            this.$nextTick(() => {
                this.original_lines = JSON.parse(JSON.stringify(this.computedLines));
            });

        } catch(e) {
            this.hodu_show_dialog("cancel", "동 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 라인 복사
     */
    copyLines() : void {
        this.copy_lines.splice(0, this.copy_lines.length);
        this.copy_lines = this.copy_lines.concat(this.computedLines);
    }

    /**
     * 라인 붙여넣기
     */
    pasteLines() : void {
        this.computedLines.splice(0, this.computedLines.length);
        for( const line of this.copy_lines ) this.computedLines.push(line);
        this.$forceUpdate();
    }

    /**
     * 라인 삭제
     */
    removeLine(computedLines, line) : void {
        computedLines.splice(computedLines.indexOf(line), 1);
        this.$forceUpdate();
    }

    /**
     * 라인 추가
     */
    add() : void {
        this.computedLines.push({
            building : this.selected_building,
            group_id : this.scope_group_id,
            line_data : {
                start_unit : "",
                end_unit : "",
                name :"",
            },
            line_id :"",
            line_seq : this.computedLines.length + 1
        });
        this.$forceUpdate();
    }

    /**
     * 저장 활성화 여부 (하나라도 달라진게 있는 경우에만)
     */
    isSaveable() : boolean {
        return JSON.stringify(this.computedLines) != JSON.stringify(this.original_lines);
    }

    /**
     * 저장
     */
    async save() : Promise<void> {
        
        const valid_list : any[] = [];
        for( const line of this.computedLines ) {
            valid_list.push({
                "index" : this.computedLines.indexOf(line),
                "start" : Number(line.line_data.start_unit),
                "end" : Number(line.line_data.end_unit)
            });
        }
        for( const line of this.computedLines ) {
            const index = this.computedLines.indexOf(line);
            const start = Number(line.line_data.start_unit);
            const end = Number(line.line_data.end_unit);

            const valid_check = valid_list.filter(valid => valid.index != index &&
                                                           ((valid.start <= start && start <= valid.end) ||
                                                           (valid.start <= end && end <= valid.end)));

            if( line.line_data.name == null || line.line_data.name.trim().length < 1 ) {
                this.hodu_show_dialog("alert", `${ index + 1 }번째 라인의 이름을 입력해주세요`, ['확인']);
                return;
            }

            if( valid_check.length > 0 ) {
                this.hodu_show_dialog("alert", `${ index + 1 }번째 라인의 호수가 다른 라인의 호수와 겹치는 구간이 존재합니다`, ['확인']);
                return;
            }
        }

        this.hodu_show_dialog("alert", "설정 변경시 현재 예약된 입주 예약이 전부 삭제 됩니다\n정말로 설정을 변경하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    const response = await this.hodu_api_call(`socket_api/v1/home/move_into/line`, API_METHOD.POST, {
                        group_id : this.scope_group_id,
                        building : this.selected_building,
                        lines : this.computedLines,
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("입주 예약 라인 설정 중 오류 발생");
                    }

                    this.hodu_show_dialog("success", '입주 예약 라인 설정 완료', ['확인'], [() => {
                        this.getBuildings();
                    }]);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "입주 예약 라인 설정 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                } 
            }
        ]);
    }

    @Watch("selected_building")
    changeBuilding() {
        this.$nextTick(() => {
            this.original_lines = JSON.parse(JSON.stringify(this.computedLines));
        });
    }

}
</script>

<style scoped>
    .moveinSettingBox .settingCon { position: relative; box-sizing: border-box; min-height: 795px; overflow-y: auto; }
    .moveinSettingBox .settingCon h2 { font-size: 15px; padding: 0 15px; }
    .moveinSettingBox .settingCon ul { overflow: hidden; }
    .moveinSettingBox .settingCon ul li { width: 100%; line-height: 60px; border: 1px solid #f1f3f5; border-radius: 10px; padding: 0 15px; margin-top: 20px; box-sizing: border-box; font-weight: bold; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; position: relative; }
    .moveinSettingBox .settingCon ul li p { width: 10%; position: relative; display: inline-block; color: #4f536d; }
    .moveinSettingBox .settingCon ul li p:before { content: ''; width: 2px; height: 30px; background: #a7a9b6; position: absolute; top: 16px; right: 0; }
    .moveinSettingBox .settingCon ul li .selBox { width: auto; display: inline-block; padding: 0 35px; }
    .moveinSettingBox .settingCon ul li .selBox select { font-weight: bold; transition: 0.2s; -webkit-appearance: none; -moz-appearance: none; appearance: none; border-radius: 5px; height: 30px; line-height: 30px; cursor: pointer; background-color: #fff; background-position: 95% center; background-repeat: no-repeat; box-sizing: border-box; border: 1px solid #f1f3f5; padding: 0 50px 0 10px; background-image: url('../../assets/images/side_area/bt_open.png'); margin-bottom: 3px; margin-left: 10px; } 
    .moveinSettingBox .settingCon ul li .selBox select:hover { background-color: #f1f3f5; }
    .moveinSettingBox .settingCon ul li.addTimeSel { position: relative; text-align: center; }
    .moveinSettingBox .settingCon ul li.addTimeSel:hover { background: #f1f3f5; }
    .moveinSettingBox .settingCon ul li.addTimeSel p { width: 14px; height: 14px; font-size: 0; background: url('../../assets/images/contents/ic_plus_new.png')no-repeat center; position: absolute; top: 23px; left: 50%; margin-left: -50px; opacity: .7; }
    .moveinSettingBox .settingCon ul li.addTimeSel p:before { display: none; }
    .moveinSettingBox .settingCon .addTimeBtn { cursor: pointer; margin: 0; padding: 0; width: 100%; display: block; padding: 20px 0; background: none; font-weight: bold; color: #9596a1; text-indent: 26px; transition: .2s; }

    .moveinSettingBox .settingCon .reservation_line .reLineSet { overflow: hidden; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet select { height: 40px; line-height: 40px; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn .copySetBtn { display: none; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn.readyCopySet .copySetBtn { display: inline-block; margin-right: 20px; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn .tippingSetBtn { display: none; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn.tipping .tippingSetBtn { display: inline-block; margin-right: 20px; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox { display: inline-block; width: 40%; box-sizing: border-box; padding: 0 35px; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox.lineTextBox { border-right: 1px solid #f1f3f5; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox input[type=text] { width: 100%; padding: 5px; border-radius: 5px; border: 1px solid #f1f3f5; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox input#builtext { width: 40px; text-align: center; margin: 0 15px; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox p:before { display: none; }

    .moveinSettingBox .settingCon ul li a { padding: 0 15px; background: #ff6363; color: #fff; border-radius: 15px; position: absolute; top: 50%; margin-top: -13px; right: 80px; height: 25px; line-height: 25px; opacity: 0; }
    .moveinSettingBox .settingCon .reservation_line a.amendBtn { background: #477fff; right: 20px; }
    .moveinSettingBox .settingCon ul li:hover a { opacity: 1; }
    .moveinSettingBox .settingCon ul li:hover a:hover { background: #ff3a00; }
    .moveinSettingBox .settingCon ul li:hover a.amendBtn:hover { background: #004bff; }
    
    .reservation_setting { margin : 20px 30px; }
    .input_btn.saveSetBtn:disabled { cursor: default; }
</style>