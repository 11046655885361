<template>
    <div id="common_date_picker_modal" class="modal">
        
        <div class="titleBox">
            <h1 class="borderbot">날짜 선택</h1>
        </div>

        <div class="modal_content">
            <HoduInlineDatePicker
                :event_bus="event_bus" 
                :dp_id="'common_datepicker'" 
                :dp_class="'datepickerDiv'"
                :default_date="common_datepicker_modal_info.default_date ? common_datepicker_modal_info.default_date : new Date()"
                :other_month_selectable="true"
                :changeYear="true"
                @select="select"
            /> 
        </div>

        <div class="btns">
            <input type="button" value="취소" @click="close">
            <input type="button" class="save" value="저장" @click.prevent="save">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonDatepickerModalInfo } from '@/store/modules/ModalInfo';

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HoduInlineDatePicker
    },
})
export default class CommonDatepickerModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State common_datepicker_modal_info !: CommonDatepickerModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetCommonDatepickerModalInfo ?: (params : CommonDatepickerModalInfo) => void;

    event_bus : Vue = new Vue();
    
    selected_date : Date = new Date();

    mounted() : void {
        this.selected_date = this.common_datepicker_modal_info.default_date ? this.common_datepicker_modal_info.default_date : new Date();
    }

    /**
     * 날짜 선택
     */
    select(date_string : string , data : any) : void {
        this.selected_date = new Date(date_string);
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetCommonDatepickerModalInfo?.({ show_modal : false });
    }

    /**
     * 저장
     */
    save() : void {
        this.common_datepicker_modal_info.callback?.(this.selected_date);
        this.close();
    }

}
</script>

<style>
    #common_date_picker_modal .datepickerDiv { width: 100%; height: 368px; padding: 25px 25px; box-sizing: border-box; text-align: center; }
    #common_date_picker_modal .datepickerDiv .ui-datepicker-prev, .ui-datepicker-next {font-size:0;display: inline-block;width: 25px;height: 25px;text-align: center;cursor: pointer;background-image: url('../../../assets/images/contents/month_bt_prev.svg');background-repeat: no-repeat;line-height:25px;background-size: 22px;overflow: hidden;}
    /* 210225 */
    #common_date_picker_modal .datepickerDiv .ui-datepicker-prev {float:left;background-position: center center; position: absolute; right: 130px; top: 0;}
    #common_date_picker_modal .datepickerDiv .ui-datepicker-next { float:right; background-position: center center; position: absolute; right: 0; top: 0;}
    #common_date_picker_modal .datepickerDiv .ui-datepicker-prev:hover {background-image: url('../../../assets/images/contents/month_bt_prev_on.svg');}
    #common_date_picker_modal .datepickerDiv .ui-datepicker-next:hover {background-image: url('../../../assets/images/contents/month_bt_next_on.svg');}
    #common_date_picker_modal .datepickerDiv a.ui-datepicker-next {margin-left:5px;background-image: url('../../../assets/images/contents/month_bt_next.svg');}
    #common_date_picker_modal .datepickerDiv .yyyy { font-size: 16px; font-weight:bold; margin-bottom: 20px; margin-top:6px; display: inline-block; }

    #common_date_picker_modal .calUl .day li { height: 20px; line-height: 20px; }
    #common_date_picker_modal .calUl .dayList { height:20px; }
    #common_date_picker_modal .calUl > li { display: block; height: 40px;line-height: 40px; }
    #common_date_picker_modal .calUl ul  { overflow: hidden; }
    #common_date_picker_modal .calUl ul li { float:left; text-align: center; display: inline-block; width:14.2857142857%;}
    #common_date_picker_modal .calUl ul li a { font-weight: bold; display:inline-block; width: 30px; height:30px;line-height: 30px; transition:0.2s; border-radius:50%;vertical-align: middle; }
    #common_date_picker_modal .calUl ul li a:hover { background:#f1f3f5; }
    #common_date_picker_modal .calUl ul li a.on { background:#477fff !important; color:#fff; }
    #common_date_picker_modal .calUl ul li a.nm { opacity:0.5 }
    #common_date_picker_modal .calUl ul li a.off { opacity:0.3; font-weight: normal; }
    #common_date_picker_modal .calUl ul li a.off:hover { background:transparent }

    /* 210225 */
    #common_date_picker_modal select.ui-datepicker-year {font-size: 16px; color: #232848; font-weight: bold; margin: 0 81px 5px 0; cursor: pointer; -webkit-appearance: none; box-sizing: border-box; background: url(/img/bt_open.0927a4a6.png) no-repeat 45px center; width: 85px; height: 30px; }
    /* #common_date_picker_modal .ui-datepicker-title {margin-top: -10px; width: 100%; } */
    #common_date_picker_modal .ui-datepicker-title { color: transparent; }
</style>

<style scoped>

    #common_date_picker_modal { position: absolute; left: 50%; top: 50%; width: 100%; height: 513px; margin-left: -160px; margin-top: -256.5px; max-width: 320px; background: #fff; border-radius: 5px; }

    #common_date_picker_modal.modal .titleBox { width : 100%; overflow:hidden;border-bottom: 0;padding: 25px 0;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea; text-align: center;}
    #common_date_picker_modal.modal .titleBox h1 {font-size: 18px;}

    #common_date_picker_modal.modal .modal_content { height : 368px; }

    #common_date_picker_modal.modal .btns { width : 100%; overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px }
    #common_date_picker_modal.modal .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    #common_date_picker_modal.modal .btns input.save:hover { color:#477fff; }
    #common_date_picker_modal.modal .btns input:hover { background:#f1f3f5; }

</style>