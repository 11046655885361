<template>
    <div id="hodu_premium_popup" class="modal">
        
        <div class="modal_content">
            <a href="#" class="popup_image" @click.prevent="goHoduPremiumPayment" />
        </div>

        <div class="btns">
            <input type="button" value="닫기" @click="close">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { HoduPremiumPopupInfo } from '@/store/modules/ModalInfo';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {

    },
})
export default class HoduPremiumPopup extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHoduPremiumPopupInfo ?: (params : HoduPremiumPopupInfo) => void;

    mounted() : void {

    }

    goHoduPremiumPayment() {
        let url : string = '//hodu.app/#/payment';
        let win : any = window.open(url, '_blank');
        win.focus();
    }

    /**
     * 모달 닫기
     */
    close() : void {
        hodu_local_storage.setItem("hodu_premium_popup_time", String(new Date().getTime()));
        this.doSetHoduPremiumPopupInfo?.({ show_modal : false });
    }

}
</script>

<style scoped>

    #hodu_premium_popup { position: absolute; left: 50%; top: 50%; width: 100%; height: 431px; margin-left: -160px; margin-top: -215.5px; max-width: 320px; background: #fff; border-radius: 5px; }

    #hodu_premium_popup.modal .titleBox { width : 100%; overflow:hidden;border-bottom: 0;padding: 25px 0;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea; text-align: center;}
    #hodu_premium_popup.modal .titleBox h1 {font-size: 18px;}

    #hodu_premium_popup.modal .modal_content { height : 356px; }
    #hodu_premium_popup.modal .popup_image { display : block; width : 100%; height : 100%; border-top-left-radius: 5px; border-top-right-radius: 5px; background-image: url("../../../assets/images/contents/upgrade_popup_premiumplus.png"); background-size : cover; }

    #hodu_premium_popup.modal .btns { width : 100%; overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px }
    #hodu_premium_popup.modal .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:100%; float: left; font-size:14px; font-weight: bold;; }
    #hodu_premium_popup.modal .btns input:hover { color:#477fff; background:#f1f3f5; }

    /* 이미지 9:10 비율 */

</style>