<template>
    <div id="attendance" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <!-- <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a> -->
            <h3 id="viewTitle" class="title_doc">근태 현황</h3>

            <!-- 클릭시 새창에 PDF를 열어주세요. 근태현황은 21페이지부터 열리게 해주세요 -->
            <span class="ussM_box"><a href="app_images/hoduWorks_manual02.pdf#page=21" target="_blank" rel=noopener title="사용 안내서를 확인해주세요" class="use_manual">?</a></span>

            <select class="fr input_btn" @change="changeCalendar($event.target.value)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                <option value="TOTAL_LIST_YEAR" :selected="calendar_view_type == 'TOTAL_LIST_YEAR'" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">연별 통합 리스트</option>
                <option value="TOTAL_LIST_MONTH" :selected="calendar_view_type == 'TOTAL_LIST_MONTH'" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">월별 통합 리스트</option>
                <option value="DAYGRID_MONTH" :selected="calendar_view_type == 'DAYGRID_MONTH'" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">월별 달력</option>
                <option value="LIST_MONTH" :selected="calendar_view_type == 'LIST_MONTH'" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">월별 리스트</option>
                <option value="LIST_WEEK" :selected="calendar_view_type == 'LIST_WEEK'" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">주별 리스트</option>
                <option value="LIST_DAY" :selected="calendar_view_type == 'LIST_DAY'" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">일별 리스트</option>
                <!-- <option value="CALENDAR" :selected="calendar_view_type == 'CALENDAR'">{{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '내 현황 달력' : '달력' }}</option> -->
                <option value="LIST" :selected="calendar_view_type == 'LIST'">{{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '내 현황 리스트' : '리스트' }}</option>
            </select>
        </div>

        <AttendanceTotalListYear :event_bus="event_bus" v-if="isTotalListYear() == true" />
        <AttendanceTotalListMonth :event_bus="event_bus" v-if="isTotalListMonth() == true" />
        <AttendanceDayGridMonth :event_bus="event_bus" @changeCalendar="changeCalendar" v-if="isDayGridMonth() == true" />
        <AttendanceListMonth :event_bus="event_bus" v-if="isListMonth() == true" />
        <AttendanceListWeek :event_bus="event_bus" v-if="isListWeek() == true" />
        <AttendanceListDay :event_bus="event_bus" v-if="isListDay() == true" />
        <AttendanceMyCalendar :event_bus="event_bus" v-if="isMyCalendar() == true" />
        <AttendanceMyList :event_bus="event_bus" v-if="isMyList() == true" />

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import AttendanceTotalListYear from '@/components/attendance/AttendanceTotalListYear.vue';
import AttendanceTotalListMonth from '@/components/attendance/AttendanceTotalListMonth.vue';
import AttendanceDayGridMonth from '@/components/attendance/AttendanceDayGridMonth.vue';
import AttendanceListMonth    from '@/components/attendance/AttendanceListMonth.vue';
import AttendanceListWeek     from '@/components/attendance/AttendanceListWeek.vue';
import AttendanceListDay      from '@/components/attendance/AttendanceListDay.vue';
// import AttendanceMyCalendar   from '@/components/attendance/AttendanceMyCalendar.vue';
import AttendanceMyList       from '@/components/attendance/AttendanceMyList.vue';

import { attendance_enum } from '@/model/attendance';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        AttendanceTotalListYear, AttendanceTotalListMonth,
        AttendanceDayGridMonth, AttendanceListMonth, AttendanceListWeek, AttendanceListDay, /* AttendanceMyCalendar, */ AttendanceMyList,
        ResizeObserver
    },
})
export default class Attendance extends Mixins(VueHoduCommon) {

    /**
     * @AttendanceInfo.State
     */
    @AttendanceInfo.State calendar_view_type !: attendance_enum.ATTENDANCE_CALENDAR_TYPE;

    /**
     * @AttendanceInfo.Action
     */
    @AttendanceInfo.Action doSetCalendarViewType ?: (params : attendance_enum.ATTENDANCE_CALENDAR_TYPE) => void;

    event_bus : Vue = new Vue();

    mounted() : void {
        
    }

    /**
     * 연별 통합 리스트
     */
    isTotalListYear() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.TOTAL_LIST_YEAR;
    }

    /**
     * 월별 통합 리스트
     */
    isTotalListMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.TOTAL_LIST_MONTH;
    }

    /**
     * 월 달력
     */
    isDayGridMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.DAYGRID_MONTH;
    }

    /**
     * 월별 리스트
     */
    isListMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_MONTH;
    }

    /**
     * 주별 리스트
     */
    isListWeek() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_WEEK;
    }

    /**
     * 일별 리스트
     */
    isListDay() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_DAY;
    }

    /**
     * 내 현황 달력
     */
    isMyCalendar() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.CALENDAR;
    }

    /**
     * 내 현황 리스트
     */
    isMyList() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST;
    }

    /**
     * 달력 변경
     */
    changeCalendar(calendar_type : attendance_enum.ATTENDANCE_CALENDAR_TYPE) : void {
        this.doSetCalendarViewType?.(calendar_type);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        if( this.isDayGridMonth() == true ) this.event_bus.$emit('daygridMonthResize');
        else if( this.isListDay() == true ) this.event_bus.$emit('listDayResize');
        else if( this.isListWeek() == true ) this.event_bus.$emit('listWeekResize');
        else if( this.isListMonth() == true ) this.event_bus.$emit('listMonthResize');
        else if( this.isMyCalendar() == true ) this.event_bus.$emit('myCalendarResize');
        else if( this.isMyList() == true ) this.event_bus.$emit('myListResize');
        else if( this.isTotalListYear() == true ) this.event_bus.$emit('totalListYearResize');
        else if( this.isTotalListMonth() == true ) this.event_bus.$emit('totalListMonthResize');
    }

}
</script>

<style>
    #attendance .schedule_box .refresh { position : absolute; right : 18px; top : 12.5px; width : 40px; height : 40px; background : url('../../assets/images/contents/ic_repeat_bk.png') no-repeat center; }
    #container #section_ce.left_area_close .section_ce_fix  div#calendar { padding-left : 61px; }
    #container #section_ce.left_area_close #attendance div#calendar { padding-left :0; }
</style>

<style scoped>
    .title_doc { margin-left: 8px; }
    #container #section_ce.left_area_close #attendance #attendance_day_grid_month { padding-left : 61px; }
    .title_box .input_btn { cursor: pointer; margin-top:10px; padding-right: 35px !important; appearance: none; -webkit-appearance: none;background: url('../../assets/images/side_area/bt_open.png') no-repeat 90% center !important } 
    .title_box .input_btn:focus { background-image: url('../../assets/images/side_area/bt_close.png') !important; }
</style>