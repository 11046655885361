<template>
    <div id="approval_approver_favorite_modal" class="modal">
        <table style="width:100%;padding:0;border:0;border-spacing:0px;border-collapse:collapse;">
            <thead>
                <tr style="line-height:60px;border-bottom:1px solid #e7e9ea;font-size:18px;text-align:left;">
                    <th colspan="3" style="padding:15px 0 0 30px;width:70%;color:rgb(083, 084, 111)">
                        결재자 즐겨찾기 설정
                    </th>
                    <th style="padding:15px 0 0 20px;border-left:1px solid #e7e9ea;color:rgb(083, 084, 111)">
                        선택된 결재자
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- 결재선 이름이 중복이 있을 경우 on추가-->
                <tr class="check_text" :class="{ on : is_duplicate == true }">
                    <td colspan="4" style="width: 100%; height: 30px; line-height: 30px;border-right:1px solid #e7e9ea;border-bottom:1px solid #e7e9ea; color: #ff5757;">
                        <p>* 지정한 이름을 가진 결재선이 이미 있습니다. 다른 이름을 지정해주세요. </p>
                    </td>
                </tr>

                <tr>
                    <td colspan="3" style="height: 50px;border-right:1px solid #e7e9ea;border-bottom:1px solid #e7e9ea;">
                        <div class="favoriteName">
                            <p>결재선 이름</p>
                            <input type="text" id="favorite_name" class="favorite_name" :value="favorite_name" @input="favorite_name = $event.target.value">
                        </div>
                    </td>
                    <td class="team_chk">
                        <div class="approverTapDiv">
                            <a class="approver_tap" :class="{ active : tab == 'APPROVER' }" @click.prevent="tab = 'APPROVER'">결재자</a>
                            <a class="reference_tap" :class="{ active : tab == 'RECEIVE_REFERENCE' }" @click.prevent="tab = 'RECEIVE_REFERENCE'">수신참조</a>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td style="width:30%;height:57px;border-right:1px solid #e7e9ea;border-bottom:1px solid #e7e9ea;">
                        <div class="title_Chk first">
                            <p class="titleLink">부서</p>
                        </div>
                    </td>
                    <td colspan="2" class="team_chk">
                        <div class="title_Chk" style="position: relative;">
                            <span style="display: inline-block;font-size: 14px;">{{ selected_department.dept_id == -1 ? '전체' : getDepartmentName(selected_department.dept_id) }}</span>
                            <p style="display: inline-block;position: absolute;top: 17px;right: 20px;"><span :style="{ color : getSelectedCheck() == 0 ? 'rgb(210, 211, 217)' : '#477fff' }">{{ getSelectedCheck() }}</span> / {{ computedEmployee.length }}</p>
                        </div>
                    </td>
                    <td class="team_chk" id="select_bg" v-if="computedSelected != null" :style="computedSelected == null || computedSelected.length < 1 ? `` : `background : none;`">
                        <div class="title_Chk" style="position: relative;">
                            <span style="display: inline-block;font-size: 14px;">{{ computedSelected.length }}명</span>
                            <a href="#" style="display: inline-block;position: absolute;top: 17px;right: 20px;font-size: 14px;" @click.prevent="deleteAllSelected">모두삭제</a>                            
                        </div>
                    </td>
                </tr>
                <tr class="detailTr">
                    <td style="width:30%;height:322px;border-right:1px solid #e7e9ea;border-bottom:1px solid #e7e9ea;">
                        <div id="wrapper">
                            
			                <!-- 메인  -->
                            <div class="chk" :class="{ click_on : selected_department.dept_id == -1 }" @click="select({ dept_id : -1 })">
                                <p>전체</p>
                            </div>

                            <div class="chk" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                                <p>{{ computedOrganization != null ? computedOrganization.dept_name : '' }}</p>
                            </div>

                            <!-- <div class="chk">
                                <p>호두웨어</p>
                            </div> -->

                            <OrganizationRecursion 
                                :level="2"
                                :content_type="'p'" 
                                :departments="computedOrganization.departments"
                                :selected_department="selected_department"
                                :department_closed_map="department_closed_map"
                                :department_height_map="department_height_map" 
                                @select="select"
                                v-if="computedOrganization != null" />

			                <!-- 첫번쩨 ul (그룹 리스트) -->
                            <ul class="sub1" v-if="false">
                                <li class="fList">
                                    <!-- 상단 삐져나오는 선 가리개 -->
                                    <span class="topBlank"></span>
                                    <!-- 하단 삐져나오는 선 가리개 -->
                                    <span class="btmBlank"></span>
                                    <!-- 길이에 따라 길어지는 세로 선 -->
                                    <span class="vertiLine"></span>
                                    <!-- 화살표 버튼 -->
                                    <input type="button" class="arw" />
                                    <!-- cld : checkbox list div -->
                                    <div class="cld check_btn">
                                        <p>호두웨어</p>
                                    </div>

                                    <!-- 두번째 ul (팀 리스트) -->
                                    <ul class="sub2">
                                        <li>
                                            <!-- horiLine 가로선 -->
                                            <span class="horiLine"></span>
                                            <div class="cld check_btn">
                                                <p>개발</p>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="horiLine"></span>
                                            <div class="cld check_btn">
                                                <p>디자인</p>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="horiLine"></span>
                                            <div class="cld check_btn">
                                                <p>기획</p>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="fList">
                                    <!-- 상단 삐져나오는 선 가리개 -->
                                    <span class="topBlank"></span>
                                    <!-- 하단 삐져나오는 선 가리개 -->
                                    <span class="btmBlank"></span>
                                    <!-- 길이에 따라 길어지는 세로 선 -->
                                    <span class="vertiLine"></span>
                                    <!-- 화살표 버튼 -->
                                    <input type="button" class="arw" />
                                    <!-- cld : checkbox list div -->
                                    <div class="cld">
                                        <p>경영지원</p>
                                    </div>
                                    <ul class="sub2">
                                        <li>
                                            <!-- horiLine 가로선 -->
                                            <span class="horiLine"></span>
                                            <div class="cld">
                                                <p>회계</p>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="horiLine"></span>
                                            <div class="cld">
                                                <p>인사</p>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="fList">
                                    <div class="cld">
                                        <p>마케팅</p>
                                    </div>
                                </li>
                            </ul>

                            <!-- <div class="chk" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                                <p style="font-size: 13px; font-weight: bold; left: 55px; color: #477fff; opacity: .8;">미배정</p>
                            </div> -->
		                </div>  
                    </td>

                    <td colspan="2" class="team_chk">
                        <div style="width: 318px; height: 322px; overflow: auto; white-space: nowrap;"> 
                            <ul class="teamlist" style="height : auto; overflow : hidden; display: inline-block;">

                                <li :key="employee.user_id" v-for="employee of computedEmployee">
                                    <input type="checkbox" :id="`teamChk${employee.user_id}`" @change="changeSelected($event, employee)"
                                           :checked="approverChecked(employee)"/>
                                    <label :for="`teamChk${employee.user_id}`" style="margin-top:5px;margin-right: 15px;"></label>
                                    <p class="profile">
                                        <img :src="getProfileImage(employee)" style="width: 35px;" @error="userImageError($event)">
                                    </p>
                                    <div class="team_text">
                                        <p class="myname">{{ employee.user_name }} ({{ getPositionName(employee.pos_id) }})</p>
                                        <p class="teamname">{{ getDepartmentName(employee.dept_id) }}</p>
                                    </div>
                                </li>

                                <!-- <li>
                                    <input type="checkbox" id="teamChk01"/>
                                    <label for="teamChk01" style="margin-top:5px;margin-right: 15px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text">
                                        <p class="myname">이호두 (대표)</p>
                                        <p class="teamname">호두웨어</p>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" id="teamChk02" checked/>
                                    <label for="teamChk02" style="margin-top:5px;margin-right: 15px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text">
                                        <p class="myname">정관리 (팀장)</p>
                                        <p class="teamname">모바일 / 개발</p>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" id="teamChk03"/>
                                    <label for="teamChk03" style="margin-top:5px;margin-right: 15px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text">
                                        <p class="myname">장회기 (과장)</p>
                                        <p class="teamname">경영지원 / 회계</p>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" id="teamChk04"/>
                                    <label for="teamChk04" style="margin-top:5px;margin-right: 15px;"></label>
                                    <p class="profile"><img src="../../../assets/images/contents/ic_approval_user.png" style="width: 35px;" alt="" ></p>
                                    <div class="team_text">
                                        <p class="myname">우직원 (과장)</p>
                                        <p class="teamname">경영지원 / 인사</p>
                                    </div>
                                </li> -->

                            </ul>
                        </div>
                    </td>

                    <td class="team_chk" id="select_bg"  v-if="computedSelected != null" :style="computedSelected == null || computedSelected.length < 1 ? `` : `background : none;`">
                        <div style="width: 241px; height: 323px; overflow: auto; white-space: nowrap;" class="selected_show approverDiv" :class="{ on : tab == 'APPROVER' }"> 
                            <ul class="select_member" style="height: auto; overflow: hidden; display: inline-block;">

                                <li class="btn_over" :key="`${emp.seq}-${emp.user_id}`" v-for="(emp, index) in computedSelected">
                                    <div class="order_updown">
                                        <a href="#" style="border-bottom: 1px solid #e7e9ea;" :disabled="index == 0" @click.prevent="up(emp)"></a>
                                        <a href="#" :disabled="index == (computedSelected.length - 1)" @click.prevent="down(emp)"></a>
                                    </div>
                                    <div class="right_team_layout">
                                        <!-- 결재자 순번 표시 추가수정 -->
                                        <p class="turnNum">{{ index + 1 }}</p>
                                        <p class="profile">
                                            <img :src="getProfileImage(emp)" style="width: 40px;" @error="userImageError($event)">
                                        </p>
                                        <div class="team_text">
                                            <p class="myname">{{ emp.user_name }} ({{ getPositionName(emp.pos_id) }})</p>
                                            <p class="teamname">{{ getDepartmentName(emp.dept_id) }}</p>
                                        </div>
                                    </div>
                                    <a href="#" class="approver-remove-btn" @click.prevent="remove(emp)">삭제</a>
                                </li>
                                
                                <!-- <li class="btn_over">
                                    <div class="order_updown">
                                        <a href="#" disabled="disabled" style="border-bottom: 1px solid rgb(231, 233, 234);"></a>
                                        <a href="#" disabled="disabled"></a>
                                    </div>
                                    <div class="right_team_layout">
                                        
                                        <p class="turnNum">1</p>
                                        <p class="profile"><img src="/img/ic_approval_user.769dd479.png" style="width: 40px;"></p>
                                        <div class="team_text">
                                            <p class="myname">정관리 (팀장)</p>
                                            <p class="teamname">모바일 / 개발</p>
                                        </div>
                                    </div>
                                    <a href="#" class="approver-remove-btn">삭제</a>
                                </li> -->
                                
                            </ul>
                        </div>

                        <div style="width: 241px; height: 323px; overflow: auto; white-space: nowrap;" class="selected_show referenceDiv" :class="{ on : tab == 'RECEIVE_REFERENCE' }"> 
                            <ul class="select_member" style="height: auto; overflow: hidden; display: inline-block;">

                                <li class="btn_over" :key="`${emp.seq}-${emp.user_id}`" v-for="(emp, index) in computedSelected">
                                    <div class="right_team_layout">
                                        <!-- 결재자 순번 표시 추가수정 -->
                                        <p class="turnNum">{{ index + 1 }}</p>
                                        <p class="profile">
                                            <img :src="getProfileImage(emp)" style="width: 40px;" @error="userImageError($event)">
                                        </p>
                                        <div class="team_text">
                                            <p class="myname">{{ emp.user_name }} ({{ getPositionName(emp.pos_id) }})</p>
                                            <p class="teamname">{{ getDepartmentName(emp.dept_id) }}</p>
                                        </div>
                                    </div>
                                    <a href="#" class="approver-remove-btn" @click.prevent="remove(emp)">삭제</a>
                                </li>

                                <!-- <li class="btn_over">
                                    <div class="order_updown">
                                        <a href="#" disabled="disabled" style="border-bottom: 1px solid rgb(231, 233, 234);"></a>
                                        <a href="#" disabled="disabled"></a>
                                    </div>
                                    <div class="right_team_layout">
                                        
                                        <p class="turnNum">1</p>
                                        <p class="profile"><img src="/img/ic_approval_user.769dd479.png" style="width: 40px;"></p>
                                        <div class="team_text">
                                            <p class="myname">정회기 (과장)</p>
                                            <p class="teamname">경영지원 / 회계</p>
                                        </div>
                                    </div>
                                    <a href="#" class="approver-remove-btn">삭제</a>
                                </li> -->
                                
                            </ul>
                        </div>

                    </td>
                </tr>
                <tr style="line-height: 70px;">
                    <td colspan="4">
                        <a href="#" class="btn_modal" @click.prevent="close">취소</a>
                        <a href="#" class="btn_modal" @click.prevent="save">저장</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { approval_modal_interface } from '@/model/approval';

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

@Component({
    components: {
        OrganizationRecursion
    },
})
export default class ApprovalApproverFavoriteModal extends Mixins(VueHoduCommon) {

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        for( const employee of employees ) employee.approver_type = 'EMPLOYEE';

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            // 같은 직급은 이름순
            if( o1_pos_seq == o2_pos_seq ) {
                if( o1_name > o2_name ) return 1;
                else if( o1_name < o2_name ) return -1;
                return 0
            }
            else if( o1_pos_seq > o2_pos_seq ) return  1;
            else if( o1_pos_seq < o2_pos_seq ) return -1;

            return 0;
        });

        return employees.filter(item => item.user_id != this.user_id && item.pos_id != null && item.dept_id != null);
    }

    /**
     * 선택된 것
     */
    get computedSelected() : any[]  {

        let selected : any[] = [];

        let temp_selected : any[] = [];
        if( this.tab == 'APPROVER' ) {
            temp_selected = this.approval_approver_favorite_modal_info.approver ?? [];
        }
        else if( this.tab == 'RECEIVE_REFERENCE' ) {
            temp_selected = this.approval_approver_favorite_modal_info.receive_reference ?? [];
        }

        selected = temp_selected;

        // 직급, 가나다순
        // selected = temp_selected.sort((o1, o2) : number => {

        //     const o1_name = o1.user_name;
        //     const o2_name = o2.user_name;

        //     const o1_pos_seq = this.getPositionSeq(o1.pos_id);
        //     const o2_pos_seq = this.getPositionSeq(o2.pos_id);

        //     // 같은 직급은 이름순
        //     if( o1_pos_seq == o2_pos_seq ) {
        //         if( o1_name > o2_name ) return 1;
        //         else if( o1_name < o2_name ) return -1;
        //         return 0
        //     }
        //     else if( o1_pos_seq > o2_pos_seq ) return  1;
        //     else if( o1_pos_seq < o2_pos_seq ) return -1;
            
        //     return 0;
        // });
        
        return selected;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State approval_approver_favorite_modal_info !: approval_modal_interface.ApprovalApproverFavoriteModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalApproverFavoriteModalInfo ?: (params : approval_modal_interface.ApprovalApproverFavoriteModalInfo) => void;

    departments : any[] = [];
    selected_department : any = { dept_id : -1 }; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];

    favorite_name : string = "";
    is_duplicate : boolean = false;

    tab : string = "APPROVER"; // APPROVER, RECEIVE_REFERENCE

    mounted() : void {
        // 버튼 클릭시 라인과 팀 보이기
        $(".arw").click(function(){
            // 리스트 인덱스 가져오기
            var getIndex = $(this).parent().index();
            
            // 버튼 클릭시 라인과 팀 보이기
            $(".fList").eq(getIndex).toggleClass("showTeam");

        });

        //결재자 수신참조 tab
        // $(".approverTapDiv a").on("click", function(){
        //     const num = $(".approverTapDiv a").index($(this));
        //     // 기존에 적용되어 있는 on class 삭제
        //     $(".approverTapDiv a").removeClass("active");
        //     $(".selected_show").removeClass("on");

        //     // 다음 요소 클릭시 on class 추가
        //     $('.approverTapDiv a:eq(' + num + ')').addClass("active");
        //     $('.selected_show:eq(' + num + ')').addClass("on");

        // });

        this.getTotalOrganizationData();
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
     async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();

            // 결재자 즐겨찾기 수정인 경우
            if( this.approval_approver_favorite_modal_info.favorite_id != null && this.approval_approver_favorite_modal_info.favorite_id.trim().length > 0 ) {
                await this.getApprovalApproverFavorite();
            }

        } catch(e) {
            alert("결재자 즐겨찾기에 필요한 데이터 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    async getApprovalApproverFavorite() {
        
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${this.approval_approver_favorite_modal_info.favorite_id}`, API_METHOD.GET);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.favorite ) {
                throw new Error("결재자 즐겨찾기 조회 오류");
            }

            const favorite = response.data.data.favorite;
            this.favorite_name = favorite.favorite_name;

        } catch(e) {
            throw e;
        }

    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 부서 이름 반환
     * level 1 : 최상위 부서 이름 반환
     * level 2 : 최상위 부서 바로 아래의 부서 이름 하나만 반환
     * level n (n > 2) : level 2 / level 3 / level 4 ... 
     */
    getDepartmentName(dept_id : number) : string {
        try {
            
            // if( dept_id == 0 ) return "미배정";

            let dept_name = "";
            const target = this.departments.filter(item => item.dept_id == dept_id)[0];

            let target_departments : any[] = [];
            for( const department of this.departments ) {
                if( target.dept_id_array.indexOf(department.dept_id) > -1 ) target_departments.push(department);
            }

            target_departments.sort((o1, o2) : number => {
                const o1_length = o1.dept_id_array.length;
                const o2_length = o2.dept_id_array.length;

                if( o1_length > o2_length ) return 1;
                else if( o1_length < o2_length ) return -1;
                return 0;
            });

            for( const department of target_departments ) {
                if( target_departments.length >= 2 && target_departments.indexOf(department) == 0 ) continue;

                if( dept_name.length > 0 ) dept_name += " / ";
                dept_name += department.dept_name;
            }
        
            return dept_name;
        } catch(e) {
            console.log(e);
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 선택된 부서에서 선택된 사람의 수 또는 선택된 직급 수
     */
     getSelectedCheck() : number {

        const ids : number[] = [];

        if( this.tab == 'APPROVER' ) {

            if( this.approval_approver_favorite_modal_info.approver == null ) return 0;

            for( const selected_emp of this.approval_approver_favorite_modal_info.approver ) {
                for( const emp of this.computedEmployee ) {
                    if( selected_emp.user_id == emp.user_id ) ids.push(selected_emp.user_id);
                }
            }
        }

        else if( this.tab == 'RECEIVE_REFERENCE' ) {

            if( this.approval_approver_favorite_modal_info.receive_reference == null ) return 0;

            for( const selected_emp of this.approval_approver_favorite_modal_info.receive_reference ) {
                for( const emp of this.computedEmployee ) {
                    if( selected_emp.user_id == emp.user_id ) ids.push(selected_emp.user_id);
                }
            }
        }

        return ids.length;
    }

    /**
     * 사람 선택
     */
     changeSelected(event, employee) : void {

        const checked : boolean = event.target.checked;

        if( this.tab == 'APPROVER' ) {

            if( this.approval_approver_favorite_modal_info.approver == null ) return;

            try {
                if( checked == true && this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id).length == 0 ) {
                    this.approval_approver_favorite_modal_info.approver.push(employee);
                }
                else if( checked == false ) {
                    while( this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id).length > 0 ) {
                        const target = this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id)[0];
                        this.approval_approver_favorite_modal_info.approver.splice(this.approval_approver_favorite_modal_info.approver.indexOf(target as any), 1);
                    }
                }
            } catch(e) {
                console.log(e);
            }
        }

        else if( this.tab == 'RECEIVE_REFERENCE' ) {

            if( this.approval_approver_favorite_modal_info.receive_reference == null ) return;

            try {
                if( checked == true && this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id).length == 0 ) {
                    this.approval_approver_favorite_modal_info.receive_reference.push(employee);
                }
                else if( checked == false ) {
                    while( this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id).length > 0 ) {
                        const target = this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id)[0];
                        this.approval_approver_favorite_modal_info.receive_reference.splice(this.approval_approver_favorite_modal_info.receive_reference.indexOf(target as any), 1);
                    }
                }
            } catch(e) {
                console.log(e);
            }
        }

        this.$forceUpdate();
    }

    approverChecked(employee) {

        if( this.tab == 'APPROVER' ) {

            if( this.approval_approver_favorite_modal_info.approver == null ) return false;

            return this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id).length > 0;

        }
        else if( this.tab == 'RECEIVE_REFERENCE' ) {

            if( this.approval_approver_favorite_modal_info.receive_reference == null ) return false;

            return this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id).length > 0;
        }

        return false;
    }

    /**
     * 모두삭제
     */
    deleteAllSelected() : void {
        if( this.tab == 'APPROVER' ) {
            this.approval_approver_favorite_modal_info?.approver?.splice(0, this.approval_approver_favorite_modal_info.approver.length);
        }
        else if( this.tab == 'RECEIVE_REFERENCE' ) {
            this.approval_approver_favorite_modal_info?.receive_reference?.splice(0, this.approval_approver_favorite_modal_info.receive_reference.length);
        }
        this.$forceUpdate();
    }

    /**
     * 순서 앞으로 당기기
     */
    up(select) : void {

        if( this.tab == 'APPROVER' ) {
            if( this.approval_approver_favorite_modal_info.approver == null ) return;

            const index = this.approval_approver_favorite_modal_info.approver.indexOf(select);
            const target_index = index - 1;
            const target_seq   = select.seq;
            
            const target = this.approval_approver_favorite_modal_info.approver[target_index];
            target.seq = target_seq;
            select.seq = select.seq - 1;

            this.approval_approver_favorite_modal_info.approver[target_index] = select;
            this.approval_approver_favorite_modal_info.approver[index] = target;
        }
        // 수신참조는 딱히 순서가 없음
        // else if( this.tab == 'RECEIVE_REFERENCE' ) {
        //     if( this.approval_approver_favorite_modal_info.receive_reference == null ) return;

        //     const index = this.approval_approver_favorite_modal_info.receive_reference.indexOf(select);
        //     const target_index = index - 1;
        //     const target_seq   = select.seq;
            
        //     const target = this.approval_approver_favorite_modal_info.receive_reference[target_index];
        //     (target as any).seq = target_seq;
        //     select.seq = select.seq - 1;

        //     this.approval_approver_favorite_modal_info.receive_reference[target_index] = select;
        //     this.approval_approver_favorite_modal_info.receive_reference[index] = target;
        // }

        this.$forceUpdate();
    } 

    /**
     * 순서 뒤로 밀기
     */
    down(select) : void {

        if( this.tab == 'APPROVER' ) {
            if( this.approval_approver_favorite_modal_info.approver == null ) return;

            const index = this.approval_approver_favorite_modal_info.approver.indexOf(select);
            const target_index = index + 1;
            const target_seq   = select.seq;
            
            const target = this.approval_approver_favorite_modal_info.approver[target_index];
            target.seq = target_seq;
            select.seq = select.seq + 1;

            this.approval_approver_favorite_modal_info.approver[target_index] = select;
            this.approval_approver_favorite_modal_info.approver[index] = target;
        }
        // 수신참조는 딱히 순서가 없음
        // else if( this.tab == 'RECEIVE_REFERENCE' ) {
        //     if( this.approval_approver_favorite_modal_info.receive_reference == null ) return;

        //     const index = this.approval_approver_favorite_modal_info.receive_reference.indexOf(select);
        //     const target_index = index + 1;
        //     const target_seq   = select.seq;
            
        //     const target = this.approval_approver_favorite_modal_info.receive_reference[target_index];
        //     (target as any).seq = target_seq;
        //     select.seq = select.seq + 1;

        //     this.approval_approver_favorite_modal_info.receive_reference[target_index] = select;
        //     this.approval_approver_favorite_modal_info.receive_reference[index] = target;
        // }
    } 

    /**
     * 삭제
     */
    remove(employee) : void {

        if( this.tab == 'APPROVER' ) {

            if( this.approval_approver_favorite_modal_info.approver == null ) return;

            while( this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id).length > 0 ) {
                const target = this.approval_approver_favorite_modal_info.approver.filter(item => item.user_id == employee.user_id)[0];
                this.approval_approver_favorite_modal_info.approver.splice(this.approval_approver_favorite_modal_info.approver.indexOf(target as any), 1);                
            }
        }
        else if( this.tab == 'RECEIVE_REFERENCE' ) {

            if( this.approval_approver_favorite_modal_info.receive_reference == null ) return;

            while( this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id).length > 0 ) {
                const target = this.approval_approver_favorite_modal_info.receive_reference.filter(item => item.user_id == employee.user_id)[0];
                this.approval_approver_favorite_modal_info.receive_reference.splice(this.approval_approver_favorite_modal_info.receive_reference.indexOf(target as any), 1);                
            }
        }

        this.$forceUpdate();
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
     userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user.png');
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetApprovalApproverFavoriteModalInfo?.({ show_modal : false });
    }
    
    async save() {

        // 결재선 이름 검사
        try {

            if( this.favorite_name == null || this.favorite_name.trim().length < 1 ) {
                alert("이름을 입력해주세요");
                $('#favorite_name').focus();
                return;
            }

            if( this.approval_approver_favorite_modal_info.approver == null || this.approval_approver_favorite_modal_info.approver.length < 1 ) {
                alert("결재자를 선택해주세요");
                return;
            }

            let body : any = null;
            if( this.approval_approver_favorite_modal_info.favorite_id != null && this.approval_approver_favorite_modal_info.favorite_id.trim().length > 0 ) {
                body = {
                    "favorite_id" : this.approval_approver_favorite_modal_info.favorite_id
                }
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${this.favorite_name}`, API_METHOD.POST, body);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("결재자 즐겨찾기 이름 중복 검사 오류");
            }

            const favorite = response.data.data.favorite;

            if( favorite != null ) {
                this.is_duplicate = true;
                return;
            }

            this.approval_approver_favorite_modal_info.callback?.(this.favorite_name, this.approval_approver_favorite_modal_info.approver, this.approval_approver_favorite_modal_info.receive_reference);
            this.close();

        } catch(e) {
            alert("이름 중복검사 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }

        
    }

}
</script>

<style>
    #approval_approver_favorite_modal .click_on{z-index: 200;background: rgb(237, 242, 255, 0.8);}
    #approval_approver_favorite_modal .chk { height: 40px; line-height: 40px; text-align: left;position: relative; cursor: pointer; font-weight: bold; }
    #approval_approver_favorite_modal .cld { height: 35px; line-height: 35px;  text-align: left;position: relative; cursor: pointer; font-weight: bold;}
    /* 최하위 왼쪽 패딩 더 주기 */
    #approval_approver_favorite_modal .sub2 .cld { padding-left: 0px; text-align: left;margin-left: 0;box-sizing: border-box;}
    /*체크박스 디자인*/
    #approval_approver_favorite_modal .chk input{margin: 0;padding: 0;opacity: 0;width: 0;}
    #approval_approver_favorite_modal .approval_content input[type='checkbox']:not(old)+label{margin-top: 6px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid rgb(210, 211, 217);background: rgb(210, 211, 217) url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;margin-right: 8px;}
    #approval_approver_favorite_modal .approval_content input[type='checkbox']:not(old):checked + label{background-color: #477fff !important;border-color: #477fff;}
    /*체크박스 텍스트*/
    #approval_approver_favorite_modal .chk p{position: absolute;left: 35px;top: 0px;}
    #approval_approver_favorite_modal .cld p{position: absolute;left: 55px;top: 0px;}
    #approval_approver_favorite_modal .sub2 .cld p{position: absolute;left: 55px;top: 0px;}
    #approval_approver_favorite_modal ul.level4 .sub2 .cld p {left: 40px !important;}

    /* 그룹과 팀 리스트에 적용 */
    #approval_approver_favorite_modal ul li { transition:0.2s; position: relative;  }
    #approval_approver_favorite_modal ul.teamlist li:hover { background: #f6f9fb; }
    /* 버튼 */
    #approval_approver_favorite_modal .arw, #approval_approver_favorite_modal .arw2, #approval_approver_favorite_modal .arw3 { background: none;color: inherit;border: none;padding: 0;font: inherit;cursor: pointer;outline: inherit; z-index: 1000; position: absolute; left: 35px;top:13px; width: 11px; height: 11px; width: 0;height: 0; cursor: pointer;border-bottom: 6px solid transparent;border-top: 6px solid transparent;border-left: 8px solid #000;}
    #approval_approver_favorite_modal .level4 > li > .arw { top : 6px; }
    #approval_approver_favorite_modal .level4 > li.showTeam > .arw { top : 10px; }

    /* 상단 삐져나오는 라인 감추기 */
    #approval_approver_favorite_modal .topBlank { z-index: 100; position: absolute; top: 0; left:35px;width: 15px; height: 14px; background: #fff; display: block; }
    /* 하단 삐져나오는 라인 감추기 */
    #approval_approver_favorite_modal .btmBlank { z-index: 100; position: absolute; bottom: 1px;left:35px; width: 15px; height: 18px; background: #fff; display: block; }
    /* 세로 선 */
    #approval_approver_favorite_modal .vertiLine { height: 100%; width: 1px; background: #dadbde; display: none; position: absolute; left: 40px; top: 0; }
    /* 가로 선 */
    #approval_approver_favorite_modal .horiLine { width: 25px; height: 1px; background: #dadbde; display: none; position: absolute; top: 15px; left: 0px; }
    /* 최하위 그룹 디폴트로 감추기 */
    #approval_approver_favorite_modal .sub2 { display: none;}
    /* 팀 보일때 선이랑 다같이 보이기 */
    #approval_approver_favorite_modal .showTeam > .sub2 { display: block; padding-left: 40px;box-sizing: border-box;}
    #approval_approver_favorite_modal .showTeam .horiLine, #approval_approver_favorite_modal .showTeam .vertiLine { display: block; }
    #approval_approver_favorite_modal .showTeam > input.arw, #approval_approver_favorite_modal .showTeam > input.arw2, #approval_approver_favorite_modal .showTeam > input.arw3 {border-left: 6px solid transparent;border-right: 6px solid transparent;border-top: 8px solid #000;}
    #approval_approver_favorite_modal .circle{display: inline-block;width:10px;height:10px;margin-top: 10px;margin-left: 35px;border-radius: 50%;font-size: 0;border: 1px solid #40c8a0; background: #40c8a0;}
</style>

<style scoped>
    #approval_approver_favorite_modal.modal { text-align:center; width: 800px; max-height:610px; border-radius:20px; left: 50%;margin-left: -400px;top: 50%;margin-top: -290px;position: absolute;background: #fff; overflow: hidden; }

    .check_text > td { display: none; }
    .check_text.on > td { display: revert; }
    .favoriteName { line-height: 50px; }
    .favoriteName > p { display: inline-block; font-size: 13px; font-weight: bold; line-height: 30px; margin-right: 15px; }
    .favoriteName .favorite_name { transition: 0.2s; line-height: 30px; height: 30px; padding: 0 15px; border: 1px solid #e7e9ea; width: 70%; box-sizing: border-box; font-size: 14px; font-weight: bold; }
    .approverTapDiv { height: 50px; line-height: 49px; font-size: 14px; font-weight: bold; box-sizing: border-box; }
    .approverTapDiv > a { display: inline-block; width: 50%; }
    .approverTapDiv > a.active { border-bottom: 2px solid #477fff; }
    
    .title_Chk.first { overflow: hidden; }
    .title_Chk.first a { transition: 0.1s; opacity:0.5; display:block; width: 50%; font-size: 14px; font-weight: bold; float: left; line-height: 54px; box-sizing: border-box; }
    .title_Chk.first a:hover { opacity: 0.8; }
    .title_Chk.first a.on { border-bottom: 3px solid #4e4e4e; opacity: 1; }
    .title_Chk.first p { width : 100%; font-size: 14px; font-weight: bold; line-height: 57px; box-sizing: border-box; text-align: left; padding-left : 30px; }
    .sub1 .arw:after { position:absolute; z-index: 10000; content: ''; display: block; width: 50px; height: 50px; background: red; }
    .title_Chk.first p { width: 100%; font-size: 14px; font-weight: bold; line-height: 57px; box-sizing: border-box; text-align: left; padding-left: 30px; }
    .team_chk { border-right: 1px solid #e7e9ea; vertical-align: top; border-bottom: 1px solid #e7e9ea; }
    .team_chk div.title_Chk { background: #fff; line-height: 20px; font-size: 14px; text-align: left; padding: 18px 30px; box-sizing: border-box; font-weight: bold; padding-left: 28px; }
    .team_chk ul.teamlist li{ min-width: 258px; height:45px;text-align: left;padding: 13px 30px 0;border-bottom: 1px solid #e7e9ea; }
    .team_chk ul.teamlist li p.profile{ overflow: hidden;height: 35px;width: 35px;border-radius: 18px;display: inline-block;vertical-align: top; margin-top:-2px; }
    .team_chk ul.teamlist li div.team_text{ display: inline-block;margin-left: 10px;margin-top:1px }
    .team_chk ul.teamlist li div.team_text span{ color:#fff;background: #477fff; padding:5px 8px;border-radius: 3px;margin-right:5px;width: 25px;text-align: center;display: inline-block; }
    .team_chk ul.teamlist li div.team_text p.myname{ display: inline-block;font-size: 12px; font-weight: bold; }
    .team_chk ul.teamlist li div.team_text p.teamname{ margin-top: 8px;font-size: 12px;color:rgb(187, 187, 187); }
    .team_chk ul.teamlist{ height: 322px;overflow-y: auto;padding-bottom: 100px; }
    ::-webkit-scrollbar{ width: 6px; height : 6px; }
    ::-webkit-scrollbar-track{ background-color: transparent; }
    ::-webkit-scrollbar-thumb{ background-color: rgb(228, 226, 229);transition: 0.5s; }
    .btn_modal{ display: block;font-weight:bold;font-size: 15px;color:rgb(083, 084, 111); float : left; width : 50%; border-top : 1px solid #e7e9ea; box-sizing: border-box; }
    #select_bg { background: rgb(241, 243, 245, 0.5) url('../../../assets/images/contents/ic_team_selct.png') no-repeat center; background-size: 150px; opacity: 0.8; }

    #wrapper { padding: 0; padding-top : 0; width: 100%;height: 100%;box-sizing: border-box; overflow: auto; white-space: nowrap; }

    #approval_approver_favorite_modal .click_on{z-index: 200;background: rgb(237, 242, 255, 0.8);}
    #approval_approver_favorite_modal .chk { height: 40px; line-height: 40px; text-align: left;position: relative; cursor: pointer; font-weight: bold; }
    #approval_approver_favorite_modal .cld { height: 35px; line-height: 35px;  text-align: left;position: relative; cursor: pointer; font-weight: bold;}
    /* 최하위 왼쪽 패딩 더 주기 */
    #approval_approver_favorite_modal .sub2 .cld { padding-left: 0px; text-align: left;margin-left: 0;box-sizing: border-box;}
    /*체크박스 디자인*/
    .chk input{margin: 0;padding: 0;opacity: 0;width: 0;}
    #approval_approver_favorite_modal input[type='checkbox']:not(old)+label{margin-top: 6px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid rgb(210, 211, 217);background: rgb(210, 211, 217) url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;margin-right: 8px;}
    #approval_approver_favorite_modal input[type='checkbox']:not(old):checked + label{background-color: #477fff !important;border-color: #477fff;}
    #approval_approver_favorite_modal label:after { content:''; display: block; position:absolute; left:0; top:0; width:100%; height:100%; }
    /*체크박스 텍스트*/
    .chk label span{position: absolute;left: 30px;top: 9px;}
    .cld label span{position: absolute;left: 40px;top: 9px;}
    .sub2 .cld label span{position: absolute;left: 50px;top: 9px;}

    #approval_approver_favorite_modal .chk p {position: absolute;left: 35px;top: 0px;}
    #approval_approver_favorite_modal .cld p {position: absolute;left: 55px;top: 0px;}
    #approval_approver_favorite_modal .sub2 .cld p {position: absolute;left: 55px;top: 0px;}
    #approval_approver_favorite_modal ul.level4 .sub2 .cld p {left: 40px !important;}
    .chk { height: 35px; line-height: 35px; text-align: left; position: relative; }

    /* 그룹과 팀 리스트에 적용 */
    #approval_approver_favorite_modal ul li { transition:0.2s; position: relative;  }
    #approval_approver_favorite_modal ul.teamlist li:hover { background: #f6f9fb; }
    /* 버튼 */
    #approval_approver_favorite_modal .arw, #approval_approver_favorite_modal .arw2, #approval_approver_favorite_modal .arw3 { background: none;color: inherit;border: none;padding: 0;font: inherit;cursor: pointer;outline: inherit; z-index: 1000; position: absolute; left: 35px;top:13px; width: 11px; height: 11px; width: 0;height: 0; cursor: pointer;border-bottom: 6px solid transparent;border-top: 6px solid transparent;border-left: 8px solid #000; }
    #approval_approver_favorite_modal .level4 > li > .arw { top : 6px; }
    #approval_approver_favorite_modal .level4 > li.showTeam > .arw { top : 10px; }
    /* 상단 삐져나오는 라인 감추기 */
    #approval_approver_favorite_modal .topBlank { z-index: 100; position: absolute; top: 0; left:35px;width: 15px; height: 14px; background: #fff; display: block; }
    /* 하단 삐져나오는 라인 감추기 */
    #approval_approver_favorite_modal .btmBlank { z-index: 100; position: absolute; bottom: 1px;left:35px; width: 15px; height: 18px; background: #fff; display: block; }
    /* 세로 선 */
    #approval_approver_favorite_modal .vertiLine { height: 100%; width: 1px; background: #dadbde; display: none; position: absolute; left: 40px; top: 0; }
    /* 가로 선 */
    #approval_approver_favorite_modal .horiLine { width: 25px; height: 1px; background: #dadbde; display: none; position: absolute; top: 15px; left: 0px; }
    /* 최하위 그룹 디폴트로 감추기 */
    #approval_approver_favorite_modal .sub2 { display: none;}
    /* 팀 보일때 선이랑 다같이 보이기 */
    #approval_approver_favorite_modal .showTeam > .sub2 { display: block; padding-left: 40px;box-sizing: border-box;}
    #approval_approver_favorite_modal .showTeam .horiLine, #approval_approver_favorite_modal .showTeam .vertiLine { display: block; }
    #approval_approver_favorite_modal .showTeam > input.arw, #approval_approver_favorite_modal .showTeam > input.arw2, #approval_approver_favorite_modal .showTeam > input.arw3 { border-left: 6px solid transparent;border-right: 6px solid transparent;border-top: 8px solid #000; }

    .team_chk ul.select_member li{min-width : 241px; text-align: left; padding: 0; border-bottom: 1px solid #e7e9ea;background: #fff;box-sizing: border-box;height: 70px; transition: 0.1s;}
    /* .team_chk ul.select_member.ref li:hover { padding: 15px 30px; } */
    /* .team_chk ul.select_member li:hover{ padding-left: 57px; } */

    .team_chk ul.select_member li p.profile{overflow: hidden;height: 40px;width: 40px;border-radius: 50%;display: inline-block; position: absolute;top: 21%; margin-left: 15px;}
    .team_chk ul.select_member li div.team_text{display: inline-block;margin-left: 55px;margin-top: 6px;}
    .team_chk ul.select_member li div.team_text p.myname{font-size: 13px; margin-top: 8px; margin-left: 10px; font-weight: bold;}
    .team_chk ul.select_member li div.team_text p.teamname{margin-top: 10px; margin-left: 10px; font-size: 12px;color:rgb(187, 187, 187);}
    div.order_updown{ z-index : 1; display : none; position: absolute; float: left; height: 100%;box-sizing: border-box;width: 47px;border-right: 1px solid #e7e9ea; background: #fff;}
    div.order_updown a{height: 50% !important;box-sizing: border-box;padding: 0 !important;display: block;width: 47px;}
    div.order_updown a:first-child{background:  url('../../../assets/images/side_area/bt_close_b.png') no-repeat center center;}
    div.order_updown a:last-child {background:  url('../../../assets/images/side_area/bt_open_b.png') no-repeat center center;}
    div.order_updown a:first-child:hover {background:rgb(237, 242, 255) url('../../../assets/images/side_area/bt_close_b.png') no-repeat center center;}
    div.order_updown a:last-child:hover {background:rgb(237, 242, 255) url('../../../assets/images/side_area/bt_open_b.png') no-repeat center center;}

    .btn_over:hover .right_team_layout { opacity: 0.3;}
    .btn_over:hover div.order_updown { display : block; }
    
    .btn_over span{color:#fff;background: #477fff; padding:5px 8px;border-radius: 3px;margin-right:5px;width: 25px;text-align: center;display: inline-block;    margin-left: 62px;margin-bottom: -5px;}
    .btn_modal:hover{background: #f1f3f5;}

    .right_team_layout { position: relative; height : 100%; float: left; width: 75%; padding : 5px 0; box-sizing: border-box; }

    .team_chk ul.select_member li p.turnNum { display: inline-block; box-sizing: border-box; padding-left: 28px; font-weight: bold; vertical-align: top; margin-top: 24px; }

    .selected_show { display: none; }
    .selected_show.on { display: block; }

    .btn_over .approver-remove-btn { display : none; position: absolute; right : 10px; top : 24px; height: 25px; width: 25px; font-size: 0; background-image : url('../../../assets/images/contents/ic_close_new.png'); background-size: cover; background-repeat: no-repeat; }
    .btn_over:hover .approver-remove-btn { display : block; }
</style>