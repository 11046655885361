<template>
    <div id="organization_modal2" class="modal">
        <div class="dis member_set_2" id="organization_department_setting_modal">
        <h1>부서 선택</h1>
        <div class="approval_content">
            <div class="filter_tap">
                <div id="">
                    <!-- 부서 리스트 -->
                    <div id="orgaDepSetDiv_modal" class="">
                        <!-- 회사-->
                        <!-- 선택 시 main에 checked  -->

                        <div class="main" :class="{ checked : selected_department != null && computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }">

                            <p class="firmName">회사명</p>

                            <div class="team">
                                <p class="title">
                                    {{ computedOrganization == null ? '' : computedOrganization.dept_name }}
                                    <!-- <span class="num none">({{ computedOrganization == null ? '0' : computedOrganization.departments.length }})</span> -->
                                </p>
                            </div>

                            <!-- <div class="chooseBtnDiv">
                                <input type="button" :id="`hodu-department-check-${computedOrganization == null ? 0 : computedOrganization.dept_id}`" class="chosen" value="선택">
                                <label :for="`hodu-department-check-${computedOrganization == null ? 0 : computedOrganization.dept_id}`" class="chooseTeam" @click="select(computedOrganization)" 
                                    :class="{ on : selected_department != null && computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }">선택하기</label>
                            </div> -->

                        </div>

                        <div class="section_scroll">

                            <OrganizationRecursion2
                                :parent_vue="this"
                                :level="2"
                                :departments="computedOrganization.departments"
                                :selected_department="selected_department"
                                :department_closed_map="department_closed_map"
                                :is_select="true"
                                @select="select" 
                                v-if="computedOrganization != null"
                                />

                            <ul class="mainUl" v-if="false">
                                <!-- 부서 리스트 -->
                                <!-- 선택 시 list에 checked  -->
                                <li class="checked">
                                    <div class="grp">
                                        <input type="button" value="열고 닫기" />
                                        <label for="" class="leftBtn">열고 닫기</label>
                                        <div class="team"><p class="title">Pheonix Team <span class="num">(3)</span></p></div>
                                        <div class="chooseBtnDiv">
                                            <input type="button" class="chosen" value="선택">
                                            <label for="" class="chooseTeam on">선택하기</label>
                                        </div>
                                        <span class="line"></span>
                                    </div>
                                    <div class="grp">
                                        <ul>
                                            <li class="">
                                                <div class="grp">
                                                    <input type="button" value="열고 닫기" />
                                                    <label for="" class="leftBtn">열고 닫기</label>
                                                    <div class="team"><p class="title">개발팀 <span class="num">(2)</span></p></div>
                                                    <div class="chooseBtnDiv">
                                                        <input type="button" class="chosen" value="선택">
                                                        <label for="" class="chooseTeam">선택하기</label>
                                                    </div>
                                                    <span class="line"></span>
                                                </div>
                                                <div class="grp">
                                                    <ul>
                                                        <li>
                                                            <div class="grp">
                                                                <div class="noBtn"></div>
                                                                <div class="team"><p class="title">Android 개발팀 <span class="num">(0)</span></p></div>
                                                                <div class="chooseBtnDiv">
                                                                    <input type="button" class="chosen" value="선택">
                                                                    <label for="" class="chooseTeam">선택하기</label>
                                                                </div>
                                                                <span class="line"></span>
                                                            </div>
                                                        </li>
                                                        <li class="">
                                                            <div class="grp">
                                                                <input type="button" value="열고 닫기" />
                                                                <label for="" class="leftBtn">열고 닫기</label>
                                                                <div class="team"><p class="title">iOS 개발팀 <span class="num">(2)</span></p></div>
                                                                <div class="chooseBtnDiv">
                                                                    <input type="button" class="chosen" value="선택">
                                                                    <label for="" class="chooseTeam">선택하기</label>
                                                                </div>
                                                                <span class="line"></span>
                                                            </div>
                                                            <div class="grp">
                                                                <ul>
                                                                    <li>
                                                                        <div class="grp">
                                                                            <div class="noBtn"></div>
                                                                            <div class="team"><p class="title">기획팀 <span class="num">(0)</span></p></div>
                                                                            <div class="chooseBtnDiv">
                                                                                <input type="button" class="chosen" value="선택">
                                                                                <label for="" class="chooseTeam">선택하기</label>
                                                                            </div>
                                                                            <span class="line"></span>
                                                                        </div>
                                                                    </li>
                                                                    <li class="">
                                                                        <div class="grp">
                                                                            <div class="noBtn"></div>
                                                                            <div class="team"><p class="title">Development Team <span class="num">(0)</span></p></div>
                                                                            <div class="chooseBtnDiv">
                                                                                <input type="button" class="chosen" value="선택">
                                                                                <label for="" class="chooseTeam">선택하기</label>
                                                                            </div>
                                                                            <span class="line"></span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li class="on">
                                                <div class="grp">
                                                    <input type="button" value="열고 닫기" />
                                                    <label for="" class="leftBtn">열고 닫기</label>
                                                    <div class="team"><p class="title">영업팀 <span class="num">(1)</span></p></div>
                                                    <div class="chooseBtnDiv">
                                                        <input type="button" class="chosen" value="선택">
                                                        <label for="" class="chooseTeam">선택하기</label>
                                                    </div>
                                                    <span class="line"></span>
                                                </div>
                                                <div class="grp">
                                                    <ul>
                                                        <li>
                                                            <div class="grp">
                                                                <div class="noBtn"></div>
                                                                <div class="team"><p class="title">Design Team <span class="num">(0)</span></p></div>
                                                                <div class="chooseBtnDiv">
                                                                    <input type="button" class="chosen" value="선택">
                                                                    <label for="" class="chooseTeam">선택하기</label>
                                                                </div>
                                                                <span class="line"></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li class="on">
                                    <div class="grp">
                                        <input type="button" value="열고 닫기" />
                                        <label for="" class="leftBtn">열고 닫기</label>
                                        <div class="team"><p class="title">IRS Team <span class="num">(2)</span></p></div>
                                        <div class="chooseBtnDiv">
                                            <input type="button" class="chosen" value="선택">
                                            <label for="" class="chooseTeam">선택하기</label>
                                        </div>
                                        <span class="line"></span>
                                    </div>
                                    <div class="grp">
                                        <ul>
                                            <li>
                                                <div class="grp">
                                                    <div class="noBtn"></div>
                                                    <div class="team"><p class="title">개발팀 <span class="num">(0)</span></p></div>
                                                    <div class="chooseBtnDiv">
                                                        <input type="button" class="chosen" value="선택">
                                                        <label for="" class="chooseTeam">선택하기</label>
                                                    </div>
                                                    <span class="line"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="grp">
                                                    <div class="noBtn"></div>
                                                    <div class="team"><p class="title">심사팀 <span class="num">(0)</span></p></div>
                                                    <div class="chooseBtnDiv">
                                                        <input type="button" class="chosen" value="선택">
                                                        <label for="" class="chooseTeam">선택하기</label>
                                                    </div>
                                                    <span class="line"></span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li class="on">
                                    <div class="grp">
                                        <input type="button" value="열고 닫기" />
                                        <label for="" class="leftBtn">열고 닫기</label>
                                        <div class="team"><p class="title">Mobile Team <span class="num">(2)</span></p></div>
                                        <div class="chooseBtnDiv">
                                            <input type="button" class="chosen" value="선택">
                                            <label for="" class="chooseTeam">선택하기</label>
                                        </div>
                                        <span class="line"></span>
                                    </div>
                                    <div class="grp">
                                        <ul>
                                            <li>
                                                <div class="grp">
                                                    <div class="noBtn"></div>
                                                    <div class="team"><p class="title">Design Team<span class="num">(0)</span></p></div>
                                                    <div class="chooseBtnDiv">
                                                        <input type="button" class="chosen" value="선택">
                                                        <label for="" class="chooseTeam">선택하기</label>
                                                    </div>
                                                    <span class="line"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="grp">
                                                    <div class="noBtn"></div>
                                                    <div class="team"><p class="title">Development Team <span class="num">(0)</span></p></div>
                                                    <div class="chooseBtnDiv">
                                                        <input type="button" class="chosen" value="선택">
                                                        <label for="" class="chooseTeam">선택하기</label>
                                                    </div>
                                                    <span class="line"></span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>

                </div>

                <div class="btns">
                    <input type="button" value="취소" class="input_modal_btn" @click.prevent="close">
                    <input type="button" value="저장" class="input_modal_btn" @click.prevent="save">
                </div>

            </div>
        </div>
    </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import { organization_modal_interface } from '@/model/organization';
const ModalInfo = namespace('ModalInfo');

import OrganizationRecursion2 from '@/components/organization/OrganizationRecursion2.vue';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion2
    },
})
export default class OrganizationModal2 extends Mixins(VueHoduCommon) {

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_modal2_info !: organization_modal_interface.OrganizationModal2Info;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationModal2Info ?: (params : organization_modal_interface.OrganizationModal2Info) => void;

    departments : any[] = [];
    selected_department : any = null;

    department_closed_map : Map<number, boolean> = new Map();

    async mounted() : Promise<void> {
        await this.getOrganization();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            // @ts-ignore
            // $('#organization_modal2 .section_scroll').mCustomScrollbar({
            //     axis : 'y',
            //     scrollbarPosition : 'outside',
            //     mouseWheelPixels : 100,
            //     scrollInertia : 60,
            //     autoDraggerLength : false
            // });
        })
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }

            const data = response.data.data.dept_info;

            // 기존에 선택된 부서
            for(const department of data) {
                if( this.organization_modal2_info.dept_id == department.dept_id ) {
                    this.selected_department = department;
                    break;
                }
            }

            // 선택된 부서가 없다면 최상위 부서가 선택됨
            if( this.selected_department == null ) this.selected_department = data.filter(item => item.dept_id == 0)[0];

            const dept_ids_count = this.selected_department.dept_id_array.length;
            for( let i = (dept_ids_count - 1) - 1; i > 0; i-- ) {
                const target_dept_id = this.selected_department.dept_id_array[i];
                const target = data.filter(item => item.dept_id == target_dept_id);

                if( target.length > 0 ) {
                    this.department_closed_map.set(target[0].dept_id, false);
                }
            }

            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(data);

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 부서 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 선택
     */
    select(department) : void {
        console.log(department);
        this.selected_department = JSON.parse(JSON.stringify(department));
    }

    /**
     * 닫기
     */
    close() : void {
        this.doSetOrganizationModal2Info?.({ show_modal : false, dept_id : 0 });
    }

    /**
     * 저장
     */
    save() : void {
        this.organization_modal2_info.callback?.(this.selected_department);
        this.close();
    }

}
</script>

<style>
    #organization_modal2 { width: 800px; height: 600px; background:#fff; position:absolute; left: 50%; top:50%; margin-left: -400px; margin-top:-300px; border-radius: 5px; }

    #organization_modal2 h1 {font-size: 19px;padding: 0 30px;height: 85px;line-height: 100px;width: 100%;-webkit-box-sizing: border-box;box-sizing: border-box;border-bottom: 1px solid #f1f3f5;}

    #organization_department_setting_modal #orgaDepSetDiv_modal { height: 445px; }

    #organization_department_setting_modal #orgaDepSetDiv_modal .section_scroll { height: 365px; overflow: hidden; overflow-y:auto; }
    #organization_department_setting_modal #orgaDepSetDiv_modal div { position: relative; overflow: hidden; width:100%; }
    #organization_department_setting_modal .line { transition: 0.1s; position: absolute; bottom:0px; left: 0; width: 100%; height: 1px; display: block; background: #f1f3f5; }
    #organization_department_setting_modal .grp:hover > .line { background: #e2e6ea }
    #organization_department_setting_modal #orgaDepSetDiv_modal .mainUl { padding-left: 0; padding: 0 0 100px 20px; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li { position: relative; overflow: hidden; line-height: 55px; width: 100%; box-sizing: border-box; padding-left: 25px; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li > .title { opacity: 0.6; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li:hover > .title { opacity: 0.9; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li.checked > .title { opacity: 1; }

    #organization_department_setting_modal #orgaDepSetDiv_modal ul li a { display: inline-block; float: left; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li .team { width: auto; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .num { margin-left: 0; padding-left: 0; opacity: 0.5; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .title { opacity: 0.6; font-size: 14px; font-weight: bold; padding-right: 70px;overflow: hidden;text-overflow: ellipsis; white-space: nowrap; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .notTeam .title { opacity: .8; color: #477fff; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .main { padding: 0 30px !important; position: relative; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .main .title { font-size: 20px; opacity: 1; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .main .firmName { z-index: -1; font-size: 13px; position: absolute; top:-25px;left: 30px; font-weight: bold; opacity: 0.4; margin-right:20px; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .main { line-height: 96px; height: 80px; padding:0 0; box-sizing: border-box; border-bottom:1px solid #e7e9ea; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .main * { display: inline-block; }

    #organization_department_setting_modal #orgaDepSetDiv_modal input { display: none; }
    #organization_department_setting_modal #orgaDepSetDiv_modal label.leftBtn { cursor:pointer; font-size: 0; background: #fff; border:1px solid #2b3b5f; width: 18px; height: 18px; padding:0; border-radius: 3px; margin-left:0; margin-top:18px; margin-right: 20px; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .noBtn { float: left; display: inline-block; font-size: 0; background: #edeef1; border:1px solid #d5d8df; width: 18px; height: 18px; padding:0; border-radius: 3px; margin-left:0; margin-top:18px; margin-right: 20px; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li label.leftBtn { position: relative; display: inline-block; float: left; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li label.leftBtn:after {z-index: 100; left: 50%; top:50%; margin-left:-5px; margin-top: -1px; position: absolute; content: ''; display: block; width: 10px; height: 2px; background: #2b3b5f; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li label.leftBtn:before { display: none; z-index: 100; left: 50%; top:50%; margin-left:-1px; margin-top: -5px; position: absolute; content: ''; display: block; width: 2px; height: 10px; background: #fff; }
    #organization_department_setting_modal #orgaDepSetDiv_modal li.on > div.grp div.grp  { display: none; }
    #organization_department_setting_modal #orgaDepSetDiv_modal li.on > div.grp > label.leftBtn { background:#2b3b5f }
    #organization_department_setting_modal #orgaDepSetDiv_modal li.on > div.grp > label.leftBtn:after { background:#fff; } 
    #organization_department_setting_modal #orgaDepSetDiv_modal li.on > div.grp > label.leftBtn:before { background:#fff; } 

    #organization_department_setting_modal #orgaDepSetDiv_modal ul li > div.grp:hover > .chooseBtnDiv > label { opacity: 1; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .main .chooseBtnDiv { top:auto; bottom: 18px; }
    /* #organization_department_setting_modal #orgaDepSetDiv_modal .main:hover .title { opacity: 0.9; } */
    /* #organization_department_setting_modal #orgaDepSetDiv_modal .main:hover .chooseBtnDiv label { opacity: 1; } */
    #organization_department_setting_modal #orgaDepSetDiv_modal .checked .chooseBtnDiv .chooseTeam.on { opacity: 1; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .chooseBtnDiv { position: absolute; right:30px; top:0; width: auto;  }
    #organization_department_setting_modal #orgaDepSetDiv_modal .chooseBtnDiv input { display: none; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .chooseBtnDiv label { transition: 0.1s; opacity: 1; margin-top:12px; cursor: pointer; background:red; display: block; width: 30px; height: 30px; z-index: 1000; font-size: 0; background: #c1c9d4 url(../../../assets/images/contents/checked_fff.png) no-repeat center; border-radius: 50%; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .chooseBtnDiv label:hover { background-color:#7ca4da; }
    /* #organization_department_setting_modal #orgaDepSetDiv_modal .chooseBtnDiv label:after { z-index: 1000; content:''; display: block; position: absolute; background:red; right: -35px; top:0; width: 55px; height: 55px; } */
    #organization_department_setting_modal #orgaDepSetDiv_modal .chooseBtnDiv label.on { background-color:#477fff }
    #organization_department_setting_modal #orgaDepSetDiv_modal .main.checked .chooseBtnDiv label { opacity: 1; }
    #organization_department_setting_modal #orgaDepSetDiv_modal .main.checked .title { opacity:1; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li:hover > div.grp > div.team > .title { opacity: 0.9; }
    #organization_department_setting_modal #orgaDepSetDiv_modal ul li.checked > .grp > .team .title { opacity: 1 !important; }

    #organization_modal2.modal .btns { width:100%;padding:0; border-top:1px solid #f1f3f5; }
    #organization_modal2.modal .btns input { width : 50%; transition:0.2s; background:#fff; font-size:14px; font-weight:bold; height:70px; line-height:70px; box-sizing: border-box; padding:0; }
    #organization_modal2.modal .btns input:hover { background:#f1f3f5; }

    #organization_department_setting_modal ::-webkit-scrollbar{width: 6px;}
    #organization_department_setting_modal ::-webkit-scrollbar-track{background-color: transparent;}
    #organization_department_setting_modal ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

</style>