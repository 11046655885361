<template>
    <div id="visitant" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <!-- title_box 시작 -->
        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">방문차량</h3>
            <a class="schBtn" @click="searchOnOff">검색하기</a>
        </div>

        <!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
        <div class="searchDiv" :class="{ on : is_search_open == true }">
            <div class="schGrp">
                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                <input type="text" id="visitant_search_text" :value="search_word" placeholder="차량 번호로 검색하세요" @input="search_word = $event.target.value">
                <div class="btns">
                    <!-- 검색 디브 removeClass(on) -->
                    <a href="#" class="closeBtn" @click.prevent="searchOnOff">닫기</a>
                </div>
            </div>
        </div>

        <!-- <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">방문차량</h3>
            <div class="checkDiv fr">
                <input type="checkbox" id="autoApprove" />
                <label for="autoApprove"><span>자동 승인</span></label>
            </div>
        </div> -->
        <!-- title_box 끝 -->
        
        <!-- section_scroll -->
        <div class="section_scroll">
            <div class="viewGroup">
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <div class="posRel">
                            <div class="bg">
                                <div class="bigGrp left">
                                    <div class="selectDiv">
                                    <select @change="changeTargetBuilding($event.target.value)">
                                        <option value="TARGET_ALL">전체</option>
                                        <option :value="building.building" :key="index" v-for="(building, index) in buildings">{{ building.building }}동</option>
                                        <!-- <option value="">100 동</option>
                                        <option value="">101 동</option>
                                        <option value="">102 동</option> -->
                                    </select>
                                    <!-- <span class="seper vertiSeper"></span>
                                    <select name="" id="">
                                        <option value="" selected>전체</option>
                                        <option value="">1 호</option>
                                        <option value="">2 호</option>
                                        <option value="">3 호</option>
                                    </select> -->
                                    <span class="seper vertiSeper"></span>
                                    </div>
                                    <div class="grp">
                                        <label for="" class="from blind">시작일자</label>
                                        <input type="button" id="visitant_fr_ymd" :value="`${hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(start_date)}`" class="calFrom">
                                    </div>
                                    <span class="seper">에서</span>
                                    <div class="grp">
                                        <label for="" class="to blind">종료일자</label>
                                        <input type="button" id="visitant_to_ymd" :value="`${hodu_date_to_format_string(end_date, 'YYYY.MM.DD')} ${getDayOfWeekByDate(end_date)}`" class="calTo">
                                    </div>
                                    <input type="button" id="btnToday" value="오늘" class="toolbar-btn today input_btn" @click="clickToday">
                                </div>
                                <div class="rightBtnDiv fr">
                                    <input type="button" class="input_btn" value="모두 승인" @click="applyAllVisitantList" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="hoduhome_div" class="content">
                    <ul class="sortHeader workHeader">
                        <!-- <li class="checkBox">
                            <div class="checkDiv">
                                <input type="checkbox" />
                                <label for=""></label>
                            </div>
                        </li> -->
                        <li class="num">No</li>
                        <li class="time"><span>방문 날짜</span></li>
                        <li class="peep"><span>동&amp;호</span></li>
                        <!-- <li class="apt2 headerSort sortOff"><span>호수</span></li> -->
                        <li class="carNum"><span>차량번호</span></li>
                        <li class="status"><a class="sort">현황</a>
                            <div class="dropdown">
                                <ul >
                                    <li >
                                        <p>
                                            <input type="checkbox" id="status_check1" value="" class="input_check">
                                            <label for="status_check1"><span >대기</span></label>
                                        </p>
                                    </li>
                                    <li >
                                        <p >
                                            <input type="checkbox" id="status_check2" value="" class="input_check">
                                            <label for="status_check2"><span >요청</span></label>
                                        </p>
                                    </li>
                                    <li >
                                        <p >
                                            <input type="checkbox" id="status_check3" value="" class="input_check">
                                            <label for="status_check3"><span >승인</span></label>
                                        </p>
                                    </li>
                                    <li >
                                        <p >
                                            <input type="checkbox" id="status_check4" value="" class="input_check">
                                            <label for="status_check4"><span >방문</span></label>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>

                    <div class="noResult" :class="{ on : computedCarListByTargetBuilding.length < 1 }" v-if="computedCarListByTargetBuilding.length < 1">
                        <p>방문차량이 존재하지 않습니다</p>
                    </div>

                    <div id="hoduhome_list_scroll">
                        <ul class="hoduhomeUl">

                            <li :key="car.car_id" v-for="(car, index) in computedCarListByTargetBuilding">
                                <span class="new" v-if="car.car_info.is_new == true">new</span>
                                <!-- <div class="checkBox">
                                    <div class="checkDiv">
                                        <input type="checkbox" />
                                        <label for=""></label>
                                    </div>
                                </div> -->
                                <p class="num">{{ index + 1}}</p>
                                <p class="time">
                                    {{ getTimeText(car) }}
                                </p>
                                <p class="peep">{{ car.resident_info.building }}동 {{ car.resident_info.unit }}호</p>
                                <!-- <p class="apt2"></p> -->
                                <p class="carNum">{{ car.car_info.car_number }}</p>
                                <p class="status">
                                    <input type="button" class="apply" value="승인" :class="{ on : car.car_info.is_new == false && car.car_info.is_apply == true, admin_on : car.request == 'ADMIN' }" @click="applyVisitantCar(car)"
                                           v-if="new Date(car.end_date).getTime() > new Date().getTime() || ( new Date(car.end_date).getTime() < new Date().getTime() && (car.car_info.is_new == true || (car.car_info.is_new == false && car.car_info.is_apply == true) ) )" />
                                    <input type="button" class="reject" value="거절" :class="{ on : car.car_info.is_new == false && car.car_info.is_apply == false }" @click="rejectVisitantCar(car)"
                                           v-if="new Date(car.end_date).getTime() > new Date().getTime() || ( new Date(car.end_date).getTime() < new Date().getTime() && (car.car_info.is_new == true || (car.car_info.is_new == false && car.car_info.is_apply == false) ) )" />
                                </p>
                                <a href="#" class="seeDets" @click.prevent="showVisitantInfoModal(car)">자세히 보기</a>
                            </li>

                            <!-- <li>
                                <span class="new">new</span>
                                <p class="num">1</p>
                                <p class="time">20.06.15 월 ~ 17 수 (3일)</p>
                                <p class="peep">100 동</p>
                                <p class="apt2">1 호</p>
                                <p class="carNum">12가 3456</p>
                                <p class="status">
                                    <input type="button" class="apply" value="승인" />
                                    <input type="button" class="reject" value="거절" />
                                </p>
                                <a href="#" class="seeDets" @click.prevent="showVisitantInfoModal">자세히 보기</a>
                            </li>
                            <li>
                                <p class="num">2</p>
                                <p class="time">20.06.15 월 ~ 17 수 (3일)</p>
                                <p class="peep">100 동</p>
                                <p class="apt2">2 호</p>
                                <p class="carNum">12가 3456</p>
                                <p class="status">
                                    <input type="button" value="승인" />
                                    <input type="button" value="거절" />
                                </p>
                            </li>
                            <li>
                                <p class="num">3</p>
                                <p class="time">20.06.15 월 ~ 16 화 (2일)</p>
                                <p class="peep">100 동</p>
                                <p class="apt2">3 호</p>
                                <p class="carNum">12가 3456</p>
                                <p class="status">
                                    <input type="button" value="승인" />
                                    <input type="button" value="거절" />
                                </p>
                            </li>
                            <li>
                                <p class="num">4</p>
                                <p class="time">20.06.15 월 ~ 16 화 (2일)</p>
                                <p class="peep">100 동</p>
                                <p class="apt2">4 호</p>
                                <p class="carNum">12가 3456</p>
                                <p class="status">
                                    <input type="button" value="승인" />
                                    <input type="button" value="거절" />
                                </p>
                            </li>
                            <li>
                                <p class="num">5</p>
                                <p class="time">20.06.15 월 (1일)</p>
                                <p class="peep">100 동</p>
                                <p class="apt2">5 호</p>
                                <p class="carNum">12가 3456</p>
                                <p class="status">
                                    <a href="" class="">방문</a>
                                </p>
                            </li>
                            <li>
                                <p class="num">6</p>
                                <p class="time">20.06.15 월 ~ 18 목 (4일)</p>
                                <p class="peep">100 동</p>
                                <p class="apt2">6 호</p>
                                <p class="carNum">12가 3456</p>
                                <p class="status">
                                    <a href="" class="">방문</a>
                                </p>
                            </li>
                            <li>
                                <p class="num">7</p>
                                <p class="time">20.06.15 월 ~ 18 목 (4일)</p>
                                <p class="peep">100 동</p>
                                <p class="apt2">7 호</p>
                                <p class="carNum">12가 3456</p>
                                <p class="status">
                                    <a href="" class="red">거절</a>
                                </p>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>

            
        </div>  

        <input type="button" class="addBtn" value="추가" @click="showVisitantCreateModal">      

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const CalendarInfo = namespace('CalendarInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
}) export default class VisitantList extends Mixins(VueHoduCommon) {
    
    /**
     * 선택된 동의 리스트만 반환 
     */
    get computedCarListByTargetBuilding() : any[] {
        let final_list : any[] = JSON.parse(JSON.stringify(this.car_list));

        final_list = final_list.filter(item => new RegExp(escape(this.search_word)).test(escape(item.car_info.car_number)) == true);

        if( this.target_building == 'TARGET_ALL') {
            return final_list;
        }

        final_list = final_list.filter(item => item.resident_info.building == this.target_building);
        
        return final_list;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeVisitantCreateModalInfo ?: (params : hodu_home_modal_info.HomeVisitantCreateModalInfo) => void;
    @ModalInfo.Action doSetHomeVisitantInfoModalInfo ?: (params : hodu_home_modal_info.HomeVisitantInfoModalInfo) => void;
    
    /**
     * @CalendarInfo.State
     */
    @CalendarInfo.State go_to_date !: Date;              

    target_building : string = "TARGET_ALL";

    apartment_building_list : any[] = [];
    car_list : any[] = [];

    start_date : Date = new Date();
    end_date : Date = new Date();

    is_search_open : boolean = false;
    search_word    : string = "";
    get_visitant_interval : number | undefined;

    is_first_api_call : boolean = false;
    new_car_id_list : string[] = [];
    new_audio : HTMLAudioElement = new Audio(require('@/assets/audio/bell.mp3'));

    buildings : any[] = [];

    mounted() : void {
        const vue = this;
        
        $(".hoduhomeUl li a").click(function(){
            $(".modalDiv.hoduhome1").addClass("on");
            return false;
        });

        this.clickToday();

        // 5초마다 예약 재조회
        this.get_visitant_interval = window.setInterval(async() => {
            this.getBuildings();
            this.getHoduHomeInfo();
            this.getCarList();
        }, 5000);

        const datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
                inst.input.val(mnt.format('YYYY.MM.DD') + ' ' + vue.getDayOfWeekByDate(mnt.toDate()));
                
                // 시작일 변경
                if( id == 'visitant_fr_ymd' ) {
                    vue.start_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.end_date = moment(vue.start_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
                    }
                } 

                // 종료일 변경
                else if( id == 'visitant_to_ymd' ) {
                    vue.end_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.start_date = moment(vue.end_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                    }
                }

                vue.end_date = moment(vue.end_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

                vue.getBuildings();
                vue.getHoduHomeInfo();
                vue.getCarList();
            },
        }

        // @ts-ignore
        $('#visitant_fr_ymd').datepicker(datepicker_option);

        // @ts-ignore
        $('#visitant_to_ymd').datepicker(datepicker_option);

        window['getBuildings'] = this.getBuildings();
        window['getHoduHomeInfo'] = this.getHoduHomeInfo;
        window['getCarList'] = this.getCarList;

        this.setScroll();
    }

    /**
     * 종료 전 interval 제거
     */
    beforeDestroy() : void {
        if( this.get_visitant_interval ) { clearInterval(this.get_visitant_interval); }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hoduhome_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });
    }

    /**
     * 동 조회
     */
    async getBuildings() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/building`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.buildings ) {
                throw new Error("동 조회 중 오류 발생");
            }

            this.buildings.splice(0, this.buildings.length);
            this.buildings = this.buildings.concat(response.data.data.buildings);

        } catch(e) {
            this.hodu_show_dialog("cancel", "동 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 호두홈 정보 조회
     */
    async getHoduHomeInfo() : Promise<void> {
        
        await this.hodu_api_call(`api/v1/home/info/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null, false)
            .then((response) => {
                console.log(response);
                const home_info : any = response.data.data.home_info; 

                this.apartment_building_list.splice(0, this.apartment_building_list.length);

                if( home_info.building_info != null ) {
                    this.apartment_building_list = this.apartment_building_list.concat(home_info.building_info);
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })
    }

    /**
     * 차량 조회(방문차량)
     */
    getCarList() : void {

        const start = moment(this.start_date).utc().format(); 
        const end   = moment(this.end_date).utc().format();
 
        this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}?is_resident=false&start_date=${start}&end_date=${end}&is_web=true`, API_METHOD.GET, null, false)
            .then((response) => {
                console.log(response);

                this.car_list.splice(0, this.car_list.length);
                this.car_list = this.car_list.concat(response.data.data.car_list);

                let is_audio_play : boolean = false;
                for( const new_car of this.car_list.filter(item => item.car_info.is_new == true) ) {
                    if( this.new_car_id_list.indexOf(new_car.car_id) > -1 ) { continue; }

                    this.new_car_id_list.push(new_car.car_id);
                    is_audio_play = true;
                }

                // 알람 간격 정해서 넣을것
                if( this.is_first_api_call == true && is_audio_play == true ) {
                    this.new_audio.play();
                }

                if( this.is_first_api_call == false ) { this.is_first_api_call = true; }
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })
    }

    /**
     * 업데이트
     */
    async updateCarInfo(car : any) : Promise<void> {

        await this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${car.car_id}`, API_METHOD.PUT, car)
            .then((response) => {
                console.log(response);

                this.new_car_id_list.splice(this.new_car_id_list.indexOf(car.car_id), 1);
                this.getHoduHomeInfo();
                this.getCarList();
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });

    }

    /**
     * 검색창 열기 / 닫기
     */
    searchOnOff() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_word = "";
        }

        // 검색 창 열릴때 검색 창에 포커스 가도록
        else {
            this.$nextTick(() => { $('#visitant_search_text').focus(); });
        }
    }

    /**
     * 조회 대상 동을 변경
     */
    changeTargetBuilding(value : string) : void {
        this.target_building = value;
    }

    /**
     * 모두 승인
     */
    applyAllVisitantList() : void {
        
        const new_list = this.car_list.filter(item => item.car_info.is_new == true);

        if( new_list.length < 1 ) {
            this.hodu_show_dialog('alert', '해당 기간에는 신규 방문차량이 존재하지 않습니다', ['확인'])
            return;
        }

        this.hodu_show_dialog('alert', '신규 방문차량을 모두 승인 처리 하시겠습니까?', ['아니오', '예'], [
            () => {},
            () => {

                const promise_list : Promise<void>[] = [];
                for( const car of new_list ) {
                    promise_list.push(this.applyVisitantCar(car));
                }

                Promise.all(promise_list)
                    .then(() => {
                        this.new_car_id_list.splice(0, this.new_car_id_list.length);
                        this.getBuildings();
                        this.getHoduHomeInfo();
                        this.getCarList();
                        this.hodu_show_dialog('success', '신규 방문차량 모두 승인 완료', ['확인']);
                    })
                    .catch((e) => {
                        this.hodu_show_dialog('cancel', '신규 방문차량 모두 승인 중 오류 발생', ['확인']);
                    })
                
            }
        ])

    }

    /**
     * 차량 승인
     */
    async applyVisitantCar(car : any) : Promise<void> {
        // 새로운게 아니라면 날짜 지난거 반응 X
        if( car.car_info.is_new == false && new Date(car.end_date).getTime() < new Date().getTime() ) { return; }

        // 이미 승인된거는 반응 X
        if( car.car_info.is_new == false && car.car_info.is_apply == true ) { return; }

        car.car_info.is_new = false;
        car.car_info.is_apply = true;

        await this.updateCarInfo(car);
    }

    /**
     * 차량 거절
     */
    rejectVisitantCar(car : any) : void {
        // 새로운게 아니라면 날짜 지난거 반응 X
        if( car.car_info.is_new == false && new Date(car.end_date).getTime() < new Date().getTime() ) { return; }

        // 이미 거절된거는 반응 X
        if( car.car_info.is_new == false && car.car_info.is_apply == false ) { return; }

        car.car_info.is_new = false;
        car.car_info.is_apply = false;

        this.updateCarInfo(car);
    }

    /**
     * 오늘 클릭
     */
    clickToday() : void {
        this.start_date = moment().set('hour', 0)
                                  .set('minute', 0)
                                  .set('second', 0)
                                  .set('millisecond', 0).toDate();

        this.end_date = moment(this.start_date).set('hour', 23)
                                               .set('minute', 59)
                                               .set('second', 59)
                                               .set('millisecond', 999).toDate();

        this.getBuildings();
        this.getHoduHomeInfo();
        this.getCarList();
    }

    /**
     * 시간 텍스트 반환
     */
    getTimeText(car : any) : string {

        const start_date = new Date(new Date(car.start_date));
        const end_date = new Date(car.end_date);
        
        const start = `${this.hodu_date_to_format_string(start_date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;

        // 포맷 (같은 년월은 표시하지 않는다)
        let end_format = 'YY.MM.DD';
        if( start_date.getFullYear() == end_date.getFullYear() ) {
            end_format = 'MM.DD';

            if( start_date.getMonth() == end_date.getMonth() ) {
                end_format = 'DD';

                if( start_date.getDate() == end_date.getDate() ) {
                    end_format = '';
                }
            }
        }

        const end   = `${this.hodu_date_to_format_string(end_date, end_format)} ${this.getDayOfWeekByDate(end_date)}`;
        // const term = `(${this.getDateDiff(start_date, end_date) + 1}일)`
        const term = `(방문기간 ${this.getDateDiff(start_date, end_date) + 1}일)`

        // return `${start}${end_format == '' ? ' ' : ` ~ ${end} `}${term}`;
        return `${start} ${term}`;
    }

    /**
     * 방문 차량 생성 모달
     */
    showVisitantCreateModal() : void {
        if( this.doSetHomeVisitantCreateModalInfo == null ) { return; }

        this.doSetHomeVisitantCreateModalInfo({
            show_modal : true
        });
    }

    /**
     * 방문 차량 정보 모달
     */
    showVisitantInfoModal(car : any) : void {
        if( this.doSetHomeVisitantInfoModalInfo == null ) { return; }
        
        const send_car = JSON.parse(JSON.stringify(car));
        // send_car.resident_info.building = this.apartment_building_list.filter(item => item.team_id == car.resident_info.building)[0].team_info.team_name;

        this.doSetHomeVisitantInfoModalInfo({
            show_modal : true,
            car : send_car,
        });
    }

    /**
     * 왼쪽 inline datepicker 날짜 변경 감지
     */
    @Watch('go_to_date')
    onGoToDateChange() : void {
        this.start_date = moment(this.go_to_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
        this.end_date = moment(this.go_to_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

        this.getBuildings();
        this.getHoduHomeInfo();
        this.getCarList();
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#hoduhome_list_scroll").mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
</script>

<style scoped>
    .title_box .checkDiv { width: 85px; }
    /*.title_box .checkDiv label { border-radius: 25px; height: 30px; line-height: 30px;  width: auto; padding: 0 15px 0 35px; background-position: 5px center;background-size: 25px;margin-top: 1px;float: right; }
    .title_box .checkDiv input[type='checkbox']:not(old):checked +label {  background-position: 5px center;    background-size: 25px;  }*/
    .title_box .checkDiv label { margin-top: 19px; }
    .title_box .checkDiv span { display: inline-block;width: 50px;font-size: 12px;font-weight: bold;margin-left: 30px;line-height: 15px;height: 14px; color:#7c88a2;}
    .title_box .checkDiv input[type='checkbox']:not(old):checked +label span { color: #202A39; }

    .bigGrp select { position: static !important; float: left; font-size: 13px; font-weight: bold; line-height: 40px; height: 40px; background-color:#fff; cursor: pointer; padding-right: 25px; padding-left: 10px; appearance: none; -moz-appearance: none; -webkit-appearance: none;}
    .bigGrp select option { font-size: 14px; }
    .settingBg .left .grp input { width: 130px !important;min-width: 130px !important;padding-right: 0 !important; margin-right: 0 !important;}
    .settingBg .left .grp { max-width: 130px!important;min-width: 130px !important;margin-right: 10px !important;}
    .selectDiv { display: inline-block; float: left; }
    .selectDiv * { float: left; }
    .settingBg .seper { margin-top: 20px; }
    .settingBg .today {margin: 0 !important }
    .selectDiv .seper.vertiSeper { width: 1px; height: 15px;margin-top: 12px; margin-right: 20px; }
    .rightBtnDiv .input_btn { margin-top: 11px; margin-right: 25px;}

    .viewGroup .checkDiv { display: none; position: static !important; }
    .viewGroup .checkDiv label { margin-top: 16px; margin-left: 25px;}
    .sortHeader .checkDiv label { margin-top:21px; }

    
    .hoduhomeUl li { position: relative; transition:0.2s; overflow: hidden; width: 100%; line-height: 55px; height: 55px; box-sizing:border-box; border-bottom: 1px solid #f1f3f5; color:#202A39; }
    .hoduhomeUl li:hover { background:#f7f9fb; font-weight: bold; }
    .hoduhomeUl li > * { float: left; display: inline-block; }
    .hoduhomeUl li .status input, .hoduhomeUl li .status a { position: relative; z-index: 1000; float: left; margin-right: 10px;  box-sizing:border-box; width: 65px; text-align: center; display: inline-block; height: 28px; line-height: 26px; border-radius: 25px; background:#f1f3f5; font-weight: bold; margin-top: 12px; color: #7c88a2;border: 1px solid #e2e5e8; }
    .hoduhomeUl li .status input { background: #fff; }
    .hoduhomeUl li .status input:hover { background:#f1f3f5 }
    .hoduhomeUl li input.apply.on { background:#447fff !important; color:#fff; border-color: #2b6eff; cursor: default; }
    .hoduhomeUl li input.apply.on.admin_on { background:#13D08B !important; color:#fff; border-color: #13D08B; cursor: default; color : #fff; }
    .hoduhomeUl li input.reject.on { background:#ff6363 !important; color:#fff; border-color: #ff4242; cursor: default; }
    .hoduhomeUl li input.ylw { background: #ffc516; color: #202A39; border-color:#ffbc00;}
    .hoduhomeUl li input.red, .hoduhomeUl li a.red { background: #ff6363; color: #fff; border-color:#ff4242;}
    .hoduhomeUl li .status a:after { display: block; content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; }
    .hoduhomeUl li .new { position: absolute; display: block; top:20px; left: 20px; padding: 0 5px; height: 16px; line-height: 16px; color:#fff; font-size: 11px; font-weight: bold; border-radius: 3px; background:#ff6363;border: 1px solid #ff2121; }
    .seeDets { position: absolute; left: 0; top:0; font-size: 0; width:100%; height: 100%; display: block; }
    .section_ce_fix .checkBox { width: 7%; text-align: center; padding-right: 0; }
    .section_ce_fix .num { width: 13%; text-align: center;padding-right: 15px;box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap; }
    .section_ce_fix .apt2 { width: 12%; padding-right: 15px;box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;}
    .section_ce_fix .time { width: 23%; padding-right: 15px;box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;}
    .section_ce_fix .carNum { width: 18%; padding-right: 15px;box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;}
    .section_ce_fix .peep { width: 27%; padding-right: 15px;box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;}
    .section_ce_fix .status { width: 19%; }
    input[type='checkbox']:not(old) + label { padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;}
    input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;}

    
    .addBtn { right: 100px; bottom:40px; position: fixed; opacity: 1;z-index: 1000;transition: 0.2s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important; }
    .addBtn:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}

    .noResult.on { display:block; }
    .noResult { display:none; position: static; box-sizing: border-box; padding: 20px 50px;width: 100%;  }
    .noResult p { width:100%; border-radius: 5px; background:#f1f3f5; line-height: 80px; height: 80px; font-size: 13px; color: #9ea5b3;}
</style>