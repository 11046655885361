<template>
    <div id="organization_vacation_detail_modal" class="modal">
        
        <div class="titleBox">
            <!-- ing 대기, rejected 거절, approved 승인, canceled 취소 -->
            <h1 :class="{
                ing : approval != null && approval.approval_state == 'ING', 
                approved : approval != null && approval.approval_state == 'COMPLETE', 
                rejected : approval != null && approval.approval_state == 'REJECT',
                canceled : approval != null && approval.approval_state == 'CANCEL'
            }">
                <span class="icon"></span>{{ approval == null ? '' : getStatusText(approval) }}
            </h1>
        </div>

        <div class="content"> 
            <div class="detail" v-if="approval != null">
                <div class="context">
                    <ul class="detailUl">
                        <li class="">
                            <p class="title">요청일</p>
                            <p class="descript">{{ `${hodu_date_to_format_string(approval.audit_created, 'YYYY.MM.DD')} ${getDayOfWeekByDate(approval.audit_created)}` }}</p>
                        </li>
                        <li class="">
                            <p class="title">휴가 타입</p>
                            <p class="descript">{{ approval.contents.vacation_type_name }}</p>
                        </li>
                        <li class="">
                            <p class="title">휴가 일자</p>
                            <p class="descript" :title="getDateText(approval)">{{ getDateText(approval) }}</p>
                        </li>
                        <li class="">
                            <p class="title">휴가 수</p>
                            <p class="descript">{{ approval.contents.vacation_type_daycount != 0 ? `${processDayCount(approval.contents.vacation_type_daycount)} 일` : `${approval.contents.vacation_selected.length} 일` }}</p>
                        </li>
                        <!-- <li>
                            <p class="title">승인자</p>
                            <p class="descript" :title="approverNameList(approval)">{{ approverNameList(approval) }}</p>
                        </li> -->
                        <li class="reason">
                            <p class="title">사유</p>
                            <textarea class="descript" disabled v-model="approval.contents.comment"></textarea>
                        </li>
                    </ul>
                </div>
                <div class="rightContext">
                    <!-- <div class="subtitle">
                        승인자
                    </div> -->
                    <!-- <div id="organization_vacation_detail_modal_approver_scroll" class="scroll">
                        <ul>
                            <li :key="approver.user_id" v-for="approver in approval.approver.filter(item => item.user_id != user_id)">
                                <p class="img" :style="{ backgroundImage : `url(app_images/profile/user/${Math.floor(approver.user_id / 10000)}/${approver.user_id}.jpg)` }">사진</p>
                                <img class="dummy" :src="`app_images/profile/user/${Math.floor(approver.user_id / 10000)}/${approver.user_id}.jpg`" @error="userImageError($event)" />
                                <p class="name">{{ approver.user_name }}</p>
                                <p class="pos">{{ approver.pos_name }}</p>
                                <p class="team">{{ approver.dept_name }}</p>
                            </li> -->
                            <!-- <li>
                                <p class="img">사진</p>
                                <p class="name">김성림</p>
                                <p class="pos">인턴</p>
                                <p class="team">모바일 / 지누스</p>
                            </li>
                            <li>
                                <p class="img">사진</p>
                                <p class="name">김성림</p>
                                <p class="pos">인턴</p>
                                <p class="team">모바일 / 지누스</p>
                            </li>
                            <li>
                                <p class="img">사진</p>
                                <p class="name">김성림</p>
                                <p class="pos">인턴</p>
                                <p class="team">모바일 / 지누스</p>
                            </li>
                            <li>
                                <p class="img">사진</p>
                                <p class="name">김성림</p>
                                <p class="pos">인턴</p>
                                <p class="team">모바일 / 지누스</p>
                            </li> -->
                        <!-- </ul>
                    </div> -->



                    <!---결재현황 내용---->
                    <div class="approval_list scroll" id="organization_vacation_detail_modal_approver_scroll">
                        <ul class="box_line" style="padding:30px 30px 100px;font-size:15px;">

                            <li :key="`${approver.user_id}-${approver.rev}`" v-for="approver in computedApproverHistory">
                                <span class="tree_line" v-if="computedApproverHistory.indexOf(approver) > 0"></span>
                                
                                <!-- 본인 결재 차례라면 yes_line, 반려 처리되면 cancel_line -->
                                <div class="stand_line" :class="{ 
                                    yes_line : approval.approval_rev == approver.rev && approver.state == 'ING', 
                                    cancel_line : approval.approval_rev == approver.rev && approver.state == 'REJECT',
                                    past_cancel_line : approval.approval_rev != approver.rev && approver.state == 'REJECT',
                                    hover : approval.approval_rev == approver.rev,
                                }">
                                    <span class="text_stand">{{ getApproverStateText(approver.state) }}</span>
                                    <p class="on_day">
                                        {{ approver.date != null && approver.date != '' ? `${hodu_date_to_format_string(approver.date, 'YYYY.MM.DD') + " " + getDayOfWeekByDate(approver.date) + " " + amPmStringToLocaleAmPmString(hodu_date_to_format_string(approver.date, 'hh:mm a'))}` : '' }}
                                    </p>
                                    <!-- <p class="hover_menu"></p> -->
                                    <span class="prof" :style="{ 'background-image' : `url(${getProfileImage(approver)})`}">프로필</span>
                                    <img class="dummy" :src="getProfileImage(approver)" @error="userImageError($event)"/>

                                    <p style="display:inline-block;margin-left: 45px;margin-top: 12px;font-weight:bold;">{{ approver.user_name }} ({{ approver.pos_name }})</p>
                                    <span style="display:block;margin-left: 45px;margin-top: 5px;">{{ approver.dept_name }}</span>
                                    <p class="comment" v-if="approver.comment != null && approver.comment.length > 0" style="white-space : pre-line;">{{ approver.comment }}</p>
                                </div>
                            </li>

                            <!-- <li>
                                <div class="stand_line">
                                    <p style="line-height:20px;">2020.09.05 11:00am</p>
                                    <p style="line-height:20px;font-weight:bold;">이지인 사원 요청</p>
                                </div>
                            </li>
                            <li>
                                <span class="tree_line"></span>
                                <div class="stand_line">
                                    <span class="text_stand">승인</span>
                                    <p class="on_day">2020.09.06 03:13pm</p>
                                    <span class="prof">프로필</span>
                                    <p style="padding-left: 45px;margin-top: 12px;width: 100%;font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; margin-bottom: 6px;">강희혜 (대리)</p><span style="display:block;margin-left: 45px;margin-top: 5px;">경영지원</span>
                                </div>
                            </li>
                            <li class="text_black">
                                <span class="tree_line"></span>
                                <div class="stand_line cancel_line">
                                    <span class="text_app text_stand">반려</span>
                                    <p class="on_day">2020.09.06 03:13pm</p>
                                    <span class="prof prof_on">프로필</span>
                                    <p style="padding-left: 45px;margin-top: 12px;width: 100%;font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; margin-bottom: 6px;">박기태 (차장)</p>
                                    <span style="padding-left: 45px;padding-top: 5px;width: 100%;font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; font-weight:normal; opacity: 0.7">국내 영업</span>
                                    <p class="comment">모든 파일에는 기안자 이름이 포함되어야합니다.</p>
                                </div>
                            </li>
                            <li class="text_black">
                                <span class="tree_line"></span>
                                <div class="stand_line yes_line">
                                    <span class="text_app text_stand">요청</span>
                                    <p class="on_day">2020.09.06 03:13pm</p>
                                    <span class="prof prof_on">프로필</span>
                                    <p style="padding-left: 45px;margin-top: 12px;width: 100%;font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; margin-bottom: 6px;">박기태 (차장)</p>
                                    <span style="padding-left: 45px;padding-top: 5px;width: 100%;font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; font-weight:normal; opacity: 0.7">국내 영업</span>
                                </div>
                            </li>
                            <li>
                                <span class="tree_line"></span>
                                <div class="stand_line">
                                    <span class="text_stand">승인</span>
                                    <p class="on_day"></p>
                                    <p class="hover_menu"></p>
                                    <span class="prof">프로필</span>
                                    <p style="padding-left: 45px;margin-top: 12px;width: 100%;font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; display:block; margin-bottom: 6px;">김성림 (팀장)</p>
                                    <span style="padding-left: 45px;padding-top: 5px;width: 100%;font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; font-weight:normal; opacity: 0.7">캐발 / 모바일</span>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="btns" :class="{ b1 : approval != null && (approval.user_id != user_id || (approval.approval_state != 'ING' || approval.approver[1].state != 'ING')) }"> -->
            <div class="btns" :class="{ b1 : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') }">
                <input type="button" value="닫기" @click.prevent="close"/>
                <!-- 삭제버튼은 관리자만 뜹니다 // 클릭시 alert 띄워주기. 정말 휴가내역을 삭제 하시겠습니까? -->
                <input type="button" value="삭제" @click.prevent="cancel" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                <!-- <input type="button" value="휴가취소" @click.prevent="cancel" v-if="approval != null && approval.user_id == user_id && approval.approval_state == 'ING' && approval.approver[1].state == 'ING'" /> -->
            </div>
            
        </div>
        
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';
import { approval_enum } from '@/model/approval';

@Component({
    components: {
        
    },
})
export default class OrganizationMyVacationDetailModal extends Mixins(VueHoduCommon) {

    /**
     * 히스토리 + 현재 결재 상태 합치기
     */
    get computedApproverHistory() : any[] {
        let history : any[] = [];

        if( this.approval != null ) {
            
            if( this.approval['histapprover'] != null ) {
                history = history.concat(this.approval['histapprover']);
            }

            history = history.concat(this.approval.approver);
        }

        return history;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_my_vacation_detail_modal_info  !: organization_modal_interface.OrganizationMyVacationDetailModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationMyVacationDetailModalInfo ?: (params : organization_modal_interface.OrganizationMyVacationDetailModalInfo) => void;

    approval : any = null;

    mounted() : void {
        this.getSelectedApprovalInfo();
    }

    /**
     * 기안서 조회
     */
    async getSelectedApprovalInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.organization_my_vacation_detail_modal_info.approval_uid}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("휴가원 조회 중 오류 발생");
            }

            const approval_info = response.data.data.approval_info;

            this.approval = JSON.parse(JSON.stringify(approval_info));

        } catch(e) {
            alert("휴가원 조회 중 오류 발생")
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 상태에 따른 텍스트 반환
     */
    getStatusText(approval : any) : string {
        switch(approval.approval_state) {
            case approval_enum.APPROVAL_STATE.ING:
                return "승인 대기중";
            case approval_enum.APPROVAL_STATE.CANCEL:
                return "취소됨";
            case approval_enum.APPROVAL_STATE.COMPLETE:
                return "승인 됨";
            case approval_enum.APPROVAL_STATE.REJECT:
                return "반려 됨";
        }

        return "";
    }

    /**
     * 휴가 날짜 텍스트 반환
     */
    getDateText(approval : any) : string {

        if( this.hodu_string_validation(approval.contents.vacation_start, '') != "" && this.hodu_string_validation(approval.contents.vacation_end, '') != '' ) {

            const vacation_start = approval.contents.vacation_start;
            const vacation_end = approval.contents.vacation_end;

            if( vacation_start == vacation_end ) {
                const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
                
                let str = `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)}`;

                // if( approval.contents.vacation_type_daycount > 0 && approval.contents.vacation_type_daycount < 1 ) {
                if( approval.contents.vacation_type_daycount == 0.5 ) {
                    let start_hour = Number(approval.contents.vacation_start_time.substring(0,2));
                    let start_min = Number(approval.contents.vacation_start_time.substring(2,4));
                    let start_am_pm = 'AM';

                    if( start_hour >= 12 ) start_am_pm = "PM";
                    if( start_hour >  12 ) start_hour -= 12;
                    if( start_hour == 0  ) start_hour = 12;

                    let end_hour = Number(approval.contents.vacation_end_time.substring(0,2));
                    let end_min = Number(approval.contents.vacation_end_time.substring(2,4));
                    let end_am_pm = 'AM';

                    if( end_hour >= 12 ) end_am_pm = "PM";
                    if( end_hour >  12 ) end_hour -= 12;
                    if( end_hour == 0  ) end_hour = 12;

                    str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                }

                return str;
            }

            const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
            const target_end = [vacation_end.substring(0,4), vacation_end.substring(4,6), vacation_end.substring(6,8)].join('-');

            return `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)} ~ ${this.hodu_date_to_format_string(target_end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_end)}`;
   
        }

        let str = "";

        for( const selected_date of approval.contents.vacation_selected ) {
            if( str.length > 0 ) str += ", ";
            const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
            str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
        }

        if( approval.contents.vacation_selected.length == 1 ) {
            // if( approval.contents.vacation_type_daycount < 1 && approval.contents.vacation_type_daycount > 0 ) {
            if( approval.contents.vacation_type_daycount == 0.5 ) {
                // str += " 오전";

                let start_hour = approval.contents.vacation_start_time ? Number(approval.contents.vacation_start_time.substring(0,2)) : -1;
                let start_min = approval.contents.vacation_start_time ? Number(approval.contents.vacation_start_time.substring(2,4)) : -1;
                let start_am_pm = 'AM';

                if( start_hour < 0 || start_min < 0 ) return str;

                if( start_hour >= 12 ) start_am_pm = "PM";
                if( start_hour >  12 ) start_hour -= 12;
                if( start_hour == 0  ) start_hour = 12;

                let end_hour = approval.contents.vacation_end_time ? Number(approval.contents.vacation_end_time.substring(0,2)) : approval.contents.vacation_end_time;
                let end_min = approval.contents.vacation_end_time ? Number(approval.contents.vacation_end_time.substring(2,4)) : approval.contents.vacation_end_time;
                let end_am_pm = 'AM';

                if( end_hour < 0 || end_min < 0 ) return str;

                if( end_hour >= 12 ) end_am_pm = "PM";
                if( end_hour >  12 ) end_hour -= 12;
                if( end_hour == 0  ) end_hour = 12;

                str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
            }
        }
        
        return str;
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 승인자 이름 리스트
     */
    approverNameList(approval) : string {
        
        let name_list : string = "";
        
        for( const approver of approval.approver ) {
            if( approval.approver.indexOf(approver) == 0 ) continue;
            if( name_list.length > 0 ) name_list += ", ";
            name_list += approver.user_name;
        }

        return name_list;
    }

    /**
     * 결재자 상태값 텍스트
     */
    getApproverStateText(state : approval_enum.APPROVAL_APPROVER_STATE) : string {

        switch(state) {
            case approval_enum.APPROVAL_APPROVER_STATE.REQUEST:
                return "요청";

            case approval_enum.APPROVAL_APPROVER_STATE.CANCEL:
                return "취소";

            case approval_enum.APPROVAL_APPROVER_STATE.WAIT:
                return "대기";

            case approval_enum.APPROVAL_APPROVER_STATE.ING:
                return "진행";

            case approval_enum.APPROVAL_APPROVER_STATE.CONFIRM:
                return "승인";

            case approval_enum.APPROVAL_APPROVER_STATE.REJECT:
                return "반려";
        }

        return "";
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 사용자 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('span.prof').css('background-image', require('@/assets/images/contents/ic_approval_user.png'));
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationMyVacationDetailModalInfo?.({ show_modal : false, approval_uid : "" });
    }

    /**
     * 휴가 취소하기
     */
    async cancel() : Promise<void> {

        if( confirm("휴가원을 취소 처리 하시겠습니까?") == false ) return;

        // try {
            
        //     await this.getSelectedApprovalInfo();

        //     if( (this.approval.approval_state != approval_enum.APPROVAL_STATE.ING && this.approval.approval_state != approval_enum.APPROVAL_STATE.TEMP) || this.approval.approver[1].state != approval_enum.APPROVAL_APPROVER_STATE.ING ) {
        //         alert("휴가원 취소를 할 수 없습니다\n휴가원이 이미 처리 됐습니다");
        //         return;
        //     }

        //     this.approval.approval_state = approval_enum.APPROVAL_STATE.CANCEL;
        //     this.approval.approver[0].state = approval_enum.APPROVAL_APPROVER_STATE.CANCEL;
        //     this.approval.approver[1].state = approval_enum.APPROVAL_APPROVER_STATE.WAIT;

        //     // 수정 API
        //     const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval.approval_uid}`, API_METHOD.PUT, this.approval);

        //     console.log(response);

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("휴가원 취소 중 오류 발생");
        //     }

        //     this.organization_my_vacation_detail_modal_info.callback?.();
        //     this.close();
            
        // } catch(e) {
        //     this.hodu_show_dialog('cancel', '휴가원 취소 처리 중 오류 발생', ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // }

        try {

            //! 1. 기안서 조회
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval.approval_uid}`, API_METHOD.GET);
        
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("기안서 조회 중 오류 발생");
            }

            const approval = response.data.data.approval_info;

            //! 2. 기안서 취소
            approval.approval_state = approval_enum.APPROVAL_STATE.CANCEL;
            
            const response_update = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval.approval_uid}?is_not_send_push=true`, API_METHOD.PUT, approval);

            console.log(response_update);

            if( !response_update || !this.isHttpStatusSuccess(response_update.status) ) {
                throw new Error("기안서 취소 중 오류 발생");
            }

            this.organization_my_vacation_detail_modal_info.callback?.();
            this.close();

        } catch(e) {
            alert("휴가원 취소 처리 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
        
    }

}
</script>

<style scoped>

    .modal { position:absolute; left:50%; top:50%; margin-top:-265px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:530px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    .modal h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    .modal .detail { overflow:hidden; }

    .modal .context { float:left; border-left:1px solid #e7e9ea; width: 60%; height:368px; box-sizing: border-box; }
    
    .modal .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px; width: 100% }
    .modal .btns input { line-height:75px; height: 75px; background:#fff; width:33.33333%; float: left; font-size:14px; font-weight: bold;; }
    .modal .btns input.save:hover { color:#477fff; }
    .modal .btns input:hover { background:#f1f3f5; }

    .modal textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 113px; max-height: 113px; font-size: 13px;line-height: 20px; padding: 15px; height:auto; box-sizing: border-box; font-weight:bold; }
    .modal textarea:hover { border-color:#477fff }
    .modal textarea:focus { background:#fff; border-color:#477fff;outline: none;  }

    #organization_vacation_detail_modal { width: 800px; margin-left: -400px; }
    #organization_vacation_detail_modal .titleBox { position: relative; text-align: center; }
    #organization_vacation_detail_modal h1 { display: inline-block; }
    #organization_vacation_detail_modal h1 .icon { float: left; margin: 34px 15px 0 0; display: inline-block;  width: 27px; height: 27px; border-radius: 50%;background-repeat: no-repeat; background-position: center center;  }
    #organization_vacation_detail_modal h1:after { z-index: 100; content: ''; display: block; position: absolute; left: 0; bottom: 0; height: 3px; width: 100%; background:#ffbc49 }
    #organization_vacation_detail_modal h1.approved:after { background-color: #477fff; }
    #organization_vacation_detail_modal h1.rejected:after { background-color: #ff6363 }
    #organization_vacation_detail_modal h1.ing .icon {background-color: #ffbc49;border: 1px solid #ffa70e;}
    #organization_vacation_detail_modal h1.rejected .icon {background-color: #ff6363;border: 1px solid #ff2e2e; background-image: url(../../../assets/images/contents/ic_close_fff.gif); background-size: 15px;}
    #organization_vacation_detail_modal h1.approved .icon {background-color: #477fff;border: 1px solid #0b55ff;background-image: url('../../../assets/images/contents/checked_fff.png');}
    #organization_vacation_detail_modal h1.canceled .icon {background-color: #eceeef;border: 1px solid #d1d3d5; background-image: url(../../../assets/images/contents/ic_close_fff.gif); background-size: 15px;}
    #organization_vacation_detail_modal .btns input { width : 50%; }
    #organization_vacation_detail_modal .btns.b1 input { width : 100%; }

    #organization_vacation_detail_modal .context { float: none; width:100%; }

    #organization_vacation_detail_modal .detailUl { width: 100%; box-sizing: border-box; }
    #organization_vacation_detail_modal .detailUl li { transition: 0.2s; overflow: hidden; line-height: 53px; height: 53px; border-bottom: 1px solid #f1f3f5; box-sizing: border-box; padding: 0 30px; width:100%; }
    #organization_vacation_detail_modal .detailUl li.width50 { width: 50%;float:left; }
    #organization_vacation_detail_modal .detailUl li.width50 .descript { width: 140px }
    #organization_vacation_detail_modal .detailUl li .descript { float: right; font-weight: bold; width: 320px; text-align: right; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #organization_vacation_detail_modal .detailUl li.reason {height: 156px; padding-right:0;}
    #organization_vacation_detail_modal .detailUl li.reason .descript { padding-right: 30px !important; width: 100%; float: left; text-align: left; line-height: 20px; margin-top: -10px;overflow:auto; text-overflow: unset; white-space: normal; }
    #organization_vacation_detail_modal .detailUl li.reason .descript:disabled { background:#fff; border:0 none; padding: 0; padding-bottom: 20px; color:#232848; white-space: pre-line;}
    #organization_vacation_detail_modal .detailUl li:last-child { border-bottom: 0 none; }
    #organization_vacation_detail_modal .detailUl li * {  display: inline-block; }

    #organization_vacation_detail_modal .context { width: 480px; float: left; }
    #organization_vacation_detail_modal .rightContext { width:320px; float: right; background:#f1f3f5; height: 368px; }
    /* .rightContext ul { padding: 20px }
    .rightContext li { position: relative;padding: 15px; padding-left: 70px; box-sizing: border-box; overflow: hidden; background: #fff; border-radius: 10px; margin-top : 15px; }
    .rightContext li:first-child { margin-top : 0; }
    .rightContext li * { display: inline-block; width: 100%; }
    .rightContext .img { left:13px; top:12.5px; position: absolute; font-size: 0; width: 40px; height: 40px; border-radius: 50%; background:#fff; border: 1px solid rgb(228, 228, 228); background-size: cover; background-position: center center; background-repeat: no-repeat } 
    .rightContext .img { background-image: url('../../../assets/images/contents/ic_approval_user_on.png'); } */

    #organization_vacation_detail_modal.notRejected .detailUl li { height: 62px; line-height: 62px; }

    #organization_vacation_detail_modal ::-webkit-scrollbar{width: 6px;}
    #organization_vacation_detail_modal ::-webkit-scrollbar-track{background-color: transparent;}
    #organization_vacation_detail_modal ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

    /* #organization_vacation_detail_modal .subtitle { background : #fff; overflow: hidden; line-height: 53px; height: 53px; border-bottom: 1px solid #f1f3f5; box-sizing: border-box; border-left: 1px solid #f1f3f5; font-weight: bold; } */
    /* #organization_vacation_detail_modal .scroll { height : 315px; overflow : hidden; overflow-y: auto; } */
    #organization_vacation_detail_modal .scroll { height : 368px; overflow : hidden; overflow-y: auto; }
    
    img.dummy { display : none; }





    /*  */

    .approval_list .box_line >li{min-width:230px;max-width: 310px;margin: 0 auto;position: relative;}
    .approval_list .box_line >li div span, .approval_list .box_line li div p{color: rgb(167, 169, 183);}
    .approval_list .box_line >li div.cancel_line span, .approval_list .box_line li div.cancel_line p, .approval_list .box_line >li div.yes_line span, .approval_list .box_line li div.yes_line p{ color: #232848; }
    .approval_list .box_line >li div.cancel_line span.text_stand { color : #FF6363; }
    .approval_list .box_line >li div.yes_line span.text_stand { color : #477fff; }
    .approver .approver_button_area { left: 0;font-size:18px;position: absolute;width: 100%; }
    /*승인시*/a.App_btn{display:block; height:60px;line-height:60px;font-size:18px;background:#477fff;color:#fff;width: 100%;text-align: center;}
    /*반려.취소시*/a.Appno_btn{display:block;height:60px;line-height:60px;font-size:18px;background:rgb(255, 094, 094);color:#fff;width: 100%;text-align: center;}
    /*승인시 반쪽버튼*/a.App_btn2{display:block;height:60px;line-height:60px;font-size:14px;background:#477fff;color:#fff;width: 50%;text-align: center;}
    /*반려.취소시 반쪽버튼*/a.Appno_btn2{display:block;height:60px;line-height:60px;font-size:18px;background:rgb(255, 094, 094);color:#fff;width: 100%;text-align: center;}
    a.stand_btn2{display:block;right: 0;height:60px;line-height:45px;font-size:14px;background:rgb(204, 206, 214);color:#fff;position: absolute;width: 50%;bottom: 60px;text-align: center;}
        
    .prof{display: block;position: absolute;left: 16px;top: 38px; font-size: 0;width: 33px;height: 33px;background:url(../../../assets/images/contents/ic_approval_user.png) no-repeat center center;background-size: 33px; box-sizing:border-box; border-radius:50%; border:1px solid #d0d4e3;}
    .prof_on{background:url(../../../assets/images/contents/ic_approval_user_on.png) no-repeat center center;background-size: 33px;}

    .approval_list .box_line >li{min-width:230px;max-width: 310px;margin: 0 auto;position: relative; font-size: 12px;}
    .approval_list .box_line >li div span, .approval_list .box_line li div p{color: rgb(167, 169, 183);}
    .approval_list .box_line >li div.cancel_line span, .approval_list .box_line li div.cancel_line p, .approval_list .box_line >li div.yes_line span, .approval_list .box_line li div.yes_line p{ color: #232848; }
    .approval_list .box_line >li div.cancel_line span.text_stand { color : #FF6363; }
    .approval_list .box_line >li div.yes_line span.text_stand { color : #477fff; }
    .approver .approver_button_area { left: 0;font-size:18px;position: absolute;width: 100%; }
    /*승인시*/a.App_btn{display:block; height:60px;line-height:60px;font-size:18px;background:#477fff;color:#fff;width: 100%;text-align: center;}
    /*반려.취소시*/a.Appno_btn{display:block;height:60px;line-height:60px;font-size:18px;background:rgb(255, 094, 094);color:#fff;width: 100%;text-align: center;}
    /*승인시 반쪽버튼*/a.App_btn2{display:block;height:60px;line-height:60px;font-size:14px;background:#477fff;color:#fff;width: 50%;text-align: center;}
    /*반려.취소시 반쪽버튼*/a.Appno_btn2{display:block;height:60px;line-height:60px;font-size:18px;background:rgb(255, 094, 094);color:#fff;width: 100%;text-align: center;}
    a.stand_btn2{display:block;right: 0;height:60px;line-height:45px;font-size:14px;background:rgb(204, 206, 214);color:#fff;position: absolute;width: 50%;bottom: 60px;text-align: center;}
    .approval_list{width:100%;height: calc(100% - 60px);background:rgb(241, 243, 245);position: relative;}
    input.tab{display: none;}
    input:checked + label.approval_info {background: none;border-bottom:2px solid #477fff;color:rgb(007, 007, 007);}
    label {display: inline-block;margin: 0 0 -1px;padding: 15px 25px;font-weight: 600;text-align: center;color: #bbb;border: 1px solid transparent;background: none;}
    #tab1:checked ~ .approval_list, #tab2:checked ~ .history, #tab1_1:checked ~ .approval_list, #tab2_1:checked ~ .history{display: block;}
    .tree_line{display: block;margin-left:35px;height:25px;width:2px;background:rgb(208, 212, 227);}
    .stand_line{border:2px solid rgb(208, 212, 227);padding:15px;border-radius:7px;position: relative;}
    .yes_line{border:2px solid #477fff;background:#fff;}/*결재요청시 */
    .cancel_line{border:2px solid rgb(255, 005, 028);background:#fff;}/*반려시*/
    .past_cancel_line{border:2px solid rgb(255, 005, 028);}
    .comment{ margin-left: 45px;margin-top: 10px;padding: 10px 13px;background: #e4e6e8; color: rgb(167, 169, 183); border-radius: 0 8px 8px 8px;font-weight: bold;line-height: 20px;font-size:12px; }
    .yes_line .comment, .cancel_line .comment{ background: #f1f3f5; }
    .on_day{display:inline-block;margin-left: 12px;font-weight: bold;width: 170px;}
    .text_black span, .text_black p{color:rgb(038, 038, 038) !important;}
    .fever{width: 28px;height: 28px;background:url(../../../assets/images/contents/ic_kstar_off.png) no-repeat;background-size: 30px;display:inline-block;padding:0;margin: 0;}
    input#fever:checked + label.fever{background:url(../../../assets/images/contents/ic_kstar_on.png)no-repeat;background-size: 28px;}
    .hover:hover > .hover_menu{display: block;}
    .hover{background: #fff;}
    .hover_menu{cursor: pointer; display:none;position: absolute; top: 10px; right: 17px; width: 25px;height: 25px;background:url(../../../assets/images/contents/ic_hover_more.png) no-repeat;background-size: 25px;}
    .new_count{line-height: normal;background:rgb(255, 063, 090);width: 35px;height: 20px;border-radius: 15px;color: #fff;}
    .cancel_line .text_app{color: rgb(255, 005, 028)!important;}
    .yes_line .text_app{color: #477fff !important;}
    .text_stand{width: 35px;display: inline-block;text-align: center;font-weight: bold;}
    .Modify{position: absolute;top: 10px;right: 20px;color:rgb(083, 084, 111);border: 1px solid #e7e9ea;height: 35px;line-height: 35px;width: 55px;text-align: center;font-size: 14px;font-weight: bold;border-radius: 5px;}


</style>