<template>
    <div id="patient_add_modal" class="modal">
        <div class="titleBox">
            <h1>환자 등록 <span>가입권유 메세지</span></h1>
        </div>
        <div class="content">
            <div class="detail">
                <div class="context">
                    <textarea name="" id="">
                        [호두병원]에 가입을 요청합니다.
                        아래의 링크를 클릭하여 호두앱설치 및 해당 병원 서비스를 이용하실 수 있습니다.
                        https://wwww.hoduware.com/signup
                    </textarea>
                </div>
                <div class="info">
                    <label for="patientName">환자명</label>
                    <input type="text" id="patientName" placeholder="홍길동" />
                    <label for="patientTel">연락처</label>
                    <div class="telDiv">
                        <select name="" id="">
                            <option value="" selected>010</option>
                            <option value="">011</option>
                            <option value="">016</option>
                            <option value="">02</option>
                        </select>
                        <input type="text" id="patientTel" placeholder="0000" >
                        <input type="text" placeholder="0000" />
                    </div>
                    <label for="pCode">고유 번호 <span class="optional">*optional</span></label>
                    <input type="text" id="pCode" placeholder="" />
                    <div class="descriptDiv">
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="btns">
                <input type="button" value="취소" />
                <input type="button" class="save" value="보내기" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class PatientAddModal extends Mixins(VueHoduCommon) {

    mounted() : void {

    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-245px; margin-left:-350px; width:100%; border-radius:5px; max-width: 700px;  height:490px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    h1 span { font-size: 14px; padding-left: 10px; opacity: 0.7; }
    .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }
    .detail { overflow:hidden; height: 328px !important; }
    .context { float:left; display:inline-block; width:45%;height:100%; }
    .info { border-left:1px solid #e7e9ea; float:left; display:inline-block; width: 55%;height:100%;box-sizing: border-box; padding: 30px; }
    .info label { display:block; font-size: 13px; height: 25px; }
    .telDiv input[type="text"] { width: 30% !important; margin-left: 15px; }
    .info input[type="text"], .info select { margin-bottom:20px; transition:0.2s; font-weight: bold; padding: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box; line-height: 50px; height:50px; }
    .info input[type="text"]:hover { background:#f1f3f5; }
    .info input[type="text"]:focus { border-color:#477fff; background:#fff; }
    .info .telDiv select { width: 30%; font-size: 13px !important; font-weight:bold; line-height: 50px;padding:0 10px; height:50px; color:#000; border-radius: 5px;box-sizing: border-box; cursor:pointer; }
    span.optional { padding-left: 10px; display: inline-block; font-weight:bold;font-size: 11px; opacity:0.7; }
    textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 100%; font-size: 14px;line-height: 26px; padding: 25px; height:auto; box-sizing: border-box; font-weight:bold; }
    textarea:hover { border-color:#477fff }
    textarea:focus { background:#fff;  }

</style>