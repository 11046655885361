<template>
<!-- 수정시 modal addClass(edit) -->
    <div :class="`modal groupAddFileBox ${group_add_file_modal_info.is_create ? '' : 'edit'}`" id="groupAddFileBoxModal">
        <!-- 추가 또는 수정으로 변경 -->
        <h1 class="" id="">{{group_add_file_modal_info.is_create ? '폴더 추가' : '폴더 수정'}}</h1>
        <div class="content">
            <div class="detail">
                <div class="titleDiv">
                    <h2>폴더명</h2>
                    <!-- 잘못된 값일 시 input addClass(wrong), 다시 수정시 wrong빼기 -->
                    <input type="text" placeholder="폴더명을 입력하세요" id="input_file_box_name" :value="group_add_file_modal_info.dir_name"/>
                </div>
                <!-- <div class="fileboxDetail">
                    <h3>파일 수</h3>
                    <p><span class="num">10</span class="txt"><span> 개</span></p>
                </div> -->
            </div>
            <div class="btns">
                <input type="button" class="closeBtn" value="취소" @click="groupAddFileBoxCancel()"/>
                <input type="button" class="deleteBtn" value="삭제" @click="groupDeleteFileBox()"/>
                <input type="button" class="addBtn" value="등록" @click="groupAddFileBoxConfirm()"/>
                <input type="button" class="saveBtn" value="저장" @click="groupAddFileBoxConfirm()"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";
import { namespace } from 'vuex-class';
import { GroupAddFileModalInfo } from '@/store/modules/ModalInfo';
const ModalInfo = namespace('ModalInfo');

@Component({

}) export default class GroupAddFileBox extends Mixins(VueHoduCommon) {
    /**
     * ModalInfo.State
     */
    @ModalInfo.State group_add_file_modal_info !: GroupAddFileModalInfo;

    /**
     * 변수 선언부
     */

    async mounted() : Promise<void> {

    }

    groupAddFileBoxCancel() : void {
        window["groupAddFileBoxCancel"]();
    }

    groupAddFileBoxConfirm() : void {
        let value = String($('#input_file_box_name').val());
        
        if ( value.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "폴더명을 입력해주세요.", ['확인']);
            return;
        }

        window["groupAddFileBoxConfirm"](this.group_add_file_modal_info.is_create, this.group_add_file_modal_info.dir_uuid, value);
    }

    groupDeleteFileBox() : void {
        this.hodu_show_dialog("alert", `${this.group_add_file_modal_info.dir_name} 삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            () => {
                window["groupDeleteFileBoxConfirm"](this.group_add_file_modal_info.dir_uuid);
            },
        ]);
    }
}
</script>

<style scoped>
    .modal.groupAddFileBox { width:650px; position:absolute; z-index:10000000000000000000000000000000; background:#fff; border-radius:5px;margin:0 auto;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden; margin-top: -117px;}
    .modal.groupAddFileBox h1 { font-size: 19px; padding: 0 30px;height:85px; line-height:100px; width:100%; box-sizing: border-box; border-bottom:1px solid #f1f3f5; }
    
    .modal.groupAddFileBox .btns { width:100%;padding:0; border-top:1px solid #f1f3f5; }
    .modal.groupAddFileBox .btns input { width:50%;transition:0.2s; background:#fff; font-size:14px; font-weight:bold; height:70px; line-height:70px;box-sizing: border-box; padding:0; }
    .modal.groupAddFileBox .btns input.closeBtn:hover { color:#ff6060; }
    .modal.groupAddFileBox .btns .saveBtn, .modal.groupAddFileBox .btns .deleteBtn { display: none; }
    .modal.groupAddFileBox .btns input.deleteBtn:hover { background:#ff6060; color:#fff; }
    .modal.groupAddFileBox .btns input.saveBtn:hover, .modal.groupAddFileBox .btns input.addBtn:hover { color:#477fff; }
    .modal.groupAddFileBox .btns input:hover { background:#f1f3f5; }
    .modal.groupAddFileBox .detail { padding:30px 40px; }
    .modal.groupAddFileBox .detail .titleDiv h2 { font-size:14px; margin-bottom:10px;background: url(../assets/images/contents/ic_fileBox_blue.png) no-repeat center left; padding-left:30px;height:30px;line-height:30px; }
    .modal.groupAddFileBox .detail .titleDiv input { transition:0.2s; line-height:55px; height:55px; padding: 0 15px; border: 1px solid #e7e9ea;width:100%; box-sizing: border-box;font-size:17px;font-weight:bold; } 
    .modal.groupAddFileBox .detail .titleDiv input:hover { border:1px solid #d5dbde; background:#f1f3f5; }
    .modal.groupAddFileBox .detail .titleDiv input:focus { border:1px solid #477fff; background:#fff !important; }
    .modal.groupAddFileBox .detail .titleDiv input.wrong { border:1px solid #ff6060; background:#fff0f0; color:#ff6060; }
    .modal.groupAddFileBox .detail .titleDiv input.wrong::placeholder { color:#ff6060; }
    .edit.modal.groupAddFileBox .btns input { width:33.333333%; }
    .edit.modal.groupAddFileBox .btns .addBtn { display:none; }
    .edit.modal.groupAddFileBox .btns .saveBtn, .edit.modal.groupAddFileBox .btns .deleteBtn { display:inline-block; }

    

</style>