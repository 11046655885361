<template>
    <div id="approval" class="section_ce_fix" style="position: relative;">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 id="viewTitle" class="title_doc">전자 결재</h3> 
            <!-- 클릭시 새창에 PDF를 열어주세요. 전자결재는 10페이지부터 열리게 해주세요 -->
            <span class="ussM_box"><a href="app_images/hoduWorks_manual02.pdf#page=10" target="_blank" rel=noopener title="사용 안내서를 확인해주세요" class="use_manual">?</a></span>
        </div>
        <div class="approval_menu">

            <div id="approval_menu_scroll">

                <div class="miniDep_box">
                    <h3 class="miniDep dep1" :class="{ on : is_draft_open == true }" @click.prevent="is_draft_open = !is_draft_open">상신함</h3>
                    <a href="#" class="appbox1" :class="{ on : is_draft_open == true }" @click.prevent="is_draft_open = !is_draft_open"></a>
                    <ul class="ApprovalBox_menu" :class="{ open : is_draft_open == true }">
                        <li :class="{ set : isDraftAll() == true }">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.DRAFT, approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL)">전체<span class="app_num">{{ draft_approvals.length }}</span></a>
                        </li>
                    <!--<li>
                            <a href="#"><span id="s_temporary"></span>임시<span class="app_num">1</span></a>
                        </li>
                        <li>
                            <a href="#"><span id="s_pendency"></span>취소<span class="app_num">1</span></a>
                        </li>-->
                        <li :class="{ set : isDraftIng() == true }">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.DRAFT, approval_enum.APPROVAL_DRAFT_BOX_TAB.ING)"><span id="s_ing"></span>진행<span class="app_num">{{ draft_approvals.filter(item => item.approval_state == 'ING').length }}</span></a>
                        </li>
                        <li :class="{ set : isDraftConfirm() == true }">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.DRAFT, approval_enum.APPROVAL_DRAFT_BOX_TAB.COMPLETE)"><span id="s_complete"></span>완료<span class="app_num">{{ draft_approvals.filter(item => item.approval_state == 'COMPLETE').length }}</span></a>
                        </li>
                        <li :class="{ set : isDraftReject() == true }">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.DRAFT, approval_enum.APPROVAL_DRAFT_BOX_TAB.REJECT)"><span id="s_return"></span>반려<span class="app_num">{{ draft_approvals.filter(item => item.approval_state == 'REJECT').length }}</span></a>
                        </li>
                        <li :class="{ set : isDraftTemp() == true }">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.DRAFT, approval_enum.APPROVAL_DRAFT_BOX_TAB.TEMP)"><span id="s_temporary"></span>임시<span class="app_num">{{ draft_approvals.filter(item => item.approval_state == 'TEMP').length }}</span></a>
                        </li>
                        <li :class="{ set : isDraftCancel() == true }">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.DRAFT, approval_enum.APPROVAL_DRAFT_BOX_TAB.CANCEL)"><span id="s_cancel"></span>취소<span class="app_num">{{ draft_approvals.filter(item => item.approval_state == 'CANCEL').length }}</span></a>
                        </li>
                    <!--<li>
                            <a href="#"><span id="s_stop"></span>대기<span class="app_num">1</span></a>
                        </li>-->
                    </ul>
                </div>

                <div class="miniDep_box">
                    <h3 class="miniDep dep1" :class="{ on : is_receive_open == true }" @click.prevent="is_receive_open = !is_receive_open">수신함</h3>
                    <a href="#" class="appbox1" :class="{ on : is_receive_open == true }" @click.prevent="is_receive_open = !is_receive_open"></a>
                    <ul class="ApprovalBox_menu" :class="{ open : is_receive_open == true }">
                        <li :class="{ set : isReceiveRequest() == true }">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.RECEIVE, approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST)">결재요청<span class="app_num">{{ receive_approvals.filter(item => item.approver.filter( app => app.user_id == user_id && app.state == 'ING').length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length }}</span></a>
                        </li>
                        <li :class="{ set : isReceiveWait() == true }">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.RECEIVE, approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT)">결재대기<span class="app_num">{{ receive_approvals.filter(item => item.approver.filter( app => app.user_id == user_id && app.state == 'WAIT').length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length }}</span></a>
                        </li>
                        <li :class="{ set : isReceiveHistory() == true }">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.RECEIVE, approval_enum.APPROVAL_RECEIVE_BOX_TAB.HISTORY)">결재내역<span class="app_num">{{ receive_approvals.filter(item => item.approver.filter( app => app.user_id == user_id && ( app.state == 'REJECT' || app.state == 'CONFIRM' ) ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length }}</span></a>
                        </li>
                        <li :class="{ set : isReceiveReference() == true }"> 
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.RECEIVE, approval_enum.APPROVAL_RECEIVE_BOX_TAB.REFERENCE)">수신참조<span class="app_num">{{ receive_approvals.filter(item => (item.receive_reference != null && item.receive_reference.filter(ref => ref.user_id == user_id).length > 0) && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length }}</span></a>
                        </li>
                    </ul>
                </div>

            <!---보존함
                <div class="miniDep_box">
                    <h3 class="miniDep dep2">보존함</h3>
                    <a href="#" class="appbox2"></a>
                    <ul class="PreserveBox_menu">
                        <li>
                            <a href="#" class="all_list">2020<span class="app_num">32</span></a>
                            <p class="set" >
                                <a href="#">3월<span class="app_num">12</span></a>
                            </p>
                            <p class="" >
                                <a href="#">2월<span class="app_num">12</span></a>
                            </p>
                            <p class="" >
                                <a href="#">1월<span class="app_num">12</span></a>
                            </p>
                        </li>
                        <li>
                            <a href="#" class="all_list">2019<span class="app_num">183</span></a>
                            <p>
                                <a href="#">12월<span class="app_num">32</span></a>
                            </p>
                        </li>
                    </ul>
                </div>-->
            <!---즐겨찾기    
                <div class="miniDep_box">
                    <h3 class="miniDep dep3">즐겨찾기</h3>
                    <a href="#" class="appbox3"></a>
                </div>---->
                
                <div class="miniDep_box">
                    <h3 class="miniDep dep4" :class="{ on : is_form_open == true }" @click.prevent="is_form_open = !is_form_open">양식함</h3>
                    <a href="#" class="appbox4" :class="{ on : is_form_open == true }" @click.prevent="is_form_open = !is_form_open"></a>
                    <ul class="FormBox_menu" :class="{ open : is_form_open == true }">
                        <li :class="{ set : isFormDownload() == true }">
                            <a href="#" :class="{ approval_disabled : isAttendanceOnly() == true }" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD)">양식 다운로드</a>
                        </li>
                        <li :class="{ set : isFormSetting() == true }" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                            <a href="#" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.FORM_SETTING)">양식 설정</a>
                        </li>
                    </ul>
                </div>

                <!-- 즐겨찾기 -->    
                <div class="miniDep_box">
                    <h3 class="miniDep dep5 on">결재자 즐겨찾기</h3>
                    <a href="#" id="addGrp" class="appbox5 btMore" @click.prevent="selectTab(approval_enum.APPROVAL_BOX_TAB.APPROVER_FAVORITE)"></a>
                </div>

            </div>

        </div> 

        <!--상신함 -->
        <ApprovalDraftBox 
            :event_bus="event_bus" 
            :tab="selected_draft_tab"
            :filter="draft_filter"
            :approvals="draft_approvals"
            @getApproval="getApproval"
            v-if="isDraft() == true" />

        <!-- 수신함 -->
        <ApprovalReceiveBox 
            :event_bus="event_bus"
            :tab="selected_receive_tab"
            :filter="receive_filter"
            :approvals="receive_approvals"
            @getApproval="getApproval"
            v-if="isReceive() == true" />

        <!--보존함-사용안함-->
        <!-- <div class="approval_content" v-if="false">
            <div class="filter_tap">
                <div class="content_top">
                    <a href="#" class="bt_hambuger"></a>
                    <h3 style="">필터링</h3>
                    <input type="text" id="filter_search" placeholder="제목, 기안자, 부서 등으로 검색하세요"/>
                    <a href="#" class="cont_del">삭제</a>
                    <a href="#" class="cont_del">삭제</a>
                </div>
                <div class="nav_item" style="position: relative;">
                    <div style="width:240px;height:180px;padding:15px;font-sise:15px;overflow: hidden;border-right: 1px solid #e7e9ea;box-sizing: border-box;overflow-y: scroll;">
                        <ul class="tree">
                            <input  type="checkbox" id="teamCheck01" value="" class="input_check0">
                            <label  for="teamCheck01" style=""></label>
                            <p style="display: inline-block;margin-top: 2px;">지누스</p>
                            <li style="margin-left:40px;">
                                <ul style="">
                                    <input  type="checkbox" id="teamCheck02_1" value="" class="input_check0">
                                    <label  for="teamCheck02_1" style=""></label>
                                    <p style="display: inline-block;margin-top: 2px;">피닉스</p>
                                    <li style="margin-left: 14px;">
                                        <input  type="checkbox" id="teamCheck03" value="" class="input_check0">
                                        <label  for="teamCheck03" style=""></label>
                                        <p style="display: inline-block;margin-top: 2px;">개발</p>
                                    </li>
                                    <li style="margin-left: 14px;">
                                        <input  type="checkbox" id="teamCheck04" value="" class="input_check0">
                                        <label  for="teamCheck04" style=""></label>
                                        <p style="display: inline-block;margin-top: 2px;">교육</p>
                                    </li>
                                </ul>
                            </li>
                            <li style="margin-left:40px;">
                                <ul style="">
                                    <input  type="checkbox" id="teamCheck02_2" value="" class="input_check0">
                                    <label  for="teamCheck02_2" style=""></label>
                                    <p style="display: inline-block;margin-top: 2px;">피닉스</p>
                                    <li style="margin-left: 14px;">
                                        <input  type="checkbox" id="teamCheck03_2" value="" class="input_check0">
                                        <label  for="teamCheck03_2" style=""></label>
                                        <p style="display: inline-block;margin-top: 2px;">개발</p>
                                    </li>
                                    <li style="margin-left: 14px;">
                                        <input  type="checkbox" id="teamCheck04_2" value="" class="input_check0">
                                        <label  for="teamCheck04_2" style=""></label>
                                        <p style="display: inline-block;margin-top: 2px;">교육</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div style="height:120px;position: absolute;top: 0;left: 240px;right:0;">
                        <ul style="height:60px;">
                            <li class="Search_li01">검색일</li>
                            <li class="Search_li02" style="">2020.09.01 목 ~ 2020.09.07 월</li>
                            <a href="#" style="position: absolute;top: 8px;right: 55px;border: 1px solid;padding: 10px 15px;line-height: 20px;box-sizing: border-box;font-size: 14px;">초기화</a>
                        </ul>
                        <ul style="height:60px;">
                            <li class="Search_li01">중요도</li>
                            <li class="Search_li02">
                                <p style="display: inline-block;margin-right:20px;">
                                    <input  type="checkbox" id="search_check01" value="" class="input_check0">
                                    <label  for="search_check01"  style="margin: 12px 10px 15px 0px;"></label>
                                    <span>전체</span>
                                </p>
                                <p style="display: inline-block;margin-right:20px;">
                                    <input  type="checkbox" id="search_check02" value="" class="input_check0">
                                    <label  for="search_check02"  style="margin: 12px 10px 15px 0px;"></label>
                                    <span>긴급</span>
                                </p>
                                <p style="display: inline-block;margin-right:20px;">
                                    <input  type="checkbox" id="search_check03" value="" class="input_check0">
                                    <label  for="search_check03"  style="margin: 12px 10px 15px 0px;"></label>
                                    <span>중요</span>
                                </p>
                                <p style="display: inline-block;margin-right:20px;">
                                    <input  type="checkbox" id="search_check04" value="" class="input_check0">
                                    <label  for="search_check04"  style="margin: 12px 10px 15px 0px;"></label>
                                    <span>요청</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div class="content" style="width: 100%;">
                <div id="noticeScroll" class="section_scroll">
                    <div class="viewGroup">
                        <div class="schedule_box"></div>
                        <div class="fixedDiv">
                            <ul id="" class="sortHeader groupNoticeHeader">
                                <li class="notiCheck" style="padding-left: 20px;box-sizing: border-box;">
                                    <p id="selectCheckBoxAll" class="checkBox on">
                                        <input type="checkbox" id="selectAll">
                                        <label for="selectAll">전체선택</label>
                                    </p>
                                </li>
                                <li class="num">No</li>
                                <li class="title headerSort"><span style="margin-left:15px;">제목</span></li>
                                <li class="group headerSort"><span style="margin-left:38px;"> 기안자 </span></li>
                                <li class="writtenBy headerSort"><span style="margin-left:26px;">부서</span></li>
                                <li class="lastday headerSort"><span style="margin-left:13px;">보존기간</span></li>
                            </ul>
                        </div>
                        <div id="approval_list" class="content mCustomScrollbar _mCS_2" style="height: 1175px; margin-right:0;position: relative; overflow: visible;">
                            <div id="mCSB_2" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_outside" tabindex="0" style="max-height: none;">
                                <div id="mCSB_2_container" class="mCSB_container" style="position: relative; left: 0px; top: 0px;" dir="ltr">
                                    <ul  class="notiListUl">
                                        <li  class="">
                                            <p  class="notiCheck"  style="padding-left: 20px;">
                                                <input  type="checkbox" id="notiCheck01" value="" class="input_check">
                                                <label  for="notiCheck01" style="margin-top: 40px;"></label></p>
                                            <p  class="num"><span >1</span></p>
                                            <p class="title">
                                                <ul class="title_info">
                                                    <li class="list_title">진행 예제</li>
                                                    <li class="list_day" style="">20.09.05 11:00am</li>
                                                </ul>
                                            </p>
                                            <p  class="group">
                                                <ul class="group_info">
                                                    <li class="list_group">이지인</li>
                                                    <li class="list_rank" style="">사원</li>
                                                </ul>
                                            </p>
                                            <p  class="writtenBy"><span >피닉스</span></p>
                                            <p  class="lastday">5년<span style="color:#aaa;">(D-1782)</span> </p>
                                        </li>
                                        <li  class="">
                                            <p  class="notiCheck"  style="padding-left: 20px;">
                                                <input  type="checkbox" id="notiCheck01" value="" class="input_check">
                                                <label  for="notiCheck01" style="margin-top: 40px;"></label></p>
                                            <p  class="num"><span >2</span></p>
                                            <p class="title">
                                                <ul class="title_info">
                                                    <li class="list_title"><span class="quick">긴급</span>반려 예제</li>
                                                    <li class="list_day" style="">20.09.05 11:00am</li>
                                                </ul>
                                            </p>
                                            <p  class="group">
                                                <ul class="group_info">
                                                    <li class="list_group">이지인</li>
                                                    <li class="list_rank" style="">사원</li>
                                                </ul>
                                            </p>
                                            <p  class="writtenBy"><span >피닉스</span></p>
                                            <p  class="lastday">5년<span style="color:#aaa;">(D-1782)</span> </p>
                                        </li>
                                        <li  class="">
                                            <p  class="notiCheck"  style="padding-left: 20px;">
                                                <input  type="checkbox" id="notiCheck01" value="" class="input_check">
                                                <label  for="notiCheck01" style="margin-top: 40px;"></label></p>
                                            <p  class="num"><span >3</span></p>
                                            <p class="title">
                                                <ul class="title_info">
                                                    <li class="list_title">미결 예제</li>
                                                    <li class="list_day" style="">20.09.05 11:00am</li>
                                                </ul>
                                            </p>
                                            <p  class="group">
                                                <ul class="group_info">
                                                    <li class="list_group">이지인</li>
                                                    <li class="list_rank" style="">사원</li>
                                                </ul>
                                            </p>
                                            <p  class="writtenBy"><span >피닉스</span></p>
                                            <p  class="lastday">5년<span style="color:#aaa;">(D-1782)</span> </p>
                                        </li>
                                        <li  class="">
                                            <p  class="notiCheck"  style="padding-left: 20px;">
                                                <input  type="checkbox" id="notiCheck01" value="" class="input_check">
                                                <label  for="notiCheck01" style="margin-top: 40px;"></label></p>
                                            <p  class="num"><span >4</span></p>
                                            <p class="title">
                                                <ul class="title_info">
                                                    <li class="list_title">완료 예제</li>
                                                    <li class="list_day" style="">20.09.05 11:00am</li>
                                                </ul>
                                            </p>
                                            <p  class="group">
                                                <ul class="group_info">
                                                    <li class="list_group">이지인</li>
                                                    <li class="list_rank" style="">사원</li>
                                                </ul>
                                            </p>
                                            <p  class="writtenBy"><span >피닉스</span></p>
                                            <p  class="lastday">5년<span style="color:#aaa;">(D-1782)</span> </p>
                                        </li>
                                        <li  class="">
                                            <p  class="notiCheck"  style="padding-left: 20px;">
                                                <input  type="checkbox" id="notiCheck01" value="" class="input_check">
                                                <label  for="notiCheck01" style="margin-top: 40px;"></label></p>
                                            <p  class="num"><span >5</span></p>
                                            <p class="title">
                                                <ul class="title_info">
                                                    <li class="list_title">임시 예제</li>
                                                    <li class="list_day" style="">20.09.05 11:00am</li>
                                                </ul>
                                            </p>
                                            <p  class="group">
                                                <ul class="group_info">
                                                    <li class="list_group">이지인</li>
                                                    <li class="list_rank" style="">사원</li>
                                                </ul>
                                            </p>
                                            <p  class="writtenBy"><span >피닉스</span></p>
                                            <p  class="lastday">5년<span style="color:#aaa;">(D-1782)</span> </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div id="mCSB_2_scrollbar_vertical" class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical" style="display: block;">
                                <div class="mCSB_draggerContainer">
                                    <div id="mCSB_2_dragger_vertical" class="mCSB_dragger" style="position: absolute; min-height: 30px; top: 0px; display: block; height: 1015px; max-height: 1165px;">
                                        <div class="mCSB_dragger_bar" style="line-height: 30px;"></div>
                                    </div>
                                    <div class="mCSB_draggerRail"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!--양식함 -->
        <ApprovalFormBox 
            :event_bus="event_bus"
            :tab="selected_tab"
            :all_templates="all_templates"
            :templates="templates"
            :none_class_template="none_class_template"
            :default_template="default_template"
            :vacation_template="vacation_template"
            :business_trip_template="business_trip_template"
            :holiday_work_template="holiday_work_template"
            :cash_disbursement_voucher_template="cash_disbursement_voucher_template"
            :free_template="free_template"
            :receive_template="receive_template"
            @getApprovalTemplateInfo="getApprovalTemplateInfo"
            v-if="isForm() == true" />

        <!-- 결재자 즐겨찾기 -->
        <ApprovalApproverFavorite 
            :event_bus="event_bus"
            v-if="isApproverFavorite() == true" />

        <!-- 김윤미 새 작업 -->
        <div class="approval_wrtie" :class="{ on : is_menu_open == true }" v-if="isForm() == false && isApproverFavorite() == false">
            <div class="inbox">

                <div class="event_btn">
                    <input type="button" class="btn_business_trip" @click.prevent="businessTripCreate" title="출장신청서 등록">
                    <p>출장신청서</p>
                </div>

                <div class="event_btn">
                    <input type="button" class="btn_day_off" @click.prevent="vacationCreate" title="휴가원 등록">
                    <p>휴가원</p>
                </div>

                <div class="event_btn" v-if="holiday_work_template != null && holiday_work_template.files != null && holiday_work_template.files[0] != null && 
                                             holiday_work_template.files[0].contents != null && holiday_work_template.files[0].contents.visible == true">
                    <input type="button" class="btn_holiday_work" @click.prevent="holidayWorkCreate" title="휴일근무 등록">
                    <p>휴일근무</p>
                </div>

                <div class="event_btn" :class="{ approval_disabled : isAttendanceOnly() == true }">
                    <input type="button" class="btn_form btn_expenses" @click.prevent="cashDisbursementVoucherCreate" :disabled="isAttendanceOnly() == true" title="지출결의서 등록">
                    <p>지출결의서</p>
                </div>

                <div class="event_btn" :class="{ approval_disabled : isAttendanceOnly() == true }">
                    <input type="button" class="btn_form btn_custom" @click.prevent="freeCreate" :disabled="isAttendanceOnly() == true" title="시스템 양식 기안서 등록">
                    <p>기안서</p>
                </div>

                <div class="event_btn" :class="{ approval_disabled : isAttendanceOnly() == true }">
                    <input type="button" class="btn_form" @click.prevent="approvalCreate" :disabled="isAttendanceOnly() == true" title="사용자 지정 기안서 등록">
                    <p>사용자 지정 문서</p>
                </div>

            </div>
            <input type="button" value="작성하기" class="app_wrt_btn" @click="menuToggle">
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ApprovalInfo = namespace('ApprovalInfo');

import ApprovalDraftBox from '@/components/approval/ApprovalDraftBox.vue';
import ApprovalReceiveBox from '@/components/approval/ApprovalReceiveBox.vue';
import ApprovalFormBox from '@/components/approval/ApprovalFormBox.vue';
import ApprovalApproverFavorite from '@/components/approval/ApprovalApproverFavorite.vue';

import moment from 'moment';

import { approval_enum, approval_interface } from '@/model/approval';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ApprovalDraftBox, ApprovalReceiveBox, ApprovalFormBox, ApprovalApproverFavorite,
        ResizeObserver
    },
})
export default class Approval extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    event_bus : Vue = new Vue();

    /**
     * @ApprovalInfo.State
     */
    @ApprovalInfo.State selected_tab !: approval_enum.APPROVAL_BOX_TAB;
    @ApprovalInfo.State selected_draft_tab !: approval_enum.APPROVAL_DRAFT_BOX_TAB;
    @ApprovalInfo.State selected_receive_tab !: approval_enum.APPROVAL_RECEIVE_BOX_TAB;
    @ApprovalInfo.State filter !: approval_interface.ApprovalFilter;
    @ApprovalInfo.State draft_filter !: approval_interface.ApprovalFilter;
    @ApprovalInfo.State receive_filter !: approval_interface.ApprovalFilter;
    // @ApprovalInfo.State is_first_init_complete !: boolean;

    /**
     * @ApprovalInfo.Action
     */
    @ApprovalInfo.Action doSetApproval ?: (params : approval_interface.t_approval) => void;
    @ApprovalInfo.Action doSetSelectedTab ?: (params : approval_enum.APPROVAL_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedDraftTab ?: (params : approval_enum.APPROVAL_DRAFT_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedReceiveTab ?: (params : approval_enum.APPROVAL_RECEIVE_BOX_TAB) => void;
    @ApprovalInfo.Action doSetFilter ?: (params : approval_interface.ApprovalFilter) => void;
    @ApprovalInfo.Action doSetDraftFilter ?: (params : approval_interface.ApprovalFilter) => void;
    @ApprovalInfo.Action doSetReceiveFilter ?: (params : approval_interface.ApprovalFilter) => void;
    // @ApprovalInfo.Action doSetIsFirstInitComplete ?: (params : boolean) => void;

    // 검색 필터값
    // filter : approval_interface.ApprovalFilter = {
    //     search_query : "",
    //     start : new Date(),
    //     end : new Date(),
    //     is_emergency : true,
    //     is_importance : true,
    //     is_normal : true,
    //     importance_filter : [10, 20, 30],
    //     departments : [],
    //     dept_ids : []
    // };
    
    draft_approvals : any[] = [];
    receive_approvals : any[] =[];

    is_draft_open : boolean = true;
    is_receive_open : boolean = true;
    is_form_open : boolean = true;

    is_menu_open : boolean = false;

    all_templates : approval_interface.ApprovalTemplateBundle[] = [];
    templates : approval_interface.ApprovalTemplateBundle[] = [];

    none_class_template : any = null;
    default_template : any = null;
    vacation_template : any = null;
    business_trip_template : any = null;
    holiday_work_template : any = null;
    cash_disbursement_voucher_template : any = null;
    free_template : any = null;
    receive_template : any = null;

    async mounted() : Promise<void> {

        //필터링 메뉴
        // $(".bt_hambuger").click(function(){
        //     $(this).toggleClass("change");
		// 	$(".bt_hambuger").parent().siblings(".nav_item").toggleClass("open");

        // });

//분류 메뉴
        // $(".bt_hambuger2").click(function(){
        //     $(this).toggleClass("change");
		// 	$(".bt_hambuger2").parent().siblings(".nav_item2").toggleClass("open");

        // });        
//각 메뉴 펼치기-결재함
        // $(".dep1, .appbox1").click(function(){
        //     $(this).toggleClass("on");
        //     $(this).siblings('.appbox1').toggleClass("on");
        //     $(this).siblings('.dep1').toggleClass("on");
		// 	$(this).siblings('.ApprovalBox_menu').toggleClass("open");
        // });
        // $(".dep2, .appbox2").click(function(){
        //     $(this).toggleClass("on");
        //     $(this).siblings('.appbox2').toggleClass("on");
        //     $(this).siblings('.dep2').toggleClass("on");
		// 	$(this).siblings('.PreserveBox_menu').toggleClass("open");
        // });
        // $(".dep4, .appbox4").click(function(){
        //     $(this).toggleClass("on");
        //     $(this).siblings('.appbox4').toggleClass("on");
        //     $(this).siblings('.dep4').toggleClass("on");
		// 	$(this).siblings('.FormBox_menu').toggleClass("open");
        // });
        // $('#approval_list li').on('click', (event) => {
        //     event.preventDefault();
        //     this.hodu_router_push('/scope/scope_id/approval/approval_id');
        // })

        // 버튼 클릭시 라인과 팀 보이기
        // $(".arw").click(function(){
        //     // 리스트 인덱스 가져오기
        //     var getIndex = $(this).parent().index();
            
        //     // 버튼 클릭시 라인과 팀 보이기
        //     $(".fList").eq(getIndex).toggleClass("showTeam");

        // });        
        
        await this.getApproval();

        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {

            const title_height = $('.title_box').outerHeight();
            const write_height = $('.approval_menu .approval_wrtie').outerHeight();
            const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                    - (write_height ? write_height : 0);

            // @ts-ignore
            $('#approval_menu_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : scroll_height,
            });

        });
    }

    /**
     * 전자결재 조회
     */
    async getApproval() : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getOrganization();
            await this.getApprovalTemplateInfo();
            await Promise.all([this.getApporvalDraftBox(), this.getApporvalReceiveBox()]);
            await this.hodu_hide_indicator();
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            const receive_filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.receive_filter));
            receive_filter.departments.splice(0, receive_filter.departments.length);
            receive_filter.departments = receive_filter.departments.concat(response.data.data.dept_info);
            
            if( receive_filter.is_selecet_all_departments == true ) {
                receive_filter.dept_ids.splice(0, receive_filter.dept_ids.length);
                for( const department of receive_filter.departments ) { 
                    receive_filter.dept_ids.push(department.dept_id);
                }
            }

            await this.doSetReceiveFilter?.(receive_filter);

        } catch(e) {
            this.hodu_show_dialog('cancel', "조직도 조회 중 오류 발생", ['확인']);
            throw e;
        }

    }

    /**
     * 상신함 조회
     */
    async getApporvalDraftBox() : Promise<void> {

        try {
            // 검색 타입, 시작일, 종료일
            let query = "?search_mode=DRAFT";
            query += `&start_date=${moment(this.draft_filter.start).utc().format()}`;
            query += `&end_date=${moment(this.draft_filter.end).utc().format()}`;
            
            // 중요도
            for( const imp_seq of this.draft_filter.importance_filter ) {
                query += `&imp_seq=${imp_seq}`;
            }

            // 웹에서는 조회 할때 탭 조건을 걸지 않는다 (카운팅을 클라이언트에서 해야하기 떄문)
            // if( this.tab != approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL ) {
            //     query += `&approval_state=${this.tab}`;
            // }

            // 클라이언트 타임존
            const timezone = -(new Date().getTimezoneOffset());
            query += `&timezone=${timezone ? timezone : 540}`;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval${query}`, API_METHOD.GET, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("상신함 문서 조회 중 오류 발생");
            }

            if( this.isAttendanceOnly() == true ) {
                response.data.data.approval_info = response.data.data.approval_info.filter(item => item.approval_type == approval_enum.APPROVAL_TYPE.VACATION);
            }

            const type_filter : string[] = [];
            if( this.draft_filter.is_approval == true ) type_filter.push(approval_enum.APPROVAL_TYPE.BASIC);
            if( this.draft_filter.is_vacation == true ) type_filter.push(approval_enum.APPROVAL_TYPE.VACATION);
            if( this.draft_filter.is_businesstrip == true ) type_filter.push(approval_enum.APPROVAL_TYPE.BUSINESSTRIP);
            if( this.draft_filter.is_holiday_work == true ) type_filter.push(approval_enum.APPROVAL_TYPE.HOLIDAY_WORK);
            if( this.draft_filter.is_cash_disbursement_voucher == true ) type_filter.push(approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER);
            if( this.draft_filter.is_free == true ) type_filter.push(approval_enum.APPROVAL_TYPE.FREE);

            response.data.data.approval_info = response.data.data.approval_info.filter(item => type_filter.indexOf(item.approval_type) >= 0);

            this.draft_approvals.splice(0, this.draft_approvals.length);
            this.draft_approvals = this.draft_approvals.concat(response.data.data.approval_info);

        } catch(e) {
            this.hodu_show_dialog('cancel', "상신함 문서 조회 중 오류 발생", ['확인']);
            throw e;
        }

    }

    /**
     * 상신함 조회
     */
    async getApporvalReceiveBox() : Promise<void> {

        try {
            // 검색 타입, 시작일, 종료일
            let query = "?search_mode=RECEIVE";
            query += `&start_date=${moment(this.receive_filter.start).utc().format()}`;
            query += `&end_date=${moment(this.receive_filter.end).utc().format()}`;
            
            // 중요도
            for( const imp_seq of this.receive_filter.importance_filter ) {
                query += `&imp_seq=${imp_seq}`;
            }

            // 웹에서는 조회 할때 탭 조건을 걸지 않는다 (카운팅을 클라이언트에서 해야하기 떄문)
            // if( this.tab != approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL ) {
            //     query += `&approval_state=${this.tab}`;
            // }

            // 클라이언트 타임존
            const timezone = -(new Date().getTimezoneOffset());
            query += `&timezone=${timezone ? timezone : 540}`;

            // 부서
            if( this.receive_filter.dept_ids.length == 0 ) {
                this.receive_approvals.splice(0, this.receive_approvals.length);
                return;
            }

            for( const dept_id of this.receive_filter.dept_ids ) {
                query += `&dept_id=${dept_id}`;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval${query}`, API_METHOD.GET, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("수신함 문서 조회 중 오류 발생");
            }

            if( this.isAttendanceOnly() == true ) {
                response.data.data.approval_info = response.data.data.approval_info.filter(item => item.approval_type == approval_enum.APPROVAL_TYPE.VACATION);
            }

            const type_filter : string[] = [];
            if( this.receive_filter.is_approval == true ) type_filter.push(approval_enum.APPROVAL_TYPE.BASIC);
            if( this.receive_filter.is_vacation == true ) type_filter.push(approval_enum.APPROVAL_TYPE.VACATION);
            if( this.receive_filter.is_businesstrip == true ) type_filter.push(approval_enum.APPROVAL_TYPE.BUSINESSTRIP);
            if( this.receive_filter.is_holiday_work == true ) type_filter.push(approval_enum.APPROVAL_TYPE.HOLIDAY_WORK);
            if( this.receive_filter.is_cash_disbursement_voucher == true ) type_filter.push(approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER);
            if( this.receive_filter.is_free == true ) type_filter.push(approval_enum.APPROVAL_TYPE.FREE);

            response.data.data.approval_info = response.data.data.approval_info.filter(item => type_filter.indexOf(item.approval_type) >= 0)

            this.receive_approvals.splice(0, this.receive_approvals.length);
            this.receive_approvals = this.receive_approvals.concat(response.data.data.approval_info);

            

        } catch(e) {
            this.hodu_show_dialog('cancel', "수신함 문서 조회 중 오류 발생", ['확인']);
            throw e;
        }

    }

    /**
     * 양식 설정 조회
     */
    async getApprovalTemplateInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class?include_templates=${true}`, API_METHOD.GET, null, false);
            
            const approval_templates = response.data.data.approval_templates;

            this.all_templates.splice(0, this.all_templates.length)
            this.all_templates = this.all_templates.concat(approval_templates);

            this.templates.splice(0, this.templates.length)
            this.templates = this.templates.concat(approval_templates.filter(template_class => template_class.template_state == '10'));

            const none_class_template_class  = approval_templates.filter(template_class => template_class.template_state == '0');
            const default_template_class  = approval_templates.filter(template_class => template_class.template_state == '20');
            const vacation_template_class = approval_templates.filter(template_class => template_class.template_state == '30');
            const business_trip_template_class = approval_templates.filter(template_class => template_class.template_state == '40');
            const holiday_work_template_class = approval_templates.filter(template_class => template_class.template_state == '80');
            const cash_disbursement_voucher_template_class = approval_templates.filter(template_class => template_class.template_state == '60');
            const free_template_class = approval_templates.filter(template_class => template_class.template_state == '70');
            const receive_template_class = approval_templates.filter(template_class => template_class.template_state == '50');

            if( none_class_template_class.length > 0 ) this.none_class_template = none_class_template_class[0];
            if( default_template_class.length > 0 ) this.default_template = default_template_class[0];
            if( vacation_template_class.length > 0 ) this.vacation_template = vacation_template_class[0];
            if( business_trip_template_class.length > 0 )  this.business_trip_template = business_trip_template_class[0];
            if( holiday_work_template_class.length > 0 )  this.holiday_work_template = holiday_work_template_class[0];
            if( cash_disbursement_voucher_template_class.length > 0 )  this.cash_disbursement_voucher_template = cash_disbursement_voucher_template_class[0];
            if( free_template_class.length > 0 )  this.free_template = free_template_class[0];
            if( receive_template_class.length > 0 )  this.receive_template = receive_template_class[0];

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 메뉴 고정
     */
    menuToggle() {
        this.is_menu_open = !this.is_menu_open;
    }

    /**
     * 양식없는 기안서 작성
     */
    freeCreate() {
        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.FREE,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    /**
     * 기안서 작성으로 이동
     */
    approvalCreate() {

        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.BASIC,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }
    
    /**
     * 휴가원 작성으로 이동
     */
    vacationCreate() {

        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.VACATION,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    /**
     * 출장신청서 작성으로 이동
     */
    businessTripCreate() : void {
        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.BUSINESSTRIP,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    holidayWorkCreate() {
        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.HOLIDAY_WORK,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    /**
     * 지출결의서 작성으로 이동
     */
    cashDisbursementVoucherCreate() : void {
        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    /**
     * 탭 선택
     */
    selectTab(tab : approval_enum.APPROVAL_BOX_TAB, sub_tab ?: approval_enum.APPROVAL_DRAFT_BOX_TAB | approval_enum.APPROVAL_RECEIVE_BOX_TAB) : void {
        
        // 양식 다운로드를 골랐는데 근태현황만 켜져있는경우는 반환
        if( tab == approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD && this.isAttendanceOnly() == true ) {
            return;
        }

        const before_tab = this.selected_tab;
        
        this.doSetSelectedTab?.(tab);

        // 서브탭 선택
        switch( this.selected_tab ) {
            case approval_enum.APPROVAL_BOX_TAB.DRAFT:
                if( sub_tab == null ) return;
                this.doSetSelectedDraftTab?.(sub_tab as approval_enum.APPROVAL_DRAFT_BOX_TAB);
                break;

            case approval_enum.APPROVAL_BOX_TAB.RECEIVE:
                if( sub_tab == null ) return;
                this.doSetSelectedReceiveTab?.(sub_tab as approval_enum.APPROVAL_RECEIVE_BOX_TAB);
                break;
        }

        this.getApproval();

        // 탭 재선택시 재조회 시키기
        if( before_tab == this.selected_tab && this.selected_tab == approval_enum.APPROVAL_BOX_TAB.APPROVER_FAVORITE ) {
            this.event_bus.$emit('getApprovalApproverFavorites');
        }
    } 

    /**
     * 선택된 탭이 상신함
     */
    isDraft() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.DRAFT;
    }

    /**
     * 상신함 탭 + 전체 탭
     */
    isDraftAll() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL;
    }

    /**
     * 상신함 탭 + 진행 탭
     */
    isDraftIng() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.ING;
    }

    /**
     * 상신함 탭 + 완료 탭
     */
    isDraftConfirm() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.COMPLETE;
    }

    /**
     * 상신함 탭 + 반려 탭
     */
    isDraftReject() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.REJECT;
    }

    /**
     * 상신함 탭 + 임시 탭
     */
    isDraftTemp() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.TEMP;
    }

    /**
     * 상신함 탭 + 취소 탭
     */
    isDraftCancel() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.CANCEL;
    }

    /**
     * 선택된 탭이 수신함
     */
    isReceive() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.RECEIVE;
    }

    /**
     * 수신함 탭 + 대기 탭
     */
    isReceiveWait() : boolean {
        return this.isReceive() && this.selected_receive_tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT;
    } 

    /**
     * 수신함 탭 + 요청 탭
     */
    isReceiveRequest() : boolean {
        return this.isReceive() && this.selected_receive_tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST;
    }

    /**
     * 수신함 탭 + 내역 탭
     */
    isReceiveHistory() : boolean {
        return this.isReceive() && this.selected_receive_tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.HISTORY;
    }

    /**
     * 수신함 탭 + 수신참조 탭
     */
    isReceiveReference() : boolean {
        return this.isReceive() && this.selected_receive_tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.REFERENCE;
    }

    /**
     * 선택된 탭이 양식함
     */
    isForm() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD || this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_SETTING;
    }

    /**
     * 양식 다운로드
     */
    isFormDownload() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD;
    }

    /**
     * 양식 설정
     */
    isFormSetting() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_SETTING;
    }

    /**
     * 결재자 즐겨찾기
     */
    isApproverFavorite() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.APPROVER_FAVORITE;
    }

    /**
     * 근태관리 기능만 ON 인 상태
     */
    isAttendanceOnly() : boolean {
        return (this.isEnableGroupFeature(this.scope_group_id, 'approval') == false && this.isEnableGroupFeature(this.scope_group_id, 'attendance') == true);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#approval_menu_scroll').mCustomScrollbar('destroy');
        this.setScroll();

        if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.DRAFT ) this.event_bus.$emit('draftResize');
        else if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.RECEIVE ) this.event_bus.$emit('receiveResize');
        else if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD ) this.event_bus.$emit('formDownloadResize');
        else if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_SETTING ) this.event_bus.$emit('formSettingResize');
        else if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.APPROVER_FAVORITE ) this.event_bus.$emit('approvalApproverFavoriteResize');
    }

}
        

    
</script>

<style>
#approval .approval_content.up2 .content_top h3 { float: left; }
#approval .approval_menu{opacity: 1;-webkit-transition: .1s;transition: .1s;position: absolute;left: 0;top: 62px;bottom: 0;background-color: #fff;width: 260px;padding: 0;border-right: 1px solid #e1e4e6;z-index: 100;}
#approval .approval_menu .approval_wrtie{width: 100%;line-height: 64px; height: 68px; border-bottom: 1px solid #e7e9ea;padding: 0 20px;position: relative;-webkit-box-sizing: border-box;box-sizing: border-box;}
/* #approval .approval_menu .app_wrt_btn{background-color: #477fff !important;font-size: 13px !important; font-weight: bold; color:#ffffff;padding: 0 50px !important;width:100%;height: 42px;border-radius: 5px !important;} */
#approval .approval_wrtie {  z-index: 1000; z-index: 100; right: 40px; bottom: 40px; position: absolute; }
#approval .app_wrt_btn { opacity: 1;transition: 0s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 53px !important;background-color:#477FFF !important;box-shadow: 0 10px 15px rgba(71, 127, 255, 0.3) !important;  }
#approval .app_wrt_btn:hover {background: #477fff url('../../assets/images/contents/btn_add_fff.png') no-repeat center center;transition: 0s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 60px !important;}
#approval .approval_wrtie.on .app_wrt_btn { background-color: #FF6363 !important; background-image : url('../../assets/images/contents/btn_close_fff.png'); }

#approval .approval_wrtie .inbox input { display:inline-block; width: 60px; height: 60px; transition:0.2s; border:1px solid #e3e7ed; background-position:center center; background-size: 63px; background-repeat: no-repeat; border-radius:50%; background-color : #f1f3f5; }
#approval .approval_wrtie:hover .inbox, .approval_wrtie.on .inbox { display: block !important; }
#approval .approval_wrtie .event_btn { position: relative; margin-top:10px; margin-bottom:20px; width: 60px; height: 60px; }
#approval .approval_wrtie .event_btn input:hover { box-shadow:0 3px 10px rgba(0,0,0,0.15); border-color:#fff; }
#approval .approval_wrtie p { position: absolute; left: -100px; top: 50%; margin-top: -20px; height: 40px; display: inline-block; line-height: 40px; font-size: 13px; font-weight: bold; width: 90px; text-align: right; z-index: 100; color: #202A39; }
#approval .approval_wrtie .inbox { display:none; margin-bottom:10px; }
#approval .approval_wrtie .btn_day_off { background-image: url(../../assets/images/contents/ic_day_off.png); background-repeat: no-repeat; background-position: center center; }
#approval .approval_wrtie .btn_form { background-image: url(../../assets/images/contents/ic_form.png); background-repeat: no-repeat; background-position: center center; }
#approval .approval_wrtie .btn_form.btn_expenses { background-image: url(../../assets/images/contents/ic_expenses.png); background-repeat: no-repeat; background-position: center center; }
#approval .approval_wrtie .inbox input.btn_business_trip { background-image: url(../../assets/images/contents/ic_business_trip.png); background-repeat: no-repeat; background-position: center center; background-size: 36px; }
#approval .approval_wrtie .inbox input.btn_holiday_work { background-image: url(../../assets/images/contents/ic_holiday_work.png); background-repeat: no-repeat; background-position: center center; }
#approval .approval_wrtie .inbox p { text-shadow : -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff; }

#approval .approval_menu .miniDep_box{border-bottom: 1px solid #e7e9ea;position: relative;overflow: hidden;margin-bottom: 0;-webkit-transition: 0.1s;transition: 0.1s;padding: 0;line-height: 50px;width: 100%;color: #8091a8;-webkit-box-sizing: border-box;box-sizing: border-box;font-size: 14px;font-weight: bold;}
#approval .miniDep_box .miniDep{float: left;position: relative;overflow: hidden;margin-bottom: 0;-webkit-transition: 0.1s;transition: 0.1s;padding: 0 0 0 20px;line-height: 57px;width: 100%;-webkit-box-sizing: border-box;box-sizing: border-box;font-size: 15px;font-weight: bold;}
#approval .approval_menu .click .miniDep{color: #232848 !important;}
#approval .ApprovalBox_menu li a {font-size: 12px; transition: 0.2s;}
#approval .ApprovalBox_menu li:hover a { font-weight: bold; }
#approval ul.FormBox_menu li:hover a { font-weight: bold; } 
#approval .ApprovalBox_menu li a:after {content:''; display: block; position: absolute; z-index: 100; left:0; top:0; width:100%; height: 29px;   }
#approval a.appbox1 {background: #fff url(../../assets/images/side_area/ic_downopen.png) no-repeat center center;position: absolute;top: 13px;right: 15px;width: 30px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 0;height: 30px;background-size: 11px !important;}
#approval a.appbox2 {background: #fff url(../../assets/images/side_area/ic_downopen.png) no-repeat center center;position: absolute;top: 13px;right: 15px;width: 30px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 0;height: 30px;background-size: 11px !important;}
#approval a.appbox3 {background: #fff url(../../assets/images/side_area/ic_downopen.png) no-repeat center center;position: absolute;top: 13px;right: 15px;width: 30px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 0;height: 30px;background-size: 11px !important;}
#approval a.appbox4 {background: #fff url(../../assets/images/side_area/ic_downopen.png) no-repeat center center;position: absolute;top: 13px;right: 15px;width: 30px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 0;height: 30px;background-size: 11px !important;}
#approval a.on{background: #fff url(../../assets/images/side_area/ic_upclose.png) no-repeat center center!important;background-size: 11px !important;}
#approval h3.on{color: #232848 !important;}
#approval .miniDep_box ul.ApprovalBox_menu {display: none;position: static;transition: 0.1s; padding-bottom: 26px;clear: both;float: left;width: 100%;-webkit-transition: 0.1s;transition: 0.1s;background: #fff;margin-top: 4px;}
#approval .miniDep_box ul.ApprovalBox_menu li{line-height: 20px;height: 20px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 12px;padding-left: 30px;font-weight: normal;-webkit-box-sizing: border-box;box-sizing: border-box;position: relative;margin: 0px; margin-bottom: 10px;}
#approval .miniDep_box ul.ApprovalBox_menu li span {display: inline-block;width: 7px;height: 7px;margin-top: 10px;margin-right: 10px;border-radius: 50%;}
#approval .miniDep_box ul.ApprovalBox_menu li span#s_stop {border:1px solid #b7b8b9;background: #eceeef;}
#approval .miniDep_box ul.ApprovalBox_menu li span#s_ing {border:1px solid #40c8a0;background: #40c8a0;}
#approval .miniDep_box ul.ApprovalBox_menu li span#s_return {border:1px solid #ff5872;background: #ff5872;}
#approval .miniDep_box ul.ApprovalBox_menu li span#s_pendency {border:1px solid #b8bbce;background: #b8bbce;}
#approval .miniDep_box ul.ApprovalBox_menu li span#s_complete {border:1px solid #477fff;background: #477fff;}
#approval .miniDep_box ul.ApprovalBox_menu li span#s_temporary {border:1px solid #ffbc58;background: #ffbc58;}
#approval .miniDep_box ul.ApprovalBox_menu li span#s_cancel {border:1px solid #b8bbce;background: #b8bbce;}
#approval .miniDep_box ul.ApprovalBox_menu li.all_list{font-weight:bold;}
#approval .miniDep_box ul.ApprovalBox_menu li.set_list{font-weight:bold;}
#approval .miniDep_box ul.ApprovalBox_menu li span.app_num{background:none; position: absolute;top: 1px;right: 25px;margin: 0;}
#approval .miniDep_box ul.ApprovalBox_menu li.set{border-right:3px solid #477fff;font-weight: bold;}
#approval .miniDep_box ul.ApprovalBox_menu li.set span.app_num {right:22px;}
#approval ul.FormBox_menu li a{display: block;}
#approval ul.FormBox_menu{display: none;position: static;transition: 0.1s; padding: 0 0 15px;clear: both;float: left;width: 100%;-webkit-transition: 0.1s;transition: 0.1s;background: #fff;}
#approval ul.FormBox_menu li{line-height: 20px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 12px;padding-left: 30px;font-weight: normal;-webkit-box-sizing: border-box;box-sizing: border-box;position: relative;margin: 3px 0 0; margin-bottom: 10px;}
#approval ul.FormBox_menu li.set{border-right:3px solid #477fff;font-weight: bold;}
#approval ul.PreserveBox_menu li a.all_list{font-weight:bold;}
#approval ul.PreserveBox_menu li.set_list{font-weight:bold;}
#approval ul.PreserveBox_menu li span {display: block;width: 20px;margin-top: 10px;margin-right: 10px;border-radius: 50%;}
#approval ul.PreserveBox_menu li span.app_num{background:none; position: absolute;top: 1px;right: 25px;margin: 0;}
#approval ul.PreserveBox_menu li p.set span.app_num{right: 22px;}
#approval ul.PreserveBox_menu li p{position: relative;}
#approval ul.PreserveBox_menu li p.set{border-right:3px solid #477fff;font-weight: bold;}
#approval ul.PreserveBox_menu {display: none;position: static;transition: 0.1s; padding: 10px 0 15px;clear: both;float: left;width: 100%;-webkit-transition: 0.1s;transition: 0.1s;background: #fff;}
#approval ul.PreserveBox_menu li{line-height: 26px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 12px;padding-left: 20px;font-weight: normal;-webkit-box-sizing: border-box;box-sizing: border-box;position: relative;margin: 3px 0 0;}
#approval ul.PreserveBox_menu li a{width: 40px;display: block;text-align: right;}

#approval .approval_content{opacity: 1;-webkit-transition: .1s;transition: .1s;position: absolute;top:62px;left: 261px;right: 0px;padding: 0 0 50px 0;border-right: 1px solid #e1e4e6;z-index: 100;}
#approval .approval_content .content_top{position: relative; height: 67px;border-bottom: 1px solid #e7e9ea;}
/* 23.03.07 수정 */
#approval .approval_content .content_top h3{line-height: 40px;height: 100%;font-size: 14px;width: auto;padding: 13px 0 0 3px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;float: right;box-sizing: border-box;}
#approval .approval_content .content_top .bt_hambuger{position: relative;margin-left: 0px;padding: 11px 30px 0 0 ;display: inline-block; float: right; width: 40px;height: 100%;background: url(../../assets/images/contents/bt_hambuger.png) no-repeat center center;box-sizing: border-box;margin-right: 20px;}
#approval .approval_content .content_top .bt_hambuger2{position: relative;margin-left: 20px;padding: 11px 0 0 30px;display: inline-block;width: 40px;height: 100%;background: url(../../assets/images/contents/bt_hambuger.png) no-repeat center center;box-sizing: border-box;}
#approval .approval_content .content_top #filter_search{/*position: absolute;top: 12px;left:135px;*/width: 355px;-webkit-transition: 0.2s;transition: 0.2s;height: 40px;line-height: 40px;padding: 0px 15px;font-size: 12px;font-weight: normal;-webkit-box-sizing: border-box;box-sizing: border-box;border: 1px solid #e7e9ea;display: inline-block;float: right; margin: 12px 15px 0 0;}
#approval .approval_content .content_top .cont_del{font-size: 13px;font-weight:bold;position: absolute;top: 12px;right:4px;padding: 11px 0 0 10px;line-height: 40px;display: inline-block;padding: 0px 30px;/*border: 1px solid #dddddd;*/}
#approval .approval_content .content_top .cont_del2{font-size: 13px;font-weight:bold;position: absolute;top: 12px;right:95px;line-height: 40px;display: inline-block; margin-right: 30px;/*border: 1px solid #dddddd;*/}
#approval .approval_content .content_top .down{font-size: 13px;font-weight:bold;position: absolute;top: 12px;right:5px;line-height: 40px;display: inline-block; margin-right: 30px;color:#477fff;/*border: 1px solid #dddddd;*/}
#approval .approval_content .content_top .add_template{font-size: 13px;font-weight:bold;position: absolute;top: 12px;right:175px;line-height: 40px;display: inline-block; margin-right: 30px;color:#477fff;/*border: 1px solid #dddddd;*/}
#approval .approval_content .content_top .appLocation { display: inline-block; vertical-align: top; padding: 25px 0 0 30px; font-weight: bold; font-size: 18px; }
#approval .approval_content .content_top .appLocation:before { content: ''; display: inline-block; width: 25px; height: 25px; vertical-align: middle; margin-top: -2px; margin-right: 8px; }
#approval .approval_content .content_top .appLocation.appDraftLc:before { background: url('../../assets/images/contents/ic_draft_box.png') no-repeat center center; background-size: 100% auto; }
#approval .approval_content .content_top .appLocation.appReceiveLc:before { background: url('../../assets/images/contents/ic_receive_box.png') no-repeat center center; background-size: 100% auto; }

/*20.10.27 추가*/
#approval .sortHeader > li {position: relative;display: inline-block;padding: 0;margin: 0 !important;}
#approval .section_scroll .group{width: 16%;}
#approval .section_scroll .group > ul > li{margin-left: 45px;}
#approval .headerSort {float: left;padding: 8px 0 7px 0;cursor: pointer;}
#approval .section_scroll .title{width: 36%;}
#approval .section_scroll .title > span{margin-left: 15px;}
#approval .section_scroll .writtenBy{width: 16%;}
#approval .section_scroll .writtenBy > span{margin-left: 26px;line-height: 60px;}
#approval .section_scroll .time{width: 17%;}
#approval .section_scroll .lastday{width: 19%;margin-left: 13px;}
#approval .section_scroll .lastday > span{margin-left: 8px;}
#approval .section_scroll .status{width: 19%;position: relative;}
#approval .section_scroll .status > span, #approval .section_scroll .status > ul{margin-left: 13px;}
#approval .content .notiListUl > li{-webkit-box-sizing: border-box;box-sizing: border-box;position: relative;/* width: 100%; */border-bottom: 1px solid #f1f3f5;height: 87px;line-height: 87px;}
#approval .up2 .content .notiListUl > li{height: 60px;line-height: 60px;}
#approval .up1 .content .notiListUl > li{height: 80px;line-height: 80px; transition: 0.2s; }
#approval .up1 .content .notiListUl > li:hover { cursor: pointer; background:#f8f9fa; }
#approval .section_scroll .content .notiListUl > li p.notiCheck{/*display: none;*/}
#approval .content .notiListUl > li p.num, #approval .content .notiListUl > li p.notiCheck{padding-right: 0;}
#approval .up1 .content .notiListUl > li p{line-height: 80px;height: 80px;}
#approval .up1 .content .notiListUl > li p.num{line-height: 80px;}
#approval .content .notiListUl > li p{display: inline-block;font-weight: bold;-webkit-box-sizing: border-box;box-sizing: border-box;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
#approval .section_scroll .num{width: 10%;text-align: center;font-weight: bold;}
#approval #approval_form_download .section_scroll .num{width: 18%;}
#approval .section_scroll .notiCheck{width: 10.5%; text-align: center;}
#approval #noticeScroll .sortHeader input[type='checkbox']:not(old) + label{margin-top: 18px;}
#approval #noticeScroll input[type='checkbox']:not(old) + label{margin-top: 16px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
#approval .up1 .content p.notiCheck label{margin-top: 35px !important;}
#approval span.chk{margin-left: 0 !important;width:30px;height:50px;font-size:0;background: url(../../assets/images/side_area/ic_downchk.png) no-repeat center center !important;background-size: 15px !important;display: inline-block;}

#approval .content .notiListUl > li div.num, #approval .content .notiListUl > li p.notiCheck{padding-right: 0;}
#approval .content .notiListUl > li div{display: inline-block;font-weight: bold;-webkit-box-sizing: border-box;box-sizing: border-box;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
#approval .title_info li.list_title{margin-left: 15px;line-height:75px;height:55px;font-size:13px;}
#approval .title_info li.list_day{margin-left: 15px;line-height:10px;height:35px;color:#aaa;font-weight:normal;}
#approval .status_info {width:80%;line-height: 80px;height: 80px;}
#approval .status_info li.list_Progress{height: 25px;font-size: 13px;position: relative;line-height: 44px;padding-top: 20px;}
#approval .status_info li.list_Protext{line-height:10px;height:35px;color:#aaa;font-weight:normal;}
#approval .status_info li.list_Protext > span{color:#000;font-weight: bold;}
#approval .up1 .content #approval_list ul.status_info .list_Protext span.ing_num{position: absolute;top: 0px;right: 5px;}
#approval .group_info li.list_group{line-height:75px;height:55px;font-size:13px;}
#approval .group_info li.list_rank{line-height:10px;height:35px;color:#aaa;font-weight:normal;}
#approval .notiListUl li p.status span.Progress_bar{background: #eceeef;font-size: 0px;display: block;height: 11px;border-radius: 20px;width:100%;font-size:0;}
#approval .notiListUl li p.status span.Progress_ing{position: absolute;top: 20px;left: 0;right: 0;font-size: 0px;display: block;height: 11px;border-radius: 20px;font-size:0;}
#approval .quick{color:#fff;border-radius:3px;background-color:#ff1955;padding:1px 5px;margin-right:6px;font-size: 11px;font-weight: bold;}
#approval .important {color:#fff;border-radius:3px;background-color:#ffc72f;padding:1px 5px;margin-right:6px;font-size: 11px;font-weight: bold;}

#approval .section_scroll .title2{width: 39%;}
#approval .section_scroll .group2{width: 22%;}
#approval .section_scroll .writtenBy2{width: 21%;}
#approval .section_scroll .writtenBy2 > span{/*margin-left: 26px;*/}
#approval .section_scroll .group2 > ul > li{/*margin-left: 38px;*/}
#approval .title_info2 li.list_title{margin-left: 15px;font-size:13px;}
#approval .group_info2 li.list_group{font-size:12px;}

/*필터링내용*/
#approval #toggle_contents {height:0;overflow:hidden;}
#approval #toggle_button:checked + #toggle_contents {height: 100px;position:relative;border:1px solid #ccc;border-top:0;padding:10px;}
#approval .close_button {cursor:pointer;position:absolute;top:5px;right:5px;padding:3px;font-size:10px;line-height:1;background:#555;color:#fff;}
#approval input.input_check0[type='checkbox']:not(old) + label{display: inline-block;padding: 0;width: 13px;height: 13px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 13px;font-size: 0;margin: 0 8px 15px 15px;}
#approval input.input_check0[type='checkbox']:not(old){margin: 0;padding: 0;opacity: 0;background: url(../../assets/images/contents/ra_check_on.png) no-repeat 0 5px;width: 0;}
#approval input.input_check0[type='checkbox']:not(old):checked + label{background-color: #477fff !important;border-color:#477fff !important;}
#approval .Search_li01{width:105px;padding: 10px 25px;line-height: 35px;border-right:1px solid #e7e9ea;border-bottom: 1px solid #e7e9ea;float: left;box-sizing: border-box;height: 55px;font-size:14px;text-align: center;}
#approval .Search_li02{width: calc(100% - 105px);padding: 10px 30px;line-height: 35px;border-bottom: 1px solid #e7e9ea;float: left;box-sizing: border-box;height: 55px;font-weight: bold;font-size:14px; overflow: hidden; white-space: nowrap;}
#approval .nav_item{display: none;}
#approval .nav_item2{display: none;}
#approval .nav_item2 ul li{position: relative;padding: 0 28px;width:50%;box-sizing: border-box; display:inline-block;line-height: 50px;border-bottom:1px solid #e7e9ea;border-right: 1px solid #e7e9ea;}
/*#approval .nav_item2 ul li:nth-child(3n){border-right:0;}*/
#approval .open{display: block!important;overflow:hidden;}
#approval .change{background: url(../../assets/images/contents/ic_filtercan_btn.png) no-repeat center center !important;background-size: 35px !important;}
#approval .change2{background: url(../../assets/images/side_area/bt_close.png) no-repeat center center !important; background-color:#f1f3f5 !important }

/*트리조직도*/
#approval #wrapper { padding-left:20px; width: 100%;height: 100%;box-sizing: border-box;}
#approval label { z-index: 1000; position: relative; padding-left: 10px }
#approval .chk { height: 33px; line-height: 35px; text-align: left;position: relative;}
#approval .cld { height: 33px; line-height: 35px; padding-left: 10px; text-align: left;position: relative;margin-left: 20px;}
/* 최하위 왼쪽 패딩 더 주기 */
#approval .sub2 .cld { padding-left: 18px; text-align: left;margin-left: 40px;}
/*체크박스 디자인*/
#approval .chk input{margin: 0;padding: 0;opacity: 0;width: 0;}
#approval .approval_content input[type='checkbox']:not(old)+label{margin-top: 6px;display: inline-block;padding: 0;width: 14px;height: 14px;border-radius: 1px;border: 2px solid rgb(193, 201, 212);background: rgb(193, 201, 212) url(../../assets/images/contents/checked_fff.png) no-repeat center;background-size: 17px;margin-right: 8px;}
#approval .approval_content input[type='checkbox']:not(old):checked + label{background-color: #477fff !important;border-color: #477fff;}
/*체크박스 텍스트*/
#approval .chk label span{position: absolute;left: 30px;top: 0px;}
#approval .cld label span{position: absolute;left: 37px;top: 0px;}
#approval .sub2 .cld label span{position: absolute;left: 38px;top: 0px;}
/* 그룹과 팀 리스트에 적용 */
#approval ul li { position: relative;  }
/* 버튼 */
#approval .arw { background: none;color: inherit;border: none;padding: 0;font: inherit;cursor: pointer;outline: inherit; z-index: 1000; position: absolute; left: 5px;top:13px; width: 11px; height: 11px; width: 0;height: 0; border-bottom: 4px solid transparent;border-top: 4px solid transparent;border-left: 6px solid #000;cursor: pointer; }
/* 상단 삐져나오는 라인 감추기 */
#approval .topBlank { z-index: 100; position: absolute; top: 0; width: 15px; height: 14px; background: #fff; display: block; }
/* 하단 삐져나오는 라인 감추기 */
#approval .btmBlank { z-index: 100; position: absolute; bottom: 0; width: 15px; height: 17px; background: #fff; display: block; }
/* 세로 선 */
#approval .vertiLine { height: 100%; width: 1px; background: #dadbde; display: none; position: absolute; left: 8px; top: 0; }
/* 가로 선 */
#approval .horiLine { width: 35px; height: 1px; background: #dadbde; display: none; position: absolute; top: 15px; left: 8px; }
/* 최하위 그룹 디폴트로 감추기 */
#approval .sub2 { display: none; }
/* 팀 보일때 선이랑 다같이 보이기 */
#approval .showTeam > .sub2 { display: block; }
#approval .showTeam .horiLine, #approval .showTeam .vertiLine { display: block; }
#approval .showTeam > input.arw {border-left: 4px solid transparent;border-right: 4px solid transparent;border-top: 6px solid #000;}

#approval .sub2 > li > ul.level4 > li > input.arw { left : 0; top : 10px; }
#approval .sub2 > li > ul.level4 > li.showTeam > input.arw { top : 12px; }
#approval .sub2 > li > ul.level4 > li.showTeam > span.vertiLine { left : 3px; }
#approval .sub2 > li > ul.level4 > li.showTeam > ul.sub2 > li > span.horiLine { left : 3px; top : 14px; }

/*스크롤바디자인변경*/
#approval ::-webkit-scrollbar{width: 6px; height : 6px;}
#approval ::-webkit-scrollbar-track{background-color: transparent;}
#approval ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

#approval div.nav_item2 ul li label{margin-top: 13.5px !important; margin-right: 16px !important;}
#approval div.nav_item2 ul li span.amount{position: absolute; top: 17px; right: 30px;}
#approval div.nav_item2 ul li input.input_check[type='checkbox']:not(old):checked + label span {font-weight: bold;}
#approval div.nav_item2 ul li label span.temp_text{position: absolute;left: 65px;top: 17px;}
#approval p.writtenBy2:hover a.download{display: block;}
#approval a.download{display: none; background: url(../../assets/images/contents/ic_download_b.png) no-repeat center center !important;width: 40px;height: 40px;position: absolute;top: 10px;right: 40px;}
#approval div.Template_setting{ transition: 0.2s; font-size:13px;margin: 0 40px 0 30px;padding:16px 0 16px 20px;position: relative;border-bottom:1px solid #e7e9ea;line-height: 25px;}
#approval .more_temp { float:right; background: #fff url(../../assets/images/side_area/bt_open.png) no-repeat center center;width: 35px;-webkit-transition: 0.2s;transition: 0.2s;font-size: 0;height: 35px; border-radius:50%; cursor: pointer; margin-top:-5px}
#approval .text_more{display: none; width:100%;}
#approval .text_more li{float:left;position: relative; padding: 10px 0 3px 34px;font-weight: bold;width: 100%;box-sizing: border-box; display: inline-block;min-width: 337px;}
#approval .text_more li:first-child { margin-top: 5px; }
#approval .text_more li:last-child { margin-bottom: 25px; }
#approval .text_more li:hover >.close_end{display: inline-block;}
#approval .text_more li .list_name,#approval .text_more li a.plus_btn { display: inline-block; word-wrap:break-word; word-break: break-all; line-height: 25px; padding-left: 15px; height: 30px; vertical-align: super; font-size: 13px; max-width: 80%; overflow:hidden; text-overflow: ellipsis; white-space: nowrap;}
/* #approval .text_more li span.plus_file{ float:left;cursor: pointer; width:44px;height: 44px;border: 2px dashed #477fff;background: rgb(232, 239, 255); border-radius: 10px; display: inline-block;font-size: 25px;line-height: 39px;text-align: center;color: #477fff; box-sizing: border-box;} */
#approval .text_more li a.plus_btn { line-height: 46px;}
#approval .text_more li span.in_file{ transition:0.1s; width:44px;height: 44px;border-radius: 10px;background: rgb(241, 243, 245) url(../../assets/images/contents/ic_docs_doc.png) no-repeat center;font-size: 0;display: inline-block;background-size: 25px;vertical-align: top;}
#approval .text_more li:hover span.in_file { background-color: #fff; box-shadow: 0 2px 6px rgba(0,0,0,0.1); }
#approval .close_end{ opacity: 0.5; font-weight: bold; cursor: pointer; height: 44px; width:100%; background:none; float:right; box-sizing: border-box; width:100%; text-align: right;}
#approval .text_more li .close_end:hover { opacity: 1; }

#container #section_ce.left_area_close #approval .approval_menu { left : 61px; }
#container #section_ce.left_area_close #approval .approval_content { left : 322px; }
#container #section_ce.left_area_close #approval .section_scroll { padding-left : 0px; }

#container #section_ce .section_scroll {
    min-width: 0px;
}

/* 결재자 즐겨찾기 */
#approval .approval_menu .miniDep_box.set { border-right: 3px solid #477fff; }
#approval .approval_menu a#addGrp { top: 14px; }

@media screen and (max-width: 1400px) {
    #approval .text_more li .list_name,#approval .text_more li a.plus_btn {display: inline-block; word-wrap:break-word; word-break: break-all; line-height: 25px; height: 30px; vertical-align: super; font-size: 13px; width: 300px;}
}

@media screen and (max-width: 1280px){
    #approval .text_more li .list_name,#approval .text_more li a.plus_btn {display: inline-block; word-wrap:break-word; word-break: break-all; line-height: 25px; height: 30px; vertical-align: super; font-size: 13px; width: 250px;}
}

/* @media screen and (max-width: 1110px){
    #approval .text_more li .list_name,#approval .text_more li a.plus_btn {display: inline-block; word-wrap:break-word; word-break: break-all; line-height: 25px; height: 30px; vertical-align: super; font-size: 13px; width: 65%;}
} */

#approval .approval_disabled { opacity : 0.6; cursor : default; }
#approval .approval_disabled * { cursor : default !important; }
</style>