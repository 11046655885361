<template>
    <div class="schedule_box">

        <!-- grp -->
        <div class="grp settingBg">
            
            <!-- posRel -->
            <div class="posRel">

                <!-- bg -->
                <div class="bg">
                    
                    <!-- bigGrp -->
                    <div class="bigGrp left">
                        
                        <div class="grp">
                            <label for="" class="from blind">시작일자</label>
                            <input type="button" class="calFrom" :id="`${id}_fr_ymd`" :value="start_value">
                        </div>

                        <span class="seper">에서</span>

                        <div class="grp">
                            <label for="" class="to blind">종료일자</label>
                            <input type="button" class="calTo" :id="`${id}_to_ymd`" :value="end_value">
                        </div>

                        <!-- 이전 다음 오늘 버튼 -->
                        <input type="button" value="" class="toolbar-btn bt-prev mr10" @click.prevent="prev"/>
                        <input type="button" value="" class="toolbar-btn bt-next mr10" @click.prevent="next"/>
                        <input type="button" value="오늘" class="toolbar-btn today" @click.prevent="today"/>
                    </div>
                    <!-- bigGrp -->

                </div>
                <!-- bg -->

            </div>
            <!-- posRel -->

        </div>
        <!-- grp -->

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ScheduleBoxDatepicker extends Mixins(VueHoduCommon) {

    @Prop() id !: string;
    @Prop() start !: Date;
    @Prop() end !: Date;
    @Prop() onSelect !: Function;

    start_value : string = "";
    end_value : string = "";

    mounted() : void {
        this.make_value();

        let datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange : '1900:2050',
            onSelect : (dateText, inst) => {
                inst.input.val(this.createDateFormat(dateText));
                this.onSelect(dateText, inst);
            }
        };

        // @ts-ignore
        $(`#${this.id}_fr_ymd`).datepicker(datepicker_option);

        // @ts-ignore
        $(`#${this.id}_to_ymd`).datepicker(datepicker_option);
    }

    prev() : void {
        this.$emit('prev');
    }

    next() : void {
        this.$emit('next');
    }

    today() : void {
        this.$emit('today');
    }

    createDateFormat(request_date : Date) : string {
        if( request_date == null ) return "";

        if( !(request_date instanceof Date) ) {
            request_date = new Date(moment(request_date).format());
        }

        const year  : string  = `${request_date.getFullYear()}`;
        const month : string = `0${request_date.getMonth() + 1}`.slice(-2);
        const date  : string  = `0${request_date.getDate()}`.slice(-2);

        return `${year}.${month}.${date} ${this.getDayOfWeekByDate(request_date)}`;
    }

    make_value() : void {
        this.start_value = this.createDateFormat(this.start);
        this.end_value = this.createDateFormat(this.end);
    }

    @Watch('start')
    @Watch('end')
    watchStartEnd() {
        this.make_value();
    }

}
</script>

<style scoped>
    .settingBg .left {float: left;margin-left: 30px;margin-right: 0;}
	.settingBg .left .grp input {position: static;width: 125px;padding-right:20px;text-align: left;margin-right: 13px;background: #fff url(../../assets/images/side_area/bt_open.png) no-repeat right center;}
	.settingBg .left .grp input:hover { color:#477fff; }
	.settingBg .left .grp input:focus {color:#477fff; background: #fff url(../../assets/images/side_area/bt_close.png) no-repeat right center;}
	.settingBg .left .grp .bg {display: none;}
	.settingBg .left .grp {width: auto;min-width: auto !important;max-width: none !important;padding: 0;margin: 0;float: left;}
	.settingBg .seper {background:#dbdfe0;float: left;margin-top: 20px;height: 2px;margin-right: 20px;}
	.settingBg .line {display:block;width: 2px;height: 15px;margin-top: 25px;margin-right: 0;background: #e7e9ea;float:right;}
    .toolbar-btn.today, .bt-next, .bt-prev {margin-top: 0 !important;}
</style>