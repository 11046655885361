<template>
    <div class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">프로젝트 설정</h3>
        </div>

        <div id="workAddScroll" class="section_scroll addPageDefault"><!--스크롤 생겨야할 영역-->
            <div class="viewGroup">					
                <div class="schedule_box">

                    <div class="grp settingBg">
                        <h3 class="selectTitle"><span class="txt">{{ is_create == true ? '프로젝트 추가' : '프로젝트 수정' }}</span></h3>
                        <p class="btn_group">
                            <input type="button" id="btnSave" value="저장" class="input_btn" @click="saveTemplate"/>
                            <!-- <input type="button" id="btnSave" value="취소" class="input_btn" @click="moveBackPage"/> -->
                            <input type="button" id="btnDel"  value="삭제" class="input_btn" @click="deleteTemplate" v-if="is_create == false && getWorkPermission('delete') == true"/>
                        </p>
                    </div>

                    <!-- 체크식일시 content에 addClass(check) -->
                    <div class="content check" id="work_template_scroll" :class="{ check : template != null && template.template_type == 'CHECK' }">
                        <div class="content_container">
                            <div class="first">
                                <!-- 업무명 영역 -->
                                <div class="sec1">
                                    <h4>1. 프로젝트명 작성</h4>
                                    <input type="text" placeholder="프로젝트명을 입력하세요" :value="template != null ? template.template_info.name : ''" @input="template != null ? template.template_info.name = $event.target.value : 'return;'"/>
                                </div>
                                <!-- 업무명 영역 끝 -->
                                <!-- 업무 개수 영역 -->
                                <div class="sec2">
                                    <h4>2. 업무 개수</h4>
                                    <input type="button" :value="template != null ? `${ template.template_info.content.length } 개` : ''" 
                                           :class="{ on : section_three_list_on == true }" @click="sectionThreeListOnOff"/>
                                    <ul class="dropdown" :class="{ on : section_three_list_on == true }">
                                        <li :key="n" v-for="n in 5">
                                            <a :class="{ on : template != null && template.template_info.content.length == n }" @click="workProcessCountChange(n)">
                                                {{ `${n} 개` }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- 업무 개수 영역 끝 -->
                                <!-- 다중 선택 영역 -->
                                <div class="sec3">
                                    <h4>3. 다중 선택(병행 작업)</h4>
                                    <input type="button" :disabled="template == null || template.template_info.content.length < 2" 
                                           :value="template != null ? template.template_info.is_multi_select == true ? '가능' : '불가능' : '불가능'" 
                                           :class="{ on : section_two_list_on == true }" @click="sectionTwoListOnOff"/>
                                    <ul class="dropdown" :class="{ on : section_two_list_on == true }">
                                        <li><a :class="{ on : template != null && template.template_info.is_multi_select == true  }" @click="changeIsMultiSelectType(true)">가능</a></li>
                                        <li><a :class="{ on : template != null && template.template_info.is_multi_select == false }" @click="changeIsMultiSelectType(false)">불가능</a></li>
                                    </ul>
                                </div>
                                <!-- 다중 선택 영역 끝 -->
                            </div>

                            <div class="seconds">
                                <h4>4. 상세 설정</h4>
                                <p>* 업무는 담당자가 이해하기 쉽게 짧고 간결하게 작성하시기 바랍니다.</p>
                                <ul class="work_process_list" v-show="template != null && template.template_info.content.length > 0">
                                    <li :key="index" v-for="(content, index) in (template != null ? template.template_info.content : [])" 
                                        :class="{ check : template != null && template.template_type == 'CHECK' }">
                                        <h5 class="work dc9" :style="{ backgroundColor : `${ hodu_hex_color_process(content.color, '#e3e7ed') } !important`, 
                                                                  color : hodu_hex_color_process(content.color, '#e3e7ed') == '#e3e7ed' ? '#232848' : '#ffffff' }">
                                            {{ template != null && template.template_type == 'FLOW' ? index + 1 : '' }}
                                        </h5>
                                        <input type="text" class="txt" placeholder="업무 입력" :value="content.title" @input="content.title = $event.target.value" />

                                        <!-- 색상 선택 영역 -->
                                        <div class="clr">
                                            <span class="dot dc9" :style="{ backgroundColor : `${ hodu_hex_color_process(content.color, '#c1cfd8') } !important` }"></span>
                                            <input type="button" value="색상선택" @click="workProcessColorListOnOff(content, index)"/>
                                        </div>

                                        <div class="pickColour" :class="{ on : content.colorOn != null && content.colorOn == true }">
                                            <ul>
                                                <!-- 기본색 영역 -->
                                                <li class="on">
                                                    <a>기본색</a>
                                                    <ul>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#FF6363' }"><a href="#" class="dc0" @click.prevent="changeColor(content, index, '#FF6363')">연어</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#FFA70E' }"><a href="#" class="dc1" @click.prevent="changeColor(content, index, '#FFA70E')">주황</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#FFC72F' }"><a href="#" class="dc2" @click.prevent="changeColor(content, index, '#FFC72F')">노랑</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#FF198B' }"><a href="#" class="dc3" @click.prevent="changeColor(content, index, '#FF198B')">자주</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#00B2C7' }"><a href="#" class="dc4" @click.prevent="changeColor(content, index, '#00B2C7')">청록</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#13D08B' }"><a href="#" class="dc5" @click.prevent="changeColor(content, index, '#13D08B')">초록</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#4DBAFF' }"><a href="#" class="dc6" @click.prevent="changeColor(content, index, '#4DBAFF')">하늘</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#477FFF' }"><a href="#" class="dc7" @click.prevent="changeColor(content, index, '#477FFF')">파랑</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#6854FF' }"><a href="#" class="dc8" @click.prevent="changeColor(content, index, '#6854FF')">보라</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#35405A' }"><a href="#" class="dc9" @click.prevent="changeColor(content, index, '#35405A')">검정</a></li>
                                                    </ul>
                                                </li>
                                                <!-- 기본색 영역 끝 -->
                                                <!-- 진한색 영역 -->
                                                <li>
                                                    <a>파스텔</a>
                                                    <ul>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#B9A494' }"><a href="#" class="lc0" @click.prevent="changeColor(content, index, '#B9A494')">연한 갈색</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#E67D9B' }"><a href="#" class="lc1" @click.prevent="changeColor(content, index, '#E67D9B')">연한 자주</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#FF9898' }"><a href="#" class="lc2" @click.prevent="changeColor(content, index, '#FF9898')">연한 빨강</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#AB8DD6' }"><a href="#" class="lc3" @click.prevent="changeColor(content, index, '#AB8DD6')">연한 보라</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#F3AC77' }"><a href="#" class="lc4" @click.prevent="changeColor(content, index, '#F3AC77')">연한 주황</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#E3D37A' }"><a href="#" class="lc5" @click.prevent="changeColor(content, index, '#E3D37A')">연한 노랑</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#8DD6A0' }"><a href="#" class="lc6" @click.prevent="changeColor(content, index, '#8DD6A0')">연한 초록</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#7197ED' }"><a href="#" class="lc7" @click.prevent="changeColor(content, index, '#7197ED')">연한 파랑</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#8DAED6' }"><a href="#" class="lc8" @click.prevent="changeColor(content, index, '#8DAED6')">연한 하늘</a></li>
                                                        <li :class="{ on : hodu_hex_color_process(content.color, '#e3e7ed').toUpperCase() == '#6F7A93' }"><a href="#" class="lc9" @click.prevent="changeColor(content, index, '#6F7A93')">연한 검정</a></li>
                                                    </ul>
                                                </li>
                                                <!-- 진한색 영역 끝 -->
                                            </ul>
                                        </div>
                                        <!-- 색상 선택 영역 끝 -->
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const WorkInfo = namespace('WorkInfo');

import { ResizeObserver } from 'vue-resize'
import { t_work_template } from '@/model/osm';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class WorkTemplateAdd extends Mixins(VueHoduCommon) {
    
    /**
     * @WorkInfo.State
     */
    @WorkInfo.State work_template_id !: string;

    template : t_work_template | null = null;
    is_create : boolean = true;

    step_count : number = 0;

    section_two_list_on   : boolean = false;
    section_three_list_on : boolean = false;

    async mounted() : Promise<void> {
        
		// 업무 색상 선택
		$(".content .second .clr input").click(function(){
			$(this).parent().siblings(".pickColour").toggleClass("on");
        });
        
        // 생성인지 조회(수정)인지 구분
        if( this.work_template_id != null && this.work_template_id.length > 0 ) {
            this.is_create = false;
        }

        // 업무 템플릿 작성 중에 업무 작성 권한이 없다면 페이지 이동
        if( this.is_create == true && this.getWorkPermission('create') == false ) {
            this.movePrevPage();
            return;
        }

        // 업무 템플릿 수정 중에 업무 수정 권한이 없다면 페이지 이동
        if( this.is_create == false && this.getWorkPermission('modify') == false ) {
            this.movePrevPage();
            return;
        }

        this.setScroll();

        // 조회라면 서버에서 조회
        if( this.is_create == false ) {
            await this.selectWorkTemplate();
        }

        // 생성이라면 초기값 부여
        else {

            this.template = {
                template_id : '',
                template_type : 'CHECK',
                scope : this.scope,
                scope_id : this.scope == OWNER_TYPE.TEAM ? this.scope_team_id : this.scope_group_id,
                last_update_tag : '0',
                audit_modified : new Date(),
                audit_delete_flag : false,
                audit_user_id : this.user_id, 
                template_info : {
                    name : '',
                    content : [
                        { color : '', title : '' },
                        { color : '', title : '' },
                        { color : '', title : '' },
                        { color : '', title : '' },
                        { color : '', title : '' }
                    ],
                    is_multi_select : false
                },
            }

        }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height : number | undefined = $('.title_box').outerHeight();
        const final_height : number             = window.innerHeight - ( title_height ? title_height : 0 );
        
        // @ts-ignore
        $('#workAddScroll').mCustomScrollbar({
            axis : 'y',
            setHeight : final_height,
            scrollbarPosition : 'outside'
        })
    }

    /**
     * 업무 템플릿 조회
     */
    async selectWorkTemplate() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template/select/${ this.work_template_id }`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                vue.template = response.data.data.template;

                if( vue.template != null ) {
                    vue.step_count = vue.template.template_info.content.length;
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });
    }

    /**
     * 업무 다중 선택 변경
     */
    changeIsMultiSelectType(is_multi_select : boolean) : void {
        if( this.template == null ) {
            return;
        }

        this.template.template_info.is_multi_select = is_multi_select;
        this.section_two_list_on = false;
    }

    /**
     * 업무 타입 선택 리스트 ON / OFF
     */
    sectionTwoListOnOff() : void {
        if( this.template == null || this.template.template_info.content.length < 2 ) {
            return;
        }

        this.section_two_list_on   = !this.section_two_list_on;
        this.section_three_list_on = false;
    }

    /**
     * 다중 선택 리스트 ON / OFF
     */
    sectionThreeListOnOff() : void {        
        this.section_two_list_on   = false;
        this.section_three_list_on = !this.section_three_list_on;
    }

    /**
     * 업무 개수 변경
     */
    workProcessCountChange(n : number) : void {
        if( this.template == null ) {
            this.section_three_list_on = false;
            return;
        }

        // 기존 업무 개수와 같은 경우
        if( this.template.template_info.content.length == n ) {
            this.section_three_list_on = false;
            return;
        }

        // 기존 업무 개수보다 많은 경우
        else if ( this.template.template_info.content.length < n ) {
            const repeat_count : number = n - this.template.template_info.content.length;
            for( let i = 0; i < repeat_count; i++ ) {
                this.template.template_info.content.push({ color : '', title : '' });
            }
        }

        // 기존 업무 개수보다 적은 경우 
        else {
            const template_count : number = this.template.template_info.content.length;
            for( let i = ( template_count - 1 ); i >= n; i-- ) {
                this.template.template_info.content.splice(i, 1);
            }
        }

        // 1개일때 자동으로 다중선택 금지
        if( n == 1 ) { this.template.template_info.is_multi_select = false; }

        this.section_three_list_on = false;
    }

    /**
     * 업무 컬러 선택창 ON / OFF
     */
    workProcessColorListOnOff(content : any, index : number) : void {
        if( this.template == null ) {
            return;
        }

        // 나머지 전부 닫기
        for( let template_content of this.template.template_info.content ) {
            if( this.template.template_info.content.indexOf(template_content) != index ) {
                template_content.colorOn = false;
                this.template.template_info.content.splice(this.template.template_info.content.indexOf(template_content), 1, template_content);
            }
        }

        content.colorOn = ( content.colorOn == null || content.colorOn == false ) ? true : false;
        this.template.template_info.content.splice(index, 1, content);
    }

    /**
     * 업무 컬러 바꾸기
     */
    changeColor(content : any, index : number, color : string) : void {
        if( this.template == null ) {
            return;
        }

        // 중복된 컬러 클릭 안되게
        const content_count : number = this.template.template_info.content.length;
        for( let i = 0; i < content_count; i++ ) {
            if( this.hodu_hex_color_process(color, '#FFFFFF').toUpperCase() == this.hodu_hex_color_process(this.template.template_info.content[i].color, '#FFFFFF').toUpperCase() ) {
                this.hodu_show_dialog('alert', "이미 선택 된 색상 입니다", ["확인"]);
                return;
            }
        }

        content.color   = color;
        content.colorOn = false;
        this.template.template_info.content.splice(index, 1, content);
    }

    /**
     * 템플릿 저장
     */
    async saveTemplate() : Promise<void> {
        if( this.template == null ) {
            return;
        }

        if( this.checkValid() == false ) {
            return;
        }

        // 업무 템플릿 생성
        if( this.is_create == true ) {
            await this.insertOrUpdateWorkTemplate();
        }

        // 업무 템플릿 조회
        else {
            if( this.step_count == this.template.template_info.content.length ) {
                await this.insertOrUpdateWorkTemplate();
                return;
            }

            await this.hodu_show_dialog('alert', "업무 개수가 달라지면<br>기존에 수행한 업무 데이터가 전부 사라집니다<br>정말 업무 템플릿을 수정하시겠습니까?",
                                  ['아니오', '예'], [() => {}, () => { this.insertOrUpdateWorkTemplate(true) } ]);
        }
    }

    /**
     * 업무 템플릿 insert 또는 update
     */
    async insertOrUpdateWorkTemplate(update_step_count : boolean = false) : Promise<void> {
        const vue = this;

        if( this.template == null ) {
            return;
        }

        // 제목, 컬러 비어있는 칸 제거
        let li_contentSize = this.template.template_info.content.length;
        for ( let i = (li_contentSize - 1); i >= 0; i-- ){
            let ls_title : string = this.template.template_info.content[i].title.trim();
            let ls_color : string = this.template.template_info.content[i].color;

            if( ( ls_title == null || ls_title.length < 1 ) ||
                    ( ls_color == null || ls_color.length < 1 ) ) {
                this.template.template_info.content.splice(i, 1)
            }
        }

        const url : string = `api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template?update_template_count=${ update_step_count }`;
        this.hodu_api_call(url, API_METHOD.POST, this.template)
            .then((response) => {
                console.log(response);
                vue.movePrevPage();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 데이터 체크
     */
    checkValid() : boolean {
        if( this.template == null ) {
            this.hodu_show_dialog('alert', "데이터 오류 잠시 후 다시 시도해주세요", ["확인"], [() => { this.movePrevPage(); }]);
            return false;
        }

        // 업무 제목 체크
        if( this.template.template_info.name.trim().length < 2 ) {
            this.hodu_show_dialog('alert', "업무 제목을 2자 이상 입력 해주세요", ["확인"]);
            return false;
        }

        // 업무 제목 체크
        if( this.template.template_info.name.trim().length > 25 ) {
            this.hodu_show_dialog('alert', "업무 제목을 25자 이내로 입력 해주세요", ["확인"]);
            return false;
        }

        // 제대로 입력한게 1개 이상인지 체크
        let li_count : number = 0;
        let li_contentSize : number = this.template.template_info.content.length;
        for( let i = 0; i < li_contentSize; i++ ){
            let ls_title : string = this.template.template_info.content[i].title.trim();
            let ls_color : string = this.template.template_info.content[i].color;

            // 내용, 색상 둘다 입력되어있는 경우
            if( ( ls_title != null && ls_title.length > 0 ) &&
                    ( ls_color != null && ls_color.length > 0 ) ) {
                li_count++;
            }
        }

        // 제대로 입력한 업무 내용이 1개 미만일때
        if( li_count < 1 ){
            this.hodu_show_dialog('alert', "업무 내용 및 색상을 1개 이상 입력 해주세요", ["확인"]);
            return false;
        }

        // FLOW, CHECK 둘다 체크해야하는내용
        for( let i = 0; i < li_contentSize; i++ ){
            let ls_title : string = this.template.template_info.content[i].title.trim();
            let ls_color : string = this.template.template_info.content[i].color;

            // 내용은 있는데 색상이 없는경우 or 색상은 있는데 내용이 없는 경우 => XOR (exclusive or)
            if( ( ls_title == null || ls_title.length < 1 ) !==
                    ( ls_color == null || ls_color.length < 1 ) ) {
                this.hodu_show_dialog('alert', `${ (i + 1) }번째 항목의 내용 및 색상을 입력 해주세요`, ["확인"]);
                return false;
            }
        }

        // FLOW일때 내용 index별 체크
        if( this.template.template_type == 'FLOW' ){
            for ( let i = (li_contentSize - 1); i >= 0; i-- ){
                let ls_title : string = this.template.template_info.content[i].title.trim();
                let ls_color : string = this.template.template_info.content[i].color;

                // 내용, 색상 둘다 입력되어있는 경우
                if( ( ls_title != null && ls_title.length > 0 ) &&
                        ( ls_color != null && ls_color.length > 0 ) ) {

                    // 이전 항목이 입력되어있지 않는 경우를 찾는다
                    for( let j = 0; j < i; j++){
                        let ls_beforeTitle : string = this.template.template_info.content[j].title.trim();
                        let ls_beforeColor : string = this.template.template_info.content[j].color;

                        if( ( ls_beforeTitle == null || ls_beforeTitle.length < 1 ) ||
                                ( ls_beforeColor == null || ls_beforeColor.length < 1 ) ) {
                            this.hodu_show_dialog('alert', `${ (j + 1) }번째 항목의 내용 및 색상을 입력 해주세요`, ["확인"]);
                            return false;
                        }

                    }

                }

            }
        }

        return true;
    }

    /**
     * 업무 템플릿 삭제
     */
    deleteTemplate() : void {
        const vue = this;

        if( this.template == null ) {
            return;
        }

        const template_id : string = this.template.template_id;

        this.hodu_show_dialog('cancel', "해당 업무 템플릿을 삭제하시겠습니까?",['아니오', '예'], 
            [() => {}, () => {
                const url : string = `api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template/${ template_id }`; 
                this.hodu_api_call(url, API_METHOD.DELETE)
                    .then((response) => {
                        console.log(response);
                        vue.movePrevPage();
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });
            } ]);
    }

    /**
     * 업무 권한 구하기
     * 업무 작성 권한(create) - 템플릿 작성 권한
     * 업무 읽기 권한(read)   - 템플릿 리스트 조회 권한
     * 업무 수정 권한(modify) - 템플릿 수정 권한
     * 업무 삭제 권한(delete) - 템플릿 삭제 권한
     */
    getWorkPermission(crud_type : string) : boolean {
        
        // 그룹
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, 'work', crud_type);
        } 
        
        // 팀
        else if ( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, 'work', crud_type);
        } 

        // 그 외의 경우는 불가능
        else {
            return false;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#workAddScroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    #workAddScroll .seconds input.wrong {border: 0 none !important;border-bottom: 1px solid #ff5050 !important;background: #fff !important;}
    #section_ce .addPageDefault .grp.settingBg h3.selectTitle .txt { background: none!important; }
    .seconds .pickColour li:before {display: none;}
        
    .addPageDefault .content {padding-top: 0;clear: both;float: left;width:100%;margin-bottom: 330px;}
    .addPageDefault .selectTitle span.num { margin-left:10px; color:#477fff; font-size:14px; }
    .addPageDefault .selectTitle span.rest {font-size:14px; }
    .addPageDefault .settingBg .btn_group {margin-right: 30px;margin-top: 11px;}
    #section_ce .addPageDefault .grp.settingBg {height: 65px;border-bottom: 1px solid #e7e9ea;box-sizing: border-box;}
    #section_ce .addPageDefault .grp.settingBg h3.selectTitle {position: static;display:inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 65px;}
    #section_ce .addPageDefault .grp.settingBg h3.selectTitle .txt {    background: url(../../assets/images/side_area/bt_open.png) no-repeat center right;padding-right: 35px !important;}
        
    .addPageDefault h4 {font-size: 16px;margin-bottom:20px;}
    .addPageDefault .first { padding-top:40px; }
    .addPageDefault .first > div {display: inline-block;}
    .addPageDefault .first input {text-align:left;line-height: 55px;height: 55px;font-size:14px;font-weight:bold;padding:0 15px;border:1px solid #e7e9ea;} 
    .addPageDefault .first > div.sec1 {width: 50%;}
    .addPageDefault .first > div.sec1 input {width: 100%;box-sizing: border-box;transition:0.2s;font-size: 16px;}
    .addPageDefault .first > div.sec1 input:hover { border:1px solid #d2d7e2; background:#f1f3f5;transition:0.2s; }
    .addPageDefault .first > div.sec1 input:focus { border:1px solid #477fff; background:#fff;transition:0.2s; }
    .addPageDefault .first > div.sec2 {position: absolute;left: 50%;margin-left: 50px;}
    .addPageDefault .first > div.sec3 {position: absolute;left: 50%;margin-left: 196px;}
    .addPageDefault .first .sec2 input, .addPageDefault .first .sec3 input {width: 100px;border-radius: 5px;transition:0.2s;background: #fff url('../../assets/images/side_area/bt_open.png') no-repeat 65px center;}
    .addPageDefault .first .sec2 input:hover, .addPageDefault .first .sec3 input:hover {background: #f1f3f5 url('../../assets/images/side_area/bt_open.png') no-repeat  65px center;border:1px solid #d2d7e2;transition:0.2s;}
    .addPageDefault .first .sec2 input.on, .addPageDefault .first .sec3 input.on {background: #fff url('../../assets/images/side_area/bt_close.png') no-repeat  65px center;border:1px solid #477fff;transition:0.2s;}
    .addPageDefault .first .dropdown { z-index:10000; display:none; position:absolute; }
    .addPageDefault .first .dropdown.on { display:block; }
    .addPageDefault .first .dropdown a {display: block;line-height: 45px;padding: 0 15px;}
    .addPageDefault .first .dropdown {background: #fff;border-radius: 5px;box-shadow: 0 5px 10px rgba(0,0,0,0.1);left: 0;width: 100%;top: 93px;}
    .addPageDefault .first .dropdown a {border-bottom: 1px solid #f1f3f5;}
    .addPageDefault .first .dropdown a:hover { background-color:#f1f3f5; font-weight:bold; }
    .addPageDefault .first .dropdown a.on:hover { background: #fff; cursor:default; }
    .addPageDefault .first .dropdown a.on { font-weight:bold; }
    .addPageDefault .first .dropdown a.on:after {display: block;content: '';position: absolute;right: 13px;top: 19px;width: 7px;height: 7px;background: #477fff;border-radius: 50%;}
    .sec2 .dropdown li, .sec3 .dropdown li {position: relative;}
    .addPageDefault .seconds {margin-top: 40px;padding-top: 40px;border-top: 1px solid #f1f3f5;position: relative;}
    .seconds ul li:before {content: '';display: block;position: absolute;background: #f1f3f5;width: 3px;height: 100%;top: 33px;left: 16px;}
    .seconds ul li.check:before {}
    .seconds ul li:last-child:before {display: none;}
    .addPageDefault .seconds > ul > li h5 {display: inline-block;width: 35px;height: 35px;border-radius: 50%;text-align: center;line-height: 35px;color: #fff;position: absolute;border: 5px solid #fff;font-size: 14px;left: -5px;top: 14px;}
    .addPageDefault .seconds > ul > li {overflow: visible;border:0 none !important;clear:both;height: 74px;margin-bottom: 20px;padding: 0 330px 0 60px;box-sizing:border-box;position: relative;width:100%;}
    .addPageDefault .seconds > ul > li:hover { background:none; }
    .addPageDefault .seconds > ul > li:hover h5 { transform:scale(1.1); transition:0.2s; }
    .addPageDefault .seconds > ul > li .txt:hover{border-bottom:1px solid #d2d7e2}
    .addPageDefault .seconds > ul > li .txt:focus { border-bottom:1px solid #477fff; }
    .addPageDefault .seconds > ul > li > input {display: inline-block;border-bottom:1px solid #e7e9ea;width: 100%;line-height: 70px;font-weight: bold;font-size:14px;}
    .addPageDefault .seconds > ul > li > .clr {display: inline-block;float: right;position:relative;position: absolute;top: 25px;right: 200px;}
    .addPageDefault .seconds > ul > li > .clr .dot { position:absolute;font-size:0; width:7px; height:7px; border-radius:50%;top: 15px; left:14px }
    .addPageDefault .seconds > ul > li > .clr input {display:inline-block;background:#fff;border-radius:20px;border: 1px solid #e7e9ea;padding:0 15px 0 30px;line-height: 35px;font-size:12px;}
    .addPageDefault .seconds > ul > li > .clr input:hover { background:#f1f3f5; border: 1px solid #d2d7e2; }
    .addPageDefault .seconds > ul > li > .clr input:focus { border: 1px solid #477fff; background:#fff; font-weight:bold; }
    .addPageDefault .seconds p {margin-bottom: 20px;opacity: 0.6;padding-top: 5px;}
    .addPageDefault .content.check .seconds ul > li h5 { font-size:0;background-image:url(../../assets/images/contents/checked_fff.png); background-repeat: no-repeat; background-position: center 6px; }
    .schedule_box { padding-left : 0px !important; padding-right : 0px !important;}
    .content_container { padding-left: 40px !important; padding-right: 40px !important; }
    .pickColour.on { max-width: 510px }
    .pickColour > ul ul li {width: 25px;height: 25px;border-radius: 50%;margin: 5px 8px;display: inline-block;}
    .pickColour > ul ul {padding: 15px 25px;}

</style>