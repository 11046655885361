<template>
    <div id="kids_attendance_list_month" class="section_scroll">
        <div class="viewGroup">
            <div class="schedule_box">
                <div class="grp settingBg">
                    <div class="posRel">
                        <div class="bg">
                            <div class=" left">
                                <h2 id="hMonthPrt" class="mr10">
                                    <input type="text" id="attendance-list-month-current-date" class="forDatePick" :value="hodu_date_to_format_string(selected_date, 'YYYY-MM-DD')" readonly/>{{ date_string }}
                                </h2>
                                <input type="button" id="btnPrev" value="" class="toolbar-btn bt-prev mr10" @click.prevent="prev">
                                <input type="button" id="btnNext" value="" class="toolbar-btn bt-next mr10" @click.prevent="next">
                                <input type="button" id="btnToday" value="오늘" class="toolbar-btn today " @click.prevent="today">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="attendance_list_month_scroll" class="content">
                <div class="monthDiv">
                    <!-- leftDiv -->
                    <div class="leftDiv">
                        <div class="sortHeader">
                            <p>이름</p>
                        </div>
                        <div id="attendance_list_month_left_scroll" class="leftScroll">
                            <ul>

                                <li :key="kid.member_key" v-for="kid in attend_emp_month_info">
                                    <p class="name">{{ kid.member_name }}</p>
                                    <p class="team">{{ kid.team_name && kid.team_name.length > 0 ? `(${kid.team_name})` : '-' }}</p>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <!-- rightDiv -->
                    <div class="rightDiv">

                        <div id="month_right_scroll" class="rightScroll" :style="{ width : `${header_width}px` }">
                            <ul class="mnthUl head" :style="{ width : `${140 * computedMonth.length}px` }">
                                
                                <li :class="{
                                    sun : day.date.getDay() == 0,
                                    publicHoliday : holidays.get(hodu_date_to_format_string(day.date, 'YYYY-MM-DD')) != null,
                                    today : hodu_date_to_format_string(day.date, 'YYYYMMDD') == hodu_date_to_format_string(new Date(), 'YYYYMMDD') 
                                }" :key="day.date.getDate()" v-for="day in computedMonth">
                                    <div class="topDiv">
                                        <p class="date">{{ day.date.getDate() }}</p>
                                        <p class="day">{{ day.day }}</p>
                                    </div>
                                </li>
                                
                            </ul>
                            <div id="attendance_list_month_right_scroll" class="grping" :style="{ width : `${140 * computedMonth.length}px`}">
                                
                                <ul id="attendance_list_month_right_ul" class="mnthUl cont" :key="kid.member_key" v-for="kid in attend_emp_month_info">

                                    <li :class="{
                                        sun : new Date(`${date_string}.${`0${day.date.getDate()}`.slice(-2)}`).getDay() == 0,
                                        publicHoliday : holidays.get(hodu_date_to_format_string(`${date_string}.${`0${day.date.getDate()}`.slice(-2)}`, 'YYYY-MM-DD')) != null,
                                        round : kid.jsonb != null && kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')] != null && kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')].attend_type == 'ABSENT'
                                    }" :key="day.date.getDate()" v-for="day in computedMonth">

                                        <div class="btmDiv" :class="{
                                            btmDiv_1 : (kid.jsonb != null && kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')] != null && kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')].attend_type == 'ABSENT'),
                                            btmDiv_2 : !(kid.jsonb != null && kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')] != null && kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')].attend_type == 'ABSENT')
                                        }" v-if="hodu_date_to_format_string(day.date, 'YYYYMMDD') <= hodu_date_to_format_string(new Date(), 'YYYYMMDD')">

                                            <a href="#" @click.prevent="() => {}" :title="kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')].attend_remark" v-if="kid.jsonb != null && kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')] != null && kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')].attend_type == 'ABSENT'">
                                                결석
                                            </a>

                                            <div class="btmDiv" :class="{ notChecked : kid.jsonb == null || getStartTimeText(kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')]) == '' }" v-if="kid.jsonb == null || kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')] == null ||  kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')].attend_type == 'NORMAL'">
                                                <p class="fromTime">
                                                    {{ kid.jsonb != null ? getStartTimeText(kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')]) : '' }}
                                                    <span title="미체크" v-if="kid.jsonb == null || getStartTimeText(kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')]) == ''">?</span>
                                                </p>
                                            </div>

                                            <div class="btmDiv" :class="{ notChecked : kid.jsonb == null || getEndTimeText(kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')], hodu_moment(`${date_string}.${`0${day.date.getDate()}`.slice(-2)}`).toDate()) == '' }" v-if="kid.jsonb == null || kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')] == null || kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')].attend_type == 'NORMAL'">
                                                <p class="toTime">
                                                    {{ kid.jsonb != null ? getEndTimeText(kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')], hodu_moment(`${date_string}.${`0${day.date.getDate()}`.slice(-2)}`).toDate()) : '' }}
                                                    <span title="미체크" v-if="kid.jsonb == null || getEndTimeText(kid.jsonb[hodu_date_to_format_string(day.date, 'YYYYMMDD')], hodu_moment(`${date_string}.${`0${day.date.getDate()}`.slice(-2)}`).toDate()) == ''">?</span>
                                                </p>
                                            </div>

                                        </div>

                                    </li>

                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import moment from 'moment';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        
    },
})
export default class KidsAttendanceListMonth extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 월 데이터
     */
    get computedMonth() : any[] {
        let month : any[] = [];

        if( this.date_string == null || this.date_string.length < 1 ) return [];

        const month_start = moment(`${this.date_string}.01`).toDate();
        const month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

        const start = month_start.getDate();
        const end = month_end.getDate()

        for( let i = start; i <= end; i++ ) {
            month.push({
                date : moment(month_start).add('day', i - 1).toDate(),
                day : this.getDayOfWeekByDate(moment(month_start).add('day', i - 1).toDate())
            });
        }

        return month;
    }
    
    /**
     * @CalendarInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @CalendarInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트

    date_string : string = "";

    attend_emp_month_info : any[] = [];
    lunar_date : Map<string, string> = new Map(); // 음력 Map
    holidays : Map<string, Object> = new Map(); // 공휴일 Map

    header_width : number = 0;

    resize_function_submit : boolean = false;

    async mounted() : Promise<void> {
        this.date_string = moment(this.selected_date).format('YYYY.MM');
        this.datepickerInit();

        // 조회
        await this.getTotalData();

        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {

            const title_height = $('#kids_attendance .title_box').outerHeight();
            const schedule_box_height = $('#kids_attendance_list_month .schedule_box').outerHeight();
            const sort_header_height = $('#kids_attendance_list_month .sortHeader').outerHeight();

            const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                    - (schedule_box_height ? schedule_box_height : 0)
                                                    - (sort_header_height ? sort_header_height : 0) - 10;

            // @ts-ignore
            $('#attendance_list_month_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_month_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_month_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            // @ts-ignore
            $('#attendance_list_month_right_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_month_right_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_month_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            const left_div_width : number | undefined = $('#kids_attendance_list_month .leftDiv').outerWidth();
            const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
            const right_area_controller_width : number | undefined = !this.right_control_box_flag ? $('#right_area_controller').outerWidth() : 0;

            const scroll_width : number = window.innerWidth - (left_div_width ? left_div_width : 0)
                                                            - (left_area_width ? left_area_width : 0) 
                                                            - (right_area_controller_width ? right_area_controller_width : 0);

            this.header_width = scroll_width;

            if( this.resize_function_submit == false ) { 
                this.event_bus.$on('listMonthResize', this.handleResize);
                this.resize_function_submit = true;

                if( this.date_string == this.hodu_date_to_format_string(new Date(), 'YYYY.MM') ) {
                    this.$nextTick(() => {
                        const last_date = new Date();
                        last_date.setMonth(last_date.getMonth() + 1);
                        last_date.setDate(0);
                        const width = document.getElementById('attendance_list_month_right_ul')?.offsetWidth ?? 0;
                        $('#month_right_scroll').scrollLeft((width * (new Date().getDate() / last_date.getDate())) - 139);
                    });
                }
            }
        });
    }

    /**
     * attend data 가져오기
     */
    async getTotalData() : Promise<void> {
        try {
            await this.hodu_show_indicator();

            // await Promise.all([this.getLunar(month_start, month_end), this.getHolidays(month_start, month_end)]);
            // await this.getEmpAttendDataMonthInfo();

            // this.$nextTick(() => {
            //     if( this.date_string == this.hodu_date_to_format_string(new Date(), 'YYYY.MM') ) {
            //         const last_date = new Date();
            //         last_date.setMonth(last_date.getMonth() + 1);
            //         last_date.setDate(0);
            //         const width = document.getElementById('attendance_list_month_right_ul')?.offsetWidth ?? 0;
            //         $('#month_right_scroll').scrollLeft((width * (new Date().getDate() / last_date.getDate())) - 139);
            //     }
            //     else {
            //         $('#month_right_scroll').scrollLeft(0);
            //     }
            // });

            // const request_date_string = this.date_string.replaceAll(/\./ig, "");
            // const today_date_string = moment().format('YYYYMMDD');
            
            // const response = await this.hodu_api_call(`api/v1/kids/attend/data/month?group_id=${this.scope_group_id}&month=${request_date_string}&today_date_string=${today_date_string}`, API_METHOD.GET, null, false);

            // console.log(response);

            // @ts-ignore
            $('#attendance_list_month_right_scroll').mCustomScrollbar('destroy');

            let month_start = moment(`${this.date_string}.01`).toDate();
            let month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

            await Promise.all([this.getLunar(month_start, month_end), this.getHolidays(month_start, month_end)]);
            await this.getEmpAttendDataMonthInfo();

            this.$nextTick(() => {
                this.setScroll();

                if( this.date_string == this.hodu_date_to_format_string(new Date(), 'YYYY.MM') ) {
                    const last_date = new Date();
                    last_date.setMonth(last_date.getMonth() + 1);
                    last_date.setDate(0);
                    const width = document.getElementById('attendance_list_month_right_ul')?.offsetWidth ?? 0;
                    $('#month_right_scroll').scrollLeft((width * (new Date().getDate() / last_date.getDate())) - 139);
                }
                else {
                    $('#month_right_scroll').scrollLeft(0);
                }
            })

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 이벤트(월 달력 일별 근태현황) 조회
     */
    async getEmpAttendDataMonthInfo() : Promise<void> {
        try {

            const request_date_string = this.date_string.replaceAll(/\./ig, "");

            // 오늘 날짜를 넣어서 오늘 이후의 데이터를 안나오게 하려다가 오늘 이후의 연차 까지 나와야해서 이 달력의 마지막 날까지 조회 하도록 변경
            const today_date_string = moment().format('YYYYMMDD');
            // const today_date_string = moment(`${this.date_string}.01`).add('month', 1).set('date', 0).format('YYYYMMDD');

            const response = await this.hodu_api_call(`api/v1/kids/attend/data/month?group_id=${this.scope_group_id}&month=${request_date_string}&today_date_string=${today_date_string}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_month_info ) {
                throw new Error("월달력 일별 근태현황 조회 실패");
            }
            
            this.attend_emp_month_info.splice(0, this.attend_emp_month_info.length);
            this.attend_emp_month_info = this.attend_emp_month_info.concat(response.data.data.attend_emp_month_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 음력 구하기
     */
    async getLunar(start : Date, end : Date) : Promise<void> {

        start = start instanceof Date ? start : new Date(moment(start).format());
        end   = end   instanceof Date ? end   : new Date(moment(end).format());

        const lunar_objs : any[] = await this.hodu_solars_to_lunars(start, end);
        
        for( let lunar_obj of lunar_objs ) {
            this.lunar_date.set(lunar_obj.solar_ymd, lunar_obj.lunar_ymd);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {

        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            this.holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

    }

    /**
     * 날짜 선택
     */
    datepickerInit() : void {

        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const date_moment = moment(dateText);
                
                vue.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
                vue.doSetSelectedDate(date_moment.toDate());
                vue.date_string = moment(vue.selected_date).format('YYYY.MM');

                inst.input.val(vue.date_string);

                // @ts-ignore
                $('#datepicker').datepicker('setDate', vue.selected_date);

                // 조회
                vue.getTotalData();
            },
        };

        // @ts-ignore
        $('#attendance-list-month-current-date').datepicker(option);
    }

    /**
     * 이전 날짜
     */
    prev() : void {
        this.doSetSelectedDate?.(moment(this.selected_date).add('month', -1).toDate());
        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // 조회
        this.getTotalData();
    }

    /**
     * 다음 날짜
     */
    next() : void {
        this.doSetSelectedDate?.(moment(this.selected_date).add('month', 1).toDate());
        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // 조회
        this.getTotalData();
    }

    /**
     * 오늘
     */
    today() : void {
        this.doSetSelectedDate?.(new Date());
        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // 조회
        this.getTotalData();
    }

    /**
     * 이벤트 개수
     */
    getEventCount(events, date : Date) : number {

        if( events == null || events.length < 1 ) return 0;
        
        // console.log(date);
        // console.log(events);
        
        const current_yyyymmdd = moment().format('YYYYMMDD');
        const day_yyyymmdd = moment(date).format('YYYYMMDD');
        if( events.length == 1 && events.attend_type == null && Number(current_yyyymmdd) < Number(day_yyyymmdd) ) {
            return 0;
        }

        let count = 0;
        let vacation_daycount = 0;
        let businesstrip_daycount = 0;
        let is_exist_state_10 = false;

        for( const event of events ) {
            if( event.attend_type == 20 ) {
                count++;
                vacation_daycount += event.daycount;
            }
            if( event.attend_type == 30 ) {
                count++;
                businesstrip_daycount += event.daycount;
            }
            else if( event.attend_type == null || event.attend_type == 10 ) {
                count += 2;
                is_exist_state_10 = true;
            }
        }

        // 휴가 합이 1일이 안되는데 출근 정보가 존재하지 않는다면(미체크) count + 2 TODO work_type 조회해서 해당 타입에 맞게 바뀌어야함
        if( is_exist_state_10 == false && (vacation_daycount + businesstrip_daycount) < 1 ) {
            return count + 2;
        }
        
        // 휴가가 1일 이상으로 신청됐다면 무조건 휴가 TODO work_type 조회해서 해당 타입에 맞게 바뀌어야함
        if( (vacation_daycount + businesstrip_daycount) >= 1 ) {
            return 1;
        }

        return count;
    }

    /**
     * 결근 여부
     */
    isAwol(events) : boolean {
        if( events == null || events.length < 1 ) return false;

        for( const event of events ) {
            if( event.attend_type == 10 ) {
                return event.attend_state == '40';
            }
        }

        return false;
    }

    /**
     * 휴가 제목 리스트 반환
     */
    getVacationTitle(events) : string {
        let vacation_name_list = "";
        for( const event of events ) {
            if( vacation_name_list.length > 0 ) vacation_name_list += ", "; 
            vacation_name_list += event.vacation_type_name;
        }
        return vacation_name_list;
    }

    /**
     * 출근 시간
     */
    getStartTimeText(event) : string {
        if( event == null ) return '';
        let start_time = event.start_time;
        return start_time ? this.processTimeText(start_time) : '';
    }

    /**
     * 퇴근 시간
     */
    getEndTimeText(event, date : Date) : string {
        if( event == null ) return '';
        let end_time = event.end_time;
        return end_time ? this.processTimeText(end_time) : '--:--';
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#attendance_list_month_left_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#attendance_list_month_right_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

}
</script>

<style scoped>
    .title_box .input_btn { cursor: pointer; margin-top:10px; padding-right: 35px !important; appearance: none; -webkit-appearance: none;background: url('../../assets/images/side_area/bt_open.png') no-repeat 90% center !important } 
    .title_box .input_btn:focus { background-image: url('../../assets/images/side_area/bt_close.png') !important; }

    .grp3 input[type="button"] { margin-top: 13px; margin-right: 25px; }
    .grp3 ul li { display: inline-block; margin-left: 15px; height: 66px; line-height: 66px }
    .grp3 ul li .clrBar { float: left; display: inline-block; font-size: 0; width: 20px; height: 3px;background:#f1f3f5; margin-top: 30px; margin-right: 10px; }
    .grp3 ul { margin-right: 25px; }
    .grp3 ul li .clrBar.ylw { background:#ffa70e;}
    .grp3 ul li .clrBar.blue { background:#447fff; }
    .grp3 ul li .clrBar.red { background:#ff6363; }
    .grp3 ul li .clrBar.green { background:#17c788; }
    .grp3 ul li .clrBar.black { background:#3f5c82; }
    .grp3 ul li .clrDiv {  float: left; width: 20px; margin-top: 23px; margin-right: 10px; }
    .grp3 ul li .clrDiv .clrBar { display: block; margin-top: 3px; }
    .settingBg > .posRel > .bg { position: static; }

    .settingBg .left  { margin-top: 0;  min-width: auto !important; width: auto !important }
    .settingBg .left input[type="text"] {float: left;line-height: 61px;font-size: 19px;color: #202A39;cursor: default;margin-right: 15px !important; font-weight: bold; min-width: auto !important; width: 80px !important }
    /* .settingBg .left #attendance-list-month-current-date {float: left;line-height: 61px;font-size: 19px;color: #202A39;cursor: default;margin-right: 15px !important; font-weight: bold; min-width: auto !important; background : none; cursor : pointer; text-align: left; } */

    /* monthDiv */
    .monthDiv { position: relative; min-height: 100px; border-top: 1px solid #e7e9ea; }
    

    /* leftDiv */
    .leftDiv { border-right: 1px solid #e7e9ea; width: 250px; height: 100%; box-sizing:border-box; position: absolute;left:0; top:0; }
    .leftDiv .sortHeader { box-sizing:border-box;line-height: 60px; height: 60px; padding:0 15px; }
    .leftDiv .sortHeader .headerSort { background-position: 205px 13px !important; }
    .leftScroll { overflow: hidden; box-sizing: border-box; border-bottom : solid 1px #f1f3f5; }
    .leftScroll ul { margin-bottom: 100px; }
    .leftScroll ul .offDayDiv { display: none; position: absolute;left: 0; top:0; width: 100%; height: 100%; text-align: center; }
    .leftScroll ul .offDayDiv .bg { display: block; background: #fff; opacity: 0.9; width: 100%; height: 100%; }
    .leftScroll ul li:hover .offDayDiv { display: block }
    .leftScroll ul .offDayDiv .txtDiv { position: absolute; left: 0; top: 0; width: 100%; height: 100%; font-weight: bold;   font-size: 13px; box-sizing: border-box;padding-right: 20px;text-align: right;}
    .leftScroll ul .offDayDiv .txtDiv span { color:#477fff; }
    .leftScroll ul .offDayDiv .txtDiv .title {height: 40px;line-height: 50px;}
    .leftScroll .img { position: absolute; width: 37px; height: 37px; border-radius: 50%; background:#f1f3f5; border:1px solid #f1f3f5; font-size: 0;  left: 20px; top: 15px; background-position: center center; background-size: cover; background-repeat: no-repeat }
    .leftScroll .name { height: 90px; line-height: 90px; font-size: 13px; font-weight: bold;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; display : inline-block; max-width: 65%; }
    .leftScroll .team { height: 90px; line-height: 90px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap; display : inline-block; margin-left : 10px; max-width: 35%; }
    .leftScroll .pos { margin-top:-2px; font-weight:bold; opacity:0.5; height: 23px; line-height: 30px;width: 149px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
    .leftScroll .team .clr { font-size: 0; margin-top:6px; margin-right: 10px; display: inline-block; float: left; width: 10px; height: 10px; border-radius: 50%; }
    .leftDiv ul li { float: left; display : inline-block; width: 249px; position: relative; border-bottom: 1px solid #e7e9ea;height: 90px; box-sizing:border-box; padding-left: 20px; padding-right: 20px; overflow:hidden; text-overflow:ellipsis; white-space: nowrap; min-width : 249px; }
    .leftDiv ul li:nth-child(1) .img { background-image: url('../../assets/images/footer/f1.jpg'); }
    .leftDiv ul li:nth-child(2) .img { background-image: url('../../assets/images/footer/f2.jpg'); }
    .leftDiv ul li:nth-child(3) .img { background-image: url('../../assets/images/footer/f3.jpg'); }
    .leftDiv ul li:nth-child(4) .img { background-image: url('../../assets/images/footer/f4.jpg'); }
    .leftDiv ul li:nth-child(5) .img { background-image: url('../../assets/images/footer/f5.jpg'); }

    .rightScroll { width: 100%; height: 100%; overflow: hidden; overflow-x: scroll; }
    .mnthUl { overflow: hidden; }
    .mnthUl.head li { height: 60px }
    .mnthUl.head li .topDiv { line-height: 60px; height: 60px; padding:0 15px; font-size: 13px !important; }
    .mnthUl.head li .topDiv * { font-size: 13px !important; }
    .mnthUl.head li.today:after { border-bottom:0 none; }
    .mnthUl.head li.today .topDiv { border-bottom: 1px solid #477fff; color : #fff; background-color : #477fff; }
    .mnthUl li { position: relative; float: left; width: 140px; height: 90px; border-right: 1px solid #e7e9ea; border-bottom: 1px solid #e7e9ea; box-sizing:border-box; }
    .mnthUl li .topDiv { overflow: hidden; background:#f8f9fa; line-height: 25px; height: 25px; padding: 0 10px; width:100%; box-sizing:border-box; border-bottom: 1px solid #e7e9ea }
    .mnthUl li .topDiv * { float: left; display: inline-block; font-size: 11px }
    .mnthUl li .topDiv .day { float: right; }
    .mnthUl li .btmDiv1 { height: 35px;line-height: 46px; text-align: center; font-weight: bold; }
    .mnthUl li .btmDiv2 { height: 35px; line-height: 25px; text-align: center; font-weight: bold; }
    .mnthUl li .btmDiv1.late, .mnthUl li .btmDiv2.late { color: #ff6363; }
    .mnthUl li .notChecked { text-align: center }
    .mnthUl li .notChecked span { background: #ffbc49;border: 1px solid #ffa70e; display:inline-block; width: 15px; height: 15px; border-radius: 50%; line-height: 15px;}
    .mnthUl li.sun  .topDiv * { color:#ff6060; }
    .mnthUl li.none { background:#fdfdfd; }
    .mnthUl li.none .topDiv { border-bottom: 1px solid #e7e9ea; }
    .mnthUl li.publicHoliday { color:#ff6363;background:#fff8f8; }
    .mnthUl li.publicHoliday .topDiv {background:#fff8f8; border-color:#ffe3e3;}
    .mnthUl li.today:after { content:''; display: block; position: absolute;left:0; top:0; width: 100%; height: 100%; border: 1px solid #477fff; box-sizing:border-box; }
    .mnthUl.cont li.today:after { border-top:0 none; border-bottom:0 none; }
    .mnthUl.cont li.today { border-bottom: 1px solid #477fff; }
    .mnthUl li .now { color:#477fff; }
    .mnthUl li.waiting .btmDiv  { text-align: center;  padding: 0; width: 100%; box-sizing:border-box; }
    .mnthUl li.waiting a { transition:0.2s; display: inline-block; width: 100%; height: 69px;line-height: 69px; text-align: center; box-sizing:border-box;background: #f1f7fd; color:#477fff; font-weight: bold; }
    .mnthUl li.waiting a:hover {background: #e0edfb}
    .mnthUl li.round  { text-align: center }
    .mnthUl li.round a { text-align: center;transition:0.2s; display: inline-block; background:#477fff; color:#fff; font-weight: bold; width: 60px; height: 60px; line-height: 60px; border-radius: 50%; }
    .mnthUl li.round a.red { background: #FF6363; cursor: default; }
    .mnthUl li.round .btmDiv {  }
    .mnthUl li.round.military a {background:#17c788; }
    .mnthUl li.ft .topDiv * { opacity: 0.5; }
    .mnthUl li.half .off { padding: 0; box-sizing:border-box; }
    .mnthUl li.half .notOff { height: 37px }
    .mnthUl li.half .offDiv { padding:4px 0; line-height: 24px;background: #477fff; color: #fff; border-radius: 0}
    .mnthUl li.half .btmDiv1.off { padding: 0; }
    
    .mnthUl li .btmDiv_4 { padding-top:5px }
    .mnthUl li .btmDiv_4 .btmDiv { height: 20px; line-height: 20px;text-align: center;  padding: 0; width: 100%; box-sizing:border-box; font-weight: bold;  }
    .mnthUl li .btmDiv_3 { padding-top:15px }
    .mnthUl li .btmDiv_3 .btmDiv { height: 20px; line-height: 20px;text-align: center;  padding: 0; width: 100%; box-sizing:border-box; font-weight: bold;  }
    .mnthUl li .btmDiv_2 { padding-top:25px }
    .mnthUl li .btmDiv_2 .btmDiv { height: 20px; line-height: 20px;text-align: center;  padding: 0; width: 100%; box-sizing:border-box; font-weight: bold;  }
    .mnthUl li .btmDiv_1 { line-height: 90px; height: 90px; }
    .mnthUl li .btmDiv_1 .btmDiv {text-align: center;  padding: 0; width: 100%; box-sizing:border-box; font-weight: bold;  }

    /* rightDiv */
    .rightDiv { box-sizing:border-box; width: 100%; padding-left: 250px;height: 100%; }

    ::-webkit-scrollbar{ width: 6px; height : 10px; }
    ::-webkit-scrollbar-track{background-color:  #e7e9ea;}
    ::-webkit-scrollbar-thumb{background-color: #232848;transition: 0.5s;}

    #hMonthPrt { float: left; line-height: 61px; font-size: 19px; color: #202A39; cursor: pointer; margin-right: 15px !important; font-weight: bold; background: none; }

    img.dummy { display : none; }
</style>