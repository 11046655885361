<template>
    <div class="section_ce_fix" @dragover.prevent="fileDragOver($event)" :class="{ dirOn : is_open_dir_list == true }" v-if="selected_dir != null">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <div class="bg4file" :class="{ on : (is_file_drag == true) }" @dragleave.prevent="fileDragLeave($event)" @drop.prevent="fileDrop($event)">
            <span>끌어당기고 떨어뜨리세요</span>
        </div>

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">
                <span class="cl" :class="hodu_color_dc_lc(scope_group_team_option.group_team_color ? scope_group_team_option.group_team_color : '')"></span>
                {{scope_group_team_option.group_team_name ? scope_group_team_option.group_team_name : ''}}
            </h3>
            
        </div>

        <!-- addClass(edit) 수정 가능한자일시 ************** 여기 스크롤 아님 -->
        <div id="fileScroll" class="section_scroll">
            <div class="viewGroup">					
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <!-- <div class="grp1">
                            <h3 class="selectTitle groupFile"><span class="cl dc4">색상</span><span class="txt">기획 그룹</span></h3>
                            <ul class="dropdown group"> 
                                <li><a>호두공지사항<span></span></a></li>
                                <li><a class="on">그룹 공지사항<span></span></a></li>
                            </ul>
                        </div>  -->
                        <!--
                        <h3 class="selectTitle box" @click="setionChange()"><span class="txt">{{header_file_dir_name ? header_file_dir_name : "파일함을 생성해주세요"}}</span><span class="total">총 파일함 <em>{{header_file_count}}</em></span></h3>
                        -->
                        <div class="titleDiv">
                            <a class="folderMenu" @click="dirListOpenOnOff">
                                <div>
                                    <span></span><span></span><span></span>
                                </div>
                            </a>
                            <div class="menuDetail">
                                <!-- <p class="title">폴더<span>4</span></p> -->
                                <p class="title">폴더<span>{{ arr_dir.length }}</span></p>
                                <!-- 하위폴더 추가 업뎃으로 인해 빼둠 -->
                                <!-- <input type="button" class="" value="추가" @click="showGroupAddFileBox"/> -->
                            </div>
                        </div>

                        <!-- 선택된 폴더 정보 / 비밀번호 있는 폴더는 pswFolder 태그 추가 -->
                        <div class="thisFolder" :class="{ pswFolder : has_password == true }">
                            
                            <!-- 폴더 색상, 아이콘, 이름 등 수정 버튼 -->
                            <a class="editThisFolder" :class="{ none_permission : selected_dir.audit_user_id != user_id }" title="수정" @click="modifyDir($event)">
                                <p class="folder" :class="`folder_${hodu_color_dc_lc(header_file_dir_color)}`">

                                    <span class="ic_fd ic-password">비밀번호 여부</span>
                                    <!-- <span class="ic_fd" 

                                          :class="{
                                              fd_pic   : header_file_dir_icon == 1,  // 사진
                                              fd_ppt   : header_file_dir_icon == 2,  // 문서
                                              fd_share : header_file_dir_icon == 3,  // 공유
                                              fd_study : header_file_dir_icon == 4,  // 연필
                                              fd_star  : header_file_dir_icon == 5,  // 별
                                              fd_lock  : header_file_dir_icon == 6,  // 자물쇠
                                              fd_no    : header_file_dir_icon == 7,  // 엑스
                                              fd_file  : header_file_dir_icon == 8,  // 클립
                                              fd_video : header_file_dir_icon == 9,  // 영상
                                              fd_music : header_file_dir_icon == 10, // 음악
                                          }">
                                    </span> -->
                                </p>
                                <p class="folderName">{{ header_file_dir_name }}</p>
                                <!-- <p class="folder folder_dc7"><span class="ic_fd fd_pic">사진</span></p>
                                <p class="folderName">심평원자료</p> -->
                            </a>

                        </div>
                        <!-- <h3 :class="`selectTitle box ${is_dir_show ? 'on' : ''}`" @click="setionChange()"><span class="txt">{{header_file_dir_name ? header_file_dir_name : "파일함을 생성해주세요"}}</span><span class="total">총 파일함 <em>{{header_file_count}}</em></span></h3> -->
                        
                        <!-- 그룹 파일함 하위폴더 추가로 인한 버튼 -->
                        <div class="folderAddBtn" v-if="scope_group_id > 0">
                            <a id="folderAdd" @click="showGroupAddFileBox" class="input_btn">폴더추가</a>
                        </div>
                        <div class="btn_group" style="">
                            <a href="#" class="input_btn delBtn" id="">삭제</a>
                            <!-- <a href="#" class="input_btn" id="addFileBox" @click.prevent="showGroupAddFileBox()">+ 파일함</a> -->
                            <!-- 검색 일단 제외 -->
                            <!-- <input type="button" class="schBtn" /> -->
<!-- 파일 추가 위치변경 -->
                            <!-- <label for="addFile" class="addFileLabel">+</label>
                            <input type="file" id="addFile" value="파일추가" @change="addFile($event)"/> -->
                        </div>
                    </div>
                </div>
<!-- 파일 추가 위치변경 -->
                <!-- 권한 없는사람에게 hide -->
                <div class="addFileDiv">
                    <!-- label 클릭시 파일선택 후 확인 클릭시 .newFileModal 불러오기 -->
                    <label for="addFile" class="addFileLabel">+</label>
                    <input type="file" multiple="multiple" id="addFile" value="파일추가" @change="addFile($event)"/>
                </div>

                <!-- .dropdownScroll에 스크롤추가 -->
                <div class="dropdownScroll">
                    <!--
                    <ul class="dropdown box">
                    -->
                    <ul :class="`dropdown box ${is_dir_show ? 'on' : ''}`">
                        <!-- mainFileBox 클래스 추가 기본파일함일시  -->
                        <li v-bind:key="index" v-for="(lo_data, index) in arr_dir">
                            <a class="mainLink" :class="{on: lo_data != null && lo_data.dir_uuid == dir_uuid}" @click.prevent="dirChange(lo_data.dir_uuid, true)">
                                <span class="file">파일 <em>{{lo_data.files.length}}</em></span>
                                <span class="txt">{{lo_data.dir_name}}</span>
                            </a>
                            <!-- 수정 클릭시 파일함 추가 모달 열기 -->
                            <!-- <a class="edit" title="수정" @click.prevent="showGroupEditFileBox(lo_data.dir_uuid, lo_data.dir_name)">수정<span></span><span></span><span></span></a> -->
                        </li>
                        <!--
                        <li class="mainFileBox">
                            <a class="mainLink on">
                                <span class="new">3</span>
                                <span class="mainBox">메인</span>
                                <span class="file">파일 <em>9</em></span>
                                <span class="txt">기본 파일함</span>
                            </a>
                        </li>
                        <li>
                            <a class="mainLink">
                                <span class="file">파일 <em>30</em></span>
                                <span class="txt">기획관련 자료</span>
                            </a>
                            <a class="edit" title="수정">수정<span></span><span></span><span></span></a>
                        </li>
                        -->
                    </ul>
                </div>

                <div class="fileMainDiv">

                    <div class="dirDiv">
                        
                        <div id="dir_scroll" class="scroll">

                            <ul class="folderUl">
                                <li :key="dir.dir_uuid" v-for="dir in arr_dir" :class="{ on : dir.dir_uuid == dir_uuid, pswFolder : dir.has_password == true }">
                                    <p class="folder" :class="`folder_${hodu_color_dc_lc(dir.dir_color)}`">
                                        <span class="blind">폴더개수</span><span class="total">{{dir.dirs.length}}</span>
                                    </p>
                                    <p class="folderName">
                                        <span class="ic-password"></span>
                                        {{ dir.dir_name }}
                                    </p>
                                    <p class="files"><span class="file">파일</span>{{ dir.path_files.length }}</p>
                                    <a class="editFolder" title="수정" @click="modifyDir($event)" v-if="dir.audit_user_id == user_id">수정</a>
                                    <a class="goDets" title="보기" @click="moveFileDir(dir)">보기</a>
                                </li>
<!-- 비밀번호 있는 폴더 pswFolder 태그 추가 -->
                                <!-- <li class="on pswFolder">
                                    <p class="folder folder_dc7">
                                        <span class="ic-password">비밀번호 여부</span>
                                    </p>
                                    <p class="folderName">side 폴더목록</p>
                                    <p class="files"><span class="file">파일</span>2</p>
                                    <a class="editFolder" title="수정">수정</a>
                                    <a class="goDets" title="보기">보기</a>
                                </li> -->
                                <!-- <li class="on">
                                    <p class="folder folder_dc7"></p>
                                    <p class="folderName">심평원자료</p>
                                    <p class="files"><span class="file">파일</span>4</p>
                                    <a class="editFolder" title="수정">수정</a>
                                    <a class="goDets" title="보기">보기</a>
                                </li>
                                <li>
                                    <p class="folder folder_dc6"></p>
                                    <p class="folderName">자료실</p>
                                    <p class="files"><span class="file">파일</span>25</p>
                                    <a class="editFolder" title="수정">수정</a>
                                    <a class="goDets" title="보기">보기</a>
                                </li>
                                <li>
                                    <p class="folder folder_dc0"></p>
                                    <p class="folderName">중요 문서</p>
                                    <p class="files"><span class="file">파일</span>16</p>
                                    <a class="editFolder" title="수정">수정</a>
                                    <a class="goDets" title="보기">보기</a>
                                </li>
                                <li>
                                    <p class="folder folder_dc2"></p>
                                    <p class="folderName">워크샵</p>
                                    <p class="files"><span class="file">파일</span>23</p>
                                    <a class="editFolder" title="수정">수정</a>
                                    <a class="goDets" title="보기">보기</a>
                                </li> -->
                            </ul>
                        </div>

                        <!-- 휴지통 만들때 주석 해제 -->
                        <!-- <div class="rubDiv">
                            <a>
                                <p class="title"><span class="icon">휴지통 아이콘</span>휴지통</p>
                                <p class="total">12</p>
                            </a>
                        </div> -->

                    </div>

                    <!-- 그룹에 들어왔을때 이동경로표시 -->
                    <div id="route_bar">
                        <nav class="deep_route">
                            <ul class="routeUl">
                                <li>
                                    <i class="ic_file">아이콘</i>
                                </li>
                                <!-- 해당 파일함 경로시 클래스 fixd_route 추가 -->
                                <li class="routeLi">
                                    <a href="#" @click.prevent="goGroupFileList">{{ scope_group_team_option.group_team_name }}</a>
                                </li>
                                <li class="routeLi" :key="dir.dir_uuid" v-for="dir in selected_dir.route_file_dirs" :class="{ fixd_route : dir_uuid == dir.dir_uuid }">
                                    <a href="#" @click.prevent="moveFileDir(dir)">{{ dir.dir_name }}</a>
                                </li>
                                <!-- <li class="routeLi fixd_route">
                                    <a href="#">검은공부방</a>
                                </li> -->
                            </ul>
                        </nav>
                    </div>
                    <!-- //그룹에 들어왔을때 이동경로표시 -->

                    <ul class="sortHeader fileHeader" id="">
                        <li class="notiCheck">
                            <p class="notiCheck on" id="notiCheckAll">
                                <input type="checkbox" id="">
                                <label for="notiCheckAll">전체선택</label>
                            </p>
                        </li>
                        <!-- <li class="num">No</li> -->

                        <li class="fileName headerSort" @click="fileNameSort"
                            :class="{
                                sortOff  : file_sort_target != 'FILE_NAME',
                                sortUp   : file_sort_target == 'FILE_NAME' && file_sort_direction == 'ASC', 
                                sortDown : file_sort_target == 'FILE_NAME' && file_sort_direction == 'DESC', 
                            }"><span>이름</span></li>

                        <li class="size headerSort" @click="sizeSort"
                            :class="{
                                sortOff  : file_sort_target != 'SIZE',
                                sortUp   : file_sort_target == 'SIZE' && file_sort_direction == 'ASC', 
                                sortDown : file_sort_target == 'SIZE' && file_sort_direction == 'DESC', 
                            }"><span>용량 / 파일수</span></li>

                        <li class="writtenBy headerSort" @click="userNameSort"
                            :class="{
                                sortOff  : file_sort_target != 'USER_NAME',
                                sortUp   : file_sort_target == 'USER_NAME' && file_sort_direction == 'ASC', 
                                sortDown : file_sort_target == 'USER_NAME' && file_sort_direction == 'DESC', 
                            }"><span>등록자</span></li>

                        <li class="time headerSort" @click="timeSort"
                            :class="{
                                sortOff  : file_sort_target != 'TIME',
                                sortUp   : file_sort_target == 'TIME' && file_sort_direction == 'ASC', 
                                sortDown : file_sort_target == 'TIME' && file_sort_direction == 'DESC', 
                            }"><span>업데이트</span></li>
                    </ul>

                    <!-- content 스크롤 추가-->
                    <div class="content_scroll">
                        <div class="content" id="">
                            <ul class="fileListUl">
                                <!-- 하위폴더 추가 / 하위폴더가 있을경우 폴더가 항상 먼저 위로 올라오게할 것 -->
                                <!-- 폴더 추가의 li에는 클래스 subFolderLi / 파일 추가의 li에는 클래스 fileUpLi-->
                                <li class="subFolderLi" :key="dir.dir_uuid" v-for="dir in selected_dir.dirs" :class="{ on : dir.open_menu == true }">
                                    <a class="fileMenu" @click.prevent="menu_open(dir)" v-if="dir.audit_user_id == user_id">메뉴보기</a>

                                    <p class="fileName pswFolder">
                                        <span class="ic-password" v-if="dir.has_password == true">비밀번호 여부</span>
                                        {{ dir.dir_name }}
                                    </p>

                                    <p class="fdType">
                                        <span class="size">{{ dirFileSize(dir) }} </span> / <span class="total">{{ dir.path_files.length }}개</span>
                                    </p>

                                    <p class="fd folder" :class="`folder_${hodu_color_dc_lc(dir.dir_color)}`">
                                        <span class="blind">폴더개수</span><span class="total">{{ dir.dirs.length }}</span>
                                    </p>

                                    <p class="fileHead writtenBy">
                                        <span>{{ dir.user_name }}</span>
                                    </p>

                                    <p class="time">
                                        <span>{{dateToString(dir.audit_modified, "yyyy-mm-dd")}}</span> {{dateToString(dir.audit_modified, "HH:MM")}}
                                    </p>

                                    <a class="goDets" title="페이지로 가기" @click.prevent="moveFileDir(dir)">페이지로가기</a>

                                    <ul class="fileMenuOp" v-if="dir.audit_user_id == user_id">
                                        <li><button type="button" class="fileOpList" @click.prevent="modifyDir($event, dir)">폴더수정</button></li>
                                        <li><button type="button" class="fileOpList pswDel" @click.prevent="delete_password(dir)" v-if="dir.has_password == true">비밀번호 삭제</button></li>
                                    </ul>
                                </li>
                                <!-- <li class="subFolderLi">
                                    <a class="fileMenu">메뉴보기</a>

                                    <p class="fileName pswFolder">
                                        <span class="ic-password">비밀번호 여부</span>
                                        스포츠센터 선생님
                                    </p>
                                    <p class="fdType">
                                        <span class="size">25.26 MB</span> / <span class="total">10개</span>
                                    </p>
                                    <p class="fd folder folder_dc2">
                                        <span class="blind">폴더개수</span><span class="total">1</span>
                                    </p>
                                    <p class="fileHead writtenBy">
                                        <span>이체육</span>
                                    </p>
                                    <p class="time">
                                        <span>22.10.27 목</span>  오후 02:01
                                    </p>
                                    <a class="goDets" title="페이지로 가기">페이지로가기</a>

                                    <ul class="fileMenuOp">
                                        <li><button type="button" class="fileOpList">폴더수정</button></li>
                                        <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                    </ul>
                                </li> -->

                                <!-- 내가 올린 리스트에는 class 추가 byMe, 파일 지울 수 있는 권한이 있는사람은 클래스 추가 remover -->
                                <li class="fileUpLi" v-bind:key="index" title="다운로드" v-for="(lo_data, index) in arr_files" :class="{byMe : lo_data.audit_user_id == user_id}" :id="`file_list_li_${index}`">
                                    <p class="notiCheck">
                                        <input type="checkbox" id="notiCheck01" value="" class="input_check">
                                        <label for="notiCheck01"></label>
                                    </p>
                                    <p class="num"><span>{{index+1}}</span></p> 
                                    <p class="preview fe" @click="fileDownLoad(lo_data.file_path, lo_data.file_name)" v-if="getExtensionOfFilename(lo_data.file_name) != 'img'">{{getExtensionOfFilename(lo_data.file_name)}}</p>
                                    <p class="preview img" @click="fileDownLoad(lo_data.file_path, lo_data.file_name)" v-if="getExtensionOfFilename(lo_data.file_name) == 'img'" :style="{ backgroundImage : `url(${'/app_images/group_file/' + lo_data.file_path})` }"></p>
                                    <p class="fileName" @click="fileDownLoad(lo_data.file_path, lo_data.file_name)"><span>{{lo_data.file_name}}</span></p> 
                                    <p class="size"><span>{{byteToSize(lo_data.file_size)}}</span></p> 
                                    <p class="writtenBy"><span>{{lo_data.user_name}}</span></p> 
                                    <p class="time"><span>{{dateToString(lo_data.audit_created, "yyyy-mm-dd")}}</span> {{dateToString(lo_data.audit_created, "HH:MM")}}</p> 
                                    <!--<a class="openFile">파일 열기</a>-->
                                    <!-- 개발 된 수정 버튼, css위해 잠시 주석처리 -->
                                    <!-- <a class="moreBtn" v-if="lo_data.audit_user_id == user_id">수정<span></span><span></span><span></span></a> -->
                                    <!-- byMe라는 클래스가 추가 되었을 시 수정버튼이 보이며, 클릭시 현재 리스트에 클래스 추가 open (예 byMe open)  -->
                                    <a class="moreBtn" title="삭제" @click="moreBtnClick(index)">삭제<span></span><span></span><span></span></a>
                                    <div class="cnfm">
                                        <p>삭제 하시겠습니까?</p>
                                        <a class="noNo" @click="moreBtnClose(index)">아니오</a>
                                        <a class="yesYes" @click="moreBtnFileDelete(index, lo_data.file_uuid)">네</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>	
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { t_event_file } from '@/model/event';
import { namespace } from 'vuex-class';
const ModalInfo  = namespace('ModalInfo');
const GroupInfo  = namespace('GroupInfo');

import { GroupAddFileModalNewInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

import moment from 'moment';
import { hodu_color } from '@/common/color';
const dateFormat = require('dateformat');

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupFileList extends Mixins(VueHoduCommon) {
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowGroupAddFileBox      ?: any;   
    @ModalInfo.Action doSetGroupAddFileModalInfo    ?: any;
    @ModalInfo.Action doSetGroupAddFileModalNewInfo ?: (params : GroupAddFileModalNewInfo) => void;
    
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * 변수 선언부
     */
    selected_dir          : any     = null;
    arr_dir               : any     = [];
    arr_files             : any     = [];
    original_files        : any     = [];
    dir_uuid              : string  = "";
    header_file_dir_name  : string  = "";
    header_file_count     : number  = 0;
    header_file_dir_color : string  = "";
    header_file_dir_icon  : number  = 0;
    is_dir_show           : boolean = false;
    is_file_drag          : boolean = false;
    has_password          : boolean = false;

    file_sort_target : string = 'TIME';
    file_sort_direction : string = "DESC";

    is_open_dir_list : boolean = false;

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() : Promise<void> {
        
        const vue = this;

        // 프리미엄 그룹 아이디가 없다면
        if ( !this.scope_group_team_option.biz_id ) {
            this.movePrevPage();
            return;
        }

        // 그룹 파일함 읽기 권한
        if ( !this.is_group_permmision(this.group_id, 'file', 'read') ) {
            this.movePrevPage();
            return;
        }

        // this.setScroll();

        // var file_drag = false;

        // 왼쪽 파일함 선택 시
        // $(".folderUl li a.goDets").click(function(){
        //     $(this).parent().siblings().removeClass("on");
        //     $(this).parent().addClass("on");

        // });

        // $(".folderMenu").click(function(){
        //     $(".section_ce_fix").toggleClass("dirOn");

        // });

        // 파일을 드래그하고 해당 영역위에 올렸을때 발생하는 이벤트
        // $('body').on('dragover', function(e){
        //     e.stopPropagation();
        //     e.preventDefault();
        //     $(".bg4file").css('display','block');
            
        //     file_drag = true;
        // });

        // 파일을 드래그하고 해당 영역을 떠났을때 발생하는 이벤트
        // $('.bg4file').on('dragleave', function(e){
        //     if(file_drag){
        //         $(".bg4file").css('display','none');
        //     }

        //     file_drag = false;
        // });

        // 이미지 파일 Drag & Drop 이벤트
        // $('.bg4file').on('drop', function (e) {
        //     e.stopPropagation();
        //     e.preventDefault();

        //     file_drag = false;

        //     $(".bg4file").css('display','none');
        //     $('#newFileModalBtn').trigger('click');
        // });

        /*
        // 블락킹 bg 클릭시 on빼기
        $(".blckng").click(function(){
			$(".selectTitle.box").removeClass("on");
			$(".dropdown.box").removeClass("on");
			$(".blckng").removeClass("on");
        });
        */

        // 기본파일함(h3.selectTitle.box) 선택시 
        /*
        $(".selectTitle.box").click(function(){
            $(this).addClass("on");
            $(".dropdown.box").addClass("on");
            $(".blckng").addClass("on");
        });
        */

       this.dir_uuid = this.$route.params.dir_id;

        // 파일함 조회
        await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
        await this.fileSort()
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();
        const getHeight        : number = (titleHeight ? titleHeight : 0) + (settingBgHeight ? settingBgHeight : 0) + (sortHeaderHeight ? sortHeaderHeight : 0);
        const finalHeight      : number | undefined = window.outerHeight - getHeight;
        const finalHeight2     : number | undefined = window.innerHeight - 186;

        // @ts-ignore
        $('.content_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : finalHeight2,
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
        });

        // @ts-ignore
        $('#dir_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0),
        });
    }
    
    async getFileDir() : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/file/${this.dir_uuid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dir ) {
                throw Error("파일함 조회 중 오류 발생");
            }

            this.selected_dir = JSON.parse(JSON.stringify(response.data.data.dir));
            
            this.header_file_dir_name = this.selected_dir.dir_name;
            this.header_file_dir_color = this.selected_dir.dir_color;
            this.header_file_dir_icon = this.selected_dir.dir_icon;
            this.has_password = this.selected_dir.has_password;

            this.arr_files.splice(0, this.arr_files.length);
            this.arr_files = this.arr_files.concat(this.selected_dir.files);

            this.original_files = JSON.parse(JSON.stringify(this.arr_files));

            if ( this.is_dir_show ) {
                this.is_dir_show = false;
            }

            this.handleResize();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', '파일함이 존재하지 않습니다', ['확인'], [() => { this.movePrevPage(); }]);
        }

    }

    /**
     * 파일함 조회
     */
    async getGroupFiles() : Promise<void> {

        await this.hodu_api_call(`api/v1/groupsweb/getGroupFiles/${this.group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null)
            .then(async(response) => {

                this.arr_dir.splice(0, this.arr_dir.length);
                this.arr_dir = this.arr_dir.concat(response.data.data.files);

                if( this.arr_dir.length < 1 ) {
                    this.hodu_show_dialog('cancel', '먼저 파일함을 생성해주세요', ['확인'], [() => { this.movePrevPage(); }]);
                    return;
                }

                // this.header_file_count = this.arr_dir.length;
                // this.header_file_dir_name = "";

                // if ( this.dir_uuid == "" ) {
                //     if( this.header_file_count > 0 ) { this.dirChange(this.arr_dir[0].dir_uuid); }
                // }
                // else {
                //     let lb_isFlag = false;

                //     for (let i = 0; i < this.arr_dir.length; i++) {
                //         if ( this.dir_uuid == this.arr_dir[i].dir_uuid ) {
                //             lb_isFlag = true;
                //             this.dirChange(this.arr_dir[i].dir_uuid);
                //             break;
                //         }
                //     }

                //     if ( !lb_isFlag ) {
                //         if( this.header_file_count > 0 ) { this.dirChange(this.arr_dir[0].dir_uuid); }
                //     }
                // }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 파일함 추가 모달
     */
    showGroupAddFileBox() : void {


        if( !this.doSetGroupAddFileModalNewInfo ) { return; }

        this.doSetGroupAddFileModalNewInfo({
            show_modal : true,
            is_create: true,
            dir_uuid: "",
            group_id: this.scope_group_id,
            up_dir_uuid: this.selected_dir.dir_uuid,
            create_function: async() => { 
                await Promise.all([await this.getFileDir(), await this.getGroupFiles()]); 
                await this.fileSort();
            }
        });
    }

    /**
     * 파일 추가
     */
    async addFile(event) : Promise<void> {
        if ( this.dir_uuid == "" ) {
            $('#addFile').val('');
            this.hodu_show_dialog("alert", "파일함을 생성해주세요", ['확인']);
            return;
        }

        const files : File[] = event.target.files;

        if ( files.length < 1 ) {
            $('#addFile').val('');
            return;
        }

        const files_length : number   = files.length;
        const form_data    : FormData = new FormData();

        for ( let i = 0; i < files_length; i++ ) {
            if( files[i].size > this.DEFAULT_FILE_MAX_SIZE ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", `${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`, ['확인']);
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", "확장자가 있는 파일만 업로드 가능 합니다", ['확인']);
                return;
            } 

            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", `${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`, ['확인']);
                return;
            }

            let file_data = {
                name: files[i].name,
                mimeType: files[i].type,
                url: '',
                size: files[i].size,
                date: new Date(),
            }

            form_data.append('file', files[i]);
        }

        await this.hodu_temp_upload(form_data)
            .then(async(response) => {
                const temp_files : t_event_file[] = response.data.data.temp_files;
                await this.groupFileSave(temp_files, false);
            })
            .catch(async(e) => {
                $('#addFile').val('');
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 파일 저장
     */
    async groupFileSave(po_files : t_event_file[], pb_overLap : boolean) : Promise<void> {
        const vue = this;
        let param_data = {file: po_files}
        
        await this.hodu_api_call(`api/v1/groupsweb/setGroupFile/${this.group_id}/${this.scope_group_team_option.biz_id}/${this.dir_uuid}/${pb_overLap}`, API_METHOD.POST, param_data)
            .then(async(response) => {
                if ( response.data.data.overlap_files.length < 1 ) {
                    await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
                    await this.fileSort();
                }
                else {
                    this.overlapFileSave(response.data.data.overlap_files);
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        $('#addFile').val('');
    }

    /**
     * 중복된 파일에 한해서 파일 저장
     */
    async overlapFileSave(po_files : t_event_file[]) : Promise<void> {
        if ( po_files.length < 1 ) {
            await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
            await this.fileSort();
            return;
        }

        let upload_files : t_event_file[] = [];

        for ( let i = 0; i < po_files.length; i++ ) {
            /*
            this.hodu_show_dialog("alert", po_files[i].name + " 중복된 파일입니다. \n\n파일을 덮어씌우시겠습니까?", ['아니오', '예'], [
                () => {},
                () => {
                    upload_files.push(po_files[i]);
                },
            ]);
            */

            if ( confirm(po_files[i].name + "\n동일한 파일명이 존재합니다.\n파일을 덮어씌우시겠습니까?") ) {
                upload_files.push(po_files[i]);
            }
        }

        if ( upload_files.length > 0 ) {
            this.groupFileSave(upload_files, true);
        }
        else {
            await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
            await this.fileSort();
        }
    }

    /**
     * BYTE TO SIZE
     */
    byteToSize(byte, decimals = 2) : string {
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];        
    }

    /**
     * 날짜 시간 변환
     */
    dateToString(date, format) : string {
        let returnVal = dateFormat(date, format);
        if ( format == "HH:MM" ) {
            const hour : number = Number(returnVal.substring(0,2));
            const min  : string = returnVal.substring(3,5);
            if( hour > 12 ) {
                returnVal = `오후 ${ `0${hour - 12}`.slice(-2) }:${min}`;
            }

            else if( hour == 12 ) {
                returnVal = `오후 12:${min}`;
            }

            else if( hour == 0 ) {
                returnVal = `오전 12:${min}`;
            }

            else {
                returnVal = `오전 ${ `0${hour}`.slice(-2) }:${min}`;
            }
        }

        return returnVal;
    }

    /**
     * 확장자 뽑기
     */
    getExtensionOfFilename(filename) : string {
        var _fileLen = filename.length;
        var _lastDot = filename.lastIndexOf('.');
    
        // 확장자 명만 추출한 후 소문자로 변경
        var _fileExt = filename.substring(_lastDot, _fileLen).toLowerCase();
 
        if ( _fileExt == ".png" || _fileExt == ".jpg" || _fileExt == ".jpeg" || _fileExt == ".bmp" || _fileExt == ".gif" ) {
            return "img";
        }
        else {
            return _fileExt.replace(".", "");
        }
    }

    /**
     * 폴더 변경
     */
    dirChange(p_dir_uuid : string, is_click : boolean = false) : void {
        
        for (let i = 0; i < this.arr_dir.length; i++) {
            let lo_data = this.arr_dir[i];

            if ( p_dir_uuid == lo_data.dir_uuid ) {

                if( this.dir_uuid != lo_data.dir_uuid && lo_data.has_password == true && is_click == true ) {
                    this.password_input(p_dir_uuid);
                    return;
                }

                this.selected_dir = JSON.parse(JSON.stringify(lo_data));

                this.dir_uuid = p_dir_uuid;
                this.header_file_dir_name = lo_data.dir_name;
                this.header_file_dir_color = lo_data.dir_color;
                this.header_file_dir_icon = lo_data.dir_icon;
                this.has_password = lo_data.has_password;

                if ( lo_data.files.length < 1 ) {
                    this.arr_files = [];
                }
                else {
                    this.arr_files = lo_data.files;
                }

                this.original_files = JSON.parse(JSON.stringify(this.arr_files));
            }
        }

        if ( this.is_dir_show ) {
            this.is_dir_show = false;
        }

        //$(".blckng").removeClass("on");
        //$(".selectTitle.box").removeClass("on");
        //$(".dropdown.box").removeClass("on");

        this.handleResize();
    }

    /**
     * 폴더함 선택
     */
    setionChange() : void {
        this.is_dir_show = !this.is_dir_show;
    }

    moreBtnClick(pi_index) : void {
        $('#file_list_li_' + pi_index).addClass("open");
    }

    moreBtnClose(pi_index) : void {
        $('#file_list_li_' + pi_index).removeClass("open");
    }

    moreBtnFileDelete(pi_index, ps_file_uuid) : void {
        $('#file_list_li_' + pi_index).removeClass("open");

        this.hodu_api_call(`api/v1/groupsweb/deleteGroupFile/${ps_file_uuid}/${this.scope_group_team_option.biz_id}`, API_METHOD.DELETE, {})
            .then(async(response) => {
                await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
                await this.fileSort();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });       
    }

    fileDownLoad(ps_path, ps_file_name) : void { 
        this.hodu_download(`/app_images/group_file/${ps_path}`, ps_file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 파일 들고 들어 온 경우
     */
    fileDragOver(event : any) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.is_file_drag = true;
    }
    
    /**
     * 파일 나간 경우
     */
    fileDragLeave(event : any) : void {
        this.is_file_drag = false;
    }
    
    /**
     * 파일 드랍
     */
    fileDrop(event : any) : void {
        this.is_file_drag = false;
        event.target.files = event.dataTransfer.files;
        this.addFile(event);
    }

    /**
     * 파일 이름 기준 정렬
     */
    fileNameSort() : void {
        // 이미 FILE_NAME Sort 중이라면
        if( this.file_sort_target == "FILE_NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.file_sort_direction ) {
                case "ASC":
                    this.file_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.file_sort_target = 'ASC';
                    break; 
            }
        }
        
        // FILE_NAME Sort가 아니였다면
        else {
            this.file_sort_target = "FILE_NAME";
            this.file_sort_direction = "ASC";
        }

        this.fileSort();
    }

    /**
     * 파일 용량 기준 정렬
     */
    sizeSort() : void {
        // 이미 SIZE Sort 중이라면
        if( this.file_sort_target == "SIZE" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.file_sort_direction ) {
                case "ASC":
                    this.file_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.file_sort_target = 'ASC';
                    break; 
            }
        }
        
        // SIZE Sort가 아니였다면
        else {
            this.file_sort_target = "SIZE";
            this.file_sort_direction = "ASC";
        }

        this.fileSort();
    }

    /**
     * 등록한 유저 이름 기준 정렬
     */
    userNameSort() : void {
        // 이미 USER_NAME Sort 중이라면
        if( this.file_sort_target == "USER_NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.file_sort_direction ) {
                case "ASC":
                    this.file_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.file_sort_target = 'ASC';
                    break; 
            }
        }
        
        // USER_NAME Sort가 아니였다면
        else {
            this.file_sort_target = "USER_NAME";
            this.file_sort_direction = "ASC";
        }

        this.fileSort();
    }

    /**
     * 등록일 기준 정렬
     */
    timeSort() : void {
        // 이미 TIME Sort 중이라면
        if( this.file_sort_target == "TIME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.file_sort_direction ) {
                case "ASC":
                    this.file_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.file_sort_target = "ASC";
                    break; 
            }
        }
        
        // TIME Sort가 아니였다면
        else {
            this.file_sort_target = "TIME";
            this.file_sort_direction = "ASC";
        }
        
        this.fileSort();
    }

    /**
     * 파일 정렬
     */
    fileSort() : void {

        this.selected_dir.dirs.sort((o1 : any, o2 : any) : number => {
            // 파일 이름 오름차순
            if( this.file_sort_target == "FILE_NAME" && this.file_sort_direction == "ASC" ) {
                if( o1.dir_name  > o2.dir_name ) { return  1; } 
                if( o1.dir_name  < o2.dir_name ) { return -1; } 
                if( o1.dir_name == o2.dir_name ) { return  0; }
            }

            // 파일 이름 내림차순
            if( this.file_sort_target == "FILE_NAME" && this.file_sort_direction == "DESC" ) {
                if( o1.dir_name  > o2.dir_name ) { return -1; } 
                if( o1.dir_name  < o2.dir_name ) { return  1; } 
                if( o1.dir_name == o2.file_name ) { return  0; }
            }

            const o1_file_size = this.dirFileSizeNumber(o1);
            const o2_file_size = this.dirFileSizeNumber(o2);

            // 파일 용량 오름차순
            if( this.file_sort_target == "SIZE" && this.file_sort_direction == "ASC" ) {
                if( o1_file_size  > o2_file_size ) { return  1; } 
                if( o1_file_size  < o2_file_size ) { return -1; } 
                if( o1_file_size == o2_file_size ) { return  0; }
            }

            // 파일 용량 내림차순
            if( this.file_sort_target == "SIZE" && this.file_sort_direction == "DESC" ) {
                if( o1_file_size  > o2_file_size ) { return -1; } 
                if( o1_file_size  < o2_file_size ) { return  1; } 
                if( o1_file_size == o2_file_size ) { return  0; }
            }

            // 유저 이름 오름차순
            if( this.file_sort_target == "USER_NAME" && this.file_sort_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 유저 이름 내림차순
            if( this.file_sort_target == "USER_NAME" && this.file_sort_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 업데이트 시간 오름차순
            if( this.file_sort_target == "TIME" && this.file_sort_direction == "ASC" ) {
                if( new Date(moment(o1.audit_modified).format()).getTime()  > new Date(moment(o2.audit_modified).format()).getTime() ) { return  1; } 
                if( new Date(moment(o1.audit_modified).format()).getTime()  < new Date(moment(o2.audit_modified).format()).getTime() ) { return -1; } 
                if( new Date(moment(o1.audit_modified).format()).getTime() == new Date(moment(o2.audit_modified).format()).getTime() ) { return  0; }
            }

            // 업데이트 시간 내림차순
            if( this.file_sort_target == "TIME" && this.file_sort_direction == "DESC" ) {
                if( new Date(moment(o1.audit_modified).format()).getTime()  > new Date(moment(o2.audit_modified).format()).getTime() ) { return -1; } 
                if( new Date(moment(o1.audit_modified).format()).getTime()  < new Date(moment(o2.audit_modified).format()).getTime() ) { return  1; } 
                if( new Date(moment(o1.audit_modified).format()).getTime() == new Date(moment(o2.audit_modified).format()).getTime() ) { return  0; }
            }

            return 0;
        });

        this.arr_files.sort((o1 : any, o2 : any) : number => {
            // 파일 이름 오름차순
            if( this.file_sort_target == "FILE_NAME" && this.file_sort_direction == "ASC" ) {
                if( o1.file_name  > o2.file_name ) { return  1; } 
                if( o1.file_name  < o2.file_name ) { return -1; } 
                if( o1.file_name == o2.file_name ) { return  0; }
            }

            // 파일 이름 내림차순
            if( this.file_sort_target == "FILE_NAME" && this.file_sort_direction == "DESC" ) {
                if( o1.file_name  > o2.file_name ) { return -1; } 
                if( o1.file_name  < o2.file_name ) { return  1; } 
                if( o1.file_name == o2.file_name ) { return  0; }
            }

            // 파일 용량 오름차순
            if( this.file_sort_target == "SIZE" && this.file_sort_direction == "ASC" ) {
                if( o1.file_size  > o2.file_size ) { return  1; } 
                if( o1.file_size  < o2.file_size ) { return -1; } 
                if( o1.file_size == o2.file_size ) { return  0; }
            }

            // 파일 용량 내림차순
            if( this.file_sort_target == "SIZE" && this.file_sort_direction == "DESC" ) {
                if( o1.file_size  > o2.file_size ) { return -1; } 
                if( o1.file_size  < o2.file_size ) { return  1; } 
                if( o1.file_size == o2.file_size ) { return  0; }
            }

            // 유저 이름 오름차순
            if( this.file_sort_target == "USER_NAME" && this.file_sort_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 유저 이름 내림차순
            if( this.file_sort_target == "USER_NAME" && this.file_sort_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 업데이트 시간 오름차순
            if( this.file_sort_target == "TIME" && this.file_sort_direction == "ASC" ) {
                if( new Date(moment(o1.audit_created).format()).getTime()  > new Date(moment(o2.audit_created).format()).getTime() ) { return  1; } 
                if( new Date(moment(o1.audit_created).format()).getTime()  < new Date(moment(o2.audit_created).format()).getTime() ) { return -1; } 
                if( new Date(moment(o1.audit_created).format()).getTime() == new Date(moment(o2.audit_created).format()).getTime() ) { return  0; }
            }

            // 업데이트 시간 내림차순
            if( this.file_sort_target == "TIME" && this.file_sort_direction == "DESC" ) {
                if( new Date(moment(o1.audit_created).format()).getTime()  > new Date(moment(o2.audit_created).format()).getTime() ) { return -1; } 
                if( new Date(moment(o1.audit_created).format()).getTime()  < new Date(moment(o2.audit_created).format()).getTime() ) { return  1; } 
                if( new Date(moment(o1.audit_created).format()).getTime() == new Date(moment(o2.audit_created).format()).getTime() ) { return  0; }
            }

            return 0;
        });
    }

    /**
     * 디렉토리 수정
     */
    modifyDir(event, dir ?: any) : void {

        if( dir == null && (this.selected_dir == null || this.selected_dir.audit_user_id != this.user_id) ) return; 
        else if( dir != null && dir.audit_user_id != this.user_id ) return; 

        this.doSetGroupAddFileModalNewInfo?.({
            show_modal : true,
            is_create: false,
            dir_uuid: dir != null ? dir.dir_uuid : this.dir_uuid,
            group_id: this.scope_group_id,
            // dir_name: this.header_file_dir_name,
            // dir_color: this.header_file_dir_color,
            // dir_icon: this.header_file_dir_icon,
            update_function: async(is_delete : boolean, dir_uuid : string) => {
                
                if( is_delete && dir_uuid == this.$route.params.dir_id ) { 
                    this.dir_uuid = ""; 
                }

                if( is_delete && dir_uuid != this.$route.params.dir_id ) { 
                    this.dir_uuid = this.$route.params.dir_id; 
                }

                await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
                await this.fileSort();
            }
        });

    }

    delete_password(dir) {

        const uuid = dir.dir_uuid;
        
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }

                    const delete_response = await this.hodu_api_call(`api/v1/groups/${dir.group_id}/file/${uuid}/password`, API_METHOD.DELETE);

                    console.log(delete_response);

                    if( !delete_response || !this.isHttpStatusSuccess(delete_response.status) ) {
                        throw new Error("파일함 비밀번호 삭제 중 오류 발생");
                    }
                    
                    this.getGroupFiles();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });

    }

    /**
     * 디렉토리 리스트 ON / OFF
     */
    dirListOpenOnOff() : void {
        this.is_open_dir_list = !this.is_open_dir_list;
    }

    /**
     * 디렉토리 비밀번호 입력
     */
    password_input(uuid : string) {

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {
               
                // 패스워드 체크
                try {
                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }

                    for (let i = 0; i < this.arr_dir.length; i++) {
                        let lo_data = this.arr_dir[i];

                        if ( uuid == lo_data.dir_uuid ) {

                            this.dir_uuid = uuid;
                            this.header_file_dir_name = lo_data.dir_name;
                            this.header_file_dir_color = lo_data.dir_color;
                            this.header_file_dir_icon = lo_data.dir_icon;
                            this.has_password = lo_data.has_password;

                            if ( lo_data.files.length < 1 ) {
                                this.arr_files = [];
                            }
                            else {
                                this.arr_files = lo_data.files;
                            }

                            this.original_files = JSON.parse(JSON.stringify(this.arr_files));
                        }
                    }

                    if ( this.is_dir_show ) {
                        this.is_dir_show = false;
                    }

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                } finally {
                    this.handleResize();
                }

            },
        });
    }

    /**
     * 파일보관함 리스트로 이동
     */
     goGroupFileList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        
        this.doGroupId(this.scope_group_id);
        this.doTeamId(this.scope_team_id);
        this.doGroupTeamOption(JSON.parse(JSON.stringify(this.scope_group_team_option)));
        this.hodu_router_push(`/group/${new Date().getTime()}/file`);
    }

    dirFileSize(dir) {

        let byte = 0;

        for( const path_file of dir.path_files ) byte += path_file.file_size;
          
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = 2;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]; 
    }

    dirFileSizeNumber(dir) {
        let byte = 0;
        for( const path_file of dir.path_files ) byte += path_file.file_size;
        return byte; 
    }

    async moveFileDir(dir) {

        if( this.dir_uuid == dir.dir_uuid ) {
            await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
            await this.fileSort();
            return;
        }

        // 비밀번호를 가진 하위 폴더와 경로가 완전히 다른 폴더는 비밀번호 체크 후 진입
        if( dir.has_password == true && this.selected_dir.route_file_dirs.filter(di => di.dir_uuid == dir.dir_uuid).length < 1 ) {

            this.doSetCommonInputModalInfo?.({
                show_modal : true,
                title : "비밀번호 입력",
                subtitle : "비밀번호",
                placeholder : "비밀번호를 입력해주세요",
                is_password : true,
                save_text : "확인",
                cancel : () => {

                },
                save : async(password) => {

                    try {

                        const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${dir.dir_uuid}/password`, API_METHOD.POST, {
                            "password" : password 
                        });

                        if( !response || !this.isHttpStatusSuccess(response.status) ) {
                            throw new Error("파일함 비밀번호 체크 중 오류 발생");
                        }
                        
                        this.hodu_router_push(`/group/${new Date().getTime()}/file/${dir.dir_uuid}`);

                    } catch(e) {
                        this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                    }

                },
            });

        }
        else {
            this.hodu_router_push(`/group/${new Date().getTime()}/file/${dir.dir_uuid}`);
        }

    }

    menu_open(target_dir) {

        if( this.selected_dir == null ) return;

        for( const dir of this.selected_dir.dirs ) {
            if( dir.dir_uuid == target_dir.dir_uuid ) continue;
            dir.open_menu = false;
        }

        if( target_dir.open_menu == null ) {
            target_dir.open_menu = true;
        }
        else {
            target_dir.open_menu = !target_dir.open_menu;
        }
        this.$forceUpdate();
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $(".content_scroll").mCustomScrollbar('destroy');

        // @ts-ignore
        $('#dir_scroll').mCustomScrollbar('destroy');

    	this.$nextTick(() => this.setScroll());
    }
    
}
</script>

<style scoped>
    .title_box { height:60px; }
    .schLi .schBtn { right:30px; }
	#addFile { display:none; }
	.addFileLabel {cursor:pointer;position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 1110000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 5px;display: block;text-align: center;font-size: 30px !important;width: 60px;border: 2px dashed #abc4ff;height: 60px;line-height: 59px;color: #608fff !important;background: rgba(71, 127, 255, 0.1) !important;}
	.addFileLabel:hover {background: #477fff !important;color: #fff !important;border: 2px solid #477fff;border-radius: 50%;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;}
	#fileScroll .btn_group { margin-right:30px;position: static;float: right;margin-top: 9px; }
	.section_scroll .delBtn { display:none; }
	.section_scroll.edit .delBtn { display:inline-block }
	#fileScroll .sortHeader input[type='checkbox']:not(old) + label { margin-top:18px; }
	#fileScroll input[type='checkbox']:not(old) + label {margin-top: 21px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius:3px;border: 2px solid #c1cfd8;background: #c1cfd8 url('../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
	#fileScroll input[type='checkbox']:not(old):checked + label {background: #477fff url('../assets/images/contents/checked_fff.png') no-repeat 0 0;background-size: 18px;border: 2px solid #477fff;}
	#fileScroll h3.selectTitle .cl {display: inline-block;width: 8px;height: 8px;float: left;margin-top: 28px;border-radius: 50%;margin-right: 15px;font-size: 0;}
	
    .title_doc .cl { display:inline-block; width: 10px; height:10px;  border-radius: 50%; margin-right:15px; float:left; margin-top: 27px; }
	h3.selectTitle {position: static;display: inline-block;font-size: 16px;padding: 0 20px 0 30px;line-height: 63px;cursor:pointer;	}
	.grp.settingBg .grp2, .grp.settingBg .grp1 { position:relative; display:inline-block; }
	.grp.settingBg h3.selectTitle:hover .txt { color:#477fff; }
	.title_doc span.total {padding-left: 10px;font-size: 16px;line-height: 20px;display: inline-block;color: #477fff;}
	.selectTitle.box span.total {display: inline-block;width: 30px;height: 30px;border-radius: 20px;text-align: center;font-size: 0;box-sizing: border-box;border: 1px solid #e7e9ea;background: #fff;float: right;line-height: 29px;margin: 17px 0 0 17px;color: #477fff;}
	.selectTitle.box:hover span.total, .selectTitle.box.on span.total {transition: 0.1s;width: auto;font-size: 12px;padding: 0 20px;}
	.selectTitle.box.on span.total { background:#477fff; color:#fff; border:1px solid #477fff; }
	.selectTitle.box span.total em {font-size: 12px;font-style: normal;}
	.settingBg ul.dropdown {width: 250px;left:30px;;top: 60px;}
	.viewGroup { overflow:hidden; }
	#container #section_ce .schedule_box {padding: 0;width: 100%;max-width: 100%; overflow:hidden;}
	#fileScroll .sortHeader {margin-top: 0;}
    .grp.settingBg { position:static !important; padding: 0 !important }
	.section_scroll .sortHeader .notiCheck { display:none; }
	.section_scroll.edit .sortHeader .notiCheck {height:60px; line-height:60px; font-size:0;display: inline-block; }
	.section_scroll.edit .sortHeader li.notiCheck { position:absolute; left:0;width:110px;top:0; }
	.section_scroll .sortHeader { position:relative;box-sizing:border-box; }
	.section_scroll.edit .sortHeader { padding-left:110px; }
	.section_scroll .notiCheck { width:8%; text-align:center; }
	.section_scroll .sortHeader p.notiCheck { text-align: center;width:100%;}
	.section_scroll .num { width:12%; text-align:center; font-weight:bold; text-indent: -10px;}
	.section_scroll.edit .num { text-indent: -60px; }
	.section_scroll .group { width:15%; }
	.section_scroll .fileName { width: 37%;}
	.section_scroll .time { width:21%; }
	.section_scroll .time span {width: 77px;display: inline-block;}
	.section_scroll .writtenBy { width:16%; }
	.section_scroll .size { width:14%; }
	.content  { float:left; width:100%; }
	.content > ul > li p {display: inline-block;font-weight: bold;float: left;box-sizing: border-box;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
	.content > ul > li p.num, .content > ul > li p.notiCheck { padding-right:0; }
	.content > ul > li { transition:0.2s; position:relative; }
	.content > ul > li:hover { background:#f6f9fb; }
	.content > ul > li a.openFile {z-index:100; position:absolute;background:none !important; font-size:0; width:90%; height:100%; right:0;top:0;  }
	.content > ul > li.byMe:hover a.moreBtn, .content > ul > li.remover:hover a.moreBtn { display:block;  }
    .content > ul > li.byMe .writtenBy span { color:#477fff; }
    .cnfm {display:none;position:absolute; left:0; top:0; width:100%; height:100%;background: rgba(33, 49, 84, 0.6); text-align: right;z-index: 1000;}
    .cnfm p {display: inline-block;color: #fff;font-size: 14px;float: none !important;}
    .cnfm a {line-height: 35px;height: 35px;font-size: 13px;font-weight: bold;background: #fff;border-radius: 20px;display: inline-block;width: 70px;margin-right: 20px;margin-top: 14px;float: right !important;text-align: center;}
    .cnfm a.noNo:hover { background-color:#ff6060 !important; }
    .cnfm a.yesYes:hover { color:#477fff; }
    .content > ul > li.byMe.open .cnfm, .content > ul > li.remover.open .cnfm { display:block; } 
    .content > ul > li.open .moreBtn { display: none !important; }
	.content > ul > li a.moreBtn { display:none;z-index: 1000;position: absolute;background: none !important;width: 35px;height: 35px;line-height: 41px;text-align: center;border-radius: 50%;background: rgba(0,0,0,0.0) !important;right: 30px;top: 15px;font-size: 0; }
	.content > ul > li a.moreBtn:hover {background: rgba(0,0,0,0.1) !important;}
	.content > ul > li a.moreBtn span { width:4px; height:4px; border-radius:50%;margin:0 2px; display:inline-block; background:#000;display:inline-block; }
	.content > ul > li a span { color:#477fff; }
	#fileScroll.section_scroll .content > ul > li:hover p.fileName span {color: #477fff !important;}
	/* .content > ul > li:hover a:hover { color:#fff; background:#477fff; } */
	.content > ul > li:hover a:hover span { color:#fff; }
	.content > ul > li { box-sizing:border-box; position:relative; width:100%; border-bottom:1px solid #f1f3f5;height: 65px;line-height: 65px;}
	.content > ul > li .preview {position: absolute;left: 12%;background: #fff;border-radius: 5px;height: 40px;font-size: 11px !important;line-height: 40px;text-transform: uppercase;text-align: center;width: 60px;color: #477fff;top: 12px;padding-right: 0;-webkit-appearance: none;border: 1px solid #e7e9ea !important;box-shadow:none !important;}
    .content > ul > li:hover  { cursor: pointer; }
    .content > ul > li .preview.img {transition: 0.1s; font-size:0 !important; background-size:cover !important; background-position:center center !important; }
	.content > ul > li p.fileName {padding-left: 80px;}
	.content > ul > li:hover .preview.img {box-shadow: 0 5px 10px rgba(0,0,0,0.2) !important;border: none !important;}
	.edit .content > ul > li .preview { margin-left:110px; left:11%; }
	
	/******** 개발시 삭제 필요 */ 
	.content > ul > li:nth-child(1) .preview.img {background-image:url(../assets/images/footer/g11.jpg);}
	.content > ul > li:nth-child(3)  .preview.img {background-image:url(../assets/images/footer/g2.jpg);}
	.content > ul > li:nth-child(4)  .preview.img {background-image:url(../assets/images/footer/g7.jpg);}
	.content > ul > li:nth-child(8)  .preview.img {background-image:url(../assets/images/footer/g4.jpg);}
	/******** 삭제필요 */
	
	
	.section_scroll.edit .content > ul > li {padding-left:110px; }
	.section_scroll.edit .content > ul > li p.notiCheck {text-align: center;position:absolute; left:0;width:110px;top:0;    display: inline-block; }
	.section_scroll .content > ul > li p.notiCheck { display:none; }
	
	
	.blckng {display:none;width: 100%;height: 100%;position: absolute;z-index: 100; }
	.blckng.on { display:block }
	#fileScroll h3.selectTitle.box {background: url(../assets/images/contents/ic_fileBox_blue.png) no-repeat center left;padding-left: 35px !important;margin-left: 30px;}	
	#fileScroll h3.selectTitle.box.on .txt { color:#477fff; }
	#fileScroll ul.dropdown.box li {position:relative;width: 33.33333%;border: none !important;display: inline-block;box-sizing: border-box;height: 100px;float: left;}
	#fileScroll ul.dropdown.box li a.mainLink {height:100px;font-size: 14px;font-weight: bold;line-height: 100px;color: #949fb7;border: 0;border-left: 1px solid #e7e9ea;width: 100%;border-bottom: 1px solid #e7e9ea;display: inline-block;box-sizing: border-box;border-collapse: collapse;}
	#fileScroll ul.dropdown.box li:hover a.mainLink {background-color: #f1f3f5 !important;}
	#fileScroll ul.dropdown.box li:hover a.mainLink.on {background-color: #fff !important;}
	#fileScroll ul.dropdown.box li a.mainLink.on, #fileScroll ul.dropdown.box li a.mainLink:hover {color: #273044;}
	#fileScroll ul.dropdown.box li a.mainLink:before {content: '';display: block;position: absolute;left: 0;top: 0;width: 40px;height: 40px;background: url(../assets/images/contents/ic_fileBox.png) no-repeat 20px 21px;background-size: 17px;opacity: 0.5;}
	#fileScroll ul.dropdown.box li a.mainLink:hover:before { opacity:1 }
	#fileScroll ul.dropdown.box li a.on.mainLink:before {background: url(../assets/images/contents/ic_fileBox_on.png) no-repeat 20px 21px;background-size: 20px;opacity: 1;top: -2px;}
	#fileScroll ul.dropdown.box li:last-child a {border-right: 1px solid #e7e9ea}
	.dropdown.box a.mainLink.on:after {display: none;}
	.dropdown.box span.mainBox {position: absolute;top: 21px;right: 20px;font-size: 12px;padding: 0 5px;line-height: 21px;height: 21px;background: #f1f3f5;border-radius: 5px;}
	.dropdown.box li a span.mainBox {background: #808ca7;color: #fff;}
	.dropdown.box span.file {font-size: 13px;position: absolute;box-sizing: border-box;width: 100%;top: 21px;padding-left: 55px;left: 0;line-height: 20px;}
	.dropdown.box em {font-style: normal;color: #477fff;padding-left: 10px;}
	.dropdown.box span.txt {position: absolute;box-sizing: border-box;width: 100%;top: 57px;padding-left: 20px;padding-right: 20px;left: 0;font-size: 16px;line-height: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
	.dropdown.box a.edit {display:none;opacity:1;position: absolute;bottom: 13px;right: 22px;width: 33px;box-sizing: border-box;padding: 0;height: 33px;line-height: 39px;font-size: 0;padding-left: 5px;border: none !important;border-radius: 50%;background: transparent;z-index: 1000000;}
    .dropdown.box a.edit span {background: black;width: 4px;height: 4px;display: block;border-radius: 50%;display: inline-block;margin: 0 2px;}
	.dropdown.box li:hover a.edit { display:block; } 
	.dropdown.box a.edit:hover {background: rgba(0,0,0,0.1);}
	#fileScroll ul.dropdown.box li a.mainLink {color: #949fb7;}
	#fileScroll ul.dropdown.box li a.mainLink.on span, #fileScroll ul.dropdown.box li a.mainLink:hover span {color: #273044;}
	#fileScroll ul.dropdown.box li a.mainLink span em {opacity: 0.6;}
	#fileScroll ul.dropdown.box li a.mainLink span {color: #aeb5c5;}
	#fileScroll ul.dropdown.box li a.mainLink.on span em, #fileScroll ul.dropdown.box li a.mainLink:hover span em {opacity:1;}
	#fileScroll ul.dropdown.box li.mainFileBox a.mainLink span.mainBox {color: #fff;}
	#fileScroll ul.dropdown.box li a.mainLink span.edit {position: absolute;right: 25px;top: -19px;}
	#fileScroll ul.dropdown.box {margin-left: -1px;position:static !important; box-shadow:none !important; border-radius: 0 !important; }
	#fileScroll ul.dropdown.box li a.mainLink span.new {opacity:0.4;display: block;position: absolute;right: 27px;top: 20px;width: auto;padding: 0 8px;height: 22px;background: #ff6060;line-height: 21px;text-align: center;font-size: 12px;color: #fff;border-radius: 5px;}
	#fileScroll ul.dropdown.box li a.mainLink:hover span.new, #fileScroll ul.dropdown.box li a.mainLink.on span.new { opacity:1; }
	#fileScroll ul.dropdown.box li.mainFileBox  a.mainLink span.new {right: 68px;}
	.dropdownScroll { height: auto;max-height: 300px;display: block;overflow-y: auto;width: 100%;position: absolute;z-index: 100000000000000;background: #fff;box-shadow: 0 10px 10px rgba(0,0,0,0.1);top: 129px;}
	
	.bg4file {display:none;position: absolute;width: 100%;vertical-align: middle;height: 100%;top: 0;line-height: 100%;left: 0; background: rgba(255, 255, 255, 0.3);box-sizing: border-box;z-index: 100000000;padding: 60px;}
	.bg4file.on {display: block;}
    .bg4file span {width: 100%;height: 100%;display: inline-block;transition: 0.1s;border-radius: 5px;background: rgba(71, 127, 255, 0.1) url(../assets/images/contents/img_dragDrop.png) no-repeat center center;background-size: 190px;border: 2px dashed #477fff;color: #477fff;font-weight: bold;font-size: 0;vertical-align: middle;text-align: center;}
	.bg4file span:hover {background: rgba(71, 127, 255, 0.15) url(../assets/images/contents/img_dragDrop.png) no-repeat center center;background-size: 200px;}
    #newFileModalBtn { height:0;width:0;opacity:0;font-size:0;border:0 none; }
    
    .content > ul > li:hover .img:after {z-index:10;position:absolute; top:0;left:0;content:'';width:100%;height:100%; display:block;background: rgba(71, 127, 255, 0.2) url(../assets/images/contents/ic_ddFile.png) no-repeat center 5px;}
	.fileListUl { margin-bottom:210px; }
	@media (max-width:1440px) {
		#fileScroll ul.dropdown.box li { width:50%; }
	}


    /* 상단 왼쪽 선택된 폴더 */
    .dirOn .thisFolder  { margin-left: 15px; }
    .thisFolder  { overflow:hidden; display: inline-block; margin-left: 0; width:auto; line-height: 65px; height: 65px; min-width: 150px; }
    .thisFolder .folder { float:left; width: 70px; height: 64px; background-size: 50px; background-position: 10px center;  }
    .thisFolder .folder span { float: left; background-size: 45px; background-position: 12px center; height: 64px; line-height: 64px; }
    .thisFolder .folderName { float:left; display: inline-block; font-size: 16px; font-weight: bold; }
    .thisFolder .editThisFolder { display: inline-block; width: 100%; height: 100%; }
    .thisFolder .editThisFolder:hover  { color:#477fff; }
    .thisFolder .editThisFolder.none_permission:hover  { color:#232848; cursor: default; }

    /* 왼쪽 상단 폴더 메뉴 */
    .titleDiv { position:relative; transition:0.1s; overflow: hidden; height: 65px; width:65px; padding-left: 65px; float:left; line-height: 65px; border-bottom:1px solid #e7e9ea; box-sizing: border-box; }
    .titleDiv .folderMenu { opacity:1; z-index: 10; position: absolute; top:0; left:0; display: inline-block; width: 65px; height: 65px; }
    .titleDiv .folderMenu div { transition:0.2s; position: relative; width: 38px; height: 38px; text-align: center; line-height: 36px; background:#f1f3f5; border-radius: 50%; margin-top:12px; margin-left: 17px; }
    .titleDiv .folderMenu div span { transform:  rotate(0) translateY(0) translateX(0); transition: 0.2s; display: inline-block; margin:0 2.5px; width: 4px; height: 4px; border-radius: 50%; background-color:#2e3856 }
    .titleDiv .folderMenu:hover span {margin-left:0; height:2px; transform: translateX(40px); border-radius:3px; opacity:0  }
    .titleDiv .folderMenu:hover span:first-child { opacity:1; width: 11px; transform:rotate(35deg) translateY(-11.5px) translateX(7px);margin-top:10px }
    .titleDiv .folderMenu:hover span:last-child { opacity:1; width: 11px; transform: rotate(-35deg) translateY(-3.5px) translateX(-7px); }

    .titleDiv .folderMenu:hover div { background-color:#e7e9ea; }
    .dirOn .titleDiv .folderMenu span { margin-left:0; height:2px; transform: translateX(40px); border-radius:3px; opacity:0  }
    .dirOn .titleDiv .folderMenu span:first-child { opacity:1; width: 16px; transform:rotate(45deg) translateY(-3.5px) translateX(2px);margin-top:10px }
    .dirOn .titleDiv .folderMenu span:last-child { opacity:1; width: 16px; transform: rotate(-45deg) translateY(-25.5px) translateX(27px); }

    .title { float: left; font-size: 14px; font-weight: bold; display: inline-block; }
    .title span { display: inline-block; padding-left:10px; color:#477fff; }
    .titleDiv input {margin-top:14px;margin-right:-5px; width: 35px; height:35px; float:right; background: transparent url(../assets/images/contents/ic_add.png) no-repeat center center;margin-right: 10px;background-size: 35px; font-size:0; border-radius: 50%;}
    .titleDiv input:hover {background-color: #477fff;background: #477fff url(../assets/images/contents/btn_add_fff.png) no-repeat center center;background-size: 35px;}
    .section_ce_fix.dirOn .settingBg .titleDiv { width:300px; opacity:1; border-right:1px solid #e7e9ea; }
    .section_ce_fix.dirOn .menuDetail { width: auto; opacity: 1; }
    .menuDetail { transition:0.1s; width: 0; opacity:0; overflow: hidden; }
    .menuDetail .title { padding-left:5px; }

    /* 왼쪽 디렉토리 섹션 */
    .section_ce_fix .fileMainDiv { transition:0.1s; padding-left:0; }
    .section_ce_fix .dirDiv { width: 0; opacity:0; overflow: hidden; }
    .section_ce_fix.dirOn .fileMainDiv { padding-left: 300px; position: relative; width: 100%; box-sizing: border-box; }
    .section_ce_fix.dirOn .dirDiv  { width:300px; opacity:1;  }
    .dirDiv { transition:0.1s; z-index: 100; position: absolute; left:0; top:0;  width:300px; height:100%; border-right:1px solid #e7e9ea;  }
    .dirDiv .title { float: left; font-size: 14px; font-weight: bold; display: inline-block; }
    .dirDiv .title span { display: inline-block; padding-left:10px; color:#477fff; }
    
    .section_ce_fix.dirOn .dirDiv .folderUl { display:block; }
    .dirDiv .folderUl { display:none; }
    /* 폴더 */
    .dirDiv .folder { position: absolute; left:0; top:0; display:inline-block; width: 50px; height:50px; background-size: 25px; background-position: 15px center; }
    .dirDiv .folderName { font-weight: bold; display: inline-block;  box-sizing: border-box; padding-left: 48px; font-size: 13px; padding-right:55px; width:100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
    .dirOn .dirDiv .folderUl li { width:100%;}
    .dirDiv .folderUl li { transition: 0.1s; position: relative; height: 50px; line-height:50px; width:0; border-bottom:1px solid #f1f3f5;  }
    .dirDiv .folderUl .files { position: absolute; right:15px; top:0; font-weight: bold; }
    .dirDiv .folderUl .files .file { display:none; }
    .dirDiv .folderUl li a.goDets { position: absolute; left:0; top:0; width:100%; height:100%; font-size:0; }
    .dirDiv .folderUl li:hover { background-color: #f6f9fb; }
    .dirDiv .folderUl li.on { overflow: hidden; cursor:default; box-sizing: border-box;  background-color: #fff; border-right: 3px solid #477fff;  }
    .dirDiv .folderUl li.on { height: 80px; }
    .dirDiv .folderUl li.on .files { display:inline-block; bottom:0; top:auto; width:100%; height: 40px; left:0; line-height: 30px; box-sizing: border-box; padding-left: 50px; padding-right: 60px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: normal;}
    .dirDiv .folderUl li.on .files .file { display:inline-block; padding:0 7px; margin-right: 10px;line-height: 23px; background:#f1f3f5; text-align: center !important; border-radius: 3px; }
    .dirDiv .folderUl li.on .folderName { float:left;box-sizing: border-box; padding-right:15px }
    .dirDiv .folderUl li.on .editFolder { display: block; top:auto; bottom: 13px;background:#fff }
    .dirDiv .folderUl li.on:hover a.goDets { cursor:default; }
    .dirDiv .folderUl li .editFolder { transition:0.1s; z-index: 9; display: none; position:absolute; right: 13px; top:11px; background:#fff; border:1px solid #e7e9ea; font-weight: bold; height: 25px; line-height: 25px; padding: 0 13px; border-radius: 15px; ; }
    .dirDiv .folderUl li .editFolder:hover {background-color:#ffba00; border-color: #ffba00}
    

    /* 휴지통 */
    .dirDiv .rubDiv { transition: 0.1s; position: absolute; overflow: hidden; bottom: 0; left:0; width:100%; height: 70px; line-height: 55px; border-top:1px solid #e7e9ea; background:#f1f3f5; box-sizing: border-box; padding: 0 15px}
    .dirDiv .rubDiv .title { float: left; }
    .dirDiv .rubDiv .title span { background: #ff6060 url('../assets/images/folder_file/fd_rbBin.png') no-repeat -3px -1px; background-size: 40px; display: inline-block;  font-size:0; width: 30px; height: 30px; padding:0; border-radius: 50%; float:left; margin: 11px 10px 0 0; }
    .dirDiv .rubDiv:hover  { background-color:#fff }
    .dirDiv .rubDiv a { display: block; width:100%; height: 100%; }
    .dirDiv .rubDiv .total {  transition: 0.1s; float: right; display: inline-block;line-height: 20px; height:20px;margin-top:18px }
    .dirDiv .rubDiv:hover .total { padding:0 10px; background:#477fff; color:#fff; font-weight: bold; border-radius: 15px; }

    /* 비밀번호 폴더 표시 */
    .thisFolder .folder { position: relative; }
    .thisFolder .folder .ic-password { display: none; width: 20px; height: 20px; font-size: 0; background: url('../assets/images/folder_file/ic_password_fff.png') no-repeat center center; position: absolute;     bottom: 16px; left: 33px; background-size: 15px; }
    .thisFolder.pswFolder .folder span.ic-password { display: block; }
    .dirDiv .folderUl li .ic-password { display: none; width: 50px; height: 50px; font-size: 0; background: url('../assets/images/folder_file/ic_password_fff.png') no-repeat; background-position: 27px 22px; position: absolute; top: 0; left: 0; background-size: 9px; }
    .dirDiv .folderUl li.pswFolder .ic-password { display: block; }

    /* 22.11.08 폴더 넘버 삭제 / 이동경로 표시 추가 / 하위폴더추가 */
    .settingBg .folderAddBtn { float: right; margin: 10px 20px 0 0; }
    .deep_route { width: 100%; height: 50px; background: #fff; border-bottom: 1px solid #e7e9ea; }
    .deep_route .routeUl { width: 100%; padding: 0 25px; box-sizing: border-box; height: 50px; line-height: 50px; display: flex; }
    .deep_route .routeUl li { max-width: 190px; height: 50px; line-height: 50px; float: left; position: relative; padding-right: 35px; box-sizing: border-box; cursor: pointer; }
    .deep_route .routeUl li:first-child { width: 58px; cursor: default; }
    .deep_route .routeUl li:before { content: ''; width: 7px; height: 7px; border-right: 1px solid #85889a; border-bottom: 1px solid #85889a; position: absolute; right: 18px; transform: rotate(-45deg); top: 21px; }
    .deep_route .routeUl li > a { line-height: 20px; vertical-align: middle; }
    .deep_route .routeUl li > a:hover { text-decoration: underline; }
    .deep_route .routeUl li.fixd_route { font-weight: bold; }
    .deep_route .routeUl li.fixd_route:before { display: none; }
    .routeUl .ic_file { width: 20px; height: 50px; display: inline-block; font-size: 0; background: url('../assets/images/menucons/ic_file_sm_dc9.png') no-repeat center center; background-size: 17px auto; vertical-align: top; }
    .dirDiv .folderUl li .ic-password { position: static; width: 15px; height: 15px; font-size: 0; background: url(/img/ic_password_232848.d5b48e83.png) no-repeat; background-position: center center; background-size: 9px; vertical-align: middle; margin-top: -3px; }
    .dirDiv .folderUl li.pswFolder .ic-password { display: inline-block; }
    .section_scroll .fileName { width: 40% !important; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-left: 4% !important; }
    .section_scroll .fdType { width: 14%; }
    .content > ul > li p.num { display: none; }
    .content > ul > li .preview { left: 4%; }
    .content > ul > li a.goDets { position: absolute; display: inline-block; height: 100%; left: 0; top: 0; width: 100%; font-size: 0; text-align: center; }
    .content .fileListUl li p.fd { position: absolute !important; left: 4%; width: 50px !important; padding-left: 0 !important;  text-align: left; font-size: 12px; height: 66px !important; line-height: 66px !important; padding-left: 0; font-weight: bold; background-position: left center; background-size: 41px 30px; }
    .fileListUl > li .fileMenuOp { display: none; }
    .fileListUl > li.on .fileMenuOp { display: block; }
    .fileListUl .fileMenuOp li { position: relative; width: 100%; margin: 0; border-radius: 0; border: none; text-align: left; border-bottom: none; }
    .content .fileListUl .subFolderLi .ic-password { display: none; width: 15px; height: 15px; font-size: 0; background: url(/img/ic_password_232848.d5b48e83.png) no-repeat center center; background-size: 9px; vertical-align: middle; margin-top: -2px; }
    .content .fileListUl .subFolderLi .pswFolder .ic-password { display: inline-block; }
    .content .fileListUl .subFolderLi .fd span.total { display: inline-block; width: 35px; line-height: 66px; text-align: center !important; color: #fff; }
    .dirDiv .folderUl li .folder span.total { display: inline-block; text-align: center; color: #fff; line-height: 51px; width: 39px; text-indent: 4px; font-size: 11px; vertical-align: top; }
</style>

<style>
    .content_scroll > .mCustomScrollBox { width:100% !important;}
</style>