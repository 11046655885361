<template>

    <!-- 수정시 modal addClass(edit) -->
    <div  id="department_add_modal" class="modal">
        <!-- 추가 또는 수정으로 변경 -->
        <div class="titleBox">
            <h1 class="" id="">진료과 등록</h1>
        </div>
        <div class="content">
            <div class="detail">
                <div class="titleDiv">
                    <label>진료과명</label>
                    <!-- 잘못된 값일 시 input addClass(wrong), 다시 수정시 wrong빼기 -->
                    <input type="text" placeholder="진료과명을 입력하세요" id="department_name" @input="department_name = $event.target.value" :value="department_name"/>
                    <label for="">자체코드</label>
                    <input type="text" placeholder="자체코드를 입력하세요" id="department_code" @input="inputDepartmentCode($event)" :value="department_setting_modal_info != null && department_setting_modal_info.department != null ? department_setting_modal_info.department.department_code : ''" :disabled="department_setting_modal_info != null && department_setting_modal_info.is_create == false"/>
                </div>
                <!-- <div class="fileboxDetail">
                    <h3>파일 수</h3>
                    <p><span class="num">10</span class="txt"><span> 개</span></p>
                </div> -->
            </div>
            <div class="btns">
                <input type="button" class="closeBtn" value="취소" v-on:click="close()"/>
                <input type="button" class="deleteBtn" value="삭제" />
                <input type="button" class="addBtn" value="등록" v-on:click="save()" />
                <input type="button" class="saveBtn" value="저장" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import {hodu_doc_object, hodu_doc_modal_info} from "@/model/hodudoc";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    }
}) 
export default class DepartmentSettingModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDepartmentSettingModalInfo ?: (params : hodu_doc_modal_info.DepartmentSettingModalInfo) => void;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State department_setting_modal_info !: hodu_doc_modal_info.DepartmentSettingModalInfo

    department_name : string = "";

    mounted() : void {
        $('#department_name').focus();
        if ( this.department_setting_modal_info.department != null ) {
            
            this.department_name = new String(this.department_setting_modal_info.department.department_name) as string
            console.log(this.department_name);
        }
    }

    save(){
        if ( this.department_setting_modal_info.department == null ) { return; }
        if ( this.department_setting_modal_info.is_create == false && this.department_setting_modal_info.department.department_name == this.department_name ) {
            return;
        }

        if (this.department_name.length < 1) {
            alert('진료과명을 입력하세요')
            return
        }
        if (this.department_setting_modal_info.department.department_code.length < 1) {
            alert('진료과 코드를 입력하세요')
            return
        }

        const biz_id = this.scope_group_team_option.biz_id;
        let body = {
            department_code : this.department_setting_modal_info.department.department_code,
            department_name : this.department_name
        }

        if ( this.department_setting_modal_info.is_create ) {
            this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/department`, API_METHOD.POST, body)
            .then((response) => {
                
                let jsonData = JSON.stringify(response.data.data);
                // console.log(jsonData);
                
                window['getDepartments']("BOTTOM");
                this.close()
            })
            .catch((e) => {
                if( e.response ) {
                    alert(e.response.data);
                    return;
                }

                this.hodu_error_process(e, false, false, true);
            });
        
        }else{
            this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/department`, API_METHOD.PUT, body)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                console.log(jsonData);
                
                window['getDepartments']();
                this.close()
            })
            .catch((e) => {
                if( e.response ) {
                    alert(e.response.data);
                    return;
                }

                this.hodu_error_process(e, false, false, true);
            });
        }
    }

    close(){
        if( this.doSetDepartmentSettingModalInfo ) { 
            this.doSetDepartmentSettingModalInfo({
                show_modal : false,
                is_create  : null,
                department : null
            })
        }
    }

    inputDepartmentCode(event) {
        const value = event.target.value;
        
        if( this.department_setting_modal_info.department != null ) {
            this.department_setting_modal_info.department.department_code = value;
        }
    }

}
</script>

<style scoped>
     h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #f1f3f5 }


    .btns { overflow: hidden; border-top:1px solid #f1f3f5;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }


    .modal { width:650px; position:absolute; z-index:10000000000000000000000000000000; background:#fff; border-radius:5px;margin:0 auto;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden; margin-top: -193px;}
    .modal h1 { font-size: 19px; padding: 0 30px;height:85px; line-height:100px; width:100%; box-sizing: border-box; border-bottom:1px solid #f1f3f5; }
    
    .modal .btns { width:100%;padding:0; border-top:1px solid #f1f3f5; }
    .modal .btns input { width:50%;transition:0.2s; background:#fff; font-size:14px; font-weight:bold; height:70px; line-height:70px;box-sizing: border-box; padding:0; }
    .modal .btns input.closeBtn:hover { color:#ff6060; }
    .modal .btns .saveBtn, .modal .btns .deleteBtn { display: none; }
    .modal .btns input.deleteBtn:hover { background:#ff6060; color:#fff; }
    .modal .btns input.saveBtn:hover, .modal .btns input.addBtn:hover { color:#477fff; }
    .modal .btns input:hover { background:#f1f3f5; }
    .modal .detail { padding:30px 40px; }
    .modal .detail .titleDiv label { font-size:13px; margin-bottom:10px;height:30px;line-height:30px; }
    .modal .detail .titleDiv input { transition:0.2s; line-height:55px; height:55px; padding: 0 15px; margin-bottom:20px; border: 1px solid #e7e9ea;width:100%; box-sizing: border-box;font-size:17px;font-weight:bold; } 
    .modal .detail .titleDiv input:last-child { margin-bottom:0; }
    .modal .detail .titleDiv input:hover { border:1px solid #d5dbde; background:#f1f3f5; }
    .modal .detail .titleDiv input:focus { border:1px solid #477fff; background:#fff !important; }
    .modal .detail .titleDiv input.wrong { border:1px solid #ff6060; background:#fff0f0; color:#ff6060; }
    .modal .detail .titleDiv input.wrong::placeholder { color:#ff6060; }
    .edit.modal .btns input { width:33.333333%; }
    .edit.modal .btns .addBtn { display:none; }
    .edit.modal .btns .saveBtn, .edit.modal .btns .deleteBtn { display:inline-block; }
</style>