<template>
    <div class="modal group_to_biz_modal">
        <div class="modal_content">

            <div class="pre_content" v-if="is_create_mode == false">
                <div class="pre_bg"></div>
                <p class="pre_p">PREMIUM</p>
                <p class="pre_descript">모두를 위한 그룹웨어 호두 프리미엄 <!-- <br>1개월 간 지금 <em>무료</em>로 체험하세요! --> </p>

                <ul class="pre_ul">
                    <li class="pre_file_li">
                        <div class="div_1">
                            <p class="pre_icon">파일함 아이콘</p>
                            <a class="pre_txt">파일함</a>
                        </div>
                        <div class="div_2">
                            <div class="div_2_bg">
                                <p class="title_div_2">What is it?</p>
                                <p>
                                    그룹&amp;팀 별 파일함<br>
                                    간단한 드랙엔드랍<br>
                                    멤버별 권한 설정                              
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="pre_sms_li">
                        <div class="div_1">
                            <p class="pre_icon">sms 서비스 아이콘</p>
                            <a class="pre_txt">SMS 서비스</a>
                        </div>
                        <div class="div_2">
                            <div class="div_2_bg">
                                <p class="title_div_2">What is it?</p>
                                <p>
                                    멤버에게 문자 전송<br>
                                    개별 문자 보내기<br>
                                    히스토리 저장
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="pre_work_li">
                        <div class="div_1">
                            <p class="pre_icon">프로젝트 아이콘</p>
                            <a class="pre_txt">프로젝트</a>
                        </div>
                        <div class="div_2">
                            <div class="div_2_bg">
                                <p class="title_div_2">What is it?</p>
                                <p>
                                    프로젝트 템플릿 생성<br>
                                    담당자 배정 가능<br>
                                    프로젝트 현황
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="pre_attach_li">
                        <div class="div_1">
                            <p class="pre_icon">파일&amp;사진 아이콘</p>
                            <a class="pre_txt">일정 파일&amp;사진</a>
                        </div>
                        <div class="div_2">
                            <div class="div_2_bg">
                                <p class="title_div_2">What is it?</p>
                                <p>
                                    일정 속 파일 첨부<br>
                                    일정 속 사진(10장 이내) 첨부
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="pre_elecapp_li">
                        <div class="div_1">
                            <p class="pre_icon">전자결재 아이콘</p>
                            <a class="pre_txt">전자결재</a>
                        </div>
                        <div class="div_2">
                            <div class="div_2_bg">
                                <p class="title_div_2">What is it?</p>
                                <p>
                                    기안서와 휴가원 작성<br>
                                    신속한 업무처리<br>
                                    히스토리 저장
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="pre_atndnce_li">
                        <div class="div_1">
                            <p class="pre_icon">근태관리 아이콘</p>
                            <a class="pre_txt">근태관리</a>
                        </div>
                        <div class="div_2">
                            <div class="div_2_bg">
                                <p class="title_div_2">What is it?</p>
                                <p>
                                    출퇴근기록의 실시간 동기화<br>
                                    다양한 근무 유형을 쉽게
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>

            <div class="pre_content" v-else>
                프리미엄 그룹 신청
                <div class="pre_input_container">
                    <ul class="pre_input_ul">

                        <li class="pre_input_li">
                            <label class="pre_label">단체명</label>
                            <input type="text" id="pre-input-company-name" class="pre_input_txt" :value="name" @input="name = $event.target.value" />
                        </li>

                        <li class="pre_input_li">
                            <label class="pre_label">연락처</label>
                            <input type="text" id="pre-input-tel" class="pre_input_txt" :value="tel" @input="tel = $event.target.value" disabled/>
                        </li>

                        <li class="pre_input_li">
                            <label class="pre_label">이메일</label>
                            <input type="text" id="pre-input-email" class="pre_input_txt" :value="email" @input="email = $event.target.value" />
                        </li>

                        <!-- <li class="pre_input_li">
                            <label class="pre_label">사원수</label>
                            <input type="text" class="pre_input_txt" />
                        </li> -->

                        <!-- <li class="pre_input_li">
                            <label class="pre_label">추천인</label>
                            <input type="text" class="pre_input_txt" />
                        </li> -->

                    </ul>
                </div>
            </div>

            <div class="btns" v-if="is_create_mode == false">
                <input type="button" class="input_btn" value="취소" @click="closeModal"/>
                <input type="button" class="input_btn pre_btn" value="프리미엄 신청" @click="groupChangeService"/>
            </div>
            
            <div class="btns" v-else>
                <input type="button" class="input_btn" value="뒤로" @click="prev"/>
                <input type="button" class="input_btn pre_btn" value="신청" @click="submit"/>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {

    }
})
export default class GroupToBizModal extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowGroupToBizModal ?: any;

    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;

    is_create_mode : boolean = false;

    name : string = "";
    tel : string = "";
    email : string = "";

    mounted() : void {
        $(".pre_ul .pre_txt").click(function(){
            $(this).parent().siblings(".div_2").addClass("on");
        });
        $(".pre_ul li .div_2").mouseleave(function(){
            $(this).removeClass("on");
        });

        this.tel = this.user_phone_number;
    }

    /**
     * Modal 닫기
     */
    closeModal() : void {
        window['groupChangeService'] = null;
        this.doSetShowGroupToBizModal(false);
    }

    /**
     * 그룹 비즈로 전환
     */
    groupChangeService() : void {
        this.is_create_mode = true;
    }

    /**
     * 이전으로
     */
    prev() : void {
        this.is_create_mode = false;
        
        this.$nextTick(() => {
            $(".pre_ul .pre_txt").click(function(){
                $(this).parent().siblings(".div_2").addClass("on");
            });
            $(".pre_ul li .div_2").mouseleave(function(){
                $(this).removeClass("on");
            });
        });
    }

    /**
     * 유효성 검사
     */
    checkValid() : boolean {
        
        this.name = this.name.trim(); 
        this.tel = this.tel.trim();
        this.email = this.email.trim(); 

        // 회사명 입력 안함
        if( this.name == null || this.name.length < 1 ) {
            alert("회사명을 입력해주세요")
            $('#pre-input-company-name').addClass('wrong');
            $('#pre-input-company-name').focus();
            return false;
        }

        // 담당자 연락처 입력 안함
        if( this.tel == null || this.tel.length < 1 ) {
            alert("담당자 연락처를 입력해주세요")
            $('#pre-input-tel').addClass('wrong');
            $('#pre-input-tel').focus();
            return false;
        }

        // 담당자 이메일 입력 안함
        if( this.email == null || this.email.length < 1 ) {
            alert("담당자 이메일을 입력해주세요")
            $('#pre-input-email').addClass('wrong');
            $('#pre-input-email').focus();
            return false;
        }

        return true;
    }

    /**
     * 프리미엄 신청
     */
    async submit() : Promise<void> {
        if( this.checkValid() == false ) return ;

        // 변경 + 이메일
        try {

            await this.hodu_show_indicator();

            // 프리미엄 그룹으로 변경 API
            const response = await this.hodu_api_call(`api/v1/groupsweb/bizGroupChange/${this.group_id}`, API_METHOD.PUT, {
                "name" : this.name,
                "tel" : this.tel,
                "email" : this.email
            }, false);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("프리미엄 그룹 변경 중 오류 발생");
            }

            try {
                let head_count = 0;

                const target = this.group_info_list.filter(item => item.group_id == this.group_id);

                if( target.length > 0 ) {
                    head_count = target[0].user_count;
                }

                await this.hodu_api_call(`api/v1/voucher?is_voucher=false`, API_METHOD.POST, {
                    "company_name" : this.name,
                    "manager_name" : this.user_name,
                    "email" : this.email,
                    "phone_number" : this.tel,
                    "head_count" : head_count
                }, false);
                
            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }

            window['groupChangeService']?.();
            this.doSetShowGroupToBizModal(false);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("프리미엄 그룹 변경 중 오류 발생");
        } finally {
            await this.hodu_hide_indicator();
        }
        
        
    }

    @Watch('name')
    changeCompanyName() : void {
        $('#pre-input-company-name').removeClass('wrong');
    }

    @Watch('tel')
    changeTel() : void {
        $('#pre-input-tel').removeClass('wrong');
    }

    @Watch('email')
    changeEmail() : void {
        $('#pre-input-email').removeClass('wrong');
    }

}
</script>

<style scoped>
    .modal { width: 900px; background:#fff; position:absolute; left:50%; top: 50%; margin-top:-233.5px; margin-left:-450px; box-sizing: border-box; border-radius: 5px; min-height:467px; overflow:hidden; }
    .btns { height:80px; overflow:hidden; border-top: 1px solid #f1f3f5; box-sizing:border-box; }
    .btns input {width:50%; float:left; border:0 none;height: 80px; line-height:80px;border-radius: 0 !important;overflow: hidden; }
    .btns input.pre_btn { color:#477fff !important; }
    .btns input.pre_btn:hover { background:#477fff !important; color:#fff !important; }

    .pre_bg { background:#f1f3f5; width:100%; height:267px; position:absolute; top:0; left:0; z-index: -1; }
    .pre_content { text-align: center; font-size: 23px; height:387px;box-sizing: border-box; padding-top:60px; }
    .pre_content em { font-style: normal; font-weight:bold; color:#477fff;  }
    .pre_p { display:inline-block; height: 25px; line-height:25px; border-radius:15px; padding: 0 20px;background:#ffd50f; border:1px solid #ffc300; font-size: 12px; font-weight:bold;margin-bottom:15px; }
    .pre_descript { line-height:35px; line-height: 40px; }

    .pre_ul { width:100%; text-align:center; margin-top:30px; }
    .pre_ul li { position:relative; top:0; transition:0.1s; width: 140px; height:150px;padding-top:10px; text-align:center; margin:0 15px;border-radius:5px; overflow:hidden; box-shadow: 0 5px 10px rgba(0,0,0,0.1); background:#fff; display:inline-block; }
    .pre_ul li .pre_icon {  display:block; margin:0 auto; width:60px; height:60px; margin-top:20px; background-size: 50px; background-position:center center; background-repeat: no-repeat; font-size:0;  }
    .pre_ul li:hover { top:-10px;}
    .pre_ul li:hover .pre_txt { color:#232848 }
    .pre_ul .pre_txt { display: inline-block; padding:0 10px; font-size: 12px; transition:0.1s; margin-top:20px; font-weight:bold; width:100px;box-sizing:border-box; color:#6e6f7b; line-height: 30px; height:32px; border:1px solid #f1f3f5; border-radius:5px; }
    .pre_ul li:hover .pre_txt:hover { background:#477fff; color:#fff; border-color:#477fff; }
    
    .div_2.on { margin-top:0; }
    .div_2 { transition:0.2s;margin-top:150%; position:absolute; top:0; left:0 ;width:100%; height:100%; padding:15px; box-sizing: border-box; text-align:left;background:rgba(46, 56, 86,0.9) }
    .div_2 p { font-size: 12px;color:#fff; line-height:22px; }
    .title_div_2 { border-bottom:1px solid #fff; font-weight:bold; text-align:center; margin-bottom:10px; padding-bottom:5px; }
    
    .pre_ul li.pre_file_li .pre_icon {background-image:url(../assets/images/contents/ic_pre_file.png);}
    .pre_ul li.pre_sms_li .pre_icon {background-image:url(../assets/images/contents/ic_pre_sms.png);}
    .pre_ul li.pre_work_li .pre_icon {background-image:url(../assets/images/contents/ic_pre_work.png);}
    .pre_ul li.pre_attach_li .pre_icon {background-image:url(../assets/images/contents/ic_pre_attach.png);}

    .pre_content .pre_input_container { font-size : 13px; font-weight: bold; padding : 0 40px; }
    .pre_content .pre_input_container .pre_input_ul .pre_input_li { margin-bottom : 10px; }
    .pre_content .pre_input_container .pre_input_ul .pre_input_li .pre_label { text-align: left;width: 100%; display: inline-block; height:40px; line-height: 50px; }
    .pre_content .pre_input_container .pre_input_ul .pre_input_li .pre_input_txt { display: block; width: 100%; border:1px solid #e3e7ed;height:40px; line-height: 40px; box-sizing:border-box; padding: 0 15px; font-weight: bold; font-size:12px;  transition:0.2s; }
    .pre_content .pre_input_container .pre_input_ul .pre_input_li .pre_input_txt:hover { background: #f1f3f5; }
    .pre_content .pre_input_container .pre_input_ul .pre_input_li .pre_input_txt:focus { background: #fff; border:1px solid #477fff; }
    .pre_content .pre_input_container .pre_input_ul .pre_input_li .pre_input_txt.wrong { border:1px solid #ff6060; background: #fff1f1; color: #ff6060;}

    .content-box .button-area { margin-top : 20px; text-align: center; }
    .content-box .button-area .input_btn { width : 100px; margin : 10px; }

    /* 21.03.18 전자결재/근태관리 추가 수정 */
    .pre_bg {height: 227px; }
    .pre_ul li {width: 217px; height: 71px; margin-bottom: 15px; margin-top: 0 !important; }
    .pre_ul li .pre_icon {display: inline-block; margin: 0 0 0 8px !important; float: left; }
    .pre_ul li {margin-top: 15px; }
    .pre_ul li.pre_elecapp_li .pre_icon {background-image:url(../assets/images/contents/ic_pre_elecapp.png);}
    .pre_ul li.pre_atndnce_li .pre_icon {background-image:url(../assets/images/contents/ic_pre_atndnce.png);}
    .div_2 {padding: 10px; }
    .div_2_bg {width: 100%; height: 100%; padding-left: 26px; display: flex; align-items: center; }
    .div_2_bg p {position: relative; }
    .div_2_bg p:before {content: ''; width: 1px; height: 85%; background: #fff; position: absolute; top: 4px; left: -15px; }
    .title_div_2 {display: none; }
</style>