<template>
    <!-- 오른쪽 더보기 버튼 클릭시 클래스추가 leftOn  -->
    <div class="reservation_create section_ce_fix"
         :class="{ leftOn : is_open_right_list == true }">
        
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">예약 등록</h3>
        </div>

        <div>
            <div class="viewGroup">
                <div class="schedule_box">
                    <div class="grp settingBg">
                        <div class="menuBg"></div>
                        <div class="posRel">
                            <div class="bg">
                                <div class="selectOptionDiv">
                                    
                                    <select @change="changeDepartment($event.target.value)">
                                        <option :key="department.department_code" v-for="department in computedDoctorExistDepartment" :selected="isSelectedDepartment(department.department_code)" :value="department.department_code">
                                            {{ department.department_name }}
                                        </option>
                                        <!-- <option value="" selected>내과</option>
                                        <option value="">마취과</option> -->
                                    </select>

                                    <div class="seperVertical"></div>

                                    <select @change="changeDoctor($event.target.value)">
                                        <option :key="doctor.doctor_code" v-for="doctor in computedDepartmentDoctor" :selected="isSelectedDoctor(doctor.doctor_code)" :value="doctor.doctor_code">
                                            {{ doctor.doctor_name }}
                                        </option>
                                        <!-- <option value="" selected>김동현</option>
                                        <option value="">이나영</option>
                                        <option value="">유지역</option> -->
                                    </select>

                                </div>
                                <div class="seperVertical"></div>
                                <h3 class="blind">시작일자와 종료일자를 선택하세요.</h3>
                                <div class="bigGrp left" id="reservation_fr_ymd_div">

                                    <!-- 이전 날짜 버튼 -->
                                    <input type="button" id="btnPrev" class="toolbar-btn bt-prev mr10" :class="{ disabled : isLeftEnd() == true }" @click="selectDateByOffset(-7)" />

                                    <!-- datepicker -->
                                    <div class="grp">
                                        <label for="available_fr_ymd" class="from blind">시작일자</label>
                                        <input type="button" class="calFrom" id="available_fr_ymd" :value="hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')"/>
                                    </div>

                                    <!-- 다음 날짜 버튼 -->
                                    <input type="button" id="btnNext" class="toolbar-btn bt-next mr10" :class="{ disabled : isRightEnd() == true }" @click="selectDateByOffset(7)" />

                                    <!-- 오늘 버튼 -->
                                    <input type="button" id="btnToday" value="오늘" class="toolbar-btn today " @click="getTodayAvailableList"/>
                                </div>

                                <!-- 오른쪽 상세 메뉴 ON / OFF -->
                                <a class="docDetLink" :class="{ on : is_open_right_list == true }" @click="rightOnOff"><span></span><span></span><span></span></a>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="content">
                    
                    <AppointmentAvailableListLeft  
                        :event_bus="event_bus" 
                        :max_date="max_date" 
                        :selected_date="selected_date" 
                        :selected_doctor="selected_doctor" 
                        :is_open_right_list="is_open_right_list"
                        @selectDate="selectDate" />

                    <AppointmentAvailableListRight 
                        :event_bus="event_bus" 
                        :max_date="max_date"  
                        />

                </div>

            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { hodu_doc_object } from '@/model/hodudoc';

import AppointmentAvailableListLeft from '@/components/hodu_d/AppointmentAvailableListLeft.vue';
import AppointmentAvailableListRight from '@/components/hodu_d/AppointmentAvailableListRight.vue';

import { ResizeObserver } from 'vue-resize';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        AppointmentAvailableListLeft, AppointmentAvailableListRight, ResizeObserver
    },
}) 
export default class AppointmentCreate extends Mixins(VueHoduCommon) {

    event_bus : Vue = new Vue();

    is_open_right_list : boolean = true;

    max_date            : Date = new Date();
    selected_date       : Date = new Date();
    selected_department : hodu_doc_object.department_info | null = null;
    selected_doctor     : hodu_doc_object.doctor_info | null = null;

    /**
     * 의사가 한 명이라도 존재하는 진료과만 반환
     */
    get computedDoctorExistDepartment() : hodu_doc_object.department_info[] {

        let doctor_exist_department : hodu_doc_object.department_info[] = [];

        if( this.department_info != null && this.doctor_info != null ) {

            for( const department of this.department_info ) {

                if( this.doctor_info.filter(item => item.department_code == department.department_code).length > 0 ) {
                    doctor_exist_department.push(department);
                }

            }

        }

        return doctor_exist_department;
    }

    /**
     * 선택된 진료과의 의사만 반환
     */
    get computedDepartmentDoctor() : hodu_doc_object.doctor_info[] {

        let department_doctor : hodu_doc_object.doctor_info[] = [];

        if( this.selected_department != null && this.doctor_info != null ) {
            const department_doctor_temp = this.doctor_info.filter(item => this.selected_department ? item.department_code == this.selected_department.department_code : false);
            department_doctor = department_doctor.concat(department_doctor_temp);
        }

        return department_doctor;
    }

    beforeMount() : void {
        // 1달 후의 토요일까지
        this.max_date = new Date(this.selected_date);
        this.max_date.setMonth(this.max_date.getMonth() + 1);
        this.max_date.setDate(this.max_date.getDate() + 6 - this.max_date.getDay());
    }

    async mounted() : Promise<void> {

        // 진료과 조회 && 의사 조회 && 병원 시간표 조회
        // console.log("Before call get_hodu_d_info");
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);
        // console.log("After call get_hodu_d_info");

        // 진료과가 없는 경우
        if( this.department_info == null || this.department_info.length < 1 || this.computedDoctorExistDepartment.length < 1 ) {
            this.hodu_show_dialog("cancel", "진료과를 등록해주세요", ["확인"], [() => {
                this.movePrevPage();
            }]);
            return;
        }
        
        // 가장 첫번째 진료과 선택 나중에 마지막에 선택한 진료과가 될 수 있음
        this.selected_department = this.computedDoctorExistDepartment[0];

        // 의사가 없는 경우
        if( this.doctor_info == null || this.doctor_info.length < 1 ) {
            this.hodu_show_dialog("cancel", "의사를 등록해주세요", ["확인"], [() => {
                this.movePrevPage();
            }]);
            return;
        }
        
        // 가장 첫번째 진료과 선택 나중에 마지막에 선택한 의사가 될 수 있음
        this.selected_doctor = this.computedDepartmentDoctor[0];

        const vue = this;
        
        const datepicker_option = {
            inline: false,
            minDate : new Date(),
            maxDate : this.max_date,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText);
                inst.input.val(mnt.format('YYYY.MM'));
                vue.selected_date = mnt.toDate();
            },
        };

        // @ts-ignore
        $('#available_fr_ymd').datepicker(datepicker_option);

        this.$nextTick(() => { this.event_bus.$emit('defaultDataInit'); });
    }

    /**
     * 오른쪽 리스트 닫기/열기
     */
    rightOnOff() : void {
        this.is_open_right_list = !this.is_open_right_list;
        this.event_bus.$emit('rerenderHooper');
    }

    /**
     * 진료과 변경
     */
    changeDepartment(value : string) : void {

        const department_list : hodu_doc_object.department_info[] = this.computedDoctorExistDepartment.filter(item => item.department_code == value);

        if( department_list.length < 1 ) { return; }

        this.selected_department = JSON.parse(JSON.stringify(department_list[0]))

        const doctor_list : hodu_doc_object.doctor_info[] = this.computedDepartmentDoctor.filter(item => this.selected_department ? item.department_code == this.selected_department.department_code : false);

        if( doctor_list.length < 1 ) { return; }

        this.selected_doctor = JSON.parse(JSON.stringify(doctor_list[0]));
    }

    /**
     * 선택된 진료과 여부
     */
    isSelectedDepartment(department_code : string) : boolean {
        if( !this.selected_department ) { return false; }

        return this.selected_department.department_code == department_code;
    }

    /**
     * 의사 변경
     */
    changeDoctor(value : string) : void {
        const doctor_list : hodu_doc_object.doctor_info[] = this.computedDepartmentDoctor.filter(item => item.doctor_code == value);

        if( doctor_list.length < 1 ) { return; }

        this.selected_doctor = JSON.parse(JSON.stringify(doctor_list[0]));
    }

    /**
     * 선택된 의사 여부
     */
    isSelectedDoctor(doctor_code : string) : boolean {
        if( !this.selected_doctor ) { return false; }

        return doctor_code == this.selected_doctor.doctor_code;
    }

    /**
     * 날짜 선택
     */
    selectDate(date : Date) : void {
        this.selected_date = date;
    }

    /**
     * 일 단위 offset 값 만큼 날짜 선택
     */
    selectDateByOffset(date_offset : number) : void {
        
        // 이전 주로 이동할 때 이미 왼쪽 끝이라면 무시 
        if( date_offset < 0 && this.isLeftEnd() ) { return; }

        // 다음 주로 이동할 때 이미 오른쪽 끝이라면 무시
        if( date_offset > 0 && this.isRightEnd() ) { return; }

        // 날짜 이동
        let move_date : Date = moment(this.selected_date).add("d", date_offset).toDate();
        if( move_date.getTime() < new Date().getTime() ) { move_date = new Date(); }
        if( move_date.getTime() > this.max_date.getTime() ) { move_date = this.max_date; }
        this.selected_date = move_date;
    }

    /**
     * 오늘 날짜로 이동
     */
    getTodayAvailableList() : void {
        this.selected_date = new Date();
    }

    /**
     * 왼쪽 끝 주간 여부
     */
    isLeftEnd() : boolean {
        const current_diff : number = this.getDateDiff(moment().set("date", new Date().getDate() - new Date().getDay()).toDate(), this.selected_date);
        const current_index : number = Math.floor(current_diff / 7);

        return current_index < 1;

    }

    /**
     * 오른쪽 끝 주간 여부
     */
    isRightEnd() : boolean {
        
        const current_diff : number = this.getDateDiff(moment().set("date", new Date().getDate() - new Date().getDay() - 1).toDate(), this.selected_date);
        const current_index : number = Math.ceil(current_diff / 7);
        const date_period : number = this.getDateDiff(moment().set("date", new Date().getDate() - new Date().getDay()).toDate(), this.max_date);
        const total_week : number = Math.ceil(date_period / 7);

        return current_index == total_week;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.event_bus.$emit('leftAvailableListResize');
        this.event_bus.$emit('rightAvailableListResize');
    }

    /**
     * 왼쪽 메뉴 접기 / 펼치기 변할때 hooper 다시 렌더링 
     */
    @Watch('left_control_box_flag')
    changeLeftControlBoxFlag() : void {
        this.event_bus.$emit('rerenderHooper');
    }

}
</script>

<style scoped>
    /* 기간별, 월별,일별,주별 */
    .selectOptionDiv {float:left; overflow: hidden; padding-left: 20px;box-sizing: border-box; height: 60px;line-height:60px;display:inline-block; font-size: 14px; font-weight:bold;}
    .selectOptionDiv select { max-width: 100px; float: left; position: static !important; cursor:pointer; padding: 0 10px; padding-right:20px; line-height:40px; font-size: 14px; margin-top:11px;  font-weight:bold;-webkit-appearance: none;-moz-appearance: none;text-indent: 1px;text-overflow: '';}
    .selectOptionDiv .seperVertical { margin-right:10px }
    .seperVertical { display:block;width:2px;height: 20px; margin-top:22px; background:#f1f3f5; float:left; }
    .docDetLink { position:relative; transition:0.1s; margin-top: 14px;display: inline-block;font-size: 0;width: 35px;height: 35px;border-radius: 50%;background: #f1f3f5;-webkit-box-sizing: border-box;box-sizing: border-box;text-align: center;float: right;margin-right: 30px;}
    .docDetLink span { transition:0.1s; display: inline-block;width: 4px;height: 4px;border-radius: 5px;background: #232848;margin: 0 2px;margin-top: 16px;float: left; }
    .docDetLink span:first-child { margin-left:7px }
    .docDetLink:hover { background:#477fff }
    .docDetLink:hover span {background-color: #fff; }
    .docDetLink.on { background-color:#477fff }
    .docDetLink.on span {background-color:#fff; margin:0; position: absolute;top: 10px;left: 16px;width: 2px;height: 16px; transform: rotate(45deg); -webkit-transform: rotate(45deg);   }
    .docDetLink.on span:first-child {left: 16px; transform: rotate(-45deg); -webkit-transform: rotate(-45deg); }

    .btnBox { padding: 11px 30px 0 0; }
    .bt-prev, .bt-next { margin-top:0 !important; }
    .bt-prev { margin-right:0 !important; }
    .settingBg .seper { margin-left: 10px; margin-right:10px; }
    .settingBg .left { margin-left: 20px; transition:0.2s; }
    .toolbar-btn.today { line-height: 33px; margin-top: 2px !important; transition:0.2s; float: left;}
    .toolbar-btn.today:hover { background-color:#e7eaec !important; }

    .settingBg .left .grp { max-width: 110px!important; min-width: 110px !important; }
    .settingBg .left .grp input.calFrom, .settingBg .left .grp input.calTo {width: 100%;padding-right: 0; background:none; text-align:center; margin-right:0 !important; font-size:16px}
    .checkBox input[type='checkbox']:not(old) + label {background-color: #c1cfd8 !important;border: 2px solid #c1cfd8 !important;background-size:21px !important;display: inline-block;padding: 0;width: 20px;height: 20px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../assets/images/contents/checked_fff.png) no-repeat 0px 0px;background-size: 21px;font-size: 0;margin-right: 8px;}
    .checkBox input[type='checkbox']:not(old):checked + label {background-color: #477fff !important;border: 2px solid #477fff !important;background-size:21px !important;}

    .content { margin-top:0; }

    input#btnPrev.disabled { opacity: 0.3; }  
    input#btnNext.disabled { opacity: 0.3; }  
    input#btnPrev.disabled:hover { cursor: default; background-color: #fff; }  
    input#btnNext.disabled:hover { cursor: default; background-color: #fff; }  

    #container #section_ce.left_area_close .schedule_box {padding-left: 61px;box-sizing: border-box;}
    .left_area_close .content {overflow: hidden;padding-left: 61px !important;}
</style>