<template>
    <div id="supply" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">비품·수불 상세보기</h3>
            <p class="btn_group">
                <input type="button" class="input_btn" value="수정" @click.prevent="moveSupplyModify">
                <input type="button" class="input_btn" value="삭제" @click.prevent="deleteHomeSupply">
            </p>
        </div>

        <div id="bt_scheduleBoxNoti" class="bt_scheduleBox" v-if="supply != null">
            <div class="inbox">
                <!-- <div class="event_btn">
                    <input type="button" title="제품등록" class="btn_supply_input" @click.prevent="moveSupplyCreate">
                    <p>제품등록</p>
                </div> -->
                <div class="event_btn">
                    <input type="button" title="입고 등록" class="btn_buy_input" @click.prevent="moveSupplyManagementCreate('inbound')">
                    <p>입고</p>
                </div>
                <div class="event_btn">
                    <input type="button" title="출고 등록" class="btn_send_input" @click.prevent="moveSupplyManagementCreate('outbound')">
                    <p>출고</p>
                </div>
            </div>
            <input type="button" class="bt_schedule" value="생성">
        </div>

        <!-- <input type="button" class="input_btn" value="수정" @click.prevent="moveSupplyModify" /> -->
        <!-- <input type="button" class="input_btn" value="이력등록" @click.prevent="moveSupplyManagementCreate"/> -->
        <!-- <input type="button" class="input_btn" value="이력상세" @click.prevent="showSupplyManagementDetail"/> -->

        <div id="supply_detail" v-if="supply != null">
            <div class="eqinfor eqinfor03 supplyIn01">
                <div class="eqinfor03_box supplyIn01_box">
                    <div class="eq_explanation supply_explanation">
                        <ul>
                            <li>
                                <p class="supplyTitle">품명</p>
                                <p class="supplyTitle_text">{{ hodu_string_validation(supply.supply_data.name) }}</p>
                            </li>
                            <li>
                                <p class="supplyClass">분류</p>
                                <p class="supplyClass_text">{{ hodu_string_validation(supply.supply_data.category) }}</p>
                            </li>
                            <li>

                                <p class="supplyTime">등록일</p>
                                <p class="supplyTime_text">
                                    {{ hodu_date_to_format_string(hodu_yyyymmdd_to_date(supply.supply_data.date), 'YYYY.MM.DD') }}
                                    ({{ getDayOfWeekByDate(hodu_yyyymmdd_to_date(supply.supply_data.date)) }})
                                </p>

                            </li>
                            <li>
                                <p class="supplyManager">관리자</p>
                                <p class="supplyManager_text">{{ hodu_string_validation(supply.supply_data.manager) }}</p>
                            </li>
                            <li>
                                <p class="supplyMamaTeam">관리부서</p>
                                <p class="supplyMamaTeam_text">{{ hodu_string_validation(supply.supply_data.manager_department) }}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="eq_picture supply_picture">
                        <h3 class="ir_so blind">제품사진</h3>
                        <p class="eq_img supply_img" :style="{ 
                            backgroundImage : supply.supply_data.image ? `url(app_images/${supply.supply_data.image.url})` : `url(${default_image})`,
                            backgroundSize : supply.supply_data.image ? 'cover' : 'auto',
                        }"></p>
                    </div>
                </div>
            </div>
            <!-- //첫번째 사진 및 설명 -->
            <div class="eqinfor eqinfor02 supplyIn02">
                <ul class="infor_box">
                    <li class="text_one">
                        <p class="eqRemark supplyRemark">비고(용도)</p>
                        <p>{{ hodu_string_validation(supply.supply_data.desc ) }}</p>
                    </li>
                </ul>
            </div>
            <!-- //두번째 비고 -->
            <div class="eqCardList supplyCardList">
                <h2>입·출고 내역</h2>
                <div id="eqinfor04_box" class="eqinfor04_list supplyIn03_list">
                    <div class="fixedDiv">
                        <ul id="" class="sortHeader">
                            <li class="supplySubmit">등록일</li>
                            <li class="buyCount">구입량</li>
                            <li class="price">구입단가</li>
                            <li class="sendCount">출고량</li>
                            <li class="supply_remark">비고</li>
                            <li class="stock">재고</li>
                            <li class="writtenBy">관리자</li>
                        </ul>
                    </div>
                    <div class="eqinfor04_con supplyIn03_con">
                        <ul class="eqinfor04Ul supplyIn03Ul">

                            <li :key="management.seq" v-for="management in supply.managements">
                                <p class="supplySubmit">
                                    {{ hodu_date_to_format_string(management.audit_created, 'YYYY.MM.DD') }}
                                    ({{ getDayOfWeekByDate(management.audit_created) }})
                                </p>
                                <p class="buyCount">{{ management.management_data.type == 'inbound' ? management.management_data.count : '-' }}</p>
                                <p class="price">{{ management.management_data.type == 'inbound' ? hodu_string_validation(String(management.management_data.unit_price)) : '-' }}</p>
                                <p class="sendCount">{{ management.management_data.type == 'outbound' ? management.management_data.count : '-' }}</p>
                                <p class="supply_remark">{{ hodu_string_validation(management.management_data.desc) }}</p>
                                <p class="stock">
                                    {{ getStock(supply.managements, management) }}
                                </p>
                                <p class="writtenBy">김관리</p>
                                <a href="#" class="eqcardDetail supplycardDetail" @click.prevent="showSupplyManagementDetail(management.seq)">이력 상세보기</a>
                            </li>

                            <!-- <li>
                                <p class="supplySubmit">2021.06.21 (월)</p>
                                <p class="buyCount">12</p>
                                <p class="price">13,000</p>
                                <p class="sendCount">-</p>
                                <p class="supply_remark">정비에 맞추어 수량 입고</p>
                                <p class="stock">20</p>
                                <p class="writtenBy">김관리</p>
                                <a href="#" class="eqcardDetail supplycardDetail" @click.prevent="showSupplyManagementDetail">이력 상세보기</a>
                            </li>
                            <li>
                                <p class="supplySubmit">2021.06.20 (일)</p>
                                <p class="buyCount">-</p>
                                <p class="price">-</p>
                                <p class="sendCount">3</p>
                                <p class="supply_remark">1단지,3단지,4단지 경비실</p>
                                <p class="stock">8</p>
                                <p class="writtenBy">김관리</p>
                                <a href="#" class="eqcardDetail supplycardDetail" @click.prevent="showSupplyManagementDetail">이력 상세보기</a>
                            </li>
                            <li>
                                <p class="supplySubmit">2021.06.18 (금)</p>
                                <p class="buyCount">-</p>
                                <p class="price">-</p>
                                <p class="sendCount">4</p>
                                <p class="supply_remark">1단지,3단지,4단지 경비실</p>
                                <p class="stock">20</p>
                                <p class="writtenBy">정관리</p>
                                <a href="#" class="eqcardDetail supplycardDetail" @click.prevent="showSupplyManagementDetail">이력 상세보기</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <!-- //세번째 내역 -->
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class Supply extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetSupplyManagementDetailModalInfo ?: (params : hodu_home_modal_info.SupplyManagementDetailModalInfo) => void;

    uid : string = "";
    supply : any = null;

    default_image = require("@/assets/images/contents/hoduhome_car_camera.svg");

    beforeMount() : void {
        this.uid = this.$route.params.uid;

        if( this.uid == null || this.uid.trim().length < 1 ) {
            this.hodu_show_dialog('cancel', '비품 조회에 필요한 데이터가 부족합니다', ['확인'], [() => { this.movePrevPage(); }])
        }
    }

    mounted() : void {
        this.getHomeSupply();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0);

        // @ts-ignore
        $('#supply_detail').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * 비품 조회
     */
    async getHomeSupply() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${this.uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.supply ) {
                throw new Error("비품 조회 중 오류 발생");
            }

            this.supply = JSON.parse(JSON.stringify(response.data.data.supply));

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 비품 삭제
     */
    async deleteHomeSupply() : Promise<void> {

        this.hodu_show_dialog('cancel', "비품을 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {

                try {

                    const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${this.uid}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("비품 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_show_dialog('cancel', "비품 삭제 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
                
            }
        ]);

    }

    /**
     * 재고수 반환
     */
    getStock(managements, current_management) : number {

        const index = managements.indexOf(current_management);
        
        let stock : number = 0;
        let array_count : number = managements.length;

        for( let i = (array_count - 1); i >= index; i-- ) {
            const management = managements[i]
            if ( management.management_data.type == 'inbound' ) stock += management.management_data.count;
            if ( management.management_data.type == 'outbound' ) stock -= management.management_data.count;
        }

        return stock;
    }

    /**
     * 비품 수정
     */
    moveSupplyModify() : void {
        this.hodu_router_push(`/home/supply/modify/${this.uid}`);
    }

    /**
     * 비품 입·출고 내역 등록
     */
    moveSupplyManagementCreate(type) : void {
        this.hodu_router_push(`/home/supply/${this.uid}/management/${type}/create`);
    }

    /**
     * 비품 입·출고 내역 상세
     */
    showSupplyManagementDetail(seq) : void {
        this.doSetSupplyManagementDetailModalInfo?.({
            show_modal : true,
            uid : this.uid,
            seq : seq,
            callback : this.getHomeSupply
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#supply_detail').mCustomScrollbar("destroy");

        this.setScroll();
    }

}
</script>

<style scoped>

    #supply_detail #eqinfor04_box .sortHeader li { width: 16%; }
    #supply_detail #eqinfor04_box .eqinfor04Ul > li p { width: 16%; }
    #supply_detail #eqinfor04_box.supplyIn03_list .supplySubmit { padding-left: 20px; }
    #supply_detail #eqinfor04_box.supplyIn03_list .supply_remark { width: 28%; }
    #supply_detail #eqinfor04_box.supplyIn03_list .price { width: 10%; }
    #supply_detail #eqinfor04_box.supplyIn03_list .buyCount, #supply_detail #eqinfor04_box.supplyIn03_list .sendCount, #supply_detail #eqinfor04_box.supplyIn03_list .stock { width: 10%; text-align: right; padding-right: 40px; }
    #supply_detail #eqinfor04_box .eqinfor04Ul > li p.stock { color: #477fff; }
</style>