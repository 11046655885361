<template>
    <div class="section_ce_fix">

	<resize-observer @notify="handleResize" />

	<div class="title_box" style="">
		<a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
		<h3 class="title_doc" style="" id="viewTitle">발송 현황</h3>
		<a class="schBtn">검색하기</a>
	</div>	
	<!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
    <div class="searchDiv">
        <div class="schGrp">
            <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
            <input type="text" id="group_member_search" placeholder="멤버를 검색하세요">
            <div class="btns">
                <!-- 검색 디브 removeClass(on) -->
                <a class="closeBtn">닫기</a>
                <!-- <a href="#" class="bt_search"><span class="blind">찾기버튼</span></a> -->
            </div>
        </div>
    </div>
	<div class="msgPage section_scroll" id="msgPageGubun"><!--스크롤 생겨야할 영역-->
		<div class="schedule_box">
			<div class="grp settingBg">
				<div class="menuBg"></div>
				<div class="posRel">
					<div class="bg">
						<h3 class="blind">시작일자와 종료일자를 선택하세요.</h3>
						<div class="bigGrp left" id="msg_status_fr_ymd_div">
							<div class="grp">
								<div class="bg"></div>
								<label for="msg_status_fr_ymd" class="from blind">시작일자</label>
								<input type="button" class="calFrom" id="msg_status_fr_ymd" :value="`${start_date ? (displayDate(start_date, 'yyyy.mm.dd') + ' ' + getYoil(start_date) + '요일') : ''}`"/>
							</div>
							<div class="seper">에서</div>
							<div class="grp">
								<div class="bg"></div>
								<label for="msg_status_to_ymd" class="to blind">종료일자</label>
								<input type="button" class="calTo" id="msg_status_to_ymd" :value="`${start_date ? (displayDate(end_date, 'yyyy.mm.dd') + ' ' + getYoil(end_date) + '요일') : ''}`"/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="content" id="allSentMsgUbody">
				<!-- 결과 없을시 -->
<!-- 				<div class="noResultsBg" id="noReusltList">
					<p class="noResults">선택하신 일자에 검색된 결과가 없습니다.</p>
				</div> -->
				<ul class="mainUl" id="mainUlHeader">
					<li>
						<ul class="msgHeader sortHeader" id="listHeader">
							<li class="checkBox headerSort">
								<p class="checkBox on">
									<input type="checkbox" id="selectAll" @click="selectAllCheckBox()" :checked="is_checkedAll"/>
									<label for="selectAll">전체선택</label>
								</p>
							</li>
							<li class="num">No</li>
							<li class="ymdHour headerSort" @click="timeSort"
								:class="{
									sortOff  : message_status_target != 'TIME',
									sortUp   : message_status_target == 'TIME' && message_status_direction == 'ASC', 
									sortDown : message_status_target == 'TIME' && message_status_direction == 'DESC', 
								}"><span>시간</span></li>
							<li class="name headerSort" @click="userNameSort"
								:class="{
									sortOff  : message_status_target != 'USER_NAME',
									sortUp   : message_status_target == 'USER_NAME' && message_status_direction == 'ASC', 
									sortDown : message_status_target == 'USER_NAME' && message_status_direction == 'DESC', 
								}"><span>이름</span></li>
							<li class="from headerSort" @click="groupNameSort"
								:class="{
									sortOff  : message_status_target != 'GROUP_TEAM',
									sortUp   : message_status_target == 'GROUP_TEAM' && message_status_direction == 'ASC', 
									sortDown : message_status_target == 'GROUP_TEAM' && message_status_direction == 'DESC', 
								}"><span>
									{{ isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? '아파트&amp;동' : '그룹&amp;팀' }}
									</span>
							</li>
							<li class="msgCont headerSort" @click="contentSort"
								:class="{
									sortOff  : message_status_target != 'CONTENT',
									sortUp   : message_status_target == 'CONTENT' && message_status_direction == 'ASC', 
									sortDown : message_status_target == 'CONTENT' && message_status_direction == 'DESC', 
								}"><span>내용</span></li>
							<li class="scs headerSort"><a class="sort">현황</a>
								<div class="dropdown">
									<ul class="">
										<li>
											<span class="scs"><input type="checkbox" id="scsCheckBox" />
											<label for="scsCheckBox"><span>성공</span></label></span>
										</li>
										<li>
											<span class="fail"><input type="checkbox" id="failCheckBox" />
											<label for="failCheckBox"><span>실패</span></label></span>
										</li>
									</ul>
								</div>
							</li>
						</ul>
						<div id="message_status_scroll">
							<ul class="subUl" id="messageStatusUserList">
								<li v-bind:key="index" v-for="(lo_data, index) in list" :value="index">
									<p class="checkBox">
										<input type="checkbox" :id="`check_${index}`" :checked="lo_data.checked" @click="rowSelectCheckBox(index)"/>
										<label :for="`check_${index}`">전체선택</label>
									</p>
									<p class="num">{{index+1}}</p>
									<p class="ymdHour">
										<span class="ymd double">{{lo_data.message_start_date}}<span class="time">{{ amPmStringToLocaleAmPmString(lo_data.message_start_time) }}</span></span>
									</p>
									<p class="name">{{lo_data.user_name}}</p>
									<p class="from">{{lo_data.group_team_name}}</p>
									<p class="msgCont">{{lo_data.noti_data.body}}</p>
									<p class="scsFail">
										<span class="scsChecked" :class="{on : messageStatusResult(lo_data)}">
											<span class="result">전송성공</span>
											<span class="btn" @click="showMessageSendStatus(lo_data)">보낸메세지</span>
										</span>
										<span class="failChecked" :class="{on : messageStatusResult(lo_data) == false}">
											<span class="result">전송실패</span>
											<span class="btn" @click.prevent="resend(lo_data)">재전송</span>
										</span>
									</p>					
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>		

    <input type="button" value="재전송" class="btn_resend bt_mms" id="bt_reSendToMember" @click="resendEvent()"/>	
    <input type="button" value="맨 위로 가기" class="scrollUp on" />	
</div>
		
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';
import moment from 'moment';
import { namespace } from 'vuex-class';
const ModalInfo  = namespace('ModalInfo');
const GroupInfo  = namespace('GroupInfo');
const dateFormat = require('dateformat');
const EtcInfo    = namespace('EtcInfo');

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {
        ResizeObserver
    }
}) 
export default class MessageStatus extends Mixins(VueHoduCommon) {
	/**
     * @ModalInfo.Action
     */
	@ModalInfo.Action doSetMessageSendStatus ?: any;

	/**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
	@GroupInfo.State team_id !: number;

	/**
     * EtcInfo.Action
     */
    @EtcInfo.Action doMessageInfo ?: any;
	
    list          : any    = [];
    original_list : any    = [];
	start_date    : string = "";
	end_date      : string = "";
    is_checkedAll : boolean = false;
    
    message_status_target : string | null = null;
    message_status_direction : string = "ASC";

    async mounted() : Promise<void> {
		this.doMessageInfo({});

		const vue = this;

		// 검색 버튼 클릭시 searchDiv 띄우기
        $("#section_ce .schBtn").click(function(){
            $("#section_ce .searchDiv").addClass("on");
			vue.$nextTick(() => $('#group_member_search').focus());
        });

        // 검색 닫기 버튼 클릭시 searchDiv 없애기
        $("#section_ce .searchDiv .closeBtn").click(function(){
            $("#section_ce .searchDiv").removeClass("on");
            $("#group_member_search").val("");
            $("#group_member_search").trigger('keyup');
		});

		if ( this.start_date == "" && this.end_date == "" ) {
            this.start_date = dateFormat(new Date(), 'yyyy-mm-dd');
			this.end_date   = this.getDayTerm(moment(this.start_date).format(), 7, "yyyy-mm-dd");
		}

		// date_picker
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                inst.input.val(`${moment(dateText).format('YYYY.MM.DD')} ${vue.getYoil(dateText)}요일`);

				if ( inst.id == "msg_status_fr_ymd" ) {
					vue.start_date = dateText;
				}
				else if ( inst.id == "msg_status_to_ymd" ) { 
					vue.end_date = dateText;
				}

				if ( vue.start_date >= vue.end_date ) {
					vue.hodu_show_dialog("alert", "종료일자는 시작일자 보다 작거나 같을 수 없습니다", ['확인'], [
						() => {
							if ( inst.id == "msg_status_fr_ymd" ) vue.start_date = inst.lastVal.toString().substring(0,10);
							if ( inst.id == "msg_status_to_ymd" ) vue.end_date = inst.lastVal.toString().substring(0,10);
						},
					]);
					
					return;
				}
				else {
					vue.getMessageStatus();
				}
            },
		};
		
		// @ts-ignore
		$('#msg_status_fr_ymd').datepicker(option);
		
		// @ts-ignore
		$('#msg_status_to_ymd').datepicker(option);

        // 필터검색
        $("#group_member_search").keyup(function(event) {
            let value = $("#group_member_search").val();
            
            if ( value == "" ) {  
                $("#messageStatusUserList li").show();
            }
            else {
                $("#messageStatusUserList li").hide();
                let temp = $("#messageStatusUserList li .name:contains('"+value+"')");
                $(temp).parent().show();
            }
        });        		

		this.getMessageStatus();
		this.setScroll();
	}

	/**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();
		const settingBgOuterHeight : number | undefined = $('.settingBg').outerHeight();
		const sortHeaderOuterHeight : number | undefined = $('.msgHeader.sortHeader').outerHeight();

        // @ts-ignore
        $('#message_status_scroll').mCustomScrollbar({
            axis : 'y',
			scrollbarPosition : 'outside',
			mouseWheelPixels : 100,
			scrollInertia : 60,
			autoDraggerLength : false,
			setHeight : window.innerHeight - ( titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight )
										   - ( settingBgOuterHeight == null ? 0 : settingBgOuterHeight )
										   - ( sortHeaderOuterHeight == null ? 0 : sortHeaderOuterHeight ),	
        });
    }

	/**
	 * 리스트 조회
	 */
	async getMessageStatus() : Promise<void> {
		this.list = [];

		let scope : string = (this.team_id > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP);

		const start = moment(this.start_date).set('hour', 0)
										     .set('minute', 0)
										     .set('second', 0)
										     .set('millisecond', 0).utc().format();
													
		const end = moment(this.end_date).set('hour', 23)
										 .set('minute', 59)
										 .set('second', 59)
										 .set('millisecond', 999).utc().format();

		await this.hodu_api_call(`api/v1/message_template/getMessageStatus/${scope}/${this.group_id}/${this.team_id}?start_date=${start}&end_date=${end}`, API_METHOD.GET, null, false)
            .then(async(response) => {
				this.list = response.data.data.list;

				let list_length = this.list.length;

				for ( let i = 0; i < list_length; i++ ) {
					this.list[i].checked = false;
					
					this.list[i].message_start_date = moment(this.list[i].audit_created).format('YYYY.MM.DD');
					this.list[i].message_start_time = moment(this.list[i].audit_created).format('hh:mm A');
                }

                this.original_list = JSON.parse(JSON.stringify(this.list));
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
			});
			
		this.is_checkedAll = false;
	}
	
	/**
	 * 메세지 전송 상태 모달 열기
	 */
	showMessageSendStatus(po_data) : void {
		this.doMessageInfo(po_data);
		window["messageSendStatusPopClose"] = this.messageSendStatusPopClose;
		this.doSetMessageSendStatus(true);
	}

	/**
	 * 메세지 상세보기 닫기
	 */
	messageSendStatusPopClose() : void { 
		this.doMessageInfo({});
		this.doSetMessageSendStatus(false);
	}

	/**
	 * 메세지 전송여부 결과 리턴
	 */
	messageStatusResult(po_data) : boolean {
		let data_length = po_data.push_result.length;

		if ( po_data.data_length < 1 ) {
			return false;
		}

		let result_value = false;

		for ( let i = 0; i < data_length; i++ ) {
			let lo_data = po_data.push_result[i];

			if ( lo_data.status == "FEED_OK" ) {
				result_value = true;
				break;
			}
		}

		return result_value;
	}	
	
	/**
	 * 날짜 변환 후 보여주기
	 */
	displayDate(po_date, ps_format) : string {
		return dateFormat(moment(po_date).format(), ps_format);
	}

    /**
     * 요일 구하기
     */
    getYoil(po_date) : string {
        var week = ['일', '월', '화', '수', '목', '금', '토'];
        var dayOfWeek = week[new Date(moment(po_date).format()).getDay()];
        return dayOfWeek;
	}

    /**
     * Day Term 구하기
     */
    getDayTerm(date, term, format) : string {        
        return dateFormat(moment(date).add(term,'day'), format);
	}	

	/**
     * 전체선택, 전체해제
     */
    async selectAllCheckBox() : Promise<void> {
        this.is_checkedAll = !this.is_checkedAll;
        
        for ( let i = 0; i < this.list.length; i++ ) {
            this.list[i].checked = this.is_checkedAll;
        }
	}
	
    /**
     * 개별 체크박스 선택,해제
     */
    rowSelectCheckBox(pi_index) : void {
		this.list[pi_index].checked = !this.list[pi_index].checked;
		this.is_checkedAll = this.list.filter(item => item.checked == true).length == this.list.length;
	}	
    
    /**
     * 시간 기준 정렬
     */
    timeSort() : void {
        // 이미 TIME Sort 중이라면
        if( this.message_status_target == "TIME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.message_status_direction ) {
                case "ASC":
                    this.message_status_direction = "DESC";
                    break;
            
                case "DESC":
                    this.message_status_target = null;
                    break; 
            }
        }
        
        // TIME Sort가 아니였다면
        else {
            this.message_status_target = "TIME";
            this.message_status_direction = "ASC";
        }

        if( this.message_status_target == null ) { this.setListToOriginalList(); }
        else { this.messageListSort(); }
    }

    /**
     * 수신자명 기준 정렬
     */
    userNameSort() : void {
        // 이미 USER_NAME Sort 중이라면
        if( this.message_status_target == "USER_NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.message_status_direction ) {
                case "ASC":
                    this.message_status_direction = "DESC";
                    break;
            
                case "DESC":
                    this.message_status_target = null;
                    break; 
            }
        }
        
        // USER_NAME Sort가 아니였다면
        else {
            this.message_status_target = "USER_NAME";
            this.message_status_direction = "ASC";
        }

        if( this.message_status_target == null ) { this.setListToOriginalList(); }
        else { this.messageListSort(); }
    }

    /**
     * 그룹&팀 이름 기준 정렬
     */
    groupNameSort() : void {
        // 이미 GROUP_TEAM Sort 중이라면
        if( this.message_status_target == "GROUP_TEAM" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.message_status_direction ) {
                case "ASC":
                    this.message_status_direction = "DESC";
                    break;
            
                case "DESC":
                    this.message_status_target = null;
                    break; 
            }
        }
        
        // GROUP_TEAM Sort가 아니였다면
        else {
            this.message_status_target = "GROUP_TEAM";
            this.message_status_direction = "ASC";
        }

        if( this.message_status_target == null ) { this.setListToOriginalList(); }
        else { this.messageListSort(); }
    }

    /**
     * 내용 기준 정렬
     */
    contentSort() : void {
        // 이미 CONTENT Sort 중이라면
        if( this.message_status_target == "CONTENT" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.message_status_direction ) {
                case "ASC":
                    this.message_status_direction = "DESC";
                    break;
            
                case "DESC":
                    this.message_status_target = null;
                    break; 
            }
        }
        
        // CONTENT Sort가 아니였다면
        else {
            this.message_status_target = "CONTENT";
            this.message_status_direction = "ASC";
        }

        if( this.message_status_target == null ) { this.setListToOriginalList(); }
        else { this.messageListSort(); }
    }

    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.list = JSON.parse(JSON.stringify(this.original_list));
    }

    /**
     * 메세지 발송현황 정렬
     */
    messageListSort() : void {
        this.list.sort((o1 : any, o2 : any) : number => {
            // 발송시간 오름차순
            if( this.message_status_target == "TIME" && this.message_status_direction == "ASC" ) {
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime()  > new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return  1; } 
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime()  < new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return -1; } 
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime() == new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return  0; }
            }

            // 발송시간 내림차순
            if( this.message_status_target == "TIME" && this.message_status_direction == "DESC" ) {
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime()  > new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return -1; } 
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime()  < new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return  1; } 
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime() == new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return  0; }
            }

            // 수신자 이름 오름차순
            if( this.message_status_target == "USER_NAME" && this.message_status_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 수신자 이름 내림차순
            if( this.message_status_target == "USER_NAME" && this.message_status_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 그룹&팀 이름 오름차순
            if( this.message_status_target == "GROUP_TEAM" && this.message_status_direction == "ASC" ) {
                if( o1.group_team_name  > o2.group_team_name ) { return  1; } 
                if( o1.group_team_name  < o2.group_team_name ) { return -1; } 
                if( o1.group_team_name == o2.group_team_name ) { return  0; }
            }

            // 그룹&팀 이름 내림차순
            if( this.message_status_target == "GROUP_TEAM" && this.message_status_direction == "DESC" ) {
                if( o1.group_team_name  > o2.group_team_name ) { return -1; } 
                if( o1.group_team_name  < o2.group_team_name ) { return  1; } 
                if( o1.group_team_name == o2.group_team_name ) { return  0; }
            }

            // 내용 오름차순
            if( this.message_status_target == "CONTENT" && this.message_status_direction == "ASC" ) {
                if( o1.noti_data.body  > o2.noti_data.body ) { return  1; } 
                if( o1.noti_data.body  < o2.noti_data.body ) { return -1; } 
                if( o1.noti_data.body == o2.noti_data.body ) { return  0; }
            }

            // 내용 내림차순
            if( this.message_status_target == "CONTENT" && this.message_status_direction == "DESC" ) {
                if( o1.noti_data.body  > o2.noti_data.body ) { return -1; } 
                if( o1.noti_data.body  < o2.noti_data.body ) { return  1; } 
                if( o1.noti_data.body == o2.noti_data.body ) { return  0; }
            }

            return 0;
        });
	}
	
	/**
	 * 재전송
	 */
	async resend(data) : Promise<void> {
		this.resendApi([data]);	
	}
	
	/**
	 * 재전송
	 */
	async resendEvent() : Promise<void> {
		let re_send_users : any[]  = [];
		let for_count     : number = this.list.length;

		for ( let i = 0; i < for_count; i++ ) {
			if ( this.list[i].checked ) {
				re_send_users.push(this.list[i]);
			}
		}

		if ( re_send_users.length == 0 ) {
			this.hodu_show_dialog("alert", "메세지 재 발송 할 사용자를 선택해 주세요", ['확인']);
            return;
		}

		this.resendApi(re_send_users);
	}

	/**
	 * 재발송 API
	 */
	async resendApi(users) : Promise<void> {

		console.log(users);

		let send_user_groups : any[] = [];

		// 같은 noti_uid는 다시 묶어서 발송
		for( const user of users ) {
			
			const target = send_user_groups.filter(send_user_group => send_user_group.noti_uid == user.noti_uid); 

			if( target.length < 1 ) {
				send_user_groups.push({
					"noti_uid" : user.noti_uid,
					"group_id" : user.group_id,
					"team_id" : user.team_id,
					"group_team_name" : user.group_team_name,
					"noti_data" : user.noti_data,
					"users" : [user]
				});
				continue;
			}

			target[0].users.push(user);
		}

		console.log(send_user_groups);

		const promise_array : Promise<any>[] = [];
		for( const send_user_group of send_user_groups ) {

			let req_data = {
				users: Array.from(send_user_group.users as any[], x => Number(x.user_id)),
				group_id: send_user_group.group_id,
				team_id: send_user_group.team_id,
				group_team_name: send_user_group.group_team_name,
				message_content: send_user_group.noti_data.body
			}

			const scope = req_data.team_id > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP;
        	const scope_id = scope == OWNER_TYPE.GROUP ? req_data.group_id : req_data.team_id;
			
			const promise = this.hodu_api_call(`api/v1/message_template/${scope}/${scope_id}/send`, API_METHOD.POST, req_data);
			promise_array.push(promise);
		}

		await Promise.all(promise_array);

		this.hodu_show_dialog('success', "재전송 완료", ['확인']);
		this.getMessageStatus();

		// console.log(send_users); 

        // let for_count  : number   = this.members.length;

        // for ( let i = 0; i < for_count; i++ ) {
        //     if ( this.members[i].checked ) {
        //         send_users.push(this.members[i].user_id);
        //         /*
        //         send_users.push({
        //             user_id: this.members[i].user_id
        //             //user_name: this.members[i].user_info.user_name,
        //             //user_phone_number: this.members[i].user_info.user_phone_number,
        //             //user_email: this.members[i].user_info.user_email
        //         });
        //         */
        //     }
        // }

        // if ( send_users.length == 0 ) {
        //     this.hodu_show_dialog("alert", "메세지 발송 할 그룹원을 선택해주세요", ['확인']);
        //     return;
        // }

        // let req_data = {
        //     users: send_users,
        //     group_id: this.selected_group_id,
        //     team_id: this.selected_team_id,
        //     group_team_name: this.selected_group_team_name,
        //     message_content: this.message_content
        // }
        
        // const scope = this.scope;
        // const scope_id = this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id;

        // await this.hodu_api_call(`api/v1/message_template/${scope}/${scope_id}/send`, API_METHOD.POST, req_data)
        //     .then(async(response) => {
        //         this.hodu_show_dialog("success", "성공적으로 메세지 발송하였습니다", ['확인']);
        //     })
        //     .catch(async(e) => {
        //         this.hodu_error_process(e, true, false);
		// 	});

	}
	
	/**
     * 리사이즈 감지
     */
    handleResize() : void {
		// @ts-ignore
		$('#message_status_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
	}

}
</script>

<style scoped>
	/* 2018_0724 출석현황 */

	.title_box { height: 60px; }
	div#grp_Step { float:left; }
	.attndPage {height: 100%;padding-top: 110px;box-sizing: border-box; margin-bottom: 100px;}
	.attndPage .schedule_box {margin-top: 30px;}

	.content {margin-top: 0px;margin-bottom: 0;padding: 0 20px;box-sizing: border-box;width: 100%;}
	.content .noResultsBg {display: none;width: 100%;color: #949494;box-sizing: border-box;border-radius: 5px;border: 1px solid #f1f3f5;padding: 60px 20px;font-size: 14px;font-weight: bold;background: #f1f3f5;text-align: center;}
	div#noReusltList {margin-top: -30px;}
	div#noReusltList {background: none;border: none;padding: 0 30px;}
	div#noReusltList .noResults {background: #f1f3f5;line-height: 100px;border-radius: 5px;}
	.content > ul { overflow: hidden; }
	.content > ul > li { margin: 10px 0 30px; }
	.content .ydd { float: none; display: block; cursor:default; width: 100%; margin: 15px 0 15px; font-size: 16px; font-weight: bold; }
	.content .attndHeader { width: 100%; height: 55px; background: #fafafa; line-height: 55px; font-size: 12px; border: 1px solid #eaeaea; box-sizing: border-box; }
	.content .attndHeader .attndYes, .content .attndHeader .attndNo { box-sizing: border-box;  }
	.content .attndHeader p { padding: 0; float: left; line-height: 54px; cursor: default; border-left: 1px solid #e4e4e4; box-sizing: border-box; padding-left: 15px; text-align: left; }
	.content .attndHeader p:first-child { border-left: 0 none; padding-left: 0; text-align: center }
	.content #message_status_scroll { margin-top : 60px; }
	.content .subUl p { float: left; }
	.content .subUl li {border-bottom: 1px solid #f1f3f5;width: 100%;height: 60px;line-height: 60px;font-size: 12px;}
	.content .subUl li:hover { background: #f6fcff; }
	.content .subUl li:first-child { /*margin-top: 5px*/ }
	.content .attndYes, .content .attndNo { text-align: center; width: 10%; height: 54px; padding: 8px 5px; box-sizing: border-box; }
	.content .subUl .attndYes, .content .subUl .attndNo { padding-right: 5px !important; padding-left: 5px !important; height: 55px;}
	.content .subUl .attndYes span, .content .subUl .attndNo span { float: left; width: 100%; height: 100%; display: inline-block; background: transparent; font-size: 0; border-radius: 5px; }
	.content .subUl .attndYes.on span { background: #e8f8fd url('../assets/images/contents/ic_attnd_yes.png') no-repeat center center;  }
	.content .subUl .attndNo.on span { background: #ffeded url('../assets/images/contents/ic_attnd_no.png') no-repeat center center; border-radius: 5px;}
	.content .subUl p {cursor: default;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;padding-right: 15px;font-weight: bold;box-sizing: border-box;}
	.content .subUl .num { padding-right: 0; padding-left: 0; }
	.content .subUl .name { font-weight: bold; }
	.content .subUl .name img {float: left;display: inline-block;margin: 13px 16px 0 0;width: 30px;height: 30px;border-radius: 50%;background: #e9eff4 url(../assets/images/contents/im_photoB.gif) no-repeat;background-size: cover;border:  1px solid #f1f3f5;}
	.content .num { width: 7%; text-align: center; }
	.content .name { width: 19%; }
	.content .mobile { width: 20%; }
	.content .email { width: 34% }

	#allAttndMemberUbody.content .subUl li:hover p {font-weight: bold;}

	#container .email.headerSort { background-position: 94% center }
	#container .attndYes.headerSort, .attndNo.headerSort { background-position: 85% center }
	#allMemberListScroll .tableDefault.tableStyle02 table tr th { height: 55px; line-height: 55px; padding: 0; }
	#allMemberListScroll .tableDefault.tableStyle02 table tr th p { padding: 0 10px !important;; line-height: 55px; box-sizing: border-box; }


	.attndPage .transit { transition: 0.3s; }
	.scrollUp { opacity: 1; z-index: 100; position: absolute; bottom: 40px; right: 40px; transition: 0.2s; font-size: 0; border-radius: 50%; display: block; width: 60px; height: 60px; background: #36bdff url('../assets/images/contents/scrollUp.png') no-repeat center center; box-shadow: 0 5px 15px rgba(92, 66, 255, 0.5); }
	.scrollUp.on { bottom: -100px !important; }
	.scrollUp:hover { background-color: #f6fcff; transition: 0.1s; box-shadow: 0 10px 25px rgba(92, 66, 255, 0.5); bottom: 45px;}


	@media all and (max-width: 1279px) {
		.grp.settingBg {/* margin-left: -320px !important; */}
	}

	a#attndMemberListExcelDownLoad span {opacity:0;display: block;margin-top: -35px;color: #fff;}
	a#attndMemberListExcelDownLoad {text-indent:10000px; transition: 0s;background: #00ab5d url(../assets/images/contents/ic_fileDownload.png) no-repeat center center;background-size: 42px;box-shadow: 0 5px 15px rgb(0, 171, 93,0.4);} 
	#attndMemberListExcelDownLoad:hover {transition: 0s;background: #00904e;background-size: 42px;font-size: 12px;box-shadow: 0 10px 25px rgb(0, 171, 93,0.5);font-weight: bold;color: #fff;text-align: center;line-height: 50px;text-indent:0;}
	#attndMemberListExcelDownLoad:hover span {opacity:1;}
	.mCSB_container { margin-right: 0 !important; }

	#container #section_ce .msgOptionPage .schedule_box {max-width: 100%;padding-left: 0;}
	/* 문자발송현황 */
	#container #section_ce .msgPage .schedule_box {max-width: 100%;}

	.sendMmsPage .toTheLeft:hover {opacity: 1;}
	#container .sendMmsPage .content .headerSort.grp {box-sizing: border-box;padding: 0;}
	.msgPage {height: 100%;padding-top: 110px; box-sizing: border-box;}
	.msgPage .schedule_box {margin-top: 0;margin-bottom:0;margin-right: 0;}
	.msgPage.sendMmsPage .schedule_box {margin-right: 0;padding-right: 0 !important;}
	.msgPage .content .msgHeader {width: 100%;height: 60px;top: 129px;line-height: 60px;padding-left: 261px;padding-right: 60px;z-index: 1;transition: 0.1s;position: fixed;left: 0;font-size: 12px;/* border: 1px solid #eaeaea; */box-sizing: border-box;}
	.left_area_close .msgPage .content .msgHeader {padding-left: 61px;}
	.right_area_close .content .msgHeader {padding-right: 0 !important;}
	.msgPage .content .msgHeader .attndYes, .msgPage .content .msgHeader .attndNo { box-sizing: border-box;  }
	.msgPage .content .msgHeader p {height: 100%;padding: 0;float: left;line-height: 60px;font-weight: bold;cursor: default;font-size: 14px !important;box-sizing: border-box;padding-left: 15px;text-align: left;background-color: #f2f5f7;height: 60px;border-bottom: 1px solid #e7e9ea;}
	.msgPage .content .msgHeader p.num {  padding-left: 0; text-align: center }
	.msgPage .content .msgHeader .checkBox { width: 7%; padding: 0; border-left: 0 none;}
	.msgPage .num {width: 7%; text-indent: -20px; font-weight: bold;}
	.msgPage .ymdHour {width: 11%;}
	.msgPage .name { width: 15% }
	.msgPage .from { width: 15% }
	.msgPage .msgCont { width: 26% }
	.msgPage .content .msgHeader .scsFail { position: relative; text-align: center; width: 22%; vertical-align: middle; }
	.msgPage .content .msgHeader .scsFail .scs {position: relative;margin-right: 10px;width: 60px;height: 100%;cursor: pointer;display: inline-block;}
	.msgPage .content .msgHeader .scsFail .fail { position: relative; width: 50px; height: 100%; cursor: pointer; display: inline-block; }
	.msgPage .content .msgHeader .scsFail label span {margin-top: 9px;margin-left: 31px;width: 40px;display: inline-block;font-size: 12px;}
	.msgPage .content .msgHeader .scsFail input[type='checkbox']:not(old) + label { margin-left: 0; left: 0;  }
	#wrap .msgPage .content .msgHeader .scsFail .scs input[type='checkbox']:not(old):checked + label {background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat 0px 0px !important;border: 2px solid #477fff !important;background-size: 21px !important;}
	.msgPage .content .msgHeader .scsFail .fail input[type='checkbox']:not(old):checked + label {background: #ff3f3f url('../assets/images/contents/checked_fff.png') no-repeat 0 0px  !important;border: 2px solid #ff3f3f !important;background-size: 21px !important;}
	.msgPage .content .msgHeader .checkBox .checkBox {display:inline-block; float:none;  }
	.msgPage .content .msgHeader .scsFail { padding-left: 0; }
	.msgPage .content .msgHeader .checkBox { position: relative; cursor: pointer; text-align: center; }
	.msgPage .content .msgHeader .checkBox span { margin-top: 17px; display: inline-block; }
	#selectCheckBoxAll input[type='checkbox']:not(old) + label {
		background-color: #c1cfd8 !important;
		border: 2px solid #c1cfd8;
	}
	#selectCheckBoxAll input[type='checkbox']:not(old):checked + label {
		background-color: #36bdff !important;
		border: 2px solid #36bdff;
	}
	.msgPage .content .checkBox {position: relative; border-left: 1px solid #fff; cursor: pointer; width: 100%; height: 100%; text-align: center; }
	.msgPage .content .checkBox span { margin-top: 10px; display: inline-block; width: 16px; height: 16px; border-radius: 5px; border: 2px solid #d9d9d9; background: #fff url('../assets/images/contents/checked_gray.png') no-repeat -2px -2px; background-size: 21px; }
	.msgPage .content .checkBox.on span { background: #36bdff url('../assets/images/contents/checked_fff.png') no-repeat -2px -2px; border: 2px solid #36bdff; background-size: 21px;}
	.msgPage .content .subUl li.on, .msgPage .content .subUl li:hover {background: #f6f9fb;transition: 0.2s;border-bottom: 1px solid #e7e9ea;}

	.msgPage.pagination#sentMsgPaging { margin-left:0; margin-top: 0; }



	/* 841부터 874까지 왼쪽 체크박스 변경시 */

	.msgPage input[type='checkbox']:not(old) {border-left: 1px solid #fff; cursor: pointer; width: 100%; height: 100%; text-align: center; }
	.msgPage input[type='checkbox']:not(old) + label { display: inline-block; padding: 0; width: 16px; height: 16px; border-radius: 5px; border: 2px solid #d9d9d9; background: #fff url('../assets/images/contents/checked_gray.png') no-repeat -2px -2px; background-size: 21px; font-size: 0; }
	.msgPage input[type='checkbox']:not(old) + label {position: absolute;top: 0;left: 50%;margin-left: -10px;margin-top: 17px;}
	.msgPage .subUl input[type='checkbox']:not(old) + label { margin-top: 11px; }
	.msgPage input[type='checkbox']:not(old):checked + label { background: #36bdff url('../assets/images/contents/checked_fff.png') no-repeat -2px -2px; border: 2px solid #36bdff; background-size: 21px; }

	.msgPage  input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 20px;height: 20px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../assets/images/contents/checked_fff.png) no-repeat 0px 0px;background-size: 21px;font-size: 0;margin-right: 8px;}
	.msgPage input[type='checkbox']:not(old):checked + label {background: #36bdff url('../assets/images/contents/checked_fff.png') no-repeat 0px 0px;background-size: 21px;border: 2px solid #36bdff;}
	
	.hc.pr_dc0 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #FF6363; border-color: #FF6363; }
	.hc.pr_dc1 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #FFA70E; border-color: #FFA70E; }
	.hc.pr_dc2 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #FFC72F; border-color: #FFC72F; }
	.hc.pr_dc3 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #FF198B; border-color: #FF198B; }
	.hc.pr_dc4 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #00B2C7; border-color: #00B2C7; }
	.hc.pr_dc5 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #13D08B; border-color: #13D08B; }
	.hc.pr_dc6 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #4DBAFF; border-color: #4DBAFF; }
	.hc.pr_dc7 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #477FFF; border-color: #477FFF; }
	.hc.pr_dc8 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #6854FF; border-color: #6854FF; }
	.hc.pr_dc9 .msgPage input[type='checkbox']:not(old):checked + label { background-color : #35405A; border-color: #35405A; }

	/* leftarea he */

	#container #section_ce .msgPage{ overflow: hidden; padding-top:0; } 
	.msgPage .subUl .checkBox { height: 100%; padding: 0; width: 7%; }
	.msgPage .subUl .ymdHour { line-height: 0; }
	.msgPage .subUl .double {display: block;line-height: 26px;height: 45px;width: 100%;text-overflow: ellipsis;white-space: nowrap;overflow:hidden;}
	.msgPage .subUl .related, .msgPage .subUl .time {color: #949494;height: 17px;display: block;line-height: 0;margin-top: 4px;}
	.msgPage .subUl .scsFail {width: 19%;text-align:left;}
	.msgPage .subUl .scsFail .scsChecked, .scsFail .failChecked {display: none;/* background: #e8efff; */border-radius: 23px;height: 100%;width: 100%;text-align: left;box-sizing: border-box;max-width: 130px;}
	.msgPage .subUl .scsChecked span.result {display: inline-block;/* background: url('../assets/images/contents/checked_blue.png') no-repeat left 8px; */display: inline-block;/* padding-left: 30px; *//* padding-right: 6px; */box-sizing: border-box;color: #477fff;}
	/* .msgPage .subUl .failChecked { background: #ffebeb } */
	.msgPage .subUl .failChecked span.result {display: inline-block;color: #ff3f3f;display: inline-block;box-sizing: border-box;}
	.msgPage .subUl li:hover .failChecked .result, .msgPage .subUl li:hover .scsChecked .result { display: none }
	.msgPage .subUl li .failChecked .btn {margin-top: 5px; background: #ff6060; height: 35px; line-height: 35px; box-sizing: border-box; width: 100px; font-weight: bold; text-align:center; }
	.msgPage .subUl li .scsChecked .btn {margin-top: 5px;background: #477fff; height: 35px;line-height: 35px; box-sizing: border-box; width: 100px; font-weight: bold; text-align:center; }
	.msgPage .subUl li .failChecked .btn, .msgPage .subUl li .scsChecked .btn { vertical-align: top; display: none; padding: 0; }
	.msgPage .subUl li:hover .failChecked.on .btn, .msgPage .subUl li:hover .scsChecked.on .btn { display: inline-block; background-color: #ff3f3f; border-radius: 20px; cursor: pointer }
	.msgPage .subUl li:hover .scsChecked.on .btn {background: #477fff;}
	.msgPage .subUl li:hover .scsChecked.on .btn:hover {background: #477fff;}
	.msgPage .subUl li:hover .failChecked, .msgPage .subUl li:hover .scsChecked { background: transparent; }
	.msgPage .subUl li:hover .failChecked span { }
	.msgPage .subUl li:hover .failChecked span { color: #fff; padding: 0; }
	.msgPage .subUl .scsChecked.on, .msgPage .subUl .failChecked.on { display: inline-block; }
	.msgPage .subUl li {padding: 7px 0;box-sizing: border-box;}
	.msgPage .subUl li p {line-height: 42px; height: 40px;}
	.msgPage .subUl li:hover p, .msgPage .subUl li:hover p span {font-weight: bold; cursor:default;}


	.setting2Bg {width: 100%;position: relative;height: 45px;margin-bottom: 10px;}
	.selectBg {min-width: 60px;width: auto;float: left;position: relative;height: 100%;box-sizing:border-box;cursor: pointer;border-radius: 5px;}
	.selectBg .selectTitle {display: block;height: 100%;font-size: 14px;width: auto;padding: 0 0 0 10px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
	.selectBg:hover h3.selectTitle .txt {color: #477fff;}
	.selectBg .selectTitle .txt {line-height: 45px;}
	.selectBg .selectTitle:after {content: '';display: inline-block;width: 20px;margin-left: 10px;height: 40px;border-radius: 50%;float: right;background: url(../assets/images/side_area/bt_open.png) no-repeat 0px 10px;}
	.sendMmsPage .settingBg .selectBg:hover h3 {color: #477fff;transition: 0.2s;}

	.selectBg .selectTitle .arrowDown {}
	.selectBg .selectTitle.on .arrowDown {background: url(../assets/images/side_area/bt_close.png) no-repeat 0px 8px;}
	.selectBg .selectTitle:hover .arrowDown {background-color: transparent;}
	.setting2Bg .btn_resend { margin-top: 8px; float: right; padding: 0 25px; background: #36bdff; box-sizing: border-box; height: 35px; line-height: 35px; font-weight: bold; border-radius: 20px; color: #fff;  } 
	.setting2Bg .btn_resend:hover { background: #f6fcff; transition: 0.2s;}
	.selectUlBg {z-index: 100;position: absolute;top: 46px;right: 0;width: 100%;height: auto;min-width: 340px;background:#fff;box-shadow: 0 5px 10px rgba(0,0,0,0.1);max-width: 420px;border-radius: 5px;}
	.selectUlBg .scroll {height: auto;max-height: 280px;}
	.selectUl li {display: block;line-height: 45px;position: relative;padding: 0 15px;}
	.selectUl {background: #fff;border-radius: 5px;/* box-shadow: 0 5px 10px rgba(0,0,0,0.1); */left: 0;width: 100%;top: 93px;border: 1px solid #f1f3f5;box-sizing: border-box;}
	.selectUl li {border-bottom: 1px solid #f1f3f5;}
	.selectUl li:last-child { border:0 none }
	.selectUl li:hover { background-color:#f1f3f5; font-weight:bold; }
	.selectUl li.on:hover { background: #fff; cursor:default; }
	.selectUl li.on { font-weight:bold; }
	.selectUl li.on:after {display: block;content: '';position: absolute;right: 13px;top: 19px;width: 7px;height: 7px;background: #477fff;border-radius: 50%;}


	.bt_mms { opacity: 1; z-index: 1000; position: absolute; bottom: 40px; right: 40px; transition: 0.2s; font-size: 0; border-radius: 50%; display: block; width: 60px; height: 60px; background: #00e29a url('../assets/images/contents/bt_mms.png') no-repeat center center; box-shadow: 0 5px 15px rgba(0, 226, 154, 0.4);}
	.bt_mms:hover { background-color: #00d08e; transition: 0.1s; box-shadow: 0 10px 25px rgba(0, 226, 154, 0.5);}

	.bt_mms.on { bottom: 40px; right: 130px !important; transition: 0.2s; font-size: 0; border-radius: 50%; display: block; width: 60px; height: 60px; background: #00e29a url('../assets/images/contents/bt_mms.png') no-repeat center center; box-shadow: 0 5px 15px rgba(0, 226, 154, 0.4);}
	.bt_mms.on:hover { background-color: #02da95; box-shadow: 0 10px 25px rgba(0, 226, 154, 0.5);}

	.btn_resend.bt_mms { background: #36bdff url('../assets/images/contents/bt_resend.png') no-repeat center center !important;box-shadow: 0 5px 15px rgba(60, 170, 255,0.4) !important; }
	.btn_resend.bt_mms:hover { background-color: #0b9aff; transition: 0.1s; box-shadow: 0 10px 25px rgba(60, 170, 255, 0.5);}

	.scrollUp {background: #36bdff url(../assets/images/contents/scrollUp.png) no-repeat center center !important;box-shadow: 0 5px 15px rgba(60, 170, 255, 0.5) !important; }
	.scrollUp:hover { background: #0b9aff url(../assets/images/contents/scrollUp.png) no-repeat center center !important; box-shadow: 0 10px 25px rgba(60, 170, 255, 0.5) !important; }

	#groupSelectBg .selectBg { padding-left:0; } 
	#groupSelectBg .selectBg {border: 1px solid #e7e9ea;border-radius: 40px;transition: 0.2s;padding: 0 15px;}
	#groupSelectBg .selectBg:hover {background: #f1f3f5;/* border: 1px solid #477fff; */}
	.setting2Bg {width: 100%;position: relative;height: 45px;margin-bottom: 10px;}
	.selectBg {min-width: 60px;width: auto;float: left;position: relative;height: 100%;box-sizing:border-box;cursor: pointer;border-radius: 5px;}
	.selectBg .selectTitle {display: block;height: 100%;font-size: 14px;width: auto;padding: 0 0 0 10px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
	.selectBg:hover h3.selectTitle .txt {color: #477fff;}
	.selectBg .selectTitle .txt {line-height: 45px;float:left;}
	.selectBg .selectTitle:after {content: '';display: inline-block;width: 20px;margin-left: 10px;height: 40px;border-radius: 50%;float: right;background: url(../assets/images/side_area/bt_open.png) no-repeat 0px 10px;}
	.sendMmsPage .settingBg .selectBg:hover h3 {color: #477fff;transition: 0.2s;}

	.settingBg > .posRel > .bg { position: static; }
	.attndPage, #instMemListScroll, #instructorListScroll, .mainConsultingPage .section_scroll, .msgPage { padding-top:0 !important; }
	#container #section_ce .msgPage .schedule_box { margin-top: 0; }
</style>