<template>
    <ul :class="{ mainUl : level == 2 }">
        <li :class="{ on : department.is_closed == true, checked : selected_department != null && selected_department.dept_id == department.dept_id }" 
            :key="department.dept_id" v-for="department in departments">
            
            <div class="content grp">
                <input type="button" id="hodu-dept-open-toggle" v-if="department.departments.length > 0" />
                <label for="hodu-dept-open-toggle" class="leftBtn" v-if="department.departments.length > 0" @click.prevent="openToggle(department)"/>
                <div class="noBtn" v-else/>
                <a href="#" class="team" @click.prevent="update(department)">
                    <p class="title">{{ department.dept_name }}
                        <span class="num">({{ department.departments.length }})</span>
                    </p>
                </a>
                <a href="#" class="addBtn" @click.prevent="add(department)" v-if="is_select == false && (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">추가</a>
                <a href="#" class="delBtn" @click.prevent="remove(department)" v-if="is_select == false && (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">삭제</a>
                <div class="chooseBtnDiv" v-else-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                    <input type="button" :id="`hodu-department-check-${department.dept_id}`" class="chosen" value="선택">
                    <label :for="`hodu-department-check-${department.dept_id}`" class="chooseTeam" @click="select(department)"
                        :class="{ on : selected_department != null && selected_department.dept_id == department.dept_id }">선택하기</label>
                </div>
                <span class="line"></span>
            </div>

            <div class="grp" v-if="department.departments.length > 0">
                <OrganizationRecursion2 
                    :level="level + 1"
                    :departments="department.departments"
                    :selected_department="selected_department"
                    :department_closed_map="department_closed_map"
                    :is_select="is_select"
                    @add="add"
                    @update="update"
                    @remove="remove"
                    @select="select" />
            </div>

        </li>

        <li class="on notTeam" v-if="level == 2">
            <div class="content grp">
                <div class="noBtn"></div>
                <a href="#" class="team" @click.prevent="() => {}">
                    <p class="title">미배정<span class="num"></span></p>
                </a>
                <div class="chooseBtnDiv">
                    <input type="button" :id="`hodu-department-check-0`" class="chosen" value="선택">
                    <label :for="`hodu-department-check-0`" class="chooseTeam" @click="select({ 'dept_id' : 0, 'dept_name' : '미배정', 'dept_id_array' : [0], 'dept_seq' : 0 })"
                        :class="{ on : selected_department != null && selected_department.dept_id == 0 }">선택하기</label>
                </div>
                <!-- <div class="chooseBtnDiv">
                    <input type="button" id="hodu-department-check-88" value="선택" class="chosen"><label for="hodu-department-check-88" class="chooseTeam">선택하기</label>
                </div> -->
                <span class="line"></span>
            </div>
        </li>

        <li class="addTeam" v-if="level == 2 && is_select == false && (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
            <div>
                <a href="#" class="team" @click.prevent="add({ dept_id : 0, dept_id_array : [0]})"><p>+ 부서 추가</p></a>
                <span class="line"></span>
            </div>
        </li>
    </ul>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

@Component({
    name : "OrganizationRecursion2",
    components: {
        
    },
})
export default class OrganizationRecursion2 extends Mixins(VueHoduCommon) {

    @Prop() level !: number;
    @Prop() departments !: any[];
    @Prop() selected_department !: any;
    @Prop() department_closed_map !: Map<number, boolean>;
    @Prop() is_select !: boolean
    
    mounted() : void {
        
    }

    /**
     * 열기·닫기
     */
    openToggle(department : any) : void {
        const copy_department = JSON.parse(JSON.stringify(department));
        copy_department.is_closed = !copy_department.is_closed;
        this.department_closed_map?.set(copy_department.dept_id, copy_department.is_closed);
        this.departments.splice(this.departments.indexOf(department), 1, copy_department);
        this.$forceUpdate();
    }
    
    /**
     * 부서 추가
     */
    add(target_department : any) : void {
        this.$emit('add', target_department);
    }

    /**
     * 부서 수정
     */
    update(target_department : any) : void {
        if( this.get_group_role(this.scope_group_id) != 'GROUP_MANAGER' && this.get_group_role(this.scope_group_id) != 'ADMIN' ) {
            this.openToggle(target_department)
            return;
        }
        this.$emit('update', target_department);
    }

    /**
     * 부서 삭제
     */
    remove(target_department : any) : void {
        this.$emit('remove', target_department);
    }

    /**
     * 부서 선택
     */
    select(target_department : any) : void {
        this.$emit('select', target_department);
    }

}
</script>

<style scoped>
    li.notTeam a.team { cursor : default; }
</style>