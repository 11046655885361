<template>
    <div id="chatting_room_info_modal" class="modal">
        <div class="titlebox">
            <h1>채팅방 수정</h1>
        </div>

        <div class="content">
            <div class="optionDiv">
                <ul class="dpUl">
                    <li class="on">
                        <div class="checkDiv">
                            <input type="radio" id="opRa0" name="optionRadio" checked />
                            <label for="opRa0"></label>
                        </div>
                        <h2>테마 디스플레이 옵션</h2>
                        <p class="ds">설정한 채팅방 사진과 제목으로 디스플레이</p>
                        <p class="preview">
                            <span class="img">대표사진</span>
                            <span class="txt">패러글라이딩 동호회</span>
                        </p>
                    </li>
                    <li>
                        <div class="checkDiv">
                            <input type="radio" id="opRa1" name="optionRadio" />
                            <label for="opRa1"></label>
                        </div>
                        <h2>기본 디스플레이 옵션</h2>
                        <p class="ds">대화자 사진과 이름으로 디스플레이</p>
                        <p class="preview">
                            <span class="img">대화자 사진</span>
                            <span class="txt">홍길동 +3</span>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="detDiv">
                <div class="line"></div>
                <ul>
                    <!-- 이미지 첨부 시 added 추가 -->
                    <li class="picLi added">
                        <dl>
                            <dt>채팅방 대표사진</dt>
                            <!-- dd에 이미지 넣기 -->
                            <dd>
                                <input type="file" id="addCtImg"/>
                                
                                <label for="addCtImg">+ 대표 사진</label>
                                <!-- 이미지 삽입 시 보이기 -->
                                <a class="delBtn" title="삭제">삭제</a>
                            </dd>
                        </dl>
                    </li>
                    <li class="titleLi">
                        <dl>
                            <dt><label for="ctTitle">채팅방 제목</label></dt>
                            <dd>
                                <input type="text" id="ctTitle" placeholder="채팅방 제목을 입력하세요" value="패러글라이딩 동호회"/>
                            </dd>
                        </dl>
                    </li>
                    <li class="introLi">
                        <dl>
                            <dt><label for="ctTxt">소개글</label></dt>
                            <dd>
                                <textarea name="" id="ctTxt" placeholder="채팅방 소개글을 작성하세요">어떠한 이야기도 가능한 곳입니다. 채팅 많이해주세요</textarea>
                            </dd>
                        </dl>
                    </li>
                </ul>
            </div>
        </div>

        <div class="btns">
            <input type="button" value="취소" />
            <input type="button" value="저장" />
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ChattingRoomInfoModal extends Mixins(VueHoduCommon) {

    mounted() : void {
        $(".dpUl > li").click(function(){
            $(this).siblings().removeClass("on");
            $(this).addClass("on");
        });
    }

}
</script>

<style scoped>
    .modal {overflow: hidden; position:absolute; left:50%; top:50%; margin-top:-280px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:567px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    .btns { border-top:1px solid #e7e9ea }
    .btns input { transition: 0.1s; width: 50%; float: left; height: 75px; line-height: 75px; font-size: 14px; text-align: center; font-weight: bold; background-color:#fff; }


    /* 라디오 버튼 */
    input[type='radio']:not(old) + label { padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;border:3px solid #fff; width:28px; height: 28px; border-radius: 50%; background-size: 25px; background-position: center center;}
    input[type='radio']:not(old):checked + label {background: #477fff url('../../../assets/images/contents/checked_fff.png') no-repeat center center;background-size: 25px;}

    .optionDiv { padding: 30px 40px 0; }
    .optionDiv h2 { transition: 0.1s; opacity:0.7; padding-left: 45px; font-size: 16px; height:18px; }
    .optionDiv .dpUl li {position:relative; display: inline-block; width:50%; box-sizing: border-box; padding-right: 20px;  }
    .optionDiv .dpUl li:last-child { padding-right:0; padding-left: 20px; }
    .optionDiv .dpUl li .preview { transition: 0.1s; opacity:0.7; overflow: hidden; display: block; width: 100%; height: 50px; border-top:1px solid #e7e9ea; line-height: 56px; font-size: 13px; font-weight: bold; }
    .optionDiv .dpUl li .preview .img { float:left; display: inline-block; width: 35px; height: 35px; font-size: 0; display: inline-block; background-size: cover; background-position: center center; border-radius: 5px; border:1px solid #e7e9ea; background-repeat: no-repeat; margin-top:10px; margin-right:10px;}
    .optionDiv .dpUl li:last-child .preview .img { border-radius: 50%; ; }
    .optionDiv .dpUl li .ds { opacity:0.7; padding-left: 45px; opacity:0.5; display: inline-block; line-height: 25px; height: 25px; font-weight: bold; margin-right: 10px; margin-bottom: 5px;
    padding-bottom: 5px; }
    .optionDiv .dpUl li { transition: 0.1s; transform: scale(0.95) }
    .optionDiv .dpUl li:hover h2 { opacity:1;}
    .optionDiv .dpUl li .checkDiv { position: absolute; left:0; top:2px; }
    .optionDiv .dpUl li:last-child .checkDiv { left: 20px }
    .optionDiv .dpUl li label:after { z-index: 1000; content:''; display: block;width: 342px;height: 115px;  position: absolute; left: 0; top:-12px;  }

    .optionDiv .dpUl li.on .preview, .optionDiv .dpUl li.on h2, .optionDiv .dpUl li.on .ds { opacity: 1; }
    .optionDiv .dpUl li.on { transform: scale(1); }

    /* detDiv */
    .detDiv { height: 0;overflow: hidden;width: 100%; }
    .detDiv .line { display: block;width: 100%; height: 1px; background:#e7e9ea; margin-bottom: 20px; }
    .detDiv { width: 527px; padding-left: 30px; padding-right: 30px;}
    .detDiv  { position: relative; width: 100%; height: 246px; box-sizing: border-box;margin-top: 23px; padding-left: 45px; padding-right: 45px;}
    .detDiv li.picLi { left: 30px; }
    .detDiv li.picLi a { position: absolute; z-index: 100; background:#ff6363 url(../../../assets/images/contents/ic_close_fff.gif) no-repeat center center; width: 30px; height: 30px; border-radius: 50%;font-size: 0; bottom: -22px;right: 6px; }
    .detDiv li.picLi a { bottom: -26px; right: 0; }
    .detDiv ul { padding-left: 185px; }
    .detDiv li.picLi dd label { background-position: center center;background-size: cover; background-repeat:no-repeat; margin-top: 1px; width: 160px;height: 160px;line-height: 160px; }
    .detDiv li.picLi dd { background: none; border: 0 none; }
    .detDiv input[type="file"] { display: none; }
    .detDiv dl dt { float: left; height: 25px; line-height: 25px; font-size: 12px !important; display: inline-block; text-align: left; width: 100%; margin-bottom: 3px; opacity: 0.5  }
    .detDiv li:hover dl dt { opacity:1 }
    .detDiv dl  { overflow: hidden; }
    .detDiv dd { display: inline-block; width:100%; float: left; }
    .detDiv ul { padding-left: 200px; box-sizing:border-box; width:100%; }
    .detDiv li.picLi { position:absolute;left: 45px; width: 150px;height: 150px;top: 20px; }
    .detDiv li.picLi dl { overflow: visible; }
    .detDiv li.picLi dt { width: 100%; font-weight: bold; }
    .detDiv li.picLi dd { border-radius: 15px; border:1px solid #f1f3f5; box-sizing: border-box; width: 163px;box-sizing: border-box;text-align: center;height: 163px;line-height: 159px;background: #fff;    background-position: center center;background-size: cover;background-repeat: no-repeat; }
    .detDiv li.picLi.added dd:hover label { opacity: 0.1; float: left; }
    .detDiv li.picLi.added label { background:#000; opacity: 0; font-size: 0; border: none; border-radius: 15px; }
    .detDiv li.picLi dd label { font-weight:bold; }
    .detDiv li { margin-bottom:10px; }
    .detDiv dt label { display: block; width: 100%;font-weight: bold}
    .detDiv dd input[type="text"] { transition:0.1s; float: left; box-sizing:border-box; padding: 0 13px; width:100%; border:1px solid #e7e9ea; height: 45px; line-height: 45px;  font-size: 14px; font-weight: bold;}
    .detDiv dd input[type="text"]:hover { background:#f1f3f5; }
    .detDiv dd input[type="text"]:focus { border-color:#477fff; background:#fff; }
    .detDiv dd label {transition: 0.1s;display: inline-block;height: 145px;line-height: 141px;width: 145px;background: #f4f7ff;border-radius: 5px;box-sizing: border-box;font-size: 13px;color: #477fff;text-align: center;border: 2px dashed #c6d7ff;}
    .detDiv dd label:hover { border-color:#477fff; background:#fff;cursor: pointer; }
    .detDiv dd textarea { transition:0.1s; box-sizing:border-box; padding: 13px;border:1px solid #f1f3f5; background: #f1f3f5; width: 100%;height: 80px;border-radius: 0; resize:none; font-weight: bold; }
    .detDiv dd textarea:hover { background: #e7e9ea; }
    .detDiv dd textarea:focus { border-color:#477fff; background:#fff;outline: none; }


    /* 추후 삭제 */
    .detDiv li.picLi dd { background-image: url(../../../assets/images/footer/g7.jpg) }
    .optionDiv .dpUl li .preview .img { background-image: url(../../../assets/images/footer/g7.jpg)  }
    .optionDiv .dpUl > li:last-child .preview .img { background-image: url(../../../assets/images/footer/f23.jpg) !important  }
</style>