<template>
    <div id="biz_apply" class="section_ce_fix">
        <TitleBox :title="'프리미엄 그룹 승인'" />
        
        <div class="content">

            <div class="content-box">
                <ul>

                    <li> 
                        <label class="input_label">그룹 ID</label>
                        <input type="number" id="biz-input-group-id" class="input_txt" :value="group_id" @input="group_id = $event.target.value" /> 
                    </li>

                    <li> 
                        <label class="input_label">회사명</label>
                        <input type="text" id="biz-input-company-name" class="input_txt" :value="company_name" @input="company_name = $event.target.value" /> 
                    </li>

                    <li> 
                        <label class="input_label">담당자 연락처</label> 
                        <input type="text" id="biz-input-tel" class="input_txt" :value="tel" @input="tel = $event.target.value" /> 
                    </li>

                    <li>
                        <label class="input_label">담당자 이메일</label>
                        <input type="text" id="biz-input-email" class="input_txt" :value="email" @input="email = $event.target.value" />
                    </li>

                    <li>
                        <label class="input_label">사원수</label>
                        <input type="text" id="biz-input-head-count" class="input_txt" :value="head_count" @input="head_count = $event.target.value" />
                    </li>
                </ul>

                <div class="button-area">
                    <input type="button" class="input_btn" value="초기화" @click.prevent="reset"/>
                    <input type="button" class="input_btn" value="승인" @click.prevent="apply"/>
                </div>

            </div>

        </div>        

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import TitleBox from '@/components/ui/TitleBox.vue';

@Component({
    components: {
        TitleBox
    },
})
export default class BizApply extends Mixins(VueHoduCommon) {

    group_id : string = "";
    company_name : string = "";
    tel : string = "";
    email : string = "";
    head_count : string = "";

    mounted() : void {
        
    }

    /**
     * 초기화
     */
    reset() : void {
        this.group_id = "";
        this.company_name = "";
        this.tel = "";
        this.email = "";
        this.head_count = "";
    }

    /**
     * 승인
     */
    apply() : void {

        if( this.checkValid() == false ) return; 

        this.hodu_show_dialog('alert', "정말로 프리미엄 그룹으로 변경 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/bizGroupChange/${this.group_id}`, API_METHOD.PUT, {
                        "name" : this.company_name,
                        "tel" : this.tel,
                        "email" : this.email,
                        "head_count" : this.head_count
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("프리미엄 그룹 변경 중 오류 발생");
                    }

                    this.reset();
                    this.hodu_show_dialog('success', "프리미엄 그룹 변경 완료", ['확인']);

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog('cancel', "프리미엄 그룹 변경 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 유효성 검사
     */
    checkValid() : boolean {
        
        this.group_id = this.group_id.trim();
        this.company_name = this.company_name.trim(); 
        this.tel = this.tel.trim();
        this.email = this.email.trim(); 
        this.head_count = this.head_count.trim(); 

        // 그룹 ID 입력 안함
        if( this.group_id == null || this.group_id.length < 1 ) {
            alert("그룹 ID를 입력해주세요")
            $('#biz-input-group-id').addClass('wrong');
            $('#biz-input-group-id').focus();
            return false;
        }

        if( new RegExp(/[0-9]{1,}/).test(this.group_id) == false || new RegExp(/\.+/).test(this.group_id) == true || new RegExp(/-+/).test(this.group_id) == true ) {
            alert("그룹 ID에는 정수인 숫자만 입력해주세요")
            $('#biz-input-group-id').addClass('wrong');
            $('#biz-input-group-id').focus();
            return false;
        }

        // 회사명 입력 안함
        if( this.company_name == null || this.company_name.length < 1 ) {
            alert("회사명을 입력해주세요")
            $('#biz-input-company-name').addClass('wrong');
            $('#biz-input-company-name').focus();
            return false;
        }

        // 담당자 연락처 입력 안함
        if( this.tel == null || this.tel.length < 1 ) {
            alert("담당자 연락처를 입력해주세요")
            $('#biz-input-tel').addClass('wrong');
            $('#biz-input-tel').focus();
            return false;
        }

        // 담당자 이메일 입력 안함
        if( this.email == null || this.email.length < 1 ) {
            alert("담당자 이메일을 입력해주세요")
            $('#biz-input-email').addClass('wrong');
            $('#biz-input-email').focus();
            return false;
        }

        // 사원수를 입력해주세요
        if( this.head_count == null || this.head_count.length < 1 ) {
            alert("사원수를 입력해주세요")
            $('#biz-input-head-count').addClass('wrong');
            $('#biz-input-head-count').focus();
            return false;
        }

        return true;
    }

    @Watch('group_id')
    changeGroupId() : void {
        $('#biz-input-group-id').removeClass('wrong');
    }

    @Watch('company_name')
    changeCompanyName() : void {
        $('#biz-input-company-name').removeClass('wrong');
    }

    @Watch('tel')
    changeTel() : void {
        $('#biz-input-tel').removeClass('wrong');
    }

    @Watch('email')
    changeEmail() : void {
        $('#biz-input-email').removeClass('wrong');
    }

    @Watch('head_count')
    changeHeadCount() : void {
        $('#biz-input-head-count').removeClass('wrong');
    }

}
</script>

<style scoped>
    .content { position: relative; height : calc(100% - 62px); }
    .content-box { position : absolute; width : 800px; height : 525px; top : 50%; left : 50%; margin-left : -400px; margin-top : -262.5px; box-shadow: 0 3px 20px rgba(71, 127, 255, 0.2); border-radius: 5px; padding : 20px 30px; box-sizing: border-box; }

    .content-box .input_label { text-align: left;width: 100%; display: inline-block; height:40px; line-height: 50px; opacity: 0.7 }

    .content-box .input_txt { display: block; width: 100%; border:1px solid #e3e7ed;height:40px; line-height: 40px; box-sizing:border-box; padding: 0 15px; font-weight: bold; font-size:12px;  transition:0.2s; }
    .content-box .input_txt:hover { background: #f1f3f5; }
    .content-box .input_txt:focus { background: #fff; border:1px solid #477fff; }
    .content-box .input_txt.wrong { border:1px solid #ff6060; background: #fff1f1; color: #ff6060;}

    .content-box .button-area { margin-top : 20px; text-align: center; }
    .content-box .button-area .input_btn { width : 100px; margin : 10px; }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

    /* Firefox */
    input[type=number] { -moz-appearance: textfield; }

</style>