<template>
    <div id="announcement_create" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">안내방송 등록</h3>
            <p class="btn_group" style="">
                <input type="button" value="저장" class="input_btn" @click.prevent="setTTS">
                <input type="button" value="미리듣기" class="input_btn annExample_btn" title="미리듣기(150자 이내)" @click.prevent="setPreTTS">
            </p>
            <!-- 설명 tool인데... 위로 안올라와서 잠시 보류... -->
            <!-- <div class="createExTool_box">
                <p>작성중 미리듣기는 <span>150자 이내 <br>2번 실행</span>가능합니다.</p>
            </div> -->
        </div>
        <div class="content announcement">
            <div class="annOption">
                <div class="when">
                    <p class="when_title">안내방송 방송일시</p>
                    <label for="ann_datepicker">
                        <input type="button" id="ann_datepicker" :value="hodu_date_to_format_string(date, 'YYYY.MM.DD')" class="input_txt" :disabled="immediately == true"/>
                    </label>
                    <div class="when_time">
                        <select class="ampm" v-model="am_pm" :disabled="immediately == true" @change="changeTime">
                            <option value="AM">오전</option>
                            <option value="PM">오후</option>
                        </select>
                        <select v-model="hour" :disabled="immediately == true" @change="changeTime">
                            <option value="12"> 12 </option>
                            <option value="1"> 01 </option>
                            <option value="2"> 02 </option>
                            <option value="3"> 03 </option>
                            <option value="4"> 04 </option>
                            <option value="5"> 05 </option>
                            <option value="6"> 06 </option>
                            <option value="7"> 07 </option>
                            <option value="8"> 08 </option>
                            <option value="9"> 09 </option>
                            <option value="10"> 10 </option>
                            <option value="11"> 11 </option>
                        </select> : 
                        <select v-model="min" :disabled="immediately == true" @change="changeTime">
                            <option value="0"> 00 </option>
                            <option value="5"> 05 </option>
                            <option value="10"> 10 </option>
                            <option value="20"> 20 </option>
                            <option value="25"> 25 </option>
                            <option value="30"> 30 </option>
                            <option value="35"> 35 </option>
                            <option value="40"> 40 </option>
                            <option value="45"> 45 </option>
                            <option value="50"> 50 </option>
                            <option value="55"> 55 </option>
                        </select>
                    </div>
                </div>
                <!-- 즉시방송/수동방송 체크시 안내방송 방송일시는 클릭되지않게 활성화 꺼주세요 -->
                <div class="when_check">
                    <p class="nowCheck_title">즉시 방송</p>
                    <label for="ann_nowCheck" class="switch">
                        <input type="checkbox" id="ann_nowCheck" v-model="immediately" @change="changeImmediately">
                        <span class="slider round"></span>
                    </label>
                    <p class="manualCheck_title">수동 방송</p>
                    <label for="ann_manualCheck" class="switch">
                        <input type="checkbox" id="ann_manualCheck" v-model="manual" @change="chageManual">
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="how_repeat">
                    <p class="repeat_title">반복 방송 설정</p>
                    <select class="ann_repeat" v-model="repeat_count">
                        <option value="1">1회</option>
                        <option value="2">2회</option>
                        <option value="3">3회</option>
                    </select>
                </div>
            </div>
            <div class="ann_textBox">
                <input type="text" id="ann_title" placeholder="제목을 입력하세요." class="input_txt" :value="title" @input="title = $event.target.value">
                <div id="announcement_create_scroll" class="ann_textCon" :style="{ height : `${scroll_height}px` }">
                    <textarea id="ann_content" class="input_textarea" placeholder="안내방송 내용을 작성하세요. *작성 중 미리듣기는 150자만 실행됩니다" :value="content" @input="content = $event.target.value" :style="{ height : `${scroll_height - 2}px` }"></textarea>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class AnnouncementCreate extends Mixins(VueHoduCommon) {

    is_create : boolean = true;
    uid : string = "";

    date : Date = new Date();
    am_pm : string = 'AM';
    hour : string = '12';
    min : string = '0';

    immediately : boolean = false;
    manual : boolean = false;

    repeat_count : string = "1";

    title : string = "";
    content : string = "";

    scroll_height : number = 0;

    audio = new Audio();
    audio_uid = "";

    tts : any = null;

    beforeMount() : void {
        console.log(this.$route.params);

        if( this.$route.params.uid == null ) {
            this.is_create = true;

            // 1시간 더한 후 00분 고정
            let time = new Date().getTime();
            time += 3600000;
            time -= (time % 3600000);
            
            this.date = new Date(time);
            this.am_pm = this.date.getHours() >= 12 ? 'PM' : 'AM';
            this.hour = String(this.date.getHours() == 0 ? '12' : this.date.getHours() > 12 ? this.date.getHours() - 12 : this.date.getHours());
            
        }
        else {
            this.is_create = false;
            this.uid = this.$route.params.uid;

            if( this.uid == null || this.uid.length < 1 ) {
                this.hodu_show_dialog("cancel", "수정 중 오류 발생 잠시 후 다시 시도 해주세요", ['확인'], [() => { this.movePrevPage() }]);
                return;
            }

            this.getTTSList();
        }

    }

    mounted() : void {

        //반복횟수 on/off
        $('.ann_repeat').off("click").on("click", function(){
        	if($(".ann_repeat").hasClass("on")){
                $(".ann_repeat").removeClass("on");
            } else {
                $(".ann_repeat").addClass("on");
            }
        });

        this.setScroll();
        this.datepickerInit();
    }

    async beforeDestroy() : Promise<void> {
        if( this.audio.duration > 0 && this.audio.paused == false ) {
            // 바로 삭제
            try {
                this.audio.pause();
                await this.hodu_api_call(`api/v1/home/tts/pre?noti_uid=${this.audio_uid}`, API_METHOD.DELETE, null, false);
            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
        }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('#announcement_create .title_box').outerHeight();
        const optionHeight : number | undefined = $('#announcement_create .annOption').outerHeight();
        const announcementTitleHeight : number | undefined = $('#announcement_create #ann_title').outerHeight();

        this.scroll_height = window.innerHeight - (titleHeight ? titleHeight : 0)
                                                 - (optionHeight ? optionHeight : 0)
                                                 - (announcementTitleHeight ? announcementTitleHeight : 0);
    }

    /**
     * datepicker
     */
    datepickerInit() : void {

        const datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: (dateText, inst) => {
                const selected_date = new Date(dateText);
                inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")}`);

                const temp_hour = this.date.getHours();
                const temp_min = this.date.getMinutes();
                selected_date.setHours(temp_hour);
                selected_date.setMinutes(temp_min);

                this.date = new Date(selected_date);
            },
        }

        // @ts-ignore
        $('#ann_datepicker').datepicker(datepicker_option);
    }

    /**
     * 안내방송 리스트 조회
     */
    async getTTSList() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}?page=0&size=0&noti_uid=${this.uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list || response.data.data.list.length < 1 ) {
                throw new Error("안내방송 조회 중 오류 발생");
            }

            this.tts = JSON.parse(JSON.stringify(response.data.data.list[0]));

            this.date = new Date(this.tts.noti_info.tts.start_time);
            this.am_pm = this.date.getHours() >= 12 ? 'PM' : 'AM';
            this.hour = String(this.date.getHours() == 0 ? '12' : this.date.getHours() > 12 ? this.date.getHours() - 12 : this.date.getHours());
            this.min = String(this.date.getMinutes());

            this.immediately = this.tts.noti_info.tts.mode == 'immediately';
            this.manual = this.tts.noti_info.tts.mode == 'manual'; 

            this.repeat_count = String(this.tts.noti_info.tts.repeat_count);

            this.title = this.tts.noti_info.title;
            this.content = this.tts.noti_data.body;

        } catch(e) {
            this.hodu_show_dialog("cancel", "안내방송 조회 중 오류 발생", ["확인"], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 미리 듣기
     */
    async setPreTTS() : Promise<void> {
        try {

            if( this.content.trim().length < 1 ) {
                this.hodu_show_dialog("alert", "방송 내용을 작성 해주세요", ['확인']);
                return;
            }
            
            const response = await this.hodu_api_call(`api/v1/home/tts/pre/${this.scope_group_id}`, API_METHOD.POST, {
                notification : {
                    noti_data : {
                        body : this.content.trim()
                    }
                }
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.noti_uid ) {
                throw new Error("미리듣기 파일 생성 중 오류 발생");
            }

            // 오디오 재생 현재 response 받자마자 파일이 생성되지 않음!
            setTimeout(async() => {
                this.audio.pause();
                this.audio_uid = response.data.data.noti_uid;
                this.audio.src = `app_images/tts/pre_${response.data.data.noti_uid}.mp3`;
                this.audio.onended = async() => {
                    // 바로 삭제
                    try {
                        await this.hodu_api_call(`api/v1/home/tts/pre?noti_uid=${response.data.data.noti_uid}`, API_METHOD.DELETE, null, false);
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    }
                };
                this.audio.play();
            }, 1500); 

        } catch(e) {
            this.hodu_show_dialog('cancel', '미리듣기 파일 생성 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 안내방송 저장
     */
    async setTTS() : Promise<void> {
        try {

            if( this.title.trim().length < 1 ) {
                this.hodu_show_dialog("alert", "방송 제목을 작성 해주세요", ['확인']);
                return;
            }

            if( this.content.trim().length < 1 ) {
                this.hodu_show_dialog("alert", "방송 내용을 작성 해주세요", ['확인']);
                return;
            }

            if( this.is_create ) {

                const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}`, API_METHOD.POST, {
                    "notification" : {
                        "noti_type" : "GROUP_TTS",
                        "noti_info" : {
                            "tts": {
                                "mode": this.immediately ? 'immediately' : this.manual ? 'manual' : "auto", 
                                "start_time": this.immediately ? new Date() : this.date, 
                                "complete_yn": "N", 
                                "repeat_count": Number(this.repeat_count)
                            }, 
                            "scope": "GROUP", 
                            "title": this.title.trim(), 
                            "shared": "T", 
                            "end_date": "2050-12-30T15:00:00.000Z", 
                            "scope_id": this.scope_group_id, 
                            "start_date": new Date(), 
                            "calendar_id": `group-${this.scope_group_id}`, 
                        },
                        "noti_data" : {
                            "body" : this.content.trim(),
                            "body_type": "TEXT"
                        }
                    }
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("안내방송 등록 중 오류 발생");
                }

                this.movePrevPage();
            }
            
            else {
                const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}?noti_uid=${this.uid}&mode=status`, API_METHOD.PUT, {
                    "notification" : {
                        "noti_type" : "GROUP_TTS",
                        "noti_info" : {
                            "tts": {
                                "mode": this.immediately ? 'immediately' : this.manual ? 'manual' : "auto", 
                                "start_time": this.immediately ? new Date() : this.date, 
                                "complete_yn": "N", 
                                "repeat_count": Number(this.repeat_count)
                            }, 
                            "scope": "GROUP", 
                            "title": this.title.trim(), 
                            "shared": "T", 
                            "end_date": "2050-12-30T15:00:00.000Z", 
                            "scope_id": this.scope_group_id, 
                            "start_date": new Date(), 
                            "calendar_id": `group-${this.scope_group_id}`, 
                        },
                        "noti_data" : {
                            "body" : this.content.trim(),
                            "body_type": "TEXT"
                        }
                    }
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("안내방송 수정 중 오류 발생");
                }

                this.movePrevPage();
            }

        } catch(e) {
            if( this.is_create ) {
                this.hodu_show_dialog('cancel', '안내방송 등록 중 오류 발생', ['확인']);
            }
            else {
                this.hodu_show_dialog('cancel', '안내방송 수정 중 오류 발생', ['확인']);
            }
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 시간 변경됨 (date 재조립)
     */
    changeTime() : void {
        const temp_date = new Date(this.date);
        
        if( this.am_pm == 'AM' && this.hour == '12' )  {
            temp_date.setHours(0);
        }
        else if( (this.am_pm == 'AM' && this.hour != '12') || (this.am_pm == 'PM' && this.hour == '12') ) {
            temp_date.setHours(Number(this.hour));
        }
        else if( this.am_pm == 'PM' && this.hour != '12' ) {
            temp_date.setHours(Number(this.hour) + 12);
        }
        
        temp_date.setMinutes(Number(this.min));

        this.date = new Date(temp_date);
        console.log(this.date);
    }

    /**
     * 즉시 방송 변경
     */
    changeImmediately() : void {
        if( this.immediately == true ) {
            this.manual = false;
        }
    }

    /**
     * 수동 방송 변경
     */
    chageManual() : void {
        if( this.manual == true ) {
            this.immediately = false;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.setScroll();
    }

}
</script>

<style scoped>
    #announcement_create .title_box { position: relative; }
    #announcement_create .title_box .btn_group .annExample_btn { background: url('../../assets/images/contents/hoduhome_Announcement_example.svg') no-repeat 11px center !important; padding-left: 45px !important; transition: .3s; }
    #announcement_create .title_box .btn_group .annExample_btn:hover { background-color: #477fff !important; background-image: url('../../assets/images/contents/hoduhome_Announcement_exampleWhite.svg') !important; color: #fff !important; border: 1px solid #477fff !important; }
    #announcement_create .title_box .createExTool_box { position: absolute; right: 63px; bottom: -60px; background: #fff; padding: 10px 12px; border-radius: 6px; display: block; opacity: 1; transition: all 0. 3s ease; width: 195px; height: 60px; z-index: 2; box-shadow: 0px 0px 9px rgb(0 0 0 / 20%); box-sizing: border-box; line-height: 15px; border: 1px solid #e1e4e6; }
    #announcement_create .title_box .createExTool_box:before { content: ''; position: absolute; left: 50%; margin-left: -5px; border-bottom: 5px solid #fff; border-left: 5px solid transparent; border-right: 5px solid transparent; top: -5px; }

    .announcement .annOption .how_repeat > .ann_repeat { width: 100%; padding-left: 0px; background: #fff url('../../assets/images/side_area/bt_open.png') no-repeat 74%; }
    .announcement .annOption .how_repeat > .ann_repeat.on { width: 100%; padding-left: 0px; background: #fff url('../../assets/images/side_area/bt_close.png') no-repeat 74%; }

    #announcement_create ::-webkit-scrollbar{width: 6px;}
    #announcement_create ::-webkit-scrollbar-track{background-color: transparent;}
    #announcement_create ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
    #announcement_create #ann_datepicker:disabled { color : rgb(170, 170, 170) }
</style>