@charset "utf-8";

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p,
	blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd,
	q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr,
	th, td, article, aside, canvas, details, figcaption, figure, footer,
	header, hgroup, menu, nav, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

body {
	line-height: 1;
	font-size: 12px;
	font-family: 'noto sans', Sans-serif;
	color: #4e4e4e;
}

a {
	color: #585858;
	cursor: pointer;
	text-decoration: none;
}

a:link, a:visited, a:active {
	cursor: pointer;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
	cursor: pointer;
}

a img {
	vertical-align: top;
	border: 0;
}

legend {
	visibility: hidden;
	height: 0px;
	width: 0px;
	line-height: 0;
	text-indent: -10px;
	font-size: 0px;
}

article, aside, details, figcaption, figure, footer, header, hgroup,
	menu, nav, section {
	display: block;
}

ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	text-decoration: none;
}

span {
	color: #4e4e4e;
}
/* change colours to suit your needs */
ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

/* change colours to suit your needs */
mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title], dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #cccccc;
	margin: 1em 0;
	padding: 0;
}

label {
	
}

input, select {
	vertical-align: middle;
	border: none;
	outline: none;
	font-family: 'noto sans', Sans-serif;
	font-size: 13px;
}

a.board-icon:link, a.board-icon:visited, a.board-icon:active {
	color: #4e4e4e;
}

/**/
.relative {
	position: relative !important;
}

/**/
.ellipsis {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
}

.hidden {
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	visibility: hidden;
	display: block
}

.blind {
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	text-indent: -9999px;
	overflow: hidden;
	visibility: hidden;;
	display: none
}

.over_hidden {
	overflow: hidden;
}

.essential01 {
	line-height: 17px;
	color: #949494;
	font-size: 11px;
	text-indent: -10px;
	margin-left: 10px;
	text-align: left
}

.underline {
	border-bottom: 1px solid #d9d9d9;
}

/**/
.indent10 {
	text-indent: -10px;
	margin-left: 10px;
}

.indent16 {
	text-indent: -16px;
	margin-left: 16px;
}

.fl {
	float: left !important;
}

.fr {
	float: right !important;
}

.floatNone {
	float: none !important
}

/**/
.clearFix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden
}

/* clearfix */
.clearfix{*zoom:1;} /*익스플로우7때문에 사용하는 zoom*/
.clearfix:before, .clearfix:after {display: block; content: '';line-height: 0;}
.clearfix:after {clear: both;}

.dpInblock {
	display: inline-block !important;
}

.dpblock {
	display: block !important;
}

.dpnone {
	display: none !important;
}

.dpTable {
	display: table !important;
}

/**/
.backgroundTrans {
	background-color: transparent
}

/**/
.lineHeight1 {
	line-height: 1;
}

.lineHeight17 {
	line-height: 17px;
}

.lineHeight20 {
	line-height: 20px !important;;
}

.lineHeight35 {
	line-height: 35px !important;;
}

.lineHeight40 {
	line-height: 40px;
}

/**/
.pd0 {
	padding: 0 !important
}

.pt0 {
	padding-top: 0 !important;
}

.pt02 {
	padding-top: 2px !important;
}

.pt04 {
	padding-top: 4px !important;
}

.pt15 {
	padding-top: 15px !important
}

.pt20 {
	padding-top: 20px !important;
}

.pl0 {
	padding-left: 0 !important;
}

.pl08 {
	padding-left: 8px !important;
}

.pl10 {
	padding-left: 10px !important;
}

.pl25 {
	padding-left: 25px !important;
}

.pl29 {
	padding-left: 29px !important;
}

.pr0 {
	padding-right: 0 !important
}

.pr05 {
	padding-right: 5px !important
}

.pr10 {
	padding-right: 10px !important
}

.pr25 {
	padding-right: 25px !important;
}

.pr29 {
	padding-right: 29px !important;
}

.pb0 {
	padding-bottom: 0 !important;
}

.pb05 {
	padding-bottom: 5 !important;
}

.pb10 {
	padding-bottom: 10px !important;
}

.pb15 {
	padding-bottom: 15px !important;
}

.pb20 {
	padding-bottom: 20px !important;
}

.ml0 {
	margin-left: 0 !important;
}

.ml02 {
	margin-left: 2px !important;
}

.ml04 {
	margin-left: 4px !important;
}

.ml08 {
	margin-left: 8px !important;
}

.ml10 {
	margin-left: 10px !important;
}

.ml15 {
	margin-left: 15px !important;
}

.ml17 {
	margin-left: 17px !important;
}

.ml20 {
	margin-left: 20px !important;
}

.ml25 {
	margin-left: 25px !important;
}

.ml30 {
	margin-left: 30px !important;
}

.ml40 {
	margin-left: 40px !important;
}

.mt0 {
	margin-top: 0px !important;
}

.mt02 {
	margin-top: 2px !important;
}

.mt03 {
	margin-top: 3px !important;
}

.mt04 {
	margin-top: 4px !important;
}

.mt05 {
	margin-top: 5px !important;
}

.mt06 {
	margin-top: 6px !important;
}

.mt07 {
	margin-top: 7px !important;
}

.mt08 {
	margin-top: 8px !important;
}

.mt09 {
	margin-top: 9px !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mt11 {
	margin-top: 11px !important;
}

.mt12 {
	margin-top: 12px !important;
}

.mt14 {
	margin-top: 14px !important;
}

.mt15 {
	margin-top: 15px !important;
}

.mt17 {
	margin-top: 17px !important;
}

.mt20 {
	margin-top: 20px !important;
}

.mt30 {
	margin-top: 30px !important;
}
.mt35 {
	margin-top: 35px !important;
}
.mt40 {
	margin-top: 40px !important;
}

.mr0 {
	margin-right: 0px !important;
}

.mr02 {
	margin-right: 2px !important;
}

.mr04 {
	margin-right: 4px !important;
}

.mr10 {
	margin-right: 10px !important;
}

.mr12 {
	margin-right: 12px !important;
}

.mr15 {
	margin-right: 15px !important;
}

.mr20 {
	margin-right: 20px !important;
}

.mr40 {
	margin-right: 40px !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mb04 {
	margin-bottom: 4px !important;
}

.mb05 {
	margin-bottom: 5px !important;
}

.mb10 {
	margin-bottom: 10px !important;
}

.w61 {
	width: 61px;
}

.w100 {
	width: 100px;
}

.w340 {
	width: 340px;
}

.w400 {
	width: 400px;
}

.w707 {
	width: 707px;
}

.w100per {
	width: 100%;
}

/* switch */
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 52px;
	height: 25px;
}
/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}
/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #e5e7ea;
	-webkit-transition: .1s;
	transition: .1s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	left: 5px;
	bottom: 5px;
	background-color: #fff;
	box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
	-webkit-transition: .1s;
	transition: .1s;
}

input:checked+.slider {
	background-color: #477fff;
}

input:focus+.slider {
	box-shadow: 0 0 1px #477fff;
}

input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
	border-radius: 25px;
}

.slider.round:before {
	border-radius: 50%;
}

/* 상단라디오 버튼 */
input[type='radio']:not(old) {
	margin: 0;
	padding: 0;
	opacity: 0;
	background: url('../images/contents/ra_check_on.png')
		no-repeat 0 4px;
	width: 0;
}

input[type='radio']:not(old)+label {
	display: inline-block;
	color: #a2a2a2;
	text-align: left;
	height: 16px;
	padding-left: 22px;
	padding-top: 3px;
	padding-bottom: 1px;
	background: url('../images/contents/ra_check_off.png')
		no-repeat 0 4px;
	line-height: 130%;
	vertical-align: top;
}

input[type='radio']:not(old):checked+label {
	background: url('../images/contents/ra_check_on.png')
		no-repeat 0 4px;
	color: #4e4e4e;
}

/* 화면 라디오 버튼 */
input.radio_round[type='radio']:not(old) {
	background: url('../images/contents/ra_check_on_02.png')
		no-repeat 0 1px;
}

input.radio_round[type='radio']:not(old)+label {
	color: #4e4e4e;
	font-size: 13px;
	padding-left: 22px;
	padding-top: 1px;
	margin-right: 5px;
	background: url('../images/contents/ra_check_off_02.png')
		no-repeat 0 1px;
}

input.radio_round[type='radio']:not(old):checked+label {
	background: url('../images/contents/ra_check_on_02.png')
		no-repeat 0 1px;
}

/* 라디오 버튼02-오렌지 */
input.radio_round02[type='radio']:not(old) {
	background: url('../images/contents/ra_check_on_03.png')
		no-repeat 0 0px;
}

input.radio_round02[type='radio']:not(old)+label {
	color: #4e4e4e;
	height: 17px;
	padding-left: 28px;
	margin-right: 9px;
	background: url('../images/contents/ra_check_off_03.png')
		no-repeat 0 0px;
}

input.radio_round02[type='radio']:not(old):checked+label {
	background: url('../images/contents/ra_check_on_03.png')
		no-repeat 0 0px;
}

/* 라디오 버튼03-오른쪽 */
input.radio_round03[type='radio']:not(old) {
	background: url('../images/contents/ra_check_on_02.png')
		no-repeat 0 0px;
}

input.radio_round03[type='radio']:not(old)+label {
	color: #4e4e4e;
	height: 15px;
	padding-left: 15px;
	padding-top: 0;
	padding-bottom: 0;
	background: url('../images/contents/ra_check_off_02.png')
		no-repeat 0 0px;
}

input.radio_round03[type='radio']:not(old):checked+label {
	background: url('../images/contents/ra_check_on_02.png')
		no-repeat 0 0px;
}

td#repeatTd input.radio_round[type='radio']:not(old)+label {
	color: #4e4e4e;
	background: #f1f3f5;
	line-height: 40px;
	padding: 0 15px;
	box-sizing: border-box;
	height: 40px;
	border-radius: 5px;
}

td#repeatTd input.radio_round[type='radio']:not(old)+label:hover {
	background: #e5e8ea;
	font-weight: bold;
}

td#repeatTd input.radio_round[type='radio']:not(old):checked+label, td#repeatTd input.radio_round[type='radio']:not(old):checked+label:hover
	{
	background: #477fff;
	color: #fff;
}

td#repeatTd input#r_none.radio_round[type='radio']:not(old):checked+label
	{
	background: #e5e8ea;
	color: #4e4e4e;
}
/* 기본 check박스 */
input[type='checkbox']:not(old) {
	margin: 0;
	padding: 0;
	opacity: 0;
	background: url('../images/side_area/ra_check_on.png')
		no-repeat 0 5px;
	width: 0;
}

input[type='checkbox']:not(old)+label {
	display: inline-block;
	color: #4e4e4e;
	text-align: left;
	height: 16px;
	padding-left: 20px;
	padding-top: 3px;
	padding-bottom: 1px;
	background: url('../images/side_area/ra_check_off.png')
		no-repeat 0 5px;
	line-height: 130%;
	vertical-align: top;
}

input[type='checkbox']:not(old):checked+label {
	background: url('../images/side_area/ra_check_on.png')
		no-repeat 0 5px;
}

/* left check박스 */
.class_depth input[type='checkbox']:not(old) {
	background: url('../images/side_area/ra_check_on.png')
		no-repeat 0 3px;
	-ms-background-position-y: 5px;
}

.class_depth input[type='checkbox']:not(old)+label {
	color: #4e4e4e;
	background: url('../images/side_area/ra_check_off.png')
		no-repeat 0 3px;
	-ms-background-position-y: 5px;
}

.class_depth input[type='checkbox']:not(old):checked+label {
	background: url('../images/side_area/ra_check_on.png')
		no-repeat 0 3px;
	-ms-background-position-y: 5px;
}

/* check박스 -오렌지 */
input.check_round[type='checkbox']:not(old) {
	background: url('../images/contents/ra_check_on_03.png')
		no-repeat 0 0px;
}

input.check_round[type='checkbox']:not(old)+label {
	color: #4e4e4e;
	height: 16px;
	padding-left: 28px;
	background: url('../images/contents/ra_check_off_03.png')
		no-repeat 0 0px;
}

input.check_round[type='checkbox']:not(old):checked+label {
	background: url('../images/contents/ra_check_on_03.png')
		no-repeat 0 0px;
}

input[type='button'] {
	cursor: pointer;
	margin: 0;
	padding: 0;
}

input[type='checkbox']+label, input[type='radio']+label {
	cursor: pointer;
}

.backPosi-Y03 {
	background-position-y: 3px !important;
}

/*인풋 기본*/
.input_txt {
	font-size: 12px;
	color: #4e4e4e;
	border: none;
	background-color: #fff;
	padding: 4px 10px 4px 0;
	height: 21px;
	line-height: 21px;
	font-weight: normal;
	font-family: 'noto sans', Sans-serif;
}

input::placeholder {
	color: #acb2bf;
	font-weight: bold;
	font-family: 'noto sans', Sans-serif;
}

::-webkit-input-placeholder {
	color: #acb2bf;
	font-weight: bold;
}

::-moz-placeholder {
	color: #acb2bf;
	opacity: 1;
	font-weight: bold;
}

:-ms-input-placeholder {
	color: #acb2bf;
	font-weight: bold;
}

.placeholder {
	color: #acb2bf;
	font-weight: bold;
}

.input_search::placeholder {
	color: #acb2bf;
}

.input_search.bg_gray::placeholder {
	color: #acb2bf;
}

input:read-only {
	color: #acb2bf;
	font-weight: bold;
}

input[type='text']:disabled {
	color: #acb2bf;
	font-weight: bold;
}

input[type='button'].input_btn {
	font-family: 'noto sans', Sans-serif;
	color: #4e4e4e;
	background-color: #fff;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 14px;
}

select.input_select {
	font-size: 12px;
	color: #4e4e4e;
	border: none;
	background-color: #fff;
	padding: 5px 6px;
	height: 27px;
	line-height: 29px;
	font-family: 'noto sans', Sans-serif;
}

.input_textarea {
	font-family: 'noto sans', Sans-serif;
	font-size: 12px;
	color: #4e4e4e;
	border: none;
	padding: 3px 10px;
	height: auto;
	line-height: 19px;
}

textarea:disabled {
	color: #acb2bf;
}

/* 셀렉트 박스 화살표 이미지 */
.selectboxWrap {
	position: relative;
	display: inline-block;
	height: 27px;
	line-height: 27px;
	background: url('../images/contents/ic_select.png')
		no-repeat 41px center; /* 화살표 이미지 */
	border: none;
}

.selectboxWrap.w51 {
	width: 51px;
	background: url('../images/contents/ic_select.png')
		no-repeat 30px center; /* 화살표 이미지 */
}

.selectboxWrap.w51:hover {
	background: url(../images/contents/ic_select_on.png)
		no-repeat 30px center;
}

.selectboxWrap.w60 {
	width: 60px;
	background: url('../images/contents/ic_select.png')
		no-repeat 41px center; /* 화살표 이미지 */
}

.selectboxWrap.w100 {
	width: 100px;
	background: url('../images/contents/ic_select.png')
		no-repeat 81px center; /* 화살표 이미지 */
}

.selectboxWrap.w111 {
	width: 111px;
	background: url('../images/contents/ic_select.png')
		no-repeat right center; /* 화살표 이미지 */
}

.selectboxWrap.w128 {
	width: 128px;
	background: url('../images/contents/ic_select.png')
		no-repeat 111px center; /* 화살표 이미지 */
}

.selectboxWrap.w194 {
	width: 194px;
	background: url('../images/contents/ic_select.png')
		no-repeat 175px center; /* 화살표 이미지 */
}

.selectboxWrap label {
	position: absolute;
	font-size: 12px;
	color: #4e4e4e;
	top: 0px;
	left: 12px;
	letter-spacing: 1px;
}

.selectboxWrap select {
	width: 100%;
	height: 27px;
	min-height: 27px;
	line-height: 27px;
	padding: 5px 6px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity = 0); /* IE 8 */
}

.selectboxWrap.selectboxWrap02 {
	background: none;
	min-width: 100px;
}

.selectboxWrap.selectboxWrap02 .allowImg {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	top: 24px;
	right: 36px;
	background: #477fff;
	z-index: 20;
}

.selectboxWrap.selectboxWrap02:hover .allowImg {
	/* background: url(../images/side_area/bt_close.png) no-repeat -8px center !important; */
	
}

.selectboxWrap.selectboxWrap02 select {
	padding: 5px 20px 5px 6px;
}

.selectboxWrap.selectboxWrap02 label {
	
}

.disabled {
	color: #949494 !important;
} /* 읽기전용 */
.disabled_textarea {
	color: #4e4e4e !important;
} /* 읽기전용 */
.inputDisable {
	color: #949494;
	background-color: #fafafa
}
/* .input_gray{background-color:#fafafa !important;} */
.input_gray {
	background-color: transparent;
}

.input_white {
	background-color: #fff !important;
}

.input_border {
	border: 1px solid #e6e6e6;
}

.borderNone {
	border: none;
}

.radius04 {
	border: 1px solid #e6e6e6;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-ms-border-radius: 4px;
}

.radius08 {
	border: 1px solid #e4e4e4;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-ms-border-radius: 4px;
}

.radius50 {
	border: 1px solid #e6e6e6;
	border-radius: 50px;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	-ms-border-radius: 50px;
}

/*정렬*/
.text-center {
	text-align: center !important;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.v-top {
	vertical-align: top
}

.v-middle {
	vertical-align: middle
}

.v-bottom {
	vertical-align: bottom
}

.h36 {
	height: 36px;
}

.fns11 {
	font-size: 11px;
}

.fns12 {
	font-size: 12px;
}

.fns13 {
	font-size: 13px !important;
}

.fnt_normal {
	font-weight: normal !important
}

.fnt_bold {
	font-weight: bold !important
}

.fnt_black {
	color: #4e4e4e !important;
}

.fnt_orange {
	color: #477fff !important;
}

.fnt_gray {
	color: #989898 !important;
}

.fnt_darkgray {
	color: #949494 !important
}

.fnt_white {
	color: #fff !important
}

.borderBox {
	box-sizing: border-box;
}

/*버튼*/
.btn {
	display: inline-block;
	padding: 10px;
	margin: 0;
	border: 0;
	cursor: pointer;
	vertical-align: middle;
	color: #fff;
	border-radius: 20px;
	-moz-border-radius: 20px; /* Firefox */
	-webkit-border-radius: 20px; /* Safari 및 Chrome */
	-ms-border-radius: 20px;
}

.btn.btnRadius50 {
	border-radius: 50px;
	-moz-border-radius: 50px; /* Firefox */
	-webkit-border-radius: 50px; /* Safari 및 Chrome */
	-ms-border-radius: 50px;
}

.btn.btn-lg {
	padding: 11px 17px;
	font-size: 12px;
}

.btn.btn-md {
	padding: 7px 20px;
	font-size: 12px;;
	line-height: 1.3;
}

.btn.btn-md02 {
	padding: 6px 20px;
	font-size: 12px;
}

.btn.btn-sm {
	padding: 6px 10px;
	font-size: 12px;;
}

.btn.btn-xs {
	padding: 5px 14px;
	font-size: 12px;
}

.btn.btn-gray {
	background: #f0f0f0;
	color: #6f6f6f;
}

.btn.btn-gray:hover {
	color: #f16727;
}

.btn.btn-darkGray {
	background: #8f8f8f;
	color: #fff;
}

.btn-lineWhite {
	border: 1px solid #fff;
	background: transparent;
	color: #fff;
}

.btn.btn-print {
	border: 1px solid #cfcfcf;
	padding-left: 43px;
	color: #949494;
	background: url(../images/contents/bt_print.png) no-repeat 17px;
}

.btn02 {
	border-radius: 0px;
	color: #fff;
	font-weight: bold;
}

.btn-white {
	background-color: #fff !important
}

.btn-orange {
	background-color: #ffbe0d !important
}

.btn-orange02 {
	background-color: #ff9147 !important
}

.btn-orange02:hover {
	background-color: #ff732e !important;
}

.btn-blue01 {
	background-color: #21c6b5 !important;
}

.btn-blue02 {
	background-color: #477fff !important;
}

.btn-red {
	background-color: #ff5a5a !important;
}

.btn-purple {
	background-color: #b770ff !important;
}

.btn-green {
	background-color: #16cc9b !important;
}

.btn-white {
	background-color: #fff !important;
	border: 1px solid #e5e5e5 !important;
	color: #949494;
}

.btn-white:hover {
	background-color: #ff5a5a !important;
	color: #fff;
}

.btn-white.on {
	background-color: #ff5a5a !important;
	color: #fff;
}

.btn-border {
	background-color: #fff !important;
	border: 1px solid #d5d5d5 !important;
	color: #949494;
}

.btn-border:hover {
	background-color: #fff !important;
	color: #4e4e4e;
	border: 1px solid #bab9b9 !important;
}

.bg_white {
	background-color: #fff;
}

.bg_gray {
	background-color: #fafafa;
}

.bg_darkgray {
	background-color: #d3d3d3;
}

.btn_bigWhite {
	border-radius: 20px;
	text-align: center;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
	border: 1px solid #eee;
	background-color: #fff;
}

.btn_bigYellow {
	border-radius: 20px;
	text-align: center;
	background-color: #ffd40b;
	color: #fff;
}

/* 보더버튼 */
.btn-grayBorder {
	border: 1px solid #ebebeb;
	color: #989898;
	padding: 5px 10px;
	text-align: center
}

.btn-grayBorder.on, .btn-grayBorder:hover {
	border: 1px solid #ffae44;
	color: #ffae44;
}

.btn-orangeBorder {
	border: 1px solid #ff9147;
	color: #ff9147;
}

.btn-orangeBorder:hover {
	border: 1px solid #ff732e;
	color: #ff732e
}

/* 동그라미 버튼 -  gray에서 hover시 red */
.bt_delete {
	transition: 0.1s;
	display: inline-block;
	background: transparent;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	text-align: center;
	line-height: 10px;
}

.bt_delete span.blind {
	line-height: auto;
	visibility: visible;
	display: inline-block;
	font-size: 0;
	text-indent: 0;
	width: 6px;
	height: 1px;
	background-color: #c3c3c3;
}

.bt_delete.bt_del_bggray {
	background-color: #ddd
}

.bt_delete.bt_del_bggray span.blind {
	background-color: #fff;
}

.bt_delete:hover {
	background-color: #ff5d5d;
}

.bt_delete:hover span.blind {
	background-color: #fff;
}

.bt_add {
	position: relative;
	float: right;
	display: inline-block;
	width: 19px;
	height: 19px;
	border-radius: 50%;
	margin-top: 11px;
	background: url(../images/side_area/ic_add.gif) no-repeat
		center center;
	z-index: 30
}

.bt_add:hover {
	background: #29c78e url(../images/side_area/ic_add_on.gif)
		no-repeat center center;
}

.bt_delete02 {
	display: inline-block;
	width: 19px;
	height: 19px;
	background: url(../images/contents/bt_del.png) no-repeat
		center center;
	z-index: 30
}

.bt_delete02:hover {
	background: #fff url(../images/contents/bt_del_on.png)
		no-repeat center center;
}

/*datepicker*/
.datepickerWrap {
	position: relative;
	z-index: 1000;
	display: inline-block;
}

.datepickerWrap span {
	position: absolute;
	right: 7px;
	top: 6px;
}

/*탭 기본*/
.tabStyle01 {
	width: 100%;
	list-style: none;
}

.tabStyle01 li {
	width: 33%;
	float: left;
	background: #fafafa;
}

.tabStyle01 li.active {
	width: 34%;
	background: #fff;
}

.tabStyle01 li a {
	padding: 13px 0 10px;
	height: 26px;
	display: block;
	font-size: 16px;
	color: #4e4e4e;
	text-align: center;
	border: 0;
	border-bottom: 1px solid #e6e5e5;
}

.tabStyle01 li.active a {
	padding: 12px 0 11px;
	border-top: 1px solid #e6e5e5;
	border-right: 1px solid #e6e5e5;
	border-bottom: 0px solid #fff;
	border-left: 1px solid #e6e5e5;
}

/*테이블스타일*/
.tableDefault>table {
	width: 100%;
	color: #4e4e4e;
}

.tableDefault.top-bdr-none table {
	border-top: 0;
}

.tableDefault.tableBorder table tbody tr th {
	vertical-align: top;
	text-align: right;
	font-weight: bold;
	padding: 21px 10px 16px 0;
	position: relative;
}

.tableDefault.tableBorder  table  tbody  tr  td {
	height: auto;
	padding: 0;
	border-bottom: 1px solid #e6e5e5;
	font-weight: normal;
	vertical-align: middle;
	position: relative;
}

#schDetailScroll .tableDefault.tableBorder table tbody tr#voteTr td,
	#schDetailScroll .tableDefault.tableBorder table tbody tr#shareTr td {
	padding: 13px 0 !important;
}

.tableDefault table th.essential:before {
	content: "* ";
	color: #e12525;
	font-weight: bold;
}

.tableDefault table th label {
	display: inline-block;
	height: 30px;
	width: 30px;
	padding-right: 20px;
	font-weight: bold;
	margin-top: 0;
	top: 14px;
	right: 25px;
	position: absolute;
	padding: 0;
	border-radius: 50%;
	font-size: 0;
}

.writtenBy label img {
	width: 30px;
	border-radius: 50%;
	border: 1px solid #f1f3f5;
}

.tableDefault.tableStyle01 table tr th {
	vertical-align: middle;
	text-align: center;
	padding: 18px 0;
	font-weight: bold;
	background-color: #f2f2f2;
	border-top: 1px solid #efefef;
	border-bottom: 1px solid #efefef;
	border-left: 1px solid #e1e1e1;
}

.tableDefault.tableStyle01 table tr td {
	padding: 18px 0;
	text-align: center;
	border-bottom: 1px solid #efefef;
	border-left: 1px solid #efefef;
}

.tableDefault.tableStyle01 table tr th:first-child, .tableDefault.tableStyle01 table tr td:first-child
	{
	border-left: 0
}

.con_normalRow {
	cursor: default;
}

#workTr td {
	position: relative;
}

#workTr td .basic span {
	position: absolute;
	right: 0;
	width: 10px;
	height: 10px;
	background: #477fff;
	border-radius: 50%;
	top: 50%;
	margin-top: -5px;
	right: 16px;
}

#workTr td>div.three {
	display: none;
	position: absolute;
	right: 16px;
	top: 1px;
	margin-top: 0;
	height: 55px;
}

#workTr td>div.three span {
	display: inline-block;
	width: 10px;
	height: 10px;
	background: #477fff;
	border-radius: 50%;
	margin-right: 10px;
}

#workTr td>div.three span.line {
	position: absolute;
	width: 85%;
	top: 50%;
	margin-top: -2px;
	margin-left: 0;
	border-radius: 0;
	height: 2px;
	background: #f1f3f5;
	z-index: -1;
}

#workTr td>div.three span:last-child {
	margin-right: 0;
}

#workTr td>p.three {
	display: none;
	position: absolute;
	right: 16px;
	top: 1px;
	margin-top: 0;
	height: 55px;
}

#workTr td>p.three span {
	display: inline-block;
	width: 10px;
	height: 10px;
	background: #477fff;
	border-radius: 50%;
	margin-right: 10px;
}

#workTr td>p.three span.line {
	position: absolute;
	width: 85%;
	top: 50%;
	margin-top: -2px;
	margin-left: 0;
	border-radius: 0;
	height: 2px;
	background: #f1f3f5;
	z-index: -1;
}

#workTr td>p.three span:last-child {
	margin-right: 0;
}

/* default */
#workTr th label {
	background: url(../images/contents/ic_work_bk.png)
		no-repeat center center;
}

#grupTr th label {
	background: url(../images/contents/ic_grp_bk.png) no-repeat
		center center;
}

#titleTr th label {
	background: url(../images/contents/ic_title_bk_off.png)
		no-repeat center center;
	top: 26px;
}

#timeTr th label {
	background: url(../images/contents/ic_time_bk.png)
		no-repeat center center;
	top: 17px;
}

#repeatTr th label {
	background: url(../images/contents/ic_repeat_bk_off.png)
		no-repeat center center;
}

#alarmTr th label {
	background: url(../images/contents/ic_alarm_bk_off.png)
		no-repeat center center;
}

#mapTr th label {
	background: url(../images/contents/ic_place_bk_off.png)
		no-repeat center center;
}

#telTr th label {
	background: url(../images/contents/ic_tel_bk_off.png)
		no-repeat center center;
}

#meetingLocationTr th label {
	background: url(../images/contents/ic_place_bk_off.png)
		no-repeat center center;
}

#attendeesTr th label {
	background: url(../images/contents/ic_grp_bk_off.png)
		no-repeat center center;
}

#memoTr th label {
	background: url(../images/contents/ic_memo_bk_off.png)
		no-repeat center center;
}

#memoTr.memo th label {
	background: url(../images/contents/ic_unlock.png)
		no-repeat 5px center;
	background-size: 20px;
}

#noteTr th label {
	background: url(../images/contents/ic_lock.png)
		no-repeat 5px center;
	background-size: 20px;
}

#remarkTr th label {
	background: url(../images/contents/ic_remark_off.png)
		no-repeat center center;
}

#addImageTr th label {
	background: url(../images/contents/ic_img_bk_off.png)
		no-repeat center center;
}

#attendTr th label {
	background: url(../images/contents/ic_attend_bk_off.png)
		no-repeat center center;
}

#addFileTr th label {
	background: url(../images/contents/ic_file_bk_off.png)
		no-repeat center center;
}

#voteTr th label {
	background: url(../images/contents/ic_vote_bk_off.png)
		no-repeat center center;
}

#shareTr th label {
	background: url(../images/contents/ic_share_bk_off.png)
		no-repeat center center;
}

#workerTr th label {
	background: url(../images/contents/ic_chooseMem_bk.png)
		no-repeat center center;
}

/* enabled */
#workTr.enabled th label {
	background: url(../images/contents/ic_work_bk.png)
		no-repeat center center;
}

#grupTr.enabled th label {
	background: url(../images/contents/ic_grp_bk.png) no-repeat
		center center;
}

#titleTr.enabled th label {
	background: url(../images/contents/ic_title_bk.png)
		no-repeat center center;
	top: 26px;
}

#workTr.enabled th label {
	background: url(../images/contents/ic_work_bk.png)
		no-repeat center center;
}

#timeTr.enabled th label {
	background: url(../images/contents/ic_time_bk.png)
		no-repeat center center;
	top: 17px;
}

#repeatTr.enabled th label {
	background: url(../images/contents/ic_repeat_bk.png)
		no-repeat center center;
}

#alarmTr.enabled th label {
	background: url(../images/contents/ic_alarm_bk.png)
		no-repeat center center;
}

#mapTr.enabled th label {
	background: url(../images/contents/ic_place_bk.png)
		no-repeat center center;
}

#telTr.enabled th label {
	background: url(../images/contents/ic_tel_bk.png) no-repeat
		center center;
}

#meetingLocationTr.enabled th label {
	background: url(../images/contents/ic_place_bk.png)
		no-repeat center center;
}

#attendeesTr.enabled th label {
	background: url(../images/contents/ic_grp_bk.png)
		no-repeat center center;
}

#memoTr.enabled th label {
	background: url(../images/contents/ic_memo_bk.png)
		no-repeat center center;
}

#memoTr.enabled.memo th label {
	background: url(../images/contents/ic_unlock.png)
		no-repeat 5px center;
	background-size: 20px;
}

#noteTr.enabled th label {
	background: url(../images/contents/ic_lock.png)
		no-repeat 5px center;
	background-size: 20px;
}

#remarkTr.enabled th label {
	background: url(../images/contents/ic_remark.png)
		no-repeat center center;
}

#addImageTr.enabled th label {
	background: url(../images/contents/ic_img_bk.png) no-repeat
		center center;
}

#attendTr.enabled th label {
	background: url(../images/contents/ic_attend_bk.png)
		no-repeat center center;
}

#addFileTr.enabled th label {
	background: url(../images/contents/ic_file_bk.png)
		no-repeat center center;
}

#voteTr.enabled th label {
	background: url(../images/contents/ic_vote_bk.png)
		no-repeat center center;
}

#shareTr.enabled th label {
	background: url(../images/contents/ic_share_bk.png)
		no-repeat center center;
}

#workerTr.enabled th label {
	background: url(../images/contents/ic_chooseMem_bk.png)
		no-repeat center center;
}

/* enabled */
#workTr.enabled:hover th label {
	background: url(../images/contents/ic_work_bk.png)
		no-repeat center center;
}

#grupTr.enabled:hover th label {
	background: url(../images/contents/ic_grp_bk.png) no-repeat
		center center;
}

#titleTr.enabled:hover th label {
	background: url(../images/contents/ic_title_bk.png)
		no-repeat center center;
	top: 26px;
}

#workTr.enabled:hover th label {
	background: url(../images/contents/ic_work_bk.png)
		no-repeat center center;
}

#timeTr.enabled:hover th label {
	background: url(../images/contents/ic_time_bk.png)
		no-repeat center center;
	top: 17px;
}

#repeatTr.enabled:hover th label {
	background: url(../images/contents/ic_repeat_bk.png)
		no-repeat center center;
}

#alarmTr.enabled:hover th label {
	background: url(../images/contents/ic_alarm_bk.png)
		no-repeat center center;
}

#mapTr.enabled:hover th label {
	background: url(../images/contents/ic_place_bk.png)
		no-repeat center center;
}

#telTr.enabled:hover th label {
	background: url(../images/contents/ic_tel_bk.png) no-repeat
		center center;
}

#meetingLocationTr.enabled:hover th label {
	background: url(../images/contents/ic_place_bk.png)
		no-repeat center center;
}

#attendeesTr.enabled:hover th label {
	background: url(../images/contents/ic_grp_bk.png)
		no-repeat center center;
}

#memoTr.enabled:hover th label {
	background: url(../images/contents/ic_memo_bk.png)
		no-repeat center center;
}

#memoTr.enabled.memo:hover th label {
	background: url(../images/contents/ic_unlock.png)
		no-repeat 5px center;
	background-size: 20px;
}

#noteTr.enabled:hover th label {
	background: url(../images/contents/ic_lock.png)
		no-repeat 5px center;
	background-size: 20px;
}

#remarkTr.enabled:hover th label {
	background: url(../images/contents/ic_remark.png)
		no-repeat center center;
}

#addImageTr.enabled:hover th label {
	background: url(../images/contents/ic_img_bk.png) no-repeat
		center center;
}

#attendTr.enabled:hover th label {
	background: url(../images/contents/ic_attend_bk.png)
		no-repeat center center;
}

#addFileTr.enabled:hover th label {
	background: url(../images/contents/ic_file_bk.png)
		no-repeat center center;
}

#voteTr.enabled:hover th label {
	background: url(../images/contents/ic_vote_bk.png)
		no-repeat center center;
}

#shareTr.enabled:hover th label {
	background: url(../images/contents/ic_share_bk.png)
		no-repeat center center;
}

#workerTr.enabled:hover th label {
	background: url(../images/contents/ic_chooseMem_bk.png)
		no-repeat center center;
}

/*
#workTr.enabled:hover th label {background:url(../images/contents/ic_work_bk_on.png) no-repeat center center;}
#grupTr.enabled:hover th label {background:url(../images/contents/ic_grp_bk_on.png) no-repeat center center;}
#titleTr.enabled:hover th label {background:url(../images/contents/ic_title_bk_on.png) no-repeat center center;}
#workTr.enabled:hover th label {background:url(../images/contents/ic_work_bk_on.png) no-repeat center center;}
#timeTr.enabled:hover th label {background:url(../images/contents/ic_time_bk_on.png) no-repeat center center;}
#repeatTr.enabled:hover th label {background:url(../images/contents/ic_repeat_bk_on.png) no-repeat center center;}
#alarmTr.enabled:hover th label {background:url(../images/contents/ic_alarm_bk_on.png) no-repeat center center;}
#mapTr.enabled:hover th label {background:url(../images/contents/ic_place_bk_on.png) no-repeat center center;}
#telTr.enabled:hover th label {background:url(../images/contents/ic_tel_bk_on.png) no-repeat center center;}
#noteTr.enabled:hover th label {background:url(../images/contents/ic_note_bk_on.png) no-repeat center center;}
#memoTr.enabled:hover th label {background:url(../images/contents/ic_memo_bk_on.png) no-repeat center center;}
#addImageTr.enabled:hover th label {background:url(../images/contents/ic_img_bk_on.png) no-repeat center center;}
#attendTr.enabled:hover th label {background:url(../images/contents/ic_attend_bk_on.png) no-repeat center center;}
#addFileTr.enabled:hover th label {background:url(../images/contents/ic_file_bk_on.png) no-repeat center center;}
#voteTr.enabled:hover th label {background:url(../images/contents/ic_vote_bk_on.png) no-repeat center center;}
#shareTr.enabled:hover th label {background:url(../images/contents/ic_share_bk_on.png) no-repeat center center;}
#workerTr.enabled:hover th label {background:url(../images/contents/ic_chooseMem_bk_on.png) no-repeat center center;}*/

/* tr border-bottom */
.schedule_box tr.enabled td:after {
	content: '';
	position: absolute;
	display: block;
	width: 0;
	height: 2px;
	background: #477fff;
	bottom: -1px;
	z-index: 1;
	left: 0;
	transition: 0.2s;
}

.schedule_box tr.enabled:hover td:after, .schedule_box tr.on td:after {
	width: 100%;
	transition: 0.2s;
}

.schedule_box tr td:after {
	content: '';
	position: absolute;
	display: block;
	width: 0;
	height: 2px;
	background: #d2d7e2;
	bottom: -1px;
	z-index: 1;
	left: 0;
	transition: 0.2s;
}

.schedule_box tr:hover td:after, .schedule_box tr.on td:after {
	width: 100%;
	transition: 0.2s;
}

/* 전체회원현황, 환자현황 */
.tableDefault.tableStyle02 table tr th {
	vertical-align: middle;
	text-align: center;
	font-weight: normal;
	background-color: #f3f3f3;
	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
	border-left: 1px solid #e4e4e4;
}

.tableDefault.tableStyle02 table tr td {
	padding: 8px 0 7px;
	text-align: center;
	border-bottom: 1px solid #f3f3f3;
}

.tableDefault.tableStyle02 table tr td.pad08 {
	padding: 8px 0
}

/* 컨설팅 현황 , 예약현황 */
/*.tableDefault.tableStyle03 {border-right:1px solid #eee}*/
.tableDefault.tableStyle03 table tr th:first-child {
	border-left: 0;
}

.tableDefault.tableStyle03 table tr th {
	padding-left: 10px;
	padding-right: 10px;
	vertical-align: middle;
	text-align: left;
	height: 55px;
	font-weight: normal;
	background-color: #fafafa;
	border: 1px solid #e4e4e4;
	color: #4e4e4e;
}

.tableDefault.tableStyle03 table tr th.num {
	text-align: center;
}

.tableDefault.tableStyle03 table tr td {
	padding-left: 10px;
	text-align: left;
	border-bottom: 1px solid #f0f0f0; /*border-left:1px solid #f0f0f0;*/
	vertical-align: middle
}

.tdBar {
	margin-top: 15px;
	float: left;
	display: inline-block;
	width: 1px;
	height: 12px;
	background-color: #d0d0d0;
}

.tableDefault.tableStyle03 input[type='checkbox']:not(old)+label {
	padding-left: 12px;
}

.tableStyle04 {
	padding: 12px 35px 27px
}

.tableDefault.tableStyle04 table tr th {
	vertical-align: top;
	padding-right: 20px;
	text-align: right;
	padding-top: 16px;
}

.tableDefault.tableStyle04 table tr th label {
	font-weight: normal
}

.tableDefault.tableStyle04 table tr td {
	padding: 7px 0;
	text-align: left;
}

/*리스트 페이징*/
.pageing {
	text-align: center;
	margin: 20px;
}

.pageing ul {
	list-style: none;
	display: inline-block;
}

.pageing ul:after {
	content: "";
	display: block;
	clear: both;
}

.pageing ul li {
	float: left;
	margin-left: -1px;
	border: 1px solid #ddd;
}

.pageing ul li a {
	display: block;
	width: 30px;
	height: 30px;
	line-height: 30px;
	color: #666;
}

.pageing ul li.active a, .pageing ul li.active:hover a {
	background: #27a3e5;
	color: #fff;
}

.pageing ul li:hover a {
	background: #f1f1f1;
}

.pageing ul li a .fa-lg {
	vertical-align: -5%;
}

/* 라디오 및 체크박스 정렬 */
.input-inline input[type='radio'], .input-inline input[type='checkbox']
	{
	position: absolute;
	top: 0px;
	left: -2px;
	cursor: pointer;
}

.input-inline {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	font-weight: normal;
	vertical-align: middle;
	cursor: pointer;
}

.input-inline+.input-inline {
	margin-top: 0;
	margin-left: 10px;
}

/*컨텐츠 공통*/
.byteMax {
	position: relative;
}

.byteMax .byteCount {
	position: absolute;
	background: rgba(0, 0, 0, .2);
	padding: 3px 5px;
	border-radius: 10px;
	font-size: 12px;
	right: 10px;
	bottom: 10px;
}

/* ck에디터 */
.editorImgProc { /*max-width:546px;*/
	max-width: 644px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	border-radius: 0
}

.hbar {
	display: inline-block;
	float: left;
	width: 1px;
	height: 12px;
	margin-top: 3px;
	background-color: #cdcdcd;
}

.vbar {
	float: left;
	width: 12px;
	height: 1px;
	margin-top: 3px;
	background-color: #cdcdcd;
}

/* 색상선택 .pickColour */
#pickColour.on {
	display: block
}

#pickColour {
	display: none;
	position: absolute;
	width: 100%;
	z-index: 10000000;
	top: 81px;
	background: #fff;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
	border-radius: 0 0 5px 5px
}

#pickColour>ul>li>a {
	float: left;
	width: 100%;
	line-height: 60px;
	border-bottom: 2px solid #f1f3f5;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
}

#pickColour>ul>li>a.on {
	border-bottom: 2px solid #477fff;
}

div#pickColour>ul>li:hover>a {
	border-bottom: 2px solid #477fff;
}

div#pickColour>ul>li {
	overflow: hidden;
	float: left;
	width: 50%
}

div#pickColour>ul ul {
	clear: both;
	box-sizing: border-box;
	padding: 25px 30px;
}

div#pickColour>ul ul li {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin: 13px 10px;
	display: inline-block;
}

div#pickColour>ul>li {
	opacity: 0.8;
}

div#pickColour>ul>li:hover, div#pickColour>ul>li.on {
	opacity: 1;
}

#pickColour>ul>li.on>a {
	border-bottom: 2px solid #477fff;
}

div#pickColour>ul ul li a {
	float: left;
	display: inline-block;
	width: 100%;
	height: 100%;
	background: #477fff;
	font-size: 0;
	border-radius: 50%;
	transition: 0.1
}

div#pickColour>ul ul li.on a {
	opacity: 1;
	transform: scale(1.15);
	background-image: url(../images/contents/checked_fff.png);
	background-repeat: no-repeat;
	background-size: 23px;
	background-position: center center;
}

div#pickColour>ul ul li:hover a {
	cursor: pointer;
	transform: scale(1.15);
	transition: 0.1;
}

div#pickColour>ul ul li.on a:hover {
	cursor: default;
}
/* 기본색 */
.dc0 {
	background-color: #FF6363 !important;
}

.dc1 {
	background-color: #FFA70E !important;
}

.dc2 {
	background-color: #FFC72F !important;
}

.dc3 {
	background-color: #FF198B !important;
}

.dc4 {
	background-color: #00B2C7 !important;
}

.dc5 {
	background-color: #13D08B !important;
}

.dc6 {
	background-color: #4DBAFF !important;
}

.dc7 {
	background-color: #477FFF !important;
}

.dc8 {
	background-color: #6854FF !important;
}

.dc9 {
	background-color: #35405A !important;
}
/* 파스텔 */
.lc0 {
	background-color: #B9A494 !important;
}

.lc1 {
	background-color: #E67D9B !important;
}

.lc2 {
	background-color: #FF9898 !important;
}

.lc3 {
	background-color: #AB8DD6 !important;
}

.lc4 {
	background-color: #F3AC77 !important;
}

.lc5 {
	background-color: #E3D37A !important;
}

.lc6 {
	background-color: #8DD6A0 !important;
}

.lc7 {
	background-color: #7197ED !important;
}

.lc8 {
	background-color: #8DAED6 !important;
}

.lc9 {
	background-color: #6F7A93 !important;
}

/* 이미지 */
.imgDc0 {
	background-color: #FF6363;
	background-image: url(../images/contents/imgDc0.png)
		!important;
}

.imgDc1 {
	background-color: #FFA70E;
	background-image: url(../images/contents/imgDc1.png)
		!important;
}

.imgDc2 {
	background-color: #FFC72F;
	background-image: url(../images/contents/imgDc2.png)
		!important;
}

.imgDc3 {
	background-color: #FF198B;
	background-image: url(../images/contents/imgDc3.png)
		!important;
}

.imgDc4 {
	background-color: #00B2C7;
	background-image: url(../images/contents/imgDc4.png)
		!important;
}

.imgDc5 {
	background-color: #13D08B;
	background-image: url(../images/contents/imgDc5.png)
		!important;
}

.imgDc6 {
	background-color: #4DBAFF;
	background-image: url(../images/contents/imgDc6.png)
		!important;
}

.imgDc7 {
	background-color: #477FFF;
	background-image: url(../images/contents/imgDc7.png)
		!important;
}

.imgDc8 {
	background-color: #6854FF;
	background-image: url(../images/contents/imgDc8.png)
		!important;
}

.imgDc9 {
	background-color: #35405A;
	background-image: url(../images/contents/imgDc9.png)
		!important;
}

/* 새 페이지네이션 */
.pagination.newPagination {
	position: static !important;
	height: auto !important;
	padding: 0 !important;
	text-align: center;
	font-size: 0;
	width: auto;
	display: inline-block;
	border-radius: 15px;
	text-align: left;
	overflow: hidden;
	margin: 30px 0 0 20px;
	background: #fff;
	width: auto !important;
	border: 1px solid #efefef;
}

.pagination.newPagination a {
	float: left;
	display: inline-block;
	width: 40px;
	color: #4e4e4e !important;
	height: 40px;
	padding-top: 2px;
	font-size: 12px;
	line-height: 40px;
	border-left: 1px solid #efefef !important;
	text-align: center;
}

.pagination.newPagination a:first-child {
	border-left: 0 none !important;
}

.pagination.newPagination {
	bottom: 45px !important;
}

.pagination.newPagination>div {
	overflow: visible;
	display: inline-block;
	text-align: center;
	height: auto;
}

.pagination.newPagination .prevBtns, .nextBtns {
	float: left;
	display: inline-block;
}

.pagination.newPagination a {
	float: left;
	display: inline-block;;
	color: #4e4e4e !important;
	padding-top: 2px;
	font-size: 12px;
	text-align: center;
}

.pagination.newPagination a.active {
	color: #fff !important;
	background-color: #7e69ff;
	font-weight: bold;
}

.pagination.newPagination a:hover {
	background-color: #7e69ff;
	font-weight: bold;
	color: #fff !important;
}

.pagination.newPagination a.fnt_gray {
	border-radius: 0;
	margin: 0 0;
	box-shadow: none;
	border-left: 0 none;
}

.pagination.newPagination a.fnt_gray:first-child {
	border-left: 1px solid #F3f3f3;
}

.pagination.newPagination a.bt_prev_news, .pagination.newPagination a.bt_next_news
	{
	border-radius: 0;
	margin: 0 0;
	box-shadow: none;
}

.pagination.newPagination a.bt_next_news {
	border-left: 0 none;
	background: url(../images/contents/month_bt_next_on.png)
		no-repeat center center;
	border-left: 1px solid #f3f3f3 !important;
}

.pagination.newPagination a.bt_end_news {
	border-radius: 0 15px 15px 0;
	border-left: 0 none;
}

.pagination.newPagination a.bt_beginning_news {
	border-radius: 15px 0 0 15px;
	border-right: 0 none;
}

.pagination.newPagination a.bt_prev_news {
	background: url(../images/contents/month_bt_prev_on.png)
		no-repeat center center;
}

.pagination.newPagination a.bt_prev_news:hover {
	background: #7e69ff url(../images/contents/bt_prev_fff.png)
		no-repeat center center;
}

.pagination.newPagination a.bt_next_news:hover {
	background: #7e69ff url(../images/contents/bt_next_fff.png)
		no-repeat center center;
}

.pagination.newPagination a.bt_end_news {
	background: #ffffff url(../images/contents/bt_end_on.png)
		no-repeat center center;
}

.pagination.newPagination a.bt_end_news:hover {
	background: #7e69ff url(../images/contents/bt_end_fff.png)
		no-repeat center center;
}

.pagination.newPagination a.bt_beginning_news {
	background: #ffffff
		url(../images/contents/bt_beginning_on.png) no-repeat
		center center;
}

.pagination.newPagination a.bt_beginning_news:hover {
	background: #7e69ff
		url(../images/contents/bt_beginning_fff.png) no-repeat
		center center;
}

.pagination.newPagination a.bt_prev_news.disabled {
	cursor: default !important;
	background: #f3f3f3
		url(../images/contents/bt_prev_grey.png) no-repeat center
		center !important;
}

.pagination.newPagination a.bt_next_news.disabled {
	cursor: default !important;
	background: #f3f3f3
		url(../images/contents/bt_next_grey.png) no-repeat center
		center !important;
}

.pagination.newPagination a.bt_end_news.disabled {
	cursor: default !important;
	background: #f3f3f3 url(../images/contents/bt_end_grey.png)
		no-repeat center center !important;
}

.pagination.newPagination a.bt_beginning_news.disabled {
	cursor: default !important;
	background: #f3f3f3
		url(../images/contents/bt_beginning_grey.png) no-repeat
		center center !important;
}

div#attendInput {
	width:100%;
	display:none;
}
div#attendInput .btns {display:none; }

#attendInput input.radio_round[type='radio']:not(old)+label {
	height: 34px;
	background: #f1f3f5;
	padding: 0 15px;
	color: #202a39;
	line-height: 34px;
	font-weight:bold;
	margin-right:15px;
	border-radius: 20px;
}

#attendInput input.radio_round[type='radio']:not(old):checked+label {
	background: #477fff;
	color: #fff;
}

#attendInput input.radio_round[type='radio']:not(old):checked+label:hover
	{
	background: #477fff;
	color: #fff;
}

#attendInput input.radio_round[type='radio']:not(old)+label:hover {
	background: #e5e7ea;
}

#attendInput input.radio_round[type='radio']:disabled+label {
	cursor: default;
}

#attendInput input.radio_round[type='radio']:disabled+label:hover {
	background: #f1f3f5;
}

#btnSave, #instSaveBtn, #hostOptionSaveBtn, #docSaveBtn, #instDelBtn {
	bottom: 40px !important;
	color: #fff;
	font-weight: bold;
	text-align: center;
	box-shadow: 0 5px 15px rgba(0, 226, 154, 0.4);
	z-index: 10000000;
	transition: 0.1s;
	position: static;
	bottom: 40px;
	right: 40px;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	padding: 0;
	font-size: 14px;
	background: #00e29a url('../images/contents/bt_save_fff.png') no-repeat
		-1px -60px !important;
}

#btnSave:hover, #instSaveBtn:hover, #hostOptionSaveBtn:hover,
	#docSaveBtn:hover, #instDelBtn:hover {
	background: #00d08e url('../images/contents/bt_save_fff.png') no-repeat
		-1px 1px !important;
	font-size: 0;
	box-sizing: border-box;
	background-size: 60px;
	box-shadow: 0 10px 25px rgba(0, 226, 154, 0.5);
	transition: 0.1s;
}

#instDelBtn {
	margin-right: 0 !important;
	right: 130px !important;
	bottom: 40px !important;
	background: #ff5a5a url(../images/contents/btn_del_fff.png)
		no-repeat center -60px !important;
	box-shadow: 0 5px 15px rgba(255, 29, 36, 0.4) !important;
}

#instDelBtn:hover {
	background: #ff3737 url(../images/contents/btn_del_fff.png)
		no-repeat center center !important;
	box-shadow: 0 10px 25px rgba(255, 29, 36, 0.5) !important;
}

#schDetailScroll #mapTr td, #schDetailScroll #noteTr td,
#schDetailScroll #memoTr td, #schDetailScroll #attendeesTr td,
#schDetailScroll #remarkTr td, #schDetailScroll #meetingLocationTr td {
	padding: 19px 0 !important;
}

tr#workTr p {
	line-height: 55px;
}

#schDetailScroll #mapTr .over_hidden {
	position: relative;
	overflow: visible;
}

#schDetailScroll #s_mainTitle {
	width: 95% !important;
	height: 80px;
	line-height: 80px;
	font-size: 25px !important;
}

#schDetailScroll #telNum {
	width: 100% !important;
	height: 55px;
	line-height: 55px;
}

#schDetailScroll input#s_mainTitle::placeholder {
	font-weight: bold !important;
}

#schDetailScroll #mapSearchKey {
	width: 100%;
	float: none !important;
	padding-left: 0;
	box-sizing: border-box;
}

#schDetailScroll #mapSearch {
	position: absolute;
	top: 0;
	right: 0;
}

#schDetailScroll textarea {
	background: #fafafa;
	padding: 7px 10px;
}

.inputgroup input[type='checkbox']:not(old) {
	width: 0 !important;
}

.inputgroup_txt01 {
	cursor: default;
	margin-top: 0 !important;
	margin-left: 6px !important;
	line-height: 40px;
}

.inputgroup input {
	font-weight: bold;
	text-align: center;
	height: 60px;
	line-height: 60px;
	box-sizing: border-box;
	width: 40px;
	color: #737373;
}

.inputgroup input#s_FrYmd, .inputgroup input#s_ToYmd {
	width: auto !important;
	box-sizing: border-box;
	padding: 0;
}

#grupTr .selectboxWrap.selectboxWrap02 {
	height: auto;
}

#s_group {
	padding: 0;
	line-height: 55px;
	height: 55px;
}

#grupTr .selectboxWrap.selectboxWrap02 .allowImg {
	display: none;
}

input[type='checkbox']:not(old) {
	background: url(../images/side_area/ra_check_on.png)
		no-repeat 0 9px;
}

input[type='checkbox']:not(old):checked+label {
	background: url(../images/side_area/ra_check_on.png)
		no-repeat 0 9px;
}

input[type='checkbox']:not(old)+label {
	background: url(../images/side_area/ra_check_off.png)
		no-repeat 0 9px;
	padding-left: 20px;
	padding-top: 9px;
}

.inputgroup .mt04 input[type='checkbox']:not(old)+label {
	margin: 0 !important;
	padding: 0 !important;
	float: right !important;
	height: 60px;
	line-height: 60px;
	box-sizing: border-box;
	background: #fff;
}

.inputgroup .mt04 input[type='checkbox']:not(old)+label:hover {
	background-color: #fff;
	color: #477fff;
	font-weight: bold;
}

.inputgroup .mt04 input[type='checkbox']:not(old):checked+label {
	color: #477fff;
}

#time_today {
	margin-right: 10px;
    color: #273044;
    padding: 0 15px;
    width: auto !important;
    background: #f1f3f5;
    border-radius: 19px;
    line-height: 35px !important;
    height: 35px;
    margin-top: 12px;font-size:12px;
}
#time_today:hover { color: #273044 !important; }
#time_today:hover, #timeTr input[type='checkbox']:not(old) + label:hover { background:#e4e8ec !important }
#timeTr input[type='checkbox']:not(old) + label {    text-align: center;
    font-weight: bold;
    margin-right: 10px;
    color: #273044;
    padding: 0 15px;
    width: auto !important;
    background: #f1f3f5 !important;
    border-radius: 19px;
    line-height: 35px !important;
    height: 35px;
	margin-top: 12px; font-size:12px; }
#timeTr .inputgroup input[type='checkbox']:checked + label, #timeTr .inputgroup input[type='checkbox']:checked + label:hover {
	background:#477fff !important; color:#fff !important;

 }
.inputgroup input:hover {
	cursor: pointer;
	font-weight: bold;
	border: 1px solid #ededed;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05)
}

.inputgroup p.fl.mt04.ml10 {
	margin-left: 20px !important;
}

.inputgroup input:hover::placeholder {
	font-weight: bold;
}

.inputgroup input:hover::placeholder {
	font-weight: bold;
}

#attendTr input[type='checkbox']:not(old)+label, #shareTr input[type='checkbox']:not(old)+label
	{
	height: 40px;
	margin: 0;
	line-height: 40px;
	box-sizing: border-box;
	border-radius: 5px;
	padding-top: 0;
	padding-left: 30px;
	padding-right: 13px;
	background: #c1cfd8
		url(../images/contents/checked_fff.png) no-repeat 6px
		center !important;
	background-size: 22px;
}

#attendTr input[type='checkbox']:not(old)+label {
	width: 30px;
	height: 30px;
	padding: 0;
	border-radius: 50%;
	top: 14px;
	right: 6px;
	background-position: 3px 3px !important;
}

#attendTr input[type='checkbox']:not(old):checked+label, #shareTr input[type='checkbox']:not(old):checked+label,
	#attendTr input[type='checkbox']:not(old):checked+label:hover, #shareTr input[type='checkbox']:not(old):checked+label:hover
	{
	background: #477fff url(../images/contents/checked_fff.png)
		no-repeat 6px center !important;
	color: #fff !important;
}

#attendTr input[type='checkbox']:not(old)+label:hover, #shareTr input[type='checkbox']:not(old)+label:hover
	{
}

#attendTr input[type='checkbox']:not(old):checked+label, #attendTr input[type='checkbox']:not(old):checked+label:hover
	{
	background: #477fff url(../images/contents/checked_fff.png)
		no-repeat 3px 3px !important;
}

#attendTr input[type='checkbox']:not(old)+label:hover {
	background-color: #e5e7ea;
}

.inputgroup {
	overflow: visible;
}

#instructorTr .selectboxWrap .allowImg, #classListTr .selectboxWrap .allowImg
	{
	height: 40px;
}

.inputgroup .selectboxWrap {
	height: 40px;
	line-height: 40px;
	padding: 0;
	box-sizing: border-box;
	width: 70px !important;
	background: url(../images/side_area/bt_open.png) no-repeat
		39px center;
}

#instructorTr .selectboxWrap, #classListTr .selectboxWrap {
	height: 40px;
	line-height: 40px;
	padding: 0;
}

.inputgroup .selectboxWrap select, #instructorTr .selectboxWrap.selectboxWrap02 select,
	#classListTr .selectboxWrap.selectboxWrap02 select {
	height: 40px;
	line-height: 40px;
	min-height: 40px;
	box-sizing: border-box;
}

#fc-userToolbox .fc-right .selectboxWrap {
	border-radius: 5px;
	width: 70px;
	height: 40px;
	line-height: 40px;
	margin-top: 8px;
	margin-right: -9px;
	transition: 0.1s;
	border: 1px solid #e1e4e6;
}

#fc-userToolbox .fc-right .selectboxWrap:hover {
	background-color: #f1f3f5 !important;
}

#fc-userToolbox .fc-right .selectboxWrap label {
	font-weight: bold;
	color: #737373 !important;
}

#fc-userToolbox .fc-right .selectboxWrap.w51 {
	background: url(../images/side_area/bt_open.png) no-repeat
		41px center;
}

.right_area_close #fc-userToolbox .fc-right .selectboxWrap {
	/* margin-right: 51px; */
}

/* ì¼ì •ë“±ë¡í™”ë©´ ìƒ‰ìƒ ë³€ê²½*/
#colorDiv {
	z-index: 1000000;
	position: absolute;
	top: 66px;
	right: 0;
	margin-right: 60px;
	background: #fff;
	border: 1px solid #f3f3f3;
	padding: 20px;
	border-radius: 5px;
	width: 262px;
	box-shadow: 0 5px 20px rgba(45, 53, 62, 0.17);
}

.forRel input.clr {
	display: inline-block;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	position: absolute;
	right: 8px;
	top: 30px;
	margin-right: 60px;
}

.forRel input.clr:hover {
	transform: scale(1.1);
}

.forRel p.color_select {
	height: 32px;
	width: 32px;
	transform: scale(0.95);
	margin: 9px 10px;
}

.forRel p.color_select:hover {
	transform: scale(1.1);
	transition: 0.03s;
}

.forRel p.color_select a {
	width: 100% !important;
	height: 100% !important;
	top: 0;
	left: 0;
	background-position: 1px 2px;
	background-size: 30px;
}

.forRel p.color_select.on {
	height: 32px;
	transform: scale(1.1);
}

/* 반복 */
#repeatTr span.inputgroup_txt02 {
	transition: 0.2s;
	color: #acb2bf
}

#repeatTr.enabled span.inputgroup_txt02 {
	transition: 0.2s;
	color: #434343
}

/* 알림 */
#alarmTr span.inputgroup_txt02 {
	transition: 0.2s;
	color: #acb2bf
}

#alarmTr.enabled span.inputgroup_txt02 {
	transition: 0.2s;
	color: #434343
}

/* 참석여부 */
#attendTr span.inputgroup_txt02 {
	transition: 0.2s;
	color: #acb2bf
}

#attendTr.enabled span.inputgroup_txt02 {
	transition: 0.2s;
	color: #434343
}

/* 사진 */
#addImageTr span.inputgroup_txt02 {
	transition: 0.2s;
	color: #acb2bf
}

#addImageTr.enabled span.inputgroup_txt02 {
	transition: 0.2s;
	color: #434343
}

/* 파일 */
#addFileTr span.inputgroup_txt02 {
	transition: 0.2s;
	color: #acb2bf
}

#addFileTr.enabled span.inputgroup_txt02 {
	transition: 0.2s;
	color: #434343
}

/* 공유 */
#shareTr span.inputgroup_txt02 {
	transition: 0.2s;
	color: #acb2bf
}

#shareTr.enabled span.inputgroup_txt02 {
	transition: 0.2s;
	color: #434343
}

/* 투표 */
#schDetailScroll .tableDefault.tableBorder table tbody tr#voteTr td {
	padding: 19px 0 !important;
}

#voteTr  span.inputgroup_txt02.ml10 {
	line-height: 21px !important;
}

#voteTr p.result {
	line-height: 34px;
	font-weight: bold;
	width: auto;
	display: inline-block;
	display: block;
	position: absolute;
	top: 12px;
	right: 60px;
}

#voteTr.enabled .result a {
	display: inline-block;
}

#voteTr span.inputgroup_txt02 {
	transition: 0.2s;
	color: #acb2bf
}

#voteTr.enabled span.inputgroup_txt02 {
	transition: 0.2s;
	color: #434343
}

#voteTr .result a {
	background: #f1f3f5;
	border-radius: 20px;
	padding: 0 15px;
	line-height: 34px;
	display: none;
}

#voteTr .result a span {
	color: #477fff;
}

#voteTr .result a:hover, #voteTr .result a:hover span {
	background-color: #477fff;
	color: #fff;
}

/* 파일_사진 */
.clickOrDropBg {
	transition: 0.1s;
	display: block;
	height: 100%;
	line-height: 100%;
	width: 100%;
	text-align: right;
	position: absolute;
	right: 0;
	font-weight: bold;
	z-index: 1;
	opacity: 1;
	top: 0;
	background: none;
	border: none;
	border-radius: 5px;
	font-size: 0;
	margin-bottom: 20px;transition:0.2s;
}
tr:hover .clickOrDropBg:after { opacity:1}
.clickOrDropBg:after {
	opacity:0;
	content: '';
	width: 40px;
	height: 60px;
	position: absolute;
	background: url(../images/contents/ic_plus_new.png)
		no-repeat center center;
	top: 0;
	right: 0; transition:0.2s;
}

.clickOrDropBg:hover {
	cursor: pointer;
}

.clickOrDropBg.forImg:hover {
	transition: 0.1s;
	color: #477fff;
}

.clickOrDropBg.forFile:hover {
	transition: 0.1s;
	color: #477fff;
}

.clickOrDropBg.dragging {
	transition: 0.1s;
	background-color: rgba(71, 127, 255, 0.1) !important;
	height: 100%;
	border-radius: 0;
	border: 0;
}

.cdInput {
	padding: 0;
	font-size: 0;
	margin: 0;
	width: 0;
	height: 0;
	border: 0 none;
}

#scheduleUploadBasic, #scheduleImageUploadBasic {
	width: 0;
	height: 0;
	padding: 0;
	margin: 0;
}

#addFileTr h3 {
	font-weight: bold;
}

.addPicNfile.readOnly .clickOrDropBg {
	display: none;
}

#addFileTr.readOnly .description {
	display: none
}

/* 공유 */
#shareTr input {
	position: absolute;
	right: 3px;
	top: 9px;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	display: inline-block;
	background: url('../images/contents/month_bt_next.svg')
		center center;
	background-size: 27px;
}

#shareTr span {
	line-height: 34px;
}

/* 그룹 선택  */
#grupTr td .selectboxWrap label:before {
	display: inline-block;
	width: 10px;
	height: 10px;
	content: '';
	background: #477fff;
	border-radius: 50px;
	margin-right: 10px;
}

/* 업무 */
#workTr td p {
	cursor: pointer;
	font-weight: bold;
	font-size:14px;
}

#workTr .dropdown {
	display: none;
	position: absolute;
	z-index: 100000;
	top: 56px;
	width: 100%;
	height: 340px;
	background: #fff;
	box-shadow: 0 15px 15px rgba(0, 0, 0, 0.15);
	border-radius: 0 0 5px 5px;
}

#workTr .dropdown.on {
	display: block;
}

#workTr .dropdown ul ul {
	display: none;
	position: absolute;
	left: 0;
	top: 60px;
	height: 280px;
	width: 100%;
	text-align: left;
	overflow: hidden;
	overflow-y: auto;
}

#workTr .dropdown>ul {
	width: 100%;
	padding-right: 60px;
	box-sizing: border-box;
	clear: both;
	overflow: hidden;
	float: left;
}

#workTr .dropdown>ul>li {
	float: left;
	height: 60px;
	width: 100%;
	text-align: center;
    border-bottom: 3px solid #f1f3f5;
    position:static;
}

#workTr .dropdown ul ul>li:hover {
	cursor: pointer;
	opacity: 0.9;
	font-weight: bold;
}

#workTr .dropdown .allTypes em { display:inline-block; padding:0 10px; background:#f1f3f5; line-height: 25px;text-align:center; border-radius: 3px; font-style:normal; margin-right:10px;font-weight:bold; }

#workTr .dropdown ul ul>li:hover>a {
	font-weight: bold;
}

#workTr .dropdown ul ul>li.on {
	font-weight: bold;
	opacity: 1;
}

#workTr .dropdown ul ul>li.on:hover {
	cursor: default;
}

#workTr .dropdown ul ul>li.on p {
	opacity: 1;
}
#workTr .dropdown ul>li a {border-bottom:none!important }
#workTr .dropdown ul ul>li {
	opacity: 0.6;
	position: relative;
	line-height: 55px;
	padding: 0 135px 0 15px;
	box-sizing: border-box;
	border-bottom: 1px solid #f1f3f5;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

#workTr .dropdown ul ul>li p {
	position: absolute;
	right: 15px;
	top: -3px;
	height: 10px;
}

#workTr .dropdown ul ul>li p span {
	display: inline-block;
	width: 10px;
	height: 10px;
	background: #477fff;
	border-radius: 50%;
	margin-left: 10px;
}

#workTr .dropdown ul ul>li p span.line {
	position: absolute;
	width: 85%;
	top: 30px;
	margin-left: 10px;
	border-radius: 0;
	height: 2px;
	background: #f1f3f5;
	z-index: -1;
}

#workTr .dropdown>ul>li.plusWork {
	position: absolute;
	background: url(../images/contents/ic_add.png) no-repeat
		center center;
	font-size: 0;
	background-size: 42px;
	right: 0;
	width: 60px !important;
	z-index: 10000000;
	border-left: 1px solid #f1f4f5;
}
#workTr .dropdown>ul>li.plusWork:hover { background:#477fff; }
#workTr .dropdown>ul>li.plusWork a {
	display: inline-block;
	width: 60px !important;
	height: 60px !important;padding:0 !important; border-bottom:0 none;
}

#workTr .dropdown>ul>li.plusWork a:hover {

	background:  url(../images/contents/btn_add_fff.png) no-repeat
		center center; background-size:40px;
}

tr#workTr .dropdown p {
	line-height: 60px;
	font-size: 14px;
	font-weight: bold;
}

tr#workTr .dropdown>ul>li a:hover p {
	color: #477fff;
}

tr#workTr .dropdown>ul>li a:hover span {
	color: #477fff;
}

tr#workTr .dropdown>ul>li:hover {
	border-bottom: 2px solid #477fff;
}

tr#workTr .dropdown>ul>li.on ul {
	display: block;
}

/* 일정등록 textarea 크기변경 */
#schDetailScroll textarea {
	width: 100% !important;
	max-width: 100% !important;
	box-sizing: border-box;
}

/* 그룹선택 보더 */
#grupTr td .selectboxWrap {
	border: 0 none;
	height: auto;
	width: 100%;
}

#grupTr td .selectboxWrap label {
	left: 0;
	font-weight: bold;
	line-height: 55px;
}
/* 색상선택 변경사항 */
#colorDiv {
	display: none;
}

#colorDiv.on {
	display: block !important;
}

.writeSche #colorDiv {
	width: 100%;
	box-sizing: border-box;
	top: 71px;
}

@media ( max-width : 1300px) {
	.tableDefault.tableBorder table tbody tr th {
		padding-right: 40px;
	}
	.tableDefault table th label {
		padding-right: 0 !important;
	}
}

textarea::placeholder { color:#acb2bf }