@media print {
    html { height : 100vh !important; box-sizing: border-box; padding : 0; margin : 0; background: #fff; }
    body { height : 100vh !important; box-sizing: border-box; padding : 0; margin : 0; background: #fff; }
    #ui-datepicker-div { display : none; }

    #header { display : none; }
    #left_area { display : none; } 
    #right_area_controller { display : none; }
    #btnAddSchedule { display: none; } 
    #section_ce { left : 0px !important; right : 0px !important; }
    #fc-userToolbox { display : none; }
    #fc-prnt-title-div { display: block !important; }

    /* 전체 높이 결정 컨테이너 */
    .fc-body .fc-day-grid-container { height : 165mm !important; border-left: solid 1px #e7e9ea; border-bottom: solid 1px #e7e9ea; }
    
    /* 주단위 높이 결정 컨테이너 */
    .fc-day-grid-container .fc-week.fc-widget-content.row-6 { height : 27.5mm !important; }
    .fc-day-grid-container .fc-week.fc-widget-content.row-5 { height : 33mm !important; }
    .fc-content-skeleton { overflow: hidden; }

    /* 일정 하나의 높이 */
    /* .fc-day-grid-container .fc-week .fc-content-skeleton .fc-day-grid-event.fc-draggable { line-height: 0 !important; margin : 0 !important; } */
    /* .fc-day-grid-container .fc-week .fc-content-skeleton .fc-day-grid-event.fc-draggable .fc-title { font-size: 0px; } */
    /* .fc-day-grid-container .fc-week .fc-content-skeleton a.fc-day-grid-event.fc-resizable { line-height: 0 !important; margin : 0 !important; } */
    /* .fc-day-grid-container .fc-week .fc-content-skeleton a.fc-day-grid-event.fc-resizable .fc-title { font-size: 0px; } */

    /* 더 보기 */
    .fc-day-grid-container .fc-week .fc-content-skeleton .fc-more-cell .fc-more { border: none !important; font-size: 0px !important; margin : 0 !important; }
    .fc-toolbar {float: left;}

    /* 주간 달력 인쇄는 기본 달력 숨기기 */
    #app.timeGridWeek, .section_ce_fix.cal.timeGridWeek { display : none !important; }

    /* 주간달력 렌더링 */
    #weekly-calendar.timeGridWeek { display : block !important; background : #fff; position: absolute; z-index: 10000; top : 0; left : 0; }
    
    .update-notification { display: none; }

    @page {
        size: landscape; 
        margin-top: 0; 
        margin-bottom: 0; 
    }
}