<template>

    <div class="section_ce_fix grpPage" :class="`${(biz_id != null && biz_id.length > 0) ? `pr_${hodu_color_dc_lc(group_team_color)}` : ''}`">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box grpDetailTitle">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">
                <!-- 그룹명 표시 -->
                {{group_team_name}}
            </h3>
            <p class="cl" :style="{ backgroundColor: `${group_team_color} !important` }" @click="groupTeamColorPickOnOff" title="개인색상 변경">색상</p>
            <!-- ************* 색상 선택시 ul 클래스 추가 (on) -->
            <ul id="grpTeamUlDiv" class="grpTeam_clr_ul" :class="{ on : group_team_color_pick == true }" style="cursor : default;" @click.prevent="(event) => {
                event.stopPropagation();
                event.preventDefault();
            }">
                <li>
                    <a href="#" class="dc0" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#FF6363' }"
                        @click="groupTeamColorChange($event, '#FF6363')">연어</a>
                </li>
                <li>
                    <a href="#" class="dc1" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#FFA70E' }"
                        @click="groupTeamColorChange($event, '#FFA70E')">주황</a>
                </li>
                <li>
                    <a href="#" class="dc2" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#FFC72F' }"
                        @click="groupTeamColorChange($event, '#FFC72F')">노랑</a>
                </li>
                <li>
                    <a href="#" class="dc3" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#FF198B' }"
                        @click="groupTeamColorChange($event, '#FF198B')">자주</a>
                </li>
                <li>
                    <a href="#" class="dc4" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#00B2C7' }"
                        @click="groupTeamColorChange($event, '#00B2C7')">청록</a>
                </li>
                <li>
                    <a href="#" class="dc5" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#13D08B' }"
                        @click="groupTeamColorChange($event, '#13D08B')">초록</a>
                </li>
                <li>
                    <a href="#" class="dc6" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#4DBAFF' }"
                        @click="groupTeamColorChange($event, '#4DBAFF')">하늘</a>
                </li>
                <li>
                    <a href="#" class="dc7" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#477FFF' }"
                        @click="groupTeamColorChange($event, '#477FFF')">파랑</a>
                </li>
                <li>
                    <a href="#" class="dc8" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#6854FF' }"
                        @click="groupTeamColorChange($event, '#6854FF')">보라</a>
                </li>
                <li>
                    <a href="#" class="dc9" :class="{ on : hodu_hex_color_process(group_team_color).toUpperCase() == '#35405A' }"
                        @click="groupTeamColorChange($event, '#35405A')">검정</a>
                </li>
            </ul>
        </div>

        <div id="grpScroll" class="section_scroll"><!--스크롤 생겨야할 영역-->
            <div class="viewGroup">					
                <div class="schedule_box">
                    <div class="grp settingBg grpDetailBox ">
                        <!-- <h3 class="selectTitle"><span class="txt">{{group_team_name}}</span></h3> -->
                        <!--
                        <ul class="dropdown">
                            <li><a class="on">기획 그룹<span></span></a></li>
                            <li><a>디자인 그룹<span></span></a></li>
                            <li><a>모바일 그룹<span></span></a></li>
                            <li><a>해외영업 그룹<span></span></a></li>
                        </ul>
                        -->
                    </div>
<!-- 22.03 수정 -->
                    <div class="content grpDetailContent" id="">
                        <div class="main hc"> 
                            <div class="first">
                               <div class="firstCon first_top" :class="{ on : group_team_list_open == true }">
                                   <!-- 그룹 이미지 및 수정 -->
                                    <div class="grp1" @click.prevent="group_team_modify" :title="is_modify ? '수정하기' : ''" :class="{ 
                                    hcList : isHoduC(data ? data.group_type : '') == true,
                                    hdList : isHoduD(data ? data.group_type : '') == true,
                                    is_modify : is_modify == true
                                }">
                                        <!-- ************** 프리미엄 태그 -->
                                        <p class="premiumTag" v-if="(biz_id != null && biz_id.length > 0)">HODU C</p>
                                        <p class="img" :class="`img${group_team_image ? '' : group_color_to_class_name(group_team_color)}`" :style="{ backgroundImage : `url(${group_team_image ? 'app_images/' + group_team_image : ''})` }">그룹 이미지</p>
                                        <img class="dummy" :src="`${group_team_image ? 'app_images/' + group_team_image : ''}`" @error="groupImageError($event)" />
                                    </div>

                                    <!-- 유저 정보, 그룹설정, 공지-->
                                    <div class="userDets">
                                        <ul>
                                            <li class="userList">
                                                <!-- 관리자 facePic 클래스 king, 부관리자 manager 추가,  -->
                                                <img class="dummy" :src="`app_images/profile/user/${Math.floor(user_id / 10000)}/${user_id}.jpg`" @error="userImageError($event)"/>
                                                <p class="facePic" :class="{ admin: is_admin, king: (is_manager || is_admin), manager: (is_sub_manager)}" :style="{ backgroundImage : `url(app_images/profile/user/${Math.floor(user_id / 10000)}/${user_id}.jpg)` }">사진</p>
                                                <p class="title1">
                                                    <span class="first1">{{ user_name }}
                                                        <em>{{(team_id > 0 ? (is_admin ? makeWord().admin : is_manager ? makeWord().team_manager : makeWord().team_user) 
                                                                            : (is_admin ? makeWord().admin : (is_manager ? makeWord().group_manager : (is_sub_manager ? makeWord().group_sub_manager : makeWord().group_user))))}}
                                                            {{ data != null && data.pos_name != null && data.pos_name.length > 0 ? `/ ${data.pos_name}` : '' }}
                                                        </em>
                                                    </span>
                                                    <span class="first1">{{ makeWord().member }}
                                                        <em class="second2">{{ data != null ? (isHoduKids() ? data.member_user_count 
                                                                                        : ((isHoduH(data.group_type) && team_id == 0) ? data.member_user_count : data.member_count)) : '' }}명</em>
                                                    </span>
                                                </p>
                                                <!-- 호두C 버튼 위치 변경 -->
                                                <a class="hoduC" @click.prevent="groupTeamCalendar($event)" v-if="biz_id && !isCurrentCalendarGroupOrTeam()" title="프리미엄 달력으로 이동"><span class="ab">프리미엄 달력 이동</span></a>
                                                <a class="hoduC" @click.prevent="groupTeamCalendar($event)" v-if="biz_id && isCurrentCalendarGroupOrTeam()" title="프리미엄 달력으로 이동"><span class="ab">달력 이동</span></a>

                                                <!-- 비즈로 변경 -->
                                                <!-- <a class="create hoduC_btn" v-if="is_group_change" @click="biz_group_change()"><span class="ab">{{(!biz_id ? '프리미엄으로 전환' : '일반그룹 변경')}}</span></a> -->
                                                <a class="create hoduC_btn" v-if="is_group_change && !biz_id" @click.prevent="biz_group_change($event)" title="프리미엄 그룹 신청"><span class="ab">프리미엄 신청</span></a>
                                            </li>
                                            <li class="memberList">
                                                <p class="title1 menTseeBtn" v-if="data != null && isHoduH(data.group_type) == false">
                                                    <a href="#" @click.prevent="groupTeamListOnOff">팀 보기</a>
                                                </p>
                                                <p class="title1 memT_last">
                                                    <span class="first1">{{ makeWord().group }} 이동 옵션</span>
                                                        <!-- <h3>그룹이동 옵션</h3> -->
                                                        <input type="radio" id="groupDash" name="groupmove" value="DASHBOARD" v-model="move_option" @change="setDashBoardMoveOptionPreference">
                                                        <label for="groupDash" class="groupM_radio">대시보드</label>
                                                        <input type="radio" id="groupCalen" name="groupmove" value="CALENDAR" v-model="move_option" @change="setDashBoardMoveOptionPreference">
                                                        <label for="groupCalen" class="groupM_radio calen_radio">달력</label>
                                                    <!-- <a class="second2">사이트 이동</a> -->
                                                </p>
                                            </li>
                                            <!-- <li class="noticeList">
                                                <p class="title grp_T02" @click.prevent="goGroupNotice"><span class="icon" :style="{ backgroundColor : group_team_color }">아이콘</span>공지사항</p>
                                                <p class="noone mt10" v-if="notice_list.length < 1">공지사항이 없습니다</p>
                                                <ul class="grp3_list_con grp3L_con02">
                                                    <li class="grp3_con g3c02" :class="{ seeOpacity : notice.read_flag == true }" :key="notice.noti_uid" v-for="notice in notice_list">
                                                        <p class="grp3_con_title">
                                                            <a href="#" @click.prevent="moveNotice(notice)">
                                                                {{ notice.noti_info.title }}
                                                            </a>
                                                            <span class="time" @click.prevent="moveNotice(notice)">
                                                                {{ 
                                                                    hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(notice.noti_info.start_date, 'YYYYMMDD') 
                                                                        ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(notice.noti_info.start_date, 'hh:mm a'))}`
                                                                        : `${hodu_date_to_format_string(notice.noti_info.start_date, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(notice.noti_info.start_date)}`
                                                                }}
                                                            </span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </li> -->

                                            <!-- 23.04.11 게시판 추가 tab -->
                                            <li class="noticeList">
                                                <div class="dashTabNav">
                                                    <p class="dashTabList title grp_T02" :class="{ on : notice_board_tab == 'GROUP_NOTICE' }">
                                                        <a href="#" @click.prevent="() => { notice_board_tab = 'GROUP_NOTICE' }">공지사항</a>
                                                    </p>
                                                    <p class="dashTabList title" :class="{ on : notice_board_tab == 'GROUP_BOARD' }">
                                                        <a href="#" @click.prevent="() => { notice_board_tab = 'GROUP_BOARD' }">게시판</a>
                                                    </p>
                                                </div>
                                                <div class="dashTabCon">
                                                    
                                                    <!-- 공지사항 -->
                                                    <ul class="grp3_list_con grp3L_con02 grp3_notice_list" :class="{ on : notice_board_tab == 'GROUP_NOTICE' }">

                                                        <p class="noone mt10" v-if="notice_list.length < 1">공지사항이 없습니다</p>

                                                        <li class="grp3_con g3c02" :class="{ seeOpacity : notice.read_flag == true }" :key="notice.noti_uid" v-for="notice in notice_list">
                                                            <p class="grp3_con_title">
                                                                <a href="#" @click.prevent="moveNotice(notice)">
                                                                    {{ notice.noti_info.title }}
                                                                </a>
                                                                <span class="time" @click.prevent="moveNotice(notice)">
                                                                    {{ 
                                                                        hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(notice.noti_info.start_date, 'YYYYMMDD') 
                                                                            ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(notice.noti_info.start_date, 'hh:mm a'))}`
                                                                            : `${hodu_date_to_format_string(notice.noti_info.start_date, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(notice.noti_info.start_date)}`
                                                                    }}
                                                                </span>
                                                            </p>
                                                        </li>
                                                    </ul>

                                                    <!-- 게시판 -->
                                                    <ul class="grp3_list_con grp3L_con02 grp3_board_list" :class="{ on : notice_board_tab == 'GROUP_BOARD' }">

                                                        <p class="noone mt10" v-if="board_list.length < 1">게시글이 없습니다</p>

                                                        <li class="grp3_con g3c02" :class="{ seeOpacity : post.read_flag == true }" :key="post.board_id" v-for="post in board_list">
                                                            <p class="grp3_con_title">
                                                                <a href="#" @click.prevent="moveGroupBoardDetail(post)">{{ post.board_info.title }}</a>
                                                                <span class="time">{{ hodu_date_to_format_string(post.audit_created, 'YYYY.MM.DD')  }} {{ getDayOfWeekByDate(post.audit_created) }}</span>
                                                            </p>
                                                        </li>

                                                        <!-- <li class="grp3_con g3c02">
                                                            <p class="grp3_con_title">
                                                                <a href="#">도시락 공구하실분~? 댓글 달아주세요</a>
                                                                <span class="time">2023.03.13 월</span>
                                                            </p>
                                                        </li>
                                                        <li class="grp3_con g3c02 seeOpacity">
                                                            <p class="grp3_con_title">
                                                                <a href="#">3월 올림픽공원 행사 일정 참고해서 놀러가요!</a>
                                                                <span class="time">2023.03.06 월</span>
                                                            </p>
                                                        </li>
                                                        <li class="grp3_con g3c02 seeOpacity">
                                                            <p class="grp3_con_title">
                                                                <a href="#">광고대행사 업체 개인적으로 알고 있는데 있는분?</a>
                                                                <span class="time">2023.03.02 목</span>
                                                            </p>
                                                        </li> -->
                                                    </ul>
                                                    
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <!-- 그룹 & 팀 -->
                                    <GroupTeamList :group_id="group_id" :team_id="team_id" :data="data" v-on:init="init" :event_bus="event_bus" @makeWord="makeWord" ref="group_team_list" v-if="data != null && isHoduH(data.group_type) == false"/>
                                    <div style="height : 0px; border-top : 1px solid #e7e9ea;" v-else/>
                               </div>

                               <div class="firstCon first_under grp3_under">
                                   <ul class="grp3_box">
                                        <!-- 2단일때 클래스 grp3_list_two 추가 -->
                                        <li class="grp3_list">
                                            <p class="title grp_T01" style="cursor : default;"><span class="icon" :style="{ backgroundColor : group_team_color }">아이콘</span>오늘의 일정</p>
                                            <p class="noone mt10" v-if="events.length < 1">오늘의 일정이 없습니다</p>
                                            <div id="group_detail_today_event_scroll">
                                                <ul class="grp3_list_con grp3L_con01" :class="{ lastPadding : events.length > 3 }">
                                                    <li class="grp3_con g3c01 mt10" :key="event.event_id" v-for="event in events">
                                                        <span class="g3c01_bar" :style="{ backgroundColor : `${hodu_hex_color_process(event.event_data.color)} !important` }"></span>
                                                        <p class="grp3_con_title">
                                                            <a href="#" @click.prevent="moveEvent(event)">
                                                                {{ event.event_data.title }}
                                                                <span class="time">{{ getEventDateText(event) }}</span>
                                                            </a>
                                                        </p>
                                                    </li>
                                                    <!-- <li class="grp3_con g3c01 mt10">
                                                        <span class="g3c01_bar">사용자 지정한 색상</span>
                                                        <p class="grp3_con_title"><a href="#">호두웨어 전 직원 미팅 <span class="time">04:00pm ~ 05:00pm</span></a></p>
                                                    </li>
                                                    <li class="grp3_con g3c01 mt10">
                                                        <span class="g3c01_bar">사용자 지정한 색상</span>
                                                        <p class="grp3_con_title"><a href="#">각 부서 팀장 전체 미팅 <span class="time">02:00pm ~ 02:30pm</span></a></p>
                                                    </li>
                                                    <li class="grp3_con g3c01 mt10">
                                                        <span class="g3c01_bar">사용자 지정한 색상</span>
                                                        <p class="grp3_con_title"><a href="#">2021년 3월 행사 관련 미팅 <span class="time">10:00am ~ 11:00am</span></a></p>
                                                    </li> -->
                                                </ul>
                                            </div>
                                        </li>
                                        <!-- 2단일때 클래스 grp3_list_two 추가 -->
                                        <li class="grp3_list">
                                            <p class="title grp_T02_new"><span class="icon" :style="{ backgroundColor : group_team_color }">아이콘</span>댓글</p>
                                            <p class="noone mt10" v-if="reply_notification_list.length < 1">댓글이 없습니다</p>
                                            <div id="dashboard_reply_notification_scroll">
                                                <ul class="grp3_list_con grp3L_con02_new">
                                                    <li class="grp3_con g3c02 mt10" :class="{ seeOpacity : notification.read_flag == true }" :key="notification.noti_uid" v-for="notification in reply_notification_list">
                                                        <p class="grp3_con_title">
                                                            <a href="#" @click.prevent="notificationClick(notification)">
                                                                {{ notification.noti_data.body }}
                                                                <span class="time">
                                                                    {{ 
                                                                        hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(notification.audit_created, 'YYYYMMDD') 
                                                                            ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(notification.audit_created, 'hh:mm a'))}`
                                                                            : `${hodu_date_to_format_string(notification.audit_created, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(notification.audit_created)}`
                                                                    }}
                                                                </span>
                                                            </a>
                                                        </p>
                                                    </li>
                                                    <!-- <li class="grp3_con g3c02 mt10">
                                                        <p class="grp3_con_title">
                                                            <a href="#">
                                                                김호두 님의 주간업무 일정에 댓글이 등록 되었습니다
                                                                <span class="time">
                                                                    2022.03.30 수
                                                                </span>
                                                            </a>
                                                        </p>
                                                    </li>
                                                    <li class="grp3_con g3c02 mt10">
                                                        <p class="grp3_con_title">
                                                            <a href="#">
                                                                마케팅 김팀장 님의 일정에 댓글이 등록 되었습니다
                                                                <span class="time">
                                                                    2022.03.29 화
                                                                </span>
                                                            </a>
                                                        </p>
                                                    </li>
                                                    <li class="grp3_con g3c02 mt10">
                                                        <p class="grp3_con_title">
                                                            <a href="#">
                                                                김호두 님의 주간업무 일정에 댓글이 등록 되었습니다
                                                                <span class="time">
                                                                    2022.03.29 화
                                                                </span>
                                                            </a>
                                                        </p>
                                                    </li> -->
                                                    <!-- <li class="grp3_con g3c02 mt10">
                                                        <p class="grp3_con_title"><a href="#">코로나 장기화로 인한 필수 인원을 제외한 모든 직원은 자택근무 실시 <span class="time">오늘 10:12am</span></a></p>
                                                    </li>
                                                    <li class="grp3_con g3c02 mt10">
                                                        <p class="grp3_con_title"><a href="#">회의실 프로젝터 사용시 주의사항 <span class="time">오늘 10:12am</span></a></p>
                                                    </li>
                                                    <li class="grp3_con g3c02 mt10 seeOpacity">
                                                        <p class="grp3_con_title"><a href="#">건물 계단에서 흡연시 벌금형 <span class="time">2021.01.23 월</span></a></p>
                                                    </li> -->
                                                </ul>
                                            </div>
                                        </li>

                                        <!-- 2단일때 클래스 grp3_LT_none 추가하여 숨기기-->
                                        <li class="grp3_list">
                                            <!-- 알림,메세지( 1칸일때 div클래스 grp3_last 사용, grp3_last_none 추가하여 숨김 ) / 알림,메세지( 2칸일때 클래스 grp3_last_two 추가 ) -->
                                            <div class="notification" :class="{ grp3_last : menu_option == 'NOTIFICATION', grp3_last_none : menu_option == 'MESSAGE', grp3_last_two : menu_option == 'NOTIFICATION_AND_MESSAGE' }">
                                                <ul>
                                                    <li class="title grp_T03" @click.prevent="() => {}" style="cursor : default;">
                                                        <span class="icon" :style="{ backgroundColor : group_team_color }">아이콘</span>알림
                                                        <!-- 알림 또는 알림/메세지 일때 -->
                                                        <select class="lt_option" v-model="menu_option" @change="setDashBoardMenuOptionPreference">
                                                            <option value="NOTIFICATION">알림</option>
                                                            <option value="MESSAGE">메세지</option>
                                                            <option value="NOTIFICATION_AND_MESSAGE">알림 &#47; 메세지</option>
                                                        </select>
                                                    </li>
                                                </ul>
                                                <p class="noone mt10" v-if="notification_list.length < 1">알림이 없습니다</p>

                                                <div id="dashboard_notification_scroll">

                                                    <ul class="grp3_list_con grp3L_con03">
                                                        <li class="grp3_con g3c03 mt10" :class="{ seeOpacity : notification.read_flag == true }" :key="notification.noti_uid" v-for="notification in notification_list">
                                                            <p class="grp3_con_title">
                                                                <a href="#" @click.prevent="notificationClick(notification)">
                                                                    {{ notification.noti_data.body }}
                                                                    <span class="time">
                                                                        {{ 
                                                                            hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(notification.audit_created, 'YYYYMMDD') 
                                                                                ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(notification.audit_created, 'hh:mm a'))}`
                                                                                : `${hodu_date_to_format_string(notification.audit_created, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(notification.audit_created)}`
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </p>
                                                        </li>
                                                        <!-- <li class="grp3_con g3c03 mt10">
                                                            <p class="grp3_con_title">
                                                                <a href="#">
                                                                    김성림님이 일정을 공유하였습니다.
                                                                    <span class="time">
                                                                        오늘 11:15am
                                                                    </span>
                                                                </a>
                                                            </p>
                                                        </li>
                                                        <li class="grp3_con g3c03 mt10">
                                                            <p class="grp3_con_title">
                                                                <a href="#">
                                                                    새로운 공지사항이 올라왔습니다.
                                                                    <span class="time">
                                                                        2021.02.04 목
                                                                    </span>
                                                                </a>
                                                            </p>
                                                        </li> -->
                                                    </ul>

                                                </div>
                                            </div>
                                            
                                            <!-- 알림( 1칸일때 div클래스 grp3_last 사용, grp3_last_none 추가하여 숨김 ) /  알림,메세지( 2칸일때 클래스 grp3_last_two 추가, grp3_last_none 클래스 삭제 ) -->
                                            <div class="message" :class="{ grp3_last : menu_option == 'MESSAGE', grp3_last_none : menu_option == 'NOTIFICATION', grp3_last_two : menu_option == 'NOTIFICATION_AND_MESSAGE' }">
                                                
                                                <ul>
                                                    <li class="title grp_T03_2" @click.prevent="() => {}" style="cursor : default;">
                                                        <span class="icon" :style="{ backgroundColor : group_team_color }">아이콘</span>메세지
                                                        <!-- 메세지로 표시했을때 -->
                                                        <select class="lt_option" v-model="menu_option" v-if="menu_option == 'MESSAGE'" @change="setDashBoardMenuOptionPreference">
                                                            <option value="NOTIFICATION">알림</option>
                                                            <option value="MESSAGE">메세지</option>
                                                            <option value="NOTIFICATION_AND_MESSAGE">알림 &#47; 메세지</option>
                                                        </select>
                                                    </li>
                                                </ul>

                                                <p class="noone mt10" v-if="message_list.length < 1">메세지가 없습니다</p>

                                                <div id="dashboard_message_scroll">

                                                    <ul class="grp3_list_con grp3L_con03">
                                                        <li class="grp3_con g3c03 mt10" :key="message.noti_uid" v-for="message in message_list">
                                                            <p class="grp3_con_title">
                                                                <a href="#" @click.prevent="() => {}" style="cursor : default;">
                                                                    {{ message.noti_data.body.replace(/#{이메일}/ig, user_email).replace(/#{이름}/ig, user_name) }}
                                                                    <span class="time">
                                                                        {{ 
                                                                            hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(message.audit_created, 'YYYYMMDD') 
                                                                                ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(message.audit_created, 'hh:mm a'))}`
                                                                                : `${hodu_date_to_format_string(message.audit_created, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(message.audit_created)}`
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </p>
                                                        </li>
                                                        <!-- <li class="grp3_con g3c03 mt10">
                                                            <p class="grp3_con_title"><a href="#">안녕하세요? 김효진님의 출입증이 새로 발급 되었습니다. 바로 사용 가능합니다. <span class="time">2021.02.09 화</span></a></p>
                                                        </li> -->
                                                    </ul>
                                                
                                                </div>

                                            </div>

                                        </li>
                                    </ul>
                               </div>
                            </div>
                            
                            <div class="grp2">
                                    <input type="button" class="bigger" value="크게보기" />
                                    <!-- 최근 공지사항, 알림, 댓글, 일정, 업무, 가입요청 등을 위한 div -->
                                    <!-- <GroupNotice :group_id="group_id" :team_id="team_id" ref="group_notice" v-on:increment="goNoticeDetail"/> -->
                                    <div class="tabDiv">
                                        <ul class="tabUl">

                                            <li v-if="data != null && isHoduKids()          == false                                           "><a href="#" :class="{ on : tab == 'STATISTICS'      }" @click.prevent="() => { tab = 'STATISTICS';      }">최근 데이터</a></li>
                                            <li v-if="data != null && isHoduKids()          == false                                           "><a href="#" :class="{ on : tab == 'MEMBER'          }" @click.prevent="() => { tab = 'MEMBER';          }">멤버</a></li>
                                            <li v-if="data != null && isApprovalEnabled()   == true                                            "><a href="#" :class="{ on : tab == 'APPROVAL'        }" @click.prevent="() => { tab = 'APPROVAL';        }">전자결재</a></li>
                                            <li v-if="data != null && isWorkEnabled()       == true                                            "><a href="#" :class="{ on : tab == 'WORK'            }" @click.prevent="() => { tab = 'WORK';            }">업무</a></li>
                                            <li v-if="data != null && isAttendanceEnabled() == true                                            "><a href="#" :class="{ on : tab == 'ATTENDANCE'      }" @click.prevent="() => { tab = 'ATTENDANCE';      }">근태현황</a></li>
                                            <li v-if="data != null && isHoduKids()          == false                                           "><a href="#" :class="{ on : tab == 'QUICKMENU'       }" @click.prevent="() => { tab = 'QUICKMENU';       }">간편메뉴</a></li>
                                            <li v-if="data != null && isHoduKids()          == true                                            "><a href="#" :class="{ on : tab == 'KIDS_ATTENDANCE' }" @click.prevent="() => { tab = 'KIDS_ATTENDANCE'; }">출석부</a></li>
                                            <li v-if="data != null && isHoduKids()          == true && get_group_role(group_id) != 'GROUP_USER'"><a href="#" :class="{ on : tab == 'KIDS'            }" @click.prevent="() => { tab = 'KIDS';            }">원아</a></li>
                                            <li v-if="data != null && isHoduKids()          == true                                            "><a href="#" :class="{ on : tab == 'KIDS_AUTH'       }" @click.prevent="() => { tab = 'KIDS_AUTH';       }">평가인증문서관리</a></li>
                                            
                                            <li class="air_pollution" v-if="data != null && isHoduKids() == true">
                                                
                                                <div class="air_list">
                                                    <ul>
                                                        <!-- 클래스별 아이콘
                                                            ic_good 좋음 / ic_generally 보통 / ic_bad 나쁨 / ic_verybad 매우나쁨 / ic_error 에러
                                                        -->
                                                        <li class="air_dust" :class="{
                                                            ic_good : air_data && air_data.pm10_level == '5',
                                                            ic_generally : air_data && air_data.pm10_level == '4',
                                                            ic_bad : air_data && air_data.pm10_level == '3',
                                                            ic_verybad : air_data && air_data.pm10_level == '2',
                                                            ic_error : air_data == null || air_data.pm10_level == null || air_data.pm10_level == '1'
                                                        }">
                                                            미세먼지
                                                            <div class="airTool_box">
                                                                <!-- 클래스별 색상 
                                                                    goodText 좋음 / generallyText 보통 / badText 나쁨 / verybadText 매우나쁨 / errorText 에러
                                                                -->
                                                                <p class="dust_text" :class="{
                                                                    goodText : air_data && air_data.pm10_level == '5',
                                                                    generallyText : air_data && air_data.pm10_level == '4',
                                                                    badText : air_data && air_data.pm10_level == '3',
                                                                    verybadText : air_data && air_data.pm10_level == '2',
                                                                    errorText : air_data == null || air_data.pm10_level == null || air_data.pm10_level == '1'
                                                                }">
                                                                    <strong>미세먼지</strong>
                                                                    {{ getAirStatusTextBylevel(air_data && air_data.pm10_level ? air_data.pm10_level : '') }} {{ air_data && air_data.pm10 ? `${air_data.pm10}㎍/㎥` : '측정불가' }}
                                                                    <!-- 클래스별 색상 
                                                                        dust_good 좋음 / dust_generally 보통 / dust_bad 나쁨 / dust_verybad 매우나쁨 / dust_error 에러
                                                                    -->
                                                                    <span class="dust ir_pm">오염농도
                                                                        <span class="dust ir_pm" :class="{
                                                                            dust_good : air_data && air_data.pm10_level == '5',
                                                                            dust_generally : air_data && air_data.pm10_level == '4',
                                                                            dust_bad : air_data && air_data.pm10_level == '3',
                                                                            dust_verybad : air_data && air_data.pm10_level == '2',
                                                                            dust_error : air_data == null || air_data.pm10_level == null || air_data.pm10_level == '1'
                                                                        }" :style="{ width : `${getAirPm10WidthPercent()}% !important`}">오염농도 값</span>
                                                                    </span>
                                                                </p>
                                                                <p class="standard_text">30</p>
                                                                <p class="standard_text">80</p>
                                                                <p class="standard_text">150</p>
                                                                <p class="standard_text"></p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div class="air_list">
                                                    <ul>
                                                        <!-- 클래스별 아이콘
                                                            ic_good 좋음 / ic_generally 보통 / ic_bad 나쁨 / ic_verybad 매우나쁨 / ic_error 에러
                                                        -->
                                                        <li class="air_smalldust" :class="{
                                                            ic_good : air_data && air_data.pm2_5_level == '5',
                                                            ic_generally : air_data && air_data.pm2_5_level == '4',
                                                            ic_bad : air_data && air_data.pm2_5_level == '3',
                                                            ic_verybad : air_data && air_data.pm2_5_level == '2',
                                                            ic_error : air_data == null || air_data.pm2_5_level == null || air_data.pm2_5_level == '1'
                                                        }">
                                                            초미세먼지
                                                            <div class="airTool_box">
                                                                <!-- 클래스별 색상 
                                                                    goodText 좋음 / generallyText 보통 / badText 나쁨 / verybadText 매우나쁨 / errorText 에러
                                                                -->
                                                                <p class="dust_text" :class="{
                                                                    goodText : air_data && air_data.pm2_5_level == '5',
                                                                    generallyText : air_data && air_data.pm2_5_level == '4',
                                                                    badText : air_data && air_data.pm2_5_level == '3',
                                                                    verybadText : air_data && air_data.pm2_5_level == '2',
                                                                    errorText : air_data == null || air_data.pm2_5_level == null || air_data.pm2_5_level == '1'
                                                                }">
                                                                    <strong>초미세먼지</strong>
                                                                    {{ getAirStatusTextBylevel(air_data && air_data.pm2_5_level ? air_data.pm2_5_level : '') }} {{ air_data && air_data.pm2_5 ? `${air_data.pm2_5}㎍/㎥` : '측정불가' }}
                                                                    <!-- 클래스별 색상 
                                                                        dust_good 좋음 / dust_generally 보통 / dust_bad 나쁨 / dust_verybad 매우나쁨 / dust_error 에러
                                                                    -->
                                                                    <span class="dust ir_pm">오염농도
                                                                        <span class="dust ir_pm" :class="{
                                                                            dust_good : air_data && air_data.pm2_5_level == '5',
                                                                            dust_generally : air_data && air_data.pm2_5_level == '4',
                                                                            dust_bad : air_data && air_data.pm2_5_level == '3',
                                                                            dust_verybad : air_data && air_data.pm2_5_level == '2',
                                                                            dust_error : air_data == null || air_data.pm2_5_level == null || air_data.pm2_5_level == '1'
                                                                        }" :style="{ width : `${getAirPm2_5WidthPercent()}% !important`}">오염농도 값</span>
                                                                    </span>
                                                                </p>
                                                                <p class="standard_text">15</p>
                                                                <p class="standard_text">35</p>
                                                                <p class="standard_text">75</p>
                                                                <p class="standard_text"></p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div class="air_list">
                                                    <ul>
                                                        <!-- 클래스별 아이콘
                                                            ic_good 좋음 / ic_generally 보통 / ic_bad 나쁨 / ic_verybad 매우나쁨 / ic_error 에러
                                                        -->
                                                        <li class="air_ppb" :class="{
                                                            ic_good : air_data && air_data.tvoc_level == '5',
                                                            ic_generally : air_data && air_data.tvoc_level == '4',
                                                            ic_bad : air_data && air_data.tvoc_level == '3',
                                                            ic_verybad : air_data && air_data.tvoc_level == '2',
                                                            ic_error : air_data == null || air_data.tvoc_level == null || air_data.tvoc_level == '1'
                                                        }">
                                                            화학물질
                                                            <div class="airTool_box">
                                                                <!-- 클래스별 색상 
                                                                    goodText 좋음 / generallyText 보통 / badText 나쁨 / verybadText 매우나쁨 / errorText 에러
                                                                -->
                                                                <p class="dust_text" :class="{
                                                                    goodText : air_data && air_data.tvoc_level == '5',
                                                                    generallyText : air_data && air_data.tvoc_level == '4',
                                                                    badText : air_data && air_data.tvoc_level == '3',
                                                                    verybadText : air_data && air_data.tvoc_level == '2',
                                                                    errorText : air_data == null || air_data.tvoc_level == null || air_data.tvoc_level == '1'
                                                                }">
                                                                    <strong>화학물질</strong>
                                                                    {{ getAirStatusTextBylevel(air_data && air_data.tvoc_level ? air_data.tvoc_level : '') }} {{ air_data && air_data.tvoc ? `${air_data.tvoc}ppb` : '측정불가' }}
                                                                    <!-- 클래스별 색상 
                                                                        dust_good 좋음 / dust_generally 보통 / dust_bad 나쁨 / dust_verybad 매우나쁨 / dust_error 에러
                                                                    -->
                                                                    <span class="dust ir_pm">오염농도
                                                                        <span class="dust ir_pm" :class="{
                                                                            dust_good : air_data && air_data.tvoc_level == '5',
                                                                            dust_generally : air_data && air_data.tvoc_level == '4',
                                                                            dust_bad : air_data && air_data.tvoc_level == '3',
                                                                            dust_verybad : air_data && air_data.tvoc_level == '2',
                                                                            dust_error : air_data == null || air_data.tvoc_level == null || air_data.tvoc_level == '1'
                                                                        }" :style="{ width : `${getAirTvocWidthPercent()}% !important`}">오염농도 값</span>
                                                                    </span>
                                                                </p>
                                                                <p class="standard_text">150</p>
                                                                <p class="standard_text">400</p>
                                                                <p class="standard_text">700</p>
                                                                <p class="standard_text"></p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div class="air_list">
                                                    <ul>
                                                        <!-- 클래스별 아이콘
                                                            ic_good 좋음 / ic_generally 보통 / ic_bad 나쁨 / ic_verybad 매우나쁨 / ic_error 에러
                                                        -->
                                                        <li class="air_ppm" :class="{
                                                            ic_good : air_data && air_data.co2_level == '5',
                                                            ic_generally : air_data && air_data.co2_level == '4',
                                                            ic_bad : air_data && air_data.co2_level == '3',
                                                            ic_verybad : air_data && air_data.co2_level == '2',
                                                            ic_error : air_data == null || air_data.co2_level == null || air_data.co2_level == '1'
                                                        }">
                                                            이산화탄소
                                                            <div class="airTool_box">
                                                                <!-- 클래스별 색상 
                                                                    goodText 좋음 / generallyText 보통 / badText 나쁨 / verybadText 매우나쁨 / errorText 에러
                                                                -->
                                                                <p class="dust_text" :class="{
                                                                    goodText : air_data && air_data.co2_level == '5',
                                                                    generallyText : air_data && air_data.co2_level == '4',
                                                                    badText : air_data && air_data.co2_level == '3',
                                                                    verybadText : air_data && air_data.co2_level == '2',
                                                                    errorText : air_data == null || air_data.co2_level == null || air_data.co2_level == '1'
                                                                }">
                                                                    <strong>이산화탄소</strong>
                                                                    {{ getAirStatusTextBylevel(air_data && air_data.co2_level ? air_data.co2_level : '') }} {{ air_data && air_data.co2 ? `${air_data.co2}ppm` : '측정불가' }}
                                                                    <!-- 클래스별 색상 
                                                                        dust_good 좋음 / dust_generally 보통 / dust_bad 나쁨 / dust_verybad 매우나쁨 / dust_error 에러
                                                                    -->
                                                                    <span class="dust ir_pm">오염농도
                                                                        <span class="dust ir_pm" :class="{
                                                                            dust_good : air_data && air_data.co2_level == '5',
                                                                            dust_generally : air_data && air_data.co2_level == '4',
                                                                            dust_bad : air_data && air_data.co2_level == '3',
                                                                            dust_verybad : air_data && air_data.co2_level == '2',
                                                                            dust_error : air_data == null || air_data.co2_level == null || air_data.co2_level == '1'
                                                                        }" :style="{ width : `${getAirCo2WidthPercent()}% !important`}">오염농도 값</span>
                                                                    </span>
                                                                </p>
                                                                <p class="standard_text">450</p>
                                                                <p class="standard_text">900</p>
                                                                <p class="standard_text">1500</p>
                                                                <p class="standard_text"></p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </li>

                                        </ul>
                                    </div>
                                    <!-- <GroupStatistics :group_id="group_id" :team_id="team_id" ref="group_statistics"/> -->
                                    <ul class="tabDivUl">
                                        <li class="tabStaticList" v-if="tab == 'STATISTICS'">
                                           <GroupStatistics :group_id="group_id" :team_id="team_id" ref="group_statistics"/> 
                                        </li>

                                        <li class="tabMemberList" v-if="tab == 'MEMBER'">
                                            <div :class="{ is_team : team_id > 0 }">
                                                <p class="title memberTitle" style="cursor : default;">
                                                    <span class="icon">아이콘</span>
                                                    {{ team_id > 0 ? makeWord().team_manager : makeWord().group_manager }}
                                                    <span class="num">{{ computedMemberManager.length }}</span>
                                                </p>
                                                <p class="noone" v-if="computedMemberManager.length < 1">
                                                    <!-- {{ team_id > 0 ? `${makeWord().team_manager}이` : `${makeWord().group_manager}가` }} 없습니다 -->
                                                    {{ team_id > 0 ? makeWord().team_manager_not_exist : makeWord().group_manager_not_exist }}
                                                </p>
                                                <div id="group_detail_manager_scroll">
                                                    <ul class="tabMemUl">
                                                        <li :class="{ not_pos : member.pos_info.pos_name == '' }" :key="member.user_id" v-for="member in computedMemberManager">
                                                            <img class="dummy" :src="`app_images/profile/user/${Math.floor(member.user_id / 10000)}/${member.user_id}.jpg`" @error="userImageError($event)"/>
                                                            <p class="memPic king" :class="{ admin : member.role == 'ADMIN' }" :style="{ backgroundImage : `url(app_images/profile/user/${Math.floor(member.user_id / 10000)}/${member.user_id}.jpg)` }">사진</p>
                                                            <p class="name">{{ member.user_info.user_name }} <span class="posi" v-if="member.pos_info.pos_name != null && member.pos_info.pos_name.length > 0">{{ member.pos_info.pos_name }}</span></p>
                                                        </li>
                                                        <!-- <li>
                                                            <p class="memPic king">사진</p>
                                                            <p class="name">김성림 <span class="posi">대표</span></p>
                                                        </li>
                                                        <li>
                                                            <p class="memPic king">사진</p>
                                                            <p class="name">김효진 <span class="posi">과장</span></p>
                                                        </li> -->
                                                    </ul>
                                                </div>
                                            </div>
                                            <div v-if="team_id < 1">
                                                <p class="title memberTitle" style="cursor : default;">
                                                    <span class="icon manager_ic">아이콘</span>
                                                    {{ makeWord().group_sub_manager }}
                                                    <span class="num gray">{{ computedMemberSubManager.length }}</span>
                                                </p>
                                                <p class="noone" v-if="computedMemberSubManager.length < 1">
                                                    <!-- {{ data != null && data.group_type == 'BIZH' ? `${makeWord().group_sub_manager} 근무자가` : `${makeWord().group_sub_manager}가` }} 없습니다 -->
                                                    {{ makeWord().group_sub_manager_not_exist }}
                                                </p>
                                                <div id="group_detail_sub_manager_scroll">
                                                    <ul class="tabMemUl">
                                                        <li :class="{ not_pos : member.pos_info.pos_name == '' }" :key="member.user_id" v-for="member in computedMemberSubManager">
                                                            <img class="dummy" :src="`app_images/profile/user/${Math.floor(member.user_id / 10000)}/${member.user_id}.jpg`" @error="userImageError($event)"/>
                                                            <p class="memPic manager" :style="{ backgroundImage : `url(app_images/profile/user/${Math.floor(member.user_id / 10000)}/${member.user_id}.jpg)` }">사진</p>
                                                            <p class="name">{{ member.user_info.user_name }} <span class="posi" v-if="member.pos_info.pos_name != null && member.pos_info.pos_name.length > 0">{{ member.pos_info.pos_name }}</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div :class="{ is_team : team_id > 0 }">
                                                <p class="title memberTitle" style="cursor : default;"> 
                                                    {{ team_id > 0 ? makeWord().team_user : makeWord().group_user }}
                                                    <span class="num">{{ computedMember.length }}</span>
                                                </p>

                                                <p class="noone" v-if="computedMember.length < 1">
                                                    <!-- {{ team_id > 0 ? (data != null && data.group_type == 'BIZH' ? `${makeWord().team_user}가` : `${makeWord().team_user}이`) : `${makeWord().group_user}가` }} 없습니다 -->
                                                    {{ team_id > 0 ? makeWord().team_user_not_exist : makeWord().group_user_not_exist }}
                                                </p>
                                                
                                                <div id="group_detail_member_scroll">
                                                    <ul class="tabMemUl">

                                                        <li :class="{ not_pos : member.pos_info.pos_name == '' }" :key="member.user_id" v-for="member in computedMember">
                                                            <img class="dummy" :src="`app_images/profile/user/${Math.floor(member.user_id / 10000)}/${member.user_id}.jpg`" @error="userImageError($event)"/>
                                                            <p class="memPic" :style="{ backgroundImage : `url(app_images/profile/user/${Math.floor(member.user_id / 10000)}/${member.user_id}.jpg)` }">사진</p>
                                                            <p class="name">{{ member.user_info.user_name }} <span class="posi" v-if="member.pos_info.pos_name != null && member.pos_info.pos_name.length > 0">{{ member.pos_info.pos_name }}</span></p>
                                                        </li>

                                                        <!-- <li>
                                                            <p class="memPic">사진</p>
                                                            <p class="name">박기태 <span class="posi">차장</span></p>
                                                        </li>
                                                        <li>
                                                            <p class="memPic">사진</p>
                                                            <p class="name">김병관 <span class="posi">과장</span></p>
                                                        </li>
                                                        <li>
                                                            <p class="memPic">사진</p>
                                                            <p class="name">김윤미 <span class="posi">대리</span></p>
                                                        </li>
                                                        <li>
                                                            <p class="memPic">사진</p>
                                                            <p class="name">황인성 <span class="posi">대리</span></p>
                                                        </li> -->
                                                    </ul>
                                                </div>
                                                <!-- <span class="mem_gradation">그라데이션</span> -->
                                            </div>
                                        </li>
                                        <li class="tabEappList" v-if="tab == 'APPROVAL'">
                                            <div>
                                                <div class="appup_top">
                                                    <p class="title appTitle" @click.prevent="moveApprovalDraftBox(approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL)"><span class="icon">아이콘</span> 상신함</p>
                                                    <ul class="appBar_box">
                                                        <li class="appBar_con">
                                                            <a href="#" title="진행중" @click.prevent="moveApprovalDraftBox(approval_enum.APPROVAL_DRAFT_BOX_TAB.ING)">
                                                                <span class="appUPbar grnapp">연두 진행</span>
                                                                <!-- 건수가 0일 경우 p에 appgrey 클래스 추가 -->
                                                                <p class="app_count" :class="{ appgrey : draft_approvals.filter(item => item.approval_state == 'ING' ).length < 1 }"><span>{{ draft_approvals.filter(item => item.approval_state == 'ING' ).length }}</span></p>
                                                            </a>
                                                        </li>
                                                        <li class="appBar_con">
                                                            <a href="#" title="완료" @click.prevent="moveApprovalDraftBox(approval_enum.APPROVAL_DRAFT_BOX_TAB.COMPLETE)">
                                                                <span class="appUPbar blueapp">파랑 완료</span>
                                                                <p class="app_count" :class="{ appgrey : draft_approvals.filter(item => item.approval_state == 'COMPLETE' ).length < 1 }"><span>{{ draft_approvals.filter(item => item.approval_state == 'COMPLETE' ).length }}</span></p>
                                                            </a>
                                                        </li>
                                                        <li class="appBar_con">
                                                            <a href="#" title="반려" @click.prevent="moveApprovalDraftBox(approval_enum.APPROVAL_DRAFT_BOX_TAB.REJECT)">
                                                                <span class="appUPbar redapp">빨강 반려</span>
                                                                <p class="app_count" :class="{ appgrey : draft_approvals.filter(item => item.approval_state == 'REJECT' ).length < 1 }"><span>{{ draft_approvals.filter(item => item.approval_state == 'REJECT' ).length }}</span></p>
                                                            </a>
                                                        </li>
                                                        <li class="appBar_con">
                                                            <a href="#" title="임시" @click.prevent="moveApprovalDraftBox(approval_enum.APPROVAL_DRAFT_BOX_TAB.TEMP)">
                                                                <span class="appUPbar ylwapp">노랑 임시</span>
                                                                <p class="app_count" :class="{ appgrey : draft_approvals.filter(item => item.approval_state == 'TEMP' ).length < 1 }"><span>{{ draft_approvals.filter(item => item.approval_state == 'TEMP' ).length }}</span></p>
                                                            </a>
                                                        </li>   
                                                    </ul>
                                                </div>
                                                <p class="noone mt10" v-if="draft_approvals.length < 1">상신함에 문서가 존재하지 않습니다</p>
                                                <div id="group_detail_approval_draft_box_scroll">
                                                    <ul class="eapp_box">
                                                        <li class="g3Eapp_con mt10" :key="approval.approval_uid" v-for="approval in draft_approvals">
                                                            <span class="g3Eapp_side" :class="{ 
                                                                grnapp : approval.approval_state == 'ING', 
                                                                blueapp : approval.approval_state == 'COMPLETE', 
                                                                redapp : approval.approval_state == 'REJECT', 
                                                                ylwapp : approval.approval_state == 'TEMP' }">상신현황 색상</span>
                                                            
                                                            <span class="quick" v-if="approval.imp_seq == 10">긴급</span>
                                                            <span class="imprtApp" v-if="approval.imp_seq == 20">중요</span>

                                                            <p class="g3Eapp_con_title">
                                                                <a href="#" @click.prevent="moveApproval(approval)">
                                                                    {{ approval.contents.title }}
                                                                    <span class="time">
                                                                        {{ 
                                                                            hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(approval.audit_created, 'YYYYMMDD') 
                                                                               ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(approval.audit_created, 'hh:mm a'))}`
                                                                               : `${hodu_date_to_format_string(approval.audit_created, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(approval.audit_created)}`
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </p>
                                                        </li>
                                                        <!-- <li class="g3Eapp_con mt10">
                                                            <span class="g3Eapp_side">상신현황 색상</span>
                                                            <p class="g3Eapp_con_title"><a href="#">마케팅 진행 협의의 건 <span class="time">2021.03.02 화</span></a></p>
                                                        </li>
                                                        <li class="g3Eapp_con mt10">
                                                            <span class="g3Eapp_side">상신현황 색상</span>
                                                            <p class="g3Eapp_con_title"><a href="#">지출결의서 <span class="time">2021.02.25 목</span></a></p>
                                                        </li>
                                                        <li class="g3Eapp_con mt10">
                                                            <span class="g3Eapp_side">상신현황 색상</span>
                                                            <p class="g3Eapp_con_title"><a href="#">마케팅 진행 협의의 건 <span class="time">2021.03.02 화</span></a></p>
                                                        </li> -->
                                                    </ul>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="appup_top">
                                                    <p class="title appTitle" @click.prevent="moveApprovalReceiveBox(approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST)"><span class="icon ic_down">아이콘</span> 수신함</p>
                                                    <ul class="appBar_box">
                                                        <li class="appBar_con">
                                                            <a href="#" @click.prevent="moveApprovalReceiveBox(approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST)">
                                                                <span class="appDOWNbar">요청</span>
                                                                <!-- 클릭시 해당 페이지로 이동 -->
                                                                <!-- 건수가 0일 경우 p에 appgrey 클래스 추가 -->
                                                                <p class="app_count" :class="{ appgrey : receive_approvals.filter(item => item.approver.filter( app => app.user_id == user_id && app.state == 'ING' ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length < 1 }">
                                                                    <span>{{ receive_approvals.filter(item => item.approver.filter( app => app.user_id == user_id && app.state == 'ING' ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length }}</span>
                                                                </p>
                                                            </a>
                                                        </li>
                                                        <li class="appBar_con">
                                                            <a href="#" @click.prevent="moveApprovalReceiveBox(approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT)">
                                                                <span class="appDOWNbar">대기</span>
                                                                <p class="app_count" :class="{ appgrey : receive_approvals.filter(item => item.approver.filter( app => app.user_id == user_id && app.state == 'WAIT' ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length < 1 }">
                                                                    <span>{{ receive_approvals.filter(item => item.approver.filter( app => app.user_id == user_id && app.state == 'WAIT' ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length }}</span>
                                                                </p>
                                                            </a>
                                                        </li>
                                                        <li class="appBar_con">
                                                            <a href="#" @click.prevent="moveApprovalReceiveBox(approval_enum.APPROVAL_RECEIVE_BOX_TAB.HISTORY)">
                                                                <span class="appDOWNbar">내역</span>
                                                                <p class="app_count" :class="{ appgrey : receive_approvals.filter(item => item.approver.filter( app => app.user_id == user_id && ( app.state == 'REJECT' || app.state == 'CONFIRM' ) ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length < 1 }">
                                                                    <span>{{ receive_approvals.filter(item => item.approver.filter( app => app.user_id == user_id && ( app.state == 'REJECT' || app.state == 'CONFIRM' ) ).length > 0 && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length }}</span>
                                                                </p>
                                                            </a>
                                                        </li>
                                                        <li class="appBar_con">
                                                            <a href="#" @click.prevent="moveApprovalReceiveBox(approval_enum.APPROVAL_RECEIVE_BOX_TAB.REFERENCE)">
                                                                <span class="appDOWNbar">참조</span>
                                                                <p class="app_count" :class="{ appgrey : receive_approvals.filter(item => (item.receive_reference != null && item.receive_reference.filter(ref => ref.user_id == user_id).length > 0) && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length < 1 }">
                                                                    <span>{{ receive_approvals.filter(item => (item.receive_reference != null && item.receive_reference.filter(ref => ref.user_id == user_id).length > 0) && item.approval_state != 'TEMP' && item.approval_state != 'CANCEL').length }}</span>
                                                                </p>
                                                            </a>
                                                        </li>   
                                                    </ul>
                                                </div>
                                                <p class="noone mt10" v-if="receive_approvals.length < 1">수신함에 문서가 존재하지 않습니다</p>
                                                <div id="group_detail_approval_receive_box_scroll">
                                                    <ul class="eapp_box">

                                                        <li class="g3Eapp_con downApp_con mt10" :key="approval.approval_uid" v-for="approval in receive_approvals">
                                                            <span class="g3Eapp_smallT">{{ getReceiveStatus(approval) }}</span>
                                                            <span class="quick" v-if="approval.imp_seq == 10">긴급</span>
                                                            <span class="imprtApp" v-if="approval.imp_seq == 20">중요</span>
                                                            <p class="g3Eapp_con_title">
                                                                <a href="#" @click.prevent="moveApproval(approval)">
                                                                    {{ approval.contents.title }} 
                                                                    <span class="time">
                                                                        {{ 
                                                                            hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(approval.audit_created, 'YYYYMMDD') 
                                                                               ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(approval.audit_created, 'hh:mm a'))}`
                                                                               : `${hodu_date_to_format_string(approval.audit_created, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(approval.audit_created)}`
                                                                        }}
                                                                    </span>
                                                                </a
                                                            ></p>
                                                        </li>

                                                        <!-- <li class="g3Eapp_con downApp_con mt10">
                                                            <span class="g3Eapp_smallT">결재요청</span>
                                                            <span class="quick">긴급</span>
                                                            <span class="imprtApp">중요</span>
                                                            <p class="g3Eapp_con_title"><a href="#">마케팅 진행 협의의 건 <span class="time">2021.03.02 화</span></a></p>
                                                        </li>
                                                        <li class="g3Eapp_con downApp_con mt10">
                                                            <span class="g3Eapp_smallT">결재내역</span>
                                                            <p class="g3Eapp_con_title"><a href="#">지출결의서 <span class="time">2021.02.25 목</span></a></p>
                                                        </li>
                                                        <li class="g3Eapp_con downApp_con mt10">
                                                            <span class="g3Eapp_smallT">결재대기</span>
                                                            <p class="g3Eapp_con_title"><a href="#">마케팅 진행 협의의 건 <span class="time">2021.03.02 화</span></a></p>
                                                        </li> -->
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="tabTaskList" v-if="tab == 'WORK'">
                                            <div>
                                                <div class="task_top">
                                                    <p class="title taskTitle" @click.prevent="move('MEETINGLOG')"><span class="icon">아이콘</span> 회의록</p>
                                                </div>
                                                <p class="noone mt10" v-if="isMeetinglogEnabled() == false || meetinglog_list.length < 1">
                                                    {{ isMeetinglogEnabled() == false ? '회의록 읽기 권한이 없습니다' : '회의록이 없습니다' }}
                                                </p>
                                                <div id="group_detail_meetinglog_scroll">
                                                    <ul class="task_box" v-if="isMeetinglogEnabled() == true">
                                                        <li class="g3task_con mt10" :key="event.event_id" v-for="event in meetinglog_list">
                                                            <span class="g3task_side"></span>
                                                            <p class="g3task_con_title">
                                                                <a href="#" @click.prevent="moveEvent(event)">
                                                                    {{ event.event_data.title }}
                                                                    <span class="time">
                                                                        {{ 
                                                                            hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(event.event_data.schedule_date.start, 'YYYYMMDD') 
                                                                               ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(event.event_data.schedule_date.start, 'hh:mm a'))}`
                                                                               : `${hodu_date_to_format_string(event.event_data.schedule_date.start, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(event.event_data.schedule_date.start)}`
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </p>
                                                        </li>
                                                        <!-- <li class="g3task_con mt10">
                                                            <span class="g3task_side"></span>
                                                            <p class="g3task_con_title"><a href="#">2021 상반기 지출관련 중간점검 <span class="time">오늘 11:13am</span></a></p>
                                                        </li>
                                                        <li class="g3task_con mt10">
                                                            <span class="g3task_side"></span>
                                                            <p class="g3task_con_title"><a href="#">APP UI 관련 회의<span class="time">2021.03.11 목</span></a></p>
                                                        </li> -->
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                            <div>
 <!-- icon 클래스 그룹색상으로 변경 -->
                                                <div class="task_top">
                                                    <p class="title taskTitle" @click.prevent="move('REPORT')"><span class="icon ic_work">아이콘</span> 업무일지</p>
                                                </div>
                                                <p class="noone mt10" v-if="isReportEnabled() == false || report_list.length < 1">
                                                    {{ isReportEnabled() == false ? '업무일지 읽기 권한이 없습니다' : '업무일지가 없습니다' }}
                                                </p>
                                                <div id="group_detail_report_scroll">
                                                    <ul class="task_box" v-if="isReportEnabled() == true">
                                                        <li class="g3task_con mt10" :key="event.event_id" v-for="event in report_list">
                                                            <span class="g3task_side"></span>
                                                            <p class="g3task_con_title">
                                                                <a href="#" @click.prevent="moveEvent(event)">
                                                                    {{ event.event_data.title }}
                                                                    <span class="time">
                                                                        {{ 
                                                                            hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(event.event_data.schedule_date.start, 'YYYYMMDD') 
                                                                               ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(event.event_data.schedule_date.start, 'hh:mm a'))}`
                                                                               : `${hodu_date_to_format_string(event.event_data.schedule_date.start, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(event.event_data.schedule_date.start)}`
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </p>
                                                        </li>
                                                        <!-- <li class="g3task_con mt10">
                                                            <span class="g3task_side"></span>
                                                            <p class="g3task_con_title"><a href="#">2021 상반기 지출관련 중간점검 <span class="time">오늘 11:13am</span></a></p>
                                                        </li>
                                                        <li class="g3task_con mt10">
                                                            <span class="g3task_side"></span>
                                                            <p class="g3task_con_title"><a href="#">APP UI 관련 회의<span class="time">2021.03.11 목</span></a></p>
                                                        </li>
                                                        <li class="g3task_con mt10">
                                                            <span class="g3task_side"></span>
                                                            <p class="g3task_con_title"><a href="#">web menu Public<span class="time">2021.03.02 화</span></a></p>
                                                        </li> -->
                                                    </ul>
                                                </div>
                                            </div>

                                            <div>
                                                <div class="task_top">
                                                    <p class="title taskTitle" @click.prevent="move('PROJECT')"><span class="icon ic_project">아이콘</span> 프로젝트</p>
                                                </div>
                                                <p class="noone mt10" v-if="isProjectEnabled() == false || project_list.length < 1">
                                                    {{ isProjectEnabled() == false ? '프로젝트 읽기 권한이 없습니다' : '프로젝트가 없습니다' }} 
                                                </p>
                                                <div id="group_detail_project_scroll">
                                                    <ul v-if="isProjectEnabled() == true">
                                                        <li class="g3task_con mt10" :key="project.event.event_id" v-for="project in project_list">
                                                            <span class="g3task_side"></span>
                                                            <p class="g3task_con_title">
                                                                <a href="#" @click.prevent="moveEvent(project.event)">
                                                                    {{ project.event.event_data.title }}
                                                                    <span class="time">
                                                                        {{ 
                                                                            hodu_date_to_format_string(new Date(), 'YYYYMMDD') == hodu_date_to_format_string(project.event.event_data.schedule_date.start, 'YYYYMMDD') 
                                                                               ? `오늘 ${amPmStringToLocaleAmPmString(hodu_date_to_format_string(project.event.event_data.schedule_date.start, 'hh:mm a'))}`
                                                                               : `${hodu_date_to_format_string(project.event.event_data.schedule_date.start, 'YYYY.MM.DD')} ${ getDayOfWeekByDate(project.event.event_data.schedule_date.start)}`
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </p>
                                                        </li>
                                                        <!-- <li class="g3task_con mt10">
                                                            <span class="g3task_side"></span>
                                                            <p class="g3task_con_title"><a href="#">web menu Public<span class="time">2021.03.02 화</span></a></p>
                                                        </li> -->
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <!-- 근태현황 -->
                                        <li class="tabAtndnceList" v-if="tab == 'ATTENDANCE'">
                                            <div>
                                                <div class="atn_top">
                                                    <div class="weekdate_wrap">
                                                        <!-- <input type="button" id="atn_left_week_start" class="datepicker" :value="start_text">
                                                        <span class="seper">~</span>
                                                        <input type="button" id="atn_left_week_end" class="datepicker" :value="end_text">
                                                        <input type="button" id="btnPrev" value="" class="atnLeft-btn bt-prev mr10" @click.prevent="prev">
                                                        <input type="button" id="btnNext" value="" class="atnLeft-btn bt-next mr10" @click.prevent="next"> -->
                                                        <input type="button" id="antWeekDay_start" class="weekdate" :value="hodu_date_to_format_string(attendance_start, 'YYYY.MM.DD')"/>
                                                        <span class="weeksign">~</span>
                                                        <input type="button" id="antWeekDay_end" class="weekdate" :value="hodu_date_to_format_string(attendance_end, 'YYYY.MM.DD')"/>
                                                        <input type="button" id="antPrev" class="atnLeft-btn bt-prev" @click.prevent="changeAtttendanceDate(-7)">
                                                        <input type="button" id="antNext" class="atnLeft-btn bt-next" @click.prevent="changeAtttendanceDate(+7)">
                                                    </div>
                                                </div>
                                                <div class="atn_left atn_weekly mt15">
                                                    <!-- 기본 ic_basic / 휴일 ic_holiday / 체크된날 ic_pastW / 오늘 ic_todayW / 휴가(연차) ic_dayoffW / 미체크 ic_uncheckW / 결근 ic_absenceW -->
                                                    <ul class="week_check">
                                                        <li :key="day.yyyymmdd" v-for="day in computedAttendanceWeek">
                                                            <a href="#" :class="{ on : day.yyyymmdd == attendance_selected }" @click.prevent="attendanceDayClick(day)">
                                                                <span class="icon" :class="{
                                                                    ic_basic : isHoliday(day) == false && isVacation(day) == false && isBusinesstrip(day) == false && isVacationAndBusinessTrip(day) == false && isUncheck(day) == false && isAwol(day) == false && isToday(day) == false, // 정상 출근 해야하는 미래날짜 (밑에꺼 중에 해당되는게 없다면)
                                                                    ic_holiday : isHoliday(day) == true, // 휴일 (work_cnt == 0이고 휴가도 안씀)
                                                                    ic_pastW : isNormal(day) == true && isVacation(day) == false && isBusinesstrip(day) == false && isVacationAndBusinessTrip(day) == false && isHoliday(day) == false, // 과거에 체크한 날
                                                                    ic_todayW : isToday(day) == true && isVacation(day) == false && isBusinesstrip(day) == false && isVacationAndBusinessTrip(day) == false && isHoliday(day) == false, // 오늘이 일하는 날이라면 무조건 
                                                                    ic_dayoffW : isVacation(day) == true || isBusinesstrip(day) == true || isVacationAndBusinessTrip(day) == true, // 휴가
                                                                    ic_uncheckW : isUncheck(day) == true && isVacation(day) == false && isBusinesstrip(day) == false && isVacationAndBusinessTrip(day) == false && isHoliday(day) == false, // 미체크
                                                                    ic_absenceW : isAwol(day) == true && isVacation(day) == false && isBusinesstrip(day) == false && isVacationAndBusinessTrip(day) == false && isHoliday(day) == false, // 결근
                                                                }">출퇴근아이콘</span>
                                                                <p>{{ hodu_date_to_format_string(day.date, 'DD') }} {{ getDayOfWeekByDate(day.date) }}</p>
                                                            </a>
                                                        </li>
                                                        <!-- <li>
                                                            <a href="#">
                                                                <span class="icon ic_holiday">출퇴근아이콘</span>
                                                                <p>14 일</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="icon ic_uncheckW">출퇴근아이콘</span>
                                                                <p>15 월</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="icon ic_pastW">출퇴근아이콘</span>
                                                                <p>16 화</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="icon ic_todayW">출퇴근아이콘</span>
                                                                <p>17 수</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="icon ic_basic">출퇴근아이콘</span>
                                                                <p>18 목</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="icon ic_dayoffW">출퇴근아이콘</span>
                                                                <p>19 금</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="icon ic_holiday">출퇴근아이콘</span>
                                                                <p>20 토</p>
                                                            </a>
                                                        </li> -->
                                                    </ul>
                                                     <!-- 관리자 전체현황일 경우 그룹가입자 전체 리스트 받아오기 -->
                                                    <ul class="week_text mt35">
                                                        <li>
                                                            <a href="#" @click.prevent="() => {}" style="cursor : default;">
                                                                <p>전체
                                                                    <span>{{ computedAttendanceMyStatistics.all }}</span>
                                                                </p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" @click.prevent="() => {}" style="cursor : default;">
                                                                <span class="atnBar blueatn">정상파랑</span>
                                                                <p>정상
                                                                    <span>{{ computedAttendanceMyStatistics.normal }}</span>
                                                                </p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" @click.prevent="() => {}" style="cursor : default;">
                                                                <span class="atnBar ylwatn">미체크노랑</span>
                                                                <p>미체크
                                                                    <span>{{ computedAttendanceMyStatistics.uncheck }}</span>
                                                                </p>
                                                            </a>
                                                        </li>
                                                        <!-- <li>
                                                            <a href="#" @click.prevent="() => {}" style="cursor : default;">
                                                                <span class="atnBar redatn">지각빨강</span>
                                                                <p>지각
                                                                    <span>1</span>
                                                                </p>
                                                            </a>
                                                        </li> -->
                                                        <li>
                                                            <a href="#" @click.prevent="() => {}" style="cursor : default;">
                                                                <span class="atnBar redatn">결근빨강</span>
                                                                <p>결근
                                                                    <span>{{ computedAttendanceMyStatistics.awol }}</span>
                                                                </p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="atnBar skyatn">휴가하늘</span>
                                                                <p>휴가
                                                                    <span>{{ computedAttendanceMyStatistics.vacation }}</span>
                                                                </p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="atnBar greenatn">출장초록</span>
                                                                <p>출장
                                                                    <span>{{ computedAttendanceMyStatistics.businesstrip }}</span>
                                                                </p>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="right">
                                                <div class="atn_top">
                                                    <!-- 관리자일 경우 보이기 -->
                                                    <div class="week_manager" v-if="(get_group_role(group_id) == 'GROUP_MANAGER' || get_group_role(group_id) == 'ADMIN')">
                                                        <h2 class="man_tab1" :class="{ active : attendance_tab == 'MY' }"><a href="#" @click.prevent="() => { attendance_tab = 'MY' }">내 현황</a></h2>
                                                        <h2 class="man_tab2" :class="{ active : attendance_tab == 'ALL' }"><a href="#" @click.prevent="() => { attendance_tab = 'ALL' }">전체 현황</a></h2>
                                                    </div>
                                                </div>
                                                <div class="atn_right">
                                                    <div class="graph">
                                                        <h2 class="hidden">일일그래프</h2>
                                                        <!-- 개인일때 oneGraph , 관리자 전체현황 allGraph -->
                                                        <GChart
                                                            type="PieChart"
                                                            id="atngraph"
                                                            class="atngraph"
                                                            :data="google_chart_data"
                                                            :options="google_chart_option"
                                                        />
                                                        <!-- <div id="atngraph" class="atngraph" :class="{ oneGraph : attendance_tab == 'MY', allGraph : attendance_tab == 'ALL' }">
                                                            <a href="#" class="worksum" @click.prevent="() => {}">
                                                                <p class="gra_center">근무중<span id="gra_dpTime">{{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(new Date(current_time_millis), 'hh:mm a')) }}</span></p>
                                                            </a>
                                                        </div> -->
                                                    </div>
                                                    <div class="graph_wrap clearfix">
                                                        <!-- 개인컨텐츠 graph_con / 관리자 전체현황 컨텐츠 allGraph_con -->
                                                        <div class="graph_con" v-if="attendance_tab == 'MY'">
                                                            <h3 class="mt05"><span class="hidden">날짜</span>{{ computedAttendacneSelectedInfo == null ? '' : hodu_date_to_format_string(computedAttendacneSelectedInfo.date, 'YYYY.MM.DD') }} {{ computedAttendacneSelectedInfo == null ? '' : getDayOfWeekByDate(computedAttendacneSelectedInfo.date, '요일') }}</h3>
                                                            <ul class="grpcon_one mt10">
                                                                <li>
                                                                    출근
                                                                    <span>{{ computedAttendacneSelectedInfo == null ? '' : getStartTimeText(computedAttendacneSelectedInfo.data) }}</span>
                                                                </li>
                                                                <li>
                                                                    퇴근
                                                                    <span>{{ computedAttendacneSelectedInfo == null ? '' : getEndTimeText(computedAttendacneSelectedInfo.data) }}</span>
                                                                </li>
                                                                <!--지난날 근무시간 나타나기 -->
                                                                <li class="grpOne_pastW_time" style="display: none;">근무시간</li>
                                                                <li class="grpOne_futureW_time">
                                                                    {{ getRemainTimeOrWorkTimeText() }}
                                                                    <span>{{ getRemainTimeOrWorkTime() }}</span>
                                                                </li>
                                                            </ul>
                                                            <ul class="grpcon_second mt10">
                                                                <li>
                                                                    <p>
                                                                        <strong class="basic_atn">{{ attend_emp_info == null ? '' : attend_emp_info.work_type_name }}</strong>
                                                                        출근 <span>{{ getStartWork() }}</span>
                                                                        <span>~</span>
                                                                        퇴근 <span>{{ getEndWork() }}</span>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="graph_con allGraph_con" v-if="attendance_tab == 'ALL'">
                                                            <h3 class="mt05"><span class="hidden">날짜</span>{{ computedAttendacneSelectedInfo == null ? '' : hodu_date_to_format_string(computedAttendacneSelectedInfo.date, 'YYYY.MM.DD') }} {{ computedAttendacneSelectedInfo == null ? '' : getDayOfWeekByDate(computedAttendacneSelectedInfo.date, '요일') }}</h3>
                                                            <p class="todayW_count">오늘의 근무 (<span class="check_count">{{ processDayCount(String(Number(computedAttendanceAllStatistics.normal) + Number(computedAttendanceAllStatistics.late) + Number(computedAttendanceAllStatistics.vacation))) }}</span> / <span class="all_count">{{ computedAttendanceAllStatistics.all }}</span>)</p>
                                                            <ul class="allgrpcon_one mt10">
                                                                <li>정상<span>{{ computedAttendanceAllStatistics.normal }}</span></li>
                                                                <li class="all_yellow">미체크<span>{{ computedAttendanceAllStatistics.uncheck }}</span></li>
                                                                <li class="all_red">지각<span>{{ computedAttendanceAllStatistics.late }}</span></li>
                                                                <li class="all_pink">결근<span>{{ computedAttendanceAllStatistics.awol }}</span></li>
                                                                <li class="all_sky">휴가<span>{{ computedAttendanceAllStatistics.vacation }}</span></li>
                                                                <li class="all_green">출장<span>{{ computedAttendanceAllStatistics.businesstrip }}</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <!-- //근태현황 -->
                                        <li class="tabAllMenusList" v-if="tab == 'QUICKMENU'">
                                            <ul class="allMenusUl">
                                                <li v-if="isEventCreateEnabled() == true"><a href="#" class="allMenus_ic01" @click.prevent="move('EVENT')">일정</a></li>
                                                <li v-if="isProjectEnabled() == true"><a href="#" class="allMenus_ic03" @click.prevent="move('PROJECT')">프로젝트</a></li>
                                                <li v-if="isMeetinglogEnabled() == true"><a href="#" class="allMenus_ic02" @click.prevent="move('MEETINGLOG')">회의록</a></li>
                                                <li v-if="isReportEnabled() == true"><a href="#" class="allMenus_ic08" @click.prevent="move('REPORT')">업무일지</a></li>
                                                <li v-if="isAttendanceEnabled() == true"><a href="#" class="allMenus_ic04" @click.prevent="move('ATTENDANCE')">근태관리</a></li>
                                                <li v-if="isApprovalEnabled() == true"><a href="#" class="allMenus_ic05" @click.prevent="move('APPROVAL')">전자결재</a></li>
                                                <li><a href="#" class="allMenus_ic06" @click.prevent="move('MEMBER')">멤버관리</a></li>
                                                <li v-if="isMessageEnabled() == true"><a href="#" class="allMenus_ic07" @click.prevent="move('MESSAGE')">메세지</a></li>
                                                <li v-if="biz_id == null || biz_id.length < 1"><a href="#" class="allMenus_ic09" @click.prevent="move('NOTIFICATION')">공지사항</a></li>
                                            </ul>
                                        </li>

                                        <!-- 호두키즈 출석부 -->
                                        <li class="tabAtndnceList" v-if="tab == 'KIDS_ATTENDANCE'">
                                            <div>
                                                <div class="atn_top">
                                                    <div class="weekdate_wrap">
                                                        <input type="button" id="antWeekDay_start" class="weekdate" :value="hodu_date_to_format_string(kids_attendance_start, 'YYYY.MM.DD')">
                                                        <span class="weeksign">~</span>
                                                        <input type="button" id="antWeekDay_end" class="weekdate" :value="hodu_date_to_format_string(kids_attendance_end, 'YYYY.MM.DD')">
                                                        <input type="button" id="antPrev" class="atnLeft-btn bt-prev" @click.prevent="changeKidsAtttendanceDate(-7)">
                                                        <input type="button" id="antNext" class="atnLeft-btn bt-next" @click.prevent="changeKidsAtttendanceDate(+7)">
                                                    </div>
                                                </div>
                                                <div class="atn_left atn_weekly mt15">
                                                    <ul class="week_check">

                                                        <li :key="day.yyyymmdd" v-for="day in computedKidsAttendanceWeek">
                                                            <a href="#" :class="{ on : day.yyyymmdd == kids_attendance_selected }" @click.prevent="kidsAttendanceDayClick(day)">
                                                                <span class="icon" :class="{
                                                                    ic_holiday : (new Date(day.date).getDay() == 0 || new Date(day.date).getDay() == 6), // 토, 일 
                                                                    ic_basic : !(new Date(day.date).getDay() == 0 || new Date(day.date).getDay() == 6) && day.yyyymmdd > hodu_date_to_format_string(new Date(), 'YYYYMMDD'), // 미래날짜
                                                                    ic_pastW : !(new Date(day.date).getDay() == 0 || new Date(day.date).getDay() == 6) && day.yyyymmdd < hodu_date_to_format_string(new Date(), 'YYYYMMDD'), // 과거 날짜
                                                                    ic_todayW : !(new Date(day.date).getDay() == 0 || new Date(day.date).getDay() == 6) && day.yyyymmdd == hodu_date_to_format_string(new Date(), 'YYYYMMDD'), // 오늘 날짜
                                                                }">출퇴근아이콘</span>
                                                                <p>{{ hodu_date_to_format_string(day.date, 'DD') }} {{ getDayOfWeekByDate(day.date) }}</p>
                                                            </a>
                                                        </li>

                                                        <!-- <li>
                                                            <a href="#" class="">
                                                                <span class="icon ic_holiday">출퇴근아이콘</span><p>18 일</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" class="">
                                                                <span class="icon ic_basic ic_pastW">출퇴근아이콘</span><p>19 월</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" class="">
                                                                <span class="icon ic_basic ic_pastW">출퇴근아이콘</span><p>20 화</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" class="">
                                                                <span class="icon ic_basic ic_pastW">출퇴근아이콘</span><p>21 수</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" class="">
                                                                <span class="icon ic_basic ic_pastW">출퇴근아이콘</span><p>22 목</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" class="on">
                                                                <span class="icon ic_todayW">출퇴근아이콘</span><p>23 금</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" class="">
                                                                <span class="icon ic_holiday">출퇴근아이콘</span><p>24 토</p>
                                                            </a>
                                                        </li> -->
                                                    </ul>
                                                    <!-- <ul class="week_text mt35">
                                                        <li>
                                                            <a href="#" style="cursor: default;">
                                                                <p>전체 <span>5</span></p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" style="cursor: default;">
                                                                <span class="atnBar blueatn">정상파랑</span><p>정상 <span>5</span></p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" style="cursor: default;">
                                                                <span class="atnBar ylwatn">미체크노랑</span><p>미체크 <span>0</span></p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" style="cursor: default;">
                                                                <span class="atnBar redatn">결근빨강</span><p>결근 <span>0</span></p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="atnBar skyatn">휴가하늘</span><p>휴가 <span>0</span></p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <span class="atnBar greenatn">출장초록</span><p>출장 <span>0</span></p>
                                                            </a>
                                                        </li>
                                                    </ul> -->
                                                </div>
                                            </div>
                                            <div class="hoduE_right" v-if="kids_attendance_tab == 'MY'">
                                                <h3 class="mt05">
                                                    <span class="hidden">날짜</span>
                                                    {{ computedKidsAttendacneSelectedInfo == null ? '' : hodu_date_to_format_string(computedKidsAttendacneSelectedInfo.date, 'YYYY.MM.DD') }}
                                                    {{ computedKidsAttendacneSelectedInfo == null ? '' : getDayOfWeekByDate(computedKidsAttendacneSelectedInfo.date, '요일') }}
                                                </h3>
                                                <ul class="user_children">

                                                    <li :key="kid.member_key" v-for="kid in kids_attend_emp_month_info">
                                                        <div class="userChil_list">
                                                            <!-- <span class="userChil_img ir_pm">원아프로필사진</span> -->
                                                            <div class="userInfor">
                                                                <p class="child_class">{{ kid.team_name }}</p>
                                                                <p class="userChil_name"><strong>{{ kid.member_name }}</strong> 원아</p>
                                                            </div>
                                                            <div class="userTime">
                                                                <span class="child_onTime" :class="{ on_absent : kid.jsonb != null && kid.jsonb[kids_attendance_selected] != null && kid.jsonb[kids_attendance_selected].attend_type == 'ABSENT' }">
                                                                    {{ kid.jsonb != null && kid.jsonb[kids_attendance_selected] != null && kid.jsonb[kids_attendance_selected].attend_type == 'ABSENT' ? kid.jsonb[kids_attendance_selected].attend_remark :
                                                                       (kid.jsonb == null || kid.jsonb[kids_attendance_selected] == null || kid.jsonb[kids_attendance_selected].start_time == null ? '-- : --' : processTimeText(kid.jsonb[kids_attendance_selected].start_time)) }}
                                                                </span>
                                                                <span class="child_offTime" :class="{ on_absent : kid.jsonb != null && kid.jsonb[kids_attendance_selected] != null && kid.jsonb[kids_attendance_selected].attend_type == 'ABSENT' }">
                                                                    {{ kid.jsonb == null || kid.jsonb[kids_attendance_selected] == null || kid.jsonb[kids_attendance_selected].end_time == null ? '-- : --' : processTimeText(kid.jsonb[kids_attendance_selected].end_time) }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div class="right" v-else-if="kids_attendance_tab == 'ALL'">
                                                <div class="atn_right">
                                                    <div class="graph">
                                                        <h2 class="hidden">일일그래프</h2>
                                                        <GChart
                                                            type="PieChart"
                                                            id="atngraph"
                                                            class="atngraph"
                                                            :data="kids_google_chart_data"
                                                            :options="kids_google_chart_option"
                                                        />
                                                    </div>

                                                    <div class="graph_wrap clearfix">
                                                        <div class="graph_con allGraph_con">
                                                            <h3 class="mt05">
                                                                <span class="hidden">날짜</span>
                                                                {{ computedKidsAttendacneSelectedInfo == null ? '' : hodu_date_to_format_string(computedKidsAttendacneSelectedInfo.date, 'YYYY.MM.DD') }}
                                                                {{ computedKidsAttendacneSelectedInfo == null ? '' : getDayOfWeekByDate(computedKidsAttendacneSelectedInfo.date, '요일') }}
                                                            </h3>
                                                            <p class="todayW_count">오늘의 출석부 (<span class="check_count">{{ processDayCount(Number(computedKidsAttendanceAllStatistics.normal) + Number(computedKidsAttendanceAllStatistics.absent)) }}</span> / <span class="all_count">{{ computedKidsAttendanceAllStatistics.all }}</span>)</p>
                                                            <ul class="allgrpcon_one mt10">
                                                                <li>정상<span>{{ computedKidsAttendanceAllStatistics.normal }}</span></li>
                                                                <li class="all_pink">결석<span>{{ computedKidsAttendanceAllStatistics.absent }}</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- 호두키즈 원아 --> <!-- 리스트 스크롤 아래로 되게해주세요 -->
                                        <li class="tabKidsList" v-if="tab == 'KIDS' && get_group_role(group_id) != 'GROUP_USER'">
                                            <div id="kids_list_scroll" class="hoduE_safety">

                                                <h3 class="mt05"><span class="hidden">날짜</span>{{ hodu_date_to_format_string(new Date(), "YYYY.MM.DD") }} {{ getDayOfWeekByDate(new Date(), "요일") }}</h3>
                                                <ul class="user_children">

                                                    <li class="safe_list" :key="kid.member_key" v-for="kid in kids">
                                                        <!-- <a href="#" class="safe_detail"> -->
                                                            <div class="userChil_list safeChil_list">

                                                                <!-- <span class="userChil_img ir_pm">원아프로필사진</span> -->

                                                                <div class="userInfor">
                                                                    <!-- 글씨 색상은 그룹 또는 팀별 색상에 맞춰서 넣어요 -->
                                                                    <p class="child_class">{{ kid.class_info ? kid.class_info.team_info.team_name : '소속없음' }}</p>
                                                                    <p class="userChil_name"><strong>{{ kid.member_name }}</strong> 원아</p>
                                                                </div>
                                                                <!-- safe_good 좋아요 / safe_bad 나빠요 / safe_verybad 매우나빠요   클래스-->
                                                                <span class="now_safe" :class="{
                                                                    safe_good : kid.band_info == null || kid.band_info.health_level == 0 || kid.band_info.health_level == 1,
                                                                    safe_bad : kid.band_info != null && kid.band_info.health_level == 2,
                                                                    safe_verybad : kid.band_info != null && kid.band_info.health_level == 3,
                                                                }">{{ getHealthLevelText(kid.band_info) }}</span>
                                                                <div class="safety_class">
                                                                    <p class="safe_heart">심박수 <span class="heart_value">{{ kid.band_info ? kid.band_info.heart_rate : '확인불가' }}</span></p>
                                                                    <p class="safe_stress">스트레스 <span class="stress_value">{{ kid.band_info ? getStressLevelText(kid.band_info) : '확인불가' }}</span></p>
                                                                    <p class="safe_steps">걸음수(활동량) <span class="steps_value">{{ kid.band_info ? kid.band_info.steps : '확인불가' }}</span></p>
                                                                </div>

                                                            </div>
                                                        <!-- </a> -->
                                                    </li>

                                                </ul>
                                            </div>
                                        </li>

                                        <li class="tabKidsAuth" v-if="tab == 'KIDS_AUTH'">
                                            평가인증문서관리
                                        </li>

                                    </ul>
                                    <!-- <GroupMemberSmall :group_id="group_id" :team_id="team_id" :is_member_read="is_member_read" :group_team_name="group_team_name" ref="group_member" v-on:increment="goMemberList"/> -->
                                </div>
                            <!-- second에 ul.grpTeamUl 스크롤 필요 -->
                            <!-- second 마우스엔터시 상단 .first div height:200px로 변경(css), 전체 윈도우 height px값 구한 후 ".title_box height값 + .settingBg height 값 + 200px + sortHeader height값"을 뺀 나머지 .second height로 변경  -->
                            <!-- <GroupTeamList :group_id="group_id" :team_id="team_id" :data="data" v-on:init="init" @makeWord="makeWord" ref="group_team_list" v-if="data != null && isHoduH(data.group_type) == false"/>
                            <div style="height : 0px; border-top : 1px solid #e7e9ea;" v-else/> -->
                        </div>
                    </div>	
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { GROUP_TYPE, CALENDAR_TYPE, OWNER_TYPE, EVENT_SUB_TYPE, CRUD_TYPE, API_METHOD, SHARE_OPTION } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const EventInfo = namespace('EventInfo');
const GroupInfo = namespace('GroupInfo');
const AttendanceInfo = namespace('AttendanceInfo');
const ApprovalInfo = namespace('ApprovalInfo');
const ModalInfo = namespace('ModalInfo');
const HoduDocInfo = namespace('HoduDocInfo');
const OrganizationInfo = namespace('OrganizationInfo');

import { GroupModalInfo, GroupListConfirmModalData, GroupNoticeReplyModalInfo, GroupBoardReplyModalInfo, HoduPremiumPopupInfo } from '@/store/modules/ModalInfo';
import GroupNotice from '@/components/GroupNotice.vue';
import GroupStatistics from '@/components/GroupStatistics.vue';
import GroupMemberSmall from '@/components/GroupMemberSmall.vue';
import GroupTeamList from '@/components/GroupTeamList.vue';
import GroupListConfirmModal from '@/components/GroupListConfirmModal.vue';
import { t_event } from '@/model/event';

const dateFormat = require('dateformat');

import { attendance_enum } from '@/model/attendance';
import { approval_enum, approval_modal_interface } from '@/model/approval';

import { ResizeObserver } from 'vue-resize';
import moment from 'moment';

import { RRule, RRuleSet, rrulestr } from 'rrule';

import { GChart } from 'vue-google-charts';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';
import { t_notification } from '@/model/osm';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { organization_enum } from '@/model/organization';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {
        GroupNotice, GroupStatistics, GroupMemberSmall, GroupTeamList, GroupListConfirmModal,
        GChart, ResizeObserver
    }
})
export default class GroupDetail extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    /**
     * 그룹관리자, 팀장이 나오게함
     */
    get computedMemberManager() : any[] {

        let members : any[] = [];

        try {
            // 팀장
            if( this.team_id > 0 ) {
                members = this.members.filter(member => member.role.indexOf(this.GroupAuth.TEAM_MANAGER) > -1 || member.role.indexOf(this.GroupAuth.ADMIN) > -1).sort(
                    (o1, o2) : number => {
                        const o1_pos_seq = o1.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o1.pos_info.pos_seq;
                        const o2_pos_seq = o2.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o2.pos_info.pos_seq;
                        const o1_user_name = o1.user_info.user_name;
                        const o2_user_name = o2.user_info.user_name;

                        if( o1_pos_seq > o2_pos_seq ) return 1;
                        else if( o1_pos_seq < o2_pos_seq ) return -1;
                        else if( o1_user_name > o2_user_name ) return 1;
                        else if( o1_user_name < o2_user_name ) return -1;
                        return 0;
                    }
                );
            }

            // 그룹관리자
            else {
                members = this.members.filter(member => member.role.indexOf(this.GroupAuth.GROUP_MANAGER) > -1 || member.role.indexOf(this.GroupAuth.ADMIN) > -1).sort(
                    (o1, o2) : number => {
                        const o1_pos_seq = o1.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o1.pos_info.pos_seq;
                        const o2_pos_seq = o2.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o2.pos_info.pos_seq;
                        const o1_user_name = o1.user_info.user_name;
                        const o2_user_name = o2.user_info.user_name;

                        if( o1_pos_seq > o2_pos_seq ) return 1;
                        else if( o1_pos_seq < o2_pos_seq ) return -1;
                        else if( o1_user_name > o2_user_name ) return 1;
                        else if( o1_user_name < o2_user_name ) return -1;
                        return 0;
                    }
                );
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return members;
    }

    /**
     * 부관리자 나오게함
     */
    get computedMemberSubManager() : any[] {

        let members : any[] = [];

        try {

            members = this.members.filter(member => member.role.indexOf(this.GroupAuth.GROUP_SUB_MANAGER) > -1).sort(
                (o1, o2) : number => {
                    const o1_pos_seq = o1.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o1.pos_info.pos_seq;
                    const o2_pos_seq = o2.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o2.pos_info.pos_seq;
                    const o1_user_name = o1.user_info.user_name;
                    const o2_user_name = o2.user_info.user_name;

                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    else if( o1_user_name > o2_user_name ) return 1;
                    else if( o1_user_name < o2_user_name ) return -1;
                    return 0;
                }
            );
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return members;
    }

    /**
     * 멤버, 팀원, 입주자가 나오게함
     */
    get computedMember() : any[] {

        let members : any[] = [];

        try {
            // 팀장
            if( this.team_id > 0 ) {
                members = this.members.filter(member => member.role.indexOf(this.GroupAuth.TEAM_USER) > -1).sort(
                    (o1, o2) : number => {
                        const o1_pos_seq = o1.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o1.pos_info.pos_seq;
                        const o2_pos_seq = o2.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o2.pos_info.pos_seq;
                        const o1_user_name = o1.user_info.user_name;
                        const o2_user_name = o2.user_info.user_name;

                        if( o1_pos_seq > o2_pos_seq ) return 1;
                        else if( o1_pos_seq < o2_pos_seq ) return -1;
                        else if( o1_user_name > o2_user_name ) return 1;
                        else if( o1_user_name < o2_user_name ) return -1;
                        return 0;
                    }
                );
            }

            // 그룹관리자
            else {
                members = this.members.filter(member => member.role.indexOf(this.GroupAuth.GROUP_USER) > -1).sort(
                    (o1, o2) : number => {
                        const o1_pos_seq = o1.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o1.pos_info.pos_seq;
                        const o2_pos_seq = o2.pos_info.pos_seq == -1 ? Number.MAX_SAFE_INTEGER : o2.pos_info.pos_seq;
                        const o1_user_name = o1.user_info.user_name;
                        const o2_user_name = o2.user_info.user_name;

                        if( o1_pos_seq > o2_pos_seq ) return 1;
                        else if( o1_pos_seq < o2_pos_seq ) return -1;
                        else if( o1_user_name > o2_user_name ) return 1;
                        else if( o1_user_name < o2_user_name ) return -1;
                        return 0;
                    }
                );
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return members;
    }

    /**
     * 날짜 및 데이터 생성
     */
    get computedAttendanceWeek() : any[] {
        
        let target : any[] = [];

        if( this.attend_emp_day_count_info == null || this.attend_emp_month_info == null ) return [];

        // attendance_start를 시작으로 일요일부터 토요일까지 데이터 생성
        for( let i = 0; i < 7; i++ ) {
            const mt : moment.Moment = moment(this.attendance_start).add("day", i);
            const key : string = mt.format('YYYYMMDD');
            target.push({
                "date" : mt.toDate(),
                "yyyymmdd" : key,
                "data" : this.attend_emp_month_info.jsonb[`${key}`] ? this.attend_emp_month_info.jsonb[`${key}`] : [],
                "work_count" : this.attend_emp_day_count_info[`${key}`]
            });
        }

        return target;
    }

    /**
     * 선택된 날짜의 정보
     */
    get computedAttendacneSelectedInfo() : any {
        
        if( this.attend_emp_day_count_info == null || this.attend_emp_month_info == null ) return null;

        let target = this.computedAttendanceWeek.filter(item => item.yyyymmdd == this.attendance_selected);

        if( target.length < 1 ) return null;

        return target[0];
    }

    /**
     * 본인 주간 통계
     */
    get computedAttendanceMyStatistics() : any {

        let data : any = {
            all : 0,
            uncheck : 0,
            normal : 0,
            awol : 0,
            vacation : 0,
            businesstrip : 0,
        };

        for( const day of this.computedAttendanceWeek ) {
            
            // 원래 쉬는날 제외
            if( this.isHoliday(day) ) {
                continue;
            }

            // 미래 날짜 제외
            if( Number(day.yyyymmdd) > Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                // 휴가 일때만 통계에 합침
                if( this.isVacation(day) ) {
                    data.all++;
                    data.vacation++;
                }
                continue;
            }

            data.all++;

            if( this.isVacationAndBusinessTrip(day) ) {
                data.vacation += 0.5;
                data.businesstrip += 0.5;
                continue;
            }

            if( this.isVacation(day) ) {
                data.vacation++;
                continue;
            }

            if( this.isBusinesstrip(day) ) {
                data.businesstrip++;
                continue;
            }

            if( this.isAwol(day) )  {
                data.awol++;
                continue;
            }

            let attend_info : any = null;
            for( const obj of day.data ) {
                if( obj.attend_type == 10 ) {
                    attend_info = obj;
                    break;
                }
            }

            // 정상, 미체크 판단
            // 오늘
            if( Number(day.yyyymmdd) == Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                
                if( attend_info == null || attend_info.start_time == null ) {
                    data.uncheck++;
                    continue;
                }

                data.normal++;
                continue;
            }

            // 과거
            if( attend_info == null || attend_info.start_time == null || attend_info.end_time == null ) {
                data.uncheck++;
                continue;
            }

            data.normal++;
        }

        return data;
    }

    /**
     * 해당 일자 전체 통계
     */
    get computedAttendanceAllStatistics() : any {

        let data : any = {
            all : '0',
            uncheck : '0',
            normal : '0',
            awol : '0',
            late : '0',
            vacation : '0',
            businesstrip : '0',
        };

        if( this.attend_day_count_info != null ) {
            data.all = this.attend_list.length; // TODO 전체 work_cnt 로 해야함
            data.normal = this.attend_day_count_info.normal_cnt ? this.processDayCount(this.attend_day_count_info.normal_cnt) : '0';
            data.uncheck = this.attend_day_count_info.uncheck_cnt ? this.processDayCount(this.attend_day_count_info.uncheck_cnt) : '0';
            data.awol = this.attend_day_count_info.awol_cnt ? this.processDayCount(this.attend_day_count_info.awol_cnt) : '0';
            data.late = this.attend_day_count_info.late_cnt ? this.processDayCount(this.attend_day_count_info.late_cnt) : '0';
            data.vacation = this.attend_day_count_info.vacation_cnt ? this.processDayCount(this.attend_day_count_info.vacation_cnt) : '0';
            data.businesstrip = this.attend_day_count_info.businesstrip_cnt ? this.processDayCount(this.attend_day_count_info.businesstrip_cnt) : '0';
        }
        
        // for( const attend of this.attend_list ) {

        //     if( Number(attend.work_cnt) == 0 && attend.vacation_name.length > 0 ) {
        //         data.all++
        //         data.vacation++
        //         continue;
        //     }

        //     data.all++

        //     if( attend.attend_state == '40' ) {
        //         data.awol++;
        //         continue;
        //     }

        //     if( attend.attend_state == '30' ) {
        //         data.late++;
        //         continue;
        //     }

        //     // 정상, 미체크 판단
        //     // 오늘
        //     if( Number(this.attendance_selected) == Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
        //         if( attend.start_time == null ) {
        //             data.uncheck++;
        //             continue;
        //         }

        //         data.normal++;
        //         continue;
        //     }

        //     // 과거
        //     if( attend.start_time == null || attend.end_time == null ) {
        //         data.uncheck++;
        //         continue;
        //     }

        //     data.normal++;
        // }

        // for( const day of this.computedAttendanceWeek ) {
            
        //     // 원래 쉬는날 제외
        //     if( this.isHoliday(day) ) {
        //         continue;
        //     }

        //     // 미래 날짜 제외
        //     if( Number(day.yyyymmdd) > Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
        //         // 휴가 일때만 통계에 합침
        //         if( this.isVacation(day) ) {
        //             data.all++;
        //             data.vacation++;
        //         }
        //         continue;
        //     }

        //     data.all++;

        //     if( this.isVacation(day) ) {
        //         data.vacation++;
        //         continue;
        //     }

        //     if( this.isAwol(day) )  {
        //         data.awol++;
        //         continue;
        //     }

        //     let attend_info : any = null;
        //     for( const obj of day.data ) {
        //         if( obj.attend_type == 10 ) {
        //             attend_info = obj;
        //             break;
        //         }
        //     }

        //     // 정상, 미체크 판단
        //     // 오늘
        //     // 미래 날짜 제외
        //     if( Number(day.yyyymmdd) == Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                
        //         if( attend_info == null || attend_info.start_time == null ) {
        //             data.uncheck++;
        //             continue;
        //         }

        //         data.normal++;
        //         continue;
        //     }

        //     // 과거
        //     if( attend_info == null || attend_info.start_time == null || attend_info.end_time == null ) {
        //         data.uncheck++;
        //         continue;
        //     }

        //     data.normal++;
        // }

        return data;
    }

    /**
     * 호두키즈 출석부 날짜 및 데이터 생성
     */
    get computedKidsAttendanceWeek() : any[] {
        
        let target : any[] = [];

        // attendance_start를 시작으로 일요일부터 토요일까지 데이터 생성
        for( let i = 0; i < 7; i++ ) {
            const mt : moment.Moment = moment(this.kids_attendance_start).add("day", i);
            const key : string = mt.format('YYYYMMDD');
            target.push({
                "date" : mt.toDate(),
                "yyyymmdd" : key
            });
        }

        return target;
    }

    /**
     * 호두키즈 출석부 선택된 날짜의 정보
     */
    get computedKidsAttendacneSelectedInfo() : any {
        let target = this.computedKidsAttendanceWeek.filter(item => item.yyyymmdd == this.kids_attendance_selected);

        if( target.length < 1 ) return null;

        let selected_info = target[0];

        // selected_info.kids = [];

        // for( let kid of this.kids_attend_emp_month_info ) {
        //     if( kid.jsonb == null || kid.jsonb[this.kids_attendance_selected] == null ) continue;
        //     selected_info.kids.push(kid);
        // }

        return selected_info;
    }

    get computedKidsAttendanceAllStatistics() : any {

        let data : any = {
            all : '0',
            normal : '0',
            absent : '0',
        };

        if( this.kids_attend_day_count_info != null ) {
            data.all = this.kids_attend_day_count_info.total_cnt ? this.processDayCount(this.kids_attend_day_count_info.total_cnt) : '0';
            data.normal = this.kids_attend_day_count_info.normal_cnt ? this.processDayCount(this.kids_attend_day_count_info.normal_cnt) : '0';
            data.absent = this.kids_attend_day_count_info.absent_cnt ? this.processDayCount(this.kids_attend_day_count_info.absent_cnt) : '0';
        }  

        return data;
    }

    event_bus : Vue = new Vue();

    /**
     * @CalendarInfo.Action
     */
    @CalendarInfo.Action doSetCalendarViewType ?: any; // calendar_view_type 업데이트

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetIsFirstRepeatEvent ?: any; // 조회하는 반복일정이 해당 반복일정의 첫 일정이였다면 true 아니라면 false
    @EventInfo.Action doSetEventOriginalDate  ?: any; // 반복일정 조회시 해당 일정의 원본 date들을 설정

    /** 
     * AttendanceInfo.Action 
     */ 
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    // @AttendanceInfo.Action doSetCalendarViewType    ?: (params : attendance_enum.ATTENDANCE_CALENDAR_TYPE) => void;

    /** 
     * ApprovalInfo.Action 
     */ 
    @ApprovalInfo.Action doSetSelectedTab        ?: (params : approval_enum.APPROVAL_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedDraftTab   ?: (params : approval_enum.APPROVAL_DRAFT_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedReceiveTab ?: (params : approval_enum.APPROVAL_RECEIVE_BOX_TAB) => void;
    
    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_modal_info !: GroupModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupModalInfo            ?: any;
    @ModalInfo.Action doSetShowGroupToBizModal       ?: any;
    @ModalInfo.Action doSetShowEventReply            ?: any;
    @ModalInfo.Action doSetGroupNoticeReplyModalInfo ?: (params : GroupNoticeReplyModalInfo) => void;
    @ModalInfo.Action doSetGroupBoardReplyModalInfo  ?: (params : GroupBoardReplyModalInfo) => void;
    @ModalInfo.Action doSetApprovalReplyModalInfo    ?: (params : approval_modal_interface.ApprovalReplyModalInfo) => void;  
    @ModalInfo.Action doSetHoduPremiumPopupInfo      ?: (params : HoduPremiumPopupInfo) => void;

    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;

    /**
     * @OrganizationInfo.Action
     */
    @OrganizationInfo.Action doSetOrganizationMemberSelectedTab ?: (params : organization_enum.ORGANIZATION_SETTING_TAB) => void;

    /**
     * 변수 선언부
     */
    data                  : any     = null;    // 조회되는 데이터
    group_team_name       : string  = "";      // 그룹&팀 명
    group_team_color      : string  = "";      // 그룹&팀 색상
    group_team_image      : string  = "";      // 그룹&팀 이미지
    group_team_descript   : string  = "";      // 그룹&팀 소개글
    biz_id                : string  = "";
    is_admin              : boolean = false;   // 최종관리자여부
    is_manager            : boolean = false;   // 그룹장, 팀장 권한
    is_sub_manager        : boolean = false;   // 부그룹장
    is_modify             : boolean = false;   // 수정권한
    is_filebox            : boolean = false;   // 파일함
    is_work               : boolean = false;   // 업무
    is_member_read        : boolean = false;   // 멤버보기
    is_notice             : boolean = false;   // 공지사항
    is_massage            : boolean = false;   // 메세지
    is_calendar           : boolean = false;   // 일정
    is_group_change       : boolean = false;   // BIZ,일반 그룹으로 변경할수 있는 플래그
    group_team_color_pick : boolean = false;   // 컬러 피커 띄울지 여부

    tab              : string = "STATISTICS";
    notice_board_tab : string = "GROUP_NOTICE";
    move_option      : string = "DASHBOARD";
    menu_option      : string = "NOTIFICATION";

    members : any[] = [];

    draft_approvals : any[] = [];
    receive_approvals : any[] = [];

    project_list : any[] = [];
    meetinglog_list : any[] = [];
    report_list : any[] = [];

    events : any[] = [];
    exdate_events : any[] = [];
    notice_list : any[] = [];
    board_list : any[] = [];
    notification_list : any[] = [];
    message_list : any[] = [];
    reply_notification_list : any[] = [];

    last_noti_uid : string = "";
    notification_page_end : boolean = false;

    last_reply_noti_uid : string = "";
    reply_notification_page_end : boolean = false;

    attendance_start : Date = new Date();
    attendance_end : Date = new Date();
    attendance_selected : string = "";
    attendance_tab : string = "MY";
    attend_emp_day_count_info : any = null;
    attend_emp_month_info : any = null;
    attend_emp_info : any = null;
    attend_list : any[] = []; 
    attend_day_count_info : any = null;

    current_time_millis = new Date().getTime();
    time_interval ?: number;
    air_time_interval ?: number;

    chart : any = null;
    google_chart_data : any[] = [];
    google_chart_option : any = null;

    air_data : any = null;

    kids_attendance_start : Date = new Date();
    kids_attendance_end : Date = new Date();
    kids_attendance_selected : string = "";
    kids_attendance_tab : string = "MY";
    kids_attend_emp_month_info : any[] = [];
    kids_attend_list : any[] = []; 
    kids_attend_day_count_info : any = null;

    kids_chart : any = null;
    kids_google_chart_data : any[] = [];
    kids_google_chart_option : any = null;

    kids : any[] = [];

    group_team_list_open : boolean = false;

    async beforeMount() {

        let dashboard_tab = hodu_local_storage.getItem("dashboard_tab");
        let user_dashboard_tab = dashboard_tab == null ? null : JSON.parse(dashboard_tab)[`user_${this.user_id}`];
        console.log(dashboard_tab);

        try {
            this.tab = (dashboard_tab == null || user_dashboard_tab == null) ? 'STATISTICS' : (user_dashboard_tab[`${this.group_id}`] == null ? 'STATISTICS' : user_dashboard_tab[`${this.group_id}`]);
        
            // 해당 탭에 대한 권한이 있는지 체크후 없으면 STATISTICS 로 만든다
            if( (this.tab == 'APPROVAL' && this.isApprovalEnabled() == false) ||
                (this.tab == 'ATTENDANCE' && this.isAttendanceEnabled() == false) ||
                (this.tab == 'WORK' && this.isWorkEnabled() == false) ) {
                this.tab = 'STATISTICS';
            }

        } catch(e) {
            this.tab = 'STATISTICS';
        }

        if ( this.group_id == 0 ) {
            this.groupTeamNotFound();
            return;
        }

        if ( this.scope_group_id > 0 ) {
            if ( !this.scope_group_team_option.biz_id || this.scope_group_team_option.biz_id.length < 1 ) { 
                this.groupTeamNotFound();
                return;
            }
        }
    }

    async mounted() {

        this.setScroll();

        //공지사항 게시판 tab
        // $(".noticeList .dashTabList a").on("click", function(){
        //     const num = $(".noticeList .dashTabList a").index($(this));
        //     $(".noticeList .dashTabList").removeClass("on");
        //     $(".noticeList .dashTabCon  .grp3_list_con").removeClass("on");

        //     $('.noticeList .dashTabList:eq(' + num + ')').addClass("on");
        //     $('.noticeList .dashTabCon .grp3_list_con:eq(' + num + ')').addClass("on");
        // });

        //팀 보기 메뉴 
        // $(".menTseeBtn a").click(function(){
        //     if($(".first_top").hasClass("on")){
        //         $(".first_top").removeClass("on");
        //     } else{
        //         $(".first_top").addClass("on");
        //     }
        // });

        // $(".tabUl li a").click(function(){
        //     var tabIndex = $(this).parent().index();

        //     $(this).addClass("on");
        //     $(this).parent().siblings().find("a").removeClass("on");

        //     $(".tabDivUl > li").eq(tabIndex).siblings().hide();
        //     $(".tabDivUl > li").eq(tabIndex).show();

        //     return false;
        // });

        
		// // .quickMenu .dropdown 배경 클릭시 드랍다운과 배경없애기
		// $(".first .grp2 .current .bg").click(function(){
		// 	$(".first .grp2").removeClass("hover");
		// 	$(".current .dropdown").removeClass("on");
		// 	$(".quickMenu").removeClass("on");
        // });
        
		// // .quickMenu 빠른메뉴 클릭시 dropdown보이기
		// $(".quickMenu").click(function(){
		// 	$(this).toggleClass("on");
		// 	// 하얀배경으로 컨텐츠 가리기
		// 	$(".first .grp2").toggleClass("hover");
		// 	$("#quickMenu").toggleClass("on");
        // });
        
        // // 오른쪽 .grp2 크게보기
		// $(".grp2 .bigger").click(function(){
		// 	$(".first").toggleClass("wider");
		// });
		
		// // 오른쪽에 버튼 전체 박스 클릭시 드랍다운
		// $(".first .grp2 .usage .btns input").click(function(){
		// 	$(this).siblings().toggleClass("on");
        // });

        await this.get_group_role_service();
        await Promise.all([this.getDashBoardPreference(), this.getGroupDetail(), this.getEvents(), this.getGroupNotice(), this.getGroupBoards()]).then(() => {
            this.getGroupMessage();
            this.getGroupNotification();
            this.getGroupReplyNotification();

            if( this.isHoduKids() == true ) {
                this.getKidsAirPollutionData();
                this.air_time_interval = window.setInterval(() => this.getKidsAirPollutionData(), 600000);
            }
        });
        
        this.time_interval = window.setInterval(() => {
            this.current_time_millis = new Date().getTime();
            this.makeStatisticsData();
            this.makeKidsStatisticsData();
        }, 1000);
    }

    /**
     * 웹소켓 닫기
     */
    beforeDestroy() : void {
        clearInterval(this.time_interval);
        clearInterval(this.air_time_interval);
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {
            const windowOuterHeight   : number | undefined = $(window).outerHeight();
            const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();

            // @ts-ignore
            $('#grpScroll').mCustomScrollbar({
                axis : 'y',
                setHeight : (windowOuterHeight == null ? 0 : windowOuterHeight) - (titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight),
                scrollbarPosition : 'outside',
            });

            const schedule_box_height = $('.grp3_under .grp3_box .grp3_list').outerHeight();
            const title_height = $('.grp3_under .grp3_box .grp3_list .title').outerHeight();

            // @ts-ignore
            $('#group_detail_today_event_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : (schedule_box_height ? schedule_box_height : 0) - (title_height ? title_height : 0) - 16,
            });

            const under_box_height = $('.grp3_under .grp3_box').outerHeight();
            const under_box_padding_top = $('.grp3_under .grp3_list .title').outerHeight();
            const under_box_scroll_height = ((under_box_height ?? 0) / ( this.menu_option == 'NOTIFICATION_AND_MESSAGE' ? 2 : 1)) - (under_box_padding_top ?? 0) - 10;

            // NOTIFICATION
            if( this.menu_option == 'NOTIFICATION' ) {
                // @ts-ignore
                $('#dashboard_notification_scroll').mCustomScrollbar({
                    axis : 'y',
                    scrollbarPosition : 'outside',
                    mouseWheelPixels : 100,
                    scrollInertia : 60,
                    autoDraggerLength : false,
                    setHeight : under_box_scroll_height,
                    callbacks:{
                        whileScrolling: this.whileScrolling
                    }
                });
            }
            // MESSAGE
            else if( this.menu_option == 'MESSAGE' ) {
                // @ts-ignore
                $('#dashboard_message_scroll').mCustomScrollbar({
                    axis : 'y',
                    scrollbarPosition : 'outside',
                    mouseWheelPixels : 100,
                    scrollInertia : 60,
                    autoDraggerLength : false,
                    setHeight : under_box_scroll_height
                });
            }
            // NOTIFICATION_AND_MESSAGE
            else if( this.menu_option == 'NOTIFICATION_AND_MESSAGE' ) {
                // @ts-ignore
                $('#dashboard_notification_scroll').mCustomScrollbar({
                    axis : 'y',
                    scrollbarPosition : 'outside',
                    mouseWheelPixels : 100,
                    scrollInertia : 60,
                    autoDraggerLength : false,
                    setHeight : under_box_scroll_height - 7,
                    callbacks:{
                        whileScrolling: this.whileScrolling
                    }
                });

                // @ts-ignore
                $('#dashboard_message_scroll').mCustomScrollbar({
                    axis : 'y',
                    scrollbarPosition : 'outside',
                    mouseWheelPixels : 100,
                    scrollInertia : 60,
                    autoDraggerLength : false,
                    setHeight : under_box_scroll_height + 7
                });
            }

            // @ts-ignore
            $('#dashboard_reply_notification_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : under_box_scroll_height,
                callbacks:{
                    whileScrolling: this.whileReplyScrolling
                }
            });

            // @ts-ignore
            // $("#grpScroll .content").mCustomScrollbar({
            // 	mouseWheelPixels: 500
            // });

        });
    }

    /**
     * 대시보드 환경설정 가져오기
     */
    async getDashBoardPreference() : Promise<void> {
        try {
            const preference = await this.get_user_preference('dashboard');

            if( preference == null || preference.preference == null || preference.preference[`${this.group_id}`] == null ) {
                this.move_option = "DASHBOARD";
                this.menu_option = "NOTIFICATION";
            }
            else {
                this.move_option = preference.preference[`${this.group_id}`].move_option;
                this.menu_option = preference.preference[`${this.group_id}`].menu_option;
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.move_option = "DASHBOARD";
            this.menu_option = "NOTIFICATION";
        }

        this.handleResize();
    }

    /**
     * 대시보드 이동 옵션 환경설정 저장
     */
    async setDashBoardMoveOptionPreference() : Promise<void> {
        try {
            let preference = await this.get_user_preference('dashboard');
            let body = {};

            if( preference != null && preference.preference != null ) {
                body = preference.preference;
            }

            body[`${this.group_id}`] = { "move_option" : this.move_option, "menu_option" : this.menu_option };

            if( !(preference == null || preference.preference == null || preference.preference[`${this.group_id}`] == null) ) {
                body[`${this.group_id}`].menu_option = preference.preference[`${this.group_id}`].menu_option;
            }

            await this.set_user_preference('dashboard', body);
            Promise.all([this.getDashBoardPreference(), this.getGroupDetail(), this.getEvents(), this.getGroupNotice(), this.getGroupBoards()]).then(() => {
                this.getGroupMessage();
                this.getGroupNotification();
                this.getGroupReplyNotification();

                if( this.isHoduKids() == true ) {
                    this.getKidsAirPollutionData();
                    this.air_time_interval = window.setInterval(() => this.getKidsAirPollutionData(), 600000);
                }
            });

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 대시보드 메뉴 옵션 환경설정 저장
     */
    async setDashBoardMenuOptionPreference() : Promise<void> {
        try {
            this.message_list.splice(0, this.message_list.length);
            this.notification_list.splice(0, this.notification_list.length);

            let preference = await this.get_user_preference('dashboard');
            let body = {};
            body[`${this.group_id}`] = { "move_option" : this.move_option, "menu_option" : this.menu_option };

            if( !(preference == null || preference.preference == null || preference.preference[`${this.group_id}`].preference == null) ) {
                body[`${this.group_id}`].move_option = preference.preference[`${this.group_id}`].move_option;
            }

            await this.set_user_preference('dashboard', body);
            Promise.all([this.getDashBoardPreference(), this.getGroupDetail(), this.getEvents(), this.getGroupNotice(), this.getGroupBoards()]).then(() => {
                this.getGroupMessage();
                this.getGroupNotification();
                this.getGroupReplyNotification();

                if( this.isHoduKids() == true ) {
                    this.getKidsAirPollutionData();
                    this.air_time_interval = window.setInterval(() => this.getKidsAirPollutionData(), 600000);
                }

                this.handleResize();
            });

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 오늘의 일정 조회
     */
    async getEvents() : Promise<void> {

        const temp_event : any[] = [];
        const temp_promise : Promise<any>[] = [];

        let owner_type : string = this.team_id > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP;
        let owner_id   : number = owner_type == OWNER_TYPE.GROUP ? this.group_id : this.team_id;
        let calendar_id : string = owner_type == OWNER_TYPE.GROUP ? `group-${this.group_id}` : `team-${this.team_id}`;

        const start_query : string = moment().set('hour', 0).set('minute', 0).set("second", 0).set('millisecond', 0).utc().format();
        const end_query : string = moment().set('hour', 23).set('minute', 59).set("second", 59).set('millisecond', 999).utc().format();

        let query : string = `?start=${start_query}&end=${end_query}&mycal=false&syscal=false&shared=false`;

        let groups : number[] = [];
        let teams : number[] = [];

        // groups query (그룹 볼때만)
        if( this.user_group_role != null && this.user_group_role.length > 0 && this.team_id < 1 ) {
            const role_size : number = this.user_group_role.length;
            for( let i = 0; i < role_size; i++ ) {

                if ( this.user_group_role[i].group_id == null || this.user_group_role[i].group_id < 1 ) {
                    continue
                }

                groups.push(this.user_group_role[i].group_id);
                    
                // PERSONAL이 아니라면 해당 그룹의 정보만 추가한다
                if( this.group_id > 0 && this.group_id != Number(this.user_group_role[i].group_id) ) {
                    continue;
                }

                query += `&groups=${Number(this.user_group_role[i].group_id)}`;
            }
        }

        // teams query (그룹 : 가입된 팀, 팀 : 해당 팀)
        if( this.user_team_role != null && this.user_team_role.length > 0 ) {
            const role_size : number = this.user_team_role.length;
            for( let i = 0; i < role_size; i++ ) {
                
                if ( this.user_team_role[i].team_id == null || this.user_team_role[i].team_id < 1 ) {
                    continue
                }

                teams.push(this.user_team_role[i].team_id);

                if( this.team_id > 0 ) {
                    if( this.user_team_role[i].team_id != this.team_id ) {
                        continue;
                    }

                    query += `&teams=${Number(this.user_team_role[i].team_id)}`;
                    break;
                }
                    
                if( this.group_id != Number(this.user_team_role[i].group_id) ) {
                    continue;
                }

                query += `&teams=${Number(this.user_team_role[i].team_id)}`;

            }
        }

        try {
            const response = await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}${query}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.events ) {
                throw new Error("이벤트 조회 중 오류발생");
            }
            
            let event_array : t_event[] = response.data.data.events;
            
            this.events.splice(0, this.events.length);
            this.exdate_events.splice(0, this.exdate_events.length);

            const event_count : number = event_array.length;
            for( let i : number = 0; i < event_count; i++ ) {
                let event : t_event = event_array[i];

                // event 데이터가 없거나 event_id가 이상한 경우 건너 뛴다
                if( event == null || event.event_id == null || event.event_id.length < 1 ) continue;

                // 일정 비공개 여부 체크
                if( event.event_data.is_private == true ) {

                    // 권한 구하기
                    let is_permitted : boolean = false;
                    if( event.team_id > 0 ) {
                        if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                            is_permitted = this.is_team_permmision(event.team_id, "event", "private_read");
                        }
                        else if( this.isMeetingLog(event.event_sub_type) ) {
                            is_permitted = this.is_team_permmision(event.team_id, "meetinglog", "private_read");
                        }
                        else if( this.isReport(event.event_sub_type) ) {
                            is_permitted = this.is_team_permmision(event.team_id, "report", "private_read");
                        }
                        else if( this.isWork(event.event_sub_type) ) {
                            is_permitted = this.is_team_permmision(event.team_id, "work", "private_read");
                        }
                    }
                    else if( event.group_id > 0 ) {
                        if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                            is_permitted = this.is_group_permmision(event.group_id, "event", "private_read");
                        }
                        else if( this.isMeetingLog(event.event_sub_type) ) {
                            is_permitted = this.is_group_permmision(event.group_id, "meetinglog", "private_read");
                        }
                        else if( this.isReport(event.event_sub_type) ) {
                            is_permitted = this.is_group_permmision(event.group_id, "report", "private_read");
                        }
                        else if( this.isWork(event.event_sub_type) ) {
                            is_permitted = this.is_group_permmision(event.group_id, "work", "private_read");
                        }
                    }

                    // 숨김일때 본인 작성이 아닌 경우
                    if( event.event_data.event_owner_id != this.user_id && is_permitted == false ) {
                        
                        // 프로젝트인 경우 assignment_type == 'ASSIGN' 인데 담당자가 아니라면 건너 뛴다
                        if( event.event_sub_type == 'WORK' && event.event_data.work != null && event.event_data.work.assignment_type == 'ASSIGN' && 
                            (event.event_data.work.assign_user_ids == null || event.event_data.work.assign_user_ids.filter(user_id => user_id == this.user_id).length < 1) ) {
                            continue;
                        }

                        if( event.event_sub_type != 'WORK' ) {

                            // 프로젝트가 아니라면 공유 받은거만
                            let is_shared = false;

                            if( event.subscribe_users != null ) {
                                is_shared = event.subscribe_users.indexOf(this.user_id) > -1; 
                            }

                            if( event.subscribe_groups != null ) {
                                for( const group_id of event.subscribe_groups ) {
                                    if( groups.indexOf(group_id) > -1 ) {
                                        is_shared = true;
                                        break;
                                    }
                                }
                            }

                            if( event.subscribe_teams != null ) {
                                for( const team_id of event.subscribe_teams ) {
                                    if( teams.indexOf(team_id) > -1 ) {
                                        is_shared = true;
                                        break;
                                    }
                                }
                            }

                            if( is_shared == false ) continue;
                        }
                    }

                }

                // event_id와 event_data.uid가 다른경우 (반복 일정에서 이 일정만 수정으로 생겨난 데이터들) isIgnore가 true면 무시한다
                if( event.event_id != event.event_data.uid && event.event_data.schedule_date.isIgnore == true ) continue;

                // RGB값만 있다면 그대로 RGB를 사용, ARGB라면 ARGB를 RGB로 변환
                event.event_data.color = this.hodu_hex_color_process(event.event_data.color);

                let hasBackground = false;

                // 종일 또는 음력
                if( event.event_data.schedule_date.isAllDay == true || event.event_data.schedule_date.lunar_yn == true ){
                    hasBackground = true;
                }

                // 연속 일정 플래그가 true이거나 시작일과 종료일이 1일 이상 차이날때 (연속 일정)
                if( event.event_data.schedule_date.isContinuos == true || this.getDateDiff(event.event_data.schedule_date.start, event.event_data.schedule_date.end) > 0 ){
                    hasBackground = true;
                }

                let start_date : Date = event.event_data.schedule_date.start instanceof Date 
                                        ? event.event_data.schedule_date.start 
                                        : new Date(moment(event.event_data.schedule_date.start).format());

                let end_date : Date = event.event_data.schedule_date.end instanceof Date 
                                      ? event.event_data.schedule_date.end 
                                      : new Date(moment(event.event_data.schedule_date.end).format());

                const dtStart : Date = new Date(moment(event.event_data.schedule_date.start).format());

                const today_date : Date = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                today_date.setHours(dtStart.getHours());
                today_date.setMinutes(dtStart.getMinutes());
                today_date.setSeconds(dtStart.getSeconds());
                today_date.setMilliseconds(dtStart.getMilliseconds());

                // 반복일정 rrule_dtstart
                let rrule_dtstart : string = this.formatDateForRruleDTSTARTByUTC(dtStart);

                // 반복일정 rrule_until
                let rrule_until : string  = this.formatDateForRruleUNTILByUTC(new Date(moment(event.event_data.schedule_date.recurrence_end).format()));

                // 반복일정일때 사용될 Rrule
                let rrule : string | null  = event.event_data.schedule_date.rrule == null 
                                                ? null 
                                                : `DTSTART:${rrule_dtstart}\nRRULE:${event.event_data.schedule_date.rrule};UNTIL=${rrule_until}`;

                event.event_data.schedule_date.rrule = rrule == null ? undefined : rrule;

                // 반복일정일때 사용될 일정 자체의 기간
                let duration : any  = event.event_data.schedule_date.rrule == null
                                    ? null 
                                    : this.makeDuration(event.event_data.schedule_date.start, event.event_data.schedule_date.end, hasBackground);

                // 일반 일정
                if( rrule == null ) {
                    temp_event.push(event);
                    continue;
                }

                // 반복일정
                let event_list : t_event[] = [];
                let repeat_event : t_event = JSON.parse(JSON.stringify(event));

                // 음력 매년 반복
                if( repeat_event.event_data.schedule_date.lunar_yn == true && repeat_event.event_data.schedule_date.lunar_start != null && repeat_event.event_data.schedule_date.lunar_end != null ) {
                    
                    // 해당 날짜로 년도를 세팅 해준다
                    const target_year_date : Date = new Date();
                    while( target_year_date.getDate() != 1 ) {
                        target_year_date.setDate(target_year_date.getDate() + 1 );
                    }
                    
                    // 음력 문자열
                    let lunar_start : string = repeat_event.event_data.schedule_date.lunar_start.replace(/-/ig, '').substring(0, 8);

                    // 평달 반복인지 윤달 반복인지 체크
                    const intercalation_obj : any = await this.hodu_is_intercalation(moment(repeat_event.event_data.schedule_date.start).format('YYYYMMDD'));

                    // 윤달인 경우, 현재 양력 연도에 해당 반복 일정이 없다면 넘어간다 
                    if( intercalation_obj.is_intercalation ) {
                        let is_search_target_year : boolean = false;
                        for( const next_date of intercalation_obj.next_date_obj ) {
                            if( next_date.solar_ymd.substr(0, 4) == `${target_year_date.getFullYear()}` ) {
                                is_search_target_year = true;
                                break;
                            }
                        }

                        if( is_search_target_year == false ) continue;
                    }

                    lunar_start = lunar_start.replace(/-/ig, '').substring(0, 8);
                    
                    const solar_start_date_promise : Promise<any[]> = this.hodu_lunar_to_solars(lunar_start, intercalation_obj.is_intercalation);
                    let solar_start_date : Date | null = null;

                    solar_start_date_promise.then((dates : any[]) => {
                        for( const date of dates ) {
                            const solar : string = date.solar_ymd
                            const lunar : string = date.lunar_ymd

                            if( solar == this.hodu_date_to_format_string(new Date(), 'YYYY-MM-DD') ) {
                                solar_start_date = new Date(solar);
                                break;
                            }
                        }
                    });

                    // 반복되는 날짜 중 오늘 날짜가 포함되지 않으면 push 금지

                    temp_promise.push(Promise.all([solar_start_date_promise])
                        .then(() => {

                            if( solar_start_date == null ) { return; }

                            // event 데이터가 없거나 event_id가 이상한 경우 건너 뛴다
                            if( repeat_event == null || repeat_event.event_id == null || repeat_event.event_id.length < 1 ) { return; }

                            const solar_end_date : Date = new Date(solar_start_date);
                            solar_end_date.setHours(23);
                            solar_end_date.setMinutes(59);
                            solar_end_date.setSeconds(59);
                            solar_end_date.setMilliseconds(999);

                            console.log(repeat_event);
                            
                            temp_event.push(repeat_event);
                        }));
                    continue;
                }

                // exdate null 체크
                if( repeat_event.event_data.schedule_date.exdate == null ) {
                    repeat_event.event_data.schedule_date.exdate = [];
                }

                const exdate_list : Date[] = repeat_event.event_data.schedule_date.exdate;
                const original_event : t_event = JSON.parse(JSON.stringify(repeat_event));

                const exdate_count : number = repeat_event.event_data.schedule_date.exdate.length;
                for( let i = 0; i < exdate_count; i++ ) {
                    
                    // 왼쪽 오른쪽 자르고 왼쪽은 t_event[]에 push, 오른쪽은 repeat_event에 다시 저장
                    const left_event  : t_event = JSON.parse(JSON.stringify(repeat_event));
                    const right_event : t_event = JSON.parse(JSON.stringify(repeat_event));

                    if( left_event.event_data.schedule_date.rrule == null || right_event.event_data.schedule_date.rrule == null ) {
                        continue;
                    }

                    const start  : Date = new Date(moment(repeat_event.event_data.schedule_date.start).format());
                    const end    : Date = new Date(moment(repeat_event.event_data.schedule_date.end).format());
                    const r_end  : Date = new Date(moment(repeat_event.event_data.schedule_date.recurrence_end).format());
                    const exdate : Date = new Date(moment(exdate_list[i]).format());

                    // 왼쪽, 오른쪽 생성 (exdate가 start ~ recurrence_end 사이라면 실행)
                    if( start.getTime() <= exdate.getTime() &&
                        exdate.getTime() <= r_end.getTime() ) {

                        // 왼쪽 데이터 가공 준비
                        const left_r_end : Date = moment(r_end).set('year', exdate.getFullYear())
                                                                .set('month', exdate.getMonth())
                                                                .set('date', exdate.getDate() - 1).toDate();

                        // 왼쪽 rrule 제조 다시 하기
                        const left_rruleObj : RRule | RRuleSet = rrulestr(i == 0 ? rrule : left_event.event_data.schedule_date.rrule);
                        let   left_rrule_string : string = left_rruleObj.toString();
                        left_rrule_string = left_rruleObj.toString();
                        left_rrule_string = left_rrule_string.substring(0, left_rrule_string.lastIndexOf('UNTIL'));
                        left_rrule_string = `${left_rrule_string}UNTIL=${this.formatDateForRruleUNTILByUTC(left_r_end)}`
                        
                        // 왼쪽 event 필요 데이터 등록 후 event_list에 push
                        left_event.event_data.schedule_date.recurrence_end = left_r_end;
                        left_event.event_data.schedule_date.rrule = left_rrule_string;

                        // dtstart와 until이 같은 날이 아니라면 push
                        // const rrule_for_start_end_equal : RRule | RRuleSet = rrulestr(left_rrule_string);
                        // const dtstart_for_start_end_equal : Date = rrule_for_start_end_equal.options.dtstart;
                        // const until_for_start_end_equal : Date | null = rrule_for_start_end_equal.options.until;
                        // if( until_for_start_end_equal != null && !(
                        //     dtstart_for_start_end_equal.getFullYear() == until_for_start_end_equal.getFullYear() &&
                        //     dtstart_for_start_end_equal.getMonth()    == until_for_start_end_equal.getMonth() &&
                        //     dtstart_for_start_end_equal.getDate()     == until_for_start_end_equal.getDate()
                        // ) ) {
                            event_list.push(JSON.parse(JSON.stringify(left_event)));
                        // }

                        // 오른쪽 데이터 가공 준비
                        const right_start : Date = new Date(start);
                        const right_end   : Date = new Date(end);

                        right_start.setFullYear(exdate.getFullYear());
                        right_start.setMonth(exdate.getMonth());
                        right_start.setDate(exdate.getDate() + 1);

                        right_end.setFullYear(exdate.getFullYear());
                        right_end.setMonth(exdate.getMonth());
                        right_end.setDate(exdate.getDate() + 1);

                        // rrule 제조 다시 하기
                        const right_rruleObj : RRule | RRuleSet = rrulestr(i == 0 ? rrule : right_event.event_data.schedule_date.rrule);
                        let   right_rrule_string : string = right_rruleObj.toString();

                        right_rrule_string = right_rruleObj.toString();
                        right_rrule_string = right_rrule_string.substring(right_rrule_string.lastIndexOf('RRULE:'));
                        right_rrule_string = `DTSTART:${this.formatDateForRruleDTSTARTByUTC(exdate)}\n${right_rrule_string}`;

                        // exdate 부터 시작하는 새로운 RRule
                        const new_rrule : RRule | RRuleSet = rrulestr(right_rrule_string);
                        const right_date : Date[] = new_rrule.all();

                        // console.log(right_date);
                        // 해당하는 반복일정이 하나 뿐이라면 exdate만 남은것이므로 제외시킨다
                        if( right_date.length < 2 ) {
                            repeat_event.event_id = undefined;
                            break;
                        }

                        // exdate 다음인 두번쨰 일정부터 RRule에 포함 시킨다
                        right_rrule_string = right_rruleObj.toString();
                        right_rrule_string = right_rrule_string.substring(right_rrule_string.lastIndexOf('RRULE:'));
                        right_rrule_string = `DTSTART:${this.formatDateForRruleDTSTARTByUTC(right_date[1])}\n${right_rrule_string}`;

                        // 오른쪽 event 필요 데이터 등록 후 repeat_event에 저장
                        right_event.event_data.schedule_date.start = right_start;
                        right_event.event_data.schedule_date.end   = right_end;
                        right_event.event_data.schedule_date.rrule = right_rrule_string;
                        repeat_event = JSON.parse(JSON.stringify(right_event));
                    }

                }

                // event_list를 가공해서 events에 push
                const event_list_count : number = event_list.length;
                this.exdate_events = this.exdate_events.concat(event_list);
                for( let i = 0; i < event_list_count; i++ ) {
                    const event_obj : t_event = event_list[i];

                    // event 데이터가 없거나 event_id가 이상한 경우 건너 뛴다
                    if( event_obj == null || event_obj.event_id == null || event_obj.event_id.length < 1 ) continue;

                    const rrule : RRule | RRuleSet = rrulestr(event_obj.event_data.schedule_date.rrule ?? "");
                    const target_yyyymmdd = Array.from(rrule.all(), x => moment(x).format('YYYYMMDD'));
                    console.log(target_yyyymmdd);
                    
                    if( target_yyyymmdd.indexOf(moment().format('YYYYMMDD')) > -1 ) {

                        const start_hour = moment(event_obj.event_data.schedule_date.start).get('hour');
                        const start_min = moment(event_obj.event_data.schedule_date.start).get('minute');
                        const end_hour = moment(event_obj.event_data.schedule_date.end).get('hour');
                        const end_min = moment(event_obj.event_data.schedule_date.end).get('minute');
                        
                        event_obj.original_start = new Date(event_obj.event_data.schedule_date.start);
                        event_obj.original_end = new Date(event_obj.event_data.schedule_date.end);
                        event_obj.event_data.schedule_date.start = moment().set('hour', start_hour).set('minute', start_min).set('second', 0).set('millisecond', 0).toDate();
                        event_obj.event_data.schedule_date.end = moment().set('hour', end_hour).set('minute', end_min).set('second', 0).set('millisecond', 0).toDate();
                        temp_event.push(event_obj);
                    }
                }

                // event 데이터가 없거나 event_id가 이상한 경우 건너 뛴다
                if( repeat_event == null || repeat_event.event_id == null || repeat_event.event_id.length < 1 ) continue;

                const rrule_obj : RRule | RRuleSet = rrulestr(repeat_event.event_data.schedule_date.rrule ?? "");
                
                const target_yyyymmdd = Array.from(rrule_obj.all(), x => moment(x).format('YYYYMMDD'));
                
                console.log(target_yyyymmdd);

                if( target_yyyymmdd.indexOf(moment().format('YYYYMMDD')) > -1 ) {

                    const start_hour = moment(repeat_event.event_data.schedule_date.start).get('hour');
                    const start_min = moment(repeat_event.event_data.schedule_date.start).get('minute');
                    const end_hour = moment(repeat_event.event_data.schedule_date.end).get('hour');
                    const end_min = moment(repeat_event.event_data.schedule_date.end).get('minute');
                    
                    repeat_event.original_start = new Date(repeat_event.event_data.schedule_date.start);
                    repeat_event.original_end = new Date(repeat_event.event_data.schedule_date.end);
                    repeat_event.event_data.schedule_date.start = moment().set('hour', start_hour).set('minute', start_min).set('second', 0).set('millisecond', 0).toDate();
                    repeat_event.event_data.schedule_date.end = moment().set('hour', end_hour).set('minute', end_min).set('second', 0).set('millisecond', 0).toDate();
                    temp_event.push(repeat_event);
                }

                // alert(repeat_event.event_data.schedule_date.rrule);
            }

            // 음력반복 양력 가져오기 API 전부 완료 될 때 까지 대기 후 events로 담음 
            Promise.all(temp_promise).then(() => {
                this.events.splice(0, this.events.length);
                this.events = this.events.concat(temp_event.filter(event => event.event_sub_type == 'BASIC' || event.event_sub_type == 'CARD').sort((o1, o2) : number => {
                    
                    const o1_isContinious = o1.event_data.schedule_date.isContinuos;
                    const o2_isContinious = o2.event_data.schedule_date.isContinuos;

                    const o1_isAllDay = o1.event_data.schedule_date.isAllDay;
                    const o2_isAllDay = o2.event_data.schedule_date.isAllDay;

                    const o1_isLunarYn = o1.event_data.schedule_date.lunar_yn;
                    const o2_isLunarYn = o2.event_data.schedule_date.lunar_yn;
                    
                    const o1_time = new Date(o1.event_data.schedule_date.start);
                    const o2_time = new Date(o2.event_data.schedule_date.start);

                    // 연속일정 - 음력 - 종일 순으로 우선도가 높다
                    if( o1_isContinious == true && o2_isContinious == true ) {
                        if( o1_isAllDay == false && o2_isAllDay == true ) return -1
                        else if( o1_isAllDay == true && o2_isAllDay == false ) return 1;
                        else if( o1_isAllDay == true && o2_isAllDay == true ) return 0;
                        else {
                            if( o1_isLunarYn == false && o2_isLunarYn == true ) return -1
                            else if( o1_isLunarYn == true && o2_isLunarYn == false ) return 1;
                            else if( o1_isLunarYn == true && o2_isLunarYn == true ) return 0;
                            else {
                                if( o1_time > o2_time ) return 1;
                                else if( o1_time < o2_time ) return -1;
                            }
                        }
                    }
                    else if( o1_isContinious == true && o2_isContinious == false ) return -1;
                    else if( o1_isContinious == false && o2_isContinious == true ) return 1;
                    else {
                        if( o1_time > o2_time ) return 1;
                        else if( o1_time < o2_time ) return -1;
                    }

                    return 0;
                }));
            });
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 공지사항 조회
     */
    async getGroupNotice() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/notification/notice/${this.group_id}/${this.team_id}/${this.team_id > 0 ? 'TEAM' : 'GROUP'}?page=0&size=0&current_date=${moment(new Date()).utc().format()}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list ) {
                throw new Error("공지사항 조회 중 오류발생");
            }

            this.notice_list.splice(0, this.notice_list.length);
            this.notice_list = this.notice_list.concat(response.data.data.list.sort((o1, o2) : number => {
                const o1_time = new Date(o1.noti_info.start_date);
                const o2_time = new Date(o2.noti_info.start_date);

                if( o1_time > o2_time ) return -1;
                else if( o1_time < o2_time ) return 1;
                return 0;
                
            }).slice(0,3));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 게시글 조회
     */
     async getGroupBoards() : Promise<void> {
        try {

            let query = "block_count=2";

            if( this.team_id > 0 ) {
                query += `&team_id=${this.team_id}`;
            }
            
            const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/board?${query}`, API_METHOD.GET);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || ! response.data.data || !response.data.data.posts ) {
                throw new Error("그룹 게시판 조회 중 오류 발생");
            }
            
            this.board_list.splice(0, this.board_list.length);
            this.board_list = this.board_list.concat(response.data.data.posts);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 알림 리스트 조회
     */
    async getGroupNotification(noti_uid ?: string) : Promise<void> {

        // 메세지만 띄운 상태에선 조회안함
        if( this.menu_option == 'MESSAGE' ) {
            this.notification_list.splice(0, this.message_list.length);
            return;
        }

        try {
            // const response = await this.hodu_api_call(`api/v1/groupsweb/dashboard/notification/${this.group_id}/${this.team_id}?block_count=${this.menu_option == 'NOTIFICATION' ? 3 : 1}`, API_METHOD.GET);
            const response = await this.hodu_api_call(`api/v1/groupsweb/dashboard/notification/${this.group_id}/${this.team_id}?block_count=50&noti_uid=${noti_uid ?? ""}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.notification_list ) {
                throw new Error("알림 조회 중 오류발생");
            }

            if( (noti_uid?.length ?? 0) < 1 ) {
                this.notification_list.splice(0, this.notification_list.length);
            }
            this.notification_list = this.notification_list.concat(response.data.data.notification_list);

            this.notification_page_end = response.data.data.is_end_of_paging;

            if( this.notification_list.length > 0 ) {
                this.last_noti_uid = this.notification_list[this.notification_list.length - 1].noti_uid;
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    async getGroupReplyNotification(reply_noti_uid ?: string) : Promise<void> {
        

        try {
            const response = await this.hodu_api_call(`api/v1/groups/dashboard/notification/${this.group_id}/${this.team_id}?block_count=50&noti_uid=${reply_noti_uid ?? ""}&tab=COMMENT`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.notification_list ) {
                throw new Error("알림 조회 중 오류발생");
            }

            if( (reply_noti_uid?.length ?? 0) < 1 ) {
                this.reply_notification_list.splice(0, this.reply_notification_list.length);
            }
            this.reply_notification_list = this.reply_notification_list.concat(response.data.data.notification_list);

            this.reply_notification_page_end = response.data.data.is_end_of_paging;

            if( this.reply_notification_list.length > 0 ) {
                this.last_reply_noti_uid = this.reply_notification_list[this.reply_notification_list.length - 1].noti_uid;
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 메세지 조회
     */
    async getGroupMessage() : Promise<void> {
        
        // 알림만 띄운 상태에선 조회안함
        if( this.menu_option == 'NOTIFICATION' ) {
            this.message_list.splice(0, this.message_list.length);
            return;
        }
 
        try {
            // const response = await this.hodu_api_call(`api/v1/notification/message/${this.group_id}/${this.team_id}?block_cnt=${ this.menu_option == 'MESSAGE' ? 3 : 1 }`, API_METHOD.GET);
            const response = await this.hodu_api_call(`api/v1/notification/message/${this.group_id}/${this.team_id}?block_cnt=50`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.message_list ) {
                throw new Error("공지사항 조회 중 오류발생");
            }

            this.message_list.splice(0, this.message_list.length);
            this.message_list = this.message_list.concat(response.data.data.message_list.sort((o1, o2) : number => {
                const o1_time = new Date(o1.audit_created);
                const o2_time = new Date(o2.audit_created);

                if( o1_time > o2_time ) return -1;
                else if( o1_time < o2_time ) return 1;
                return 0;  
            }));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 그룹 단건 조회
     */
    async getGroupDetail() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getDetail/${this.group_id}/${this.team_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                if ( !response.data.data.data ) {
                    await vue.groupTeamNotFound();
                    return;
                }

                this.data                = response.data.data.data;
                this.group_team_name     = (this.team_id > 0 ? this.data.team_info.team_name : this.data.group_info.group_name);
                this.group_team_color    = this.data.user_group_team_color;
                this.group_team_image    = (this.team_id > 0 ? this.data.team_info.image : this.data.group_info.image);
                this.group_team_descript = (this.team_id > 0 ? this.data.team_info.description : this.data.group_info.description);
                this.is_manager          = (this.team_id > 0 ? this.data.team_role == this.GroupAuth.TEAM_MANAGER : this.data.group_role == this.GroupAuth.GROUP_MANAGER);
                this.is_admin            = (this.data.group_role == this.GroupAuth.ADMIN || this.data.team_role == this.GroupAuth.ADMIN);
                this.is_sub_manager      = (this.team_id > 0 ? false : this.data.group_role == this.GroupAuth.GROUP_SUB_MANAGER);
                this.is_filebox          = (this.data.group_features.filebox.enable && this.is_group_permmision(this.group_id, 'file', 'read'));
                this.is_work             = this.data.group_features.work.enable && (this.team_id == 0 ? this.is_group_permmision(this.group_id, 'work', 'read') : this.is_team_permmision(this.team_id, 'work', 'read'));
                this.is_member_read      = (this.team_id == 0 ? this.is_group_permmision(this.group_id, 'member', 'read') : this.is_team_permmision(this.team_id, 'member', 'read'))
                this.is_notice           = this.data.group_features.notice.enable;
                this.is_massage          = this.data.group_features.message.enable;
                this.is_calendar         = this.data.group_features.calendar.enable;
                this.biz_id              = this.data.biz_id;
                this.group_team_color    = await this.hodu_hex_color_process(this.group_team_color);
                this.is_group_change     = this.team_id == 0 && (this.is_manager || this.is_admin) ? true : false;
                
                const event_count = response.data.data.event_count;

                if( this.data.group_type == 'GROUP' && event_count >= 1000 ) {
                    const popup_time = hodu_local_storage.getItem("hodu_premium_popup_time");
                    if( popup_time == null || (popup_time != null && (new Date().getTime() - Number(popup_time)) >= 60 * 60 * 1000) ) {
                        this.doSetHoduPremiumPopupInfo?.({ show_modal : true });
                    }
                }

                // 호두 키즈인경우 탭이 존재할수 없는거라면 출석부로 고정
                if( this.isHoduKids() == true && this.tab.includes('KIDS') == false ) {
                    this.tab = 'KIDS_ATTENDANCE';
                }

                this.handleResize();

            })
            .catch(async(e) => {
                await vue.groupTeamNotFound();
                return;
            });

        this.is_modify = false;
        this.is_modify = (( this.group_id > 0 && this.team_id == 0 ) ? this.is_group_permmision(this.group_id, 'group_info', 'modify') : this.is_team_permmision(this.team_id, 'team_info', 'modify'));
    }

    /**
     * 호두키즈 공기 정보 조회
     */
    async getKidsAirPollutionData() : Promise<void> {
        try {
            const partner_key = this.getGroupPartnerKey(this.group_id);
            const response = await this.hodu_api_call(`api/v1/kids/air?partner_key=${partner_key}`, API_METHOD.GET, null ,false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("호두키즈 공지 정보 조회 중 오류 발생");
            }

            if( response.data.data.air_data == null ) {
                this.air_data = null;
                return;
            }

            this.air_data = JSON.parse(JSON.stringify(response.data.data.air_data));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 페이지 새로고침
     * p_func_name : 값이 있으면 새로고침 이 아닌 다른 func 호출
     */
    async init(p_group_id, p_team_id, p_func_name) : Promise<void> {

        await this.hodu_show_indicator();

        try {
            if ( !p_func_name ) {
                await this.doGroupId(p_group_id);
                await this.doTeamId(p_team_id);

                await this.get_group_role_service();
                await this.getGroupDetail();
                await this.getGroupNotice();
                await this.getGroupBoards();
                await this.getGroupMessage();
                await this.getGroupNotification();
                await this.getGroupReplyNotification();
                await this.getEvents();
                
                await this.hodu_show_indicator();
                // @ts-ignore
                await this.$refs.group_team_list.getGroupTeam();

                await this.hodu_show_indicator();
                
                // @ts-ignore
                // await this.$refs.group_notice.getNotice();

                await this.hodu_show_indicator();
                
                // @ts-ignore
                // await this.$refs.group_member.getGroupMember();

                await this.hodu_show_indicator();

                // @ts-ignore
                if( this.$refs.group_statistics ) await this.$refs.group_statistics.getList();

                await this.hodu_show_indicator();
                if( p_team_id > 0 ) {
                    for( const group of this.all_group_data ) {
                        if( group.group_id != p_group_id ) {
                            continue;
                        }
                        
                        for( const team of group.teams ) {
                            if( team.team_id != p_team_id ) { continue; }

                            this.data.group_type = group.biz_type;
                        }
                    }
                }

            }
            else {
                if ( p_func_name == "team_add" ) {
                    await this.group_team_add();
                }
            }

            // this.handleResize();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        } finally {
            await this.hodu_hide_indicator();
        }
        
        
    }

    /**
     * 모달창 아니오
     */
    groupListConfirmCancel() : void {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 모달창 예
     */
    groupListConfirm(po_data) : void {
        const vue = this;

        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;

        if ( po_data.modal_type == "my_out" ) {
            let url = "";

            if ( po_data.team_id > 0 ) {
                url = `api/v1/teams/teamMemberDelete/${po_data.group_id}/${po_data.team_id}/${po_data.user_id}`;
            }
            else {
                url = `api/v1/groups/groupMemberDelete/${po_data.group_id}/${po_data.user_id}`;
            }

            this.hodu_api_call(url, API_METHOD.DELETE, null)
                .then(async(response) => {
                    await vue.get_group_role_service();

                    if ( this.team_id > 0 ) {
                        vue.init(po_data.group_id, 0, "");
                    }
                    else {
                        vue.groupTeamNotFound();
                    }
                    return;
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        this.doSetGroupModalInfo(this.group_modal_info);
    }        

    /**
     * 그룹 팀 나가기
     */
    group_team_out() : void {
        let param_data : GroupListConfirmModalData = {
            modal_type       : "my_out",
            type             : (this.team_id > 0 ? 'TEAM' : 'GROUP'),
            group_id         : this.group_id,
            team_id          : this.team_id,
            color            : this.group_team_color,
            image            : this.group_team_image,
            description      : this.group_team_descript,
            name             : this.group_team_name,
            user_id          : this.user_id!,
            is_appr_required : false,
            appr_state       : "",
            member_created   : ""
        }

        window["groupListConfirmCancel"] = this.groupListConfirmCancel;
        window["groupListConfirm"]       = this.groupListConfirm;        

        this.group_modal_info.show_group_list_confirm = true;
        this.group_modal_info.data = param_data;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 멤버관리 이동
     */
    async goMemberList() {

        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);
        this.doGroupTeamOption({group_team_name : this.group_team_name,
                                group_team_color : this.group_team_color,
                                group_team_image : this.group_team_image,
                                group_team_descript : this.group_team_descript,
                                biz_id : this.biz_id});

        // 전자결재 또는 근태현황 중 하나라도 사용시
        if( (this.isEnableGroupFeature(this.group_id, 'attendance') || this.isEnableGroupFeature(this.team_id, 'approval')) ) {
            await this.groupMainPageGo('/');
            this.doSetOrganizationMemberSelectedTab?.(organization_enum.ORGANIZATION_SETTING_TAB.MEMBER);
            this.hodu_router_push(`/${this.team_id > 0 ? 'TEAM' : 'GROUP'}/${this.team_id > 0 ? this.team_id : this.group_id}/organization/member/setting?${new Date().getTime()}`);
            return;
        }

        this.hodu_router_push(`/group/${new Date().getTime()}/member`);
    }

    /**
     * 공지사항 이동
     */
    goGroupNotice() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);
        this.doGroupTeamOption({group_team_name : this.group_team_name,
                                group_team_color : this.group_team_color,
                                group_team_image : this.group_team_image,
                                group_team_descript : this.group_team_descript,
                                biz_id : this.biz_id,
                                noti_uid : ""});

        this.hodu_router_push(`/group/${new Date().getTime()}/notice`);
    }

    /**
     * 공지사항 상세이동
     */
    goNoticeDetail(noti_uid) : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);
        this.doGroupTeamOption({group_team_name : this.group_team_name,
                                group_team_color : this.group_team_color,
                                group_team_image : this.group_team_image,
                                group_team_descript : this.group_team_descript,
                                biz_id : this.biz_id,
                                noti_uid : noti_uid});

        this.hodu_router_push(`/group/${new Date().getTime()}/notice_detail`);
    }

    /**
     * 파일함 이동
     */
    goGroupFileList() : void {
        // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
        if( this.scope_group_id == this.group_id && this.scope_team_id == this.team_id ) {
            this.hodu_router_push(`/group/${new Date().getTime()}/file`);
            return;
        }
        else {
            this.groupMainPageGo(`/group/${new Date().getTime()}/file`);
        }
    }

    /**
     * 그룹 팀 수정
     */
    group_team_modify() : void {

        if( this.is_modify == false ) return;

        if ( this.team_id > 0 ) {
            this.doGroupId(this.group_id);
            this.doTeamId(this.team_id);
            this.doGroupTeamOption({group_team_name : this.group_team_name,
                                    group_team_color : this.group_team_color,
                                    group_team_image : this.group_team_image,
                                    group_team_descript : this.group_team_descript,
                                    biz_id : this.biz_id});

            this.hodu_router_push(`/team/${new Date().getTime()}/create`);
        }
        else {
            this.doGroupId(this.group_id);
            this.hodu_router_push(`/group/${new Date().getTime()}/create`);
        }
    }

    /**
     * 팀 생성
     */
    group_team_add() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(0);
        this.doGroupTeamOption({group_team_name : this.group_team_name,
                                group_team_color : this.group_team_color,
                                group_team_image : this.group_team_image,
                                group_team_descript : this.group_team_descript,
                                biz_id : this.biz_id});        

        this.hodu_router_push(`/team/${new Date().getTime()}/create`);
    }

    /**
     * 일정
     */
    async goGroupCalendar(data ?: any) : Promise<void> {

        if( data != null ) {
            const vue = this;

            const targets = vue.group_info_list.filter(item => item.group_id);
            if( targets.length < 1 ) return;

            const target = targets[0];

            if( $('#bt_calContr').hasClass('on') == false ) {
                $('#container').find('#left_area').find('.left_inbox').addClass('on');
                $('.ui-datepicker-calendar-wrap').hide();
                $('#bt_calContr').addClass('on');

                const left_area_height : number | undefined = $('#left_area').outerHeight();
                const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

                const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
                const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

                let heightSize : number = left_area_height_final - left_inbox_height_final;
                $('.left_inbox02').height(heightSize+"px");
            }

            await vue.doSetGroupMenuClose(true);
            await vue.doSetMessageMenuClose(true);
            await vue.doSetWorkMenuClose(true);
            await vue.doSetCommunityMenuClose(true);
            await vue.doSetResidentMenuClose(true);

            await vue.doSetCalendarId(`group-${data.group_id}`);
            await vue.doSetScope(OWNER_TYPE.GROUP);  
            await vue.doSetScopeGroupId(data.group_id);  
            await vue.doSetScopeTeamId(0);  

            // 조회
            await vue.doSetScopeGroupTeamOption({
                group_team_name : target.group_name,
                group_team_color : target.color,
                group_team_image : `profile/group/${Math.floor(target.group_id / 10000)}/${target.group_id}.jpg`,
                group_team_descript : '',
                biz_id : target.biz_id,
                biz_type : target.biz_type
            });

            await vue.hodu_loading_timer_exit();
            await vue.hodu_loading();

            setTimeout(() => {
                // @ts-ignore
                $('.left_inbox02').mCustomScrollbar('destroy');
                window['left_inbox02_mCustomScrollbar']();
            }, 100);

            if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
            return;
        }

        const target_date : Date = new Date();
        const start_date : Date = new Date(target_date.getTime());
        const end_date   : Date = new Date(target_date.getTime());

        // 시작 시간이 '정시' 거나 '23시' 일 경우는 그대로 사용한다 
        if( target_date.getMinutes() != 0 && target_date.getHours() != 23 ){
           start_date.setHours(target_date.getHours() + 1);
        }
        
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        // 시작시간이 23시라면 23시 50분 고정, 아니라면 시작시간 + 1시간에 0분
        if( start_date.getHours() == 23 ){
            end_date.setHours(23);
            end_date.setMinutes(50);
        } else {
            end_date.setHours(start_date.getHours() + 1);
            end_date.setMinutes(0);
        }

        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        // event 기본 값 설정
        const event : t_event = {
            "audit_created": new Date(),
            "audit_delete_flag": false,
            "audit_deleted": null,
            "audit_modified": new Date(),
            "audit_user_id": 0,
            "calendar_id": (this.team_id > 0 ? `team-${this.team_id}` : `group-${this.group_id}`),
            "event_data": {
                "alarm": [],
                "attachment": {
                    "files": [],
                    "imgs": []
                },
                "attend": false,
                "color": "#477FFF",
                "event_owner_group_id": (this.team_id > 0 ? 0 : this.group_id),
                "event_owner_group_name": this.team_id > 0 ? "" : this.group_team_name,
                "event_owner_id": this.user_id,
                "event_owner_name": this.user_name,
                "event_owner_team_id": (this.team_id > 0 ? this.team_id : 0),
                "event_owner_team_name": this.team_id > 0 ? this.group_team_name : "",
                "event_push_yn": true,
                "event_sub_type": EVENT_SUB_TYPE.SCHEDULE,
                "location": [],
                "memo": "",
                "note": "",
                "percent_complete": 0,
                "priority": 0,
                "schedule_date": {
                    "end": end_date,
                    "isAllDay": false,
                    "isContinuos": false,
                    "isIgnore": false,
                    "lunar_yn": false,
                    "recurrence_end": end_date,
                    "start":  start_date
                },
                "contacts" : [{
                    "name" : "",
                    "tel" : ""
                }],
                "status": "",
                "title": "",
                "uid": "",
                "version": "1",
                "vote": [],
                "is_private": false,
            },
            "event_id": "",
            "event_type": (this.team_id > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP),
            "event_sub_type": EVENT_SUB_TYPE.SCHEDULE,
            "user_id": 0,
            "team_id": this.team_id,
            "group_id": (this.team_id > 0 ? 0 : this.group_id),
            "subscribe_users": [],
            "subscribe_groups": [],
            "subscribe_teams": [],
            "duration" : "",
        }

        // EventInfo에 이벤트 등록
        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.CREATE);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 일정 등록 페이지로 이동 
        this.hodu_router_push('/event');
    }

    /**
     * 업무
     */
    goGroupWork() : void {
        // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
        if( this.scope_group_id == this.group_id && this.scope_team_id == this.team_id ) {
            this.hodu_router_push(`/work/${new Date().getTime()}/list`);
            return;
        }
        else {
            this.groupMainPageGo(`/work/${new Date().getTime()}/list`);
        }
    }

    /**
     * 회의록 이동
     */
    goGroupMeetinglog() : void {
        // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
        if( this.scope_group_id == this.group_id && this.scope_team_id == this.team_id ) {
            this.hodu_router_push(`/meetinglog?${new Date().getTime()}`);
            return;
        }
        else {
            this.groupMainPageGo(`/meetinglog?${new Date().getTime()}`);
        }
    }

    /**
     * 업무일지 이동
     */
    goGroupReport() : void {
        // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
        if( this.scope_group_id == this.group_id && this.scope_team_id == this.team_id ) {
            this.hodu_router_push(`/report?${new Date().getTime()}`);
            return;
        }
        else {
            this.groupMainPageGo(`/report?${new Date().getTime()}`);
        }
    }

    /**
     * 근태현황으로 이동
     */
    goGroupAttendance() : void {

        this.doSetAttendanceStartDate(new Date());
        this.$store.dispatch("AttendanceInfo/doSetCalendarViewType", (this.get_group_role(this.group_id) == 'GROUP_MANAGER' || this.get_group_role(this.group_id) == 'ADMIN') ? attendance_enum.ATTENDANCE_CALENDAR_TYPE.DAYGRID_MONTH : attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST);

        // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
        if( this.scope_group_id == this.group_id ) {
            this.hodu_router_push(`/GROUP/${this.group_id}/attendance?${new Date().getTime()}`);
            return;
        }
        else {
            this.groupMainPageGo(`/GROUP/${this.group_id}/attendance?${new Date().getTime()}`);
        }
    }

    /**
     * 전자결재로 이동
     */
    goGroupApproval() : void {

        this.doSetSelectedTab?.(approval_enum.APPROVAL_BOX_TAB.DRAFT);
        this.doSetSelectedDraftTab?.(approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
        this.doSetSelectedReceiveTab?.(approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);

        // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
        if( this.scope_group_id == this.group_id ) {
            this.hodu_router_push(`/GROUP/${this.group_id}/approval?${new Date().getTime()}`);
            return;
        }
        else {
            this.groupMainPageGo(`/GROUP/${this.group_id}/approval?${new Date().getTime()}`);
        }
    }

    /**
     * 메세지
     */
    goGroupMsg() : void {
        // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
        if( this.scope_group_id == this.group_id && this.scope_team_id == this.team_id ) {
            this.hodu_router_push(`/message/${new Date().getTime()}/send`);
            return;
        }
        else {
            this.groupMainPageGo(`/message/${new Date().getTime()}/send`);
        }
    }

    /**
     * 메인이동
     */
    async groupTeamCalendar(event) : Promise<void> {
        event.stopPropagation();
        event.preventDefault();

        // 이미 해당 달력이라면 그냥 이동만함
        if( !this.isPersonalScope(this.scope as OWNER_TYPE) == true  && this.group_id == this.scope_group_id && this.team_id == this.scope_team_id ) {
            this.hodu_router_push('/');
            return;
        }

        this.groupMainPageGo('/');
    }

    /**
     * 비즈화면 이동
     */
    async groupMainPageGo(ps_pagePath) : Promise<void> {

        if( $('#bt_calContr').hasClass('on') == false ) {
            $('#container').find('#left_area').find('.left_inbox').addClass('on');
            $('.ui-datepicker-calendar-wrap').hide();
            $('#bt_calContr').addClass('on');

            const left_area_height : number | undefined = $('#left_area').outerHeight();
            const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

            const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
            const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

            let heightSize : number = left_area_height_final - left_inbox_height_final;
            $('.left_inbox02').height(heightSize+"px");
        }

        await this.doSetGroupMenuClose(true);
        await this.doSetMessageMenuClose(true);
        await this.doSetWorkMenuClose(true);
        await this.doSetCommunityMenuClose(true);
        await this.doSetResidentMenuClose(true);

        await this.doSetCalendarId(this.team_id > 0 ? `team-${this.team_id}` : `group-${this.group_id}`);
        await this.doSetScope(this.team_id > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP);
        await this.doSetScopeGroupId(this.group_id);
        await this.doSetScopeTeamId(this.team_id);
        await this.doSetScopeGroupTeamOption({
            group_team_name     : this.group_team_name,
            group_team_color    : this.group_team_color,
            group_team_image    : this.group_team_image,
            group_team_descript : this.group_team_descript,
            biz_id              : this.biz_id,
            biz_type            : this.data.group_type,
        });

        // 병원 달력인 경우 무조건 월 달력으로 변경 시킨다
        if( this.data.group_type == GROUP_TYPE.BIZD ) {
            this.doSetLoadingBouncy(true);
            this.doSetLoadingEnd1(false);
            this.doSetLoadingEnd2(false);
            this.doSetLoadingEnd3(false);           
            
            // 2020-11-26 LSJ - 병렬처리를 위해 await 제거했음
            Promise.all([this.get_hodu_d_info(this.biz_id), this.doSetCalendarViewType(CALENDAR_TYPE.DAY_GRID_MONTH)]).then(async() => {
                await this.hodu_loading_timer_exit();
                await this.hodu_loading();
                this.hodu_router_push(`${ps_pagePath}`);

                setTimeout(() => {
                    // @ts-ignore
                    $('.left_inbox02').mCustomScrollbar('destroy');
                    window['left_inbox02_mCustomScrollbar']();
                }, 100);

                if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
            });

            return;
        }

        await this.hodu_loading_timer_exit();
        await this.hodu_loading();
        this.hodu_router_push(`${ps_pagePath}`);

        setTimeout(() => {
            // @ts-ignore
            $('.left_inbox02').mCustomScrollbar('destroy');
            window['left_inbox02_mCustomScrollbar']();
        }, 100);

        if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
    }

    /**
     * BIZ -> 일반
     * 일반 -> BIZ
     */
    async biz_group_change(event) : Promise<void> {
        // this.hodu_show_dialog("alert", `${(!this.biz_id ? 'BIZ 그룹으로 변경 하시겠습니까?' : '일반그룹으로 변경 하시겠습니까?')}`, ['아니오', '예'], [
        //     () => {},
        //     () => {
        //         
        //     },
        // ]);

        event.stopPropagation();
        event.preventDefault();

        // 인증 체크
        if( (this.auth_info == null || this.auth_info.auth_check == false) ) {
            try {
                let popup : Window | null = null;

                // window['skip_button_select'] = false;

                window['auth_callback'] = (user_info) => {
                    try {
                        this.doSetAuthInfo(user_info.auth_info);
                        this.doSetUserPhoneNumber(user_info.user_phone_number);
                        this.doSetCountryCode(user_info.country_code);
                        window['groupChangeService'] = this.groupChangeService;
                        this.doSetShowGroupToBizModal(true);
                        
                        // console.log(authResult);
                        // this.firebase_auth(authResult, auth_check_later, (user_info) => {
                        //     try {
                        //         console.log(user_info);
                        //         this.doSetAuthInfo(user_info.auth_info);
                        //         this.doSetUserPhoneNumber(user_info.user_phone_number);
                        //         this.doSetCountryCode(user_info.country_code);

                        //         if( auth_check_later == true ) {
                        //             return;
                        //         }

                        //         window['groupChangeService'] = this.groupChangeService;
                        //         this.doSetShowGroupToBizModal(true);
                                
                        //     } catch(e) {
                        //         this.hodu_error_process(e, false, false, true);
                        //     }
                        // });
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    } finally {
                        popup?.close();
                    }
                }

                const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                const systemZoom = width / window.screen.availWidth;

                const w = 500;
                const h = 626;
                const l = (width - w) / 2 / systemZoom + dualScreenLeft;
                const t = (height - h) / 2 / systemZoom + dualScreenTop;

                popup = window.open(`/naver-auth.html?device_uid=${this.get_device_uid()}&session_token=${this.get_session_token()}`, `naver-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
            return;
        }

        window['groupChangeService'] = this.groupChangeService;
        this.doSetShowGroupToBizModal(true);
    }

    async groupChangeService() : Promise<void> {
        await this.get_group_role_service();
        Promise.all([this.getDashBoardPreference(), this.getGroupDetail(), this.getEvents(), this.getGroupNotice(), this.getGroupBoards()]).then(() => {
            
            const promise : Promise<any>[] = [];
            
            promise.push(this.getGroupMessage());
            promise.push(this.getGroupNotification());
            promise.push(this.getGroupReplyNotification());

            if( this.isHoduKids() == true ) {
                promise.push(this.getKidsAirPollutionData());
            }

            Promise.all(promise).then(() => {
                if( this.isHoduKids() == true ) {
                    this.air_time_interval = window.setInterval(() => this.getKidsAirPollutionData(), 600000);
                }
                this.hodu_show_dialog('success', "프리미엄 그룹 전환 완료", ['확인']);
            });
        });

        // window.open(`/voucher.html?is_voucher=false&group_id=${this.group_id}`, "_blank");

        // this.hodu_api_call(`api/v1/groupsweb/${this.biz_id ? 'groupChange' : 'bizGroupChange'}/${this.group_id}`, API_METHOD.PUT, null)
        //     .then(async(response) => {
        //         // this.groupTeamNotFound();
        //         this.getGroupDetail();
        //         this.get_group_role_service();
        //     })
        //     .catch(async(e) => {
        //         this.hodu_error_process(e, true, false);
        //     });        
    }

    /**
     * 그룹&팀 조회시 에러나 날경우
     */
    async groupTeamNotFound() : Promise<void> {
        if ( this.scope_group_id > 0 ) {
            await this.hodu_loading_timer_exit();
            await this.hodu_loading();
        }

        this.reset_biz_initialization();
        this.reset_group_initialization();

        this.hodu_router_push(`/group/${new Date().getTime()}`);

        setTimeout(() => {
            // @ts-ignore
            $('.left_inbox02').mCustomScrollbar('destroy');
            window['left_inbox02_mCustomScrollbar']();

        }, 100);

        return;
    }

    /**
     * 그룹, 팀 컬러 피커 ON / OFF
     */
    groupTeamColorPickOnOff(event) : void {
        event.stopPropagation();
        event.preventDefault();
        this.group_team_color_pick = !this.group_team_color_pick;
    }

    /**
     * 그룹 & 팀 개인 색상 설정
     */
    groupTeamColorChange(event, color : string) : void {

        event.stopPropagation();
        event.preventDefault();
        
        const cate : string = this.team_id > 0 ? 'team_color' : 'group_color';
        const value : string = color;
        const target_id : number = this.team_id > 0 ? this.team_id : this.group_id;

        this.hodu_api_call(`api/v1/groups/updateGroupUserPreference`, API_METHOD.PUT, {
            "cate" : cate,
            "value" : value,
            "id" : target_id
        }).then(async(response) => {
            console.log(response);
            this.group_team_color_pick = false;
            await this.get_group_role_service();
            Promise.all([this.getDashBoardPreference(), this.getGroupDetail(), this.getEvents(), this.getGroupNotice(), this.getGroupBoards()]).then(() => {
                this.getGroupMessage();
                this.getGroupNotification();
                this.getGroupReplyNotification();

                if( this.isHoduKids() == true ) {
                    this.getKidsAirPollutionData();
                    this.air_time_interval = window.setInterval(() => this.getKidsAirPollutionData(), 600000);
                }
            });
            this.scope_group_team_option.group_team_color = color;
            this.doSetScopeGroupTeamOption(this.scope_group_team_option);
            if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
            
        }).catch((e) => {
            this.hodu_error_process(e, true, false);
        })
    }
    
    /**
     * 현재 보고있는 달력의 그룹 또는 팀인지 판단
     */
    isCurrentCalendarGroupOrTeam() : boolean {
        return this.group_id == this.scope_group_id && this.team_id == this.scope_team_id;
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return yyyymmdd && yyyymmdd.length >= 8 ? new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-')) : new Date();
    }

    /**
     * 이동
     */
    move(type : string) : void {

        // <li class="notice new" v-if="is_notice" @click="goGroupNotice()"><a>공지사항</a></li>
        // <li class="notice" v-if="is_notice" @click="goGroupNotice()"><a>공지사항</a></li>
        // <li class="sch" v-if="is_calendar" @click="goGroupCalendar()"><a>일정</a></li>
        // <li class="work" v-if="is_work" @click="goGroupWork()"><a>프로젝트</a></li>
        // <li class="file" v-if="is_filebox" @click="goGroupFileList()"><a>파일함</a></li>
        // <li class="mem" v-if="is_member_read"><a id="goToMem1" @click="goMemberList()">{{isHoduH(scope_group_team_option.biz_type ? scope_group_team_option.biz_type : '') ? "입주자 관리" : "멤버관리"}}</a></li>
        // <li class="msg" v-if="is_massage" @click="goGroupMsg()"><a>메세지</a></li>

        if ( type == 'EVENT' ) {
            this.goGroupCalendar();
        }
        // else if ( type == 'CALENDAR' ) {

        // }
        else if ( type == 'PROJECT' ) {
            this.goGroupWork();
        }
        else if ( type == 'MEETINGLOG' ) {
            this.goGroupMeetinglog();
        }
        else if ( type == 'REPORT' ) {
            this.goGroupReport();
        }
        else if ( type == 'ATTENDANCE' ) {
            this.goGroupAttendance();
        }
        else if ( type == 'APPROVAL' ) {
            this.goGroupApproval();
        }
        else if ( type == 'MEMBER' ) {
            this.goMemberList();
        }
        else if ( type == 'MESSAGE' ) {
            this.goGroupMsg();
        }
        else if ( type == 'NOTIFICATION' ) {
            this.goGroupNotice();
        }

        // else if ( type == 'CHATTING' ) {

        // }

    }

    @Watch('tab')
    @Watch('team_id')
    async tabChange() : Promise<void> {

        let obj : any = null;

        let dashboard_tab = hodu_local_storage.getItem("dashboard_tab");
        if( dashboard_tab == null || dashboard_tab[`user_${this.user_id}`] == null || obj[`user_${this.user_id}`][`${this.group_id}`] == null ) {
            obj = {};
            obj[`user_${this.user_id}`] = {};
            obj[`user_${this.user_id}`][`${this.group_id}`] = this.tab;
        }
        else {
            obj = JSON.parse(JSON.stringify(dashboard_tab));
            obj[`user_${this.user_id}`][`${this.group_id}`] = this.tab;
        }
        
        hodu_local_storage.setItem("dashboard_tab", JSON.stringify(obj));
        console.log(JSON.stringify(obj));
        
        // @ts-ignore
        $('#group_detail_manager_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#group_detail_sub_manager_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#group_detail_member_scroll').mCustomScrollbar('destroy');

        // @ts-ignore;
        $('#group_detail_approval_draft_box_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore;
        $('#group_detail_approval_receive_box_scroll').mCustomScrollbar('destroy');

        // @ts-ignore;
        $('#group_detail_meetinglog_scroll').mCustomScrollbar('destroy');

        // @ts-ignore;
        $('#group_detail_report_scroll').mCustomScrollbar('destroy');

        // @ts-ignore;
        $('#group_detail_project_scroll').mCustomScrollbar('destroy');

        // @ts-ignore;
        $('#kids_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();

        this.members.splice(0, this.members.length);
        this.draft_approvals.splice(0, this.draft_approvals.length);
        this.receive_approvals.splice(0, this.receive_approvals.length);
        this.project_list.splice(0, this.project_list.length);
        this.meetinglog_list.splice(0, this.meetinglog_list.length);
        this.report_list.splice(0, this.report_list.length);
        this.kids.splice(0, this.kids.length);

        this.attend_emp_day_count_info = null;
        this.attend_emp_month_info = null;
        this.attend_emp_info = null;
        this.attendance_tab = 'MY';

        if( this.tab == 'STATISTICS' ) return;
        else if( this.tab == 'QUICKMENU') return;
        else if( this.tab == 'MEMBER') {
            await this.getMemberList();
            return;
        }
        else if( this.tab == 'APPROVAL') {

            this.$nextTick(() => {

                const list_height = $('.tabEappList').outerHeight();
                const title_height = $('.tabEappList .appup_top').outerHeight();

                const scrollbar_option = {
                    axis : 'y',
                    scrollbarPosition : 'outside',
                    mouseWheelPixels : 100,
                    scrollInertia : 60,
                    autoDraggerLength : false,
                    setHeight : list_height ? (list_height - (title_height ?? 0) - 17) : 0,
                };

                // @ts-ignore
                $('#group_detail_approval_draft_box_scroll').mCustomScrollbar(scrollbar_option);
                
                // @ts-ignore
                $('#group_detail_approval_receive_box_scroll').mCustomScrollbar(scrollbar_option);

            });

            Promise.all([this.getApporvalDraftBox(), this.getApporvalReceiveBox()]);
            return;
        }
        else if( this.tab == 'WORK') {
            

            this.$nextTick(() => {

                const list_height = $('.tabTaskList').outerHeight();
                const title_height = $('.tabTaskList .task_top').outerHeight();

                const scrollbar_option = {
                    axis : 'y',
                    scrollbarPosition : 'outside',
                    mouseWheelPixels : 100,
                    scrollInertia : 60,
                    autoDraggerLength : false,
                    setHeight : list_height ? (list_height - (title_height ?? 0) - 10) : 0,
                };

                // @ts-ignore;
                $('#group_detail_meetinglog_scroll').mCustomScrollbar(scrollbar_option);

                // @ts-ignore;
                $('#group_detail_report_scroll').mCustomScrollbar(scrollbar_option);

                // @ts-ignore;
                $('#group_detail_project_scroll').mCustomScrollbar(scrollbar_option);

            });
            
            Promise.all([this.getProjectList(), this.getMeetingLogAndReport()]);

            return;
        }
        else if( this.tab == 'ATTENDANCE') {

            // 최초에 탭을 누르면 해당 날짜의 주로 표시 (시작 : 일요일, 끝 : 토요일)
            this.attendance_start = moment().add('day', -(new Date().getDay())).toDate();
            this.attendance_end = moment().add('day', 7 - (new Date().getDay()) - 1).toDate();
            this.attendance_selected = moment().format('YYYYMMDD');

            this.$nextTick(() => {

                // datepicker
                const datepicker_option = {
                    inline: false,
                    showOtherMonths: true,
                    selectOtherMonths: true,
                    dateFormat: 'yy-mm-dd',
                    monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                    yearSuffix : '.',
                    blankSpace : '',
                    changeYear : true,
                    yearRange  : '1900:2050',
                    onSelect: (dateText, inst) => {
                        this.attendance_start = moment(dateText).add('day', -(moment(dateText).toDate().getDay())).toDate();
                        this.attendance_end = moment(dateText).add('day', 7 - (moment(dateText).toDate().getDay()) - 1).toDate();
                        this.attendance_selected = moment(dateText).format('YYYYMMDD');

                        $('#antWeekDay_start').val(this.hodu_date_to_format_string(this.attendance_start, "YYYY.MM.DD"));
                        $('#antWeekDay_end').val(this.hodu_date_to_format_string(this.attendance_end, "YYYY.MM.DD"));

                        this.$nextTick(async() => {
                            if( this.attendance_tab == 'MY' ) {
                                await this.getMyAttendDataMonthInfo();
                            }
                            else {
                                await Promise.all([this.getMyAttendDataMonthInfo(), this.getEmpAttendDataDayInfo()]);
                            }
                            this.$nextTick(() => this.makeStatisticsData());
                        });
                    }
                }

                // @ts-ignore
                $('#antWeekDay_start').datepicker(datepicker_option);
                // @ts-ignore
                $('#antWeekDay_end').datepicker(datepicker_option);
                
                //근태 그래프
                // let i=1;
                // let func1 = setInterval(function(){
                //     if(i<26){
                //         color1(i);
                //         i++;
                //     } else if(i<101){
                //         color2(i);
                //         i++;
                //     } else {
                //         clearInterval(func1);
                //     }
                // },10);

                // function color1(i){
                //     $(".atngraph").css({
                //         "background":"conic-gradient(#477fff 0% "+i+"%, #ffffff "+i+"% 100%)"
                //         });
                    
                // }
                // function color2(i){
                //     $(".atngraph").css({
                //         "background":"conic-gradient(#477fff 0% 25%, #f1f3f5 25% "+i+"%, #ffffff "+i+"% 100%)" 
                //         });
                    
                // }
                
                // function replay(){
                //     i=1;
                //     func1 = setInterval(function(){
                //         if(i<26){
                //             color1(i);
                //             i++;
                //         } else if(i<101){
                //             color2(i);
                //             i++;
                //         } else {
                //             clearInterval(func1);
                //         }
                //     },10);
                // }

                // $('.atngraph a').on('click', (e) => {
                //     e.preventDefault();
                //     replay();
                // })
                
                //대시보드 관리자 tab메뉴
                // $('.week_manager > h2').click (function(){
                // 	var boardIndex = $(this).index();
                // 	$('.graph_wrap > div').hide();
                // 	$('.graph_wrap > div').eq(boardIndex).show();
                // 	$(this).addClass('active').siblings().removeClass();

                // 	return false;
                // });
                
                this.getMyAttendDataMonthInfo();

            });
            return;
        }
        else if( this.tab == 'KIDS_ATTENDANCE') {
            
            // 선생님은 전체만 부모님은 본인 아이만!
            this.kids_attendance_tab = (this.get_group_role(this.group_id) == 'GROUP_MANAGER' || this.get_group_role(this.group_id) == 'ADMIN') ? 'ALL' : 'MY';

            // 최초에 탭을 누르면 해당 날짜의 주로 표시 (시작 : 일요일, 끝 : 토요일)
            this.kids_attendance_start = moment().add('day', -(new Date().getDay())).toDate();
            this.kids_attendance_end = moment().add('day', 7 - (new Date().getDay()) - 1).toDate();
            this.kids_attendance_selected = moment().format('YYYYMMDD');

            this.$nextTick(() => {

                // datepicker
                const datepicker_option = {
                    inline: false,
                    showOtherMonths: true,
                    selectOtherMonths: true,
                    dateFormat: 'yy-mm-dd',
                    monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                    yearSuffix : '.',
                    blankSpace : '',
                    changeYear : true,
                    yearRange  : '1900:2050',
                    onSelect: (dateText, inst) => {
                        this.kids_attendance_start = moment(dateText).add('day', -(moment(dateText).toDate().getDay())).toDate();
                        this.kids_attendance_end = moment(dateText).add('day', 7 - (moment(dateText).toDate().getDay()) - 1).toDate();
                        this.kids_attendance_selected = moment(dateText).format('YYYYMMDD');

                        $('#antWeekDay_start').val(this.hodu_date_to_format_string(this.kids_attendance_start, "YYYY.MM.DD"));
                        $('#antWeekDay_end').val(this.hodu_date_to_format_string(this.kids_attendance_end, "YYYY.MM.DD"));

                        this.$nextTick(async() => {
                            if( this.kids_attendance_tab == 'MY' ) {
                                await this.getMyKidsAttendDataMonthInfo();
                            }
                            else {
                                await Promise.all([this.getMyKidsAttendDataMonthInfo(), this.getKidsAttendDataDayInfo()]);
                            }
                            this.$nextTick(() => this.makeKidsStatisticsData());
                        });
                    }
                }

                // @ts-ignore
                $('#antWeekDay_start').datepicker(datepicker_option);

                // @ts-ignore
                $('#antWeekDay_end').datepicker(datepicker_option);
              
                if( this.kids_attendance_tab == 'MY' ) {
                    this.getMyKidsAttendDataMonthInfo();
                }
                else {
                    Promise.all([this.getMyKidsAttendDataMonthInfo(), this.getKidsAttendDataDayInfo()]);
                }
            });
            return;
        }
        else if( this.tab == 'KIDS') {

            this.$nextTick(() => {
                
                // @ts-ignore
                $('#kids_list_scroll').mCustomScrollbar({
                    axis : 'y',
                    scrollbarPosition : 'outside',
                    mouseWheelPixels : 100,
                    scrollInertia : 60,
                    autoDraggerLength : false,
                    setHeight : 219,
                });

            });

            this.getKidsInfo();
        }

    }

    async getMemberList() : Promise<void> {
        this.$nextTick(() => {

            const list_height = $('.tabMemberList').outerHeight();

            const scrollbar_option = {
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : list_height ? (list_height - 40) : 0,
            };

            // @ts-ignore
            $('#group_detail_manager_scroll').mCustomScrollbar(scrollbar_option);

            // @ts-ignore
            $('#group_detail_sub_manager_scroll').mCustomScrollbar(scrollbar_option);

            // @ts-ignore
            $('#group_detail_member_scroll').mCustomScrollbar(scrollbar_option);
        });

        try {
            const response = await this.hodu_api_call(`api/v1/groupsweb/getGroupTeamMember/${this.group_id}/${this.team_id}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list ) {
                throw new Error("멤버 조회 중 오류 발생");
            }
            
            this.members.splice(0, this.members.length);
            this.members = this.members.concat(response.data.data.list); 

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 상신함 조회
     */
    async getApporvalDraftBox() : Promise<void> {

        try {
            // 검색 타입, 시작일, 종료일
            let query = "?search_mode=DRAFT";
            query += `&start_date=${moment().add('month', -1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format()}`;
            query += `&end_date=${moment().utc().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).format()}`;

            // 클라이언트 타임존
            const timezone = -(new Date().getTimezoneOffset());
            query += `&timezone=${timezone ? timezone : 540}`;

            const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval${query}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("상신함 문서 조회 중 오류 발생");
            }

            this.draft_approvals.splice(0, this.draft_approvals.length);
            this.draft_approvals = this.draft_approvals.concat(response.data.data.approval_info.filter(approval => approval.approval_state != 'CANCEL'));
            this.draft_approvals.sort((o1, o2) : number => {
                const o1_time = o1.audit_created;
                const o2_time = o2.audit_created;

                if( o1_time == o2_time ) return 0;
                return o1_time > o2_time ? -1 : 1;
            });

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 상신함 조회
     */
    async getApporvalReceiveBox() : Promise<void> {

        try {
            // 검색 타입, 시작일, 종료일
            let query = "?search_mode=RECEIVE";
            query += `&start_date=${moment().add('month', -1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format()}`;
            query += `&end_date=${moment().utc().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).format()}`;

            // 클라이언트 타임존
            const timezone = -(new Date().getTimezoneOffset());
            query += `&timezone=${timezone ? timezone : 540}`;

            const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval${query}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("수신함 문서 조회 중 오류 발생");
            }

            this.receive_approvals.splice(0, this.receive_approvals.length);
            this.receive_approvals = this.receive_approvals.concat(response.data.data.approval_info.filter(approval => approval.user_id != this.user_id && approval.approval_state != 'TEMP' && approval.approval_state != 'CANCEL'));
            this.receive_approvals.sort((o1, o2) : number => {
                const o1_time = o1.audit_created;
                const o2_time = o2.audit_created;

                if( o1_time == o2_time ) return 0;
                return o1_time > o2_time ? -1 : 1;
            });

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 프로젝트 리스트 조회
     */
    async getProjectList() : Promise<void> {

        let url : string = `api/v1/calendars/${ this.team_id > 0 ? `team-${this.team_id}` : `group-${this.group_id}` }/works/${ this.team_id > 0 ? 'TEAM' : 'GROUP' }/${ this.team_id > 0 ? this.team_id : this.group_id }`;
        
        const start : string = moment().add('week', -1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format();
        const end : string = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).utc().format();

        let query : string = `?start=${start}&end=${end}&mycal=false&syscal=false&shared=false&work_template_type=ALL&work_status=ALL&search=`;

        // 그룹 업무 리스트 일때
        if( this.team_id < 1 ) {
            query += `&groups=${ this.group_id }`;
            
            // 가입한 팀 추가
            for( const team_role of this.user_team_role ) {
                if( team_role.group_id == this.group_id ) {
                    query += `&teams=${ team_role.team_id }`;
                }   
            }
        } 

        // 팀 업무 리스트 일때
        else {
            query += `&teams=${ this.team_id }`;
        }

        let groups : number[] = [];
        let teams : number[] = [];

        if( this.user_group_role != null && this.user_group_role.length > 0 ) {
            for( const group_role of this.user_group_role ) {
                if( group_role.group_id == null || group_role.group_id < 1 ) {
                    continue
                }
                groups.push(group_role.group_id);
            }
        }

        if( this.user_team_role != null && this.user_team_role.length > 0 ) {
            for( const team_role of this.user_team_role ) {
                if( team_role.team_id == null || team_role.team_id < 1 ) {
                    continue
                }
                teams.push(team_role.team_id);
            }
        }

        try {
            const response = await this.hodu_api_call(`${url}${query}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_list ) {
                throw new Error("프로젝트 조회 중 오류 발생");
            }

            const temp_project : any[] = [];
            const response_project : any[] = response.data.data.work_list;

            for( const project of response_project ) {
                
                if ( project.event == null || project.event.event_data == null ) {
                    temp_project.push(project);
                    continue;
                }

                const event = project.event;
                const event_data = event.event_data;

                // 숨김 처리 아니라면 추가
                if( (event_data.is_private ?? false) == false ) {
                    temp_project.push(project);
                    continue;
                }

                // 본인 작성이면 숨김상태여도 그대로 추가
                if( event_data.event_owner_id == this.user_id ) {
                    temp_project.push(project);
                    continue;
                }

                // 권한 구하기
                let is_permitted : boolean = false;
                if( event.team_id > 0 ) {
                    if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "event", "private_read");
                    }
                    else if( this.isMeetingLog(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "meetinglog", "private_read");
                    }
                    else if( this.isReport(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "report", "private_read");
                    }
                    else if( this.isWork(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "work", "private_read");
                    }
                }
                else if( event.group_id > 0 ) {
                    if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "event", "private_read");
                    }
                    else if( this.isMeetingLog(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "meetinglog", "private_read");
                    }
                    else if( this.isReport(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "report", "private_read");
                    }
                    else if( this.isWork(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "work", "private_read");
                    }
                }

                if( is_permitted == true ) {
                    temp_project.push(project);
                    continue;
                }

                // 프로젝트의 경우 담당자면 볼 수 있음
                if( event.event_sub_type, "WORK" && event_data.work != null ) {

                    if( event_data.work?.assignment_type == "ALL" ) {
                        temp_project.push(project);
                        continue;
                    }

                    const assign_user_ids = event_data.work!.assign_user_ids;
                    if( assign_user_ids != null && assign_user_ids.indexOf(this.user_id) > -1 ) {
                        temp_project.push(project);
                        continue;
                    }

                }

                // 숨김 처리인데 개인 공유 받은 경우 추가
                if( event.subscribe_users != null && event.subscribe_users.indexOf(this.user_id) > -1 ) {
                    temp_project.push(project);
                    continue;
                }

                // 숨김 처리인데 그룹 공유 받은 경우 추가
                for( const group_id of groups ) {
                    if ( event.subscribe_groups != null && event.subscribe_groups.indexOf(group_id) > -1 ) {
                        temp_project.push(project);
                        break;
                    }
                }

                if( temp_project.indexOf(project) > -1 ) continue;

                // 숨김 처리인데 팀 공유 받은 경우 추가
                for( const team_id of teams ) {
                    if ( event.subscribe_teams != null && event.subscribe_teams.indexOf(team_id) > -1) {
                        temp_project.push(project);
                        break;
                    }
                }

                if( temp_project.indexOf(project) > -1 ) continue;
            }

            this.project_list.splice(0, this.project_list.length);
            this.project_list = this.project_list.concat(temp_project.sort((o1, o2) : number => {

                const o1_time = new Date(o1.event.event_data.schedule_date.start);
                const o2_time = new Date(o2.event.event_data.schedule_date.start);

                if( o1_time > o2_time ) return -1;
                else if( o1_time < o2_time ) return 1;

                return 0;
            }));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        // this.hodu_api_call(`${url}${query}`, API_METHOD.GET)
        //     .then((response) => {
        //         console.log(response);

        //         vue.work_list.splice(0, vue.work_list.length);
        //         vue.work_list = vue.work_list.concat(response.data.data.work_list);
        //         vue.work_original_list = JSON.parse(JSON.stringify(vue.work_list));
        //     })
        //     .catch((e) => {
        //         this.hodu_error_process(e, true, false);
        //     });
    }

    /**
     * 회의록, 업무일지 조회
     */
    async getMeetingLogAndReport() : Promise<void> { 

        try {
            let owner_type  : string = this.team_id > 0 ? 'TEAM' : 'GROUP';
            let owner_id    : number = this.team_id > 0 ? this.team_id : this.group_id;
            let calendar_id : string = this.team_id > 0 ? `team-${this.team_id}` : `group-${this.group_id}`;

            const start_query : string = moment().add('week', -1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format();
            const end_query : string = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).utc().format();

            let query : string = `?start=${start_query}&end=${end_query}&mycal=false&syscal=false&shared=false`;

            query += this.team_id < 1 ? `&groups=${this.group_id}` : `&teams=${this.team_id}`;

            // 그룹 달력인 경우 가입된 팀도 조회한다
            if( this.team_id < 1 ) {
                const role_size : number = this.user_team_role.length;
                for( let i = 0; i < role_size; i++ ) {
                    const team_role = this.user_team_role[i];
                    if( team_role.team_id == null || team_role.team_id < 1 || this.group_id != team_role.group_id ){
                        continue
                    }

                    query += `&teams=${Number(this.user_team_role[i].team_id)}`;
                }
            }

            let groups : number[] = [];
            let teams : number[] = [];

            if( this.user_group_role != null && this.user_group_role.length > 0 ) {
                for( const group_role of this.user_group_role ) {
                    if( group_role.group_id == null || group_role.group_id < 1 ) {
                        continue
                    }
                    groups.push(group_role.group_id);
                }
            }

            if( this.user_team_role != null && this.user_team_role.length > 0 ) {
                for( const team_role of this.user_team_role ) {
                    if( team_role.team_id == null || team_role.team_id < 1 ) {
                        continue
                    }
                    teams.push(team_role.team_id);
                }
            }
        
            const response = await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}${query}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.events ) {
                throw new Error("업무일지, 회의록 조회 중 오류 발생");
            }

            const temp_meetinglog : t_event[] = [];
            const temp_report : t_event[] = [];
            const response_meetinglog : t_event[] = response.data.data.events.filter(item => item.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG);
            const response_report : t_event[] = response.data.data.events.filter(item => item.event_sub_type == EVENT_SUB_TYPE.REPORT);

            for( const event of response_meetinglog ) {
                
                if ( event == null || event.event_data == null ) {
                    temp_meetinglog.push(event);
                    continue;
                }

                const event_data = event.event_data;

                // 숨김 처리 아니라면 추가
                if( (event_data.is_private ?? false) == false ) {
                    temp_meetinglog.push(event);
                    continue;
                }

                // 본인 작성이면 숨김상태여도 그대로 추가
                if( event_data.event_owner_id == this.user_id ) {
                    temp_meetinglog.push(event);
                    continue;
                }

                // 권한 구하기
                let is_permitted : boolean = false;
                if( event.team_id > 0 ) {
                    if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "event", "private_read");
                    }
                    else if( this.isMeetingLog(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "meetinglog", "private_read");
                    }
                    else if( this.isReport(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "report", "private_read");
                    }
                    else if( this.isWork(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "work", "private_read");
                    }
                }
                else if( event.group_id > 0 ) {
                    if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "event", "private_read");
                    }
                    else if( this.isMeetingLog(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "meetinglog", "private_read");
                    }
                    else if( this.isReport(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "report", "private_read");
                    }
                    else if( this.isWork(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "work", "private_read");
                    }
                }

                if( is_permitted == true ) {
                    temp_meetinglog.push(event);
                    continue;
                }

                // 프로젝트의 경우 담당자면 볼 수 있음
                if( event.event_sub_type, "WORK" && event_data.work != null ) {

                    if( event_data.work?.assignment_type == "ALL" ) {
                        temp_meetinglog.push(event);
                        continue;
                    }

                    const assign_user_ids = event_data.work!.assign_user_ids;
                    if( assign_user_ids != null && assign_user_ids.indexOf(this.user_id) > -1 ) {
                        temp_meetinglog.push(event);
                        continue;
                    }

                }

                // 숨김 처리인데 개인 공유 받은 경우 추가
                if( event.subscribe_users != null && event.subscribe_users.indexOf(this.user_id) > -1 ) {
                    temp_meetinglog.push(event);
                    continue;
                }

                // 숨김 처리인데 그룹 공유 받은 경우 추가
                for( const group_id of groups ) {
                    if ( event.subscribe_groups != null && event.subscribe_groups.indexOf(group_id) > -1 ) {
                        temp_meetinglog.push(event);
                        break;
                    }
                }

                if( temp_meetinglog.indexOf(event) > -1 ) continue;

                // 숨김 처리인데 팀 공유 받은 경우 추가
                for( const team_id of teams ) {
                    if ( event.subscribe_teams != null && event.subscribe_teams.indexOf(team_id) > -1) {
                        temp_meetinglog.push(event);
                        break;
                    }
                }

                if( temp_meetinglog.indexOf(event) > -1 ) continue;
            }

            for( const event of response_report ) {

                if ( event == null || event.event_data == null ) {
                    temp_report.push(event);
                    continue;
                }

                const event_data = event.event_data;

                // 숨김 처리 아니라면 추가
                if( (event_data.is_private ?? false) == false ) {
                    temp_report.push(event);
                    continue;
                }

                // 본인 작성이면 숨김상태여도 그대로 추가
                if( event_data.event_owner_id == this.user_id ) {
                    temp_report.push(event);
                    continue;
                }

                // 권한 구하기
                let is_permitted : boolean = false;
                if( event.team_id > 0 ) {
                    if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "event", "private_read");
                    }
                    else if( this.isMeetingLog(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "meetinglog", "private_read");
                    }
                    else if( this.isReport(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "report", "private_read");
                    }
                    else if( this.isWork(event.event_sub_type) ) {
                        is_permitted = this.is_team_permmision(event.team_id, "work", "private_read");
                    }
                }
                else if( event.group_id > 0 ) {
                    if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "event", "private_read");
                    }
                    else if( this.isMeetingLog(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "meetinglog", "private_read");
                    }
                    else if( this.isReport(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "report", "private_read");
                    }
                    else if( this.isWork(event.event_sub_type) ) {
                        is_permitted = this.is_group_permmision(event.group_id, "work", "private_read");
                    }
                }

                if( is_permitted == true ) {
                    temp_report.push(event);
                    continue;
                }

                // 프로젝트의 경우 담당자면 볼 수 있음
                if( event.event_sub_type, "WORK" && event_data.work != null ) {

                    if( event_data.work?.assignment_type == "ALL" ) {
                        temp_report.push(event);
                        continue;
                    }

                    const assign_user_ids = event_data.work!.assign_user_ids;
                    if( assign_user_ids != null && assign_user_ids.indexOf(this.user_id) > -1 ) {
                        temp_report.push(event);
                        continue;
                    }

                }

                // 숨김 처리인데 개인 공유 받은 경우 추가
                if( event.subscribe_users != null && event.subscribe_users.indexOf(this.user_id) > -1 ) {
                    temp_report.push(event);
                    continue;
                }

                // 숨김 처리인데 그룹 공유 받은 경우 추가
                for( const group_id of groups ) {
                    if ( event.subscribe_groups != null && event.subscribe_groups.indexOf(group_id) > -1 ) {
                        temp_report.push(event);
                        break;
                    }
                }

                if( temp_report.indexOf(event) > -1 ) continue;

                // 숨김 처리인데 팀 공유 받은 경우 추가
                for( const team_id of teams ) {
                    if ( event.subscribe_teams != null && event.subscribe_teams.indexOf(team_id) > -1) {
                        temp_report.push(event);
                        break;
                    }
                }

                if( temp_report.indexOf(event) > -1 ) continue;
            }

            this.meetinglog_list.splice(0, this.meetinglog_list.length);
            this.meetinglog_list = this.meetinglog_list.concat(temp_meetinglog.sort((o1, o2) : number => {

                const o1_time = new Date(o1.event_data.schedule_date.start);
                const o2_time = new Date(o2.event_data.schedule_date.start);

                if( o1_time > o2_time ) return -1;
                else if( o1_time < o2_time ) return 1;

                return 0;
            }));

            this.report_list.splice(0, this.report_list.length);
            this.report_list = this.report_list.concat(temp_report.sort((o1, o2) : number => {

                const o1_time = new Date(o1.event_data.schedule_date.start);
                const o2_time = new Date(o2.event_data.schedule_date.start);

                if( o1_time > o2_time ) return -1;
                else if( o1_time < o2_time ) return 1;

                return 0;
            }));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 근태현황 데이터 조회
     */
    async getMyAttendDataMonthInfo() : Promise<void> {
        
        this.hodu_show_indicator();

        try {
            
            // 시작, 종료가 같은 월이라면
            if( this.attendance_start.getMonth() == this.attendance_end.getMonth() ) {
                const month_end : Date = moment(this.attendance_start).add('month', 1).set("date", 0).toDate();

                let query = `month=${this.hodu_date_to_format_string(this.attendance_start, 'YYYYMM')}`;
                query    += `&search_mode=USER`;
                query    += `&today_date_string=${this.hodu_date_to_format_string(month_end, 'YYYYMMDD')}`;
                query    += `&date_string=${this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')}`;
                query    += `&week_day_number=${new Date().getDay()}`;

                const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/organization/my/emp/attend/data/month?${query}`, API_METHOD.GET, null, false);

                console.log(response);
                
                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_day_count_info || !response.data.data.attend_emp_month_info ) {
                    throw new Error("근태현황 조회 중 오류 발생");
                }

                const data = response.data.data;
                const attend_emp_day_count_info_array = data.attend_emp_day_count_info;
                const attend_emp_month_info_array = data.attend_emp_month_info;
                
                this.attend_emp_info = data.emp_info;

                if( attend_emp_day_count_info_array.length > 0 && attend_emp_day_count_info_array[0].contents ) this.attend_emp_day_count_info = attend_emp_day_count_info_array[0].contents;
                if( attend_emp_month_info_array.length > 0 ) this.attend_emp_month_info = attend_emp_month_info_array[0];
            }

            // 시작, 종료가 다른 월이라면
            else {
                
                const start_month_end : Date = moment(this.attendance_start).add('month', 1).set("date", 0).toDate();

                let start_query = `month=${this.hodu_date_to_format_string(this.attendance_start, 'YYYYMM')}`;
                start_query    += `&search_mode=USER`;
                start_query    += `&today_date_string=${this.hodu_date_to_format_string(start_month_end, 'YYYYMMDD')}`;
                start_query    += `&date_string=${this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')}`;
                start_query    += `&week_day_number=${new Date().getDay()}`;

                const start_response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/organization/my/emp/attend/data/month?${start_query}`, API_METHOD.GET, null, false);

                console.log(start_response);
                
                if( !start_response || !this.isHttpStatusSuccess(start_response.status) || !start_response.data || !start_response.data.data || !start_response.data.data.attend_emp_day_count_info || !start_response.data.data.attend_emp_month_info ) {
                    throw new Error("근태현황 조회 중 오류 발생");
                }

                const end_month_end : Date = moment(this.attendance_end).add('month', 1).set("date", 0).toDate();

                let end_query = `month=${this.hodu_date_to_format_string(this.attendance_end, 'YYYYMM')}`;
                end_query    += `&search_mode=USER`;
                end_query    += `&today_date_string=${this.hodu_date_to_format_string(end_month_end, 'YYYYMMDD')}`;
                end_query    += `&date_string=${this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')}`;
                end_query    += `&week_day_number=${new Date().getDay()}`;

                const end_response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/organization/my/emp/attend/data/month?${end_query}`, API_METHOD.GET, null, false);

                console.log(end_response);
                
                if( !end_response || !this.isHttpStatusSuccess(end_response.status) || !end_response.data || !end_response.data.data || !end_response.data.data.attend_emp_day_count_info || !end_response.data.data.attend_emp_month_info ) {
                    throw new Error("근태현황 조회 중 오류 발생");
                }

                const start_data = start_response.data.data;

                const start_attend_emp_day_count_info_array = start_data.attend_emp_day_count_info;
                const start_attend_emp_month_info_array = start_data.attend_emp_month_info;
                let start_attend_emp_day_count_info : any = null;
                let start_attend_emp_month_info : any = null;

                if( start_attend_emp_day_count_info_array.length > 0 && start_attend_emp_day_count_info_array[0].contents ) start_attend_emp_day_count_info = start_attend_emp_day_count_info_array[0].contents;
                if( start_attend_emp_month_info_array.length > 0 ) start_attend_emp_month_info = start_attend_emp_month_info_array[0];

                const end_data = end_response.data.data;

                const end_attend_emp_day_count_info_array = end_data.attend_emp_day_count_info;
                const end_attend_emp_month_info_array = end_data.attend_emp_month_info;
                let end_attend_emp_day_count_info : any = null;
                let end_attend_emp_month_info : any = null;

                if( end_attend_emp_day_count_info_array.length > 0 && end_attend_emp_day_count_info_array[0].contents ) end_attend_emp_day_count_info = end_attend_emp_day_count_info_array[0].contents;
                if( end_attend_emp_month_info_array.length > 0 ) end_attend_emp_month_info = end_attend_emp_month_info_array[0];

                this.attend_emp_day_count_info = Object.assign(start_attend_emp_day_count_info ?? {}, end_attend_emp_day_count_info ?? {});

                this.attend_emp_info = start_data.emp_info;
                const jsonb = Object.assign((start_attend_emp_month_info ?? { "jsonb" : {} }).jsonb, (end_attend_emp_month_info ?? { "jsonb" : {} }).jsonb);
                if( start_attend_emp_month_info != null ) {
                    start_attend_emp_month_info.jsonb = jsonb;
                    this.attend_emp_month_info = start_attend_emp_month_info;
                }
                else if( end_attend_emp_month_info != null ) {
                    end_attend_emp_month_info.jsonb = jsonb;
                    this.attend_emp_month_info = end_attend_emp_month_info;
                }
                else {
                    this.attend_emp_month_info = { "jsonb" : jsonb };
                }

                this.$forceUpdate();
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        } finally {
            this.hodu_hide_indicator();
        }

    }

    /**
     * 통계용 선택된 날짜 근무
     */
    @Watch('attendance_tab')
    async getEmpAttendDataDayInfo() : Promise<void> {

        // 본인 현황 보고있을땐 조회 안함
        if( this.attendance_tab == 'MY' ) {
            await this.getMyAttendDataMonthInfo();
            this.$nextTick(() => this.makeStatisticsData());
            return;
        }

        try {
            await this.getMyAttendDataMonthInfo();

            const date_string = this.attendance_selected;

            // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
            if( this.getAttendanceStart() == '' || ( Number(this.getAttendanceStart()) > Number(date_string) ) ) {
                this.attend_list.splice(0, this.attend_list.length);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/organization/emp/attend/data/day?date_string=${date_string}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_day_count_info || !response.data.data.attend_emp_day_data_info ) {
                throw new Error("일일 근태현황 데이터 조회 중 오류 발생");
            }

            this.attend_list.splice(0, this.attend_list.length);
            this.attend_list = this.attend_list.concat(response.data.data.attend_emp_day_data_info.filter(attend => attend.is_dayoff == false));

            this.attend_emp_day_count_info = JSON.parse(JSON.stringify(response.data.data.attend_emp_day_count_info));
            this.attend_day_count_info = JSON.parse(JSON.stringify(response.data.data.attend_emp_day_count_info));

            this.$nextTick(() => this.makeStatisticsData());

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        // try {
        //     const date_string = this.date_string.replaceAll(/\./ig, "");

        //     // 근태현황 시작일이 적용 안됐다면 전부 제외
        //     if( this.getAttendanceStart() == '' ) {
        //         this.total_attend = JSON.parse(JSON.stringify({
        //             awol_cnt:"0",
        //             late_cnt:"0",
        //             normal_cnt:"0",
        //             uncheck_cnt:"0",
        //             vacation_cnt:"0"
        //         }));
        //         this.attend_list.splice(0, this.attend_list.length);
        //         return;
        //     }

        //     // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
        //     if( Number(this.getAttendanceStart()) > Number(date_string) ) {
        //         this.total_attend = JSON.parse(JSON.stringify({
        //             awol_cnt:"0",
        //             late_cnt:"0",
        //             normal_cnt:"0",
        //             uncheck_cnt:"0",
        //             vacation_cnt:"0"
        //         }));
        //         this.attend_list.splice(0, this.attend_list.length);
        //         return;
        //     }

        //     const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/data/day?date_string=${date_string}`, API_METHOD.GET, null, false);

        //     console.log(response);

        //     if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_day_count_info || !response.data.data.attend_emp_day_data_info ) {
        //         throw new Error("일일 근태현황 데이터 조회 중 오류 발생");
        //     }

        //     this.total_attend = JSON.parse(JSON.stringify(response.data.data.attend_emp_day_count_info));
            
        //     this.attend_list.splice(0, this.attend_list.length);
        //     this.attend_list = this.attend_list.concat(response.data.data.attend_emp_day_data_info);

        // } catch(e) {
        //     this.hodu_error_process(e, false, false, true);
        //     this.hodu_show_dialog('cancel', "데이터 조회 중 오류 발생", ['확인']);
        // }

    }

    /**
     * 호두키즈 출석부 데이터 조회
     */
    async getMyKidsAttendDataMonthInfo() : Promise<void> {
        
        try {
            if( this.kids_attendance_start.getMonth() == this.kids_attendance_end.getMonth() ) {

                const month_end : Date = moment(this.kids_attendance_start).add('month', 1).set("date", 0).toDate();

                let query = `month=${this.hodu_date_to_format_string(this.kids_attendance_start, 'YYYYMM')}`;
                query    += `&group_id=${this.group_id}`;
                query    += `&today_date_string=${this.hodu_date_to_format_string(month_end, 'YYYYMMDD')}`;
                query    += `&date_string=${this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')}`;
                query    += `&parent_id=${this.user_id}`;

                const response = await this.hodu_api_call(`api/v1/kids/attend/data/month?${query}`, API_METHOD.GET);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_month_info ) {
                    throw new Error("출석부 조회 중 오류 발생");
                }

                this.kids_attend_emp_month_info.splice(0, this.kids_attend_emp_month_info.length)

                if( response.data.data.attend_emp_month_info.length > 0 && response.data.data.attend_emp_month_info[0].member_key == null ) {
                    return;
                }

                this.kids_attend_emp_month_info = this.kids_attend_emp_month_info.concat(response.data.data.attend_emp_month_info);

            }
            else {

                const start_month_end : Date = moment(this.attendance_start).add('month', 1).set("date", 0).toDate();

                let start_query = `month=${this.hodu_date_to_format_string(this.kids_attendance_start, 'YYYYMM')}`;
                start_query    += `&group_id=${this.group_id}`;
                start_query    += `&today_date_string=${this.hodu_date_to_format_string(start_month_end, 'YYYYMMDD')}`;
                start_query    += `&date_string=${this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')}`;
                start_query    += `&parent_id=${this.user_id}`;

                const start_response = await this.hodu_api_call(`api/v1/kids/attend/data/month?${start_query}`, API_METHOD.GET);

                console.log(start_response);
                
                if( !start_response || !this.isHttpStatusSuccess(start_response.status) || !start_response.data || !start_response.data.data || !start_response.data.data.attend_emp_month_info ) {
                    throw new Error("출석부 조회 중 오류 발생");
                }

                this.kids_attend_emp_month_info.splice(0, this.kids_attend_emp_month_info.length)

                if( !(start_response.data.data.attend_emp_month_info.length > 0 && start_response.data.data.attend_emp_month_info[0].member_key == null) ) {
                    this.kids_attend_emp_month_info = this.kids_attend_emp_month_info.concat(start_response.data.data.attend_emp_month_info);
                }

                const end_month_end : Date = moment(this.kids_attendance_end).add('month', 1).set("date", 0).toDate();

                let end_query = `month=${this.hodu_date_to_format_string(this.kids_attendance_end, 'YYYYMM')}`;
                end_query    += `&group_id=${this.group_id}`;
                end_query    += `&today_date_string=${this.hodu_date_to_format_string(end_month_end, 'YYYYMMDD')}`;
                end_query    += `&date_string=${this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')}`;
                end_query    += `&parent_id=${this.user_id}`;
                
                const end_response = await this.hodu_api_call(`api/v1/kids/attend/data/month?${end_query}`, API_METHOD.GET);

                console.log(end_response);
                
                if( !end_response || !this.isHttpStatusSuccess(end_response.status) || !end_response.data || !end_response.data.data || !end_response.data.data.attend_emp_month_info ) {
                    throw new Error("출석부 조회 중 오류 발생");
                }

                if( !(end_response.data.data.attend_emp_month_info.length > 0 && end_response.data.data.attend_emp_month_info[0].member_key == null) ) {
                    for( let end_kid of end_response.data.data.attend_emp_month_info ) {
                        const target = this.kids_attend_emp_month_info.filter(start_kid => start_kid.member_key == end_kid.member_key);
                        if( target.length > 0 ) {
                            
                            if( target[0].jsonb == null ) {
                                target[0].jsonb = end_kid.jsonb;
                            }
                            else if( target[0].jsonb != null && end_kid.jsonb != null ) {
                                target[0].jsonb = Object.assign(target[0].jsonb, end_kid.jsonb);
                            }

                        }
                        else {
                            this.kids_attend_emp_month_info.push(end_kid);
                        }
                    }
                }

            }

            this.$forceUpdate();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 통계용 선택된 날짜 근무
     */
    @Watch('kids_attendance_tab')
    async getKidsAttendDataDayInfo() : Promise<void> {

        // 본인 현황 보고있을땐 조회 안함
        if( this.kids_attendance_tab == 'MY' ) {
            await this.getMyKidsAttendDataMonthInfo();
            this.$nextTick(() => this.makeKidsStatisticsData());
            return;
        }

        try {
            await this.getMyKidsAttendDataMonthInfo();

            const date_string = this.kids_attendance_selected;

            const response = await this.hodu_api_call(`api/v1/kids/attend/data/day?group_id=${this.group_id}&date_string=${date_string}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_day_count_info || !response.data.data.attend_emp_day_data_info ) {
                throw new Error("일일 근태현황 데이터 조회 중 오류 발생");
            }

            // this.kids_attend_list.splice(0, this.kids_attend_list.length);
            // this.kids_attend_list = this.kids_attend_list.concat(response.data.data.attend_emp_day_data_info.filter(attend => attend.is_dayoff == false));

            this.kids_attend_day_count_info = JSON.parse(JSON.stringify(response.data.data.attend_emp_day_count_info));

            this.$nextTick(() => this.makeKidsStatisticsData());

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 원아 정보 조회
     */
    async getKidsInfo() : Promise<void> {

        const partner_key : string = this.getGroupPartnerKey(this.group_id);

        try {
            const response = await this.hodu_api_call(`api/v1/kids?partner_key=${partner_key}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.kids ) {
                throw new Error("원아 정보 조회 중 오류 발생");
            }

            let response_kids = response.data.data.kids;
            if( this.get_group_role(this.group_id) == this.GroupAuth.GROUP_USER ) {
                response_kids = response_kids.filter(kid => kid.parent_id.indexOf(this.user_id) > -1);
            }

            response_kids.sort((o1, o2) => {
                const o1_health_level = o1.band_info ? o1.band_info.health_level : 0;
                const o2_health_level = o2.band_info ? o2.band_info.health_level : 0;

                if( o1_health_level > o2_health_level ) return -1;
                else if( o1_health_level < o2_health_level ) return 1;
                return 0;
            })

            this.kids.splice(0, this.kids.length);
            this.kids = this.kids.concat(response_kids);

        } catch(e) {    
            throw e;
        }

    }
    
    /**
     * 수신함 상태값 텍스트 반환
     */
    getReceiveStatus(approval : any) : string {

        if( approval.approver.filter( app => app.user_id == this.user_id && app.state == 'ING' ).length > 0 && approval.approval_state != 'TEMP' && approval.approval_state != 'CANCEL' ) {
            return "결재요청";
        }

        else if( approval.approver.filter( app => app.user_id == this.user_id && app.state == 'WAIT' ).length > 0 && approval.approval_state != 'TEMP' && approval.approval_state != 'CANCEL' ) {
            return "결재대기";
        }

        else if( approval.approver.filter( app => app.user_id == this.user_id && ( app.state == 'REJECT' || app.state == 'CONFIRM' ) ).length > 0 && approval.approval_state != 'TEMP' && approval.approval_state != 'CANCEL' ) {
            return "결재내역";
        }
        
        else if( (approval.receive_reference != null && approval.receive_reference.filter(ref => ref.user_id == this.user_id).length > 0) && approval.approval_state != 'TEMP' && approval.approval_state != 'CANCEL' ) {
            return "수신참조";
        }

        return "";
    }

    /**
     * 결재 페이지로 이동
     */
    moveApproval(approval) : void {
        this.hodu_router_push(`/GROUP/${this.group_id}/approval/${approval.approval_uid}`);
    }

    /**
     * 전자결재 상신함 페이지로 이동
     */
    moveApprovalDraftBox(tab : approval_enum.APPROVAL_DRAFT_BOX_TAB) : void {
        this.doSetSelectedTab?.(approval_enum.APPROVAL_BOX_TAB.DRAFT);
        this.doSetSelectedDraftTab?.(tab);
        this.doSetSelectedReceiveTab?.(approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);

        // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
        if( this.scope_group_id == this.group_id ) {
            this.hodu_router_push(`/GROUP/${this.group_id}/approval?${new Date().getTime()}`);
            return;
        }
        else {
            this.groupMainPageGo(`/GROUP/${this.group_id}/approval?${new Date().getTime()}`);
        }        
    }

    /**
     * 전자결재 수신함 페이지로 이동
     */
    moveApprovalReceiveBox(tab : approval_enum.APPROVAL_RECEIVE_BOX_TAB) : void {
        this.doSetSelectedTab?.(approval_enum.APPROVAL_BOX_TAB.RECEIVE);
        this.doSetSelectedDraftTab?.(approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
        this.doSetSelectedReceiveTab?.(tab);

        // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
        if( this.scope_group_id == this.group_id ) {
            this.hodu_router_push(`/GROUP/${this.group_id}/approval?${new Date().getTime()}`);
            return;
        }
        else {
            this.groupMainPageGo(`/GROUP/${this.group_id}/approval?${new Date().getTime()}`);
        }        
    }

    /**
     * 일정으로 이동
     */
    async moveEvent(event : t_event) : Promise<void> {

        if( event.event_data.approval && event.event_data.approval.approval_uid && event.event_data.approval.approval_uid.length > 0 ) {
            
            const approver : number[] = Array.from(event.event_data.approval.approver ? event.event_data.approval.approver : [], x => x.user_id);
            const receiver : number[] = Array.from(event.event_data.approval.receive_reference ? event.event_data.approval.receive_reference : [], x => x.user_id);
            
            // 작성자, 결재자, 수신참조만 기안서 화면으로 이동
            if( event.event_data.event_owner_id == this.user_id || approver.indexOf(this.user_id) > -1 || receiver.indexOf(this.user_id) > -1 ) {
                this.hodu_router_push(`/GROUP/${event.group_id}/approval/${event.event_data.approval.approval_uid}`);
            }

            return;
        }

        // 반복 일정의 날짜 데이터 설정
        if( event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.lunar_yn == false ) {

            // 해당 반복일정의 첫 일정인지 구하기 (쪼개진 일정중 가장 빠른것 찾아야함!)
            const target = this.exdate_events.filter(item => item.event_id == event.event_id);
            
            for( const target_event of target ) {
                if( event.original_start == null || target_event.event_data.schedule_date.start == null ) continue;
                
                console.log(target_event.event_data.schedule_date.start);
                if( event.original_start.getTime() > new Date(target_event.event_data.schedule_date.start).getTime() ) {
                    event.original_start = new Date(target_event.event_data.schedule_date.start);
                }
            }

            const dtStart : Date = new Date(moment(event.original_start).format());
            
            // DTSTART랑 UNTIL이 이미 들어있다면 제거후 재등록
            let rrule_string : string = event.event_data.schedule_date.rrule;
            if( rrule_string.indexOf(';UNTIL') > -1 ) {
                rrule_string = rrule_string.substring(0, rrule_string.indexOf(';UNTIL'));
            }
            if( rrule_string.indexOf('FREQ') > - 1 ) {
                rrule_string = rrule_string.substring(rrule_string.indexOf('FREQ'));
            }
            event.event_data.schedule_date.rrule = rrule_string;

            const rrule : RRule | RRuleSet = rrulestr(`DTSTART:${this.formatDateForRruleDTSTARTByUTC(dtStart)}\nRRULE:${event.event_data.schedule_date.rrule};UNTIL=${this.formatDateForRruleUNTILByUTC(new Date(moment(event.event_data.schedule_date.recurrence_end).format()))}`);
            // alert(rrule.all());

            const rrule_array = rrule.all();
            
            // 해당 반복일정 조각이 반복일정의 첫 조각인지 판단
            this.doSetIsFirstRepeatEvent( this.hodu_date_to_format_string(rrule_array[0], 'YYYYMMDD') == this.hodu_date_to_format_string(new Date(), 'YYYYMMDD') );
            
            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : event.original_start,
                original_end : event.original_end
            });
        }
        
        // 음력 반복 일정
        else if ( event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.lunar_yn == true ) {

            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : event.original_start,
                original_end : event.original_end
            });
            
        }

        // 해당 시작일 (TODO 해당 반복일정 조각의 시작일, 종료일을 사용해야함)
        // const start_date : Date = new Date(moment(arg.event.start).format());
        // start_date.setHours(new Date(moment(event.event_data.schedule_date.start).format()).getHours());
        // start_date.setMinutes(new Date(moment(event.event_data.schedule_date.start).format()).getMinutes());
        // event.event_data.schedule_date.start = start_date; // ATOMIC START
        
        // const end_date : Date = new Date(moment(arg.event.end ? arg.event.end : arg.event.start).format());
        // if( arg.event.allDay == true && (event.event_data.schedule_date.isAllDay == true || event.event_data.schedule_date.isContinuos == true) ) {
        //     end_date.setSeconds(-1);
        // }

        // end_date.setHours(new Date(moment(event.event_data.schedule_date.end).format()).getHours());
        // end_date.setMinutes(new Date(moment(event.event_data.schedule_date.end).format()).getMinutes());
        // event.event_data.schedule_date.end = end_date;   // ATOMIC END

        // EventInfo에 이벤트 등록
        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.READ);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 일정 등록 페이지로 이동 
        this.hodu_router_push(`/event/${ event.event_id }`);
    }

    /**
     * DTSTART 제작 : 일정 시작일
     */
    formatDateForRruleDTSTARTByUTC(date : Date) : string {
        return `${dateFormat(date, "UTC:yyyymmdd")}T${dateFormat(date, "UTC:HHMMss")}Z`;
    }

    /**
     * UNTIL 제작 : 해당 날짜 -1일까지 뿌려줌
     */
    formatDateForRruleUNTILByUTC(date : Date) : string {
        date.setDate(date.getDate() + 1); // 월의 마지막 일 이후로 넘어가면 자동으로 다음달로 계산해줌
        return `${dateFormat(date, "UTC:yyyymmdd")}`;
    }

    /**
     * duration 제작
     */
    makeDuration(startDate : Date | string, endDate : Date | string, hasBackground : boolean ) : string {
        let _startDate : Date = startDate instanceof Date ? startDate : new Date(moment(startDate).format());
        let _endDate   : Date = endDate   instanceof Date ? endDate   : new Date(moment(endDate).format());

        // 두 날짜 사이의 간격을 밀리초로 구함
        const timeMills : number = _endDate.getTime() - _startDate.getTime();
        
        // timeMillis를 hour(시간), minute(분)으로 만듬
        const hour   : number = Math.floor(timeMills / 3600 / 1000);
        const minute : number = Math.floor( (timeMills - (hour * 3600 * 1000)) / 60 / 1000 );

        // 강제로 2자리로 만듬 ( 10이상이면 10, 11, 12 그대로, 10미만이면 01, 02, 03 처럼 앞에 0을 붙임 ) 
        let duration_hour   : string = `0${ hasBackground ? hour + 24 : hour}`.slice(-2);
        let duration_minute : string = `0${minute}`.slice(-2);

        // ex) 01:00
        return `${duration_hour}:${duration_minute}`;
    }

    /**
     * 일정 날짜 텍스트 반환
     * 1. 연속일정이면 yyyy.mm.dd hh:mm a ~ yyyy.mm.dd hh:mm a
     * 2. 음력이면 음력 mm.dd
     * 3. 종일이면 종일
     * 4. 일반이면 hh:mm a
     */
    getEventDateText(event) : string {

        try {

            if( event.event_data.schedule_date.lunar_yn == true ) {
                const lunar = event.event_data.schedule_date.lunar_start;
                const lunars = lunar.split('-');
                return `음력 ${lunars[1]}.${lunars[2]}`;
            }

            const today = moment().format('YYYYMMDD');

            const start_yyyymmdd = moment(event.event_data.schedule_date.start).format('YYYYMMDD');
            const start = moment(event.event_data.schedule_date.start).format('YYYY.MM.DD');
            const start_time = this.amPmStringToLocaleAmPmString(moment(event.event_data.schedule_date.start).format('hh:mm a'));

            const end_yyyymmdd = moment(event.event_data.schedule_date.end).format('YYYYMMDD');
            const end = moment(event.event_data.schedule_date.end).format('YYYY.MM.DD');
            const end_time = this.amPmStringToLocaleAmPmString(moment(event.event_data.schedule_date.end).format('hh:mm a'));
            
            if( event.event_data.schedule_date.isAllDay ) {
                if( today != start_yyyymmdd || today != end_yyyymmdd ) {
                    return `${start} ~ ${end}`;
                }
                return "종일";
            }

            if( today != start_yyyymmdd || today != end_yyyymmdd ) {
                return `${today == start_yyyymmdd ? '오늘' : start} ${start_time} ~ ${start_yyyymmdd == end_yyyymmdd ? '' : (today == end_yyyymmdd ? '오늘' : end)} ${end_time}`;
            }

            return `${start_time} ~ ${end_time}`;

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return "";
    }

    /**
     * 공지사항으로 이동
     */
    async moveNotice(notice) : Promise<void> {
        
        this.doGroupId(notice.group_id);
        this.doTeamId(notice.team_id);

        this.doGroupTeamOption({
            group_team_name : this.group_team_name,
            group_team_color : this.group_team_color,
            group_team_image : this.group_team_image,
            group_team_descript : this.group_team_descript,
            biz_id : this.biz_id,
            noti_uid : notice.noti_uid
        });

        this.doGroupTeamOption(this.group_team_option);
        this.hodu_router_push(`/group/${new Date().getTime()}/notice_detail`);

        try {
            await this.hodu_api_call(`api/v1/notification/insertRead/${notice.noti_uid}`, API_METHOD.PUT, {}, false);
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 날짜 변경 (+-day)
     */
    changeAtttendanceDate(day : number) : void {
        this.attendance_start = moment(this.attendance_start).add('day', day).toDate();
        this.attendance_end = moment(this.attendance_end).add('day', day).toDate();
        this.attendance_selected = moment([this.attendance_selected.substring(0,4), this.attendance_selected.substring(4,6), this.attendance_selected.substring(6,8)].join('-')).add('day', day).format('YYYYMMDD');
        this.$nextTick(async() => {
            if( this.attendance_tab == 'MY' ) {
                await this.getMyAttendDataMonthInfo();
            }
            else {
                await Promise.all([this.getMyAttendDataMonthInfo(), this.getEmpAttendDataDayInfo()]);
            }
            this.$nextTick(() => this.makeStatisticsData());
        });
    }

    /**
     * 과거에 정상 체크
     */
    isNormal(day) : boolean {
        let attend_info : any = null;

        for( const data of day.data ) {
            if( data.attend_type == 10 ) {
                attend_info = data;
            }
        }

        return day.work_count > 0 && attend_info != null && attend_info.start_time != null && attend_info.end_time != null && (attend_info.attend_state == '30' || attend_info.attend_state == '10');
    }

    /**
     * 미체크
     */
    isUncheck(day) : boolean {
        let attend_info : any = null;

        for( const data of day.data ) {
            if( data.attend_type == 10 ) {
                attend_info = data;
            }
        }

        // 오늘은 무조건 미체크 아님
        if( day.yyyymmdd == this.hodu_date_to_format_string(new Date(), 'YYYYMMDD') ) {
            return false;
        }

        return day.work_count > 0 && ((attend_info == null && Number(day.yyyymmdd) < Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD'))) || (attend_info != null && (attend_info.attend_state == '20' || attend_info.start_time == null || attend_info.end_time == null)));
    }

    /**
     * 결근 여부
     */
    isAwol(day) : boolean {
        let attend_info : any = null;

        for( const data of day.data ) {
            if( data.attend_type == 10 ) {
                attend_info = data;
            }
        }

        return day.work_count > 0 && attend_info != null && attend_info.attend_state == '40';
    }

    /**
     * 오늘날짜인지 구분
     */
    isToday(day) : boolean {
        return day.yyyymmdd == this.hodu_date_to_format_string(new Date(), 'YYYYMMDD');
    }

    /**
     * 쉬는날
     */
    isHoliday(day) : boolean {
        return day.work_count == 0;
    }

    /**
     * 휴가인지 구분
     */
    isVacation(day) : boolean {

        let vacation_count = 0;

        if( day.work_count == 0 ) {
            return false;
        }

        for( const data of day.data ) {
            if( data.attend_type == 20 ) {
                vacation_count += data.daycount;
            }
        }

        return day.work_count - vacation_count <= 0;
    }

    /**
     * 출장인지 구분
     */
    isBusinesstrip(day) : boolean {

        let businesstrip_count = 0;

        if( day.work_count == 0 ) {
            return false;
        }

        for( const data of day.data ) {
            if( data.attend_type == 30 ) {
                businesstrip_count += data.daycount;
            }
        }

        return day.work_count - businesstrip_count <= 0;
    }

    /**
     * 휴가, 출장 동시에 있어서 근무 안찍는 날이라면
     */
    isVacationAndBusinessTrip(day) : boolean {
        let vacation_count = 0;
        let businesstrip_count = 0;

        if( day.work_count == 0 ) {
            return false;
        }

        for( const data of day.data ) {
            if( data.attend_type == 20 ) {
                vacation_count += data.daycount;
            }
            else if( data.attend_type == 30 ) {
                businesstrip_count += data.daycount;
            }
        }

        // 둘중 하나라도 없다면 false
        if( vacation_count == 0 || businesstrip_count == 0 ) return false;

        return day.work_count - vacation_count - businesstrip_count <= 0;
    }

    /**
     * 출근 시간
     */
    getStartTimeText(events : any[]) : string {
        let start_time = null;

        for( const attend_info of events ) {
            // 출 퇴근 정보
            if( attend_info.attend_type == 10 ) {
                start_time = attend_info.start_time;
            }

            // 휴가 정보
            else if( attend_info.attend_type == 20 ) {
                
            }
        }

        return start_time != null ? this.processTimeText(start_time) : '미체크'
    }

    /**
     * 퇴근 시간
     */
    getEndTimeText(events : any[]) : string {
        let end_time = null;
        const today_string = moment().format('YYYYMMDD');

        if( this.computedAttendacneSelectedInfo == null ) return '';

        for( const attend_info of events ) {
            // 출 퇴근 정보
            if( attend_info.attend_type == 10 ) {
                end_time = attend_info.end_time;
            }
            // 휴가 정보
            else if( attend_info.attend_type == 20 ) {
                
            }
        }

        return end_time != null ? this.processTimeText(end_time) : (
            (moment(this.computedAttendacneSelectedInfo.date).format('YYYYMMDD') == today_string) ? (this.getStartTimeText(events) == '미체크' ? '미체크' : '근무중') : '미체크'
        );
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string | number) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;

        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 남은 근무 시간 또는 근무한 시간 텍스트 반환
     */
    getRemainTimeOrWorkTimeText() : string {

        if( this.computedAttendacneSelectedInfo == null ) return '';

        let attend_info : any = null;
        for( const data of this.computedAttendacneSelectedInfo.data ) {
            if( data.attend_type == 10 ) {
                attend_info = data;
            }
        }

        // 휴가 또는 휴일이라면 혹은 미래날짜라면
        if( this.isVacation(this.computedAttendacneSelectedInfo) == true || this.isHoliday(this.computedAttendacneSelectedInfo) == true || Number(this.attendance_selected) > Number(moment().format('YYYYMMDD')) ) {
            return "근무시간";
        }

        // 오늘 시작일자, 종료일자 비어있는 경우
        if( this.isToday(this.computedAttendacneSelectedInfo) && ( attend_info == null || attend_info.start_time == null || attend_info.end_time == null ) ) {
            if( this.attend_emp_info == null ) return "";
            return "남은시간"
        }

        return "근무시간";
    }

    /**
     * 남은 근무 시간 또는 근무한 시간 반환
     */
    getRemainTimeOrWorkTime() : string {

        if( this.computedAttendacneSelectedInfo == null ) return '';

        let attend_info : any = null;
        for( const data of this.computedAttendacneSelectedInfo.data ) {
            if( data.attend_type == 10 ) {
                attend_info = data;
            }
        }

        // 휴가 또는 휴일이라면
        if( this.isVacation(this.computedAttendacneSelectedInfo) == true || this.isHoliday(this.computedAttendacneSelectedInfo) == true ) {
            return '-';
        }

        // 미래 날짜라면?
        if( Number(this.attendance_selected) > Number(moment().format('YYYYMMDD')) ) {
            return '-';
        }

        const target_yyyy_mm_dd = moment(this.attendance_selected).format('YYYY.MM.DD')

        // 오늘 시작일자, 종료일자 비어있는 경우
        if( this.isToday(this.computedAttendacneSelectedInfo) && ( attend_info == null || attend_info.start_time == null || attend_info.end_time == null ) ) {
            // 정규 퇴근 시간 - 현재 기준이 되는 시간 
            if( this.attend_emp_info == null ) return "-";

            const work_type = this.attend_emp_info.work_type[new Date(this.computedAttendacneSelectedInfo.date).getDay()]; 
            const to_time   = work_type.pm_to != null ? work_type.pm_to : (work_type.am_to != null ? work_type.am_to : '')

            if( to_time == '' ) return '-';

            const to_time_millis = moment(`${target_yyyy_mm_dd} ${to_time.substring(0,2)}:${to_time.substring(2,4)}:${ to_time.substring(4,6) == '' ? "00" : to_time.substring(4,6) }`).toDate().getTime();
            let result = to_time_millis - this.current_time_millis;

            if( result <= 0 ) {
                return "00:00:00";
            }
            
            const hour = Math.floor( result / (3600 * 1000) );
            const min = Math.floor((result - (hour * 3600 * 1000)) / (60 * 1000));
            const second = Math.floor((result - (hour * 3600 * 1000) - (min * (60 * 1000))) / 1000);
            
            return `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)}:${`0${second}`.slice(-2)}`;
        }

        if( attend_info == null || attend_info.start_time == null || attend_info.end_time == null ) {
            // 결근
            if( (attend_info != null && attend_info.attend_state == '40') ) return "없음";

            // 미체크
            return '알수없음';
        }

        // 종료시간 - 시작시간
        const from_time_millis = moment(`${target_yyyy_mm_dd} ${attend_info.start_time.substring(0,2)}:${attend_info.start_time.substring(2,4)}:${ attend_info.start_time.substring(4,6) == '' ? '00' : attend_info.start_time.substring(4,6) }`).toDate().getTime();
        const to_time_millis = moment(`${target_yyyy_mm_dd} ${attend_info.end_time.substring(0,2)}:${attend_info.end_time.substring(2,4)}:${ attend_info.end_time.substring(4,6) == '' ? '00' : attend_info.end_time.substring(4,6) }`).toDate().getTime();

        const result = to_time_millis - from_time_millis;

        if( result <= 0 ) {
            return "00:00:00";
        } 

        const hour = Math.floor( result / (3600 * 1000) );
        const min = Math.floor((result - (hour * 3600 * 1000)) / (60 * 1000));
        const second = Math.floor((result - (hour * 3600 * 1000) - (min * (60 * 1000))) / 1000);

        return `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)}:${`0${second}`.slice(-2)}`;
    }

    /**
     * 근무 시작하는 시각
     */
    getStartWork() : string {
        if( this.attend_emp_info == null || this.computedAttendacneSelectedInfo == null ) return '';

        // 휴가 또는 휴일이라면
        if( this.isVacation(this.computedAttendacneSelectedInfo) == true || this.isHoliday(this.computedAttendacneSelectedInfo) == true ) {
            return '없음';
        }

        const work_type = this.attend_emp_info.work_type[new Date(this.computedAttendacneSelectedInfo.date).getDay()];
        return work_type.am_from != null ? this.processTimeText(work_type.am_from) : (work_type.pm_from != null ? this.processTimeText(work_type.pm_from) : '없음');
    }

    /**
     * 근무 끝나는 시각
     */
    getEndWork() : string {
        if( this.attend_emp_info == null || this.computedAttendacneSelectedInfo == null ) return '';

        // 휴가 또는 휴일이라면
        if( this.isVacation(this.computedAttendacneSelectedInfo) == true || this.isHoliday(this.computedAttendacneSelectedInfo) == true ) {
            return '없음';
        }

        const work_type = this.attend_emp_info.work_type[new Date(this.computedAttendacneSelectedInfo.date).getDay()];
        return work_type.pm_to != null ? this.processTimeText(work_type.pm_to) : (work_type.am_to != null ? this.processTimeText(work_type.am_to) : '없음');
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getAttendanceStart() : string {
        const feature = this.get_group_feature(this.group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * 통계 데이터 만들기
     */
    makeStatisticsData() : void {
        
        // 근태관리일때 통계그래프 조정
        if( this.tab != 'ATTENDANCE' ) return;

        this.$nextTick(() => {
            // console.log(this.attendance_tab);

            if( this.attendance_tab == 'MY' ) {
                
                this.google_chart_data.splice(0, this.google_chart_data.length);
                this.google_chart_data.push(['근태현황', '근태현황']);

                // 데이터가 없으면 비어있는 그래프
                if( this.attend_emp_info == null || this.computedAttendacneSelectedInfo == null ) {
                    this.google_chart_data.push(["", 1]);

                    this.google_chart_option = JSON.parse(JSON.stringify({
                        title: '',
                        pieHole: 0.6,
                        pieSliceText : "none",
                        slices: {
                            0: {color: '#f1f3f5'},
                        },
                        legend: 'none',
                        tooltip : { trigger : 'none' },
                        chartArea : { width : '85%', height : '85%' }
                    }));
                    return;
                }

                let attend_info : any = null;
                let vacation_info : any[] = [];
                for( const obj of this.computedAttendacneSelectedInfo.data ) {
                    if( obj.attend_type == 10 ) {
                        attend_info = obj;
                    }
                    else if( obj.attend_type == 20 ) {
                        vacation_info.push(obj);
                    }
                }

                // 휴가순서는 시작시간이 작은게 먼저 오도록 
                vacation_info.sort((o1, o2) : number => {
                    const o1_start = Number(o1.start_time);
                    const o2_start = Number(o2.start_time);

                    if( o1_start == o2_start) return 0;
                    return o1_start > o2_start ? 1 : -1;
                });

                let has_vacation = false;
                let vacation_start_hhmmss = `${Number.MAX_SAFE_INTEGER}`;
                let vacation_end_hhmmss = "0";
                let vacation_am_pm = '';
                let vacation_start_time_millis = 0;
                let vacation_end_time_millis = 0;
                let vacation_time_millis = 0;

                // 휴가 하나
                if( vacation_info.length == 1 ) {
                    has_vacation = true;
                    vacation_am_pm = Number(vacation_info[0].daycount) == 1 ? 'DAY' : vacation_info[0].vacation_am_pm;
                    vacation_start_hhmmss = vacation_info[0].start_time;
                    vacation_end_hhmmss = vacation_info[0].end_time;
                }
                
                // 휴가 다수
                else if( vacation_info.length > 1 ) {
                    has_vacation = true;
                    vacation_am_pm = 'DAY';
                    for( const vacation of vacation_info ) {
                        if( Number(vacation.start_time) < Number(vacation_start_hhmmss) ) vacation_start_hhmmss = vacation.start_time;
                        if( Number(vacation.end_time) > Number(vacation_end_hhmmss) ) vacation_end_hhmmss = vacation.end_time;
                    }
                }

                if( has_vacation && vacation_am_pm != 'DAY' ) {
                    vacation_start_time_millis = (Number(vacation_start_hhmmss.substring(0,2)) * 3600000) + (Number(vacation_start_hhmmss.substring(2,4)) * 60000) + (Number(vacation_start_hhmmss.substring(4,6)) * 1000);
                    vacation_end_time_millis = (Number(vacation_end_hhmmss.substring(0,2)) * 3600000) + (Number(vacation_end_hhmmss.substring(2,4)) * 60000) + (Number(vacation_end_hhmmss.substring(4,6)) * 1000);
                    vacation_time_millis = vacation_end_time_millis - vacation_start_time_millis;
                }

                // console.log(`vacation_start_time_millis : ${vacation_start_time_millis}`);
                // console.log(`vacation_end_time_millis : ${vacation_end_time_millis}`);
                // console.log(`vacation_time_millis : ${vacation_time_millis}`);

                // 오늘
                if( this.attendance_selected == moment().format('YYYYMMDD') ) {
                    
                    // 결근이면 전체 #ff6363
                    if( this.isAwol(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["결근", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#ff6363'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }

                    // 휴가·출장이면 반반 하늘색, 초록색 그래프
                    if( this.isVacationAndBusinessTrip(this.computedAttendacneSelectedInfo) == true ) {
                        
                        // 어느쪽이 먼저인지 판단
                        if( vacation_am_pm == 'AM' ) {
                            this.google_chart_data.push(["휴가", 0.5]);
                            this.google_chart_data.push(["출장", 0.5]);
                        }
                        else {
                            this.google_chart_data.push(["출장", 0.5]);
                            this.google_chart_data.push(["휴가", 0.5]);
                        }

                        const slices = vacation_am_pm == 'AM' ? { 0: {color: '#4dbaff'}, 1: {color: '#13d08b'} } : { 0: {color: '#13d08b'}, 1: {color: '#4dbaff'} };

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: slices,
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));

                        return;
                    }

                    // 휴가면 전체 하늘색 그래프
                    if( this.isVacation(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["휴가", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#4dbaff'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }
                    
                    // 출장이면 전체 초록색 그래프
                    if( this.isBusinesstrip(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["출장", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#13d08b'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }

                    // 휴일이면 빈그래프
                    if( this.isHoliday(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["휴일", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#f1f3f5'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }
                    
                    // 해당일자 근무 타입에서 계산
                    const work_type = this.attend_emp_info.work_type[this.computedAttendacneSelectedInfo.date.getDay()];

                    const start_hhmmss = work_type.am_from != null ? work_type.am_from : work_type.pm_from;
                    const end_hhmmss = work_type.pm_to != null ? work_type.pm_to : work_type.am_to;

                    const current_time_millis_hhmmss = moment(this.current_time_millis).format('HHmmss');
                    const current_time_millis = (Number(current_time_millis_hhmmss.substring(0,2)) * 3600000) + (Number(current_time_millis_hhmmss.substring(2,4)) * 60000) + (Number(current_time_millis_hhmmss.substring(4,6)) * 1000);

                    // 오늘 정규 출근 시간 (ms)
                    const start_time_millis = (Number(start_hhmmss.substring(0,2)) * 3600000) + (Number(start_hhmmss.substring(2,4)) * 60000) + (Number(start_hhmmss.substring(4,6)) * 1000);

                    // 오늘 정규 퇴근 시간 (ms)
                    const end_time_millis = (Number(end_hhmmss.substring(0,2)) * 3600000) + (Number(end_hhmmss.substring(2,4)) * 60000) + (Number(end_hhmmss.substring(4,6)) * 1000);

                    // 오늘 총 근무시간 (ms)
                    const total_time_millis = end_time_millis - start_time_millis;

                    // 오늘 실제 출근 시간 (ms)
                    const emp_start_time_millis = attend_info == null || attend_info.start_time == null ? -1 : ((Number(attend_info.start_time.substring(0, 2)) * 3600000) + (Number(attend_info.start_time.substring(2,4)) * 60000));

                    // 오늘 실제 퇴근 시간 (ms)
                    const emp_end_time_millis = attend_info == null || attend_info.end_time == null ? -1 : ((Number(attend_info.end_time.substring(0, 2)) * 3600000) + (Number(attend_info.end_time.substring(2,4)) * 60000));

                    // 조기출근시 남은 시간에 더해준다
                    let remain_add_time_millis = 0; 
                    if( emp_start_time_millis > -1 && start_time_millis - emp_start_time_millis > 0 ) {
                        remain_add_time_millis = start_time_millis - emp_start_time_millis;
                    }

                    // 휴게시간 (ms)
                    let break_start_time_millis = 0;
                    let break_end_time_millis = 0;
                    let break_time_millis = 0;  
                    if( work_type.am_to != null && work_type.pm_from != null ) {
                        const am_to_hhmmss = work_type.am_to;
                        const pm_from_hhmmss = work_type.pm_from;

                        break_start_time_millis = (Number(am_to_hhmmss.substring(0,2)) * 3600000) + (Number(am_to_hhmmss.substring(2,4)) * 60000) + (Number(am_to_hhmmss.substring(4,6)) * 1000);
                        break_end_time_millis = (Number(pm_from_hhmmss.substring(0,2)) * 3600000) + (Number(pm_from_hhmmss.substring(2,4)) * 60000) + (Number(pm_from_hhmmss.substring(4,6)) * 1000);

                        break_time_millis = break_end_time_millis - break_start_time_millis;
                    }

                    // console.log(`start_time_millis : ${start_time_millis}`);
                    // console.log(`end_time_millis : ${end_time_millis}`);
                    // console.log(`total_time_millis : ${total_time_millis}`);
                    // console.log(`emp_start_time_millis : ${emp_start_time_millis}`);
                    // console.log(`emp_end_time_millis : ${emp_end_time_millis}`);
                    // console.log(`current_time_millis : ${current_time_millis}`);
                    // console.log(`break_start_time_millis : ${break_start_time_millis}`);
                    // console.log(`break_end_time_millis : ${break_end_time_millis}`);
                    // console.log(`break_time_millis : ${break_time_millis}`);

                    // 컬러데이터
                    const slices_color : any = [];

                    if( has_vacation && vacation_am_pm == 'AM' ) {
                        this.google_chart_data.push(["휴가", vacation_time_millis / 60000]);
                        slices_color.push("#4dbaff");
                    }

                    // 지각 #ff2e2e (실제 출근 시간 - 정규 출근시간 >= 0) 출근시간이 없다면 (현재시간 - 정규 출근시간)
                    let late_time_millis = emp_start_time_millis >= 0 ? (emp_start_time_millis - start_time_millis) : (current_time_millis - start_time_millis);
                    if( late_time_millis < 0 ) late_time_millis = 0;
                    if( late_time_millis > 0 ) {
                        this.google_chart_data.push(["지각", late_time_millis / 60000]);
                        slices_color.push("#ff2e2e");
                    }

                    // 근무시간 #477fff 퇴근 시간이 있다면 (퇴근시간 - 출근시간) 없다면 (현재시간 - 출근시간) 출근시간이 없다면 0
                    let work_time_millis = emp_end_time_millis >= 0 ? (emp_end_time_millis - emp_start_time_millis) 
                                                                    : (emp_start_time_millis >= 0 ? (current_time_millis - emp_start_time_millis) : 0); 
                                                                   
                    
                    // // 휴게시간 이전 퇴근 또는 근무중 (근무시간 차감 없음)
                    // if( (emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) < break_start_time_millis ) {
                    //     // DO NOTHING
                    // }
                    // // 휴게시간 중 퇴근 또는 근무중
                    // else if( break_start_time_millis < (emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) && (emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) < break_end_time_millis ) {
                    //     work_time_millis = work_time_millis - ((emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) - break_start_time_millis)
                    // }
                    // // 휴게시간 이후 퇴근 또는 근무중
                    // else if( break_end_time_millis < (emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) ) {
                    //     work_time_millis = work_time_millis - break_time_millis;
                    // }

                    if( work_time_millis < 0 ) work_time_millis = 0;
                    if( work_time_millis > 0 ) {
                        this.google_chart_data.push(["근무시간", work_time_millis / 60000]);
                        slices_color.push("#477fff");
                    }
                    
                    // 남은시간 #f1f3f5 (총근무시간 - 지각값 - 근무시간값)
                    let remain_time_millis = total_time_millis - late_time_millis - work_time_millis + remain_add_time_millis;
                    if( has_vacation ) remain_time_millis = remain_time_millis - vacation_time_millis;

                    // // 휴게시간 이전 퇴근 또는 근무중 (근무시간 차감 없음)
                    // if( (emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) < break_start_time_millis ) {
                    //     // DO NOTHING
                    // }
                    // // 휴게시간 중 퇴근 또는 근무중
                    // else if( break_start_time_millis < (emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) && (emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) < break_end_time_millis ) {
                    //     remain_time_millis = remain_time_millis - ((emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) - break_start_time_millis)
                    // }
                    // // 휴게시간 이후 퇴근 또는 근무중
                    // else if( break_end_time_millis < (emp_end_time_millis > 0 ? emp_end_time_millis : current_time_millis) ) {
                    //     remain_time_millis = remain_time_millis - break_time_millis;
                    // }

                    if( remain_time_millis < 0 ) remain_time_millis = 0;
                    if( remain_time_millis > 0 ) {
                        this.google_chart_data.push(["남은시간", remain_time_millis / 60000]);
                        slices_color.push("#f1f3f5");
                    }

                    if( has_vacation && vacation_am_pm == 'PM' ) {
                        this.google_chart_data.push(["휴가", vacation_time_millis / 60000]);
                        slices_color.push("#4dbaff");
                    }

                    const slices = {};
                    for( const color of slices_color ) {
                        slices[`${slices_color.indexOf(color)}`] = { color : color };
                    }

                    this.google_chart_option = JSON.parse(JSON.stringify({
                        title: '',
                        pieHole: 0.6,
                        pieSliceText : "none",
                        slices: slices,
                        legend: 'none',
                        tooltip: { textStyle : { fontSize : 13 }, text : "value" },
                        chartArea : { width : '85%', height : '85%' }
                    }));
                }

                // 과거
                else if( Number(this.attendance_selected) < Number(moment().format('YYYYMMDD')) ) {
                    
                    // 결근이면 전체 #ff6363
                    if( this.isAwol(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["결근", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#ff6363'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }

                    // 휴가·출장이면 반반 하늘색, 초록색 그래프
                    if( this.isVacationAndBusinessTrip(this.computedAttendacneSelectedInfo) == true ) {
                        
                        // 어느쪽이 먼저인지 판단
                        if( vacation_am_pm == 'AM' ) {
                            this.google_chart_data.push(["휴가", 0.5]);
                            this.google_chart_data.push(["출장", 0.5]);
                        }
                        else {
                            this.google_chart_data.push(["출장", 0.5]);
                            this.google_chart_data.push(["휴가", 0.5]);
                        }

                        const slices = vacation_am_pm == 'AM' ? { 0: {color: '#4dbaff'}, 1: {color: '#13d08b'} } : { 0: {color: '#13d08b'}, 1: {color: '#4dbaff'} };

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: slices,
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));

                        return;
                    }

                    // 휴가면 전체 하늘색 그래프
                    if( this.isVacation(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["휴가", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#4dbaff'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }

                    // 출장이면 전체 초록색 그래프
                    if( this.isBusinesstrip(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["출장", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#13d08b'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }

                    // 휴일이면 전체 빈그래프
                    if( this.isHoliday(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["휴일", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#f1f3f5'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }

                    // 해당일자 근무 타입에서 계산
                    const work_type = this.attend_emp_info.work_type[this.computedAttendacneSelectedInfo.date.getDay()];

                    const start_hhmmss = work_type.am_from != null ? work_type.am_from : work_type.pm_from;
                    const end_hhmmss = work_type.pm_to != null ? work_type.pm_to : work_type.am_to;

                    // 과거 정규 출근 시간 (ms)
                    const start_time_millis = (Number(start_hhmmss.substring(0,2)) * 3600000) + (Number(start_hhmmss.substring(2,4)) * 60000) + (Number(start_hhmmss.substring(4,6)) * 1000);

                    // 과거 정규 퇴근 시간 (ms)
                    const end_time_millis = (Number(end_hhmmss.substring(0,2)) * 3600000) + (Number(end_hhmmss.substring(2,4)) * 60000) + (Number(end_hhmmss.substring(4,6)) * 1000);
                    
                    // 과거 총 근무시간 (ms)
                    const total_time_millis = end_time_millis - start_time_millis;

                    // 과거 실제 출근 시간 (ms)
                    const emp_start_time_millis = attend_info == null || attend_info.start_time == null ? -1 : ((Number(attend_info.start_time.substring(0, 2)) * 3600000) + (Number(attend_info.start_time.substring(2,4)) * 60000));

                    // 과거 실제 퇴근 시간 (ms)
                    const emp_end_time_millis = attend_info == null || attend_info.end_time == null ? -1 : ((Number(attend_info.end_time.substring(0, 2)) * 3600000) + (Number(attend_info.end_time.substring(2,4)) * 60000));
                    
                    // 조기출근시 남은 시간에 더해준다
                    let remain_add_time_millis = 0; 
                    if( emp_start_time_millis > -1 && start_time_millis - emp_start_time_millis > 0 ) {
                        remain_add_time_millis = start_time_millis - emp_start_time_millis;
                    }

                    // 휴게시간 (ms)
                    let break_start_time_millis = 0;
                    let break_end_time_millis = 0;
                    let break_time_millis = 0;  
                    if( work_type.am_to != null && work_type.pm_from != null ) {
                        const am_to_hhmmss = work_type.am_to;
                        const pm_from_hhmmss = work_type.pm_from;

                        break_start_time_millis = (Number(am_to_hhmmss.substring(0,2)) * 3600000) + (Number(am_to_hhmmss.substring(2,4)) * 60000) + (Number(am_to_hhmmss.substring(4,6)) * 1000);
                        break_end_time_millis = (Number(pm_from_hhmmss.substring(0,2)) * 3600000) + (Number(pm_from_hhmmss.substring(2,4)) * 60000) + (Number(pm_from_hhmmss.substring(4,6)) * 1000);

                        break_time_millis = break_end_time_millis - break_start_time_millis;
                    }

                    // 컬러데이터
                    const slices_color : any = [];

                    // console.log(`start_time_millis : ${start_time_millis}`);
                    // console.log(`end_time_millis : ${end_time_millis}`);
                    // console.log(`total_time_millis : ${total_time_millis}`);
                    // console.log(`emp_start_time_millis : ${emp_start_time_millis}`);
                    // console.log(`emp_end_time_millis : ${emp_end_time_millis}`);
                    
                    if( has_vacation && vacation_am_pm == 'AM' ) {
                        this.google_chart_data.push(["휴가", vacation_time_millis / 60000]);
                        slices_color.push("#4dbaff");
                    }

                    // 지각 #ff2e2e (실제 출근 시간 - 정규 출근시간 > 0)
                    let late_time_millis = emp_start_time_millis - start_time_millis;
                    if( late_time_millis < 0 ) late_time_millis = 0;
                    if( late_time_millis > 0 ) {
                        this.google_chart_data.push(["지각", late_time_millis / 60000]);
                        slices_color.push("#ff2e2e");
                    }

                    // 미체크 #ffa70e (start_time이 비었다면 해당일 전체, end_time이 비어있다면 총 근무시간 - 지각시간)
                    let uncheck_time_millis = emp_start_time_millis == -1 ? total_time_millis : ( emp_end_time_millis == -1 ? total_time_millis - late_time_millis : 0);
                    if( uncheck_time_millis < 0 ) uncheck_time_millis = 0;
                    if( uncheck_time_millis > 0 ) {
                        this.google_chart_data.push(["미체크", uncheck_time_millis / 60000]);
                        slices_color.push("#ffa70e");
                    }

                    // 근무시간 #477fff (퇴근시간 - 출근시간) 미체크라면 0
                    let work_time_millis = emp_end_time_millis == 0 ? 0 : emp_end_time_millis - emp_start_time_millis;

                    // // 휴게시간 이전 퇴근 (근무시간 차감 없음)
                    // if( emp_end_time_millis < break_start_time_millis ) {
                    //     // DO NOTHING
                    // }
                    // // 휴게시간 중 퇴근
                    // else if( break_start_time_millis < emp_end_time_millis && emp_end_time_millis < break_end_time_millis ) {
                    //     work_time_millis = work_time_millis - (emp_end_time_millis - break_start_time_millis)
                    // }
                    // // 휴게시간 이후 퇴근
                    // else if( break_end_time_millis < emp_end_time_millis ) {
                    //     work_time_millis = work_time_millis - break_time_millis;
                    // }

                    if( work_time_millis < 0 ) work_time_millis = 0;
                    if( work_time_millis > 0 ) {
                        this.google_chart_data.push(["근무시간", work_time_millis / 60000]);
                        slices_color.push("#477fff");
                    }

                    // 남은시간 #f1f3f5 (총근무시간 - 지각값 - 미체크시간값 - 근무시간값) 조기퇴근인 경우에만
                    let remain_time_millis = total_time_millis - late_time_millis - uncheck_time_millis - work_time_millis + remain_add_time_millis;
                    if( has_vacation ) remain_time_millis = remain_time_millis - vacation_time_millis;

                    // // 휴게시간 이전 퇴근 또는 근무중 (근무시간 차감 없음)
                    // if( emp_end_time_millis < break_start_time_millis ) {
                    //     // DO NOTHING
                    // }
                    // // 휴게시간 중 퇴근 또는 근무중
                    // else if( break_start_time_millis < emp_end_time_millis && emp_end_time_millis < break_end_time_millis ) {
                    //     remain_time_millis = remain_time_millis - (emp_end_time_millis - break_start_time_millis)
                    // }
                    // // 휴게시간 이후 퇴근 또는 근무중
                    // else if( break_end_time_millis < emp_end_time_millis ) {
                    //     remain_time_millis = remain_time_millis - break_time_millis;
                    // }

                    if( remain_time_millis < 0 ) remain_time_millis = 0;
                    if( remain_time_millis > 0 ) {
                        this.google_chart_data.push(["조기퇴근", remain_time_millis / 60000]);
                        slices_color.push("#f1f3f5");
                    }

                    if( has_vacation && vacation_am_pm == 'PM' ) {
                        this.google_chart_data.push(["휴가", vacation_time_millis / 60000]);
                        slices_color.push("#4dbaff");
                    }

                    const slices = {};
                    for( const color of slices_color ) {
                        slices[`${slices_color.indexOf(color)}`] = { color : color };
                    }

                    this.google_chart_option = JSON.parse(JSON.stringify({
                        title: '',
                        pieHole: 0.6,
                        pieSliceText : "none",
                        slices: slices,
                        legend: 'none',
                        tooltip: { textStyle : { fontSize : 13 }, text : "value" },
                        chartArea : { width : '85%', height : '85%' }
                    }));
                }
                
                // 미래
                else {

                    // 휴가·출장이면 반반 하늘색, 초록색 그래프
                    if( this.isVacationAndBusinessTrip(this.computedAttendacneSelectedInfo) == true ) {
                        
                        // 어느쪽이 먼저인지 판단
                        if( vacation_am_pm == 'AM' ) {
                            this.google_chart_data.push(["휴가", 0.5]);
                            this.google_chart_data.push(["출장", 0.5]);
                        }
                        else {
                            this.google_chart_data.push(["출장", 0.5]);
                            this.google_chart_data.push(["휴가", 0.5]);
                        }

                        const slices = vacation_am_pm == 'AM' ? { 0: {color: '#4dbaff'}, 1: {color: '#13d08b'} } : { 0: {color: '#13d08b'}, 1: {color: '#4dbaff'} };

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: slices,
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));

                        return;
                    }

                    // 휴가면 전체 하늘색 그래프
                    if( this.isVacation(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["휴가", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#4dbaff'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }

                    // 출장이면 전체 하늘색 그래프
                    if( this.isBusinesstrip(this.computedAttendacneSelectedInfo) == true ) {
                        this.google_chart_data.push(["출장", 1]);

                        this.google_chart_option = JSON.parse(JSON.stringify({
                            title: '',
                            pieHole: 0.6,
                            pieSliceText : "none",
                            slices: {
                                0: {color: '#13d08b'},
                            },
                            tooltip : { textStyle : { fontSize : 13 }, text : "value", isHtml : true },
                            legend: 'none',
                            chartArea : { width : '85%', height : '85%' }
                        }));
                        return;
                    }

                    // 무조건 백그라운드 비어있음
                    this.google_chart_data.push(["", 1]);

                    this.google_chart_option = JSON.parse(JSON.stringify({
                        title: '',
                        pieHole: 0.6,
                        pieSliceText : "none",
                        slices: {
                            0: {color: '#f1f3f5'},
                        },
                        legend: 'none',
                        tooltip : { trigger : 'none' },
                        chartArea : { width : '85%', height : '85%' }
                    }));
                }

                // $('.tabAtndnceList .atn_right .atngraph').css("background", `conic-gradient(#477fff 0% 0%, #ffffff 0% 100%)`);
            }
            else {

                // 통계값
                // let percent_benchmark = 100;

                // console.log(percent_benchmark);
                
                // 통계값이 0이 아니라면 기본적으로 5%씩 차지함 
                // if( this.computedAttendanceAllStatistics.normal > 0 ) percent_benchmark -= 5;
                // if( this.computedAttendanceAllStatistics.uncheck > 0 ) percent_benchmark -= 5;
                // if( this.computedAttendanceAllStatistics.late > 0 ) percent_benchmark -= 5;
                // if( this.computedAttendanceAllStatistics.awol > 0 ) percent_benchmark -= 5;
                // if( this.computedAttendanceAllStatistics.vacation > 0 ) percent_benchmark -= 5;

                this.google_chart_data.splice(0, this.google_chart_data.length);
                this.google_chart_data.push(['근태현황', '근태현황']);

                // 일 아무도 안하는 날은 #f1f3f5로 전부 채워진 빈 그래프 생성
                if( this.attend_list.length == 0 ) {
                    this.google_chart_data.push(["", 1]);

                    this.google_chart_option = JSON.parse(JSON.stringify({
                        title: '',
                        pieHole: 0.6,
                        pieSliceText : "none",
                        slices: {
                            0: {color: '#f1f3f5'},
                        },
                        legend: 'none',
                        tooltip : { trigger : 'none' },
                        chartArea : { width : '85%', height : '85%' }
                    }));
                    return;
                }

                const slices_color : any = [];

                // let statistics : any[] = [];
                if( this.computedAttendanceAllStatistics.normal > 0 ) {
                    // let percent = 5 + ((this.computedAttendanceAllStatistics.normal / this.computedAttendanceAllStatistics.all) * (percent_benchmark / 100) * 100);
                    // statistics.push({ "type" : 'NORMAL', "percent" : percent });
                    this.google_chart_data.push(["정상", Number(this.computedAttendanceAllStatistics.normal)]);
                    slices_color.push("#477fff");
                }
                if( this.computedAttendanceAllStatistics.uncheck > 0 ) {
                    // let percent = 5 + ((this.computedAttendanceAllStatistics.uncheck / this.computedAttendanceAllStatistics.all) * (percent_benchmark / 100) * 100);
                    // statistics.push({ "type" : 'UNCHECK', "percent" : percent });
                    this.google_chart_data.push(["미체크", Number(this.computedAttendanceAllStatistics.uncheck)]);
                    slices_color.push("#ffa70e");
                }
                if( this.computedAttendanceAllStatistics.late > 0 ) {
                    // let percent = 5 + ((this.computedAttendanceAllStatistics.late / this.computedAttendanceAllStatistics.all) * (percent_benchmark / 100) * 100);
                    // statistics.push({ "type" : 'LATE', "percent" : percent });
                    this.google_chart_data.push(["지각", Number(this.computedAttendanceAllStatistics.late)]);
                    slices_color.push("#ff2e2e");
                }
                if( this.computedAttendanceAllStatistics.awol > 0 ) {
                    // let percent = 5 + ((this.computedAttendanceAllStatistics.awol / this.computedAttendanceAllStatistics.all) * (percent_benchmark / 100) * 100);
                    // statistics.push({ "type" : 'AWOL', "percent" : percent });
                    this.google_chart_data.push(["결근", Number(this.computedAttendanceAllStatistics.awol)]);
                    slices_color.push("#ff6363");
                }
                if( this.computedAttendanceAllStatistics.vacation > 0 ) {
                    // let percent = 5 + ((this.computedAttendanceAllStatistics.vacation / this.computedAttendanceAllStatistics.all) * (percent_benchmark / 100) * 100);
                    // statistics.push({ "type" : 'VACATION', "percent" : percent });
                    this.google_chart_data.push(["휴가", Number(this.computedAttendanceAllStatistics.vacation)]);
                    slices_color.push("#4dbaff");
                }
                if( this.computedAttendanceAllStatistics.businesstrip > 0 ) {
                    // let percent = 5 + ((this.computedAttendanceAllStatistics.vacation / this.computedAttendanceAllStatistics.all) * (percent_benchmark / 100) * 100);
                    // statistics.push({ "type" : 'VACATION', "percent" : percent });
                    this.google_chart_data.push(["출장", Number(this.computedAttendanceAllStatistics.businesstrip)]);
                    slices_color.push("#13d08b");
                }
                // 오늘, 미래날짜는 통계값에 비어있는 데이터 추가
                if( Number(this.attendance_selected) >= Number(moment().format('YYYYMMDD')) ) {
                    
                    const num = this.attend_list.length - Number(this.computedAttendanceAllStatistics.normal)
                                                        - Number(this.computedAttendanceAllStatistics.uncheck)
                                                        - Number(this.computedAttendanceAllStatistics.late)
                                                        - Number(this.computedAttendanceAllStatistics.late)
                                                        - Number(this.computedAttendanceAllStatistics.awol)
                                                        - Number(this.computedAttendanceAllStatistics.vacation)
                                                        - Number(this.computedAttendanceAllStatistics.businesstrip);
                    this.google_chart_data.push(["", num]);
                    slices_color.push("#f1f3f5");
                }

                const slices = {};
                for( const color of slices_color ) {
                    slices[`${slices_color.indexOf(color)}`] = { color : color };
                }

                // 차트 데이터가 비어있다면 빈그래프 추가
                if( slices_color.length < 1 ) {
                    this.google_chart_data.push(["", 1]);

                    this.google_chart_option = JSON.parse(JSON.stringify({
                        title: '',
                        pieHole: 0.6,
                        pieSliceText : "none",
                        slices: {
                            0: {color: '#f1f3f5'},
                        },
                        legend: 'none',
                        tooltip : { trigger : 'none' },
                        chartArea : { width : '85%', height : '85%' }
                    }));
                    return;
                }

                this.google_chart_option = JSON.parse(JSON.stringify({
                    title: '',
                    pieHole: 0.6,
                    pieSliceText : "none",
                    slices: slices,
                    legend: 'none',
                    tooltip: { textStyle : { fontSize : 13 }, text : "value" },
                    chartArea : { width : '85%', height : '85%' }
                }));

                // this.chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('atngraph'));
                // this.chart.draw(googleChartData, options);

                // console.log(statistics);

                // let css = `conic-gradient(`;
                // let accumulate = 0;
                // for( const data of statistics ) {
                    
                //     let color = "";
                //     switch(data.type) {
                //         case "NORMAL":
                //             color = "#477fff";
                //             break;
                //         case "UNCHECK":
                //             color = "#ffa70e";
                //             break;
                //         case "LATE":
                //             color = "#ff2e2e";
                //             break;
                //         case "AWOL":
                //             color = "#ff6363";
                //             break;
                //         case "VACATION":
                //             color = "#4dbaff";
                //             break;
                //     }

                //     css += `${color} ${accumulate}% ${accumulate + data.percent}%,`;
                //     accumulate += data.percent;
                // }
                // css = css.substring(0, css.length - 1); // 마지막 쉼표 제거
                // css += `)`;

                // console.log(css);
                // console.log($('.tabAtndnceList .atn_right .atngraph'));
                // $('.tabAtndnceList .atn_right .atngraph').css("background", css);
            }
        });
    }

    /**
     * 근태현황 날짜 클릭
     */
    async attendanceDayClick(day) : Promise<void> {
        this.attendance_selected = day.yyyymmdd;
        this.$nextTick(async() => {
            
            if( this.attendance_tab == 'MY' ) {
                await this.getMyAttendDataMonthInfo();
            }
            else {
                await Promise.all([this.getMyAttendDataMonthInfo(), this.getEmpAttendDataDayInfo()]);
            }

            await this.makeStatisticsData();
        });
    }

    /**
     * 호두키즈 출석부 날짜 변경 (+-day)
     */
    changeKidsAtttendanceDate(day : number) : void {
        this.kids_attendance_start = moment(this.kids_attendance_start).add('day', day).toDate();
        this.kids_attendance_end = moment(this.kids_attendance_end).add('day', day).toDate();
        this.kids_attendance_selected = moment([this.kids_attendance_selected.substring(0,4), this.kids_attendance_selected.substring(4,6), this.kids_attendance_selected.substring(6,8)].join('-')).add('day', day).format('YYYYMMDD');
        
        this.$nextTick(async() => {
            if( this.kids_attendance_tab == 'MY' ) {
                await this.getMyKidsAttendDataMonthInfo();
            }
            else {
                await Promise.all([this.getMyKidsAttendDataMonthInfo(), this.getKidsAttendDataDayInfo()]);
            }
            this.$nextTick(() => this.makeKidsStatisticsData());
        });
    }

    /**
     * 통계 데이터 만들기
     */
    makeKidsStatisticsData() : void {

        if( this.tab != 'KIDS_ATTENDANCE' ) return;

        this.$nextTick(() => {
            this.kids_google_chart_data.splice(0, this.kids_google_chart_data.length);
            this.kids_google_chart_data.push(['출석부', '출석부']);

            const slices_color : any = [];

            // let statistics : any[] = [];
            if( this.computedKidsAttendanceAllStatistics.normal > 0 ) {
                this.kids_google_chart_data.push(["정상", Number(this.computedKidsAttendanceAllStatistics.normal)]);
                slices_color.push("#477fff");
            }

            if( this.computedKidsAttendanceAllStatistics.absent > 0 ) {
                this.kids_google_chart_data.push(["결석", Number(this.computedKidsAttendanceAllStatistics.absent)]);
                slices_color.push("#ff6363");
            }

            const slices = {};
            for( const color of slices_color ) {
                slices[`${slices_color.indexOf(color)}`] = { color : color };
            }

            // 차트 데이터가 비어있다면 빈그래프 추가
            if( slices_color.length < 1 ) {
                this.kids_google_chart_data.push(["", 1]);

                this.kids_google_chart_option = JSON.parse(JSON.stringify({
                    title: '',
                    pieHole: 0.6,
                    pieSliceText : "none",
                    slices: {
                        0: {color: '#f1f3f5'},
                    },
                    legend: 'none',
                    tooltip : { trigger : 'none' },
                    chartArea : { width : '85%', height : '85%' }
                }));
                return;
            }

            this.kids_google_chart_option = JSON.parse(JSON.stringify({
                title: '',
                pieHole: 0.6,
                pieSliceText : "none",
                slices: slices,
                legend: 'none',
                tooltip: { textStyle : { fontSize : 13 }, text : "value" },
                chartArea : { width : '85%', height : '85%' }
            }));
        })
    }

    /**
     * 호두키즈 출석부 날짜 클릭
     */
    async kidsAttendanceDayClick(day) : Promise<void> {
        this.kids_attendance_selected = day.yyyymmdd;
        this.$nextTick(async() => {
            
            if( this.kids_attendance_tab == 'MY' ) {
                await this.getMyKidsAttendDataMonthInfo();
            }
            else {
                await Promise.all([this.getMyKidsAttendDataMonthInfo(), this.getKidsAttendDataDayInfo()]);
            }

            await this.makeKidsStatisticsData();
        });
    }

    /**
     * 공기 상태 텍스트 반환
     */
    getAirStatusTextBylevel(level : string) : string {

        if( level == null || level.length < 1 ) return '연결되지않음';

        switch(level) {
            case"5": return "좋음";
            case"4": return "보통";
            case"3": return "나쁨";
            case"2": return "매우나쁨";
            default: return '연결되지않음';
        }
    }

    /**
     * 미세먼지 그래프 퍼센트 반환
     */
    getAirPm10WidthPercent() : number {

        if( this.air_data == null || this.air_data.pm10 == null ) {
            return 0;
        }

        const pm10 = this.air_data.pm10;

        // 임의 설정한 max 값이 250이므로 그 이상의 수치는 100%로 억지로 치환해서 보냄
        if( pm10 >= 250 ) {
            return 100;
        }

        // 좋음 최대 18%, 구간 수치 30
        if( pm10 <= 30 ) {
            return (pm10 / 30 * 100) * 0.18;
        }

        // 보통 최대 38%, 구간 수치 50
        if( pm10 <= 80 ) {
            return 18 + ((pm10 - 30) / 50 * 100) * 0.2;
        }

        // 나쁨 최대 61%, 구간 수치 70
        if( pm10 <= 150 ) {
            return 38 + ((pm10 - 80) / 70 * 100) * 0.23;
        }

        // 매우나쁨 최대 100% 구간 수치 100 (오른쪽 끝의 값을 250으로 임의로 설정한값임)
        return 61 + (pm10 - 150) / 100 * 100 * 0.39;
    }
    
    /**
     * 초미세먼지 그래프 퍼센트 반환
     */
    getAirPm2_5WidthPercent() : number {

        if( this.air_data == null || this.air_data.pm10 == null ) {
            return 0;
        }

        const pm2_5 = this.air_data.pm2_5;

        // 임의 설정한 max 값이 120이므로 그 이상의 수치는 100%로 억지로 치환해서 보냄
        if( pm2_5 >= 120 ) {
            return 100;
        }

        // 좋음 최대 18%, 구간 수치 15
        if( pm2_5 <= 15 ) {
            return (pm2_5 / 15 * 100) * 0.18;
        }

        // 보통 최대 38%, 구간 수치 20
        if( pm2_5 <= 35 ) {
            return 18 + ((pm2_5 - 15) / 20 * 100) * 0.2;
        }

        // 나쁨 최대 57%, 구간 수치 40
        if( pm2_5 <= 75 ) {
            return 38 + ((pm2_5 - 35) / 40 * 100) * 0.19;
        }

        // 매우나쁨 최대 100% 구간 수치 45 (오른쪽 끝의 값을 120으로 임의로 설정한값임)
        return 57 + (pm2_5 - 75) / 45 * 100 * 0.43;
    }

    /**
     * 화학물질 그래프 퍼센트 반환
     */
    getAirTvocWidthPercent() : number {

        if( this.air_data == null || this.air_data.pm10 == null ) {
            return 0;
        }

        const tvoc = this.air_data.tvoc;

        // 임의 설정한 max 값이 1000이므로 그 이상의 수치는 100%로 억지로 치환해서 보냄
        if( tvoc >= 1000 ) {
            return 100;
        }

        // 좋음 최대 20%, 구간 수치 150
        if( tvoc <= 150 ) {
            return (tvoc / 150 * 100) * 0.20;
        }

        // 보통 최대 43%, 구간 수치 250
        if( tvoc <= 400 ) {
            return 20 + ((tvoc - 150) / 250 * 100) * 0.23;
        }

        // 나쁨 최대 67%, 구간 수치 300
        if( tvoc <= 700 ) {
            return 40 + ((tvoc - 400) / 300 * 100) * 0.24;
        }

        // 매우나쁨 최대 100% 구간 수치 300 (오른쪽 끝의 값을 1000으로 임의로 설정한값임)
        return 67 + (tvoc - 700) / 1000 * 100 * 0.33;
    }

    /**
     * 이산화탄소 그래프 퍼센트 반환
     */
    getAirCo2WidthPercent() : number {

        if( this.air_data == null || this.air_data.pm10 == null ) {
            return 0;
        }

        const co2 = this.air_data.co2;

        // 임의 설정한 max 값이 2000이므로 그 이상의 수치는 100%로 억지로 치환해서 보냄
        if( co2 >= 2000 ) {
            return 100;
        }

        // 좋음 최대 20%, 구간 수치 450
        if( co2 <= 450 ) {
            return (co2 / 450 * 100) * 0.20;
        }

        // 보통 최대 43%, 구간 수치 450
        if( co2 <= 900 ) {
            return 20 + ((co2 - 450) / 450 * 100) * 0.23;
        }

        // 나쁨 최대 69%, 구간 수치 600
        if( co2 <= 1500 ) {
            return 43 + ((co2 - 900) / 600 * 100) * 0.26;
        }

        // 매우나쁨 최대 100% 구간 수치 500 (오른쪽 끝의 값을 2000으로 임의로 설정한값임)
        return 69 + (co2 - 1500) / 500 * 100 * 0.31;
    }

    /**
     * 원아 건강 종합 지수
     */
    getHealthLevelText(band_info) : string {
        if( band_info == null ) return "확인불가";
        
        switch(band_info.health_level) {
            case 1: return "좋음";
            case 2: return "나쁨";
            case 3: return "매우나쁨";
            default: return "확인불가";
        }
    }

    /**
     * 스트레스 지수
     */
    getStressLevelText(band_info) : string {
        if( band_info == null ) return "확인불가";

        switch(band_info.stress) {
            case 0: return "Good";
            case 1: return "Normal";
            case 2: return "Lite";
            case 3: return "High";
            default: return "확인불가";
        }
    }

    /**
     * 전자결재 활성화 여부
     */
    isApprovalEnabled() : boolean {
        return this.isEnableGroupFeature(this.group_id, 'approval');
    }

    /**
     * 근태관리 활성화 여부
     */
    isAttendanceEnabled() : boolean {
        return this.isEnableGroupFeature(this.group_id, 'attendance');
    }

    /**
     * 업무탭 활성화 여부
     */
    isWorkEnabled() : boolean {

        // 업무 기능 자체를 사용 안함
        if( this.isEnableGroupFeature(this.group_id, 'work') == false ) {
            return false;
        }
        
        // 업무 기능 세가지의 읽기권한이 전부 없다면
        if( this.isReportEnabled() == false && this.isMeetinglogEnabled() == false && this.isProjectEnabled() == false ) {
            return false;
        }

        return true;
    }

    /**
     * 업무일지 활성화 여부
     */
    isReportEnabled() : boolean {

        // 업무 기능 자체를 사용 안함
        if( this.isEnableGroupFeature(this.group_id, 'work') == false ) {
            return false;
        }
        
        // 그룹일때 업무일지 읽기 권한 없음
        if( this.team_id < 1 && this.is_group_permmision(this.group_id, 'report', 'read') == false ) {
            return false;
        }

        // 팀일때 업무일지 읽기 권한 없음
        if( this.team_id > 0 && this.is_team_permmision(this.team_id, 'report', 'read') == false ) {
            return false;
        }

        return true;
    }

    /**
     * 회의록 활성화 여부
     */
    isMeetinglogEnabled() : boolean {

        // 업무 기능 자체를 사용 안함
        if( this.isEnableGroupFeature(this.group_id, 'work') == false ) {
            return false;
        }
        
        // 그룹일때 회의록 읽기 권한 없음
        if( this.team_id < 1 && this.is_group_permmision(this.group_id, 'meetinglog', 'read') == false ) {
            return false;
        }

        // 팀일때 회의록 읽기 권한 없음
        if( this.team_id > 0 && this.is_team_permmision(this.team_id, 'meetinglog', 'read') == false ) {
            return false;
        }

        return true;
    }

    /**
     * 프로젝트 활성화 여부
     */
    isProjectEnabled() : boolean {
        
        // 업무 기능 자체를 사용 안함
        if( this.isEnableGroupFeature(this.group_id, 'work') == false ) {
            return false;
        }

        // 그룹일때 프로젝트 읽기 권한 없음
        if( this.team_id < 1 && this.is_group_permmision(this.group_id, 'work', 'read') == false ) {
            return false;
        }

        // 팀일때 프로젝트 읽기 권한 없음
        if( this.team_id > 0 && this.is_team_permmision(this.team_id, 'work', 'read') == false ) {
            return false;
        }

        return true;
    }

    /**
     * 일정 작성 활성화 여부
     */
    isEventCreateEnabled() : boolean {

        // 그룹일때 일정 작성 권한 없음
        if( this.team_id < 1 && this.is_group_permmision(this.group_id, 'event', 'create') == false ) {
            return false;
        }

        // 팀일때 일정 작성 권한 없음
        if( this.team_id > 0 && this.is_team_permmision(this.team_id, 'event', 'create') == false ) {
            return false;
        }
        
        return true;
    }

    /**
     * 메세지 활성화 여부
     */
    isMessageEnabled() : boolean {
        return this.isEnableGroupFeature(this.group_id, 'message');
    }

    /**
     * 호두키즈 여부
     */
    isHoduKids() : boolean {
        return this.data != null && this.data.group_info != null && this.data.group_info.project_type == 'kids';
    }

    /**
	 * 단어 오브젝트 생성
	 */
	makeWord() : any {

        let word = {
            "group" : "",
            "team" : "",
            "admin" : "",
            "group_manager" : "",
            "group_sub_manager" : "",
            "group_user" : "",
            "team_manager" : "",
            "team_user" : "",
            "member" : "",
            "group_manager_not_exist" : "",
            "group_sub_manager_not_exist" : "",
            "group_user_not_exist" : "",
            "team_manager_not_exist" : "",
            "team_user_not_exist" : ""
        };
        
		if( this.data == null || this.data.group_info == null ) {
            return word;
		}

        word = {
            "group" : "그룹",
            "team" : "팀",
            "admin" : "최종관리자",
            "group_manager" : "관리자",
            "group_sub_manager" : "부관리자",
            "group_user" : "멤버",
            "team_manager" : "팀장",
            "team_user" : "팀원",
            "member" : "멤버",
            "group_manager_not_exist" : "관리자가 존재하지 않습니다",
            "group_sub_manager_not_exist" : "부관리자가 존재하지 않습니다",
            "group_user_not_exist" : "멤버가 존재하지 않습니다",
            "team_manager_not_exist" : "팀장이 존재하지 않습니다",
            "team_user_not_exist" : "팀원이 존재하지 않습니다"
        };

        if( this.isHoduH(this.data.group_type) == true ) {
            word = {
                "group" : "아파트",
                "team" : "동",
                "admin" : "최종관리자",
                "group_manager" : "관리자",
                "group_sub_manager" : "보안실",
                "group_user" : "입주자",
                "team_manager" : "동장",
                "team_user" : "입주자",
                "member" : "입주자",
                "group_manager_not_exist" : "관리자가 존재하지 않습니다",
                "group_sub_manager_not_exist" : "보안실 근무자가 존재하지 않습니다",
                "group_user_not_exist" : "입주자가 존재하지 않습니다",
                "team_manager_not_exist" : "동장이 존재하지 않습니다",
                "team_user_not_exist" : "입주자가 존재하지 않습니다"
            };
        }

        else if( this.isHoduKids() == true ) {
            word = {
                "group" : "원",
                "team" : "반",
                "admin" : "최종관리자",
                "group_manager" : "선생님",
                "group_sub_manager" : "선생님",
                "group_user" : "원아",
                "team_manager" : "선생님",
                "team_user" : "원아",
                "member" : "원아",
                "group_manager_not_exist" : "선생님이 존재하지 않습니다",
                "group_sub_manager_not_exist" : "선생님이 존재하지 않습니다",
                "group_user_not_exist" : "원아가 존재하지 않습니다",
                "team_manager_not_exist" : "선생님이 존재하지 않습니다",
                "team_user_not_exist" : "원아가 존재하지 않습니다"
            };
        }

        return word;
	}

    notice_message = "";
    notice_positive_button_text = '예';
    notice_negative_button_text = '아니오';
    notice_single_button = false;

    notice_positive_func : Function = () => {};
    notice_negative_func : Function = () => {};

    /**
     * 알림 처리
     */
    async notificationClick(notification) : Promise<void> {

        try {

            const noti_uid : string = notification.noti_uid;

            let scope : string = notification.noti_info.scope && notification.noti_info.scope.length > 0 ? notification.noti_info.scope 
                                : (notification.team_id && notification.team_id > 0) ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP;

            let scope_id : number = notification.noti_info.scope_id && notification.noti_info.scope_id > 0 ? notification.noti_info.scope_id
                                    : (notification.team_id && notification.team_id > 0) ? notification.team_id : (notification.group_id ? notification.group_id : 0);

            const event_id : string = notification.noti_info.event_id;
            const calendar_id : string = notification.noti_info.calendar_id;

            this.notice_message = "";
            this.notice_positive_button_text = '예';
            this.notice_negative_button_text = '아니오';
            this.notice_single_button = false;

            this.notice_positive_func = () => {};
            this.notice_negative_func = () => {};

            const target : string = notification.team_id && notification.team_id > 0 ? '팀' : '그룹';

            switch( notification.noti_sub_type ) {
                /**
                 * 일정 개인 공유
                 */
                case "EVENT_SHARE":
                    this.notice_message = `${notification.noti_data.body}\n등록하시겠습니까?`;

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);
                        
                        // 공유 처리 + 이동
                        this.insertSharedEvent(notification);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };

                    break;

                case "EVENT_COPY":
                    this.notice_message = `${notification.noti_data.body}\n등록하시겠습니까?`;

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);
                        
                        // 복사 처리 + 이동
                        this.eventCopyApply(notification);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };
                    break;

                case "EVENT_MOVE":
                    this.notice_message = `${notification.noti_data.body}\n등록하시겠습니까?`;

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);
                        
                        // 이동 처리 + 이동
                        this.eventMoveApply(notification);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };
                    break;

                /**
                 * 그룹, 팀 일정 공유 && 공유된 일정 수정 && 그룹, 팀 일정 등록 또는 수정
                 */
                case "GROUP_EVENT_SHARE":
                case "GROUP_EVENT_PRIVATE_SHARE":
                case "EVENT_MODIFIED":
                case "GROUP_EVENT_CREATE":
                case "GROUP_EVENT_MODIFIED":
                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);

                        // 일정 조회 후 해당 일정으로 이동
                        this.getAndGoEvent(calendar_id, event_id, scope, scope_id);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };

                    break;

                /**
                 *공유된 일정, 그룹, 팀 일정 삭제
                 */
                case "EVENT_DELETED":
                case "GROUP_EVENT_DELETED":
                    this.notice_single_button = true;
                    this.notice_message = `${notification.noti_data.body}`;
                    this.notice_positive_button_text = '확인';

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);
                    };
                
                    this.notice_negative_func = () => {};
                    
                    break;

                /**
                 * 일정 댓글
                 */
                case "CREATE_COMMENT":
                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        this.insertReplyRead(noti_uid);

                        // 일정 조회 후 해당 일정으로 이동 + 댓글창 띄우기
                        await this.getAndGoEvent(calendar_id, event_id, scope, scope_id, "CREATE_COMMENT");
                    };
                    this.notice_negative_func = () => { 
                        this.insertReplyRead(noti_uid); 
                    };

                    break;

                /**
                 * 그룹 공지사항 댓글
                 */
                case "CREATE_NOTI_COMMENT":
                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        this.insertReplyRead(noti_uid);

                        let group_id = notification.group_id ? notification.group_id : 0;
                        let team_id = notification.team_id ? notification.team_id : 0;

                        this.getNotiGroupOrTeamInfo((team_id > 0 ? 'TEAM' : 'GROUP'), (team_id > 0 ? team_id : group_id), "CREATE_NOTI_COMMENT", notification.noti_info.event_id);

                        // this.doGroupId(notification.group_id);
                        // this.doTeamId(notification.team_id);

                        // this.doGroupTeamOption({
                        //     group_team_name : this.group_team_name,
                        //     group_team_color : this.group_team_color,
                        //     group_team_image : this.group_team_image,
                        //     group_team_descript : this.group_team_descript,
                        //     biz_id : this.biz_id,
                        //     noti_uid : notice.noti_uid
                        // });
                    };

                    this.notice_negative_func = () => {  
                        this.insertReplyRead(noti_uid);
                    };

                    break;

                /**
                 * 그룹, 팀 사용자 역할(권한) 변경
                 */
                case "GROUP_TEAM_USER_ROLR":
                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);

                        // 멤버 리스트로 이동
                        this.getNotiGroupOrTeamInfo(scope, scope_id, 'GROUP_TEAM_USER_ROLR');
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };
                    
                    break;

                /**
                 * 그룹, 팀 가입 승인 요청
                 */
                case "GROUP_TEAM_INFORM":
                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);

                        // 가입 승인 페이지로 이동
                        this.getNotiGroupOrTeamInfo(scope, scope_id, 'GROUP_TEAM_INFORM');

                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };
                    
                    break;

                /**
                 * 그룹, 팀 가입 승인
                 */
                case "GROUP_TEAM_APPROV":
                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);
                        
                        // 그룹, 팀 상세로 이동
                        this.getNotiGroupOrTeamInfo(scope, scope_id, 'GROUP_TEAM_APPROV');
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };
                    break;

                /**
                 * 그룹, 팀 초대
                 */
                case "GROUP_TEAM_INVITE":
                    this.notice_message = `${notification.noti_info.group_name} ${target}에 가입하시겠습니까?`;
                    this.notice_positive_button_text = '승인';
                    this.notice_negative_button_text = '거절';

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);

                        // 그룹, 팀에 가입처리 + 개인 달력의 group_detail로 이동!
                        if( scope == OWNER_TYPE.GROUP ) {
                            this.groupMemberInsert((notification.group_id ? notification.group_id : 0), false);
                        } 
                        
                        else if( scope == OWNER_TYPE.TEAM ) {
                            
                            // 가입된 그룹인지 체크 후 가입 안되어있으면 그룹 가입 후 팀 가입
                            Promise.all([this.get_group_role_service()]).then(() => {
                                let group_joined : boolean = false;
                                for( let group of this.all_group_data ) {
                                    if( group.group_id == notification.group_id ) {
                                        group_joined = true;
                                    }
                                }

                                // 가입 된 그룹이라면 바로 팀 가입
                                if( group_joined == true ) {
                                    this.teamMemberInsert((notification.group_id ? notification.group_id : 0), (notification.team_id ? notification.team_id : 0), false);
                                }

                                // 가입 안 된 그룹이라면 그룹 가입 후 팀 가입
                                else {
                                    Promise.all([this.groupMemberInsert((notification.group_id ? notification.group_id : 0), false)]).then(() => {
                                        this.teamMemberInsert((notification.group_id ? notification.group_id : 0), (notification.team_id ? notification.team_id : 0), false);
                                    })
                                }
                            })

                            
                        }

                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };

                    break;

                case "GROUP_BOARD_CREATE":
                case "GROUP_BOARD_MODIFIED":

                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }

                        this.hodu_router_push(`/group/${new Date().getTime()}/board`, async() => {
                            this.hodu_router_push(`/group/${new Date().getTime()}/board/${notification.noti_info.board_id}`);
                        });
                        this.insertRead(noti_uid);
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                    };

                    break;

                case "GROUP_BOARD_COMMENT":
                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.hodu_router_push(`/group/${new Date().getTime()}/board`, async() => {
                            this.hodu_router_push(`/group/${new Date().getTime()}/board/${notification.noti_info.board_id}`);
                        });
                        this.insertRead(noti_uid);

                        this.doSetGroupBoardReplyModalInfo?.({
                            "show_modal" : true,
                            "group_id" : notification.noti_info.group_id,
                            "board_id" : notification.noti_info.board_id,
                        })
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                    };
                break;

                /**
                 * 그룹 가입 거절 && 그룹 강제탈퇴 && 그룹, 팀 삭제
                 */
                case "GROUP_TEAM_REJECT":
                case "GROUP_TEAM_FORCED_WITHDRAWAL":
                case "GROUP_DELETED":
                case "TEAM_DELETED":
                case "GROUP_BOARD_DELETED":
                    this.notice_single_button = true;
                    this.notice_message = `${notification.noti_data.body}`;
                    this.notice_positive_button_text = '확인';

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);
                    };
                
                    this.notice_negative_func = () => {}; 
                    break;

                /**
                 * 예약 내용 수정, 상태 변경
                 */
                case "APPOINTMENT_CONTENT_CHANGE":
                case "APPOINTMENT_STATUS_CHANGE":
                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = () => {
                        this.insertRead(noti_uid);

                        // 일정 조회 후 해당 일정으로 이동
                        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.GET)
                            .then(async(response) => {
                                console.log(response);
                                const event : t_event = response.data.data.event_data;
                                
                                if( this.doSetAppointmentDetailInfo == null ) { return; } 

                                // 개인 달력으로 이동
                                if( this.scope != OWNER_TYPE.PERSONAL ) {
                                    this.doSetGroupMenuClose(false);
                                    this.doSetCalendarId(`personal-${this.user_id}`);
                                    this.doSetScope(OWNER_TYPE.PERSONAL);  
                                    this.doSetScopeGroupId(0);  
                                    this.doSetScopeTeamId(0);  
                                    this.doSetScopeGroupTeamOption({
                                        group_team_name : '',
                                        group_team_color : '#FFFFFF',
                                        group_team_image : '',
                                        group_team_descript : '',
                                        biz_id : '',
                                        biz_type : ''
                                    });

                                    await this.hodu_loading_timer_exit();
                                    await this.hodu_loading();

                                    if( window['left_inbox02_mCustomScrollbar'] != null ) {
                                        setTimeout(() => {
                                        
                                            // @ts-ignore
                                            $('.left_inbox02').mCustomScrollbar('destroy');

                                            window['left_inbox02_mCustomScrollbar']();

                                        }, 100);
                                    }
                                    if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
                                }

                                this.doSetAppointmentDetailInfo({
                                    event : event,
                                    is_patient : true,
                                });
                                
                                this.hodu_router_push(`/hospital/${new Date()}/appointment/${notification.noti_info.event_id}`);
                            })
                            .catch((e) => {
                                this.hodu_error_process(e, true, false);
                            });

                    };

                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };
                    break;

                /**
                 * 병원 파일함에 파일 추가
                 */
                case "HOSPITAL_ADD_FILE":

                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        this.insertRead(noti_uid);

                        // 개인 달력으로 이동
                        if( this.scope != OWNER_TYPE.PERSONAL ) {
                            this.doSetGroupMenuClose(false);
                            this.doSetCalendarId(`personal-${this.user_id}`);
                            this.doSetScope(OWNER_TYPE.PERSONAL);  
                            this.doSetScopeGroupId(0);  
                            this.doSetScopeTeamId(0);  
                            this.doSetScopeGroupTeamOption({
                                group_team_name : '',
                                group_team_color : '#FFFFFF',
                                group_team_image : '',
                                group_team_descript : '',
                                biz_id : '',
                                biz_type : ''
                            });

                            await this.hodu_loading_timer_exit();
                            await this.hodu_loading();

                            if( window['left_inbox02_mCustomScrollbar'] != null ) {
                                setTimeout(() => {
                                
                                    // @ts-ignore
                                    $('.left_inbox02').mCustomScrollbar('destroy');

                                    window['left_inbox02_mCustomScrollbar']();

                                }, 100);
                            }
                            if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
                        }

                        this.hodu_router_push(`/hospital/file/${notification.noti_info.biz_id}`);
                    };

                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid); 
                    };
                    break;

                /**
                 * 전자결재 기안서 생성
                 */
                case "APPROVAL_CREATE":

                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 기안서 수정
                 */
                case "APPROVAL_MODIFY":

                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 기안서 수정 (제외됨)
                 */
                case "APPROVAL_MODIFY_EXCEPT":

                    this.notice_message = `${notification.noti_data.body}\n전자결재로 이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        await this.goGroupCalendar(notification.noti_info);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`);
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 기안서 삭제(취소)
                 */
                case "APPROVAL_DELETE":

                    if( notification.noti_info.scope != null && notification.noti_info.scope.length > 0 ) {
                        this.notice_single_button = true;
                        this.notice_message = `${notification.noti_data.body}`;
                        this.notice_positive_button_text = '확인';

                        this.notice_positive_func = () => {
                            this.insertRead(noti_uid);
                        };
                    
                        this.notice_negative_func = () => {};
                    }

                    else {
                        this.notice_message = `${notification.noti_data.body}\n전자결재로 이동하시겠습니까?`;

                        this.notice_positive_func = async() => {
                            await this.goGroupCalendar(notification.noti_info);
                            this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                            this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`);
                            this.insertRead(noti_uid);
                        }

                        this.notice_negative_func = () => {
                            this.insertRead(noti_uid);
                        };
                    }
                    
                    
                    break;

                /**
                 * 전자결재 기안서 결재 순서
                 */
                case "APPROVAL_ORDER":

                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        await this.goGroupCalendar(notification.noti_info);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.insertRead(noti_uid);
                    };
                    break;
                    
                /**
                 * 전자결재 기안서 반려
                 */
                case "APPROVAL_REJECT":

                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.DRAFT);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedDraftTab", approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 기안서 최종 승인
                 */
                case "APPROVAL_COMPLETE":

                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.DRAFT);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedDraftTab", approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);
                    }

                    this.notice_negative_func = () => {
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 전자결재 휴가원, 출장신청서 최종승인으로 인한 일정 생성
                 */
                case "APPROVAL_EVENT_CREATE":

                    if( (notification.noti_info.permitted_user_ids as number[]).indexOf(this.user_id) > -1 ) {

                        this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                        this.notice_positive_func = async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                            this.insertRead(noti_uid);
                        }

                        this.notice_negative_func = () => {
                            this.insertRead(noti_uid);
                        };

                    }

                    else {
                        this.notice_single_button = true;
                        this.notice_message = `${notification.noti_data.body}`;
                        this.notice_positive_button_text = '확인';

                        this.notice_positive_func = () => {
                            this.insertRead(noti_uid);
                        };
                    
                        this.notice_negative_func = () => {};
                    }

                    break;

                /**
                 * 전자결재 댓글
                 */
                case "APPROVAL_COMMENT":

                    this.notice_message = `${notification.noti_data.body}\n이동하시겠습니까?`;

                    this.notice_positive_func = async() => {
                        if( this.scope != OWNER_TYPE.GROUP || this.scope_group_id != notification.noti_info.group_id ) {
                            await this.goGroupCalendar(notification.noti_info);
                        }
                        this.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.DRAFT);
                        this.$store.dispatch("ApprovalInfo/doSetSelectedDraftTab", approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
                        this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval?${new Date().getTime()}`, async() => {
                            this.hodu_router_push(`/GROUP/${notification.noti_info.group_id}/approval/${notification.noti_info.approval_uid}`);
                        });
                        this.insertRead(noti_uid);

                        this.doSetApprovalReplyModalInfo?.({
                            "show_modal" : true,
                            "group_id" : notification.noti_info.group_id,
                            "approval_uid" : notification.noti_info.approval_uid,
                        })
                    };
                    this.notice_negative_func = () => { 
                        this.insertRead(noti_uid);
                    };
                    break;

                /**
                 * 일정 이동 완료, 예약 삭제, 지정되지 않은 타입
                 */
                case "EVENT_MOVE_COMPLETE":
                case "APPOINTMENT_DELETE":
                default:
                    this.notice_single_button = true;
                    this.notice_message = `${notification.noti_data.body}`;
                    this.notice_positive_button_text = '확인';

                    this.notice_positive_func = () => {
                        if( notification.noti_sub_type.includes("_COMMENT") ) {
                            this.insertReplyRead(noti_uid);
                        }
                        else {
                            this.insertRead(noti_uid);
                        }
                    };
                
                    this.notice_negative_func = () => {};   
                    break;
            }

            let dialog_button : string[] = [];
            let dialog_function : Function[] = [];

            if( this.notice_single_button == true ) {
                dialog_button.push(this.notice_positive_button_text);
                dialog_function.push(this.notice_positive_func);
            }
            else {
                dialog_button.push(this.notice_negative_button_text);
                dialog_button.push(this.notice_positive_button_text);
                dialog_function.push(this.notice_negative_func);
                dialog_function.push(this.notice_positive_func);
            }

            this.hodu_show_dialog('alert', this.notice_message, dialog_button, dialog_function)

        } catch(e) {
            this.hodu_show_dialog('cancel', "알림 처리 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 알림 읽기 처리
     */
    insertRead(noti_uid : string) : void {
        this.hodu_api_call(`api/v1/notification/insertRead/${noti_uid}`, API_METHOD.PUT)
            .then((response) => {
                console.log(response);
                this.getGroupNotification();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 댓글 알림 읽기 처리
     */
    insertReplyRead(noti_uid : string) : void {
        this.hodu_api_call(`api/v1/notification/insertRead/${noti_uid}`, API_METHOD.PUT)
            .then((response) => {
                console.log(response);
                this.getGroupReplyNotification();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 공유 수락
     */
    insertSharedEvent(notification : t_notification) : void {

        const event_id : string = notification.noti_info.event_id;
        const scope    : string = notification.noti_info.scope;
        const scope_id : number = notification.noti_info.scope_id;

        this.hodu_api_call(`api/v1/calendars/${scope.toLowerCase()}-${scope_id}/events/${scope}/${scope_id}/${event_id}/share`, API_METHOD.PUT)
            .then(async(response) => {
                console.log(response);
                const event : t_event = response.data.data.event_data;
                this.goEvent(event);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 일정 복사 요청 수락
     */
    eventCopyApply(notification : t_notification) : void {

        const event_id : string = notification.noti_info.event_id;
        const scope    : string = notification.noti_info.scope;
        const scope_id : number = notification.noti_info.scope_id;

        this.hodu_api_call(`api/v1/calendars/${scope.toLowerCase()}-${scope_id}/events/${scope}/${scope_id}/${event_id}/share/copy`, API_METHOD.PUT)
            .then(async(response) => {
                console.log(response);
                const event : t_event = response.data.data.event_data;
                this.goEvent(event);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 일정 이동 요청 수락
     */
    eventMoveApply(notification : t_notification) : void {

        const event_id : string = notification.noti_info.event_id;
        const scope    : string = notification.noti_info.scope;
        const scope_id : number = notification.noti_info.scope_id;

        this.hodu_api_call(`api/v1/calendars/${scope.toLowerCase()}-${scope_id}/events/${scope}/${scope_id}/${event_id}/share/move`, API_METHOD.PUT)
            .then(async(response) => {
                console.log(response);
                const event : t_event = response.data.data.event_data;
                this.goEvent(event);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 일정으로 이동
     */
    async goEvent(event : t_event, noti_type : string = "") : Promise<void> {
        // EventInfo에 이벤트 등록
        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.READ);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 반복일정이라면 첫번째 반복을 보는 취급한다
        if( event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.rrule.length > 0 ) {
            this.doSetIsFirstRepeatEvent(true);

            // 음력 반복 일정 일때는 일정의 원래 음력 날짜 store EventInfo에 등록
            if( event.event_data.schedule_date.lunar_yn == true ) {
                this.doSetEventOriginalDate({
                    original_start : await this.hodu_solar_to_lunar(new Date(moment(event.event_data.schedule_date.start).format('YYYY-MM-DD HH:mm:ss'))),
                    original_end : await this.hodu_solar_to_lunar(new Date(moment(event.event_data.schedule_date.end).format('YYYY-MM-DD HH:mm:ss'))),
                });
            }

            // 그냥 반복 일정 일때는 일정의 원래 날짜 store EventInfo에 등록
            else {
                
                this.doSetEventOriginalDate({
                    original_start : new Date(moment(event.event_data.schedule_date.start).format()),
                    original_end : new Date(moment(event.event_data.schedule_date.end).format())
                })
            }
        }

        this.hodu_router_push(`/event/${event.event_id}`);

        switch( noti_type ) {
            case "CREATE_COMMENT":
                this.doSetShowEventReply(true);
                break;
        }
    }

    /**
     * 일정 조회 후 이동
     */
    getAndGoEvent(calendar_id : string, event_id : string, scope : string, scope_id : number, noti_type : string = "") : void {
        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                const event : t_event = response.data.data.event_data;
                this.goEvent(event, noti_type);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * scope, scope_id로 그룹 또는 팀 정보 가져오기
     */
    getNotiGroupOrTeamInfo(scope : string, scope_id : number, noti_type : string = "", noti_uid : string = "") : void {
        this.hodu_api_call(`api/v1/groupsweb/notification/${scope}/${scope_id}/info`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                const group_or_team_info : any = response.data.data.group_or_team_info;

                this.doGroupId(group_or_team_info.group_id);
                this.doTeamId(group_or_team_info.team_id);
                this.doGroupTeamOption({
                    group_team_name : group_or_team_info.group_team_name,
                    group_team_color : group_or_team_info.group_team_color,
                    group_team_image : group_or_team_info.group_team_image,
                    group_team_descript : group_or_team_info.group_team_descript,
                    biz_id : group_or_team_info.biz_id,            
                    noti_uid : noti_uid ? noti_uid : ""
                });

                switch( noti_type ) {
                    case "GROUP_TEAM_USER_ROLR":

                        // 전자결재 또는 근태현황 중 하나라도 사용시
                        if( (this.isEnableGroupFeature(this.group_id, 'attendance') || this.isEnableGroupFeature(this.team_id, 'approval')) ) {
                            await this.groupMainPageGo('/');
                            this.doSetOrganizationMemberSelectedTab?.(organization_enum.ORGANIZATION_SETTING_TAB.MEMBER);
                            this.hodu_router_push(`/${this.team_id > 0 ? 'TEAM' : 'GROUP'}/${this.team_id > 0 ? this.team_id : this.group_id}/organization/member/setting?${new Date().getTime()}`);
                            return;
                        }

                        this.hodu_router_push(`/group/${new Date().getTime()}/member`);
                        break;

                    case "GROUP_TEAM_INFORM":
                        this.hodu_router_push(`/group/${new Date().getTime()}/approve`);
                        break;

                    case "GROUP_TEAM_APPROV":
                        this.doGroupStatisticsStartDate("");
                        this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
                        break;

                    case "GROUP_NOTICE":
                        // 개인 달력이 아니라면 개인 달력으로 이동
                        if( this.scope != OWNER_TYPE.PERSONAL ) { this.goPersonalCalendar(); }
                        this.hodu_router_push(`/group/${new Date().getTime()}/notice`);
                        break;

                    case "CREATE_NOTI_COMMENT":
                        this.hodu_router_push(`/group/${new Date().getTime()}/notice_detail`);
                        this.doSetGroupNoticeReplyModalInfo?.({
                            show_modal : true,
                            noti_uid : noti_uid
                        });
                        break;
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 그룹 가입
     */
    groupMemberInsert(group_id : number, is_appr_required : boolean) : void {
        
        this.hodu_api_call(`api/v1/groups/groupMemberInsert/${group_id}`, API_METHOD.POST, {
            "is_appr_required" : is_appr_required
        }).then(async(response) => {
            console.log(response);

            // 개인 달력이 아니라면 개인 달력으로 이동
            if( this.scope != OWNER_TYPE.PERSONAL ) { this.goPersonalCalendar(); }

            this.doGroupId(group_id);
            this.doTeamId(0);
            this.doGroupStatisticsStartDate("");
            this.hodu_router_push(`/group/${new Date().getTime()}/detail`);

        }).catch((e) => {
            this.hodu_error_process(e, true, false);
        });

    }

    /**
     * 팀 가입
     */
    teamMemberInsert(group_id : number, team_id : number, is_appr_required : boolean) : void {
        
        this.hodu_api_call(`api/v1/teams/teamMemberInsert/${group_id}/${team_id}`, API_METHOD.POST, {
            "is_appr_required" : is_appr_required
        }).then(async(response) => {
            console.log(response);

            // 개인 달력이 아니라면 개인 달력으로 이동
            if( this.scope != OWNER_TYPE.PERSONAL ) { this.goPersonalCalendar(); }

            this.doGroupId(group_id);
            this.doTeamId(team_id);
            this.doGroupStatisticsStartDate("");
            this.hodu_router_push(`/group/${new Date().getTime()}/detail`);

        }).catch((e) => {
            this.hodu_error_process(e, true, false);
        });

    }

    /**
     * 개인 달력으로 이동
     */
    async goPersonalCalendar() : Promise<void> {
        this.doSetGroupMenuClose(false);
        this.doSetCalendarId(`personal-${this.user_id}`);
        this.doSetScope(OWNER_TYPE.PERSONAL);  
        this.doSetScopeGroupId(0);  
        this.doSetScopeTeamId(0);  
        this.doSetScopeGroupTeamOption({
            group_team_name : '',
            group_team_color : '#FFFFFF',
            group_team_image : '',
            group_team_descript : '',
            biz_id : '',
            biz_type : ''
        });

        await this.hodu_loading_timer_exit();
        await this.hodu_loading();

        setTimeout(() => {
            // @ts-ignore
            $('.left_inbox02').mCustomScrollbar('destroy');
            window['left_inbox02_mCustomScrollbar']();
        }, 100);

        if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
    }

    /**
     * 알림 스크롤 리스너 - 페이징 처리
     */
    @Throttle(500)
    async whileScrolling() : Promise<void> {
        
        // 페이징 끝났다면 빠져나감
        if( this.notification_page_end == true ) {
            return;
        }

        try {
            const noti_ul_scroll_height : number | undefined = $('#dashboard_notification_scroll').height();
            const container_height      : number | undefined = $('#dashboard_notification_scroll .mCSB_container').height();
            let container_top : number = 0;
            let container_top_string : string = "";

            try {
                container_top_string = $('#dashboard_notification_scroll .mCSB_container').css('top')

                if(container_top_string) {
                    container_top_string = container_top_string.replace('px', '');
                    container_top = Math.abs(Number(container_top_string));
                } else {
                    container_top = 0;
                }
            } catch(e) {
                container_top = 0;
            }
        
            if( (container_height ? container_height : 0) - (noti_ul_scroll_height ? noti_ul_scroll_height : 0) - container_top <= 250 ) {
                this.getGroupNotification(this.last_noti_uid);
            }

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
        
    }

    /**
     * 댓글 알림 스크롤 리스너 - 페이징 처리
     */
    @Throttle(500)
    async whileReplyScrolling() : Promise<void> {
        
        // 페이징 끝났다면 빠져나감
        if( this.reply_notification_page_end == true ) {
            return;
        }

        try {
            const noti_ul_scroll_height : number | undefined = $('#dashboard_reply_notification_scroll').height();
            const container_height      : number | undefined = $('#dashboard_reply_notification_scroll .mCSB_container').height();
            let container_top : number = 0;
            let container_top_string : string = "";

            try {
                container_top_string = $('#dashboard_reply_notification_scroll .mCSB_container').css('top')

                if(container_top_string) {
                    container_top_string = container_top_string.replace('px', '');
                    container_top = Math.abs(Number(container_top_string));
                } else {
                    container_top = 0;
                }
            } catch(e) {
                container_top = 0;
            }
        
            if( (container_height ? container_height : 0) - (noti_ul_scroll_height ? noti_ul_scroll_height : 0) - container_top <= 250 ) {
                this.getGroupReplyNotification(this.last_reply_noti_uid);
            }

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
        
    }

    groupTeamListOnOff() : void {
        this.group_team_list_open = !this.group_team_list_open;
    }

    moveGroupBoardDetail(post) : void {
        this.hodu_router_push(`/group/${new Date().getTime()}/board/${post.board_id}`);
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/im_photoB.gif');
        if( jquery_taget.parent().find('p.facePic').length > 0 ) jquery_taget.parent().find('p.facePic').css('background-image', `url(${replace_image})`);
        if( jquery_taget.parent().find('p.memPic').length > 0 ) jquery_taget.parent().find('p.memPic').css('background-image', `url(${replace_image})`);
    }

    /**
     * 그룹 이미지 에러
     */
    groupImageError(event) : void {
        this.group_team_image = "";
        this.$forceUpdate();
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        this.$nextTick(() => {
            // @ts-ignore
            $('#grpScroll').mCustomScrollbar('destroy');

            // @ts-ignore
            // $(".content").mCustomScrollbar('destroy');

            // @ts-ignore
            $('#group_detail_today_event_scroll').mCustomScrollbar('destroy');

            // @ts-ignore
            $('#dashboard_message_scroll').mCustomScrollbar('destroy');

            // @ts-ignore
            $('#dashboard_notification_scroll').mCustomScrollbar('destroy');

            // @ts-ignore
            $('#dashboard_reply_notification_scroll').mCustomScrollbar('destroy');

            this.event_bus.$emit('handleResizeGroupTeamList');
            
            this.setScroll();
        });
        
    }


}
</script>

<style scoped>
    .grpDetailContent .grp1 .premiumTag  { display:none; font-size:0; position: absolute; top:45px;left:55px; z-index:1000; width:108px; height:108px; background: url('../assets/images/contents/ic_c_tag.png') no-repeat left top; }
	.grpDetailContent .grp1.hcList .premiumTag { display:block;  background-image: url('../assets/images/contents/ic_c_tag.png'); }
    .grpDetailContent .grp1.hdList .premiumTag { display:block; background-image: url('../assets/images/contents/ic_d_tag.png'); }
    
    #grpTeamUlDiv.grpTeam_clr_ul.on { display:block; }
    #grpTeamUlDiv.grpTeam_clr_ul { background:#fff; z-index:1000; display:none; position: absolute;top: 214;height: 126px;left: 0;box-shadow: 0 5px 10px rgba(0,0,0,0.05)}
    #grpTeamUlDiv.grpTeam_clr_ul li  { margin:0 5px; display: inline-block !important; opacity:1 !important;   width:30px; height:30px;position: static !important; }
    #grpTeamUlDiv.grpTeam_clr_ul:hover li a {position: static !important; opacity:1 !important; display:block !important; } 
    #grpTeamUlDiv.grpTeam_clr_ul li:hover a {position: static !important;  opacity:1 !important; display:block !important; }
    .grpPage .hc .first .grp1  #grpTeamUlDiv.grpTeam_clr_ul a { opacity:1 !important;margin-left:0; }
    #grpTeamUlDiv.grpTeam_clr_ul li a { transition:0.1s; cursor: pointer;width: 25px;height: 25px;font-size:0;box-sizing: border-box; display: inline-block !important;position:static !important; border-radius: 50%;margin-top: 13px;margin-right: 13px;  }
    #grpTeamUlDiv.grpTeam_clr_ul li a:hover { transform: scale(1.12) }
    #grpTeamUlDiv.grpTeam_clr_ul li a.on {background-image: url(../assets/images/contents/checked_fff.png); transform:scale(1.15);background-image: url(../assets/images/contents/checked_fff.png); background-position:center center;  }
    #grpTeamUlDiv.grpTeam_clr_ul  { border-top:1px solid #f1f3f5; overflow:hidden; width:100%; text-align:center; padding: 10px 25px 20px;box-sizing: border-box; }
    #grpTeamUlDiv.grpTeam_clr_ul .setting_txt { line-height: 55px; height:55px; padding: 0 20px;font-size:14px; font-weight:bold;}
    #grpTeamUlDiv.grpTeam_clr_ul .setting_clr {position:absolute;top:16px; right:23px; width:23px; height:23px;border-radius:50%; font-size: 0; transition:0.1s;}
    #grpTeamUlDiv.grpTeam_clr_ul .setting_clr:hover { transform:scale(1.15) }
    .userDets { position: relative; flex: 1 1 40%; padding: 15px 0px; padding-left: 70px; padding-right: 25px; }

    /* 22.03 수정 css */
    .grpPage .grpDetailTitle .cl { display: inline-block; transition: .2s; width: 15px; height: 15px; border-radius: 50%; font-size: 0; margin: 23px 0 0 10px; cursor: pointer; }
    .grpDetailTitle #grpTeamUlDiv.grpTeam_clr_ul { width: 50%; top: 0; left: auto; right: 0px; height: 62px; box-shadow: none; margin-top: -2px; }
    .grpDetailTitle #grpTeamUlDiv.grpTeam_clr_ul li a { margin-top: 7px;}

    .grpPage .first { height: auto; min-height: 440px; background: #eef0f5; padding: 15px 20px; }
    .grpPage .first .firstCon { width: 100%; background: #fff; border-radius: 10px; box-sizing: border-box; }
    .grpPage .first .grp3_under { padding: 15px 25px; }
    .grpPage .first .first_top  { min-height: 130px; /* max-height: 380px; */ margin-bottom: 20px; overflow: hidden; display: flex; flex-wrap: wrap; }
    .grp3_under .grp3_box { height: 215px; border-top: none; }
    .grpPage .first .grp1 { width: 130px; height: 90px; position: static; padding: 0px; float: none; border: none; vertical-align: middle; margin-top: 19px; margin-left: 25px; }
    .grpPage .first .img { position: static; width: 130px; height: 90px; }
    .userDets li { width: 30%; border-right: 1px solid #e7e9ea; height: 100px; padding-top: 10px; }
    .userDets li:first-child { width: 27%; }
    .userDets li:last-child { width: 43%; border-right: 0px; }
    .userDets .facePic { top: 25px; left: 20px; }
    .grpPage .hc .first .userList a { display: none; opacity: 0; position: absolute; bottom: 0; left: 0; width: 170px; height: 45px; line-height: 45px; font-weight: 700; font-size: 14px; color: #fff; background: #477fff; border-radius: 40px; transition: .2s; text-align: center; }
    .grpPage .hc .first .userList a.hoduC,
    .grpPage .hc .first .userList a.hoduC_btn { display: block; margin-left: 20px; width: 115px; height: 25px; line-height: 23px; bottom: 20px !important; z-index: 5!important; }
    .userDets li .title1 { padding-right: 10px; }
    .userDets li .menTseeBtn { width: 80%; max-width: 250px; margin: 0 auto; }
    .userDets li .menTseeBtn a { padding: 4px 15px; display: block; border: 1px solid #e7e9ea; border-radius: 5px; text-align: center; margin-bottom: 15px; }
    .userDets li .memT_last { float: none; padding-left: 25px; }
    .userDets li .memT_last label.groupM_radio { margin-right: 15px; }
    .userDets li .memT_last label.calen_radio { margin-right: 0px; }
    .userDets .noticeList .title { font-size: 14px; font-weight: 700; line-height: 25px; text-indent: 35px; overflow: visible; cursor: pointer; position: relative; height: 30px; margin-left: 30px; }
    .userDets .noticeList .title span { width: 25px; height: 25px; border-radius: 50%; background: #477fff; display: block; vertical-align: top; margin-right: 10px; position: absolute; top: 0; left: 0; font-size: 0; }
    .userDets .noticeList .title span:before { content: ""; width: 15px; height: 15px; display: inline-block; background-image: url(../assets/images/menucons/ic_grp3_notice_white.svg); background-repeat: no-repeat; background-position: 50%; margin-right: 15px; position: absolute; top: 5px; left: 5px; background-size: cover; }
    .firstCon .noticeList .noone { background: #f1f3f5; border-radius: 5px; height: 50%; line-height: 45px; text-align: center; font-weight: 700; color: #98aec3; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin: 16px 30px; padding: 0 5px; }
    .userDets ul.grp3L_con02 { padding-left: 30px; }
    .userDets li.grp3_con { width: 100% !important; display: block; height: auto; line-height: 25px; padding-top: 0; border-right: 0px; font-weight: bold; overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap; }
    .userDets li.seeOpacity { opacity: .5; }
    .userDets .grp3L_con02 p.grp3_con_title { height: 30px; line-height: 30px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .userDets .grp3L_con02 p.grp3_con_title a { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: 70%; display: inline-block; box-sizing: border-box; }
    .userDets li.grp3_con span.time { float: right; width: 30%; text-align: right; cursor: pointer; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    /* 그룹, 팀 */
    .grpPage .first .first_top .second { border-top: 1px solid #e7e9ea; margin-bottom: 50px; width: 100%; margin-top: 20px; display: none; }
    .grpPage .first .first_top.on .second { display: block; }
    .grpPage .first .first_top.on .userDets li .menTseeBtn a { border-color: #477fff; background: #477fff; color: #fff; }

    .grp3_under .grp3_list ul li:first-child select { width: 115px; }
    .grp3_under .grp3_list .title.grp_T01 { margin-left: 15px; }
    .grp3_under ul li.grp3_list .grp3_list_con.grp3L_con01 { padding-left: 15px; }
    .grp3_under .grp3_list .title.grp_T02_new span:before { background-image: url(../assets/images/menucons/ic_grp3_cmmnt_white.svg); }

    .grp2 .bigger { transition:0.2s;display:none;position: absolute;width: 30px !important;left: 0;padding: 0;height: 40px;border-radius: 20px 0 0 20px;background: #fff url(../assets/images/contents/month_bt_prev.svg) no-repeat center center;background-size: 22px !important;font-size: 0;border: 1px solid #e7e9ea;margin-top: -20px;top: 50%;border-right: 0 none;margin-left: 415px;z-index: 10000000; }
    .tabDiv { padding: 0 20px; }
    .tabDivUl { height: 290px; }
    .tabDivUl > li { border-bottom: 1px solid #e7e9ea; height: 290px; }
    .tabDivUl li > div { padding: 17px 30px; }                                                                         
    .tabDivUl li.tabAtndnceList > div { padding-top: 30px; }
    .tabAllMenusList .allMenusUl li { height: 145px; }
    .tabAllMenusList .allMenusUl li a { background-position: center 47px; padding-top: 90px; }
    .tabAtndnceList .atn_right .graph { width: 20%; min-width: 120px; }
    .tabAtndnceList .atn_right .graph_wrap { width: 80%; }
    .tabAtndnceList .atn_right .graph_wrap > div { width: 100%; min-width: 300px; margin-left: 0px; padding: 0 10px 0 20px; }
    .tabDivUl li > div.usage { padding: 0; height: 290px; box-sizing: border-box; background: #fff; position: relative; }
    .grp2 .usage h5 { height: 40px; padding: 25px 30px 0; font-size: 16px; }
    .grp2 .usage h5 input { line-height: 20px!important; height: 20px; font-size: 16px; }

    /* 23.04.11 대시보드 게시판 추가 tab */
    .dashTabNav { display: flex; gap: 10px; border-bottom: 2px solid #e7e9ea; box-sizing: border-box; }
    .userDets li:last-child { padding-left: 10px; }
    .userDets .noticeList .title { text-indent: 0px !important; margin-left: 0px !important; }
    .userDets .noticeList .title.on { border-bottom: 2px solid #477fff; margin-bottom: -2px; white-space: nowrap; }
    .dashTabNav p a { display: block; padding: 0 10px 8px 10px; font-weight: normal; }
    .dashTabNav p.on a { font-weight: bold; }
    .userDets ul.grp3L_con02 { padding-left: 10px !important; display: none; }
    .userDets ul.grp3L_con02.on { display: block; }

</style>


