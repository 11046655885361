<template>
    <div id="organization_vacation_add_modal" class="modal">
        <div class="titleBox">
            <h1>휴가 등록</h1>
        </div>
        <div class="content" v-if="approval != null"> 
            <div class="detail">
                
                <HoduInlineDatePicker
                    :event_bus="event_bus" 
                    :dp_id="'organization_vacation_add_datepicker'" 
                    :dp_class="'datepickerDiv'"
                    :other_month_selectable="true"
                    :default_date="new Date()"
                    @select="select"
                    @changeMonthYear="change"
                />

                <div class="context">

                    <div class="timeDiv">

                        <div class="startDiv">
                            <input type="button" :class="{ on : is_start_select == true }" :value="`${start_text} 부터`" @click.prevent="set(true)">
                        </div>

                        <div class="endDiv">
                            <input type="button" :class="{ on : is_start_select == false }" :value="`${end_text} 까지`" @click.prevent="set(false)">
                        </div>

                    </div>

                    <div class="detailDiv">

                        <div class="selDiv left">
                            <label>승인자</label>
                            <select v-model="selected_approver_id">
                                <option :key="employee.user_id" :value="employee.user_id" v-for="employee in computedEmployee">{{ `${employee.user_name} (${getPositionName(employee.pos_id)})` }}</option>
                            </select>
                        </div>

                        <div class="selDiv">
                            <label>타입</label>
                            <select v-model="selected_vacation_type_id">
                                <option :key="vacation_type.vacation_type_id" :value="vacation_type.vacation_type_id" v-for="vacation_type in vacation_types">{{ `${vacation_type.vacation_type_name} (${Number(vacation_type.daycount)}일)` }}</option>
                            </select>
                        </div>
                        
                        <label>사유</label>
                        <textarea placeholder="간단히 휴가 사유를 작성하세요" :value="approval.contents.comment" @input="inputComment($event)"></textarea>

                    </div>
                </div>
            </div>

            <div class="btns">
                <input type="button" value="취소" @click.prevent="close"/>
                <input type="button" class="save" value="저장" @click.prevent="save"/>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';

import { approval_enum, approval_interface } from '@/model/approval';
import { organization_modal_interface } from '@/model/organization';

import moment from 'moment';

// TODO 사용하면 vacation_selected 대응 해야함
@Component({
    components: {
        HoduInlineDatePicker
    },
})
export default class OrganizationVacationAddModal extends Mixins(VueHoduCommon) {

    /**
     * 승인자 대상 직원
     */
    get computedEmployee() : any[] {
        
        const target = this.employees.filter(item => item.user_id != this.user_id && item.dept_id != null && item.pos_id != null);

        target.sort((o1, o2) : number => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            if( o1_pos_seq > o2_pos_seq ) return 1;
            else if( o1_pos_seq < o2_pos_seq ) return -1;
            else if( o1_name > o2_name ) return 1;
            else if( o1_name < o2_name ) return -1;

            return 0;
        });

        return target;
    }

    /**
     * 선택된 휴가 타입
     */
    get computedSelectedVacationType() : any {

        if( this.approval == null || this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ||
            this.vacation_types == null || this.vacation_types.length < 1 ) {
            return null;
        }

        return this.vacation_types.filter(item => item.vacation_type_id == this.selected_vacation_type_id)[0];
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_vacation_add_modal_info !: organization_modal_interface.OrganizationVacationAddModlInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationAddModlInfo ?: (params : organization_modal_interface.OrganizationVacationAddModlInfo) => void;

    event_bus : Vue = new Vue();
    
    approval : approval_interface.t_approval | null = null;

    departments : any[] = [];
    position : any[] = [];
    employees : any[] = [];
    vacation_types : any[] = [];

    my_employee_info : any = null;
    my_position_info : any = null;
    my_department_info : any = null;

    selected_approver_id : string = "";
    selected_vacation_type_id : string = "";

    start : Date = new Date();
    end : Date = new Date();
    is_start_select : boolean = true;

    start_text : string = "";
    end_text : string = "";

    mounted() : void {

        const today = new Date();

        this.approval = {
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.VACATION,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        }

        this.getTotalOrganizationData();
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        
        try {

            if( this.approval == null  ) return;

            await this.hodu_show_indicator();
            await Promise.all([this.getOrganizationPosition(), this.getOrganization(), this.getOrganizationEmp(), this.getOrganizationVacationTypeInfo()]);
            await this.hodu_hide_indicator();

            // 본인 정보 찾기 (my_employee_info, my_position_info, my_department_info)
            if( this.my_employee_info == null ) {
                alert("직원 정보가 존재하지 않습니다\n관리자에게 문의하세요");
                this.close();
                return;
            }

            for( const department of this.departments ) {
                if( this.my_employee_info.dept_id == department.dept_id ) {
                    this.my_department_info = department;
                    this.approval.dept_id = department.dept_id;
                    break;
                }
            }

            if( this.my_department_info == null ) {
                alert("부서가 설정되어 있지 않습니다\n관리자에게 문의하세요");
                this.close();
                return;
            }

            for( const pos of this.position ) {
                if( this.my_employee_info.pos_id == pos.pos_id ) {
                    this.my_position_info = pos;
                    this.approval.pos_id = pos.pos_id;
                    break;
                }
            }

            if( this.my_position_info == null ) {
                alert("직급이 설정되어 있지 않습니다\n관리자에게 문의하세요");
                this.close();
                return;
            }

            // 제목을 '휴가원'으로 지정하고 변경 불가능
            this.approval.contents.title = '휴가원';
            
            if( this.vacation_types == null || this.vacation_types.length < 1 ) {
                alert("휴가 종류가 설정 되어 있지 않습니다\n관리자에게 문의하세요");
                this.close();
                return;
            }

            if( this.computedEmployee.length < 1 ) {
                alert("휴가원을 승인 해 줄 대상이 존재하지 않습니다");
                this.close();
                return;
            }
            
            this.selected_approver_id = this.computedEmployee[0].user_id;
            this.selected_vacation_type_id = this.vacation_types[0].vacation_type_id;
            
            this.start = moment().toDate();
            this.end = moment(this.start).toDate();

            if( Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) > 1 ) {
                this.end = moment(this.start).set('date', this.start.getDate() +  Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) - 1).toDate();
            }

            this.makeDateText();

        } catch(e) {
            alert("조직도 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

            for( const employee of this.employees ) {
                if( employee.user_id == this.user_id ) {
                    this.my_employee_info = employee;
                    break;
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 휴가타입 조회
     */
    async getOrganizationVacationTypeInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.vacation_type_info ) {
                throw new Error("휴가타입 조회 중 오류 발생")
            }

            this.vacation_types.splice(0, this.vacation_types.length);
            this.vacation_types = this.vacation_types.concat(response.data.data.vacation_type_info);
            this.vacation_types.sort((o1, o2) : number => {
                if( o1.vacation_type_id > o2.vacation_type_id ) return 1;
                else return -1;
            });

        } catch(e) {
            throw e;
        }
    }

    /**
     * 날짜 텍스트 생성
     */
    makeDateText() : void {
        this.start_text = `${this.hodu_date_to_format_string(this.start, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.start, '요일')}`;
        this.end_text = `${this.hodu_date_to_format_string(this.end, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.end, '요일')}`;
    }
    
    /**
     * 시작일, 종료일 달력 세팅 변경
     */
    set(is_start_select : boolean) : void {
        this.is_start_select = is_start_select;
        this.changeDate(this.is_start_select == true ? this.start : this.end);
    }

    /**
     * 달력 선택 됨
     */
    select(date_string : string , data : any) : void {
        console.log(date_string);

        const daycount = Number(this.computedSelectedVacationType.vacation_type_daycount);

        if( this.is_start_select == true ) {
            this.start = new Date(date_string);
            this.end = new Date(date_string);
            
            // 휴가 타입의 휴가 일수 계산 후 end 세팅
            if( daycount > 1 ) {
                this.end = moment(this.end).set('date', moment(this.end).get('date') + daycount - 1).toDate();
            }
        }
        else {
            this.end = new Date(date_string);
            this.start = new Date(date_string);

            // 휴가 타입의 휴가 일수 계산 후 start 세팅
            if( daycount > 1 ) {
                this.start = moment(this.end).set('date', moment(this.end).get('date') - daycount + 1).toDate();
            }
        }

        this.makeDateText();
    }

    /**
     * 달력 년, 월 변경 됨
     */
    change(year: number, month: number, inst: any) : void {
        // DO NOTHING
    }

    /**
     * datepicker 선택 된 날짜 변경
     */
    changeDate(target_date : Date) : void {
        this.event_bus.$emit('changeDate', target_date);
    }

    /**
     * 휴가타입 변경
     */
    @Watch('selected_vacation_type_id')
    changeSelectedVacationTypeId() : void {
        this.start = moment().toDate();
        this.end = moment(this.start).toDate();

        if( Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) > 1 ) {
            this.end = moment(this.start).set('date', this.start.getDate() +  Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) - 1).toDate();
        }

        this.start_text = `${this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.start)}`;
        this.end_text = `${this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.end)}`;
        
        this.changeDate( this.is_start_select == true ? this.start : this.end );

        this.$nextTick(() => this.makeDateText());
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 부서 이름 반환
     * level 1 : 최상위 부서 이름 반환
     * level 2 : 최상위 부서 바로 아래의 부서 이름 하나만 반환
     * level n (n > 2) : level 2 / level 3 / level 4 ... 
     */
    getDepartmentName(dept_id : number) : string {
        try {

            if( dept_id == 0 ) return "미배정";

            let dept_name = "";
            const target = this.departments.filter(item => item.dept_id == dept_id)[0];

            let target_departments : any[] = [];
            for( const department of this.departments ) {
                if( target.dept_id_array.indexOf(department.dept_id) > -1 ) target_departments.push(department);
            }

            target_departments.sort((o1, o2) : number => {
                const o1_length = o1.dept_id_array.length;
                const o2_length = o2.dept_id_array.length;

                if( o1_length > o2_length ) return 1;
                else if( o1_length < o2_length ) return -1;
                return 0;
            });

            for( const department of target_departments ) {
                if( target_departments.length >= 2 && target_departments.indexOf(department) == 0 ) continue;

                if( dept_name.length > 0 ) dept_name += " / ";
                dept_name += department.dept_name;
            }
        
            return dept_name;
        } catch(e) {
            console.log(e);
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationVacationAddModlInfo?.({ show_modal : false });
    }

    /**
     * 저장
     */
    async save() : Promise<void> {
        if( this.approval == null ) return;

        if( this.approval.contents.comment.trim().length < 1 ) {
            alert("휴가 사유를 입력 해주세요");
            return;
        }

        try {
            this.approval.approver.push({
                seq : 0,
                rev : 1,
                state : approval_enum.APPROVAL_APPROVER_STATE.REQUEST,
                comment : "",
                user_id : this.user_id,
                user_name : this.user_name,
                dept_id : this.my_department_info.dept_id,
                dept_name : this.getDepartmentName(this.my_department_info.dept_id),
                pos_id : this.my_position_info.pos_id,
                pos_name : this.getPositionName(this.my_position_info.pos_id),
                date : new Date(),
                user_pic : this.my_employee_info.user_pic
            });

            const employees = this.computedEmployee.filter(employee => employee.user_id == this.selected_approver_id);
            
            if( employees.length < 1) {
                alert("휴가 등록 중 오류 발생");
                return;
            }

            const employee = employees[0];
            
            this.approval.approver.push({
                seq : 1,
                rev : 1,
                state : approval_enum.APPROVAL_APPROVER_STATE.ING,
                comment : "",
                user_id : employee.user_id,
                user_name : employee.user_name,
                dept_id : employee.dept_id,
                dept_name : this.getDepartmentName(employee.dept_id),
                pos_id : employee.pos_id,
                pos_name : this.getPositionName(employee.pos_id),
                user_pic : this.my_employee_info.user_pic
            });

            this.approval.contents.title = this.approval.contents.title.trim();
            this.approval.contents.comment = this.approval.contents.comment.trim();
            this.approval['comment'] = {};
            this.approval.approval_cur = 0;
            this.approval.approval_total = this.approval.approver.length - 1;
            // this.approval.requested = new Date();
            // this.approval.expired = new Date();

            this.approval.contents.vacation_type_id = this.computedSelectedVacationType.vacation_type_id;
            this.approval.contents.vacation_type_name = this.computedSelectedVacationType.vacation_type_name;
            this.approval.contents.vacation_type_daycount = this.computedSelectedVacationType.vacation_type_daycount;
            this.approval.contents.vacation_start = moment(this.start).format('YYYYMMDD');
            this.approval.contents.vacation_end = moment(this.end).format('YYYYMMDD');

            // if( this.computedSelectedVacationType.vacation_type_daycount < 1 ) {    
            if( this.computedSelectedVacationType.vacation_type_daycount == 0.5 ) {    
                this.approval.contents.vacation_am_pm = (this.computedSelectedVacationType.contents != null && this.computedSelectedVacationType.contents.amPm != null) ? this.computedSelectedVacationType.contents.amPm : 'AM';
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval`, API_METHOD.POST, this.approval);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("휴가 등록 중 오류 발생");
            }

            this.organization_vacation_add_modal_info.callback?.();
            this.close();

        } catch(e) {
            alert("휴가 등록 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    inputComment(event) {
        const value = event.target.value;

        if( this.approval != null ) {
            this.approval.contents.comment = value
        }
    }

}
</script>

<style scoped>
    .modal { position:absolute; left:50%; top:50%; margin-top:-265px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:530px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    .modal h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    .modal .detail { overflow:hidden; }
    .datepickerDiv { float:left; width: 40%; height: 368px; padding: 25px 25px; box-sizing: border-box; text-align: center; }
    .ui-datepicker-prev, .ui-datepicker-next {font-size:0;display: inline-block;width: 25px;height: 25px;text-align: center;cursor: pointer;background-image: url('../../../assets/images/contents/month_bt_prev.svg');background-repeat: no-repeat;line-height:25px;background-size: 22px;overflow: hidden;}
    .ui-datepicker-prev {float:left;background-position: center center;}
    .ui-datepicker-next { float:right; background-position: center center;}
    .ui-datepicker-prev:hover {background-image: url('../../../assets/images/contents/month_bt_prev_on.svg');}
    .ui-datepicker-next:hover {background-image: url('../../../assets/images/contents/month_bt_next_on.svg');}
    a.ui-datepicker-next {margin-left:5px;background-image: url('../../../assets/images/contents/month_bt_next.svg');}
    .datepickerDiv .yyyy { font-size: 16px; font-weight:bold; margin-bottom: 20px; margin-top:6px; display: inline-block; }

    .calUl .day li { height: 20px; line-height: 20px; }
    .calUl .dayList { height:20px; }
    .calUl > li { display: block; height: 40px;line-height: 40px; }
    .calUl ul  { overflow: hidden; }
    .calUl ul li { float:left; text-align: center; display: inline-block; width:14.2857142857%;}
    .calUl ul li a { font-weight: bold; display:inline-block; width: 30px; height:30px;line-height: 30px; transition:0.2s; border-radius:50%;vertical-align: middle; }
    .calUl ul li a:hover { background:#f1f3f5; }
    .calUl ul li a.on { background:#477fff !important; color:#fff; }
    .calUl ul li a.nm { opacity:0.5 }
    .calUl ul li a.off { opacity:0.3; font-weight: normal; }
    .calUl ul li a.off:hover { background:transparent }
    

    .modal .context { float:left; border-left:1px solid #e7e9ea; width: 60%; height:368px; box-sizing: border-box; }
    
    .modal .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px; width: 100% }
    .modal .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold;; }
    .modal .btns input.save:hover { color:#477fff; }
    .modal .btns input:hover { background:#f1f3f5; }

    .timeDiv { overflow: hidden; }
    .startDiv, .endDiv { overflow:hidden; width:50%; height: 60px; float:left; }
    .startDiv { border-right:1px solid #477fff; box-sizing: border-box; }
    .startDiv.on select, .endDiv.on select { font-weight:bold; } 
    .startDiv select, .endDiv select { cursor:pointer; box-sizing: border-box; transition: 0.2s; float:left; width: 33.3333333333%; appearance: none; padding: 0 15px;line-height:50px;background-image:url('../../../assets/images/side_area/bt_open.png'); background-position: 95%  center; background-repeat: no-repeat; height: 50px; border-right:1px solid #f1f3f5; border-bottom:1px solid #e7e9ea;}
    .startDiv select:last-child, .endDiv select:last-child { border-right:0; }
    
    .timeDiv select:hover { background-color:#f1f3f5; }
    .timeDiv select:focus { border-bottom:1px solid #477fff; background-color:#fff;background-image:url('../../../assets/images/side_area/bt_close.png'); }
    .timeDiv input[type="button"] { border:none; border-bottom:1px solid #e7e9ea; cursor:pointer;transition:0.2s; background:#fff; font-size:13px; font-weight: normal; padding: 0 15px;height: 60px; line-height: 60px; width: 100%; box-sizing: border-box;  }
    .timeDiv input[type="button"]:hover { background:#f1f3f5;border-color:#477fff; }
    .timeDiv input[type="button"]:focus, .timeDiv input[type="button"].on { background-color:#477fff !important; border-color:#477fff; color:#fff; font-weight: bold;}
    
    .detailDiv { overflow: hidden; padding: 20px 30px 0; }
    .detailDiv .selDiv { box-sizing: border-box; width: 50%; float: left; padding-left: 10px;}
    .detailDiv .selDiv.left { padding-right: 10px; padding-left: 0; }
    .detailDiv label { overflow: hidden;height: 25px; display:block; width: 100%; }
    .detailDiv select {  line-height: 45px; height:45px; margin-bottom: 20px;  cursor:pointer; box-sizing: border-box; transition: 0.2s;width: 100%; border-radius: 5px; appearance: none; padding: 0 15px;background-image:url('../../../assets/images/side_area/bt_open.png'); background-position: 97%  center; font-weight: bold; background-repeat: no-repeat;border:1px solid #e7e9ea;}
    .detailDiv select:hover { border-color:#477fff; }
    .detailDiv select:focus {  border-color:#477fff; background-color: #fff; background-image:url('../../../assets/images/side_area/bt_close.png');}
    .modal input[type="text"] { margin-bottom:20px; transition:0.2s; font-size:14px; font-weight: bold; padding: 15px; border:1px solid #e7e9ea;width: 100%; box-sizing: border-box;  line-height: 50px; height:50px; }
    .modal input[type="text"]:hover { background:#f1f3f5; }
    .modal input[type="text"]:focus { border-color:#477fff; background:#fff; }

    .modal textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 143px; max-height: 143px; font-size: 13px;line-height: 20px; padding: 15px; height:auto; box-sizing: border-box; font-weight:bold; }
    .modal textarea:hover { border-color:#477fff }
    .modal textarea:focus { background:#fff; border-color:#477fff;outline: none;  }

    .modal.edit .btns input { width : 50%; }
</style>