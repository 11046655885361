<template>
    <div id="exception" class="section_ce_fix">

        <div class="nav-container">
            <div class="nav">
                <ul>
                    <li></li>
                    <li><button id="btn_showAllList" class="btn btn-dark" @click="getException">초기화</button></li>
                    <li>
                        <select name="search_type" style="margin-right: 5px;" @change="searchTabChange($event)">
                            <option value="ALL"> 전체 </option>
                            <option value="MESSAGE"> message </option>   
                            <option value="TRACE"> trace </option>
                        </select>
                        <input type="text" id="search_value" style="width:300px; margin-right: 5px;" 
                               :value="search_query" @input="search_query = $event.target.value.trim()" @keyup="keyUpSearch($event)">
                        <button id="btn_search" class="btn btn-dark" @click="getException">검색</button>
                    </li>
                    <li>
                        <select id="os_value" @change="osTabchange($event)">
                            <option value="ALL"> 전체 </option>    
                            <option value="ANDROID"> Android </option>
                            <option value="IOS"> IOS </option>
                            <option value="WEB"> WEB </option> 
                        </select>
                    </li>
                    
                    <!-- 등록된 exception 버전 -->
                    <li>
                        <select id="app_version" @change="versionChange($event)">
                            <option value="ALL"> 전체 </option>  
                            <option :value="version" :key="version" v-for="version in exception_version_list"> {{ version }} </option>
                        </select>
                    </li>

                    <!-- user_id로 정보 긁어오기 주석처리 API도 지워짐 -->
                    <!-- <li>
                        <input type="text" placeholder="USER_ID 검색" :value="user_search_query" @keydown="searchUserByUserId($event)" @input="user_search_query = $event.target.value.trim()">
                    </li> -->
                </ul>
                <div class="clear"></div>
            </div>
        </div>

        <div id="exception_wrap">
            <table style="word-break: break-all; white-space: pre-line;">
                <thead class="msgHeader sortHeader sendMmsHeader" id="exception_header">
                    <th class="no">No</th>
                    <th class="solve">solve</th>
                    <th class="os">os</th>
                    <th class="os_version">os_version</th>
                    <th class="app_version">version</th>
                    <th class="user_id">user_id</th>
                    <th class="message">message</th>
                    <th class="stack_trace">stack_trace</th>
                    <th class="device_model">device_model</th>
                    <th class="package_name">package</th>
                    <th class="crt_ymd">crt_ymd</th>
                </thead>

                <tbody align="center">
                    <tr :key="exception.exception_id" v-for="(exception, index) in exception_list">
                        <td nowrap> <span class="no">{{ exception_list.length - index }}</span> </td>
                        <td nowrap> <input type="button" class="solve" value="해결" @click="solveExceptionReport(exception.exception_id)"/> </td>
                        <td nowrap> <span class="os">{{ exception.os }}</span> </td>
                        <td nowrap> <span class="os_version">{{ exception.os_version }}</span> </td>
                        <td nowrap> <span class="app_version">{{ exception.app_version }}</span> </td>
                        <td nowrap> <span class="user_id">{{ exception.user_id }}</span> </td>
                        <td align="left"> <span class="message">{{ exception.exception_message }}</span> </td>
                        <td align="left"> <span class="stack_trace">{{ exception.exception_trace }}</span> </td>
                        <td nowrap> <span class="device_model">{{ exception.device_model }}</span> </td>
                        <td nowrap> <span class="package_name">{{ exception.package_name }}</span> </td>
                        <td nowrap> <span class="crt_ymd">{{ exception.crt_ymd }}</span> </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { ginus_exception_log } from '@/model/osm';

@Component({
    components: {

    }
}) export default class Exception extends Mixins(VueHoduCommon) {

    os_tab : string = 'ALL';
    version_tab : string = 'ALL'; 
    exception_version_list : string[] = [];
    exception_list : ginus_exception_log[] = [];
    search_type : string = 'ALL';
    search_query : string = "";
    user_search_query : string = "";

    mounted() : void {
        this.getException();

        var lo_thread    = null;
        var exceptionId  = 0;

        // 스크롤시 thead가 따라다니도록
        const exception_wrap : HTMLElement | null = document.getElementById("exception_wrap");
        if( exception_wrap != null ) {
            exception_wrap.addEventListener("scroll", function(){
                const translate = "translate(0,"+this.scrollTop+"px)";
                const map : HTMLTableSectionElement | null = this.querySelector("thead");
                if( map != null ) {
                    map.style.transform = translate;
                }
            });
        }
        
    }

    /**
     * Exception 조회
     */
    getException(scroll_to_top : boolean = true) : void {
        if( scroll_to_top == true ) { $('#exception_wrap').scrollTop(0); }
        this.hodu_api_call(`api/v1/exception?os=${this.os_tab}&search_type=${this.search_type}&search_query=${this.search_query}&app_version=${this.version_tab}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.exception_version_list.splice(0, this.exception_version_list.length);
                this.exception_version_list = this.exception_version_list.concat(response.data.data.exception_version_list); 

                this.exception_list.splice(0, this.exception_list.length);
                this.exception_list = this.exception_list.concat(response.data.data.exception_list); 
            })
            .catch((e) => {});
    }

    /**
     * 엔터키 반응해서 검색하도록 설정
     */
    keyUpSearch(event : KeyboardEvent) : void {
        if( event.keyCode != 13 ) { return; }
        this.getException();
    }

    /**
     * 검색 탭 변경
     */
    searchTabChange(event : any) : void {
        this.search_type = event.target.value;
    }

    /**
     * OS 탭 변경
     */
    osTabchange(event : any) : void {
        this.os_tab = event.target.value;
        this.getException();
    }

    /**
     * 버전 탭 변경
     */
    versionChange(event : any) : void {
        this.version_tab = event.target.value;
        this.getException();
    } 

    /**
     * user_id 검색
     */
    // searchUserByUserId(event : KeyboardEvent) : void {
    //     const code : number = event.keyCode;

    //     if( code == 13 ) {
    //         if( this.user_search_query == null || this.user_search_query.length < 1 ) {
    //             alert("user_id를 입력해주세요");
    //             return;
    //         }

    //         let user_id : number 
    //         try {
    //             user_id = Number(this.user_search_query);
    //             if( isNaN(user_id) ) { throw new Exception(); }
    //         } catch(e) {
    //             return;
    //         }

    //         this.hodu_api_call(`api/v1/hodu/user/${user_id}`, API_METHOD.GET)
    //             .then((response) => {
    //                 const textArea : HTMLTextAreaElement = document.createElement("textarea");
    //                 document.body.appendChild(textArea);
    //                 textArea.value = JSON.stringify(response.data.data.user_info);
    //                 textArea.select();
    //                 document.execCommand('copy');
    //                 document.body.removeChild(textArea);
    //                 alert("클립보드에 유저 정보가 복사 되었습니다.");
    //             })
    //             .catch((e) => { if( e.response ) { alert(e.response.data); } });
    //     }
    // }

    /**
     * exception 해결
     */
    solveExceptionReport(exception_id : number) : void {
        this.hodu_api_call(`api/v1/exception/${exception_id}`, API_METHOD.DELETE)
            .then((response) => {
                console.log(response); 
                this.getException(false); 
            })
            .catch((e) => { console.log(e); });
    }

}

</script>

<style scoped>
    .section_ce_fix .title_box { line-height:61px; height:61px; }
    
    .nav-container {background:url('../assets/images/bg/nav_bg.jpg') repeat-x 0 0;}

    .nav {height:60px;}
    .nav ul {list-style:none;}
    .nav ul li {float:left; margin-top:6px; padding:6px;}
    .nav ul li:first-child {padding-left:0;}
    .nav ul li a:hover {text-decoration: underline;}

    #exception_wrap {
        overflow: auto;
        height: 93vh;
    }
    thead{
        background: #ffffffff;
    }
    td, th{
        vertical-align: middle;
        border: 1px solid #dee2e6;
    }
    td {
        padding: 15px 10px;
    }
    input[type=button] {
        padding : 5px 10px;
        border-radius: 5px;
        background-color: #477fff;
        color : white;
    }
    button {
        border-radius: 5px;
        background: #343a40;
    }
    table, thead, tbody, tr, td, span {
        -webkit-user-select: text !important;
        -khtml-user-select: text !important;
        -moz-user-select: text !important;
        -ms-user-select: text !important;
        user-select: text !important;
        -webkit-touch-callout: text !important;
        -webkit-tap-highlight-color: text !important; 
    }
    span {
        line-height: 20px;
        font-size: 14px;
    }
</style>