<template>
    <div class="dis member_set_2" id="organization_approval_setting">
        <div class="approval_content">

            <div class="content_top">
                <input type="button" class="mag_btn" :class="{ on : is_close_organization == true }" @click="is_close_organization = !is_close_organization">
                <h3 style="margin-left: 60px;">결재자 사인이미지 설정</h3>
                <input type="text" id="filter_search" placeholder="이름, 부서로 검색하세요" style="left:250px" :value="search_query" @input="search_query = $event.target.value"/>
            </div>
            <div class="wrapper" style="padding-top : 0;">
                    
                <div class="chk all" style="padding-top : 20px; border-bottom:1px solid #e7e9ea; background: #f2f5f7;">
                    <p style="top:10px; font-size:13px; font-weight:bold;">부서</p>
                </div>

                <!-- 조직도 모달 -->
                <!-- 직원 기본 설정 좌측 조직도랑 똑같은거에요 -->
                <!-- <a href="#" class="organization_setting" @click.prevent="showOrganizationModal"></a> -->

                <div id="organization_setting_scroll" :style="{ height : `${department_scroll_height}px` }"> 

                    <div class="chk all" style="padding-top : 0;cursor:pointer;" :class="{ click_on : selected_department.dept_id == -1 }" @click="select({ dept_id : -1 })">
                        <p style="top:0px;">전체</p>
                    </div>

                    <!-- 메인  -->
                    <!-- <div class="chk" style="cursor:pointer;" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                        <p>{{ computedOrganization != null ? computedOrganization.dept_name : '' }}</p>
                    </div> -->

                    <OrganizationRecursion 
                        :level="2"
                        :content_type="'p'" 
                        :departments="computedOrganization.departments"
                        :selected_department="selected_department"
                        :department_closed_map="department_closed_map"
                        :department_height_map="department_height_map" 
                        @select="select"
                        v-if="computedOrganization != null" />

                    <!-- 미배정 -->
                    <div class="chk" style="cursor:pointer;" :class="{ click_on : computedOrganization != null && selected_department.dept_id == computedOrganization.dept_id }" @click="select(computedOrganization)">
                        <p style="font-size: 13px; font-weight: bold; left: 60px; color: #477fff; opacity: .8;">미배정</p>
                    </div>

                </div>

            </div>

            <div id="noticeScroll" class="section_scroll">
                <!-- 사인등록 모달 -->
                <!-- <div class="signModalDiv">
                    <div id="signModalCon" class="modal">
                        <div class="titleBox">
                            <h1 class="ing">결재사인</h1>
                        </div>
                        <div class="content">
                            <div class="detail">
                                <ul>
                                    <li>
                                        <p class="signImgLook">사인이미지</p>
                                        <p class="signImgText">
                                            결재시 사용되는 사인이미지 입니다. <br>
                                            등록된 사인은 등록시점 이후 결재정보부터 적용됩니다.<br>
                                            이미지 등록을 하지 않을 시 기본 승인 이미지가 제공됩니다.
                                        </p>
                                        <a href="#">파일</a>
                                    </li>
                                    <li>
                                        <p class="signImgOption">
                                            사인이미지 권장크기는 가로*세로=45*45픽셀, 용량은 100kbyte 입니다.<br>
                                            <span>(확장자는 gif, jpg, png파일 형식만 등록 가능합니다.)</span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="btns">
                                <input type="button" value="닫기" class="close">
                                <input type="button" value="저장" class="save">
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- //사인등록 모달 -->

                <div class="viewGroup">
                    <div class="schedule_box"></div>

                    <div class="fixedDiv">
                        <ul id="" class="sortHeader groupNoticeHeader">
                            <li class="num">No</li>
                            <li class="title headerSort">
                                <span>이름</span>
                            </li>

                            <li class="group headerSort">
                                <span>부서</span>
                            </li>
                            
                            <li class="pos headerSort">
                                <span>직급</span>
                            </li>
                            
                            <li class="approver headerSort">
                                <span>결재자</span>
                            </li>

                            <li class="signature">
                                <span>결재사인</span>
                            </li>
                        </ul>
                    </div>

                    <div id="organization_emp_scroll" class="content">
                        <ul class="notiListUl">

                            <li :key="employee.user_id" v-for="(employee, index) in computedEmployee">
                                <p class="num"><span >{{ index + 1 }}</span></p>
                                <p class="title">
                                    <img :src="getProfileImage(employee)" class="king mCS_img_loaded" @error="userImageError($event)">
                                    <span>{{ employee.user_name }}</span>
                                </p>
                                <p class="group">
                                    <span class="setting">{{ getDepartmentName(employee.dept_id) == '-' ? '' : getDepartmentName(employee.dept_id) }}</span>
                                </p>
                                <p class="pos">
                                    <span class="setting">{{ getPositionName(employee.pos_id) == '-' ? '' : getPositionName(employee.pos_id) }}</span>
                                </p>
                                <p class="approver">
                                    {{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '' : (employee.is_approver == true ? '예' : '아니오') }}
                                    <select @change="changeEmployeeApprover($event, employee)" v-if="get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN'">
                                        <option value="N" :selected="employee.is_approver == false">아니오</option>
                                        <option value="Y" :selected="employee.is_approver == true">예</option>
                                    </select>
                                </p>

                                <p class="signature" :class="{
                                    chanDelImg : (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') && employee.contents.sign != null && employee.contents.sign.url != null && employee.contents.sign.url.length > 0,
                                    noneSignImg : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') && employee.contents.sign == null,
                                    addSignImg : (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') && !(employee.contents.sign != null && employee.contents.sign.url != null && employee.contents.sign.url.length > 0 ),
                                }">
                                    <img :src="`app_images/${employee.contents.sign.url}`" v-if="employee.contents.sign != null && employee.contents.sign.url != null && employee.contents.sign.url.length > 0"/>
                                    <span class="addSign" @click.prevent="showOrganizationSignModal(employee)">사인 등록</span>
                                    <a href="#" class="changeBtn" @click.prevent="showOrganizationSignModal(employee)">수정</a>
                                    <a href="#" class="delBtn" @click.prevent="deleteSign(employee)">삭제</a>
                                </p>

                                <!-- 결재자인데 사인 이미지 등록이 된 경우 chanDelImg 클래스 추가-->
                                <!-- <p class="signature chanDelImg">
                                    <img src="../../assets/images/contents/e_sign.png" alt="">
                                    <span class="signImg">결재사인 이미지</span>
                                    <span class="addSign">사인 등록</span>
                                    <a href="#" class="changeBtn">수정</a>
                                    <a href="#" class="delBtn">삭제</a>
                                </p> -->

                                <!-- 결재자가 아닐 경우 noneSignImg 클래스 추가-->
                                <!-- <p class="signature noneSignImg">
                                    <img src="../../assets/images/contents/e_sign.png" alt="">
                                    <span class="signImg">결재사인 이미지</span>
                                    <span class="addSign">사인 등록</span>
                                    <a href="#" class="changeBtn">수정</a>
                                    <a href="#" class="delBtn">삭제</a>
                                </p> -->

                                <!-- 결재자인데 사인이미지 등록이 안됐을 경우 addSignImg 클래스 추가 -->
                                <!-- <p class="signature addSignImg">
                                    <img src="../../assets/images/contents/e_sign.png" alt="">
                                    <span class="signImg">결재사인 이미지</span>
                                    <span class="addSign">사인 등록</span>
                                    <a href="#" class="changeBtn">수정</a>
                                    <a href="#" class="delBtn">삭제</a>
                                </p> -->
                            </li>

                            <!-- <li class="">
                                <p class="num"><span >1</span></p>
                                <p class="title">
                                    <img src="../../assets/images/contents/ic_approval_user_on.png" class="king mCS_img_loaded">
                                    <span>김성림</span>
                                </p>
                                <p class="group">
                                    <span class="setting">지누스</span>
                                </p>
                                <p class="pos">
                                    <span class="setting">부장</span>
                                </p>
                                <p class="approver">
                                    <select name="" id="">
                                        <option value="" selected="selected">예</option>
                                        <option value="" >아니오</option>
                                    </select>
                                </p>
                                <p class="signature chanDelImg">
                                    <img src="../../assets/images/contents/e_sign.png" alt="">
                                    <span class="signImg">결재사인 이미지</span>
                                    <span class="addSign">사인 등록</span>
                                    <a href="#" class="changeBtn">수정</a>
                                    <a href="#" class="delBtn">삭제</a>
                                </p>
                            </li>
                            <li class="">
                                <p class="num"><span >2</span></p>
                                <p class="title">
                                    <img src="../../assets/images/contents/ic_approval_user_on.png">
                                    <span>김개발</span>
                                </p>
                                <p class="group">
                                    <span class="setting">호두</span>
                                </p>
                                <p class="pos">
                                    <span class="setting">팀장</span>
                                </p>
                                <p class="approver">
                                    <select name="" id="">
                                        <option value="" >예</option>
                                        <option value="" selected="selected">아니오</option>
                                    </select>
                                </p>
                                <p class="signature noneSignImg">
                                    <img src="../../assets/images/contents/e_sign.png" alt="">
                                    <span class="signImg">결재사인 이미지</span>
                                    <span class="addSign">사인 등록</span>
                                    <a href="#" class="changeBtn">수정</a>
                                    <a href="#" class="delBtn">삭제</a>
                                </p>
                            </li>
                            <li class="">
                                <p class="num"><span >3</span></p>
                                <p class="title">
                                    <img src="../../assets/images/contents/ic_approval_user_on.png">
                                    <span>재갈호두</span>
                                </p>
                                <p class="group">
                                    <span class="setting">호두</span>
                                </p>
                                <p class="pos">
                                    <span class="setting">사원</span>
                                </p>
                                <p class="approver">
                                    <select name="" id="">
                                        <option value="" selected="selected">예</option>
                                        <option value="" >아니오</option>
                                    </select>
                                </p>
                                <p class="signature addSignImg">
                                    <img src="../../assets/images/contents/e_sign.png" alt="">
                                    <span class="signImg">결재사인 이미지</span>
                                    <span class="addSign">사인 등록</span>
                                    <a href="#" class="changeBtn">수정</a>
                                    <a href="#" class="delBtn">삭제</a>
                                </p>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_enum, organization_modal_interface } from '@/model/organization';

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

@Component({
    components: {
        OrganizationRecursion
    },
})
export default class OrganizationApprovalSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME ) {

                if( o1_name == o2_name ) {
                    // 같은 이름은 직급순
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_name > o2_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION ) {

                // 같은 직급은 이름순
                if( o1_pos_seq == o2_pos_seq ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0
                }
                else if( o1_pos_seq > o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
                else if( o1_pos_seq < o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.DEPARTMENT ) {
                const o1_dept_id   = o1.dept_id;
                const o2_dept_id   = o2.dept_id;

                const o1_dept_name = this.getDepartmentName(o1_dept_id);
                const o2_dept_name = this.getDepartmentName(o2_dept_id);

                if( o1_dept_name == o2_dept_name ) {

                    if( o1_dept_id == o2_dept_id ) {
                        // 같은 이름은 dept_id 순서 + 직급순
                        if( o1_pos_seq > o2_pos_seq ) return 1;
                        else if( o1_pos_seq < o2_pos_seq ) return -1;
                        return 0;
                    }
                    else if( o1_dept_id > o2_dept_id ) return 1;
                    else if( o1_dept_id < o2_dept_id ) return -1;
                    
                    return 0;
                }
                else if( o1_dept_name > o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_dept_name < o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if ( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.APPROVER ) {
                const o1_is_approver = o1.is_approver;
                const o2_is_approver = o2.is_approver;

                if( o1_is_approver == o2_is_approver ) {
                    // 같다면 직급 순
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_is_approver == false && o2_is_approver == true ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_is_approver == true && o2_is_approver == false ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if ( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.EMAIL ) {
                const o1_email = o1.user_email;
                const o2_email = o2.user_email;

                if( o1_email == o2_email ) {
                    // 이메일이 같다면 직급 순 (일반적으로 데이터가 들어있다면 같을 순 없지만 비어있다면 똑같을수 있음)
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_email > o2_email ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_email < o2_email ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            return 0;
        });

        // 검색
        if( this.search_query != null && this.search_query.length > 0 ) {
            employees = employees.filter(item => 
                                                 // 이름   
                                                 this.hodu_string_includes(item.user_name, this.search_query) ||
                                                 
                                                 // 부서
                                                 this.hodu_string_includes(this.getDepartmentName(item.dept_id), this.search_query) );
        }

        return employees;
    }

    /**
     * @ModalInfo.Action 
     */
    @ModalInfo.Action doSetOrganizationSignModalInfo ?: (params : organization_modal_interface.OrganizationSignModalInfo) => void;

    departments : any[] = [];
    selected_department : any = { dept_id : -1 }; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];
    search_query : string = "";

    sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE = organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    department_scroll_height : number = 0;

    is_close_organization : boolean = false;
    
    async mounted() : Promise<void> {
        this.event_bus?.$on("OrganizationApprovalSettingResize", this.handleResize);

        //사인등록 모달
        // $('.addSign').click(function(){
        //     $('.signModalDiv').fadeIn(200);
        //     $('.modal').show();
        //     return false;
        // })

        //사인등록 모달 닫기
        // $('.modal .btns input.close').click(function(){
        //     $('.signModalDiv').fadeOut()
        // })

        await this.getTotalOrganizationData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_box_height = $('#organization_setting .title_box').outerHeight();
        const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            
        // 조직도 스크롤
        const all_height = $('#organization_setting .approval_content .wrapper .chk.all').outerHeight();
        const content_top_height = $('#organization_setting .approval_content .content_top').outerHeight();

        this.department_scroll_height = window.innerHeight - (title_box_height ? title_box_height : 0)
                                                           - (title_box2_height ? title_box2_height : 0)
                                                           - (content_top_height ? content_top_height : 0)
                                                           - (all_height ? all_height : 0);

        // 부서원 리스트 스크롤
        const fixed_div_height = $('#organization_setting .approval_content .section_scroll .fixedDiv').outerHeight();

        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (title_box2_height ? title_box2_height : 0)
                                           - (content_top_height ? content_top_height : 0)
                                           - (fixed_div_height ? fixed_div_height : 0)
        });
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 부서 이름 반환
     */
    getDepartmentName(dept_id : number) : string {
        try {
            const target = this.departments.filter(item => item.dept_id == dept_id);
            if( target.length < 1 ) return "-";
            if( target[0].dept_id == 0 ) return "미배정";
            return target[0].dept_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 직원 결재자 여부 변경
     */
    async changeEmployeeApprover(event, employee : any) : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getTotalOrganizationData();

            const is_approver_yn = event.target.value;

            // 결재자로 만들때 같은 부서에 같은 직급이고 결재자인 사람이 있는지 체크
            if( is_approver_yn == 'Y' ) {
                const target_pos_id = employee.pos_id;
                const target_dept_id = employee.dept_id;

                const target_emp = this.computedEmployee.filter(emp => emp.pos_id == target_pos_id && emp.dept_id == target_dept_id && emp.is_approver == true);

                if( target_emp.length > 0 ) {
                    $(event.target).val('N').prop("selected", true);
                    this.hodu_show_dialog('alert', "이미 결재자로 등록 된 같은 부서, 같은 직급인 직원이 있습니다", ['확인']);
                    return;
                }
            }

            // API 실행
            const data = JSON.parse(JSON.stringify(employee));
            data.is_approver = is_approver_yn == 'Y';

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.POST, data);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 직원 직급 변경 중 오류 발생");
            }

            await this.getTotalOrganizationData();

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직원 직급 변경 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        } finally {
            this.hodu_hide_indicator();
        }
    }

    /**
     * 사인 추가/수정 모달
     */
    showOrganizationSignModal(employee) : void {

        this.doSetOrganizationSignModalInfo?.({
            show_modal : true,
            group_id : employee.group_id,
            user_id : employee.user_id,
            callback : this.getTotalOrganizationData
        });

    }

    /**
     * 사인 삭제
     */
    async deleteSign(employee) : Promise<void> {

        if( confirm("정말로 사인을 삭제하시겠습니까?") == false ) {
            return;
        }
        
        try {
            const group_id = employee.group_id ?? 0;
            const user_id = employee.user_id ?? 0;

            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/${user_id}/sign`, API_METHOD.DELETE);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("사인 삭제 중 오류 발생");
            }
            
            this.getTotalOrganizationData();

        } catch(e) {
            this.hodu_show_dialog('cancel', "사인 삭제 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user_on.png');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style>
    #organization_approval_setting .click_on{z-index: 200;background: rgb(237, 242, 255, 0.8);}
</style>

<style scoped>
    #organization_approval_setting .mag_btn { position: absolute; margin-left: 20px; padding: 11px 0 0 30px; display: inline-block; width: 40px; height: 100%; background: url('../../assets/images/contents/bt_hambuger.png') no-repeat center center; box-sizing: border-box; }
    #organization_raorganization_approval_settingnk_setting .mag_btn.on { background: url('../../assets/images/contents/ic_close_backgray.png') no-repeat center center !important; background-size: 35px !important; }

    #container #section_ce #organization_approval_setting .section_scroll { width: 85%; }
    #organization_approval_setting #noticeScroll { position: absolute; width: 55%; top: 66px; left: 15%; right: 0; border-right: 1px solid #f1f3f5; }

    #organization_approval_setting .viewGroup li > span { margin-left: 20px; }
    #organization_approval_setting .viewGroup .num { width: 9%; text-align: center; font-weight: bold; padding-left: 20px; box-sizing: border-box; }
    #organization_approval_setting .viewGroup .title { width: 20%; }
    #organization_approval_setting .viewGroup .group { width: 18%; }
    #organization_approval_setting .viewGroup .pos { width: 14%; }
    #organization_approval_setting .viewGroup .approver { width: 13%; }
    #organization_approval_setting .viewGroup .signature { width: 26%; }
    #organization_approval_setting div#organization_emp_scroll ul p.approver select { width: auto; font-weight: bold; transition: 0.2s; -moz-appearance: none; appearance: none; border-radius: 5px; height: 30px; line-height: 30px; cursor: pointer; background-color: #fff; background-position: 95% center; background-repeat: no-repeat; box-sizing: border-box; border: 1px solid #fff; }
    #organization_approval_setting div#organization_emp_scroll ul p.approver:hover select { font-weight:bold; border:1px solid #000;  padding: 0 30px 0 10px; background-image: url('../../assets/images/side_area/bt_open.png'); }
    #organization_setting .content .notiListUl > li p.signature { font-size: 0; }
    #organization_setting .content .notiListUl > li p.signature img { width: 50px; height: 50px; display: inline-block; vertical-align: middle; }
    #organization_setting .content .notiListUl > li p.signature.noneSignImg img { display: none; }
    #organization_setting .content .notiListUl > li p.signature.noneSignImg:after { content:''; width: 8px; height: 2px; display: inline-block; background: #a4a4a4; vertical-align: middle; margin-left: 20px; }
    #organization_setting .content .notiListUl > li p.signature.addSignImg img { display: none; }
    #organization_setting .content .notiListUl > li p.signature.addSignImg .addSign { font-size: 13px; width: 40px; height: 40px; border: 1px solid #e7e9ea; padding: 8px 20px; border-radius: 30px; vertical-align: middle; cursor: pointer; }
    #organization_setting .content .notiListUl > li p.signature.addSignImg .addSign:hover { background: #f1f3f5; }
    #organization_setting .content .notiListUl > li p > a { width: 40px; font-size: 13px; vertical-align: middle; margin-left: 10px; text-align: center; display: none; }
    #organization_setting .content .notiListUl > li p > a.changeBtn { margin-left: 60px; }
    #organization_setting .content .notiListUl > li:hover p a.changeBtn { background: #13d08b !important; }
    #organization_setting .content .notiListUl > li:hover p a.changeBtn:hover { background: #0da970 !important; }
    #organization_setting #organization_approval_setting .content .notiListUl > li:hover p a { display: none; }
    #organization_setting #organization_approval_setting .content .notiListUl > li:hover p.chanDelImg a { display: inline-block; }

    @media screen and (max-width: 1260px) {
        #organization_setting .content .notiListUl > li p > a.changeBtn { margin-left: 40px; }
    }
    
    </style>