<template>
    <div id="friend" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <a href="#" class="input_btn" id="inviteMate" @click.prevent="showFriendAddModal">추가</a>

        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">친구</h3>
            <a class="schBtn" @click.prevent="() => { is_search_open = true; }">검색하기</a>
        </div>
        <!-- 검색 디브, .schBtn 클릭시 searchDiv addClass(on) -->
        <div class="searchDiv" :class="{ on : is_search_open == true }">
            <div class="schGrp">
                <input type="text" id="group_member_search" placeholder="이름, 전화번호, 이메일로 검색하세요" :value="search_query" @input="search_query = $event.target.value">
                <div class="btns">
                    <!-- 검색 디브 removeClass(on) -->
                    <a class="closeBtn" @click.prevent="() => { 
                        is_search_open = false;
                        search_query = ''; 
                    }">닫기</a>
                    <!--<a href="#" class="bt_search"><span class="blind">찾기버튼</span></a>-->
                </div>
            </div>
        </div>
        <div class="section_scroll">
            <div id="friendDiv" class="content">
                <div class="leftScroll" :class="{
                    on : is_close_hide_list == true
                }" >
                    <div class="grp settingBg"> 
                        <h3 class="selectTitle">
                            <span class="txt">총</span>
                            <span class="num">{{ computedFriends.length }}</span>
                            <span class="txt">명</span>
                        </h3>
                        <p class="btn_group">
                            <a id="friRefreshBtn" class="input_btn" @click.prevent="getFriends(true)">새로고침</a>
                        </p>
                    </div>

                    <div class="left_content">
                        <div class="friendTitle">
                            <div id="group_member_header" class="msgHeader sortHeader sendMmsHeader groupMemberHeader">
                                <p class="num">No</p>
                                <p class="userName">이름</p>
                                <p class="tel">전화번호</p>
                                <p class="email">이메일</p>
                            </div>
                        </div>
                        <div id="friendList" class="friendList">
                            <ul id="friendUl" class="friend_option">
                                <li :key="friend.friend_user_id" v-for="(friend, index) in computedFriends">
                                    
                                    <!-- new 친구추가시 newFriendUser 클래스 추가 하여 레드닷 표시 -->

                                    <div class="friendUser" :class="{ newFriendUser : getDateDiff(new Date(), new Date(friend.audit_created)) < 3 }">
                                        <p class="num">{{ index + 1 }}</p>
                                        <p class="userName">
                                            <img :src="make_user_profile_image_url(friend.friend_user_id)" alt="userImages" @error="imageError($event)">
                                            <span>{{ getFriendName(friend) }}</span>
                                        </p>
                                        <p class="tel">{{ hodu_string_validation(friend.user_phone_number) }}</p>
                                        <p class="email">{{ hodu_string_validation(friend.user_email) }}</p>
                                        <button type="button" title="설정" class="frOpMore" :class="{ on : friend.moreOption == true }" @click.prevent="clickMoreOption(computedFriends, friend)">
                                            <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="버튼이미지">
                                        </button>
                                        <ul class="frOpDiv" :class="{ on : friend.moreOption == true }">
                                            <li><button type="button" class="frOpList" @click.prevent="changeName(friend)">이름변경</button></li>
                                            <li><button type="button" class="frOpList" @click.prevent="hideFriend(friend)">숨김</button></li>
                                            <li><button type="button" class="frOpList" @click.prevent="blockFriend(friend)">차단</button></li>
                                        </ul>
                                    </div>
                                </li>
                                <!-- <li>
                                    <div class="friendUser">
                                        <p class="num">1</p>
                                        <p class="userName">
                                            <img src="../../../src/assets/images/contents/ic_approval_user.png" alt="userImages">
                                            <span>김운동</span>
                                        </p>
                                        <p class="tel">01023456789</p>
                                        <p class="email">abcdefgh@gmail.com</p>
                                        <button type="button" title="설정" class="frOpMore">
                                            <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="버튼이미지">
                                        </button>
                                        <ul class="frOpDiv">
                                            <li><button type="button" class="frOpList">이름변경</button></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div class="friendUser">
                                        <p class="num">2</p>
                                        <p class="userName">
                                            <img src="../../../src/assets/images/contents/ic_approval_user.png" alt="userImages">
                                            <span>강요리</span>
                                        </p>
                                        <p class="tel">01023456789</p>
                                        <p class="email">rkddyfldlapdlfrlfrp@gmail.com</p>
                                        <button type="button" title="설정" class="frOpMore">
                                            <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="버튼이미지">
                                        </button>
                                        <ul class="frOpDiv">
                                            <li><button type="button" class="frOpList">이름변경</button></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div class="friendUser">
                                        <p class="num">3</p>
                                        <p class="userName">
                                            <img src="../../../src/assets/images/contents/im_photoB.gif" alt="userImages">
                                            <span>김호두</span>
                                        </p>
                                        <p class="tel">01023456789</p>
                                        <p class="email">abcdefgh@gmail.com</p>
                                        <button type="button" title="설정" class="frOpMore">
                                            <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="버튼이미지">
                                        </button>
                                        <ul class="frOpDiv">
                                            <li><button type="button" class="frOpList">이름변경</button></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div class="friendUser">
                                        <p class="num">4</p>
                                        <p class="userName">
                                            <img src="../../../src/assets/images/contents/ic_approval_user_on.png" alt="userImages">
                                            <span>권팀장</span>
                                        </p>
                                        <p class="tel">01023456789</p>
                                        <p class="email">hodumibile_app@gmail.com</p>
                                        <button type="button" title="설정" class="frOpMore">
                                            <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="버튼이미지">
                                        </button>
                                        <ul class="frOpDiv">
                                            <li><button type="button" class="frOpList">이름변경</button></li>
                                        </ul>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="rightScroll">
                    <div class="friend_right">

                        <div class="titleDiv">
                            <a href="#" class="config_btn" title="숨김친구" :class="{
                                on : is_close_hide_list == true
                            }" @click.prevent="is_close_hide_list = !is_close_hide_list">
                                <span class="hidden">내용보기 닫기</span>
                            </a>
                        </div>

                        <div id="friend_right_list" class="friendRightCon" :class="{
                                on : is_close_hide_list == true
                            }" >
                            <!-- 숨김친구 -->
                            <div class="RightDivShare hidingDiv">
                                <div class="rightConTitle">
                                    <h2>숨김친구</h2>
                                    <p class="hiding_friedn_num">총 <span>{{ computedHideFriends.length }}</span>명</p>
                                    <a class="foldBtn">접기펴기</a>
                                </div>

                                <div id="friendRightList" class="friendRightList">

                                    <ul class="friendRightUl">

                                        <li class="friendRightList" :key="friend.friend_user_id" v-for="friend in computedHideFriends">
                                            <p class="hidingFriendImg">
                                                <img :src="make_user_profile_image_url(friend.friend_user_id)" alt="userImages" @error="imageError($event)">
                                            </p>
                                            <p class="hidingFriendName"><span>{{ getFriendName(friend) }}</span></p>
                                            <p class="hidingFriendTel"><span>{{ hodu_string_validation(friend.user_phone_number) }}</span></p>
                                            <button type="button" title="설정" class="frHidMore" @click.prevent="clickMoreOption(computedHideFriends, friend)">
                                                <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="버튼이미지">
                                            </button>
                                            <ul class="frHidDiv" :class="{ on : friend.moreOption == true }">
                                                <li><button type="button" class="frHidList" @click.prevent="showFriend(friend)">숨김해제</button></li>
                                            </ul>
                                        </li>

                                        <!-- <li class="friendRightList">
                                            <p class="hidingFriendImg">
                                                <img src="" alt="">
                                            </p>
                                            <p class="hidingFriendName"><span>홍길동</span></p>
                                            <p class="hidingFriendTel"><span>01023235656</span></p>
                                            <button type="button" title="설정" class="frHidMore">
                                                <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="버튼이미지">
                                            </button>
                                            <ul class="frHidDiv">
                                                <li><button type="button" class="frHidList">숨김해제</button></li>
                                            </ul>
                                        </li> -->

                                        <!-- <li class="friendRightList">
                                            <p class="hidingFriendImg">
                                                <img src="" alt="">
                                            </p>
                                            <p class="hidingFriendName"><span>장발장</span></p>
                                            <p class="hidingFriendTel"><span>01055556666</span></p>
                                            <button type="button" title="설정" class="frHidMore">
                                                <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="버튼이미지">
                                            </button>
                                            <ul class="frHidDiv">
                                                <li><button type="button" class="frHidList">숨김해제</button></li>
                                            </ul>
                                        </li> -->

                                    </ul>
                                </div>
                            </div>
                            <!-- // 숨김친구 -->

                            <!-- 차단친구 -->
                            <div class="RightDivShare blockDiv">
                                <div class="rightConTitle">
                                    <h2>차단친구</h2>
                                    <p class="hiding_friedn_num">총 <span>{{ computedBlockFriends.length }}</span>명</p>
                                    <a class="foldBtn">접기펴기</a>
                                </div>

                                <div id="friendRightList" class="friendRightList">

                                    <ul class="friendRightUl">

                                        <li class="friendRightList" :key="friend.friend_user_id" v-for="friend in computedBlockFriends">
                                            <p class="hidingFriendImg">
                                                <img :src="make_user_profile_image_url(friend.friend_user_id)" alt="userImages" @error="imageError($event)">
                                            </p>
                                            <p class="hidingFriendName"><span>{{ getFriendName(friend) }}</span></p>
                                            <p class="hidingFriendTel"><span>{{ hodu_string_validation(friend.user_phone_number) }}</span></p>
                                            <button type="button" title="설정" class="frHidMore" @click.prevent="clickMoreOption(computedBlockFriends, friend)">
                                                <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="버튼이미지">
                                            </button>
                                            <ul class="frHidDiv" :class="{ on : friend.moreOption == true }">
                                                <li><button type="button" class="frHidList" @click.prevent="unblockFriend(friend)">차단해제</button></li>
                                            </ul>
                                        </li>

                                        <!-- <li class="friendRightList">
                                            <p class="hidingFriendImg">
                                                <img src="" alt="">
                                            </p>
                                            <p class="hidingFriendName"><span>홍길동</span></p>
                                            <p class="hidingFriendTel"><span>01023235656</span></p>
                                            <button type="button" title="설정" class="frHidMore">
                                                <img src="../../../src/assets/images/contents/ic_right_more_232848.png" alt="사진">
                                            </button>
                                            <ul class="frHidDiv">
                                                <li><button type="button" class="frHidList">차단해제</button></li>
                                            </ul>
                                        </li> -->

                                    </ul>
                                </div>
                            </div>
                            <!-- //차단친구 -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonSelectFriendAndGroupTeamModalInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class Friend extends Mixins(VueHoduCommon) {

    get computedFriends() : any[] {

        let computed_friends : any[]  = JSON.parse(JSON.stringify(this.friends));
        
        if( this.search_query.trim().length > 0 ) {
            computed_friends = computed_friends.filter(friend => this.hodu_string_includes(this.getFriendName(friend), this.search_query) ||
                                                                 (friend.user_phone_number != null && this.hodu_string_includes(friend.user_phone_number, this.search_query)) ||
                                                                 (friend.user_email != null && this.hodu_string_includes(friend.user_email, this.search_query)) );
        }

        computed_friends = computed_friends.filter(friend => friend.is_hide == false && friend.is_block == false);

        return computed_friends.sort((o1, o2) : number => {

            // 3일이내 신규등록된 친구
            const o1_dday = this.getDateDiff(new Date(), new Date(o1.audit_created));
            const o2_dday = this.getDateDiff(new Date(), new Date(o2.audit_created));

            /// 둘다 3일 이내
            if( o1_dday < 3 && o2_dday < 3 ) {
                if( o1_dday < o2_dday ) return -1;
                else if( o1_dday > o2_dday ) return 1;
                return 0;
            }

            // 한쪽만 3일이내
            if( o1_dday >= 3 && o2_dday < 3 ) {
                return 1;
            }

            // 한쪽만 3일이내
            if( o1_dday < 3 && o2_dday >= 3 ) {
                return -1;
            }

            let o1_name = this.getFriendName(o1);
            let o2_name = this.getFriendName(o2);

            if( o1_name < o2_name ) return -1;
            else if( o1_name > o2_name ) return 1;

            return 0;
        });
    }

    get computedHideFriends() : any[] {

        let computed_friends : any[]  = JSON.parse(JSON.stringify(this.friends));

        computed_friends = computed_friends.filter(friend => friend.is_hide == true);

        return computed_friends.sort((o1, o2) : number => {

            let o1_name = this.getFriendName(o1);
            let o2_name = this.getFriendName(o2);

            if( o1_name < o2_name ) return -1;
            else if( o1_name > o2_name ) return 1;

            return 0;
        });
    }

    get computedBlockFriends() : any[] {

        let computed_friends : any[]  = JSON.parse(JSON.stringify(this.friends));

        computed_friends = computed_friends.filter(friend => friend.is_block == true);

        return computed_friends.sort((o1, o2) : number => {

            let o1_name = this.getFriendName(o1);
            let o2_name = this.getFriendName(o2);

            if( o1_name < o2_name ) return -1;
            else if( o1_name > o2_name ) return 1;

            return 0;
        });
    }

    /**
     * @ModalInfo.state
     */
    @ModalInfo.State common_select_friend_and_group_team_modal_info !: CommonSelectFriendAndGroupTeamModalInfo;

    is_search_open : boolean = false;
    search_query : string = "";
    is_close_hide_list : boolean = false;

    mounted() : void {
        //옵션 클릭 친구 리스트랑 같은 설정부분입니다
        // $('.frHidMore').click(function(){
        //     $(this).toggleClass('on');   
        //     $(this).siblings('ul').toggle();         
        // })

        // //메뉴 on/off
        // $('.config_btn').off("click").on("click", function(){
        // 	if($(".config_btn, .friendRightCon, .leftScroll").hasClass("on")){
        //         $(".config_btn, .friendRightCon, .leftScroll").removeClass("on");
        //     } else {
        //         $(".config_btn, .friendRightCon, .leftScroll").addClass("on");
        //     }
        // });

        // 서브폴더 열기
        $(".RightDivShare .foldBtn").click(function(){
            $(this).parent().parent().toggleClass("on");
        });

        this.getFriends(true);
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const setting_height = $('.grp.settingBg').outerHeight();
        const sort_header_height = $('.content .sortHeader').outerHeight();

        const friend_list_height = window.innerHeight - (title_height ? title_height : 0)
                                                - (setting_height ? setting_height : 0)
                                                - (sort_header_height ? sort_header_height : 0);

        const right_friend_list_height = window.innerHeight - (title_height ? title_height : 0)
                                                            - (setting_height ? setting_height : 0);

        // @ts-ignore
        $('#friendList').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : friend_list_height,
        });

        // @ts-ignore
        $('#friend_right_list').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : right_friend_list_height,
        });
        
    }

    /**
     * 설정 버튼 클릭
     */
    clickMoreOption(friends : any[], target_friend : any) : void {
        try {

            let current_flag = friends[friends.indexOf(target_friend)].moreOption;
            if( current_flag == null ) current_flag = false;

            if( current_flag == true ) {
                friends[friends.indexOf(target_friend)].moreOption = false;
                return;
            }
            
            for( const friend of friends ) {
                friend.moreOption = false;
            }

            friends[friends.indexOf(target_friend)].moreOption = true;

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        } finally {
            this.$forceUpdate();
        }
    }

    /**
     * 이름 변경
     */
    changeName(friend : any) : void {

        const friend_name = this.getFriendName(friend);

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "친구 이름 변경",
            subtitle : "친구 이름",
            placeholder : "친구 이름을 입력해주세요",
            content : friend_name,
            save : async(friend_custom_nickname) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                        "friend_custom_nickname" : friend_custom_nickname
                    });

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("친구 이름 변경 중 오류 발생");
                    }
                    
                    this.getFriends(true);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "친구 이름 변경 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
                
            }
        });

    } 

    /**
     * 친구 숨기기
     */
    async hideFriend(friend) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                "is_hide" : true
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("친구 숨기는 중 오류 발생");
            }
            
            this.getFriends(true);

        } catch(e) {
            this.hodu_show_dialog("cancel", "친구 숨기는 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구 숨기기 해제
     */
    async showFriend(friend) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                "is_hide" : false
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("친구 숨김 해제 중 오류 발생");
            }
            
            this.getFriends(true);

        } catch(e) {
            this.hodu_show_dialog("cancel", "친구 숨김 해제 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구 차단
     */
     async blockFriend(friend) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                "is_block" : true
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("친구 숨기는 중 오류 발생");
            }
            
            this.getFriends(true);

        } catch(e) {
            this.hodu_show_dialog("cancel", "친구 숨기는 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구 차단해제
     */
     async unblockFriend(friend) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                "is_block" : false
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("친구 숨기는 중 오류 발생");
            }
            
            this.getFriends(true);

        } catch(e) {
            this.hodu_show_dialog("cancel", "친구 숨기는 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구 추가 모달
     */
    showFriendAddModal() : void {

        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            "show_modal" : true,
            "title" : "친구추가",
            "use_share_option" : false,
            "use_user_select_only" : true,
            "use_group_select_only" : true,
            "use_need_user_email" : false,
            "callback" : async(selected_info) => {
                console.log(selected_info);

                try {

                    const body = { "friend_user_ids" : selected_info.selected_user_ids };

                    const response = await this.hodu_api_call(`/api/v1/friend`, API_METHOD.POST, body);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("친구 추가 중 오류 발생");
                    }

                    this.getFriends();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "친구 추가 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#friendList, #friend_right_list').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
</script>

<style scoped>
    a#inviteMate:hover { background-size: 60px !important; }
    .settingBg h3.selectTitle .num { padding: 0 5px; color: #477fff; }
    #friend p.btn_group { height: 65px; position: absolute; top: -4px; right: 81px; float: none !important; }
    .settingBg .btn_group a { display: inline-block; }
    
    #friend .content p { float: left; font-weight: bold;  box-sizing: border-box; line-height: 55px; }
    #friend .content .num { width: 10%; text-align: center; text-indent: -10px; }
    #friend .content .userName { width: 29%; padding-left: 30px; }
    #friend .content .tel { width: 28%; padding-left: 0; }
    #friend .content .email { width: 33%; padding-left: 0; }

    #friendUl { min-height: 720px; }
    #friendUl .friendUser { width: 100%; float: left; position: relative; box-sizing: border-box; white-space: nowrap; text-overflow: ellipsis; transition: .2s; }
    #friendUl .friendUser:hover { background: #f6f9fb; }
    #friendUl .friendUser:hover .frOpMore { display: block; }
    #friendUl .friendUser p { border-bottom: 1px solid #f1f3f5; }
    #friendUl .friendUser p.userName { position: relative; padding-left: 70px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #friendUl .friendUser img, .friendRightUl .hidingFriendImg img { width: 37px; height: 37px; border-radius: 50%; box-sizing: border-box; position: absolute; top: 8px; left: 0; font-size: 0; border: 2px solid #477fff; margin-left: 20px; vertical-align: middle; }
    #friendUl .friendUser .frOpMore { display: none; width: 35px; height: 35px; position: absolute; top: 10px; right: 20px; border-radius: 50%; background: none; border: none; cursor: pointer; transition: .2s; }
    #friendUl .friendUser .frOpMore.on { display: block; }
    #friendUl .friendUser .frOpMore.on img { opacity: 1; }
    #friendUl .friendUser .frOpMore img { width: 20px; height: 20px; margin-left: 0; position: static; border: none; transform: rotate(90deg); opacity: 0.6; }
    #friendUl .frOpDiv { display: none; position: absolute; top: 40px; right: 40px; width: 120px; height: auto; box-sizing: border-box; overflow: hidden; background: #fff; box-shadow: 0px 5px 10px rgb(0 0 0 / 10%); border-radius: 5px; transition: .2s; z-index: 10; }
    #friendUl .frOpDiv.on { display: block; }
    #friendUl .frOpDiv li:hover .frOpList { background: #f1f3f5; font-weight: bold; } 
    #friendUl .frOpDiv .frOpList { width: 100%; text-align: left; font-size: 12px; cursor: pointer; padding: 10px 15px; background: #fff; border: none; }

    /* 숨김친구 추가 */
    #friend #friendDiv { position: relative; }
    #friend #friendDiv .leftScroll { box-sizing: border-box; width: 100%; padding-right: 380px; }
    #friend #friendDiv .leftScroll.on { padding-right: 0; }
    #friend #friendDiv .leftScroll.on .left_content { position: relative; }
    #friend #friendDiv .rightScroll { width: 380px; box-sizing: border-box; position: absolute; right: 0; top: 0; }
    #friend #friendDiv .rightScroll .titleDiv { line-height: 65px; height: 65px; position: relative; border-bottom: 1px solid #e7e9ea; box-sizing: border-box; overflow: hidden; padding: 0 25px; }
    #friend #friendDiv .rightScroll .titleDiv .title { line-height: 65px; font-weight: bold; font-size: 14px; }
    #friend #friendDiv .rightScroll .titleDiv a.config_btn.on { position: absolute; margin-right: 20px; display: inline-block; width: 30px; height: 30px; background: url('../../assets/images/contents/bt_hambuger.png') no-repeat center center; background-size: 22px 22px; box-sizing: border-box; right: 13px; top: 16px; transition: .2s; }
    #friend #friendDiv .rightScroll .titleDiv a.config_btn { position: absolute; margin-right: 20px; display: inline-block; width: 30px; height: 30px; background: url('../../assets/images/contents/ic_x_new.png') no-repeat center center; background-size: 22px 22px; border-radius: 50%; background-color: #f1f3f5; box-sizing: border-box; right: 12px; top: 15px; transition: .2s; }
    #friend #friendDiv .rightScroll .titleDiv a.config_btn:hover { background-color: #e5e7ea; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon { height: 800px; background: #fff; border-left: 1px solid #e7e9ea; transition: .2s; }
/* 고정 height값때문에 임시로 height auto 지정했음 나중에 뺄 것 */
    .friendRightCon .RightDivShare { height: auto; border-bottom: 2px solid #e7e9ea; }
    .friendRightCon .RightDivShare.on:last-child { border-bottom: none; }
    .friendRightCon .RightDivShare #friendRightList { display: none; }
/* css 작업 으로 height: auto !important; 강제 적용 나중에 뺄 것 */
    .friendRightCon .RightDivShare.on #friendRightList { display: block; height: auto !important; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl { height: 100%; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList { position: relative; height: 55px; text-overflow: ellipsis; white-space: nowrap; padding: 0 25px;box-sizing: border-box; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList:hover { border-bottom: 1px solid #e7e9ea; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList p { margin-left: 15px; line-height: 55px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList .hidingFriendImg { width: 37px; height: 37px; display: inline-block; background: #f1f3f5; border-radius: 50%; vertical-align: middle; margin-top: 9px; margin-left: -6px; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList .frHidMore { display: none; width: 35px; height: 35px; position: absolute; top: 10px; right: 20px; border-radius: 50%; background: none; border: none; cursor: pointer; transition: .2s; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList:hover .frHidMore { display: block; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList .frHidMore img { width: 20px; height: 20px; margin-left: 0; position: static; border: none; transform: rotate(90deg); opacity: 0.6; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList .frHidDiv { display: none; position: absolute; top: 40px; right: 40px; width: 120px; height: auto; box-sizing: border-box; overflow: hidden; background: #fff; box-shadow: 0px 5px 10px rgb(0 0 0 / 10%); border-radius: 5px; transition: .2s; z-index: 99999; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList .frHidDiv.on { display: block; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList .frHidDiv .frHidList { width: 100%; text-align: left; font-size: 12px; cursor: pointer; padding: 10px 15px; background: #fff; border: none;  }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList .frHidDiv li:hover .frHidList { background: #f1f3f5; font-weight: bold; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon.on { display: none; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .rightConTitle { padding: 0 25px; display: flex; align-items: center; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .rightConTitle > h2 { display: inline-block; font-size: 14px; margin-right: 10px; }
    #friend #friendDiv .rightScroll .friend_right .friendRightCon .rightConTitle .hiding_friedn_num { flex-grow: 1; }
    .RightDivShare .rightConTitle .foldBtn { font-size: 0; z-index: 10000; opacity: 1; transition: 0.2s; width: 60px; height: 65px; background-color: transparent; border: none; border-radius: 0; box-sizing: border-box; background-image: url('../../assets/images/side_area/bt_open.png'); background-size: 26px; background-repeat: no-repeat; background-position: center center; }
    .RightDivShare.on .rightConTitle .foldBtn { background-image: url('../../assets/images/side_area/bt_close.png'); }

    /* new 친구 추가 표시 */
    #friendUl .friendUser.newFriendUser p.userName:before { content: ''; width: 10px; height: 10px; background: #ff6363; position: absolute; top: 10px; left: 47px; border-radius: 50%; z-index: 9999; }
    /* 차단 친구 리스트 opacity */
    .blockDiv .friendRightUl p { opacity: .7; }

    /* #friend #friendDiv .rightScroll .friend_right .friendRightCon .friendRightUl .friendRightList:last-child { margin-bottom: 100px; } */
    .friendRightCon .RightDivShare:last-child { margin-bottom: 100px; }
</style>