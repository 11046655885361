<template>
    <div id="kids_attendance_list_day" class="section_scroll">
        <div class="viewGroup">
            <div class="schedule_box">
                <div class="grp settingBg">
                    <div class="posRel">
                        <div class="bg">
                            <div class=" left">
                                <input type="button" id="attendance-list-day-current-date" :value="date_string">
                                <input type="button" id="btnPrev" value="" class="toolbar-btn bt-prev mr10" @click.prevent="handlePrev">
                                <input type="button" id="btnNext" value="" class="toolbar-btn bt-next mr10" @click.prevent="handleNext">
                                <input type="button" id="btnToday" value="오늘" class="toolbar-btn today" @click.prevent="handleToday">
                                <!-- <input type="button" id="sbt" class="toolbar-btn savebt rt" value="저장"> -->
                            </div>

                            <a href="#" class="refresh" @click.prevent="refresh"></a>
                            <!-- <div class="grp3 fr">
                                <ul>
                                    <li>
                                        <span class="clrBar ylw">노랑</span>
                                        미체크
                                    </li>
                                    <li>
                                        <span class="clrBar red">빨강</span>
                                        지각
                                    </li>
                                    <li>
                                        <span class="clrBar gray">회색</span>
                                        결근
                                    </li>
                                    <li>
                                        <div class="clrDiv">
                                            <span class="clrBar blue">파랑</span>
                                            <span class="clrBar black">검정</span>
                                            <span class="clrBar green">초록</span>
                                        </div>
                                        연차/반차
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div id="mem_div" class="content">
                <div class="leftScroll">

                    <ul class="sortHeader">

                        <li class="num"><span>No</span></li>

                        <li class="name headerSort" :class="{
                            sortOff  : sort_type != 'USER_NAME',
                            sortUp   : sort_type == 'USER_NAME' && sort_direction == 'ASC', 
                            sortDown : sort_type == 'USER_NAME' && sort_direction == 'DESC', 
                        }" @click.prevent="sort(attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.USER_NAME)">
                            <span>이름</span>
                        </li>

                        <li class="grpTeam headerSort" :class="{
                            sortOff  : sort_type != 'DEPARTMENT_NAME',
                            sortUp   : sort_type == 'DEPARTMENT_NAME' && sort_direction == 'ASC', 
                            sortDown : sort_type == 'DEPARTMENT_NAME' && sort_direction == 'DESC', 
                        }" @click.prevent="sort(attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.DEPARTMENT_NAME)">
                            <span>반</span>
                        </li>

                        <li class="humCon">
                            <span>상태</span>
                        </li>

                        <li class="start headerSort" :class="{
                            sortOff  : sort_type != 'START',
                            sortUp   : sort_type == 'START' && sort_direction == 'ASC', 
                            sortDown : sort_type == 'START' && sort_direction == 'DESC', 
                        }" @click.prevent="sort(attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.START)">
                            <span>등원</span>
                        </li>

                        <li class="getOff headerSort" :class="{
                            sortOff  : sort_type != 'END',
                            sortUp   : sort_type == 'END' && sort_direction == 'ASC', 
                            sortDown : sort_type == 'END' && sort_direction == 'DESC', 
                        }" @click.prevent="sort(attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.END)">
                            <span>하원</span>
                        </li>
                    </ul>

                    <div id="day_attend_member_list_scroll">
                        <ul class="memUl">


                            <!-- <li :key="attend.user_id" v-for="(attend, index) in computedAttendList" @click.prevent="showAttendanceRequestModal(attend)"> -->
                            <li :key="attend.user_id" v-for="(attend, index) in computedAttendList">
                                <p class="num">{{ index + 1 }}</p>
                                <p class="name">
                                    {{ attend.member_name }}
                                </p>
                                <p class="grpTeam">{{ attend.team_name ? attend.team_name : '-' }}</p>
                                <p class="humCon">
                                    <span :title="attend.attend_type == 'ABSENT' ? attend.attend_remark : ''">{{ getStatusText(attend) }}</span>
                                </p>

                                <p class="start">
                                    <span class="notChecked" v-if="attend.attend_type != 'ABSENT' && Number(attend.work_cnt) != 0 && !attend.start_time">?</span>
                                    {{ attend.attend_type == 'ABSENT' ? '결석' : attend.start_time ? getTimeText(attend.start_time) : '미체크' }}
                                </p>

                                <p class="getOff">
                                    <span class="notChecked" v-if="attend.attend_type != 'ABSENT' && !attend.end_time && date_string != hodu_date_to_format_string(new Date(), 'YYYY.MM.DD')">?</span>
                                    {{ attend.attend_type == 'ABSENT' ? '결석' : attend.end_time ? getTimeText(attend.end_time) : (date_string == hodu_date_to_format_string(new Date(), 'YYYY.MM.DD') ? '-' : '미체크') }}
                                </p>
                
                            </li>

                        </ul>
                    </div>
                </div>

                <div class="rightScroll" v-if="total_attend != null">

                    <div class="titleDiv">
                        <p class="title">
                            전체<span>{{ Number(total_attend.total_cnt) }}</span>
                        </p>
                    </div>

                    <div id="day_attend_summary_scroll" class="descriptDiv scroll">
                        <ul class="descriptUl">
                            <li>
                                <p class="title">정상</p>
                                <p class="total">{{ Number(total_attend.normal_cnt) }}</p>
                            </li>
                            <li>
                                <p class="title">결석</p>
                                <p class="total red">{{ Number(total_attend.absent_cnt) }}</p>
                            </li>
                            <li>
                                <p class="title">미체크</p>
                                <p class="total">{{ Number(total_attend.unchecked_cnt) }}</p>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');
const ModalInfo = namespace('ModalInfo');

import moment from 'moment';
import { attendance_enum, attendance_modal_interface } from '@/model/attendance';

@Component({
    components: {
        
    },
})
export default class KidsAttendanceListDay extends Mixins(VueHoduCommon) {

    attendance_enum : any = attendance_enum;

    @Prop() event_bus !: Vue;

    /**
     * 정렬된 리스트
     */
    get computedAttendList() : any[] {
        const computed_list : any[] = JSON.parse(JSON.stringify(this.attend_list));

        computed_list.sort((o1, o2) : number => {

            const o1_name = o1.member_name;
            const o2_name = o2.member_name;
            if( this.sort_type == attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.USER_NAME ) {

                if( o1_name == o2_name ) return 0;
                else if( o1_name > o2_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.DEPARTMENT_NAME ) {

                const o1_team_name = o1.team_name;
                const o2_team_name = o2.team_name;

                if( o1_team_name == null || o2_team_name == null ) { 
                    if( o1_team_name == null && o2_team_name == null ) {
                        if( o1_name == o2_name ) return 0;
                        else if( o1_name > o2_name ) return  1;
                        else if( o1_name < o2_name ) return -1;
                    }
                    else if( o1_team_name == null ) return  1;
                    else if( o2_team_name == null ) return -1;
                }

                if( o1_team_name == o2_team_name ) {
                    if( o1_name == o2_name ) return 0;
                    else if( o1_name > o2_name ) return  1;
                    else if( o1_name < o2_name ) return -1;
                }
                else if( o1_team_name > o2_team_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_team_name < o2_team_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.START ) {

                const o1_start = o1.start_time;
                const o2_start = o2.start_time;

                if( o1_start == null || o2_start == null ) { 
                    if( o1_start == null && o2_start == null ) {
                        if( o1_name == o2_name ) return 0;
                        else if( o1_name > o2_name ) return  1;
                        else if( o1_name < o2_name ) return -1;
                    }
                    else if( o1_start == null ) return  1;
                    else if( o2_start == null ) return -1;
                }

                if( o1_start == o2_start ) {
                    if( o1_name == o2_name ) return 0;
                    else if( o1_name > o2_name ) return  1;
                    else if( o1_name < o2_name ) return -1;
                }
                else if( o1_start > o2_start ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_start < o2_start ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.END ) {
                
                const o1_start = o1.start_time;
                const o2_start = o2.start_time;
                const o1_end = o1.end_time;
                const o2_end = o2.end_time;

                if( o1_end == null || o2_end == null ) { 
                    if( o1_end == null && o2_end == null ) {
                        
                        if( o1_start == null || o2_start == null ) { 
                            if( o1_start == null && o2_start == null ) {
                                if( o1_name == o2_name ) return 0;
                                else if( o1_name > o2_name ) return  1;
                                else if( o1_name < o2_name ) return -1;
                            }
                            else if( o1_start == null ) return  1;
                            else if( o2_start == null ) return -1;
                        }

                        if( o1_start == o2_start ) {
                            if( o1_name == o2_name ) return 0;
                            else if( o1_name > o2_name ) return  1;
                            else if( o1_name < o2_name ) return -1;
                        }
                        else if( o1_start > o2_start ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                        else if( o1_start < o2_start ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;

                    }
                    else if( o1_end == null ) return  1;
                    else if( o2_end == null ) return -1;
                }

                if( o1_end == o2_end ) {
                    if( o1_name == o2_name ) return 0;
                    else if( o1_name > o2_name ) return  1;
                    else if( o1_name < o2_name ) return -1;
                }
                else if( o1_end > o2_end ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_end < o2_end ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }
            
            return 0;
        });
        
        return computed_list;
    }

    /**
     * @CalendarInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @CalendarInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAttendanceRequestModalInfo ?: (params : attendance_modal_interface.AttendanceRequestModalInfo) => void;

    date_string : string = "";

    attend_list : any[] = [];
    total_attend : any = null;

    sort_type : attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE = attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.START;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    mounted() : void {
        this.event_bus.$on('listDayResize', this.handleResize);

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');
        this.datepickerInit();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const schedule_box_height = $('.schedule_box').outerHeight();
        const sort_header_height = $('.sortHeader').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                 - (schedule_box_height ? schedule_box_height : 0)
                                                 - (sort_header_height ? sort_header_height : 0);

        // @ts-ignore
        $('#day_attend_member_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });

        // @ts-ignore
        $('#day_attend_summary_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * 데이터 조회
     */
    @Watch('date_string')
    async getEmpAttendDataDayInfo() : Promise<void> {

        try {
            const date_string = this.date_string.replaceAll(/\./ig, "");

            const response = await this.hodu_api_call(`api/v1/kids/attend/data/day?group_id=${this.scope_group_id}&date_string=${date_string}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_day_count_info || !response.data.data.attend_emp_day_data_info ) {
                throw new Error("일일 근태현황 데이터 조회 중 오류 발생");
            }

            this.total_attend = JSON.parse(JSON.stringify(response.data.data.attend_emp_day_count_info));
            
            this.attend_list.splice(0, this.attend_list.length);
            this.attend_list = this.attend_list.concat(response.data.data.attend_emp_day_data_info);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "데이터 조회 중 오류 발생", ['확인']);
        }

    }

    /**
     * 새로고침
     */
    async refresh() : Promise<void> {
        await this.hodu_show_indicator();
        await this.getEmpAttendDataDayInfo();
        await this.hodu_hide_indicator();
    }

    /**
     * 날짜 선택
     */
    datepickerInit() : void {

        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const date_moment = moment(dateText);
                
                vue.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
                vue.doSetSelectedDate(date_moment.toDate());
                vue.date_string = moment(vue.selected_date).format('YYYY.MM.DD');

                // @ts-ignore
                $('#datepicker').datepicker('setDate', vue.selected_date);
            },
        };

        // @ts-ignore
        $('#attendance-list-day-current-date').datepicker(option);
    }

    /**
     * 이전 날짜로 이동
     */
    async handlePrev() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.set('date', date_moment.get('date') - 1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 다음 날짜로 이동
     */
    async handleNext() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.set('date', date_moment.get('date') + 1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 오늘 날짜로 이동
     */
    async handleToday() : Promise<void> {
        
        const date_moment = moment();

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date());
    }

    /**
     * 달력 이동 해야 되는 날짜가 바뀐 것을 감지
     */
    @Watch('go_to_date')
    onDateChange() : void {
        const date_moment = moment(this.go_to_date);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');
    }

    /**
     * 시간 텍스트 가져오기
     */
    getTimeText(time_text : string) : string {
        if( time_text == null || time_text.length < 6 ) return '';
        const time = [time_text.substring(0,2), time_text.substring(2,4), time_text.substring(4,6)].join(':');
        const target_date = moment(moment(this.selected_date).format('YYYY-MM-DD') + ` ${time}`).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    } 

    /**
     * 정렬
     */
    sort(sort_type : attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 오늘 여부
     */
    isToday() : boolean {
        return this.date_string == moment().format('YYYY.MM.DD');
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;

        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 상태값 텍스트 반환
     */
    getStatusText(attend) : string {

        switch( attend.attend_type ) {
            case "NORMAL": return '정상';
            case "ABSENT": return '결석';
        }

        return "-";
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#day_attend_member_list_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#day_attend_summary_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }
}
</script>

<style scoped>
    .grp3 input[type="button"] { margin-top: 13px; margin-right: 25px; }
    .grp3 ul li { display: inline-block; margin-left: 15px; height: 66px; line-height: 66px }
    .grp3 ul li .clrBar { float: left; display: inline-block; font-size: 0; width: 20px; height: 3px;background:#f1f3f5; margin-top: 30px; margin-right: 10px; }
    .grp3 ul { margin-right: 25px; }
    .grp3 ul li .clrBar.ylw { background:#ffa70e;}
    .grp3 ul li .clrBar.blue { background:#447fff; }
    .grp3 ul li .clrBar.red { background:#ff6363; }
    .grp3 ul li .clrBar.green { background:#17c788; }
    .grp3 ul li .clrBar.black { background:#3f5c82; }
    .grp3 ul li .clrDiv {  float: left; width: 20px; margin-top: 23px; margin-right: 10px; }
    .grp3 ul li .clrDiv .clrBar { display: block; margin-top: 3px; }
    .settingBg > .posRel > .bg { position: static; }
    .settingBg > .posRel { z-index: 100; }

    .settingBg .left  { margin-top: 0;  min-width: auto !important; width: auto !important }
    .settingBg .left #attendance-list-day-current-date {float: left;line-height: 61px;font-size: 19px;color: #202A39;cursor: default;margin-right: 15px !important; font-weight: bold; min-width: auto !important; width: 102px !important; background : none; cursor : pointer; }


    .memUl li { position: relative; transition:0.2s; overflow: hidden; width: 100%; line-height: 60px; height: 60px; box-sizing:border-box; border-bottom: 1px solid #f1f3f5; color:#202A39; }
    .memUl li:hover { background:#f7f9fb; font-weight: bold; }
    .memUl li > * { font-weight: bold; float: left; display: inline-block; padding-right: 15px;box-sizing:border-box; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;}
    
    .memUl li:hover a { width: 15px; }
    .memUl li:hover a:hover { width: 35px; background-color:#ff3f3f; }
    .memUl li a { transition:0.1s; position: absolute; right: 0;top:0; width: 0; padding: 0; height: 100%; font-size:0; background-color: #ff6060; }
    .memUl li .notChecked { background: #ffbc49;border: 1px solid #ffa70e; display:inline-block; width: 20px; height: 20px;  line-height: 20px; border-radius: 50%; text-align: center; font-weight: bold; margin-right: 10px;}
    .section_ce_fix .num { width: 15%; text-align: center; }
    .section_ce_fix .memUl .num { padding-right: 0; }
    .section_ce_fix .name { width: 15%; }
    .section_ce_fix .grpTeam { width: 15%; }
    .section_ce_fix .humCon{ width: 19%; }
    .section_ce_fix .humCon.lay{ width: 50%;}
    .section_ce_fix .start { width: 18%; }
    .section_ce_fix .getOff { width: 18%; }
    .memUl .totalLi span { color:#477fff; }
    .memUl .totalLi .none { opacity: 0.3;    color: #202A39;  }
    .memUl .name .img { display: inline-block; float: left; width: 33px; height: 33px; border:1px solid #f1f3f5; border-radius: 50%; margin-top: 11px; margin-right: 10px;  font-size: 0; background-position: center center; background-size: cover; background-repeat: no-repeat; background-color:#fff; }
    .section_ce_fix .grpTeam .clr { display: inline-block; width: 9px; height: 9px; border-radius: 50%; font-size: 0; margin-top:25px; margin-right: 10px; float: left; }
    
    /* .memUl li:nth-child(1) .img { background-image: url('../../assets/images/footer/f1.jpg'); }
    .memUl li:nth-child(2) .img { background-image: url('../../assets/images/footer/f2.jpg'); }
    .memUl li:nth-child(3) .img { background-image: url('../../assets/images/footer/f3.jpg'); }
    .memUl li:nth-child(4) .img { background-image: url('../../assets/images/footer/f4.jpg'); }
    .memUl li:nth-child(5) .img { background-image: url('../../assets/images/footer/f5.jpg'); }
    .memUl li:nth-child(6) .img { background-image: url('../../assets/images/footer/f6.jpg'); }
    .memUl li:nth-child(7) .img { background-image: url('../../assets/images/footer/f7.jpg'); } */

    .content { position: relative; height: 900px }
    .leftScroll { box-sizing:border-box; width: 100%; padding-right: 14%; }
    .leftScroll .titleDiv { width:100%; line-height: 60px; height: 60px; box-sizing:border-box; border-bottom: 1px solid #e7e9ea; overflow: hidden; padding: 0 25px; }
    .leftScroll .titleDiv .title { font-weight: bold; font-size: 14px; }
    .leftScroll .titleDiv input { float: right; margin-top: 9px }
    .leftScroll .titleDiv * {display: inline-block; }

    .rightScroll { height: 100%; border-left: 1px solid #e7e9ea; width: 14%; box-sizing:border-box; position: absolute;right:0; top:0; }
    .rightScroll .titleDiv { line-height: 60px; height: 60px; border-bottom: 1px solid #e7e9ea; overflow: hidden; padding: 0 25px; }
    .rightScroll .titleDiv .title { font-weight: bold; font-size: 14px; width: 100%; }
    .rightScroll .titleDiv .title span { float: right; }
    .rightScroll .titleDiv input { float: right; margin-top: 9px}
    .rightScroll .titleDiv * {display: inline-block; }
    
    .descriptUl li { position: relative; line-height: 55px; height: 55px; padding:0 25px; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; }
    .descriptUl li:hover { background:#f7f9fb; }
    .descriptUl li .total { font-weight: bold; float: right }
    .descriptUl li .total.none { opacity: 0.3 }
    .descriptUl li .total.red { color:#ff6363; }
    .descriptUl li * {  display: inline-block; }
    .descriptUl li a {position: absolute; left: 0; top:0; width: 100%; height: 100%; font-size: 0; }
    .descriptUl li .title {width: 40%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}

    img.dummy { display : none; }
    .memUl .name .img.no-image { background-image : url('../../assets/images/contents/im_photoB.gif') !important; }

    .toolbar-button-savebt{border:none;background:none;font-size:13px; color:#202A39; font-weight:bold;}
    .rt{position: absolute; right: 14%;}
    .toolbar-btn.savebt{
        font-size: 14px;
        font-weight:bold;
        line-height: 40px;
        padding: 0 13px;
        background-color:#ffffff !important;
        border-radius: 5px;
        margin-left: 10px;
        -moz-border-radius : 5px;
        -webkit-border-radius : 5px;
        -ms-border-radius: 5px;
        border: 1px solid #e9e9e9;}
    .toolbar-btn.savebt.rt:hover { background-color: #477fff !important; color:#fff; }
    .humCon_s{ margin-left: 10%; color:red;}

</style>