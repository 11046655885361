<template>
    <div id="organization_vacation_type" class="dis member_set_3">

        <div class="bt_scheduleBox" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
            <input type="button" id="btnAddVacationType" class="bt_schedule" @click="add"/>
        </div>

        <ul class="sortHeader groupNoticeHeader">
            <li class="num" style="padding-left : 20px; box-sizing: border-box;">No</li>
            <li class="title" :class="{ notPermitted : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') }"><span style="margin-left:20px;">이름</span></li>
            <li class="day"><span style="margin-left:20px;">일수</span></li>
            <li class="pay"><span style="margin-left:20px;">유급 / 무급</span></li>
            <li class="button" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"><span style="margin-left:40px;">버튼</span></li>
        </ul>

        <div id="organization_vacation_type_scroll">
            <ul class="organization_vacation_type_ul">

                <li :key="vacation_type.vacation_type_id" v-for="(vacation_type, index) in vacation_type_list">
                    <p class="num">{{ index + 1 }}</p>
                    <p class="title" :class="{ notPermitted : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') }">{{ vacation_type.vacation_type_name }}</p>
                    <p class="day">{{ processDayCount(vacation_type.vacation_type_daycount) == '0' ? '직접입력' : processDayCount(vacation_type.vacation_type_daycount) }}</p>
                    <p class="pay">{{ vacation_type.vacation_dedution_yn == true ? '유급' : '무급' }}</p>
                    <p class="button" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                        <!-- <a href="#" class="edit" @click.prevent="edit(vacation_type)">수정</a> -->
                        <a href="#" class="delete" @click.prevent="deleteVacationType(vacation_type)">삭제</a>
                    </p>
                </li>

                <!-- <li>
                    <p class="num">1</p>
                    <p class="title">연차</p>
                    <p class="day">1</p>
                    <p class="pay">유급</p>
                    <p class="button">
                        <a href="#" class="edit">수정</a>
                        <a href="#" class="delete">삭제</a>
                    </p>
                </li>

                <li>
                    <p class="num">2</p>
                    <p class="title">반차</p>
                    <p class="day">0.5</p>
                    <p class="pay">유급</p>
                    <p class="button">
                        <a href="#" class="edit">수정</a>
                        <a href="#" class="delete">삭제</a>
                    </p>
                </li>

                <li>
                    <p class="num">3</p>
                    <p class="title">반반차</p>
                    <p class="day">0.25</p>
                    <p class="pay">유급</p>
                    <p class="button">
                        <a href="#" class="edit">수정</a>
                        <a href="#" class="delete">삭제</a>
                    </p>
                </li>

                <li>
                    <p class="num">4</p>
                    <p class="title">COVID-19 self-isolation</p>
                    <p class="day">14</p>
                    <p class="pay">무급</p>
                    <p class="button">
                        <a href="#" class="edit">수정</a>
                        <a href="#" class="delete">삭제</a>
                    </p>
                </li> -->

            </ul>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationVacationTypeSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationTypeModalInfo  ?: (params : organization_modal_interface.OrganizationVacationTypeModalInfo) => void;

    vacation_type_list : any[] = [];

    mounted() : void {
        this.event_bus?.$on("OrganizationVacationTypeResize", this.handleResize);
        this.getOrganizationVacationTypeInfo();
        this.setScroll();    
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const title2_height = $('.title_box2').outerHeight();
        const sort_header_height = $('#organization_vacation_type .sortHeader').outerHeight();

        // @ts-ignore
        $('#organization_vacation_type_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) 
                                           - (title2_height ? title2_height : 0) 
                                           - (sort_header_height ? sort_header_height : 0),
        });
    }

    /**
     * 휴가타입 조회
     */
    async getOrganizationVacationTypeInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.vacation_type_info ) {
                throw new Error("휴가타입 조회 중 오류 발생")
            }

            this.vacation_type_list.splice(0, this.vacation_type_list.length);
            this.vacation_type_list = this.vacation_type_list.concat(response.data.data.vacation_type_info);
            this.vacation_type_list.sort((o1, o2) : number => {
                if( o1.vacation_type_id > o2.vacation_type_id ) return 1;
                else return -1;
            });

        } catch(e) {
            this.hodu_show_dialog('cancel', '휴가타입 조회 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 추가
     */
    add() : void {
        this.doSetOrganizationVacationTypeModalInfo?.({
            show_modal : true,
            is_create : true,
            callback : this.getOrganizationVacationTypeInfo
        })
    }

    /**
     * 수정
     */
    edit(vacation_type : any) : void {
        this.doSetOrganizationVacationTypeModalInfo?.({
            show_modal : true,
            is_create : false,
            vacation_type_id : vacation_type.vacation_type_id,
            title : vacation_type.vacation_type_name,
            day : Number(this.processDayCount(vacation_type.vacation_type_daycount)),
            pay : vacation_type.vacation_dedution_yn,
            callback : this.getOrganizationVacationTypeInfo
        })
    }

    /**
     * 삭제
     */
    deleteVacationType(vacation_type : any) : void {
        this.hodu_show_dialog('cancel', `정말로 삭제하시겠습니까?\n'${vacation_type.vacation_type_name}'`, ['아니오', '예'], [
            () => {},
            async() => {

                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype/${vacation_type.vacation_type_id}`, API_METHOD.DELETE);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("휴가타입 삭제 중 오류 발생");
                    }

                    this.getOrganizationVacationTypeInfo();
                    
                } catch(e) {
                    this.hodu_show_dialog('cancel', '휴가타입 삭제 중 오류 발생', ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }

            }
        ])
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#organization_vacation_type_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
</script>

<style scoped>
    #organization_vacation_type .num { width : 10%; text-align: center; font-weight: bold;}
    #organization_vacation_type .title { width : 40%; font-weight: bold;}
    #organization_vacation_type .title.notPermitted { width : 60%; font-weight: bold;}
    #organization_vacation_type .day { width : 15%; font-weight: bold;}
    #organization_vacation_type .pay { width : 15%; font-weight: bold;}
    #organization_vacation_type .button { width : 20%; font-weight: bold;}
    
    #organization_vacation_type .button .edit { color : #477fff; }
    #organization_vacation_type .button .delete { color : #FF6363; margin-left : 20px; }

    #organization_vacation_type .organization_vacation_type_ul > li {-webkit-box-sizing: border-box;box-sizing: border-box;position: relative;/* width: 100%; */border-bottom: 1px solid #f1f3f5;height: 70px;line-height: 70px;}
    #organization_vacation_type .organization_vacation_type_ul > li > p {display: inline-block;font-weight: bold;-webkit-box-sizing: border-box;box-sizing: border-box;padding-left: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-size: 14px;}
</style>