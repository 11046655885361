<template>
    <div id="delete_data">
        <!-- 단계 프로그레스바 -->
        <div class="progress-container">
            <div class="progress" id="progress"></div>
            <div class="circle" :class="{ active : step == 1 }">1</div>
            <div class="circle" :class="{ active : step == 2 }">2</div>
            <div class="circle" :class="{ active : step == 3 }">3</div>
        </div>

        <!-- 로그인화면 -->
        <div class="delete_content login_content" v-if="step == 1">
            <div class="title_top">
                <h1 id="logo">
                    <img src="../assets/images/ware/hodu_logo.png" alt="호두로고">
                </h1>
                <p>MANAGE AND SHARE YOUR LIFE</p>
            </div>

            <div class="login_tap">
                <ul>
                    <li :class="{ active : password_login_mode == 'email' }" @click.prevent="changePasswordLoginMode('email')">이메일 로그인</li>
                    <li :class="{ active : password_login_mode == 'phone' }" @click.prevent="changePasswordLoginMode('phone')">휴대폰 번호 로그인</li>
                </ul>
            </div>
            <div class="login_accnt">
                <ul class="default_accnt" v-if="password_login_mode == 'email'">
                    <li>
                        <input type="text" placeholder="아이디 (이메일 주소)" id="ware_userId" class="ware_userId ware_input_txt" :value="id" @input="id = $event.target.value" @keydown="idKeyDown">
                        <input type="password" placeholder="비밀번호" id="ware_userPwd" class="ware_userPwd ware_input_txt" :value="pw" @input="pw = $event.target.value" @keydown="pwKeyDown">
                        <a href="#" class="move_btn login_btn" @click.prevent="login">로그인</a>
                    </li>
                </ul>
                <ul class="default_accnt" v-if="password_login_mode == 'phone'">
                    <li>
                        <input type="number" placeholder="휴대폰 번호 ('-'제외)" id="ware_userId" class="ware_userId ware_input_txt" :value="id" @input="id = $event.target.value" @keydown="idKeyDown">
                        <input type="password" placeholder="비밀번호" id="ware_userPwd" class="ware_userPwd ware_input_txt" :value="pw" @input="pw = $event.target.value" @keydown="pwKeyDown">
                        <a href="#" class="move_btn login_btn" @click.prevent="login">로그인</a>
                    </li>
                </ul>
            </div>
            
            <div class="ware_accntBtn_title">
                <h2>다른 서비스 계정으로 로그인</h2>
            </div>
            <div class="another_account">
                <div class="another_account_div">
                    <div class="other_services kakao_logIn_box">
                        <a href="#" class="kakao_logIn_btn" @click.prevent="kakaoLogin"></a>
                    </div>
                    <div class="other_services google_logIn_box">
                        <a href="#" class="google_logIn_btn" @click.prevent="googleLogin"></a>
                    </div>
                    <div class="other_services apple_logIn_box">
                        <a href="#" class="apple_logIn_btn" @click.prevent="appleLogin"></a>
                        <vue-apple-signin color="black" :border="true" :radius="10" type="sign in" v-show="false"></vue-apple-signin>
                    </div>
                </div>
            </div>


            <p class="this_screen">Google Play의 앱 데이터 삭제 로그인</p>
        </div>

        <!-- 요청화면 -->
        <div class="delete_content del_request_content" v-if="step == 2">
            <div class="title_top">
                <h1 id="logo">
                    <img src="../assets/images/ware/hodu_logo.png" alt="호두로고">
                </h1>
                <p>MANAGE AND SHARE YOUR LIFE</p>
            </div>

            <div class="user_infor">
                <ul>
                    <li>
                        <p class="facePic" :style="{ 'background-image' : `url(${make_user_profile_image_url(user_id)})`}">이미지</p>
                        <img class="dummy" :src="make_user_profile_image_url(user_id)" @error="userImageError($event)"/>
                    </li>
                    
                    <li>
                        <p class="user_name">{{ user_name }}</p>
                        <p>
                            <span class="connect_ic" :class="{
                                'hodu' : user_type == 'EMAIL' || user_type == 'PHONE' ,
                                'kakao' : user_type == 'KAKAOTALK',
                                'google' : user_type == 'GOOGLE',
                                'apple' : user_type == 'APPLE',
                            }">
                                로그인아이콘
                            </span>
                            로그인 정보
                        </p>
                    </li>
                </ul>
            </div>
            <div class="del_request_select">
                <ul class="del_select_ul">
                    <li>
                        <div class="del_radio_div">   
                            <p>
                                계정 삭제
                                <span>계정 삭제 시 일정, 그룹&팀 등의 모든 데이터를 삭제합니다</span>
                            </p>
                            <input type="radio" id="del-select-check-1" name="del_select_option" value="all_delete" v-model="delete_type">
                            <label for="del-select-check-1" class="del_select_option"></label>
                        </div>             
                    </li>
                    <li>
                        <div class="del_radio_div">   
                            <p>
                                모든 일정 삭제
                                <span>모든 종류의 일정(업무일지, 회의록 등)을 전부 삭제합니다</span>
                            </p>
                            <input type="radio" id="del-select-check-2" name="del_select_option" value="event_delete" v-model="delete_type">
                            <label for="del-select-check-2" class="del_select_option"></label>
                        </div>             
                    </li>
                    <li>
                        <div class="del_radio_div">   
                            <p>
                                모든 그룹&팀 탈퇴 및 삭제
                                <span>가입된 모든 그룹&팀의 데이터를 전부 삭제합니다</span>
                            </p>
                            <input type="radio" id="del-select-check-3" name="del_select_option" value="group_team_delete" v-model="delete_type">
                            <label for="del-select-check-3" class="del_select_option"></label>
                        </div>             
                    </li>
                </ul>
                <a href="#" class="move_btn login_btn" @click.prevent="requestDeleteData">삭제 요청</a>
            </div>

            <p class="this_screen">Google Play의 앱 데이터 삭제 요청</p>
        </div>

        <!-- 완료화면 -->
        <div class="delete_content complete_content" v-if="step == 3">
            <div class="title_top">
                <h1 id="logo">
                    <img src="../assets/images/ware/hodu_logo.png" alt="호두로고">
                </h1>
                <p>MANAGE AND SHARE YOUR LIFE</p>
            </div>

            <div class="complete_div">
                <img src="../assets/images/contents/img_success.png" alt="데이터 삭제성공">
                <p>데이터 삭제 처리가 완료되었습니다</p>
            </div>

            <p class="this_screen">Google Play의 앱 데이터 삭제 완료</p>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, getBrowserInfo, getOSInfo } from '@/mixin/VueHoduCommon';

declare let Kakao : any;
import '@/assets/js/kakao'

import { hodu_local_storage } from '@/lib/HoduLocalStorage';
import { hodu_cookie } from '@/lib/HoduCookie';

@Component({
    components: {
        
    },
})
export default class DeleteData extends Mixins(VueHoduCommon) {

    step : number = 1;

    id : string = ""; // 아이디 변수 (:value + @input)
    pw : string = ""; // 패스워드 변수 (:value + @input)
    password_login_mode : string = "email";

    login_type : string = "";

    delete_type : string = "";

    async mounted() {
        // $('.login_tap ul li').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.login_accnt > ul').hide();
        // 	$('.login_accnt > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // })

        if( !Kakao.isInitialized() ){ await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }

        this.appleLoginEventInit();
    }

    /**
     * 비밀번호 로그인 모드 변경
     */
    changePasswordLoginMode(mode : string) : void {
        this.password_login_mode = mode;
        this.id = "";
        this.pw = "";
    }

    /**
     * 로그인
     */
    async login() {

        const vue = this;
        const loginSuccessCallback = vue.loginSuccessCallback;
        const loginFailCallback    = vue.loginFailCallback;
        
        if( this.id == null || this.id.trim() == ""){
            alert(`${ this.password_login_mode == 'email' ? '이메일을' : '휴대폰번호를' } 입력 해주세요`);
            return;
        }

        // 이메일로그인 모드 && 개발 아이디가 아니라면 이메일 형식 체크
        if( this.password_login_mode == 'email' && !( this.id.trim() == "3" || this.id.trim() == "4" || this.id.trim() == "5" || this.id.trim() == "6" || this.id.trim() == "7" ) ) {
            // 이메일 형식 체크
            if(! /\S+@\S+\.\S+/.test(this.id.trim()) ){
                alert('이메일 형식을 확인해주세요');
                return;
            }
        }    

        if( this.pw == null || this.pw.trim() == ""){
            alert('패스워드를 입력 해주세요');
            return;
        }

        this.login_type = this.password_login_mode == 'email' ? 'EMAIL' : 'PHONE';
        await this.hodu_api_call(`/api/v1/auth/login/${this.password_login_mode == 'email' ? 'EMAIL' : 'PHONE'}`, API_METHOD.POST, {
            sns_id        : this.id.trim(),
            password      : this.pw.trim(),
            app_ver       : this.getVersion(),
            device_os     : "WEB",
            device_os_ver : await getBrowserInfo(),
            device_model  : await getOSInfo(),
            device_token  : ""
        })
        .then((response) => loginSuccessCallback(response))
        .catch((e)=> loginFailCallback(e));

    }

    async kakaoLogin() {
        
        const access_token = Kakao.Auth.getAccessToken();

        /**
         * access_token 있다면
         */
        if( access_token ) {
            this.login_type = 'KAKAOTALK';
            await this.hodu_api_call(`/api/v1/auth/login/KAKAOTALK`, API_METHOD.POST, {
                app_ver                : this.getVersion(),
                device_os              : "WEB",
                device_os_ver          : await getBrowserInfo(),
                device_model           : await getOSInfo(),
                kakaotalk_access_token : access_token,
                device_token           : ""
            }) 
            .then((response) => this.loginSuccessCallback(response))
            .catch((e) => this.kakaoLoginForm()); // 실패시 LoginForm
        }
        else {
            this.kakaoLoginForm();
        }
    }

    async kakaoLoginForm() {

        const vue = this;

        await Kakao.Auth.loginForm({
            success: async(authObj) => {
                vue.login_type = 'KAKAOTALK';
                await vue.hodu_api_call(`/api/v1/auth/login/KAKAOTALK`, API_METHOD.POST, {
                    app_ver                : vue.getVersion(),
                    device_os              : "WEB",
                    device_os_ver          : await getBrowserInfo(),
                    device_model           : await getOSInfo(),
                    kakaotalk_access_token : authObj.access_token,
                    device_token           : ""
                }) 
                .then((response) => vue.loginSuccessCallback(response))
                .catch((e) => vue.loginFailCallback(e));
            },
            fail: async(e) => vue.loginFailCallback(e)
        });

    }

    async googleLogin() {
        window['gapi'].load('auth2', async() => {
            const auth2 = window['gapi'].auth2.init({
                client_id: '48808503160-k10pbn6thi0ko0easfuid8e4lq4afta9.apps.googleusercontent.com',
            });

            console.log(auth2);

            auth2.grantOfflineAccess({ scope: 'profile email', prompt: 'select_account' }).then(async(result) => {
                console.log(result);
                const access_code = result.code;

                try {
                
                    this.login_type = 'GOOGLE';
                    await this.hodu_api_call(`/api/v1/auth/login/GOOGLE`, API_METHOD.POST, {
                        sns_id        : "",
                        app_ver       : this.getVersion(),
                        device_os     : "WEB",
                        device_os_ver : await getBrowserInfo(),
                        device_model  : await getOSInfo(),
                        device_token  : "",
                        google_code   : access_code
                    }) 
                    .then((response) => this.loginSuccessCallback(response))
                    .catch((e) => this.loginFailCallback(e)); // 실패시 LoginForm

                } catch(e) {
                    alert("구글 계정 로그인 중 오류 발생");
                    this.hodu_error_process(e, false, false, true);
                }

            });
        });
    }

    async appleLogin() {
        $('#appleid-signin').trigger('click');
    }

    /**
     * 애플 계정 로그인 관련 이벤트
     */
    appleLoginEventInit() {
        
        // meta 태그 redirect-uri 변경
        document.getElementById('appleid-signin-redirect-uri')?.setAttribute('content', `${location.protocol}//${location.hostname}/api/v1/apple`);

        document.addEventListener('AppleIDSignInOnSuccess', async(data) => {

            console.log(data);

            if( !data || !data['detail'] || !data['detail']['authorization'] ) {
                alert("애플 계정 로그인 중 오류 발생");
                return;
            }

            const code = data['detail']['authorization']['code'];
            const id_token = data['detail']['authorization']['id_token'];
            const state = data['detail']['authorization']['state'];

            try {

                this.login_type = 'APPLE';
                const response = await this.hodu_api_call('api/v1/auth/login/APPLE', API_METHOD.POST, { 
                    app_ver                : this.getVersion(),
                    device_os              : "WEB",
                    device_os_ver          : await getBrowserInfo(),
                    device_model           : await getOSInfo(),
                    sns_id                 : id_token,
                    apple_access_token     : code,
                    device_token           : "" 
                });

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("애플 계정 로그인 중 오류 발생");
                }
                
                console.log(response);
                this.loginSuccessCallback(response);

            } catch(e) {
                this.loginFailCallback(e);
            }

        });

        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            console.log(error);

            if( error && (error as any).detail && (error as any).detail.error ) {

                if( (error as any).detail.error == "popup_closed_by_user" || (error as any).detail.error == "user_cancelled_authorize" ) {
                    return;
                } 

            }

            alert("애플 계정 로그인 중 오류 발생");
        });
    }

    async loginSuccessCallback(response) {
        
        sessionStorage.setItem("session_token", response.data.data.session_token);
        hodu_local_storage.setItem("session_token", response.data.data.session_token);
        hodu_local_storage.setItem("kakao_login_flag", String(this.login_type == "KAKAOTALK"));
        hodu_cookie.set("session_token", response.data.data.session_token);

        this.doLogin({
            "isLogin" : false, // 로그인 이후 다른 페이지 이동 하면 안됨
            "user_id" : response.data.data.user.user_id,
            "user_type" : this.login_type,
            "user_email" : response.data.data.user.user_email,
            "user_name" : response.data.data.user.user_name,
            "user_phone_number" : response.data.data.user.user_phone_number,
            "country_code" : response.data.data.user.country_code,
            "is_temp_password" : response.data.data.is_temp_password,
            "user_preference" : response.data.data.user_preference,
            "user_group_role" : response.data.data.group_role,
            "user_team_role" : response.data.data.team_role,
            "template_map" : response.data.data.template_map,
            "auth_info" : response.data.data.user.auth_info,
        });

        this.step = 2;
    }

    async loginFailCallback(e) {
        this.hodu_error_process(e, false, false, true);
        alert("로그인 실패\n잠시 후 다시 시도해주세요");
    }
 
    /**
     * 아이디에서 엔터시 로그인시도
     */
    idKeyDown(event) {
        if( event.keyCode != 13 ) {
            return;
        }

        this.login();
    }
    
    /**
     * 비밀번호에서 엔터시 로그인시도
     */
    pwKeyDown(event) {
        if( event.keyCode != 13 ) {
            return;
        }

        this.login();
    }

    requestDeleteData() {

        if( (this.delete_type?.length ?? 0) < 1 ) {
            alert("삭제할 데이터를 선택해주세요");
            return;
        }

        if( this.delete_type == 'all_delete' ) {
            if( confirm("정말 계정 및 모든 데이터를 삭제 하시겠습니까?") == false ) return;
            this.allDelete();
            return;
        }

        if( this.delete_type == 'event_delete' ) {
            if( confirm("정말 모든 일정 데이터를 삭제 하시겠습니까?") == false ) return;
            this.eventDelete();
            return;
        }

        if( this.delete_type == 'group_team_delete' ) {
            if( confirm("정말 모든 그룹&팀 데이터를 삭제 하시겠습니까?") == false ) return;
            this.groupTeamDelete();
            return;
        }

    }

    async allDelete() {
        try {
            const response = await this.hodu_api_call(`api/v1/delete_data/all_delete`, API_METHOD.POST);

            if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("계정 삭제 실패");
            }

            this.step = 3;

        } catch(e) {
            alert("계정 삭제 중 오류 발생");
        }
    }

    async eventDelete() {

        try {
            const response = await this.hodu_api_call(`api/v1/delete_data/event_delete`, API_METHOD.POST);

            if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("일정 삭제 실패");
            }

            this.step = 3;

        } catch(e) {
            alert("일정 삭제 중 오류 발생");
        }

    }

    async groupTeamDelete() {

        try {
            const response = await this.hodu_api_call(`api/v1/delete_data/group_team_delete`, API_METHOD.POST);

            if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("그룹&팀 데이터 삭제 실패");
            }

            this.step = 3;

        } catch(e) {
            alert("그룹&팀 데이터 삭제 중 오류 발생");
        }

    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_target = $(event.target);
        const defaultImage = require('@/assets/images/contents/ic_approval_user.png');
        jquery_target.parent().find('p.facePic').css("background-image", `url(${defaultImage})`);
    }
    
}
</script>

<style scoped>
    #delete_data { width: 100%; max-width: 400px; min-width: 300px; height: 600px; box-shadow: 0 3px 20px rgba(71,127,255,.2); border-radius: 5px; display: inline-block; position: relative; top: 100px; padding: 30px 40px; box-sizing: border-box; }

    /* 프로그레스바 */
    .progress-container { display: flex; justify-content: space-between; position: relative; margin-bottom: 25px; z-index: 0; box-sizing: border-box; }
    .progress-container::before { content: ''; background-color: #e0e0e0; position: absolute; top: 50%; left: 0; transform: translateY(-50%); height: 4px; width: 100%; z-index: -1; }
    .progress { background-color: #477fff; position: absolute; top: 50%; left: 0; transform: translateY(-50%); height: 4px; width: 0%; z-index: -1; transition: 0.4s ease; }
    .circle { background-color: #fff; color: #999; border-radius: 50%; height: 40px; width: 40px; display: flex; align-items: center; justify-content: center; border: 3px solid #e0e0e0; transition: 0.4s ease; box-sizing: border-box; }
    .circle.active { border-color: #477fff; }

    /* 공통 */
    .title_top { text-align: center; color: #477fff; font-weight: bold; line-height: 25px; font-size: 11px; }
    .title_top h1 { cursor: default; }
    .title_top img { width: 90px; height: auto; }
    .move_btn { width: 80%; height: 45px; line-height: 45px; margin: 20px auto 10px auto; display: block; background: #477fff; border-radius: 8px; transition: .2s; color: #fff; font-size: 13px; text-align: center; }
    .move_btn:hover { background: #3371ff; }
    input, select { vertical-align: middle; border: none; outline: none; font-family: noto sans,Sans-serif; font-size: 13px; }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    /* Firefox  */
    input[type='number'] {
    -moz-appearance: textfield;
    }
    .del_radio_div input[type='radio']:not(old)+label { background: url('../assets/images/contents/ra_check_off_03.png')no-repeat center; height: 20px; }
    .del_radio_div input[type='radio']:not(old):checked+label { background: url('../assets/images/contents/ic_radio_on.png') no-repeat center; background-size: 21px 21px; }
    p.this_screen { text-align: center; color: #999; line-height: 20px; position: absolute; bottom: 32px; left: 50%; margin-left: -85px; }
    /* ///////////////////////////////////////////////////////////////////////////////////공통 */

    /* 로그인 */
    .login_tap { margin: 10px 0 20px 0; }
    .login_tap ul { display: flex; text-align: center; }
    .login_tap li { width: 50%; height: 50px; line-height: 50px; box-sizing: border-box; font-size: 13px; font-weight: bold; border-bottom: 2px solid #f1f3f5; cursor: pointer; }
    .login_tap li.active { border-bottom: 2px solid #477fff; color: #477fff; }
    input.ware_input_txt { display: block; width: 95%; border: 1px solid #e3e7ed; height: 40px; line-height: 40px; -webkit-box-sizing: border-box; box-sizing: border-box; padding: 0 15px; font-weight: 700; font-size: 12px; -webkit-transition: .2s; transition: .2s; margin: 0 auto 10px auto; }
    
    .ware_accntBtn_title { margin-top: 30px; }
    .ware_accntBtn_title h2 { width: 100%; height: 30px; line-height: 30px; opacity: .5; display: flex; justify-content: space-around; align-items: center; }
    .ware_accntBtn_title h2:before,
    .ware_accntBtn_title h2:after
    { content: ""; width: 22%; height: 2px; background: #a9aab2; }
    .another_account { padding-top: 1cqw; margin-bottom: 20px; }
    .another_account_div { width: 100%; display: flex; box-sizing: border-box; justify-content: center; gap: 32px; }
    .other_services { width: 50px; height: 50px; border-radius: 30px; box-sizing: border-box; }
    .other_services.kakao_logIn_box { background: #ffe500; }
    .other_services.google_logIn_box { background: #fff; border: 1px solid #e3e7ed; }
    .other_services.apple_logIn_box { background: #000; }
    .other_services a { width: 50px; height: 50px; background-size: 35px auto; background-repeat: no-repeat; background-position: center; display: block; }
    .other_services a.kakao_logIn_btn { background-image: url('../assets/images/contents/kakao_logo.svg'); }
    .other_services a.google_logIn_btn { background-image: url('../assets/images/contents/google_logo.svg'); }
    .other_services a.apple_logIn_btn { background-image: url('../assets/images/contents/apple_white_logo.svg'); }

    /* 삭제요청 */
    .user_infor { margin-top: 25px; }
    .user_infor ul { display: flex; gap: 15px; align-items: center; }
    .facePic { width: 45px; height: 45px; border: 2px solid #a9aab2; border-radius: 30px; box-sizing: border-box; font-size: 0; background-repeat: no-repeat; background-position: center; background-size: cover; }
    .user_infor p { line-height: 23px; }
    .user_infor p.user_name { font-size: 13px; font-weight: bold; }
    .user_infor p .connect_ic { width: 18px; height: 18px; display: inline-block; border: 1px solid #f1f3f5; border-radius: 30px; font-size: 0; vertical-align: middle; margin-right: 3px; margin-top: -3px; box-sizing: border-box; }
    .user_infor p .connect_ic.hodu { background: url('../assets/images/ware/hodu_logo.png') no-repeat center center; background-size: 14px auto; }
    .user_infor p .connect_ic.kakao { background: url('../assets/images/contents/kakao_logo.svg') no-repeat center center; background-size: 14px auto; }
    .user_infor p .connect_ic.google { background: url('../assets/images/contents/google_logo.svg') no-repeat center center; background-size: 14px auto; }
    .user_infor p .connect_ic.apple { background: url('../assets/images/contents/apple_black_logo.png') no-repeat center center; background-size: 14px auto; }

    .del_request_select { padding-top: 25px; box-sizing: border-box; }
    .del_radio_div { display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px; }
    .del_radio_div p { font-weight: bold; line-height: 20px; }
    .del_radio_div span { display: block; font-size: 12px; opacity: .6; font-weight: normal; }

    /* 요청완료 */
    .complete_div { text-align: center; box-sizing: border-box; line-height: 40px; font-size: 13px; font-weight: bold; display: block; margin-top: 100px; }
</style>