<template>
    <div class="userListModal modal peepsOn">
        
        <!-- 상단 영역 시작 -->
        <div class="titleBox">
            <h1 class="borderbot">{{ biz_type == 'BIZD' ? '병원' : '아파트' }} 선택</h1>
        </div>
        <!-- 상단 영역 끝 -->

        <!-- 내용 영역 시작 -->
        <div class="modal_content">

            <!-- 멤버 헤더 -->
            <ul class="sortHeader teamHeader">
                <li class="num">No</li>

                <li class="pNm">
                    <span>{{ biz_type == 'BIZD' ? '병원' : '아파트' }}</span>
                </li>
            </ul>

        </div>
        <!-- 내용 영역 끝 -->

        <!-- 멤버 -->
        <div class="peepsDiv on">
            <div class="peepsUlDiv">
                <ul class="peeps" v-if="login_hospital_select_modal_info != null">
                    
                    <li :key="hospital_index" v-for="(hospital, hospital_index) in login_hospital_select_modal_info.hospital_list" @click="selectHospital(hospital.biz_id)">
                        <p class="num">{{ hospital_index + 1 }}</p>
                        <p class="fPic" :style="{ backgroundImage : `url(${hospital != null && hospital.hospital_info != null && hospital.hospital_info.images != null && hospital.hospital_info.images.length > 0 ? `app_images/${hospital.hospital_info.images[0].url}` : default_hospital_image })` }">멤버 사진</p>
                        <!-- <img class="fPicDummy" style="display : none;" :src="default_hospital_image" @error="userImageError($event)" /> -->
                        <p class="pNm">{{ hospital.group_name }}</p>
                    </li>

                </ul>
            </div>
        </div>

        <!-- 멤버 끝 -->
        <div class="btns b1">
            <input type="button" class="input_btn" value="닫기" @click="clickCloseButton">
        </div>

    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupNoticeReadInfo, LoginHospitalModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {

    }
}) export default class LoginHospitalSelectModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State login_hospital_select_modal_info !: LoginHospitalModalInfo

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetLoginHospitalModalInfo ?: (param : LoginHospitalModalInfo) => void;

    default_hospital_image : string = require("@/assets/images/contents/imgDc7.png");

    biz_type = 'BIZD';

    mounted() : void {
        if( this.login_hospital_select_modal_info.hospital_list.length > 0 ) {
            this.biz_type = this.login_hospital_select_modal_info.hospital_list[0].biz_type;
        }
    }

    getText() : string {
        switch(this.biz_type) {
            case "BIZD": return '병원';
            case "BIZH": return '아파트';
            case "KIDS": return '호두키즈';
            default: return '';
        }
    }

    /**
     * 선택한 병원으로 이동
     */
    selectHospital(biz_id : string) {
        if ( this.login_hospital_select_modal_info.callback == null ) { 
            return;
        }
        this.login_hospital_select_modal_info.callback(biz_id)
        this.login_hospital_select_modal_info.show_modal = false
    }

    /**
     * 확인 버튼 클릭
     */
    clickCloseButton() : void {
        if ( this.doSetLoginHospitalModalInfo ) {
            this.doSetLoginHospitalModalInfo({
                show_modal    : false,
                hospital_list : []
            })
        }
    }

}

</script>

<style scoped>
    /* 모달 공통 css */
    .modal { position:absolute; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}
    
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}
    .titleBox .total { float:left; font-size:14px; font-weight:bold; margin-left:15px;margin-top:2px; }
    .titleBox .total span { color:#477fff; padding-left:5px;}
    .titleBox .total.team {  }
    .modal .btns {border-top:1px solid #f1f3f5; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; }
    .modal .btns.b1 input { width:100% }

    .modal .schBtn { position:absolute; right:0;top:20px;right:30px; }
    .modal .schGrp {display:none;transition:0.1s;position: absolute;top: 110px;z-index: 100000000;width: 100%;height: 60px;background: white;top: 89px;left: 0;}
    .modal .schGrp.on { display:block; }
    .modal .schGrp input {transition:0.1s;background:transparent;width: 100%;box-sizing: border-box;height: 100%;line-height: 60px;padding: 0 100px 0 30px;font-size: 14px;font-weight: bold;}
    .modal .schGrp:hover { background:#f1f3f5; }
    .modal .schGrp input:focus { background:#fff;border-bottom:1px solid #477fff; height:60px;line-height:61px; }
    .modal .schGrp a.schBtn { top:0px; }

    .modal .noResult { display:none; position: absolute; z-index: 1000000;top: 150px;height: 316px;background: #fff;line-height: 316px;font-weight: bold;font-size: 21px;color: #afb6c3;}

    /* 공유모달 css */
    .userListModal .sortHeader { position:relative; }
    .sortHeader li.grpCheck { position: absolute;left: 0;top: 0;width: 100px;text-align: center;font-weight: bold;font-size: 13px;}
    .userListModal { transition:0.3s; max-width: 800px; bottom:auto !important; margin-top: -268px !important;height: 536px !important;}
    .shareUlDiv {height: auto;display: block;width: 100%;height: 316px;max-height: 316px;overflow:hidden; overflow-y:auto;}
    .shareUlDiv ul {display: block;width:100%;}
    .shareUlDiv li .grp {width: 100%;transition:0.1s;height: 55px;line-height: 55px;border-bottom: 1px solid #f1f3f5; position:relative;}
    .shareUlDiv li .grp:hover { background:#f1f3f5; }
    .shareUlDiv li .grp * {display: inline-block;float:left;}
    .shareUlDiv li .grp:hover .count a { border:2px solid #477fff; border-radius: 5px; padding:0 10px; line-height:30px; height:30px;margin-top:11px; }
    .shareUlDiv li .grp .count.noCount { opacity:0.6 }
    .shareUlDiv li .count a { transition:0.1s; }
    .shareUlDiv li .count a:hover { background:#477fff; color:#fff; }
    .shareUlDiv li .count a:hover span { color:#fff; }
    .shareUlDiv li .img {width: 57px;font-size: 0; background-position:center center !important; background-size: cover !important;height: 42px;border-radius: 5px;border: 1px solid #f1f3f5;position: absolute;left: 180px;top: 7px;}
    .shareUlDiv .teamUl li  .img {width: 50px;height: 36px;top: 9px;left: 186px;}
    .shareUlDiv li h2 {font-size: 14px;}
    .shareUlDiv li .nm { width:64%;box-sizing:border-box; padding-left:120px; padding-right:20px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
    .shareUlDiv li .count { width:10%;font-weight:bold; }
    .shareUlDiv li .count span { color:#477fff; margin-right:4px; }
    .shareUlDiv li.withTeams a.showTeams {position: absolute;right: 20px;top: 0;font-size: 0;width: 35px;height: 60px;border-radius: 50%; background:  url('../../../assets/images/side_area/bt_open.png') no-repeat center center;}
    .shareUlDiv li a.showTeams.on, .shareUlDiv li.on a.showTeams {background:  url('../../../assets/images/side_area/bt_close.png') no-repeat center center;  }
    .shareUlDiv .teamUl { display:none; border-top:1px solid #f1f3f5;border-bottom:1px solid #f1f3f5;}
    .shareUlDiv li.on > .grp {  }
    .shareUlDiv li.on .teamUl { display:block; }
    .userListModal input[type='checkbox']:not(old) + label {position: absolute;left: 38px;top: 17px;z-index:10000000; }
    .userListModal .teamUl input[type='checkbox']:not(old) + label{left: 50px;}
    .userListModal input[type='checkbox']:not(old) + label, .userListModal input[type='checkbox']:not(old) + label:hover {margin-left: 5px;display: inline-block;  padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
    .userListModal input[type='checkbox']:not(old):checked + label { border:2px solid #477fff;background: #477fff url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;}
    .grpUl > li > .grp { height:60px; line-height:60px; }
    .grpUl > li > .grp .img  { top:8px; }
    .grpUl > li > .grp:hover > .count > a { margin-top:14px; }
    .grpUl > li > .grp input[type='checkbox']:not(old) + label { top: 19px }
    .shareUlDiv li .grp .cl {position: absolute;left: 151px;top: 26px;width: 8px;height: 8px;font-size: 0;border-radius: 50%;background: #f1f3f5;}
    .shareUlDiv li .teamUl .grp .cl { left:158px; }
    .userListModal .teamUl .num {padding-left: 90px;}

    .grpHeader .num { width:15%;  }
    .grpHeader .nm {padding-left: 14px;width: 64%;box-sizing: border-box;}
    .grpHeader .count {width: 19%;}

    .teamHeader { display:none; }
    .userListModal.peepsOn ul.grpUl {height: 447px;}
    .userListModal.peepsOn { margin-top: -268px !important; height: 536px !important;}
    .userListModal.peepsOn .teamHeader { display:block; }
    .userListModal.peepsOn .grpHeader { display:none; }
    .userListModal .peepsDiv { display:none;width:100%;position: absolute;background: #fff;z-index: 1000000000;top: 150px; }
    .userListModal .peepsDiv.on {display: block; position: static;}
    .userListModal .peepsDiv .peepsUlDiv { width: 100%;height: 316px;max-height: 316px;overflow: hidden;overflow-y: auto;}
    .userListModal ul.peeps li * {display: inline-block;}
    .userListModal ul.peeps li {transition:0.1s;position:relative;line-height: 50px;height: 50px;font-size: 13px;font-weight: bold;border-bottom:1px solid #f1f3f5;}
    .userListModal ul.peeps li .fPic {position:absolute;background-color:#f1f3f5; left:155px; top:10px;font-size: 0;width: 40px;height: 30px;border-radius: 3px;background-position: center center !important;background-size: cover !important;border: 1px solid #e7e9ea;}
    .userListModal ul.peeps li .pNm { padding-left:80px; padding-right:20px; width:63%; box-sizing:border-box; }
    .userListModal ul.peeps li .pos { font-size:12px; }
    .userListModal ul.peeps li:hover { background:#f1f3f5; }
    .userListModal .peeps input[type='checkbox']:not(old) + label { top:14px; }
    .userListModal .peeps .fPic.none { background-image : url(../../../assets/images/contents/im_photoB.gif) !important; }

    .userListModal .pNm {  width:63%; box-sizing:border-box;    padding-left: 20px;  }
    .userListModal .num {font-weight:bold; /* padding-left: 80px; */ width: 17%; box-sizing:border-box; text-align: center;font-size:12px;}
    .userListModal .peeps input[type='checkbox']:not(old) + label:after {content: '';display: block;width: 800px;height: 50px;position: absolute;left: -45px;top: -16px;}
    
    
    .teamHeader .pos {width: 20%;}
    .sortHeader input[type='checkbox']:not(old) + label { top:19px;}

    .shareUlDiv.mCS_destroyed { overflow : hidden !important }
</style>