<template>
    <div class="leftAreaDiv" v-if="selected_patient != null">
        <!-- 환자 검색 디브 -->
        <div class="schDiv2">
            <!-- 하단 리스트에 없는 사람이 있을시 div.noResult show -->
            <input type="text" placeholder="환자 검색하기" :value="search_word" @input="search_word = $event.target.value"/>
        </div>
        <div id="patient_filebox_dir_list_scroll" class="scroll"> <!-- 스크롤 -->
            <!-- 환자없을시 보이기 -->
            <div class="noResult" :class="{ on : computedPatientList.length < 1 }">
                <p>검색된 환자가 없습니다 :(</p>
            </div>
            <!-- 환자리스트 -->
            <ul class="patientUl">

                <li :key="patient.user_id" v-for="(patient, index) in computedPatientList" :class="{ on : patient.user_id == selected_patient.user_id }" @click="selectPatientFileBox(patient)">
                    <p class="no">{{ index + 1 }}</p>
                    <p class="name">{{ patient.user_name }}</p>
                    <p class="filebox"><a title="파일함">{{ `${ patient.file_count }개` }}</a></p>
                    <a class="edit" title="자세히보기">자세히 보기</a>
                </li>

                <!-- <li class="on">
                    <p class="no">1</p>
                    <p class="name">김이정</p>
                    <p class="filebox"><a class="" title="파일함">10개</a></p>
                    <a class="edit" title="자세히보기">자세히 보기</a>
                </li>
                <li>
                    <p class="no">2</p>
                    <p class="name">김이연</p>
                    <p class="filebox"><a class="" title="파일함">0개</a></p>
                    <a class="edit" title="자세히보기">자세히 보기</a>
                </li>
                <li>
                    <p class="no">3</p>
                    <p class="name">김이석</p>
                    <p class="filebox"><a class="" title="파일함">10개</a></p>
                    <a class="edit" title="자세히보기">자세히 보기</a>
                </li>
                <li>
                    <p class="no">4</p>
                    <p class="name">김이순</p>
                    <p class="filebox"><a class="" title="파일함">10개</a></p>
                    <a class="edit" title="자세히보기">자세히 보기</a>
                </li>
                <li>
                    <p class="no">5</p>
                    <p class="name">김이혁</p>
                    <p class="filebox"><a class="" title="파일함">10개</a></p>
                    <a class="edit" title="자세히보기">자세히 보기</a>
                </li>
                <li>
                    <p class="no">6</p>
                    <p class="name">김이현</p>
                    <p class="filebox"><a class="" title="파일함">10개</a></p>
                    <a class="edit" title="자세히보기"></a>
                </li>
                <li>
                    <p class="no">7</p>
                    <p class="name">김이횬</p>
                    <p class="filebox"><a class="" title="파일함">10개</a></p>
                    <a class="edit" title="자세히보기">자세히 보기</a>
                </li> -->
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import { hodu_doc_object } from '../../model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {

    },
})
export default class PatientFileBoxDirList extends Mixins(VueHoduCommon) {

    get computedPatientList() : hodu_doc_object.patient_info[] {

        let patient_infos : hodu_doc_object.patient_info[] = [];

        if( this.patient_info != null ) {
            patient_infos = this.patient_info.filter(item => this.hodu_string_includes(item.user_name, this.search_word) == true);
        }

        return patient_infos;
    }

    @Prop() event_bus !: Vue;

    @Prop() selected_patient !: hodu_doc_object.patient_info | null;

    search_word : string = "";

    mounted() : void {
        
        if( this.event_bus != null ) { this.event_bus.$on('dirListResize', this.handleResize); }

        this.setScroll();

        // 왼쪽 환자 리스트 클릭시 파일함 변경
        // $(".patientUl li").click(function(){

        //     var getIndex = $(this).index();
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");

        //     // 예제, 아무것도 없을 시
        //     if( getIndex == 1 ) {
        //         $(".rightAreaDiv").addClass("nothing");
        //     } else {
        //         $(".rightAreaDiv").removeClass("nothing");
        //     }

        // });

    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        this.$nextTick(() => {
            const title_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const sch_div_2_height : number | undefined = $('.schDiv2').outerHeight();
            // const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

            // @ts-ignore
            $('#patient_filebox_dir_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sch_div_2_height ? sch_div_2_height : 0)
            });
        });

    }

    /**
     * 환자 파일함 선택
     */
    selectPatientFileBox(patient : hodu_doc_object.patient_info) : void {
        this.$emit('selectPatientFileBox', patient);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#patient_filebox_dir_list_scroll').mCustomScrollbar('destroy');
        this.setScroll();
    }

    /**
     * 환자 선택 변경
     */
    @Watch('selected_patient')
    changedSelectedPatient() : void {
        this.handleResize();
    }

}

</script>

<style scoped>

    .noResult { display:none; position: static; padding: 20px; }
    .noResult.on { display:block; }
    .noResult p { font-size: 14px !important; background:#f1f3f5; color:#a3a9b5; line-height: 70px;; height:70px; }
    
    .no { width: 20%; font-weight:bold; display:inline-block; text-align: center; box-sizing: border-box; }
    .name { width:45%;font-size: 12px; display:inline-block; padding-right: 20px; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
    
    .leftAreaDiv { width: 30%; float:left; height:100%; }
    .patientUl li { transition:0.2s; overflow:hidden; position: relative;box-sizing:border-box; border-bottom: 1px solid #f1f3f5; border-right: 0 solid #e7e9ea}
    .patientUl li:hover { border-right: 3px solid #e7e9ea;  }
    .patientUl li:hover, .patientUl li.on { background-color: #f7f9fb; }
    .patientUl li.on { border-right:3px solid #477fff; }
    .patientUl li:hover a.edit { opacity: 1; }
    .patientUl a.edit { transition:0.2s; opacity: 0; position: absolute; font-size: 0; display: block; width:100%; height:100%;left:0;top:0; box-sizing: border-box; }
    .patientUl a.edit:hover {}
    .patientUl p { float:left; line-height: 55px; height: 55px; font-weight:bold; font-size: 12px; }
    
    .patientUl li:hover .filebox a {background:#fff; padding:0 10px;border:1px solid #e7e9ea }
    .filebox a:hover, .patientUl li.on .filebox a { padding:0 10px; color:#fff; background:#477fff !important; border-color:#2a6bff !important }
    
    .schDiv2 { overflow: hidden; position:relative; height: 61px; line-height: 61px;box-sizing: border-box; border-top:1px solid #e7e9ea; }
    .schDiv2 input[type="text"] { transition:0.2s; float: left; font-weight:bold; width:100%; height:60px; line-height: 60px; font-size:14px; padding: 0 20px 0 55px;box-sizing: border-box;border-bottom:1px solid #e7e9ea;background: url(../../assets/images/contents/ic_search_on.png) no-repeat 20px center; }
    .schDiv2 input[type="text"]:hover { background-color:#f1f3f5; }
    .schDiv2 input[type="text"]:focus { border-color:#477fff; background-color:#fff; }
    .schDiv2 input[type="button"] { position: absolute;  left:10px; top:10px;}
    
    .filebox { width: 35%; font-size: 12px; }
    .filebox a { transition:0.2s;position:relative; z-index: 1000; display:inline-block; line-height: 25px; height:25px; border-radius: 20px;border:1px solid #fff }

</style>