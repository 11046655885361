<template>
    <div id="request_setting_scroll" class="reservation_time">
        
        <div class="reservation_time reservation_setting">

            <div class="reLineSet">
                <div class="lineSetBtn fr readyCopySet">
                    <input type="button" value="저장" class="input_btn saveSetBtn" 
                           @click.prevent="save" :disabled="isSaveable() == false">
                </div>
            </div>

            <h2>예약 시간 구간 설정</h2>
            <ul>
                <li :key="index" v-for="(time, index) in times">
                    <p>{{ index + 1 }}회차 시간</p>
                    <div class="selBox startSelectBox">
                        <span>시작 시간</span>
                        <select :id="`startTime${index}`" @change="startTimeChange($event, time)">
                            <option :key="n" :value="(n - 1) * 30" v-for="n in 48" :selected="time.time_start == getTime((n - 1) * 30, '')">
                                {{ getTime((n - 1) * 30, ':') }}
                            </option>
                        </select>
                    </div>
                    <div class="selBox endSelectBox">
                        <span>종료 시간</span>
                        <select :id="`endTime${index}`" @change="endTimeChange($event, time)">
                            <option :key="n" :value="(n * 30) < 1440 ? n * 30 : 1439" v-for="n in 48" :selected="(n * 30) < 1440 ? (time.time_end == getTime(n * 30, '')) : time.time_end == '2359'">
                                {{ getTime(n * 30, ':') }}
                            </option>
                        </select>
                    </div>
                    <a href="#" class="delBtn" @click.prevent="deleteMoveIntoTime(time)">삭제</a>
                </li>
                <!-- <li>
                    <p>1회차 시간</p>
                    <div class="selBox startSelectBox">
                        <span>시작 시간</span>
                        <select name="startTime" id="startTime">
                            <option value="1">07 : 00</option>
                            <option value="2">07 : 30</option>
                            <option value="3">08 : 00</option>
                            <option value="4">08 : 30</option>
                        </select>
                    </div>
                    <div class="selBox endSelectBox">
                        <span>종료 시간</span>
                        <select name="endTime" id="endTime">
                            <option value="1">09 : 00</option>
                            <option value="2">09 : 30</option>
                            <option value="3">10 : 00</option>
                            <option value="4">10 : 30</option>
                        </select>
                    </div>
                    <a href="#" class="delBtn">삭제</a>
                </li>
                <li>
                    <p>2회차 시간</p>
                    <div class="selBox startSelectBox">
                        <span>시작 시간</span>
                        <select name="startTime" id="startTime">
                            <option value="1">07 : 00</option>
                            <option value="2">07 : 30</option>
                            <option value="3">08 : 00</option>
                            <option value="4">08 : 30</option>
                        </select>
                    </div>
                    <div class="selBox endSelectBox">
                        <span>종료 시간</span>
                        <select name="endTime" id="endTime">
                            <option value="1">09 : 00</option>
                            <option value="2">09 : 30</option>
                            <option value="3">10 : 00</option>
                            <option value="4">10 : 30</option>
                        </select>
                    </div>
                    <a href="#" class="delBtn">삭제</a>
                </li> -->
                <!-- 회차 추가 -->
                <li class="addTimeSel">
                    <p class="add_icon">추가 이미지</p>
                    <input type="button" value="회차 추가" class="addTimeBtn" @click.prevent="add">
                </li>
            </ul>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class ResidentMoveIntoRequestTimeSetting extends Mixins(VueHoduCommon) {

    times : any[] = [];
    original_times : any[] = [];

    mounted() : void {
        this.getMoveIntoTimes();
    }

    async getMoveIntoTimes() {
        try {
            const response = await this.hodu_api_call(`api/v1/home/move_into/time?group_id=${this.scope_group_id}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.times ) {
                throw new Error("입주 예약 시간 조회 중 오류 발생");
            }

            this.times.splice(0, this.times.length);
            this.times = this.times.concat(response.data.data.times);

            this.original_times = JSON.parse(JSON.stringify(this.times));

        } catch(e) {
            this.hodu_show_dialog("cancel", "입주 예약 시간 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 저장
     */
    async save() : Promise<void> {

        const valid_list : any[] = [];
        for( const time of this.times ) {
            valid_list.push({
                "index" : this.times.indexOf(time),
                "start" : Number(time.time_start),
                "end" : Number(time.time_end)
            });
        }
        for( const time of this.times ) {
            const index = this.times.indexOf(time);
            const start = Number(time.time_start);
            const end = Number(time.time_end);

            const valid_check = valid_list.filter(valid => valid.index != index &&
                                                           ((valid.start <= start && start <= valid.end) ||
                                                           (valid.start <= end && end <= valid.end)));

            if( valid_check.length > 0 ) {
                this.hodu_show_dialog("alert", `${ index + 1 }회차 시간이 다른 회차의 시간과 겹치는 구간이 존재합니다`, ['확인']);
                return;
            }
        }

        this.hodu_show_dialog("alert", "설정 변경시 현재 예약된 입주 예약이 전부 삭제 됩니다\n정말로 설정을 변경하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {

                try {
                    
                    const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time`, API_METHOD.POST, {
                        group_id : this.scope_group_id,
                        times : this.times,
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("입주 예약 라인 설정 중 오류 발생");
                    }

                    this.hodu_show_dialog("success", '입주 예약 시간 설정 완료', ['확인'], [() => {
                        this.getMoveIntoTimes();
                    }]);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "입주 예약 시간 설정 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ]);
    }

    /**
     * 입주 예약 시간 추가
     */
    async add() {

        this.times.push({
            "time_id" : "",
            "time_seq" : this.times.length + 1,
            "group_id" : this.scope_group_id,
            "time_start" : "0000",
            "time_end" : "2359",
        });
        // try {

        //     const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time`, API_METHOD.POST, {
        //         "group_id" : this.scope_group_id,
        //         "time_start" : "0000",
        //         "time_end" : "2359",
        //     });

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("입주 예약 시간 추가 중 오류 발생");
        //     }

        //     this.getMoveIntoTimes();

        // } catch(e) {
        //     this.hodu_show_dialog("cancel", "입주 예약 시간 추가 중 오류 발생", ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // }
    }

    /**
     * 시작시간 변경
     */
    async startTimeChange(event, time) {

        const value = Number(event.target.value);
        
        const new_time = this.getTime(value, '');
        const new_end_time = this.getTime(value + 30, '');

        time.time_start = new_time;
        time.time_end = new_end_time;
        this.$forceUpdate();

        // try {

        //     // 해당 time_id 로 등록된 예약(오늘을 포함한 이후 예약)이 있다면 취소된다는 메시지가 떠야함
        //     // TODO 유효성 체크

        //     const value = Number(event.target.value);

        //     const new_time = this.getTime(value, '');
        //     const new_end_time = this.getTime(value + 30, '');

        //     console.log(`${new_time} ${new_end_time}`);

        //     const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time/${time.time_id}`, API_METHOD.PUT, {
        //         "group_id" : this.scope_group_id,
        //         "time_start" : new_time,
        //         "time_end" : new_end_time,
        //     });

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("입주 예약 시간 수정 중 오류 발생");
        //     }

        // } catch(e) {
        //     this.hodu_show_dialog("cancel", "입주 예약 시간 수정 중 오류 발생", ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // } finally {
        //     this.getMoveIntoTimes();
        // }
    }

    /**
     * 종료시간 변경
     */
    async endTimeChange(event, time) {

        const value = Number(event.target.value);
        const new_time = this.getTime(value, '');
        time.time_end = new_time;

        if( Number(time.time_start) >= Number(time.time_end) ) {
            const new_start_time = this.getTime(value - 30, '');
            time.time_start = new_start_time;
        }

        this.$forceUpdate();

        // try {

        //     // 해당 time_id 로 등록된 예약(오늘을 포함한 이후 예약)이 있다면 취소된다는 메시지가 떠야함
        //     // TODO 유효성 체크

        //     const value = Number(event.target.value);
        //     const new_time = this.getTime(value, '');

        //     const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time/${time.time_id}`, API_METHOD.PUT, {
        //         "group_id" : this.scope_group_id,
        //         "time_end" : new_time
        //     });

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("입주 예약 시간 수정 중 오류 발생");
        //     }

        // } catch(e) {
        //     this.hodu_show_dialog("cancel", "입주 예약 시간 수정 중 오류 발생", ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // } finally {
        //     this.getMoveIntoTimes();
        // }
    }

    /**
     * 예약 삭제
     */
    async deleteMoveIntoTime(time) : Promise<void> {

        this.times.splice(this.times.indexOf(time), 1);
        // try {

        //     const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time/${time.time_id}`, API_METHOD.DELETE, {
        //         "group_id" : this.scope_group_id
        //     });

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("입주 예약 시간 삭제 중 오류 발생");
        //     }

        // } catch(e) {
        //     this.hodu_show_dialog("cancel", "입주 예약 시간 삭제 중 오류 발생", ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // } finally {
        //     this.getMoveIntoTimes();
        // }
    }

    /**
     * 시간값 계산
     */
    getTime(time : number, fix : string) {

        if( time >= 1440 ) {
            return `23${fix}59`;
        }

        const hour = Math.floor(time / 60);
        const min = time % 60;

        return `${`0${hour}`.slice(-2)}${fix}${`0${min}`.slice(-2)}`;
    }

    /**
     * 저장 활성화 여부 (하나라도 달라진게 있는 경우에만)
     */
    isSaveable() : boolean {
        return JSON.stringify(this.times) != JSON.stringify(this.original_times);
    }

}
</script>

<style scoped>
    .moveinSettingBox .settingCon { position: relative; box-sizing: border-box; min-height: 795px; overflow-y: auto; }
    .moveinSettingBox .settingCon h2 { line-height: 40px; font-size: 15px; padding: 0 15px; }
    .moveinSettingBox .settingCon ul { overflow: hidden; }
    .moveinSettingBox .settingCon ul li { width: 100%; line-height: 60px; border: 1px solid #f1f3f5; border-radius: 10px; padding: 0 15px; margin-top: 20px; box-sizing: border-box; font-weight: bold; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; position: relative; }
    .moveinSettingBox .settingCon ul li p { width: 10%; position: relative; display: inline-block; color: #4f536d; }
    .moveinSettingBox .settingCon ul li p:before { content: ''; width: 2px; height: 30px; background: #a7a9b6; position: absolute; top: 16px; right: 0; }
    .moveinSettingBox .settingCon ul li .selBox { width: auto; display: inline-block; padding: 0 35px; }
    .moveinSettingBox .settingCon ul li .selBox select { font-weight: bold; transition: 0.2s; -webkit-appearance: none; -moz-appearance: none; appearance: none; border-radius: 5px; height: 30px; line-height: 30px; cursor: pointer; background-color: #fff; background-position: 95% center; background-repeat: no-repeat; box-sizing: border-box; border: 1px solid #f1f3f5; padding: 0 50px 0 10px; background-image: url('../../assets/images/side_area/bt_open.png'); margin-bottom: 3px; margin-left: 10px; } 
    .moveinSettingBox .settingCon ul li .selBox select:hover { background-color: #f1f3f5; }
    .moveinSettingBox .settingCon ul li.addTimeSel { position: relative; text-align: center; }
    .moveinSettingBox .settingCon ul li.addTimeSel:hover { background: #f1f3f5; }
    .moveinSettingBox .settingCon ul li.addTimeSel p { width: 14px; height: 14px; font-size: 0; background: url('../../assets/images/contents/ic_plus_new.png')no-repeat center; position: absolute; top: 23px; left: 50%; margin-left: -50px; opacity: .7; }
    .moveinSettingBox .settingCon ul li.addTimeSel p:before { display: none; }
    .moveinSettingBox .settingCon .addTimeBtn { cursor: pointer; margin: 0; padding: 0; width: 100%; display: block; padding: 20px 0; background: none; font-weight: bold; color: #9596a1; text-indent: 26px; transition: .2s; }

    .moveinSettingBox .settingCon .reservation_line .reLineSet { overflow: hidden; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet select { height: 40px; line-height: 40px; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn .copySetBtn { display: none; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn.readyCopySet .copySetBtn { display: inline-block; margin-right: 20px; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn .tippingSetBtn { display: none; }
    .moveinSettingBox .settingCon .reservation_line .reLineSet .lineSetBtn.tipping .tippingSetBtn { display: inline-block; margin-right: 20px; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox { display: inline-block; width: 40%; box-sizing: border-box; padding: 0 35px; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox.lineTextBox { border-right: 1px solid #f1f3f5; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox input[type=text] { width: 100%; padding: 5px; border-radius: 5px; border: 1px solid #f1f3f5; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox input#builtext { width: 40px; text-align: center; margin: 0 15px; }
    .moveinSettingBox .settingCon .reservation_line .inputTextBox p:before { display: none; }

    .moveinSettingBox .settingCon ul li a { padding: 0 15px; background: #ff6363; color: #fff; border-radius: 15px; position: absolute; top: 50%; margin-top: -13px; right: 80px; height: 25px; line-height: 25px; opacity: 0; }
    .moveinSettingBox .settingCon .reservation_line a.amendBtn { background: #477fff; right: 20px; }
    .moveinSettingBox .settingCon ul li:hover a { opacity: 1; }
    .moveinSettingBox .settingCon ul li:hover a:hover { background: #ff3a00; }
    .moveinSettingBox .settingCon ul li:hover a.amendBtn:hover { background: #004bff; }

    .reservation_setting { margin : 20px 30px; }

    .input_btn.saveSetBtn:disabled { cursor: default; }
</style>