<template>
    <div id="organization_holiday_setting" class="section_scroll">
        <div class="content">
            <div class="pubHolidayDiv">

                <ul class="sortHeader">
                    <li class="num">No</li>
                    <li class="yyyy" v-on-clickaway="dropdownOutsideClick">
                        <a href="#" class="sort" @click.prevent="() => { is_year_open = !is_year_open }">{{ selected_year }}년</a>
                        <div id="year_dropdown" class="dropdown" :class="{ on : is_year_open == true }">
                            <ul>
                                <li :key="2020 + i" v-for="i in 30">
                                    <a href="#" :class="{ on : selected_year == String(2020 + i) }" @click.prevent="() => { 
                                        selected_year = String(2020 + i);
                                        is_year_open = false;
                                    }">
                                        {{ 2020 + i }}년
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="time headerSort" :class="{
                        sortOff  : sort_type != 'DATE',
                        sortUp   : sort_type == 'DATE' && sort_dir == 'ASC', 
                        sortDown : sort_type == 'DATE' && sort_dir == 'DESC', 
                    }" @click.prevent="sort(organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE.DATE)">
                        <span>기간</span>
                    </li>

                    <li class="holidayName headerSort" :class="{
                        sortOff  : sort_type != 'NAME',
                        sortUp   : sort_type == 'NAME' && sort_dir == 'ASC', 
                        sortDown : sort_type == 'NAME' && sort_dir == 'DESC', 
                    }" @click.prevent="sort(organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE.NAME)">
                        <span>휴일명</span>
                    </li>

                    <li class="whether"><span>쉬나요?</span></li>
                </ul>

                <div id="organization_holiday_setting_scroll">
                    <ul class="holidayUl">
                        <li :key="holiday.ymd" v-for="(holiday, index) in computedHoliday">
                            <p class="num">{{ index + 1 }}</p>
                            <p class="yyyy">{{ holiday.ymd.substring(0, 4) }}년</p>
                            <p class="time"><span>{{ `${hodu_date_to_format_string(holiday.ymd, 'M월 D일')}` }}</span></p>
                            <p class="holidayName">{{ holiday.holi_name }}</p>
                            <div class="whether">
                                {{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '' : (holiday.is_rest == true ? '네' : '아니오') }}
                                <select @change="changeNotHoliday($event, holiday)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                    <option value="yes" :selected="holiday.is_rest == true">네</option>
                                    <option value="no" :selected="holiday.is_rest == false">아니오</option>
                                </select>
                            </div>
                        </li>
                        <!-- <li>
                            <p class="num">1</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>1월 1일</span></p>
                            <p class="holidayName">새해</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">2</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>1월 24일</span></p>
                            <p class="holidayName">설날 연휴</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">3</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>1월 25일</span></p>
                            <p class="holidayName">설날</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">4</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>1월 27일</span></p>
                            <p class="holidayName">대체공휴일(설날)</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">5</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>4월 15일</span></p>
                            <p class="holidayName">국회의원 선거</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">6</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>4월 30일</span></p>
                            <p class="holidayName">석가탄신일</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">7</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>5월 5일</span></p>
                            <p class="holidayName">어린이날</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">8</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>6월 6일</span></p>
                            <p class="holidayName">현충일</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">9</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>8월 15일</span></p>
                            <p class="holidayName">광복절</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">10</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>9월 30일</span></p>
                            <p class="holidayName">추석 연휴</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">11</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>10월 1일</span></p>
                            <p class="holidayName">추석</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">12</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>10월 2일</span></p>
                            <p class="holidayName">추석 연휴</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">13</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>10월 3일</span></p>
                            <p class="holidayName">개천절</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">14</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>10월 9일</span></p>
                            <p class="holidayName">한글날</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li>
                        <li>
                            <p class="num">15</p>
                            <p class="yyyy">2020년</p>
                            <p class="time"><span>12월 25일</span></p>
                            <p class="holidayName">성탄절</p>
                            <div class="whether">
                                <select>
                                    <option value="yes">네</option>
                                    <option value="no">아니오</option>
                                </select>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';
import moment from 'moment';
import { organization_enum } from '@/model/organization';

import { directive as onClickaway } from 'vue-clickaway';

@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        
    },
})
export default class OrganizationHolidaySetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;

    @Prop() event_bus !: Vue;

    get computedHoliday() : any[] {
        let holidays : any[] = []; 

        for( const holiday of this.holidays ) {
            const not_holiday = this.not_holidays.filter(item => item.holiday == holiday.solar_ymd.replace(/-/ig, ""));
            const is_rest : boolean = not_holiday.length < 1;

            holidays.push({
                "ymd" : holiday.solar_ymd,
                "holi_name" : holiday.holi_name,
                "is_rest" : is_rest
            });
        }
        
        holidays.sort((o1, o2) : number => {

            if( this.sort_type == organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE.DATE ) {
                const o1_yyyymmdd = o1.ymd.replace(/-/ig, "");
                const o2_yyyymmdd = o2.ymd.replace(/-/ig, "");

                if( o1_yyyymmdd > o2_yyyymmdd ) return this.sort_dir == SORT_TYPE.ASC ?  1 : -1;
                if( o1_yyyymmdd < o2_yyyymmdd ) return this.sort_dir == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE.NAME ) {
                const o1_name = o1.holi_name;
                const o2_name = o2.holi_name;

                if( o1_name > o2_name ) return this.sort_dir == SORT_TYPE.ASC ?  1 : -1;
                if( o1_name < o2_name ) return this.sort_dir == SORT_TYPE.ASC ? -1 :  1;
            }

            return 0;
        });

        return holidays;
    }

    selected_year : string = "";

    is_year_open : boolean = false;

    holidays : any[] = []; // 공휴일
    not_holidays : any[] = []; // 공휴일 쉬지 않는 날

    sort_type : organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE = organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE.DATE;
    sort_dir : SORT_TYPE = SORT_TYPE.ASC;

    mounted() : void {
        this.event_bus?.$on("OrganizationHolidaySettingResize", this.handleResize);

        this.selected_year = moment().format('YYYY');
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            // 조직도 스크롤
            const title_box_height = $('#organization_setting .title_box').outerHeight();
            const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            const sort_header_height = $('#organization_holiday_setting .sortHeader').outerHeight();

            // @ts-ignore
            $('#organization_holiday_setting_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (title_box2_height ? title_box2_height : 0)
                                            - (sort_header_height ? sort_header_height : 0)
            });

            // @ts-ignore
            $('#year_dropdown').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
            });
        });
    }

    /**
     * 법정 휴무일 조회
     */
    @Watch('selected_year')
    async getLegalHolidays() : Promise<void> {
        try {
            let start_date = moment(new Date()).set("years", Number(this.selected_year)).set("months", 0).set("dates",1).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate();
            let end_date   = moment(start_date).set("months", 11).set("dates",31).toDate();

            await this.getOrganizationNotHoliday();
            await this.getHolidays(start_date, end_date);

        } catch(e) {
            this.hodu_show_dialog('cancel', '법정 휴무일 조회 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {
        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        this.holidays.splice(0, this.holidays.length);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {

            this.holidays.push(holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                holi_day_before.holi_name = holi_day.holi_name + " 연휴";

                this.holidays.push(holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                holi_day_after.holi_name = holi_day.holi_name + " 연휴";

                this.holidays.push(holi_day_after);
            }
        }

    }

    /**
     * 쉬지 않는 날 조회
     */
    async getOrganizationNotHoliday() : Promise<void> {
        
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/holiday`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.not_holiday ) {
                throw new Error("법정 휴무일 조회 중 오류 발생");
            }

            this.not_holidays.splice(0, this.not_holidays.length);
            this.not_holidays = this.not_holidays.concat(response.data.data.not_holiday);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 법정 휴무일 쉬지 않는 날 등록
     */
    async insertOrganizationNotHoliday(holiday_yyyymmdd : string) : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/holiday`, API_METHOD.POST, { holiday : holiday_yyyymmdd });

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("법정 휴무일 쉬지 않는 날 등록 중 오류 발생");
            }

            this.getLegalHolidays();

        } catch(e) {
            this.hodu_show_dialog('cancel', '법정 휴무일 쉬지 않는 날 등록 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 법정 휴무일 쉬지 않는 날 등록
     */
    async deleteOrganizationNotHoliday(holiday_yyyymmdd : string) : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/holiday/${holiday_yyyymmdd}`, API_METHOD.DELETE);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("법정 휴무일 쉬지 않는 날 삭제 중 오류 발생");
            }

            this.getLegalHolidays();

        } catch(e) {
            this.hodu_show_dialog('cancel', '법정 휴무일 쉬지 않는 날 삭제 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 쉬는날, 쉬지않는날 변경
     */
    async changeNotHoliday(event, holiday) : Promise<void> {

        const value = event.target.value;

        const holiday_yyyymmdd = holiday.ymd.replace(/-/ig, '');

        if( value == 'yes' ) {
            this.deleteOrganizationNotHoliday(holiday_yyyymmdd);
        }

        // insert
        else if( value == 'no' ) {
            this.insertOrganizationNotHoliday(holiday_yyyymmdd);
        }
    }

    /**
     * 정렬
     */
    sort(sort_type : organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_dir = SORT_TYPE.ASC;
            return;
        }

        this.sort_dir = (this.sort_dir == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 드롭다운 바깥쪽 클릭
     */
    dropdownOutsideClick() : void {
        this.is_year_open = false;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_holiday_setting_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#year_dropdown').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    .pubHolidayDiv { }
    .pubHolidayDiv .num { width:13%; text-align: center; }
    .pubHolidayDiv .yyyy { width:17%; }
    .pubHolidayDiv .time { width:25%; }
    .pubHolidayDiv .holidayName { width:30%; }
    .pubHolidayDiv .whether { width:15%; }
    .pubHolidayDiv .whether select {font-weight:bold; transition:0.2s; appearance: none; border-radius:5px; height:30px; line-height: 30px;cursor:pointer; background:#fff; box-sizing: border-box;border:1px solid #fff;}
    .pubHolidayDiv .whether select:hover { font-weight:bold; background:#fff; }
    .pubHolidayDiv .whether select:focus { border-color:#477fff !important; background:#fff; }
    .pubHolidayDiv .holidayUl .time span {width:60px;display:inline-block; }
    .pubHolidayDiv li:hover .whether select { font-weight:bold; border:1px solid #000;  padding: 0 10px;}

    .holidayUl li { transition: 0.1s; position:relative; font-weight:bold; width:100%; overflow:hidden; line-height:55px; height:55px; border-bottom:1px solid #f1f3f5; }
    .holidayUl li:hover { background:#f6f9fb; }
    .holidayUl li > * { float:left; }
    .holidayUl li.gray {background:#f7f9fb; }
    .holidayUl li.gray > * { opacity:0.4;  }
    .holidayUl li.gray > * span {   }
    .holidayUl li.gray:hover > * { opacity:1 }
    .holidayUl li.replacement { opacity:0; transition:0.2s; overflow:hidden; height: 0; line-height: 0; color:#477fff; }
    .holidayUl li.replacement .whether select {width:80px !important;color:#477fff;}
    .holidayUl li.replacement .clr { position:absolute; top:22px; left: 20px; width:10px; height:10px; border-radius: 50%; display:block; background:#477fff; }
    .holidayUl li.replacement .holidayName span { padding-left:10px; color:#477fff; }
    .holidayUl li.replacement input[type="text"] {color:#477fff; transition:0.2s; font-size:12px; background: transparent; font-weight: bold;; }
    .holidayUl li.replacement:hover input[type="text"], .holidayUl li.replacement:hover select { box-sizing: border-box; padding:0 10px; border:1px solid #000; line-height:30px; height:30px; border-radius: 5px;cursor:pointer; width: 110px; }
    .holidayUl li.replacement:hover input[type="text"]:hover, .holidayUl li.replacement:hover select:hover { background:#f1f3f5; }
    .holidayUl li.replacement:hover input[type="text"]:focus, .holidayUl li.replacement:hover select:focus { background:#fff; border-color:#477fff; }
    .holidayUl li.replacement .yyyy select {color:#477fff; width:70px !important; transition:0.2s;background:transparent;font-weight: bold; appearance: none; line-height:30px; height:30px; border-radius: 5px; font-size:12px; }
    .holidayUl.on .replacement {opacity:1; height: 55px; line-height: 55px;} 

    .dropdown { max-height: 470px; overflow-y: auto; }
    .whether { font-size : 13px; }
</style>