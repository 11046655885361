/* Default styling for jQuery Datepicker v5.0.1. */
.datepick {
	width: 220px !important; 
	color: #000;
    border-radius: 0.25em;
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
	font-family: Arial,Helvetica,Sans-serif;
    position: absolute;
    height: 245px;
    padding: 0 0 25px 0;
    box-shadow: 0 8px 13px rgb(0 0 0 / 9%);
    margin: 0 auto;
    font-size: 11px;
    background-color: #fff;
}
.datepick-rtl {
	direction: rtl;
}
.datepick-popup {
	z-index: 1000;
}
.datepick-disable {
	position: absolute;
	z-index: 100;
	background-color: white;
	opacity: 0.5;
	filter: alpha(opacity=50);
}
.datepick a {
	color: #232848;
	text-decoration: none;
}
.datepick a.datepick-disabled {
	color: #888;
	cursor: auto;
}
.datepick button {
    margin: 0.25em;
    padding: 0.125em 0em;
    background-color: #fcc;
    border: none;
    border-radius: 0.25em;
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    font-weight: bold;
}
.datepick-nav, .datepick-ctrl {
	float: left;
	width: 220px;
	background-color: #fff;
	color: #232848;
	font-size: 90%;
	font-weight: bold;
	border-top: 1px solid #f3f3f3;
	border-bottom: 1px solid #e6e6e6;
	height: 30px;
	line-height: 33px;
	/* padding: 0 10px; */
	
}
.datepick-ctrl {
	position: absolute;
    bottom: 0;
}
.datepick-cmd {
	width: 30%;
}
.datepick-cmd:hover {
	background-color: #777;
}
.datepick-ctrl .datepick-cmd:hover {
	background-color: #777;
}
.datepick-cmd-prevJump, .datepick-cmd-nextJump {
	width: 8%;
}
a.datepick-cmd {
	height: 30px;
	text-align: center;
}
a.datepick-cmd:hover {
	color: #fff;
}
button.datepick-cmd {
	text-align: center;
}
.datepick-cmd-prev, .datepick-cmd-prevJump, .datepick-cmd-clear {
	float: left;
}
.datepick-cmd-current, .datepick-cmd-today {
	float: left;
	width: 35%;
	text-align: center;
}
.datepick-cmd-next, .datepick-cmd-nextJump, .datepick-cmd-close {
	float: right;
	text-align: right;
}
.datepick-rtl .datepick-cmd-prev, .datepick-rtl .datepick-cmd-prevJump,
.datepick-rtl .datepick-cmd-clear {
	float: right;
	padding-left: 0%;
	text-align: right;
}
.datepick-rtl .datepick-cmd-current, .datepick-rtl .datepick-cmd-today {
	float: right;
}
.datepick-rtl .datepick-cmd-next, .datepick-rtl .datepick-cmd-nextJump,
.datepick-rtl .datepick-cmd-close {
	float: left;
	padding-right: 0%;
	text-align: left;
}
.datepick-month-nav {
	float: left;
	background-color: #777;
	text-align: center;
}
.datepick-month-nav div {
	float: left;
	width: 12.5%;
	margin: 1%;
	padding: 1%;
}
.datepick-month-nav span {
	color: #888;
}
.datepick-month-row {
	clear: left;
}
.datepick-month {
	float: left;
	width: 100%;
	text-align: center;
}
.datepick-month-header, .datepick-month-header select, .datepick-month-header input {
	height: 40px;
	line-height: 40px;
    background-color: #fff;
    color: #232848;
    font-weight: bold;
}
.datepick-month-header select, .datepick-month-header input {
	height: 1.4em;
	margin: 0em;
	padding: 0em;
	border: none;
	font-size: 100%;
}
.datepick-month-header input {
	position: absolute;
	display: none;
}
.datepick-month table {
	width: 100%;
	border-collapse: collapse;
}
.datepick-month thead {
	background-color: #fff;
}
.datepick-month th, .datepick-month td {
	margin: 0em;
	padding: 5px 0;
	font-weight: normal;
	text-align: center;
}
.datepick-month th {

}
.datepick-month th, .datepick-month th a {
	background-color: #fff;
	color: #232848;
	text-transform: uppercase;
    font-size: 6px;
    padding: 14px 0 8px;
}
.datepick-month td {
	/* background-color: #eee;
	border: 1px solid #aaa; */
	font-size: 100%;
	position: relative;
}
.datepick-month td:hover a {
	color: #477fff;
	background-color: #eaf0ff;
	border-radius: 50%;
	font-weight: bold !important;
	width: 26px;
	height: 26px;
	display: inline-block;
	line-height: 26px;
	position: absolute;
	left: 2px;
	top: -2px;
}

.datepick-month td.datepick-week * {
	/* background-color: #777; */
	color: #fff;
	border: none;
}
.datepick-month a {
	width: 100%;
	padding: 0.125em 0em;
	background-color: #fff;
	color: #232848;
	text-decoration: none;
}
.datepick-month span {
	display: block;
	width: 100%;
	/* padding: 0.125em 0em; */
	color: #7c88a2;
    font-size: 11px;
}
.datepick-month td span {
	color: #888;
}
.datepick-month td .datepick-other-month {
	background-color: #fff;
	color: #c0cad0;
    opacity: 0.7;
}
.datepick-month td .datepick-weekend {
	background-color: #fff;
}
.datepick-month td .datepick-today {
	padding: 0;
	color: #477fff !important;
	border: 1px solid #477fff;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: inline-block;
	line-height: 25px;
	position: static !important;
	box-sizing: border-box;
	font-weight: bold;
}
.datepick-month td .datepick-highlight {
	background-color: #eaf0ff;
}
.datepick-month td .datepick-selected {
	background-color: #fff;
	border: 1px solid #477fff;
	color: #232848;
	border-radius: 50%;
	width: 22px;
	height: 22px;
	display: inline-block;
	line-height: 22px;
	position: absolute;
	left: 2px;
	top: -2px;
}
tepick-month td .datepick-today a {
	display: inline-block;
} */
.datepick-month th.datepick-week {
	background-color: #777;
	color: #fff;
}
.datepick-status {
	clear: both;
	background-color: #ddd;
	text-align: center;
}
.datepick-clear-fix {
	clear: both;
}
.datepick-cmd-clear {
	width: 50%;
}
.datepick-cmd-close {
	width: 50%;
}





/* 추가 사항 */
.datepick { height: 268px; }

.datepick-month-header { font-size: 0; border-bottom: 1px solid #f1f3f5; box-sizing: border-box;}
.datepick-month-header select, .datepick-month-header input  { float: left; font-size: 13px !important; height: 39px; line-height: 39px; }

.datepick-nav { height: auto; border: none }
.datepick-cmd-today { display: none }
.datepick-cmd.datepick-cmd-prev,.datepick-cmd.datepick-cmd-next { top: 0; right:0; position: absolute; width: 40px; height: 40px; display: inline-block; background: red ; font-size: 0;background: url('../../../assets/images/contents/month_bt_next.svg') no-repeat center center;background-size: 22px; }
.datepick-cmd.datepick-cmd-prev { right: 80px;background-image: url('../../../assets/images/contents/month_bt_prev.svg'); }
.datepick-month-header select { cursor: pointer; width: 70px;-webkit-appearance: none; padding-left: 15px; box-sizing: border-box;background:  url('../../../assets/images/side_area/bt_open.png') no-repeat 53px center; }
.datepick-month-header select:focus { background-image: url('../../../assets/images/side_area/bt_close.png') ; }
.datepick-month-header select:last-child { width: auto; margin-left: 70px;-webkit-appearance: none;width: 40px; text-indent: 10px; padding-left: 0; }

.datepick-month td { padding: 0; width: 32px; height: 28px; text-align: center; }
.datepick-month a { display: inline-block; line-height: 25px; padding:0; width: 25px; height: 25px; border-radius: 50%; box-sizing: border-box; border:1px solid #fff }
.datepick-month td:hover a {border-color: #eaf0ff}
.datepick-month td:hover a, .datepick-month td .datepick-selected { width: 25px; height: 25px; line-height: 25px; position: static !important; border-radius: 50%; box-sizing: border-box; }
.datepick-month td:hover a.datepick-today, .datepick-month td .datepick-selected { font-weight: bold; background-color:#477fff !important; color:#fff !important; border-color: #477fff !important }

.datepick-ctrl { height: 40px; }
.datepick-ctrl a.datepick-cmd { transition: 0.1s; line-height: 40px; height: 40px; display: inline-block; }
.datepick-ctrl a.datepick-cmd:hover  { background:#f1f3f5; color: #232848;}