<template>
    <div class="rightDiv" :class="{
        mate : TAB == 'USER',
        img : TAB == 'IMAGE',
        file : TAB == 'FILE'
    }">

        <!-- section_ce_fix에 rightDivClosed있을시 (rightDiv 접혔을때만) 보임, 온라인된 사람과 안된사람-->
        <!-- <div class="rightDivDescript">
            <p><span>8</span> / 14</p>
        </div> -->

        <!-- 버튼, .section_ce_fix에 .leftDivClosed 추가시 hide -->
        <!-- <div class="btns"> -->
            <!-- 친구 (선택된 친구/총 인원), 그룹 (몇개인지) 버튼 -->
            <!-- mBtn 클릭 시 .magUl에 mate 클래스 추가, gBtn 클리기 .magUl에 grp 클래스추가 -->
            <!-- <input type="button" class="on mBtn" value="친구 (6/14)" /><input type="button" class="gBtn" value="그룹 (4)" /> -->
        <!-- </div> -->

        <!-- leftDivClosed 추가 시, chatroomTitle 그룹채팅 시 클래스 추가 grp -->
        <div class="chatroomTitle">
            <p class="title">일반 채팅</p>
            <p class="title rm">일반 채팅</p>
            <!-- 일반 채팅 (친구들과 채팅), 일반 채팅에서 그룹명으로 변경 -->
            <!-- <p class="title">일반 채팅</p> -->
            <!-- <p class="title rm"><span class="dot dc7"></span>호두웨어</p> -->
            
            <!-- 방장 일 시 -->
            <a href="#" class="ctMenu" :class="{ on : is_setting_open == true }" @click.prevent="is_setting_open = !is_setting_open">메뉴</a>

            <!-- 초대된 사람일 시 -->
            <input type="button" value="나가기" @click="leave" v-if="is_chatting_room_manager == false"/>

        </div>

        <!-- leftDivClosed 추가 시, chatroomBtns -->
        <div class="chatroomBtns">
            <input type="button" :class="{ on : TAB == 'USER' }" value="멤버" @click.prevent="tabChange('USER')"/>
            <input type="button" :class="{ on : TAB == 'IMAGE' }" value="사진" @click.prevent="tabChange('IMAGE')"/>
            <input type="button" :class="{ on : TAB == 'FILE' }" value="파일" @click.prevent="tabChange('FILE')"/>
        </div>

        <!-- 찾기 div -->
        <div class="schPeepsDiv">
            <input type="text" placeholder="이름으로 검색하세요" :value="user_search_query" @input="user_search_query = $event.target.value" />
            <input type="button" class="schBtn" />
        </div>

        <!-- 사진, 파일 찾기 div -->
        <div class="imgFileSchDiv">

            <div class="timeDiv">
                <select v-model="selected_file_year">
                    <option :value="select.year == '전체' ? 'ALL' : select.year" :key="select.year" v-for="select in (TAB == 'IMAGE' ? computedImageListByYear : computedFileListByYear)">
                        {{ `${select.year}${select.year == '전체' ? '' : '년' } (${ TAB == 'IMAGE' ? select.images.length : select.files.length })` }}
                    </option>
                    <!-- <option value="">전체 (20)</option>
                    <option value="" selected>2020년 (20)</option>
                    <option value="">2019 (92)</option> -->
                </select>
            </div>

            <div class="sortDiv">
                <select v-model="selected_file_sort">
                    <option value="TIME">최신 순</option>
                    <option value="NAME">가나다순</option>
                    <option value="SIZE">파일크기순</option>
                    <!-- <option value="">내가 올린 사진만</option>
                    <option value="">내 사진 제외</option> -->
                </select>
            </div>

        </div>

        <!-- 스크롤 -->
        <div class="magDiv scroll">
            
            <!-- 결과 없을 시 -->
            <div class="noResult">
                <p>검색된 결과가 없습니다.</p>
            </div>

            <!-- magUl 친구와 그룹 ul, mate는 친구, grp는 그룹 -->
            <ul class="magUl mate">

                <!-- 친구 리스트 -->
                <li class="mateList" v-if="false">
                    <ul class="mateUl">
                        <!-- 체크박스 선택시 list에 클래스 추가 on, title에 온라인인지 오프라인인지 적어주기 -->
                        <li class="online on" title="온라인">
                            <!-- 체크박스 -->
                            <div class="checkDiv">
                                <input id="mcheck0" type="checkbox" checked />
                                <label for="mcheck0"></label>
                            </div>
                            <!-- 온라인, 오프라인 -->
                            <span class="dot">온라인</span>
                            <!-- 사진 -->
                            <p class="mPic">사진</p>
                            <!-- 이름 -->
                            <p class="mName">김금수</p>
                            <!-- 채팅룸으로 가는 링크 -->
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="online" title="온라인">
                            <div class="checkDiv">
                                <input id="mcheck1" type="checkbox" />
                                <label for="mcheck1"></label>
                            </div>
                            <span class="dot">온라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김고연</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="offline" title="오프라인">
                            <div class="checkDiv">
                                <input id="mcheck2" type="checkbox" />
                                <label for="mcheck2"></label>
                            </div>
                            <span class="dot">오프라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김나은</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="offline" title="오프라인">
                            <div class="checkDiv">
                                <input id="mcheck3" type="checkbox" />
                                <label for="mcheck3"></label>
                            </div>
                            <span class="dot">오프라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김나혁</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="online" title="온라인">
                            <div class="checkDiv">
                                <input id="mcheck4" type="checkbox" />
                                <label for="mcheck4"></label>
                            </div>
                            <span class="dot">온라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김도진</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="offline" title="오프라인">
                            <div class="checkDiv">
                                <input id="mcheck5" type="checkbox" />
                                <label for="mcheck5"></label>
                            </div>
                            <span class="dot">오프라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김라미</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="offline" title="오프라인">
                            <div class="checkDiv">
                                <input id="mcheck6" type="checkbox" />
                                <label for="mcheck6"></label>
                            </div>
                            <span class="dot">오프라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김미연</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="online" title="온라인">
                            <div class="checkDiv">
                                <input id="mcheck7" type="checkbox" />
                                <label for="mcheck7"></label>
                            </div>
                            <span class="dot">온라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김배진</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="online" title="온라인">
                            <div class="checkDiv">
                                <input id="mcheck8" type="checkbox" />
                                <label for="mcheck8"></label>
                            </div>
                            <span class="dot">온라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">Jerry Miller</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="offline" title="오프라인">
                            <div class="checkDiv">
                                <input id="mcheck9" type="checkbox" />
                                <label for="mcheck9"></label>
                            </div>
                            <span class="dot">오프라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김은혁</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="online" title="온라인">
                            <div class="checkDiv">
                                <input id="mcheck10" type="checkbox"/>
                                <label for="mcheck10"></label>
                            </div>
                            <span class="dot">온라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김준호</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="offline" title="오프라인">
                            <div class="checkDiv">
                                <input id="mcheck11" type="checkbox" />
                                <label for="mcheck11"></label>
                            </div>
                            <span class="dot">오프라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">김철중</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="online" title="온라인">
                            <div class="checkDiv">
                                <input id="mcheck12" type="checkbox" />
                                <label for="mcheck12"></label>
                            </div>
                            <span class="dot">온라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">라미오</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                        <li class="online" title="온라인">
                            <div class="checkDiv">
                                <input id="mcheck13" type="checkbox" />
                                <label for="mcheck13"></label>
                            </div>
                            <span class="dot">온라인</span>
                            <p class="mPic">사진</p>
                            <p class="mName">배민영</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </li>
                    </ul>
                </li>

                <!-- 그룹 리스트 -->
                <li class="grpList" v-if="false">
                    <ul class="grpUl">
                            <!-- 체크박스 선택시 list에 클래스 추가 on, title에 온라인인지 오프라인인지 적어주기 -->
                        <li class="grpLi" title="">
                            <div class="grpLiDiv">
                                <!-- 체크박스 -->
                                <div class="checkDiv">
                                    <input id="grpcheck0" type="checkbox" />
                                    <label for="grpcheck0"></label>
                                </div>
                                <!-- 그룹 사진 -->
                                <p class="grpPic">그룹 사진</p>
                                <!-- 그룹명 -->
                                <p class="grpName"><span class="clr dc7">파랑</span>호두웨어</p>
                                <!-- 멤버 보기 -->
                                <a class="oac" title="팀보기">팀 3</a>
                                <!-- 채팅룸으로 가는 링크 -->
                                <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                <!-- 그룹 멤버 -->
                                <div class="memberDiv">
                                    <p class="memPic first">멤버 사진</p>
                                    <p class="memPic">멤버 사진</p>
                                    <p class="memPic">멤버 사진</p>
                                    <p class="memPic last"><span>+5</span></p>
                                    <a class="seeMembers" title="멤버보기">멤버 9명</a>
                                </div>
                            </div>
                            <ul class="memberUl">
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck0-0" type="checkbox" />
                                        <label for="grpcheck0-0"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">1</span>김정우</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck0-1" type="checkbox" />
                                        <label for="grpcheck0-1"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">2</span>이유진</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck0-2" type="checkbox" />
                                        <label for="grpcheck0-2"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">3</span>이윤지</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck0-3" type="checkbox" />
                                        <label for="grpcheck0-3"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">4</span>김지아</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck0-4" type="checkbox" />
                                        <label for="grpcheck0-4"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">5</span>배수빈</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck0-5" type="checkbox" />
                                        <label for="grpcheck0-5"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">6</span>유준영</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck0-6" type="checkbox" />
                                        <label for="grpcheck0-6"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">7</span>김윤우</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck0-7" type="checkbox" />
                                        <label for="grpcheck0-7"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">8</span>하시후</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck0-8" type="checkbox" />
                                        <label for="grpcheck0-8"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">9</span>김진우</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                            </ul>
                        </li>
                        <li class="grpLi" title="">
                            <div class="grpLiDiv">
                                <div class="checkDiv">
                                    <input id="grpcheck1" type="checkbox" />
                                    <label for="grpcheck1"></label>
                                </div>
                                <p class="grpPic">그룹 사진</p>
                                <p class="grpName"><span class="clr dc0">빨강</span>WEWALK Companies Inc</p>
                                <a class="oac" title="팀보기">팀 3</a>
                                <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                <div class="memberDiv">
                                    <p class="memPic first">멤버 사진</p>
                                    <p class="memPic">멤버 사진</p>
                                    <p class="memPic">멤버 사진</p>
                                    <p class="memPic last"><span>+7</span></p>
                                    <a class="seeMembers" title="멤버보기"> 멤버 11명</a>
                                </div>
                            </div>
                            <ul class="memberUl">
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-0" type="checkbox" />
                                        <label for="grpcheck1-0"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">1</span>Garry Thompson</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-1" type="checkbox" />
                                        <label for="grpcheck1-1"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">2</span>Jonathan Ross</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-2" type="checkbox" />
                                        <label for="grpcheck1-2"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">3</span>Conrad Aiken</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-3" type="checkbox" />
                                        <label for="grpcheck1-3"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">4</span>Delmira Agustini</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-4" type="checkbox" />
                                        <label for="grpcheck1-4"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">5</span>Javed Akhtar</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-5" type="checkbox" />
                                        <label for="grpcheck1-5"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">6</span>ernard Barton</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-5" type="checkbox" />
                                        <label for="grpcheck1-5"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">7</span>Helen Bevington</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-5" type="checkbox" />
                                        <label for="grpcheck1-5"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">8</span>Gioconda Belli</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-5" type="checkbox" />
                                        <label for="grpcheck1-5"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">9</span>Gustavo Adolfo Bécquer</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-5" type="checkbox" />
                                        <label for="grpcheck1-5"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">10</span>Stephen Vincent Benét</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="grpcheck1-5" type="checkbox" />
                                        <label for="grpcheck1-5"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">11</span>Richard Berengarten</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                            </ul>
                        </li>

                        <li class="grpLi" title="">
                            <div class="grpLiDiv">
                                <div class="checkDiv">
                                    <input id="grpcheck0" type="checkbox" />
                                    <label for="grpcheck0"></label>
                                </div>
                                <p class="grpPic">그룹 사진</p>
                                <p class="grpName"><span class="clr dc2">노랑</span>다운타임</p>
                                <a class="oac" title="팀보기">팀 2</a>
                                <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                <div class="memberDiv">
                                    <p class="memPic first">멤버 사진</p>
                                    <p class="memPic">멤버 사진</p>
                                    <p class="memPic">멤버 사진</p>
                                    <p class="memPic last"><span>+5</span></p>
                                    <a class="seeMembers" title="멤버보기"> 멤버 9명</a>
                                </div>
                            </div>
                            <ul class="memberUl">
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="tcheck2-0" type="checkbox" />
                                        <label for="tcheck2-0"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">1</span>이은지</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="tcheck2-1" type="checkbox" />
                                        <label for="tcheck2-1"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">2</span>김은혁</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="tcheck2-2" type="checkbox" />
                                        <label for="tcheck2-2"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">3</span>유연지</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="tcheck2-3" type="checkbox" />
                                        <label for="tcheck2-3"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">4</span>김지연</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="tcheck2-4" type="checkbox" />
                                        <label for="tcheck2-4"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">5</span>박형진</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="tcheck2-5" type="checkbox" />
                                        <label for="tcheck2-5"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">6</span>이 진</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="online" title="온라인">
                                    <div class="checkDiv">
                                        <input id="tcheck2-4" type="checkbox" />
                                        <label for="tcheck2-4"></label>
                                    </div>
                                    <span class="dot">온라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">7</span>최유정</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="tcheck2-5" type="checkbox" />
                                        <label for="tcheck2-5"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">8</span>jerry Miller</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                                <li class="offline" title="오프라인">
                                    <div class="checkDiv">
                                        <input id="tcheck2-5" type="checkbox" />
                                        <label for="tcheck2-5"></label>
                                    </div>
                                    <span class="dot">오프라인</span>
                                    <p class="mPic">사진</p>
                                    <p class="mName"><span class="num">9</span>하은역</p>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <!-- 팀 리스트 -->
                <li class="teamList" v-if="false">
                    <div class="mainGrp">
                        <input type="button" class="prev" value="뒤로가기" />
                        <div class="grpLiDiv">
                            <!-- 그룹 사진 -->
                            <p class="grpPic">그룹 사진</p>
                            <!-- 그룹명 -->
                            <p class="grpName"><span class="clr dc7">파랑</span>호두웨어</p>
                            <!-- 멤버 보기 -->
                            <p class="oac" title="멤버 보기"><span>멤버</span> 9명</p>
                            <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                        </div>
                    </div>
                    <!-- scroll -->
                    <div class="scroll">
                        <ul class="grpUl">
                            <!-- 체크박스 선택시 list에 클래스 추가 on, title에 온라인인지 오프라인인지 적어주기 -->
                            <li class="grpLi" title="">
                                <div class="grpLiDiv">
                                    <!-- 체크박스 -->
                                    <div class="checkDiv">
                                        <input id="grpcheck0" type="checkbox" />
                                        <label for="grpcheck0"></label>
                                    </div>
                                    <!-- 그룹 사진 -->
                                    <p class="grpPic">팀 사진</p>
                                    <!-- 그룹명 -->
                                    <p class="grpName"><span class="clr dc7">파랑</span>IRS</p>
                                    <!-- 멤버 보기 -->
                                    <a class="oac" title="멤버 보기">멤버 3명</a>
                                    <!-- 채팅룸으로 가는 링크 -->
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </div>
                                <ul class="memberUl">
                                    <li class="online" title="온라인">
                                        <div class="checkDiv">
                                            <input id="grpcheck0-0" type="checkbox" />
                                            <label for="grpcheck0-0"></label>
                                        </div>
                                        <span class="dot">온라인</span>
                                        <p class="mPic">사진</p>
                                        <p class="mName"><span class="num">1</span>김정우</p>
                                        <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                    </li>
                                    <li class="online" title="온라인">
                                        <div class="checkDiv">
                                            <input id="grpcheck0-1" type="checkbox" />
                                            <label for="grpcheck0-1"></label>
                                        </div>
                                        <span class="dot">온라인</span>
                                        <p class="mPic">사진</p>
                                        <p class="mName"><span class="num">2</span>이유진</p>
                                        <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                    </li>
                                    <li class="online" title="온라인">
                                        <div class="checkDiv">
                                            <input id="grpcheck0-2" type="checkbox" />
                                            <label for="grpcheck0-2"></label>
                                        </div>
                                        <span class="dot">온라인</span>
                                        <p class="mPic">사진</p>
                                        <p class="mName"><span class="num">3</span>이윤지</p>
                                        <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="grpLi" title="">
                                <div class="grpLiDiv">
                                    <div class="checkDiv">
                                        <input id="grpcheck1" type="checkbox" />
                                        <label for="grpcheck1"></label>
                                    </div>
                                    <p class="grpPic">팀 사진</p>
                                    <p class="grpName"><span class="clr dc0">빨강</span>피닉스</p>
                                    <a class="oac" title="멤버 보기">멤버 3명</a>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </div>
                                <ul class="memberUl">
                                    <li class="online" title="온라인">
                                        <div class="checkDiv">
                                            <input id="grpcheck1-0" type="checkbox" />
                                            <label for="grpcheck1-0"></label>
                                        </div>
                                        <span class="dot">온라인</span>
                                        <p class="mPic">사진</p>
                                        <p class="mName"><span class="num">1</span>김지아</p>
                                        <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                    </li>
                                    <li class="offline" title="오프라인">
                                        <div class="checkDiv">
                                            <input id="grpcheck1-1" type="checkbox" />
                                            <label for="grpcheck1-1"></label>
                                        </div>
                                        <span class="dot">오프라인</span>
                                        <p class="mPic">사진</p>
                                        <p class="mName"><span class="num">2</span>배수빈</p>
                                        <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                    </li>
                                    <li class="online" title="온라인">
                                        <div class="checkDiv">
                                            <input id="grpcheck1-2" type="checkbox" />
                                            <label for="grpcheck1-2"></label>
                                        </div>
                                        <span class="dot">온라인</span>
                                        <p class="mPic">사진</p>
                                        <p class="mName"><span class="num">3</span>유준영</p>
                                        <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                    </li>
                                </ul>
                            </li>

                            <li class="grpLi" title="">
                                <div class="grpLiDiv">
                                    <div class="checkDiv">
                                        <input id="grpcheck0" type="checkbox" />
                                        <label for="grpcheck0"></label>
                                    </div>
                                    <p class="grpPic">팀 사진</p>
                                    <p class="grpName"><span class="clr dc2">노랑</span>모바일</p>
                                    <a class="oac" title="멤버 보기">멤버 2명</a>
                                    <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                </div>
                                <ul class="memberUl">
                                    <li class="online" title="온라인">
                                        <div class="checkDiv">
                                            <input id="tcheck2-0" type="checkbox" />
                                            <label for="tcheck2-0"></label>
                                        </div>
                                        <span class="dot">온라인</span>
                                        <p class="mPic">사진</p>
                                        <p class="mName"><span class="num">1</span>하시후</p>
                                        <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                    </li>
                                    <li class="offline" title="오프라인">
                                        <div class="checkDiv">
                                            <input id="tcheck2-1" type="checkbox" />
                                            <label for="tcheck2-1"></label>
                                        </div>
                                        <span class="dot">오프라인</span>
                                        <p class="mPic">사진</p>
                                        <p class="mName"><span class="num">2</span>김진우</p>
                                        <a class="makeRoom" title="채팅하기">채팅룸 만들기</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </li>

                <!-- .leftDivClosed일 시 .mateList에 클래스추가 chatting / 친구리스트와 동일, 방장일 시 강퇴 버튼 추가 / 채팅 창 속 친구 리스트 / -->
                <li class="mateList chatting">

                    <!-- leftDivClosed 추가 시 -->
                    <div class="editDiv">
                        <p class="total"><span>{{ getOnlineMemberCount() }}</span> / {{ (connected_channel == null || connected_channel.users == null) ? 0 : connected_channel.users.filter(user => user.leaved_flag == false).length }}</p>
                        <input type="button" class="editBtn invite" value="초대" @click="invite"/>
                    </div>
                    
                    <div id="chat-user-list-scroll">
                        <ul class="mateUl">
                            
                            <li :class="{ 
                                online : users_online[user.user_id] != null,
                                offline : users_online[user.user_id] == null,
                                owner : user.user_role == 'OWNER' 
                            }" :title="users_online[user.user_id] != null ? '온라인' : '오프라인'" 
                               :key="user.user_id" v-for="user in computedUserList"> 
                                <p class="creator" title="방장" v-if="user.user_role == 'OWNER'">방장</p>
                                <span class="dot"></span>
                                <p class="mPic" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor(user.user_id / 10000)}/${user.user_id}.jpg)`}">사진</p>
                                <img class="error_detect_img" :src="`/app_images/profile/user/${Math.floor(user.user_id / 10000)}/${user.user_id}.jpg`" @error="userImageError($event)"/>
                                <p class="mName">{{ `${getUserName(user)}${user.user_id == user_id ? ' (나)' : ''}` }}</p>
                                
                                <!-- 방장 권한 ON / OFF -->
                                <input type="button" class="mandate" :value="user.user_role != 'OWNER' ? `방장 권한 부여` : `방장 권한 제거`" 
                                       :title="user.user_role != 'OWNER' ? `방장 권한 부여` : `방장 권한 제거`" @click="promoteMember(user)" 
                                       v-if="user.user_id != user_id && is_chatting_room_manager == true"/>

                                <!-- 강퇴 -->
                                <input type="button" class="del" value="강퇴" title="강퇴" @click="kickMember(user)" v-if="user.user_id != user_id && is_chatting_room_manager == true"/>

                            </li>

                            <!-- 체크박스 선택시 list에 클래스 추가 on, title에 온라인인지 오프라인인지 적어주기 -->
                            <li class="online" title="온라인" v-if="false">
                                <!-- 체크박스 -->
                                <div class="checkDiv">
                                    <input id="mccheck0" type="checkbox" />
                                    <label for="mccheck0"></label>
                                </div>
                                <!-- 온라인, 오프라인 -->
                                <span class="dot">온라인</span>
                                <!-- 사진 -->
                                <p class="mPic">사진</p>
                                <!-- 이름 -->
                                <p class="mName">김금수</p>
                                <!-- 강퇴 -->
                                <input type="button" class="del" value="강퇴" title="강퇴" />
                            </li>
                            <li class="online" title="온라인" v-if="false">
                                <div class="checkDiv">
                                    <input id="mccheck1" type="checkbox" />
                                    <label for="mccheck1"></label>
                                </div>
                                <span class="dot">온라인</span>
                                <p class="mPic">사진</p>
                                <p class="mName">김고연</p>
                                <input type="button" class="del" value="강퇴" title="강퇴" />
                            </li>
                            <li class="offline" title="오프라인" v-if="false">
                                <div class="checkDiv">
                                    <input id="mccheck2" type="checkbox" />
                                    <label for="mccheck2"></label>
                                </div>
                                <span class="dot">오프라인</span>
                                <p class="mPic">사진</p>
                                <p class="mName">김나은</p>
                                <input type="button" class="del" value="강퇴" title="강퇴" />
                            </li>
                            <li class="offline" title="오프라인" v-if="false">
                                <div class="checkDiv">
                                    <input id="mccheck3" type="checkbox" />
                                    <label for="mccheck3"></label>
                                </div>
                                <span class="dot">오프라인</span>
                                <p class="mPic">사진</p>
                                <p class="mName">김나혁</p>
                                <input type="button" class="del" value="강퇴" title="강퇴" />
                            </li>
                            <!-- 방장은 리스트에 cr -->
                            <li class="online me cr" title="온라인 (방장)" v-if="false">
                                <!-- 방장 -->
                                <p class="creator" title="방장">방장</p>
                                <div class="checkDiv">
                                    <input id="mccheck4" type="checkbox" />
                                    <label for="mccheck4"></label>
                                </div>
                                <span class="dot">온라인</span>
                                <p class="mPic">사진</p>
                                <p class="mName">김연지 (나)</p>
                                <input type="button" class="leave" value="나가기" title="나가기" />
                            </li>
                        </ul>
                    </div>

                </li>

                <li class="imgList" v-if="TAB == 'IMAGE' && computedImageListByYear.filter(item => item.year == selected_file_year) != null">
                    <!-- leftDivClosed 추가 시 -->
                    <!-- <div class="editDiv">
                        <div class="checkDiv">
                            <input id="imgcheckAll" type="checkbox" />
                            <label for="imgcheckAll"><span>전체 선택</span></label>
                        </div>
                        <p class="counting"><span>0</span> / 20</p>
                        <p class="total">총 20개</p>
                        <input type="button" class="editBtn" value="수정" />
                        <input type="button" class="cancelBtn" value="취소" />
                    </div> -->

                    <div id="chat-image-list-scroll">

                        <ul class="imgUl">
                            
                            <li :key="index" v-for="(image, index) in computedImageListByYear.filter(item => selected_file_year == 'ALL' ? item.year == '전체' : item.year == selected_file_year)[0].images">
                                <img class="error_detect_img" :src="`/chat_data/${image.url}`" @error="imageError($event)"/>
                                <a class="img" :style="{ backgroundImage : `url(/chat_data/${image.url})` }" @click.prevent="imageOpen([image], image)" :title="image.name">사진</a>
                                <p class="imgName">{{ image.name }}</p>
                            </li>

                        </ul>

                    </div>
                </li>

                <li class="fileList" v-if="computedFileListByYear.filter(item => item.year == selected_file_year) != null">
                    <!-- leftDivClosed 추가 시 -->
                    <!-- <div class="editDiv">
                        <div class="checkDiv">
                            <input id="filecheckAll" type="checkbox" />
                            <label for="filecheckAll"><span>전체 선택</span></label>
                        </div>
                        <p class="counting"><span>0</span> / 5</p>
                        <p class="total">총 5개</p>
                        <input type="button" class="editBtn" value="수정" />
                        <input type="button" class="cancelBtn" value="취소" />
                    </div> -->

                    <div id="chat-file-list-scroll">
                        <ul class="fileUl">
                            
                            <li :key="index" v-for="(file, index) in computedFileListByYear.filter(item => selected_file_year == 'ALL' ? item.year == '전체' : item.year == selected_file_year)[0].files" @click="download(file)">
                                <!-- 사진 -->
                                <a class="file format">{{ file.name.substr(file.name.lastIndexOf('.') + 1) }}</a>
                                <!-- 이름 -->
                                <p class="fileName">{{ file.name }}</p>
                                <!-- 용량 -->
                                <p class="fileSize">{{ 
                                    file.size >= 1 * 1024 * 1024 ? `${ (file.size / 1024 / 1024).toFixed(2) } MB` :
                                    file.size >= 1 * 1024        ? `${ (file.size / 1024       ).toFixed(2) } KB` :
                                                                   `${ file.size } Byte`         
                                }}</p>
                            </li>

                        </ul>
                    </div>
                </li>
            </ul>
            <!-- magUl ends -->
        </div>
        <!-- section_ce_fix에 rightDivClosed있을시 (rightDiv 접혔을때만) 보임, 리스트 클릭 방지와 다시 풀리는 용도-->
        <!-- <div class="whenClosed"></div> -->
        <!-- leftDivClosed 추가 시, 친구 초대 보이기 -->
        <!-- <input type="button" class="inviteMate" value="+ 친구 초대" @click="showChattingRoomInviteModal"/> -->
        <input type="button" class="delSelectedBtn" value="삭제">

        <!-- .ctMenu 클릭 시, height잡기 -->
        <div class="rightSettingDiv scroll" :class="{ on : is_setting_open == true }">
            <div class="forRel">
                <div class="rightSetting">
                    <!-- 방 생성시 타이틀과 소개글을 넣었을 시에만 보임, 아닐 시 .mainTitle > .dets만 보임-->
                    <!-- <div class="mainTitle">
                        <p class="crImg">채팅 사진</p>
                        <div>
                            <p class="dets"><span>생성일 : 2020.03.20 수</span><span class="last">인원 : 5명</span></p>
                            <p class="title">패러글라이딩 동호회</p>
                        </div>
                        <p class="descript">패러글라이딩부터 삶의 얘기를 하는 공간</p>
                        
                        <input type="button" value="수정" title="수정" @click="showChattingRoomInfoModal"/>
                    </div> -->
                    
                    <p class="title" v-if="is_chatting_room_manager == true">채팅</p>
                    <ul v-if="is_chatting_room_manager == true">
                        <li>
                            <p><span class="dot"></span>모든 메세지</p>
                            <div class="btn">
                                <input type="button" class="delBtn" value="삭제" @click="deleteAllMessages"/>
                            </div>
                        </li>
                        <li>
                            <p><span class="dot"></span>모든 사진</p>
                            <div class="btn">
                                <input type="button" class="delBtn" value="삭제" @click="deleteAllImages"/>
                            </div>
                        </li>
                        <li>
                            <p><span class="dot"></span>모든 파일</p>
                            <div class="btn">
                                <input type="button" class="delBtn" value="삭제" @click="deleteAllFiles"/>
                            </div>
                        </li>
                    </ul>
                    <!-- <p class="title" v-if="false">내 설정</p>
                    <ul v-if="false">
                        <li>
                            <p><span class="dot"></span>내가 보낸 메세지</p>
                            <div class="btn">
                                <input type="button" class="delBtn" value="삭제" />
                            </div>
                        </li>
                        <li>
                            <p><span class="dot"></span>내가 보낸 사진</p>
                            <div class="btn">
                                <input type="button" class="delBtn" value="삭제" />
                            </div>
                        </li>
                        <li>
                            <p><span class="dot"></span>내가 보낸 파일</p>
                            <div class="btn">
                                <input type="button" class="delBtn" value="삭제" />
                            </div>
                        </li>
                    </ul> -->
                    <p class="title">채팅방</p>
                    <ul class="chatSetUl">

                        <li>
                            <p><span class="dot"></span>채팅방 이름 변경</p>
                            <div class="btn">
                                <input type="button" class="delBtn" value="변경" @click="updateChattingRoom"/>
                            </div>
                        </li>

                        <li v-if="is_chatting_room_manager == true">
                            <p><span class="dot"></span>채팅방 배경색 선택</p>
                            <div class="btn">
                                <input type="button" class="chngeClr" :class="getChattingColorClass()" value="파랑" @click="showColorPicker"/>
                            </div>
                        </li>
                        <!-- <li v-if="false">
                            <p><span class="dot"></span>메세지 모두 확인 시 자동 삭제 <span>(1시간 후 자동 삭제)</span></p>
                            <div class="btn">
                                <label class="switch">
                                    <input type="checkbox">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </li> -->
                        <li v-if="is_chatting_room_manager == true">
                            <p><span class="dot"></span>채팅방 삭제</p>
                            <div class="btn">
                                <input type="button" class="delBtn" value="삭제" @click="deleteChattingRoom()"/>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- <input type="button" class="leaveRoomBtn" value="나가기" /> -->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { CommonColorPickerModalInfo, CommonInputModalInfo } from '@/store/modules/ModalInfo';
import { hodu_color } from '@/common/color';

import { chat_interface, chat_modal_interface } from '@/model/chat';
import { t_event_file } from '@/model/event';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ChattingRight extends Mixins(VueHoduCommon) {

    @Prop() event_bus                !: Vue;
    @Prop() connected_channel        !: chat_interface.I_CHANNEL_INFO | null;
    @Prop() users_online             !: Object
    @Prop() is_chatting_room_manager !: boolean;
    
    /**
     * 유저 리스트
     */
    get computedUserList() : any[] {

        let target_users : any[] = []; 

        if( this.connected_channel == null || this.connected_channel.users == null ) { return []; }

        target_users = target_users.concat(this.connected_channel.users.filter(
            item => this.hodu_string_includes(item.user_name, this.user_search_query) &&
                    item.leaved_flag == false 
        ));

        // sort 순서
        // 1. 나
        // 2. 방장
        // 3. 이름 오름차순
        target_users.sort((o1, o2) : number => {

            if( o1.user_id == this.user_id ) {
                return -1;
            }

            if( o2.user_id == this.user_id ) {
                return 1;
            }

            const o1_online = this.users_online[o1.user_id];
            const o2_online = this.users_online[o2.user_id];
            
            if( o1_online != null && o2_online == null ) {
                return -1;
            } 

            if( o1_online == null && o2_online != null ) {
                return 1;
            }

            if( o1.user_role.toUpperCase() == "OWNER" && o2.user_role.toUpperCase() != "OWNER" ) {
                return -1;
            }

            if( o1.user_role.toUpperCase() != "OWNER" && o2.user_role.toUpperCase() == "OWNER" ) {
                return 1;
            }

            if( o1.user_name < o2.user_name ) {
                return -1;
            }

            if( o1.user_name > o2.user_name ) {
                return 1;
            }

            return 0;
        });

        return target_users;
    }

    /**
     * 년도별 이미지 리스트
     */
    get computedImageListByYear() : any[] {

        const target_list : any[] = [];

        target_list.push({
            "year" : "전체",
            "images" : this.all_images
        })

        for( const image of this.all_images ) {
             
            const year = moment(image.date).format('YYYY'); 

            const target_year = target_list.filter(item => item.year == year);

            if( target_year.length < 1 ) {
                
                target_list.push({
                    "year" : year,
                    "images" : [image] 
                });

                continue;
            }

            target_year[0].images.push(image);

        }

        // 정렬
        for( const target of target_list ) {
            target.images.sort((o1, o2) : number => {
                
                // 최신순
                if( this.selected_file_sort == "TIME" ) {
                    const o1_time = moment(o1.date).toDate().getTime();
                    const o2_time = moment(o2.date).toDate().getTime();
                    
                    if( o1_time == o2_time ) { return 0; }
                    return o1_time < o2_time ? 1 : -1;
                }

                // 가나다순
                if( this.selected_file_sort == 'NAME' ) {
                    const o1_name = o1.name;
                    const o2_name = o2.name;
                    
                    if( o1_name == o2_name ) { return 0; }
                    return o1_name < o2_name ? -1 : 1;
                }

                // 파일크기순
                if( this.selected_file_sort == 'SIZE' ) {
                    const o1_size = o1.size;
                    const o2_size = o2.size;
                    
                    if( o1_size == o2_size ) { return 0; }
                    return o1_size < o2_size ? 1 : -1;
                }

                return 0;
            });
        }

        return target_list;
    }

    /**
     * 년도별 파일 리스트
     */
    get computedFileListByYear() : any[] {
        
        const target_list : any[] = [];

        target_list.push({
            "year" : "전체",
            "files" : this.all_files
        })

        for( const file of this.all_files ) {
             
            const year = moment(file.date).format('YYYY'); 

            const target_year = target_list.filter(item => item.year == year);

            if( target_year.length < 1 ) {
                
                target_list.push({
                    "year" : year,
                    "files" : [file] 
                });

                continue;
            }

            target_year[0].files.push(file);

        }

        // 정렬
        for( const target of target_list ) {
            target.files.sort((o1, o2) : number => {

                // 최신순
                if( this.selected_file_sort == "TIME" ) {
                    const o1_time = moment(o1.date).toDate().getTime();
                    const o2_time = moment(o2.date).toDate().getTime();
                    
                    if( o1_time == o2_time ) { return 0; }
                    return o1_time < o2_time ? 1 : -1;
                }

                // 가나다순
                if( this.selected_file_sort == 'NAME' ) {
                    const o1_name = o1.name;
                    const o2_name = o2.name;
                    
                    if( o1_name == o2_name ) { return 0; }
                    return o1_name < o2_name ? -1 : 1;
                }

                // 파일크기순
                if( this.selected_file_sort == 'SIZE' ) {
                    const o1_size = o1.size;
                    const o2_size = o2.size;
                    
                    if( o1_size == o2_size ) { return 0; }
                    return o1_size < o2_size ? 1 : -1;
                }
                
                return 0;
            });
        }

        return target_list;
    }

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventImagePreview              ?: any;
    @ModalInfo.Action doSetCommonColorPickerModalInfo         ?: (params : CommonColorPickerModalInfo) => void;
    @ModalInfo.Action doSetChattingRoomInfoModalInfo          ?: (params : chat_modal_interface.ChattingRoomInfoModalInfo) => void;

    all_images : t_event_file[] = [];
    all_files : t_event_file[] = [];

    selected_file_year : string = "ALL"; // ALL, 2020, 2021
    selected_file_sort : string = "TIME"; // TIME, NAME, SIZE

    TAB : string = "USER"; // USER, IMAGE, FILE
    is_setting_open : boolean = false;

    user_search_query : string = "";

    mounted() : void {
        this.event_bus?.$on('handleRightResize', this.handleResize);
        this.event_bus?.$on('imageOrFileAdd', this.imageOrFileAdd);
        this.event_bus?.$on('imageOrFileDelete', this.imageOrFileDelete);

        this.getFilesAndImages();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const main_title_height : number | undefined = $('.section_ce_fix .main-title').outerHeight();
        const chatroom_title_height : number | undefined = $('.section_ce_fix .rightDiv .chatroomTitle').outerHeight();
        const chatroom_button_height : number | undefined = $('.section_ce_fix .rightDiv .chatroomBtns').outerHeight();
        const search_bar_height : number | undefined = $('.section_ce_fix .rightDiv .schPeepsDiv').outerHeight();
        const edit_div_height : number | undefined = $('.section_ce_fix .rightDiv .magDiv .magUl .mateList .editDiv').outerHeight();
        const img_file_sch_div_height : number | undefined = $('.section_ce_fix .rightDiv .imgFileSchDiv').outerHeight();

        if( this.TAB == 'USER' ) {

            // @ts-ignore
            $('#chat-user-list-scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - ( main_title_height == null ? 0 : main_title_height )
                                               - ( chatroom_title_height == null ? 0 : chatroom_title_height )
                                               - ( chatroom_button_height == null ? 0 : chatroom_button_height )
                                               - ( search_bar_height == null ? 0 : search_bar_height )
                                               - ( edit_div_height == null ? 0 : edit_div_height )
            });

        }

        else if( this.TAB == 'IMAGE' ) {

            // @ts-ignore
            $('#chat-image-list-scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - ( main_title_height == null ? 0 : main_title_height )
                                               - ( chatroom_title_height == null ? 0 : chatroom_title_height )
                                               - ( chatroom_button_height == null ? 0 : chatroom_button_height )
                                               - ( img_file_sch_div_height == null ? 0 : img_file_sch_div_height )
            });

        }

        else if( this.TAB == 'FILE' ) {

            // @ts-ignore
            $('#chat-file-list-scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - ( main_title_height == null ? 0 : main_title_height )
                                               - ( chatroom_title_height == null ? 0 : chatroom_title_height )
                                               - ( chatroom_button_height == null ? 0 : chatroom_button_height )
                                               - ( img_file_sch_div_height == null ? 0 : img_file_sch_div_height )
            });

        }

        

    }

    /**
     * 모든 이미지 조회
     */
    async getAllImages() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_images`, API_METHOD.GET, null, false);

            console.log(response);

            this.all_images.splice(0, this.all_images.length);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.files ) {
                throw new Error("이미지 조회 실패");
            }

            this.all_images = this.all_images.concat(response.data.data.files);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 모든 파일 조회
     */
    async getAllFiles() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_files`, API_METHOD.GET, null, false);

            console.log(response);

            this.all_files.splice(0, this.all_files.length);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.files ) {
                throw new Error("파일 조회 실패");
            }

            this.all_files = this.all_files.concat(response.data.data.files);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 파일, 이미지 조회 병렬처리
     */
    async getFilesAndImages() : Promise<void> {
        Promise.all([this.getAllImages(), this.getAllFiles()]);
    }

    /**
     * 이미지, 파일 웹소켓에서 받으면 추가
     */
    imageOrFileAdd(files : t_event_file[]) : void {
        for( const file of files ) {
            // 이미지
            if( new RegExp('image').test(file.mimeType) == true ) {
                this.all_images.push(file);
                continue;
            }

            // 파일
            this.all_files.push(file);
        }
    }

    /**
     * 모든 이미지 삭제 또는 모든 파일 삭제
     */
    imageOrFileDelete(type : string) : void {
        if( type == 'IMAGE' ) {
            this.all_images.splice(0, this.all_images.length);
            return;
        }

        this.all_files.splice(0, this.all_files.length);
    }

    /**
     * 채팅방 나가기
     */
    async leave() : Promise<void> {

        this.hodu_show_dialog("cancel", `정말로 채팅방에서 나가시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {

                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }

                if( this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id)[0] == null ) {
                    return;
                }

                if( this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id)[0].user_role == 'OWNER' ) {
                    this.hodu_show_dialog("cancel", "방장은 채팅방에서 나갈 수 없습니다", ['확인']);
                    return;
                }

                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/leave`, API_METHOD.GET);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 나가기 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 나가기 중 오류 발생", ['확인']);
                }
                
            },
        ]);
    }

    /**
     * 채팅방 멤버 역할 변경
     */
    async promoteMember(user : chat_interface.t_chat_channel_user) : Promise<void> {

        // 방장 권한 부여
        if( user.user_role != 'OWNER' ) {
            this.hodu_show_dialog('alert', `정말로 ${user.user_name}님께 방장 권한을 부여 하시겠습니까?`, ['아니오', '예'], [
                () => {},
                async() => {

                    try {
                        const first_response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/users/${user.user_id}/OWNER`, API_METHOD.PUT);

                        console.log(first_response);

                        if( !first_response || !this.isHttpStatusSuccess(first_response.status) || !first_response.data ) {
                            throw new Error("방장 권한 부여 실패");
                        }

                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                        this.hodu_show_dialog("cancel", "방장 권한 부여 중 오류 발생", ['확인']);
                    }

                }
            ]);

            return;
        } 

        // 방장 권한 없애기
        this.hodu_show_dialog('alert', `정말로 ${user.user_name}님의 방장 권한을 제거 하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {

                try {
                    const first_response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/users/${user.user_id}/MEMBER`, API_METHOD.PUT);

                    console.log(first_response);

                    if( !first_response || !this.isHttpStatusSuccess(first_response.status) || !first_response.data ) {
                        throw new Error("방장 권한 제거 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "방장 권한 제거 중 오류 발생", ['확인']);
                }

            }
        ]);
        
    }

    /**
     * 채팅방 강퇴
     */
    async kickMember(user : chat_interface.t_chat_channel_user) : Promise<void> {
        
        this.hodu_show_dialog("cancel", `정말로 ${user.user_name}님을 강퇴시키시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {

                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/users/${user.user_id}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 멤버 강퇴 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 멤버 강퇴 중 오류 발생", ['확인']);
                }
                
            },
        ]);
        
    }

    /**
     * 탭 변경
     */
    tabChange(tab : string) : void {
        if ( this.TAB == tab ) { return; }

        this.TAB = tab;
        this.selected_file_year = 'ALL';
        this.selected_file_sort = 'TIME';
        this.user_search_query = "";
        this.$nextTick(() => this.handleResize());
    }

    /**
     * 온라인 멤버의 수
     */
    getOnlineMemberCount() : number {
        let count = 0;

        if( this.connected_channel == null || this.connected_channel.users == null ) { return 0; }

        for( const user of this.connected_channel.users ) {
            if( this.users_online[`${user.user_id}`] == null ) { continue; }
            count++;
        }

        return count;
    }

    /**
     * 초대
     */
    invite() : void {
        this.$emit('invite');
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('p.mPic').addClass('no-img');
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        $(event.target).parent().find('a.img').addClass("no-img");
    }

    /**
     * 이미지 열기
     */
    imageOpen(files : t_event_file[], file : t_event_file) : void {
        const image_index : number = files.indexOf(file);

        if( image_index == -1 ){
            return;
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : files,
            "type" : "CHAT"
        });
        this.doSetShowEventImagePreview(true);
    }

    /**
     * 파일 다운로드
     */
    download(file : t_event_file) : void {
        this.hodu_download(`/chat_data/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 모든 메세지 삭제
     */
    deleteAllMessages() : void {
        
        this.hodu_show_dialog('cancel', '정말 모든 메세지를 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_messages`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 모든 메세지 삭제 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 모든 메세지 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 모든 사진 삭제
     */
    deleteAllImages() : void {
        
        this.hodu_show_dialog('cancel', '정말 모든 사진을 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_images`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 모든 사진 삭제 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 모든 사진 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 모든 파일 삭제
     */
    deleteAllFiles() : void {
        
        this.hodu_show_dialog('cancel', '정말 모든 파일을 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_files`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 모든 파일 삭제 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 모든 파일 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 채널 삭제
     */
    deleteChattingRoom() : void {
        
        this.hodu_show_dialog('cancel', '정말 채팅방을 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 삭제 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 컬러에 따른 클래스 반환
     */
    getChattingColorClass() : string {

        if( this.connected_channel == null || this.connected_channel.info == null || this.connected_channel.info.color == null ) { return "bgc7"; }

        switch( this.hodu_hex_color_process(this.connected_channel.info.color).toUpperCase() ) {
            case hodu_color.chat_color_0.toUpperCase(): return "bgc0";
            case hodu_color.chat_color_1.toUpperCase(): return "bgc1";
            case hodu_color.chat_color_2.toUpperCase(): return "bgc2";
            case hodu_color.chat_color_3.toUpperCase(): return "bgc3";
            case hodu_color.chat_color_4.toUpperCase(): return "bgc4";
            case hodu_color.chat_color_5.toUpperCase(): return "bgc5";
            case hodu_color.chat_color_6.toUpperCase(): return "bgc6";
            case hodu_color.chat_color_7.toUpperCase(): return "bgc7";
            case hodu_color.chat_color_8.toUpperCase(): return "bgc8";
            case hodu_color.chat_color_9.toUpperCase(): return "bgc9";
        }

        return `bgc7`;
    }
 
    /**
     * 색상 선택 모달
     */
    showColorPicker() : void {
        this.doSetCommonColorPickerModalInfo?.({
            show_modal     : true,
            selected_color : this.connected_channel?.info?.color ?? hodu_color.chat_color_7,
            is_dc_only     : true,
            dc_color       : this.hodu_chat_color
        }); 

        window['applyColor'] = async(color : string) => {

            try {
                const post_data = {
                    "type" : this.connected_channel?.type,
                    "sub_type" : this.connected_channel?.sub_type,
                    "ref_id" : this.connected_channel?.ref_id,
                    "is_private" : this.connected_channel?.is_private,
                    "info" : this.connected_channel?.info 
                };

                post_data.info!.color = color;

                const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}`,API_METHOD.PUT, post_data);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                    throw new Error("채팅방 배경색 적용 실패");
                }

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "채팅방 배경색 적용 중 오류 발생", ['확인']);
            }

        };
    }

    

    /**
     * 채팅방 이름 변경
     */
    updateChattingRoom() : void {

        if( this.connected_channel == null ) return;

        const users = this.connected_channel?.users?.filter(item => String(item.user_id) == String(this.user_id)) ?? [];

        if( users.length < 1 ) {
            throw new Error("채팅방 이름 변경 중 오류 발생");
        }

        const post_data = JSON.parse(JSON.stringify(users[0]));

        const content = (post_data.user_info.title ?? "").trim().length > 0 ? post_data.user_info.title.trim() : (this.connected_channel.info ? this.connected_channel.info.title : "");

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "채팅방 이름 변경",
            subtitle : "채팅방 이름",
            placeholder : "채팅방 이름을 입력해주세요",
            content : content,
            cancel : () => {},
            save : async(title) => {
                try {

                    if( post_data.user_info == null ) post_data.user_info = {};
                    post_data.user_info.title = title;

                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/users/${this.user_id}`, API_METHOD.PUT, post_data);
                    
                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 이름 변경 실패");
                    }

                    // const post_data = {
                    //     "type" : this.connected_channel?.type,
                    //     "sub_type" : this.connected_channel?.sub_type,
                    //     "ref_id" : this.connected_channel?.ref_id,
                    //     "is_private" : this.connected_channel?.is_private,
                    //     "info" : this.connected_channel?.info 
                    // };

                    // post_data.info!.title = title;

                    // const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}`,API_METHOD.PUT, post_data);

                    // console.log(response);

                    // if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                    //     throw new Error("채팅방 이름 변경 실패");
                    // }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 이름 변경 중 오류 발생", ['확인']);
                }
            }
        });

    }

    /**
     * 채팅방 설정 모달
     */
    showChattingRoomInfoModal() : void {

        if( this.doSetChattingRoomInfoModalInfo == null ) { return; }

        this.doSetChattingRoomInfoModalInfo({
            show_modal : true
        });

    }

    /**
     * 클라이언트 여러개 열었을때를 대비해서 권한이 사라졌는데 환경설정에 들어가있다면 환경설정을 닫음
     */
    @Watch('is_chatting_room_manager')
    changeRole() : void {
        if( this.is_chatting_room_manager == false ) {
            this.is_setting_open = false;
        }
    }

    /**
     * 유저네임 반환
     */
    getUserName(user : any) : string {

        if( isNaN(Number(user.user_id)) == false ) {
            const friend = this.getFriend(Number(user.user_id));

            if( friend != null ) {
                return this.getFriendName(friend);       
            }
        }

        return user.user_name;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#chat-user-list-scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#chat-image-list-scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#chat-file-list-scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>

    input[type='checkbox']:not(old) + label { padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;}
    input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;}



    /* 오른쪽 닫혔을때 (친구, 그룹 버튼 있는 부분) */
    .section_ce_fix.rightDivClosed .rightDiv { transition:0.1s; width:70px; overflow: hidden; }
    .section_ce_fix.rightDivClosed .rightDiv .rightDivDescript {display:block; }
    .section_ce_fix.rightDivClosed .rightDiv .btns, .section_ce_fix.rightDivClosed .rightDiv .schPeepsDiv, .section_ce_fix.rightDivClosed .rightDiv .checkDiv { display:none; }
    .section_ce_fix.rightDivClosed .mateUl { overflow:hidden; }
    .section_ce_fix.rightDivClosed .mateUl li .dot, .section_ce_fix.rightDivClosed .mateUl li .dot {left: 14px !important;}
    .section_ce_fix.rightDivClosed .mateUl li { border-bottom: 0 none;height: 52px !important; }
    .section_ce_fix.rightDivClosed .magUl .mPic {left: 18px !important;}
    .section_ce_fix.rightDivClosed .leftDiv { padding-right:70px; }
    .section_ce_fix.rightDivClosed .title_box .mag_btn { margin-right:-5px; }
    .section_ce_fix.rightDivClosed .rightDiv .whenClosed { display:block; }
    .section_ce_fix.rightDivClosed .rightDiv .rightSettingDiv { display: none; }
    .rightSettingDiv .chatSetUl li p span { opacity: 0.7; width: auto; height: auto; background: none; }
    .rightSettingDiv .switch { width: 42px; margin-right: 17px;height: 20px;; }
    .rightSettingDiv .slider:before { height: 12px;width: 12px;left: 5px;bottom: 4px; }
    .rightSettingDiv input:checked+.slider:before { transform: translateX(20px);}

    /* chatroomUl 닫혔을때, chatroom 오픈 */
    
    .btns2 { display:none;float:right; }
    .btns2 input { margin-left:15px; margin-top:11px; height: 35px; line-height: 33px; }
    .btns2 .schBtn { margin-top:12px; margin-right:-8px; margin-left: 10px; width: 35px;height: 35px; }
    .btns2.editMode .input_btn { display: inline-block !important;  }
    .btns2.editMode .edit, .btns2.editMode .schBtn { display: none !important;  }
 
    /* 채팅자 보이기 */
    #section_ce .section_ce_fix.leftDivClosed .mateList { display:none; }
    #section_ce .section_ce_fix.leftDivClosed .rightDiv.mate .mateList.chatting { display:block }
    #section_ce .section_ce_fix .rightDiv.mate .mateList.chatting { display: none }
    #section_ce .section_ce_fix.leftDivClosed .rightDiv.img .imgList { display:block }
    #section_ce .section_ce_fix.leftDivClosed .rightDiv.file .fileList { display:block }
    .section_ce_fix.leftDivClosed .chatroomBtns { display:block }
    .section_ce_fix.leftDivClosed .editDiv { display:block }
    .section_ce_fix.leftDivClosed .rightDiv .btns { display:none !important; }
    .section_ce_fix.leftDivClosed .rightDiv .chatroomTitle { display:block }
    
    .section_ce_fix.leftDivClosed .rightDiv.img .imgFileSchDiv { display:block }
    .section_ce_fix.leftDivClosed .rightDiv.file .imgFileSchDiv { display:block }

    .section_ce_fix.leftDivClosed .rightDiv.img .editDiv, .section_ce_fix.leftDivClosed .rightDiv.file .editDiv { display:block }

    .section_ce_fix.leftDivClosed .rightDiv.img .schPeepsDiv { display:none }
    .section_ce_fix.leftDivClosed .rightDiv.file .schPeepsDiv { display:none }

    .section_ce_fix.leftDivClosed .rightDiv.mate .inviteMate { display:block }
    

    /* 오른쪽 닫힐때 */
    .section_ce_fix.rightDivClosed .rightDiv .inviteMate { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .delSelectedBtn { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .imgFileSchDiv { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .chatroomBtns { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .editDiv { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .chatroomTitle { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .rightSettingDiv { display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv p.creator { display: none; }
    .section_ce_fix.rightDivClosed .rightDiv .memberDiv { display: none; }
    .section_ce_fix.rightDivClosed .rightDiv .grpUl .grpLi > div .grpPic { left: 12px; }
    .section_ce_fix.rightDivClosed .rightDiv .grpUl > li > div { height: 66px }
    .section_ce_fix.rightDivClosed .rightDiv .mateUl li .dot, .section_ce_fix.rightDivClosed .rightDiv .mateUl li .dot, .memberUl li .dot, .section_ce_fix.rightDivClosed .rightDiv  .memberUl li .dot {left:14px !important; }
    #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .checkDiv { display: none !important; }
    .section_ce_fix.rightDivClosed .rightDiv .editMode .grpUl .grpLi > div .grpPic { left: 12px; }
    .section_ce_fix.rightDivClosed .rightDiv .teamList .mainGrp .prev { display: none }
    .section_ce_fix.rightDivClosed .rightDiv .teamList .mainGrp .grpPic { left: 7px; }
    .section_ce_fix.rightDivClosed.leftDivClosed .rightDiv .mateList.chatting { display:block !important; }
    .section_ce_fix.rightDivClosed .rightDiv .fileList {display:none !important }
    .section_ce_fix.rightDivClosed .rightDiv .imgList {display:none !important }

    #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .mateUl li .mPic, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .mateUl li .mPic, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .memberUl li .mPic, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .memberUl li .mPic {left: 20px !important;}
    #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .mateUl li .dot, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .mateUl li .dot, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .memberUl li .dot, #section_ce .section_ce_fix.rightDivClosed .rightDiv .editMode .memberUl li .dot {left: 14px !important;}


    /* 방장일때 */
    #section_ce .section_ce_fix.leftDivClosed.rm .editDiv .editBtn { display:block; }

    #section_ce .section_ce_fix.leftDivClosed.rm { }
    
    #section_ce .section_ce_fix.leftDivClosed .chatroomTitle .title.rm { display:none; }
    #section_ce .section_ce_fix.leftDivClosed.rm .chatroomTitle .title { display:none; } 
    #section_ce .section_ce_fix.leftDivClosed.rm .chatroomTitle .title.rm { display:inline-block; }
    
    /* chatroom 오픈, rightDiv 오픈된 채팅룸에 디테일 보임 */
    
    
    .chatroomBtns, .inviteMate, .imgFileSchDiv, .fileList, .imgList, .delSelectedBtn { display:none }
    .editDiv { display:none }
    .chatroomTitle {display:none;}

    #section_ce .section_ce_fix.leftDivClosed .magUl > li { display:none; }



    /* leftDivClosed, rightDivClosed 동시에 = 채팅룸이 열렸을때 */
    .section_ce_fix.leftDivClosed.rightDivClosed .useWhenClosed { padding-right:70px }


    .content, .forRel { width:100%; height: 100%; }

    /* 오른쪽 닫혔을때 사용되는 blocking div */
    .whenClosed { display:none; position: absolute; z-index: 100; top:0; left:0; width:100%; height: 100%; opacity:0; background:#35405A; transition:0.2s;}
    .whenClosed:hover { opacity:0.05; cursor:pointer }

    /* 왼쪽 닫혔을때 사용되는 blocking div */
    .leftDiv .whenClosed { top:60px;}

    /* .title_box */
    .title_box .mag_btn { transition:0.1s; width: 36px; height: 36px; margin-top:3px; font-size:0; border:1px solid #e7e9ea; font-size:0; box-sizing: border-box; border-radius: 50%; background:  url(../../assets/images/contents/ic_mag.png) no-repeat 6px center; background-size: 23px; }
    .title_box .mag_btn:hover {background-color:#f1f3f5}
    .title_box .mag_btn.on { background: #477fff url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; border-color:#477fff }


    /* rightDiv 친구리스트 */
    .rightDiv {z-index:1000;transition:0.1s; position: absolute;right: 0; top:0; width: 400px; height: 100%; box-sizing: border-box; border-left: 1px solid #e7e9ea; }
    .rightDiv .btns { width: 100%; overflow: hidden; }
    .rightDiv .btns input { font-weight: bold; float: left; height: 60px; line-height: 60px; border-bottom: 2px solid #e7e9ea; width: 50%; background:#fff; }
    .rightDiv .btns input:hover { border-color: #b9c0c3 }
    .rightDiv .btns input.on { border-color: #477fff; background:#fff !important; }


    /* rightDiv .rightDivDescript */
    .rightDivDescript { display:none; font-weight: bold; }
    .rightDivDescript p { height: 35px; line-height: 35px; border-bottom:1px solid #e7e9ea; text-align: center; font-size: 11px; }
    .rightDivDescript span { color:#06c580 }


    /* 친구,그룹 검색 */
    .schPeepsDiv { position: relative;  }
    .schPeepsDiv input[type="text"] { transition:0.2s; font-weight: bold; height: 55px; line-height: 55px; padding:0 15px 0 45px; border-bottom: 1px solid #e7e9ea; box-sizing: border-box; width:100%; }
    .schPeepsDiv input[type="text"]:hover { background:#f1f3f5; }
    .schPeepsDiv input[type="text"]:focus { background:#fff; border-color:#477fff; }
    .schPeepsDiv .schBtn { position: absolute; right:auto; left: 5px; top:8px; }
    .schPeepsDiv .schBtn:hover  {background: url(../../assets/images/contents/ic_search_on.png) no-repeat center center;}
    

    /* 친구 ul mateUl */
    .magUl.mate li.mateList { display:block; }
    .magUl.grp li.grpList { display:block; }
    .magUl.grp li.mateList { display:none; }
    .magUl.mate li.grpList { display:none; }
    .magUl.mate li.teamList { display:none !important; }
    .mateUl li, .memberUl li {transition:0.1s;  overflow: hidden; position: relative; padding:0 40px 0 110px; line-height: 55px; height: 55px; width:100%; border-bottom:1px solid #f1f3f5;box-sizing: border-box; overflow:hidden; text-overflow: ellipsis; white-space: nowrap;}
    .mateUl li:hover, .memberUl li:hover { background:#f7f9fb; }
    .mateUl li.on, .memberUl li.on { background:#fff !important;  }
    
    .mateUl li:hover > *, .mateUl li.on > p { opacity:1 !important; }
    .mateUl li .dot, .memberUl li .dot {transition:0.1s;  z-index: 10; position: absolute; left: 15px; font-size:0; top:9px; display:block; width:10px; height: 10px; border-radius: 50%; border: 2px solid #fff;  }    
    .mateUl li.online .dot, .memberUl li.online .dot { background:#13D08B }
    .mateUl li.offline .dot, .memberUl li.offline .dot { background:#b2c0d2 }
    .mateUl li > *, .memberUl li > * { float: left; }
    .mateUl li .mPic, .mateUl li .mPic, .memberUl li .mPic, .memberUl li .mPic { left: 60px !important; }
    .mateUl li .dot, .mateUl li .dot, .memberUl li .dot, .memberUl li .dot { left: 55px !important }
    .mateUl li .mPic, .memberUl li .mPic { transition:0.1s; z-index: 1; position: absolute; background-color:#fff; left: 20px; top:11px; font-size:0; width: 33px; height: 33px; border-radius: 50%; border:1px solid #e7e9ea; background-position: center center; background-size: cover; background-repeat: no-repeat; box-sizing: border-box; }
    .mateUl li .mName, .memberUl li .mName { transition:0.1s; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width:100%; }
    .mateUl li.offline .mPic, .mateUl li.offline .mName, .memberUl li.offline .mPic, .memberUl li.offline .mName { transition:0.1s; opacity:0.6 }
    .magUl li a.makeRoom { opacity:0; position: absolute; right: 0; top: 0; display: block; width: 55px; height: 55px; font-size: 0; background-position: center center; background-size: 30px; background-repeat: no-repeat; background-image:url(../../assets/images/contents/ic_addChatroom.png);  }
    .magUl li:hover a.makeRoom { opacity:1 }
    .mateUl li a.makeRoom { opacity:0 !important; }
    .mateUl li:hover a.makeRoom { opacity:1 !important; }
    .magUl li a.makeRoom:hover { background-image:url(../../assets/images/contents/ic_addChatroom_on.png);  } 
    .magUl li.on .checkDiv, .magUl li:hover .checkDiv {opacity:1; display:inline-block; }
    .checkDiv { position: absolute;left:20px; top:17px; }
    .magUl li input[type='checkbox']:not(old) + label { padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;}
    .magUl li input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;}
    .magUl  { padding-bottom: 100px; }
    .magUl > li > ul { padding-bottom: 100px; }


    

    /* leftDivClosed 채팅창 체크박스 없음 */
    .magUl li.mateList.chatting .checkDiv { display:none; }
    .magUl li.mateList.chatting  .mateUl li:hover input[type="button"] { opacity:1; }
    .magUl li.mateList.chatting  .mateUl li input[type="button"] { opacity:0; position:absolute; right:20px; top:14px;font-size:0; display:block; width: 30px; height:30px; border-radius: 50%; }
    .magUl li.mateList.chatting  .mateUl li input.leave { background: url(../../assets/images/contents/ic_lcr.png) no-repeat center center; }
    .magUl li.mateList.chatting  .mateUl li input.leave:hover { background-image: url(../../assets/images/contents/ic_lcr_on.png); }
    .magUl li.mateList.chatting  .mateUl li input.del {background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center}
    .section_ce_fix.leftDivClosed .magUl li.mateList.chatting  .mateUl li input.del { display:none }
    .section_ce_fix.leftDivClosed.rm .magUl li.mateList.chatting  .mateUl li input.del { display:block }
    .section_ce_fix.leftDivClosed.rm .magUl li.mateList.chatting.editMode  .mateUl li input.del , .section_ce_fix.leftDivClosed.rm .magUl li.mateList.chatting.editMode  .mateUl li input.leave { display:none }
    .mateList.chatting .mateUl li,.mateList.chatting  .memberUl li { padding: 0 40px 0 70px; }
    .mateList.chatting .mateUl li .mPic, .mateList.chatting .mateUl li .mPic, .mateList.chatting .memberUl li .mPic, .mateList.chatting .memberUl li .mPic {left: 22px !important;}
    .mateList.chatting .mateUl li .dot,.mateList.chatting .mateUl li .dot,.mateList.chatting .memberUl li .dot,.mateList.chatting .memberUl li .dot {left: 17px !important;}
    .magUl li .creator { position: absolute; right:20px; top:11px; display:block; width: 30px; height: 30px;border-radius: 50%; background: #fff url(../../assets/images/contents/faveStar.png) no-repeat center center; background-size: 22px; box-sizing:border-box; border:2px solid #ffc23a; font-size:0;}
    .magUl ul li:hover .creator { display: none; }
    .magUl ul li.cr { background:#fff9ea; }


    /* editMode */
    #section_ce .editMode .mateUl li, #section_ce .editMode .memberUl li {transition:0.1s;  overflow: hidden; position: relative; padding:0 40px 0 110px; line-height: 55px; height: 55px; width:100%; border-bottom:1px solid #f1f3f5;box-sizing: border-box; overflow:hidden; text-overflow: ellipsis; white-space: nowrap;}
    #section_ce .editMode .mateUl li .mPic, #section_ce .editMode .mateUl li .mPic, #section_ce .editMode .memberUl li .mPic, #section_ce .editMode .memberUl li .mPic { left: 60px !important; }
    #section_ce .editMode .mateUl li .dot, #section_ce .editMode .mateUl li .dot, #section_ce .editMode .memberUl li .dot, #section_ce .editMode .memberUl li .dot { left: 55px !important }
    /* #section_ce .magUl li.editMode  .mateUl li:hover .checkDiv input { opacity:0 !important } */

    .rightDiv .inviteMate, .rightDiv .delSelectedBtn, .rightDiv .leaveRoomBtn { transition:0.2s; display: none;position: fixed;border-top:1px solid #e7e9ea; background:#fff; height: 40px;line-height: 35px;right: 150px;bottom: 40px; border-radius: 25px;width: 200px;z-index: 10000; font-size: 13px; font-weight: bold; }
    .rightDiv .inviteMate { background:#477fff; color:#fff; }
    .rightDiv .inviteMate:hover { background:#296aff }
    .rightDiv .delSelectedBtn, .rightDiv .leaveRoomBtn { background:#ff6363; color:#fff; }
    .rightDiv .delSelectedBtn:hover, .rightDiv .leaveRoomBtn:hover {background:#ff4040}
    #section_ce .rightDiv.editMode .inviteMate { display:none !important  }
    #section_ce .rightDiv.editMode .delSelectedBtn { display:block }

    #section_ce .rightSettingDiv.on .leaveRoomBtn { display:block }

    


    /* .chatroomTitle */
    .rightDiv .chatroomTitle { position:relative; padding:0 20px; line-height: 60px; height: 60px; border-bottom:1px solid #e7e9ea; box-sizing:border-box; }
    .rightDiv .chatroomTitle > * { display:inline-block; }
    .rightDiv .chatroomTitle .title { font-weight:bold; font-size: 13px; width: 300px; }
    .rightDiv .chatroomTitle .title .dot { display: inline-block; width: 8px; height: 8px; border-radius: 50%; margin-right:10px;}
    .rightDiv .chatroomTitle input { position:absolute; right:55px; top:0;font-size:0; width: 60px; height:100%; background: url(../../assets/images/contents/ic_lcr.png) no-repeat center center;}
    .rightDiv .chatroomTitle input:hover {  background-image: url(../../assets/images/contents/ic_lcr_on.png) }

    /* .chatroomBtns */
    .chatroomBtns { overflow: hidden; height: 50px; line-height: 50px; box-sizing:border-box; }
    .chatroomBtns * { float: left;width: 33.333333%;height: 50px; line-height: 50px; border-bottom:2px solid #e7e9ea;text-align: center; background:#fff; font-weight: bold;font-size: 12px; display: inline-block; }
    .chatroomBtns input.on { border-color:#477fff; }

    /* .editDiv */
    .rightDiv .editDiv { position: relative; border-bottom: 1px solid #e7e9ea; padding: 0 20px; }
    .rightDiv .editDiv .checkDiv { display:none; }
    .rightDiv .editDiv > * { display: inline-block; line-height: 50px; height: 50px;}
    .rightDiv .editDiv .editBtn, .rightDiv .editDiv .cancelBtn { width: 60px; font-weight: bold; height: 100%; font-size: 12px; position: absolute; right:20px; top:0;background: #fff; }
    .rightDiv .me .editDiv .editBtn { display:block !important; } 
    .rightDiv .editDiv .editBtn { height: 25px; line-height: 25px;  background: #FFC72F; padding:0 15px; border-radius: 15px;  margin-top:12px; }
    .rightDiv .editDiv .editBtn:hover { background:#fdc01b }
    .rightDiv .editDiv .cancelBtn { height: 25px; line-height: 25px;  background: #f1f3f5;  color:#232848; padding:0 15px; border-radius: 15px;  margin-top:12px; }
    .rightDiv .editDiv .cancelBtn:hover { background:#e7e9ea }
    .rightDiv .editDiv .counting { display:none; font-size: 13px; font-weight: bold; padding-left: 100px; }
    .rightDiv .editDiv .counting span { color:#477fff }
    .rightDiv .editDiv label span { position: absolute; left: 30px; top: -17px;height: 50px;line-height: 50px;width: 60px; display: inline-block; font-size: 12px; font-weight: bold; color:#7c88a2 }
    .rightDiv .editDiv .total { font-size: 13px; font-weight: bold; }
    .rightDiv .editDiv .total span { color:#08bf7d; }
    .rightDiv .editDiv .cancelBtn { display:none; }
    #section_ce .editMode .checkDiv { display:block !important; opacity:1 !important; }
    #section_ce .editMode .cancelBtn, #section_ce .editMode .counting { display:inline-block !important; }
    #section_ce .editMode .total { display:none }
    .rightDiv .editDiv input[type='checkbox']:not(old):checked + label span { color:#000 !important; }
    
    /* .ctMenu */
    .ctMenu { transition:0.1s;margin-top: 13px; float:right; font-size:0; display: block; width: 34px; height: 34px; box-sizing:border-box; border-radius: 50%; padding: 8px 9px; border:1px solid #e7e9ea; background: #fff url(../../assets/images/contents/ic_setting_bk.png) no-repeat center center; background-size: 26px;}
    .ctMenu:hover  {  background-color:#f1f3f5; }
    .ctMenu span { float:left; display: block; width: 17px; height: 2px; margin:2px auto; background:#000; }
    .ctMenu.on span { display: none }
    .ctMenu.on { background:#f1f3f5 url(../../assets/images/contents/ic_close.gif) no-repeat center center; }
    .ctMenu.on:hover { background-color:#e7e9ea; }

    /* leftDivClosed 채팅창 체크박스 없음 */
    .magUl li.imgList { display:none; }

    .imgFileSchDiv {width:100%; overflow:hidden; height: 55px; line-height: 55px; box-sizing: border-box; font-weight: bold; border-bottom:1px solid #e7e9ea; }
    .imgFileSchDiv > * { display: inline-block; width: 50%;  float:left; }
    .imgFileSchDiv > div:first-child { width: 50%; }
    .imgFileSchDiv > div:last-child select { width: 100%; border-right: 0 none; }
    .imgFileSchDiv select { font-size: 12px; cursor:pointer; float:left; appearance:none; -webkit-appearance:none;background: #fff url(../../assets/images/side_area/bt_open.png) no-repeat 95% center; width: 100%; padding: 0 20px 0 15px; line-height: 55px; height: 55px;  box-sizing: border-box; border-right:1px solid #e7e9ea; font-weight: bold; border-radius: 0; }
    .imgFileSchDiv select option { font-size: 14px; }
    .imgFileSchDiv select:focus { border-color: #477fff; background-image: url(../../assets/images/side_area/bt_close.png) }

    /* .imgUl */
    .imgUl { overflow: hidden; }
    .imgUl > li { position:relative; float:left; display:inline-block; width: 99.65px; height: 100px; border-bottom: 1px solid #f1f3f5; border-right:1px solid #f1f3f5; box-sizing:border-box; }
    .imgUl > li a { position: absolute;left:0; top:0; display: block; width: 100%; height: 100%; font-size: 0;background-size: cover; background-position:center center;background-repeat: no-repeat; }
    .imgUl > li a:after { transition:0.2s; content:''; display: block; position: absolute; width: 100%; height: 100%; left: 0; top: 0; background:#000; opacity:0; }
    .imgUl > li:hover a:after { opacity:0.2;}
    .imgUl > li.on a:after { opacity:0.2;}
    .imgUl .delImg {display:none; opacity:0; z-index: 100; right: 10px; bottom:10px; width:25px; height: 25px; line-height: 25px; background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; position: absolute; font-size: 0; border-radius: 50%; }
    .imgUl > li:hover .delImg { opacity:1; }
    .imgUl > li.me .delImg, .rm .imgUl .delImg { display:block; }
    .imgUl > li .imgName { font-size:0; }

    .imgUl .ymList { display: none; overflow: hidden; width:100% !important; height: 40px; line-height: 38px; font-size: 14px; font-weight: bold; padding: 0 15px; box-sizing: border-box; background:#f7f9fb;border-top:1px solid #f1f3f5; box-sizing: border-box; }
    .imgUl .ymList span { color:#477fff; float: right; font-size: 13px; }
    .imgList .checkDiv { display:none !important; }

    #section_ce .imgList.editMode .ymList { display: none; }
    .imgList.editMode .checkDiv { display: block !important; opacity:1 !important; z-index: 10000; }
    .imgList.editMode .imgUl > li a:after { opacity:0.3;}
    .imgList.editMode .delImg { display:none !important; }
    .imgList li .checkDiv { left: 10px; top:10px; }
    .imgList li input[type='checkbox']:not(old) + label { padding: 0;width: 20px;height: 20px;border-radius:50%;border: 2px solid #fff;background: transparent url('../../assets/images/contents/checked_fff.png') no-repeat center center;background-size: 22px !important;font-size: 0;}
    .imgList li input[type='checkbox']:not(old):checked + label {background-color: #477fff; border-color:#fff;}
    .imgList li input[type='checkbox']:not(old) + label:after { z-index: 1000000; content:''; display:block; width:99.65px; height: 100px; position: absolute; left:-10px; top:-10px; }


    
    /* .fileList */
    .fileUl > li { position:relative; width:100%; display:inline-block; border-bottom: 1px solid #f1f3f5; box-sizing:border-box; padding: 10px 20px 10px 100px; }
    
    .fileList .checkDiv { display: none !important; }
    .fileList li { position: relative; height: 70px; }
    .fileList li:hover { background: #f7f9fb; }
    .fileList .file { background:#fff; position: absolute; left: 20px; top:13px; display: inline-block; width: 60px; height: 40px; border-radius: 5px; border:1px solid #e7e9ea; text-align: center; line-height: 40px;  }
    .fileList .file.format { font-weight: bold; color:#477fff; font-size: 12px; text-transform: uppercase; }
    .fileList .fileName { font-weight: bold; font-size: 12px; line-height: 30px; height: 25px; text-overflow:ellipsis; overflow: hidden; white-space: nowrap; }
    .fileList .fileSize { font-weight: bold; font-size: 12px; line-height: 20px; height: 20px; opacity:0.5; text-overflow:ellipsis; overflow: hidden; white-space: nowrap; }
    .delfile { display:none; position: absolute; right: 20px; top: 18px; width: 30px; height:30px;border-radius: 50%; font-size:0; background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center}
    .editMode.fileList .delfile { display: none !important; }
    .editMode.fileList .checkDiv { display:block !important; }
    .editMode.fileList li { padding-left: 135px; }
    .editMode.fileList .file { left: 55px; }
    .editMode.fileList li .checkDiv { top: 24px;}
    .fileList li:hover .delfile { display:block; }
    .fileList li:hover .delfile { display: none; }
    .fileList li.me:hover .delfile { display: block; }
    .rm .fileList li:hover .delfile { display: block; }
    


    /* 결과 없을 시  */
    .magDiv { position: relative; }
    .magDiv .noResult.on { display:block; }
    .magDiv .noResult { display:none; position: static; box-sizing: border-box; width: 100%; padding: 15px 20px; }
    .magDiv .noResult p { background:#f1f3f5; line-height: 60px; height: 60px; color: #93a0b1; font-size: 13px; } 


    /* 그룹 리스트 */
    .grpUl > li > div { overflow: hidden; position: relative; height: 115px; line-height: 65px; border-bottom: 1px solid #f1f3f5; box-sizing: border-box; padding: 0 110px 0 115px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;  }
    .grpUl > li > div .checkDiv { top: 25px; }
    .grpUl > li > div .makeRoom { height: 65px !important; line-height: 65px !important; }
    .grpUl > li > div .memberDiv { padding-top:10px; border-top:1px dashed #e7e9ea; overflow:hidden; position: absolute; bottom: 10px; width:325px; height: 31px; line-height: 31px; left: 53px; }
    .grpUl > li > div .memberDiv > * { float:left; }
    .grpUl > li > div .memberDiv p.first{ margin-left:0px; }
    .grpUl > li > div .memberDiv p { position:relative; width: 28px; height: 28px; border:2px solid #fff;box-sizing: border-box; margin-left:-7px;background-color:#fff; border-radius: 50%; font-size:0; background-size:cover; background-position: center center; background-repeat: no-repeat; }
    .grpUl > li > div .memberDiv p.last span {  position: absolute; background:rgba(0,0,0,0.6); width:100%; height: 100%; text-align: center; font-weight: bold; color:#fff;  border-radius: 50%; font-size: 11px !important; line-height: 24px ; text-shadow: 0 2px 2px rgb(0,0,0);}
    .grpUl > li > div .memberDiv .seeMembers { float:right; margin-top:1px; height: 23px; line-height: 23px; background:#f1f3f5; border:1px solid #e7e9ea; padding:0 15px; border-radius: 15px; font-weight: bold; }
    .grpUl > li > div:hover .memberDiv .seeMembers { background:#477fff; color:#fff; border-color:#477fff }
    .grpUl > li > div .memberDiv .seeMembers:after {  top:0; left:0;position: absolute; width: 100%; height: 100%; content:'';display:block; background: transparent; }
    .grpUl > li > div .memberDiv .seeMembers.on { background:#ff6363; font-size:0; color:#fff; }
    .grpUl > li > div .memberDiv .seeMembers.on:after { position:absolute; right:0; top:11px; padding:0 15px;height: 25px; line-height: 25px; width:100%; box-sizing:border-box; border-radius: 20px; display:block; content:'닫기'; text-align: center;  font-size: 12px !important;background:#ff6363; color:#fff;}
    .grpUl > li > div .memberDiv .seeMembers.on:hover:after {background:#ff3939}
    .grpUl > li > div:hover  { background:#f7f9fb; }
    .grpUl > li > div > * { float: left; }
    .grpUl > li > div.on { padding-right: 50px; }
    .grpUl > li > div.on .makeRoom { display:block !important; opacity: 1 !important; }
    .grpUl > li > div.on .oac  {display:none; }
    .grpUl > li .oac { transition:0.1s; position: absolute; right: 20px; width:auto; padding: 0 15px;height: 25px; box-sizing: border-box; border:1px solid #e7e9ea; line-height: 23px;top:21px; border-radius: 15px; font-size:12px; font-weight:bold; text-align:center;background:#fff; } 
    .grpUl > li > div:hover > .oac { right: 50px; }
    .grpUl > li .oac:hover { background:#fff; color:#477fff; border-color:#477fff }
    .grpUl > li .oac.on { background: #ff6363 url(../../assets/images/contents/ic_close_fff.gif) no-repeat center center; background-size: 15px; border-color:#ff6363; font-size:0; }
    .grpUl > li .grpName { font-size: 13px; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width:100%;}
    .grpUl > li .grpName span.clr { font-size:0; width: 8px; height:8px; margin-top:29px; margin-right:8px; border-radius: 5px; float:left; display: inline-block;  }
    .grpUl > li .grpPic { background-color:#fff; width: 45px; height: 35px;font-size: 0; border-radius: 5px; position:absolute; left: 55px; top:14px; border: 1px solid #f1f3f5; background-size: cover; background-position: center center;background-repeat: no-repeat;  }
    .grpUl > li .makeRoom { height: 60px !important; line-height: 60px !important; }
    .grpUl > li .checkDiv { top: 17px; }
    .grpUl > li div a.makeRoom { opacity:0 !important; }
    .grpUl > li div:hover a.makeRoom { opacity:1 !important; }
    .grpUl .grpLi.on div .checkDiv { display: block; }
    .grpUl .grpLi div .checkDiv { display: none; }
    .grpUl .grpLi > div { transition:0.1s; padding-left: 80px; } 
    .grpUl .grpLi > div .grpPic { transition:0.1s; left: 20px; }
    .grpUl .grpLi > div .memberDiv {transition:0.1s; left: 20px; width: 358px;  }
    .grpUl .grpLi.on > div { padding-left: 110px; }
    .grpUl .grpLi.on > div .grpPic { left: 55px; }
    .grpUl .grpLi.on > div .memberDiv { left: 53px; width: 325px; }

    /* 멤버 리스트 */
    .grpUl > li.on .memberUl { display: block;}
    .memberUl { display:none; }
    .memberUl {border-bottom: 3px solid #e7e9ea;}
    .memberUl li:hover { background:#f7f9fb !important; }
    .memberUl li .mName span { display:inline-block; width: 20px; }
    .memberUl li .makeRoom { opacity:0 !important }
    .memberUl li:hover .makeRoom { opacity:1 !important }


    /* 팀리스트 */
    .teamList { display:none !important; }
    .teamList.teamOn { display:block !important; }
    .teamList { position: absolute; top:0; height:100%; left:0; background:#fff; width:100%; }
    .teamList .grpLiDiv { height: 65px; }
    .teamList .memberUl.on { display:block; }
    .teamList .mainGrp  { font-weight: bold; overflow: hidden; position: relative; padding: 0 25px 0 115px; line-height: 45px; height: 70px; border-bottom:2px solid #e7e9ea; }
    .teamList .mainGrp:hover { background: #f7f9fb }
    .teamList .mainGrp > * { float: left; }
    .teamList .mainGrp .prev { position: absolute;left:0;top:0; display: inline-block;float: left;width: 45px;height: 100%;background:  url(../../assets/images/contents/month_bt_prev.svg) no-repeat 8px center; background-size: 24px; font-size: 0; }
    .teamList .mainGrp .prev:hover { background-image: url(../../assets/images/contents/month_bt_prev_on.svg) }
    .teamList .mainGrp .grpPic { position: absolute; left: 45px; top: 13px; display: block; border-radius: 5px; border: 1px solid #f1f3f5; background-size: cover; background-position: center center; background-repeat: no-repeat; width: 55px; height: 45px; box-sizing: border-box; font-size: 0; ; }
    .teamList .mainGrp .grpName { font-size: 14px; width:100%; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; }
    .teamList .mainGrp .grpName span.clr { font-size:0; width: 8px; height:8px; margin-top:19px; margin-right:8px; border-radius: 5px; float:left; display: inline-block; }
    .teamList .mainGrp .oac { position: absolute; left:115px; bottom:14px; top: auto; line-height: 19px; height:19px;  width:100%; overflow:hidden; text-overflow: ellipsis; white-space: nowrap;}
    .teamList .mainGrp .oac span { float: left; display:inline-block; height: 100%; padding: 0 8px; margin-right:10px; border-radius: 3px; background:#f1f3f5; }
    .teamList .mainGrp:hover .oac span { background:#fff; }
    .teamList .mainGrp .makeRoom { height: 70px; line-height: 70px; opacity:1 !important  }

    .teamList .grpUl .oac:hover { background:#477fff; border-color:#477fff; color:#fff; }
    .teamList .grpUl .oac.on { font-size:0; color:#fff; background:#fff; }
    .teamList .grpUl .oac.on:after { transition:0.2s; position:absolute;background:#ff6363; right:-1px; top:-2px; padding:0 16px;height: 27px; line-height: 27px; box-sizing:border-box; border-radius: 20px; display:block; content:'닫기'; text-align: center;  font-size: 12px !important;background:#ff6363; color:#fff;}
    .teamList .grpUl .oac.on:hover:after {background:#ff3939}

    .teamList .grpLi.on div .checkDiv { display: block; }
    .teamList .grpLi div .checkDiv { display: none; }
    .teamList .grpLi > div { transition:0.1s; padding-left: 80px; } 
    .teamList .grpLi > div .grpPic { transition:0.1s; left: 20px; }
    .teamList .grpLi > div .memberDiv {transition:0.1s; left: 20px; width: 358px;  }
    .teamList .grpLi.on > div { padding-left: 110px; }
    .teamList .grpLi.on > div .grpPic { left: 55px; }
    .teamList .grpLi.on > div .memberDiv { left: 53px; width: 325px; }


    
    /* .rightSettingDiv */
    .rightSettingDiv { display:none; }
    .rightSettingDiv.on { z-index: 100000; display: none; position: absolute; left: 0;top:60px; width:100%; height: 824px; background: #fff; }
    .rightSettingDiv.on { display: block }
    .rightSettingDiv li { position: relative; padding:0 60px 0 20px; height: 50px; line-height: 49px; border-bottom: 1px solid #f1f3f5; box-sizing:border-box; } 
    .rightSettingDiv li p { font-size: 12px; font-weight: normal; width:100%; text-overflow:ellipsis; overflow: hidden; white-space: nowrap; }
    .rightSettingDiv li:hover p { font-weight: bold; }
    .rightSettingDiv li p span.dot { display: inline-block; width: 5px; height: 5px; margin-right:8px; opacity:0.2; border-radius: 50%; background:#000; }
    .rightSettingDiv li .btn { position: absolute; right:0; top:0; padding:0;}
    .rightSettingDiv li .btn input { height: 50px; line-height: 49px; width: 60px; padding-right:25px; box-sizing:border-box; text-align: right; background:transparent; font-size: 12px; float:right }
    .rightSettingDiv li .btn input:hover { color:#ff6363;font-weight: bold; }
    .rightSettingDiv .rightSetting { padding-bottom: 100px; }
    .rightSettingDiv li .btn input.chngeClr { transition:0.2s; width: 25px; height: 25px; border-radius: 50%; border: 1px solid #e7e9ea; font-size:0;margin-right: 20px;margin-top: 12px;float: right; box-sizing:border-box; padding:0; }
    .rightSettingDiv li .btn input.chngeClr:hover { border:3px solid #e7e9ea; }
    .rightSettingDiv .title { border-bottom:1px solid #f1f3f5; background:#f1f3f5; height: 40px; line-height: 40px; padding:0 20px; font-size: 13px; font-weight: bold; }
    .rightSettingDiv .delThisRoom { color:#ff6363; }
    .rightSettingDiv li.delThisRoom p span { background-color:#ff6363; }
    .rightSettingDiv .mainTitle p.dets span { opacity: 0.5; display:block; height: 25px; line-height: 25px; font-weight: bold; }
    .rightSettingDiv .mainTitle > div { padding-left: 100px; box-sizing: border-box; }
    .rightSettingDiv .mainTitle { overflow: hidden; position:relative; padding: 10px 20px 10px; box-sizing:border-box; width:100%; }
    .rightSettingDiv .mainTitle p { padding: 10px 0 10px !important;}
    .rightSettingDiv .mainTitle p.title { padding:0; background: none; border-bottom:0 none; height: auto;line-height: unset;padding-right: 0 !important; font-size: 14px; margin-bottom:5px;    margin-bottom: 10px;
    padding-top: 0 !important; padding-bottom:0 !important }
    .rightSettingDiv .mainTitle p.descript { height: auto; line-height: 20px;margin-top: 20px;display: block;border-top: 1px solid #e7e9ea; }
    .rightSettingDiv .mainTitle input { position: absolute;right: 20px;top: 20px; height: 30px; line-height: 30px; width: 30px; border-radius: 50%; font-weight: bold; background:#ffbe0f url(../../assets/images/folder_file/fd_study.png) no-repeat -5px -3px; background-size: 45px; font-size: 0; }
    .rightSettingDiv .mainTitle input:hover { background-color:#fbb700 }
    .rightSettingDiv .mainTitle .crImg { position: absolute; left:20px; top:10px; float: left; display: block;width: 80px;margin: 0 auto; height: 80px; border:1px solid #f1f3f5; border-radius: 10px; font-size: 0;margin-bottom:5px; margin-top:10px; padding:0 !important; background-size: cover; background-position: center center; background-repeat: no-repeat }

    /* 채팅룸 추가 버튼 */
    .addBtn  { position:fixed;right:490px; bottom:40px; opacity: 1;z-index: 1000;transition: 0.1s; font-size: 0;border-radius: 50%;display: block;width: 60px;height: 60px; background: #477fff url('../../assets/images/contents/ic_addChatroom_fff.png') no-repeat center center;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4);background-size: 40px !important; }
    .addBtn:hover {background: #477fff url('../../assets/images/contents/ic_addChatroom_fff.png') no-repeat center center;transition: 0.1s;box-shadow: 0 10px 25px rgba(71, 127, 255, 0.5);background-size: 45px !important;}
    .addBtn.quick { right: 180px; }
    .addBtn.dntknow { right: 300px; }
    .section_ce_fix.rightDivClosed .addBtn { right: 160px; }
    
    /* 임의로 추가한 css 문제될시 변경 */
    .imgUl > li .img.no-img { background-image: url(../../assets/images/contents/groupImg.png) !important; border : #fff solid 1px; }
    
    .chatroomUl li.specList .peepsDiv.one p { background-image: url(../../assets/images/footer/g7.jpg) !important; }

    .editBtn.invite { background-color: #477fff !important; color : #fff; }
    .editBtn.invite:hover { background-color: #296aff !important; }

    .error_detect_img { display : none; }
    .mPic.no-img { background-color : #f1f3f5 !important; background-image: url('../../assets/images/contents/im_photoB.gif') !important; }

    .magUl li.mateList.chatting .mateUl li input[type="button"].mandate { right : 60px; background-image : url('../../assets/images/contents/ic_manager.png'); background-color : #fff; background-size: 18px; background-position: center center; background-repeat: no-repeat; border : solid 1px #f1f3f5; }
    .magUl li.mateList.chatting .mateUl li input[type="button"].mandate:hover { background-image : url('../../assets/images/contents/ic_king.png'); }

    .magUl li.mateList.chatting .mateUl li.owner input[type="button"].mandate { background-image : url('../../assets/images/contents/ic_king.png'); }
    .magUl li.mateList.chatting .mateUl li.owner input[type="button"].mandate:hover { background-image : url('../../assets/images/contents/ic_manager.png'); }
    
    .chngeClr.bgc0 { background-color : #FFE0E0 !important; }
    .chngeClr.bgc1 { background-color : #FFEDCF !important; }
    .chngeClr.bgc2 { background-color : #FFF4D5 !important; }
    .chngeClr.bgc3 { background-color : #FFD1E8 !important; }
    .chngeClr.bgc4 { background-color : #CCF0F4 !important; }
    .chngeClr.bgc5 { background-color : #D0F6E8 !important; }
    .chngeClr.bgc6 { background-color : #DBF1FF !important; }
    .chngeClr.bgc7 { background-color : #DAE5FF !important; }
    .chngeClr.bgc8 { background-color : #E1DDFF !important; }
    .chngeClr.bgc9 { background-color : #D7D9DE !important; }
</style>    