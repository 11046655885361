<template>
<!-- 업무 현황 -->
<!-- ********** 체크와 단계 현황 모달과 모두 동일하며 .ul1 > li > a span.txt 내용만 변경  -->
<div class="modal remodal workStatus basicStatus" style="width:auto;">
	<button class="remodal-close" @click="modalClose"><span></span><span></span></button>
	<div class="left_titleBox">
		<h1 class="">프로젝트 현황</h1>
		<div class="btns">
			<input type="button" class="refreshBtn fr" value="새로고침" @click="getBasicStatus"/>
			<p class="refreshDescript">{{ replash_times }}</p>
		</div>
	</div>
	<div class="remodal-content remodal-content07">
		<div class="bg"></div>
		<div class="titleBg">
			<h2>
                <a style="cursor:default;">
                    <span class="dot" :style="{ backgroundColor : `${hodu_hex_color_process(work_data ? work_data.event_data.color : null)} !important`}" ></span>
                    {{ work_data ? work_data.event_data.title : '' }}
                </a>
            </h2>
		</div>
        <div id="statusScroll">
            <div class="chartBg two" :class="{ on : see_chart == true }">
                <div class="details2">
                    <!-- 업무명 -->
                    <h3>기본프로젝트</h3>
                    <p class="status">
                        이 프로젝트는 <span>기본프로젝트</span>입니다 현재 <span>{{ work_data ? work_data.status_info.length : ''}}</span>명에게 배정되었으며 그 중 
                        <span>{{ getStartOrEndWorkStatusCount() }}</span>명이 프로젝트를 수행중입니다.
                    </p>
                </div>
                <input type="button" class="seeMore" value="차트 보기" @click="chartOnOff"/>
                <div class="BarChart">
                    
                    <ul class="barUl two">
                        <li :class="{ zero : getStartWorkStatusCount() == 0 }" :style="{ width : getStartProgress() }">
                            <span class="num">{{ getStartWorkStatusCount() }}</span><span class="dc2 dcG txt">현재 진행 중 담당자</span>
                        </li>

                        <li :class="{ zero : getEndWorkStatusCount() == 0 }" :style="{ width : getEndProgress() }">
                            <span class="num">{{ getEndWorkStatusCount() }}</span><span class="dc7 dcG txt">완료한 담당자</span>
                        </li>
                    </ul>

                    <ul class="details">
                        <li :class="{ zero : getStartWorkStatusCount() == 0 }">
                            <span class="dot dc2"></span><span class="txt">진행 중</span><span class="num">{{ getStartWorkStatusCount() }}</span>
                        </li>

                        <li :class="{ zero : getEndWorkStatusCount() == 0 }">
                            <span class="dot dc7"></span><span class="txt">완료</span><span class="num">{{getEndWorkStatusCount()}}</span>
                        </li>
                    </ul>
                    
                </div>
            </div>
            <div class="people">
                <h3 class="total">전체 <span>{{ getStartOrEndWorkStatusCount() }}명  업무중</span></h3>
                <ul class="ul1 check">

                    <li :class="{ on : start_list_on == true }">
                        <p class="step dc2">진행 중</p>

                        <a @click="startListOnOff">
                            <span class="txt">현재 진행 중인 담당자</span>
                            <span class="seper"></span>
                            <span class="num">{{ getStartWorkStatusCount() }}</span>
                            <span class="btn">열기</span>
                        </a>
                        
                        <!-- 진행하는 사람이 없을경우 -->
                        <ul v-if="getStartWorkStatusCount() == 0">
                            <li class="noResult">프로젝트를 진행 중인 담당자가 없습니다</li>
                        </ul>

                        <!-- 진행하는 사람이 있을경우 -->
                        <ul v-else>
                            
                            <li :key="worker.user_id" v-for="worker in getStartWorker">
                                <img :src="`/app_images/profile/user/${Math.floor((worker.user_id ? worker.user_id : 0) / 10000)}/${worker.user_id}.jpg`" alt="프로필 사진" @error="imageError($event)"/>
                                <p class="name">{{ worker.user_name }}</p>
                            </li>

                        </ul>
                    </li>

                    <li :class="{ on : end_list_on == true }">
                        <p class="step dc7">완료</p>

                        <a @click="endListOnOff">
                            <span class="txt">현재 완료한 담당자</span>
                            <span class="seper"></span>
                            <span class="num">{{ getEndWorkStatusCount() }}</span>
                            <span class="btn">열기</span>
                        </a>

                        <!-- 업무를 완료한 사람이 없는 경우 -->
                        <ul v-if="getEndWorkStatusCount() == 0">
                            <li class="noResult">프로젝트를 완료한 담당자가 없습니다</li>
                        </ul>

                        <!-- 업무를 완료한 사람이 있는 경우 -->
                        <ul v-else>

                            <li class="done" :key="worker.user_id" v-for="worker in getEndWorker">
                                <img :src="`/app_images/profile/user/${Math.floor((worker.user_id ? worker.user_id : 0) / 10000)}/${worker.user_id}.jpg`" alt="프로필 사진" @error="imageError($event)"/>
                                <p class="name">{{ worker.user_name }}</p>
                            </li>

                        </ul>
                    </li>

                </ul>

                <div class="people2">
                    <h3>시작 전</h3>
                    <p class="sub">현재 <span class="total">{{ work_data ? work_data.status_info.length - getStartOrEndWorkStatusCount() : 0 }}</span>명이 이 프로젝트를 시작하지 않았습니다.</p>
                    <ul class="ul2">
                        <li :key="worker.user_id" v-for="worker in getWaitWorker">
                            <img :src="`/app_images/profile/user/${Math.floor((worker.user_id ? worker.user_id : 0) / 10000)}/${worker.user_id}.jpg`" alt="프로필 사진" @error="imageError($event)"/>
                            <p class="name">{{ worker.user_name }}</p>
                        </li>
                    </ul>   
                </div>

            </div>
        </div>
	</div>
</div>
<!-- //업무 현황 -->
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import moment from 'moment';
import { ResizeObserver } from 'vue-resize';

import { namespace } from 'vuex-class';
const WorkInfo = namespace('WorkInfo');
const ModalInfo = namespace('ModalInfo');

import { WorkStatusInfo } from '@/store/modules/WorkInfo';

@Component({
    components: {
        ResizeObserver
    }
}) export default class WorkDefaultStatus extends Mixins(VueHoduCommon) {
    
    /**
     * @WorkInfo.State
     */
    @WorkInfo.State work_status_info !: WorkStatusInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowWorkDefaultStatus ?: any;

    /**
     * 변수 선언
     */
    work_data     : any     = null;  // 업무 데이터
    replash_times : string  = "";    // 최종적으로 업무 데이터가 로드된 시점의 시간 텍스트 (ex : 2019.12.20 11:22:54 AM) 
    see_chart     : boolean = false; // 차트 자세히 보기

    start_list_on : boolean = false;
    end_list_on   : boolean = false;

    get getWaitWorker() : any[] {
        if( !this.work_data ) {
            return [];
        }

        return this.work_data.status_info.filter(status => status.work_status_info.progress[0].status == 'WAIT');
    }

    get getStartWorker() : any[] {
        if( !this.work_data ) {
            return [];
        }

        return this.work_data.status_info.filter(status => status.work_status_info.progress[0].status == 'START');
    }
    
    get getEndWorker() : any[] {
        if( !this.work_data ) {
            return [];
        }

        return this.work_data.status_info.filter(status => status.work_status_info.progress[0].status == 'END');
    }

    mounted() {
        // 기본업무 조회
        this.getBasicStatus();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height    : number | undefined = $('.left_titleBox').outerHeight();
        const title_bg_height : number | undefined = $('.titleBg').outerHeight();
        const final_height    : number = window.innerHeight - (title_height ? title_height : 0) - (title_bg_height ? title_bg_height : 0);

        // @ts-ignore
        $("#statusScroll").mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : final_height
        });
    }

    /**
     * 기본업무 조회
     */
    async getBasicStatus() : Promise<void> {
        
        await this.hodu_api_call(`api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/status/${this.work_status_info.event_id}`, API_METHOD.GET)
			.then(async(response) => {
                console.log(response);
                this.work_data = response.data.data.list[0];
                this.replash_times = this.amPmStringToLocaleAmPmString(moment(new Date()).format('YYYY.MM.DD hh:mm:ss A'));
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
                this.doSetShowWorkDefaultStatus(false);
            })
    }

    /**
     * 차트 보기 ON / OFF
     */
    chartOnOff() : void {
        const vue = this;
        this.see_chart = !this.see_chart;

        // 첫번쨰 리스트에, 애니메이션 용
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(0).find(".txt").toggleClass("ani");
            if( vue.see_chart == true ) {
                $(".chartBg").find(".barUl li").eq(0).find(".txt").css('height', vue.getStartProgress(true));
            } else {
                $(".chartBg").find(".barUl li").eq(0).find(".txt").removeAttr("style");
            }
        }, 200);

        // 두번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(1).find(".txt").toggleClass("ani");
            if( vue.see_chart == true ) {
                $(".chartBg").find(".barUl li").eq(1).find(".txt").css('height', vue.getEndProgress(true));
            } else {
                $(".chartBg").find(".barUl li").eq(1).find(".txt").removeAttr("style");
            }
        }, 205);
        
        setTimeout(function(){
            $(".chartBg").find(".barUl li").find(".txt").removeClass("ani");
            $(".chartBg").find(".barUl li").find(".txt").toggleClass("transit2");
        }, 300);
        
    }

    /**
     * 업무를 시작 또는 완료한 사람 수 반환
     */
    getStartOrEndWorkStatusCount() : number {
        if( !this.work_data ) {
            return 0;
        }

        let start_or_end_count : number = 0;

        for( let status of this.work_data.status_info ) {
            if( status.work_status_info.progress[0].status != 'WAIT' ) {
                start_or_end_count++;
            }
        }

        return start_or_end_count;
    }

    /**
     * 업무 진행중인 사람 수 반환
     */
    getStartWorkStatusCount() : number {
        if( !this.work_data ) {
            return 0;
        }

        let start_count : number = 0;

        for( let status of this.work_data.status_info ) {
            if( status.work_status_info.progress[0].status == 'START' ) {
                start_count++;
            }
        }

        return start_count;
    }

    /**
     * 업무 완료한 사람 수 반환
     */
    getEndWorkStatusCount() : number {
        if( !this.work_data ) {
            return 0;
        }

        let end_count : number = 0;

        for( let status of this.work_data.status_info ) {
            if( status.work_status_info.progress[0].status == 'END' && status.work_status_info.is_end == true ) {
                end_count++;
            }
        }

        return end_count;
    }
    
    /**
     * 업무 진행중 progress 구하기
     */
    getStartProgress(is_vertical : boolean = false) : string {
        if( this.getStartOrEndWorkStatusCount() == 0 ) {
            return '50%';
        }

        // 세로 막대
        if( is_vertical == true ) {
            if( this.work_data == null || this.work_data.status_info.length < 1 ) {
                return '0%';
            }

            return `${ this.getStartWorkStatusCount() == 0 ? '3' : this.getStartWorkStatusCount() / this.work_data.status_info.length * 100 }%`;
        }

        // 완료가 0명인경우 3% 기본값 빼준다
        if( this.getEndWorkStatusCount() == 0 ) {
            return `${ ((this.getStartWorkStatusCount() / this.getStartOrEndWorkStatusCount() * 100) - 3).toFixed(10) }%`;
        }

        return `${ this.getStartWorkStatusCount() == 0 ? '3' : (this.getStartWorkStatusCount() / this.getStartOrEndWorkStatusCount() * 100).toFixed(10) }%`;
    }

    /**
     * 업무 완료 progress 구하기
     */
    getEndProgress(is_vertical : boolean = false) : string {
        if( this.getStartOrEndWorkStatusCount() == 0 ) {
            return '50%';
        }

        // 세로 막대
        if( is_vertical == true ) {
            if( this.work_data == null || this.work_data.status_info.length < 1 ) {
                return '0%';
            }

            return `${ this.getEndWorkStatusCount() == 0 ? '3' : this.getEndWorkStatusCount() / this.work_data.status_info.length * 100 }%`;
        }

        // 시작이 0명인경우 3% 기본값 빼준다
        if( this.getStartWorkStatusCount() == 0 ) {
            return `${ ((this.getEndWorkStatusCount() / this.getStartOrEndWorkStatusCount() * 100) - 3).toFixed(10) }%`;
        }

        return `${ this.getEndWorkStatusCount() == 0 ? '3' : (this.getEndWorkStatusCount() / this.getStartOrEndWorkStatusCount() * 100).toFixed(10) }%`;
    }

    /**
     * 진행중 리스트 접기 / 펼치기
     */
    startListOnOff() : void {
        this.start_list_on = !this.start_list_on;
    }

    /**
     * 완룐 리스트 접기 / 펼치기
     */
    endListOnOff() : void {
        this.end_list_on = !this.end_list_on;
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require("@/assets/images/contents/im_photoB.gif");
    }

    /**
     * 모달 닫기
     */
    modalClose() : void {
        this.doSetShowWorkDefaultStatus(false);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#statusScroll").mCustomScrollbar('destroy');

        this.setScroll();
    }

    
}

</script>

<style scoped>
    .modal {position:absolute; background:#fff; width:100%; min-width:800px;right:0;top:0;  }
    .workStatus .people .ul1.check > li:after { background : transparent !important; }
    .workStatus { background: #f1f3f5; }
    .workStatus .left_titleBox { background: #ffffff; border-radius: 0px !important; }
    .workStatus .titleBg { background: #ffffff; }
    .workStatus .chartBg { background: #ffffff; }
    .workStatus .people { background: #ffffff; }
    .people2 > .btn p { text-align:center; }
</style>