<template>
    <div id="organization_vacation_detail_modal" class="modal">
        <div class="titleBox">
            <!-- ing 대기, rejected 거절, approved 승인 -->
            <h1 :class="{
                ing : approval != null && approval.approval_state == 'ING', 
                approved : approval != null && approval.approval_state == 'COMPLETE', 
                rejected : approval != null && approval.approval_state == 'REJECT',
            }">
                <span class="icon"></span>{{ approval == null ? '' : getStatusText(approval) }}
            </h1>
        </div>
        <div class="content"> 
            <div class="detail" v-if="approval != null && user_employee_info != null">
                <div class="leftContent">
                    <p class="img" :style="{ backgroundImage : `url(app_images/profile/user/${Math.floor(approval.user_id / 10000)}/${approval.user_id}.jpg)` }">사진</p>
                    <img class="dummy" :src="`app_images/profile/user/${Math.floor(approval.user_id / 10000)}/${approval.user_id}.jpg`" @error="userImageError($event)"/>
                    <p class="name">{{ approval.approver[0].user_name }}</p>
                    <p class="pos">{{ approval.approver[0].pos_name }}</p>
                    <p class="team">{{ approval.approver[0].dept_name }}</p>
                    <p class="email">{{ user_employee_info.user_email && user_employee_info.user_email.length > 0 ? user_employee_info.user_email : '-' }}</p>
                    <p class="tel">{{ user_employee_info.user_phone_number && user_employee_info.user_phone_number.length > 0 ? user_employee_info.user_phone_number : '-' }}</p>
                </div>
                <div class="context">
                    <ul class="detailUl">
                        <li>
                            <p class="title">요청일</p>
                            <p class="descript">{{ `${hodu_date_to_format_string(approval.audit_created, 'YYYY.MM.DD')} ${getDayOfWeekByDate(approval.audit_created)}` }}</p>
                        </li>
                        <li>
                            <p class="title">휴가 종류</p>
                            <p class="descript">{{ approval.contents.vacation_type_name }}</p>
                        </li>
                        <li>
                            <p class="title">휴가 일자</p>
                            <p class="descript">{{ getDateText(approval) }}</p>
                        </li>
                        <li>
                            <p class="title">휴가 수</p>
                            <p class="descript">{{ approval.contents.vacation_type_daycount != 0 ? `${processDayCount(approval.contents.vacation_type_daycount)} 일` : `${approval.contents.vacation_selected.length} 일` }}</p>
                        </li>
                        <li class="reason">
                            <p class="title">사유</p>
                            <textarea class="descript" disabled v-model="approval.contents.comment"></textarea>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 삭제여부는 다시 기획해서 선택 -->
            <!-- <div class="btns" :class="{ b1 : isDisabled() == true }"> -->
            <div class="btns b1">
                <input type="button" value="닫기" @click.prevent="close"/>
                <!-- <input type="button" value="반려" v-if="isDisabled() == false" /> -->
                <!-- <input type="button" class="save" value="승인" v-if="isDisabled() == false"/> -->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';
import { approval_enum } from '@/model/approval';

// TODO 사용하면 vacation_selected 대응 해야함
@Component({
    components: {
        
    },
})
export default class OrganizationVacationDetailModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_vacation_detail_modal_info  !: organization_modal_interface.OrganizationVacationDetailModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationDetailModalInfo ?: (params : organization_modal_interface.OrganizationVacationDetailModalInfo) => void;

    employees : any[] = [];

    user_employee_info : any = null;

    approval : any = null;

    async mounted() : Promise<void> {
        await Promise.all([this.getOrganizationEmp(), this.getSelectedApprovalInfo()]);

        if( this.approval == null ) {
            this.close();
            return;
        }

        const target = this.employees.filter(employee => employee.user_id == this.approval.approver[0].user_id);

        if( target.length < 1 ) {
            this.close();
            return;
        }

        this.user_employee_info = target[0];
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 기안서 조회
     */
    async getSelectedApprovalInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.organization_vacation_detail_modal_info.approval_uid}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("휴가원 조회 중 오류 발생");
            }

            const approval_info = response.data.data.approval_info;

            this.approval = JSON.parse(JSON.stringify(approval_info));

        } catch(e) {
            alert("휴가원 조회 중 오류 발생")
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 상태에 따른 텍스트 반환
     */
    getStatusText(approval : any) : string {
        switch(approval.approval_state) {
            case approval_enum.APPROVAL_STATE.ING:
                return "승인 대기중";
            case approval_enum.APPROVAL_STATE.CANCEL:
                return "취소됨";
            case approval_enum.APPROVAL_STATE.COMPLETE:
                return "승인 됨";
            case approval_enum.APPROVAL_STATE.REJECT:
                return "반려 됨";
        }

        return "";
    }

    /**
     * 휴가 날짜 텍스트 반환
     */
    getDateText(approval : any) : string {

        const vacation_start = approval.contents.vacation_start;
        const vacation_end = approval.contents.vacation_end;

        if( vacation_start == vacation_end ) {
            const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
            return `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)}`;
        }

        const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
        const target_end = [vacation_end.substring(0,4), vacation_end.substring(4,6), vacation_end.substring(6,8)].join('-');

        return `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)} ~ ${this.hodu_date_to_format_string(target_end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_end)}`;
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 사용자 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('p.img').css('background-image', require('@/assets/images/contents/ic_approval_user_on.png'))
    }

    /**
     * 체크 비활성화 여부
     */
    isDisabled() : boolean {
        if( this.approval == null ) return true;
        const target = this.approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);
        return target.length < 1;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationVacationDetailModalInfo?.({
            show_modal : false,
            approval_uid : ""
        });
    }

}
</script>

<style scoped>

    .modal { position:absolute; left:50%; top:50%; margin-top:-265px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:530px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    .modal h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    .modal .detail { overflow:hidden; }

    .modal .context { float:left; border-left:1px solid #e7e9ea; width: 60%; height:368px; box-sizing: border-box; }
    
    .modal .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px; width: 100% }
    .modal .btns input {  line-height:75px; height: 75px; background:#fff; width:33.33333%; float: left; font-size:14px; font-weight: bold;; }
    .modal .btns input.save:hover { color:#477fff; }
    .modal .btns input:hover { background:#f1f3f5; }

    .modal textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 113px; max-height: 113px; font-size: 13px;line-height: 20px; padding: 15px; height:auto; box-sizing: border-box; font-weight:bold; }
    .modal textarea:hover { border-color:#477fff }
    .modal textarea:focus { background:#fff; border-color:#477fff;outline: none;  }
    
    #organization_vacation_detail_modal { width: 800px; margin-left: -400px; }
    #organization_vacation_detail_modal .titleBox { position: relative; text-align: center; }
    #organization_vacation_detail_modal h1 { display: inline-block; }
    #organization_vacation_detail_modal h1 .icon { float: left; margin: 34px 15px 0 0; display: inline-block;  width: 27px; height: 27px; border-radius: 50%;background-repeat: no-repeat; background-position: center center;  }
    #organization_vacation_detail_modal h1:after { z-index: 100; content: ''; display: block; position: absolute; left: 0; bottom: 0; height: 3px; width: 100%; background:#ffbc49 }
    #organization_vacation_detail_modal h1.approved:after { background-color: #477fff; }
    #organization_vacation_detail_modal h1.rejected:after { background-color: #ff6363 }
    #organization_vacation_detail_modal h1.ing .icon {background-color: #ffbc49;border: 1px solid #ffa70e;}
    #organization_vacation_detail_modal h1.rejected .icon {background-color: #ff6363;border: 1px solid #ff2e2e; background-image: url(../../../assets/images/contents/ic_close_fff.gif); background-size: 15px;}
    #organization_vacation_detail_modal h1.approved .icon {background-color: #477fff;border: 1px solid #0b55ff;background-image: url('../../../assets/images/contents/checked_fff.png');}
    #organization_vacation_detail_modal .btns input { width : 33.3%; }
    #organization_vacation_detail_modal .btns.b1 input { width : 100% }

    #organization_vacation_detail_modal .context { overflow: hidden; float: left; width:60%; }
    #organization_vacation_detail_modal .leftContent { float:left; width: 40%; background:#f1f3f5; text-align: center; height: 368px; box-sizing: border-box; padding: 0 40px }
    #organization_vacation_detail_modal .leftContent p { width: 100%; font-weight: bold; line-height: 25px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
    #organization_vacation_detail_modal .leftContent p.name { font-size: 18px; margin: 16px 0 10px; }
    #organization_vacation_detail_modal .leftContent p.pos { margin-top:-8px; font-size: 14px; }
    #organization_vacation_detail_modal .leftContent p.team { padding-bottom: 10px; border-bottom: 1px solid #fff; margin-bottom: 10px; }
    #organization_vacation_detail_modal .leftContent p.email,#organization_vacation_detail_modal .leftContent p.tel {color:#989bab}
    #organization_vacation_detail_modal .leftContent .img { margin:0 auto; margin-top:35px; width: 120px; height: 120px; border-radius: 50%; background: url(../../../assets/images/contents/ic_approval_user_on.png) no-repeat center center; background-size: cover; box-shadow: 0 5px 10px rgba(0,0,0,0.1); border:8px solid #fff; font-size: 0; }
    #organization_vacation_detail_modal .detailUl { width: 100%; box-sizing: border-box; }
    #organization_vacation_detail_modal .detailUl li { transition: 0.2s; overflow: hidden; line-height: 53px; height: 53px; border-bottom: 1px solid #f1f3f5; box-sizing: border-box; padding: 0 30px; width:100%; }
    #organization_vacation_detail_modal .detailUl li .descript { float: right; font-weight: bold; width: 320px; text-align: right; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #organization_vacation_detail_modal .detailUl li.reason {height: 156px; padding-right:0;}
    #organization_vacation_detail_modal .detailUl li.reason .descript { padding-right: 30px !important; width: 100%; float: left; text-align: left; line-height: 20px; margin-top: -10px;overflow:auto; text-overflow: unset; white-space: normal; }
    #organization_vacation_detail_modal .detailUl li.reason .descript:disabled { background:#fff; border:0 none; padding: 0; color:#232848 }
    #organization_vacation_detail_modal .detailUl li:last-child { border-bottom: 0 none; }
    #organization_vacation_detail_modal .detailUl li * {  display: inline-block; }
    #organization_vacation_detail_modal .detailUl li:hover * {  }
    /* #organization_vacation_detail_modal .detailUl li:hover { background: #f7f9fb} */

    #organization_vacation_detail_modal.notRejected .detailUl li { height: 62px; line-height: 62px; }

    img.dummy { display : none; }
    
</style>