<template>
    <div id="organization_quitter_setting" class="section_scroll">
        <div class="viewGroup">
            <div class="fixedDiv">
                <ul class="sortHeader groupNoticeHeader">
                    <li class="title">
                        <span>이름</span>
                    </li>
                    <li class="group">
                        <span>부서</span>
                    </li>
                    <li class="quitDate">
                        <span>퇴사일</span>
                    </li>
                    <li class="careerPrint">
                        <span>증명서</span>
                    </li>
                </ul>
            </div>

            <div id="organization_quitter_setting_scroll" class="content">
                <ul class="notiListUl">

                    <li :key="index" v-for="(quitter, index) in quitters">
                        <p class="title">
                            <span>{{ quitter.quitter_data.name }}</span>
                        </p>
                        <p class="group">
                            <span>{{ quitter.quitter_data.dept_name }}</span>
                        </p>
                        <p class="quitDate">
                            <!-- <label class="to blind">퇴사일자</label> -->
                            <input type="button" :value="hodu_date_to_format_string(yyyymmddToDate(quitter.quitter_data.end_date), 'YYYY.MM.DD')" class="calTo" @click.prevent="changeEndDate(quitter)">
                        </p>
                        <p class="careerPrint">
                            <input type="button" value="경력증명서 발급" class="dwnldBtn" @click.prevent="print(quitter)">
                        </p>
                    </li>
                    <!-- <li>
                        <p class="title">
                            <span>김호두</span>
                        </p>
                        <p class="group">
                            <span>경영</span>
                        </p>
                        <p class="quitDate">
                            <label class="to blind">퇴사일자</label>
                            <input type="button" id="report_to_ym" value="2024.07.24" class="calTo hasDatepicker">
                        </p>
                        <p class="careerPrint">
                            <input type="button" value="경력증명서 발급" class="dwnldBtn">
                        </p>
                    </li>
                    <li>
                        <p class="title">
                            <span>이퇴사</span>
                        </p>
                        <p class="group">
                            <span>디자인</span>
                        </p>
                        <p class="quitDate">
                            <label class="to blind">퇴사일자</label>
                            <input type="button" id="report_to_ym" value="2024.05.20" class="calTo hasDatepicker">
                        </p>
                        <p class="careerPrint">
                            <input type="button" value="경력증명서 발급" class="dwnldBtn">
                        </p>
                    </li>
                    <li>
                        <p class="title">
                            <span>안이요</span>
                        </p>
                        <p class="group">
                            <span>경영</span>
                        </p>
                        <p class="quitDate">
                            <label class="to blind">퇴사일자</label>
                            <input type="button" id="report_to_ym" value="2024.06.24" class="calTo hasDatepicker">
                        </p>
                        <p class="careerPrint">
                            <input type="button" value="경력증명서 발급" class="dwnldBtn">
                        </p>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonDatepickerModalInfo } from '@/store/modules/ModalInfo';
import { organization_modal_interface } from '@/model/organization';

@Component({
    components: {
        
    },
})
export default class OrganizationQuitterSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetCommonDatepickerModalInfo ?: (params : CommonDatepickerModalInfo) => void;
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordPrintModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordPrintModalInfo) => void; 

    quitters : any[] = [];
    
    mounted() : void {
        this.event_bus?.$on("OrganizationQuitterSettingResize", this.handleResize);
        this.getQuitters();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            // 조직도 스크롤
            const title_box_height = $('#organization_setting .title_box').outerHeight();
            const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            const sort_header_height = $('#organization_quitter_setting .sortHeader').outerHeight();

            // @ts-ignore
            $('#organization_quitter_setting_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (title_box2_height ? title_box2_height : 0)
                                            - (sort_header_height ? sort_header_height : 0)
            });
        });
    }

    async getQuitters() {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/quitter`, API_METHOD.GET);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.quitters ) {
                throw new Error("퇴사자 조회 중 오류 발생");
            }

            this.quitters.splice(0, this.quitters.length);
            this.quitters = this.quitters.concat(response.data.data.quitters);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "퇴사자 조회 중 오류 발생", ['확인']);
        }
    }

    changeEndDate(quitter) {

        this.doSetCommonDatepickerModalInfo?.({
            show_modal : true,
            default_date : this.yyyymmddToDate(quitter.quitter_data.end_date),
            callback : async(selected_date : Date) => {
                try {

                    quitter.quitter_data.end_date = `${selected_date.getFullYear()}${`0${selected_date.getMonth() + 1}`.slice(-2)}${`0${selected_date.getDate()}`.slice(-2)}`;

                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/quitter/${quitter.user_id}`, API_METHOD.PUT, quitter.quitter_data);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("퇴사자 정보 수정 중 오류 발생");
                    }

                    this.getQuitters();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "퇴사자 정보 수정 중 오류 발생", ['확인']);
                }
            }
        })

    }

    print(quitter) {
        this.doSetOrganizationEmpPersonalRecordPrintModalInfo?.({
            show_modal : true,
            group_id : quitter.group_id,
            user_id : quitter.user_id,
            is_quitter : true,
        });
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_quitter_setting_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    #organization_quitter_setting .viewGroup .title { width: 30%; padding-left: 30px; box-sizing: border-box; }
    #organization_quitter_setting .viewGroup .group { width: 20%; }
    #organization_quitter_setting .viewGroup .quitDate { width: 30%; }
    #organization_quitter_setting .viewGroup .careerPrint { width: 20%; padding-left: 0; }
    #organization_quitter_setting .viewGroup .groupNoticeHeader li > span { margin-left: 15px; }

    .quitDate input { position: static; cursor: pointer; box-sizing: border-box; font-size: 14px; font-weight: bold; width: 110px; height: 100%; line-height: 70px; margin-top: -4px; text-align: left; color: #4e4e4e; padding-right: 20px; background: url('../../assets/images/side_area/bt_open.png') no-repeat right center; }
    .quitDate input:hover { color: #477fff; }
    .quitDate input:focus { color: #477fff; background-image: url('../../assets/images/side_area/bt_close.png'); }

    .careerPrint input { background: none; color: #232848; padding: 10px; box-sizing: border-box; transition: .2s; }
    .careerPrint input:hover { color: #fff; border: 1px solid #477fff; border-radius: 5px; background: #477fff; }
</style>