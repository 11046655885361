<template>
    <div id="example_uuid">
        <p><span>UUID : </span>{{ uuid }}</p>
        <input type="button" class="input_btn" value="생성" @click="createNewUUID"/>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

const uuidV4 = require("uuid/v4");

@Component({
    components : {

    },
})
export default class ExampleUUID extends Mixins(VueHoduCommon) {

    uuid : string = "";

    /**
     * 컴포넌트를 사용하는 부분에서 받아오는 데이터 Prop
     */
    @Prop() prefix : string = "";

    mounted() : void {
        this.createNewUUID();
    }

    createNewUUID() : void {
        this.uuid = uuidV4();

        if( this.prefix && this.prefix.length > 0 ) {
            this.uuid = this.prefix + this.uuid.substring(this.prefix.length);
        }

        /**
         * 컴포넌트를 사용하는 부분에서 log v-on:log 에 매칭시킨 함수를 실행시킨다
         */
        this.$emit('log', this.uuid);
    }

}
</script>

<style scoped>
    #example_uuid { display: inline-block; border : 1px solid black; border-radius: 3px; padding : 10px; width: 350px; }
    #example_uuid input { margin-top : 10px; }
</style>