<template>
    <div id="group_board" class="section_ce_fix">
        
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">게시판</h3>
            <a href="#" class="schBtn" @click.prevent="searchOnOff(true)">검색하기</a>
        </div>

        <SearchBar 
            :is_search_open="is_search_open" 
            :placeholder="'공지사항을 검색하세요'"
            :search_query="search_query" 
            @searchOff="searchOnOff"
            @query="(value) => search_query_temp = value"
            @search="search"
            @searchKeyDown="searchKeyDown" 
            />
        
        <div id="bt_scheduleBoxNoti" class="bt_scheduleBox">
            <input type="button" id="btnAddNotification" class="bt_schedule" value="작성/수정" @click.prevent="moveCreate">
        </div>
        
        <div id="noticeScroll" class="section_scroll">
            <div class="viewGroup">
                <div class="fixedDiv">
                    <ul class="sortHeader groupNoticeHeader">
                        <li class="num">No</li>
                        <li class="group">
                            <span>그룹 &amp; 팀</span>
                        </li>
                        <li class="title">
                            <span>제목</span>
                        </li>
                        <li class="writtenBy">
                            <span>작성자</span>
                        </li>
                        <li class="time">
                            <span>작성일</span>
                        </li>
                        <li class="status">
                            <span>읽은사람</span>
                        </li>
                    </ul>
                </div>
                
                <!-- content 스크롤 -->
                <div class="content" id="group_board_scroll">
                    <ul class="notiListUl">
                        <li :key="post.board_id" :class="{ new : post.read_flag == false }" v-for="(post, index) in posts">
                            <p class="num"><span>{{ index + 1 }}</span></p>
                            <p class="group"><span>{{ post.group_team_name }}</span></p>
                            <p class="title">
                                <span>{{ post.board_info.title }}</span>
                                <i class="noticeFile_icon" v-if="post.board_info.files != null && post.board_info.files.length > 0">파일첨부여부 icon</i>
                            </p>
                            <p class="writtenBy"><span>{{ post.user_name }}</span></p>
                            <p class="time"><span>{{ hodu_date_to_format_string(post.audit_created, 'YYYY.MM.DD') }}</span></p>
                            <p class="status"><a @click.prevent="showGroupBoardReadModal(post)"><span>{{ post.read_count }}</span> 명</a></p> 
                            <a class="seeNotice" title="상세보기" @click.prevent="moveDetail(post.board_id)">상세보기</a>
                        </li>
                        <!-- <li>              
                            <p class="num"><span>1</span></p>     
                            <p class="group"><span>호두웨어</span></p>
                            <p class="title"><span>파일이 첨부된 게시글</span><i class="noticeFile_icon">파일첨부여부 icon</i></p>
                            <p class="writtenBy"><span>호호두</span></p>
                            <p class="time"><span>2023.04.11</span></p>
                            <p class="status"><a><span>0</span> 명</a></p> 
                            <a class="seeNotice" title="상세보기" @click.prevent="moveDetail">상세보기</a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <!-- <input type="button" class="input_btn" value="상세" @click.prevent="moveDetail"> -->
        
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import SearchBar from '@/components/ui/SearchBar.vue';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

import { GroupBoardReadInfo } from '@/store/modules/ModalInfo';
import { GroupNoticeSearchInfo } from '@/store/modules/GroupInfo';

import { ResizeObserver } from 'vue-resize';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        SearchBar, ResizeObserver
    },
})
export default class GroupBoard extends Mixins(VueHoduCommon) {
    
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_board_read_info !: GroupBoardReadInfo

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupBoardReadInfo ?: (params : GroupBoardReadInfo) => void;
    
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_notice_search_info !: GroupNoticeSearchInfo;

    /**
     * GroupInfo.Action
     */
    @GroupInfo.Action doSetGroupNoticeSearchInfo ?: (params : GroupNoticeSearchInfo) => void;
    
    posts : any[] = [];
    
    is_page_end = false;

    is_search_open : boolean = false;
    search_query : string = "";
    search_query_temp : string = "";
    
    async beforeMount() : Promise<void> {
        this.search_query = this.group_notice_search_info.search_query ? this.group_notice_search_info.search_query : "";
        if ( this.search_query.length > 0 ) {
            this.is_search_open = true;
            this.search_query_temp = this.search_query;
        }
    }

    mounted() : void {
        this.setScroll();
        this.getGroupBoards();
    }

    setScroll() {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        const scrollHeight      : number | undefined = window.innerHeight - ( titleHeight ? titleHeight : 0 ) - ( settingBgHeight ? settingBgHeight : 0 ) - ( sortHeaderHeight ? sortHeaderHeight : 0 );

        // @ts-ignore
        $('#group_board_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : scrollHeight,
            scrollbarPosition : 'outside',
            callbacks:{
                whileScrolling: this.whileScrolling
            }
        });
    }

    /**
     * 알림 스크롤 리스너 - 페이징 처리
     */
     @Throttle(500)
    async whileScrolling() : Promise<void> {
        
        // 페이징 끝났다면 빠져나감
        if( this.is_page_end == true ) {
            return;
        }

        try {
            const noti_ul_scroll_height : number | undefined = $('#group_board_scroll').height();
            const container_height      : number | undefined = $('#group_board_scroll .mCSB_container').height();
            let container_top : number = 0;
            let container_top_string : string = "";

            try {
                container_top_string = $('#group_board_scroll .mCSB_container').css('top')

                if(container_top_string) {
                    container_top_string = container_top_string.replace('px', '');
                    container_top = Math.abs(Number(container_top_string));
                } else {
                    container_top = 0;
                }
            } catch(e) {
                container_top = 0;
            }
        
            if( (container_height ? container_height : 0) - (noti_ul_scroll_height ? noti_ul_scroll_height : 0) - container_top <= 250 ) {
                this.getGroupBoards(this.posts[this.posts.length - 1].board_id);
            }

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }

    }
    
    async getGroupBoards(last_board_id ?: string | null | undefined) : Promise<void> {
        
        try {

            let query = "block_count=20";

            if( last_board_id != null && last_board_id.length > 0 ) {
                query += `&board_id=${last_board_id}`
            }

            if( this.scope_team_id > 0 ) {
                query += `&team_id=${this.scope_team_id}`;
            }

            if( this.search_query != null && this.search_query.length > 0 ) {
                query += `&search_query=${this.search_query}`;
            }
            
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/board?${query}`, API_METHOD.GET);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || ! response.data.data || !response.data.data.posts ) {
                throw new Error("그룹 게시판 조회 중 오류 발생");
            }
            
            if( last_board_id == null ) {
                this.posts.splice(0, this.posts.length);
            }
            this.posts = this.posts.concat(response.data.data.posts);

            this.is_page_end = response.data.data.is_page_end;
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "그룹 게시판 조회 중 오류 발생", ['확인']);
        }
        
    }

    /**
     * 그룹 게시글 읽음 현황 모달 보이기
     */
    showGroupBoardReadModal(board) : void {
        this.doSetGroupBoardReadInfo?.({
            show_modal : true,
            group_id : board.group_id,
            board_id : board.board_id
        });
    }

    /**
     * 검색 ON / OFF
     */
    searchOnOff(flag : boolean) : void {

        this.is_search_open = flag;

        if( flag == false ) {
            this.search_query = "";
            this.search_query_temp = "";

            const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
            search_info.search_query = "";
            this.doSetGroupNoticeSearchInfo?.(search_info);

            this.getGroupBoards();
        }
    }

    /**
     * 검색 keydown
     */
    searchKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }
        
        this.search();
    }

    /**
     * 검색
     */
    search() : void {
        this.search_query = this.search_query_temp.trim();

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.search_query = this.search_query;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        this.getGroupBoards();
    }

    moveCreate() : void {
        this.hodu_router_push(`/group/${new Date().getTime()}/board/create`);
    }
    
    moveDetail(board_id : string) : void {
        this.hodu_router_push(`/group/${new Date().getTime()}/board/${board_id}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#group_board_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }
    
}
</script>

<style scoped>
.viewGroup { overflow: hidden; }
.section_scroll .sortHeader { position: relative; box-sizing: border-box; }
.section_scroll .num { width: 10%; text-align: center; font-weight: bold; }
.section_scroll .group { width: 16%; }
.section_scroll .title { width: 31%; }
.section_scroll .writtenBy { width: 17%; }
.section_scroll .time { width: 14%; }
.section_scroll .status { width: 12%; }

.content .notiListUl { margin-bottom: 150px; }
.content .notiListUl > li { box-sizing: border-box; position: relative; z-index: 0; width: 100%; border-bottom: 1px solid #f1f3f5; height: 55px; line-height: 55px; display: flex; }
.content .notiListUl > li p { font-weight: bold; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.content .notiListUl > li p .noticeFile_icon { font-size: 0; background: url('../assets/images/contents/ic_file_bk.png') no-repeat center; width: 18px; height: 18px; background-size: 100% auto; display: inline-block; vertical-align: middle; margin-left: 10px; }
.content .notiListUl > li p.status { position: relative; height: 55px; }
.content .notiListUl > li p.status a span { color: #477fff; }
a.seeNotice { position: absolute; line-height: 55px; height: 55px; top: 0; right: 0; width: 90%; display: block; border: none; z-index: 1000; font-size: 0; background: transparent; }
.content .notiListUl > li:hover .status > a { border: 2px solid #477fff; padding: 0 10px; line-height: 29px; height: 30px; display: inline-block; color: #477fff; border-radius: 5px; top: 10px; }
.content .notiListUl > li p.status a { position: absolute; left: 0; opacity: 1; top: 0; z-index: 10000000; display: block; }
.content .notiListUl > li:hover p.status a:hover { color: #fff; background: #477fff; }
.content .notiListUl > li:hover p.status a:hover span { color: #fff; }

.content .notiListUl > li.new:after {
    content: 'NEW';
    display: block;
    position: absolute;
    top: 17px;
    left: 26%;
    font-size: 10px;
    font-weight: bold;
    background: #ff6060;
    height: 20px;
    line-height: 20px;
    border-radius: 5px;
    padding: 0 5px;
    color: #fff;
}

.content .notiListUl > li.new .title {
    padding-left: 45px;
}
</style>