<template>
    <div id="group_board_create" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box" style="">
            <!--<div class="title_inbox"></div>-->
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">게시판 작성</h3>
            <p class="btn_group">
                <input type="button" value="저장" class="input_btn" @click.prevent="actionSave()">
            </p>
        </div>

        <div class="content bulletinBoard">
            <div class="grp">
                <div class="grp_select_box" @click.prevent="groupTeamSelectOnOff">
                    <input type="button" :value="computedSelectedGroupTeam.team_id > 0 ? computedSelectedGroupTeam.team_name : computedSelectedGroupTeam.group_name" class="sort1">
                    <ul class="dropdown" :class="{ on : group_team_select == true }">
                        <li :key="index" v-for="(group_team, index) in computedGroupTeams">
                            <a href="#" :class="{ on : group_id == group_team.group_id && team_id == group_team.team_id }" @click.prevent="groupTeamSelect(group_team)">
                                {{ group_team.team_id > 0 ? group_team.team_name : group_team.group_name }}
                            </a>
                        </li>
                        <!-- <li>
                            <a href="#" class="on">호두웨어</a>
                        </li>
                        <li>
                            <a href="#">모바일팀</a>
                        </li>
                        <li>
                            <a href="#">디자인</a>
                        </li>
                        <li>
                            <a href="#">경영지원</a>
                        </li>
                        <li>
                            <a href="#">마케팅</a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="editArea">
                <input type="text" id="noti_title" class="input_txt " placeholder="제목을 입력하세요." :value="title" @input="title = $event.target.value">
                <div class="edtContentDiv">
                    <textarea id="modal_noti_contents" name="" cols="" rows="5" class="input_textarea" placeholder="내용을 작성하세요"></textarea>
                </div>
            </div>

            <!-- 첨부파일 -->
            <div class="attachedFile" @dragover.prevent="fileDragOver($event)" @dragleave.prevent="fileDragLeave" @drop.prevent="fileDrop($event)">
                <ul class="attachedTitle sortHeader">
                    <li>
                        <p class="attFile_text">파일첨부</p>
                        <p class="attFilebtn_box">
                            
                            <input type="file" id="cdFile" class="cdInput" @change="addFileNormal($event)" multiple>

                            <a href="#" class="attFileUp" @click.prevent="addFileButton">파일추가</a>
                            
                            <!-- 파일 하나라도 추가시 전체삭제 표시 -->
                            <a href="#" class="attFileDel" @click.prevent="allFileDelete" v-if="board_files.length > 0">전체삭제</a>
                            
                            <span>최대 5개 파일을 첨부할 수 있습니다. (한 파일당 최대 크기)</span>
                        </p>
                    </li>
                </ul>
                <div id="file_scroll" class="attFile_list">
                    <ul class="fileList_box">
                        <li :key="index" v-for="(file, index) in board_files">
                            <p class="file_num">파일 {{ index + 1 }}</p>
                            <p class="file_title"><span>{{ file.name }}</span></p>
                            <a href="#" class="file_delete" @click.prevent="deleteFile(file)">삭제버튼</a>
                        </li>
                        <!-- <li>
                            <p class="file_num">파일 1</p>
                            <p class="file_title"><span>hoduware_introduction.pdf</span></p>
                            <a href="#" class="file_delete">삭제버튼</a>
                        </li>
                        <li>
                            <p class="file_num">파일 2</p>
                            <p class="file_title"><span>hoduware_introduction.pdf</span></p>
                            <a href="#" class="file_delete">삭제버튼</a>
                        </li>
                        <li>
                            <p class="file_num">파일 3</p>
                            <p class="file_title"><span>hoduware_introduction.pdf</span></p>
                            <a href="#" class="file_delete">삭제버튼</a>
                        </li>
                        <li>
                            <p class="file_num">파일 4</p>
                            <p class="file_title"><span>hoduware_introduction.pdf</span></p>
                            <a href="#" class="file_delete">삭제버튼</a>
                        </li>
                        <li>
                            <p class="file_num">파일 5</p>
                            <p class="file_title"><span>hoduware_introduction.pdf</span></p>
                            <a href="#" class="file_delete">삭제버튼</a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

declare var CKEDITOR: any;

import { t_event_file } from '@/model/event';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupBoardCreate extends Mixins(VueHoduCommon) {

    get computedGroupTeams() : any[] {
        let group_teams : any[] = [];

        for( let group_team of this.all_group_data_temp ) {
            if( group_team.group_id == this.scope_group_id ) {
                group_teams.push(group_team);
            }
        }

        group_teams.sort((o1, o2) : number => {
            
            if( o1.team_id < o2.team_id ) return -1;
            else if( o1.team_id > o2.team_id ) return 1;

            return 0;
        })

        return group_teams;
    }

    get computedSelectedGroupTeam() : any {

        let selected_group_team : any = null;

        for( let group_team of this.computedGroupTeams ) {
            if( group_team.group_id == this.group_id && group_team.team_id == this.team_id ) {
                selected_group_team = group_team;
                break;
            }
        }

        return selected_group_team;
    }

    init_cke : boolean = false;
    file_drag : boolean = false;
    group_team_select : boolean = false;

    board_id : string = "";
    board : any = null;

    title : string = "";

    board_files : any[] = [];
    all_board_files : any[] = [];

    group_id : number = 0;
    team_id : number = 0;

    beforeMount(): void {
        this.board_id = this.$route.params.board_id;
        this.group_id = this.scope_group_id;
        this.team_id = this.scope_team_id;
    }

    async mounted() {
        // $(".grp_select_box").click(function(){
        //     $(".dropdown").toggleClass("on");
        // });

        // CKEDITOR
        const vue = this;
        await CKEDITOR.on('instanceReady', function( e ) {
            let editor = e.editor,body = CKEDITOR.document.getBody();
            
            editor.on( 'focus', function() {
                // texArea에 포커스가 있을때 datePicker none 처리
                $('#ui-datepicker-div').css("display", "none");
            });

            vue.init_cke = true

            // content 높이 설정
            vue.$nextTick(async() => {
                const title_height = $('.title_box').outerHeight();
                const grp_height = $('.content .grp').outerHeight();
                const noti_title_height = $('#noti_title').outerHeight();
                const cke_top_height = $('#cke_modal_noti_contents .cke_top').outerHeight();
                const cke_bottom_height = $('#cke_modal_noti_contents .cke_bottom').outerHeight();

                let height = window.innerHeight - (title_height ? title_height : 0)
                                                - (grp_height ? grp_height : 0)
                                                - (noti_title_height ? noti_title_height : 0)
                                                - (cke_top_height ? cke_top_height : 0)
                                                - (cke_bottom_height ? cke_bottom_height : 0)
                                                - 205;

                $("#cke_modal_noti_contents .cke_contents").attr('style', 'height : ' + height + 'px !important');

                // @ts-ignore
                $('#file_scroll').mCustomScrollbar('destroy');

                // @ts-ignore
                $('#file_scroll').mCustomScrollbar({
                    axis : 'y',
                    scrollbarPosition : 'outside',
                    mouseWheelPixels : 120,
                    scrollInertia : 60,
                    autoDraggerLength : false,
                    setHeight : 145
                });
            });

            
        });

        if( this.board_id != null && this.board_id.length > 0 ) {
            await this.getGroupBoard();
        }
        else {
            await this.setCkeditor();
        }
    }

    async getGroupBoard() : Promise<void> {

        try {
            
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/board/${this.board_id}?do_read=false`, API_METHOD.GET);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || ! response.data.data || !response.data.data.post ) {
                throw new Error("그룹 게시글 조회 중 오류 발생");
            }

            this.board = JSON.parse(JSON.stringify(response.data.data.post));

            await this.setCkeditor();

            this.group_id = this.board.group_id;
            this.team_id = this.board.team_id;
            this.title = this.board.board_info.title;

            this.board_files = this.board_files.concat(this.board.board_info.files);

            CKEDITOR.instances.modal_noti_contents.setData(this.board.board_info.content);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "그룹 게시글 조회 중 오류 발생", ['확인']);
        }
        
    }

    /**
     * CKEditor 세팅
     */
     async setCkeditor() : Promise<void> {
        let ckeditorOption : any = {
            uploadUrl: "api/v1/upload/noticeUpload?type=image",  // 이게 드래그 드롭을 위한 URL
            filebrowserUploadUrl: "api/v1/upload/noticeUpload?type=file",  // 파일업로드를 위한 URL
            filebrowserImageUploadUrl : "api/v1/upload/noticeUpload?type=image",//"/hoduweb/upload/ckeditorImg.do",
            filebrowserUploadMethod : 'xhr',
            fileTools_requestHeaders: {
                'X-Requested-With': 'XMLHttpRequest',
                'device-uid': sessionStorage.getItem("device_uid"),
                'authorization': sessionStorage.getItem("session_token"),
            }
        };

        ckeditorOption.resize_maxWidth = 652;

        await CKEDITOR.replace('modal_noti_contents', ckeditorOption);
        // CKEDITOR.instances.modal_noti_contents.setData(this.o_notice.noti_data.body);
        //CKEDITOR.instances.modal_noti_contents.updateElement();
    }

    groupTeamSelectOnOff() {
        this.group_team_select = !this.group_team_select;
    }

    groupTeamSelect(group_team) {
        this.group_id = group_team.group_id;
        this.team_id = group_team.team_id;
    }

    /**
     * 파일추가 버튼
     */
     addFileButton() : void {
        $('#cdFile').trigger('click');
    }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
     fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        this.file_drag = false;
        this.addFile(event.dataTransfer.files);
    }

    /**
     * 파일 추가 - +버튼 눌러서 추가
     */
     addFileNormal(event) : void {
        const vue = this;
        const files : File[] = event.target.files;
        this.addFile(files);
    }

    /**
     * 파일 객체 추가
     */
    addFile(files : File[]) : void {
        let file_count : number = files.length;
        const event_files : t_event_file[] = [];

        if( this.board_files.length >= 5 ) {
            alert(`파일 최대 개수는 5개 입니다`);
            $('#cdFile').val("");
            return;
        }

        // 파일 여유 공간이 있지만 선택한 파일 개수 + 기존에 있던 파일 개수가 최대치를 넘은 경우
        if( this.board_files.length + file_count > 5 ) {
            if( files instanceof FileList ) {
                files = Array.prototype.slice.call(files, 0, (5 - this.board_files.length));
            }

            else {
                alert(`파일 최대 개수는 5개 입니다`);
                $('#cdFile').val("");
                return;
            }

            file_count = files.length;
        }

        let max_size : number = this.is_premium_group("GROUP", this.group_id) ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
        let max_size_text : string = this.is_premium_group("GROUP", this.group_id) ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

        // 파일 용랑 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > max_size ) {
                alert(`${max_size_text} 이하의 파일만 업로드 가능 합니다`);
                $('#cdFile').val("");
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#cdFile').val("");
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#cdFile').val("");
                return;
            }
            
            event_files.push({
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: "",                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            })

            // 업로드용 파일 객체 담기
            this.all_board_files.push({
                index : ( this.board_files.length + i ), 
                file : files[i]
            });
        }

        // 배열 합치기
        this.board_files = this.board_files.concat(event_files);

        // 파일 input 비우기
        $('#cdFile').val("");
    }

    /**
     * 파일 삭제
     */
    deleteFile(file : t_event_file) : void {

        const file_index : number = this.board_files.indexOf(file);

        if( file_index == -1 ){
            return;
        }

        this.board_files.splice(file_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const files_count : number = this.all_board_files.length;
        for( let i = (files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.all_board_files[i].index > file_index ) {
                this.all_board_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.all_board_files[i].index == file_index ) {
                this.all_board_files.splice(i, 1);
                break;
            }
        }
    }

    /**
     * 파일 전체 삭제
     */
    allFileDelete() : void {
        this.board_files.splice(0, this.board_files.length);
        this.all_board_files.splice(0, this.all_board_files.length);
    }

    /**
     * 저장
     */
     async actionSave() : Promise<void> {
        
        let content_body = CKEDITOR.instances.modal_noti_contents.getData();

        if ( this.title.trim().length < 1 ) {
            alert('제목을 입력하세요');
            return;
        }

        if ( content_body.trim().length < 1 ) {
            alert('내용을 입력하세요');
            return;
        }

        if( this.all_board_files.length > 0 ) {
            const form_data : FormData = new FormData();
        
            // FormData 내용 생성
            const image_files_count : number = this.all_board_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.all_board_files[i].file);
            }

            try {
                const response = await this.hodu_temp_upload(form_data);
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("이미지 임시 업로드 실패");
                }

                const temp_files : t_event_file[] = response.data.data.temp_files;

                // 임시파일 데이터로 객체 대체하기
                const all_files_count : number = this.all_board_files.length;
                for( let i = 0; i < all_files_count; i++ ) {
                    this.board_files.splice(this.all_board_files[i].index, 1, temp_files[i]);    
                }

            } catch(e) {
                this.hodu_error_process(e, true, false);
                return;
            }
        }

        // 등록된 이미지 찾기
        let HTML = content_body;
        //let re = /<img src="(.*?)"/gi;
        let re = /src="(.*?)"/gi;
        let result = HTML.match(re);
        let replace_images : string[] = [];

        if ( result && result.length > 0 ) {
            for ( let i = 0; i < result.length; i++ ) {
                let img = result[i].replace('src="', '').replace('"', '');

                if ( Number(img.indexOf("/app_images/temp_uploads/")) > -1 ) {
                    replace_images.push(img);
                }
            }
        }

        let req : any = {
            "title" : this.title,
            "content" : content_body,
            "files" : this.board_files,
            "html_images" : replace_images,
            "team_id" : this.team_id
        };

        // 수정
        if( this.board_id != null && this.board_id.length > 0 ) {
            try {

                const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/board/${this.board_id}`, API_METHOD.PUT, req);

                console.log(response);

                if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("게시글 수정 중 오류 발생");
                }
                
                this.movePrevPage();

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog('cancel', '게시글 수정 중 오류 발생', ['확인']);
            }
        }
        // 작성
        else {
            try {

                const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/board`, API_METHOD.POST, req);

                console.log(response);

                if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("게시글 작성 중 오류 발생");
                }
                
                this.movePrevPage();

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog('cancel', '게시글 작성 중 오류 발생', ['확인']);
            }
        }

     }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        if( !this.init_cke ) return;

        // content 높이 설정
        this.$nextTick(async() => {
            const title_height = $('.title_box').outerHeight();
            const grp_height = $('.content .grp').outerHeight();
            const noti_title_height = $('#noti_title').outerHeight();
            const cke_top_height = $('#cke_modal_noti_contents .cke_top').outerHeight();
            const cke_bottom_height = $('#cke_modal_noti_contents .cke_bottom').outerHeight();

            let height = window.innerHeight - (title_height ? title_height : 0)
                                            - (grp_height ? grp_height : 0)
                                            - (noti_title_height ? noti_title_height : 0)
                                            - (cke_top_height ? cke_top_height : 0)
                                            - (cke_bottom_height ? cke_bottom_height : 0)
                                            - 205;

            $("#cke_modal_noti_contents .cke_contents").attr('style', 'height : ' + height + 'px !important');

            // @ts-ignore
            $('#file_scroll').mCustomScrollbar('destroy');

            // @ts-ignore
            $('#file_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : 145
            });
        });
    }

}
</script>

<style scoped>
.bulletinBoard .grp { height: 60px; border-bottom: 2px solid #e7e9ea; box-sizing: border-box; width: 100%; border-right: 1px solid #e7e9ea; }
.bulletinBoard .grp .grp_select_box { padding: 0 20px; box-sizing: border-box; position: relative; width: auto; float: left; }
.bulletinBoard .grp input[type='button'] { font-size: 12px; font-weight: bold; background: url('../assets/images/side_area/bt_open.png') no-repeat right center; line-height: 60px; width: auto; float: left; text-align: left; padding-right: 28px; color: #232848; }
.bulletinBoard .grp input[type='button']:focus { background-image: url('../assets/images/side_area/bt_close.png');}
.bulletinBoard .grp .dropdown { max-height: 230px; overflow-y: auto; top: 60px; }

.content.bulletinBoard .editArea { margin-top: 0; border-right: 1px solid #e7e9ea; }
#noti_title { font-weight: bold; line-height: 70px; height: 70px; padding: 0 20px; box-sizing: border-box; width: 100%; font-size: 18px; border-bottom: 2px solid #e7e9ea; }
#noti_title:focus { border-bottom: 2px solid #477fff; background: #fff; }
#noti_title:hover {     background: #f1f3f5; }

.attachedFile .sortHeader { border-top: 1px solid #e7e9ea; position: relative; box-sizing: border-box; }
.attachedFile ul li { width: 100%; padding: 0 25px; box-sizing: border-box; height: 100%; }
.attachedFile ul li p { display: inline-block; float: left; padding: 0 10px; box-sizing: border-box; font-weight: bold; }
.attachedFile .sortHeader li p.attFilebtn_box a { width: 100px; height: 30px; display: inline-block; vertical-align: middle; text-align: center; line-height: 30px; margin-right: 15px; color: #fff; font-weight: bold; border-radius: 5px; }
.attachedFile .sortHeader li p.attFilebtn_box a.attFileUp { background: #477fff;}
.attachedFile .sortHeader li p.attFilebtn_box a.attFileUp:hover { background: #2858f3; }
.attachedFile .sortHeader li p.attFilebtn_box a.attFileUp:before { content: '+'; width: 15px; height: 15px; border-radius: 50%; display: inline-block; background: #fff; color: #477fff; vertical-align: top; margin-top: 8px; line-height: 15px; margin-right: 10px; font-size: 17px; text-align: center; }
.attachedFile .sortHeader li p.attFilebtn_box a.attFileDel { background: #ff6363; }
.attachedFile .sortHeader li p.attFilebtn_box a.attFileDel:hover { background: #f04343; }
.attachedFile .sortHeader li p.attFilebtn_box a.attFileDel:before { content: 'x'; width: 15px; height: 15px; border-radius: 50%; display: inline-block; background: #fff; color: #ff6363; vertical-align: top; margin-top: 8px; line-height: 12px; margin-right: 10px; font-size: 13px; text-align: center; }
.attachedFile .sortHeader li p.attFilebtn_box span { font-weight: normal; font-size: 11px; opacity: .7; }
.attFile_list .fileList_box { height: 50px; line-height: 50px; box-sizing: border-box; }
.attFile_list .fileList_box .file_num { width: 70px; height: 30px; line-height: 30px; vertical-align: middle; margin-top: 13px; border-radius: 5px 0 0 5px; background: #f8f9fa; text-align: center; color: #787c8f;  }
.attFile_list .fileList_box .file_title { width: 250px; height: 30px; line-height: 30px; margin-top: 13px; border: 1px solid #f8f9fa; }
.attFile_list .fileList_box .file_delete { display: inline-block; font-size: 0; width: 25px; height: 25px; background: #ff6363; background-image: url('../assets/images/contents/btn_close_fff.png'); background-repeat: no-repeat; background-position: center; background-size: 25px; margin-left: 9px; border-radius: 50%; margin-top: 15px;}
.attFile_list .fileList_box .file_delete:hover { background-color: #f04343; }

.left_area_close .content.notice { padding-left: 61px !important; } 
    
#group_board_create ::-webkit-scrollbar{width: 6px;}
#group_board_create ::-webkit-scrollbar-track{background-color: transparent;}
#group_board_create ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
</style>

<style>
    .cke_chrome { border:0 none !important; }
    .cke_top { background:#f1f3f5 !important; border-bottom:0 none !important; }
    .cke_bottom { border-top: 0 none !important;background: #f1f3f5 !important; padding:0 !important; line-height:0px !important; height:0px !important; }
    .cke_resizer { display:none !important; }

    /* 첨부파일 수정 */
    .attachedFile .sortHeader { border-top: 1px solid #e7e9ea; position: relative; box-sizing: border-box; }
    .attachedFile ul li { width: 100%; padding: 0 25px; box-sizing: border-box; height: 100%; }
    /* .attachedFile ul li:last-child { padding-bottom: 50px; } */
    .attachedFile ul li p { display: inline-block; float: left; padding: 0 10px; box-sizing: border-box; font-weight: bold; }
    .attachedFile .sortHeader li p.attFilebtn_box a { width: 100px; height: 30px; display: inline-block; vertical-align: middle; text-align: center; line-height: 30px; margin-right: 15px; color: #fff; font-weight: bold; border-radius: 5px; }
    .attachedFile .sortHeader li p.attFilebtn_box a.attFileUp { background: #477fff;}
    .attachedFile .sortHeader li p.attFilebtn_box a.attFileUp:hover { background: #2858f3; }
    .attachedFile .sortHeader li p.attFilebtn_box a.attFileUp:before { content: '+'; width: 15px; height: 15px; border-radius: 50%; display: inline-block; background: #fff; color: #477fff; vertical-align: top; margin-top: 8px; line-height: 15px; margin-right: 10px; font-size: 17px; text-align: center; }
    .attachedFile .sortHeader li p.attFilebtn_box a.attFileDel { background: #ff6363; }
    .attachedFile .sortHeader li p.attFilebtn_box a.attFileDel:hover { background: #f04343; }
    .attachedFile .sortHeader li p.attFilebtn_box a.attFileDel:before { content: 'x'; width: 15px; height: 15px; border-radius: 50%; display: inline-block; background: #fff; color: #ff6363; vertical-align: top; margin-top: 8px; line-height: 12px; margin-right: 10px; font-size: 13px; text-align: center; }
    .attachedFile .sortHeader li p.attFilebtn_box span { font-weight: normal; font-size: 11px; opacity: .7; }
    .attFile_list .fileList_box { height: 50px; line-height: 50px; box-sizing: border-box; }
    .attFile_list .fileList_box .file_num { width: 70px; height: 30px; line-height: 30px; vertical-align: middle; margin-top: 13px; border-radius: 5px 0 0 5px; background: #f8f9fa; text-align: center; color: #787c8f;  }
    .attFile_list .fileList_box .file_title { width: 250px; height: 30px; line-height: 30px; margin-top: 13px; border: 1px solid #f8f9fa; }
    .attFile_list .fileList_box .file_delete { display: inline-block; font-size: 0; width: 25px; height: 25px; background: #ff6363; background-image: url('../assets/images/contents/btn_close_fff.png'); background-repeat: no-repeat; background-position: center; background-size: 25px; margin-left: 9px; border-radius: 50%; margin-top: 15px;}
    .attFile_list .fileList_box .file_delete:hover { background-color: #f04343; }
</style>