<template>
    <div class="dis member_set_2" id="organization_department_setting">
        <div class="approval_content">
            <div class="filter_tap">
                <!-- 부서설정 탑 -->
                <!-- <div class="content_top"> -->
                    <!-- <h3 style="padding-left: 35px;">조직도 설정</h3> -->
                    <!-- <input type="button" class="refresh" title="그룹환경 불러오기" value="그룹환경 불러오기" @click.prevent="reset" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"/> -->
                    <!-- <input type="text" id="filter_search" placeholder="부서를 검색하세요" /> -->
                <!-- </div> -->
                
                <div id="organization_department_scroll">

                    <!-- 부서 리스트 -->
                    <div id="orgaDepSetDiv" class="section_scroll">

                        <!-- 회사-->
                        <div class="main">
                            <p class="firmName">회사명</p>
                            <!-- <a href="#" class="team" @click.prevent="computedOrganization == null ? () => {} : update(computedOrganization)"> -->
                            <a href="#" class="top_dept team" @click.prevent="() => {}">
                                <p class="title">
                                    {{ computedOrganization != null ? computedOrganization.dept_name : '' }}
                                    <span class="num none">({{ computedOrganization != null ? computedOrganization.departments.length : '' }})</span>
                                </p>
                            </a>
                            <a href="#" class="addBtn" @click.prevent="computedOrganization == null ? () => {} : add(computedOrganization)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">추가</a>
                        </div>

                        <OrganizationRecursion2
                            :parent_vue="this"
                            :level="2"
                            :departments="computedOrganization.departments"
                            :department_closed_map="department_closed_map"
                            :is_select="false"
                            @add="add"
                            @update="update"
                            @remove="remove"
                            v-if="computedOrganization != null" 
                            />

                        <ul class="mainUl" v-if="false">
                            <!-- 부서 리스트 -->
                            <li class="on">
                                <div class="grp">
                                    <input type="button" value="열고 닫기" />
                                    <label for="" class="leftBtn">열고 닫기</label>
                                    <a href="#" class="team"><p class="title">Pheonix Team <span class="num">(3)</span></p></a>
                                    <a href="#" class="addBtn">추가</a>
                                    <span class="line"></span>
                                </div>
                                <div class="grp">
                                    <ul>
                                        <li class="on">
                                            <div class="grp">
                                                <input type="button" value="열고 닫기" />
                                                <label for="" class="leftBtn">열고 닫기</label>
                                                <a href="#" class="team"><p class="title">개발팀 <span class="num">(2)</span></p></a>
                                                <a href="#" class="addBtn">추가</a>
                                                <span class="line"></span>
                                            </div>
                                            <div class="grp">
                                                <ul>
                                                    <li>
                                                        <div class="grp">
                                                            <div class="noBtn"></div>
                                                            <a href="#" class="team"><p class="title">Android 개발팀 <span class="num">(0)</span></p></a>
                                                            <a href="#" class="addBtn">추가</a>
                                                            <span class="line"></span>
                                                        </div>
                                                    </li>
                                                    <li class="on">
                                                        <div class="grp">
                                                            <input type="button" value="열고 닫기" />
                                                            <label for="" class="leftBtn">열고 닫기</label>
                                                            <a href="#" class="team"><p class="title">iOS 개발팀 <span class="num">(2)</span></p></a>
                                                            <a href="#" class="addBtn">추가</a>
                                                            <span class="line"></span>
                                                        </div>
                                                        <div class="grp">
                                                            <ul>
                                                                <li>
                                                                    <div class="grp">
                                                                        <div class="noBtn"></div>
                                                                        <a href="#" class="team"><p class="title">기획팀 <span class="num">(0)</span></p></a>
                                                                        <a href="#" class="addBtn">추가</a>
                                                                        <span class="line"></span>
                                                                    </div>
                                                                </li>
                                                                <li class="on">
                                                                    <div class="grp">
                                                                        <div class="noBtn"></div>
                                                                        <a href="#" class="team"><p class="title">Development Team <span class="num">(0)</span></p></a>
                                                                        <a href="#" class="addBtn">추가</a>
                                                                        <span class="line"></span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="on">
                                            <div class="grp">
                                                <input type="button" value="열고 닫기" />
                                                <label for="" class="leftBtn">열고 닫기</label>
                                                <a href="#" class="team"><p class="title">영업팀 <span class="num">(1)</span></p></a>
                                                <a href="#" class="addBtn">추가</a>
                                                <span class="line"></span>
                                            </div>
                                            <div class="grp">
                                                <ul>
                                                    <li>
                                                        <div class="grp">
                                                            <div class="noBtn"></div>
                                                            <a href="#" class="team"><p class="title">Design Team <span class="num">(0)</span></p></a>
                                                            <a href="#" class="addBtn">추가</a>
                                                            <span class="line"></span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li class="on">
                                <div class="grp">
                                    <input type="button" value="열고 닫기" />
                                    <label for="" class="leftBtn">열고 닫기</label>
                                    <a href="#" class="team"><p class="title">IRS Team <span class="num">(2)</span></p></a>
                                    <a href="#" class="addBtn">추가</a>
                                    <span class="line"></span>
                                </div>
                                <div class="grp">
                                    <ul>
                                        <li>
                                            <div class="grp">
                                                <div class="noBtn"></div>
                                                <a href="#" class="team"><p class="title">개발팀 <span class="num">(0)</span></p></a>
                                                <a href="#" class="addBtn">추가</a>
                                                <span class="line"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="grp">
                                                <div class="noBtn"></div>
                                                <a href="#" class="team"><p class="title">심사팀 <span class="num">(0)</span></p></a>
                                                <a href="#" class="addBtn">추가</a>
                                                <span class="line"></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li class="on">
                                <div class="grp">
                                    <input type="button" value="열고 닫기" />
                                    <label for="" class="leftBtn">열고 닫기</label>
                                    <a href="#" class="team"><p class="title">Mobile Team <span class="num">(2)</span></p></a>
                                    <a href="#" class="addBtn">추가</a>
                                    <span class="line"></span>
                                </div>
                                <div class="grp">
                                    <ul>
                                        <li>
                                            <div class="grp">
                                                <div class="noBtn"></div>
                                                <a href="#" class="team"><p class="title">Design Team<span class="num">(0)</span></p></a>
                                                <a href="#" class="addBtn">추가</a>
                                                <span class="line"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="grp">
                                                <div class="noBtn"></div>
                                                <a href="#" class="team"><p class="title">Development Team <span class="num">(0)</span></p></a>
                                                <a href="#" class="addBtn">추가</a>
                                                <span class="line"></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <!-- 회사 바로 밑으로 부서 추가 버튼 -->
                            <li class="addTeam">
                                <div class="">
                                    <a href="#" class="team"><p>+ 부서 추가</p></a>
                                    <span class="line"></span>
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import OrganizationRecursion2 from '@/components/organization/OrganizationRecursion2.vue';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion2
    },
})
export default class OrganizationDepartmentSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) department['is_closed'] = is_closed;
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }
    
    departments : any[] = [];
    search_query : string = "";

    department_closed_map : Map<number, boolean> = new Map();

    employees : any[] = [];

    async mounted() : Promise<void> {

        this.event_bus.$on('OrganizationDepartmentSettingResize', this.handleResize);

        $(".grp .leftBtn").click(function(){
            $(this).parent().parent("li").toggleClass("on");
        }); 

        await this.getTotalOrganizationData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_box_height = $('#organization_setting .title_box').outerHeight();
        const title_box2_height = $('#organization_setting .title_box2').outerHeight();
        const content_top_height = $('#organization_setting .approval_content .filter_tap .content_top').outerHeight();

        // @ts-ignore
        $('#organization_department_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (title_box2_height ? title_box2_height : 0)
                                           - (content_top_height ? content_top_height : 0)
        });

    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganization();
            await this.getOrganizationEmp();
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 등록
     */
    add(target_department : any) : void {
        
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "부서 추가",
            subtitle : "부서명",
            placeholder : "부서명을 입력해주세요",
            save : async(name, selected_radio, check_flag) => {
                try {

                    await this.getOrganization();

                    target_department.is_closed = false;
                    this.department_closed_map.set(target_department.dept_id, false);

                    const new_department = JSON.parse(JSON.stringify(target_department));
                    new_department.dept_name = name;

                    // 같은 레벨에 있는 부서 수를 구해서 dept_seq 세팅
                    let same_level_departments = JSON.parse(JSON.stringify(this.departments));
                    for( const id of target_department.dept_id_array ) {
                        same_level_departments = same_level_departments.filter(item => item.dept_id_array.indexOf(id) > -1);
                    }
                    same_level_departments = same_level_departments.filter(item => item.dept_id_array.length == new_department.dept_id_array.length);
                    new_department.dept_seq = same_level_departments.length;

                    // insert API
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.POST, { 
                        "dept_name"     : new_department.dept_name,
                        "dept_id_array" : new_department.dept_id_array,  
                        "dept_seq"      : new_department.dept_seq,
                        "team_sync"     : check_flag, 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                        throw new Error("조직도 부서 정보 추가 중 오류 발생");
                    }

                    this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 부서 추가 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            },
            has_check : (target_department.dept_id == 0 && this.teamSyncChecked() == false) ? true : false,
            check_text : "부서 달력 생성"
        });

    }

    /**
     * 부서 수정
     */
    update(target_department : any) : void {
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "부서 수정",
            subtitle : "부서명",
            placeholder : "부서명을 입력해주세요",
            content : target_department.dept_name,
            save : async(name, selected_radio, check_flag) => {
                try {

                    await this.getOrganization();

                    // update api
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/${target_department.dept_id}`, API_METHOD.PUT, { "dept_name" : name, "team_sync" : check_flag, }, false);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                        throw new Error("조직도 부서 정보 수정 중 오류 발생");
                    }

                    this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 부서 수정 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            },
            has_check : (target_department.team_id > 0 && this.teamSyncChecked() == false),
            check_text : "부서 달력 수정"
        });
    }

    /**
     * 부서 삭제
     */
    remove(target_department : any) : void {
        this.hodu_show_dialog('cancel', `'${target_department.dept_name}' 부서를 삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async(check_flag) => {
                try {
                    // delete api
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/${target_department.dept_id}`, API_METHOD.DELETE, { "team_sync" : check_flag });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 부서 정보 삭제 중 오류 발생");
                    }

                    await this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 부서 삭제 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ], (target_department.team_id > 0 && this.teamSyncChecked() == false), "부서 달력 삭제");
    }

    /**
     * 부서 초기화 진행 (그룹환경 불러오기)
     */
    reset() : void {
        this.hodu_show_dialog("alert", "그룹환경 불러오기를 진행하면 기존에 입력한\n부서 및 직원의 부서, 직원의 기본결재자 정보가 전부 초기화 됩니다\n정말로 그룹환경 불러오기를 진행하시겠습니까?", ['아니오', '예'], [
            () => this.getTotalOrganizationData(),
            () => {
                this.hodu_show_dialog("alert", "최종 확인입니다\n정말로 그룹환경 불러오기를 진행하시겠습니까?", ['아니오', '예'], [
                    () => this.getTotalOrganizationData(),
                    async() => {
                        try {

                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/set_team_default`, API_METHOD.POST);

                            console.log(response);
                            
                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("그룹환경 불러오기 진행 중 오류 발생");
                            }

                            await this.getTotalOrganizationData();

                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                            this.hodu_show_dialog('cancel', "그룹환경 불러오기 진행 중 오류 발생", ['확인']);
                        }
                    },
                ]);
                
            },
        ]);
    }

    /**
     * 팀&부서 동기화 체크
     */
    teamSyncChecked() : boolean {
        const feature = this.get_group_feature(this.scope_group_id);
        if( feature == null || feature.team_sync == null || feature.team_sync.enable == null ) return false;
        return feature.team_sync.enable;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#organization_department_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style>
    #organization_department_setting #orgaDepSetDiv { padding: 0 35px }

    #organization_department_setting #orgaDepSetDiv div { position: relative; overflow: hidden; width:100%; }
    #organization_department_setting .line { transition: 0.1s; position: absolute; bottom:0px; left: 0; width: 100%; height: 1px; display: block; background: #f1f3f5; }
    #organization_department_setting .grp:hover > .line { background: #e2e6ea }
    #organization_department_setting #orgaDepSetDiv .mainUl { padding-left: 0; }
    #organization_department_setting #orgaDepSetDiv ul li { overflow: hidden; line-height: 55px; width: 100%; box-sizing: border-box; padding-left: 25px; }
    
    #organization_department_setting #orgaDepSetDiv ul li a { display: inline-block; float: left; }
    #organization_department_setting #orgaDepSetDiv ul li a.team { }
    #organization_department_setting #orgaDepSetDiv ul li > div:hover > a.addBtn { opacity: 1; }
    #organization_department_setting #orgaDepSetDiv a.addBtn { position: absolute; right: 0px;top:50%; opacity: 0; margin-top: -12.5px; font-weight: bold; height: 25px; line-height: 25px; padding: 0 15px; border-radius: 15px; background: #477fff; color:#fff; }
    #organization_department_setting #orgaDepSetDiv .addBtn:hover {background:#004bff}
    #organization_department_setting #orgaDepSetDiv ul li > div:hover > a.delBtn { opacity: 1; }
    #organization_department_setting #orgaDepSetDiv a.delBtn { position: absolute; right: 60px;top:50%; opacity: 0; margin-top: -12.5px; font-weight: bold; height: 25px; line-height: 25px; padding: 0 15px; border-radius: 15px; background: #ff6363; color:#fff; }
    #organization_department_setting #orgaDepSetDiv .delBtn:hover {background:#ff3a00; }
    #organization_department_setting #orgaDepSetDiv .num { margin-left: 0; padding-left: 0; opacity: 0.5; }
    #organization_department_setting #orgaDepSetDiv .title { font-size: 14px; font-weight: bold; padding-right: 20px; }
    #organization_department_setting #orgaDepSetDiv .title:hover { color:#477fff }
    #organization_department_setting #orgaDepSetDiv .main .title { font-size: 20px; }
    #organization_department_setting #orgaDepSetDiv .main .firmName { font-size: 13px; position: absolute; top:-26px;left: 0;; font-weight: bold; opacity: 0.5; margin-right:20px }
    #organization_department_setting #orgaDepSetDiv .main { line-height: 120px; height: 90px; padding:0 0; box-sizing: border-box; border-bottom:1px solid #e7e9ea; }
    #organization_department_setting #orgaDepSetDiv ul li > div:hover > span.line { background: #232848; opacity: 0.5; }
    #organization_department_setting #orgaDepSetDiv .main * { display: inline-block; }
    #organization_department_setting #orgaDepSetDiv .main .addBtn { top:65% }
    #organization_department_setting #orgaDepSetDiv .main:hover a.addBtn { opacity: 1; }
    #organization_department_setting #orgaDepSetDiv .main .delBtn { top:65% }
    #organization_department_setting #orgaDepSetDiv .main:hover a.delBtn { opacity: 1; }

    #organization_department_setting #orgaDepSetDiv input { display: none; }
    #organization_department_setting #orgaDepSetDiv label.leftBtn { cursor:pointer; font-size: 0; background: #fff; border:1px solid #2b3b5f; width: 18px; height: 18px; padding:0; border-radius: 3px; margin-left:0; margin-top:18px; margin-right: 20px; }
    #organization_department_setting #orgaDepSetDiv .noBtn { float: left; display: inline-block; font-size: 0; background: #edeef1; border:1px solid #d5d8df; width: 18px; height: 18px; padding:0; border-radius: 3px; margin-left:0; margin-top:18px; margin-right: 20px; }
    #organization_department_setting #orgaDepSetDiv .noBtn::before { content:''; display: none; z-index: 100; left: 50%; top:50%; margin-left:-5px; margin-top: -1px; position: absolute; display: block; width: 10px; height: 2px; background: #fff; }
    #organization_department_setting #orgaDepSetDiv ul li label.leftBtn { position: relative; display: inline-block; float: left; }
    #organization_department_setting #orgaDepSetDiv ul li label.leftBtn:after {z-index: 100; left: 50%; top:50%; margin-left:-5px; margin-top: -1px; position: absolute; content: ''; display: block; width: 10px; height: 2px; background: #2b3b5f; }
    #organization_department_setting #orgaDepSetDiv ul li label.leftBtn:before { display: none; z-index: 100; left: 50%; top:50%; margin-left:-1px; margin-top: -5px; position: absolute; content: ''; display: block; width: 2px; height: 10px; background: #fff; }
    #organization_department_setting #orgaDepSetDiv li.on > div.grp div.grp  { display: none; }
    #organization_department_setting #orgaDepSetDiv li.on > div.grp > label.leftBtn { background:#2b3b5f }
    #organization_department_setting #orgaDepSetDiv li.on > div.grp > label.leftBtn:after { background:#fff; } 
    #organization_department_setting #orgaDepSetDiv li.on > div.grp > label.leftBtn:before { background:#fff; } 

    #organization_department_setting #orgaDepSetDiv .addTeam .team { transition: 0.2s; opacity: 0.7; width:100%; font-size: 14px; font-weight: bold; color:#477fff;  }
    #organization_department_setting #orgaDepSetDiv .addTeam .team:hover  { opacity: 1; }

    #organization_department_setting .refresh { border-radius: 50%; position : absolute; right : 18px; top : 12.5px; font-size:0; width : 40px; height : 40px; background : url('../../assets/images/contents/ic_repeat_bk.png') no-repeat center center; }
    .left_area_close #organization_department_setting .refresh { right: 80px; }
    #organization_department_setting .refresh:hover  { background-color:#f1f3f5; }

    .main a.top_dept { cursor:default; } 

    #organization_department_setting #orgaDepSetDiv div.chooseBtnDiv { display : none !important; }

</style>