<template>
    <!-- 리스트에 한사람 연락처 클릭 시 class추가 one -->
    <div id="patient_send_message_modal" class="modal" :class="{ one : patient_send_message_modal_info.selected_patients.length == 1 }">
        <div class="titleBox">
            <h1>메세지 발송</h1>
            <!-- <h1>DM 발송</h1> -->
            <!-- 연락처 선택된 사람 이름, .modal.one 일때만 보여짐 -->
            <p class="toWhom" v-if="patient_send_message_modal_info.selected_patients.length == 1">
                {{ patient_send_message_modal_info.selected_patients[0] != null ? patient_send_message_modal_info.selected_patients[0].user_name : '' }}
            </p>
            <!-- <p class="toWhom">김이정</p> -->
            <input type="button" class="input_btn" value="전체선택" />
        </div>
        <div class="content">
            <div class="detail">
                <div class="context">
                    <div class="msgTempDiv">
                        <select @change="selectMessageTemplate($event.target.value)">
                            <option value="">직접입력</option>
                            <option :value="template.message_info.body" :key="template.uid" v-for="template of template_list">
                                {{ template.title }}
                            </option>
                            <!-- <option value="" selected>가입권유</option>
                            <option value="">문구 2</option>
                            <option value="">문구 3</option>
                            <option value="">문구 4</option> -->
                        </select>
                    </div>
                    <textarea :value="send_text" @input="send_text = $event.target.value"/>
                    <!--  <textarea>
                        [호두병원]에 가입을 요청합니다.
                        아래의 링크를 클릭하여 호두앱설치 및 해당 병원 서비스를 이용하실 수 있습니다.
                        https://wwww.hoduware.com/signup
                    </textarea> -->
                </div>
                <div class="info">
                    <div id="send_target_patient_scroll" class="scroll">
                        <ul>
                            
                            <li :key="patient.user_id" v-for="(patient, index) in patient_send_message_modal_info.selected_patients">
                                <p class="checkp">
                                    <input type="checkbox" :id="`p_${patient.user_id}`" v-model="patient.checked"/>
                                    <label :for="`p_${patient.user_id}`"></label>
                                </p>
                                <p class="num">{{ index + 1 }}</p>
                                <p class="name">{{ patient.user_name }}</p>
                                <p class="tel">
                                    {{ patient.user_phone_number == null || patient.user_phone_number.length == 0 ? '-' : patient.user_phone_number }}
                                </p>
                            </li>

                            <!-- <li>
                                <p class="checkp">
                                    <input type="checkbox" id="p1" />
                                    <label for="p1"></label>
                                </p>
                                <p class="num">1</p>
                                <p class="name">김이정</p>
                                <p class="tel">010-2020-2030</p>
                            </li>
                            <li>
                                <p class="checkp">
                                    <input type="checkbox" id="p2" />
                                    <label for="p2"></label>
                                </p>
                                <p class="num">2</p>
                                <p class="name">김이연</p>
                                <p class="tel">010-2020-2030</p>
                            </li>
                            <li>
                                <p class="checkp">
                                    <input type="checkbox" id="p3" />
                                    <label for="p3"></label>
                                </p>
                                <p class="num">3</p>
                                <p class="name">김이석</p>
                                <p class="tel">010-2020-2030</p>
                            </li>
                            <li>
                                <p class="checkp">
                                    <input type="checkbox" id="p4" />
                                    <label for="p4"></label>
                                </p>
                                <p class="num">4</p>
                                <p class="name">김이순</p>
                                <p class="tel">010-2020-2030</p>
                            </li> -->

                        </ul>
                    </div>
                </div>
            </div>
            <div class="btns">
                <input type="button" value="취소" @click="closeModal"/>
                <input type="button" class="save" value="보내기" @click="sendMessage"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object } from '@/model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class PatientSendMessageModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State patient_send_message_modal_info !: hodu_doc_modal_info.PatientSendMessageModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetPatientSendMessageModalInfo ?: (params : hodu_doc_modal_info.PatientSendMessageModalInfo) => void;

    template_list : any[] = [];

    send_text : string = "";

    mounted() : void {
        this.getMessageTemplate();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const modal_height = $('#patient_send_message_modal').outerHeight();
        const modal_title_height = $('#patient_send_message_modal .titleBox').outerHeight();
        const modal_button_height = $('#patient_send_message_modal .btns').outerHeight();

        // @ts-ignore
        $('#send_target_patient_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight :  (modal_height ? modal_height : 0) - (modal_title_height ? modal_title_height : 0) - (modal_button_height ? modal_button_height : 0),
        });

    }

    /**
     * 메시지 템플릿 조회
     */
    getMessageTemplate() : void {
        this.hodu_api_call(`api/v1/message_template/GROUP/${this.scope_group_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.template_list = this.template_list.concat(response.data.data.list);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 메시지 보내기
     */
    async sendMessage() : Promise<void> {
        
        const checked_patient : hodu_doc_object.patient_info[] = this.patient_send_message_modal_info.selected_patients.filter(item => item.checked == true);
        const users : number[] = [];
        for( const patient of checked_patient ) { users.push(patient.user_id); }

        let req_data = {
            users: users,
            group_id: this.scope_group_id,
            team_id: 0,
            group_team_name: this.scope_group_team_option.group_team_name,
            message_content: this.send_text
        }
        
        const scope = OWNER_TYPE.GROUP;
        const scope_id = this.scope_group_id;

        await this.hodu_api_call(`api/v1/message_template/${scope}/${scope_id}/send`, API_METHOD.POST, req_data)
            .then(async(response) => {
                this.hodu_show_dialog("success", "성공적으로 메세지 발송하였습니다", ['확인']);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            }).finally(() => {
                this.closeModal();
            });

    }

    /**
     * 메세지 템플릿 선택
     */
    selectMessageTemplate(value : string) : void {
        this.send_text = value;
    } 

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetPatientSendMessageModalInfo == null ) { return; }

        this.doSetPatientSendMessageModalInfo({ show_modal : false, selected_patients : [] });
    }

}
</script>

<style scoped>
    .modal.one  { width: 500px; max-width:500px; margin-left:-250px;  }
    .modal.one .toWhom { display:block; }
    .modal.one .info, .modal.one .titleBox input { display:none;  }
    .modal.one .context { width:100%; }


    .toWhom { display:none; font-weight: bold; position:absolute; right:30px; top:30px; line-height: 35px; height:35px; padding:0 10px;border-radius: 5px; border:1px solid #e7e9ea;font-size: 14px; }
    .modal { position:absolute; left:50%; top:50%; margin-top:-245px; margin-left:-400px; width:100%; border-radius:5px; max-width: 800px;  height:490px; background:#fff; box-shadow:0 10px 20px rgba(0,0,0,0.1); }
    .titleBox { position: relative; }
    .titleBox input { position: absolute; right:30px;top: 27px; }
    h1 { font-size: 20px;height: 85px; line-height: 95px;padding: 0 30px; border-bottom:1px solid #e7e9ea }
    h1 span { font-size: 14px; padding-left: 10px; opacity: 0.7; }
    .btns { overflow: hidden; border-top:1px solid #e7e9ea;border-radius: 0 0 5px 5px }
    .btns input { transition:0.2s; line-height:75px; height: 75px; background:#fff; width:50%; float: left; font-size:14px; font-weight: bold; }
    .btns input.save:hover { color:#477fff; }
    .btns input:hover { background:#f1f3f5; }
    .detail { overflow:hidden; height: 328px !important; }
    .context { float:left; display:inline-block; width:40%;height:100%; }
    .msgTempDiv select { transition:0.2s; cursor:pointer; height: 55px; line-height: 55px; font-size: 14px; padding: 0 20px; width:100%;background-image: url('../../../assets/images/side_area/bt_open.png'); background-repeat: no-repeat; background-position: 95% center; box-sizing: border-box;  font-weight:bold; appearance: none; box-sizing: border-box;border-bottom: 1px solid #e7e9ea; }
    .msgTempDiv select:hover{ background-color: #f7f9fb; }
    .msgTempDiv select:focus { border-color:#477fff; background-color:#fff;background-image: url(../../../assets/images/side_area/bt_close.png)}
    .info { float: left; width: 60%;height:100%; border-left: 1px solid #e7e9ea; box-sizing: border-box; }
    ul li { position:relative; transition:0.2s; overflow: hidden; height: 55px; line-height: 55px; font-weight: bold; border-bottom:1px solid #f1f3f5; }
    ul li:hover { background:#f7f9fb; }
    ul li label:after { content: ''; display:block; position:absolute; left:0; top:0;width:100%; height:100%; }
    ul li input[type='checkbox']:not(old) + label {margin-top:15px;display: inline-block;padding: 0;width: 17px;height: 17px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 19px;font-size: 0;margin-right: 8px;}
    ul li input[type='checkbox']:not(old):checked + label {background: #477fff url('../../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 19px;}

    ul li p { float:left; display:inline-block; box-sizing: border-box; }
    .num { width: 12%; text-align: left;}
    .checkp { width:18%; text-align: center; }
    .name {width: 35%; padding-right:20px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .tel { width: 35%; padding-right:20px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }


    textarea { transition:0.2s; border-color:#f1f3f5; resize: none; background:#f1f3f5; width: 100%; padding: 15px; min-height: 273px; font-size: 14px;line-height: 26px; padding: 25px; height:auto; box-sizing: border-box; font-weight:bold; }
    textarea:hover { border-color:#477fff }
    textarea:focus { background:#fff;  }
</style>