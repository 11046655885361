<template>
    <div id="right_area_controller" :class="{ on : right_control_box_flag == true }">
        <ul>
            <li v-if="!isHoduD(scope_group_team_option ? scope_group_team_option.biz_type : '')" :class="{ on : user_profile_open == true}" class="userLi"><a href="#" @click.prevent="userProfileClick" :style="{ backgroundImage : `url(${ profile_image_error == true ? userDefaultImageUrl : `${userImageUrl}?t=${image_time}`})` }" ><span>개인정보</span></a></li>
            
            <li v-if="!isHoduD(scope_group_team_option ? scope_group_team_option.biz_type : '') && !isHoduDomainService()" :class="{ on : notification_list_menu_open == true, 
                          newOn : notificatin_count > 0 && notification_list_menu_open == false }" 
                class="noticeLi"><a href="#" @click.prevent="notificationListMenuClick" ><span>알림</span><span class="new"></span></a></li>
            
            <li v-if="!isHoduD(scope_group_team_option ? scope_group_team_option.biz_type : '')" :class="{ on : todo_menu_open == true || todo_create_or_update == true,
                          newOn : todo_count > 0 && todo_menu_open == false && todo_create_or_update == false }" 
                class="toDoLi"><a href="#" @click.prevent="todoMenuOpenClick"><span>할 일</span><span class="new"></span></a></li>

            <li class="printLi" v-if="$route.path == '/' && calendar_view_type != 'timeGridDay'">
                <a href="#" @click.prevent="printCalendar"><span>인쇄</span></a>
            </li>

            <li class="filterLi filter_icon" :class="{ on : filter_menu_open == true }">
                <a href="#" @click.prevent="filterMenuOnOff"><span>필터</span></a>

                
                <!-- <div class="filter_content" :class="{ on : filter_menu_open == true }">
                    <ul>
                        <li> 
                            <p> 
                                <input type="checkbox" id="filter_1" @change="filterChange($event.target, 'basic')" :checked="isFilterChecked('basic')">
                                <label for="filter_1"></label>
                                <span @click="filterCheck($event, 'basic')">일정</span> 
                            </p> 
                        </li>
                        <li> 
                            <p> 
                                <input type="checkbox" id="filter_2" @change="filterChange($event.target, 'report')" :checked="isFilterChecked('report')">
                                <label for="filter_2"></label>
                                <span @click="filterCheck($event, 'report')">업무일지</span> 
                            </p> 
                        </li>
                        <li> 
                            <p> 
                                <input type="checkbox" id="filter_3" @change="filterChange($event.target, 'meetinglog')" :checked="isFilterChecked('meetinglog')">
                                <label for="filter_3"></label>
                                <span @click="filterCheck($event, 'meetinglog')">회의록</span> 
                            </p> 
                        </li>
                        <li> 
                            <p> 
                                <input type="checkbox" id="filter_4" @change="filterChange($event.target, 'work')" :checked="isFilterChecked('work')">
                                <label for="filter_4"></label>
                                <span @click="filterCheck($event, 'work')">프로젝트</span> 
                            </p> 
                        </li>
                        <li> 
                            <p> 
                                <input type="checkbox" id="filter_5" @change="filterChange($event.target, 'vacation')" :checked="isFilterChecked('vacation')">
                                <label for="filter_5"></label>
                                <span @click="filterCheck($event, 'vacation')">휴가원</span> 
                            </p> 
                        </li>
                        <li> 
                            <p> 
                                <input type="checkbox" id="filter_6" @change="filterChange($event.target, 'businesstrip')" :checked="isFilterChecked('businesstrip')">
                                <label for="filter_6"></label>
                                <span @click="filterCheck($event, 'businesstrip')">출장신청서</span> 
                            </p> 
                        </li>
                    </ul>
                </div> -->

            </li>

            <!-- <li class="fileLi settingLi"><a href="#" @click.prevent="preferencesMenuClick"><span>환경설정</span></a></li> -->

            <!-- 21.04.19 설정부분 기능설정 페이지에 넣어서 우측에서는 삭제함 -->
            <li :class="{ on : preferences_menu_open == true }" class="fileLi settingLi" v-if="getPreferencesVisibility()"><a href="#" @click.prevent="preferencesMenuClick"><span>환경설정</span></a></li>

            <li v-if="!isHoduD(scope_group_team_option ? scope_group_team_option.biz_type : '')" :class="{ on : etc_menu_open == true }" class="etcLi"><a href="#" @click.prevent="etcMenuClick"><span>기타</span></a></li>
        
        </ul>
        <p class="ft_copyright">created by hoduware</p>
        <img :src="`${ profile_image_error == true ? userImageUrl : `${userImageUrl}?t=${image_time}`}`" alt="사용자이미지 onError 감지용" @error="profileImageError" style="display : none;">
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD, GROUP_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const MenuInfo = namespace('MenuInfo');

import moment from 'moment';

import { directive as onClickaway } from 'vue-clickaway';
import { hodu_local_storage, local_storage_info } from '@/lib/HoduLocalStorage';

@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        
    },
})
export default class RightController extends Mixins(VueHoduCommon) {

    /**
     * @CalendarInfo.State 
     */
    @CalendarInfo.State calendar_view_type !: string;

    /**
     * @MenuInfo.State
     */
    @MenuInfo.State user_profile_open           !: boolean;
    @MenuInfo.State notification_list_menu_open !: boolean;
    @MenuInfo.State preferences_menu_open       !: boolean;
    @MenuInfo.State todo_menu_open              !: boolean;
    @MenuInfo.State todo_create_or_update       !: boolean;
    @MenuInfo.State etc_menu_open               !: boolean;
    @MenuInfo.State filter_menu_open            !: boolean;
    
    /**
     * @MenuInfo.Action
     */
    @MenuInfo.Action doSetUserProfileOpen          ?: any;
    @MenuInfo.Action doSetNotificationListMenuOpen ?: any;
    @MenuInfo.Action doSetPreferencesMenuOpen      ?: any;
    @MenuInfo.Action doSetTodoMenuOpen             ?: any;
    @MenuInfo.Action doTodoCreateOrUpdate          ?: any;
    @MenuInfo.Action doSetEtcMenuOpen              ?: any;
    @MenuInfo.Action doSetFilterMenuOpen           ?: (params : boolean) => void;

    profile_image_error : boolean = false;
    userImageUrl : string = require("@/assets/images/contents/im_photoB.gif");
    userDefaultImageUrl : string = require("@/assets/images/contents/im_photoB.gif");

    is_remain_noti_and_todo : number | undefined;
    notificatin_count : number = 0;
    todo_count : number = 0;

    beforeMount() : void {
        this.userImageUrl = `/app_images/profile/user/${Math.floor((this.user_id != null ? this.user_id : 0) / 10000)}/${this.user_id}.jpg`;
    }

    mounted() : void {
        this.getNotificationCount();
        this.getTodoCount();

        // 안 읽은 알림이 있는지, 기간 내의(오늘이 시작일 이후) 완료되지 않은 할 일이 있는지 여부 조회 (1분 마다 조회)
        this.is_remain_noti_and_todo = window.setInterval(() => {
            this.getNotificationCount();
            this.getTodoCount();
        }, 60000);

        // App.vue에서 메뉴가 닫힐때 개수 가져오는 함수 호출
        window['getNotificationCount'] = this.getNotificationCount;
        window['getTodoCount'] = this.getTodoCount;
    }

    /**
     * 종료 전 interval 제거
     */
    beforeDestroy() : void {
        if( this.is_remain_noti_and_todo ) { clearInterval(this.is_remain_noti_and_todo); }
    }

    /**
     * 안 읽은 알림 개수 조회
     */
    getNotificationCount() : void {
        
        this.hodu_api_call(`api/v1/notification/count?current_date=${moment(new Date()).utc().format()}&is_web=true`, API_METHOD.GET, null, false)
            .then((response) => {
                // console.log(response);
                this.notificatin_count = response.data.data.notificatin_count;

                if( this.notificatin_count < 0 ) {
                    this.notificatin_count = 0;
                }
            })
            .catch((e) => {
                this.notificatin_count = 0;
            });
    }

    /**
     * 완료하지 않은 개인 할 일 개수 조회
     */
    getTodoCount() : void {
        this.hodu_api_call('api/v1/todo/count', API_METHOD.GET, null, false)
            .then((response) => {
                // console.log(response);
                this.todo_count = response.data.data.todo_count;

                if( this.todo_count < 0 ) {
                    this.todo_count = 0;
                }
            })
            .catch((e) => {
                this.todo_count = 0;
            });
    }

    /**
     * 프로필 이미지 에러
     */
    profileImageError() : void {
        this.profile_image_error = true;
    }
    
    /**
     * 프로필 클릭
     */
    userProfileClick() : void {
        this.doSetUserProfileOpen(!this.user_profile_open);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(false);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(false);
    }

    /**
     * 알림 리스트 메뉴 클릭
     */
    notificationListMenuClick() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(!this.notification_list_menu_open);
        this.doSetPreferencesMenuOpen(false);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(false);

        // 알림 메뉴가 닫힐때 안 읽은 알림 개수 재조회
        if( this.notification_list_menu_open == false ) { this.getNotificationCount(); }
    }

    /**
     * 환경설정 메뉴 클릭
     */
    preferencesMenuClick() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(!this.preferences_menu_open);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(false);
    }

    /**
     * 할 일 메뉴 클릭
     */
    todoMenuOpenClick() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(false);

        // 할 일 리스트, 할 일 작성&수정 둘 중 하나라도 열려 있을때 닫는다
        if( this.todo_menu_open == true || this.todo_create_or_update == true ) {
            this.doSetTodoMenuOpen(false);
            this.doTodoCreateOrUpdate(false);

            // 할 일 메뉴가 닫힐때 남은 할 일 개수를 재조회
            this.getTodoCount();
            return;
        }
        
        // 닫혀 있을때는 todoList를 연다
        this.doSetTodoMenuOpen(true);
    }

    /**
     * 기타메뉴 클릭
     */
    etcMenuClick() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(false);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(!this.etc_menu_open);
        this.doSetFilterMenuOpen?.(false);
    }

    /**
     * 환경설정이 보일지의 여부
     */
    getPreferencesVisibility() : boolean {

        // 개인 달력
        if( this.scope == OWNER_TYPE.PERSONAL ) {
            return true; // TODO 나중에 개인 환경 설정 나오면 true로 변경 후 작업
        }

        // 호두 D, 호두 H는 일반 파일함 사용 안함
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD || this.scope_group_team_option.biz_type == GROUP_TYPE.BIZH ) { return false; }

        // 프리미엄 그룹 달력 && 그룹 관리자 일때만 보임
        if( this.scope != OWNER_TYPE.PERSONAL && this.scope_group_team_option.biz_id != null && this.scope_group_team_option.biz_id.length > 0 &&
            (this.get_group_role(this.scope_group_id) == 'GROUP_MANAGER' || this.get_group_role(this.scope_group_id) == 'ADMIN') ) {
            return true; 
        }
        
        return false;
    }

    /**
     * 프린트
     */
    printCalendar() : void {
        window.print();
    }

    /**
     * 필터메뉴 열기 / 닫기
     */
    filterMenuOnOff() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(false);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(!this.filter_menu_open);
    }

    /**
     * 이미지 에러가 난 상태에서 프로필 변경시 반영을 하기 위함
     */
    @Watch('image_time')
    watchImageTimeTag() : void {
        this.profile_image_error = false;
    }
}

</script>

<style scoped>
    .newOn .new {  display: block !important; }
    .newOn a:hover { background-color:#477fff !important }
    .newOn:hover a:hover .new { background: #477fff !important;border: 2px solid #fff; }
    .new { position: absolute;left: 21px !important;top: auto !important;min-width: auto !important;top: 8px !important;padding: 0 !important;width: 5px;display: none !important;height: 5px;background: #ff6060 !important;border: 2px solid #fff; }

    /* #container #right_area_controller li.filterLi span { min-width: 75px; } */

    .filter_icon { position: relative; }
    .filter_content { display : none; box-sizing: content-box; padding:20px 20px; z-index: 9999999; background: #ffffff; position: absolute; left: -153px; top:28px; box-shadow: 0 0px 10px rgba(0,0,0,0.05); -webkit-box-shadow: 0 0px 10px rgba(0,0,0,0.05); border-radius: 3px;}
    .filter_content.on { display: block; }
    .filter_content::after{ content: ''; display: block; top: 17px; transform: translateY(-50%); right:-7px; border-top: 34px solid #ffffff; border-right: 20px solid transparent; width: 0px; height: 0px; position: absolute; }
    .filter_content >ul >li {padding: 5px;}
    .filter_content >ul >li:first-child{padding-top: 0px;}
    .filter_content >ul >li:last-child{padding-bottom: 0px;}
    .filter_content >ul >li>p {opacity: 1; }
    .filter_content >ul >li>p >span { display: inline-block; padding: 4px 0; color: #7c88a2; cursor: pointer; font-weight: bold;}
    input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border-radius:3px;border: 2px solid transparent;background: #c1cfd8 url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
    input[type='checkbox']:not(old):checked + label {background: #477fff url('../../assets/images/contents/checked_fff.png') no-repeat -1px -1px; background-size: 18px;}

</style>