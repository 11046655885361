<template>
    <div id="supply_list" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">비품·수불 관리</h3>
        </div>

        <!-- <input type="button" class="input_btn" value="상세" @click.prevent="moveSupplyDetail" /> -->
        <div id="bt_scheduleBoxNoti" class="bt_scheduleBox">
            <div class="inbox">
                <div class="event_btn">
                    <input type="button" title="제품등록" class="btn_supply_input" @click.prevent="moveSupplyCreate"/>
                    <p>제품등록</p>
                </div>
                <div class="event_btn">
                    <input type="button" title="입고 등록" class="btn_buy_input" @click.prevent="moveSupplyManagementCreate('inbound')">
                    <p>입고</p>
                </div>
                <div class="event_btn">
                    <input type="button" title="출고 등록" class="btn_send_input" @click.prevent="moveSupplyManagementCreate('outbound')">
                    <p>출고</p>
                </div>
            </div>
            <input type="button" class="bt_schedule" value="생성">
        </div>

        <div class="section_scroll">
            <div class="viewGroup">
                <div id="aptSupply_div" class="aptSupply_sort">
                    <!-- 타이틀 -->
                    <div class="titleDiv titleTab_div">
                        <h2 class="title" :class="{ active : tab == 'SUPPLY' }">
                            <a href="#" class="tit_tab" @click.prevent="tabChange('SUPPLY')">제품 목록</a>
                        </h2>
                        <h2 class="title" :class="{ active : tab == 'MANAGEMENT' }">
                            <a href="#" class="tit_tab" @click.prevent="tabChange('MANAGEMENT')">입·출고 목록</a>
                        </h2>
                    </div>
                    <!-- //타이틀 -->
                    <div class="fixedDiv_con">
                        <ul id="supply_list" class="supplyList_calss" v-if="tab == 'SUPPLY'">
                            <li>
                                <div class="filter_tap">
                                    <div class="content_top filter2">
                                        <input type="text" id="filter_search" placeholder="분류, 제품명 등으로 검색하세요" :value="search_query" @input="search_query = $event.target.value">
                                    </div>
                                </div>
                                <ul id="" class="sortHeader">
                                    <li class="time">
                                        <span>등록일</span>
                                    </li>
                                    <li class="supplyClass">
                                        <span>분류</span>
                                    </li>
                                    <li class="supplyTitle">
                                        <span>품명</span>
                                    </li>
                                    <li class="price">
                                        <span>최종단가</span>
                                    </li>
                                    <li class="stock">
                                        <span>재고</span>
                                    </li>
                                    <li class="store">
                                        <span>비고(용도)</span>
                                    </li>
                                    <li class="writtenBy">
                                        <span>담당자</span>
                                    </li>
                                </ul>
                                <div id="supply_scroll" class="aptSupply_con" :style="{ height : `${scroll_height}px` }">
                                    <ul class="aptSupplyUl">

                                        <li :key="supply.uid" v-for="supply of computedSupplies">
                                            <p class="time">
                                                {{ hodu_date_to_format_string(hodu_yyyymmdd_to_date(supply.supply_data.date), 'YYYY.MM.DD') }}
                                                ({{ getDayOfWeekByDate(hodu_yyyymmdd_to_date(supply.supply_data.date)) }})
                                            </p>
                                            <p class="supplyClass">{{ hodu_string_validation(supply.supply_data.category) }}</p>
                                            <p class="supplyTitle">
                                                <span class="supply_img" :style="{
                                                    backgroundImage : `url(app_images/${supply.supply_data.image.url})`
                                                }" v-if="supply.supply_data.image != null">이미지</span>
                                                {{ hodu_string_validation(supply.supply_data.name) }}
                                            </p>
                                            <p class="price">
                                                {{ (supply.unit_price == null || supply.unit_price == -1) ? '-' : hodu_string_validation(String(supply.unit_price)) }}
                                            </p>
                                            <p class="stock">
                                                {{ supply.inbound_count - supply.outbound_count }}
                                            </p>
                                            <p class="store">
                                                {{ hodu_string_validation(supply.supply_data.desc) }}
                                            </p>
                                            <p class="writtenBy">{{ hodu_string_validation(supply.user_name) }}</p>
                                            <a href="#" class="supplyDetail" @click.prevent="moveSupplyDetail(supply.uid)">제품 상세보기</a>
                                        </li>

                                        <!-- <li>
                                            <p class="time">21.06.21 (월)</p>
                                            <p class="supplyClass">공기구</p>
                                            <p class="supplyTitle"><span class="supply_img">이미지</span>전동드릴</p>
                                            <p class="price">13,000</p>
                                            <p class="stock">20</p>
                                            <p class="store">벽 드릴작업 용도</p>
                                            <p class="writtenBy">김관리</p>
                                            <a href="#" class="supplyDetail" @click.prevent="moveSupplyDetail">제품 상세보기</a>
                                        </li> -->
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        
                        <ul id="inAndout_list" class="inAndout_calss" v-if="tab == 'MANAGEMENT'">
                            <li>
                                <div class="filter_tap">
                                    <div class="content_top filter2">
                                        <input type="text" id="filter_search" placeholder="분류, 제품명 등으로 검색하세요" :value="search_query" @input="search_query = $event.target.value">
                                        <a href="#" class="bt_filter" @click.prevent="filterOnOff"></a>
                                    </div>
                                    <div class="nav_filter" :class="{ on : filter_open == true }" v-if="filter_open == true">
                                        <div>
                                            <ul>
                                                <li>
                                                    <input type="button" id="supplyFil_calendar_start" class="supplyFil_calendar" :value="`${hodu_date_to_format_string(filter_start, 'YYYY.MM.DD')} ${getDayOfWeekByDate(filter_start)}`">
                                                    <span>~</span>
                                                    <input type="button" id="supplyFil_calendar_end" class="supplyFil_calendar" :value="`${hodu_date_to_format_string(filter_end, 'YYYY.MM.DD')} ${getDayOfWeekByDate(filter_end)}`">
                                                </li>
                                                <li class="detail_filter">
                                                    <label for="filter_inAndout">입·출고</label>
                                                    <select name="filter_inAndout" id="filter_inAndout" v-model="filter_type">
                                                        <option value="">전체</option>
                                                        <option value="inbound">입고</option>
                                                        <option value="outbound">출고</option>
                                                    </select>
                                                    <label for="filter_class">분류</label>
                                                    <select name="filter_class" id="filter_class" v-model="filter_category">
                                                        <option value="">전체</option>
                                                        <option :key="category" :value="category" v-for="category in categories">{{ category }}</option>
                                                        <!-- <option value="공기구">공기구</option> -->
                                                        <!-- <option value="비품">비품</option> -->
                                                        <!-- <option value="사무용품">사무용품</option> -->
                                                    </select>
                                                    <label for="store_search">구입처</label>
                                                    <input type="text" id="store_search" :value="filter_vendor" @input="filter_vendor = $event.target.value">
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ul id="" class="sortHeader">
                                    <li class="time">
                                        <span>등록일</span>
                                    </li>
                                    <li class="supplyClass">
                                        <span>분류</span>
                                    </li>
                                    <li class="supplyTitle">
                                        <span>품명</span>
                                    </li>
                                    <li class="buyCount">
                                        <span>입고량</span>
                                    </li>
                                    <li class="sendCount">
                                        <span>출고량</span>
                                    </li>
                                    <li class="stock">
                                        <span>재고</span>
                                    </li>
                                    <li class="price">
                                        <span>단가</span>
                                    </li>
                                    <li class="store">
                                        <span>비고</span>
                                    </li>
                                    <li class="writtenBy">
                                        <span>담당자</span>
                                    </li>
                                </ul>
                                <div id="supply_management_scroll" class="aptSupply_con" :style="{ height : `${scroll_height}px` }">
                                    <ul class="aptSupplyUl">
                                        <li :key="`${management.uid}_${management.seq}`" v-for="management in computedManagements">
                                            <p class="time">
                                                {{ hodu_date_to_format_string(management.audit_created, 'YYYY.MM.DD') }}
                                                ({{ getDayOfWeekByDate(management.audit_created) }})
                                            </p>
                                            <p class="supplyClass">{{ hodu_string_validation(management.supply.supply_data.category) }}</p>
                                            <p class="supplyTitle">
                                                <span class="supply_img" :style="{
                                                    backgroundImage : `url(app_images/${management.supply.supply_data.image.url})`
                                                }" v-if="management.supply.supply_data.image != null">이미지</span>
                                                {{ hodu_string_validation(management.supply.supply_data.name) }}
                                            </p>
                                            <p class="buyCount">{{ management.management_data.type == 'inbound' ? management.management_data.count : '-' }}</p>
                                            <p class="sendCount">{{ management.management_data.type == 'outbound' ? management.management_data.count : '-' }}</p>
                                            <p class="stock">{{ getStock(management) }}</p>
                                            <p class="price">{{ management.management_data.type == 'inbound' ? hodu_string_validation(String(management.management_data.unit_price)) : '-' }}</p>
                                            <p class="store">{{ hodu_string_validation(management.management_data.desc) }}</p>
                                            <p class="writtenBy">{{ hodu_string_validation(management.user_name) }}</p>
                                            <a href="#" class="supplyDetail" @click.prevent="showSupplyManagementDetail(management.uid, management.seq)">입·출고 상세보기</a>
                                        </li>
                                        <!-- <li>
                                            <p class="time">2021.06.21 (월)</p>
                                            <p class="supplyClass">공기구</p>
                                            <p class="supplyTitle"><span class="supply_img">이미지</span>전동드릴</p>
                                            <p class="buyCount">12</p>
                                            <p class="sendCount">-</p>
                                            <p class="stock">20</p>
                                            <p class="price">13,000</p>
                                            <p class="store">호두철물점 / 정비에 맞추어 수량 입고</p>
                                            <p class="writtenBy">김관리</p>
                                            <a href="#" class="supplyDetail">입·출고 상세보기</a>
                                        </li>
                                        <li>
                                            <p class="time">2021.06.20 (일)</p>
                                            <p class="supplyClass">공기구</p>
                                            <p class="supplyTitle"><span class="supply_img">이미지</span>전동드릴</p>
                                            <p class="buyCount">-</p>
                                            <p class="sendCount">3</p>
                                            <p class="stock">8</p>
                                            <p class="price">-</p>
                                            <p class="store">호두철물점 / 1단지,5단지,7단지 경비실</p>
                                            <p class="writtenBy">정관리</p>
                                            <a href="#" class="supplyDetail">입·출고 상세보기</a>
                                        </li>
                                        <li>
                                            <p class="time">2021.06.21 (월)</p>
                                            <p class="supplyClass">공기구</p>
                                            <p class="supplyTitle"><span class="supply_img">이미지</span>전동드릴</p>
                                            <p class="buyCount">-</p>
                                            <p class="sendCount">4</p>
                                            <p class="stock">11</p>
                                            <p class="price">-</p>
                                            <p class="store">호두철물점 / 1단지,3단지,4단지 경비실</p>
                                            <p class="writtenBy">정관리</p>
                                            <a href="#" class="supplyDetail">입·출고 상세보기</a>
                                        </li> -->
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class SupplyList extends Mixins(VueHoduCommon) {

    /**
     * computed 비품 리스트
     */
    get computedSupplies() : any[] {
        let list = JSON.parse(JSON.stringify(this.supplies));

        const search_query = this.search_query.toUpperCase().trim();

        if( search_query.length > 0 ) {
            list = list.filter(supply => this.hodu_string_includes(supply.supply_data.name, search_query) ||
                                         this.hodu_string_includes(supply.supply_data.category, search_query) ||
                                         this.hodu_string_includes(supply.supply_data.manager, search_query) || 
                                         this.hodu_string_includes(supply.supply_data.manager_department, search_query) || 
                                         this.hodu_string_includes(supply.supply_data.desc, search_query) || 
                                         this.hodu_string_includes(String(supply.unit_price), search_query) || 
                                         this.hodu_string_includes(String(supply.inbound_count - supply.outbound_count), search_query) || 
                                         this.hodu_string_includes(supply.user_name, search_query));
        }

        return list;
    }

    /**
     * computed 장비 관리 이력 리스트
     */
    get computedManagements() : any[] {
        let list = JSON.parse(JSON.stringify(this.managements));

        const search_query = this.search_query.toUpperCase().trim();

        if( search_query.length > 0 ) {
            list = list.filter(management => this.hodu_string_includes(management.supply.supply_data.name, search_query) ||
                                             this.hodu_string_includes(management.supply.supply_data.category, search_query) ||
                                             this.hodu_string_includes(management.management_data.desc, search_query) ||
                                             this.hodu_string_includes(management.user_name, search_query));
        }

        if( this.filter_open == true ) {
            
            // 날짜 필터
            list = list.filter(management => this.hodu_date_to_format_string(this.filter_start, "YYYYMMDD") <= this.hodu_date_to_format_string(management.audit_created, 'YYYYMMDD') && 
                                             this.hodu_date_to_format_string(management.audit_created, 'YYYYMMDD') <= this.hodu_date_to_format_string(this.filter_end, "YYYYMMDD"))

            if( this.filter_type != null && this.filter_type.length > 0 ) {
                list = list.filter(management => management.management_data.type == this.filter_type);
            }

            if( this.filter_category != null && this.filter_category.length > 0 ) {
                list = list.filter(management => management.supply.supply_data.category == this.filter_category);
            }

            if( this.filter_vendor != null && this.filter_vendor.trim().length > 0 ) {
                list = list.filter(management => management.management_data.vendor != null && this.hodu_string_includes(management.management_data.vendor, this.filter_vendor));
            }

        }

        return list;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetSupplyManagementDetailModalInfo ?: (params : hodu_home_modal_info.SupplyManagementDetailModalInfo) => void;

    tab : string = "SUPPLY";

    supplies : any[] = [];
    managements : any[] = [];
    categories : any[] = [];

    scroll_height : number = 0;

    search_query : string = "";

    filter_open : boolean = false;
    filter_start : Date = new Date();
    filter_end : Date = new Date();
    filter_type : string = "";
    filter_category : string = "";
    filter_vendor : string = "";

    beforeMount() : void {
        this.filter_start = new Date();
        this.filter_end   = new Date(this.filter_start);
        this.filter_start.setDate(this.filter_start.getDate() - 7);
    }

    mounted() : void {

        if( this.tab == 'SUPPLY' ) {
            this.getHomeSupplies();
        }
        else {
            this.getHomeSupplyManagements();
        }

        this.setScroll();

        // //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.fixedDiv_con > ul').hide();
        // 	$('.fixedDiv_con > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });

        // //필터 on/off
        // $('.bt_filter').off("click").on("click", function(){
        // 	if($(".bt_filter, .nav_filter").hasClass("on")){
        //         $(".bt_filter, .nav_filter").removeClass("on");
        //     } else {
        //         $(".bt_filter, .nav_filter").addClass("on");
        //     }
        // });
        
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const title_div_height = $('.titleDiv').outerHeight();
        const sort_header_height = $('.sortHeader').outerHeight();
        const filter_tap_height = $('.filter_tap').outerHeight();

        this.scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                - (title_div_height ? title_div_height : 0)
                                                - (sort_header_height ? sort_header_height : 0)
                                                - (filter_tap_height ? filter_tap_height : 0);
    }

    /**
     * 비품 리스트 조회
     */
    async getHomeSupplies() : Promise<void> {
        
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply`, API_METHOD.GET);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.supplies ) {
                throw new Error("비품 리스트 조회 중 오류 발생");
            }

            this.supplies.splice(0, this.supplies.length);
            this.supplies = this.supplies.concat(response.data.data.supplies);

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 리스트 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 비품 관리 이력 리스트 조회
     */
    async getHomeSupplyManagements() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/management`, API_METHOD.GET);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.managements ) {
                throw new Error("비품 관리 이력 리스트 조회 중 오류 발생");
            }

            this.managements.splice(0, this.managements.length);
            this.managements = this.managements.concat(response.data.data.managements);

            if( response.data.data.managements ) {
                this.categories.splice(0, this.categories.length);
                this.categories = this.categories.concat(response.data.data.categories);
            }

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 관리 이력 리스트 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 탭 변경
     */
    tabChange(tab) : void {
        this.search_query = "";
        this.filter_open = false;
        this.tab = tab;

        if( this.tab == 'SUPPLY' ) {
            this.getHomeSupplies();
        } 
        else {
            this.getHomeSupplyManagements();
        }

        this.$nextTick(() => this.setScroll());
    }

    /**
     * 재고수 반환
     */
    getStock(current_management) : number {

        let filter = this.managements.filter(management => management.uid == current_management.uid);

        let index = -1;
        for( const management of filter ) {
            if( management.uid == current_management.uid && management.seq == current_management.seq ) index = filter.indexOf(management);
        }

        if( index < 0 ) {
            return 0; 
        }

        let stock : number = 0;
        let array_count : number = filter.length;

        for( let i = (array_count - 1); i >= index; i-- ) {
            const management = filter[i]
            if ( management.management_data.type == 'inbound' ) stock += management.management_data.count;
            if ( management.management_data.type == 'outbound' ) stock -= management.management_data.count;
        }

        return stock;
    }

    /**
     * 필터 열기 / 닫기
     */
    filterOnOff() : void {
        this.filter_open = !this.filter_open;

        if( this.filter_open == true ) this.datepickerInit();
        this.$nextTick(() => this.setScroll());
    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {

            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(selected_date)}`);

                    switch ( inst.id ) {
                        case "supplyFil_calendar_start":
                            this.filter_start = new Date(selected_date);
                            if( this.hodu_date_to_format_string(this.filter_start, 'YYYYMMDD') > this.hodu_date_to_format_string(this.filter_end, 'YYYYMMDD') ) {
                                this.filter_end = new Date(this.filter_start);
                            }
                            break;

                        case "supplyFil_calendar_end":
                            this.filter_end = new Date(selected_date);
                            if( this.hodu_date_to_format_string(this.filter_start, 'YYYYMMDD') > this.hodu_date_to_format_string(this.filter_end, 'YYYYMMDD') ) {
                                this.filter_start = new Date(this.filter_end);
                            }
                            break;
                    }

                },
            }

            // @ts-ignore
            $('#supplyFil_calendar_start, #supplyFil_calendar_end').datepicker(datepicker_option);
        });
    }

    /**
     * 비품 입·출고 내역 상세
     */
    showSupplyManagementDetail(uid, seq) : void {
        this.doSetSupplyManagementDetailModalInfo?.({
            show_modal : true,
            uid : uid,
            seq : seq,
            callback : this.getHomeSupplyManagements
        });
    }

    /**
     * 비품 상세
     */
    moveSupplyDetail(uid) : void {
        this.hodu_router_push(`/home/supply/${uid}`);
    }

    /**
     * 비품 생성
     */
    moveSupplyCreate() : void {
        this.hodu_router_push(`/home/supply/create`);
    }

    /**
     * 비품 입·출고 내역 등록
     */
    moveSupplyManagementCreate(type) : void {
        this.hodu_router_push(`/home/supply/all/management/${type}/create`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.$nextTick(() => this.setScroll());
    }

}
</script>

<style scoped>
    /* 필터 */
    #supply_list .filter_tap .content_top { position: relative; height: 67px; border-bottom: 1px solid #e7e9ea; }
    #supply_list .filter_tap .content_top #filter_search { position: absolute; top: 12px; left: 30px; width: 355px; transition: 0.2s; height: 40px; line-height: 40px; padding: 0px 15px; font-size: 12px; font-weight: normal; box-sizing: border-box; border: 1px solid #e7e9ea; display: inline-block; }
    #supply_list .filter_tap .content_top .bt_filter { position: absolute; width: 40px; height: 100%; background: url('../../assets/images/contents/ic_top_filter_new.png') no-repeat center; box-sizing: border-box; right: 15px; background-size: 27px; padding: 0 35px; }
    #supply_list .filter_tap .content_top .bt_filter.on { background: url('../../assets/images/contents/ic_attnd_no_dc7.png') no-repeat center; background-size: 25px; }
    #supply_list .filter_tap .content_top h3 { line-height: 67px; height: 100%; font-size: 14px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; box-sizing: border-box; text-align: right; margin-right: 37px; }
    #supply_list .filter_tap .nav_filter { display: none; }
    #supply_list .filter_tap .nav_filter.on { display: block; }
    #supply_list .filter_tap .nav_filter ul { padding: 0 30px; }
    #supply_list .filter_tap li { line-height: 55px; font-size: 14px; border-bottom: 1px solid #f1f3f5; }
    #supply_list .filter_tap li span { padding: 0 30px; font-weight: bold; }
    #supply_list .filter_tap .supplyFil_calendar { width : 87px; background: none; font-weight: bold; }
    #supply_list .filter_tap .supplyFil_calendar:hover { color: #477fff; }
    #supply_list .filter_tap .detail_filter { border-bottom: none; }
    #supply_list .filter_tap .detail_filter label { margin-right: 10px; }
    #supply_list .filter_tap .detail_filter select { border: 1px solid #e7e9ea; border-radius: 5px; height: 30px; line-height: 30px; cursor: pointer; box-sizing: border-box; padding: 0 30px 0 10px; appearance: none; background: url(/img/bt_open.0927a4a6.png) no-repeat right; margin-right: 40px; }
    #supply_list .filter_tap .detail_filter #store_search { border: 1px solid #e7e9ea; border-radius: 5px; height: 30px; padding: 0 10px; }

    /* 리스트 */
    #supply_list #aptSupply_div .sortHeader { padding: 0 20px; }
    #supply_list #aptSupply_div .sortHeader li { width: 12%; text-align: left; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #supply_list #aptSupply_div.aptSupply_sort .supplyClass, #supply_list #aptSupply_div.aptSupply_sort .price { width: 9%; }
    #supply_list #aptSupply_div.aptSupply_sort .supplyTitle, #supply_list #aptSupply_div.aptSupply_sort .store { width: 20%; }
    #supply_list #aptSupply_div.aptSupply_sort .time { width: 13%; }
    #supply_list #aptSupply_div.aptSupply_sort .supplyList_calss .supplyTitle, #supply_list #aptSupply_div.aptSupply_sort .supplyList_calss .store { width: 24%; }
    #supply_list #aptSupply_div.aptSupply_sort .supplyList_calss .supplyClass { width: 13%; }

    #supply_list #aptSupply_div.aptSupply_sort .buyCount, #supply_list #aptSupply_div.aptSupply_sort .sendCount, #supply_list #aptSupply_div.aptSupply_sort .stock { width: 7%; text-align: right; padding-right: 20px; }
    #supply_list #aptSupply_div.aptSupply_sort .stock { color: #477fff; }
    #supply_list #aptSupply_div.aptSupply_sort .writtenBy { width: 8%; text-align: right; }

    #supply_list .aptSupply_con { position: relative; overflow: auto; }
    #supply_list .aptSupplyUl li { position: relative; transition: .2s; overflow: hidden; width: 100%; line-height: 55px; height: 55px; padding: 0 20px; box-sizing: border-box; border-bottom: 1px solid #f1f3f5; color: #202a39; cursor: pointer; }
    #supply_list .aptSupplyUl > li p { width: 12%; text-align: left; font-weight: 700; float: left; display: inline-block; padding-right: 15px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; opacity: .7; }
    #supply_list .aptSupplyUl > li:hover p { opacity: 1; font-weight: bold; }
    #supply_list .aptSupplyUl > li p.supplyTitle span { width: 40px; height: 40px; display: inline-block; font-size: 0; border: 2px solid #f1f3f5; border-radius: 50%; background: url('../../assets/images/popup/voucher_popup.png') no-repeat center; background-size: cover; vertical-align: top; margin-top: 5px; margin-right: 10px; }
    #supply_list .aptSupplyUl a.supplyDetail { position: absolute; line-height: 55px; height: 55px; top: 0; width: 100%; display: block; border: none; z-index: 1000; font-size: 0; background: transparent; left: 0; }

    #supply_list ::-webkit-scrollbar{width: 6px;}
    #supply_list ::-webkit-scrollbar-track{background-color: #f1f3f5;}
    #supply_list ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}
</style>