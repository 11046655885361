<template>
    <div class="section_ce_fix">
        <div class="title_box" style="">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" style="" id="viewTitle">호두 공지사항 {{is_created ? "등록" : "수정"}}</h3>
            <p class="btn_group" style="">
                <input type="button" id="" name="" value="저장" class="input_btn" @click="actionSave()">
            </p>
        </div>
        <div class="content notice">
            <div class="grp">
                <div class="when">
                    <label for="noti_datepicker">공지 등록일</label>
                    <input type="button" id="noti_datepicker" value="2019.11.01" class="input_txt" style="" readonly/>
                </div>
            </div>
            <div class="editArea">
                <input type="text" id="noti_title" class="input_txt " placeholder="제목을 입력하세요." :value="o_notice.noti_info.title" @input="o_notice.noti_info.title = $event.target.value"/>
                <div class="edtContentDiv">
                    <textarea id="modal_noti_contents" name="" cols="" rows="5" class="input_textarea" placeholder="공지 내용을 작성하세요"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { t_notification } from '@/model/osm';

const EtcInfo    = namespace('EtcInfo');
const dateFormat = require('dateformat');

declare var CKEDITOR: any;
@Component({
    components : {}
})
export default class HoduNoticeCreate extends Mixins(VueHoduCommon) {
    @EtcInfo.State hodu_noti_uid !: string;
    is_created : boolean = true;

    o_notice : t_notification = {
        noti_uid : "",
        noti_type : "",
        noti_sub_type: "",
        group_id: 0,
        team_id: 0,
        team_ids: [],
        group_ids: [],
        user_ids: [],
        noti_info: {
            title: "",
            start_date: null,
            end_date: null,
            calendar_id: "",
            event_id: "",
            event_sub_type: "",
            shared: "",
            scope: "",
            scope_id: 0
        },
        noti_data: {
            body: "",
            body_type: null
        },
        meta: "",
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: 0
    };    

    async mounted() : Promise<void> {
        const vue = this;

        // 리사이즈시 322를 제외한 section_ce하이트로 하단에 하이트 변경
        let height_ori = $("#section_ce").outerHeight();
        let height = (height_ori == null ? 0 : height_ori) - 332;

        // 공지사항 수정일때 사용함..
        if ( this.hodu_noti_uid ) {
            this.is_created = false;
        }
        else {
            this.is_created = true;
            this.o_notice.noti_info.start_date = new Date();
            this.setNoticeStartYmdDisplay();
        }

        // date_picker
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                vue.o_notice.noti_info.start_date = new Date(dateText)
                vue.setNoticeStartYmdDisplay();
            },
        };

        // @ts-ignore
        $('#noti_datepicker').datepicker(option);

        // CKEDITOR
        setTimeout(() => {
            $("#cke_modal_noti_contents .cke_contents").attr('style', 'height : ' + height + 'px !important');
        }, 100);
        
        await CKEDITOR.on( 'instanceReady', function( e ) {
            let editor = e.editor,body = CKEDITOR.document.getBody();
            editor.on( 'focus', function() {
                // texArea에 포커스가 있을때 datePicker none 처리
                $('#ui-datepicker-div').css("display", "none");
            });
        });

        // 공지 상세
        this.getNoticeDetatil();
    }

    /**
     * 공지 시작일 표시
     */
    setNoticeStartYmdDisplay() : void {
        $('#noti_datepicker').val(dateFormat(this.o_notice.noti_info.start_date, "yyyy.mm.dd"));
    }    

    /**
     * 공지사항 상세화면 조회
     */
    async getNoticeDetatil() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getNoticeDetail/0/0?uid=${this.hodu_noti_uid}`, API_METHOD.GET, {}, false)
            .then(async(response) => {
                if ( this.is_created == false ) {
                    // 데이터가 없을경우
                    if ( !response.data.data.noti_data ) {
                        vue.movePrevPage();
                        return;
                    }
                    else {
                        this.o_notice = response.data.data.noti_data;
                        this.setCkeditor();
                        this.setNoticeStartYmdDisplay();
                    }
                }
                else {
                    this.setCkeditor();
                }

                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, true);
            });        
    }    

    /**
     * CKEditor 세팅
     */
    async setCkeditor() : Promise<void> {
        let ckeditorOption : any = {
            uploadUrl: "api/v1/upload/noticeUpload?type=image",  // 이게 드래그 드롭을 위한 URL
            filebrowserUploadUrl: "api/v1/upload/noticeUpload?type=file",  // 파일업로드를 위한 URL
            filebrowserImageUploadUrl : "api/v1/upload/noticeUpload?type=image",//"/hoduweb/upload/ckeditorImg.do",
            filebrowserUploadMethod : 'xhr',
            fileTools_requestHeaders: {
                'X-Requested-With': 'XMLHttpRequest',
                'device-uid': sessionStorage.getItem("device_uid"),
                'authorization': sessionStorage.getItem("session_token"),
            }
        };

        ckeditorOption.resize_maxWidth = 652;

        await CKEDITOR.replace('modal_noti_contents', ckeditorOption);
        CKEDITOR.instances.modal_noti_contents.setData(this.o_notice.noti_data.body);
    }    

    /**
     * 저장
     */
    async actionSave() : Promise<void> {
        const vue = this;

        let content_body = CKEDITOR.instances.modal_noti_contents.getData();

        if ( this.o_notice.noti_info.title.trim().length < 1 ) {
            alert('제목을 입력하세요');
            return;
        }

        if ( content_body.trim().length < 1 ) {
            alert('내용을 입력하세요');
            return;
        }

        // 등록된 이미지 찾기
        let HTML = content_body;
        //let re = /<img src="(.*?)"/gi;
        let re = /src="(.*?)"/gi;
        let result = HTML.match(re);
        let replace_images : string[] = [];

        if ( result && result.length > 0 ) {
            for ( let i = 0; i < result.length; i++ ) {
                let img = result[i].replace('src="', '').replace('"', '');

                if ( Number(img.indexOf("/app_images/temp_uploads/")) > -1 ) {
                    replace_images.push(img);
                }
            }
        }

        this.o_notice.noti_data.body = content_body;

        if ( this.is_created ) {
            this.o_notice.noti_type = "HODU_NOTICE";
            this.o_notice.noti_data.body_type = "HTML";
            this.o_notice.noti_info.end_date = new Date("2050.12.31");
        }

        let req_data = {
            notification: this.o_notice,
            web_notice: "Y",
            replace_images: replace_images
        }

        await this.hodu_api_call(`api/v1/notification/notice/HODU`, this.is_created ? API_METHOD.POST : API_METHOD.PUT, req_data)
            .then(async(response) => {
                if ( this.is_created ) {
                    
                }

                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }    
}
</script>

<style scoped>
    .title_box { line-height:61px; height:61px; }
    .notice.content.noTeamsNotice .share {display:none  }
    .notice.content.noTeamsNotice .when { width:100% !important; border-right:0; }
    .notice.content .grp > div { display:inline-block; float:left; height:80px;padding: 0 25px;box-sizing: border-box; }
    .notice.content .grp > div > * {display: block; }
    .notice.content .grp > div > label, .notice.content .grp > div h4 { width:100%; color:#7c88a2; font-size:13px; font-weight:bold;margin-top:5px; line-height:40px;height:30px; float:left; }
    .notice.content .grp {height: 82px;border-bottom: 2px solid #e7e9ea;padding: 0 px;box-sizing: border-box;width: 100%;border-right: 1px solid #e7e9ea;}
    .notice.content .editArea {margin-top: 0;border-right: 1px solid #e7e9ea;}
    #noti_title { transition:0.2s; font-weight:bold; line-height: 70px; height: 70px;padding:0 20px; box-sizing:border-box; width:100%;font-size:18px;border-bottom:2px solid #e7e9ea;}
    #noti_title:hover { background:#f1f3f5; }
    #noti_title:focus { border-bottom:2px solid #477fff; background:#fff; }
    #noti_title:disabled {background: #fff;color: #273044;}
    #noti_datepicker { font-size:15px; font-weight: bold;}
    #noti_datepicker:disabled { color:#273044 !important; cursor:default; }
    .notice .when { border-right:1px solid #e7e9ea; width:50%;}
    .notice .share { padding:0 30px; position:relative; }
    .notice .share p { height: 37px;line-height: 37px; margin-top: 5px; }
    .notice .when input { height: 33px;line-height: 33px;width: 100%;text-align: left; }
    .notice .when input:hover, .notice .when input:focus { color:#477fff; }
    .notice .share span.fPic {left: 24px;top: 39px;border: 1px solid #e7e9ea;background-color: #f1f3f5;font-size: 0;min-width: 27px !important;width: 27px !important;background-position: center center !important;background-size: cover !important;height: 27px !important;border-radius: 50%;}
    .notice .share .whoWrote {padding-left: 40px;font-weight: bold;font-size: 14px;}
    
    .notice input[type='checkbox']:not(old) + label {display: inline-block;padding: 0;width: 15px;height: 15px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;font-size: 0;margin-right: 8px;}
    .notice input[type='checkbox']:not(old) + label span { color:#7c88a2; }
    .notice input[type='checkbox']:not(old) + label span em { color:#477fff; font-style:normal; }
    .notice .share span{position: absolute;left: 55px;width: 100% !important;height: 30px !important;max-width: 200px !important;min-width: 100px !important;z-index: 1000000;top: 36px;line-height: 30px;font-size: 14px;font-weight: bold;}
    .notice .share input[type='checkbox']:not(old):checked + label {background: #477fff url(../assets/images/contents/checked_fff.png) no-repeat -1px -1px;background-size: 18px;border: 2px solid #477fff;}
    .notice .share input[type='checkbox']:not(old):checked + label span { color:#273044; }
    .content.notice {width: 100%;max-width: 1000px;min-width: 700px;}
    #cke_modal_noti_contents .cke_contents { transition:0.1s; }
    .cke_chrome { border:0 none !important; }
    .cke_top { background:#f1f3f5 !important; border-bottom:0 none !important; }
    .cke_bottom { border-top: 0 none !important;background: #f1f3f5 !important; padding:0 !important; line-height:40px !important; height:40px !important; }
    .cke_resizer { display:none !important; }
    #cke_modal_noti_contents .cke_contents { transition:0.1s !important; }
</style>