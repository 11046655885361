<template>
    <div :class="{ on : calendar_menu_close }" class="alldate_box">
        <h2 @click="calendar_menu_click" class="tit_date">달력</h2>

        <a href="#" class="btMore" id="addGrp" @click.prevent="moveCalendar"></a>

        <div class="setDiv">
            <div class="raDiv"><input type="radio" id="chosenRa" name="chosenRa" class="radio_round" value="ra1" checked><label id="" for="chosenRa"><span>저장된 리스트 1</span></label></div>
            <a href="#" id="showSets" class="showSets"><span class="">저장된 세팅</span></a>
        </div>

        <!-- <a href="#" id="saveStng" data-remodal-target="modal-saveStng" class="bt_add"><span class="">현재 세팅 저장</span></a> -->
        
        <ul id="groupListUl" class="date_depth" style="color : white; text-align: left">

            <!-- 지난 일정 -->
            <li> 
                <p id="past_schedule" @click="past_schedule_click" style="background: transparent !important;"> 
                    <input type="checkbox" id="past_check" v-model="schedule_search_config.past_schedule"/>
                    <label for="past_schedule" :style="past_schedule_style"></label>
                    <span>지난 일정</span> 
                </p> 
            </li>

            <!-- 개인 일정 -->
            <li :class="{ on : personal_list_on == true }" v-if="isPersonalScope(scope)"> 
                <p @click="depth01_onOff('PERSONAL')"> 
                    <input type="checkbox" id="personal_check" v-model="schedule_search_config.personal_schedule" @change="personal_schedule_click"/>
                    <label for="personal_check"></label>
                    <span>개인 일정</span>
                </p>
                <ul class="date_depth02">
                    <li> 
                        <p id="my_schedule" @click="my_schedule_check_click"> 
                            <input type="checkbox" id="my_schedule_check" v-model="schedule_search_config.my_schedule"/>
                            <label for="my_schedule"></label>
                            <span>내 일정</span> 
                        </p> 
                    </li>
                    <li> 
                        <p id="shared_schedule" @click="shared_schedule_check_click">
                            <input type="checkbox" id="shared_schedule_check" v-model="schedule_search_config.shared_schedule"/>
                            <label for="shared_schedule"></label>
                            <span>공유받은 일정</span>
                        </p> 
                    </li>
                </ul>
            </li>

            <!-- 일반 그룹 -->
            <li :class="{ on : group_list_on == true }" v-if="normal_group_data != null && normal_group_data.length > 0 && isPersonalScope(scope)"> 
                <p @click="depth01_onOff('GROUP')"> 
                    <input type="checkbox" id="group_schedule_check" v-model="schedule_search_config.group_schedule" @change="group_schedule_click($event)"/>
                    <label for="group_schedule_check"></label>
                    <span>그룹 일정</span> 
                </p>
                <ul class="date_depth02">
                    <li :class="{ 
                        off : (group_data.teams != null && group_data.teams.length > 0 && group_data.listOn == false), 
                        on : (group_data.teams != null && group_data.teams.length > 0 && group_data.listOn == true) 
                        }" :key="group_data.group_id" v-for="(group_data, group_index) in normal_group_data"> 
                        <p id="normal_group_p" @click="depth02_onOff(group_data.teams != null ? group_data.teams.length : 0, group_data, 'GROUP', group_index)"> 
                            <input type="checkbox" :id="`check-${group_data.group_id}`" onclick="return false;" v-model="group_data.checked" />
                            <label :for="`check-${group_data.group_id}`" @click="group_filter_click(normal_group_data, group_data, 'NORMAL', group_index)"
                                   :style="group_data.css_style ? group_data.css_style : ''"></label>
                            <span>{{ group_data.group_name }}</span> 
                        </p> 
                        
                        <!-- 일반 그룹의 팀 -->
                        <ul class="date_depth03" v-if="group_data.teams != null && group_data.teams.length > 0">
                            <li :key="team_data.group_id + '-' + team_data.team_id" v-for="team_data in group_data.teams">
                                <p @click="team_filter_click(normal_group_data, group_data, team_data, 'NORMAL')"> 
                                    <input type="checkbox" :id="`check-${group_data.group_id}-${team_data.team_id}`" v-model="team_data.checked" onclick="return false;"/>
                                    <label :for="`check-${group_data.group_id}-${team_data.team_id}`" @click="team_filter_click(normal_group_data, group_data, team_data, 'NORMAL')"
                                           :style="team_data.css_style ? team_data.css_style : ''"></label>
                                    <span>{{ team_data.team_name }}</span> 
                                </p> 
                            </li>
                        </ul>

                    </li>
                </ul>
            </li>

            <!-- 호두C -->
            <li :class="{ on : hodu_c_list_on == true }" class="hc" v-if="hodu_c_group_data != null && hodu_c_group_data.filter(item => item.biz_type != 'BIZD').length > 0 && isPersonalScope(scope)"> 
                <p @click="depth01_onOff('HODU_C')"> 
                    <input type="checkbox" id="hodu_c_schedule_check" v-model="schedule_search_config.hodu_c_schedule" @change="hodu_c_schedule_click"/>
                    <label for="hodu_c_schedule_check"></label>
                    <span>프리미엄 일정</span> 
                </p>
                <ul class="date_depth02">

                    <li :class="{ 
                        off : (group_data.teams != null && group_data.teams.length > 0 && group_data.listOn == false), 
                        on : (group_data.teams != null && group_data.teams.length > 0 && group_data.listOn == true) 
                        }" :key="group_data.group_id" v-for="(group_data, group_index) in hodu_c_group_data.filter(item => item.biz_type != 'BIZD')"
                        > 
                        <p id="hodu_c_group_p" @click="depth02_onOff(group_data.teams != null ? group_data.teams.length : 0, group_data, 'HODU_C', group_index)"> 
                            <input type="checkbox" :id="`check-${group_data.group_id}`" v-model="group_data.checked" onclick="return false;"/>
                            <label :for="`check-${group_data.group_id}`" @click="group_filter_click(hodu_c_group_data, group_data, 'HODU_C', group_index)"
                                   :style="group_data.css_style ? group_data.css_style : ''"></label>
                            <span>{{ group_data.group_name }}</span>
                        </p> 

                        <!-- 비즈 그룹의 팀 -->
                        <ul class="date_depth03" v-if="group_data.teams != null && group_data.teams.length > 0">
                            <li :key="team_data.group_id + '-' + team_data.team_id" v-for="team_data in group_data.teams">
                                <p @click="team_filter_click(hodu_c_group_data, group_data, team_data, 'HODU_C')"> 
                                    <input type="checkbox" :id="`check-${group_data.group_id}-${team_data.team_id}`" v-model="team_data.checked" onclick="return false;" />
                                    <label :for="`check-${group_data.group_id}-${team_data.team_id}`" @click="team_filter_click(hodu_c_group_data, group_data, team_data, 'HODU_C')"
                                           :style="team_data.css_style ? team_data.css_style : ''"></label>
                                    <span>{{ team_data.team_name }}</span> 
                                </p> 
                            </li>
                        </ul>

                    </li>
                </ul>
            </li>

            <!-- 호두D -->
            <li :class="{ on : hodu_d_list_on == true }" class="hd" v-if="hodu_d_group_data != null && hodu_d_group_data.length > 0 && isPersonalScope(scope)">
                <p @click="depth01_onOff('HODU_D')"> 
                    <input type="checkbox" id="hodu_d_schedule_check" v-model="schedule_search_config.hodu_d_schedule" @change="hodu_d_schedule_click"/>
                    <label for="hodu_d_schedule_check"></label>
                    <span>호두닥</span> 
                </p>

                <!-- 병원 -->
                <ul class="date_depth02">
                    <li :class="{ 
                        off : (group_data.teams != null && group_data.teams.length > 0 && group_data.listOn == false), 
                        on : (group_data.teams != null && group_data.teams.length > 0 && group_data.listOn == true) 
                        }" :key="group_data.group_id" v-for="(group_data, group_index) in hodu_d_group_data">

                        <p id="hodu_d_group_p" @click="depth02_onOff(group_data.teams != null ? group_data.teams.length : 0, group_data, 'HODU_D', group_index)"> 
                            <input type="checkbox" :id="`appointment_check-${group_data.group_id}`" v-model="group_data.checked" onclick="return false;"/>
                            <label :for="`appointment_check-${group_data.group_id}`" @click="group_filter_click(hodu_d_group_data, group_data, 'HODU_D', group_index)"
                                   :style="group_data.css_style ? group_data.css_style : ''"></label>
                            <span>{{ group_data.group_name }}</span>
                        </p>
                        
                        <!-- 의사 -->
                        <ul class="date_depth03" v-if="group_data.teams != null && group_data.teams.length > 0">
                            <li :key="doctor.doctor_code" v-for="doctor in group_data.teams">
                                <p @click="team_filter_click(hodu_d_group_data, group_data, doctor, 'HODU_D')"> 
                                    <input type="checkbox" :id="`appointment_check-${group_data.group_id}-${doctor.team_id}`" v-model="doctor.checked" onclick="return false;" />
                                    <label :for="`appointment_check-${group_data.group_id}-${doctor.team_id}`" @click="team_filter_click(hodu_d_group_data, group_data, doctor, 'HODU_D')"
                                           :style="doctor.css_style ? doctor.css_style : ''"></label>
                                    <span>{{ doctor.team_name }}</span> 
                                </p> 
                            </li>
                        </ul>

                    </li>
                </ul>

            </li>

            <!-- 그룹&팀 달력에서 보여줄 체크박스 -->
            <li :class="{ on : group_and_team_list_on == true }" class="hc" v-if="!isPersonalScope(scope) && ( isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') || isHoduH(scope_group_team_option ? scope_group_team_option.biz_type : ''))">
                <!-- 그룹 -->
                <p @click="group_data.teams == null || group_data.teams.length < 1 ? bizGroupFilterPClick($event) : depth01_onOff('GROUP_AND_TEAM')" 
                   :key="group_data.group_id" v-for="group_data in getGroupAndTeam"
                   :style="group_data.teams == null || group_data.teams.length < 1 ? 'background : transparent !important;' : ''"> 
                    <input type="checkbox" id="hodu_c_group_check" v-model="group_data.checked" 
                           @change="bizGroupFilterOnOff($event.target.checked, group_data.group_id)"/>
                    <label for="hodu_c_group_check" class="biz_group_check_label" :style="group_data.css_style ? group_data.css_style : ''"></label>
                    <span @click="group_data.teams == null || group_data.teams.length < 1 ? bizGroupFilterNameClick($event) : 'return true'">{{ group_data.group_name }}</span>
                </p>

                <!-- 팀 -->
                <ul class="date_depth02" v-if="getGroupAndTeam[0] != null && getGroupAndTeam[0].teams != null && getGroupAndTeam[0].teams.length > 0">
                    <li :key="team_data.team_id" v-for="team_data in (getGroupAndTeam[0] == null ? [] : getGroupAndTeam[0].teams)">
                        <p id="group_and_team_p"> 
                            <input type="checkbox" class="biz_team_check" :id="`groupAndTeamCheck-${team_data.team_id}`" v-model="team_data.checked" 
                                   @change="bizTeamFilterOnOff($event.target.checked, team_data.team_id)"/>
                            <label :for="`groupAndTeamCheck-${team_data.team_id}`" class="biz_team_check_label" :style="team_data.css_style ? team_data.css_style : ''"></label>
                            <span @click="bizTeamFilterNameClick($event)">{{ team_data.team_name }}</span>
                        </p>
                    </li>
                </ul>
            </li>

            <!-- HODU D 그룹에서 보여줄 병원 예약 체크박스 -->
            <li :class="{ on : group_appointment_list_on == true }" class="hd" v-if="!isPersonalScope(scope) && isHoduD(scope_group_team_option.biz_type) && computedHoduDDepartmentAndDoctor.length > 0">
                <p @click="depth01_onOff('APPOINTMENT')"> 
                    <input type="checkbox" id="group_hodu_d_schedule_check" v-model="schedule_search_config.group_appointment" @change="changeGroupAppointment($event)"/>
                    <label for="group_hodu_d_schedule_check" :style="schedule_search_config.group_appointment ? `background-color : ${scope_group_team_option.group_team_color}; border-color : ${scope_group_team_option.group_team_color};` : ''"></label>
                    <span>병원 예약</span> 
                </p>

                <!-- 진료과 -->
                <ul class="date_depth02">
                    <li :class="{ 
                        off : (department.teams != null && department.teams.length > 0 && department.listOn == false), 
                        on : (department.teams != null && department.teams.length > 0 && department.listOn == true) 
                        }" :key="department.department_code" v-for="department in computedHoduDDepartmentAndDoctor">

                        <p id="group_hodu_d_group_p"> 
                            <input type="checkbox" :id="`appointment_check-${department.department_code}`" v-model="department.checked" @change="departmentOnOff($event.target.checked, department)"/>
                            <label :for="`appointment_check-${department.department_code}`" :style="department.css_style ? department.css_style : ''"></label>
                            <span @click="departmentListOnOff(department)">{{ department.group_name }}</span>
                        </p>

                        <!-- 의사 -->
                        <ul class="date_depth03" v-if="department.teams != null && department.teams.length > 0">
                            <li :key="doctor.doctor_code" v-for="doctor in department.teams">
                                <p> 
                                    <input type="checkbox" :id="`appointment_check-${department.department_code}-${doctor.doctor_code}`" v-model="doctor.checked" @change="doctorOnOff($event.target.checked, doctor)"/>
                                    <label :for="`appointment_check-${department.department_code}-${doctor.doctor_code}`" :style="doctor.css_style ? doctor.css_style : ''"></label>
                                    <span @click="doctorOnOff(!doctor.checked, doctor)">{{ doctor.team_name }}</span> 
                                </p> 
                            </li>
                        </ul>

                    </li>
                </ul>

            </li>

        </ul>
    </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, GROUP_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import { GroupAndTeamFilter } from '@/store/modules/CalendarInfo';
import { GroupTeamInfo } from '@/store/modules/GroupInfo';
const CalendarInfo = namespace('CalendarInfo');

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

import { hodu_local_storage, local_storage_info } from '@/lib/HoduLocalStorage';
import { hodu_doc_object } from '@/model/hodudoc';

@Component({

})
export default class LeftGroupFilter extends Mixins(VueHoduCommon) {

    /**
     * 그룹&팀 달력일때 가져올 리스트
     */
    get getGroupAndTeam() : GroupTeamInfo[] {
        if( this.scope == OWNER_TYPE.PERSONAL ){
            return [];
        }

        const groupAndTeam : GroupTeamInfo[] = [];
        const hodu_c_group_data_length : number = this.hodu_c_group_data.length;
        for( let i = 0; i < hodu_c_group_data_length; i++ ) {
            const group_info : GroupTeamInfo = JSON.parse(JSON.stringify(this.hodu_c_group_data[i]));
            if( group_info.group_id != this.scope_group_id ) {
                continue;
            }

            let group_and_team_filter : GroupAndTeamFilter | null = null;
            const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
            for( let j = 0; j < filter_length; j++ ) {
                if( this.scope_group_team_option.biz_id == this.schedule_search_config.group_and_team_filter[j].biz_id &&
                    this.scope == this.schedule_search_config.group_and_team_filter[j].scope &&
                    (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) == this.schedule_search_config.group_and_team_filter[j].scope_id ) {
                    group_and_team_filter = this.schedule_search_config.group_and_team_filter[j];
                    break;
                }
            }

            const team_length : number = group_info.teams.length;
            for( let j = team_length - 1; j >= 0; j-- ) {
                const team_info : GroupTeamInfo = JSON.parse(JSON.stringify(group_info.teams[j]));
                
                if( this.scope_team_id > 0 && team_info.team_id != this.scope_team_id ) {
                    group_info.teams.splice(j, 1);
                    continue;
                }

                // team_info 체크 정보 가공
                team_info.checked = true;
                if( group_and_team_filter != null && group_and_team_filter.team_ids != null && group_and_team_filter.team_ids.indexOf(team_info.team_id) > -1 ) {
                    team_info.checked = false;
                }

                group_info.teams.splice(j, 1, team_info);
            }

            // group_info 체크 정보 가공
            group_info.listOn  = true;
            group_info.checked = true;
            if( group_and_team_filter != null && group_and_team_filter.group_id != null && group_and_team_filter.group_id == group_info.group_id ) {
                group_info.checked = false;
            }
            groupAndTeam.push(group_info);
        }
        return groupAndTeam;
    }

    /**
     * 호두 D 정보로 필터 제작
     */
    get computedHoduDDepartmentAndDoctor() : GroupTeamInfo[] {

        if( this.recomputed ) { return []; } 

        let department_and_doctor : GroupTeamInfo[] = [];

        // 진료과, 의사 정보가 있다면 필터로 제작한다 (department : 그룹, doctor : 팀)
        if( this.department_info != null && this.doctor_info != null ) {

            for( const department of this.department_info ) {
                
                // 해당 진료과인 의사만 
                const doctors : hodu_doc_object.doctor_info[] = this.doctor_info.filter(item => item.department_code == department.department_code);
                
                // 해당 진료과에 의사가 없으면 건너뜀
                if( doctors.length < 1 ) { continue; }
                
                const teams : GroupTeamInfo[] = [];

                for( const doctor of doctors) {
                    teams.push({
                        level : 2,
                        row_type : "DOCTOR",
                        group_id : this.scope_group_id,
                        team_id : 0,
                        biz_id : this.scope_group_team_option.biz_id,
                        biz_type : this.scope_group_team_option.biz_type,
                        group_name : doctor.department_name,
                        team_name : doctor.doctor_name,
                        color : doctor.doctor_detail.color,
                        team_count : 0,
                        group_features : [],
                        group_info : {},
                        limit_event_attachment : 0,
                        limit_event_image_count : 0,
                        user_count : 0,
                        checked : this.schedule_search_config.group_appointment_filter.indexOf(`${this.scope_group_team_option.biz_id}___${doctor.department_code}___${doctor.doctor_code}`) < 0, // 필터에 없다면 체크
                        shareChecked : false,
                        listOn : false,
                        shareListOn : false,
                        teams : [],
                        department_code : doctor.department_code,
                        doctor_code : doctor.doctor_code,
                        css_style : this.schedule_search_config.group_appointment_filter.indexOf(`${this.scope_group_team_option.biz_id}___${doctor.department_code}___${doctor.doctor_code}`) < 0 
                                    ? `background-color : ${doctor.doctor_detail.color}; border-color : ${doctor.doctor_detail.color};`
                                    : '',
                        role : []
                    })
                }

                const is_list_on : boolean | undefined = this.appointment_list_on_map.get(department.department_code);

                // 진료과 추가 
                department_and_doctor.push({
                    level : 1,
                    row_type : "DEPARTMENT",
                    group_id : this.scope_group_id,
                    team_id : 0,
                    biz_id : this.scope_group_team_option.biz_id,
                    biz_type : this.scope_group_team_option.biz_type,
                    group_name : department.department_name,
                    team_name : '',
                    color : this.scope_group_team_option.group_team_color,
                    team_count : teams.length,
                    group_features : [],
                    group_info : {},
                    limit_event_attachment : 0,
                    limit_event_image_count : 0,
                    user_count : 0,
                    checked : teams.filter(item => item.checked == true).length > 0, // 하나라도 체크 된게 있다면 true
                    shareChecked : false,
                    listOn : is_list_on ? is_list_on : false,
                    shareListOn : false,
                    teams : teams,
                    department_code : department.department_code,
                    css_style : teams.filter(item => item.checked == true).length > 0 
                                ? `background-color : ${this.scope_group_team_option.group_team_color}; border-color : ${this.scope_group_team_option.group_team_color};`
                                : '',
                    role: []
                });

            }

        }

        return department_and_doctor;
    }

    /** 
     * @CalendarInfo.Action 
     */ 
    @CalendarInfo.Action doSetStartDate ?: any; // start_date 업데이트

    calendar_menu_close = true;

    personal_list_on          : boolean = false;
    group_list_on             : boolean = false;
    hodu_c_list_on            : boolean = false;
    hodu_d_list_on            : boolean = false; 
    group_and_team_list_on    : boolean = false;
    group_appointment_list_on : boolean = false;

    appointment_list_on_map : Map<string, boolean> = new Map();

    past_schedule_style : string = "";
    recomputed : boolean = false;

    // Vue가 생성 되면 가입한 그룹&팀 조회해서 필터 
    async mounted() : Promise<void> {
        await this.get_group_role_service();

        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD ) {
            await this.get_hodu_d_info(this.scope_group_team_option.biz_id);
        }  

        // 필터 체크 색상 재적용하는 함수 등록
        window["setFilterCheckColor"] = this.setFilterCheckColor;
        this.$nextTick(() => { setTimeout(async() => { await this.setFilterCheckColor(); }, 500); });
    }

    calendar_menu_click() {
        // if( this.left_control_box_flag == true ) {
        //     this.calendar_menu_close = false;
        //     this.doSetLeftControlBoxFlag(false);
        //     return;
        // }

        this.calendar_menu_close = !this.calendar_menu_close;
        this.filterToLocal();
    }

    past_schedule_click(){
        this.schedule_search_config.past_schedule = !this.schedule_search_config.past_schedule;
        this.filterToLocal();
    }

    personal_schedule_click(){
        this.schedule_search_config.my_schedule     = this.schedule_search_config.personal_schedule;
        this.schedule_search_config.shared_schedule = this.schedule_search_config.personal_schedule;
        this.filterToLocal();
    }

    my_schedule_check_click(){
        this.schedule_search_config.my_schedule = !this.schedule_search_config.my_schedule;
        this.filterToLocal();
    }

    shared_schedule_check_click(){
        this.schedule_search_config.shared_schedule = !this.schedule_search_config.shared_schedule;
        this.filterToLocal();
    }

    /**
     * 일반 그룹&팀 전부 ON/OFF
     */
    group_schedule_click(event){

        const normal_group_size : number = this.normal_group_data.length;
        for( let i = 0; i < normal_group_size; i++ ){
            
            this.normal_group_data[i].checked = event.target.checked;

            // 그룹 필터에 추가or제거
            if( !this.normal_group_data[i].checked ){
                if( this.schedule_search_config.group_filter.indexOf(Number(this.normal_group_data[i].group_id)) == -1 ){
                    this.schedule_search_config.group_filter.push(Number(this.normal_group_data[i].group_id));
                }
            } else {
                const group_filter_index : number = this.schedule_search_config.group_filter.indexOf(this.normal_group_data[i].group_id);

                if( group_filter_index != -1 ){
                    this.schedule_search_config.group_filter.splice(group_filter_index, 1);
                }
            }

            if( this.normal_group_data[i].teams != null && this.normal_group_data[i].teams.length > 0 ){
                const hodu_c_team_size : number = this.normal_group_data[i].teams.length;
                for( let j = 0; j < hodu_c_team_size; j++ ){
                    this.normal_group_data[i].teams[j].checked = this.schedule_search_config.group_schedule;

                    // 팀 필터에 추가or제거
                    if( !this.normal_group_data[i].teams[j].checked ){
                        if( this.schedule_search_config.team_filter.indexOf(Number(this.normal_group_data[i].teams[j].team_id)) == -1 ){
                            this.schedule_search_config.team_filter.push(Number(this.normal_group_data[i].teams[j].team_id));
                        }
                    } else {
                        const team_filter_index : number = this.schedule_search_config.team_filter.indexOf(this.normal_group_data[i].teams[j].team_id);

                        if( team_filter_index != -1 ){
                            this.schedule_search_config.team_filter.splice(team_filter_index, 1);
                        }
                    }
                }
            }
        }

        this.filterToLocal();
    }

    /**
     * 호두C 그룹&팀 전부 ON/OFF
     */
    hodu_c_schedule_click(event){
        
        const hodu_c_group_size : number = this.hodu_c_group_data.length;
        for( let i = 0; i < hodu_c_group_size; i++ ){
            
            this.hodu_c_group_data[i].checked = event.target.checked;

            // 그룹 필터에 추가or제거
            if( !this.hodu_c_group_data[i].checked ){
                if( this.schedule_search_config.group_filter.indexOf(Number(this.hodu_c_group_data[i].group_id)) == -1 ){
                    this.schedule_search_config.group_filter.push(Number(this.hodu_c_group_data[i].group_id));
                }
            } else {
                const group_filter_index : number = this.schedule_search_config.group_filter.indexOf(this.hodu_c_group_data[i].group_id);

                if( group_filter_index != -1 ){
                    this.schedule_search_config.group_filter.splice(group_filter_index, 1);
                }
            }

            if( this.hodu_c_group_data[i].teams != null && this.hodu_c_group_data[i].teams.length > 0 ){
                const hodu_c_team_size : number = this.hodu_c_group_data[i].teams.length;
                for( let j = 0; j < hodu_c_team_size; j++ ){
                    this.hodu_c_group_data[i].teams[j].checked = this.schedule_search_config.hodu_c_schedule;

                    // 팀 필터에 추가or제거
                    if( !this.hodu_c_group_data[i].teams[j].checked ){
                        if( this.schedule_search_config.team_filter.indexOf(Number(this.hodu_c_group_data[i].teams[j].team_id)) == -1 ){
                            this.schedule_search_config.team_filter.push(Number(this.hodu_c_group_data[i].teams[j].team_id));
                        }
                    } else {
                        const team_filter_index : number = this.schedule_search_config.team_filter.indexOf(this.hodu_c_group_data[i].teams[j].team_id);

                        if( team_filter_index != -1 ){
                            this.schedule_search_config.team_filter.splice(team_filter_index, 1);
                        }
                    }
                }
            }
        }

        this.filterToLocal();
    }

    /**
     * 호두 D 전체 ON / OFF
     */
    hodu_d_schedule_click(event) : void {
        const hodu_d_group_size : number = this.hodu_d_group_data.length;
        for( let i = 0; i < hodu_d_group_size; i++ ){
            
            this.hodu_d_group_data[i].checked = event.target.checked;
            
            for( let j = 0; j < this.hodu_d_group_data[i].teams.length; j++ ) {
                this.hodu_d_group_data[i].teams[j].checked = event.target.checked;
                const doctor_key = `${this.hodu_d_group_data[i].teams[j].biz_id}___${this.hodu_d_group_data[i].teams[j].department_code}___${this.hodu_d_group_data[i].teams[j].doctor_code}`;
                const doctor_index = this.schedule_search_config.hodu_d_filter.indexOf(doctor_key);

                // 체크 해제 상태가 되었을때 필터에 추가
                if( !this.hodu_d_group_data[i].checked ){
                    if( doctor_index < 0 ) { this.schedule_search_config.hodu_d_filter.push(doctor_key); }
                } 
                
                // 체크 상태가 되었을때 필터에서 제외
                else {
                    if( doctor_index > -1 ) { this.schedule_search_config.hodu_d_filter.splice(doctor_index, 1); }
                }
            }

        }

        this.filterToLocal();
    }

    /**
     * on -> 모든 팀 활성화, off -> 모든 팀 비활성화
     */
    @Debounce(1)
    group_filter_click(target_list : any, group_obj : any, type : string, group_index : number){
        
        if( group_index == -1 ) {
            return;
        }
    
        // 그룹 checked 변경
        group_obj.checked = !group_obj.checked;

        // 팀 전체 checked 그룹과 똑같도록 변경
        if( group_obj.teams != null && group_obj.teams.length > 0 ){
            const team_size : number = group_obj.teams.length;
            for( let i = 0; i < team_size; i++ ){
                const team_obj : any = group_obj.teams[i];
                team_obj.checked = group_obj.checked;
                group_obj.teams.splice(i, 1, team_obj);
            }
        }

        switch ( type ) {
            case "NORMAL":
                this.normal_group_data.splice(group_index, 1, group_obj);
                break;

            case "HODU_C":
                this.hodu_c_group_data.splice(group_index, 1, group_obj);
                break;

            case "HODU_D":
                this.hodu_d_group_data.splice(group_index, 1, group_obj);
                break;
        }

        // 호두닥 필터
        if( type == "HODU_D" ) {
            
            const doctor_count : number = group_obj.teams.length;
            for( let i = 0; i < doctor_count; i++ ) {
                const doctor_key = `${group_obj.teams[i].biz_id}___${group_obj.teams[i].department_code}___${group_obj.teams[i].doctor_code}`;
                const doctor_index = this.schedule_search_config.hodu_d_filter.indexOf(doctor_key);
                
                // 체크 해제 상태가 되었을때 필터에 추가
                if( !group_obj.checked ) { 
                    if( doctor_index < 0 ) { this.schedule_search_config.hodu_d_filter.push(doctor_key); }
                }

                // 체크 상태가 되었을때 필터에서 제외
                else { 
                    if( doctor_index > -1 ) { this.schedule_search_config.hodu_d_filter.splice(doctor_index, 1); }
                }
            }

            let is_unchecked_all : boolean = true;
            for( const group_data of this.hodu_d_group_data ) {
                if( group_data.checked == true ) { 
                    is_unchecked_all = false;
                    break;    
                }
            }

            this.schedule_search_config.hodu_d_schedule = !is_unchecked_all;

        }

        // 일반 필터
        else {
            // 그룹, 팀 아이디 필터에 추가or제거
            if( !group_obj.checked ){
                if( this.schedule_search_config.group_filter.indexOf(Number(group_obj.group_id)) == -1 ){
                    this.schedule_search_config.group_filter.push(Number(group_obj.group_id));
                }
            } else {
                const group_filter_index : number = this.schedule_search_config.group_filter.indexOf(group_obj.group_id);

                if( group_filter_index != -1 ){
                    this.schedule_search_config.group_filter.splice(group_filter_index, 1);
                }
            }

            // console.log("group_filter=" + JSON.stringify(this.schedule_search_config.group_filter));

            if( group_obj.teams != null && group_obj.teams.length > 0 ){
                const team_size : number = group_obj.teams.length;
                for( let i = 0; i < team_size; i++ ){
                    const team_obj : any = group_obj.teams[i];
                    
                    if( !team_obj.checked ){
                        if( this.schedule_search_config.team_filter.indexOf(Number(team_obj.team_id)) == -1 ){
                            this.schedule_search_config.team_filter.push(Number(team_obj.team_id));
                        }
                    } else {
                        const team_filter_index : number = this.schedule_search_config.team_filter.indexOf(team_obj.team_id);

                        if( team_filter_index != -1 ){
                            this.schedule_search_config.team_filter.splice(team_filter_index, 1);
                        }
                    }
                }
            }
        }

        this.filterToLocal();
    }

    /**
     * 해당 팀만 on / off
     */
    @Debounce(1)
    team_filter_click(target_list : any, group_obj : any, team_obj : any, type : string){
        const group_index : number = target_list.indexOf(group_obj);
        const team_index  : number = group_obj.teams.indexOf(team_obj);

        if( group_index == -1 || team_index == -1 ) {
            return;
        } 
    
        team_obj.checked = !team_obj.checked;

        // 호두닥 필터
        if( type == 'HODU_D' ) {
            const doctor_key = `${team_obj.biz_id}___${team_obj.department_code}___${team_obj.doctor_code}`;
            const doctor_index = this.schedule_search_config.hodu_d_filter.indexOf(doctor_key);

            // 체크 해제 상태가 되었을때 필터에 추가
            if( !team_obj.checked ) { 
                if( doctor_index < 0 ) { this.schedule_search_config.hodu_d_filter.push(doctor_key); }
            }

            // 체크 상태가 되었을때 필터에서 제외
            else { 
                if( doctor_index > -1 ) { this.schedule_search_config.hodu_d_filter.splice(doctor_index, 1); }
            }

        }

        // 일반 필터
        else {
            // 팀 아이디 필터에 추가or제거
            if( !team_obj.checked ){
                if( this.schedule_search_config.team_filter.indexOf(Number(team_obj.team_id)) == -1 ){
                    this.schedule_search_config.team_filter.push(Number(team_obj.team_id));
                }
            } else {
                const team_filter_index : number = this.schedule_search_config.team_filter.indexOf(team_obj.team_id);

                if( team_filter_index != -1 ){
                    this.schedule_search_config.team_filter.splice(team_filter_index, 1);
                }
            }
        }

        group_obj.teams.splice(team_index, 1, team_obj);

        switch ( type ) {
            case "NORMAL":
                this.normal_group_data.splice(group_index, 1, group_obj);
                break;

            case "HODU_C":
                this.hodu_c_group_data.splice(group_index, 1, group_obj);
                break;

            case "HODU_D":
                group_obj.checked = this.schedule_search_config.hodu_d_filter.filter(key => new RegExp(group_obj.biz_id).test(key) == true).length < group_obj.teams.length;
                this.hodu_d_group_data.splice(group_index, 1, group_obj);
                break;
        }

        if( type == "HODU_D" ) {
            let is_unchecked_all : boolean = true;
                for( const group_data of this.hodu_d_group_data ) {
                    if( group_data.checked == true ) { 
                        is_unchecked_all = false;
                        break;    
                    }
                }

            this.schedule_search_config.hodu_d_schedule = !is_unchecked_all;
        }

        this.filterToLocal();
    }

    /**
     * 깊이 1 그룹메뉴 ON / OFF
     */
    depth01_onOff(type : string) : void {
        switch (type) {
            case OWNER_TYPE.PERSONAL:
                this.personal_list_on = !this.personal_list_on;
                break;

            case "GROUP":
                this.group_list_on = !this.group_list_on;
                break;

            case "HODU_C":
                this.hodu_c_list_on = !this.hodu_c_list_on;
                break;

            case "HODU_D":
                this.hodu_d_list_on = !this.hodu_d_list_on;
                break;

            case "GROUP_AND_TEAM":
                this.group_and_team_list_on = !this.group_and_team_list_on;
                break;

            case "APPOINTMENT":
                this.group_appointment_list_on = !this.group_appointment_list_on;
                break;
        }
    }

    /**
     * 깊이 2 그룹메뉴 ON / OFF
     */
    depth02_onOff(teams_length : number, group_data : any, type : string, group_index : number) : void {
        
        /**
         * 하위 내용이 없으면 클릭
         */
        if( teams_length < 1 ){
            switch( type ) {
                case "GROUP":
                    this.group_filter_click(this.normal_group_data, group_data, type, group_index);
                    break;

                case "HODU_C":
                    this.group_filter_click(this.hodu_c_group_data, group_data, type, group_index);
                    break;

                case "HODU_D":
                    this.group_filter_click(this.hodu_d_group_data, group_data, type, group_index);
                    break;
            }
            
            return;
        }

        /**
         * 하위 내용이 있으면 리스트 펼치기
         */
        let data_index : number = -1;
        let is_list_on : boolean | undefined;
        switch (type) {
            case "GROUP":
                data_index = this.normal_group_data.indexOf(group_data);

                // index out 방지
                if( data_index == -1 ){
                    return;
                }
                group_data.listOn = !group_data.listOn
                this.normal_group_data.splice(data_index, 1, group_data);
                break;

            case "HODU_C":
                data_index = this.hodu_c_group_data.indexOf(group_data);

                // index out 방지
                if( data_index == -1 ){
                    return;
                }
                group_data.listOn = !group_data.listOn
                this.hodu_c_group_data.splice(data_index, 1, group_data);
                break;       
                
            case "HODU_D":
                data_index = this.hodu_d_group_data.indexOf(group_data);

                // index out 방지
                if( data_index == -1 ){
                    return;
                }
                group_data.listOn = !group_data.listOn
                this.hodu_d_group_data.splice(data_index, 1, group_data);
                break;
        }

    }

    /**
     * 그룹or팀 진입 상태에서 그룹 필터 p태그 클릭
     */
    bizGroupFilterPClick(event : any) : void {
        const biz_group_check_label : any = $(event.target).find('.biz_group_check_label');
        biz_group_check_label.trigger('click');
    }

    /**
     * 그룹or팀 진입 상태에서 그룹 필터 이름 클릭
     */
    bizGroupFilterNameClick(event : any) : void {
        const biz_group_check_label : any = $(event.target).parent().find('.biz_group_check_label');
        biz_group_check_label.trigger('click');
    }

    /**
     * 그룹or팀 진입 상태에서 그룹 필터 ON / OFF
     */
    bizGroupFilterOnOff(checked : boolean, group_id : number) : void {
        
        // 필터 정보 찾기
        let filter : GroupAndTeamFilter | null = null;
        let filter_index : number = -1;
        const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
        for( let i = 0; i < filter_length; i++ ) {
            if( this.scope_group_team_option.biz_id == this.schedule_search_config.group_and_team_filter[i].biz_id &&
                this.scope == this.schedule_search_config.group_and_team_filter[i].scope &&
                (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id ) == this.schedule_search_config.group_and_team_filter[i].scope_id ) {
                filter = JSON.parse(JSON.stringify(this.schedule_search_config.group_and_team_filter[i]));
                filter_index = i;
                break;
            }
        }

        // 필터가 존재하지 않는 경우
        if( filter == null ) {
            filter = {
                biz_id : this.scope_group_team_option.biz_id,
                scope : this.scope,
                scope_id : (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id),
                group_id : null,
                team_ids : []
            };
        }
        
        // checked가 false라면 필터에 추가!
        if( checked == false ) {
            filter.group_id = group_id;
            
            // 기존에 존재했다면 splice
            if( filter_index > -1 ) {
                this.schedule_search_config.group_and_team_filter.splice(filter_index, 1, filter);
            }

            // 기존에 없었다면 push
            else {
                this.schedule_search_config.group_and_team_filter.push(filter);
            }
        }

        // checked가 true라면 필터에서 제거!
        else {
            // 기존에 없다면 나간다
            if( filter_index == -1 ) {
                return;
            }

            // team_ids가 남아있으면 splice(index, 1, object)
            if( filter.team_ids.length > 0 ) {
                this.schedule_search_config.group_and_team_filter.splice(filter_index, 1, filter);
            }

            // team_ids도 없다면 splice(index, 1);
            else {
                this.schedule_search_config.group_and_team_filter.splice(filter_index, 1);
            }
        }

        this.filterToLocal();
    }
    
    /**
     * 그룹or팀 진입 상태에서 팀 필터 이름 클릭
     */
    bizTeamFilterNameClick(event) : void {
        const biz_team_check_label : any = $(event.target).parent().find('.biz_team_check_label');
        console.log(biz_team_check_label);
        biz_team_check_label.trigger('click');
    }

    /**
     * 그룹or팀 진입 상태에서 팀 필터 ON / OFF
     */
    bizTeamFilterOnOff(checked : boolean, team_id : number) : void {

        // 필터 정보 찾기
        let filter : GroupAndTeamFilter | null = null;
        let filter_index : number = -1;
        const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
        for( let i = 0; i < filter_length; i++ ) {
            if( this.scope_group_team_option.biz_id == this.schedule_search_config.group_and_team_filter[i].biz_id &&
                this.scope == this.schedule_search_config.group_and_team_filter[i].scope &&
                (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) == this.schedule_search_config.group_and_team_filter[i].scope_id ) {
                filter = JSON.parse(JSON.stringify(this.schedule_search_config.group_and_team_filter[i]));
                filter_index = i;
                break;
            }
        }

        // 필터가 존재하지 않는 경우
        if( filter == null ) {
            filter = {
                biz_id : this.scope_group_team_option.biz_id,
                scope : this.scope,
                scope_id : (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id),
                group_id : null,
                team_ids : []
            };
        }
        
        // checked가 false라면 필터에 추가!
        if( checked == false ) {
            // 이미 들어있는 경우는 나간다
            if( filter.team_ids.indexOf(team_id) > -1 ) {
                return;
            }

            filter.team_ids.push(team_id);

            // 기존에 존재했다면 splice
            if( filter_index > -1 ) {
                this.schedule_search_config.group_and_team_filter.splice(filter_index, 1, filter);
            }

            // 기존에 없었다면 push
            else {
                this.schedule_search_config.group_and_team_filter.push(filter);
            }
        }

        // checked가 true라면 필터에서 제거!
        else {
            // 기존에 없다면 || team_id가 들어있지 않은 경우는 나간다
            if( filter_index == -1 || filter.team_ids.indexOf(team_id) == -1) {
                return;
            }

            filter.team_ids.splice(filter.team_ids.indexOf(team_id), 1);

            // group_id, team_ids 둘중 하나라도 남아있다면 splice(index, 1, object)
            if( filter.team_ids.length > 0 || filter.group_id != null ) {
                this.schedule_search_config.group_and_team_filter.splice(filter_index, 1, filter);
            }

            // 둘중 하나라도 안남아있다면 splcie(index, 1);
            else {
                this.schedule_search_config.group_and_team_filter.splice(filter_index, 1);
            }
        }

        this.filterToLocal();
    }

    /**
     * 필터 데이터를 로컬로 이동시킨다
     */
    filterToLocal() : void {
        local_storage_info.schedule_search_config = this.schedule_search_config;
        hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info));

        // 로컬에 저장하는 과정에서 체크 컬러 다시 구하기 실행
        this.setFilterCheckColor();
    }

    /**
     * 체크 컬러를 재적용 시킨다
     */
    async setFilterCheckColor() : Promise<void> {

        const biz_check_color : string = this.scope == OWNER_TYPE.PERSONAL ? "#477FFF" : this.scope_group_team_option.group_team_color;

        // 지난 일정 - 체크 된 경우 (개인달력 or 프리미엄달력)
        if( this.schedule_search_config.past_schedule == true ) { 
            this.past_schedule_style = `background-color : ${biz_check_color}`;
        } 
    
        // 지난 일정 - 체크 안 된 경우
        else {
            this.past_schedule_style = "";
        }

        // 일반 그룹, 팀 색상 적용
        const normal_group_length : number = this.normal_group_data.length;
        for( let i = 0; i < normal_group_length; i++ ) {
            if( this.normal_group_data[i].checked == true ) {
                this.normal_group_data[i].css_style = `background-color : ${this.hodu_hex_color_process(this.normal_group_data[i].color)}`;
            }

            else {
                this.normal_group_data[i].css_style = "";
            }

            const normal_team_length : number = this.normal_group_data[i].teams.length;
            for( let j = 0; j < normal_team_length; j++ ) {
                if( this.normal_group_data[i].teams[j].checked == true ) {
                    this.normal_group_data[i].teams[j].css_style = `background-color : ${this.hodu_hex_color_process(this.normal_group_data[i].teams[j].color)}`;
                }

                else {
                    this.normal_group_data[i].teams[j].css_style = "";
                }
            }
        }

        // 비즈 그룹, 팀 색상 적용
        const hodu_c_group_length : number = this.hodu_c_group_data.length;
        for( let i = 0; i < hodu_c_group_length; i++ ) {
            if( this.hodu_c_group_data[i].checked == true ) {
                this.hodu_c_group_data[i].css_style = `background-color : ${this.hodu_hex_color_process(this.hodu_c_group_data[i].color)}`;
            }

            else {
                this.hodu_c_group_data[i].css_style = "";
            }

            const hodu_c_team_length : number = this.hodu_c_group_data[i].teams.length;
            for( let j = 0; j < hodu_c_team_length; j++ ) {
                if( this.hodu_c_group_data[i].teams[j].checked == true ) {
                    this.hodu_c_group_data[i].teams[j].css_style = `background-color : ${this.hodu_hex_color_process(this.hodu_c_group_data[i].teams[j].color)}`;
                }

                else {
                    this.hodu_c_group_data[i].teams[j].css_style = "";
                }
            }
        }

        // 그룹, 팀 달력 색상 적용
        const computed_group_length : number = this.getGroupAndTeam.length;
        for( let i = 0; i < computed_group_length; i++ ) {
            if( this.getGroupAndTeam[i].checked == true ) {
                this.getGroupAndTeam[i].css_style = `background-color : ${this.hodu_hex_color_process(this.getGroupAndTeam[i].color)}`;
            }

            else {
                this.getGroupAndTeam[i].css_style = "";
            }

            const computed_team_length : number = this.getGroupAndTeam[i].teams.length;
            for( let j = 0; j < computed_team_length; j++ ) {
                if( this.getGroupAndTeam[i].teams[j].checked == true ) {
                    this.getGroupAndTeam[i].teams[j].css_style = `background-color : ${this.hodu_hex_color_process(this.getGroupAndTeam[i].teams[j].color)}`;
                }

                else {
                    this.getGroupAndTeam[i].teams[j].css_style = "";
                }
            }
        }
    }

    /**
     * 그룹에서 예약 전체 ON / OFF
     */
    changeGroupAppointment(event) : void {
        const is_checked : boolean = event.target.checked;

        this.schedule_search_config.group_appointment_filter.splice(0, this.schedule_search_config.group_appointment_filter.length);
        
        if( is_checked == true ) {
            this.filterToLocal();
            this.recomputed = true;
            this.$nextTick(() => { this.recomputed = false; }); 
            return;
        }

        for ( const department of this.computedHoduDDepartmentAndDoctor ) {
            for( const doctor of department.teams ) {
                const key = `${doctor.biz_id}___${doctor.department_code}___${doctor.doctor_code}`;
                this.schedule_search_config.group_appointment_filter.push(key);
            }
        }

        this.filterToLocal();
    }

    /**
     * 호두 D 그룹 달력에서 병원 예약 - 진료과의 의사 리스트 ON / OFF
     */
    departmentListOnOff(department : GroupTeamInfo) : void {

        if( department.department_code == null ) { return; }

        const is_checked : boolean | undefined = this.appointment_list_on_map.get(department.department_code);
        if( is_checked == null ) {
            this.appointment_list_on_map.set(department.department_code, true);
            this.recomputed = true;
            this.$nextTick(() => { this.recomputed = false; }); 
            return;
        }

        this.appointment_list_on_map.set(department.department_code, !is_checked);
        this.recomputed = true;
        this.$nextTick(() => { this.recomputed = false; }); 
        
    }

    /**
     * 호두 D 그룹 달력에서 해당 진료과의 의사 필터 전체 ON / OFF
     */
    departmentOnOff(is_checked : boolean, department : GroupTeamInfo) : void {

        // 해당 진료과의 필터 전부 제거
        this.schedule_search_config.group_appointment_filter = this.schedule_search_config.group_appointment_filter.filter(item => new RegExp(`___${department.department_code}___`).test(item) == false);
        
        // 체크 해제라면 그대로 종료
        if( is_checked == true ) {
            this.schedule_search_config.group_appointment = true; 
            this.filterToLocal();
            return; 
        }

        // 체크라면 해당 진료과의 모두 집어넣기
        for(const doctor of department.teams) {
            const key = `${doctor.biz_id}___${doctor.department_code}___${doctor.doctor_code}`;
            this.schedule_search_config.group_appointment_filter.push(key);
        }

        let total_count = 0;
        for( const department of this.computedHoduDDepartmentAndDoctor ) {
            total_count += department.team_count
        }

        // 필터 여부
        // alert(`[${total_count}, ${this.schedule_search_config.group_appointment_filter.length}]`);
        this.schedule_search_config.group_appointment = (total_count != this.schedule_search_config.group_appointment_filter.length);

        this.filterToLocal();
    }

    /**
     * 호두 D 그룹 달력에서 의사 필터 ON / OFF
     */
    doctorOnOff(is_checked : boolean, doctor : GroupTeamInfo) : void {

        const key = `${doctor.biz_id}___${doctor.department_code}___${doctor.doctor_code}`;
        const index : number = this.schedule_search_config.group_appointment_filter.indexOf(key);

        if( is_checked == false ) {
            if( index < 0 ) { this.schedule_search_config.group_appointment_filter.push(key); }
        }

        else {
            if( index > -1 ) { this.schedule_search_config.group_appointment_filter.splice(index, 1); }
        }

        let total_count = 0;
        for( const department of this.computedHoduDDepartmentAndDoctor ) {
            total_count += department.team_count
        }

        // 필터 여부
        // alert(`[${total_count}, ${this.schedule_search_config.group_appointment_filter.length}]`);
        this.schedule_search_config.group_appointment = (total_count != this.schedule_search_config.group_appointment_filter.length);

        this.filterToLocal();
    }

    @Watch('scope')
    watchScope() : void {
        if( this.scope == OWNER_TYPE.TEAM ) { this.group_and_team_list_on = true; }
        else { this.group_and_team_list_on = false; }
        
        // HODU D 로 진입할때 기존에 열어놨던 병원 예약 필터 닫기
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD ) { 
            this.group_appointment_list_on = false;
            this.appointment_list_on_map.clear();
        }

        // PERSONAL은 필터를 열어놓은채로, 그룹&팀은 닫아놓은채로
        if( this.scope == OWNER_TYPE.PERSONAL ) { this.calendar_menu_close = true; }
        else { this.calendar_menu_close = true; }
    }

    async moveCalendar() : Promise<void> {
        await this.doSetStartDate(new Date()); 

        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date());
        
        this.hodu_router_push(`/?${new Date().getTime()}`);
        this.calendarGotoDateCss(this.hodu_date_to_format_string(new Date(), 'YYYY-MM-DD'));
    }

    /**
     * 캘린더 변경 CSS 
     */
    calendarGotoDateCss(date : string) : void {
        
        this.$nextTick(() => {
            $('#calendar').find('.fc-day-number').removeClass('on_orange');
            $('#calendar').find('.fc-day-number').removeClass('on_select');
            
            if(this.hodu_date_to_format_string(new Date(), 'YYYY-MM-DD') == date) {
                $('#calendar').find(`[data-date="${date}"]`).find('.fc-day-number').addClass('on_orange');
            }else {
                $('#calendar').find(`[data-date="${date}"]`).find('.fc-day-number').addClass('on_select');
            }
        });
        
    }

}

</script>

<style scoped>
    .alldate_box a#addGrp {position: absolute;background: #fff url('../../assets/images/contents/ic_add.png') no-repeat center center !important; top: 7px;right: 15px;width: 30px;transition: 0.2s;font-size: 0;background-size: 26px;height: 30px;}
    .alldate_box a#addGrp {background: #fff url('../../assets/images/contents/month_bt_next.svg') no-repeat center center !important; background-size:21px !important; }
    .alldate_box a#addGrp:hover {background: #f1f3f5 url('../../assets/images/contents/month_bt_next_on.svg') no-repeat center center !important; background-size:21px !important; }
    .leftOn .alldate_box a#addGrp { display: none; }
    #wrap.leftOn #left_area:hover .alldate_box a#addGrp { display: block; }
</style>