<template>
    <div class="modal vistorDetModal">
<!--------   ylw 등록요청 / gray 완료 / blue 추가완료 / red 거절 (클래스 or h1 문구 변경) -->
        <div class="titlebox" :class="{
            ylw : request_car != null && request_car.car_info.state == 'REQUEST',
            gray : request_car != null && request_car.car_info.state == 'APPROVE' && request_car.car_info.is_contract == false,
            blue : request_car != null && request_car.car_info.state == 'APPROVE' && request_car.car_info.is_contract == true,
            red : request_car != null && request_car.car_info.state == 'REJECT'
        }">
            <h1>{{ getTitle() }}</h1>
        </div>
        <div class="modal_content">
            <div class="modal_detail">
                <div class="Moleft_con">
<!------------------    car_img 기본은 css에 입력해둠 입주자가 이미지 올릴시 userC_img 추가 -->
<!------------------    이미지 클릭시 원본 새창으로 띄워주기 -->
                    <p class="car_img" v-if="request_car != null && (request_car.car_info.images == null || request_car.car_info.images.length < 1)">차량등록증사진</p>
                    
                    <div class="car_img" v-else>
                        <Hooper class="imageSlideContainer" ref="hooperSlide" @slide="updateViewIndex" :initialSlide="view_index" v-if="request_car != null">
                            <Slide class="imageSlide" :key="`${image.name}-${index}`" v-for="(image, index) in request_car.car_info.images">
                                <p :id="`slideImage_${index}`" class="hooperImage" :style="{ backgroundImage : `url(app_images/${image.url})` }"  @click.prevent="showImageBigger(image)"/>
                            </Slide>
                        </Hooper>
                        <div class="dot_container" v-if="request_car != null && request_car.car_info.images.length > 1">
                            <a href="#" class="dot" :class="{ on : index == view_index }" :key="`${image.name}-${index}`" v-for="(image, index) in request_car.car_info.images" @click.prevent="slideTo(index)"></a>
                        </div>
                    </div>

                    <strong class="carI_title">차량등록증</strong>
                    <p class="img_ex">*클릭시 원본 이미지를 볼 수 있습니다.</p>
                </div>
                <div class="moright_text">
                    <ul>
                        <li>
                            <p class="title">신청 날짜</p>
                            <p class="descript">{{ request_car != null ? `${hodu_date_to_format_string(new Date(request_car.start_date), 'YYYY.MM.DD')} ${getDayOfWeekByDate(new Date(request_car.start_date))}` : '-' }}</p>
                        </li>
                        <li>
                            <p class="title">동&amp;호수</p>
                            <p class="descript">{{ `${home_resident_car_request_modal_info.resident.resident_info.building}동 ${home_resident_car_request_modal_info.resident.resident_info.unit} 호` }}</p>
                        </li>
                        <li>
                            <p class="title">연락처</p>
                            <p class="descript">{{ home_resident_car_request_modal_info.resident.resident_info.phone && home_resident_car_request_modal_info.resident.resident_info.phone.length > 0 ? home_resident_car_request_modal_info.resident.resident_info.phone : '-'  }}</p>
                                                    
                        </li>
                        <li>
                            <p class="title">차량 번호</p>
                            <p class="descript">{{ request_car ? request_car.car_info.car_number : '-' }}</p>
                        </li>
                        <li>
<!--------------------------    기본 요청시 reject_text / 거절시 reject_reason (추가) / 완료시 complete_text (추가) -->
                            <div :class="{
                                reject_text : request_car != null && request_car.car_info.state == 'REQUEST',
                                reject_reason : request_car != null && request_car.car_info.state == 'REJECT',
                                complete_text : request_car != null && request_car.car_info.state == 'APPROVE',
                            }">
                                <p id="complete_text" v-if="request_car != null && request_car.car_info.state == 'APPROVE'">차량등록이 완료되었습니다.</p>
                                <p id="reject_reason" v-if="request_car != null && request_car.car_info.state == 'REJECT'">{{ request_car != null ? request_car.car_info.comment :  '-' }}</p>
                                <input type="text" id="reject_text" name="reject_text" placeholder="거절 시 사유를 작성하세요" class="re_reason" :value="comment" @input="comment = $event.target.value" v-if="request_car != null && request_car.car_info.state == 'REQUEST'">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
<!---------  거절/완료 일때 btn_reject 클래스 추가 닫기버튼만 나오게 -->
        <div class="btns" :class="{ b3 : request_car != null && request_car.car_info.state == 'REQUEST' }">
            <input type="button" value="닫기" class="close" @click.prevent="closeModal">
            <input type="button" value="거절" class="reject" @click.prevent="reject" v-if="request_car != null && request_car.car_info.state == 'REQUEST'">
            <input type="button" value="승인" class="complete" @click.prevent="approve" v-if="request_car != null && request_car.car_info.state == 'REQUEST'">
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { Hooper, Slide } from 'hooper';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        Hooper, Slide
    },
})
export default class HomeResidentCarRequestModal extends Mixins(VueHoduCommon) {

    // this.$refs.hooperSlide 사용
    $refs !: { hooperSlide : HTMLFormElement; }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_resident_car_request_modal_info !: hodu_home_modal_info.HomeResidentCarRequestModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeResidentCarRequestModalInfo ?: (params : hodu_home_modal_info.HomeResidentCarRequestModalInfo) => void;

    request_car : any = null;
    comment : string = "";

    view_index : number = 0;
    image_none = require('@/assets/images/contents/im_photoB.gif');

    mounted() : void {
        this.request_car = this.home_resident_car_request_modal_info.car;
    }

    getTitle() : string {
        if( this.request_car == null ) return '';

        if( this.request_car != null && this.request_car.car_info.state == 'REQUEST' ) {
            return `등록요청${ this.request_car.car_info.is_contract == true ? ' (추가차량)' : ''}`;
        }

        if( this.request_car != null && this.request_car.car_info.state == 'APPROVE' && this.request_car.car_info.is_contract == false ) {
            return '등록완료';
        }

        if( this.request_car != null && this.request_car.car_info.state == 'APPROVE' && this.request_car.car_info.is_contract == true ) {
            return '등록완료';
        }
        
        if( this.request_car != null && this.request_car.car_info.state == 'REJECT' ) {
            return '등록거절';
        }

        return '';
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        this.doSetHomeResidentCarRequestModalInfo?.({ show_modal : false });
    }

    /**
     * 거절
     */
    async reject() : Promise<void> {

        if( this.request_car == null ) return;

        const copy_comment = this.comment.trim();

        // 거절사유 필수
        if( copy_comment.length < 1 ) {
            alert("거절사유를 작성해주세요");
            $('#reject_text').focus();
            return;
        }

        try {
            const request_car = JSON.parse(JSON.stringify(this.request_car));
            request_car.images = request_car.car_info.images;
            request_car.car_info.state = 'REJECT'; 
            request_car.car_info.is_apply = false;
            request_car.car_info.comment = copy_comment;

            const response = await this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${this.request_car.car_id}`, API_METHOD.PUT, request_car);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("입주자 차량 거절 처리 중 오류 발생");
            }

            this.home_resident_car_request_modal_info.callback?.();
            this.closeModal();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 승인
     */
    async approve() : Promise<void> {

        if( this.request_car == null ) return;

        try {

            const request_car = JSON.parse(JSON.stringify(this.request_car));
            request_car.images = request_car.car_info.images;
            request_car.car_info.state = 'APPROVE';
            request_car.car_info.is_apply = true;
            
            const response = await this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${this.request_car.car_id}`, API_METHOD.PUT, request_car);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("입주자 차량 승인 처리 중 오류 발생");
            }

            this.home_resident_car_request_modal_info.callback?.();
            this.closeModal();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 이미지 크게 보기
     */
    showImageBigger(image) : void {
        if( this.$refs.hooperSlide.isSliding == true ) return;
        window.open(`app_images/${image.url}`, '_blank');
    }

    /**
     * 현재 보고있는 인덱스로 세팅
     */
    updateViewIndex(payload : any) : void {
        this.view_index = payload.currentSlide;
    }

    /**
     * 해당 인덱스로 이동
     */
    slideTo(index : number) : void {
        this.view_index = index;
        this.$refs.hooperSlide.slideTo(index);
    }

    /**
     * 거절
     */
    // rejectReuqest() : void {
    //     if( this.request_car == null ) { return; }

    //     this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${this.request_car.car_id}`, API_METHOD.DELETE)
    //         .then((response) => {
    //             console.log(response);
    //             window['residentSearch']();
    //             this.closeModal();
    //         })
    //         .catch((e) => {
    //             this.hodu_error_process(e, false, false);
    //         });
    // }

    /**
     * 승인
     */
    // applyRequest() : void {
    //     if( this.request_car == null ) { return; }

    //     this.request_car.car_info.is_apply = true;

    //     this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${this.request_car.car_id}`, API_METHOD.PUT, this.request_car)
    //         .then((response) => {
    //             console.log(response);
    //             window['residentSearch']();
    //             this.closeModal();
    //         })
    //         .catch((e) => {
    //             this.hodu_error_process(e, false, false);
    //         });
    // }

}
</script>

<style scoped>
    .modal { width: 750px; height: 430px; border-radius: 5px; position: absolute; left: 50%; top: 50%; margin-top: -215px; margin-left: -375px; background: #fff; z-index: 100000000!important; }
    .modal .titlebox { border-radius: 5px 5px 0 0; overflow: hidden; padding: 0; }
    .modal .titlebox h1 { font-size: 20px; height: 80px; line-height: 90px; font-weight: 700; text-align: center; width: 100%; background: #f1f3f5; color: #7c88a2; }

    .modal .titlebox.ylw h1 { background: #ffc72f; color: #202a39; }
    .modal .titlebox.gray h1 { background: #35405a; color: #fff; }
    .modal .titlebox.blue h1 { background: #477fff; color: #fff; }
    .modal .titlebox.red h1 { background: #ff6363; color: #fff; }

    .modal .btns { overflow: hidden; border-radius: 0 0 5px 5px; }
    .modal .btns input { -webkit-transition: .2s; transition: .2s; width: 100%; box-sizing: border-box; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: 700; background: #fff; }
    .modal .btns.b3 input {  width: 33.333%; }
    .modal .btn_reject input { width: 100%; box-sizing: border-box; float: left; height: 70px; line-height: 70px; font-size: 14px; text-align: center; font-weight: 700; background: #fff; }
    .modal .btn_reject input.reject, .modal .btn_reject input.complete { display: none; }
    .modal .btns input:hover, .modal .btn_reject input:hover { background: #f1f3f5; }

    .modal_content { overflow: hidden; height: 279px; border-bottom: 1px solid #e7e9ea; }
    .modal_content .Moleft_con { float: left; width: 40%; background: #f1f3f5; text-align: center; height: 324px; -webkit-box-sizing: border-box; box-sizing: border-box; padding: 0 40px; }
    .modal_content .Moleft_con .car_img { width: 130px; height: 175px; border-radius: 5px; margin: 0 auto; margin-top: 19px; background: url('../../../assets/images/contents/hoduhome_car_camera.png') no-repeat center center; background-size: cover; -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 10%); box-shadow: 0 5px 10px rgb(0 0 0 / 10%); border: 3px solid #e7e9ea; font-size: 0; cursor: pointer; }
    .modal_content .Moleft_con .car_img.userC_img { background-image: url('../../../assets/images/contents/imgDc5.png'); }
    .modal_content .Moleft_con div.car_img { background : none; position: relative; }
    .modal_content .Moleft_con .user_img { display: none; }
    .modal_content .Moleft_con .carI_title { margin: 15px 0; display: block; }
    .modal_content .Moleft_con .img_ex { color: #989bab; }

    .modal .modal_content ul li { padding: 0 30px; overflow: hidden; line-height: 55px; height: 55px; border-bottom: 1px solid #f1f3f5; }
    .modal .modal_content ul li p { display: inline-block; font-size: 13px; }
    .modal .modal_content ul li p.descript { float: right; text-align: right; font-weight: 700; }
    .modal .modal_content ul li .reject_text { background: #f1f3f5; border-radius: 5px; height: 40px; line-height: 40px; padding-left: 10px; box-sizing: border-box; margin-top: 7px; }
    .modal .modal_content ul li .reject_text p { width: 100%; background: none; font-weight: bold; display: none; }
    .modal .modal_content ul li .reject_text input#reject_text { width: 100%; background: none; font-weight: bold; opacity: .5; }

    .modal .modal_content ul li .complete_text p#complete_text { display: block; }
    .modal .modal_content ul li .complete_text input#reject_text { display: none; }
    .modal .modal_content ul li .reject_reason p#reject_reason { display: block; }
    .modal .modal_content ul li .reject_reason input#reject_text { display: none; }

    .vistorDetModal .hooper { width : 100%; height : 100%; }
    .vistorDetModal .hooper .imageSlide { padding : 0; width : 100%; height: 100%; }
    .vistorDetModal .hooperImage { width : 100%; height : 100%; background-size: cover; background-position: center; }

    .vistorDetModal .dot_container { position : absolute; width : 100%; height : 25px; bottom : 0; left : 0; cursor : default; background-color : rgba(0, 0, 0, 0.5); }
    .vistorDetModal .dot_container .dot { display : inline-block; width : 10px; height : 10px; border : 1px solid #000; border-radius: 50%; background-color : #f1f3f5; box-sizing: border-box; margin-top : 7.5px; margin-right: 3px; }
    .vistorDetModal .dot_container .dot.on { border-color : #fff; background-color: #477fff; }
</style>