<template>
    <ul id="announcement_expected_list" class="annExpected">
        <li>
            <ul class="sortHeader workHeader">
                <li class="num"><span>No</span></li>
                <li class="apt1"><span>제목</span></li>
                <li class="annWriter"><span>작성자</span></li>
                <li class="annTime"><span>방송일시</span></li>
                <li class="annFile"><span>파일</span></li>
            </ul>

            <div id="aptAnn_list_scroll">

                <div class="noResult" v-if="computedExpectedList.length < 1">
                    <p>검색된 결과가 없습니다.</p>
                </div>

                <ul class="aptAnnUl annExampleUl">

                    <li :key="announcement.noti_uid" v-for="(announcement, index) in computedExpectedList">
                        <p class="num">{{ index + 1 }}</p>
                        <p class="apt1">{{ announcement.noti_info.title }}</p>
                        <p class="annWriter">{{ announcement.audit_user_name }}</p>
                        <p class="annTime">{{ hodu_date_to_format_string(announcement.noti_info.tts.start_time, 'YY.MM.DD') }} ({{ getDayOfWeekByDate(announcement.noti_info.tts.start_time) }}) {{ amPmStringToLocaleAmPmString(hodu_date_to_format_string(announcement.noti_info.tts.start_time, 'hh:mm a')) }} <span class="remainingT">({{ getRemainTime(announcement.noti_info.tts.start_time) }}{{ getRemainTime(announcement.noti_info.tts.start_time) == '시간만료' ? '' : ' 남음' }})</span></p>
                        <p class="annFile_icon annExample"><span class="annExample_icon" title="미리듣기" @click="setPreTTS(announcement.noti_uid)">미리듣기 icon</span></p>
                        <p class="annFile_icon annPlay"><span class="annPlay_icon" title="방송시작" @click="play(announcement)">방송시작 icon</span></p>
                        <a title="자세히 보기" class="annDets" @click="moveDetail(announcement.noti_uid)">자세히 보기</a>
                    </li>

                    <!-- <li>
                        <p class="num">1</p>
                        <p class="apt1">승강기 점검 안내</p>
                        <p class="annWriter">김관리</p>
                        annTime 안에 span.remainingT 남은시간 / span.completeT 완료
                        <p class="annTime">21.05.16 (일) 오전 11:00 <span class="remainingT">(50시간 남음)</span></p>
                        <p class="annFile_icon annExample"><span class="annExample_icon" title="미리듣기">미리듣기 icon</span></p>
                        <p class="annFile_icon annPlay"><span class="annPlay_icon" title="방송시작">방송시작 icon</span></p>
                        <a title="자세히 보기" class="annDets" @click="moveDetail">자세히 보기</a>
                    </li>
                    <li>
                        <p class="num">2</p>
                        <p class="apt1">층간 소음 및 야간 소음 안내</p>
                        <p class="annWriter">이정직</p>
                        annTime 안에 span.remainingT 남은시간 / span.completeT 완료
                        <p class="annTime">21.05.14 (금) 오후 08:00 <span class="remainingT">(10시간 남음)</span></p>
                        <p class="annFile_icon annExample"><span class="annExample_icon" title="미리듣기">미리듣기 icon</span></p>
                        <p class="annFile_icon annPlay"><span class="annPlay_icon" title="방송시작">방송시작 icon</span></p>
                        <a title="자세히 보기" class="annDets" @click="moveDetail">자세히 보기</a>
                    </li>
                    <li>
                        <p class="num">3</p>
                        <p class="apt1">봄 맞이 아파트 복도 물청소 안내</p>
                        <p class="annWriter">김관리</p>
                        annTime 안에 span.remainingT 남은시간 / span.completeT 완료
                        <p class="annTime">21.05.14 (금) 오전 11:00 <span class="remainingT">(2시간 남음)</span></p>
                        <p class="annFile_icon annExample"><span class="annExample_icon" title="미리듣기">미리듣기 icon</span></p>
                        <p class="annFile_icon annPlay"><span class="annPlay_icon" title="방송시작">방송시작 icon</span></p>
                        <a title="자세히 보기" class="annDets" @click="moveDetail">자세히 보기</a>
                    </li> -->
                </ul>
            </div>
        </li>
    </ul>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class AnnouncementExpectedList extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;
    @Prop() search_query !: string;
    
    /**
     * 방송 예정 리스트
     */
    get computedExpectedList() : any[] {

        let list : any[] = JSON.parse(JSON.stringify(this.announcements));

        list = list.filter(item => item.noti_info && item.noti_info.tts && item.noti_info.tts.complete_yn == 'N');

        if( this.search_query.trim().length > 0 ) {
            list = list.filter(item => this.hodu_string_includes(item.noti_info.title, this.search_query) || 
                                       this.hodu_string_includes(item.noti_data.body, this.search_query));
        }
        
        list.sort((o1, o2) : number => {

            const o1_start_time = new Date(o1.noti_info.tts.start_time).getTime();
            const o2_start_time = new Date(o2.noti_info.tts.start_time).getTime();

            if( o1_start_time < o2_start_time ) return 1;
            else if( o1_start_time > o2_start_time ) return -1;

            return 0;
        });

        return list;
    }

    announcements : any[] = [];
    audio = new Audio();

    update_interval : number | undefined;

    target_noti_uid = "";
    goal_repeat_count = 0;
    current_repeat_count = 0;

    mounted() : void {
        this.event_bus?.$on('expected_scroll', this.handleResize);
        this.getTTSList();

        this.setScroll();

        // 5분 단위인지 체크후 UI 강제 업데이트
        this.update_interval = window.setInterval(() => {
            
            const target_date = new Date();
            
            if( target_date.getMinutes() % 5 == 0 && target_date.getSeconds() == 0 ) {
                this.$forceUpdate();
            } 
            
        }, 1000);
    }

    beforeDestroy() : void {
        if( this.update_interval ) { clearInterval(this.update_interval); }

        this.target_noti_uid = "";
        this.goal_repeat_count = 0;
        this.current_repeat_count = 0;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {

            const titleHeight  : number | undefined = $('#announcement_list .title_box').outerHeight();
            const title2Height : number | undefined = $('#announcement_list .title_div').outerHeight();
            const sortHeaderHeight : number | undefined = $('#announcement_list .sortHeader').outerHeight();

            // @ts-ignore
            $('#aptAnn_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                            - (title2Height ? title2Height : 0)
                                            - (sortHeaderHeight ? sortHeaderHeight : 0)
            });

        });
    }

    /**
     * 안내방송 리스트 조회
     */
    async getTTSList() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}?page=0&size=0`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list ) {
                throw new Error("안내방송 조회 중 오류 발생");
            }

            this.announcements.splice(0, this.announcements.length);
            this.announcements = this.announcements.concat(response.data.data.list);

        } catch(e) {
            this.hodu_show_dialog("cancel", "안내방송 조회 중 오류 발생", ["확인"]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 미리 듣기
     */
    async setPreTTS(noti_uid) : Promise<void> {
        this.target_noti_uid = "";
        this.goal_repeat_count = 0;
        this.current_repeat_count = 0;

        this.audio.pause();
        this.audio.src = `app_images/tts/${noti_uid}.mp3`;
        this.audio.play();
    }

    /**
     * 방송
     */
    async play(announcement) : Promise<void> {
        try {

            this.audio.pause();

            // 반복 횟수만큼 재생
            this.target_noti_uid = announcement.noti_uid;
            this.goal_repeat_count = announcement.noti_info.tts.repeat_count;
            this.current_repeat_count = 0;

            this.current_repeat_count++;
            this.audio.src = `app_images/tts/${announcement.noti_uid}.mp3`;
            this.audio.onended = () => {
                if( this.target_noti_uid != announcement.noti_uid || this.goal_repeat_count <= this.current_repeat_count ) {
                    return;
                }

                if( this.goal_repeat_count != this.current_repeat_count ) {
                    this.current_repeat_count++;
                    setTimeout(() => { this.audio.play(); }, 1500); 
                }
            }
            this.audio.play();

            // 방송 완료로 변경
            announcement.noti_info.tts.complete_yn = 'Y';
            const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}?noti_uid=${announcement.noti_uid}&mode=status`, API_METHOD.PUT, {
                "notification" : announcement
            });

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("안내방송 방송 완료로 수정 중 오류 발생");
            }

            await this.getTTSList();

        } catch(e) {
            this.hodu_show_dialog('cancel', '방송 시작 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 남은 시간 반환
     */
    getRemainTime(time : Date | string) : string {

        time = time instanceof Date ? time : new Date(time);

        const expected_time = time.getTime();
        const current_time = new Date().getTime();

        const offset = expected_time - current_time;

        if( offset < 1 ) {
            return "시간만료";
        }

        const offset_sec = offset / 1000;

        // 일 단위 필요하면 사용
        // const offset_day = offset_sec / 24 / 3600;
        
        const offset_hour = offset_sec / 3600;
        const offset_min = offset_sec / 60;

        // 일 단위 필요하면 사용
        // if( offset_day >= 1 ) {
        //     return `${offset_day.toFixed(0)}일`;
        // }

        if( offset_hour >= 1 ) {
            return `${offset_hour.toFixed(0)}시간`;
        }

        if( offset_min >= 1 ) {
            return `${offset_min.toFixed(0)}분`;
        }

        return `${offset_sec.toFixed(0)}초`;
    }

    /**
     * 안내방송 상세 페이지 이동
     */
    moveDetail(uid) : void {
        this.hodu_router_push(`/home/announcement/${uid}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#aptAnn_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }
}
</script>

<style scoped>
    #announcement_expected_list .noResult { position: unset; margin-top : 20px; }
    #announcement_expected_list .noResult p { background-color: #f1f3f5; }
</style>