<template>
    <!-- 공유 모달과 탭부분을 제외한 동일한 구조 -->
    <div class="shareStatusModal modal">
        
        <!-- 상단 영역 시작 -->
        <div class="titleBox">
            
            <!-- 멤버 클릭시 하단 타이틀 변경 > 공유된 멤버 -->
            <h1 class="borderbot">공유된 리스트</h1>
            <!-- <p class="total">그룹/팀 <span>{{ subscribe_groups.length + subscribe_teams.length }}</span></p>
            <p class="total user">사용자 <span>{{ subscribe_users.length }}</span></p>  -->
            
        </div>
        <!-- 상단 영역 끝 -->
        <!-- 탭 영역 -->
        <div class="tabs">
            <!-- <input type="button" class="userTab" value="사용자"    @click="shareTabChange('PERSONAL')"       :class="{ on : view_scope == 'PERSONAL' }"/>
            <input type="button" class="grpTab"  value="그룹 / 팀" @click="shareTabChange('GROUP_AND_TEAM')" :class="{ on : view_scope == 'GROUP_AND_TEAM' || view_scope == 'GROUP_AND_TEAM_USERS' }"/> -->
            <a href="#" class="userTab" @click.prevent="shareTabChange('PERSONAL')"       :class="{ on : view_scope == 'PERSONAL' }">
                공유자
                <span>{{ subscribe_users.length }}</span>
            </a>
            <a href="#" class="grpTab" @click.prevent="shareTabChange('GROUP_AND_TEAM')" :class="{ on : view_scope == 'GROUP_AND_TEAM' || view_scope == 'GROUP_AND_TEAM_USERS' }">
                그룹 / 팀
                <span>{{ subscribe_groups.length + subscribe_teams.length }}</span>
            </a>
        </div>
        <div class="modal_content">

            <!-- 그룹 헤더 -->
            <!-- <ul class="sortHeader grpHeader">
				<li class="num">No</li>
				<li class="nm headerSort sortOff"><span>그룹</span></li>
                <li class="count headerSort sortUp"><span>멤버</span></li>
			</ul> -->

            <!-- 멤버 헤더 -->
            <!-- <ul class="sortHeader teamHeader">
                <li class="num">No</li>
                <li class="pNm headerSort sortOff"><span>이름</span></li>
                <li class="pos headerSort"><span>직급</span></li>
            </ul> -->

            <!-- 공유 내용 영역 시작 -->
            <div class="shareUlDiv">
                <div class="noResult" :class="{ on : computedShareGroupAndTeamList.length < 1 }">수신한 그룹/팀이 없습니다</div>
                <ul class="grpUl">
                    
                    <li :key="index" v-for="(share_obj, index) of computedShareGroupAndTeamList">
                        <div class="grp">
                            <p class="num">{{ index + 1 }}</p>
                            <p class="img" :style="{ backgroundImage : `url(${getGroupAndTeamImageUrl(share_obj)})`}">그룹 사진</p>
                            <img class="fPicDummy" :src="getGroupAndTeamImageUrl(share_obj)" @error="groupAndTeamImageError($event, share_obj)" />
                            <h2 class="nm">{{ share_obj.share_scope_name }}</h2>
                            <p class="cl dc0" :style="{ backgroundColor : `${ hodu_hex_color_process(share_obj.scope_color) } !important` }">그룹 색</p>
                            <p class="count">
                                <a herf="#" @click.prevent="shareTabChange('GROUP_AND_TEAM_USERS', share_obj.share_scope, share_obj.share_scope_id)">
                                    <span>{{ share_obj.users.length }}</span> 
                                </a>
                            </p>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
        <!-- 공유 내용 영역 끝 -->

        <!-- 멤버 -->
        <div class="peepsDiv" v-if="view_scope == 'PERSONAL' || view_scope == 'GROUP_AND_TEAM_USERS'" 
             :class="{ on : view_scope == 'PERSONAL' || view_scope == 'GROUP_AND_TEAM_USERS' }">
            <div class="peepsUlDiv">
                <div class="noResult" :class="{ on : computedShareUserList.length < 1 }">수신한 공유자가 없습니다</div>
                <ul class="peeps">
                    
                    <li :key="index" v-for="(share_user_obj, index) in computedShareUserList">
                        <p class="num">{{ index + 1 }}</p>
                        <p class="fPic" :style="{ backgroundImage : `url(/app_images/profile/user/${Math.floor((share_user_obj.user_id != null ? share_user_obj.user_id : 0) / 10000)}/${share_user_obj.user_id != null ? share_user_obj.user_id : 0}.jpg)` }">멤버 사진</p>
                        <img class="fPicDummy" :src="`/app_images/profile/user/${Math.floor((share_user_obj.user_id != null ? share_user_obj.user_id : 0) / 10000)}/${share_user_obj.user_id != null ? share_user_obj.user_id : 0}.jpg`"/>
                        <p class="pNm">{{ share_user_obj.user_name }}</p>

                        <!-- 그룹 / 팀에서 눌렀을때만 나옴 -->
                        <p class="pos" v-if="view_scope == 'GROUP_AND_TEAM_USERS'" :class="getRole(share_user_obj)['role']">{{ getRole(share_user_obj)['role_text'] }}</p>
                    </li>
                </ul>
            </div>

        </div>
        <!-- 멤버 끝 -->
        <div class="btns b1">
            <input type="button" class="input_btn" value="닫기" @click="closeButtonClick">
        </div>
        
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { EventShareStatusInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {

    }
}) export default class EventShareStatus extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State event_share_status_info !: EventShareStatusInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventShareStatusInfo ?: any;

    get computedShareGroupAndTeamList() : any[] {
        let target : any[] = [];

        if( this.view_scope == 'GROUP_AND_TEAM' ) {
            target = target.concat(this.share_list.filter(share_obj => share_obj.share_scope != 'PERSONAL'));
        }

        return target;
    }

    get computedShareUserList() : any[] {
        let target : any[] = [];

        if( this.view_scope == 'PERSONAL' ) {
            for( let share_obj of this.share_list.filter(share_obj => share_obj.share_scope == 'PERSONAL') ) {
                target = target.concat(share_obj.users);
            }
        }

        else if ( this.view_scope == 'GROUP_AND_TEAM_USERS' ) {
            const scope_share_obj : any[] = this.share_list.filter(share_obj => share_obj.share_scope    == this.view_share_scope && 
                                                                                share_obj.share_scope_id == this.view_share_scope_id );
            for( let share_obj of scope_share_obj ) {
                target = target.concat(share_obj.users);
            }
        }

        return target;
    }

    share_list       : any[]    = [];
    subscribe_users  : number[] = [];
    subscribe_groups : number[] = [];
    subscribe_teams  : number[] = [];

    view_scope          : string = 'PERSONAL'; // PERSONAL & GROUP_AND_TEAM & GROUP_AND_TEAM_USERS
    view_share_scope    : string = '';
    view_share_scope_id : number = 0;

    mounted() : void { 

        // .count 클릭 시 
        $(".count a").click(function(){
            $(".peepsDiv").addClass("on");

        });

        this.getShareList();
    }

    /**
     * 공유 목록 불러오기
     */
    getShareList() : void {
        this.hodu_api_call(`api/v1/calendars/${this.event_share_status_info.calendar_id}/events/${this.event_share_status_info.scope}/${this.event_share_status_info.scope_id}/${this.event_share_status_info.event_id}/share?is_web=true`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.share_list.splice(0, this.share_list.length);

                // 조회 결과가 없는 경우 return
                if( response.data.data.share_list == null ) {
                    return;
                }
 
                this.share_list       = this.share_list.concat(response.data.data.share_list.share_list);
                this.subscribe_users  = response.data.data.share_list.subscribe_users;
                this.subscribe_groups = response.data.data.share_list.subscribe_groups;
                this.subscribe_teams  = response.data.data.share_list.subscribe_teams;
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            })
    }

    /**
     * 수신자 탭 변경
     */
    shareTabChange(tab : string, share_scope ?: string, share_scope_id ?: number) : void {
        this.view_scope = tab;
        
        if( share_scope    ) { this.view_share_scope    = share_scope;    }
        if( share_scope_id ) { this.view_share_scope_id = share_scope_id; }
    }

    /**
     * 그룹 / 팀 role에 따른 오브젝트 반환
     */
    getRole(share_user_obj : any) : Object {
        if( !share_user_obj ) {
            return { role : '', role_text : '' };
        }

        // group_role 체크
        if( share_user_obj.group_role && share_user_obj.group_role[0] != null ) {
            
            if( share_user_obj.group_role[0] == 'ADMIN' ) {
                return { role : 'king', role_text : '최종관리자' };
            }
            
            else if( share_user_obj.group_role[0] == 'GROUP_MANAGER' ) {
                return { role : 'king', role_text : '관리자' };
            }

            else if( share_user_obj.group_role[0] == 'GROUP_SUB_MANAGER' ) {
                return { role : 'manager', role_text : '부관리자' };
            }

            else if( share_user_obj.group_role[0] == 'GROUP_USER' ) {
                return { role : 'mem', role_text : '멤버' };
            }
        }

        // team_role 체크
        if( share_user_obj.team_role && share_user_obj.team_role[0] != null ) {
            
            if( share_user_obj.team_role[0] == 'ADMIN' ) {
                return { role : 'king', role_text : '최종관리자' };
            }
            
            else if( share_user_obj.team_role[0] == 'TEAM_MANAGER' ) {
                return { role : 'king', role_text : '팀장' };
            }

            else if( share_user_obj.team_role[0] == 'TEAM_USER' ) {
                return { role : 'mem', role_text : '팀원' };
            }
        }

        return '';
    }

    /**
     * 그룹, 팀 이미지 URL 반환
     */
    getGroupAndTeamImageUrl(share_obj : any) : string {
        if( !share_obj ) {
            return '';
        }

        // 그룹
        if( share_obj.share_scope == OWNER_TYPE.GROUP ) {
            return `/app_images/profile/group/${Math.floor(share_obj.share_scope_id / 10000)}/${share_obj.share_scope_id}.jpg`;
        }

        // 팀
        else if ( share_obj.share_scope == OWNER_TYPE.TEAM ) {
            return `/app_images/profile/team/${Math.floor(share_obj.share_scope_id / 10000)}/${share_obj.share_scope_id}.jpg`;
        }

        return '';
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 그룹, 팀 이미지 불러오는 중 에러가 발생한 경우
     */
    groupAndTeamImageError(event, share_obj : any) : void {
        $(event.target).parent().find('.img').addClass(this.hodu_color_dc_lc_for_group(share_obj.scope_color));
    }
    
    /**
     * 현재 상태에 따라서 Modal을 닫거나 그룹/팀의 유저 정보창에서 나간다
     */
    closeButtonClick() : void {

        // 그룹, 팀 유저를 보고있는 상태에선 그룹 / 팀 리스트로 이동만 시키고 종료
        if( this.view_scope == 'GROUP_AND_TEAM_USERS' ) {
            this.view_scope = 'GROUP_AND_TEAM';
            return;
        }

        // 그 외의 경우는 모달 닫기
        this.doSetEventShareStatusInfo({
            show_event_share_status : false,
            event_id : ""
        })
    }
}
</script>

<style scoped>
    /* 모달 공통 css */
    .modal { position:absolute; background:#fff; }
    .modal { border-radius:5px;margin:0 auto;width:100%;height: auto;top:50%;right: 0;left: 0;margin-bottom: 0;overflow:hidden;}
    
    .titleBox {overflow:hidden;border-bottom: 0;padding: 40px 0 30px 40px;box-shadow: none !important;line-height: inherit;border-bottom: 1px solid #e7e9ea;}
    .titleBox h1 {font-size: 18px;float:left;}
    .titleBox .total { float:left; font-size:14px; font-weight:bold; margin-left:15px;margin-top:2px; }
    .titleBox .total span { color:#477fff; padding-left:5px;}
    .titleBox .total.team {  }
    .modal .btns {border-top:1px solid #f1f3f5; height: 70px;line-height: 70px;border-radius: 0 0 5px 5px;overflow: hidden;}
    .modal .btns input {width: 50%;height: 70px;line-height: 60px;border: 0 none;float: left;font-size: 14px;border-radius: 0; }
    .modal .btns.b1 input {width: 100%;}
    .modal .schBtn { position:absolute; right:0;top:20px;right:30px; }
    .modal .schGrp {display:none;transition:0.1s;position: absolute;top: 110px;z-index: 100000000;width: 100%;height: 60px;background: white;top: 89px;left: 0;}
    .modal .schGrp.on { display:block; }
    .modal .schGrp input {transition:0.1s;background:transparent;width: 100%;box-sizing: border-box;height: 100%;line-height: 60px;padding: 0 100px 0 30px;font-size: 14px;font-weight: bold;}
    .modal .schGrp:hover { background:#f1f3f5; }
    .modal .schGrp input:focus { background:#fff;border-bottom:1px solid #477fff; height:60px;line-height:61px; }
    .modal .schGrp a.schBtn { top:0px; }

    .modal .noResult { display:none; position: absolute; z-index: 100000;top: 150px;height: 316px;background: #fff;line-height: 316px;font-weight: bold;font-size: 21px;color: #afb6c3;}
    .modal .noResult.on { display:block; }
    .modal .peepsDiv.on .noResult.on { top : 0px; }

    /* 공유자 보기 모달 css */
    .shareStatusModal .tabs { height: 57px; line-height: 57px; position: relative; font-size: 14px; }
    /* .shareStatusModal .tabs input {float: left;width: 50%;font-size: 14px;font-weight: bold;text-align: center;height:100%;background:#fff;border-bottom: 2px solid #e0e3e8;box-sizing: border-box;}
    .shareStatusModal .tabs input.on { border-bottom: 2px solid #477fff; } */
    .shareStatusModal .tabs a { width: 50%; display: inline-block; float: left; text-align: center; position: relative; color: #999; border-bottom: 3px solid #e7e9ea; }
    .shareStatusModal .tabs a span { color: #999; }
    .shareStatusModal .tabs a.on { border-bottom-color: #477fff; }
    .shareStatusModal .tabs a.on,
    .shareStatusModal .tabs a.on span { font-weight: bold; color: #477fff; }
    .shareStatusModal .sortHeader { position:relative; }
    .sortHeader li.grpCheck { position: absolute;left: 0;top: 0;width: 100px;text-align: center;font-weight: bold;font-size: 13px;}
    .shareStatusModal { transition:0.3s; max-width: 800px; bottom:auto !important; margin-top: -268px !important;height: 536px !important;}
    .shareUlDiv {height: auto;display: block;width: 100%;height: 316px;max-height: 316px;overflow:hidden; overflow-y:auto;}
    .shareUlDiv ul {display: block;width:100%;}
    .shareUlDiv li .grp {width: 100%;transition:0.1s;height: 55px;line-height: 55px;border-bottom: 1px solid #f1f3f5; position:relative;}
    .shareUlDiv li .grp:hover { background:#f1f3f5; }
    .shareUlDiv li .grp * {display: inline-block;float:left;}
    .shareUlDiv li .grp:hover .count a { border:2px solid #477fff; border-radius: 5px; padding:0 10px; line-height:30px; height:30px;margin-top:11px; }
    .shareUlDiv li .grp .count.noCount { opacity:0.6 }
    .shareUlDiv li .count a { transition:0.1s; }
    .shareUlDiv li .count a:hover { background:#477fff; color:#fff; }
    .shareUlDiv li .count a:hover span { color:#fff; }
    .shareUlDiv li .img {width: 57px;font-size: 0; background-position:center center !important; background-size: cover !important;height: 42px;border-radius: 5px;border: 1px solid #f1f3f5;position: absolute;left: 135px;top: 7px;}
    .shareUlDiv .teamUl li  .img {width: 50px;height: 36px;top: 9px;left: 141px;}
    .shareUlDiv li h2 {font-size: 14px;}
    .shareUlDiv li .nm { width:64%;box-sizing:border-box; padding-left:120px; padding-right:20px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
    .shareUlDiv li .count { width:10%;font-weight:bold; }
    .shareUlDiv li .count span { color:#477fff; margin-right:4px; }
    .shareUlDiv li.withTeams a.showTeams {position: absolute;right: 20px;top: 0;font-size: 0;width: 35px;height: 60px;border-radius: 50%; background:  url('../../../assets/images/side_area/bt_open.png') no-repeat center center;}
    .shareUlDiv li a.showTeams.on, .shareUlDiv li.on a.showTeams {background:  url('../../../assets/images/side_area/bt_close.png') no-repeat center center;  }
    .shareUlDiv .teamUl { display:none; border-top:1px solid #f1f3f5;border-bottom:1px solid #f1f3f5;}
    .shareUlDiv li.on > .grp {  }
    .shareUlDiv li.on .teamUl { display:block; }
    .shareStatusModal input[type='checkbox']:not(old) + label {position: absolute;left: 38px;top: 17px;z-index:10000000; }
    .shareStatusModal .teamUl input[type='checkbox']:not(old) + label{left: 50px;}
    .shareStatusModal input[type='checkbox']:not(old) + label, .shareStatusModal input[type='checkbox']:not(old) + label:hover {margin-left: 5px;display: inline-block;  padding: 0;width: 17px;height: 17px;border-radius: 3px;border: 2px solid #c1cfd8;background: #c1cfd8 url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;font-size: 0;margin-right: 8px;}
    .shareStatusModal input[type='checkbox']:not(old):checked + label { border:2px solid #477fff;background: #477fff url(../../../assets/images/contents/checked_fff.png) no-repeat 0 0;background-size: 18px;}
    .grpUl > li > .grp { height:60px; line-height:60px; }
    .grpUl > li > .grp .img  { top:8px; }
    .grpUl > li > .grp:hover > .count > a { margin-top:14px; }
    .grpUl > li > .grp input[type='checkbox']:not(old) + label { top: 19px }
    .shareUlDiv li .grp .cl {position: absolute;left: 111px;top: 26px;width: 8px;height: 8px;font-size: 0;border-radius: 50%;background: #f1f3f5;}
    .shareUlDiv li .teamUl .grp .cl { left:117px; }
    .shareStatusModal .teamUl .num {padding-left: 30px;}

    .grpHeader .num { width:15%;  }
    .grpHeader .nm {padding-left: 14px;width: 64%;box-sizing: border-box;}
    .grpHeader .count {width: 19%;}

    .teamHeader { display:none; }
    .shareStatusModal.peepsOn ul.grpUl {height: 447px;}
    .shareStatusModal.peepsOn { margin-top: -268px !important; height: 536px !important;}
    .shareStatusModal.peepsOn .teamHeader { display:block; }
    .shareStatusModal.peepsOn .grpHeader { display:none; }
    .shareStatusModal .peepsDiv { display:none;width:100%;position: absolute;background: #fff;z-index: 1000000000;top: 151px; }
    .shareStatusModal .peepsDiv.on {display: block;}
    .shareStatusModal .peepsDiv .peepsUlDiv { width: 100%;height: 316px;max-height: 316px;overflow: hidden;overflow-y: auto;}
    .shareStatusModal ul.peeps li * {display: inline-block;}
    .shareStatusModal ul.peeps li {transition:0.1s;position:relative;line-height: 55px;height: 55px;font-size: 13px;font-weight: bold;border-bottom:1px solid #f1f3f5;}
    .shareStatusModal ul.peeps li .fPic {position:absolute;background-color:#f1f3f5; left:115px; top:11px;font-size: 0;width: 30px;height: 30px;border-radius: 50%;background-position: center center !important;background-size: cover !important;border: 1px solid #e7e9ea;}
    .shareStatusModal ul.peeps li .pNm { padding-left:55px; padding-right:20px; width:63%; box-sizing:border-box; }
    .shareStatusModal ul.peeps li .pos { font-size:12px; }
    .shareStatusModal ul.peeps li:hover { background:#f1f3f5; }
    .shareStatusModal .peeps input[type='checkbox']:not(old) + label { top:14px; }
    .shareStatusModal .peeps .fPic.none { background-image : url(../../../assets/images/contents/im_photoB.gif) !important; }

    .shareStatusModal .pNm {  width:63%; box-sizing:border-box;    padding-left: 20px;  }
    .shareStatusModal .num {font-weight:bold;width: 14%; box-sizing:border-box; text-align: center;font-size:12px;}
    .shareStatusModal .peeps input[type='checkbox']:not(old) + label:after {content: '';display: block;width: 800px;height: 50px;position: absolute;left: -45px;top: -16px;}
    
    
    .teamHeader .pos {width: 20%;}
    .sortHeader input[type='checkbox']:not(old) + label { top:19px;}

    .shareUlDiv.mCS_destroyed { overflow : hidden !important }
    .fPicDummy { display : none !important; }

    /* 추후삭제 */
    /* .grpUl > li:nth-child(1) .img { background-image: url(../../../assets/images/footer/g4.jpg) }
    .teamUl > li:nth-child(1) .img { background-image: url(../../../assets/images/footer/g1.jpg) }
    .teamUl > li:nth-child(2) .img { background-image: url(../../../assets/images/footer/g2.jpg) }
    .teamUl > li:nth-child(3) .img { background-image: url(../../../assets/images/footer/g6.jpg) }
    .teamUl > li:nth-child(4) .img { background-image: url(../../../assets/images/footer/g10.jpg) }
    .teamUl > li:nth-child(5) .img { background-image: url(../../../assets/images/footer/g11.jpg) }
    .grpUl > li:nth-child(2) .img { background-image: url(../../../assets/images/footer/g7.jpg) }

    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(1) .fPic { background-image:  url(../../../assets/images/footer/f1.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(2) .fPic { background-image:  url(../../../assets/images/footer/f2.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(3) .fPic { background-image:  url(../../../assets/images/footer/f3.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(4) .fPic { background-image:  url(../../../assets/images/footer/f4.jpg)  }
    .grpUl > li:nth-child(1) > .peepsDiv .peeps li:nth-child(5) .fPic { background-image:  url(../../../assets/images/footer/f5.jpg)  } */
</style>