<template>
    <div id="kids_list" class="section_ce_fix">

        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize" />

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 id="viewTitle" class="title_doc">원아</h3>
            <a class="schBtn" @click.prevent="toggleSearch">검색하기</a>
        </div>

        <div class="searchDiv" :class="{ on : is_search_open == true }">
            <div class="schGrp">
                <a href="#" class="bt_option"><span class="txt">검색옵션</span></a>
                <input type="text" id="kids_search" placeholder="이름, 반, 담당선생님, 보호자 전화번호로 검색하세요" :value="search_query" @input="search_query = $event.target.value">
                <div class="btns">
                    <a class="closeBtn" @click.prevent="toggleSearch">닫기</a>
                </div>
            </div>
        </div>

        <div id="kids_list" class="section_scroll">
            <div class="viewGroup">

                <div class="fixedDiv">
                    <ul class="sortHeader kids_list_header">
                        <li class="num"><span>No</span></li>
                        <li class="name headerSort" :class="{
                                sortOff  : sort_type != 'NAME',
                                sortUp   : sort_type == 'NAME' && sort_direction == 'ASC', 
                                sortDown : sort_type == 'NAME' && sort_direction == 'DESC', 
                            }" @click.prevent="sort('NAME')">
                            <span>이름</span>
                        </li>
                        <li class="class" v-on-clickaway="dropdownOutsideClick">
                            <a href="#" class="sort" @click.prevent="toggleClassFilter">반</a>

                            <div class="dropdown" :class="{ on : is_class_filter_open == true }">
                                <ul>
                                    <li :key="item.team_id" v-for="item in computedClasses">
                                        <p>
                                            <input type="checkbox" :id="`kids_class_${item.team_id}`" class="input_check" :checked="class_filter.indexOf(item.team_id) == -1" @change="changeClassFilter($event, item)">
                                            <label :for="`kids_class_${item.team_id}`">
                                                <span>{{ item.team_name }}</span>
                                            </label>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="teacher"><span>담당선생님</span></li>
                        <li class="tel"><span>보호자 전화번호</span></li>
                    </ul>
                </div>

                <div id="kids_list_scroll" class="scroll">

                    <ul class="kids_list_ul">

                        <li :key="kid.member_key" v-for="(kid, index) in computedKids">
                            <a href="#" @click.prevent="moveKidDetail(kid.member_key)">
                                <p class="num">{{ index + 1 }}</p>
                                <p class="name">{{ kid.member_name }}</p>
                                <p class="class">
                                    {{ (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ? '' : (kid.class_info ? kid.class_info.team_info.team_name : '소속없음') }}
                                    <select @click.prevent="(event) => { event.stopPropagation() }" @change="changeKidsClass($event, kid)" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                        <option value="0" :selected="kid.class_info == null || teams.filter(team => team.team_id == kid.class_info.team_id).length < 1">소속없음</option>
                                        <option :value="team.team_id" :key="team.team_id" v-for="team in teams" 
                                                :selected="kid.class_info != null && team.team_id == kid.class_info.team_id">
                                            {{ team.team_info.team_name }}
                                        </option>
                                        <!-- <option>개나리반</option>
                                        <option>무지개반</option> -->
                                    </select>
                                </p>
                                <p class="teacher" :title="kid.teacher_info && kid.teacher_info.length > 0 ? getTeacherNameList(kid) : ''">
                                    {{ kid.teacher_info && kid.teacher_info.length > 0 ? getTeacherNameList(kid) : '-' }}
                                </p>
                                <p class="email">{{ getParentTel(kid) }}</p>
                            </a>
                        </li>

                        <!-- <li>
                            <a href="#" @click.prevent="moveKidDetail">
                                <p class="num">1</p>
                                <p class="name">이키즈</p>
                                <p class="class">무지개반</p>
                                <p class="teacher">이성재</p>
                                <p class="email">010-7104-6968, 010-7104-6968</p>
                            </a>
                        </li> -->
                    </ul>

                </div>
                
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, OWNER_TYPE, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { directive as onClickaway } from 'vue-clickaway';

import { ResizeObserver } from 'vue-resize';

@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        ResizeObserver
    },
})
export default class KidsList extends Mixins(VueHoduCommon) {

    /**
     * 원아 정보
     */
    get computedKids() : any[] {

        let computed_kids : any[] = JSON.parse(JSON.stringify(this.kids));

        if( this.search_query.trim().length > 0 ) {
            computed_kids = computed_kids.filter(kid => this.hodu_string_includes(kid.member_name, this.search_query) || 
                                                        kid.class_info != null && this.hodu_string_includes(kid.class_info.team_info.team_name, this.search_query) ||
                                                        kid.teacher_info != null && this.hodu_string_includes(kid.teacher_info.user_name, this.search_query) ||
                                                        this.getParentTelToArray(kid).filter(tel => this.hodu_string_includes(tel, this.search_query)).length > 0);
        }

        if( this.class_filter.length > 0 ) {            
            computed_kids = computed_kids.filter(kid => this.class_filter.indexOf(kid.team_id) == -1);
        }

        return computed_kids.sort((o1, o2) : number => {

            const o1_name = o1.member_name;
            const o2_name = o2.member_name;

            const o1_class_name = o1.class_info ? o1.class_info.team_info.team_name : null;
            const o2_class_name = o2.class_info ? o2.class_info.team_info.team_name : null;

            if( this.sort_type == 'NAME' ) {
                if( o1_name > o2_name ) return this.sort_direction == "ASC" ? 1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == "ASC" ? -1 : 1;
                return 0;
            }

            else if( this.sort_type == 'CLASS' ) {
                
                if( o1_class_name == o2_class_name ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0;
                }
                else if( o1_class_name == null && o2_class_name != null ) return 1;
                else if( o1_class_name != null && o2_class_name == null ) return -1;
                else if( o1_class_name > o2_class_name ) return this.sort_direction == "ASC" ? 1 : -1;
                else if( o1_class_name < o2_class_name ) return this.sort_direction == "ASC" ? -1 : 1;
                
            }

            return 0;
        });

    }

    /**
     * 반 정보
     */
    get computedClasses() : any[] {

        const classes : any[] = [];

        classes.push({
            "team_id" : 0,
            "team_name" : '소속없음',
        });

        for( const kid of this.kids ) {
            if( kid.class_info == null || classes.filter(item => item.team_id == kid.class_info.team_id).length > 0 ) continue;

            classes.push({
                "team_id" : kid.class_info.team_id,
                "team_name" : kid.class_info.team_info.team_name,
            });
        }

        return classes;
    }

    // 정렬
    sort_type : string = "NAME";
    sort_direction : SORT_TYPE = SORT_TYPE.ASC;

    class_filter : number[] = [];
    is_class_filter_open : boolean = false;

    is_search_open : boolean = false;
    search_query : string = "";

    teams : any[] = [];
    kids : any[] = [];

    mounted() : void {
        this.getTotalData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        let title_height = $('.title_box').outerHeight();
        let sort_header_height = $('.sortHeader').outerHeight();

        let scroll_height = window.innerHeight - (title_height ? title_height : 0) 
                                               - (sort_header_height ? sort_header_height : 0);
        
        // @ts-ignore
        $('#kids_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });

    }

    /**
     * 전체 데이터 조회
     */
    async getTotalData() : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getTeamInfo();
            await this.getKidsInfo();
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "원아 정보 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 팀 정보 조회
     */
    async getTeamInfo() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/teams/${this.scope_group_id}/0`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.teams ) {
                throw new Error("팀 정보 조회 중 오류 발생");
            }

            this.teams.splice(0, this.teams.length);
            this.teams = this.teams.concat(response.data.data.teams);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 원아 정보 조회
     */
    async getKidsInfo() : Promise<void> {

        const partner_key : string = this.getGroupPartnerKey(this.scope_group_id);

        try {
            const response = await this.hodu_api_call(`api/v1/kids?partner_key=${partner_key}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.kids ) {
                throw new Error("원아 정보 조회 중 오류 발생");
            }

            this.kids.splice(0, this.kids.length);
            this.kids = this.kids.concat(response.data.data.kids);

        } catch(e) {    
            throw e;
        }

    }

    /**
     * 원아 반 변경
     */
    async changeKidsClass(event, kid) : Promise<void> {

        let before_api_call = true;
        const original_team_id = kid.class_info?.team_id ?? 0;

        try {
            const partner_key = this.getGroupPartnerKey(this.scope_group_id);
            const string_team_id = event.target.value;
            const team_id = Number(string_team_id);

            const response = await this.hodu_api_call(`api/v1/kids/class`, API_METHOD.PUT, {
                "type" : "kids",
                "group_id" : this.scope_group_id,
                "team_id" : team_id,
                "partner_key" : partner_key,
                "member_key" : kid.member_key
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("원아 반 변경 중 오류 발생");
            }

            before_api_call = true;
            this.getTotalData();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "원아 반 변경 중 오류 발생", ['확인']);

            // API 실행이 완료되기전에 오류 발생시 원본값으로 변경
            if( before_api_call ) {
                $(event.target).val(String(original_team_id));
            }
        }

    }

    /**
     * 보호자 전화번호 string 반환
     */
    getParentTel(kid) : string {

        if( kid == null || kid.parent_info == null ) return '-';

        let return_string : string = "";

        for( const parent of kid.parent_info ) {
            if( return_string.length > 0 ) return_string += ", ";
            return_string += parent.user_phone_number;
        }
        
        return return_string.length > 0 ? return_string : '-';
    }

    /**
     * 보호자 전화번호 array 반환 
     */
    getParentTelToArray(kid) : string[] {
        if( kid == null || kid.parent_info == null ) return [];

        let return_string_array : string[] = [];

        for( const parent of kid.parent_info ) {
            if( parent.user_phone_number.trim().length > 0 ) return_string_array.push(parent.user_phone_number);
        }

        return return_string_array;
    }

    /**
     * 담당선생님 이름 리스트 반환
     */
    getTeacherNameList(kid) : string {
        if( kid.teacher_info == null || kid.teacher_info.length < 1 ) return '-';

        let name_list = "";

        for( const teacher of kid.teacher_info ) {
            if( name_list.length > 0 ) name_list += ", ";
            name_list += teacher.user_name;
        }
        
        return name_list.length < 1 ? '-' : name_list;
    }

    /**
     * 검색창 토글
     */
    toggleSearch() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_query = "";
        }
        else {
            this.$nextTick(() => $('#kids_search').focus());
        }
    }

    /**
     * 정렬
     */
    sort(sort_type : string) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 클래스 필터 토글 
     */
    toggleClassFilter(event) : void {
        event.stopPropagation();
        this.is_class_filter_open = !this.is_class_filter_open
    } 

    /**
     * 클래스 필터 변경
     */
    changeClassFilter(event, item) : void {
        const checked = event.target.checked;
        const team_id = item.team_id;

        if( checked == true && this.class_filter.indexOf(team_id) > -1 ) {
            this.class_filter.splice(this.class_filter.indexOf(team_id), 1);
        }
        else if( checked == false && this.class_filter.indexOf(team_id) == -1 ) {
            this.class_filter.push(team_id);
        }
    }

    /**
     * 원아 상세로 이동
     */
    moveKidDetail(member_key) : void {
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/kids/kid/${member_key}`);
    }

    /**
     * 드롭다운 바깥쪽 클릭
     */
    dropdownOutsideClick() : void {
        this.is_class_filter_open = false;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#kids_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    #kids_list .num { width : 10%; text-align: center; }
    #kids_list .name { width : 20%; }
    #kids_list .class { width : 20%; }
    #kids_list .teacher { width : 20%; }
    #kids_list .tel { width : 30%; }

    #kids_list .kids_list_ul > li { box-sizing:border-box; position:relative; z-index: 0; width:100%; border-bottom:1px solid #f1f3f5;height: 55px;line-height: 55px; }
    #kids_list .kids_list_ul > li p { display: inline-block;font-weight: bold;float: left;box-sizing: border-box;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; }
    #kids_list .kids_list_ul > li p.num { padding-right: 0px }
    #kids_list .kids_list_ul > li p select {font-weight: bold; transition: 0.2s;-webkit-appearance: none;-moz-appearance: none;appearance: none;border-radius: 5px;height: 30px;line-height: 30px;cursor: pointer;background-color: #fff;background-position: 95% center;background-repeat: no-repeat;box-sizing: border-box;border:1px solid #f1f3f5; padding: 0 30px 0 10px; background-image: url(../../assets/images/side_area/bt_open.png); margin : 0; margin-bottom : 3px; }
    #kids_list .kids_list_ul > li p select:hover { border:1px solid #e7e9ea; }

    .dropdown { text-indent: 0px !important; }
</style>