<template>
    <div class="searchPlaces remodal modal w1110"  :class="{ faveOn : favorite_open == true }">
        <button class="remodal-close" @click="locationModalClose"><span></span><span></span></button>
        <h1 class="borderbot">{{ isRead(event_crud_type) ? '장소' : '장소 검색' }}</h1>
        <div class="remodal-content  remodal-content05">
            <div class="map_wrap">
                <div id="modalMap" class="modal_mapStyle"></div>

                <div id="menu_wrap" class="bg_white" style="overflow-y : hidden !important" :style="{ display : isRead(event_crud_type) ? 'none' : 'block' }">
                    <div class="option">
                        <input type="text" @keydown="locationKeyDown" id="modalMapkeyword" size="15" placeholder="장소를 검색하세요" 
                               :value="search_query" @input="search_query = $event.target.value"> 
                        <button type="button" id="modalMapBtnSearch" @click="locationSearch">검색하기</button>
                    </div>
                    <div class="placesListDivScroll" style="height: 405px; overflow: hidden; max-height: 405px;">
                        <ul id="placesList">
                            
                            <li class="item" :key="location.id" v-for="location in location_list" @click="locationClick(location)">
                                <span :class="`markerbg marker_${location_list.indexOf(location) + 1}`"></span>
                                <span v-if="location.isFavorite == true" class="faveStar"></span>
                                <div class="info" style="text-align:left;">
                                    <h5>{{ location.place_name }}</h5>    
                                    <span v-if="location.road_address_name != null && location.road_address_name.length > 0">{{ location.road_address_name }}</span>
                                    <span class="jibun gray">{{ location.address_name }}</span>
                                    <span class="tel" v-if="location.phone != null && location.phone.length > 0">{{ location.phone }}</span>
                                </div>
                            </li>

                        </ul>

                        <div id="pagination">
                            <a href="#" v-if="total_page >= 1" :class="{ on : current_page == 1 }" @click.prevent="selectOtherPage(1)">1</a>
                            <a href="#" v-if="total_page >= 2" :class="{ on : current_page == 2 }" @click.prevent="selectOtherPage(2)">2</a>
                            <a href="#" v-if="total_page >= 3" :class="{ on : current_page == 3 }" @click.prevent="selectOtherPage(3)">3</a>
                        </div>

                    </div>

                </div>
            </div>

            <!-- 즐겨찾기 영역 시작 -->
            <div class="favePlaces">
                <h2 id="searchPlacesFavorite" @click="favoriteOpen" :style="{ display : isRead(event_crud_type) ? 'none' : 'block' }">즐겨찾기</h2>
                <div class="option">
                    <input type="text" id="favoriteModalMapkeyword" size="15" placeholder="즐겨찾기한 장소를 검색하세요" :value="favorite_search_query" @input="favorite_search_query = $event.target.value">
                </div>
                <div class="faveScroll">
                    <ul id="favoriteAddrUl">
                        <!-- 삭제 클릭시 리스트 클래스 추가 delConfirm -->
                        <li class="item" :key="favorite.addr_uuid" v-for="(favorite, index) in ( favorite_search_query.trim() == '' ? favorite_list : favorite_search_list )" 
                        :class="{ editing : favorite_list.indexOf(favorite) == favorite_modify_index, off : favorite_modify_index != -1 && favorite_list.indexOf(favorite) != favorite_modify_index,
                                delConfirm : show_delete_alert[index] != null && show_delete_alert[index] == true }">
                            <span class="faveStar"></span>
                            <div class="info" style="text-align:left;">
                                <input type="text" :value="favorite.addr.title" :id="`favPlaceNm${favorite_list.indexOf(favorite)}`" @focus="favoriteFocusEdit(favorite)" />
                                <span v-if="favorite.addr.new_addr != null && favorite.addr.new_addr.length > 0">{{ favorite.addr.new_addr }}</span>
                                <span class="jibun gray" v-if="favorite.addr.old_addr != null && favorite.addr.old_addr.length > 0">{{ favorite.addr.old_addr }}</span>
                                <span class="tel" v-if="favorite.addr.addr_tel != null && favorite.addr.addr_tel.length > 0">{{ favorite.addr.addr_tel }}</span>
                                
                                <!-- 즐겨찾기 제목 수정 버튼 영역 끝 -->
                                <div class="grp">
                                    <input type="button" value="취소" class="btn_cancel" @click.prevent="cancelModifyFavorite(favorite)">
                                    <input type="button" value="저장" class="btn_save"   @click.prevent="modifyFavorite(favorite)">
                                </div>
                                <!-- 즐겨찾기 제목 수정 버튼 영역 끝 -->

                            </div>

                            <!-- 취소 저장 버튼 -->
                            <div class="btns">
                                
                                <p class="btn_del favDel" @click="deleteFavorite(favorite)"><span>삭제</span></p>
                                <!-- <p class="btn_del favDel" @click.prevent="deleteAlertOpen(index)"><span>삭제</span></p> -->
                                <p class="btn_del favCon" @click.prevent="selectFavorite(favorite)"><span>선택</span></p>
                            </div>

                            <!-- 삭제 컨펌창 -->
                            <div class="confirm">
                                <div class="bg"></div>
                                <p>
                                    <span class="title">지누스</span>
                                    <span class="question">삭제 하시겠습니까?</span>
                                </p>
                                <div class="btn_grp">
                                    <input type="button" value="아니오" class="btn_no" @click="deleteAlertCancel(index)">
                                    <!-- <input type="button" value="예" class="btn_yes"  @click="deleteFavorite(favorite)"> -->
                                </div>
                            </div>


                            <div class="successMode">
                                <div class="bg"></div>
                                <span class="success"></span><p>저장되었습니다</p>
                            </div>

                        </li>

                    </ul>
                </div>
            </div>
            <!-- 즐겨찾기 영역 끝 -->

        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { CRUD_TYPE, API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
import { t_favorite_addr } from '@/model/osm';
import { t_event_location } from '@/model/event';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

declare var daum: any;

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

@Component({

}) export default class EventDaumMap extends Mixins(VueHoduCommon) {

    @EventInfo.State  event_crud_type       !: string;
    @EventInfo.State  location_search_query !: string;
    @EventInfo.State  selected_location     !: t_event_location;

    @EventInfo.Action doSetSelectedLocation ?: any;
    
    @ModalInfo.Action doSetShowEventDaumMap ?: any;

    map : any;
    
    map_overlay : any = new daum.maps.CustomOverlay({xAnchor:1}); // 검색 결과 목록이나 마커를 클릭했을 때 장소명을 표출할 인포윈도우를 생성합니다

    search_query : string = "";
    searched_query : string = ""; // 최종적으로 검색한 값
    total_page : number = 0;
    current_page : number = 1;
    favorite_open : boolean = false;

    location_list : any[] = [];
    selected_any_location : any = null;
    marker_list : any[] = [];
    total_count : number = 0;

    favorite_list : t_favorite_addr[] = [];
    favorite_modify_index : number = -1;

    favorite_search_list : t_favorite_addr[] = [];
    favorite_search_query : string = "";
    
    favorite_add : boolean = false; // favroite이 추가되서 재조회하는경우 list를 맨 밑으로 가게한다

    show_delete_alert : boolean[] = [];

    async mounted() {
        $('#modalMapkeyword').focus();
        
        let container = document.getElementById('modalMap');
        let mapOption = {
            center: new daum.maps.LatLng(
                this.event_crud_type == CRUD_TYPE.READ && this.selected_location != null && this.selected_location.geo != null ? this.selected_location.geo.latitude  : 37.566826,
                this.event_crud_type == CRUD_TYPE.READ && this.selected_location != null && this.selected_location.geo != null ? this.selected_location.geo.longitude : 126.9786567
            ), // 지도의 중심좌표
            level: 3 // 지도의 확대 레벨
        }; 

        this.map = new daum.maps.Map(container, mapOption); //지도 생성 및 객체 리턴

        // @ts-ignore
        $('.placesListDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
        });
        
        // @ts-ignore
        $('.faveScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
        });

        await this.getFavoriteAddress();

        // 검색어 들어있는채로 들어왔다면 검색도 한다
        if( this.location_search_query != null && this.location_search_query.length > 0 ) {
            this.search_query = this.location_search_query;
            await this.locationSearch();
        }

        // 일정 조회에서 들어왔다면 마커 생성
        if( this.event_crud_type == CRUD_TYPE.READ && this.selected_location != null && this.selected_location.geo != null ) {
            const bounds = new daum.maps.LatLngBounds();

            // 마커 추가 
            const marker : any = new daum.maps.Marker({
                position: new daum.maps.LatLng(this.selected_location.geo.latitude, this.selected_location.geo.longitude),
                image: new daum.maps.MarkerImage(
                    require('../../../assets/images/contents/ic_pin_chosen.png'), 
                    new daum.maps.Size(40, 40),
                    {
                        spriteSize : new daum.maps.Size(40, 40),  // 스프라이트 이미지의 크기
                        spriteOrigin : new daum.maps.Point(0, 0), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
                        offset: new daum.maps.Point(20, 40)       // 마커 좌표에 일치시킬 이미지 내에서의 좌표
                    }
                ) 
            });
            marker.setMap(this.map);
            this.marker_list.push(marker);

            bounds.extend(new daum.maps.LatLng(this.selected_location.geo.latitude, this.selected_location.geo.longitude));

            this.map.setBounds(bounds);

            // overlay설정
            const content : string = `
            <div class="modal_mapwrap">
                <div class="info">
                    <div class="title">${ this.selected_location.place }</div>
                    <div class="body">
                        <div class="desc">
                            <div class="ellipsis"> ${ this.selected_location.address_old } </div>
                        </div>
                        <div class="modalBtns">
                            <a href="javascript: window.closeDaumMapModal()" id="locationConfirm" class="confirm" style="width:100%;">확인</a>
                        </div>
                    </div>
                </div>
            </div>`;
            
            this.map_overlay.setContent(content);
            this.map_overlay.setPosition(new daum.maps.LatLng(this.selected_location.geo.latitude, this.selected_location.geo.longitude));
            this.map_overlay.setMap(this.map);

            daum.maps.event.addListener(this.map, 'click', (mouseEvent) => { this.map_overlay.setContent(''); });
            daum.maps.event.addListener(this.map, 'dragstart', (mouseEvent) => { this.map_overlay.setContent(''); });
        }

        // 카카오맵에 만들어 넣는 html에서 사용 할 함수를 window에 함수 등록
        window["addFavorite"]    = this.addFavorite;
        window["selectLocation"] = this.selectLocation;
        window["closeDaumMapModal"] = () => this.doSetShowEventDaumMap(false);
    }

    locationModalClose() : void {
        this.doSetShowEventDaumMap(false);
    }

    /**
     * 즐겨찾기 메뉴 toggle
     */
    favoriteOpen() : void {
        this.favorite_open = !this.favorite_open;
    }

    /**
     * 키다운 이벤트 감지 : 엔터키 누르면 검색
     */
    locationKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.locationSearch();
    }

    /**
     * 장소 검색, 페이징은 별도의 함수
     */
    async locationSearch() : Promise<void> {
        const vue = this;

        // 검색어 입력이 없는 경우
        if( this.search_query.trim().length < 1 ){
            return;
        } 

        await this.hodu_api_call(`api/v1/location?query=${this.search_query}&page=${this.current_page}&size=15`, API_METHOD.GET)
            .then(async(response) => {
                // console.log(JSON.stringify(response));  

                // 최종 검색 결과 저장
                vue.searched_query = vue.search_query;

                // 검색결과가 없는 경우
                if( response.data.data.documents.length < 1 ) {
                    alert("검색 결과가 존재하지 않습니다");
                    return;
                }

                // @ts-ignore
                $('.placesListDivScroll').mCustomScrollbar("scrollTo", "top", { scrollInertia : 0 });
                
                vue.location_list = response.data.data.documents;
                vue.total_count   = response.data.data.total_count;

                vue.setMapInformation();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * bound, 마커, favorite 정보 등을 가공 
     */
    setMapInformation() : void {

        const location_count : number = this.location_list.length;

        if( location_count > 0 ) {
            this.total_page = ( 30 < this.total_count )                           ? 3 
                            : ( 15 < this.total_count && 30 >= this.total_count ) ? 2
                            : 1; 
        } else {
            this.total_page = 0;
        }

        // bounds 설정
        const bounds = new daum.maps.LatLngBounds();

        // 마커 제거 하고 진행
        this.removeMarker();

        for( let i = 0; i < location_count; i++ ) { 
            
            // 즐겨찾기인지 검사 (위도, 경도로 체크)
            const favorite_count : number = this.favorite_list.length;
            for( let j = 0; j < favorite_count; j++ ) {
                if( this.favorite_list[j].addr.latitude == this.location_list[i].y && this.favorite_list[j].addr.longitude == this.location_list[i].x ) {
                    this.location_list[i].isFavorite = true;
                }
            }

            // 마커 추가 
            const marker : any = new daum.maps.Marker({
                position: new daum.maps.LatLng(this.location_list[i].y, this.location_list[i].x),
                image: new daum.maps.MarkerImage(
                    require('../../../assets/images/contents/marker_number_red.png'), 
                    new daum.maps.Size(36, 37),
                    {
                        spriteSize : new daum.maps.Size(36, 691),             // 스프라이트 이미지의 크기
                        spriteOrigin : new daum.maps.Point(0, (i * 46) + 10), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
                        offset: new daum.maps.Point(13, 37)                   // 마커 좌표에 일치시킬 이미지 내에서의 좌표
                    }
                ) 
            });
            marker.setMap(this.map);
            this.marker_list.push(marker);

            bounds.extend(new daum.maps.LatLng(this.location_list[i].y, this.location_list[i].x));
        }
        
        // bounds 적용
        this.map.setBounds(bounds);

        // overlay 없애기
        this.map_overlay.setMap(null);
    }

    /**
     * 지도 위에 표시 된 모든 마커 제거
     */
    removeMarker() : void {
        const marker_count : number = this.marker_list.length;
		for ( let i = 0; i < marker_count; i++ ) {
            const marker : any = this.marker_list[i];
            marker.setMap(null);
        }
        this.marker_list = [];
    }
    /**
     * 페이지네이션 클릭 이벤트 ( current_page 바꿔서 재조회 )
     */
    selectOtherPage(seleted_page : number) : void {
        if( this.current_page == seleted_page ) {
            return;
        }
        
        const vue = this;
        this.current_page = seleted_page;

        this.hodu_api_call(`api/v1/location?query=${this.searched_query}&page=${this.current_page}&size=15`, API_METHOD.GET)
            .then((response) => {
                // console.log(JSON.stringify(response));  

                // 페이지 변경 후 기존 검색 결과로 되돌린다
                vue.search_query = vue.searched_query;

                // 검색결과가 없는 경우 ()
                if( response.data.data.documents.length < 1 ) {
                    alert("데이터 조회 오류, 잠시 후 다시 시도해주세요");
                    return;
                }

                // @ts-ignore
                $('.placesListDivScroll').mCustomScrollbar("scrollTo", "top", { scrollInertia : 0 });
                
                vue.location_list = response.data.data.documents;
                vue.total_count   = response.data.data.total_count;

                vue.setMapInformation();
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }
    
    /**
     * 리스트 클릭 이벤트 ( 맵 이동 + 맵에 정보 및 확인창 띄우기 )
     */
    locationClick(location : any) : void {
        this.selected_any_location = location;

        this.map_overlay.setMap(null);
        this.map.setLevel(2);
        this.map.setCenter(new daum.maps.LatLng(location.y, location.x));

        const content : string = `
            <div class="modal_mapwrap">
                <div class="info">
                    <div class="title">${ location.place_name }</div>
                    <div class="body">
                        <div class="desc">
                            <div class="ellipsis"> ${ location.address_name } </div>
                        </div>
                        <div class="modalBtns">
                            <a href="javascript: window.addFavorite()   " id="addFavorite"     class="confirm">즐겨찾기 ${ this.selected_any_location.isFavorite == true ? '해제' : '등록'}</a>
                            <a href="javascript: window.selectLocation()" id="locationConfirm" class="confirm">선택</a>
                        </div>
                    </div>
                </div>
            </div>`;
            
        this.map_overlay.setContent(content);
        this.map_overlay.setPosition(new daum.maps.LatLng(location.y, location.x));
        this.map_overlay.setMap(this.map);
    }

    /**
     * 즐겨찾기 조회
     */
    async getFavoriteAddress() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/user/favorite`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);
                vue.favorite_list = response.data.data.favorite;

                vue.show_delete_alert.splice(0, vue.show_delete_alert.length);
                const favorite_count : number = vue.favorite_list.length;
                for( let i = 0; i < favorite_count; i++ ) {
                    vue.show_delete_alert.push(false);
                }

                this.map_overlay.setMap(null);

                if( this.selected_any_location != null ) {
                    this.locationClick(this.selected_any_location);
                }

                if( vue.favorite_add == true ) {
                    setTimeout(() => { 
                        // @ts-ignore
                        $('.faveScroll').mCustomScrollbar("scrollTo", "bottom", { scrollInertia : 0 }); 
                        vue.favorite_add = false;
                    }, 75);
                }
                
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 즐겨찾기 등록
     */
    @Debounce(150)
    addFavorite() : void {
        const vue = this;

        // 이미 존재하는 즐겨찾기라면 
        if( this.selected_any_location.isFavorite == true ) {
            const favorite_legnth : number = this.favorite_list.length;
            let addr : t_favorite_addr | undefined;
            for( let i = 0; i < favorite_legnth; i++ ) {
                if( ( this.selected_any_location.x == this.favorite_list[i].addr.longitude ) &&
                    ( this.selected_any_location.y == this.favorite_list[i].addr.latitude  ) ) {
                    addr = this.favorite_list[i];
                }
            }
            
            if( addr != null ) {
                this.deleteFavorite(addr);
            }
        }

        // 즐겨찾기 추가
        else {
            this.hodu_api_call(`api/v1/user/favorite`, API_METHOD.POST, {
                "id" : this.selected_any_location.id,
                "title" : this.selected_any_location.place_name,
                "addr_tel" : this.selected_any_location.phone,
                "old_addr" : this.selected_any_location.address_name,
                "new_addr" : this.selected_any_location.road_address_name,
                "longitude" : this.selected_any_location.x,
                "latitude" : this.selected_any_location.y
            }).then((reponse) => {
                vue.favorite_add = true;
                
                // 선택된 장소 즐겨찾기 플래그 업데이트
                vue.selected_any_location.isFavorite = true;
                
                // 즐겨찾기 재조회
                vue.getFavoriteAddress();

            }).catch((e) => {
                this.hodu_error_process(e, false, false);
            });
        }
    }

    /**
     * 포커스 들어가면 수정모드로 변경
     */
    favoriteFocusEdit(favorite : t_favorite_addr) : void {
        this.favorite_modify_index = this.favorite_list.indexOf(favorite);
    }

    /**
     * 즐겨찾기 수정 취소
     */
    cancelModifyFavorite(favorite : t_favorite_addr) : void {
        $(`#favPlaceNm${this.favorite_list.indexOf(favorite)}`).val(this.favorite_list[this.favorite_list.indexOf(favorite)].addr.title);
        
        // setTimeout이 없으면 favorite_modify_index가 -1이 되면서 뒤쪽 li 클릭이벤트 조건을 만족해서 동시에 발생해버린다
        setTimeout(() => { this.favorite_modify_index = -1; }, 1);
    }

    /**
     * 즐겨찾기 이름 수정
     */
    modifyFavorite(favorite : t_favorite_addr) : void {
        const vue = this;

        const modifyName : string = String($(`#favPlaceNm${this.favorite_list.indexOf(favorite)}`).val()).trim();

        // 이름 검사
        if( modifyName.length < 1 ) {
            alert("즐겨찾기 이름을 입력해주세요");
            return;
        }
        
        // 즐겨찾기 이름 수정
        this.hodu_api_call(`api/v1/user/favorite/${favorite.addr_uuid}`, API_METHOD.PUT, {
            "title" : modifyName
        }).then((response) => {
            
            // 즐겨찾기 재조회
            vue.getFavoriteAddress();
            this.favorite_modify_index = -1;

        }).catch((e) => {
            this.hodu_error_process(e, false, false);
        });
    }

    /**
     * 즐겨찾기 리스트에 삭제 alert 띄우기
     */
    deleteAlertOpen(index : number) : void {
        this.show_delete_alert.splice(index, 1, true);
    }

    /**
     * 즐겨찾기 삭제 취소
     */
    deleteAlertCancel(index : number) : void {
        this.show_delete_alert.splice(index, 1, false);
    }

    /**
     * 즐겨찾기 삭제
     */
    @Debounce(150)
    deleteFavorite(favorite : t_favorite_addr) : void {
        const vue = this;

        this.hodu_api_call(`api/v1/user/favorite/${favorite.addr_uuid}`, API_METHOD.DELETE)
            .then((response) => {
                
                // 조회된 장소 리스트에서 즐겨찾기 플래그 업데이트
                const location_count : number = vue.location_list.length;
                for( let i = 0; i < location_count; i++ ) {
                    if( favorite.addr.latitude == this.location_list[i].y && favorite.addr.longitude == this.location_list[i].x ) {
                        vue.location_list[i].isFavorite = false;
                        break;
                    }
                }
                
                // 즐겨찾기 재조회
                vue.getFavoriteAddress();

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }
    
    /**
     * 장소 선택
     */
    selectLocation() : void {
        this.doSetSelectedLocation({
            "place" : this.selected_any_location.place_name,
            "tel" : this.selected_any_location.phone,
            "post_code" : "",
            "address" : this.selected_any_location.road_address_name,
            "address_old" : this.selected_any_location.address_name,
            "geo" : {
                "longitude" : this.selected_any_location.x,
                "latitude" : this.selected_any_location.y,
            }
        });
        window["changeSeletedLocation"]();
        this.doSetShowEventDaumMap(false);
    }

    /**
     * 즐겨찾기 선택
     */
    selectFavorite(favorite : t_favorite_addr) : void {
        if( this.favorite_modify_index > -1 ) {
            return;
        } 

        this.doSetSelectedLocation({
            "place" : favorite.addr.title,
            "tel" : favorite.addr.addr_tel,
            "post_code" : "",
            "address" : favorite.addr.new_addr,
            "address_old" : favorite.addr.old_addr,
            "geo" : {
                "longitude" : favorite.addr.longitude,
                "latitude" : favorite.addr.latitude,
            }
        });
        window["changeSeletedLocation"]();
        this.doSetShowEventDaumMap(false);
    }

    /**
     * 즐겨찾기 장소 검색
     */
    @Watch('favorite_search_query')
    favoriteSearch() : void {
        this.favorite_search_list.splice(0, this.favorite_search_list.length);
        this.favorite_search_list = this.favorite_search_list.concat(this.favorite_list.filter(favorite => favorite.addr.title.indexOf(this.favorite_search_query.trim()) > -1));

        console.log(this.favorite_search_query.trim());
        console.log(JSON.stringify(this.favorite_search_list))
    }
}

</script>
<style>
    .placesListDivScroll .mCSB_inside > .mCSB_container { margin-right: 0 !important; }
    .map_wrap { border:0 none; }
    .modal_mapwrap {position: absolute;left: 0;bottom: 40px;width: 300px;height: 142px;margin-left: -150px;text-align: left;/* overflow: hidden; */font-size: 12px;line-height: 1.5;}
    .modal_mapwrap * {padding: 0;margin: 0;}
    .modal_mapwrap .info {width: 300px;height: 140px;border-radius: 5px;border-bottom: 2px solid #ccc;border-right: 1px solid #ccc;overflow: hidden;background: #fff;}
    .modal_mapwrap .info:nth-child(1) {border: 0;box-shadow: 0px 5px 6px rgba(0,0,0,0.2);}
    .modal_mapwrap .title {font-size: 16px;height: 50px;line-height: 65px;padding: 0 15px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;text-align:center; }
    .modal_mapwrap .ellipsis {opacity:0.5;line-height: 30px;font-size: 13px;padding: 0 15px;box-sizing: border-box;height: 44px;text-align:center;}
    .modal_mapwrap .modalBtns {border-top: 1px solid #f1f3f5;}
    .modal_mapwrap .modalBtns a {height: 45px;line-height: 45px;font-size: 13px;width: 50%;float: left;box-sizing: border-box;text-align: center;border:0 none;}
    .modal_mapwrap .modalBtns a#locationConfirm {background: #477fff;color: #fff;}
    #menu_wrap.noResult { height:45px !important;  }
    #addFavorite:hover { background:#f1f3f5; }
</style>
<style scoped>
    .searchPlaces.modal {max-width:964px;background:#fff;position: absolute;z-index: 1000000;left: 0;right: 0;margin: 0 auto;top:50%;margin-top:-270px}
    .mCSB_inside > .mCSB_container { margin-right:0 !important; }
    .searchPlaces.remodal.w1110 .remodal-content05 { overflow:visible; }
    .map_wrap, .map_wrap * {margin:0;padding:0;font-size:12px;}
    .map_wrap a, .map_wrap a:hover, .map_wrap a:active{color:#000;text-decoration: none;}
    .map_wrap {position:relative;transition: 0.1s;width:100%;height:472px;border:0 none;}
    .modal_mapStyle{width:100%;height:100%;position:relative;overflow:hidden;border-radius: 0 0 5px 5px;}
    #menu_wrap {position:absolute;top:0;left:0;bottom:0;width:250px;box-sizing: border-box;margin:10px 0 30px 10px;overflow-y:auto;background: rgba(255, 255, 255, 0.95);z-index: 1;font-size:12px;border-radius: 10px;box-shadow: 0 10px 10px rgba(0,0,0,0.15);}
    .bg_white {background:#fff;}
    .searchPlaces .option{text-align: center;height: 30px;overflow: hidden;position: relative;padding: 0;}
    .searchPlaces .option p {margin:10px 0;}  
    .searchPlaces .option button {margin-left: 5px;height: 45px;position: absolute;top: 0;right: 0;background: url(../../../assets/images/contents/ic_search_on.png) no-repeat center center;width: 50px;font-size: 0 !important;color: inherit;border: none;padding: 0;border-radius: 15px;font: inherit;cursor: pointer;outline: inherit;}
    .searchPlaces .option button.btn_edit {right: 10px;background: #ffbe0d url(../../../assets/images/contents/input_pen_fff.png) no-repeat center center;}
    .searchPlaces li {list-style: none;}
    .searchPlaces .item {transition: 0.2s;position:relative;border-bottom: 1px solid #e0e0e0;overflow: hidden;cursor: pointer;min-height: 65px;height: auto;padding: 7px 30px 7px 0;min-height: 80px;box-sizing: border-box;}
    .searchPlaces .item span {display: block;overflow: hidden;font-weight: bold;font-size: 12px;height: 23px;line-height: 23px;text-overflow: ellipsis;white-space: nowrap;}
    .searchPlaces .item h5, .searchPlaces .item .info {text-overflow: ellipsis;overflow: hidden;white-space: nowrap;font-size: 14px;padding-right: 15px;box-sizing: border-box;}
    .searchPlaces .item .info{padding:33px 0 10px 45px;}
    .searchPlaces .info .gray {color: #737373;}
    .searchPlaces .info .jibun {padding-left: 30px;background: url(http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/places_jibun.png) 0 4px no-repeat;font-weight: normal;}
    .searchPlaces .info .tel {color: #477fff;}
    .searchPlaces #placesList .item {padding-right: 20px;}
    .searchPlaces #placesList .item .markerbg {margin: 5px 0 0 10px;}
    .searchPlaces .item .markerbg {float:left;position:absolute;width: 26px;height: 28px;margin: 9px 0 0 10px;background:url('../../../assets/images/contents/marker_number_red.png') no-repeat;background-size: 25px;}
    .searchPlaces .item .marker_1 {background-position: 0 -7px;}
    .searchPlaces .item .marker_2 {background-position: 0 -39px;}
    .searchPlaces .item .marker_3 {background-position: 0 -70px}
    .searchPlaces .item .marker_4 {background-position: 0 -101px;}
    .searchPlaces .item .marker_5 {background-position: 0 -134px; }
    .searchPlaces .item .marker_6 {background-position: 0 -166px;}
    .searchPlaces .item .marker_7 {background-position: 0 -196px;}
    .searchPlaces .item .marker_8 {background-position: 0 -230px;}
    .searchPlaces .item .marker_9 {background-position: 0 -261px}
    .searchPlaces .item .marker_10 {background-position: 0 -293px;}
    .searchPlaces .item .marker_11 {background-position: 0 -325px}
    .searchPlaces .item .marker_12 {background-position: 0 -357px}
    .searchPlaces .item .marker_13 {background-position: 0 -390px}
    .searchPlaces .item .marker_14 {background-position: 0 -420px}
    .searchPlaces .item .marker_15 {background-position: 0 -453px}
    #pagination {margin:10px auto;text-align: center;display: block;height: 60px;width: 100%;}
    #pagination .on {font-weight: bold; cursor: default;color: #477fff !important;background: #f1f3f5;}
    #pagination a {display: inline-block;width: 25px;height: 25px;border-radius: 50%;line-height: 25px;border: 1px solid #e9ebec;margin: 0 5px;}
    #pagination a:hover {background: #f1f3f5;}
    .searchPlaces .option { height:47px; }
    .searchPlaces .option input {height: 100%;width: 100%;text-align: left;box-sizing: border-box;padding-right: 40px;background: transparent;padding-left: 15px;}
    input#modalMapkeyword {height:47px; line-height:47px; border-bottom:2px solid #e0e0e0;font-size: 14px;padding-left: 15px;font-weight: bold;font-size: 13px;}
    input#modalMapkeyword:hover, input#favoriteModalMapkeyword:hover { background:#f1f3f5; }
    input#modalMapkeyword:focus, input#favoriteModalMapkeyword:focus { background:#fff; border-bottom:2px solid #477fff; }
    input#favoriteModalMapkeyword {border-bottom:2px solid #e0e0e0; padding-right: 15px;font-weight: bold;font-size: 13px;}
    .searchPlaces .item h5 {margin-bottom: 5px;}
    .searchPlaces .item:hover {background: #f1f3f5;transition:0.1s;}


    .remodal.searchPlaces.faveOn .map_wrap {width: 100%;transition: 0.1s;}
    .remodal.searchPlaces.faveOn .favePlaces {width: 250px;transition: 0.1s;}
    .faveScroll { max-height:423px; overflow:hidden; }
    .faveScroll .mCSB_inside > .mCSB_container { margin-right:0; }
    .favePlaces {background: white;position: absolute;right: 0;z-index: 100000000;top: 0;height: 100%;border-left:1px solid #f3f3f3;box-sizing: border-box;width:0;}
    .favePlaces ul {height: auto;}
    .favePlaces ul li {text-align: left;}
    .favePlaces h2:hover, .faveOn .favePlaces h2 {width:120px;background-position: 15px center;box-sizing:border-box;padding: 0 15px 0 40px;font-size:14px;}
    .faveOn .favePlaces h2 {background-color:#f1f3f5;}
    .favePlaces h2 {position: absolute;right: 20px;top: -50px;background: url(../../../assets/images/contents/faveStar.png) no-repeat center center;background-size: 23px;z-index: 1000000000;padding: 0;width: 35px;font-size: 0;height: 35px;line-height: 34px;color: #484848;border: 1px solid #e5e9ec;border-radius: 30px;cursor: pointer;}

    #favoriteAddrUl .item input {background: transparent;font-size: 14px;width: 170px;font-weight: bold;line-height: 20px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;    position: absolute;
    top: 16px;
    z-index: 100;
}
    #favoriteAddrUl .item input:hover { cursor: pointer;}  
    #favoriteAddrUl .item.editing input {cursor: text; background: #fff;border: 1px solid #eaeaea;padding-left: 4px;}
    #favoriteAddrUl .item input.editing:hover { cursor: text;} 
    #favoriteAddrUl .item.editing {background: #f3f3f3;}
    #favoriteAddrUl .item.editing .btns { display:none !important; }
    #favoriteAddrUl .item input.btn_edit {display:none;background: #ffbe0d url(../../../assets/images/contents/input_pen_fff.png) no-repeat center center;width: 30px;height:30px;font-size: 0 !important;color: inherit;border: none;padding: 0;border-radius: 15px;font: inherit;cursor: pointer;outline: inherit;left:12px; top:20px;}
    #favoriteAddrUl .faveStar {background: url(../../../assets/images/contents/faveStar.png) no-repeat top center;background-size: 21px;background-position: 0px -3px;}
    .searchPlaces .item span.faveStar {position: absolute;width: 26px;height: 28px;margin: 11px 0 0 10px; }
    #favoriteAddrUl .item .btns {display:none;box-sizing: border-box;padding: 0 0 0 37px;}
    #favoriteAddrUl .item .btn_del {text-align: center;transition:0.1s;position: static;background: #ffffff;line-height: 30px;border: 1px solid #e5eaef;border-radius: 5px;margin-bottom: 10px;padding: 0;display: inline-block;width: 47%;margin: 0;box-sizing: border-box;}
    #favoriteAddrUl .item .btn_del.favCon { margin-left:4%; }
    #favoriteAddrUl .item .btn_del.favCon:hover span { color:#477fff; }
    #favoriteAddrUl .item .btn_del span {display: inline-block;line-height: 30px;}
    #favoriteAddrUl .item:hover .btns {display: block;}
    #favoriteAddrUl .item:hover .btn_del:hover span {color: #ff0000;}
    .searchPlaces #favoriteAddrUl .item .info {padding-left: 40px;}
    .searchPlaces #favoriteAddrUl .item:hover .info {transition: 0.2s;padding-left: 40px;}
    .searchPlaces #favoriteAddrUl .item:hover {transition: 0.2s;}
    #favoriteAddrUl .grp { display: none; }
    #favoriteAddrUl .grp input {color:#fff;width: 95px;line-height: 30px;padding-left: 0;border-radius: 5px;border: 0;margin-right: 10px;font-size: 12px;}
    #favoriteAddrUl .grp input.btn_save {color: #477fff;position:static;} 
    #favoriteAddrUl .grp input.btn_save:hover {background: #477fff;color: #fff;}
    #favoriteAddrUl .grp input.btn_cancel {color: #ff5a5a;position:static;} 
    #favoriteAddrUl .grp input.btn_cancel:hover {background: #ff5a5a;color: #fff;}
    #favoriteAddrUl .item.editing { padding-right:0; }
    #favoriteAddrUl .item.editing .grp {display:block;margin-top: 10px;text-align: center;width: 206px;}
    #favoriteAddrUl .item.editing .grp input:hover { cursor: pointer }
    #favoriteAddrUl .item.editing .btn_edit { display:none; }
    #favoriteAddrUl .item.editing .faveStar { width:0; background:0; transition:0.1s; }
    .searchPlaces #favoriteAddrUl .item.editing .info {padding-left: 20px;}
    #favoriteAddrUl .item.editing .info > input {width: 200px; margin-bottom:5px;margin-top: -2px;}
    #favoriteAddrUl .item.editing:hover { cursor:default; }
    #favoriteAddrUl .item.editing .btn_del { display:none; }
    #favoriteAddrUl .item.editing .btn_del span { display:none; }
    #favoriteAddrUl .item .info >  input:hover {line-height: 20px;border: 1px solid #eaeaea;background: #fff;cursor: text;margin-top: -2px;width: 180px;padding-left: 4px;-webkit-box-sizing: border-box;box-sizing: border-box;padding-right: 30px;background: #fff  url(../../../assets/images/contents/input_pen.png) no-repeat top center;background-position: 156px 5px;}
    #favoriteAddrUl .item.editing .info > input:hover { background:#fff; padding-right:4px; box-sizing:border-box;width: 206px;margin-top: -2px;}


    /* */
    .searchPlaces #favoriteAddrUl .item.off:hover .info {padding-left: 48px;}
    #favoriteAddrUl .item.off:hover .faveStar {background-position: 1px -4px;}
    .searchPlaces #favoriteAddrUl .item.off {background: #fff;cursor: default;opacity: 0.7;}
    #favoriteAddrUl .item.off:hover .btn_del {display: none;}
    #favoriteAddrUl .item.off input:hover {padding-left: 1px;border: 0 none;margin-top: 0;background: none;cursor: default;}
    #favoriteAddrUl .item input[type='text']:disabled {color: #6f6f6f;}

    /* */
    #placesList .faveStar {background: url(../../../assets/images/contents/faveStar.png) no-repeat top center;background-size: 20px;background-position: 1px -4px;position: absolute;z-index: 1000;top: 35px;left: -2px;width: 30px;height: 30px;display: inline-block;}

    #favoriteAddrUl > li.delConfirm .confirm { display:block; }
    #favoriteAddrUl > li.delConfirm .btns { display:block !important; }
    #favoriteAddrUl .item .confirm, .item .successMode { display:none; position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 1000000;text-align: center; box-sizing:border-box; padding: 15px 0;}
    #favoriteAddrUl .item .confirm:hover, .item .successMode:hover { cursor: default;left: 0;top: 0;}
    #favoriteAddrUl .item .confirm .bg, .item .successMode .bg {top:0;left:0;opacity:0.95;position: absolute;z-index: -1;background: rgba(241, 243, 245, 0.95);width: 100%;height: 100%;}
    #favoriteAddrUl .item .confirm .btn_grp input {border:0 none;border-radius: 5px;margin: 0 5px;display: inline-block;width: 35%;color:#fff;line-height:30px;border: 1px solid #e7ebef;font-size:12px;}
    #favoriteAddrUl .item .confirm .btn_grp input.btn_no {background: #fff;color: #000;}
    #favoriteAddrUl .item .confirm .btn_grp input.btn_no:hover {color: #ff3737;}
    #favoriteAddrUl .item .confirm .btn_grp input.btn_yes {color: #000;background: #fff;}
    #favoriteAddrUl .item .confirm .btn_grp input.btn_yes:hover {color: #477fff;}
    #favoriteAddrUl .confirm p  {position: absolute;width: 100%;margin-top:-43px;top:50%;}
    #favoriteAddrUl .confirm .btn_grp {position:absolute;margin-top: 22px;bottom: 20px;width:100%;left:0;}
    #favoriteAddrUl .confirm .btn_grp .btn_no, #favoriteAddrUl .confirm .btn_grp .btn_yes { position:static; }
    .confirm span.title {display: inline-block;text-align: left;max-width: 180px;text-overflow: ellipsis;overflow: hidden;font-size: 16px;display: none;color: #fff;white-space: nowrap;font-weight: bold;}
    .confirm span.question {margin: 7px 0 20px;font-size: 14px;color: #000;}
    #favoriteAddrUl .item .successMode { opacity:0; bottom:-200px; transition:0.1s;  display:block; top:auto; }
    #favoriteAddrUl .item .successMode.on { opacity:1; bottom:0; transition:0.1s; }
    #favoriteAddrUl .item .successMode .bg { opacity:1 }

    #favoriteAddrUl .item p.btn_del.favCon {display: block;position: absolute;left: 0;top: 0;margin: 0;width: 100%;height: 100%;background: none;font-size: 0;border: 0;background: transparent;margin: 0 !important;}
    #favoriteAddrUl .item p.btn_del.favCon span { display:none; }
    #favoriteAddrUl .item p.btn_del.favDel {position: absolute;display: block !important;background: none;border: 0 none;font-size: 0 !important;width: 25px;height: 25px;background: #fff  url(../../../assets/images/contents/ic_x_new.png) no-repeat center center;border:1px solid #e3e7ed;background-size:21px;border-radius: 50%;z-index: 1000;left: 8px;top: 13px;}
    #favoriteAddrUl .item p.btn_del.favDel:hover {  }
    #favoriteAddrUl .item p.btn_del.favDel span {display: none;}

    #placesList .info { padding-top: 7px !important; }
</style>