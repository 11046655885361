<template>
    <!-- 오른쪽 닫기 .closeLeft 클릭시 section_ce_fix에 toggleClass leftDivClosed -->
    <div id="group_file_box_new" class="section_ce_fix" :class="{
        dp1 : file_search_query.length > 0 || is_card_view == false,
        dp2 : file_search_query.length < 1 && is_card_view == true,
        fileSrcOn : file_search_query.length > 0,
        leftDivClosed : is_right_div_open == false
    }" @dragover.prevent="fileDragOver($event)">
    
        <!-- 리사이즈 옵저버 -->
        <resize-observer @notify="handleResize"/>

        <div class="bg4file" :class="{ on : (is_file_drag == true) }" @dragleave.prevent="fileDragLeave($event)" @drop.prevent="fileDrop($event)" v-if="selected_dir != null">
            <span>끌어당기고 떨어뜨리세요</span>
        </div>

        <div class="addFileDiv" v-if="selected_dir != null">
            <!-- label 클릭시 파일선택 후 확인 클릭시 .newFileModal 불러오기 -->
            <label for="addFile" class="addFileLabel">+</label>
            <input type="file" multiple="multiple" id="addFile" value="파일추가" @change="addFile($event)"/>
        </div>

        <div class="title_box">
            <a href="#" class="bt_back" @click.prevent="movePrevPage"><span class="blind">이전화면 가기</span></a>
            <h3 class="title_doc" id="viewTitle">파일함</h3>
        </div>

        <!-- 검색 -->
        <div class="schedule_box">
            <div class="settingBg">
                
                <!-- 폴더트리 메뉴 default 열어두기 -->
                <div class="btns_left" v-show="file_search_query.length < 1">
                    <input type="button" class="schBtn closeLeft" :class="{ on : is_right_div_open == true }" @click.prevent="dirTreeOnOff">
                </div>

                <!-- 파일, 폴더 검색 -->
                <div class="schDiv">
                    <input type="text" placeholder="파일명 또는 폴더명을 검색하세요" :value="file_search_query" @input="file_search_query = $event.target.value">
                    <input type="button" class="schBtn">
                    <!-- 파일 검색 쓰면 검색 닫는 버튼 -->
                    <button class="schBtnClear" @click.prevent="file_search_query = ''"></button>
                </div>

                <div class="folderAddBtn" v-if="file_search_query.length < 1">
                    <a id="folderAdd" class="input_btn" @click.prevent="showGroupAddFileBox">폴더추가</a>
                </div>

                <div class="btns" v-if="file_search_query.length < 1">
                    <input type="button" value="박스형으로보기" title="박스형" class="dpTwo" @click.prevent="cardViewModeOnOff" v-if="is_card_view == false">
                    <input type="button" value="리스트로보기" title="리스트" class="dpOne" @click.prevent="cardViewModeOnOff" v-if="is_card_view == true">
                </div>
            </div>
        </div>

        <!-- 그룹에 들어왔을때 이동경로표시 -->
        <div id="route_bar">
            <nav class="deep_route">
                <ul class="routeUl">
                    <li>
                        <i class="ic_file">아이콘</i>
                    </li>

                    <!-- 해당 파일함 경로시 클래스 fixd_route 추가 -->
                    <li class="routeLi" :class="{ fixd_route : selected_dir == null }">
                        <a href="#" @click.prevent="selectFileBox('', true)">{{ scope_group_team_option.group_team_name }}</a>
                    </li>

                    <li class="routeLi" :key="dir.dir_uuid" v-for="dir in (selected_dir == null ? [] : selected_dir.route_file_dirs)" :class="{
                        fixd_route : dir.dir_uuid == dir_uuid
                    }">
                        <a href="#" @click.prevent="selectFileBox(dir.dir_uuid, true)">{{ dir.dir_name }}</a>
                    </li>

                </ul>
            </nav>
        </div>
        <!-- //그룹에 들어왔을때 이동경로표시 -->

        <!-- content -->
        <div id="" class="content">

            <!-- left 폴더 트리 -->
            <div class="groupLeftDiv">
                <div class="forRel">
                    <div class="leftContent">
                        <ul class="sortHeader">
                            <li class="fdType sortOff">
                                <span>폴더</span>
                            </li>
                        </ul>

                        <div id="filebox_tree" class="fdTreeBox">
                            
                            <ul class="fdTreeUl">

                                <!-- 폴더트리 처음 li만 fdTreeFirst 클래스 붙음 -->
                                <li class="fdTreeFirst" :class="{ active : dir_uuid == null || dir_uuid.length < 1 }">
                                    <div class="fd_tree fdTotal">
                                        <a href="#" @click.prevent="selectFileBox('', false)">전체보기</a>
                                    </div>
                                </li>

                                <li class="fdTreeFirst" :key="dir.dir_uuid" v-for="dir in computedDirTree" :class="{ active : dir.dir_uuid == dir_uuid }">
                                    <div class="fd_tree" :class="{ tree_has_child : dir.dirs.length > 0 }">
                                        <a href="#" @click.prevent="selectFileBox(dir.dir_uuid, false)">{{ dir.dir_name }}</a>
                                        <button class="mainMore" @click.prevent="toggleLeftDir(dir)">더보기</button>
                                    </div>
                                    <GroupFileBoxRecursion
                                        :level="dir.level" 
                                        :dirs="dir.dirs" 
                                        :dir_uuid="dir_uuid" 
                                        :dir_closed_map="dir_closed_map"
                                        :class="{ open : dir.is_closed == false }"
                                        @select="selectFileBox"
                                        v-if="dir.dirs.length > 0"
                                    />
                                </li>

                                <!-- <li class="fdTreeFirst"> -->
                                    <!-- 하위폴더가 있을 경우 tree_has_child 클래스 추가 -->
                                    <!-- <div class="fd_tree tree_has_child">
                                        <a href="#">경영자</a>
                                        <button class="mainMore">더보기</button>
                                    </div> -->
                                    <!-- 더보기 클릭하면 opne 클래스 추가 -->
                                    <!-- depth추가 될때마다  padding-left: 15px 씩 증가 하게 -->
                                    <!-- <ul class="fdSubUl fdSub_1depth">
                                        <li>
                                            <div class="fd_tree">
                                                <a href="#">관리비</a>
                                                <button class="mainMore">더보기</button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="fd_tree tree_has_child">
                                                <a href="#">경영자료</a>
                                                <button class="subMore">더보기</button>
                                            </div>
                                            <ul class="fdSubUl fdSub_2depth">
                                                <li>
                                                    <div class="fd_tree tree_has_child">
                                                        <a href="#">세무세무</a>
                                                        <button class="subMore">더보기</button>
                                                    </div>
                                                    <ul class="fdSubUl fdSub_3depth">
                                                        <li>
                                                            <div class="fd_tree">
                                                                <a href="#">납부</a>
                                                                <button class="subMore">더보기</button>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="fd_tree">
                                                                <a href="#">계산서</a>
                                                                <button class="subMore">더보기</button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <div class="fd_tree">
                                                        <a href="#">회사업무</a>
                                                        <button class="subMore">더보기</button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li> -->

                                <!-- <li class="fdTreeFirst">
                                    <div class="fd_tree tree_has_child">
                                        <a href="#">APP개발</a>
                                        <button class="mainMore">더보기</button>
                                    </div>

                                    <ul class="fdSubUl fdSub_1depth">
                                        <li>
                                            <div class="fd_tree">
                                                <a href="#">ios</a>
                                                <button class="subMore">더보기</button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="fd_tree">
                                                <a href="#">Android</a>
                                                <button class="subMore">더보기</button>
                                            </div>
                                        </li>
                                    </ul>

                                </li> -->

                                <!-- <li class="fdTreeFirst">
                                    <div class="fd_tree">
                                        <a href="#">홍보마케팅 자료</a>
                                        <button class="mainMore">더보기</button>
                                    </div>
                                </li> -->

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //left 폴더 트리 -->

            <!-- right 폴더 리스트 -->
            <div class="groupRightDiv">
                <!-- 그룹 폴더 -->
				<div class="group_file">
					<ul class="sortHeader">
                        <!-- 아이콘 클릭시 fave_on 클래스 추가 후 즐겨찾기 정렬 -->
                        <li class="faveStar" title="즐겨찾기 정렬" :class="{ fave_on : show_fave_only == true }">
                            <i class="ic_faveStar faveSort" @click.prevent="() => { show_fave_only = !show_fave_only }">아이콘</i>
                        </li>
						<li class="grp headerSort" @click.prevent="sortTypeChange('DIR_NAME')"
                            :class="{
                                sortOff  : sort_type != 'DIR_NAME',
                                sortUp   : sort_type == 'DIR_NAME' && sort_dir == 'ASC',
                                sortDown : sort_type == 'DIR_NAME' && sort_dir == 'DESC'
                            }">
                            <span>이름</span>
                        </li>
                        <li class="size headerSort sortOff" @click.prevent="sortTypeChange('FILE_COUNT')"
                            :class="{
                                sortOff  : sort_type != 'FILE_COUNT',
                                sortUp   : sort_type == 'FILE_COUNT' && sort_dir == 'ASC',
                                sortDown : sort_type == 'FILE_COUNT' && sort_dir == 'DESC'
                            }">
                            <span>용량 / 파일수</span>
                        </li>
						<li class="fileHead writtenBy headerSort" @click.prevent="sortTypeChange('USER_NAME')"
                            :class="{
                                sortOff  : sort_type != 'USER_NAME',
                                sortUp   : sort_type == 'USER_NAME' && sort_dir == 'ASC',
                                sortDown : sort_type == 'USER_NAME' && sort_dir == 'DESC'
                            }">
                            <span>등록자</span>
                        </li>
						<li class="lastUpdated headerSort" @click.prevent="sortTypeChange('UPDATE')"
                            :class="{
                                sortOff  : sort_type != 'UPDATE',
                                sortUp   : sort_type == 'UPDATE' && sort_dir == 'ASC',
                                sortDown : sort_type == 'UPDATE' && sort_dir == 'DESC'
                            }">
                            <span>업데이트</span>
                        </li>
					</ul>
                    
                    <div id="filebox_scroll" class="group_file_scroll">
                        <div class="h4Div">
                            <h4>폴더</h4>
                            <p class="totalNum">&#40;{{ computedDirsAndFiles.filter(item => item.list_type == 'DIR').length }}개&#41;</p>
                        </div>

                        <!-- 파일함 존재하지 않을 때 -->
                        <div class="noResult" :class="{ on : computedDirsAndFiles.length < 1 }">
                            <p>파일함이 존재하지 않습니다</p>
                        </div>

                        <ul class="grfolderUl group">

                            <!-- 폴더 추가 -->
                            <li class="addFolderList">
                                <a class="folder add_folder" @click.prevent="showGroupAddFileBox">파일함</a>
                            </li>

                            <!-- 내가 생성한 폴더 또는 파일 클래스 writerCrt 추가 메뉴보기 생성 -->
                            <!-- 비밀번호 파일 pswFolder 클래스 추가 -->
                            <li :key="(obj.list_type == 'DIR') ? obj.dir_uuid : obj.file_uuid" v-for="obj in computedDirsAndFiles" :class="{
                                writerCrt : isEditable(obj) == true,
                                pswFolder : obj.has_password == true,
                                fileUpLi : obj.list_type == 'FILE',
                                on : obj.menu_open == true
                            }" :title="obj.list_type == 'DIR' ? '폴더로 이동' : '다운로드'">

                                <!-- TODO 메뉴 ON / OFF -->
                                <a class="fileMenu" @click.prevent="menuOpen(obj)">메뉴보기</a>

                                <p class="faveStar" title="즐겨찾기" :class="{ fave_on : obj.is_favorite == true }">
                                    <i class="ic_faveStar faveSelect" @click.prevent="insertFileOrDirFavorite(scope_group_id, obj, false)">아이콘</i>
                                </p>

                                <p class="grp fileName">
                                    <span class="ic-password">비밀번호 여부</span>
                                    {{ obj.list_type == 'DIR' ? obj.dir_name : obj.file_name }}
                                </p>

                                <p class="size">
                                    <span class="size">{{ obj.list_type == 'DIR' ? dirFileSize(obj) : byteToSize(obj.file_size) }}</span>
                                    <span class="total"> &#47; {{ obj.path_files == null ? '0' : obj.path_files.length }}개</span>
                                </p>

                                <p class="fd folder" :class="`folder_${hodu_color_dc_lc(obj.dir_color)}`" v-if="obj.list_type == 'DIR'">
                                    <span class="blind">폴더개수</span>
                                    <span class="total">{{ obj.dirs.length }}</span>
                                </p>

                                <p class="preview" v-if="obj.list_type == 'FILE'" @click.prevent="fileDownLoad(obj.file_path, obj.file_name)" :class="{
                                    fe : new RegExp('image').test(obj.file_type) == false,
                                    img : new RegExp('image').test(obj.file_type) == true
                                }" :style="{ backgroundImage : getBackgroundImage(obj) }">
                                    <span class="blind">파일</span>
                                    {{ obj.file_name.substring(obj.file_name.lastIndexOf('.') + 1) }}
                                </p>

                                <p class="fileHead writtenBy">
                                    <span>{{ obj.user_name }}</span>
                                </p>

                                <p class="lastUpdated">{{ hodu_date_to_format_string(obj.audit_modified, 'YY.MM.DD') }} {{ getDayOfWeekByDate(obj.audit_modified) }} </p>

                                <a class="goDets" @click.prevent="obj.list_type == 'DIR' ? selectFileBox(obj.dir_uuid, false) : fileDownLoad(obj.file_path, obj.file_name)">페이지로가기</a>

                                <ul class="fileMenuOp" v-if="obj.list_type == 'DIR'">
                                    <li><button type="button" class="fileOpList" @click.prevent="showGroupAddFileBox($event, obj)">폴더수정</button></li>
                                    <li><button type="button" class="fileOpList fdMove" @click.prevent="dirMove(obj)">폴더이동</button></li>
                                    <li><button type="button" class="fileOpList pswDel" @click.prevent="password_input(obj, 'delete')">비밀번호 삭제</button></li>
                                </ul>

                                <ul class="fileMenuOp fileUplodOp" v-if="obj.list_type == 'FILE'">
                                    <li><button type="button" class="fileOpList fdMove" @click.prevent="fileMove(obj)">파일이동</button></li>
                                    <li><button type="button" class="fileOpList" @click.prevent="fileDelete(obj.file_uuid)">파일삭제</button></li>
                                </ul>
                            </li>

                            
                            <!-- <li class="writerCrt pswFolder">
                                <a class="fileMenu">메뉴보기</a>
                                <p class="faveStar" title="즐겨찾기">
                                    <i class="ic_faveStar faveSelect">아이콘</i>
                                </p>
                                <p class="grp"><span class="ic-password">비밀번호 여부</span>경영자</p>
                                <p class="fd folder folder_dc2">
                                    <span class="blind">폴더개수</span>
                                    <span class="total">2</span>
                                </p>
                                <p class="fileHead writtenBy">
                                    <span>이과장</span>
                                </p>
                                <p class="lastUpdated">24.05.19 월</p>
                                <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                <ul class="fileMenuOp">
                                    <li><button type="button" class="fileOpList fdMove">폴더이동</button></li>
                                    <li><button type="button" class="fileOpList">폴더수정</button></li>
                                    <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                </ul>
                            </li>

                            <li>
                                <a class="fileMenu">메뉴보기</a>
                                <p class="faveStar" title="즐겨찾기">
                                    <i class="ic_faveStar faveSelect">아이콘</i>
                                </p>
                                <p class="grp"><span class="ic-password">비밀번호 여부</span>APP개발</p>
                                <p class="fd folder folder_dc3">
                                    <span class="blind">폴더개수</span>
                                    <span class="total">2</span>
                                </p>
                                <p class="fileHead writtenBy">
                                    <span>이팀장</span>
                                </p>
                                <p class="lastUpdated">24.05.19 월</p>
                                <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                <ul class="fileMenuOp">
                                    <li><button type="button" class="fileOpList fdMove">폴더이동</button></li>
                                    <li><button type="button" class="fileOpList">폴더수정</button></li>
                                    <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                </ul>
                            </li>

                            <li>
                                <a class="fileMenu">메뉴보기</a>
                                <p class="faveStar" title="즐겨찾기">
                                    <i class="ic_faveStar faveSelect">아이콘</i>
                                </p>
                                <p class="grp"><span class="ic-password">비밀번호 여부</span>홍보마케팅 자료</p>
                                <p class="fd folder folder_dc0">
                                    <span class="blind">폴더개수</span>
                                    <span class="total">0</span>
                                </p>
                                <p class="fileHead writtenBy">
                                    <span>장팀장</span>
                                </p>
                                <p class="lastUpdated">24.04.10 수</p>
                                <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                <ul class="fileMenuOp">
                                    <li><button type="button" class="fileOpList fdMove">폴더이동</button></li>
                                    <li><button type="button" class="fileOpList">폴더수정</button></li>
                                    <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                </ul>
                            </li> -->

                            <!-- <li class="fileUpLi writerCrt" title="다운로드">
                                <a class="fileMenu">메뉴보기</a>
                                <p class="faveStar" title="즐겨찾기">
                                    <i class="ic_faveStar faveSelect">아이콘</i>
                                </p>
                                <p class="grp fileName"><span class="ic-password">비밀번호 여부</span>호두 프리미엄</p>
                                <p class="size">
                                    <span class="size">705.51 KB</span>
                                    <span class="total"> &#47; 6개</span>
                                </p>
                                <p class="preview img">
                                    <span class="blind">파일</span>
                                </p>
                                <p class="fileHead writtenBy">
                                    <span>이과장</span>
                                </p>
                                <p class="lastUpdated">24.05.19 월</p>
                                <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                <ul class="fileMenuOp">
                                    <li><button type="button" class="fileOpList fdMove">폴더이동</button></li>
                                    <li><button type="button" class="fileOpList">폴더수정</button></li>
                                    <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                </ul>

                                <ul class="fileMenuOp fileUplodOp">
                                    <li><button type="button" class="fileOpList fdMove">파일이동</button></li>
                                    <li><button type="button" class="fileOpList">파일삭제</button></li>
                                </ul>
                            </li>

                            <li class="fileUpLi writerCrt" title="다운로드">
                                <a class="fileMenu">메뉴보기</a>
                                <p class="faveStar" title="즐겨찾기">
                                    <i class="ic_faveStar faveSelect">아이콘</i>
                                </p>
                                <p class="grp fileName"><span class="ic-password">비밀번호 여부</span>호두_직원_기본인사정보_가져오기_양식.xlsx</p>
                                <p class="size">
                                    <span class="size">705.51 KB</span>
                                    <span class="total"> &#47; 6개</span>
                                </p>
                                <p class="preview fe">
                                    <span class="blind">파일</span>
                                    xlsx
                                </p>
                                <p class="fileHead writtenBy">
                                    <span>이과장</span>
                                </p>
                                <p class="lastUpdated">24.05.19 월</p>
                                <a title="페이지로 가기" class="goDets">페이지로가기</a>

                                <ul class="fileMenuOp">
                                    <li><button type="button" class="fileOpList fdMove">폴더이동</button></li>
                                    <li><button type="button" class="fileOpList">폴더수정</button></li>
                                    <li><button type="button" class="fileOpList pswDel">비밀번호 삭제</button></li>
                                </ul>

                                <ul class="fileMenuOp fileUplodOp">
                                    <li><button type="button" class="fileOpList fdMove">파일이동</button></li>
                                    <li><button type="button" class="fileOpList">파일삭제</button></li>
                                </ul>
                            </li> -->

                        </ul>
                    </div>

				</div>
				<!-- // 그룹 폴더 -->

            </div>
            <!-- //right 폴더 리스트 -->

            <div class="group_file grp_file_search">
                <ul class="sortHeader">
                    <!-- 아이콘 클릭시 fave_on 클래스 추가 후 즐겨찾기 정렬 -->
                    <li class="faveStar" title="즐겨찾기만 보기" :class="{ fave_on : show_fave_only == true }">
                        <i class="ic_faveStar faveSort" @click.prevent="() => { show_fave_only = !show_fave_only }">아이콘</i>
                    </li>
                    <li class="grp headerSort sortUp"><span>이름</span></li>
                </ul>

                <div id="search_filebox_scroll">
                    <div class="premium_group_file">

                        <!-- 검색 결과가 없을 때 -->
                        <div class="noResult" :class="{ on : computedSearchFiles.length < 1 }">
                            <p>검색된 결과가 없습니다.</p>
                        </div>

                        <ul class="grfolderUl">

                            <!-- 검색 대상이 폴더 안 파일일 경우 li에 subMore on 추가하여 더보기 아이콘 및 기본 on 상태로 나오기 -->
                            <li :key="dir.dir_uuid" v-for="dir in computedSearchFiles" :class="{
                                subMore : dir.files.length > 0,
                                on : dir.files.length > 0 && close_search_dir_uuids.indexOf(dir.group_type == 'BIZC' ? dir.dir_uuid : dir.biz_id) < 0
                            }">

                                <p class="faveStar" title="즐겨찾기" :class="{ fave_on : dir.is_favorite == true }">
                                    <i class="ic_faveStar faveSelect" @click.prevent="insertFileOrDirFavorite(scope_group_id, dir, false)">아이콘</i>
                                </p>

                                <!-- 디렉토리 비밀번호 걸려있을경우 내부 span에 pswFolder 클래스 추가 -->
                                <p class="grp" v-html="getGrpHtml(dir)"></p>

                                <p class="fd folder" :class="`folder_${hodu_color_dc_lc(dir.dir_color)}`">
                                    <span class="blind">폴더개수</span>
                                    <span class="total">{{ dir.dirs.length }}</span>
                                </p>

                                <a title="파일 보기" class="seeSubFolder" @click="searchFileBoxOnOff(dir)" v-if="dir.files.length > 0">파일</a>
                                <a title="파일함으로 이동" class="goDets" @click.prevent="selectFileBox(dir.dir_uuid, false)">파일함으로 이동</a>

                                <div class="subFolderDiv">
                                        <div class="blackBg"></div>
                                        <div class="subFoldertitleDiv">
                                            <h5>폴더</h5>
                                            <input type="button" value="취소" class="closeSub" @click.prevent="searchFileBoxOnOff(dir)">
                                        </div>

                                        <div class="subFolderContent">
                                            <ul class="subFolderUl">

                                                <li class="fileUpLi writerCrt" :key="file.file_uuid" v-for="file in dir.files">
                                                    <p class="grp fileName" v-html="getFileGrpHtml(file)"></p>
                                                    <p class="preview" :class="{ 
                                                        fe : new RegExp('image').test(file.file_type) == false,
                                                        img : new RegExp('image').test(file.file_type) == true 
                                                    }" :style="{ backgroundImage : getBackgroundImage(file) }">
                                                        <span class="blind">파일</span>
                                                        {{ file.file_name.substring(file.file_name.lastIndexOf('.') + 1) }}
                                                    </p>
                                                    <a title="다운로드" class="goDets" @click.prevent="fileDownLoad(file.file_path, file.file_name)">다운로드</a>
                                                </li>

                                            </ul>
                                        </div>

                                    </div>

                            </li>
                        </ul>

                    </div>
                </div>
            </div>

        </div>
        <!-- //content -->
        
        <!-- <a @click.prevent="moveFileBox">메인달력 파일함</a><br/>
        <a @click.prevent="moveGroupFileBox"><b>그룹달력 파일함 페이지</b></a><br/>
        <a @click.prevent="moveGroupFileList">그룹달력 파일함 디렉토리 내부 페이지</a> -->

    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

import { GroupAddFileModalNewInfo, GroupFileboxMoveModalInfo } from '@/store/modules/ModalInfo';
import { hodu_cookie } from '@/lib/HoduCookie';

const dateFormat = require('dateformat');

import { t_event_file } from '@/model/event';

import GroupFileBoxRecursion from '@/components/GroupFileBoxRecursion.vue';

import { ResizeObserver } from 'vue-resize';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

@Component({
    components: {
        GroupFileBoxRecursion, ResizeObserver
    },
})
export default class GroupFileBoxNew extends Mixins(VueHoduCommon) {

    get computedDirsAndFiles() {
        let target_list : any[] = [];

        let target_dirs = Array.from(this.dirs, (item => Object.assign(item, { "list_type" : "DIR" })));
        let target_files = Array.from(this.files, (item => Object.assign(item, { "list_type" : "FILE" })));

        target_list = target_list.concat(target_dirs);
        target_list = target_list.concat(target_files);

        if( this.is_card_view == false && this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list.sort((o1, o2) => {

            const o1_list_type = o1.list_type;
            const o2_list_type = o2.list_type;

            if( o1_list_type != o2_list_type ) {
                if( o1_list_type == 'DIR' && o2_list_type == 'FILE' ) return -1;
                else if( o1_list_type == 'FILE' && o2_list_type == 'DIR' ) return 1;
            }

            const is_fave = o1.is_favorite;
            const is_fave2 = o2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            // 디렉토리, 파일 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {

                let name = o1_list_type == 'DIR' ? o1.dir_name : o1.file_name;
                let name2 = o2_list_type == 'DIR' ? o2.dir_name : o2.file_name;
                
                // 디렉토리 이름이 같다면
                if( name == name2 ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return name > name2 ? 1 : -1;
                }

                return name < name2 ? 1 : -1;
            }

            // 유저 이름 기준 정렬
            if( this.sort_type == 'USER_NAME' ) {
                
                // 유저 이름이 같다면
                if( o1.user_name == o2.user_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return o1.user_name > o2.user_name ? 1 : -1;
                }

                return o1.user_name < o2.user_name ? 1 : -1;
            }

            if( this.sort_type == 'FILE_COUNT' ) {
                
                const o1_file_size = o1_list_type == 'DIR' ? this.dirFileSizeNumber(o1) : o1.file_size;
                const o2_file_size = o2_list_type == 'DIR' ? this.dirFileSizeNumber(o2) : o2.file_size;

                if( o1_file_size == o2_file_size ) return 0;

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return o1_file_size > o2_file_size ? 1 : -1;
                }

                return o1_file_size < o2_file_size ? 1 : -1;
                
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {
                
                // 날짜가 같다면
                if( new Date(o1.audit_modified).getTime() == new Date(o2.audit_modified).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(o1.audit_modified).getTime() > new Date(o2.audit_modified).getTime() ? 1 : -1;
                }

                return new Date(o1.audit_modified).getTime() < new Date(o2.audit_modified).getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    /**
     * 파일함 디렉토리 트리
     */
    get computedDirTree() : any {

        let copy_dirs = JSON.parse(JSON.stringify(this.all_dirs));

        copy_dirs.sort((o1, o2) => {

            const is_fave = o1.is_favorite;
            const is_fave2 = o2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            const o1_dir_name = o1.dir_name;
            const o2_dir_name = o2.dir_name;

            if( o1_dir_name < o2_dir_name ) return -1;
            else if( o1_dir_name > o2_dir_name ) return 1;

            return 0;
        });

        let max_level = 0;
        for( const dir of copy_dirs ) {
            if( dir.dir_path.length > max_level ) max_level = dir.dir_path.length;
            dir['dirs'] = [];
            dir['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.dir_closed_map.get(dir.dir_uuid);
            if( is_closed != null ) {
                dir['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_dirs = copy_dirs.filter(item => item.dir_path.length == search_level);
            const next_search_dirs = copy_dirs.filter(item => item.dir_path.length == search_next_level); 

            for( const next_search_dir of next_search_dirs ) {
                const next_search_dir_uuid = next_search_dir.dir_uuid;
                next_search_dir.level = search_next_level;

                for( const current_search_dir of current_search_dirs ) {
                    const current_search_dir_uuid = current_search_dir.dir_uuid;
                    current_search_dir.level = search_level;

                    if( current_search_dir.dir_path.indexOf(next_search_dir_uuid) > -1 ) {
                        next_search_dir.dirs.push(current_search_dir);
                    }

                }
            }
            
            search_level--;
        }

        const top_dirs = copy_dirs.filter(item => item.dir_path.length == 1);
        if( top_dirs == null || top_dirs.length < 1 ) return null;

        return top_dirs;
    }

    get computedSearchFiles() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.search_files));

        if( this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list.sort((o1, o2) => {

            const o1_group_type = o1.group_type;
            const o2_group_type = o2.group_type;

            if( o1_group_type != o2_group_type ) {
                if( o1_group_type == 'BIZC' && o2_group_type == 'BIZD' ) return -1;
                else if( o1_group_type == 'BIZD' && o2_group_type == 'BIZC' ) return 1;
            }

            const o1_path_dirs = o1.path_dirs;
            const o2_path_dirs = o2.path_dirs;

            let o1_path_names = ""; 
            let o2_path_names = "";
            
            const o1_path_dirs_length = o1_path_dirs.length;
            for( let i = 0; i < o1_path_dirs_length; i++ ) {
                const o1_path_dir = o1_path_dirs[i];
                if( i > 0 ) o1_path_names += "_";
                o1_path_names += o1_path_dir.dir_name;
            }
            
            const o2_path_dirs_length = o2_path_dirs.length;
            for( let i = 0; i < o2_path_dirs_length; i++ ) {
                const o2_path_dir = o2_path_dirs[i];
                if( i > 0 ) o2_path_names += "_";
                o2_path_names += o2_path_dir.dir_name;
            }

            // 노드, 트리 형식 정렬에 사용하기 좋음
            return o1_path_names.localeCompare(o2_path_names);
        });

        for( const obj of target_list ) {
            obj.list_type = 'DIR';
        }

        // 비밀번호 중간에 걸려있으면 제외시켜야함 && 패스워드 걸린 디렉토리는 디렉토리 이름이 검색된게 아니면 검색 리스트에서 제외되어야함
        target_list = target_list.filter(item => this.isMiddleDirHasPassword(item) == false && this.isPassHasPasswordDirRule(item) == true);

        // 비밀번호 걸린 디렉토리는 파일이 노출되어서는 안됨
        for( const item of target_list ) {
            if( item.has_password == true ) {
                item.files.splice(0, item.files.length);
            }
        }

        return target_list;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupAddFileModalNewInfo ?: (params : GroupAddFileModalNewInfo) => void;
    @ModalInfo.Action doSetGroupFileboxMoveModalInfo ?: (params : GroupFileboxMoveModalInfo) => void;

    /**
     * @GroupInfo.State
     */
    @GroupInfo.State selected_dir_uuid !: string

    /**
     * @GroupInfo.Action
     */
    @GroupInfo.Action doSelectedDirUuid ?: (params : string) => void;
    
    all_dirs : any[] = [];

    dir_uuid : string = "";
    selected_dir : any = null;

    dirs : any[] = [];
    files : any[] = [];

    sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE' = 'DIR_NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    is_card_view : boolean = true;
    is_right_div_open : boolean = true;
    show_fave_only : boolean = false;

    file_search_query : string = "";
    search_files : any[] = [];
    close_search_dir_uuids : string[] = [];

    is_file_drag : boolean = false;

    dir_closed_map : Map<string, boolean> = new Map();

    // beforeMount() {
    //     this.dir_uuid = String(this.$route.query.dir_uuid ?? "");
    // }

    async mounted() {

        this.dir_uuid = this.selected_dir_uuid;

        // //리스트형으로 보기
        // $(".btns .dpTwo").click(function(){
        //     $(".section_ce_fix").addClass("dp2");
        //     $(".section_ce_fix").removeClass("dp1");
        //     $(".btns .dpOne").show();
        //     $(this).hide();
        // });

        // //박스형으로 보기
        // $(".btns .dpOne").click(function(){
        //     $(".section_ce_fix").removeClass("dp2");
        //     $(".section_ce_fix").addClass("dp1");
        //     $(".btns .dpTwo").show();
        //     $(this).hide();
        // });

        // // 즐겨찾기 정렬
        // $(".faveStar").click(function(){
        //     $(this).toggleClass("fave_on");
        // });

        // //메뉴 열기
        // $(".fileMenu").click(function(){
        //     $(this).parent().toggleClass("on");
        // });

        // // 왼쪽 menu 열기
        // $(".closeLeft").click(function(){
        //     $(".section_ce_fix").toggleClass("leftDivClosed");
        //     $(this).toggleClass("on");

        // });

        // // 왼쪽 메뉴 체크
        // $(".fdTreeBox .fd_tree a").click(function(){
        //     $(this).parent().parent().addClass('active').siblings().removeClass();
        // })


        // // 왼쪽 메뉴 dropdown
        // $(".tree_has_child > .mainMore").click(function(){
        //     $(this).parent().siblings().toggleClass("open");
        //     $(this).toggleClass("open");
        // })

        // // 왼쪽 메뉴 dropdown2
        // $(".tree_has_child > .subMore").click(function(){
        //     $(this).parent().siblings().toggleClass("open");
        //     $(this).toggleClass("open");
        // });

        const group_file_box_is_card_view = hodu_cookie.get('group_file_box_is_card_view');
        if( group_file_box_is_card_view != null ) {
            this.is_card_view = group_file_box_is_card_view === 'true';
        }

        const group_file_box_is_right_div_open = hodu_cookie.get('group_file_box_is_right_div_open');
        if( group_file_box_is_right_div_open != null ) {
            this.is_right_div_open = group_file_box_is_right_div_open === 'true';
        }

        await this.getGroupFiles();
        if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
            await this.getFileDir();
        }
        else {
            this.files.splice(0, this.files.length);
        }
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {

            const title_box_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const route_bar_height : number | undefined = $('#route_bar').outerHeight();
            const sort_header_height : number | undefined = this.is_card_view == true ? 0 : $('.sortHeader').outerHeight();
            const left_sort_header_height : number | undefined = $('.leftContent .sortHeader').outerHeight();
            const search_sort_header_height : number | undefined = $('.grp_file_search .sortHeader').outerHeight();

            // @ts-ignore
            $('#filebox_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (route_bar_height ? route_bar_height : 0)
                                            - (sort_header_height ? sort_header_height : 0),
            });

            // @ts-ignore
            $('#filebox_tree').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (route_bar_height ? route_bar_height : 0)
                                            - (left_sort_header_height ? left_sort_header_height : 0),
            });

            // @ts-ignore
            $('#search_filebox_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (search_sort_header_height ? search_sort_header_height : 0),
            });
        
            // const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
            // const right_area_controller_width : number | undefined = $('#right_area_controller').outerWidth();

            // @ts-ignore
            // $('#route_bar').mCustomScrollbar({
            //     axis : 'x',
            //     scrollbarPosition : 'outside',
            //     mouseWheelPixels : 100,
            //     scrollInertia : 60,
            //     autoDraggerLength : false,
            //     setWidth : window.innerWidth - (left_area_width ? left_area_width : 0) 
            //                                  - (right_area_controller_width ? right_area_controller_width : 0),
            // });
        });
        
    }

    /**
     * 그룹 파일함 전체 조회
     */
    async getGroupFiles() : Promise<void> {
        await this.hodu_api_call(`api/v1/groupsweb/getGroupFiles/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                
                this.all_dirs.splice(0, this.all_dirs.length);
                this.all_dirs = this.all_dirs.concat(response.data.data.all_dirs);

                if( this.dir_uuid == null || this.dir_uuid.length < 1 ) {
                    this.dirs.splice(0, this.dirs.length);
                    this.dirs = this.dirs.concat(response.data.data.files);
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    async getFileDir() : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/file/${this.dir_uuid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dir ) {
                throw Error("파일함 조회 중 오류 발생");
            }

            this.selected_dir = JSON.parse(JSON.stringify(response.data.data.dir));

            this.dirs.splice(0, this.dirs.length);
            this.dirs = this.dirs.concat(this.selected_dir.dirs);

            this.files.splice(0, this.files.length);
            this.files = this.files.concat(this.selected_dir.files);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 파일함 추가 모달
     */
    showGroupAddFileBox(event, dir ?: any) : void {

        this.doSetGroupAddFileModalNewInfo?.({
            show_modal : true,
            is_create: dir != null ? false : true,
            dir_uuid: dir != null ? dir.dir_uuid : "",
            group_id: this.scope_group_id,
            up_dir_uuid: this.selected_dir != null ? this.selected_dir.dir_uuid : undefined,
            create_function: async() => { 
                await this.getGroupFiles();
                if( this.dir_uuid != null && this.dir_uuid.length > 0 ) { 
                    await this.getFileDir();
                }
                else {
                    this.files.splice(0, this.files.length);
                }
            },
            update_function: async(is_delete : boolean, dir_uuid : string) => {
                await this.getGroupFiles();
                if( this.dir_uuid != null && this.dir_uuid.length > 0 ) { 
                    await this.getFileDir();
                }
                else {
                    this.files.splice(0, this.files.length);
                }
            }
        });
    }

    isEditable(obj) {
        if( obj == null ) return false;
        const is_group_manager = (this.get_group_role(this.scope_group_id) == 'GROUP_MANAGER' || this.get_group_role(this.scope_group_id) == 'ADMIN');
        return is_group_manager || obj.audit_user_id == this.user_id;
    }

    dirFileSize(dir) {

        let byte = 0;

        if( dir.path_files == null ) return `0 Bytes`;

        for( const path_file of dir.path_files ) byte += path_file.file_size;
          
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]; 
    }

    dirFileSizeNumber(dir) {
        let byte = 0;
        for( const path_file of dir.path_files ) byte += path_file.file_size;
        return byte; 
    }

    /**
     * BYTE TO SIZE
     */
    byteToSize(byte, decimals = 2) : string {
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];        
    }

    /**
     * 날짜 시간 변환
     */
    dateToString(date, format) : string {
        let returnVal = dateFormat(date, format);
        if ( format == "HH:MM" ) {
            const hour : number = Number(returnVal.substring(0,2));
            const min  : string = returnVal.substring(3,5);
            if( hour > 12 ) {
                returnVal = `오후 ${ `0${hour - 12}`.slice(-2) }:${min}`;
            }

            else if( hour == 12 ) {
                returnVal = `오후 12:${min}`;
            }

            else if( hour == 0 ) {
                returnVal = `오전 12:${min}`;
            }

            else {
                returnVal = `오전 ${ `0${hour}`.slice(-2) }:${min}`;
            }
        }

        return returnVal;
    }

    /**
     * 확장자 뽑기
     */
    getExtensionOfFilename(filename) : string {
        var _fileLen = filename.length;
        var _lastDot = filename.lastIndexOf('.');
    
        // 확장자 명만 추출한 후 소문자로 변경
        var _fileExt = filename.substring(_lastDot, _fileLen).toLowerCase();
 
        if ( _fileExt == ".png" || _fileExt == ".jpg" || _fileExt == ".jpeg" || _fileExt == ".bmp" || _fileExt == ".gif" ) {
            return "img";
        }
        else {
            return _fileExt.replace(".", "");
        }
    }

    /**
     * 카드 뷰 모드 ON / OFF
     */
    cardViewModeOnOff() : void {
        this.is_card_view = !this.is_card_view;

        hodu_cookie.set('group_file_box_is_card_view', String(this.is_card_view));
        
        this.$nextTick(() => this.handleResize());
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    async selectFileBox(dir_uuid : string, is_route : boolean, complete_password_input : boolean = false) {

        // console.log(`selectFileBox : ${dir_uuid}, ${is_route}, ${complete_password_input}`);
        
        // 비밀번호 걸린 디렉토리 찾기용
        if( is_route == false && dir_uuid != null && dir_uuid.length > 0 ) {
            
            let dir = null;
            let has_password : boolean = false;

            for( const dir_or_file of this.all_dirs ) {
                if( dir_or_file.list_type == 'FILE' ) continue;
                if( dir_or_file.dir_uuid == dir_uuid ) {
                    has_password = dir_or_file.has_password;
                    dir = dir_or_file;
                    break;
                }
            }

            if( has_password == true && complete_password_input == false ) {
                this.password_input(dir, 'select');
                return;
            }

        }

        this.file_search_query = "";

        this.dir_uuid = dir_uuid;
        this.doSelectedDirUuid?.(this.dir_uuid);

        await this.getGroupFiles();
        if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
            await this.getFileDir();
        }
        else {
            this.selected_dir = null;
            this.files.splice(0, this.files.length);
        }

    }

    /**
     * 파일함 즐겨찾기 ON / OFF
     */
    async insertFileOrDirFavorite(group_id : number, obj : any, is_root : boolean) {

        try {
            
            let response : any = null;

            if( obj.list_type == 'DIR' ) {
                response = await this.hodu_api_call(`/api/v1/fileDirFavorite/${group_id}/${obj.dir_uuid}?is_root=${is_root}`, API_METHOD.POST);
            }
            else {
                response = await this.hodu_api_call(`/api/v1/fileFavorite/${obj.file_uuid}/${obj.dir_uuid}?is_root=${is_root}`, API_METHOD.POST);
            }

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("파일함 즐겨찾기 ON / OFF 중 오류 발생");
            }

            // 재조회
            await this.getGroupFiles();
            if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                await this.getFileDir();
            }
            else {
                this.files.splice(0, this.files.length);
            }

            if( this.file_search_query != null && this.file_search_query.length > 0 ) {
                this.watchFileSearchQuery();
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일함 즐겨찾기 설정 중 오류 발생", ['확인']);
        }
    }

    menuOpen(obj) {

        let before_menu_open : boolean = obj.menu_open;

        if( this.dirs.length > 0 ) {
            for( const dir of this.dirs ) {
                dir.menu_open = false;
                if( obj.list_type == 'DIR' && obj.dir_uuid == dir.dir_uuid ) {
                    dir.menu_open = (before_menu_open == true ? false : true);
                }
            }
        }
        

        if( this.files.length > 0 ) {
            for( const file of this.files ) {
                file.menu_open = false;
                if( obj.list_type == 'FILE' && obj.file_uuid == file.file_uuid ) {
                    file.menu_open = (before_menu_open == true ? false : true);
                }
            }
        }        

        this.$forceUpdate();
    }

    async delete_password(dir) {

        try {

            const delete_response = await this.hodu_api_call(`api/v1/groups/${dir.group_id}/file/${dir.dir_uuid}/password`, API_METHOD.DELETE);

            console.log(delete_response);

            if( !delete_response || !this.isHttpStatusSuccess(delete_response.status) ) {
                throw new Error("파일함 비밀번호 삭제 중 오류 발생");
            }
            
            await this.getGroupFiles();
            if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                await this.getFileDir();
            }
            else {
                this.files.splice(0, this.files.length);
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일함 비밀번호 삭제 중 오류 발생", ['확인']);
        }

    }

    fileDelete(file_uuid) {
        this.hodu_show_dialog('cancel', "정말 파일을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.hodu_api_call(`api/v1/groupsweb/deleteGroupFile/${file_uuid}/${this.scope_group_team_option.biz_id}`, API_METHOD.DELETE, {})
                    .then(async(response) => {
                        await this.getGroupFiles();
                        if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                            await this.getFileDir();
                        }
                        else {
                            this.files.splice(0, this.files.length);
                        }
                    })
                    .catch(async(e) => {
                        this.hodu_error_process(e, true, false);
                    });
            }
        ])
    }

    fileDownLoad(ps_path, ps_file_name) : void { 
        this.hodu_download(`/app_images/group_file/${ps_path}`, ps_file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 배경화면 반환
     */
    getBackgroundImage(obj : any) : string {
        if( new RegExp(escape('image')).test(escape(obj.file_type)) == false ) {
            return 'none';
        }

        return obj.file_path.indexOf('patient_file') > -1 ? `url(app_images/${obj.file_path})` : `url(app_images/group_file/${obj.file_path})`;
    }

    /**
     * 파일 들고 들어 온 경우
     */
    fileDragOver(event : any) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.is_file_drag = true;
    }
    
    /**
     * 파일 나간 경우
     */
    fileDragLeave(event : any) : void {
        this.is_file_drag = false;
    }
    
    /**
     * 파일 드랍
     */
    fileDrop(event : any) : void {
        this.is_file_drag = false;
        event.target.files = event.dataTransfer.files;
        this.addFile(event);
    }

    /**
     * 파일 추가
     */
    async addFile(event) : Promise<void> {
        
        if ( this.dir_uuid == "" ) {
            $('#addFile').val('');
            this.hodu_show_dialog("alert", "파일함을 생성해주세요", ['확인']);
            return;
        }

        const files : File[] = event.target.files;

        if ( files.length < 1 ) {
            $('#addFile').val('');
            return;
        }

        const files_length : number   = files.length;
        const form_data    : FormData = new FormData();

        for ( let i = 0; i < files_length; i++ ) {
            if( files[i].size > this.DEFAULT_FILE_MAX_SIZE ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", `${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`, ['확인']);
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", "확장자가 있는 파일만 업로드 가능 합니다", ['확인']);
                return;
            } 

            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", `${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`, ['확인']);
                return;
            }

            let file_data = {
                name: files[i].name,
                mimeType: files[i].type,
                url: '',
                size: files[i].size,
                date: new Date(),
            }

            form_data.append('file', files[i]);
        }

        await this.hodu_temp_upload(form_data)
            .then(async(response) => {
                const temp_files : t_event_file[] = response.data.data.temp_files;
                await this.groupFileSave(temp_files, false);
            })
            .catch(async(e) => {
                $('#addFile').val('');
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 파일 저장
     */
    async groupFileSave(po_files : t_event_file[], pb_overLap : boolean) : Promise<void> {
        const vue = this;
        let param_data = {file: po_files}
        
        await this.hodu_api_call(`api/v1/groupsweb/setGroupFile/${this.scope_group_id}/${this.scope_group_team_option.biz_id}/${this.dir_uuid}/${pb_overLap}`, API_METHOD.POST, param_data)
            .then(async(response) => {
                if ( response.data.data.overlap_files.length < 1 ) {
                    await this.getGroupFiles();
                    if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                        await this.getFileDir();
                    }
                    else {
                        this.files.splice(0, this.files.length);
                    }
                }
                else {
                    this.overlapFileSave(response.data.data.overlap_files);
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        $('#addFile').val('');
    }

    /**
     * 중복된 파일에 한해서 파일 저장
     */
    async overlapFileSave(po_files : t_event_file[]) : Promise<void> {
        if ( po_files.length < 1 ) {
            await this.getGroupFiles();
            if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                await this.getFileDir();
            }
            else {
                this.files.splice(0, this.files.length);
            }
            return;
        }

        let upload_files : t_event_file[] = [];

        for ( let i = 0; i < po_files.length; i++ ) {
            /*
            this.hodu_show_dialog("alert", po_files[i].name + " 중복된 파일입니다. \n\n파일을 덮어씌우시겠습니까?", ['아니오', '예'], [
                () => {},
                () => {
                    upload_files.push(po_files[i]);
                },
            ]);
            */

            if ( confirm(po_files[i].name + "\n동일한 파일명이 존재합니다.\n파일을 덮어씌우시겠습니까?") ) {
                upload_files.push(po_files[i]);
            }
        }

        if ( upload_files.length > 0 ) {
            this.groupFileSave(upload_files, true);
        }
        else {
            await this.getGroupFiles();
            if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                await this.getFileDir();
            }
            else {
                this.files.splice(0, this.files.length);
            }
        }
    }

    /**
     * 폴더 토글
     */
    toggleLeftDir(dir : any, complete_password_input : boolean = false) : void {

        // console.log(`toggleLeftDir : ${dir}, ${complete_password_input}`);

        if( dir.is_closed == true && dir.has_password == true && complete_password_input == false ) {
            this.password_input(dir, 'toggle');
            return;
        }

        dir.is_closed = !dir.is_closed;
        this.$forceUpdate();
        this.dir_closed_map.set(dir.dir_uuid, dir.is_closed);
    }

    /**
     * 디렉토리 비밀번호 입력
     */
    password_input(dir : any, action : string) {

        let uuid : string = dir.dir_uuid;

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }
                    
                    switch( action ) {
                        case 'select':
                            this.selectFileBox(dir.dir_uuid, false, true);
                            break;

                        case 'toggle':
                            this.toggleLeftDir(dir, true);
                            break;

                        case 'delete':
                            this.delete_password(dir);
                            break;
                    }

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });
    }

    /**
     * 오른쪽 div 닫기 / 열기
     */
    dirTreeOnOff() : void {
        this.is_right_div_open = !this.is_right_div_open; 
        hodu_cookie.set('group_file_box_is_right_div_open', String(this.is_right_div_open));
    }

    /**
     * 파일 검색 쿼리 변경 감지
     */
    @Debounce(250)
    @Watch('file_search_query')
    async watchFileSearchQuery() : Promise<void> {

        this.close_search_dir_uuids.splice(0, length);

        if( this.file_search_query.length < 1 ) {
            this.search_files.splice(0, this.search_files.length);
            return;
        }

        try {
            const response = await this.hodu_api_call(`api/v1/file?search_query=${this.file_search_query}&group_id=${this.scope_group_id}`, API_METHOD.GET, null, false);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.groups ) {
                throw new Error("검색 중 오류 발생");
            }

            this.search_files.splice(0, this.search_files.length);
            this.search_files = this.search_files.concat(response.data.data.groups);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        this.$nextTick(() => this.handleResize()); 
    }

    /**
     * 그룹 파일함 검색 리스트 폴더 열기
     */
    searchFileBoxOnOff(dir) : void {
        
        if( dir == null ) return;

        let dir_uuid = dir.group_type == 'BIZC' ? dir.dir_uuid : dir.biz_id;

        let index = this.close_search_dir_uuids.indexOf(dir_uuid);
        if( index < 0 ) {
            this.close_search_dir_uuids.push(dir_uuid);
        }
        else {
            this.close_search_dir_uuids.splice(index, 1);
        }

    }

    getGrpHtml(dir) {

        // <i class="clr dc6"></i>
        // 비밀번호 파일 pswFolder 클래스 추가
        // <span>{{ dir.group_name + `${ dir.group_type == 'BIZC' ? " / " : "" }` }} </span> 
        // <span :class="{ pswFolder : dir.has_password == true }" v-if="dir.group_type == 'BIZC'">
        //     <i class="ic-password">비밀번호 여부</i>
        //     {{ dir.dir_name }}
        //     <mark>호두C</mark>
        // </span>

        if( dir == null ) return '';

        let html = `<i class="clr ${this.hodu_color_dc_lc(dir.group_color)}"></i>`;
        html += `<span>${ dir.group_name }</span>`;

        if( dir.group_type != 'BIZC' || dir.path_dirs == null || dir.path_dirs.length < 1 ) {
            return html;
        }

        const SHOW_DIR_TREE_MAX_COUNT = 4;

        const dir_count = dir.path_dirs.length;
        for( let i = 0; i < dir_count; i++ ) {
            const path_dir = dir.path_dirs[i];

            if( dir_count > SHOW_DIR_TREE_MAX_COUNT ) {
                if( (SHOW_DIR_TREE_MAX_COUNT / 2) - 1 < i && i < dir_count - (SHOW_DIR_TREE_MAX_COUNT / 2) ) {
                    if( i == dir_count - (SHOW_DIR_TREE_MAX_COUNT / 2) - 1 ) html += ` / ··· `;
                    continue;
                }
            }

            const mark_text = path_dir.dir_name.replaceAll(this.file_search_query, `<mark>${this.file_search_query}</mark>`);

            html += ` / <span class="${ path_dir.has_password == true ? "pswFolder" : "" }"><i class="ic-password">비밀번호 여부</i>${mark_text}</span>`;
        }

        return html;
    }

    getFileGrpHtml(file) {

        if( file == null ) return '';

        return file.file_name.replaceAll(this.file_search_query, `<mark>${this.file_search_query}</mark>`)

    }

    isMiddleDirHasPassword(dir) {

        if( dir == null || dir.path_dirs == null || dir.path_dirs.length <= 1 ) return false; 

        let is_middle_dir_has_password : boolean = false;

        let count = dir.path_dirs.length;

        for( let i = 0; i < (count - 1); i++ ) {
            const path_dir = dir.path_dirs[i];

            if( path_dir.has_password == true ) {
                is_middle_dir_has_password = true;
                break;
            }
        }

        return is_middle_dir_has_password;
    }

    isPassHasPasswordDirRule(dir) {

        if( dir == null ) return false;
        if( dir.has_password == false ) return true;

        let is_pass_has_password_dir_rule : boolean = new RegExp(this.file_search_query).test(dir.dir_name);

        return is_pass_has_password_dir_rule;
    }

    /**
     * 파일 이동
     */
    fileMove(file) {
        this.doSetGroupFileboxMoveModalInfo?.({
            show_modal : true,
            type : "FILE",
            file_uuid : file.file_uuid,
            callback : async() => {
                await this.getGroupFiles();
                if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                    await this.getFileDir();
                }
                else {
                    this.files.splice(0, this.files.length);
                }
            },
        })
    }

    /**
     * 디렉토리 이동
     */
     dirMove(dir) {
        this.doSetGroupFileboxMoveModalInfo?.({
            show_modal : true,
            type : "DIR",
            dir_uuid : dir.dir_uuid,
            callback : async() => {
                await this.getGroupFiles();
                if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                    await this.getFileDir();
                }
                else {
                    this.files.splice(0, this.files.length);
                }
            },
        })
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        // @ts-ignore
        $('#filebox_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#filebox_tree').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#search_filebox_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

    // moveFileBox() : void {
    //     this.hodu_router_push(`/filebox?${new Date().getTime()}`);
    // }

    // moveGroupFileBox() : void {
    //     this.hodu_router_push(`/group/filebox?${new Date().getTime()}`);
    // }

    // moveGroupFileList() : void {
    //     this.hodu_router_push(`/group/filebox/dir_id?${new Date().getTime()}`);
    // }

}
</script>

<style>
    #group_file_box_new .fdTreeBox .fdTreeUl li { position: relative; line-height: 60px; box-sizing: border-box; cursor: pointer; }
    #group_file_box_new .fdTreeBox .fdTreeUl li.active:after { content: ''; width: 2px; height: 60px; display: inline-block; background: #477fff; position: absolute; top: 0; right: 0; }
    #group_file_box_new .fdTreeBox .fdTreeUl li a { display: block; font-size: 14px; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    #group_file_box_new .fdTreeBox .fdTreeUl li button { display: none; font-size: 0; width: 40px; height: 40px; border-radius: 50%; background-image: url('../assets/images/side_area/bt_open.png'); background-repeat: no-repeat; background-position: center center; position: absolute; }
    #group_file_box_new .fdTreeBox .fdTreeUl li .tree_has_child button { display: block; }
    #group_file_box_new .fdTreeBox .fdTreeUl li button.mainMore { float: right; top: 10px; right: 10px; }
    #group_file_box_new .fdTreeBox .fdTreeUl li button.mainMore:hover { background-color: #f1f3f5; }
    #group_file_box_new .fdTreeBox .fdTreeUl li button.mainMore.open { background-image: url('../assets/images/side_area/bt_close.png'); }
    #group_file_box_new .fdTreeBox .fdTreeUl li button.subMore { top: 15px; margin-left: -30px; width: 30px; height: 30px; transform: rotate( -90deg ); }
    #group_file_box_new .fdTreeBox .fdTreeUl li button.subMore.open { background-image: url('../assets/images/side_area/bt_close.png'); transform: rotate( 0deg ); }
    #group_file_box_new .fdTreeBox .fdTreeUl li button.subMore:hover { background-image: url('../assets/images/side_area/bt_open_b.png'); }

    #group_file_box_new .fdTreeBox .fdTreeUl .fdSubUl { display: none; }
    #group_file_box_new .fdTreeBox .fdTreeUl .fdSubUl.open { display: block; }
    #group_file_box_new .fdTreeBox .fdTreeUl .fd_tree { border-bottom: 1px solid #e7e9ea; padding: 0 40px 0 30px; position: relative; transition: 0.1s; }
    #group_file_box_new .fdTreeBox .fdTreeUl .fd_tree:hover { background: #f7f9fb; }

    #group_file_box_new .grfolderUl li .grp .clr { display: inline-block; width: 8px; height: 8px; margin-right: 10px; border-radius: 50%; }
    #group_file_box_new .group_file.grp_file_search .pswFolder .ic-password { display: inline-block; }
    #group_file_box_new.section_ce_fix .grp .ic-password { display: none; width: 15px; height: 15px; font-size: 0; background: url('../assets/images/folder_file/ic_password_232848.png') no-repeat center center; background-size: 9px; vertical-align: middle; margin-top: -2px; }
    #group_file_box_new.section_ce_fix mark { font-style: normal; background: none; color: #477fff;}
</style>

<style scoped>
    #group_file_box_new .schedule_box { /* position: static !important; */ /* padding: 0; */ /* max-width: 100%; */ }
    button { border: none; outline: none; background-color: inherit ; cursor: pointer; }

    .left_area_close .settingBg { padding-left: 61px; }
    .settingBg { height: 80px; position: relative; border-bottom: 1px solid #e7e9ea; }
    .settingBg > div { display: inline-block; vertical-align: middle; }
    .settingBg .btns_left { margin: 0 10px 0 20px; }
    .settingBg .schDiv { position: relative; padding: 15px; box-sizing: border-box; width: 400px; display: inline-block; margin-left: 15px; }
    .settingBg .schDiv input[type="text"] { width: 100%; height: 50px; line-height: 50px; padding: 0 15px; border-radius: 5px; border: 1px solid #e7e9ea; background: #f1f3f5; font-weight: bold; box-sizing: border-box; transition: .2s; }
    .settingBg .schDiv input[type="text"]:hover { border: 1px solid #477fff; }
    .settingBg .schDiv input[type="text"]:focus { background: #fff; border-color: #477fff; }
    .section_ce_fix.fileSrcOn .settingBg .schDiv .schBtn { display: none; }
    .settingBg .schDiv .schBtn { position: absolute ; right:20px;top:20px; }
    .settingBg select { cursor:pointer; transition:0.2s; margin-top: 20px; height: 35px; line-height: 31px; border:1px solid #232848; appearance: none; padding: 0 40px 0 20px; border-radius: 20px; font-weight: bold; font-size: 14px;background: url('../assets/images/side_area/bt_open.png') no-repeat 90% center; float: right; margin-right: 20px; }
    .settingBg select:hover { background-color:#f1f3f5; }
    .settingBg select:focus { border-color:#477fff; background-color:#fff;background-image: url('../assets/images/side_area/bt_close.png'); }
    .settingBg .btns { float: right; margin: 18px 20px 0 0; }
    .settingBg .folderAddBtn { float: right; margin: 18px 20px 0 0; }
    .settingBg .btns_left .schBtn.closeLeft { position: static; background: url('../assets/images/contents/bt_hambuger_new.png') no-repeat center; }
    .settingBg .schDiv .schBtnClear { display: none; position: absolute; top: 28px; right: 28px; width: 25px; height: 25px; background: url('../assets/images/contents/ic_attnd_no_dc7.png') center center no-repeat; background-size: 60%; border: 1px solid #477fff; border-radius: 50%; outline: none; cursor: pointer; }
    .section_ce_fix.fileSrcOn .settingBg .schDiv .schBtnClear { display: block; }

    /* 이동경로 표시 */
    .left_area_close .deep_route { padding-left: 61px; box-sizing: border-box; }
    .deep_route { width: 100%; height: 50px; background: #fff; border-bottom: 1px solid #e7e9ea; }
    .deep_route .routeUl { /* width: 100%; */ padding: 0 25px; box-sizing: border-box; height: 50px; line-height: 50px; /* display: flex; */ overflow-y: hidden; overflow-x: auto; white-space: nowrap; }
    .deep_route .routeUl li { /* max-width: 190px; */ height: 50px; line-height: 50px; /* float: left; */ position: relative; padding-right: 35px; box-sizing: border-box; cursor: pointer; display : inline-block; }
    .deep_route .routeUl li:first-child { width: 58px; cursor: default; }
    .deep_route .routeUl li:before { content: ''; width: 7px; height: 7px; border-right: 1px solid #85889a; border-bottom: 1px solid #85889a; position: absolute; right: 18px; transform: rotate(-45deg); top: 21px; }
    
    .deep_route .routeUl li > a { line-height: 20px; vertical-align: middle; }
    .deep_route .routeUl li > a:hover { text-decoration: underline; }
    .deep_route .routeUl li.fixd_route { font-weight: bold; }
    .deep_route .routeUl li.fixd_route:before { display: none; }
    .routeUl .ic_file { width: 20px; height: 50px; display: inline-block; font-size: 0; background: url('../assets/images/menucons/ic_file_sm_dc9.png') no-repeat center center; background-size: 17px auto; vertical-align: top; }

    /* content */
    .content { position: relative; overflow: hidden; height: 100%; }
    .content .noResult { display:none; position: static; padding: 20px 30px; }
    .content .noResult.on { display:block; }
    /* .groupRightDiv .noResult p { background: #f1f3f5; height: 80px; line-height: 80px; font-size : 14px; } */
    .content .noResult p { background: #f1f3f5; height: 80px; line-height: 80px; font-size : 14px; }

    .section_ce_fix.dp2 .sortHeader { display: none; }
    .section_ce_fix.dp2 .groupLeftDiv .sortHeader { display: block; }
    .section_ce_fix.dp1 .addFolderList { display:none; }

    .faveStar { cursor: pointer; position: absolute; z-index: 10000000; }
    .faveStar .ic_faveStar { width: 40px; height: 40px; display: inline-block; font-size: 0; vertical-align: middle; margin-top: -2px; }
    .faveStar .faveSort { background: url('../assets/images/contents/ic_fave_off.png'); background-repeat: no-repeat; background-size: 23px; background-position: center center; }
    .faveStar.fave_on .faveSort { background-image: url('../assets/images/contents/ic_fave_on.png'); }
    .faveStar .faveSelect { background: url('../assets/images/contents/faveStar_off.png'); background-repeat: no-repeat; background-size: 23px; background-position: center center; }
    .faveStar.fave_on .faveSelect { background-image: url('../assets/images/contents/faveStar.png');}

    .section_ce_fix.leftDivClosed .groupLeftDiv { width: 0; }
    .section_ce_fix.leftDivClosed .leftContent { display: none; }
    .section_ce_fix.leftDivClosed .groupRightDiv { padding-left: 0; }

    /* 비밀번호 */
    .section_ce_fix .grfolderUl.group  .grp .ic-password { display: none; width: 30px; height: 30px; font-size: 0; background: url('../assets/images/folder_file/ic_password_fff.png') no-repeat center center; position: absolute; top: 90px; left: 100px; background-size: 20px; z-index: 9999; }
    .section_ce_fix.dp1 .grfolderUl.group  .grp .ic-password { display: none; width: 15px; height: 15px; font-size: 0; background: url('../assets/images/folder_file/ic_password_232848.png') no-repeat center center; background-size: 9px; position: static; vertical-align: middle; margin-top: -2px; }
    .section_ce_fix .grfolderUl.group li.pswFolder .grp .ic-password { display: inline-block; }
    .grfolderUl li .fileMenuOp li .pswDel { display: none; }
    .grfolderUl li.pswFolder .fileMenuOp li .pswDel { display: block; }

    /* left 폴더 트리 */
    .left_area_close .groupLeftDiv { left: 61px; }
    .groupLeftDiv { transition: 0.1s; position: absolute; width: 340px; left: 0; top: 0; box-sizing: border-box; border-right: 1px solid #e7e9ea; height: 100%; }
    .groupLeftDiv .sortHeader > li { width: 100%; padding-left: 30px; box-sizing: border-box; }

    .fdTreeBox .fdTreeUl li { position: relative; line-height: 60px; box-sizing: border-box; cursor: pointer; }
    .fdTreeBox .fdTreeUl li.active:after { content: ''; width: 2px; height: 60px; display: inline-block; background: #477fff; position: absolute; top: 0; right: 0; }
    .fdTreeBox .fdTreeUl li a { display: block; font-size: 14px; font-weight: bold; }
    .fdTreeBox .fdTreeUl li button { display: none; font-size: 0; width: 40px; height: 40px; border-radius: 50%; background-image: url('../assets/images/side_area/bt_open.png'); background-repeat: no-repeat; background-position: center center; position: absolute; }
    .fdTreeBox .fdTreeUl li .tree_has_child button { display: block; }
    .fdTreeBox .fdTreeUl li button.mainMore { float: right; top: 10px; right: 10px; }
    .fdTreeBox .fdTreeUl li button.mainMore:hover { background-color: #f1f3f5; }
    .fdTreeBox .fdTreeUl li button.mainMore.open { background-image: url('../assets/images/side_area/bt_close.png'); }
    .fdTreeBox .fdTreeUl li button.subMore { top: 15px; margin-left: -30px; width: 30px; height: 30px; transform: rotate( -90deg ); }
    .fdTreeBox .fdTreeUl li button.subMore.open { background-image: url('../assets/images/side_area/bt_close.png'); transform: rotate( 0deg ); }
    .fdTreeBox .fdTreeUl li button.subMore:hover { background-image: url('../assets/images/side_area/bt_open_b.png'); }

    .fdTreeBox .fdTreeUl .fdSubUl { display: none; }
    .fdTreeBox .fdTreeUl .fdSubUl.open { display: block; }
    .fdTreeBox .fdTreeUl .fd_tree { border-bottom: 1px solid #e7e9ea; padding: 0 60px 0 30px; position: relative; transition: 0.1s; }
    .fdTreeBox .fdTreeUl .fd_tree:hover { background: #f7f9fb; }
    /* .fdTreeBox .fdTreeUl li .fdSubUl .fd_tree { padding-left: 50px; }
    .fdTreeBox .fdTreeUl li .fdSubUl.fdSub_2depth .fd_tree { padding-left: 65px; }
    .fdTreeBox .fdTreeUl li .fdSubUl.fdSub_3depth .fd_tree { padding-left: 80px; } */

    /* right 폴더 리스트 */
    .section_ce_fix.dp1 .h4Div { display: none; }
    .groupRightDiv { float: left; width: 100%; box-sizing: border-box; padding-left: 340px; }    
    
    /* .grfolderUl li { text-align: center; position: relative; float: left; display: inline-block; width: 180px; height: 200px; margin: 30px 0 0 30px; -webkit-box-sizing: border-box; box-sizing: border-box; border: 1px solid #e7e9ea; border-radius: 5px; }
    .grfolderUl li:hover { background: #f7f9fb; }
    .grfolderUl li a { position: absolute; display: inline-block; height: 100%; left: 0; top: 0; width: 100%; text-align: center; font-size: 0; }
    .grfolderUl li a.fileMenu { display: none; right: 5px; top: 16px; width: 30px; height: 30px; left: auto; }
    .grfolderUl li.writerCrt a.fileMenu { display: inline-block; }
    .grfolderUl li .grp { font-size: 14px; height: 35px; line-height: 45px; padding: 0 25px; box-sizing: border-box; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .grfolderUl li .size { height: 20px; line-height: 25px; }
    .fd .total { color: #fff; }
    .grfolderUl li .fileMenuOp { display: none; position: absolute; top: 42px; right: 20px; width: 120px; height: auto; box-sizing: border-box; overflow: hidden; background: #fff; box-shadow: 0px 5px 10px rgb(0 0 0 / 10%); border-radius: 5px; transition: .2s; z-index: 9999; }
    .grfolderUl li.on .fileMenuOp { display: block; } */

    .grfolderUl li { text-align: center; position: relative; float: left; display: inline-block; width: 180px; height: 200px; margin: 30px 0 0 30px; -webkit-box-sizing: border-box; box-sizing: border-box; border: 1px solid #e7e9ea; border-radius: 5px; }
    .grfolderUl li:hover { background: #f7f9fb; }
    .grfolderUl li a { position: absolute; display: inline-block; height: 100%; left: 0; top: 0; width: 100%; text-align: center; font-size: 0; }
    .grfolderUl li a.fileMenu { display: none; right: 5px; top: 16px; width: 30px; height: 30px; left: auto; }
    .grfolderUl li.writerCrt a.fileMenu { display: inline-block; }
    .grfolderUl li .fileName { font-size: 14px; height: 35px; line-height: 45px; padding: 0 35px; box-sizing: border-box; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .grfolderUl li .size { height: 20px; line-height: 25px; }
    .fd .total { color: #fff; }
    .grfolderUl li .fileMenuOp { display: none; position: absolute; top: 42px; right: 20px; width: 120px; height: auto; box-sizing: border-box; overflow: hidden; background: #fff; box-shadow: 0px 5px 10px rgb(0 0 0 / 10%); border-radius: 5px; transition: .2s; z-index: 9999; }
    .grfolderUl li.on .fileMenuOp { display: block; }
    .grfolderUl li.on .fileUplodOp { display: none; }
    .grfolderUl li.fileUpLi.on .fileMenuOp { display: none; }
    .grfolderUl li.fileUpLi.on .fileUplodOp { display: block; }
    .grfolderUl li.fileUpLi .total { display: none; }
    /* .grfolderUl li.fileUpLi .goDets { display: none; } */
    

    .groupRightDiv .h4Div { overflow: hidden; margin: 30px 30px 0 30px; font-size: 16px; margin-bottom: -5px; }
    .groupRightDiv .h4Div h4, .groupRightDiv .h4Div p { display: inline-block; margin-right: 5px; font-weight: bold; }
    .grfolderUl.group { overflow: hidden; box-sizing: border-box; padding-right: 30px; padding-bottom: 40px; }
    .fileHead { font-size: 12px; height: 25px; font-weight: bold; }
    .grfolderUl li .fileMenuOp li { text-align: left; width: 100%; height: auto; margin: 0; border: none; }

    .section_ce_fix.dp1 .grfolderUl { padding-right: 0; padding-bottom: 0; border-bottom: 0 none; padding-bottom: 120px; }
    .section_ce_fix.dp1 .faveStar { width: 5%; margin-left: 12px !important; }
    .section_ce_fix.dp1.leftDivClosed .faveStar { margin-left: 22px !important; }
    .section_ce_fix.dp1 .grp { width: 40%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-left: 8% !important; }
    .section_ce_fix.dp1 .fileHead { width: 15%; height: 60px; line-height: 60px; }
    .section_ce_fix.dp1 .lastUpdated { width: 20%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .section_ce_fix.dp1 .grfolderUl li { width: 100%; margin: 0; border-radius: 0; border: none; height: 66px; line-height: 66px; border-bottom: 1px solid #f1f3f5; transition: 0.2s; }
    .section_ce_fix.dp1 .grfolderUl li:hover { background: #f7f9fb; }
    .section_ce_fix.dp1 .grfolderUl p { float: left; text-align: left; font-size: 12px; height: 66px; line-height: 66px; padding-left: 0; font-weight: bold; }
    .section_ce_fix.dp1 .grfolderUl .grp { padding-left: 63px; font-size: 13px; }
    .section_ce_fix.dp1 .grfolderUl li.fileUpLi .fileName { padding-left: 75px; }
    .section_ce_fix.dp1 .grfolderUl p.fd { position: absolute; left: 8.3%; padding-left: 0; padding-left: 0; width: 50px; background-position: left center; background-size: 41px 30px; }
    .section_ce_fix.dp1 .fd span.total { display: inline-block; width: 35px; line-height: 69px; text-align: center !important; }

    .section_ce_fix.dp1 .size { width: 14%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

    .section_ce_fix.dp1 .group_file > .group_file_scroll ul > li .preview { height: 40px; line-height: 40px; text-align: center; }
    .section_ce_fix.dp2 .group_file > .group_file_scroll ul > li .preview { position: relative; display: inline-block; width: 50%; height: 55px; line-height: 55px; margin: 20px 0; left: 0; top: 0; font-weight: bold; }
    .section_ce_fix.dp2 .group_file > .group_file_scroll ul > li:hover .preview::after { background-position: center 13px; }

    .group_file > .group_file_scroll ul > li .preview { position: absolute; left: 8%; background: #fff; border-radius: 5px; height: 40px; font-size: 11px; line-height: 40px; text-transform: uppercase; text-align: center; width: 60px; color: #477fff; top: 12px; padding-right: 0; border: 1px solid #e7e9ea; box-shadow:none; }
    .group_file > .group_file_scroll ul > li .preview.img { transition: 0.1s; font-size:0; background-size:cover; background-position:center center; }
	.group_file > .group_file_scroll ul > li:hover .preview { box-shadow: 0 5px 10px rgba(0,0,0,0.2); border: none; }
	.group_file > .group_file_scroll ul > li:hover .preview::after { content:''; z-index:10; position:absolute; top:0; left:0; width:100%; height:100%; display:block; background: rgba(71, 127, 255, 0.2) url('../assets/images/contents/ic_ddFile.png') no-repeat center 5px; cursor: pointer; }
    .group_file > .group_file_scroll ul > li .preview.img { background-image: url('../assets/images/contents/imgDc8.png')}
    
    .section_ce_fix .group_file.grp_file_search { display: none; }
    .section_ce_fix.fileSrcOn .groupLeftDiv { display: none; }
    .section_ce_fix.fileSrcOn .groupRightDiv { display: none; }
    .section_ce_fix.fileSrcOn #route_bar { display: none; }
    .section_ce_fix.fileSrcOn .group_file.grp_file_search { display: block; }
    
    .section_ce_fix mark { font-style: normal; background: none; color: #477fff;}
    .group_file.grp_file_search .pswFolder .ic-password { display: inline-block; }

    #route_bar ::-webkit-scrollbar{ height : 3px;}
    #route_bar ::-webkit-scrollbar-track{background-color: transparent;}
    #route_bar ::-webkit-scrollbar-thumb{background-color: rgb(228, 226, 229);transition: 0.5s;}

    .bg4file {display:none;position: absolute;width: 100%;vertical-align: middle;height: 100%;top: 0;line-height: 100%;left: 0; background: rgba(255, 255, 255, 0.3);box-sizing: border-box;z-index: 100000000;padding: 60px;}
	.bg4file.on {display: block;}
    .bg4file span {width: 100%;height: 100%;display: inline-block;transition: 0.1s;border-radius: 5px;background: rgba(71, 127, 255, 0.1) url(../assets/images/contents/img_dragDrop.png) no-repeat center center;background-size: 190px;border: 2px dashed #477fff;color: #477fff;font-weight: bold;font-size: 0;vertical-align: middle;text-align: center;}
	.bg4file span:hover {background: rgba(71, 127, 255, 0.15) url(../assets/images/contents/img_dragDrop.png) no-repeat center center;background-size: 200px;}

    #addFile { display:none; }
	.addFileLabel {cursor:pointer;position: fixed;bottom: 40px;right: 100px;opacity: 1;z-index: 1110000 !important;transition: 0.2s;font-size: 0;padding: 0 !important;border-radius: 5px;display: block;text-align: center;font-size: 30px !important;width: 60px;border: 2px dashed #abc4ff;height: 60px;line-height: 59px;color: #608fff !important;background: rgba(71, 127, 255, 0.1) !important;}
	.addFileLabel:hover {background: #477fff !important;color: #fff !important;border: 2px solid #477fff;border-radius: 50%;box-shadow: 0 5px 15px rgba(71, 127, 255, 0.4) !important;}

    /* 파일 검색 */
    .grfolderUl { overflow: hidden; box-sizing: border-box; padding-right: 30px; border-bottom: 5px solid #f1f3f5; padding-bottom: 40px; }
    .grfolderUl > li.on .subFolderDiv { display:block; }
    .grfolderUl > li.subMore .seeSubFolder { display:block; }
    .subFolderDiv, .grfolderUl > li.on .seeSubFolder { display:none; }

    .section_ce_fix.dp1 .grfolderUl li.on { height: auto; border-bottom: 2px solid #f1f3f5; }
    .section_ce_fix.dp1 .group_file.grp_file_search .grp { width: 100%; box-sizing: border-box; margin-left: 6% !important; }
    .section_ce_fix.dp1 .group_file.grp_file_search .grp.fileName { margin-left: 7.3% !important; }
    .section_ce_fix.dp1.fileSrcOn .grfolderUl .fd { left: 6.2%; }
    .section_ce_fix.dp1 .grfolderUl li a.seeSubFolder, .section_ce_fix.dp1 .closeSub { font-size:0; z-index: 10000; opacity: 1; transition:0.2s;left:auto; width: 60px; height: 65px; border-radius: 50%; right: 0; top:0; background-color:transparent; border:none; border-radius: 0; box-sizing: border-box;background-image: url('../assets/images/side_area/bt_open.png'); background-size: 26px; background-repeat: no-repeat; background-position: center center }
    .section_ce_fix.dp1 .closeSub { background-image: url('../assets/images/side_area/bt_close.png'); position: absolute; }
    .section_ce_fix.dp1 .grfolderUl li a.seeSubFolder:hover, .section_ce_fix.dp1 .closeSub:hover { background-color:#fff; }
    .section_ce_fix.dp1 .grfolderUl > li > .goDets { height: 66px !important; }

    .section_ce_fix.dp1 .subFoldertitleDiv h5 { display: none; }
    .section_ce_fix.dp1 .subFolderDiv li { border-top: 1px solid #f1f3f5; border-bottom: 0; background: #fff; line-height: 55px !important; height: 55px !important; }
    .section_ce_fix.dp1 .subFolderDiv li p { line-height: 55px !important; height: 55px !important; }
    .subFolderContent > ul > li .preview { position: absolute; left: 7%; background: #fff; border-radius: 5px; text-transform: uppercase; text-align: center; width: 50px; color: #477fff; top: 8px; padding-right: 0; border: 1px solid #e7e9ea; box-shadow:none; }
    .section_ce_fix.dp1 .subFolderContent > ul > li p.preview { height: 33px !important; line-height: 33px !important; text-align: center; background-size: cover; background-position: center; }
    .subFolderContent > ul > li .preview.img { transition: 0.1s; font-size:0; background-size:cover; background-position:center center; }
    .subFolderContent > ul > li .preview.img { background-image: url('../assets/images/contents/imgDc8.png')}
    
    #group_file_box_new.section_ce_fix li.pswFolder .grp .ic-password { display: inline-block; }
</style>