<template>
    <div id="organization_position_setting" class="dis member_set_1">

        <div class="bt_scheduleBox" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
            <input type="button" id="btnAddVacationType" class="bt_schedule" @click.prevent="addPosition"/>
        </div>

        <div class="section_scroll">
            <div class="fixedDiv">
                <ul class="sortHeader groupNoticeHeader">
                    <li class="no"><span>No</span></li>
                    <li class="name" :class="{ notPermitted : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') }"><span>직급명</span></li>
                    <li class="button" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')"><span>버튼</span></li>
                </ul>

                <div id="organization_position_setting_scroll">
                    <ul class="content">

                        <li :key="pos.pos_id" v-for="(pos, index) in position">
                            <p class="no"><span class="clr" :class="`dc${index % 10}`">{{ index + 1 }}</span></p>
                            <p class="name" :class="{ notPermitted : (get_group_role(scope_group_id) != 'GROUP_MANAGER' && get_group_role(scope_group_id) != 'ADMIN') }">
                                <a href="#" @click.prevent="modifyPosition(pos)"><span>{{ pos.pos_name }}</span></a>
                            </p>
                            <p class="button" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                <a href="#" class="delBtn" @click.prevent="deletePosition($event, pos)">삭제</a>
                            </p>
                            <div class="btns" v-if="(get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN')">
                                <a href="#" class="up" title="위" @click.prevent="updatePositionSeq(index, -1)" :disabled="pos.pos_seq == 1">위</a>
                                <a href="#" class="down" title="아래" @click.prevent="updatePositionSeq(index, 1)" :disabled="pos.pos_seq == (position.length)">아래</a>
                            </div>
                        </li>

                        <!-- <li>
                            <p class="no"><span class="clr dc0">1</span></p>
                            <p class="name"><span>대표</span></p>
                            <p class="button">
                                <a href="#" class="delBtn">삭제</a>
                            </p>
                            <div class="btns">
                                <a href="#" class="up" title="위">위</a>
                                <a href="#" class="down" title="아래">아래</a>
                            </div>
                        </li>
                        <li>
                            <p class="no"><span class="clr dc1">2</span></p>
                            <p class="name"><span>차장</span></p>
                            <p class="button">
                                <a href="#" class="delBtn">삭제</a>
                            </p>
                            <div class="btns">
                                <a href="#" class="up" title="위">위</a>
                                <a href="#" class="down" title="아래">아래</a>
                            </div>
                        </li>
                        <li>
                            <p class="no"><span class="clr dc2">3</span></p>
                            <p class="name"><span>과장</span></p>
                            <p class="button">
                                <a href="#" class="delBtn">삭제</a>
                            </p>
                            <div class="btns">
                                <a href="#" class="up" title="위">위</a>
                                <a href="#" class="down" title="아래">아래</a>
                            </div>
                        </li>
                        <li>
                            <p class="no"><span class="clr dc3">4</span></p>
                            <p class="name"><span>대리</span></p>
                            <p class="button">
                                <a href="#" class="delBtn">삭제</a>
                            </p>
                            <div class="btns">
                                <a href="#" class="up" title="위">위</a>
                                <a href="#" class="down" title="아래">아래</a>
                            </div>
                        </li>
                        <li>
                            <p class="no"><span class="clr dc4">5</span></p>
                            <p class="name"><span>사원</span></p>
                            <p class="button">
                                <a href="#" class="delBtn">삭제</a>
                            </p>
                            <div class="btns">
                                <a href="#" class="up" title="위">위</a>
                                <a href="#" class="down" title="아래">아래</a>
                            </div>
                        </li>
                        <li>
                            <p class="no"><span class="clr dc5">6</span></p>
                            <p class="name"><span>인턴</span></p>
                            <p class="button">
                                <a href="#" class="delBtn">삭제</a>
                            </p>
                            <div class="btns">
                                <a href="#" class="up" title="위">위</a>
                                <a href="#" class="down" title="아래">아래</a>
                            </div>
                        </li> -->
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class OrganizationPositionSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    position : any[] = [];

    mounted() : void {
        this.event_bus.$on('OrganizationPositionSettingResize', this.handleResize);
        this.getOrganizationPosition();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_box_height = $('#organization_setting .title_box').outerHeight();
        const title_box2_height = $('#organization_setting .title_box2').outerHeight();
        const sort_header_height = $('#organization_setting .section_scroll .sortHeader').outerHeight();

        // @ts-ignore
        $('#organization_position_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (title_box2_height ? title_box2_height : 0)
                                           - (sort_header_height ? sort_header_height : 0)
        });
    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직급 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 직급 추가 모달
     */
    addPosition() : void {
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "직급 추가",
            subtitle : "직급명",
            placeholder : "직급명을 입력해주세요",
            save : async(name) => {
                try {

                    // 정보 가공
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.POST, { "pos_name" : name });
                    
                    console.log(response);
                    
                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 직급 추가 중 오류 발생");
                    }

                    this.getOrganizationPosition();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직급 추가 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 직급 수정 모달
     */
    modifyPosition(position : any) : void {
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "직급 수정",
            subtitle : "직급명",
            placeholder : "직급명을 입력해주세요",
            content : position.pos_name,
            save : async(name) => {
                try {
                    await this.modifyPositionAPI(position.pos_id, { "pos_name" : name });
                    this.getOrganizationPosition();
                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직급 수정 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 직급 수정 API
     */
    async modifyPositionAPI(pos_id : number, data : any) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position/${pos_id}`, API_METHOD.PUT, data, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 직급 수정 중 오류 발생");
            }
        } catch(e) {
            throw e;
        }
    }

    /**
     * 직급 순서 변경
     */
    async updatePositionSeq(index : number, dir : number) : Promise<void> {
        try {
            // 제일 직급이 높은데 위로 가는걸 누른경우, 제일 직급이 낮은데 아래로 가는걸 누른 경우
            if( (dir == -1 && index == 0) || (dir == 1 && index == (this.position.length - 1)) ) return;
            
            const target_position = this.position[index];

            await this.getOrganizationPosition();
            
            const new_target_position = this.position.filter(item => item.pos_id == target_position.pos_id)[0];
            const affected_position = this.position[this.position.indexOf(new_target_position) + dir];

            if( new_target_position == null || affected_position == null ) {
                throw new Error("조직도 직급 순서 수정 중 오류 발생");
            }

            const modify_promise : Promise<any>[] = [];
            
            modify_promise.push(this.modifyPositionAPI(new_target_position.pos_id, { pos_seq : new_target_position.pos_seq + dir }));
            modify_promise.push(this.modifyPositionAPI(affected_position.pos_id, { pos_seq : affected_position.pos_seq - dir }));

            this.hodu_show_indicator();
            await Promise.all(modify_promise);
            await this.getOrganizationPosition();
            this.hodu_hide_indicator();
            
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직급 순서 수정 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 직급 삭제
     */
    deletePosition(event, position : any) : void {
        event.stopPropagation();

        this.hodu_show_dialog('cancel', `정말로 직급을 삭제하시겠습니까?\n'${position.pos_name}'`, ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    // 정보 가공
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position/${position.pos_id}`, API_METHOD.DELETE);
                    
                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 직급 삭제 중 오류 발생");
                    }

                    this.getOrganizationPosition();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직급 삭제 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ]);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_position_setting_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
</script>

<style scoped>
    #organization_position_setting .section_scroll .no { width : 15%; text-align: center; }
    #organization_position_setting .section_scroll .name { width : 60%; }
    #organization_position_setting .section_scroll .name.notPermitted { width : 85%; }
    #organization_position_setting .section_scroll .button { width : 25%; }

    #organization_position_setting .section_scroll ul.content > li {overflow:hidden; box-sizing: border-box;position: relative; border-bottom: 1px solid #f1f3f5;height: 70px;line-height: 70px;}
    #organization_position_setting .section_scroll ul.content > li > p {float:left; display: inline-block;font-weight: bold;-webkit-box-sizing: border-box;box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-size: 14px; box-sizing: border-box;}
    #organization_position_setting .section_scroll ul.content > li > p.name { padding-right: 35px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
    #organization_position_setting .clr { display: inline-block; font-size: 16px; min-width:0;height: 35px; padding:0 13px; line-height: 35px; border-radius: 20px; }

    #organization_position_setting .section_scroll ul.content li .btns { transition: 0.1s; position: absolute; right:35px; top:0; line-height: 70px; z-index: 10; }
    #organization_position_setting .section_scroll ul.content li .btns a.up { display : inline-block; font-size : 0; width : 35px; height : 35px; margin-top:16px;  background : #f1f3f5 url('../../assets/images/side_area/bt_close.png') center center; border-radius: 50%;}
    #organization_position_setting .section_scroll ul.content li .btns a.down { display : inline-block; font-size : 0; width : 35px; height : 35px;margin-left:10px; margin-top:16px; background : #f1f3f5 url('../../assets/images/side_area/bt_open.png') center center; border-radius: 50%;}
    #organization_position_setting .section_scroll ul.content li .btns a:hover { background-color:#e7ebef }
    #organization_position_setting .section_scroll ul.content > li > p > a.delBtn { line-height: 70px; color: #ff6363;}

    #organization_position_setting span.clr.dc0 { background-color:#ff6363 !important; border-color:#ff6363 !important; color:#fff !important; }
    #organization_position_setting span.clr.dc1 { background-color:#FFA70E !important; border-color:#FFA70E !important; color:#fff !important; }
    #organization_position_setting span.clr.dc2 { background-color:#FFC72F !important; border-color:#FFC72F !important; color:#fff !important; }
    #organization_position_setting span.clr.dc3 { background-color:#FF198B !important; border-color:#FF198B !important; color:#fff !important; }
    #organization_position_setting span.clr.dc4 { background-color:#00B2C7 !important; border-color:#00B2C7 !important; color:#fff !important; }
    #organization_position_setting span.clr.dc5 { background-color:#13D08B !important; border-color:#13D08B !important; color:#fff !important; }
    #organization_position_setting span.clr.dc6 { background-color:#4DBAFF !important; border-color:#4DBAFF !important; color:#fff !important; }
    #organization_position_setting span.clr.dc7 { background-color:#477FFF !important; border-color:#477FFF !important; color:#fff !important; }
    #organization_position_setting span.clr.dc8 { background-color:#6854FF !important; border-color:#6854FF !important; color:#fff !important; }
    #organization_position_setting span.clr.dc9 { background-color:#35405A !important; border-color:#35405A !important; color:#fff !important; }
</style>